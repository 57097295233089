export const WebDevelopmentSvg = ({
  className,
  ...rest
}: {
  className: string | null;
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 496 496"
    // enable-background="new 0 0 496 496"
    xmlSpace="preserve"
    className={className || ""}
  >
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M224.818176,333.624573 
	C225.386826,338.821167 225.403152,343.645447 226.880829,349.181641 
	C229.937317,344.508270 232.652573,340.282928 235.452927,336.114777 
	C236.437988,334.648590 237.264297,332.598419 239.535065,333.073547 
	C241.728271,333.532471 241.273758,335.647034 241.445541,337.199921 
	C242.272385,344.674530 243.660461,352.130707 242.176849,359.819977 
	C237.905716,354.055298 239.576019,346.834198 237.783920,339.115234 
	C234.392044,344.384613 231.771515,348.559875 229.031754,352.655365 
	C228.074463,354.086365 227.425751,356.433075 225.123398,355.828430 
	C222.729813,355.199860 222.911972,352.783844 222.734406,350.837952 
	C222.273071,345.782623 221.882645,340.720764 221.430435,335.256958 
	C216.131790,340.511658 214.655045,348.249512 208.391922,352.300110 
	C206.649323,350.027100 207.885269,348.780121 208.616791,347.605011 
	C211.865723,342.386047 215.189453,337.213013 218.539246,332.057861 
	C219.506546,330.569244 220.339264,328.551941 222.621292,329.061005 
	C224.752411,329.536407 224.144226,331.740387 224.818176,333.624573 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M261.112732,155.582825 
	C261.037903,150.189911 261.037903,145.252686 261.037903,140.325729 
	C264.263367,141.220459 264.338593,141.221039 264.280121,148.886093 
	C264.253174,152.418167 264.851593,155.829254 265.695496,160.236923 
	C269.025330,155.419632 271.932678,151.168076 274.894867,146.955048 
	C275.815094,145.646210 276.518677,143.674377 278.596863,144.221924 
	C280.718323,144.780884 280.071045,146.868820 280.395966,148.428101 
	C281.440826,153.442459 281.118927,158.603531 281.633453,164.461594 
	C285.259674,161.653152 286.359467,158.022751 288.709778,155.460968 
	C290.836517,153.142853 291.151642,148.440414 297.285492,148.814499 
	C292.202911,156.442032 287.745087,163.170502 283.233246,169.862534 
	C282.535950,170.896759 281.453186,171.372665 280.174042,170.845459 
	C278.860352,170.304031 278.801178,169.096390 278.692963,167.925766 
	C278.192352,162.508820 277.690125,157.091995 277.048309,150.161469 
	C273.444000,155.410080 270.642303,159.580276 267.734009,163.674744 
	C266.747772,165.063232 266.165802,167.326035 263.702850,166.596848 
	C261.347870,165.899658 261.821411,163.773438 261.677063,162.001663 
	C261.515106,160.013840 261.350830,158.026215 261.112732,155.582825 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M314.546387,309.466980 
	C317.906708,313.741547 321.983459,317.022186 324.345154,321.812012 
	C322.780182,323.656036 320.426849,324.003784 319.196533,326.240814 
	C317.149292,329.963013 313.550720,331.887390 309.079498,330.884399 
	C304.616974,329.883392 300.927612,325.679291 300.827484,321.329315 
	C300.721008,316.703705 302.923798,313.414276 307.317139,311.702515 
	C308.078308,311.405945 308.809509,311.032349 309.489777,310.723755 
	C309.869629,308.900513 308.281647,308.378723 307.641083,307.320496 
	C306.832733,305.985107 304.766693,304.662323 306.432648,303.080322 
	C308.259796,301.345245 309.288086,303.635468 310.350220,304.713867 
	C311.748718,306.133759 313.004700,307.694000 314.546387,309.466980 
M309.412170,327.070404 
	C313.696869,328.313629 316.260498,326.122162 317.357422,322.412659 
	C318.419861,318.819824 316.244781,316.245789 313.126007,314.873901 
	C309.603455,313.324463 306.841095,315.068085 305.231323,318.129791 
	C303.112457,322.159637 305.737244,324.630127 309.412170,327.070404 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M160.520782,296.824493 
	C157.058197,294.028870 155.437546,290.719055 155.651413,286.621063 
	C153.029221,285.750519 151.441177,287.717773 149.468842,288.257080 
	C148.286835,288.580261 147.143753,288.880310 146.535095,287.407745 
	C146.029160,286.183716 146.858429,285.426453 147.756622,285.042694 
	C155.184998,281.869019 162.336884,278.000397 170.521255,275.477661 
	C171.021332,278.405914 172.339844,280.433746 173.848572,282.501831 
	C176.515106,286.156952 176.629059,290.257294 173.776627,294.051758 
	C170.842468,297.954956 166.141983,298.990692 160.520782,296.824493 
M165.484055,294.573730 
	C166.570328,294.196777 167.766541,293.997528 168.720764,293.407135 
	C170.937347,292.035706 172.684021,290.126282 172.224091,287.324371 
	C171.766769,284.538605 170.143692,282.360840 167.263885,281.638916 
	C164.301208,280.896240 162.045105,282.483612 160.346695,284.616577 
	C157.549225,288.129761 159.342590,291.988892 165.484055,294.573730 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M341.597961,221.703918 
	C337.942017,222.752731 335.156403,225.295685 331.287476,225.095886 
	C330.304047,223.306824 331.047852,221.125153 329.182892,219.399857 
	C325.808563,216.278183 325.276337,212.059402 327.650238,208.013916 
	C329.960724,204.076538 333.790527,202.247498 338.234589,203.009323 
	C342.991852,203.824829 345.660614,207.097733 346.284119,211.935043 
	C346.406036,212.880920 346.542084,213.824982 346.666260,214.730804 
	C349.083862,215.114258 350.726776,213.727829 352.593872,213.162445 
	C353.790833,212.799957 354.984741,212.596161 355.558044,214.000336 
	C356.169922,215.498962 354.977509,216.082901 353.892914,216.543961 
	C349.919800,218.232941 345.928284,219.878647 341.597961,221.703918 
M330.083649,213.426010 
	C330.736359,217.377441 332.865753,220.086090 337.048859,219.356018 
	C340.757263,218.708817 343.234680,216.263702 342.772552,211.973740 
	C342.416779,208.671234 340.298279,206.732544 337.264557,206.592773 
	C333.321930,206.411102 330.831818,208.681778 330.083649,213.426010 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M316.842255,196.632080 
	C314.030609,195.590454 311.980591,194.008911 310.988129,191.705139 
	C310.042786,189.510605 308.815277,187.871521 306.091309,187.047806 
	C308.475403,182.820572 312.116699,180.433624 315.083893,177.467651 
	C317.659149,174.893478 320.417114,172.500305 323.127625,170.064194 
	C324.218903,169.083374 325.504944,167.664963 326.896210,169.311737 
	C328.251923,170.916473 326.642670,171.989761 325.595856,172.998978 
	C324.422577,174.130051 323.197601,175.207520 321.602386,176.667847 
	C325.338531,178.537979 328.498169,180.509048 329.553284,184.562668 
	C330.087158,186.613861 329.936584,188.504013 329.223755,190.392059 
	C327.403961,195.211899 323.071869,197.491241 316.842255,196.632080 
M326.193756,185.145142 
	C325.441498,184.079208 324.845642,182.842133 323.904846,181.982605 
	C321.880676,180.133331 319.514496,179.750092 317.058502,181.185043 
	C314.422852,182.724960 312.881012,184.897232 313.328094,188.118576 
	C313.719177,190.936340 315.616394,192.513702 318.072754,193.308975 
	C321.828857,194.525101 325.374969,191.252502 326.193756,185.145142 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M194.206818,317.180176 
	C189.396164,321.813538 184.868500,326.229431 180.251129,330.549408 
	C179.166382,331.564270 177.960739,333.757599 176.217346,331.864410 
	C174.601486,330.109711 176.607437,328.900146 177.708557,327.781769 
	C178.754257,326.719727 179.960007,325.815247 180.818405,325.079041 
	C180.733856,322.821808 179.104996,323.233978 178.248138,322.675568 
	C174.445007,320.196808 172.122208,316.916840 172.958115,312.254974 
	C173.819046,307.453369 177.103043,304.636688 181.750412,303.876770 
	C186.390518,303.118042 189.766083,305.480255 191.977036,309.465210 
	C192.597580,310.583649 192.595490,311.923950 194.200180,312.478546 
	C197.588028,313.649384 195.748596,315.355591 194.206818,317.180176 
M188.328278,310.221436 
	C185.562973,306.664276 182.305634,306.325867 178.956619,309.172089 
	C176.500702,311.259369 175.349258,314.026733 177.318771,317.066589 
	C179.048996,319.737091 181.502090,321.576416 184.798553,319.948425 
	C188.526886,318.107178 190.951202,315.393433 188.328278,310.221436 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M186.539352,169.954788 
	C193.782043,169.699463 197.910324,172.629486 198.989700,178.407898 
	C199.935349,183.470413 196.935028,187.503479 190.317596,189.979813 
	C190.715820,192.282227 192.927765,193.300949 193.986588,195.118500 
	C194.547516,196.081360 194.669434,197.037979 193.668198,197.750671 
	C192.874390,198.315704 192.042297,198.029221 191.436844,197.370895 
	C186.069489,191.534607 180.723328,185.678818 174.878555,179.290634 
	C180.367386,177.376114 180.920425,171.149124 186.539352,169.954788 
M195.263916,181.740707 
	C196.288742,177.996735 194.216614,175.773041 191.344391,174.079010 
	C189.335236,172.894073 187.238113,172.995331 185.304535,174.411530 
	C183.141800,175.995590 181.890518,178.064392 182.476669,180.801682 
	C183.135025,183.876205 185.358017,185.662720 188.230286,186.328598 
	C191.514435,187.089966 193.402115,184.899994 195.263916,181.740707 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M343.852386,225.801117 
	C350.361267,227.595047 353.490723,231.312073 353.005249,236.216858 
	C352.500366,241.317078 349.257965,244.202362 343.554901,244.502777 
	C341.179596,240.337021 342.586243,235.065277 340.016235,230.565842 
	C333.315216,234.463165 337.908295,239.207474 339.359558,244.730453 
	C334.041382,242.906204 333.144928,239.454712 332.918610,235.982986 
	C332.546051,230.268707 336.434753,226.692551 343.852386,225.801117 
M344.879547,237.307831 
	C345.320862,238.285355 344.983307,239.721970 346.617371,240.100662 
	C349.242493,238.900101 350.047089,236.604355 349.449493,234.006973 
	C348.919830,231.704681 347.482666,229.939590 344.647949,230.014389 
	C343.923981,232.389099 344.352051,234.495926 344.879547,237.307831 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M214.255005,174.411774 
	C206.043839,179.424057 198.630310,176.883774 196.921234,168.826218 
	C195.418945,161.743591 202.000519,155.930374 209.342010,157.932816 
	C211.841064,158.614441 214.131668,160.026474 214.712677,162.640045 
	C215.370697,165.600067 212.263565,165.750595 210.617111,166.800629 
	C208.155624,168.370438 205.472443,169.592621 202.644409,171.089218 
	C204.888824,174.406006 207.396591,173.899002 209.978989,173.082382 
	C212.325134,172.340454 212.865891,168.986572 215.894073,168.851288 
	C217.193512,171.217224 215.789291,172.666763 214.255005,174.411774 
M201.338226,163.623123 
	C200.467499,164.890259 199.926895,166.194550 201.004379,167.941406 
	C204.287094,166.625046 207.818161,165.789001 210.497253,161.989059 
	C206.889603,160.939163 204.180969,160.461487 201.338226,163.623123 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M164.457153,273.826508 
	C159.659332,276.033875 155.323273,276.249329 151.683212,272.509430 
	C148.772522,269.518921 148.004837,265.905853 149.703995,261.924316 
	C150.595200,259.835999 152.039032,258.337677 154.098434,257.381744 
	C157.023285,256.024139 158.728119,256.528595 159.342438,260.069763 
	C159.925385,263.430084 160.002670,266.969513 161.785507,270.617706 
	C168.311752,266.479919 164.473114,262.045074 162.524536,257.669220 
	C165.608658,256.417664 166.654785,258.271576 167.613983,260.200653 
	C170.086441,265.173065 169.239380,269.157227 164.457153,273.826508 
M154.682755,270.572296 
	C156.265213,271.473175 157.690231,271.704132 157.661804,269.268524 
	C157.628006,266.375519 157.116898,263.548126 155.558777,260.692932 
	C151.357819,263.612427 151.502594,266.697876 154.682755,270.572296 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M299.831299,181.628662 
	C293.234985,178.297607 291.098724,174.588852 292.549255,169.457031 
	C294.066284,164.090027 298.013275,161.226181 303.168762,161.751801 
	C309.418396,162.388962 313.307892,168.728928 311.071411,174.580322 
	C309.997467,177.389984 308.343628,177.365768 306.036377,175.954926 
	C303.003571,174.100388 300.435150,171.366653 296.757874,170.547607 
	C295.588776,174.754166 295.588776,174.754166 305.037842,180.323120 
	C304.061432,182.994720 302.051514,182.168518 299.831299,181.628662 
M302.546967,165.237686 
	C301.148407,165.390747 299.448151,164.952713 298.847687,167.624283 
	C302.020630,168.597153 303.775970,172.417023 308.157043,172.183151 
	C308.403473,168.251892 306.964478,165.974304 302.546967,165.237686 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M350.291748,274.260529 
	C349.996185,281.466156 347.111969,284.947571 341.547882,285.210266 
	C338.485260,285.354858 336.631531,284.385315 337.663483,280.840759 
	C338.219635,278.930450 338.787323,277.018280 339.213684,275.077026 
	C339.589172,273.367554 340.702026,271.725983 339.796844,269.473206 
	C335.050293,270.165588 333.822357,273.474182 333.657227,277.474518 
	C333.601318,278.829407 335.020081,280.785828 332.731659,281.087891 
	C330.436920,281.390717 330.458771,279.045929 330.267700,277.510742 
	C329.616699,272.279877 333.418518,266.928772 338.346100,265.927582 
	C343.656860,264.848511 348.368774,267.973602 350.291748,274.260529 
M343.042328,273.059021 
	C342.673492,275.786133 340.426910,278.333252 342.020203,281.356659 
	C346.329895,279.784576 348.087219,276.594452 346.543182,273.207581 
	C345.880371,271.753632 344.995544,269.908661 343.042328,273.059021 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M155.074097,234.040878 
	C150.404678,229.870789 149.373734,224.919495 151.977615,220.579330 
	C154.299652,216.708908 158.222931,215.567276 163.443512,217.416779 
	C163.836807,222.132629 160.943405,226.463852 160.921387,231.888626 
	C168.089752,230.357925 167.223877,225.065811 167.152435,220.299255 
	C170.643280,221.667618 171.476883,225.221191 169.680786,229.662476 
	C167.470703,235.127380 162.383270,236.755722 155.074097,234.040878 
M157.839706,228.746887 
	C158.438065,225.937378 160.061447,223.276733 159.341171,220.171326 
	C155.930847,220.069168 154.521469,222.051041 154.179871,224.711365 
	C153.875214,227.083847 153.155701,230.271484 157.839706,228.746887 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M204.079254,322.756775 
	C202.136261,321.150513 199.476013,322.023712 198.112274,320.214905 
	C198.058762,318.706512 198.937836,318.265930 199.985275,318.148987 
	C205.092331,317.578644 210.887680,322.899445 211.043777,328.261230 
	C211.203598,333.750427 205.894760,338.934906 200.426147,338.630127 
	C194.647766,338.308105 190.853851,333.592438 191.578506,327.632904 
	C192.125778,323.132080 193.580307,322.390381 197.415634,324.698059 
	C200.479065,326.541321 203.519028,328.423553 206.918610,330.501953 
	C207.978760,326.765930 206.496704,324.745972 204.079254,322.756775 
M203.094177,331.958466 
	C200.641129,330.438141 198.435806,328.394318 195.095825,327.930054 
	C194.450378,332.313293 196.623535,334.504547 200.147141,335.228027 
	C201.633362,335.533173 204.618225,335.894287 203.094177,331.958466 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M287.676453,328.499695 
	C286.344757,330.031616 285.730682,332.324646 283.274414,330.731750 
	C284.049042,326.312103 287.756622,322.964661 292.492340,322.855621 
	C297.069397,322.750183 300.446014,324.895508 302.147949,329.269989 
	C303.955780,333.916809 302.610535,338.427612 298.621094,341.055145 
	C294.666412,343.659760 290.746063,343.346741 287.131897,340.423370 
	C284.400726,338.214172 283.678314,335.965149 287.593903,334.057220 
	C290.811981,332.489166 293.953064,330.763184 297.498505,328.914154 
	C294.005920,325.158936 290.989197,326.049866 287.676453,328.499695 
M296.787415,338.306671 
	C298.393188,336.854614 299.975830,335.392944 299.046631,332.537384 
	C295.276733,332.893982 292.454285,334.865112 289.522644,337.117188 
	C291.499878,339.894226 293.681061,340.013733 296.787415,338.306671 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M180.322220,190.681976 
	C183.967285,194.844254 184.226212,199.023300 181.510834,203.291641 
	C179.267639,206.817749 175.850586,208.486588 171.757294,207.765457 
	C167.328384,206.985184 164.197281,204.279816 163.331863,199.720490 
	C162.520966,195.448425 164.067993,191.833435 167.678406,189.404572 
	C171.969650,186.517685 176.140610,187.308456 180.322220,190.681976 
M166.488113,198.221115 
	C166.848358,199.718399 167.613983,200.993774 168.709366,202.051712 
	C170.644211,203.920380 172.891815,204.746933 175.540253,203.808044 
	C177.978134,202.943771 179.224045,201.066818 179.618668,198.602417 
	C180.075134,195.751740 178.659225,193.833862 176.351013,192.423981 
	C171.969818,189.747864 168.710083,191.427216 166.488113,198.221115 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M333.472412,295.441345 
	C338.404785,301.082855 338.410858,306.875854 333.794952,310.973206 
	C329.695160,314.612427 323.465424,314.050110 319.589539,309.690979 
	C316.274597,305.962708 316.205688,301.011536 319.412933,297.003723 
	C322.807220,292.762207 327.280243,292.176849 333.472412,295.441345 
M320.589691,304.129761 
	C321.144714,305.727356 321.966919,307.154175 323.361420,308.160980 
	C325.700684,309.849976 328.228912,310.396393 330.759857,308.744812 
	C333.014374,307.273621 334.105713,305.065247 333.614716,302.331665 
	C333.082062,299.365936 331.095215,297.706329 328.243683,297.212433 
	C323.399414,296.373322 321.233429,299.061493 320.589691,304.129761 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M351.549866,246.793060 
	C352.964783,246.613937 354.011353,246.669037 354.031555,248.056381 
	C354.051727,249.444244 352.985779,249.995560 351.850067,250.421585 
	C348.177673,251.799164 344.510010,253.189392 340.421387,254.732971 
	C343.875122,259.607513 350.731323,259.235504 353.372406,264.260376 
	C351.492157,266.084442 350.188751,265.002747 348.936707,264.362518 
	C345.104614,262.403046 341.299286,260.390564 337.501678,258.364746 
	C336.061371,257.596405 334.313385,256.976715 334.294586,254.960632 
	C334.274231,252.777420 336.245850,252.475739 337.707092,251.904129 
	C342.175476,250.156204 346.694458,248.537735 351.549866,246.793060 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M160.811890,241.110077 
	C161.299911,241.349365 161.462799,241.402130 161.599350,241.494034 
	C163.577408,242.825348 167.004532,242.998154 167.005157,245.977585 
	C167.005905,249.463531 163.315308,249.370316 161.033737,250.212112 
	C156.906265,251.734955 153.109955,254.401672 146.813202,254.534393 
	C150.671753,248.668564 156.939957,249.337921 161.128189,246.064255 
	C157.728027,241.724197 150.833466,242.063187 147.253555,235.982407 
	C153.060059,236.238602 156.536163,239.245422 160.811890,241.110077 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M326.817352,287.466675 
	C325.619629,284.379547 327.543457,284.210052 329.179565,284.926056 
	C336.025238,287.922089 342.773071,291.143158 349.534302,294.329468 
	C350.647980,294.854279 351.572876,295.798248 350.804688,297.107758 
	C349.872955,298.695984 348.493774,297.927429 347.304962,297.364380 
	C340.550232,294.165283 333.809357,290.936951 326.817352,287.466675 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M156.078217,209.000488 
	C153.697220,207.378632 150.029648,207.748367 149.951828,203.885330 
	C154.298157,203.521133 171.550766,211.203293 174.539963,214.731125 
	C172.331329,216.083145 172.331329,216.083145 156.078217,209.000488 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M279.499542,333.697937 
	C280.584930,338.512268 282.838501,342.666840 282.906036,347.760651 
	C280.298096,347.484161 278.213989,347.872681 276.431854,349.463013 
	C275.546814,350.252777 274.233612,350.527802 273.386383,349.272919 
	C272.711029,348.272583 273.169373,347.319702 274.157104,346.786194 
	C278.622253,344.374512 277.945251,340.721588 276.626190,336.886017 
	C276.356964,336.103088 275.908752,335.300995 275.926666,334.516541 
	C275.966064,332.792450 273.394318,330.306213 276.491821,329.506653 
	C278.845612,328.899048 278.692444,331.789856 279.499542,333.697937 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M225.984314,151.072021 
	C221.121597,155.713638 221.811005,160.783691 223.833130,166.145996 
	C224.359100,167.540802 225.534180,169.691528 223.272125,170.379684 
	C220.984131,171.075729 220.929993,168.580475 220.453949,167.167282 
	C218.849594,162.404449 217.022461,157.677002 216.329865,151.534393 
	C220.513824,154.275482 222.384140,148.493607 225.984314,151.072021 
z"
    />
  </svg>
);
export const WebDevelopmentSvgWhite = ({
  className,
  ...rest
}: {
  className: string | null;
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 496 496"
    // enable-background="new 0 0 496 496"
    xmlSpace="preserve"
    className={className || ""}
  >
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M224.818176,333.624573 
	C225.386826,338.821167 225.403152,343.645447 226.880829,349.181641 
	C229.937317,344.508270 232.652573,340.282928 235.452927,336.114777 
	C236.437988,334.648590 237.264297,332.598419 239.535065,333.073547 
	C241.728271,333.532471 241.273758,335.647034 241.445541,337.199921 
	C242.272385,344.674530 243.660461,352.130707 242.176849,359.819977 
	C237.905716,354.055298 239.576019,346.834198 237.783920,339.115234 
	C234.392044,344.384613 231.771515,348.559875 229.031754,352.655365 
	C228.074463,354.086365 227.425751,356.433075 225.123398,355.828430 
	C222.729813,355.199860 222.911972,352.783844 222.734406,350.837952 
	C222.273071,345.782623 221.882645,340.720764 221.430435,335.256958 
	C216.131790,340.511658 214.655045,348.249512 208.391922,352.300110 
	C206.649323,350.027100 207.885269,348.780121 208.616791,347.605011 
	C211.865723,342.386047 215.189453,337.213013 218.539246,332.057861 
	C219.506546,330.569244 220.339264,328.551941 222.621292,329.061005 
	C224.752411,329.536407 224.144226,331.740387 224.818176,333.624573 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M261.112732,155.582825 
	C261.037903,150.189911 261.037903,145.252686 261.037903,140.325729 
	C264.263367,141.220459 264.338593,141.221039 264.280121,148.886093 
	C264.253174,152.418167 264.851593,155.829254 265.695496,160.236923 
	C269.025330,155.419632 271.932678,151.168076 274.894867,146.955048 
	C275.815094,145.646210 276.518677,143.674377 278.596863,144.221924 
	C280.718323,144.780884 280.071045,146.868820 280.395966,148.428101 
	C281.440826,153.442459 281.118927,158.603531 281.633453,164.461594 
	C285.259674,161.653152 286.359467,158.022751 288.709778,155.460968 
	C290.836517,153.142853 291.151642,148.440414 297.285492,148.814499 
	C292.202911,156.442032 287.745087,163.170502 283.233246,169.862534 
	C282.535950,170.896759 281.453186,171.372665 280.174042,170.845459 
	C278.860352,170.304031 278.801178,169.096390 278.692963,167.925766 
	C278.192352,162.508820 277.690125,157.091995 277.048309,150.161469 
	C273.444000,155.410080 270.642303,159.580276 267.734009,163.674744 
	C266.747772,165.063232 266.165802,167.326035 263.702850,166.596848 
	C261.347870,165.899658 261.821411,163.773438 261.677063,162.001663 
	C261.515106,160.013840 261.350830,158.026215 261.112732,155.582825 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M314.546387,309.466980 
	C317.906708,313.741547 321.983459,317.022186 324.345154,321.812012 
	C322.780182,323.656036 320.426849,324.003784 319.196533,326.240814 
	C317.149292,329.963013 313.550720,331.887390 309.079498,330.884399 
	C304.616974,329.883392 300.927612,325.679291 300.827484,321.329315 
	C300.721008,316.703705 302.923798,313.414276 307.317139,311.702515 
	C308.078308,311.405945 308.809509,311.032349 309.489777,310.723755 
	C309.869629,308.900513 308.281647,308.378723 307.641083,307.320496 
	C306.832733,305.985107 304.766693,304.662323 306.432648,303.080322 
	C308.259796,301.345245 309.288086,303.635468 310.350220,304.713867 
	C311.748718,306.133759 313.004700,307.694000 314.546387,309.466980 
M309.412170,327.070404 
	C313.696869,328.313629 316.260498,326.122162 317.357422,322.412659 
	C318.419861,318.819824 316.244781,316.245789 313.126007,314.873901 
	C309.603455,313.324463 306.841095,315.068085 305.231323,318.129791 
	C303.112457,322.159637 305.737244,324.630127 309.412170,327.070404 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M160.520782,296.824493 
	C157.058197,294.028870 155.437546,290.719055 155.651413,286.621063 
	C153.029221,285.750519 151.441177,287.717773 149.468842,288.257080 
	C148.286835,288.580261 147.143753,288.880310 146.535095,287.407745 
	C146.029160,286.183716 146.858429,285.426453 147.756622,285.042694 
	C155.184998,281.869019 162.336884,278.000397 170.521255,275.477661 
	C171.021332,278.405914 172.339844,280.433746 173.848572,282.501831 
	C176.515106,286.156952 176.629059,290.257294 173.776627,294.051758 
	C170.842468,297.954956 166.141983,298.990692 160.520782,296.824493 
M165.484055,294.573730 
	C166.570328,294.196777 167.766541,293.997528 168.720764,293.407135 
	C170.937347,292.035706 172.684021,290.126282 172.224091,287.324371 
	C171.766769,284.538605 170.143692,282.360840 167.263885,281.638916 
	C164.301208,280.896240 162.045105,282.483612 160.346695,284.616577 
	C157.549225,288.129761 159.342590,291.988892 165.484055,294.573730 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M341.597961,221.703918 
	C337.942017,222.752731 335.156403,225.295685 331.287476,225.095886 
	C330.304047,223.306824 331.047852,221.125153 329.182892,219.399857 
	C325.808563,216.278183 325.276337,212.059402 327.650238,208.013916 
	C329.960724,204.076538 333.790527,202.247498 338.234589,203.009323 
	C342.991852,203.824829 345.660614,207.097733 346.284119,211.935043 
	C346.406036,212.880920 346.542084,213.824982 346.666260,214.730804 
	C349.083862,215.114258 350.726776,213.727829 352.593872,213.162445 
	C353.790833,212.799957 354.984741,212.596161 355.558044,214.000336 
	C356.169922,215.498962 354.977509,216.082901 353.892914,216.543961 
	C349.919800,218.232941 345.928284,219.878647 341.597961,221.703918 
M330.083649,213.426010 
	C330.736359,217.377441 332.865753,220.086090 337.048859,219.356018 
	C340.757263,218.708817 343.234680,216.263702 342.772552,211.973740 
	C342.416779,208.671234 340.298279,206.732544 337.264557,206.592773 
	C333.321930,206.411102 330.831818,208.681778 330.083649,213.426010 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M316.842255,196.632080 
	C314.030609,195.590454 311.980591,194.008911 310.988129,191.705139 
	C310.042786,189.510605 308.815277,187.871521 306.091309,187.047806 
	C308.475403,182.820572 312.116699,180.433624 315.083893,177.467651 
	C317.659149,174.893478 320.417114,172.500305 323.127625,170.064194 
	C324.218903,169.083374 325.504944,167.664963 326.896210,169.311737 
	C328.251923,170.916473 326.642670,171.989761 325.595856,172.998978 
	C324.422577,174.130051 323.197601,175.207520 321.602386,176.667847 
	C325.338531,178.537979 328.498169,180.509048 329.553284,184.562668 
	C330.087158,186.613861 329.936584,188.504013 329.223755,190.392059 
	C327.403961,195.211899 323.071869,197.491241 316.842255,196.632080 
M326.193756,185.145142 
	C325.441498,184.079208 324.845642,182.842133 323.904846,181.982605 
	C321.880676,180.133331 319.514496,179.750092 317.058502,181.185043 
	C314.422852,182.724960 312.881012,184.897232 313.328094,188.118576 
	C313.719177,190.936340 315.616394,192.513702 318.072754,193.308975 
	C321.828857,194.525101 325.374969,191.252502 326.193756,185.145142 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M194.206818,317.180176 
	C189.396164,321.813538 184.868500,326.229431 180.251129,330.549408 
	C179.166382,331.564270 177.960739,333.757599 176.217346,331.864410 
	C174.601486,330.109711 176.607437,328.900146 177.708557,327.781769 
	C178.754257,326.719727 179.960007,325.815247 180.818405,325.079041 
	C180.733856,322.821808 179.104996,323.233978 178.248138,322.675568 
	C174.445007,320.196808 172.122208,316.916840 172.958115,312.254974 
	C173.819046,307.453369 177.103043,304.636688 181.750412,303.876770 
	C186.390518,303.118042 189.766083,305.480255 191.977036,309.465210 
	C192.597580,310.583649 192.595490,311.923950 194.200180,312.478546 
	C197.588028,313.649384 195.748596,315.355591 194.206818,317.180176 
M188.328278,310.221436 
	C185.562973,306.664276 182.305634,306.325867 178.956619,309.172089 
	C176.500702,311.259369 175.349258,314.026733 177.318771,317.066589 
	C179.048996,319.737091 181.502090,321.576416 184.798553,319.948425 
	C188.526886,318.107178 190.951202,315.393433 188.328278,310.221436 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M186.539352,169.954788 
	C193.782043,169.699463 197.910324,172.629486 198.989700,178.407898 
	C199.935349,183.470413 196.935028,187.503479 190.317596,189.979813 
	C190.715820,192.282227 192.927765,193.300949 193.986588,195.118500 
	C194.547516,196.081360 194.669434,197.037979 193.668198,197.750671 
	C192.874390,198.315704 192.042297,198.029221 191.436844,197.370895 
	C186.069489,191.534607 180.723328,185.678818 174.878555,179.290634 
	C180.367386,177.376114 180.920425,171.149124 186.539352,169.954788 
M195.263916,181.740707 
	C196.288742,177.996735 194.216614,175.773041 191.344391,174.079010 
	C189.335236,172.894073 187.238113,172.995331 185.304535,174.411530 
	C183.141800,175.995590 181.890518,178.064392 182.476669,180.801682 
	C183.135025,183.876205 185.358017,185.662720 188.230286,186.328598 
	C191.514435,187.089966 193.402115,184.899994 195.263916,181.740707 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M343.852386,225.801117 
	C350.361267,227.595047 353.490723,231.312073 353.005249,236.216858 
	C352.500366,241.317078 349.257965,244.202362 343.554901,244.502777 
	C341.179596,240.337021 342.586243,235.065277 340.016235,230.565842 
	C333.315216,234.463165 337.908295,239.207474 339.359558,244.730453 
	C334.041382,242.906204 333.144928,239.454712 332.918610,235.982986 
	C332.546051,230.268707 336.434753,226.692551 343.852386,225.801117 
M344.879547,237.307831 
	C345.320862,238.285355 344.983307,239.721970 346.617371,240.100662 
	C349.242493,238.900101 350.047089,236.604355 349.449493,234.006973 
	C348.919830,231.704681 347.482666,229.939590 344.647949,230.014389 
	C343.923981,232.389099 344.352051,234.495926 344.879547,237.307831 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M214.255005,174.411774 
	C206.043839,179.424057 198.630310,176.883774 196.921234,168.826218 
	C195.418945,161.743591 202.000519,155.930374 209.342010,157.932816 
	C211.841064,158.614441 214.131668,160.026474 214.712677,162.640045 
	C215.370697,165.600067 212.263565,165.750595 210.617111,166.800629 
	C208.155624,168.370438 205.472443,169.592621 202.644409,171.089218 
	C204.888824,174.406006 207.396591,173.899002 209.978989,173.082382 
	C212.325134,172.340454 212.865891,168.986572 215.894073,168.851288 
	C217.193512,171.217224 215.789291,172.666763 214.255005,174.411774 
M201.338226,163.623123 
	C200.467499,164.890259 199.926895,166.194550 201.004379,167.941406 
	C204.287094,166.625046 207.818161,165.789001 210.497253,161.989059 
	C206.889603,160.939163 204.180969,160.461487 201.338226,163.623123 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M164.457153,273.826508 
	C159.659332,276.033875 155.323273,276.249329 151.683212,272.509430 
	C148.772522,269.518921 148.004837,265.905853 149.703995,261.924316 
	C150.595200,259.835999 152.039032,258.337677 154.098434,257.381744 
	C157.023285,256.024139 158.728119,256.528595 159.342438,260.069763 
	C159.925385,263.430084 160.002670,266.969513 161.785507,270.617706 
	C168.311752,266.479919 164.473114,262.045074 162.524536,257.669220 
	C165.608658,256.417664 166.654785,258.271576 167.613983,260.200653 
	C170.086441,265.173065 169.239380,269.157227 164.457153,273.826508 
M154.682755,270.572296 
	C156.265213,271.473175 157.690231,271.704132 157.661804,269.268524 
	C157.628006,266.375519 157.116898,263.548126 155.558777,260.692932 
	C151.357819,263.612427 151.502594,266.697876 154.682755,270.572296 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M299.831299,181.628662 
	C293.234985,178.297607 291.098724,174.588852 292.549255,169.457031 
	C294.066284,164.090027 298.013275,161.226181 303.168762,161.751801 
	C309.418396,162.388962 313.307892,168.728928 311.071411,174.580322 
	C309.997467,177.389984 308.343628,177.365768 306.036377,175.954926 
	C303.003571,174.100388 300.435150,171.366653 296.757874,170.547607 
	C295.588776,174.754166 295.588776,174.754166 305.037842,180.323120 
	C304.061432,182.994720 302.051514,182.168518 299.831299,181.628662 
M302.546967,165.237686 
	C301.148407,165.390747 299.448151,164.952713 298.847687,167.624283 
	C302.020630,168.597153 303.775970,172.417023 308.157043,172.183151 
	C308.403473,168.251892 306.964478,165.974304 302.546967,165.237686 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M350.291748,274.260529 
	C349.996185,281.466156 347.111969,284.947571 341.547882,285.210266 
	C338.485260,285.354858 336.631531,284.385315 337.663483,280.840759 
	C338.219635,278.930450 338.787323,277.018280 339.213684,275.077026 
	C339.589172,273.367554 340.702026,271.725983 339.796844,269.473206 
	C335.050293,270.165588 333.822357,273.474182 333.657227,277.474518 
	C333.601318,278.829407 335.020081,280.785828 332.731659,281.087891 
	C330.436920,281.390717 330.458771,279.045929 330.267700,277.510742 
	C329.616699,272.279877 333.418518,266.928772 338.346100,265.927582 
	C343.656860,264.848511 348.368774,267.973602 350.291748,274.260529 
M343.042328,273.059021 
	C342.673492,275.786133 340.426910,278.333252 342.020203,281.356659 
	C346.329895,279.784576 348.087219,276.594452 346.543182,273.207581 
	C345.880371,271.753632 344.995544,269.908661 343.042328,273.059021 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M155.074097,234.040878 
	C150.404678,229.870789 149.373734,224.919495 151.977615,220.579330 
	C154.299652,216.708908 158.222931,215.567276 163.443512,217.416779 
	C163.836807,222.132629 160.943405,226.463852 160.921387,231.888626 
	C168.089752,230.357925 167.223877,225.065811 167.152435,220.299255 
	C170.643280,221.667618 171.476883,225.221191 169.680786,229.662476 
	C167.470703,235.127380 162.383270,236.755722 155.074097,234.040878 
M157.839706,228.746887 
	C158.438065,225.937378 160.061447,223.276733 159.341171,220.171326 
	C155.930847,220.069168 154.521469,222.051041 154.179871,224.711365 
	C153.875214,227.083847 153.155701,230.271484 157.839706,228.746887 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M204.079254,322.756775 
	C202.136261,321.150513 199.476013,322.023712 198.112274,320.214905 
	C198.058762,318.706512 198.937836,318.265930 199.985275,318.148987 
	C205.092331,317.578644 210.887680,322.899445 211.043777,328.261230 
	C211.203598,333.750427 205.894760,338.934906 200.426147,338.630127 
	C194.647766,338.308105 190.853851,333.592438 191.578506,327.632904 
	C192.125778,323.132080 193.580307,322.390381 197.415634,324.698059 
	C200.479065,326.541321 203.519028,328.423553 206.918610,330.501953 
	C207.978760,326.765930 206.496704,324.745972 204.079254,322.756775 
M203.094177,331.958466 
	C200.641129,330.438141 198.435806,328.394318 195.095825,327.930054 
	C194.450378,332.313293 196.623535,334.504547 200.147141,335.228027 
	C201.633362,335.533173 204.618225,335.894287 203.094177,331.958466 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M287.676453,328.499695 
	C286.344757,330.031616 285.730682,332.324646 283.274414,330.731750 
	C284.049042,326.312103 287.756622,322.964661 292.492340,322.855621 
	C297.069397,322.750183 300.446014,324.895508 302.147949,329.269989 
	C303.955780,333.916809 302.610535,338.427612 298.621094,341.055145 
	C294.666412,343.659760 290.746063,343.346741 287.131897,340.423370 
	C284.400726,338.214172 283.678314,335.965149 287.593903,334.057220 
	C290.811981,332.489166 293.953064,330.763184 297.498505,328.914154 
	C294.005920,325.158936 290.989197,326.049866 287.676453,328.499695 
M296.787415,338.306671 
	C298.393188,336.854614 299.975830,335.392944 299.046631,332.537384 
	C295.276733,332.893982 292.454285,334.865112 289.522644,337.117188 
	C291.499878,339.894226 293.681061,340.013733 296.787415,338.306671 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M180.322220,190.681976 
	C183.967285,194.844254 184.226212,199.023300 181.510834,203.291641 
	C179.267639,206.817749 175.850586,208.486588 171.757294,207.765457 
	C167.328384,206.985184 164.197281,204.279816 163.331863,199.720490 
	C162.520966,195.448425 164.067993,191.833435 167.678406,189.404572 
	C171.969650,186.517685 176.140610,187.308456 180.322220,190.681976 
M166.488113,198.221115 
	C166.848358,199.718399 167.613983,200.993774 168.709366,202.051712 
	C170.644211,203.920380 172.891815,204.746933 175.540253,203.808044 
	C177.978134,202.943771 179.224045,201.066818 179.618668,198.602417 
	C180.075134,195.751740 178.659225,193.833862 176.351013,192.423981 
	C171.969818,189.747864 168.710083,191.427216 166.488113,198.221115 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M333.472412,295.441345 
	C338.404785,301.082855 338.410858,306.875854 333.794952,310.973206 
	C329.695160,314.612427 323.465424,314.050110 319.589539,309.690979 
	C316.274597,305.962708 316.205688,301.011536 319.412933,297.003723 
	C322.807220,292.762207 327.280243,292.176849 333.472412,295.441345 
M320.589691,304.129761 
	C321.144714,305.727356 321.966919,307.154175 323.361420,308.160980 
	C325.700684,309.849976 328.228912,310.396393 330.759857,308.744812 
	C333.014374,307.273621 334.105713,305.065247 333.614716,302.331665 
	C333.082062,299.365936 331.095215,297.706329 328.243683,297.212433 
	C323.399414,296.373322 321.233429,299.061493 320.589691,304.129761 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M351.549866,246.793060 
	C352.964783,246.613937 354.011353,246.669037 354.031555,248.056381 
	C354.051727,249.444244 352.985779,249.995560 351.850067,250.421585 
	C348.177673,251.799164 344.510010,253.189392 340.421387,254.732971 
	C343.875122,259.607513 350.731323,259.235504 353.372406,264.260376 
	C351.492157,266.084442 350.188751,265.002747 348.936707,264.362518 
	C345.104614,262.403046 341.299286,260.390564 337.501678,258.364746 
	C336.061371,257.596405 334.313385,256.976715 334.294586,254.960632 
	C334.274231,252.777420 336.245850,252.475739 337.707092,251.904129 
	C342.175476,250.156204 346.694458,248.537735 351.549866,246.793060 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M160.811890,241.110077 
	C161.299911,241.349365 161.462799,241.402130 161.599350,241.494034 
	C163.577408,242.825348 167.004532,242.998154 167.005157,245.977585 
	C167.005905,249.463531 163.315308,249.370316 161.033737,250.212112 
	C156.906265,251.734955 153.109955,254.401672 146.813202,254.534393 
	C150.671753,248.668564 156.939957,249.337921 161.128189,246.064255 
	C157.728027,241.724197 150.833466,242.063187 147.253555,235.982407 
	C153.060059,236.238602 156.536163,239.245422 160.811890,241.110077 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M326.817352,287.466675 
	C325.619629,284.379547 327.543457,284.210052 329.179565,284.926056 
	C336.025238,287.922089 342.773071,291.143158 349.534302,294.329468 
	C350.647980,294.854279 351.572876,295.798248 350.804688,297.107758 
	C349.872955,298.695984 348.493774,297.927429 347.304962,297.364380 
	C340.550232,294.165283 333.809357,290.936951 326.817352,287.466675 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M156.078217,209.000488 
	C153.697220,207.378632 150.029648,207.748367 149.951828,203.885330 
	C154.298157,203.521133 171.550766,211.203293 174.539963,214.731125 
	C172.331329,216.083145 172.331329,216.083145 156.078217,209.000488 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M279.499542,333.697937 
	C280.584930,338.512268 282.838501,342.666840 282.906036,347.760651 
	C280.298096,347.484161 278.213989,347.872681 276.431854,349.463013 
	C275.546814,350.252777 274.233612,350.527802 273.386383,349.272919 
	C272.711029,348.272583 273.169373,347.319702 274.157104,346.786194 
	C278.622253,344.374512 277.945251,340.721588 276.626190,336.886017 
	C276.356964,336.103088 275.908752,335.300995 275.926666,334.516541 
	C275.966064,332.792450 273.394318,330.306213 276.491821,329.506653 
	C278.845612,328.899048 278.692444,331.789856 279.499542,333.697937 
z"
    />
    <path
      fill="#f5f5f5"
      opacity="1.000000"
      stroke="none"
      d="
M225.984314,151.072021 
	C221.121597,155.713638 221.811005,160.783691 223.833130,166.145996 
	C224.359100,167.540802 225.534180,169.691528 223.272125,170.379684 
	C220.984131,171.075729 220.929993,168.580475 220.453949,167.167282 
	C218.849594,162.404449 217.022461,157.677002 216.329865,151.534393 
	C220.513824,154.275482 222.384140,148.493607 225.984314,151.072021 
z"
    />
  </svg>
);




export const GalaxySvg = ({
  className,
  ...rest
}: {
  className?: string;
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    //  xmlns:xlink="http://www.w3.org/1999/xlink"
		className={className||''}
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 1024 1024"
    // enable-background="new 0 0 1024 1024"
    xmlSpace="preserve"
  >
    <path
      fill="#10100F"
      opacity="1.000000"
      stroke="none"
      d="
M412.214813,1024.640991 
	C276.533112,1025.000000 141.066193,1024.984985 5.599375,1025.082153 
	C2.037542,1025.084717 0.891083,1024.575317 0.892450,1020.601440 
	C1.008863,682.184204 1.009541,343.766907 0.887076,5.349662 
	C0.885601,1.273525 2.166296,0.919770 5.648242,0.922572 
	C121.786331,1.016027 237.924515,1.000000 354.531342,1.000000 
	C356.210052,5.900295 353.847290,9.176448 348.900177,9.143279 
	C346.085327,9.124408 343.273865,8.545508 340.066162,9.137966 
	C333.348999,6.764215 327.837830,10.540796 322.070099,11.796950 
	C319.317474,12.396441 316.729950,14.290273 313.669952,14.535679 
	C311.599426,14.701732 309.922760,15.815064 308.338867,17.073824 
	C307.011505,18.128736 305.499695,19.260918 303.819214,18.808119 
	C298.948914,17.495821 295.525574,20.091059 292.044006,22.720163 
	C289.162354,24.896246 286.188019,26.336983 282.338623,25.242304 
	C279.293793,24.376423 277.274017,26.512682 275.699951,28.689894 
	C273.950684,31.109526 271.470337,32.122364 268.991241,33.524117 
	C262.371490,37.267071 255.610855,40.871159 249.427429,45.280632 
	C245.831848,47.844685 243.038162,48.782200 239.135757,46.149738 
	C235.998245,44.033257 232.786133,43.956039 229.916443,47.425980 
	C227.083893,50.851002 225.013062,50.842491 218.480713,48.527245 
	C220.635086,51.531902 220.195740,53.841000 217.193939,55.799866 
	C211.871078,59.273361 209.665283,65.090691 207.393845,70.501022 
	C205.864777,74.143112 204.536301,77.911758 202.643738,81.814728 
	C197.300827,89.116150 191.247238,86.657059 185.541428,83.338150 
	C182.044693,81.304192 179.810181,81.791412 177.509460,85.219986 
	C171.688690,85.978706 169.354874,89.633636 168.233795,94.922913 
	C167.827927,95.928902 167.438644,96.535072 166.794678,97.400787 
	C161.839584,103.361557 156.430206,104.178970 149.988037,100.001106 
	C148.040115,98.737854 146.555008,99.375481 145.132019,100.605080 
	C143.885590,101.682114 143.195602,103.232285 142.084229,104.893951 
	C140.976913,106.407784 139.911774,107.458488 138.345840,108.526527 
	C137.551743,108.918289 137.073730,109.072006 136.217743,109.237846 
	C129.452362,108.954124 123.136566,110.558228 116.555313,108.724129 
	C112.977974,107.727173 108.706238,110.519127 104.650925,111.181519 
	C104.650925,111.181519 100.485519,123.578880 100.485519,123.578880 
	C100.786102,124.189255 101.253174,124.667191 101.764359,125.091713 
	C104.669861,127.504684 104.256119,129.901688 101.680672,132.211014 
	C99.568039,134.105362 99.148575,136.461533 99.785782,139.037933 
	C100.948135,143.737656 99.969688,148.307343 99.044350,152.865189 
	C98.463150,155.727875 97.139938,158.093948 94.185173,159.356003 
	C91.059502,160.691071 89.913818,164.351990 86.838974,165.918350 
	C85.596230,166.551407 86.257004,167.924362 86.734962,169.043869 
	C89.648766,175.868729 88.462341,177.617279 80.817871,177.875443 
	C80.154907,177.897842 79.491486,177.959045 79.037598,178.729614 
	C79.443909,180.900436 81.679131,181.463989 82.980080,182.874100 
	C85.845360,185.979767 85.317810,189.038055 81.345757,189.988541 
	C78.366203,190.701538 76.115005,191.687531 74.989517,194.754807 
	C73.992752,197.471283 71.483826,196.622284 68.907791,196.611923 
	C70.442970,198.773438 72.655396,200.460510 71.747726,203.213013 
	C70.909035,205.756332 68.945824,207.405273 66.679642,208.458328 
	C63.244678,210.054474 63.481804,212.950912 62.544502,216.008209 
	C60.809566,221.667267 65.086617,226.849503 62.547329,232.390503 
	C61.642776,234.364349 63.315437,237.264511 63.983589,239.445724 
	C65.978371,245.957687 61.887119,251.466919 62.068645,257.562164 
	C62.154362,260.440399 59.738293,261.369385 57.552563,262.231354 
	C54.786079,263.322388 53.276871,264.464447 56.755779,266.690643 
	C59.636673,268.534119 59.597183,271.319397 58.731770,274.563721 
	C58.428349,275.076691 58.278538,275.264008 57.845863,275.672089 
	C55.906410,276.675415 54.347996,277.699097 52.642010,278.325928 
	C50.319561,279.179291 48.960964,280.218597 49.836182,283.044861 
	C50.731316,285.935394 47.365726,287.682495 47.439758,290.797058 
	C47.356907,292.067932 47.282516,292.991302 46.938614,294.225983 
	C46.414791,295.714691 45.050175,296.805756 46.835136,298.348450 
	C48.719803,300.965424 48.334690,303.258118 46.861225,305.529144 
	C44.086590,309.805573 44.063240,314.070679 47.127338,317.967010 
	C48.652073,319.905884 48.827374,321.711884 48.075996,323.942993 
	C46.971596,327.222290 44.900860,329.706940 42.221043,331.755829 
	C38.966438,334.244232 36.927296,336.941101 38.630726,341.480560 
	C39.720028,344.383484 37.690536,347.241119 36.964584,350.465149 
	C36.193268,352.148285 35.159531,353.158905 33.749031,354.293243 
	C30.866613,355.498901 29.320654,357.597778 27.714960,360.095215 
	C26.312256,361.581024 25.397026,362.945343 25.494122,365.100342 
	C25.942614,367.031403 26.278364,368.640259 26.086128,370.662537 
	C25.036879,373.655304 24.100626,376.270813 24.882753,379.199341 
	C25.433132,381.260162 24.706326,383.092163 23.385878,384.730774 
	C22.773952,385.490143 22.018583,386.425079 22.789984,387.371460 
	C23.716093,388.507660 24.931978,387.925903 25.954388,387.384186 
	C27.706892,386.455536 29.413986,385.437195 31.257221,384.693237 
	C32.895401,384.032104 34.537209,383.892578 35.805580,385.381470 
	C37.196888,387.014679 36.342754,388.477325 35.184052,389.786743 
	C33.978237,391.149475 32.857746,392.580597 31.418941,394.211334 
	C29.924168,395.343048 28.372757,395.615387 27.140917,396.450714 
	C22.945068,399.295807 20.045235,402.500519 18.936365,408.138794 
	C17.462206,415.634430 17.965443,423.776489 13.078233,430.543213 
	C11.605942,432.581726 11.307585,435.683411 12.098475,438.298645 
	C14.327229,445.668488 16.041590,453.094452 16.104033,461.125244 
	C17.048513,462.348511 17.443993,463.490997 18.339046,464.747925 
	C19.756626,468.052979 16.974386,469.785828 16.186108,472.501190 
	C15.293132,475.028809 15.933942,477.268127 15.954880,479.821655 
	C16.111275,480.820190 16.167322,481.491089 16.228333,482.497620 
	C16.231384,483.271881 16.192308,483.708832 16.057018,484.456207 
	C15.662286,485.926941 14.768995,486.918549 15.019729,488.546234 
	C15.223464,489.955811 15.204880,491.054749 15.099325,492.494751 
	C14.828792,494.686035 15.236186,496.506958 15.350327,498.693420 
	C14.964550,501.796326 16.283619,504.051514 17.564278,506.686432 
	C16.144350,511.957733 16.209141,516.519653 18.146946,521.638733 
	C19.473602,525.143372 17.647203,529.691833 17.278200,533.778259 
	C17.176052,534.909485 16.921251,536.043213 16.772837,537.189026 
	C16.148943,542.005554 17.088936,546.626099 18.817934,551.004028 
	C19.847761,553.611633 19.651011,555.794495 18.402500,558.440491 
	C17.395411,560.675110 18.433962,562.675537 17.973843,564.938049 
	C17.835472,565.466492 17.763584,565.671936 17.549637,566.166382 
	C16.765686,567.341003 16.408253,568.248291 17.357119,569.527832 
	C17.602259,570.019897 17.685583,570.226807 17.844284,570.764465 
	C17.964102,571.551880 17.965410,572.008667 17.878120,572.809692 
	C17.632702,574.055847 17.368380,574.947266 17.384417,576.238403 
	C17.382509,577.090942 17.540150,577.758301 17.268366,578.058350 
	C13.099786,582.660278 15.205663,588.575684 14.050417,593.802307 
	C13.078644,598.198914 15.838516,602.119019 17.754366,605.906189 
	C18.978901,608.326843 19.503305,610.391113 19.510324,613.243896 
	C19.520504,617.381897 21.805046,621.384644 23.960833,625.090149 
	C26.166374,628.881042 27.813595,632.591431 23.959137,636.352051 
	C22.904530,637.380981 23.462915,638.426270 23.878464,639.550232 
	C25.641804,644.319580 26.435074,648.863464 25.929585,654.324524 
	C25.443092,659.580322 26.526129,666.383240 32.524178,669.996887 
	C33.945347,670.853088 35.294010,671.832153 36.965332,672.986328 
	C39.114956,675.200745 41.420902,676.790833 42.839378,679.627686 
	C43.421089,683.369507 41.796371,686.488342 41.608574,689.724731 
	C42.298756,691.134644 43.380623,690.381348 44.279125,690.322937 
	C47.682846,690.101990 48.284340,692.005920 47.659065,694.776550 
	C47.401325,695.918640 46.776833,696.947205 46.117496,697.902527 
	C43.598606,701.552307 43.762657,704.948792 46.646568,708.389954 
	C48.921078,711.104004 50.156322,714.104370 47.631409,717.460205 
	C47.251614,717.964905 47.179615,717.451782 47.429054,717.853760 
	C51.884377,725.034668 44.406620,732.238159 47.552975,739.492615 
	C48.668724,742.065125 50.465412,743.906494 52.187523,745.901306 
	C57.417385,751.959290 57.428040,753.408325 51.719009,758.890381 
	C50.264065,760.287537 50.586105,761.083435 52.116272,761.857300 
	C54.496838,763.061340 56.622627,764.700684 59.054024,765.824402 
	C60.760895,766.613281 63.085644,768.125977 62.420357,769.674744 
	C60.539917,774.052246 61.524426,779.058716 59.241684,783.124573 
	C57.903961,785.507324 57.539654,786.718872 60.197018,788.242310 
	C64.308434,790.599243 64.419701,795.927856 64.073990,798.909668 
	C63.421997,804.533142 67.135384,806.998169 69.448242,810.406250 
	C72.541267,814.963928 72.888008,815.143066 68.056488,818.224670 
	C66.235573,819.386047 66.616760,820.560120 67.072632,822.013550 
	C67.534630,823.486572 68.014244,825.003906 70.070580,824.562927 
	C71.908501,824.168823 73.703033,824.216980 75.492477,824.793884 
	C80.588875,826.436829 82.245102,830.258301 79.453682,834.781616 
	C77.967033,837.190674 77.379356,839.716980 78.905235,841.796387 
	C81.686684,845.586914 83.240845,850.421143 88.006767,852.658875 
	C91.411575,854.257507 92.608246,856.796570 90.333740,860.370056 
	C89.294693,862.002563 87.589523,863.896423 88.753960,865.835144 
	C90.096924,868.071106 92.350891,866.538330 94.223190,866.173950 
	C97.011909,865.631165 98.823051,866.583618 99.242950,869.510864 
	C99.596169,871.973145 100.135902,874.440002 99.504143,876.946350 
	C97.611038,884.457092 100.165573,891.304871 102.875290,898.454712 
	C103.753105,899.965393 104.770119,900.790588 106.128914,901.050537 
	C113.671082,902.493347 121.247299,903.189514 128.818909,901.376221 
	C131.585144,900.713867 134.328262,900.496216 137.506973,900.772583 
	C139.372925,901.140442 140.781326,901.658936 142.265030,902.867310 
	C144.165482,905.695801 145.706711,908.343994 148.559814,910.363037 
	C150.806595,911.901184 152.708511,910.996887 154.589966,910.406860 
	C158.823990,909.078979 162.520844,910.094543 164.417145,914.038818 
	C168.532822,922.599365 175.237213,923.593872 183.592499,922.030090 
	C193.431519,920.188721 203.344666,928.667419 204.688019,939.913513 
	C205.047058,942.919312 206.097641,945.100830 207.974976,947.405090 
	C211.099884,951.240845 214.291901,955.229614 212.539734,960.931824 
	C211.763321,963.458496 213.371078,965.888550 215.808914,965.192322 
	C221.244110,963.639893 226.573776,964.982971 232.239899,964.517761 
	C234.970062,962.927673 237.490326,961.785278 240.565384,961.015625 
	C245.040970,960.671387 247.370148,963.467834 249.290680,966.325500 
	C251.910736,970.224121 255.323273,972.313599 259.925629,972.745667 
	C262.655121,973.001892 264.945770,974.251282 266.997009,976.076904 
	C271.905060,980.445251 277.499237,983.548828 283.967987,984.961670 
	C285.622772,985.323120 287.037903,986.178772 288.392670,987.154541 
	C289.377899,987.864197 290.521057,988.453491 291.608765,987.677979 
	C295.117828,985.176147 297.420776,987.057434 299.634552,989.657654 
	C300.606049,990.798706 301.714417,992.113098 303.143677,992.342896 
	C310.287903,993.491699 316.048340,998.239441 323.364380,999.882324 
	C324.859802,1000.348938 326.028137,1000.634705 327.541016,1001.027954 
	C328.565277,1001.380188 329.192352,1001.728516 330.084229,1002.330566 
	C333.406036,1006.097595 336.989441,1007.462769 341.796021,1005.219482 
	C342.850281,1004.979248 343.567505,1004.961731 344.648010,1005.053467 
	C350.166473,1004.927063 352.727936,1009.566650 357.145905,1011.476318 
	C358.023773,1011.467896 358.532257,1011.470154 359.438049,1011.524170 
	C366.237976,1010.577576 372.111847,1011.485229 378.171234,1014.334412 
	C384.001923,1017.076050 390.852814,1016.776306 397.293640,1017.356812 
	C402.103394,1017.790283 406.220825,1019.852356 410.496063,1021.642700 
	C411.591034,1022.101257 412.467651,1022.903564 412.214813,1024.640991 
z"
    />
    <path
      fill="#10100F"
      opacity="1.000000"
      stroke="none"
      d="
M678.756470,1.302561 
	C792.972046,1.000000 906.944031,1.016194 1020.916016,0.917613 
	C1024.156006,0.914810 1025.104736,1.318279 1025.103638,4.914167 
	C1024.992188,343.664337 1024.991211,682.414551 1025.111206,1021.164673 
	C1025.112549,1024.922119 1023.912598,1025.071167 1020.837158,1025.069336 
	C884.537231,1024.989136 748.237305,1025.000000 611.468628,1025.000000 
	C610.018799,1020.228577 614.060303,1020.257446 616.535034,1020.081116 
	C625.749390,1019.424744 634.449890,1016.375610 643.449036,1014.754333 
	C651.076538,1013.380127 658.335388,1009.734802 666.374207,1011.127258 
	C669.752380,1011.712402 672.579468,1010.751709 675.347595,1009.006836 
	C676.797180,1008.093079 678.010071,1007.062866 678.725708,1005.463623 
	C680.134460,1002.315369 682.189026,1001.608643 684.986877,1003.906006 
	C688.183228,1006.530579 690.785828,1006.477417 695.141174,1004.827576 
	C704.198669,1001.396667 712.188965,995.527954 721.818848,993.499695 
	C724.566895,992.921021 726.287354,990.036011 729.499939,989.193176 
	C730.911865,988.803162 731.962585,988.785828 733.404175,988.981812 
	C737.346985,990.767090 740.437073,990.597778 743.010498,987.144775 
	C744.757568,984.800476 747.347839,983.540710 750.128418,982.822876 
	C752.582520,982.189514 754.868652,981.089294 756.799011,979.619690 
	C759.932861,977.233643 763.240295,975.633911 767.157959,975.158325 
	C769.328613,974.894775 771.308655,973.986816 772.965454,972.496704 
	C775.695129,970.041809 778.994995,968.345520 781.789856,966.017029 
	C785.924377,962.572449 790.337280,961.905884 795.620605,963.821289 
	C798.579529,964.896667 800.736084,965.252991 802.991943,962.569031 
	C805.367554,959.742676 808.974365,959.971558 811.645020,962.971497 
	C814.515137,966.195557 817.206848,965.670349 820.456848,963.814697 
	C819.285583,962.150940 817.938110,963.147644 816.763794,963.130005 
	C814.817383,963.100769 812.406982,963.727539 811.683289,961.257568 
	C811.024902,959.010742 812.867371,957.406494 814.595215,956.545532 
	C819.483276,954.109680 822.145325,949.696960 824.974548,945.445679 
	C827.640686,941.439514 828.554321,936.531128 831.411255,932.214905 
	C831.549988,931.410522 831.603333,930.944519 831.702393,930.124084 
	C834.971313,921.690979 837.536438,920.075256 846.489441,920.568848 
	C847.273987,920.332458 847.734680,920.287048 848.534485,920.383179 
	C850.744019,921.476013 852.416748,922.963684 855.043030,922.986694 
	C856.249756,922.873291 857.131836,922.882080 858.345886,922.978149 
	C860.642334,923.407654 862.074219,922.588440 863.428345,920.794983 
	C864.386414,919.959595 865.217407,919.560974 866.352051,918.998169 
	C870.351074,916.126526 874.069946,913.443420 877.418701,909.770020 
	C878.737366,908.542847 879.966980,907.811279 881.588379,907.040283 
	C886.288147,904.782715 890.335754,904.017395 893.700684,908.823303 
	C894.813354,910.412354 896.690857,909.799866 898.264893,908.978699 
	C900.214050,907.961670 901.666260,906.337830 903.327209,904.962280 
	C907.175659,901.775208 911.221863,899.202515 916.448792,902.013855 
	C916.887085,902.249451 917.572327,902.644226 917.834534,902.442139 
	C922.049866,899.192993 927.536865,901.963257 932.140137,899.200562 
	C935.137146,895.310486 934.043091,890.695923 935.512939,886.349121 
	C935.627625,885.516235 935.710754,885.043884 935.903687,884.228149 
	C937.055298,880.579285 936.854248,877.453613 934.532104,874.273560 
	C934.094421,873.243713 934.030640,872.514954 934.201904,871.414429 
	C936.574585,866.438049 940.738342,866.518311 944.953613,867.260254 
	C945.289307,865.098511 943.760864,864.171814 943.302612,862.790771 
	C942.821350,861.340088 942.749573,859.883728 942.787476,858.370422 
	C942.884277,854.500793 947.327087,854.048767 948.423035,851.198181 
	C949.574890,848.202209 950.474731,845.527100 954.485046,846.093506 
	C956.196594,846.335205 956.735596,845.136902 956.998108,843.669678 
	C957.520691,840.749207 958.671692,837.781067 956.000977,835.244263 
	C954.672852,833.982788 952.995422,833.143250 951.768494,831.767273 
	C950.857605,830.745850 950.122437,829.615479 950.697388,828.164612 
	C951.251770,826.765808 952.512573,825.847412 953.828125,826.193542 
	C958.276428,827.363770 962.237366,824.330261 966.581909,824.960449 
	C968.084961,825.178406 969.334778,824.815002 969.200439,822.940125 
	C969.097473,821.502441 968.432373,820.382202 966.814758,820.218262 
	C965.804443,820.115906 964.854065,820.543579 963.864990,820.648804 
	C962.063416,820.840454 960.523682,820.332886 959.635071,818.679810 
	C958.821716,817.166626 959.395203,815.727173 960.359192,814.510010 
	C962.214722,812.166992 963.881897,809.519287 966.056335,807.614746 
	C971.078674,803.215759 970.572266,798.072876 968.920044,792.689453 
	C968.102112,790.024353 968.389221,788.136780 970.975586,786.889954 
	C973.550781,785.648499 973.602112,783.691895 972.489807,781.478455 
	C970.810913,778.137451 970.215759,774.164307 966.453186,772.046204 
	C964.940247,771.194519 964.729126,769.296082 964.802856,767.198975 
	C965.069580,765.511963 965.806641,764.498474 967.051941,763.370850 
	C976.776001,759.089783 977.532959,749.808167 980.264526,741.723633 
	C981.092896,739.272034 979.209106,737.281616 979.070190,734.631104 
	C979.492920,732.222717 980.487732,730.270630 977.034180,729.473694 
	C974.266846,728.835083 975.031128,726.328064 976.133545,724.619019 
	C978.357666,721.171143 980.253601,718.338440 976.585388,714.170837 
	C974.363953,711.647034 978.090332,709.742249 980.184875,707.759949 
	C983.788086,705.424683 983.798218,702.690247 982.012268,699.557800 
	C981.046631,697.864075 980.048584,696.180664 979.247803,694.005371 
	C979.023376,692.944580 979.064941,692.237793 979.450684,691.232300 
	C980.751465,689.717529 982.311829,690.106689 984.391602,689.717834 
	C981.047607,687.275635 981.994446,684.324768 982.878418,681.054810 
	C983.269775,680.040039 983.668152,679.437927 984.399658,678.624146 
	C989.983459,675.909485 993.993408,671.634460 998.684082,668.310181 
	C1002.057190,665.919556 999.031982,662.506653 1000.638367,660.121765 
	C1002.358459,657.568115 1004.343079,655.408569 1001.452454,652.302246 
	C999.086670,649.759949 999.831970,647.184753 1002.164795,644.848938 
	C1004.957153,642.052979 1007.671936,639.212830 1003.825989,635.176270 
	C1003.000732,634.310181 1003.053467,632.872437 1003.505859,631.824829 
	C1005.717957,626.701599 1006.753540,621.190613 1008.970947,616.051758 
	C1011.254639,610.759583 1011.748230,605.000427 1012.547913,599.309265 
	C1013.678711,591.262634 1012.123352,583.670288 1009.827271,575.762695 
	C1009.927490,575.198486 1009.983826,574.979065 1010.162903,574.450806 
	C1010.564758,573.580505 1010.901062,573.044189 1011.069092,572.123291 
	C1011.117981,571.430237 1011.144653,571.035583 1011.204285,570.351074 
	C1011.282349,569.490112 1011.320618,568.919800 1011.117798,568.075562 
	C1010.686951,566.848816 1010.114746,566.027344 1009.432190,564.900146 
	C1009.107422,563.961182 1009.099731,563.308044 1009.356995,562.330566 
	C1012.655762,558.945374 1009.196289,556.788452 1008.329834,553.918701 
	C1008.066956,552.077148 1008.556702,550.725037 1009.332031,549.089111 
	C1012.085205,547.043518 1012.752991,544.940979 1012.216003,541.684509 
	C1011.480164,537.221863 1011.055725,532.661865 1010.477966,527.845154 
	C1009.872498,526.990601 1009.539246,526.378052 1009.148926,525.403015 
	C1008.616028,522.974548 1009.066895,521.142822 1010.743835,519.731689 
	C1011.969604,518.700256 1012.745117,517.551331 1012.013184,515.546875 
	C1011.419922,512.961060 1013.428223,510.341522 1010.671631,508.188049 
	C1009.594421,507.452118 1008.920410,506.795074 1008.304443,505.614868 
	C1008.114014,505.042175 1008.064331,504.805359 1008.005188,504.206665 
	C1008.126892,501.982056 1009.494263,500.967010 1010.963989,499.616211 
	C1012.941223,496.942139 1008.830261,494.928314 1010.810913,492.179749 
	C1010.992310,491.681091 1011.073364,491.491272 1011.281372,491.040527 
	C1011.542725,490.327850 1011.897583,489.845978 1010.950317,489.456818 
	C1009.810730,489.326782 1008.950317,489.296295 1007.739746,489.108063 
	C1004.902161,487.911041 1004.612488,486.283722 1006.561035,483.995911 
	C1012.190857,482.734192 1012.562561,482.094421 1009.674561,478.292023 
	C1009.122437,477.381836 1008.970337,476.698853 1008.956421,475.634094 
	C1009.022888,475.057343 1009.071289,474.831512 1009.241882,474.286255 
	C1010.086426,472.872437 1010.789734,471.771271 1010.650635,470.040161 
	C1010.492615,467.539246 1011.468201,465.315430 1010.225342,462.859680 
	C1009.719482,461.372314 1009.628967,460.170532 1009.585693,458.597961 
	C1009.622192,456.734039 1009.298279,455.020264 1010.070557,453.801758 
	C1014.744263,446.427979 1012.250610,437.874573 1014.158691,430.065247 
	C1014.396729,429.091034 1014.526184,428.044922 1013.823853,427.187317 
	C1008.384521,420.545013 1009.824646,412.565094 1009.637329,404.963165 
	C1009.538940,400.970825 1007.322998,398.795563 1004.160400,397.510376 
	C1001.654541,396.492035 999.366577,395.234802 996.858032,393.523865 
	C996.335266,393.186371 996.127014,393.046997 995.627197,392.666626 
	C992.899658,389.426788 988.126892,387.856842 989.536743,382.310547 
	C992.647766,379.897736 994.777222,381.512268 996.589844,383.641266 
	C999.119080,386.612061 1002.259583,387.249084 1006.353271,386.829865 
	C1004.751831,383.838562 1003.657471,381.171082 1004.572632,378.040100 
	C1005.004456,376.562958 1003.816467,375.223602 1002.950928,373.993164 
	C1002.182007,372.900238 1001.223450,371.974915 1000.322510,370.996948 
	C998.936035,369.491943 997.584167,367.831055 998.550842,365.676788 
	C999.595581,363.348816 1001.860291,365.191772 1003.530945,364.635498 
	C1003.811096,362.428497 1001.923035,361.477203 1001.057861,359.972504 
	C996.609924,352.236969 991.743408,344.917603 984.243347,339.652618 
	C982.244507,338.249481 981.743286,335.800110 983.236084,333.383057 
	C984.175110,331.862579 983.960388,330.867279 982.170654,329.830078 
	C977.615845,327.190521 975.992126,322.473022 978.044189,317.624969 
	C979.039307,315.274170 979.434143,313.285370 977.990112,311.073029 
	C977.134766,309.762604 976.650574,308.079895 977.599365,306.820129 
	C980.558105,302.891571 979.416260,299.024811 977.828918,294.736755 
	C977.826782,293.939758 977.883789,293.492889 978.085144,292.741333 
	C978.780701,291.332458 979.315979,290.222015 978.813599,288.598694 
	C978.879761,285.794830 975.557251,283.076508 979.354187,280.750977 
	C979.881775,280.427856 978.626465,279.511078 978.010010,279.045929 
	C976.678162,278.040924 975.065918,277.465485 973.763611,276.426300 
	C969.842651,273.297424 970.065552,269.264099 974.361206,266.420593 
	C972.375305,264.395599 970.792114,262.040192 968.744568,260.088531 
	C966.822388,258.256409 965.642029,256.191223 966.437500,253.411667 
	C966.868103,251.906876 966.607788,250.446686 966.004639,249.025421 
	C965.679688,248.259674 965.051819,247.345779 965.313538,246.670410 
	C966.849060,242.708237 967.656067,238.910675 965.529968,234.769699 
	C964.915649,233.573349 966.615051,232.043549 968.022400,231.411133 
	C970.719177,230.199295 971.772400,228.763077 969.373169,226.234070 
	C968.109070,224.901642 967.864197,223.247421 968.763184,221.610062 
	C970.353577,218.713562 968.641846,217.218536 966.399109,216.094330 
	C963.176331,214.478836 961.200439,212.241730 960.982422,208.405151 
	C960.808289,205.340027 958.251526,203.167496 957.141541,200.383377 
	C956.517090,198.817215 954.808716,199.665604 953.546143,199.859467 
	C950.753479,200.288239 948.485229,199.494171 947.468872,196.740875 
	C946.481812,194.066925 948.279480,192.366043 950.116821,190.823196 
	C951.660461,189.526947 953.689758,189.021896 955.239014,187.738647 
	C955.886719,187.202057 956.512817,186.515228 956.213257,185.606964 
	C955.902466,184.664932 954.978943,184.633820 954.138428,184.629730 
	C953.639221,184.627304 953.143005,184.706543 952.649902,184.786789 
	C950.272522,185.173752 947.835022,185.445084 946.515198,182.845993 
	C945.326782,180.505554 946.560852,178.723633 948.302856,177.164902 
	C949.026123,176.517776 950.194519,175.348419 949.303345,174.714508 
	C946.131653,172.458405 945.539001,167.260437 940.849121,166.955795 
	C936.674011,166.684586 935.356689,163.896194 935.161560,160.629364 
	C934.731018,153.420181 932.509521,146.236572 935.302673,138.925919 
	C936.349976,136.184692 935.445801,133.162613 931.764954,132.390854 
	C928.769104,131.762695 927.300964,130.103745 928.173523,127.062050 
	C929.209045,123.452652 931.867981,124.679214 934.433411,125.516953 
	C935.296021,123.305206 933.956787,121.928406 933.001282,120.489563 
	C932.169922,119.237755 930.945007,118.000946 931.236450,116.469513 
	C932.264038,111.068634 928.969116,111.421341 925.322815,111.539566 
	C921.226501,111.672386 917.214172,111.314903 913.100037,112.560738 
	C910.563965,113.328697 907.326721,112.591179 905.342346,110.183258 
	C904.135437,108.718681 902.644348,108.394020 900.685242,107.979797 
	C899.499146,107.521851 898.810242,106.934448 897.910645,106.111069 
	C897.114014,105.417229 896.562500,104.861282 895.529114,105.693604 
	C894.664673,106.519638 893.997375,107.100914 892.905945,107.712135 
	C888.241028,109.172203 884.951294,107.110191 881.510254,104.360245 
	C880.029419,103.500053 878.865234,102.795769 877.395508,101.953896 
	C876.401550,101.253983 875.831360,100.577461 874.928955,99.772995 
	C874.526733,99.390785 874.369507,99.231689 873.994202,98.814507 
	C870.795776,95.557350 867.640869,92.752060 864.321838,90.135536 
	C862.254883,88.506088 860.065552,87.711166 857.333740,89.652191 
	C855.928284,90.307381 854.780151,90.645515 853.320801,91.189468 
	C852.010376,91.557121 851.093506,91.964287 850.121033,92.196869 
	C841.419617,94.277885 837.989380,92.525421 834.720032,84.178055 
	C834.043213,82.450058 833.493164,80.523735 831.984009,79.510658 
	C826.845703,76.061508 825.279785,70.647827 823.718872,65.212997 
	C822.760315,61.875488 821.078125,59.021687 818.071228,57.287910 
	C815.153320,55.605412 815.040344,52.270847 813.405273,50.227409 
	C811.889404,48.332970 808.209839,52.232597 805.114990,50.978249 
	C803.617249,50.371197 802.462646,49.510948 802.016357,47.984844 
	C800.945557,44.322689 798.530273,43.990364 795.977661,46.000446 
	C791.463806,49.554863 787.664490,48.934521 782.853577,46.057056 
	C778.428162,43.410191 772.996277,42.692581 768.267456,40.075550 
	C763.451660,37.410374 759.603882,32.835655 753.573669,32.280224 
	C752.012695,32.136448 751.032104,30.256069 750.106323,28.857931 
	C747.691406,25.210604 744.282471,23.973763 740.161377,25.141926 
	C737.724670,25.832651 735.619751,25.788830 733.615723,24.136421 
	C732.297241,23.049351 730.710205,22.562326 729.021790,22.368883 
	C727.691528,22.216465 725.965759,22.186701 725.334229,21.110415 
	C721.620422,14.781270 714.386780,15.989811 708.669800,13.147748 
	C707.344849,12.780780 706.390686,12.472665 705.057129,12.088217 
	C703.961975,11.676837 703.310364,11.257352 702.415771,10.501272 
	C700.750488,8.163641 698.706055,7.527390 696.488708,8.211868 
	C693.472839,9.142832 690.330505,9.373325 687.307129,10.281103 
	C681.100647,12.144612 677.215637,8.224228 678.756470,1.302561 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M730.127380,989.642334 
	C727.913330,991.302856 725.680969,992.376343 724.082092,994.368713 
	C723.357117,995.272278 722.433105,996.078125 721.068237,995.508484 
	C718.635803,994.493530 716.885315,995.886536 715.250305,997.306580 
	C713.431519,998.886108 711.520447,1000.132141 709.015991,999.988159 
	C706.978577,999.871094 705.452820,1000.689514 704.377563,1002.352112 
	C703.177612,1004.207581 701.457581,1005.017700 699.317566,1004.983093 
	C696.918640,1004.944336 695.455933,1005.737854 693.573059,1007.706299 
	C690.515625,1010.902466 686.760803,1007.621643 684.658813,1005.566833 
	C681.576294,1002.553772 680.771545,1002.374634 679.906799,1006.745972 
	C679.244690,1010.092834 671.454346,1013.470947 667.101013,1012.875488 
	C661.253235,1012.075623 655.741455,1012.795044 649.907104,1014.604736 
	C640.326233,1017.576538 630.263672,1019.020996 620.375061,1020.961060 
	C617.089966,1021.605530 613.360229,1020.780762 611.052124,1024.626221 
	C596.979126,1025.000000 582.958191,1025.000000 568.468628,1025.000000 
	C566.962219,1023.844666 567.459778,1022.678711 568.170288,1021.188354 
	C568.526001,1020.671204 568.687500,1020.478333 569.131592,1020.037903 
	C575.078430,1015.721558 581.160828,1014.972473 587.695435,1017.387451 
	C592.030334,1018.989563 596.440308,1018.433228 600.072937,1015.995789 
	C602.983826,1014.042603 605.855164,1012.640381 609.228088,1012.746399 
	C614.949951,1012.925964 619.455078,1011.148621 622.525757,1005.675415 
	C623.741760,1004.989746 624.710815,1004.891235 626.056946,1005.077515 
	C628.975708,1007.082092 631.281372,1006.049927 633.855164,1004.368347 
	C634.844604,1004.034424 635.534790,1003.985596 636.581543,1004.055298 
	C641.144836,1004.863037 645.437134,1006.346008 649.495239,1002.610352 
	C650.256775,1002.324524 650.713440,1002.261230 651.516357,1002.343140 
	C653.968445,1003.705505 656.268494,1004.239502 659.073975,1004.017639 
	C660.336182,1003.854309 661.259277,1003.869873 662.519958,1004.132446 
	C663.974060,1004.597900 664.846008,1005.465942 666.329468,1005.869629 
	C667.855225,1006.410583 668.746948,1007.172729 669.748352,1005.193909 
	C673.892090,997.005493 676.868347,995.577026 686.105347,997.592163 
	C690.421875,998.533936 693.450500,996.500977 697.166748,995.086609 
	C701.958557,993.262756 702.871887,987.928162 707.559631,986.305115 
	C712.098877,984.733643 716.668213,983.338928 721.652954,982.043457 
	C726.293823,982.715088 728.780884,985.417419 730.127380,989.642334 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M412.468658,1025.000000 
	C408.990601,1021.086792 404.225189,1020.232056 400.033691,1019.239258 
	C393.853058,1017.775146 387.348297,1017.683838 380.983887,1016.986389 
	C380.009613,1016.879578 378.783508,1016.979858 378.122192,1016.437622 
	C372.635132,1011.938843 366.304596,1012.257507 359.433716,1012.991089 
	C358.278870,1010.945679 359.698212,1009.367249 360.357117,1007.274536 
	C360.718872,1006.799561 360.893433,1006.633301 361.380280,1006.285217 
	C363.048065,1005.650635 364.410431,1005.214417 365.985260,1004.337769 
	C367.059998,1004.052979 367.785400,1004.074219 368.837769,1004.416626 
	C370.113373,1005.194885 370.962341,1006.776245 372.028442,1006.270691 
	C375.985443,1004.394287 378.678284,1006.452271 381.442230,1008.744873 
	C383.643799,1010.571045 385.998627,1010.438721 388.415436,1009.108337 
	C390.770996,1007.811646 393.436615,1007.210938 395.326691,1009.400635 
	C399.353729,1014.065979 405.094147,1013.700256 410.084442,1013.972168 
	C414.538940,1014.214905 418.957336,1014.856018 423.762817,1015.057556 
	C424.591827,1015.091919 425.056061,1015.177246 425.824463,1015.477295 
	C427.935944,1018.345032 430.447815,1019.411804 433.976746,1018.794434 
	C439.759888,1017.270508 445.437531,1018.153931 450.934723,1017.057495 
	C452.200256,1016.804993 453.569153,1016.964233 455.184601,1016.366150 
	C455.776886,1016.160339 456.019379,1016.088074 456.637878,1015.940796 
	C457.787933,1015.766174 458.558624,1015.794861 459.700867,1016.031921 
	C460.550323,1016.344910 460.970398,1016.632385 461.557861,1017.304199 
	C462.006653,1020.411255 465.420105,1021.507812 465.218170,1024.712402 
	C447.645782,1025.000000 430.291534,1025.000000 412.468658,1025.000000 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M678.531372,1.000000 
	C679.168335,8.980927 680.937866,10.163463 688.814087,8.313755 
	C691.516418,7.679145 694.213196,7.223896 696.884827,6.324384 
	C699.381653,5.483727 701.735474,6.576905 702.964844,9.629467 
	C698.252441,12.744388 692.640747,11.179476 687.339233,13.171121 
	C686.733276,13.421970 686.483032,13.502789 685.841919,13.632711 
	C684.406128,13.675034 683.398987,13.363791 681.976990,13.239250 
	C680.561768,12.944623 679.654968,12.471813 678.618408,11.479933 
	C677.028625,7.836659 674.994995,6.342096 671.471313,8.514875 
	C670.484436,9.123356 669.276123,9.800399 668.200012,9.576280 
	C663.957153,8.692700 660.019470,8.319194 656.324951,11.529362 
	C655.076111,12.614475 653.046814,11.369152 651.885437,10.144873 
	C649.063965,7.170550 646.122864,6.580443 642.047485,7.844078 
	C634.580994,10.159168 628.234436,6.228273 621.906372,2.979844 
	C621.524841,2.783980 621.532654,2.095988 621.786377,1.294687 
	C640.687561,1.000000 659.375122,1.000000 678.531372,1.000000 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M621.531372,1.000000 
	C626.340271,5.655956 638.011292,8.811277 643.076782,6.135391 
	C646.652161,4.246658 649.019653,5.098977 651.314636,7.815545 
	C654.037964,11.039191 656.637451,12.485411 659.242126,7.639941 
	C660.217590,5.825113 661.841736,5.588267 663.401550,6.966527 
	C666.159729,9.403525 668.775330,8.628603 671.557434,6.987311 
	C676.310059,4.183481 676.859009,4.467183 678.998352,10.624609 
	C673.978516,12.308976 668.828491,12.609339 663.677307,12.784928 
	C662.677979,12.818991 661.754211,12.717998 660.613525,13.580133 
	C658.173645,15.019957 655.951782,15.309875 653.244995,14.430339 
	C649.894897,13.584351 647.528442,11.019007 643.808655,11.338372 
	C642.993530,11.277350 642.534546,11.213984 641.748901,11.053446 
	C640.366638,10.687385 639.316956,10.362090 637.885864,10.826298 
	C637.101440,10.964667 636.644348,10.995242 635.833313,10.977446 
	C632.439514,10.695464 629.505493,9.487537 626.026184,10.000271 
	C620.037659,9.544607 614.271057,9.780256 609.008484,7.152361 
	C608.711731,7.004186 608.331543,6.668741 608.118713,6.766008 
	C602.358398,9.398986 597.536255,4.456921 592.061401,4.798067 
	C589.879883,4.934003 587.906067,4.647926 588.771362,1.297909 
	C599.687561,1.000000 610.375122,1.000000 621.531372,1.000000 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M338.769226,8.298985 
	C342.232391,7.581931 345.297974,6.951940 348.740234,7.764638 
	C352.962555,8.761512 354.296387,5.246148 354.993591,1.378339 
	C373.020905,1.000000 391.041779,1.000000 409.531342,1.000000 
	C411.351654,4.276414 409.032135,5.464895 406.706268,5.652056 
	C400.551422,6.147329 394.613800,8.357716 388.108582,6.239436 
	C383.137604,4.620733 380.497772,6.703282 378.056213,11.502001 
	C377.467377,12.154062 377.055450,12.418826 376.221375,12.609759 
	C374.887207,12.550213 374.204163,11.935878 373.279175,11.113871 
	C372.881195,10.767015 372.725800,10.626719 372.333313,10.294652 
	C371.199249,9.472544 370.578583,10.149659 369.581665,10.722702 
	C366.658722,11.616415 364.388214,10.907185 362.060547,9.043929 
	C358.314697,4.238757 356.617615,8.447981 354.149658,10.516987 
	C352.281372,11.531836 350.624359,12.069818 348.489685,12.322672 
	C346.837280,12.447989 345.571869,12.359113 343.935364,12.119310 
	C342.818420,11.896364 342.106903,11.632413 341.008545,11.340256 
	C340.145538,11.112612 339.683807,10.900964 338.976624,10.357449 
	C338.479401,9.612398 338.424011,9.116111 338.769226,8.298985 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M588.550049,1.000000 
	C589.060425,2.544099 589.197571,4.523533 591.335022,3.634865 
	C594.256897,2.420046 596.420471,3.855158 598.841492,4.803791 
	C601.256653,5.750118 603.716919,7.969283 606.128967,4.623837 
	C606.620300,3.942343 607.889709,4.145346 608.375305,4.785579 
	C612.131775,9.737884 618.280823,6.337488 622.710510,8.942300 
	C623.322021,9.301919 624.331909,8.984195 625.576538,8.980349 
	C630.202881,10.016247 634.539490,10.909474 634.228516,17.001621 
	C630.691223,18.427513 627.239075,18.173763 623.438110,18.366722 
	C621.974609,18.312998 620.987366,18.009851 619.794067,17.204468 
	C617.011963,8.986125 609.528503,11.928981 604.261230,10.024414 
	C603.971497,9.919652 603.587341,10.116074 603.271057,10.055885 
	C598.192932,9.089528 593.250061,7.115482 587.945862,9.813393 
	C586.454773,10.571786 584.142334,9.051297 582.115051,7.786954 
	C581.653320,7.392807 581.483154,7.215881 581.099121,6.736145 
	C580.184814,4.889492 578.674072,3.571585 579.767761,1.296153 
	C582.700012,1.000000 585.400024,1.000000 588.550049,1.000000 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M450.741730,1.267684 
	C460.687561,1.000000 470.375122,1.000000 480.531342,1.000000 
	C482.076599,5.375464 477.485260,4.440410 475.653046,6.632525 
	C474.456451,7.528959 473.472198,7.960726 472.004242,8.274895 
	C470.439972,8.433007 469.273987,8.452530 468.112732,8.405149 
	C461.858185,8.149947 461.858521,8.141494 457.720795,12.620717 
	C454.469757,16.140076 445.655487,14.774224 444.115509,10.116875 
	C446.463379,7.136856 448.557098,4.400400 450.741730,1.267684 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M537.767029,1.249193 
	C539.714355,1.000000 541.428711,1.000000 543.571533,1.000000 
	C545.995789,3.763674 548.200623,6.193747 552.358704,6.150203 
	C552.888977,6.390245 553.090271,6.511870 553.544800,6.881805 
	C555.182983,9.998628 556.565979,12.634791 560.666870,11.140869 
	C562.173401,10.592035 563.964905,10.961908 564.556641,13.406073 
	C564.291687,14.817869 563.618408,15.562192 562.492676,16.418596 
	C561.939758,16.717356 561.708191,16.810518 561.116455,16.982971 
	C556.372070,16.540558 552.137512,15.257505 547.669495,14.984097 
	C543.630493,14.736940 542.820068,11.122667 542.325623,7.705975 
	C539.606262,11.044333 538.663879,16.282829 532.504639,15.671440 
	C529.070496,12.789691 526.923706,9.293561 524.542664,5.555512 
	C524.460327,4.967144 524.474365,4.728609 524.638611,4.168578 
	C526.008606,2.913920 527.520691,2.847575 528.391418,3.895630 
	C531.144409,7.209545 533.677917,6.095904 536.423950,3.570873 
	C536.772644,2.615175 537.073425,2.011559 537.767029,1.249193 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M537.703430,3.734049 
	C537.698303,7.238696 534.281555,5.960876 532.623657,7.603157 
	C529.600403,10.597914 529.736572,6.154853 528.429260,5.083109 
	C527.600830,4.403951 526.771973,4.022939 525.366272,4.008848 
	C520.342163,5.809647 515.599976,6.014122 510.438904,4.870435 
	C509.640045,5.055727 509.178680,5.137074 508.365112,5.241330 
	C504.923828,5.137630 502.365540,5.863560 499.903839,8.148261 
	C494.609497,13.062026 491.703583,12.808823 486.091248,7.833689 
	C485.289856,7.123287 484.270447,7.094741 482.960205,6.775673 
	C482.411560,6.528306 482.208313,6.391571 481.851105,5.917746 
	C483.005066,1.047394 486.307434,1.884306 489.925018,2.774530 
	C490.479523,2.997297 490.692902,3.097177 491.204346,3.370458 
	C493.085785,4.501684 494.683197,5.785186 496.141479,3.024026 
	C496.219757,2.271521 496.355377,1.864807 496.779419,1.240002 
	C508.354218,1.000000 519.708435,1.000000 531.531372,1.000000 
	C533.330750,2.886116 535.851379,2.194571 537.703430,3.734049 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M376.966064,12.389725 
	C377.217377,4.904617 382.482391,3.361210 391.068604,5.462835 
	C393.066681,5.951911 394.985474,5.944683 396.946472,5.978637 
	C400.498260,6.040134 403.559875,3.017666 407.470886,4.458210 
	C408.414124,4.805638 409.568604,3.084028 409.959442,1.351537 
	C415.022095,1.000000 420.044189,1.000000 425.533142,1.000000 
	C427.184357,5.219898 424.260712,5.966926 421.372772,5.774013 
	C416.942963,5.478108 412.914093,5.910059 409.341034,8.795616 
	C407.814850,10.028141 405.949615,10.231382 404.213837,9.288158 
	C402.341919,8.270968 400.761810,8.512924 398.936523,10.078943 
	C398.428802,10.432714 398.214630,10.557141 397.656677,10.819368 
	C396.154816,11.344313 394.908997,11.284454 393.395020,11.730321 
	C392.566101,11.860058 392.088989,11.871799 391.260376,11.783385 
	C386.925201,10.631564 382.729004,7.611570 379.157043,13.188761 
	C378.086853,13.571925 377.424805,13.420204 376.966064,12.389725 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M525.284180,1024.757324 
	C518.978882,1025.000000 512.957703,1025.000000 506.468262,1025.000000 
	C502.058075,1019.338806 502.173981,1016.036926 506.809448,1013.013794 
	C507.450623,1012.997681 507.701660,1013.023315 508.302002,1013.163574 
	C510.259918,1014.204346 510.373810,1015.857788 510.858215,1017.424927 
	C511.824432,1020.550842 512.486694,1017.523071 513.536316,1016.965576 
	C516.055481,1015.217407 518.533081,1018.780273 520.964050,1016.164856 
	C521.818481,1014.947388 522.482727,1014.012878 523.485352,1012.893555 
	C523.951233,1012.528564 524.158752,1012.408813 524.710266,1012.177368 
	C525.532898,1011.984192 526.014221,1011.967041 526.848755,1012.080200 
	C527.414001,1012.253845 527.628357,1012.352112 528.105713,1012.680908 
	C530.118469,1015.010437 527.340393,1017.760254 529.509460,1020.142761 
	C530.362549,1023.903320 527.456726,1023.695251 525.284180,1024.757324 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M398.217529,10.225410 
	C399.130890,7.018379 401.437012,6.164330 403.881775,7.705849 
	C406.217133,9.178393 407.648468,8.854681 409.483917,7.083246 
	C412.585785,4.089517 416.636810,3.655753 420.528229,4.466847 
	C423.478394,5.081762 424.930969,4.236808 425.957001,1.360137 
	C430.356903,1.000000 434.713806,1.000000 439.535339,1.000000 
	C441.657776,3.922199 439.615936,4.758211 437.101318,5.698218 
	C435.585571,6.355382 434.383972,6.726775 432.784668,7.002569 
	C430.424408,7.369027 428.262268,4.436738 426.080383,7.534341 
	C425.548218,7.831297 425.320404,7.929637 424.727600,8.128543 
	C420.208618,8.305846 416.116974,9.153027 412.343628,10.651781 
	C407.557526,12.552790 403.079315,12.179387 398.217529,10.225410 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M564.224731,1024.764893 
	C562.933289,1025.000000 561.866638,1025.000000 560.399963,1025.000000 
	C558.461670,1022.784546 555.819397,1023.779663 553.528564,1022.724976 
	C551.483643,1020.407288 553.162964,1019.503479 554.705200,1018.536926 
	C556.441162,1017.448914 557.289612,1016.142334 555.580872,1013.935486 
	C555.426025,1013.096008 555.521606,1012.618835 555.974670,1011.867920 
	C557.405518,1010.779968 558.707642,1010.745056 560.435181,1011.030579 
	C563.737915,1012.843201 567.307373,1013.112000 570.256409,1015.590637 
	C571.108521,1017.612915 570.549805,1019.055542 569.279785,1020.710815 
	C569.028931,1021.021240 569.001404,1021.002441 569.011475,1021.015747 
	C568.524170,1021.484680 567.994019,1021.891968 567.058472,1022.270508 
	C565.567749,1022.653687 565.210876,1023.808838 564.224731,1024.764893 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M465.468658,1025.000000 
	C464.700134,1022.255737 460.535919,1021.940369 461.048737,1018.324219 
	C464.244995,1016.464722 466.255493,1020.230774 469.558533,1019.841492 
	C473.121826,1021.244873 476.334900,1022.571045 479.770691,1019.519714 
	C481.023224,1018.928528 482.009644,1018.863342 483.394958,1018.977539 
	C484.776459,1019.236816 485.745544,1019.619812 487.191864,1019.601562 
	C488.989655,1019.720215 490.316010,1020.005432 491.519623,1021.475708 
	C491.982574,1022.698181 491.918121,1023.520752 491.249054,1024.655518 
	C482.645782,1025.000000 474.291534,1025.000000 465.468658,1025.000000 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M525.467590,1025.000000 
	C524.858093,1022.133118 529.217896,1023.793396 529.125854,1020.576172 
	C529.227539,1020.008423 529.289551,1019.801270 529.538330,1019.329895 
	C530.407166,1018.447571 530.653320,1017.621643 530.916626,1016.421570 
	C531.177979,1015.627136 531.415283,1015.208557 532.005432,1014.588867 
	C533.267639,1013.890747 534.267761,1013.845154 535.647217,1014.219849 
	C537.187561,1015.049438 537.971191,1016.148499 538.075867,1017.438171 
	C538.338867,1020.679871 540.605957,1021.571533 543.519653,1022.395752 
	C544.743530,1024.066650 543.675781,1024.303833 542.313110,1024.776123 
	C536.645081,1025.000000 531.290100,1025.000000 525.467590,1025.000000 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M508.024414,1013.742798 
	C505.018524,1013.962585 504.016632,1015.424927 504.543549,1018.055359 
	C504.957428,1020.121521 505.494049,1022.163086 505.988098,1024.607788 
	C504.611115,1025.000000 503.222198,1025.000000 501.416656,1025.000000 
	C499.333221,1023.407471 497.818604,1021.650146 495.691650,1020.032837 
	C494.674438,1016.561584 493.387787,1013.055786 499.175446,1014.286621 
	C500.308075,1014.527527 500.873444,1013.508301 501.575073,1012.843384 
	C503.991425,1010.553589 506.198578,1010.854553 508.024414,1013.742798 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M561.751221,1.270152 
	C563.066711,1.000000 564.133362,1.000000 565.600037,1.000000 
	C566.956421,2.782032 569.675049,1.619322 570.527283,4.027534 
	C569.785461,6.653283 569.616089,9.253778 566.935181,10.839278 
	C566.111938,11.011470 565.637329,11.019530 564.828613,10.880386 
	C563.678284,10.520089 562.942749,9.986088 561.698608,10.037080 
	C560.683716,10.151934 559.995789,10.202025 558.937866,10.227241 
	C558.066895,10.178346 557.576477,10.074862 556.746582,9.750523 
	C555.732483,9.116682 555.327148,8.475518 555.094177,7.314270 
	C555.465637,3.055786 559.994568,4.102875 561.751221,1.270152 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M561.533875,1.000000 
	C562.299133,3.688027 561.469299,5.324574 558.390015,5.034090 
	C557.401001,4.940795 556.508301,5.280824 556.011475,6.720895 
	C555.234009,7.618948 554.489929,7.732422 553.375977,7.195950 
	C553.068665,6.944602 553.013367,6.992198 553.031372,6.960586 
	C550.838623,5.223287 545.923340,6.854306 546.771729,1.327910 
	C551.689270,1.000000 556.378479,1.000000 561.533875,1.000000 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M435.909363,5.317911 
	C436.927917,3.401739 440.190216,4.703983 439.990753,1.365606 
	C440.750000,1.000000 441.500000,1.000000 442.625000,1.000000 
	C444.045044,2.060932 445.130035,2.835781 446.755493,1.245028 
	C448.066681,1.000000 449.133331,1.000000 450.600006,1.000000 
	C450.047150,4.221302 449.743195,7.873951 445.297821,9.049965 
	C444.188385,9.162694 443.503998,8.968580 442.523315,8.502481 
	C440.387604,6.706369 437.301178,8.113745 435.909363,5.317911 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M542.468018,1025.000000 
	C542.552124,1024.448486 543.104126,1023.896851 543.761414,1023.070129 
	C543.848328,1022.525757 543.830017,1022.256409 544.095398,1021.696533 
	C546.128906,1020.409973 548.045837,1020.685303 550.329346,1020.715820 
	C551.271057,1020.856750 551.770935,1021.054565 552.549561,1021.600464 
	C555.189026,1022.774536 558.661011,1020.204224 559.987427,1024.595947 
	C554.311951,1025.000000 548.623962,1025.000000 542.468018,1025.000000 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M491.292542,3.579537 
	C488.089600,4.076109 484.663879,1.380029 482.099365,5.605371 
	C480.038727,7.845583 477.907898,7.636775 475.335999,6.318769 
	C475.590149,2.673145 480.085510,4.736533 480.946228,1.353682 
	C484.030609,1.000000 487.061188,1.000000 490.545898,1.000000 
	C491.570679,1.656680 491.739777,2.406723 491.292542,3.579537 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M495.491943,1020.264771 
	C499.263885,1019.654236 500.151184,1021.918945 500.977417,1024.640137 
	C497.969391,1025.000000 494.938812,1025.000000 491.454102,1025.000000 
	C490.990631,1024.256470 490.981293,1023.512878 490.973206,1022.397461 
	C491.965881,1020.692444 493.692963,1021.231628 495.491943,1020.264771 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M570.252319,4.227117 
	C569.257629,2.181215 565.696350,5.318735 566.004089,1.337260 
	C569.692932,1.000000 573.385803,1.000000 577.539368,1.000000 
	C579.355042,3.769271 577.259888,4.516973 575.046753,5.488324 
	C573.928894,5.619430 573.197693,5.496370 572.114258,5.260568 
	C571.298279,5.023571 570.876465,4.794482 570.252319,4.227117 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M546.625000,1.000000 
	C546.837341,5.132645 550.931030,4.472267 552.966064,6.576501 
	C548.800110,7.367619 545.006897,7.011622 544.014404,1.375582 
	C544.750000,1.000000 545.500000,1.000000 546.625000,1.000000 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M573.850525,5.277339 
	C575.504211,4.113698 577.484924,3.797791 577.964661,1.334539 
	C578.444458,1.000000 578.888916,1.000000 579.666687,1.000000 
	C579.545837,2.908494 581.189758,3.886983 581.959717,5.621579 
	C580.729736,7.290384 579.042053,6.417195 577.246216,6.806528 
	C576.483032,6.897286 576.045776,6.914693 575.285034,6.854625 
	C574.760620,6.735813 574.558472,6.660717 574.107300,6.382169 
	C573.804688,5.972041 573.751099,5.765364 573.850525,5.277339 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M537.967834,3.585932 
	C535.774658,3.823593 533.128235,4.916986 532.037781,1.375815 
	C533.714355,1.000000 535.428711,1.000000 537.571533,1.000000 
	C537.990967,1.739001 537.981934,2.478002 537.967834,3.585932 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M491.061432,3.925889 
	C491.036804,3.185003 491.010925,2.457566 490.992493,1.365065 
	C492.714355,1.000000 494.428711,1.000000 496.571533,1.000000 
	C496.976776,1.441078 496.953552,1.882155 496.919922,2.654355 
	C495.624237,5.009096 493.838501,5.299815 491.405640,4.209210 
	C491.079041,3.937827 491.060150,3.939340 491.061432,3.925889 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M446.600006,1.000000 
	C446.878571,2.047668 447.259430,3.417645 445.781372,3.741347 
	C444.235382,4.079934 443.489899,3.052676 443.046387,1.354990 
	C444.066681,1.000000 445.133331,1.000000 446.600006,1.000000 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M565.744629,1021.359985 
	C566.751526,1020.997620 567.507996,1020.998596 568.642944,1021.014343 
	C568.703491,1022.123840 568.385498,1023.218628 568.033813,1024.656738 
	C567.555542,1025.000000 567.111084,1025.000000 566.333313,1025.000000 
	C565.180176,1024.008301 564.929382,1022.928711 565.744629,1021.359985 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M565.640503,1021.068115 
	C565.994202,1022.064270 565.993225,1023.131836 565.996155,1024.599731 
	C565.555542,1025.000000 565.111084,1025.000000 564.333313,1025.000000 
	C564.154236,1023.621826 563.455872,1021.959717 565.640503,1021.068115 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M102.315186,899.156982 
	C98.312935,892.913879 97.152138,886.282898 97.770500,879.277588 
	C97.957809,877.155579 98.553574,875.012878 98.383263,872.926880 
	C97.894379,866.939148 97.757301,866.877075 91.854088,868.574707 
	C89.761375,869.176514 88.239433,868.360840 86.888496,867.119995 
	C85.438133,865.787720 86.276245,864.424377 87.236816,863.156067 
	C87.530327,862.768494 87.687172,862.276672 87.902679,861.830750 
	C90.677185,856.089966 90.055618,856.835144 84.833038,852.480591 
	C80.567558,848.924072 76.987732,843.889709 76.166107,837.782776 
	C75.989388,836.469238 76.875221,835.617554 77.712479,834.806519 
	C79.484413,833.090027 81.014389,831.281189 79.142204,828.777832 
	C77.071426,826.008850 73.990410,824.662231 70.928658,825.897888 
	C68.057938,827.056519 65.896744,827.194336 66.256256,823.890015 
	C66.495758,821.688538 59.721130,816.990845 68.133377,816.900818 
	C70.325989,816.877319 72.103844,814.677734 70.270576,813.341736 
	C67.232742,811.127869 66.904602,806.759827 63.116299,805.393677 
	C62.267609,805.087646 61.008144,803.558472 61.520344,803.199829 
	C67.007851,799.358459 59.078114,795.580322 61.538204,791.677979 
	C62.032734,790.893494 60.647678,789.508301 59.160496,788.992798 
	C55.686356,787.788757 55.085808,785.217529 57.486267,782.610535 
	C57.700855,782.377563 58.058491,782.282959 58.311523,782.077148 
	C58.800800,781.679260 59.262882,781.247864 59.736221,780.830322 
	C55.529633,776.246521 62.866364,772.438660 61.065060,769.333557 
	C59.342236,766.363647 54.813114,764.900085 51.335777,763.131653 
	C50.012142,762.458557 48.289650,762.481689 48.182858,760.586914 
	C48.078358,758.732727 49.695744,758.209167 51.016533,757.514832 
	C55.766697,755.017639 56.696075,752.317017 52.597595,748.916809 
	C46.818218,744.122009 46.324051,737.415100 44.278957,731.191833 
	C43.856659,729.906738 44.509895,728.996948 45.625320,728.299561 
	C49.520271,725.864319 48.951298,723.059570 45.897636,720.528748 
	C44.353138,719.248596 43.775444,718.369812 45.672733,717.221375 
	C49.567329,714.863953 48.181438,711.835876 46.009083,709.753601 
	C41.489304,705.421143 41.977932,701.241089 45.185665,696.635864 
	C46.196682,695.184387 47.941208,693.632080 46.467876,691.788452 
	C45.099800,690.076538 43.903103,692.166077 42.603798,692.543640 
	C40.311661,693.209656 38.656132,692.369141 39.697647,689.895569 
	C40.947754,686.926819 41.055870,683.767578 42.008026,680.376343 
	C44.482746,677.943481 46.575451,678.972229 48.857224,681.025269 
	C50.855812,683.558472 51.940155,685.858643 51.502541,688.958984 
	C50.276382,697.645874 51.056507,698.504333 59.770546,699.443115 
	C60.247707,699.799011 60.407734,699.978577 60.726120,700.479980 
	C61.533707,704.018494 60.526215,706.259949 56.955044,705.912170 
	C52.356091,705.464294 52.007427,706.885925 54.540501,710.226379 
	C54.933640,710.744751 54.857132,711.487854 54.719555,712.492859 
	C52.912865,715.396973 52.455822,717.868958 54.499439,720.616272 
	C56.643009,723.497986 56.622387,726.479065 53.770088,729.407654 
	C53.355927,730.132080 53.170021,730.616272 53.726830,731.314209 
	C54.959755,732.211121 56.143608,732.668274 57.318836,733.753418 
	C57.650490,734.218933 57.756809,734.425659 57.965919,734.969849 
	C58.256981,736.256653 58.191940,737.199951 57.906452,738.481445 
	C55.032249,743.713135 55.032249,743.713135 62.163338,748.521790 
	C62.491444,748.999573 62.590450,749.211243 62.753410,749.767090 
	C62.032375,752.495239 60.660267,754.944153 64.355560,756.326904 
	C65.555069,756.775818 65.688438,758.138611 65.286324,759.322205 
	C64.579582,761.402344 65.627235,762.762268 67.296951,764.259888 
	C68.272675,765.437439 68.879326,766.445129 69.651588,767.763123 
	C71.623833,771.548584 72.468567,774.773743 68.431557,777.519348 
	C66.956879,778.522339 66.917702,780.794983 64.800995,782.642822 
	C70.662933,782.784058 70.063400,785.992737 68.785568,789.770569 
	C67.513145,791.770691 66.969612,793.350830 69.297272,794.538757 
	C72.203102,796.021851 72.127304,798.025635 70.654366,800.641174 
	C68.233475,804.939941 68.559311,805.762329 73.095718,808.044495 
	C75.797829,809.403809 77.380821,811.505798 77.937271,814.780029 
	C77.950249,815.715576 77.871132,816.332031 78.601730,816.944092 
	C79.260925,817.170227 79.643318,817.303711 80.292465,817.620728 
	C82.453278,818.742371 81.412361,822.316101 84.851074,822.171509 
	C85.673477,822.144226 86.144829,822.199585 86.957375,822.437622 
	C87.720573,822.854858 88.087883,823.189697 88.595451,823.903442 
	C89.441452,825.883667 89.356590,827.600830 89.163391,829.662659 
	C88.773346,831.424744 88.203583,832.866699 89.657227,834.410889 
	C90.312729,835.404114 90.650894,836.183228 90.652473,837.383545 
	C90.302025,838.761475 90.673645,839.653992 91.475937,840.765137 
	C91.699104,841.263306 91.764854,841.475342 91.864716,842.023315 
	C91.857269,843.187561 91.649002,843.983093 91.069916,844.579468 
	C89.550079,846.144897 87.822746,847.763855 90.365059,849.731384 
	C92.917107,851.706482 93.443993,848.490723 95.256264,847.534668 
	C98.985909,846.864746 99.944557,848.956909 100.181152,851.749146 
	C100.320480,853.393555 99.680107,855.038269 100.161034,857.065796 
	C100.137100,859.340820 99.703835,861.244385 100.299965,863.486206 
	C101.101265,867.385437 101.472069,870.990906 103.663315,874.153076 
	C107.014160,878.988708 106.715469,879.804565 101.126450,883.040894 
	C106.567894,884.189270 106.854980,890.604858 111.749039,892.313416 
	C113.115532,892.790527 113.061470,894.853455 112.439362,896.364258 
	C111.819817,897.868896 110.733887,898.900879 109.059921,898.850647 
	C106.889862,898.785645 104.780701,899.314209 102.315186,899.156982 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M58.282963,275.112427 
	C58.349133,271.370453 58.259121,268.047638 54.014328,266.554779 
	C52.953686,266.181763 52.026104,265.328400 52.113548,263.903961 
	C52.230766,261.994446 53.822521,262.279968 54.996315,261.806549 
	C58.417149,260.426849 62.955219,259.719879 60.251659,254.098587 
	C60.064140,253.708694 60.072105,252.867691 60.323708,252.676315 
	C65.653831,248.622192 61.351948,242.974182 62.746925,238.288300 
	C63.201202,236.762344 62.250610,235.762024 61.306534,234.800018 
	C59.925282,233.392548 59.560547,231.835556 61.132576,230.460495 
	C63.620724,228.284088 62.593491,226.313278 60.896214,224.486389 
	C59.472477,222.953949 58.577023,221.620438 60.562500,219.918915 
	C62.054775,218.640045 61.850372,217.170715 61.355484,215.395187 
	C60.340576,211.753952 61.214306,209.237930 65.293747,207.628799 
	C71.623268,205.132126 71.993416,202.566086 67.521576,197.964249 
	C66.701134,197.119965 65.684013,196.207199 66.367470,194.953552 
	C67.136986,193.542068 68.426041,194.151047 69.401062,194.751389 
	C72.303925,196.538727 73.813744,196.003616 74.422661,192.442810 
	C74.879723,189.769974 76.922523,188.750641 79.653358,188.969025 
	C81.311249,189.101624 83.209892,189.131165 83.725777,187.013809 
	C84.214470,185.008057 82.581856,184.094482 81.227402,183.120438 
	C80.419937,182.539749 79.480476,182.058594 78.865540,181.313766 
	C77.934502,180.186081 75.174332,179.846436 76.571236,177.648239 
	C77.601311,176.027267 79.591385,175.679413 81.525024,176.332870 
	C82.790825,176.760635 84.198090,177.513535 85.308693,176.484909 
	C87.179817,174.751923 88.870689,172.974411 85.983719,170.377289 
	C83.170555,167.846558 82.667625,165.250916 87.197800,163.799072 
	C88.681183,163.323685 89.824135,162.481430 90.229797,160.794525 
	C90.415138,160.023804 90.861664,158.934372 91.464211,158.702362 
	C101.532295,154.825790 98.543167,146.489044 98.163315,139.119507 
	C97.996742,135.887741 97.505424,132.819397 101.095909,130.885406 
	C103.352333,129.669998 103.909912,127.456444 101.282898,126.379585 
	C95.627327,124.061295 98.661171,121.437813 100.745811,118.919922 
	C101.763016,117.691330 103.806618,117.012001 102.309059,114.953972 
	C99.426003,110.991920 102.461311,109.749382 105.294044,109.225098 
	C108.500259,108.631699 111.992393,109.377480 114.489372,106.428703 
	C114.725044,106.150383 115.596718,106.024300 115.881111,106.230415 
	C121.955978,110.633400 128.721695,107.331245 135.565140,108.013374 
	C137.099426,110.949814 141.736313,113.716934 136.026489,116.979454 
	C135.371063,116.981247 135.113892,116.948364 134.498047,116.788437 
	C132.401306,115.743782 131.796478,112.679138 129.709503,113.122887 
	C125.837448,113.946175 121.783775,112.731255 118.053261,114.441994 
	C116.989326,114.929886 116.455956,115.699059 116.791252,116.784157 
	C118.136505,121.137619 115.554070,124.761574 114.467514,128.986969 
	C113.344185,130.070435 112.180695,130.251892 111.062714,130.536819 
	C108.500343,131.189850 107.722977,132.535919 108.368279,135.294617 
	C109.700890,140.991608 109.204086,146.495087 104.840973,150.961700 
	C103.469246,152.365982 103.671471,153.099960 105.009636,154.534103 
	C108.024666,157.765427 107.746452,158.703949 103.572952,160.687653 
	C100.284134,162.250870 103.129723,164.382172 102.871170,166.579147 
	C102.641068,168.493073 101.809990,169.725693 100.546890,171.120880 
	C98.887886,172.298538 97.419762,171.904892 96.021034,171.605713 
	C97.831848,174.461319 96.715302,176.368271 94.196831,178.100098 
	C88.885269,181.752594 88.777054,182.597961 92.449417,187.930267 
	C93.401115,189.312134 94.186699,190.765335 94.649078,192.378845 
	C96.058258,197.296265 94.385025,199.653854 89.425232,198.960846 
	C86.045349,198.488571 84.751968,199.303436 84.998642,202.734955 
	C85.272110,206.539124 83.342201,208.265274 79.548401,207.981903 
	C78.417343,207.897430 76.958473,207.475693 76.380058,208.724075 
	C75.674789,210.246262 77.076408,211.089340 78.088074,211.928284 
	C80.447693,213.885040 84.428604,215.642761 82.387848,219.254623 
	C80.115044,223.277206 76.557007,220.157455 73.570396,219.217575 
	C73.421753,219.170792 73.242531,219.221176 72.472893,219.245117 
	C74.831169,222.794022 73.008263,224.997284 70.495834,227.425842 
	C65.482773,232.271545 65.377197,244.646683 69.995956,249.977905 
	C70.542702,250.608994 71.102982,251.213440 71.538376,252.332718 
	C71.684540,253.422928 71.495506,254.110306 70.984604,255.069153 
	C68.558479,257.427490 66.590576,259.482147 69.542862,262.522644 
	C70.079849,263.075684 69.736412,264.082397 69.119171,265.089996 
	C67.276817,266.738403 65.528114,265.116089 63.869396,265.464294 
	C64.076942,272.903198 63.905563,273.217896 58.282963,275.112427 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M338.685364,7.833620 
	C339.001831,8.443921 339.001221,8.891077 339.000488,9.673565 
	C339.578979,13.657746 337.471405,14.853093 334.387054,14.638461 
	C331.424194,14.432282 329.529449,14.694343 328.577240,18.273067 
	C327.636566,21.808455 324.397552,21.866875 321.682770,20.081982 
	C317.961761,17.635523 315.990601,19.310570 314.986298,22.700794 
	C313.733490,26.929794 311.312103,28.571211 306.888550,28.134354 
	C303.975189,27.846638 300.836700,29.260841 299.596252,31.673368 
	C297.022797,36.678513 292.867767,36.847965 288.573212,35.986187 
	C285.506104,35.370712 284.035706,36.076042 281.982147,38.408337 
	C279.324402,41.426861 275.007324,42.720020 272.140808,46.303673 
	C271.777130,46.818588 271.608429,47.010757 271.123260,47.427433 
	C269.570251,48.239132 268.299164,48.256218 266.661987,47.732841 
	C264.798950,45.990463 263.360443,44.179592 261.737366,47.578781 
	C260.775299,49.593662 259.152802,51.211720 256.914764,51.625908 
	C254.408234,52.089783 252.697433,52.985245 251.882980,55.655231 
	C251.130035,58.123569 249.277908,57.796585 247.518799,56.521160 
	C246.310455,55.645069 245.485825,54.305683 244.080536,53.658382 
	C241.743515,52.581894 239.757217,52.454861 239.395660,55.706303 
	C239.049286,58.821209 237.512054,60.117184 234.365921,59.601776 
	C231.779037,59.177990 229.472488,60.326725 226.798218,61.276764 
	C223.714203,64.383102 220.218353,65.052483 216.333054,64.755547 
	C213.809448,64.562675 212.135391,65.413818 212.675629,68.449516 
	C213.022751,70.400108 212.343887,72.204735 210.744476,73.241524 
	C208.417053,74.750221 208.373764,77.001595 208.087555,79.647072 
	C207.571396,81.263908 206.751724,82.171822 205.162140,82.822342 
	C204.273300,82.994469 203.760574,82.970726 202.625702,82.427902 
	C205.718689,71.724403 207.592346,60.447788 217.813507,53.763981 
	C220.150101,52.236038 218.827301,50.750191 217.169296,49.478722 
	C216.288605,48.803360 214.864304,47.918686 215.659332,46.732037 
	C216.534683,45.425529 217.963486,46.151108 219.216888,46.777805 
	C225.556656,49.947670 225.567932,49.925167 230.195572,44.174622 
	C231.340637,42.751713 238.078171,42.676765 239.573044,44.418655 
	C242.657654,48.013023 245.403961,47.603344 248.228455,44.271458 
	C251.166321,40.805813 255.739777,40.199085 259.384827,37.932281 
	C263.344879,35.469574 266.433533,31.709734 271.632721,30.926027 
	C274.521149,30.490639 274.755310,25.761711 277.940887,24.446615 
	C279.121155,23.959368 280.337250,22.427910 281.466919,23.160988 
	C287.359406,26.984879 290.898682,21.986530 294.373383,19.243626 
	C296.997284,17.172319 299.352936,16.520403 302.010651,17.294582 
	C304.482391,18.014593 306.101746,17.632174 307.731293,15.811701 
	C309.474304,13.864468 311.701721,12.825245 314.381805,13.006185 
	C316.848175,13.172698 318.998291,12.405597 320.746704,10.704423 
	C322.029541,9.456237 323.463135,8.885527 325.236115,8.995705 
	C328.094818,9.173352 330.719849,8.993163 333.050201,6.740767 
	C334.807190,5.042551 336.764587,6.303562 338.685364,7.833620 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M360.820007,1006.714355 
	C361.184235,1009.018616 360.850525,1010.873230 359.162964,1012.672058 
	C358.555176,1012.989075 358.112335,1012.990356 357.337372,1012.989990 
	C355.606323,1011.610596 354.304718,1010.221741 353.543793,1008.306824 
	C351.236542,1002.500427 350.779907,1002.400574 345.342773,1005.919556 
	C344.250000,1006.000305 343.500000,1006.000610 342.375000,1006.000122 
	C338.052704,1005.714172 333.933594,1006.211182 330.261292,1003.239807 
	C329.448090,1002.825989 328.897400,1002.651001 328.173950,1002.236816 
	C327.098114,1000.946228 326.024506,1000.435669 324.316193,1000.967651 
	C320.486847,1001.342773 317.461823,999.827026 314.712952,998.052002 
	C308.850525,994.266357 300.281891,995.544189 296.354584,988.128723 
	C295.674103,986.843872 294.209351,987.069641 293.427704,988.163757 
	C291.001160,991.560425 289.529663,989.901306 286.981964,987.873535 
	C283.303894,984.946106 277.908081,985.503357 273.860321,982.615356 
	C272.178497,981.415466 270.188141,980.600647 268.548401,979.152161 
	C265.352234,976.328979 262.154633,973.796936 257.329071,973.715759 
	C253.898743,973.658142 250.927734,971.630188 248.992554,968.233032 
	C247.393494,965.425964 245.429718,962.582214 241.365952,962.005493 
	C239.544205,959.444458 242.528397,958.309753 242.975632,955.980591 
	C243.627197,954.753052 244.368286,954.117859 245.597229,953.502930 
	C248.931290,952.911011 250.589493,954.610535 251.783905,957.019348 
	C252.521713,958.507263 253.038696,960.116638 254.129562,961.408752 
	C255.748093,963.325989 257.619263,965.037354 259.671021,966.408936 
	C262.516449,968.310974 264.030853,964.913025 266.284637,964.302368 
	C266.931549,964.127075 267.572662,963.980103 268.604126,963.983276 
	C269.204498,964.067810 269.441925,964.124329 270.019745,964.317688 
	C272.420746,966.198059 275.088867,965.284607 277.727600,966.190796 
	C279.258087,966.945435 280.088593,967.902283 280.897736,969.378601 
	C282.027863,973.912170 284.044769,976.162292 288.968018,975.769958 
	C293.837616,975.381775 298.186676,977.522583 302.826752,979.370361 
	C308.389130,981.585449 313.533691,986.019836 320.044586,985.591797 
	C323.300018,985.377686 325.866547,988.076782 329.490784,987.250305 
	C330.318695,987.075439 330.798615,987.035889 331.637939,987.087280 
	C333.180511,987.463684 333.876678,988.256836 334.460205,989.340820 
	C335.194824,990.705383 332.621552,993.314758 335.257904,993.414734 
	C339.080597,993.559631 341.797607,997.835327 345.906006,995.964294 
	C348.161530,994.937195 350.368042,995.166382 352.969940,995.766357 
	C354.000336,995.740479 354.687897,995.739014 355.719360,995.726624 
	C359.348450,996.037415 360.382660,997.798462 358.933563,1000.690674 
	C357.636230,1003.280090 358.500275,1004.982422 360.820007,1006.714355 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M37.304878,673.982727 
	C31.207054,671.264954 26.193544,668.502258 25.567921,660.640381 
	C25.274565,656.953979 23.407242,654.014465 25.352652,649.765808 
	C26.226471,647.857361 24.692053,642.880493 22.416828,639.917114 
	C20.752146,637.748962 20.353516,636.577759 23.438663,634.620483 
	C27.023476,632.346252 24.184050,628.981384 22.960188,626.000549 
	C20.986420,621.193237 16.384880,617.064331 18.744421,610.977783 
	C18.972281,610.390076 18.592974,609.130249 18.079540,608.761414 
	C14.167851,605.950989 15.090313,600.782410 12.412806,597.369934 
	C11.894285,596.709167 11.696245,595.186340 12.101527,594.513855 
	C14.180491,591.064636 13.724447,587.817139 12.150279,584.345215 
	C11.580023,583.087463 12.462507,581.617493 13.922729,581.207397 
	C16.456659,580.495850 15.901361,578.508545 16.037811,576.349365 
	C18.714062,574.354126 20.040461,575.892700 20.983831,578.115112 
	C21.560484,579.473633 22.136131,581.084473 22.153635,582.350647 
	C22.236197,588.322998 24.709696,594.010376 23.945513,600.369995 
	C21.126284,603.589844 20.944002,606.529968 23.471893,609.630249 
	C24.487864,610.876221 24.951517,612.271973 23.599323,613.649780 
	C22.528238,614.741089 22.231548,615.918579 23.231136,617.202148 
	C24.282305,618.552002 25.679596,619.008911 27.210466,618.238098 
	C30.252846,616.706177 32.343105,617.813599 32.665501,620.865112 
	C33.084435,624.830505 35.815067,625.930969 39.052036,627.323242 
	C40.821434,629.228760 42.168365,630.988281 42.525749,633.655273 
	C41.823608,636.642944 39.724491,637.650574 37.414665,638.260437 
	C33.909695,639.185730 32.726837,641.060669 34.384087,644.493042 
	C36.143200,648.136353 37.404202,651.661926 35.154224,655.829895 
	C34.032288,657.908203 35.512482,660.411133 37.376099,662.019714 
	C39.129665,663.533447 41.244522,664.627319 42.532787,666.668213 
	C43.014027,667.430664 43.693672,667.618713 44.649792,667.525635 
	C50.158031,666.989807 50.162899,667.022583 50.344177,672.458984 
	C50.366364,673.124329 50.356041,673.790649 50.352737,674.812988 
	C50.304497,675.890076 50.147308,676.586243 49.722771,677.573608 
	C47.168140,680.016541 45.040329,678.641357 42.851868,677.445923 
	C41.002609,676.435791 39.880653,674.358582 37.304878,673.982727 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M233.044754,964.655945 
	C232.280548,968.024292 229.451569,967.523010 228.227097,966.695190 
	C224.919235,964.458984 222.030685,965.520874 218.809006,966.490356 
	C216.519302,967.179321 213.793427,968.078857 211.945206,965.601196 
	C210.456558,963.605469 210.234406,961.807983 211.450531,958.928894 
	C213.223602,954.731323 209.367172,951.180542 206.819550,948.172241 
	C204.727036,945.701233 202.910889,944.171448 203.443436,940.217773 
	C204.237946,934.319214 198.223709,928.214600 192.707733,925.510193 
	C188.342941,923.370239 184.472137,923.068787 179.921051,923.915894 
	C172.491669,925.298706 167.060547,922.279724 163.722321,915.452087 
	C161.604080,911.119690 158.531479,909.702271 153.996246,912.274170 
	C152.125107,913.335449 149.935471,913.879456 148.069672,911.409668 
	C148.015396,908.307800 145.179672,905.102539 149.630432,902.941406 
	C150.672379,902.734009 151.376663,902.737000 152.429199,902.812927 
	C153.905121,902.911682 154.996872,903.724670 156.126373,903.246887 
	C158.752213,902.136414 160.722122,902.723328 161.482056,905.523926 
	C162.050156,907.617493 163.979050,906.627380 165.302277,907.716797 
	C166.621613,909.085449 166.947388,910.541077 167.401337,911.972778 
	C169.881302,919.794189 172.338058,921.188232 180.720337,919.777893 
	C182.530334,919.473328 184.342590,919.267151 186.261688,918.086304 
	C187.501038,917.524231 188.332474,917.007141 189.176804,916.476379 
	C194.104599,913.378845 197.229645,914.243774 200.066422,919.408752 
	C200.456741,920.119385 200.621750,920.979614 201.796051,921.490967 
	C202.606888,921.734192 203.054260,921.908569 203.774567,922.332886 
	C206.002899,924.417725 204.184875,926.969604 205.389481,929.307251 
	C205.663986,929.802002 205.768265,930.004028 206.010773,930.519409 
	C206.817581,933.431091 208.810135,935.269836 210.003372,937.561157 
	C211.112976,939.691711 212.273239,938.631836 213.803925,937.603882 
	C214.584503,937.295837 215.059921,937.235229 215.904587,937.302246 
	C219.430450,938.527893 220.380341,940.909546 219.916595,944.049805 
	C219.535736,946.628906 220.642120,948.135254 223.159821,948.697632 
	C224.944946,949.096375 226.571762,949.830261 228.072357,950.914307 
	C232.726639,954.276550 235.357224,958.357605 233.044754,964.655945 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M16.191256,461.850128 
	C13.657125,461.577606 12.401406,460.640839 14.123885,458.339630 
	C14.870366,457.342285 15.427163,456.024963 14.901595,455.094238 
	C12.672972,451.147644 13.694643,446.423950 12.064907,442.591461 
	C9.459584,436.464813 10.002616,431.501801 14.007477,426.215057 
	C16.943262,422.339569 14.600174,416.884308 16.860708,412.523590 
	C17.592945,411.111023 17.770325,409.350494 17.948719,407.721466 
	C18.767250,400.246887 22.538965,395.979095 30.838448,394.255463 
	C32.645679,399.444153 32.019417,403.318726 27.092936,406.429138 
	C23.944067,408.417236 22.879087,413.240784 22.582922,416.431458 
	C22.235918,420.169769 21.330450,424.093445 21.858488,428.080658 
	C22.459021,432.615326 20.387503,437.174683 21.311724,441.861786 
	C21.660482,443.630463 21.446270,445.511566 22.486031,447.515808 
	C22.871012,448.541656 22.935148,449.255402 22.748949,450.331299 
	C22.119595,452.002899 20.598049,452.594666 20.049061,454.331726 
	C19.074068,457.137512 18.149645,459.579834 16.191256,461.850128 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M102.012505,899.381409 
	C104.085915,898.149292 105.884155,896.415833 108.524246,897.625183 
	C110.442307,898.503723 111.210800,896.833740 111.669563,895.387329 
	C112.233360,893.609558 111.361298,892.402344 109.598572,892.127808 
	C106.888382,891.705750 105.015060,891.081970 105.444115,887.504761 
	C105.620163,886.036865 103.491180,884.983337 101.630722,885.002014 
	C99.724670,885.021057 98.935532,884.082031 99.033279,882.210938 
	C99.142334,880.123291 100.293053,880.611755 101.771782,880.703796 
	C104.881355,880.897522 106.088089,878.870239 103.968941,876.742249 
	C100.515450,873.274597 99.898277,869.002930 99.040588,864.319580 
	C104.472427,859.677490 106.695389,859.967773 107.650543,866.488953 
	C108.723099,873.811646 109.925186,880.631836 115.394928,886.142212 
	C116.702194,887.459167 117.137970,889.803528 116.611183,891.836853 
	C115.551842,895.925842 118.124344,896.339844 120.736061,895.442749 
	C123.955688,894.336853 126.600090,893.269958 128.902679,896.974060 
	C129.795990,898.411072 130.947388,896.931091 131.738037,896.196045 
	C133.703629,894.368591 135.727448,893.111511 138.593903,895.253174 
	C139.603027,897.540771 139.615906,899.475525 138.324432,901.685791 
	C130.846695,902.090088 123.656830,904.817749 116.912666,904.245056 
	C112.363884,903.858704 106.335396,904.764221 102.209183,900.724915 
	C102.017296,900.537048 102.078339,900.090759 102.012505,899.381409 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M45.748390,295.361359 
	C46.000271,293.933228 46.000542,292.866455 46.001396,291.399658 
	C51.265892,290.283478 50.906418,294.893951 52.458046,298.120483 
	C52.519989,299.237122 52.314507,299.938934 51.908089,300.949890 
	C49.819817,305.204926 52.568836,309.512817 50.868999,313.788147 
	C51.110928,315.012817 51.123749,315.914825 51.023483,317.154846 
	C50.857502,318.368286 50.758152,319.247620 51.019150,320.470184 
	C51.345497,322.291901 51.546303,323.831085 51.171028,325.264832 
	C50.501961,327.821045 50.876972,329.680542 53.430080,331.032776 
	C55.631012,332.198517 56.180634,334.229675 55.117271,336.927429 
	C54.715500,337.692688 54.413868,338.081879 53.779926,338.668396 
	C50.494667,340.287262 48.598484,342.167725 49.722054,345.890625 
	C50.180996,347.411285 49.443550,349.181549 48.045708,349.758789 
	C44.345745,351.286591 40.669285,353.618439 36.184250,351.293884 
	C35.823044,346.582214 39.148846,342.429871 36.276299,337.647339 
	C35.297592,336.017883 37.864258,333.670105 39.674320,332.052826 
	C41.968884,330.002594 44.888203,328.592468 46.105339,325.348328 
	C47.254368,322.285706 48.868866,319.495026 44.488220,317.628296 
	C43.097855,317.035797 42.822525,315.644531 43.420742,314.213287 
	C43.950863,312.944946 44.395378,311.526093 43.531582,310.398499 
	C41.676270,307.976532 42.352726,306.929260 44.973007,305.631683 
	C47.290127,304.484253 47.451649,301.927185 46.265144,299.247803 
	C45.177807,298.001495 44.929451,296.924377 45.748390,295.361359 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M138.307617,902.133911 
	C137.996552,899.956238 137.980942,897.919983 137.961365,895.447266 
	C138.012833,892.995972 138.611343,891.128662 139.759583,889.070984 
	C140.466507,888.568604 140.938812,888.446350 141.794647,888.561035 
	C144.544983,891.912048 147.564331,893.365479 151.648270,892.182007 
	C153.785675,891.562561 155.937286,892.000488 158.037384,892.616394 
	C158.835052,892.850342 159.812607,893.535522 160.394684,893.272400 
	C165.790512,890.832581 167.792618,895.533630 171.075424,898.324585 
	C174.563522,902.401428 171.877090,905.148010 169.549255,908.300415 
	C169.435623,909.062866 169.405930,909.498413 169.405304,910.251221 
	C169.395126,911.722534 170.020508,912.491943 171.170898,913.300659 
	C171.890701,913.950745 172.279175,914.463623 172.789780,915.281128 
	C173.402435,916.469604 173.794495,917.446167 175.346710,917.567505 
	C179.323608,917.964844 180.672180,915.005737 182.864929,912.617065 
	C183.648041,912.210693 184.145081,912.105286 185.059967,912.117798 
	C188.732056,913.364075 189.993958,915.250671 187.262253,918.681030 
	C179.270813,924.959106 168.647797,921.717957 166.070236,912.596558 
	C165.711166,911.325989 165.406815,910.039917 165.033249,908.381714 
	C164.905487,905.718811 166.538956,904.076782 167.832413,901.063843 
	C163.027634,905.848083 157.914337,901.264709 153.276520,903.774841 
	C152.247620,903.945007 151.518448,903.940430 150.424988,903.920288 
	C148.656998,902.649963 149.576797,900.706055 148.695847,899.298462 
	C148.040024,898.250488 147.123505,897.317017 145.898880,898.044189 
	C144.791245,898.701782 145.529816,899.766052 145.978836,900.624756 
	C146.703888,902.011292 148.461823,903.410339 146.927261,904.987244 
	C145.553635,906.398621 143.880920,905.493896 142.229889,904.245056 
	C140.851044,903.435425 139.727051,902.855408 138.307617,902.133911 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M137.903183,108.393723 
	C138.800140,107.082024 139.606369,106.167130 140.689392,105.115402 
	C145.039413,104.318367 145.065659,104.341461 145.751083,109.448982 
	C149.519760,108.762497 150.306824,103.773277 154.473282,103.616806 
	C157.924606,103.487198 161.322113,102.118393 165.151001,103.560951 
	C165.353439,101.301964 164.457550,99.277672 165.740387,97.215942 
	C166.225098,96.460747 166.489639,95.977539 166.867767,95.243004 
	C167.343567,94.664711 167.764740,94.480415 168.593719,94.566963 
	C170.592148,98.482841 170.566956,102.694275 171.776703,106.961479 
	C171.775803,108.206429 171.666656,109.107872 171.751465,110.362244 
	C171.724823,111.189163 171.631363,111.656097 171.354126,112.456726 
	C170.028473,114.571159 170.697311,116.712975 170.063049,118.593712 
	C169.276840,120.925003 167.487823,122.962410 165.319504,121.361900 
	C158.690948,116.469162 150.996124,116.879524 143.505310,116.077492 
	C142.845612,116.006866 142.181854,115.974739 141.132446,115.820496 
	C137.966064,114.183517 137.579620,111.617546 137.903183,108.393723 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M17.493803,571.108521 
	C17.359301,570.814087 17.223038,570.388245 17.127769,570.185974 
	C16.654911,569.612000 16.464144,569.161987 16.557823,568.270569 
	C20.935123,565.732422 20.692831,561.075989 22.334667,557.458252 
	C22.697861,556.657898 22.336662,555.731262 21.740704,555.160461 
	C19.297546,552.820312 19.029175,549.689758 18.133343,546.673035 
	C17.175373,543.447144 16.670586,541.266663 20.227190,539.220032 
	C22.955437,537.650085 21.996094,534.513062 21.322756,531.941895 
	C20.437654,528.562012 19.279894,525.223145 21.232319,521.477234 
	C21.545155,519.773621 20.868904,518.449768 21.158062,516.744751 
	C21.812935,515.057007 22.519958,513.719910 22.032047,511.808899 
	C19.700785,505.767090 18.959494,499.698395 17.988297,493.715729 
	C17.366297,489.884186 20.195272,486.918030 25.141151,485.920776 
	C25.962883,487.083466 26.148113,488.088593 25.850027,489.060883 
	C23.745384,495.925659 25.771479,502.388672 27.712149,508.862732 
	C27.952099,509.663208 28.361235,510.532715 28.128365,511.291534 
	C25.135281,521.044434 28.335922,531.021667 27.075937,541.223511 
	C27.181816,542.153625 27.174980,542.685791 27.078791,543.606689 
	C26.155552,546.385864 27.353758,548.473022 27.973034,550.657349 
	C29.153822,554.822205 30.128365,558.844238 26.608170,562.650757 
	C24.530643,564.897339 25.881916,568.019348 25.692577,571.123779 
	C24.684746,573.310181 22.830540,573.294006 20.846291,573.750854 
	C20.263556,573.763855 20.032778,573.744141 19.475962,573.618164 
	C18.269615,573.078125 18.030327,572.181824 17.493803,571.108521 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M35.716599,351.126038 
	C39.817238,352.067566 43.126030,349.614807 46.780273,349.328674 
	C48.072201,349.227539 49.602238,347.313202 48.670990,345.841949 
	C45.671875,341.103577 49.699596,339.985382 52.656860,338.034607 
	C53.491524,343.272308 53.779610,348.515594 50.698509,353.623413 
	C50.240562,354.148193 49.997276,354.410950 49.690735,354.497772 
	C42.744656,356.465546 41.716148,357.852112 41.347752,365.107391 
	C41.144913,369.102112 44.541508,369.126556 47.109070,370.650513 
	C47.669876,371.311096 47.865082,371.768890 48.018196,372.646210 
	C47.851692,375.874329 46.062588,377.359589 43.556183,378.156799 
	C41.687595,378.751129 39.864159,379.421661 37.884693,380.682861 
	C36.424805,381.548035 35.394054,382.383148 34.278328,383.074738 
	C32.795696,383.993744 31.211540,385.510132 29.457354,383.688141 
	C27.735117,381.899384 27.614727,379.667664 29.214212,377.864075 
	C31.218502,375.604065 31.946438,373.068390 32.076149,369.850586 
	C32.506618,368.649139 33.182018,368.002197 33.724289,366.897614 
	C34.136292,363.625671 35.024391,360.559143 32.276981,357.750488 
	C31.910240,356.428192 31.963182,355.472839 32.749550,354.286682 
	C33.801216,353.081604 34.620663,352.173370 35.716599,351.126038 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M233.368408,964.971924 
	C231.934570,964.438782 230.970245,963.739441 231.404877,962.322815 
	C231.965210,960.496338 234.194885,958.231628 232.448746,956.900146 
	C229.288391,954.490234 227.444809,950.167786 222.623886,949.954041 
	C218.809448,949.784912 217.235672,947.628418 218.569366,943.898865 
	C219.469788,941.380737 218.612488,939.749146 216.214859,938.276367 
	C214.598969,932.688477 215.484833,929.768372 219.666077,927.249512 
	C220.323685,927.334778 220.576553,927.414124 221.142303,927.729492 
	C222.254898,928.943481 222.628860,930.168152 223.485138,931.526978 
	C223.876633,932.518494 223.960571,933.208374 223.927246,934.240906 
	C223.715408,935.596069 223.535507,936.621399 224.572784,937.737427 
	C225.131897,938.542969 225.440155,939.123901 225.895386,940.010010 
	C227.500809,943.209778 231.495285,943.922363 232.198029,947.465332 
	C239.406876,945.249878 239.880722,952.582886 243.739639,955.699768 
	C244.656860,958.613647 242.111908,959.550171 241.058609,961.656189 
	C238.575287,962.975952 236.150558,963.951843 233.368408,964.971924 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M23.026386,601.347168 
	C23.432280,597.910950 23.646152,594.952820 21.236792,592.353638 
	C20.239962,591.278320 20.280579,589.922363 21.042442,588.268921 
	C23.133154,583.731323 20.002317,580.101685 18.244978,576.295288 
	C18.122818,576.030640 17.319916,576.080261 16.443274,575.961243 
	C16.180063,575.101624 16.307161,574.264526 16.672508,573.210205 
	C17.309084,572.614258 17.778524,572.475098 18.620356,572.815796 
	C18.921783,573.056641 18.982155,573.020142 18.966249,573.051636 
	C22.348585,574.062805 23.561865,576.803467 23.937412,580.473511 
	C26.498236,578.954651 26.891987,576.645142 28.547436,574.932739 
	C29.226646,574.477234 29.674696,574.351868 30.498650,574.347412 
	C32.818745,575.713257 36.480980,573.632874 37.196774,576.785583 
	C37.756824,579.252319 34.489231,580.149780 33.246227,582.002930 
	C32.795334,582.675171 32.078056,583.147095 31.282478,584.243286 
	C33.496269,584.261108 35.016865,585.151489 35.643974,587.540161 
	C35.818375,588.350220 35.830353,588.822021 35.708557,589.641479 
	C35.379322,590.587158 34.977367,591.093567 34.639477,591.640442 
	C32.348736,595.348328 32.702606,595.971436 37.593246,596.331665 
	C39.445137,596.782166 39.864178,597.875244 39.720627,599.649353 
	C37.988785,602.167786 38.729912,604.902161 37.669884,607.554993 
	C34.893764,609.430176 34.255024,607.464722 33.769825,605.673523 
	C33.131107,603.315491 31.875072,602.734802 29.737543,603.806213 
	C26.842764,605.257202 24.609377,604.585388 23.026386,601.347168 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M22.615665,520.815430 
	C20.557755,524.859253 21.939812,528.492188 22.854963,532.328491 
	C23.643335,535.633301 25.491705,539.964966 19.598110,541.052917 
	C18.663452,541.225464 17.845915,542.107910 18.309223,542.763855 
	C20.749197,546.218140 18.416092,551.580261 23.066910,554.187500 
	C24.473722,554.976196 25.634838,556.761414 24.028236,558.339111 
	C22.313330,560.023071 21.882187,561.889526 21.903919,564.138428 
	C21.928596,566.692200 19.611000,567.073730 17.577225,568.099976 
	C16.746941,567.820190 16.523184,567.326660 16.756266,566.339844 
	C16.957413,565.962463 16.999619,566.000000 16.979628,565.980469 
	C18.772083,563.917786 18.038855,561.797668 17.104345,559.337280 
	C19.075562,553.967163 18.312374,549.442200 14.631279,545.412476 
	C14.346949,545.101196 14.123830,544.408752 14.274148,544.073853 
	C17.175665,537.609375 14.904176,530.414734 17.155548,523.868164 
	C17.367817,523.250916 17.683220,522.069336 17.597820,522.035339 
	C12.792690,520.120239 16.371790,515.511597 14.537018,512.588257 
	C13.026466,510.181458 16.195599,509.386993 16.903366,507.327820 
	C17.178270,506.902893 17.389685,506.813141 17.921131,506.846436 
	C19.303169,508.875092 20.445559,510.701782 22.868032,511.732452 
	C22.986946,513.247009 24.209499,514.704651 22.246052,515.852539 
	C19.863228,517.724426 21.637255,519.086060 22.615665,520.815430 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M32.708344,368.777771 
	C32.962616,372.407135 34.555363,376.159576 30.217167,378.462708 
	C28.472191,379.389069 28.869598,381.416626 30.047949,382.828857 
	C31.712845,384.824158 32.504803,382.595825 33.591171,381.912476 
	C34.554401,381.306549 35.492828,380.661194 36.797729,379.984680 
	C39.016895,380.337677 40.574158,381.378723 42.449722,382.573242 
	C43.625793,383.769745 44.031723,384.912659 43.926559,386.597870 
	C43.152031,389.037079 41.347515,390.072754 39.444435,391.513794 
	C36.472332,392.213654 34.517284,394.620392 31.321089,394.236816 
	C31.021658,394.021790 31.000744,394.000702 30.989582,393.989380 
	C31.249395,391.857025 32.646358,390.413300 34.083122,389.052887 
	C35.061661,388.126434 36.432983,387.376587 35.097546,385.875000 
	C33.985935,384.625092 32.750092,385.114899 31.545954,385.949432 
	C29.796038,387.162201 27.704681,387.712219 26.035248,389.204071 
	C24.266638,390.784485 22.089190,390.092346 20.703564,388.495789 
	C19.286594,386.863098 20.605322,385.195648 21.886145,384.252930 
	C24.037912,382.669128 24.216591,380.945984 23.405916,378.612915 
	C22.549959,376.149536 22.681641,373.698120 24.826439,371.330139 
	C27.165382,369.287079 29.540216,368.207550 32.708344,368.777771 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M165.604996,97.032738 
	C166.124847,98.883980 165.619476,100.935081 167.088120,102.647232 
	C167.733917,103.400101 168.323944,104.411942 167.411713,105.278275 
	C166.487961,106.155540 165.523529,105.274155 164.727310,104.840096 
	C161.787125,103.237289 158.948853,104.739059 156.049026,104.840485 
	C152.527039,104.963676 150.696304,106.347374 149.440201,109.572075 
	C148.631210,111.648972 145.703690,111.810310 144.210632,111.355064 
	C141.756348,110.606735 143.791595,108.926537 144.293076,107.493073 
	C145.070099,105.272034 143.172272,105.225792 141.334335,104.991982 
	C141.041229,103.001999 142.546326,101.728348 143.467255,100.214943 
	C145.503510,96.868599 149.156647,96.956375 151.570557,100.214119 
	C152.898590,102.006401 162.353241,101.126320 163.798187,98.994728 
	C164.248138,98.330940 164.379440,97.456146 165.604996,97.032738 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M52.346626,299.105774 
	C49.629295,296.837128 50.699188,292.280884 46.321655,291.143188 
	C45.989780,289.201141 45.792850,287.336456 47.636242,286.170410 
	C48.548656,285.593262 49.373241,284.488373 48.849953,283.749512 
	C45.141827,278.513641 49.664524,278.094635 52.758652,276.773987 
	C53.952335,276.264496 55.101372,275.650421 56.637184,275.043823 
	C57.520981,275.596191 57.943298,276.240936 58.161621,277.408966 
	C58.219551,278.304901 58.185505,278.813232 58.042362,279.686462 
	C57.363148,281.657928 57.411217,283.374756 57.069046,285.382568 
	C56.266983,287.527466 56.642036,289.322906 57.033138,291.462646 
	C57.055397,293.997864 54.826107,295.244843 54.551102,297.700073 
	C53.987659,298.639465 53.429916,299.054352 52.346626,299.105774 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M21.705538,451.162750 
	C21.917770,450.189911 21.951422,449.460327 22.003834,448.366058 
	C26.229893,445.153137 28.504917,446.107849 30.636711,452.008575 
	C30.110868,454.215698 27.878714,454.286011 26.900726,456.121338 
	C26.087492,457.132660 25.399240,457.768585 24.336752,458.503784 
	C21.190577,459.420166 19.184429,460.927216 18.920977,464.469788 
	C18.671652,464.994537 18.541643,465.189728 18.107246,465.561188 
	C16.711529,465.037781 16.058716,464.073761 15.940935,462.390076 
	C15.815732,459.193329 18.237284,457.229279 18.782223,454.278503 
	C19.827656,453.121429 20.734934,452.317932 21.705538,451.162750 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M33.345451,368.966064 
	C30.475100,369.168549 28.499319,371.444214 25.390736,370.999390 
	C25.175476,369.169800 24.744732,367.444641 24.043640,365.379730 
	C24.746700,363.303314 29.053770,364.202728 27.086464,360.288818 
	C28.073895,357.402466 28.727470,354.433197 32.616699,353.998718 
	C32.990604,355.057739 32.999439,356.125610 33.010574,357.593750 
	C34.691704,360.555115 32.794624,363.862762 34.876160,366.681366 
	C34.913883,367.892120 34.668369,368.669098 33.345451,368.966064 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M177.202362,85.216209 
	C179.478790,79.609024 180.557724,79.197006 185.341766,81.876114 
	C188.038940,83.386551 191.257004,83.645393 193.792419,85.662918 
	C194.834854,86.492409 196.305527,85.953087 197.180511,84.632423 
	C198.146042,83.175087 199.683563,82.568230 201.637939,82.040985 
	C201.999390,81.999390 202.009933,82.009804 202.015381,82.015198 
	C202.538055,82.620628 202.898285,83.292465 202.950531,84.482727 
	C202.728195,85.917938 202.142838,86.733742 201.077713,87.660446 
	C197.973526,89.761139 194.853256,88.882179 191.488403,88.873856 
	C190.921646,88.827507 190.698044,88.793175 190.153595,88.659637 
	C188.674484,87.987465 187.590302,87.257812 186.027283,86.789749 
	C185.135040,86.420135 184.576416,86.102211 183.737610,85.634995 
	C181.455795,83.948601 179.428635,86.761070 177.202362,85.216209 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M142.019806,904.361206 
	C143.410263,904.173035 144.845474,904.330627 146.236404,904.483337 
	C147.018112,903.057556 145.795578,902.700989 145.180099,902.029358 
	C143.795456,900.518127 142.652145,898.692505 143.596893,896.740784 
	C144.201294,895.492249 146.011002,895.946228 147.294479,896.093079 
	C149.741531,896.373169 150.431961,898.366638 150.795059,900.332642 
	C150.957703,901.213135 150.374420,902.231445 150.095154,903.546021 
	C147.507324,904.626465 147.504288,906.155945 148.960968,907.885742 
	C149.994904,909.113464 149.609360,909.904358 148.268524,910.879883 
	C144.601028,910.244141 143.873489,906.970886 142.019806,904.361206 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M57.377892,278.272034 
	C57.045280,277.305695 57.025017,276.538422 57.004181,275.387390 
	C57.003613,275.003662 57.486572,274.985077 57.727753,274.976746 
	C61.626884,273.157776 64.296867,270.970459 62.131546,266.257965 
	C61.650276,265.210510 61.528355,263.409271 62.692757,262.600159 
	C64.404289,261.410858 64.707726,263.330017 65.454674,264.259888 
	C66.147232,265.122009 67.214401,264.943420 68.740646,265.249756 
	C69.470818,266.992126 68.617470,268.531433 69.734589,270.264343 
	C70.105766,271.536224 70.106117,272.482666 69.704468,273.770630 
	C68.996971,274.986572 68.200920,275.571228 67.177803,275.689331 
	C65.940262,275.832153 65.150658,275.284454 64.339035,274.235352 
	C63.980724,273.772217 63.450012,275.377380 62.755920,275.860260 
	C61.819786,276.511536 61.026897,277.368896 60.033138,277.961884 
	C59.308273,278.394409 58.542843,278.630859 57.377892,278.272034 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M345.248108,1006.265991 
	C345.997070,1003.284363 347.568939,1001.673950 350.839355,1001.967834 
	C354.217773,1002.271423 353.685699,1004.992249 354.503143,1006.948975 
	C355.252319,1008.742249 356.104340,1010.492493 356.958130,1012.625061 
	C355.014343,1012.924866 353.327911,1011.699768 352.499786,1010.322327 
	C350.809998,1007.511902 348.209229,1007.075195 345.248108,1006.265991 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M176.566132,84.987534 
	C179.448395,86.418839 181.232712,81.020302 183.948532,84.631927 
	C183.184280,86.600792 181.597717,87.267212 180.022873,87.445312 
	C175.201370,87.990585 172.299561,91.125847 169.296356,94.824669 
	C168.528854,94.988976 168.086929,94.992325 167.313171,94.993675 
	C167.386536,93.589149 167.884033,92.205780 168.179626,90.780540 
	C169.088028,86.400658 171.587921,84.296631 176.566132,84.987534 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M37.114105,674.317261 
	C37.593845,671.584534 38.619621,671.568359 40.189049,673.367249 
	C42.321861,675.811951 44.629120,678.055664 48.615982,678.001709 
	C49.904549,678.750671 50.025028,679.729431 49.395218,681.419800 
	C46.926933,681.300598 45.319733,679.284851 42.415077,679.978760 
	C41.031113,677.657959 38.108948,677.057190 37.114105,674.317261 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M330.043365,1003.355835 
	C333.886902,1003.793823 337.775055,1004.586670 341.831604,1005.689514 
	C336.451660,1008.856567 333.908539,1008.367920 330.043365,1003.355835 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M137.663406,107.996544 
	C140.121475,109.732826 137.835754,113.024033 140.647919,114.814400 
	C141.630005,115.695946 141.498932,116.438446 140.823090,117.502747 
	C138.579788,118.951805 136.690140,121.378075 134.371918,117.830292 
	C134.392197,117.172729 134.459274,116.913994 134.760162,116.329559 
	C137.149124,115.972694 138.833298,115.461327 137.228241,112.895210 
	C136.408951,111.585358 135.906647,110.316956 136.009903,108.408188 
	C136.451874,108.010948 136.892380,108.003555 137.663406,107.996544 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M26.624435,360.040039 
	C28.569231,360.005493 30.176033,360.192383 29.879662,362.361206 
	C29.452005,365.490753 26.921841,364.902466 24.409397,365.001038 
	C24.021849,363.026794 24.262789,361.152985 26.624435,360.040039 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M15.517243,480.280640 
	C13.915677,477.980438 14.466854,475.677216 14.756044,473.215149 
	C15.564528,472.463989 16.225697,472.218597 17.342381,472.363464 
	C18.097471,472.722015 18.404413,473.067596 18.658279,473.855835 
	C18.759418,475.306580 18.585350,476.418976 18.695793,477.870300 
	C18.535496,478.897858 18.205173,479.486023 17.440529,480.194305 
	C16.712631,480.546051 16.259047,480.575836 15.517243,480.280640 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M17.863602,472.994019 
	C16.855902,473.015656 16.240702,473.013855 15.318039,473.005585 
	C14.282792,470.070984 17.670908,468.873657 17.812180,466.026184 
	C17.802858,465.737488 17.926014,465.403442 17.961870,465.229065 
	C18.816158,464.607178 19.458809,464.984436 20.311474,465.806763 
	C20.787949,466.589386 20.941692,467.088104 21.024776,468.007812 
	C20.764912,470.324432 19.937801,471.907623 17.863602,472.994019 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M14.390362,499.258667 
	C13.857527,497.181580 13.674138,495.324066 13.731586,493.233276 
	C14.628702,492.441650 15.381310,492.174957 16.641918,492.373840 
	C19.216013,494.634064 19.355770,496.779175 16.717457,499.158844 
	C15.833911,499.577972 15.291753,499.610504 14.390362,499.258667 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M14.031559,499.429688 
	C14.478275,499.025818 14.915633,499.012512 15.681559,499.001526 
	C18.441069,500.904297 19.223740,503.368103 18.242085,506.649628 
	C17.966442,506.958527 17.467266,506.985352 17.217062,506.988983 
	C15.016049,504.999725 13.571973,502.798828 14.031559,499.429688 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M16.801928,559.229126 
	C19.579809,561.068420 20.082436,563.323486 17.328629,565.904419 
	C16.821371,563.792847 16.683105,561.624695 16.801928,559.229126 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M17.216915,492.707458 
	C16.164242,493.023468 15.434173,493.012909 14.338263,493.001160 
	C13.991148,491.615021 14.009874,490.230042 14.036856,488.429626 
	C14.783883,487.308563 15.651362,487.216003 16.976841,487.733521 
	C18.344757,489.414520 18.071041,490.892456 17.216915,492.707458 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M17.464104,487.994629 
	C16.293327,488.017975 15.543142,488.013092 14.419034,488.011200 
	C12.344707,486.710754 14.004366,486.222443 14.824034,485.251068 
	C16.391487,484.017914 17.597656,484.071167 18.577339,486.086853 
	C18.573820,487.089447 18.323132,487.576355 17.464104,487.994629 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M90.801483,150.335342 
	C90.552505,152.090561 89.789307,153.002533 88.224228,152.754379 
	C87.490677,152.638062 87.108170,151.927292 87.559326,151.348969 
	C88.327103,150.364822 88.856102,148.446274 90.801483,150.335342 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M18.399353,487.334595 
	C17.922451,485.676697 17.141193,484.983063 15.351301,485.015686 
	C15.010193,484.539368 15.029740,484.066498 15.092128,483.241394 
	C16.147385,482.136749 17.205973,482.398560 18.651333,482.908020 
	C20.242523,483.821960 21.620598,484.582367 21.332365,486.257874 
	C21.104940,487.579895 19.942144,487.782715 18.399353,487.334595 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M324.238831,1001.264526 
	C325.538574,998.479126 326.850800,998.566162 327.965485,1001.669312 
	C326.826691,1001.841431 325.652161,1001.685242 324.238831,1001.264526 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M18.306343,483.582642 
	C17.285297,483.592346 16.513643,483.292511 15.438478,482.940918 
	C15.136353,482.184448 15.137737,481.479706 15.188032,480.425720 
	C15.638898,480.078491 16.040852,480.080505 16.746283,480.073242 
	C18.547953,480.666138 18.936543,481.789093 18.306343,483.582642 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M16.617104,566.031311 
	C16.993368,566.461548 17.029324,566.960571 17.145729,567.828857 
	C17.196659,568.596130 17.167137,568.994324 17.085058,569.688110 
	C14.259492,569.179749 14.436137,567.801880 16.617104,566.031311 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M45.657532,295.065735 
	C45.999271,296.064606 45.998543,297.129181 45.996811,298.592712 
	C43.293633,298.141418 43.335030,296.807373 45.657532,295.065735 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M18.921539,572.749023 
	C18.475830,573.042175 18.029879,573.027649 17.247341,573.003052 
	C16.962795,572.498169 17.014835,572.003296 17.213087,571.161255 
	C18.191107,571.057861 18.766666,571.547546 18.921539,572.749023 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M899.894348,107.331337 
	C902.498291,106.957901 904.525085,107.125671 906.548584,109.452263 
	C908.729492,111.959724 912.242493,112.343559 915.628113,110.717842 
	C920.739319,108.263504 926.039490,110.814102 931.260742,110.434296 
	C932.567383,110.339241 934.642456,112.131134 933.205322,114.046890 
	C931.173401,116.755676 932.635986,118.325150 934.463257,119.943100 
	C935.816223,121.141121 936.012695,122.842018 936.623718,124.364410 
	C937.113037,125.583687 936.880737,126.681374 935.763733,127.438148 
	C934.609253,128.220291 933.538696,127.891548 932.636414,126.949570 
	C931.876770,126.156487 931.352722,124.730614 929.906067,125.865860 
	C929.046570,126.540283 929.011475,127.597496 929.035034,128.616425 
	C929.065857,129.952393 929.666565,130.596558 931.046265,130.981949 
	C936.656677,132.549057 938.734741,135.550629 936.483948,140.480042 
	C934.436890,144.963028 935.575623,149.177124 935.741821,153.521637 
	C935.828186,155.782318 936.328125,157.987183 936.377930,160.304855 
	C936.437744,163.089417 937.086853,167.150269 942.073242,165.198120 
	C942.994690,164.837372 944.272461,164.880188 944.671570,166.001923 
	C945.898743,169.450943 948.038574,171.807816 951.279846,173.694305 
	C953.085266,174.745117 951.511353,176.506760 950.048157,177.603516 
	C948.531067,178.740723 946.135864,179.674515 947.329529,182.216080 
	C948.614502,184.952072 950.867249,183.601929 952.979614,183.250183 
	C954.965698,182.919449 957.822632,181.988602 958.474548,184.853470 
	C958.968018,187.022308 957.204407,188.943436 955.041016,189.796066 
	C953.329529,190.470596 951.566406,190.977356 950.227722,192.308990 
	C948.944641,193.585190 947.235229,194.820129 948.640137,196.946457 
	C949.889465,198.837357 951.762878,199.642899 953.826843,198.502167 
	C957.346436,196.556961 958.201599,197.492996 959.352173,201.363907 
	C960.390503,204.857315 962.580627,207.979507 963.190857,211.901596 
	C963.607788,214.581390 967.451111,214.579559 969.512268,216.213760 
	C971.559875,217.837097 972.589355,219.439850 970.473083,221.495987 
	C968.341797,223.566696 969.276672,224.614777 971.157288,226.192459 
	C974.136169,228.691406 973.588318,230.608795 969.731384,232.008636 
	C966.229004,233.279816 964.837524,235.169952 968.171692,237.965897 
	C970.523254,239.937790 970.211243,241.473022 968.176392,243.230560 
	C967.551941,243.769989 966.358521,244.751480 966.392151,244.795639 
	C969.577087,248.984207 965.547180,254.789536 969.247070,258.582672 
	C971.639282,261.035034 973.060608,264.511902 976.562500,265.893738 
	C977.027222,266.077118 977.582031,266.942627 977.505310,267.391357 
	C977.361389,268.233215 976.624023,268.793335 975.648743,268.633667 
	C974.267456,268.407440 972.495239,267.306366 972.127380,269.728027 
	C971.755188,272.177643 972.533020,274.373108 974.762695,275.742279 
	C976.447998,276.777191 978.332336,277.510651 979.935059,278.649902 
	C981.084229,279.466827 982.909363,280.407715 982.435974,282.029541 
	C981.827332,284.114777 979.781921,281.675171 978.469421,282.729218 
	C978.513428,284.691864 980.614258,286.122711 979.995972,288.618896 
	C979.812988,289.154877 979.630859,289.308563 979.094971,289.516327 
	C978.009521,289.475403 977.399963,289.134216 976.648254,288.370178 
	C975.739380,286.949951 975.628967,285.515564 974.493958,284.201996 
	C973.614136,281.161530 972.656494,278.638153 968.968567,278.121277 
	C967.480347,277.611328 966.447021,277.025848 965.446777,275.788818 
	C963.903015,270.882263 963.903015,270.882263 967.138916,262.915527 
	C965.723877,261.941437 964.841736,263.811432 963.574829,263.587799 
	C961.166321,263.162598 958.713318,262.819946 960.009583,259.310699 
	C960.463501,258.081879 961.167786,256.779083 959.037476,255.866028 
	C958.041931,255.514084 957.444214,255.155716 956.710449,254.383179 
	C956.409546,253.877274 956.324158,253.655533 956.194824,253.080154 
	C956.293823,250.585556 957.760193,249.157791 959.504761,248.824051 
	C963.586548,248.043228 962.444519,245.836792 961.310425,243.124115 
	C961.212219,242.296600 961.246155,241.823380 961.476562,241.023010 
	C962.684570,239.083649 963.962280,236.921478 960.651062,237.103516 
	C957.866028,237.256653 955.910950,236.922684 955.393494,234.123489 
	C954.881042,231.350983 957.281372,230.599106 959.259033,229.720016 
	C959.862549,229.451752 960.467407,229.180283 960.956238,228.200485 
	C959.890869,226.325363 957.721619,226.631958 955.958313,226.177338 
	C953.294006,225.490417 951.796082,224.058426 952.714050,221.157150 
	C953.851135,217.563538 952.283386,215.468445 949.108582,214.100067 
	C946.769165,213.091721 945.857727,211.391388 946.811279,208.891006 
	C947.871460,206.111023 947.128845,204.750809 943.951355,205.169952 
	C941.496338,205.493805 939.470886,204.265167 937.402893,203.194626 
	C935.751465,202.339706 934.852478,201.080902 935.094910,199.202255 
	C935.346680,197.250916 936.278625,195.918091 938.322021,195.267670 
	C943.780579,193.530060 944.168152,192.070999 940.546875,187.560364 
	C939.510742,186.269852 938.464294,184.977951 936.902771,183.687408 
	C936.521790,183.234802 936.387268,183.039200 936.092163,182.526428 
	C934.449280,177.909729 930.402100,174.553070 931.233704,169.386429 
	C931.305054,168.943298 930.957458,168.353470 930.556824,168.098175 
	C927.356812,166.058914 927.386719,163.236969 927.920227,159.838425 
	C928.823120,154.086014 928.591797,154.049698 930.562927,151.063583 
	C929.416077,149.745255 927.567139,149.812973 926.209045,148.872543 
	C922.614624,146.383469 921.677246,142.540176 923.790222,138.995163 
	C924.715637,137.442581 927.206299,135.832291 926.483765,134.523163 
	C925.177307,132.155869 924.234558,129.180435 921.988708,127.636536 
	C918.530273,125.259163 917.281738,122.142273 917.690491,118.300804 
	C917.952271,115.840408 916.710999,116.100723 915.065002,116.024483 
	C908.645203,115.727119 903.162537,113.587425 899.894348,107.331337 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M686.131958,13.307434 
	C688.746521,9.692503 692.829712,10.941450 696.278931,10.084625 
	C698.113037,9.629006 700.152100,9.998800 702.556641,9.992529 
	C703.505615,10.251967 703.996643,10.514897 704.743774,10.888292 
	C706.771057,13.132213 703.796021,14.464939 704.158325,16.912195 
	C707.036438,16.622776 706.411560,13.807405 707.736694,12.227309 
	C712.413269,11.669225 716.058105,15.923365 720.721558,14.140615 
	C720.845947,14.093081 721.147888,14.177301 721.178833,14.265839 
	C723.422180,20.687468 730.194214,20.250305 734.663330,23.284391 
	C736.207031,24.332409 737.225647,24.740372 739.084961,24.039467 
	C744.512573,21.993454 748.260803,23.554413 751.450806,28.589344 
	C752.142212,29.680712 753.202820,31.242943 754.198547,31.325827 
	C762.573914,32.023041 767.276184,39.700645 774.651367,41.925091 
	C779.527100,43.395668 784.329346,44.528839 788.604553,47.570435 
	C790.356689,48.817017 792.273560,46.843746 793.859741,45.636425 
	C799.198303,41.573055 800.495239,42.021763 803.251953,47.863422 
	C804.872192,51.296700 807.824097,50.322052 810.471863,48.980209 
	C811.921936,48.245373 813.296143,46.577911 814.945435,47.425632 
	C816.995605,48.479412 815.537231,50.319324 815.227478,51.842575 
	C814.904663,53.429836 815.719727,54.457794 817.065125,54.982830 
	C823.950867,57.670067 825.009277,64.228508 826.864441,70.083504 
	C827.688477,72.684258 828.288391,74.665771 830.862244,76.398323 
	C833.368103,78.085068 835.528381,80.561058 836.225769,84.306129 
	C837.572205,91.536819 842.848267,93.184029 852.648071,90.044937 
	C853.888733,90.840828 854.041992,91.878143 853.698853,93.385468 
	C853.386353,93.955925 853.218018,94.154579 852.712158,94.535728 
	C849.802246,95.055145 847.296387,95.354630 844.791931,96.376099 
	C838.415283,98.976799 834.985046,96.895691 833.912720,90.238998 
	C833.424927,89.922966 833.236938,89.784187 832.804321,89.392578 
	C831.328613,87.209961 831.562805,84.592117 829.407898,82.739761 
	C825.964233,78.759918 822.789734,75.266724 816.972168,77.078674 
	C815.124634,77.654129 813.268921,76.407654 812.944519,74.248970 
	C812.529480,71.486588 811.067993,69.735092 808.606506,68.638664 
	C807.998840,68.367950 807.192200,67.969040 807.073608,67.419785 
	C805.867920,61.836426 801.562378,60.690804 796.824219,59.874985 
	C795.124695,59.582359 793.948120,58.068893 793.465393,56.426540 
	C792.460205,53.006596 790.642517,53.307178 788.355103,55.065292 
	C784.229309,58.236393 779.761353,56.720741 775.377197,55.958118 
	C773.706848,55.667557 773.052002,54.195305 772.460693,52.791637 
	C771.593445,50.733219 770.132690,49.177765 768.404236,47.813950 
	C766.895081,46.623146 765.588867,46.282246 764.119080,47.992210 
	C761.380005,51.179054 758.756714,49.838226 757.454285,46.882179 
	C755.635620,42.754673 751.641418,41.042568 748.924744,37.964638 
	C747.595154,36.458252 745.640564,37.016735 743.756042,37.276459 
	C738.538513,37.995510 733.307007,38.368259 730.411804,32.450722 
	C730.046997,31.705057 729.357971,30.696201 728.656250,30.806372 
	C724.254822,31.497391 719.991699,28.325314 715.241638,30.547157 
	C714.164917,30.913015 713.422607,30.919296 712.334473,30.566162 
	C711.412903,29.882721 711.128540,29.209623 710.953247,28.115339 
	C710.574707,26.678993 712.087708,25.282394 710.062439,24.350855 
	C709.350098,24.183783 708.944580,24.076441 708.237976,23.863932 
	C706.750122,23.343824 705.673523,22.633633 704.086426,22.415554 
	C702.694946,22.337105 701.630554,22.406702 700.246216,22.713861 
	C699.448975,22.812971 698.988403,22.792942 698.191772,22.605883 
	C697.443604,22.248966 697.080566,21.948090 696.579956,21.289816 
	C696.134277,20.330334 696.072266,19.646046 696.190002,18.623447 
	C696.499451,17.124262 696.465088,16.302685 694.721191,17.474766 
	C694.116943,17.972172 693.744080,18.226484 693.033569,18.597031 
	C692.017700,18.929195 691.322266,18.927250 690.307556,18.598415 
	C688.561157,16.986761 687.725464,15.063744 686.131958,13.307434 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M965.714478,766.791626 
	C965.846436,769.490417 966.171997,771.461914 969.191162,772.313171 
	C971.146912,772.864624 975.309021,783.206787 974.951904,785.321350 
	C974.651917,787.097839 973.560303,787.793884 971.885742,788.025452 
	C968.928467,788.434387 968.543335,790.459839 970.243286,792.207886 
	C972.971252,795.012939 970.267090,798.020020 971.121582,800.634827 
	C972.369507,804.453247 969.997681,805.532715 967.706604,807.597290 
	C965.467957,809.614563 964.090088,812.674438 961.727539,814.821045 
	C960.647217,815.802551 959.282410,817.006714 960.708618,818.598267 
	C961.796265,819.811707 963.154724,819.758362 964.789246,819.163696 
	C968.833191,817.692200 970.877625,819.298462 971.003601,823.602234 
	C971.078186,826.149231 970.218018,827.581177 967.488281,826.641541 
	C965.126526,825.828674 963.048157,825.802734 960.898926,827.339966 
	C959.326721,828.464417 957.369019,828.183411 955.591797,827.674377 
	C954.115112,827.251404 952.460815,826.509338 951.646545,828.282166 
	C950.688232,830.368652 952.769592,831.031799 954.014709,832.012817 
	C955.561523,833.231628 957.693054,833.809387 958.646667,835.656616 
	C960.593079,839.427368 958.011719,843.126648 958.149841,846.923401 
	C958.191589,848.069702 955.986572,848.155090 954.664185,847.726318 
	C952.021729,846.869568 950.014465,846.530884 950.604248,850.547241 
	C950.914490,852.659851 948.960938,853.687195 947.452881,854.632385 
	C943.320923,857.222351 942.695374,860.409119 945.709290,864.202271 
	C946.681274,865.425781 948.759888,866.472900 947.378845,868.314087 
	C946.408936,869.607239 944.567810,869.132141 943.182312,868.535767 
	C940.037048,867.181946 937.737122,868.494507 935.322754,870.837219 
	C932.840454,870.978149 933.131409,869.255493 932.885498,867.489746 
	C932.914307,860.776184 933.156250,854.495117 933.574341,847.816284 
	C933.540283,846.615417 933.645447,845.831909 933.884460,844.687378 
	C935.387878,840.873474 936.869446,837.499268 941.276733,837.035889 
	C942.441101,836.913391 943.197449,836.141479 943.057983,835.034058 
	C942.712524,832.292236 944.498779,829.669128 944.025024,827.219666 
	C943.267212,823.300903 944.862671,820.584045 946.270752,817.190735 
	C948.114319,812.747986 950.410889,810.539124 955.035034,809.864502 
	C957.789490,809.462708 960.083801,807.621094 962.612427,804.950500 
	C959.400757,804.602417 957.647583,806.562134 955.481445,806.891968 
	C953.240356,807.233154 950.986206,807.359070 949.800476,805.018311 
	C948.733765,802.912659 950.469116,801.653870 951.797729,800.415833 
	C953.370117,798.950500 954.835022,797.397034 956.888550,796.478271 
	C962.419556,794.003906 962.670532,793.276978 960.587708,787.400391 
	C960.304260,786.600769 960.136719,785.807556 960.346680,784.627991 
	C961.679626,782.329712 965.056763,780.520203 959.983765,778.668335 
	C956.512573,777.401245 959.777954,774.233826 959.174438,771.665649 
	C959.220337,771.089783 959.262573,770.861633 959.445435,770.314697 
	C960.867310,768.638855 962.663940,768.041077 964.426086,766.784912 
	C964.973206,766.625488 965.203552,766.606812 965.714478,766.791626 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M1010.716980,573.861328 
	C1009.735657,579.395081 1014.710815,583.171814 1014.444458,588.364136 
	C1014.346680,590.270935 1015.053833,592.206604 1013.980530,594.075745 
	C1013.652832,594.646484 1013.078125,595.486450 1013.271912,595.882874 
	C1015.642761,600.734070 1012.554993,605.031982 1012.112061,609.587341 
	C1011.788330,612.916626 1010.065308,615.716003 1009.341492,618.515320 
	C1008.054016,623.494873 1005.579651,628.011169 1004.551636,632.999207 
	C1004.428101,633.598694 1004.384216,634.661438 1004.689819,634.836975 
	C1011.926697,638.994690 1006.065857,642.765320 1003.667236,645.254028 
	C1000.691345,648.341675 999.979919,649.906433 1003.704590,652.568665 
	C1005.781616,654.053101 1005.681091,656.699951 1003.960388,658.287537 
	C1001.763733,660.314270 1000.338684,661.968750 1002.371765,664.890076 
	C1003.371643,666.326843 1002.276489,667.633545 1001.160156,668.248108 
	C995.647888,671.283264 992.223083,677.315796 985.387329,678.976196 
	C982.082825,679.015747 979.354919,678.481201 977.181030,675.662354 
	C976.204102,674.574829 975.863647,673.596680 975.667603,672.133118 
	C975.705750,668.417786 974.879761,664.914246 978.024292,661.952209 
	C978.832520,661.452026 979.340515,661.304016 980.258057,661.304871 
	C982.520691,661.833496 983.481812,663.991699 985.843628,663.831543 
	C992.292725,663.394348 993.437134,661.791443 991.196472,655.727661 
	C989.823486,652.011902 989.121094,648.393982 992.187683,645.072815 
	C994.813843,642.228577 993.334595,640.657837 990.337219,639.860107 
	C987.258850,639.040710 984.091431,638.478821 983.280762,634.269104 
	C983.396057,632.332581 984.154297,631.133362 985.782837,630.105835 
	C988.279175,629.638245 988.691833,627.730774 989.717224,625.915649 
	C993.284851,624.246887 993.432373,621.634521 992.260315,618.807739 
	C991.075867,615.950928 990.464111,613.171387 991.904419,609.895020 
	C994.144592,604.402344 996.582886,603.085876 1000.483765,605.117859 
	C1003.383728,606.628540 1004.638184,608.835815 1003.066772,612.078918 
	C1002.080200,614.114868 1000.559143,616.041138 1001.530884,618.897583 
	C1004.317322,617.830994 1004.980713,615.557251 1005.515747,613.118530 
	C1006.980042,606.444946 1007.298523,599.771179 1006.691406,592.925720 
	C1006.231384,587.738892 1008.979187,582.546326 1007.210876,576.841858 
	C1007.483215,574.638733 1008.313538,573.429565 1010.716980,573.861328 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M978.709656,293.724121 
	C978.949402,295.393524 979.375610,296.558655 980.115723,297.750214 
	C981.526917,300.022247 981.789612,302.863190 980.263367,304.807220 
	C978.160950,307.485352 977.527161,309.411926 980.221558,311.987579 
	C981.487305,313.197571 981.629578,315.435242 980.302856,316.545898 
	C976.877319,319.413666 979.286255,322.749786 979.234924,325.826782 
	C979.210754,327.273010 980.525391,328.794617 982.213806,328.629150 
	C987.620056,328.099396 986.561340,331.110443 984.687378,333.585114 
	C983.072510,335.717560 983.213318,336.969940 985.008057,338.574463 
	C986.354614,339.778381 987.394287,341.453674 988.913391,342.313293 
	C994.356445,345.393280 997.849731,349.695679 999.303589,355.895691 
	C999.690735,357.546570 1001.981384,358.669037 1003.067017,360.260010 
	C1004.279907,362.037567 1007.287292,363.581543 1005.472961,366.144684 
	C1003.711243,368.633575 1001.728638,365.314575 999.510681,365.468170 
	C998.684570,367.814453 1000.309021,368.891541 1001.594604,370.431427 
	C1004.025940,373.343811 1008.417847,375.619202 1005.339783,380.817230 
	C1004.209961,382.725250 1006.670471,383.920441 1007.761353,385.285980 
	C1009.582703,387.566040 1008.295105,389.231934 1006.170227,388.784088 
	C1002.401611,387.989777 997.579224,389.226318 995.439941,384.198975 
	C994.661926,382.370605 992.776672,381.814636 990.114624,381.826752 
	C988.111450,379.843231 986.990295,377.844421 984.603027,376.550079 
	C983.986633,375.985016 983.694275,375.605743 983.298950,374.858673 
	C982.445007,371.242340 984.353394,370.246490 987.108887,369.514923 
	C991.907166,368.240936 992.445374,366.798431 990.224548,362.510101 
	C989.917969,361.918121 990.262878,362.685120 989.960327,362.091064 
	C987.660645,357.575928 982.352966,352.582031 977.791260,350.707581 
	C976.261108,349.346313 975.812561,347.877991 975.649170,345.898743 
	C975.589844,343.347046 975.395630,341.207367 974.109680,338.955750 
	C973.361938,336.366150 971.202698,335.410370 969.830444,333.795471 
	C967.725220,331.317902 967.727600,329.118744 970.135498,326.870026 
	C971.543274,325.555237 974.578125,324.994751 974.141296,322.904907 
	C973.443115,319.564819 975.089905,315.984894 972.708862,312.619995 
	C972.019836,311.761963 971.667358,311.115936 971.219238,310.091492 
	C970.714417,308.499359 970.654663,307.229187 970.722656,305.568726 
	C970.846497,304.697998 970.983337,304.221497 971.363586,303.456543 
	C972.201538,302.375336 973.092468,302.032684 974.045410,301.861115 
	C978.189392,301.114777 977.072754,299.632690 974.325989,297.711456 
	C973.692017,297.092590 973.425415,296.656830 973.151245,295.771454 
	C973.672180,291.501953 975.651855,291.407013 978.709656,293.724121 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M853.260559,94.268433 
	C853.000854,92.933510 853.001770,91.869186 853.003784,90.406013 
	C854.102112,89.665710 855.199402,89.324257 856.646851,88.975540 
	C861.667175,87.993340 865.446777,89.481079 867.992310,93.444450 
	C869.608826,95.961311 872.776184,96.213135 874.733765,98.735901 
	C875.004883,98.995171 875.000000,99.000000 875.002441,98.997787 
	C875.529419,99.533997 876.062378,100.063850 876.825928,100.760406 
	C878.230469,101.454735 879.422607,101.963860 880.809814,102.733871 
	C882.938904,105.295059 885.709290,106.594429 887.843140,108.592667 
	C890.355835,110.945679 891.266724,109.143608 892.720459,107.202179 
	C893.484558,106.409126 893.974060,105.824120 894.726074,105.112991 
	C895.682007,104.421021 896.454529,104.256271 897.656616,104.763390 
	C898.583923,105.489616 899.167908,105.980156 899.877197,106.733490 
	C904.804199,111.825439 909.724609,116.166565 917.438293,114.183266 
	C919.742737,113.590752 920.391296,115.398857 919.666199,117.307961 
	C918.059937,121.537010 918.752014,124.665756 923.430725,126.121033 
	C924.611938,126.488441 924.857117,127.335258 924.984680,128.384186 
	C925.102783,129.355392 925.088745,131.013428 925.546509,131.171509 
	C931.948425,133.382416 927.871643,135.678146 925.903381,138.388535 
	C922.012695,143.746384 923.766113,147.506897 930.621521,148.977539 
	C931.789612,149.228104 933.149597,149.223969 933.500488,150.510300 
	C933.986145,152.290482 932.494202,152.676315 931.193298,153.092148 
	C929.716736,153.564072 929.402283,154.445938 929.418579,156.096451 
	C929.441589,158.416962 930.432983,160.951813 928.604431,163.141525 
	C928.013611,163.849045 928.240234,165.057800 929.074402,165.267532 
	C933.627136,166.412140 932.830933,169.701401 932.744202,173.094345 
	C932.660034,176.383591 936.958923,177.783615 936.996521,181.628815 
	C931.829163,183.431625 929.166321,181.664719 929.171509,176.717041 
	C929.173279,175.058426 929.695618,173.193115 928.523071,171.896805 
	C924.813843,167.796341 924.424072,163.185104 925.266907,157.948151 
	C925.668579,155.452087 924.956482,152.487991 923.168884,150.429047 
	C920.774841,147.671585 919.853882,144.381073 918.656372,140.749573 
	C918.186768,139.827301 917.940735,139.194962 917.927246,138.525620 
	C917.802612,132.331711 917.551636,129.203812 914.866455,125.373039 
	C911.970154,121.241127 908.716064,117.243385 903.623901,115.299675 
	C901.359985,114.435547 900.259033,111.728439 897.865295,110.728767 
	C898.942322,115.643654 895.781189,118.794632 890.632019,117.854500 
	C883.898071,116.625008 878.605713,113.357918 875.854858,106.777512 
	C875.399963,105.689461 875.031128,104.199646 873.700439,104.360100 
	C869.403137,104.878242 867.683472,101.464485 864.965637,98.917786 
	C863.134094,96.672462 862.952576,93.036919 858.706421,93.934036 
	C857.746094,94.388474 857.082397,94.643974 856.037598,94.917809 
	C854.915222,95.043259 854.193909,94.907791 853.260559,94.268433 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M996.773804,392.233032 
	C1000.869263,395.791656 1007.284058,395.701263 1010.341858,400.790039 
	C1011.013977,401.908661 1012.628235,403.124115 1011.657593,404.187836 
	C1009.099121,406.991669 1010.870239,409.885376 1011.111572,412.651093 
	C1011.336182,415.225189 1013.449890,417.664825 1011.228455,420.358398 
	C1010.980591,420.659058 1011.522278,422.095001 1012.069763,422.493622 
	C1018.569946,427.226624 1014.534729,433.430756 1014.462646,439.065125 
	C1014.445984,440.368866 1013.814514,441.852448 1014.250366,442.938416 
	C1015.465088,445.965088 1014.723816,448.405914 1012.814941,450.757477 
	C1011.268921,452.661835 1010.814209,454.890472 1010.968994,457.698120 
	C1007.107239,458.920929 1007.161072,455.693817 1006.160889,453.170593 
	C1006.093323,450.785187 1008.381287,449.517700 1007.866211,447.026001 
	C1007.617859,445.387329 1007.404297,444.076202 1006.813477,442.503906 
	C1005.033264,439.293091 1005.334839,436.334167 1005.663574,433.094543 
	C1006.236206,427.450806 1005.158081,421.709412 1006.151550,416.020782 
	C1006.313477,415.093353 1005.635803,414.152985 1004.028564,413.645935 
	C1004.259766,417.103699 1005.030396,420.520050 1001.703918,422.938873 
	C1000.815369,423.585022 1002.255127,424.984009 1002.793030,425.971161 
	C1004.147827,428.457458 1003.290222,429.716003 1000.225586,429.936340 
	C998.543213,429.577332 997.550659,428.766785 996.395874,427.513306 
	C992.193909,422.680237 992.797852,416.980927 992.123779,411.227112 
	C992.242493,409.384125 992.632568,407.965363 992.938599,406.172302 
	C993.102661,405.606903 993.182983,405.386993 993.420227,404.858154 
	C994.761719,403.157684 994.701599,401.760620 993.450439,400.045624 
	C993.093994,399.051025 993.075623,398.361481 993.329712,397.334045 
	C994.561951,395.555481 995.331604,393.869080 996.773804,392.233032 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M832.679565,931.748535 
	C829.326416,939.279541 826.395447,946.923096 821.219421,953.442566 
	C819.292847,955.869141 816.333862,956.120117 814.426819,958.144287 
	C813.585999,959.036682 811.889282,959.748962 812.708435,961.308411 
	C813.316101,962.465149 814.812378,962.040466 815.907410,961.922974 
	C817.373596,961.765686 818.800232,961.256897 820.265686,961.070618 
	C821.237488,960.947144 822.312988,961.040039 822.724854,962.227051 
	C823.148315,963.447510 822.843018,964.520447 821.852661,965.351318 
	C818.913208,967.817200 813.353699,967.197205 811.054077,964.161438 
	C808.543701,960.847656 804.748230,961.060730 803.064392,964.830811 
	C801.153015,969.110229 798.641235,965.028503 796.246887,965.275269 
	C795.672302,964.648376 795.469360,964.233459 795.442627,963.408264 
	C795.622375,962.459534 796.078674,961.835571 795.525391,961.242432 
	C792.127136,957.599182 791.834167,955.114319 794.119263,951.029785 
	C795.070923,949.328613 796.477661,948.857605 798.224487,948.909241 
	C799.874939,948.958069 801.465149,949.496399 803.458008,949.571716 
	C806.336243,949.921143 806.577393,948.300903 806.654724,946.093750 
	C807.157776,945.460632 807.545349,945.201599 808.303650,944.889282 
	C809.980042,944.537964 811.179688,944.169861 811.638123,942.616516 
	C812.921082,938.269592 815.376770,936.958069 819.368652,939.713318 
	C819.985962,940.139343 820.625061,939.842163 821.455017,939.209900 
	C823.445374,937.937256 824.456116,936.363770 825.196960,934.521423 
	C825.958801,932.626892 827.385071,931.390869 829.799438,930.888245 
	C830.974609,930.848572 831.711975,931.047668 832.679565,931.748535 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M1006.614746,451.928772 
	C1007.533264,454.455383 1007.771484,456.919373 1010.598145,458.075562 
	C1011.009033,459.462219 1011.071594,460.814270 1011.148438,462.573944 
	C1011.146851,465.303528 1008.389404,467.396423 1010.709595,470.241272 
	C1010.974731,471.558411 1011.007812,472.526306 1010.338013,473.754883 
	C1010.140076,474.068298 1009.964539,474.440063 1009.912964,474.639099 
	C1008.236145,476.828156 1009.593384,479.100525 1009.200317,481.583557 
	C1009.012512,482.446503 1008.798828,482.899261 1008.199036,483.540466 
	C1006.442383,485.028687 1004.792114,486.251892 1007.355408,488.381165 
	C1008.866882,488.855377 1009.629395,489.709778 1010.449463,490.997498 
	C1011.138977,492.997406 1009.589966,494.481598 1010.199341,496.516632 
	C1010.760925,499.469177 1008.325073,501.205170 1008.359192,503.993683 
	C1008.465820,504.594452 1008.484253,504.840057 1008.564819,505.452515 
	C1010.330933,509.993286 1007.724365,514.332153 1009.070435,518.889282 
	C1008.885803,519.978027 1008.642029,520.665710 1008.324951,521.695801 
	C1007.573730,524.764954 1008.846375,527.797424 1006.511597,530.329346 
	C1005.111694,531.847351 1007.378174,532.300415 1008.253052,533.479553 
	C1008.791260,534.364746 1008.919067,535.024597 1008.887695,536.036133 
	C1008.725220,537.294800 1008.012939,538.232849 1009.066162,539.411377 
	C1009.381226,539.842346 1009.494263,540.029114 1009.714233,540.533447 
	C1010.109619,542.494629 1009.486450,544.060242 1009.401367,546.033813 
	C1009.412781,547.140930 1009.327820,547.873535 1009.069092,548.968750 
	C1009.081970,550.658691 1008.621216,551.899170 1008.515686,553.582031 
	C1008.740967,555.871399 1008.615112,557.719788 1007.570190,559.802002 
	C1004.923340,561.492920 1003.435547,560.453552 1002.922546,558.082642 
	C1002.225281,554.860474 1001.324707,551.571228 1003.392334,548.413269 
	C1004.621033,546.536743 1004.406982,544.519287 1003.128723,542.785095 
	C998.400940,536.370667 999.806458,529.224976 1000.632263,522.182556 
	C1000.905396,519.852783 1001.415283,517.663147 999.997559,515.486389 
	C999.040466,514.016968 998.747375,512.113647 999.834045,510.744293 
	C1002.559265,507.310150 1001.906006,503.654053 1001.071472,499.908264 
	C1000.659546,498.059723 1000.947021,496.270020 1001.469482,494.112122 
	C1001.401733,491.738770 1001.630737,489.744446 1001.502625,487.384094 
	C1001.645264,485.764862 1001.171692,484.686646 1000.396118,483.319855 
	C1000.095398,482.524780 999.988464,482.051178 999.906921,481.202362 
	C999.950317,479.360840 1000.461060,478.008392 1001.217163,476.372620 
	C1002.119385,474.936951 1003.789551,474.804108 1004.239746,473.060181 
	C1000.150940,468.096741 1000.164124,467.947418 1005.047485,464.504974 
	C1005.271484,463.225952 1005.100342,462.273468 1004.973389,461.314392 
	C1004.539734,458.039734 1004.159973,454.778259 999.456116,457.326447 
	C998.684570,457.744415 997.936523,457.109985 997.358093,456.017334 
	C997.671509,451.781921 1000.036194,450.115601 1004.135681,450.044556 
	C1005.334961,450.383881 1005.972900,450.857971 1006.614746,451.928772 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M936.978271,883.640869 
	C937.000244,884.443359 936.997986,884.886719 936.992981,885.662598 
	C936.072144,886.959778 934.880737,887.085632 933.796082,886.611633 
	C930.305786,885.086304 928.812439,886.518555 927.740784,889.869446 
	C926.241211,894.558350 926.023804,894.360168 931.111694,894.529236 
	C934.710938,894.648926 934.192261,897.106079 933.236572,899.744873 
	C932.165588,902.161194 930.017883,902.374756 928.408142,902.222351 
	C924.954895,901.895447 921.572998,900.885437 919.092957,904.494568 
	C918.945740,904.708862 917.446777,904.325134 916.866455,903.859131 
	C911.795837,899.787903 907.970215,903.052856 904.127747,905.978149 
	C902.570129,907.164062 901.267700,908.686707 899.702515,909.860718 
	C896.415100,912.326477 893.371033,912.504883 891.220032,908.381958 
	C888.591370,903.343262 885.588196,907.939453 882.384888,907.997559 
	C880.577942,905.504333 882.838806,904.502625 884.290771,902.822266 
	C886.104736,901.616821 887.748657,900.844971 889.369507,900.036621 
	C892.157593,898.646118 894.903931,899.148193 896.234314,901.817200 
	C898.155457,905.671387 899.668335,904.096313 901.536682,902.053772 
	C902.427734,901.079712 903.263367,900.059875 904.376221,898.823608 
	C906.978027,896.701050 908.844971,894.532166 912.668457,896.658569 
	C914.618103,897.742676 916.991577,895.284729 917.666748,892.254150 
	C917.771057,891.447266 917.865479,890.990051 918.090027,890.197876 
	C919.959473,885.426086 924.677307,883.692383 927.521729,879.771240 
	C931.211914,879.951965 935.199158,878.871826 936.978271,883.640869 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M979.661255,690.735962 
	C979.970459,691.751831 979.976501,692.501160 979.978882,693.625244 
	C978.290466,699.682617 972.947205,700.928528 970.145569,702.443237 
	C972.118469,700.469788 975.154053,700.393372 977.698059,704.705078 
	C978.439453,705.961670 980.464050,705.867615 981.117188,707.682739 
	C980.956421,709.627625 979.601990,710.054871 978.537231,710.796814 
	C976.637268,712.120728 976.198914,713.808167 978.515015,714.896973 
	C982.161499,716.611328 981.069458,718.967407 979.739319,721.385132 
	C978.872192,722.961121 977.641907,724.335571 976.752014,725.901306 
	C975.928284,727.350769 975.278442,728.693298 978.192200,728.458252 
	C983.205322,728.053772 982.514282,730.468384 980.151550,733.679871 
	C980.985657,738.882751 978.963867,742.860596 976.282349,746.709351 
	C975.085876,748.426697 973.890991,749.846191 971.246704,749.428284 
	C968.977417,745.414307 969.019897,741.316223 969.473145,736.827026 
	C969.428162,735.517883 969.852295,734.765381 970.866638,734.005127 
	C971.489014,733.675476 972.009216,733.866882 972.214966,733.621277 
	C972.636780,733.117920 972.359558,733.819519 971.763672,732.770142 
	C971.717346,732.013672 971.797852,731.832886 972.043640,731.418396 
	C973.044006,730.017517 971.748230,729.582886 971.244629,728.526123 
	C970.530396,726.616028 970.584900,724.969971 970.899658,722.968933 
	C970.912476,720.919189 973.565369,720.344238 972.545898,718.059631 
	C971.271851,713.961609 969.031189,711.122375 965.829834,708.687622 
	C961.431396,705.342468 961.817871,701.828430 966.682617,699.063049 
	C970.498352,696.894043 971.950439,692.129211 976.653442,690.468994 
	C977.813477,690.102051 978.595093,690.147766 979.661255,690.735962 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M796.069336,962.641968 
	C795.999207,963.445984 795.998474,963.892029 795.996399,964.672668 
	C786.533325,961.285400 781.138977,968.632385 774.640991,972.907715 
	C773.287781,973.798035 772.470886,975.844604 770.757263,975.819702 
	C761.936646,975.691467 756.245789,983.118652 748.247742,984.960327 
	C746.369629,985.392883 744.787537,986.611511 743.651672,988.483337 
	C741.398376,992.197021 738.276917,993.213928 734.223267,990.294250 
	C733.852051,987.137695 736.212219,986.219055 738.044617,984.940735 
	C739.367920,984.017334 741.246704,983.849854 742.090332,981.725464 
	C742.741272,980.818359 743.330933,980.400574 744.402710,980.078979 
	C750.838745,981.591919 755.405884,978.289673 759.393677,974.713135 
	C764.064941,970.523621 769.540527,967.317505 773.775146,962.635010 
	C774.218994,962.144287 774.865662,961.564331 775.433838,961.617676 
	C780.510010,962.093811 784.788757,959.403931 789.785767,958.316345 
	C791.982361,959.948059 794.327454,960.700867 796.069336,962.641968 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M1011.036133,574.086304 
	C1009.455811,574.036255 1008.459961,574.767761 1008.035522,576.659058 
	C1005.573792,580.664185 1001.651001,583.196472 999.823364,587.759460 
	C997.960632,589.332764 998.220520,591.178345 998.124634,592.949890 
	C997.865784,597.734375 997.245300,598.134155 992.186768,596.502380 
	C991.415466,595.671265 991.206116,594.981567 991.233215,593.870728 
	C992.205078,591.694031 993.142456,589.870667 990.832153,587.929077 
	C990.277588,587.378296 989.992004,587.036865 989.552002,586.397095 
	C988.791748,584.973755 988.669861,583.618896 987.607788,582.336365 
	C987.180176,581.439819 987.078308,580.795349 987.117310,579.804932 
	C987.280457,578.840515 987.502136,578.229736 987.809448,577.309875 
	C988.122986,576.401062 988.384277,575.816895 988.961365,574.673828 
	C989.413452,573.950928 989.552673,573.788208 989.941406,573.423706 
	C991.105652,572.641357 992.101746,572.310852 993.498657,572.247925 
	C994.642090,572.310669 995.468872,572.314209 996.606079,572.062256 
	C998.551025,571.787598 1000.061829,572.145569 1001.788330,573.080994 
	C1004.995056,575.556458 1005.876587,573.549438 1006.606567,570.789795 
	C1006.866699,568.984924 1007.261169,567.588562 1008.227905,566.003662 
	C1008.810974,565.335938 1009.234436,565.071350 1010.098022,564.887329 
	C1011.878601,565.144409 1012.024780,566.187195 1011.982544,567.669434 
	C1011.984131,568.442566 1011.989136,568.887512 1011.989258,569.666260 
	C1011.993103,570.444397 1011.998352,570.888855 1012.004272,571.666626 
	C1011.981201,572.593933 1011.801147,573.130981 1011.234558,573.814819 
	C1011.044495,574.032898 1011.024170,574.068176 1011.036133,574.086304 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M970.525635,748.811157 
	C975.187500,747.571472 979.637451,740.108032 978.527771,736.009888 
	C978.267334,735.048340 978.403442,734.444336 979.672791,734.044006 
	C982.560181,738.338196 982.658325,743.397339 980.627197,747.356506 
	C977.563965,753.327271 977.157349,761.650574 968.326538,763.868408 
	C967.535034,763.864380 967.036743,763.700745 966.681152,763.361023 
	C964.976868,761.732788 961.564148,760.938599 962.524109,757.854736 
	C963.557556,754.534973 966.754028,754.670532 969.936462,754.966675 
	C968.108215,753.966553 966.872925,752.773499 967.427979,750.331787 
	C968.211304,749.124268 969.108704,748.760681 970.525635,748.811157 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M849.267944,921.239502 
	C848.548279,921.018250 848.112671,921.021912 847.350220,921.028076 
	C845.217712,921.710876 844.322998,920.294922 843.307129,919.328308 
	C840.530029,916.685913 838.310181,917.506165 836.483154,920.392883 
	C834.726868,923.168091 833.169861,926.104309 829.147339,926.691956 
	C826.661804,925.422668 827.395813,923.869263 828.482605,922.032837 
	C829.276611,921.161499 829.931030,920.599304 829.901855,919.330200 
	C829.776428,917.865234 829.780212,916.732422 830.316284,915.315063 
	C834.400330,912.889160 837.434204,909.860718 839.980469,905.847900 
	C840.510864,905.610962 840.733704,905.543579 841.303955,905.466187 
	C842.801758,905.629761 843.694641,906.420715 845.094360,906.875854 
	C846.264648,907.328064 847.087830,907.723450 848.166382,908.387756 
	C848.987732,909.072021 849.392273,909.648315 849.813660,910.632690 
	C850.079651,911.665283 850.082214,912.368042 849.986328,913.415100 
	C848.605347,916.820496 852.835632,918.199219 851.888489,921.288757 
	C850.922607,921.807495 850.233276,921.795166 849.267944,921.239502 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M742.794678,981.713562 
	C742.996277,983.352722 742.966736,984.755981 741.235046,985.028259 
	C738.277405,985.493347 736.602356,987.864441 734.200562,989.739502 
	C732.929321,990.013184 731.865051,990.011719 730.401611,990.010010 
	C727.841675,987.443604 726.221375,984.285583 722.316162,983.140320 
	C721.665222,982.605225 721.509033,982.154724 721.721558,981.302979 
	C722.492004,980.343994 723.352234,979.804688 724.098083,980.094543 
	C727.484924,981.410828 729.152222,979.815186 731.312927,977.234497 
	C734.768677,973.107239 740.891418,975.586304 742.794678,981.713562 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M981.394348,707.968872 
	C978.779785,706.740540 975.916016,706.687439 974.853333,703.280029 
	C974.135071,700.977051 972.180603,702.234192 971.130615,703.802612 
	C970.007629,705.480042 968.940247,706.930847 966.888977,704.849304 
	C965.779297,703.723267 964.987549,702.493896 967.241577,702.143860 
	C972.507080,701.326172 975.898254,697.791992 979.675354,694.154053 
	C981.779541,696.143372 983.104248,698.554199 984.197571,701.133667 
	C985.507874,704.224670 985.152588,706.587219 981.394348,707.968872 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M979.025146,293.691223 
	C977.325806,294.372620 974.693420,291.016357 973.980835,295.662170 
	C970.960266,297.359161 971.065857,293.985046 969.245483,292.823730 
	C968.050171,291.481995 967.749390,290.186157 967.556519,288.435059 
	C967.332581,284.516357 966.473877,280.950226 968.667969,277.322998 
	C973.016602,277.089630 975.492310,278.682526 974.999390,283.594482 
	C976.206299,284.871368 975.753052,286.469482 976.773621,287.756500 
	C977.490417,288.257416 977.978333,288.523804 978.721375,288.902039 
	C979.531860,289.701752 979.717834,290.471313 979.272278,291.686523 
	C979.048096,292.480682 979.040344,292.920807 979.025146,293.691223 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M933.376587,899.921509 
	C933.193054,896.484009 933.004211,893.501160 928.510010,896.994141 
	C927.420105,897.841125 926.353821,898.002869 925.544861,896.793335 
	C924.842407,895.742859 923.827820,894.643738 924.566956,893.196106 
	C925.013733,892.320984 925.695862,891.563354 926.120728,890.680054 
	C929.325195,884.018799 929.313354,884.013123 936.604004,885.988647 
	C935.269775,890.458984 936.755310,895.673767 933.376587,899.921509 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M967.735901,749.824036 
	C967.679138,754.357361 970.646667,754.031799 973.832092,753.414795 
	C972.368835,756.107605 970.329895,755.942383 968.385254,756.002502 
	C966.396729,756.064026 963.996094,755.943359 963.370056,758.198303 
	C962.776733,760.335449 965.445923,760.284729 966.533630,761.347412 
	C967.095337,761.896301 967.464050,762.642761 967.967407,763.654907 
	C967.372192,764.798828 966.729553,765.587830 966.041016,766.686035 
	C965.803345,766.994507 965.611511,766.993835 965.131836,766.992432 
	C963.900940,767.143311 963.399170,766.339050 962.678711,766.012268 
	C956.165649,763.057983 955.750671,762.164307 959.097717,755.700928 
	C960.808960,752.396179 963.372559,749.860474 967.735901,749.824036 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M828.749756,926.346191 
	C831.877258,925.459595 833.492493,923.146240 834.702087,921.010620 
	C838.237671,914.768066 840.803650,914.330811 845.281982,919.735474 
	C845.592346,920.110046 846.026550,920.382019 846.713379,920.866211 
	C846.838135,922.088257 846.202454,923.368652 845.189819,922.751587 
	C838.683167,918.786926 836.565186,924.191833 833.667114,927.960876 
	C833.377197,928.337830 833.269531,928.854919 833.033691,929.656616 
	C832.302002,930.585571 831.521484,930.864258 830.209351,930.591125 
	C828.509827,929.494080 827.963562,928.280884 828.749756,926.346191 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M867.240540,919.714661 
	C866.215271,920.317871 865.436707,920.642090 864.342041,920.999023 
	C863.445862,921.547302 862.776001,921.804199 861.639648,921.624939 
	C861.096191,921.355347 860.906738,921.197327 860.591309,920.692200 
	C861.849731,915.637512 865.023315,911.922607 867.670532,907.478943 
	C868.738220,906.378479 869.559875,905.563904 870.690491,905.196533 
	C872.483704,904.613770 874.353088,904.399475 875.814331,905.758972 
	C877.356750,907.193848 876.236267,908.787415 875.510620,910.652466 
	C874.984131,913.063599 873.363220,914.240479 871.736816,914.835510 
	C869.384583,915.696228 868.577332,917.612244 867.240540,919.714661 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M1008.451172,549.165771 
	C1008.135193,548.201843 1008.097046,547.488159 1008.090210,546.417664 
	C1009.640076,544.458618 1011.625732,542.971680 1009.284546,540.212891 
	C1009.067139,539.955566 1009.005066,539.990112 1009.026123,539.961487 
	C1008.308899,539.356140 1007.913330,538.622559 1008.031433,537.337708 
	C1008.037415,536.274841 1008.020813,535.538391 1007.971191,534.435059 
	C1006.557495,531.026245 1009.194092,529.460266 1010.723755,527.162537 
	C1011.884888,527.133789 1012.841003,527.392822 1012.760376,528.407898 
	C1012.352356,533.544434 1013.081604,538.578674 1013.915527,543.615601 
	C1014.341797,546.190063 1013.786011,548.267212 1010.457520,548.955994 
	C1009.696777,549.354248 1009.245239,549.491577 1008.451172,549.165771 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M937.279297,883.720215 
	C935.421021,880.168823 930.927429,882.510193 928.343323,880.038330 
	C926.785828,879.518311 926.323608,878.574890 926.455505,876.908752 
	C928.272034,873.232300 930.711670,871.833252 934.671326,873.738464 
	C939.577881,876.143616 937.814392,879.994751 937.279297,883.720215 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M980.367310,691.002319 
	C979.266113,690.995667 978.567688,690.988892 977.519531,690.979675 
	C976.374023,690.457886 976.178467,689.654114 976.167847,688.359863 
	C976.751526,686.039307 977.779175,684.356995 978.614563,682.588562 
	C979.584900,680.534607 981.068970,679.596375 983.674316,680.720032 
	C983.976868,683.912415 981.104614,687.637329 986.311401,689.129883 
	C986.588745,689.209351 986.788208,690.349304 986.653503,690.905884 
	C986.406677,691.925110 985.480835,691.879395 984.635986,691.728455 
	C983.345337,691.497864 982.058533,691.245422 980.367310,691.002319 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M989.991821,381.991394 
	C989.133484,386.916931 993.779846,388.305725 995.973572,391.627014 
	C995.489014,392.886627 994.557007,393.234192 993.248901,393.645294 
	C990.579895,393.923401 989.394470,392.363831 988.257141,390.755035 
	C987.187378,389.241882 985.557129,388.488495 983.768982,387.285370 
	C983.368103,386.759644 983.243103,386.519287 983.009033,385.879700 
	C982.731750,383.987396 983.014526,382.642029 984.553955,381.359192 
	C986.394104,380.597260 987.825928,380.619659 989.567017,381.643311 
	C989.916382,381.916718 989.966675,381.966522 989.991821,381.991394 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M935.009644,873.623169 
	C932.196716,873.891174 929.310669,873.588501 927.318054,876.826111 
	C925.570007,877.324463 924.768677,876.503723 924.519836,875.311523 
	C923.701416,871.390198 929.208130,865.914429 933.693665,866.707336 
	C934.159668,868.178894 934.316895,869.352844 934.739990,870.764648 
	C935.006287,871.751221 935.006714,872.500000 935.009644,873.623169 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M874.962280,98.652374 
	C871.634521,97.415939 867.704041,97.140305 865.874207,92.703568 
	C864.569946,89.541054 860.461914,90.281006 857.256165,89.247452 
	C858.734253,86.263878 861.097900,86.401688 863.346619,87.955330 
	C867.624756,90.911148 871.724365,94.119339 874.962280,98.652374 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M1010.932312,526.704102 
	C1011.562500,529.793640 1008.658142,531.034851 1008.000000,533.710815 
	C1005.017395,532.917053 1000.598206,532.393127 1006.346619,528.502747 
	C1008.495728,527.048279 1006.041077,524.495300 1006.775879,522.234680 
	C1009.144714,521.529297 1008.854431,523.521301 1009.781250,524.740845 
	C1010.266174,525.488037 1010.541504,525.969849 1010.932312,526.704102 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M984.107422,680.738770 
	C978.835205,680.229248 979.799622,685.422974 977.349487,687.772400 
	C974.804932,688.165955 975.418030,686.509155 975.587219,685.388672 
	C975.852783,683.629822 976.912903,681.996399 976.307922,679.760010 
	C976.148254,678.929749 976.125977,678.447632 976.210327,677.604370 
	C976.483948,676.533203 976.872131,675.934753 977.722656,675.229126 
	C979.550659,677.653015 982.902161,676.595215 984.959106,678.683105 
	C984.758667,679.509521 984.490417,679.997131 984.107422,680.738770 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M892.579102,106.995499 
	C891.318481,108.649574 893.565979,110.795647 892.164856,112.951302 
	C887.932251,110.369621 883.949280,107.737953 881.042236,103.341537 
	C884.744629,104.272179 887.517395,108.245667 892.579102,106.995499 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M707.788025,11.745627 
	C707.395813,14.406858 709.688782,18.120651 705.206665,18.729494 
	C704.069397,18.883978 702.377258,18.208284 701.653503,17.327681 
	C700.752808,16.231733 702.328796,15.394798 702.982300,14.507759 
	C703.657593,13.591145 704.277405,12.633560 704.960693,11.346325 
	C705.858704,11.162457 706.717346,11.326157 707.788025,11.745627 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M1008.446533,520.244263 
	C1006.444092,515.379700 1008.380981,510.657806 1007.895691,505.537476 
	C1008.053833,504.907715 1008.222534,504.710022 1008.717529,504.725647 
	C1009.526123,505.534882 1010.008301,506.130798 1010.752441,506.858093 
	C1013.118713,509.426331 1010.484619,512.020142 1011.372437,514.857300 
	C1012.061157,517.715637 1012.238953,520.010742 1008.446533,520.244263 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M867.339050,919.955688 
	C866.696472,916.750916 867.832275,914.545532 871.236511,913.753235 
	C872.324097,913.500122 873.461792,912.996460 873.941284,911.375000 
	C874.642822,910.381226 875.258240,909.698914 876.531067,909.425293 
	C877.095642,909.458435 877.317139,909.518372 877.788940,909.814697 
	C876.004578,914.827087 870.915466,916.397461 867.339050,919.955688 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M1006.723633,560.492310 
	C1007.101379,558.323853 1006.516541,556.352112 1007.734863,554.290405 
	C1008.102295,553.770874 1008.304688,553.611877 1008.780151,553.725830 
	C1009.524170,556.671265 1016.204224,557.901855 1010.551636,561.946533 
	C1009.854553,562.327759 1009.424561,562.530701 1008.567871,562.545044 
	C1007.498108,562.144287 1007.065979,561.565491 1006.723633,560.492310 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M878.360291,910.006042 
	C877.865601,910.039246 877.691895,910.027405 877.257202,910.005371 
	C876.562134,903.326721 877.570740,902.290161 884.659607,902.754028 
	C885.045166,905.140686 882.221375,905.317078 882.041809,907.664917 
	C880.897034,908.656921 879.789185,909.309021 878.360291,910.006042 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M1007.856628,520.215210 
	C1010.778564,519.539124 1011.610657,518.126465 1010.107910,515.330566 
	C1010.622009,514.345276 1011.405518,514.255554 1012.605957,514.771057 
	C1014.257507,516.961121 1014.246826,518.783203 1012.195007,520.171875 
	C1010.701294,521.182922 1009.735229,522.348572 1009.998535,524.626282 
	C1008.671692,524.590454 1008.233704,523.354187 1007.282166,522.187866 
	C1007.225708,521.467102 1007.418030,520.923035 1007.856628,520.215210 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M1012.967529,514.630859 
	C1012.200928,515.010071 1011.455566,515.007263 1010.335754,515.010132 
	C1008.516357,512.342590 1011.132568,510.236786 1010.995911,507.366577 
	C1015.532043,508.359558 1013.258545,511.576752 1012.967529,514.630859 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M1010.139160,470.295288 
	C1006.193298,467.431519 1008.057129,465.372925 1010.860596,463.184326 
	C1014.538879,464.670532 1010.787170,467.275116 1011.951660,469.642365 
	C1011.518555,470.400665 1011.035767,470.546814 1010.139160,470.295288 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M1008.091309,484.060760 
	C1007.912659,483.375854 1007.929871,482.983978 1008.011108,482.302979 
	C1009.236511,481.064453 1008.756958,479.468658 1009.780396,478.155396 
	C1011.994080,478.314667 1014.100403,479.353882 1013.482788,481.332550 
	C1012.881042,483.260345 1011.376709,485.717468 1008.091309,484.060760 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M1009.127075,497.269348 
	C1008.444641,495.213165 1008.950806,493.492950 1009.639954,491.416962 
	C1010.132019,490.655212 1010.657288,490.500214 1011.622375,490.784790 
	C1012.015381,493.281006 1010.567017,495.815002 1013.551147,497.563110 
	C1014.362671,498.038544 1013.876892,499.395966 1012.391968,499.964172 
	C1010.705994,499.662750 1010.011597,498.595764 1009.127075,497.269348 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M995.021484,340.252380 
	C992.421753,341.399811 991.315002,340.343292 991.104126,338.100281 
	C991.001343,337.007233 991.548706,335.943970 992.754395,336.257751 
	C994.532227,336.720490 996.245667,337.553314 995.021484,340.252380 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M1008.890625,497.417542 
	C1010.106689,497.567474 1011.487732,497.860962 1011.995483,499.710876 
	C1011.093628,501.173431 1010.128662,502.308533 1009.112305,503.766876 
	C1008.844177,504.206726 1008.627502,504.323303 1008.107300,504.229767 
	C1006.127258,501.581085 1009.541687,500.031281 1008.890625,497.417542 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M859.383667,923.933228 
	C857.934326,923.994873 856.866516,924.005066 855.398193,924.018860 
	C854.346680,923.406189 854.166138,922.629578 854.349487,921.371582 
	C856.175598,918.662720 858.171387,918.987122 860.595459,920.602966 
	C860.911438,920.868469 860.958496,920.941284 860.981689,920.977966 
	C862.073364,922.611084 861.084778,923.318054 859.383667,923.933228 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M854.753418,920.793945 
	C854.992493,921.793213 854.995483,922.535950 854.998169,923.650635 
	C852.185608,925.377563 850.965820,922.727173 849.046082,921.347839 
	C849.740112,921.008667 850.496277,921.002686 851.630249,920.994568 
	C852.322571,920.638184 852.712830,920.407959 853.516052,920.287842 
	C854.082092,920.374084 854.299744,920.455750 854.753418,920.793945 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M992.767029,393.358063 
	C993.533508,392.127319 994.420532,392.011322 995.694458,391.976807 
	C996.038513,391.960693 996.525024,391.974518 996.768433,391.980469 
	C996.968994,393.938629 996.740906,395.806183 994.435547,396.950684 
	C992.767761,396.286377 992.267822,395.165863 992.767029,393.358063 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M1010.044128,477.561829 
	C1009.792297,479.309875 1010.563599,481.002167 1008.425293,481.938477 
	C1008.160278,479.658325 1007.149719,477.136719 1009.494507,474.953033 
	C1009.921509,475.616394 1009.981750,476.394684 1010.044128,477.561829 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M1007.787842,561.855896 
	C1008.664978,561.944092 1009.104919,561.957214 1009.877991,561.973022 
	C1010.241577,562.702026 1010.272034,563.428284 1010.389648,564.512207 
	C1010.163025,565.157471 1009.849182,565.445068 1009.246582,565.865784 
	C1007.524719,566.902039 1006.792480,566.102173 1006.170044,564.437622 
	C1006.165466,563.058899 1006.488831,562.268616 1007.787842,561.855896 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M859.202026,924.225586 
	C859.641968,923.200317 860.281677,922.415833 860.963135,921.322998 
	C861.759827,921.012695 862.514832,921.010803 863.647827,921.020264 
	C863.563721,923.629517 861.980774,924.718384 859.202026,924.225586 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M1011.996948,490.996735 
	C1011.480408,490.996887 1010.966736,491.000305 1010.063965,491.022369 
	C1008.485779,490.937531 1008.030029,490.128754 1007.735962,488.819489 
	C1008.815613,488.357544 1009.964783,488.173737 1011.549561,488.000793 
	C1012.575317,488.688965 1012.734314,489.456665 1012.230469,490.653992 
	C1011.993347,490.994354 1011.999817,491.000000 1011.996948,490.996735 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M1009.822449,470.507324 
	C1010.268127,470.083374 1010.745605,470.053284 1011.579468,470.030884 
	C1011.851868,471.413727 1012.520508,473.002960 1010.485779,473.994965 
	C1010.044800,473.012604 1009.949524,471.956909 1009.822449,470.507324 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M1009.096436,553.635315 
	C1008.861145,553.992371 1008.668945,553.985962 1008.188232,553.954712 
	C1007.955261,552.538025 1008.010681,551.146057 1008.119751,549.334839 
	C1008.596619,548.937317 1009.019897,548.959106 1009.763306,548.992432 
	C1009.768799,550.426636 1009.454163,551.849304 1009.096436,553.635315 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M1012.325562,490.948120 
	C1011.986023,490.248566 1011.978760,489.502777 1011.978271,488.384338 
	C1014.191345,488.513733 1014.586853,489.437256 1012.325562,490.948120 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M897.946289,104.669739 
	C897.247314,104.998726 896.494568,104.998367 895.365295,104.992439 
	C895.544373,102.919449 896.402832,102.212357 897.946289,104.669739 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M829.823364,930.297974 
	C830.773682,929.990662 831.512817,929.996765 832.621094,930.004517 
	C832.991333,930.447693 832.992554,930.889221 832.990540,931.661621 
	C832.238892,931.994812 831.490356,931.997131 830.367310,931.997314 
	C829.623352,931.600586 829.496948,931.139221 829.823364,930.297974 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M979.350891,291.989197 
	C979.034241,291.283447 979.012634,290.526337 978.983765,289.391541 
	C979.146240,289.010742 979.316040,289.007629 979.740479,289.002869 
	C980.292114,290.029266 981.600342,291.177551 979.350891,291.989197 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M1012.210571,569.852905 
	C1011.291565,569.575623 1011.009460,569.042603 1011.758423,568.146912 
	C1012.676758,568.420227 1012.989990,568.946533 1012.210571,569.852905 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M1011.330566,573.995300 
	C1011.199707,573.468262 1011.354980,572.903625 1011.757568,572.169556 
	C1013.077087,572.890869 1012.814209,573.516968 1011.330566,573.995300 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M876.979248,100.634705 
	C876.116516,100.915405 875.338318,100.671951 875.049744,99.315567 
	C875.894226,99.076500 876.664917,99.328407 876.979248,100.634705 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M1007.810913,504.292114 
	C1008.013428,504.027222 1008.222900,504.034790 1008.746643,504.066223 
	C1009.060913,504.090149 1009.048584,504.515137 1009.046265,504.727051 
	C1008.850769,504.964325 1008.657654,504.989746 1008.174805,505.060303 
	C1007.862732,504.925140 1007.840393,504.744843 1007.810913,504.292114 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M569.393738,1020.972656 
	C569.335083,1019.592224 569.641235,1018.163208 569.991943,1016.368347 
	C570.838440,1013.796875 573.239563,1013.329773 574.967773,1011.609619 
	C575.789062,1011.342712 576.281494,1011.337158 577.128601,1011.544556 
	C580.064636,1013.650513 581.970032,1013.650452 582.808167,1009.979675 
	C583.281738,1007.905518 584.717407,1007.017090 586.849426,1007.043091 
	C596.750183,1007.164001 606.583679,1006.699951 616.227417,1004.089355 
	C618.578430,1003.452881 621.195068,1002.939209 623.083130,1005.677124 
	C622.439941,1008.314392 620.129578,1009.596375 619.748962,1012.050903 
	C619.514709,1013.561462 617.954651,1014.343384 616.675537,1013.894043 
	C612.845764,1012.548767 609.271790,1015.018799 605.541077,1014.346985 
	C604.631226,1014.183105 603.948425,1014.608032 603.331909,1015.200134 
	C597.828857,1020.485535 592.070679,1020.434387 584.957642,1017.889832 
	C580.191345,1016.184753 574.286560,1017.175049 569.393738,1020.972656 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M623.411255,1006.040894 
	C620.987488,1004.222839 618.519470,1004.637573 616.462463,1005.434387 
	C610.115051,1007.892883 603.514038,1007.848022 596.910889,1007.987122 
	C593.338745,1008.062378 589.921814,1010.427185 586.118652,1008.214600 
	C584.770996,1007.430664 583.968506,1009.253052 584.027527,1010.731812 
	C584.105408,1012.684387 583.724548,1014.615845 581.821716,1015.338806 
	C579.531067,1016.209229 578.392883,1014.141357 577.044434,1012.396484 
	C579.834290,1005.711792 582.567017,1005.294312 588.827576,1004.717651 
	C594.214905,1004.221375 600.005371,1005.787048 605.144714,1002.872925 
	C605.719971,1002.546692 605.013367,1002.605408 605.586914,1002.704346 
	C613.092590,1003.999207 617.681641,999.289856 622.637512,994.891418 
	C626.052063,991.860901 630.489136,992.196106 635.077393,992.735474 
	C636.266785,992.071411 637.180908,991.714233 638.556519,991.684692 
	C640.666077,992.447327 641.513977,993.951965 642.355286,995.886719 
	C642.385254,996.481323 642.340332,996.717651 642.058228,997.220947 
	C640.574707,998.415100 639.019409,997.912476 637.524353,998.373352 
	C639.799988,999.497253 642.868469,1000.070435 642.312439,1003.280701 
	C641.898499,1005.670471 639.387451,1004.799011 637.338257,1005.023071 
	C636.249695,1005.030884 635.500671,1005.028381 634.377014,1005.024353 
	C631.847534,1005.198303 629.760864,1005.773315 627.309204,1006.085205 
	C625.925903,1006.052002 624.866943,1006.043762 623.411255,1006.040894 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M721.369751,980.685730 
	C722.012146,981.457703 722.007263,981.898315 722.000000,982.669495 
	C716.602905,984.991394 710.838013,985.863464 705.741577,988.827576 
	C703.668640,990.033142 702.307007,990.850891 703.839722,993.284058 
	C704.261719,993.953979 705.049316,994.624329 704.284058,995.451477 
	C703.603333,996.187256 702.693298,995.879456 701.995239,995.449707 
	C699.679138,994.023865 699.091980,995.094971 697.258301,996.893677 
	C695.489319,998.629089 691.838196,1000.671021 688.327820,999.584717 
	C686.112488,998.899231 683.967590,998.572449 681.597473,998.535339 
	C675.946106,998.446899 672.249634,1000.640991 671.028809,1005.998718 
	C670.244690,1009.439636 668.756714,1009.144592 666.307739,1007.160034 
	C665.878418,1006.803528 665.751953,1006.599670 665.718567,1006.049927 
	C667.381897,1004.093750 666.975403,1002.488892 665.912476,1000.783813 
	C664.905762,999.168823 664.379456,997.329163 665.539612,995.642456 
	C666.756165,993.873840 668.202393,995.505798 669.546326,995.717224 
	C670.204895,995.820801 669.632019,996.103882 669.983337,995.801270 
	C674.392578,992.003296 679.744873,994.880371 684.814331,993.494751 
	C690.739563,994.277710 694.325195,990.801819 697.882629,987.404663 
	C699.787659,985.585571 701.955139,984.944275 704.526489,984.522034 
	C710.026245,983.619019 715.053650,980.729126 721.369751,980.685730 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M637.270752,1005.300232 
	C637.378479,1004.737732 637.732361,1004.398560 638.142883,1004.154663 
	C639.224243,1003.512085 641.682617,1004.890015 641.390076,1002.462097 
	C641.186157,1000.769104 639.398682,1000.135925 637.602417,999.964905 
	C636.405273,999.850891 634.157593,1000.778137 634.575317,998.200500 
	C634.964294,995.800476 636.777527,996.494141 638.413025,996.871765 
	C639.344238,997.086853 640.361450,996.929443 641.748535,996.902893 
	C642.360291,996.735657 642.563293,996.605347 643.122070,996.402832 
	C646.408447,996.474487 648.382874,998.154297 650.187866,1000.712646 
	C650.518921,1001.499573 650.545471,1001.976196 650.224487,1002.747070 
	C646.831787,1008.712585 641.978516,1006.112976 637.270752,1005.300232 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M650.329590,1003.032104 
	C649.994873,1002.588928 649.987305,1002.143433 650.139160,1001.079468 
	C651.499329,999.503357 652.563660,998.378418 654.194336,997.435547 
	C655.985413,997.094604 657.305481,997.601257 658.942444,998.301514 
	C660.934692,999.813477 663.227783,1000.548645 663.746338,1003.395386 
	C663.663391,1004.000488 663.585022,1004.234985 663.254150,1004.740967 
	C661.934509,1005.004822 660.867432,1004.997131 659.400146,1004.989746 
	C657.026428,1003.657166 654.664001,1003.992432 652.232971,1003.256836 
	C651.529297,1003.035950 651.093079,1003.032959 650.329590,1003.032104 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M627.058044,1006.401794 
	C628.808289,1004.064270 630.964783,1003.444885 633.761841,1004.750732 
	C632.087646,1007.096558 630.231567,1009.404663 627.058044,1006.401794 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M652.051147,1003.387817 
	C654.295471,1003.051208 657.014893,1001.123840 658.845703,1004.689941 
	C656.467896,1005.910217 654.178467,1005.501587 652.051147,1003.387817 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M663.146240,1005.299011 
	C663.001038,1004.842529 663.000488,1004.672546 663.000000,1004.247681 
	C664.596375,1002.965698 665.485779,1003.788574 666.075684,1005.596069 
	C666.000977,1006.163757 666.001953,1006.332458 666.003906,1006.754211 
	C664.851746,1007.007568 663.928223,1006.569580 663.146240,1005.299011 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M456.120575,1016.665100 
	C454.989960,1018.216248 453.613953,1019.172058 452.164001,1018.771362 
	C447.250885,1017.413757 442.524719,1021.126221 437.587952,1019.141174 
	C436.872803,1018.853638 435.738342,1019.608948 434.401611,1019.933350 
	C431.519806,1018.793457 428.943512,1017.796143 426.253723,1016.253174 
	C425.548798,1016.020325 425.105225,1016.016907 424.328918,1016.013062 
	C420.639709,1015.159241 416.942322,1014.898926 413.930450,1013.696167 
	C409.617523,1011.973816 404.629333,1010.800354 401.503540,1006.308105 
	C401.733032,1004.748901 402.619385,1004.243103 404.016235,1003.776611 
	C405.170776,1003.565552 405.938507,1003.423401 407.069641,1003.108398 
	C408.283417,1002.930054 409.144989,1002.859924 409.929657,1003.104858 
	C419.440948,1006.073425 429.351807,1005.644958 439.089417,1006.731873 
	C445.897980,1007.491821 452.213226,1010.074951 456.120575,1016.665100 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M456.258423,1017.031738 
	C449.301178,1010.331543 441.497131,1007.261353 431.746246,1007.791138 
	C423.675659,1008.229675 415.636139,1006.275513 407.407410,1003.998779 
	C406.135406,1003.275208 405.608490,1003.492126 405.203125,1004.790283 
	C404.141693,1005.178467 403.278503,1005.354553 402.212524,1005.797119 
	C400.934784,1007.055847 399.776489,1006.873535 398.284851,1006.113464 
	C397.214508,1005.369629 396.659637,1004.565674 395.517334,1003.917114 
	C395.063751,1003.540649 394.898895,1003.369751 394.592773,1002.871033 
	C394.103241,998.998657 398.143158,999.295532 399.491943,996.761902 
	C404.025116,992.456726 409.749786,993.377502 413.678894,999.007385 
	C414.902710,1000.760986 416.079315,1002.313843 418.247559,1002.344604 
	C425.513611,1002.447937 432.639160,1003.775085 439.821320,1004.608093 
	C441.178284,1004.765442 442.419708,1004.353455 443.665375,1003.823669 
	C448.865051,1001.612183 450.108124,1001.892761 452.606964,1006.907227 
	C454.091980,1009.887085 456.005280,1011.600464 459.347260,1011.928589 
	C461.385223,1012.128723 462.476929,1011.311340 463.321320,1009.231567 
	C464.367859,1007.884949 465.505402,1007.402161 467.164246,1007.197388 
	C468.827148,1007.361511 470.120117,1007.600891 471.309540,1008.128723 
	C474.868408,1009.708008 475.513306,1012.164307 472.787994,1014.856750 
	C471.153839,1016.471191 470.130615,1018.076294 470.157532,1020.688538 
	C466.623199,1021.792053 464.294373,1019.654724 461.415100,1018.012085 
	C460.929047,1017.647766 460.731201,1017.390686 460.252686,1017.066650 
	C459.303131,1016.164001 458.451202,1016.168274 457.275177,1016.796387 
	C456.844055,1017.018799 456.676727,1017.021912 456.258423,1017.031738 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M397.729370,1004.785645 
	C399.093140,1005.351379 400.199738,1005.685059 401.658020,1006.041199 
	C406.615082,1008.271545 410.621918,1011.910889 415.695679,1012.844299 
	C418.525421,1013.364868 421.167786,1013.851440 423.777100,1015.726990 
	C417.957062,1015.621643 411.914032,1015.281433 405.879974,1014.823120 
	C401.361511,1014.479858 396.846710,1014.304504 393.915924,1009.758972 
	C392.969086,1008.290344 390.879395,1008.890137 389.448242,1010.050598 
	C385.714447,1013.078247 382.539062,1012.012207 379.380890,1008.956726 
	C377.616852,1007.249939 376.020996,1004.898865 372.954956,1008.095337 
	C370.688843,1010.457825 369.849670,1007.472351 369.042053,1005.374390 
	C368.411133,1003.533630 369.605347,1002.835083 370.659424,1001.737244 
	C371.416992,1001.367249 371.885681,1001.274170 372.720642,1001.318298 
	C375.547363,1002.461487 378.007507,1003.606689 381.074768,1003.933044 
	C382.407990,1004.211426 383.392365,1004.680908 384.779968,1004.286499 
	C385.515900,1004.181030 385.941162,1004.164124 386.688232,1004.216187 
	C387.642731,1004.347778 388.257507,1004.550171 389.223450,1004.611938 
	C390.412323,1004.653931 391.277863,1004.710632 392.475464,1004.655762 
	C393.695129,1004.606873 394.585602,1004.583313 395.794800,1004.307922 
	C396.602356,1004.262390 397.046783,1004.364502 397.729370,1004.785645 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M370.895081,1001.793457 
	C371.042236,1003.293091 370.151611,1003.869019 369.174957,1004.762817 
	C368.250153,1005.016357 367.499664,1005.014343 366.373962,1005.017456 
	C364.968323,1005.861755 363.617676,1005.982788 362.226837,1006.784668 
	C361.969849,1006.979370 361.485413,1006.986145 361.243317,1006.994263 
	C355.864441,1005.894287 355.484528,1005.087524 357.815399,1000.326599 
	C358.546692,998.832947 359.651062,997.171448 356.281860,996.956177 
	C354.545197,993.790222 358.482361,993.323547 359.085999,990.776001 
	C360.284821,989.386414 361.472565,989.543762 363.020630,990.079407 
	C363.751038,990.512207 364.106934,990.849976 364.343689,991.286072 
	C365.694580,993.773621 362.100342,996.818542 364.307220,998.430298 
	C366.046631,999.700562 368.968628,999.556213 370.895081,1001.793457 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M426.003387,1016.408203 
	C429.162445,1015.745056 431.751556,1016.801025 433.843567,1019.677856 
	C430.811829,1020.247192 427.225616,1021.492493 426.003387,1016.408203 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M362.320038,1006.983032 
	C362.636108,1005.570251 363.765015,1004.944885 365.650726,1005.017334 
	C365.344757,1006.449280 364.203461,1007.050110 362.320038,1006.983032 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M457.389221,1017.017334 
	C457.809937,1016.015015 458.374481,1013.972961 459.985779,1016.654053 
	C459.280609,1016.972534 458.523865,1016.995728 457.389221,1017.017334 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M660.071106,13.201063 
	C660.122009,12.438675 660.379395,11.586321 660.514832,11.605279 
	C666.464905,12.438095 672.255859,9.258099 678.612976,10.950827 
	C679.753662,11.333283 680.504883,11.669756 681.564270,12.040071 
	C682.609558,12.714831 683.557678,13.042279 684.472778,14.021767 
	C684.900269,15.088468 684.895752,15.833598 684.505493,16.922379 
	C683.005920,18.680548 681.313354,19.104198 679.080750,19.041702 
	C677.986877,18.887306 677.286621,18.682230 676.243408,18.392132 
	C675.437500,18.227200 674.987183,18.100887 674.222961,17.819559 
	C672.880005,17.186380 671.988953,16.472477 670.724487,15.718081 
	C667.015198,14.935061 663.062012,17.019493 660.071106,13.201063 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M684.128418,17.375626 
	C683.998108,16.251696 683.987854,15.518276 683.968262,14.418182 
	C684.020630,13.685183 684.199951,13.386397 684.739746,13.073414 
	C685.000000,13.001591 685.498840,13.002337 685.748169,12.998148 
	C688.474243,13.347690 689.828247,14.751290 690.000366,17.631073 
	C688.995605,19.188721 687.657166,19.215181 685.869812,18.928932 
	C684.968689,18.580093 684.559326,18.230789 684.128418,17.375626 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M684.778625,12.740387 
	C684.799988,13.267728 684.600037,13.533886 684.179443,13.925777 
	C683.070923,13.945129 682.291687,13.683828 681.948242,12.383745 
	C682.767395,12.209011 683.662292,12.344107 684.778625,12.740387 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M633.870056,17.340870 
	C633.454529,12.453525 629.577332,11.363404 626.166199,9.296815 
	C629.216187,7.600568 632.201416,9.331807 635.645508,9.840609 
	C638.020874,12.079286 640.060364,13.923827 642.751709,10.267559 
	C646.650269,9.264471 649.548523,10.930568 652.699463,13.026861 
	C653.789978,14.101021 653.352051,14.822228 652.176270,15.552170 
	C650.615540,15.744652 649.323792,15.711487 648.352661,17.241112 
	C646.434692,18.460751 644.280884,18.497427 642.587280,20.241732 
	C642.099915,20.549849 641.891541,20.654631 641.345398,20.863220 
	C640.299011,21.102842 639.588135,21.088360 638.522339,20.932346 
	C637.236389,20.621790 636.342041,20.272789 635.147583,19.698692 
	C634.242737,19.039274 633.867615,18.434113 633.870056,17.340870 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M650.788391,15.337225 
	C651.902588,14.948230 652.667542,14.685738 653.000854,13.402097 
	C655.122314,13.481874 657.184998,14.331460 659.570435,12.949934 
	C663.056763,14.033246 666.159973,15.908187 669.460449,13.197562 
	C669.582886,13.096999 670.403748,13.846888 670.979614,14.534361 
	C670.803040,17.833166 669.443359,20.070953 666.386230,21.543385 
	C665.548096,21.740868 665.062683,21.746161 664.246094,21.535336 
	C663.386780,21.013607 662.870239,20.551170 662.679565,19.937204 
	C661.364746,15.702993 658.758911,15.842760 655.584290,17.777315 
	C654.744385,18.289124 653.856079,18.669386 652.495361,18.614904 
	C651.690674,18.369051 651.298828,18.091267 650.758789,17.425400 
	C650.412903,16.617485 650.411865,16.126093 650.788391,15.337225 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M642.657898,10.018008 
	C643.039307,12.004866 642.663635,13.840885 640.243774,13.882617 
	C638.191467,13.918014 636.252563,13.375367 636.010559,10.375261 
	C636.444458,9.996565 636.888916,9.995222 637.666687,9.996554 
	C638.798828,10.005257 639.597656,10.003270 640.696045,10.012166 
	C641.438965,10.013344 641.882263,10.012105 642.657898,10.018008 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M640.809937,9.770464 
	C640.273865,11.018265 639.401367,11.180447 638.187500,10.241647 
	C638.721680,8.992194 639.596130,8.832957 640.809937,9.770464 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M271.306122,46.246506 
	C271.529755,42.513973 275.016022,41.873291 277.167511,40.190952 
	C279.316620,38.510506 281.922516,37.940670 282.565857,34.531677 
	C282.949768,32.497482 285.004272,33.068508 286.652618,34.046898 
	C289.426849,35.693581 292.397400,35.227249 295.305237,34.333092 
	C296.065155,34.099419 297.058014,33.827793 297.428101,33.245674 
	C300.864990,27.839483 302.860321,26.686184 308.993256,26.975195 
	C311.354950,27.086487 312.588837,26.227911 313.104553,23.735411 
	C313.630646,21.192764 314.326843,18.277174 317.110321,17.269882 
	C318.836792,16.645105 321.015533,16.347044 322.235443,18.849524 
	C323.055389,20.531477 324.784119,20.101307 326.220215,19.690310 
	C327.254852,19.394201 328.075867,18.466837 327.804871,17.441792 
	C326.246613,11.547791 330.705383,12.730732 333.337952,13.228409 
	C336.192169,13.767995 337.782288,13.325809 338.857605,10.326149 
	C339.440125,10.010144 339.879913,10.011391 340.649475,10.013577 
	C341.388672,10.441483 341.841614,10.802032 342.716248,10.995560 
	C344.386047,11.024999 345.764893,11.022246 347.557373,11.021469 
	C349.200653,12.527865 351.175568,11.519230 352.872681,12.632824 
	C353.669495,14.570593 352.635315,15.727691 351.449036,17.179073 
	C350.493713,17.799772 349.771118,17.936655 348.657349,17.860073 
	C345.172729,16.080925 342.764709,16.946310 340.724518,20.167713 
	C340.109985,20.995209 339.705017,21.774801 339.128174,21.846743 
	C332.420654,22.683237 327.416962,28.425016 320.379639,28.299061 
	C317.244049,28.242939 314.554260,29.273838 312.505188,31.808525 
	C311.669434,32.842327 310.527344,33.792755 309.203644,33.915325 
	C301.922974,34.589474 295.944580,39.469219 288.410492,39.923763 
	C285.432922,40.103401 281.510986,41.956200 279.130432,44.995441 
	C277.076538,47.617619 274.505280,47.935452 271.306122,46.246506 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M376.669373,12.022877 
	C377.464569,12.321172 377.927887,12.619733 378.683167,12.927677 
	C379.192169,12.812755 379.409271,12.688447 380.001984,12.559690 
	C381.366302,12.825811 382.356903,12.701265 383.351532,12.586374 
	C388.768188,11.960685 389.754181,13.021832 389.029785,19.270695 
	C381.672455,19.051565 374.272339,19.730177 368.631653,13.469561 
	C368.884979,11.204506 370.321136,10.995823 372.234222,10.918907 
	C373.241119,11.047701 373.789703,11.213400 374.660034,11.727827 
	C375.453308,11.993732 375.895416,12.008437 376.669373,12.022877 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M373.215942,11.701536 
	C371.789581,12.095681 370.520844,11.754320 369.221680,12.863497 
	C368.655853,13.496928 368.240173,13.715849 367.397461,13.792732 
	C360.237976,12.271063 357.096436,13.490776 354.495483,18.865463 
	C353.868591,19.425379 353.414368,19.567822 352.562134,19.526257 
	C351.499084,19.067225 351.142365,18.445639 350.983063,17.316757 
	C350.942993,15.503900 351.993195,14.619472 352.842468,13.269643 
	C352.391815,12.285894 352.498962,11.606637 353.329529,10.743286 
	C356.058350,9.171368 358.438354,7.732007 361.680054,7.818947 
	C364.234680,9.219629 366.481903,10.329885 369.533325,9.938580 
	C370.652985,9.401131 371.440460,9.212026 372.670624,9.736104 
	C373.028564,9.960590 373.002441,9.996713 373.020935,9.984312 
	C373.397125,10.380888 373.530334,10.853187 373.215942,11.701536 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M362.005402,7.593264 
	C359.585663,8.952092 357.145905,9.931309 354.343567,10.946880 
	C353.810669,10.818151 353.640320,10.653067 353.725708,10.240578 
	C356.549072,4.471931 359.157135,3.566239 362.005402,7.593264 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M353.598267,10.002576 
	C353.981415,9.993172 353.988159,10.488395 353.984558,10.735817 
	C353.665192,11.451504 353.349365,11.919770 353.004150,12.681225 
	C351.328125,12.708827 349.329712,13.828081 348.057617,11.385410 
	C349.719055,10.686287 351.467102,10.349133 353.598267,10.002576 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M372.982330,9.628304 
	C372.252899,9.964579 371.477234,9.968567 370.312622,9.949207 
	C370.116943,9.025707 369.694427,7.718791 371.002014,7.481274 
	C372.212646,7.261372 372.619904,8.330163 372.982330,9.628304 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M373.329224,12.021105 
	C373.000153,11.560505 373.007629,11.106714 373.027283,10.312418 
	C373.928101,10.075216 374.690735,10.351065 374.968384,11.664261 
	C374.562744,11.995324 374.114258,12.011620 373.329224,12.021105 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M342.834167,10.782108 
	C342.091064,12.061993 341.464813,11.808020 341.017578,10.301561 
	C341.539917,10.188500 342.100494,10.362484 342.834167,10.782108 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M581.723877,7.024216 
	C585.991211,7.691218 590.090576,11.385776 593.433594,5.740871 
	C594.289490,4.295603 595.890991,5.325591 596.788086,6.350445 
	C598.852905,8.709378 601.205994,8.653482 604.247192,8.670585 
	C608.628845,8.695227 612.924561,10.164628 617.568237,9.737351 
	C619.772400,9.534540 617.480713,14.043850 619.948975,15.674481 
	C618.459839,17.883463 616.291931,18.175648 613.679382,18.370136 
	C612.235840,18.417150 611.199219,18.362690 609.828064,18.001522 
	C606.524170,14.737619 603.197937,12.949068 598.630310,14.110052 
	C596.106812,14.751465 594.368713,11.725948 591.463257,12.165821 
	C590.975830,12.415708 590.781372,12.515492 590.295776,12.761928 
	C588.948853,13.292624 588.219116,14.065792 587.327148,15.163713 
	C586.674255,15.582412 586.249390,15.707262 585.468384,15.755901 
	C583.827026,15.479136 582.519836,15.289896 580.861572,15.788958 
	C580.027588,15.887340 579.541870,15.874412 578.681641,15.746189 
	C576.255676,14.932140 574.151794,14.068269 574.306213,11.445370 
	C574.453552,8.942169 576.884705,8.866153 578.586365,8.084869 
	C579.472473,7.678036 580.387024,7.338219 581.723877,7.024216 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M582.084229,6.711285 
	C581.222656,8.360384 580.248413,9.641793 578.449829,9.957417 
	C577.255920,10.166946 575.471985,9.581406 575.256653,11.411296 
	C574.993958,13.643734 577.092102,13.746147 578.781311,14.606653 
	C579.037170,17.466860 577.046814,16.910316 575.113708,16.969877 
	C574.492615,16.760275 574.260437,16.645336 573.748413,16.274189 
	C572.842712,15.245636 572.578125,14.301702 572.294128,13.385064 
	C571.763184,11.671279 571.358765,10.224479 569.127991,11.999475 
	C567.669006,13.160343 566.422241,12.337449 565.874268,10.352777 
	C569.052795,9.473505 567.517822,5.764911 569.851929,4.279969 
	C570.451050,3.967390 570.891235,3.974264 571.661316,3.997441 
	C572.527527,4.542160 573.062439,5.071910 573.788391,5.809864 
	C573.982849,6.014542 574.000000,6.000134 573.992798,6.008679 
	C574.425781,6.510863 574.950134,6.683906 575.804688,6.230577 
	C577.760986,4.686871 579.535095,5.186600 581.667969,5.939170 
	C582.032288,6.129345 582.042542,6.296044 582.084229,6.711285 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M612.733521,17.586788 
	C614.982361,16.802551 617.089233,16.413523 619.596802,16.014202 
	C620.543396,16.864922 621.407166,16.987539 622.652161,17.011986 
	C626.361145,20.068846 630.737610,17.095036 634.662903,18.937290 
	C635.868713,19.131300 636.728943,19.295549 637.795776,19.718740 
	C639.119873,22.941790 636.705383,23.265036 634.948242,23.798809 
	C632.886230,24.425240 630.599854,24.563946 628.554138,24.188278 
	C622.873596,23.145115 617.432129,21.283365 612.733521,17.586788 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M634.904968,18.710302 
	C630.967407,19.328362 626.711182,21.769936 623.055786,17.379639 
	C626.402100,17.460987 629.810852,15.784009 633.614014,16.995483 
	C634.267151,17.484518 634.534241,17.969036 634.904968,18.710302 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M366.832397,13.322119 
	C367.546967,13.160738 367.965240,13.155101 368.697723,13.154531 
	C372.055298,15.915029 375.627411,17.284605 379.725708,16.965712 
	C382.805145,16.726099 385.132172,19.319633 388.592041,19.013611 
	C389.836487,18.241514 390.822754,17.930075 392.329987,18.119928 
	C396.419464,19.625257 400.582764,19.144627 404.044952,18.691721 
	C409.785400,17.940790 415.952698,17.414415 421.118439,13.960260 
	C422.212952,13.228402 423.563538,12.880016 424.890900,12.763650 
	C431.000610,12.228034 436.855316,10.822351 442.640442,7.905037 
	C443.539948,8.166870 444.073120,8.378942 444.766602,8.835367 
	C444.662384,14.998905 449.168762,12.604678 451.939850,12.996167 
	C454.941986,13.420294 457.487030,12.992486 457.997406,9.072339 
	C458.790497,2.980628 458.918091,2.992076 463.963684,6.226432 
	C466.251221,7.692784 468.805389,6.714649 471.658997,7.087256 
	C472.411987,11.342463 468.648010,10.082055 466.260803,11.220917 
	C465.395538,11.267721 464.910370,11.251498 464.091614,11.174179 
	C461.163971,10.556767 461.380188,12.670433 460.763580,14.431124 
	C460.484436,14.936244 460.342163,15.125135 459.903778,15.522213 
	C458.458984,16.278231 457.227386,16.366932 455.613861,16.394825 
	C453.796204,16.456610 452.342224,16.448643 450.574554,16.897549 
	C448.601562,17.374578 447.018738,17.951210 444.987305,18.073696 
	C444.413757,18.034935 444.188904,17.998861 443.645050,17.857592 
	C442.542206,17.415354 441.926331,16.776163 440.792114,16.486675 
	C439.638123,16.498890 438.804382,16.611578 437.668427,16.962456 
	C431.342896,18.111694 425.048981,17.644224 419.081146,20.666924 
	C418.113434,21.064373 417.452789,21.281525 416.487854,21.665508 
	C415.317932,22.108786 414.439087,22.339611 413.263153,22.747389 
	C412.300110,23.036282 411.642242,23.145229 410.665314,23.307274 
	C408.785431,23.519644 407.545837,24.094110 407.103699,26.125174 
	C406.656891,27.518993 406.320007,28.604610 405.543091,29.876287 
	C404.932495,30.416670 404.501404,30.577442 403.683777,30.597178 
	C402.666931,30.347244 402.099304,29.954378 401.235657,29.390350 
	C400.512329,29.036844 400.109894,28.810839 399.424622,28.374804 
	C396.200500,25.309925 392.789948,23.822323 388.597595,24.365475 
	C386.101593,24.688852 383.842346,23.482111 381.102631,23.028755 
	C380.266541,22.954020 379.799286,22.849867 379.016113,22.551598 
	C376.437958,20.291264 374.403442,18.962799 372.858246,23.173410 
	C371.934021,25.691879 369.846771,26.099525 367.530945,25.044579 
	C364.353149,23.596968 360.995087,24.211384 357.707245,24.057671 
	C355.578156,23.958136 354.438812,24.553429 355.765594,26.966318 
	C357.650208,30.393671 355.204468,31.421951 352.602753,32.065323 
	C348.710052,33.027939 345.066925,31.506271 341.038574,30.615791 
	C339.009338,30.482603 338.166290,32.057446 336.479370,32.712624 
	C335.744324,32.906685 335.313995,32.952862 334.560638,32.945183 
	C332.491150,32.411110 331.695770,33.991428 330.172729,34.767632 
	C329.204102,35.062622 328.530914,35.081581 327.509033,35.003906 
	C324.284271,34.323635 322.390625,34.820560 322.753510,38.432247 
	C322.910004,39.990009 321.755157,41.066521 320.427887,42.290794 
	C319.513367,42.810440 318.837433,42.937126 317.800751,42.956512 
	C315.430084,42.594238 314.789307,43.623932 315.048401,45.783188 
	C315.510956,47.420055 316.355835,48.527493 316.870361,50.169197 
	C316.913605,52.489594 316.252991,53.671417 313.947266,53.419567 
	C309.603973,52.945156 305.321625,52.482697 303.425659,47.168961 
	C302.802399,46.355350 302.159332,45.419170 301.787811,45.623203 
	C297.473846,47.992313 291.960327,45.508720 287.922882,49.331913 
	C285.290497,51.824619 281.826965,51.418747 278.257385,50.282066 
	C277.680084,49.415092 277.607086,48.742836 277.892883,48.120712 
	C279.449646,44.731697 288.566589,40.385086 292.225067,41.359432 
	C296.308563,42.446964 298.554230,39.009785 301.395050,37.418560 
	C303.663300,36.148056 305.567657,35.006462 308.232117,35.380638 
	C310.299988,35.671036 312.511963,35.119904 313.715027,33.537418 
	C317.576813,28.457615 323.668884,28.937159 328.817871,27.361954 
	C333.268768,26.000315 337.513031,24.195559 341.376099,21.905066 
	C344.631348,19.974983 347.827484,18.837852 351.776428,18.628977 
	C352.517731,18.829632 352.936646,18.868338 353.671417,18.903566 
	C358.597046,18.080490 362.983948,16.745142 366.832397,13.322119 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M464.753540,10.441246 
	C467.341949,9.552653 469.936676,9.641473 471.998047,7.465842 
	C472.873932,6.829177 473.644989,6.535127 474.701752,6.112662 
	C477.011017,5.983179 479.034607,5.982109 481.485748,5.945129 
	C481.913300,5.909220 482.009674,6.010269 482.057983,6.060647 
	C483.031342,12.075656 481.805725,13.260603 476.171753,11.616711 
	C474.554749,11.144895 473.266052,11.852799 471.851196,12.191203 
	C469.770782,12.688791 467.718994,13.506016 465.218842,12.458839 
	C464.599060,11.797309 464.481934,11.306594 464.753540,10.441246 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M560.770752,16.292240 
	C561.022034,16.039808 561.503296,16.014116 561.743896,15.999914 
	C566.298767,15.386627 569.650574,16.666527 571.619629,21.246336 
	C571.827087,22.152512 571.839844,22.678627 571.656860,23.582859 
	C571.157043,24.659088 570.576660,25.137188 569.480469,25.501934 
	C566.165833,24.095509 563.719666,24.706236 562.959290,27.987694 
	C561.532349,34.145256 557.257812,36.517227 551.274231,37.497375 
	C550.684937,37.532021 550.447510,37.509830 549.881653,37.344505 
	C549.167969,36.897701 548.586487,36.535698 548.600342,36.137928 
	C548.798401,30.448723 545.277710,27.617420 540.554932,25.266644 
	C541.078308,22.156847 542.929932,19.973799 543.985107,17.395184 
	C545.248535,14.307454 551.900024,13.749236 555.223511,16.553764 
	C557.284790,18.293196 558.747620,18.709110 560.770752,16.292240 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M560.623840,16.010580 
	C561.160217,18.261620 560.291565,20.073750 558.174561,20.698988 
	C555.926270,21.362984 555.702148,19.047144 554.861389,17.781797 
	C552.971802,14.937858 550.103516,16.294460 547.624390,16.040535 
	C545.298035,15.802260 545.076721,17.581264 544.766235,19.230892 
	C544.350891,21.436897 543.014099,23.039143 541.170776,24.724434 
	C539.711731,26.024616 538.549927,25.798901 537.305847,24.314785 
	C536.500244,21.386631 536.767212,18.160187 532.502686,17.741497 
	C532.015015,17.389387 531.845947,17.212374 531.842407,16.013573 
	C537.136841,14.550694 540.684448,10.258976 540.433350,4.322890 
	C543.120972,4.774927 543.996948,6.414171 543.976807,8.749726 
	C543.943909,12.575604 545.930664,14.371183 549.588501,13.951530 
	C553.404297,13.513755 556.728516,15.070910 560.623840,16.010580 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M565.656372,10.001625 
	C566.709045,11.053291 567.457703,12.621572 568.792053,10.851425 
	C569.532288,9.869470 569.550537,8.070666 571.282166,8.427364 
	C573.120361,8.806011 572.835632,10.648061 573.158569,12.025044 
	C573.343567,12.814217 573.613892,13.583382 573.848877,14.744986 
	C571.403870,16.244690 569.020020,13.639183 566.210693,14.985054 
	C565.377869,14.870722 564.938477,14.685284 564.323792,14.125879 
	C563.622742,11.800967 562.053833,11.705389 560.629395,12.297343 
	C555.900269,14.262653 553.916382,12.006163 553.081177,7.326094 
	C553.780945,6.976708 554.493164,7.008814 555.559692,7.068968 
	C556.102417,7.631255 556.290833,8.165496 556.703308,8.880835 
	C557.414490,9.515779 557.962891,9.727572 558.897705,9.337498 
	C559.875244,9.152788 560.579834,9.121905 561.636963,9.083459 
	C562.576355,9.048285 563.117188,9.182906 563.827637,9.699932 
	C564.484009,9.906175 564.910156,9.944009 565.656372,10.001625 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M524.627197,4.791678 
	C527.998169,7.584537 529.909851,10.971273 531.960693,14.640509 
	C532.007935,14.991787 531.971985,15.026608 531.953918,15.043968 
	C526.228333,15.825979 521.956787,12.771399 517.580688,9.306526 
	C518.346985,5.278892 520.554077,3.731795 524.627197,4.791678 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M524.985107,4.773280 
	C521.920105,4.507974 519.932861,6.098399 518.159912,8.721785 
	C515.926819,10.779300 514.105591,9.832141 512.042542,8.218670 
	C510.943909,6.991971 510.192841,5.988507 509.913696,4.396839 
	C510.237335,3.811628 510.552582,3.349229 510.677704,3.395960 
	C515.198730,5.084074 519.784180,3.621190 524.664551,3.859501 
	C524.995728,4.177179 524.994385,4.347526 524.985107,4.773280 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M563.848877,13.988770 
	C564.525574,13.881700 564.933594,13.936233 565.647949,14.032597 
	C569.114929,15.124715 572.936523,15.057286 574.491943,19.400343 
	C574.734253,20.259079 574.742676,20.764341 574.502319,21.626379 
	C574.161621,22.151077 573.969116,22.320778 573.412109,22.614094 
	C572.526062,22.788733 572.029541,22.699961 571.257202,22.233829 
	C569.791992,17.819426 565.570190,17.908878 562.349609,16.038570 
	C562.516357,15.373943 563.048279,14.762157 563.848877,13.988770 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M464.682098,12.030210 
	C467.850922,12.181648 470.469696,11.125681 473.152710,10.391690 
	C474.844269,9.928930 476.778503,7.392195 477.781158,11.400645 
	C478.034637,12.414081 480.170380,12.270784 480.813538,10.611517 
	C481.286163,9.392246 481.569855,8.099757 482.022034,6.475460 
	C484.643921,5.833021 487.733917,3.936198 488.281342,8.766005 
	C488.453613,10.285932 490.134186,10.826016 491.832764,10.577269 
	C494.615265,10.169785 497.519196,9.530518 499.117676,7.234991 
	C501.253632,4.167600 503.934509,3.634949 507.640503,4.111862 
	C509.303223,7.010268 507.084686,7.964561 505.257416,8.957504 
	C503.837097,9.729302 502.553650,10.340961 503.511414,12.611147 
	C503.268616,15.164478 501.761627,15.863879 499.821198,15.652923 
	C497.718536,15.424330 496.790619,16.146284 497.131165,18.638855 
	C497.289734,20.283562 497.218445,21.543272 496.392212,23.042187 
	C492.554993,25.310877 489.783386,23.735790 488.236542,20.684706 
	C486.017761,16.308296 482.880585,15.428619 478.406525,16.313465 
	C475.172760,16.953016 471.759705,16.273241 468.486237,17.011227 
	C466.875397,17.374380 465.396942,17.685369 464.933807,19.851070 
	C463.533966,26.396687 463.435699,26.375669 456.088562,26.394615 
	C455.186981,26.318073 454.685364,26.215919 453.854401,25.897160 
	C452.269257,24.869688 452.585480,23.593863 452.791443,22.062418 
	C453.493713,21.256748 454.151917,21.014074 455.184509,20.800886 
	C455.737030,20.718880 455.955872,20.695789 456.495392,20.647816 
	C458.264984,20.614882 459.098785,20.099941 458.535461,18.156013 
	C458.453552,16.886354 458.852264,16.117083 459.694000,15.176888 
	C459.897461,14.905079 459.928131,14.440036 459.951294,14.208162 
	C460.450958,13.564072 460.861267,13.082406 461.614807,12.512695 
	C462.685059,12.021854 463.505157,11.851990 464.682098,12.030210 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M503.305115,13.231335 
	C499.391815,10.593127 499.891998,8.991816 503.992035,8.134762 
	C505.909546,7.733941 507.246246,6.675345 508.001648,4.487428 
	C508.499390,4.112288 508.930023,4.076435 509.685730,4.038613 
	C510.654327,4.808288 511.297943,5.579930 511.988586,6.658194 
	C509.081299,8.943732 507.368958,12.538240 503.305115,13.231335 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M491.131958,4.319933 
	C492.866821,4.172572 494.543701,3.753882 496.542786,3.029069 
	C497.876526,3.653025 498.325500,4.967326 497.376038,5.375308 
	C495.342041,6.249359 493.019623,6.918792 491.131958,4.319933 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M393.338806,19.199348 
	C391.908081,19.108562 390.850098,19.073753 389.395203,19.023066 
	C388.465118,14.791028 387.250458,11.631947 382.065216,14.431334 
	C381.210785,14.892613 380.492004,14.622647 380.054199,13.340183 
	C382.866211,9.591290 386.417542,9.581620 390.622467,10.975632 
	C391.443359,10.981965 391.888916,10.982454 392.668640,10.981422 
	C394.205139,10.929289 395.464386,11.245780 396.753235,10.203728 
	C397.125916,9.969569 397.293701,9.962483 397.712555,9.939404 
	C405.020172,11.273605 411.656128,9.870479 418.224426,7.222376 
	C419.953247,6.525392 422.175995,7.053592 424.610779,7.033168 
	C425.821930,8.018862 425.619141,9.407570 424.812927,9.688449 
	C418.388367,11.926744 414.781738,20.435114 405.712158,17.356977 
	C402.243561,16.179770 397.787262,18.768198 393.338806,19.199348 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M390.767395,10.698524 
	C387.650696,11.985729 383.876190,10.801144 380.376160,12.995848 
	C379.823792,13.003102 379.653809,12.995185 379.229462,12.962165 
	C379.249298,8.987870 380.948669,7.388556 385.059631,8.771394 
	C386.848846,9.373241 388.934723,9.079862 390.767395,10.698524 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M396.598755,9.980980 
	C396.604523,12.360932 395.155701,12.228056 393.226074,11.241591 
	C393.829559,9.828005 395.072845,10.031588 396.598755,9.980980 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M508.113892,1014.145996 
	C505.440826,1012.588318 503.428375,1010.501770 501.807465,1015.381104 
	C500.443939,1019.485718 498.339203,1014.386414 495.996643,1015.007690 
	C495.996643,1016.359192 495.996643,1017.812622 496.034119,1019.658569 
	C496.071564,1020.051086 496.042297,1020.053772 496.048401,1020.063538 
	C490.116272,1020.305359 484.815826,1018.646667 480.931824,1014.041565 
	C479.239929,1012.035522 477.669708,1009.937927 475.827362,1008.038269 
	C474.167114,1006.326416 472.729279,1005.114441 469.940887,1005.536743 
	C466.377960,1006.076416 465.133820,1003.288269 464.704041,1000.272522 
	C464.300781,997.442749 463.264740,996.445129 460.464294,998.066589 
	C457.547791,999.755432 455.085327,998.303894 452.610474,996.093750 
	C451.594055,992.427917 453.730042,991.085876 456.304871,990.114624 
	C457.992584,989.478027 459.809326,989.662109 461.961975,989.433167 
	C463.148560,989.330750 463.886688,989.523865 464.865997,990.143250 
	C465.991211,991.187561 466.338959,992.381287 467.525208,993.356079 
	C468.949646,994.705444 469.788116,996.018494 470.198608,997.922729 
	C470.427979,999.020264 470.752930,999.598450 472.016083,999.266235 
	C472.930511,999.061523 473.555420,999.051392 474.494873,999.210083 
	C476.246124,999.861816 477.464447,1001.492798 479.613770,1000.105774 
	C484.228271,998.397827 488.112610,1001.078308 492.624603,1001.087280 
	C494.967285,1000.809204 496.621490,1001.425293 498.201294,1003.173218 
	C498.826050,1004.107300 499.068207,1004.802246 499.375214,1005.836914 
	C500.533325,1009.046753 503.378693,1008.245483 505.755615,1009.151245 
	C508.212708,1010.331177 510.419556,1011.032166 513.160889,1010.227295 
	C514.097046,1009.916748 514.724915,1009.711731 515.676514,1009.406128 
	C516.458557,1009.246582 516.913635,1009.251099 517.697998,1009.426453 
	C518.852295,1010.016663 519.451599,1010.730652 520.183838,1011.788818 
	C520.328064,1012.351868 520.318787,1012.592102 520.102905,1013.132019 
	C519.691101,1013.565491 519.485901,1013.699158 518.934937,1013.962891 
	C517.620178,1014.330811 516.630920,1014.400024 515.262939,1014.471924 
	C513.135559,1014.774170 511.406006,1015.542664 509.255737,1014.563965 
	C508.729279,1014.344299 508.553986,1014.286072 508.113892,1014.145996 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M528.239441,1013.324951 
	C527.770996,1013.152893 527.597168,1013.096130 527.174011,1012.924622 
	C526.388245,1012.197876 526.409363,1011.510864 526.823425,1010.478027 
	C527.142273,1009.978210 527.291687,1009.794128 527.703979,1009.374878 
	C530.464172,1007.114380 533.887634,1006.696777 536.475281,1004.144714 
	C538.795105,1001.989807 541.396423,1000.851074 543.608826,999.156433 
	C547.013794,996.548340 550.195129,999.472900 553.798950,999.691406 
	C554.615601,1000.513489 554.771301,1001.223389 554.554932,1002.353760 
	C554.140625,1003.104980 553.789307,1003.434753 553.030884,1003.797729 
	C552.265686,1004.000305 551.825500,1004.047852 551.080200,1004.045166 
	C550.216309,1004.060364 549.683899,1004.151978 549.187561,1004.908630 
	C548.894409,1005.555115 548.721436,1005.934814 548.383423,1006.614807 
	C546.690979,1010.908264 546.422302,1015.988586 539.981384,1016.027344 
	C538.655212,1015.839233 537.811890,1015.387878 536.666504,1014.776978 
	C535.760193,1014.330505 535.145691,1014.070496 534.204346,1013.776245 
	C533.373291,1013.375610 532.846558,1013.066895 531.917603,1013.065125 
	C530.593018,1013.242981 529.590759,1013.583984 528.239441,1013.324951 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M518.323120,1010.091675 
	C517.558044,1009.989136 517.111633,1009.992737 516.330566,1009.993896 
	C515.394104,1010.013611 514.861084,1010.210571 514.216187,1010.833130 
	C511.388000,1012.572449 508.824860,1012.636414 506.176270,1010.348511 
	C503.906250,1007.953613 501.008972,1008.255920 498.235291,1007.228699 
	C497.970917,1006.256897 497.961517,1005.509521 497.964417,1004.388611 
	C498.813843,1002.997620 499.998505,1002.968445 501.156158,1002.949158 
	C509.718597,1002.806763 518.282471,1003.520081 526.845764,1002.907898 
	C527.509094,1002.860535 528.125244,1003.212524 528.980103,1003.820435 
	C530.165466,1006.019287 529.614685,1007.743896 528.275391,1009.713257 
	C528.026306,1010.032532 528.000000,1010.005737 528.013062,1010.015381 
	C526.868408,1010.588501 525.976318,1011.421631 525.211304,1012.828979 
	C524.858093,1013.224243 524.712463,1013.384888 524.326355,1013.764465 
	C522.877991,1014.094299 521.662048,1014.254883 520.369507,1013.033813 
	C520.132019,1012.646973 520.117615,1012.169312 520.073975,1011.934387 
	C519.481567,1011.278381 519.100342,1010.702332 518.323120,1010.091675 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M508.991577,1014.717896 
	C510.746796,1014.018982 512.588989,1013.635376 514.824097,1013.306152 
	C515.902588,1014.570984 515.400452,1015.527039 514.329956,1016.657471 
	C513.991150,1018.957153 513.645691,1021.058777 511.003754,1020.864258 
	C507.741028,1020.624084 509.856750,1018.308044 509.700592,1016.850525 
	C509.634064,1016.229614 509.294189,1015.638062 508.991577,1014.717896 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M514.454529,1016.980652 
	C514.419495,1015.922119 514.742615,1014.940674 515.141418,1013.659912 
	C516.226013,1013.206238 517.234924,1013.052002 518.628540,1012.941284 
	C519.786438,1013.956909 521.047974,1014.399109 521.835571,1015.767822 
	C520.379944,1022.149048 517.352356,1017.866211 514.454529,1016.980652 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M527.956177,1013.574707 
	C529.058899,1012.800354 530.172974,1012.391052 531.647095,1011.971191 
	C532.423340,1012.042297 532.775085,1012.239258 533.200317,1012.829590 
	C533.408752,1013.809143 533.023926,1014.254578 532.238281,1014.794678 
	C531.962830,1015.411316 531.937927,1015.848450 531.892578,1016.613342 
	C531.139099,1017.686890 531.027283,1018.767334 530.255859,1019.832092 
	C530.068298,1020.070679 529.615662,1020.140991 529.390625,1020.178284 
	C527.430298,1019.882629 525.117798,1019.907288 527.250061,1017.157166 
	C527.997375,1016.193420 528.003540,1015.073547 527.956177,1013.574707 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M522.194824,1015.937012 
	C520.449707,1015.908264 519.368469,1015.251282 519.031250,1013.326538 
	C519.201782,1012.936279 519.390259,1012.887695 519.855347,1012.743042 
	C521.222107,1012.917786 522.312195,1013.188538 523.744141,1013.721375 
	C523.537109,1014.595154 522.988220,1015.206848 522.194824,1015.937012 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M525.329590,1013.081421 
	C524.957764,1011.449951 525.128784,1009.998779 527.651245,1010.025818 
	C527.684387,1010.755249 527.342590,1011.485535 526.962769,1012.512878 
	C526.501648,1012.906372 526.078552,1013.002808 525.329590,1013.081421 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M393.049133,19.475420 
	C396.504913,16.474098 401.903656,18.527233 404.942657,14.394645 
	C405.385559,13.792413 406.264832,14.638672 406.934753,15.220825 
	C410.349854,18.188452 414.615509,17.322866 415.944183,13.210944 
	C416.568207,11.279863 417.691376,11.279809 419.168335,10.903808 
	C421.299744,10.361186 423.951233,10.524818 424.989624,7.410893 
	C425.049622,7.047163 425.518585,7.008710 425.753448,6.995427 
	C427.659760,4.894687 429.723846,7.023583 431.790588,6.254521 
	C433.132324,5.707998 434.221100,5.372333 435.652344,5.002413 
	C437.737305,7.361175 441.200348,4.529293 442.925293,7.589122 
	C438.244812,13.068472 431.812897,13.399757 425.504974,13.994672 
	C421.726929,14.350985 418.883789,16.898670 415.427185,18.047098 
	C411.598114,19.319260 406.976532,17.795305 403.798645,21.353228 
	C403.602386,21.572950 402.680115,21.537262 402.476562,21.297308 
	C400.152252,18.557070 397.747772,19.231220 395.125793,20.837626 
	C394.453064,21.249763 393.540527,20.668612 393.049133,19.475420 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M431.701538,5.970908 
	C430.871948,9.621272 428.373535,5.929031 426.367340,6.996308 
	C426.914398,2.338184 429.208282,4.471530 431.701538,5.970908 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M535.960938,1014.000244 
	C537.032837,1013.976868 537.839478,1014.226379 538.853882,1014.719116 
	C540.773071,1017.177612 542.446106,1018.781067 544.403198,1015.124268 
	C545.160706,1013.708801 546.597351,1012.989746 548.151917,1013.500916 
	C550.774353,1014.363159 552.479675,1012.770996 554.714600,1011.499756 
	C555.288147,1011.494812 555.516296,1011.545105 556.005493,1011.828979 
	C556.202454,1012.490234 556.138306,1012.917847 556.052002,1013.669312 
	C557.063293,1018.513672 553.800232,1019.425232 550.317261,1020.561035 
	C548.239868,1021.280640 546.282349,1020.630310 544.314758,1021.793823 
	C544.049988,1022.015686 543.950378,1022.405090 543.908508,1022.600098 
	C537.305786,1024.707886 537.002197,1020.007324 536.188599,1015.317871 
	C535.919373,1014.728638 535.807556,1014.500854 535.960938,1014.000244 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M574.989624,1011.776367 
	C574.924683,1014.947510 572.340393,1014.780518 570.348511,1015.860962 
	C567.283569,1014.435791 563.639832,1015.128052 561.110596,1012.232910 
	C560.217712,1007.921570 563.904175,1008.165955 566.210938,1006.600708 
	C566.768005,1006.350159 566.999207,1006.268494 567.592651,1006.119873 
	C568.443604,1006.031067 568.928528,1006.062378 569.751465,1006.270020 
	C572.106018,1007.680359 573.498413,1009.569641 574.989624,1011.776367 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M567.232544,1006.699036 
	C566.037964,1009.705811 561.993896,1008.224365 561.094421,1011.610718 
	C559.634460,1011.954895 558.332825,1011.995361 556.648926,1012.049194 
	C556.266602,1012.062561 556.051270,1012.007874 555.941895,1011.988342 
	C554.669922,1010.590942 554.558838,1008.959534 554.763367,1006.869019 
	C554.912659,1006.220032 554.997742,1005.969666 555.285645,1005.384766 
	C557.851990,1002.664856 560.136963,1006.187439 562.854797,1005.065796 
	C564.264648,1004.832947 565.288330,1004.853821 566.618896,1005.440735 
	C567.089844,1005.874146 567.225342,1006.099854 567.232544,1006.699036 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M549.968201,1020.226196 
	C551.241699,1017.532166 556.258728,1018.917786 555.760986,1014.221680 
	C559.154175,1014.574707 559.557556,1016.061523 557.266846,1018.154297 
	C555.997925,1019.313660 554.482849,1020.203430 552.972534,1021.533569 
	C552.482666,1021.861572 552.098999,1021.870605 551.426392,1021.874084 
	C550.598938,1021.554810 550.160095,1021.155090 549.968201,1020.226196 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M452.589478,995.632080 
	C455.869843,996.515991 458.386475,999.320129 461.811798,995.609009 
	C463.677887,993.587341 465.968842,995.872681 466.003906,998.872559 
	C466.051086,1002.907532 466.623352,1006.292480 471.999420,1003.597473 
	C473.065521,1003.062988 474.052002,1003.395203 474.547272,1004.396057 
	C476.409698,1008.159607 481.018219,1009.638428 482.314240,1013.956665 
	C482.628937,1015.005432 484.223694,1016.373474 485.241058,1016.382812 
	C489.083679,1016.418213 491.884735,1018.982971 495.672333,1020.026733 
	C495.835663,1023.553955 493.400513,1021.982300 491.318481,1022.012756 
	C490.163513,1020.850769 488.907654,1021.057190 487.381531,1020.958374 
	C486.339478,1020.276123 485.543976,1019.967896 484.265991,1020.210144 
	C482.929260,1020.272522 481.918457,1020.263489 480.528564,1020.266357 
	C478.310364,1020.905151 476.574341,1020.089600 474.837219,1019.783020 
	C471.760498,1019.240112 471.659576,1017.361877 473.224091,1015.134521 
	C476.177551,1010.929810 475.018921,1008.561523 469.673950,1008.008423 
	C469.349518,1007.974854 469.018555,1008.007446 468.347717,1008.023315 
	C466.906433,1008.274292 465.808075,1008.515198 464.371521,1008.766724 
	C461.652222,1008.132874 459.448029,1006.896545 456.817139,1007.107971 
	C454.576782,1007.288025 452.918671,1006.053467 452.261200,1003.931641 
	C451.254822,1000.684082 449.398804,1000.397766 446.533966,1001.630737 
	C439.244751,1004.767761 438.229034,1004.595825 432.694244,998.851746 
	C430.664581,996.745483 428.427307,996.181641 425.632812,995.873962 
	C420.252533,995.281555 414.630829,995.859558 409.591278,993.137939 
	C408.704376,992.658936 407.638336,992.712158 406.642639,992.868408 
	C402.665802,993.492615 399.484070,992.002625 396.639404,988.852600 
	C396.895599,985.327454 399.392822,985.513489 401.412689,985.811096 
	C412.501617,987.444763 423.643036,988.480469 434.817444,989.272949 
	C437.812408,989.485352 440.292450,990.204773 441.767395,993.720032 
	C443.203888,997.143799 446.991516,995.156250 450.184753,995.286499 
	C451.153534,995.150208 451.701385,995.222229 452.589478,995.632080 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M468.240692,1008.292175 
	C472.039398,1006.475342 476.866669,1008.473694 477.742096,1012.122192 
	C478.135162,1013.760498 477.090454,1014.556702 475.696381,1014.964294 
	C474.354004,1015.356689 472.967468,1015.911926 473.243958,1017.530823 
	C473.637756,1019.836487 475.421051,1017.787537 476.446289,1018.309265 
	C477.473297,1018.831909 478.509033,1019.337280 479.845215,1020.064270 
	C477.387299,1024.200806 474.127136,1023.748169 470.321136,1021.182434 
	C467.931427,1018.805908 467.995300,1016.887024 470.496338,1014.963440 
	C471.681580,1014.051758 474.016174,1013.745422 473.553009,1011.644409 
	C472.995880,1009.116943 470.416504,1009.202148 468.240692,1008.292175 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M484.184235,1020.500366 
	C484.931305,1018.812622 485.920685,1018.560913 486.964142,1020.609253 
	C486.156952,1020.861328 485.292694,1020.790222 484.184235,1020.500366 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M536.007080,1013.517883 
	C536.182617,1013.930603 536.139160,1014.133850 536.063416,1014.646729 
	C534.952759,1014.968384 533.874329,1014.980469 532.391846,1014.983337 
	C532.251221,1014.484253 532.514648,1013.994324 532.886414,1013.247314 
	C533.151245,1012.848022 533.307739,1012.705811 533.772339,1012.639404 
	C534.649536,1012.912964 535.218811,1013.110657 536.007080,1013.517883 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M530.406006,1019.993408 
	C530.059021,1018.931580 529.821960,1017.704102 531.544312,1017.025146 
	C531.984253,1018.117981 532.169189,1019.322510 530.406006,1019.993408 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M564.004272,9.591709 
	C563.495544,9.752597 562.933105,9.636859 562.180115,9.298508 
	C562.783569,8.004703 563.433899,8.112412 564.004272,9.591709 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M558.799194,9.126020 
	C558.689392,10.422413 558.168945,11.474349 556.984375,9.345983 
	C557.427429,9.064078 557.927612,9.066225 558.799194,9.126020 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M549.571533,1019.941162 
	C550.303833,1020.380249 550.652527,1020.838745 551.069336,1021.582886 
	C549.066345,1021.915344 546.995239,1021.962036 544.487061,1022.012207 
	C545.070129,1019.599182 547.239075,1020.054932 549.571533,1019.941162 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M573.989746,5.759938 
	C573.089294,5.919363 572.320496,5.651263 572.037842,4.329269 
	C572.543457,4.183858 573.095703,4.353984 573.825745,4.760050 
	C574.001221,5.165771 573.998901,5.335553 573.989746,5.759938 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M575.684875,6.016502 
	C575.911560,6.667507 575.855225,7.447679 575.041870,7.485437 
	C574.734192,7.499718 574.398743,6.913649 574.030762,6.309230 
	C574.438721,6.014319 574.891907,6.011414 575.684875,6.016502 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M80.316940,817.992188 
	C79.554657,817.993469 79.111145,817.993713 78.334991,817.993896 
	C77.275856,817.801697 76.894783,817.285156 76.848145,816.299988 
	C76.371262,812.436829 75.333160,809.456177 70.911102,808.870300 
	C67.749832,808.451416 66.275261,803.654785 68.475586,801.141296 
	C70.591164,798.724792 72.539566,796.990723 67.812553,795.225098 
	C65.370445,794.312927 65.103455,792.105164 67.629852,790.071899 
	C69.653046,790.025757 69.689713,792.669189 71.926880,792.117188 
	C75.806435,790.171387 78.932541,790.401978 81.173973,794.393311 
	C83.171295,792.951477 82.419868,790.826843 83.383102,789.002014 
	C84.022232,788.039307 84.621078,787.558472 85.667923,787.004272 
	C88.497391,786.634766 91.501602,784.387512 92.761543,789.020508 
	C92.306839,791.041809 91.366356,792.533691 91.356079,794.645569 
	C88.296776,798.798096 86.656227,803.785522 81.889854,806.115723 
	C80.997116,806.552246 79.866692,806.976562 80.152222,808.177429 
	C80.517189,809.712524 81.822693,809.491882 82.984421,809.205933 
	C85.068611,808.692932 87.151970,808.187439 89.196388,807.510315 
	C92.533928,806.404968 95.704727,806.543213 98.586647,809.462524 
	C99.013763,810.489197 99.061813,811.218262 98.902664,812.324036 
	C97.878098,815.047363 96.135681,816.720642 93.818100,818.343811 
	C91.103622,819.355896 89.901093,821.822449 87.328384,822.938965 
	C86.555458,822.995239 86.111969,822.995178 85.335876,822.994263 
	C82.455841,822.279053 83.210724,818.681396 80.316940,817.992188 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M139.940552,888.618652 
	C139.728165,890.803040 140.666962,893.123474 138.290680,894.826477 
	C135.459198,894.789246 133.400284,895.351746 132.165375,897.838806 
	C130.291245,901.613220 128.150269,900.613281 127.207176,897.493652 
	C126.220856,894.230896 124.361214,894.803528 122.904465,896.183350 
	C120.125526,898.815369 117.904602,896.937195 115.314957,896.134888 
	C113.581856,895.597961 113.657372,895.209717 114.451385,893.873413 
	C117.138962,889.350159 114.650566,885.647278 111.567055,883.029419 
	C107.836525,879.862122 106.181465,876.304199 107.325752,871.808350 
	C108.161659,868.524048 106.465210,865.801270 105.524109,863.335449 
	C104.216507,859.909180 101.919647,864.841492 99.390472,863.979248 
	C98.639297,862.229004 97.878937,860.482666 98.979179,858.369873 
	C99.407112,854.110962 101.325317,850.144775 98.476295,846.004639 
	C98.100616,844.960388 98.108475,844.241516 98.502808,843.202148 
	C107.749260,840.510193 112.375931,845.143799 110.357063,854.073547 
	C109.526146,857.748779 111.190071,861.631958 110.320396,865.562622 
	C109.969475,867.148743 111.903137,868.713684 114.492691,868.408813 
	C115.337975,868.272339 115.826523,868.279724 116.665924,868.474731 
	C117.644173,869.045166 118.050102,869.657471 118.456429,870.697876 
	C118.723129,872.052246 118.774635,873.199524 118.339035,873.999817 
	C115.536652,879.148621 118.950516,883.867798 119.285530,888.781250 
	C119.437271,891.006714 121.080658,892.609558 123.494225,891.770386 
	C126.387009,890.764526 130.849182,893.741882 132.322723,888.219788 
	C132.908966,887.649231 133.317581,887.435669 134.118500,887.286255 
	C135.804504,887.379578 137.106293,887.752441 138.768860,888.001648 
	C139.297165,888.184204 139.499344,888.282471 139.940552,888.618652 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M57.330574,735.197754 
	C57.122093,734.925049 56.932030,734.488708 56.818436,734.279541 
	C54.751549,731.808960 54.171562,729.337463 55.754818,726.238403 
	C58.701576,724.787292 60.817226,727.739441 63.857277,727.148804 
	C66.178131,727.804443 68.018578,728.585693 69.488953,730.611938 
	C70.330185,733.396851 71.413818,735.199036 73.993408,736.515564 
	C79.141418,739.143005 79.849480,743.844177 76.174942,748.628235 
	C74.814415,750.399597 75.637253,751.311157 76.968483,752.261230 
	C78.529228,753.375061 80.778587,753.924316 80.160004,756.907959 
	C79.361961,757.775574 78.636734,758.055481 77.456367,758.278381 
	C75.083290,758.463379 73.223129,758.117493 71.196907,756.835205 
	C69.937218,755.611450 69.535103,754.364929 69.046432,752.758057 
	C68.699020,751.100891 68.298935,749.580078 68.887947,748.513855 
	C71.693649,743.435120 67.397163,741.216797 65.001060,738.205139 
	C64.144020,737.127930 63.137627,737.074890 61.926033,737.501648 
	C59.840977,738.236023 58.446751,737.346741 57.330574,735.197754 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M72.148392,792.704895 
	C71.835953,793.787476 71.734222,794.834290 70.644691,794.549866 
	C68.690971,794.039856 69.215248,791.857239 68.133560,790.310547 
	C68.019386,789.165833 67.866257,788.281006 68.108421,787.522400 
	C69.043839,784.592285 68.383926,783.304382 65.161461,784.424866 
	C64.112251,784.789673 63.145508,784.808594 62.616829,783.715454 
	C61.983006,782.404846 63.191299,782.058289 63.968430,781.532166 
	C66.739822,779.656250 66.556740,775.615173 70.086525,773.922363 
	C71.898880,773.053101 68.037933,770.837280 69.009125,768.359192 
	C69.881218,767.413147 70.828705,767.591553 72.119812,767.752075 
	C73.641502,768.062561 74.721313,768.523254 75.952835,769.464600 
	C77.205383,771.221924 78.187500,772.756653 80.313850,773.621521 
	C81.833366,775.375305 83.085838,776.901672 82.573631,779.420166 
	C79.080353,783.136780 75.454872,782.125916 71.383400,779.833679 
	C71.992973,782.378662 73.871994,782.987915 75.173752,784.515991 
	C76.202187,786.581604 75.766922,787.982056 73.937332,788.761108 
	C72.209534,789.496643 72.163017,790.881653 72.148392,792.704895 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M57.069546,735.331055 
	C59.107903,735.694092 61.032410,738.714172 63.139839,735.031738 
	C63.487492,734.424316 64.548843,734.249817 64.673080,735.014099 
	C65.473984,739.940674 75.440475,741.241455 70.061394,748.630127 
	C69.681946,749.151367 70.072136,750.232849 70.103073,751.464355 
	C67.103630,754.245422 64.925217,752.191284 62.385914,750.200562 
	C62.092255,749.901123 62.072598,749.444336 62.043045,749.217651 
	C59.486511,746.359436 57.732399,743.306946 56.974560,739.359131 
	C56.995876,737.912598 57.006439,736.824829 57.069546,735.331055 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M62.002991,750.252075 
	C64.899338,749.169861 66.546677,752.514404 69.703476,751.914246 
	C70.451881,752.967529 70.802071,754.060181 71.171410,755.498901 
	C72.306564,757.624268 74.712494,758.991394 72.815674,761.933838 
	C72.215881,762.495056 71.821213,762.735291 71.082375,763.025757 
	C69.499123,763.319641 68.502174,764.042664 67.240616,764.917603 
	C64.491272,763.713257 61.496075,762.677185 64.031097,758.938538 
	C65.218170,757.187866 63.572392,757.129456 62.608269,756.766602 
	C58.325119,755.154175 58.247669,753.096680 62.002991,750.252075 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M54.246773,713.268982 
	C54.054832,711.450256 53.843575,710.043579 52.334789,709.187988 
	C51.014545,708.439514 49.476379,707.498047 50.443890,705.822205 
	C51.406590,704.154724 53.347885,703.625122 55.196949,704.247498 
	C57.902439,705.158081 60.126293,705.606995 60.107193,701.195312 
	C63.432652,699.890503 65.051125,702.093506 66.804375,704.671265 
	C67.183975,706.465576 66.870049,707.732056 65.688126,709.125000 
	C62.046329,710.002197 59.911961,712.922546 56.391434,713.977539 
	C55.476665,713.956421 54.982128,713.805603 54.246773,713.268982 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M67.184814,705.498596 
	C64.872208,703.867432 62.858818,702.413696 60.498512,700.872681 
	C60.151592,700.785278 60.089523,700.390320 60.041065,700.196411 
	C59.649784,699.644775 59.326756,699.146240 58.884781,699.035339 
	C51.173508,697.101624 51.055279,696.936768 51.405052,688.942688 
	C51.440861,688.124268 51.327721,687.299927 51.685181,686.146179 
	C52.430054,685.303040 53.132618,685.142700 54.247898,685.286499 
	C56.987045,687.103882 56.392021,690.619385 59.028454,692.571045 
	C59.781296,693.229248 60.287136,693.659424 61.107044,694.228149 
	C62.095161,694.948547 62.708469,695.586914 63.524540,696.486389 
	C64.721703,698.818359 66.488220,698.326538 68.516479,698.102173 
	C69.077080,698.173462 69.299309,698.228271 69.830505,698.440857 
	C70.691605,699.052917 71.076256,699.633057 71.520554,700.594116 
	C71.752861,701.385010 71.799873,701.852295 71.724350,702.674133 
	C71.460434,703.705933 71.117912,704.317566 70.431206,705.125000 
	C69.328026,705.888733 68.424896,705.948242 67.184814,705.498596 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M99.249481,842.662231 
	C99.026520,843.711609 99.015930,844.471008 99.006546,845.610107 
	C98.519577,846.582031 97.867401,846.787109 96.789307,846.719482 
	C95.161438,846.150146 94.648872,844.723389 93.160492,843.957031 
	C92.317619,843.439880 91.899040,842.937256 91.235352,842.221436 
	C91.007339,841.990234 91.006111,841.495117 91.003967,841.247559 
	C90.426384,840.318604 90.122238,839.517578 89.982979,838.325684 
	C89.752968,837.195496 89.505890,836.390930 89.130676,835.293213 
	C88.198036,834.043945 88.032448,832.895325 88.004074,831.351929 
	C88.000420,828.958923 88.001389,826.917908 88.003128,824.439514 
	C88.385307,823.684387 88.820007,823.502869 89.662964,823.584717 
	C90.388733,824.004578 90.697517,824.391479 90.875511,824.856262 
	C92.575745,829.296387 92.143349,834.845276 97.641365,837.322266 
	C98.508087,837.712708 98.508720,839.392883 99.373169,840.639282 
	C99.611923,841.437561 99.588097,841.906860 99.249481,842.662231 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M53.626419,713.054443 
	C54.447758,712.997131 54.921158,713.007263 55.749214,713.029663 
	C57.898479,715.453308 58.048237,718.329468 58.420864,721.583374 
	C58.482090,723.209961 58.265606,724.346069 57.363464,725.705933 
	C54.942516,728.364685 56.268276,730.841003 56.743473,733.679077 
	C55.590302,733.554443 54.475750,733.038452 53.177757,732.267334 
	C52.559452,731.615417 52.213329,731.160645 52.033676,730.291443 
	C55.589172,726.306335 55.525200,722.909546 51.865082,719.065063 
	C50.051353,717.159973 51.448208,714.802185 53.626419,713.054443 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M52.485420,685.616211 
	C51.994076,687.722534 52.988518,689.318115 52.518410,691.281738 
	C51.578773,695.206482 53.651730,697.266724 57.890888,697.203125 
	C59.459797,697.179688 59.945152,697.915100 60.003212,699.631042 
	C52.354099,700.502136 50.559216,699.565613 49.400539,694.838562 
	C49.054913,693.428528 49.534790,692.299927 49.789459,691.035706 
	C50.357193,688.217346 50.714516,685.380859 48.981396,682.400635 
	C48.960873,682.038147 48.993649,682.007385 49.010876,681.993225 
	C49.772045,681.571228 50.493778,681.740173 51.527966,682.276611 
	C52.644405,683.266663 52.917423,684.197998 52.485420,685.616211 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M90.958038,842.352539 
	C91.559517,842.169128 92.111694,842.347961 92.837593,842.767090 
	C94.200722,844.050171 93.443146,845.808289 94.728470,847.464111 
	C94.560265,849.896912 94.278809,852.487610 92.055679,852.484070 
	C89.719749,852.480408 88.183830,850.159851 87.267899,848.102417 
	C85.727531,844.642395 90.829323,845.307556 90.958038,842.352539 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M56.688656,739.154724 
	C59.212864,741.778442 60.714001,744.944458 61.972969,748.626282 
	C60.579647,749.065735 59.698891,747.940186 58.543484,747.412354 
	C52.366268,744.590576 52.357086,744.610657 56.688656,739.154724 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M95.691772,846.128906 
	C96.750687,845.997192 97.502899,845.996826 98.631424,845.993164 
	C100.234688,846.097229 101.721733,846.547119 101.574966,847.798645 
	C101.177467,851.187927 100.353653,854.527222 99.357956,857.956787 
	C98.501869,856.155029 98.369499,854.358643 98.855804,852.369934 
	C99.411125,850.099121 98.705421,848.127380 95.396362,848.015076 
	C95.008568,848.009460 95.000732,848.000732 95.000099,848.000366 
	C94.544495,847.290771 94.899666,846.761047 95.691772,846.128906 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M80.151276,818.342285 
	C81.663506,816.158752 83.344170,813.873718 83.380363,818.869019 
	C83.388687,820.017944 84.383163,821.159729 84.963531,822.649170 
	C79.223289,826.161804 81.991714,819.993225 80.151276,818.342285 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M67.011826,765.336792 
	C67.107925,763.536926 67.701904,762.407532 69.668365,762.098267 
	C70.821396,762.980591 71.792435,763.745422 72.622620,765.123901 
	C73.074707,766.488220 73.046967,767.476501 72.235817,768.707275 
	C71.196899,768.747620 70.398933,768.489502 69.306572,768.108521 
	C68.030319,767.484070 67.305672,766.768799 67.011826,765.336792 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M90.447487,824.055420 
	C89.555756,824.000122 89.112328,824.000305 88.336403,824.001343 
	C87.686394,823.889832 87.426994,823.698669 87.112320,823.211975 
	C88.658592,821.223083 89.355743,818.428467 92.646851,818.004944 
	C94.947479,820.947449 94.493317,823.070557 90.447487,824.055420 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M87.751816,831.200806 
	C89.179329,831.830872 88.965614,833.088989 89.002258,834.635193 
	C87.552795,834.196289 86.508568,833.223633 87.751816,831.200806 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M89.707138,838.042480 
	C90.871941,838.544373 90.993530,839.410156 90.999184,840.663330 
	C90.492867,840.017334 87.040977,840.637451 89.707138,838.042480 
z"
    />
    <path
      fill="#10100F"
      opacity="1.000000"
      stroke="none"
      d="
M56.497536,760.986694 
	C56.982300,760.123047 57.586037,760.023438 58.175140,760.425903 
	C58.653679,760.752808 58.649841,761.276978 58.159172,761.590942 
	C57.560371,761.974060 56.958160,761.867126 56.497536,760.986694 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M76.765030,816.187561 
	C77.262878,816.493469 77.522713,816.986877 77.892456,817.737061 
	C77.614738,818.523499 77.073219,818.903931 76.521263,818.415588 
	C75.847694,817.819580 76.075111,817.057434 76.765030,816.187561 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M51.787315,730.175964 
	C52.322514,730.474487 52.636982,730.946533 52.972885,731.715393 
	C52.110813,731.807983 50.956497,731.836243 51.787315,730.175964 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M71.308365,253.205811 
	C65.157532,248.093567 64.620476,242.244553 66.152298,234.943512 
	C67.073853,230.551254 67.632431,227.115875 71.686089,224.583710 
	C74.088112,223.083221 71.941261,221.081833 70.961052,219.463226 
	C70.441216,218.604813 69.660744,217.695129 70.494537,216.798874 
	C71.470581,215.749741 72.223068,216.982300 73.036285,217.386215 
	C74.659325,218.192398 76.233887,219.136307 77.928474,219.744827 
	C79.142624,220.180801 80.700714,220.424469 81.556343,219.092285 
	C82.415764,217.754211 81.516685,216.701065 80.605865,215.596542 
	C79.273903,213.981277 77.308434,213.394104 75.763313,212.162720 
	C74.336136,211.025314 73.569122,209.538681 74.302246,207.782501 
	C75.101006,205.869080 76.793922,205.789032 78.523643,206.333496 
	C82.771362,207.670532 84.576637,206.660294 83.503044,201.837540 
	C82.667313,198.083313 84.835007,196.477036 88.549011,197.635406 
	C90.147903,198.134109 91.992653,198.561829 93.278931,197.339157 
	C94.647041,196.038727 93.818420,194.293045 93.397835,192.670349 
	C92.878273,190.665833 91.812881,189.019455 90.495476,187.525711 
	C87.316864,183.921631 87.812492,179.510788 92.098587,177.590179 
	C95.746666,175.955460 97.952789,174.577728 93.835037,171.116898 
	C93.164482,170.553314 92.807014,169.592560 93.411835,168.906281 
	C94.430923,167.749954 95.226677,168.791687 95.738060,169.566101 
	C96.613121,170.891190 97.851074,171.028961 99.595261,170.990585 
	C104.060547,172.445587 107.342323,175.207581 111.086517,177.882614 
	C112.277298,178.840668 113.200974,179.575455 114.269005,180.692139 
	C115.313126,182.852493 114.593643,184.363388 113.124504,186.023254 
	C112.674324,186.437805 112.484032,186.589966 111.987869,186.935974 
	C111.092972,187.425262 110.457283,187.629227 109.880592,187.935455 
	C106.850937,189.544144 101.238945,186.848129 101.055191,193.208633 
	C96.532593,195.479355 97.494965,199.782516 97.236885,203.608780 
	C97.049850,206.381821 93.988785,209.692032 98.970444,211.775024 
	C99.501228,212.061523 99.702477,212.199066 100.152115,212.608749 
	C100.759476,213.553009 100.854691,214.268890 100.651382,215.363037 
	C99.722504,217.072983 98.416870,218.195312 98.357803,220.324295 
	C96.858238,226.355148 93.344833,229.787201 87.416550,230.300690 
	C85.311066,230.483063 83.708000,231.358704 81.948059,232.918335 
	C80.802872,233.917755 79.658768,234.336243 78.726791,235.573059 
	C76.561897,237.783844 75.316376,240.524338 72.257141,241.195267 
	C71.032379,241.463867 69.443153,241.438202 69.555809,243.370773 
	C69.642227,244.853073 70.645416,245.756470 71.852364,246.342316 
	C72.725609,246.766159 73.755440,246.849228 74.930206,247.529022 
	C75.690567,248.353485 75.861389,249.052338 75.767387,250.174973 
	C75.193504,251.782043 74.125793,252.428848 72.702576,253.205322 
	C72.111511,253.368698 71.864578,253.390457 71.308365,253.205811 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M111.316689,178.732239 
	C107.524437,176.453674 103.063179,175.273026 100.237732,171.213440 
	C99.946335,169.549103 100.939369,168.623459 101.853256,167.291321 
	C102.862907,166.054550 104.019127,165.945831 105.193230,165.867523 
	C107.120209,165.738998 107.857910,164.614960 107.485275,162.828690 
	C106.921043,160.123917 108.129822,157.234375 106.303017,154.607300 
	C105.470795,153.410538 105.404907,151.015411 106.632721,150.456619 
	C110.389297,148.746948 110.499451,145.926285 110.158142,142.539124 
	C110.035179,141.318893 111.186485,140.478973 112.470726,140.321503 
	C114.458099,140.077789 114.850723,138.823837 114.653549,136.756424 
	C114.778839,134.799179 114.938629,133.231552 117.122284,132.552399 
	C119.557022,136.848984 121.390686,141.189453 120.384048,146.485306 
	C120.185791,147.064377 120.091461,147.287735 119.812271,147.817749 
	C116.510452,151.888016 113.253448,155.592850 114.362518,161.367859 
	C114.618683,161.893585 114.709366,162.112396 114.889069,162.680237 
	C113.938126,168.203903 114.829659,173.800186 111.316689,178.732239 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M115.732170,136.756744 
	C115.857025,139.224609 118.561501,143.059067 112.631493,141.643158 
	C110.057693,141.028610 110.770348,144.151199 111.388496,145.236298 
	C113.049446,148.151917 113.879189,150.109955 109.202652,150.089325 
	C106.731178,150.078400 106.575928,152.209061 107.226707,153.676346 
	C108.315781,156.131866 108.478355,158.415543 108.534637,161.122391 
	C108.673759,167.812515 109.118652,167.803268 102.385223,167.001251 
	C101.389732,165.668167 100.512894,164.390976 100.230606,162.993683 
	C99.747032,160.600006 100.570473,158.696976 103.358711,158.600052 
	C104.229454,158.569778 105.346062,160.573624 105.840080,158.274490 
	C106.156410,156.802322 105.434608,155.617783 103.967781,155.210175 
	C99.378372,153.934814 100.358040,152.495895 103.530891,150.346756 
	C106.355873,148.433258 108.723923,140.034119 107.757637,136.841461 
	C105.732285,130.149643 106.216400,129.447342 113.579178,128.981461 
	C115.574791,131.268585 113.981171,134.207077 115.732170,136.756744 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M115.964066,136.630814 
	C112.489265,135.351669 112.978790,132.595932 113.948608,129.345947 
	C114.142250,126.711487 113.544205,124.371735 115.392532,122.343819 
	C116.032272,121.641914 116.329803,119.849686 115.885872,119.060486 
	C112.006737,112.164322 117.983223,113.157967 121.337257,112.130272 
	C123.966393,111.324684 126.836327,113.499580 129.655212,110.774567 
	C130.659241,109.803986 132.758102,113.373306 133.990417,115.610916 
	C133.865997,116.114769 133.693939,116.273849 133.186737,116.539574 
	C131.913788,116.767662 131.011169,116.454567 129.741211,116.418381 
	C121.171440,116.277161 119.825394,117.692650 120.874100,125.754486 
	C121.144562,127.833710 120.044678,129.186890 118.966827,131.000778 
	C118.768303,131.736710 118.614548,132.141647 118.169632,132.742157 
	C115.862328,133.223465 116.013618,134.796265 115.964066,136.630814 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M118.269028,131.232742 
	C116.454575,130.636688 115.596909,129.844269 117.617630,129.155533 
	C119.358185,128.562286 120.187500,127.884109 119.815071,126.020790 
	C118.002571,116.952408 119.657356,114.999176 129.560974,115.005737 
	C130.728989,115.671417 131.310394,116.588310 132.749786,116.190018 
	C133.164886,116.004295 133.339508,115.997810 133.776077,115.973511 
	C134.038040,115.955696 134.516006,115.981300 134.754990,115.992577 
	C134.993973,116.003853 134.985321,116.494438 134.982941,116.739761 
	C133.941666,118.128120 135.149261,119.536804 134.316254,121.060211 
	C132.823914,122.469696 131.120972,122.403030 129.310120,123.138351 
	C125.366577,123.303101 122.466484,123.793297 122.888359,128.669479 
	C123.115227,131.291748 121.276711,132.485809 118.269028,131.232742 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M70.888680,253.256195 
	C70.999084,252.999390 71.496544,252.997650 71.745270,252.997299 
	C73.165970,253.620361 73.175133,255.202927 74.594421,256.039246 
	C75.010864,256.402710 75.168533,256.562683 75.509277,257.012238 
	C76.457947,259.154755 76.122849,260.981476 75.436142,263.129700 
	C75.120934,263.878998 74.887627,264.275635 74.350243,264.864380 
	C73.893318,265.164825 73.688179,265.241852 73.170059,265.281769 
	C72.510506,264.903229 72.203079,264.976929 71.619843,265.449310 
	C70.871216,265.596161 70.449745,265.503815 69.864471,265.030212 
	C70.839554,261.664246 68.331619,258.615936 69.784218,255.257706 
	C70.248314,254.495392 70.513298,254.004181 70.888680,253.256195 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M69.681213,255.144653 
	C69.711411,258.122375 73.037453,261.141754 70.144608,264.680725 
	C69.839180,265.114563 69.675728,265.225433 69.262581,265.176849 
	C69.012878,265.017426 68.988693,264.988922 68.974457,264.975037 
	C68.848953,264.149597 69.012711,262.837158 68.584869,262.602234 
	C62.844223,259.450531 66.550888,257.442139 69.681213,255.144653 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M352.048767,18.515404 
	C345.248169,21.299273 339.057373,25.198454 332.464905,28.152002 
	C329.561249,29.452904 325.837372,30.591051 322.607452,30.686565 
	C319.697449,30.772625 317.978455,31.605181 316.187592,33.433659 
	C313.489868,36.188000 310.394531,37.943207 306.367249,36.655972 
	C305.334686,36.325939 304.045227,36.097565 303.732635,37.133591 
	C302.503296,41.207985 297.136169,39.578445 295.521698,43.402859 
	C294.946259,44.765984 292.833435,43.351616 291.526703,42.690895 
	C290.535522,42.189716 289.051880,40.597782 288.583649,41.523678 
	C286.366180,45.908661 280.469208,45.212799 277.918396,49.994236 
	C274.484009,51.168613 272.176941,53.991646 268.450378,53.453613 
	C267.321625,52.485409 267.357788,51.560150 267.865356,50.267204 
	C268.611908,48.913334 269.577271,48.245853 270.654694,47.212082 
	C270.873077,46.949455 270.945648,46.476097 270.972839,46.238197 
	C274.088257,45.857014 277.099487,46.928417 278.953949,42.364464 
	C279.895233,40.047806 285.210815,38.470005 287.690430,38.498005 
	C295.390015,38.584957 301.139801,32.684311 308.600952,32.886295 
	C309.183441,32.902061 310.078064,32.510391 310.327179,32.041946 
	C313.822205,25.470028 321.358948,28.533073 325.748505,25.621351 
	C330.160797,22.694584 335.163422,22.591938 339.367035,20.040894 
	C339.500000,19.960207 339.575562,19.784958 339.848877,19.343307 
	C342.226898,17.664211 344.707123,17.234835 347.607300,16.936373 
	C348.738007,16.948095 349.486847,16.960062 350.609680,16.958742 
	C351.321960,17.376932 351.660309,17.808409 352.048767,18.515404 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M203.798920,82.291763 
	C205.164093,81.981300 206.080032,81.538383 206.862122,80.293205 
	C208.037674,76.780701 208.583633,73.094261 213.369125,73.504921 
	C213.831924,73.544647 214.359604,73.149109 214.527573,72.694016 
	C216.757736,66.651833 218.667694,70.715614 220.759430,73.254311 
	C222.592773,71.135056 223.241348,68.396980 225.620178,66.564720 
	C227.308182,65.688805 228.801849,65.714661 230.304367,65.722672 
	C236.260193,65.754440 239.429398,63.101978 240.465591,57.252586 
	C241.034790,54.039421 244.205093,52.497597 245.956070,54.947594 
	C248.805450,58.934494 251.387207,57.483162 254.393219,55.785202 
	C255.685516,55.055241 256.929596,54.160160 258.876984,54.223396 
	C259.455811,54.377777 259.676208,54.479450 260.143311,54.850800 
	C261.139557,58.076385 259.568573,60.012058 257.156921,60.959980 
	C254.055725,62.178932 252.059113,64.391571 250.008575,67.227341 
	C249.186523,67.957298 248.553574,68.303101 247.503906,68.588821 
	C245.779343,68.665886 244.826584,67.804039 243.849014,67.028938 
	C242.632980,66.064789 242.246994,67.040703 241.598099,68.156845 
	C238.766357,71.243851 236.330276,74.037735 231.667938,71.876869 
	C229.822342,71.021477 225.139786,76.151566 224.487900,79.179993 
	C224.279419,80.148514 224.397797,81.170380 224.221634,82.146439 
	C223.551086,85.861809 221.797165,86.464417 218.790482,84.015305 
	C217.498245,82.962730 216.630356,81.500587 215.419510,80.375748 
	C214.342545,79.375237 213.688248,79.995804 213.202454,81.468857 
	C213.030090,82.023018 212.948517,82.242760 212.694244,82.772659 
	C210.579865,85.282097 208.198547,85.924568 205.060989,84.978096 
	C204.514221,84.741493 204.307587,84.620369 203.866501,84.227753 
	C203.475174,83.497337 203.446075,83.032364 203.798920,82.291763 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M260.266235,54.800011 
	C256.522186,54.551693 254.869125,58.304882 251.453552,58.502125 
	C249.275848,58.627892 247.358215,58.907696 246.277420,56.930260 
	C245.384979,55.297459 244.284348,54.691879 242.696167,55.126801 
	C239.940948,55.881302 242.649597,57.094204 242.577484,58.099773 
	C242.279037,62.262539 235.638107,67.587227 231.364105,67.090393 
	C229.888367,66.918854 228.379456,67.032570 226.451569,67.022133 
	C224.195526,65.099785 224.290695,62.988739 225.713928,60.394135 
	C229.305191,59.210506 232.331924,56.973282 236.051071,58.412903 
	C237.740463,59.066837 237.880005,57.632046 237.985291,56.421848 
	C238.150330,54.524445 237.377640,52.093517 239.912247,51.301319 
	C242.589844,50.464432 245.071915,51.908173 246.573700,53.746258 
	C249.364120,57.161541 250.799149,58.000156 251.355194,52.632652 
	C251.479080,51.436630 252.135086,50.028610 254.051117,50.539070 
	C258.379974,51.692356 259.722809,48.213036 261.609863,45.576214 
	C262.317566,44.587337 261.436096,42.323181 263.345062,42.533672 
	C265.096344,42.726768 265.968903,44.392086 265.825684,46.923576 
	C263.165741,49.358414 262.693390,52.594784 260.266235,54.800011 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M225.648621,60.051857 
	C226.005539,62.055367 226.005585,64.101456 226.011551,66.587906 
	C225.022736,68.918961 223.725403,70.719444 223.126740,72.728165 
	C222.625732,74.409248 221.831192,75.557724 220.248932,75.635605 
	C217.637802,75.764122 219.116821,73.477020 218.576920,72.352646 
	C218.243393,71.658081 217.740692,71.044762 216.612839,69.327347 
	C216.599014,73.900116 215.085281,75.629066 211.303177,75.113380 
	C208.118637,74.679176 209.673187,78.741707 207.332413,79.827179 
	C206.837997,76.806450 206.726135,73.756157 210.356293,71.987587 
	C212.587631,70.900505 211.157669,68.807541 211.067276,67.083473 
	C210.954163,64.926094 210.512573,63.040997 214.323471,63.354271 
	C218.055222,63.661049 222.634277,64.428520 225.648621,60.051857 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M270.470520,46.961746 
	C271.011993,48.138165 270.535797,49.034512 269.283020,49.847885 
	C266.675568,51.169041 265.354462,50.707058 265.732239,47.445297 
	C265.962036,47.038338 265.982178,47.017952 265.992310,47.007618 
	C267.357605,46.989536 268.712799,46.981789 270.470520,46.961746 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M203.696457,82.016174 
	C204.021347,82.460342 204.010788,82.897484 203.994797,83.662750 
	C203.782639,84.687843 203.305038,85.091782 202.307068,85.132797 
	C202.004471,84.256462 202.007523,83.510742 202.015701,82.392807 
	C202.467560,82.016777 202.914276,82.012962 203.696457,82.016174 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M330.119507,987.646667 
	C329.318359,989.009888 327.739716,990.048096 327.187134,989.533020 
	C322.212250,984.895935 314.068848,989.199158 309.640533,983.167908 
	C308.690308,981.873596 306.951233,981.657471 305.507935,982.128296 
	C303.038574,982.933899 301.425201,982.751343 300.722229,979.826294 
	C300.299927,978.068970 298.522766,978.219788 297.051788,977.906982 
	C294.289398,977.319519 291.446777,976.382202 288.729584,977.073792 
	C283.059784,978.516663 281.194611,975.244141 279.979675,970.414795 
	C280.151550,969.913696 280.334320,969.782471 280.855652,969.646057 
	C281.866486,969.764526 282.473236,970.044495 283.298584,970.618530 
	C284.663666,972.046387 285.691528,973.471375 287.992798,972.356079 
	C289.167938,971.928711 290.064667,971.884399 291.315063,972.018188 
	C292.327026,972.199402 292.971802,972.439575 293.988464,972.572876 
	C295.013428,972.628296 295.698181,972.660034 296.731903,972.649414 
	C297.545654,972.634216 298.011292,972.639954 298.827576,972.679626 
	C300.874664,972.823853 302.302887,973.546570 303.805542,974.875610 
	C304.820435,975.774658 305.490540,976.610962 306.935364,976.751953 
	C307.646088,976.900146 308.041962,977.023193 308.708374,977.312622 
	C310.009033,978.133484 310.572937,979.324951 311.965088,980.039429 
	C312.764465,980.486450 313.294983,980.790771 314.126709,981.190430 
	C315.528351,981.884460 316.633514,982.481812 318.174927,982.865051 
	C320.292084,983.554871 321.875488,983.128906 323.584961,981.739563 
	C324.318207,981.406250 324.771027,981.346863 325.567261,981.467651 
	C326.083405,981.702087 326.267731,981.838318 326.666779,982.239258 
	C327.580658,984.408752 329.741272,985.297119 330.119507,987.646667 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M244.135529,955.723022 
	C240.781754,956.303162 238.828644,955.376648 238.953781,951.668457 
	C239.070175,948.218994 237.315735,947.751648 234.478363,949.202637 
	C231.846481,950.548584 229.891754,950.312988 229.983444,946.672363 
	C230.031479,944.765442 229.040085,943.998047 227.234344,943.962341 
	C225.105652,943.920349 225.010086,942.383118 225.007187,940.400574 
	C227.752396,938.572998 230.761017,939.199280 233.648941,938.911194 
	C234.454559,938.830872 235.599518,939.060547 235.860870,939.754211 
	C238.361298,946.391113 245.239014,945.104004 250.321121,947.819580 
	C252.421555,948.967041 254.013840,950.204224 255.997833,951.517395 
	C256.503784,952.221313 256.642456,952.690369 256.594055,953.548523 
	C255.160507,956.212708 253.391769,955.553101 251.484314,954.692322 
	C249.921753,953.987244 248.462830,952.618835 246.248459,953.762451 
	C245.428177,954.493469 244.864517,954.991821 244.135529,955.723022 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M246.352173,954.038757 
	C246.829742,950.787109 248.853256,952.517456 250.685196,952.715271 
	C252.421539,952.902832 253.315720,955.644653 255.778198,954.028137 
	C257.573547,952.981445 258.422760,954.000916 259.509064,955.262939 
	C260.337067,956.640442 260.743317,957.792664 261.166840,959.315308 
	C261.508881,960.938843 261.640411,962.349121 263.595856,961.593018 
	C264.959442,961.065857 266.349213,960.772400 268.105713,961.502197 
	C269.210175,962.396790 269.663300,963.241272 269.246124,964.661499 
	C268.218781,965.094727 267.325104,965.035461 266.596985,965.354309 
	C264.288788,966.364929 262.583191,970.577454 260.011200,968.574280 
	C256.759766,966.041809 252.647583,963.824463 251.297867,959.387939 
	C250.531006,956.867249 249.617142,954.666504 246.352173,954.038757 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M353.169708,996.730713 
	C350.117340,996.401306 347.368652,996.340088 344.583893,997.823792 
	C343.312469,998.501099 341.675049,998.012085 340.432129,996.902832 
	C339.701324,996.250549 338.905731,995.557617 338.011993,995.222168 
	C335.274048,994.194702 333.501892,991.934448 333.471039,989.326721 
	C333.466705,988.963013 332.848053,988.606506 332.221191,988.085632 
	C331.342529,987.182190 331.201111,986.353271 331.669647,985.076294 
	C334.602386,983.256287 336.782471,983.445618 338.045227,987.055054 
	C338.203186,988.073059 338.266968,989.284485 338.432648,989.256226 
	C341.889313,988.668091 343.631409,991.522766 346.341492,992.731934 
	C347.190338,992.106201 347.813202,991.775696 348.821411,991.406982 
	C349.666504,991.254700 350.153717,991.293030 350.969299,991.575317 
	C352.585815,993.030090 353.369720,994.534912 353.169708,996.730713 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M269.270660,964.992920 
	C268.763428,964.187500 268.490173,963.394043 268.065430,962.318237 
	C266.092651,959.511047 263.058289,963.044128 260.987030,960.434692 
	C260.399200,958.920349 260.439819,957.517639 259.234863,956.234253 
	C258.232452,955.339050 257.459656,954.682983 256.385193,953.957275 
	C256.062683,953.453064 256.041809,953.018555 256.017151,952.256836 
	C255.886627,951.421753 256.025543,950.990906 256.718689,950.459839 
	C257.522827,950.192505 258.002747,950.165161 258.827759,950.327637 
	C262.111908,951.430359 262.449554,956.224854 266.911957,955.543152 
	C267.470734,955.643921 267.687897,955.715088 268.185669,955.982056 
	C268.786316,956.497864 269.078217,956.841614 269.529358,957.483521 
	C270.077026,958.306335 270.422089,958.865967 271.098022,959.590942 
	C272.582855,961.105347 274.246887,960.996460 275.836243,961.264404 
	C280.352570,962.025940 281.876404,964.036926 281.411560,969.475708 
	C280.808868,970.019104 280.640869,970.019592 280.220856,970.032471 
	C279.380066,969.227722 278.791321,968.410461 278.059570,967.325073 
	C275.970001,964.042847 272.746826,967.025940 270.230011,965.261047 
	C269.971405,965.046265 269.504639,965.004883 269.270660,964.992920 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M353.370392,997.047485 
	C352.350098,995.612244 351.695770,994.160217 351.009644,992.368652 
	C351.140900,991.858215 351.303986,991.687439 351.791565,991.375244 
	C354.652191,991.669922 357.045532,990.554565 359.795593,990.786438 
	C360.074371,993.797913 357.078491,994.286804 355.998169,996.605713 
	C355.196655,996.964600 354.466522,996.997681 353.370392,997.047485 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M332.042786,984.690857 
	C332.046265,985.796448 332.004913,986.507019 331.946106,987.571045 
	C331.499420,987.934448 331.070251,987.944336 330.318298,987.954468 
	C329.306854,985.950012 326.434937,985.978699 325.986633,983.371338 
	C328.606232,980.327393 330.555420,981.090027 332.042786,984.690857 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M270.070923,965.420166 
	C272.508881,966.122437 275.938019,961.634155 277.907104,966.686646 
	C275.370483,966.415283 272.429260,968.197266 270.070923,965.420166 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M22.598146,601.049927 
	C25.238741,602.816956 27.191496,604.738770 30.408714,601.966736 
	C32.666058,600.021667 34.680199,601.114197 34.979256,604.329773 
	C35.094437,605.568298 34.637447,607.005066 36.446609,607.928467 
	C37.335388,608.049377 37.806454,608.232178 38.443382,608.896118 
	C39.244873,610.407227 39.290348,611.680237 39.147884,613.342651 
	C39.025307,613.949280 38.955650,614.185486 38.725090,614.749817 
	C34.027683,619.831604 34.023346,619.835388 38.372276,624.858032 
	C39.027435,625.614746 39.634529,626.360901 39.177505,627.734924 
	C34.763348,627.503540 31.518732,626.197815 31.390970,621.008301 
	C31.327543,618.431946 29.974092,617.588074 27.616184,619.764465 
	C25.142483,622.047791 23.100657,619.876282 21.938696,618.100342 
	C21.022028,616.699341 18.288301,614.453796 22.422237,612.761047 
	C25.159912,611.640076 22.141474,610.377014 21.647125,609.543457 
	C19.828699,606.477295 18.478472,603.692017 22.598146,601.049927 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M49.501236,675.111572 
	C49.075184,674.083862 49.123405,673.078918 48.986740,672.099915 
	C48.771038,670.554504 49.867184,668.425415 48.031315,667.610229 
	C46.506447,666.933044 45.516293,668.608948 44.505707,669.719727 
	C43.084301,671.281921 42.337471,670.584229 41.970055,668.781189 
	C41.459934,666.277893 39.643723,665.030701 37.459576,663.933960 
	C33.656555,662.024292 32.389717,657.007385 34.767403,653.276306 
	C37.031212,649.724060 33.671017,647.442505 32.884796,644.688538 
	C31.343010,639.287903 31.395844,638.481262 36.994461,637.057434 
	C38.859009,636.583191 40.393108,635.856934 41.859966,634.159119 
	C43.765266,632.595398 45.235893,633.283875 46.970249,634.557800 
	C47.539318,635.314331 47.719986,635.811157 47.773571,636.731323 
	C47.013897,639.917542 44.517380,640.039734 42.340145,640.660950 
	C40.185333,641.275879 38.508976,642.462280 39.672569,644.895142 
	C41.533836,648.786743 41.320328,652.871643 41.446125,656.971497 
	C41.614609,662.462585 42.696629,663.144714 48.523903,661.357056 
	C49.397758,661.184631 49.906895,661.193176 50.787598,661.402161 
	C55.608463,665.467773 55.207630,669.774841 49.501236,675.111572 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M49.090973,675.441895 
	C51.873943,672.753967 51.080589,668.728821 53.513287,666.060242 
	C54.506039,664.971313 51.549873,664.232178 50.983795,662.492920 
	C55.325100,658.844055 56.915771,658.988525 59.756950,663.374878 
	C59.668800,664.823914 59.127922,665.690430 58.489029,666.903442 
	C58.247597,667.701172 58.083885,668.145447 57.724079,668.887085 
	C57.162945,669.769226 56.656017,670.233765 55.848438,670.865723 
	C53.696152,672.271362 53.472961,674.226685 53.225494,676.537842 
	C52.731804,678.781250 52.638042,680.784363 51.245739,682.742737 
	C50.500629,682.744080 50.015301,682.478455 49.279037,682.095947 
	C49.020935,680.918823 49.013767,679.858521 49.004917,678.400391 
	C49.026180,677.270508 49.049126,676.538635 49.090973,675.441895 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M47.037643,634.826172 
	C45.445900,634.676819 44.138382,634.323669 42.496056,633.968750 
	C41.131153,632.207458 40.101055,630.447876 39.019325,628.350830 
	C39.047974,626.335754 37.537457,625.929626 36.602047,625.073669 
	C31.928785,620.797485 31.999619,619.600830 37.570461,614.998718 
	C40.152832,616.859192 37.289856,620.352173 40.339020,622.342896 
	C42.975784,626.071411 44.017052,630.433716 47.480434,633.604675 
	C47.453781,634.175171 47.387825,634.398804 47.037643,634.826172 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M187.343063,918.945435 
	C187.338425,916.900513 189.111313,914.495667 185.360657,913.143616 
	C184.228073,912.132812 183.902924,911.114380 184.251678,909.545227 
	C189.515289,904.815796 190.988190,904.957886 194.641800,910.491577 
	C195.579437,911.911743 196.844574,912.387695 198.678040,912.903748 
	C200.696304,913.717529 201.699158,915.039368 202.736710,916.894409 
	C203.402237,918.995972 203.524933,920.681641 202.295517,922.631531 
	C200.683228,922.969299 199.664673,922.857666 198.980331,921.214355 
	C196.281555,914.733948 194.507889,914.351685 188.628510,918.569824 
	C188.372772,918.753357 188.018295,918.799194 187.343063,918.945435 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M221.261261,927.740723 
	C218.124176,927.803589 217.384323,929.670166 217.023315,931.957520 
	C216.746262,933.712891 216.407852,935.458557 216.048157,937.601807 
	C215.558914,938.002869 215.118256,938.010620 214.347168,938.025879 
	C211.939255,939.496826 210.313339,939.224182 209.288040,936.774841 
	C208.438187,934.744629 206.984970,933.152649 205.281052,931.265869 
	C205.005615,930.992493 204.981750,930.507629 204.965027,930.265503 
	C204.404388,929.377380 204.473709,928.717041 205.193481,927.841553 
	C207.583633,927.516113 209.653656,928.036804 211.597672,928.555176 
	C213.869141,929.160828 214.897568,928.274048 216.023972,926.282593 
	C217.184708,924.430420 218.154984,922.898132 220.739792,923.530640 
	C221.477966,924.093262 221.739517,924.553528 221.936584,925.484253 
	C221.958847,926.442993 221.814560,926.964355 221.261261,927.740723 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M153.286575,904.099487 
	C154.691498,900.404846 157.881561,902.105164 160.350769,902.197937 
	C162.708237,902.286560 164.872299,903.204834 166.137756,900.253784 
	C166.874191,898.536499 168.065292,899.568542 168.987793,900.139526 
	C170.023468,900.780579 170.248383,902.081848 169.281097,902.573914 
	C167.083344,903.691833 167.345551,906.370483 165.298248,907.838745 
	C163.350296,908.151428 161.711243,908.300354 159.809723,908.473206 
	C160.552612,905.310059 161.118927,901.993530 155.925339,904.772827 
	C155.394409,905.056885 154.384171,904.445374 153.286575,904.099487 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M205.041199,931.349731 
	C207.574631,931.401672 209.292999,933.032288 209.828903,935.382324 
	C210.307632,937.481445 211.314163,938.145874 213.625473,938.018616 
	C212.503799,941.157654 210.811646,942.442261 208.980286,938.314087 
	C207.929306,935.945068 205.472321,934.438416 205.041199,931.349731 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M202.356888,922.982239 
	C202.016510,921.280640 202.000336,919.588074 201.973389,917.471924 
	C206.282257,916.263123 205.561127,919.448120 205.615326,922.291809 
	C205.237503,922.880188 205.023270,923.070374 204.393738,923.150146 
	C203.545990,923.023621 203.113525,923.007446 202.356888,922.982239 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M203.982178,923.427490 
	C204.157837,923.022644 204.337250,923.005615 204.786072,922.966614 
	C206.996094,923.909912 207.238724,925.480896 206.339050,927.681763 
	C205.708344,928.477295 205.367691,928.947021 204.987671,929.719971 
	C200.871628,928.536194 205.060547,925.767700 203.982178,923.427490 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M30.140347,453.270935 
	C29.012131,449.183838 27.438328,446.245453 22.369190,447.967590 
	C20.947830,447.823761 19.941950,447.295044 20.120152,446.141052 
	C20.886831,441.176239 17.843510,436.095886 20.726997,431.155731 
	C21.441471,429.931641 21.290396,428.173706 20.254665,426.911560 
	C19.096136,425.499725 19.072582,424.283173 20.438873,423.041595 
	C20.802660,422.710968 21.439596,422.133209 21.351473,421.933472 
	C19.342773,417.380707 22.418190,413.994781 23.479553,410.045166 
	C24.056122,407.899567 24.303808,405.807800 26.136906,405.043152 
	C30.184361,403.354828 31.557949,400.774902 30.388205,396.615326 
	C30.238363,396.082489 30.735661,395.367676 30.977409,394.379456 
	C33.775574,393.860626 35.075569,390.240295 38.595650,390.992065 
	C40.055222,392.221039 39.651661,393.569214 38.828186,394.556854 
	C34.917183,399.247528 35.810230,404.067993 38.226398,409.358032 
	C38.263386,410.216736 38.244991,410.707245 38.172760,411.562012 
	C37.710873,414.341736 38.656254,416.753784 38.078636,419.521423 
	C37.598534,421.264313 36.536903,422.298431 35.812916,423.915161 
	C34.253662,426.171021 33.573345,428.553253 31.552685,430.327545 
	C33.153698,430.407684 34.442509,429.870209 35.949623,430.739502 
	C38.139080,434.655945 37.357143,437.118622 33.180923,438.546906 
	C29.992325,439.637421 29.753845,441.660980 32.064766,444.186951 
	C34.500580,446.849457 37.579273,448.972321 39.285988,452.725647 
	C39.609856,453.830109 39.487301,454.535461 38.695389,455.395752 
	C37.692928,455.954041 36.950844,456.054077 35.815643,455.980682 
	C34.491455,455.732574 33.576099,455.379700 32.271282,455.072571 
	C31.230833,454.680237 30.657618,454.247345 30.140347,453.270935 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M21.555155,450.979614 
	C21.988331,452.518677 21.291235,453.547241 19.384279,453.999115 
	C19.019207,452.495087 19.705612,451.464691 21.555155,450.979614 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M50.002453,318.334778 
	C49.999802,316.933655 49.999763,315.867310 50.002003,314.401093 
	C52.008629,310.268280 50.120941,305.479309 53.574219,301.871521 
	C54.698059,301.546021 55.378979,301.810944 56.255806,302.558990 
	C56.626175,303.052765 56.742447,303.270355 56.967930,303.836975 
	C57.488403,306.440948 56.838840,308.522858 55.770412,310.420898 
	C53.336742,314.744293 55.085999,319.659393 53.687511,324.112122 
	C53.586308,324.434387 53.750679,325.045166 53.819820,325.028717 
	C58.524940,323.911713 58.962032,331.367340 64.103783,330.102539 
	C64.633965,330.365173 64.830650,330.502380 65.225494,330.942932 
	C65.698814,332.197937 65.684914,333.179382 65.692047,334.535370 
	C65.469383,335.717102 64.996307,336.310516 63.939682,336.910828 
	C60.598053,337.207581 57.680756,338.402893 54.335567,337.281799 
	C54.488880,334.823883 55.559143,332.625519 51.959751,331.734467 
	C49.703587,331.175964 48.685810,329.389832 49.508045,326.714813 
	C50.069813,324.887207 49.880127,322.828613 50.029423,320.440918 
	C50.020184,319.562317 50.011059,319.115967 50.002453,318.334778 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M53.895309,337.230988 
	C56.979713,336.649963 59.944347,336.278473 63.325726,335.901489 
	C63.968418,335.980011 64.194374,336.063965 64.391357,336.507477 
	C63.766735,337.945190 62.627983,338.447113 61.550003,339.526794 
	C58.439804,342.400909 56.327198,345.454346 55.097183,349.216797 
	C54.416855,351.297852 54.069912,353.938812 50.551880,354.221985 
	C52.735107,349.219299 50.182880,343.453217 52.964645,338.367645 
	C53.314655,337.889587 53.572304,337.702515 53.895309,337.230988 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M54.145905,301.623840 
	C51.577698,305.456940 53.313202,310.184235 50.267838,313.776825 
	C49.710922,313.454590 49.031265,312.737274 49.185429,312.388397 
	C50.399445,309.641266 50.979031,307.058105 49.137325,304.247589 
	C48.540119,303.336243 49.371235,302.063232 50.707626,301.165222 
	C51.621616,300.445862 52.319061,300.206024 53.482758,300.481537 
	C53.983170,300.845642 54.131573,301.054596 54.145905,301.623840 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M54.215706,300.766296 
	C53.228050,301.010223 52.492382,301.003693 51.389507,300.996124 
	C51.191395,300.446960 51.360493,299.898773 51.765411,299.176514 
	C52.487431,298.736755 52.973633,298.471039 53.962990,298.333191 
	C54.777748,299.145782 54.775719,299.830688 54.215706,300.766296 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M49.780811,318.151520 
	C50.679249,318.424255 50.994392,318.946503 50.244606,319.853394 
	C49.344837,319.586517 48.999283,319.070068 49.780811,318.151520 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M185.266785,908.782410 
	C185.035507,910.088196 185.032700,911.147095 185.040695,912.602966 
	C184.610306,913.014832 184.169113,913.029724 183.397461,913.055664 
	C178.943970,909.076172 173.695206,909.585022 168.334717,909.243042 
	C167.772629,907.341553 168.526871,906.250977 169.950729,905.377197 
	C172.265244,903.956543 172.206329,901.963623 171.098923,899.128784 
	C173.754257,895.398865 176.528793,894.136902 179.819077,897.551819 
	C180.455032,898.211853 181.240829,898.284119 182.283447,897.489624 
	C183.106003,896.870972 183.699905,896.526550 184.654694,896.121155 
	C189.338135,895.899902 192.044785,892.358887 195.819687,891.153931 
	C195.477905,889.288147 193.681808,888.780823 193.247406,886.960205 
	C193.182404,886.387695 193.188843,886.155884 193.284546,885.587769 
	C193.767715,884.391113 194.452240,883.770935 195.390213,882.927124 
	C195.865692,882.622375 196.072205,882.528503 196.612366,882.387207 
	C197.165817,882.372620 197.385635,882.405457 197.896790,882.587402 
	C198.329132,882.896851 198.470123,883.057007 198.765411,883.490112 
	C199.273148,884.446350 199.403458,885.285400 200.509247,885.751648 
	C201.569458,886.175171 202.308197,886.559021 203.196030,887.318237 
	C204.135101,888.373413 204.651505,889.342163 205.570740,890.430542 
	C206.345016,891.410706 206.941833,892.104492 207.650757,893.159546 
	C208.181702,894.394409 208.299408,895.357910 208.364929,896.702026 
	C208.399994,898.061890 208.275848,899.034180 207.897476,900.332947 
	C207.098557,901.925781 206.171799,903.002563 205.112335,904.407349 
	C203.585739,905.541809 203.380707,907.627319 201.084961,907.776672 
	C195.632370,907.484436 190.358856,903.867676 185.266785,908.782410 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M170.930908,899.069946 
	C167.769424,898.501770 166.663651,895.803955 164.927032,892.921448 
	C163.722824,895.961304 162.325394,896.188171 159.656311,894.670288 
	C157.266022,893.311035 154.320374,892.791321 151.111191,893.633972 
	C147.545975,894.570068 143.282104,895.172302 142.073730,889.347778 
	C145.814316,887.454285 148.620499,883.764771 153.451401,884.514343 
	C155.869827,884.889587 155.886490,881.864746 157.322449,880.191650 
	C157.734146,879.757324 157.925400,879.608643 158.454391,879.321289 
	C159.270050,879.081848 159.752869,879.034241 160.602692,879.086792 
	C162.122284,879.427185 163.285522,879.696045 164.862366,879.627014 
	C165.724930,879.618652 166.221710,879.621277 167.102127,879.634277 
	C168.819107,879.683289 170.086365,879.928650 171.372955,881.150635 
	C171.948257,882.184998 172.064606,882.947021 172.013367,884.109619 
	C171.823776,885.441833 171.417404,886.348450 171.392181,887.688965 
	C171.371292,888.513916 171.357315,888.984131 171.329224,889.804810 
	C170.638138,892.890686 170.970856,895.626709 171.160675,898.675293 
	C171.008163,898.987793 170.957245,899.043091 170.930908,899.069946 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M157.835602,879.859375 
	C158.169159,883.279175 155.404282,884.933105 154.434174,888.624573 
	C152.485413,884.341614 150.560028,884.704468 148.248871,887.093933 
	C146.854843,888.535156 144.914871,889.180237 142.428589,888.976318 
	C141.626892,888.946472 141.211502,888.922058 140.487854,888.877075 
	C140.179581,888.856445 140.140518,888.880493 140.118652,888.878479 
	C139.173416,888.114014 138.120667,887.502747 137.152527,886.237183 
	C135.471542,882.925903 137.066010,880.562500 138.873718,877.952881 
	C144.295288,881.644775 149.714920,881.203735 155.316910,878.940552 
	C156.031815,878.651672 156.909348,879.103577 157.835602,879.859375 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M168.051651,909.357056 
	C172.673691,905.683960 177.154984,909.927734 181.770004,909.343079 
	C183.048477,909.181091 182.884827,911.104614 183.037857,912.647217 
	C182.027283,916.708313 180.197495,919.378906 175.446808,918.928833 
	C174.109390,918.156189 173.294495,917.267456 172.235184,916.199463 
	C171.753784,915.499268 171.479172,915.017761 171.087326,914.284668 
	C170.173706,913.122314 169.448013,912.143921 168.261322,911.187561 
	C168.054810,910.531982 168.048187,910.104614 168.051651,909.357056 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M168.074722,911.301758 
	C169.647018,910.970154 171.037460,911.224976 170.974335,913.662598 
	C169.457855,913.902222 168.389542,913.235779 168.074722,911.301758 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M172.053772,916.324219 
	C173.513733,916.082520 174.547348,916.761353 174.995514,918.585693 
	C173.456238,918.964233 172.423355,918.271973 172.053772,916.324219 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M201.876328,85.264526 
	C202.494247,84.741341 202.987076,84.480499 203.734634,84.105270 
	C203.989365,83.990883 204.042801,84.043030 204.069336,84.069290 
	C204.933762,85.039246 205.193558,86.179611 204.671616,87.275047 
	C202.437195,91.964615 204.960587,95.197174 208.173706,98.581284 
	C209.281097,100.113014 209.355255,101.616531 209.732513,103.426575 
	C209.871490,104.308792 209.859344,104.817955 209.667572,105.696335 
	C209.322937,106.526207 209.025146,106.939659 208.376556,107.551788 
	C202.200485,114.109818 195.726471,109.710884 190.093170,107.898575 
	C185.868713,106.539513 182.182541,106.014038 177.707809,107.031998 
	C175.424988,107.312668 173.622955,108.131836 171.342804,108.136269 
	C170.292862,103.898941 167.831512,100.092842 168.942749,95.335648 
	C170.430222,90.490921 172.914124,87.245544 178.097000,86.750786 
	C179.852554,86.583191 181.536148,85.662003 183.632782,85.028168 
	C184.559097,85.156639 185.105270,85.342133 185.828369,85.762177 
	C186.998505,86.893997 188.349564,87.019341 189.729919,87.819237 
	C189.974854,88.028610 189.992157,88.010284 189.980896,88.023422 
	C191.609131,89.815742 190.404388,91.384827 189.593872,92.973221 
	C188.854065,94.423073 187.811981,95.812492 189.147491,98.194908 
	C189.432144,93.861534 191.594620,92.604141 195.262421,93.023201 
	C199.343750,93.489517 197.764343,89.090683 199.802155,87.278709 
	C200.589676,86.511482 201.170456,86.019173 201.876328,85.264526 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M139.751556,117.331345 
	C140.313019,116.530411 140.625443,116.060524 140.951065,115.294632 
	C145.026382,114.054985 149.335693,114.408852 153.024048,115.466278 
	C157.256973,116.679825 162.655350,115.187851 165.764633,120.351738 
	C166.759399,122.003815 168.523621,120.072182 168.953064,118.235863 
	C169.421967,116.230751 168.094467,113.899330 170.722046,112.179008 
	C173.141403,114.262596 172.926453,116.730606 172.808838,119.521324 
	C172.839203,120.319374 172.796555,120.775261 172.591492,121.558868 
	C172.326065,122.078133 172.181290,122.266045 171.747620,122.665070 
	C170.164780,123.538895 168.775574,123.902199 167.001160,124.239471 
	C164.706497,124.419182 162.794617,124.781815 160.492966,124.874138 
	C155.682785,123.720047 150.991730,123.409607 146.846893,121.249023 
	C144.200470,119.869514 142.360107,123.490387 139.573715,121.853745 
	C138.693314,120.224602 138.821747,118.931152 139.751556,117.331345 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M170.783813,108.147278 
	C172.630875,106.984055 174.116135,105.586403 176.661179,106.028900 
	C178.055710,106.950020 178.065781,108.091881 177.568115,109.696793 
	C176.463852,110.988388 175.326614,111.233719 173.676453,111.242249 
	C172.656631,111.099571 172.123520,110.874420 171.292297,110.270569 
	C170.277527,109.555878 170.001480,109.017448 170.783813,108.147278 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M170.943298,110.327194 
	C171.448120,110.025398 171.950851,110.043564 172.828339,110.101532 
	C173.496857,110.578735 173.587830,111.054916 173.284317,111.893532 
	C172.638733,112.472855 172.156708,112.549721 171.340469,112.229378 
	C171.034790,112.011261 170.906403,111.966782 170.841095,111.940018 
	C170.830933,111.491226 170.886093,111.069191 170.943298,110.327194 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M26.030998,544.336548 
	C25.967997,543.555176 25.984682,543.114624 26.019484,542.343994 
	C27.840914,537.271973 27.647781,532.392029 27.187569,527.456116 
	C26.957544,524.989075 26.176233,522.380249 26.954121,520.030884 
	C29.291206,512.972473 27.043747,506.439270 25.416239,499.713074 
	C24.610920,496.384827 25.129503,492.814850 25.820089,489.396393 
	C25.915955,488.921844 26.481726,488.441956 26.371254,488.033203 
	C24.847486,482.395264 28.879490,480.081207 32.708954,477.214111 
	C32.982620,476.674561 33.111607,476.469849 33.490349,476.004150 
	C36.196590,475.166077 37.321014,473.866333 36.677803,471.165558 
	C36.217407,469.232483 35.623936,467.380402 34.836845,465.569092 
	C33.406281,462.276978 33.625134,459.555389 37.258663,457.733521 
	C39.794888,456.461884 41.436108,454.533325 40.277641,451.055176 
	C39.756706,449.532227 39.717419,448.316315 39.918503,446.722656 
	C40.167797,445.419708 40.580589,444.524658 40.546410,443.182129 
	C39.668468,439.537201 40.333664,436.276489 40.755539,432.707275 
	C40.825840,431.903717 41.014462,431.367645 40.195786,430.959167 
	C39.523800,430.874023 39.131111,430.787811 38.438770,430.519104 
	C37.762333,430.056122 37.468990,429.683868 37.103477,428.907043 
	C36.743618,427.510620 36.830994,426.498138 37.428085,425.184326 
	C44.406418,421.737885 46.629940,418.006287 46.346931,410.227448 
	C46.309963,409.211304 46.134399,408.226685 45.426273,407.452454 
	C40.911064,402.515472 42.087460,397.840118 46.035545,393.268341 
	C46.358135,392.894775 46.568336,392.428253 46.982819,391.680603 
	C48.385708,389.466278 50.328003,388.006958 50.839394,385.274628 
	C52.488159,382.411530 52.066002,379.981232 50.431244,377.288239 
	C50.259769,376.517487 50.262363,376.069946 50.458202,375.310974 
	C51.214336,374.062042 52.320892,373.714050 53.362877,372.771606 
	C54.472378,371.958954 55.466537,371.505402 56.225624,370.260803 
	C56.619030,369.847626 56.797451,369.698395 57.302544,369.410339 
	C58.358025,369.115326 59.061466,369.188385 60.011707,369.743988 
	C59.294628,375.299133 58.233635,380.933380 55.914471,385.494049 
	C51.648911,393.882355 51.907837,402.208282 52.567024,410.976074 
	C53.041714,417.289886 52.046753,423.479675 48.830635,429.150024 
	C47.395538,431.680267 46.811653,434.196198 50.211735,435.815002 
	C52.217972,436.770203 54.277637,437.830353 53.767311,440.545807 
	C53.155041,443.803650 50.140656,442.609100 47.893616,443.381561 
	C49.760132,443.744080 51.727779,442.656403 53.188843,444.755066 
	C53.425095,448.283386 49.472637,448.437317 48.555313,451.342896 
	C45.804134,455.361145 47.891247,459.144958 48.307671,463.260071 
	C48.454327,464.531738 48.642723,465.444916 48.775063,466.718292 
	C49.833607,474.979492 50.628193,483.341217 47.083004,490.369537 
	C44.538227,495.414581 42.647804,501.378510 37.484890,505.130646 
	C33.736324,507.854889 34.005836,509.377686 37.216270,513.140503 
	C39.363800,515.657410 40.267952,518.145142 39.063290,521.756897 
	C37.975399,525.018494 39.051342,528.800232 38.275341,532.362061 
	C37.985886,533.690735 39.972397,534.161987 41.131905,534.747803 
	C46.947754,537.686035 49.815891,543.464905 48.098385,549.821411 
	C46.430378,555.994751 49.061184,561.998962 48.357704,568.115845 
	C48.204250,569.450256 49.463825,570.460327 51.324810,570.781372 
	C52.937778,571.410583 53.735218,572.279907 53.737385,574.073608 
	C51.630787,576.742126 50.625320,579.459961 50.323265,582.819580 
	C46.322483,585.072754 46.298916,587.408630 49.058384,590.486694 
	C52.099140,593.878601 53.874622,597.679382 50.511044,601.979858 
	C49.194073,603.663696 50.617775,604.689209 51.380646,605.863159 
	C52.709652,607.908203 53.664368,609.943054 51.291397,612.284790 
	C48.145519,612.966125 47.525860,610.541931 46.306816,608.917358 
	C43.838985,605.628601 43.389526,601.286804 40.457447,597.820801 
	C38.880955,595.617310 38.340561,593.461670 38.314911,590.811279 
	C39.774788,588.068115 42.875843,586.512085 41.958099,582.683167 
	C41.637604,580.738770 41.629932,579.123779 41.831406,577.160278 
	C42.273571,575.023193 41.843361,573.708313 39.520515,573.171509 
	C37.662846,572.440979 36.375019,571.125305 36.602657,569.711731 
	C37.273155,565.548279 34.381336,561.553406 36.658699,557.213318 
	C38.200035,554.276062 35.947590,550.823425 36.333851,547.467041 
	C36.520279,545.847107 34.412876,546.182251 33.122395,546.556274 
	C30.394880,547.347046 27.853922,547.523499 26.030998,544.336548 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M25.674877,544.248596 
	C28.329073,545.524170 30.593695,547.315918 33.448280,544.649902 
	C34.833843,543.355835 36.250145,544.643188 37.500980,545.345032 
	C38.997902,546.184998 38.903641,547.458740 38.101261,548.734619 
	C37.107681,550.314575 37.225842,551.806213 38.141201,553.381775 
	C38.544537,554.075989 39.139004,555.359253 38.874516,555.635376 
	C35.490929,559.167358 38.693645,563.396423 37.546524,567.157654 
	C36.997391,568.958130 37.624039,570.702515 39.678139,571.904541 
	C40.114784,572.218750 40.214268,572.461060 40.101784,573.033813 
	C38.986160,574.002136 37.982620,574.085449 36.577507,573.786804 
	C34.697430,572.371704 33.263626,571.157898 31.030947,572.586487 
	C29.786873,573.382507 28.167910,573.324646 26.314623,573.045288 
	C25.756693,572.857788 25.543871,572.753174 25.079927,572.396667 
	C24.150312,567.500244 22.611002,563.133179 27.324406,558.957397 
	C29.451199,557.073181 27.283691,552.659485 25.986534,549.681274 
	C25.210323,547.899109 24.632311,546.345337 25.674877,544.248596 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M33.665726,476.715698 
	C34.283627,479.578003 32.644009,480.591217 30.491123,480.898346 
	C28.940760,481.119476 27.646645,481.850189 27.860687,483.281555 
	C28.786722,489.473999 25.227587,495.268463 27.397875,501.889618 
	C29.130238,507.174652 30.886620,513.177185 28.545284,519.262817 
	C27.392437,522.259399 28.753868,525.723572 28.849033,528.831482 
	C28.977190,533.016846 30.243502,538.035767 26.386555,541.944214 
	C24.401264,538.774658 25.098583,534.748962 25.635912,532.045471 
	C26.950853,525.429688 22.666061,518.823364 26.651886,512.229004 
	C28.281582,509.532776 25.595821,505.419220 24.513725,502.570587 
	C22.346222,496.864594 24.109442,491.937958 24.765285,486.280212 
	C24.251484,484.120270 24.338514,482.322937 24.318111,480.192169 
	C24.591011,479.146484 24.936127,478.515015 25.599384,477.637787 
	C28.162609,475.423279 30.578135,474.309570 33.665726,476.715698 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M25.121151,479.645721 
	C25.191782,481.769531 26.409885,483.562286 24.883774,485.580383 
	C20.901020,488.713684 17.710855,491.539886 19.851784,497.228424 
	C21.578854,501.817261 21.677479,506.933899 23.316269,511.770264 
	C20.001179,512.269775 17.330769,512.061401 17.983364,507.381256 
	C18.097534,504.362701 16.700966,502.155548 16.069839,499.371155 
	C17.102215,497.368225 18.886761,495.889587 17.061195,493.332245 
	C16.929590,491.641174 16.964865,490.248291 17.021828,488.439148 
	C17.346121,487.916656 17.601822,487.741852 17.933098,487.299347 
	C18.925913,486.923431 20.203121,487.334473 20.431875,486.109497 
	C20.650328,484.939758 19.306873,484.783203 18.340359,484.049500 
	C17.810959,482.819580 17.564960,481.747009 17.184359,480.369171 
	C17.361555,479.567566 17.673353,479.071228 18.147530,478.325928 
	C18.474569,477.886841 18.639231,477.696716 19.130501,477.315094 
	C20.699627,476.693970 21.942957,476.751648 23.531494,477.272583 
	C24.527027,477.880951 24.883099,478.519073 25.121151,479.645721 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M24.457592,572.126465 
	C24.828804,572.144714 25.260536,572.291199 25.477936,572.359741 
	C26.912706,572.840332 27.863014,573.643921 28.904087,574.802490 
	C29.481756,577.172180 27.429035,578.136353 26.895985,579.809692 
	C26.525404,580.972961 26.240522,582.773560 24.603703,582.462402 
	C22.884340,582.135437 21.786255,580.534851 22.094528,578.773132 
	C22.564816,576.085510 20.491541,575.207947 19.032223,573.435120 
	C20.662355,572.758118 22.374369,572.433167 24.457592,572.126465 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M22.827539,520.716003 
	C18.502930,518.194153 18.374802,517.769897 21.631268,516.000610 
	C22.273123,517.441223 22.559465,518.892273 22.827539,520.716003 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M47.492146,370.968384 
	C40.512718,370.584076 39.118004,368.763824 40.656994,362.568176 
	C40.815071,361.931793 40.932587,361.279877 41.003201,360.628052 
	C41.739586,353.830200 42.548016,353.167206 49.547493,353.948364 
	C49.925037,353.925873 50.132458,354.120331 50.236748,354.216949 
	C50.347820,354.545959 50.354603,354.778320 50.211945,355.324402 
	C46.451385,357.330505 44.956356,360.199677 44.769226,364.150574 
	C44.683456,365.961334 45.453293,366.448547 47.075722,367.059082 
	C47.793709,367.503632 48.126869,367.852051 48.529713,368.608368 
	C48.726360,369.760559 48.521751,370.451355 47.492146,370.968384 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M42.356506,382.964966 
	C40.165424,382.694214 38.595215,381.982666 37.276848,380.239258 
	C38.741680,378.511475 40.246861,376.981781 42.642941,377.015686 
	C44.939709,377.048218 46.254021,375.688660 47.092861,373.289581 
	C48.365208,372.206543 49.106983,372.796753 49.829025,374.147614 
	C49.969257,375.020020 49.978130,375.525787 49.951450,376.403473 
	C49.897358,377.018585 49.860188,377.262115 49.692421,377.848572 
	C48.663002,379.333099 48.259148,380.743927 47.251518,382.227600 
	C45.649403,383.302673 44.219097,383.434692 42.356506,382.964966 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M49.328506,375.154602 
	C48.921631,374.192566 48.736294,373.386810 47.480232,372.999786 
	C47.148926,372.490997 47.116291,372.057068 47.097630,371.295471 
	C47.389454,370.511566 47.667309,370.055359 47.962715,369.317078 
	C53.192101,366.122253 55.022167,366.188232 57.500645,369.845337 
	C57.400414,370.308807 57.264832,370.704346 57.173531,370.892395 
	C56.195866,372.117706 54.668755,371.744690 53.524834,372.871552 
	C52.786469,373.863159 51.904518,373.921997 50.874241,374.378113 
	C50.349556,374.882874 50.012314,375.113861 49.328506,375.154602 
z"
    />
    <path
      fill="#1B1B1A"
      opacity="1.000000"
      stroke="none"
      d="
M35.236115,366.780121 
	C30.022882,365.333740 33.855721,361.137421 32.769691,358.209839 
	C38.149460,359.607422 34.065079,363.876343 35.236115,366.780121 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M251.302094,948.939575 
	C246.414276,949.100159 242.752258,945.665222 238.177139,945.786316 
	C237.759689,945.797424 236.982422,945.372742 236.956528,945.095825 
	C236.323914,938.326965 231.498398,940.619812 227.659683,940.697876 
	C227.038681,940.710571 226.409134,940.302551 225.395660,940.040894 
	C224.676971,939.537720 224.346176,939.082703 223.976059,938.338013 
	C223.450668,937.425354 223.188156,936.735046 223.441864,935.586487 
	C224.248230,934.251038 225.206467,934.346252 226.515656,934.629150 
	C228.592163,935.569336 230.118988,934.766663 231.974121,933.952759 
	C238.901657,930.913879 238.737930,936.523132 239.991623,940.818481 
	C241.740036,938.720886 242.700974,936.108337 245.879211,937.686035 
	C247.327988,938.981873 246.390305,940.832764 247.560593,941.904236 
	C249.592941,942.185486 251.621994,938.875000 253.329102,941.462769 
	C255.084335,944.123474 254.072403,946.805664 251.302094,948.939575 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M227.246918,935.743896 
	C226.213211,935.687500 225.440353,935.361877 224.353210,935.004150 
	C223.870834,935.114380 223.702759,935.256653 223.273010,935.197266 
	C223.018829,934.249695 223.026321,933.503662 223.040253,932.384644 
	C223.267731,931.096313 222.883240,930.315491 222.366928,929.233521 
	C221.975052,928.110352 222.038055,927.187439 221.248413,926.220764 
	C221.007385,925.550171 220.985260,925.120911 220.949905,924.369629 
	C220.530060,922.767029 221.311447,922.137817 222.653931,921.552734 
	C229.343048,924.614380 229.073593,929.837036 227.246918,935.743896 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M222.729004,928.747925 
	C223.947662,929.799622 226.007675,930.414795 223.341827,931.969238 
	C221.970657,931.550232 221.236053,930.812683 221.082550,929.269043 
	C221.465393,928.616394 221.918701,928.493286 222.729004,928.747925 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M223.021332,928.606201 
	C222.597824,928.956055 222.160767,928.959778 221.397675,928.952637 
	C221.071640,928.941711 221.016800,928.484863 220.992630,928.256348 
	C220.979401,927.572571 220.990341,927.117310 221.015381,926.320740 
	C222.438721,926.089233 223.013718,926.922729 223.021332,928.606201 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M222.756653,935.191406 
	C223.182343,934.986511 223.353333,934.977356 223.781616,934.970093 
	C224.021332,935.741089 224.003738,936.510071 223.961456,937.663696 
	C222.355484,937.755920 221.613144,937.011230 222.756653,935.191406 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M35.787453,572.536682 
	C37.160133,572.879150 38.139275,573.043457 39.482639,573.286255 
	C41.368622,573.362488 41.894054,574.434998 42.332336,575.999878 
	C42.029243,577.522644 42.079861,578.767090 41.393890,580.192017 
	C37.490593,581.637512 37.715748,585.574524 35.483635,587.996338 
	C33.834183,587.186340 33.614525,583.685242 30.914648,586.364990 
	C30.272367,587.002502 29.333067,586.751587 28.775078,585.996765 
	C27.936357,584.862122 28.909361,584.391296 29.583664,583.646667 
	C31.732018,581.274292 33.739853,578.774719 36.031914,576.051025 
	C35.210766,574.281128 32.332657,578.319641 31.054321,575.311279 
	C31.285761,572.125488 33.213291,572.029785 35.787453,572.536682 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M38.770027,589.777100 
	C39.618259,592.107727 40.168674,594.217590 40.879539,596.655762 
	C42.233788,599.371521 43.295425,601.738220 39.558548,603.339905 
	C38.316029,602.532166 38.164806,601.646545 38.603874,600.221558 
	C38.786404,598.739929 38.840359,597.664978 37.245644,597.190430 
	C36.762611,596.962769 36.583946,596.790649 36.235474,596.286377 
	C35.740852,594.996155 35.726017,594.002625 35.746826,592.634399 
	C35.830170,591.766357 35.947746,591.283386 36.295151,590.485168 
	C36.656574,589.989380 36.839497,589.821411 37.380157,589.553406 
	C37.982666,589.487671 38.227432,589.521973 38.770027,589.777100 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M36.782356,595.757080 
	C36.995255,596.199036 37.007103,596.386658 36.980114,596.854614 
	C34.917564,598.846985 32.989113,598.030396 31.959879,596.235840 
	C30.358744,593.444153 32.708126,591.842224 34.870544,590.115173 
	C35.512325,589.645264 35.902557,589.534058 36.613609,589.808960 
	C36.857853,590.412537 36.840958,590.820984 36.789925,591.534424 
	C35.909851,593.023010 35.942066,594.248352 36.782356,595.757080 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M36.085548,572.385315 
	C34.502926,573.016663 32.799427,573.247681 31.217976,574.698730 
	C30.565868,574.987488 30.129835,574.997437 29.369373,575.045776 
	C27.661537,574.925415 26.142405,574.982544 25.775944,572.753540 
	C27.795898,571.715088 30.168549,572.362366 32.145634,570.696838 
	C33.745110,569.349487 35.158585,570.300903 36.085548,572.385315 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M37.155327,590.000732 
	C36.499660,589.994751 36.124573,589.985474 35.470200,589.976440 
	C35.165218,589.533020 35.139526,589.089233 35.122494,588.311218 
	C36.890274,585.397705 34.559376,580.279602 40.431900,579.947754 
	C43.013416,582.646851 38.912140,584.106934 39.373314,586.904968 
	C39.233559,588.060913 38.971310,588.775085 38.243832,589.700928 
	C37.810036,589.999146 37.622971,589.998291 37.155327,590.000732 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M38.436481,599.942261 
	C38.865765,600.599243 38.941605,601.411682 39.041801,602.630371 
	C40.141266,605.126465 40.926777,607.200989 38.376526,609.058289 
	C37.710522,608.822510 37.412567,608.647034 36.956482,608.254150 
	C37.570862,605.446045 35.832127,602.449036 38.436481,599.942261 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M46.619202,382.396057 
	C47.191536,380.782623 46.466484,379.086243 48.469353,378.044250 
	C51.196949,380.188232 50.124065,382.743988 49.379356,385.629517 
	C49.177921,386.234161 49.083130,386.469391 48.806293,387.034668 
	C47.793968,388.583344 46.681957,389.543640 45.411613,390.835144 
	C44.980991,391.226776 44.804295,391.374878 44.354015,391.725830 
	C42.184170,392.915649 40.961681,394.250122 41.733994,396.914948 
	C41.819645,397.693634 41.799641,398.140106 41.634720,398.907654 
	C40.082531,401.453674 38.752472,403.707764 39.815430,406.873627 
	C39.985176,407.935120 39.931438,408.643341 39.532227,409.661133 
	C38.963394,410.313446 38.511608,410.479034 37.656403,410.373871 
	C37.265774,410.084595 37.266258,409.894897 37.176590,409.769806 
	C32.495556,403.239288 34.959076,397.402100 38.947456,391.347168 
	C39.734005,389.339539 41.325851,388.568146 42.808617,387.234863 
	C43.989994,385.430054 44.904510,383.817017 46.619202,382.396057 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M46.487122,382.103180 
	C47.274326,384.734650 45.014729,385.421783 43.293983,386.841553 
	C42.739231,385.836639 42.496883,384.704102 42.123333,383.239746 
	C43.160484,381.612549 44.689495,382.079254 46.487122,382.103180 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M57.056534,278.392578 
	C60.667065,276.240173 63.569569,272.324646 68.753212,274.135315 
	C69.598961,273.874695 70.354713,274.250488 70.237968,274.473572 
	C68.422729,277.942078 69.279228,282.009705 67.807968,285.478027 
	C66.492584,288.578827 64.567062,291.228088 60.468266,288.231750 
	C59.553436,290.778809 62.162098,293.698456 58.624969,295.243530 
	C56.938316,294.825806 57.124577,293.388885 56.257244,292.222839 
	C55.966064,290.119293 54.245762,288.442688 55.740345,286.236145 
	C57.369095,284.408447 57.424133,282.561584 57.033005,280.311096 
	C57.037811,279.566010 57.042667,279.139069 57.056534,278.392578 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M55.964485,292.296997 
	C57.401802,292.096466 58.015209,292.865540 57.926094,294.563965 
	C57.753246,295.503357 57.345116,295.941040 56.738594,296.603210 
	C55.977299,297.409912 55.424873,298.004364 54.327820,298.138214 
	C54.042892,298.029266 54.001167,297.997620 53.980522,297.980469 
	C52.544357,295.437134 54.270443,294.052216 55.964485,292.296997 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M56.749813,280.257080 
	C59.386036,282.092987 59.142647,283.995941 56.300606,285.864197 
	C56.154137,284.176208 55.618355,282.290222 56.749813,280.257080 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M40.951191,450.958008 
	C42.966305,453.707733 41.855671,457.754608 38.375568,458.781494 
	C34.159481,460.025574 34.558411,462.309174 36.101086,465.093292 
	C37.507065,467.630676 37.587940,470.433380 38.335472,473.083649 
	C39.600899,477.569946 37.676304,477.187622 34.363907,476.043396 
	C31.884712,471.011230 27.907549,468.614410 22.418207,467.693756 
	C21.923748,467.348114 21.750576,467.175598 21.418537,466.671509 
	C20.920446,465.106720 21.225088,463.940216 21.746634,462.460999 
	C22.059784,461.744659 22.280691,461.366974 22.714287,460.767578 
	C23.101278,460.203400 23.277117,459.865143 23.443659,459.225159 
	C23.525831,458.765228 23.570890,458.583435 23.847364,458.242310 
	C24.567953,457.499268 25.057125,456.915558 25.797516,456.187866 
	C27.664021,454.350189 29.585066,454.782654 31.438025,455.392273 
	C33.488403,456.066833 35.480164,456.455597 37.676514,455.076782 
	C38.103203,454.320862 38.354885,453.872437 38.561966,453.160522 
	C38.612206,452.727203 38.707050,452.557373 39.001877,452.174347 
	C39.644722,451.573944 40.122738,451.240051 40.951191,450.958008 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M37.494812,454.780579 
	C36.478844,457.663177 34.369453,458.394287 31.578428,456.803406 
	C30.071667,455.944550 28.478636,455.960785 26.449032,456.040588 
	C26.074947,453.895630 27.313766,453.040680 29.674561,452.954956 
	C30.507130,453.218353 30.971460,453.516449 31.679184,453.944519 
	C32.662868,454.558746 33.466881,454.871826 34.674606,454.992310 
	C35.713451,454.935333 36.425777,454.863617 37.494812,454.780579 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M23.677538,458.053955 
	C24.078773,458.082977 24.147680,458.496246 24.160982,458.705322 
	C24.458738,459.365723 24.549364,459.853058 24.221987,460.687408 
	C23.688574,461.115875 23.431635,461.304596 23.105572,461.773163 
	C22.070585,462.924805 21.060358,463.806213 20.789974,465.548920 
	C20.520945,466.031433 20.374174,466.201477 19.890526,466.364075 
	C19.171898,466.021515 18.790148,465.686432 18.203064,465.203003 
	C17.773718,461.222015 18.186291,457.867340 23.677538,458.053955 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M199.661423,87.151161 
	C199.666168,89.277817 199.283325,91.546730 198.992752,93.827408 
	C198.735092,95.849899 197.936539,95.588036 196.315720,94.966194 
	C191.569565,93.145302 191.165649,93.564140 191.468658,98.658188 
	C191.501968,99.218208 191.221466,99.996391 190.808868,100.339592 
	C190.075790,100.949371 189.130051,100.697533 188.669266,99.920815 
	C187.512299,97.970619 185.025497,96.343712 187.582428,93.522606 
	C188.708405,92.280266 190.114349,90.812080 189.967041,88.400452 
	C192.969421,86.334610 196.328064,89.176346 199.661423,87.151161 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M189.824249,87.719131 
	C188.564392,88.047989 187.200195,87.948685 186.163177,86.300705 
	C187.420471,85.968185 188.798401,86.036354 189.824249,87.719131 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M55.799732,296.424622 
	C56.474361,295.693756 56.917175,295.355682 57.641045,294.961945 
	C61.447643,293.427338 58.844070,291.428528 58.348103,289.631927 
	C58.028286,288.473450 57.719574,287.280334 58.792397,286.432556 
	C59.494118,285.877991 60.318340,286.262482 61.097946,286.679413 
	C62.258591,287.300140 63.116428,289.726898 64.684784,288.069977 
	C66.104073,286.570557 68.144241,283.360382 67.627136,282.704285 
	C64.566719,278.821289 68.407753,277.029633 69.295044,274.231659 
	C69.026390,272.962280 68.981194,271.911072 68.894699,270.467590 
	C68.357529,267.617859 70.070740,266.694427 72.151962,265.676819 
	C73.711372,265.512878 74.737885,265.129272 75.485001,263.620911 
	C76.264854,262.844543 76.896461,262.484924 77.983810,262.209900 
	C78.880829,262.169769 79.375595,262.290314 80.144135,262.767090 
	C81.060707,263.926422 81.210518,264.944885 81.101715,266.389008 
	C80.965759,267.001648 80.885818,267.237061 80.616631,267.786072 
	C76.332794,269.387939 76.413750,273.293152 75.203491,276.399811 
	C74.680199,277.743073 75.267952,278.189148 76.503067,278.291687 
	C78.849060,278.486481 79.843620,280.132019 80.619812,282.445709 
	C80.744453,283.033417 80.771034,283.275391 80.768692,283.885681 
	C80.369026,285.817261 79.322807,286.844604 77.707214,287.848480 
	C76.927979,288.220673 76.421310,288.566895 75.979012,288.489166 
	C68.913948,287.247864 66.039070,292.985504 61.992271,296.799225 
	C59.684162,298.974396 61.501247,301.997681 60.161293,304.700745 
	C59.772850,305.422119 59.467846,305.770325 58.766754,306.169739 
	C57.325401,306.174255 57.071346,305.197693 56.406601,304.167419 
	C56.224869,303.882080 56.144176,303.406555 56.101128,303.169281 
	C54.545033,301.002014 55.135380,298.903381 55.799732,296.424622 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M73.222595,265.729706 
	C71.685463,267.140350 70.438896,268.322571 69.022873,269.790039 
	C65.303650,268.680817 68.722664,267.106659 68.875717,265.318848 
	C69.012878,265.017426 69.507736,265.011414 69.755180,265.007568 
	C70.473976,265.000854 70.945328,264.998047 71.770164,264.988495 
	C72.277336,264.878204 72.431023,264.774689 72.808746,264.835693 
	C73.032791,265.000214 72.994469,265.006134 72.997864,264.987061 
	C73.171898,265.145721 73.342537,265.323486 73.222595,265.729706 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M139.662933,117.055771 
	C139.989609,118.377266 139.978607,119.754227 139.964111,121.544304 
	C139.406311,125.156990 137.117737,124.122879 134.777985,123.716171 
	C133.919647,123.116379 133.649490,122.614746 133.716095,121.591164 
	C134.053192,119.951363 134.251236,118.727242 134.714920,117.244095 
	C136.304810,121.296814 137.871613,117.255486 139.662933,117.055771 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M21.767279,466.985046 
	C29.866400,467.227142 33.523632,469.744781 33.992676,475.595459 
	C33.994724,475.993896 33.975399,476.479401 33.964073,476.721710 
	C31.452644,476.561920 28.927099,475.968353 26.393154,477.960754 
	C25.639172,478.378937 25.158966,478.538330 24.254499,478.295502 
	C22.668640,478.059601 21.433783,478.005219 19.828510,477.935242 
	C18.375460,477.569122 18.289967,476.585052 18.023903,475.350159 
	C17.890659,474.557343 17.781549,474.114685 17.571770,473.344727 
	C18.344765,471.598785 19.218430,470.180084 20.176092,468.413818 
	C20.540234,467.561584 20.916502,467.167023 21.767279,466.985046 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M17.762203,475.174927 
	C18.945852,475.458740 19.071423,476.378815 19.363764,477.588928 
	C19.265560,477.943756 19.073029,477.967865 18.595200,478.034485 
	C17.831766,477.230255 16.892286,476.516357 17.762203,475.174927 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M22.021994,466.743011 
	C21.631468,467.314331 21.215176,467.653107 20.529486,468.029053 
	C20.081757,467.693726 19.903425,467.321259 19.639374,466.652710 
	C19.553654,466.356598 19.791071,466.171173 19.906658,466.074585 
	C20.415897,465.645691 20.865520,465.501617 21.668406,465.809326 
	C21.957178,466.044159 21.996227,466.510468 22.021994,466.743011 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M936.709473,183.159286 
	C940.839417,184.144470 942.645264,187.356369 943.989075,190.612137 
	C945.551880,194.398453 943.824280,196.988800 939.845825,196.643478 
	C937.611755,196.449570 936.775146,197.002640 936.240723,198.789429 
	C935.608826,200.901871 936.804565,201.521286 938.614197,202.275894 
	C940.586121,203.098175 942.258972,204.307144 944.619202,203.928909 
	C948.793579,203.259949 950.369019,204.840683 948.524353,208.353470 
	C946.749756,211.732910 947.704346,212.241501 950.845276,213.319290 
	C954.250122,214.487640 956.143860,217.497574 954.103516,221.259964 
	C952.477783,224.257858 954.113831,224.727783 956.438965,224.979263 
	C957.098389,225.050598 957.859497,224.846725 958.413025,225.108932 
	C960.223572,225.966400 963.409485,225.294235 963.474792,228.115494 
	C963.528625,230.442062 961.027771,230.748276 959.023865,231.220566 
	C957.499146,231.579880 955.453369,232.152542 956.421326,234.507004 
	C957.174438,236.338943 958.387329,236.822723 960.330811,235.501907 
	C962.116882,234.288132 963.551697,235.710449 964.415955,237.167252 
	C965.526001,239.038284 964.648010,240.305359 962.324463,240.957428 
	C959.737915,240.829453 958.131531,238.749390 955.441895,238.869629 
	C954.687256,238.561600 954.286926,238.323105 953.638489,237.821747 
	C950.871948,234.176605 946.957703,233.172195 943.303589,231.772476 
	C941.344666,231.022064 939.729126,231.454453 938.958801,234.080322 
	C938.537415,234.793991 938.224365,235.146332 937.547852,235.626160 
	C936.310059,236.182388 935.330505,236.297714 934.346191,236.217865 
	C932.881653,236.099091 931.258301,236.093170 930.662048,234.379120 
	C930.164307,232.948105 931.120789,231.891251 931.968811,230.881744 
	C932.894470,229.779724 934.278076,229.081848 935.097473,227.561768 
	C932.909668,227.615707 931.302856,229.556824 928.930054,228.316986 
	C928.336304,227.707474 928.112976,227.270905 927.865295,226.437195 
	C928.521301,221.258896 929.181274,216.418640 932.732605,212.286072 
	C933.974121,211.439682 935.920898,211.646820 935.482544,209.772491 
	C935.085388,208.074081 934.516113,206.232376 931.685791,206.730072 
	C930.575623,206.597519 929.954712,206.242767 929.287109,205.315445 
	C928.640442,203.759262 928.659546,202.493759 928.842773,200.854507 
	C929.060608,200.030289 929.260620,199.595734 929.757996,198.941574 
	C930.933533,197.876709 932.403259,197.761261 933.127625,196.169037 
	C933.392578,195.704224 933.501404,195.517670 933.784729,195.055786 
	C935.273682,192.525757 933.499268,190.299515 933.903748,188.030731 
	C934.236938,186.161392 935.132935,184.662064 936.709473,183.159286 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M937.008057,182.740387 
	C933.829346,186.840927 937.183411,191.851761 934.363159,195.913849 
	C931.506592,193.727859 928.607056,193.726883 925.597961,194.516357 
	C923.247864,195.132919 920.899658,194.839096 920.142700,192.456894 
	C919.142639,189.309540 916.810791,187.795181 914.492004,185.926544 
	C912.148132,184.037689 911.323547,181.873962 914.516296,179.480820 
	C916.902466,177.692276 918.708801,175.137970 921.235229,173.192276 
	C921.068726,172.447784 920.992798,172.015060 920.919983,171.241241 
	C921.419312,168.646576 921.742188,166.622482 919.070801,165.193970 
	C917.335693,164.266159 917.684143,161.642212 915.904419,160.094711 
	C914.980774,159.214752 914.364807,158.521866 913.789673,157.372192 
	C913.675720,156.581543 913.789734,156.151901 914.265015,155.525543 
	C915.311523,154.584412 916.848755,154.146332 915.281006,152.372070 
	C914.235352,150.984100 913.876160,149.705933 914.008972,147.952820 
	C914.231506,146.706512 914.570557,145.854324 915.034912,144.687592 
	C915.287537,144.184052 915.403137,143.991272 915.732422,143.540955 
	C917.059875,142.535553 917.676697,141.361908 918.744263,140.162659 
	C920.773438,140.187256 921.084229,141.423599 920.982361,142.852417 
	C920.856018,144.626953 921.504944,146.075287 922.651978,147.353302 
	C926.369873,151.495560 928.110046,155.947800 926.197571,161.670975 
	C925.326294,164.278122 926.045898,167.345734 928.559509,169.243530 
	C930.498413,170.707367 931.660767,172.635727 930.891296,174.782761 
	C928.975464,180.128372 931.849426,181.515396 936.613770,181.991196 
	C937.002441,181.998779 937.006409,182.493195 937.008057,182.740387 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M928.860657,227.988495 
	C931.297791,228.298630 932.519897,226.853058 933.939514,225.736694 
	C935.163696,224.774139 936.471069,224.858704 937.360413,226.200836 
	C938.229431,227.512177 937.119446,228.331360 936.287903,229.098984 
	C935.811523,229.538712 935.142090,229.767166 934.656677,230.199677 
	C933.373108,231.343323 930.951477,232.190506 931.752930,234.203339 
	C932.416199,235.869293 934.741638,234.707901 936.695435,235.084625 
	C937.623657,237.354919 938.368347,239.058685 941.325256,238.126740 
	C943.243408,237.522202 945.592712,237.494064 946.735535,239.400818 
	C949.007935,243.192444 952.062500,243.250824 955.781799,241.856781 
	C956.195007,240.595581 954.037476,240.051682 954.771484,238.351837 
	C957.333679,238.281021 960.349060,236.842270 961.699951,240.751709 
	C961.961060,241.440979 961.954773,241.884048 961.954468,242.659332 
	C962.044495,245.598007 960.395447,247.130707 958.442444,248.339401 
	C956.919189,249.282089 955.459167,250.212738 954.334229,252.100677 
	C953.617004,252.568558 953.140076,252.673325 952.275024,252.616821 
	C946.791260,247.960693 940.441589,248.136368 934.201355,248.090439 
	C932.204102,248.075714 930.288452,248.141144 928.237305,246.819946 
	C927.785461,246.401550 927.628845,246.210358 927.316772,245.684509 
	C926.954102,244.648102 926.927307,243.935577 927.116272,242.887161 
	C927.496216,241.746307 928.115540,241.137955 928.648071,240.144302 
	C928.848633,239.279556 928.702576,238.730331 928.338501,237.911270 
	C927.989624,237.019379 927.867126,236.389130 927.744080,235.420685 
	C927.659729,233.758163 927.698608,232.421524 927.450684,230.757660 
	C927.393005,230.187469 927.393066,229.956024 927.477173,229.385284 
	C927.797729,228.635361 928.111755,228.298630 928.860657,227.988495 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M958.761292,255.161072 
	C961.039856,253.297104 961.400085,255.291748 962.182129,256.407349 
	C963.778503,258.684601 961.311462,259.481689 960.233826,261.324554 
	C962.155334,261.817169 963.586121,264.271149 965.384583,260.878448 
	C966.217102,259.307892 967.685913,260.323975 968.703857,261.269989 
	C969.888062,262.370575 969.984558,263.421783 968.694702,264.606812 
	C965.841614,267.228088 964.477356,270.325104 966.006958,274.585022 
	C965.670288,275.365234 965.231323,275.559570 964.342285,275.452301 
	C963.792725,275.195862 963.603271,275.037903 963.200867,274.581238 
	C961.009399,268.663635 957.219421,264.242065 953.407410,259.470612 
	C953.560608,257.801300 954.554077,257.216583 955.876953,256.513367 
	C956.916809,255.944183 957.760498,255.750946 958.761292,255.161072 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M963.828613,275.247681 
	C964.490662,274.961151 964.927002,274.968536 965.691040,274.971375 
	C966.818481,275.567505 967.618286,276.168152 968.692505,276.904663 
	C969.079041,280.442932 968.018860,283.845917 968.957520,287.647980 
	C967.613403,288.675171 966.200378,289.160583 964.834229,290.411407 
	C962.845520,290.801270 961.719666,289.865570 960.426270,288.491943 
	C958.148010,284.281982 957.771667,280.725800 962.228027,278.087402 
	C963.114197,277.562744 963.199646,276.424286 963.828613,275.247681 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M957.270142,256.684143 
	C956.176208,257.542175 955.299744,258.047485 954.241089,258.801819 
	C951.681152,261.437134 955.486694,263.518585 954.173279,266.115326 
	C951.622253,267.536255 950.342468,265.963867 948.768677,264.227661 
	C948.188904,263.607269 947.879211,263.234467 947.356934,262.567017 
	C945.398987,260.205505 945.116699,257.959595 946.565369,255.253952 
	C947.886841,254.059341 949.150757,254.271057 950.361633,254.564835 
	C952.671692,255.125290 951.327026,253.402664 951.756226,252.382370 
	C952.385254,252.059448 952.845703,252.039810 953.651794,252.011490 
	C955.003235,251.200592 955.849243,251.387146 956.753784,252.746033 
	C956.948669,253.075226 956.960876,253.538971 956.979614,253.770172 
	C957.519043,254.702972 957.719910,255.471710 957.270142,256.684143 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M957.017456,252.732620 
	C956.175110,252.743790 955.401611,252.412338 954.312744,252.041840 
	C953.978882,250.052750 953.783081,248.215424 956.637268,247.824203 
	C959.027771,247.496567 960.183411,245.278030 961.696533,243.202530 
	C962.700195,244.051865 963.508301,245.071701 964.165222,246.181061 
	C965.460815,248.368805 964.814575,249.820404 962.244385,249.491730 
	C959.605225,249.154205 958.387207,250.691223 957.017456,252.732620 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M977.000732,287.617798 
	C974.890869,287.802551 975.009033,286.235626 974.999634,284.374329 
	C977.099182,284.199860 976.987549,285.760162 977.000732,287.617798 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M957.368164,256.981812 
	C957.031311,256.277832 957.009949,255.518753 956.993530,254.380524 
	C957.494934,254.250931 957.991577,254.500488 958.745300,254.855972 
	C958.922302,255.858063 958.696960,256.656799 957.368164,256.981812 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M714.880127,30.277689 
	C715.316345,29.382427 715.570251,28.436729 715.984436,28.360380 
	C720.519653,27.524391 724.872131,29.728415 729.435791,29.286716 
	C730.145691,29.218012 731.217896,30.242130 731.711914,31.021257 
	C735.044312,36.276081 738.301697,37.486031 744.345703,35.431828 
	C745.516846,35.033779 746.839844,34.698967 747.686523,35.461555 
	C751.310486,38.725613 756.567261,40.360546 758.110596,45.732613 
	C758.552979,47.272621 759.485657,48.470276 761.083557,48.759846 
	C763.050720,49.116329 762.980774,47.268490 763.427246,46.156788 
	C764.332336,43.903343 765.820496,44.065361 767.337830,45.232384 
	C769.292236,46.735611 771.789917,47.913239 772.707642,50.278923 
	C774.031921,53.692783 775.919067,54.977722 779.790344,55.412712 
	C783.304749,55.807594 785.854553,55.349293 788.483643,53.321659 
	C791.265564,51.176254 793.778687,49.332623 794.363770,55.244568 
	C794.761841,59.266918 798.550903,59.663361 801.668945,59.473026 
	C804.320557,59.311165 806.577759,60.620411 806.635254,62.403950 
	C806.757385,66.187721 808.884033,67.431900 811.689209,68.828674 
	C813.298401,69.629944 814.182312,71.358070 814.048645,73.444275 
	C813.897949,75.796379 815.811462,76.581955 817.355530,75.887733 
	C823.692078,73.038742 826.496094,77.536766 829.855835,81.677681 
	C830.315735,83.401093 829.291992,83.560501 827.954834,83.725052 
	C825.946106,83.209557 825.407288,81.402985 823.681885,80.517334 
	C822.977722,80.208885 822.579651,80.022255 821.887756,79.687683 
	C820.254395,78.735802 819.388428,79.432091 818.859314,80.560204 
	C816.868652,84.804054 814.730591,83.777710 812.258301,80.554375 
	C811.890747,80.111794 811.751709,79.927956 811.425110,79.453339 
	C810.892639,78.562920 810.613770,77.931946 810.233215,76.970932 
	C809.926025,75.992477 809.828491,75.319183 809.692200,74.315994 
	C807.574585,70.028084 803.751831,71.579765 800.231812,71.046249 
	C799.670166,70.914803 799.449219,70.842545 798.915405,70.612762 
	C797.173889,69.099579 795.586548,68.557396 793.616760,69.228676 
	C790.863708,70.166855 789.367676,69.295853 788.659363,66.269981 
	C787.436462,61.045464 786.596680,60.615391 781.590393,62.164398 
	C779.375427,62.849731 777.898621,62.187954 776.177490,60.866264 
	C772.326965,57.909332 769.346802,53.388653 763.335938,53.488693 
	C761.571289,53.590485 760.176086,53.886337 758.404968,53.829308 
	C756.512512,53.341778 756.066223,52.008755 755.432495,50.444363 
	C754.948608,49.428345 754.418823,48.956985 753.233704,49.035976 
	C752.018127,49.124348 751.134705,49.035187 749.967407,48.534767 
	C747.360046,45.669952 744.663269,43.744350 741.160095,42.708195 
	C734.733337,40.807293 729.050293,36.875275 722.131409,36.110435 
	C718.993652,35.763588 716.571655,33.349506 714.880127,30.277689 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M714.621033,29.946791 
	C718.783630,32.496201 721.795288,36.939884 727.424744,35.133366 
	C734.318054,40.254013 744.266418,39.609673 749.964844,47.394470 
	C750.333069,48.083485 750.543945,48.494366 750.559326,49.319969 
	C750.381226,49.862270 750.259766,50.058300 749.847229,50.433064 
	C746.167908,51.795368 746.479736,47.576366 743.977173,46.978756 
	C741.755859,46.629704 739.882141,46.518818 737.921631,47.965607 
	C735.763489,48.972443 733.902039,49.540611 731.765015,47.962769 
	C731.310425,47.242210 731.156982,46.783054 731.047363,45.945969 
	C731.264221,43.204281 730.205139,41.716026 727.600342,40.965134 
	C725.980225,40.210918 724.760620,39.364254 722.995422,38.990875 
	C722.508789,38.823849 722.318359,38.747227 721.853821,38.539322 
	C721.031006,38.153988 720.464539,37.972267 719.536621,38.017693 
	C718.568909,38.131359 717.912903,38.153862 716.911743,38.094662 
	C713.841797,37.216476 712.125366,34.644592 709.063965,33.667973 
	C707.031555,29.935175 702.852722,30.767313 700.020142,28.462090 
	C699.474548,27.766870 699.293823,27.299623 699.221924,26.408148 
	C699.304871,25.778292 699.380676,25.533031 699.689819,24.980967 
	C701.366760,23.779116 702.860657,24.110472 704.726685,24.535072 
	C705.839600,24.618845 706.562988,24.771791 707.629639,25.066778 
	C710.059265,25.848566 710.797607,27.641909 711.791626,29.683973 
	C712.783325,29.944548 713.518677,29.956848 714.621033,29.946791 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M833.766235,89.242218 
	C835.840759,89.047371 837.870850,89.400314 836.410767,91.766701 
	C834.046692,95.598114 836.970398,95.574646 839.179810,95.935524 
	C841.519226,96.317627 843.957397,96.091263 845.795349,94.459930 
	C847.617493,92.842598 849.363159,93.083832 851.659912,93.972946 
	C853.008972,94.986954 852.861450,96.616013 854.251831,97.749802 
	C854.632324,98.486816 854.717773,98.953590 854.670837,99.796524 
	C854.003235,101.950516 852.608887,102.889145 850.605774,103.668976 
	C849.141357,104.178246 847.959473,104.379761 846.546387,105.031815 
	C845.106689,105.702026 843.951477,106.019890 842.355957,105.830956 
	C839.693237,104.597275 837.533691,103.053635 834.729797,103.219345 
	C832.481873,103.352188 831.407776,101.351524 829.483643,100.171982 
	C828.714600,99.853195 828.299255,99.615891 827.670044,99.053925 
	C826.853699,97.555496 826.920227,96.364876 827.785522,94.909798 
	C830.339600,93.439400 832.002319,91.539536 833.766235,89.242218 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M684.682556,18.150776 
	C686.434937,17.995485 687.808655,17.998497 689.594849,17.998459 
	C690.748840,17.985266 691.490295,17.975119 692.602661,17.962749 
	C693.420715,18.595720 693.328308,19.270164 692.970703,20.274536 
	C692.681396,20.786606 692.539795,20.977299 692.115662,21.387943 
	C691.401123,21.826038 690.951416,21.998175 690.127014,22.206116 
	C688.775818,22.384411 687.809875,22.632353 686.440430,22.852219 
	C683.027954,22.285931 682.474304,20.752043 684.682556,18.150776 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M826.725220,82.811531 
	C827.873474,82.831718 828.732300,82.663437 829.800903,82.235550 
	C833.082581,83.043556 832.690491,85.806656 833.057983,88.592438 
	C832.707397,89.309395 832.297119,89.503777 831.479492,89.475159 
	C830.003845,86.741707 825.488892,87.319588 825.662842,83.232483 
	C826.020996,82.823402 826.228333,82.723228 826.725220,82.811531 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M705.257568,25.587189 
	C703.595276,25.599501 702.169556,25.285786 700.374329,24.953022 
	C699.379089,24.276634 699.271118,23.510942 699.765381,22.331560 
	C700.831787,20.818333 702.078735,21.036383 703.620361,21.033352 
	C705.163452,22.205818 706.267456,23.397432 705.257568,25.587189 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M830.759094,89.203125 
	C831.544312,89.018120 831.976624,89.010490 832.734375,88.994492 
	C833.059692,88.986115 833.533508,88.987106 833.770386,88.990982 
	C834.104126,92.107376 832.292542,93.817482 829.299377,94.947357 
	C827.598328,92.631401 829.043945,91.016823 830.759094,89.203125 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M699.666748,21.996090 
	C700.001648,22.730526 700.003235,23.466244 700.004883,24.567966 
	C700.002014,25.105452 699.999084,25.276930 699.998047,25.705658 
	C699.143250,26.774433 698.137268,27.115183 696.568176,26.804319 
	C695.137085,26.124472 694.551636,25.261190 694.485413,23.695982 
	C695.122559,22.213860 696.114990,21.992624 697.610474,22.001820 
	C698.444458,21.998165 698.888916,21.997768 699.666748,21.996090 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M705.392944,25.934231 
	C704.706482,24.526365 704.391907,23.139534 704.030396,21.397076 
	C705.387634,21.040039 706.525330,21.705637 707.807251,22.758907 
	C708.578186,23.692570 708.735535,24.451258 708.247131,25.623098 
	C707.255615,25.951414 706.510193,25.953339 705.392944,25.934231 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M692.267273,20.259617 
	C692.261292,19.500595 692.520020,18.997383 692.876099,18.227348 
	C693.100159,17.654985 693.298706,17.406986 693.783325,17.106014 
	C695.007751,16.151733 695.885132,16.488270 696.873474,17.651405 
	C697.042419,18.752052 697.046875,19.501801 697.056763,20.626400 
	C696.724243,21.375383 696.313782,21.635403 695.460876,21.816200 
	C694.037476,21.788340 693.216431,21.282751 692.267273,20.259617 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M708.188477,26.230423 
	C708.000732,25.218473 708.000427,24.487459 708.000061,23.390869 
	C708.442261,23.021479 708.884583,23.017664 709.658569,23.015888 
	C711.084900,23.887045 711.551392,25.074156 711.853027,26.708702 
	C712.005737,27.734516 712.016418,28.468283 712.037476,29.567148 
	C709.620056,30.084167 710.108582,27.105680 708.188477,26.230423 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M854.267212,97.948730 
	C851.023071,98.237076 851.079651,96.701836 851.985474,94.359955 
	C852.027649,94.020721 852.513794,94.009247 852.756897,94.003540 
	C853.749390,93.996010 854.498779,93.994194 855.622864,93.994690 
	C857.243652,95.877151 855.819092,96.807106 854.267212,97.948730 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M697.353394,17.948719 
	C696.238403,17.689638 695.438904,17.376976 694.318481,17.029911 
	C694.400146,14.662678 695.968872,14.694430 697.558594,15.474417 
	C698.652954,16.011379 699.339478,17.012552 697.353394,17.948719 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M712.138794,26.678726 
	C710.570251,26.364159 709.961853,25.233574 709.997070,23.363712 
	C712.534485,22.908735 713.615479,23.804081 712.138794,26.678726 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M694.797607,21.302528 
	C695.481140,21.003508 695.932190,21.002335 696.722778,21.001207 
	C697.370178,21.110315 697.615784,21.299990 697.899597,21.784420 
	C697.467712,22.878040 696.607727,22.989172 695.362610,22.979153 
	C694.666260,22.588270 694.511780,22.129299 694.797607,21.302528 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M960.407898,783.675903 
	C960.826294,787.550232 965.401978,790.053589 963.048279,794.158630 
	C962.370605,795.340454 962.104431,796.848877 960.587891,796.854492 
	C956.585938,796.869324 954.661011,800.134216 951.882080,802.063904 
	C951.039124,802.649353 950.103821,803.248474 950.467712,804.407654 
	C950.877380,805.712769 952.073914,805.949951 953.302246,805.970520 
	C955.965576,806.015137 958.012085,804.488464 960.158875,803.248901 
	C961.431824,802.513977 962.882874,801.090515 963.733826,803.363892 
	C964.261780,804.774231 964.722534,806.866333 962.692200,807.734009 
	C959.146301,809.249207 956.157959,812.241272 951.934998,811.995239 
	C949.750000,811.867920 949.247559,813.131958 948.774292,814.968933 
	C948.183655,817.260986 948.160034,820.054749 945.182129,821.043945 
	C944.833618,821.159729 944.361206,822.060486 944.445923,822.156372 
	C948.128601,826.329834 943.235535,830.697327 944.761902,834.950012 
	C945.370789,836.646667 944.626099,839.173096 942.810852,838.653137 
	C937.959534,837.263672 937.269775,840.964722 935.289978,843.799438 
	C930.949707,844.127808 932.352417,839.735596 930.031738,837.943481 
	C929.678955,837.452515 929.554749,837.241211 929.316284,836.675232 
	C928.989502,834.967285 929.380310,833.822998 930.716187,832.698486 
	C931.530090,832.358887 932.023926,832.331299 932.861572,832.567200 
	C935.068359,834.800354 936.674622,835.131836 937.809265,832.040955 
	C932.849243,830.309814 932.713257,829.971375 935.176758,825.578125 
	C938.140442,820.292847 937.979675,818.778259 933.986328,814.319519 
	C932.559937,812.726990 931.779114,810.893555 932.681458,808.837036 
	C933.684937,806.549866 935.452332,805.082397 938.100952,805.152649 
	C940.124084,805.206238 941.997375,805.903687 943.838745,806.719666 
	C944.669983,807.087891 945.812561,807.940491 946.381165,806.564819 
	C946.932190,805.231445 945.917725,804.400635 944.780151,803.903320 
	C943.214111,803.218567 941.507446,803.420471 939.861450,803.242188 
	C937.769958,803.015747 935.853516,802.438660 935.234985,800.136658 
	C934.654419,797.976624 935.845520,796.893860 937.714478,795.912903 
	C940.477356,794.462952 938.003296,792.880615 937.377136,791.032166 
	C936.844055,788.124695 938.296814,786.976501 940.546082,786.799927 
	C943.285400,786.584839 946.501709,785.924744 947.875244,789.211121 
	C949.067261,792.063171 951.080322,791.984131 953.468201,791.839294 
	C955.809021,791.697449 957.256836,791.100159 956.644897,788.263794 
	C956.100769,785.741455 956.624207,783.621704 960.407898,783.675903 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M968.330322,749.995850 
	C962.287415,751.577271 959.893738,756.163879 958.230652,761.180908 
	C957.700867,762.778992 957.758545,765.324768 960.865662,763.689148 
	C964.303162,761.879761 963.658875,765.001892 964.618652,766.737000 
	C963.954346,768.693970 962.752502,769.956421 960.309753,769.950439 
	C958.635986,768.332703 957.093506,767.603088 954.747131,768.811768 
	C952.228455,769.053650 950.434753,768.449036 949.290344,766.008179 
	C949.128235,764.293030 949.788269,763.229858 950.722778,761.890503 
	C951.341797,760.996521 951.782227,760.424683 952.403809,759.544678 
	C956.444519,755.574951 959.176941,751.751038 955.651062,746.220581 
	C954.340576,744.165039 955.232727,741.903137 957.149048,740.486450 
	C960.200806,738.230469 963.227783,735.762695 967.738892,735.923828 
	C968.545715,735.820190 969.008667,735.810791 969.817993,735.875916 
	C970.450562,740.014526 970.737671,744.124146 970.958618,748.576050 
	C970.140564,749.258850 969.388672,749.599365 968.330322,749.995850 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M934.278320,866.730469 
	C928.923767,867.161621 924.859375,872.353333 926.707397,876.848511 
	C927.247742,877.804565 927.495483,878.609131 927.871582,879.706848 
	C927.012390,884.810181 921.390137,885.535828 919.278809,889.796936 
	C914.798218,886.816711 915.021423,885.877380 921.287781,880.420898 
	C916.333496,877.007751 916.287964,876.579712 919.687988,871.178650 
	C921.670898,868.028625 924.529053,865.246521 923.544434,860.869446 
	C923.213928,859.399963 924.758301,858.222595 925.533081,856.579346 
	C925.738708,856.057495 925.828064,855.853882 926.088745,855.369202 
	C926.956787,854.100830 928.194641,853.448425 928.180298,851.690430 
	C928.247131,850.929626 928.364746,850.507385 928.731323,849.820068 
	C930.615784,848.361023 932.118774,846.830139 934.667969,846.842041 
	C934.839661,853.479187 934.696899,859.967590 934.278320,866.730469 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M954.806641,768.246948 
	C957.087585,766.615845 959.565796,763.702148 959.962585,769.567871 
	C959.960754,769.938843 959.933838,770.425110 959.927856,770.668579 
	C959.350403,772.751282 957.701416,773.496948 955.966309,774.657898 
	C954.558655,775.273132 953.396729,775.354919 952.269592,775.594849 
	C950.501892,775.971069 948.186401,776.094727 947.648682,777.945374 
	C946.954956,780.333069 949.807190,779.760132 950.938110,780.691101 
	C952.355042,781.857422 953.182251,783.124146 952.656616,784.949646 
	C952.168030,786.646423 950.703369,787.217651 949.186707,787.224304 
	C946.558044,787.235779 943.930725,786.929382 940.957397,786.865479 
	C939.310181,786.412842 938.873047,784.840759 937.190613,784.487854 
	C935.304138,784.315857 933.774414,784.317383 932.231689,785.609619 
	C931.074280,786.753845 930.649963,788.190613 928.842896,788.519531 
	C928.304504,788.424438 928.100708,788.335144 927.705933,787.975769 
	C927.469238,787.492737 927.423523,787.279846 927.423340,786.749390 
	C927.217957,785.940125 928.559143,786.254395 927.614502,785.450806 
	C926.336548,784.411926 926.789612,783.389893 927.246338,782.083862 
	C927.758057,780.994202 927.733765,780.223938 927.035828,779.198608 
	C926.260315,777.838928 925.981934,776.720642 926.545837,775.193604 
	C927.125793,774.598755 927.563965,774.425476 928.403015,774.436890 
	C931.003418,777.104431 933.280518,776.490234 935.917969,774.547119 
	C936.754517,774.696472 937.139526,774.990784 937.447083,775.395630 
	C938.173340,776.351807 937.808472,778.262390 939.777771,778.118469 
	C940.292908,778.080750 940.781921,777.701233 940.800842,777.181946 
	C940.873291,775.190247 939.226074,774.905457 937.861755,774.364685 
	C937.398499,774.181091 936.922668,774.032166 936.095459,773.821411 
	C933.828979,772.675171 931.638123,772.470886 929.274780,771.588623 
	C928.585205,771.016052 928.336670,770.574036 928.159424,769.677612 
	C928.154907,769.027039 928.191895,768.767029 928.382568,768.143555 
	C929.181030,766.877197 930.062622,766.385925 931.536865,766.278809 
	C933.993469,766.776611 935.942566,767.866577 938.524048,767.654968 
	C941.099670,767.752380 943.090332,768.531555 944.905212,769.781250 
	C948.525818,772.274475 951.742615,771.747864 954.806641,768.246948 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M934.990967,846.617737 
	C932.908569,847.426025 932.035339,849.965210 929.375366,849.996033 
	C925.450562,850.431946 924.478638,849.358215 925.521362,845.909241 
	C926.417542,842.945007 928.802612,840.661804 929.742004,837.286194 
	C933.961365,837.576904 931.942200,842.091980 934.708801,843.835815 
	C935.016663,844.752319 935.008118,845.498474 934.990967,846.617737 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M955.666138,774.186279 
	C956.152893,771.868347 958.127563,772.019775 959.616028,771.044556 
	C960.737976,772.381348 961.410400,773.811462 960.108154,775.419189 
	C958.956665,776.840698 959.257874,777.778687 961.204407,777.975403 
	C962.723022,778.128967 964.794128,777.530457 964.875549,780.039490 
	C964.942810,782.108948 963.586426,783.264282 961.388062,783.972412 
	C961.028137,784.026672 961.006531,784.006104 960.996582,783.996521 
	C960.021301,782.825317 958.768250,782.189819 957.354553,781.656067 
	C952.365723,779.772522 952.150208,778.843750 955.666138,774.186279 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M998.772339,587.244385 
	C1000.843506,582.935242 1002.949158,579.114380 1007.685547,577.154724 
	C1009.889526,578.853577 1009.906433,582.163940 1009.389282,583.476074 
	C1007.107483,589.265747 1008.560913,595.097717 1008.356445,600.843933 
	C1008.212524,604.890381 1007.993408,608.830627 1007.009888,612.742310 
	C1006.891113,613.214539 1007.015991,613.742981 1006.942688,614.232605 
	C1006.460999,617.452637 1004.276001,619.505371 1001.882385,621.347046 
	C1001.089966,621.956787 1000.306763,621.475586 999.884033,620.609802 
	C998.369202,617.507385 999.826538,614.823669 1001.322510,612.302673 
	C1002.175659,610.864868 1003.616943,609.755798 1002.288330,607.936646 
	C1000.888123,606.019348 998.757568,605.344910 996.610046,605.415894 
	C994.286560,605.492859 994.071838,607.652710 993.469849,609.706177 
	C993.026550,610.124329 992.800598,610.274597 992.189514,610.290161 
	C991.009033,609.301636 990.842773,608.242188 990.842529,606.738403 
	C991.024475,605.298035 991.423950,604.342896 992.105591,603.104736 
	C996.768250,599.730225 998.309937,593.881836 1003.562500,591.896545 
	C1002.493347,589.027893 998.327454,591.023621 998.772339,587.244385 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M983.693481,633.798950 
	C984.980103,636.564880 986.537537,637.761658 989.474609,638.204590 
	C996.092407,639.202454 996.989868,641.526062 992.755920,646.338745 
	C990.222717,649.218201 991.424561,651.935669 992.164551,654.694397 
	C992.867920,657.316467 995.710388,659.597168 994.451477,662.455872 
	C993.162964,665.381775 989.496033,663.224792 987.269897,664.830688 
	C984.246643,667.011597 982.398315,665.495178 981.109131,662.168823 
	C980.352417,661.063660 980.443726,660.071838 981.245911,659.845642 
	C984.787720,658.847168 984.460632,655.978821 984.658813,652.969482 
	C984.725647,651.906006 984.886597,651.214844 985.273376,650.212219 
	C988.288635,646.162048 987.411316,642.807068 983.056274,641.468445 
	C980.872742,640.797241 978.927734,640.183838 979.092651,637.007324 
	C979.918030,634.726868 981.060486,633.344299 983.693481,633.798950 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M984.131104,633.742126 
	C982.252441,634.034058 981.419434,635.399658 980.231750,636.776550 
	C979.827576,637.149841 979.601013,637.251526 979.052246,637.170044 
	C978.158630,632.996094 977.871155,629.082825 981.926270,626.515808 
	C983.317932,625.634827 983.226868,624.338745 983.026855,622.993958 
	C982.807129,621.517273 982.084106,619.671021 982.864136,618.608276 
	C986.272339,613.965149 986.029602,608.581726 986.037964,603.401123 
	C986.047485,597.489685 987.571960,591.723083 987.428101,585.493835 
	C988.332764,584.547119 988.972595,584.725952 989.808960,585.662842 
	C990.352600,586.088684 990.603821,586.280090 990.925110,586.744263 
	C992.241028,588.557556 993.488342,590.150208 992.293152,592.704346 
	C992.000610,593.760254 991.940247,594.460632 991.894653,595.825562 
	C991.759521,597.539490 991.290955,598.476013 990.709961,599.697266 
	C990.285583,600.282959 989.974365,600.543701 989.906738,600.780884 
	C990.347778,600.982605 990.486816,601.135681 990.764893,601.568848 
	C991.384094,603.021240 991.251770,604.332458 991.980896,605.695740 
	C992.235962,606.962341 992.242859,608.037781 992.232300,609.516235 
	C991.069397,612.434692 990.614319,614.975708 991.956177,617.905762 
	C991.975464,619.072937 992.043274,619.904480 992.491699,620.992493 
	C992.804688,622.465271 992.333374,623.378845 991.283020,624.362671 
	C990.409241,624.983582 989.571289,625.220825 989.511047,626.500000 
	C989.384888,628.378784 988.527222,629.534485 987.162354,630.747437 
	C985.444763,631.154602 984.895264,632.354065 984.131104,633.742126 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M989.781494,624.184998 
	C990.971863,623.495361 991.713440,622.819519 991.930664,621.366821 
	C991.557007,620.622559 991.200439,620.157776 990.996460,619.280457 
	C990.835388,616.075317 988.772400,613.028381 991.887024,610.118530 
	C992.387512,609.919556 992.560364,609.919800 992.992859,609.947021 
	C991.834351,612.593140 992.042603,614.996094 993.221375,617.830139 
	C994.490051,620.880615 996.432861,624.885559 990.410645,626.358887 
	C989.913574,626.373535 989.759338,626.247009 989.505005,625.838379 
	C989.341003,625.153809 989.391968,624.768555 989.781494,624.184998 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M985.612549,652.774170 
	C986.569824,655.222778 985.368530,657.323425 985.437012,659.594177 
	C985.530212,662.684631 981.889709,658.710815 981.035339,661.520996 
	C980.546265,661.842102 980.109558,661.854004 979.345093,661.864624 
	C978.253967,661.688538 977.784607,661.191895 977.471436,660.130859 
	C977.732483,657.903137 979.386902,657.277527 980.896851,656.051270 
	C982.328552,654.454712 982.893127,652.304443 985.612549,652.774170 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M977.673218,659.828247 
	C978.249573,660.540649 978.525146,660.971863 978.908997,661.633179 
	C976.599060,664.577148 976.930725,667.883545 977.009521,671.568726 
	C976.504333,672.251160 976.000916,672.265869 975.149597,671.843567 
	C974.623047,671.447083 974.436768,671.256531 974.034424,670.728149 
	C972.592896,666.909363 973.376770,663.550781 974.720337,659.913208 
	C975.781494,659.231628 976.571716,659.212585 977.673218,659.828247 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M974.578247,671.521790 
	C975.394653,671.388245 975.924988,671.598755 976.727661,671.904602 
	C977.168884,672.855347 977.337769,673.710754 977.753296,674.783081 
	C977.713501,675.582764 977.427063,676.165588 976.830505,676.868652 
	C972.497192,676.707947 973.957031,674.042358 974.578247,671.521790 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M987.282227,630.934937 
	C986.823914,629.141113 988.609314,628.285400 989.131714,626.412842 
	C989.326660,625.924194 989.442871,625.771118 989.783203,625.799316 
	C990.007324,625.980469 990.020447,626.237061 990.044189,626.368591 
	C990.078186,628.421326 990.072510,630.333801 987.282227,630.934937 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M948.691284,263.319000 
	C950.777039,263.460480 950.977112,266.318268 953.658691,265.989563 
	C954.235229,265.874847 954.460083,265.761627 955.033691,265.796143 
	C956.376099,266.821136 956.796387,267.996887 957.165039,269.598938 
	C957.330322,270.681732 957.347717,271.406525 957.389282,272.479187 
	C957.766907,276.265656 959.372742,279.467987 956.745239,283.127350 
	C955.330139,285.098206 959.424072,285.803101 960.739624,287.782471 
	C962.100037,288.694916 963.228760,289.361267 964.688782,290.061340 
	C967.299438,289.538788 968.493713,290.803345 968.900696,292.752380 
	C969.536987,295.799957 971.186279,298.497437 971.966614,302.244934 
	C972.047546,303.444916 971.903381,303.954620 971.568604,304.813049 
	C970.052795,306.413849 968.443481,306.331848 966.428955,306.032318 
	C965.319153,305.631531 964.744385,305.148773 964.163330,304.165161 
	C963.668945,302.525696 963.241089,301.289764 961.242188,301.104462 
	C960.704346,300.922943 960.492310,300.835541 959.984070,300.567017 
	C958.092346,298.384521 955.569702,297.272858 954.283752,294.534180 
	C954.100708,293.735596 954.079834,293.269775 954.180481,292.460999 
	C955.276733,289.924500 954.168579,288.846771 951.900757,288.075653 
	C951.410645,287.817383 951.224121,287.698944 950.797729,287.355560 
	C949.914917,286.290314 949.476196,285.276093 948.232239,284.522705 
	C945.360046,282.099854 946.042419,279.683380 948.356873,278.173676 
	C950.484375,276.785919 950.455750,274.590302 951.515076,272.840546 
	C952.530945,271.162506 951.982971,269.677032 950.445862,268.547211 
	C948.745667,267.297546 947.462402,265.894806 948.691284,263.319000 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M974.678223,337.819275 
	C976.366272,339.868988 977.567993,341.717987 976.938354,344.555969 
	C975.268250,346.891632 976.519165,348.615692 977.516479,350.648010 
	C977.896606,351.326935 978.058594,351.743622 978.280273,352.497375 
	C978.852417,354.895569 980.133667,355.707520 982.237854,355.384430 
	C983.700806,355.159821 985.118225,355.278351 986.343872,356.868408 
	C986.570740,357.438049 986.621277,357.684143 986.667969,358.312439 
	C985.838562,366.910126 985.658875,367.068787 974.502441,369.232758 
	C972.576111,369.380005 971.744019,368.529175 971.409302,366.724670 
	C972.160400,363.116577 978.297913,363.920502 977.181030,361.578156 
	C974.953491,356.906921 974.583557,351.453430 970.886719,347.152039 
	C970.034668,346.520813 969.623535,345.971619 969.316162,344.964417 
	C969.271729,342.167236 968.946411,339.846039 966.390869,338.116821 
	C965.382324,337.275879 964.776917,336.552338 964.455994,335.237152 
	C964.498352,334.628906 964.568787,334.390625 964.901855,333.885620 
	C969.115234,332.888092 971.564270,335.712555 974.678223,337.819275 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M965.750244,305.215210 
	C967.565796,305.026123 969.111694,305.074524 971.017883,305.142334 
	C971.549683,306.498230 971.721313,307.834656 971.914124,309.578400 
	C971.797668,310.160034 971.659973,310.334412 971.248291,310.688568 
	C969.197449,311.432526 969.458862,312.428070 970.570435,313.875061 
	C970.903442,314.630402 971.017090,315.091705 971.099731,315.931885 
	C970.986450,321.854340 966.342773,324.052765 962.643921,327.354370 
	C958.979736,327.568695 958.378662,323.707825 955.347961,322.679565 
	C953.827026,321.104523 953.667297,319.558014 954.269592,317.516785 
	C955.093323,316.213837 956.078064,315.779053 957.460571,315.252380 
	C958.818909,314.837280 959.831177,314.768463 961.206360,314.807434 
	C962.488281,314.837250 963.516479,315.697968 964.218445,315.131927 
	C964.614807,314.812317 963.555237,313.918060 963.184326,312.880341 
	C962.724792,311.366211 962.733459,310.172760 963.131470,308.627930 
	C963.759827,307.154572 964.656677,306.332489 965.750244,305.215210 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M973.693420,369.623352 
	C974.092834,367.819214 974.948608,367.201843 976.494629,366.975922 
	C984.386353,365.822662 984.378906,365.771545 985.712097,358.265198 
	C990.836121,360.997650 991.108276,366.993103 986.304749,369.518280 
	C984.032654,370.712708 982.224060,371.599823 983.675232,374.797363 
	C984.162354,375.260956 984.429321,375.457581 984.820435,375.872528 
	C986.784973,377.694397 987.434509,379.433899 985.298523,381.753906 
	C983.842407,382.861145 984.109436,384.120056 984.026306,385.643494 
	C981.316528,386.395599 980.918823,384.202179 980.046021,382.533203 
	C978.094788,378.801971 977.124329,374.580048 974.170776,370.950134 
	C973.850281,370.437408 973.754456,370.212738 973.693420,369.623352 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M974.914307,337.745026 
	C971.899048,336.734070 968.877930,335.364990 965.511841,333.915070 
	C964.112793,333.483490 963.626770,332.619720 963.124329,331.327972 
	C962.638916,327.990906 964.259583,325.781677 966.359070,324.233459 
	C968.765869,322.458649 970.207458,320.293945 970.991089,317.181274 
	C971.082947,315.973877 971.418274,315.126801 971.240051,313.905090 
	C971.243530,313.123322 971.343506,312.690094 971.723633,312.008667 
	C972.156738,311.686432 972.375061,311.626740 972.858887,311.778748 
	C974.794250,312.260101 976.018433,312.888214 975.518066,314.930115 
	C974.879822,317.534546 974.492981,319.958435 976.260376,322.506866 
	C977.370605,324.107697 975.400452,325.073547 974.199280,325.870392 
	C967.585754,330.258148 967.589661,330.230835 973.573364,335.384247 
	C974.158997,335.888611 974.470032,336.711731 974.914307,337.745026 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M983.928223,374.651917 
	C980.988159,373.871185 980.610840,370.970123 983.846130,369.543365 
	C990.153320,366.761932 988.958923,362.924652 986.259033,358.214996 
	C985.991211,357.996918 985.976685,357.513580 985.973267,357.271790 
	C985.163025,355.659485 984.524658,354.212189 982.370483,354.744934 
	C980.640503,355.172821 978.862915,355.041382 977.386597,353.176270 
	C977.143494,352.486176 977.102417,352.061737 977.043640,351.317993 
	C977.086670,350.611755 977.272949,350.295654 977.843384,349.974792 
	C978.597900,350.036316 979.072021,350.256958 979.553955,350.275726 
	C986.025513,350.527924 991.307922,352.002899 990.347717,360.302948 
	C990.291382,360.790100 991.026917,361.383759 991.422913,361.905975 
	C994.945251,366.550842 993.517578,370.169312 987.616455,370.901855 
	C985.317139,371.187286 983.725098,371.513947 983.928223,374.651917 
z"
    />
    <path
      fill="#2B2929"
      opacity="1.000000"
      stroke="none"
      d="
M974.706787,297.255066 
	C977.020447,297.695984 979.592163,298.780853 979.016663,301.000824 
	C978.312317,303.717438 975.217102,302.898834 972.578735,302.968109 
	C972.191772,302.935181 972.028625,303.038849 971.934448,303.060669 
	C971.226807,302.208923 971.363525,301.328552 972.000244,300.157898 
	C972.901611,299.030975 973.615540,298.187836 974.706787,297.255066 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M985.446777,382.040192 
	C985.273987,380.284760 986.200928,378.524628 984.995605,376.426208 
	C987.901428,376.535004 989.591492,378.217499 989.931030,381.561432 
	C988.562378,381.955963 987.208435,381.995239 985.446777,382.040192 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M974.751343,296.921692 
	C974.935791,298.170135 975.141418,299.393982 973.368042,299.954041 
	C970.283447,298.245544 967.963684,296.274170 969.756958,292.239746 
	C972.697083,291.949097 971.290039,295.342529 973.625366,295.952576 
	C974.091614,296.334595 974.285034,296.590210 974.751343,296.921692 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M977.955017,349.581451 
	C977.908020,350.187500 977.695862,350.465485 977.254883,350.871094 
	C975.979187,349.134827 973.991272,347.396790 976.563599,345.015320 
	C977.190613,346.376404 977.490234,347.814911 977.955017,349.581451 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M973.074219,311.681366 
	C972.940552,311.995453 972.756836,312.000458 972.297424,312.004730 
	C970.942383,312.191162 970.635803,311.538391 970.727722,310.286011 
	C971.080200,309.913330 971.252930,309.902527 971.680481,309.938721 
	C972.298279,310.447876 972.661255,310.910065 973.074219,311.681366 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M849.887695,103.328163 
	C851.161621,102.130981 852.321228,101.252602 853.743896,100.186852 
	C854.172668,99.857948 854.338562,99.716408 854.830078,99.560196 
	C855.976990,99.693512 856.799866,99.961525 857.908081,99.384613 
	C858.606384,99.108383 859.018066,98.988907 859.753723,98.842224 
	C861.535706,98.714981 862.977600,98.498055 864.694580,98.025543 
	C866.582458,98.975967 869.044983,98.868042 869.194275,101.607254 
	C869.244751,102.532364 870.221313,103.138802 871.024475,102.899788 
	C875.452087,101.582115 876.888428,103.312469 877.622070,107.725716 
	C878.253662,111.525108 888.804749,117.324158 892.595398,116.899391 
	C896.441406,116.468422 897.603027,114.418388 896.006714,110.960243 
	C895.542297,109.954239 894.440063,108.740097 895.558716,107.802399 
	C896.946838,106.638977 897.304749,108.678703 898.204590,109.064888 
	C902.032837,110.707863 903.986511,114.706612 908.539490,116.085648 
	C910.786804,116.766319 914.470398,120.770340 916.128296,124.270157 
	C917.422119,127.001633 917.467163,130.240219 921.689331,130.692978 
	C923.257751,130.861176 922.849915,132.957443 921.415710,133.684128 
	C918.997925,134.909134 918.872498,136.944244 919.012695,139.585205 
	C919.031067,141.756897 918.842834,143.409180 916.348389,143.952148 
	C914.876648,142.186157 915.440308,140.110992 915.270874,138.197159 
	C915.087708,136.127792 915.135559,134.367996 912.984314,133.239197 
	C911.531250,132.476730 911.157410,130.939651 911.266052,129.356873 
	C911.514709,125.733597 909.524536,125.155426 906.222290,125.807396 
	C903.806946,124.873573 902.075500,123.214493 899.354248,122.966721 
	C897.869019,122.353058 896.850952,122.595909 895.738037,123.171997 
	C892.437073,124.880699 889.656311,124.329498 887.685974,120.989616 
	C886.670715,119.268661 885.064392,118.553619 883.187683,118.240776 
	C881.165161,117.903610 879.458313,116.959488 877.981873,115.534576 
	C873.477478,111.187248 873.468811,111.196236 869.210815,116.303185 
	C867.997131,117.255623 866.976135,117.846962 865.523193,118.380386 
	C864.939453,118.475197 864.700989,118.475838 864.126770,118.353439 
	C863.227905,117.782867 862.833862,117.204674 862.377319,116.260056 
	C861.713745,114.512978 862.170105,112.941681 861.431763,111.270966 
	C861.097290,110.876770 860.961914,110.719574 860.637939,110.313843 
	C860.006592,109.369865 859.681946,108.602135 858.942749,107.728218 
	C857.160522,106.040787 855.296021,107.327522 853.601379,106.947746 
	C851.706543,106.523109 850.395874,105.505600 849.887695,103.328163 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M855.353149,100.232216 
	C854.999512,100.023811 854.503296,100.004829 854.255066,100.002151 
	C853.985596,99.571007 853.964294,99.142525 853.920593,98.394211 
	C854.564941,96.936333 855.231689,95.798294 855.947998,94.328629 
	C856.489990,93.717674 856.982361,93.438370 857.736572,93.050354 
	C863.278503,93.971397 863.797852,95.295319 860.260742,99.711349 
	C859.537964,99.995850 859.101929,100.008568 858.338989,100.035934 
	C857.313477,100.592888 856.544128,100.717941 855.353149,100.232216 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M860.371094,99.988205 
	C860.879028,97.472626 863.469788,94.556389 858.203857,93.211670 
	C862.660217,90.594604 863.768982,94.012154 864.997681,97.620392 
	C864.569031,100.722115 862.402100,99.793617 860.371094,99.988205 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M1005.204651,450.768921 
	C1001.621033,450.529205 999.538452,452.301392 997.954285,455.337097 
	C996.914978,457.337799 995.664734,456.411804 994.131592,455.772461 
	C993.619385,455.365845 993.435913,455.179993 993.033264,454.675049 
	C990.002869,449.135590 990.153015,448.260620 995.191833,445.517639 
	C996.927673,444.572662 997.555725,443.399200 997.483826,441.593811 
	C997.413574,439.826538 996.169067,439.636017 994.788513,439.366089 
	C990.801758,438.586578 988.943970,435.984406 988.879395,431.642212 
	C989.011719,431.029297 989.091675,430.791138 989.367859,430.232361 
	C991.599365,428.540070 994.007690,427.971313 996.516235,426.931641 
	C997.722717,427.591400 998.571533,428.167908 999.698181,428.883057 
	C1000.460144,429.473724 1000.945618,430.075043 1001.600647,430.100616 
	C1007.020935,430.312195 1007.263733,430.367737 1005.670532,435.516907 
	C1004.823853,438.253540 1004.565308,440.214813 1007.752441,441.758606 
	C1008.743286,443.312866 1009.143188,444.710327 1008.981812,446.608215 
	C1007.962769,448.327728 1004.782532,447.493317 1005.204651,450.768921 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M1007.958130,441.630737 
	C1005.020081,441.333008 1000.337769,441.940155 1004.507507,436.467133 
	C1005.553223,435.094452 1005.276855,432.714478 1005.636108,430.635376 
	C1003.769470,429.863281 1001.099182,433.862732 1000.022461,429.357666 
	C1002.458618,428.942383 1003.885925,428.473755 1001.267578,426.077362 
	C998.981812,423.985352 997.568665,421.904297 1001.930664,420.470917 
	C1003.863220,419.835876 1003.866150,418.657166 1002.742310,417.043518 
	C1001.625061,415.439240 1001.604614,413.604797 1002.819336,411.950989 
	C1003.351685,411.226227 1004.348022,410.825623 1005.010986,411.342865 
	C1006.728577,412.682861 1008.194580,414.351166 1007.941956,416.744507 
	C1007.683044,419.197021 1006.562073,421.734741 1006.953430,424.042694 
	C1007.751770,428.750763 1007.388672,433.209656 1006.289062,437.782074 
	C1006.054504,438.757355 1007.331543,440.096222 1007.958130,441.630737 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M992.651001,392.981567 
	C993.312073,394.133301 993.630737,395.235748 994.022339,396.679749 
	C994.089539,397.761383 994.083862,398.501465 994.103149,399.611237 
	C995.057495,401.333618 995.142944,402.767609 994.293518,404.644623 
	C994.045532,405.021393 994.007751,404.997009 994.016052,405.017761 
	C993.248901,405.911926 992.903931,407.223755 991.174255,407.487488 
	C989.376160,406.985962 989.259094,405.865448 989.629517,404.660309 
	C991.235168,399.436737 988.843628,395.376648 985.583008,391.268738 
	C985.118286,390.906067 984.943542,390.745178 984.545898,390.303528 
	C983.821777,389.174072 983.508179,388.285156 984.013916,387.054443 
	C988.526001,386.813477 989.155701,391.529480 992.651001,392.981567 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M996.849365,426.645081 
	C995.098389,428.526520 993.681335,430.761444 990.406311,430.017944 
	C989.915100,429.783081 989.810974,429.559326 989.712158,428.965881 
	C993.216431,423.663971 988.987061,418.365540 990.412231,413.305023 
	C990.656921,412.436371 990.122375,411.361450 990.708862,410.079895 
	C991.383545,409.535187 991.868896,409.470856 992.692139,409.786926 
	C994.677124,415.329498 993.010376,421.445129 996.849365,426.645081 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M999.204956,398.804382 
	C1000.772949,395.624542 1002.812561,397.728241 1004.082825,398.471375 
	C1005.550720,399.330139 1006.044739,401.464844 1005.843689,403.280975 
	C1005.636536,405.152924 1004.392822,404.778748 1003.453796,403.838745 
	C1001.973206,402.356506 1000.636414,400.730560 999.204956,398.804382 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M1005.138428,451.274475 
	C1004.443726,449.574982 1003.898804,448.120544 1003.367432,446.702240 
	C1005.241150,445.404144 1006.647705,447.273895 1008.605774,446.977020 
	C1010.714966,449.432465 1008.851685,450.527954 1006.999634,451.994690 
	C1006.297180,452.031189 1005.792786,451.775360 1005.138428,451.274475 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M993.276123,409.741760 
	C992.592957,409.971008 992.178833,409.945587 991.453491,409.915344 
	C990.370300,409.358246 990.214478,408.578033 990.508423,407.325653 
	C991.604004,406.398773 992.515381,405.845734 993.725586,405.165588 
	C993.864624,406.521362 993.704956,408.004242 993.276123,409.741760 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M994.395203,404.941162 
	C994.041992,403.619995 994.038513,402.218567 994.081543,400.399048 
	C996.886902,401.284943 996.684387,402.963196 994.395203,404.941162 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M796.332764,962.945679 
	C794.512024,961.181396 790.974670,962.843140 790.148682,959.321777 
	C787.129272,956.011902 782.917419,956.375488 779.460266,954.828125 
	C777.887878,954.124329 777.029236,955.604980 776.583374,956.881714 
	C775.616272,959.651306 773.709229,961.720154 771.770630,963.787292 
	C770.034424,965.638550 768.095581,966.973938 765.780945,964.845215 
	C764.207336,963.397949 763.486206,965.020508 762.363220,965.388000 
	C759.043030,966.474243 757.586670,970.722595 753.221680,970.378296 
	C751.664185,966.322449 754.355652,964.389038 756.989990,962.761597 
	C759.296570,961.336670 761.373352,959.837524 762.929626,957.155640 
	C763.716064,956.047546 764.423828,955.371582 765.559937,954.630737 
	C766.344910,954.318481 766.815918,954.242004 767.642334,954.289368 
	C770.483948,955.783325 770.753418,953.794434 771.450562,951.893799 
	C772.077087,951.010071 772.650269,950.581299 773.643677,950.112427 
	C777.318359,949.303040 780.757874,949.034485 784.164795,947.235596 
	C786.148682,946.701111 787.769165,946.968933 789.271851,947.559753 
	C791.359924,948.380737 793.021057,947.832214 794.873901,946.192139 
	C797.796387,944.563110 800.405579,944.612061 803.380188,946.157715 
	C804.906982,947.510498 805.162781,948.811890 804.246582,950.642700 
	C803.009644,950.939087 802.001587,951.000427 801.042542,950.812195 
	C796.606140,949.941467 793.014832,952.824158 793.352600,957.221130 
	C793.626221,960.781494 796.570007,959.424438 798.588623,959.455017 
	C798.592834,961.233337 798.071655,962.331909 796.332764,962.945679 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M803.229248,946.800659 
	C800.589417,946.366333 798.189331,945.439270 795.137146,946.716125 
	C793.224548,942.224365 797.058289,940.698547 799.224365,937.881287 
	C801.089661,935.423767 802.593811,933.498352 805.880859,935.473206 
	C807.981506,936.735291 808.978455,935.179932 808.495361,933.086121 
	C807.854004,930.306274 808.115540,927.869507 810.589355,925.658569 
	C815.070923,925.214355 818.055603,926.698364 819.602417,930.901123 
	C820.504395,933.351868 821.857056,932.833374 822.935974,930.432861 
	C823.377380,927.948669 823.482361,925.759216 824.292725,923.321045 
	C825.676819,921.550903 827.078308,920.450439 829.444702,920.539307 
	C829.979431,920.866821 830.140808,921.072388 830.152344,921.640381 
	C828.992004,923.002380 827.923706,923.978699 828.769409,925.816895 
	C829.251404,927.116638 829.497925,928.233276 829.889404,929.667236 
	C830.020874,930.431396 830.007263,930.878174 829.993164,931.660034 
	C827.756470,932.263489 826.091614,933.537842 826.122131,935.718933 
	C826.158875,938.356689 824.542053,939.022278 822.349060,939.901672 
	C818.434143,940.484863 817.442200,936.857361 814.700195,935.417114 
	C812.793335,937.878906 813.309631,941.843018 809.804321,943.682861 
	C808.154663,944.358887 806.578491,944.106995 805.118164,945.234497 
	C804.468994,945.892944 803.994446,946.282043 803.229248,946.800659 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M808.763550,943.340027 
	C813.336365,940.822937 811.963928,936.097595 814.053345,931.463989 
	C816.748047,934.486694 819.085938,937.109192 821.720642,939.896240 
	C821.371948,942.741333 819.826294,942.173462 818.445801,940.862366 
	C814.979187,937.569824 813.639771,939.489868 813.027527,943.004211 
	C812.687744,944.954407 811.733765,945.953308 809.380249,945.988892 
	C808.420227,945.308716 808.268799,944.537109 808.763550,943.340027 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M803.258362,947.234741 
	C803.165649,946.444031 803.338867,945.891052 803.750366,945.162964 
	C804.980835,944.383545 805.803162,944.828003 806.803955,945.708069 
	C808.295471,948.567810 810.599426,951.579773 804.393860,950.995483 
	C803.838196,949.819275 803.681213,948.645935 803.258362,947.234741 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M807.336609,946.002441 
	C806.195984,945.752991 805.387146,945.501038 804.283447,945.118469 
	C804.434387,942.286743 806.582886,943.193726 808.603699,942.996582 
	C808.997620,943.747009 808.997681,944.496521 808.996460,945.620544 
	C808.552856,945.996765 808.110596,945.998413 807.336609,946.002441 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M993.701782,454.931915 
	C995.085144,455.250519 996.175903,455.481110 997.599304,455.695740 
	C998.379272,456.559235 998.869324,456.873352 999.823120,456.160095 
	C1003.639587,453.306305 1005.302551,454.055878 1005.982788,458.811646 
	C1006.239258,460.604370 1006.621277,462.379181 1006.940369,464.565063 
	C1001.491272,464.409241 998.115112,467.524872 995.573364,471.732880 
	C998.592834,472.680115 1002.345520,469.522156 1005.776917,472.746582 
	C1005.960388,475.048370 1004.826538,476.012573 1002.446777,476.054565 
	C1000.363586,477.217163 1000.276245,479.258606 999.191162,481.144653 
	C998.133789,481.718750 997.291809,481.548553 996.482605,481.650391 
	C989.634888,482.511932 989.470825,482.538239 988.820190,475.295776 
	C988.127197,467.581207 987.054932,459.983643 984.106018,452.367798 
	C983.858643,451.260162 983.808777,450.504181 983.789429,449.361023 
	C984.345276,445.571198 982.559998,441.679840 986.227722,438.710815 
	C990.533630,441.129089 988.576111,445.157196 988.823364,448.529999 
	C988.966919,450.487427 988.170593,452.440460 990.855469,453.618469 
	C991.938171,454.042603 992.686035,454.418365 993.701782,454.931915 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M1008.117432,561.637878 
	C1007.840027,562.409363 1007.455078,562.887878 1007.028076,563.687744 
	C1003.936340,565.901062 1001.328735,565.976196 1000.018738,562.028931 
	C999.045532,559.096558 996.010071,558.240784 994.335693,556.014343 
	C992.954956,554.178284 991.216248,552.608704 990.621277,550.267639 
	C990.120544,548.297180 990.180969,546.621765 993.009521,546.078003 
	C993.847107,546.138000 994.296692,546.265991 994.990845,546.641113 
	C995.841003,547.200195 996.395874,547.831787 997.265869,546.752502 
	C998.332764,539.984375 997.368530,533.462769 997.118347,526.994385 
	C996.987122,523.599915 998.931519,520.217896 997.074585,516.524658 
	C996.735779,515.168213 996.738098,514.159119 996.873535,512.767578 
	C997.618286,510.706848 996.948486,508.877655 997.655762,506.836975 
	C1000.026062,504.917053 999.354187,502.843262 999.058472,500.665924 
	C998.660461,497.735504 998.194519,494.526215 1002.638489,493.773560 
	C1002.444824,496.376221 1002.081604,498.613434 1002.714172,501.215118 
	C1003.472290,504.333008 1004.645203,507.904053 1001.239929,510.735321 
	C999.889099,511.858490 999.829163,513.919006 1001.279785,515.183777 
	C1003.996765,517.552612 1002.386902,520.322083 1002.105713,522.822754 
	C1001.533997,527.907410 1000.122009,532.965027 1002.174011,537.988708 
	C1002.786194,539.487427 1003.778320,540.849060 1004.708923,542.195679 
	C1006.513428,544.806946 1006.281433,547.005554 1004.593750,549.841248 
	C1002.591431,553.205200 1003.052002,556.959167 1006.297913,560.086487 
	C1007.113770,560.533020 1007.561829,560.938965 1008.117432,561.637878 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M1005.985840,472.619812 
	C1002.250244,471.670013 998.549133,473.721039 994.754578,473.291229 
	C993.688904,473.170532 992.406555,474.112762 992.131165,472.278442 
	C991.966614,471.182343 992.423157,470.230652 993.618713,470.107941 
	C996.833130,469.777954 998.156006,468.137695 998.156128,464.858734 
	C998.156189,462.753815 999.981812,462.875824 1001.521057,463.352783 
	C1003.086792,463.838013 1004.620422,464.426727 1006.551392,464.969055 
	C1006.868225,465.114380 1006.837769,465.351349 1006.730103,465.394135 
	C1002.198669,467.194916 1002.052856,469.492432 1005.985840,472.619812 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M1002.975281,493.544189 
	C999.361633,495.298004 999.960266,498.052887 1000.423523,500.919952 
	C1000.773743,503.086823 1002.680969,505.844116 998.435852,506.988525 
	C996.529236,507.307373 996.265869,506.134521 996.456543,505.245850 
	C997.154236,501.993317 996.437378,498.660278 997.169250,495.425049 
	C997.862610,492.360291 997.967896,489.996582 995.310791,487.065735 
	C991.972351,483.383301 997.082581,483.157623 999.289551,481.526367 
	C999.734314,481.102814 999.917908,480.938965 1000.431763,480.939575 
	C1000.816772,481.531311 1000.871399,481.958557 1000.953735,482.707642 
	C1001.701233,483.746429 1001.844177,484.806885 1002.667114,485.806946 
	C1002.891174,488.399475 1002.940552,490.751495 1002.975281,493.544189 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M1009.032288,540.346252 
	C1011.383057,540.184753 1012.018555,541.664246 1011.964172,543.740662 
	C1011.908691,545.859741 1010.440125,545.969482 1008.507202,546.042603 
	C1007.551758,544.144470 1009.107605,542.577698 1009.032288,540.346252 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M1000.867249,480.737549 
	C1000.762207,481.104065 1000.286926,481.148621 1000.051025,481.184906 
	C999.815063,481.221191 999.341064,481.359375 999.154419,481.197815 
	C999.082520,479.070282 998.541504,476.790771 1001.704834,476.079803 
	C1001.712402,477.495514 1001.342346,478.933258 1000.867249,480.737549 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M1007.760254,537.158142 
	C1008.892090,537.546448 1009.004944,538.397095 1009.044312,539.606934 
	C1008.118835,539.985474 1007.032532,540.324585 1006.503845,539.253845 
	C1006.057556,538.350098 1006.878052,537.829285 1007.760254,537.158142 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M1002.863770,485.724121 
	C1001.476440,485.860626 1000.990479,484.981598 1000.991943,483.370056 
	C1002.049561,483.471893 1002.778320,484.186798 1002.863770,485.724121 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M885.388977,903.007446 
	C881.268616,903.670593 877.319641,904.071045 877.046936,909.611206 
	C876.210815,910.318481 875.425293,910.641846 874.321167,910.997070 
	C873.441895,909.269409 876.921997,907.849548 874.895386,906.422913 
	C872.864197,904.993042 870.570435,906.262512 868.421021,907.947754 
	C866.127014,906.466919 865.527405,904.425415 866.720947,902.302002 
	C867.812622,900.359619 869.976807,899.907898 871.988525,900.341553 
	C874.875244,900.963867 877.179810,900.769836 878.743713,897.866760 
	C879.296814,896.839966 880.490173,896.250671 881.642273,896.068787 
	C884.707031,895.585144 886.492798,894.265442 887.403198,890.938538 
	C888.373291,887.393860 893.081787,886.792114 894.666687,889.965210 
	C896.549500,893.734741 898.096191,892.169312 900.308044,890.651184 
	C904.343384,887.881409 905.811279,888.488403 907.281372,893.604126 
	C907.104614,895.622864 906.651001,897.139465 905.270752,898.698975 
	C904.366089,902.429749 901.311462,903.927063 899.188354,906.136719 
	C898.185364,907.180664 896.935974,907.342102 896.096375,905.522217 
	C893.284851,899.428040 893.251343,899.460205 887.657410,902.532776 
	C887.105652,902.835754 886.394653,902.848694 885.388977,903.007446 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M926.674133,855.768738 
	C925.303101,862.724487 923.759705,869.504211 919.056885,874.978394 
	C917.201904,877.137573 918.494812,877.808777 920.607727,877.992920 
	C921.880249,878.103882 923.429932,877.762085 923.824585,879.543091 
	C924.251587,881.469666 922.801025,882.384705 921.401428,882.741272 
	C917.329895,883.778442 916.840759,886.142578 918.746704,889.764893 
	C919.009338,890.446167 919.007629,890.890076 919.002930,891.666992 
	C916.338867,895.997864 912.303772,894.569519 908.310059,894.455688 
	C907.250793,893.380676 907.264771,892.269043 908.023560,891.612671 
	C911.334290,888.748596 912.128174,884.654907 913.624390,880.515259 
	C914.198303,878.594482 913.883850,877.012878 913.684875,875.085388 
	C913.629883,871.935059 913.512695,869.106140 916.491455,867.459229 
	C917.548889,866.874695 917.656677,866.039062 917.064453,865.000061 
	C915.691711,862.592041 916.407410,860.282043 917.733154,857.721558 
	C920.582275,855.969360 923.276001,854.925781 926.674133,855.768738 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M907.673584,893.772095 
	C911.216492,892.251770 915.234314,894.762268 918.646484,892.041504 
	C919.976501,895.268433 916.798340,895.881165 915.425537,897.670837 
	C913.130371,900.662964 911.950195,898.405396 910.202454,896.939453 
	C907.689392,894.831604 908.025024,899.990784 905.364441,898.995239 
	C905.312317,897.584717 905.617249,896.164612 905.961121,894.380432 
	C906.375793,893.629944 906.830261,893.476440 907.673584,893.772095 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M969.177551,736.700195 
	C964.963745,737.126587 961.521851,738.647217 958.353638,741.069092 
	C956.550476,742.447449 955.577393,743.829346 956.710266,746.070923 
	C960.076416,752.731018 959.501587,755.214600 953.282349,759.837891 
	C952.477112,760.118591 952.056213,760.010193 951.607422,759.281555 
	C951.465210,758.108337 951.828125,757.393311 952.169128,756.652893 
	C954.410767,751.784790 954.304016,751.510010 949.092651,748.733643 
	C948.099548,747.811279 947.728699,746.936096 947.553223,745.599121 
	C947.685791,744.769531 947.887817,744.338379 948.407959,743.688110 
	C951.351868,741.839905 952.534241,739.267578 953.349365,736.048584 
	C953.783447,735.364441 954.107788,735.036255 954.799194,734.613403 
	C961.337158,735.048218 962.000977,734.538452 962.788574,728.264526 
	C963.047363,726.530090 963.875977,725.440674 964.800293,724.034790 
	C966.031799,721.955139 963.890991,718.469116 968.200806,718.256042 
	C969.401184,718.580750 970.010132,719.231934 970.911011,720.034058 
	C971.444641,720.588745 971.685730,720.952515 971.908691,721.681519 
	C972.092041,723.980347 972.232422,725.953735 972.474670,728.334656 
	C972.887573,729.603943 972.987366,730.470398 972.413879,731.734009 
	C972.194275,732.121216 972.101196,732.106140 972.077087,732.065613 
	C971.251526,733.550293 969.421265,734.454712 969.177551,736.700195 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M971.179443,720.923584 
	C969.978210,720.927734 969.253845,720.569519 968.991455,719.255981 
	C968.528870,715.164795 966.823059,712.662109 963.387756,710.474243 
	C960.454529,708.606018 959.086609,704.755554 958.895386,700.610718 
	C959.003052,700.045959 959.068115,699.825500 959.291138,699.300537 
	C961.368164,696.963623 965.056091,697.318726 966.289429,693.807678 
	C967.280823,692.652405 968.274048,692.038391 969.530396,691.192749 
	C971.900940,690.002686 972.397034,688.097900 972.673218,686.059143 
	C973.037842,683.366943 973.774597,680.849731 976.390808,679.068604 
	C980.250549,681.988403 975.640869,684.726074 976.819153,687.791748 
	C977.081665,688.742065 977.076599,689.488953 977.120605,690.606567 
	C972.706665,693.761292 970.626770,698.996460 965.411194,701.361877 
	C961.914856,702.947693 964.223389,706.064758 966.683105,707.856873 
	C969.939636,710.229553 973.034424,712.665161 972.968872,717.623169 
	C972.916931,719.182556 972.167297,719.971191 971.179443,720.923584 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M971.019104,721.263672 
	C971.040283,719.954407 971.375244,718.947510 972.654053,718.151123 
	C974.060974,718.107605 975.179932,718.204529 976.550354,718.323181 
	C975.923828,720.678833 973.046997,719.703735 972.240723,721.810547 
	C971.625610,721.916260 971.359070,721.737061 971.019104,721.263672 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M972.535645,732.096313 
	C972.211548,731.264587 972.228821,730.407898 972.411316,729.146729 
	C973.496277,729.010498 974.639526,729.036194 974.762451,730.325439 
	C974.889404,731.656860 973.900879,731.913635 972.535645,732.096313 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M969.211548,736.840698 
	C968.814026,734.903992 969.090576,733.072266 971.715576,732.150452 
	C972.052979,732.025085 972.096069,732.481689 972.091309,732.710938 
	C972.185181,733.125732 972.283813,733.311279 972.185669,733.742920 
	C971.479980,734.482666 970.971130,734.976257 970.312866,735.687378 
	C969.927368,736.177856 969.691223,736.450867 969.211548,736.840698 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M972.161804,733.908936 
	C971.992859,733.813354 971.996887,733.637573 972.043701,733.201050 
	C972.236755,733.130066 972.407898,733.308411 972.522827,733.517639 
	C972.552063,733.570801 972.402283,733.722351 972.161804,733.908936 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M752.671631,969.746704 
	C757.355713,968.544373 760.138367,964.823425 763.764648,962.305908 
	C765.638367,961.005127 766.466064,960.201538 766.461060,963.255005 
	C766.457520,965.429382 768.367126,965.385132 769.086243,964.567505 
	C771.119446,962.255737 774.090210,960.580994 774.917725,957.377014 
	C775.930664,953.454956 777.462036,951.389404 781.665771,954.241882 
	C784.192200,955.956238 788.687012,953.748108 789.952576,958.632507 
	C788.424500,958.892334 787.026306,959.274719 785.985535,960.530029 
	C784.081238,962.826782 781.782471,963.693848 778.937927,962.463013 
	C777.337463,961.770447 775.694885,962.144287 774.831482,963.304993 
	C770.759644,968.779419 764.296448,971.417297 759.739502,976.412109 
	C757.204895,979.190186 753.131958,980.601379 749.673523,982.483948 
	C748.005615,983.391968 747.178284,981.119263 745.341797,981.036987 
	C744.137085,979.554382 746.123108,978.168091 744.715820,976.424255 
	C741.709656,972.699280 742.065979,972.008911 746.829956,970.583679 
	C747.789368,970.296631 748.634338,969.846985 749.867798,969.332397 
	C750.966248,969.089478 751.688782,969.167419 752.671631,969.746704 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M749.995483,969.540466 
	C749.484070,971.057373 748.693726,971.787354 747.429749,971.940369 
	C746.026917,972.110291 744.022644,971.653870 743.655518,973.414551 
	C743.159485,975.793274 745.780701,974.604004 746.742981,975.492493 
	C749.613647,978.143433 746.318054,978.914062 745.160095,980.692749 
	C744.446594,981.172424 743.896179,981.347290 743.172852,981.761108 
	C741.718384,981.892944 739.924255,982.129211 740.260864,980.139771 
	C740.589844,978.195251 740.755310,977.040283 738.198914,976.930115 
	C735.727844,976.823669 732.217041,975.871826 731.621094,978.537354 
	C730.347839,984.232117 727.635071,982.856140 724.273621,981.079407 
	C723.879517,980.871094 723.294373,981.024048 722.407410,981.013245 
	C722.017029,981.017029 721.981995,980.981506 721.964478,980.963623 
	C720.762390,978.375366 722.803833,978.273132 724.311462,978.093750 
	C726.065308,977.884949 727.373901,977.294922 728.226318,975.268066 
	C728.634338,974.789551 728.824829,974.616211 729.362671,974.254700 
	C733.094788,973.674927 735.987305,973.000427 738.253296,969.690552 
	C740.257385,966.763306 745.958923,966.985413 749.995483,969.540466 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M988.190552,584.707031 
	C988.334473,589.044067 987.793823,593.153015 987.826538,596.935120 
	C987.860535,600.854309 984.260620,605.456665 988.290771,609.585266 
	C988.958435,610.269287 987.552856,614.379639 986.057007,615.475403 
	C983.118958,617.627686 983.944824,620.076599 984.412231,622.527100 
	C984.926025,625.220947 986.198730,627.935120 981.703003,628.090027 
	C980.788269,628.121521 979.846619,628.885193 980.249939,629.653259 
	C981.629028,632.279602 979.097229,633.934082 978.882324,636.491455 
	C979.954834,638.257080 978.553101,639.056641 977.830505,640.346313 
	C976.719666,641.376587 975.918091,641.916504 976.636230,643.465210 
	C980.257202,651.274475 979.386597,655.243896 973.012695,660.478821 
	C972.406555,661.111328 971.988037,661.388062 971.142700,661.686523 
	C970.226990,661.800964 969.713623,661.707153 968.907776,661.250244 
	C967.516418,659.247070 967.697388,657.484192 968.767090,655.407959 
	C972.149414,652.925415 972.947327,650.474426 969.675781,647.266602 
	C969.361206,646.513550 969.309204,646.051819 969.410583,645.236389 
	C972.255554,640.023254 972.913513,634.507141 974.476746,628.923462 
	C974.768372,628.440979 974.886292,628.250793 975.200562,627.793091 
	C976.330078,626.373230 977.513489,625.388550 977.951294,623.532349 
	C978.425415,621.953064 978.748047,620.673706 978.823242,618.996887 
	C978.249084,615.708862 978.043274,613.097778 980.070496,610.241333 
	C981.639160,608.030945 980.378662,604.738342 980.637756,601.580627 
	C980.809814,597.459595 979.876465,593.631104 983.055786,590.296509 
	C984.412781,588.873291 982.642151,586.229004 982.991272,583.759033 
	C983.245544,582.456177 983.588440,581.547424 983.956543,580.261169 
	C984.327332,579.469666 984.640503,579.085999 985.355957,578.589478 
	C986.197693,578.324585 986.689514,578.364807 987.448608,578.797241 
	C987.796326,579.783447 987.865234,580.518494 987.983765,581.619507 
	C988.605957,582.706055 988.777222,583.498535 988.190552,584.707031 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M994.246826,546.703735 
	C991.866333,546.910339 991.053101,547.412598 991.760193,549.890137 
	C992.610535,552.869629 995.025452,554.424194 996.845703,556.456299 
	C998.710815,558.538513 1002.454529,558.842896 1001.767883,563.219116 
	C1001.569519,564.483215 1004.629395,563.725708 1006.580566,564.009521 
	C1007.112671,564.875610 1007.343018,565.669800 1008.643188,565.968750 
	C1008.488220,567.277954 1008.018555,568.557068 1007.483337,570.174316 
	C1007.686462,571.395935 1007.029663,571.831665 1006.224976,572.533203 
	C1003.098755,573.663208 1001.168030,572.377075 999.585876,570.202332 
	C998.620972,568.876038 997.838379,567.395203 996.490845,566.386108 
	C995.427917,565.590027 994.241516,564.668945 992.906677,565.087097 
	C991.147644,565.638062 991.894104,567.254700 991.978760,568.474670 
	C992.108459,570.343323 992.061707,572.110474 990.188599,573.640198 
	C989.924805,573.858704 990.001038,573.927307 990.015015,573.976624 
	C989.886597,574.179138 989.744263,574.332275 989.602173,574.799011 
	C989.303833,575.503723 989.005310,575.894775 988.534546,576.467407 
	C988.028992,577.544678 987.295654,577.823792 986.024719,577.897583 
	C983.661011,577.578247 983.039062,576.258911 983.098511,574.108398 
	C984.208069,571.411133 987.555054,569.489075 984.167236,566.254517 
	C983.431763,565.854126 983.051697,565.552063 982.501404,564.877441 
	C981.478027,562.041016 982.584351,560.250366 984.683167,559.187927 
	C988.682800,557.163208 989.438354,554.057251 988.294373,550.045959 
	C987.836792,548.441772 987.855835,546.754395 988.317993,545.127869 
	C988.872498,543.176025 989.502686,540.937195 992.111206,541.448242 
	C994.714050,541.958069 995.446594,543.897461 994.246826,546.703735 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M990.277954,573.921631 
	C990.567566,571.652100 991.423218,569.530823 990.269775,567.138855 
	C989.727234,566.013855 989.587585,564.379272 990.844910,563.479858 
	C991.974060,562.672058 993.447693,562.785645 994.634521,563.481995 
	C996.699646,564.693848 998.928772,565.574585 999.939575,568.244507 
	C1000.724854,570.318848 1002.096802,572.510681 1005.469971,571.936279 
	C1006.233398,574.522217 1004.695801,574.825867 1002.298950,574.379639 
	C1000.197754,573.717224 998.490295,573.250244 996.397278,572.764587 
	C995.568848,572.843750 995.120789,572.902954 994.328552,572.904541 
	C992.869995,573.251953 991.750549,573.618225 990.277954,573.921631 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M998.634888,587.040588 
	C999.398132,589.440308 1001.067505,589.156067 1002.743286,588.593445 
	C1003.881470,588.211487 1004.630798,588.427246 1005.195984,589.494141 
	C1005.694214,590.434631 1006.122009,591.800110 1005.131897,592.153320 
	C999.748718,594.074341 998.594788,600.504578 993.250977,602.874939 
	C992.316040,603.083191 991.835388,602.872009 991.315063,601.995972 
	C990.937744,599.939819 990.873962,598.281372 991.714111,596.301880 
	C991.890015,595.923828 991.904175,595.898010 991.892639,595.881104 
	C995.330383,596.704529 998.322205,597.202209 996.534607,591.808838 
	C995.971191,590.109009 996.556213,588.251831 998.634888,587.040588 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M1002.006226,574.515381 
	C1003.263367,573.916687 1004.815430,574.106262 1005.746399,572.261597 
	C1006.219421,571.543213 1006.592102,571.159607 1007.191284,570.644226 
	C1007.139587,575.701416 1006.199402,576.468750 1002.006226,574.515381 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M992.403870,592.970520 
	C991.741455,591.281372 991.421997,589.502930 991.074097,587.337524 
	C993.857849,588.283447 995.255493,590.022095 992.403870,592.970520 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M988.196289,585.127869 
	C987.914124,584.270691 987.908997,583.505554 987.968628,582.362915 
	C990.131104,582.181458 989.962463,583.776672 990.041870,585.593994 
	C989.524963,585.715759 988.999146,585.467773 988.196289,585.127869 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M985.794800,577.308655 
	C986.615906,576.920227 987.208008,576.837402 988.081238,576.701782 
	C988.758606,577.324585 988.796875,577.993286 988.025513,578.850220 
	C987.337097,579.038330 986.946716,579.028137 986.261963,579.012634 
	C985.590332,578.613098 985.456421,578.148743 985.794800,577.308655 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M994.134644,573.133423 
	C994.391479,572.193115 994.930176,571.941223 995.844666,572.553955 
	C995.658508,573.568604 995.155701,573.983337 994.134644,573.133423 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M989.765381,574.863037 
	C989.511719,574.870422 989.392761,574.633301 989.347473,574.382935 
	C989.333679,574.306519 989.522705,574.193420 989.823914,574.061523 
	C989.995483,574.221741 989.961914,574.417603 989.765381,574.863037 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M908.041138,893.648804 
	C907.542969,893.994934 907.102234,894.001282 906.330750,894.012085 
	C905.826843,892.625854 906.569031,890.812012 904.576172,890.204163 
	C902.871582,889.684143 901.968201,890.937744 900.843872,892.024536 
	C896.674255,896.055420 895.672058,895.860352 893.979736,890.847717 
	C893.509949,889.456299 892.724792,888.872437 891.435730,889.151367 
	C889.829407,889.498901 887.764038,889.906799 888.270447,892.149414 
	C889.251465,896.494324 886.617004,896.859070 883.501587,897.008606 
	C881.623596,897.098755 879.531860,897.405090 879.157104,899.572388 
	C878.513000,903.297546 876.558167,902.980042 873.743408,902.113586 
	C868.076416,900.368958 866.749634,901.657227 868.033447,907.639954 
	C866.544434,912.549500 863.830322,916.388062 860.996338,920.537476 
	C859.129517,921.400940 857.400757,919.546082 855.296753,920.900452 
	C854.989563,921.050476 854.503296,921.003052 854.259155,920.993347 
	C852.423401,918.844421 850.400269,917.039429 849.058350,914.330750 
	C849.016174,913.263733 849.032349,912.527527 849.057983,911.423218 
	C849.151306,910.261353 851.531982,909.018127 848.303589,908.857361 
	C847.183228,908.682251 846.387207,908.458740 845.300964,908.113281 
	C844.325378,907.201721 843.398438,906.794739 842.233032,906.200439 
	C841.994690,906.012756 841.501221,905.978333 841.254028,905.968628 
	C836.268677,904.683350 832.726746,900.689819 827.466675,899.793884 
	C826.486633,897.707947 826.545715,895.964233 827.751587,893.976562 
	C832.118530,892.830444 834.634033,889.829590 837.497803,886.666809 
	C838.315674,886.314148 838.816284,886.251526 839.701111,886.386841 
	C840.524597,886.715515 840.921082,887.020569 841.463501,887.705505 
	C840.911011,893.539978 845.207764,896.676575 847.662109,900.688293 
	C848.490356,902.042114 850.003113,902.100281 850.461365,901.027466 
	C852.353699,896.597412 856.021118,897.210999 859.615112,897.316223 
	C860.079834,897.329773 860.505432,896.919434 861.155518,896.380249 
	C864.444580,893.406799 867.297424,891.576904 871.342773,895.008728 
	C873.898315,897.176697 878.557983,894.159790 880.106689,889.524780 
	C880.435242,888.764709 880.693298,888.370300 881.259155,887.767151 
	C883.789978,885.521484 886.953735,886.876404 889.710205,885.329712 
	C890.493286,885.109192 890.951538,885.040283 891.761230,885.023010 
	C896.714417,886.830200 900.247070,885.463745 903.005249,881.102112 
	C903.441589,879.801636 903.173706,878.745117 903.422119,877.402100 
	C903.900269,876.282166 904.554993,875.692505 905.443115,874.866699 
	C905.907349,874.554565 906.108765,874.454224 906.638916,874.278687 
	C908.256836,874.147339 909.519714,874.627014 909.606140,875.900330 
	C909.826050,879.140076 912.120850,879.273682 914.692139,879.750061 
	C913.702637,884.941467 912.727173,890.062805 908.041138,893.648804 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M827.797058,900.216370 
	C833.867126,898.179810 836.684265,903.170959 840.847412,905.657043 
	C838.470032,909.490540 837.388000,914.511292 831.460083,914.992188 
	C829.986267,912.030457 829.867065,908.505188 826.554565,906.209167 
	C825.940125,904.980957 825.876038,904.008118 826.075073,902.638794 
	C826.420166,901.522034 826.851868,900.894775 827.797058,900.216370 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M826.529419,906.353882 
	C830.631836,907.532166 832.810486,909.890930 831.110474,914.674194 
	C831.066345,916.421448 831.029663,917.795898 830.990479,919.583984 
	C830.869263,920.209534 830.750488,920.421448 830.304199,920.792908 
	C828.556458,921.619202 827.136353,922.285950 825.377441,923.002258 
	C823.588928,921.062073 825.653564,920.131592 826.439636,918.451416 
	C828.584656,914.628357 826.262695,911.388184 826.009216,907.671997 
	C826.134766,907.087585 826.218384,906.861389 826.529419,906.353882 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M848.742798,914.206055 
	C851.183716,915.676697 853.699402,917.088135 854.009888,920.624451 
	C853.568970,920.990906 853.122864,920.998230 852.342407,920.998962 
	C851.335876,918.530273 847.159973,917.943665 848.742798,914.206055 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M848.091309,909.185974 
	C848.721313,906.335449 851.142212,906.290649 852.125732,907.598633 
	C853.467102,909.382507 851.171875,910.208557 849.407837,910.998047 
	C848.779480,910.525513 848.491516,909.995850 848.091309,909.185974 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M842.138794,906.300171 
	C843.135925,906.050903 844.390381,905.836609 844.949585,907.650513 
	C843.852600,908.006897 842.928772,907.567383 842.138794,906.300171 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M829.984680,921.214966 
	C830.098938,920.648071 830.292664,920.399231 830.772827,920.101746 
	C832.067688,920.899414 831.805542,921.518494 830.291138,921.968628 
	C829.999207,921.827637 829.996033,921.652588 829.984680,921.214966 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M969.766113,291.745911 
	C968.773926,295.245605 971.235107,297.159485 972.875122,299.721619 
	C972.691345,300.822510 972.350403,301.629883 971.924866,302.759888 
	C969.157104,300.726440 968.825134,297.288422 968.124207,294.167847 
	C967.718018,292.359375 967.322815,291.121857 965.263550,290.354218 
	C964.932373,287.652374 965.617371,286.291809 968.627319,287.896301 
	C969.132935,289.200134 969.322632,290.352264 969.766113,291.745911 
z"
    />
    <path
      fill="#10100F"
      opacity="1.000000"
      stroke="none"
      d="
M932.720520,889.170776 
	C932.480835,890.728271 931.760437,891.182068 930.670593,890.410950 
	C930.381653,890.206482 930.189331,889.452209 930.335815,889.118896 
	C930.892822,887.851746 931.758301,888.013245 932.720520,889.170776 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M984.212769,386.624634 
	C984.528809,387.651611 984.764221,388.474762 985.026917,389.621704 
	C981.442322,391.969330 981.023071,388.730713 979.675598,386.454315 
	C979.216187,385.530182 978.952637,384.902527 978.418335,384.053711 
	C977.605164,382.904846 976.746277,382.258362 975.721069,381.306030 
	C975.396484,380.855530 975.290894,380.656921 975.079590,380.135254 
	C974.509460,377.828674 975.050842,375.713806 974.018616,373.486328 
	C973.755737,372.983337 973.660645,372.776550 973.471436,372.240845 
	C973.334412,371.454041 973.379333,371.005798 973.742554,370.306396 
	C979.414734,371.673981 978.083557,377.639679 980.872009,381.047882 
	C981.886536,382.287964 981.911743,384.452118 983.667969,385.864807 
	C983.994629,386.004547 984.132141,386.420837 984.212769,386.624634 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M976.747131,678.717285 
	C975.314087,681.916321 973.543457,684.579163 973.896790,688.088745 
	C974.091431,690.021729 972.620605,691.311890 970.381165,691.972900 
	C968.224060,689.370728 967.281128,686.406921 966.702026,682.921753 
	C967.140808,677.755371 967.623352,672.977051 969.622559,668.138855 
	C972.622131,666.546631 973.221680,669.016479 974.621216,670.809937 
	C974.845764,671.114929 974.867493,671.138000 974.865845,671.157837 
	C975.251953,673.039795 974.290222,675.145630 976.199219,676.889954 
	C976.597046,677.448242 976.673706,677.907593 976.747131,678.717285 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M575.380371,1012.093811 
	C572.807922,1011.286194 571.584412,1009.373596 570.136475,1007.249390 
	C569.836548,1005.986023 570.438904,1005.515747 571.531921,1004.998901 
	C575.920044,1005.276672 579.528015,1005.189148 582.100098,1001.043030 
	C583.615540,998.599915 586.185547,998.596130 588.482056,1000.853943 
	C591.948181,1004.261658 596.039917,1001.157043 599.823669,1001.645447 
	C600.991943,1001.796204 600.868591,999.799866 600.924133,998.687683 
	C601.059265,995.978638 602.669861,994.784119 605.127869,994.728821 
	C611.725891,994.580444 617.757080,991.863403 623.976318,990.248352 
	C628.379089,989.104919 631.673950,989.433716 635.581177,992.478516 
	C635.800171,994.798462 634.667847,995.145569 633.158630,994.849854 
	C626.187134,993.483643 621.971069,995.140076 618.168518,1001.061462 
	C617.492676,1002.113892 616.948914,1003.195251 615.723206,1003.164795 
	C609.697021,1003.015564 604.048706,1006.138855 597.948425,1005.164368 
	C596.430481,1004.921814 594.998047,1005.054810 593.576416,1005.669922 
	C592.668762,1006.062683 591.404297,1006.843384 590.792175,1006.519531 
	C584.370850,1003.122253 581.295776,1008.359436 577.196472,1011.787720 
	C576.557312,1012.064026 576.128906,1012.065491 575.380371,1012.093811 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M642.763184,996.051636 
	C640.512878,995.516296 640.038818,993.960754 639.319824,992.119507 
	C639.584167,991.477905 639.786072,991.386902 640.319031,991.252136 
	C641.598694,991.237305 642.371521,991.656006 643.257568,992.556396 
	C643.787292,993.445679 643.898315,994.108154 643.772583,995.132446 
	C643.582336,995.659119 643.469177,995.854797 642.763184,996.051636 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M638.975281,992.060852 
	C638.199280,992.378845 637.432861,992.676086 636.359741,993.013123 
	C636.053040,993.052979 636.023743,993.023743 636.009399,993.009399 
	C635.189697,991.781189 634.988831,990.529724 636.466370,989.792542 
	C637.693176,989.180542 638.553162,989.986389 639.046265,991.552429 
	C639.000000,991.997559 638.984863,992.040100 638.975281,992.060852 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M642.152466,996.255798 
	C642.170349,996.052734 642.543335,996.061035 642.729675,996.053589 
	C643.042297,996.191040 643.168640,996.335938 643.090393,996.684204 
	C642.885864,996.887512 642.519836,996.877197 642.338501,996.871582 
	C642.157227,996.866028 642.134644,996.458862 642.152466,996.255798 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M728.770203,975.785156 
	C728.448120,978.470215 727.480713,980.336121 724.415466,979.285522 
	C723.394592,978.935608 722.513306,979.138428 722.016846,980.615479 
	C715.184021,982.245056 709.052734,985.740479 701.979675,986.269897 
	C699.710205,986.439697 696.736938,988.896484 695.670471,991.080811 
	C693.246155,996.045898 689.658142,994.778687 685.230835,993.809937 
	C683.893311,992.082092 684.141663,990.419983 683.564331,988.894592 
	C682.502625,986.089111 683.606445,984.253235 686.759644,983.234619 
	C687.881592,982.980408 688.637451,982.997253 689.752686,983.252930 
	C691.396301,984.178894 692.972229,985.116516 694.277832,984.397766 
	C698.772400,981.923218 704.242676,982.545105 708.571045,979.346985 
	C712.671875,976.317078 718.283386,976.626709 722.653748,973.659729 
	C724.271729,972.561279 726.658508,974.447021 728.770203,975.785156 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M666.380737,1005.972778 
	C665.535889,1004.955994 664.793152,1004.258972 663.318176,1004.031616 
	C663.284607,1001.130737 659.968811,1001.545898 659.047607,999.385742 
	C659.531860,996.228577 660.961182,993.780090 660.721558,990.544739 
	C660.459778,987.009399 663.959534,987.839783 666.716736,988.113098 
	C670.719055,991.279175 675.171997,988.989197 679.459717,990.172729 
	C681.203003,991.570923 682.788818,992.573059 684.695129,993.730225 
	C684.963379,993.960999 685.026062,994.026062 685.058350,994.059692 
	C683.261719,997.567383 680.794006,996.110474 678.428589,994.900208 
	C676.440369,993.882996 675.224426,993.490295 673.825500,996.189697 
	C671.690308,1000.309937 668.908813,996.210022 666.288696,996.128601 
	C665.528320,998.176270 666.388367,999.599915 667.913513,1001.213867 
	C669.536560,1002.931152 670.067078,1005.087402 666.380737,1005.972778 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M643.213501,997.053284 
	C642.885864,996.887512 642.914612,996.466675 642.915283,996.256409 
	C642.916016,996.046143 642.981873,995.993225 643.006348,995.958740 
	C643.611084,995.383057 644.101624,994.740112 645.192505,994.198853 
	C647.542725,993.600952 649.390320,993.762146 651.237793,995.496033 
	C652.232239,997.556396 651.962463,999.088440 650.292480,1000.698242 
	C650.002441,1000.997559 649.987793,1001.011841 649.978027,1001.021179 
	C647.593506,1000.151917 646.250610,997.533386 643.213501,997.053284 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M667.229248,988.755554 
	C663.948730,989.025269 659.879822,987.704224 662.773926,993.601807 
	C663.737610,995.565369 660.833130,997.056213 659.286865,998.872253 
	C657.558960,998.742981 656.135620,998.436523 654.347778,998.096008 
	C653.886230,997.853455 653.789124,997.644897 653.762939,997.100098 
	C655.148926,995.262817 655.143066,993.936523 653.376831,992.762878 
	C652.212891,991.989441 650.612427,991.608093 650.573059,989.452148 
	C651.040527,988.198242 651.718201,987.508057 652.716553,986.616516 
	C653.688599,986.066406 654.402588,985.896729 655.503540,985.817261 
	C656.333435,985.855957 656.795105,985.941956 657.561768,986.194702 
	C660.897644,987.063782 663.430054,983.502930 666.869263,985.523193 
	C667.302429,985.921082 667.438599,986.121765 667.670593,986.678711 
	C667.782837,987.538635 667.684692,988.020508 667.229248,988.755554 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M654.089844,996.656921 
	C653.982117,997.193665 653.981750,997.367004 653.982300,997.801147 
	C653.245117,999.440125 652.768738,1001.112488 650.348206,1000.992920 
	C650.323914,999.614014 650.645325,998.230469 650.972168,996.487915 
	C652.161926,994.009705 653.211304,994.532166 654.089844,996.656921 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M405.301422,1004.977600 
	C405.142181,1003.570312 403.643219,1002.374023 404.525787,1000.564453 
	C406.406464,1000.476440 406.388733,1002.177307 406.966980,1003.645874 
	C406.535645,1004.309753 406.066803,1004.631226 405.301422,1004.977600 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M396.406738,988.375854 
	C400.577850,990.191040 404.217194,991.387878 408.724701,991.607666 
	C412.644501,991.798706 417.184723,993.304932 421.486786,994.110596 
	C423.272644,994.445007 424.893677,994.696472 426.803650,994.380676 
	C429.222321,993.980835 431.450897,994.914551 433.203003,997.210388 
	C438.081207,1003.602600 439.633026,1003.772522 446.363861,1000.169861 
	C449.598938,998.438232 452.735016,997.307129 453.233673,1002.973022 
	C453.439575,1005.312317 455.268555,1006.598816 457.553192,1005.807434 
	C460.490326,1004.790039 462.258148,1006.088684 463.970398,1008.455566 
	C463.562836,1010.517883 465.010498,1013.839355 462.290497,1013.654419 
	C458.810150,1013.417725 454.299194,1014.199463 452.297791,1009.818848 
	C451.689880,1008.488281 451.342316,1007.071411 450.521393,1005.776855 
	C449.065979,1003.481323 447.923737,1002.051941 445.227356,1004.626892 
	C443.237305,1006.527405 440.573059,1006.348450 437.744202,1005.897888 
	C432.012848,1004.985107 426.274078,1003.999695 420.406921,1003.896057 
	C417.146484,1003.838440 413.055328,1004.701660 412.315979,999.092957 
	C412.105957,997.499817 408.231415,997.071777 406.449524,995.304077 
	C403.761993,992.637878 402.684998,996.606445 400.017944,996.930664 
	C398.165771,996.434509 396.985596,996.076782 395.560822,997.220581 
	C395.061310,997.432129 394.851044,997.491211 394.313599,997.562744 
	C392.313873,997.408691 390.739746,996.394409 388.659241,997.095093 
	C383.551300,999.052307 378.247406,999.321777 373.355194,1001.983154 
	C372.568451,1002.029785 372.134399,1002.039307 371.376312,1002.072021 
	C367.982788,1001.744141 365.432709,998.350281 361.660248,1001.081726 
	C361.170258,997.275085 363.932709,994.598389 363.211792,991.149414 
	C362.416870,990.255432 362.212494,989.502319 362.553528,988.293396 
	C364.119507,987.002869 365.217987,987.577759 366.449432,988.903687 
	C367.576172,991.481140 368.268433,993.768127 366.683716,996.208862 
	C368.875214,996.252625 370.607574,996.689880 372.731628,996.500977 
	C377.970612,993.733704 383.766815,992.851074 388.083038,988.942810 
	C389.208069,987.924133 390.778046,987.732666 392.673340,987.650635 
	C394.100586,987.597229 395.117249,987.734863 396.406738,988.375854 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M393.675262,997.000183 
	C394.000000,997.000000 394.500000,997.000000 394.750000,997.000000 
	C396.326111,996.081421 397.719849,995.788696 399.614746,996.770386 
	C400.002777,996.997375 400.000000,997.000000 400.001831,996.997803 
	C400.814026,1001.022705 395.279083,999.149231 395.059265,1002.625244 
	C395.025085,1004.168091 394.233398,1004.851501 393.214478,1005.744873 
	C391.926239,1005.966187 390.864807,1005.977417 389.405365,1005.981812 
	C388.602936,1005.553223 388.155975,1005.197571 387.285858,1005.029297 
	C386.553955,1005.008301 386.110321,1005.009216 385.333923,1005.006287 
	C384.201233,1004.995361 383.401215,1004.996277 382.301208,1004.996704 
	C378.883087,1005.080994 375.854889,1004.832703 373.176453,1002.336426 
	C374.384033,999.005859 377.303375,998.667419 380.024872,997.993225 
	C382.750000,997.317932 385.479034,996.658569 388.602051,995.976685 
	C390.447540,996.312317 391.749451,997.282837 393.675262,997.000183 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M393.324463,1005.965515 
	C393.023071,1004.819092 393.468201,1003.905884 394.726929,1003.134033 
	C395.012268,1002.988342 395.503723,1002.998230 395.749420,1003.004089 
	C396.376404,1003.395935 396.525543,1003.856995 396.223083,1004.705139 
	C395.445740,1005.881104 394.577850,1005.988037 393.324463,1005.965515 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M396.334198,1005.018677 
	C396.001770,1004.570862 395.999847,1004.124695 395.996521,1003.344238 
	C396.902374,1003.088013 397.682800,1003.343567 397.947113,1004.697144 
	C397.545929,1005.018555 397.105316,1005.019409 396.334198,1005.018677 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M382.188782,1005.240479 
	C382.719025,1003.982605 383.592590,1003.815430 384.813354,1004.759033 
	C384.282135,1006.013184 383.407837,1006.177124 382.188782,1005.240479 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M387.173126,1005.244385 
	C387.885742,1003.956726 388.512451,1004.180969 388.970795,1005.689697 
	C388.454437,1005.810486 387.901520,1005.645935 387.173126,1005.244385 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M362.766357,987.836609 
	C363.020935,988.809753 363.003540,989.513611 362.977783,990.567139 
	C362.118774,990.838562 361.268158,990.760376 360.233765,990.852295 
	C357.580139,991.437439 355.410309,994.100525 352.240692,991.947205 
	C351.101776,988.405762 354.069916,989.105164 356.130615,988.253296 
	C357.974060,987.724670 359.482300,987.483032 361.384094,987.316406 
	C362.009155,987.409363 362.251770,987.488342 362.766357,987.836609 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M689.789307,21.278988 
	C690.479736,21.053812 690.913452,21.035646 691.672485,21.005947 
	C696.433716,22.299889 693.107605,25.148903 693.140015,27.648993 
	C692.915649,28.203909 692.804626,28.417356 692.453308,28.896935 
	C691.990051,29.241573 691.767090,29.320080 691.195435,29.354036 
	C686.981445,26.134911 684.508667,29.700901 681.320068,31.424747 
	C679.136658,32.550499 677.240784,32.939430 675.047485,31.429546 
	C674.292480,30.548855 674.067566,29.833454 674.005127,28.690273 
	C674.070129,28.080788 674.125061,27.843460 674.335449,27.281412 
	C676.339478,25.252697 679.302002,24.043606 677.249634,20.283039 
	C677.209656,19.459400 677.321106,19.005543 677.768372,18.319305 
	C679.765930,17.749451 681.511230,17.431458 683.632446,17.049290 
	C684.317749,17.118263 684.577698,17.316284 684.924744,17.785828 
	C684.403809,19.621479 682.818848,21.546858 686.643555,21.841301 
	C687.871887,21.960701 688.743469,22.030098 689.789307,21.278988 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M665.769165,21.220100 
	C668.531189,19.831406 668.684326,16.891872 670.758667,15.051044 
	C672.151306,14.992050 673.075256,15.427703 673.931763,16.578430 
	C674.396423,17.255527 674.549316,17.716864 674.491455,18.579102 
	C672.892517,20.558025 670.689575,21.364849 669.066467,23.427841 
	C667.958496,23.873354 667.188721,23.861158 666.096558,23.351093 
	C665.510986,22.623589 665.437317,22.104309 665.769165,21.220100 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M674.004272,19.408558 
	C674.015686,18.515228 674.026794,18.037880 674.072327,17.201145 
	C674.535828,16.873560 674.965027,16.905363 675.717285,16.955299 
	C676.585571,17.667072 676.836670,18.435093 676.437622,19.687656 
	C675.434692,20.568230 674.682739,20.548399 674.004272,19.408558 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M676.365967,20.078533 
	C676.066101,19.106829 676.044128,18.396210 676.031250,17.329510 
	C676.589661,17.170860 677.138916,17.368294 677.854492,17.816582 
	C678.010925,18.479584 678.001099,18.891731 677.992554,19.613686 
	C677.633301,20.352293 677.183350,20.491081 676.365967,20.078533 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M612.490173,17.152279 
	C613.296631,18.859331 614.598022,18.995813 615.988464,18.986687 
	C618.878113,18.967714 621.199158,20.262566 623.775757,21.514202 
	C628.336792,23.729834 633.357239,23.848783 637.947876,20.335155 
	C638.761719,19.955738 639.520935,19.933792 640.659912,19.926910 
	C642.295227,21.596916 643.034912,23.772879 645.696533,24.500202 
	C646.603027,25.825132 645.826660,26.513390 644.935791,27.506310 
	C644.256226,28.073069 643.830994,28.345160 643.033936,28.729618 
	C640.988708,29.410124 639.255371,29.370918 637.157837,29.156559 
	C635.886475,28.959669 634.972473,28.786077 633.690186,28.986416 
	C632.386536,29.157648 631.440735,29.110199 630.166809,28.746670 
	C628.045166,27.177713 625.749573,27.570391 623.401245,26.807163 
	C621.849121,25.862928 620.541077,25.254862 618.773987,24.744095 
	C610.832275,21.311903 602.730835,20.154232 594.625305,19.253717 
	C591.457520,18.901781 588.488525,18.338259 585.367920,16.654598 
	C584.953003,16.154875 584.838440,15.915484 584.851196,15.298950 
	C585.401367,14.913148 585.824158,14.904491 586.563599,14.878981 
	C587.756470,14.036277 588.407776,12.966817 589.722961,12.172739 
	C589.911438,11.933017 589.951904,11.949698 589.956787,11.928397 
	C590.383667,11.629567 590.983948,11.295383 591.331055,11.539762 
	C596.425537,15.126280 602.928833,14.276969 608.643616,17.029428 
	C610.030334,17.095007 611.067566,17.103987 612.490173,17.152279 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M646.289856,25.231556 
	C642.920288,25.273529 640.320251,24.977343 641.018555,20.365181 
	C641.039612,19.941975 641.467224,19.840679 641.685974,19.820303 
	C642.253357,19.585238 642.625366,19.427591 643.321350,19.341499 
	C644.175598,19.410311 644.727478,19.435677 645.433777,18.884720 
	C646.120056,18.306232 646.657166,18.059942 647.528198,17.806854 
	C648.407898,17.501013 649.011719,17.482782 649.915894,17.681673 
	C650.595886,17.884872 650.991150,17.964102 651.697754,18.005959 
	C652.888733,17.630823 653.829895,17.335943 654.657654,16.848459 
	C660.256409,13.551440 661.710876,14.057399 664.022034,20.525774 
	C662.947205,22.312248 661.267395,22.359190 660.263245,21.303839 
	C657.273682,18.161919 655.157410,20.269409 652.627686,22.385515 
	C650.535400,23.779716 649.120972,25.771015 646.289856,25.231556 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M648.171875,17.745213 
	C647.539490,18.334812 647.076538,18.655275 646.317993,18.998102 
	C645.597717,19.425320 645.192749,19.859200 644.293640,20.003273 
	C643.531006,19.985872 643.063171,19.967154 642.250061,19.874180 
	C642.775330,17.156170 645.011414,17.075218 647.614014,16.951679 
	C648.112061,17.119333 648.226685,17.297705 648.171875,17.745213 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M648.254150,18.117857 
	C648.004395,17.835659 648.006348,17.656982 648.002930,17.209633 
	C648.127563,15.350868 647.934021,13.414454 650.802368,14.781334 
	C651.017639,15.479237 651.016174,15.918310 651.014465,16.687082 
	C650.982300,17.609253 650.783936,18.135431 650.173950,18.773773 
	C649.473938,18.716358 648.989868,18.468870 648.254150,18.117857 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M652.054443,22.118061 
	C652.783630,19.054052 655.685547,18.851637 657.603638,17.426882 
	C658.779968,16.553017 659.788574,17.507252 659.983398,18.909821 
	C660.268372,20.962570 661.711487,21.048044 663.689148,20.931641 
	C664.512329,20.899458 664.934204,20.907677 665.672852,20.898689 
	C666.012024,21.323967 666.034424,21.766451 666.069885,22.539965 
	C665.503845,24.715223 664.291748,25.931726 662.153442,26.534367 
	C661.580505,26.423149 661.329651,26.275158 661.098206,26.096289 
	C660.873108,25.922325 660.942566,25.995319 661.429260,26.596691 
	C661.603394,28.384338 660.750671,29.397488 659.391907,30.492599 
	C658.875427,30.781328 658.657776,30.875349 658.098450,31.062397 
	C652.239807,30.926924 652.156494,30.888535 653.332031,25.362989 
	C653.628296,23.970255 652.982483,23.248436 652.054443,22.118061 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M650.258850,18.955215 
	C650.146362,18.422255 650.334839,17.880661 650.768799,17.177925 
	C651.326599,17.129072 651.582275,17.318113 651.890625,17.799364 
	C651.519958,18.325409 651.039856,18.635994 650.258850,18.955215 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M347.812622,16.631584 
	C346.122467,19.412163 343.392151,18.912615 340.427856,19.022575 
	C342.048279,16.694347 343.697357,13.288136 347.812622,16.631584 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M366.869080,12.975306 
	C364.495941,18.481201 359.868896,19.781507 354.214050,19.184690 
	C354.550110,12.479483 359.752594,9.958707 366.869080,12.975306 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M608.864624,16.750753 
	C608.736450,17.290771 608.411377,17.721460 608.232971,17.667501 
	C602.345825,15.887146 595.518677,17.044483 590.304932,11.994474 
	C591.303833,9.686954 593.002747,9.464596 594.749329,11.303737 
	C596.601990,13.254517 598.980408,13.567930 601.090759,12.717474 
	C605.264404,11.035580 607.238098,13.023189 608.864624,16.750753 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M573.588257,22.172733 
	C573.780396,21.401623 573.831848,21.000109 573.896179,20.295319 
	C574.224304,18.754017 573.006470,17.645300 573.830811,16.170469 
	C575.567383,15.872077 577.141479,16.349089 578.796875,15.148097 
	C579.472717,14.878715 579.911438,14.909733 580.680481,14.946904 
	C581.173645,15.125089 581.336487,15.297122 581.637451,15.797034 
	C582.084229,18.229088 582.437073,20.333883 581.506226,22.732525 
	C580.544678,23.984407 579.427063,24.495291 578.165222,25.414494 
	C577.340027,25.720617 576.839111,25.765635 575.951782,25.626865 
	C574.306030,24.933716 573.356812,24.107643 573.588257,22.172733 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M581.347534,16.404421 
	C580.991211,15.931602 580.995239,15.736059 581.005005,15.246786 
	C582.039551,13.754346 583.256226,13.862885 584.759888,14.683709 
	C584.996155,15.114259 585.013733,15.306713 585.059082,15.787712 
	C584.094238,17.024008 582.945557,17.101032 581.347534,16.404421 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M589.555603,11.952177 
	C590.066040,13.670192 588.691223,14.093238 587.206299,14.781982 
	C586.976624,13.355436 587.248352,11.989545 589.555603,11.952177 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M574.010376,15.749079 
	C573.708008,17.236601 575.283203,18.316282 574.174133,19.819347 
	C572.530457,16.437574 568.718323,16.515118 566.038940,14.425009 
	C568.541870,12.918088 570.969604,13.051470 573.513916,15.049079 
	C573.851868,15.128408 573.963867,15.540617 574.010376,15.749079 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M403.182312,29.595459 
	C403.703613,29.498388 403.927521,29.452887 404.332825,29.477921 
	C405.039703,29.426277 405.542206,29.256422 406.388641,28.853607 
	C410.779572,27.342274 415.222412,28.219700 419.514038,26.221317 
	C424.087280,24.842348 428.780487,26.682285 433.081665,23.807783 
	C433.864960,23.328115 434.349579,23.129677 435.241638,22.902292 
	C436.672577,22.683416 437.709656,22.738281 439.100800,23.094891 
	C439.676270,23.354511 439.888947,23.490307 440.320801,23.929745 
	C441.323578,27.648228 439.004608,28.610834 436.461609,28.961220 
	C434.321655,29.256071 432.279205,29.474001 430.392944,31.315048 
	C429.632599,31.683186 429.166565,31.802797 428.326111,31.864460 
	C423.216370,30.593288 418.663635,32.123779 414.486877,33.765938 
	C410.668854,35.267040 405.838745,34.119095 402.903351,38.300941 
	C401.953461,39.654129 399.776215,38.710876 398.302917,37.868137 
	C395.296326,36.148350 393.633270,36.922405 393.303162,40.416298 
	C393.080872,42.768642 392.221924,44.925426 391.020752,46.920124 
	C390.137512,48.386833 388.987946,49.168156 387.516632,47.450417 
	C384.468445,43.891685 384.460876,43.898159 381.177612,48.339722 
	C374.857513,53.805943 369.690521,60.106972 362.485809,63.998066 
	C361.033813,64.782257 359.775726,65.994629 358.040283,66.017342 
	C355.808441,66.046539 354.361786,67.044350 353.283417,68.974411 
	C351.233643,72.643105 346.852356,72.004799 343.511780,74.050331 
	C339.812592,75.417984 341.108978,78.864510 339.724487,81.432831 
	C339.221405,82.128365 338.835571,82.431732 338.034882,82.789276 
	C333.251953,83.260216 333.426147,79.117973 331.499512,76.504684 
	C330.639343,75.043716 329.830566,74.050858 328.907043,73.099518 
	C324.728638,68.795242 325.593536,65.379013 331.308014,63.312313 
	C332.841583,62.757683 334.494446,62.570961 336.275024,61.611847 
	C338.334351,60.656898 340.278473,60.417328 342.221802,59.206192 
	C344.097931,58.571606 344.209534,57.570042 344.164886,56.014919 
	C344.016205,50.835514 345.439972,49.483902 350.605103,49.912067 
	C353.542480,50.155567 356.153137,50.578247 355.304321,46.197701 
	C355.110199,45.195854 355.416290,44.024246 356.486542,43.700375 
	C362.955109,41.742947 368.217010,35.899765 374.851044,35.851280 
	C382.204193,35.797539 387.179901,30.588490 394.134583,29.493423 
	C394.907074,29.274351 395.360809,29.219332 396.151001,29.252823 
	C397.036652,29.377575 397.606201,29.708305 398.242432,28.929720 
	C398.439209,28.486320 398.520508,28.307068 398.803986,27.908367 
	C399.198486,27.635305 399.390869,27.581694 399.842407,27.678200 
	C400.523712,27.868563 400.945831,27.908808 401.685059,27.972637 
	C402.445160,28.446671 402.459503,29.130777 403.182312,29.595459 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M326.655029,34.103134 
	C327.741943,34.043598 328.484070,34.051514 329.597168,34.068386 
	C331.999176,35.347858 332.270538,32.939465 333.735046,32.285187 
	C334.450684,32.148949 334.874420,32.142757 335.615692,32.151894 
	C338.728699,33.453995 338.654510,30.825232 339.785217,29.273031 
	C343.853271,29.090673 347.205566,31.558159 351.216064,31.037546 
	C354.790405,30.573547 356.918060,29.875084 353.559509,26.226255 
	C351.742157,24.251837 352.016327,23.015030 354.871979,22.885511 
	C359.386749,22.680744 364.023621,21.651382 368.279083,23.923864 
	C370.326172,25.017036 371.211395,24.654430 371.937958,22.542391 
	C373.470093,18.088755 375.259918,17.778067 379.064484,21.537762 
	C378.406952,23.084127 376.412811,23.322323 376.594238,25.563133 
	C375.777100,27.241280 376.036743,28.922222 374.871918,30.432907 
	C371.456238,30.843763 368.678589,31.030087 365.904846,33.544006 
	C363.325439,35.881851 358.923065,33.663460 355.189209,35.072666 
	C354.699249,35.385063 354.494385,35.496803 353.961731,35.733356 
	C350.188843,36.334415 346.699493,35.066868 343.297699,37.797119 
	C340.854614,39.757942 338.001984,40.562870 338.407928,44.563515 
	C338.574829,46.208763 337.246033,47.711834 335.230957,47.680729 
	C333.235504,47.649933 331.705658,48.328999 330.357727,50.247421 
	C329.687836,50.758343 329.254486,50.971237 328.447296,51.235390 
	C324.269836,52.067738 321.334229,53.351768 321.569092,58.245602 
	C321.710358,61.188881 319.215393,61.333935 316.563751,60.735481 
	C314.276733,58.377071 315.662994,56.177177 316.370636,54.057869 
	C316.785492,52.815487 317.817932,51.729408 316.274017,50.219292 
	C315.248016,48.948380 313.784058,48.339729 314.019501,46.340530 
	C314.171570,44.323914 314.869019,42.970791 316.670410,41.990128 
	C317.719391,41.895271 318.438995,41.886837 319.518127,41.862953 
	C322.102234,39.654320 325.023102,40.601200 329.003326,40.674599 
	C325.728729,38.465927 322.240631,37.154785 326.655029,34.103134 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M278.063232,50.057617 
	C281.922546,49.383144 286.620239,51.016739 288.852386,45.921658 
	C289.255157,45.002270 290.526947,45.001930 291.533813,45.317860 
	C294.481110,46.242661 297.037140,46.531872 299.530670,43.746265 
	C300.970703,42.137577 302.792511,43.662865 303.933594,45.656616 
	C303.973633,46.717201 303.875824,47.416283 303.223083,48.284828 
	C299.752472,50.324768 295.134552,48.567589 292.447327,52.658318 
	C289.860565,52.965954 288.095673,53.858898 286.964722,55.978329 
	C285.969666,57.843170 283.855621,58.822990 282.498322,57.322056 
	C279.495667,54.001644 278.539520,56.975945 276.953644,58.567154 
	C275.748108,59.776703 275.329285,61.390728 274.312622,63.097771 
	C271.054474,64.702995 269.250671,62.572544 267.003143,60.699997 
	C266.499573,59.949226 266.365906,59.463139 266.424377,58.576920 
	C269.450134,54.620499 273.772552,52.988552 277.788330,50.299599 
	C278.058411,50.070377 278.054321,50.053947 278.063232,50.057617 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M436.208832,23.722368 
	C435.541199,23.974487 435.121796,24.002844 434.387573,24.049421 
	C429.726044,26.144974 424.941010,25.538164 420.008667,26.448509 
	C417.562073,27.421631 415.428650,27.621433 412.833008,27.089903 
	C411.348633,26.628639 410.435059,26.026684 409.573792,24.726299 
	C409.288086,23.797338 409.331696,23.257059 409.780212,22.403017 
	C410.772583,22.053511 411.515686,22.030462 412.630615,22.009525 
	C413.894989,22.076696 414.814697,22.265614 415.748627,21.214142 
	C416.457916,20.661743 416.934845,20.355370 417.708313,20.033749 
	C420.027649,20.180172 421.897125,19.740488 423.663574,18.719555 
	C425.739197,17.519949 427.850525,16.983702 430.199799,18.189228 
	C433.056824,19.655333 435.010803,16.832869 437.731201,16.194952 
	C438.539856,15.103421 439.400909,15.013313 440.640381,15.056026 
	C441.318207,15.422949 441.596771,15.821679 441.862305,16.634064 
	C441.959137,17.478006 441.919556,17.954655 441.647675,18.744461 
	C440.006439,20.754015 437.972900,21.831594 436.208832,23.722368 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M302.952332,48.329926 
	C303.172821,47.448521 303.346863,46.896400 303.761108,46.172058 
	C308.124664,51.254635 308.118774,51.289940 314.826080,52.409073 
	C316.013977,52.607269 315.848114,51.419540 316.026367,50.318779 
	C317.854950,50.053757 319.870941,50.413403 318.383514,52.740120 
	C317.073364,54.789433 317.121460,57.146843 316.008850,59.552101 
	C314.239136,63.254852 310.904938,62.546738 307.680542,63.050076 
	C302.469391,61.507793 305.126984,57.486156 304.328491,54.252922 
	C303.972870,53.258606 303.777161,52.588066 303.412964,51.617035 
	C303.010406,50.442238 302.819733,49.570988 302.952332,48.329926 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M400.004028,27.489845 
	C399.944031,27.898066 399.786407,27.967813 399.374786,28.085957 
	C398.338715,28.741947 397.467926,29.060287 396.113007,28.924372 
	C395.530212,28.852295 395.299713,28.804363 394.740631,28.630981 
	C391.036438,26.295197 390.158356,29.640882 388.165039,31.383568 
	C387.156952,31.885309 386.425690,31.937042 385.314209,31.702299 
	C384.503021,31.346703 384.109009,31.035942 383.559326,30.345615 
	C382.693359,27.466209 381.718994,25.010618 380.814392,22.245527 
	C387.048920,24.466137 395.017242,20.241892 400.004028,27.489845 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M326.774170,33.761314 
	C326.953217,35.377682 322.823944,37.217503 327.488403,37.989189 
	C328.788696,38.204311 331.145569,37.544144 330.563049,39.810688 
	C330.194092,41.246296 328.209015,42.467384 326.292664,42.128738 
	C324.386749,41.791927 322.555206,40.921242 320.243042,41.864128 
	C320.589966,39.957367 322.004059,38.758202 321.052185,35.869438 
	C320.198578,33.278976 324.200348,33.411327 326.774170,33.761314 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M380.654846,21.908117 
	C382.864838,23.953253 384.826599,25.973993 384.008057,29.568211 
	C383.074585,31.150925 381.788513,31.205008 380.074371,30.995136 
	C378.717804,30.578638 378.021545,29.855255 377.052948,28.900452 
	C376.349823,28.063066 376.069672,27.413897 375.989075,26.333401 
	C374.090454,23.056883 374.383759,21.430759 378.758118,21.939491 
	C379.554657,21.907084 379.954376,21.906462 380.654846,21.908117 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M437.554657,15.956243 
	C435.463898,18.805742 433.192413,22.326511 428.666992,18.874792 
	C427.001617,17.604555 425.545959,18.793711 424.059296,19.461819 
	C422.291382,20.256315 420.631805,21.676069 418.245087,20.281200 
	C423.895752,16.359596 430.572754,16.417608 437.554657,15.956243 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M440.734619,19.296959 
	C441.014709,18.598665 441.040985,18.206247 441.062256,17.518871 
	C441.346558,16.723093 441.779785,16.491436 442.713135,16.701693 
	C443.069275,16.874449 443.525665,16.958931 443.751709,17.011572 
	C446.448944,18.486414 449.656860,18.200945 452.043274,20.641384 
	C452.454285,21.023123 452.601929,21.193680 452.883759,21.674881 
	C453.278442,22.761496 453.860352,23.497070 453.219177,24.607679 
	C452.815643,25.230650 452.425232,25.479181 451.577850,25.537613 
	C448.767334,23.696632 446.324860,22.275160 443.332764,24.623247 
	C442.585114,25.209938 441.449188,25.010849 440.276367,24.240231 
	C440.012054,24.011774 439.989319,23.988768 439.978210,23.977047 
	C438.783417,22.351601 439.514618,20.963379 440.734619,19.296959 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M453.390808,21.986021 
	C453.017914,21.985525 452.537659,21.958729 452.297607,21.944252 
	C451.243744,20.132988 449.132599,18.944328 449.768646,16.259830 
	C451.165436,14.549836 452.744476,15.128366 454.576721,15.120833 
	C457.054810,16.801121 457.774811,18.798878 456.264648,21.647757 
	C455.256195,21.992872 454.509949,21.989695 453.390808,21.986021 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M449.607452,15.961510 
	C450.665833,17.702866 451.340546,19.458296 452.036377,21.571751 
	C449.408661,20.537708 446.759827,19.145638 444.044373,17.408890 
	C445.726440,16.701338 447.475128,16.338461 449.607452,15.961510 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M456.251038,21.996147 
	C456.072754,19.906279 456.048615,17.830610 455.024231,15.524816 
	C456.332092,15.089040 457.707153,15.022686 459.489868,14.930708 
	C459.698059,15.725250 459.498627,16.545416 459.174225,17.663269 
	C459.990906,19.695251 459.994141,21.067785 457.332642,21.765575 
	C456.996735,21.988644 456.499603,21.996246 456.251038,21.996147 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M409.700073,23.798569 
	C410.562836,24.530672 411.129150,25.027908 411.846558,25.767967 
	C411.844025,28.917749 408.807434,28.106255 407.222778,29.734743 
	C406.405426,29.908197 405.852081,29.855001 404.901367,29.649820 
	C404.884003,28.514305 405.264038,27.530777 405.800385,26.259090 
	C406.894379,24.865942 407.438232,23.197054 409.700073,23.798569 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M339.691895,29.059330 
	C339.336975,30.917545 341.742950,35.951481 336.038391,32.503738 
	C337.076141,31.141537 338.219025,30.115856 339.691895,29.059330 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M333.834442,31.878595 
	C333.091217,33.581905 333.854858,37.601826 330.034515,34.402657 
	C331.359314,33.499451 330.737366,29.933764 333.834442,31.878595 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M316.775269,41.668602 
	C316.175140,43.145157 315.350433,44.386600 314.271484,45.813416 
	C313.322571,43.622894 312.745880,41.312443 316.775269,41.668602 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M462.246582,12.752386 
	C461.526611,13.320567 461.049927,13.636742 460.273804,13.964603 
	C460.293976,12.165126 458.958344,9.390285 462.789246,9.752940 
	C463.612244,10.996034 463.152313,11.768861 462.246582,12.752386 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M410.002075,23.706646 
	C408.532654,23.952438 407.646729,25.035805 406.263245,25.852657 
	C405.726166,23.515976 407.248962,22.578505 409.667175,22.089493 
	C410.022217,22.510990 410.014923,22.945423 410.002075,23.706646 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M462.335693,13.003670 
	C462.251465,12.200528 462.499573,11.396657 462.873230,10.298559 
	C463.435944,10.017014 463.873108,10.029696 464.636047,10.076198 
	C464.971191,10.546505 464.980591,10.982993 464.992096,11.749409 
	C464.424316,12.904763 463.565002,13.001276 462.335693,13.003670 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M415.644348,20.974216 
	C415.920319,21.876619 416.265259,22.980055 415.238037,23.510492 
	C414.346588,23.970821 413.819763,23.174862 413.150391,22.301252 
	C413.515717,21.097580 414.395966,21.003754 415.644348,20.974216 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M443.020386,16.562365 
	C442.623596,16.961702 442.177917,17.048958 441.394714,17.180061 
	C441.034546,16.743933 441.011902,16.263952 440.981140,15.426925 
	C441.884216,15.048487 442.655884,15.263295 443.020386,16.562365 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M403.397522,29.316360 
	C402.740295,29.687183 402.277893,29.350134 402.033203,28.318712 
	C402.436523,28.343546 402.870819,28.690876 403.397522,29.316360 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M495.672028,23.281834 
	C496.021545,21.636072 496.026703,20.237583 496.045593,18.419802 
	C498.484406,14.610786 502.825653,14.689503 505.845642,14.484756 
	C514.260376,13.914254 522.926025,12.246196 531.578003,15.812421 
	C531.916382,16.054144 531.947144,16.529470 531.964722,16.766983 
	C533.793884,18.881151 536.335327,20.028234 537.734192,22.784306 
	C538.102051,24.446428 538.955505,25.023840 540.658081,25.025942 
	C541.561890,25.342501 542.087036,25.835365 542.669312,25.915922 
	C547.110596,26.530380 550.646667,27.773411 549.076233,33.519997 
	C548.854248,34.332268 549.468567,35.373089 549.847900,36.652344 
	C550.383606,37.452435 550.527954,37.974323 550.227539,38.931709 
	C548.422119,40.350655 546.871216,40.128933 545.068298,38.848877 
	C544.510132,38.161999 544.238159,37.723785 544.181213,37.228668 
	C543.494934,31.263376 542.875366,30.429331 536.723450,29.965603 
	C532.856812,29.674141 530.014954,28.152374 528.223145,24.842865 
	C526.967590,22.523859 525.576111,22.184799 523.485107,23.721903 
	C521.414734,25.243784 519.144531,25.438364 516.972900,23.987953 
	C514.649719,22.436293 512.372559,24.394356 510.292419,24.009798 
	C502.771118,22.619316 497.697510,25.586273 494.076447,31.929106 
	C493.594513,32.773251 492.580231,33.656918 491.674805,33.558372 
	C487.893250,33.146797 484.458588,34.712524 480.583679,34.880493 
	C477.489807,26.723412 483.724152,24.886909 489.935455,23.856897 
	C491.683472,23.567024 493.562927,24.330454 495.672028,23.281834 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M551.081543,37.345535 
	C557.562012,35.426884 561.070984,30.990417 563.235901,24.804625 
	C564.275391,21.834330 566.588257,21.187960 568.736755,24.805498 
	C569.372681,25.367811 569.591125,25.810362 569.636475,26.701239 
	C568.424377,29.445217 568.722595,30.943365 571.593079,31.926889 
	C575.551758,33.283302 579.312683,33.812420 583.089417,31.603397 
	C591.185120,26.868233 600.501892,28.788363 606.323425,36.737762 
	C604.873413,40.214607 602.330994,39.268665 599.989075,38.594765 
	C598.240295,38.091579 596.751587,36.945885 595.161743,36.061398 
	C592.974731,34.844704 591.111084,34.511383 590.096252,37.491234 
	C589.560364,39.065025 588.338440,40.257694 586.722656,39.736099 
	C582.976196,38.526703 579.962585,40.382854 576.400452,41.686310 
	C575.005554,41.882938 574.072205,41.606064 572.921997,40.834625 
	C570.423462,36.836811 567.580994,35.304253 563.091553,37.051254 
	C560.838806,37.927910 558.224426,38.020561 555.474976,38.765831 
	C554.416626,38.988438 553.698975,38.996330 552.625122,38.779957 
	C551.828979,38.438816 551.465088,38.114716 551.081543,37.345535 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M569.009583,27.426727 
	C568.992981,26.555502 568.994202,26.110981 568.998962,25.333078 
	C569.490479,24.738585 569.978516,24.477468 570.721619,24.107044 
	C571.942871,23.475607 572.518127,24.258394 573.475708,24.777287 
	C574.307190,24.999187 574.876770,25.037561 575.724609,25.106541 
	C576.436523,25.094744 576.870056,25.054548 577.627930,24.976622 
	C578.429749,24.583637 578.954041,24.492054 579.855774,24.921696 
	C580.994507,29.118874 577.985840,29.928442 575.362671,30.724190 
	C572.601562,31.561800 570.077332,31.145969 569.009583,27.426727 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M574.154785,25.693768 
	C571.639648,27.801596 572.360229,24.867182 571.226013,24.180588 
	C570.976929,23.553087 570.977173,23.108440 570.966797,22.330357 
	C571.400146,21.976820 571.844055,21.956717 572.620850,21.926743 
	C573.723877,22.932755 573.669067,24.270546 574.154785,25.693768 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M550.738525,36.990089 
	C551.304016,37.103233 551.565857,37.296829 551.885132,37.786514 
	C552.178162,39.054729 551.561646,39.339203 550.341797,39.185196 
	C550.000000,38.553696 550.000000,38.108845 550.000000,37.330280 
	C550.000000,36.996571 550.492371,36.992874 550.738525,36.990089 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M531.933411,15.806179 
	C520.120056,14.775710 508.384125,14.645378 496.442749,17.950125 
	C494.768494,15.211713 496.066803,14.049152 498.722168,14.150348 
	C500.121033,14.203656 501.562042,15.354753 502.851868,13.309425 
	C506.492126,11.996959 507.107697,7.037924 511.665405,6.983651 
	C513.645630,7.991386 515.112000,9.382752 517.638550,9.030978 
	C522.411621,11.002025 526.813293,12.967893 531.575378,14.997545 
	C531.935852,15.061330 531.950439,15.558213 531.933411,15.806179 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M537.967529,22.612980 
	C535.183777,21.874044 532.991760,20.057474 531.759094,17.203014 
	C535.830139,16.598585 538.660400,17.368702 537.967529,22.612980 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M574.306763,26.021957 
	C573.675171,24.909670 573.331726,23.776129 572.970947,22.279728 
	C572.953613,21.916868 573.345032,21.867537 573.536987,21.835335 
	C573.969604,22.999609 574.695374,23.855167 575.829590,24.840317 
	C575.533630,25.423532 575.064270,25.712120 574.306763,26.021957 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M495.611023,23.031563 
	C495.615204,25.443953 493.688843,25.131945 492.146271,24.956940 
	C488.641968,24.559381 485.743378,26.470806 482.711365,27.500587 
	C479.761383,28.502502 481.367279,31.824049 481.005432,34.568096 
	C481.100128,35.251255 481.191742,35.500607 481.042206,36.067341 
	C479.885681,36.915459 478.658203,37.224285 477.978302,36.594704 
	C473.318726,32.279831 467.115143,33.452702 461.713074,31.831635 
	C458.409454,30.840281 454.764435,30.228720 454.776428,25.324722 
	C456.662170,25.000401 458.324463,25.015591 459.986450,24.997889 
	C462.835938,24.967545 464.551331,24.071978 463.408569,20.746698 
	C462.410461,17.842371 463.087982,16.520523 466.561096,15.714266 
	C472.132294,14.420931 477.829559,15.805837 483.346039,14.114220 
	C485.683044,13.397592 487.150818,15.312071 488.071716,17.573835 
	C489.344421,20.699751 490.891083,23.754120 495.611023,23.031563 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M454.685883,24.976547 
	C456.914185,31.942724 463.416077,30.963091 468.089142,31.449202 
	C471.724091,31.827320 474.662659,31.784889 477.076202,34.835011 
	C477.669159,35.584373 479.166931,35.617722 480.620422,35.985439 
	C481.350983,36.813240 481.043030,37.493103 480.223785,38.364922 
	C479.200439,38.928143 478.448608,39.040783 477.303589,38.949184 
	C474.988953,38.005936 473.131378,37.161636 470.800690,37.798286 
	C468.178894,38.514462 466.052795,36.900928 463.924042,35.612961 
	C461.551575,34.177540 459.422546,33.529270 456.662720,35.453789 
	C451.879761,38.789082 449.588013,37.628281 448.393982,31.826090 
	C448.175293,30.763485 447.444366,30.124140 446.675415,29.007761 
	C446.386597,26.014050 447.616882,24.600979 450.663422,24.763680 
	C451.482117,24.968821 451.950867,24.956175 452.769958,24.892471 
	C453.537415,24.878639 453.954590,24.915861 454.685883,24.976547 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M457.352325,21.991222 
	C457.769043,20.938133 459.245392,20.312649 459.029572,18.307360 
	C462.272339,20.525963 460.612549,21.466898 457.352325,21.991222 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M529.657043,1003.836365 
	C524.180908,1005.134705 518.914856,1005.401367 514.400940,1004.920715 
	C509.158722,1004.362488 503.832062,1005.470093 498.341522,1004.009644 
	C496.572113,1003.276123 495.542511,1001.762451 493.341125,1001.921082 
	C485.096161,997.860596 475.772675,998.309814 467.660828,993.740784 
	C466.624695,993.218201 466.087219,992.715942 465.650665,991.633484 
	C465.171265,989.771423 463.767822,988.850525 463.292114,987.009277 
	C463.924622,983.254272 466.466858,983.550598 468.692657,984.277466 
	C471.623138,985.234558 474.072968,984.741455 476.816681,983.503235 
	C482.720001,980.838928 488.879028,982.130005 494.933380,983.129211 
	C496.528076,983.392395 497.522552,984.692749 497.747467,986.297913 
	C498.217346,989.651611 500.580963,990.751404 503.416687,990.754028 
	C506.975647,990.757324 509.588867,991.681763 510.603119,995.453796 
	C510.970642,996.820618 511.882690,997.965332 513.540161,997.343811 
	C519.039551,995.281616 525.175537,994.663330 528.845093,988.879944 
	C531.926147,984.024109 536.382385,980.904236 542.712402,981.983582 
	C543.847961,982.177124 545.002808,981.642883 546.124878,981.329895 
	C551.102844,979.941406 555.501587,980.714355 559.701965,984.013794 
	C564.621582,987.878052 571.221130,986.224976 574.234436,980.661926 
	C575.240601,978.804443 576.584167,977.904968 578.624573,978.103943 
	C580.610840,978.297729 582.596497,978.524841 584.910278,978.958008 
	C586.131531,979.339111 586.844177,979.942078 588.003357,980.353271 
	C589.221741,980.276550 590.109253,980.131958 591.348267,979.929199 
	C597.993652,981.319824 603.243164,978.014160 608.765076,976.048767 
	C610.132263,975.562195 611.415771,973.918213 612.782837,974.351379 
	C618.136597,976.047668 621.284485,973.079407 624.659546,969.698120 
	C627.343689,967.008789 631.065979,966.343079 634.843811,966.589294 
	C637.946045,966.791382 640.177002,965.795471 641.743042,962.872620 
	C643.296448,959.973389 646.134521,957.992126 647.798340,955.046021 
	C649.293640,952.398193 652.007812,952.490784 653.559753,954.693909 
	C657.296814,959.998535 661.784241,960.113708 667.374756,957.619385 
	C670.948059,961.930115 666.957031,964.329346 665.040771,967.020813 
	C663.181824,969.631592 661.164795,967.306946 659.434631,966.314087 
	C657.098816,964.973755 654.489319,964.361572 652.079712,963.234924 
	C649.550293,962.052063 647.940369,962.941162 648.421936,965.710144 
	C649.043945,969.286072 647.233337,970.156067 644.333313,970.314331 
	C641.294617,970.480225 638.903381,971.717834 637.279175,974.771606 
	C637.047424,975.287292 636.941650,975.486633 636.642456,975.961548 
	C635.536682,977.164246 634.397949,977.905579 633.810852,979.538879 
	C633.485596,980.013733 633.333252,980.191223 632.888062,980.570496 
	C631.282959,981.430481 629.815002,981.580444 628.080200,982.122803 
	C623.589417,982.665955 619.136292,981.247314 615.506836,984.643188 
	C614.574158,985.515808 612.855713,985.302673 611.699158,984.653320 
	C608.942505,983.105591 606.136292,983.707703 603.548035,984.605957 
	C599.471008,986.020935 595.370667,986.992981 590.689148,987.158813 
	C589.811523,987.209961 589.311951,987.188721 588.447632,987.087524 
	C584.432068,986.458496 580.980957,987.224548 577.286133,988.715454 
	C573.482300,989.356018 570.060608,989.951416 567.482178,992.706116 
	C566.925293,993.301147 566.090271,993.864197 565.301086,993.831055 
	C556.911438,993.477722 549.614014,999.474365 541.075745,997.996094 
	C539.178528,997.667603 539.743530,998.647949 539.882019,999.580383 
	C540.100220,1001.049438 539.520447,1002.165527 538.046997,1003.196899 
	C535.289124,1003.855652 532.767700,1002.891907 529.657043,1003.836365 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M466.888336,993.165710 
	C475.353027,996.568176 485.200928,995.308594 492.824646,1001.607544 
	C492.047943,1003.286316 490.555817,1003.400269 489.493225,1002.585938 
	C486.513245,1000.302063 483.148163,1001.183533 479.458008,1001.031860 
	C477.773102,1001.356262 476.667328,1000.920959 475.282593,1000.218140 
	C474.244324,1000.002869 473.495972,999.995605 472.373413,999.989319 
	C471.114471,1000.035828 470.222961,1000.112488 469.263336,999.208374 
	C469.247925,997.208923 468.463806,995.836243 467.171448,994.294495 
	C466.892395,993.798035 466.775024,993.605530 466.888336,993.165710 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M452.962341,995.483459 
	C452.511871,995.897766 452.078949,995.941467 451.319000,995.977600 
	C449.826294,996.000854 448.657532,996.046875 447.495056,995.976868 
	C442.580444,995.681091 441.648956,994.128845 443.841949,989.730103 
	C444.451599,988.507324 444.883240,987.292175 445.005432,985.573730 
	C445.134521,984.972290 445.211731,984.737305 445.493530,984.192322 
	C446.107117,983.598389 446.556702,983.425781 447.411560,983.449890 
	C448.432617,983.822571 448.888489,984.358521 449.500305,985.210327 
	C450.353424,986.639099 451.082581,987.693420 452.995758,987.546143 
	C455.333221,987.578979 457.323975,987.483582 459.709412,987.634277 
	C461.226959,987.782715 462.242340,988.115784 462.962677,989.492188 
	C461.744507,991.550415 459.934113,990.779175 458.360443,991.018555 
	C455.831696,991.403137 453.345978,991.873596 452.962341,995.483459 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M498.051819,1007.365112 
	C501.013672,1006.439148 504.073090,1005.786011 505.940887,1009.681152 
	C503.174316,1009.922241 500.241699,1010.218689 498.051819,1007.365112 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M463.239136,990.032532 
	C462.397339,989.063538 461.498566,988.994385 460.255157,988.909668 
	C459.360107,988.216309 459.203827,987.455566 459.562012,986.256836 
	C461.122620,984.983215 462.309082,985.382996 463.704224,986.688293 
	C464.717590,988.042542 466.230957,988.639404 466.018524,990.627197 
	C465.861115,991.154541 465.695709,991.339355 465.245300,991.285034 
	C464.472809,990.749451 463.985352,990.452942 463.239136,990.032532 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M475.134216,1000.301636 
	C476.177490,1000.174316 477.362305,1000.338379 478.780334,1000.780090 
	C477.313934,1004.551758 476.446167,1001.355164 475.134216,1000.301636 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M469.023376,999.307739 
	C469.883484,999.140259 470.732666,999.310608 471.790558,999.735596 
	C471.545166,1000.826172 471.088287,1001.780273 469.941223,1001.568176 
	C468.925934,1001.380432 469.101929,1000.399475 469.023376,999.307739 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M518.060852,1010.289490 
	C518.208862,1009.315613 518.175537,1008.201843 519.266479,1008.636780 
	C520.265930,1009.035339 519.908447,1010.193542 520.020508,1011.378540 
	C519.126404,1011.791321 518.432678,1011.519592 518.060852,1010.289490 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M464.976990,991.393555 
	C465.137817,991.033020 465.315430,991.020142 465.759766,990.988525 
	C466.297852,991.457581 466.569153,991.945435 466.979736,992.675842 
	C467.094543,993.096497 467.070129,993.274597 467.027710,993.721680 
	C465.572571,993.926575 464.997498,993.089905 464.976990,991.393555 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M514.316711,1011.015808 
	C514.187134,1010.148193 514.108215,1008.982361 515.817444,1009.772949 
	C515.534058,1010.321045 515.072266,1010.650574 514.316711,1011.015808 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M536.800049,1003.179810 
	C539.028137,1001.496643 540.290771,999.906494 537.481873,998.033386 
	C536.689880,997.505249 536.101318,997.049744 536.678772,996.140991 
	C537.162170,995.380127 537.990356,995.053589 538.817688,995.580872 
	C544.649353,999.296875 549.898621,995.644165 555.177307,994.050293 
	C557.110535,993.466553 558.934143,992.885376 560.890137,993.033325 
	C563.880737,993.259644 566.480774,992.721008 568.344971,990.152161 
	C570.348572,987.391296 573.323914,988.067261 576.499084,987.953918 
	C577.286804,988.286865 577.483887,988.710205 577.461609,989.558105 
	C577.247925,990.105042 577.108398,990.298645 576.691162,990.712585 
	C571.939514,991.975220 572.633484,995.673645 572.103088,999.124695 
	C569.609253,1000.760986 567.308350,1000.265198 565.354187,998.996948 
	C561.792236,996.685364 558.563232,997.252319 554.653320,999.730835 
	C552.267395,999.739441 550.614197,1000.917114 548.741089,999.592102 
	C546.199707,997.794434 544.556091,1000.289062 542.698669,1001.566223 
	C541.060120,1002.692932 539.309998,1003.657288 537.102356,1004.626587 
	C536.479553,1004.172119 536.480347,1003.784607 536.800049,1003.179810 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M555.751160,1005.792969 
	C555.959229,1007.731018 555.956177,1009.429321 555.892822,1011.548218 
	C553.193909,1013.635925 551.287231,1017.468994 547.117310,1014.434326 
	C546.174927,1013.748596 545.366821,1015.455688 544.970276,1016.593384 
	C544.408630,1018.205078 543.555725,1019.943420 541.588989,1019.529175 
	C539.866699,1019.166382 539.016724,1017.567261 539.048706,1015.332642 
	C543.492065,1015.177185 545.898926,1013.173523 545.998962,1008.637512 
	C546.021118,1007.633972 546.063660,1006.636353 547.523926,1006.034241 
	C550.570618,1007.416016 550.111633,1004.094299 551.704590,1003.324524 
	C552.362061,1003.035278 552.802612,1002.994751 553.850891,1003.100098 
	C555.096741,1003.884888 555.068115,1004.836304 555.751160,1005.792969 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M553.930298,1002.933960 
	C553.938171,1002.181213 553.971191,1001.451843 553.997070,1000.355835 
	C553.989929,999.989136 553.998291,999.999207 553.994019,999.994629 
	C559.336670,997.195801 564.649658,998.504700 570.304199,1000.247375 
	C572.915283,1004.288330 569.992310,1004.667603 567.341248,1005.193054 
	C565.713196,1005.515869 564.284241,1004.014832 562.281677,1004.707520 
	C559.984192,1005.538025 558.514954,1003.099670 556.187012,1003.935791 
	C555.374756,1003.877441 554.944275,1003.714783 554.275208,1003.234680 
	C554.004883,1003.004883 553.955444,1002.957397 553.930298,1002.933960 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M528.380493,1009.986694 
	C527.805054,1008.188293 528.256348,1006.456299 528.953491,1004.377563 
	C529.000000,1003.997559 529.000000,1004.000000 528.998779,1004.000000 
	C531.535950,1002.285461 533.927612,1003.177063 536.632996,1004.688477 
	C536.966003,1004.965820 536.981934,1004.981812 536.989746,1004.989685 
	C535.106995,1008.089111 531.278687,1007.941711 528.380493,1009.986694 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M551.603699,1003.078979 
	C552.314087,1005.308533 551.083557,1006.815796 549.442993,1007.907227 
	C547.843445,1008.971313 548.191711,1007.335022 547.923218,1006.256409 
	C547.934204,1005.526855 547.985535,1005.118835 548.063232,1004.403076 
	C548.403870,1003.583496 548.845276,1003.248352 549.726074,1003.132019 
	C550.437500,1003.120361 550.861694,1003.101318 551.603699,1003.078979 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M549.853577,1002.892578 
	C549.557251,1003.432251 549.101135,1003.725098 548.367310,1004.056641 
	C548.207275,1003.150574 548.079590,1001.934387 549.853577,1002.892578 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M534.016174,1012.456848 
	C533.903137,1012.775879 533.726013,1012.836548 533.271790,1012.943726 
	C532.685791,1012.861450 532.427673,1012.665710 532.113708,1012.181763 
	C532.782593,1010.999817 533.427673,1011.105713 534.016174,1012.456848 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M561.887207,1004.348633 
	C562.703796,1002.945496 563.285095,1001.476074 564.893311,1002.998108 
	C567.511902,1005.476440 569.392761,1004.417603 571.040527,1001.448975 
	C571.189697,1000.913940 571.278687,1000.721191 571.596985,1000.307495 
	C572.671387,999.616211 572.943726,998.832336 573.036621,997.591675 
	C573.135010,996.777039 573.286621,996.330994 573.729431,995.609009 
	C576.603149,993.091492 579.306152,992.264160 582.289429,995.320068 
	C581.177429,997.927673 579.129211,999.767395 579.632507,1002.884827 
	C579.860352,1004.295959 578.147095,1004.977478 576.727966,1004.854675 
	C575.218933,1004.724182 573.783447,1004.676453 572.168335,1005.689880 
	C571.365845,1006.080688 570.838257,1006.275391 570.155029,1006.708130 
	C569.560913,1006.955322 569.122498,1006.964478 568.355225,1006.977295 
	C567.858582,1006.846191 567.690735,1006.711426 567.295288,1006.347656 
	C565.984558,1006.088013 564.901489,1006.057434 563.411804,1006.024597 
	C562.313354,1005.815613 561.928406,1005.329407 561.887207,1004.348633 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M567.062744,1006.357666 
	C567.386475,1006.160767 567.644409,1006.308838 567.933960,1006.771973 
	C568.026428,1006.980957 567.564453,1007.028381 567.334351,1007.050415 
	C567.104248,1007.072510 567.057739,1006.596680 567.062744,1006.357666 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M561.686035,1004.020264 
	C562.276978,1004.534546 562.535278,1005.021729 562.899414,1005.765625 
	C560.439819,1008.832458 558.938354,1004.788757 556.401672,1005.056030 
	C555.662720,1004.648560 555.505066,1004.168640 555.775391,1003.286865 
	C558.073059,1001.768494 559.503418,1003.965942 561.686035,1004.020264 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M555.657654,1002.964050 
	C555.994995,1003.422607 556.001831,1003.889526 556.022034,1004.707153 
	C556.035400,1005.057861 556.015686,1005.546265 555.989014,1005.789551 
	C554.532227,1005.910706 553.981995,1005.051392 554.008667,1003.355225 
	C554.445618,1002.994019 554.886414,1002.983215 555.657654,1002.964050 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M445.715515,984.749390 
	C445.904022,986.493469 447.274536,987.792175 446.006775,989.594177 
	C442.665802,994.342834 442.833466,994.488892 449.066040,995.048645 
	C449.541809,995.091370 450.002533,995.301575 450.731140,995.702026 
	C445.095123,998.231995 443.785309,997.310730 439.485077,992.234253 
	C437.340393,989.702393 433.074921,990.589905 429.713745,990.308533 
	C424.956970,989.910278 420.170044,989.810303 415.431885,989.268799 
	C410.357849,988.688965 405.329895,987.713806 400.271393,986.983521 
	C399.130615,986.818726 397.918579,986.864563 396.915344,988.282959 
	C395.805115,988.633728 394.883545,988.665833 393.616150,988.642212 
	C388.810974,986.333008 384.579559,985.496277 379.760742,988.341614 
	C374.696869,991.331665 372.663361,986.848145 370.151611,983.343262 
	C369.354889,983.117981 368.918243,982.927917 368.216370,982.458984 
	C367.566467,981.452209 367.813293,980.785828 368.556091,979.904358 
	C370.441803,978.724670 372.228607,979.127014 374.314026,978.958740 
	C377.175079,979.200378 379.628784,979.820190 382.539825,979.599304 
	C383.704407,979.590454 384.484833,979.570679 385.660522,979.674072 
	C387.402496,979.859558 388.644623,980.342712 390.219513,981.069641 
	C392.882660,982.973145 395.305634,983.472534 397.966888,982.027161 
	C400.262939,980.780090 402.553772,981.219543 404.542236,982.592102 
	C407.170593,984.406372 410.014282,984.732300 413.051270,984.542114 
	C420.508667,984.075134 427.850128,985.725281 435.620941,985.867676 
	C437.121674,986.155212 438.065857,987.472107 439.700073,986.453491 
	C440.923828,985.768005 441.860413,985.216736 443.066711,984.449829 
	C444.093933,984.146667 444.779480,984.196350 445.715515,984.749390 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M90.000168,824.412903 
	C92.023071,822.851929 93.688370,821.497375 93.001236,818.392700 
	C94.470306,816.432739 95.938957,814.867920 97.698792,813.151611 
	C101.261879,813.677246 101.990227,816.389771 103.011604,819.391602 
	C105.989716,815.233215 109.235291,817.393921 112.793961,819.083618 
	C113.968613,819.778198 114.662415,820.474060 115.440399,821.607361 
	C115.897598,822.654907 115.959770,823.394653 115.754044,824.501831 
	C115.229874,825.741272 114.482979,826.290527 113.378212,826.908081 
	C112.573639,827.360046 112.126480,827.731873 111.893173,828.648804 
	C111.695107,830.065918 111.293938,831.088928 110.602409,832.371826 
	C108.962082,835.722290 107.004234,838.128113 103.292244,838.452087 
	C101.760498,838.585754 100.697456,839.679749 99.310036,840.835449 
	C97.583260,840.888184 97.154938,839.990051 96.924965,838.714661 
	C96.793098,837.983337 95.964439,836.697327 95.836380,836.743164 
	C88.956299,839.203003 92.892799,833.219727 91.672127,831.355408 
	C90.408997,829.426270 89.708344,827.219116 90.000168,824.412903 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M98.347275,810.257629 
	C92.731438,805.579590 87.702332,809.591431 82.840179,810.999329 
	C80.114449,811.788574 79.017548,811.081360 78.309532,809.116211 
	C77.599281,807.145020 77.672310,805.345337 80.688850,804.917664 
	C83.813995,804.474548 86.358665,802.966980 85.537422,799.010437 
	C85.095329,796.880554 86.346123,797.362854 87.530518,797.693054 
	C89.330322,798.194885 89.927643,797.217102 90.048050,795.269043 
	C90.473145,794.609924 90.914062,794.440552 91.757797,794.492554 
	C95.431114,797.206848 93.707115,799.658813 91.860680,802.385864 
	C93.472595,801.369690 94.918228,799.363831 97.173691,801.618896 
	C97.612030,803.648376 97.845787,805.247253 99.337395,806.620850 
	C100.647995,807.827698 101.608330,809.674255 98.347275,810.257629 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M98.041908,810.432251 
	C100.523148,809.394958 99.893745,808.217041 98.354858,807.264160 
	C96.475807,806.100708 95.350998,804.866516 96.875107,802.326904 
	C97.423920,801.689758 97.873955,801.513062 98.737610,801.614929 
	C101.510536,804.862854 104.332253,807.797302 104.942192,811.950012 
	C105.330681,814.594971 106.753571,814.329346 108.444832,813.325806 
	C110.479889,812.118164 112.531616,811.566101 114.307373,813.561584 
	C116.293388,815.793274 115.235878,817.847900 113.247429,819.892578 
	C111.975807,819.833435 110.868111,819.857605 110.088097,819.363892 
	C107.155518,817.507812 104.821060,817.063660 103.921738,821.336182 
	C103.767479,822.068970 103.327263,822.673279 102.633781,822.479919 
	C102.092819,822.328979 101.220940,821.455261 101.310326,821.238525 
	C102.750725,817.745483 99.181358,816.108948 98.250984,813.216431 
	C98.005440,812.266174 98.020897,811.532227 98.041908,810.432251 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M75.256035,785.302185 
	C72.908966,785.017273 72.003372,783.110352 70.464325,782.235962 
	C68.452705,781.093140 69.527145,779.475281 70.166550,778.401062 
	C71.187317,776.686157 72.295021,778.518250 72.858582,779.023376 
	C75.467567,781.361816 78.219498,781.034607 81.732101,780.191833 
	C82.518845,782.857666 83.922310,784.425415 86.183426,785.806702 
	C86.535446,786.530701 86.546371,786.984314 86.198318,787.703125 
	C85.481491,788.233826 84.994934,788.502502 84.254646,788.885620 
	C79.705986,791.997375 78.676437,791.582825 75.256035,785.302185 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M74.986153,785.363159 
	C76.474449,785.064514 77.552193,785.718689 77.904892,787.205383 
	C78.726570,790.669006 80.955688,789.964355 83.635368,789.040833 
	C83.999496,789.997131 84.056717,790.998657 83.983658,791.990479 
	C83.856659,793.714905 84.585434,796.065674 82.120979,796.526184 
	C80.258438,796.874268 79.558365,795.288696 78.799034,793.588501 
	C77.395790,790.446838 74.883888,792.480591 72.389542,792.956360 
	C69.904182,790.250305 68.784798,787.865417 73.900269,787.587158 
	C74.975212,787.528625 74.881157,786.489929 74.986153,785.363159 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M92.333832,794.963623 
	C91.567810,794.957520 91.140450,794.949951 90.394684,794.921143 
	C90.343231,793.288208 89.220016,791.318726 91.651886,790.089844 
	C93.188911,787.778259 95.215935,785.843323 94.519531,782.587646 
	C95.932373,779.822876 97.772552,780.000488 100.098045,781.526733 
	C100.811371,782.497314 100.982277,783.261047 100.990494,784.451050 
	C99.851982,787.512451 100.982918,790.222717 100.730682,793.251404 
	C98.225243,795.537598 94.978432,792.527588 92.333832,794.963623 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M94.709305,782.761475 
	C97.399712,786.391785 94.887779,788.017700 92.318710,789.869995 
	C91.983475,784.849060 88.618370,788.341675 86.355255,787.969971 
	C85.981049,787.531311 85.994041,787.097412 86.007256,786.337646 
	C85.811371,785.528992 85.892044,785.086975 86.482513,784.440552 
	C87.851997,783.649414 88.941330,783.093933 90.305176,782.251526 
	C91.963295,781.736755 93.230255,781.746643 94.709305,782.761475 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M99.002991,841.327698 
	C98.987709,839.093384 99.348099,836.983154 101.632523,837.163757 
	C105.685509,837.484375 107.765373,835.403992 109.604012,832.055542 
	C113.373497,833.760498 114.960106,836.474976 114.438423,840.405273 
	C114.246269,841.852966 114.225281,843.386475 115.433426,844.958130 
	C116.030853,845.855164 116.307693,846.510925 116.486816,847.571960 
	C116.350082,849.101440 115.727928,850.067017 115.145012,851.061279 
	C112.115044,856.229797 112.122643,856.235779 118.142334,858.783081 
	C118.581306,859.493958 118.664642,859.964600 118.578735,860.810120 
	C116.873695,863.349182 116.853004,866.194763 115.239014,868.754028 
	C114.751190,870.787964 113.626129,871.507324 112.276703,870.439209 
	C110.289566,868.866272 107.193581,867.297119 108.292564,864.285400 
	C109.349892,861.387817 110.693604,858.998474 108.305344,856.180054 
	C107.664581,855.423889 108.576469,854.382996 108.978729,853.472473 
	C111.354828,848.094604 109.533363,844.910156 103.665352,843.918945 
	C102.374817,843.700867 101.113556,843.309631 99.437958,842.975220 
	C99.027863,842.519165 99.018623,842.086060 99.002991,841.327698 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M138.403625,877.586609 
	C139.720245,880.652710 136.314392,882.591309 137.866943,885.671021 
	C138.385071,887.943481 136.860550,887.733459 135.365891,887.984497 
	C134.565811,888.017456 134.132843,888.041565 133.375320,888.086853 
	C131.456894,889.145325 130.174362,888.229126 128.971176,887.357056 
	C127.299599,886.145569 125.451035,885.657471 123.432777,885.352417 
	C118.006966,884.532227 116.590279,882.188660 118.136467,876.685791 
	C118.513618,875.343506 118.425987,874.070801 118.334785,872.377319 
	C118.529053,871.237854 118.237335,870.524414 118.120377,869.418945 
	C118.167557,868.803345 118.218323,868.560242 118.432648,867.983398 
	C118.756821,867.462158 118.917435,867.274658 119.375168,866.872070 
	C119.901047,866.579712 120.129807,866.502441 120.717209,866.424072 
	C123.710999,868.073669 123.838974,872.663025 128.223053,872.427612 
	C128.780243,872.397644 128.752853,874.106812 128.514618,875.087097 
	C128.231995,876.250000 126.870163,877.283447 128.305115,878.823364 
	C128.735855,879.130737 128.906113,879.254578 129.319153,879.573547 
	C130.495895,880.894104 131.406647,881.155762 132.483856,879.438721 
	C134.092926,877.727722 135.662552,876.714417 138.403625,877.586609 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M118.733963,870.815552 
	C119.442238,873.321289 120.394127,875.347351 118.899277,877.996826 
	C117.619797,880.264465 118.600449,883.928223 121.832451,883.747498 
	C126.287697,883.498352 129.163147,885.761353 132.690491,888.032227 
	C133.398499,890.322327 131.504288,891.666748 130.936920,893.528198 
	C130.845871,893.826965 130.599594,894.078369 130.425049,894.351685 
	C127.637032,892.124817 124.576035,893.716858 121.654831,893.495605 
	C117.548416,893.184631 117.483841,889.191895 117.557045,885.812500 
	C117.567520,885.329041 117.786964,884.648132 117.549667,884.391418 
	C113.585907,880.104126 116.888252,875.923340 117.822678,871.365723 
	C118.139694,870.888977 118.307693,870.758850 118.733963,870.815552 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M115.332092,869.027588 
	C115.001953,866.174255 114.796822,863.260376 117.665039,861.070862 
	C121.158913,861.676208 120.891441,863.946472 120.266739,866.623352 
	C120.027496,866.974426 119.977272,867.023499 119.952499,867.048401 
	C119.769829,867.227600 119.611931,867.381897 119.222450,867.772949 
	C118.990868,868.009705 118.998962,868.000610 119.003159,867.996643 
	C118.588707,868.412903 118.186699,868.856079 117.288742,869.037415 
	C116.548645,869.041138 116.106277,869.034241 115.332092,869.027588 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M135.259445,888.267090 
	C135.873444,887.488159 136.748123,886.982849 137.812744,886.229858 
	C139.025558,886.428772 139.751282,887.139038 140.058319,888.565796 
	C138.559067,888.930481 136.998657,889.293945 135.259445,888.267090 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M117.014656,869.337891 
	C117.462349,868.714966 117.933655,868.381897 118.706161,868.020752 
	C119.004349,868.745117 119.001335,869.497559 118.995270,870.626221 
	C118.822227,871.004272 118.652229,871.006104 118.226959,871.013550 
	C117.660561,870.555359 117.349419,870.091492 117.014656,869.337891 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M80.167862,757.643555 
	C79.884407,754.975098 78.685982,754.159973 76.784859,753.957825 
	C74.337090,753.697510 73.569336,751.747009 73.242577,749.778992 
	C72.932983,747.914429 74.364258,747.225891 75.984322,746.988831 
	C78.079567,746.682190 77.265358,745.382812 77.218887,744.054138 
	C77.103195,740.747009 75.969063,737.644409 72.695694,737.307190 
	C68.591614,736.884460 68.942062,734.524170 68.993721,731.429871 
	C70.380005,728.862183 72.481148,727.993408 75.285896,727.677979 
	C76.441483,727.672180 77.199158,727.766541 78.292503,728.111450 
	C79.796562,728.811340 80.395096,729.943481 81.506119,731.069214 
	C82.388641,732.059021 82.977478,732.816650 83.494514,734.032776 
	C79.412476,737.376160 80.755501,741.608582 80.937439,745.665100 
	C81.034836,747.836548 81.668068,748.872620 83.896263,748.283325 
	C86.848808,747.502380 89.084587,748.607117 90.965210,750.785706 
	C91.182510,751.037476 91.401917,751.297791 91.672379,751.486755 
	C93.025238,752.431885 97.705612,758.016052 98.335396,759.492554 
	C99.074059,760.632568 99.411751,761.563538 99.841034,762.873291 
	C100.165985,764.294983 100.143890,765.332397 99.601555,766.702454 
	C99.219009,767.229431 99.024780,767.406799 98.481392,767.763245 
	C96.643143,768.443604 95.118294,768.343384 93.234940,768.162842 
	C91.005127,767.506165 89.356247,767.910034 87.630020,769.413452 
	C86.367859,770.204102 85.324051,770.630127 83.830025,770.723999 
	C82.585533,770.557739 81.840652,770.102600 81.009224,769.206787 
	C80.771393,768.742920 80.717072,768.540527 80.701416,768.031738 
	C80.896431,767.147766 81.049309,766.569641 81.008751,765.664307 
	C80.889015,764.749634 80.835693,764.131348 80.753342,763.189941 
	C80.663597,761.333679 80.804123,759.783569 80.167862,757.643555 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M57.344486,726.159729 
	C57.135544,724.971863 57.223267,723.930115 57.401436,722.507263 
	C57.403713,720.892212 58.197201,720.498413 59.569107,720.330444 
	C61.663437,720.986877 63.525444,721.229858 65.415291,722.275879 
	C65.898590,724.387573 65.447319,726.023010 64.264366,727.802124 
	C61.724571,727.963379 58.995583,729.647644 57.344486,726.159729 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M64.183609,728.284546 
	C64.279823,726.266357 64.586945,724.495422 64.923981,722.340820 
	C65.183868,721.913025 65.413849,721.868835 65.948029,721.995972 
	C67.276512,724.899109 68.689957,726.683105 72.017189,725.103455 
	C73.200401,724.541626 74.616661,724.794250 76.106827,725.722473 
	C76.753372,726.655884 76.820442,727.382202 76.430466,728.463989 
	C73.636543,728.222961 72.056725,730.649963 69.379654,730.986206 
	C67.460609,730.180481 65.927559,729.356079 64.183609,728.284546 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M71.778442,762.340332 
	C73.944885,759.820435 71.357368,758.373352 71.151611,756.195068 
	C72.849777,756.243469 74.509003,756.641846 76.544533,757.051514 
	C77.531296,757.609619 77.802132,758.305176 77.767654,759.497620 
	C77.210533,761.368958 76.453247,762.720032 75.214401,764.229126 
	C74.148415,764.823547 73.373581,764.857056 72.230392,764.398193 
	C71.574585,763.738098 71.467865,763.227295 71.778442,762.340332 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M77.324570,760.260498 
	C76.979210,759.270630 76.975311,758.533142 76.946121,757.429321 
	C77.690186,757.045532 78.459534,757.028137 79.612381,757.006592 
	C79.995865,757.002441 80.000000,757.000000 79.998566,757.001953 
	C80.811058,758.014404 80.855675,759.039795 79.697601,760.308960 
	C78.778770,760.690491 78.221268,760.682678 77.324570,760.260498 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M81.926712,779.931091 
	C82.493004,777.766418 80.668022,776.522400 79.970230,774.496704 
	C81.840309,772.327271 84.125305,771.038696 86.792877,769.683655 
	C87.775108,769.189575 88.467873,769.074829 89.533257,769.281372 
	C91.001221,769.952515 92.022385,771.636597 93.335785,769.481689 
	C94.310364,767.882690 96.053787,767.992554 98.035889,768.013916 
	C99.427399,767.997925 100.406174,768.151123 101.441238,769.221497 
	C101.799667,769.785522 101.912025,770.031677 102.127197,770.672974 
	C102.388901,772.158386 102.365326,773.256348 102.362625,774.770142 
	C102.292221,776.916748 101.822670,778.504028 100.396942,780.136353 
	C94.247345,779.127319 88.440834,780.565552 82.317680,780.291504 
	C82.014114,780.019531 81.956940,779.959473 81.926712,779.931091 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M71.805481,764.229370 
	C72.742966,764.006104 73.489494,764.028381 74.609299,764.061279 
	C76.438118,764.086304 77.784142,763.799683 79.119705,762.520447 
	C80.102287,762.176331 80.766365,762.229309 81.663918,762.793823 
	C81.963402,763.769470 82.002762,764.510071 82.036163,765.621643 
	C81.990738,766.581238 81.799797,767.109314 81.183746,767.738770 
	C80.962730,767.921936 80.949814,767.963196 80.928207,767.963623 
	C80.078156,769.134583 78.705757,769.191284 77.235458,769.774719 
	C76.756180,770.004272 76.556595,770.079407 76.039436,770.193115 
	C74.694511,769.864258 73.667160,769.496887 72.317337,769.067627 
	C71.986046,767.938416 71.977234,766.871094 71.955315,765.403687 
	C71.832977,764.827332 71.723740,764.651062 71.805481,764.229370 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M87.199417,769.759644 
	C84.792686,771.221558 83.879257,774.458008 80.289078,774.103271 
	C77.868912,773.942322 76.090492,773.401672 75.783142,770.580322 
	C75.721863,770.231689 75.868011,770.115417 75.938416,770.054077 
	C78.191124,768.292847 80.187126,771.458679 82.675407,770.145142 
	C84.099541,769.697937 85.199455,769.341187 86.645309,768.938843 
	C87.123581,769.084290 87.255920,769.275452 87.199417,769.759644 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M71.998070,763.654053 
	C71.991844,764.154053 71.987236,764.324219 71.962425,764.749023 
	C70.549248,764.896179 69.990891,764.074890 70.012604,762.413208 
	C70.450424,762.048706 70.893082,762.024414 71.668449,762.002502 
	C72.000671,762.444702 72.000183,762.884460 71.998070,763.654053 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M66.689522,705.610535 
	C67.711746,705.216248 68.537918,705.111267 69.692963,705.017334 
	C70.839348,705.666748 70.975998,706.564026 70.918800,707.847656 
	C70.585175,709.217224 69.873772,710.073669 69.531647,711.430176 
	C68.805756,713.143616 68.753578,714.627991 68.825066,716.486145 
	C68.402550,718.240906 67.388596,718.836853 65.640549,718.984741 
	C64.994896,718.908325 64.740921,718.843384 64.139557,718.591919 
	C62.116222,716.765320 61.755131,714.831360 62.651402,712.298584 
	C63.215916,711.037048 63.806355,710.247131 64.751671,709.266479 
	C65.498581,707.967529 65.996025,706.933716 66.689522,705.610535 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M64.606079,708.982422 
	C64.818588,709.852844 64.636040,710.704285 64.189964,711.760010 
	C60.648338,714.007874 61.514107,717.691650 60.246128,720.875244 
	C59.309460,721.356201 58.623238,721.592041 57.714447,721.977051 
	C57.040054,719.370667 55.721485,716.766296 56.078506,713.458008 
	C59.376961,712.816956 60.212379,707.746277 64.606079,708.982422 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M76.559204,728.830933 
	C76.160004,728.096008 76.094383,727.402710 76.007217,726.358887 
	C70.889580,724.035706 71.864601,719.812439 72.148468,715.455872 
	C72.045959,714.599792 72.038948,714.110046 72.105484,713.261353 
	C73.189041,709.594543 73.511230,706.120483 75.089119,702.904724 
	C76.731003,699.558594 73.638885,695.436707 69.115814,694.600464 
	C67.162514,694.239197 65.142937,694.266541 62.805031,694.613281 
	C62.222160,694.672180 61.986130,694.663208 61.418968,694.528320 
	C60.643154,694.355591 60.198467,694.308899 59.619961,694.078857 
	C59.237778,693.408569 59.023903,692.907043 58.744225,692.132324 
	C58.354393,691.157043 57.813564,690.596069 57.316525,689.649048 
	C56.421925,686.927307 58.139072,685.365417 59.325573,683.277039 
	C59.496300,682.301941 59.324657,681.652527 59.252274,680.639343 
	C59.285561,679.796021 59.420368,679.331909 59.863457,678.594788 
	C63.418556,676.085632 67.345116,676.140869 71.410469,675.303711 
	C73.252197,675.250488 74.487785,675.657288 74.500938,677.409119 
	C74.524139,680.495850 71.831215,681.289978 69.583359,682.748657 
	C71.298126,684.506836 73.043755,683.484070 74.699577,683.262756 
	C79.254616,682.653809 80.404152,683.926025 78.666931,688.032349 
	C77.956680,689.711121 77.917389,691.027710 78.514206,692.742981 
	C80.525620,698.523926 82.041275,704.463196 83.768707,710.334778 
	C84.863991,714.057678 82.487259,716.619141 80.977600,719.535095 
	C87.336845,721.013123 87.643585,721.312195 88.153290,727.850647 
	C88.280670,729.484741 88.680725,730.731079 90.153885,731.567200 
	C91.450058,732.302856 92.916000,732.993652 92.051926,735.246948 
	C90.650002,736.273376 89.355667,736.333496 87.638672,736.248962 
	C85.880432,735.967529 84.631958,735.489319 83.291168,734.277100 
	C82.410995,733.512634 81.839294,733.011169 81.137848,732.251099 
	C80.303062,731.252930 80.157204,730.157837 79.154495,729.270386 
	C78.240433,729.014893 77.566605,728.943665 76.559204,728.830933 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M61.758907,694.113037 
	C69.820992,691.651428 74.488579,693.817200 76.856148,700.156128 
	C77.651939,702.286804 76.321648,703.012817 75.735527,704.437866 
	C74.689125,706.981995 74.258308,709.779297 73.306915,712.733398 
	C71.540016,713.611816 70.835464,712.433228 69.799286,711.298462 
	C69.452866,710.507812 69.410118,710.033691 69.731613,709.246094 
	C69.968002,707.899841 69.999184,706.855164 70.026093,705.419434 
	C70.337387,704.490723 70.652946,703.953186 71.252136,703.268555 
	C71.952957,701.872375 74.093552,701.143127 72.736382,699.367188 
	C71.347221,697.549316 69.421791,697.587097 67.073784,698.014709 
	C66.240891,698.005859 65.774948,697.905701 64.997040,697.588867 
	C63.852356,696.226868 62.235897,695.845886 61.758907,694.113037 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M59.811790,691.795044 
	C59.956615,692.499634 59.891438,693.010925 59.656200,693.708862 
	C55.825993,692.876892 55.279003,689.928772 55.025318,686.388184 
	C53.947781,682.620850 56.467098,679.021118 54.280869,675.332153 
	C54.157677,674.447205 54.193756,673.937683 54.465721,673.071289 
	C55.309158,671.800903 56.197563,671.324890 57.728668,671.573730 
	C59.672997,673.608032 60.034977,675.882751 60.065460,678.619873 
	C60.014229,679.441833 60.030663,679.883728 60.048500,680.657227 
	C60.030720,681.581238 60.179161,682.121338 60.735840,682.811157 
	C61.170040,685.639404 57.172276,686.274414 58.061485,689.550415 
	C58.529312,690.521729 59.067039,691.060425 59.811790,691.795044 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M54.636890,674.869202 
	C57.517555,678.399536 57.574333,681.812988 55.034912,685.634521 
	C54.306458,685.993652 53.632587,685.981873 52.629532,685.959839 
	C52.015652,685.124268 51.730961,684.298950 51.216103,683.241699 
	C50.938053,680.882996 50.962463,678.767334 52.009953,676.412231 
	C52.680019,675.326233 53.318699,674.697266 54.636890,674.869202 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M66.849213,697.457642 
	C69.067619,696.341370 71.246956,695.895142 73.206184,697.793030 
	C75.576332,700.088989 75.812057,701.934753 71.852989,703.074463 
	C71.436951,702.665344 71.338120,702.209167 71.217056,701.402954 
	C71.066322,700.465454 70.846550,699.922119 70.204910,699.280823 
	C69.979095,699.088013 69.522659,698.994873 69.290298,698.978760 
	C68.451729,698.331665 67.312363,698.695312 66.849213,697.457642 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M66.534363,697.123657 
	C67.462685,697.588318 68.083931,698.044250 68.881554,698.731445 
	C66.967056,699.087219 64.429932,701.527710 63.025822,697.464050 
	C63.342461,696.708862 63.802425,696.525208 64.676895,696.780579 
	C65.410149,697.034607 65.818718,697.074707 66.534363,697.123657 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M64.949753,696.661499 
	C64.550171,697.011963 64.098770,697.029480 63.309845,697.082275 
	C62.366333,696.449707 61.760345,695.781921 61.121101,694.758179 
	C61.202904,694.191589 61.371243,694.039917 61.749275,693.864258 
	C62.412315,695.206909 64.539551,694.728821 64.949753,696.661499 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M70.016846,699.392334 
	C70.937286,699.161804 71.930122,699.200439 71.372681,700.843506 
	C70.814758,700.600830 70.434677,700.148743 70.016846,699.392334 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M95.989059,845.629272 
	C95.737595,846.492676 95.476738,846.987854 95.107666,847.741455 
	C91.081879,848.149780 92.203712,845.841919 92.964828,843.369995 
	C94.605392,842.962219 96.002937,843.175964 95.989059,845.629272 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M99.291931,212.792435 
	C94.996239,212.088165 92.360649,210.508957 95.344658,205.925186 
	C96.503075,204.145721 95.891823,202.063934 95.988213,200.114548 
	C96.139732,197.050034 95.453812,193.770767 99.809929,192.644348 
	C101.698914,192.155869 100.844048,190.224976 100.430817,188.809967 
	C99.891617,186.963593 100.636833,185.219833 102.424011,186.510864 
	C105.751633,188.914703 108.340477,186.781372 111.589722,186.022034 
	C114.211868,188.164291 114.287949,190.398132 112.190132,192.769318 
	C108.449448,196.997482 108.467529,197.013474 114.602890,198.783951 
	C115.162453,198.961395 115.381844,199.049500 115.910187,199.315826 
	C117.687302,200.771698 119.006981,202.135590 118.956497,204.624603 
	C118.847443,205.199417 118.776840,205.422623 118.530731,205.947617 
	C117.606537,207.110687 116.580078,207.673431 115.641129,208.834625 
	C114.142136,210.580414 112.689278,211.757141 110.266129,211.850876 
	C109.679077,211.752182 109.451080,211.681717 108.921051,211.422745 
	C106.205635,209.051529 107.363510,205.409714 105.027611,202.712784 
	C103.202103,204.890015 100.041641,205.898148 100.546371,209.916763 
	C100.619133,210.489944 100.630470,210.722061 100.582443,211.298828 
	C100.332153,212.084641 100.022903,212.407089 99.291931,212.792435 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M108.851479,210.692810 
	C109.130524,210.890671 109.560074,210.941895 109.771729,210.986450 
	C111.424866,212.314224 113.550781,213.062668 114.034050,215.122696 
	C114.957634,219.059586 117.023087,217.290329 119.352448,216.289581 
	C120.180405,216.372635 120.598640,216.581161 121.139992,217.201691 
	C121.336563,217.996704 121.282661,218.466629 121.010742,219.257629 
	C120.329659,220.640625 120.191826,221.859512 119.332840,223.164795 
	C117.357666,224.599396 115.526260,225.150391 113.167572,224.085052 
	C112.469635,223.587021 112.121445,223.246460 111.608032,222.570557 
	C110.306198,221.562576 111.281509,217.966568 108.021347,220.265640 
	C107.526482,220.896423 107.188667,221.203568 106.506264,221.623779 
	C105.537079,221.984756 104.864708,222.017990 103.870926,221.818817 
	C103.377136,221.619644 103.195389,221.511765 102.820740,221.160736 
	C102.394241,220.376633 102.270409,219.796722 101.896378,219.017578 
	C101.645607,218.631256 101.536781,218.482544 101.252106,218.119904 
	C100.835747,217.622467 100.603416,217.332397 100.133469,216.534729 
	C99.921021,215.261627 99.954254,214.489670 100.009750,213.331909 
	C99.880165,212.795547 99.728310,212.644974 99.807755,212.272156 
	C100.019814,211.876282 100.000549,211.702637 99.964417,211.267517 
	C100.091949,209.583572 99.281975,207.998840 100.467239,206.763138 
	C101.605125,205.576797 102.959946,204.480591 104.666969,204.510086 
	C106.747398,204.546005 106.640236,206.204865 106.689835,207.736160 
	C106.730156,208.980774 107.737274,209.713196 108.851479,210.692810 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M115.250488,199.674545 
	C113.301323,199.622208 111.560104,199.263611 109.808174,198.968552 
	C108.262337,198.708191 107.544510,197.590118 107.381119,196.275360 
	C107.211945,194.913956 107.841591,193.579910 109.237450,193.176575 
	C112.896591,192.119308 112.931160,189.657639 111.975624,186.343826 
	C111.959290,185.972565 111.985519,185.979553 111.985275,185.970810 
	C113.697624,184.947739 115.434013,183.946487 114.238922,181.218857 
	C112.882942,180.943405 111.945908,180.531693 111.173775,179.255249 
	C112.012230,173.582565 112.992622,168.197830 113.970551,162.425873 
	C118.250481,163.080368 119.425529,164.785645 118.299339,168.406479 
	C118.003571,169.357437 117.604012,170.294632 117.105247,171.149399 
	C115.169205,174.467270 115.883667,176.778473 119.846695,178.292694 
	C120.406288,178.928116 120.597458,179.367905 120.725693,180.209305 
	C120.656715,181.542831 120.328751,182.452866 119.931595,183.712509 
	C119.541679,184.709824 119.185677,185.323822 118.590729,186.203949 
	C115.090759,190.264511 113.675529,194.467606 115.250488,199.674545 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M74.272690,256.699951 
	C71.861221,256.842743 72.022415,255.238373 71.998512,253.373993 
	C72.479156,251.978897 73.194992,251.245468 74.685432,251.040970 
	C75.792809,250.250137 76.374672,249.457993 75.235840,248.182648 
	C72.415871,248.248840 69.896484,247.939972 68.603951,245.361221 
	C67.740746,243.639023 67.140045,240.485535 69.480087,240.434235 
	C73.568382,240.344635 75.060059,237.458054 77.695328,235.168976 
	C78.789391,234.631699 79.398163,234.974335 80.140717,235.798264 
	C79.812958,239.726685 76.980598,241.491867 74.662941,243.592941 
	C76.808647,244.576172 79.174278,243.985764 81.866264,243.618256 
	C83.384949,244.917633 84.278381,246.262039 84.537567,248.286926 
	C83.930992,250.250626 81.783569,250.226273 81.011658,252.100555 
	C80.474518,252.958755 80.068321,253.497025 79.370483,254.230850 
	C78.549576,254.813507 77.921227,255.026657 76.971138,255.290771 
	C75.811562,255.494385 75.092758,255.874557 74.272690,256.699951 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M81.573654,233.411591 
	C81.939774,230.140717 83.865532,228.911972 86.901520,228.955444 
	C92.466286,229.035095 95.374397,225.641510 96.983093,220.378632 
	C99.144493,219.527664 99.794441,221.204498 100.815323,222.951797 
	C101.456429,225.214783 101.478943,227.096390 100.816727,229.343933 
	C99.081833,231.911285 97.087845,232.962067 94.281265,232.336609 
	C90.090340,231.402618 86.383308,234.660797 81.573654,233.411591 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M100.265228,223.226074 
	C99.168091,222.091232 98.359497,221.183151 97.270012,220.142593 
	C96.935120,218.254898 97.118690,216.632355 99.539917,216.049332 
	C99.887794,216.033600 100.005997,215.942688 100.080307,215.936584 
	C100.562698,216.487244 101.021698,216.998535 101.792183,217.688080 
	C102.056633,217.908356 102.043976,217.959824 102.069077,217.951309 
	C102.452347,218.392685 102.802994,218.846832 102.945343,219.713837 
	C102.963226,220.169556 102.965904,220.338287 102.993614,220.758942 
	C103.395012,221.787796 103.079193,222.420914 102.299202,223.232346 
	C101.516899,223.578583 101.035767,223.588547 100.265228,223.226074 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M114.007774,181.332932 
	C114.789337,181.177139 115.559807,181.372986 116.330284,181.568832 
	C115.620773,183.444107 115.290489,185.631348 112.348007,185.965149 
	C112.686508,184.550781 114.213737,183.527847 114.007774,181.332932 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M80.302361,236.061172 
	C79.463005,235.709442 78.983208,235.461884 78.254013,235.110764 
	C78.081459,233.572571 78.920151,232.995895 80.618706,232.980835 
	C80.967041,232.966415 80.988602,232.988434 80.998566,232.998489 
	C81.347275,233.386948 81.564812,233.827255 81.647583,234.694214 
	C81.421623,235.541031 81.109970,235.912506 80.302361,236.061172 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M75.022171,248.335571 
	C76.017433,248.114594 77.459152,247.639236 77.552025,249.217514 
	C77.612335,250.242401 76.636726,250.713699 75.342484,250.971710 
	C75.006775,250.244385 75.009109,249.481064 75.022171,248.335571 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M99.819855,212.148102 
	C100.039070,212.049911 100.057159,212.498749 100.044586,212.722427 
	C99.861954,212.961349 99.691895,212.976608 99.265839,212.993637 
	C99.148972,212.700119 99.345901,212.450394 99.819855,212.148102 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M120.179367,179.062256 
	C112.951645,176.946121 112.529251,176.725311 116.333107,169.448700 
	C118.237686,165.805328 117.269501,164.185638 114.282501,162.177063 
	C113.968086,162.038651 113.965004,162.025345 113.957932,162.028015 
	C113.052139,161.075348 112.664726,159.526230 113.522621,158.909866 
	C117.158768,156.297531 116.535072,151.797318 118.755768,148.248077 
	C118.996239,147.997147 119.049316,147.544586 119.069565,147.317657 
	C121.522789,145.579239 121.394318,143.485046 120.556198,141.128235 
	C119.946007,139.412384 118.013092,137.430740 120.302940,135.888062 
	C122.196014,134.612686 123.976700,136.303574 125.916229,137.500946 
	C127.297134,138.865692 127.395866,141.512680 130.063385,140.313889 
	C131.981735,139.451767 134.233597,137.967331 135.835480,140.125443 
	C137.330338,142.139343 136.045670,144.498703 134.823822,146.866409 
	C133.645599,148.246490 132.385422,148.951736 130.891052,149.925201 
	C128.763901,150.830429 129.502335,152.152084 129.772385,153.417816 
	C130.094070,154.925446 130.028763,156.363953 128.678802,157.462952 
	C127.476143,158.442047 125.030548,158.054169 125.022675,160.256805 
	C125.013344,162.871841 125.150528,165.549271 126.919403,167.778244 
	C127.327370,168.292328 126.890640,167.590363 127.421021,168.477448 
	C127.722107,169.013275 127.824814,169.240112 128.035675,169.829208 
	C128.349289,171.198669 128.337326,172.216446 128.249985,173.613770 
	C127.924644,175.270676 127.317261,176.288864 125.671249,176.814636 
	C124.725548,176.984619 124.096596,177.017441 123.283127,177.449982 
	C122.912537,177.774567 122.769379,177.912262 122.400063,178.249054 
	C121.663864,178.799911 121.103188,179.013412 120.179367,179.062256 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M126.531876,137.644089 
	C123.899963,138.966522 122.072708,135.124069 120.430763,137.122772 
	C119.168968,138.658722 122.015472,140.351257 122.151665,142.438110 
	C122.295074,144.635696 124.066849,147.172729 119.472313,147.068573 
	C119.960724,144.390106 120.103073,141.591141 118.437172,139.276581 
	C117.040672,137.336319 118.326927,135.529144 117.948715,133.331436 
	C117.941803,132.521194 117.958015,132.086761 117.975388,131.325867 
	C119.843079,130.886826 122.088165,131.485626 121.888359,128.242889 
	C121.505646,122.031624 121.568565,122.027748 128.588608,122.019150 
	C129.169006,122.200348 129.313568,122.376030 129.538742,122.882843 
	C129.511749,125.715637 129.838959,127.940147 132.222275,129.522522 
	C133.631561,130.458221 134.202408,132.034836 133.320862,134.076904 
	C132.568909,134.777008 131.931808,135.000351 130.939041,135.211853 
	C129.101456,135.347275 127.626991,135.493393 126.531876,137.644089 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M118.639679,148.040710 
	C118.855408,150.112732 117.080322,152.082199 119.233337,154.385391 
	C120.854576,156.119736 119.176674,156.929031 117.471176,156.982224 
	C114.633423,157.070709 113.896294,158.779816 113.969116,161.625671 
	C111.275429,161.518478 112.479385,159.878891 112.695442,158.288666 
	C113.260704,154.128311 115.160240,150.795471 118.639679,148.040710 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M132.700073,115.878769 
	C132.828720,116.736198 132.824432,117.683624 131.754486,117.573273 
	C130.700150,117.464531 130.297974,116.598587 130.042999,115.335442 
	C130.809113,115.255554 131.609482,115.501152 132.700073,115.878769 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M129.141083,123.330246 
	C129.042007,122.860657 129.038315,122.693344 129.029541,122.275360 
	C130.290497,121.236145 131.645996,120.843376 133.500900,121.134216 
	C133.894653,121.581001 133.934174,121.986504 133.824158,122.967606 
	C133.049072,124.148621 132.284744,124.464348 131.123535,124.615097 
	C130.157562,124.489090 129.635056,124.159622 129.141083,123.330246 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M78.244614,262.719727 
	C77.509140,263.267365 77.014557,263.532318 76.263924,263.913727 
	C75.601730,264.393280 75.129486,264.533112 74.285126,264.214996 
	C74.446907,261.936676 75.319717,259.960144 75.015114,257.383789 
	C75.200562,256.802856 75.378555,256.625000 75.904053,256.296875 
	C77.014626,255.981064 77.759117,256.046875 78.802666,256.536194 
	C79.240227,256.968140 79.361610,257.189819 79.545807,257.784668 
	C79.535469,259.663269 79.395752,261.151245 78.244614,262.719727 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M76.249763,256.596710 
	C76.019150,256.934326 75.520416,256.953949 75.271500,256.967346 
	C75.022575,256.980713 74.538338,256.962189 74.297531,256.940918 
	C74.062515,255.793976 73.865410,254.577255 75.657639,254.044418 
	C76.572327,254.655167 76.728325,255.413925 76.249763,256.596710 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M73.864197,264.188416 
	C74.430031,263.990356 74.880936,264.000275 75.669861,264.020172 
	C75.889175,265.489655 75.005905,266.020142 73.289642,265.965546 
	C72.932030,265.958130 72.974022,265.463837 72.987640,265.215912 
	C73.300217,264.842377 73.549545,264.651825 73.864197,264.188416 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M72.942154,264.860596 
	C73.032791,265.000214 72.577034,264.976593 72.350342,264.979187 
	C72.123650,264.981750 72.426163,264.625763 72.426163,264.625763 
	C72.426163,264.625763 72.851509,264.721008 72.942154,264.860596 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M277.651917,50.044594 
	C276.145203,54.614944 270.985504,55.009853 267.550720,58.133751 
	C266.784943,58.023048 266.482666,57.686913 266.223022,56.899570 
	C266.108337,55.575783 266.434113,54.709057 267.499634,53.473846 
	C271.553741,53.384899 273.425507,48.740417 277.651917,50.044594 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M265.582153,47.037621 
	C265.956879,48.622768 266.194946,50.016899 268.630890,49.989044 
	C268.685638,50.798103 268.372498,51.597336 268.051086,52.721169 
	C268.042786,53.045765 268.037170,53.021942 268.040649,53.031189 
	C265.895264,54.706734 263.268036,54.818752 260.385071,55.150482 
	C260.020142,55.015423 259.997528,54.996975 259.986053,54.985985 
	C261.526306,52.203300 260.898102,47.995987 265.582153,47.037621 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M209.403259,104.201218 
	C207.724380,102.888000 208.251007,101.239227 207.899643,99.447350 
	C207.296646,97.679962 206.216446,96.629593 205.465942,95.373199 
	C202.690216,90.726479 204.037231,87.023376 209.135757,85.228531 
	C210.105682,84.887077 210.857727,84.324059 211.693542,83.237457 
	C211.928864,82.956802 212.025299,82.536720 212.061325,82.324249 
	C216.358093,80.960571 220.666397,83.898949 219.574814,87.967979 
	C218.114670,93.410866 220.581100,93.762482 224.914154,93.553802 
	C225.967819,94.421783 226.275864,95.353386 226.729248,96.606720 
	C226.267273,102.202820 222.980652,105.173927 218.762451,103.199234 
	C215.018768,101.446678 212.403366,101.700111 209.403259,104.201218 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M225.284088,93.836594 
	C223.989044,94.125099 222.884949,94.126968 222.122131,94.638115 
	C220.761292,95.549988 219.629395,96.222977 218.239487,94.738808 
	C216.947067,93.358727 216.799591,91.948662 217.511078,90.207321 
	C219.416901,85.542870 218.302017,83.682159 212.459686,82.096924 
	C212.286285,80.429840 210.984940,78.014961 213.324081,77.337578 
	C215.391190,76.738983 216.272293,78.945709 217.296112,80.446785 
	C217.943268,81.395660 218.772644,82.231750 219.588409,83.050545 
	C220.265106,83.729744 220.915146,84.759209 222.021484,84.444420 
	C223.269302,84.089355 223.104065,82.804604 222.946182,81.916115 
	C221.901993,76.040123 227.060501,73.741791 229.745544,70.036110 
	C230.041916,69.627098 231.286331,69.298462 231.421432,69.467560 
	C235.111374,74.085815 237.528595,69.881958 240.610291,68.092056 
	C242.412231,66.788727 243.852982,67.098030 245.578812,68.308754 
	C247.318527,70.144829 247.039902,72.278679 245.204971,72.427536 
	C241.044678,72.765038 238.067337,76.246353 233.489700,75.757683 
	C228.981949,75.276474 226.592072,79.210220 227.570389,84.510109 
	C227.916107,85.522118 227.963486,86.221939 227.817902,87.271912 
	C226.962265,89.030273 226.944977,90.606308 226.709778,92.488052 
	C226.362289,93.222992 226.033340,93.539375 225.284088,93.836594 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M227.345612,85.119247 
	C224.148193,83.456535 224.733795,81.189461 226.024887,79.075302 
	C227.885651,76.028297 230.093979,73.518486 234.337769,74.011551 
	C238.840866,74.534729 242.321457,71.729439 246.056122,69.409103 
	C246.183914,68.820938 246.282074,68.596100 246.721436,68.233444 
	C247.550323,67.837173 248.037994,67.578720 248.777939,67.208290 
	C249.984299,66.750725 250.397980,67.367760 250.857803,68.438141 
	C251.124344,70.935501 249.796982,72.582672 248.998596,74.789635 
	C248.088516,76.708237 247.157806,78.157768 245.407776,79.401558 
	C240.637833,79.174377 240.637833,79.174377 234.639267,84.729332 
	C232.115524,85.289154 229.847534,84.618195 227.345612,85.119247 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M250.281891,69.231125 
	C249.826080,68.490997 249.655792,67.960350 249.257843,67.263008 
	C250.620163,63.582386 252.752945,60.732964 256.857330,59.846226 
	C258.888611,59.407387 259.303253,57.411495 259.973694,55.375313 
	C262.070251,53.084728 264.558502,52.726051 267.634583,53.023849 
	C267.777588,53.831383 267.511078,54.622326 267.121643,55.705978 
	C265.958130,57.217022 264.641907,58.129574 263.250885,59.452225 
	C262.890106,59.875225 262.736908,60.035404 262.329468,60.406494 
	C261.362671,61.112885 260.566772,61.448799 259.503113,61.949478 
	C258.462524,62.408279 257.704956,62.731384 257.034912,63.712227 
	C256.689789,64.112434 256.544830,64.268166 256.165253,64.642860 
	C255.030777,65.487411 254.303345,66.265556 253.632248,67.542862 
	C252.721832,68.784241 251.947418,69.654785 250.281891,69.231125 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M211.596893,83.000473 
	C211.984009,84.310875 211.921616,85.391922 210.237915,85.997276 
	C204.418396,88.089592 203.645584,91.173698 207.783066,95.444855 
	C208.751724,96.444824 208.940231,97.335449 208.027222,98.765717 
	C203.834137,97.885277 201.132324,90.935371 203.299088,87.581169 
	C203.792877,86.816757 203.807388,85.742729 204.069183,84.453369 
	C206.494675,83.807297 209.118378,85.052498 211.596893,83.000473 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M246.755341,67.916611 
	C246.937668,68.388527 246.747086,68.629532 246.288330,68.932213 
	C244.617462,68.694183 243.149170,68.342583 241.298676,67.965248 
	C241.193985,66.714325 240.422577,64.826714 242.007858,64.421326 
	C244.516312,63.779873 244.572067,67.112442 246.755341,67.916611 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M326.223633,982.010742 
	C325.567902,982.273926 325.159332,982.262573 324.445282,982.281616 
	C319.438110,982.058289 315.342804,979.720154 311.669922,977.359985 
	C307.776215,974.858032 303.676819,974.326355 299.197357,973.698914 
	C298.495728,973.920654 298.076355,973.936096 297.342407,973.961548 
	C296.289917,973.062073 295.151703,973.026855 293.867126,972.521423 
	C291.026001,970.776917 287.011627,972.917542 285.267944,968.807312 
	C285.243561,968.246033 285.271759,968.020081 285.447571,967.483398 
	C286.940277,965.774292 288.660828,967.909912 290.272705,966.482788 
	C290.838074,965.954590 291.159668,965.648254 291.731812,965.113403 
	C296.302582,961.281616 296.302582,961.281616 301.102997,965.701233 
	C302.676392,962.558289 304.256989,959.566650 304.059753,955.998596 
	C303.936981,953.777954 304.533630,951.985657 306.804504,951.280701 
	C309.014038,950.594788 310.743713,951.536377 311.885132,953.420349 
	C313.026184,955.303711 314.710754,956.668823 316.325500,958.582642 
	C316.647949,959.355286 316.702454,959.827026 316.572235,960.652710 
	C311.610748,965.781128 311.620575,966.158386 315.188568,972.182068 
	C316.163971,973.828857 317.415894,974.824158 319.182098,974.356628 
	C323.870605,973.115479 325.346252,976.086914 326.612427,979.953247 
	C326.723175,980.798157 326.641937,981.274353 326.223633,982.010742 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M299.272278,973.959106 
	C299.538971,971.290710 301.151367,972.328308 302.747192,972.765015 
	C304.580750,973.266907 306.344208,974.523438 308.427979,973.421021 
	C308.902405,973.169983 310.119324,973.266663 310.215515,973.510620 
	C312.607971,979.576721 319.945923,977.730225 323.817474,982.092407 
	C322.940460,984.774292 321.134857,985.500977 318.321808,984.078430 
	C317.029572,983.048584 316.160065,981.981140 314.333191,982.002930 
	C313.450775,981.813354 312.899323,981.631592 312.181213,981.203674 
	C311.116852,980.145386 310.290466,979.259766 309.190979,978.221313 
	C308.534851,977.997009 308.098328,977.980713 307.334656,977.947144 
	C306.316956,977.115784 305.209534,977.030701 304.164490,976.235840 
	C302.167358,976.069153 301.520935,973.716614 299.272278,973.959106 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M325.951599,982.472900 
	C326.011139,981.815247 326.045776,981.345154 326.102386,980.524597 
	C326.240997,979.958435 326.357635,979.742859 326.755310,979.290100 
	C328.345154,978.328003 329.713348,977.908325 331.552643,978.369263 
	C332.238403,978.778625 332.544434,979.105225 332.924500,979.776733 
	C333.338196,980.573853 333.516296,981.157898 334.394867,981.478149 
	C335.748352,981.563782 336.794067,981.311462 338.194824,981.452515 
	C338.970734,981.759949 339.333038,982.074707 339.828064,982.788513 
	C340.449005,984.635864 341.015747,986.127991 340.642273,988.138977 
	C339.803345,989.591492 338.822845,989.849243 337.305634,989.250732 
	C337.431824,985.799988 335.937622,984.623779 332.488342,985.072510 
	C330.292847,984.301270 329.461151,981.049561 326.277313,982.994873 
	C325.936646,983.039001 325.926666,982.660522 325.951599,982.472900 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M285.645660,967.833557 
	C286.842346,970.612854 288.900146,970.801941 291.057983,970.412720 
	C292.255463,970.196716 293.291168,969.843079 293.820557,971.874390 
	C293.392853,972.744995 293.013855,972.918091 292.280334,973.044495 
	C290.941956,973.084961 289.916809,973.081665 288.507202,973.080505 
	C285.992279,974.349915 284.898499,972.573547 283.282990,971.261536 
	C282.456604,970.822693 281.903290,970.649658 281.163452,970.247559 
	C280.976898,970.018616 281.072449,969.941162 281.119263,969.901245 
	C282.225555,968.470520 283.054077,966.655701 285.645660,967.833557 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M283.046753,971.341370 
	C284.477936,971.651489 285.945953,972.307129 287.768311,973.022766 
	C285.424103,976.295776 284.373047,973.561096 283.046753,971.341370 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M314.196960,982.258301 
	C316.041382,980.137268 317.100616,981.323242 317.964844,983.588745 
	C316.599396,983.983704 315.483337,983.284180 314.196960,982.258301 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M309.017700,978.350342 
	C310.499939,978.065369 311.564453,978.720154 311.970337,980.622742 
	C310.472778,980.926453 309.425110,980.253174 309.017700,978.350342 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M294.040375,971.958740 
	C295.160248,972.078430 296.412781,971.899963 296.970032,973.635864 
	C296.269287,974.001343 295.510742,974.031189 294.373077,974.042236 
	C293.619751,973.635803 293.473236,973.175720 293.769165,972.330078 
	C293.983734,972.017090 294.021515,971.978210 294.040375,971.958740 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M304.017212,976.372131 
	C305.075562,976.056763 306.330841,975.801086 306.941254,977.593750 
	C305.851624,977.966370 304.630127,978.159058 304.017212,976.372131 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M293.680725,972.039368 
	C293.988068,972.462646 293.992371,972.908264 293.995361,973.688599 
	C293.442780,973.860657 292.891541,973.697876 292.153687,973.311768 
	C292.437286,972.746094 292.907501,972.403870 293.680725,972.039368 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M251.126953,949.329468 
	C251.998840,946.683105 253.948578,944.515930 252.472672,941.650940 
	C249.979843,941.557983 248.630081,945.342896 245.993027,943.750122 
	C243.771576,942.408264 246.354507,940.455627 246.230927,938.137695 
	C246.927750,937.298157 247.390442,937.175598 248.233734,937.130859 
	C250.239166,937.409790 251.887634,937.660339 253.886383,938.013672 
	C254.455002,938.223999 254.672989,938.333740 255.165512,938.687012 
	C257.363922,942.463379 256.937622,946.433472 257.362152,950.530518 
	C256.890076,951.055725 256.594788,951.262756 256.156403,951.699646 
	C253.932983,952.202576 252.468796,951.210938 251.126953,949.329468 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M260.770752,959.713257 
	C262.712189,960.227417 264.239471,961.187378 266.065826,959.716614 
	C267.545959,958.524475 267.834473,960.156616 267.928253,961.689026 
	C266.615845,962.074463 265.331085,961.871277 264.150970,962.821167 
	C261.378448,965.052979 260.420258,963.388489 260.011688,960.371094 
	C260.173981,959.813110 260.341827,959.631104 260.770752,959.713257 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M261.025879,959.604858 
	C260.860870,959.981934 260.689911,959.986450 260.262512,959.993042 
	C259.693176,958.891968 259.380280,957.788879 259.036316,956.340454 
	C261.100494,956.188171 261.006683,957.743530 261.025879,959.604858 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M357.212189,988.706421 
	C355.916290,990.907166 353.100342,989.080139 351.991119,991.547424 
	C351.874542,991.878662 351.423431,991.935730 351.200623,991.982361 
	C350.538055,992.025208 350.098297,992.021484 349.328491,992.018311 
	C347.853821,991.530762 347.254059,990.525330 346.497009,989.207397 
	C346.237610,988.410828 346.179382,987.933594 346.209167,987.082642 
	C346.498779,985.748779 346.615845,984.760498 346.743530,983.378540 
	C347.692902,979.916626 349.673645,979.444336 352.655090,980.899719 
	C354.639008,982.890320 353.389801,984.707458 352.974487,986.496460 
	C354.520386,987.127747 357.183167,985.057495 357.212189,988.706421 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M347.048035,986.674072 
	C347.149689,987.439636 347.142639,987.881897 347.126282,988.655762 
	C347.003052,990.104187 346.909454,991.221924 346.993958,992.682373 
	C346.792542,994.353821 346.489807,995.773987 344.735199,995.550720 
	C343.376770,995.377808 343.039764,994.137573 343.034149,992.943604 
	C343.021179,990.172119 342.093689,990.002991 339.803009,991.338196 
	C338.302612,992.212769 336.654510,991.699036 336.756165,989.201660 
	C337.840454,988.839783 338.706299,988.679993 339.786072,988.263794 
	C340.310669,987.697571 340.637054,987.405273 341.267761,986.970581 
	C342.822937,986.441711 343.829590,985.403076 345.566528,985.443848 
	C346.315826,985.700256 346.661865,985.983643 347.048035,986.674072 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M347.314575,992.992126 
	C343.965637,992.183472 344.934723,990.772278 346.800537,989.132690 
	C348.098053,989.503906 348.794525,990.240662 348.983582,991.700134 
	C348.535492,992.334595 348.072479,992.650208 347.314575,992.992126 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M257.483887,950.858643 
	C254.819977,947.482727 255.883560,943.443298 255.013000,939.387695 
	C256.883850,937.869202 258.136230,939.184937 258.370605,940.531494 
	C259.292023,945.824829 262.468536,946.406433 267.142700,945.146606 
	C268.126068,945.496338 268.625214,945.955078 269.294525,946.723267 
	C270.175995,947.824219 270.929962,948.577881 272.148621,949.304138 
	C272.780212,949.753052 273.118286,950.042603 273.665466,950.608154 
	C274.468933,951.593567 274.824127,952.436768 275.230225,953.634399 
	C275.611908,956.287781 279.233398,956.832520 278.200378,960.035278 
	C275.532501,961.161621 273.143524,960.461792 270.380615,959.940430 
	C269.726318,959.405823 269.435059,958.943420 269.115723,958.195190 
	C268.942596,957.788757 268.797577,957.668030 268.594116,957.275391 
	C268.525970,956.822693 268.516327,956.641907 268.524506,956.200317 
	C268.511261,955.501099 268.241943,955.413147 267.560547,955.530457 
	C265.488708,956.059204 264.408630,955.085510 263.586639,953.748596 
	C262.660065,952.241577 261.332214,951.386536 259.316528,950.935608 
	C258.581848,950.871826 258.182159,950.870178 257.483887,950.858643 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M270.145142,960.200684 
	C272.435059,958.148804 274.847900,959.180115 277.648865,959.914917 
	C279.658600,959.450378 281.303864,959.514954 283.378204,959.830383 
	C286.797028,961.127808 287.792297,963.234558 286.348938,966.629333 
	C286.035583,967.151428 286.017212,967.340393 285.960083,967.812500 
	C284.086945,967.537964 283.137054,969.125610 281.486664,969.826599 
	C281.009308,965.142517 279.860565,961.526062 273.841217,962.798340 
	C272.123688,963.161377 271.179352,961.743713 270.145142,960.200684 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M259.129639,951.202271 
	C261.593781,949.184387 263.439850,949.553955 264.427887,952.749573 
	C264.818726,954.013550 265.594940,955.082397 267.593872,954.840576 
	C268.094147,954.930603 268.221954,955.049744 268.161499,955.597900 
	C262.577820,959.357666 261.755249,953.843872 259.129639,951.202271 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M268.831909,955.814575 
	C269.098480,956.175476 269.094696,956.351501 269.081146,956.791321 
	C268.904205,957.156189 268.737030,957.257263 268.320526,957.204468 
	C268.053589,956.880127 268.036011,956.709473 267.995819,956.282837 
	C267.973236,956.026855 267.993713,956.006165 268.003418,955.996948 
	C268.195892,955.868469 268.378723,955.749084 268.831909,955.814575 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M268.184814,957.252441 
	C268.071167,957.050720 268.570496,957.037170 268.820923,957.046143 
	C269.071350,957.055176 269.086670,957.483032 269.087158,957.696289 
	C268.763428,957.863525 268.500366,957.711731 268.184814,957.252441 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M38.027008,609.409668 
	C39.983768,607.398743 38.703911,605.377686 38.891914,603.280457 
	C42.172562,602.206848 40.586269,599.630188 41.027168,597.393677 
	C44.582691,598.520874 43.980713,602.510803 45.788490,605.118530 
	C47.322021,607.330627 48.062050,610.088623 50.592575,611.967590 
	C51.281929,612.316467 51.515820,612.673645 51.663368,613.433228 
	C51.565578,614.825317 51.417358,615.892456 51.911137,617.260498 
	C52.155876,618.255066 52.240299,618.936951 52.318504,619.978027 
	C51.417023,626.497131 53.723934,632.174561 55.882706,637.567261 
	C57.429768,641.431824 58.729321,645.234497 59.628029,649.619751 
	C59.987675,652.872070 60.731487,655.735962 60.327934,658.994446 
	C59.456333,659.751404 58.727467,659.890076 57.587021,659.728882 
	C52.069469,656.162842 49.267979,651.729431 51.448105,645.312134 
	C51.966301,643.786804 51.353561,642.767578 49.943550,641.639038 
	C49.443230,640.952332 49.261345,640.506348 49.107513,639.676025 
	C50.977047,634.179626 48.297050,630.627258 44.884571,627.365234 
	C43.369907,625.917297 41.437027,624.857849 40.569336,622.387451 
	C40.291687,621.635498 40.246166,621.183228 40.355202,620.398438 
	C40.967842,618.808105 42.325867,617.507568 39.988251,616.187622 
	C39.153870,615.627869 38.774418,615.095459 38.587517,614.170227 
	C38.473419,612.508057 38.259487,611.164673 38.027008,609.409668 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M38.449165,614.091919 
	C39.060436,614.128723 39.433521,614.406067 40.017353,614.792603 
	C41.137493,616.228271 41.527054,617.657471 40.995705,619.620483 
	C40.895641,620.432922 40.887741,620.875427 40.873390,621.649719 
	C40.823963,622.406738 40.645779,622.764709 40.025478,623.101013 
	C35.740692,621.465088 37.652687,618.396179 37.938812,615.322632 
	C38.012615,614.737976 38.111797,614.535217 38.449165,614.091919 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M56.825851,658.595703 
	C57.826115,658.890320 58.523769,659.019958 59.576050,659.165283 
	C60.308483,658.770508 60.724606,658.748718 61.406006,659.346680 
	C61.847397,660.084229 61.991322,660.553467 62.090042,661.409851 
	C62.054367,662.772522 61.777924,663.691772 60.913864,664.747864 
	C60.444576,665.105835 60.231632,665.212402 59.441357,665.022888 
	C58.566273,658.247131 54.384205,662.128418 51.294285,662.105103 
	C50.475212,662.150879 50.053120,662.129272 49.313271,662.111450 
	C43.626438,661.544556 40.532986,657.536499 41.553455,652.332092 
	C41.808308,651.032349 42.242603,649.752869 42.007565,648.435120 
	C41.388561,644.964600 42.277157,642.230408 45.596161,640.587646 
	C46.797310,639.993103 46.946728,638.804382 47.138451,637.348755 
	C46.982430,636.552795 46.949173,636.112854 46.834663,635.351440 
	C46.753414,635.029968 47.068417,634.968994 47.226711,634.942993 
	C50.370842,634.551819 49.812767,636.588074 49.812355,638.688965 
	C49.982098,639.460571 50.034546,639.869080 50.147499,640.580872 
	C51.351025,642.593262 50.320564,643.975525 49.640018,645.504456 
	C47.092377,651.227600 50.363205,657.296082 56.825851,658.595703 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M46.825005,637.316772 
	C48.600758,639.329041 48.738472,641.247253 45.559437,642.300720 
	C44.946510,642.503784 44.104736,642.790588 43.882233,643.274841 
	C42.122601,647.104370 44.554047,651.341919 42.668110,655.472961 
	C42.215412,656.464600 44.795246,661.144836 48.778633,661.824158 
	C46.868275,664.196594 44.205338,664.662231 41.679184,663.541077 
	C38.961750,662.334961 40.116589,659.484863 40.025578,657.306213 
	C39.840984,652.887390 40.296860,648.523865 37.830379,644.340576 
	C35.662525,640.663757 39.463219,640.031250 41.662903,639.147095 
	C43.234966,638.515137 45.348396,639.461914 46.825005,637.316772 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M54.965996,674.686279 
	C54.188927,675.334839 53.438622,675.646545 52.385921,675.992920 
	C52.129562,673.799255 52.253162,671.599792 54.645149,670.056763 
	C55.868893,670.723572 55.587658,671.542542 55.094856,672.684692 
	C54.995399,673.448853 54.994080,673.899109 54.965996,674.686279 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M57.162926,669.421326 
	C57.219742,668.618774 57.249352,668.225403 57.294674,667.536621 
	C57.619511,666.734253 58.070442,666.458923 59.074230,666.483704 
	C61.420326,668.146912 61.430882,669.754883 59.114357,671.439453 
	C57.777672,671.324036 57.338711,670.688049 57.162926,669.421326 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M56.893585,669.079163 
	C57.451954,669.486755 57.713787,669.961426 58.001289,670.721436 
	C58.138145,671.167297 58.249329,671.327820 58.124825,671.662292 
	C57.120388,672.149048 56.351643,672.461853 55.289806,672.886597 
	C54.012459,672.196533 55.002399,671.367004 54.981049,670.275146 
	C55.531986,669.714417 56.064518,669.430298 56.893585,669.079163 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M59.218895,666.727722 
	C58.612122,667.038818 58.236179,667.083618 57.585308,667.184814 
	C57.019676,666.245911 57.183033,665.437256 58.549152,664.860229 
	C58.864025,664.726746 58.984398,664.942627 59.044403,665.050720 
	C59.403835,665.544189 59.518860,665.978455 59.218895,666.727722 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M50.084961,638.743286 
	C48.505932,638.123657 49.535648,635.976807 47.680038,635.016663 
	C43.956741,631.866028 41.718117,628.020386 39.850685,623.492065 
	C39.948914,622.858704 40.180027,622.571289 40.639042,622.132690 
	C43.674377,625.367310 47.883259,627.308960 49.956631,631.572632 
	C51.167152,634.061890 51.521057,636.082520 50.084961,638.743286 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M206.316803,928.205688 
	C206.018723,926.516602 206.242432,924.985779 205.208832,923.286316 
	C204.752243,920.805176 204.762939,918.537537 202.320312,917.087280 
	C201.128708,916.161194 200.294769,915.274109 199.210846,914.208740 
	C197.883774,912.548157 197.744904,911.117432 199.549957,909.646606 
	C201.327881,909.455444 201.946152,910.543579 202.945068,911.653198 
	C203.886124,912.335571 204.610519,912.736145 205.731598,913.014648 
	C207.297470,913.417908 208.573517,913.041321 210.171921,913.219055 
	C211.167480,913.512512 211.725052,913.902710 212.410187,914.685486 
	C213.557281,917.037537 213.296066,919.199951 213.037247,921.343811 
	C212.789093,923.399170 213.355453,924.948853 215.723053,925.872803 
	C216.222809,926.156738 216.409821,926.293457 216.795761,926.715088 
	C216.273834,928.486633 214.769547,929.515625 214.506760,931.684265 
	C212.684998,928.039795 208.574661,931.153076 206.316803,928.205688 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M201.180969,909.624146 
	C198.870728,910.244629 198.991989,911.801575 198.970657,913.656128 
	C195.869415,914.088013 193.510834,914.153503 192.734406,909.724854 
	C191.773224,904.242432 188.201965,908.777954 185.401337,908.978760 
	C185.327850,906.607788 187.481812,905.363281 189.127396,905.312317 
	C192.894943,905.195557 196.859650,904.718994 200.737595,906.854797 
	C201.588745,907.702026 201.725739,908.472046 201.180969,909.624146 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M217.256470,926.781189 
	C216.821228,926.998230 216.647797,926.996399 216.214203,926.990601 
	C213.914917,924.275024 214.817673,921.619507 216.283157,919.111694 
	C217.335953,917.310120 219.012222,915.979126 221.214813,916.560181 
	C223.530273,917.171021 224.089233,919.049133 223.322968,921.642456 
	C222.435272,922.508972 221.826126,923.008118 221.076813,923.777466 
	C218.876862,923.634766 218.111084,924.981018 217.256470,926.781189 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M38.648037,452.957336 
	C35.455074,449.888458 33.006233,446.225769 29.296778,443.919006 
	C27.719423,442.938080 28.652958,441.938324 28.919657,440.932251 
	C29.386356,439.171692 29.757944,436.966400 32.109013,437.110168 
	C36.314266,437.367310 36.144630,434.754272 36.285336,431.372375 
	C40.416061,432.530365 39.306026,435.454346 37.897575,437.849396 
	C36.219376,440.703156 36.223740,443.085175 38.355667,445.498413 
	C40.194489,447.579926 40.384068,449.895660 39.287601,452.730530 
	C39.064556,453.051636 38.778709,453.017670 38.648037,452.957336 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M35.951889,431.052032 
	C34.334263,430.790985 33.019672,431.484009 31.676748,432.116241 
	C30.808022,432.525177 29.768265,433.136444 29.245672,431.758423 
	C28.883545,430.803497 28.970366,429.469788 30.110043,429.293427 
	C33.178303,428.818573 32.428028,425.643097 34.363018,424.107666 
	C34.931755,423.872681 35.164459,423.719055 35.792389,423.703735 
	C37.024323,424.748444 36.945644,425.886383 36.985367,427.410034 
	C36.975918,428.656799 36.960243,429.487183 36.385185,430.594727 
	C36.116413,430.911163 36.005569,431.003723 35.951889,431.052032 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M36.225914,424.205627 
	C35.651253,424.053253 35.461544,424.045929 34.985443,424.032440 
	C35.128941,422.753418 35.467873,421.424225 36.994926,420.444031 
	C38.232529,418.962311 37.711754,417.187500 39.065758,415.757172 
	C42.870594,417.702576 42.904640,418.712158 39.329514,423.440613 
	C38.218510,423.981384 37.436127,424.241669 36.225914,424.205627 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M39.486259,415.637878 
	C38.808800,417.255554 39.423649,418.982880 37.561584,420.073486 
	C37.041485,417.605316 36.858219,415.010712 36.973042,412.224548 
	C38.366978,411.873688 38.841904,412.658905 39.481255,413.705780 
	C39.712505,414.454834 39.721832,414.897736 39.486259,415.637878 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M39.456043,413.973328 
	C38.576534,413.447388 38.135891,412.901764 37.483192,412.194580 
	C37.257595,411.633514 37.244057,411.233978 37.268944,410.539307 
	C37.694275,410.179688 38.081184,410.115234 38.763519,410.038330 
	C41.628593,410.847534 41.010628,412.347626 39.456043,413.973328 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M34.782364,454.749146 
	C33.842590,456.427765 33.026657,455.466095 32.059509,454.339111 
	C32.802921,454.213440 33.683266,454.352356 34.782364,454.749146 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M56.161800,304.288269 
	C57.048775,304.081055 57.698532,304.473114 57.935471,305.679016 
	C58.914543,307.154968 58.792110,308.481506 58.403912,310.137421 
	C56.816143,312.482422 55.518826,314.544098 57.287262,317.400452 
	C57.612274,318.111023 57.725540,318.539093 57.792534,319.306580 
	C57.497288,320.983154 57.826607,322.053375 59.430580,322.852417 
	C60.264229,323.390106 60.773560,323.817810 61.500729,324.518738 
	C62.703114,326.795959 65.674507,327.473816 65.295532,330.597107 
	C63.637661,330.953156 62.096790,330.777771 60.673885,331.109100 
	C58.053619,331.719147 56.694313,332.075592 57.621643,328.274536 
	C58.426346,324.976044 55.880116,326.752991 54.576546,327.209442 
	C53.656654,327.531494 52.731556,328.287231 51.828815,327.520264 
	C50.839684,326.679871 51.775139,325.699463 51.953224,324.790802 
	C53.268764,318.078400 53.121799,311.063873 56.161800,304.288269 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M65.183090,331.150085 
	C65.149292,328.041504 61.511993,328.174225 60.887390,325.453491 
	C62.662178,323.325104 64.557060,324.240875 66.815140,325.237366 
	C69.006477,327.649963 70.095787,329.876678 67.245636,332.459717 
	C66.548462,332.526520 66.266121,332.482574 65.646759,332.173828 
	C65.286781,331.739410 65.263817,331.569794 65.183090,331.150085 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M63.778881,337.390808 
	C63.967514,336.812805 63.935196,336.626892 63.822670,336.168518 
	C64.105637,335.453461 64.468803,335.010925 64.871834,334.272400 
	C65.179428,333.849213 65.447174,333.722015 66.158966,333.640991 
	C68.673416,335.236847 68.484787,337.253326 67.515915,339.745758 
	C64.974731,340.996277 63.844784,340.057617 63.778881,337.390808 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M55.781059,296.172180 
	C56.034222,298.045959 56.036903,300.060059 56.048828,302.503113 
	C55.550713,302.687012 55.043354,302.441986 54.273911,302.090546 
	C54.011833,301.984131 53.993862,301.500000 53.978786,301.258392 
	C53.989914,300.269531 54.016117,299.522278 54.042610,298.402161 
	C54.538788,297.457031 55.034683,296.884796 55.781059,296.172180 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M66.266403,333.717712 
	C65.847794,333.999817 65.660934,333.988159 65.192886,333.976440 
	C64.186455,333.418121 64.069359,332.816772 65.046265,332.032776 
	C65.496811,331.918732 65.683868,331.928436 66.151138,331.958618 
	C66.631805,332.452423 66.654083,332.934082 66.266403,333.717712 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M57.693863,370.229309 
	C56.842468,368.357117 55.562374,367.580505 53.662991,368.301544 
	C52.075153,368.904358 50.477871,369.078339 48.399582,369.022461 
	C47.840805,368.723694 47.629688,368.472626 47.124123,368.171753 
	C43.200607,363.543213 44.099392,359.380890 49.846161,355.394379 
	C50.124233,355.076904 50.228664,354.692291 50.284851,354.502930 
	C52.038239,353.625916 52.570572,352.274536 53.145668,350.570679 
	C54.607708,346.239014 56.332520,341.945068 60.462711,338.880249 
	C62.110657,339.775024 61.656673,341.056854 61.281185,342.670990 
	C61.077213,343.259827 60.976192,343.485840 60.682423,344.023438 
	C58.602776,345.942963 58.226582,347.888153 59.112942,350.169495 
	C60.228554,353.040863 59.280136,355.069427 56.421265,356.109955 
	C54.760136,356.714539 53.559635,357.770172 52.675968,359.239990 
	C51.921818,360.494354 50.228321,361.525085 50.666756,363.074005 
	C51.245152,365.117371 53.220615,363.902954 54.571133,364.070831 
	C56.449375,364.304291 58.100140,364.985596 59.892609,366.297729 
	C60.445950,366.950684 60.625580,367.401001 60.697090,368.260681 
	C60.582600,368.863281 60.487347,369.093384 60.092819,369.547119 
	C59.191471,369.897064 58.589390,370.023438 57.693863,370.229309 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M60.300007,343.232941 
	C60.294804,341.842926 60.584793,340.681915 60.833839,339.156006 
	C61.167641,337.532745 62.083542,337.048981 63.660248,337.011841 
	C64.060043,338.530701 64.750389,339.561249 66.646149,339.933319 
	C67.354111,340.337860 67.552818,340.786102 67.506012,341.681488 
	C65.575356,344.567535 63.061832,343.901031 60.300007,343.232941 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M207.578171,897.183777 
	C207.228134,895.911255 207.065903,894.924255 206.854675,893.564270 
	C206.485977,892.584290 206.071152,892.032043 205.631012,891.096802 
	C204.743851,889.602295 204.386108,888.206726 203.280075,886.814697 
	C202.524551,884.837708 202.598633,883.105774 202.521530,881.027222 
	C202.473724,877.637939 200.939545,875.156067 199.542953,872.228149 
	C199.713913,870.697632 200.508591,870.025574 201.809235,869.287170 
	C203.519104,868.666077 204.895691,868.584045 206.707794,868.711548 
	C209.261765,869.186584 210.647537,870.380554 211.043274,872.997864 
	C211.023041,873.656494 210.986145,873.914062 210.828110,874.533203 
	C209.363754,877.259949 206.204071,879.713989 208.417007,882.360291 
	C210.597504,884.967957 214.371613,884.936279 217.748444,883.953979 
	C219.346329,883.489258 220.855377,882.753967 222.799683,882.184509 
	C223.866745,881.978088 224.579788,882.011597 225.615677,882.320068 
	C230.254807,886.416504 226.304230,889.133972 224.240021,892.009338 
	C225.644363,893.345337 227.391144,892.504272 228.922073,893.596313 
	C230.119568,898.052185 225.279938,897.419495 223.771744,900.055664 
	C223.225433,900.306824 222.993011,900.370850 222.404266,900.395020 
	C219.483002,897.410828 217.127487,895.420715 213.472412,899.436462 
	C211.013229,902.138245 209.139557,899.738159 207.578171,897.183777 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M207.253998,897.255737 
	C210.739929,900.030579 212.967270,898.656677 216.007019,895.793945 
	C219.860703,892.164551 220.040924,897.771851 222.016693,899.457458 
	C221.872955,900.211182 221.585281,900.553711 220.898468,900.963745 
	C218.098755,902.690247 218.832199,906.416626 215.908295,908.094666 
	C214.932251,909.144775 214.334671,910.053833 213.754944,911.388062 
	C212.821777,912.742981 211.747131,913.178406 210.134735,913.340393 
	C208.789917,913.448853 207.827362,913.761658 206.476715,913.546631 
	C205.201172,912.940552 204.387543,912.333862 203.537811,911.186523 
	C202.919556,909.872803 202.853729,908.865417 203.289368,907.496826 
	C204.729660,905.346985 205.588928,903.318787 206.612564,900.968994 
	C206.859390,899.619751 206.988495,898.616455 207.253998,897.255737 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M193.652740,885.827942 
	C197.898727,889.696899 199.874557,892.657959 192.319901,894.531311 
	C190.253601,895.043701 188.493469,897.445618 185.364685,896.983215 
	C183.357941,895.691467 183.042725,893.861572 182.789459,891.604004 
	C182.826752,890.675293 182.939209,890.159912 183.299698,889.308228 
	C184.490265,887.695068 185.944977,887.106812 187.693130,886.279053 
	C189.774338,885.653015 191.522049,885.421936 193.652740,885.827942 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M199.690964,871.555359 
	C202.717209,873.596375 203.205994,876.500183 203.845947,879.708740 
	C202.600357,881.340515 201.115753,882.198364 199.103104,882.737549 
	C198.365219,882.655212 197.942871,882.594971 197.307526,882.838928 
	C196.919785,883.081238 196.737045,883.078308 196.280945,883.096497 
	C195.266541,883.168091 194.618042,882.928955 193.820236,882.218994 
	C192.248108,879.790283 195.850937,879.160828 194.985153,876.842529 
	C194.708725,875.508728 194.727692,874.514099 195.018311,873.133118 
	C195.265060,872.507996 195.397659,872.269775 195.834579,871.752441 
	C197.185974,870.917969 198.261536,870.876526 199.690964,871.555359 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M183.605011,890.719849 
	C184.241837,892.671570 184.602142,894.430664 184.961304,896.574524 
	C184.471863,897.223267 183.983582,897.487244 183.244507,897.871948 
	C182.861679,898.093384 182.732758,898.277466 182.597122,898.282532 
	C179.470612,898.399658 171.767120,893.431580 170.348877,890.221558 
	C170.124481,889.504700 170.120239,889.109131 170.131042,888.417297 
	C170.446838,887.744507 170.810394,887.447144 171.593079,887.174194 
	C172.598099,887.107178 173.412552,887.108398 173.762878,887.617859 
	C176.198074,891.159485 179.660919,891.142090 183.605011,890.719849 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M170.076965,890.286743 
	C173.109421,894.184082 178.688858,894.562439 182.623779,897.980469 
	C182.529282,900.400818 180.360901,900.724548 179.528687,899.271423 
	C176.853607,894.600708 174.356445,897.392334 171.354675,898.957275 
	C169.093506,896.403870 168.842697,893.623291 170.076965,890.286743 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M197.709381,882.400085 
	C199.888458,882.143372 201.016907,879.846680 203.496796,880.072998 
	C203.893463,882.105652 203.935867,884.123474 203.981476,886.576721 
	C204.138992,887.584534 203.963806,888.026184 203.084412,888.214478 
	C201.996353,887.763306 201.282791,887.434570 200.277069,887.059387 
	C199.145752,886.350159 199.052917,885.231812 198.184906,884.249146 
	C197.952454,884.053650 197.815765,883.620300 197.750031,883.402832 
	C197.644470,883.003296 197.604675,882.821289 197.709381,882.400085 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M180.150024,903.212219 
	C182.571030,904.297058 182.612900,905.828247 180.647720,906.679688 
	C179.160812,907.323975 176.275101,907.813477 175.824829,906.225037 
	C175.052032,903.499023 178.396362,904.265686 180.150024,903.212219 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M204.307831,906.664856 
	C204.045853,908.100525 203.993240,909.155762 203.938232,910.607178 
	C204.039917,911.662415 203.839661,912.235901 203.172607,912.913452 
	C201.428070,912.923462 200.979095,911.961731 200.944092,910.290405 
	C200.954910,909.202393 200.998428,908.469788 201.046509,907.369202 
	C203.379532,907.203979 201.391495,904.118530 203.606995,903.970032 
	C204.622635,904.570374 204.770691,905.374146 204.307831,906.664856 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M204.443481,906.954224 
	C204.053528,906.264587 204.008575,905.483765 203.927307,904.312134 
	C204.638306,902.909485 205.385620,901.897705 206.431610,900.754517 
	C206.514725,902.837463 206.591171,905.142639 204.443481,906.954224 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M202.819809,888.393188 
	C203.034271,887.893921 203.358612,887.695862 203.833817,887.254883 
	C206.055847,887.134583 206.014786,888.669983 206.087311,890.504517 
	C205.933289,891.027283 205.767334,891.183044 205.306915,891.166016 
	C204.002502,890.512939 203.300201,889.753967 202.819809,888.393188 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M194.057495,885.749023 
	C192.396957,886.965271 190.627045,887.088318 188.437088,887.066162 
	C187.786011,886.642456 187.741653,886.178711 188.066345,885.372009 
	C188.427780,884.879761 188.605881,884.708130 189.112167,884.361145 
	C190.398102,883.839722 191.383102,883.803040 192.743881,884.016052 
	C193.325836,884.220215 193.546112,884.334961 194.009903,884.731567 
	C194.209457,885.176880 194.165527,885.340210 194.057495,885.749023 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M194.539307,884.968994 
	C194.055313,885.006714 193.857208,884.999878 193.361755,884.992493 
	C192.699066,884.703918 192.486877,884.325806 192.451111,883.534729 
	C192.696869,882.780518 192.998718,882.454529 193.722565,882.145752 
	C194.607788,882.257202 195.158478,882.429993 195.858368,882.860229 
	C195.958145,883.945435 195.689194,884.629639 194.539307,884.968994 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M197.967529,884.400269 
	C199.441116,884.070129 200.000076,884.930237 199.989288,886.658081 
	C198.547928,886.937622 198.007187,886.070312 197.967529,884.400269 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M205.037460,891.310608 
	C205.193878,890.978394 205.375275,890.963623 205.827972,890.910156 
	C206.374573,891.430176 206.649872,891.988892 206.865433,892.869446 
	C205.920273,893.009644 205.228088,892.612488 205.037460,891.310608 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M197.669510,882.258972 
	C197.845047,882.334106 197.836166,882.507324 197.755798,882.932861 
	C197.684280,883.185303 197.393387,883.127808 197.247955,883.105957 
	C197.118240,882.782776 197.245773,882.540405 197.669510,882.258972 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M161.358215,880.023254 
	C160.588776,879.975464 160.178589,879.909241 159.463745,879.775818 
	C158.035400,878.971924 156.781097,878.460022 155.397507,877.490234 
	C152.903610,874.802490 150.654816,872.877075 146.871994,872.620544 
	C142.176529,872.302307 141.445709,870.400208 144.208466,866.544189 
	C146.407028,863.475647 144.599075,861.652893 142.770004,859.763428 
	C140.850510,857.780518 138.591125,856.006348 142.330887,853.412048 
	C146.609970,854.597168 149.708664,857.170227 153.198792,859.773926 
	C154.034882,860.416809 154.581329,860.857544 155.367508,861.554077 
	C156.944260,863.489197 158.206909,865.210938 160.739456,865.135010 
	C162.380951,865.085754 163.564636,865.889221 164.122589,867.891479 
	C164.329468,869.200439 164.226822,870.145935 163.857895,871.415955 
	C162.777939,873.116089 161.630341,874.416809 163.884735,875.749878 
	C164.808685,876.296265 164.859085,877.411072 164.492249,878.768433 
	C163.641708,879.900696 162.681046,879.956909 161.358215,880.023254 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M155.727417,876.777954 
	C157.421799,877.095276 158.508118,877.676941 159.085571,879.400024 
	C158.982178,879.799988 158.805252,879.891418 158.343109,880.071777 
	C155.454926,879.523438 153.383743,880.919434 151.041733,881.663269 
	C146.711411,883.038452 142.027939,883.845276 139.349228,878.050903 
	C138.997269,878.003479 139.036453,877.953857 139.046738,877.923950 
	C137.797150,876.788147 136.084747,876.176147 135.233398,874.142761 
	C134.992813,873.026733 135.080231,872.289795 135.582977,871.263733 
	C136.183594,870.633545 136.617538,870.405579 137.469849,870.274414 
	C140.921906,873.581726 146.037094,873.455261 149.081055,877.193726 
	C150.687790,879.166992 153.147491,875.601929 155.727417,876.777954 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M183.916504,890.605408 
	C178.786942,893.638611 175.776260,892.964783 172.419464,888.060852 
	C171.274567,887.395264 171.026932,886.581177 171.220947,885.293518 
	C171.281921,884.271851 171.274643,883.579529 171.296768,882.542297 
	C171.799759,882.016113 172.285324,881.962891 173.135010,882.183105 
	C176.267487,885.063782 179.465271,886.991577 183.773865,887.515991 
	C184.179260,887.958130 184.284088,888.182861 184.213470,888.735535 
	C184.009155,889.475159 183.980316,889.886780 183.916504,890.605408 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M164.055115,879.077271 
	C164.350891,875.403259 161.334824,876.230347 159.506409,875.693298 
	C159.876343,873.678711 161.044464,872.847778 162.649445,872.043945 
	C164.940002,872.137939 166.439453,874.254089 169.004837,873.278198 
	C169.831543,873.242920 170.294678,873.332886 171.039093,873.705200 
	C171.758911,874.543518 171.898468,875.242004 171.792480,876.337280 
	C171.545044,877.123718 171.312225,877.525635 170.768631,878.119873 
	C169.495224,879.114258 168.276657,879.583008 166.986603,880.491943 
	C166.372452,880.612488 166.062439,880.633789 165.517456,880.620239 
	C164.582077,880.498230 164.213821,880.044556 164.055115,879.077271 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M167.074615,880.717896 
	C166.830887,878.983948 167.541840,878.000061 169.637299,878.132080 
	C171.200638,878.383240 172.312027,878.829529 173.412201,880.021606 
	C173.866196,881.064148 173.864166,881.791870 173.370331,882.815796 
	C172.681519,882.890991 172.218185,882.668335 171.540527,882.321533 
	C170.291489,881.024902 168.838638,881.068176 167.074615,880.717896 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M163.726837,878.844849 
	C164.431564,879.197571 164.794022,879.632263 165.220383,880.339233 
	C164.023224,881.155273 162.761566,881.234985 161.248993,880.329102 
	C161.512833,879.068054 162.465408,879.033203 163.726837,878.844849 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M170.971466,885.148193 
	C171.542358,885.756714 171.795547,886.549194 172.059540,887.669617 
	C171.641022,888.018677 171.211685,888.039734 170.464233,888.090942 
	C170.028320,887.139099 169.910355,886.157227 170.971466,885.148193 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M244.771286,79.261261 
	C245.858322,77.824181 246.694962,76.624748 247.776520,75.234436 
	C249.887848,73.415237 251.398712,74.393639 252.651733,75.840385 
	C254.719757,78.228188 252.875641,80.174568 251.574539,82.144135 
	C253.888794,79.339577 255.582642,76.528481 253.490646,72.785370 
	C252.575439,71.147812 253.631012,69.641800 255.387421,68.394379 
	C258.773560,67.039688 259.491547,63.673691 262.024048,61.521606 
	C266.442535,60.641869 268.367096,65.507843 272.618958,65.454468 
	C274.023285,67.793777 272.638123,69.107971 271.073975,70.695465 
	C269.606415,71.527138 268.497467,72.833633 267.273254,72.629250 
	C261.343445,71.639252 260.212036,75.731010 258.800232,79.959221 
	C257.707306,83.232422 256.224243,86.409424 252.647842,87.907440 
	C250.405945,88.846497 250.532166,91.509071 248.816711,93.269348 
	C248.273941,93.505890 248.039032,93.552063 247.450439,93.549881 
	C244.953751,92.779243 245.265457,90.010590 243.478745,89.053688 
	C242.396194,89.131363 241.882767,89.868202 241.735016,90.661728 
	C240.977051,94.732750 238.537979,97.069847 234.571960,97.995522 
	C232.723999,98.426834 231.275208,99.519386 229.925690,101.297195 
	C228.543198,108.938538 222.275574,108.046730 217.345779,108.822548 
	C215.455551,109.120018 213.583282,108.036621 212.658875,110.778244 
	C212.302475,111.835167 210.797028,111.983353 209.268417,111.495399 
	C207.573029,109.790382 208.750214,108.230743 208.915421,106.329453 
	C208.931168,105.534462 208.958755,105.088097 209.058472,104.313614 
	C210.270676,103.861069 210.684174,103.157539 211.010971,102.095078 
	C211.875854,99.283104 212.232437,96.204613 215.959778,100.634674 
	C219.348679,104.662476 223.904770,103.038559 226.083374,97.391388 
	C225.871735,94.938408 227.368912,93.803635 228.771362,92.240089 
	C229.098877,91.791290 229.230621,91.611519 229.554993,91.157776 
	C230.821472,87.814491 233.142044,86.036446 236.115250,85.193275 
	C239.586029,84.209000 242.334503,82.305542 244.771286,79.261261 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M177.308105,110.329384 
	C177.028824,109.006203 177.021637,107.925446 177.024323,106.440765 
	C181.556473,104.823914 185.599625,103.633507 190.451401,106.726326 
	C193.247025,108.508430 197.679886,108.685913 201.300522,109.943802 
	C204.072601,110.906891 204.708969,107.103218 207.467407,106.948288 
	C209.662567,109.464066 206.367752,111.319901 206.941071,114.037270 
	C206.987839,115.080246 206.896927,115.765945 206.537079,116.752869 
	C204.480194,118.846321 203.594757,121.214951 202.426941,123.814285 
	C199.992859,125.400108 198.393845,124.758850 197.750763,122.357208 
	C197.313950,120.725861 196.682663,120.529839 195.102692,121.582291 
	C193.840698,122.062988 192.884964,122.113663 191.575531,121.733368 
	C191.045959,121.448692 190.856354,121.300850 190.472931,120.854843 
	C190.120636,116.361679 190.100647,112.088135 184.515030,110.637848 
	C184.113998,110.314308 183.961044,110.183189 183.584930,109.871246 
	C182.621841,109.126099 182.019043,109.443512 181.197250,110.189362 
	C179.853134,111.029671 178.732910,111.103287 177.308105,110.329384 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M183.445526,92.928154 
	C184.047791,95.043930 183.200180,95.930794 181.621399,96.668777 
	C178.988266,97.899590 176.199661,97.466698 173.493011,97.525352 
	C172.346054,97.550201 170.968399,96.806122 171.588623,94.856590 
	C173.014587,90.374367 179.195511,86.919853 183.907532,88.032631 
	C184.984070,88.286865 185.716202,88.647942 185.881973,89.831421 
	C186.137863,91.658302 184.449097,91.787239 183.445526,92.928154 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M206.491089,114.105324 
	C203.976318,111.116089 207.804764,109.890266 207.850525,107.269142 
	C208.003769,106.636871 208.214493,106.392860 208.684326,106.082047 
	C210.873444,107.335335 209.234695,108.870827 209.105316,110.680695 
	C210.083069,113.296898 208.577057,113.826607 206.491089,114.105324 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M133.912415,123.089722 
	C135.816650,122.928581 137.684799,123.362312 139.699265,122.236816 
	C144.514496,116.331688 149.349411,121.676819 153.986282,121.894867 
	C155.535767,121.967720 157.714798,122.646210 159.746674,123.674957 
	C160.908203,125.839615 159.154648,127.343811 159.116089,129.507629 
	C157.748550,132.507126 155.617737,133.339874 152.487595,132.779373 
	C148.169083,128.711273 142.795212,128.453323 137.840897,127.217331 
	C135.807922,126.710144 133.355942,127.242226 133.509735,123.690414 
	C133.666397,123.341919 133.830429,123.173790 133.912415,123.089722 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M158.659821,129.362198 
	C157.605804,127.305443 158.573563,125.925636 159.849396,124.265472 
	C162.004852,123.706276 164.004593,123.475075 166.387787,123.266983 
	C168.537537,124.334694 169.996399,123.795105 171.448853,122.136795 
	C171.605988,121.860825 171.803131,121.845390 171.872559,121.915787 
	C172.220734,121.900681 172.499481,121.815170 173.114609,122.010284 
	C173.902878,126.770821 173.070816,128.001099 168.764542,128.338440 
	C165.505600,128.593735 162.226044,128.539703 158.659821,129.362198 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M171.028046,112.383728 
	C171.479752,112.007744 171.924698,112.004227 172.703735,112.005013 
	C173.927826,111.734764 174.377747,112.281540 174.896133,113.281464 
	C175.369370,115.348862 175.107956,117.003769 174.300629,118.938469 
	C173.995468,119.454651 173.852722,119.648010 173.429749,120.065979 
	C172.915649,120.337059 172.681793,120.383537 172.166321,120.209518 
	C171.596924,117.578072 171.309128,115.167130 171.028046,112.383728 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M171.263062,121.900009 
	C171.480423,125.632294 169.712158,125.753593 166.914841,123.598724 
	C168.154205,122.839798 169.537170,122.389496 171.263062,121.900009 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M171.881500,120.322403 
	C172.093201,120.053528 172.301697,120.118042 172.821976,120.282349 
	C173.351379,120.698044 173.436157,121.046310 173.231628,121.657791 
	C172.886246,121.903923 172.697464,121.919182 172.225342,121.960312 
	C171.920746,121.542725 171.899506,121.099258 171.881500,120.322403 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M174.399948,114.280304 
	C173.903458,113.507690 173.710754,112.941315 173.277924,112.192123 
	C173.067795,111.592560 173.097824,111.175812 173.165466,110.450211 
	C174.222458,110.124710 175.241821,110.108078 176.648590,110.089203 
	C178.186737,109.884346 179.337448,109.681725 180.726746,109.221237 
	C181.669144,108.420166 182.442337,108.256607 183.632706,108.782242 
	C183.968674,109.027283 183.996338,109.001961 183.985016,109.013535 
	C184.490448,109.636635 184.706757,110.346367 184.540222,111.496017 
	C181.302628,113.189087 178.192307,114.534912 174.399948,114.280304 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M52.870667,575.413330 
	C52.950085,573.896301 53.136837,572.662720 51.362587,572.052002 
	C51.067287,567.466614 49.665024,562.521118 54.628567,559.451843 
	C56.103432,558.539917 56.432388,557.154724 56.519859,555.634033 
	C56.858505,549.746948 58.693718,543.847839 56.368599,537.973816 
	C56.129963,537.370972 56.266762,536.660828 56.508102,535.656982 
	C58.369385,533.671387 59.819172,534.683044 60.598656,536.153625 
	C62.091522,538.970032 64.638336,540.720154 66.977150,543.208496 
	C67.378471,543.681824 67.516350,543.889587 67.800255,544.441284 
	C68.164215,545.497437 68.261284,546.231873 68.381104,547.331482 
	C68.457603,548.427307 68.664215,549.125549 68.955070,550.182251 
	C69.348305,552.005859 69.347794,553.480103 69.097328,555.317871 
	C68.372856,557.744995 67.973198,559.791748 69.684738,562.013916 
	C70.748878,564.860657 70.088188,567.337769 69.592728,570.178650 
	C69.427315,571.719116 69.324821,572.902039 69.772781,574.400146 
	C70.038666,575.408997 70.130867,576.095703 70.278732,577.118225 
	C70.394630,578.126526 70.459534,578.798096 70.716217,579.780823 
	C70.846817,580.556763 70.874069,581.004211 70.849442,581.786621 
	C70.365700,584.384094 71.050591,586.394348 72.359047,588.596313 
	C72.668800,589.312012 72.765816,589.744934 72.796577,590.521362 
	C72.561050,592.300781 72.030121,593.773315 73.294601,595.394287 
	C73.662170,596.056030 73.826279,596.456177 74.040184,597.183472 
	C74.342964,598.313049 74.339088,599.201172 75.096512,600.174927 
	C75.402969,600.637451 75.516418,600.830933 75.761063,601.338501 
	C76.024704,602.091736 76.125298,602.538208 76.237869,603.336304 
	C76.363144,605.068726 76.304031,606.451050 76.063950,608.161133 
	C75.424011,610.201843 75.230751,611.872314 76.631165,613.706726 
	C76.955414,614.433777 77.103600,614.867065 77.300110,615.652344 
	C77.818527,617.838501 78.236885,619.688232 78.880707,621.855652 
	C79.215553,623.155762 79.297249,624.126770 79.372749,625.463257 
	C78.863647,629.979614 81.080688,633.116089 83.462006,636.584351 
	C83.763985,637.135620 83.865562,637.368408 84.074104,637.970459 
	C84.470161,639.872742 84.427216,641.425842 84.448700,643.361450 
	C84.440262,645.039062 84.413734,646.333069 84.962997,647.958191 
	C85.433784,650.827393 84.762680,653.119202 83.546890,655.666748 
	C82.290955,656.781799 80.674675,657.168030 80.208366,656.136902 
	C77.907814,651.049866 71.627556,650.173828 68.844269,645.174011 
	C64.806297,640.411865 62.771133,635.369385 64.088608,629.367188 
	C64.337105,628.235046 64.715431,626.997070 64.042480,625.971191 
	C60.746819,620.947205 60.906063,614.966309 59.237156,609.556641 
	C57.348782,603.435608 53.335777,598.219482 55.563923,591.023071 
	C56.400459,588.321289 52.641777,585.252075 53.031193,581.370789 
	C52.707504,579.270020 52.418430,577.534607 52.870667,575.413330 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M56.450504,534.577759 
	C60.570648,542.522888 58.134872,550.295593 57.937565,557.970703 
	C57.921638,558.590332 57.643101,559.691895 57.343292,559.743958 
	C50.376331,560.953247 54.449051,568.163818 51.028145,571.614990 
	C48.338211,572.301331 46.111076,570.573425 46.576736,568.489868 
	C47.859459,562.750122 45.199268,557.265686 46.136772,551.709351 
	C46.617741,548.858765 47.013710,546.362122 46.618023,543.258545 
	C45.961761,538.111328 42.853771,536.420898 39.180878,535.390137 
	C35.456055,534.344971 35.610649,532.662415 36.994713,530.017822 
	C38.450630,527.236023 38.741444,524.697815 35.246456,523.109375 
	C39.506630,519.588745 39.573303,516.578247 35.742958,513.449585 
	C31.853928,510.272980 31.825836,506.869995 36.381233,504.013550 
	C39.685413,501.941650 41.402401,499.299500 41.943760,495.713623 
	C42.167576,494.231018 42.613235,493.386322 44.084007,492.780426 
	C45.906574,492.029633 47.111012,490.277802 46.497955,488.382538 
	C45.805412,486.241486 46.655216,484.885864 47.761112,483.391785 
	C48.053703,482.996490 48.521706,482.239197 48.398094,482.099121 
	C44.739243,477.952850 50.065388,473.030731 47.249165,468.818024 
	C47.062801,468.539246 47.410488,467.903473 47.764439,467.220245 
	C48.898445,466.378876 49.725777,466.789917 50.844223,467.312714 
	C51.298260,467.646973 51.464546,467.802246 51.842323,468.225098 
	C52.956627,470.702576 54.996471,470.026825 56.995247,470.445801 
	C57.739319,470.864227 58.095078,471.200928 58.597710,471.909485 
	C59.295494,473.422699 59.315857,474.686707 59.389530,476.314514 
	C59.445221,477.406097 59.407925,478.128479 59.401566,479.198120 
	C59.414436,480.444275 59.360546,481.350464 59.781197,482.534515 
	C61.086246,485.657959 60.597095,488.459137 59.865440,491.596741 
	C59.868015,492.392639 59.817101,492.846680 59.616943,493.628906 
	C59.376675,494.157623 59.249317,494.354340 58.872875,494.798828 
	C56.987595,496.322144 55.251881,497.013428 53.101593,496.426544 
	C50.148548,495.620575 49.584915,497.116638 50.153046,499.658722 
	C51.097206,503.883331 50.162819,507.817566 48.120193,511.531494 
	C47.084656,513.414307 46.952450,515.097412 48.133804,517.090210 
	C51.340073,522.498962 53.378189,528.131104 49.432602,534.636597 
	C51.928493,534.991516 53.888794,533.216614 56.450504,534.577759 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M59.812603,370.141602 
	C59.793552,369.770721 59.858810,369.380066 59.906013,369.187714 
	C60.562935,368.509735 60.503506,367.832550 60.400658,366.832031 
	C60.309868,366.270050 60.295677,366.040863 60.323174,365.468811 
	C60.701790,362.356171 60.773659,359.588013 60.250065,356.472992 
	C60.148346,355.648041 60.168560,355.175385 60.347862,354.366577 
	C62.896263,350.236755 64.237694,345.800934 67.378464,342.015381 
	C67.632141,339.948120 67.960075,338.297424 69.764709,336.947144 
	C71.654121,336.150696 72.534874,334.112885 74.938080,334.722717 
	C77.015541,337.987030 74.040955,339.999939 73.496414,342.940308 
	C73.446022,344.052551 73.320366,344.779663 73.052353,345.847900 
	C72.408356,347.940521 70.688271,348.497498 69.159317,349.851807 
	C72.836311,350.347076 72.919815,352.557953 72.868462,355.524536 
	C72.771027,361.152649 71.184479,366.572174 70.981552,372.519623 
	C70.511589,374.072113 69.881615,375.163513 69.298462,376.693573 
	C66.298981,384.468262 65.300316,392.473633 61.970676,399.738800 
	C61.691235,400.348572 61.526333,401.047760 61.717468,401.681671 
	C63.999584,409.250244 61.912579,416.785553 59.866005,423.698853 
	C57.764454,430.797821 56.729031,438.033173 54.669899,445.457458 
	C54.215256,445.942902 53.995583,446.099670 53.373619,446.301575 
	C52.189812,443.717865 50.569012,444.132202 48.709084,445.112946 
	C47.712376,445.638550 46.584900,446.421997 45.542351,445.295837 
	C44.788357,444.481415 44.952480,443.423370 45.431400,442.499298 
	C46.052814,441.300232 47.107231,440.963837 48.381908,441.281982 
	C50.053783,441.699310 52.227196,443.076630 52.771305,440.241150 
	C53.232761,437.836426 50.921040,437.562408 49.176609,436.991486 
	C45.661411,435.841034 44.790215,432.428925 47.259724,428.638367 
	C50.806168,423.194733 51.267220,417.253967 51.127674,410.968597 
	C51.003750,405.386841 49.363010,399.845825 50.886574,394.163910 
	C51.369469,392.363068 51.744450,390.640839 52.905640,389.223541 
	C54.780479,386.935242 55.801197,384.104218 55.771889,381.423859 
	C55.724606,377.098907 57.889240,373.920105 59.812603,370.141602 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M53.495121,580.909119 
	C55.058094,584.769958 56.344154,588.565430 57.632919,592.359985 
	C58.021458,593.503906 58.327251,594.943298 57.429070,595.669739 
	C53.851929,598.562683 54.955307,600.405701 58.074909,602.971436 
	C61.155457,605.505005 61.943958,609.946533 61.596329,613.463562 
	C61.137062,618.110046 63.322891,621.235413 65.276146,624.723389 
	C65.743156,625.557373 67.128830,626.324341 66.566971,627.209167 
	C62.745884,633.226440 66.320549,638.277222 68.741165,643.761719 
	C70.847260,645.768555 71.542007,647.759766 69.019463,649.309998 
	C66.654068,650.763672 64.563576,649.769470 63.087433,647.542603 
	C61.975956,645.865845 62.218864,643.099243 58.963341,642.773438 
	C56.219589,640.319031 57.468277,638.599487 59.556149,636.988098 
	C61.776554,635.274475 61.659191,633.382629 59.135715,632.475586 
	C53.376671,630.405579 51.404350,626.187073 51.265907,620.240356 
	C51.042755,619.221924 51.045189,618.482483 51.053844,617.373291 
	C51.014168,616.203857 51.005722,615.403076 50.979053,614.302368 
	C50.966759,613.564880 50.964787,613.127441 50.947029,612.362854 
	C53.117817,609.028564 50.879234,607.119019 49.122681,605.204163 
	C48.140045,604.132996 46.478046,603.525879 48.058548,601.676941 
	C52.018681,597.044250 52.179237,594.940979 47.647430,591.028748 
	C45.078144,588.810730 45.221310,586.882263 45.909222,584.106995 
	C46.550190,581.520996 47.900768,582.639038 49.822491,583.003174 
	C50.754559,582.982361 50.991512,582.783264 51.269554,582.238281 
	C51.901257,581.449768 52.469273,581.008240 53.495121,580.909119 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M39.027916,414.321960 
	C40.288891,412.979370 40.168900,411.754242 39.306931,410.239960 
	C39.049046,409.268555 39.039150,408.511230 39.053963,407.376312 
	C40.122890,404.677185 40.695293,402.232666 41.030216,399.370117 
	C41.077343,398.568665 41.072845,398.109863 41.097256,397.308044 
	C40.629070,394.579010 41.874584,392.952087 43.753635,391.246002 
	C43.998203,390.997406 44.000000,391.000000 43.998955,390.999084 
	C44.830891,390.375885 45.746578,390.045319 47.412838,390.613586 
	C48.125145,392.909668 47.619442,394.298676 46.048428,395.718994 
	C42.755390,398.696136 43.176186,403.068054 46.619492,406.103607 
	C46.981922,406.423126 47.473984,406.660767 47.704254,407.054382 
	C49.177319,409.572357 45.729671,423.380798 43.171329,424.518494 
	C41.901562,425.083191 40.282921,424.863403 38.410492,424.998657 
	C37.599552,424.623840 37.457661,424.168671 37.790642,423.322021 
	C40.124504,421.138641 42.271614,419.275177 39.639053,416.040588 
	C39.232727,415.505829 39.135696,415.078400 39.027916,414.321960 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M36.411873,431.110596 
	C36.047253,430.207245 35.978096,429.503357 35.905762,428.444641 
	C36.627338,427.433441 37.112392,427.904572 37.794868,428.718628 
	C38.115028,429.310852 38.305382,429.570740 38.797382,429.879395 
	C41.172935,431.836823 41.548710,434.095642 40.309216,436.552704 
	C39.518044,438.121033 39.123665,439.566132 40.437744,441.411530 
	C41.094769,442.872864 40.924225,444.100403 41.117737,445.643433 
	C41.159203,447.367767 41.151173,448.735962 41.191826,450.510651 
	C40.938568,451.746124 40.721859,452.639862 39.387611,452.990662 
	C39.065552,450.264740 39.240601,447.736389 36.406399,445.598511 
	C33.816887,443.645203 34.619629,440.656189 36.339855,438.019348 
	C37.698582,435.936676 39.087353,433.677429 36.411873,431.110596 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M47.319973,464.225952 
	C46.561787,460.792877 46.265450,457.545837 45.581944,454.382477 
	C45.182743,452.534912 45.590698,451.556763 47.707748,451.017761 
	C48.660255,451.111603 49.097759,451.486023 49.681221,452.129639 
	C50.258209,452.734100 50.703197,453.035400 51.551220,453.182434 
	C52.247704,453.299072 52.626637,453.447754 53.202236,453.891785 
	C53.579479,454.551483 53.631226,454.983032 53.479965,455.743958 
	C52.385513,457.350891 50.189880,458.077332 51.445950,460.713593 
	C51.517788,461.297974 51.512150,461.537903 51.392799,462.124512 
	C50.420521,463.799225 49.357754,464.748993 47.319973,464.225952 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M48.291515,387.204437 
	C48.105732,384.232300 49.569847,381.637421 48.886688,378.371307 
	C48.819294,377.947052 49.042881,377.520691 49.128857,377.295837 
	C49.552380,376.684906 49.976650,376.513916 50.782631,376.773987 
	C52.821877,379.237030 55.497238,381.328125 52.162792,384.703735 
	C51.125317,386.170105 50.605026,387.842590 48.291515,387.204437 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M53.242203,453.668640 
	C52.480114,453.843231 52.063160,453.857758 51.333603,453.886932 
	C50.411987,453.936462 49.872292,453.766235 49.249084,453.116089 
	C48.817413,452.403778 48.549648,451.933472 48.175610,451.214294 
	C48.882263,448.829071 49.041233,446.035919 52.734917,446.450989 
	C52.971333,446.346741 53.338600,446.292419 53.853874,446.401459 
	C55.596268,448.416138 55.282578,450.362183 54.322021,452.643799 
	C53.944088,453.147949 53.765713,453.328247 53.242203,453.668640 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M40.144768,442.276611 
	C36.688141,440.930328 37.680668,438.589203 38.840103,436.866608 
	C40.294289,434.706146 39.837246,432.817688 39.047104,430.370544 
	C39.456539,429.983124 39.896797,429.977417 40.667427,429.973572 
	C41.360058,430.036621 41.655663,430.213715 41.967575,430.747284 
	C42.495663,434.743378 40.370426,438.468353 41.981571,442.609863 
	C41.261036,443.438568 40.804420,443.016235 40.144768,442.276611 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M53.814301,580.578247 
	C53.264675,581.497314 52.758369,582.019714 52.142159,582.800049 
	C51.620010,583.404358 51.147495,583.538879 50.288002,583.261414 
	C49.961372,583.061462 49.974289,583.039001 49.957489,583.038086 
	C50.062340,580.210999 47.334686,576.551453 52.604637,575.087891 
	C54.526295,576.535645 53.787266,578.429810 53.814301,580.578247 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M38.503922,587.285522 
	C36.853138,584.007874 40.258808,582.889832 40.831902,580.240479 
	C41.009472,578.845215 41.221413,577.821655 41.569298,576.512207 
	C41.904598,576.066101 42.103947,575.906006 42.666729,575.868469 
	C43.020275,578.035339 43.010391,580.079651 43.000603,582.562012 
	C42.521088,585.343933 41.733223,587.400757 38.503922,587.285522 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M38.129768,587.449036 
	C40.413292,586.781189 41.358784,584.985291 42.736794,583.192383 
	C44.241386,586.394897 42.624924,588.448181 39.411343,589.955383 
	C38.889397,589.996216 38.710945,589.994507 38.264793,589.996399 
	C38.023731,589.269958 38.050362,588.539978 38.129768,587.449036 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M47.040054,464.324280 
	C48.467106,464.059021 49.373005,463.046600 50.697937,462.142151 
	C52.575089,463.596130 52.517838,465.401703 51.321190,467.656586 
	C50.194107,467.756165 49.397163,467.507477 48.309658,467.132751 
	C47.699200,466.219086 47.379303,465.431458 47.040054,464.324280 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M47.989830,386.991028 
	C49.466030,387.107391 50.356991,386.029449 51.710808,385.147705 
	C52.116592,387.650330 50.711445,389.441864 48.363045,390.958527 
	C48.024826,391.023529 48.002842,391.002625 47.992710,390.992523 
	C47.158764,389.991211 47.049332,388.905090 47.768902,387.361206 
	C47.986107,386.984680 47.998062,386.995758 47.989830,386.991028 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M43.260323,575.792847 
	C42.807690,576.017090 42.585213,576.043213 42.033985,576.147827 
	C41.165955,575.441895 40.626667,574.657471 39.967125,573.618958 
	C39.882088,573.134338 39.917313,572.903870 39.983917,572.324951 
	C42.011936,572.377380 43.711422,573.063782 43.260323,575.792847 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M38.011795,428.591370 
	C37.491093,428.741669 36.974766,428.492981 36.180511,428.167053 
	C35.853802,427.013306 35.805012,425.936768 35.798595,424.460419 
	C36.379314,423.789642 36.917667,423.518707 37.732101,423.129272 
	C38.004566,423.452545 38.000950,423.894257 37.998104,424.667389 
	C38.004639,426.063324 38.010406,427.127838 38.011795,428.591370 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M53.109520,373.092896 
	C53.300694,370.869446 54.864586,371.037170 56.707428,371.049805 
	C56.950809,372.464844 56.095333,373.020111 54.457523,373.092163 
	C53.871433,373.194427 53.628750,373.277344 53.109520,373.092896 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M39.936199,442.417419 
	C40.429543,442.292328 40.945774,442.534363 41.723438,442.890900 
	C41.866951,443.854553 41.749035,444.703674 41.399174,445.776215 
	C39.806553,445.287872 40.059273,443.969879 39.936199,442.417419 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M52.532406,372.864258 
	C53.045311,372.876617 53.257652,372.927704 53.792053,373.045166 
	C53.918026,374.429230 53.091869,375.005585 51.484222,375.087921 
	C50.757442,375.075928 50.495098,374.872406 50.280075,374.244141 
	C50.877632,373.622925 51.554737,373.262939 52.532406,372.864258 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M50.030556,374.186890 
	C50.489830,374.157257 50.721653,374.389130 51.021286,374.897308 
	C51.123913,375.532043 51.101265,375.947968 51.078140,376.676941 
	C50.661716,377.008179 50.245762,377.026337 49.522324,377.057739 
	C49.152454,376.621704 49.090073,376.172424 49.032459,375.382996 
	C49.311676,374.825562 49.586124,374.608215 50.030556,374.186890 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M42.245644,430.841553 
	C41.770844,430.810638 41.500389,430.617371 41.113865,430.199768 
	C41.358536,429.460144 41.831619,429.055237 42.401600,429.542633 
	C42.598057,429.710602 42.443951,430.288574 42.245644,430.841553 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M23.961409,478.413208 
	C24.379045,478.078583 24.854595,478.043213 25.688114,478.003296 
	C25.903629,478.542206 25.761175,479.085632 25.410864,479.819000 
	C24.808445,479.576782 24.413883,479.144623 23.961409,478.413208 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M49.770905,355.189026 
	C47.346989,358.689911 44.190746,362.195129 46.775448,367.679352 
	C45.172340,367.988800 42.815235,367.638092 43.330429,365.660980 
	C44.125111,362.611420 42.778091,358.742462 46.190853,356.576141 
	C47.149807,355.967438 48.335751,355.716309 49.770905,355.189026 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M213.276520,911.203125 
	C213.138885,909.812012 212.939819,908.616028 214.640991,908.010620 
	C218.274826,905.554626 220.800613,908.580627 223.662704,909.218933 
	C227.414383,910.055664 228.864166,912.229492 227.583847,916.076355 
	C227.150421,917.378418 228.454605,917.605896 229.380798,917.846008 
	C230.856522,918.228577 232.065735,919.020203 233.347168,920.340698 
	C233.755478,920.724243 233.926163,920.868042 234.337921,921.239502 
	C235.717911,922.920105 237.468765,923.299744 239.333206,924.165955 
	C240.011719,924.592773 240.350067,924.904419 240.863434,925.537720 
	C241.856308,927.476807 243.081451,928.740173 244.520981,929.843689 
	C245.995193,930.973938 247.171143,932.285522 246.385681,934.677979 
	C243.462967,936.271790 240.881470,936.439270 238.466034,934.180298 
	C237.237259,933.031189 235.556152,932.600647 233.998306,932.113220 
	C231.176834,931.230530 232.911697,933.635132 232.206970,934.721619 
	C230.472809,935.529480 229.513245,937.753235 227.196289,936.254150 
	C227.118484,933.825867 226.222336,931.856567 227.385406,929.369812 
	C228.763153,926.424011 224.079651,925.263489 223.293549,922.230042 
	C222.988892,920.035522 222.953995,917.602661 220.520782,917.502930 
	C218.528717,917.421387 217.236908,919.168396 216.674332,921.352783 
	C216.260132,922.960938 215.974472,924.525940 215.972733,926.576538 
	C212.234222,926.804382 210.364792,925.399780 211.649765,921.269531 
	C212.172089,919.590515 211.909485,917.667419 212.007782,915.421631 
	C212.384720,913.781128 213.989716,913.076233 213.276520,911.203125 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M232.365570,934.985474 
	C233.061005,932.892578 227.827286,933.395020 230.233139,931.077942 
	C231.964005,929.410950 234.240845,929.545349 235.984863,931.497437 
	C238.485062,934.295837 241.866959,934.605347 245.851288,935.222900 
	C246.760849,936.147705 246.774170,936.802429 246.241470,937.719482 
	C246.020859,937.979065 246.001541,937.998413 245.991074,938.008911 
	C244.571579,938.231445 242.473587,937.123535 242.640121,940.144470 
	C242.708771,941.389832 241.977646,942.893433 240.269440,942.791748 
	C238.659637,942.695923 238.167557,941.415344 237.989395,939.978882 
	C237.908340,939.325378 237.470947,938.652466 237.570267,938.050293 
	C238.376648,933.161011 235.430405,934.300415 232.365570,934.985474 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M36.996326,595.594666 
	C35.352306,594.969360 34.062065,593.856018 36.454552,591.937988 
	C36.840275,592.952209 36.924763,594.065063 36.996326,595.594666 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M40.744022,399.169281 
	C42.757172,401.989563 42.468563,404.626617 39.431492,406.932922 
	C37.031200,404.006165 37.563446,401.459137 40.744022,399.169281 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M43.640068,391.024719 
	C43.202797,392.840668 42.407387,394.683899 41.369068,396.746094 
	C38.590179,393.807465 40.704651,392.345398 43.640068,391.024719 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M47.688824,387.105347 
	C47.988617,388.051239 47.991127,389.117798 47.988106,390.583374 
	C46.920200,390.993835 45.857819,391.005280 44.396675,391.007446 
	C44.684055,389.340393 46.081623,388.322632 47.688824,387.105347 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M22.004662,465.694397 
	C21.527128,466.030640 21.097076,466.017120 20.344635,465.990814 
	C19.903135,464.174286 20.172310,462.594757 22.630276,462.028412 
	C22.673504,463.102814 22.362823,464.223724 22.004662,465.694397 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M24.286934,460.960449 
	C24.014833,460.533661 24.031893,460.068939 24.111618,459.259338 
	C24.791632,459.078278 25.537609,459.215271 25.489086,460.009003 
	C25.469406,460.330933 24.896988,460.619049 24.286934,460.960449 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M79.685104,268.235046 
	C79.953201,267.945953 80.011391,267.494324 80.039337,267.268372 
	C81.383728,265.639526 80.972275,263.519684 82.173950,262.045685 
	C83.356628,260.594940 84.530319,258.964996 86.660942,259.654144 
	C88.809990,260.349274 89.042030,262.190491 88.634430,264.170654 
	C88.468803,264.975311 88.052368,265.737091 88.297012,267.099854 
	C90.411980,265.372162 91.116661,263.529022 90.393578,261.145691 
	C89.959000,259.713348 89.378555,258.315277 89.399323,256.787048 
	C89.413948,255.710312 90.215942,254.205231 90.904716,254.704880 
	C94.961723,257.647949 95.895355,253.231140 98.419098,251.714264 
	C99.176987,251.319336 99.650116,251.196381 100.513626,251.150208 
	C101.126335,251.237717 101.365295,251.309952 101.908020,251.595642 
	C102.325241,252.025360 102.438705,252.241608 102.606743,252.816101 
	C102.119270,258.841888 99.020340,263.716217 97.704002,269.148956 
	C97.044388,271.871277 94.509491,271.625061 91.934456,272.232422 
	C93.851860,273.677307 97.390663,273.510620 95.989914,277.230164 
	C92.754890,277.717621 89.973763,278.601898 87.148254,279.489136 
	C83.674629,280.579926 80.483604,279.228851 78.374458,276.478973 
	C76.308319,273.785187 76.900887,270.896027 79.685104,268.235046 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M98.770775,251.792740 
	C98.669235,254.216782 94.456230,254.854858 96.599113,258.105011 
	C96.705399,258.266235 95.970741,259.253052 95.459915,259.483398 
	C94.777351,259.791168 94.293129,259.267090 94.041283,258.545166 
	C93.486526,256.955017 92.809288,255.458313 90.671295,255.473999 
	C89.986053,257.061646 90.591537,259.007324 91.294762,259.697571 
	C94.542999,262.885773 91.308723,265.172455 90.421783,267.646820 
	C89.941704,268.986145 88.127838,270.102753 86.724098,269.352509 
	C85.398056,268.643799 86.147545,267.005066 86.638191,265.738831 
	C87.310730,264.003204 89.303200,261.760071 86.550415,260.639252 
	C84.203133,259.683563 83.422897,262.360138 82.486427,264.053864 
	C81.947990,265.027710 82.111481,266.433655 80.403496,266.968781 
	C80.046547,265.965179 80.025818,264.887970 80.009178,263.407196 
	C79.183212,261.639893 80.520775,259.905396 79.130623,258.307434 
	C78.998711,258.000000 78.945061,257.539917 78.911392,257.310791 
	C78.346138,256.339905 78.121536,255.528763 78.492462,254.222076 
	C78.925484,253.292938 79.187630,252.763321 79.684425,252.050110 
	C83.035179,249.429565 87.409714,249.777679 90.441635,246.921814 
	C91.811127,245.631851 93.657753,246.577179 94.532280,248.211334 
	C95.442657,249.912476 96.662247,251.055115 98.770775,251.792740 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M59.266903,305.243042 
	C58.911591,303.550049 60.123367,302.306824 59.414543,300.688263 
	C58.242302,298.011444 59.733662,296.297516 61.947197,295.042847 
	C63.434399,294.199860 64.573685,293.058228 65.255867,291.487305 
	C66.644653,288.289307 68.407211,286.130768 72.452682,287.828796 
	C73.420860,288.235199 74.940285,287.328339 76.588257,286.997925 
	C77.808746,287.706024 78.053581,288.666595 77.969109,290.110138 
	C77.305252,292.998840 75.361092,294.685242 73.799301,297.043701 
	C73.068336,297.888062 72.479729,298.328522 71.489113,298.823547 
	C68.404854,299.272736 66.260300,300.589905 64.653374,302.829834 
	C63.696255,304.163940 62.708889,305.476410 60.564056,305.752075 
	C59.980572,305.654724 59.756199,305.570435 59.266903,305.243042 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M79.561783,267.956329 
	C77.690620,275.401154 78.037086,276.262146 83.518036,278.312073 
	C85.088211,278.899292 86.248314,278.836761 87.687302,277.929443 
	C89.960144,276.496338 92.441711,275.468597 95.530609,277.004944 
	C96.252663,277.405792 96.463341,277.819214 96.411606,278.664246 
	C94.085175,281.102448 90.959473,281.493866 88.161011,283.204956 
	C87.071175,283.471588 86.265739,283.132935 85.208321,283.496521 
	C84.555862,283.696533 84.170738,283.757996 83.484787,283.781250 
	C82.221832,283.643372 81.229774,283.687195 80.046661,283.216064 
	C78.708885,280.933624 78.097992,278.137177 74.564636,279.880951 
	C73.740059,280.287872 71.878029,279.512024 72.485924,278.748322 
	C74.879234,275.741547 74.374939,271.726532 76.440353,268.750366 
	C76.858505,268.147797 78.235443,268.210632 79.561783,267.956329 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M86.728333,282.701050 
	C89.335907,280.664764 92.257607,279.788300 95.560196,278.897949 
	C96.085052,279.063599 96.236755,279.258972 96.431122,279.823425 
	C94.999344,283.012634 91.847397,283.649231 89.278732,285.632080 
	C85.015533,286.723907 81.730957,288.088654 81.826981,293.149292 
	C81.879959,295.941498 79.965385,297.230957 76.796043,296.759949 
	C74.281075,294.759735 77.396637,293.392731 76.925972,291.338165 
	C76.944351,289.922363 76.980919,288.849274 76.993912,287.373291 
	C77.862442,286.147217 78.754555,285.324066 79.810577,284.242432 
	C80.643318,283.361755 81.504929,283.197327 82.669182,282.935669 
	C83.415726,282.875641 83.839447,282.864899 84.580574,282.835938 
	C85.357582,282.537537 85.853622,282.436768 86.728333,282.701050 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M78.723625,258.252625 
	C83.567589,258.200195 81.829445,260.337372 80.180939,262.699585 
	C79.566612,263.003052 79.119949,263.002441 78.338516,263.002136 
	C78.152000,261.503387 78.300270,260.004333 78.723625,258.252625 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M58.879936,305.209473 
	C59.172344,305.002625 59.342709,305.005219 59.768623,305.011627 
	C60.542938,305.620178 60.894035,306.300079 60.937790,307.498840 
	C60.734680,308.683563 60.332424,309.340668 59.369301,310.051758 
	C58.766113,310.263855 58.507164,310.290344 57.914864,310.113525 
	C57.653870,308.782684 57.726227,307.655151 57.892387,306.253265 
	C58.310863,305.885193 58.568100,305.698547 58.879936,305.209473 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M82.802307,282.668030 
	C82.520424,283.883759 81.613243,283.990692 80.321922,284.003235 
	C79.974503,283.983948 79.882370,283.514160 79.846275,283.277649 
	C80.745872,282.851349 81.507599,281.837219 82.802307,282.668030 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M73.526596,269.543121 
	C73.478516,269.570404 73.574677,269.515839 73.526596,269.543121 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M921.691772,172.886124 
	C924.420593,173.825073 924.462646,175.903763 922.217407,175.706573 
	C917.393677,175.282944 917.374023,180.503479 913.869751,181.729538 
	C912.245056,182.298004 914.591125,184.666382 916.060730,185.614273 
	C918.297058,187.056732 921.187195,187.497498 920.959656,191.471573 
	C920.841980,193.527100 922.886780,193.846878 925.427795,193.334717 
	C928.121216,192.791824 932.314087,189.763855 933.985840,195.625046 
	C933.992310,195.993088 934.000000,195.999527 933.996582,195.996674 
	C933.277588,196.985809 932.769409,198.178284 931.222656,198.792999 
	C930.662476,199.090820 930.405518,199.278595 930.091064,199.756485 
	C928.627747,201.113464 929.170166,202.770432 928.699036,204.495117 
	C928.416748,204.969177 928.268677,205.137146 927.815186,205.447174 
	C924.916077,205.816193 923.658813,204.471756 923.292175,201.673569 
	C922.329529,203.218933 921.112915,204.160828 919.626831,205.315277 
	C919.171082,205.662415 918.980896,205.792709 918.489258,206.094788 
	C914.901917,209.161758 912.122803,212.895828 906.767090,212.870148 
	C905.954712,212.602402 905.549072,212.335159 904.969727,211.700989 
	C904.678406,211.167313 904.604553,210.932632 904.554077,210.330246 
	C904.891846,209.008102 905.560791,208.304947 906.497925,207.366058 
	C908.395264,203.633804 910.681396,200.417419 911.869568,196.778900 
	C912.529541,194.758194 913.504700,193.330154 915.594299,191.785522 
	C912.392395,191.147934 910.799255,189.184006 909.519592,186.414276 
	C909.246460,185.874023 909.153442,185.647919 908.964111,185.064423 
	C907.963806,180.677521 910.694641,178.834015 913.766479,176.802307 
	C916.522705,175.391937 917.284668,170.891434 921.691772,172.886124 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M954.829590,237.843903 
	C955.255066,238.607742 955.422241,239.416153 955.891296,239.672699 
	C957.165771,240.369736 959.097717,240.654236 958.727234,242.534912 
	C958.247925,244.968063 956.256958,242.953796 955.144470,243.384583 
	C950.932739,245.015442 947.874756,244.844025 945.684021,240.032257 
	C944.636169,237.730865 941.864075,239.457474 939.827576,239.800522 
	C938.424255,240.036880 936.647217,240.634338 935.692261,239.252747 
	C934.768250,237.915924 936.867676,237.101196 937.035889,235.457489 
	C937.389343,234.958374 937.638794,234.755569 937.978394,234.301453 
	C939.504578,230.837265 941.452942,230.318100 944.726624,231.987167 
	C947.643616,233.474350 950.426086,235.212128 953.669678,236.832245 
	C954.245667,237.197281 954.500977,237.384796 954.829590,237.843903 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M934.255737,212.702133 
	C929.826782,216.082840 930.689697,221.215698 928.963074,225.700897 
	C928.735413,226.218979 928.537048,226.342270 927.988037,226.461212 
	C927.200195,226.280960 926.832092,226.013321 926.411011,225.320969 
	C926.006714,223.869705 926.353027,222.804474 926.738953,221.431519 
	C927.122803,220.177963 927.447815,219.230423 927.153564,217.912506 
	C926.954041,217.013214 926.869690,216.400803 926.716614,215.476074 
	C926.583435,214.511826 926.434143,213.877258 926.179016,212.915405 
	C925.980042,211.888474 925.978455,211.191238 926.093384,210.137848 
	C926.633545,208.435211 926.571228,206.914215 927.654297,205.398254 
	C927.879517,205.115097 927.946655,205.051193 927.979919,205.018951 
	C928.399780,204.615326 928.857422,204.474350 929.689331,204.787384 
	C930.463928,205.326767 930.935120,205.642578 931.703186,205.980255 
	C935.776611,206.983078 936.611084,209.117371 934.255737,212.702133 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M953.829773,236.742371 
	C949.760864,238.130676 947.485901,234.381363 944.396118,232.916565 
	C941.641968,231.610855 940.368164,232.252243 938.415588,234.029144 
	C937.813599,231.506973 938.880432,229.437210 941.539307,230.121735 
	C945.994385,231.268723 950.925415,231.889725 953.829773,236.742371 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M934.344360,212.962479 
	C934.266235,210.647446 935.347290,208.077682 932.155762,206.298264 
	C936.006775,203.197006 935.850464,207.710510 936.952332,209.158417 
	C938.626526,211.358215 936.260620,212.121460 934.344360,212.962479 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M929.996826,204.591156 
	C929.552673,205.005478 929.112732,205.000000 928.342957,204.990601 
	C928.311829,203.343094 927.173096,201.331421 929.615234,200.056702 
	C929.985046,201.370010 929.993042,202.770676 929.996826,204.591156 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M931.347717,198.977997 
	C931.795410,198.073120 932.614929,197.165939 933.713867,196.126282 
	C934.041443,197.602432 933.828125,199.011612 931.347717,198.977997 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M927.616638,226.658096 
	C927.995483,226.290527 928.181763,226.243774 928.650879,226.146362 
	C928.977844,226.505859 929.021851,226.916046 929.136353,227.628357 
	C928.971436,228.209549 928.736084,228.488617 928.257446,228.891449 
	C927.404846,228.456009 927.270630,227.759155 927.616638,226.658096 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M915.243286,176.724136 
	C914.135437,179.933289 908.757080,179.736313 910.037781,184.565186 
	C906.607727,186.316147 906.576416,183.553345 906.154846,181.584778 
	C905.811035,179.979462 906.449036,178.266891 906.036743,176.626724 
	C905.510254,174.532272 905.219604,172.414963 902.052612,172.618668 
	C898.772339,172.829620 898.205933,170.042938 897.569153,167.596603 
	C896.924744,165.120712 897.606323,163.400467 900.250610,162.621475 
	C901.274719,162.319794 902.873779,161.837570 901.860657,160.333206 
	C901.239868,159.411469 900.029724,160.176590 899.191345,160.727966 
	C898.498535,161.183594 897.946960,161.849258 897.203491,162.222885 
	C894.894592,163.383270 892.817505,162.873260 891.674438,160.653549 
	C890.495972,158.365341 891.940857,156.688904 894.038391,156.042191 
	C897.642700,154.930923 899.851746,151.739655 903.229187,150.267807 
	C904.301453,149.800522 903.339600,149.016922 902.007080,149.059509 
	C900.670715,149.191086 899.695923,149.149796 898.384094,148.740051 
	C896.519287,147.547256 895.331421,146.264450 894.989502,143.994659 
	C894.998840,143.387009 895.028992,143.148514 895.163330,142.572617 
	C895.902710,140.922424 897.207642,139.729645 895.817017,137.820496 
	C895.505432,137.363541 895.387329,137.174667 895.116638,136.685883 
	C894.096863,134.168579 893.540894,131.976532 895.429932,129.955688 
	C897.408325,127.839272 895.387756,127.264610 893.838440,126.142288 
	C893.473694,122.544411 896.572266,123.158005 898.622192,122.023392 
	C901.547180,121.844765 904.105835,121.655861 905.724670,124.773972 
	C907.492859,127.260811 906.053345,128.821060 904.527588,130.089432 
	C903.223999,131.173050 902.291443,131.695877 904.155579,133.260635 
	C905.514099,134.400940 905.734009,137.318481 908.544556,135.336105 
	C910.615051,133.875732 912.934082,133.129608 914.794006,135.340363 
	C916.562500,137.442413 915.100586,139.713181 913.640259,141.098053 
	C908.523499,145.950500 911.844177,149.242081 915.551758,152.948059 
	C915.908142,154.015640 915.854614,154.735092 915.302734,155.739075 
	C914.789856,156.148636 914.529846,156.318710 914.193176,156.761993 
	C914.994324,159.931183 915.995667,162.899506 914.089355,166.222473 
	C913.857971,166.726929 913.756165,166.923477 913.483398,167.400681 
	C912.442993,168.795334 912.153564,169.927994 913.419800,171.353851 
	C914.219910,172.303070 914.822449,172.985886 915.515442,174.041351 
	C915.865662,175.081604 915.823547,175.777847 915.243286,176.724136 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M915.389038,153.038727 
	C913.952271,152.026871 913.004028,150.650070 911.726440,150.192215 
	C907.142517,148.549301 905.715881,147.110306 910.687866,143.643936 
	C912.007751,142.723679 912.679993,139.874588 914.282288,138.244888 
	C915.045471,137.468521 914.876709,136.342285 913.906982,135.654160 
	C912.521790,134.671219 910.898315,134.671341 909.820496,135.866272 
	C906.388611,139.671036 904.451294,139.043106 903.596680,134.090469 
	C903.450378,133.242706 902.740540,133.256516 902.055115,133.468689 
	C900.810669,133.853882 900.126343,133.380219 900.107056,132.095139 
	C900.090088,130.962265 900.643433,130.177109 901.832886,130.083878 
	C904.537292,129.871887 905.237427,127.825165 905.957520,125.366119 
	C912.229492,122.970345 912.300598,122.968559 912.475525,129.941177 
	C912.505005,131.113235 912.682983,131.779816 913.663635,132.304932 
	C918.778259,135.043701 916.412231,139.415771 916.024597,143.670715 
	C915.992676,143.993698 916.000000,143.999329 915.996582,143.996262 
	C915.344360,144.647141 914.725525,145.315750 914.904785,146.678406 
	C915.005859,148.476013 914.830261,149.978043 915.895386,151.615082 
	C916.232605,152.409103 916.135620,152.797195 915.389038,153.038727 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M915.317444,173.897766 
	C914.111816,173.919113 913.340332,173.670395 913.020447,172.366882 
	C911.954163,170.713699 911.846252,169.270569 912.734253,167.366791 
	C912.992554,166.989822 913.000000,167.000000 912.994873,166.997787 
	C914.768005,164.825424 915.349731,162.257675 915.731812,159.273010 
	C918.063293,159.213547 918.507446,160.839203 918.929932,162.432434 
	C919.175964,163.360611 919.106262,164.698029 920.293823,164.750244 
	C926.056824,165.003540 922.415955,168.152161 922.026611,170.646805 
	C920.114685,172.509521 917.153687,171.518600 915.317444,173.897766 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M914.998169,174.370895 
	C915.915649,170.541397 918.224121,170.000092 921.623291,171.001068 
	C921.989136,171.445618 921.999573,171.883102 922.017334,172.648376 
	C919.199341,173.128922 917.331482,174.832397 915.350220,176.947891 
	C915.009644,176.251770 915.002258,175.499435 914.998169,174.370895 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M915.877380,158.744629 
	C915.907776,161.775558 916.710449,164.814804 913.315918,166.829773 
	C914.632019,164.008026 913.054199,160.698517 913.997070,157.297745 
	C914.632141,157.467499 915.195862,157.973404 915.877380,158.744629 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M915.037720,153.365723 
	C915.370972,152.862381 915.627808,152.662292 915.940430,152.162399 
	C919.222839,153.506912 918.101074,154.767578 915.436768,155.997620 
	C915.073242,155.284576 915.048523,154.516968 915.037720,153.365723 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M915.228882,146.801636 
	C914.239868,146.841919 913.297485,146.816879 913.418701,145.748901 
	C913.538086,144.697281 914.402893,144.291855 915.666809,144.029816 
	C915.825745,144.859100 915.658325,145.725006 915.228882,146.801636 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M928.340576,241.808197 
	C928.263123,242.867599 928.194153,243.581314 928.075500,244.650284 
	C926.259399,247.478088 924.909302,249.932281 928.668213,252.338287 
	C931.297546,253.809189 933.908569,254.827881 934.813721,257.644714 
	C935.402710,259.477692 935.345764,261.283966 933.116089,262.625488 
	C929.734131,262.069702 926.735596,263.824799 923.390747,262.687683 
	C919.165161,258.818451 915.979919,254.560287 913.498169,249.359955 
	C913.143066,248.627747 912.997620,248.186615 912.830200,247.390625 
	C912.694397,246.220078 912.571594,245.326508 912.882568,244.585831 
	C914.685913,240.290558 913.050842,237.747345 908.642395,236.137299 
	C907.767578,234.705093 907.723511,233.457870 907.887024,231.808533 
	C908.408264,229.997421 908.621704,228.502289 909.150818,226.707443 
	C909.453125,226.175140 909.603943,225.982224 910.069580,225.607254 
	C913.216370,225.721924 914.296021,223.515366 915.967773,221.507462 
	C916.497314,221.184174 916.727051,221.079865 917.326416,220.872070 
	C919.785461,220.477875 921.865662,220.438263 924.277344,221.151733 
	C924.829956,221.457748 925.028992,221.611374 925.412964,222.086029 
	C924.847839,227.649017 925.176208,232.747787 927.547180,237.594528 
	C928.138245,238.802734 928.438599,240.099274 928.340576,241.808197 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M951.778076,251.759888 
	C952.096313,252.524277 952.224792,252.992599 952.448425,253.409943 
	C952.929749,254.308243 955.114319,253.783585 954.451965,255.547897 
	C953.855225,257.137604 952.500671,256.148560 951.496338,255.994461 
	C950.229370,255.800049 948.994141,255.399460 947.144348,254.796356 
	C946.118347,251.931030 944.319702,251.719604 942.083374,251.065872 
	C937.042297,249.592255 932.351624,251.455200 927.196289,251.345673 
	C925.668640,249.258743 925.718933,247.558777 927.946899,245.963013 
	C930.634766,246.988678 933.237427,246.199844 935.375366,246.612000 
	C940.876953,247.672562 947.632385,245.290527 951.778076,251.759888 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M928.613281,242.071259 
	C927.037292,238.194733 922.927551,235.211624 924.791565,230.100418 
	C925.644897,227.760574 921.543640,227.467606 921.402283,225.046127 
	C922.038696,223.273590 924.499939,225.933899 924.956421,223.305389 
	C926.404480,222.577621 926.384583,223.649597 926.807983,224.743362 
	C927.193726,225.297806 927.354065,225.601624 927.661743,226.121368 
	C927.848938,227.005325 927.888733,227.673370 927.971313,228.678314 
	C928.014160,229.015213 928.017822,229.507721 928.009705,229.753860 
	C928.111694,231.527908 927.188904,233.132248 928.499756,234.882248 
	C928.775940,235.860413 928.892944,236.539444 929.054688,237.560242 
	C929.452576,238.353287 929.783386,238.815628 929.815674,239.694641 
	C929.817444,240.794632 929.713196,241.549927 928.613281,242.071259 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M928.275024,245.620392 
	C927.243225,247.256516 926.459778,248.741592 927.711670,250.805389 
	C928.365845,251.427612 928.506104,251.884003 928.175659,252.730286 
	C924.024841,251.546661 923.744873,248.385239 927.640259,245.048538 
	C928.025757,245.005539 928.206787,245.409729 928.275024,245.620392 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M928.764038,234.788574 
	C925.565186,234.243668 925.951111,232.445358 927.734924,230.183655 
	C929.471558,231.232224 928.807068,232.885696 928.764038,234.788574 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M930.038330,239.844788 
	C929.536194,239.482178 929.287354,238.985626 929.068970,238.195557 
	C930.027466,238.155197 931.083740,238.323883 930.038330,239.844788 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M960.935181,287.625427 
	C958.876343,287.563019 956.882568,286.995392 955.295654,285.333862 
	C954.239929,284.228485 953.389343,282.697784 954.777466,282.047943 
	C959.687195,279.749603 956.190979,276.576935 955.988892,273.375214 
	C958.568848,272.059357 961.012146,272.869629 963.732910,273.767578 
	C964.000000,274.000000 964.025879,274.477386 964.040039,274.715576 
	C964.948608,277.325836 966.097961,279.204620 961.968567,280.245911 
	C957.936401,281.262726 960.527771,284.731781 960.935181,287.625427 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M963.966370,273.640259 
	C961.572205,273.592133 958.837708,275.714813 956.337891,273.056213 
	C956.025330,272.265564 956.050659,271.531128 956.083862,270.429321 
	C957.489075,268.554352 956.524536,267.491547 955.176514,266.260406 
	C954.940369,266.064301 954.474121,266.040436 954.242249,266.014221 
	C954.021790,264.520935 953.029480,263.623505 952.160767,262.628571 
	C950.558838,260.793884 951.305359,259.701202 953.714844,259.069763 
	C958.298096,263.136322 962.086792,267.534393 963.966370,273.640259 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M948.813110,262.855225 
	C948.946228,264.903748 948.999268,266.408997 951.257507,267.375305 
	C954.165405,268.619568 955.019226,270.978027 952.711365,273.748688 
	C952.004883,274.596802 950.769165,275.376099 951.716309,276.687775 
	C953.277649,278.850342 952.099060,278.182404 950.475159,278.480255 
	C947.790894,278.972534 945.749207,280.552673 948.812866,283.770325 
	C949.084045,284.290497 949.171692,284.542938 949.012939,285.111389 
	C947.496460,286.155121 946.252014,286.058441 944.752930,285.055634 
	C942.749756,282.267700 943.029724,279.900909 945.027771,277.642242 
	C946.630676,275.830200 947.342163,274.510010 943.940918,273.797333 
	C938.815796,272.723541 937.625732,267.788513 935.286804,263.697052 
	C936.200745,261.060486 936.636658,258.607574 938.077026,256.593567 
	C939.256531,254.944260 940.968994,254.651031 943.213928,255.163864 
	C944.244995,255.672607 944.612976,256.272705 944.862549,257.319366 
	C945.012695,258.415771 944.752441,259.236725 945.442444,260.136536 
	C946.305664,260.740601 946.996338,261.093048 947.758545,261.835815 
	C948.240234,262.195862 948.491455,262.390381 948.813110,262.855225 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M947.923462,261.735474 
	C947.156372,261.793182 946.370850,261.524109 945.297852,261.123840 
	C944.576660,260.598083 944.216003,260.153931 944.015442,259.290344 
	C943.975525,258.252808 943.968994,257.503143 943.955627,256.378754 
	C944.339233,254.925430 945.104370,254.427612 946.573486,254.707489 
	C946.897217,254.895950 946.954834,254.954239 946.983765,254.983521 
	C947.106995,257.173096 946.168579,259.477478 947.923462,261.735474 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M762.831299,52.319054 
	C769.656067,51.166000 773.061523,56.728439 777.405457,59.961102 
	C779.333801,61.396145 780.100342,61.360020 781.769409,60.624180 
	C786.509705,58.534279 789.595947,60.309811 789.922607,65.603073 
	C790.096497,68.418922 790.831238,69.265518 793.425720,67.823975 
	C795.684326,66.569031 797.515625,67.123550 798.961304,69.626595 
	C796.722107,72.658524 793.858215,73.480865 790.078125,72.492966 
	C788.384521,72.413361 787.154236,72.122116 785.818237,71.085564 
	C785.382080,70.181839 785.483948,71.045189 785.323303,70.398170 
	C783.727783,63.972023 783.727783,63.972023 778.266174,68.529976 
	C776.676697,69.070099 775.495850,68.888535 774.118286,67.916382 
	C773.696106,67.484734 773.553040,67.293434 773.263550,66.784454 
	C772.458191,65.243744 773.335205,63.467476 771.417236,62.585449 
	C770.910034,62.565243 770.705811,62.548512 770.204590,62.442875 
	C769.179932,61.942493 768.609863,61.339752 767.781738,60.567451 
	C766.791809,59.402355 766.053223,58.417282 764.574341,57.791092 
	C763.956543,57.338154 763.660889,57.012260 763.246582,56.346092 
	C762.692566,54.936230 762.459167,53.810486 762.831299,52.319054 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M831.076538,88.657578 
	C830.408691,90.773094 829.705444,92.520424 828.973755,94.621483 
	C827.879944,95.846519 827.978088,97.033531 828.100037,98.573624 
	C828.809814,99.838875 829.126404,100.834465 828.717163,102.336571 
	C828.262878,103.144180 827.852966,103.429924 826.961426,103.547318 
	C825.561584,103.285210 825.173096,102.424202 824.639893,101.294556 
	C824.291687,100.466782 824.108765,99.909889 823.696228,99.171555 
	C823.352051,98.685669 823.116394,98.453445 822.626282,98.157791 
	C822.408020,98.057571 821.936462,97.955437 821.693115,97.966690 
	C820.937683,98.094513 820.473389,98.323410 819.764160,98.722290 
	C817.886536,99.290359 816.530701,100.322762 814.632568,100.897552 
	C812.046082,101.031235 811.360718,99.510925 810.817749,97.406693 
	C810.507446,96.979126 810.368652,96.817047 810.006836,96.419449 
	C809.202881,95.510735 808.535645,94.870781 809.138428,93.609642 
	C812.210144,92.778877 813.482361,91.931099 813.917053,88.210976 
	C814.315674,84.798851 818.134766,81.673470 821.649109,79.126060 
	C822.425415,79.079597 822.854980,79.071411 823.606689,79.060722 
	C824.633240,79.849785 824.952759,80.767250 824.710815,82.167633 
	C823.425354,84.489845 820.979126,85.450958 820.378113,87.903152 
	C820.084045,89.102875 819.972168,90.190575 820.960083,91.107597 
	C821.784424,91.872795 822.722656,91.991852 823.676270,91.395477 
	C824.771606,90.710533 825.224426,89.681282 824.938110,88.416954 
	C824.580139,86.836197 823.574951,85.342812 824.544800,83.331085 
	C824.941284,82.933914 825.150635,82.831383 825.682434,82.864120 
	C826.194275,84.538437 826.699707,86.052521 828.578003,85.584343 
	C831.383606,84.885010 830.864014,86.814720 831.076538,88.657578 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M789.721130,71.351410 
	C792.984009,72.855713 795.527039,70.627884 798.650757,69.988464 
	C799.029419,69.973137 799.506775,70.004974 799.745789,70.014870 
	C801.934082,71.552658 804.059692,72.462166 806.620361,72.130432 
	C808.114319,71.936890 809.480530,72.310394 810.743164,73.769417 
	C810.997681,74.758644 811.014954,75.504456 811.032104,76.623291 
	C811.026306,77.594376 811.195435,78.132324 811.784058,78.802933 
	C811.986267,79.010323 812.000000,79.000000 811.994751,79.005325 
	C813.455139,81.803795 814.524109,84.543350 810.551270,86.710846 
	C806.410767,85.930008 803.776001,84.042442 802.113586,80.308670 
	C800.833435,77.433365 798.746765,75.108437 794.749695,77.714317 
	C791.843628,79.608948 789.740051,77.062340 789.721130,71.351410 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M808.811157,86.248497 
	C813.864197,85.749153 811.360046,82.075363 811.992676,79.436951 
	C813.327637,80.169777 814.665710,81.328926 816.092285,82.564728 
	C817.855957,81.107857 817.330688,77.928947 820.015564,77.235222 
	C820.975220,76.987282 821.583740,77.482887 821.944031,78.736252 
	C819.119202,81.439362 818.856201,86.035614 814.836914,87.445168 
	C814.618042,87.521919 814.408813,88.409294 814.577087,88.692238 
	C818.592957,95.446968 813.049744,93.661446 809.441040,93.984673 
	C808.793945,94.099159 808.580200,94.215248 808.035400,94.176178 
	C807.564819,93.820618 807.425415,93.620193 807.166382,93.073135 
	C806.699829,90.968567 807.437988,89.377792 807.954102,87.392860 
	C808.229797,86.852699 808.367615,86.651253 808.811157,86.248497 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M810.987305,73.640656 
	C809.274963,73.939758 807.775330,72.611832 805.869934,73.504402 
	C802.949097,74.872551 801.506470,72.745758 800.051453,70.368523 
	C803.139954,69.052078 806.179688,70.486549 809.284668,70.465118 
	C811.527039,70.449638 810.769775,72.127472 810.987305,73.640656 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M755.671387,48.825394 
	C756.495911,50.195957 757.059326,51.309391 757.844727,52.651321 
	C758.920654,53.877934 759.555481,54.961517 759.305359,56.696892 
	C758.469788,57.579281 757.378601,57.863205 756.958984,57.213345 
	C755.069946,54.287346 751.787170,54.284134 748.871216,52.829300 
	C748.607361,51.716339 748.949158,51.096073 749.760376,50.296844 
	C750.020081,50.031094 749.998596,50.000668 750.014526,50.009857 
	C750.906799,49.094498 751.980896,48.593040 753.616943,48.487289 
	C754.468567,48.355370 754.944702,48.391167 755.671387,48.825394 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M758.945801,57.339851 
	C758.688904,55.852554 758.351624,54.728333 758.040527,53.241966 
	C759.465637,52.586823 760.864685,52.293819 762.626892,52.004326 
	C763.360107,53.066048 763.730042,54.124256 764.123413,55.521412 
	C763.408325,56.829849 762.571228,57.689335 761.126282,58.384869 
	C759.991394,58.598293 759.319763,58.407497 758.945801,57.339851 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M824.228516,83.151031 
	C824.012329,81.913857 823.987183,80.874008 823.945435,79.446182 
	C825.445923,79.681267 826.519592,80.711067 826.977234,82.670166 
	C826.846313,83.000862 826.677979,83.001732 826.257324,83.000992 
	C825.822266,83.014252 825.639709,83.029106 825.184082,83.073532 
	C824.747131,83.184860 824.583313,83.266602 824.228516,83.151031 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M754.187622,48.682518 
	C752.898193,49.317921 751.804749,49.630955 750.370911,49.981522 
	C750.017822,49.512707 750.005127,49.006367 749.997803,48.115662 
	C751.090454,47.732624 752.177734,47.733948 753.671631,47.702721 
	C754.179993,47.900166 754.281799,48.130161 754.187622,48.682518 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M811.968994,78.714691 
	C811.085327,78.814957 809.933044,78.831245 810.803772,77.165993 
	C811.338501,77.470558 811.645081,77.944809 811.968994,78.714691 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M754.315430,49.020706 
	C754.000000,48.783108 754.008301,48.561337 754.047485,48.004868 
	C755.209412,46.789661 755.700378,47.365757 755.940796,48.787560 
	C755.501343,49.067207 755.070251,49.051868 754.315430,49.020706 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M716.769653,37.376823 
	C717.786621,37.134945 718.521973,37.079643 719.626221,37.032074 
	C720.589478,37.038685 721.117859,37.217239 721.736572,37.845543 
	C721.907349,38.075809 721.983826,38.017033 721.955933,38.056343 
	C723.212891,39.470238 725.117126,39.509232 727.006104,40.397598 
	C728.351318,40.531841 729.266785,40.713284 729.468994,42.302444 
	C729.309937,42.896439 729.182861,43.114685 728.741882,43.547440 
	C725.403748,44.877529 722.491211,43.255653 719.148926,43.402618 
	C718.558228,43.416256 718.325195,43.425140 717.749207,43.426769 
	C715.353088,43.469299 713.119995,42.234543 711.051636,44.466248 
	C710.531311,44.754459 710.304443,44.845226 709.708862,45.011280 
	C702.007324,44.316235 700.294434,42.636581 698.076172,33.618980 
	C698.232300,32.381042 698.604614,31.665670 699.477844,30.787899 
	C700.518799,30.170219 701.285950,30.065063 702.439270,30.284222 
	C706.016724,35.344925 711.450500,36.075573 716.769653,37.376823 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M716.712769,37.076385 
	C716.844727,37.623608 716.727539,38.350704 716.417786,38.444908 
	C713.333496,39.383118 704.261353,34.804153 703.026917,31.412033 
	C705.432434,29.044115 706.780518,31.321686 708.730713,32.708855 
	C712.352844,32.697090 714.266174,35.008450 716.712769,37.076385 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M737.738159,47.284134 
	C739.258484,44.324608 741.303650,45.174950 743.653809,45.887642 
	C744.325867,46.329296 744.513550,46.774311 744.454346,47.641865 
	C743.778442,48.999878 742.643433,49.558834 742.193787,50.672752 
	C741.558594,52.246040 742.608826,54.760101 740.231750,55.296421 
	C738.099854,55.777431 736.698364,53.858788 734.881775,52.443249 
	C733.657776,49.441345 736.009827,48.763981 737.738159,47.284134 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M737.643555,47.060349 
	C738.052795,49.157516 735.232422,49.308990 735.022461,51.633583 
	C734.791077,52.147392 734.603027,52.323257 734.047485,52.623798 
	C731.652588,52.901661 730.250427,51.720226 728.648315,50.160507 
	C728.130371,49.041683 728.123718,48.251312 728.602417,47.112137 
	C729.553223,46.284313 730.334717,46.203033 731.495605,46.703163 
	C733.590942,48.902416 735.442444,47.594112 737.643555,47.060349 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M708.975769,32.605255 
	C706.767761,33.676033 705.785645,30.861040 703.345703,31.030836 
	C702.231812,31.016506 701.490356,30.997383 700.377625,30.972961 
	C699.411438,30.471931 699.245483,29.817873 699.688416,28.507442 
	C702.970886,29.468084 707.431091,27.805069 708.975769,32.605255 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M743.963989,48.406288 
	C744.001709,47.539589 744.000977,47.082790 744.000854,46.282455 
	C746.079468,44.391434 746.595154,46.073318 746.882568,47.509518 
	C747.173584,48.963165 747.572632,50.020397 749.645264,50.007801 
	C749.836426,50.580921 749.652710,51.130745 749.226624,51.852310 
	C745.489197,53.807140 744.719177,51.293049 743.963989,48.406288 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M729.094116,43.311317 
	C728.925964,42.073647 728.634888,41.310158 727.302307,41.036449 
	C726.865479,40.807026 726.745972,40.628979 726.809509,40.186447 
	C730.295105,39.745209 732.240967,41.140850 732.153381,44.782051 
	C730.501953,45.526314 729.804016,44.639477 729.094116,43.311317 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M726.813232,39.615288 
	C726.992371,40.099209 726.992249,40.276459 726.988525,40.719391 
	C725.029358,40.960373 722.890747,41.350723 721.987183,38.451195 
	C723.627869,37.991058 725.381958,37.675735 726.813232,39.615288 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M729.019409,42.984978 
	C729.812378,43.556431 730.593689,44.136677 731.636047,44.842445 
	C731.901611,45.407448 731.906250,45.846924 731.870728,46.612801 
	C731.138489,46.966801 730.446350,46.994396 729.405334,47.030357 
	C728.020508,46.091682 727.984070,44.968712 728.732849,43.382076 
	C728.987793,43.011753 729.007812,42.993767 729.019409,42.984978 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M695.779114,26.308239 
	C697.064026,25.979420 698.131653,25.975460 699.599670,25.967203 
	C700.011108,26.402199 700.022217,26.841490 700.050415,27.608345 
	C700.067444,27.935907 700.029846,27.971252 700.011230,27.989119 
	C699.393494,28.536858 698.715942,28.895706 697.513428,28.934052 
	C696.874634,28.816181 696.627747,28.728367 696.074463,28.394829 
	C695.519714,27.668203 695.450806,27.162893 695.779114,26.308239 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M721.909546,37.776913 
	C721.374329,37.892288 720.841248,37.708763 720.151733,37.282524 
	C720.926514,35.906742 721.535278,36.184391 721.909546,37.776913 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M841.637878,104.989182 
	C843.117432,104.754189 844.234863,104.510384 845.672119,104.127274 
	C846.247559,104.212135 846.642700,104.515312 846.583984,104.747757 
	C845.238770,110.077744 842.175842,114.498177 838.648499,118.847260 
	C836.249634,118.317917 834.453613,117.229813 833.677185,115.385933 
	C832.429565,112.423149 829.998718,111.412140 826.997192,110.339684 
	C826.473511,109.593010 826.393188,109.084198 826.620605,108.187866 
	C827.789429,106.854210 829.101074,106.725060 830.765381,106.509407 
	C834.339233,104.775055 837.510315,102.867638 841.637878,104.989182 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M837.765259,118.372108 
	C838.899780,117.927452 840.724426,117.898453 840.442810,117.111038 
	C838.621582,112.019661 842.312805,110.063362 845.301575,107.529297 
	C845.893555,107.027313 845.784973,105.699150 845.995728,104.369995 
	C846.828674,102.809288 848.088013,103.028526 849.636719,103.002899 
	C851.781250,105.069992 853.560730,106.929443 856.685974,105.287384 
	C857.829407,104.686600 858.911682,105.366074 859.831299,106.705246 
	C858.533325,109.435539 856.452026,110.117447 853.705322,109.628929 
	C848.475891,108.698837 847.438110,109.546974 846.520996,115.437294 
	C846.770142,116.210144 846.856384,116.666275 846.893494,117.483200 
	C846.829651,118.066353 846.774597,118.296562 846.556274,118.843918 
	C845.926453,119.720924 845.336792,120.127190 844.406189,120.682274 
	C843.431702,121.201180 842.742310,121.460342 841.651917,121.706589 
	C839.381836,121.547844 837.694580,121.082779 837.765259,118.372108 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M841.780762,104.706383 
	C839.046387,106.442551 834.819641,103.766411 832.339661,107.932922 
	C830.794067,106.907455 830.036560,105.537727 829.330933,103.749329 
	C828.991577,102.093155 829.253723,100.808861 829.819458,99.264351 
	C830.485352,98.435173 831.486694,98.195442 831.568542,98.791481 
	C831.994751,101.895653 834.009033,102.347672 836.502869,101.978729 
	C838.801819,101.638618 840.091553,103.159859 841.780762,104.706383 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M829.686157,98.983658 
	C830.726929,100.120934 830.378784,101.264969 830.020996,102.708122 
	C829.613098,103.395081 829.152283,103.530045 828.300659,103.200165 
	C827.996094,101.905357 828.008301,100.822578 828.077576,99.334717 
	C828.548035,98.945236 828.961365,98.960838 829.686157,98.983658 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M695.867188,25.690620 
	C695.988831,26.420471 695.981323,26.857565 695.971558,27.622379 
	C695.797668,28.136980 695.625916,28.323860 695.105591,28.665329 
	C693.980652,28.978392 693.247192,28.878023 692.285095,28.203259 
	C691.031433,25.745138 694.911194,23.780552 692.159790,21.245625 
	C691.997864,20.994417 691.993835,20.499046 691.998230,20.251442 
	C692.812561,20.254778 693.622559,20.505718 694.731201,20.880671 
	C695.027283,21.443226 695.024536,21.881773 695.025146,22.648842 
	C695.264954,23.784197 695.501465,24.591030 695.867188,25.690620 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M689.711914,21.040173 
	C689.723572,22.696667 688.951233,23.396532 687.169067,22.106411 
	C687.610779,21.040884 688.476562,20.972935 689.711914,21.040173 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M937.675537,791.143616 
	C939.839966,792.043762 941.315674,793.345947 940.854614,795.731262 
	C940.475281,797.693970 939.162537,797.834717 937.443237,797.448730 
	C936.393799,797.213074 936.082764,798.155518 936.091431,799.052002 
	C936.106201,800.580139 936.862793,801.662598 938.386169,801.843872 
	C940.030029,802.039368 941.706543,801.949768 943.367554,802.015442 
	C945.687805,802.107056 947.509338,802.787292 948.288208,805.352112 
	C948.733154,806.817627 948.680054,807.876404 947.543762,808.921936 
	C946.234680,810.126526 945.330078,809.391663 944.072327,808.658325 
	C941.177185,806.970276 938.208923,804.616150 934.757385,807.743164 
	C932.389832,809.888062 933.201721,812.085327 935.079468,813.684570 
	C939.373901,817.341980 939.953613,820.268860 937.149780,825.371460 
	C936.360718,826.807556 933.724182,827.739624 935.026550,829.765808 
	C936.575806,832.176147 938.503174,829.365417 940.941223,829.421570 
	C939.067383,832.225525 939.000854,835.851135 935.529114,836.751709 
	C933.794373,837.201599 933.077881,835.451538 933.001709,833.406799 
	C932.283569,832.335938 931.530579,830.800415 930.997253,830.949280 
	C925.720093,832.422546 922.831665,827.839661 918.741211,826.433350 
	C915.967529,825.479736 914.769226,824.244385 915.841064,821.022461 
	C916.381836,819.467224 916.726562,818.230164 917.677673,816.844849 
	C919.061462,815.767517 920.346985,815.667786 922.021118,815.669434 
	C923.961914,815.780701 925.238098,816.525513 926.745728,817.859253 
	C925.079773,813.051270 931.157104,810.323425 929.344910,805.390869 
	C930.148743,803.568115 932.873962,804.492126 932.770630,801.960876 
	C932.546204,801.544861 932.451050,801.382385 932.198120,800.992188 
	C931.679749,800.331665 931.242249,800.017761 930.387939,799.836365 
	C929.672913,799.689880 929.285339,799.522705 928.698730,799.044434 
	C928.173523,798.128723 928.121582,797.445068 928.390137,796.408447 
	C928.669128,795.892273 928.818420,795.708801 929.252930,795.318359 
	C932.207092,793.957764 935.243225,793.553162 937.675537,791.143616 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M938.005127,790.601746 
	C937.535461,795.478088 933.455322,794.644165 930.450073,795.973694 
	C928.435364,794.336914 929.723633,792.655334 930.116943,790.982056 
	C930.530151,789.223877 930.911560,787.471313 930.756226,785.319092 
	C932.072815,782.653015 934.199951,782.996826 936.591431,783.018799 
	C937.991150,783.708313 939.160400,784.222900 939.833435,785.714661 
	C942.881531,785.951172 945.708252,786.010986 948.534058,785.981445 
	C949.987915,785.966248 951.732117,786.213013 951.849060,783.983337 
	C951.937988,782.288757 950.527100,781.946777 949.376343,781.273621 
	C947.831360,780.369995 944.588135,782.388977 944.645081,779.385071 
	C944.706970,776.120544 947.770996,774.953857 950.631714,774.324463 
	C952.075195,774.006836 953.597778,774.048828 955.496216,773.908020 
	C956.005493,775.044739 955.862976,776.054810 955.025269,777.031189 
	C953.227539,779.126770 954.504700,779.890015 956.573730,779.960693 
	C958.837219,780.038025 960.899475,780.224060 960.981628,783.593506 
	C958.090271,784.178101 956.696045,785.054138 958.221985,788.335144 
	C959.780762,791.686707 958.176147,793.696960 954.500916,793.255737 
	C953.117676,793.089661 951.899292,793.177551 950.610718,793.618347 
	C948.413330,794.370117 947.009766,793.790588 946.995178,791.198364 
	C946.986206,789.608032 946.770447,787.846008 944.717285,788.131714 
	C942.421936,788.451172 939.190613,786.164612 938.005127,790.601746 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M916.679688,820.750000 
	C916.432007,823.195801 915.681641,825.452332 919.445557,825.031128 
	C920.080994,824.959961 920.991943,824.965332 921.367920,825.342224 
	C924.109009,828.089417 926.432007,831.499756 931.235535,828.608643 
	C931.863770,828.230530 932.901917,830.744263 932.991821,832.659912 
	C932.552429,833.030029 932.109741,833.026245 931.334961,833.020447 
	C928.886047,833.504272 928.726990,834.807983 929.772461,836.757690 
	C930.004150,836.997253 930.000000,837.000000 930.001465,836.997986 
	C930.872559,839.355103 930.414551,841.094604 928.632874,843.163757 
	C927.197144,844.831299 923.783203,847.529968 928.650391,849.992065 
	C929.018799,850.446350 929.013916,850.890198 929.004517,851.667053 
	C928.550842,852.948792 927.363770,853.304382 927.044556,854.694702 
	C927.007690,855.169006 927.007202,855.335632 927.004578,855.752441 
	C924.281921,856.667236 921.561401,857.331970 918.453735,858.007324 
	C915.840759,857.547119 915.130188,855.939514 915.025635,853.523621 
	C915.148743,852.899658 915.229919,852.660767 915.520386,852.114929 
	C915.994812,851.605408 916.299255,851.480469 916.583740,851.324829 
	C918.242249,850.417358 921.283142,852.554443 921.728821,849.917603 
	C922.188110,847.199829 923.123779,844.014404 920.810486,841.417114 
	C919.423523,839.859863 918.463379,837.965332 919.115601,835.979492 
	C920.551514,831.607544 918.388794,829.089355 914.777771,826.746094 
	C913.937317,825.949341 913.524719,825.298096 913.116882,824.178833 
	C912.892334,822.935364 913.010559,822.122803 913.642578,821.016357 
	C914.687744,820.214355 915.504028,820.164001 916.679688,820.750000 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M930.008423,836.648682 
	C928.578674,836.869507 927.891602,836.039307 928.091736,834.671875 
	C928.288635,833.326355 928.660400,831.971924 930.791260,832.776428 
	C930.672852,834.112427 930.342773,835.206299 930.008423,836.648682 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M954.628052,767.973755 
	C955.815308,769.453491 955.994995,770.818298 954.014587,771.450500 
	C951.293091,772.319153 948.538879,774.215515 945.687866,772.064209 
	C943.852600,770.679321 942.211365,769.079468 939.361328,768.975891 
	C938.777832,768.493713 938.780396,768.026245 939.160278,767.253540 
	C939.543823,766.799011 939.727417,766.646912 940.235229,766.353760 
	C942.253967,765.972534 944.154358,766.225220 944.456055,763.645020 
	C944.616699,762.270508 945.718811,761.443481 947.549316,761.496887 
	C948.976746,762.212769 949.204407,763.352356 949.696045,764.728149 
	C950.369751,767.432495 952.287109,767.747803 954.628052,767.973755 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M951.560486,758.679260 
	C952.113770,759.166382 952.313354,759.449646 952.741211,759.860718 
	C952.795532,760.538025 952.621582,761.087463 952.208618,761.809998 
	C951.307922,762.508972 950.560791,762.743469 949.344604,762.479553 
	C948.219055,761.298401 948.335571,760.297180 949.325317,759.026489 
	C950.123230,758.487671 950.650452,758.396912 951.560486,758.679260 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M948.701050,761.786621 
	C949.703796,761.958862 950.459412,761.972900 951.592285,761.984985 
	C951.376282,762.820251 950.782959,763.657410 950.021729,764.760376 
	C948.448608,764.864868 947.982117,763.965210 947.998718,762.333130 
	C948.144653,761.867859 948.299316,761.748108 948.701050,761.786621 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M927.328247,854.967834 
	C926.184814,854.550537 925.173828,854.034485 925.483276,852.971252 
	C925.891785,851.567810 927.284424,852.166321 928.643188,852.000366 
	C930.693481,853.965759 928.985779,854.364258 927.328247,854.967834 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M947.824402,744.685059 
	C948.250000,745.772461 948.616394,746.550171 949.042969,747.645508 
	C949.167358,749.022583 948.645569,749.806824 947.628723,750.706055 
	C945.679138,751.710571 944.103943,752.659302 942.976257,754.136414 
	C941.135132,756.547791 939.276123,758.948242 941.805847,762.101013 
	C943.354126,764.030457 942.343933,765.461182 940.245850,766.757324 
	C939.996826,766.997559 940.000000,767.000000 939.998779,766.998413 
	C937.572937,769.053711 935.101318,766.227051 932.332275,767.167358 
	C931.273560,767.549927 930.547668,767.832581 929.573364,768.286011 
	C929.077515,768.472351 928.829895,768.487854 928.260315,768.315796 
	C927.797180,767.923523 927.655945,767.718872 927.425903,767.153442 
	C927.345337,766.546753 927.353516,766.300903 927.453186,765.700989 
	C928.685547,763.457947 927.975830,761.655945 927.390808,759.788574 
	C926.748352,757.738220 926.345215,755.635315 929.158813,754.280701 
	C932.023804,754.843506 934.068848,753.831482 935.403015,751.890442 
	C936.597900,750.151978 936.343201,748.712708 933.284607,749.434570 
	C932.482727,749.409607 932.079346,749.210327 931.534546,748.617371 
	C930.725403,746.541626 931.271118,745.143799 932.781982,744.078369 
	C933.717773,743.418457 934.125549,742.682007 932.944214,741.453003 
	C932.624390,740.972229 932.530151,740.754822 932.399170,740.177917 
	C932.891418,737.228027 934.932739,735.680481 936.810486,733.588501 
	C939.729919,732.482605 942.463013,732.108643 945.520752,731.522217 
	C948.189087,732.858704 951.554504,732.564697 952.527344,736.308350 
	C952.496155,738.492798 951.826355,740.037415 950.197632,741.479004 
	C949.005005,742.425720 947.310547,742.534973 947.824402,744.685059 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M928.412292,781.938965 
	C928.060669,783.163025 927.865356,783.978210 927.767578,785.199219 
	C927.818420,785.821594 927.771790,786.038086 927.639404,786.575562 
	C926.570190,788.182739 926.602661,789.566345 926.811523,791.131470 
	C927.204346,794.074829 926.235657,796.380920 922.667908,797.219543 
	C918.370605,797.665161 916.278564,795.334473 914.806335,792.132996 
	C914.236389,790.893555 913.533325,789.457642 912.052673,789.792419 
	C910.258667,790.197998 911.467834,792.014893 911.010254,793.480591 
	C910.787842,794.264465 910.577820,794.676697 910.067932,795.307068 
	C908.446228,796.396240 907.576721,797.739685 906.242493,799.109253 
	C905.707275,799.306580 905.469116,799.313538 904.921448,799.133789 
	C904.473633,798.747131 904.335327,798.547180 904.062622,798.005859 
	C902.926636,793.735901 904.463745,790.479614 906.785889,787.059631 
	C907.041260,786.515808 907.158691,786.307190 907.490417,785.814209 
	C910.054382,783.962830 910.234314,781.873779 909.274109,779.422852 
	C908.508667,777.469238 908.058472,775.508301 910.882080,774.239807 
	C913.948181,774.166748 915.630005,775.324341 915.727966,778.247375 
	C915.800720,780.418823 916.616150,782.057739 918.925415,782.631653 
	C920.075867,776.094360 921.688293,774.104492 926.695618,773.554688 
	C927.118164,773.943420 927.230957,774.154114 927.169312,774.677979 
	C926.924011,776.194458 926.942200,777.380127 927.815186,778.752258 
	C928.541321,779.801453 928.775940,780.610535 928.412292,781.938965 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M921.700806,796.381836 
	C925.136536,795.944519 926.248352,794.207397 925.570984,791.239868 
	C925.177124,789.514587 924.162231,787.704773 927.217896,786.939331 
	C927.658325,787.079102 927.762939,787.261597 927.987976,787.735840 
	C930.142273,790.153503 928.970947,792.868652 929.232056,795.646118 
	C928.981323,796.759644 928.940491,797.508972 928.942688,798.631958 
	C929.117004,799.204895 929.248291,799.404236 929.267822,799.943909 
	C927.055969,801.457031 927.243713,804.763733 924.109009,805.223694 
	C922.496277,804.149841 921.912781,802.728088 921.158081,800.959839 
	C920.714600,799.240845 920.711365,797.929993 921.700806,796.381836 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M928.830933,771.382935 
	C931.425354,771.196350 933.945679,770.168945 935.976440,772.797119 
	C936.453918,773.524475 936.561157,773.965576 936.283936,774.734375 
	C931.962463,779.867737 931.841125,779.878418 929.020264,775.296387 
	C928.152893,773.955383 927.930725,772.876770 928.830933,771.382935 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M936.328125,775.253052 
	C936.089111,774.596924 936.098694,774.171875 936.150146,773.432373 
	C937.668945,772.963623 939.080017,773.031128 940.467957,773.736938 
	C942.266846,774.651733 943.429443,775.872314 942.755859,777.983887 
	C942.037720,780.235352 940.057739,779.999573 938.272583,779.944336 
	C935.146362,779.847656 937.038452,777.706604 936.859070,776.403320 
	C936.815918,776.089966 936.673523,775.790222 936.328125,775.253052 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M929.287170,796.024902 
	C928.403503,793.662537 927.787109,791.314392 928.063477,788.437500 
	C929.849182,788.012207 929.847595,786.429443 930.709290,785.195312 
	C932.638306,788.578674 932.122864,791.982178 930.073792,795.640503 
	C929.905884,796.032288 929.729004,796.035950 929.287170,796.024902 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M932.258972,767.500122 
	C934.234802,762.568542 936.870728,767.876648 939.627808,766.986694 
	C939.736755,767.483398 939.475952,767.970032 939.099365,768.707153 
	C936.708740,769.181702 934.520020,768.951111 932.258972,767.500122 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M929.090332,770.724792 
	C929.101379,772.111694 929.055420,773.145203 928.985840,774.566162 
	C928.525146,774.966309 928.088135,774.979126 927.322998,774.991577 
	C926.970032,774.810547 926.945251,774.629883 926.883484,774.178223 
	C926.142883,773.091003 925.929688,772.137573 926.149048,770.717712 
	C926.434875,769.867371 926.720093,769.448975 927.413330,768.878784 
	C927.984131,768.625610 928.236084,768.584961 928.813843,768.745361 
	C929.104187,769.421326 929.068787,769.896423 929.090332,770.724792 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M940.030334,785.565613 
	C939.121521,785.145569 938.187866,784.362793 937.116821,783.303101 
	C938.566956,782.947144 939.957764,783.141296 940.030334,785.565613 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M928.529663,782.220337 
	C928.165039,781.529846 928.074158,780.711731 927.969849,779.486816 
	C929.169312,779.822571 930.809998,780.445007 928.529663,782.220337 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M929.183838,768.823120 
	C928.953125,768.955994 928.766724,768.965637 928.307739,768.504395 
	C928.255676,768.084045 928.476013,768.134583 929.010742,768.320984 
	C929.325073,768.456848 929.228088,768.699951 929.183838,768.823120 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M928.012024,785.642212 
	C927.865051,785.605164 927.896667,785.414795 927.903076,785.321777 
	C927.909424,785.228760 928.292664,785.356262 928.292664,785.356262 
	C928.292664,785.356262 928.158997,785.679199 928.012024,785.642212 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M991.733398,601.796631 
	C992.003357,602.375122 992.206665,602.622498 992.700317,602.913208 
	C992.765259,603.791260 992.611389,604.567200 992.343262,605.614990 
	C989.199463,605.728455 990.269104,603.405701 990.071411,601.361084 
	C990.783997,600.638550 991.224792,600.987793 991.733398,601.796631 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M976.556396,640.141602 
	C977.313599,638.948059 977.942261,638.167969 978.711304,637.121338 
	C979.051636,636.888855 979.251587,636.922913 979.752808,637.002563 
	C979.727356,640.072083 981.771667,640.084839 983.865540,640.066772 
	C987.186279,640.038147 987.364014,643.260315 988.586792,645.110229 
	C989.652832,646.722961 989.240112,649.013245 986.440552,650.049805 
	C985.359924,649.670166 984.826416,648.954895 984.852173,648.168945 
	C984.961426,644.836975 982.959412,644.166748 979.830811,644.237610 
	C977.692993,643.578369 976.387634,642.625610 976.556396,640.141602 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M979.762268,643.307495 
	C985.153748,641.993286 986.786072,644.440430 986.050415,649.681458 
	C986.043030,650.848938 986.008789,651.562195 985.934570,652.632202 
	C983.511902,653.000244 983.236816,654.996826 982.261475,656.788452 
	C979.455811,655.904541 979.201904,653.413513 980.123047,651.674927 
	C981.733582,648.635071 980.789795,646.204895 979.762268,643.307495 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M991.911133,601.630981 
	C991.433960,601.778687 990.996399,601.488342 990.325195,601.078735 
	C990.091492,600.959473 990.067017,600.947754 990.064758,600.936584 
	C989.924500,600.780029 989.786438,600.634644 989.830933,600.250122 
	C989.981506,598.602478 989.971680,597.201294 991.586182,596.084412 
	C991.910278,597.680237 991.930542,599.436584 991.911133,601.630981 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M990.745850,619.168823 
	C991.285706,619.471802 991.613464,619.955872 991.952576,620.747925 
	C991.101379,620.810608 989.956665,620.801941 990.745850,619.168823 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M990.002197,625.645142 
	C990.007324,625.980469 989.610596,626.057434 989.410522,626.067322 
	C988.072327,625.293823 988.038330,624.611389 989.690308,624.011353 
	C989.989258,624.414307 989.993164,624.862122 990.002197,625.645142 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M989.854126,600.096191 
	C990.013367,600.011108 990.045532,600.468445 990.054016,600.696899 
	C989.940002,600.677368 989.817444,600.429382 989.854126,600.096191 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M979.610596,642.973999 
	C980.061584,644.397400 981.234802,644.649292 982.124390,645.327881 
	C983.644226,646.487366 984.606934,648.069214 982.542358,649.700012 
	C980.083496,651.642212 980.925964,653.965637 981.850586,656.650879 
	C981.111267,658.363464 978.980103,657.866577 978.118286,659.801514 
	C977.275452,660.123901 976.576904,660.138428 975.528748,660.166870 
	C974.368530,660.677979 973.517212,660.815002 972.257568,660.196045 
	C974.497803,657.261292 977.600159,655.280518 977.563599,650.563354 
	C977.543823,648.018433 977.525635,646.167725 975.640930,644.568420 
	C973.554810,642.798218 973.687012,641.312683 976.373779,639.860962 
	C977.064148,641.179077 977.558533,642.537415 979.610596,642.973999 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M971.787964,660.126587 
	C972.723450,659.963928 973.543152,660.006958 974.771057,660.115479 
	C975.450439,663.580017 973.903442,666.927734 974.868591,670.731079 
	C972.779053,671.008789 972.719849,668.317078 970.493530,667.934204 
	C968.890259,667.398438 968.453674,666.312317 968.135986,664.771057 
	C968.140930,664.191833 968.171814,663.962463 968.324585,663.411133 
	C968.891724,662.226990 969.847534,662.008118 970.741699,661.190186 
	C971.238159,660.787720 971.476196,660.584717 971.787964,660.126587 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M944.734497,283.775513 
	C945.793030,285.234680 947.093079,284.941559 948.618347,285.117065 
	C949.677551,285.389862 950.082397,285.973206 950.828979,286.689697 
	C951.102356,286.904358 951.190063,286.842590 951.243591,286.846802 
	C951.525330,287.250977 951.754456,287.650482 952.116089,288.366699 
	C952.557068,290.230103 953.091858,291.709717 953.690186,293.512817 
	C954.025269,294.869385 954.265930,295.911865 954.812378,297.195679 
	C955.659729,298.804962 956.912048,299.109222 958.571655,299.300598 
	C959.105774,299.405518 959.312073,299.477417 959.765747,299.764374 
	C960.013062,299.979431 960.404907,300.102417 960.607117,300.138245 
	C961.919128,301.123962 963.374573,301.638702 964.356995,303.258118 
	C964.480713,304.884094 965.215454,306.139496 964.323364,307.749268 
	C964.090698,309.452118 964.063477,310.820709 964.043884,312.600098 
	C963.891235,313.189270 963.730957,313.367676 963.254456,313.713562 
	C960.587036,314.466339 958.737671,313.847626 957.375671,311.747955 
	C953.986511,306.522949 953.966187,306.536133 948.230957,309.502136 
	C945.587952,308.463928 946.721802,306.917847 947.125610,305.317749 
	C949.484131,295.971985 943.629517,284.837646 934.667969,281.430847 
	C933.704956,281.064728 932.773926,280.776550 931.540039,281.693176 
	C929.459351,282.714630 927.847656,284.521393 925.462158,282.462524 
	C924.413574,277.910797 927.588318,276.792725 930.807861,275.358765 
	C931.950623,275.300903 932.671265,275.503876 933.719116,275.881775 
	C934.928345,276.346039 935.790588,276.712402 937.034668,277.038971 
	C941.926392,276.827179 943.554138,279.791992 944.734497,283.775513 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M954.233215,298.256287 
	C952.773987,297.192383 953.032471,295.900085 952.926636,294.350281 
	C953.300720,293.668243 953.742798,293.518097 954.550232,293.720642 
	C955.718384,296.498810 959.147461,296.656708 959.983521,299.637268 
	C959.844849,299.998230 959.676575,300.017029 959.254272,300.039612 
	C957.369934,299.892242 955.861572,299.387787 954.233215,298.256287 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M951.342529,289.199432 
	C951.119080,288.448639 951.113953,287.986847 951.203003,287.188049 
	C953.288696,287.963745 958.058289,286.685089 955.185120,291.859528 
	C952.136108,293.327026 952.639587,290.361023 951.342529,289.199432 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M964.149048,303.393677 
	C962.370667,303.053497 960.998352,302.756439 960.851685,300.520447 
	C962.580750,300.103851 964.733459,299.283569 964.935730,302.668060 
	C964.765991,303.228699 964.607971,303.398743 964.149048,303.393677 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M951.117310,289.265198 
	C953.150574,288.865753 952.708496,291.408936 954.559998,291.939636 
	C954.891418,292.422699 954.903198,292.848816 954.887451,293.592224 
	C954.428955,293.934570 953.998108,293.959656 953.240723,293.983582 
	C951.695801,292.783447 951.656067,291.097137 951.117310,289.265198 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M954.988342,266.406799 
	C957.470581,266.438873 958.913025,267.323090 956.415100,269.981995 
	C955.739929,268.957733 955.388123,267.853516 954.988342,266.406799 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M963.882507,303.616638 
	C964.026001,303.180359 964.203918,303.142151 964.652954,303.081299 
	C965.135376,303.584015 965.346619,304.109436 965.788818,304.806274 
	C965.929321,306.093597 966.179626,307.354095 964.446167,307.997742 
	C964.050964,306.727264 963.983948,305.370972 963.882507,303.616638 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M951.098145,286.563904 
	C950.194397,286.945526 949.429443,286.736237 949.041443,285.457428 
	C948.987671,284.966949 948.993530,284.781067 948.997925,284.316620 
	C950.347534,284.140839 950.992249,284.921570 951.098145,286.563904 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M955.795654,322.223450 
	C959.067993,322.124176 959.542603,325.104340 961.565796,326.867340 
	C962.253845,327.298187 962.508118,327.658478 962.555786,328.479736 
	C960.499084,331.665070 962.822083,333.008820 964.728760,334.813416 
	C965.426697,335.658142 965.909424,336.259735 966.642578,337.012878 
	C968.651184,338.861420 968.793640,341.372803 970.125854,343.673737 
	C970.401611,344.512054 970.559021,345.020844 970.932068,345.697327 
	C972.247070,347.999512 972.442688,349.839935 969.161011,350.656372 
	C966.554810,347.176453 963.568787,344.520233 960.680542,341.656830 
	C955.505920,336.526794 955.195068,329.467834 955.795654,322.223450 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M967.821411,350.335876 
	C970.539978,350.215729 971.172302,348.764160 971.102539,346.262390 
	C973.713806,346.150726 973.964417,348.326416 974.527771,350.147156 
	C975.565430,353.500977 975.866760,356.993439 978.603271,359.823761 
	C980.364319,361.645172 980.058411,364.055267 976.685181,364.633484 
	C975.247009,364.879974 973.851807,365.377502 972.066650,365.879913 
	C968.715820,366.630219 969.465332,364.579437 969.614136,362.663300 
	C969.740967,362.093781 969.800171,361.870209 969.968750,361.322723 
	C970.397034,360.169312 970.751526,359.353455 971.125977,358.194275 
	C971.947388,356.159454 970.585205,355.597687 969.252197,354.648926 
	C967.783386,353.419952 966.919983,352.285553 967.821411,350.335876 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M970.789551,361.827454 
	C971.450806,363.344910 969.190857,364.642578 971.412964,365.948212 
	C971.884216,367.120789 972.239624,368.157013 973.453003,369.076935 
	C973.728271,369.258636 973.832397,369.654449 973.895691,369.849213 
	C973.943359,370.492584 973.927734,370.941223 973.898376,371.726471 
	C972.952087,373.334778 971.502319,373.749115 970.135742,374.266357 
	C968.738647,374.795227 967.206299,375.283203 966.019348,373.985748 
	C964.746155,372.594025 965.476868,371.211151 966.551819,370.077759 
	C967.878845,368.678589 967.487549,367.076050 967.372925,365.446747 
	C967.218262,363.248383 967.727844,361.479706 970.789551,361.827454 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M977.161133,353.277405 
	C978.854248,353.226837 980.321899,354.284912 982.263916,353.477020 
	C984.702148,352.462738 985.821106,353.890991 985.978271,356.648254 
	C984.440979,355.841156 982.901489,356.788055 981.329956,356.916077 
	C979.076294,357.099701 977.707703,355.798248 977.161133,353.277405 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M964.999329,334.750916 
	C961.422791,334.494202 958.478088,333.565338 961.476257,328.966278 
	C962.752930,329.061493 962.974426,329.897095 963.477051,330.872375 
	C964.049988,331.849792 964.494507,332.552521 965.052979,333.544739 
	C965.129456,334.037903 965.092102,334.241577 964.999329,334.750916 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M970.442505,343.764252 
	C967.479614,342.858429 967.722839,340.104828 966.931946,337.523926 
	C969.868286,338.267670 970.328796,340.852722 970.442505,343.764252 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M971.119507,361.811646 
	C967.499207,362.241943 968.284485,364.420288 969.021973,366.577423 
	C969.720642,368.620941 970.896118,370.795135 967.230774,371.234375 
	C966.254211,371.351379 965.969482,372.333862 966.421143,373.148743 
	C966.885132,373.985931 967.819214,374.042572 968.571899,373.558868 
	C969.974243,372.657654 971.337219,371.750336 973.503174,372.049377 
	C973.884644,372.063110 973.938660,372.034454 973.965515,372.019836 
	C974.657837,372.907837 974.933289,373.923859 974.807983,375.427551 
	C972.430420,378.691956 974.177673,381.303711 975.644653,383.570587 
	C977.312256,386.147156 978.022095,388.504486 976.922119,391.483917 
	C976.104370,393.699097 976.260742,396.113159 977.014343,398.355652 
	C978.039795,401.407043 978.681152,404.423828 977.731506,407.622345 
	C977.279602,409.144409 977.763123,410.559387 978.544128,411.902008 
	C984.187622,421.604309 980.798767,431.175659 975.536926,437.644867 
	C975.089722,438.194641 974.471252,438.397156 973.418335,438.293243 
	C972.650818,437.842041 972.343140,437.445068 972.025513,436.616455 
	C971.712891,435.081238 971.751892,433.853760 972.321106,432.845520 
	C974.359253,429.235016 974.229248,426.822479 970.637817,423.581055 
	C965.795288,419.210419 965.541687,406.632111 969.367859,401.135223 
	C971.583679,397.951721 970.377380,394.822937 970.674744,390.756165 
	C966.369080,394.229462 964.913330,391.630646 963.829834,388.429321 
	C963.340454,386.983368 962.632385,385.698547 961.468018,384.224182 
	C960.758484,382.706970 960.704407,381.451202 960.852417,379.804871 
	C961.913696,377.086914 960.916748,375.489929 959.249023,373.778503 
	C955.989807,370.433990 956.119202,368.964935 960.022705,366.447388 
	C962.114563,365.098236 962.517883,364.084015 960.724365,362.302216 
	C959.642395,361.227356 958.807861,359.807861 958.836670,358.335205 
	C958.964355,351.797974 955.886414,346.043488 954.576477,339.870300 
	C953.501648,334.805511 952.233215,329.920227 948.919312,325.739746 
	C945.675415,321.647614 948.058655,316.832520 947.755371,312.073944 
	C948.844543,311.061554 949.850525,310.928070 951.305298,311.144226 
	C953.778687,312.288544 954.699585,314.147339 955.199097,316.690125 
	C955.156006,318.547211 954.781494,320.033844 955.794312,321.679321 
	C956.325256,324.053802 956.879822,326.112152 957.087280,328.278473 
	C957.694763,334.622955 958.660278,340.848358 965.870300,343.669647 
	C968.040527,344.518860 969.575012,346.596313 968.088135,349.714966 
	C967.734192,351.573425 968.262939,352.704376 969.748047,353.782349 
	C970.596497,354.825256 970.994324,355.710236 971.114380,357.074158 
	C970.990112,358.397034 970.990356,359.356323 971.130737,360.669495 
	C971.171509,361.198395 971.159912,361.373871 971.119507,361.811646 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M952.252930,312.211334 
	C950.915955,312.017059 949.844055,312.020386 948.132324,311.777466 
	C947.198914,310.851562 947.203918,310.175720 947.761475,309.252319 
	C948.866699,308.247833 949.767700,307.534790 950.583069,306.734467 
	C953.098450,304.265472 957.335571,305.367188 957.859436,308.959381 
	C958.277527,311.826721 959.269226,313.221985 962.592468,312.994141 
	C963.600403,313.979797 963.156494,314.770111 962.259277,315.755859 
	C960.926208,315.991364 959.858887,315.996674 958.391418,315.994507 
	C955.778809,315.387970 954.708923,313.041168 952.252930,312.211334 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M963.727112,330.775696 
	C962.755371,330.889679 962.203247,330.348236 961.909607,329.164551 
	C961.829346,328.455627 961.845276,328.050934 961.854736,327.342407 
	C963.962280,323.089447 969.730042,321.995239 970.092468,316.240540 
	C970.562866,315.598267 970.981750,315.506012 971.711670,315.801300 
	C971.936340,319.447540 970.716919,322.148621 967.867126,324.379700 
	C966.007874,325.835297 964.087402,327.670380 963.727112,330.775696 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M952.127197,312.318542 
	C954.771973,311.614410 956.746460,312.604156 957.846191,315.665375 
	C957.485474,316.787872 956.690369,317.005157 955.503113,317.076233 
	C953.536926,316.043427 953.272766,314.090515 952.127197,312.318542 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M970.536133,309.941711 
	C971.111633,310.498779 971.315735,311.073456 971.770813,311.826111 
	C972.016907,312.447571 972.012085,312.891113 972.002441,313.667328 
	C971.612793,314.322357 971.167114,314.458069 970.377075,314.217957 
	C968.917236,313.271698 966.919861,313.174805 967.280701,311.173035 
	C967.550171,309.678162 969.088379,310.125092 970.536133,309.941711 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M962.329956,316.146118 
	C962.244690,315.185364 962.495789,314.384644 962.870544,313.289368 
	C963.170227,312.994812 963.346313,312.994812 963.786987,313.002838 
	C965.211487,314.009521 966.371460,315.008209 967.781616,316.222321 
	C965.905273,317.373444 964.297607,317.110840 962.329956,316.146118 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M970.081848,314.315918 
	C970.509644,314.002716 970.934387,314.003174 971.678345,314.001831 
	C972.690430,314.432129 972.978577,314.965271 972.203003,315.856384 
	C971.580688,315.982330 971.179382,315.960663 970.481445,315.907288 
	C970.149414,315.460236 970.114075,315.044922 970.081848,314.315918 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M898.851929,121.710930 
	C898.353027,124.582787 895.099060,123.209885 894.014282,125.578384 
	C893.424316,126.634407 892.676575,126.948509 891.443115,127.164902 
	C885.406189,125.828568 879.377441,125.697853 874.808044,121.206551 
	C873.197693,119.623703 870.474426,119.877083 868.143494,118.502762 
	C867.527222,117.765724 867.441833,117.248383 867.783142,116.347023 
	C868.768555,114.968765 869.594849,114.066948 870.173706,112.798805 
	C871.586914,109.702744 875.988098,109.506699 877.534363,112.527428 
	C878.615906,114.640404 880.075500,116.497353 882.070496,116.693367 
	C885.674622,117.047501 888.105286,117.859604 889.726379,121.627739 
	C890.742371,123.989273 893.776489,123.618187 895.595520,121.570557 
	C896.782288,120.234573 897.586121,119.962395 898.851929,121.710930 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M845.924316,116.218460 
	C842.753174,113.185005 846.095032,111.428123 847.242371,109.412605 
	C848.169617,107.783676 849.942871,106.112556 852.121887,107.646378 
	C854.779846,109.517342 856.983398,108.466988 859.634521,107.073631 
	C860.354553,107.783546 860.718689,108.541817 861.101562,109.628769 
	C861.213623,110.602669 861.518188,111.186325 861.706116,112.146698 
	C861.743530,112.930565 861.652893,113.368004 861.287842,114.067139 
	C860.692261,114.593773 860.268555,114.761147 859.467163,114.847977 
	C858.899597,114.799042 858.676941,114.747894 858.149780,114.544846 
	C857.037781,113.689262 856.291931,112.853989 854.715088,113.096497 
	C850.797424,111.828407 848.670654,114.381905 845.924316,116.218460 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M863.650024,117.744980 
	C864.008057,117.981308 864.503540,117.985237 864.751282,117.985947 
	C865.894531,117.723495 866.600830,118.050217 867.355652,119.085861 
	C866.311340,123.640823 863.744751,121.623505 861.126831,120.535515 
	C860.518799,119.730682 860.412964,119.177979 860.671143,118.201157 
	C861.610596,117.257599 862.425720,117.177559 863.650024,117.744980 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M860.611328,114.141617 
	C860.795898,113.448624 860.839783,113.033470 860.892151,112.305328 
	C860.990173,111.372803 861.233887,110.823578 861.878052,110.150375 
	C865.547119,111.186073 861.922058,113.613129 862.998657,115.623642 
	C862.712952,116.318184 862.355652,116.503830 861.626343,116.535698 
	C861.136047,116.390785 860.955872,116.285606 860.612183,115.913559 
	C860.349792,115.235695 860.351746,114.826523 860.611328,114.141617 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M867.367554,119.096786 
	C866.550476,118.767738 866.039612,118.495255 865.263916,118.104713 
	C865.810303,117.405556 866.621582,116.824448 867.713684,116.121017 
	C868.005859,116.465698 868.017151,116.932724 868.047363,117.749603 
	C868.160583,118.534622 868.040588,118.889977 867.367554,119.096786 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M860.748657,116.376404 
	C861.373779,116.037575 861.837646,116.022865 862.649536,116.003494 
	C863.316345,116.464050 863.635132,116.929253 863.980957,117.687881 
	C863.283875,117.981308 862.559631,117.981300 861.473511,117.978180 
	C860.743347,117.629761 860.577637,117.201233 860.748657,116.376404 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M855.141479,100.297318 
	C855.752625,100.024254 856.505737,100.024696 857.635498,100.037857 
	C857.529968,102.465668 856.489197,101.950989 855.141479,100.297318 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M861.860596,109.943390 
	C861.881714,110.520111 861.656006,111.091820 861.165405,111.827927 
	C860.217896,111.491982 860.004761,110.921707 860.878906,110.091454 
	C861.120361,109.957436 861.613770,109.938370 861.860596,109.943390 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M979.605774,384.955139 
	C982.205200,385.966553 981.035461,389.932587 984.687134,389.960266 
	C985.054260,389.945526 985.028442,389.952423 985.038635,389.949402 
	C986.384399,391.275360 987.567810,392.838257 987.195801,394.749359 
	C986.360413,399.039825 987.803650,402.886200 989.020752,406.828705 
	C990.004150,410.014160 990.725647,413.225739 988.982666,416.460449 
	C988.113098,418.074280 988.622681,419.672791 989.533142,421.161194 
	C990.401672,422.581024 991.228271,424.020691 990.939697,426.131592 
	C990.679810,426.900421 990.521729,427.331390 990.189758,428.384491 
	C989.987549,429.168060 989.977539,429.336151 989.993408,429.755524 
	C990.019287,430.006836 989.813049,430.270355 989.778687,430.434082 
	C989.637817,431.151978 989.311890,431.499847 988.386475,431.664551 
	C987.768250,431.622986 987.528259,431.556580 986.997192,431.262695 
	C986.072021,427.974548 985.605469,424.916595 982.905396,422.701996 
	C981.463318,421.519135 981.312195,419.631714 981.261658,417.693817 
	C981.100647,411.526489 980.285034,405.449585 979.307617,399.327911 
	C978.590576,394.836884 975.480530,389.640656 979.605774,384.955139 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M987.742126,431.315247 
	C988.368408,430.894684 988.762085,430.807648 989.450073,430.659210 
	C990.167786,434.550354 990.830383,438.208832 996.148621,438.081726 
	C998.149902,438.033875 998.906860,439.512848 999.005615,441.401733 
	C999.138428,443.941528 998.513672,446.276794 995.812317,446.789673 
	C990.625671,447.774475 991.622925,450.245026 993.958252,453.609253 
	C993.603088,454.358765 993.101562,454.540649 992.126709,454.381836 
	C988.338684,451.746918 987.649780,448.137329 990.153931,443.579742 
	C990.925293,442.175964 990.828796,442.012146 989.764282,440.811646 
	C987.550049,438.314575 986.687805,435.091522 987.742126,431.315247 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M991.833557,454.177673 
	C992.486755,453.931610 992.926208,453.959503 993.695923,453.973450 
	C994.036133,454.136597 994.045898,454.313751 994.025024,454.755432 
	C993.594421,455.799652 993.026855,455.958862 992.125061,455.268555 
	C991.848511,454.854614 991.734131,454.653137 991.833557,454.177673 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M989.934814,428.788208 
	C991.322876,424.290436 986.276306,421.665771 986.741821,418.346588 
	C987.236511,414.820099 990.217651,411.420349 987.892700,407.764374 
	C984.481567,402.400330 986.619995,396.315186 985.075562,390.350586 
	C990.640747,392.699921 992.971680,400.046814 990.491882,406.710815 
	C990.775757,407.698059 990.858948,408.444275 991.042236,409.550537 
	C992.840027,414.497589 990.712158,419.518677 992.882935,424.129761 
	C993.780273,426.035675 991.755798,427.309937 990.305359,428.820251 
	C989.997559,429.000000 990.000000,429.000000 989.934814,428.788208 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M750.371948,969.967163 
	C743.908325,967.800293 741.544739,968.562073 737.450806,973.644287 
	C735.452271,976.125305 732.471741,974.502991 729.564209,975.085693 
	C727.714539,973.394165 728.843506,972.335327 730.209167,971.567749 
	C735.903259,968.367615 740.724426,962.593933 748.480530,965.283020 
	C749.019348,965.469849 749.667358,964.796082 750.321899,964.031677 
	C750.657532,963.309448 750.842041,962.890381 751.226196,962.182373 
	C752.109619,960.955994 752.783447,960.006836 753.466125,958.619507 
	C754.293518,957.447083 755.077637,956.770203 756.396423,956.107178 
	C759.053772,955.421265 761.302979,955.241577 763.750000,956.729126 
	C763.040527,960.119446 761.518982,962.581360 758.066711,963.535400 
	C755.286987,964.303589 754.285400,966.962402 753.016602,969.622925 
	C752.227722,969.975586 751.485596,969.977478 750.371948,969.967163 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M768.197144,954.677673 
	C767.490295,954.828186 767.051453,954.871277 766.283203,954.943726 
	C763.504639,956.715698 761.423096,955.688660 759.148743,953.833801 
	C757.829712,947.473938 759.002686,946.207886 764.318542,947.952271 
	C765.892822,948.468689 766.851257,948.087646 767.716125,946.883484 
	C768.593201,945.662170 769.503723,944.482910 771.436340,944.039917 
	C773.446716,943.987000 774.693604,944.653259 775.511108,946.523743 
	C775.568665,947.115662 775.544861,947.352112 775.371338,947.905273 
	C774.146973,949.304932 772.667114,949.940430 771.659729,951.548462 
	C771.377869,951.980957 771.270447,952.155151 770.941589,952.535645 
	C770.251587,953.092957 769.726501,953.367188 769.106506,953.990906 
	C768.760010,954.322754 768.612549,954.446533 768.197144,954.677673 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M785.314636,947.777222 
	C781.955750,950.178101 778.506348,950.983093 774.411255,950.997192 
	C773.409668,950.004517 773.851379,949.232056 774.764038,948.260376 
	C775.009827,948.013916 775.000000,948.000000 775.007324,948.004517 
	C777.507385,943.876160 781.507080,945.223938 785.472412,945.410645 
	C786.448730,946.312378 786.288513,946.956177 785.314636,947.777222 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M758.611084,952.869995 
	C761.225891,953.083740 762.700684,955.622314 765.575256,954.975769 
	C765.488953,955.575256 765.024109,956.177307 764.300293,956.913696 
	C761.677795,957.043579 759.314209,957.039307 756.510254,957.055054 
	C755.693298,956.727417 755.478027,956.295288 755.440918,955.418823 
	C756.002075,953.794067 756.910522,953.014282 758.611084,952.869995 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M785.379883,948.009644 
	C785.261047,947.506042 785.522156,947.012085 785.892822,946.260620 
	C786.355347,945.561584 786.796265,945.241211 787.727173,944.942017 
	C790.377869,944.745911 792.494629,945.150635 794.684448,946.739929 
	C795.000000,947.000000 795.006775,947.009094 795.013550,947.018250 
	C792.453613,952.214417 788.864807,947.858154 785.379883,948.009644 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M769.794800,952.189209 
	C769.987854,951.979980 770.457886,951.945618 770.693481,951.942627 
	C771.140503,951.835449 771.351990,951.731323 771.830078,951.859619 
	C771.788696,957.612122 771.788696,957.612122 768.090820,955.126465 
	C767.929138,954.785095 767.912964,954.373169 767.930786,954.167847 
	C768.541077,953.484924 769.061523,952.931213 769.794800,952.189209 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M772.338135,951.960815 
	C771.902405,952.066101 771.708191,952.040100 771.221497,951.976807 
	C771.419373,950.093323 771.747681,948.108276 774.649292,948.016724 
	C774.756775,948.812012 774.503662,949.610107 774.124023,950.700439 
	C773.524841,951.271667 773.052246,951.550659 772.338135,951.960815 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M823.722595,930.813232 
	C825.374023,933.704346 822.822998,933.441711 821.634155,933.824341 
	C820.483215,934.194641 818.241089,934.319641 818.374023,932.785217 
	C818.817322,927.671509 814.565735,927.673340 811.404053,926.048340 
	C809.576965,923.862366 807.313904,922.047180 807.829834,918.611206 
	C808.840271,916.568481 809.009888,914.014404 812.316772,914.948303 
	C818.439026,917.193726 821.202209,921.372864 821.396423,927.458252 
	C821.438660,928.781006 821.993713,930.056396 823.722595,930.813232 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M808.716492,918.782104 
	C807.345398,921.861206 812.290161,922.598999 811.025146,925.683594 
	C809.795410,928.342102 808.181213,930.560852 810.129700,933.283752 
	C811.202087,934.782410 810.633789,936.017090 809.329712,937.103943 
	C807.937683,938.264038 806.315613,938.157043 805.328003,937.019043 
	C803.009338,934.347168 802.048950,936.817200 800.086792,937.801147 
	C797.944336,934.047607 799.715637,931.782288 802.559082,929.903015 
	C807.559998,926.597900 807.584106,926.479919 805.442566,920.594360 
	C805.738525,919.495056 806.262878,918.998291 807.305542,918.544434 
	C807.921753,918.478943 808.172241,918.510925 808.716492,918.782104 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M824.074524,930.602539 
	C821.533447,932.333679 819.982971,932.237000 819.935425,928.776917 
	C819.906250,926.654236 819.054932,924.537170 819.068237,922.420654 
	C819.099060,917.523071 814.219055,918.891174 812.089111,916.356750 
	C812.455933,915.460022 812.915222,914.950134 813.895020,914.561646 
	C814.463135,914.472168 814.696045,914.474609 815.268066,914.564331 
	C819.335083,916.544800 823.153076,918.162170 827.669617,918.710693 
	C826.999146,920.076477 826.047607,921.233826 825.067383,922.721558 
	C825.098267,925.483704 825.192322,927.919983 824.074524,930.602539 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M795.007202,946.628723 
	C792.921997,946.668030 790.844055,946.336060 788.369873,945.986206 
	C788.702209,941.130493 791.889404,938.622253 796.644165,937.153564 
	C797.849121,936.998718 798.627747,937.113708 799.676086,937.746582 
	C799.997559,937.985352 800.005493,938.042236 800.013733,938.071045 
	C799.389221,941.455261 794.535522,942.219666 795.007202,946.628723 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M984.646545,448.754456 
	C984.963989,449.766693 984.968628,450.508789 984.966431,451.621887 
	C985.447205,455.641327 985.778503,459.205963 981.969238,461.560913 
	C981.279846,461.987183 981.168152,463.040100 981.336914,463.870544 
	C982.541077,469.796448 981.575684,475.630981 980.676514,481.473541 
	C980.445801,482.972748 981.472534,483.940460 982.280762,484.978149 
	C983.220703,486.184906 984.228699,487.523926 983.807678,489.045319 
	C982.805786,492.665955 984.199463,493.077911 987.513062,492.863586 
	C994.257080,492.427399 997.266968,496.808685 994.858887,503.254822 
	C993.652954,506.482971 995.982300,508.585419 996.812378,511.534729 
	C996.795288,512.126282 996.756958,512.361389 996.586853,512.927979 
	C995.673401,517.173035 992.536804,521.039673 996.624268,525.010864 
	C997.757996,526.112305 997.965210,527.925720 997.393799,529.693176 
	C995.716614,534.880615 990.226685,538.513245 984.646729,537.849670 
	C983.152527,537.671936 982.359680,538.036499 982.343750,539.537964 
	C982.251587,548.244019 980.725891,556.895447 981.338867,566.009094 
	C980.951782,568.990845 980.001221,571.111145 976.638062,571.470093 
	C976.036682,571.196289 975.834229,571.015747 975.476562,570.461670 
	C975.055481,567.729126 975.677124,565.411438 975.663818,562.689270 
	C976.074585,560.024963 974.637756,558.665649 973.243469,557.171143 
	C967.487183,551.000793 966.427856,543.628784 970.096619,536.246399 
	C970.567627,535.298645 971.181396,534.415344 972.256226,534.403748 
	C976.292419,534.360046 976.700867,531.696289 976.617920,528.610046 
	C976.548584,526.030029 977.153442,523.675537 978.861267,521.657715 
	C981.354675,518.711975 981.790710,515.987915 979.713562,512.201050 
	C977.819824,508.748596 975.546448,505.024170 976.400085,500.483551 
	C976.776550,498.481415 975.012756,497.183777 973.204163,496.540283 
	C971.612732,495.974060 970.127808,495.299652 968.582458,493.920105 
	C967.971497,493.263245 967.675537,492.842499 967.255615,492.036072 
	C965.892761,486.564514 967.175598,481.448578 967.325989,476.356201 
	C967.434326,472.686615 969.346863,469.297119 973.736206,468.526398 
	C976.688477,468.007996 976.639160,466.017273 976.333984,463.631470 
	C975.854980,459.887695 973.228821,456.550842 974.465942,452.185791 
	C977.904724,450.765350 980.248230,447.109344 984.646545,448.754456 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M996.323242,512.229187 
	C993.632751,508.224243 990.323303,504.652863 995.272827,500.173920 
	C996.460938,499.098816 991.951416,494.189972 989.489136,494.048187 
	C988.184082,493.973114 986.755798,493.926117 985.564880,494.362488 
	C981.329163,495.914459 980.833862,494.212860 982.219177,490.744720 
	C982.796326,489.300049 983.378845,487.837097 982.020264,486.616791 
	C979.316772,484.188599 976.747742,481.832886 980.646484,478.347168 
	C977.565857,473.706757 982.913757,468.630981 979.873657,463.930969 
	C978.805115,462.279022 979.241333,460.764648 981.208252,459.918091 
	C984.347412,458.567139 985.603638,456.669769 983.214539,453.508179 
	C982.883179,453.069733 983.614624,452.346436 984.630493,452.051208 
	C989.595276,460.507294 990.035767,469.818481 990.162781,479.197601 
	C990.173279,479.973602 990.252747,481.007629 990.734131,481.461273 
	C991.582031,482.260284 992.728699,481.809387 993.370300,481.005859 
	C995.044861,478.908905 996.643433,479.333374 998.646301,480.972321 
	C998.967773,481.036255 999.398438,481.102875 999.606689,481.162048 
	C999.772949,481.544800 999.853455,481.995117 999.670044,482.172546 
	C997.688232,484.089386 991.693481,485.732941 999.353577,488.231079 
	C999.677734,488.336792 999.891541,489.829590 999.642334,490.478729 
	C997.641052,495.691925 999.016541,501.325073 997.791016,506.796204 
	C997.854309,508.550690 999.582825,509.908905 998.235840,511.763611 
	C997.615906,512.372131 997.164307,512.525513 996.323242,512.229187 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M985.111267,448.700836 
	C981.200928,447.820435 979.406067,452.703064 975.413879,451.977631 
	C974.856934,451.806885 974.688477,451.637085 974.398438,451.129150 
	C974.239319,450.314392 974.288391,449.848022 974.635803,449.104797 
	C975.696716,447.999847 977.893250,448.320648 977.457703,446.549896 
	C977.138123,445.250244 975.439819,446.092133 974.066772,445.737152 
	C973.116089,445.359406 972.681519,444.892517 972.444458,443.913025 
	C972.469849,442.652222 973.366272,441.829102 972.709106,440.523102 
	C972.460999,439.861450 972.409241,439.462555 972.733643,438.542694 
	C976.587036,433.498016 980.113647,429.434540 979.791260,422.652924 
	C979.578308,418.172394 978.980835,414.533112 976.416809,411.065857 
	C976.026611,410.538147 975.293274,409.642853 975.445129,409.425659 
	C980.141418,402.710266 971.630249,395.523376 976.319641,388.545471 
	C977.681335,386.519348 973.847046,384.369110 973.082520,381.815002 
	C972.463257,379.745789 971.019348,378.003967 973.605042,376.056641 
	C975.372864,376.759155 974.669617,378.490814 975.787598,379.747620 
	C976.027710,379.979584 976.082214,380.433716 976.093750,380.662109 
	C976.650818,381.825378 977.605713,382.189911 978.727234,382.833984 
	C979.260925,383.508820 979.581238,383.969330 979.926636,384.722839 
	C978.627808,388.712433 977.721191,392.325958 981.707581,395.117645 
	C982.044128,395.353363 982.396179,396.204590 982.381958,396.213776 
	C977.149048,399.593811 985.099609,403.991882 981.521179,407.606171 
	C980.950439,408.182648 982.261108,408.786835 983.072510,409.018951 
	C983.813049,409.230804 984.846069,409.287872 984.182190,410.568817 
	C981.556458,415.635071 980.855591,420.258972 986.360229,424.194122 
	C988.224670,425.526917 988.288208,427.954712 986.975098,430.455627 
	C987.747253,431.578949 986.948486,432.405945 986.972534,433.516785 
	C986.627441,434.857574 986.729980,435.825409 987.456909,437.021851 
	C987.616455,437.757751 987.554871,438.176849 987.138245,438.782440 
	C983.621765,441.645203 986.055847,445.288971 985.111267,448.700836 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M987.149719,439.200836 
	C986.880432,438.560333 986.879700,438.138702 986.855957,437.401337 
	C986.295166,436.366486 985.982239,435.567963 985.948181,434.334900 
	C986.178040,433.157837 986.436401,432.306702 986.804810,431.132629 
	C987.090515,430.844910 987.266174,430.880127 987.708252,430.948547 
	C989.313293,434.445129 987.723022,438.947906 991.994568,441.451202 
	C993.048767,442.069000 991.530945,443.391205 991.076172,444.363678 
	C989.643127,447.427887 988.853027,450.479675 991.809692,453.613892 
	C992.032227,454.095520 992.017029,454.287292 991.982300,454.767578 
	C988.056335,454.740601 986.257324,452.898346 987.561096,448.888031 
	C988.595642,445.706238 986.219666,442.593079 987.149719,439.200836 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M980.362183,566.228882 
	C980.005615,560.995544 980.210449,555.987976 979.932373,551.007263 
	C979.702698,546.893982 982.949402,543.151062 980.561584,538.634583 
	C979.817444,537.226929 982.235107,533.661987 985.047363,536.294373 
	C987.389282,538.486389 988.614807,537.108765 990.488342,536.084106 
	C992.067749,535.220398 992.796631,533.723083 993.899231,532.532654 
	C997.911560,528.200867 997.684570,526.460510 992.825867,523.114685 
	C992.150269,522.649475 991.030029,521.685974 991.226257,521.620728 
	C995.646057,520.151428 992.195374,515.042664 995.601562,513.003784 
	C997.081970,513.442322 996.865295,514.779968 997.783936,515.767822 
	C1000.686584,518.443909 998.035461,521.226807 998.416809,523.432983 
	C999.550232,529.989075 997.578369,536.645813 999.568542,543.044312 
	C1000.120850,544.819946 1000.121460,546.295044 998.225708,547.713989 
	C997.313782,548.577026 996.541809,548.742554 995.335205,548.243652 
	C994.870850,547.700684 994.679199,547.444275 994.199524,547.128784 
	C993.860596,545.128723 994.532288,542.407654 991.748657,542.356018 
	C988.971069,542.304504 990.281860,545.511597 989.253845,547.023193 
	C988.983032,547.421265 989.253235,548.318176 989.482483,548.904968 
	C991.566406,554.237366 991.211670,558.550720 984.888916,560.599609 
	C983.187561,561.150940 982.896912,562.626160 983.008667,564.599976 
	C983.062439,566.741760 981.896484,566.616211 980.362183,566.228882 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M998.007935,515.586792 
	C996.535034,515.912170 995.995300,515.023804 995.964478,513.312134 
	C995.937073,512.955750 995.980408,512.485535 995.997070,512.250122 
	C996.452454,512.008179 996.891235,512.001648 997.658875,511.989441 
	C997.993896,513.051514 998.000061,514.119263 998.007935,515.586792 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M995.044617,548.341064 
	C995.742249,548.007507 996.495728,548.002808 997.625793,548.000244 
	C997.464294,550.091064 996.604492,550.781311 995.044617,548.341064 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M915.638794,852.751099 
	C915.798340,854.726318 916.525208,856.158386 917.886841,857.712891 
	C917.949646,861.265747 916.176392,864.939148 921.926086,867.284180 
	C916.769470,868.052856 913.956909,869.648193 914.990479,874.591919 
	C910.367615,876.984863 910.239014,871.956360 907.567627,870.209778 
	C906.919861,869.610535 906.600891,869.217896 906.133484,868.454773 
	C905.485535,866.888916 905.071106,865.655823 905.717041,863.999512 
	C906.786011,863.015747 907.783569,862.889404 909.183105,862.936523 
	C914.315735,862.940918 915.068237,862.067505 913.416321,857.953430 
	C912.396545,855.413635 911.433289,853.183533 915.638794,852.751099 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M907.719238,869.274902 
	C911.115234,869.876587 910.584656,874.668396 914.626953,874.968262 
	C915.130066,876.520935 915.280457,878.030884 915.190430,879.750244 
	C911.533020,880.841431 907.734131,882.441040 908.663330,876.094055 
	C908.795593,875.190979 907.536865,875.107056 906.354736,874.999146 
	C905.876465,874.790466 905.764160,874.597534 905.655151,874.069702 
	C906.195679,872.293884 906.630920,870.808960 907.719238,869.274902 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M960.441650,698.729553 
	C960.338379,699.225952 960.283081,699.395264 960.146362,699.819092 
	C957.606201,702.605286 959.908691,704.707336 960.893127,706.711060 
	C962.230286,709.432495 962.371704,711.678345 959.687927,713.951294 
	C959.059753,714.918335 958.943787,715.644287 959.646423,716.620972 
	C961.516724,719.394653 965.118286,720.087463 966.043335,723.671997 
	C965.423584,725.143433 964.799561,726.285583 964.111206,727.752930 
	C963.857605,728.213257 963.668518,728.348389 963.155457,728.609985 
	C962.168152,728.810730 961.391418,728.858643 960.954773,728.395996 
	C957.669861,724.914917 955.041931,726.432556 952.350281,729.268677 
	C951.026672,730.663147 949.458984,732.016724 946.928833,731.178101 
	C945.947510,727.291504 948.553894,725.158508 950.590942,722.898010 
	C954.287964,718.795532 954.408386,718.485779 950.734497,714.483948 
	C949.798767,713.464661 949.074280,712.438599 949.135620,710.654297 
	C949.327942,709.593872 949.613892,708.938599 950.062744,707.970093 
	C951.054382,705.033325 951.796204,702.527466 954.221313,700.616455 
	C956.258362,699.011230 955.315002,695.942871 956.285522,693.310547 
	C957.454102,691.612305 958.525696,690.280457 959.877808,688.695190 
	C961.547180,687.920349 962.769653,688.191406 964.156738,689.374023 
	C964.995178,690.584351 965.117126,691.589966 964.914062,692.994751 
	C963.902771,695.477661 961.438721,696.289429 960.441650,698.729553 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M957.937744,713.954956 
	C960.845032,711.690552 961.715637,709.632629 959.437073,706.273132 
	C958.429688,704.787964 956.471558,702.208130 959.699585,700.114685 
	C960.712402,704.822388 961.768127,709.157288 966.998108,711.246033 
	C970.138611,712.500305 970.709656,715.187317 969.001831,718.571899 
	C966.837952,719.077515 964.987061,719.390442 967.043762,722.041870 
	C967.690247,722.875366 967.668396,723.469116 966.350708,723.953735 
	C963.738342,722.036011 961.429199,720.070679 959.019531,717.800903 
	C959.058044,716.301941 958.691101,715.252197 957.937744,713.954956 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M946.596008,730.858276 
	C948.814758,731.129150 950.012756,729.742554 951.197571,728.714905 
	C956.976318,723.702393 956.935608,723.655457 962.674438,727.989380 
	C964.156677,730.608704 962.023865,731.044250 960.504578,731.680664 
	C958.723816,732.426697 956.823120,732.891113 955.228699,734.694214 
	C954.867493,735.274658 954.693359,735.544006 954.242981,735.898682 
	C953.606323,736.488342 953.260132,737.051819 952.289429,737.104858 
	C951.592407,733.408508 947.481140,734.833008 945.986084,732.247131 
	C945.823914,731.463135 945.946777,731.112610 946.596008,730.858276 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M946.759338,750.332397 
	C947.507874,749.464417 948.045776,748.874512 948.843506,748.123901 
	C956.313965,750.012878 957.142822,752.692078 952.104187,758.580627 
	C951.491638,758.918945 951.069031,758.954651 950.328003,759.002502 
	C948.478027,759.511536 947.503357,758.439331 947.616150,757.362122 
	C947.868042,754.956970 946.501404,752.911438 946.759338,750.332397 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M955.314148,735.026978 
	C954.818481,732.605103 955.213074,730.930176 958.247803,730.869080 
	C959.879578,730.836365 961.797058,730.475403 962.945557,728.399170 
	C963.196533,728.035400 963.366882,728.028259 963.791992,728.049622 
	C963.697815,730.516235 964.784119,733.155334 961.950439,735.141602 
	C959.564941,736.813843 957.750488,737.173035 955.314148,735.026978 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M951.743713,737.198975 
	C952.495239,736.736267 953.000427,736.481140 953.772400,736.128357 
	C954.037720,739.449463 953.281616,742.454712 949.362244,743.965027 
	C948.343872,743.344116 948.252075,742.561829 948.781067,741.364258 
	C950.737793,740.426086 950.824890,738.716431 951.743713,737.198975 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M948.674316,741.055786 
	C949.014587,741.771484 949.003540,742.517029 949.000427,743.635986 
	C948.794189,744.273193 948.580017,744.537048 948.124756,744.897827 
	C944.650635,743.311890 946.011902,742.145386 948.674316,741.055786 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M964.303284,694.206970 
	C963.978943,692.882507 963.992004,691.838562 964.008545,690.403198 
	C964.761719,688.318176 965.108948,686.579590 965.037537,684.695312 
	C964.980042,683.178833 965.231262,681.602173 967.650269,681.762207 
	C967.280579,685.336853 970.821106,687.782104 969.997925,691.604492 
	C969.230957,692.629578 968.452087,693.243958 967.415771,694.022217 
	C966.362976,694.652588 965.531616,694.818237 964.303284,694.206970 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M963.632690,693.991699 
	C964.764587,693.971802 965.563416,694.017273 966.760254,694.124329 
	C966.413025,697.481812 963.824768,698.393860 960.684021,699.011719 
	C958.120117,695.505615 961.995605,695.528687 963.632690,693.991699 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M751.652527,963.709778 
	C751.482666,966.769714 749.251465,967.392517 747.163147,966.773193 
	C741.699890,965.153137 737.601379,966.436462 734.744324,971.429443 
	C734.079895,972.590515 733.138123,972.889832 731.827026,972.459412 
	C730.142334,971.906189 729.433350,972.942688 729.118896,974.758606 
	C729.132751,975.112061 729.085327,975.573608 729.061401,975.804688 
	C720.590210,974.299622 714.171631,979.299072 706.879211,981.995056 
	C701.845947,983.855957 696.353760,987.969971 690.038818,984.498352 
	C690.113037,983.039001 690.875671,982.274231 691.823120,982.089783 
	C697.815308,980.923462 702.957642,977.896179 708.465515,974.819580 
	C709.311584,974.787659 709.792175,974.821289 710.621826,974.942139 
	C716.883972,973.965271 721.868469,970.289978 727.799072,969.154602 
	C729.421997,968.843933 729.604431,966.214294 730.237305,964.146423 
	C734.419678,960.186096 738.755249,957.535950 744.858643,959.630493 
	C745.925842,959.929138 746.560181,960.277222 747.359680,961.013550 
	C748.293701,962.974548 750.023560,962.931946 751.652527,963.709778 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M957.004517,692.701660 
	C956.776062,697.063171 958.151794,701.645874 952.619690,703.680969 
	C950.978699,704.284668 953.952087,706.860901 951.377502,707.963013 
	C947.960083,707.534424 948.920471,704.888062 948.328796,702.647583 
	C948.005127,697.253906 948.326965,692.412170 951.291748,688.029968 
	C952.741577,685.887024 951.578247,683.105835 952.439575,680.751831 
	C953.290894,678.425049 954.010559,676.027100 955.673340,674.088623 
	C956.814819,672.757874 957.657898,671.234436 956.626160,669.571350 
	C953.131165,663.938110 956.776672,659.026733 959.071289,654.782654 
	C961.753479,649.821777 965.783813,645.778442 962.688965,639.615234 
	C962.533691,639.305969 963.145447,638.793152 963.918945,638.349548 
	C968.401367,638.119995 970.658875,639.899963 970.211853,644.635254 
	C970.047791,645.463928 970.041138,645.902100 970.030762,646.669067 
	C969.350708,649.540161 970.764160,651.958496 970.266296,654.757996 
	C968.970764,656.601990 968.906982,658.387695 968.963379,660.584961 
	C969.374634,661.365234 969.515808,661.833435 969.134460,662.658569 
	C968.738220,663.088928 968.610046,663.239075 968.299561,663.622559 
	C966.794922,667.018555 968.381653,670.209595 967.933472,673.512634 
	C967.129700,679.435547 963.393250,683.289246 959.891663,687.841675 
	C958.137207,689.123901 956.351990,690.001282 957.004517,692.701660 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M983.795288,582.764648 
	C983.950867,584.657532 984.233826,586.234619 984.742310,587.833984 
	C985.076538,588.885376 985.276917,590.925049 984.865662,591.122314 
	C980.479126,593.226929 982.788879,597.207886 982.023743,600.642944 
	C981.519531,601.457092 980.656311,601.949524 980.689087,602.413635 
	C980.991150,606.699036 980.551636,610.675598 977.866943,614.337158 
	C976.521179,616.172546 979.119995,616.993774 979.895386,618.668823 
	C979.989990,620.477112 980.172119,621.984436 979.132446,623.653809 
	C977.933228,625.027771 976.905762,626.077820 976.192139,627.749573 
	C975.997559,627.997070 976.000000,628.000000 975.997559,627.998779 
	C975.613525,628.331848 975.172729,628.512817 974.315186,628.395142 
	C972.353760,624.682983 972.511597,620.639160 971.107727,616.978699 
	C970.760620,616.073669 970.904541,614.842285 971.596375,614.174133 
	C974.286621,611.575989 973.409607,608.506836 973.034180,605.114990 
	C973.152771,604.533020 973.221802,604.305481 973.442017,603.757690 
	C975.866821,599.240173 973.690979,594.847595 974.098022,590.218262 
	C973.967163,589.136353 974.032959,588.420532 974.410828,587.398560 
	C974.752502,586.921631 974.930115,586.765259 975.437866,586.491760 
	C977.241882,586.433838 978.561218,586.554199 979.246033,584.629089 
	C979.904541,582.778320 981.386475,581.717468 983.795288,582.764648 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M980.001221,566.355713 
	C980.868774,565.827515 981.727844,565.663513 982.796997,565.240540 
	C983.448914,564.987915 983.890564,564.994324 984.663696,565.003784 
	C986.750916,567.565369 985.769836,569.962158 984.297363,572.690979 
	C984.209656,574.398010 983.472839,576.064880 985.668823,576.936768 
	C986.016174,577.448547 986.008484,577.893982 985.984253,578.673340 
	C985.646484,579.102539 985.387695,579.285889 985.077026,579.770264 
	C983.760376,581.616150 982.124084,582.494629 979.794128,582.723022 
	C977.899780,581.920776 976.284363,581.400085 974.219971,581.326904 
	C973.618713,580.874573 973.417053,580.489624 973.268311,579.726929 
	C973.305725,578.284790 973.242798,577.181458 972.989502,575.737427 
	C972.966003,573.543945 973.601074,572.048340 975.676514,571.085938 
	C978.491089,570.628174 979.842896,569.226501 980.001221,566.355713 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M973.623352,627.873291 
	C974.434082,627.992432 974.880188,627.994690 975.660706,627.997253 
	C974.108459,633.571838 974.614014,639.889099 970.407593,644.939209 
	C968.973389,642.422363 968.557068,639.222839 964.358276,639.044434 
	C962.473572,637.608582 962.257263,635.782654 962.402466,633.497803 
	C965.552002,630.240967 969.904907,629.999084 973.623352,627.873291 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M980.230469,618.790833 
	C975.265259,617.930298 975.177917,615.267700 977.698669,611.938416 
	C979.501831,609.556885 980.500427,607.340698 978.783447,604.367432 
	C977.400940,601.973206 979.155762,601.208313 981.612427,600.995972 
	C981.480835,605.306030 983.510193,610.014771 979.378784,613.781921 
	C977.833923,615.190674 981.314514,616.654968 980.230469,618.790833 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M970.407349,654.984619 
	C970.275452,652.426453 966.092712,650.190063 969.714844,647.156250 
	C974.953247,650.403015 975.081299,651.801941 970.407349,654.984619 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M978.782959,582.145996 
	C981.216858,582.458679 982.505981,580.621033 984.615234,580.030640 
	C984.820190,580.844849 984.677490,581.706543 984.283081,582.783081 
	C981.539795,582.873047 980.121460,583.786011 979.953613,586.538086 
	C979.720886,590.354553 977.917236,588.045593 976.252197,587.101440 
	C975.756348,584.830017 977.553650,583.812561 978.782959,582.145996 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M976.292664,627.851074 
	C975.960510,626.230896 976.171509,624.608826 978.622192,624.035400 
	C978.995728,625.750977 977.841187,626.759766 976.292664,627.851074 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M969.205811,662.932190 
	C968.873779,662.501709 968.881165,662.064575 968.933716,661.300659 
	C969.418823,660.970764 969.858887,660.967651 970.628479,660.953003 
	C970.927063,661.916077 970.638184,662.707092 969.205811,662.932190 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M984.407715,572.969604 
	C984.353149,570.631348 984.673462,568.226318 984.994507,565.414124 
	C988.410767,567.732788 988.005676,570.355713 984.407715,572.969604 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M892.288940,885.622681 
	C891.533081,885.936523 891.090454,885.941833 890.315735,885.944092 
	C887.469238,886.430054 884.685425,885.675171 882.257812,887.811829 
	C881.917603,888.362549 881.721680,888.610901 881.239990,888.911255 
	C878.864929,891.615662 869.732300,894.013733 866.974182,892.763367 
	C865.529480,892.108337 865.141602,890.923828 865.136475,889.521667 
	C865.130371,887.846375 865.192688,886.170837 863.740967,884.474243 
	C863.203918,883.355835 863.159241,882.557922 863.567383,881.373413 
	C864.354065,880.341370 865.126648,880.041565 866.394653,879.864380 
	C867.370422,879.865112 867.915588,879.958252 868.830200,880.259766 
	C875.669922,882.618958 882.088196,884.472229 888.253174,879.533020 
	C889.577393,878.471985 891.154480,877.844238 892.797607,879.900208 
	C893.532898,882.003357 893.488403,883.691406 892.288940,885.622681 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M863.464111,883.569092 
	C867.165527,883.937134 867.486694,885.992981 866.618164,888.592346 
	C865.979431,890.503906 866.113281,892.521362 868.426636,891.847961 
	C872.374756,890.698608 876.719910,891.585815 880.705322,889.170471 
	C881.447937,892.661621 880.882385,895.570435 876.367798,896.010376 
	C875.109985,896.132996 870.397949,895.734802 869.385742,894.947327 
	C866.272888,892.525391 864.708801,895.312927 862.444824,896.971558 
	C861.652405,896.995972 861.245544,896.828857 860.696167,896.260437 
	C860.333923,895.577576 860.219238,895.152771 860.135376,894.392334 
	C860.306396,892.323914 858.966431,891.522278 857.741333,890.263306 
	C857.393616,889.567932 857.332153,889.127869 857.430237,888.341064 
	C857.912964,887.165955 858.559631,886.475464 859.145203,885.324646 
	C860.295166,883.746582 861.466858,882.962830 863.464111,883.569092 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M860.852539,895.794373 
	C861.462036,896.104431 861.704651,896.318848 861.992188,896.832092 
	C861.798523,897.440308 861.527893,898.127258 861.175598,898.171997 
	C857.034790,898.698364 852.779114,898.418457 850.287720,903.172119 
	C849.493286,904.687805 846.470703,904.191406 846.285889,901.731262 
	C846.087891,899.096375 844.726929,898.020813 842.689514,897.083740 
	C838.390381,895.106567 842.004333,891.459167 841.018921,888.359497 
	C841.450928,887.650330 841.900330,887.493774 842.743896,887.613464 
	C844.780396,889.194214 844.026733,891.882629 846.066345,893.316040 
	C847.718384,894.477112 848.844055,895.869080 851.296936,894.781189 
	C854.374817,893.416077 857.408325,895.805054 860.852539,895.794373 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M843.258789,888.265503 
	C842.579224,887.941528 842.140320,887.907532 841.373413,887.907532 
	C840.754456,887.788513 840.506592,887.583435 840.183289,887.114624 
	C839.674072,886.563049 839.416992,886.141418 839.290161,885.258667 
	C839.369873,884.636719 839.450867,884.395386 839.781494,883.863403 
	C840.697144,883.153381 841.432312,882.987061 842.584656,883.013672 
	C844.214539,883.328613 845.174500,884.080750 846.286377,885.234131 
	C848.860901,888.312622 850.424866,885.585449 852.537170,884.525513 
	C854.738342,883.731873 856.462769,882.846313 857.835327,880.844849 
	C858.752625,880.263855 859.442200,880.178650 860.496826,880.479736 
	C862.230713,882.642212 859.865601,883.635986 859.494385,885.547302 
	C859.173645,886.345032 858.899109,886.750366 858.265808,887.339966 
	C857.261475,888.473816 856.926392,889.593689 856.190369,890.502686 
	C854.451904,892.649658 853.100403,895.446167 849.651917,894.835266 
	C846.188477,894.221680 844.353271,891.823120 843.258789,888.265503 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M905.729370,873.700867 
	C906.000000,874.000000 905.994934,874.491821 905.991821,874.737549 
	C905.988708,874.983337 905.995728,874.998230 905.989807,874.993774 
	C905.457642,875.526978 904.929504,876.062866 904.199341,876.795532 
	C904.320679,878.097900 904.646301,879.205566 904.992798,880.661621 
	C903.491821,881.956970 901.830750,882.622131 900.337036,883.669495 
	C897.603333,885.586426 895.414246,885.071472 894.311829,881.284668 
	C894.792175,878.912598 896.349304,877.299133 896.598877,874.814026 
	C897.408386,873.660217 898.283447,873.199158 899.650513,872.831055 
	C901.078796,872.590271 902.130005,872.615112 903.580261,872.732788 
	C904.508118,872.904053 905.001587,873.109436 905.729370,873.700867 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M839.776184,884.775391 
	C840.026917,885.426208 840.057739,885.848389 840.076660,886.586792 
	C839.614929,886.979858 839.165100,887.056702 838.372803,887.145081 
	C836.210266,887.144287 835.804626,888.808899 834.841125,889.824158 
	C833.545715,891.189270 832.060120,892.056152 829.850220,891.075928 
	C829.317749,888.400452 831.078979,885.981445 828.838440,883.444702 
	C827.637634,882.085144 829.578552,880.524780 831.099060,879.726929 
	C833.198486,878.625244 835.266846,879.094177 837.592896,880.186340 
	C838.233826,880.673645 838.522827,881.023682 838.871582,881.736938 
	C839.286682,882.856384 839.045471,883.785217 839.776184,884.775391 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M894.658508,879.845703 
	C895.685242,883.154541 896.626099,885.270935 900.068298,882.282898 
	C901.070496,881.412964 902.829102,881.414368 904.627319,881.013184 
	C901.765137,884.822754 898.946350,889.646545 892.308472,886.100769 
	C891.984680,884.236023 891.993591,882.540771 892.001282,880.419800 
	C892.690186,879.351379 893.481812,879.260620 894.658508,879.845703 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M854.401611,914.239014 
	C854.041382,909.351135 855.716553,907.136902 858.519470,908.478516 
	C860.027954,909.200439 862.951965,909.986755 861.424988,912.683289 
	C859.966858,915.258362 857.806519,917.321655 854.401611,914.239014 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M829.590149,890.849487 
	C833.729797,891.629944 834.254700,886.570496 837.848267,886.889221 
	C835.594421,889.827515 834.647522,894.260742 829.437988,894.122314 
	C828.431946,893.559570 828.196289,892.832153 828.442200,891.614258 
	C828.795837,891.071899 829.001648,890.901855 829.590149,890.849487 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M828.676575,890.954224 
	C829.029907,891.867004 829.025818,892.625183 829.040649,893.765747 
	C827.417297,895.589539 828.146301,897.504272 828.094299,899.653198 
	C827.765198,900.620911 827.382507,901.187744 826.663940,901.848572 
	C822.465454,899.447571 823.545593,893.402222 828.676575,890.954224 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M882.380066,888.005981 
	C883.685425,884.838257 886.416809,884.686218 889.723999,885.683411 
	C888.210205,888.950317 884.926819,886.512512 882.380066,888.005981 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M904.314026,876.948853 
	C904.066345,876.084167 904.329956,875.314758 905.666992,875.035889 
	C905.905945,875.890930 905.664307,876.677307 904.314026,876.948853 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M829.267822,891.067139 
	C823.606384,893.355225 826.368103,898.091309 826.054504,901.875977 
	C826.454224,903.042053 826.580444,904.141479 826.731689,905.656738 
	C826.756714,906.072510 826.476257,906.356873 826.385010,906.534485 
	C825.485413,908.599426 825.108093,910.690186 823.415527,912.520996 
	C821.913940,913.555359 820.699219,914.568848 819.103271,914.298584 
	C817.241516,913.983215 815.747192,912.804565 816.523010,910.892578 
	C819.549194,903.435242 815.488525,897.853943 811.925842,892.089111 
	C810.581665,889.914246 808.064514,888.309387 808.789429,884.934326 
	C809.635681,884.229675 810.337524,884.110291 811.432373,884.253052 
	C812.857849,884.780579 813.704285,885.592773 814.720642,886.141846 
	C819.044800,888.477661 821.257751,887.989502 823.600830,883.693970 
	C824.893127,881.324951 826.950867,879.634521 828.390930,877.432129 
	C829.549194,875.660706 831.207092,875.305725 833.494629,875.856750 
	C835.470459,876.557922 836.688599,877.708984 838.000732,879.294312 
	C838.234131,879.843994 838.279907,880.082031 838.173706,880.650513 
	C836.425354,880.705505 834.821655,880.181885 833.234558,880.228027 
	C831.791565,880.269897 830.200439,880.918579 829.603821,882.374329 
	C828.872803,884.158020 830.867126,883.810486 831.618530,884.440186 
	C835.252625,887.485413 830.019836,888.179260 830.027283,890.619873 
	C829.972900,890.967224 829.501587,891.025452 829.267822,891.067139 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M822.709229,912.258789 
	C824.397644,910.552490 823.155273,907.689880 826.002075,906.794434 
	C827.535767,910.429626 830.332397,913.903015 828.021790,918.543213 
	C822.994873,920.688171 819.258789,917.739502 815.122864,915.344727 
	C816.926086,912.634705 820.758118,915.805908 822.709229,912.258789 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M976.030090,379.562256 
	C973.869507,379.819733 974.036072,378.210785 973.981934,376.349854 
	C973.969299,374.914459 973.975342,373.856873 973.986877,372.402252 
	C977.205750,373.661255 975.589294,376.697357 976.030090,379.562256 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M978.824585,382.751831 
	C977.804199,382.951263 976.518372,383.143341 976.118774,381.243164 
	C977.215149,381.009430 978.120850,381.467896 978.824585,382.751831 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M959.077026,688.204956 
	C960.390076,685.211853 961.787659,682.868530 964.223145,680.569824 
	C966.511108,678.410400 968.155029,672.779968 966.561157,668.140625 
	C966.034668,666.608032 965.249084,664.932739 967.813354,663.928711 
	C968.714539,664.964966 969.311829,666.073914 970.036438,667.529785 
	C970.405701,672.472412 968.186768,676.684814 968.015625,681.594604 
	C965.304565,682.606140 966.017151,684.386353 966.536560,686.109375 
	C967.078857,687.908447 967.520020,689.670837 964.393677,689.994568 
	C962.978088,689.671082 961.944092,689.330383 960.579468,688.983887 
	C959.814148,688.988525 959.453552,688.830322 959.077026,688.204956 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M572.298157,1005.921631 
	C572.655945,1003.558228 574.206177,1003.014832 576.484436,1003.659607 
	C578.611267,1004.261597 579.672424,1002.625427 578.670837,1001.299561 
	C575.357117,996.913330 578.870789,997.149353 581.753174,996.372070 
	C582.326294,995.722168 582.718750,995.476257 583.577576,995.454590 
	C589.563049,999.806152 594.725586,997.800049 599.640625,994.180664 
	C601.492188,992.817261 603.196716,991.279175 605.725220,990.340637 
	C606.542664,990.139465 607.022400,990.095581 607.869507,990.128662 
	C613.557495,990.699585 618.698120,990.349854 623.514893,986.801636 
	C624.246948,986.279907 624.721680,986.077576 625.615967,985.870239 
	C626.812439,985.740662 627.599304,985.847473 628.746704,986.194153 
	C631.114685,987.935364 633.144531,987.860962 635.611084,987.188293 
	C639.357666,986.166626 640.441162,987.314087 640.270996,991.647461 
	C639.867798,991.967163 639.694336,991.976685 639.260498,991.991821 
	C638.542236,991.172119 638.085571,990.227356 636.949402,990.427063 
	C635.938354,990.604614 636.103333,991.579773 635.999207,992.661011 
	C630.129578,989.006409 624.233521,991.805786 618.408386,993.040771 
	C613.611206,994.057800 609.211426,996.678223 603.999878,996.147644 
	C601.946411,995.938599 601.837769,997.848694 602.318176,999.412415 
	C603.683838,1003.856812 601.499634,1004.438721 597.949707,1003.461609 
	C596.473083,1003.055054 594.763184,1002.472046 593.630066,1003.420044 
	C590.587524,1005.965271 588.982178,1004.323425 587.441589,1001.782288 
	C585.832275,999.127930 584.297852,999.932373 582.938965,1001.988770 
	C580.451172,1005.753784 577.436768,1008.041382 572.298157,1005.921631 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M640.347107,992.015259 
	C640.303345,988.183228 638.412292,986.857849 635.273560,988.629028 
	C632.758301,990.048218 631.012878,989.570129 629.000916,987.645508 
	C630.467407,984.812988 633.135864,985.439941 635.798584,984.997681 
	C640.993469,985.034119 645.131775,988.218201 650.526428,988.562988 
	C651.146973,991.649231 653.960266,990.641174 655.076111,992.057312 
	C656.550171,993.928101 658.823853,995.585388 654.361572,996.997498 
	C653.429138,996.007385 652.819641,995.116150 651.217651,995.992310 
	C649.430298,994.960876 647.612793,994.939331 645.408691,994.870361 
	C644.328918,994.607544 643.920227,994.037842 643.178223,993.333069 
	C642.156494,992.771423 641.404785,992.422119 640.347107,992.015259 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M642.930847,993.470703 
	C643.813293,993.056335 644.589966,993.225891 644.967041,994.526733 
	C644.558228,995.183960 644.096680,995.534851 643.333008,995.905029 
	C643.005005,995.223083 642.979248,994.521912 642.930847,993.470703 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M709.285583,975.565796 
	C708.580566,975.946899 707.881836,975.818054 707.620789,976.097778 
	C703.004822,981.044800 696.546509,981.860413 690.255066,983.958374 
	C689.194885,984.098816 688.462219,984.061829 687.361694,984.024353 
	C686.895203,983.784241 686.796448,983.544617 686.791504,982.927856 
	C688.503418,979.524475 690.658142,978.052856 694.451294,978.296265 
	C697.648926,978.501526 700.458496,976.593201 702.044250,973.514709 
	C702.985413,971.687683 703.159790,968.816162 706.139587,969.452515 
	C709.052490,970.074402 709.827637,972.466980 709.285583,975.565796 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M686.873901,982.645508 
	C686.982666,983.187683 686.985596,983.354858 686.991211,983.772949 
	C684.989990,985.208618 682.965210,986.378845 685.053955,989.127136 
	C686.092163,990.493103 685.591431,991.883179 685.016418,993.612793 
	C683.000732,993.996460 680.909241,994.326050 679.994629,991.458191 
	C682.388367,988.379944 678.815491,984.672607 681.693604,981.731934 
	C682.883789,980.982483 683.863159,980.885742 685.252319,981.144409 
	C686.101868,981.483398 686.483032,981.822876 686.873901,982.645508 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M681.751831,981.803040 
	C681.778748,983.736572 680.867065,985.299316 682.316345,987.145996 
	C683.571960,988.745850 683.510925,990.682983 680.310974,991.064331 
	C677.284241,990.836975 674.716614,991.011780 672.103760,991.698303 
	C670.235413,992.189331 668.664551,990.950012 667.158569,989.323975 
	C666.980591,988.567322 666.993164,988.107727 667.024292,987.303955 
	C667.596069,986.468262 668.236206,986.174622 669.328491,986.115234 
	C670.793945,986.260071 671.879822,986.588623 673.330078,986.626587 
	C674.476929,986.625977 675.296021,986.569641 676.339722,986.097778 
	C678.175171,985.026245 678.315552,983.053284 679.866333,981.730835 
	C680.600098,981.440613 681.045654,981.457825 681.751831,981.803040 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M656.207275,986.612915 
	C655.236328,986.934998 654.520691,987.023010 653.446228,987.146118 
	C652.643738,987.454651 652.162720,987.618286 651.262573,987.622925 
	C648.955383,986.698608 647.306824,985.593323 647.299561,982.825195 
	C648.004578,980.981812 649.624756,980.942383 651.019226,979.965454 
	C651.490906,979.646423 651.687317,979.529968 652.199890,979.281433 
	C653.391113,978.873413 654.295349,978.819153 655.537598,978.799194 
	C656.309265,978.837585 656.743713,978.896790 657.488159,979.047607 
	C658.809875,979.444946 659.825928,979.596313 661.184326,979.183594 
	C662.181152,979.032593 662.848694,979.082642 663.837036,979.375488 
	C665.189453,980.124512 666.177307,980.795715 667.167114,982.006958 
	C667.281555,982.589966 667.239441,982.835754 666.928040,983.343567 
	C664.798035,984.624634 662.792847,984.395386 660.444702,984.253540 
	C658.061890,983.566345 657.319580,985.068970 656.207275,986.612915 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M658.706787,983.259277 
	C661.360413,983.063049 663.714355,983.106628 666.506836,983.189392 
	C667.588928,983.841370 667.699829,984.581238 667.216675,985.715454 
	C664.026550,985.973328 661.276917,985.836304 659.434753,989.128540 
	C658.727112,990.393250 658.220703,988.580322 657.989990,987.422729 
	C657.249329,985.785156 657.680908,984.624878 658.706787,983.259277 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M658.794983,982.774353 
	C658.756653,984.153198 658.506836,985.286987 658.122192,986.751587 
	C657.532288,987.053101 657.077271,987.023682 656.287109,986.920654 
	C656.368591,985.127075 655.028015,982.336121 658.794983,982.774353 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M666.984436,986.260620 
	C666.967834,985.324646 666.966736,984.622131 666.955566,983.574097 
	C666.966370,983.035156 666.987244,982.841675 667.027771,982.357056 
	C667.473206,981.670898 667.976196,981.495728 668.943237,981.689697 
	C670.666199,983.117432 671.164307,984.610718 670.249512,986.717773 
	C669.241638,987.015442 668.508057,986.997070 667.408569,986.969238 
	C667.042786,986.959839 666.999939,986.494019 666.984436,986.260620 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M650.763184,987.159912 
	C651.440308,986.903137 651.914795,986.954956 652.738281,987.093994 
	C652.940674,988.040588 652.571167,988.717163 651.340088,988.985718 
	C651.034607,989.034180 650.945312,988.945007 650.900696,988.900391 
	C650.568909,988.433594 650.479126,987.969299 650.763184,987.159912 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M369.816711,982.292725 
	C371.341553,982.337708 373.104858,981.182129 373.607269,983.526611 
	C374.717316,988.706665 375.978088,989.480408 380.795135,986.456665 
	C385.601868,983.439209 389.286591,984.551636 393.118927,988.296143 
	C390.768433,988.850220 388.719330,989.733032 386.785614,991.583862 
	C383.197723,995.017883 377.766541,994.988159 373.430481,997.886963 
	C369.970276,996.675293 367.959747,994.990417 370.921448,991.650452 
	C369.356934,990.729431 367.696594,991.486694 366.292511,990.169434 
	C365.557861,988.922302 364.815491,988.243103 363.351440,988.105225 
	C362.855438,988.070984 362.672546,988.036072 362.213501,987.958984 
	C361.617096,987.518677 361.458221,987.067322 361.619629,986.208374 
	C362.098541,985.526001 362.490753,985.254517 363.268738,984.963135 
	C365.872833,984.791626 367.686096,983.563904 369.816711,982.292725 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M366.047058,990.282349 
	C366.727509,989.942017 367.420807,989.855652 368.052063,990.014954 
	C369.801239,990.456238 371.861267,988.133484 373.165619,990.048462 
	C374.630371,992.199036 371.626190,992.442810 370.848236,993.698425 
	C369.172516,996.403198 372.933533,995.711853 372.996338,997.614258 
	C370.757599,998.730591 368.328491,996.229065 366.001190,998.213928 
	C365.364105,998.757324 364.638367,998.148193 364.505707,997.518799 
	C364.393341,996.986023 364.592896,995.993713 364.953430,995.817871 
	C367.621460,994.517029 365.971252,992.419373 366.047058,990.282349 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M393.795563,996.747192 
	C392.278564,998.648071 390.746582,998.182434 389.157104,996.270630 
	C390.731140,994.396790 392.073547,996.199890 393.795563,996.747192 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M399.861359,996.704956 
	C398.613068,997.000732 397.223358,997.004028 395.416809,997.003662 
	C396.202515,993.825012 397.764801,993.540649 399.861359,996.704956 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M428.228210,1001.725708 
	C428.351410,1000.364563 428.721161,999.390320 429.984528,999.497192 
	C430.789185,999.565308 430.996033,1000.343506 430.751343,1001.008789 
	C430.328491,1002.158325 429.782623,1003.299805 428.228210,1001.725708 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M362.079163,985.656738 
	C361.985809,986.412476 361.975616,986.843201 361.951416,987.595459 
	C360.645325,988.696655 359.256073,989.088745 357.368591,988.967773 
	C356.573517,986.275146 355.056122,987.637451 353.780426,988.097839 
	C352.625946,988.514465 351.292023,989.685181 350.386932,988.019897 
	C349.648102,986.660645 351.120483,986.103271 351.941315,985.386230 
	C352.741089,984.687683 352.965546,983.757324 352.992218,982.372925 
	C357.697296,978.595947 359.576080,982.936096 362.079163,985.656738 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M698.726318,32.826973 
	C700.601440,38.416504 702.202942,43.763420 709.624084,44.047680 
	C711.308716,47.627357 709.823486,50.827698 708.709961,54.059078 
	C708.080383,55.886433 707.705261,58.157249 705.253052,58.326828 
	C702.571838,58.512245 703.924500,55.308640 702.031738,54.286285 
	C700.320557,53.254795 698.474792,53.179737 697.251099,51.397995 
	C696.677429,50.293232 696.441895,49.403202 695.623230,48.441505 
	C695.315918,47.969013 695.202759,47.772530 694.942139,47.268333 
	C693.010498,44.338779 690.583923,41.839909 693.733459,38.631416 
	C692.156128,37.928165 691.294983,38.539623 690.032593,38.819916 
	C688.958069,39.003227 688.244690,38.946335 687.219727,38.574707 
	C685.591309,36.882336 684.175110,35.911537 681.927734,35.832470 
	C679.026428,35.730396 676.030273,35.197247 674.774292,31.441723 
	C676.764771,31.498013 678.524109,31.352802 680.629639,30.960468 
	C683.373840,29.547995 685.938904,28.818024 689.095398,29.119625 
	C691.413818,31.040276 693.075073,33.521610 696.733521,32.234497 
	C697.576111,32.232960 698.037415,32.355690 698.726318,32.826973 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M659.221069,30.216257 
	C659.623352,28.860113 660.292114,27.768829 660.998352,26.352959 
	C663.302002,26.226450 663.674927,23.637508 665.747192,22.963383 
	C666.785889,22.879442 667.488770,22.887892 668.543640,22.870178 
	C669.710022,22.291416 670.505859,21.683844 671.435242,21.353977 
	C673.051575,20.780281 674.751465,20.601072 676.019531,21.964134 
	C677.400513,23.448591 676.769531,24.981823 675.354492,26.719803 
	C675.066223,27.071604 674.998535,27.507858 674.956848,27.724632 
	C674.243469,28.836966 673.356140,29.451651 672.100952,30.075413 
	C671.673523,30.525379 671.316406,30.491758 671.078857,30.672165 
	C671.355347,30.797316 671.613708,30.736679 672.158508,30.483431 
	C672.889771,30.602913 673.225708,30.858345 673.631836,31.529613 
	C672.518799,36.001980 669.971741,37.118942 667.510132,34.022896 
	C665.232056,31.157648 662.442505,30.992350 659.221069,30.216257 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M675.417419,27.019184 
	C676.261902,21.561043 676.261902,21.561043 669.296387,22.883595 
	C669.439026,20.516817 670.807556,19.132936 673.641724,19.001295 
	C674.496887,19.287859 674.989258,19.583138 675.784851,19.847935 
	C676.510620,19.858904 676.933167,19.900358 677.674805,19.932652 
	C681.044617,23.469439 680.030823,25.731247 675.417419,27.019184 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M697.272095,32.701218 
	C694.189697,33.866749 690.883484,36.184219 689.988586,30.461597 
	C689.791931,29.581871 689.883179,29.178799 690.647827,28.935284 
	C691.162476,29.011261 691.330444,29.014172 691.750366,29.013390 
	C692.873596,28.985521 693.758606,29.027655 694.772400,28.274387 
	C695.022522,28.062748 695.497070,28.014456 695.733215,27.982279 
	C695.969360,27.950102 696.461548,28.023554 696.709717,28.041306 
	C698.104736,29.350300 698.362549,30.761614 697.272095,32.701218 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M690.861755,28.691200 
	C690.732483,29.208847 690.470520,29.409340 690.096436,29.834919 
	C687.259033,30.372566 684.533813,30.685127 681.388367,30.961227 
	C682.245239,27.302637 686.398193,27.752666 688.797424,25.549391 
	C689.716858,24.705029 689.840454,27.511566 690.861755,28.691200 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M673.361084,31.286705 
	C672.607849,31.064983 672.146973,31.034920 671.347412,31.005875 
	C671.126343,28.995262 672.265869,28.061598 674.561401,27.957779 
	C674.951904,28.659435 674.988647,29.377462 675.030640,30.450247 
	C675.035889,30.805004 675.010010,31.006618 674.994507,31.107063 
	C674.568848,31.453360 674.128479,31.551125 673.361084,31.286705 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M694.646118,28.026707 
	C694.766541,29.661739 694.015625,30.359362 692.205566,29.253849 
	C692.001587,28.833107 692.000854,28.656517 692.001831,28.214500 
	C692.758850,27.962938 693.514282,27.976803 694.646118,28.026707 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M580.876465,16.429119 
	C582.078918,16.111900 583.170715,16.096655 584.674683,16.078836 
	C594.848145,17.733025 604.610474,19.383247 614.369568,21.052843 
	C616.040588,21.338717 617.519836,22.035913 618.825928,23.672504 
	C619.964905,25.007288 621.468750,24.789425 622.791138,25.758888 
	C625.302551,26.360361 627.810120,25.079588 629.992249,27.316450 
	C630.071716,28.240913 629.861572,28.746086 629.162109,29.441753 
	C627.808777,30.210361 626.712158,30.364744 625.176636,30.404312 
	C622.864502,30.497477 620.964478,30.555166 618.736206,29.887871 
	C617.140503,29.362545 616.544128,27.914061 614.917419,28.063030 
	C606.618652,28.823017 598.781860,27.497429 591.493103,22.445995 
	C590.941833,22.268854 590.728577,22.190973 590.216309,21.972919 
	C589.043396,21.500975 588.334656,20.532902 587.004517,21.330372 
	C586.388306,21.987492 586.013306,22.468727 585.389893,23.167625 
	C584.959656,23.503939 584.765442,23.612486 584.248291,23.820564 
	C583.236816,24.037083 582.560425,23.986816 581.390625,23.324799 
	C580.845642,20.827620 580.805725,18.779356 580.876465,16.429119 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M651.566711,21.884251 
	C655.297363,22.171293 657.790771,22.390148 654.256836,26.701046 
	C651.155273,30.484573 655.337036,29.666094 657.632324,30.076801 
	C658.946350,32.511360 657.139771,32.873665 655.560242,33.262714 
	C653.956970,33.657608 652.309265,33.379993 650.261475,33.209808 
	C648.766785,32.854984 647.848633,32.295277 646.796143,31.262821 
	C645.813110,29.837952 645.421326,28.461571 644.128784,27.280628 
	C644.543884,26.484573 645.117798,25.986107 645.845825,25.243855 
	C648.331055,24.996881 649.163879,22.416853 651.566711,21.884251 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M635.781982,28.184145 
	C638.053589,27.908134 640.088257,27.948153 642.559387,27.978813 
	C643.168945,28.168419 643.342163,28.367382 643.630554,28.946083 
	C641.979675,33.514854 641.289124,33.699825 636.340576,30.968275 
	C635.891541,30.489059 635.742126,30.270733 635.506104,29.666229 
	C635.461304,29.020096 635.503174,28.760136 635.781982,28.184145 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M628.772644,29.299034 
	C629.223450,28.659761 629.452148,28.355888 629.902527,27.861170 
	C631.165771,27.740591 632.207397,27.810858 633.641357,27.855892 
	C634.143616,28.076986 634.253601,28.323313 634.302734,28.971243 
	C633.188660,31.224195 631.703247,32.165226 629.170288,31.400404 
	C628.544373,30.700668 628.451172,30.184874 628.772644,29.299034 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M643.308960,29.314417 
	C643.017822,28.900230 643.012268,28.714237 643.001221,28.248852 
	C643.095215,27.653784 643.278320,27.398008 643.757568,27.092583 
	C645.554016,26.982067 647.012939,27.145844 646.987427,29.642551 
	C645.739441,30.728828 644.646790,30.281343 643.308960,29.314417 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M622.952148,25.625149 
	C621.598938,26.055706 620.221008,25.976498 619.163147,24.294842 
	C620.375793,24.166584 622.037842,23.473101 622.952148,25.625149 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M634.300171,29.214050 
	C633.988770,28.814589 633.993225,28.618261 634.015747,28.126297 
	C634.475891,27.169544 635.023132,26.994057 635.864868,27.667973 
	C636.013428,28.061401 636.008057,28.254684 635.997803,28.737968 
	C635.599487,29.389503 635.140442,29.519123 634.300171,29.214050 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M644.046265,20.312038 
	C644.473145,19.686029 644.947571,19.367464 645.722229,19.034683 
	C645.845459,19.903858 646.359131,21.101849 645.048096,21.454002 
	C644.821106,21.514967 644.416321,20.914454 644.046265,20.312038 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M648.757690,32.336639 
	C651.888550,31.447977 655.122192,33.176590 657.925171,30.475109 
	C658.036804,30.104639 658.495544,30.024853 658.725098,29.988129 
	C659.913513,29.821457 660.901978,29.797968 661.825623,29.541061 
	C664.329529,28.844566 666.616577,28.144983 667.236328,31.970221 
	C667.521423,33.729599 669.297180,34.542732 670.956543,34.592445 
	C672.608582,34.641937 672.894958,33.165363 673.045532,31.451454 
	C673.494446,31.098650 673.920105,31.102251 674.662354,31.156681 
	C677.086060,33.699924 679.675537,35.279175 683.000366,34.434837 
	C685.650757,33.761776 686.764893,34.700764 687.020081,37.585587 
	C686.640808,39.771595 684.897400,40.356873 683.531372,41.758453 
	C679.725281,44.320103 676.055237,45.960220 671.789734,43.976849 
	C670.554504,43.402504 669.586304,43.942902 668.220093,44.568764 
	C667.388916,44.723812 666.911682,44.694225 666.124634,44.413574 
	C664.098206,38.224499 660.250061,35.301090 653.875122,35.892590 
	C652.744019,35.997540 651.606323,35.563583 650.161865,35.116035 
	C649.614929,34.867462 649.405457,34.744011 648.942749,34.353905 
	C648.502136,33.620140 648.463928,33.138840 648.757690,32.336639 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M661.014526,26.016968 
	C660.155823,25.900696 659.097534,26.227081 658.580322,25.216675 
	C658.489258,25.038876 658.907349,24.363173 659.202393,24.269142 
	C660.118835,23.977081 660.613281,24.568140 660.968445,25.700155 
	C661.035767,26.028372 661.000427,26.000031 661.014526,26.016968 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M580.540283,24.659473 
	C579.563232,25.023878 579.102844,25.008787 578.297363,24.966293 
	C578.080017,23.563992 578.923706,23.005533 580.549561,22.915962 
	C580.885559,22.875883 580.959839,22.959002 580.996338,23.001108 
	C581.250549,23.451380 581.248108,23.863838 580.540283,24.659473 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M341.801697,73.309227 
	C344.427429,72.609421 346.254089,70.673050 348.952454,70.744263 
	C350.840973,70.794098 352.230865,69.200760 352.744629,67.308815 
	C353.349823,65.080132 354.166382,63.660633 356.873505,64.772850 
	C358.360840,65.383919 359.784790,64.772034 360.907074,63.592232 
	C361.362000,63.113991 361.768494,62.461662 362.335175,62.237076 
	C369.488831,59.401985 373.258545,52.399925 379.726746,48.199627 
	C382.187103,46.365704 384.167450,47.611340 386.039062,48.594624 
	C388.542633,49.909901 390.142914,49.390060 391.016724,46.697308 
	C391.958710,43.794632 393.842224,42.815739 396.798889,43.701260 
	C398.607788,44.243019 400.334198,43.539555 401.152161,42.021515 
	C403.919312,36.885944 409.302429,35.937927 413.924713,33.965092 
	C418.113373,32.177349 422.435150,30.225471 427.634155,30.990131 
	C428.443268,30.983124 428.885834,30.985750 429.660400,30.988264 
	C434.709015,28.213516 439.993744,28.533278 445.501312,28.353245 
	C447.117981,33.133484 443.325806,37.808567 438.734344,36.578342 
	C434.254639,35.378052 431.291077,34.835609 428.920715,40.214161 
	C427.064636,44.425823 421.175751,44.186947 418.933289,40.288532 
	C417.337585,37.514477 415.899628,38.383789 414.200989,39.724373 
	C412.866791,40.777348 412.134979,42.323566 411.433258,43.823597 
	C410.122101,46.626495 408.307343,48.415680 404.890411,48.020645 
	C401.612244,47.641663 400.771942,49.874996 400.154266,52.564941 
	C398.593079,59.363697 398.498352,59.420742 391.465973,58.312305 
	C388.688812,57.874569 388.154388,58.667423 389.108704,61.207565 
	C390.190308,64.086464 388.714111,65.065422 386.142731,64.225945 
	C382.794952,63.133003 380.359955,64.049240 378.292664,66.744331 
	C377.255737,68.096077 375.639557,69.444267 374.063263,68.954514 
	C369.813599,67.634148 368.235809,70.368843 366.554230,73.211037 
	C364.897278,76.011559 361.772034,77.295624 359.547913,80.023399 
	C354.703857,83.089973 350.637177,86.270645 350.578857,92.721725 
	C350.005890,93.359154 349.581390,93.595001 348.740021,93.775314 
	C345.787872,93.329666 343.424561,94.234451 341.172455,95.244286 
	C336.238556,97.456612 331.413696,99.898827 326.872070,103.499023 
	C325.970093,104.557060 325.076843,104.944931 323.749969,105.267570 
	C320.574524,105.714935 317.745544,106.031738 314.770142,107.281113 
	C314.253265,107.584183 314.037415,107.690506 313.481201,107.918274 
	C310.891541,108.169144 309.081879,109.317513 306.966156,110.703133 
	C305.620270,111.149025 304.618378,111.130104 303.264282,110.716644 
	C300.844574,109.168930 299.791199,107.240562 299.953949,104.716385 
	C300.176208,101.269981 298.375061,101.013290 295.567871,101.618355 
	C292.127869,102.359810 288.306030,103.777931 286.419556,98.598763 
	C287.249115,96.355766 288.195007,94.571930 289.744171,92.717041 
	C291.892639,91.656914 293.616486,92.236504 295.669189,93.088623 
	C303.642548,96.697861 311.138397,100.671776 320.029602,97.918365 
	C322.033478,97.297806 323.508484,96.066902 325.135803,94.961182 
	C330.062592,91.613525 335.910370,89.177010 337.761414,82.265678 
	C338.326752,81.897842 338.583130,81.706657 338.895905,81.224579 
	C341.079926,78.963387 339.810150,75.754448 341.801697,73.309227 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M337.637329,81.998840 
	C337.633606,83.615669 337.311646,85.235153 336.861206,86.818092 
	C336.090759,89.525627 335.492767,91.923409 331.634277,92.327133 
	C329.336975,92.567513 327.108185,94.864075 325.140472,96.572472 
	C320.457458,100.638351 314.714417,101.288055 309.422241,99.797325 
	C304.961304,98.540756 299.961823,97.952362 296.058746,94.386261 
	C299.619995,84.525932 308.193634,79.560272 315.175232,73.141335 
	C318.431549,70.147476 321.814697,70.779182 325.147919,73.068642 
	C326.664612,74.110397 327.776062,75.586945 329.311218,77.027527 
	C329.848022,77.361267 330.170349,77.447655 330.704407,77.239227 
	C331.099182,76.801567 331.320740,76.539871 331.776917,76.144058 
	C333.776886,76.116249 333.842285,77.462265 334.145050,78.777893 
	C334.520081,80.407463 335.140198,81.972900 337.637329,81.998840 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M331.998138,75.607834 
	C331.998718,76.443321 331.996185,76.885429 331.992706,77.659119 
	C331.596466,78.424011 331.152374,78.785904 330.290527,78.994522 
	C329.681763,78.954048 329.268860,78.978653 329.052673,78.784363 
	C320.322662,70.939064 320.241089,70.838478 311.441803,78.364616 
	C306.844116,82.297081 300.740204,84.957848 299.363068,91.879791 
	C299.154022,92.930489 297.923767,93.662552 296.344604,93.971024 
	C294.244202,93.681587 292.502625,93.340080 290.376465,92.994026 
	C287.370880,95.735069 284.709747,97.124603 281.855774,93.267662 
	C280.816254,91.862831 279.576141,92.287056 278.354553,92.968842 
	C275.666595,94.468987 272.721283,94.615753 269.420715,94.503845 
	C267.926483,93.044121 268.340118,91.505333 269.541107,90.954483 
	C274.340729,88.753067 277.768616,84.358238 283.221680,82.629814 
	C286.188660,82.280174 288.604980,81.497505 290.838806,79.409538 
	C291.768707,78.993149 292.432404,78.860619 293.441376,78.729752 
	C294.438660,78.637451 295.103180,78.623436 296.085632,78.529602 
	C298.552185,78.107018 299.335999,76.180252 300.855347,74.574921 
	C304.727020,73.436829 306.690033,69.475555 310.971558,69.180099 
	C311.765808,69.185257 312.243744,69.340706 312.693329,69.274017 
	C312.873322,69.247322 312.714050,68.841164 312.068878,68.527344 
	C311.691101,68.179840 311.556824,68.013893 311.286011,67.546028 
	C310.472534,63.681408 312.440643,62.011543 315.534515,61.236523 
	C318.859985,60.403496 319.909821,62.554012 320.627777,65.185188 
	C320.875214,66.091980 321.167542,67.269447 322.471710,67.069229 
	C323.367310,66.931725 323.731110,66.002075 323.909271,65.206749 
	C324.573059,62.243252 325.990021,59.754433 328.713440,58.033737 
	C322.029449,53.972027 322.029449,53.972027 327.770996,50.274921 
	C328.456512,50.016804 328.882782,49.991863 329.628662,49.947342 
	C334.429108,51.231651 336.909393,55.125446 337.091217,61.648640 
	C336.518127,65.069923 333.773590,63.586632 332.167297,64.391853 
	C330.299988,65.327919 327.575684,65.380402 327.443817,68.084557 
	C327.346527,70.079796 328.234955,71.862694 330.768860,72.289047 
	C332.226196,72.534264 331.898712,74.092728 331.998138,75.607834 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M384.661743,30.998022 
	C385.735413,30.972586 386.468475,30.962297 387.567871,30.938856 
	C389.909546,29.478140 391.441071,27.177433 394.681641,27.794558 
	C394.987244,28.014666 395.000000,28.000610 394.992767,28.008949 
	C395.372528,28.402691 395.524353,28.865280 395.219879,29.718445 
	C390.452209,32.989468 384.583557,33.402622 380.624908,37.531868 
	C380.027924,38.154606 379.052460,37.958931 378.279846,37.525272 
	C377.702606,37.201248 376.766693,36.411743 376.658386,36.516979 
	C373.270813,39.808342 367.654205,37.805897 364.511169,41.679611 
	C363.700775,42.678371 362.637054,43.859238 361.495544,44.126122 
	C358.530518,44.819344 354.661285,44.060650 357.389984,49.503513 
	C357.999084,50.718491 355.055237,52.172714 352.730408,51.797249 
	C345.595673,50.644974 345.564270,50.698307 345.904297,57.341824 
	C345.986755,58.953178 345.494690,59.923611 343.376526,59.983795 
	C341.636292,58.975693 340.837494,57.483471 339.796906,56.203156 
	C336.257141,51.847736 337.445465,46.084930 342.505646,43.444347 
	C343.705780,42.818073 344.887970,42.421947 345.452209,41.041473 
	C346.916046,37.460114 349.739777,35.782852 353.747070,35.239212 
	C354.002777,35.003662 354.000000,35.000000 354.001648,35.001553 
	C356.878632,33.150558 360.028839,34.047337 363.056427,34.221947 
	C367.777924,34.494247 371.637878,31.394768 376.526764,31.059000 
	C377.320831,30.936132 377.778717,30.914845 378.580200,30.961153 
	C380.721069,31.161163 382.519470,31.485067 384.661743,30.998022 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M427.783508,30.729006 
	C421.018982,32.151241 414.629211,34.933479 408.232269,37.847256 
	C406.185638,38.779491 404.699310,39.495407 403.451172,41.566349 
	C401.810883,44.287888 399.684174,47.496532 395.507416,44.791046 
	C393.905823,43.753609 392.940521,44.722347 392.474670,46.117455 
	C390.604065,51.719646 387.425690,52.004875 383.129944,48.641300 
	C382.538422,48.178154 381.558228,48.211349 380.378357,48.006641 
	C380.562653,47.182751 381.176056,46.396309 381.680115,45.545097 
	C382.744843,43.747055 383.262054,41.482689 386.332520,42.204067 
	C389.581635,42.967407 387.911682,45.572983 388.542847,47.994709 
	C390.888733,45.151886 391.879822,42.512817 392.040253,39.376595 
	C392.266174,34.959759 394.504181,33.756718 398.044220,36.149628 
	C400.771698,37.993275 403.069153,39.359657 404.115906,34.667122 
	C404.233673,34.139229 405.025879,33.262642 405.274475,33.335499 
	C413.138275,35.640087 419.723297,28.062323 427.783508,30.729006 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M353.591919,34.998268 
	C353.861145,36.109657 353.341095,36.794716 352.144409,36.962708 
	C348.589478,37.461746 346.636566,39.568607 346.580109,43.130100 
	C346.558258,44.511578 346.054077,45.151234 344.928955,44.747952 
	C342.189392,43.766018 340.762360,44.973206 340.130249,47.411041 
	C339.419250,50.153126 336.924011,53.094719 340.924286,55.500694 
	C342.187866,56.260677 343.099457,57.571247 342.987885,59.628517 
	C341.661682,61.716759 339.896149,62.315056 337.396851,62.023422 
	C335.347656,57.843819 334.274445,53.332764 330.254883,50.098587 
	C329.941437,47.354057 330.932556,45.567337 333.789368,46.167244 
	C338.497772,47.155972 337.799652,44.273754 337.019684,41.669441 
	C336.659698,40.467491 335.898712,39.227940 337.470886,38.903484 
	C342.765045,37.810925 346.830353,31.734701 353.591919,34.998268 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M446.252014,29.192657 
	C440.967133,30.401222 435.589447,29.426497 430.301971,31.105656 
	C430.883240,28.506062 432.423340,27.407810 435.243195,27.832682 
	C437.453186,28.165670 439.619629,27.645130 439.998169,24.383619 
	C441.118042,23.851568 442.749603,24.100380 443.244446,23.464561 
	C446.382843,19.431801 448.626587,21.515823 450.950806,24.641483 
	C448.939087,25.191046 447.682739,26.368656 447.033875,28.653376 
	C446.830597,29.124569 446.666107,29.253496 446.252014,29.192657 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M407.376068,29.964478 
	C407.345428,27.278910 409.896698,27.498798 411.779236,26.269968 
	C414.369385,26.007414 416.741058,26.004040 419.557068,25.997330 
	C420.133850,26.169485 420.266296,26.344973 420.198761,26.782696 
	C416.546387,30.591951 411.607025,27.929111 407.376068,29.964478 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M420.456757,27.031889 
	C419.998779,26.869797 419.998779,26.694660 420.000122,26.256760 
	C424.233826,24.279411 428.642242,23.739281 433.623230,24.043673 
	C432.367035,27.315805 429.273071,26.924095 426.366119,26.988480 
	C424.549652,27.028709 422.731903,27.010712 420.456757,27.031889 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M440.636292,19.032131 
	C440.681915,20.408773 440.375427,21.826466 440.018005,23.604740 
	C438.899597,23.972372 437.832062,23.979420 436.362610,23.966301 
	C435.814514,20.913517 438.237549,20.160366 440.636292,19.032131 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M341.719482,73.159378 
	C341.445862,75.679794 341.640076,78.569313 339.372559,80.928848 
	C339.664764,78.395363 338.647430,75.256950 341.719482,73.159378 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M398.727539,28.770660 
	C400.091187,29.867678 399.828705,30.715967 398.897949,31.359636 
	C398.057159,31.941116 397.402222,31.588526 397.041046,30.329227 
	C396.667847,28.806450 397.459778,28.619629 398.727539,28.770660 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M399.082306,28.780884 
	C398.506073,29.174459 397.929199,29.351936 397.174377,29.779968 
	C396.552521,30.026550 396.108643,30.022575 395.331757,30.025215 
	C394.996674,29.583872 394.994537,29.135910 394.988953,28.352619 
	C396.087494,28.025639 397.189453,28.033991 398.706116,28.088348 
	C399.120819,28.134354 399.081696,28.564781 399.082306,28.780884 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M327.640686,50.030655 
	C328.081848,52.414986 325.912109,52.696644 324.533417,53.654430 
	C324.649048,55.228554 325.919342,54.814011 326.762451,55.108562 
	C328.470184,55.705193 330.926117,56.111954 331.253418,57.752136 
	C331.657379,59.776653 328.991943,59.536724 327.665527,60.310024 
	C325.294220,61.692505 326.097839,64.488441 325.046326,66.484512 
	C324.371460,67.765594 324.307587,69.668488 322.498535,69.769516 
	C320.428345,69.885132 319.954132,67.944687 319.335022,66.445496 
	C318.613037,64.697235 319.674011,61.667931 316.105255,62.048069 
	C313.027618,62.375889 311.904083,64.308372 312.014465,67.591438 
	C308.433838,68.039124 306.179718,66.709595 306.763855,62.371445 
	C309.768188,61.450882 312.515778,60.852615 315.605438,60.089249 
	C318.609863,60.046841 321.177734,60.801476 320.171570,56.078758 
	C319.040405,50.769104 324.490448,51.634388 327.640686,50.030655 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M377.223358,31.674030 
	C374.641815,32.662010 372.422180,33.273579 370.212280,34.775265 
	C368.583374,35.882153 365.347382,35.121819 362.814392,35.351521 
	C360.205627,35.588093 357.535614,35.149426 354.447876,35.004658 
	C355.820648,31.142143 359.287140,33.787418 361.891510,33.017879 
	C363.715271,32.478989 366.920685,34.406734 366.538177,30.087683 
	C366.428406,28.848608 368.030548,28.967075 368.946350,29.059593 
	C370.747711,29.241579 372.528015,29.631674 374.728394,29.904329 
	C375.563599,29.553667 376.036499,29.442266 376.886719,29.726376 
	C377.472534,30.370369 377.549469,30.855059 377.223358,31.674030 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M377.184631,29.561714 
	C376.565063,29.856941 376.156616,29.869429 375.443939,29.877449 
	C375.408112,28.624609 373.025818,27.207626 375.691864,26.036238 
	C376.316681,26.458166 376.629883,26.935801 376.991089,27.704784 
	C377.371063,28.364483 377.479431,28.795029 377.184631,29.561714 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M327.581757,45.042473 
	C327.194641,45.750454 326.803955,45.759727 326.458191,45.254536 
	C326.385864,45.148846 326.388702,44.903660 326.463684,44.800209 
	C326.822632,44.305176 327.212677,44.324474 327.581757,45.042473 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M283.908783,82.696365 
	C279.579224,85.289581 276.451294,89.626030 271.453888,91.005226 
	C270.237518,91.340927 269.391388,92.004173 269.027954,93.616440 
	C267.002686,96.795769 265.096619,96.814278 263.209930,93.454422 
	C264.747986,88.494965 268.441010,84.911964 269.150940,79.906334 
	C267.691864,79.637985 266.863251,81.239655 265.178406,81.723473 
	C262.852051,81.867432 260.954224,81.381226 260.651428,79.197327 
	C260.330780,76.884575 262.368225,76.233543 264.123291,75.652946 
	C266.733215,74.789566 269.091461,73.703560 269.763062,70.272903 
	C271.549438,69.348320 271.999786,67.833672 272.814880,66.223938 
	C272.781372,65.117256 272.942444,64.379128 273.544556,63.469837 
	C273.136017,59.039284 276.531586,56.929546 278.722931,54.326855 
	C279.500244,53.403606 282.302673,51.198719 282.407196,55.338661 
	C282.435211,56.447891 282.931854,56.841194 283.844177,56.890377 
	C284.974670,56.951317 285.595093,56.382507 285.977692,55.254059 
	C286.832947,52.731873 288.229004,50.843639 291.680969,51.879185 
	C295.276276,51.451336 297.737671,47.592308 301.688324,49.616520 
	C302.266266,49.912624 302.908173,50.090340 303.718719,50.749805 
	C304.315369,51.467751 304.623840,51.940899 304.957001,52.708179 
	C304.664246,59.161648 303.027557,60.662231 297.401855,59.126003 
	C294.481934,58.328640 293.104767,59.770844 291.532745,61.923111 
	C291.368164,62.909317 291.190704,63.549881 290.925415,64.500610 
	C290.955475,67.934158 292.507141,65.976746 293.932587,65.448059 
	C294.943359,65.083733 295.646240,65.045898 296.699493,65.254463 
	C298.976501,66.397171 299.852142,68.044350 299.698914,70.209984 
	C299.590851,71.737877 299.822693,73.146736 300.894531,74.711464 
	C300.792664,77.250160 299.665741,78.822624 297.319183,79.873260 
	C295.704132,76.070290 293.216980,73.955299 288.843231,73.630135 
	C285.629395,73.391205 282.900360,71.265457 279.591766,69.751740 
	C284.512024,73.016907 282.167969,78.283150 283.908783,82.696365 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M273.398041,63.191761 
	C273.617554,64.015678 273.506775,64.785118 273.151978,65.734299 
	C268.393799,67.708588 266.138245,63.544521 262.472961,62.113056 
	C261.687744,61.719799 261.505676,61.231594 261.745544,60.303314 
	C261.942566,59.947151 261.997559,60.000263 261.972778,59.971687 
	C263.345642,58.865944 264.832642,58.576557 266.863159,59.680389 
	C269.663635,60.174065 270.544800,63.137112 273.398041,63.191761 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M304.004883,50.658596 
	C299.861328,49.298527 296.216644,51.387711 292.218628,52.268394 
	C294.163055,47.467480 298.365295,48.133385 302.629456,48.005562 
	C303.865540,48.544548 303.996399,49.406673 304.004883,50.658596 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M267.241028,59.646667 
	C265.793854,59.946720 264.311005,59.965042 262.388062,59.963238 
	C262.202881,57.309483 264.336578,56.789562 266.648529,56.037437 
	C267.025421,56.496796 267.052094,56.994900 267.149353,57.864075 
	C267.215057,58.611740 267.210175,58.988342 267.241028,59.646667 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M290.228119,62.198647 
	C291.734741,58.346245 294.002899,56.110123 298.438782,58.154999 
	C303.208679,60.353825 303.477386,56.372849 304.717590,53.240746 
	C306.639587,54.585686 306.135956,56.550198 305.691528,58.341595 
	C305.355255,59.696999 305.102112,60.795738 306.717865,61.869061 
	C306.465240,65.492569 309.643524,66.040489 311.706512,67.840088 
	C312.015503,67.987206 312.005646,67.992424 312.011780,67.987984 
	C312.385681,68.389114 312.519409,68.861023 312.213684,69.725342 
	C309.720062,70.479729 307.525543,70.979034 306.145691,73.210487 
	C305.185394,74.763451 303.541809,75.130768 301.407990,75.025650 
	C298.360168,75.175232 297.532623,73.633316 298.143860,71.402832 
	C298.646149,69.569748 298.850098,68.011497 297.154480,66.328094 
	C296.703003,63.122540 300.052277,64.939705 300.680267,63.142014 
	C297.514557,59.584164 293.840698,63.098404 290.228119,62.198647 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M394.821106,27.701679 
	C392.875519,28.978071 390.763794,29.941477 388.293152,30.915293 
	C389.414185,28.302088 389.938965,23.863537 394.821106,27.701679 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M384.890076,30.767597 
	C383.525757,32.966061 381.658997,32.947590 379.275879,31.978882 
	C378.620758,31.536551 378.508179,31.096409 378.805664,30.313215 
	C380.401978,30.011358 381.789276,30.001144 383.593201,29.981329 
	C384.318604,30.095388 384.574615,30.288919 384.890076,30.767597 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M378.966736,29.703815 
	C378.999207,30.445276 378.983765,30.868980 378.951294,31.608995 
	C378.505096,31.941704 378.075867,31.958103 377.323975,31.977070 
	C376.998535,31.506269 376.995819,31.032904 376.983337,30.201996 
	C376.978088,29.433880 376.982635,29.023306 377.013123,28.304432 
	C377.941528,28.086325 378.701385,28.369375 378.966736,29.703815 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M476.682800,38.172001 
	C477.751740,38.003590 478.500732,38.002392 479.624268,37.999660 
	C483.229034,35.112160 486.753479,33.962624 491.301178,34.337204 
	C495.786285,34.706627 500.202057,33.377682 502.458588,28.548325 
	C503.520355,26.276020 505.551178,25.702686 507.916901,26.098232 
	C509.226837,26.317253 510.579346,26.992416 511.835846,26.710680 
	C524.868103,23.788544 533.959045,32.704735 544.717590,37.723553 
	C546.322998,38.738808 547.726013,39.137062 549.627441,38.999321 
	C550.551147,38.826294 551.102234,38.654049 551.826660,38.243370 
	C552.749451,38.004532 553.498962,38.004124 554.623108,38.003841 
	C558.306519,39.256329 561.427429,37.708553 564.550964,37.154377 
	C567.739563,36.588654 570.338806,36.883972 572.723633,39.732971 
	C573.786926,40.318184 574.573792,40.635143 575.679138,40.980598 
	C578.493164,40.933796 581.111572,40.569393 583.272217,42.112278 
	C588.031433,45.510746 593.194153,45.330074 598.558533,44.279026 
	C603.307861,43.348492 608.070496,42.575459 609.836792,48.950897 
	C609.923889,49.265533 610.192505,49.680054 610.462097,49.692059 
	C616.573975,49.964298 620.083130,56.127953 626.088806,56.589317 
	C629.654175,56.863213 631.486511,58.166946 629.709106,62.027512 
	C628.486816,64.682358 630.275452,65.532272 632.366760,66.253098 
	C637.412720,67.992279 642.511963,69.435509 647.899475,69.514496 
	C651.663147,69.569687 654.704102,70.282135 653.615906,75.223763 
	C653.157776,77.303871 654.714600,78.107620 656.158630,79.026329 
	C658.457886,80.489151 660.425537,82.249359 660.437988,85.635773 
	C659.777527,86.631371 659.069153,86.936852 657.901306,87.152466 
	C656.220337,87.212708 655.020813,86.834373 653.535034,86.152893 
	C651.105835,84.771576 649.778564,82.319626 646.721497,81.978371 
	C646.185974,81.787613 645.976624,81.697037 645.473511,81.430862 
	C642.625061,78.145798 639.215637,77.618729 635.144531,77.978340 
	C634.109985,77.692039 633.502625,77.327423 632.681274,76.633392 
	C630.807739,73.198898 627.546387,72.185646 624.459961,70.343750 
	C622.276367,68.086533 619.616150,66.832222 618.345947,64.132225 
	C617.494568,62.322586 615.835693,62.299213 614.059265,62.607006 
	C611.620361,63.029583 610.152039,62.242367 610.533508,59.411530 
	C611.093384,55.255932 608.821777,55.394264 606.002747,56.519024 
	C604.924866,56.949089 603.958069,57.615944 602.937744,58.169613 
	C597.881592,60.913208 596.972961,60.846382 593.290100,56.482395 
	C591.082214,53.866116 588.419678,53.360062 585.263916,53.735931 
	C577.919373,54.610718 570.423950,54.344234 563.516479,57.932995 
	C560.964539,59.258865 557.801086,59.612278 554.381958,59.331371 
	C553.499451,59.268108 553.003174,59.175499 552.156494,58.914436 
	C547.564514,56.653225 543.003662,55.217075 541.682617,49.479988 
	C541.029846,46.645031 537.538452,46.386623 534.816711,46.739029 
	C530.235596,47.332180 526.420410,46.881763 525.195312,41.464066 
	C525.004089,40.618454 524.344910,39.734753 523.524658,39.818371 
	C519.158264,40.263500 517.377808,37.294228 515.586121,33.761265 
	C515.063904,37.971779 514.059387,41.636269 509.656738,42.666618 
	C505.929749,43.538860 502.586212,45.935284 498.704834,45.866871 
	C494.628113,45.795021 490.951660,47.673870 486.913269,47.697784 
	C484.629364,47.711304 483.380646,49.352116 483.119019,51.621727 
	C482.671112,55.507584 480.020050,56.884552 476.595490,57.300144 
	C468.802399,58.245892 461.128357,58.277939 453.374268,56.260357 
	C443.336334,53.648529 434.012054,54.670658 426.698517,63.364758 
	C423.087219,67.657745 417.779449,68.247780 412.529663,68.155304 
	C410.101624,68.112526 407.883423,68.225655 405.736938,70.201340 
	C405.009308,70.675957 404.557709,70.877052 403.727783,71.113052 
	C401.156738,71.652237 398.529114,70.644058 396.587738,73.287292 
	C395.489990,74.152504 394.572815,74.566910 393.187927,74.848358 
	C392.281799,74.871758 391.785736,74.754074 391.022644,74.293823 
	C391.172668,67.727051 394.785095,64.277565 400.515747,65.001709 
	C403.952576,65.436005 404.573547,63.892460 404.131744,60.959938 
	C403.289520,55.369144 403.342773,55.361122 408.908539,54.847614 
	C413.702087,54.405354 415.172119,52.750160 414.594971,48.152538 
	C414.343231,46.146893 415.074829,44.363583 416.911346,44.506191 
	C420.908386,44.816566 425.269531,42.777592 428.880920,45.994400 
	C430.378540,47.328365 431.880249,46.994030 433.506165,46.297375 
	C437.875061,44.425426 441.794586,41.232090 447.030762,41.880886 
	C448.290253,42.036945 448.874634,40.670631 449.683136,39.847157 
	C454.092896,35.355515 460.884277,33.168262 465.773407,36.482536 
	C469.110657,38.744820 472.952820,37.093380 476.682800,38.172001 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M545.008972,37.605293 
	C537.953796,38.211468 533.575928,32.554729 527.775146,30.006958 
	C525.104675,28.834040 522.647705,28.068958 519.764832,27.936834 
	C515.547119,27.743534 511.242554,29.471718 507.080322,27.252489 
	C505.834320,26.588148 504.178955,27.061653 503.814789,28.609171 
	C502.117065,35.824013 496.147583,36.002819 490.824585,35.781048 
	C487.035431,35.623180 483.967499,36.651936 480.370850,37.993210 
	C480.258789,37.502041 480.518860,37.005955 480.885498,36.250252 
	C480.992096,35.990635 481.000031,35.496250 481.004272,35.249077 
	C483.814941,33.096710 486.292023,31.749731 490.318390,32.536335 
	C493.960815,33.247940 495.779510,28.347073 497.240173,25.653301 
	C499.282166,21.887531 501.256287,21.709183 504.762939,22.653269 
	C507.411896,23.366432 510.315948,23.640179 512.885254,21.760918 
	C514.189026,20.807350 515.733704,20.567589 516.879028,22.038990 
	C519.155396,24.963522 521.338013,24.316517 523.495483,21.990763 
	C526.234436,19.038187 527.701904,19.913965 529.047302,23.375366 
	C530.372559,26.784647 532.544128,28.765873 536.976685,28.337099 
	C543.597351,27.696663 546.416931,31.244993 545.008972,37.605293 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M660.211792,86.263321 
	C658.949097,83.156609 657.607605,80.708275 654.285278,79.836220 
	C651.485168,79.101257 650.630310,77.293961 652.174316,74.553444 
	C653.674927,71.889923 652.418701,70.882240 649.735535,71.008202 
	C643.114746,71.319046 636.945984,69.332481 630.737671,67.421562 
	C626.875305,66.232712 625.710571,64.336243 628.513306,60.997440 
	C630.752319,58.330227 629.024780,57.909107 626.788330,58.032478 
	C623.291199,58.225395 620.165894,57.967094 618.393616,54.236671 
	C618.058289,53.530918 616.795471,53.083069 615.878174,52.868412 
	C612.035828,51.969353 607.887634,51.696537 607.365417,46.319279 
	C607.252136,45.152946 605.590576,44.956615 604.397583,45.031189 
	C602.918579,45.123642 601.431641,45.257210 599.975708,45.523643 
	C594.137268,46.592075 588.300842,47.998497 583.075806,43.565113 
	C581.217651,41.988483 578.700745,42.216934 576.252991,41.276085 
	C579.360596,39.622456 582.000000,35.523262 586.541321,38.579201 
	C588.001770,39.561996 588.804199,38.239780 589.001831,36.654354 
	C589.592163,31.918362 591.931580,32.603512 595.182678,34.408192 
	C598.323303,36.151600 601.174316,39.426376 605.737671,37.259140 
	C606.217041,36.897144 606.437256,36.809620 606.989441,36.845703 
	C609.902893,39.739647 612.642334,42.420406 612.420410,47.258209 
	C615.992310,44.982430 618.525452,41.605473 623.155396,42.737228 
	C626.886719,43.660137 630.929260,43.223644 632.723572,47.743614 
	C632.990234,49.187759 632.523499,50.928738 633.856506,51.110455 
	C637.755432,51.641968 639.650818,55.181686 643.225586,56.724030 
	C645.625977,57.440479 647.173767,58.599693 647.770325,61.158287 
	C647.226624,64.357376 647.662537,66.446472 651.179688,66.677132 
	C653.043274,66.799347 654.861267,67.461296 655.711365,69.276657 
	C658.773804,75.816383 664.506165,78.814018 670.909241,80.951469 
	C672.183838,81.376945 673.303528,82.052887 674.727783,82.956421 
	C675.204102,83.340851 675.381714,83.512085 675.784668,83.979568 
	C677.520264,89.914467 680.987549,93.230934 686.938477,94.013275 
	C689.123291,94.300514 691.044128,95.490425 690.496399,98.661575 
	C687.793030,100.700783 685.654724,98.514908 682.969910,98.178215 
	C681.736328,97.815445 680.876526,97.464455 679.690735,97.022583 
	C678.358154,96.448898 677.512573,95.712090 676.248657,95.026253 
	C675.768921,94.747215 675.578491,94.631401 675.107544,94.331520 
	C672.845398,92.603462 670.493347,91.774933 667.964722,90.557472 
	C666.238892,88.240448 664.268433,87.069351 661.332764,86.969894 
	C660.814758,86.755104 660.619202,86.641640 660.211792,86.263321 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M607.311157,37.266132 
	C606.834839,36.992832 606.667236,36.990856 606.248169,36.986778 
	C600.959106,31.644104 594.539185,29.341902 587.528076,30.400501 
	C584.592651,30.843723 582.396423,34.145699 578.755432,34.786098 
	C574.601318,35.516747 571.622009,32.807613 567.930908,32.327087 
	C566.322144,32.117653 566.651917,28.984365 568.666992,27.185335 
	C572.827698,31.236883 576.223877,30.847719 579.973999,25.393105 
	C580.023560,25.038969 580.005493,25.011997 580.011353,25.014114 
	C581.020996,24.128109 582.388367,24.025099 583.738281,23.166216 
	C583.963684,22.934780 584.397400,22.874006 584.616333,22.869953 
	C585.956970,22.091831 587.185059,21.700991 588.938232,21.900906 
	C590.565674,22.224092 591.705750,22.716265 592.953369,23.760075 
	C594.036804,25.402109 594.426270,27.470438 597.187378,26.361221 
	C598.460815,26.142225 599.305725,26.467360 600.394104,27.096432 
	C603.528381,31.341812 604.692566,31.545130 608.910583,28.531380 
	C609.901611,28.100107 610.601990,28.088299 611.638000,28.368919 
	C616.020813,33.625343 617.649109,33.751381 624.758911,29.232918 
	C626.062744,28.980688 627.129089,28.973827 628.595032,28.965307 
	C628.994507,29.416424 628.994324,29.869202 628.997070,30.661217 
	C632.151489,34.163483 635.919922,36.686752 638.696960,40.229164 
	C640.020325,41.917191 641.978943,42.366203 643.730164,43.277756 
	C645.689514,44.297623 648.254456,44.957344 647.650635,48.328865 
	C645.380981,49.720440 643.186646,49.426434 641.040344,49.486504 
	C636.747009,49.606663 633.575745,48.163013 632.512329,43.574139 
	C632.133057,41.937660 631.571350,40.002899 629.381775,40.445660 
	C623.490601,41.636909 617.845581,39.810310 611.689453,39.300392 
	C609.920837,38.986561 608.645508,38.529072 607.311157,37.266132 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M572.960693,39.653564 
	C568.755676,37.165764 564.362000,38.422543 559.965637,39.507374 
	C558.273071,39.925011 556.477417,40.799225 555.081482,38.373886 
	C558.628540,35.433235 563.203064,36.149479 567.221985,34.597675 
	C569.317383,33.788593 571.857666,36.628540 572.960693,39.653564 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M476.713074,37.784054 
	C473.789429,40.930267 470.413208,38.970779 467.287109,38.035061 
	C462.964508,36.741173 458.442291,34.377892 454.552826,37.831448 
	C450.402222,41.516888 446.200012,43.927464 440.539581,44.480186 
	C437.813477,44.746384 435.312378,47.158566 432.671539,48.525845 
	C431.443573,49.161617 429.376678,49.521828 428.945892,48.546196 
	C426.266235,42.477627 420.808685,47.727455 417.134552,45.494614 
	C416.005890,44.808701 414.849487,46.480141 415.754272,48.100037 
	C416.564850,49.551140 419.300690,50.985920 417.142639,52.587399 
	C414.860626,54.280861 412.500946,57.218681 409.374054,56.475269 
	C404.436096,55.301277 403.571808,56.353771 405.696289,60.864475 
	C406.432556,62.427711 406.550873,64.585464 405.097443,66.049881 
	C403.611938,67.546616 401.610901,67.333817 399.987366,66.393753 
	C397.097412,64.720390 395.504089,65.187569 394.549683,68.537720 
	C394.013885,70.418419 391.558441,71.194992 391.038116,73.648193 
	C390.653992,74.391502 390.229614,74.654518 389.348419,74.825119 
	C384.174072,72.827431 379.987244,73.660210 376.174683,77.268654 
	C373.602417,79.703217 370.062286,80.606590 367.040649,82.360214 
	C364.576019,83.790604 362.234558,82.146698 359.494568,80.527893 
	C358.941254,78.592827 359.219360,77.443024 360.642517,76.685028 
	C365.094543,74.313858 367.673950,70.508095 368.491425,65.429459 
	C373.536346,69.147781 374.810181,69.099159 378.276550,64.435982 
	C381.364532,60.281830 384.857666,62.312347 388.328278,63.638447 
	C389.169128,60.961327 384.464935,59.962086 386.739685,57.406967 
	C388.932495,54.943893 391.248627,56.836685 393.555847,57.637447 
	C395.843811,58.431549 397.866577,58.293362 398.172119,55.102154 
	C398.312744,53.633736 398.898926,52.199303 398.968597,50.734501 
	C399.135010,47.235073 401.648743,45.487499 404.217194,46.438576 
	C408.377960,47.979321 409.246857,45.237946 410.370575,42.853729 
	C411.457245,40.548058 412.397156,38.196651 414.891693,37.226246 
	C417.269043,36.301430 420.272278,34.661182 420.058014,39.658028 
	C419.969238,41.729221 421.468719,42.017574 423.105988,41.985485 
	C425.830048,41.932095 428.489502,40.804333 428.794678,38.194805 
	C429.418549,32.860123 431.992950,32.987366 435.986786,34.439865 
	C442.051727,36.645634 442.293549,36.420612 445.993103,29.369221 
	C446.002441,29.002893 446.498840,28.999617 446.746979,28.997627 
	C448.965607,29.152037 451.484680,28.943558 450.163025,32.155418 
	C449.623505,33.466526 448.732880,34.599251 450.358612,35.494957 
	C451.878357,36.332253 453.922882,36.556206 455.007080,35.218048 
	C458.887329,30.428877 462.377441,32.714531 466.035583,35.382221 
	C467.678162,36.580097 469.403107,37.276733 471.581635,36.350307 
	C473.317291,35.612194 475.182770,35.678669 476.713074,37.784054 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M464.079254,986.603699 
	C462.907837,986.679321 461.813629,986.395508 460.379822,986.075806 
	C458.314178,987.451416 456.451813,987.541260 454.172302,986.376709 
	C452.100800,983.758301 453.052917,981.386414 453.927155,979.041382 
	C454.906860,976.413574 455.692627,973.731140 456.695770,971.011047 
	C450.984314,971.046265 449.671173,972.964355 449.227173,981.725586 
	C449.039917,982.565369 448.838989,983.013062 448.289734,983.675659 
	C447.558014,983.917603 447.108551,983.915222 446.322998,983.885864 
	C445.308502,983.558411 444.848206,983.030212 444.328430,982.093018 
	C443.496033,975.124084 443.495789,975.123657 437.602020,978.311523 
	C437.021698,978.625366 436.417328,978.881531 435.462830,979.195068 
	C433.524353,979.564758 432.157227,980.738708 430.088043,980.431885 
	C427.681183,978.042175 424.092133,977.458130 422.418732,975.428101 
	C418.473358,970.641663 414.010101,967.838867 407.835754,967.348267 
	C406.453827,967.238403 405.091309,966.351562 403.532959,966.667664 
	C400.969147,967.187622 398.994934,966.048828 397.321106,964.257019 
	C396.046783,962.892944 394.698547,961.749512 392.737305,961.471375 
	C390.672882,961.178589 388.160095,961.561157 387.301941,958.872803 
	C386.395325,956.032715 388.834442,954.777832 390.549164,952.901855 
	C389.175568,951.051941 387.138031,950.321716 385.147858,949.783813 
	C382.687256,949.118774 380.388062,948.352905 378.642029,946.368591 
	C377.979187,945.615356 377.026123,944.792419 376.090057,944.948669 
	C370.876892,945.818787 366.342896,942.138489 360.966400,943.107178 
	C356.858582,943.847351 354.923462,941.630859 355.503937,937.558655 
	C355.935669,934.530151 354.348328,932.057739 354.270996,928.894409 
	C355.052429,926.535767 356.551910,926.111633 358.794586,926.636902 
	C359.341278,926.837036 359.551544,926.930542 360.057556,927.188416 
	C361.257355,927.919189 362.092285,928.639160 363.569061,928.678955 
	C364.776672,928.784058 365.647705,928.919617 366.758118,929.438477 
	C367.347656,929.951233 367.555542,930.343018 367.727905,931.113892 
	C367.821045,932.296875 367.570587,933.222351 368.670166,934.068848 
	C370.227997,935.262573 371.760742,935.668030 373.603912,936.193420 
	C375.775360,937.448975 377.623962,936.803955 379.804230,936.185425 
	C380.599915,935.994019 381.069824,935.935242 381.916077,935.917480 
	C382.791748,935.997742 383.272369,936.143250 384.042847,936.584045 
	C384.507751,937.008606 384.660889,937.210876 384.978455,937.753540 
	C385.679779,944.862549 390.025055,947.065308 396.111969,947.795898 
	C403.535767,948.686951 404.500275,949.935181 404.410767,957.010193 
	C404.384674,959.071594 405.173950,960.155457 407.118835,960.439575 
	C409.416260,960.775269 411.743591,961.725037 413.967712,961.532593 
	C421.032623,960.921082 427.380432,965.079651 434.426453,964.294800 
	C436.004272,964.119019 437.281464,965.080139 438.369049,966.135742 
	C439.426239,967.161743 440.478302,967.750793 442.133972,966.473267 
	C443.501617,965.500793 444.672577,965.049194 446.378021,965.059204 
	C447.764069,965.219360 448.730652,965.632507 450.153931,965.392639 
	C460.191742,963.572144 469.835022,961.461853 479.997742,964.432983 
	C480.571869,964.615906 480.797699,964.683594 481.337280,964.883301 
	C482.415649,965.418945 483.200928,965.671509 484.384094,965.263306 
	C485.388580,965.075684 486.060181,965.136536 487.033844,965.513062 
	C490.956482,970.964600 495.295166,975.270264 501.498077,977.610229 
	C503.918518,978.523315 505.689941,980.798828 507.617126,982.643433 
	C511.261261,986.131104 515.077881,986.645447 519.424316,984.156189 
	C524.175293,981.435364 528.824646,978.565613 533.851807,976.326782 
	C536.878601,974.978882 539.694641,973.157349 540.995483,969.746338 
	C541.681824,967.946594 543.350830,967.014404 545.198120,965.852905 
	C545.903931,965.475281 546.336487,965.351074 547.114502,965.317749 
	C548.456177,965.622620 549.456665,965.734497 550.688965,964.887390 
	C559.455322,960.752625 568.027405,963.224670 576.248352,965.095032 
	C583.358826,966.712769 589.352844,966.342468 595.669434,962.523376 
	C600.143799,959.818115 605.562866,958.562195 610.915222,959.098816 
	C614.754944,959.483765 617.430420,958.632263 618.985168,955.020203 
	C620.575562,951.325562 623.579651,949.535889 627.336853,948.632385 
	C632.412842,947.411804 637.062439,945.330261 640.912842,941.665527 
	C642.501221,940.153748 644.419495,938.797791 646.615356,938.868530 
	C653.227539,939.081604 657.517334,935.683716 661.007446,930.116089 
	C664.633301,926.607178 669.208679,925.890503 673.604309,923.985718 
	C675.287903,923.664185 676.492615,923.195496 677.512512,921.741699 
	C679.046082,920.510742 680.511230,919.846313 682.364563,919.164490 
	C682.946838,919.022461 683.185364,918.982605 683.789673,918.930237 
	C684.647461,918.956543 685.128784,919.050171 685.935608,919.356812 
	C686.665894,919.814026 687.007324,920.162598 687.416260,920.908936 
	C685.278198,925.792358 684.194824,931.129272 678.950439,934.120850 
	C678.143677,935.170837 677.470215,935.865906 676.597900,936.880127 
	C674.073425,943.740295 668.982239,946.537109 662.506104,946.343933 
	C653.714966,946.081726 645.977234,949.455505 638.019653,952.269348 
	C635.813782,953.049377 636.362488,954.021423 637.284790,955.591492 
	C640.164001,960.493164 637.450500,965.638184 631.728699,966.355042 
	C630.240112,966.541504 628.728821,966.536316 627.234558,966.453247 
	C622.794067,966.206543 618.576599,966.423889 615.456726,970.329895 
	C614.647400,971.343140 613.281372,972.039673 612.006042,972.260193 
	C607.654968,973.012695 603.830383,973.845276 601.000305,978.200806 
	C599.306091,980.808350 594.751526,979.557373 591.290710,980.749695 
	C590.210938,981.303894 589.427612,981.617554 588.327271,981.956055 
	C587.471436,981.455200 586.934082,980.928223 586.192078,980.206787 
	C583.686096,979.860596 581.359009,980.282593 579.070496,979.541748 
	C576.287842,978.641052 574.858948,979.519043 575.338684,982.656738 
	C575.430054,983.254700 575.524963,984.053894 575.222351,984.477539 
	C573.247070,987.243347 561.752075,988.224060 559.193115,985.565186 
	C555.286438,981.505981 550.892517,981.248535 545.958923,982.863892 
	C544.094055,983.474487 542.360168,983.954102 540.199707,983.330872 
	C537.247253,982.479248 534.341187,983.924011 532.910889,986.435120 
	C528.559021,994.075745 520.891113,996.602417 513.515991,999.507996 
	C510.610809,1000.652527 509.271332,998.313904 509.063782,995.758301 
	C508.816437,992.712830 507.407104,991.348755 504.529175,992.367920 
	C502.044373,993.247925 500.455627,991.400696 498.433594,990.937317 
	C495.642029,990.297668 496.169128,988.321106 496.580780,986.216736 
	C496.846741,984.857056 495.606506,984.100952 494.349731,984.104126 
	C487.827759,984.120483 481.078186,981.682251 474.935181,985.989746 
	C473.201904,987.205139 471.316376,987.345947 469.377655,985.968933 
	C467.685333,984.766846 465.790222,984.199585 464.079254,986.603699 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M591.400696,981.003540 
	C592.263916,977.804993 595.332947,978.428528 597.733459,978.102173 
	C600.167236,977.771301 601.887817,977.378174 602.040222,974.471436 
	C602.188965,971.633667 604.202271,970.637878 606.561951,971.422241 
	C610.605591,972.766296 613.629517,970.672058 615.676758,968.155640 
	C617.793823,965.553467 619.944397,964.841492 622.933838,964.974976 
	C625.590820,965.093628 628.307617,964.673035 630.908081,965.073975 
	C634.993042,965.703918 635.997986,962.342041 637.527527,960.112183 
	C639.143311,957.756775 636.219421,956.859680 634.998047,955.481018 
	C634.248047,954.634460 633.035950,953.934509 633.458679,952.580200 
	C633.864136,951.281189 635.097839,951.166321 636.293335,950.987671 
	C640.246582,950.396851 643.660278,948.588562 647.268250,946.903870 
	C651.232910,945.052734 656.275391,945.351013 660.870178,945.065247 
	C663.875549,944.878357 666.639160,944.321716 669.545898,943.418152 
	C673.509338,942.186218 673.448853,938.599548 675.632568,936.074707 
	C678.381775,937.077209 677.690186,938.883362 677.029968,940.618652 
	C676.053223,943.185303 674.237732,945.102051 672.019287,946.635132 
	C668.667114,948.951660 667.073181,951.896912 668.495728,956.351624 
	C668.489441,956.942993 668.451111,957.178711 668.206421,957.707214 
	C664.263672,960.296082 660.591614,962.525269 656.080505,959.569336 
	C651.870850,956.811035 649.802246,957.109680 645.784241,960.512939 
	C645.155823,961.045227 643.970764,961.356445 643.826538,961.957764 
	C642.177429,968.834961 636.909424,967.985168 631.747864,968.104126 
	C627.568665,968.200317 624.021912,970.498108 622.202881,974.243835 
	C620.898376,976.929993 619.588806,977.048706 617.144470,976.251343 
	C614.309387,975.326416 611.941406,976.284973 609.001648,977.441284 
	C603.680054,979.534546 598.307312,983.839233 591.400696,981.003540 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M658.300354,979.980469 
	C657.564270,979.969910 657.128601,979.965088 656.366089,979.956787 
	C654.967834,978.730652 653.840942,978.007324 652.246216,979.791077 
	C652.030518,980.025940 652.002441,980.000000 652.012817,980.015686 
	C650.138855,981.707825 647.497681,980.524597 645.206299,981.956787 
	C644.705872,982.263184 644.495422,982.371521 643.943115,982.585938 
	C643.125122,982.755920 642.648560,982.762451 641.821899,982.628113 
	C638.815613,980.934021 635.240784,983.592285 632.929321,980.356201 
	C632.999023,979.838379 632.995972,979.667969 632.992798,979.242004 
	C633.872131,978.153137 634.716675,977.284668 635.789368,976.216187 
	C636.003845,976.002441 636.000000,976.000000 636.001221,976.001953 
	C637.055420,974.835388 638.374939,973.758728 639.899963,974.575500 
	C644.973694,977.292419 647.746338,974.081604 650.083374,970.548218 
	C652.532166,966.845825 655.576477,965.856628 659.437683,967.620056 
	C663.363586,969.413086 666.594971,968.251587 668.335693,964.848999 
	C670.758484,960.113098 674.420410,959.038818 679.046936,959.834717 
	C682.270996,960.389282 683.719055,959.266846 683.831543,956.053284 
	C683.878601,954.708069 684.441345,953.435059 685.353394,952.441345 
	C687.319519,950.298828 688.152649,948.098633 686.697449,945.245972 
	C685.748047,943.384644 686.963135,941.740479 688.159790,939.892029 
	C688.893799,939.057983 689.494568,938.637207 690.499695,938.174316 
	C693.313049,937.406311 695.431335,938.383606 697.466125,939.574524 
	C700.142883,941.141235 701.926453,941.139404 702.913757,937.558960 
	C703.705994,934.685913 705.374939,932.098145 707.375793,929.822449 
	C708.411194,928.644836 709.684082,927.820251 711.339417,928.486755 
	C712.831909,929.087708 713.060486,930.466064 712.961609,931.825073 
	C712.811584,933.887085 712.760620,935.849182 714.973938,937.429810 
	C716.156799,943.636414 711.217590,941.031189 708.661377,941.977844 
	C713.535278,945.994202 713.547424,946.126343 709.061951,948.791992 
	C707.715698,948.996521 706.772461,948.836182 705.602051,948.113403 
	C703.105591,945.736877 701.507812,946.188538 699.682007,948.649109 
	C697.324158,951.826416 695.189453,955.167480 692.285583,957.990173 
	C690.055664,960.157959 690.091736,963.363220 690.546692,966.446045 
	C691.324707,971.717773 689.499573,973.151367 684.381531,971.228027 
	C680.993347,969.954773 677.448608,969.912048 674.109253,970.508301 
	C670.891846,971.082886 667.770447,972.623657 666.972107,976.567566 
	C666.680481,978.008179 666.157043,979.476624 664.299377,980.162231 
	C663.212830,980.088013 662.475281,980.079407 661.369019,980.081543 
	C660.202515,980.008911 659.401550,979.998413 658.300354,979.980469 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M688.749023,939.758362 
	C688.544067,942.305908 685.830078,944.676331 689.224548,946.898254 
	C689.538147,947.103455 689.809570,947.946289 689.645386,948.222290 
	C687.252136,952.246399 684.630981,956.067078 684.021790,960.994873 
	C683.828491,962.558044 681.486755,961.444946 680.050354,961.221497 
	C675.411926,960.499756 671.266724,960.442200 669.248718,966.190857 
	C668.044067,969.622375 662.599548,970.593201 659.216492,968.868225 
	C656.290955,967.376648 653.272888,967.005066 651.722961,970.719482 
	C650.527893,973.583435 647.905273,974.753235 645.878235,976.647644 
	C644.218689,978.198608 642.632996,978.341248 641.162781,976.610107 
	C639.801697,975.007568 638.320618,975.252808 636.349243,975.967102 
	C636.257629,970.740295 639.567688,969.120239 644.238525,968.973877 
	C646.013672,968.918274 648.908813,969.718384 646.710632,965.964783 
	C645.480591,963.864197 647.301819,962.223267 648.754089,960.900818 
	C650.406860,959.395813 651.282166,961.320068 652.527771,961.908691 
	C656.206299,963.646912 660.375000,964.368286 663.660156,967.883911 
	C664.489258,964.170959 668.455322,962.642517 667.999878,958.383972 
	C668.000793,957.833313 668.001526,957.666687 668.003601,957.250000 
	C669.528564,952.229736 672.380859,949.418640 677.802856,950.069763 
	C678.864319,950.197144 680.583923,950.652222 680.268982,948.297119 
	C679.415161,941.913330 681.791443,939.430298 688.749023,939.758362 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M626.188660,986.567749 
	C625.513733,986.913879 625.111877,987.040710 624.421753,987.297119 
	C623.258789,987.899658 622.343140,988.178345 620.992554,987.944946 
	C619.100525,986.955505 617.484497,986.440308 615.351440,987.082031 
	C614.579163,987.110840 614.140015,987.080627 613.381714,986.964355 
	C611.375610,986.369080 609.747559,986.067200 608.392578,988.178833 
	C607.551941,988.751587 606.903870,988.890808 605.885803,988.855408 
	C605.121643,988.702087 604.703430,988.555969 604.000488,988.251709 
	C602.849792,987.683838 602.082458,987.933350 601.040649,988.578796 
	C600.298706,988.914124 599.847290,989.047668 599.021362,989.203064 
	C598.156372,989.293335 597.655518,989.298340 596.768616,989.248352 
	C594.223633,988.432129 591.611572,988.849243 589.960754,986.405884 
	C595.755249,985.646667 601.219116,983.979065 606.673157,982.257507 
	C607.827881,981.893005 608.993286,981.978516 610.066345,982.571350 
	C612.962891,984.171753 615.576355,984.933411 617.617676,981.191528 
	C618.512878,979.550476 620.166077,980.371643 621.666016,980.665894 
	C623.438538,981.013611 625.300903,980.903137 627.557251,980.989075 
	C628.346069,981.315063 628.590637,981.711792 628.738770,982.533203 
	C628.497864,984.379089 627.553528,985.412598 626.188660,986.567749 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M577.096863,990.270386 
	C576.919434,989.470093 576.934631,989.035339 576.934692,988.273682 
	C579.997803,985.238098 583.729248,985.357727 587.721008,985.781311 
	C589.621460,987.850830 592.184082,987.557800 594.289917,989.083252 
	C592.409668,994.566833 586.366455,996.742920 581.528564,994.058777 
	C579.925964,993.169678 578.543091,991.974548 577.096863,990.270386 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M628.300354,983.308838 
	C627.997681,982.550842 627.997864,982.104187 627.994141,981.322144 
	C629.314636,980.237183 630.726562,979.863281 632.629028,980.001465 
	C634.362183,981.516296 636.044800,981.170349 637.605591,980.609253 
	C639.157654,980.051147 640.491638,979.865906 641.824463,981.587402 
	C642.386108,982.365479 642.535400,982.906189 642.263306,983.899780 
	C641.095459,985.135437 639.918579,985.028992 638.325562,984.999634 
	C634.734863,984.953247 631.509338,985.362488 628.300354,983.308838 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M536.977173,1004.632385 
	C534.581177,1004.645142 532.196411,1004.324524 529.404541,1004.001953 
	C530.800110,1000.276306 533.850342,1002.455444 536.718384,1002.695129 
	C536.997803,1003.407776 536.993103,1003.853394 536.977173,1004.632385 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M594.391968,989.317444 
	C591.903748,988.800354 589.636963,988.723999 588.048462,986.399658 
	C588.423218,986.034607 588.872681,986.017151 589.659485,986.005859 
	C591.775208,987.680908 594.355530,986.721924 596.652588,987.980347 
	C597.120239,988.266541 597.210693,988.500305 597.086426,989.043335 
	C596.176941,989.752563 595.453674,989.798401 594.391968,989.317444 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M635.665283,976.039185 
	C635.919006,977.528442 635.257812,978.593872 633.332397,978.953552 
	C633.084473,977.465881 633.751221,976.406555 635.665283,976.039185 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M586.041382,980.324280 
	C586.892273,980.082336 587.669495,980.332520 587.959839,981.665283 
	C587.104309,981.915344 586.329346,981.662354 586.041382,980.324280 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M429.715363,979.747620 
	C431.769470,980.064819 432.421082,977.715576 434.636475,978.014038 
	C437.844208,979.981445 441.498779,979.800293 444.725555,981.754944 
	C445.319031,982.429443 445.635590,982.866211 445.969543,983.580933 
	C445.986145,984.043945 445.985352,984.229004 445.990234,984.691406 
	C445.273804,984.995544 444.551697,985.022400 443.468567,985.069336 
	C442.555145,985.456787 441.965759,985.950623 441.318512,985.864197 
	C433.694550,984.845520 426.005371,985.333679 418.313080,984.216553 
	C410.834778,983.130493 403.398529,980.017822 395.176819,981.901550 
	C391.973175,981.278625 389.587708,979.561279 386.472473,978.741821 
	C383.807251,976.495117 381.531403,974.373169 379.595673,971.879517 
	C376.481140,967.867432 370.316345,966.455078 365.505493,968.519470 
	C363.367859,969.436646 361.655121,968.695801 360.128998,967.216370 
	C358.836609,965.963501 357.749786,964.433716 355.435516,964.064880 
	C354.205963,963.655701 353.333984,963.297302 352.196472,962.678284 
	C348.694092,957.137573 346.199249,951.364685 342.019958,946.266541 
	C341.545410,945.992859 341.359436,945.878418 340.915131,945.566101 
	C339.773376,944.499329 339.090485,943.337463 337.307526,943.236328 
	C336.047302,942.945190 335.198029,942.567383 334.304871,941.554077 
	C333.608643,940.342407 333.263214,939.381836 332.882324,938.015015 
	C332.449005,934.638000 333.150238,931.797668 334.226929,928.636108 
	C334.604370,928.106506 334.791199,927.924744 335.351990,927.646484 
	C341.857025,929.674561 346.659149,934.418457 352.955139,936.263855 
	C354.229462,936.637329 354.950165,937.974792 355.377777,939.298218 
	C355.582611,939.932190 355.662628,940.677490 356.027344,941.178162 
	C360.255676,946.982544 365.001862,952.369446 369.640259,957.840881 
	C371.089020,959.549866 372.887604,959.645203 375.076050,958.994263 
	C380.335785,957.429993 382.563843,958.609131 383.885132,963.782043 
	C384.454102,966.009583 385.073914,966.669250 387.525970,966.433350 
	C393.117401,965.895264 398.712219,967.033691 404.337494,966.986694 
	C406.873047,966.965515 407.807617,969.199829 408.446350,971.396484 
	C409.595612,975.348938 411.690765,977.310242 416.385590,977.143982 
	C420.718445,976.990601 424.863434,979.744385 429.715363,979.747620 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M453.724609,984.731628 
	C455.489349,986.644043 457.467041,985.755493 459.632507,986.023743 
	C460.014740,986.751892 459.989258,987.463745 459.939728,988.528564 
	C457.583801,988.906982 455.251923,988.932495 452.480652,988.954224 
	C451.128815,988.153381 450.294922,987.275452 449.235107,986.196777 
	C448.707092,985.490967 448.381805,985.009705 448.032013,984.224243 
	C448.002777,983.493164 447.998108,983.066284 447.996094,982.318481 
	C451.083496,980.016785 451.967010,982.901733 453.724609,984.731628 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M449.058838,986.315796 
	C450.531158,986.079041 451.572876,986.760376 451.991699,988.618774 
	C450.459747,988.955933 449.421295,988.260803 449.058838,986.315796 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M593.992249,989.442261 
	C594.811707,989.098145 595.544434,989.088684 596.644531,989.081970 
	C597.596985,989.019653 598.134583,988.833008 598.832153,988.291016 
	C599.490356,988.084167 599.918274,988.052185 600.667114,987.995361 
	C601.283691,987.668030 601.634583,987.457703 602.352051,987.333252 
	C603.274475,987.434265 603.789490,987.818604 604.704102,987.931641 
	C605.738647,987.909973 606.468567,987.885986 607.561707,987.825073 
	C608.389771,987.509460 608.890747,987.407227 609.781128,987.654846 
	C610.917053,989.389282 610.293640,990.325500 608.391968,990.903076 
	C607.552856,990.928162 607.108337,990.919983 606.330444,990.915283 
	C600.602783,992.764893 594.865356,993.574463 589.784058,996.380615 
	C588.131836,997.293091 586.324341,997.233276 584.216431,996.378662 
	C583.467529,996.155334 583.055542,996.127197 582.334534,996.111084 
	C579.654785,993.655884 577.210754,994.201660 574.394409,996.001587 
	C572.462524,993.699463 574.088379,992.179016 575.751770,990.320435 
	C576.028931,990.044800 576.468201,989.979980 576.686157,989.942444 
	C577.835815,990.160645 578.937195,990.180420 579.672485,990.709229 
	C585.885254,995.177246 588.154968,995.060913 593.992249,989.442261 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M572.222900,1000.765625 
	C572.007812,1001.009644 571.553406,1001.058044 571.327026,1001.082397 
	C565.756470,999.266479 560.228455,1000.135681 554.379150,999.994446 
	C556.959229,995.657349 560.327148,994.671509 564.616394,997.164612 
	C566.658203,998.351440 568.631714,999.312988 571.699768,999.088074 
	C572.515137,999.669739 572.549438,1000.090881 572.222900,1000.765625 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M575.675293,990.066284 
	C575.380066,991.809814 574.731201,993.574890 574.066895,995.698730 
	C574.029846,996.484253 574.008301,996.910889 573.966797,997.657715 
	C573.591187,998.515930 573.238831,999.057495 572.288818,999.137085 
	C571.997559,998.997559 571.922485,998.927551 571.881958,998.892090 
	C570.493835,994.938416 569.953918,991.340698 575.675293,990.066284 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M571.993835,999.332886 
	C572.481079,998.734619 572.964539,998.471619 573.697449,998.093262 
	C573.959167,999.135254 574.161804,1000.374390 572.350464,1000.952393 
	C572.001953,1000.562500 571.996033,1000.115417 571.993835,999.332886 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M354.776489,963.248535 
	C357.594757,962.667725 359.209930,963.743042 360.734344,965.927124 
	C362.189423,968.011658 364.538177,969.966370 366.049316,965.653870 
	C366.684113,963.842224 367.978943,963.697632 369.069611,964.801697 
	C371.023865,966.779602 373.298309,967.194641 375.872559,967.036133 
	C377.118469,966.959412 378.145996,967.549744 378.904694,968.484558 
	C381.271118,971.400391 383.591370,974.353699 385.731873,977.901855 
	C383.686859,979.570923 381.867798,978.613159 379.705017,978.424744 
	C377.653595,978.437256 375.996094,978.730286 374.211487,979.732605 
	C372.546143,980.917175 371.091400,979.904663 369.036987,979.810608 
	C366.541809,977.559814 366.354309,974.471619 363.830200,972.520508 
	C362.850250,972.298767 362.200195,972.128845 361.220398,971.864502 
	C358.093475,970.796936 356.086182,968.972534 354.755524,965.924011 
	C354.521210,965.380249 354.450836,965.152466 354.364410,964.566528 
	C354.411987,963.978088 354.475616,963.747742 354.776489,963.248535 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M393.630310,980.993896 
	C398.826691,980.018616 403.674561,978.610718 408.272888,981.779480 
	C408.400909,981.867737 408.609192,981.937561 408.747284,981.898926 
	C416.875977,979.624146 424.367371,986.320251 432.711395,983.436462 
	C435.671082,982.413635 438.787903,985.856140 442.674042,985.055176 
	C442.918793,986.453369 442.061157,987.019165 440.415161,987.077881 
	C439.335510,987.606567 438.550293,987.798340 437.329895,987.256226 
	C427.362213,987.529602 417.822357,984.851562 408.186951,986.351257 
	C406.782715,986.569824 406.092651,985.541931 405.256439,984.782837 
	C402.836761,982.586670 400.584442,981.621704 397.473450,984.063538 
	C394.989624,986.012878 392.487366,984.192505 390.085144,982.362244 
	C390.732117,980.805542 392.013916,980.960266 393.630310,980.993896 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M354.644745,963.773376 
	C355.000000,964.000000 355.007599,964.498047 355.010864,964.747070 
	C356.642242,967.115662 355.874115,971.352600 360.654907,970.991089 
	C361.748627,970.994263 362.498291,970.996765 363.622742,971.000854 
	C366.479706,973.294861 365.701843,977.326416 368.754425,979.690186 
	C369.005554,979.931824 369.014923,979.966919 369.007446,979.983459 
	C368.734100,980.490112 368.468231,980.980286 368.092957,981.727539 
	C367.457550,982.670532 366.721344,982.993896 365.513123,983.238403 
	C360.663361,982.851746 356.657806,981.635071 354.494080,977.201965 
	C353.859711,975.902283 352.575775,975.426758 351.088013,974.560852 
	C349.418030,972.792358 348.960876,970.948914 348.942200,968.548096 
	C349.858398,965.566772 351.204773,963.549988 354.644745,963.773376 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M393.785248,980.714600 
	C393.149445,982.199280 391.850128,981.949280 390.271606,982.038330 
	C388.823730,981.726257 387.752686,981.394043 386.347839,980.993652 
	C385.416687,980.267151 385.278564,979.510803 385.765808,978.336548 
	C385.998779,978.002441 386.002960,977.996826 386.009399,977.990356 
	C388.934937,977.565857 391.403351,978.534729 393.785248,980.714600 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M374.372070,980.018066 
	C374.437744,977.551941 376.337372,977.271729 378.623352,977.018188 
	C380.093811,978.618347 383.229004,977.481445 383.228699,980.613953 
	C380.233765,981.026428 377.426086,981.331360 374.372070,980.018066 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M364.799194,982.362305 
	C365.804749,982.086365 366.531250,982.053223 367.620667,982.002441 
	C368.447906,981.999695 368.912323,982.014587 369.724792,982.062378 
	C369.078278,984.515503 367.547729,986.179688 364.283875,985.894165 
	C362.998596,984.563599 363.551453,983.545166 364.799194,982.362305 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M383.394592,980.946289 
	C383.014069,978.294250 379.650269,979.705322 379.058533,977.352661 
	C381.058716,977.341064 383.129944,977.669922 385.599976,978.000610 
	C386.001953,978.734192 386.005096,979.465881 386.011139,980.561584 
	C385.265411,980.939514 384.516785,980.953552 383.394592,980.946289 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M437.036133,987.352661 
	C437.749756,987.027527 438.523163,987.027527 439.683136,987.063416 
	C439.422577,989.060730 438.534363,989.783386 437.036133,987.352661 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M99.734009,794.296997 
	C100.160431,791.170349 98.029526,788.496338 99.566788,785.269775 
	C102.662804,784.772339 101.577606,787.578979 102.791946,789.047424 
	C103.541466,791.635010 103.184639,793.963684 103.407776,796.245911 
	C103.549110,797.691528 102.549728,800.069763 105.075905,800.142517 
	C107.179527,800.203125 109.414581,799.451111 110.003006,796.875427 
	C110.366737,795.283325 110.292847,793.542969 112.348495,792.501099 
	C117.105446,793.776245 117.765404,794.973450 115.656631,798.501465 
	C114.402931,800.598938 113.411568,802.928467 111.168098,804.349182 
	C109.662117,805.302917 110.357498,806.245361 111.836227,807.158997 
	C113.470924,808.549500 114.868752,809.697937 116.266068,811.317017 
	C117.263222,812.798767 116.974823,814.454285 118.308990,815.738159 
	C118.731834,816.042236 118.899773,816.169189 119.311783,816.502380 
	C120.188057,817.310608 120.776039,817.953003 121.493767,818.926880 
	C122.044296,820.080444 122.097443,820.985229 121.961807,822.248413 
	C121.694290,823.485474 121.352211,824.334778 120.779938,825.457397 
	C120.092125,826.346069 119.462959,826.408813 118.477600,826.001526 
	C118.032219,825.685547 117.873695,825.535950 117.528282,825.123901 
	C116.939590,823.873718 116.240746,823.121216 115.205536,822.248169 
	C114.397209,821.541992 113.817917,821.041443 113.085838,820.293945 
	C113.304031,818.965393 113.577805,817.836914 114.070320,816.813782 
	C114.700233,815.505127 114.247253,814.628967 113.240967,813.833862 
	C112.224640,813.030945 111.244553,812.977722 110.072952,813.638977 
	C104.823158,816.601807 105.126808,816.481873 103.568611,811.316467 
	C103.045334,809.581848 102.618340,807.072327 100.520569,806.053162 
	C98.987946,805.308655 99.045723,804.051025 98.937744,802.350952 
	C98.616707,801.588318 98.420578,801.174683 98.294266,800.371399 
	C98.334221,799.325562 98.556381,798.655090 98.619232,797.604858 
	C98.747856,796.301208 98.922913,795.370972 99.734009,794.296997 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M115.475098,846.166138 
	C113.615974,845.390991 112.771538,844.181030 113.048759,842.572876 
	C113.703812,838.772766 112.375618,835.601257 110.109612,832.293091 
	C110.273270,830.876648 110.590935,829.780884 110.946411,828.342529 
	C111.016159,827.405884 111.209991,826.875671 111.823837,826.235596 
	C112.824661,825.770569 113.607666,825.495239 114.678902,825.090149 
	C115.669533,824.418396 116.445236,824.253967 117.637848,824.752319 
	C117.973686,824.997559 118.046280,825.018066 118.079903,825.035156 
	C118.760376,825.995911 118.676178,827.037415 118.531525,828.408203 
	C118.808708,829.973633 119.833641,830.416748 121.135536,831.057068 
	C122.258751,831.785339 122.735657,832.546082 122.771240,833.898804 
	C122.664139,834.471069 122.586029,834.692383 122.302109,835.198975 
	C121.583458,835.969482 120.985146,836.327271 120.053543,836.802246 
	C117.677841,837.605896 117.347717,839.433350 116.745781,841.464661 
	C114.370621,842.883179 117.134888,844.095398 116.429459,845.566162 
	C116.137596,846.023987 115.960854,846.165222 115.475098,846.166138 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M118.095108,824.713562 
	C117.222557,824.995178 116.471436,824.992737 115.343719,824.975342 
	C114.971222,824.230713 114.975311,823.501038 114.977959,822.406982 
	C116.553810,822.163269 118.699142,821.512878 118.095108,824.713562 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M111.749290,826.062378 
	C111.851601,826.587769 111.661522,827.129700 111.227829,827.835815 
	C109.932411,827.062073 110.212128,826.451721 111.749290,826.062378 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M98.993988,799.711182 
	C98.963127,800.431152 98.961967,800.867249 98.935730,801.628723 
	C98.492088,801.969299 98.073540,801.984436 97.340622,802.004272 
	C95.869270,801.581238 94.626534,800.539795 93.951775,802.653931 
	C93.280762,804.756348 91.455383,805.864563 89.882248,804.463684 
	C87.842361,802.647278 90.167099,801.307617 91.474899,800.335876 
	C93.371346,798.926819 93.159203,797.397766 92.138245,795.301331 
	C94.198563,793.022644 96.105888,789.066711 99.632065,793.955200 
	C99.937668,795.062500 99.915352,796.105042 99.857018,797.537354 
	C99.511185,798.403809 99.155495,798.856079 98.993988,799.711182 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M81.869080,780.340332 
	C83.477386,779.988892 84.967506,780.125061 86.310104,779.337158 
	C90.600449,776.819519 94.716629,777.905884 99.150543,779.961243 
	C99.745277,779.820984 99.976799,779.658386 100.611649,779.626099 
	C101.381577,780.215637 101.543877,780.738037 101.336494,781.711365 
	C100.916962,782.212280 100.661156,782.325928 100.043678,782.226318 
	C98.183525,781.877075 96.533058,780.240051 95.135803,782.743652 
	C93.618507,783.032837 92.238472,783.037781 90.444519,783.052490 
	C89.308327,783.799927 88.358284,784.160461 87.234650,784.808960 
	C86.692825,785.122559 86.436272,785.315002 86.121597,785.796936 
	C82.665161,785.760925 80.156792,784.842468 81.869080,780.340332 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M99.761230,782.378052 
	C99.875511,781.990601 100.069031,781.968140 100.552284,781.907593 
	C101.887970,782.085449 102.617424,782.783997 103.573486,783.806641 
	C104.874969,786.178101 105.367584,788.114807 102.242355,789.176636 
	C99.775139,788.427612 101.214035,786.363037 100.064819,785.112915 
	C99.820427,784.231262 99.830444,783.487183 99.761230,782.378052 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M87.353271,784.998108 
	C87.450211,783.933411 88.177071,783.205750 89.704674,783.059448 
	C89.893387,784.477356 89.016174,784.998718 87.353271,784.998108 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M135.508224,870.528931 
	C135.934860,871.820068 135.946243,872.537903 135.950043,873.614746 
	C133.708054,875.752502 130.554794,876.487793 129.242096,879.832397 
	C120.312027,879.137695 129.640320,875.211060 127.376373,872.981689 
	C121.653717,875.609314 122.960182,869.305237 120.383110,867.056702 
	C120.115326,864.982544 119.224953,863.251038 118.293732,861.232117 
	C118.016525,860.559143 118.005081,860.113647 117.996941,859.334106 
	C118.463142,856.708740 120.526260,855.310425 121.453331,852.881958 
	C122.157806,852.079468 122.759018,851.755005 123.819244,851.795227 
	C125.790306,853.587158 122.956581,856.594055 126.218063,858.068420 
	C127.094902,858.685425 127.505142,859.242249 127.777222,860.282288 
	C127.806320,860.862244 127.784622,861.094666 127.651184,861.657837 
	C126.988319,863.044189 125.467941,863.914856 127.075844,865.629272 
	C126.760353,868.454712 126.902794,870.413879 130.709625,869.225586 
	C132.492584,868.910583 133.860718,869.091797 135.508224,870.528931 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M121.958534,852.696777 
	C122.293999,855.756226 120.419220,857.211487 118.308151,858.854248 
	C115.560196,859.027100 112.932007,859.425537 111.517006,856.725769 
	C110.204765,854.222229 112.807037,852.964233 113.566566,851.127197 
	C113.989174,850.105103 114.878334,849.275879 115.849655,848.183472 
	C116.992203,847.491394 117.791389,847.827515 118.865219,848.313538 
	C119.798416,848.988220 120.348541,849.616089 121.142792,850.436279 
	C121.713135,851.184753 121.930305,851.754700 121.958534,852.696777 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M119.283859,849.139893 
	C118.145714,848.771423 117.409485,848.513550 116.407669,848.131409 
	C115.879265,847.518250 115.616470,847.029358 115.259880,846.283142 
	C115.166084,846.025818 115.627846,846.048950 115.858887,846.041260 
	C117.578102,844.580811 119.349510,844.051697 121.756592,844.608398 
	C123.143593,847.295349 121.587669,848.367737 119.283859,849.139893 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M128.969086,880.026917 
	C128.527054,875.454285 131.238785,874.100220 135.552368,873.980957 
	C136.762878,875.236877 138.706619,875.467712 139.007080,877.574341 
	C137.067963,877.919067 135.124298,878.082520 133.332275,879.839905 
	C132.013519,880.820251 130.926300,881.090820 129.338196,880.278320 
	C128.966858,880.033752 128.974411,880.018311 128.969086,880.026917 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M129.007965,880.365906 
	C130.047104,880.023315 131.127335,880.012878 132.612610,880.007080 
	C132.924881,881.187866 132.577652,882.350952 131.331360,882.605225 
	C129.998367,882.877014 129.372925,881.865479 129.007965,880.365906 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M119.195374,867.905701 
	C119.092934,867.710754 119.269699,867.465759 119.724442,867.173950 
	C119.833176,867.375122 119.657234,867.617920 119.195374,867.905701 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M99.270370,763.241394 
	C98.187355,762.465881 98.057343,761.607178 97.911713,760.412598 
	C97.491943,759.318237 97.246712,758.430969 97.571709,757.681091 
	C99.477051,753.284607 97.569153,750.468079 93.929855,748.180115 
	C91.444443,746.617432 90.596779,744.549316 92.649399,741.962708 
	C94.014107,740.243103 94.078835,737.900085 95.692490,735.917847 
	C97.041222,735.266296 97.889030,735.582031 98.718727,736.788330 
	C99.762581,738.041077 98.007828,740.263550 100.801300,740.325439 
	C101.760429,740.079590 102.413971,740.063599 103.387321,740.279541 
	C105.064369,741.161682 105.471161,742.858032 107.100868,743.825745 
	C107.551979,744.140259 107.734154,744.266479 108.185745,744.590210 
	C109.644913,745.704285 110.947975,745.313599 112.260315,745.179749 
	C114.748222,744.925842 116.641998,745.901794 116.859634,748.511475 
	C117.089470,751.267517 118.592377,752.593567 120.948814,753.472534 
	C122.563850,754.074890 124.575539,755.449158 123.607758,756.993225 
	C120.883507,761.339783 123.595139,764.769714 124.826096,768.870117 
	C125.003380,770.273865 124.840889,771.246338 123.840332,772.312073 
	C123.054443,772.749512 122.557045,772.861877 121.659462,772.853271 
	C120.545021,772.686218 119.846397,772.425842 118.804962,772.057861 
	C111.469757,769.827820 110.325058,766.848389 114.233551,760.594299 
	C114.852592,759.603699 115.237938,758.545654 114.274086,758.031128 
	C111.497231,756.548950 110.078827,753.468628 107.127502,751.776550 
	C106.169380,753.904541 106.962967,756.119019 105.385231,757.666138 
	C104.671944,758.365479 105.095413,759.235718 105.993355,759.612915 
	C108.156181,760.521423 109.265846,761.888184 108.055267,764.234009 
	C106.721970,766.817566 104.540306,767.539673 101.477936,766.725464 
	C99.974411,765.895020 100.155823,764.478027 99.270370,763.241394 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M100.760880,765.972778 
	C102.362885,765.917786 103.733574,766.075378 104.998100,765.779358 
	C106.637909,765.395569 107.436905,763.879028 107.525360,762.402832 
	C107.629311,760.668091 105.957031,761.125610 104.909988,760.983337 
	C103.670120,760.814697 102.623528,760.402039 102.275414,759.097595 
	C101.843315,757.478516 103.140488,757.254089 104.201668,756.766663 
	C105.673775,756.090637 106.124725,755.040466 105.075760,753.589600 
	C104.277222,752.485046 103.372849,751.141602 105.130676,750.289185 
	C106.489853,749.630066 108.193123,749.750061 109.311707,750.958679 
	C111.360512,753.172485 112.517258,756.069275 116.016922,757.046448 
	C117.501305,757.460938 117.834679,760.776978 115.884590,761.541382 
	C113.223969,762.584290 112.813591,763.877380 113.220802,766.384033 
	C113.736603,769.559021 115.968544,770.100586 118.736191,770.756592 
	C119.099586,773.765198 117.278481,775.603088 115.650475,777.299683 
	C112.094124,781.005859 107.176819,779.684387 105.467377,774.759705 
	C105.093590,773.682922 104.880890,772.533081 103.638062,771.553345 
	C103.068695,771.277222 102.841835,771.163574 102.104218,770.494873 
	C100.762566,769.556213 99.931686,769.172546 98.758408,768.737915 
	C97.793022,768.556274 97.530060,768.176453 97.927032,767.276733 
	C98.396431,767.027588 98.565941,767.048950 98.991142,767.052979 
	C99.542503,766.548584 99.917618,766.154785 100.760880,765.972778 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M95.653526,735.809570 
	C95.849274,738.579590 95.382523,741.047363 93.571991,742.843140 
	C91.547104,744.851440 92.768944,745.734863 94.402725,746.952820 
	C100.608543,751.579468 100.580597,751.616943 98.058685,759.740784 
	C92.841408,758.943237 96.190704,755.977112 96.895973,752.923279 
	C93.318146,754.894775 91.099419,754.123596 89.343376,751.021179 
	C88.196785,748.995544 86.208061,748.554688 83.767136,749.732605 
	C80.624161,751.249329 78.933029,749.756775 79.395630,746.259155 
	C79.566612,744.966431 80.353455,743.197327 79.791122,742.456726 
	C76.411026,738.005554 77.943886,735.790100 82.901215,734.374817 
	C84.241272,733.941040 85.437614,734.126160 86.825920,735.037476 
	C88.405235,736.404114 85.438774,738.614502 88.085648,739.388733 
	C91.049026,740.255554 91.588593,737.691650 92.931885,735.699707 
	C93.947395,735.208801 94.667847,735.235840 95.653526,735.809570 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M97.775284,767.007690 
	C98.256981,767.382446 98.287041,767.758667 98.366562,768.410889 
	C96.555229,769.205627 94.633492,768.839783 93.629822,771.671204 
	C92.724648,774.224670 90.954735,772.540649 89.954262,770.462646 
	C90.105881,769.036011 90.730568,768.202393 91.734528,767.272339 
	C93.778778,767.077942 95.551208,767.043640 97.775284,767.007690 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M82.841125,769.800781 
	C80.911766,775.862610 78.789696,769.636658 76.347687,770.032654 
	C76.403061,767.305237 78.542648,768.197937 80.523376,767.971313 
	C81.498604,768.491638 82.090607,769.019226 82.841125,769.800781 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M91.813812,766.847046 
	C91.971573,768.278870 92.064186,769.510010 90.226501,770.021973 
	C89.163033,770.090210 88.446495,770.074890 87.370270,770.056213 
	C87.006462,769.859924 87.002357,769.667053 86.994751,769.183716 
	C88.168205,767.388794 89.066673,765.326538 91.813812,766.847046 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M81.939713,762.616211 
	C81.193260,763.020874 80.462486,763.012817 79.365150,763.002441 
	C78.367104,762.360291 78.238426,761.594116 78.641129,760.395386 
	C79.790016,759.534485 79.974892,758.659912 79.990692,757.352173 
	C83.293526,757.741516 81.517120,760.389587 81.939713,762.616211 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M101.019928,765.615356 
	C100.619942,766.291809 100.200508,766.621765 99.513947,766.993652 
	C99.164345,765.952393 99.081856,764.869141 99.020599,763.377686 
	C100.468506,763.076721 101.002022,763.944824 101.019928,765.615356 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M78.962311,729.399658 
	C80.422966,729.045166 80.995132,729.894775 81.005081,731.637146 
	C79.954529,731.572571 79.232239,730.862244 78.962311,729.399658 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M81.253357,767.912109 
	C81.156082,767.386658 81.349426,766.851440 81.786484,766.154419 
	C83.048271,766.929688 82.799515,767.544189 81.253357,767.912109 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M60.192680,721.358521 
	C59.360859,717.481628 58.958385,713.924683 63.565796,711.984375 
	C62.756996,713.735840 63.063766,715.501221 63.999786,717.613953 
	C64.071526,718.663940 64.503265,718.795471 65.385132,718.718689 
	C66.581322,719.177979 66.709305,719.973145 66.229164,721.367615 
	C65.833511,721.873169 65.391266,721.884827 65.172577,721.921021 
	C63.328102,723.158203 61.835461,722.673889 60.192680,721.358521 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M72.484299,714.917236 
	C73.882439,715.573059 74.134705,716.549988 73.670090,717.503479 
	C72.094048,720.737671 73.367249,723.166809 75.834549,725.686157 
	C74.525658,726.118591 72.971687,725.943848 71.622070,726.388916 
	C66.159966,728.189758 66.183189,728.260193 65.892334,722.304810 
	C65.833511,721.873169 65.864258,721.900513 65.877823,721.893188 
	C67.959953,720.698364 69.945839,719.444031 70.228775,716.376404 
	C70.795265,715.423706 71.385849,715.038086 72.484299,714.917236 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M78.540161,760.039062 
	C78.896141,760.782227 78.941765,761.520569 78.992989,762.629395 
	C78.882645,766.002441 77.863297,767.007629 75.155113,764.366211 
	C74.976280,762.650452 75.006821,761.242737 76.679596,760.171021 
	C77.398674,760.016724 77.814232,760.025391 78.540161,760.039062 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M101.257820,780.182617 
	C100.642418,780.026489 100.417427,780.025635 99.853104,780.004272 
	C100.054794,778.629578 100.595810,777.275452 101.177841,775.560974 
	C101.548180,774.724304 101.998825,774.437988 102.985916,774.359558 
	C106.610825,777.587524 106.337128,778.516541 101.257820,780.182617 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M103.475052,774.674194 
	C102.750237,775.062988 102.309593,775.085510 101.543900,775.154297 
	C101.188217,773.968018 101.157585,772.735535 101.202484,771.049072 
	C101.278000,770.595154 101.584381,770.326233 101.560211,770.123901 
	C101.794403,769.923889 102.052773,769.926331 102.698273,769.947510 
	C104.149963,771.283081 104.398262,772.726685 103.475052,774.674194 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M101.546188,769.936462 
	C101.615715,770.103088 101.595528,770.272522 101.376740,770.512451 
	C101.278000,770.595154 101.375633,770.239319 101.484543,770.089600 
	C101.593452,769.939819 101.556335,769.951416 101.546188,769.936462 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M70.741776,715.970459 
	C70.961205,719.224854 71.764580,722.802368 66.316833,721.923706 
	C65.770790,721.049622 65.650185,720.213257 65.292236,719.159607 
	C64.903656,718.759277 64.752426,718.576355 64.894119,718.191223 
	C66.304390,718.006104 67.457039,718.097229 67.945518,716.368164 
	C68.721619,715.347961 69.552605,715.278870 70.741776,715.970459 
z"
    />
    <path
      fill="#2C2B2B"
      opacity="1.000000"
      stroke="none"
      d="
M71.252548,716.130920 
	C70.271156,716.240479 69.512627,716.172363 68.374268,716.072815 
	C67.137177,714.392639 65.955597,712.687073 68.979324,711.417236 
	C69.294769,710.985840 69.389641,710.816895 69.748482,710.769531 
	C70.860275,711.462158 71.708107,712.033142 72.799835,712.800049 
	C72.997894,713.435730 72.952065,713.875488 72.878418,714.645508 
	C72.392212,715.301697 71.933815,715.627502 71.252548,716.130920 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M64.902908,717.986084 
	C65.187050,717.988953 65.108696,718.464600 65.081787,718.703430 
	C64.365257,719.737915 63.511028,720.381104 62.651974,719.389038 
	C62.121506,718.776489 62.780617,718.284973 63.748474,718.018555 
	C64.240112,717.970154 64.429443,717.976624 64.902908,717.986084 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M70.007988,710.566833 
	C70.012421,710.891174 69.621002,711.069275 69.410454,711.112000 
	C68.161293,710.380005 67.938271,709.662170 69.641525,709.001343 
	C69.959068,709.377197 69.981308,709.809814 70.007988,710.566833 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M96.254242,736.090454 
	C95.185562,736.055481 94.443993,736.036804 93.330917,736.037109 
	C92.621597,735.967896 92.348198,735.781738 92.061028,735.265869 
	C91.909584,733.568787 90.972679,733.204773 89.698616,732.925598 
	C86.984680,732.330994 86.136917,730.915894 86.752144,727.876404 
	C87.802368,722.687988 86.241295,721.411743 80.935196,722.404663 
	C79.533821,722.666870 79.009216,722.140015 79.112381,720.966675 
	C79.196396,720.011169 79.358322,718.703247 79.995064,718.212524 
	C84.974503,714.375122 82.784531,708.834167 81.116501,705.513855 
	C78.791557,700.886108 80.185455,695.067017 75.944206,691.327881 
	C75.019646,690.512878 75.294495,689.610779 76.295128,688.867981 
	C77.666924,687.849670 79.255074,686.241943 78.337105,684.809875 
	C77.081078,682.850342 75.066154,684.778381 73.380875,685.040527 
	C72.417336,685.190430 71.387321,685.765015 70.529579,685.558167 
	C68.944916,685.176025 67.118980,684.526733 67.186157,682.457947 
	C67.248222,680.546692 68.512337,680.485840 70.319069,680.463013 
	C72.903824,680.430298 74.439690,678.932007 72.387955,676.031433 
	C71.038025,675.785767 70.592506,675.048462 70.307877,673.803894 
	C70.592110,671.976013 70.325577,670.588867 69.323227,669.022217 
	C69.050858,668.301270 68.950859,667.869385 68.862099,667.088379 
	C69.671471,663.251343 72.291290,659.954224 68.351265,656.312439 
	C66.218231,654.340881 64.023842,654.205566 61.332611,653.614136 
	C60.858322,653.296631 60.694412,653.135132 60.371395,652.670166 
	C59.632874,650.465637 60.453926,648.357117 59.189301,646.225830 
	C58.550713,644.833862 57.871971,643.779114 58.787766,642.265625 
	C61.757736,641.288757 64.255989,640.361084 63.472294,645.168396 
	C63.146095,647.169373 64.813560,649.324829 67.610855,648.857666 
	C70.409332,648.390320 69.660759,646.473633 69.042252,644.349731 
	C70.022552,644.071655 70.962334,644.357910 71.505173,645.285217 
	C71.912605,645.981262 71.988884,647.071838 72.562599,647.437378 
	C76.236198,649.778137 80.136856,651.818359 82.853447,656.035828 
	C83.833679,656.218323 84.159348,656.333252 84.741135,656.464233 
	C85.547050,656.433594 85.984871,656.127930 86.691505,655.707764 
	C87.336166,655.418091 87.739754,655.360413 88.474030,655.445679 
	C88.979691,655.655396 89.164848,655.784668 89.537498,656.203369 
	C89.899170,656.929504 90.022614,657.380310 90.131561,658.201660 
	C90.160858,658.798828 90.156967,659.038452 90.110977,659.635254 
	C88.827454,665.259644 85.247993,669.477234 84.598976,675.259583 
	C83.635315,683.845215 83.205040,691.868225 88.747414,699.103271 
	C89.687134,700.330017 89.673897,701.533020 89.041275,702.824829 
	C87.765396,705.430359 88.507736,708.180725 90.159760,709.836914 
	C94.905464,714.594360 94.147095,720.527527 94.182137,726.195740 
	C94.207146,730.241699 95.151688,732.191833 99.574043,731.552979 
	C102.505173,731.129456 105.385887,732.635864 108.431297,732.159729 
	C109.782425,731.948486 111.327377,732.948242 111.512650,734.257019 
	C111.974075,737.516602 114.861893,739.590515 115.171692,743.133423 
	C112.899338,745.337280 110.620377,746.617188 107.341454,745.220581 
	C106.993919,745.005615 107.000000,745.000000 106.997025,745.003052 
	C106.237053,743.750854 105.430237,742.531433 104.211433,741.226562 
	C103.239807,741.003357 102.492844,741.000244 101.372406,740.993652 
	C99.714645,740.567566 99.254684,739.320435 98.221039,738.214966 
	C97.905891,737.086304 97.647896,736.292725 96.254242,736.090454 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M71.134590,672.705200 
	C71.255867,673.799194 71.524818,674.596130 71.932251,675.682190 
	C68.581551,678.002991 64.408859,677.399414 60.389763,678.949646 
	C58.442223,677.162231 58.059792,674.949158 57.926888,672.240234 
	C57.889149,671.836243 57.940254,671.418640 57.983608,671.212708 
	C60.348587,670.578674 60.217415,669.133118 59.246017,667.219971 
	C59.010891,666.585022 59.033718,666.176147 59.080479,665.463013 
	C59.104412,665.158813 59.590698,665.079834 59.836151,665.059082 
	C60.810318,664.336914 62.119392,663.965515 61.330872,662.242859 
	C61.054287,661.554199 60.967033,661.131531 60.875771,660.384766 
	C61.058617,659.884583 61.245468,659.708435 61.773491,659.353027 
	C64.720657,658.651062 66.174431,660.008362 67.092567,662.230713 
	C67.723289,663.757446 68.168518,665.382996 69.659767,666.871399 
	C69.876038,667.561462 69.860680,667.951111 69.839676,668.633057 
	C69.379776,670.476013 70.990860,671.167725 71.134590,672.705200 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M91.586395,735.044067 
	C92.282043,735.170288 92.524467,735.376404 92.839249,735.847412 
	C93.468597,739.448914 92.633827,741.539856 88.573387,741.038147 
	C86.876801,740.828430 85.254532,740.895752 84.542587,738.933960 
	C83.703621,736.622131 86.678642,737.286804 87.039871,735.524719 
	C88.494164,735.164246 89.837585,735.115967 91.586395,735.044067 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M59.971909,691.674683 
	C59.089794,691.916626 58.300476,691.650696 58.082520,690.248779 
	C58.929909,690.079834 59.692818,690.376404 59.971909,691.674683 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M60.928200,682.732666 
	C60.022057,682.816956 58.930168,682.752991 59.822514,681.155823 
	C60.344509,681.471130 60.639133,681.953430 60.928200,682.732666 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M120.152512,216.813995 
	C119.052979,217.093658 118.419456,217.502335 117.910316,218.247452 
	C115.933647,221.140320 114.265076,221.817947 113.677223,217.456299 
	C113.340591,214.958618 110.990356,213.817566 110.029297,211.382980 
	C111.857986,210.800674 113.371323,209.951813 114.742973,208.210480 
	C115.741676,207.300995 116.834137,207.159821 117.750534,206.193054 
	C117.949623,205.965714 117.996803,205.986084 117.979965,205.966339 
	C118.179871,205.824753 118.396606,205.702927 118.983055,205.634842 
	C121.807457,207.164658 123.545631,209.105118 123.431435,212.491974 
	C122.914154,213.357712 122.372437,213.703217 121.518501,214.082474 
	C120.979912,214.404831 120.770073,214.667984 120.658661,215.280060 
	C120.647575,215.936600 120.550575,216.297134 120.152512,216.813995 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M115.280258,199.974030 
	C115.435593,197.502518 110.795937,196.557983 112.437424,194.872635 
	C114.993866,192.247864 113.260155,187.582886 117.608757,186.016663 
	C119.798683,186.905701 120.058670,188.853989 120.906937,190.889420 
	C121.094383,194.971954 123.878609,198.184738 122.851440,202.432388 
	C122.310616,203.499710 121.723839,203.974030 120.600449,204.265778 
	C118.723099,203.281036 118.788368,200.730789 116.242004,200.257172 
	C115.979889,200.030640 115.513306,199.994873 115.280258,199.974030 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M109.045090,210.514893 
	C107.209877,211.011612 106.141838,209.870804 105.300636,208.347275 
	C104.791130,207.424484 106.953522,205.410461 104.403252,205.513336 
	C102.847450,205.576080 101.280495,206.652252 100.626938,208.322388 
	C100.353226,209.021805 100.486618,209.880524 100.191238,210.836426 
	C98.585251,208.447021 97.810913,205.919174 101.459747,204.686600 
	C103.712158,203.925720 102.009872,199.337830 105.111938,200.438492 
	C108.105522,201.500671 107.664642,205.075623 108.133545,207.797745 
	C108.272049,208.601791 108.676880,209.359940 109.045090,210.514893 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M116.048431,200.378784 
	C118.317673,199.833878 120.314926,200.067490 120.010681,203.613235 
	C120.080330,204.640015 119.865135,205.179214 119.228455,205.823135 
	C118.806610,205.978577 118.638000,205.974976 118.216263,205.958984 
	C118.436699,203.821548 117.275818,202.274612 116.048431,200.378784 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M117.634140,206.004395 
	C117.928925,207.440674 117.077026,208.002731 115.360672,208.008636 
	C115.438728,206.959641 116.175262,206.263031 117.634140,206.004395 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M96.321938,280.249146 
	C96.002258,279.831879 95.993500,279.639252 95.959045,279.157471 
	C95.934143,278.467010 95.934937,278.065735 95.914307,277.364319 
	C95.874077,275.013733 94.893562,273.836853 92.805206,274.245758 
	C91.278198,274.544739 89.507477,275.258972 89.477921,272.965027 
	C89.454803,271.170990 91.080917,269.813721 92.914383,270.263580 
	C96.171394,271.062714 97.442459,270.308685 96.481522,266.894684 
	C96.393364,266.581451 96.220825,266.052948 96.347282,265.952942 
	C100.327255,262.805206 99.185669,257.459015 101.836914,253.352264 
	C103.395828,251.624771 105.083389,250.570374 106.804642,251.962372 
	C108.412025,253.262283 107.079926,255.011078 106.662132,256.542175 
	C106.318779,257.800507 105.531586,258.950775 105.732048,260.344788 
	C110.710892,259.982391 114.672554,254.530807 113.000900,250.354187 
	C111.543015,246.711685 113.631676,244.543686 116.476219,243.268188 
	C119.200409,242.046677 119.673157,240.365189 119.081741,237.758957 
	C118.265213,234.160690 118.688637,230.524231 119.052757,226.561920 
	C119.021919,225.761658 119.046371,225.306335 119.179520,224.522705 
	C120.186470,222.820908 119.930000,221.222595 120.169655,219.383575 
	C120.271271,218.745773 120.411095,218.485535 120.840851,218.155396 
	C121.169434,217.856018 121.309219,217.680939 121.671242,217.252945 
	C123.354607,215.832443 123.594818,213.761353 125.476486,212.560287 
	C127.442230,212.630295 127.998856,213.833038 128.395279,215.126419 
	C130.622894,222.394150 130.287918,229.225494 124.769333,235.067505 
	C123.728630,236.169189 123.416664,237.255051 123.979828,238.634552 
	C124.604446,240.164612 125.222214,241.699692 125.114410,243.777832 
	C124.900558,245.568069 124.159492,246.935562 124.774750,248.775955 
	C124.857307,249.621292 124.834267,250.104080 124.652695,250.929657 
	C122.810257,254.042465 121.550667,257.230133 118.176468,258.569763 
	C118.184669,265.912567 109.554489,267.342285 107.766953,273.896118 
	C104.735023,277.685577 100.869713,279.406708 96.321938,280.249146 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M119.550423,225.960876 
	C120.333160,231.175354 119.643265,236.395645 120.909126,241.521790 
	C121.295982,243.088394 120.069839,243.614700 118.562317,244.044739 
	C113.226288,245.566940 112.737000,247.146729 116.133232,251.152451 
	C116.738365,251.866196 117.245399,252.984833 116.643982,253.312378 
	C112.529892,255.552963 112.073380,261.620422 106.796654,262.596069 
	C105.638817,262.810150 104.451302,263.347809 103.548340,262.303162 
	C102.761436,261.392792 103.490021,260.508209 103.906120,259.585510 
	C104.901054,257.379181 105.734886,255.100204 106.635666,252.845795 
	C105.178841,251.786621 104.004684,253.291153 102.380569,253.015839 
	C102.021980,252.860062 102.017509,252.689957 102.009499,252.264618 
	C102.003799,249.851440 102.323288,247.676666 101.621475,245.219238 
	C100.941162,242.162842 100.535019,239.572144 104.391335,238.946182 
	C105.252640,238.806381 105.744270,238.080704 106.068359,237.295822 
	C106.762314,235.615204 104.295639,229.064713 102.178413,227.514252 
	C101.608665,226.917831 101.434998,226.484955 101.430565,225.652496 
	C102.432144,223.618378 104.110901,222.704788 105.764114,221.256790 
	C106.318665,220.884216 106.568169,220.685272 106.881226,220.208649 
	C109.096497,218.627258 110.245804,220.149307 111.729965,221.733551 
	C112.301407,222.129944 112.551636,222.326111 112.866798,222.801025 
	C115.194786,225.574554 113.682281,228.611206 113.839745,231.953339 
	C117.349571,231.052444 116.571411,226.876862 119.550423,225.960876 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M119.903305,225.683548 
	C118.226959,227.391418 118.347305,229.496826 117.643097,231.271088 
	C116.876457,233.202576 115.415680,234.495392 113.001091,233.460236 
	C110.568748,232.417450 111.917969,231.200653 112.455185,229.625183 
	C113.060898,227.848801 112.848892,225.793564 112.989403,223.434906 
	C114.699585,224.331299 116.381287,223.200836 118.412987,222.916992 
	C119.175026,222.902618 119.506561,223.089020 119.822983,223.718414 
	C119.896538,224.458633 119.906654,224.904037 119.903305,225.683548 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M111.998749,221.626892 
	C110.207550,222.153717 109.291580,220.337479 107.338921,220.042023 
	C107.165703,218.342316 107.844261,216.853302 109.707138,217.273956 
	C111.579529,217.696762 111.814117,219.578171 111.998749,221.626892 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M122.268143,217.765182 
	C121.870491,218.038544 121.702187,218.047760 121.281769,218.044037 
	C120.817238,217.759003 120.604836,217.486877 120.156143,217.101227 
	C119.926605,216.557281 119.933342,216.126907 119.936035,215.373505 
	C119.588249,214.643585 119.472557,214.179794 119.835625,213.379730 
	C120.782639,212.996979 121.478912,212.893616 122.512253,212.687485 
	C123.039948,212.723190 123.230591,212.861633 123.582878,213.314682 
	C124.142982,215.182098 123.264275,216.323547 122.268143,217.765182 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M105.641464,221.011902 
	C105.838142,223.079498 104.599869,224.264969 102.423660,224.999176 
	C101.671608,224.665604 101.456322,224.194473 101.553406,223.245758 
	C102.018906,222.407486 102.372040,221.955414 102.871902,221.257095 
	C103.767891,221.009430 104.517136,221.008026 105.641464,221.011902 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M120.167427,223.796600 
	C119.615082,223.785568 119.343742,223.557892 118.912056,223.109802 
	C119.030441,221.726822 119.309174,220.564255 119.910248,219.231018 
	C121.439445,220.512680 121.185516,222.034775 120.167427,223.796600 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M103.190437,219.836029 
	C102.666557,219.514633 102.372559,219.028473 102.086365,218.242554 
	C102.998070,218.164597 104.096031,218.237503 103.190437,219.836029 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M102.003014,217.607330 
	C101.113953,217.908630 100.326225,217.684219 100.147598,216.258667 
	C101.002815,216.063034 101.695610,216.398315 102.003014,217.607330 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M120.487007,190.977768 
	C118.670288,189.879547 118.757729,188.089371 118.000664,186.309891 
	C118.159691,185.441910 118.383133,184.925598 118.837036,184.240448 
	C119.485840,183.642456 119.985756,183.503632 120.918266,183.874390 
	C122.412956,185.539124 122.396431,187.199219 122.064331,189.301208 
	C121.723419,190.229034 121.374519,190.653168 120.487007,190.977768 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M119.913002,179.379822 
	C120.348572,178.698578 120.822403,178.339813 121.596222,177.929657 
	C122.875244,178.772476 122.856834,179.775208 121.883255,181.085815 
	C121.273033,181.347549 120.998665,181.368790 120.360199,181.195587 
	C119.981140,180.568176 119.966202,180.135239 119.913002,179.379822 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M119.739441,181.219070 
	C120.177589,180.994843 120.359100,180.988541 120.812927,180.974136 
	C121.542877,181.398987 121.784294,181.933121 121.804260,182.860977 
	C121.706856,183.353058 121.635918,183.542725 121.361725,183.958099 
	C120.693962,184.146042 120.229446,184.108261 119.416214,184.071060 
	C119.205925,183.193420 119.344360,182.315216 119.739441,181.219070 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M99.328934,251.995758 
	C96.470757,252.652802 93.751312,253.914734 93.783104,249.232330 
	C93.794891,247.495987 91.963959,246.412811 90.626060,248.048996 
	C87.965775,251.302353 84.158699,251.004974 80.323761,251.899719 
	C80.175186,249.866531 81.394463,249.010193 83.687241,248.968781 
	C86.025208,247.204529 87.985245,245.448975 90.682693,244.117035 
	C93.477219,244.056519 95.306061,244.190781 95.744789,240.509232 
	C96.050743,237.941788 97.161873,234.847046 99.693710,232.622070 
	C103.205528,233.965302 102.355469,236.581329 101.469482,238.701508 
	C100.524879,240.961960 100.915268,242.721832 102.272186,244.920868 
	C102.557655,247.358871 102.944656,249.480698 101.128052,251.544556 
	C100.425926,251.849091 100.026566,251.899475 99.328934,251.995758 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M79.812004,236.268677 
	C80.263840,235.843353 80.524620,235.648895 80.841934,235.156219 
	C87.469078,232.225586 90.820610,234.210114 89.973160,240.741882 
	C87.933563,243.321106 85.261795,243.519745 82.306244,243.956512 
	C78.228088,247.869339 74.429070,243.800262 70.536133,244.312637 
	C74.936356,243.082504 77.084236,239.562576 79.812004,236.268677 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M81.946274,244.296936 
	C83.208252,241.082901 86.372612,242.645126 88.606239,241.076141 
	C89.952423,240.277466 91.046585,240.026123 91.931252,240.985962 
	C92.997490,242.142792 92.466515,243.287460 91.261841,244.630280 
	C88.645035,245.906342 87.723053,249.012970 84.400467,248.941864 
	C83.999710,247.201599 81.657059,246.574600 81.946274,244.296936 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M78.334747,253.860199 
	C78.758621,254.636169 78.853912,255.449783 78.913467,256.672546 
	C78.163315,257.052185 77.448891,257.022736 76.376816,256.963806 
	C76.013199,256.198120 76.007248,255.461945 75.997787,254.356216 
	C76.664902,253.957062 77.335526,253.927444 78.334747,253.860199 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M91.329514,244.968994 
	C90.782181,243.533966 93.305908,241.231827 89.286163,240.950134 
	C89.946091,235.812149 87.597046,233.876205 81.333611,234.948837 
	C80.969734,234.510010 80.980751,234.081223 81.000145,233.330505 
	C83.120735,232.659470 85.599701,232.893875 87.270966,231.843323 
	C91.084908,229.445877 95.443817,232.299332 99.745300,230.219254 
	C100.771072,231.018143 100.834755,231.716248 100.406036,232.732590 
	C98.689552,237.271759 95.705185,241.115875 96.981537,246.239395 
	C94.811928,246.475266 93.534599,244.432663 91.329514,244.968994 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M101.386665,222.893311 
	C101.761887,223.344696 101.858009,223.829834 102.020538,224.680145 
	C102.080109,225.466278 102.073288,225.887253 102.088882,226.623749 
	C102.880135,228.410492 103.034081,229.698074 100.680893,230.217712 
	C100.271828,230.076401 100.022827,230.028183 99.897644,229.996933 
	C99.841545,227.934509 99.910629,225.903351 99.978195,223.435730 
	C100.353638,222.975220 100.730606,222.951141 101.386665,222.893311 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M133.394867,123.298958 
	C133.975159,124.253624 134.241547,125.961845 135.039749,125.692513 
	C139.473831,124.196327 142.734955,128.024048 146.763474,127.985649 
	C149.367920,127.960823 151.218765,128.867905 151.949646,131.920227 
	C151.935867,132.815567 151.704758,133.200806 150.901459,133.522827 
	C148.802521,133.922073 146.957550,131.603516 145.426590,133.395584 
	C143.832031,135.262131 142.451019,137.359268 141.103714,139.422760 
	C140.391312,140.513840 141.979675,140.909378 142.697250,141.477783 
	C144.915253,143.234772 145.274231,145.294052 143.512680,147.522263 
	C141.193878,150.455383 142.281433,155.053513 138.506577,157.374100 
	C137.769440,157.827225 138.631607,159.539948 139.510269,160.834290 
	C140.454498,164.291428 138.301544,165.710114 135.879974,167.332108 
	C133.074677,168.696350 130.129837,167.225082 127.376953,168.896240 
	C125.141861,167.700027 122.158775,158.767792 123.301491,158.525101 
	C128.864822,157.343613 128.647827,153.526062 128.410599,149.417664 
	C128.404465,149.311874 128.976730,149.172699 129.624756,149.007156 
	C131.347092,149.715164 131.522720,152.751801 134.053833,151.247803 
	C135.926727,150.134949 134.953705,148.793564 133.990082,147.212067 
	C134.171570,146.085144 134.542480,145.316605 134.674728,144.508987 
	C134.904694,143.104691 136.002426,141.329773 134.804184,140.348526 
	C133.485733,139.268829 132.140625,140.656464 130.880829,141.530090 
	C127.273735,144.031555 126.530960,143.582214 126.053925,138.470535 
	C126.060226,138.069092 126.045471,138.051804 126.054390,138.052841 
	C127.115311,136.738541 128.114655,135.370010 129.907364,134.372284 
	C130.868866,134.133850 131.553436,134.046326 132.581207,133.922821 
	C133.656586,133.193253 134.685089,132.993454 135.708099,132.185562 
	C134.799652,130.506409 132.899628,130.155914 131.541245,129.147812 
	C129.799576,127.855263 128.439713,126.478874 130.702667,124.160072 
	C131.713623,123.756401 132.418488,123.506203 133.394867,123.298958 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M134.796448,167.241425 
	C135.902435,164.929474 139.298828,164.721664 138.971786,161.381897 
	C137.042404,157.214279 139.873138,155.898254 142.810028,154.423401 
	C143.436050,154.491348 143.676071,154.571548 144.168182,154.929291 
	C145.118576,158.520050 143.927612,161.649796 142.381302,164.227844 
	C139.801285,168.529312 139.268829,172.490738 141.864563,176.822601 
	C142.198471,177.379837 142.120834,178.114853 142.064880,179.153412 
	C141.534439,181.285049 140.292969,181.986450 138.179413,181.967712 
	C137.302902,181.810043 136.835876,181.626114 136.093857,181.168671 
	C135.052505,180.380951 134.441101,179.661392 133.473114,178.835648 
	C132.804749,178.356186 132.080124,178.003143 131.916641,178.264954 
	C130.216858,180.987228 127.110413,182.840652 126.870972,186.776581 
	C127.027481,187.843445 127.012909,188.560196 126.822792,189.631958 
	C126.369377,190.927170 125.812431,191.716171 124.595245,192.375656 
	C122.829155,192.510513 122.015984,191.705093 121.340073,190.163132 
	C121.161438,188.221725 121.151024,186.600418 121.149544,184.581467 
	C121.158478,184.183807 121.216881,184.175156 121.242722,184.160858 
	C122.875740,182.329315 123.086220,180.115799 123.116470,177.495422 
	C123.280411,176.951813 123.387108,176.747711 123.763512,176.327698 
	C125.163467,176.005890 126.382942,176.124023 127.199203,174.583649 
	C127.917992,173.279999 127.932037,172.091034 128.685120,170.834320 
	C130.826187,169.604904 132.994980,169.082245 134.796448,167.241425 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M133.591583,146.934479 
	C134.761093,148.233459 138.365219,147.122726 137.218643,149.900757 
	C136.482941,151.683334 134.831680,153.778732 132.482773,153.781418 
	C130.009476,153.784256 130.028442,151.605347 129.985901,149.358063 
	C131.071869,148.309372 132.173462,147.653519 133.591583,146.934479 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M134.642929,167.012985 
	C134.748367,170.142456 132.949249,171.367096 129.558350,171.107422 
	C128.517944,171.147415 127.960411,170.930191 127.324272,170.196167 
	C127.113266,169.785889 127.096222,169.618546 127.061249,169.198944 
	C128.361328,164.277588 131.769455,167.362579 134.642929,167.012985 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M123.746559,176.807800 
	C123.304146,179.355331 125.672729,182.585831 121.640137,184.095871 
	C121.189262,183.352386 121.109970,182.558182 121.057953,181.365021 
	C121.950912,180.376572 122.005653,179.489029 121.931580,178.222717 
	C121.896202,177.878281 121.912247,177.421280 121.928391,177.193207 
	C122.376595,176.602875 122.870132,176.471359 123.746559,176.807800 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M127.086739,170.382782 
	C127.684914,170.180420 128.239532,170.407623 128.983032,170.885254 
	C129.040421,172.218185 129.178787,173.416000 127.633553,174.144470 
	C127.233971,173.113770 127.138573,171.963058 127.086739,170.382782 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M124.072266,176.811203 
	C123.596245,177.027603 123.124535,177.010223 122.298676,176.978989 
	C122.158630,176.018448 122.257988,174.920578 123.876480,175.864777 
	C124.033211,176.111801 124.076576,176.577423 124.072266,176.811203 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M130.755737,123.897774 
	C129.695740,125.988075 130.442749,127.444298 132.261612,128.510254 
	C132.956696,128.917618 133.834717,128.998871 134.580170,129.337143 
	C136.230820,130.086197 138.680191,130.901779 138.377747,132.733521 
	C137.976303,135.164978 135.348083,133.563797 133.334534,133.926926 
	C133.075699,132.327530 132.825867,130.876144 131.243179,130.183762 
	C128.034256,128.779968 127.946648,126.232178 128.806015,123.198090 
	C129.531357,123.281624 130.017044,123.535286 130.755737,123.897774 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M129.794281,134.159760 
	C129.686584,136.073303 128.572327,137.356430 126.396057,138.010315 
	C125.845734,135.610977 126.592880,133.982880 129.794281,134.159760 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M229.716125,91.799026 
	C229.794861,94.245354 227.149139,94.636345 226.341934,96.810043 
	C225.138748,96.484367 225.010300,95.568680 224.962296,94.249611 
	C225.062897,93.596230 225.249435,93.345596 225.703278,93.022346 
	C226.903748,92.018059 227.893982,91.117760 229.716125,91.799026 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M227.033752,85.368530 
	C228.729630,82.175217 231.105804,83.731865 233.657196,84.031456 
	C234.906982,85.279938 234.373535,86.183151 233.221771,86.686516 
	C231.825394,87.296806 230.822006,88.153854 230.225006,89.975998 
	C228.446350,90.874184 227.465607,90.262199 226.955139,88.428513 
	C227.033630,87.275330 227.033249,86.512711 227.033752,85.368530 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M226.707092,88.079590 
	C227.488739,89.063370 228.210114,89.764565 229.673004,89.979538 
	C230.380386,90.390022 230.534286,90.848999 230.244263,91.708878 
	C230.037735,92.030174 230.024246,92.023346 230.028076,92.030205 
	C228.895676,92.326714 227.759445,92.616364 226.265381,92.904427 
	C226.789185,91.380539 223.966263,89.492088 226.707092,88.079590 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M230.340302,91.984253 
	C230.023407,91.581276 230.009079,91.132378 229.991882,90.347092 
	C229.234879,86.737320 232.518997,86.442039 233.957123,84.390457 
	C235.208389,83.374924 236.954849,82.300613 236.568176,81.260040 
	C235.249161,77.710358 237.633408,77.152405 239.902054,76.095078 
	C243.104187,74.602692 241.269592,79.038055 243.294327,78.838669 
	C243.613388,78.807236 243.947845,78.932159 244.648438,79.004204 
	C244.498413,83.024323 241.876556,84.935310 238.235443,85.810036 
	C234.729172,86.652382 231.795685,88.187965 230.340302,91.984253 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M256.222290,68.650429 
	C254.776871,70.293465 253.392288,71.234070 254.941483,73.519707 
	C257.848236,77.808258 253.983215,84.157707 248.568375,84.377121 
	C247.137344,81.235832 258.430267,78.337372 248.384781,75.041092 
	C247.512299,72.865211 249.350418,71.525002 249.957352,69.376434 
	C251.158890,68.984161 251.852951,68.225861 252.752060,67.213409 
	C253.660980,66.437004 254.425079,66.212646 255.671860,66.551590 
	C256.388184,67.203865 256.521545,67.724670 256.222290,68.650429 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M256.359436,68.967682 
	C255.999268,68.571075 255.998520,68.140579 256.001465,67.387245 
	C255.405563,66.366295 255.235565,65.580681 255.758194,64.348907 
	C255.992401,63.996101 256.000000,64.000160 255.998047,63.996361 
	C256.872498,63.143742 257.723572,62.268925 258.853577,61.244995 
	C259.836761,60.708195 260.583435,60.363979 261.636322,59.983456 
	C261.969757,60.430641 261.996948,60.914127 262.059509,61.760857 
	C260.758575,64.753799 260.413818,68.166229 256.359436,68.967682 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M255.653152,64.035378 
	C255.996552,64.762337 256.000702,65.528580 256.005005,66.679611 
	C255.254944,67.055695 254.504715,67.046989 253.379425,67.035156 
	C253.068130,65.494820 253.726044,64.421631 255.653152,64.035378 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M258.741821,61.069012 
	C258.896027,62.524605 258.252838,63.619041 256.334656,63.959190 
	C256.091125,62.442905 256.766724,61.372032 258.741821,61.069012 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M327.276825,979.828552 
	C327.071442,980.086853 326.597260,980.132263 326.360809,980.153198 
	C325.126282,979.549866 324.189301,978.842529 324.076263,977.562744 
	C323.879730,975.337524 322.872192,974.894592 320.751404,975.529480 
	C317.247467,976.578430 314.115875,976.527283 313.667542,971.666504 
	C313.614227,971.088806 313.035950,970.531189 312.632050,970.018616 
	C309.317657,965.812378 311.016754,963.064819 315.559814,960.983276 
	C316.435425,961.396484 316.601929,961.978943 316.501892,962.668945 
	C316.290894,964.123840 314.662750,965.804993 316.917084,966.915466 
	C319.120728,968.000977 321.444031,969.089539 323.741821,966.277283 
	C325.175507,965.150818 326.352936,963.797668 327.923157,964.971497 
	C329.175446,965.907715 328.888184,967.388245 328.291565,968.625977 
	C327.179413,970.933167 328.083771,972.061279 330.356812,972.475891 
	C333.289124,973.010864 335.985199,974.415100 339.026001,974.613037 
	C346.186707,975.079224 346.229248,975.192566 345.082764,982.706177 
	C344.852814,983.281738 344.734467,983.500366 344.352570,983.981995 
	C343.363831,984.535400 342.654541,984.431763 341.639160,984.001221 
	C341.143280,983.707825 340.960358,983.571838 340.556396,983.185791 
	C339.926208,982.204285 339.579651,981.386658 338.226196,981.752563 
	C336.891235,982.251770 335.818787,982.591187 334.403900,982.909668 
	C333.521698,982.455383 333.018097,981.983582 332.333771,981.203491 
	C332.088196,980.506531 332.063446,980.075256 332.036926,979.320007 
	C330.160614,976.357971 328.959778,979.061035 327.276825,979.828552 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M316.281281,959.110718 
	C313.683899,957.931580 311.551331,956.811707 310.804077,954.032837 
	C310.263458,952.022461 308.494507,951.633484 306.792480,952.317566 
	C304.901398,953.077637 305.247803,954.652710 305.392731,956.476135 
	C305.702454,960.371948 303.900604,963.962402 302.231598,967.328064 
	C301.511475,968.780334 299.285950,967.981201 298.767883,966.302429 
	C297.016235,960.625732 294.866364,964.042908 292.394501,965.982361 
	C290.917877,965.002380 291.133087,963.787659 291.807892,962.218201 
	C293.512970,959.976868 295.325439,958.902283 297.886475,959.712524 
	C299.783691,960.312805 301.021545,959.593140 301.558655,957.329834 
	C301.525909,955.188171 302.012177,953.159851 299.404663,952.149170 
	C298.363953,952.015747 297.673920,951.851501 296.681610,951.375366 
	C294.877747,949.745117 293.514526,948.204041 294.672974,946.008057 
	C295.731812,944.000854 297.535431,944.091492 299.769775,944.881409 
	C305.465546,947.835815 310.706085,949.137512 316.607208,946.077209 
	C320.737915,943.934998 322.622864,945.660706 321.918396,950.595215 
	C320.639954,952.148315 318.584747,952.412292 318.099976,954.674438 
	C317.590240,956.409668 318.222900,958.194336 316.281281,959.110718 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M315.976746,959.327942 
	C317.772980,958.040344 315.618134,956.469910 316.738800,955.177612 
	C320.116058,953.545471 321.377563,956.129272 322.847809,957.920776 
	C324.138367,959.493408 324.110657,961.305359 322.538086,962.805725 
	C321.028412,964.245972 321.222107,965.297363 323.601868,966.568359 
	C321.250427,972.640137 317.228485,968.718994 314.660889,967.410950 
	C310.747040,965.416931 316.274872,963.833679 315.902344,961.290771 
	C315.930573,960.506592 315.961700,960.078369 315.976746,959.327942 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M286.342102,967.133423 
	C285.786530,964.791443 286.896515,962.072632 283.332214,961.001465 
	C282.378021,960.159668 282.551605,959.445129 283.498352,958.568848 
	C285.647400,957.934875 286.928589,958.806335 288.292908,960.335266 
	C289.406372,962.528198 289.402191,964.766479 290.922974,966.783020 
	C289.747437,970.732544 288.151428,968.201782 286.342102,967.133423 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M291.267090,966.967957 
	C286.988434,967.123474 288.257721,964.042053 287.998596,961.425537 
	C289.595306,959.628479 291.078125,960.164001 292.757385,961.644409 
	C292.665314,963.108704 292.338745,964.224792 292.032043,965.697266 
	C291.927307,966.364746 291.734344,966.625000 291.267090,966.967957 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M327.431946,980.038452 
	C327.458252,977.131775 330.087463,976.583435 332.105927,976.353333 
	C334.364319,976.095947 334.580383,977.514099 332.370148,978.941528 
	C330.620880,979.327332 329.206665,979.658691 327.431946,980.038452 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M342.218262,987.703369 
	C341.537811,987.984497 341.098419,987.989990 340.329529,988.001343 
	C339.666870,986.590393 339.333740,985.173401 338.994751,983.388794 
	C339.366272,982.616394 339.827545,982.461121 340.692810,982.770508 
	C341.012817,982.985840 341.000000,983.000000 341.007019,982.993774 
	C341.511200,983.514893 341.993988,984.054260 342.572144,984.976440 
	C342.908051,986.047913 342.842651,986.758118 342.218262,987.703369 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M341.008972,982.629517 
	C340.563049,982.990906 340.113312,982.996033 339.326233,983.011108 
	C338.788879,982.748535 338.588776,982.475952 338.176178,982.057922 
	C338.140991,981.079834 338.348419,980.220398 339.419739,980.213806 
	C340.790924,980.205383 340.913239,981.248352 341.008972,982.629517 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M332.113403,981.268188 
	C332.969849,981.054016 333.678925,981.371338 334.004730,982.587891 
	C333.092651,982.941284 332.322388,982.694031 332.113403,981.268188 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M240.289230,926.209717 
	C239.912857,925.658203 239.711578,925.407715 239.237289,925.085632 
	C237.821091,923.581177 235.854889,923.562134 234.287903,922.194763 
	C234.072632,921.948669 234.009277,921.982544 234.032257,921.954834 
	C231.077484,916.909973 226.907333,912.392151 227.926590,905.785095 
	C228.174286,904.179504 227.864761,902.484863 228.887604,900.641602 
	C230.911758,898.140198 233.579208,896.815735 235.444046,894.142334 
	C235.931519,893.796082 236.157181,893.703064 236.749329,893.638245 
	C237.559052,893.878174 237.951157,894.159424 238.466507,894.833496 
	C239.678101,897.791260 237.391937,900.678711 239.390381,903.586243 
	C239.674850,904.362183 239.734741,904.829102 239.686401,905.655762 
	C237.375900,911.791077 242.725571,915.361816 243.846802,920.178711 
	C244.136917,921.424988 245.463150,920.994873 245.943665,919.844788 
	C246.379028,918.802734 246.222916,917.530457 247.460968,916.478821 
	C248.440033,916.014893 249.144974,915.949646 250.214554,916.072205 
	C253.953995,917.448181 257.673706,918.153503 260.337280,921.523315 
	C261.011749,922.343567 261.347290,922.963257 261.746552,923.945068 
	C262.169312,925.623840 261.953430,926.863708 261.331329,928.116211 
	C258.162537,934.496155 259.774811,938.438782 266.499542,941.391479 
	C268.443268,942.244995 270.703857,943.320312 268.326660,946.077698 
	C261.471619,949.171692 257.650696,947.421448 256.875366,939.964844 
	C256.841644,939.640564 256.133270,939.386414 255.376556,939.045532 
	C255.013931,938.991394 254.536392,938.953552 254.299438,938.918579 
	C252.829529,937.801086 253.341141,936.551392 253.622559,935.252136 
	C254.443863,931.460205 251.444611,927.755310 247.774872,928.949707 
	C244.177948,930.120422 242.647720,928.082947 240.289230,926.209717 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M240.068375,926.311340 
	C241.636215,925.893250 242.993408,926.288879 243.914566,927.720520 
	C244.615921,928.810669 245.659500,928.900757 246.325760,927.895020 
	C247.643478,925.905762 248.605759,926.544678 250.281830,927.646484 
	C256.417664,931.679688 256.487000,931.574219 254.062683,938.509033 
	C252.247437,939.047119 250.421707,939.307922 248.389786,938.131958 
	C247.190887,937.336365 246.853912,936.364136 246.175964,935.262939 
	C245.962967,935.048889 245.969528,935.032532 245.955444,935.033997 
	C246.223938,932.972351 245.289963,931.706726 243.521423,930.665344 
	C241.992508,929.765137 240.582138,928.551208 240.068375,926.311340 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M245.971176,935.414917 
	C247.053177,935.438904 247.728760,936.191406 248.001907,937.648926 
	C247.594208,937.972656 247.144241,937.980225 246.357574,937.983398 
	C246.007034,937.246338 245.993210,936.513611 245.971176,935.414917 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M344.289886,984.254395 
	C344.014191,983.475586 343.926941,982.959229 344.035461,982.487976 
	C345.206879,977.400146 343.819580,974.924377 338.051575,975.928711 
	C334.913391,976.475037 332.250977,973.974365 329.029938,973.717041 
	C325.889984,973.466248 323.275818,971.969788 326.955780,968.469116 
	C327.673462,967.786316 328.396301,966.692688 327.529144,965.873474 
	C326.205444,964.622864 325.822968,966.960999 324.401733,966.975952 
	C324.090332,967.038757 324.039917,967.017151 324.013855,967.008545 
	C322.703278,965.279602 323.266541,963.860840 324.802368,962.726135 
	C326.106567,961.762573 328.242767,961.319275 328.973480,962.370544 
	C331.403046,965.865784 335.759613,965.436340 338.926514,968.032959 
	C339.455353,968.742188 339.661652,969.195129 339.866943,970.033203 
	C339.888885,972.686523 341.335938,972.570740 342.950439,972.294556 
	C345.597229,971.841919 348.234863,971.555664 350.760559,973.741211 
	C353.716949,973.860413 355.691864,973.815369 356.331635,977.682312 
	C356.940308,981.361450 361.316223,981.059387 364.684418,982.071533 
	C364.729675,983.152222 364.381104,984.184998 363.969513,985.537720 
	C363.482697,985.892029 363.058899,985.926514 362.315552,985.971313 
	C361.175873,984.546753 357.515839,986.812012 358.248047,982.801208 
	C358.472717,981.570618 355.365631,982.297791 353.405426,981.994019 
	C351.149811,981.406555 349.060516,979.600098 347.973938,983.326538 
	C347.749146,984.181030 347.389435,984.542480 346.492401,984.825684 
	C345.562836,984.894653 345.052155,984.776184 344.289886,984.254395 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M345.752686,984.573364 
	C346.400757,984.170776 346.846375,984.031433 347.626465,983.782410 
	C347.854248,984.614197 347.747620,985.555603 347.398865,986.747192 
	C346.850555,986.824097 346.544373,986.650879 346.095032,986.252808 
	C345.632721,985.693298 345.498932,985.296143 345.752686,984.573364 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M345.637573,984.220459 
	C345.958832,984.690613 345.962524,985.070984 345.959045,985.739746 
	C344.427429,985.906616 343.933014,987.617371 342.304779,987.936646 
	C341.994568,987.279724 342.011932,986.580322 342.037415,985.530884 
	C342.147308,984.957397 342.249084,984.734009 342.691437,984.370361 
	C343.199219,984.202820 343.366394,984.175354 343.772034,984.059448 
	C344.446991,984.024292 344.883514,984.077515 345.637573,984.220459 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M268.028809,946.292969 
	C269.150604,942.996887 267.046967,941.763550 264.994904,942.356323 
	C261.591980,943.339355 260.976685,942.046814 260.764435,939.197876 
	C260.704987,938.399597 259.458801,937.758362 259.092529,936.901611 
	C257.831970,933.953003 255.991348,930.841187 259.598236,928.223450 
	C260.892426,927.284119 261.008911,926.112854 260.982117,924.393311 
	C261.306580,923.659851 261.700195,923.411865 262.526215,923.286865 
	C263.337067,923.383606 263.761108,923.563538 264.384277,924.073364 
	C265.237244,924.570923 265.299988,926.544067 266.543427,924.611206 
	C266.587402,923.219604 266.416809,922.170105 265.855255,920.839355 
	C263.743652,915.332458 263.766449,915.306335 271.613708,914.125244 
	C272.811859,913.929993 273.610229,913.989563 274.772064,914.361816 
	C277.620544,917.250671 280.956085,919.547302 279.782654,924.386169 
	C278.197998,926.111938 275.633728,927.175537 279.582977,928.971924 
	C282.988068,930.520813 283.572388,934.445374 285.194702,937.854736 
	C285.386902,939.207520 285.320618,940.195312 285.058624,941.544800 
	C284.675629,942.620300 284.220398,943.194763 283.283508,943.803955 
	C282.007294,944.822632 278.747375,944.288086 281.320618,947.361084 
	C282.018036,948.131165 282.348389,948.729004 282.516052,949.760925 
	C281.815796,952.068420 280.315765,951.632996 278.959259,951.169861 
	C277.194885,950.567444 275.486969,951.357849 273.438477,951.045288 
	C272.786163,950.834961 272.506317,950.662720 272.149780,950.189758 
	C271.313477,949.218018 270.402313,948.786621 269.254333,948.172852 
	C268.710876,947.505249 268.388824,947.044495 268.028809,946.292969 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M273.208801,951.229248 
	C275.827667,948.550842 278.705139,949.380554 281.802185,950.228516 
	C282.591248,949.532227 283.262756,949.569763 284.275299,949.692017 
	C285.054352,949.821838 285.497437,949.911438 286.274658,950.066772 
	C289.900146,950.380066 293.321533,947.318237 296.750366,950.756226 
	C297.752472,951.003174 298.499176,951.011353 299.619171,951.027954 
	C301.626251,952.496094 301.638458,954.700989 302.727539,956.773193 
	C302.622284,960.913818 301.362213,963.281799 297.050018,960.658142 
	C295.496460,959.712952 294.641815,961.019409 293.288177,961.841248 
	C291.570953,961.664612 290.150024,961.336609 288.359772,961.007935 
	C286.897583,960.320618 285.804749,959.634094 284.393402,958.878296 
	C281.472321,956.059814 276.955261,957.538452 274.264771,954.225952 
	C273.787689,953.166809 273.542450,952.353210 273.208801,951.229248 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M274.026611,954.346558 
	C276.444977,954.105347 278.304169,956.366943 281.133057,955.439209 
	C282.421204,955.016724 283.934662,956.259766 284.048157,958.467163 
	C283.727142,959.313416 283.379425,959.817810 282.994080,960.630920 
	C281.458862,961.021545 279.941010,961.308594 278.283813,960.225708 
	C278.471130,956.843628 273.783386,957.666626 274.026611,954.346558 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M269.377625,955.848389 
	C268.920654,955.998291 268.739075,955.997070 268.285278,955.991821 
	C268.006714,955.792419 268.000305,955.596985 267.980133,955.106567 
	C268.205353,953.529480 268.864594,952.511902 270.185272,953.414246 
	C271.348083,954.208679 270.296997,955.028076 269.377625,955.848389 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M269.162598,948.261841 
	C270.161652,948.038086 271.405121,947.864075 271.957611,949.639648 
	C270.856445,949.990723 269.931671,949.548157 269.162598,948.261841 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M58.772167,641.775024 
	C59.269791,643.121155 59.499546,644.239441 59.873039,645.675720 
	C61.139267,647.063110 60.263798,648.069702 59.445210,649.560547 
	C58.578987,649.736938 57.877449,649.480835 57.839928,649.149536 
	C56.895344,640.808960 52.236725,633.628967 50.531811,625.551147 
	C50.215225,624.051208 50.823563,622.356079 51.022675,620.356201 
	C52.759834,620.203430 53.090130,621.426086 52.972317,622.849304 
	C52.582714,627.555786 55.415062,629.837341 59.388237,630.823730 
	C61.620949,631.378113 62.952599,632.095520 63.042114,634.567932 
	C63.120213,636.724915 62.468876,637.877441 60.144756,638.232971 
	C58.501579,638.484314 57.626995,639.734924 58.772167,641.775024 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M57.042824,658.435059 
	C55.048592,658.354370 52.378571,661.649048 50.988987,658.563110 
	C49.083279,654.330872 44.741913,650.351257 48.145817,644.963379 
	C48.829494,643.881165 50.110374,643.159424 50.135300,641.268921 
	C53.258732,641.701660 54.638512,642.644836 52.650543,646.341980 
	C49.983784,651.301697 53.416573,654.933228 57.042824,658.435059 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M62.403667,659.477417 
	C61.930912,659.847717 61.718952,659.886780 61.189377,659.993286 
	C60.723171,659.806946 60.532333,659.590454 60.114967,659.296082 
	C59.939941,656.349243 57.590733,653.781982 58.980644,650.411255 
	C58.971115,650.028137 58.997639,650.003723 59.011772,649.993408 
	C60.438599,649.550598 60.365646,650.659790 60.790878,651.732910 
	C61.006630,651.989868 61.019680,652.487732 61.025803,652.736694 
	C62.685291,654.745239 63.533737,656.718140 62.403667,659.477417 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M41.258240,619.934509 
	C39.591370,618.750793 40.117470,617.161804 40.156151,615.290405 
	C43.015530,615.913513 44.241886,617.359619 41.258240,619.934509 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M50.727329,614.194336 
	C52.033592,614.683594 52.182438,615.562317 51.295799,616.808472 
	C49.984360,616.325195 49.814007,615.448181 50.727329,614.194336 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M61.077667,662.297180 
	C61.956165,662.162231 62.793129,662.378479 62.796505,663.426392 
	C62.800816,664.763672 61.778538,664.896790 60.429340,665.022583 
	C60.392334,664.231384 60.703068,663.424438 61.077667,662.297180 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M212.915710,911.164917 
	C214.466782,911.195007 215.080368,912.012268 214.888382,913.387390 
	C214.714508,914.632996 213.798981,914.892883 212.379242,914.969849 
	C211.501282,914.742554 210.990997,914.497253 210.218048,914.146240 
	C209.788330,913.844910 209.621323,913.649353 209.729294,913.183228 
	C210.907578,912.413574 211.810898,911.914490 212.915710,911.164917 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M203.143646,913.307983 
	C203.088852,912.505127 203.276016,911.937805 203.699509,911.186890 
	C204.833908,911.064819 205.636154,911.272217 205.949753,912.611938 
	C206.133331,913.110168 206.270203,913.291443 206.206055,913.747803 
	C205.131882,913.863098 204.258743,913.703308 203.143646,913.307983 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M206.196671,914.259155 
	C206.001755,913.840759 205.998505,913.658691 205.995880,913.202759 
	C207.065277,912.934143 208.134064,912.939392 209.603561,912.928650 
	C209.998550,913.100586 209.992844,913.288574 209.971252,913.758545 
	C208.724548,913.864380 207.689560,915.223816 206.196671,914.259155 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M56.934898,320.361237 
	C56.764202,319.627808 56.747890,319.231964 56.721416,318.539185 
	C56.640156,318.020020 56.569057,317.797852 56.605110,317.242004 
	C58.466805,315.085083 63.224552,316.294739 62.099533,311.222748 
	C62.257545,309.635345 62.718826,308.461945 64.161636,308.630341 
	C65.873215,308.830078 66.898247,310.355835 66.806938,311.773529 
	C66.531166,316.055206 68.899826,316.552429 72.567780,316.177795 
	C74.597816,317.117432 75.807098,318.371002 76.415466,320.571655 
	C76.196579,322.040588 75.636765,322.860962 74.185745,323.236816 
	C69.283295,319.851837 64.605438,322.187836 59.614098,322.750732 
	C58.319733,322.243317 57.632957,321.549988 56.934898,320.361237 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M58.769505,322.309326 
	C62.651917,321.335144 65.998711,319.230072 69.966568,319.942932 
	C71.648254,320.245087 73.310120,320.163025 73.930023,322.599426 
	C73.825844,323.711761 73.363693,324.208954 72.459785,324.758972 
	C70.633919,325.306519 69.167801,325.903107 67.259430,326.063171 
	C65.154877,325.712799 63.385319,325.380768 61.219009,325.081665 
	C60.366772,324.837463 59.911289,324.560364 59.219536,324.157562 
	C58.629807,323.624878 58.482002,323.153381 58.769505,322.309326 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M57.298157,316.767761 
	C57.025135,317.253357 56.985691,317.457153 56.828754,317.951599 
	C53.685459,315.446564 54.793037,312.773804 57.266937,310.021240 
	C57.829720,309.922668 58.077919,309.935150 58.936073,310.226929 
	C60.306221,312.981689 58.895615,314.725983 57.298157,316.767761 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M58.937737,321.669800 
	C58.999325,322.452301 58.999401,322.903381 58.991371,323.693176 
	C57.512581,323.274384 55.473076,322.937469 56.559010,320.238556 
	C57.741535,320.043579 58.579914,320.258972 58.937737,321.669800 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M66.982819,326.401917 
	C68.085152,324.692780 69.571106,324.060699 71.699890,324.066040 
	C72.645088,326.328979 72.316292,328.603943 73.843628,330.881866 
	C75.409546,333.217346 71.751068,335.003540 71.162819,337.680176 
	C68.963554,338.196930 68.603058,339.819550 68.037094,341.649353 
	C67.822258,342.116974 67.655510,342.244720 67.241539,342.177124 
	C66.994606,341.534088 66.994911,341.086395 66.989120,340.302368 
	C66.847374,338.158905 66.711716,336.351715 66.305359,334.278015 
	C66.097252,333.554871 66.159859,333.098236 66.326912,332.310303 
	C69.437378,330.566467 67.312431,328.554443 66.982819,326.401917 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M61.305740,353.700928 
	C61.048412,354.477692 61.029461,354.912231 60.999130,355.672821 
	C61.023529,359.109375 57.884190,362.200409 60.951710,365.666260 
	C60.978535,366.170227 60.984142,366.337280 60.997169,366.754944 
	C60.814365,367.187164 60.624146,367.368744 60.145233,367.281525 
	C57.069904,366.351532 54.632992,364.564453 51.323784,365.800110 
	C49.258949,366.571136 47.410519,363.744629 48.634811,362.541168 
	C51.118317,360.099915 51.418945,355.619171 55.802128,354.933777 
	C58.599495,354.496399 59.182194,353.138947 57.627018,350.394806 
	C56.426617,348.276642 56.081905,345.539764 59.515297,343.972382 
	C64.148781,346.402863 63.127296,349.831512 61.305740,353.700928 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M61.395477,353.979187 
	C60.747784,350.907104 62.920498,347.499878 59.964119,344.272217 
	C59.883789,343.902405 59.951000,343.454041 59.977909,343.229004 
	C62.134705,342.755035 64.264595,342.506134 66.694397,342.119507 
	C66.994308,341.981781 67.491768,341.986908 67.740387,341.988037 
	C67.668762,343.896606 63.465679,351.897095 61.395477,353.979187 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M59.885460,367.342407 
	C60.047859,367.010925 60.239182,367.009155 60.717545,367.006470 
	C60.825310,367.563416 60.646027,368.121246 60.209976,368.837219 
	C59.940269,368.554260 59.927326,368.113159 59.885460,367.342407 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M209.735382,875.243408 
	C209.956741,874.929871 209.962204,874.464661 209.976410,874.232544 
	C210.434601,873.337830 210.922195,872.747925 212.190063,873.067505 
	C216.879593,876.453918 222.092239,877.802856 227.123154,878.922852 
	C235.020172,880.680847 236.842697,882.429993 236.537140,891.633301 
	C234.970596,893.225342 233.484833,892.573669 231.676758,892.050903 
	C228.252869,889.944702 230.310989,885.001648 226.204620,883.255005 
	C225.211945,883.020264 224.474289,883.013611 223.367844,882.999268 
	C219.442169,882.759705 215.818192,882.928589 212.592773,880.939880 
	C210.561646,879.687683 208.620316,878.451660 209.735382,875.243408 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M209.610092,875.013794 
	C210.888031,879.200134 215.212418,882.073425 220.017746,881.444885 
	C221.209076,881.289124 222.268097,880.813477 222.934265,882.646179 
	C219.294662,885.184448 215.684311,886.732422 211.042877,885.305908 
	C205.596909,883.632019 204.701904,882.330078 207.387985,877.249817 
	C207.840210,876.394409 208.202820,875.434509 209.610092,875.013794 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M226.003738,883.385498 
	C230.194382,883.758423 231.980469,886.093262 230.873230,890.678223 
	C231.343048,891.331055 231.534607,891.771057 231.515640,892.637207 
	C231.048141,893.689819 230.454544,894.094421 229.335846,894.121094 
	C226.766159,893.036621 223.657379,896.082642 222.344238,893.439087 
	C221.059631,890.853088 224.240555,889.732483 225.971375,888.302429 
	C227.698792,886.875305 226.471832,885.275757 226.003738,883.385498 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M228.933685,894.328247 
	C229.479599,893.725586 229.972290,893.460693 230.718842,893.077393 
	C231.896912,892.021790 232.985260,891.985046 234.490021,892.438171 
	C235.055878,892.514160 235.283875,892.531250 235.828400,892.680298 
	C236.231155,893.023743 236.317398,893.235352 236.201447,893.723755 
	C236.036072,897.545410 232.901169,898.315186 230.136597,899.983154 
	C228.889908,899.880432 228.312210,899.954102 227.480972,900.403076 
	C226.987061,900.632324 226.781158,900.715210 226.244690,900.878601 
	C224.957870,901.071838 224.049591,900.955811 223.040131,900.097900 
	C223.778381,896.819519 227.111679,896.662415 228.933685,894.328247 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M222.652344,899.820862 
	C223.957199,899.855835 225.068466,899.912537 226.595245,899.979004 
	C228.777283,902.495544 227.497543,904.412415 225.650620,906.019714 
	C224.263611,907.226685 222.522018,907.173584 221.006042,906.187988 
	C218.962250,904.859375 218.062698,903.083191 219.815430,900.427368 
	C220.563705,900.075989 221.000351,900.028076 221.754730,899.885986 
	C222.072464,899.791809 222.458755,899.842590 222.652344,899.820862 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M207.212524,869.655884 
	C205.573334,869.974609 204.202515,870.019836 202.420135,870.062866 
	C200.496872,869.921753 198.809494,870.372925 197.616486,868.411987 
	C199.356689,863.083374 203.644424,865.794495 207.206390,865.208374 
	C207.776031,865.505371 207.980499,865.665649 208.329132,866.187744 
	C208.558212,867.639526 208.216110,868.579895 207.212524,869.655884 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M207.302277,869.997070 
	C207.122208,869.088196 207.300308,868.247131 207.733307,867.199951 
	C208.572433,866.454834 209.258652,866.204468 210.417297,866.370789 
	C211.717545,867.022766 212.083191,867.967590 212.593307,869.248169 
	C212.902206,870.328979 212.938675,871.063293 212.402420,872.405457 
	C211.508026,873.265442 211.009262,873.524231 210.250549,873.891724 
	C210.052551,872.191956 209.798172,870.570862 207.302277,869.997070 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M197.642090,867.785156 
	C198.832382,869.386963 200.550812,868.648438 201.801376,869.793396 
	C201.454041,870.505493 200.899551,870.950378 200.182205,871.605713 
	C198.928574,871.850220 197.837830,871.884338 196.340012,871.884766 
	C195.032593,870.942261 194.939514,869.863342 195.483276,868.328735 
	C196.174927,867.613647 196.705963,867.475525 197.642090,867.785156 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M219.762283,900.088013 
	C219.108078,902.664368 219.456573,904.866638 222.359009,905.555908 
	C226.283615,906.488037 226.314438,903.195984 227.005402,900.366943 
	C227.010788,899.988831 226.998291,900.001404 226.992538,900.006836 
	C227.660965,899.429321 228.424576,899.260010 229.625092,899.744141 
	C229.969971,899.971619 230.027023,900.027283 230.056274,900.056335 
	C229.969620,905.893127 226.798203,912.256104 233.455917,916.810486 
	C234.676056,917.645203 233.865952,919.647644 234.045929,921.516479 
	C231.506348,920.701172 229.518875,918.419922 226.160614,918.703735 
	C224.732040,918.824463 223.542557,917.667114 225.641479,915.826233 
	C228.077148,913.690063 227.794495,911.473083 224.070694,910.876831 
	C221.120712,910.404480 218.927933,907.805725 215.364532,907.962646 
	C215.898834,905.654724 218.988388,904.236328 217.531754,900.940613 
	C217.222397,900.240662 218.622559,900.105408 219.762283,900.088013 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M184.364410,889.020264 
	C184.015121,888.867676 183.992233,888.671875 183.937027,888.182129 
	C183.566849,886.391785 184.473572,885.508911 185.830612,884.506470 
	C186.940262,884.118530 187.690170,884.156921 188.705948,884.732056 
	C188.736313,885.503906 188.483459,886.018188 188.140076,886.806091 
	C186.929962,887.712219 185.810394,888.344666 184.364410,889.020264 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M195.776062,867.820190 
	C196.017792,869.058777 195.986282,870.077576 195.943878,871.473694 
	C195.891708,872.025024 195.850449,872.198975 195.728882,872.628845 
	C194.723022,874.258545 193.675690,875.518921 191.992157,876.642883 
	C188.257523,877.383911 184.975113,878.185364 181.859100,879.955750 
	C178.329559,881.961060 176.615234,881.157288 176.186890,877.117249 
	C175.811005,873.572083 173.610504,874.430847 171.279846,874.197388 
	C170.529892,874.044739 170.093323,874.041809 169.329346,874.039185 
	C165.356827,873.665955 164.658752,871.254883 165.049072,867.773315 
	C165.368729,866.882446 165.657257,866.433899 166.321014,865.777832 
	C167.727676,864.822510 168.945221,864.517395 170.600952,864.603394 
	C172.474686,867.437317 175.491516,867.024597 178.166870,868.134033 
	C178.808121,868.620422 179.143051,868.937561 179.690811,869.535034 
	C184.169266,873.359802 187.940536,873.282715 191.827744,869.480957 
	C192.879623,868.452332 193.749893,867.201843 195.776062,867.820190 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M191.706329,876.235474 
	C192.721878,874.695923 193.085190,873.033264 195.338379,872.918823 
	C195.726974,873.954895 195.805389,875.025146 195.871246,876.496887 
	C195.135117,878.045898 194.113800,878.884766 192.724533,879.803955 
	C191.112274,880.376648 190.245102,881.397217 188.999313,882.494751 
	C188.021301,882.958984 187.312988,883.049438 186.218292,882.918823 
	C185.629120,882.727966 185.404984,882.616028 184.923264,882.228271 
	C184.491577,877.130249 188.656281,877.669739 191.706329,876.235474 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M185.781036,882.364807 
	C186.765808,882.007874 187.506485,881.988220 188.617401,881.956177 
	C190.060150,881.326477 190.656754,880.213440 191.811768,879.245605 
	C193.105484,878.367798 194.174637,877.692627 195.551239,876.957886 
	C197.604111,879.336914 193.684631,879.531616 194.060638,881.756714 
	C193.951309,882.384216 193.782166,882.642639 193.354401,882.988037 
	C192.069656,883.919495 190.866699,884.438721 189.252228,884.782837 
	C188.993637,884.989990 188.999191,884.999268 188.994171,884.994507 
	C188.242859,884.995300 187.496552,885.000854 186.376907,885.005676 
	C185.374512,884.333191 185.272079,883.554810 185.781036,882.364807 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M189.399750,884.998108 
	C189.859848,883.827209 191.110901,883.366699 192.810684,883.102905 
	C193.135986,883.532898 193.112839,883.949280 193.077057,884.678833 
	C191.978226,884.996704 190.892044,885.001465 189.399750,884.998108 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M171.253464,864.720337 
	C169.902924,865.333618 168.806442,865.667236 167.360870,866.004883 
	C163.527542,863.841064 159.495758,863.273682 155.338333,862.036133 
	C154.467239,861.782471 153.920242,861.618896 153.200897,861.220032 
	C151.724899,859.674500 153.250549,858.306091 152.845978,856.704407 
	C151.819656,854.886841 150.267914,855.502502 148.602982,855.327515 
	C145.906631,854.989441 143.824432,854.403320 144.563644,850.910400 
	C146.009659,847.570618 141.225037,846.041260 143.089401,842.741211 
	C146.354263,841.067505 149.119385,841.539978 151.641586,843.627686 
	C152.812408,844.596802 154.077133,845.370911 155.602280,845.543457 
	C159.147736,845.944763 160.548767,847.876770 159.787003,851.336731 
	C159.371140,853.225647 160.503998,853.583191 161.907562,854.474915 
	C165.566559,856.799622 169.877472,858.536804 171.753967,863.347290 
	C171.666611,863.962769 171.587082,864.201782 171.253464,864.720337 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M155.847885,876.678101 
	C154.292465,877.176208 152.853363,877.984863 151.514893,878.996582 
	C150.684021,879.624634 149.031723,880.247009 148.877518,879.676636 
	C147.425003,874.303772 140.077637,876.465393 138.267471,871.201172 
	C137.004608,869.909180 136.685898,868.644958 137.158936,866.836426 
	C137.553833,865.999878 137.920242,865.656738 138.756470,865.356018 
	C140.148422,865.021423 141.618668,865.881836 142.181702,864.469360 
	C142.967804,862.497437 141.033813,861.638123 139.937103,860.095581 
	C139.596603,859.641602 139.470917,859.450928 139.188965,858.953369 
	C137.755356,856.223511 138.657425,852.380005 134.244904,851.596008 
	C133.758774,851.203735 133.592606,851.007324 133.276688,850.446167 
	C132.715317,847.255493 134.112839,845.998230 137.206024,845.884277 
	C138.439713,846.075562 139.177231,846.424500 140.168884,847.158203 
	C141.537399,849.372864 144.373260,850.440918 143.208557,853.671753 
	C139.487411,855.325745 141.872421,857.696533 142.821640,858.413940 
	C147.224716,861.741516 147.904083,863.860718 144.683823,867.766113 
	C143.030334,869.771362 142.840210,870.833191 145.790970,871.130188 
	C146.270859,871.178406 146.813950,871.656433 147.188873,871.528503 
	C151.880905,869.927979 153.608582,873.447021 155.847885,876.678101 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M143.120163,854.266479 
	C143.693573,851.277039 140.419113,850.791626 140.030380,848.355103 
	C142.351151,846.313721 144.125946,847.414001 145.889954,849.611572 
	C145.622162,851.980591 144.392761,854.419556 148.632874,853.999023 
	C150.162125,855.487732 152.134552,854.598389 153.746063,855.763306 
	C156.317596,857.965393 153.429031,858.887085 153.080170,860.658081 
	C149.783585,858.847046 146.538666,856.709290 143.120163,854.266479 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M155.050369,862.307373 
	C159.558060,860.984070 163.579025,861.870117 166.945618,865.655945 
	C166.896088,866.321777 166.706558,866.580505 166.230789,866.903320 
	C165.431351,867.824280 164.888275,868.699585 163.356308,868.239868 
	C162.864777,866.981689 162.342560,865.740784 161.232025,866.228760 
	C157.389435,867.917297 156.312897,865.255554 155.050369,862.307373 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M163.033722,868.425049 
	C163.842850,867.773132 164.635971,867.516113 165.723740,867.140259 
	C165.907272,869.550476 165.668579,872.130188 168.788513,873.750366 
	C166.439331,876.182861 164.861374,874.437439 163.143219,872.290466 
	C162.992004,870.928772 163.004852,869.874268 163.033722,868.425049 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M138.233383,865.581360 
	C138.004730,867.333862 138.005142,868.743103 138.003998,870.574951 
	C137.540298,871.009216 137.078140,871.020813 136.269745,871.067322 
	C135.923477,871.102173 135.940933,871.071289 135.932159,871.066040 
	C133.859695,869.626221 131.822067,868.169983 131.044128,865.241211 
	C130.527863,864.700623 130.231262,864.398682 129.703842,863.890381 
	C128.699844,863.007751 128.048920,862.222717 127.245064,861.166382 
	C126.849976,860.405884 126.650780,859.878479 126.217880,859.183960 
	C124.444275,857.083374 123.223366,854.722351 125.356400,852.874939 
	C127.177094,851.298096 129.628784,852.595337 131.274109,853.917114 
	C134.907425,856.835876 138.183548,860.125671 138.233383,865.581360 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M185.767639,882.016418 
	C186.016022,882.771606 186.006912,883.515625 186.000671,884.632263 
	C185.403992,885.794434 184.804459,886.584045 184.054810,887.630859 
	C179.499390,888.775024 175.989441,887.489319 173.344849,883.366150 
	C173.098602,882.338989 173.052368,881.564209 172.995285,880.403076 
	C172.464584,879.419617 172.226639,878.729492 172.413589,877.595337 
	C173.830215,875.951416 175.412460,876.377686 175.682007,877.755371 
	C176.679840,882.855042 180.045776,882.047119 183.522736,881.330872 
	C183.833405,881.266907 184.168411,881.424133 184.735840,881.773438 
	C185.166550,881.995911 185.338333,882.000549 185.767639,882.016418 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M185.055298,881.645630 
	C184.671280,882.044250 184.334625,882.056763 184.026291,882.156616 
	C179.958832,883.474243 175.256607,886.364014 175.048569,878.380981 
	C175.037781,877.967163 174.136902,877.576538 173.279999,877.143372 
	C172.507431,877.398865 172.065918,877.503967 171.295502,877.181580 
	C171.003159,876.234253 170.994339,875.504395 170.975983,874.411133 
	C176.845963,871.106323 176.745041,871.114258 177.278931,877.914185 
	C177.526245,881.064331 179.408112,880.065613 181.026733,878.940247 
	C184.072861,876.822449 187.314743,875.442261 191.559296,875.989990 
	C190.867111,879.271667 186.195709,877.962646 185.055298,881.645630 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M170.779816,877.101929 
	C171.434372,876.995239 171.856766,877.026428 172.594391,877.084595 
	C172.941116,877.836731 172.972656,878.561768 172.994308,879.651733 
	C171.886642,879.937317 170.665359,880.149170 170.047653,878.471619 
	C170.174332,877.844421 170.360992,877.542114 170.779816,877.101929 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M246.717468,92.798691 
	C246.993546,93.009666 247.480133,93.048912 247.723602,93.066330 
	C249.228333,93.579109 249.942184,93.326820 250.572815,91.927711 
	C253.015121,86.509209 253.157562,86.507835 259.122437,88.730247 
	C260.570282,92.175995 258.433014,95.191849 258.944458,98.709076 
	C259.067505,99.816391 259.008667,100.547783 258.683411,101.602356 
	C256.975403,103.843063 254.786743,104.562744 252.778152,103.383804 
	C248.150116,100.667389 244.863373,104.233536 242.069443,106.224167 
	C239.262009,108.224419 240.592438,112.300682 241.804932,115.472008 
	C242.336166,116.861465 243.113342,118.154381 243.473846,119.992249 
	C241.264145,123.487251 240.944839,128.427353 235.301285,128.498123 
	C234.583832,127.939682 234.348404,127.485725 234.235657,126.577850 
	C234.310883,125.689026 234.482971,125.218254 234.958588,124.485565 
	C237.347595,121.937660 235.732117,120.310516 234.233017,118.703888 
	C232.920013,117.296661 231.568710,115.945114 233.796570,114.212425 
	C235.176208,113.139420 234.256485,111.828873 234.245850,110.562630 
	C234.187943,103.673386 236.104233,97.583717 241.788208,93.166191 
	C243.206909,92.063599 244.763550,91.759926 246.717468,92.798691 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M206.060944,114.370171 
	C207.242523,113.443382 208.464127,112.999855 209.007782,111.337807 
	C211.282242,110.764137 213.919220,110.840210 211.925339,107.346634 
	C211.565231,106.715668 211.469986,106.093079 212.179626,105.633377 
	C213.020126,105.088905 213.731079,105.373871 214.351608,106.050438 
	C218.381073,110.443733 222.422058,105.331512 226.580322,105.909416 
	C228.322678,106.151566 225.605347,102.059486 228.630798,101.040947 
	C230.929230,101.312492 233.673538,100.121368 234.452118,102.949524 
	C235.135147,105.430557 233.543839,107.327461 231.631073,108.813850 
	C230.329697,109.825134 229.172668,110.999298 227.592422,112.230057 
	C226.341888,112.812195 225.431305,112.667542 224.237701,112.086403 
	C222.994461,111.287010 222.057434,110.869537 220.973846,112.274994 
	C219.839478,113.905365 219.522507,115.412376 219.701859,117.387253 
	C217.472687,122.356285 214.805389,118.827789 212.052383,118.073166 
	C209.695557,121.570618 211.187225,127.784904 204.688446,128.863037 
	C203.858765,128.951111 203.384415,128.912842 202.582642,128.677261 
	C202.070053,128.387146 201.889816,128.232239 201.570053,127.756897 
	C201.413895,127.209869 201.397263,126.983246 201.460861,126.432037 
	C202.232910,124.786415 203.960983,124.475494 204.597382,122.742683 
	C204.788559,121.840744 204.952271,121.243782 205.254135,120.348160 
	C205.634918,119.466995 205.969498,118.914917 205.882446,117.642776 
	C205.848953,116.270050 205.931976,115.510071 206.060944,114.370171 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M226.743668,112.330414 
	C227.616669,110.745407 228.239441,109.446060 229.617050,108.638664 
	C231.630508,107.458588 233.652130,105.962524 233.524796,103.427361 
	C233.365631,100.258385 229.750793,104.332031 229.028076,101.294151 
	C229.941483,98.521645 231.453415,97.047127 234.326981,96.628326 
	C236.640579,96.291130 239.481949,95.611023 239.841232,92.537674 
	C240.142044,89.964569 240.668350,86.850151 243.173370,86.669861 
	C246.362869,86.440331 245.585541,90.339325 246.855621,92.673897 
	C242.050903,93.399933 237.663406,97.461349 236.974350,102.089653 
	C236.396652,105.970016 234.178146,109.509308 236.718353,113.816040 
	C238.166290,116.270905 234.491196,114.800308 233.283005,115.932518 
	C235.674744,118.439209 240.087891,120.293388 236.380829,124.948654 
	C233.946777,123.493576 233.064819,120.851601 230.580063,119.239960 
	C230.133545,118.895927 229.962372,118.750221 229.556610,118.365051 
	C228.595078,117.000877 227.516479,116.197136 226.498428,114.894989 
	C226.160339,113.904129 226.199127,113.237701 226.743668,112.330414 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M259.383240,89.365280 
	C255.374466,88.500839 251.677414,87.282944 251.514267,93.494568 
	C251.445450,96.115044 249.508850,96.148468 248.107330,93.371651 
	C248.850266,89.451958 251.029129,87.266098 254.320740,85.235344 
	C257.103302,83.518639 257.999359,79.471176 258.727020,76.123062 
	C259.579468,72.200806 261.096130,69.953148 265.429688,71.663635 
	C267.279633,72.393806 267.967712,70.522186 269.643555,70.050720 
	C271.854065,75.137505 268.070190,76.002571 264.590973,76.953247 
	C263.347870,77.292923 261.663239,77.062721 261.530701,78.715897 
	C261.396942,80.383751 263.152893,80.445503 264.655640,80.961975 
	C265.360413,81.320419 265.572937,81.719841 265.663208,82.535629 
	C265.531403,83.583954 265.271484,84.238609 264.888611,85.226425 
	C264.086334,87.106438 263.468109,88.696945 261.479919,89.644745 
	C260.636627,89.795082 260.156586,89.732620 259.383240,89.365280 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M202.280441,125.773849 
	C202.093597,126.023476 201.943146,126.492996 201.877823,126.730698 
	C200.517532,128.618881 202.053268,130.409073 201.422424,132.421753 
	C199.721298,134.197266 198.692642,133.150314 197.496979,131.667725 
	C196.208084,128.958435 195.822449,126.379601 195.271927,123.486954 
	C194.950974,122.728859 194.845062,122.272705 194.892944,121.465134 
	C195.181168,119.039040 196.537201,118.726265 198.150467,119.277786 
	C199.308975,119.673836 199.992981,120.359383 199.194672,121.910309 
	C198.105896,124.025551 200.051895,123.719048 201.775742,124.131805 
	C202.516571,124.705292 202.561661,125.108109 202.280441,125.773849 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M190.671814,119.893387 
	C190.889648,120.181664 190.906311,120.608391 190.914062,120.821777 
	C191.728546,122.789009 193.730698,123.105606 195.157318,124.634399 
	C195.560486,125.305634 195.666534,125.737473 195.632156,126.520874 
	C194.819641,128.195847 195.002335,129.843582 193.933075,131.375488 
	C193.027008,131.819885 191.978912,132.071640 191.773834,131.681992 
	C189.814072,127.958771 185.304794,126.177429 184.399277,121.453079 
	C185.297302,120.060806 186.488144,119.828590 188.001526,119.445023 
	C188.589661,119.313957 188.829620,119.283379 189.430466,119.284737 
	C190.012238,119.412827 190.233093,119.508972 190.671814,119.893387 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M190.932404,119.778244 
	C190.708710,120.188858 190.527786,120.196045 190.076431,120.178391 
	C187.533035,117.890755 185.358948,115.547523 183.850555,112.393250 
	C183.951355,111.297546 183.967743,110.539848 183.978912,109.403633 
	C184.669281,109.474525 185.324799,110.227280 186.066772,110.325272 
	C191.929413,111.099510 192.026215,114.905991 190.932404,119.778244 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M206.236603,119.659332 
	C205.958359,120.702705 205.919556,121.406502 205.872589,122.462654 
	C205.172302,123.950546 204.037079,124.181999 202.512970,124.171707 
	C202.181915,124.047966 202.015808,123.999428 201.931931,123.972519 
	C202.154587,121.295219 202.151627,118.498207 205.429535,117.063110 
	C205.765930,117.030029 205.977203,116.954552 206.089279,116.949692 
	C206.645035,117.695740 206.786819,118.481567 206.236603,119.659332 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M195.259354,124.876923 
	C193.043076,124.294868 190.413834,124.508698 190.691483,121.231155 
	C192.007629,121.055534 193.093414,121.075905 194.585663,121.111526 
	C195.026810,121.443535 195.176819,121.690186 195.646393,121.957245 
	C196.996796,123.045052 196.246353,123.877991 195.259354,124.876923 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M183.927933,108.693634 
	C183.218277,109.018448 182.467880,109.009605 181.341400,108.982071 
	C181.549820,106.876297 182.429321,106.217796 183.927933,108.693634 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M150.772324,133.318207 
	C151.365067,133.080811 151.596848,132.913300 151.882660,132.498718 
	C154.198151,132.033722 156.459946,131.859863 158.105103,129.512390 
	C161.379562,126.945923 164.921494,126.706673 168.585831,127.018875 
	C171.677902,127.282333 173.296509,125.973366 173.072433,122.315842 
	C173.082825,121.550919 173.090622,121.213165 173.116089,120.628769 
	C173.133759,120.382126 173.213425,120.287727 173.270905,120.265167 
	C174.116272,119.578339 175.007874,119.455048 176.246124,119.974792 
	C176.635925,120.425552 176.756393,120.636276 176.991699,121.195084 
	C177.407013,123.266159 177.374527,124.986534 176.956284,127.066780 
	C176.728485,127.652756 176.610550,127.876709 176.239288,128.384399 
	C174.152908,130.244171 171.751907,130.099518 169.262131,130.874847 
	C168.186020,131.159164 167.454590,131.263260 166.361755,131.389633 
	C163.258606,131.505356 163.101532,133.205124 163.890579,135.150269 
	C164.507126,136.670197 165.394394,138.084976 165.686356,139.729675 
	C166.092163,142.015610 165.526184,143.718552 162.866119,143.890869 
	C161.328369,143.990463 159.877304,144.095871 159.236145,145.821671 
	C157.566360,150.316223 153.980713,152.425049 149.583527,153.409210 
	C147.794830,153.809540 146.607986,155.568726 144.337296,155.240158 
	C144.040848,155.039597 143.999985,155.000046 143.979431,154.980377 
	C141.908676,149.804749 142.131302,149.491806 150.639954,146.936661 
	C144.323914,144.640625 143.222168,140.532501 145.056702,135.348312 
	C146.074600,132.471863 148.356018,133.232300 150.772324,133.318207 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M176.309082,119.958572 
	C175.261932,120.240051 174.607101,120.854393 173.539703,120.314072 
	C173.574142,118.444466 173.819885,116.646317 174.080887,114.461121 
	C177.316818,114.094017 179.657150,110.315086 183.593781,112.003700 
	C186.111191,114.320107 188.774445,116.207184 189.742081,119.806335 
	C189.805984,120.153542 189.387466,120.173157 189.178009,120.177048 
	C185.841644,120.290092 183.261826,117.991562 179.840149,118.335808 
	C178.619537,119.049835 177.704178,119.651360 176.309082,119.958572 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M75.175888,609.306519 
	C74.992882,607.286255 74.993904,605.571655 74.997253,603.428528 
	C75.521347,602.576599 75.667511,602.054871 75.189644,601.225891 
	C74.968132,601.024048 74.969086,601.025085 74.960396,601.030029 
	C74.654358,600.696777 74.408203,600.324768 74.147385,599.592896 
	C74.221298,597.125244 74.609230,594.924805 72.537155,592.952576 
	C72.319016,592.161865 72.360657,591.698120 72.620224,590.895020 
	C72.713234,584.141418 72.004578,577.706055 73.761337,571.381653 
	C74.335449,569.314819 73.585655,567.419250 72.197754,565.887573 
	C70.712067,564.248047 70.245949,562.096985 68.874413,559.994507 
	C68.080421,558.789062 68.036949,557.776367 68.018066,556.380310 
	C68.011467,554.287354 68.005989,552.572266 67.999710,550.428589 
	C67.986267,549.405212 67.812645,548.867554 67.234108,548.192505 
	C67.023056,547.237061 67.010323,546.492249 67.003944,545.374878 
	C70.046967,542.108887 69.414978,538.318909 69.568542,534.700317 
	C69.757797,530.240540 68.292213,525.332764 74.113716,522.690186 
	C75.943878,521.859375 74.665596,519.947876 72.728966,519.998169 
	C68.143143,520.117249 67.621140,517.485291 67.972855,513.808777 
	C68.423553,509.097504 65.944336,504.322266 68.250687,499.602356 
	C68.496979,499.098328 68.040886,498.294800 67.647530,497.756531 
	C65.250961,494.477081 65.744980,491.217072 67.669937,487.960632 
	C68.212509,487.042847 68.339806,486.100708 67.887131,484.734192 
	C67.608543,483.389618 67.570282,482.373444 67.022842,481.076965 
	C66.253830,477.932129 65.306870,475.188660 66.026344,471.899475 
	C66.844215,469.979126 68.265411,468.867615 68.975464,466.895905 
	C70.791718,461.154022 70.933792,455.780609 68.492432,450.148743 
	C67.941673,448.866302 67.865692,447.870300 67.967422,446.475586 
	C68.424622,444.636780 68.566963,443.112976 69.212570,441.333221 
	C71.676552,438.395050 72.759476,435.660828 71.703888,431.993591 
	C70.996056,429.534515 71.523453,426.780579 72.119965,423.864105 
	C73.074760,421.925873 75.234215,420.851593 74.253922,418.181519 
	C74.167450,417.411499 74.182701,416.968811 74.334541,416.203278 
	C75.057007,414.185852 77.440613,412.955048 75.650909,410.360291 
	C75.367172,409.623444 75.315155,409.177734 75.405563,408.391724 
	C75.998871,407.079803 76.526085,406.100861 76.019348,404.594910 
	C75.503754,402.079315 76.089111,400.030518 76.839256,397.659058 
	C78.042137,393.371735 82.166252,390.539703 81.097893,385.482727 
	C80.413391,382.488281 80.668816,380.010590 81.959915,377.530151 
	C83.645744,374.291443 83.255455,370.478790 84.588814,366.724487 
	C88.400627,358.196167 93.587349,351.241791 101.352356,345.959167 
	C101.867592,344.954529 102.414177,344.222321 101.326729,343.313477 
	C100.637993,342.981171 100.246475,342.771240 99.576126,342.341003 
	C97.752235,340.749054 96.769600,339.118134 97.069466,336.579773 
	C97.328079,335.481628 97.640282,334.800964 98.142197,333.799194 
	C100.521927,325.972656 106.014481,319.652771 106.208946,311.429321 
	C106.242065,310.028717 106.826233,308.634277 107.951523,308.018311 
	C113.601204,304.925568 114.897697,299.297516 116.560562,293.472229 
	C116.619225,292.374969 116.749466,291.655426 117.008324,290.584778 
	C117.345963,289.526459 117.697540,288.879852 118.324570,287.967163 
	C120.629807,286.014679 122.415176,283.992798 125.084572,282.504700 
	C125.973534,282.386902 126.463173,282.506805 127.196854,283.038696 
	C128.277924,284.712219 128.176865,286.194061 127.618729,287.584778 
	C125.651482,292.486664 126.910873,297.634644 126.230392,303.007538 
	C125.968353,303.599976 125.842255,303.825073 125.475006,304.347473 
	C123.191933,306.002655 121.901329,307.586548 122.215225,310.365631 
	C122.600723,313.778534 120.481491,316.212311 118.244507,318.529083 
	C112.039543,324.955322 108.514030,332.882050 108.734917,341.592529 
	C108.870796,346.950989 108.291435,351.676910 105.829529,356.675079 
	C104.948135,358.044983 103.720848,358.556396 102.786987,359.887604 
	C99.402016,363.619965 98.695000,368.218842 96.846626,372.278076 
	C95.952637,374.241425 95.229744,376.271088 94.190002,378.182831 
	C93.183487,380.033508 89.493805,379.975525 90.343704,382.755798 
	C91.196129,385.544373 91.994858,388.628052 94.350266,390.653564 
	C97.579346,393.430328 97.074242,396.212738 94.402702,398.729675 
	C91.367416,401.589417 91.171768,404.670502 92.696106,408.227020 
	C94.165047,411.654358 94.269783,414.521118 89.523598,415.055573 
	C87.604973,415.271606 86.994835,416.406738 87.116020,418.140808 
	C87.484070,423.407471 86.068222,428.460571 84.438065,433.277649 
	C80.969658,443.526764 81.189194,453.422882 85.557755,463.272217 
	C86.384796,465.136810 86.245621,467.187988 86.792587,469.493713 
	C86.951546,470.370331 86.970810,470.878357 86.890457,471.767151 
	C86.477310,473.413666 86.348076,474.921387 85.456177,475.817535 
	C81.269737,480.023987 83.052658,484.668427 83.607803,489.601440 
	C84.298790,495.741547 84.957520,501.975891 84.606087,508.220917 
	C84.460457,510.808868 83.859978,513.516907 86.095268,516.048218 
	C86.879135,516.882812 87.258324,517.530029 87.502762,518.640137 
	C81.954773,523.104248 82.471657,528.440186 84.513412,534.041748 
	C85.479431,536.692078 85.145172,539.210327 84.238846,541.796326 
	C83.090469,545.073059 83.339020,547.992737 86.802734,549.879089 
	C89.491241,551.343262 89.326683,553.798035 88.201630,555.914124 
	C85.817642,560.398132 86.504639,565.688721 84.122612,570.139404 
	C83.463837,571.370239 83.518562,572.973572 84.524849,573.888428 
	C87.640846,576.721191 88.201813,580.829529 90.347107,584.506836 
	C90.806847,584.906677 90.987473,585.073059 91.405891,585.524902 
	C92.217247,586.644470 92.601013,587.565063 92.964165,588.902161 
	C93.135574,590.031006 93.085342,590.783630 92.871613,591.896851 
	C90.798744,596.214905 91.291107,599.505493 93.272133,603.804260 
	C97.231232,612.395142 100.122459,621.502075 98.757034,631.464905 
	C97.907043,637.666870 102.563263,641.545532 106.968254,644.773926 
	C110.836357,647.608826 112.933289,650.450684 110.601143,655.189758 
	C109.588188,657.248230 111.252090,659.027405 111.270523,661.333008 
	C109.521698,663.657104 107.531097,665.471252 107.635086,668.605835 
	C110.949921,667.435364 109.206665,663.959045 111.068893,661.898926 
	C115.756805,661.740967 117.196884,665.274170 117.993820,668.475098 
	C119.408165,674.155823 121.079689,679.645508 124.058212,684.707947 
	C125.498909,687.156616 125.465309,689.787415 124.727585,692.444885 
	C124.271561,694.087646 123.740410,695.977295 125.172638,697.150146 
	C130.637604,701.625671 130.620743,709.092102 134.862198,714.328247 
	C137.497406,717.581421 138.196381,721.844299 135.631424,726.021301 
	C133.943268,728.770508 134.703918,731.119324 137.721939,732.572266 
	C138.621307,733.005066 139.780426,733.363281 140.195679,734.196899 
	C144.303879,742.444519 153.287994,748.002441 153.498749,758.408691 
	C153.572571,762.054260 155.446686,764.317444 159.556152,764.912231 
	C160.370743,765.236145 160.782806,765.507568 161.346527,766.152893 
	C162.448059,769.529907 160.579559,772.047363 159.863022,774.799622 
	C159.004379,778.097595 160.661896,777.615479 162.973602,776.514709 
	C163.782318,776.343262 164.248154,776.388000 165.001678,776.719299 
	C166.023087,777.954773 165.908737,779.094788 165.787567,780.272034 
	C165.593399,782.158325 163.933777,784.604248 165.983551,785.796387 
	C168.294220,787.140137 170.968994,788.521423 173.924744,787.974731 
	C179.946411,786.860718 183.737030,790.645203 181.746277,796.702209 
	C180.286469,801.143799 180.893936,803.252808 185.572800,804.079651 
	C187.580002,804.434326 188.137894,806.181580 187.587357,807.979980 
	C186.357269,811.998108 188.712585,811.714966 191.531235,811.898132 
	C194.726196,812.105835 197.193604,808.511475 200.792053,810.698242 
	C201.819397,811.069763 200.525314,812.865173 202.301636,812.071167 
	C203.435699,805.465820 202.706268,804.741089 196.639877,806.116089 
	C194.284592,806.649902 191.181427,808.408875 189.861008,805.737854 
	C188.169189,802.315430 185.771957,798.859619 186.094986,794.711670 
	C186.252243,792.692383 187.797302,792.008423 189.685455,792.203186 
	C191.862762,792.427734 193.875870,793.114929 195.849182,794.748596 
	C197.809662,795.918274 199.544220,797.128967 200.154938,793.554321 
	C201.313293,790.031616 203.233292,789.351746 205.490341,791.986572 
	C206.939194,793.677979 208.780548,793.891357 210.922333,794.614136 
	C212.213470,796.421936 211.595352,798.253113 212.008789,800.327393 
	C211.631622,805.443970 210.969849,810.142273 209.981369,815.179810 
	C207.417374,817.683350 207.352036,821.570374 203.888824,823.328491 
	C201.515030,823.875122 199.123901,823.141052 197.324722,825.476074 
	C196.381607,826.169006 195.519226,826.877075 194.845291,826.643005 
	C190.771973,825.227905 187.395767,824.615356 186.767487,830.583191 
	C186.641357,831.781311 184.577774,831.856750 183.323288,831.036743 
	C181.239639,829.674805 180.070587,827.762817 180.409134,825.193726 
	C180.631104,823.509277 181.820862,822.235168 182.509995,820.746338 
	C183.335007,818.963867 182.844940,817.576660 180.763382,817.876404 
	C177.449768,818.353516 175.127625,816.407837 172.183823,814.958618 
	C170.791199,814.204956 169.839615,813.431213 168.511475,812.554565 
	C165.263245,809.028992 161.574265,806.281311 160.918869,801.372559 
	C160.640854,799.290283 158.714371,798.776917 156.868851,798.232178 
	C152.371262,796.904785 150.137970,793.877502 151.145523,789.309204 
	C152.041550,785.246643 151.659119,781.421875 150.979858,777.492554 
	C150.780777,776.340820 150.589737,775.106079 151.010803,774.032715 
	C152.359512,770.594971 151.276947,769.150635 147.657700,768.224976 
	C144.309937,767.368591 141.896225,764.402649 139.141800,762.312561 
	C137.012268,760.696594 137.685303,758.122559 138.903687,755.585266 
	C139.787903,753.782471 142.707092,754.321655 142.132019,751.522339 
	C139.093643,748.894897 137.560760,745.214844 133.849777,743.276978 
	C133.059784,743.075989 132.623062,742.908447 131.894470,742.520020 
	C129.558945,740.291992 127.898636,738.046326 127.623474,735.053223 
	C127.506935,733.785522 126.816505,732.856506 125.810570,732.213074 
	C119.491341,728.170227 118.170128,722.038391 118.414085,714.864380 
	C117.711494,713.791260 117.289322,712.961792 116.807533,711.775696 
	C116.491768,710.425903 115.917831,709.642273 115.075348,709.069702 
	C110.898468,706.230286 110.009918,702.473267 111.889771,697.603882 
	C111.690636,696.481079 111.506767,695.687866 110.318291,695.215088 
	C109.615799,694.894287 109.237862,694.658875 108.646988,694.133789 
	C107.870842,693.018311 107.396797,692.039246 107.436897,691.080322 
	C107.669731,685.512695 105.114365,681.466309 102.091415,676.836731 
	C99.392624,672.703552 97.941986,667.350403 95.294525,662.778503 
	C93.784698,660.171204 95.251419,657.286438 96.109695,654.337219 
	C92.731918,655.038025 90.511971,654.538513 91.911362,650.495667 
	C92.688797,648.249695 91.690392,646.688904 90.062714,645.262756 
	C87.689819,643.183716 85.303642,641.104553 83.225769,638.247314 
	C82.975464,638.005005 82.957352,637.526123 82.958229,637.286499 
	C82.240227,636.236206 82.019730,635.268127 82.129745,633.852417 
	C83.032616,631.885254 83.766212,630.230408 84.564117,628.609314 
	C85.333519,627.046204 85.179283,626.137512 83.723747,624.872314 
	C79.002602,620.768738 78.900070,616.111572 83.540070,610.857056 
	C80.890228,611.148254 78.500900,612.217468 76.221054,610.236877 
	C75.724205,609.918152 75.542061,609.765137 75.175888,609.306519 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M68.708206,483.848297 
	C70.106598,485.700623 70.215660,487.501984 68.905754,489.077118 
	C66.243896,492.277863 66.638412,495.006348 69.660873,497.733246 
	C70.600479,498.580963 71.461273,500.176636 70.081825,501.241486 
	C67.252930,503.425232 68.600899,505.971344 69.061569,508.493256 
	C69.326790,509.945282 70.352722,511.313965 69.824295,512.845154 
	C68.410576,516.941650 68.166451,519.824829 74.101204,518.164734 
	C75.732262,517.708496 76.816177,519.137939 76.942444,520.893860 
	C77.069275,522.657410 76.842033,524.716553 74.781319,524.749451 
	C70.669266,524.815186 70.921158,527.385254 70.982323,530.161621 
	C71.055611,533.488342 71.067963,536.820251 70.967758,540.145630 
	C70.901855,542.332703 70.712730,544.593445 67.402328,544.989868 
	C67.010300,545.002441 66.972389,544.515869 66.961914,544.272095 
	C55.918514,534.452271 57.951954,521.821167 58.991203,509.488068 
	C59.344627,505.293915 58.658421,501.208557 58.359138,496.673401 
	C58.225170,495.718292 58.302879,495.181305 58.724709,494.314331 
	C58.944443,493.966370 58.983459,493.985748 59.000801,493.998901 
	C59.879375,493.299194 60.026093,492.324951 60.080673,490.990112 
	C60.355026,490.091278 60.679180,489.561798 61.286224,488.844727 
	C62.455185,487.856812 63.499485,487.193481 64.412849,485.929077 
	C64.788574,485.577972 64.951141,485.450867 65.376427,485.160828 
	C66.576927,484.586884 67.397789,483.921906 68.708206,483.848297 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M82.970398,638.432983 
	C87.517036,639.319275 89.574905,643.775940 93.267250,646.233887 
	C94.749710,647.220825 94.233963,649.571350 93.176628,651.255005 
	C92.675690,652.052673 91.725624,652.963440 93.099174,653.672058 
	C93.956039,654.114197 94.637268,653.454651 94.970146,652.582336 
	C95.380470,651.507019 96.148232,650.789795 97.210335,651.494751 
	C98.322418,652.232788 98.361221,653.801697 97.806664,654.656799 
	C94.434807,659.855957 96.080856,663.958496 100.273834,667.654785 
	C100.507195,667.860535 100.714005,668.214722 100.742020,668.517517 
	C101.261925,674.138367 104.478745,678.199402 107.989372,682.351379 
	C110.413086,685.217957 107.190865,689.397461 109.008591,693.149536 
	C108.914551,694.112549 108.590729,694.520020 107.640556,694.850586 
	C106.117844,695.060242 105.125511,694.718872 103.952026,693.794128 
	C102.007889,689.158630 100.075729,684.929626 96.641006,681.160034 
	C96.029823,680.603210 95.729988,680.239807 95.314331,679.527100 
	C94.449326,676.965759 94.769569,674.755432 95.357094,672.544128 
	C95.776245,670.966675 96.279579,669.340393 94.006805,668.184631 
	C93.344833,667.750793 93.008568,667.452698 92.517014,666.842590 
	C91.802605,665.642395 91.588768,664.537231 90.519272,663.553589 
	C89.517509,662.424316 89.243507,661.403137 89.501068,659.918213 
	C89.510307,659.375854 89.512581,659.159790 89.535484,658.622009 
	C89.722031,657.676208 89.825905,657.056580 89.182068,656.266785 
	C88.941483,656.059509 88.997414,656.000000 88.967636,656.029419 
	C87.943405,655.126160 87.578094,653.781616 86.585831,652.448608 
	C85.919830,651.167236 85.682327,650.089661 85.309433,648.718445 
	C84.751297,647.030579 84.478218,645.566040 83.222076,644.219482 
	C82.988647,642.286987 82.976990,640.573975 82.970398,638.432983 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M58.631416,495.883545 
	C61.110153,500.334198 61.663952,505.224609 60.440674,509.504486 
	C58.442863,516.494263 60.960560,523.185120 60.364315,529.932983 
	C59.827103,536.012817 64.582779,538.945862 66.962852,543.654846 
	C62.267365,542.704651 61.136429,537.707397 57.350998,535.045410 
	C56.995174,535.004150 56.990753,535.011169 56.984917,535.014282 
	C54.416206,532.303162 53.694916,528.832642 53.841053,525.385315 
	C54.168011,517.672913 54.727074,509.994690 53.946373,502.260742 
	C53.649021,499.315063 55.110550,496.817261 58.631416,495.883545 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M68.747124,559.337830 
	C72.464729,563.797119 77.323448,567.664246 74.726440,575.153809 
	C73.112221,579.809082 73.876015,585.289001 73.284424,590.699402 
	C72.818474,591.125488 72.640602,591.250977 72.196655,591.187256 
	C71.936974,590.565491 71.943375,590.132935 71.956192,589.376099 
	C70.540627,587.151917 70.486580,584.864502 70.072189,582.328491 
	C69.994614,581.555481 69.995308,581.111084 69.999222,580.333313 
	C69.986359,579.401917 69.804504,578.864807 69.205605,578.203064 
	C68.985405,577.254639 68.975380,576.506836 68.963966,575.385132 
	C68.936920,573.798950 69.237564,572.539673 68.234177,571.215820 
	C68.069099,568.270630 69.452713,565.699890 68.939575,562.495972 
	C68.404999,561.349426 68.200844,560.559570 68.747124,559.337830 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M75.772690,609.247742 
	C78.080872,609.063171 80.216919,612.244141 82.305244,608.628723 
	C82.909515,607.582520 84.546593,608.576538 85.285240,609.450562 
	C86.272964,610.619324 85.312729,611.939087 84.571434,612.521301 
	C82.345390,614.269592 80.369659,615.416565 81.633606,619.185059 
	C82.573288,621.986755 83.025391,624.019165 86.342163,624.410767 
	C87.600433,624.559204 88.336609,626.160645 87.164452,627.079895 
	C85.194443,628.625000 85.621140,631.415649 83.360069,632.935791 
	C80.623070,631.438049 80.204544,628.498779 78.232346,626.238464 
	C77.985550,624.937073 77.976448,623.871704 77.971367,622.406860 
	C77.986382,620.030151 78.366707,617.954773 76.279503,616.230103 
	C76.039459,615.543335 76.024742,615.101868 76.007202,614.329224 
	C75.819115,612.500427 74.507446,611.117737 75.772690,609.247742 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M77.992889,626.416870 
	C81.630798,626.539185 81.646484,629.912292 82.968559,632.651611 
	C83.005478,634.083740 82.998909,635.160217 82.975723,636.641724 
	C79.697624,634.422485 77.515320,631.273254 77.992889,626.416870 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M85.693657,647.784058 
	C87.171928,648.835571 86.959412,650.091309 86.995224,651.635620 
	C87.263695,653.138916 87.685898,654.261292 87.194244,655.770386 
	C86.931686,656.971191 86.628120,657.702209 85.319359,657.948181 
	C84.473579,657.458008 83.936447,656.928955 83.200096,656.199341 
	C82.996666,656.002991 82.999908,656.000000 82.998123,656.001221 
	C83.331284,653.603760 83.666245,651.205017 84.004410,648.398315 
	C84.405647,647.645752 84.866051,647.504211 85.693657,647.784058 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M69.733124,582.235229 
	C73.175529,583.311096 70.495010,586.466919 71.971359,588.681824 
	C69.098610,587.627625 69.342728,585.027405 69.733124,582.235229 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M72.754578,593.332397 
	C76.203629,593.793396 75.911949,595.945740 75.037033,598.655151 
	C74.276505,599.453735 73.826691,599.023682 73.201691,598.252563 
	C72.989662,597.563965 72.980255,597.127930 72.972565,596.364807 
	C72.403206,595.331055 72.216019,594.547546 72.754578,593.332397 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M76.080994,616.349548 
	C79.540100,616.644287 79.343643,618.941956 78.246017,621.763733 
	C76.523842,620.535522 77.012207,618.379211 76.080994,616.349548 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M68.998871,558.662231 
	C68.994995,559.767212 68.987671,560.536804 68.958115,561.692627 
	C65.891624,560.895386 67.040787,558.610474 67.745544,556.257996 
	C68.878624,556.551819 68.991142,557.416626 68.998871,558.662231 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M85.993797,647.639038 
	C85.556000,648.000122 85.113525,647.997803 84.339340,647.992859 
	C82.839859,647.160461 83.030304,645.909424 82.998032,644.364075 
	C85.047134,644.133362 85.980812,645.282349 85.993797,647.639038 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M75.747963,608.999329 
	C76.162048,610.522705 76.324089,612.045410 76.245262,613.783020 
	C74.038246,612.969055 73.553444,611.445068 74.737793,609.224487 
	C75.159874,609.000122 75.327896,608.999390 75.747963,608.999329 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M73.006485,592.662598 
	C73.004494,593.755371 72.999733,594.515625 72.984627,595.656799 
	C70.172989,595.303589 70.935120,593.355652 71.679474,591.248657 
	C72.108223,590.998474 72.285866,590.998962 72.729919,590.999756 
	C72.998795,591.443359 73.001259,591.886780 73.006485,592.662598 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M68.011360,571.358276 
	C70.335022,571.433289 70.263954,572.854492 69.226944,574.787842 
	C67.822456,574.154663 68.026337,572.905273 68.011360,571.358276 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M73.004303,598.350098 
	C73.494461,598.259766 73.989845,598.519470 74.742767,598.889648 
	C74.996002,599.452148 74.991699,599.904358 74.969551,600.695801 
	C73.527023,600.903320 72.986557,600.036133 73.004303,598.350098 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M74.967125,601.353394 
	C75.483139,601.247559 76.247658,601.337280 76.455612,601.725281 
	C76.779579,602.329712 76.198730,602.738159 75.293900,602.958496 
	C74.988434,602.560913 74.977280,602.121765 74.967125,601.353394 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M69.019066,578.298462 
	C69.891533,578.187012 71.035835,578.153625 70.221474,579.823975 
	C69.682533,579.531494 69.362610,579.062988 69.019066,578.298462 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M67.047585,548.280396 
	C67.930542,548.180664 69.050987,548.189880 68.219223,549.826782 
	C67.685730,549.526245 67.372559,549.052551 67.047585,548.280396 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M62.252190,488.810303 
	C61.781429,489.526062 61.508823,490.011963 61.122086,490.752350 
	C60.585655,491.519073 60.229683,492.106049 59.237007,492.161530 
	C57.797535,489.239868 60.814522,486.512726 59.017738,483.369751 
	C58.985950,482.123993 59.025150,481.230164 58.238361,480.223724 
	C58.014328,479.240417 58.014919,478.499969 58.016273,477.389282 
	C59.109314,475.611450 59.173649,474.111176 58.210777,472.268005 
	C57.688080,471.883331 57.432011,471.692352 57.117813,471.212311 
	C56.436546,467.453217 53.907341,468.108337 51.262108,468.225159 
	C50.995533,468.009125 50.999718,468.000854 50.995384,468.002869 
	C50.991844,466.287964 50.992634,464.571045 50.990776,462.424988 
	C50.988125,461.995850 50.995029,461.497986 50.993217,461.249023 
	C50.380486,459.124664 51.429829,457.814728 52.766838,456.263245 
	C53.003830,455.524261 52.991886,455.039948 52.938503,454.192169 
	C52.962299,453.669586 53.027538,453.510468 53.234097,453.135620 
	C56.240284,451.327454 58.647297,449.476349 60.378929,446.417725 
	C62.300793,443.023071 65.506714,442.776520 68.824036,445.635376 
	C68.977814,447.070709 68.987610,448.139740 68.921104,449.939178 
	C67.548843,452.698700 66.749832,454.698090 69.381744,456.791016 
	C69.855026,457.717957 69.912003,458.389435 69.771866,459.397766 
	C69.013153,460.978577 68.119949,462.223480 69.544205,463.926941 
	C69.899712,464.880829 69.919975,465.542511 69.597687,466.526855 
	C68.831604,468.078491 68.071526,469.244232 67.302307,470.795532 
	C66.515434,474.193237 67.829369,477.124115 67.993553,480.584229 
	C68.136696,482.716522 67.527466,484.178833 66.229843,485.738037 
	C65.985802,485.985535 65.505287,486.006500 65.265106,486.018738 
	C64.119949,486.833588 63.128132,487.548431 62.252190,488.810303 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M59.021629,495.656921 
	C55.733246,498.498260 54.640388,500.894714 55.991253,505.631287 
	C57.237514,510.000946 56.417744,515.408508 55.726685,520.134521 
	C54.995407,525.135559 55.893806,529.584229 56.979324,534.613770 
	C55.029091,535.085449 53.005112,534.597534 51.309959,536.176025 
	C50.565792,536.868896 49.750401,538.360718 48.482761,537.137329 
	C47.590855,536.276611 47.700676,534.744446 48.263279,533.841919 
	C52.300819,527.364990 50.092037,521.861572 45.866154,516.572327 
	C44.571648,514.952087 45.509731,513.160461 46.259968,511.900116 
	C48.802105,507.629639 50.046638,503.515472 48.272793,498.441681 
	C47.531506,496.321350 48.608925,492.818390 52.383602,494.837006 
	C54.835140,496.148010 56.397823,494.979401 58.601555,494.014557 
	C58.967445,494.417175 58.990452,494.867950 59.021629,495.656921 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M51.036758,468.359924 
	C51.606522,467.833405 52.261669,467.745575 52.819962,467.464996 
	C54.311985,466.715179 55.671894,464.549774 57.373375,465.776398 
	C58.902168,466.878510 56.704506,468.673370 57.010025,470.622284 
	C54.598854,471.347992 51.400753,473.752350 51.036758,468.359924 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M58.000076,472.416809 
	C60.249496,473.302795 60.693596,474.837646 58.309219,476.858002 
	C58.011253,475.624329 58.005466,474.229553 58.000076,472.416809 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M58.011604,480.320557 
	C59.611160,480.223389 60.369064,480.944031 59.256458,482.797363 
	C58.130512,482.456909 58.007088,481.585510 58.011604,480.320557 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M58.951107,492.343506 
	C59.449245,491.745880 59.960949,491.481812 60.740303,491.112274 
	C60.948734,492.140442 61.163746,493.398468 59.358208,493.953156 
	C59.000320,493.567078 58.982498,493.122101 58.951107,492.343506 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M76.145584,407.653137 
	C75.979500,408.442139 75.983131,408.887024 75.997055,409.665527 
	C76.144913,410.140930 76.282478,410.282776 76.394577,410.719849 
	C76.087296,411.735413 75.489731,412.302307 75.304703,413.387573 
	C75.145111,414.282288 74.977859,414.865936 74.573822,415.702820 
	C74.179222,416.585693 74.039368,417.209076 74.057358,418.197388 
	C74.145889,419.231720 74.026665,419.905823 73.660477,420.892395 
	C72.748787,422.146759 71.894112,423.033142 72.010071,424.766174 
	C71.950058,429.445831 71.819534,433.735535 70.839798,438.324219 
	C68.407379,440.880188 66.458740,439.645874 64.575729,438.087036 
	C60.449322,434.670990 60.629078,434.716248 58.933090,440.007721 
	C58.141033,442.478943 58.159882,445.827454 54.185120,446.274719 
	C53.867374,446.090729 53.798191,446.168518 53.744820,446.172729 
	C54.540180,441.468628 55.887119,436.786896 54.510094,431.953583 
	C54.129082,430.616241 54.992439,430.210510 55.983566,429.854645 
	C57.206314,429.415588 57.915348,428.497589 57.955109,427.240265 
	C58.225311,418.695984 63.708679,410.462555 59.497555,401.638275 
	C59.316509,401.258911 59.343048,400.572235 59.574062,400.235107 
	C64.445435,393.126648 64.331490,384.305450 67.855270,376.347076 
	C69.098244,375.355896 70.115211,374.645477 70.416916,372.945618 
	C70.506165,371.006622 71.283768,369.621124 72.046173,367.882355 
	C73.234322,365.986420 74.635582,364.641510 76.562843,365.395721 
	C79.236839,366.442230 80.792305,364.986725 82.813812,363.359894 
	C83.661690,363.212250 84.148880,363.282104 84.938751,363.663483 
	C85.515984,364.351044 85.623260,364.847748 85.463142,365.751587 
	C84.216431,372.034210 81.348122,377.509064 80.972015,383.606934 
	C80.920891,384.435760 81.004784,385.429993 80.391861,385.961029 
	C74.808907,390.798370 77.300972,397.618439 76.011635,403.884277 
	C75.995979,405.294678 76.537720,406.264679 76.145584,407.653137 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M73.016876,367.660034 
	C73.141731,369.787354 71.343964,370.706940 71.035797,372.655334 
	C70.830391,373.125000 70.663536,373.252411 70.246384,373.188660 
	C69.527412,366.760803 71.570526,360.755066 71.698532,354.567108 
	C71.732246,352.937347 72.705368,350.900177 69.604225,351.669800 
	C67.881371,352.097321 66.465454,351.686981 66.274010,349.802979 
	C66.066544,347.761261 67.855247,348.109497 69.098228,347.992371 
	C70.156403,347.892670 71.001816,347.479248 71.825134,346.317505 
	C73.151657,344.712006 75.227417,345.449402 76.571671,343.745819 
	C75.196915,342.830536 73.710876,344.135254 72.269051,343.223724 
	C71.939232,340.140228 74.990364,338.544983 74.972580,335.333282 
	C74.433853,334.320953 74.198189,333.591034 74.480125,332.399536 
	C74.992416,331.645966 75.422096,331.385529 76.308426,331.255768 
	C78.541000,331.598297 78.922623,333.015686 78.865822,334.559845 
	C78.790962,336.595062 79.766022,337.959320 81.584198,339.301300 
	C83.196152,341.017303 84.818909,342.133942 86.417328,343.311310 
	C89.787331,345.793640 89.630142,347.177307 85.564224,349.890747 
	C84.374802,350.004272 83.497932,349.885254 82.452896,350.518982 
	C81.314514,351.303772 80.448746,351.929260 79.370224,352.826965 
	C75.451424,354.637817 73.594421,357.173279 74.830215,361.306458 
	C75.548569,363.709045 73.224182,365.194092 73.016876,367.660034 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M75.248787,331.764252 
	C75.010109,332.757294 74.992256,333.492950 74.971420,334.595520 
	C73.089134,334.945831 72.867416,337.006897 71.240448,337.811157 
	C70.882286,336.417206 71.317169,335.012482 72.133354,333.781372 
	C73.204247,332.166077 74.134384,331.100433 71.400719,329.802704 
	C68.051353,328.212708 71.185295,326.422729 71.976730,324.420105 
	C72.534546,323.793060 73.007492,323.504852 73.732391,323.101501 
	C74.474228,322.420074 74.964111,321.853790 75.677567,321.108154 
	C76.613861,321.219482 77.060905,321.776764 77.532791,322.774170 
	C77.726196,323.592865 77.727287,324.069214 77.527771,324.876831 
	C75.357376,326.874817 76.646042,329.570648 75.248787,331.764252 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M61.259571,365.719482 
	C55.308109,363.107422 58.593422,359.717865 60.715603,356.203186 
	C62.842571,359.045776 62.028114,362.250641 61.259571,365.719482 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M72.007179,343.378235 
	C72.488571,342.914032 72.970093,342.835388 73.446144,342.731689 
	C75.046150,342.383179 76.406525,340.254089 78.281990,342.027832 
	C78.922813,342.633881 79.170143,343.614746 78.582634,344.236145 
	C77.006989,345.902679 75.292114,347.499298 72.354584,346.034607 
	C72.006470,345.248535 72.005638,344.500397 72.007179,343.378235 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M69.747993,373.172729 
	C70.162956,372.997925 70.329826,372.998291 70.746979,372.998108 
	C71.037735,374.589081 70.837845,375.999390 68.378113,375.994873 
	C68.506592,375.119873 69.003242,374.233887 69.747993,373.172729 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M50.007271,583.351807 
	C50.421700,583.041687 50.882030,583.021912 51.687309,583.030029 
	C51.940609,583.714111 51.850269,584.472839 51.042912,584.505188 
	C50.725018,584.517883 50.383781,583.947266 50.007271,583.351807 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M52.659023,456.012817 
	C53.238945,457.776611 52.420780,459.138702 51.150192,460.693176 
	C47.664509,458.348907 49.284519,456.999146 52.659023,456.012817 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M49.087692,453.178650 
	C49.613487,453.067291 50.141773,453.260529 50.845528,453.677673 
	C50.092503,455.076935 49.475929,454.812744 49.087692,453.178650 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M53.862450,446.483856 
	C56.757374,443.930634 57.638672,440.821075 58.009293,437.443726 
	C58.175106,435.932739 57.259136,433.762726 59.443188,433.253326 
	C61.663948,432.735352 63.831802,433.718414 64.727905,435.864929 
	C65.636200,438.040619 67.120171,438.806885 69.594536,438.977539 
	C70.335907,439.374573 70.490974,439.838135 70.222427,440.686890 
	C69.848030,442.501923 69.688011,444.001709 69.248001,445.751587 
	C65.049927,444.448730 61.775291,443.697357 60.475666,449.460266 
	C59.878578,452.107971 57.152237,453.443024 53.740120,452.925903 
	C54.123581,450.952423 54.132225,448.925995 53.862450,446.483856 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M234.106171,922.296997 
	C235.770523,922.543640 237.785278,922.485840 238.961761,924.626709 
	C237.235687,924.533264 235.224182,924.566772 234.106171,922.296997 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M101.117126,251.869995 
	C101.193130,249.814835 101.560982,247.830978 101.914742,245.457932 
	C102.069229,244.933319 102.237808,244.797882 102.672417,244.848038 
	C102.870712,247.220657 104.749825,249.529785 102.266861,251.795547 
	C101.806961,251.986694 101.607971,251.963989 101.117126,251.869995 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M73.303673,316.949738 
	C72.631615,317.032776 72.273994,316.944794 71.971954,317.037628 
	C67.720047,318.344666 63.995262,319.212860 65.798203,312.320312 
	C66.192940,310.811279 65.230789,310.402863 63.050293,310.659546 
	C61.688625,312.087616 60.615414,311.499237 59.325275,310.228363 
	C59.053333,309.949158 59.033642,309.953461 59.052650,309.948425 
	C59.321938,309.469910 59.572216,308.996429 59.923599,308.273407 
	C63.933311,305.325226 65.238724,299.728058 70.819794,298.378479 
	C71.568146,297.892334 72.036674,297.607819 73.025101,297.444122 
	C74.372932,299.099640 74.902008,300.811768 76.958076,301.348206 
	C78.840942,301.839447 79.081825,303.576965 79.338486,305.551910 
	C79.467300,307.039215 79.694115,308.163269 79.984497,309.631775 
	C80.558151,314.732758 77.126396,315.821320 73.303673,316.949738 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M70.711044,298.126526 
	C67.263123,301.038483 65.402664,305.978668 60.395851,308.011780 
	C60.018227,307.271362 60.011753,306.518860 60.014725,305.390930 
	C62.002541,304.997467 62.567245,303.348969 63.598843,302.109680 
	C65.333282,300.026123 66.962952,297.540741 70.711044,298.126526 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M76.657768,291.202484 
	C79.146355,292.801086 77.103439,294.021423 76.163727,295.714661 
	C75.664406,297.085175 74.891380,297.568909 73.451904,297.462097 
	C73.108238,297.326721 73.048920,297.227295 73.031281,297.173523 
	C73.004501,294.538422 75.566429,293.485321 76.657768,291.202484 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M87.007843,282.606506 
	C86.586510,282.903625 86.102448,282.920441 85.258179,282.877441 
	C85.306656,281.513855 85.793190,280.534332 87.007843,282.606506 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M79.260056,310.244293 
	C78.695396,308.882935 78.352669,307.791595 78.006165,306.351135 
	C82.238724,303.992493 83.424599,300.586090 82.474617,296.210968 
	C81.309944,290.847137 86.069962,288.830933 88.762497,285.260620 
	C90.570847,282.604614 93.102814,281.615845 95.789749,280.277344 
	C99.461151,278.115143 102.911285,276.205811 106.665787,274.134338 
	C110.370583,270.962128 114.822723,269.277313 117.578201,265.420074 
	C118.236168,264.498993 119.501091,264.210632 121.051224,264.767242 
	C122.041794,265.853363 122.196884,266.869598 122.148727,268.315186 
	C122.045967,268.943817 121.982368,269.188385 121.768677,269.774536 
	C118.552353,274.017273 115.020821,277.562012 112.982368,282.228912 
	C111.901451,284.703583 109.669220,285.575195 107.169128,284.654175 
	C104.562927,283.694061 101.938988,283.885559 99.304283,283.930176 
	C95.385803,283.996490 93.552773,285.705933 94.200584,289.429138 
	C95.107590,294.641998 93.368523,299.040497 91.562256,303.747040 
	C90.280777,307.086212 91.747482,311.532745 85.571732,311.336395 
	C84.063881,311.288483 84.593567,315.171967 84.100494,317.589661 
	C82.760376,320.317993 80.977272,322.405029 81.665390,325.569000 
	C82.037476,327.279907 80.613953,328.558807 78.250244,328.431213 
	C77.056099,327.637939 76.682381,326.742981 76.813309,325.325562 
	C76.966927,324.534637 76.975761,324.097626 76.988770,323.332825 
	C75.839218,320.648529 76.715714,318.524567 77.827850,316.480865 
	C78.848312,314.605530 79.981125,312.796173 79.260056,310.244293 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M88.610901,285.010498 
	C88.454910,286.360443 88.277779,288.319580 87.273102,288.941162 
	C83.152863,291.490265 83.509697,295.237274 84.018394,299.082062 
	C84.565895,303.220123 83.856110,306.255310 78.358612,305.962402 
	C77.779137,304.307159 78.837997,302.108246 75.202866,302.517090 
	C72.459595,302.825623 73.174278,299.882416 73.083389,297.691162 
	C73.884979,296.977203 74.661713,296.627655 75.730927,296.147858 
	C79.242035,296.047699 81.644928,295.809418 80.364998,291.236786 
	C79.500580,288.148621 83.490753,285.305573 88.610901,285.010498 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M901.766357,148.150940 
	C903.576294,146.681549 905.144165,146.369324 905.839294,148.659195 
	C906.390381,150.474472 905.767517,151.806168 903.509644,152.107056 
	C899.341125,152.662582 897.547974,157.611298 893.032104,157.879684 
	C892.041504,157.938553 891.686584,159.818726 892.843079,160.727066 
	C893.983948,161.623169 895.413757,162.625137 896.781250,161.087799 
	C897.876526,159.856598 898.676147,158.268372 900.534973,158.099930 
	C902.171631,157.951660 904.297791,157.698441 904.526001,159.868439 
	C904.695801,161.483353 904.414368,163.674988 901.934021,163.959152 
	C900.056213,164.174286 897.528320,163.529144 898.818420,167.028748 
	C899.598450,169.144577 898.852783,172.199081 903.111694,171.268570 
	C906.095703,170.616638 908.577942,175.024063 907.633728,179.366898 
	C907.116150,181.747589 907.010254,183.559738 909.680054,184.915283 
	C910.038940,184.962357 910.126465,185.425079 910.156372,185.658630 
	C910.522888,187.559799 909.454651,188.227158 907.790344,188.776855 
	C903.041565,187.704285 903.199646,183.761032 902.296082,180.515411 
	C901.988159,179.409393 902.580933,177.266693 902.010742,177.202728 
	C896.721802,176.609344 896.228210,170.417114 891.970093,168.856049 
	C889.893555,168.094742 888.815979,166.522293 887.557007,164.536926 
	C887.273804,163.757019 887.198364,163.291672 887.225159,162.467712 
	C888.583252,159.654037 888.830322,157.570740 886.491516,155.244705 
	C884.403809,153.168457 885.491821,151.416168 888.648438,150.807617 
	C893.650024,152.514755 897.968018,152.452896 901.766357,148.150940 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M905.182373,209.722198 
	C904.917114,210.148941 904.900269,210.330597 904.899292,210.785431 
	C905.080566,211.247635 905.245972,211.436630 905.541748,211.980392 
	C906.052917,213.013336 906.213013,213.755402 906.329224,214.901825 
	C906.410461,217.379913 906.168945,219.422714 905.143433,221.717880 
	C904.587769,222.783600 904.091370,223.402481 903.099731,224.080750 
	C901.856201,224.075073 901.373230,223.468033 900.902710,222.334259 
	C900.536682,218.985458 899.988525,216.089630 898.811279,213.323196 
	C898.295105,212.110199 897.724487,210.770172 897.958191,209.495041 
	C898.972534,203.961853 898.064087,198.820847 895.790588,193.689011 
	C894.833252,191.528122 894.671631,188.800232 896.687195,186.705002 
	C899.113647,184.182663 895.729858,181.728455 896.879089,178.919037 
	C901.048096,177.437607 902.018494,180.418716 902.872375,182.993515 
	C904.324158,187.370895 905.129028,191.879959 903.747681,196.489426 
	C903.365234,197.765686 903.428101,199.078156 903.726440,200.766937 
	C903.993591,201.646042 904.103455,202.151367 904.220215,203.031342 
	C904.473267,204.991135 904.125977,206.681839 905.237793,208.461395 
	C905.395203,209.013168 905.413025,209.245132 905.182373,209.722198 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M905.326172,215.084503 
	C905.158813,214.039108 905.076294,213.346893 904.957458,212.307892 
	C905.140381,211.845963 905.359619,211.730820 905.902405,211.797943 
	C911.343079,211.973999 914.101990,208.216782 917.593140,205.005310 
	C919.717712,206.655106 920.969543,208.609985 920.960205,211.562592 
	C920.060486,215.209625 919.725037,218.660812 917.162231,221.658234 
	C916.871094,221.918259 916.433899,221.934464 916.218140,221.970612 
	C915.655884,222.327866 915.271667,222.593826 914.498291,222.864960 
	C912.373291,222.936523 911.393494,221.978622 911.126038,220.293961 
	C910.862000,218.631073 910.032043,217.575607 907.966919,217.244720 
	C906.649658,216.888199 905.876282,216.359512 905.326172,215.084503 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M906.918457,188.222809 
	C908.398132,188.010529 909.194031,187.358246 910.008179,186.166656 
	C912.403015,187.311905 912.852356,191.354797 916.807312,190.283539 
	C917.332458,190.141266 917.833374,191.012634 917.759155,191.759445 
	C917.647583,192.882736 916.959045,193.692108 915.919250,193.800461 
	C913.812866,194.019974 913.023071,194.882294 913.641296,197.022629 
	C913.789673,197.536606 913.455750,198.510880 913.020996,198.820892 
	C909.844299,201.086151 910.154907,205.274124 907.516418,208.056473 
	C906.995972,208.032013 906.801758,207.937225 906.404785,207.590256 
	C905.768494,206.598877 905.527405,205.776535 905.055054,204.710205 
	C904.589722,203.640549 904.176575,202.900635 903.597778,201.876953 
	C903.354126,201.133728 903.357483,200.693817 903.617920,199.950714 
	C908.852661,197.777313 907.650757,194.148773 906.299805,190.138672 
	C906.277039,189.305817 906.404053,188.853378 906.918457,188.222809 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M920.139038,212.181610 
	C920.616760,209.445984 917.817322,208.040634 917.975220,205.330215 
	C917.949158,204.952682 918.403564,204.868347 918.632996,204.840729 
	C924.331482,203.288330 925.580383,203.945404 927.145874,209.561264 
	C927.179871,210.747345 927.136780,211.502884 927.091919,212.636917 
	C926.919861,213.151154 926.749695,213.286896 926.260864,213.492767 
	C924.097351,213.076004 922.143555,213.127914 920.139038,212.181610 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M927.454468,209.719223 
	C925.450745,207.219391 924.122009,203.787613 919.286499,204.876007 
	C920.259949,202.795258 922.593811,201.444138 922.755798,198.451462 
	C925.489990,200.211899 923.421326,204.569992 927.568115,205.046097 
	C927.814941,206.558945 927.750427,208.002792 927.454468,209.719223 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M906.431641,206.722839 
	C906.896301,207.069977 906.949036,207.282410 907.096008,207.810837 
	C906.636169,208.700699 906.082214,209.274567 905.231079,209.907867 
	C904.933960,209.967270 904.658936,209.600281 904.587402,209.382416 
	C904.228760,207.845596 904.563110,206.889328 906.431641,206.722839 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M906.060425,211.752655 
	C906.008667,211.987854 905.791382,211.995514 905.247620,211.982147 
	C904.921204,211.961121 904.807068,211.510056 904.861084,211.284348 
	C905.241699,211.214142 905.568237,211.369629 906.060425,211.752655 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M927.542542,214.834396 
	C927.891235,215.831085 927.927429,216.554977 927.943604,217.641022 
	C927.700867,218.168381 927.478088,218.333618 926.845825,218.576523 
	C924.153320,218.161072 923.878845,216.833649 925.251953,214.736481 
	C926.149048,214.351944 926.692505,214.370010 927.542542,214.834396 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M927.101807,224.662170 
	C926.519592,224.433090 926.005798,223.872177 925.249939,223.152222 
	C925.007874,222.993179 924.977966,222.507935 924.967285,222.265076 
	C924.656738,221.131760 925.183472,220.716156 926.246704,220.367767 
	C926.837708,220.398743 927.069275,220.464233 927.559326,220.781677 
	C927.601990,222.132538 927.386108,223.231445 927.101807,224.662170 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M927.977600,220.774902 
	C927.817810,221.033615 927.320312,220.997055 927.071777,220.975616 
	C926.312317,220.269684 926.208923,219.516479 926.652039,218.367172 
	C927.037231,218.021347 927.214722,218.014496 927.657837,218.005402 
	C929.106140,218.746277 928.625061,219.630951 927.977600,220.774902 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M927.888794,214.806122 
	C927.427612,215.079956 927.000305,215.052719 926.250488,215.021606 
	C925.574585,214.619370 925.422363,214.154968 925.661865,213.295868 
	C926.058594,212.974396 926.264587,212.981689 926.780396,213.002197 
	C927.367615,213.511932 927.645142,214.008469 927.888794,214.806122 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M860.879272,116.087196 
	C860.939148,116.481247 860.968384,116.910202 861.054565,117.657120 
	C861.065491,118.426956 861.019531,118.878845 860.965820,119.671333 
	C862.183716,121.754242 863.802673,122.288963 865.969299,122.322975 
	C871.134460,122.404060 876.241699,122.105919 880.669617,126.246933 
	C883.167419,128.582916 887.166199,128.035324 890.803345,126.356323 
	C891.796448,126.093430 892.511169,126.040947 893.581055,125.951202 
	C895.489319,126.259026 897.610168,125.211700 898.526978,126.918869 
	C899.558777,128.840134 897.663879,129.927643 896.355347,130.817398 
	C893.810364,132.547974 896.271973,134.433868 896.019165,136.590088 
	C893.682373,138.709366 891.668457,141.425797 887.854736,139.395050 
	C887.001343,138.293961 886.851501,137.326797 886.763306,135.990799 
	C885.385559,131.185181 882.845886,134.540970 880.443481,134.844269 
	C879.630554,135.026611 879.152771,135.057449 878.308594,134.985779 
	C877.714294,134.853180 877.483093,134.774475 876.937134,134.510590 
	C874.163696,130.600967 870.462646,129.465897 865.897888,129.344208 
	C863.447510,129.129669 861.480835,128.619644 859.567932,126.960785 
	C858.118652,124.640549 857.572693,122.378838 856.892639,119.758118 
	C856.798401,118.848236 856.821411,118.327240 857.001099,117.428406 
	C857.299316,116.555351 857.597717,116.132423 858.342529,115.603226 
	C859.209045,115.317719 859.714905,115.361900 860.482788,115.830475 
	C860.758362,116.100365 860.848633,116.122101 860.879272,116.087196 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M901.603882,147.901733 
	C899.605408,153.584274 896.785889,154.531677 889.350952,151.977722 
	C887.630249,149.605194 890.015259,149.187943 891.288696,147.814667 
	C891.805969,147.467560 892.027283,147.347687 892.607239,147.100311 
	C894.522461,146.576294 896.014099,146.773956 897.773315,147.673279 
	C899.134521,147.895142 900.174438,147.906738 901.603882,147.901733 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M887.718140,139.274872 
	C891.150330,140.840683 892.476929,136.163879 895.658325,136.982895 
	C896.027893,136.969299 896.016357,136.982895 896.023315,136.978546 
	C897.059814,138.307480 897.138428,139.755753 896.309448,141.671753 
	C896.067322,142.059006 896.020386,142.514465 895.991333,142.741562 
	C894.521484,144.210022 890.813660,143.646866 892.286987,147.623444 
	C892.102478,147.892471 892.051270,147.946228 892.025635,147.973114 
	C887.352173,147.860565 886.052979,145.497269 887.718140,139.274872 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M892.524048,147.921280 
	C892.032654,145.775955 889.124268,144.512726 890.497070,141.703949 
	C892.150208,141.393799 893.399780,143.646790 895.594055,142.972305 
	C895.604126,144.850281 897.054077,145.903015 898.004395,147.554871 
	C896.378357,147.905731 894.661987,147.927902 892.524048,147.921280 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M912.638428,167.065063 
	C912.991272,168.395645 912.989990,169.801468 912.976868,171.630829 
	C910.063538,170.786850 910.483643,169.059204 912.638428,167.065063 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M896.416138,141.968460 
	C896.044556,140.646301 896.021790,139.233612 896.014648,137.397552 
	C899.003418,138.200058 898.462158,139.952393 896.416138,141.968460 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M946.976074,254.537201 
	C946.111633,255.184677 945.325989,255.473404 944.244629,255.883057 
	C940.048035,255.441727 937.902771,256.948181 937.969116,261.070953 
	C937.987488,262.212555 937.165161,262.823486 936.205200,263.778564 
	C935.030945,264.860687 933.957520,265.092804 932.388550,264.528870 
	C931.645569,263.842957 931.504395,263.307739 931.860840,262.380371 
	C935.800415,259.957764 933.987915,257.793732 932.117126,255.780090 
	C931.121216,254.708252 929.187317,255.085022 928.141357,253.340637 
	C927.965820,252.598663 927.972778,252.155334 927.975342,251.380798 
	C929.768311,251.234726 931.101990,250.288895 932.512939,249.356354 
	C933.729370,248.552399 935.377625,247.333923 936.617188,248.483459 
	C939.011963,250.704376 941.813416,249.809509 944.376953,249.890015 
	C947.902100,250.000717 948.429321,251.330368 946.976074,254.537201 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M925.605957,212.770477 
	C925.876343,213.422577 925.900024,213.878067 925.925903,214.675644 
	C924.791199,216.122299 924.705017,217.107132 926.558472,217.966736 
	C926.877747,218.760956 926.895752,219.493713 926.868530,220.590302 
	C926.312744,221.157166 925.802185,221.360184 925.124146,221.792725 
	C922.565552,222.007706 920.174622,221.993164 917.327393,221.948456 
	C917.716248,218.762085 918.561340,215.605896 919.671631,212.191406 
	C921.678894,212.699860 923.843567,209.890656 925.605957,212.770477 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M913.722900,246.773239 
	C913.959473,247.475433 913.975464,247.910080 914.004028,248.670670 
	C914.503418,251.065964 914.062988,252.938568 912.552490,254.882507 
	C910.302246,255.585205 909.266846,254.298691 909.261108,252.763611 
	C909.247742,249.172668 906.624207,248.100266 903.916992,246.515823 
	C903.286377,245.692123 903.126038,245.044876 903.163696,244.030182 
	C903.471741,242.724091 904.196350,241.878891 904.072754,240.461304 
	C904.041809,239.462372 904.155823,238.807724 904.610596,237.884125 
	C905.718689,236.721344 906.797241,236.271896 908.407471,236.409882 
	C910.996460,237.066467 911.898132,238.553391 912.031433,240.812378 
	C912.147339,242.775879 912.805054,244.660233 913.722900,246.773239 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M909.131592,236.743134 
	C907.835571,237.289154 906.761047,237.612869 905.349731,237.973053 
	C903.454956,236.032074 902.757751,233.456772 899.974670,232.353394 
	C897.511658,231.376907 898.556885,228.643570 898.058472,226.292068 
	C897.917847,225.138306 897.980347,224.367386 898.330872,223.234772 
	C899.170044,221.918045 900.045227,221.375244 901.605713,221.749512 
	C902.140747,222.522858 902.327759,223.083740 902.758606,223.821091 
	C903.372375,224.253296 903.679749,224.568588 904.061829,225.247131 
	C904.516846,227.387146 906.148193,227.606857 907.763184,228.372894 
	C908.580933,229.021408 908.867065,229.618057 908.946655,230.665497 
	C908.954773,232.403030 908.952454,233.798050 908.955872,235.611603 
	C909.092163,236.193695 909.222656,236.357269 909.131592,236.743134 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M912.184082,255.286026 
	C912.505005,253.148178 912.994690,251.305710 913.750427,249.229950 
	C916.108032,251.623550 918.861572,253.987946 920.046021,256.974609 
	C920.771118,258.803162 922.288391,259.679596 922.765076,261.876953 
	C921.570679,263.061401 920.367676,262.847870 919.278687,263.133911 
	C915.974731,264.001740 913.669556,263.651184 914.209473,259.376007 
	C914.419006,257.716431 913.432922,256.620911 912.184082,255.286026 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M931.846558,261.870483 
	C932.131470,262.473785 932.131470,262.899597 932.145020,263.645111 
	C929.628357,265.856201 930.879089,268.811340 929.815430,271.416809 
	C927.527832,272.327240 925.783447,271.518005 923.676941,270.514282 
	C921.373047,267.937317 921.080261,265.424377 922.721191,262.359619 
	C922.997559,262.001221 923.028076,261.971741 923.043457,261.955200 
	C925.936462,263.354614 928.662964,259.544922 931.846558,261.870483 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M907.801392,216.285645 
	C913.074341,215.076370 911.659119,219.871490 913.697998,221.977402 
	C915.195740,224.614334 913.172546,224.914093 911.224670,225.663055 
	C910.883179,225.815338 910.481018,225.813416 910.282227,225.843307 
	C909.660461,226.337814 909.140869,226.503662 908.145386,226.158157 
	C907.365906,225.493927 907.072083,224.987808 906.784058,223.987854 
	C906.436035,221.182007 906.111877,218.801056 907.801392,216.285645 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M909.126587,237.237122 
	C908.910034,236.965454 908.945190,236.497940 908.953430,236.264038 
	C911.467102,236.433380 914.869324,235.979187 915.462524,239.099197 
	C915.881836,241.303925 914.539185,243.843689 913.963257,246.637329 
	C911.383301,245.730484 910.182251,243.543900 910.676453,240.804047 
	C910.966492,239.195923 910.499329,238.320297 909.126587,237.237122 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M911.184326,225.988342 
	C910.956299,223.868530 913.490723,224.310883 913.971191,222.372345 
	C914.471191,222.018829 914.908508,221.991653 915.674072,221.985596 
	C915.091370,224.038055 915.596008,227.608521 911.184326,225.988342 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M908.011902,225.838287 
	C908.723572,225.757446 909.111694,225.785431 909.791626,225.843292 
	C909.898804,227.415039 909.714233,228.956879 909.243408,230.753372 
	C908.641663,230.543350 908.326233,230.078674 907.977295,229.322311 
	C907.464111,228.513611 907.186340,227.913895 907.262634,226.863342 
	C907.450623,226.335114 907.569519,226.141113 908.011902,225.838287 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M929.766235,271.273987 
	C929.208618,268.426331 926.212402,265.393921 931.775513,263.985779 
	C933.179443,263.989166 934.200256,264.013489 935.606567,264.058533 
	C937.455139,266.420319 940.458191,267.508453 940.845032,270.885590 
	C940.992798,272.175690 943.299133,273.104462 945.047852,272.763641 
	C946.415833,272.497009 947.505920,272.026367 948.345032,273.699097 
	C949.276367,275.555603 948.014160,276.457428 946.997009,277.498474 
	C945.398682,279.134430 943.261536,280.552887 944.949463,283.683838 
	C943.966309,283.911163 942.434875,283.494965 942.609497,282.692780 
	C943.693359,277.716278 939.451294,279.560425 937.256348,278.229980 
	C936.098511,277.156189 936.857483,275.313934 935.301331,275.048950 
	C934.911865,274.982635 934.785706,276.079468 934.211670,276.810150 
	C933.421997,276.835388 932.871277,276.645294 932.141602,276.217468 
	C931.508057,275.492493 930.996521,275.067688 930.276611,274.374939 
	C929.569641,273.318024 929.246582,272.489563 929.766235,271.273987 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M943.765869,259.180359 
	C944.306885,259.468506 944.631775,259.934570 944.983521,260.696594 
	C944.112305,260.815308 942.975586,260.851318 943.765869,259.180359 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M807.775513,92.749435 
	C807.977417,93.037064 807.986816,93.515388 808.003174,93.754044 
	C808.296936,95.353355 809.426575,95.427368 810.738770,95.809486 
	C811.000244,95.998283 811.000000,96.000000 811.001221,95.999321 
	C812.361938,97.947777 810.733887,99.117706 809.819153,100.521049 
	C811.059998,100.443756 812.283569,101.179718 813.781494,100.272369 
	C815.956604,99.736740 817.011902,97.416687 819.608887,97.809982 
	C820.107117,97.985077 820.234802,98.204163 820.362061,98.803574 
	C819.298523,103.118095 816.772278,105.662735 813.096741,107.362358 
	C810.280579,108.664627 807.492981,110.038910 805.195923,112.317726 
	C802.877075,114.618156 801.585693,113.264992 800.688110,110.667984 
	C799.641296,107.639145 800.434692,104.480606 799.524902,101.158203 
	C798.925537,100.256218 798.612183,99.611153 798.260681,98.595871 
	C797.863098,96.116280 799.107666,93.597328 796.355835,91.775269 
	C794.985168,90.688889 794.493652,88.470650 792.311584,90.496979 
	C787.601868,94.870491 786.131042,94.259163 784.491821,87.306770 
	C783.227966,84.401299 780.535828,85.818306 778.431885,84.718506 
	C777.952637,84.445854 777.776184,84.311180 777.386230,83.922073 
	C776.516907,81.723000 774.844299,81.286995 772.796753,80.795403 
	C770.721802,79.340775 770.221619,77.630836 771.047729,75.255768 
	C771.679871,73.614876 775.270569,72.360817 770.990662,70.870605 
	C770.194336,70.767410 769.754089,70.627357 769.048218,70.204391 
	C768.312988,69.337440 768.185852,68.624931 768.407837,67.498940 
	C768.701111,66.939682 768.868103,66.742813 769.373413,66.352646 
	C771.011902,65.718842 772.278625,65.711678 773.813843,66.666748 
	C775.197937,67.255157 776.256104,67.626801 777.657104,68.000961 
	C778.757263,68.199364 779.272339,68.697739 779.820312,69.669701 
	C780.609619,73.371735 782.033875,75.623878 786.042969,74.414948 
	C787.388916,74.009064 788.789001,74.177757 789.676819,75.346123 
	C794.176147,81.267250 801.561035,84.147072 805.914734,90.700211 
	C806.816223,91.278778 807.225281,91.844391 807.775513,92.749435 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M806.220215,91.801056 
	C802.586609,91.591026 802.232483,88.163910 800.349121,86.249443 
	C796.701660,82.541725 791.208557,80.927887 788.752075,75.864494 
	C788.247009,74.823357 787.137268,75.186394 786.125122,75.524712 
	C781.617554,77.031364 779.535522,75.634117 778.975525,70.437500 
	C781.015503,68.585831 783.203796,68.869820 785.727966,69.720245 
	C786.813904,71.129837 788.067627,70.926788 789.577026,71.037056 
	C789.629639,73.268242 791.440613,74.657051 792.073486,76.509209 
	C792.523987,77.827583 793.300415,77.790085 794.301086,76.953995 
	C797.531433,74.254959 803.095276,75.236588 803.545898,79.251137 
	C803.996155,83.261909 806.866577,83.581085 809.013123,85.685959 
	C809.116943,86.047195 809.041138,86.523430 809.021790,86.763763 
	C807.932861,88.405952 807.632507,90.233971 806.220215,91.801056 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M760.802734,58.166214 
	C761.121338,56.384373 762.123962,55.975365 763.786865,55.904938 
	C764.251465,56.174202 764.436646,56.429569 764.907349,56.750023 
	C766.644531,60.000889 769.786560,62.388206 769.243286,66.675316 
	C769.012207,66.987320 768.961365,67.038795 768.935791,67.064362 
	C765.225586,68.768944 762.030884,72.332649 757.045654,69.828644 
	C756.298035,68.902122 756.104187,68.160835 756.079590,66.992554 
	C756.206177,65.872017 756.452271,65.168541 756.834839,64.128647 
	C757.073059,63.578732 757.182861,63.367542 757.500793,62.871685 
	C758.979980,61.418869 760.643188,60.528782 760.802734,58.166214 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M786.028625,69.513321 
	C783.916382,69.830620 781.835876,71.371315 779.330200,70.060028 
	C778.704468,69.538147 778.438477,69.039856 778.086243,68.272522 
	C778.376526,67.460243 778.634216,66.717972 779.150879,66.409363 
	C781.056702,65.270988 781.504211,61.531269 784.221619,62.446892 
	C787.294312,63.482235 785.604004,66.783577 786.028625,69.513321 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M769.436768,66.991684 
	C769.051331,63.317036 764.904358,61.395168 765.113037,57.263672 
	C766.635681,57.007835 767.593323,57.805092 768.011719,59.634575 
	C768.524536,60.494461 769.006042,60.996006 769.769348,61.708008 
	C770.027649,61.940929 770.506836,62.001060 770.747437,62.021358 
	C772.549438,62.668839 772.706116,64.342384 773.808228,65.688873 
	C774.070435,66.049202 774.085571,66.216393 774.120239,66.633545 
	C772.713684,66.921021 771.287537,66.958542 769.436768,66.991684 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M774.043213,65.494781 
	C771.916626,65.910469 771.629333,64.187866 771.028320,62.382809 
	C771.473022,61.187893 772.103638,59.951920 773.122009,60.572678 
	C774.826660,61.611816 773.703674,63.579571 774.043213,65.494781 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M769.975830,61.633415 
	C769.109131,61.902889 768.334595,61.657509 768.091553,60.290966 
	C768.935303,60.081013 769.665161,60.381226 769.975830,61.633415 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M799.759155,100.155746 
	C801.117737,100.548943 802.317505,101.493301 801.800476,102.628380 
	C800.249817,106.032120 802.136597,109.050133 802.329346,113.580444 
	C806.973022,106.402321 816.612244,107.172195 819.721558,99.000343 
	C820.560608,98.434517 820.951477,97.874237 821.724731,97.230141 
	C822.028381,97.076988 822.486633,97.073769 822.714783,97.052551 
	C823.161011,97.262749 823.402161,97.466850 823.835693,97.810043 
	C824.296448,98.460609 824.587891,98.944786 824.897461,99.722931 
	C824.960083,100.963509 825.026184,101.912567 824.391479,103.091522 
	C820.808411,105.560692 817.658447,107.686287 818.478455,112.676422 
	C818.920837,115.368340 816.462463,116.509727 814.244263,117.401001 
	C811.834656,118.369156 809.134583,118.832779 807.300110,121.467621 
	C805.851746,123.758018 803.651733,125.111450 802.967712,127.953339 
	C801.321655,130.374496 799.420105,130.489197 797.004333,129.218781 
	C795.765076,127.482994 795.564819,125.536736 796.273865,124.298622 
	C797.786194,121.657997 796.230103,120.606514 794.489380,119.045494 
	C794.501282,118.502747 794.551819,118.290695 794.785767,117.814255 
	C795.510010,116.960640 796.324890,116.619064 796.644165,115.469208 
	C796.799377,114.755325 796.895935,114.343788 797.085510,113.614273 
	C797.527466,112.060555 798.282593,110.856270 797.767212,109.523163 
	C796.442688,106.096809 797.609558,103.165672 799.759155,100.155746 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M823.720825,103.349525 
	C824.123230,102.167542 824.275757,101.310410 824.671875,100.235092 
	C825.918518,100.565857 825.948853,101.570549 826.177124,102.878647 
	C827.287964,104.376884 827.556458,105.753632 827.410156,107.575974 
	C827.260071,108.348473 827.201599,108.775429 827.169250,109.526260 
	C827.885315,110.979439 829.258484,111.611534 829.643616,112.925217 
	C831.285156,118.523712 836.111633,120.398964 841.009033,122.787201 
	C841.430420,129.798462 840.242493,131.249603 831.359741,134.676331 
	C830.682495,134.619553 830.415833,134.552689 829.801880,134.261383 
	C827.575378,132.023544 827.280823,129.455383 827.679260,127.013268 
	C828.393921,122.633667 827.083069,118.895355 825.194824,115.074738 
	C823.446350,111.536819 821.646851,107.830627 823.720825,103.349525 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M823.656860,82.974915 
	C824.037476,82.953712 824.475525,83.024811 824.693237,83.063965 
	C824.905029,84.776657 825.390930,86.158310 826.405579,87.617104 
	C828.052429,89.984650 825.977966,91.517403 824.440796,92.974159 
	C822.906372,94.428329 821.503113,93.908737 820.142090,92.595520 
	C818.658203,91.163734 817.160095,89.757935 818.938965,87.600311 
	C820.280701,85.972717 819.975769,82.777061 823.656860,82.974915 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M827.701538,107.935059 
	C826.244568,106.788513 826.026489,105.411385 826.221985,103.581154 
	C826.644714,103.170677 827.025024,103.107407 827.694641,103.016144 
	C828.431946,102.996284 828.879944,103.004433 829.664001,103.009979 
	C830.663879,104.435371 832.216309,105.450699 831.990112,107.641617 
	C830.688293,107.976936 829.386414,107.962769 827.701538,107.935059 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M813.736206,99.993240 
	C812.460510,103.937836 810.110596,100.871780 807.201233,100.934402 
	C809.438293,99.662521 810.619019,98.488632 810.962585,96.346008 
	C814.275024,95.791832 810.953613,99.641121 813.736206,99.993240 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M810.843628,95.756363 
	C809.787292,95.605728 807.736389,97.035225 807.784668,94.201981 
	C808.019531,93.992691 808.513306,93.965088 808.760498,93.974091 
	C809.567383,94.493538 810.127197,95.003983 810.843628,95.756363 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M821.870728,96.847336 
	C821.937378,97.941193 821.616516,98.629929 820.376282,98.879173 
	C820.058960,98.721344 820.042969,98.531326 820.003174,98.053650 
	C820.192200,96.832008 820.409302,95.904694 821.870728,96.847336 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M823.890259,97.757019 
	C823.669189,97.903931 823.394653,97.732918 823.062195,97.247375 
	C823.284424,97.094933 823.562012,97.263680 823.890259,97.757019 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M806.230591,92.136909 
	C806.280762,90.189056 806.948792,88.544312 808.701904,87.153687 
	C808.675659,88.756882 808.348938,90.509659 807.999756,92.649750 
	C807.480530,92.776001 806.983643,92.514938 806.230591,92.136909 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M760.682373,57.870964 
	C764.522339,61.461498 760.407959,61.666134 758.395813,62.995972 
	C756.882263,61.080032 755.176086,60.318684 752.598999,60.696938 
	C752.072327,60.485016 751.874573,60.365501 751.440979,59.987968 
	C750.541931,58.610695 750.181458,57.249138 749.098938,56.457016 
	C747.038757,54.949474 744.960999,53.659660 742.912659,56.630417 
	C741.088257,59.276558 738.389648,59.596020 735.094116,58.883621 
	C732.454041,57.116917 732.343445,55.049404 733.772217,52.386604 
	C734.045471,52.048679 734.512878,52.014965 734.745972,51.993248 
	C737.090454,51.946423 738.359375,53.698360 740.331604,54.645096 
	C741.484253,52.436592 738.107910,48.145824 743.624634,48.008026 
	C745.661377,49.075867 745.453369,52.154369 748.630127,52.005878 
	C749.095947,52.139172 749.224731,52.367138 749.316956,52.353188 
	C753.392639,51.737015 756.199890,53.604668 758.657227,56.937935 
	C759.483215,57.272987 759.940308,57.569176 760.682373,57.870964 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M701.769653,53.145981 
	C706.176697,52.454918 704.025330,55.980442 704.983826,58.312325 
	C708.339844,53.941532 708.573364,49.190315 709.857849,44.421719 
	C710.019714,44.074135 710.494263,44.009830 710.732666,43.987328 
	C712.620911,42.760590 714.742065,43.236454 716.747437,42.204136 
	C717.166016,41.990448 717.336182,41.986595 717.761597,41.981495 
	C718.713501,42.669067 720.194885,42.875111 720.194763,43.858223 
	C720.194580,47.211212 722.246155,47.725483 724.941772,48.079384 
	C726.989319,48.348202 728.814209,49.381554 728.067688,52.102852 
	C727.555420,53.970501 728.470032,55.666821 728.750977,57.802834 
	C728.807739,58.651878 728.730042,59.130642 728.386047,59.913261 
	C727.779968,60.507629 727.334167,60.696541 726.483032,60.762329 
	C724.653137,60.410423 724.122803,59.210960 723.537964,58.016243 
	C722.938416,56.791435 722.783691,54.772659 720.629883,55.633690 
	C718.953613,56.303768 718.362305,57.808933 718.318237,59.614540 
	C718.169189,65.716019 715.268433,68.086258 708.832886,67.047195 
	C708.314270,66.774940 708.117981,66.646095 707.673706,66.268204 
	C706.124695,63.361633 704.427490,61.533848 701.033875,61.788445 
	C698.756348,61.959320 697.904175,60.399830 698.504700,57.874626 
	C698.733643,57.366100 698.841003,57.172199 699.133362,56.707420 
	C700.195496,55.501808 700.738892,54.332989 701.769653,53.145981 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M728.450439,58.049408 
	C726.540405,56.623371 725.998901,54.193081 726.477051,53.012127 
	C728.286743,48.542664 725.118286,49.226955 722.844116,49.037090 
	C720.326294,48.826874 716.789795,49.758751 719.584351,45.094391 
	C719.840271,44.667290 718.762085,43.440876 718.160156,42.282932 
	C721.484070,41.399139 724.715027,43.154053 728.563232,43.007736 
	C728.998840,44.084949 729.009888,45.158142 729.038635,46.635033 
	C729.045166,47.773346 729.033997,48.507969 729.026184,49.610565 
	C730.624756,52.719513 732.384399,55.463734 728.450439,58.049408 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M716.610718,41.992168 
	C715.532898,43.514389 714.270264,45.555275 711.307739,44.009167 
	C711.060120,38.883354 714.635803,43.078739 716.610718,41.992168 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M697.338501,33.072308 
	C697.001160,31.684578 696.991272,30.286459 696.969604,28.473700 
	C697.716858,28.041090 698.475952,28.023123 699.613831,28.006071 
	C699.997131,28.747526 700.001587,29.488070 700.006226,30.598137 
	C699.737061,31.492376 699.467773,32.017090 699.086670,32.817417 
	C698.538635,33.082661 698.102234,33.072289 697.338501,33.072308 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M728.027222,58.328938 
	C731.672607,56.031231 727.860291,53.069427 729.014282,50.374607 
	C730.470581,50.635319 731.911682,51.292088 733.699097,51.998772 
	C734.388489,53.980751 733.028564,56.245129 735.537048,57.915989 
	C737.103149,59.272060 736.505859,60.486919 735.512451,61.988209 
	C732.605896,63.318466 730.583374,61.903419 728.282776,60.319603 
	C728.035522,59.553665 728.029297,59.108337 728.027222,58.328938 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M841.298035,123.051064 
	C837.309631,123.234909 830.819214,119.288925 829.495728,116.022255 
	C828.756042,114.196571 827.927368,112.406937 827.167053,110.225601 
	C830.424316,110.053551 834.124268,109.309357 834.409851,114.434486 
	C834.491333,115.896873 836.293762,116.646942 837.742065,117.757622 
	C838.106628,119.915115 839.576172,120.350517 841.337402,120.854126 
	C841.868469,121.018570 842.079773,121.145859 842.367554,121.635223 
	C842.344421,122.499451 842.079651,122.889336 841.298035,123.051064 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M859.150635,115.695831 
	C858.594116,116.105225 858.358398,116.297165 858.089233,116.777863 
	C857.013550,117.717613 855.451660,117.347168 854.551880,118.903259 
	C854.105652,119.212898 853.903137,119.302757 853.365356,119.391335 
	C852.602051,119.246605 852.209412,119.035995 851.595642,118.548752 
	C849.615234,115.664520 847.947815,118.832489 845.998169,118.161972 
	C845.713257,117.562668 845.672302,117.184998 845.608398,116.523544 
	C847.764893,113.424484 849.298889,109.296974 854.584595,112.021393 
	C855.744934,112.708443 856.893066,112.739265 857.781982,113.787735 
	C857.995117,114.005173 858.458618,114.016998 858.690430,114.010696 
	C859.347168,114.377777 859.508667,114.842720 859.150635,115.695831 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M845.668579,118.118568 
	C847.834045,118.248016 849.379944,112.214104 851.881104,117.764458 
	C851.749695,118.261803 851.609802,118.419823 851.201660,118.746689 
	C849.943542,119.303200 849.015564,119.808220 847.813354,120.501251 
	C847.038208,120.655403 846.593445,120.592682 845.887634,120.223450 
	C845.524292,119.789886 845.432983,119.575012 845.393433,119.013794 
	C845.445312,118.667442 845.583008,118.296791 845.668579,118.118568 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M842.095642,122.131836 
	C841.876099,121.850540 841.800537,121.363953 841.728882,121.127609 
	C842.154236,120.551567 842.651367,120.211868 843.450867,119.822464 
	C844.227905,119.550133 844.645020,119.641815 845.085205,120.355850 
	C844.882996,121.417122 844.355530,121.886749 843.366821,122.354980 
	C842.789551,122.449776 842.552368,122.431450 842.095642,122.131836 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M845.669434,120.766350 
	C844.717957,120.657814 844.485901,120.362549 844.003540,119.920029 
	C844.157776,119.456810 844.562317,119.140846 845.206055,118.746162 
	C845.568604,118.886932 845.691956,119.106430 845.939331,119.682663 
	C846.171814,120.219475 846.280334,120.399544 845.669434,120.766350 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M917.272461,820.778564 
	C916.264771,821.022522 915.521118,821.025818 914.405762,821.039795 
	C913.885986,820.883179 913.737915,820.715942 913.503418,820.226196 
	C914.228821,817.395020 913.355225,815.346252 911.538269,813.204834 
	C911.307373,812.433472 911.305725,811.973633 911.517334,811.184082 
	C913.132690,809.267212 914.900635,808.972351 917.212524,809.815063 
	C917.729614,810.059998 917.927612,810.169312 918.402832,810.461487 
	C919.887329,811.742615 921.283813,811.815186 923.041992,811.248657 
	C923.673401,810.887329 924.027893,810.667725 924.625916,810.241333 
	C927.210266,808.884766 925.098206,805.644531 927.796936,804.398193 
	C928.593018,804.268127 929.044006,804.344849 929.733398,804.762146 
	C929.971741,806.669373 930.011902,808.259888 931.103943,809.734985 
	C932.482605,811.597412 931.981995,812.903992 929.538879,812.980530 
	C926.591797,813.072876 927.611328,814.782227 927.936890,816.403076 
	C928.209290,817.759460 929.908936,819.531311 928.217224,820.457703 
	C925.740662,821.813599 926.319824,818.456055 925.040710,817.749756 
	C924.610168,817.511963 924.156738,817.315796 923.355652,817.050293 
	C921.626709,815.688660 920.108154,815.779480 918.269897,816.769104 
	C917.858459,818.185791 917.697449,819.361816 917.272461,820.778564 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M930.396973,805.001587 
	C929.570557,804.996399 929.152405,804.990295 928.420593,804.984985 
	C927.387024,804.418640 927.492981,803.739624 928.092346,802.765076 
	C929.549866,801.686951 931.000793,801.570496 932.675354,801.049622 
	C934.401184,801.170715 935.160278,801.938232 934.851135,803.333862 
	C934.319580,805.733582 932.282410,804.743713 930.396973,805.001587 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M933.003784,800.752930 
	C932.789612,803.104431 931.221802,802.998230 929.332031,802.974731 
	C928.234375,802.289307 928.156128,801.454468 928.521973,800.148499 
	C928.691711,799.745789 928.787720,799.355347 928.886719,799.180481 
	C929.433838,798.994995 929.881958,798.984436 930.666199,798.981628 
	C931.599060,799.018494 932.131226,799.212769 932.779053,799.822876 
	C932.973022,800.038269 932.987793,800.514954 933.003784,800.752930 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M918.433838,817.007263 
	C919.204590,813.919250 920.947632,814.553894 922.926270,816.642822 
	C921.614441,817.001587 920.231384,817.003174 918.433838,817.007263 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M932.950195,799.746582 
	C932.427002,799.851135 931.881042,799.664001 931.168701,799.233154 
	C931.936890,797.933655 932.546509,798.221191 932.950195,799.746582 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M914.581909,825.974060 
	C920.753967,828.364197 921.635681,829.922119 920.248169,835.621399 
	C919.915283,836.988647 919.752441,838.696777 920.807861,839.337402 
	C924.145081,841.363403 923.461853,843.985352 923.236206,847.224670 
	C922.801147,853.468445 923.205078,853.496582 916.414917,852.031006 
	C914.520813,851.101990 914.981323,849.072510 913.524414,847.728699 
	C912.855347,846.893982 912.497620,846.276062 912.089355,845.270264 
	C911.794861,844.206360 911.742432,843.476990 911.762817,842.382019 
	C913.928345,836.862610 912.851440,831.211121 914.581909,825.974060 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M914.816650,825.763428 
	C915.936890,829.626160 913.934875,833.207031 914.791382,836.802246 
	C915.255127,838.748901 913.373901,839.673157 912.984741,841.608459 
	C912.187744,842.756165 911.861816,843.602661 912.765381,844.802856 
	C913.237671,845.506714 913.507812,845.995911 913.888977,846.741821 
	C915.516968,849.503052 915.148132,851.410583 911.847656,852.491394 
	C909.432983,850.260010 908.544922,846.699036 904.285522,846.627747 
	C902.478943,844.800110 902.176575,842.856812 902.655396,840.445923 
	C903.216553,838.672729 906.052979,841.102051 905.538452,838.061462 
	C905.369324,837.314819 905.387695,836.876587 905.604980,836.123779 
	C907.641418,833.666870 908.835938,831.214050 909.157532,828.334473 
	C909.359314,826.528687 909.676208,824.732361 911.665161,823.449707 
	C912.488403,823.294312 912.952759,823.380371 913.661499,823.820190 
	C914.183960,824.550415 914.444092,825.032043 914.816650,825.763428 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M910.658325,852.080811 
	C913.733215,851.790833 914.068970,849.917358 913.997803,847.378479 
	C916.762512,847.418152 915.768372,849.615417 916.012756,851.628662 
	C916.022522,852.026123 915.980103,852.491272 915.950012,852.722534 
	C912.607971,853.629395 912.700989,855.183044 914.759399,857.314331 
	C916.339722,858.950684 916.462952,860.949097 915.414246,862.782837 
	C914.354919,864.635071 912.694336,865.677795 910.215088,864.168579 
	C909.041138,860.985474 908.526367,857.997070 908.205811,854.623901 
	C908.656311,853.279053 909.359070,852.607239 910.658325,852.080811 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M913.959839,823.691284 
	C913.487732,824.048157 913.051636,824.027588 912.287415,824.008850 
	C911.228699,823.410461 911.079895,822.591125 911.186279,821.295837 
	C911.668152,820.039612 912.286011,819.542419 913.621399,819.772705 
	C913.995422,820.155212 914.000305,820.334167 914.019653,820.781738 
	C914.021362,821.804932 914.008667,822.559387 913.959839,823.691284 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M946.614868,750.082642 
	C949.108765,752.566895 948.880188,755.756104 949.727661,758.866455 
	C949.684326,759.784424 949.359253,760.554321 948.991150,761.634521 
	C948.948120,761.944824 948.469177,761.965881 948.229614,761.976746 
	C946.788513,762.093262 945.155090,762.652283 945.443420,763.716248 
	C947.007568,769.488037 943.374451,767.443665 940.380493,767.008423 
	C940.465515,765.114624 942.831299,763.564270 939.484314,761.410034 
	C936.322632,759.375061 940.077881,756.705750 940.820923,754.683594 
	C941.494019,752.851929 943.494141,750.501404 946.614868,750.082642 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M948.652710,701.893311 
	C950.867981,703.465759 949.326416,705.868286 950.775513,707.778931 
	C950.867676,708.575439 950.682861,709.120850 950.260254,709.838257 
	C947.989197,712.097412 949.244019,713.539612 951.009827,714.820679 
	C953.272827,716.462646 953.474915,718.649231 951.461365,720.186523 
	C947.343750,723.330322 943.996582,727.037048 941.285278,731.406311 
	C940.452026,732.749084 938.746521,732.824707 937.279541,733.851929 
	C936.884460,736.665100 934.348877,737.500366 933.106628,739.673950 
	C932.199280,740.792419 931.410461,741.586792 930.390503,742.633545 
	C929.177734,743.603699 928.112000,743.965210 926.565063,743.701294 
	C922.200439,743.384033 924.215698,747.115112 922.797180,749.012451 
	C921.736816,752.013000 918.809265,751.870239 916.787170,753.611389 
	C914.113159,756.564209 912.756775,759.593384 913.219666,763.336487 
	C913.448853,765.190491 913.071594,767.107422 911.567444,768.320129 
	C908.198059,771.036926 908.396057,774.570496 908.986877,778.232666 
	C909.382996,780.688110 909.839600,783.121826 908.234131,785.716980 
	C907.994995,785.993774 908.000000,786.000000 907.996582,785.997498 
	C904.004822,788.979675 902.393005,792.692139 904.651245,797.807495 
	C904.849060,798.260681 904.856018,798.437683 904.887329,798.879150 
	C906.216064,801.694153 905.578247,803.471375 902.429077,804.269531 
	C900.394775,802.305054 899.996094,799.626160 897.855347,798.103821 
	C897.187317,797.628662 896.783752,796.643494 896.980042,795.875977 
	C898.026917,791.783142 897.248535,786.506836 900.110168,783.776978 
	C904.226868,779.849915 903.487244,775.993225 902.730347,771.604736 
	C902.535156,770.472717 902.606140,769.283813 901.782471,767.917969 
	C901.377869,767.119934 901.242920,766.632874 901.169678,765.754700 
	C901.375854,763.982910 901.716187,762.595764 901.638794,760.822021 
	C901.627991,760.038818 901.615112,759.594849 901.567993,758.827515 
	C901.499695,757.591553 900.928406,756.907471 900.404602,755.837280 
	C900.018860,754.314209 900.307678,753.284546 901.551025,752.291138 
	C902.828796,751.676270 903.667969,751.083191 904.106567,749.649780 
	C904.371765,749.158936 904.495422,748.969360 904.861572,748.535889 
	C905.655396,747.857361 906.268372,747.529358 907.233948,747.117554 
	C908.483093,746.624023 910.011169,746.903748 910.336304,746.159912 
	C913.487976,738.948853 922.717224,737.716003 925.679443,730.200684 
	C927.374512,725.900208 930.712524,723.611145 935.644958,725.900085 
	C937.881775,726.938171 938.422119,725.117493 938.522766,723.754822 
	C939.024231,716.966003 944.578003,712.064758 945.235046,705.330444 
	C945.394165,703.699463 946.468994,702.251953 948.652710,701.893311 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M908.344238,785.959595 
	C907.511963,781.834045 908.806030,777.625061 906.942444,773.415710 
	C906.170715,771.672607 907.860840,769.272156 909.822815,768.143677 
	C911.718750,767.053223 912.491272,765.366333 911.915100,763.600342 
	C910.466187,759.159546 912.914062,756.197876 915.640869,753.051270 
	C919.736206,752.723083 922.947632,751.211609 925.908508,748.936218 
	C927.471252,747.735229 929.218872,746.619934 931.676392,747.782837 
	C932.056091,748.322998 932.223206,748.581116 932.653687,748.920898 
	C933.612549,749.593933 933.484314,750.279236 932.912659,751.269836 
	C930.321411,752.993958 928.065002,754.572693 925.618958,755.909119 
	C921.862854,757.961243 922.000732,759.948975 925.699524,762.307495 
	C926.858276,763.046326 927.803528,763.878479 928.020874,765.661865 
	C928.034912,766.007324 928.012634,766.500122 927.999512,766.746399 
	C927.288879,767.706116 926.512756,768.292786 925.580811,768.689026 
	C920.613770,770.800720 919.210693,770.237061 916.881226,764.925903 
	C914.850586,765.913025 915.274658,767.290344 915.853271,768.944763 
	C917.672241,774.145569 917.059631,774.882141 911.251648,774.751709 
	C908.048767,776.511475 910.359741,778.272034 910.886047,779.829224 
	C911.800964,782.535950 912.203308,784.885864 908.344238,785.959595 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M911.343018,775.022461 
	C911.250061,771.389465 915.114990,775.558411 915.806213,772.710144 
	C916.430115,770.138916 913.167908,769.331787 913.357605,767.098816 
	C913.556885,764.752197 914.886475,762.941650 917.223450,762.579773 
	C919.800842,762.180664 918.957764,764.476868 918.941528,765.666199 
	C918.897217,768.911865 920.303223,769.287476 922.948303,768.184265 
	C924.295288,767.622498 925.780334,767.391846 927.594666,767.002075 
	C927.992920,767.166138 927.999512,767.339539 928.020691,767.773193 
	C928.035278,768.033508 928.018311,768.008301 928.025146,768.014771 
	C927.522156,768.404358 927.505615,769.003418 927.166687,769.757141 
	C926.953857,771.019836 926.938171,772.073059 926.884521,773.516724 
	C922.203735,775.207886 920.307007,777.974731 921.344727,782.643005 
	C921.935730,785.301392 920.177612,785.471741 919.012634,784.702332 
	C916.773499,783.223572 913.028625,782.435059 914.390686,778.260742 
	C915.217712,775.725952 913.092529,775.713806 911.343018,775.022461 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M937.466064,734.089966 
	C941.544617,729.730774 943.846130,723.363281 949.661438,720.180603 
	C952.729919,718.501160 952.472839,716.850281 949.687134,715.150635 
	C947.208130,713.638123 946.108215,712.013794 949.684875,710.058594 
	C949.951843,711.744263 950.449158,712.922424 952.097229,714.027100 
	C956.009094,716.649170 955.866272,720.383057 952.171021,723.159546 
	C949.768005,724.965088 948.564087,727.717163 946.964478,730.532349 
	C946.608704,731.021240 946.359802,731.224487 946.023804,731.683350 
	C943.482239,733.581787 940.776062,734.189026 937.466064,734.089966 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M931.979614,747.649780 
	C929.478210,747.874023 927.687561,749.195862 925.809204,750.607178 
	C923.097534,752.644592 920.334595,754.925293 916.238159,753.255493 
	C916.366028,749.600159 920.580078,752.081299 921.724976,749.214722 
	C923.653442,747.210999 922.760071,744.064880 925.694824,742.854004 
	C927.155212,742.717590 928.257812,742.495728 929.673889,742.132690 
	C930.638733,741.273560 931.475952,740.900757 932.716858,740.840698 
	C934.346252,741.041809 935.954956,740.853821 936.413818,742.402405 
	C936.971313,744.283997 935.078125,744.420227 933.988159,744.834656 
	C932.749268,745.305908 931.986816,745.907288 931.979614,747.649780 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M928.353027,765.823608 
	C926.780762,765.246887 926.504822,763.423218 924.810730,763.114502 
	C923.092529,762.801453 920.521179,762.930603 920.536804,760.737732 
	C920.554077,758.296570 922.331787,755.468811 924.327515,754.776489 
	C926.939453,753.870422 928.930603,752.265259 931.596069,751.004456 
	C933.223083,752.738770 932.859619,753.989746 930.442444,754.867249 
	C926.852051,756.029114 927.667480,758.030212 928.993713,759.939087 
	C930.426208,762.001038 930.476868,763.834656 928.353027,765.823608 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M930.308960,755.192078 
	C930.797424,753.950134 932.270325,753.319092 931.988098,751.332947 
	C932.265625,750.531128 932.557129,750.072021 932.849121,749.329529 
	C934.140503,748.842041 933.533325,746.429810 934.996399,746.877869 
	C936.389893,747.304688 937.417908,748.601685 937.840637,750.095581 
	C938.177368,751.286011 937.895325,753.030273 936.735535,752.946777 
	C934.140808,752.759949 933.964355,758.238770 930.308960,755.192078 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M933.018188,740.733521 
	C932.232605,741.307434 931.432922,741.625244 930.310303,741.967285 
	C930.087097,740.576233 930.916809,739.990784 932.608398,739.979004 
	C932.971313,740.136841 932.987671,740.307068 933.018188,740.733521 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M901.368164,804.536011 
	C904.569946,803.712036 904.035828,801.309631 904.878601,799.483154 
	C904.911682,799.143738 905.384705,799.090698 905.622559,799.082397 
	C906.046936,798.909790 906.233398,798.745483 906.790527,798.628174 
	C907.843628,799.041443 908.308044,799.604248 908.721924,800.651184 
	C908.994873,803.347229 907.489075,804.937622 906.074585,806.918701 
	C904.804016,808.079285 904.451477,809.163025 905.129700,810.814148 
	C905.507690,813.543762 907.178589,816.040894 904.332336,818.332886 
	C899.904236,814.684875 898.739136,810.257996 901.368164,804.536011 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M921.571167,796.043335 
	C921.970459,797.428284 921.993958,798.793579 922.022217,800.570190 
	C919.407410,802.390686 918.307068,799.601624 916.144897,798.749878 
	C913.884521,796.630127 913.661133,793.844971 912.414673,791.491943 
	C912.563293,790.937439 912.028259,791.316467 912.130127,791.520142 
	C912.952759,793.164978 911.858826,793.851196 910.186279,794.113098 
	C909.913330,793.274231 909.968262,792.615540 909.944763,791.959717 
	C909.890625,790.447205 908.813721,788.545837 911.436462,788.084351 
	C913.601746,787.703308 914.934204,788.941589 915.693115,790.687073 
	C916.815369,793.268127 917.589539,796.061401 921.571167,796.043335 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M909.782349,794.139160 
	C911.632507,793.597778 911.677612,791.839539 912.627136,790.562012 
	C914.976074,792.262512 914.888306,795.065857 915.988342,797.633240 
	C915.889709,798.197083 915.750183,798.396667 915.323242,798.821045 
	C912.824768,799.863953 911.062195,801.892578 908.077759,801.348022 
	C907.465881,800.687378 907.179993,800.181580 906.786255,799.405579 
	C907.039185,797.788086 907.707703,796.594238 908.723572,795.198364 
	C909.228760,794.793884 909.472900,794.599426 909.782349,794.139160 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M904.895630,797.647461 
	C900.371094,793.213684 901.082947,789.700439 907.634277,786.034790 
	C908.263367,790.082458 903.307861,792.751648 904.895630,797.647461 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M908.606079,794.999817 
	C908.939575,796.439758 908.301514,797.678223 906.994446,798.951233 
	C906.678345,799.135437 906.269226,799.125854 906.064819,799.099976 
	C906.314270,797.527893 906.592041,795.875610 908.606079,794.999817 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M914.765808,798.319580 
	C915.158020,798.017029 915.332092,798.012024 915.767700,798.002319 
	C918.664124,797.361816 919.563843,799.924438 921.681885,800.929199 
	C923.115967,801.844177 923.595398,803.053894 923.969238,804.671753 
	C925.662659,806.465942 926.284363,808.224548 925.300293,810.739624 
	C924.952515,811.420044 924.748108,811.671448 924.240112,811.959961 
	C922.214294,811.836426 920.336975,812.337646 918.282654,811.265503 
	C917.966248,811.032654 918.000000,811.000000 917.983582,811.017700 
	C914.357483,808.945007 917.415649,805.180176 915.605469,802.313293 
	C914.660583,801.008301 914.175903,799.915100 914.765808,798.319580 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M925.412598,811.040894 
	C924.724365,809.328430 924.371216,807.552856 924.009033,805.389893 
	C926.764526,804.418152 924.883362,800.184082 928.354248,799.817688 
	C928.783081,800.550293 928.874451,801.354736 928.959961,802.561768 
	C928.673828,803.445374 928.393555,803.926514 928.110046,804.696655 
	C925.568054,806.293152 929.736694,810.241333 925.412598,811.040894 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M927.193176,769.862427 
	C927.155090,769.420471 927.340637,768.874329 927.779053,768.174683 
	C928.031982,768.021240 928.024841,768.505310 928.022583,768.747192 
	C927.891174,769.302063 927.690002,769.558411 927.193176,769.862427 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M947.969299,312.034546 
	C948.521851,315.115051 950.546875,318.117249 948.219788,321.335297 
	C947.964600,321.688110 948.572510,322.914978 949.056152,323.513306 
	C953.705078,329.264191 955.839600,335.773468 956.314331,343.186951 
	C956.468872,345.600739 960.756714,347.454437 959.748779,350.638458 
	C958.857056,353.455566 959.997864,355.849060 960.112000,358.428528 
	C960.179321,359.950409 960.911804,361.345581 962.353882,362.105743 
	C966.875061,364.488861 964.544312,366.062775 961.619751,367.109924 
	C956.942749,368.784454 957.164917,370.700592 960.808716,373.396729 
	C962.452576,374.613037 963.716431,376.237549 962.235229,378.762268 
	C958.450134,379.067169 957.448669,376.064972 955.969177,373.852081 
	C954.163025,371.150421 952.354431,368.707336 948.864441,368.121552 
	C947.092102,367.824066 945.410889,366.604401 945.505371,364.904327 
	C945.750671,360.490295 942.427185,357.209259 942.171692,353.070557 
	C942.014526,350.524384 940.467773,349.702454 938.056702,349.082825 
	C937.498901,348.827484 937.284302,348.701508 936.799988,348.310394 
	C936.110840,347.386749 935.910400,346.655975 935.803467,345.507080 
	C935.816650,344.622498 935.892456,344.127411 936.121582,343.290314 
	C937.108826,340.819489 937.946106,338.740906 939.253662,336.756836 
	C943.844482,329.790833 940.617188,323.407227 937.023010,317.328369 
	C934.424683,312.933746 932.768738,308.670624 933.607117,303.302277 
	C934.216736,299.399323 934.129517,295.110016 928.439453,293.776123 
	C925.856506,293.170624 924.201050,290.148651 921.222656,288.871704 
	C920.101562,288.133484 919.391296,287.446503 918.271606,286.670105 
	C917.033203,285.391663 916.590698,284.098511 916.671265,282.780609 
	C917.169434,274.631287 912.907593,269.173981 906.667786,264.801697 
	C905.626770,264.072266 905.127319,263.123535 905.457642,261.486816 
	C907.908264,259.359650 910.509521,260.148621 911.975403,261.719482 
	C917.446899,267.582977 923.721985,272.999634 925.712463,281.740784 
	C927.938293,284.097198 928.755310,280.903290 930.653320,281.018127 
	C935.938721,280.619202 938.700623,284.177246 940.889160,287.516571 
	C943.190735,291.028473 944.115601,295.382385 946.491455,299.011230 
	C947.887390,301.143280 948.300720,303.643707 946.988708,306.113770 
	C945.973022,308.025818 945.714966,309.867798 947.722839,311.775146 
	C947.954712,312.029602 947.969849,312.023132 947.969299,312.034546 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M925.969116,281.667603 
	C922.905945,281.520599 923.314453,278.324493 922.444458,276.584961 
	C920.065063,271.827972 915.582520,268.927124 913.067749,264.492218 
	C911.583252,261.874390 908.951660,262.049103 906.403564,261.020996 
	C904.951660,260.726471 904.023499,260.167511 902.858521,259.357483 
	C902.488708,258.941284 902.368103,258.750183 902.123718,258.241150 
	C901.886475,257.480560 901.805176,257.032043 901.728149,256.235962 
	C901.517700,253.426300 902.475708,250.921417 901.382446,248.130676 
	C901.273254,247.289825 901.342041,246.811935 901.713745,246.043793 
	C902.397400,245.508331 902.881836,245.446732 903.692871,245.778320 
	C907.818848,246.884033 911.920593,247.494461 910.287537,253.206924 
	C910.186096,253.561829 911.006287,254.180176 911.706482,254.833298 
	C918.127014,255.004456 915.169067,259.315491 915.222595,262.562073 
	C917.684082,262.144531 919.946655,260.648102 922.630249,261.995178 
	C923.210999,264.425598 922.463684,266.939545 923.838745,269.634766 
	C926.060730,271.359955 928.129089,272.703217 930.711914,273.806305 
	C931.291382,274.479584 931.609924,274.937866 931.945679,275.687958 
	C929.841980,277.602600 926.241150,277.562378 925.969116,281.667603 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M947.969360,311.650879 
	C944.829773,311.377655 943.328613,310.684753 945.564697,306.849396 
	C947.599548,303.359039 946.888184,299.345703 942.660522,296.878815 
	C940.462036,295.595947 941.922180,292.915344 941.089478,290.832428 
	C939.128967,285.928467 934.839294,284.240479 931.333435,281.026001 
	C932.951538,279.040070 937.771179,279.963104 939.729492,282.781769 
	C940.276245,283.568756 940.418518,284.588898 941.361023,285.177765 
	C950.727905,291.030548 950.255432,299.492157 947.756409,308.772400 
	C947.997925,309.757904 947.990967,310.515015 947.969360,311.650879 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M934.188965,277.280029 
	C933.976257,276.863037 933.943054,276.689362 933.988892,276.539948 
	C934.419067,275.137726 932.603699,273.729462 933.877563,272.428284 
	C934.484802,271.807953 935.402649,272.210663 936.082397,272.738983 
	C937.648499,273.956268 938.542419,275.356415 937.151489,277.637909 
	C936.139465,277.814758 935.272339,277.674683 934.188965,277.280029 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M954.038818,298.355103 
	C955.510620,298.517609 957.045532,299.008148 958.790344,299.771057 
	C956.871887,301.374664 955.454834,300.144501 954.038818,298.355103 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M970.345825,358.210266 
	C970.013916,356.935242 969.987183,355.886017 969.953613,354.444916 
	C970.407654,355.573181 975.614868,353.653961 972.310974,357.854004 
	C971.644287,358.383911 971.183838,358.526642 970.345825,358.210266 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M970.030273,358.335358 
	C970.482788,357.989929 970.924927,357.995422 971.699707,358.004272 
	C971.903687,358.861938 971.775085,359.716217 971.414795,360.796692 
	C970.171814,360.554474 970.016113,359.660065 970.030273,358.335358 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M937.768188,348.277649 
	C941.382019,348.258301 944.167847,348.765686 943.503601,353.511505 
	C943.267944,355.195282 944.593689,357.363007 945.752197,358.898010 
	C946.805176,360.293152 947.865173,360.996979 946.447266,362.936188 
	C945.491211,364.243652 946.715271,366.554565 947.971252,366.578156 
	C954.230713,366.695557 955.951355,371.735352 958.725952,375.726440 
	C959.473999,376.802429 960.485168,377.695526 961.671265,378.832489 
	C961.986450,380.372192 962.005981,381.752045 962.033936,383.545502 
	C964.982178,391.541779 965.876587,399.470459 966.651733,407.520203 
	C967.562317,416.975037 968.927551,426.438232 972.692017,435.714264 
	C972.970093,436.469940 972.983521,436.915985 973.021362,437.696411 
	C973.045776,438.030823 973.011353,438.015015 972.997620,438.002838 
	C972.025146,438.911285 971.964478,439.810822 973.253296,440.941101 
	C973.591431,441.630920 973.589783,442.064819 973.285522,442.774719 
	C973.315918,443.615692 973.536011,444.182037 974.042725,444.876831 
	C974.944214,445.903809 975.141479,446.881897 974.736816,448.294189 
	C974.275269,449.328552 974.021790,450.034454 973.631531,451.074829 
	C971.290588,454.111023 968.653076,454.539673 965.811096,452.515900 
	C964.038208,457.033051 964.278259,461.622528 961.319275,465.375854 
	C956.619446,462.556274 958.542969,458.073944 958.251892,454.215118 
	C958.119019,452.453522 958.881592,450.622864 957.481812,448.647644 
	C957.077393,447.595581 956.992859,446.858215 957.018188,445.741882 
	C957.763916,443.376984 956.817688,441.656586 956.012207,439.533630 
	C955.380737,435.832428 955.389709,432.426758 954.639893,428.718323 
	C954.498291,426.511444 954.804443,424.666809 954.102661,422.510559 
	C953.790588,421.367249 953.682373,420.585754 953.577881,419.408142 
	C950.462280,408.718567 950.121338,397.795715 947.128967,387.603577 
	C945.215698,381.086914 944.125122,374.139404 940.611816,367.679321 
	C940.171814,366.872681 939.992981,366.381104 939.791931,365.489319 
	C939.489990,363.098785 939.800293,361.056488 939.236328,358.705841 
	C938.975159,357.631042 938.942627,356.903137 938.986206,355.822998 
	C939.963562,352.861298 937.087646,351.048187 937.768188,348.277649 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M972.978821,435.640472 
	C967.719421,435.120148 968.572083,430.353699 968.282227,427.246490 
	C967.783630,421.901672 965.599854,416.860687 965.504639,411.624146 
	C965.339233,402.520905 962.777344,393.807465 962.028687,384.395111 
	C964.835754,384.494995 964.692688,387.074432 965.459595,388.972565 
	C966.305237,391.065735 966.953735,393.251404 969.086975,389.859497 
	C969.726746,388.842255 970.475769,387.807373 971.866577,388.687653 
	C972.892883,389.337097 972.580505,390.318604 972.406555,391.356995 
	C972.134583,392.979645 971.737427,394.770111 972.156738,396.277130 
	C972.852173,398.776978 972.806641,400.338409 970.313232,401.998932 
	C968.688354,403.080994 967.688232,405.582153 968.047424,407.840607 
	C969.028198,414.007629 967.716614,421.038788 974.940979,424.887085 
	C976.838806,425.898010 976.318420,428.457001 975.122314,430.284332 
	C974.112244,431.827209 973.065796,433.324036 972.978821,435.640472 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M890.695312,126.054932 
	C889.072998,129.255859 881.388794,130.774338 879.114197,127.493256 
	C875.218445,121.873474 870.004089,124.645958 865.394470,123.812485 
	C863.129089,123.402885 860.750427,123.578850 860.724854,120.222687 
	C862.901428,119.172791 865.047424,122.473305 866.979248,119.380722 
	C867.367065,118.934059 867.620605,118.754295 867.944092,118.300186 
	C872.118347,118.195190 875.544495,119.012222 878.650330,122.345230 
	C881.497498,125.400635 886.661804,123.816177 890.695312,126.054932 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M857.993408,113.670006 
	C856.870361,113.936890 855.613159,114.163651 855.041870,112.374512 
	C856.502136,111.254860 857.952881,110.651405 857.993408,113.670006 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M859.207153,116.004890 
	C858.909973,115.550781 858.925659,115.109055 858.931763,114.335861 
	C859.331665,113.982033 859.741211,113.959671 860.451294,113.900543 
	C860.800049,114.361641 860.848206,114.859505 860.827393,115.728867 
	C860.345581,116.072670 859.932861,116.044975 859.207153,116.004890 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M989.793579,428.581482 
	C989.869629,428.576416 989.878540,428.807373 989.939941,428.905304 
	C989.848206,428.893890 989.753601,428.754974 989.793579,428.581482 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M995.881226,441.218231 
	C996.881226,441.982758 997.085754,442.804626 996.278381,443.407623 
	C995.692017,443.845612 994.954468,443.415649 994.621460,442.814331 
	C994.080811,441.838043 994.561829,441.295227 995.881226,441.218231 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M751.956177,963.603027 
	C750.376404,963.051880 747.768311,966.725037 746.940918,962.393188 
	C747.987671,960.640808 749.329285,959.896606 751.506592,960.453735 
	C752.028137,960.846741 752.184753,961.065613 752.239502,961.675049 
	C752.089722,962.475830 752.041809,962.885986 751.956177,963.603027 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M755.714172,954.881165 
	C756.019348,955.480103 756.024841,955.935364 756.050171,956.732910 
	C755.571960,957.646179 755.074036,958.217224 754.325928,958.930176 
	C753.871094,959.153076 753.666443,959.234131 753.173035,959.152771 
	C752.608765,958.631653 752.450317,958.225220 752.429321,957.445068 
	C752.625549,956.689880 752.842712,956.301086 753.352051,955.704102 
	C754.121521,955.036072 754.719482,954.764404 755.714172,954.881165 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M752.488159,959.211670 
	C753.181213,959.011169 753.359924,959.021301 753.807129,959.051758 
	C753.934082,960.150757 754.121765,961.379822 752.458618,961.987976 
	C752.071167,961.879700 752.004700,961.693665 751.861206,961.221680 
	C751.696045,960.769226 751.607971,960.602783 751.579834,960.159180 
	C751.751160,959.728821 751.862488,959.575500 752.488159,959.211670 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M756.274109,954.873047 
	C755.504395,955.264954 754.994995,955.505066 754.217041,955.831909 
	C751.169373,952.028137 756.097351,950.470337 756.554810,947.191162 
	C757.434753,943.822021 758.692505,941.781311 762.513794,942.832825 
	C766.332581,943.883728 767.748901,942.010681 766.820923,937.902954 
	C766.848877,933.757996 764.988159,929.744141 768.332336,926.019897 
	C769.224976,925.253235 769.940369,924.978210 771.062988,924.746277 
	C773.386780,924.590820 775.345581,924.537964 777.302307,924.281311 
	C778.979492,924.061218 781.146973,924.307495 781.735413,925.753296 
	C783.172241,929.283630 785.921082,928.582703 788.880310,928.784607 
	C789.709900,929.581299 789.934021,930.293823 789.988037,931.458679 
	C789.909302,932.105042 789.846619,932.359375 789.613647,932.967896 
	C788.115295,935.036011 786.449280,936.127930 783.852234,936.158569 
	C781.732910,936.271362 779.739685,934.217712 778.074768,936.999634 
	C777.229492,938.445068 776.100891,939.143005 774.857544,940.178650 
	C773.897400,941.048401 772.857056,941.470947 772.855896,943.001343 
	C772.787598,943.729309 772.664978,944.125549 772.287964,944.753296 
	C771.064758,945.076965 769.977783,945.281311 769.541565,946.281311 
	C767.916809,950.005737 766.873474,952.158997 762.221619,948.331604 
	C758.896545,945.595764 760.828003,951.353577 759.024414,952.614624 
	C758.157898,953.560669 757.346130,954.140991 756.274109,954.873047 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M773.735291,940.165039 
	C774.054871,938.288391 775.664795,938.262512 776.774902,937.228638 
	C778.225586,935.583130 779.667297,934.697083 781.987732,935.357788 
	C784.201477,936.233521 786.125000,938.178955 788.046143,934.940491 
	C789.504333,933.993103 790.539978,933.084045 791.709839,931.764648 
	C792.252197,931.466919 792.492981,931.385925 793.120972,931.273560 
	C797.003113,931.868225 799.023865,933.455811 797.248535,937.676270 
	C792.650146,938.729431 791.563782,943.441956 788.046570,945.624268 
	C787.535706,945.974915 787.097717,945.981506 786.331055,945.995605 
	C782.598755,946.830627 778.837280,945.707764 775.348877,947.952393 
	C774.916870,946.525818 774.296387,945.444580 772.425903,945.037170 
	C772.070251,944.558533 772.044250,944.113464 772.009705,943.334229 
	C772.078613,941.875977 772.868835,941.175415 773.735291,940.165039 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M769.671265,952.025513 
	C769.918457,952.903992 769.659607,953.691406 768.275024,953.932495 
	C768.068420,953.072754 768.355042,952.306885 769.671265,952.025513 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M814.225464,914.716797 
	C813.534607,915.318176 813.069275,915.633118 812.310547,915.970703 
	C808.994141,914.411377 810.510315,917.972839 809.141846,918.718018 
	C808.836060,919.013489 808.661865,919.005493 808.226624,918.983032 
	C807.140137,918.422974 806.466797,917.706360 805.762817,917.022583 
	C804.492310,915.788513 802.747742,914.741516 803.406494,912.570312 
	C804.131592,910.180359 806.244873,910.191772 808.592224,910.017456 
	C809.711060,909.912354 810.451477,909.973816 811.534668,910.264648 
	C812.782410,910.857727 813.418884,911.616577 814.260132,912.672363 
	C814.575012,913.469421 814.577148,913.949707 814.225464,914.716797 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M809.088745,910.661499 
	C808.442871,911.767761 807.728455,911.798340 806.879456,911.483032 
	C805.529541,910.981628 804.619080,911.609131 804.250000,912.847107 
	C803.806885,914.333435 804.918335,914.767151 806.064636,915.163696 
	C807.483459,915.654541 807.984314,916.794312 807.966187,918.593994 
	C807.477844,919.233582 806.990173,919.498535 806.250000,919.880005 
	C804.629272,921.021973 804.295166,922.132324 805.096130,923.824585 
	C806.257263,926.277588 804.173401,927.401489 802.613831,928.565979 
	C800.923401,929.828186 799.247864,930.009460 797.991272,927.914185 
	C796.880249,926.061768 794.896545,927.251709 793.173584,926.360718 
	C792.345276,924.612000 793.178955,923.358948 794.331604,922.863831 
	C800.308350,920.295837 798.110840,914.383179 799.719238,910.054077 
	C800.165039,908.853943 798.891663,908.449463 798.073364,907.957336 
	C796.655579,907.104797 795.281738,906.212769 794.470947,904.262451 
	C794.267334,903.139648 794.428162,902.421814 795.035278,901.443359 
	C797.168457,899.440979 796.973389,895.389282 801.346191,896.116699 
	C803.442322,896.943115 804.648010,898.174072 805.302002,900.317566 
	C803.273621,903.397827 803.854797,904.985107 807.635620,905.471863 
	C808.105286,905.765198 808.263367,905.922180 808.557556,906.378662 
	C809.034973,907.884705 807.669312,909.321655 809.088745,910.661499 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M792.617493,925.894287 
	C794.373230,926.098694 795.596375,925.761108 796.722534,924.893616 
	C797.702515,924.138855 798.518494,924.242493 798.923645,925.612122 
	C799.293701,926.863403 798.446838,929.906372 801.322693,927.840454 
	C802.094055,927.286377 807.032715,926.684082 802.738708,923.257751 
	C800.785217,921.698853 803.265015,920.305847 805.622437,920.002380 
	C809.913330,925.902832 809.584351,927.371277 803.073792,930.959412 
	C800.472900,932.392822 799.735596,934.471680 799.997375,937.583313 
	C799.248169,937.988586 798.498840,937.991821 797.374756,937.995117 
	C797.604980,934.562988 796.171326,932.602478 792.358215,932.033569 
	C791.071899,931.384583 790.720703,930.440857 790.344055,929.093628 
	C790.250488,927.285339 790.806396,926.245544 792.617493,925.894287 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M808.281006,907.191895 
	C808.060669,906.939758 808.031372,906.455383 808.000488,906.214722 
	C806.655334,904.818665 805.747314,903.446045 805.651855,901.286926 
	C811.344177,896.551025 807.844116,892.399658 805.246826,887.865601 
	C805.072327,886.123169 805.403198,884.965393 807.227051,884.357178 
	C807.875183,884.378662 808.130798,884.442078 808.691162,884.756958 
	C808.982056,886.492737 809.931580,887.453003 810.882874,888.385681 
	C816.310913,893.707153 817.996033,900.726990 819.777039,907.727539 
	C819.961426,908.452332 820.982849,909.112610 819.866089,909.728088 
	C818.717346,910.361023 816.212708,910.072021 817.569885,912.358398 
	C818.992859,914.755554 820.719116,912.447449 822.632080,912.011597 
	C822.498474,917.467896 818.209045,913.993652 815.389465,915.024902 
	C814.944763,915.040833 814.473083,915.005737 814.236572,915.004517 
	C813.999634,914.562012 813.999329,914.120728 813.986816,913.348755 
	C813.658997,912.666443 813.456665,912.260620 813.415039,911.449341 
	C813.568115,910.141968 814.761230,908.694031 812.518372,908.737427 
	C810.993835,908.766846 809.574341,908.708435 808.281006,907.191895 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M973.868896,578.732178 
	C973.940735,579.438965 973.926208,579.859741 973.903931,580.596313 
	C973.941101,581.314758 973.914307,581.712769 973.728271,582.393921 
	C973.289551,583.626953 973.071411,584.605347 972.863159,585.903198 
	C972.543396,587.544312 973.109009,588.695007 973.724609,590.189087 
	C974.129028,592.019165 973.714355,593.388550 972.894043,595.032593 
	C967.519165,599.329163 967.157104,606.129639 963.573608,611.057434 
	C962.675537,612.292542 963.501038,613.599915 963.591858,614.891357 
	C963.882874,619.033630 964.218628,623.274109 960.382507,626.233459 
	C959.334045,627.042358 959.471619,627.592163 960.043457,628.665161 
	C963.372803,634.912231 962.520508,640.298828 957.150269,644.874512 
	C954.901062,646.790955 953.424683,648.925537 952.341187,651.613953 
	C950.762634,655.530640 948.024597,658.253113 943.177612,658.390076 
	C942.006104,657.206421 941.796326,655.655090 942.664124,655.044434 
	C947.618286,651.558899 947.201721,645.566589 948.555969,640.846436 
	C949.720032,636.789246 951.792786,632.244568 950.430847,627.235107 
	C949.765381,625.163635 950.223145,623.465271 950.523560,621.383301 
	C950.575867,620.497131 950.594543,619.987000 950.619141,619.085083 
	C950.630676,617.894226 950.744080,617.106750 950.959534,615.924072 
	C950.893555,607.822937 954.318970,600.681946 954.584656,592.630127 
	C954.584290,591.387817 954.636658,590.562866 954.879578,589.344727 
	C957.312622,584.942505 957.467896,580.563965 957.677185,576.051208 
	C957.987488,569.361023 959.679810,562.761292 959.114990,555.606384 
	C958.785217,554.412964 958.697998,553.593323 958.672974,552.353455 
	C958.766357,550.826660 959.051575,549.795105 960.215698,548.800659 
	C963.184570,548.645081 963.059265,550.814392 963.468140,552.470093 
	C963.906982,554.247131 964.741882,555.757751 965.645142,557.349121 
	C968.186035,561.825684 972.162048,561.930786 976.719971,561.762878 
	C976.850830,564.433411 977.516418,566.936096 976.134583,569.622620 
	C974.613892,571.353882 972.699097,572.436890 973.464722,575.201782 
	C973.177307,576.535828 973.249329,577.515991 973.868896,578.732178 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M977.122192,561.705688 
	C975.428955,561.871033 974.043335,562.409180 972.715515,563.134460 
	C970.212585,564.501709 968.993591,563.605774 967.047852,561.445129 
	C963.854492,557.899048 961.609924,554.341553 961.288879,549.269653 
	C960.664917,548.685913 960.451416,548.299866 960.438538,547.489197 
	C961.069702,545.948059 961.790955,544.804993 962.262634,543.176880 
	C962.653320,542.479492 962.946899,542.129333 963.552856,541.597717 
	C967.014221,540.540894 967.187134,538.093506 967.043884,535.098633 
	C967.176880,534.526978 967.251831,534.303711 967.491516,533.768555 
	C968.432800,532.373047 969.102539,531.165527 970.049133,530.259583 
	C975.789612,524.765747 975.107971,518.418274 972.564148,511.850220 
	C971.683960,509.577484 972.560486,507.582306 973.186523,505.546417 
	C974.052002,502.731750 974.167664,500.401123 970.919861,498.890961 
	C968.789429,497.900360 967.659424,496.067322 968.744202,493.283966 
	C970.448669,493.728149 971.590088,494.943817 973.411072,495.114990 
	C975.566833,495.317596 977.629822,497.070160 977.605713,498.829529 
	C977.519714,505.097382 979.663940,510.268311 983.298706,515.239319 
	C984.636414,517.068848 982.393677,519.667908 981.214233,520.832825 
	C978.244507,523.766357 977.871094,527.021240 977.936829,530.779663 
	C977.988708,533.741028 977.584229,536.781250 973.205688,535.594910 
	C972.021790,535.274170 971.294373,535.534973 971.071655,536.819580 
	C970.395935,540.715759 969.218994,544.602844 969.162292,548.506897 
	C969.105225,552.432556 972.823669,554.478821 975.232178,557.064819 
	C976.429749,558.350586 978.540100,559.026550 977.122192,561.705688 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M960.675049,465.293457 
	C961.656067,460.825165 964.344421,457.028992 963.321838,452.538300 
	C963.130737,451.698975 963.028625,450.834991 963.899780,450.381409 
	C964.930908,449.844543 965.913025,450.336304 966.533997,451.122589 
	C968.512878,453.628143 970.395691,452.822357 972.663025,451.059204 
	C973.385986,450.619507 973.841797,450.475250 974.673462,450.794678 
	C974.974487,451.022125 974.993164,451.499969 975.009277,451.738312 
	C974.858521,456.727478 977.687805,460.746887 978.240967,465.406647 
	C978.619934,468.598663 977.769470,470.079651 974.980286,469.886597 
	C969.242065,469.489380 968.161011,474.504150 968.523926,477.364044 
	C969.128723,482.129242 967.234680,486.541931 967.973572,491.572205 
	C967.415894,492.617645 966.716736,492.790131 965.582031,492.605347 
	C964.832886,492.193573 964.534973,491.823029 964.288208,491.014160 
	C964.133484,489.371338 964.278687,487.980774 962.560547,487.039612 
	C962.113464,486.711182 961.942932,486.567688 961.553589,486.170593 
	C960.747192,484.972076 960.153687,484.031006 959.356018,482.818054 
	C958.903381,481.608734 958.907593,480.697784 959.215088,479.452026 
	C960.432678,477.583008 960.926331,475.945770 960.006104,473.754272 
	C959.702332,472.413544 959.599976,471.411285 959.595215,469.996094 
	C959.495667,468.189484 959.673584,466.837189 960.675049,465.293457 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M964.830811,492.284485 
	C965.793274,491.982269 966.518738,491.994507 967.606079,492.013245 
	C968.288879,492.129608 968.550964,492.320343 968.868164,492.810150 
	C969.116943,495.456512 969.464294,497.561157 972.537354,498.139343 
	C975.390808,498.676270 975.811035,500.518799 975.198792,503.367096 
	C974.428101,506.952209 970.959534,511.099884 976.371399,514.189575 
	C977.065857,514.586060 976.625671,515.731445 976.061951,516.415466 
	C975.284241,517.359070 974.619446,518.575378 975.059998,519.623474 
	C978.045349,526.726196 972.009460,529.685242 968.328674,533.940308 
	C966.014954,533.169434 965.935120,531.549988 966.400391,529.858398 
	C968.853027,520.941711 967.486206,511.832550 967.824707,502.810730 
	C967.966675,499.027618 964.519958,496.367737 964.830811,492.284485 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M973.262085,576.183838 
	C972.574829,573.572815 971.086060,570.736450 975.607727,569.995605 
	C975.977356,570.156067 975.989441,570.328674 976.020874,570.760376 
	C975.327026,572.437012 973.792908,573.473328 974.035156,575.687744 
	C973.860840,576.144104 973.687561,576.247742 973.262085,576.183838 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M974.383179,449.161499 
	C974.183472,447.881134 974.171936,446.830566 974.244873,445.392639 
	C975.969482,444.925049 977.606750,443.068268 979.248901,444.989349 
	C979.957336,445.818146 980.090515,446.793945 979.531250,447.685364 
	C978.638794,449.107758 977.597412,450.420044 975.313721,449.121094 
	C974.861694,449.122131 974.716492,449.256714 974.383179,449.161499 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M973.146362,440.791962 
	C970.894897,440.428101 970.216125,439.545166 972.657593,438.044800 
	C973.000427,438.429932 973.016968,438.869171 973.036865,439.638031 
	C973.149719,440.160126 973.259216,440.352631 973.146362,440.791962 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M972.947388,441.373962 
	C972.944214,440.860504 972.964355,440.682190 973.012390,440.235718 
	C975.048462,440.530212 975.758911,441.397247 973.412781,442.978149 
	C973.054077,442.602631 973.012451,442.155853 972.947388,441.373962 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M985.683105,434.211548 
	C986.876587,434.539093 986.982544,435.431885 986.883728,436.737976 
	C985.308105,436.746735 984.664734,435.950317 985.683105,434.211548 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M973.915649,449.090515 
	C974.195068,448.931732 974.603882,448.917694 974.805420,448.952606 
	C975.002258,449.439545 974.997498,449.891571 974.983643,450.682861 
	C974.536865,451.016449 974.099243,451.010773 973.333252,451.002563 
	C973.215393,450.416443 973.425842,449.832886 973.915649,449.090515 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M908.826416,853.758118 
	C908.493408,857.187378 912.427673,859.819641 910.035034,863.528625 
	C908.928040,863.928711 907.866455,863.974243 906.406616,864.030762 
	C905.014709,863.792969 904.508545,863.053833 904.037964,861.865662 
	C903.609131,860.381775 903.882812,859.033325 902.687744,857.839783 
	C902.180298,857.336548 901.888367,857.050293 901.364624,856.562378 
	C899.673523,855.048523 898.222595,855.123474 896.522095,856.598267 
	C895.707703,857.219727 895.099182,857.526611 894.083740,857.757568 
	C892.434814,857.731873 891.560913,857.098267 891.240234,855.821960 
	C890.928284,854.580017 895.010437,845.458252 896.274170,844.988220 
	C898.385010,844.203308 899.694458,843.059753 899.232178,840.261230 
	C899.269165,838.858826 899.597473,837.977173 900.996704,837.440430 
	C902.142151,837.387390 902.829163,837.663757 903.731079,838.355347 
	C904.091919,838.844666 904.183594,839.075439 904.128845,839.648804 
	C902.308228,841.747375 904.677795,843.488586 904.003418,845.610474 
	C903.639160,849.651855 905.415344,852.004211 908.826416,853.758118 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M906.312744,873.826416 
	C905.542847,873.995605 905.085632,873.991272 904.285828,873.975159 
	C902.942505,872.066406 901.786316,870.459290 899.527771,869.738159 
	C897.567139,869.112244 896.300293,867.581970 896.129517,865.014954 
	C896.632629,862.489380 897.729797,860.840149 900.305725,860.034424 
	C901.196045,859.946228 901.693970,860.007202 902.504517,860.315979 
	C904.009644,862.854614 903.061096,866.343628 906.744873,867.778931 
	C907.091248,868.333862 907.278442,868.594055 907.748901,868.924194 
	C907.974365,870.715820 908.312683,872.477417 906.312744,873.826416 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M906.952148,867.675903 
	C903.935303,867.028076 899.110779,867.183777 902.993958,861.417603 
	C903.422485,860.723999 903.867065,860.547424 904.693359,860.786926 
	C905.251770,861.815796 905.503479,862.629150 905.881714,863.742188 
	C906.314209,865.138367 906.620178,866.234924 906.952148,867.675903 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M958.600525,687.919678 
	C959.255676,688.158386 959.524109,688.410583 960.020630,688.820435 
	C960.274780,690.845886 959.710083,692.321289 957.342468,692.923828 
	C954.156372,690.545288 953.268616,688.540161 958.600525,687.919678 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M957.577393,713.870789 
	C959.969604,714.035156 962.529785,714.229065 959.246948,717.302979 
	C957.241089,716.922913 956.007751,716.133423 957.577393,713.870789 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M687.336060,982.960022 
	C686.657959,982.920532 686.393005,982.738159 686.091003,982.247803 
	C685.246277,977.404846 686.025574,976.041626 690.779480,975.669250 
	C695.917480,975.266724 699.600891,973.209656 701.776733,968.037781 
	C702.244446,967.245178 702.590393,966.852295 703.312866,966.282043 
	C709.240723,965.032532 714.829712,964.185852 720.231567,962.010132 
	C722.646423,961.037537 724.801636,959.395508 727.812378,958.872009 
	C731.647766,959.284973 732.533936,960.747986 729.838013,963.839417 
	C727.667297,965.331726 725.451172,966.119324 724.557739,968.616028 
	C724.098938,969.897949 722.723450,970.863464 721.411316,970.741821 
	C716.985962,970.331238 714.508789,973.351440 711.344910,975.904785 
	C710.571045,975.957031 710.142761,975.943909 709.395020,975.908203 
	C708.613525,974.465576 708.211548,973.021851 707.666138,971.634583 
	C707.354553,970.841919 706.692322,970.275330 705.769226,970.222168 
	C704.422485,970.144531 704.349304,971.178223 703.968445,972.132080 
	C701.477295,978.371704 698.932983,980.317993 692.376038,979.493408 
	C689.049255,979.075073 690.276001,983.291016 687.336060,982.960022 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M729.328735,964.213013 
	C727.827942,962.775635 733.279968,960.886902 728.272949,959.998413 
	C727.488708,959.499939 727.204590,958.929871 727.088562,957.950317 
	C727.065552,956.789490 726.941711,955.972107 726.455017,954.899353 
	C726.267517,954.153015 726.255981,953.712402 726.379517,952.940308 
	C727.207275,948.739807 731.389954,947.531921 733.068848,943.945312 
	C733.458374,943.200623 733.756531,942.822571 734.397461,942.258484 
	C736.326233,941.218689 737.877747,940.327759 739.305542,939.124817 
	C741.423218,937.340454 743.717834,937.637146 745.511536,939.667908 
	C747.618103,942.052856 746.218140,943.938293 744.266052,945.584839 
	C741.416992,947.988098 737.456604,949.735718 739.123413,954.990845 
	C740.754028,957.702026 745.569092,956.009216 745.229980,960.646484 
	C739.862061,958.406982 735.938049,961.865173 731.371887,963.866455 
	C730.654602,964.400085 730.182678,964.525024 729.328735,964.213013 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M728.591675,964.007568 
	C729.464355,964.009277 729.921387,964.013672 730.722168,964.032227 
	C730.743713,970.508362 728.870483,972.287903 722.505127,971.984680 
	C720.321960,971.880737 718.641235,972.522583 717.362244,974.339966 
	C715.917847,976.392456 714.111450,977.666016 711.225159,976.249512 
	C713.163513,972.244812 715.224060,968.543457 720.709473,969.763916 
	C722.044556,970.060913 723.874268,969.041077 723.881104,967.248291 
	C723.893311,964.056458 725.813599,963.846130 728.591675,964.007568 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M745.298462,961.006592 
	C744.752502,956.240967 739.623108,959.479980 738.071289,956.378906 
	C742.851807,953.925415 747.699829,952.664368 752.709473,956.609375 
	C752.964722,957.418945 752.978821,957.870850 752.997681,958.661987 
	C753.002441,959.001099 753.000000,959.000000 753.000122,959.000610 
	C752.751038,959.196350 752.532166,959.420898 752.172974,959.837769 
	C752.002502,960.000610 751.917297,960.473572 751.850708,960.704590 
	C750.405029,961.269775 749.025940,961.603882 747.283875,961.990356 
	C746.473511,961.717407 746.026184,961.392090 745.298462,961.006592 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M942.630005,658.067993 
	C947.879944,656.444092 951.388977,653.582275 951.992920,648.170593 
	C952.172546,646.560974 953.153748,645.824219 954.389282,645.322754 
	C958.209534,643.772095 959.423584,640.141235 960.724121,636.867310 
	C961.725037,634.347656 961.947632,631.429382 958.987671,629.388611 
	C957.858337,628.609924 956.519897,627.650879 956.378601,625.952209 
	C961.395630,625.312622 964.463379,619.857117 962.143005,614.931824 
	C961.099976,612.717712 961.165039,611.451599 962.698608,609.700378 
	C964.559998,607.574768 965.789734,605.078308 966.054016,602.126099 
	C966.353699,598.777405 969.397400,597.406738 971.711365,595.151733 
	C975.887024,596.847656 974.416138,600.297668 974.252075,603.641357 
	C974.002441,604.002441 974.000000,604.000000 974.001221,604.001221 
	C968.257141,606.256409 966.699158,609.634521 967.394714,616.023376 
	C968.030273,621.860901 967.840820,627.754700 963.320557,632.825317 
	C963.399231,634.813416 963.686279,636.527893 963.981689,638.622803 
	C963.801025,639.263306 963.388855,639.640259 963.455017,639.766785 
	C966.330444,645.258728 966.374939,649.135315 961.688293,653.459534 
	C958.132690,656.740173 959.339844,661.653259 956.271912,664.776245 
	C956.094788,664.956482 956.045349,665.580750 956.193787,665.716125 
	C961.426758,670.485962 956.199402,674.071716 955.174377,678.376648 
	C953.345032,686.059692 949.470825,693.227783 949.139526,701.656250 
	C947.020447,703.056946 946.143616,704.911743 946.084045,706.932617 
	C945.974792,710.638855 944.099854,713.295654 942.165466,716.297363 
	C940.359741,719.099426 939.622803,722.920105 939.457275,726.342773 
	C939.329773,728.980103 939.087097,729.318665 936.817688,728.252625 
	C930.236450,725.161072 930.295593,725.235962 926.645508,731.407715 
	C925.243591,733.778137 922.660767,735.444641 922.521118,738.580017 
	C922.505737,738.927490 921.767700,739.589722 921.497620,739.527710 
	C915.929810,738.249512 914.452637,742.519775 912.540405,746.116089 
	C911.453308,748.160645 909.645569,748.038757 907.404053,748.003723 
	C906.890991,747.828552 906.778564,747.652161 906.677246,747.162231 
	C906.983826,746.049561 907.555237,745.450317 908.340454,744.637329 
	C909.127869,743.856140 909.606323,743.238098 909.690796,742.066772 
	C909.888245,740.612610 910.176697,739.523987 911.104309,738.312622 
	C914.453552,735.680664 916.254761,732.563538 917.984131,729.003296 
	C920.236572,724.366394 925.257812,722.067322 929.728821,719.774597 
	C934.238403,717.461975 934.643372,717.221863 932.396667,712.829407 
	C931.533936,711.142639 930.593872,709.452271 931.637573,707.671509 
	C934.482483,702.817932 935.785278,697.168518 939.410522,692.727722 
	C942.208191,689.300659 941.740112,685.513184 940.120544,681.919556 
	C938.833557,679.063965 938.675842,676.597046 940.410645,673.943726 
	C942.449524,670.825256 940.829590,667.910156 939.503418,664.668518 
	C939.091614,663.611145 939.018066,662.868103 939.154785,661.736389 
	C939.793884,659.870056 940.823608,658.811157 942.630005,658.067993 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M963.479248,633.020264 
	C965.203735,626.413818 967.589783,619.866028 965.454773,612.571777 
	C964.341370,608.767639 969.847046,603.138000 973.640076,603.958008 
	C973.989502,605.664551 973.915833,607.308105 975.111877,608.729370 
	C975.479065,609.165710 975.766174,610.326538 975.557068,610.495239 
	C969.227112,615.603943 975.477844,621.792419 974.026245,627.639282 
	C971.307678,631.074707 967.654724,632.167236 963.479248,633.020264 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M974.385132,603.910156 
	C973.520996,601.181396 973.039551,598.360291 972.286865,595.270508 
	C972.342346,593.581482 972.669006,592.161133 973.003967,590.372253 
	C973.412842,589.678955 973.870605,589.533020 974.690125,589.782837 
	C975.045349,594.608032 977.583679,599.257019 974.385132,603.910156 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M972.713501,582.316650 
	C973.067688,581.713074 973.262573,581.439514 973.676758,581.038940 
	C975.601746,580.572144 977.416199,578.809509 978.992554,581.633545 
	C979.208740,584.075195 978.259094,585.526489 976.169373,586.681458 
	C975.948364,586.940369 975.499207,586.936584 975.274780,586.945679 
	C974.576904,587.195923 974.131226,587.152344 973.494751,586.526855 
	C972.999268,585.042358 972.585022,583.883118 972.713501,582.316650 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M973.732971,585.760010 
	C974.190063,586.266235 974.381287,586.517639 974.841187,586.839233 
	C975.028564,587.716248 975.006714,588.477783 974.989929,589.619629 
	C974.554260,590.000793 974.113586,590.001587 973.342529,590.003052 
	C971.555542,589.202332 970.510742,588.228516 971.751709,586.200684 
	C972.395508,585.619324 972.864746,585.469360 973.732971,585.760010 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M972.768433,576.219116 
	C973.180359,576.017395 973.351013,576.018494 973.777954,576.030029 
	C974.297546,576.903992 975.198120,577.672607 974.192078,578.854065 
	C972.354675,578.771362 971.683655,578.013184 972.768433,576.219116 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M869.680969,880.920349 
	C868.583557,881.082520 868.138062,881.040955 867.359802,880.956665 
	C866.685547,880.571838 866.435059,880.174255 866.273804,879.357300 
	C870.264648,874.983276 865.005005,874.642151 863.577393,872.400818 
	C863.328857,871.864624 863.259644,871.636414 863.165527,871.050537 
	C863.254456,869.414368 863.904419,868.334900 865.132080,868.174500 
	C871.554199,867.335754 875.250549,862.506226 879.775635,858.281982 
	C880.595093,857.339722 881.350220,856.796265 881.926147,855.653137 
	C882.476807,854.738647 882.939880,854.199341 883.811462,853.547913 
	C884.859802,853.093201 885.605530,853.057007 886.730042,853.317993 
	C887.548157,853.704285 887.938416,854.037720 888.457886,854.779541 
	C888.901611,855.857300 888.918945,856.607849 888.585144,857.703125 
	C885.247131,860.824097 881.641846,863.175354 878.918213,866.789185 
	C881.092651,867.330811 881.372009,865.668762 882.197144,864.778992 
	C884.911743,861.851746 887.487671,864.430786 889.875732,865.248413 
	C892.693237,866.212952 891.418396,868.363831 890.237549,870.450684 
	C889.898621,870.929199 889.750244,871.110962 889.351074,871.537964 
	C884.074951,877.346008 876.514282,877.582520 869.680969,880.920349 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M896.557251,864.890198 
	C897.359680,867.669617 899.074768,868.807617 901.716492,869.146423 
	C904.103638,869.452576 905.332397,870.736145 903.998230,873.619629 
	C902.922607,873.951294 901.902039,873.939148 900.497681,873.921021 
	C898.987549,872.960022 898.051453,873.317566 897.119141,874.665039 
	C897.363342,876.846924 898.512634,878.948486 895.264893,879.961853 
	C894.229004,880.084839 893.486755,880.040649 892.372253,879.995239 
	C891.150391,878.894775 890.255859,879.238892 889.526917,880.052185 
	C883.296387,887.004211 876.182007,883.776001 869.103027,881.492310 
	C869.028992,881.124084 868.952148,880.986450 868.951416,880.907654 
	C872.501099,879.541809 876.387024,879.930847 879.940674,878.428772 
	C883.516357,876.917297 886.787292,875.238037 888.752197,871.259033 
	C888.990540,870.992676 889.000000,871.000000 888.996338,870.995117 
	C893.720703,871.655640 893.752258,866.817810 896.557251,864.890198 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M859.195007,886.154175 
	C858.752319,884.193481 859.595154,882.855164 860.874023,881.325562 
	C861.151123,880.865845 861.274902,880.713440 861.664551,880.431763 
	C862.318298,880.215576 862.709900,880.192810 863.387878,880.320435 
	C863.827332,880.525024 863.984253,880.643188 864.104980,881.053772 
	C864.013672,881.957825 863.958679,882.569458 863.840210,883.476685 
	C862.402039,883.846497 861.127319,884.138062 860.120972,885.669189 
	C859.785034,886.091797 859.615845,886.221619 859.195007,886.154175 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M866.717957,878.732422 
	C867.012512,879.389526 867.019165,879.825867 867.026367,880.588074 
	C866.284668,881.003418 865.542297,881.092896 864.434326,881.264282 
	C864.068665,881.346191 864.038330,881.149414 864.026428,881.050598 
	C863.624451,880.188660 863.928955,879.562500 864.703247,878.760132 
	C865.474182,878.413696 865.950317,878.394592 866.717957,878.732422 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M843.006592,888.382446 
	C844.142517,888.069519 844.565063,888.819031 845.118530,889.741333 
	C848.245605,894.952698 850.750854,895.012268 854.194031,890.253235 
	C855.019775,889.112000 856.319153,888.313477 857.682251,887.155640 
	C858.116882,887.128906 858.270081,887.304016 858.307007,887.808228 
	C858.173645,888.528015 858.156494,888.918640 858.131165,889.602539 
	C858.932129,891.162170 860.274292,891.996338 861.027344,893.686157 
	C861.107544,894.435852 861.105042,894.874634 861.126038,895.641357 
	C859.319824,896.940796 857.506531,897.366211 855.516296,896.270325 
	C854.558228,895.742859 853.603760,895.164185 852.280212,895.499573 
	C846.214050,897.036926 843.425110,894.973877 843.006592,888.382446 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M861.327881,893.791077 
	C859.608948,893.295532 858.424927,892.313171 858.104675,890.239014 
	C860.043823,890.401978 861.703979,891.150085 861.327881,893.791077 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M858.487671,888.047974 
	C858.145508,887.941711 858.100159,887.746033 858.009277,887.252136 
	C858.099182,886.661194 858.292786,886.414062 858.743896,886.084656 
	C859.111816,885.964966 859.280396,885.973145 859.701538,885.971680 
	C859.943359,886.849609 859.720703,887.613037 858.487671,888.047974 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M846.238892,886.233948 
	C845.172363,885.432312 844.359314,884.845154 843.273193,884.128967 
	C842.867737,883.799866 842.735413,883.599670 842.671509,883.045898 
	C844.025696,880.757507 846.090332,879.583984 847.501526,877.741882 
	C848.874695,875.949585 850.792908,875.749817 853.137085,876.783875 
	C853.623230,877.066895 853.804871,877.194946 854.219971,877.551025 
	C855.119385,878.834778 855.946899,879.708252 857.575928,880.585693 
	C858.183228,884.016052 856.618835,885.184631 853.389893,885.009766 
	C850.412842,883.595703 849.034790,887.663818 846.238892,886.233948 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M845.996826,886.369019 
	C847.259216,885.950256 848.457092,885.986511 848.975952,884.323914 
	C850.183411,880.455322 851.485046,882.313171 852.855835,884.691284 
	C849.665100,888.876343 848.032043,889.274414 845.996826,886.369019 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M843.085999,882.603455 
	C843.001709,883.164612 843.000977,883.331726 843.000122,883.749390 
	C842.257935,883.997620 841.515930,883.995239 840.402832,883.988770 
	C839.430908,883.971191 838.898804,883.782532 838.305542,883.117310 
	C838.128479,882.449768 838.102539,882.029907 838.049194,881.295532 
	C838.032227,880.810364 838.042725,880.639893 838.068726,880.213806 
	C840.640015,878.764343 842.164062,879.901123 843.085999,882.603455 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M862.218323,880.572144 
	C861.967041,880.930725 861.497925,880.985291 861.262573,881.001709 
	C860.272034,881.011780 859.516785,881.005432 858.384399,881.003174 
	C858.007324,881.007324 857.997070,880.997070 857.992432,880.992432 
	C857.161133,877.320679 858.992126,876.905457 862.162537,878.144531 
	C862.627991,879.061768 862.640381,879.635803 862.218323,880.572144 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M838.142822,883.201904 
	C838.668823,883.086792 839.183350,883.304077 839.864868,883.753052 
	C840.024963,884.154602 840.018005,884.324402 840.003601,884.749084 
	C839.065186,884.906555 838.268188,884.639221 838.142822,883.201904 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M897.326416,874.860718 
	C895.992371,874.140442 896.269958,873.465515 897.318054,872.905823 
	C898.295837,872.383606 899.299500,871.736389 900.034790,873.614258 
	C899.284546,874.185913 898.455078,874.456665 897.326416,874.860718 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M809.363037,884.994385 
	C808.995850,885.008484 808.538452,884.880127 808.307129,884.825928 
	C805.335144,883.198242 802.268188,884.046448 799.369568,883.635437 
	C797.443726,883.362305 797.476990,884.703735 797.509399,886.207825 
	C797.575745,889.289185 796.259766,891.582153 792.748596,892.435791 
	C790.083801,888.401001 789.964417,883.572754 791.937439,880.273682 
	C794.217468,876.461243 798.643311,873.828369 803.961182,875.295166 
	C805.252625,875.651306 806.591431,875.577209 808.273315,875.200684 
	C809.128662,875.006287 809.622681,874.951904 810.482666,874.940369 
	C813.131653,875.224121 815.371887,875.925171 818.230164,875.785400 
	C816.191467,871.204468 818.973450,868.360779 821.683716,865.513977 
	C822.649719,864.499268 823.872437,863.894653 825.274536,864.450439 
	C826.824768,865.064819 826.945557,866.437256 826.536560,867.792847 
	C826.003662,869.559082 826.534973,871.048584 827.527527,872.862366 
	C827.659363,877.727234 824.332886,879.913940 821.153625,881.932007 
	C818.560913,883.577637 815.927856,885.478577 812.261108,885.268127 
	C811.198364,885.035583 810.464294,885.007935 809.363037,884.994385 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M812.021057,885.411621 
	C817.640625,882.219604 824.131714,880.410889 826.971313,873.368835 
	C827.776855,872.076904 828.817200,871.960449 830.316650,872.125732 
	C832.504944,873.017578 833.846252,874.221436 834.121887,876.627991 
	C828.584106,875.328247 827.281738,880.669006 825.516663,883.146240 
	C820.271667,890.507751 819.199951,891.148682 812.021057,885.411621 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M834.260254,877.182129 
	C833.084595,875.867737 832.171875,874.728882 831.114868,873.310608 
	C833.984497,870.677429 835.487671,874.267700 838.094666,874.908875 
	C838.607971,875.178589 838.804504,875.300720 839.260986,875.648499 
	C842.686157,880.034058 844.614807,876.852661 847.017456,874.466309 
	C847.789185,874.061951 848.273315,873.919861 849.174194,873.805481 
	C851.793091,874.091187 854.063293,874.338684 854.203613,877.627258 
	C851.156738,876.921082 848.757568,876.771362 847.109924,880.145508 
	C846.548157,881.295898 844.821228,881.877319 843.314575,882.858154 
	C842.153564,881.190613 840.410461,880.677002 838.413940,880.005493 
	C836.659119,879.417358 835.423645,878.616943 834.260254,877.182129 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M583.984863,996.511658 
	C589.482300,996.749817 593.309692,991.663269 598.750305,991.544128 
	C601.007751,991.494751 603.251831,990.831116 605.749512,990.682739 
	C602.216614,993.333740 599.115051,996.552612 594.771790,998.414490 
	C590.408508,1000.284912 587.374329,999.207458 583.984863,996.511658 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M608.263550,991.234985 
	C608.657593,990.209656 609.317932,989.482971 610.038086,988.460205 
	C610.347656,987.703369 610.712646,987.368347 611.558838,987.057373 
	C612.933228,986.952454 613.948242,987.019653 615.297241,986.594727 
	C616.919128,986.519043 618.174805,985.751709 619.694946,986.824951 
	C621.096924,987.096008 622.218079,987.101685 623.736328,987.267029 
	C624.024780,988.410217 623.934387,989.604004 622.805603,989.791382 
	C618.074951,990.576355 613.525452,992.935303 608.263550,991.234985 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M627.803284,983.262146 
	C631.046509,983.328796 634.095398,983.660034 637.567017,983.989990 
	C638.502441,985.429626 637.352600,985.350708 636.266479,985.784180 
	C633.869385,986.359253 631.743042,986.727600 629.256104,987.210327 
	C628.142517,987.230835 627.389526,987.136902 626.276123,986.914978 
	C625.777039,985.335083 626.404541,984.281067 627.803284,983.262146 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M636.388184,986.005859 
	C636.557495,985.485046 637.119324,984.979309 637.835388,984.231079 
	C639.032776,983.960327 640.075928,983.932007 641.509888,983.887939 
	C642.536255,983.463745 643.061279,982.926208 643.812439,982.220642 
	C644.018860,982.029602 643.990723,981.989746 643.979736,981.968018 
	C644.992493,981.172119 646.088684,980.932739 647.609497,981.808350 
	C647.547119,984.351196 649.473206,985.075867 650.807617,986.570923 
	C650.953979,987.299438 650.942200,987.747437 650.893188,988.525635 
	C648.442017,989.525269 645.890930,987.390320 643.409912,989.472900 
	C642.494873,990.241150 642.197510,988.431885 641.631958,987.756897 
	C640.377747,986.260193 638.625366,985.967651 636.388184,986.005859 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M702.726074,967.763550 
	C701.449036,974.358215 697.235657,977.343018 690.881714,976.926758 
	C687.414978,976.699646 686.751587,978.786987 686.036743,981.634888 
	C684.934937,982.011658 683.872559,982.001038 682.412598,982.007080 
	C681.560486,981.974548 681.106140,981.925415 680.309692,981.855103 
	C678.945190,982.182190 677.945557,982.588074 676.684082,983.193115 
	C675.009583,984.358704 675.495422,986.271301 674.187744,987.663147 
	C672.780762,987.778381 671.619751,987.662598 670.217041,987.290283 
	C669.669739,985.622559 669.364136,984.211365 669.001221,982.440796 
	C670.483032,980.237122 672.312439,978.821106 672.568909,975.918091 
	C672.841003,972.838806 678.434082,969.734802 682.262512,971.028076 
	C688.358215,973.087219 693.216614,970.943542 698.204224,968.190247 
	C699.518494,967.464722 700.883728,966.829041 702.726074,967.763550 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M675.765015,982.335205 
	C677.135376,981.529358 678.193726,980.596252 679.757446,981.612366 
	C679.530762,983.665771 679.543823,985.697144 677.370728,986.982849 
	C675.556946,985.925293 675.128967,984.441101 675.765015,982.335205 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M675.627930,982.079285 
	C676.330750,983.432922 676.663940,984.868774 677.011780,986.679443 
	C676.434753,987.906372 675.557922,988.011292 674.283691,987.932434 
	C674.416443,985.991455 673.007751,983.656250 675.627930,982.079285 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M703.137817,967.804382 
	C700.028259,967.643311 698.148132,969.948059 695.897278,971.167542 
	C692.137939,973.204407 688.257202,973.666321 684.368591,972.531067 
	C680.964539,971.537109 678.189026,972.697388 675.294128,973.899597 
	C674.223450,974.344299 672.759094,975.169800 673.463257,976.389893 
	C676.173584,981.085876 672.314575,981.080261 669.315186,982.050659 
	C668.522278,982.069458 668.100708,982.057434 667.363281,982.055603 
	C665.938049,981.918335 664.643982,982.188416 664.025391,980.429321 
	C665.153381,979.235291 665.235352,977.795044 665.618103,976.576538 
	C667.320251,971.156799 671.340271,968.438232 676.949219,968.944092 
	C680.167786,969.234436 683.542358,968.383545 686.441040,970.742126 
	C686.965210,971.168640 688.471802,970.902527 689.202148,970.443115 
	C690.368103,969.709595 689.836670,968.461975 689.572327,967.301514 
	C688.546936,962.800598 687.808289,958.824463 692.887329,955.425171 
	C695.117126,953.932800 697.134888,950.640076 698.662720,947.739868 
	C700.423157,944.398132 702.877197,943.971802 705.938354,946.605957 
	C699.795654,952.180237 699.465210,953.921997 703.344971,961.389343 
	C704.182007,963.000366 705.386230,964.510254 704.257080,966.769104 
	C703.735535,967.157837 703.475281,967.343811 703.137817,967.804382 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M648.305786,982.084229 
	C646.870911,982.085266 645.816345,982.049561 644.365234,981.979980 
	C645.567017,978.347778 648.768738,980.691772 651.588257,980.019531 
	C651.786621,982.152954 650.162781,981.978271 648.305786,982.084229 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M652.430298,980.023682 
	C652.212646,978.570190 652.402954,976.800293 654.017517,976.538879 
	C655.698547,976.266846 655.858459,977.939514 656.022827,979.599609 
	C654.969543,979.975952 653.899841,979.998718 652.430298,980.023682 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M658.179443,980.223938 
	C658.751526,978.966431 659.634155,978.813721 660.828003,979.830017 
	C660.240356,981.055542 659.359802,981.181519 658.179443,980.223938 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M701.800049,52.720894 
	C702.039917,54.392269 701.991333,55.791840 700.304932,56.839405 
	C698.089111,54.856297 696.346802,54.457001 694.458252,57.157585 
	C693.203918,58.951157 691.237732,59.506161 689.120239,58.804943 
	C687.024719,58.110981 687.681152,56.282532 687.361572,54.687393 
	C686.670776,51.238575 684.787048,48.074944 686.016113,44.260456 
	C686.431213,42.972321 684.404297,42.377346 683.200928,41.267616 
	C683.603577,39.395573 685.168945,39.000999 686.738708,38.101582 
	C687.773804,37.955601 688.512268,37.947491 689.620422,37.932709 
	C691.059814,38.249054 691.875549,39.184471 691.604553,40.064449 
	C690.439575,43.847511 693.125916,45.037949 695.797302,46.723724 
	C696.010376,46.996967 696.043762,47.464149 696.049927,47.698257 
	C696.531616,48.519745 697.059387,49.059467 697.778625,49.807503 
	C698.138245,52.339916 700.373169,51.643177 701.800049,52.720894 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M695.999878,46.627106 
	C688.802917,47.826023 690.090393,42.836643 690.127747,38.267639 
	C691.340942,37.349274 692.645203,36.542995 694.054932,36.256802 
	C695.636780,35.935673 696.521790,37.544128 695.583130,38.324829 
	C691.958496,41.339455 694.155151,43.717213 695.999878,46.627106 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M697.938843,49.693825 
	C697.063354,49.912273 696.290833,49.630684 696.079285,48.257740 
	C696.938660,48.072132 697.702087,48.364391 697.938843,49.693825 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M670.996582,30.995424 
	C670.911377,31.028967 670.829529,31.067038 670.878296,31.056000 
	C671.008850,31.006891 671.000000,30.999952 670.996582,30.995424 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M612.267578,29.266083 
	C611.235168,29.025387 610.492981,29.017963 609.379761,29.010927 
	C606.124695,31.079987 603.346680,30.883663 600.331421,28.311874 
	C599.451477,27.139029 598.589478,27.018343 597.341919,27.013924 
	C595.295044,27.830957 594.507568,26.266924 593.195984,25.298355 
	C592.139954,24.496775 591.259399,23.998945 590.187012,23.255348 
	C589.617554,22.611927 589.484070,22.144529 589.808838,21.288960 
	C591.448242,21.301586 593.206360,21.211760 594.243958,22.032625 
	C600.633667,27.087696 607.893860,27.394093 615.468445,26.373913 
	C616.912964,26.179367 617.542236,27.040504 617.735168,28.874878 
	C616.656738,30.083735 615.670532,30.199207 614.281860,30.152245 
	C613.419678,29.994434 612.969360,29.789553 612.267578,29.266083 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M611.991211,29.411470 
	C612.425232,29.024736 612.873169,29.016663 613.656860,29.008343 
	C615.048523,30.101067 616.230408,30.220867 617.764648,29.243526 
	C618.003601,29.021322 618.010681,28.985035 618.016968,28.966206 
	C620.057007,28.946840 622.090759,28.946304 624.560425,28.966660 
	C624.523315,33.519981 620.559448,33.330318 617.669434,33.820591 
	C615.087402,34.258617 612.373901,33.262955 611.991211,29.411470 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M590.016968,20.982052 
	C590.015442,21.423609 590.007751,21.876759 589.997559,22.669744 
	C588.564392,22.992649 587.133728,22.975714 585.269104,22.912331 
	C585.036316,22.339233 585.237366,21.812584 585.668457,21.114258 
	C586.936584,20.154322 588.053528,20.032852 589.634033,20.763149 
	C590.022400,20.979246 590.010681,20.993645 590.016968,20.982052 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M589.864319,20.682287 
	C588.924072,20.980394 587.825684,20.981541 586.312866,20.962635 
	C586.780334,18.109871 588.187134,18.863707 589.864319,20.682287 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M583.597168,22.944324 
	C583.399109,24.437729 582.260986,25.046705 580.365845,25.017822 
	C580.276428,24.525738 580.535706,24.035238 580.913879,23.293978 
	C581.765442,23.013433 582.497986,22.983652 583.597168,22.944324 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M648.805420,31.789772 
	C648.970154,32.468319 648.977112,32.908871 648.983643,33.680145 
	C646.848999,36.303783 643.984741,36.430435 641.185425,36.439568 
	C637.533264,36.451485 635.085266,34.997490 635.745483,30.399029 
	C638.296204,29.667921 640.775269,34.641781 642.971558,29.427635 
	C644.134033,29.304935 645.244690,29.523647 646.674316,29.878162 
	C647.544739,30.526566 648.096191,31.039169 648.805420,31.789772 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M635.995422,29.760159 
	C636.269653,34.954262 639.431396,35.567455 643.404907,34.913750 
	C644.989685,34.653038 646.561707,34.315269 648.561340,34.011726 
	C648.983154,34.010872 648.998474,34.004284 648.994141,34.010227 
	C652.252502,36.453449 650.281006,39.284420 649.412659,42.712013 
	C655.496704,36.714939 660.391296,40.481869 665.666565,43.893669 
	C666.441101,44.011440 666.887878,44.008739 667.669678,44.011284 
	C672.220093,44.248108 672.220093,44.248108 674.128723,50.866016 
	C675.285522,50.658211 675.523071,49.558392 676.120483,48.812988 
	C679.010010,45.207592 680.302246,45.879272 683.022522,49.308697 
	C687.001282,54.324837 684.388855,57.897602 681.623047,62.175812 
	C678.445740,66.145065 674.020508,65.045219 669.805176,66.010910 
	C668.624329,66.206284 667.839233,66.171364 666.693359,65.944611 
	C661.381104,62.211033 661.020630,60.713093 664.410889,56.381279 
	C665.854126,54.537113 667.076904,52.805653 664.956421,50.808998 
	C663.097168,49.058258 661.170654,49.430595 659.151367,50.852879 
	C653.292358,54.979740 652.533997,54.823441 648.306519,48.628857 
	C647.089722,46.056671 645.157410,45.025726 643.464050,44.468430 
	C636.750793,42.259071 633.280029,36.610401 629.039551,31.350025 
	C630.759033,31.227453 632.128418,30.437176 633.679443,29.167950 
	C634.430908,29.009644 634.877380,29.008375 635.658447,29.017536 
	C635.992981,29.027967 635.993286,29.516100 635.995422,29.760159 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M681.287842,62.269043 
	C681.075928,60.530392 680.645752,58.887184 681.951233,57.797630 
	C684.149170,55.963333 687.575928,54.318356 681.865173,52.560085 
	C680.761780,52.220375 681.176941,50.885887 681.501587,49.930855 
	C681.969360,48.554245 681.382324,47.643002 680.105835,47.258720 
	C678.648499,46.820019 678.074524,47.954838 677.519836,48.990250 
	C677.208496,49.571552 676.953674,50.184715 676.620728,50.752632 
	C675.818604,52.121048 675.352722,54.234856 673.232361,53.658371 
	C671.331787,53.141624 671.971558,51.150764 672.053833,49.796570 
	C672.220825,47.048489 671.829407,44.882984 668.266968,44.288731 
	C668.728577,42.523956 670.030396,41.428143 671.479370,42.372707 
	C675.503296,44.995758 678.863464,42.995975 682.666992,41.140198 
	C688.261963,41.468681 688.965637,42.492966 686.976929,47.417141 
	C686.496216,48.607464 685.971802,49.658714 687.088196,50.467384 
	C689.308044,52.075367 690.726318,53.584698 688.330750,56.236897 
	C687.775208,56.851898 688.662598,57.746258 689.590759,57.877411 
	C691.518860,58.149860 693.282227,57.953243 693.920593,55.694508 
	C694.372070,54.097034 695.230347,53.042538 697.004761,53.071926 
	C699.052307,53.105831 699.927124,54.290310 699.988770,56.612659 
	C699.997559,56.997673 700.000000,57.000000 699.998779,56.998981 
	C698.882812,57.838154 697.071167,58.297234 697.813843,60.271423 
	C698.384094,61.787453 699.596924,62.672501 701.280762,62.367325 
	C704.203369,61.837624 705.788391,63.779934 707.739807,65.776733 
	C707.974487,66.019341 708.000000,66.000694 707.990234,66.013069 
	C708.460876,66.487732 708.036072,65.838623 708.298889,66.396271 
	C710.412842,70.882683 714.115662,65.565193 716.685486,67.825874 
	C718.710999,69.607773 720.329346,73.170006 724.368896,70.237381 
	C726.182007,70.059425 727.256287,70.647850 728.117676,72.280441 
	C729.114685,75.007637 729.675476,77.438171 729.079224,79.917374 
	C728.340149,82.990845 729.726868,84.198654 732.573975,84.409515 
	C734.380188,84.543289 736.206848,84.438477 737.992188,84.805695 
	C740.430054,85.307159 742.469421,86.468872 742.681824,89.159912 
	C742.918030,92.154076 740.641113,92.976730 738.233582,93.440590 
	C736.939392,93.689949 735.639648,93.913925 733.943237,94.115829 
	C726.277954,92.580597 720.948608,87.946724 715.626343,82.642242 
	C713.373230,75.898766 707.322571,75.092453 702.346924,74.723854 
	C696.117798,74.262398 692.813293,71.533775 691.272888,66.105087 
	C690.296631,62.664555 688.309509,62.082169 684.942261,62.965656 
	C683.524780,63.167435 682.511230,63.069042 681.287842,62.269043 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M665.997131,43.591778 
	C663.680115,45.725674 662.382263,43.567871 660.969299,42.524136 
	C657.549805,39.998215 654.435730,39.842243 651.525024,43.184845 
	C650.665222,44.172203 649.930969,45.680119 648.437988,44.320858 
	C647.051758,43.058781 646.771057,41.453480 648.438477,40.085613 
	C650.398621,38.477608 649.869690,36.637360 649.034119,34.375534 
	C653.196594,35.592575 657.727417,33.198246 662.279907,36.373180 
	C665.350647,38.514675 666.080994,40.157383 665.997131,43.591778 
z"
    />
    <path
      fill="#F5F4F3"
      opacity="1.000000"
      stroke="none"
      d="
M678.772827,95.803986 
	C679.865723,96.159447 680.720398,96.326996 681.786255,96.749138 
	C683.911682,99.177734 686.453003,99.851250 689.537781,100.041122 
	C690.083862,100.109543 690.298706,100.153946 690.812012,100.329460 
	C691.976013,101.052933 692.809448,101.713242 694.239258,101.854599 
	C694.993713,102.027420 695.410217,102.184616 696.097839,102.574661 
	C696.538147,102.943016 696.693909,103.115105 697.041504,103.578354 
	C697.643372,105.037361 698.552979,105.871033 699.587341,106.272240 
	C706.737488,109.045715 712.849121,114.117287 720.869507,115.786934 
	C722.570007,116.280426 723.940063,116.644379 725.626526,117.166168 
	C729.208923,118.891899 732.618164,120.156731 735.774109,121.983833 
	C737.555115,123.014954 739.527100,123.264252 741.841309,122.247772 
	C742.896973,122.017105 743.608276,122.039894 744.651978,122.341125 
	C750.881836,131.562271 761.458130,134.312393 769.790527,140.672424 
	C770.333435,140.902557 770.543518,141.010559 771.047607,141.315369 
	C774.740356,144.618423 777.612061,148.320892 782.010315,150.797089 
	C782.530579,151.052948 782.730896,151.169785 783.204346,151.500275 
	C785.008240,153.593430 786.549805,155.397293 786.600952,158.084091 
	C786.639709,160.120743 788.187378,160.696991 790.383789,160.600662 
	C792.045898,160.598419 793.327515,160.711975 794.921082,161.356247 
	C797.887207,164.829208 796.328247,166.543076 792.620789,167.537354 
	C790.628357,165.945221 788.842773,165.096954 786.495972,165.260300 
	C784.125732,165.425278 782.101013,164.464569 781.839294,161.822357 
	C781.471497,158.109283 779.249634,157.169876 775.655273,157.358383 
	C774.801147,157.282410 774.319885,157.192139 773.498047,156.940979 
	C770.427856,154.867157 767.920532,152.503403 763.970215,152.117188 
	C763.379883,151.922409 763.148560,151.827820 762.592285,151.543274 
	C760.118652,149.496323 758.605042,147.371124 759.823486,144.383743 
	C760.836243,141.900909 759.380981,140.671997 757.224731,139.252502 
	C756.349976,140.532257 756.484192,142.875885 754.167297,142.908035 
	C751.609192,142.943558 749.180786,141.926147 748.085938,139.998611 
	C743.847778,132.537460 736.138794,132.185913 729.226990,130.553619 
	C723.885742,129.292221 718.970947,127.754997 715.250244,123.521774 
	C714.586975,122.767143 713.833191,121.954124 712.867371,121.754791 
	C705.133911,120.158607 699.882141,114.506775 693.860352,110.147064 
	C690.986145,108.066162 687.987793,107.052811 684.499695,107.725716 
	C677.587463,109.059143 672.145569,106.376541 667.457397,101.575928 
	C665.945801,100.028053 664.751831,98.155067 662.969421,96.892242 
	C659.094666,94.147041 655.196045,91.834991 650.039734,93.734856 
	C645.396729,95.445580 641.550232,93.161461 638.179871,90.240059 
	C635.831543,88.204491 633.602478,87.845207 630.450378,88.352577 
	C624.551758,89.302040 619.509155,85.926331 614.726868,83.149796 
	C610.740234,80.835213 607.083618,78.552605 602.259216,79.110641 
	C600.760681,79.283966 599.339844,78.510818 597.975098,77.881294 
	C594.960999,76.490906 591.920715,76.176582 588.775330,77.462120 
	C586.194519,78.516922 583.382019,78.789551 580.981506,77.507500 
	C573.514465,73.519646 565.124512,74.142242 557.261169,72.276840 
	C548.663696,70.237282 540.136353,68.250389 531.259888,67.914513 
	C528.437195,67.807709 525.748657,66.625252 523.534180,64.687683 
	C520.247864,61.812256 516.017761,61.225929 512.057007,60.045593 
	C509.618408,59.318874 507.833405,60.581551 506.755951,62.736649 
	C505.371796,65.505035 503.494873,67.454315 500.125885,66.838219 
	C496.738922,66.218864 496.684753,63.262863 496.009857,60.600948 
	C495.788879,62.451748 496.814270,64.399345 495.040619,65.913979 
	C493.431793,67.287857 491.625519,68.418785 489.603363,67.787849 
	C484.135376,66.081764 479.282227,68.619331 474.745453,70.477249 
	C466.491333,73.857498 458.033661,75.619896 449.355286,77.399353 
	C438.652252,79.593956 427.703705,81.099556 417.351318,84.962967 
	C413.462769,86.414131 409.491119,87.795357 405.171631,86.950195 
	C403.844269,86.690475 402.494843,86.983887 401.268616,87.558815 
	C394.810822,90.586533 387.531830,91.708061 381.591278,95.898735 
	C378.944366,97.765953 375.191101,96.637314 372.607941,99.533951 
	C369.336670,101.098465 366.355804,102.368996 362.673279,102.424637 
	C361.451752,102.461853 360.581787,102.491943 359.672516,103.395477 
	C358.936737,104.022110 358.396637,104.370049 357.530701,104.800896 
	C356.024597,105.306587 354.775360,105.611305 353.686157,106.904556 
	C352.510406,107.847366 351.416046,108.098289 349.944122,108.274872 
	C348.236237,108.320015 346.914368,108.502068 345.464172,109.530212 
	C344.924469,109.785835 344.699860,109.875145 344.121460,110.062424 
	C338.502197,110.246071 333.526764,111.224655 329.365906,114.774811 
	C328.993744,115.092331 328.512726,115.379791 328.037903,115.446716 
	C317.621063,116.915108 310.761017,125.391693 301.510925,128.912888 
	C296.263916,130.910248 291.465607,134.153503 285.933533,135.157578 
	C282.152496,135.843826 280.218475,137.435471 280.119751,141.633499 
	C279.443787,143.579437 278.365021,144.575073 276.317474,144.891785 
	C272.095245,143.448959 270.070221,146.218750 267.558777,148.664307 
	C267.139313,149.036987 266.966461,149.178436 266.522888,149.512421 
	C265.329437,150.271927 264.398499,150.827698 263.414673,151.860809 
	C261.413666,154.151276 258.660461,155.021225 256.790741,157.492874 
	C254.935394,159.060440 254.148041,161.476654 251.229080,161.430603 
	C250.037292,160.727097 249.581512,159.848312 248.971710,158.632416 
	C247.753555,155.813538 246.373779,153.384033 247.531906,150.159409 
	C247.580811,149.285751 247.605850,148.783554 247.659027,147.896912 
	C247.765610,146.728943 247.987411,145.987411 248.582840,144.975967 
	C249.890488,143.842407 251.171906,143.715576 252.810699,143.540924 
	C255.400513,143.603516 256.174469,141.701645 257.335205,140.399551 
	C260.642242,136.689774 264.103760,133.220978 269.306641,131.504425 
	C272.894928,126.354340 278.922516,125.785477 283.611664,123.716957 
	C291.393036,120.284355 297.955414,114.898849 306.041809,111.529518 
	C307.383636,111.003334 308.444275,110.709358 309.779144,110.244019 
	C310.841187,109.847847 311.602570,109.549385 312.475464,108.804245 
	C312.903839,108.508720 313.085358,108.404259 313.562927,108.193146 
	C315.056885,107.756882 316.332336,107.631210 317.596680,106.595329 
	C319.501312,105.931831 321.103699,105.362503 322.805054,104.244080 
	C323.890228,103.852173 324.736115,103.673866 325.786346,103.237793 
	C331.658630,99.655525 337.548126,96.779404 343.970947,94.228821 
	C346.737579,94.281731 349.238556,94.846916 351.543030,92.694641 
	C352.009094,92.377533 352.210632,92.273727 352.734497,92.062469 
	C355.196533,91.560242 357.582092,91.859070 359.405640,89.583580 
	C360.267975,88.960060 360.902771,88.638840 361.894470,88.219040 
	C365.315155,87.568825 368.495117,87.383446 371.476562,85.262360 
	C374.612701,83.209923 378.157318,84.225029 381.398315,82.545540 
	C381.960449,82.257034 382.193604,82.157257 382.790771,81.946098 
	C385.169128,81.663826 387.050476,81.170937 388.609467,79.768044 
	C393.281738,75.563568 399.072449,74.329803 405.056854,73.654617 
	C406.369446,73.506531 407.718353,73.613586 409.016144,73.410927 
	C414.493591,72.555557 419.870483,69.949913 425.795227,72.316132 
	C426.265747,71.903130 426.464600,71.747841 426.997925,71.410187 
	C431.019836,70.874496 434.751129,71.133492 437.746490,67.970596 
	C439.302124,66.327965 441.826508,66.899826 443.836853,67.968033 
	C448.411865,70.398941 453.116119,69.906845 457.820038,68.599861 
	C459.585083,68.109459 461.318024,67.573662 463.157013,67.557320 
	C466.222046,67.530098 468.644562,66.571663 469.820892,63.436108 
	C470.740662,60.984440 472.769073,60.011101 475.237000,59.702526 
	C476.844971,59.501476 478.601959,59.894291 480.263306,58.358101 
	C482.144714,56.984627 483.964569,56.638176 486.236511,56.469967 
	C491.975800,54.524445 497.328278,52.844097 503.112488,54.640457 
	C504.737213,55.145042 506.466064,54.848061 507.976105,54.059254 
	C512.391541,51.752693 516.811829,52.766117 521.066101,54.252354 
	C529.270142,57.118462 537.619690,58.837650 546.336914,58.685505 
	C550.346252,58.615532 553.561890,60.154980 555.063599,64.056976 
	C556.027588,66.561630 557.670227,66.867432 560.097473,66.971344 
	C566.171570,67.231400 572.061279,68.347603 578.346619,67.216461 
	C582.833679,66.408936 587.760498,68.870720 592.303040,70.420753 
	C596.167175,71.739311 599.721497,72.090973 603.345398,70.082390 
	C607.048523,68.029930 610.648804,67.984695 614.043396,70.711449 
	C615.488831,71.872475 616.983459,71.922569 618.719482,71.543457 
	C623.962036,70.398560 628.579651,71.649139 632.723083,75.725174 
	C633.500183,76.256157 633.993042,76.518341 634.742920,76.890411 
	C638.215088,78.914383 642.257996,78.425484 645.697144,80.786697 
	C646.003906,80.993668 646.000000,81.000000 646.003174,80.996933 
	C648.067810,82.355118 650.366821,83.289436 652.731689,84.793640 
	C654.102295,85.311485 655.204590,85.623276 656.651001,85.972366 
	C657.963623,87.011787 659.167480,87.340202 660.844116,87.206367 
	C661.602234,87.167824 662.032410,87.208145 662.761475,87.406799 
	C663.962952,88.061241 664.813110,88.700981 666.168701,89.067406 
	C666.658142,89.308594 666.844666,89.426743 667.238525,89.805527 
	C669.770691,92.017250 672.906738,92.129501 675.717529,93.801422 
	C675.989624,94.007019 676.000000,94.000603 675.996704,94.006508 
	C676.683044,94.796494 677.607361,95.208580 678.772827,95.803986 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M249.190964,144.700470 
	C249.020172,145.764938 249.010773,146.507767 248.996582,147.622025 
	C245.599091,150.276657 242.304153,152.617294 237.754532,151.695877 
	C236.225845,151.386261 234.149124,151.758163 235.460770,154.753906 
	C234.351608,160.745255 230.706253,162.574234 225.941284,159.752274 
	C224.455338,158.872223 223.177139,157.986542 221.351669,158.607254 
	C218.514771,159.571930 215.890945,158.062759 213.272873,157.849777 
	C208.390366,157.452545 203.180588,156.875214 198.529175,157.496185 
	C194.077301,158.090515 190.465714,160.334488 187.447464,155.266724 
	C186.742508,154.083115 185.172485,153.981079 183.806046,154.018387 
	C181.269287,154.087646 180.989532,155.321136 182.214020,157.244980 
	C183.158386,158.728714 184.804810,159.267303 186.196884,160.159576 
	C188.237717,161.467651 189.201004,163.428711 188.366135,165.725021 
	C187.577148,167.895172 185.596756,167.313675 183.713028,167.400162 
	C175.318237,167.785568 172.736618,169.664917 171.888885,180.045090 
	C171.247803,181.598358 171.270813,182.744217 172.402924,184.068207 
	C172.351456,187.507416 170.154327,186.972137 168.160583,186.776947 
	C162.263077,186.199539 158.577850,189.910309 159.669098,195.408417 
	C159.984436,196.997238 159.914505,198.707474 158.737473,199.599670 
	C155.285431,202.216309 154.250595,206.599091 151.266785,209.416061 
	C149.145660,211.418610 149.899704,212.776459 151.489197,214.758408 
	C151.791718,215.583862 151.853134,216.084656 151.785431,216.982483 
	C151.137802,219.200241 150.363144,220.832443 147.668106,220.648621 
	C145.690475,218.138687 144.616501,215.069336 140.501450,216.378799 
	C139.348801,216.745590 138.324554,215.154602 137.526733,213.662140 
	C137.262344,212.901245 137.199829,212.446091 137.222961,211.638916 
	C138.495789,208.162521 135.880814,207.624588 133.805542,206.309586 
	C133.426636,205.866776 133.305145,205.664719 133.063446,205.129257 
	C132.138947,200.057861 134.832611,196.116806 136.362946,192.073669 
	C137.609726,188.779694 140.368256,186.198700 145.081833,187.548599 
	C148.562988,188.545547 151.180313,186.215988 151.403870,181.814056 
	C151.613281,177.690231 154.875290,174.236801 153.521301,169.803436 
	C153.138901,168.551346 154.684875,167.471344 156.115356,167.276596 
	C157.641571,167.068802 159.114960,166.944855 159.798828,164.828369 
	C160.444641,163.650162 161.119202,162.964249 162.197495,162.178268 
	C171.130768,157.881485 175.864578,151.034637 176.868958,141.199280 
	C176.914352,140.338333 177.022446,139.854889 177.351868,139.039581 
	C182.492630,135.286377 187.600037,133.702545 193.489075,137.559998 
	C195.841431,137.873871 197.356949,139.306290 199.601105,139.866791 
	C201.026764,140.392181 202.146667,140.693710 203.622849,140.997452 
	C205.042999,141.392365 206.127441,141.712341 207.493195,142.233063 
	C208.880402,143.032211 209.934250,143.767975 211.573303,143.933624 
	C214.359406,143.971619 216.449097,143.096863 218.344482,140.992996 
	C218.754028,140.582001 218.934357,140.431122 219.418945,140.094086 
	C224.474930,138.065689 229.062271,136.885208 234.075546,139.706696 
	C237.009308,141.357834 239.978973,139.367508 242.192123,137.348419 
	C244.129471,135.580933 242.653671,133.760590 241.388626,132.108261 
	C237.836929,127.469215 238.419678,125.459984 243.932449,123.621185 
	C245.717361,123.025810 246.815735,122.589233 246.216751,120.199844 
	C244.355499,112.775429 249.474777,107.302422 256.951202,108.966499 
	C259.942749,109.632339 261.497009,109.747108 262.212341,106.311615 
	C262.814056,103.421707 265.001495,101.806343 268.004974,101.694618 
	C272.187164,101.539062 275.716675,99.852081 279.316650,97.193909 
	C281.956268,96.373413 284.203461,96.232201 286.684723,97.703262 
	C288.483185,102.860855 292.146851,101.184143 295.052063,100.339638 
	C300.715790,98.693291 301.848297,99.285202 301.113190,104.922539 
	C300.850769,106.934692 302.227448,107.865906 302.940491,109.636154 
	C300.585358,112.188873 297.958008,111.262611 295.543152,110.195595 
	C292.700623,108.939629 290.539734,108.983192 288.715515,111.855492 
	C286.613007,115.166054 283.649933,116.083237 280.132782,114.072594 
	C277.655243,112.656288 276.923492,114.098160 275.556122,115.945839 
	C271.682739,121.179832 267.333618,126.147499 259.922424,125.754433 
	C256.235809,125.558899 254.456085,128.487320 251.755829,129.887589 
	C248.069916,131.798996 248.741455,134.375992 249.212463,137.419556 
	C249.564468,139.694214 249.789169,142.050705 249.190964,144.700470 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M249.442871,145.018585 
	C246.878738,141.134232 249.139923,136.337631 246.436096,132.517456 
	C245.948883,131.829102 246.616867,130.548523 247.382919,130.531601 
	C250.873917,130.454498 252.038879,127.125282 254.527420,125.694016 
	C255.101227,125.364006 255.684875,124.538673 256.153229,124.615593 
	C262.027618,125.580223 266.353455,123.296463 270.666351,119.342659 
	C272.109283,118.019836 273.707062,117.610443 274.259155,115.197723 
	C275.195007,111.108086 277.181305,110.675621 280.764526,113.032928 
	C283.481659,114.820442 285.829376,114.437866 287.329468,111.387917 
	C289.307007,107.367210 291.911133,106.035980 295.890961,108.920570 
	C297.750732,110.268539 300.006592,110.020554 302.587433,109.991455 
	C304.072510,109.992882 305.136932,109.997429 306.600677,110.000198 
	C307.349762,110.396393 307.494781,110.858589 307.217499,111.693275 
	C299.826599,115.258804 294.461395,121.729660 286.374054,123.447105 
	C284.337097,123.879677 282.348938,125.031296 280.825714,126.674782 
	C279.430908,128.179672 278.120422,129.318192 275.658508,128.500061 
	C272.952484,127.600815 272.693390,131.226135 270.345642,131.949036 
	C265.155426,133.173920 260.728149,135.965302 255.322540,135.089371 
	C252.394699,134.614929 251.293335,136.335251 253.190674,139.033127 
	C254.352386,140.685013 255.614120,142.265488 254.230804,144.718201 
	C252.618317,145.002380 251.237259,145.008728 249.442871,145.018585 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M287.331848,97.952278 
	C284.627411,98.015358 282.265350,98.012733 279.460571,98.013069 
	C272.199768,97.867935 265.548492,100.286674 258.342224,99.167801 
	C256.453308,95.776169 258.395660,92.905563 258.998840,89.528015 
	C259.496124,89.130981 259.915771,89.098488 260.651733,89.067047 
	C261.988770,89.740677 263.001099,90.422966 263.837708,91.728455 
	C263.968414,95.532768 265.899689,95.368843 268.644775,94.125374 
	C272.441681,92.694168 276.584534,93.647263 279.538849,90.480675 
	C280.374329,89.585175 282.448608,89.608757 282.741821,91.342674 
	C283.702972,97.026756 286.804840,93.670494 289.634705,93.029015 
	C289.482300,94.746292 289.555420,96.778862 287.331848,97.952278 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M353.250000,92.745361 
	C352.994507,92.988152 352.504608,93.001099 352.259613,93.006134 
	C351.621918,93.384377 351.159973,93.517723 350.311829,93.202568 
	C350.009583,84.762428 351.359955,82.641090 358.627899,80.001999 
	C358.995117,79.995117 359.000000,79.999901 359.001221,80.001328 
	C360.553253,82.201851 362.498657,84.179749 362.870941,87.377975 
	C362.700867,87.972725 362.588867,88.196495 362.237793,88.709686 
	C361.524628,89.312424 361.050446,89.625748 360.279846,89.964447 
	C358.178406,91.740784 355.387756,90.950760 353.250000,92.745361 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M345.363464,94.997223 
	C338.909210,97.643517 332.820892,100.276497 326.361633,102.944763 
	C326.102936,102.500580 326.073151,101.713600 326.348145,101.586609 
	C333.327728,98.363632 339.221161,92.593575 347.765259,92.742264 
	C348.383575,94.972740 346.829620,94.718193 345.363464,94.997223 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M307.349731,111.996643 
	C306.999786,111.556404 306.999603,111.111275 306.999695,110.332291 
	C308.086975,107.834206 309.795105,106.758293 312.627441,106.999680 
	C313.378326,107.386978 313.525909,107.847839 313.226227,108.695740 
	C313.009766,109.008873 313.000000,109.000000 313.004089,109.004608 
	C312.465759,109.532845 311.929474,110.062691 311.194855,110.793991 
	C309.897095,111.327141 308.798279,111.659447 307.349731,111.996643 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M322.636719,104.028427 
	C322.566223,106.403809 320.676239,106.729218 318.380463,107.006088 
	C317.007629,107.831779 315.901978,108.057617 314.336700,107.281281 
	C315.741241,103.233437 319.751984,105.757957 322.636719,104.028427 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M345.234833,95.269409 
	C345.870392,94.505142 346.743195,93.999756 347.807495,93.249451 
	C348.442566,93.002396 348.886139,93.000259 349.662292,92.996002 
	C350.443604,92.996773 350.892334,92.999649 351.677856,93.006851 
	C350.641602,95.949898 348.019531,95.642113 345.234833,95.269409 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M313.746338,106.995316 
	C315.067169,106.997375 316.139191,107.001274 317.613647,107.011337 
	C317.560455,109.699280 315.418762,108.803452 313.402435,109.007851 
	C313.006897,108.562424 313.004028,108.115974 313.000610,107.334763 
	C313.000000,107.000000 313.497559,106.997162 313.746338,106.995316 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M330.182678,79.245239 
	C330.467987,78.703201 330.933105,78.374863 331.694946,78.018616 
	C331.816010,78.887779 331.853302,80.021202 330.182678,79.245239 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M284.358887,83.055450 
	C281.167328,83.090393 280.583496,81.622871 281.432281,79.188133 
	C282.571350,75.920914 282.113098,73.354164 278.504547,71.948189 
	C276.336060,71.103317 277.036896,69.680962 278.136566,68.445183 
	C279.393433,67.032784 281.327881,66.243851 282.267914,68.163452 
	C284.232422,72.175110 288.219360,72.601364 291.389648,72.740471 
	C296.252228,72.953842 297.542053,74.896423 297.008179,79.581192 
	C296.255066,80.013710 295.498962,80.017700 294.364929,80.017944 
	C293.255280,79.363258 292.455078,79.073059 291.264313,79.780182 
	C289.468719,82.188217 288.107147,84.747612 284.358887,83.055450 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M312.252838,70.307007 
	C312.006714,69.591827 312.005341,69.132195 312.010986,68.328056 
	C313.391357,68.306015 315.810730,67.167648 315.620148,69.773254 
	C315.495056,71.483704 313.754120,71.036201 312.252838,70.307007 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M291.389282,80.014038 
	C291.424774,77.480309 292.479919,78.026314 293.853180,79.730904 
	C293.244812,80.013466 292.502563,80.012726 291.389282,80.014038 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M264.238220,91.813797 
	C262.462494,91.920479 261.421417,91.255112 261.014069,89.397659 
	C262.665131,88.438484 262.809875,86.736526 263.745972,85.201385 
	C264.425659,84.595100 264.795471,84.155273 264.990540,83.286728 
	C265.018616,82.553726 265.015503,82.109550 265.010071,81.332214 
	C266.875946,78.394943 269.122833,77.030861 272.782349,78.817940 
	C268.106598,82.072884 269.215424,88.606171 264.238220,91.813797 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M289.974243,62.346474 
	C293.513947,61.331726 296.922424,58.150318 300.635986,61.191658 
	C301.597870,61.979408 304.224915,61.456318 303.340820,63.801022 
	C302.698395,65.504799 301.208618,66.163704 299.286713,65.275177 
	C298.804901,65.052414 298.054016,65.411575 297.211365,65.761856 
	C296.257538,66.027924 295.519165,66.035423 294.411713,66.056702 
	C292.706909,67.011703 291.526642,66.564705 290.170715,65.331329 
	C289.954132,64.242256 289.964172,63.484165 289.974243,62.346474 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M289.622192,65.055435 
	C291.075989,65.318306 292.207916,65.636261 293.691223,66.012344 
	C293.258728,68.884445 291.183105,68.197350 289.435333,67.530640 
	C288.352081,67.117416 287.624268,66.015030 289.622192,65.055435 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M487.275421,57.723732 
	C485.214203,58.083031 483.367340,57.699310 481.350098,58.845882 
	C474.797852,59.857182 469.056366,62.121712 462.227448,60.550541 
	C454.838440,58.850510 446.882507,56.787388 438.355286,59.669842 
	C441.541321,61.034081 443.935608,59.686535 446.356384,59.739746 
	C449.633514,59.811783 452.685028,60.456230 454.513977,63.519009 
	C455.240479,64.735611 455.075195,66.178421 454.072906,67.234428 
	C451.499420,69.945816 448.237000,69.673592 445.278961,68.443626 
	C438.641846,65.683907 433.151550,68.526062 427.357544,71.752968 
	C427.125427,72.048737 427.061401,72.528191 427.032898,72.768356 
	C425.721191,73.999252 424.775726,73.267616 424.188171,72.227730 
	C422.296509,68.879662 419.676453,69.183609 417.184448,71.070053 
	C413.006317,74.232857 409.181702,74.228554 405.188721,70.299492 
	C406.292816,67.103432 408.109100,66.062004 411.599823,66.559090 
	C417.705017,67.428490 423.430023,66.287758 427.286102,60.558739 
	C429.532684,57.220940 432.982666,55.970982 436.823578,54.874767 
	C443.989349,52.829605 450.665375,53.818371 457.639191,55.805580 
	C464.131470,57.655582 470.941681,56.503983 477.570831,55.805557 
	C479.950348,55.554863 482.629639,54.629436 481.536224,50.926235 
	C480.792297,48.406727 482.968048,46.773403 484.583893,46.515610 
	C488.609924,45.873291 492.744598,44.261410 496.517975,44.417740 
	C502.179474,44.652279 506.475250,41.562988 511.475830,40.409771 
	C513.674072,39.902828 514.390198,37.069016 513.349426,34.467075 
	C512.850708,33.220230 512.666992,31.725727 514.453064,31.206451 
	C516.055176,30.740665 516.964539,31.771084 517.491760,33.100163 
	C518.726929,36.214169 518.908752,40.239613 524.402832,37.669323 
	C526.393066,36.738247 526.697571,40.118176 526.827942,41.601017 
	C527.186340,45.676434 529.782227,45.965706 532.766602,45.554634 
	C540.583008,44.477974 541.631836,45.077152 544.108459,52.327824 
	C545.322937,55.883327 549.613586,54.987915 551.831360,57.690437 
	C552.130981,58.753220 551.793762,59.574158 551.119141,59.441441 
	C546.153564,58.464466 540.816040,60.118275 536.116943,57.097000 
	C532.970703,55.074112 529.502686,54.257694 525.642883,54.941727 
	C522.064270,55.575920 519.464111,54.058910 518.531921,50.347214 
	C517.823669,47.527325 516.039917,46.643921 513.310608,47.173382 
	C508.705963,48.066635 503.738403,47.683853 500.735229,52.748528 
	C499.294891,55.177486 495.765778,53.737926 493.281738,54.766617 
	C491.319336,55.579292 489.101074,55.744617 487.275421,57.723732 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M487.374329,57.950874 
	C488.580902,53.419445 492.993622,53.919315 496.421875,53.162693 
	C498.941132,52.606697 500.867371,52.392090 501.172791,49.331768 
	C501.352814,47.527779 502.871155,47.191265 504.485779,46.981297 
	C507.752655,46.556473 511.022308,46.067436 514.239319,45.367874 
	C517.851074,44.582470 519.837402,46.015423 519.926575,49.539738 
	C520.010376,52.846775 521.850830,54.561867 524.568237,53.750595 
	C529.157837,52.380409 532.948120,53.243591 536.753967,55.766090 
	C541.238647,58.738541 546.507996,57.965515 551.744324,58.270317 
	C552.444153,58.000835 552.888306,58.000954 553.665527,58.001572 
	C557.849731,60.323246 561.387024,58.045700 564.877563,57.026039 
	C571.356506,55.133404 577.827271,54.476151 584.476562,55.702908 
	C587.558655,56.271538 590.213867,57.429615 591.380188,60.636158 
	C592.087585,62.581047 593.563904,63.502384 595.479492,63.785103 
	C603.955078,65.036011 612.147217,67.987022 620.825989,68.012253 
	C622.211426,68.016281 623.430298,68.423546 624.705322,69.720436 
	C628.338135,70.725037 631.565735,71.612328 632.965088,75.644302 
	C629.543396,75.530243 627.074707,72.215317 623.308533,72.603020 
	C623.160217,72.618294 622.967957,72.409477 622.846069,72.451126 
	C617.868347,74.151978 613.690491,72.734123 609.365173,69.947639 
	C607.001526,68.424911 604.256897,71.111320 602.155396,72.308617 
	C599.092041,74.053825 596.182800,73.589897 593.699463,72.487663 
	C591.367065,71.452408 588.988159,71.041420 586.634033,70.409416 
	C581.946411,69.150932 577.433960,67.096085 572.294067,69.767639 
	C569.714661,71.108345 566.522339,68.964630 563.566467,68.634712 
	C562.274048,68.490456 561.001587,67.749794 559.687988,68.274307 
	C555.909546,69.783035 554.475464,68.611946 553.751770,64.519608 
	C553.007080,60.309040 549.094543,59.938255 545.417847,60.010277 
	C537.164734,60.171947 529.246521,58.726093 521.439026,55.898178 
	C517.275818,54.390224 512.733032,52.378254 508.187744,55.714752 
	C506.390747,57.033855 504.092438,56.743069 501.975800,55.990433 
	C496.907379,54.188198 492.269318,55.614475 487.374329,57.950874 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M624.958130,69.640282 
	C619.716858,69.356812 614.379089,69.483925 609.126282,68.092476 
	C603.612793,66.631996 597.897827,65.941124 592.292114,64.809151 
	C591.588562,64.667099 590.472290,63.894588 590.465454,63.401421 
	C590.412537,59.593052 588.418518,57.389336 584.838379,57.242741 
	C581.918579,57.123184 579.124756,56.398876 576.066528,56.570583 
	C569.883728,56.917728 564.018066,58.268604 558.105469,59.764759 
	C556.723389,60.114510 555.094543,60.535854 554.081543,58.371464 
	C559.434631,58.123203 564.578125,56.939068 569.235168,54.142742 
	C570.783203,53.213249 572.291626,53.013447 573.967468,52.991364 
	C577.943481,52.938972 581.877686,52.990807 585.863770,52.180271 
	C589.806152,51.378609 593.504883,52.310936 595.150085,56.866737 
	C596.049316,59.356762 597.974609,59.447758 600.019226,58.307858 
	C602.049255,57.176121 603.955750,55.821621 605.992615,54.703655 
	C607.835327,53.692238 609.944519,53.378036 611.597534,54.806965 
	C613.112976,56.116947 613.400879,57.944004 611.887756,59.681747 
	C611.445129,60.190018 611.113159,60.852459 611.695862,61.442738 
	C612.418884,62.175274 613.320740,61.904110 613.950500,61.372868 
	C616.850159,58.926769 618.788391,60.371021 619.489136,63.210571 
	C620.273987,66.390739 623.911499,66.578163 624.958130,69.640282 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M404.661804,69.994980 
	C406.159027,70.005844 407.426758,69.912445 408.253723,70.872162 
	C410.780090,73.804062 413.286957,72.508690 415.633392,70.790993 
	C421.534302,66.471306 422.664612,66.647690 426.645203,72.932564 
	C425.753723,76.491943 423.784271,74.991966 422.125244,73.548752 
	C420.229645,71.899742 418.605896,71.794739 416.344879,73.018074 
	C411.432159,75.676117 405.739075,74.573387 400.478424,75.827431 
	C396.239227,76.837997 392.181519,77.867142 389.222412,81.292023 
	C387.729706,83.019699 385.811218,83.084557 383.399200,82.999352 
	C382.544647,82.363487 382.737518,81.723785 383.135620,80.778305 
	C383.619507,79.880379 384.013214,79.326645 384.626617,78.510406 
	C386.623505,75.419128 389.624603,75.068367 392.754150,74.239281 
	C393.788300,73.688179 394.573334,73.369949 395.676178,73.022606 
	C397.525024,70.543266 400.349030,71.422554 402.733429,70.241203 
	C403.444061,70.000214 403.886902,69.998291 404.661804,69.994980 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M645.850464,80.691223 
	C642.190674,80.538719 638.254028,80.492867 635.070618,77.358353 
	C639.173401,76.211166 643.151550,76.038445 645.850464,80.691223 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M392.669006,74.004448 
	C391.631195,77.219337 387.058319,75.633087 385.353699,78.937775 
	C383.784546,75.854027 385.076691,74.416519 388.618958,74.126465 
	C389.443939,74.000000 389.887848,73.999397 390.664673,73.998169 
	C391.443268,73.999199 391.889008,74.000847 392.669006,74.004448 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M652.843262,84.690094 
	C650.222839,84.899948 647.821411,84.019753 646.062927,81.340355 
	C649.044678,80.521881 651.054565,82.078499 652.843262,84.690094 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M402.589539,70.001579 
	C402.057800,73.420135 398.541931,71.299065 396.374939,72.948044 
	C396.576233,68.939537 399.766632,70.275352 402.589539,70.001579 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M661.220581,87.687759 
	C659.005859,89.559853 658.060547,88.078850 657.045898,86.330688 
	C657.746399,86.006905 658.497620,86.004135 659.624451,85.999924 
	C660.000000,85.998474 660.496338,86.003036 660.744507,86.004570 
	C661.374878,86.387802 661.524414,86.845467 661.220581,87.687759 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M696.300232,103.138489 
	C695.561096,102.997070 695.122253,102.993774 694.354187,102.987274 
	C693.258423,102.303070 692.195740,102.116844 691.213013,101.221107 
	C690.994568,101.010162 691.000000,100.999695 690.995117,101.002975 
	C690.845581,100.823586 690.700928,100.640930 690.507812,100.112015 
	C690.850891,98.195663 692.909363,95.912720 690.058777,95.300377 
	C683.431885,93.876877 679.385254,89.378830 675.252563,84.238770 
	C675.002441,83.998779 675.000000,84.000000 675.000610,83.999237 
	C674.073425,83.054031 673.145142,82.103134 672.842224,80.750755 
	C672.317200,78.406746 670.904480,77.452057 668.520752,77.854195 
	C662.550537,78.861374 659.331604,74.504921 656.576599,70.690567 
	C654.985901,68.488167 653.689697,67.081001 651.135864,66.513626 
	C649.146057,66.071541 647.448425,65.032486 647.024292,62.367424 
	C646.812195,59.917690 645.617676,58.685425 643.344360,58.036865 
	C641.500427,52.501667 635.656433,52.657013 632.101196,49.350731 
	C631.340332,43.864326 626.573425,45.623329 623.383789,44.045837 
	C621.151367,42.475578 618.995789,43.135914 616.921326,43.116039 
	C613.826538,43.086395 611.027588,42.546593 610.768494,38.355980 
	C614.945496,38.381878 618.941589,38.504002 622.821838,39.238613 
	C624.891235,39.630405 626.630249,39.835564 628.517517,38.788342 
	C631.312561,37.237350 633.726135,38.766766 633.700378,41.243019 
	C633.625549,48.429188 638.154358,48.258739 643.079163,48.009350 
	C644.406494,47.942135 645.740295,48.002769 647.529480,48.006660 
	C647.987793,48.008606 647.998779,48.001591 647.995117,48.006428 
	C649.918335,50.453514 651.295105,53.259190 653.166565,55.749161 
	C654.679199,57.761688 656.074585,59.677696 658.900208,59.871067 
	C662.107849,60.090580 662.691895,63.817234 665.657593,64.940979 
	C666.746948,65.012344 667.493896,65.014664 668.614258,65.019089 
	C676.322632,70.701408 684.913147,75.270721 687.426025,85.288696 
	C687.998169,87.569435 689.397461,88.648697 691.450745,89.203583 
	C695.254639,90.231514 697.525879,92.714691 699.375854,96.192772 
	C702.383789,101.847870 708.172791,103.189415 713.866821,103.223335 
	C718.239624,103.249390 720.816284,104.747055 722.360352,109.005188 
	C720.540955,113.619278 717.190369,112.006729 713.738647,111.479462 
	C707.681030,111.664749 704.260803,106.774223 699.019165,105.134277 
	C697.816284,104.642998 697.223022,103.942078 696.300232,103.138489 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M646.733459,62.243591 
	C647.640198,62.744938 648.186768,64.019890 648.935242,64.152206 
	C655.838623,65.372536 658.460083,71.800484 663.177490,75.647903 
	C664.111084,76.409325 665.148132,77.343483 666.254028,76.869118 
	C672.847168,74.041069 674.281006,78.033409 674.950989,83.609718 
	C669.301941,81.986488 663.489380,80.201530 658.909180,75.950600 
	C658.068298,75.170128 656.797119,74.554176 656.446350,73.597595 
	C655.097229,69.918137 653.245300,67.588478 648.748230,67.520241 
	C646.641846,67.488274 645.345276,65.049408 646.733459,62.243591 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M675.050781,84.384430 
	C676.798889,83.857628 678.765259,84.306419 679.221741,85.978912 
	C680.780518,91.690407 685.793091,92.531792 690.192688,93.917747 
	C694.451477,95.259331 694.602539,96.812607 691.205566,99.715904 
	C690.532043,100.226089 690.135010,100.148186 689.915527,99.346031 
	C690.196350,96.100784 688.300049,95.282845 685.954590,95.050774 
	C684.315430,94.888588 682.846924,94.571503 681.255859,93.881645 
	C677.104248,92.081627 675.641296,88.822205 675.050781,84.384430 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M610.678833,37.862934 
	C611.688354,41.649376 614.561951,41.722641 617.395508,42.028751 
	C619.230042,42.226936 621.406250,41.084824 622.908936,43.615513 
	C620.008789,43.893696 617.009399,43.541676 615.692322,47.368176 
	C615.137085,48.981163 613.432922,49.265797 611.879211,48.748650 
	C610.309265,48.226074 609.982666,47.008961 610.681641,45.562710 
	C612.676514,41.434875 606.564819,41.134491 607.008301,37.371429 
	C608.121643,37.236828 609.240906,37.478848 610.678833,37.862934 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M631.744507,49.225502 
	C633.476746,49.638130 634.995789,50.897984 636.422607,50.801090 
	C641.246948,50.473480 642.564697,53.278748 642.993652,57.606567 
	C642.195740,57.884903 640.926880,58.054298 640.656677,57.612640 
	C638.761841,54.515789 636.090088,52.825592 632.551086,52.158466 
	C631.301636,51.922951 630.745483,50.788731 631.744507,49.225502 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M689.631592,98.953705 
	C690.111206,99.313255 690.301147,99.570496 690.789490,99.875259 
	C691.005310,100.152969 691.003357,100.323608 690.995789,100.750252 
	C687.882629,100.318680 683.538635,103.027153 682.046509,97.385803 
	C684.708252,96.536911 686.883911,98.108040 689.631592,98.953705 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M675.832825,93.699844 
	C672.911194,93.248627 669.359253,93.765312 667.064209,90.361496 
	C667.163452,89.874931 667.329285,89.744377 667.754089,89.795776 
	C671.028931,90.080429 673.499756,91.406143 675.832825,93.699844 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M667.962646,89.615524 
	C667.843872,89.983315 667.674683,89.988899 667.251465,89.999992 
	C666.997559,90.005493 666.501221,89.999207 666.253052,89.997177 
	C665.260193,89.287018 664.189453,89.119194 663.208740,88.230583 
	C662.547729,88.015869 662.105530,88.010185 661.331665,88.000412 
	C660.998718,87.554024 660.997437,87.111710 660.994385,86.337753 
	C663.845703,85.923820 666.767944,85.694206 667.962646,89.615524 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M678.864624,95.705780 
	C677.874756,95.941116 676.615051,96.164940 676.055359,94.352341 
	C677.143188,94.013931 678.075256,94.436821 678.864624,95.705780 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M600.043945,28.368767 
	C602.655762,28.826960 605.366089,29.225666 608.580688,29.006493 
	C606.563110,33.416721 603.354980,33.317287 600.043945,28.368767 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M592.791992,25.185162 
	C594.473389,24.938671 595.380920,25.960943 596.715210,26.863739 
	C595.349304,29.071068 594.151306,28.629967 592.791992,25.185162 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M388.661316,73.846230 
	C387.471558,75.232834 385.250214,75.833237 385.040100,78.651733 
	C384.827576,79.552193 384.652710,80.102859 384.238922,80.826614 
	C383.213745,81.636993 382.201202,81.873390 381.064697,82.657944 
	C379.284271,83.380424 377.788544,83.334908 375.925110,83.129181 
	C373.631897,82.374695 372.153778,83.030746 370.817383,84.493965 
	C368.641602,86.876228 365.903625,88.142372 362.326172,88.117905 
	C362.663300,84.902115 357.915436,84.049728 359.002563,80.389801 
	C366.768341,83.626434 372.208374,78.550797 377.521576,74.507072 
	C381.311462,71.622673 384.521149,71.356018 388.661316,73.846230 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M548.350464,966.165344 
	C547.557495,965.993347 547.112488,965.991638 546.333862,965.989624 
	C543.160706,966.820496 540.286682,966.463440 537.043213,966.179932 
	C535.095459,965.656006 533.208984,965.552979 532.098022,964.400391 
	C528.412842,960.576782 525.702087,962.885986 522.921143,965.524170 
	C520.182312,968.122314 517.622559,971.027405 513.074463,971.264893 
	C511.117767,971.201233 509.754425,970.637695 508.436981,969.175415 
	C504.821411,964.819092 494.272644,963.238281 488.604340,966.160706 
	C488.052246,966.345947 487.817627,966.375427 487.290894,966.203674 
	C486.248016,965.998230 485.497284,965.994080 484.371155,965.990540 
	C483.614899,966.376587 483.157166,966.524902 482.315369,966.213379 
	C482.008148,965.990234 482.002014,965.995117 482.006042,965.987976 
	C481.762726,965.757019 481.384857,965.606262 481.254578,965.342102 
	C478.576965,959.910950 473.472534,959.017029 468.246704,958.344788 
	C463.097168,957.682251 458.366058,955.804688 453.585205,953.832458 
	C447.238922,951.214539 440.118469,952.424866 433.554962,950.517395 
	C432.141388,950.106628 430.506348,950.309326 429.165619,949.660583 
	C422.926605,946.641846 416.598328,944.378174 409.496979,946.157349 
	C408.552612,946.393921 407.406342,946.088989 406.644562,945.458008 
	C403.530731,942.878784 399.677399,942.484375 395.592651,941.522095 
	C394.212097,941.113586 393.276825,940.649658 391.968475,940.088745 
	C389.475220,938.527283 386.761200,938.762817 384.285217,937.244385 
	C383.555389,936.999451 383.113251,936.996399 382.339478,936.991821 
	C381.425049,936.330811 380.699890,936.014404 379.496887,936.132629 
	C378.512634,936.282043 377.845703,936.263367 376.830322,936.171021 
	C372.931610,935.146362 371.093109,931.450134 367.258392,930.250244 
	C365.924957,930.007812 364.866089,929.998474 363.410217,929.984741 
	C362.321777,929.203308 361.397766,928.793091 360.244415,928.183105 
	C360.009186,927.992615 360.000000,928.000000 360.004272,927.994141 
	C359.415833,927.312744 359.195374,926.533813 359.527313,925.269653 
	C361.985748,923.024231 364.409546,923.974487 366.812653,924.558533 
	C378.893799,927.494812 390.262512,932.751099 402.489990,935.281921 
	C414.147064,937.694763 425.085846,943.063416 437.232178,943.646729 
	C440.832397,943.819641 444.129669,943.948486 447.092407,941.490417 
	C449.308868,939.651489 451.951691,939.270691 454.595673,940.410278 
	C458.569946,942.123169 463.038116,942.125549 466.923645,944.152344 
	C468.143341,944.788696 469.524384,944.549561 470.953735,943.325317 
	C478.331573,942.527344 485.133240,944.059937 491.889771,945.888977 
	C492.153809,945.960510 492.314331,946.380310 492.704529,946.993896 
	C495.033539,952.818970 499.488800,955.407043 506.013763,954.477051 
	C512.418030,953.564270 518.649109,952.659607 525.145874,954.592224 
	C530.160461,956.083923 533.163391,954.142639 536.357605,948.980225 
	C537.034729,948.392273 537.485107,948.154053 538.338501,947.885986 
	C542.396179,947.275391 546.874207,948.822754 549.103271,943.583984 
	C550.087952,942.436646 551.055542,942.106506 552.552856,942.147339 
	C556.819031,944.322693 560.753357,945.746643 565.292297,944.252441 
	C567.701233,943.459290 569.877075,944.512817 571.765686,946.040710 
	C575.196899,948.816528 578.651428,949.392761 582.811462,947.262756 
	C588.394226,944.404236 594.925598,945.912781 600.830200,943.785278 
	C606.903931,941.596802 613.297913,940.302551 619.788818,939.724792 
	C621.820862,939.543823 623.645691,938.580872 625.300964,937.487183 
	C632.629822,932.644897 641.299194,932.484375 649.772827,930.607605 
	C650.615784,936.094910 647.052979,940.271362 641.983154,939.011475 
	C635.335999,937.359558 631.392639,941.792603 626.523193,944.284790 
	C625.818298,944.645447 625.269043,945.710754 624.520386,945.674988 
	C618.984863,945.410767 614.040466,948.348694 608.681641,948.585083 
	C605.454590,948.727356 602.429138,948.844482 599.612427,950.586548 
	C596.391296,952.578979 592.657349,952.823730 589.050476,953.241333 
	C578.245667,954.492249 568.256897,958.952332 557.666504,961.050781 
	C554.747253,961.629272 552.924377,964.667603 549.642517,965.666809 
	C549.137878,966.064270 548.918152,966.199890 548.350464,966.165344 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M650.330505,931.249084 
	C640.893555,934.599060 630.668884,934.824341 622.223694,940.650696 
	C619.229492,942.716492 615.010742,941.479675 611.338257,942.774292 
	C601.016479,946.413208 589.593872,945.651794 579.449036,950.505676 
	C576.720642,951.811096 573.956909,949.585876 571.847229,947.821228 
	C569.366333,945.746155 567.035767,944.531311 563.823364,945.961304 
	C559.793030,947.755493 556.442749,946.244446 553.205811,943.269714 
	C553.240295,940.245911 555.527283,940.908752 557.136902,940.606445 
	C564.583740,939.207703 572.045776,939.443665 579.458130,940.816040 
	C582.886902,941.450867 586.061707,941.043457 589.185669,939.600281 
	C590.392212,939.042969 591.648560,938.305542 592.943848,938.293579 
	C597.407471,938.252319 601.306091,937.926819 603.043091,932.566467 
	C603.723450,930.466736 606.660522,930.589355 608.052673,932.504089 
	C611.022217,936.588745 613.426575,934.419312 616.899963,932.937073 
	C626.113647,929.005432 634.961731,924.315247 645.255066,922.685791 
	C651.960754,921.624268 658.503601,919.552124 665.461121,919.310486 
	C668.058044,919.220276 671.091980,917.626038 672.204346,914.712341 
	C674.619751,908.384949 680.360046,907.202148 685.636414,907.174011 
	C691.813416,907.141052 696.781799,906.216980 700.207092,900.685852 
	C701.194641,899.091248 702.872009,898.169250 704.723206,897.756958 
	C711.195923,896.315247 716.432251,892.274658 722.287048,889.551270 
	C732.901123,884.614014 741.477661,876.400024 751.924744,871.444763 
	C757.764221,868.674927 763.492981,865.833618 769.374390,862.557983 
	C772.126892,866.904480 768.216003,868.535583 765.954468,871.215027 
	C764.633362,872.104431 763.535950,872.644104 762.284790,873.611694 
	C758.181458,875.857727 752.935059,874.978760 750.365479,879.573486 
	C750.056152,880.126526 749.138855,880.480164 749.110291,881.061584 
	C748.652832,890.364441 739.755310,888.990051 734.621277,891.669067 
	C731.001099,893.558167 728.268921,895.145569 726.529907,899.081177 
	C725.642883,899.752869 724.941284,899.927429 723.840454,899.959595 
	C718.499573,898.895447 715.441956,902.139404 712.017822,905.285339 
	C711.378052,906.123108 710.827087,906.536621 709.872681,906.965332 
	C708.484741,907.372559 707.370056,907.544678 706.216003,908.508728 
	C704.676819,909.270935 703.322754,909.508301 701.672974,909.950134 
	C698.506042,910.140381 695.912048,910.624512 694.998291,914.295715 
	C694.698608,914.794128 694.559937,914.982727 694.153748,915.400269 
	C692.654419,916.357239 691.310669,916.708679 689.550903,916.815918 
	C686.646545,915.963379 685.715088,918.006653 684.227783,919.719604 
	C683.823547,919.956909 683.656799,919.959106 683.239990,919.963806 
	C680.565674,919.773926 678.248291,919.899719 676.376343,922.343140 
	C675.926208,922.713745 675.728333,922.844116 675.204468,923.126953 
	C668.903137,925.557129 663.228271,928.693054 656.765930,929.581665 
	C655.706604,929.727417 655.200562,930.733398 654.080811,931.511536 
	C652.680847,932.073486 651.648376,932.061218 650.330505,931.249084 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M649.965698,931.362549 
	C651.071167,931.001526 652.135010,930.998230 653.597900,930.992554 
	C656.166870,929.148804 658.744812,929.319214 661.670166,929.761108 
	C659.294189,936.060608 655.693909,940.827454 648.035400,940.026123 
	C645.754578,939.787415 643.733826,940.727783 642.208130,942.357056 
	C638.050720,946.796143 632.788635,948.972656 626.987366,950.170715 
	C623.485840,950.893921 621.067078,952.717102 619.973206,956.369934 
	C618.771912,960.381592 615.702148,961.816956 611.784180,960.709595 
	C609.224915,959.986206 607.181702,960.703857 604.724304,961.249451 
	C600.140564,962.267212 596.334106,964.248108 592.205139,966.278870 
	C588.050476,968.322205 582.564331,969.217041 577.312317,966.806335 
	C570.707214,963.774536 563.287598,965.242065 556.284973,964.161011 
	C554.588562,963.899048 552.630310,965.332397 550.398682,965.995605 
	C549.832825,966.130127 549.660706,966.255371 549.237000,966.184204 
	C553.498352,959.418335 560.911072,958.942749 567.666565,956.943481 
	C577.000183,954.181213 586.496765,952.353210 596.031494,950.472229 
	C600.999329,949.492188 605.368347,945.681458 611.064941,947.563782 
	C611.724243,947.781616 612.903198,946.366943 613.864685,945.745239 
	C615.256409,944.845276 616.658875,943.705933 618.441895,944.308716 
	C622.153442,945.563477 625.228638,945.686768 626.747009,941.087341 
	C627.144226,939.883911 628.656128,939.922668 629.829529,940.430969 
	C631.133545,940.995911 632.406006,940.598877 632.844849,939.500610 
	C634.481140,935.405579 636.839355,935.952332 640.247742,937.440979 
	C644.738770,939.402527 647.204285,937.672119 649.965698,931.362549 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M429.960083,979.618652 
	C426.036102,981.072815 422.216553,981.249573 418.694702,978.530945 
	C418.130402,978.095398 417.106781,978.088745 416.313080,978.142761 
	C410.050720,978.568848 408.516052,977.511902 407.020081,971.230652 
	C406.688263,969.837402 406.378937,968.287048 404.907623,968.242676 
	C398.853302,968.060364 392.708191,967.527832 386.762024,968.338501 
	C382.981781,968.853760 382.392487,968.015259 382.564087,964.802368 
	C382.796143,960.456238 379.915710,958.358093 376.097565,959.955505 
	C371.714203,961.789368 365.022247,958.378601 364.076569,953.611084 
	C363.728027,951.853943 363.356537,950.095581 361.701508,949.550171 
	C357.444458,948.147461 356.271301,944.427856 355.094238,940.923767 
	C354.300385,938.560486 352.913940,936.879761 350.820465,936.545532 
	C344.969757,935.611450 341.112396,931.315491 336.165588,928.301025 
	C334.503571,924.565735 332.469696,921.372070 331.569183,917.377380 
	C334.975708,915.249878 337.347687,918.018494 340.447754,918.871094 
	C341.713654,919.686279 342.487213,920.589294 343.927643,921.104736 
	C344.861542,921.546082 345.437225,921.918762 346.226868,922.578369 
	C347.524536,924.417480 348.635223,925.981018 349.707581,927.570190 
	C350.550812,928.819885 351.437805,928.771240 352.759094,927.679199 
	C353.530029,927.422974 353.988556,927.441650 354.722778,927.779175 
	C355.432129,931.691345 358.342773,934.703857 356.614136,939.190125 
	C355.577240,941.881104 359.404938,942.369995 361.670105,941.898682 
	C364.848969,941.237122 367.759827,940.494690 370.299286,943.450256 
	C371.129150,944.416077 372.469055,943.643799 373.585663,943.308716 
	C375.043884,942.871094 376.991150,942.455750 377.833557,943.675476 
	C381.148346,948.475952 387.091492,948.098328 391.372314,950.922424 
	C392.179230,951.454712 393.388855,951.565247 393.507904,952.702087 
	C393.638519,953.950012 392.453796,954.298401 391.567780,954.626282 
	C389.834045,955.267761 387.789062,955.679932 388.167175,958.134338 
	C388.594452,960.907593 390.972534,959.710571 392.593994,959.988647 
	C393.243561,960.100098 394.243073,959.798157 394.531036,960.144226 
	C398.177155,964.523621 403.235565,965.147583 408.383423,966.320618 
	C410.450562,966.791687 412.238281,967.654297 414.219574,966.771423 
	C416.166656,965.903748 417.765228,966.471069 418.366577,968.150879 
	C419.889221,972.404053 422.704346,974.902710 427.007263,976.081360 
	C428.434937,976.472473 429.511841,977.685608 429.960083,979.618652 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M689.254639,939.887512 
	C686.344666,939.834045 684.199646,941.210327 682.181091,942.569336 
	C679.946289,944.073914 678.930054,946.000000 681.917908,947.960022 
	C684.244324,949.486023 684.410950,950.608398 681.296570,951.114563 
	C680.492859,951.245178 679.538635,951.992554 678.962769,951.742981 
	C673.548706,949.396484 671.392090,953.658447 668.307129,956.839417 
	C665.314453,950.630981 665.533264,949.610962 671.456116,945.358582 
	C674.528137,943.152893 676.972229,940.804016 676.127441,936.305725 
	C676.484558,935.412659 676.976257,934.827759 677.731506,934.117798 
	C683.508484,930.394592 688.646240,926.562500 691.362061,920.119141 
	C692.611877,917.153870 696.839966,917.285339 699.059570,914.474487 
	C703.480286,911.151550 707.814758,908.300842 712.881897,907.038635 
	C716.228271,906.205017 718.542358,904.417847 720.320435,901.159912 
	C720.749329,900.716248 720.946716,900.563293 721.490479,900.264404 
	C723.081116,899.915039 724.330322,900.030762 725.944702,900.112427 
	C726.535522,900.260132 726.759888,900.356445 727.265442,900.682373 
	C728.287903,901.838684 729.090027,903.166870 728.458313,904.027649 
	C725.029541,908.700439 728.971130,909.816772 732.107666,911.745728 
	C733.109985,912.981445 733.380005,914.165283 733.839600,915.657715 
	C734.247986,922.550110 732.524658,924.046204 726.373169,923.658081 
	C721.098328,923.325195 715.826782,923.299194 710.539429,922.720398 
	C706.924377,922.324646 703.533020,924.522583 700.732605,926.966614 
	C698.501343,928.913757 699.282349,931.101685 700.630798,933.236877 
	C701.531555,934.663330 702.463379,936.254395 701.176758,937.820374 
	C699.839478,939.447998 698.122925,938.659607 696.615601,937.948486 
	C694.757568,937.071960 693.092468,937.261047 691.245361,938.744141 
	C690.496643,939.251038 690.004211,939.514282 689.254639,939.887512 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M394.691345,940.192871 
	C399.280457,941.336121 404.386078,940.357178 407.663177,944.609436 
	C408.014587,945.065308 409.319214,945.129944 410.035767,944.907166 
	C416.348663,942.943665 421.710510,945.098450 427.649689,947.373169 
	C433.353607,949.557739 439.706024,951.508606 446.325653,950.205017 
	C449.245758,949.629944 452.511078,951.276306 454.666229,952.430359 
	C462.045807,956.382324 469.806488,957.516541 477.850464,958.055542 
	C479.613434,958.173706 480.111877,959.383423 479.949890,960.820923 
	C479.734924,962.728577 481.072693,963.843323 481.934204,965.615479 
	C471.518280,963.449036 461.136230,964.355469 450.404785,966.984497 
	C449.335480,966.380371 448.513306,966.149292 447.323792,966.010925 
	C445.880402,966.238525 444.760773,966.479309 443.322021,966.861206 
	C439.923187,968.427551 438.184967,966.738586 437.319672,964.155029 
	C436.457642,961.581299 438.253693,960.202698 440.379578,959.199951 
	C441.885406,958.489563 443.497040,958.119568 445.198456,957.579102 
	C443.887848,957.011292 442.104004,956.614441 441.505035,957.319275 
	C436.309784,963.433228 430.008484,958.527039 424.338837,958.939514 
	C420.208527,959.240051 416.162994,956.061768 412.767731,953.707275 
	C407.568726,950.101807 401.985138,948.750427 396.032196,948.007996 
	C393.916046,947.744202 391.911896,947.306519 391.413239,944.984741 
	C390.918243,942.679871 392.373962,941.268372 394.691345,940.192871 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M535.653076,964.999939 
	C539.134705,965.222229 542.311096,964.513733 545.707397,965.707520 
	C541.113586,975.773071 531.195129,979.082581 522.562195,983.898926 
	C513.531616,988.937195 512.896484,988.502747 504.666412,981.521179 
	C502.326599,979.536194 499.891083,977.558716 496.665710,976.671509 
	C491.653076,975.292664 489.465027,970.821045 487.040375,966.354614 
	C486.998779,966.002441 487.495789,965.994934 487.744324,965.993774 
	C490.664154,964.296753 493.633850,964.408081 496.555206,964.800110 
	C500.437408,965.321106 501.300690,966.761169 500.211639,970.733154 
	C499.924194,971.781494 499.718018,972.622559 500.596313,973.384094 
	C500.968719,973.706909 501.527527,973.985474 501.945984,973.727966 
	C503.196136,972.958557 502.177521,972.037720 502.005707,971.175415 
	C501.170868,966.984253 502.845825,966.041931 508.736267,967.768311 
	C509.641937,969.423279 510.770996,970.042053 512.649841,970.000000 
	C515.166565,972.601868 516.904236,971.057434 518.885498,969.579895 
	C523.739075,965.960327 529.140625,964.042236 535.653076,964.999939 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M394.707977,939.869019 
	C394.603790,940.684692 394.350525,941.536072 393.785461,942.032288 
	C390.276794,945.113159 392.762604,946.110657 395.485626,946.866699 
	C396.574188,947.168945 397.809845,946.891724 398.951813,947.059753 
	C404.146149,947.823914 408.704620,949.695435 413.447845,952.312317 
	C418.035797,954.843445 422.455139,958.760071 429.478333,957.747986 
	C433.456238,957.174744 437.380615,958.949707 441.334198,955.476929 
	C442.411469,954.530701 447.076385,955.343628 449.690094,957.824646 
	C447.547699,960.081299 444.386230,958.116028 442.346436,959.842957 
	C440.954498,961.021423 437.994263,960.395630 438.114105,963.262939 
	C438.219055,965.773804 440.417847,966.219360 442.635712,966.958862 
	C441.532562,970.226440 439.508545,969.510437 437.796692,967.444092 
	C436.322876,965.664917 434.948395,965.018250 432.682831,965.823975 
	C431.501556,966.244080 429.829681,966.393250 428.771118,965.870972 
	C421.478485,962.272278 413.383606,963.653992 405.808899,961.940247 
	C403.150787,961.338867 401.536591,960.007690 402.879608,957.017883 
	C404.966675,952.371948 403.078033,950.198975 398.411835,949.646362 
	C397.436646,949.530823 396.493744,949.148804 395.518799,949.025146 
	C386.658508,947.901733 386.063049,947.383057 384.008667,938.402893 
	C386.561951,937.423157 388.704803,939.870422 391.653900,939.005432 
	C392.805725,939.243591 393.610229,939.492065 394.707977,939.869019 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M454.001190,984.601196 
	C451.147614,985.946777 451.204163,981.759460 448.369659,982.008789 
	C447.971710,978.209839 447.515808,974.363281 450.448853,971.197205 
	C451.640411,969.911133 452.825684,968.836914 454.661469,969.027100 
	C456.234650,969.190002 458.285095,968.182800 459.265564,969.821716 
	C460.188538,971.364563 457.580200,971.671570 457.397339,972.865234 
	C456.792206,976.815186 454.128937,980.128967 454.001190,984.601196 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M699.715820,914.701599 
	C699.157715,916.763062 697.717712,917.988831 695.921631,918.456055 
	C692.956604,919.227417 692.142212,921.404846 690.838562,923.886108 
	C688.294495,928.728271 684.089172,932.201904 678.363159,933.989807 
	C678.033936,932.237549 679.148682,931.349609 680.603516,930.706421 
	C682.904968,929.689026 684.599731,928.550232 683.374146,925.434265 
	C682.139893,922.296204 686.479492,923.723145 686.928650,921.358521 
	C687.092773,920.017517 687.677246,919.313171 688.615662,918.439575 
	C690.405396,917.268066 691.942871,916.335449 693.755981,915.228638 
	C693.994507,914.992065 693.981995,914.973877 693.979553,914.963013 
	C694.636902,914.429932 695.332825,913.978088 696.599243,913.785645 
	C697.880432,913.740906 698.684204,913.952576 699.715820,914.701599 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M662.374512,929.969849 
	C659.652649,930.587524 657.310852,931.304810 654.413208,930.993713 
	C654.136658,929.294983 654.967834,928.175903 656.727844,928.064087 
	C663.123596,927.657593 668.493652,924.277039 674.639038,922.043518 
	C675.578735,922.687683 675.732117,923.456848 675.225952,924.647827 
	C670.902588,926.630859 666.818481,928.275879 662.374512,929.969849 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M445.001953,981.579041 
	C441.355316,981.969116 437.749969,981.819763 435.058350,978.359741 
	C437.653503,978.015198 438.826324,975.247253 441.494293,974.777893 
	C444.922028,974.174988 446.840515,973.817932 445.174469,978.215271 
	C444.841431,979.094360 445.045197,980.176758 445.001953,981.579041 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M366.987305,930.347473 
	C371.819519,928.362793 372.986206,933.677063 376.649109,934.963745 
	C377.986755,937.390198 376.513763,937.553955 374.379578,937.245300 
	C372.071686,936.870850 369.904816,937.593628 368.288208,935.219238 
	C367.275726,934.385681 367.023956,933.572327 367.004700,932.328735 
	C366.994568,931.559265 366.992706,931.118591 366.987305,930.347473 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M355.302521,927.866394 
	C354.556488,927.996155 354.111786,927.996826 353.333527,927.998779 
	C349.945465,928.288208 348.878113,926.395142 348.536743,923.471313 
	C351.999451,921.374756 354.812042,925.385681 358.486511,924.068970 
	C359.064331,924.247314 359.284058,924.353516 359.749451,924.731079 
	C359.998230,925.749084 360.001373,926.495667 360.006531,927.615295 
	C358.530579,928.075500 357.143585,926.565430 355.302521,927.866394 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M391.766449,938.708191 
	C389.168579,942.600708 386.958740,939.778870 384.309753,938.140015 
	C383.998779,938.000000 383.997223,937.501221 383.997375,937.251831 
	C386.588043,937.055298 389.280182,936.568359 391.766449,938.708191 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M676.315796,922.204834 
	C676.849854,917.847595 679.783997,918.964294 682.727051,919.702881 
	C681.555237,920.623535 680.120605,921.280823 678.347900,921.975159 
	C677.619690,922.384583 677.160278,922.517761 676.315796,922.204834 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M684.324707,919.968323 
	C684.236938,917.156677 684.813293,914.696716 688.783936,915.752075 
	C689.331421,916.343689 689.526306,916.739990 689.482788,917.540894 
	C688.623657,918.714966 687.570923,919.156616 686.313843,919.875549 
	C685.551575,919.986572 685.105347,919.984253 684.324707,919.968323 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M374.047302,937.338806 
	C375.116302,936.933411 376.391235,937.168396 376.941528,935.348145 
	C377.750427,935.006653 378.499054,935.006042 379.622009,935.007568 
	C380.376709,935.393127 380.524963,935.851562 380.220642,936.693726 
	C378.119873,937.969421 376.291077,939.393860 374.047302,937.338806 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M675.751221,921.999268 
	C676.446533,921.999512 676.893066,922.001465 677.674683,922.007812 
	C677.911316,923.531677 677.249634,924.597229 675.326538,924.953003 
	C674.991882,924.239929 674.997192,923.494202 675.003662,922.375427 
	C675.004883,922.002441 675.502441,922.000916 675.751221,921.999268 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M686.110779,920.207153 
	C686.086121,918.562256 686.923584,917.990662 688.618042,917.986694 
	C689.102173,918.140930 689.237427,918.307556 689.188843,918.738281 
	C688.513184,919.587158 688.021484,920.171753 687.266113,920.881470 
	C686.685242,920.890076 686.425720,920.696289 686.110779,920.207153 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M366.750336,932.204712 
	C367.869843,932.543457 367.992493,933.409119 367.997498,934.663208 
	C366.383606,934.781860 365.630127,934.065491 366.750336,932.204712 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M447.212067,966.253479 
	C448.186615,964.508301 448.952423,965.530823 449.867615,966.724243 
	C449.141815,966.831482 448.282990,966.670349 447.212067,966.253479 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M360.151245,928.281006 
	C361.138916,928.055542 362.393646,927.844116 362.949860,929.642212 
	C361.858673,929.987854 360.932587,929.555054 360.151245,928.281006 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M482.047089,966.284668 
	C482.449829,965.989868 482.891541,965.989563 483.664490,965.990234 
	C483.762207,966.500305 483.657196,967.258911 483.265289,967.460815 
	C482.659210,967.773254 482.259125,967.188110 482.047089,966.284668 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M548.739624,965.989502 
	C549.155212,965.991150 549.325134,965.994568 549.750000,966.001404 
	C549.666260,967.201721 549.417542,968.697937 548.042847,966.288818 
	C548.002441,965.995117 548.493896,965.991272 548.739624,965.989502 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M380.334595,937.001892 
	C379.999359,936.559509 379.998688,936.116638 379.997192,935.341736 
	C380.897614,935.085144 381.673462,935.338684 381.959198,936.673828 
	C381.561615,936.993958 381.115417,936.997620 380.334595,937.001892 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M691.381226,938.993042 
	C692.250183,935.475891 694.122925,933.939636 697.476318,936.917725 
	C698.364685,937.706604 699.482666,938.502136 700.486633,937.281494 
	C701.431519,936.132690 700.381531,935.205872 699.741089,934.232056 
	C696.220337,928.879333 696.708923,926.377686 702.533203,923.878723 
	C706.142334,922.330017 709.457642,919.548462 714.282593,921.803711 
	C718.725159,923.880249 723.897583,921.156250 728.953308,922.669373 
	C732.364380,923.690125 733.131226,919.868896 733.001343,916.404724 
	C735.278809,915.455261 738.345276,917.421326 739.977356,913.693481 
	C744.932312,914.184204 749.437378,914.114014 753.694336,911.594238 
	C755.295105,910.646790 757.263611,910.885742 759.076843,911.338196 
	C761.242493,911.878540 762.775513,911.229980 764.338318,909.537964 
	C767.858826,905.726501 771.810364,906.201050 775.554688,910.963928 
	C774.906799,913.388733 774.292419,915.407898 774.335388,917.612976 
	C774.382080,920.005432 773.375183,921.779541 770.397888,922.282593 
	C769.274048,922.361816 768.535339,922.325684 767.458618,922.213318 
	C766.267029,922.057861 765.412781,921.889343 764.270996,922.386475 
	C763.741333,922.534729 763.520508,922.568726 762.962891,922.561401 
	C760.328308,919.892944 757.890625,917.945007 754.406006,920.740051 
	C754.169067,920.930115 753.734375,921.019104 753.449402,920.924927 
	C749.154663,919.506165 746.617126,920.453857 745.956543,925.480591 
	C745.735046,927.165222 743.199036,928.233093 741.394409,927.523254 
	C736.638977,925.652954 733.087097,928.251160 729.014282,930.396362 
	C727.301453,931.280212 726.030212,932.205933 724.562805,933.447876 
	C721.948730,935.079468 720.219910,937.362671 717.287231,938.533386 
	C716.479309,938.682007 716.020447,938.624512 715.299805,938.242981 
	C711.319336,938.034607 710.395264,936.183594 711.531982,932.880249 
	C711.956360,931.646973 712.559814,930.152588 711.000549,929.394653 
	C709.452087,928.641968 708.627686,929.935181 707.947144,931.118408 
	C706.099426,934.330811 703.319763,937.102417 703.517944,941.254944 
	C703.626770,943.534851 702.132935,942.638367 700.852661,942.479309 
	C697.516724,942.064880 695.335205,938.736084 691.381226,938.993042 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M704.417114,966.985229 
	C704.292358,963.298828 701.245972,960.948792 700.011047,957.809082 
	C698.466736,953.882874 700.796875,948.121216 705.637390,946.981323 
	C706.559143,947.833618 707.424438,947.960266 708.659302,948.027344 
	C712.306946,947.902039 715.271484,945.224670 718.859924,946.778748 
	C719.661987,947.126099 720.296448,946.187195 720.730774,945.490906 
	C722.838379,942.112488 725.604187,942.176636 728.943115,944.187988 
	C730.511414,947.677490 726.088928,949.259277 726.941040,952.676514 
	C726.965637,953.450623 726.961548,953.894104 726.957275,954.670105 
	C727.095459,955.562622 727.458130,956.005615 727.819031,956.763184 
	C727.920654,957.772522 727.937500,958.512451 727.945312,959.622192 
	C720.530212,963.336792 713.308777,967.297729 704.417114,966.985229 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M729.426819,945.082642 
	C726.047668,944.138123 722.565613,941.727295 721.819458,947.527161 
	C721.663147,948.742676 720.323975,949.353638 719.622070,948.850830 
	C716.222412,946.415833 712.820129,948.693176 709.218994,948.304199 
	C710.220886,946.897644 713.771362,946.025574 709.178101,944.339355 
	C707.913757,943.875183 705.318054,943.090942 705.854858,941.314026 
	C706.499939,939.178345 708.909668,939.846008 710.591003,940.400940 
	C712.613586,941.068359 714.151611,941.086182 714.968506,938.384705 
	C715.461609,938.022705 715.898438,938.020996 716.663208,938.023499 
	C719.773804,939.924438 722.795349,939.857727 726.246704,939.044556 
	C727.321533,938.882812 728.046082,938.906128 729.129150,939.146118 
	C729.707214,939.389832 729.923950,939.522278 730.377441,939.955444 
	C731.086487,941.209778 731.162170,942.216675 730.913452,943.621460 
	C730.590759,944.464722 730.256409,944.835144 729.426819,945.082642 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M643.684387,981.999268 
	C643.912659,982.945923 643.624939,983.718445 642.229126,983.872559 
	C641.928650,983.432800 641.956665,982.993408 641.997192,982.220825 
	C642.456421,981.914734 642.903137,981.941833 643.684387,981.999268 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M612.194580,987.759399 
	C611.562988,987.996887 611.143433,988.041199 610.410950,988.124756 
	C609.614319,988.087952 609.130615,988.011902 608.286011,987.862061 
	C608.104431,984.354187 610.201538,984.724060 612.740479,985.753540 
	C613.038208,986.629456 612.851074,987.146057 612.194580,987.759399 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M619.935303,986.740784 
	C618.595520,987.145264 617.215149,987.200134 615.419922,987.256287 
	C614.884094,987.059570 614.763123,986.861572 614.801697,986.354248 
	C616.590088,986.384521 618.441101,983.557861 619.935303,986.740784 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M614.632385,986.036133 
	C614.971863,986.246155 614.982361,986.447449 614.999023,986.953125 
	C614.371399,987.996460 613.514709,988.036133 612.310181,987.982544 
	C612.161011,987.420410 612.339600,986.888306 612.751892,986.189575 
	C613.424866,986.024414 613.864197,986.025940 614.632385,986.036133 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M603.253052,987.991577 
	C602.552429,988.056213 602.105103,988.039978 601.322937,987.997131 
	C601.219849,986.347534 601.967957,985.645264 603.795654,986.756958 
	C603.944458,987.366882 603.775452,987.665894 603.253052,987.991577 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M598.724731,988.075012 
	C598.844604,989.000000 598.746094,990.030579 597.191528,989.287842 
	C597.013062,988.909241 597.014038,988.733704 597.022461,988.295471 
	C597.482239,988.033142 597.934692,988.033569 598.724731,988.075012 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M603.160889,988.282227 
	C603.186096,987.798584 603.372498,987.524597 603.778015,987.127747 
	C604.305176,987.105530 604.564758,987.278809 604.892334,987.729370 
	C604.619568,988.643066 604.115051,989.003479 603.160889,988.282227 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M212.207550,870.215576 
	C211.128204,869.460571 211.012604,868.591431 210.994690,867.341309 
	C209.000000,863.515625 212.933075,861.557739 213.364624,858.332703 
	C214.306610,857.475647 215.485886,857.168701 215.802399,857.892456 
	C217.731155,862.303101 223.151321,864.148071 223.998764,869.360474 
	C224.483093,872.339539 226.645355,873.960632 229.517548,874.398682 
	C234.056778,875.091064 237.705185,877.997070 242.073532,879.194031 
	C245.295685,880.076904 244.968796,882.856812 243.828781,885.373779 
	C242.614517,888.054749 242.842438,889.813232 246.245056,890.224670 
	C247.773468,890.409424 249.224716,891.356812 249.938904,892.613708 
	C252.531433,897.176270 258.177856,898.708862 260.495026,903.536865 
	C261.068573,904.731873 262.556824,905.342712 263.854095,905.528992 
	C268.872589,906.249451 273.029480,910.444885 278.620483,909.010132 
	C280.210663,908.602051 281.083069,910.128052 282.010529,911.429443 
	C284.428864,914.822754 288.406433,915.668823 292.014557,913.711182 
	C293.948730,912.661743 295.829102,911.644592 298.484924,912.075317 
	C300.148010,912.589844 301.092896,913.487122 301.955719,914.519409 
	C304.740173,917.850708 308.302460,918.676758 312.222351,917.158691 
	C315.525757,915.879456 318.197296,916.490845 321.024017,918.462280 
	C325.588562,921.645752 331.334259,922.956848 335.100647,927.645752 
	C334.665863,931.053406 334.331665,934.106750 333.995667,937.583740 
	C333.554230,938.516479 333.107971,939.017761 332.141113,939.374023 
	C331.115784,939.456909 330.474487,939.244080 329.546509,938.815186 
	C325.226715,937.025269 321.602905,934.935608 319.767944,930.325012 
	C319.221985,929.817505 318.888733,929.554260 318.246094,929.176270 
	C315.083313,929.234680 313.289581,927.014648 310.873840,925.849915 
	C312.225311,929.112915 317.224609,931.012634 314.474365,935.505615 
	C313.070923,937.798462 310.782196,937.251221 308.349182,936.324829 
	C307.474640,935.835327 306.939667,935.443115 306.146240,934.861450 
	C305.235413,934.157776 304.686676,933.510925 303.655792,932.980652 
	C303.218262,932.701904 303.049561,932.580200 302.658661,932.240234 
	C301.821136,931.211853 301.239624,930.361145 299.858032,929.997070 
	C298.771698,929.511780 298.133148,928.956848 297.412476,928.035034 
	C296.792175,927.251892 296.638245,926.375793 295.387451,926.522217 
	C294.719421,926.661133 294.326904,926.700378 293.621887,926.666443 
	C293.114532,926.559692 292.915924,926.492065 292.437805,926.277832 
	C290.885406,925.134949 289.335083,925.348938 287.541321,925.098877 
	C285.885925,924.735474 284.510193,924.139587 284.013824,922.972046 
	C282.351349,919.061890 278.954468,917.290955 275.229675,915.282227 
	C274.220001,915.040283 273.480011,915.033508 272.370087,915.020386 
	C269.557495,915.395142 266.955658,913.396240 264.364075,915.733398 
	C261.623413,917.399719 259.379822,917.812317 258.187286,914.431213 
	C257.413239,912.236755 255.924103,913.101196 254.525421,913.453430 
	C252.609161,913.936035 250.216705,914.025635 250.452133,911.554688 
	C250.782623,908.086243 249.328262,907.401367 246.440109,906.963135 
	C243.633957,906.537292 242.455582,904.348755 242.294205,901.556152 
	C242.215302,900.190918 243.460556,898.626221 241.455078,897.356079 
	C240.536041,896.981079 239.975403,896.642090 239.233734,896.006470 
	C238.577896,895.091003 238.291641,894.292603 237.291473,893.689331 
	C236.833649,893.499084 236.640656,893.448303 236.223602,893.198975 
	C235.999527,893.000488 235.994919,893.006470 235.990128,893.010132 
	C235.807465,892.843689 235.629578,892.673523 235.724152,892.254272 
	C235.622406,889.727966 235.491791,887.375122 234.813751,885.192444 
	C233.920914,882.318115 232.913818,879.466064 228.597198,880.765747 
	C227.069931,881.225586 224.830933,880.612000 223.372452,879.732727 
	C220.607056,878.065430 217.859009,876.903381 214.582428,876.875488 
	C212.789398,876.860291 212.074448,875.494263 212.014618,873.401489 
	C212.006790,873.006592 212.000183,873.000244 212.003143,873.003418 
	C212.828354,872.254822 213.125000,871.419373 212.207550,870.215576 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M335.250610,927.999695 
	C329.850830,924.192017 323.558746,922.338867 318.510529,918.337402 
	C317.343811,917.412598 316.043640,917.183350 314.369507,917.758179 
	C306.701691,920.391235 303.898285,919.515503 299.206360,913.270142 
	C298.674194,912.332642 299.304962,911.475220 299.057129,911.110657 
	C295.088348,905.272339 298.741180,902.756470 303.820618,900.514160 
	C305.416840,899.924316 306.598083,900.118591 307.971283,901.117065 
	C309.764618,904.259460 311.672852,906.593201 315.232727,907.120483 
	C317.451385,907.449036 318.570862,909.836792 321.036804,910.537598 
	C322.548920,910.762268 323.644043,911.134094 324.912476,911.982605 
	C326.390869,913.469482 327.645294,914.668335 329.900177,914.772217 
	C330.687897,914.935059 331.119873,915.097473 331.816162,915.523193 
	C332.189728,915.961609 332.282867,916.180725 332.186157,916.702393 
	C332.881470,920.571838 337.440186,922.692017 336.001953,927.585876 
	C335.835358,927.998169 335.668304,927.998779 335.250610,927.999695 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M340.722168,945.220459 
	C345.633759,944.251587 344.586182,948.745117 346.364807,950.572388 
	C349.389130,953.679382 350.530762,957.463257 352.019043,961.592651 
	C350.892517,963.562622 348.898956,963.991089 347.221741,965.452881 
	C343.730438,966.626953 341.617279,965.292847 341.375092,962.191711 
	C341.182831,959.729553 339.912872,958.843628 337.718018,957.951416 
	C334.580017,956.388245 333.649933,953.776245 333.113037,950.963196 
	C332.277618,946.585876 333.414276,945.545044 337.531433,947.167847 
	C339.565735,947.969604 339.601898,946.213501 340.722168,945.220459 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M341.000671,944.998779 
	C340.608826,946.713989 342.038910,949.070740 339.191498,949.922424 
	C336.252502,950.801514 336.373169,947.582886 334.423157,945.919373 
	C334.003601,950.432678 334.076996,953.929810 337.779755,956.729980 
	C337.652649,959.650635 335.701080,958.979919 333.659485,958.769653 
	C332.799194,958.289368 332.437317,957.878906 332.043640,956.997925 
	C331.711090,955.875488 331.660797,955.108765 331.587006,953.985718 
	C330.971313,951.195740 330.343719,948.791077 327.118591,947.966614 
	C326.205231,947.535034 325.722412,947.093384 325.178894,946.265198 
	C324.659088,944.964783 323.971588,944.280945 322.625854,943.837402 
	C322.155487,943.564453 321.977020,943.433350 321.597595,943.030884 
	C320.505951,939.599731 321.522705,937.930176 324.969849,938.386536 
	C326.464172,938.584412 327.889648,938.549011 329.656006,938.040894 
	C330.494598,938.248901 330.990417,938.505920 331.744202,938.870850 
	C333.120392,939.079407 333.819916,939.858521 334.793091,940.769897 
	C335.562500,941.841064 336.424774,941.965759 337.669739,941.987671 
	C338.846405,942.867371 339.712891,943.715149 340.784668,944.786011 
	C341.001709,944.997559 341.000000,945.000000 341.000671,944.998779 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M346.944305,965.355347 
	C347.416504,962.539734 349.350769,962.264465 351.663391,961.986328 
	C352.883972,962.139587 353.735046,962.308411 354.799927,962.728455 
	C355.010803,963.149719 355.007874,963.319763 355.002472,963.744934 
	C352.694305,964.116455 351.614594,965.925964 350.148193,967.703003 
	C349.633636,968.344666 349.195587,968.516479 348.343811,968.436401 
	C347.146240,967.662537 346.891296,966.744690 346.944305,965.355347 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M334.987274,940.662964 
	C333.858765,940.987122 332.929321,940.550415 332.145081,939.269043 
	C332.470612,938.669434 332.938995,938.360046 333.700623,938.028992 
	C334.314270,938.787292 334.634674,939.567261 334.987274,940.662964 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M340.964478,944.658936 
	C339.473938,944.918457 338.415619,944.249146 338.041901,942.333740 
	C339.532318,942.074646 340.589417,942.745605 340.964478,944.658936 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M368.998230,979.563049 
	C363.774841,979.370178 365.668579,974.525330 364.046509,971.383606 
	C367.623657,972.537354 367.527740,976.340942 368.998230,979.563049 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M360.794403,970.725647 
	C354.056213,974.883057 356.022491,968.620300 355.015869,965.369995 
	C357.275818,966.405823 357.602417,969.790466 360.794403,970.725647 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M347.688599,967.772705 
	C348.449188,967.994446 348.892303,967.996826 349.667694,967.999634 
	C350.330994,969.737732 350.662018,971.475403 351.005249,973.594360 
	C347.949158,973.686707 344.967987,972.699158 341.769745,973.858398 
	C340.481506,974.325378 337.734161,974.794128 338.737427,971.241211 
	C341.415070,968.941467 344.255310,967.912720 347.688599,967.772705 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M101.887344,789.510010 
	C104.274078,788.154724 103.782600,786.470520 103.007111,784.389160 
	C105.517151,782.384155 106.546928,783.824890 107.073524,786.117310 
	C107.532692,788.116089 106.711609,790.039673 106.544952,792.197571 
	C110.283226,791.121582 110.914444,788.110718 111.311043,785.041199 
	C111.551605,783.179321 110.562782,780.812561 113.439934,780.214661 
	C115.949295,779.693115 116.901367,781.614136 117.944939,783.331116 
	C118.201408,783.753174 118.573685,784.099731 119.154755,784.715393 
	C119.701462,785.318909 119.983200,785.688599 120.436493,786.363403 
	C121.164940,787.670837 121.640823,788.721985 122.418701,790.002991 
	C122.626335,791.076477 122.428078,791.721375 121.591019,792.424805 
	C118.710716,793.331238 116.138824,789.099060 113.301544,792.804077 
	C112.542488,793.438843 111.537376,793.990051 111.607841,794.252869 
	C113.216873,800.253296 108.791710,800.825745 104.864067,802.162659 
	C102.316864,803.029846 101.978355,802.018799 101.991798,799.936218 
	C102.013237,796.614258 101.938927,793.291626 101.887344,789.510010 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M138.281677,846.837280 
	C135.611816,846.824341 133.681580,847.148254 133.991974,850.621582 
	C132.250916,852.040283 131.544937,850.940186 131.174545,849.535767 
	C130.753937,847.940857 129.904251,846.344238 129.886688,844.789429 
	C129.840805,840.731628 127.977211,838.268250 124.027168,836.732300 
	C123.642197,836.298096 123.510422,836.105774 123.252075,835.588623 
	C123.536995,832.200012 121.254539,830.270752 119.868484,827.586914 
	C119.682976,827.044495 119.652718,826.813782 119.832588,826.304321 
	C120.213097,825.187866 120.383484,824.350037 120.803238,823.310181 
	C122.213402,823.247192 123.161400,823.859741 124.362526,824.707275 
	C126.921623,829.553406 129.472076,833.855835 134.937531,835.465088 
	C136.953629,836.058716 136.903839,838.814209 138.924805,840.102051 
	C139.744156,840.824341 140.132462,841.427368 140.453064,842.481873 
	C140.398285,844.377808 139.989120,845.766479 138.281677,846.837280 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M119.257797,817.163330 
	C118.892715,817.021301 118.409523,817.041748 118.167809,817.034668 
	C116.657608,815.789001 117.253387,813.927246 116.211411,812.273254 
	C114.590118,810.998413 112.872612,810.353333 112.016510,808.312866 
	C110.974083,806.517578 111.241844,805.287415 113.062202,804.112000 
	C114.943626,803.568970 116.408463,803.543518 117.906403,804.368225 
	C116.870148,803.011169 116.669067,801.579102 117.200859,799.662476 
	C117.530624,799.065186 117.702301,798.849548 118.226089,798.417358 
	C119.623184,797.774292 120.705978,797.748047 122.198090,797.978943 
	C123.354477,798.299744 124.037964,798.683716 124.902710,799.478882 
	C125.521072,800.872437 124.944000,801.635742 123.948685,802.546204 
	C122.104012,803.473389 122.299797,804.464050 123.416443,805.869629 
	C123.707657,806.621277 123.767113,807.082642 123.722000,807.914185 
	C122.585114,810.530029 122.472069,813.029968 121.712326,815.755493 
	C121.061539,816.753967 120.424171,817.160522 119.257797,817.163330 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M114.286324,804.754272 
	C112.602722,805.095032 112.009727,805.939941 111.983658,807.640015 
	C110.236191,807.982605 108.446686,807.902283 108.203598,805.697571 
	C107.992661,803.784607 109.689629,803.550232 110.982727,802.793579 
	C114.688248,800.625061 116.025322,795.477783 113.356651,793.160400 
	C112.789909,791.121399 113.647797,789.946655 115.399643,789.289917 
	C118.023758,788.306274 118.544327,790.950745 120.361389,791.989136 
	C121.463722,792.375244 121.827950,793.018188 122.099564,794.153137 
	C122.165070,795.031799 122.107208,795.526978 121.849846,796.357544 
	C121.287582,797.664429 120.305550,797.948425 119.257782,798.711487 
	C118.992073,798.943665 118.497696,798.975281 118.250343,798.988159 
	C116.344688,800.541199 116.818420,803.397095 114.286324,804.754272 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M124.263596,824.705627 
	C122.848221,824.951599 121.642456,825.139587 121.111412,823.427979 
	C120.990318,822.061279 120.928040,821.014465 120.854294,819.576782 
	C120.649345,817.968628 121.393280,817.613647 122.841080,817.608704 
	C125.596001,819.572205 125.711327,821.856018 124.263596,824.705627 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M115.996918,812.397461 
	C117.924896,812.844971 118.061577,814.512939 117.950439,816.656006 
	C115.292999,816.520447 116.201370,814.401855 115.996918,812.397461 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M123.149078,817.653809 
	C122.344597,818.201538 121.777298,818.431885 121.026413,818.924072 
	C119.982475,818.973999 119.216370,818.649475 118.952744,817.337280 
	C119.400787,816.758362 119.908867,816.495483 120.675316,816.110718 
	C121.347694,815.619751 121.831024,815.459167 122.746140,815.666748 
	C123.406525,816.292114 123.499023,816.795349 123.149078,817.653809 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M119.792343,825.917847 
	C120.042717,826.025635 120.077232,826.477051 120.084282,826.703308 
	C120.423172,827.408447 120.547432,827.936279 120.267105,828.883423 
	C119.313591,829.764587 118.514572,829.832825 117.326096,829.255493 
	C117.303940,827.922913 117.630760,826.824097 118.035561,825.388672 
	C118.589684,825.304810 119.065826,825.557434 119.792343,825.917847 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M99.210358,799.818726 
	C97.986389,799.163757 98.153076,798.523010 99.555099,797.985474 
	C99.699478,798.498901 99.577957,799.070618 99.210358,799.818726 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M122.449265,845.328247 
	C120.343498,845.398499 118.598015,845.709106 116.471222,846.026611 
	C116.669441,844.582397 113.754990,843.692566 115.729721,842.030945 
	C119.371765,841.893738 120.780411,838.720093 123.695343,837.331299 
	C125.367409,837.600281 126.480736,838.226501 127.639175,839.505310 
	C128.028976,840.396973 128.129105,840.945190 128.110535,841.936523 
	C127.258827,844.975098 125.564445,846.143188 122.449265,845.328247 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M124.212631,837.680542 
	C123.187187,838.140930 122.205742,838.390320 122.199928,839.283813 
	C122.170692,843.775208 119.680191,843.288696 116.390038,842.047974 
	C116.546288,839.721497 114.296883,835.971558 119.588943,835.982178 
	C120.590889,835.977356 121.121147,835.801453 121.757271,835.185486 
	C121.943985,834.967041 121.991707,834.967712 122.009285,834.983887 
	C122.413475,834.652527 122.864998,834.508423 123.679115,834.794312 
	C123.976799,835.020874 124.032578,835.473816 124.061920,835.700073 
	C124.420021,836.354065 124.530907,836.832275 124.212631,837.680542 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M123.989906,834.630005 
	C123.543358,835.013000 123.109909,835.005188 122.351669,834.998657 
	C121.858215,834.139465 121.689575,833.278992 121.268143,832.204102 
	C120.333084,831.372070 119.854713,830.654297 119.915894,829.356201 
	C119.968758,828.559448 119.990395,828.093445 120.051682,827.278503 
	C122.570503,828.737183 123.896881,831.161743 123.989906,834.630005 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M119.576752,829.019409 
	C120.834129,829.541626 121.010246,830.389526 121.015884,831.653259 
	C119.376831,831.787781 117.857513,831.382507 117.035217,829.355713 
	C117.720207,829.018982 118.463303,829.016174 119.576752,829.019409 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M121.656570,835.000488 
	C121.817307,835.898682 121.874023,837.031189 120.170876,836.202637 
	C120.454201,835.663330 120.911674,835.348633 121.656570,835.000488 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M119.187042,785.767822 
	C116.109940,785.528015 116.839104,780.494080 113.490929,781.258789 
	C109.858612,782.088440 112.967094,785.386536 112.698891,787.215393 
	C111.906799,792.616821 107.274277,792.669861 104.304901,795.216431 
	C104.829712,793.022766 105.552864,790.852539 105.816368,788.627930 
	C106.025925,786.858643 106.957962,784.431396 103.366257,784.052856 
	C102.017448,783.929626 101.254425,783.567871 100.938469,782.196045 
	C100.848259,781.460266 100.854515,781.051025 100.864082,780.334473 
	C102.205757,778.696838 106.513550,779.174255 103.458435,775.186157 
	C103.145226,773.630859 103.099571,772.221252 103.069656,770.388916 
	C105.944878,769.830322 106.684723,771.746887 106.956665,774.038574 
	C107.160812,775.758972 106.615578,777.841187 109.411972,777.982544 
	C111.970871,778.111877 114.571342,778.127319 115.666672,775.309937 
	C116.299088,773.683228 117.033516,772.379883 118.779648,771.300659 
	C119.537277,771.194641 120.085342,771.357483 120.808502,771.754517 
	C122.252846,773.345398 122.460365,774.953552 121.975021,777.073792 
	C119.345512,779.622986 119.484077,782.589111 119.187042,785.767822 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M124.191772,838.245117 
	C124.012894,837.539307 124.024551,837.078674 124.063736,836.272217 
	C126.240662,836.130798 127.597435,838.409424 130.009872,838.085693 
	C131.124573,837.936035 132.032501,838.637878 131.362701,839.914673 
	C129.156204,844.120667 132.813019,847.221436 133.656265,850.960327 
	C133.983856,851.016479 133.994110,851.003540 133.988953,851.009644 
	C136.987640,852.644592 137.920776,855.826782 139.766815,858.746948 
	C139.959198,859.025513 139.988983,859.036560 139.973953,859.044067 
	C140.809189,860.385620 141.821274,861.620544 142.640549,862.958801 
	C143.901901,865.019104 142.737167,865.822571 140.363647,865.938293 
	C139.554779,865.933533 139.111740,865.938538 138.336487,865.934082 
	C134.006958,856.663696 132.203979,854.836304 125.487221,853.473389 
	C125.662994,855.120667 125.829979,856.685486 125.990570,858.633545 
	C120.647865,858.486511 123.692711,855.148193 123.918961,852.376587 
	C123.200409,851.447388 122.227654,852.012268 121.213463,851.294434 
	C120.063797,850.948242 119.271751,850.667297 118.950333,849.345947 
	C119.898682,847.922913 120.915459,846.816528 122.010605,845.399048 
	C124.042030,845.021484 125.853210,844.659302 127.002380,842.424072 
	C127.519485,841.599670 127.701141,841.059631 127.226791,840.199707 
	C126.138672,839.481384 125.260498,838.985779 124.191772,838.245117 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M131.171158,869.635864 
	C130.328583,870.909485 129.360016,871.003845 128.360596,870.982910 
	C126.910156,870.952515 125.147209,871.251892 124.556320,869.529602 
	C123.991386,867.882935 126.199547,867.777466 126.857620,866.330322 
	C126.826950,864.314392 128.046341,864.367615 129.581329,864.456726 
	C131.364746,865.876526 131.597229,867.492065 131.171158,869.635864 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M131.366837,870.027466 
	C130.243301,868.682678 129.875748,867.252930 129.984741,865.332825 
	C130.077377,864.763123 130.179840,864.570129 130.562561,864.207275 
	C134.887589,864.279846 132.568298,869.561035 135.897919,870.725281 
	C134.526123,870.718445 133.128891,870.376221 131.366837,870.027466 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M130.740021,863.838867 
	C130.743622,864.316345 130.578125,864.547913 130.160614,864.844116 
	C129.125397,865.141479 128.275909,865.326782 127.222092,865.774048 
	C125.970901,865.880310 124.581169,866.258240 124.251427,864.789795 
	C123.903709,863.241150 124.912529,862.473694 126.639053,862.050293 
	C127.885178,861.976440 128.700211,862.257202 129.724487,862.840027 
	C130.221542,863.211182 130.450577,863.400085 130.740021,863.838867 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M129.839050,862.769775 
	C129.106979,862.885376 128.263870,862.697021 127.208145,862.258179 
	C127.001984,861.828674 127.008438,861.649719 127.032028,861.202026 
	C128.176147,861.035034 129.118500,861.459534 129.839050,862.769775 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M120.983414,851.382751 
	C121.809052,851.229492 122.667290,851.357422 123.737053,851.737183 
	C123.457054,852.257629 122.965523,852.526245 122.211952,852.899536 
	C121.638611,852.557434 121.327309,852.110657 120.983414,851.382751 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M115.422119,852.953369 
	C115.808418,852.244812 116.197784,852.235229 116.542389,852.741577 
	C116.614494,852.847534 116.612000,853.091980 116.537148,853.195312 
	C116.179062,853.689758 115.789879,853.672119 115.422119,852.953369 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M107.171783,745.322632 
	C109.695183,745.429565 112.040863,744.501465 114.631645,743.046021 
	C118.518326,741.916565 121.233917,742.564758 122.456123,746.444275 
	C123.250000,748.964172 125.709656,750.143738 127.301468,752.514526 
	C128.408081,754.870178 127.699295,756.816711 127.067780,759.116943 
	C125.094078,761.981262 127.631927,764.849731 126.203110,767.747070 
	C125.961586,768.284973 125.841286,768.490295 125.467049,768.949097 
	C124.984253,769.272766 124.755379,769.342957 124.230026,769.210571 
	C122.487244,767.483093 122.964203,765.053162 120.877701,763.600525 
	C119.017624,762.305664 120.215218,760.343933 121.561928,758.938843 
	C123.861755,756.539551 122.863098,755.292542 120.110931,754.536377 
	C117.069717,753.700745 114.581039,752.723206 115.712418,748.466248 
	C116.208397,746.600159 114.212357,745.733521 112.275192,746.420288 
	C110.454742,747.065552 108.754097,747.410828 107.171783,745.322632 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M120.719612,778.213135 
	C120.909096,776.209351 120.937134,774.521667 120.974380,772.411316 
	C121.428429,771.987793 121.873253,771.986938 122.651566,771.984863 
	C124.779510,776.800415 129.441284,779.692017 131.507477,784.780640 
	C132.060303,786.472778 131.846375,787.705200 130.687805,789.083801 
	C129.719513,789.774902 128.970444,789.932983 127.806015,789.871765 
	C126.045044,789.444397 124.837212,788.569885 123.039856,788.280029 
	C122.223869,788.093140 121.777596,787.924744 121.073555,787.478149 
	C118.769974,784.398010 119.071968,781.479675 120.719612,778.213135 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M131.316681,784.818298 
	C128.831268,780.607788 122.537842,778.905457 122.991776,772.386353 
	C123.858002,771.445496 123.970673,770.586975 123.945694,769.341797 
	C124.110558,769.005188 124.287560,769.002502 124.730087,768.997437 
	C126.781776,769.926331 128.082306,771.476501 129.639160,772.699585 
	C134.470276,776.495300 134.838745,779.092957 131.316681,784.818298 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M97.995033,738.361694 
	C99.524406,738.081787 100.586563,738.741333 100.960999,740.652954 
	C100.344955,742.181580 99.413193,743.468567 98.023567,742.639893 
	C96.295616,741.609436 98.410164,740.053528 97.995033,738.361694 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M103.997002,741.353760 
	C106.042709,741.128540 106.981369,742.275452 106.990746,744.641907 
	C105.594276,744.269653 103.907318,743.792786 103.997002,741.353760 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M127.266464,752.756836 
	C124.483551,751.095215 121.226814,749.958862 120.884552,746.001831 
	C120.603371,742.750916 117.170425,744.754700 115.355705,743.154419 
	C113.318321,740.448486 110.380867,738.597168 110.703461,734.905884 
	C110.748009,734.396179 109.382652,733.195129 109.122269,733.312988 
	C105.758560,734.835999 102.452843,732.415955 99.177742,733.250122 
	C95.046333,734.302185 93.024643,731.938660 92.749878,727.629578 
	C92.396950,722.094910 94.513023,716.089478 89.163429,711.133179 
	C87.322037,709.427063 84.269554,705.270874 88.215233,701.634705 
	C89.186447,700.739685 88.327141,699.563416 87.336411,699.350098 
	C83.678963,698.562378 84.114922,695.832947 83.955505,693.142029 
	C83.690857,688.674805 81.111839,684.513306 82.485023,679.744629 
	C82.901955,678.296753 85.344994,666.982727 86.199898,665.552979 
	C87.291969,663.726501 88.028046,661.687134 88.959152,659.369507 
	C89.167435,658.827576 89.334122,658.655640 89.750839,658.741577 
	C90.245247,660.120544 90.489639,661.241699 90.875946,662.681580 
	C91.122879,663.714600 91.169167,664.429443 90.961746,665.494751 
	C88.719826,670.878235 92.352150,676.041260 90.779518,680.931458 
	C87.962929,689.689819 96.368866,694.879028 97.155884,702.435120 
	C97.250473,703.343323 98.472137,703.866089 99.280403,704.442688 
	C101.986984,706.373108 102.215523,708.634766 100.274818,711.258057 
	C99.486115,712.324219 98.772789,713.460510 98.358986,714.733337 
	C97.225594,718.219788 98.315392,720.653137 101.840813,721.597473 
	C105.427193,722.558350 108.524246,724.496704 111.828537,726.011841 
	C114.840919,727.393250 116.596840,729.843201 115.819824,733.215210 
	C115.041512,736.592834 116.859413,738.128113 119.647980,739.639648 
	C120.566856,740.134827 121.148148,740.520020 122.085510,740.980225 
	C123.408821,741.802124 123.952850,742.819275 124.628029,744.156860 
	C125.286507,745.758240 126.271904,746.679871 127.458313,747.897827 
	C127.758385,748.415161 127.853600,748.639038 128.032059,749.223755 
	C128.229080,750.614929 128.080078,751.593933 127.266464,752.756836 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M62.513870,659.873413 
	C61.768532,657.795593 61.394188,655.782593 61.025887,653.377563 
	C65.056358,651.847351 67.531059,653.459106 70.380730,656.550354 
	C74.060440,660.541992 71.471565,663.208801 69.935913,666.790222 
	C67.627831,667.074219 65.994652,666.248047 65.993156,663.665405 
	C65.991920,661.534058 65.146095,660.159241 62.513870,659.873413 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M71.242104,672.799622 
	C70.552696,671.618225 65.993858,672.108398 69.517166,668.997803 
	C71.342430,669.717163 72.518654,670.659363 71.242104,672.799622 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M87.340874,656.069275 
	C86.593201,654.889709 85.531204,653.792603 86.745453,652.202759 
	C89.127113,652.214722 88.993240,653.792419 88.958435,655.678467 
	C88.512047,656.059570 88.086250,656.060303 87.340874,656.069275 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M61.006508,651.612976 
	C60.502766,651.430176 59.998890,650.870483 59.260460,650.146973 
	C59.326782,648.882019 59.627655,647.780945 59.972656,646.336792 
	C63.629612,647.121460 59.893745,649.636414 61.006508,651.612976 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M83.042694,656.321167 
	C83.896042,656.084778 84.673111,656.339172 84.958221,657.673950 
	C84.100998,657.919067 83.324417,657.663330 83.042694,656.321167 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M88.943146,656.382812 
	C89.865753,656.186584 91.013268,656.102234 90.221878,657.822449 
	C89.834343,658.147705 89.667503,658.295410 89.250595,658.221375 
	C88.981956,657.568481 88.963379,657.137329 88.943146,656.382812 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M89.000397,658.249573 
	C89.000526,657.999634 89.500259,657.999878 89.750717,658.000000 
	C90.001183,658.000061 90.000893,658.500000 90.000885,658.749756 
	C89.834053,658.999634 89.667244,658.999756 89.250595,658.999695 
	C89.000755,658.999451 89.000275,658.499512 89.000397,658.249573 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M119.132454,206.278473 
	C119.167046,205.442535 119.358871,204.902878 119.783134,204.186829 
	C120.495827,203.742844 120.976082,203.475266 121.702515,203.082977 
	C123.460403,204.387787 124.167419,206.394760 125.666176,208.261719 
	C125.944603,209.047455 126.020355,209.518509 126.025162,210.359848 
	C125.940208,210.955688 125.877548,211.189423 125.634140,211.737183 
	C124.892845,212.501572 124.366074,213.002579 123.333961,213.104858 
	C123.002258,212.966553 122.939476,212.774994 122.894394,212.679871 
	C122.673233,209.982925 120.756454,208.412109 119.132454,206.278473 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M119.784103,213.106110 
	C120.057678,213.533432 120.028992,213.966492 119.966148,214.725021 
	C119.436340,214.784546 118.676949,214.630508 118.505730,214.226929 
	C118.236519,213.592407 118.860420,213.241440 119.784103,213.106110 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M125.471916,209.331268 
	C124.208557,207.280777 121.317581,206.531769 121.969467,203.338470 
	C123.001114,198.950470 119.363441,195.750793 120.049103,191.394302 
	C120.343925,190.755768 120.617432,190.532806 121.031395,190.076447 
	C122.283127,190.003784 122.874557,190.820663 123.697105,191.836304 
	C124.311523,192.344055 124.577194,192.736938 124.765060,193.568237 
	C124.709129,198.955780 124.887581,203.934280 127.337082,208.792297 
	C126.760727,209.395966 126.298920,209.514740 125.471916,209.331268 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M124.767715,212.291077 
	C125.005692,212.004608 125.127251,211.539902 125.211746,211.315094 
	C125.788528,210.077774 127.027664,210.064697 127.868378,209.044678 
	C128.360840,208.296112 128.735733,207.833679 129.276123,207.131042 
	C129.672150,206.638168 130.155518,206.426086 130.059448,206.110046 
	C129.868164,205.480759 130.331604,206.093155 129.374008,206.160446 
	C128.238907,205.717804 127.643219,205.099304 127.114952,203.959213 
	C126.690285,202.446869 126.736389,201.255920 127.002434,199.701797 
	C127.297783,198.624268 127.626038,197.958389 128.221756,197.008575 
	C128.948776,196.131622 129.567307,195.723923 130.657288,195.433044 
	C131.260208,195.438843 131.497513,195.488281 132.033173,195.739471 
	C134.545731,198.546722 131.522568,201.876511 133.754150,204.759628 
	C133.991455,205.006821 133.997849,205.499771 134.002563,205.746216 
	C135.375244,207.356400 136.416794,208.995346 137.855347,210.704559 
	C137.983078,211.445526 137.983932,211.888474 137.981171,212.663635 
	C132.647186,214.942657 132.715866,219.305801 132.177628,224.056503 
	C131.600250,229.152893 131.135330,234.590714 127.221626,238.883698 
	C125.697945,240.555054 126.994820,243.160873 127.839378,245.048157 
	C128.856873,247.321930 129.133438,249.459381 128.465195,252.128464 
	C128.429626,252.909653 128.426361,253.354309 128.452728,254.126801 
	C128.489929,255.395706 129.074158,256.100189 129.612946,257.211243 
	C130.161621,259.489014 129.271271,260.925751 127.645889,262.432373 
	C126.244942,263.357361 125.075966,263.793762 123.403885,263.481506 
	C121.875397,259.352570 123.640915,255.581528 123.981598,251.351730 
	C124.020737,250.557693 124.021606,250.111664 124.026230,249.331146 
	C124.012810,247.862122 123.986206,246.728455 123.850533,245.304321 
	C124.121735,243.144516 123.642349,241.467621 122.932518,239.749237 
	C121.757858,236.905624 120.687431,233.883102 125.269493,232.911148 
	C126.149887,232.724380 126.845177,232.220642 126.861832,231.296921 
	C126.971497,225.214752 130.561050,218.939301 125.550430,212.998505 
	C124.982773,212.780838 124.756256,212.679184 124.767715,212.291077 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M123.706360,251.160828 
	C125.804214,254.943420 124.702942,258.642059 123.035797,262.645447 
	C123.454933,264.243927 122.682579,264.779907 121.359184,265.102692 
	C119.347656,264.992310 118.046127,265.956238 117.403770,267.205017 
	C115.332802,271.231049 111.545708,272.562897 107.337593,273.941071 
	C106.865433,270.316895 107.873993,267.590240 111.903694,266.422729 
	C114.288628,265.731750 116.603813,264.342224 115.189117,260.934448 
	C114.739769,259.852051 114.961266,257.954071 116.025223,257.858307 
	C120.241119,257.478851 120.940292,253.466782 123.706360,251.160828 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M124.735397,212.076935 
	C125.005692,212.004608 125.117058,212.441757 125.163177,212.662125 
	C124.843529,214.779816 124.809662,216.841522 122.384094,217.959534 
	C122.356392,216.590897 122.673988,215.152451 122.996918,213.340271 
	C123.489868,212.694107 123.977486,212.421677 124.735397,212.076935 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M123.733505,245.211014 
	C124.453583,246.148270 125.453964,247.226562 124.273987,248.794159 
	C122.724304,247.980530 122.814339,246.750031 123.733505,245.211014 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M102.918892,244.672821 
	C102.765625,245.034500 102.592804,245.035385 102.160309,245.052521 
	C99.438087,243.269135 98.591690,241.119202 100.293999,238.296799 
	C101.188629,236.813522 101.291061,235.191116 100.423645,233.284485 
	C100.178223,232.280838 100.173012,231.543030 100.219818,230.440811 
	C101.664917,229.829926 101.927116,228.794632 102.048988,227.278625 
	C103.427994,226.977737 104.745476,226.999344 106.061035,227.061508 
	C106.755852,227.094345 107.699715,227.855469 107.454399,228.050385 
	C103.982498,230.808762 108.220108,233.014679 107.862877,235.363068 
	C107.512238,237.668243 108.529480,241.194901 103.533493,240.609558 
	C100.672539,240.274353 103.080795,243.000656 102.918892,244.672821 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M150.771912,133.004181 
	C150.703369,133.675980 150.162689,134.641937 149.950699,134.574493 
	C147.274887,133.723389 146.193405,134.266556 145.860489,137.405121 
	C145.517441,140.639099 145.405746,143.192139 148.775803,144.799988 
	C150.029449,145.398148 152.725143,143.860901 152.415283,146.733704 
	C152.150116,149.191940 149.423935,150.026688 147.842667,149.662125 
	C143.092834,148.567032 144.091812,151.679718 143.969299,154.555847 
	C141.127686,155.639893 139.384659,157.328445 138.994232,160.657455 
	C136.750687,158.816559 133.978058,157.413406 139.052963,154.369598 
	C141.654678,152.809174 142.210419,147.614120 143.321518,143.949692 
	C143.597427,143.039719 142.197464,142.072418 140.829178,142.021179 
	C139.699753,141.978867 138.318359,142.093414 138.208084,140.435211 
	C138.115601,139.044556 139.236557,138.479187 140.245102,137.861816 
	C141.395172,137.157837 142.459290,136.321472 142.861923,134.960526 
	C144.267319,130.210449 147.084763,130.562424 150.771912,133.004181 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M143.982529,155.410965 
	C146.189362,153.498413 147.977188,151.750305 151.094284,151.526672 
	C153.150253,151.379166 155.966339,150.043945 156.938171,147.472961 
	C158.120682,144.344696 158.386612,140.445496 163.555710,142.697723 
	C164.553665,143.132538 165.045258,141.579529 164.653641,140.559479 
	C164.007339,138.876038 162.909256,137.325607 162.501083,135.600906 
	C161.928665,133.182220 160.570068,130.040558 165.614685,130.017792 
	C167.110428,133.273148 167.436523,136.687195 168.820480,139.914764 
	C170.036530,142.750748 169.252335,145.473083 166.384583,147.746384 
	C163.000000,150.429367 158.609360,152.928146 160.556274,158.772629 
	C161.106094,160.423141 159.641006,161.978134 158.183411,162.824631 
	C155.251602,164.527237 152.262054,167.022003 149.538788,167.944138 
	C144.000366,169.819473 144.588806,173.747757 143.749817,177.949890 
	C143.222733,179.025909 142.624390,179.435013 141.433502,179.251953 
	C142.023514,175.993195 138.534042,174.707993 138.335785,171.603119 
	C138.026886,166.765442 142.655640,164.371384 142.582367,159.969040 
	C142.559265,158.581299 143.451111,157.178329 143.982529,155.410965 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M140.993225,179.414917 
	C141.619873,178.870148 142.150742,178.673706 142.848434,178.240189 
	C143.936890,177.188492 144.240219,176.122803 144.360931,174.938507 
	C144.460541,173.961548 144.536896,172.364685 145.129791,172.188431 
	C150.713684,170.528656 153.063187,162.394485 160.743042,164.760345 
	C160.500565,167.324173 159.672180,169.129822 156.676849,168.437439 
	C154.997574,168.049255 153.896942,168.965271 154.529343,170.649796 
	C156.623718,176.228485 152.489273,180.611267 151.604645,185.599899 
	C151.047363,188.742523 147.911530,191.120621 143.828796,188.848053 
	C139.208939,186.276489 137.024216,192.553375 137.068726,192.985718 
	C137.507584,197.247818 133.256592,200.081100 133.996307,204.604126 
	C132.067795,204.742096 130.953964,204.035110 131.554214,201.726776 
	C131.961533,200.160370 131.872894,198.465012 132.006592,196.412933 
	C132.589554,191.949371 136.272232,188.516663 134.178986,183.864517 
	C133.658768,182.708344 134.814117,181.403168 136.669006,181.002625 
	C138.399033,180.848740 140.040451,181.574020 140.993225,179.414917 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M131.265533,195.681763 
	C130.502472,196.260208 130.007721,196.521011 129.254822,196.890533 
	C124.361084,196.545822 127.224648,192.713516 126.048721,190.330231 
	C125.962791,189.251129 125.968498,188.501785 125.973137,187.377762 
	C126.411964,183.673065 128.203613,182.015579 132.013458,182.631241 
	C135.418259,187.245468 133.654755,191.316788 131.265533,195.681763 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M131.249695,195.999069 
	C131.086685,191.907135 134.009521,188.053284 132.016037,183.395477 
	C131.628693,182.593384 131.453568,182.108429 131.608597,181.169296 
	C132.695816,179.667770 133.886063,179.224625 135.696960,179.736328 
	C136.366852,180.044098 136.616608,180.245773 136.910004,180.744904 
	C136.178802,182.096130 134.138382,183.218063 135.201599,184.610977 
	C138.961777,189.537170 134.010925,192.132385 132.311859,195.833801 
	C131.838791,195.998627 131.670471,195.998688 131.249695,195.999069 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M132.227142,180.797638 
	C132.015778,181.461548 132.015656,181.902344 132.011383,182.673798 
	C129.160919,182.652313 127.808327,184.576324 126.247665,186.803162 
	C125.700867,186.613800 125.112579,186.108536 125.206161,185.852676 
	C126.550003,182.178619 129.111343,179.276535 131.607315,176.384918 
	C132.237869,175.654434 133.443939,176.145309 133.962479,177.627716 
	C133.728027,178.977570 133.058838,179.761154 132.227142,180.797638 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M125.720459,190.165863 
	C128.049973,190.238342 128.247620,191.657043 127.748589,193.220306 
	C127.317467,194.570831 126.983925,195.744766 128.697052,196.839142 
	C128.824692,197.551346 128.652740,198.103470 128.241608,198.828705 
	C127.627411,199.346359 127.182365,199.521866 126.319588,199.452026 
	C124.745720,198.050858 124.179199,196.473785 123.995316,194.363281 
	C123.971756,193.557678 123.960930,193.118362 123.944809,192.349930 
	C124.454277,191.457642 124.969055,190.894455 125.720459,190.165863 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M132.362656,180.979004 
	C132.046921,179.862610 131.837479,178.595139 133.686203,178.038345 
	C134.615005,178.452393 135.198486,178.934509 135.917648,179.669006 
	C134.938690,180.259995 133.824036,180.598618 132.362656,180.979004 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M342.883240,983.946411 
	C342.893951,984.513611 342.694061,984.743347 342.238983,985.050049 
	C341.711823,984.661194 341.378113,984.141602 341.029236,983.304810 
	C341.587494,983.212646 342.160950,983.437622 342.883240,983.946411 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M325.773376,944.790405 
	C326.166718,945.553833 326.345398,946.102783 326.763275,946.823425 
	C328.223938,948.428345 330.702332,949.919189 328.930786,951.862671 
	C326.688019,954.323059 323.669525,954.066467 320.997772,951.427246 
	C320.790314,949.576782 322.639160,948.139160 321.054749,946.837891 
	C319.440369,945.511902 317.699768,946.694763 316.492615,947.442932 
	C310.845215,950.942993 305.607819,949.479004 300.182678,946.316223 
	C298.184082,944.141418 295.781769,942.647278 296.391602,939.402039 
	C296.643066,938.064026 295.659821,937.911804 294.515106,937.813538 
	C286.849670,937.155457 286.089172,936.106873 287.449036,928.366943 
	C287.534760,927.879028 287.720795,927.411682 288.067993,926.619629 
	C288.403625,926.101746 288.566162,925.910095 289.054047,925.518311 
	C290.404297,925.003723 291.410980,924.984741 292.699829,925.734680 
	C293.015198,925.965881 293.003693,925.982788 293.000610,925.992615 
	C295.175934,927.256897 297.123688,928.919678 299.905457,929.676208 
	C301.223145,929.790100 301.957184,930.247864 302.336090,931.568604 
	C304.422607,935.972046 305.916229,940.447632 311.541290,941.099304 
	C313.692139,941.348511 313.705658,943.218079 312.818939,945.510742 
	C315.147888,944.181213 317.196136,943.286133 319.632263,942.415649 
	C320.455353,942.287659 320.920929,942.353943 321.670349,942.713257 
	C322.136932,942.935486 322.307587,942.956116 322.735107,943.000366 
	C323.645386,943.875610 324.766266,943.954285 325.773376,944.790405 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M288.767853,925.903076 
	C288.827820,927.129456 288.631592,928.095398 288.511902,929.070618 
	C287.547516,936.928955 287.547760,936.886230 295.480530,936.125916 
	C296.697113,936.009277 299.541321,935.611511 298.284515,937.838013 
	C296.341095,941.280945 298.876556,942.960388 299.959473,945.654602 
	C298.519714,946.137817 296.946198,944.266479 295.565277,946.153564 
	C294.145691,948.093567 296.451965,948.922546 296.944031,950.655396 
	C293.363159,948.512390 290.004639,952.050598 286.230347,951.224182 
	C285.584412,949.470337 286.955231,949.111450 287.658813,948.339111 
	C288.202881,947.741943 288.827637,947.173645 288.552368,946.254639 
	C288.407990,945.772705 288.013763,945.433350 287.507874,945.420471 
	C286.265198,945.388855 285.956299,946.378601 285.641174,947.287842 
	C285.275269,948.343567 285.215332,949.509644 284.265076,950.692993 
	C283.536865,950.707764 283.030731,950.462646 282.266357,950.103455 
	C281.741180,949.499451 281.474274,949.009521 281.093689,948.264038 
	C279.702728,946.391907 280.172668,944.955933 281.711914,943.297729 
	C282.538483,942.810730 283.088898,942.628052 283.811554,942.204529 
	C284.668488,940.850220 286.200287,939.850708 284.250610,938.183105 
	C281.915558,934.849182 281.607025,930.343750 276.912811,929.179626 
	C275.946075,928.939880 274.103973,928.135986 274.535126,926.538574 
	C275.008270,924.785339 276.820282,925.042175 278.654297,924.968750 
	C280.669067,924.444946 281.839752,925.759644 283.570892,926.140137 
	C284.914642,926.838867 285.807892,926.502197 286.938416,925.649170 
	C287.687164,925.439941 288.122498,925.491211 288.767853,925.903076 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M320.585907,950.974243 
	C321.791229,952.043274 322.991913,951.844666 324.125946,952.022644 
	C328.270142,952.673157 328.761932,950.976624 327.010620,947.359009 
	C331.240265,946.212524 331.421356,949.967041 332.946838,952.629761 
	C332.168365,953.746033 331.878143,954.587891 332.784485,955.797974 
	C332.998962,956.448547 333.002838,956.889709 333.010101,957.661804 
	C333.703888,958.718201 334.419403,959.335938 335.499786,959.478027 
	C342.011810,960.334900 343.118317,962.813599 339.325684,968.889221 
	C336.975739,968.232178 335.310944,966.411377 332.715088,967.775269 
	C331.422028,968.454651 330.129303,967.603088 330.053223,965.875854 
	C329.979340,964.197754 329.505035,963.014771 327.511230,963.023865 
	C325.334167,963.033813 324.094238,963.958313 324.003296,966.607117 
	C322.349030,966.670898 320.048218,967.942505 319.252533,965.585754 
	C318.499969,963.356750 320.217743,962.525330 322.014954,961.699097 
	C323.369354,961.076355 323.014557,959.719482 322.148834,958.785828 
	C320.826416,957.359497 319.722656,955.628479 317.315125,955.030396 
	C316.768433,952.631409 317.102570,950.697388 320.585907,950.974243 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M302.964844,956.643677 
	C300.209717,956.267944 300.606903,953.746948 299.999878,951.410095 
	C303.901733,951.105347 302.369080,954.294434 302.964844,956.643677 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M240.724396,896.272339 
	C243.388428,896.115234 245.389343,896.233276 244.190186,899.739807 
	C242.683655,904.145142 243.967453,905.791748 248.604553,905.973755 
	C251.599701,906.091248 254.587082,905.951599 251.548111,910.200806 
	C251.050186,910.897034 250.913010,911.778503 251.665955,912.448792 
	C252.405945,913.107666 253.191864,912.776489 253.956863,912.394287 
	C256.849182,910.949280 259.964386,908.429260 259.634430,914.956543 
	C259.545715,916.711426 261.985413,915.800903 263.664673,915.135132 
	C264.340851,917.067017 264.764221,919.111450 266.812225,920.738159 
	C268.209259,921.826599 267.973389,923.086731 267.995850,924.632812 
	C266.999969,925.820618 265.922424,926.078735 264.352203,925.254822 
	C263.873169,924.695557 263.684235,924.440918 263.210693,924.125732 
	C261.853210,923.335999 260.990906,922.333069 259.754303,921.262817 
	C257.844940,917.845459 254.549576,917.839233 251.329391,916.992798 
	C250.264618,916.933838 249.524155,916.938904 248.413544,916.934570 
	C246.040314,915.391968 244.663757,913.205750 241.833176,912.544983 
	C239.021118,911.888489 238.956482,908.998779 238.802780,906.280579 
	C238.961899,905.538940 238.967422,905.104553 238.974670,904.344360 
	C237.495667,901.145203 239.348328,898.923706 240.724396,896.272339 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M238.620514,906.048096 
	C240.535873,908.292419 239.793198,912.987549 245.101456,911.657471 
	C245.706879,911.505798 247.778671,914.141602 248.025604,916.565552 
	C246.484467,918.057556 247.987534,919.346436 247.857178,920.551392 
	C247.683960,922.152649 246.638809,922.844055 245.175079,922.932861 
	C244.042770,923.001526 242.237167,923.093811 242.326950,921.741516 
	C242.677963,916.456055 236.956039,913.439392 237.501648,908.131775 
	C237.588074,907.290894 237.344070,906.544312 238.620514,906.048096 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M240.756714,895.878662 
	C241.427475,898.698059 239.909485,900.895569 239.036011,903.648438 
	C234.826065,901.740601 238.053421,898.574402 237.995636,895.401123 
	C238.169571,894.862061 238.335373,894.726562 238.784271,894.742737 
	C239.545212,895.184692 240.023056,895.475037 240.756714,895.878662 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M251.137070,917.219299 
	C254.360184,916.900574 258.290863,915.391602 259.878479,920.654846 
	C260.145386,921.181763 260.254639,921.370361 260.150818,921.806946 
	C257.311615,920.037476 254.261292,918.829041 251.137070,917.219299 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M259.978699,922.344482 
	C259.956055,921.878174 259.974365,921.701416 260.014404,921.258911 
	C261.641693,921.012207 262.108521,922.303284 262.869080,923.711304 
	C262.551056,924.007874 262.102356,924.005188 261.316956,924.010498 
	C260.660004,923.557007 260.339813,923.095520 259.978699,922.344482 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M239.000061,894.588013 
	C238.890518,894.916077 238.713669,894.937805 238.270309,894.978516 
	C237.684448,894.891357 237.424484,894.703186 237.111816,894.216614 
	C236.852188,893.833008 236.704605,893.665771 236.779404,893.248718 
	C237.894867,893.051025 238.651978,893.307861 239.000061,894.588013 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M236.751205,892.998657 
	C237.001358,893.165710 237.000961,893.332581 237.000153,893.749756 
	C236.999756,894.000183 236.499878,894.000122 236.249573,894.000366 
	C235.999008,893.833740 235.998779,893.666992 235.999054,893.250366 
	C236.166580,892.999817 236.333618,892.999207 236.751205,892.998657 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M238.545242,920.472778 
	C238.572876,920.521118 238.517609,920.424500 238.545242,920.472778 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M338.976868,969.351624 
	C340.070099,967.433044 339.899048,965.550354 340.197296,963.773499 
	C340.621063,961.249023 340.126282,959.916809 337.092560,960.753601 
	C335.409607,961.217834 333.289490,961.637024 333.021698,958.378174 
	C334.503357,957.845276 335.993225,957.697876 337.739136,957.282104 
	C340.367737,957.781982 343.730042,957.283813 342.461853,961.866150 
	C341.863159,964.029236 343.910370,965.193298 346.612732,964.994812 
	C347.863220,965.538208 347.994202,966.401611 348.002228,967.655762 
	C345.391418,969.344666 342.789001,970.731140 339.386200,970.989990 
	C338.993195,970.560364 338.987640,970.120789 338.976868,969.351624 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M288.992249,926.109497 
	C288.555359,926.124878 288.122131,926.090515 287.362671,926.062012 
	C286.355957,926.669006 285.634613,927.176819 284.328522,927.075684 
	C282.322327,927.036255 280.610931,927.130920 279.308563,925.175232 
	C280.188202,920.818787 276.612854,918.718384 275.014526,915.395203 
	C279.136230,915.685791 283.349365,916.147278 284.394012,921.521606 
	C284.625122,922.710693 285.908936,923.614014 287.647125,923.957520 
	C288.481659,924.403259 289.043427,924.734009 289.118500,925.672363 
	C288.984253,925.960938 288.995911,926.059875 288.992249,926.109497 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M267.007141,920.629883 
	C264.711151,919.963196 262.149902,919.080383 263.960388,915.329590 
	C266.266693,911.279297 268.962067,913.731445 271.770111,914.741943 
	C271.275116,915.334167 270.501099,916.008972 269.833069,915.916687 
	C265.655304,915.339355 265.252075,917.066162 267.007141,920.629883 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M284.026978,938.356995 
	C285.343811,938.233826 287.351868,937.366333 287.686066,939.308960 
	C288.039215,941.361877 285.630341,940.816467 284.316528,941.828796 
	C283.993866,940.895508 284.003967,939.827332 284.026978,938.356995 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M281.648010,943.033142 
	C281.659882,944.418518 281.331665,945.843628 280.991699,947.638672 
	C279.656860,947.834473 278.230438,947.508606 278.403870,945.861267 
	C278.565338,944.327026 279.843201,943.492432 281.648010,943.033142 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M264.028137,925.339294 
	C265.055817,925.002502 266.123199,925.000061 267.590271,924.997620 
	C267.932861,926.189331 267.581024,927.346130 266.327332,927.602722 
	C264.977234,927.879028 264.372986,926.850403 264.028137,925.339294 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M284.370117,950.957520 
	C284.126617,949.497192 283.930603,947.948975 284.372528,946.611572 
	C284.815186,945.272034 284.601959,942.890991 287.045990,943.278442 
	C288.607971,943.526062 290.352478,944.157532 290.762268,946.006409 
	C291.194397,947.956177 289.719940,948.925354 288.270355,949.745300 
	C287.713348,950.060364 287.066772,950.217041 286.234436,950.700684 
	C285.570984,950.957642 285.134155,950.959900 284.370117,950.957520 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M79.020752,310.342773 
	C80.606377,310.185303 80.553337,310.986969 80.417160,312.423553 
	C80.076378,316.018829 77.394066,318.720276 77.005386,322.623169 
	C76.666344,322.515656 76.339737,322.026276 75.957138,321.232849 
	C75.017265,319.814636 74.133392,318.700470 73.106445,317.311493 
	C73.159836,315.658936 73.581406,314.003326 75.215508,314.377441 
	C78.675018,315.169403 78.559311,312.760437 79.020752,310.342773 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M59.022694,310.328979 
	C60.139999,310.266907 61.226665,310.584656 62.653870,310.936707 
	C64.750656,316.333252 64.296577,316.870880 57.476582,317.032867 
	C56.361759,314.527008 59.149410,313.065491 59.022694,310.328979 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M235.631088,891.966064 
	C236.002731,892.173279 236.008896,892.341431 236.000183,892.761658 
	C234.593216,893.015076 233.201096,893.016357 231.390839,892.988281 
	C230.977432,892.515930 230.982147,892.072876 230.990143,891.296631 
	C232.417465,891.284607 233.841522,891.605896 235.631088,891.966064 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M229.924637,899.642578 
	C229.224564,899.981140 228.479172,899.990662 227.360275,900.006226 
	C227.484558,897.848633 228.393570,897.387390 229.924637,899.642578 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M196.372940,868.034180 
	C194.306610,868.005432 193.596848,869.205872 192.720871,870.487427 
	C189.620285,875.023193 183.066803,875.083862 179.050781,870.363037 
	C180.136612,868.649170 181.687180,868.738647 183.585083,868.808899 
	C184.864105,868.966003 185.770248,869.242493 187.036926,868.914551 
	C190.505188,867.835449 191.750824,865.018250 193.465714,862.623535 
	C196.689362,858.122009 198.437088,857.549255 203.741302,859.319214 
	C205.253265,860.724182 206.671844,861.656006 208.306427,862.899048 
	C208.808044,863.948730 208.800995,864.688171 208.239014,865.707031 
	C204.762650,866.496826 201.413330,866.116211 198.151672,867.717651 
	C197.560486,868.024109 197.128540,868.026245 196.372940,868.034180 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M207.987152,866.241333 
	C207.987137,865.242310 207.992813,864.494080 207.990234,863.371704 
	C207.448013,862.412476 207.228470,861.724609 207.424713,860.582153 
	C208.562485,858.630554 210.222824,858.110840 212.274933,857.578064 
	C212.886703,857.547119 213.132309,857.572388 213.687347,857.801270 
	C215.092117,861.508423 211.995941,863.497131 211.002258,866.627869 
	C210.238449,867.002625 209.488724,867.000366 208.363586,866.995972 
	C207.989761,866.826599 207.991318,866.659363 207.987152,866.241333 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M211.998230,870.382080 
	C214.098404,870.535706 214.782852,871.391479 212.335449,872.950745 
	C212.003723,872.256653 212.001358,871.506714 211.998230,870.382080 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M185.314148,869.992310 
	C183.264343,869.993896 181.559692,869.996399 179.429077,869.998169 
	C178.686432,869.887329 178.427811,869.697815 178.113922,869.213745 
	C173.201706,863.192444 175.125443,857.244873 177.943085,851.052612 
	C179.188019,850.647644 179.803146,851.150940 180.349991,851.795593 
	C184.483978,856.668823 188.283676,857.540649 194.504776,855.041931 
	C199.413361,853.070312 201.804169,853.930176 204.262604,859.323059 
	C198.452850,858.011780 195.448563,860.885193 193.198563,865.493347 
	C192.103561,867.735962 190.664734,869.801636 187.405487,869.985840 
	C186.579651,869.991638 186.119461,869.992920 185.314148,869.992310 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M179.257721,850.662598 
	C176.847366,856.631409 175.409622,862.305176 177.992340,868.607178 
	C176.822235,868.333374 175.696564,868.290405 174.324997,868.677429 
	C171.987198,869.337097 171.149643,867.825928 171.007065,865.372559 
	C170.999390,865.000000 171.010513,864.505859 171.014084,864.258728 
	C172.974808,862.260986 172.059631,860.872986 170.474121,859.541077 
	C167.418274,856.973816 164.943832,853.871704 162.501358,850.749451 
	C161.258362,849.160461 160.360779,847.406738 160.745422,844.957153 
	C166.012161,840.779175 166.037552,840.753967 170.190796,844.938843 
	C171.378906,846.135925 172.652084,846.485596 174.603622,845.948486 
	C177.815628,846.030579 180.038467,846.774048 179.257721,850.662598 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M191.695557,879.065063 
	C191.785446,880.660278 191.196045,882.002014 189.174408,882.136963 
	C189.094208,880.426086 189.772766,879.382324 191.695557,879.065063 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M160.932724,844.647095 
	C163.165680,850.766418 167.221909,855.099854 171.872025,858.877563 
	C174.299133,860.849365 174.976913,862.325073 171.369415,863.963501 
	C169.624115,861.033081 162.546097,855.768921 159.794037,855.913391 
	C156.281952,856.097717 157.409134,853.983398 158.069168,852.430908 
	C159.805206,848.347595 158.806335,846.454834 154.182541,846.952637 
	C153.165283,847.062256 152.041367,846.729065 151.599701,845.794067 
	C149.912445,842.222473 146.731552,843.085266 143.118042,842.839722 
	C141.123810,841.406982 140.923874,839.744263 140.704834,838.099060 
	C140.500931,836.567627 140.300995,835.062866 137.950256,834.879883 
	C136.911743,834.741211 136.275238,834.490112 135.474014,833.797363 
	C134.590454,832.562988 133.852600,831.641174 133.281067,830.208740 
	C133.142593,829.371460 133.175461,828.887939 133.398102,828.060608 
	C135.025848,825.479858 136.201721,822.892273 139.664795,822.402100 
	C143.095901,826.479919 146.449585,830.202942 151.818481,832.031250 
	C152.501297,832.504761 152.845749,832.827209 153.347137,833.478638 
	C154.354874,836.955322 153.458649,841.144104 158.649979,842.099609 
	C159.801498,842.740662 160.452866,843.406555 160.932724,844.647095 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M138.216614,847.105103 
	C138.429947,845.803406 138.908951,844.605469 139.623001,843.189392 
	C140.577469,842.359924 141.384537,842.223755 142.627930,842.770142 
	C142.983444,843.014526 142.995117,843.005188 142.990875,843.011108 
	C142.719711,845.975647 147.009995,846.509888 146.258911,849.803406 
	C144.272217,849.296509 142.746658,847.976746 140.350937,848.006836 
	C139.486252,847.739563 138.984253,847.474182 138.216614,847.105103 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M153.926880,855.630005 
	C152.360687,855.685547 150.334503,856.838379 149.077698,854.367554 
	C150.645584,854.306213 152.668900,853.163452 153.926880,855.630005 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M139.961731,858.617371 
	C136.830276,857.520386 136.054230,854.137329 134.044312,851.353271 
	C140.262955,849.850586 138.797211,855.124329 139.961731,858.617371 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M140.213623,866.201782 
	C144.847412,863.860107 138.979004,861.812622 139.972382,859.401672 
	C141.813843,860.103088 143.651337,861.347229 143.866608,863.603943 
	C144.082062,865.862488 143.165207,867.424927 140.213623,866.201782 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M257.999146,99.368835 
	C259.949097,97.801094 262.112549,97.997215 264.249786,97.994110 
	C265.895874,97.991722 268.377686,98.624977 269.047089,97.802673 
	C272.422089,93.656967 275.256012,97.236320 278.649292,98.005173 
	C276.907867,101.761101 273.271210,102.969849 269.438202,102.896873 
	C265.193024,102.816063 263.415710,104.573868 262.932495,108.599747 
	C262.614288,111.250648 262.609497,114.489296 258.351379,110.736839 
	C254.838898,107.641495 246.396133,112.376022 246.233658,117.032471 
	C246.217453,117.497154 246.357773,118.010040 246.563736,118.433266 
	C249.321869,124.100876 249.332901,124.131889 243.373444,124.993385 
	C241.969650,125.196320 240.729446,125.447945 240.244125,126.968071 
	C239.769211,128.455673 240.374557,129.461090 241.607010,130.351791 
	C244.198761,132.224854 246.639191,134.880493 244.218460,137.796219 
	C241.881592,140.610947 237.414902,143.733551 234.372498,141.569717 
	C229.129654,137.840866 224.669571,140.020950 219.404877,140.995605 
	C218.618835,140.658981 218.379807,140.230530 218.293686,139.344818 
	C221.717743,134.019394 226.496262,130.621979 231.824539,127.325356 
	C232.954819,127.069664 233.695007,127.145966 234.692383,127.756622 
	C240.190369,128.275208 239.220078,122.118484 242.853516,120.303177 
	C243.176361,117.866783 240.066040,115.400536 241.960815,114.110725 
	C245.588745,111.641129 246.671204,106.270187 251.756271,105.709663 
	C254.149475,105.445869 256.303375,104.870018 257.739380,102.241043 
	C257.979309,101.222885 257.985718,100.481262 257.999146,99.368835 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M257.613708,102.016815 
	C258.462952,105.722031 256.399567,107.047615 253.057053,106.972595 
	C250.355316,106.911942 248.090942,107.194954 247.831345,110.649033 
	C247.780304,111.328171 247.035873,112.458138 246.509155,112.520691 
	C241.287018,113.140892 242.986237,116.598053 243.245087,119.789772 
	C239.432144,116.069641 239.016998,111.291389 239.328232,106.318008 
	C239.338531,106.153572 239.325424,105.980537 239.372574,105.826630 
	C239.870636,104.200966 250.002808,100.200905 251.391434,101.299118 
	C253.297455,102.806541 255.111588,103.226768 257.613708,102.016815 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M229.648071,118.070976 
	C233.467102,118.699051 234.870605,121.345329 235.977966,124.671982 
	C235.716492,125.147110 235.463287,125.342728 235.151398,125.825882 
	C234.461288,126.531425 233.811707,126.841225 232.679077,126.836250 
	C230.420044,126.301888 228.912720,125.097488 227.041367,123.825012 
	C226.051788,122.984344 225.445099,122.265778 224.676865,121.229599 
	C224.382721,120.465096 224.351456,120.003708 224.578674,119.223457 
	C226.095291,117.703369 227.839020,118.695457 229.648071,118.070976 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M231.801575,126.324722 
	C232.753708,126.007317 233.513260,126.009239 234.653915,126.024963 
	C235.024796,126.472595 235.014542,126.906418 234.998077,127.666008 
	C234.242477,128.001877 233.493073,128.011963 232.368774,128.025543 
	C231.684250,127.617760 231.564484,127.153725 231.801575,126.324722 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M203.766632,128.315002 
	C209.396484,127.254631 208.664978,122.427559 209.948517,118.989204 
	C210.377914,117.838928 209.470840,116.129166 210.969345,115.434296 
	C212.706497,114.628769 213.056763,116.547073 213.937119,117.367790 
	C215.498428,118.823349 217.046677,119.958290 218.944153,117.395966 
	C220.711700,115.817513 221.964035,116.686188 223.286407,118.262405 
	C221.967239,121.563988 221.327087,124.293968 224.365082,126.890923 
	C225.776428,128.097397 226.339737,130.074463 224.778336,131.747360 
	C223.180511,133.459274 221.268661,133.173660 219.334183,132.228943 
	C214.869583,130.048645 214.797913,130.062256 214.762772,135.351639 
	C214.738190,139.052399 213.052460,140.479858 209.161041,139.510071 
	C208.725388,135.817673 207.287216,133.249237 203.513580,131.916534 
	C202.915695,130.580261 202.953964,129.585846 203.766632,128.315002 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M223.270477,119.199066 
	C222.220459,117.771378 220.929977,117.465401 219.346741,117.072266 
	C217.171967,115.301376 218.601471,113.862854 219.717331,112.155991 
	C220.987396,111.177040 221.949646,110.290169 223.706772,110.713867 
	C224.801437,111.264679 225.567383,111.619377 226.647278,112.018806 
	C226.987946,112.781410 227.014679,113.499283 227.064636,114.576538 
	C227.828491,115.896751 228.728210,116.708778 229.772583,117.799164 
	C229.982635,118.016373 229.995148,118.006302 229.988403,118.012192 
	C229.282104,121.471710 227.099838,119.045296 225.187378,119.148407 
	C224.427917,119.438164 223.991943,119.521873 223.270477,119.199066 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M201.410843,132.325882 
	C200.494095,130.464188 198.900055,128.767593 201.487503,127.054100 
	C201.863266,127.134636 201.914017,127.300865 202.003922,127.724854 
	C202.900711,128.965866 203.477493,130.063568 203.319199,131.774689 
	C202.759644,132.436798 202.276398,132.560318 201.410843,132.325882 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M202.128693,124.374557 
	C203.194199,123.673904 204.206497,123.299835 205.541595,122.870392 
	C206.006912,125.016319 204.273560,125.360695 202.433899,125.980087 
	C202.087555,125.582695 202.081512,125.141922 202.128693,124.374557 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M223.973236,110.602707 
	C222.915482,111.239998 221.795471,111.570015 220.324738,111.920822 
	C220.732315,110.067902 221.464752,108.132317 223.973236,110.602707 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M203.209991,132.108383 
	C202.642120,130.861557 202.348953,129.765930 202.049423,128.326447 
	C202.480408,127.987335 202.917740,127.992058 203.683746,127.999001 
	C204.022263,129.065659 204.032120,130.130096 204.047165,131.593704 
	C203.863129,132.081772 203.673920,132.170670 203.209991,132.108383 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M206.323090,119.948486 
	C206.017380,119.233093 206.037582,118.467270 206.129578,117.323135 
	C208.160767,117.654846 208.730515,118.575516 206.323090,119.948486 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M229.953369,117.678787 
	C228.422745,117.944038 227.373596,117.254395 227.092957,115.282852 
	C228.563583,115.112373 229.615311,115.788750 229.953369,117.678787 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M264.777954,83.167809 
	C266.061127,83.918594 265.800262,84.537872 264.284485,84.963684 
	C264.175293,84.442032 264.354736,83.889877 264.777954,83.167809 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M202.624512,131.983856 
	C203.121124,131.967316 203.306976,131.977417 203.772583,131.990204 
	C204.712372,132.052460 205.372986,132.106201 206.032333,132.172592 
	C210.270111,132.599167 211.798462,134.357620 208.991333,138.605759 
	C208.616196,139.309784 208.196762,139.511978 207.355286,139.501373 
	C205.092789,137.964310 203.406464,136.187057 200.495911,136.981613 
	C198.838028,137.434204 197.405243,136.143219 195.728088,135.057236 
	C194.432220,134.053467 193.902115,133.016083 193.945160,131.363037 
	C194.390808,129.716232 192.086899,128.230255 194.596191,127.019669 
	C196.393265,127.691193 196.853745,129.199966 197.796936,130.748444 
	C198.950043,131.416183 199.098999,133.226334 200.877289,132.341736 
	C201.486771,132.098633 201.900253,132.054581 202.624512,131.983856 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M197.993179,130.599243 
	C196.509995,130.316589 195.458710,129.251068 194.983505,127.290512 
	C194.928040,126.533333 194.949463,126.103767 194.935303,125.353218 
	C195.217865,124.248840 195.536011,123.465439 195.852386,122.364914 
	C197.852219,124.426430 196.770416,127.613106 197.993179,130.599243 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M193.638763,130.997528 
	C194.514191,131.864883 195.032288,132.726608 195.793549,133.778214 
	C196.139801,134.201874 196.242920,134.435669 196.154633,135.002563 
	C195.324234,135.810318 194.592484,135.993362 193.434265,135.990814 
	C191.564133,135.649124 190.361984,134.778122 188.867676,133.705139 
	C186.498703,130.876541 182.842926,130.537399 180.684418,127.861824 
	C179.640701,126.568077 178.077103,128.900665 176.291214,128.204590 
	C176.004776,125.967842 176.002502,123.929893 176.008499,121.455460 
	C177.418808,119.690125 179.142258,119.472733 181.405518,119.605011 
	C182.761230,119.636253 183.608078,119.877594 184.698669,120.674011 
	C184.413788,125.335709 188.227203,124.729729 190.480988,125.771408 
	C192.813385,126.849411 188.115753,131.554977 193.638763,130.997528 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M185.366150,120.992920 
	C184.263275,120.987602 183.515640,120.982849 182.394409,120.970222 
	C181.181778,120.039543 179.934158,119.592331 179.081711,118.255157 
	C179.150360,116.659653 180.051422,115.509804 181.153275,116.327309 
	C183.376862,117.977058 186.480927,117.585182 188.674973,119.966782 
	C188.066025,121.170631 186.864471,120.965240 185.366150,120.992920 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M176.003693,128.003128 
	C178.696014,126.088081 181.290756,123.468941 183.456451,128.763062 
	C184.315536,130.863098 188.270889,129.180954 188.967682,132.626602 
	C187.161285,135.291855 184.574387,134.514053 182.164062,134.721481 
	C180.067917,134.901886 177.019226,134.393906 177.973877,138.653320 
	C177.998825,139.443893 177.997864,139.887772 177.994446,140.664581 
	C176.994843,142.074371 176.043793,143.176483 174.974777,144.206543 
	C171.601440,147.456833 167.649185,150.374161 167.642151,155.908478 
	C167.638535,158.749619 164.853027,160.281326 163.239243,162.753265 
	C162.419815,163.495148 161.837234,163.985855 161.132050,164.740417 
	C158.935745,165.408630 156.825836,165.685043 154.799850,166.258148 
	C153.201584,166.710251 151.606598,167.461227 152.371964,169.708969 
	C153.038742,171.667236 151.752731,172.300629 150.367096,171.905838 
	C146.951599,170.932755 145.600143,171.972336 145.862793,175.511139 
	C145.955353,176.758377 144.993118,177.563354 143.387848,177.948151 
	C141.124863,174.375214 144.431030,171.412567 144.307510,168.007828 
	C144.303040,167.884491 144.544464,167.626083 144.600494,167.644638 
	C149.401978,169.232925 150.579544,163.372803 154.590240,162.873962 
	C159.411270,162.274323 160.335175,160.027802 158.231110,155.826279 
	C157.794159,154.953751 156.865005,153.385559 157.660004,153.300827 
	C161.918396,152.846970 161.457321,147.560364 165.461227,146.458893 
	C169.643616,145.308334 168.508957,141.401382 166.960999,138.657532 
	C165.473221,136.020309 164.418900,133.661713 165.968872,130.405029 
	C166.767532,130.038544 167.513580,130.046677 168.632706,130.070679 
	C171.317368,130.091232 173.913544,131.252441 175.773804,128.242538 
	C176.006271,128.006775 176.000305,128.000473 176.003693,128.003128 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M175.628448,128.048126 
	C175.397491,132.853790 172.725418,132.499374 169.225067,130.348145 
	C170.720688,128.274170 173.354431,129.334976 175.628448,128.048126 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M178.643127,118.001053 
	C180.463760,118.084068 181.576950,118.701057 181.975693,120.626152 
	C180.304474,120.977921 178.588135,120.993484 176.444275,121.014008 
	C176.011383,120.844429 176.006012,120.669884 175.990784,120.233253 
	C176.462372,118.961456 177.175049,118.231880 178.643127,118.001053 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M196.338898,795.943420 
	C195.528870,795.853699 194.970947,795.822083 194.600967,795.542053 
	C192.462463,793.923889 189.672104,792.152710 187.621765,793.962036 
	C185.617935,795.730408 187.692612,798.152222 189.255768,799.943726 
	C190.210342,801.037720 189.964813,802.450378 190.033615,803.754944 
	C190.144760,805.862549 191.923401,806.600281 193.095810,805.825256 
	C196.231018,803.752869 200.458633,805.358582 203.378036,802.255432 
	C201.955017,805.894104 206.933136,809.097534 203.272705,812.786865 
	C202.288361,813.464050 201.759644,813.102173 201.129028,812.308472 
	C200.171860,810.901184 198.295242,810.547729 197.936676,811.136047 
	C195.771835,814.687927 192.371674,812.626709 189.618179,813.217590 
	C185.815994,814.033508 184.148972,812.158875 185.901642,808.818848 
	C187.559509,805.659546 186.531342,805.178040 183.595856,804.947266 
	C179.361374,804.614258 177.997330,801.144836 180.263748,797.528015 
	C182.120102,794.565613 180.486862,791.530640 179.108261,789.145996 
	C177.800781,786.884399 175.724930,788.107544 174.304672,789.951660 
	C173.576263,790.897461 172.602402,790.835510 171.687317,790.093506 
	C171.041840,789.570068 170.428360,789.002380 169.752411,788.522217 
	C167.751648,787.100952 163.608139,789.519470 163.310394,785.511536 
	C163.123383,782.994141 164.375381,780.369751 164.990906,777.396729 
	C164.322784,776.629333 163.824753,775.380798 162.916473,776.778687 
	C162.368927,779.815552 160.552826,779.066284 158.813782,778.346436 
	C156.639923,777.446472 157.694778,776.292358 158.379700,774.854248 
	C159.432022,772.644592 160.066071,770.235718 160.916840,767.551880 
	C161.248535,766.487549 162.585587,766.464661 162.254211,765.467896 
	C162.118805,765.060547 161.378891,765.292297 160.653259,765.705200 
	C154.107803,766.027039 152.348679,764.368408 151.978546,758.325928 
	C151.648804,752.942688 150.583801,747.849915 145.142838,745.067749 
	C143.860474,744.412048 143.205505,743.441711 142.997345,741.968750 
	C142.426620,737.930115 140.524506,734.952698 136.378174,733.600708 
	C132.332596,732.281494 131.710281,728.699890 133.869263,726.100403 
	C137.215027,722.071960 135.917404,718.501709 133.909027,715.141235 
	C131.703522,711.450928 130.555679,706.862122 128.287979,703.899109 
	C124.307617,698.698181 121.533134,694.242126 123.953613,687.775269 
	C118.740311,680.600769 118.749222,671.238953 113.951614,663.886841 
	C113.387436,663.022278 112.769402,662.290100 111.351837,662.040710 
	C109.572945,658.931213 106.469315,656.332825 110.322716,652.403503 
	C111.621811,651.078857 109.937576,647.742188 107.198936,646.589783 
	C102.790588,644.734802 100.790451,640.509338 98.063393,637.028381 
	C96.887680,635.527649 96.734566,633.583557 97.034843,631.771179 
	C98.875351,620.662231 95.578537,610.758057 90.186363,601.270081 
	C88.399467,598.125854 90.055817,595.250488 91.721481,592.220825 
	C93.763580,591.746765 94.090141,593.181885 93.912819,594.196106 
	C93.120743,598.726685 95.652779,602.235168 97.363914,605.950317 
	C98.987526,609.475464 99.024994,613.291870 99.896324,616.843445 
	C101.940445,625.175415 100.869652,635.015686 108.137505,641.288147 
	C113.411209,645.839539 118.331612,649.767578 114.910233,657.638794 
	C114.617172,658.312927 115.077499,659.382996 115.662415,659.944275 
	C120.037300,664.142761 120.615669,669.956604 122.339424,675.292664 
	C123.526909,678.968628 124.131233,679.521362 128.149384,681.371094 
	C128.737167,680.194336 127.857193,679.333679 127.233772,678.780884 
	C120.514366,672.823547 120.415024,663.843994 117.277344,656.318115 
	C115.519333,652.101501 116.216881,646.774658 113.474274,642.617493 
	C109.600609,636.745911 108.052605,629.790649 104.438927,623.797058 
	C101.683258,619.226440 102.462608,614.078186 102.793701,609.137085 
	C103.025368,605.679871 102.426109,602.889954 99.508316,600.821228 
	C95.588226,598.041870 94.790489,593.321594 92.263672,589.255920 
	C91.685158,588.212341 91.370018,587.424622 91.027283,586.318481 
	C92.249481,584.943237 92.744614,583.521912 92.497963,581.989197 
	C91.726593,577.195618 92.986450,572.112976 89.536583,567.666687 
	C87.934135,565.601379 88.475922,562.583130 90.518318,560.339050 
	C91.606949,559.142944 93.138672,558.228210 93.251816,556.249939 
	C87.026352,562.222412 88.584450,569.097656 90.881721,575.943542 
	C91.952698,579.135071 93.157440,582.180969 91.232452,585.740417 
	C90.255371,585.691284 89.069031,585.575439 88.836876,585.043274 
	C87.038017,580.919922 85.769173,576.564514 82.359848,573.269348 
	C81.773933,572.703064 81.733185,570.670105 82.280182,569.981628 
	C85.697929,565.679504 83.908211,559.809265 86.944702,555.373779 
	C88.159241,553.599670 88.659660,551.703674 85.245422,550.603516 
	C82.727058,549.791992 81.057793,546.141052 82.232605,543.974243 
	C85.454453,538.032043 81.980011,532.776489 81.603149,527.236511 
	C81.347260,523.474792 82.112198,520.345886 86.753342,519.283447 
	C87.325684,518.582214 87.751152,518.267639 88.459312,517.955078 
	C88.660721,517.850708 88.879196,517.534729 88.731461,517.385254 
	C87.921570,516.966248 87.224861,516.975647 86.233139,516.986694 
	C83.829918,517.120422 82.965660,516.142761 82.985199,514.025269 
	C83.085030,503.207336 83.822243,492.332397 80.434746,481.788727 
	C79.992378,480.411865 80.864693,479.546143 81.737984,478.801514 
	C83.732170,477.101074 84.585419,474.771301 85.739197,472.222900 
	C89.327873,473.127136 87.948334,475.516754 87.438713,477.591553 
	C84.948051,487.731781 86.258682,497.637970 89.213745,507.444519 
	C89.398277,508.056885 89.845741,508.604706 90.678642,508.749786 
	C91.985062,507.257538 90.997871,505.615021 90.853592,504.061432 
	C90.528893,500.565063 90.036697,496.991547 91.738838,493.773834 
	C93.480888,490.480774 93.414291,487.490387 91.504707,484.358490 
	C89.956024,481.818481 89.863663,479.607147 93.370613,478.638245 
	C95.342346,478.093475 95.514366,476.788879 94.753593,475.170868 
	C93.476479,472.454712 93.525818,469.702820 94.429932,466.908295 
	C94.926758,465.372711 94.638397,463.995178 93.720421,462.664246 
	C90.001526,457.272491 90.346786,451.417328 90.487808,445.086975 
	C90.657455,437.470795 94.045303,430.271484 92.692421,422.583954 
	C92.435966,421.126678 92.680962,419.093903 93.937004,418.446106 
	C98.426437,416.130554 97.621643,412.516663 97.070953,408.661072 
	C96.719048,406.197235 97.269249,403.603699 98.158539,401.310516 
	C100.207199,396.027588 101.192825,390.623749 102.080772,385.024841 
	C102.848976,380.181030 105.888298,375.667633 107.227997,370.699432 
	C107.838974,368.433716 108.244835,366.666931 106.034203,364.583923 
	C107.200714,366.858643 108.983925,368.677216 106.235123,370.819153 
	C102.097313,371.717194 102.796410,373.176941 105.415024,375.276184 
	C105.499100,376.126038 105.520454,376.707367 105.223969,377.073975 
	C101.524048,381.648529 99.888878,386.758789 101.029533,392.635345 
	C101.089066,392.942047 100.983337,393.358093 100.774002,393.583740 
	C95.917114,398.818848 95.321701,404.919800 96.934685,411.560303 
	C97.301102,413.068787 96.726074,414.745514 95.472595,415.615509 
	C92.178413,417.901917 92.597374,421.193787 92.419281,424.529114 
	C91.997513,432.427734 91.135239,440.292786 89.710464,448.084747 
	C89.189232,450.935364 89.531151,453.739227 90.414543,456.450592 
	C92.158569,461.803528 90.476059,466.190216 86.402283,470.039062 
	C82.434235,461.282867 80.094704,452.162598 80.258057,442.813782 
	C80.350098,437.546661 83.749748,432.639740 84.475395,427.202484 
	C84.926033,423.825928 86.548645,420.715576 85.366646,417.015778 
	C84.715836,414.978638 85.676544,412.626923 88.970490,413.470825 
	C92.308784,414.326080 93.682457,413.163666 91.913200,409.920105 
	C89.293854,405.118073 88.946533,401.051636 93.930847,397.312103 
	C95.867912,395.858795 96.888519,393.825531 93.234322,391.648804 
	C89.866020,389.642395 89.457428,384.901703 88.103523,381.208221 
	C87.942177,380.768066 88.454361,379.591644 88.797989,379.527618 
	C96.417999,378.108276 95.034370,370.651672 97.639481,365.990143 
	C98.889999,363.752472 99.642632,361.208038 101.811172,359.116486 
	C103.080574,358.656952 104.001099,358.305603 104.791229,357.204407 
	C105.220596,355.866333 105.164886,354.580170 105.706978,353.637909 
	C107.789536,350.017914 108.019707,346.409088 107.115562,342.304138 
	C105.863571,336.619873 108.924889,331.703033 111.248627,326.828766 
	C112.936279,323.288727 113.210907,318.972473 117.928719,316.957031 
	C119.625351,316.232208 122.136101,313.175110 120.497505,309.472931 
	C119.198486,306.537964 122.154816,305.294739 124.650772,304.040100 
	C125.234055,304.239410 125.466583,304.489532 125.588531,304.794067 
	C126.343941,306.680573 127.364082,309.147614 129.498810,308.547638 
	C131.671051,307.937164 130.982697,305.189148 130.790833,303.313263 
	C130.102631,296.584686 131.761322,294.406616 138.835617,293.260773 
	C141.330566,292.856659 143.046402,292.198456 141.673706,289.088196 
	C138.893158,282.788177 139.019348,282.505249 144.608597,277.595703 
	C141.676147,280.014435 138.901321,282.379364 139.978378,286.980591 
	C140.571732,289.515411 138.805267,291.406036 136.438919,291.458984 
	C130.891907,291.583008 129.890823,295.479950 128.731430,299.587463 
	C128.202438,301.461548 128.719803,304.217773 125.275978,304.215485 
	C125.741470,301.901764 125.218826,299.776794 124.958649,297.710419 
	C124.348152,292.861603 126.049011,288.405060 127.128021,283.089111 
	C130.817719,278.328094 134.990524,274.720306 136.619293,268.924011 
	C139.016815,266.800690 141.144180,266.480713 143.547623,269.413452 
	C139.639359,264.076355 140.695557,261.270538 146.698944,260.487488 
	C148.493851,262.277985 147.151031,264.508789 148.415344,266.620941 
	C150.754623,265.623993 152.358704,263.525360 154.206848,261.968262 
	C156.443573,260.083740 152.882217,260.171844 152.587646,258.603424 
	C153.126450,255.011826 151.117340,251.183105 155.014755,248.555511 
	C158.576675,251.595718 158.537827,256.612610 162.203613,259.813690 
	C161.948395,263.146118 159.459579,264.506744 157.574875,266.313080 
	C153.910385,269.825226 153.926025,270.766541 157.857605,274.011261 
	C160.582550,276.260162 160.351929,281.449371 156.796509,284.408752 
	C154.301910,286.485138 152.875000,288.783752 152.290283,291.839478 
	C151.839615,294.194672 150.171844,295.606873 148.089676,296.437897 
	C144.039719,298.054321 141.711746,301.210480 140.372314,305.106934 
	C138.445267,310.712799 136.503494,316.151001 137.827179,322.511902 
	C138.595627,326.204651 137.957489,330.993561 132.997574,332.725616 
	C131.206406,333.351135 132.220291,334.635406 131.965057,335.608276 
	C130.663681,340.568726 129.964417,345.748840 124.750557,348.674591 
	C122.707214,349.821228 121.451584,352.275238 121.123749,354.477905 
	C120.346992,359.696869 116.992981,364.386078 118.050041,369.973816 
	C118.297348,371.281067 117.766190,372.667145 117.010330,373.767853 
	C113.512451,378.861511 114.390373,384.243469 116.060036,389.573151 
	C116.697899,391.609222 116.772301,393.506165 116.196793,395.431427 
	C114.252541,401.935608 115.066719,409.542328 108.691208,414.306427 
	C108.032158,414.798920 107.680588,415.751587 107.766113,416.560394 
	C108.174423,420.421539 106.335960,424.066132 106.124725,427.535095 
	C105.546906,437.024261 102.632439,446.260529 104.098656,456.067352 
	C105.253311,463.790314 103.900528,471.811127 102.806427,479.668610 
	C101.509468,488.982971 98.596062,498.038116 98.430534,507.528442 
	C98.368202,511.101532 98.902435,514.406006 101.067657,517.450378 
	C103.124321,520.342102 103.430519,523.510559 101.704437,526.868713 
	C99.312538,531.522156 99.106407,536.109680 102.861755,540.319519 
	C104.535187,542.195435 105.113823,544.348877 104.191940,546.798767 
	C102.513687,551.258728 104.311531,554.432678 107.789528,557.208557 
	C110.456322,559.336975 113.800720,561.062744 114.055206,565.530151 
	C113.881104,566.976257 113.441284,567.913452 112.558838,569.053345 
	C105.877357,571.791992 103.587730,575.948792 104.898796,583.229553 
	C106.992477,594.856384 110.439201,606.141113 113.769615,617.452087 
	C114.006783,618.257568 114.254768,619.138489 114.895233,619.650208 
	C119.619987,623.425354 119.706207,629.232849 121.558418,634.308777 
	C124.460678,642.262207 127.440521,650.195129 130.611588,658.048462 
	C135.021866,668.970825 138.989685,680.079651 144.259018,690.644287 
	C144.861206,691.851562 145.174149,693.226013 144.800507,694.536133 
	C143.882309,697.755859 144.699814,700.794189 145.651749,704.344116 
	C147.163116,703.392395 148.377609,701.795105 150.351273,702.197510 
	C152.239441,702.582581 153.752060,703.509521 154.951401,704.972656 
	C157.782028,708.425903 158.061615,714.485046 155.542267,717.529724 
	C155.009186,718.174011 154.342896,718.679199 153.706314,719.215881 
	C147.944061,724.074280 147.651535,726.701111 152.955627,732.090393 
	C155.434723,734.609314 157.398026,736.861694 156.471786,740.719604 
	C155.906082,743.075928 158.146347,743.566772 159.698822,744.500061 
	C163.441360,746.750183 167.253403,749.016174 166.800781,754.336670 
	C166.621918,756.439087 168.280899,756.670654 169.715668,757.059570 
	C173.018692,757.954773 174.619370,759.707581 173.145233,763.280334 
	C172.534897,764.759644 172.447922,766.249878 174.429245,766.844727 
	C174.987289,766.614990 175.230087,766.190796 175.406296,765.718445 
	C176.064133,763.954956 175.823502,761.255676 178.645126,761.452515 
	C181.400925,761.644714 181.619965,764.231140 182.109268,766.296570 
	C182.953934,769.862000 181.710052,773.486938 181.912628,778.500671 
	C187.726089,772.391541 192.516632,774.368652 197.125885,778.445557 
	C197.358704,778.651550 197.748566,778.668823 198.053177,778.801147 
	C200.969360,780.068237 204.094131,780.657166 207.126587,781.532166 
	C210.974350,782.642395 208.406433,787.294128 211.566559,789.151245 
	C212.458649,791.149414 212.470108,792.786011 211.284424,794.690552 
	C208.408096,795.188232 205.694962,796.774292 204.384430,792.443481 
	C203.674362,790.096924 201.812317,791.380615 201.038910,793.658203 
	C199.877747,795.360901 198.218109,795.487244 196.338898,795.943420 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M171.927002,814.033325 
	C175.780411,814.369568 178.345901,817.307800 182.706924,816.517456 
	C185.686600,815.977417 185.504868,819.757141 183.507843,821.942932 
	C181.355301,824.298889 180.439819,826.636902 182.889526,829.296875 
	C183.656342,830.129395 184.447495,831.178833 185.802597,830.378906 
	C186.764709,829.810913 187.170807,828.784302 186.743713,827.818542 
	C185.806656,825.699585 185.919739,823.799622 188.036346,822.706665 
	C190.383957,821.494568 191.050385,823.897217 192.191299,825.132629 
	C193.597015,826.654663 194.930481,825.267883 196.652069,825.041138 
	C198.801102,825.377258 200.679932,826.321655 202.347366,824.020935 
	C205.529099,822.011475 205.223480,817.842285 208.607025,816.045044 
	C211.535812,819.036133 210.744232,822.770203 210.242355,825.993530 
	C209.291275,832.101807 209.758621,838.216919 209.120331,844.727783 
	C206.749908,849.335022 202.216003,847.813843 198.515091,849.385620 
	C197.125183,850.173035 196.328110,851.071838 195.241760,852.176636 
	C191.173737,849.256104 187.294785,847.031555 181.925125,848.286987 
	C180.409210,848.641357 178.391632,846.252075 178.627823,844.008850 
	C178.885834,841.558167 181.003952,841.136353 183.296478,840.988281 
	C181.522339,840.908203 179.957382,840.706238 178.394272,840.479492 
	C172.130814,839.571228 171.467224,838.182617 174.738342,832.725403 
	C176.655045,829.527710 175.660416,827.423523 172.009827,825.994751 
	C170.732803,825.201721 169.784790,824.585388 168.750473,824.070679 
	C165.286102,822.347046 164.296585,819.888977 167.018097,816.284912 
	C167.433121,815.850281 167.613922,815.694763 168.099136,815.362915 
	C169.446106,814.754822 170.515869,814.403076 171.927002,814.033325 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M86.000389,470.333313 
	C88.588997,465.215698 91.252426,460.827667 88.346809,454.754150 
	C86.178703,450.222229 89.594574,445.214111 89.784698,440.313721 
	C89.989655,435.031494 90.712524,429.770264 90.954720,424.488220 
	C91.127350,420.723419 90.495277,417.069427 94.669655,414.528503 
	C97.020752,413.097412 96.468933,409.926819 95.238922,407.672546 
	C92.831978,403.261292 94.101707,399.565338 97.241676,396.391388 
	C99.603134,394.004395 100.511459,391.748383 99.379387,388.396027 
	C98.169411,384.813019 98.889084,381.455566 102.455185,379.300415 
	C103.840912,378.462921 104.525047,377.198486 104.960281,375.354004 
	C103.900398,373.696442 103.650711,372.285828 104.729065,370.370361 
	C108.695015,368.551697 106.803322,367.178650 104.826324,366.128082 
	C103.636223,365.495697 102.826935,364.858612 103.215530,363.509338 
	C103.366043,362.986725 103.970139,362.386444 104.493919,362.220673 
	C105.800995,361.806976 106.584511,362.653046 107.372475,363.628357 
	C108.594063,365.140411 109.483467,366.796967 108.844719,368.672546 
	C107.213806,373.461365 106.919090,378.734863 103.732697,382.970978 
	C103.442932,383.356171 103.094315,383.896179 103.150642,384.313293 
	C104.316711,392.948151 98.859627,400.190918 98.131996,408.410522 
	C97.964333,410.304474 97.296867,412.581360 100.325058,413.074310 
	C101.213463,413.218964 101.880569,413.732269 101.688911,414.767853 
	C101.498230,415.798157 100.527504,415.712067 99.792686,416.036835 
	C93.836884,418.669189 93.015129,420.164032 94.318047,426.344086 
	C94.628029,427.814392 95.350372,429.248718 94.573540,430.692749 
	C90.349457,438.544769 91.761810,446.967804 92.004410,455.275177 
	C92.014122,455.607788 91.901413,456.105896 92.074738,456.248413 
	C98.824646,461.798798 93.188934,470.445099 97.228706,476.771729 
	C98.782509,479.205139 96.469131,479.986481 94.484055,479.950775 
	C90.817078,479.884796 91.057289,481.181366 92.761810,483.678467 
	C95.047592,487.027008 95.912323,490.336212 93.070343,494.208923 
	C90.919235,497.140198 91.950127,500.954224 92.321671,504.413055 
	C92.426971,505.393341 92.960907,506.358276 92.924690,507.316101 
	C92.866577,508.852692 93.849991,511.115448 91.705032,511.650421 
	C89.590103,512.177917 88.301865,510.203278 87.846039,508.472107 
	C86.711685,504.164001 86.225861,499.673370 84.935066,495.421295 
	C83.442299,490.503967 85.858353,485.664398 84.546539,480.664917 
	C83.908348,478.232727 88.348747,475.926086 86.267471,472.250061 
	C86.000458,471.555542 86.000313,471.111115 86.000389,470.333313 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M127.221909,255.181229 
	C127.023743,254.538071 127.018242,254.100693 127.010086,253.335266 
	C127.858574,252.228226 128.409088,251.156158 129.397263,250.514847 
	C131.233932,249.322891 131.490692,248.321198 129.479889,246.845139 
	C126.551849,244.695770 125.543694,242.186478 129.524780,240.016006 
	C131.171524,239.118210 130.839874,237.947037 130.425995,236.660233 
	C129.330200,233.253357 130.977890,231.377625 133.544296,229.205353 
	C137.395065,225.945923 141.734772,223.864120 146.555969,221.766754 
	C148.795029,221.122620 149.794876,222.075577 149.850128,223.871780 
	C149.938522,226.745239 147.798477,228.964432 147.524460,231.909058 
	C147.176910,235.643845 143.449753,235.081406 140.806183,235.685181 
	C140.475662,235.760666 140.160675,235.845688 139.679306,236.154022 
	C140.563202,239.768799 145.460449,240.571045 145.788757,244.902863 
	C145.401642,246.581070 144.490005,247.371216 142.946808,248.012146 
	C140.019211,248.206772 138.369705,249.644775 136.999557,251.710220 
	C133.407928,257.124481 130.791992,263.049011 127.864311,268.811676 
	C127.554245,269.421997 127.265137,270.109802 127.475609,270.725037 
	C128.855164,274.757782 126.127762,276.481049 123.355888,278.172455 
	C119.856422,280.307800 118.150841,283.206787 119.162773,287.693054 
	C118.838264,288.559784 118.662025,289.111145 118.248306,289.837952 
	C116.427437,291.567017 114.116447,292.594055 114.093605,295.645477 
	C113.958565,296.163879 113.894402,296.365112 113.713089,296.851440 
	C113.005501,298.056824 113.187805,298.995972 113.577011,300.247070 
	C113.613579,301.223785 113.368958,301.790527 112.601318,302.412476 
	C111.114677,302.884033 109.815186,303.067932 109.372139,304.916382 
	C109.042786,305.341705 108.875778,305.482666 108.419212,305.776123 
	C106.790871,306.454529 105.163055,306.479431 104.331871,308.367828 
	C102.879623,311.137451 102.857430,313.676239 103.832413,316.609802 
	C103.859314,317.651855 103.672508,318.297241 102.997162,319.084778 
	C101.867844,319.784973 100.613701,319.773956 100.334755,321.420166 
	C100.189484,322.151794 100.084305,322.566162 99.844482,323.277954 
	C99.614090,323.762238 99.499466,323.944305 99.146233,324.341522 
	C98.547440,324.781555 98.176590,324.987213 97.521149,325.318115 
	C96.235573,326.151520 96.356865,327.307159 95.979439,328.634125 
	C95.467514,329.934143 94.781570,330.556854 93.339851,330.683777 
	C88.679901,326.008453 89.358894,313.071716 94.367149,309.531219 
	C96.597679,307.954376 98.025581,307.060181 95.918373,304.276611 
	C94.277634,302.109222 95.598801,300.193237 97.558975,298.829132 
	C102.703804,295.248901 106.675003,290.209991 110.775574,285.780121 
	C114.614784,281.632599 118.140526,276.203552 120.758636,270.283203 
	C120.996689,269.997559 121.006981,269.505524 121.010803,269.259460 
	C122.818459,266.802368 124.899628,264.825195 126.757591,262.228485 
	C127.647720,260.565796 129.208710,259.561432 128.968170,257.370605 
	C128.456314,256.471039 127.936852,255.927673 127.221909,255.181229 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M92.000168,589.375000 
	C93.681686,588.930054 94.679428,589.393005 95.099426,591.352966 
	C95.967705,595.404785 98.310425,598.629883 101.810448,600.881226 
	C104.251282,602.451294 104.736229,604.675720 104.385414,607.294556 
	C103.395187,614.686584 105.185493,621.106262 108.120461,628.202637 
	C111.768211,637.022522 117.283333,645.024536 118.692467,655.300842 
	C119.866730,663.864380 122.701614,673.457336 129.847443,680.217957 
	C130.546127,680.879028 132.394730,681.365845 130.979828,682.808533 
	C130.064072,683.742249 128.589157,684.503418 127.363373,683.508728 
	C124.741898,681.381531 121.761848,679.839905 120.816910,675.836121 
	C119.564674,670.530273 119.223572,664.689514 114.315804,660.734619 
	C112.034813,658.896362 112.926865,656.229248 114.055229,653.840149 
	C115.872849,649.991821 114.949379,647.777771 110.835373,645.617432 
	C104.992554,642.549255 101.539864,637.050171 101.148499,630.279785 
	C100.959694,627.013489 100.186333,624.121338 98.723755,621.269775 
	C98.219193,620.286011 97.788811,618.905945 98.080338,617.933472 
	C99.427322,613.440308 97.716774,609.557678 95.762779,605.831848 
	C93.548256,601.609192 91.430710,597.477478 92.268318,592.250488 
	C92.000214,591.250000 92.000114,590.500000 92.000168,589.375000 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M168.081299,815.695312 
	C164.452988,820.894348 168.155243,822.603882 171.837463,824.696655 
	C169.808716,827.140076 164.741760,826.493225 162.840210,823.218933 
	C161.109085,820.238037 158.890533,818.099060 156.199600,816.098877 
	C153.875870,814.371521 153.364990,811.960876 155.928024,809.835510 
	C158.433899,807.757446 157.793320,806.432800 155.603226,804.358398 
	C152.742386,801.648743 148.051529,800.304016 147.942917,794.899719 
	C148.291901,792.937683 148.232147,791.340088 147.827911,789.406006 
	C148.160675,781.668640 148.160675,781.668640 140.090958,780.894165 
	C138.729355,780.831482 137.827240,780.507812 136.819611,779.550232 
	C135.787399,777.788635 136.092743,776.349731 136.905899,774.597839 
	C137.233185,774.079712 137.382080,773.886169 137.800064,773.449524 
	C144.439545,769.570007 144.220154,767.964539 136.407410,762.874268 
	C135.597290,762.110474 135.233841,761.474121 134.866425,760.415405 
	C134.726624,756.220886 130.490219,753.780640 131.485443,749.330322 
	C134.311218,747.411926 135.751678,749.718201 137.831833,751.072327 
	C138.896210,752.206238 139.426682,753.253906 139.961517,754.680603 
	C139.777695,757.098938 137.691635,760.123108 139.584579,761.045532 
	C143.361954,762.886414 145.404831,767.752380 150.296860,767.343262 
	C153.861343,767.045227 154.589600,768.638550 153.197968,771.441711 
	C151.911697,774.032715 152.308334,776.410767 152.688782,779.128906 
	C153.139679,782.350281 154.434296,785.531982 152.379074,789.155029 
	C150.566315,792.350647 153.972610,796.496765 157.971924,796.967224 
	C159.503876,797.147339 161.527069,797.108337 161.662384,798.797974 
	C162.090576,804.144836 165.791779,807.467834 168.846786,811.617065 
	C169.134293,812.090698 169.279861,812.255859 169.400024,812.750549 
	C169.068909,813.900208 168.437057,814.547546 168.081299,815.695312 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M119.351326,287.970093 
	C116.191277,284.924835 117.138092,282.148102 119.496292,279.260376 
	C121.906807,276.308624 128.973969,276.408966 125.652512,269.404968 
	C125.025108,268.081970 128.433502,265.923645 129.282501,263.341919 
	C131.019241,258.060669 134.232101,253.388947 137.474518,248.820633 
	C138.476562,247.408829 139.630005,246.868500 141.624969,247.009247 
	C142.692474,251.070221 141.425232,254.744110 139.526886,258.616333 
	C139.092804,259.047028 138.906601,259.204010 138.414871,259.554810 
	C132.426468,260.650208 133.997070,265.357971 133.174515,269.192017 
	C132.915451,269.688660 132.797562,269.877258 132.472198,270.320923 
	C130.397629,271.625610 131.015793,273.477386 130.723175,275.382202 
	C130.518890,275.892120 130.407196,276.084930 130.058990,276.516815 
	C127.411110,277.946899 126.651497,280.388611 125.235291,282.755676 
	C123.600243,285.043060 122.108765,286.988434 119.351326,287.970093 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M124.271591,745.235107 
	C123.491325,744.135498 122.985474,743.265259 122.234894,742.205505 
	C121.638054,741.659790 121.142662,740.986389 121.337524,740.785400 
	C125.699425,736.286987 121.394897,734.256836 118.827927,731.711060 
	C118.488144,731.374146 118.357773,730.853516 118.130417,730.058472 
	C118.035942,729.514404 118.014221,729.296387 117.979652,728.756714 
	C118.032913,726.760254 117.085831,725.773193 115.398651,724.920349 
	C114.497604,724.364929 113.970787,723.888611 113.299408,723.022583 
	C111.267929,716.746582 111.859467,715.759644 118.623138,714.056396 
	C120.080475,714.159790 121.149712,714.587952 120.784348,715.846069 
	C118.755844,722.830750 120.931725,727.931946 127.386047,731.262695 
	C128.314880,731.742065 129.070541,732.654663 128.888596,733.670715 
	C128.312836,736.885986 130.690903,738.828064 131.958115,741.631592 
	C131.259247,743.133057 129.857376,743.373718 128.665894,744.465942 
	C127.863434,745.107056 127.268013,745.444214 126.263885,745.738403 
	C125.432098,745.795471 124.977325,745.677551 124.271591,745.235107 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M207.713181,846.326538 
	C207.372818,836.269531 209.252853,826.633545 208.987274,816.455933 
	C209.103134,810.863647 209.999725,805.837708 210.979080,800.407837 
	C211.440918,799.639465 211.954987,799.502136 212.888000,799.782349 
	C214.280487,802.362854 213.192337,804.806152 213.457306,807.613037 
	C213.541946,809.899414 213.490540,811.776917 213.188843,814.042725 
	C212.975510,815.214783 212.686584,815.955750 212.500992,817.121765 
	C212.535248,819.094666 212.477066,820.674805 212.437012,822.657471 
	C212.478821,824.149780 212.378464,825.229858 212.147827,826.710388 
	C211.283203,829.772339 211.682861,832.518433 211.472992,835.629883 
	C211.601959,839.321655 211.487442,842.622803 212.287155,846.263794 
	C212.551804,850.181274 212.463745,853.711670 213.027466,857.602966 
	C211.889069,859.320190 210.150284,859.246155 208.100037,859.695740 
	C206.419540,857.732483 206.637100,855.795227 206.558533,853.502258 
	C206.576828,852.552246 206.580231,852.008423 206.576202,851.053223 
	C206.516693,849.235474 206.645584,847.869934 207.713181,846.326538 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M107.757294,305.270813 
	C108.014061,305.042328 108.510674,305.029968 108.758492,305.013245 
	C109.786964,304.027435 110.530457,303.021606 111.870232,302.174408 
	C112.399399,301.521057 112.677818,301.051239 112.972580,300.296448 
	C112.481003,299.304291 112.283165,298.536133 112.764267,297.339539 
	C112.998474,296.998688 113.000000,297.000000 112.999084,296.999390 
	C114.469116,295.528259 114.048721,292.192413 117.688591,292.776917 
	C116.309479,298.858856 115.952118,305.446289 109.225609,308.506592 
	C108.231979,308.958710 107.168839,309.881989 107.349876,311.232727 
	C108.444809,319.401978 101.579330,325.056885 100.629723,332.579163 
	C100.579849,332.974182 99.978554,333.299622 99.318848,333.829163 
	C95.836578,333.985596 95.871498,331.788727 95.995163,329.164246 
	C96.042564,328.528992 96.064995,328.277954 96.171585,327.662964 
	C96.719566,326.138519 97.714386,325.404358 98.742889,324.245331 
	C98.985237,323.988007 98.999695,324.000000 98.993912,323.993103 
	C99.749138,323.392059 99.982986,322.551239 100.168655,321.320190 
	C100.517403,320.353302 100.900002,319.795837 101.698349,319.152161 
	C102.315575,318.520966 102.634705,318.056488 102.979492,317.298035 
	C104.624855,314.395081 103.194244,311.375397 104.236542,308.352386 
	C104.984627,306.672302 106.324463,306.187805 107.757294,305.270813 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M213.211777,800.216064 
	C212.461304,800.016235 212.039536,800.012573 211.301208,800.001282 
	C209.150345,798.586609 210.742462,797.125183 210.945541,795.339478 
	C210.979187,793.278381 210.983582,791.564148 210.980621,789.421265 
	C209.801376,784.924683 212.056625,781.384460 212.828156,777.217712 
	C214.333359,772.887939 215.016785,768.566162 219.020798,765.985291 
	C220.769638,768.191406 220.098907,770.323242 219.745804,772.430115 
	C218.841873,777.823364 217.002594,783.017761 216.266266,788.847412 
	C215.446976,791.409119 215.431564,793.643372 215.669159,796.277832 
	C215.575195,797.690186 215.266968,798.654846 214.503647,799.846436 
	C214.017288,800.230103 213.778885,800.321167 213.211777,800.216064 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M75.324089,405.222595 
	C74.650917,400.914886 76.036949,396.826019 75.042725,392.717072 
	C74.897148,392.115448 74.927032,391.204926 75.284561,390.787750 
	C81.288673,383.782318 81.028435,374.447296 84.679955,366.233704 
	C85.133736,365.863312 85.307243,365.733185 85.737686,365.802612 
	C85.702858,372.595551 81.892853,378.455566 82.008240,385.565186 
	C82.422348,390.115295 78.115982,392.407349 78.010483,396.662292 
	C77.673325,399.391235 77.337662,401.783661 77.000992,404.588043 
	C76.598412,405.317780 76.144791,405.471893 75.324089,405.222595 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M86.257217,365.769409 
	C85.822365,366.001343 85.650108,366.000488 85.219040,365.996521 
	C84.963005,365.557068 84.965782,365.120697 84.971840,364.356995 
	C84.641777,363.630737 84.351822,363.202454 83.999825,362.369598 
	C83.795341,361.212952 83.884460,360.447906 84.227951,359.337097 
	C87.746513,354.530151 89.627281,348.817322 95.487518,346.023071 
	C97.547707,345.493225 99.227737,344.930847 101.390602,345.067444 
	C101.990501,345.255188 102.216667,345.370178 102.722031,345.743805 
	C97.270912,349.289490 94.172089,355.113861 89.743858,359.654053 
	C88.049515,361.391235 87.025833,363.263519 86.257217,365.769409 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M96.234398,346.756104 
	C90.631462,349.272522 89.421448,355.162964 85.370743,358.952637 
	C83.074020,356.363342 84.739418,354.137238 85.971657,351.946381 
	C86.990936,350.134064 88.625717,348.708008 89.640717,346.451569 
	C90.443092,345.472351 91.199898,344.947205 92.009323,344.009125 
	C92.348839,343.580292 92.481743,343.406403 92.809586,342.970459 
	C93.936134,340.872803 93.618546,339.358337 92.165108,337.830261 
	C90.403152,335.977783 89.294472,333.799042 90.765701,330.885742 
	C91.448776,330.460175 91.899124,330.410553 92.695595,330.610138 
	C94.445419,332.394165 95.364716,334.559448 97.806175,335.728455 
	C97.568291,338.136047 98.690575,339.715454 99.959549,341.618774 
	C99.084633,343.676575 97.632576,344.990723 96.234398,346.756104 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M112.687592,696.764160 
	C113.592407,697.443726 113.966522,698.122070 113.529449,698.701660 
	C110.304382,702.978149 112.413109,705.733948 116.115555,708.053406 
	C116.994003,708.603760 117.713806,709.237976 117.955078,710.656616 
	C114.778572,714.089539 111.874153,713.067627 108.770493,710.106934 
	C107.577263,709.227051 106.819702,708.472107 106.119537,707.140015 
	C105.357536,703.999451 105.402153,701.302246 107.015030,698.405579 
	C108.002312,697.228760 108.997208,696.811035 110.426407,696.382019 
	C111.353966,696.244995 111.872414,696.322571 112.687592,696.764160 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M108.655594,709.265503 
	C111.505699,710.637573 113.848732,712.961060 117.614685,711.011169 
	C118.246857,711.806763 118.498459,712.608704 118.879395,713.702881 
	C118.858841,714.816467 118.608505,715.813782 117.693260,715.801086 
	C112.535408,715.729736 112.747131,718.651245 113.996490,722.602783 
	C107.817238,722.191040 105.255875,716.127930 108.655594,709.265503 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M138.357788,752.170532 
	C135.628143,752.117493 134.796127,749.440979 132.347412,749.032471 
	C131.836121,748.814636 131.667969,748.636658 131.375092,748.112549 
	C131.110199,745.640564 132.194580,744.042358 133.748718,742.272644 
	C136.278442,741.668762 137.241257,743.159607 137.966248,744.920776 
	C138.954956,747.322510 141.606918,748.181396 142.953369,750.632629 
	C141.881577,752.365723 140.362183,752.552368 138.357788,752.170532 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M70.356598,440.940125 
	C70.002556,440.553070 69.997063,440.104065 69.981606,439.317993 
	C70.315903,434.291595 70.660164,429.602173 71.014282,424.466370 
	C71.270210,423.671021 71.535767,423.337555 72.416977,423.016052 
	C73.379211,426.883057 72.305099,430.761505 73.815750,434.686096 
	C74.795258,437.230896 72.820557,439.335602 70.356598,440.940125 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M68.698402,460.327942 
	C68.943855,459.222687 68.953087,458.495087 68.957108,457.403687 
	C67.297569,455.072144 67.390282,452.958221 68.692184,450.368408 
	C68.957474,449.971497 68.994514,449.981079 69.007675,449.995667 
	C69.480934,450.178253 70.266380,450.246552 70.352959,450.529022 
	C71.979393,455.836151 72.412682,461.180573 70.290825,466.771545 
	C69.834045,467.127319 69.659836,467.255829 69.221962,467.194794 
	C68.954071,466.266815 68.949860,465.528351 68.938591,464.420685 
	C68.168953,463.021240 67.930115,461.917511 68.698402,460.327942 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M111.000763,661.998779 
	C112.045731,665.415649 111.282455,668.365234 108.641190,670.774475 
	C107.331375,671.969299 106.110207,671.975952 106.076660,669.903442 
	C106.022987,666.586853 106.308754,663.290894 110.625465,662.007812 
	C111.001221,661.997559 111.000000,662.000000 111.000763,661.998779 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M98.249680,335.826538 
	C94.945541,336.242371 92.684814,335.529572 92.988914,331.369812 
	C92.813759,330.806427 92.644737,330.641815 92.736816,330.238312 
	C93.902100,329.921051 94.677925,329.661896 94.960800,328.322632 
	C95.390671,327.623901 95.847839,327.475800 96.689148,327.779602 
	C97.180855,329.944061 96.372444,332.128479 98.680969,333.819916 
	C98.831642,334.551605 98.659554,335.102325 98.249680,335.826538 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M77.984848,490.233734 
	C78.801651,490.786896 79.530319,491.199127 79.638939,491.736664 
	C79.982803,493.438293 80.889801,495.568237 78.840141,496.599121 
	C77.253487,497.397125 76.724472,495.400787 76.013054,494.435516 
	C74.457039,492.324127 74.824615,490.736481 77.984848,490.233734 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M202.367813,823.547302 
	C201.896835,827.528870 200.708267,829.746765 197.220062,825.247314 
	C197.960770,822.185669 199.368988,820.724609 202.367813,823.547302 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M78.278229,396.791290 
	C75.392082,392.175842 80.964043,390.254456 81.848763,386.306091 
	C83.511986,386.215851 84.073463,386.785553 83.162125,388.320007 
	C81.551636,391.031647 80.078239,393.824768 78.278229,396.791290 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M133.663208,742.003418 
	C134.462784,744.165710 132.630585,745.494995 132.041611,747.644653 
	C128.877090,749.164368 128.401978,746.884460 127.897583,744.383057 
	C128.203140,741.891785 129.770660,742.013977 131.629288,741.997559 
	C132.446213,741.997375 132.888748,741.999634 133.663208,742.003418 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M138.007187,752.355713 
	C139.326202,751.261047 140.732239,750.855896 142.639648,750.974243 
	C144.023331,751.544067 145.102158,752.247437 144.531754,753.558838 
	C143.835297,755.159790 142.220078,755.016357 140.400482,755.021606 
	C138.600647,754.910339 137.981918,754.093018 138.007187,752.355713 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M96.391396,346.985474 
	C95.849983,344.497772 96.924973,342.838715 99.677917,342.019226 
	C100.470360,341.976868 100.906517,341.985474 101.670151,341.998871 
	C102.845108,342.988464 102.937981,344.079010 102.223068,345.620972 
	C100.253891,346.321960 98.514153,346.652557 96.391396,346.985474 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M117.999207,292.624390 
	C115.122894,292.696808 115.552643,296.070923 113.337929,296.940369 
	C112.927696,293.768219 112.766327,290.482361 117.627350,290.015625 
	C118.007500,290.759369 118.004181,291.505310 117.999207,292.624390 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M196.160080,796.299744 
	C196.428741,793.281494 198.587601,794.205139 200.604248,794.000000 
	C201.117081,797.868713 200.029022,798.473328 196.160080,796.299744 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M125.336533,283.002075 
	C124.960846,279.783905 125.585724,276.971863 129.630493,276.009705 
	C131.529282,279.066589 128.526855,280.371277 127.248657,282.721375 
	C126.999695,283.000000 127.000000,283.000000 126.999847,283.000000 
	C126.556175,283.000397 126.112663,283.000793 125.336533,283.002075 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M160.548950,766.057373 
	C160.673721,764.620789 160.111084,762.525024 162.587250,763.246155 
	C163.796005,763.598206 165.253769,764.267212 164.810242,765.921936 
	C164.402039,767.444824 163.424652,768.557373 161.269287,767.334106 
	C160.937790,766.858398 160.859985,766.538879 160.548950,766.057373 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M111.284409,696.660278 
	C110.465981,697.813171 109.612053,697.990967 108.342949,697.977722 
	C107.516624,697.882324 107.095406,697.658752 106.546860,697.008789 
	C106.147659,695.963501 106.193253,695.258484 106.813416,694.324097 
	C107.507721,693.975037 107.949059,693.885559 108.700958,693.660034 
	C109.475960,693.639404 109.940392,693.754883 110.761490,693.913818 
	C111.604034,694.682007 111.776543,695.457764 111.284409,696.660278 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M74.215469,416.191193 
	C73.974594,415.256378 73.996208,414.511292 74.032784,413.393738 
	C74.550362,412.463318 75.078873,411.929718 75.785019,411.176666 
	C75.978233,410.971863 75.983269,410.485199 75.995316,410.242126 
	C80.229858,412.185120 77.206375,413.882904 75.313263,415.838928 
	C74.827209,416.126953 74.652588,416.253906 74.215469,416.191193 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M72.750740,421.297363 
	C72.966080,420.230896 72.975014,419.486420 72.989952,418.369751 
	C73.382538,417.620697 73.842484,417.474213 74.685051,417.778412 
	C76.777336,420.210571 76.267609,421.826691 73.365349,422.962585 
	C73.013115,423.012482 73.002098,423.001953 73.000336,423.000366 
	C72.605995,422.618378 72.454597,422.158569 72.750740,421.297363 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M90.194580,518.687744 
	C89.219131,518.993347 88.478416,519.004517 87.367264,519.019409 
	C86.727654,518.518433 86.458504,518.013611 86.063492,517.250488 
	C86.983711,515.191650 88.186951,514.482422 89.926674,516.716675 
	C90.416862,517.404968 90.533600,517.871521 90.194580,518.687744 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M163.334351,777.000000 
	C161.929321,776.887695 160.597916,777.237183 160.258972,775.710388 
	C160.179108,775.350708 160.589111,774.519470 160.758621,774.523682 
	C162.348953,774.562683 163.983643,774.630127 164.937500,776.657715 
	C164.555817,777.000000 164.111649,777.000000 163.334351,777.000000 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M168.594666,813.371338 
	C168.819748,812.827087 168.865128,812.649353 168.949615,812.198547 
	C170.214706,811.897522 171.165741,812.398621 172.051239,813.690308 
	C171.898102,815.255127 171.236588,816.164490 169.358856,816.014404 
	C168.433594,815.341858 168.217392,814.592834 168.594666,813.371338 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M66.298943,485.953064 
	C66.484146,484.470062 66.982491,482.954559 67.738312,481.219543 
	C68.866241,481.540497 68.979385,482.404297 68.985672,483.648804 
	C68.195732,484.628937 67.403908,485.274780 66.298943,485.953064 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M200.710358,812.153320 
	C201.494400,812.260681 201.988785,812.521362 202.740967,812.891052 
	C202.693405,814.968872 201.393387,815.481140 199.898544,814.569275 
	C198.657074,813.812012 199.752792,812.966492 200.710358,812.153320 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M170.722168,804.833984 
	C169.755905,805.985291 168.888214,806.152039 168.334579,804.878418 
	C168.189362,804.544189 168.384766,803.790039 168.674637,803.587036 
	C169.765808,802.822754 170.489029,803.270874 170.722168,804.833984 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M68.657791,467.170563 
	C69.133247,467.003967 69.308205,467.002625 69.745705,467.000061 
	C69.753250,468.595673 69.370026,470.118561 67.419373,470.999817 
	C66.933403,469.632538 67.021545,468.273743 68.657791,467.170563 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M75.039131,405.326355 
	C75.458069,405.007141 75.898521,405.004517 76.669487,405.000946 
	C78.231506,406.144440 78.353325,407.144775 76.299362,407.950928 
	C75.670799,407.212463 75.365723,406.427673 75.039131,405.326355 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M104.671112,356.965454 
	C105.382713,357.881134 105.973816,358.806671 104.937370,359.617462 
	C104.189835,360.202209 103.422386,360.053650 102.440712,359.180115 
	C102.486961,357.871338 102.932953,356.879059 104.671112,356.965454 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M102.245514,345.995026 
	C101.995735,344.928009 101.997841,343.864624 101.998787,342.402466 
	C104.484093,342.348267 104.075500,343.789612 103.138756,345.690674 
	C102.833275,346.001160 102.665337,345.999908 102.245514,345.995026 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M111.390701,697.011292 
	C111.065323,696.249634 111.062103,695.485352 111.088531,694.339111 
	C112.515404,694.124207 113.025513,694.998962 112.998199,696.653442 
	C112.573395,697.002869 112.143127,697.005737 111.390701,697.011292 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M80.627686,465.554260 
	C81.403419,465.335388 81.683380,465.607635 81.564590,466.209076 
	C81.539902,466.334167 81.362732,466.502686 81.236404,466.521637 
	C80.628571,466.612793 80.373375,466.317413 80.627686,465.554260 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M191.423431,818.955566 
	C191.809494,818.248352 192.198486,818.241882 192.542435,818.745361 
	C192.614609,818.851013 192.612839,819.095520 192.538162,819.198242 
	C192.179840,819.691040 191.790924,819.671692 191.423431,818.955566 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M74.996071,417.665649 
	C74.550285,417.999176 74.106277,417.999573 73.329117,417.998779 
	C73.159126,417.448212 73.322289,416.898895 73.719223,416.175537 
	C74.127785,416.000854 74.302589,416.000244 74.739609,415.999817 
	C75.000504,416.444183 74.999176,416.888336 74.996071,417.665649 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M62.385895,488.990845 
	C61.936188,487.402130 62.137501,485.999481 64.650909,486.028564 
	C64.920799,487.528290 64.249336,488.575836 62.385895,488.990845 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M106.740921,694.018555 
	C107.060066,694.787415 107.053741,695.510254 107.052689,696.595581 
	C104.859070,700.025024 104.962807,703.207825 106.790352,706.732666 
	C107.559761,707.507690 108.118515,708.016724 108.835625,708.765137 
	C106.382912,714.887817 109.499031,718.989624 113.656891,722.933228 
	C114.494644,723.257019 114.984123,723.523376 115.730072,723.899902 
	C117.060081,725.021912 117.621483,726.442749 118.851456,727.720154 
	C118.981750,728.006592 118.987587,728.502014 118.992020,728.749756 
	C117.915115,731.165649 119.379478,732.592773 120.509697,734.093384 
	C122.145630,736.265381 122.446922,738.443298 120.329582,740.848755 
	C116.679062,739.203674 111.316498,738.831055 114.785576,732.594971 
	C115.671059,731.003174 114.464409,728.914917 112.707932,727.968811 
	C109.506805,726.244751 106.322418,724.307739 102.888718,723.235718 
	C95.956017,721.071289 94.155716,717.351196 98.395462,711.554626 
	C100.794884,708.274048 101.714783,706.210205 96.980125,704.804443 
	C95.479385,704.358887 95.130493,703.108765 95.004150,701.676270 
	C94.584221,696.915283 92.563522,692.907593 88.980118,689.760803 
	C87.904884,688.816650 87.774200,687.644043 88.120186,686.610840 
	C89.896889,681.305054 90.600632,676.099854 88.203705,670.728088 
	C87.397377,668.921082 88.940804,667.262634 89.878960,665.301392 
	C91.028854,664.200317 91.852692,664.456421 92.781776,665.695435 
	C93.111839,666.313660 93.301048,666.572266 93.783966,666.886658 
	C95.475052,668.476746 95.523720,670.357788 95.025658,672.119263 
	C94.395859,674.346619 94.734734,676.359741 95.784363,678.693237 
	C96.119194,679.307007 96.309792,679.565430 96.793221,679.880859 
	C97.569275,685.011780 100.351135,688.856567 103.794724,692.728760 
	C104.807068,693.321289 105.611259,693.646973 106.740921,694.018555 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M103.992783,692.593994 
	C103.230415,692.401489 102.501923,691.736511 101.678001,691.225403 
	C97.768486,688.800171 94.986855,685.896973 97.005295,680.408203 
	C100.615501,683.327637 103.639488,686.993591 103.992783,692.593994 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M96.004898,678.574829 
	C90.920380,676.071594 94.762535,671.542542 93.998001,667.422058 
	C97.194183,667.664001 98.632378,669.238831 96.784225,672.459839 
	C95.749229,674.263672 96.055664,676.230896 96.004898,678.574829 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M92.995193,665.646973 
	C92.221428,665.678284 91.441635,665.358948 90.347473,665.004395 
	C90.201485,664.424500 90.369865,663.879883 90.778061,663.167847 
	C92.456223,663.094971 93.012383,663.947021 92.995193,665.646973 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M68.608902,450.043518 
	C68.966766,452.032501 68.976059,454.093475 68.968620,456.597168 
	C64.216637,455.181671 65.573326,452.715027 68.608902,450.043518 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M68.600670,460.032898 
	C68.949829,461.043823 68.965050,462.137360 68.955902,463.641205 
	C66.350616,463.138031 66.384956,461.782837 68.600670,460.032898 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M73.283081,367.798218 
	C73.170677,363.764252 73.038811,359.487152 73.611305,355.306458 
	C73.899750,353.200073 76.620735,353.135071 78.663254,352.145996 
	C84.188202,353.123322 82.956131,357.543213 83.686508,361.390930 
	C83.800941,362.330475 83.706314,362.851624 83.244293,363.673645 
	C81.496796,366.833099 80.064392,369.578186 76.293556,366.335114 
	C75.266418,365.451721 74.258095,366.675293 73.283081,367.798218 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M72.620392,421.043701 
	C72.970802,421.425018 72.984459,421.874664 72.998352,422.661560 
	C72.508621,423.261169 72.018669,423.523529 71.276428,423.902954 
	C71.059891,422.884308 70.835350,421.635895 72.620392,421.043701 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M83.336731,363.999664 
	C83.006729,363.554108 83.004684,363.109772 83.000053,362.332092 
	C83.391701,361.686432 83.840187,361.539764 84.651260,361.755859 
	C84.970818,362.414276 84.981911,362.875244 84.984055,363.682953 
	C84.538307,364.020081 84.101501,364.010468 83.336731,363.999664 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M75.662460,411.026154 
	C75.926407,411.875824 75.688240,412.654785 74.361511,412.967621 
	C74.083687,412.108612 74.331024,411.337433 75.662460,411.026154 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M84.964844,361.586456 
	C84.523933,361.966461 84.088135,361.979614 83.324898,361.995789 
	C81.039879,359.102356 82.904877,354.831665 79.276688,352.216675 
	C79.465042,350.964844 80.190498,350.241455 81.679932,350.029724 
	C82.390427,349.558838 82.843803,349.212494 83.711426,349.027710 
	C85.703072,348.980072 87.505196,348.944855 87.782310,346.745850 
	C87.998932,345.026947 86.352203,344.694000 85.237068,344.141174 
	C83.576447,343.317902 82.008781,342.468536 81.141594,340.313477 
	C80.489746,339.314667 79.926193,338.403229 80.272079,337.713562 
	C83.292747,331.690857 81.481613,324.940247 83.118599,318.370331 
	C83.004585,316.834808 82.966537,315.670685 83.009575,314.509583 
	C83.230286,308.554993 84.879692,307.428864 91.240814,310.337280 
	C85.026764,304.193665 93.392136,301.278473 92.772934,296.275208 
	C92.595490,294.841431 93.356369,292.641357 92.958191,290.767700 
	C91.698570,284.840454 93.881683,282.538910 99.772583,282.644867 
	C102.690033,282.697327 105.423866,281.943237 108.239273,283.728821 
	C109.748352,284.685913 111.331085,283.512848 111.988464,281.582550 
	C113.579269,276.911407 116.774010,273.371094 120.661224,270.051788 
	C121.408119,273.023468 120.533661,275.475800 118.090683,277.464203 
	C115.867264,279.273926 114.242004,281.499054 113.892570,284.549164 
	C113.439224,288.506500 109.330986,289.016785 106.992401,291.117554 
	C103.459579,294.291077 101.782196,299.227844 96.809181,301.126251 
	C95.093079,301.781372 96.530891,303.587952 97.874939,304.539948 
	C100.152382,306.153076 100.535660,309.057831 97.991791,309.513184 
	C90.613884,310.833862 92.384254,316.791229 91.512840,321.156769 
	C90.994911,323.751465 92.077034,326.665558 92.718842,329.717712 
	C92.997917,329.999420 92.989723,330.485596 92.986252,330.728333 
	C92.541557,330.976746 92.100342,330.982422 91.328026,330.992065 
	C88.597252,333.287140 88.283394,335.389526 91.305244,337.517426 
	C93.316154,338.933472 94.705154,340.786804 93.224823,343.736694 
	C92.992226,343.992554 93.000000,344.000000 92.996170,343.996399 
	C92.466591,344.532410 91.930397,345.061707 91.209511,345.805603 
	C89.560135,350.353912 85.252617,353.304932 85.027626,358.633240 
	C85.010918,359.750763 84.990440,360.485199 84.964844,361.586456 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M82.847351,318.296509 
	C84.153976,324.059113 85.080612,330.097260 82.074280,335.960510 
	C81.557678,336.968048 81.359856,338.139038 81.007233,339.617249 
	C76.292854,339.121246 77.600266,335.463501 77.253479,332.224854 
	C75.959076,330.696564 76.476395,329.550873 77.683617,328.223145 
	C79.877548,327.795105 81.749878,326.483276 80.652565,324.977081 
	C78.100433,321.473969 80.609077,320.251556 82.847351,318.296509 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M77.979889,327.682617 
	C77.698784,329.106750 77.384094,330.208618 77.032410,331.654633 
	C76.557961,332.001648 76.120491,332.004517 75.355492,332.014526 
	C76.172035,329.836639 72.099625,326.722015 76.611916,325.023560 
	C77.287491,325.767883 77.616898,326.564087 77.979889,327.682617 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M83.824165,348.781128 
	C83.530594,349.317566 83.061180,349.635132 82.295883,349.976349 
	C82.189705,349.108337 82.156876,347.961517 83.824165,348.781128 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M126.640083,262.026764 
	C127.072098,265.705719 124.419525,267.340363 121.360550,268.971222 
	C121.010391,267.943634 121.006157,266.873840 121.002495,265.403259 
	C121.585213,264.509338 122.167351,264.016205 122.872307,263.258545 
	C124.095863,262.684845 125.196609,262.375641 126.640083,262.026764 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M887.677246,161.729416 
	C887.796082,162.484421 887.836426,162.938583 887.897766,163.734131 
	C888.079590,164.201706 888.240356,164.327866 888.357971,164.753876 
	C887.982666,165.536102 887.528625,165.910385 887.079651,166.628113 
	C887.146973,167.533020 887.619934,167.937759 888.273193,168.640167 
	C888.626343,169.107117 888.749023,169.311646 889.014648,169.850937 
	C890.089050,173.802704 890.918701,177.376785 885.335571,178.320770 
	C884.145569,177.539368 883.781860,176.548981 883.898071,175.562317 
	C884.377502,171.492447 882.702148,168.997543 878.887451,167.466003 
	C876.612915,166.552780 875.475830,164.395599 875.288940,161.867767 
	C874.940063,157.150238 872.448547,154.103348 868.020691,152.954681 
	C860.868286,151.099197 857.278381,142.944489 861.156250,136.768677 
	C862.434937,134.732147 862.645691,132.826462 862.567871,130.316071 
	C863.348755,129.091934 864.361694,129.058487 865.675476,128.796310 
	C867.168640,128.684555 867.817871,129.393341 868.522400,130.578674 
	C868.570984,134.320923 868.570984,134.320923 865.824890,138.738098 
	C869.076904,137.918396 871.354736,139.832733 873.790527,141.161346 
	C876.967102,142.894058 877.198914,145.641296 876.216614,148.793304 
	C875.819336,150.068207 876.542114,150.986420 877.975647,151.738876 
	C878.474060,152.018799 878.664124,152.146271 879.101135,152.510773 
	C880.021790,153.665390 880.200562,154.780304 880.690369,156.103683 
	C881.221191,157.270050 881.620056,158.166885 883.113281,158.179886 
	C884.259460,158.230026 885.035889,158.479965 885.981628,159.153854 
	C886.809082,159.961304 887.270142,160.651093 887.677246,161.729416 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M887.643311,165.207001 
	C887.848816,164.886795 887.866821,164.478867 887.892822,164.277191 
	C890.209290,165.450089 891.317261,169.105011 895.200989,167.564392 
	C895.889221,167.291382 896.893188,169.162354 896.632263,170.330612 
	C895.701721,174.497284 898.809448,175.019440 901.250000,174.630478 
	C904.616638,174.093948 904.193176,176.013229 903.824890,177.702286 
	C902.978882,181.582596 904.312439,184.736740 906.868774,187.870514 
	C907.183167,188.464401 907.152100,188.908646 907.137939,189.687225 
	C907.267395,193.450043 908.589600,197.204453 904.379944,199.810181 
	C904.167664,200.521149 904.169128,200.947800 904.221497,201.690704 
	C904.061340,202.125229 903.850281,202.243484 903.324585,202.181183 
	C901.184509,199.780060 901.878479,197.307358 902.550293,194.995377 
	C903.819275,190.628326 902.452698,186.507584 901.452515,182.423798 
	C901.090515,180.945953 900.038025,178.961792 897.332947,179.057480 
	C896.740723,179.174789 896.525391,179.282791 895.956848,179.318573 
	C890.676147,177.855850 890.210205,173.219589 888.258301,169.233490 
	C888.013306,168.980850 888.000000,169.000000 888.009583,168.993683 
	C887.662781,168.620621 887.381653,168.203125 887.111206,167.359436 
	C887.059448,166.466446 887.189453,165.980957 887.643311,165.207001 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M888.061523,161.862335 
	C886.878235,161.930466 886.289490,161.498306 886.074219,160.296234 
	C885.057251,156.606155 881.673523,155.643509 879.213623,153.265137 
	C878.959778,153.039627 878.489014,153.013535 878.254028,152.994812 
	C875.515015,149.398392 878.205383,146.764343 880.182312,144.683090 
	C882.714050,142.017715 883.737305,139.457840 882.746460,135.685349 
	C884.201660,134.031372 885.672485,133.726547 887.666687,134.744598 
	C887.992493,136.061279 888.002991,137.112228 888.032959,138.555176 
	C888.060974,142.315262 887.240601,145.980881 891.634521,147.952820 
	C892.201538,150.125702 889.502747,149.697357 889.035034,151.621216 
	C886.430298,152.055145 885.003113,153.378937 887.674805,154.902435 
	C891.665588,157.178085 890.676453,159.178940 888.061523,161.862335 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M895.739380,178.702759 
	C896.077515,178.918182 896.519775,178.976288 896.737915,179.021530 
	C897.097473,181.897369 902.375061,184.032547 898.599609,187.199020 
	C894.699402,190.470154 897.313171,193.460541 898.192200,196.578369 
	C898.488770,197.630386 899.941162,198.636948 899.800293,199.488022 
	C898.569519,206.924942 900.731201,213.971771 901.967590,221.543579 
	C901.161194,222.224808 900.368591,222.487640 899.283569,222.874390 
	C896.504578,222.994553 897.475708,219.968369 895.521729,219.028976 
	C892.929138,215.444260 892.240417,211.997940 893.759033,208.016647 
	C896.427734,201.020065 892.445862,194.771545 891.069641,188.252762 
	C890.634216,186.190247 888.963745,186.636383 887.506042,187.502960 
	C885.577576,188.649368 883.811096,190.591049 880.975220,188.644745 
	C880.446960,187.915756 880.300720,187.425858 880.300598,186.507477 
	C880.869690,184.606644 881.563293,183.182709 882.847290,181.646118 
	C887.242981,180.448456 890.870056,177.556351 895.739380,178.702759 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M907.774658,215.899933 
	C908.062134,218.329849 908.037537,220.715515 908.016296,223.546371 
	C906.197144,225.169556 904.921143,224.421997 903.945129,222.409882 
	C904.363342,219.859512 904.724243,217.718277 905.163208,215.154175 
	C905.981812,215.106094 906.722229,215.480881 907.774658,215.899933 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M906.839844,206.563782 
	C905.864258,207.047867 905.188049,207.587173 904.701050,208.861053 
	C902.333130,208.189133 903.154175,206.260193 903.125732,204.226456 
	C903.852905,203.336670 904.577881,203.261215 905.630066,203.838318 
	C906.219055,204.825317 906.527588,205.547668 906.839844,206.563782 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M903.762878,222.172668 
	C904.844177,223.122375 906.057190,223.602997 907.686157,223.966370 
	C908.077637,224.381226 908.135681,224.770859 908.264526,225.444977 
	C908.335388,225.729477 908.161499,225.862518 908.078308,225.933701 
	C906.695557,227.092270 905.296814,227.357727 903.401855,226.236816 
	C903.031921,225.522751 903.024475,225.086975 903.009766,224.324402 
	C903.176086,223.446579 903.349731,222.895599 903.762878,222.172668 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M905.910889,203.790695 
	C905.225708,204.041412 904.540771,203.979874 903.517395,203.876724 
	C903.139160,203.428162 903.099365,203.021210 903.034729,202.307434 
	C903.219360,202.003784 903.428955,202.006973 903.955444,202.008575 
	C905.179688,202.095352 905.765869,202.541397 905.910889,203.790695 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M904.511230,200.026001 
	C905.173706,196.916504 907.688354,194.111069 906.858154,190.213470 
	C907.769104,191.514389 908.334534,193.029602 909.006836,194.495804 
	C911.044189,198.939255 907.082092,198.779739 904.511230,200.026001 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M882.764038,135.798859 
	C885.808716,139.049652 886.521912,141.350266 882.216309,144.205414 
	C879.944397,145.711960 877.768616,148.679596 878.015503,152.592255 
	C874.687439,152.120163 872.814087,150.602173 875.306580,147.065460 
	C876.392395,145.524719 875.961609,143.896347 874.551636,143.206635 
	C871.260742,141.596878 868.284546,136.932556 863.712891,142.520889 
	C863.236755,137.462341 867.936646,135.565201 867.971130,131.407928 
	C871.778076,128.345764 874.045166,131.033325 876.679321,133.739471 
	C876.966736,134.037552 877.446594,134.132095 877.687622,134.173477 
	C878.569275,134.707016 879.211609,135.194778 880.407593,135.074860 
	C881.370911,135.081055 881.986694,135.226013 882.764038,135.798859 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M866.262207,128.856323 
	C865.394653,129.931320 864.525391,129.993210 863.307007,130.015900 
	C862.287903,130.618683 861.514893,131.032166 860.159912,131.170959 
	C854.751160,129.373077 854.313293,128.574097 855.140747,122.135071 
	C856.616699,120.234863 857.587646,120.985611 858.227600,122.320328 
	C858.705139,123.316208 858.757690,124.504623 859.697998,125.745926 
	C861.470520,127.428482 863.396057,127.652412 865.672241,127.950317 
	C866.204651,128.169403 866.366272,128.371429 866.262207,128.856323 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M866.236938,129.275360 
	C865.998779,128.943802 866.001221,128.748413 866.023315,128.260193 
	C870.303772,128.458374 874.871033,128.326080 876.943909,133.676056 
	C874.041199,133.553711 872.308716,129.850616 868.376465,131.021942 
	C867.476501,130.498184 866.977051,129.954865 866.236938,129.275360 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M883.309937,135.886627 
	C882.226440,136.047409 881.479370,136.048203 880.358521,136.057831 
	C879.663513,135.663589 879.527039,135.202301 879.754211,134.376617 
	C882.008728,132.245575 884.395508,131.650864 887.086304,132.027527 
	C888.680237,132.250641 888.895874,133.074860 888.124634,134.690613 
	C886.498169,135.014862 884.988037,134.860641 883.309937,135.886627 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M859.930054,125.683884 
	C857.037292,125.756119 858.714905,122.207726 856.085571,120.909569 
	C855.452515,120.022453 855.485352,119.572784 855.881592,118.883728 
	C856.564270,118.469849 857.013062,118.450836 857.730957,118.783783 
	C858.632080,121.109833 859.271545,123.211983 859.930054,125.683884 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M857.995483,118.671013 
	C857.550415,119.003029 857.108215,118.998383 856.334229,118.995758 
	C855.619690,119.379013 855.164185,119.512741 854.326843,119.184540 
	C853.924744,116.442108 854.829895,115.387268 857.669739,116.850372 
	C857.993652,117.436378 857.995972,117.885368 857.995483,118.671013 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M879.617676,134.051636 
	C879.966187,134.507263 879.974976,134.951752 879.984253,135.731430 
	C878.214844,137.417191 878.160217,136.000702 877.946045,134.514236 
	C878.378418,134.156723 878.828186,134.098602 879.617676,134.051636 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M903.999817,245.627197 
	C903.561096,246.005646 903.124573,246.011276 902.360718,246.020782 
	C901.496216,245.604950 900.951843,245.060226 901.005066,244.406189 
	C901.373474,239.878952 900.074463,236.374100 896.450623,233.177109 
	C893.676758,230.729935 895.165771,227.750183 898.585815,225.941467 
	C899.699158,228.979813 899.173157,232.677994 904.212097,232.658295 
	C905.473083,232.653366 904.820007,235.572708 905.007690,237.582504 
	C905.012268,238.757370 905.011658,239.505234 905.013306,240.627167 
	C904.985535,241.594650 904.803162,242.132019 904.228027,242.807983 
	C904.019592,243.763184 904.010803,244.508774 903.999817,245.627197 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M904.312988,242.978394 
	C904.194641,242.459595 904.360901,241.901581 904.771423,241.172409 
	C906.055664,241.901535 905.804504,242.523392 904.312988,242.978394 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M898.973816,225.629044 
	C895.394409,229.321686 895.104492,232.157471 899.950867,234.622864 
	C904.997375,237.190079 901.270813,241.660263 902.021057,245.600876 
	C902.029663,246.463623 902.025940,246.902603 902.025879,247.671051 
	C901.682190,249.981781 901.939148,252.033356 901.084961,254.225388 
	C895.576904,254.402985 894.453613,252.615768 896.378174,247.301147 
	C894.992493,246.686478 893.435547,247.473709 892.070251,246.791855 
	C889.704529,245.610321 889.356323,243.471909 890.953064,241.901855 
	C894.725952,238.192047 891.800476,236.050552 889.450745,233.763458 
	C887.424744,231.791534 883.131775,231.431976 885.077576,226.778381 
	C885.385986,226.040634 883.264771,225.722382 882.257751,225.244690 
	C879.785706,224.071991 878.688843,222.354889 879.939148,219.318787 
	C883.749390,214.249191 884.883972,214.132706 889.883728,217.489578 
	C891.399414,218.507187 892.532471,220.637970 895.476685,219.362534 
	C899.324646,218.469681 898.340393,220.937927 898.757080,222.796875 
	C898.987366,223.749939 898.983643,224.501556 898.973816,225.629044 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M903.072388,226.326904 
	C904.415466,225.972931 905.791687,225.987350 907.581482,226.003326 
	C907.989929,226.761017 907.984802,227.517166 907.961792,228.651978 
	C906.006226,228.925232 903.794006,229.388641 903.072388,226.326904 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M930.858032,273.722168 
	C927.905701,274.557709 925.719543,273.366058 924.161499,270.314453 
	C925.741150,270.329651 927.479919,270.660645 929.600708,271.001648 
	C930.236389,271.815460 930.489868,272.619263 930.858032,273.722168 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M732.705688,93.374451 
	C734.751282,92.663063 736.508118,92.208290 738.292969,92.021080 
	C740.049316,91.836861 741.862366,91.618683 741.766052,89.436790 
	C741.675537,87.384590 740.119202,86.221260 738.106995,86.081551 
	C735.805786,85.921768 733.429871,86.315903 731.191650,85.901382 
	C728.431580,85.390213 724.658142,85.216965 727.552429,80.459229 
	C728.807068,78.396759 727.894165,75.956497 727.076416,73.358322 
	C727.616272,72.466499 728.259521,72.106194 729.392761,71.985664 
	C729.999084,72.008659 730.237549,72.042686 730.816833,72.178238 
	C734.252380,73.951408 737.984558,73.984871 741.239624,76.013382 
	C743.357544,75.568123 745.107544,74.891121 747.329590,75.233704 
	C748.428528,75.618134 748.913574,76.347939 749.741821,76.689209 
	C749.708801,75.697243 748.897339,75.427368 748.095093,74.745605 
	C747.205933,73.951614 746.818848,73.192543 746.261230,72.198853 
	C745.798462,71.490807 745.445618,71.079361 744.594849,70.884964 
	C743.920715,70.776764 743.541199,70.667244 742.906372,70.357605 
	C741.472595,68.597153 739.773987,67.686432 737.877808,66.461639 
	C737.493408,66.021202 737.364441,65.819908 737.101562,65.278999 
	C736.379211,61.541119 736.953308,58.980484 741.197693,59.051250 
	C742.064148,59.065697 742.673157,58.636906 743.320129,58.055855 
	C747.598450,54.213284 748.028809,54.299999 751.797729,59.678215 
	C752.023499,59.979282 752.002441,59.997498 752.014282,59.986389 
	C753.366150,61.107822 754.884216,62.057518 755.999634,63.897129 
	C756.474487,64.521774 756.676819,64.919121 756.846436,65.679077 
	C756.900818,66.758568 756.936035,67.502342 757.001221,68.617256 
	C757.539001,70.087013 757.355591,71.173195 756.848511,72.562561 
	C756.490356,73.408531 756.117615,73.912628 756.506653,74.731323 
	C757.640381,75.311020 758.620239,75.152573 759.899048,75.204323 
	C764.234192,75.966751 764.548340,70.968544 768.350220,70.150978 
	C769.437927,69.922806 769.885437,69.934593 770.668701,69.954544 
	C772.454895,70.472572 772.872375,71.588264 772.675964,73.350739 
	C770.731506,77.113365 766.296631,79.584854 769.332703,84.704018 
	C770.241699,86.236725 768.322571,87.572296 766.346741,87.594940 
	C764.896179,87.611557 763.717896,86.108116 761.959351,86.881508 
	C763.818604,92.112877 768.075928,90.693176 772.097656,90.817360 
	C772.549805,95.661888 770.674500,97.606819 766.772949,96.223366 
	C761.808472,94.463028 756.821289,92.643425 751.882751,89.655846 
	C752.187561,93.590691 749.626770,94.606972 746.567627,94.338570 
	C742.892578,94.016129 741.711914,97.440193 738.763184,98.841507 
	C736.678711,99.474808 734.992859,99.462051 732.994690,98.403397 
	C731.696167,96.707832 731.655823,95.247063 732.705688,93.374451 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M772.231384,90.924744 
	C768.153076,93.535507 764.779236,93.094078 761.516418,89.790283 
	C759.990356,88.245132 759.410767,86.542625 760.454102,84.941681 
	C761.681274,83.058701 762.974060,84.907028 764.212341,85.516998 
	C765.460388,86.131775 766.874390,87.682465 768.151978,86.013153 
	C769.604919,84.114685 767.790710,83.555763 766.411072,82.916191 
	C764.612671,82.082458 764.755981,80.852158 766.241272,79.991959 
	C768.654053,78.594635 769.505188,75.976952 771.619751,74.034271 
	C772.104553,74.137276 772.249329,74.309227 772.235291,74.778595 
	C771.120850,76.699539 771.796021,78.172745 772.733887,79.839020 
	C774.229004,81.346535 776.441162,80.907120 777.784668,82.739090 
	C778.039307,83.125801 778.045288,83.293678 778.055664,83.713486 
	C778.611084,85.511818 777.473267,86.947968 777.731079,88.814255 
	C777.625061,89.364578 777.553528,89.578140 777.277710,90.059692 
	C776.512695,90.703041 775.888062,90.855316 774.921509,90.831879 
	C774.252686,90.737885 773.880920,90.683960 773.248535,90.691818 
	C772.822449,90.779709 772.654358,90.824356 772.231384,90.924744 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M799.922241,99.714096 
	C799.828491,103.455681 796.756592,106.809990 799.783813,110.522659 
	C800.423096,111.306770 798.910706,112.473679 798.123291,113.720505 
	C796.914978,114.834854 795.846313,114.470413 795.519836,113.479370 
	C794.388245,110.044067 792.588074,105.637253 793.875000,103.037155 
	C796.052612,98.637596 792.666931,98.559540 791.010559,97.916649 
	C786.120544,96.018593 783.086487,92.701538 781.487915,87.461334 
	C782.096558,84.936111 783.592468,84.982491 785.652100,85.781845 
	C786.039307,87.139511 786.019958,88.289177 786.125671,89.427216 
	C786.257996,90.851929 787.088379,92.002678 788.360107,92.415703 
	C790.227600,93.022217 790.262634,91.082230 790.908936,90.107346 
	C791.781250,88.791580 792.097717,86.896782 794.280029,87.105171 
	C796.403503,87.307930 795.692566,89.400299 796.743774,90.746170 
	C798.636536,92.908142 796.757202,95.628029 798.734192,97.772156 
	C799.242554,98.508430 799.520691,98.987076 799.922241,99.714096 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M755.750793,73.331886 
	C756.229431,71.861115 756.478027,70.739723 756.878967,69.303360 
	C759.327515,70.682060 761.514709,69.462715 763.562317,68.534111 
	C765.060120,67.854836 766.345398,66.725761 768.528992,67.061859 
	C768.944275,67.795586 768.978394,68.501236 769.001465,69.558952 
	C768.990356,69.911018 769.022583,69.980606 769.026978,70.018700 
	C764.826355,70.182785 764.142944,75.071091 760.341675,76.125092 
	C758.734802,75.798332 757.690247,77.206604 756.175293,76.255554 
	C755.401062,75.323738 755.224670,74.548416 755.750793,73.331886 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M785.815674,85.662979 
	C784.638672,86.204185 782.954407,85.426064 782.040894,87.610046 
	C781.437378,90.822876 779.558472,90.102432 777.372803,89.283279 
	C776.038757,87.334564 775.507507,85.721733 777.855469,84.229950 
	C780.583191,84.418396 783.444824,82.995102 785.815674,85.662979 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M799.019531,97.664780 
	C794.618591,97.128273 796.174438,94.301758 796.959473,91.368362 
	C800.693115,92.112831 800.041809,94.654465 799.019531,97.664780 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M772.402222,75.000427 
	C772.054932,74.891266 772.033325,74.706512 771.985779,74.243546 
	C771.725647,72.848816 771.491516,71.732307 771.130920,70.289246 
	C772.775330,68.259804 773.691956,70.015442 774.314087,71.061333 
	C775.340393,72.786797 774.211365,74.019005 772.402222,75.000427 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M777.982422,82.594803 
	C776.249634,82.520187 774.269104,82.513489 773.039490,80.372307 
	C774.936646,80.061142 777.128906,79.577446 777.982422,82.594803 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M756.254395,64.989609 
	C753.600159,64.523392 753.608093,61.932644 752.073853,60.316795 
	C752.813538,59.878063 753.789551,60.045692 754.357849,59.640915 
	C758.253235,56.866276 758.030518,59.678238 758.043396,62.650925 
	C758.058594,63.045975 757.977661,63.499077 757.947449,63.727219 
	C757.564758,64.434181 757.122620,64.789963 756.254395,64.989609 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M756.069336,65.228317 
	C756.424072,64.692551 756.881836,64.362183 757.628418,63.993587 
	C757.744873,64.532944 757.572510,65.110519 757.132812,65.851440 
	C756.574463,65.892723 756.343384,65.699028 756.069336,65.228317 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M829.743530,133.726013 
	C830.002136,133.998993 830.480591,134.045807 830.719482,134.072144 
	C831.124817,134.265686 831.291260,134.432892 831.567749,134.935822 
	C830.855652,138.892685 827.681885,139.879181 824.844543,140.749908 
	C822.091248,141.594849 820.749756,139.493317 821.784912,136.584076 
	C822.192261,135.439301 824.550842,134.016418 821.992798,133.136826 
	C819.939514,132.430786 817.501587,129.287323 815.161255,133.418167 
	C813.941772,136.002838 812.269165,136.899368 809.796082,136.049438 
	C807.588257,135.290680 805.800720,133.982071 806.076477,131.476166 
	C806.393433,128.595245 804.974121,127.689240 802.275208,127.223877 
	C802.094299,124.414665 804.559204,123.470963 805.882935,121.336578 
	C809.780457,118.315063 813.829041,116.954308 818.589294,117.292191 
	C823.796143,117.661766 825.572571,118.829399 826.734253,123.989853 
	C827.457642,127.203293 827.462830,130.618942 829.743530,133.726013 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M829.950806,133.593628 
	C826.470276,133.234329 826.048462,129.524536 825.708618,127.424515 
	C825.170410,124.098335 825.130249,119.336502 822.346130,118.982758 
	C818.173584,118.452599 813.241089,117.139091 809.104492,120.240013 
	C808.489807,120.700745 807.573608,120.759216 806.418457,121.024185 
	C807.519470,117.739479 810.844421,117.339844 813.694397,116.225197 
	C816.207642,115.242241 818.100342,114.919022 816.908020,110.789642 
	C815.886414,107.251343 819.116882,104.030136 823.606140,103.022873 
	C823.858215,107.203636 824.601257,111.119171 826.737671,114.836189 
	C828.854980,118.519989 830.679321,122.319183 829.112854,126.915527 
	C828.434082,128.907288 829.019653,131.169174 829.950806,133.593628 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M801.902344,127.338097 
	C807.526123,125.581680 807.594177,125.587517 807.117004,131.149872 
	C806.958862,132.993179 807.707336,134.011002 809.266541,134.578903 
	C810.936218,135.187042 812.772888,136.227966 813.876709,133.293945 
	C818.287415,131.079437 819.747559,134.243866 820.764282,137.185394 
	C821.321960,138.798737 821.663574,140.264587 823.221558,141.587021 
	C821.149048,145.075790 818.812134,144.350861 816.688049,142.132538 
	C813.322449,138.617767 809.809692,137.163528 804.796509,138.967636 
	C800.194885,140.623657 798.172119,139.139847 796.323120,134.281708 
	C795.838074,133.007462 795.150269,131.913925 793.948914,130.645386 
	C793.277771,129.586060 793.172913,128.794525 793.516357,127.589661 
	C794.683350,126.304337 795.627014,126.312737 796.711182,127.721275 
	C798.659546,129.425522 800.316833,129.582520 801.902344,127.338097 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M777.000488,89.268402 
	C778.500793,88.944565 780.051270,89.334030 781.718628,88.242920 
	C784.973633,90.228966 785.447021,95.051003 789.779785,95.906380 
	C792.323181,96.408501 794.052979,98.311935 796.333740,99.247200 
	C797.314209,99.649277 798.613586,100.356277 798.257080,101.750198 
	C797.936218,103.004417 796.814026,102.775902 795.805786,102.470734 
	C794.707581,102.138336 793.921021,102.992714 794.487366,103.666252 
	C797.020142,106.678085 794.717834,110.889839 797.582764,113.927704 
	C797.956360,114.459190 797.957886,114.889282 797.954956,115.642166 
	C797.459656,116.532448 796.923462,117.055962 796.175110,117.764076 
	C795.964783,117.950462 795.482239,117.972046 795.241089,117.986176 
	C794.608521,118.369247 794.145569,118.528755 793.262329,118.325874 
	C792.849121,112.587837 791.927368,107.268227 789.273865,102.187607 
	C788.189331,100.111053 787.220886,98.837921 784.911438,98.150429 
	C781.051880,97.001526 777.797668,94.858757 776.781982,90.341644 
	C776.999451,89.872803 777.000183,89.700119 777.000488,89.268402 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M792.762207,118.248360 
	C793.439636,117.991982 793.885437,117.992630 794.665649,117.996796 
	C795.599915,120.855560 797.511719,119.887794 799.244690,119.462791 
	C799.966309,119.285805 800.612976,119.441307 800.703308,120.259720 
	C800.748535,120.670013 800.439087,121.490540 800.256042,121.500488 
	C795.979736,121.732918 798.367249,125.576027 796.968262,127.729553 
	C796.148376,127.734970 795.394348,127.383003 794.062134,126.780769 
	C793.104736,125.930138 792.929565,125.261246 792.782410,124.228516 
	C792.670837,122.763237 792.589172,121.639488 792.316650,120.191490 
	C792.272339,119.385681 792.362793,118.938126 792.762207,118.248360 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M796.289551,117.923500 
	C796.069214,117.069359 796.342407,116.310760 797.640137,116.017830 
	C797.923889,116.898521 797.671326,117.675751 796.289551,117.923500 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M831.262329,135.317657 
	C830.995361,135.081436 830.976379,134.588989 830.967407,134.343750 
	C835.177795,131.524979 840.581116,130.057495 840.827637,123.298386 
	C841.246582,122.788483 841.485535,122.573807 841.769836,122.070953 
	C842.103882,121.834053 842.331604,121.817574 842.896606,121.742104 
	C843.469238,121.696274 843.704468,121.709442 844.158325,122.000664 
	C844.414551,122.511276 844.452209,122.743835 844.444580,123.326035 
	C843.122253,127.385216 842.088318,131.225571 838.641846,134.144135 
	C837.253845,135.200836 836.043579,135.887375 834.420654,136.538544 
	C833.048340,136.690262 832.234802,136.250977 831.262329,135.317657 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M836.415283,125.950638 
	C836.803101,125.233101 837.193787,125.228188 837.539490,125.737717 
	C837.612061,125.844727 837.610352,126.090050 837.535400,126.194351 
	C837.175537,126.694618 836.784729,126.674065 836.415283,125.950638 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M752.013062,59.604591 
	C751.126709,59.262020 750.198547,58.580261 749.339478,57.820385 
	C747.281372,56.000042 745.363770,54.559662 744.942383,58.964268 
	C744.771606,60.749302 743.528442,61.312592 742.138794,60.785000 
	C737.031128,58.845894 738.150635,62.582653 737.968506,65.607292 
	C734.889160,66.918137 735.010376,64.704170 734.894775,62.395325 
	C735.272949,60.892632 735.571899,59.792103 735.886047,58.348915 
	C738.709351,58.039524 740.848694,57.978138 742.622742,54.634693 
	C744.458191,51.175430 747.763489,53.931965 750.073547,55.399963 
	C751.219910,56.128395 752.090576,57.583557 752.013062,59.604591 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M734.614868,61.946587 
	C736.582214,62.797108 735.323364,65.301125 737.622803,65.962234 
	C737.958435,66.030235 737.977051,66.024635 737.973450,66.031479 
	C739.520203,67.171661 740.195129,69.369354 742.707275,69.844658 
	C743.446350,69.999054 743.890259,70.001099 744.667114,70.003639 
	C745.595459,70.023315 746.130310,70.206558 746.796509,70.794792 
	C747.343384,71.765800 747.684326,72.533775 748.079102,73.613220 
	C748.438721,74.362976 748.536682,74.843651 748.213501,75.682724 
	C745.934753,76.331711 743.869446,76.664642 741.407043,77.009384 
	C738.447693,74.355690 735.011536,73.973221 731.312561,73.227036 
	C730.989258,73.021385 730.520996,72.979576 730.286560,72.962204 
	C727.337524,70.671921 723.755005,69.018707 724.047913,64.213341 
	C724.047668,62.532017 724.455811,61.380787 725.784912,60.263908 
	C726.518066,60.018848 726.953003,60.012638 727.714844,60.002712 
	C730.398254,59.700516 732.145630,61.393112 734.614868,61.946587 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M725.797241,59.790924 
	C725.745056,61.117260 725.406982,62.209465 725.021606,63.643570 
	C723.831726,66.011452 720.048584,67.723961 724.110535,70.702576 
	C721.748291,74.078064 719.743896,73.583611 717.793335,70.660805 
	C716.376953,68.538437 714.825500,67.005943 712.170166,69.304359 
	C708.431946,72.540207 708.201172,69.589806 707.985779,66.402451 
	C710.110046,65.737801 712.326965,67.667503 714.453796,65.237625 
	C715.870605,63.618896 718.729370,63.722744 716.718872,59.900879 
	C715.416870,57.425884 718.209106,54.415981 721.452271,53.363346 
	C722.845825,52.911053 723.620850,53.379509 723.990906,54.837128 
	C724.396790,56.435959 724.996216,57.985668 725.797241,59.790924 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M707.977661,65.646317 
	C704.966248,65.634850 702.855774,62.698807 699.290100,63.338272 
	C698.136658,63.545124 696.177307,61.456463 696.174683,59.442898 
	C696.172363,57.622543 697.617249,57.063389 699.623413,57.003677 
	C699.171326,59.007690 697.846985,61.122360 701.985107,60.568459 
	C705.368958,60.115524 707.456970,61.823833 707.977661,65.646317 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M838.125061,134.119827 
	C840.559387,130.672775 841.793518,126.757927 843.935913,122.937691 
	C844.225220,122.695053 844.459839,122.298508 844.743103,121.945183 
	C845.026367,121.591858 845.431824,121.382782 845.483398,121.160583 
	C845.624512,120.789429 845.714111,120.640480 845.933472,120.265472 
	C846.494568,120.036484 846.925720,120.033554 847.680725,120.018967 
	C848.647583,119.118881 849.778442,119.053307 850.846802,118.286087 
	C851.092468,118.104179 851.492310,118.103767 851.690918,118.103775 
	C852.215637,118.206642 852.478638,118.396721 852.800659,118.888252 
	C853.666199,119.839279 854.748291,119.967850 855.784058,120.784775 
	C856.003479,120.996063 855.991211,121.010681 855.984131,121.021027 
	C856.674438,124.152016 854.023560,128.516251 859.670166,130.009308 
	C861.013916,131.989334 859.659729,132.543427 857.877319,133.158142 
	C856.921265,133.266434 856.376038,133.261917 855.436768,133.168640 
	C850.763245,133.245346 846.414917,132.740555 841.946472,134.681335 
	C840.460510,135.115997 839.391235,135.046051 838.125061,134.119827 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M856.006226,120.663055 
	C854.870850,120.958427 853.630615,121.177299 852.998291,119.434792 
	C853.104858,119.077736 853.286987,119.053001 853.743347,118.999077 
	C854.458618,118.977776 854.899536,118.985649 855.671448,118.995651 
	C856.004639,119.441864 856.006836,119.885956 856.006226,120.663055 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M850.731689,118.075516 
	C850.880859,119.475578 850.021667,120.027222 848.352417,120.000648 
	C848.098572,118.515274 848.971436,117.963257 850.731689,118.075516 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M844.543945,121.787994 
	C844.207458,121.888206 844.010559,121.852196 843.523865,121.749649 
	C843.643677,121.466873 844.053406,121.250641 844.706543,120.993744 
	C844.950073,120.953079 844.996033,120.997917 844.953247,121.191345 
	C844.910522,121.384781 844.683655,121.651756 844.543945,121.787994 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M845.083862,121.460068 
	C844.910522,121.384781 844.984558,121.217690 845.012817,121.130737 
	C845.041016,121.043777 845.291443,120.985191 845.413208,120.961792 
	C845.658813,121.238419 845.566223,121.437408 845.083862,121.460068 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M914.631470,798.059448 
	C915.298645,799.116882 915.613281,800.211853 915.960938,801.649719 
	C915.799683,803.147583 914.891479,803.825623 914.028198,804.899658 
	C913.506897,805.638672 913.272034,806.165100 913.395447,807.097595 
	C913.381958,808.540588 912.809814,809.462158 912.143433,810.697998 
	C912.054260,811.451294 912.060486,811.890808 912.067261,812.659973 
	C912.232483,815.495422 910.443970,817.869629 911.739868,820.740845 
	C911.933167,821.783691 911.952454,822.525635 911.965515,823.639160 
	C909.342224,826.676514 911.367371,830.733521 909.056458,833.586548 
	C908.336914,834.474792 908.002869,835.616211 906.086182,835.714050 
	C904.880493,834.642883 904.506775,833.604736 904.729736,832.662354 
	C906.192444,826.481384 906.398682,820.142578 907.120117,813.873535 
	C907.287048,812.423340 904.556091,812.454834 904.537964,810.265137 
	C904.176025,809.125061 904.135254,808.365845 904.696045,807.278992 
	C905.850342,805.237061 906.793091,803.528442 907.743774,801.506592 
	C909.927490,800.161133 912.103210,799.128967 914.631470,798.059448 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M912.387146,810.971802 
	C910.140808,809.447571 912.246765,808.665161 912.856079,807.285767 
	C912.616638,806.616455 912.469971,806.153320 912.767578,805.302002 
	C912.992676,803.372437 914.301941,802.910583 915.696777,802.126953 
	C918.022034,802.050415 920.218140,802.759766 918.189026,804.915833 
	C916.152039,807.080322 917.839600,808.566467 917.974731,810.695374 
	C916.226135,810.700195 914.495667,809.828918 912.387146,810.971802 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M912.104553,820.801575 
	C907.396484,818.714600 910.227051,816.018982 911.799194,813.194824 
	C913.985474,814.720642 917.535522,815.885376 914.142334,819.691345 
	C913.425476,820.171265 912.860413,820.366333 912.104553,820.801575 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M918.049683,811.389893 
	C919.811096,811.136719 921.707153,810.613098 923.752747,811.772461 
	C921.982910,813.306641 919.990356,813.792969 918.049683,811.389893 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M912.975464,844.635864 
	C910.938782,844.470520 910.224731,843.613770 912.599854,842.034302 
	C912.940186,842.727661 912.961792,843.490967 912.975464,844.635864 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M909.287720,853.903992 
	C903.693604,853.378479 901.983704,851.029663 903.746094,846.202881 
	C907.537964,846.074585 912.079773,844.805054 911.009766,851.579224 
	C910.533752,852.596375 910.039307,853.181702 909.287720,853.903992 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M904.184814,840.257812 
	C903.979309,839.815063 903.976135,839.638794 903.956909,839.198486 
	C903.854126,838.720154 903.767334,838.505920 903.872803,838.002808 
	C904.494568,837.515076 904.941833,837.459717 905.682739,837.757874 
	C907.866699,838.250671 908.535645,839.579956 907.613220,841.005249 
	C906.621765,842.537231 905.439148,841.009766 904.184814,840.257812 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M905.971191,837.643677 
	C905.524841,837.978455 905.091187,837.988892 904.331787,838.004517 
	C903.872620,837.813538 903.739197,837.617249 903.590027,837.064880 
	C903.833252,835.954102 904.366760,835.568787 905.485229,835.674438 
	C905.811829,835.818054 905.916626,835.919373 905.969238,835.970337 
	C906.009155,836.454041 905.996460,836.886658 905.971191,837.643677 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M854.003662,877.995972 
	C853.592285,875.541626 851.659363,875.264221 849.351318,874.984375 
	C848.012085,873.810608 848.643494,872.805603 849.310852,871.824951 
	C849.960815,870.869812 851.269958,869.959717 851.035950,868.883545 
	C849.092346,859.946350 856.790466,858.516541 861.775146,855.126282 
	C863.449341,853.987549 864.603455,852.963379 864.683594,850.926392 
	C864.709717,850.263062 864.667480,849.356873 865.090393,849.011597 
	C869.950439,845.043396 869.886292,837.194275 876.167847,834.532837 
	C881.539001,832.257141 881.718140,826.593567 884.865784,822.489502 
	C886.235840,821.015259 886.305969,819.380859 887.061646,817.700134 
	C889.547974,816.477478 890.964783,818.217163 893.353271,819.492737 
	C889.465820,815.940369 888.921021,812.682922 891.868774,809.047913 
	C893.444519,807.104736 893.418762,804.783752 892.994812,802.406433 
	C892.339844,798.734192 892.405273,795.133057 894.682007,791.544189 
	C895.088867,790.616882 895.394958,790.014893 895.616455,789.024658 
	C895.750977,788.244385 895.842285,787.799988 896.011902,787.020996 
	C896.126648,783.652649 894.639771,780.036560 899.328369,778.838074 
	C900.167542,778.623657 900.201294,777.346558 899.818909,776.619690 
	C898.490845,774.095032 899.815735,771.765808 900.186035,769.006165 
	C900.319031,767.906006 900.608826,767.268005 901.642212,766.932129 
	C905.779541,770.652710 906.134155,780.104187 902.409058,782.988647 
	C900.651672,784.349426 899.809875,785.753540 899.960938,787.965515 
	C900.093567,789.908386 900.127197,791.798218 898.658203,793.559082 
	C897.602234,794.825012 897.277100,797.152710 899.328491,797.679565 
	C902.755432,798.559753 901.839478,800.991272 901.998901,803.574829 
	C899.359009,805.706604 898.792969,808.473328 899.648743,810.994202 
	C900.689453,814.059814 900.518066,817.157104 901.063599,820.572449 
	C901.261169,821.892029 901.223267,822.855591 900.981628,824.169800 
	C900.529419,825.684509 899.724121,826.583252 898.848389,827.839600 
	C898.464417,828.265808 898.299377,828.423645 897.868286,828.790527 
	C896.566467,829.513855 896.022156,830.526794 894.980591,831.518738 
	C890.308716,833.171997 887.474915,835.334534 887.866211,840.851868 
	C888.204224,845.618774 886.183655,847.173279 881.464050,847.922791 
	C879.049377,848.306274 874.494934,855.043823 874.967712,857.533020 
	C876.065796,863.314575 875.172424,864.508728 869.830750,862.943237 
	C864.888062,861.494629 862.268311,866.278809 858.070740,866.559509 
	C857.728333,866.582397 858.196899,868.073914 858.393188,869.261719 
	C859.910889,876.442078 859.384766,877.683167 854.362671,878.235107 
	C854.007324,877.992676 854.000000,877.999268 854.003662,877.995972 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M909.205322,744.807312 
	C908.513428,745.591187 908.022034,746.174988 907.267761,746.884399 
	C906.472717,747.542480 905.928467,748.062744 905.210022,748.808167 
	C905.015503,749.012817 905.000000,749.000000 905.006470,749.007446 
	C903.774292,749.863037 904.229919,751.467529 903.220459,752.755981 
	C901.593201,753.111389 901.011230,753.924744 901.053833,755.617249 
	C901.122070,756.225525 901.193970,756.487976 900.952515,757.013428 
	C900.367432,757.245300 900.095703,757.214172 899.501526,756.927368 
	C896.900513,753.504028 897.606506,749.895935 897.718994,745.994995 
	C900.171143,740.338501 902.904907,735.195251 907.322937,731.406189 
	C910.328918,728.828125 911.269287,724.475647 915.537720,722.899414 
	C917.514771,726.603882 916.027710,729.934570 914.487183,733.025635 
	C913.601257,734.803345 912.927307,736.570496 912.209045,738.724243 
	C910.826538,739.836304 911.044739,741.093384 911.007263,742.637939 
	C910.457886,743.522583 909.932861,744.065857 909.205322,744.807312 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M902.000000,766.666687 
	C901.683105,767.469604 901.366272,767.939270 901.023499,768.704468 
	C899.255615,771.408142 897.778503,774.139709 893.982605,774.291138 
	C893.220398,773.599731 893.695374,774.291138 893.284424,773.766235 
	C887.028015,765.773315 888.265015,761.345032 898.236206,757.422241 
	C899.855530,757.936829 900.302002,759.149963 901.225830,760.384399 
	C901.567322,761.100403 901.672791,761.529480 901.673523,762.279541 
	C901.560547,762.940552 901.481262,763.300842 901.474182,763.901245 
	C901.532837,764.138489 901.782837,764.560303 901.891418,764.780151 
	C902.000000,765.444458 902.000000,765.888916 902.000000,766.666687 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M926.031189,742.602173 
	C923.737183,744.238953 924.858643,747.480286 922.357178,748.937866 
	C921.785522,746.312988 922.569153,743.794739 923.862549,741.552124 
	C925.053772,739.486694 925.763306,740.572449 926.031189,742.602173 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M901.286743,761.000488 
	C899.549805,760.903625 898.985474,760.050537 899.000977,758.340332 
	C898.820618,757.525757 898.932739,757.147522 899.658447,756.923096 
	C899.987793,756.992615 900.486572,756.994446 900.735962,756.994751 
	C901.579468,757.019104 902.112915,757.202698 902.759583,757.810730 
	C902.962158,758.462708 902.971924,758.899841 902.984741,759.664795 
	C902.578003,760.410828 902.184082,760.851135 901.286743,761.000488 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M900.994385,761.334961 
	C901.461182,760.683533 901.929626,760.364563 902.692932,760.019165 
	C902.840027,761.491272 902.692200,762.989868 902.272217,764.744263 
	C901.832703,765.001831 901.665466,765.003662 901.247192,765.007568 
	C900.611572,764.623901 900.464539,764.162537 900.772766,763.311523 
	C900.992188,762.554199 900.994141,762.110840 900.994385,761.334961 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M903.347412,752.923462 
	C903.177979,751.624817 902.445007,749.945374 904.656311,749.079956 
	C904.815063,750.376526 905.542236,752.053833 903.347412,752.923462 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M905.309326,748.985840 
	C905.084839,748.105286 905.334534,747.324951 906.685852,747.053223 
	C907.004883,747.010010 907.002625,747.507385 907.003052,747.756104 
	C906.536682,748.322876 906.069946,748.640869 905.309326,748.985840 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M902.923950,757.707031 
	C902.410339,757.841248 901.868225,757.656860 901.155762,757.233765 
	C900.993469,756.823059 901.001648,756.651062 901.029968,756.221069 
	C901.938110,756.084961 902.703735,756.365173 902.923950,757.707031 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M905.867554,835.399597 
	C905.305359,836.042847 904.798889,836.267639 904.121338,836.716309 
	C903.592346,837.415527 903.149658,837.776733 902.287476,838.002930 
	C901.118225,838.129028 900.340637,838.355835 900.113342,839.702881 
	C899.212097,841.162537 897.994629,841.404907 896.309448,841.232788 
	C893.783997,837.407288 894.152344,835.081177 897.763733,834.409180 
	C901.717712,833.673462 902.657288,831.931519 901.996887,828.289307 
	C901.617676,826.197998 902.695251,824.042725 902.347290,821.503418 
	C902.370422,820.123779 902.694397,819.218079 903.674011,818.223389 
	C906.447083,815.604797 903.376099,813.196167 903.987488,810.404297 
	C904.152771,809.876831 904.323059,809.747803 904.751099,809.809448 
	C908.802612,815.170654 909.452148,820.850037 907.066956,826.662720 
	C905.925415,829.444580 906.216003,832.202637 905.867554,835.399597 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M903.697876,817.740967 
	C903.716553,818.795349 903.470215,819.600220 903.092407,820.697998 
	C902.315002,821.618530 901.554871,821.728394 900.376770,821.262329 
	C899.357849,816.958679 898.496582,812.897217 898.075623,808.790649 
	C897.856812,806.656128 898.314331,804.373657 901.612061,804.008789 
	C899.681152,808.794189 902.120667,813.083008 903.697876,817.740967 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M905.006958,809.618286 
	C904.837769,810.000671 904.666748,810.001282 904.239136,810.003906 
	C902.778198,808.892456 902.647705,807.888733 904.595093,807.005493 
	C904.940063,807.709290 904.972595,808.472961 905.006958,809.618286 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M960.294800,478.718597 
	C960.063354,480.078278 960.049683,481.132599 960.052124,482.582092 
	C960.866272,483.674194 960.954163,484.775940 961.753174,485.814056 
	C961.951599,486.042725 962.000000,486.003662 961.976868,486.026123 
	C962.347900,486.490234 962.616211,486.998260 962.785156,488.005829 
	C962.536072,492.047607 962.683838,495.726746 960.986328,499.096436 
	C960.687317,499.689972 960.548340,500.358490 960.704163,501.021027 
	C963.929871,514.735657 960.428528,528.576233 961.461670,542.740112 
	C961.398254,543.946655 961.360779,544.743958 960.998291,545.865662 
	C960.286682,547.934387 958.489014,549.341492 959.737793,551.737549 
	C959.973877,552.755310 959.982788,553.505127 959.989136,554.629944 
	C959.045654,566.105774 958.148010,577.211792 956.035645,588.593628 
	C956.030945,589.760986 956.023193,590.507385 956.008911,591.626831 
	C953.754822,599.589478 951.809265,607.231140 951.974060,615.613159 
	C951.966309,616.728638 951.969604,617.470520 951.974243,618.583374 
	C951.629639,619.305664 951.276306,619.649231 950.642456,620.236145 
	C948.142517,624.057312 946.605042,627.896240 946.174377,632.095337 
	C945.529480,638.385437 943.827637,644.429321 942.075623,650.464539 
	C941.604797,652.086365 941.262634,653.685669 941.820068,655.733398 
	C941.976013,657.151184 941.870422,658.146484 940.923401,659.250305 
	C940.081970,659.857605 939.458496,660.235840 938.834717,660.307129 
	C934.768311,660.772583 933.202271,661.468689 934.336304,666.835938 
	C935.740723,673.483276 932.798523,680.195190 927.930542,685.471252 
	C927.026855,686.450623 926.123840,687.202515 925.924500,688.726135 
	C925.074402,695.221313 924.938660,695.282654 918.422302,696.096985 
	C917.597595,696.200012 916.752747,696.221008 916.430420,697.204651 
	C924.078308,703.591003 924.111816,703.735901 916.118225,710.352173 
	C914.588013,711.618774 914.757080,712.793396 915.380432,714.282227 
	C916.393799,716.702637 915.900574,719.069885 914.180542,720.791748 
	C910.947571,724.028015 908.554810,728.316711 903.715698,729.683350 
	C903.073120,729.864807 902.079773,730.202148 902.082092,730.696472 
	C902.107117,735.941833 896.397278,739.180908 897.200195,745.048157 
	C897.031494,745.932129 896.833313,746.409180 896.288025,747.151428 
	C895.035950,748.046997 893.989136,748.125488 892.536621,747.711548 
	C888.960632,742.546326 889.276367,737.493713 892.211975,732.543335 
	C894.007141,729.516174 896.366699,726.927979 899.209961,724.799438 
	C901.991821,722.716858 904.543091,720.710205 901.657104,716.673279 
	C900.400574,714.915710 901.893982,713.265686 903.510132,712.100342 
	C908.387817,708.583252 911.232483,704.022522 910.842651,697.782898 
	C910.665649,694.950012 912.422729,692.771118 913.475830,690.343933 
	C914.663391,687.606750 915.553772,684.891296 911.440735,683.569824 
	C909.658264,682.997253 908.820557,681.406433 909.346069,679.553040 
	C910.051758,677.063782 912.116699,675.088318 914.400085,675.409241 
	C919.964783,676.191406 921.459290,672.539368 922.481873,668.646851 
	C924.442993,661.181946 927.342041,654.190613 931.469604,647.677490 
	C932.834229,645.523987 932.522827,643.201660 931.604370,640.944153 
	C930.439026,638.079529 931.202942,635.650391 932.507996,632.891479 
	C934.970581,627.685852 935.370850,621.815308 936.492859,616.223022 
	C937.557068,610.918762 938.416748,605.768860 942.287598,601.641541 
	C945.009277,598.739685 945.222656,595.251221 943.552734,591.878296 
	C942.013489,588.769226 941.902161,585.883911 943.290955,582.784607 
	C943.989502,581.225830 943.998352,579.553223 943.623047,577.877563 
	C943.361572,576.710571 943.287476,575.513184 944.182617,574.568909 
	C948.197449,570.333862 947.082703,564.623108 948.472595,559.636902 
	C948.775818,558.549011 948.537964,557.230835 947.969360,556.220886 
	C945.786926,552.344421 945.570374,548.793823 948.814026,545.255005 
	C950.358582,543.569763 949.355347,541.358337 948.724670,539.518188 
	C947.060059,534.660828 946.499146,529.867859 948.164612,524.858337 
	C948.910706,522.614136 948.776550,520.118835 947.337036,518.111145 
	C945.052429,514.924927 945.257385,511.784302 946.348083,508.184753 
	C947.833557,503.282837 949.268311,498.308289 947.348328,493.021332 
	C946.294739,490.120056 948.041687,487.147980 948.210083,484.158844 
	C948.377502,481.189056 947.784058,478.869080 944.937500,477.541351 
	C941.865540,476.108459 941.361450,473.731567 942.688660,470.919678 
	C943.725037,468.723907 943.699036,467.186218 941.879456,465.174133 
	C938.745911,461.709106 939.692749,457.815613 944.181763,456.041779 
	C948.394043,454.377289 948.959656,452.122620 947.129028,448.320770 
	C945.794189,445.548615 946.014221,445.442657 949.301880,437.209381 
	C942.824524,437.527039 942.956238,437.529938 943.112732,430.431915 
	C943.187256,427.049622 943.622925,423.818359 944.742493,420.642395 
	C945.745667,417.796997 945.427002,415.424744 942.301025,414.009155 
	C938.569946,412.319519 937.254517,409.243835 938.043396,405.382050 
	C938.688538,402.223785 937.885864,399.878662 935.249084,398.009216 
	C933.660339,396.882812 932.526917,395.070892 933.284973,393.239655 
	C934.822693,389.525330 933.584167,386.667816 931.672791,383.517029 
	C929.321045,379.640472 928.879639,375.327820 930.939087,371.065857 
	C932.227295,368.399780 932.405090,366.003387 929.964905,363.841980 
	C928.946838,362.940247 928.143311,361.721008 928.458984,360.308594 
	C929.032349,357.743500 927.815063,356.213928 925.840332,354.946716 
	C925.140381,354.497559 924.179138,353.818939 924.198730,353.141541 
	C924.391785,346.445099 918.023376,341.835602 918.543396,335.051819 
	C918.730042,332.617767 917.843872,330.483765 916.190125,328.662506 
	C914.590698,326.901062 913.127747,324.857269 913.098755,322.511047 
	C913.017517,315.942169 911.065186,309.777069 909.687683,303.466309 
	C909.066345,300.619476 907.961304,298.758881 905.424988,297.220520 
	C901.017700,294.547424 898.301880,289.934326 894.240662,286.737244 
	C892.159973,285.099243 893.794250,282.602753 895.022705,280.875519 
	C897.705322,277.103851 897.995361,273.981445 893.475769,271.402832 
	C892.005798,270.564178 891.066650,269.026672 890.716553,267.358246 
	C889.776855,262.878937 886.758362,260.802246 882.651245,259.424225 
	C879.848633,258.483917 876.135071,257.988647 876.503906,253.235291 
	C880.122375,250.379547 883.203979,251.230225 886.730530,253.268295 
	C894.617615,257.826385 898.547180,265.348938 902.537231,272.917114 
	C902.844116,273.499298 902.935059,274.347382 903.506714,274.609558 
	C911.490479,278.270660 912.230164,286.968872 916.715210,293.481720 
	C917.067627,293.946594 917.206909,294.130981 917.521545,294.608154 
	C918.115173,295.671600 918.395020,296.520905 919.196655,297.452515 
	C919.507751,297.913330 919.618225,298.109375 919.851929,298.623596 
	C921.189148,303.315308 922.850952,307.553223 924.145386,312.249054 
	C924.498474,312.732178 924.627136,312.936584 924.913452,313.470337 
	C928.254395,324.247345 933.925110,333.772064 936.689636,344.745026 
	C936.923950,345.772156 936.917297,346.518707 936.900085,347.638428 
	C936.156555,350.880127 938.279114,352.486847 939.870239,354.697540 
	C940.039673,355.739838 940.050720,356.483002 940.069214,357.597778 
	C940.144531,360.160034 939.525452,362.399628 940.776489,364.760468 
	C941.016541,365.432892 941.023254,365.867615 941.028320,366.628357 
	C942.719604,371.567078 944.275879,376.209686 944.720520,381.153503 
	C945.041260,384.719177 946.991028,387.831879 947.907959,391.266327 
	C950.311157,400.268219 949.783325,409.998383 954.715576,418.706421 
	C954.986206,419.753357 954.994324,420.502625 954.999023,421.626587 
	C955.155273,423.828644 954.601318,425.723389 955.741455,427.742126 
	C956.810547,431.651855 957.240967,435.338745 957.043335,439.533417 
	C956.872559,441.550690 958.190369,442.794983 958.024658,444.690430 
	C958.040710,445.756317 958.040405,446.498016 958.040161,447.610535 
	C958.822510,453.766907 957.478516,459.652130 958.912842,465.424438 
	C959.262085,466.830170 959.098450,468.359314 960.554077,469.739105 
	C960.852966,471.082764 960.904053,472.139923 960.960083,473.594391 
	C960.921265,475.476471 961.238220,476.998169 960.294800,478.718597 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M917.208740,296.189331 
	C917.009766,295.988037 916.992615,295.502716 916.984741,295.260010 
	C915.375732,289.602051 912.611206,284.680389 910.257568,279.597809 
	C908.840942,276.538757 906.423645,273.968140 904.551025,271.157013 
	C901.923645,267.212799 901.621826,263.638214 902.819824,259.188995 
	C904.203308,259.007172 905.434082,258.880188 905.964172,260.633972 
	C906.193359,261.757721 906.273193,263.210907 906.530701,263.243103 
	C912.539917,263.994293 912.959473,270.335358 916.705200,273.330780 
	C918.813110,275.016418 917.809631,277.666138 917.990173,279.919189 
	C918.134949,281.726440 918.230530,283.537567 918.606323,285.660400 
	C919.523010,286.543365 920.155579,287.142303 921.097229,287.880798 
	C923.844543,287.819794 925.536133,289.377411 926.832214,291.016022 
	C928.739563,293.427551 930.767456,294.185791 933.757568,292.303772 
	C933.771362,297.597900 936.644592,302.067169 934.415527,307.305237 
	C933.637268,309.134003 934.950378,313.149719 936.635437,314.460480 
	C940.170227,317.210022 940.204895,321.457703 942.257263,324.766815 
	C944.587097,328.523224 942.998962,333.457855 940.911377,337.063385 
	C939.815063,338.956879 938.832458,341.008240 937.360840,342.969543 
	C934.438416,340.637268 933.910400,337.772217 935.166687,334.542145 
	C935.737732,333.073883 936.294983,331.890228 934.224487,330.908356 
	C929.822449,328.820801 926.679626,323.511719 928.507263,319.511322 
	C930.833435,314.419495 928.191467,313.922791 924.260620,313.233521 
	C923.995117,313.006104 924.000000,313.000000 923.996338,313.002319 
	C923.404358,312.388763 922.759827,311.555786 923.004272,310.828827 
	C924.670288,305.873657 921.632812,302.360809 919.214294,298.289551 
	C919.001282,297.998779 919.000000,298.000000 919.001099,297.999390 
	C918.466431,297.466949 917.938416,296.927490 917.208740,296.189331 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M923.995483,313.399719 
	C925.288696,312.774963 926.583313,312.345398 927.875732,312.351593 
	C931.149902,312.367279 932.803528,315.120331 930.807739,317.558136 
	C927.713623,321.337372 930.254822,324.686768 931.158081,328.139069 
	C931.541809,329.605347 932.849854,329.633453 934.403870,329.427917 
	C937.879761,328.968201 938.796082,332.031738 937.290649,333.684387 
	C934.416199,336.839996 936.062622,339.410797 936.989136,342.659943 
	C937.029480,343.470764 937.012573,343.915375 936.963135,344.692780 
	C936.204346,344.688904 934.826599,344.304871 934.847107,344.022461 
	C935.389160,336.562500 928.142822,331.928711 927.935425,324.766785 
	C927.817810,320.704529 923.731323,318.021484 923.995483,313.399719 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M940.277344,354.779663 
	C936.397888,353.863708 935.028076,351.390411 936.558594,348.072693 
	C937.071106,348.011963 937.252747,348.012329 937.706543,348.015930 
	C937.647217,350.659943 941.980835,351.484650 940.277344,354.779663 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M921.274475,287.714386 
	C920.256470,288.183075 919.191589,288.216858 918.892944,286.368347 
	C919.894714,286.006805 920.775635,286.288971 921.274475,287.714386 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M903.077393,258.672852 
	C903.313477,260.970245 904.419800,263.004578 903.182434,265.118958 
	C902.965515,265.489777 902.978210,266.454468 903.138916,266.519775 
	C907.547119,268.311127 905.552856,274.777618 910.297180,276.300385 
	C910.730469,276.439514 911.384949,276.891907 911.391418,277.209961 
	C911.518982,283.449829 918.180054,287.526031 916.985352,294.596741 
	C914.324646,294.529816 913.960571,291.833313 913.319275,290.174133 
	C911.866699,286.416107 908.576294,283.628784 907.989319,279.507294 
	C907.966309,279.345367 907.921997,279.070251 907.838196,279.049713 
	C902.387878,277.715118 901.540466,272.522949 898.777710,268.837738 
	C897.563721,267.218384 896.053650,266.138214 895.720642,263.866791 
	C895.400391,261.682648 893.918762,259.863403 892.049438,258.733643 
	C889.491577,257.187714 886.963623,255.672256 884.843872,253.521515 
	C882.676819,251.322769 880.132019,253.159546 877.381104,253.014191 
	C876.217468,252.766785 875.706238,252.231583 875.285583,251.145096 
	C875.273926,247.704971 877.634705,247.285583 879.898438,246.520615 
	C883.326782,245.362106 883.969177,242.590027 880.901733,240.844833 
	C876.751282,238.483475 876.192444,233.717575 873.032043,230.784012 
	C871.839233,229.676758 872.366882,227.961136 873.151672,226.580963 
	C874.075073,224.957367 874.613037,223.214859 874.707764,221.361832 
	C874.807434,219.412003 876.410645,217.314880 875.022522,215.583267 
	C873.565552,213.765869 871.646912,215.782639 869.901672,215.793716 
	C865.377441,215.822403 859.347168,210.929459 858.920349,205.799973 
	C858.453064,200.184677 856.893188,195.876282 851.234924,193.635117 
	C848.547058,192.570450 847.645630,189.855423 847.063904,186.802216 
	C847.621155,182.038635 848.742493,177.454468 842.977112,174.829437 
	C841.857605,173.824768 840.874146,173.202408 839.766113,172.180542 
	C839.386047,171.440140 839.325439,170.971619 839.459595,170.148468 
	C840.438721,168.275833 842.181152,167.788956 843.370178,166.639999 
	C844.112793,165.922424 844.765503,165.062927 844.255981,164.001053 
	C843.630493,162.697754 842.435181,162.723877 841.240845,163.084946 
	C840.270691,163.378250 839.480713,164.019150 838.645569,164.564346 
	C836.162720,166.185135 834.436890,165.654480 832.980164,163.003723 
	C830.712708,158.877518 825.047241,157.299164 820.726318,159.412445 
	C819.527344,159.998856 818.131836,161.246460 817.067200,160.733383 
	C810.947205,157.783752 805.484253,161.405624 799.388916,162.085236 
	C798.796509,161.953156 798.563599,161.877655 798.003662,161.629974 
	C794.793762,158.455536 790.111267,159.407272 786.860840,156.363464 
	C786.213623,155.391174 785.933411,154.666443 785.802368,153.486176 
	C785.835754,152.564331 785.950073,152.055588 786.312256,151.223511 
	C792.537781,146.513031 796.154907,147.248398 800.017761,153.676437 
	C800.738647,154.876068 801.489502,156.151154 803.092041,155.414764 
	C808.976624,152.710739 815.540466,153.044296 821.605286,151.153839 
	C824.166748,150.355423 826.434937,151.288391 828.688293,152.629959 
	C832.975525,155.182465 837.302429,156.701294 842.070740,153.274094 
	C844.802795,151.310486 847.090210,152.513947 848.533142,155.431885 
	C849.426025,157.237564 849.298584,159.364777 850.509827,161.444519 
	C850.757019,162.258438 850.815186,162.738571 850.768433,163.577728 
	C850.612427,164.561539 850.341125,165.758881 850.577820,165.754501 
	C854.311340,165.685333 857.318237,168.999222 861.552246,167.461990 
	C865.200134,166.137573 867.712280,169.191971 866.205322,172.932098 
	C864.786743,176.452927 863.710815,179.201492 868.859375,180.180756 
	C870.575562,180.507172 870.721252,182.192078 870.945923,183.743896 
	C871.867676,190.110397 873.390564,191.578278 880.739624,193.147110 
	C881.585022,193.128067 882.063782,193.196259 882.870483,193.473053 
	C885.148804,195.392090 886.274597,197.419327 885.163574,200.434570 
	C884.661621,201.653809 884.152344,202.574295 885.559937,203.522552 
	C889.294617,208.514603 888.608154,212.387497 883.473999,214.932892 
	C881.792236,215.766693 881.355835,216.807617 881.123047,218.680603 
	C878.978699,223.661163 882.437988,224.188568 885.520386,224.919571 
	C886.649536,225.187363 888.033386,225.117310 888.407471,226.438522 
	C888.819824,227.894836 887.366821,227.698486 886.566406,228.129990 
	C884.510620,229.238312 885.568970,230.395111 886.927856,230.742966 
	C889.378784,231.370361 890.805542,233.023041 892.367493,234.780090 
	C894.817871,237.536545 897.142151,240.040283 891.962402,242.321350 
	C891.089417,242.705826 891.004150,243.636581 891.162659,244.476028 
	C891.392883,245.695175 892.373291,246.034851 893.413269,245.932098 
	C894.549927,245.819794 895.657837,245.441528 896.788879,245.238297 
	C897.737854,245.067780 898.841492,244.704086 899.412109,245.844299 
	C899.875793,246.770996 899.095520,247.445099 898.479919,247.925552 
	C894.026794,251.401077 896.485535,252.873444 900.566650,254.031296 
	C901.114075,254.181610 901.293152,254.314529 901.639587,254.736359 
	C902.040833,255.863983 901.963745,256.796326 902.802795,257.747253 
	C903.045715,257.968506 903.070007,258.437927 903.077393,258.672852 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M901.192505,255.260666 
	C900.982971,255.015244 900.959412,254.531448 900.947266,254.290070 
	C901.042847,252.134460 898.811951,250.025574 901.720825,248.159653 
	C905.664124,249.959885 901.877014,252.918304 902.997986,255.609833 
	C902.273132,256.448944 901.823242,256.023468 901.192505,255.260666 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M900.992310,255.367920 
	C901.481689,255.271042 901.980469,255.526825 902.737183,255.893326 
	C903.008423,256.440735 903.021790,256.877472 903.040405,257.641357 
	C901.575867,257.936218 900.994751,257.095734 900.992310,255.367920 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M954.978516,418.616486 
	C950.171326,417.500519 951.312805,412.762421 950.287476,409.504486 
	C948.738892,404.584045 947.539978,399.309570 946.755554,394.378906 
	C945.321594,385.365204 941.659363,376.901367 941.017822,367.374146 
	C944.519409,370.360260 944.632812,375.105042 945.890137,379.385315 
	C946.104370,380.114594 945.414856,381.223816 945.755981,381.784241 
	C950.913879,390.257172 950.743958,399.916718 952.151672,409.248474 
	C952.614136,412.314362 954.010010,415.239441 954.978516,418.616486 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M960.857056,469.608704 
	C959.064758,469.989044 957.108887,469.733673 957.222778,467.584442 
	C957.558655,461.246979 956.152710,454.894257 957.744263,448.279968 
	C960.024170,448.971832 960.246643,450.759521 959.877441,452.596893 
	C959.067200,456.627777 959.457153,460.564819 960.710815,464.744141 
	C960.952881,466.395874 960.932495,467.793823 960.857056,469.608704 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M941.013062,364.581055 
	C937.482300,363.532867 939.227722,360.842590 939.787842,358.232056 
	C941.751221,359.827728 940.706604,362.098541 941.013062,364.581055 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M955.979553,427.600708 
	C952.805664,426.908569 954.066162,424.593323 954.727051,422.259277 
	C956.625977,423.484894 955.765137,425.444641 955.979553,427.600708 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M958.285339,444.799438 
	C956.850891,443.692505 956.167419,442.208282 956.805420,440.180176 
	C958.265198,441.283600 959.446167,442.597382 958.285339,444.799438 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M753.171021,956.849731 
	C748.385010,954.790100 743.579773,956.131348 738.432251,956.011353 
	C736.028870,950.443420 736.733093,948.690552 742.578247,945.015198 
	C744.195740,943.998169 746.758179,943.320801 745.093018,940.807495 
	C743.571045,938.510437 741.428589,938.195984 739.475037,940.774719 
	C738.559387,941.983643 737.154785,942.576294 735.294800,942.861816 
	C735.278931,938.671204 731.936707,941.321228 730.241089,940.244873 
	C729.985352,940.036621 729.995972,940.006897 729.980774,940.010742 
	C728.545166,938.601318 728.412903,936.729370 728.334229,934.923767 
	C728.239746,932.755127 729.514771,930.918457 731.594055,931.652649 
	C736.602356,933.420898 739.781494,930.211792 743.399597,928.059998 
	C744.391113,927.470276 745.254395,926.647583 746.265686,926.085449 
	C747.939392,925.155151 749.739441,924.642822 751.345642,926.116821 
	C753.000732,927.635559 753.471741,929.746155 752.278076,931.557800 
	C749.904114,935.160767 748.428955,938.661621 749.881104,943.136780 
	C750.518066,945.099792 748.872620,946.841431 747.232178,948.148804 
	C746.456360,948.767151 745.600830,949.556152 746.393738,950.633240 
	C747.099548,951.592041 748.196594,951.265320 749.144531,950.978027 
	C751.611938,950.230286 753.112732,948.009399 755.321289,946.857361 
	C755.928528,946.540588 756.570679,946.440308 757.553955,946.723389 
	C758.500549,950.585999 753.622375,951.650269 753.956726,955.548218 
	C753.851685,956.250732 753.665955,956.521973 753.171021,956.849731 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M752.220337,959.891296 
	C752.087769,959.667603 752.270752,959.401917 752.775818,959.102417 
	C752.898132,959.322815 752.710754,959.583069 752.220337,959.891296 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M757.937988,946.578857 
	C755.821228,947.132812 754.777161,948.773071 753.490967,950.031189 
	C751.901367,951.586182 750.168701,952.872803 747.874878,952.944824 
	C746.465393,952.989136 744.582947,953.467651 744.195679,951.392700 
	C743.875916,949.679199 743.416443,947.790344 746.017334,946.962952 
	C748.996033,946.015320 749.901062,943.527283 748.276855,941.077698 
	C746.339294,938.155579 747.160461,936.210999 749.266357,934.083313 
	C750.069397,933.271912 750.538208,932.139954 751.235229,931.211060 
	C752.354309,929.719727 751.831848,928.369995 750.920715,927.087646 
	C749.656433,925.308167 748.222412,926.362244 746.969849,927.060669 
	C744.534973,928.418396 742.553101,930.397766 740.087158,931.830444 
	C737.120972,933.553894 734.898682,934.975464 731.571350,932.778503 
	C729.922241,931.689697 729.274719,933.178894 729.442749,934.787781 
	C729.597046,936.265442 729.798401,937.738159 729.972290,939.613892 
	C729.223816,940.009766 728.481995,940.004944 727.368774,940.000061 
	C726.653870,939.689514 726.377075,939.328857 726.094788,938.577026 
	C725.903870,936.669495 726.333496,935.116638 726.218933,933.198853 
	C726.329285,931.885742 726.869751,931.157227 727.768921,930.249390 
	C732.009766,927.495178 735.363159,923.653931 741.164001,926.215942 
	C743.778381,927.370544 746.346558,925.823669 745.505676,921.983032 
	C744.659607,918.118225 746.388062,917.430237 749.608093,918.828308 
	C751.129211,919.488708 753.042053,920.826111 754.253967,919.603821 
	C758.792236,915.026855 761.104797,917.920715 763.661438,921.941895 
	C767.440063,927.003174 767.060303,929.767761 762.246582,933.698608 
	C761.753174,936.203674 763.562500,936.148010 765.255737,936.639404 
	C766.285583,936.829895 766.920898,937.088989 767.716309,937.786133 
	C768.928345,939.837524 769.750183,941.773743 767.833862,943.364563 
	C766.166931,944.748474 764.084717,945.825500 762.007080,944.288513 
	C759.183594,942.199768 758.770386,944.429382 757.937988,946.578857 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M789.506226,928.470581 
	C787.493469,929.571106 785.995605,930.179810 784.464722,930.688965 
	C783.016418,931.170776 781.354126,930.690002 781.452209,929.243042 
	C781.808594,923.983948 778.551758,925.486389 775.761108,925.868225 
	C774.797302,926.000061 773.812683,925.980225 772.434326,926.033997 
	C771.535583,925.352112 771.801147,924.720459 772.397461,923.832642 
	C773.323853,922.971863 774.156006,922.605957 775.267944,922.075928 
	C777.220093,921.574036 777.717041,920.050110 778.845581,918.697632 
	C779.712341,918.183960 780.364075,918.098145 781.375488,918.224731 
	C783.298401,919.088745 784.725586,919.014404 786.104614,917.245972 
	C788.725769,914.640442 789.379028,911.208191 791.814819,908.500732 
	C798.786987,907.188843 799.423340,908.527283 798.637024,914.811707 
	C797.862732,921.000671 792.706177,923.484131 789.506226,928.470581 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M760.320068,934.259521 
	C760.190369,933.576721 760.229858,932.750793 760.476562,932.683533 
	C766.385620,931.069397 765.187866,926.944214 764.020386,922.416504 
	C764.010498,922.012634 764.002014,922.001404 764.006958,922.005859 
	C764.410400,921.573364 764.857178,921.205078 765.717102,920.962524 
	C766.757019,920.921753 767.508972,920.933533 768.636353,920.967407 
	C769.333069,921.317993 769.554443,921.703857 769.697144,922.485107 
	C769.643127,923.720886 769.384216,924.579407 769.114258,925.774780 
	C766.922302,929.620728 768.140015,933.460022 767.984802,937.598022 
	C767.208923,938.007324 766.450745,937.988098 765.313599,937.945190 
	C764.123840,935.966125 762.748901,934.721558 760.320068,934.259521 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M789.267944,934.711914 
	C788.587891,935.713013 787.931274,936.311340 787.636292,937.053467 
	C785.671387,941.996155 784.527222,938.800415 783.115601,936.345459 
	C782.802734,935.865112 782.636353,935.686462 782.734985,935.255127 
	C785.109131,935.355469 786.751221,934.409363 788.616943,933.039551 
	C789.339172,933.366089 789.502625,933.834717 789.267944,934.711914 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M782.652283,934.843872 
	C782.997253,935.175903 782.994568,935.349365 782.980469,935.783325 
	C781.138428,935.818176 779.419128,936.262390 777.352295,936.944885 
	C777.183838,932.567688 779.842957,933.856567 782.652283,934.843872 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M769.432434,926.086914 
	C768.060059,925.369934 767.775879,924.484924 768.729858,923.188599 
	C770.272644,922.279785 771.438110,922.691772 772.802856,923.725220 
	C772.769287,924.520569 772.497803,925.014343 772.128662,925.773193 
	C771.288208,926.049194 770.545288,926.059998 769.432434,926.086914 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M790.684631,928.779419 
	C791.865540,929.538086 791.990845,930.395264 791.979675,931.645508 
	C791.981018,931.979919 791.994019,931.991638 791.987549,931.986450 
	C791.313171,932.574768 790.550232,932.729614 789.357361,932.245056 
	C789.010864,931.262451 789.005737,930.510193 789.003174,929.381958 
	C789.005798,929.005920 789.000000,929.000000 789.002869,929.002930 
	C789.387024,928.624512 789.844055,928.475586 790.684631,928.779419 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M789.004822,932.253540 
	C789.757385,932.005371 790.498718,931.996094 791.610596,931.984009 
	C791.955627,933.514893 791.283569,934.559448 789.408386,934.996277 
	C789.052673,934.586426 789.028137,934.124756 788.985779,933.316528 
	C788.967957,932.969971 788.993652,932.492371 789.004822,932.253540 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M773.609680,939.961304 
	C774.026001,941.116821 773.550720,942.031677 772.290710,942.853333 
	C770.259766,941.066040 772.013611,940.634460 773.609680,939.961304 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M808.021240,907.235657 
	C809.616699,907.145874 811.009766,907.670349 812.774109,907.360046 
	C815.467285,906.886292 816.873169,908.152893 814.426758,910.984009 
	C813.628723,911.113037 813.165588,911.112732 812.360229,911.001465 
	C811.260193,910.948914 810.502319,910.960693 809.364990,910.963379 
	C807.929626,910.024963 807.227356,908.991821 808.021240,907.235657 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M812.052368,911.262268 
	C812.492004,910.223999 813.058716,910.019226 813.931885,910.842163 
	C814.069092,911.500427 814.046265,911.933838 813.999023,912.692627 
	C813.055847,912.923218 812.287354,912.629944 812.052368,911.262268 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M791.028687,928.681030 
	C790.550903,929.004639 790.109436,929.009216 789.336792,929.009827 
	C789.137695,927.272156 789.706848,925.404785 791.127380,924.680603 
	C796.523132,921.929810 796.562073,916.308044 797.857117,911.712219 
	C799.125061,907.212280 794.612671,909.876465 792.464722,908.966064 
	C791.405640,908.428894 791.221375,907.710083 791.342957,906.530518 
	C792.073547,904.974121 793.021423,904.163696 794.676514,903.877686 
	C800.318115,908.932800 799.608704,908.990784 800.212524,916.374573 
	C800.697083,922.299988 795.558228,922.394104 793.148438,925.676880 
	C791.960449,926.463867 791.249451,927.199829 791.028687,928.681030 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M805.538086,887.991638 
	C807.886902,889.500061 808.192810,892.352478 810.710327,893.567322 
	C812.323608,894.345703 812.355347,895.932434 810.678955,897.145020 
	C809.232422,898.191345 808.438782,900.083069 806.212769,900.668579 
	C805.834839,901.019348 805.685852,901.123596 805.307800,901.079590 
	C804.143311,899.813232 803.207764,898.695129 802.123413,897.301514 
	C800.554749,895.459595 798.399719,895.665161 796.383789,894.660828 
	C795.936646,894.324707 795.782715,894.156250 795.519653,893.659912 
	C795.710022,890.172607 797.946167,889.696777 800.289795,890.356567 
	C802.883728,891.086792 804.159912,890.001709 805.538086,887.991638 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M795.062012,903.698669 
	C794.254761,904.642212 793.467163,905.293335 792.370728,905.995239 
	C791.860779,905.868958 791.659729,905.691956 791.273376,905.158142 
	C790.752808,903.754333 790.819336,902.702637 791.159302,901.276367 
	C792.188904,899.438721 792.033447,897.784363 792.138000,896.164429 
	C792.260071,894.271973 793.027649,893.016235 795.548096,893.654480 
	C795.863892,893.857788 795.929626,893.926331 795.962402,893.960693 
	C797.899475,896.271362 796.973389,898.755432 796.249695,901.589478 
	C795.677307,902.451721 795.379456,902.928955 795.062012,903.698669 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M804.697083,900.974182 
	C805.078857,900.931335 805.531616,900.940674 805.757690,900.927856 
	C806.630127,902.370728 807.276550,903.826416 807.946289,905.628052 
	C805.734985,906.662109 803.460327,907.237610 802.333252,904.359009 
	C801.636841,902.580261 801.982971,901.138062 804.697083,900.974182 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M796.346802,901.909668 
	C795.982056,899.610779 795.988892,897.247009 795.995422,894.439209 
	C798.245422,894.024658 800.572876,893.858337 801.922363,896.667236 
	C797.474731,895.635010 798.710632,900.500671 796.346802,901.909668 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M795.953369,893.512207 
	C793.952454,894.017517 792.550903,894.530762 793.462585,896.939514 
	C793.991333,898.336365 794.574829,899.820068 792.443848,900.938416 
	C787.877930,899.590088 787.464355,898.334106 789.888428,893.234497 
	C790.773132,892.468384 791.469543,892.225403 792.600220,892.132202 
	C795.165710,889.913208 798.100525,888.134521 795.403137,884.383179 
	C794.760132,883.488892 795.341309,882.038025 796.453308,882.221802 
	C800.268005,882.851990 804.562744,880.729858 807.988159,884.446533 
	C806.744080,885.077087 805.825134,885.738831 805.902954,887.643311 
	C806.297668,892.527771 804.167358,893.300476 800.460510,891.665100 
	C798.496765,890.798828 796.910034,890.883118 795.953369,893.512207 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M941.383423,656.064941 
	C938.522705,652.755798 940.677063,649.437378 941.582214,646.645081 
	C942.756714,643.021667 943.602722,639.458923 944.032288,635.711853 
	C944.702881,629.862366 946.022156,624.189941 949.626587,619.067993 
	C950.517334,619.533264 951.059692,620.068481 951.786377,620.823120 
	C951.658813,622.765625 951.334961,624.500488 951.007446,626.617676 
	C951.155884,627.296387 951.414978,627.653809 951.324402,627.912048 
	C948.036987,637.282593 947.007629,647.418579 941.383423,656.064941 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M940.988037,656.336182 
	C942.585754,650.462769 945.389709,645.384338 946.213318,639.489380 
	C946.782288,635.416321 949.145203,631.593872 950.855103,627.329712 
	C952.418640,627.169312 953.150513,627.899597 952.720337,629.351074 
	C952.209167,631.076111 950.753479,633.006287 951.160583,634.432678 
	C952.762573,640.045349 946.561768,644.006653 948.330200,649.427917 
	C948.911133,651.208740 948.003723,651.534912 946.757324,652.333252 
	C945.007935,653.453735 943.340515,654.930115 943.010620,657.636963 
	C942.184631,658.913635 941.366882,659.822388 940.267822,660.860596 
	C939.673584,660.583496 939.523438,660.126709 939.771118,659.302307 
	C940.853516,658.452209 940.979492,657.588013 940.988037,656.336182 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M956.302246,588.767029 
	C954.573120,585.173828 955.969360,581.294128 956.259094,577.527893 
	C956.825012,570.171326 958.321045,562.886292 959.709229,555.288269 
	C961.672241,556.647766 961.004639,558.711731 960.855042,560.599609 
	C960.323059,567.314148 958.630554,573.949402 959.078186,580.716919 
	C959.282532,583.805969 957.410278,585.930542 956.302246,588.767029 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M952.257202,615.702881 
	C949.827332,614.234253 951.073120,611.690613 951.332886,609.990662 
	C952.249695,603.990723 951.942932,597.640137 955.635010,592.079224 
	C956.385864,600.032349 952.027588,607.365967 952.257202,615.702881 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M959.981079,551.667236 
	C956.298767,550.828186 958.815552,549.016785 959.766602,547.190979 
	C960.215942,546.869568 960.390747,546.751404 960.826538,546.812256 
	C961.065430,547.437317 961.043335,547.883179 961.019104,548.663940 
	C960.122131,549.532654 960.016602,550.412048 959.981079,551.667236 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M974.025696,585.622681 
	C973.600647,585.979553 973.143127,585.989502 972.342773,585.999756 
	C972.133179,584.833679 972.266296,583.667419 972.636169,582.243896 
	C973.246277,583.083008 973.619690,584.179382 974.025696,585.622681 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M951.988647,620.684814 
	C951.087524,620.918762 950.309937,620.647949 950.033569,619.310303 
	C950.424194,618.986328 950.867859,618.980042 951.643555,618.964111 
	C951.981934,619.415894 951.988342,619.877441 951.988647,620.684814 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M960.342529,544.252075 
	C960.414856,532.780457 959.088745,521.477173 960.917053,510.227478 
	C961.356384,507.524597 961.567383,504.535004 958.977417,502.369415 
	C958.174744,501.698303 957.624023,500.299103 958.359863,499.703827 
	C962.110596,496.669586 960.376099,492.252380 961.721558,488.284119 
	C963.452698,487.346924 964.161194,488.299713 964.927979,489.654236 
	C965.018127,490.435699 965.022583,490.874664 965.047363,491.641846 
	C972.321106,502.968689 968.561523,515.194458 968.969177,526.956421 
	C969.046387,529.183655 965.849548,530.749146 967.839966,533.659424 
	C967.985352,533.993652 967.958008,534.466125 967.954102,534.702759 
	C965.803589,536.635864 967.077454,540.075562 964.284424,541.794189 
	C963.876831,542.308350 963.678772,542.558044 963.199219,542.868164 
	C962.441956,543.841614 961.888855,544.687134 960.342529,544.252075 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M964.374451,541.998047 
	C965.807861,540.032532 963.711121,536.244812 967.591858,535.007263 
	C968.254395,537.914673 969.970947,541.532166 964.374451,541.998047 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M960.030884,544.415283 
	C960.820740,543.777588 961.612244,543.506836 962.695435,543.109863 
	C962.826599,544.346741 963.539673,545.983398 961.432983,546.918457 
	C960.913025,546.990845 960.738464,546.990356 960.302490,546.988770 
	C960.038208,546.252625 960.035339,545.517517 960.030884,544.415283 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M965.251221,489.784851 
	C964.193298,489.415710 963.372864,488.834747 962.277466,488.126892 
	C961.992065,487.565979 961.981628,487.131989 961.962463,486.373291 
	C964.441345,485.910156 965.810913,486.893158 965.251221,489.784851 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M960.419189,478.942200 
	C960.198120,477.522827 960.319336,476.021667 960.702820,474.256104 
	C962.364380,475.675964 962.644958,477.313446 960.419189,478.942200 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M961.961182,485.690186 
	C960.518738,485.900482 959.975220,485.035126 960.050049,483.329498 
	C961.504272,483.117767 962.020691,483.999481 961.961182,485.690186 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M897.064697,864.660950 
	C896.006042,865.453491 894.942749,866.240479 895.199890,867.164734 
	C896.981201,873.567139 893.331787,872.229553 889.378845,871.044434 
	C888.649475,869.260132 891.562866,867.910095 890.218384,866.642822 
	C888.804199,865.309753 886.267517,865.640564 884.340088,864.555725 
	C883.311646,863.976929 883.166260,865.682312 882.903503,866.585388 
	C882.071960,869.442261 879.904175,869.263123 877.733154,868.664490 
	C876.755737,868.394958 875.762024,867.267639 876.767212,866.467712 
	C880.289062,863.665161 882.843445,859.638611 887.678467,858.032593 
	C889.982178,858.947632 891.839050,859.054749 893.808105,857.316895 
	C894.562134,856.829346 895.050354,856.550354 895.796631,856.159790 
	C897.661682,854.718994 899.432678,854.854980 901.637817,855.723145 
	C902.320557,856.112488 902.571045,856.309875 902.889160,856.782227 
	C904.267456,858.853394 902.996887,859.722107 901.288452,860.739746 
	C898.804321,861.084778 898.146790,862.868347 897.064697,864.660950 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M893.716431,857.010254 
	C892.816589,860.315735 891.311584,862.600220 888.098511,858.369751 
	C888.049683,857.282410 888.049011,856.524231 888.045898,855.387573 
	C888.843323,851.732788 892.567993,849.660217 891.914062,845.423462 
	C892.049377,844.873169 892.121155,844.658752 892.350830,844.146790 
	C893.182678,842.974854 893.990540,842.231201 895.400024,841.244507 
	C897.115051,840.665344 898.236694,840.337280 899.717285,840.012695 
	C901.898682,843.430664 901.456909,845.706482 897.069153,846.005920 
	C896.742371,846.028259 896.353760,845.994324 896.106567,846.157349 
	C895.585815,846.500732 895.139709,846.957458 894.663025,847.367676 
	C900.167419,851.360596 888.650696,853.028015 893.716431,857.010254 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M901.935974,855.617493 
	C900.312988,855.996643 898.607056,856.013306 896.477905,856.039062 
	C897.586365,852.851624 899.626587,853.372498 901.935974,855.617493 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M901.309692,860.960327 
	C901.618164,859.834595 902.272949,858.736206 902.967896,857.315002 
	C905.150879,857.172424 905.011414,858.751953 905.002686,860.626099 
	C904.564575,861.012329 904.129150,861.022217 903.368408,861.053345 
	C902.580750,861.045654 902.118347,861.016663 901.309692,860.960327 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M902.166077,838.275269 
	C902.453491,837.703247 902.907776,837.362122 903.656189,836.980591 
	C903.961182,837.118408 903.972046,837.296509 903.994507,837.742188 
	C904.006042,838.009766 903.975830,838.472900 903.958374,838.703674 
	C903.404907,838.791687 902.868958,838.648926 902.166077,838.275269 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M728.965332,945.406799 
	C729.353943,944.948608 729.609741,944.763184 729.928528,944.297729 
	C730.937012,943.191223 731.989624,942.953430 733.541016,943.582520 
	C734.019165,945.800354 734.353699,947.841248 731.419617,948.089783 
	C728.661072,948.323425 729.439453,951.608276 727.286499,952.839844 
	C723.238464,949.436218 727.075134,947.859924 728.965332,945.406799 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M734.028076,943.632446 
	C732.865601,943.900574 731.839294,943.970093 730.427368,944.063293 
	C730.025513,943.005310 730.009338,941.923584 729.989258,940.439209 
	C732.357910,939.458557 734.730530,938.880493 737.372742,938.236755 
	C737.525024,940.406372 734.379700,940.164856 734.955505,942.510132 
	C734.638428,942.997742 734.383545,943.192505 734.028076,943.632446 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M730.115112,952.818848 
	C730.912598,950.548279 732.581482,950.658020 733.832703,951.330627 
	C735.917297,952.451355 734.696838,954.453003 734.921997,956.290955 
	C732.566650,955.917603 730.683411,955.539124 730.115112,952.818848 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M728.140686,956.862549 
	C727.006592,956.832764 725.961426,956.753479 726.746277,955.178467 
	C727.868225,955.198059 729.078979,955.141479 728.140686,956.862549 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M939.588867,659.003662 
	C939.984192,659.436646 939.987305,659.880615 939.988525,660.657288 
	C939.970154,661.737061 939.953674,662.484131 939.934082,663.604614 
	C939.842896,664.186279 939.754883,664.394531 939.462891,664.871460 
	C937.673157,667.541016 937.496460,670.117981 938.392944,673.143738 
	C938.519226,673.692871 938.551697,673.915649 938.564575,674.473572 
	C938.299438,675.853821 937.690857,676.812256 937.797729,678.272339 
	C937.761719,679.052429 937.662842,679.488037 937.342651,680.209167 
	C936.423584,681.493103 934.934448,682.400330 935.756714,683.788086 
	C938.285522,688.056152 935.282898,689.491577 932.066650,691.266235 
	C931.517700,691.841309 931.201538,692.168762 930.655457,692.753845 
	C927.959595,695.647278 926.378357,698.610229 926.844910,702.379028 
	C927.012878,703.735840 926.777527,705.084290 925.876282,706.134094 
	C921.845642,710.829102 920.880371,717.234375 916.487915,722.497070 
	C911.850342,729.186890 905.604614,733.715149 902.142883,740.406555 
	C901.360840,741.918335 900.230591,743.043152 899.149780,744.686157 
	C898.313477,745.577148 897.546082,745.728577 896.351685,745.241577 
	C895.880249,741.744385 894.271912,737.897461 899.294189,736.658142 
	C897.842346,730.191223 902.928162,728.771240 906.901489,726.065552 
	C908.339905,725.086060 909.987610,723.711060 911.305603,722.237732 
	C913.798096,719.451477 916.614136,717.037231 913.085144,712.905090 
	C911.259277,710.767151 913.680542,709.786865 915.276062,709.109802 
	C916.962280,708.394348 918.805298,707.994019 919.819580,706.275146 
	C921.418457,703.565308 921.750427,701.095703 917.914429,700.196838 
	C915.834412,699.709534 913.631531,699.356689 914.188293,696.653809 
	C914.714783,694.098206 916.700073,694.407288 918.925781,694.737549 
	C923.355591,695.394958 925.158325,693.241211 924.338074,688.654236 
	C924.007324,686.804688 924.530640,686.122803 926.096008,684.804565 
	C929.934875,681.571899 932.367432,677.427612 932.672363,671.865295 
	C932.866821,668.317993 933.446350,665.228638 931.289856,662.041504 
	C930.484863,660.851868 930.881836,658.765869 933.358643,659.688782 
	C935.487244,660.481873 937.234070,659.041321 939.588867,659.003662 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M929.790283,692.272095 
	C930.104065,691.677185 930.291504,691.419434 930.771118,691.105713 
	C933.007141,689.322693 936.739929,690.606445 937.648682,686.832520 
	C938.214111,684.484436 936.699158,682.700500 936.822510,680.274658 
	C936.972839,679.531250 936.973877,679.095886 936.977295,678.333862 
	C936.973145,677.121582 937.251587,676.305481 937.795776,675.252197 
	C937.999573,674.833313 937.999084,674.666687 938.000488,674.250000 
	C939.050415,670.899780 937.106995,667.634155 938.758728,664.268188 
	C938.992676,663.981995 939.462646,663.973877 939.696777,663.975952 
	C943.043457,667.133362 944.078857,670.782349 941.539673,674.601746 
	C939.910400,677.052368 940.229553,678.769043 941.385681,681.366272 
	C943.462524,686.031616 943.551636,690.421570 939.554260,695.047668 
	C936.201660,698.927673 935.033813,704.560120 932.277649,709.136841 
	C931.410217,710.577148 933.427551,711.663025 934.246277,712.852112 
	C936.847290,716.629883 935.713318,718.929871 931.511536,720.795227 
	C925.952271,723.263123 920.046143,725.546570 918.128113,732.501953 
	C917.369873,735.251587 915.017761,737.134277 912.303101,738.850098 
	C911.186218,736.558044 911.611755,734.626831 913.146362,732.309509 
	C914.748169,729.890747 915.093445,726.639771 915.995239,723.378784 
	C916.002441,723.004883 916.000000,723.000000 916.002441,723.001221 
	C917.709656,720.118774 920.929688,719.980591 923.637695,718.986572 
	C924.066711,716.810608 920.849854,716.437378 921.611938,714.226501 
	C922.258728,712.349976 923.873840,711.464600 925.169495,710.216309 
	C927.098206,708.358215 927.942993,705.746338 929.489563,703.606262 
	C929.989014,702.915161 930.021240,701.894287 929.328125,701.311584 
	C925.683228,698.247864 928.003174,695.423401 929.790283,692.272095 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M909.321838,744.958801 
	C909.085388,744.102844 909.340759,743.322754 910.687378,743.047729 
	C910.919800,743.903076 910.657593,744.677673 909.321838,744.958801 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M857.302124,869.255981 
	C856.085449,866.218140 853.686951,862.458618 859.997192,864.477661 
	C860.863770,864.754944 861.828003,864.788452 862.216553,863.972473 
	C864.510437,859.155457 868.007446,860.849487 871.410767,862.214966 
	C872.599426,862.691956 873.647583,862.972412 874.468689,861.734436 
	C875.237427,860.575562 874.930847,859.415833 873.992615,858.586060 
	C871.484680,856.368042 872.814514,855.187927 874.549805,853.040405 
	C877.299438,849.637390 878.460388,845.120178 884.562439,845.873352 
	C886.399963,846.100220 886.759277,842.057861 886.839172,839.890198 
	C887.057129,833.974426 888.422974,832.082458 894.590942,830.983093 
	C895.184631,830.867554 895.393494,830.754578 895.910034,830.819580 
	C897.085876,833.599426 894.849121,833.924011 893.403809,834.813171 
	C892.313660,835.483765 890.291870,836.005676 890.788757,837.350891 
	C893.169006,843.795166 888.005188,847.286255 885.533936,851.835205 
	C885.219055,852.414917 884.933472,853.002014 884.247681,853.747864 
	C883.747437,854.496033 883.482666,854.987244 883.105591,855.734375 
	C882.499329,856.598938 881.740723,856.889465 881.108643,857.732788 
	C877.105164,863.106323 873.468750,868.559265 866.136414,869.026306 
	C865.255615,869.082397 864.546936,869.382446 864.098022,870.622437 
	C861.422241,872.461792 859.539124,870.861023 857.302124,869.255981 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M884.382812,854.003662 
	C883.640503,849.291809 888.320068,847.146667 889.535095,843.200195 
	C889.899048,842.018005 891.465820,841.025513 890.096375,839.725159 
	C886.294373,836.114746 889.713684,835.281616 892.159607,833.884277 
	C893.404236,833.173279 895.224426,833.398132 895.938171,831.346313 
	C895.455688,830.013855 896.072449,829.273987 896.769409,828.253601 
	C897.008606,828.006775 897.498108,827.996033 897.742920,827.992798 
	C898.707336,827.259277 899.526917,826.610107 899.965698,825.307007 
	C900.019836,823.944580 900.024170,822.886902 900.032593,821.434021 
	C900.767822,821.025146 901.499084,821.011475 902.595581,820.994324 
	C905.164917,824.961426 902.406189,829.175720 903.530151,833.308167 
	C903.845398,834.467224 902.036865,835.901672 899.866516,835.489807 
	C895.931641,834.742920 894.748657,836.650085 895.954590,840.645508 
	C895.993347,840.993347 896.000000,841.000000 895.997437,840.997070 
	C894.901733,841.610291 893.972473,842.389282 893.231018,843.791382 
	C893.021606,844.019470 893.000000,844.000000 893.010315,844.010803 
	C892.666931,844.346924 892.012024,844.746399 892.078491,845.055969 
	C892.971069,849.210571 889.950073,851.261536 887.270752,853.826416 
	C886.236816,853.996582 885.495178,853.999451 884.382812,854.003662 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M888.644043,871.032532 
	C887.985962,876.763672 880.150513,881.708069 872.663574,881.274414 
	C871.680237,881.217468 870.703186,881.052002 869.336914,880.882874 
	C872.740356,878.886841 875.852295,875.281555 880.639465,875.770813 
	C884.475159,876.162781 885.176941,871.697388 888.644043,871.032532 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M857.014038,869.396484 
	C859.386047,868.698425 861.156982,870.386719 863.647339,870.955933 
	C864.012207,870.995117 864.034302,871.489380 864.040039,871.736694 
	C864.615479,874.153442 865.948303,876.269226 864.297363,878.898743 
	C863.627991,879.497681 863.139465,879.573730 862.309570,879.219910 
	C859.939941,877.186218 858.871216,878.338562 858.031921,880.647827 
	C856.320862,880.858398 854.176819,881.575745 854.016724,878.374084 
	C860.896912,877.425049 857.010132,872.894836 857.014038,869.396484 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M864.228210,879.218018 
	C863.834961,876.785156 863.684143,874.615417 863.789612,872.214844 
	C865.009338,872.589844 865.960205,873.217041 866.939270,873.796570 
	C868.356934,874.635925 871.158630,872.834412 871.373901,875.360168 
	C871.548279,877.405151 868.222107,876.634521 867.171692,878.653076 
	C866.559204,878.961609 866.112671,878.970093 865.331299,878.987183 
	C864.821167,879.157593 864.645874,879.319397 864.228210,879.218018 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M887.102661,854.213623 
	C887.134766,853.211731 887.019958,852.048218 887.493042,851.712097 
	C889.875244,850.019470 891.928101,848.426270 890.373779,844.976685 
	C890.094727,844.357117 891.496460,844.082336 892.662048,844.017334 
	C893.838379,848.526306 892.059143,852.004822 888.388184,854.940552 
	C887.771179,854.817261 887.499023,854.625366 887.102661,854.213623 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M881.316040,857.953796 
	C879.538086,856.044678 881.180115,856.184448 882.669922,855.996582 
	C882.914673,856.894653 882.661377,857.675293 881.316040,857.953796 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M863.655090,878.970337 
	C864.154236,878.963440 864.322632,878.971985 864.743652,878.988159 
	C864.739197,879.479492 864.481995,879.963257 864.119629,880.699463 
	C863.559326,880.963867 863.104126,880.976013 862.307983,880.959473 
	C861.976562,880.500732 861.986084,880.070679 861.997803,879.317261 
	C862.441467,878.991150 862.882935,878.988464 863.655090,878.970337 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M811.245728,875.740479 
	C810.548950,875.980103 810.107666,875.979187 809.335449,875.973511 
	C805.384277,875.267151 801.929688,874.493347 799.734192,870.912109 
	C798.337219,868.633179 796.912109,869.050110 796.102844,871.711060 
	C794.393921,877.329834 794.337769,877.312744 788.218384,875.654236 
	C786.872131,874.548584 786.100464,873.289185 784.367004,872.610840 
	C782.282288,867.333618 787.228516,859.108643 792.506592,859.354736 
	C798.296509,859.624695 803.984314,856.960571 809.812073,859.112732 
	C811.651001,859.791809 813.249756,858.350342 814.802490,857.333374 
	C817.746948,855.404602 820.418457,855.545166 822.538391,859.086060 
	C822.028809,861.518921 821.363464,863.542114 820.851318,865.613586 
	C820.448364,867.243835 819.309143,869.558594 817.962402,869.204407 
	C811.895935,867.608704 813.392395,873.194702 811.245728,875.740479 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M787.606201,874.948975 
	C788.988403,875.044495 790.051086,874.887146 790.967407,875.183044 
	C793.764038,876.085999 795.439087,875.610229 794.568665,872.288391 
	C793.916504,869.799377 795.408447,868.158997 796.998718,867.419800 
	C799.057007,866.462891 800.522888,868.509583 800.986938,870.196716 
	C802.143982,874.403748 806.599060,873.033203 808.925171,875.611877 
	C807.887085,877.378357 806.088806,877.420349 804.918823,876.867432 
	C799.984863,874.535828 796.901184,878.056091 793.307190,880.102600 
	C792.500183,880.562073 791.712036,881.406799 792.118713,881.995178 
	C794.302307,885.153503 791.909912,888.364441 792.688599,891.741821 
	C792.483521,892.294250 792.028625,892.607788 791.283569,892.945312 
	C790.199585,893.014404 789.471008,892.829773 788.580261,892.058289 
	C787.842102,890.913330 787.839661,889.731934 787.201294,889.095276 
	C783.381836,885.286011 783.666321,881.109619 785.981201,876.329773 
	C786.389587,875.511902 786.753784,875.155823 787.606201,874.948975 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M811.348145,876.143677 
	C810.426025,873.510986 812.244507,871.308472 811.567810,868.680115 
	C811.018677,866.546875 813.275757,866.727600 814.211609,867.325562 
	C819.082764,870.437866 819.304016,866.675903 819.964172,863.587891 
	C820.226990,862.358398 819.654175,860.853577 821.646545,860.071289 
	C824.004028,858.646545 825.343506,860.187561 827.115601,861.321289 
	C829.670410,865.826660 832.519287,869.458008 837.129333,871.479858 
	C838.622742,872.134827 839.760803,873.454651 839.209351,875.705139 
	C835.660217,877.238831 834.637573,872.839478 831.351929,873.027710 
	C829.915649,873.023499 828.860718,873.015869 827.410034,873.013611 
	C825.180969,871.649597 822.671509,870.518250 825.301331,867.684753 
	C825.903381,867.036194 826.121948,866.179443 825.307251,865.510010 
	C824.560608,864.896606 823.328857,865.026550 823.120850,865.696167 
	C822.147156,868.831909 815.415344,870.245605 820.386597,875.288330 
	C821.775635,876.697205 820.618469,878.084778 818.459106,877.671082 
	C816.203735,877.238953 813.956787,876.763123 811.348145,876.143677 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M838.997559,876.002441 
	C838.833801,873.939331 837.612427,872.732422 835.758545,872.074524 
	C831.227783,870.466858 828.414795,867.278015 827.037598,862.358459 
	C832.217957,859.647766 837.362488,857.420776 842.774109,861.781189 
	C844.718201,863.347656 846.187012,862.504211 846.431091,859.938721 
	C846.526001,858.941833 846.515442,857.941956 846.537476,856.942627 
	C846.717834,848.776733 847.612488,847.575134 855.930237,844.727234 
	C859.590881,845.084167 861.038147,842.057983 863.420959,840.780945 
	C868.087463,838.279785 871.560913,834.165039 875.843201,831.104797 
	C877.946838,829.601501 878.358826,826.967957 879.135559,824.618286 
	C879.804382,822.594971 880.645447,820.608215 882.572998,818.890137 
	C884.795166,818.549255 885.770325,819.585693 886.113892,821.651489 
	C883.750061,827.827209 882.234436,834.031311 875.519226,836.811218 
	C873.003845,837.852600 872.369568,840.279541 871.363342,843.067261 
	C869.853333,847.250793 865.007446,850.207031 865.091064,855.602966 
	C860.607239,855.382324 858.648193,860.058411 854.738220,860.942444 
	C852.810608,861.378174 851.389893,862.801331 852.189819,864.472168 
	C854.319763,868.921143 851.739197,871.525146 849.034302,874.616699 
	C848.545532,874.980469 848.107056,874.990295 847.339539,875.004517 
	C844.925781,877.172546 842.567688,878.040100 839.353516,876.276672 
	C838.995117,876.004883 839.000000,876.000000 838.997559,876.002441 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M839.006714,876.371094 
	C841.442932,876.030396 843.903442,876.157898 846.613464,875.056274 
	C846.560425,877.435852 845.191772,879.363220 842.765503,879.530762 
	C841.285645,879.632935 839.043457,879.287720 839.006714,876.371094 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M617.602661,29.014256 
	C617.137390,31.702168 615.800293,31.655996 614.062195,29.348972 
	C615.062378,29.007793 616.132019,29.007492 617.602661,29.014256 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M732.627075,92.958382 
	C732.976318,94.400726 732.970459,95.800781 732.948853,97.620560 
	C730.722595,99.743408 728.855286,101.581543 728.144653,104.521759 
	C727.497375,107.199516 725.739380,109.128250 722.342285,109.157944 
	C719.537415,105.687164 716.610962,104.874222 712.200806,104.558670 
	C706.234924,104.131805 699.277466,102.820770 697.151733,94.884506 
	C696.288879,91.663330 692.891663,90.859207 689.962585,90.210678 
	C688.171387,89.814064 686.556213,89.262573 686.344788,87.372421 
	C685.517761,79.980492 681.092224,75.448662 674.988220,71.872002 
	C672.578369,70.459953 671.855530,67.052483 669.055664,65.365051 
	C672.485962,62.948452 677.004089,65.134933 680.665649,62.147102 
	C682.073730,61.991291 683.137634,61.995625 684.600830,62.000343 
	C686.514771,62.390896 687.615234,63.382687 688.583740,64.532349 
	C689.846008,66.030785 690.497681,67.764503 689.400940,69.541626 
	C688.319641,71.293739 686.562622,70.805382 684.924438,70.391632 
	C683.513184,70.035194 682.136719,69.258026 679.903503,70.258545 
	C683.110291,72.213150 685.871643,74.108437 687.489380,77.291336 
	C688.751648,79.774940 689.904358,78.132828 691.072693,76.719078 
	C694.216187,72.914993 695.946533,73.057564 699.103882,77.470901 
	C699.356384,78.740433 699.352844,79.679497 699.402710,80.962967 
	C699.713501,82.592407 698.729797,84.704338 701.742737,84.275284 
	C702.253906,84.029221 702.460693,83.926941 702.992065,83.688980 
	C707.067444,81.148689 710.682434,77.940773 715.724731,81.747421 
	C716.869934,82.450424 718.000122,82.698967 718.551819,83.401649 
	C722.161255,87.998993 727.440247,90.124046 732.627075,92.958382 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M665.844971,64.703568 
	C665.151062,66.085693 664.233643,65.797180 663.526367,64.952927 
	C661.479919,62.510132 659.447815,60.469254 655.733582,60.805630 
	C653.572449,61.001350 653.642883,58.162823 652.499023,56.841248 
	C650.482605,54.511547 648.734192,51.872860 648.000000,48.380913 
	C651.063721,49.661743 656.056641,49.590553 659.944153,48.272522 
	C662.917908,47.264301 665.563965,48.214760 667.255676,50.953102 
	C668.713501,53.312740 668.174744,55.266506 665.780334,56.836639 
	C662.109985,59.243435 662.748718,61.839867 665.844971,64.703568 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M715.965454,81.638290 
	C711.224304,80.093071 707.329407,81.920349 703.360840,84.964233 
	C701.395935,82.904953 702.248901,79.112030 698.267517,78.240273 
	C697.827759,76.036583 696.789795,74.969673 694.751831,75.089989 
	C692.928833,75.197601 692.182861,76.368790 691.993042,78.046364 
	C691.830078,79.486336 691.247498,80.736298 689.642334,80.783981 
	C688.083496,80.830292 686.496277,80.241631 686.179871,78.547928 
	C685.461548,74.702904 682.734253,73.100227 679.457642,71.987206 
	C678.294312,71.592033 677.119873,71.094086 677.476013,69.628136 
	C677.797668,68.304016 679.047424,68.100288 680.263306,68.013489 
	C681.968201,67.891785 683.542908,68.231537 685.074768,69.004936 
	C686.211182,69.578621 687.625488,70.629219 688.600281,69.080566 
	C689.513977,67.628960 688.711914,66.218239 687.539795,65.092155 
	C686.719604,64.304207 685.923889,63.490814 685.058594,62.344749 
	C690.372437,60.070160 691.295471,60.576439 692.315552,66.013168 
	C693.023987,69.789185 697.050049,73.318466 701.132751,72.905708 
	C706.221436,72.391243 709.832092,76.734787 714.890259,76.476578 
	C716.194763,76.409981 714.725403,79.781555 715.965454,81.638290 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M724.354492,71.025146 
	C723.132996,69.012932 719.352722,71.183296 719.645691,68.580009 
	C719.877441,66.520821 722.101196,64.947540 724.631287,64.028923 
	C726.392273,66.018631 725.026184,70.234619 729.581604,69.912895 
	C729.675964,69.906219 729.877258,71.412132 730.040405,72.578621 
	C729.298157,72.957619 728.544189,72.970406 727.415100,72.973648 
	C726.885437,71.565201 726.046631,70.991096 724.354492,71.025146 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M372.284302,99.259933 
	C374.297699,94.352310 379.805542,96.503845 383.180237,93.593483 
	C388.485260,89.018356 395.795502,88.473289 402.088409,85.812622 
	C406.008392,84.155258 411.007812,86.191154 415.496277,84.152519 
	C425.801270,79.472076 437.198029,77.670898 448.047607,76.119873 
	C460.888489,74.284172 472.315704,69.382248 484.248749,65.445267 
	C485.962799,64.879753 487.196991,65.547508 488.460327,66.248245 
	C490.836884,67.566437 492.642761,66.401711 494.363312,65.041702 
	C496.742523,63.161018 494.169617,62.421967 493.422913,61.218266 
	C492.860992,60.312515 492.811462,59.112072 493.775421,58.559467 
	C494.549469,58.115746 495.714600,58.004505 496.608246,58.198009 
	C498.086243,58.518051 499.121155,60.066738 498.566162,61.172401 
	C497.277893,63.738731 497.610229,65.410744 500.452972,65.835426 
	C502.770020,66.181580 504.283569,65.152306 505.168457,62.541042 
	C506.702057,58.015549 509.261902,57.115925 513.745605,58.901711 
	C517.966553,60.582821 522.646118,60.995438 526.092102,64.794304 
	C527.505005,66.351921 530.757507,67.018837 533.076721,66.839844 
	C542.445129,66.116829 550.908569,70.211891 559.871094,71.309044 
	C567.635925,72.259567 575.278137,73.082451 582.430176,76.571724 
	C584.372864,77.519508 586.654419,77.354507 588.613098,75.948120 
	C589.137329,75.571716 589.776367,75.292839 590.404297,75.133980 
	C593.053955,74.463661 611.040710,78.751198 613.133362,80.646172 
	C617.343262,84.458359 623.373413,84.448441 627.817993,87.745247 
	C628.039795,87.909813 628.585083,87.953751 628.774719,87.800385 
	C634.426758,83.229317 637.689941,88.249809 641.453918,90.996155 
	C643.905396,92.784836 646.494202,93.506065 649.428894,92.682205 
	C655.870850,90.873817 662.342712,92.828461 666.415100,98.089607 
	C671.175232,104.239197 676.679871,108.148346 685.057434,106.126396 
	C688.338074,105.334587 691.340942,106.495972 694.325806,108.619072 
	C700.556458,113.050842 705.602905,119.635025 714.120483,120.310501 
	C714.850586,120.368393 715.609863,121.534401 716.144897,122.327744 
	C718.882324,126.386688 723.144897,128.442535 727.545715,128.726685 
	C733.675781,129.122467 738.811462,132.579849 744.765503,133.174301 
	C746.072205,133.304749 746.860596,134.318268 746.910767,135.584946 
	C747.042114,138.897919 749.888855,139.737335 751.941284,141.289490 
	C752.924316,142.032913 754.172546,142.384232 755.160767,141.515594 
	C756.239014,140.567871 755.037903,139.777237 754.608643,138.968658 
	C754.210022,138.217865 753.816101,137.402878 754.541687,136.693604 
	C755.312317,135.940308 756.173035,136.503677 756.787537,136.954956 
	C758.655640,138.326904 760.464722,139.784576 762.234680,141.282272 
	C762.930969,141.871506 763.207886,142.939697 762.419434,143.451828 
	C758.514648,145.987961 761.137939,148.145508 762.846619,150.696198 
	C761.836670,152.137894 760.450806,152.005142 759.344666,151.185699 
	C754.346252,147.482864 748.266785,145.846558 743.070190,142.534225 
	C741.499329,141.532990 740.127075,140.360306 739.048584,138.852036 
	C737.765564,137.057892 736.018372,136.345276 733.098633,136.596237 
	C738.916809,139.917221 742.710876,144.904053 748.365479,147.579712 
	C751.308716,148.972427 752.765625,152.033859 754.302185,154.807205 
	C754.805420,155.715515 754.889954,156.764420 754.258484,157.655273 
	C753.425781,158.830093 752.159485,158.714066 751.107483,158.185486 
	C746.520935,155.880997 741.513367,154.256454 737.653442,150.722473 
	C732.959534,146.424988 727.883667,143.302078 721.198669,143.924850 
	C719.227844,144.108459 717.449829,143.256332 716.194702,142.004120 
	C710.410156,136.232742 702.556152,134.656433 695.488525,131.494492 
	C694.122925,130.883560 692.676697,130.441833 691.393188,129.657730 
	C689.470093,128.482849 687.553772,127.062683 688.577515,124.508621 
	C689.511414,122.178719 691.829773,121.580208 694.057861,122.102005 
	C697.628418,122.938164 701.167725,123.818802 704.955200,123.694153 
	C701.485535,123.030357 698.535950,121.575027 695.830872,119.211128 
	C691.879150,115.757828 687.686829,116.987686 685.634888,121.768700 
	C684.259827,124.972710 681.429688,124.321213 679.828979,122.958115 
	C672.160583,116.427841 661.937683,115.346107 653.625610,110.239014 
	C651.318787,108.821701 650.655029,107.216293 651.329895,104.894806 
	C652.404663,101.197357 650.247864,99.690521 647.315063,98.665619 
	C645.680847,98.094521 644.714905,98.320061 644.010925,100.339325 
	C642.380066,105.016830 636.002197,106.235291 633.101196,102.176140 
	C630.997681,99.232681 628.735779,98.881500 625.857544,99.626633 
	C621.432678,100.772186 617.369629,99.894485 613.210022,98.281494 
	C604.376465,94.856049 595.031006,93.611961 585.755371,91.928230 
	C577.782227,90.480927 570.041504,90.413956 562.128479,91.824493 
	C559.146118,92.356117 556.382874,90.424500 553.422180,89.946854 
	C551.098389,89.571960 548.812195,89.419327 546.097595,89.428833 
	C540.790955,89.992355 536.465149,88.880043 532.889160,84.659019 
	C531.361633,81.276657 533.057129,79.594391 535.549255,78.451859 
	C537.915710,77.366898 540.573547,77.161087 542.870850,78.191772 
	C548.799988,80.851921 553.808594,78.699364 558.949219,75.828781 
	C555.305054,74.206329 555.136902,74.228340 552.583740,76.564110 
	C548.742981,80.077835 546.597229,79.782333 544.286682,75.118027 
	C543.543396,73.617615 542.827271,72.267227 541.099548,72.615982 
	C534.576477,73.932770 528.573181,71.270622 522.440063,70.094719 
	C517.818970,69.208710 513.688660,71.364258 509.094208,70.671944 
	C499.679688,69.253296 490.186737,69.979713 480.787933,71.566231 
	C475.353027,72.483650 473.815063,73.922371 473.341675,78.845901 
	C470.643829,80.957031 467.468384,81.720215 464.735687,81.753807 
	C457.257935,81.845741 450.283112,85.297226 442.754333,84.568665 
	C440.392853,84.340141 438.177338,85.145226 435.987427,85.968613 
	C432.223724,87.383698 428.440582,88.511375 424.363708,87.117989 
	C422.695648,86.547874 421.057159,86.983940 419.674072,88.077347 
	C416.226990,90.802483 412.093506,92.136658 407.760315,93.964096 
	C406.597870,94.263420 405.806305,94.354279 404.618073,94.406700 
	C398.941071,94.383583 394.480072,97.076080 389.351776,98.899704 
	C385.071777,97.582359 381.276642,98.330139 377.453308,99.441956 
	C375.884216,99.898254 374.207764,100.251221 372.284302,99.259933 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M371.606384,99.007698 
	C374.562683,98.535820 377.253174,98.413986 379.658630,97.522743 
	C382.713745,96.390785 385.482788,95.900558 388.625916,97.989815 
	C391.632751,99.263092 394.737854,97.060654 397.547852,99.252792 
	C398.940216,99.721405 399.837250,99.210838 401.042511,98.562248 
	C401.585846,98.446503 401.813812,98.439713 402.374329,98.537613 
	C403.100494,98.920135 403.390472,99.283623 403.664062,100.073288 
	C403.333557,103.516373 400.780548,104.359116 398.448425,104.933655 
	C392.850708,106.312721 387.266235,107.652519 381.776184,109.476089 
	C377.464294,110.908340 373.185150,110.874207 371.209290,104.985481 
	C368.148895,112.357925 362.659821,115.776718 355.604889,117.552750 
	C353.265625,115.157806 355.475525,114.129349 356.849457,112.581688 
	C354.415466,113.679222 352.219055,114.682220 350.083405,115.798981 
	C349.056610,116.335899 348.010406,117.242027 348.168518,118.444939 
	C348.359314,119.896393 350.215179,118.999130 351.158966,120.207336 
	C351.647217,122.396111 350.354431,123.300095 348.969482,123.820587 
	C340.649994,126.947144 332.610901,130.892532 323.790039,132.574921 
	C322.966064,132.732071 322.148468,132.696762 321.079407,132.114471 
	C319.055756,125.770355 315.753235,130.423340 312.827637,130.873657 
	C312.287231,131.182220 312.090179,131.437027 312.114075,132.033722 
	C312.494843,132.823135 312.732971,133.378891 312.947571,134.309113 
	C311.737427,139.933029 306.731628,141.037170 302.666504,143.529266 
	C298.895752,138.404861 296.569824,138.779190 290.710205,145.547073 
	C292.833893,146.547211 294.917725,144.600342 297.090820,145.875275 
	C296.181335,150.658783 292.927429,152.784271 288.590668,153.290314 
	C285.468628,153.654633 282.710480,154.605179 280.805573,157.097198 
	C277.264221,161.730087 272.676117,163.646179 266.937561,163.062622 
	C262.617706,162.623322 259.169312,163.095200 258.418213,168.651627 
	C258.071747,171.214813 254.933472,172.481796 253.076294,174.806885 
	C251.715652,175.734055 250.312210,175.937164 249.201416,177.265656 
	C243.932297,177.290054 240.097977,179.024979 238.052231,184.285309 
	C237.345886,185.423965 236.660217,186.059967 235.314651,186.248260 
	C233.568527,184.093460 232.079025,182.161453 228.802567,183.534561 
	C229.317291,184.711853 231.060867,185.813889 230.290588,187.936432 
	C223.774780,186.371338 221.124969,190.509445 218.711227,195.153702 
	C217.519547,197.446609 215.554947,199.176468 215.387604,202.185806 
	C215.235840,204.915268 212.849289,205.259506 210.142227,203.974426 
	C213.886932,208.327789 213.709625,210.377426 209.342804,212.925446 
	C205.843109,214.967453 203.380646,218.056824 200.583801,220.879852 
	C203.065338,219.962738 204.781021,217.829407 207.164490,216.805740 
	C208.586319,216.195084 210.146561,215.682465 211.161987,217.306152 
	C212.041611,218.712708 211.018112,219.880875 210.017059,220.754761 
	C207.503235,222.949295 205.460464,225.567627 203.259644,228.041214 
	C199.579041,232.177994 198.059525,232.053223 195.261093,227.268997 
	C194.924561,226.693680 194.495392,226.185074 193.887680,225.370087 
	C194.010513,219.081772 192.747925,214.387894 185.782867,212.765747 
	C181.979172,211.879852 181.387787,208.877747 183.907501,205.881409 
	C186.149841,203.214874 188.445862,200.560364 192.611359,201.045502 
	C198.486588,201.729752 198.498566,201.626846 200.312256,195.508438 
	C201.736984,194.159302 202.655197,195.059570 203.457565,195.827011 
	C204.826187,197.136017 206.233826,197.479660 208.142975,196.230927 
	C210.369675,194.382797 210.496735,191.533310 212.701462,189.632599 
	C214.214935,188.630112 215.719666,188.208450 216.430817,186.729843 
	C220.795883,177.653809 229.200470,173.875381 238.175125,170.290115 
	C239.832916,169.750412 241.028183,169.056778 242.544922,168.231339 
	C248.746338,166.560135 252.971970,163.027969 255.793091,157.274933 
	C256.876678,153.694305 260.696167,153.693832 262.696167,151.154297 
	C263.753784,150.306931 264.835632,150.151047 265.785950,149.214630 
	C269.158325,148.000137 270.969116,144.746490 274.650452,144.018082 
	C276.415375,144.035889 277.789246,143.962936 278.838257,142.302979 
	C280.318115,139.565750 282.512909,138.341248 284.854431,136.930984 
	C295.484436,130.528671 308.202301,128.222916 317.837433,119.867561 
	C320.791840,117.305595 325.058258,117.498940 328.527954,115.868134 
	C329.701935,115.316345 331.241760,114.886490 332.112793,116.345734 
	C332.967102,117.776924 331.870148,118.850502 330.910400,119.790718 
	C329.958282,120.723450 328.948120,121.595810 328.153198,123.103180 
	C327.818939,124.052711 326.744202,124.297363 327.421906,125.143089 
	C328.793884,123.935829 329.632172,122.317657 331.580383,122.487030 
	C332.742859,122.588089 333.405365,122.540527 333.205841,121.002289 
	C332.740692,117.415710 335.256989,116.208992 338.395081,115.376091 
	C339.550903,115.518684 340.256592,115.971741 341.189697,116.212090 
	C340.489227,115.657860 339.747498,115.615028 338.742676,115.254440 
	C335.064484,113.028908 336.566010,111.484032 339.076019,110.314705 
	C340.717163,109.550171 342.714325,110.403168 344.699463,109.245331 
	C345.992432,108.058716 347.466461,108.219368 348.768005,107.227112 
	C350.174072,106.842712 351.348114,106.685722 352.761108,106.264374 
	C353.992432,105.058716 355.466461,105.219368 356.768005,104.227112 
	C357.494629,103.739479 357.989227,103.479263 358.741333,103.109222 
	C359.523895,102.457748 360.072662,101.939934 360.802429,101.203003 
	C364.668915,101.698997 367.835083,99.810143 371.606384,99.007698 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M545.786072,88.337257 
	C550.692200,87.845039 555.114380,89.226440 559.656006,90.058624 
	C563.769043,90.812279 568.246399,89.746521 572.541565,89.283012 
	C575.881104,88.922615 579.148499,88.694405 582.365967,89.950287 
	C584.391418,90.740898 586.430969,91.446228 588.619751,90.306206 
	C589.158813,90.025406 590.095032,89.877769 590.548584,90.156754 
	C597.080750,94.174522 605.230530,92.573036 611.776978,96.159393 
	C617.455078,99.270073 623.217041,99.193611 629.102783,97.719337 
	C631.534180,97.110336 632.683655,97.099075 633.232666,99.944542 
	C634.076538,104.318390 637.793152,102.973068 640.555786,102.901840 
	C643.881226,102.816101 642.913696,100.521408 642.348755,98.561638 
	C642.050903,97.528259 642.143433,96.138237 643.385498,96.311707 
	C646.464844,96.741791 649.561951,97.202576 652.080994,99.512306 
	C654.334900,101.578918 653.967590,103.334976 652.432251,105.568794 
	C651.679688,106.663597 651.668152,108.237152 653.424255,108.821846 
	C660.418823,111.150635 666.509888,115.798950 673.941467,116.935486 
	C676.166260,117.275719 676.768799,118.999153 677.932678,120.332024 
	C679.448608,122.068123 681.679138,123.062325 683.690796,122.615913 
	C685.935852,122.117729 683.689880,120.131287 684.030334,118.795303 
	C684.346619,117.553749 684.828247,116.214783 686.032288,116.279404 
	C690.046997,116.494911 694.623413,113.974197 697.742920,118.983482 
	C699.466187,121.750702 702.853088,121.662209 705.797180,122.190201 
	C707.146484,122.432190 709.169067,122.847374 708.578064,124.884758 
	C708.080811,126.599182 706.411377,125.918762 705.163574,125.556839 
	C701.528748,124.502579 697.566101,125.400635 694.030945,123.300438 
	C692.451050,122.361832 690.145874,123.137062 689.383545,125.031830 
	C688.526611,127.161736 690.623779,127.864403 692.084473,128.506241 
	C695.882385,130.175034 699.689575,131.851425 703.593506,133.243027 
	C708.616394,135.033493 713.344788,137.186829 717.195129,141.036346 
	C718.063110,141.904114 719.185608,143.097473 720.635132,142.824493 
	C728.505310,141.342453 733.791687,145.323273 739.246887,150.357620 
	C742.759338,153.599136 747.804688,155.213669 752.240173,157.401031 
	C753.650269,158.096436 754.211243,156.794205 753.620239,155.871857 
	C752.112122,153.518295 750.747925,150.274475 748.508423,149.333939 
	C741.155151,146.245758 736.955261,138.915848 729.597229,135.814758 
	C733.777039,135.457809 738.280945,132.691040 740.938293,139.100082 
	C742.078369,141.849731 745.849976,142.287842 748.454895,143.713043 
	C753.018433,146.209900 757.656311,148.570953 762.631470,150.994446 
	C763.166687,151.000244 763.333313,151.000488 763.750000,151.000977 
	C766.404175,153.287369 769.900085,153.561234 772.723694,155.791824 
	C773.443909,156.000961 773.887817,156.001938 774.664673,156.003601 
	C777.593384,158.054077 780.418884,159.886978 781.641724,163.209290 
	C782.401428,165.273285 784.205994,165.058929 785.842957,164.987915 
	C788.524048,164.871613 790.317749,165.793015 790.942505,168.601791 
	C791.509277,171.149536 793.641602,171.991837 795.722717,173.173691 
	C802.539612,177.044998 809.434631,181.015366 810.403198,190.424454 
	C815.254272,188.341064 819.558838,190.599930 824.287292,192.317993 
	C827.129272,194.618393 828.502991,197.213226 827.930481,200.937790 
	C826.325500,203.935028 822.399475,201.685959 820.807007,204.894379 
	C820.374146,205.292526 820.182251,205.435104 819.658997,205.728058 
	C818.106140,206.247437 816.879944,206.217331 815.295288,205.799072 
	C813.347473,204.828201 812.198853,203.554276 811.249756,201.626114 
	C810.866821,199.314117 811.058838,197.002151 807.639832,197.088165 
	C806.017883,196.583084 805.280762,195.692719 804.963989,194.037354 
	C804.847229,192.388290 805.335754,191.116150 804.935242,189.801025 
	C804.263000,187.593658 803.696106,184.949203 801.169922,184.561661 
	C798.733582,184.187881 799.389221,187.194702 798.324951,188.468826 
	C797.695129,189.222870 797.122986,190.004684 795.930054,190.676910 
	C791.211792,191.620239 789.709778,188.441925 788.000244,185.051971 
	C787.322388,182.186005 788.476562,180.003418 790.013000,176.783951 
	C787.011230,179.119080 784.749512,180.361328 781.786194,180.897247 
	C775.832397,180.676926 770.527771,179.783447 767.271973,173.944138 
	C766.830139,171.011612 766.087341,169.425323 762.910950,168.678925 
	C758.483337,167.638550 754.621277,164.678970 750.739380,162.343872 
	C746.982971,160.084274 743.276306,158.248077 738.750854,157.087311 
	C738.210083,156.817871 738.001587,156.693054 737.511169,156.335678 
	C734.301514,153.123749 730.991394,150.866669 727.111816,149.096329 
	C721.850891,146.695633 716.220154,144.954453 711.517395,141.388748 
	C710.726685,140.789200 709.789368,140.147079 708.837036,140.114868 
	C703.105408,139.921036 698.599121,136.630737 693.388794,134.149292 
	C691.874084,133.269653 690.702698,132.506363 689.084351,131.832947 
	C685.617371,129.758331 682.888672,127.113594 678.886230,125.921677 
	C677.446533,125.189255 676.406677,124.499695 675.088013,123.528992 
	C670.735840,119.453331 665.295776,118.354935 660.327698,116.993752 
	C650.594727,114.327065 640.821716,111.628334 632.109558,106.697113 
	C627.735901,104.221535 623.723877,103.636673 619.138184,103.628555 
	C616.812622,103.624435 614.478577,103.188240 612.364746,102.140152 
	C599.037842,95.532539 584.445984,94.683273 570.107849,94.015556 
	C562.937866,93.681656 555.991455,93.358299 549.111877,91.399094 
	C547.520752,90.945961 545.707642,90.945381 545.786072,88.337257 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M842.479492,173.653793 
	C849.306458,176.538010 850.300720,178.683441 848.002014,185.635406 
	C847.063477,187.101944 845.743408,187.337997 844.436707,187.572220 
	C842.970520,187.835052 841.340820,188.313461 840.179199,187.100845 
	C838.773682,185.633591 840.206421,184.375732 840.882996,183.119751 
	C841.445679,182.075302 842.051392,180.975647 841.139526,179.898102 
	C840.064758,178.628067 838.727112,179.201340 837.482178,179.664474 
	C836.537476,180.015945 835.613342,180.539764 835.064087,181.357529 
	C830.999939,187.408737 825.592590,190.334946 817.838318,188.854431 
	C813.296875,188.770294 809.922546,187.566635 809.571350,182.650009 
	C809.350098,179.553741 806.652405,179.483948 804.415405,178.261780 
	C801.806030,176.119843 801.015564,172.059036 796.407104,172.809998 
	C793.235229,172.208862 790.819824,171.263824 791.747559,167.314026 
	C794.286255,166.419266 796.363647,165.682617 795.127502,162.297699 
	C794.965027,160.256439 796.118408,160.373138 797.649780,160.763031 
	C798.002441,160.996552 798.501221,160.997894 798.750610,160.998947 
	C801.356201,162.635834 801.004822,165.329575 801.191833,167.645203 
	C803.996216,168.030548 805.698914,164.859177 807.382263,165.521332 
	C813.174744,167.799744 816.539124,165.570038 819.614014,160.850632 
	C820.636169,159.281799 823.349731,159.331818 824.995911,160.939682 
	C826.486694,162.395782 825.578735,163.990158 824.596558,165.414536 
	C823.955261,166.344620 823.120605,167.202759 823.423218,169.176331 
	C828.927124,168.028473 834.738098,161.281586 839.899170,169.662140 
	C839.997864,170.446167 839.995667,170.892334 839.998169,171.673096 
	C840.583191,172.735718 841.481995,172.966156 842.479492,173.653793 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M786.941284,150.661621 
	C786.997986,151.446884 786.998474,151.890869 786.998230,152.667862 
	C785.683411,154.078857 784.610046,153.423172 783.285217,152.262878 
	C783.000000,152.000900 782.500732,151.999802 782.251099,151.998993 
	C780.145996,149.548218 776.282288,148.209946 777.821899,143.890015 
	C778.307922,142.526199 776.898438,142.468292 775.912170,142.428833 
	C774.447205,142.370224 772.984863,143.024536 771.257080,142.225006 
	C770.997559,142.002060 770.499878,142.000259 770.251038,141.999207 
	C768.012878,140.025314 766.014526,138.087585 764.575745,135.614944 
	C763.293762,133.411880 761.464050,131.742676 758.750549,131.460052 
	C753.098511,130.871292 748.286743,128.901489 745.255981,123.261917 
	C744.245056,123.005753 743.496765,123.004944 742.374207,123.004211 
	C738.538452,122.488144 735.638489,121.321083 734.863708,117.349106 
	C734.477600,115.369614 732.332458,115.447632 730.857788,114.183922 
	C732.127869,109.290039 737.741272,106.810638 741.382996,109.469704 
	C743.718933,111.175407 746.056213,112.503372 748.890625,113.024590 
	C750.054016,113.238518 751.273804,113.846687 751.726929,114.879379 
	C753.256897,118.366310 754.968140,120.419571 759.403870,118.675865 
	C761.237793,117.954941 762.596313,120.595757 763.352295,122.478249 
	C764.389160,125.060219 766.227722,126.597832 769.012207,126.505112 
	C773.730530,126.348007 776.759277,128.764008 779.097229,132.525879 
	C780.048096,134.055878 781.025452,135.659607 783.414734,136.107391 
	C783.921570,136.446426 784.104553,136.607407 784.468079,137.082214 
	C784.762939,141.182724 783.853149,145.058121 785.878784,148.936081 
	C786.439026,149.516434 786.687805,149.901474 786.941284,150.661621 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M427.511993,72.032288 
	C427.080719,70.588745 426.642670,68.123459 428.212097,68.164909 
	C433.077057,68.293427 437.352844,64.785088 442.294189,66.143669 
	C445.145050,66.927490 447.896301,68.344414 450.956543,67.797226 
	C452.235809,67.568497 453.569183,67.006790 453.857483,65.435379 
	C454.157013,63.802849 453.029022,63.102962 451.825165,62.404213 
	C449.727600,61.186737 447.522400,60.676384 445.133820,61.169865 
	C444.325623,61.336834 443.533569,61.854855 442.750854,61.821354 
	C440.255249,61.714531 436.233154,63.382774 435.753021,60.329185 
	C435.199158,56.806782 439.705750,57.584141 441.876740,57.201130 
	C448.717957,55.994194 455.591583,57.656067 462.241974,58.892750 
	C466.700104,59.721771 470.747223,60.657646 475.169556,59.219326 
	C476.683624,58.726887 478.462738,59.049400 480.560364,58.999870 
	C480.337830,62.024235 477.887451,60.804066 476.110962,60.969177 
	C473.478485,61.213840 471.085114,61.462070 470.932892,64.956734 
	C470.837036,67.157654 468.750885,68.527718 467.299286,68.542763 
	C458.859955,68.630241 450.412079,74.528313 441.939392,68.558662 
	C440.148743,67.297020 437.687073,68.385208 437.096222,69.796555 
	C435.292755,74.104271 432.531769,73.257233 429.386902,72.030869 
	C428.954590,71.862289 428.397736,72.012962 427.511993,72.032288 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M339.262573,115.750229 
	C336.451965,117.687515 331.610840,118.207436 335.640472,123.145470 
	C336.364105,124.032257 334.829163,125.340393 334.092010,124.713379 
	C330.535828,121.688545 329.594025,125.591370 327.337341,126.943970 
	C326.421967,126.313042 326.265228,125.541481 326.764587,124.342133 
	C325.621765,119.805161 330.831940,119.713066 332.181305,115.307671 
	C326.903320,119.120293 321.492096,118.759369 317.001282,121.855911 
	C310.557281,126.299210 304.088867,130.406128 296.120789,132.658142 
	C290.479797,134.252457 284.602814,137.773148 279.345642,141.939789 
	C279.036163,134.858994 279.036743,134.863495 285.848846,133.967606 
	C286.502106,133.881699 287.193207,133.806183 287.780457,133.537369 
	C295.617310,129.949814 303.439606,126.330345 311.261139,122.709381 
	C311.556335,122.572723 312.003326,122.380005 312.050262,122.147064 
	C313.315308,115.867050 318.952240,117.243904 323.006653,115.820488 
	C326.105865,114.732430 328.999695,113.533096 331.713684,111.308502 
	C335.095581,108.536453 339.801086,108.948242 344.554382,109.000000 
	C344.552765,111.774025 342.212646,110.526924 340.703552,111.114456 
	C339.362732,111.636467 335.895782,111.288795 338.815002,114.738434 
	C339.175049,115.167427 339.350098,115.333946 339.262573,115.750229 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M803.758606,176.759644 
	C805.330017,177.723480 806.739319,178.093079 808.265320,178.025238 
	C810.098694,177.943726 811.271057,178.866531 810.936890,180.672928 
	C809.980347,185.844421 813.112427,187.253479 817.613281,187.948792 
	C820.948242,187.826523 823.325745,188.675201 824.255005,192.325531 
	C821.209351,191.968246 818.089966,192.241058 815.401978,190.740219 
	C812.115784,188.905396 811.510376,193.965103 808.690002,193.482346 
	C809.358704,185.309402 803.657654,177.191528 794.933716,174.187195 
	C791.966248,173.165253 789.683533,171.984360 789.608521,168.342850 
	C789.552246,165.611053 787.218628,165.911179 785.503296,166.055176 
	C782.616516,166.297470 780.371094,165.440613 779.858215,162.526443 
	C779.325256,159.498169 775.360229,159.696228 775.007935,156.372787 
	C779.315063,155.881500 783.743774,155.157257 783.236633,162.108704 
	C783.031250,164.923721 786.218872,163.963409 787.952820,163.903046 
	C789.972412,163.832733 792.033508,163.066513 791.997803,166.639420 
	C791.666870,170.178223 793.829346,171.173340 796.640747,171.956726 
	C800.212158,172.006088 801.434998,174.881927 803.758606,176.759644 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M235.314575,155.093750 
	C233.233246,154.775558 232.840668,153.665680 233.193527,152.134338 
	C233.494995,150.826004 234.602066,149.773438 235.672928,150.147568 
	C240.323532,151.772308 244.093826,148.995636 248.602661,147.995087 
	C248.994781,148.439346 248.997772,148.885269 248.999466,149.665558 
	C246.521515,152.688614 246.495621,155.208511 249.759796,157.758987 
	C250.321381,158.787811 250.639206,159.576248 250.984451,160.683975 
	C250.879974,161.563843 250.604584,162.041809 249.826630,162.655609 
	C247.292206,163.548553 245.110840,163.588257 242.562439,162.761017 
	C241.548874,162.075424 241.246033,161.375061 241.057983,160.229431 
	C241.339722,155.881531 237.460693,156.708878 235.314575,155.093750 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M254.343933,144.957184 
	C254.187836,142.705643 253.782425,140.794876 251.449554,139.584885 
	C249.913040,138.787933 249.708527,137.179398 250.342224,135.665604 
	C250.944519,134.226868 252.345139,132.606583 253.670197,133.187439 
	C259.143372,135.586670 263.956970,131.590179 269.582825,132.003403 
	C266.663483,134.907043 262.209290,136.213043 259.346161,139.951614 
	C258.014587,141.690338 257.222534,144.214432 254.343933,144.957184 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M745.038757,123.363068 
	C749.085999,125.917290 752.466553,130.339066 758.189758,129.940796 
	C761.533020,129.708145 763.487732,131.575745 765.174377,133.835510 
	C766.933350,136.192245 768.353333,138.801987 769.961853,141.649628 
	C765.994141,140.475296 762.671997,137.784592 759.081421,135.554703 
	C757.136963,134.347046 755.438171,132.378082 753.349426,131.843536 
	C748.834412,130.688065 746.592163,127.690414 745.038757,123.363068 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M730.707947,113.842911 
	C733.859436,114.202728 737.074097,113.411659 736.405029,118.548546 
	C736.149902,120.506920 740.050537,120.769577 741.792114,122.729980 
	C740.528564,124.755928 738.900574,125.054871 736.804443,123.947624 
	C733.308655,122.101028 729.707092,120.454788 726.072571,118.365204 
	C726.043945,117.075912 726.612793,116.414009 727.383545,115.501114 
	C728.413452,114.505470 729.255554,113.809090 730.707947,113.842911 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M697.694275,103.761978 
	C700.951050,103.500710 702.965149,105.402679 705.204895,106.754463 
	C707.420105,108.091476 709.233276,110.313576 712.598389,110.013062 
	C715.080078,112.783287 719.121277,112.352715 721.380981,115.386322 
	C721.484741,115.962875 721.469116,116.201492 721.227966,116.719070 
	C715.599609,117.063919 711.511597,113.669388 707.158875,111.312401 
	C703.406677,109.280594 698.284302,109.345085 696.040527,104.354218 
	C696.382690,103.623657 696.842773,103.472771 697.694275,103.761978 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M771.054443,142.337494 
	C772.775696,141.668427 774.551086,141.083252 776.332703,141.063248 
	C778.692505,141.036774 782.194519,140.291306 778.943359,144.817871 
	C776.717712,147.916550 781.868713,148.665329 781.962646,151.653320 
	C777.326599,150.109863 774.266907,146.335419 771.054443,142.337494 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M249.695953,162.255524 
	C250.115555,161.691315 250.306915,161.431473 250.794525,161.116486 
	C253.368149,160.826584 253.579010,158.105118 255.702515,157.149750 
	C254.981918,162.951843 249.871063,167.966141 243.384827,168.999313 
	C241.146622,166.125732 243.446732,165.004486 245.256241,163.699539 
	C246.482697,162.815033 248.112991,163.256454 249.695953,162.255524 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M797.948364,160.622009 
	C797.143372,161.160385 796.284363,161.324219 795.212646,161.743164 
	C793.611755,161.997437 792.223572,161.996582 790.418884,161.995422 
	C788.435608,160.464645 786.139160,159.398987 786.786621,156.296158 
	C790.472107,157.832901 795.066528,156.779739 797.948364,160.622009 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M361.997894,88.249496 
	C365.449677,86.812653 368.747253,85.413307 371.129639,82.428307 
	C371.967529,81.378517 373.096558,80.594788 374.784546,81.747009 
	C375.555969,84.177246 373.138824,84.221230 372.237274,85.760132 
	C369.651978,89.156471 366.206116,88.961990 362.417755,88.999931 
	C361.998779,88.999084 361.997009,88.499359 361.997894,88.249496 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M787.006470,155.619247 
	C786.871765,158.373428 789.565063,159.201187 789.963562,161.652191 
	C786.882446,162.267380 784.691528,161.123505 785.320007,157.724777 
	C785.779114,155.241486 783.174316,154.618637 783.036682,152.340393 
	C784.103027,152.310303 785.206116,152.619705 786.653320,152.964981 
	C786.999939,153.748993 787.002380,154.497131 787.006470,155.619247 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M372.400879,85.944611 
	C371.940186,84.140739 373.333984,83.376999 374.805725,82.263931 
	C376.713959,82.000465 378.428528,81.999176 380.571533,81.997871 
	C381.352448,82.055847 381.637787,82.229088 381.933258,82.751709 
	C381.628662,85.438835 379.714661,85.006599 378.137482,84.971161 
	C376.280029,84.929420 374.519043,85.224182 372.400879,85.944611 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M274.768036,143.739166 
	C272.262482,145.679550 270.739777,149.361328 266.381256,148.999390 
	C268.482086,146.597977 269.685883,142.219635 274.768036,143.739166 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M772.844116,155.693176 
	C769.641724,155.381180 766.241943,154.848740 764.045898,151.359161 
	C767.536804,151.193359 770.593689,152.336441 772.844116,155.693176 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M353.398926,92.993011 
	C353.575684,88.949989 356.759003,90.273804 359.573792,89.991959 
	C359.052673,93.312569 356.418488,93.135658 353.398926,92.993011 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M249.959778,157.644241 
	C245.221512,155.791397 245.039307,154.949341 248.649582,150.065277 
	C247.817932,152.616776 248.269806,155.028122 249.959778,157.644241 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M721.339478,117.147095 
	C721.002625,116.830368 721.002869,116.662704 721.003967,116.243561 
	C722.930237,114.143883 725.071594,114.356819 727.645264,115.665359 
	C727.506531,116.583771 727.015503,117.170105 726.259766,117.881332 
	C724.519470,117.988869 723.015869,118.141769 721.339478,117.147095 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M348.651978,107.003265 
	C348.799072,109.102486 347.235443,108.987915 345.374207,108.999008 
	C345.639191,107.569580 346.770660,106.955154 348.651978,107.003265 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M356.651978,104.003281 
	C356.799103,106.102539 355.235413,105.987892 353.374207,105.999008 
	C353.639008,104.569252 354.770813,103.955421 356.651978,104.003281 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M265.680084,149.029419 
	C265.906097,150.438675 265.065338,151.014145 263.351624,150.993530 
	C263.466522,149.963638 264.192169,149.242325 265.680084,149.029419 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M691.055664,101.351463 
	C692.147400,101.006966 693.075378,101.441795 693.876465,102.698639 
	C692.882996,102.942390 691.620850,103.171120 691.055664,101.351463 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M382.250854,83.001236 
	C381.685333,82.888931 381.427002,82.698181 381.113129,82.213669 
	C381.546631,81.129906 382.411407,81.007896 383.663574,81.002480 
	C383.739716,81.494431 383.479462,81.989166 383.109436,82.741455 
	C382.999695,82.999001 382.500458,83.000725 382.250854,83.001236 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M663.053223,88.360199 
	C664.142883,88.012138 665.068604,88.444008 665.858704,89.709198 
	C664.872681,89.940460 663.615906,90.163330 663.053223,88.360199 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M697.973206,103.688263 
	C697.563416,103.981102 697.116028,103.988777 696.333130,103.999756 
	C695.997864,103.835938 695.998230,103.668823 695.999268,103.251038 
	C696.891235,101.952110 697.515930,102.185234 697.973206,103.688263 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M360.682495,101.046722 
	C360.913574,101.900436 360.657440,102.678680 359.316406,102.953346 
	C359.086182,102.100189 359.342133,101.321754 360.682495,101.046722 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M311.313873,110.951294 
	C311.083221,110.092773 311.346710,109.318153 312.689484,109.051399 
	C312.918610,109.909729 312.653168,110.682747 311.313873,110.951294 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M234.948395,155.366425 
	C238.251450,154.204666 241.762619,155.857208 242.250336,158.774170 
	C237.367828,160.009140 235.593735,164.894806 231.452545,166.724289 
	C229.598572,167.543320 228.026947,169.322723 226.564636,170.909622 
	C223.618210,174.107040 220.582214,174.748444 217.023865,171.792877 
	C214.654846,169.825134 213.350983,170.335495 212.952728,173.632507 
	C212.259079,179.375061 211.531204,179.851196 205.636490,179.381714 
	C204.100662,179.259399 202.680176,179.741409 201.197617,179.821411 
	C199.327637,179.922302 197.859497,179.174484 197.431564,177.288055 
	C196.956024,175.191772 198.287704,174.301590 200.145187,173.910385 
	C201.342697,173.658173 203.187881,173.923279 202.655838,171.799988 
	C202.204819,170.000092 200.696350,169.727600 199.070114,170.010498 
	C197.735825,170.242599 196.340683,170.635422 195.564789,171.736786 
	C192.718567,175.776886 189.107056,175.750809 184.988495,174.129395 
	C182.579758,173.181122 181.113373,173.953598 181.160233,176.784821 
	C181.182480,178.128128 181.153152,179.677750 180.102829,180.523911 
	C175.104416,184.550797 174.146942,189.685349 175.433838,195.896820 
	C174.700607,196.691254 174.038742,196.926880 172.985672,197.085098 
	C169.180969,196.224533 169.483551,199.014938 168.767212,201.264008 
	C166.382736,203.772964 164.298935,206.036530 162.910172,208.945435 
	C160.688492,213.598923 155.691666,213.630417 151.338440,215.142853 
	C147.970627,213.105591 145.099457,212.078873 150.308624,208.370697 
	C153.621872,206.012100 155.100342,200.932846 158.476410,197.814285 
	C159.375122,196.984116 159.008804,195.521667 158.058517,194.655777 
	C154.867218,191.747894 156.805099,190.205048 159.495316,188.743530 
	C160.354355,188.276840 161.375671,187.772980 161.851089,186.997131 
	C163.453201,184.382584 165.268524,183.675064 168.020386,185.414398 
	C169.270172,186.204315 170.878601,186.570694 171.938843,184.375854 
	C171.278732,183.278397 170.795334,182.436127 171.014099,180.961899 
	C169.421295,176.709366 172.259323,173.379196 172.464294,169.691818 
	C172.556885,168.026138 181.655853,165.161545 183.656479,165.859100 
	C185.006332,166.329773 186.685944,167.671173 187.406982,165.537842 
	C187.980728,163.840256 187.379486,161.936646 185.361313,161.089005 
	C184.759506,160.836243 184.014343,160.885025 183.455261,160.577057 
	C180.925354,159.183502 179.758728,156.853806 179.364456,154.110718 
	C179.228745,153.166611 180.037094,152.238419 180.770081,152.409332 
	C183.630478,153.076340 187.646469,150.977203 189.187271,154.734009 
	C191.211319,159.669098 194.061050,158.238617 196.991455,156.311859 
	C199.562988,154.621063 201.432007,153.194016 203.816101,156.898865 
	C205.385635,159.337906 207.868332,157.338196 209.368515,155.962860 
	C211.284866,154.206009 212.339188,154.083633 214.030579,156.318298 
	C215.910416,158.801941 218.722839,158.567459 221.222397,157.123093 
	C222.841980,156.187210 224.014236,155.767471 225.594666,157.286209 
	C229.874237,161.398636 231.316071,161.097748 234.948395,155.366425 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M147.236893,221.675720 
	C146.898682,223.213943 146.358261,224.831589 145.226654,224.628799 
	C139.092209,223.529358 137.179306,229.933228 132.518082,231.554459 
	C129.832947,232.488388 131.333313,235.140030 132.036346,237.204620 
	C132.666168,239.054260 135.136017,242.179871 129.978012,241.224136 
	C129.048584,241.051910 128.354950,241.494766 128.164536,242.476974 
	C127.922035,243.727921 128.306244,244.718826 129.347443,245.528564 
	C134.029526,249.169815 134.022217,249.187653 129.072083,252.318130 
	C128.658249,252.579849 128.168488,252.721481 127.360703,252.963058 
	C128.629517,249.119034 126.578094,245.705582 125.541107,242.188416 
	C124.826057,239.763168 124.460220,237.597214 127.305321,236.312546 
	C128.742340,235.663681 128.948898,234.412186 129.046906,233.016663 
	C129.447220,227.316589 133.390106,221.893707 130.135574,215.850311 
	C129.516052,214.699936 130.084015,212.290817 132.397141,214.025513 
	C134.626846,215.697632 135.653900,213.260223 137.634567,213.035736 
	C139.477493,214.602448 140.283051,216.174591 143.480774,214.641357 
	C147.982086,212.483032 146.622589,216.954681 147.013214,219.593933 
	C147.363586,220.402344 147.497330,220.856964 147.236893,221.675720 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M147.372345,222.058945 
	C147.019989,221.544830 147.015320,221.107712 147.014313,220.342880 
	C149.051880,220.342926 149.911041,219.089706 150.739258,217.221954 
	C153.707260,216.112396 154.003113,217.874084 153.264908,219.547241 
	C152.193008,221.976730 153.048599,223.890717 153.715317,226.155197 
	C155.078796,230.786316 153.090622,235.281570 152.298904,240.213318 
	C151.232101,242.950562 149.610199,244.626251 146.813782,245.568329 
	C146.159409,245.579056 145.898911,245.530746 145.321655,245.242432 
	C143.181458,240.853226 138.525131,239.179031 136.285675,234.880753 
	C138.006744,234.614120 139.627350,234.186813 141.257431,234.147034 
	C144.697113,234.063049 147.860489,233.990646 146.130478,228.994263 
	C145.776962,227.973328 146.868195,227.311218 147.594421,226.610825 
	C149.041473,225.215302 149.896835,223.686340 147.372345,222.058945 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M151.297028,241.246155 
	C151.514908,237.635880 151.930832,234.250214 152.578125,230.909409 
	C152.896317,229.267044 153.401077,227.597946 152.399887,226.174210 
	C150.676727,223.723846 150.453094,221.436523 152.321945,218.993729 
	C152.953445,218.168289 152.586761,217.487488 151.326431,217.055359 
	C150.999451,216.555908 150.998917,216.111816 150.996674,215.334686 
	C153.070160,213.712021 154.942078,212.105286 157.585724,211.881638 
	C160.244659,211.656677 161.319168,209.524231 162.323792,207.375305 
	C163.493698,204.872818 164.468765,202.146805 167.849854,201.124786 
	C168.630615,200.390610 169.237534,200.002197 170.299652,199.707886 
	C171.386505,199.484268 172.117996,199.432175 173.183441,199.753784 
	C175.966370,202.744766 173.973495,204.734390 171.882523,206.025681 
	C167.469910,208.750717 163.807693,212.392914 159.632996,215.392090 
	C154.814697,218.853699 154.229263,223.017151 157.878952,227.579178 
	C159.046494,229.038559 160.146362,230.453720 159.856445,232.827454 
	C159.576767,233.669220 159.302719,234.092392 158.661072,234.714386 
	C156.694641,235.506470 156.579544,237.002487 156.148407,238.392242 
	C155.475677,240.560715 154.323410,242.248566 151.297028,241.246155 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M163.377289,162.954498 
	C166.667999,154.994919 167.497574,145.132751 177.642700,141.062653 
	C178.186707,142.939941 179.974380,145.000565 177.137497,146.649094 
	C175.544479,147.574783 176.372742,149.198410 176.692856,150.684891 
	C177.205688,153.066360 177.024414,155.232956 173.550323,154.342300 
	C172.165741,153.987350 170.914658,154.432587 171.135590,155.859085 
	C171.793640,160.107880 169.188965,161.109192 165.914230,161.758835 
	C165.147949,161.910858 164.470505,162.510742 163.377289,162.954498 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M178.348495,138.846970 
	C176.742950,138.212311 175.134506,137.483902 175.447052,135.727173 
	C175.785965,133.822220 177.831848,134.396347 179.071457,133.765335 
	C182.074478,132.236710 185.301407,134.429077 188.637756,132.999817 
	C190.472031,132.931076 191.380020,133.947617 192.709976,134.850861 
	C193.921326,135.916718 194.516190,136.964996 194.160004,138.663391 
	C193.673721,138.991028 193.226883,139.118851 193.038803,138.953094 
	C188.321411,134.795532 183.497818,137.455124 178.348495,138.846970 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M218.699951,138.748322 
	C218.994293,139.430450 218.997269,139.880402 219.002563,140.667923 
	C219.004883,141.005493 219.000153,141.000000 219.002655,141.001541 
	C217.683533,143.580353 214.636154,143.337708 212.338486,144.940353 
	C210.628479,144.826828 208.965027,145.536026 208.208099,143.034882 
	C211.200302,139.851944 214.524551,138.443115 218.699951,138.748322 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M138.278015,210.851501 
	C135.504562,210.646866 134.166077,209.234436 134.009827,206.357803 
	C139.327530,206.894043 139.835236,207.417465 138.278015,210.851501 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M194.354843,139.046326 
	C193.668869,137.899384 193.337738,136.798752 193.003616,135.347839 
	C193.745285,135.002625 194.489944,135.007706 195.606964,135.017181 
	C197.352066,136.175873 198.672379,137.391815 200.258789,138.804321 
	C200.561386,139.556122 200.553177,140.012131 200.217194,140.740265 
	C197.836731,141.474518 196.501144,139.655609 194.354843,139.046326 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M212.214798,145.245300 
	C213.314453,142.381714 216.473251,142.912460 218.687546,141.143082 
	C218.853821,145.489624 214.615631,143.981369 212.214798,145.245300 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M207.988510,143.011505 
	C206.809296,142.859192 205.641937,142.694626 204.236603,142.266754 
	C203.409393,141.578522 202.794785,141.172958 202.896576,140.012146 
	C203.459274,139.378052 203.906799,139.172852 204.768860,139.018677 
	C205.656265,139.026672 206.137161,139.167542 206.928177,139.581726 
	C208.202728,140.369949 208.660263,141.205399 208.238342,142.659882 
	C208.023010,142.975174 208.000381,142.999252 207.988510,143.011505 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M203.154724,139.672852 
	C203.253220,140.518997 203.518158,141.005569 203.890869,141.747803 
	C202.825607,141.848175 201.652588,141.692902 200.239838,141.275299 
	C200.002228,140.580093 200.004395,140.147263 200.007797,139.389923 
	C200.577698,138.398178 201.316345,138.233307 202.511063,138.500168 
	C203.023483,138.876831 203.172318,139.095062 203.154724,139.672852 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M170.741898,180.726044 
	C171.779846,181.335724 172.155396,182.221359 172.032623,183.656128 
	C170.361237,183.531189 168.074783,183.351868 170.741898,180.726044 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M728.270569,115.867844 
	C725.957886,115.996803 723.918274,115.996170 721.441772,115.993805 
	C719.078735,113.159225 714.034363,115.021294 713.031128,110.371368 
	C715.859924,109.671738 718.879639,112.731934 721.822510,109.328323 
	C725.159363,107.863892 726.985352,105.788582 727.455444,102.311195 
	C727.791077,99.829498 728.845337,97.531090 732.545349,98.035431 
	C734.668640,98.052483 736.404175,98.064682 738.570557,98.040352 
	C739.415283,97.663895 739.889099,97.478722 740.794556,97.549591 
	C743.428528,100.424881 745.881165,101.212296 749.838074,101.421791 
	C753.733582,101.628059 758.425415,102.708580 762.082642,104.173347 
	C767.712036,106.428047 773.648193,107.828598 779.254639,110.201302 
	C783.102966,111.829948 784.533691,113.500298 784.441589,117.263382 
	C784.364624,120.408897 783.999878,123.736885 788.072876,125.560608 
	C789.168579,126.971947 789.275879,128.266708 789.030640,130.000580 
	C788.767883,130.872787 788.522522,131.319397 787.945312,131.981873 
	C786.059204,133.229233 783.958069,133.836227 784.142517,136.719193 
	C780.747009,137.644012 779.083130,135.635162 777.771667,133.185944 
	C775.792664,129.489883 773.106323,127.389343 768.579224,127.936287 
	C764.870911,128.384293 762.891479,126.248215 761.895508,122.701035 
	C761.198242,120.217865 760.178284,117.674873 756.854309,120.876221 
	C754.471802,123.170792 752.418945,122.000793 752.101379,118.932442 
	C751.735413,115.397789 750.032532,113.864189 746.606018,113.993919 
	C745.215942,114.046555 743.629639,113.812622 742.928589,112.770233 
	C738.611084,106.350456 734.944946,110.926384 731.060791,113.653381 
	C730.179565,114.578751 729.361572,115.158501 728.270569,115.867844 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M770.348389,863.139465 
	C762.303406,869.563538 752.689880,872.598694 743.905151,877.161255 
	C743.610962,877.313965 743.246948,877.409485 743.040344,877.642456 
	C734.856323,886.867188 722.692383,889.706970 712.920044,896.386536 
	C711.582397,897.300842 709.947021,898.017883 708.359131,898.281250 
	C704.228821,898.966370 701.571411,900.688477 699.162842,904.605469 
	C697.041077,908.056152 692.043945,909.899414 687.602783,908.544739 
	C684.153748,907.492615 681.868896,909.503540 679.063049,910.121521 
	C675.897766,910.818665 673.856445,912.176208 673.653687,916.019653 
	C673.527771,918.405945 664.795227,921.834778 661.712524,921.426025 
	C657.304504,920.841614 652.766357,919.750610 649.313354,924.047546 
	C648.402039,925.181580 646.950073,925.088623 645.590454,924.728271 
	C642.034241,923.785889 638.713623,924.820312 635.571838,926.257080 
	C631.063354,928.318726 626.836182,931.025024 621.753113,931.631348 
	C619.666504,931.880188 618.239197,933.295837 616.760864,934.689880 
	C612.897339,938.333252 610.472900,938.028870 607.691467,933.672546 
	C607.110229,932.762146 606.444885,932.086060 605.324341,932.196350 
	C603.975769,932.329224 603.178284,933.221130 603.114502,934.498718 
	C602.861816,939.561462 600.349243,940.823242 595.773621,939.199951 
	C594.961182,938.911682 593.691467,939.158264 592.920166,939.628845 
	C587.343262,943.031250 581.252075,943.356689 575.251831,941.860840 
	C567.876099,940.022034 560.790955,941.971924 553.291748,942.715149 
	C552.140991,943.161560 551.281982,943.325562 550.210266,943.744751 
	C547.301208,947.328796 543.271240,947.571350 539.272522,948.757263 
	C538.555542,948.998352 538.111084,948.999146 537.333313,949.000000 
	C532.693909,954.805664 527.077942,955.366455 520.517273,953.606323 
	C516.492676,952.526611 512.385986,954.162781 508.312683,954.529114 
	C502.095245,955.088318 496.342682,954.466064 492.231354,948.351868 
	C490.749512,945.788452 489.277252,944.614807 486.423248,945.616699 
	C483.190399,946.751465 479.924255,945.912537 476.991394,944.285339 
	C475.511688,943.464294 474.216125,942.520630 472.242065,943.734131 
	C470.169464,945.519043 468.265259,945.003601 466.383545,944.160278 
	C461.980743,942.186951 457.123962,941.657104 452.678955,939.812805 
	C451.394104,939.279724 450.085785,939.576355 448.773529,939.948669 
	C446.819641,940.503052 444.579285,940.889771 442.898956,939.898926 
	C437.741699,936.857788 432.328949,936.364014 426.589081,937.261475 
	C425.458008,937.438354 424.177948,937.139160 423.214172,936.521423 
	C413.114990,930.046570 401.068481,929.315491 389.973846,926.926758 
	C378.986420,924.561035 368.739258,919.532776 357.476013,918.577881 
	C357.146973,918.550049 356.736023,918.551636 356.511810,918.362671 
	C350.540131,913.330566 342.896698,912.031128 336.020905,909.008057 
	C331.024323,906.811218 326.471375,903.603516 320.470276,902.985474 
	C319.569183,902.984314 319.065613,902.904724 318.227386,902.617432 
	C314.736755,898.891052 310.800507,896.718994 306.316742,895.065918 
	C300.811554,893.036316 296.902527,888.297485 291.913544,885.404724 
	C282.782501,880.110229 275.233459,872.286804 265.036102,868.721313 
	C262.643280,867.884644 261.345001,865.997742 260.882996,863.527893 
	C260.609039,862.063354 260.675812,860.514404 259.991760,859.152466 
	C259.254395,857.684326 258.550659,855.636841 256.730621,855.807617 
	C255.030624,855.967163 255.846451,857.963623 255.683853,859.176086 
	C255.210632,862.704834 252.795914,861.523071 250.411987,861.141113 
	C247.643280,860.226624 247.450974,857.357788 245.260132,855.871033 
	C244.843033,855.494019 244.690414,855.329346 244.353134,854.886414 
	C243.454300,852.744019 242.106720,851.327209 240.617004,849.602295 
	C240.033813,848.771973 239.718246,848.169434 239.227493,847.261536 
	C238.644806,846.078003 238.315140,845.176514 238.150391,843.843750 
	C238.212128,842.972778 238.370132,842.504150 238.914764,841.832886 
	C242.182785,840.971680 243.356476,843.219788 244.587067,845.142029 
	C246.769577,848.551147 249.646393,850.214172 253.796341,849.790161 
	C258.437988,849.315857 262.480164,851.397949 264.139252,855.384766 
	C267.555359,863.593689 274.869019,864.447571 282.495514,866.797302 
	C282.029358,863.152893 279.337128,862.080261 277.693909,860.269104 
	C276.712799,859.187866 275.033234,858.178101 275.857544,856.535645 
	C276.877502,854.503418 278.779388,855.533752 280.272614,856.052246 
	C285.206604,857.765442 289.504669,860.648254 293.576691,863.824646 
	C295.515442,865.337097 296.960144,867.307129 294.359863,869.611511 
	C293.428589,870.436646 290.748901,870.550598 292.671967,872.603271 
	C294.144165,874.174805 296.369720,876.869324 298.473572,874.655457 
	C301.569092,871.397888 304.135864,872.408081 307.351654,873.908325 
	C310.149475,875.213440 313.710266,874.273621 316.204468,876.803467 
	C317.490479,878.108093 319.350220,879.190979 318.361053,881.335327 
	C317.435333,883.341980 315.522217,882.579834 313.937744,882.257812 
	C311.658051,881.794495 309.400360,881.230896 306.441498,881.511353 
	C308.633728,884.069031 311.064331,885.538574 313.842407,886.401367 
	C322.975891,889.238159 330.793365,894.732605 339.181183,899.046570 
	C343.904938,901.476013 348.715546,903.798096 353.328033,906.402283 
	C367.370117,914.330322 383.431580,916.578064 398.049805,922.821411 
	C400.815857,924.002747 403.823669,924.400513 406.766663,924.977600 
	C408.808746,925.377991 410.667847,925.366028 412.366211,923.831604 
	C415.148163,921.318176 417.598755,922.430725 418.658600,925.394897 
	C420.813660,931.422607 425.282654,932.390930 430.660950,931.551575 
	C434.445953,930.960938 437.754730,931.728638 441.271667,933.218323 
	C446.771027,935.547668 452.745117,934.331360 458.455383,933.960205 
	C461.342163,933.772522 463.608673,933.319214 465.325836,936.119812 
	C466.034271,937.275269 467.174438,938.076050 468.564575,938.283203 
	C478.575348,939.774414 488.493896,941.793823 498.473358,943.459900 
	C500.612488,943.817017 502.786102,943.411377 504.932617,943.062622 
	C509.166504,942.374390 513.141174,942.000977 515.768433,946.733215 
	C517.165466,949.249512 519.803223,948.099365 521.483337,946.820007 
	C526.145630,943.269958 531.385193,942.301941 537.030029,942.541382 
	C538.195251,942.590820 539.395447,942.510864 540.467285,942.022339 
	C550.967957,937.236450 562.351562,936.997131 573.505798,935.592224 
	C575.151733,935.384888 577.084351,935.786987 576.428711,932.990967 
	C575.674133,929.772949 577.825562,928.416321 580.313293,927.551575 
	C583.183289,926.553955 585.930969,927.039612 588.249146,929.064026 
	C589.599487,930.243347 589.449097,933.360962 592.357056,932.474365 
	C594.890320,931.701965 597.632568,930.925476 599.035706,928.426392 
	C603.580017,920.332825 611.934875,919.967041 619.561584,918.177673 
	C621.306458,917.768311 623.046021,918.648315 623.587280,920.425598 
	C624.542114,923.561218 626.617249,923.252380 629.105164,922.876892 
	C637.621338,921.591614 645.284668,917.815979 653.128784,914.643066 
	C655.829346,913.550659 658.742310,912.708069 661.537354,910.929138 
	C660.131104,909.016418 656.678650,908.816101 657.443604,905.731567 
	C658.105164,903.064453 660.648071,902.565125 662.685913,901.537781 
	C667.741272,898.989380 672.933044,896.703491 677.787476,893.769287 
	C681.291748,891.651123 684.512512,890.915833 687.365112,894.884888 
	C688.973022,897.122070 691.265564,896.108154 693.348022,895.648315 
	C699.998108,894.179993 705.249451,889.787537 711.250671,886.954285 
	C714.647949,885.350342 718.405090,884.264771 722.364746,880.951355 
	C717.683533,881.305786 714.984314,883.436462 711.982056,884.794128 
	C709.732605,885.811279 706.854919,888.576050 704.970581,885.528442 
	C703.207031,882.676086 706.284912,880.490540 708.327942,878.555969 
	C712.599487,874.511414 718.444580,874.599426 723.564575,872.793579 
	C725.277954,872.189209 727.129822,871.965637 729.220825,871.277161 
	C730.281677,871.005066 731.004456,870.946594 732.084473,870.909912 
	C733.057617,870.652100 732.492920,871.314087 732.904480,870.854431 
	C737.577148,865.635376 744.311340,862.484131 747.838501,856.166931 
	C748.899231,854.267273 750.059265,852.555603 751.927246,850.887451 
	C752.399536,850.533264 752.597412,850.403748 753.111572,850.114563 
	C758.273315,847.236023 761.505554,851.517822 765.296753,852.912415 
	C769.873901,854.596008 774.039917,853.154602 775.123901,848.564453 
	C775.681152,846.204651 776.966797,845.009155 779.354553,845.575867 
	C781.869202,846.172607 783.476074,845.140442 784.813354,843.188110 
	C787.417908,839.385925 791.219910,838.300964 795.870117,838.779358 
	C796.946411,840.249268 796.547485,841.433716 795.794617,842.934692 
	C793.421082,845.548279 791.919495,848.383911 789.986145,850.767761 
	C786.647156,854.884766 782.531982,858.536133 777.737183,860.955811 
	C775.613708,862.027344 773.163025,862.869995 770.348389,863.139465 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M319.879089,902.355286 
	C327.348724,900.982544 332.562164,905.662537 338.305603,908.898010 
	C339.514465,909.578918 340.321808,911.541138 342.086334,910.269104 
	C345.913147,907.510559 349.412506,910.656250 350.583801,912.573608 
	C354.001740,918.168579 359.743927,917.407104 364.506012,918.742981 
	C367.438263,919.565613 370.733795,919.590759 373.539429,921.045288 
	C382.243805,925.558105 392.313721,924.527222 401.257050,928.038147 
	C408.244263,930.781128 416.405426,929.734070 422.717499,934.804077 
	C424.778259,936.459473 427.595520,936.572876 429.922638,935.736938 
	C434.215851,934.194763 437.753326,935.433411 441.189697,937.772888 
	C442.179474,938.446838 443.192963,939.224548 444.377045,938.951050 
	C453.225983,936.907593 460.759308,941.370667 468.719513,943.742493 
	C469.500000,943.974976 470.366241,943.919495 471.595734,943.998840 
	C471.065033,947.233704 468.282928,946.419983 466.735535,945.558594 
	C462.576080,943.243286 457.667542,943.726013 453.532776,941.428284 
	C451.498840,940.297852 449.389038,941.012207 447.673218,942.794556 
	C445.194244,945.369568 442.302765,945.482727 438.686310,945.302063 
	C425.068268,944.622070 412.738647,939.146667 399.833679,935.845459 
	C392.944366,934.083191 385.737732,933.295837 379.330627,930.054626 
	C373.453827,927.081604 367.141418,926.153076 360.414093,925.001648 
	C359.995117,925.002441 359.497559,925.001526 359.248779,925.000732 
	C351.341095,922.926514 345.058044,917.905945 337.396454,915.423950 
	C330.748901,913.270447 323.687408,909.627014 319.879089,902.355286 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M492.005890,948.370361 
	C497.578125,952.883545 503.912598,954.393005 511.268005,953.145630 
	C515.228455,952.473938 519.250732,951.640381 523.498840,952.854431 
	C528.261841,954.215637 532.565857,952.207642 536.642700,949.042053 
	C535.263245,955.097656 530.917542,958.007507 524.955505,956.030457 
	C518.715942,953.961365 512.747437,954.768677 506.601990,955.853943 
	C499.825500,957.050659 494.115967,954.171265 492.005890,948.370361 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M508.945129,967.622009 
	C507.676300,968.001343 506.353821,968.005432 505.031342,968.003540 
	C502.330139,967.999695 501.567566,968.851807 503.575073,971.107544 
	C504.836487,972.524841 506.078186,974.388062 503.838928,975.645752 
	C501.684387,976.855835 499.751984,975.472290 498.237732,973.715393 
	C496.672333,971.899353 496.593414,970.549011 499.222626,969.756714 
	C500.066620,969.502380 500.675751,968.808289 500.137512,967.869385 
	C499.539886,966.826721 498.573944,966.110413 497.333008,966.077759 
	C494.524872,966.003662 491.714294,966.019470 488.448792,965.995728 
	C489.972137,963.149231 492.280151,961.748230 496.064301,962.741028 
	C499.499603,963.642151 502.793488,965.315857 506.515686,965.131592 
	C507.765808,965.069702 508.566925,966.009460 508.945129,967.622009 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M535.765503,964.739990 
	C531.404480,964.481445 527.817810,968.161743 523.308105,968.040405 
	C520.931641,967.976501 519.873657,970.144043 518.714844,971.496033 
	C516.063721,974.589050 514.505432,974.131653 513.037048,970.346558 
	C516.523010,970.170105 518.655090,967.856995 520.875061,965.638306 
	C526.646423,959.869995 526.651123,959.899597 533.207520,963.782166 
	C533.882568,964.181885 534.751892,964.253601 535.765503,964.739990 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M539.434204,948.998291 
	C541.513733,945.466187 546.118835,946.304810 549.629333,944.043396 
	C548.668884,949.815491 544.068665,949.002075 539.434204,948.998291 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M769.964294,863.348511 
	C772.221191,861.573364 774.259399,859.822632 777.185852,859.929504 
	C779.049194,859.997559 780.063782,858.421875 781.215271,857.256836 
	C783.010620,855.440247 783.950439,852.718384 787.258545,852.618408 
	C788.727234,852.573975 788.615906,850.631104 789.148315,849.452515 
	C790.288940,846.927185 791.715881,844.501343 794.648376,843.040283 
	C798.237122,842.380371 801.365356,841.585510 803.066650,837.892090 
	C803.691711,837.452881 804.075317,837.256165 804.768005,836.992493 
	C805.812561,836.650818 806.544861,836.382935 807.042480,835.312500 
	C807.538330,834.256653 807.940796,833.498657 808.586365,832.485107 
	C813.981995,825.437317 823.337708,823.928040 832.149475,828.912720 
	C835.423401,830.764709 838.098816,831.128845 841.326111,829.161987 
	C842.912659,828.195190 843.987671,827.448059 843.572083,825.368530 
	C843.075806,822.885498 844.035889,821.159607 847.061707,820.797241 
	C849.690308,822.770020 850.515259,825.210510 850.083313,828.026184 
	C849.505249,831.794617 848.170105,835.400635 847.724182,839.220093 
	C847.497864,841.158447 845.987671,842.362488 843.621704,842.828125 
	C842.413391,842.743713 841.711365,842.408875 840.839478,841.611938 
	C839.488220,839.894043 840.416382,838.147095 839.942139,836.549561 
	C837.373108,837.290405 838.490540,839.021545 838.617310,840.570129 
	C838.973999,844.928101 837.771179,846.398315 833.634827,846.289917 
	C831.803101,846.241943 830.772400,846.694092 830.367371,848.463806 
	C829.985474,850.132751 829.268738,851.598877 827.801636,852.596802 
	C825.763428,853.983093 823.488281,854.920532 821.334351,853.300293 
	C819.179504,851.679382 820.600403,849.728333 821.545959,847.972900 
	C821.784302,847.530334 821.979553,847.074524 821.806763,846.196106 
	C819.218140,845.819214 818.852539,847.938293 818.050354,849.585815 
	C816.693115,852.373413 814.733765,852.559753 812.414185,850.746460 
	C807.553528,846.946899 801.048096,847.464722 796.744263,852.063354 
	C795.720215,853.157471 794.836670,854.902405 793.515076,855.077515 
	C785.893738,856.087952 784.941101,862.275452 783.015320,867.860901 
	C782.425049,869.572876 782.761719,871.089783 782.933716,872.732300 
	C783.283447,876.072144 781.463745,877.918945 778.305054,877.842346 
	C767.280579,877.575378 758.641479,881.928589 752.144043,890.742981 
	C751.851624,891.139648 751.442993,891.450684 750.854248,892.062134 
	C749.287903,893.236084 747.890015,894.061829 746.584045,895.567627 
	C743.631348,898.794617 740.514587,900.599182 736.249023,900.070007 
	C734.290466,899.827087 732.290588,900.386475 730.199646,901.640991 
	C729.067871,901.911255 728.322449,901.840515 727.316772,901.226562 
	C727.016235,900.985352 727.000000,901.000000 727.006714,900.991455 
	C726.583313,900.580322 726.147095,900.182129 725.996521,899.281006 
	C726.577881,894.033997 728.500916,891.230164 734.294800,890.650330 
	C738.419434,890.237488 741.887085,886.222656 746.514587,885.856873 
	C746.878967,885.828064 747.405701,885.338623 747.514893,884.963684 
	C750.163025,875.867432 750.440552,875.601196 759.738892,873.583313 
	C760.220947,873.478699 760.679932,873.267639 761.541382,873.046387 
	C763.111572,873.012573 764.141724,872.768250 764.810425,871.235779 
	C766.509766,868.446411 769.383118,866.861694 769.964294,863.348511 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M700.315430,914.845581 
	C699.249512,915.000977 698.492065,914.997009 697.356079,914.987976 
	C694.561401,910.968384 697.625183,910.343628 700.686646,909.263672 
	C702.331543,908.270447 703.732483,907.878784 705.628296,908.003540 
	C706.670105,907.187439 707.641968,906.849670 708.769043,906.195679 
	C709.552124,905.825745 710.101746,905.648987 710.824463,905.233765 
	C713.826416,902.828003 716.526062,900.389282 720.729370,900.761475 
	C720.998047,904.720398 718.573059,907.236938 715.573242,907.729553 
	C709.812439,908.675598 705.471375,912.104736 700.315430,914.845581 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M721.262634,901.013306 
	C717.411560,901.110596 714.882019,903.776184 711.355469,904.954712 
	C713.784851,900.962891 717.391296,898.374023 722.758301,898.720825 
	C723.474609,899.712769 723.029846,900.163208 722.252747,900.790833 
	C721.993591,900.990234 721.506287,901.005249 721.262634,901.013306 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M700.606934,909.007202 
	C700.907043,911.699219 695.878967,910.580017 696.985229,914.596924 
	C696.227722,914.982178 695.478088,914.981445 694.352783,914.966431 
	C694.148560,910.978394 695.720764,908.469788 700.606934,909.007202 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M764.649048,870.971802 
	C765.453857,872.078247 766.356934,873.233459 764.981689,874.302917 
	C763.792969,875.227295 762.986816,874.458862 762.097168,873.267334 
	C762.078369,871.562073 762.920227,870.959534 764.649048,870.971802 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M689.349365,918.962158 
	C689.000244,918.831116 688.995667,918.659851 688.979004,918.231445 
	C688.976807,917.537903 688.986694,917.101440 688.999512,916.337463 
	C690.420898,915.673828 691.839417,915.337769 693.626221,914.996887 
	C693.540894,917.374390 691.477783,917.995361 689.349365,918.962158 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M722.411743,900.994995 
	C722.256531,900.494202 722.519470,899.998169 722.891968,899.242554 
	C723.749573,898.986511 724.497742,898.990051 725.619812,898.989746 
	C726.315491,899.454956 726.637207,899.924011 726.986145,900.687988 
	C725.618958,900.988525 724.224426,900.994080 722.411743,900.994995 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M708.650391,906.007141 
	C708.536499,907.040649 707.809937,907.762085 706.320435,907.972961 
	C706.092773,906.558838 706.935852,905.985107 708.650391,906.007141 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M319.666687,902.000000 
	C323.402344,904.981689 326.609528,907.980408 330.701538,910.335632 
	C339.831848,915.590637 350.418427,917.804749 358.937408,924.649536 
	C355.502655,926.294922 352.420868,925.573303 349.348145,923.045776 
	C348.308899,923.580688 347.539215,923.734192 346.341248,923.248535 
	C345.450317,922.836243 344.904053,922.652893 344.183044,922.233521 
	C343.533630,921.514771 343.017853,921.074890 342.350311,920.315430 
	C339.659393,917.126648 335.519409,918.113342 332.332947,916.022217 
	C331.562622,915.994568 331.126099,915.986755 330.362122,915.975098 
	C329.245636,915.182678 328.275391,914.599182 327.289673,913.519348 
	C324.313354,910.984863 321.422607,908.892517 318.600433,906.711426 
	C317.195587,905.625610 315.838196,904.391968 315.877991,902.076843 
	C316.480560,901.539429 316.943420,901.480957 317.712982,901.778809 
	C318.444458,901.998352 318.888885,901.999146 319.666687,902.000000 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M368.961914,954.437622 
	C366.609192,953.170776 367.447021,952.234131 368.912933,951.596252 
	C370.674011,950.830078 372.371185,948.778259 374.258209,949.739746 
	C375.773315,950.511658 375.111664,952.779907 374.843811,954.333557 
	C374.455109,956.588379 372.828949,955.195679 371.699188,955.086609 
	C370.885590,955.008118 370.087677,954.767090 368.961914,954.437622 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M346.007385,923.388062 
	C346.745789,923.015076 347.494995,923.010559 348.618591,923.006714 
	C349.126617,925.302795 350.930908,926.383179 352.703674,927.850952 
	C352.859100,929.441650 352.360992,930.658691 350.714294,930.794678 
	C349.048065,930.932251 348.992310,929.432068 348.689423,928.406921 
	C348.162048,926.621887 345.938843,925.852600 346.007385,923.388062 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M331.996887,916.253052 
	C335.840515,916.435669 340.009094,915.746460 342.986755,919.597778 
	C342.606323,920.346985 342.144012,920.493042 341.308929,920.214233 
	C338.242218,919.024536 335.483185,918.053955 332.360260,917.044128 
	C331.995728,916.837830 331.995148,916.670715 331.996887,916.253052 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M341.059875,920.337036 
	C341.446167,919.995544 341.891113,919.995972 342.669861,919.995728 
	C343.321198,920.465698 343.638672,920.936279 343.982239,921.702209 
	C342.871338,921.938782 341.604675,922.164124 341.059875,920.337036 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M727.047485,901.337158 
	C727.762024,900.986633 728.507874,900.987915 729.626831,900.989746 
	C730.742493,900.972107 731.437500,901.126953 732.301147,901.822510 
	C733.475769,903.823364 733.334473,905.784058 733.758179,907.988525 
	C733.833618,909.329468 733.574402,910.236694 732.544250,911.545898 
	C730.130066,912.142639 728.420044,911.528015 727.049255,910.354553 
	C725.247559,908.812256 723.523621,906.907410 726.609131,905.032654 
	C728.417847,903.933655 727.140808,902.821472 727.047485,901.337158 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M740.290039,913.623108 
	C739.356567,918.705078 738.370667,919.071594 733.331909,916.163330 
	C731.828979,915.166748 732.053955,913.911316 732.012512,912.373901 
	C732.012817,912.010986 732.000854,912.002502 732.005737,912.007324 
	C733.528137,910.236389 735.261230,908.996460 738.144653,909.764221 
	C739.008972,909.979919 739.468750,910.195923 740.161621,910.764404 
	C740.778748,911.786926 740.808228,912.537842 740.290039,913.623108 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M740.436279,914.005493 
	C740.034180,913.239563 739.995850,912.533508 739.949463,911.473206 
	C740.567688,907.411255 743.939392,907.645508 746.390137,906.992798 
	C748.859070,906.335205 751.173462,905.903381 752.712952,903.580078 
	C753.578796,902.273438 755.122681,901.855408 756.603210,901.555481 
	C759.791138,900.909790 761.964539,899.161987 763.117676,896.058411 
	C764.230347,893.063843 766.468140,891.643677 769.699829,891.813721 
	C771.052856,891.884949 772.716370,891.864319 773.362793,890.686707 
	C775.711670,886.407410 780.488159,884.229065 782.629639,879.405029 
	C782.867859,878.296570 783.183594,877.618408 783.896545,876.725708 
	C784.796082,875.982483 785.500916,875.717041 786.645264,875.847839 
	C787.009155,877.637939 787.016968,879.218201 785.924072,880.671021 
	C784.541870,882.508301 784.522949,884.531067 786.665039,885.723938 
	C789.010681,887.030273 789.084412,888.998413 788.996094,891.587280 
	C788.462524,895.530762 787.342163,898.940186 786.851074,902.503967 
	C786.500061,905.052063 784.735413,906.928040 782.315430,907.974487 
	C780.630737,908.703064 779.137634,909.679871 777.494446,911.159302 
	C776.662537,911.556885 776.149170,911.586060 775.315308,911.221436 
	C770.209778,907.052368 767.650085,907.209717 764.584534,911.960876 
	C763.529114,913.596558 762.567261,913.747864 760.923706,913.156860 
	C757.295532,911.852173 753.930420,912.208679 750.623413,914.506042 
	C747.402588,916.743591 744.001160,915.942078 740.436279,914.005493 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M774.776917,911.291992 
	C775.445801,910.979370 775.888000,910.976868 776.661804,910.970093 
	C779.490173,910.311401 781.698181,909.351624 784.130249,911.947571 
	C785.972656,913.914246 786.301636,910.893616 786.680908,909.929688 
	C787.621155,907.539551 787.644470,904.565735 791.570190,904.718872 
	C791.889038,905.100220 791.919983,905.289795 792.006409,905.762695 
	C792.054016,906.775024 792.046204,907.503967 792.050659,908.597046 
	C791.133240,912.139771 789.908203,915.164124 787.286255,917.805908 
	C785.732971,918.913391 784.279541,919.156860 782.381714,919.038330 
	C781.266724,918.985352 780.520508,918.998962 779.401184,919.015503 
	C775.344971,918.075806 777.775452,921.671387 776.224487,922.731934 
	C775.218201,923.307312 774.443481,923.634094 773.354736,923.993835 
	C771.901123,923.776001 770.761536,923.525208 769.296631,923.131226 
	C768.976318,922.544006 768.981262,922.099915 768.999023,921.322754 
	C772.026245,921.376709 773.283020,920.129456 773.050232,917.038879 
	C772.906555,915.131531 772.423279,912.957214 774.776917,911.291992 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M726.747192,937.791687 
	C726.975647,938.471985 726.983032,938.908508 726.993896,939.672485 
	C723.553589,940.640503 719.882019,943.217834 717.010498,938.427612 
	C719.216980,936.527832 720.723328,934.110168 723.915466,933.242249 
	C725.487000,934.645447 725.471619,936.356201 726.747192,937.791687 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M727.683228,930.036499 
	C727.756775,930.805176 727.500305,931.602600 727.113403,932.690979 
	C726.322937,933.611084 725.552246,933.730347 724.357544,933.245605 
	C724.014465,933.014465 724.004944,933.004944 724.000427,933.000000 
	C724.753357,931.604065 725.705322,930.435913 727.683228,930.036499 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M765.818237,920.704834 
	C765.542969,921.259949 765.080872,921.609802 764.315308,921.985046 
	C764.162231,921.089172 764.166748,920.011719 765.818237,920.704834 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M722.572083,953.479248 
	C724.448303,956.181458 725.126831,958.743225 721.965576,960.382385 
	C719.789490,961.510803 717.396667,961.614136 715.989807,958.882996 
	C714.621521,956.226624 714.233032,954.036011 718.357483,953.913574 
	C719.651001,953.875305 720.934570,953.503479 722.572083,953.479248 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M213.252625,858.004272 
	C209.198395,855.269287 211.721222,851.259033 210.979965,847.457642 
	C213.678864,845.287537 214.829239,846.929321 215.846741,849.150818 
	C218.215118,854.321899 222.516113,856.498413 227.983093,856.772156 
	C228.648315,856.805542 227.835907,856.643066 228.478439,856.774963 
	C235.007721,858.115112 239.747406,863.113159 245.927689,865.391296 
	C248.275970,866.256897 248.211197,870.597107 247.995407,873.680908 
	C247.504395,880.697571 255.085739,886.746033 261.628784,884.508423 
	C264.495880,883.527893 266.679352,883.940063 268.727112,886.320129 
	C271.541443,889.591309 274.598297,892.573120 275.810516,896.975830 
	C276.762268,900.432434 279.621735,900.949707 282.927216,901.058777 
	C289.794464,901.285400 296.641296,901.687744 303.730896,900.749512 
	C303.780579,903.057556 302.163879,903.525391 300.570465,903.919861 
	C296.736053,904.869080 297.328522,906.704712 299.886230,908.352661 
	C302.861206,910.269409 302.215607,911.379456 299.224976,912.732544 
	C297.325043,912.949890 295.324615,912.695435 294.238831,913.980408 
	C290.726196,918.137085 286.295654,917.182922 283.306519,914.650146 
	C280.121918,911.951843 277.398651,910.790100 273.206299,910.401062 
	C270.007416,910.104248 266.547485,908.013672 263.141571,906.848633 
	C260.732941,906.024780 257.780670,905.434570 257.913269,901.790527 
	C257.928680,901.367004 257.494690,900.544495 257.291779,900.551636 
	C251.974274,900.739380 251.683517,895.136658 248.467041,892.923828 
	C247.392288,892.184509 246.618179,890.875854 245.071823,891.181335 
	C238.999466,892.380859 240.716415,888.648682 242.105194,886.011963 
	C244.163635,882.103821 244.236511,880.505249 239.026459,879.585693 
	C234.845627,878.847717 231.062668,875.956970 226.655533,875.005493 
	C224.375015,874.513000 223.124725,872.901917 222.624313,870.116699 
	C222.175781,867.620178 221.858139,864.137573 217.602493,863.766418 
	C214.507034,863.496338 217.338608,859.253235 214.371277,858.086060 
	C213.996780,858.004761 213.500641,857.998840 213.252625,858.004272 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M321.974213,942.542358 
	C321.527985,942.932312 321.089752,942.949890 320.322205,942.970337 
	C315.921295,943.640198 312.755981,941.089539 309.264099,939.808228 
	C307.674652,939.225037 306.864288,937.553345 307.739410,935.321960 
	C309.945251,935.835327 312.321991,937.187439 313.644226,934.757629 
	C315.063141,932.150085 313.452698,930.412292 310.849304,929.068787 
	C309.210999,928.223389 306.806000,926.564026 309.474854,924.116760 
	C310.686066,923.006042 312.224213,922.306885 312.912079,924.500549 
	C313.865204,927.540283 316.055847,927.743469 318.798615,927.770386 
	C319.592529,929.190186 318.683411,930.348145 318.885895,931.731323 
	C319.230103,930.856018 319.094696,930.071228 319.768921,929.219116 
	C322.433777,932.675537 324.918640,936.289551 329.786377,937.720215 
	C328.799835,939.859192 327.821350,941.653992 325.012665,939.759888 
	C322.361206,937.971924 322.063873,940.171509 321.974213,942.542358 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M307.823792,934.764160 
	C307.772583,937.278748 308.854004,938.693787 310.938019,939.432251 
	C313.791107,940.443359 316.648071,941.443604 319.748169,942.710815 
	C318.170288,945.277527 314.706940,944.907043 312.912506,947.347412 
	C312.448181,947.978821 311.369446,948.145691 310.679413,947.453430 
	C309.730804,946.501587 310.301971,945.723267 311.143768,944.991089 
	C312.266602,944.014526 314.079956,942.270142 311.078613,942.161560 
	C304.486115,941.922852 303.334473,936.971191 301.714630,932.206604 
	C302.122559,931.817993 302.298859,931.645020 302.742310,931.728699 
	C303.009491,931.985352 303.499817,931.998657 303.744995,932.004028 
	C304.523773,932.541260 305.062988,933.067383 305.806763,933.788452 
	C306.555695,934.166565 307.103485,934.346252 307.823792,934.764160 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M319.894257,928.779419 
	C320.056305,929.642151 320.073853,930.295288 320.155579,930.940308 
	C320.306915,932.134766 321.667450,933.410217 320.192352,934.451660 
	C319.791870,934.734375 318.631683,934.255920 318.007019,933.843689 
	C316.498901,932.848267 317.266571,931.579346 317.824310,930.395386 
	C318.102234,929.805542 318.414612,929.231995 318.855347,928.330444 
	C319.317352,928.113586 319.577209,928.299072 319.894257,928.779419 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M300.266174,929.785339 
	C296.581848,931.224304 295.010132,928.608215 293.051544,926.337646 
	C293.442108,925.996704 293.886627,925.990906 294.664612,925.985901 
	C295.907745,925.851440 296.778503,925.893433 297.758118,926.770203 
	C298.493713,927.582275 298.987427,928.164490 299.745728,928.869751 
	C300.181641,929.169067 300.352966,929.345459 300.266174,929.785339 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M289.387268,925.980286 
	C288.728058,925.476196 288.471863,924.991516 288.107849,924.253418 
	C289.642456,924.308777 291.655640,923.173035 292.930542,925.604797 
	C291.940216,925.977173 290.865265,925.988403 289.387268,925.980286 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M297.964355,926.672974 
	C297.139496,926.834778 296.279022,926.669617 295.208252,926.245544 
	C295.161163,925.256958 295.175720,924.311768 296.247314,924.424561 
	C297.306396,924.535950 297.707581,925.405090 297.964355,926.672974 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M300.262726,930.170959 
	C300.007843,929.872864 300.007660,929.696838 300.008881,929.256775 
	C301.595947,928.962891 303.004059,929.166992 302.997620,931.618042 
	C302.832520,931.987427 302.655518,931.989502 302.212372,931.991333 
	C301.469940,931.425049 300.993683,930.859070 300.262726,930.170959 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M305.958496,933.670410 
	C305.105652,933.913513 304.330261,933.659119 304.039734,932.325806 
	C304.891571,932.084045 305.669556,932.334839 305.958496,933.670410 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M304.350616,901.005920 
	C301.591492,901.548157 299.012848,901.727478 296.818085,902.739441 
	C294.478027,903.818420 292.437561,903.907043 290.184296,902.919373 
	C288.322968,902.103333 286.381561,901.808472 284.346588,902.105042 
	C277.169708,903.150513 275.741974,902.641235 273.969147,895.797729 
	C272.768188,891.161865 268.439697,889.433289 266.335571,885.693604 
	C265.387604,884.008789 262.636353,884.830994 261.091125,886.446045 
	C259.091034,888.536377 257.355194,888.085999 255.627808,886.132141 
	C254.621231,884.993469 253.202667,884.500732 251.940826,883.744324 
	C248.188812,881.495239 244.921280,879.170959 246.525009,873.498352 
	C248.315170,867.166321 245.012131,863.706848 238.452347,864.437622 
	C236.304962,864.676819 235.717148,863.670898 235.192917,862.327087 
	C233.612091,858.274780 230.587631,857.514954 226.721039,858.233582 
	C222.520599,859.014160 215.152527,853.765320 214.698044,849.510132 
	C214.475876,847.429993 213.298019,847.135864 211.348236,847.012695 
	C209.885880,843.698303 209.902420,840.284363 210.091461,836.412231 
	C213.014343,834.109375 215.936172,837.155884 219.182449,835.931091 
	C219.760956,835.995972 219.988922,836.043701 220.540421,836.219421 
	C222.617691,837.779236 224.535675,838.956299 226.814575,840.208252 
	C227.255447,840.573975 227.417725,840.737183 227.781433,841.180664 
	C228.711960,843.577148 230.149841,844.636536 232.431717,844.741211 
	C234.128311,844.819092 236.592545,845.489197 236.600662,846.879456 
	C236.633545,852.517334 241.759094,851.506470 244.748108,853.771240 
	C244.993134,854.009521 244.993958,854.505981 244.995758,854.754211 
	C247.089600,856.388794 247.304962,859.397034 250.076630,860.538208 
	C251.095490,860.840881 251.572433,861.316528 252.044861,862.268005 
	C252.467529,863.334717 252.477493,864.238098 253.368378,864.639832 
	C259.983765,867.623657 264.747101,872.899170 269.637299,877.970703 
	C271.306366,879.701599 273.260986,880.097229 275.741089,880.461609 
	C277.106720,881.266052 277.749725,882.292236 278.669403,883.547424 
	C285.110382,891.196289 291.502991,898.138916 302.521332,897.219666 
	C305.445923,896.975525 308.201141,899.015198 311.626587,898.860291 
	C313.482239,899.327820 314.845856,899.940613 315.879120,901.637695 
	C318.875641,906.684753 324.231781,908.508118 327.946472,912.642578 
	C327.345673,913.657349 326.560120,913.762085 325.343445,913.240234 
	C323.901001,912.668030 322.806976,912.331177 321.364319,911.987793 
	C317.678986,907.709839 312.367462,906.042175 308.242859,902.244202 
	C306.896118,901.674255 305.798065,901.341858 304.350616,901.005920 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M308.001831,902.380981 
	C313.285614,903.737061 317.751465,906.617432 320.972260,911.636902 
	C317.486938,910.599548 314.740845,907.733826 310.780487,906.998901 
	C309.283722,906.721130 307.940308,904.834106 308.001831,902.380981 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M325.057434,913.351624 
	C325.748993,913.001465 326.502960,912.997986 327.634125,912.989990 
	C329.031372,913.462585 329.759430,914.181946 329.998413,915.654175 
	C328.041718,915.980591 326.157837,915.754028 325.057434,913.351624 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M332.997528,955.630249 
	C330.915283,955.462524 330.221527,954.602966 332.664429,953.047974 
	C332.995087,953.744507 332.997498,954.498840 332.997528,955.630249 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M325.935577,944.661621 
	C324.861206,944.942261 323.607422,945.158997 323.053864,943.361572 
	C324.134979,943.054810 325.394806,942.824890 325.935577,944.661621 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M133.992615,827.643311 
	C133.982742,828.436951 133.973846,828.873962 133.960663,829.638672 
	C132.888718,831.814697 134.816025,831.831848 135.770325,832.796753 
	C136.461075,833.329834 136.934036,833.643372 137.702759,833.982178 
	C141.713867,835.503235 141.121811,837.990173 139.196243,840.937012 
	C135.866669,836.510254 129.956467,835.581787 126.551476,831.578613 
	C125.245598,830.043396 124.872345,827.714905 124.002335,825.388672 
	C123.881165,822.896179 124.416534,820.702820 123.095108,818.326294 
	C122.924294,817.540344 122.936707,817.109497 122.937317,816.355164 
	C121.502541,813.593689 122.988472,811.172913 122.958603,808.369507 
	C122.918541,807.587097 122.888466,807.178345 122.835808,806.463013 
	C122.178452,805.078369 121.878929,803.969910 122.781174,802.425415 
	C123.625168,801.549622 124.195183,801.025757 124.893204,800.238403 
	C124.579956,799.598450 124.194397,799.166321 123.586960,798.542114 
	C123.082298,798.074890 122.810410,797.790161 122.399475,797.230957 
	C122.001099,796.433655 121.811317,795.864014 121.255402,795.150513 
	C120.985756,794.199890 120.904274,793.481079 120.757614,792.406494 
	C121.097244,791.586243 121.502052,791.121704 121.930321,790.330750 
	C121.824242,789.123535 121.905724,788.289978 122.705872,787.325073 
	C124.378906,786.928833 125.756630,787.030151 126.856812,788.713501 
	C128.422836,790.599243 127.071632,792.881409 128.593689,794.768799 
	C129.194763,795.918579 129.503952,796.818420 129.911331,798.082031 
	C130.391693,800.258484 130.237167,802.002319 129.125946,803.962036 
	C125.856789,811.243774 127.623314,820.471252 133.010696,825.579407 
	C133.506241,826.049316 133.806290,826.636780 133.992615,827.643311 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M120.500992,777.937134 
	C123.211784,780.665527 118.298302,783.479004 120.978195,786.602051 
	C120.858894,787.100159 120.694344,787.241760 120.244431,787.226440 
	C119.646301,786.938965 119.389008,786.737488 119.063110,786.250977 
	C119.016052,783.303772 115.728172,780.077332 120.500992,777.937134 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M119.976807,787.411621 
	C120.136200,787.049500 120.313339,787.029358 120.756958,786.983948 
	C121.443695,786.988770 121.863945,787.018921 122.598473,787.064026 
	C122.769241,787.922180 122.625732,788.765381 122.218002,789.806519 
	C120.541969,789.914612 120.006554,789.061951 119.976807,787.411621 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M138.980774,841.394714 
	C138.815033,838.971313 140.787537,836.605408 138.151260,834.309937 
	C141.001129,832.750977 141.483475,834.934875 141.953751,836.980469 
	C142.351547,838.710876 142.588074,840.478394 142.939713,842.622009 
	C142.204056,843.005432 141.424683,842.996338 140.251678,842.979187 
	C139.608322,842.520935 139.358551,842.070679 138.980774,841.394714 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M122.663605,808.230347 
	C125.112305,810.378723 123.738075,812.823608 123.000717,815.643921 
	C122.483757,816.022644 122.041992,816.013550 121.266960,815.996643 
	C120.952614,813.391968 120.579231,810.719727 122.663605,808.230347 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M114.387543,804.952942 
	C115.306801,803.106689 114.803268,800.248901 117.662796,799.056274 
	C116.953239,801.005432 120.823326,805.676819 114.387543,804.952942 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M122.707260,802.052368 
	C123.017639,803.161621 122.980118,804.249695 122.877899,805.747131 
	C119.740227,803.918030 119.671425,803.292725 122.707260,802.052368 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M122.734436,796.751221 
	C123.345276,797.176941 123.632645,797.396606 124.079102,797.838318 
	C124.118507,798.393188 123.936478,798.686462 123.442459,799.039246 
	C122.079155,799.095032 120.956604,799.046021 119.413055,798.970337 
	C119.091164,798.050232 119.374916,797.295044 120.700333,797.039062 
	C121.407654,796.617615 121.872589,796.467590 122.734436,796.751221 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M123.365685,799.346375 
	C123.375908,798.840027 123.550102,798.535950 123.981262,798.146118 
	C124.502754,798.513367 124.767288,798.966431 125.026520,799.697205 
	C124.524025,799.832886 124.026840,799.690796 123.365685,799.346375 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M123.028152,796.630249 
	C122.604088,796.971436 122.150276,796.985657 121.356308,796.997559 
	C121.009323,796.533447 121.002480,796.071594 121.031433,795.264282 
	C122.004929,794.970215 122.788704,795.236389 123.028152,796.630249 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M127.012665,840.327820 
	C127.661575,840.079712 128.428421,840.148438 128.479980,840.945984 
	C128.500366,841.261353 127.938881,841.614441 127.358627,842.016357 
	C127.052765,841.614441 127.030617,841.146545 127.012665,840.327820 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M136.791931,778.727600 
	C137.788025,779.313843 138.575455,779.629456 139.681442,779.972534 
	C141.014938,780.055176 141.807419,780.970398 142.808380,780.876404 
	C146.879303,780.493958 148.185257,782.586792 147.748306,786.268616 
	C147.640091,787.180542 147.986725,787.866577 148.752441,788.772339 
	C148.856323,790.571777 150.608505,791.906494 149.254272,793.785583 
	C145.519547,797.292664 140.677643,797.994995 136.422241,800.311157 
	C135.263000,799.468445 135.033600,798.498169 135.171646,797.506714 
	C135.576492,794.598816 135.640182,792.231140 131.450180,792.615051 
	C129.675278,792.777710 128.979141,791.283447 129.764130,789.237183 
	C130.313507,787.888062 130.647873,786.798035 130.984818,785.360962 
	C133.179489,779.670349 133.377579,775.071228 126.806091,772.577576 
	C125.986786,772.266724 125.644859,770.697754 125.038673,769.353394 
	C124.995621,768.995117 125.001984,768.499939 125.004448,768.252319 
	C127.517487,765.738159 126.596001,763.163330 125.822708,760.283691 
	C126.305702,757.919128 126.639038,755.859131 126.979080,753.407349 
	C126.989044,751.945251 126.992302,750.874878 126.996643,749.403503 
	C130.623016,747.770020 131.076889,750.477295 131.002884,752.736145 
	C130.888123,756.239136 132.157486,758.440002 135.715851,759.772217 
	C136.167999,760.562378 136.344620,761.110107 136.760315,761.828918 
	C141.814362,769.027100 141.814362,769.027100 138.265289,773.715820 
	C137.996445,773.997559 138.000000,774.000000 137.998718,773.998535 
	C136.767899,775.243347 135.003662,776.320068 136.791931,778.727600 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M125.655487,760.037842 
	C128.277771,762.693909 128.584961,765.339233 125.352341,767.939087 
	C127.106300,765.452148 123.264366,762.650330 125.655487,760.037842 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M129.610123,788.983887 
	C130.097580,790.429138 130.083374,792.585205 132.227661,791.647278 
	C138.511566,788.898865 136.578201,793.602295 136.686432,796.365112 
	C136.722992,797.298340 136.253967,798.251282 135.799377,799.879517 
	C132.681213,802.135132 132.893234,804.428162 134.285690,806.819214 
	C135.983276,809.734131 136.439590,812.684509 134.827881,815.721375 
	C133.646240,817.947815 134.561737,819.452820 136.383301,821.164795 
	C136.618195,823.566101 135.500916,824.628296 133.580490,824.755127 
	C131.687988,824.880066 130.531479,823.753357 129.787888,822.088745 
	C128.888626,820.075623 128.351959,817.995361 128.706177,815.795776 
	C129.322983,811.965271 128.828918,808.270081 127.756859,804.306091 
	C128.971848,802.365784 129.074692,800.591492 128.969284,798.387451 
	C129.578186,796.979675 129.449509,796.118164 128.196930,795.236755 
	C124.367180,793.911194 126.695290,791.625793 126.976974,789.387329 
	C127.765450,789.010559 128.503281,789.000183 129.610123,788.983887 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M135.993805,759.626709 
	C129.497375,759.933044 129.373398,759.828918 129.871353,752.928101 
	C130.004105,751.088440 129.731354,749.657227 127.363266,749.050659 
	C126.997719,749.002441 126.995338,748.504211 126.993614,748.255127 
	C126.340996,747.333069 125.678139,746.666687 125.847900,745.314697 
	C126.494667,744.738403 126.988708,744.476807 127.739899,744.104614 
	C129.014969,745.383545 129.652054,747.100037 131.675949,747.951538 
	C131.998779,748.000000 132.001541,748.496460 132.002899,748.744568 
	C131.219757,753.232117 136.539307,755.093384 135.993805,759.626709 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M120.159775,741.247314 
	C121.248116,738.818542 121.424942,736.771240 119.393364,734.853943 
	C117.790901,733.341614 117.625336,731.485535 118.730331,729.249390 
	C119.488632,731.766724 121.317215,733.239258 123.845245,734.097839 
	C127.028976,735.179016 127.892586,736.556580 124.278427,738.356689 
	C123.049866,738.968567 121.915939,739.649658 122.002274,741.650696 
	C121.438927,741.838867 120.887672,741.661865 120.159775,741.247314 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M125.666702,744.999146 
	C126.315269,745.789001 126.629944,746.578064 126.968262,747.686523 
	C125.473465,747.916016 124.412422,747.252197 124.035843,745.343323 
	C124.442383,745.003235 124.887596,745.000793 125.666702,744.999146 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M128.644653,208.775238 
	C128.429840,210.363953 127.378990,210.997650 125.614433,211.073257 
	C125.231255,210.663101 125.166283,210.235916 125.122414,209.485748 
	C125.547729,209.119049 125.951942,209.075363 126.660980,209.004578 
	C127.360397,208.640778 127.814705,208.507126 128.644653,208.775238 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M129.203125,208.801804 
	C128.517715,208.979218 128.074875,208.984482 127.298920,208.983612 
	C125.177887,205.574402 123.737846,199.626740 123.720558,194.244354 
	C125.334846,195.116882 125.271469,196.876312 125.970589,198.658112 
	C127.383881,200.194519 126.734413,202.088776 127.782967,203.733612 
	C128.552994,204.518738 129.107895,205.035248 129.816620,205.794693 
	C130.107742,206.191605 130.245041,206.345596 130.175964,206.730164 
	C129.794952,207.517059 129.620331,208.073349 129.203125,208.801804 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M128.001617,203.585419 
	C126.081078,203.171021 125.910820,201.518616 126.003708,199.376312 
	C126.453026,199.004257 126.895630,199.001556 127.670334,199.000336 
	C128.003342,200.390762 128.004227,201.779694 128.001617,203.585419 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M127.063545,255.294617 
	C127.923561,255.085083 128.694092,255.359161 128.933685,256.702850 
	C128.072739,256.913300 127.303085,256.638245 127.063545,255.294617 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M130.137360,206.885239 
	C129.969589,206.960754 129.972076,206.498810 129.971268,206.268219 
	C130.314270,206.194321 130.679489,206.341736 130.137360,206.885239 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M284.043396,927.347656 
	C284.534851,926.133301 285.416107,926.018066 286.693665,926.047485 
	C286.463226,927.355896 286.671448,930.096130 284.043396,927.347656 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M208.035339,860.003052 
	C208.044617,860.752502 208.025055,861.500916 207.993744,862.623413 
	C206.212540,863.036011 204.590683,862.823364 204.043259,860.353882 
	C203.998810,860.000000 204.000519,860.000000 204.002167,859.997559 
	C204.984436,859.194824 206.052521,858.958862 207.594452,859.746765 
	C207.948822,860.000000 208.006500,860.002014 208.035339,860.003052 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M185.125778,870.213196 
	C185.419525,869.312256 185.958008,868.983765 186.880615,869.770508 
	C186.588638,870.668396 186.052948,871.014404 185.125778,870.213196 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M207.963791,859.563171 
	C206.897354,859.998840 205.845901,859.997681 204.399109,859.995850 
	C201.595276,854.869324 199.344543,854.103455 194.440125,856.740723 
	C188.218384,860.086487 181.192474,857.732605 179.147446,851.333130 
	C179.205750,847.967529 177.647568,846.794189 174.390961,846.999817 
	C173.838318,846.963928 173.600418,846.984131 173.526291,846.883606 
	C170.736603,843.099915 168.366318,839.328247 162.566803,839.049377 
	C159.644089,838.908875 159.553085,835.057373 162.090027,831.808960 
	C162.767426,830.941589 164.215942,830.339111 163.712982,829.006897 
	C163.176041,827.584595 161.682617,827.559265 160.344421,827.418701 
	C150.541824,826.389038 150.540207,826.381042 149.431900,816.431091 
	C149.356873,815.757507 149.196075,815.137512 148.440796,814.440186 
	C147.042099,813.734314 145.871780,813.713623 144.714767,813.890381 
	C140.698425,814.504028 138.066589,813.342102 138.224915,808.769714 
	C138.279541,807.192139 137.384705,806.390198 136.350510,805.491577 
	C134.763519,804.112488 134.525620,802.411072 135.761429,800.273438 
	C135.993286,799.995117 135.999680,800.000000 135.997116,799.996582 
	C139.475006,796.777588 143.783463,795.256775 148.614716,794.009277 
	C148.437653,799.809631 153.999252,800.881958 156.990173,803.648071 
	C160.252289,806.664978 161.194138,808.562744 157.477478,810.517456 
	C153.230942,812.750793 155.469940,814.350220 157.634796,815.693237 
	C160.284485,817.337097 162.649857,818.416870 163.619568,822.176697 
	C164.265640,824.681641 168.348236,825.557251 171.770721,825.260498 
	C178.985092,826.987793 179.614990,829.710449 174.218033,835.094482 
	C173.498428,835.812317 173.278610,836.330200 173.653854,837.174622 
	C174.171387,838.339294 175.022720,838.965881 176.349976,838.984314 
	C180.026978,839.035278 183.632462,840.222473 187.924896,838.928589 
	C186.672836,841.824707 184.860352,841.771545 183.099228,842.068359 
	C181.490982,842.339355 178.801376,842.320251 179.821350,844.942566 
	C180.526810,846.756165 182.259125,848.706482 184.969879,847.476746 
	C185.720612,847.136230 186.472900,846.754028 187.133148,846.267151 
	C189.721298,844.358215 191.399460,844.747070 192.002060,848.086792 
	C192.270203,849.572937 192.616104,851.016052 194.454239,851.874512 
	C196.223190,851.775208 196.399490,850.637085 196.821503,849.268860 
	C199.561569,845.100342 204.122833,848.278137 207.566986,846.050903 
	C207.973114,847.382751 207.983627,848.771545 207.989227,850.576904 
	C207.481506,851.424927 207.317810,851.946289 207.772491,852.792419 
	C207.979828,855.047668 207.979294,857.087036 207.963791,859.563171 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M148.649658,813.125916 
	C152.010056,813.676147 150.916580,816.122498 150.957901,817.900085 
	C151.139908,825.730774 151.288528,825.931580 159.270554,826.001465 
	C160.269806,826.010193 161.285873,825.929443 162.264236,826.081970 
	C163.929504,826.341736 165.824890,826.773987 166.426010,828.425354 
	C167.167755,830.462891 165.145004,831.169312 163.824509,832.097534 
	C162.319275,833.155518 160.446289,834.218079 161.397812,836.416199 
	C162.381378,838.688171 164.292999,839.409180 166.784760,838.606201 
	C170.070816,837.547363 172.053055,838.625061 171.978424,842.307129 
	C171.945068,843.952454 172.688828,845.169250 173.828522,846.671875 
	C172.186462,848.948364 169.697876,847.484497 169.413742,846.443726 
	C167.738358,840.307251 164.543243,843.890320 161.354767,844.960449 
	C160.415512,844.510010 159.831039,844.020020 159.122681,843.266235 
	C156.051666,840.883606 154.827881,837.647583 153.241302,834.245605 
	C152.685257,833.888672 152.427017,833.698792 152.113251,833.215210 
	C150.114471,830.640564 147.186325,829.453918 145.320099,827.077271 
	C140.475021,820.906738 141.057266,818.221008 148.649658,813.125916 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M148.739105,812.723145 
	C148.928131,814.032959 148.572891,814.903870 147.709686,815.573181 
	C146.096008,816.824524 142.730988,816.968079 143.301270,819.608032 
	C144.010605,822.891785 145.125641,826.448608 148.562485,828.235107 
	C150.308838,829.142822 151.497665,830.359802 151.960419,832.644531 
	C146.941055,831.326904 141.497559,830.118286 140.260071,823.252319 
	C139.010529,822.095825 137.654984,821.975342 136.271606,821.174316 
	C132.312790,820.854065 131.017578,819.567810 133.289810,816.015137 
	C135.120682,813.152527 135.056900,810.414368 133.002213,807.272095 
	C130.016312,802.705566 130.840622,800.882751 135.606766,799.996765 
	C136.037766,802.391296 135.353561,805.143616 139.326141,805.152649 
	C140.826950,805.156067 140.885513,806.249084 140.386780,807.651001 
	C138.723022,812.328003 139.599854,813.226807 144.629242,812.257385 
	C145.934570,812.005676 147.223022,811.786987 148.739105,812.723145 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M135.954468,821.357910 
	C137.812637,819.981262 139.311081,819.876587 139.959290,822.672913 
	C137.229309,823.554260 136.385727,826.195374 134.330627,827.943115 
	C128.212479,823.537170 124.656425,818.182983 126.913132,810.468384 
	C127.519936,808.394043 124.393257,806.107544 127.608841,804.054199 
	C130.803436,807.287964 131.231171,811.010010 130.192673,815.175415 
	C129.763092,816.898499 129.950317,818.835083 130.159515,820.639526 
	C130.342941,822.221558 131.443359,823.390076 133.019791,823.763184 
	C134.613525,824.140320 135.492874,823.175293 135.954468,821.357910 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M153.000031,834.385742 
	C157.095062,835.231018 156.439194,839.901794 158.989807,842.629639 
	C152.834976,841.937073 152.217209,841.089600 153.000031,834.385742 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M135.951874,832.700439 
	C132.359711,832.640320 132.051956,832.301392 133.643402,830.027893 
	C134.609451,830.772461 135.262527,831.578491 135.951874,832.700439 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M208.381317,142.935547 
	C207.765549,142.183197 207.508072,141.391220 207.124695,140.308380 
	C206.871567,139.837555 206.744339,139.657593 206.810471,139.209473 
	C207.442215,138.951538 207.880585,138.961761 208.647369,138.974487 
	C212.296036,138.902893 215.353180,139.030914 212.903351,133.947556 
	C212.189163,132.465637 213.302429,129.219818 214.658630,127.861191 
	C216.139893,126.377296 217.296783,129.006699 218.418228,129.990799 
	C220.146469,131.507385 222.084824,133.184860 223.976730,131.099594 
	C225.845581,129.039764 223.554810,127.754547 222.099625,126.398987 
	C219.543594,124.017891 218.268173,121.530090 222.787445,119.235336 
	C223.422562,118.997215 223.824783,119.036423 224.524841,119.118698 
	C224.861511,119.577805 224.900345,119.993858 224.959106,120.723892 
	C225.548508,121.536110 226.074570,122.081192 226.867371,122.754189 
	C228.990891,123.084824 230.061035,124.698395 231.795776,125.749161 
	C231.995682,126.454849 231.997177,126.904297 231.996277,127.691391 
	C227.793442,131.504379 223.593018,134.979736 219.191956,138.717789 
	C215.419800,139.881638 211.941986,141.028152 208.381317,142.935547 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M227.022186,122.616432 
	C226.160019,122.932144 225.368164,122.712387 225.032196,121.357262 
	C225.886993,121.070625 226.662888,121.309158 227.022186,122.616432 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M206.943420,138.606628 
	C207.002670,139.120621 207.001511,139.299911 206.999557,139.748367 
	C206.560181,140.004517 206.121597,139.991501 205.354004,139.970749 
	C204.608688,139.551834 204.157700,139.196701 203.280472,139.045471 
	C202.245163,139.028046 201.499374,139.027618 200.381317,139.046295 
	C197.998199,138.761078 196.658890,137.669983 196.023773,135.361664 
	C195.988434,134.846085 195.997589,134.670593 196.021729,134.231583 
	C198.442871,135.011871 200.430359,137.265381 203.622894,134.819031 
	C204.765030,133.943848 205.642899,137.152878 206.943420,138.606628 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M193.666580,224.813202 
	C195.668427,224.956009 196.710327,225.372971 197.055664,227.378494 
	C197.767120,231.510300 199.597626,230.379913 201.542542,228.094360 
	C203.989883,225.218414 206.149338,222.071854 209.279205,219.825867 
	C210.044891,219.276398 211.183334,218.663834 210.221054,217.516296 
	C209.592407,216.766602 208.649750,217.158432 207.974731,217.672699 
	C206.000076,219.177063 204.113770,220.800568 202.103027,222.253052 
	C201.086761,222.987198 199.774490,224.325150 198.617966,223.258575 
	C197.297211,222.040527 197.950562,220.060822 199.285141,219.403580 
	C203.172943,217.488968 204.711594,212.682663 209.296066,211.645767 
	C210.742218,211.318710 211.953796,210.025925 211.795822,208.311295 
	C211.655914,206.792465 211.015808,205.378967 209.227310,205.054398 
	C207.444962,204.730927 206.778671,203.950577 208.168854,202.430893 
	C209.211838,201.290726 210.142242,200.769592 211.155746,202.706085 
	C212.199753,204.700882 214.714722,203.467056 214.332993,202.583176 
	C212.009079,197.202209 218.052719,196.069199 219.008957,192.486191 
	C219.171066,191.878754 219.747406,191.393127 220.064453,190.814041 
	C223.301224,184.901611 223.526123,184.826920 229.629761,187.952164 
	C230.838440,187.250290 231.805923,186.942993 233.279205,187.275299 
	C234.953598,189.746307 235.706085,192.309479 237.905579,194.276657 
	C239.508560,195.710342 239.875259,198.162079 236.759277,199.514343 
	C235.081451,199.177322 234.064026,197.440201 232.050095,198.822281 
	C233.278381,199.699051 234.678726,198.943832 235.994095,199.706161 
	C236.770279,200.599426 237.075333,201.514267 236.593552,202.179626 
	C235.038910,204.326645 234.409134,206.120056 237.537262,207.433212 
	C238.280273,207.745148 238.767029,208.740952 238.467133,209.530869 
	C237.341782,212.495132 239.269180,214.466675 240.647263,216.990234 
	C240.737244,218.000290 240.652130,218.664642 240.572388,219.651337 
	C241.014984,221.235336 241.819489,222.350769 241.996613,224.047882 
	C241.876144,226.274796 240.874649,227.845581 240.114227,229.491638 
	C236.910065,236.427536 233.733429,237.016678 228.267380,231.713913 
	C227.208893,232.894943 227.516251,234.115494 228.060822,235.401871 
	C230.547562,241.276001 230.148483,241.972107 223.866577,242.883530 
	C226.989746,244.361816 227.197311,244.543945 228.436737,243.131424 
	C230.592499,240.674576 233.123260,238.810822 236.208359,240.445084 
	C239.637558,242.261658 239.359451,245.932129 239.005539,249.643463 
	C238.707047,250.423004 238.443909,250.815811 237.809448,251.351624 
	C232.490936,252.857956 227.289810,253.491241 222.148529,255.380829 
	C221.355789,254.702133 221.157532,254.060364 221.072189,253.049805 
	C221.069107,251.959152 221.060410,251.195755 219.848877,250.802917 
	C217.709213,250.054825 216.304291,248.780045 214.809494,247.155365 
	C214.370316,246.536530 214.233612,245.949631 213.323303,246.364227 
	C212.773743,246.682541 212.440033,246.834488 211.831360,247.064301 
	C211.037949,247.360947 210.477417,247.490112 209.812897,248.067688 
	C209.424759,248.369385 209.266861,248.489044 208.868378,248.785233 
	C207.911697,249.416504 207.414825,250.018143 207.266205,251.235352 
	C206.705612,253.667618 206.031647,255.718918 204.863708,257.518097 
	C203.586411,259.485718 201.986618,260.838837 199.555176,258.393860 
	C199.021347,256.819122 200.891342,255.236191 198.674988,253.963318 
	C197.586746,251.348770 199.325516,250.036789 200.580643,248.596130 
	C202.254593,246.674683 203.440613,244.605988 203.352570,241.654724 
	C203.555115,240.754089 204.568024,240.239273 203.332153,239.364822 
	C201.696198,236.919479 203.309448,235.715927 204.878632,234.824829 
	C206.779297,233.745514 208.357971,234.836151 209.724716,236.196442 
	C210.959244,237.425110 211.700897,239.484329 214.026367,238.909927 
	C214.717926,238.739105 215.068665,238.058151 214.977127,237.354294 
	C214.680389,235.072662 212.740082,234.821671 211.053726,234.124100 
	C207.813583,232.783890 207.684631,231.336761 210.464981,228.739075 
	C211.580231,227.697083 214.038116,226.935776 212.191833,225.036804 
	C210.594910,223.394287 208.989075,225.306656 208.109573,226.477692 
	C204.521652,231.254974 199.134171,234.363373 196.116760,239.666641 
	C195.225922,241.232330 193.834579,242.532761 192.360641,243.610519 
	C190.638641,244.869598 188.820312,245.714752 187.339386,242.799820 
	C187.392975,241.019470 187.592560,239.610443 188.338699,238.305145 
	C187.438965,239.443954 186.006104,240.205505 185.924469,242.299316 
	C185.592010,244.592804 184.860107,246.447525 184.750793,248.456146 
	C184.635284,250.579056 183.305862,251.530899 181.227554,251.902313 
	C176.206375,252.799667 175.159698,255.842682 178.334076,260.074860 
	C180.144363,262.488403 182.075912,264.842163 179.945206,268.336060 
	C178.792114,269.237488 177.582901,269.481232 176.771759,270.838928 
	C174.363876,273.194305 172.548096,275.508545 171.251404,278.290161 
	C169.747269,281.516785 167.620758,284.248016 164.090302,285.591736 
	C162.458664,286.212738 161.515686,287.605072 160.697098,289.109131 
	C157.990891,294.081543 155.283875,299.055542 152.076996,303.734192 
	C151.604889,304.422943 151.327042,305.218353 150.944321,305.954224 
	C148.970337,309.749634 152.874893,314.463806 147.870529,318.352539 
	C144.584869,320.905762 145.776215,326.692139 145.759857,331.217499 
	C145.749283,334.146240 144.815933,337.278809 143.486313,339.838470 
	C140.637115,345.323486 139.754227,351.440063 137.431747,357.119263 
	C134.757645,363.658234 133.709793,371.615448 135.387711,378.695007 
	C137.246170,386.536285 133.324890,390.574738 128.201630,394.575897 
	C126.636856,395.797943 124.831635,396.703369 123.403488,398.138519 
	C121.997437,399.551392 121.259506,400.903534 122.364220,402.979980 
	C124.017792,406.088074 123.583183,409.483978 122.905754,412.758667 
	C122.323288,415.574280 121.737595,418.723022 117.470375,416.835083 
	C116.688416,416.489136 115.894051,416.575836 115.227890,417.334229 
	C113.649841,419.130768 114.323174,427.851349 116.360870,429.473175 
	C117.620972,430.476074 118.327179,431.922119 117.334534,432.860413 
	C110.926323,438.917694 112.879616,446.724548 113.145149,453.897552 
	C113.401413,460.819855 112.136429,467.442719 111.039413,474.120544 
	C110.517395,477.298279 111.117630,479.523834 113.507874,481.638458 
	C118.925209,486.431000 119.564018,488.987488 116.954872,495.645447 
	C115.935272,498.247345 115.077332,500.584808 117.045242,503.107056 
	C117.914795,504.221558 117.408165,505.658783 117.492104,506.967896 
	C117.743065,510.881989 117.301041,514.952881 119.858757,518.440552 
	C120.926308,519.896240 121.017670,521.543701 119.428589,523.269775 
	C118.463531,524.108215 117.561058,524.447815 116.762268,525.472900 
	C115.436401,527.269897 113.291031,528.684021 115.181847,530.820679 
	C116.919044,532.783813 118.752350,531.640320 120.435860,530.396484 
	C121.999504,529.241272 124.150780,528.337402 125.388275,529.922302 
	C128.449677,533.843323 131.144409,531.752441 134.336914,529.850464 
	C134.873215,529.628784 135.100021,529.572998 135.675079,529.545593 
	C138.197708,530.530334 139.249664,532.746582 141.329834,534.325623 
	C142.305405,535.463684 143.068771,536.340027 143.304398,537.860229 
	C141.762070,540.900696 143.967758,544.789795 140.475723,546.889038 
	C140.157272,547.080444 140.372742,547.825928 140.666245,548.243652 
	C141.551956,549.504272 143.504517,550.590027 141.900101,552.403564 
	C140.176376,554.351868 138.281021,552.895447 136.216461,552.066040 
	C135.702698,551.673462 135.520874,551.381104 135.267471,551.197510 
	C134.729034,550.807495 135.501923,551.341309 134.894150,551.124390 
	C134.608917,551.022522 134.607529,551.265015 135.336960,551.354248 
	C135.821609,552.184143 135.866257,552.801697 135.705383,553.744995 
	C135.342804,554.804565 134.884125,555.483032 134.661621,556.536011 
	C134.674423,557.142151 134.783096,557.457581 135.180664,557.913696 
	C135.523895,558.199585 135.665726,558.313110 135.983902,558.643677 
	C136.233368,559.036682 136.306458,559.212708 136.408936,559.676514 
	C136.397598,560.561218 136.334351,561.156921 136.275330,562.074585 
	C136.027908,565.647217 138.906204,569.232300 141.644867,568.891846 
	C145.224960,568.446716 143.224045,565.076294 144.530609,563.015259 
	C145.820129,561.670593 147.153900,560.991516 148.599854,560.627258 
	C150.403625,560.172791 151.753601,557.361084 153.835434,559.031494 
	C155.642975,560.481689 154.202179,562.750916 154.139496,565.010864 
	C153.515121,567.006470 153.352463,568.717285 153.181656,570.820557 
	C152.820801,572.508301 152.377274,573.754089 151.337128,575.162109 
	C143.187790,575.571228 135.087982,576.757324 128.524139,570.194702 
	C128.481796,568.620605 129.562836,567.415649 128.156677,566.215088 
	C125.812157,564.213440 124.551254,562.091919 128.387985,560.278076 
	C129.306625,559.843750 128.457230,559.564209 128.303070,559.340027 
	C127.924896,558.789978 127.391304,558.407837 126.357521,558.628540 
	C125.310600,559.216309 124.724686,559.796753 124.778053,561.103577 
	C124.842903,562.957336 125.880379,564.348389 125.271088,566.251892 
	C124.998543,566.741577 124.865440,566.923950 124.487793,567.341187 
	C123.708313,567.991821 123.127861,568.339294 122.268433,568.878723 
	C120.227386,570.098389 118.482948,569.628235 116.425095,568.905273 
	C115.107269,568.270996 114.185722,567.650635 113.262589,566.506714 
	C113.243248,562.858337 110.653282,561.558044 108.539520,559.632690 
	C101.604874,553.316101 100.401955,555.072388 103.398689,545.272766 
	C103.898911,543.636963 103.283340,542.720886 102.278198,541.796509 
	C96.902519,536.852966 97.489372,531.065857 100.699158,525.555481 
	C102.444756,522.558655 101.536995,520.127502 100.056000,518.339417 
	C94.872940,512.081421 95.887459,504.511566 97.910522,498.457214 
	C99.676849,493.171143 99.110657,487.912476 100.610695,482.742493 
	C102.907928,474.824890 102.772629,466.363190 102.999451,458.074463 
	C103.058952,455.900146 103.068962,453.745972 101.911385,451.766876 
	C101.320175,450.756165 100.629311,449.508972 101.443565,448.491425 
	C103.687767,445.686920 103.184402,442.419647 102.908531,439.368103 
	C102.539375,435.284607 107.018715,431.368805 103.240135,427.258514 
	C108.596573,424.175903 105.628319,419.097534 106.221512,414.871124 
	C106.266380,414.551514 106.373154,414.087921 106.598175,413.968689 
	C111.656685,411.288116 112.503807,406.687286 113.224976,401.488617 
	C113.813248,397.247955 115.785912,393.762329 114.045601,388.793610 
	C112.580139,384.609711 111.097595,378.824615 115.153503,374.223389 
	C116.737152,372.426819 116.462860,370.145111 116.622635,367.769104 
	C117.101547,360.647156 117.965981,352.143127 123.163483,348.006348 
	C127.478477,344.572021 128.338745,340.597198 130.500137,336.701294 
	C130.733047,336.281403 130.957687,335.723602 130.877914,335.285919 
	C130.460876,332.997498 126.328194,328.005585 133.726196,331.656952 
	C134.705643,332.140350 137.298721,323.379456 136.572128,321.408325 
	C133.677673,313.556030 139.716583,298.746033 147.267227,295.376526 
	C149.549927,294.357819 150.982788,293.162079 151.235565,290.324615 
	C151.472656,287.662994 152.085098,285.011475 155.530014,283.755493 
	C158.162292,282.795837 158.180038,279.334015 158.670654,276.851807 
	C159.123672,274.559784 156.665421,275.373047 155.453568,274.834900 
	C152.571686,273.555145 152.113754,269.447571 154.687515,266.882385 
	C156.904770,264.672516 159.343933,262.685364 161.842804,260.299866 
	C161.863754,259.276733 161.654694,258.566620 161.575180,257.453461 
	C161.497406,256.084503 161.182877,255.130600 161.103760,254.138885 
	C160.329514,244.434372 162.217697,241.755661 171.879684,239.949860 
	C174.686905,239.425201 175.863144,238.116241 177.226501,235.716919 
	C179.030289,232.542435 181.613876,230.048584 182.366837,226.048355 
	C182.979523,222.793350 186.426041,224.387985 189.151657,224.530579 
	C190.799881,224.552200 192.072617,224.313141 193.666580,224.813202 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M213.861954,776.682373 
	C213.095993,780.767578 212.194107,784.535156 211.132751,788.647705 
	C209.292389,788.723450 206.096878,789.804626 208.011581,786.106018 
	C209.407150,783.410217 207.681320,782.991943 205.847092,782.452454 
	C200.588196,780.905945 194.596939,781.014282 191.141129,775.470703 
	C190.555679,774.531555 189.059204,775.092834 188.042908,775.948914 
	C187.038315,776.795227 185.890274,777.498535 184.729904,778.125183 
	C183.496857,778.791016 182.394028,780.648132 180.824387,779.463440 
	C179.302551,778.314941 179.855515,776.339478 180.276260,774.802979 
	C181.224289,771.340759 181.436401,767.938477 180.274048,764.516418 
	C179.923096,763.483276 179.848450,761.974487 178.154282,762.373230 
	C176.902649,762.667908 177.111160,763.878662 177.006393,764.843628 
	C176.819580,766.564087 177.654587,768.905334 175.261276,769.503662 
	C173.387878,769.972168 172.584793,767.857971 171.365112,766.824707 
	C169.531845,765.271606 170.674515,764.084717 171.759109,762.639343 
	C174.845627,758.526123 170.496185,758.441101 168.733429,758.283508 
	C165.112900,757.959839 164.272278,757.693359 165.369080,753.406433 
	C166.487915,749.033386 161.820114,746.380127 158.109283,745.392212 
	C154.454391,744.419189 153.797821,743.016602 155.010208,739.916687 
	C155.983795,737.427429 155.563644,735.762146 152.995743,734.234985 
	C149.691986,732.270020 148.453873,728.511780 147.268478,725.092529 
	C145.949661,721.288513 149.937210,720.526794 151.858810,718.872314 
	C156.120834,715.202881 157.049286,712.360657 154.981628,707.332642 
	C153.982590,704.903381 152.136078,703.576416 149.752243,703.239624 
	C147.526581,702.925232 147.181702,704.834595 146.858856,706.526978 
	C146.723221,707.238098 146.250168,707.747314 145.496002,707.713318 
	C144.477707,707.667419 143.923218,706.854492 144.091888,706.031189 
	C144.911057,702.032898 140.529388,698.203186 143.742096,694.111389 
	C144.027664,693.747681 143.838120,692.729126 143.528809,692.222595 
	C139.109695,684.984802 136.228806,676.946228 133.228470,669.173523 
	C130.402176,661.851624 127.288078,654.555908 124.851089,647.025146 
	C122.432404,639.550964 118.806290,632.495667 117.548790,624.592468 
	C117.152672,622.102905 112.984833,621.932129 112.719254,619.203430 
	C112.148506,613.339294 108.914009,608.324707 107.922485,602.581787 
	C106.884415,596.569275 105.411209,590.606567 103.717888,584.740540 
	C102.384583,580.121765 103.893951,575.883362 104.845642,571.622681 
	C105.635010,568.088745 109.036407,569.170410 112.026802,568.971313 
	C113.524551,568.503540 114.608223,568.372498 116.036545,568.733276 
	C117.860847,569.874084 117.743568,571.305054 117.306023,572.786072 
	C116.869980,574.261902 117.336945,575.332520 118.932320,576.247803 
	C120.094353,577.111450 121.042206,577.684753 122.394073,578.248840 
	C123.685493,579.111633 123.955727,580.372742 124.787537,581.155823 
	C129.194183,585.304321 129.115509,587.260803 123.940521,591.536316 
	C122.347153,592.464233 121.487900,586.993591 120.001114,591.667236 
	C118.397896,596.345093 119.300941,600.911804 118.812996,605.356506 
	C118.480492,608.385254 120.264755,610.548706 123.347092,611.741882 
	C128.304565,613.660889 129.125778,615.348145 127.544685,620.035278 
	C125.440475,626.273254 124.449242,632.099548 131.354965,636.203125 
	C132.922577,637.134644 132.710709,638.772400 132.193253,640.247681 
	C131.174850,643.151062 132.305359,645.160217 134.249100,647.383789 
	C138.835602,652.630615 142.220078,658.848450 145.756546,664.744934 
	C148.187912,668.798767 147.947830,674.580750 150.835159,678.956970 
	C150.959167,679.144897 150.911484,679.357056 150.918488,679.443970 
	C151.837860,690.834045 152.150406,691.404358 160.079803,700.624878 
	C162.438065,703.367188 163.617142,706.668274 162.854782,710.367920 
	C162.039047,714.326477 162.614273,717.827454 165.114624,721.194641 
	C166.892990,723.589417 167.351944,726.713928 168.333115,729.792114 
	C169.791031,729.314880 170.938065,727.944580 172.632935,728.020447 
	C174.913315,728.122498 176.812363,728.977051 176.536316,731.490723 
	C175.850266,737.738403 179.642853,743.298584 179.009979,749.543823 
	C178.785233,751.761597 180.837418,752.123474 182.578781,751.959717 
	C186.259369,751.613403 188.719101,752.811340 189.544647,756.675964 
	C190.003998,758.826294 191.680435,759.545959 193.683517,759.527466 
	C198.691269,759.481323 203.624054,759.650574 208.391983,761.585083 
	C210.945602,762.621155 213.560089,760.321777 216.564774,761.335693 
	C219.325409,765.035095 217.306396,767.414246 214.642944,769.439758 
	C210.445099,772.632202 210.397400,772.569458 213.861954,776.682373 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M150.815308,241.264801 
	C153.468658,241.113724 155.005676,240.200745 154.998077,237.479065 
	C154.993622,235.879410 154.846558,234.131851 157.606201,234.014618 
	C159.570679,235.508087 159.256149,237.341812 158.721573,239.099121 
	C158.120026,241.076706 158.082657,242.998627 158.717194,245.323975 
	C158.776001,246.729309 158.456696,247.664017 157.625610,248.797226 
	C157.132111,249.170578 156.898621,249.267349 156.332550,249.182068 
	C153.551926,249.377594 154.088776,251.298294 154.004639,252.884186 
	C153.908691,254.692993 154.294159,256.560120 153.163239,258.629089 
	C152.727722,259.608185 152.239563,260.015869 151.437256,260.556213 
	C150.594772,261.039612 149.998764,261.291687 149.069611,261.586670 
	C148.310455,261.687653 147.885147,261.619934 147.239136,261.222473 
	C141.580154,262.175812 140.807419,263.953400 144.248581,267.754913 
	C145.288971,268.904236 145.609543,269.885620 145.031235,271.259338 
	C144.777817,271.861267 144.654739,272.633820 143.810196,272.527954 
	C142.341736,272.343903 142.217438,271.106445 141.972824,269.971558 
	C141.282822,266.770142 139.285614,268.717590 137.072479,268.663513 
	C135.201935,265.301117 136.076050,262.442780 137.742859,259.326355 
	C138.002441,259.003021 138.000000,259.000000 138.001129,259.001221 
	C140.187347,257.834045 142.078827,256.040192 145.082031,255.819733 
	C146.793671,255.689453 148.210648,256.491364 149.550964,255.650467 
	C149.568375,253.412506 147.485443,253.379776 146.203018,252.060364 
	C144.488052,249.842178 144.519150,247.798386 145.780151,245.350357 
	C147.546387,243.847885 149.086685,242.687225 150.815308,241.264801 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M124.962402,304.350555 
	C128.357391,303.267487 126.814552,300.140625 127.661156,298.220764 
	C129.356186,294.376923 129.244141,289.248840 135.860947,290.404175 
	C137.602661,290.708282 139.338577,289.309418 138.994293,286.882996 
	C138.055496,280.266418 142.443756,277.296448 147.533554,274.224762 
	C147.658218,277.981873 144.542557,278.762909 142.872604,280.514771 
	C140.368439,283.141785 139.758118,285.429443 142.598511,288.150299 
	C143.582291,289.092651 145.558884,290.145630 144.302597,291.739197 
	C143.071808,293.300476 141.709885,295.062988 139.070480,294.902771 
	C133.445496,294.561249 131.287399,297.487640 132.276321,302.877014 
	C132.701279,305.192932 132.022614,307.732819 131.687714,310.149109 
	C131.618179,310.650818 130.918381,311.327148 130.394165,311.456146 
	C128.623489,311.891998 124.612427,306.857391 124.962402,304.350555 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M130.514862,695.147705 
	C137.421143,696.720703 137.288223,696.701660 136.209000,704.227600 
	C135.780396,707.216553 134.926071,710.649109 138.739075,712.544983 
	C139.241562,712.794739 139.838608,713.640503 139.773788,714.128113 
	C138.655655,722.541504 142.601349,729.422302 146.888519,736.514771 
	C141.747681,735.001160 137.040054,728.175354 137.884842,723.831848 
	C138.686188,719.711731 137.150009,716.138855 134.898148,713.090210 
	C132.549957,709.911072 131.220276,706.498169 130.394302,702.760132 
	C129.804596,700.091370 128.432999,697.871521 126.598824,695.864258 
	C125.866341,695.062622 125.964279,693.966187 126.652267,693.102905 
	C127.461075,692.088013 128.613235,691.790833 129.796432,692.241577 
	C131.141983,692.754272 130.414825,693.898376 130.514862,695.147705 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M117.648209,335.136353 
	C115.548203,331.060059 116.811394,322.481476 119.595459,319.613434 
	C121.372978,317.782349 121.640778,314.023041 125.606857,315.008301 
	C126.551445,315.242920 128.172531,315.488342 127.367661,316.552795 
	C125.061066,319.603210 127.144524,324.246613 123.152870,326.740295 
	C121.130630,328.003662 121.826775,330.660034 122.433762,332.728363 
	C123.190315,335.306335 123.192169,337.803040 122.437096,341.217560 
	C120.605400,338.866028 119.260353,337.139282 117.648209,335.136353 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M85.227127,546.040100 
	C88.027206,540.433411 87.176331,535.074219 85.992058,529.664551 
	C85.608284,527.911499 83.752579,525.328247 86.634026,524.587341 
	C89.297745,523.902466 88.396332,526.994629 89.177536,528.395508 
	C91.175407,531.977905 92.324303,535.391602 90.205742,539.403625 
	C89.523026,540.696472 90.445869,542.254272 92.258308,541.694153 
	C94.713844,540.935303 96.597343,540.811462 96.943619,543.981384 
	C97.418205,548.325806 94.118759,545.712708 92.569946,546.245850 
	C90.562180,546.937012 88.644814,548.110596 86.385254,547.811401 
	C85.613708,547.709167 85.334938,547.105530 85.227127,546.040100 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M90.999847,586.000000 
	C90.967224,582.391663 90.668083,579.022339 89.189407,575.460571 
	C86.061882,567.926941 88.128677,560.598755 92.151314,553.782043 
	C92.563560,553.083435 93.057503,552.282898 94.059517,552.567322 
	C95.097992,552.862183 94.915863,553.843262 94.981728,554.631653 
	C95.184578,557.059631 94.893478,559.097290 92.342987,560.485840 
	C90.445244,561.519043 89.218796,563.242310 89.799316,565.867249 
	C90.379257,568.489502 91.727196,568.994568 94.075806,568.398438 
	C94.430260,568.308411 95.220001,569.031372 95.442078,569.538635 
	C95.755005,570.253418 95.213371,570.763306 94.466728,570.894470 
	C90.998436,571.503601 92.784958,573.140076 93.246246,575.130676 
	C94.083801,578.745056 95.848618,582.817505 91.386688,585.942139 
	C90.999695,586.000000 91.000000,586.000000 90.999847,586.000000 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M150.794464,260.205505 
	C151.469589,259.683624 151.939163,259.366058 152.704681,259.024231 
	C154.707947,259.180176 157.063858,258.372742 157.521042,260.850800 
	C157.895752,262.881958 155.492081,262.547729 154.463852,263.422150 
	C152.231979,265.320129 150.175125,267.423889 147.705063,269.767700 
	C146.675293,267.005859 145.465759,264.569550 146.954727,261.373535 
	C147.444504,261.000000 147.888992,261.000000 148.666870,261.000000 
	C149.597443,260.985382 150.132217,260.802673 150.794464,260.205505 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M127.346893,282.959961 
	C126.377167,280.161591 129.315735,278.974457 129.959839,276.352814 
	C130.000748,276.001221 130.000000,276.000000 130.000610,276.000610 
	C132.194946,274.637939 130.441147,272.164642 131.775208,270.248657 
	C132.001221,270.001221 132.000000,270.000000 132.000916,270.000610 
	C133.213547,268.853149 134.624420,268.345490 136.645813,268.832703 
	C136.998581,268.998779 137.000000,269.000000 137.000610,269.000610 
	C136.846924,275.612091 132.214417,279.228516 127.346893,282.959961 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M156.000977,249.396317 
	C156.000000,249.000000 156.500000,248.999954 156.750000,248.999969 
	C159.690994,247.799438 159.991714,250.042480 160.362396,251.590912 
	C160.852631,253.638687 161.390900,255.619553 162.747696,257.710388 
	C162.825821,258.551270 162.652267,259.102509 162.239655,259.826904 
	C157.862396,257.854401 156.264099,254.216125 156.000977,249.396317 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M118.405838,345.659698 
	C117.758392,349.427307 115.292259,351.926422 112.932922,354.525177 
	C114.410179,351.321625 114.471977,347.340851 118.405838,345.659698 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M130.585617,685.358521 
	C133.931396,685.468872 133.235291,687.611938 132.520294,689.087280 
	C131.864212,690.441040 130.184525,690.260071 129.156631,689.302673 
	C127.223854,687.502563 129.101776,686.551575 130.585617,685.358521 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M104.613846,370.008423 
	C105.000504,371.388580 105.000099,372.777161 104.999695,374.582275 
	C103.485741,374.790802 101.351974,375.637634 101.135185,373.067993 
	C100.958969,370.979431 102.267052,370.095337 104.613846,370.008423 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M151.497086,741.614746 
	C149.490677,741.893250 148.058594,741.549438 148.540527,739.422302 
	C148.776581,738.380554 149.884979,737.885986 150.928101,738.221497 
	C152.732239,738.801636 153.364517,739.874817 151.497086,741.614746 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M176.359619,774.088013 
	C178.111053,777.135254 175.463745,776.494141 174.151932,776.871399 
	C173.399719,777.087708 172.311707,776.742859 172.356277,775.782349 
	C172.478470,773.149414 174.615906,774.291992 176.359619,774.088013 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M99.420654,572.606567 
	C99.932487,573.962097 100.420433,575.387817 98.726097,575.504700 
	C97.587494,575.583374 96.419868,574.419495 96.515182,573.272522 
	C96.654518,571.595886 98.066307,572.063354 99.420654,572.606567 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M116.179642,568.735962 
	C114.941818,569.104431 114.169899,570.975281 112.675751,569.182373 
	C112.156898,568.177246 111.838486,567.393982 112.921753,566.506470 
	C114.399338,565.673096 115.196220,566.424011 116.048210,567.690613 
	C116.253265,568.180115 116.320274,568.359192 116.179642,568.735962 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M168.458496,781.866272 
	C166.307220,779.883606 168.357010,779.340637 169.373703,778.585815 
	C169.985580,778.131470 170.642319,778.624146 170.798279,779.355164 
	C171.139252,780.953369 170.529236,781.892395 168.458496,781.866272 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M93.257431,381.896698 
	C93.321312,380.554810 93.872986,380.106384 94.842613,380.649963 
	C95.443085,380.986572 95.866867,381.714508 95.427803,382.306061 
	C94.817879,383.127777 94.008263,382.899689 93.257431,381.896698 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M123.774490,311.770325 
	C124.287178,310.953278 124.868973,311.024048 125.360825,311.542175 
	C125.502251,311.691162 125.510033,312.212219 125.372871,312.338318 
	C124.762947,312.899017 124.253136,312.550262 123.774490,311.770325 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M90.255188,518.949463 
	C89.979683,518.539978 89.999535,518.097717 90.049255,517.324585 
	C90.720718,517.103210 91.487053,517.180542 91.478615,518.002075 
	C91.475456,518.310120 90.877388,518.612000 90.255188,518.949463 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M152.445129,707.628357 
	C152.663620,708.402771 152.392059,708.682373 151.791428,708.563477 
	C151.666534,708.538818 151.498322,708.362000 151.479401,708.235901 
	C151.388290,707.629028 151.683334,707.374451 152.445129,707.628357 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M196.654160,849.042725 
	C197.776840,850.828003 198.859650,852.827087 195.016434,852.175537 
	C194.989120,850.831055 194.831558,849.529663 196.654160,849.042725 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M168.477493,813.067383 
	C168.839035,813.753479 168.903702,814.502075 168.981689,815.626587 
	C168.995026,816.002441 168.500488,816.006226 168.253296,816.007996 
	C166.842468,814.975769 166.616058,813.998596 168.477493,813.067383 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M146.264297,256.692383 
	C143.301926,256.614075 141.358032,258.985931 138.382446,258.994873 
	C141.255722,255.932907 138.835556,250.895416 141.953278,247.386230 
	C143.146942,246.982971 144.069397,246.542419 144.856903,245.286667 
	C145.171738,245.002838 145.338577,245.003235 145.755768,245.006088 
	C146.134033,247.111008 145.585251,249.286697 146.842621,251.642593 
	C148.187897,253.608887 147.578857,255.022171 146.264297,256.692383 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M104.686310,307.794922 
	C103.964783,310.886841 107.004051,314.372009 103.347313,316.964081 
	C101.587418,314.246490 100.727959,311.483521 102.966675,308.343018 
	C103.400085,307.646851 103.857590,307.521545 104.686310,307.794922 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M111.815842,301.880493 
	C112.100853,303.603851 111.825424,305.017548 109.378609,305.011597 
	C108.077278,302.551910 107.660667,300.505096 111.815842,301.880493 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M105.296997,307.867859 
	C104.557442,307.999390 104.114876,307.998749 103.340584,307.996643 
	C103.419968,305.549927 105.325142,305.250885 107.646683,305.032288 
	C107.982040,306.636169 106.699577,307.106445 105.296997,307.867859 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M101.787651,318.750519 
	C101.824707,319.540802 101.652962,320.096130 101.241562,320.826355 
	C100.602798,321.505096 100.246132,322.056061 99.295189,322.136932 
	C99.187927,320.341309 98.679871,318.168427 101.787651,318.750519 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M96.999733,327.750000 
	C96.555817,327.999725 96.113762,328.000671 95.340340,328.003235 
	C95.186249,326.634918 94.447784,324.960510 96.647110,324.074036 
	C97.577492,324.689392 97.740723,325.459381 97.238480,326.660400 
	C97.004272,327.003662 97.001602,327.501221 96.999733,327.750000 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M97.345291,326.942993 
	C97.003250,326.253510 97.002228,325.503326 97.001587,324.378235 
	C97.442810,323.999268 97.883652,323.995270 98.654869,323.989624 
	C98.942047,325.127716 99.165756,326.387207 97.345291,326.942993 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M112.697525,297.039612 
	C112.997864,297.751740 112.997246,298.504822 112.992783,299.634644 
	C112.028435,299.188690 109.929817,298.595978 112.697525,297.039612 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M98.999023,322.331421 
	C99.496178,321.740082 99.991074,321.479797 100.743935,321.110382 
	C100.937355,322.135101 101.162857,323.402863 99.330856,323.932678 
	C98.991013,323.544952 98.993889,323.103699 98.999023,322.331421 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M135.371368,243.445007 
	C134.597015,243.663315 134.317139,243.391922 134.436035,242.791199 
	C134.460754,242.666321 134.637604,242.498138 134.763748,242.479202 
	C135.370712,242.388077 135.625290,242.683167 135.371368,243.445007 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M138.371643,773.953247 
	C141.009903,769.957825 138.417618,766.414124 137.000977,762.383118 
	C138.013214,762.544189 139.069702,763.022583 140.032196,763.645996 
	C142.236206,765.073364 144.411377,766.348206 144.054016,769.727234 
	C143.682938,773.235901 140.825256,772.930786 138.371643,773.953247 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M148.991653,788.620728 
	C147.753662,788.905212 146.102692,788.333923 146.428024,787.210266 
	C147.829315,782.370483 145.360703,781.434998 141.392303,781.556274 
	C141.042297,781.566956 140.672470,780.928345 140.155914,780.295654 
	C147.918900,779.082825 149.982132,780.967896 148.991653,788.620728 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M137.006088,778.629517 
	C135.950577,778.337708 134.271896,778.177795 134.239746,776.618774 
	C134.201019,774.741638 135.986664,774.594177 137.644989,774.036865 
	C137.668793,775.418335 137.340179,776.839539 137.006088,778.629517 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M136.998993,268.575836 
	C135.675613,269.733551 134.274277,270.135712 132.374084,270.000885 
	C131.972336,268.548035 132.982574,267.300568 132.426804,265.663269 
	C130.966858,261.362335 133.281998,259.535950 137.618988,259.009369 
	C137.668106,262.052979 137.333755,265.102936 136.998993,268.575836 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M131.655792,270.042053 
	C131.335831,271.922791 134.613708,274.717316 130.346329,275.959564 
	C130.666092,274.078979 127.383591,271.283569 131.655792,270.042053 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M119.207108,727.813477 
	C116.959869,727.828003 116.582886,726.201843 116.027603,724.353271 
	C117.889519,724.493469 119.306778,725.440491 119.207108,727.813477 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M221.234344,837.233154 
	C220.995316,837.002441 220.497833,837.000854 220.249100,836.999207 
	C216.733307,834.217224 215.091522,829.906677 211.140732,827.349976 
	C211.028946,825.945496 211.008362,824.875244 211.000977,823.403687 
	C213.122635,821.170593 215.330460,822.119629 217.439774,822.897888 
	C219.908188,823.808594 222.360657,824.571716 225.414230,824.190308 
	C229.579330,824.983398 230.439758,828.086853 231.117142,831.171753 
	C231.598511,833.363953 231.904938,835.469666 235.128586,835.738647 
	C237.934555,836.862244 239.619308,838.429626 239.227448,841.661438 
	C239.000381,842.445007 239.000153,842.889954 238.999649,843.668701 
	C234.760727,845.117554 230.532684,846.183105 227.262451,841.273438 
	C226.998169,841.002441 227.000000,841.000000 226.999374,841.001221 
	C225.645920,839.059387 223.165161,838.877563 221.234344,837.233154 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M226.240067,824.708374 
	C223.237396,826.664185 220.682892,825.865173 218.001648,824.591370 
	C216.091782,823.684143 214.047821,822.860474 211.414917,823.003052 
	C211.022980,821.283508 211.031815,819.564636 211.072388,817.417236 
	C211.804733,816.286377 212.664062,815.835632 213.916443,815.353882 
	C217.577271,816.023804 220.907913,816.669800 224.449921,817.939941 
	C224.953354,818.309143 225.138779,818.479187 225.536469,818.962891 
	C226.359543,820.937683 226.946884,822.602234 226.240067,824.708374 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M210.765350,827.299316 
	C215.756287,828.441345 218.330109,831.845154 219.855927,836.676270 
	C216.793823,838.105530 213.774872,837.490417 210.486694,835.984131 
	C210.161835,833.168762 209.646103,830.346924 210.765350,827.299316 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M225.240662,818.709656 
	C221.646378,817.807434 217.780914,818.423340 214.373932,816.049072 
	C213.368500,815.977966 212.758743,815.880920 212.059891,815.228638 
	C211.909775,812.936157 211.934357,810.903564 211.978851,808.435425 
	C213.145615,806.998230 214.453796,806.680298 216.300537,807.231384 
	C217.896057,810.984619 219.489670,814.072571 223.843918,814.826660 
	C225.312546,815.081055 226.050842,816.701965 225.240662,818.709656 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M217.339539,808.214600 
	C215.612274,808.001587 214.222504,808.000793 212.415771,808.000000 
	C211.915436,805.560791 211.872223,803.126221 212.853729,800.412842 
	C213.066666,800.013184 213.250259,800.006470 213.709351,799.994446 
	C216.798447,799.778198 215.751862,803.166199 217.885956,804.107544 
	C219.238663,805.862793 220.857788,807.439758 217.339539,808.214600 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M211.670013,815.157104 
	C212.466812,815.148254 213.048431,815.327759 213.815018,815.753601 
	C213.478531,816.848328 212.646378,816.989807 211.427780,816.988647 
	C211.221054,816.440979 211.337936,815.893188 211.670013,815.157104 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M207.989227,852.672607 
	C207.336472,852.906738 206.563370,852.851868 206.522324,852.041443 
	C206.506912,851.737305 207.091461,851.402832 207.693390,851.037842 
	C207.988892,851.441284 207.993484,851.889038 207.989227,852.672607 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M116.104301,569.247070 
	C115.972000,568.933716 116.057358,568.462402 116.121803,568.231750 
	C117.944122,567.581665 119.597580,569.510437 121.694763,568.086426 
	C122.431107,567.545349 123.002373,567.527039 123.741882,567.209595 
	C123.954521,567.033203 123.999214,567.005127 124.021027,566.990234 
	C126.076447,565.724792 127.345688,567.093384 128.827637,568.662048 
	C131.701370,571.831665 134.417633,573.845825 138.940781,573.377930 
	C142.562317,573.003235 146.366425,574.393311 150.505829,575.000977 
	C152.711655,575.167725 153.536667,573.575378 155.181900,572.804565 
	C156.553696,573.160400 156.803085,574.035217 156.865265,575.361633 
	C155.492233,578.755554 154.513947,581.998413 151.829803,584.534180 
	C151.061737,584.486938 150.635361,584.383179 149.916397,584.152710 
	C147.178146,584.362183 148.030075,586.432007 147.366821,587.912231 
	C151.775284,587.897949 154.708725,585.537231 157.186905,582.610535 
	C158.489532,581.072083 159.802643,579.666870 161.795807,578.433167 
	C162.701279,577.450989 163.440643,576.836853 164.817352,576.675903 
	C165.656189,576.934204 166.053757,577.239929 166.581192,577.958618 
	C167.262527,579.502319 167.266327,580.743164 166.851654,582.342407 
	C166.225616,584.018494 164.429077,584.466736 164.376923,586.131042 
	C165.091095,585.805847 165.427078,585.068237 166.342560,584.484375 
	C170.573761,582.594299 169.727112,587.796021 172.507874,588.119812 
	C172.981613,587.990234 173.163589,587.907227 173.611832,587.668396 
	C174.068481,587.421448 174.258850,587.330383 174.756775,587.142517 
	C175.945358,586.855469 176.825806,586.884766 178.036285,587.150757 
	C180.048416,588.155273 181.295120,589.497253 182.327820,591.459778 
	C182.505112,592.721375 181.549347,593.820679 182.427612,594.533508 
	C183.342102,595.275757 183.697952,594.103516 184.383514,593.311523 
	C184.762543,592.938477 184.941849,592.820190 185.433075,592.687256 
	C187.595154,594.733459 186.225006,591.033997 187.667908,591.697510 
	C188.939636,591.553467 189.850647,591.730286 191.079620,592.138306 
	C193.903442,596.311401 199.073532,598.937439 198.113922,605.171692 
	C198.491318,606.389221 198.734528,607.279358 198.518219,608.546875 
	C198.131439,609.224670 197.787857,609.492371 197.049072,609.693970 
	C196.435150,609.646667 196.130432,609.565308 195.818207,609.539185 
	C195.671799,609.526855 195.567429,609.609375 195.512497,609.697998 
	C195.161530,610.264771 195.931793,610.497009 195.510742,609.980103 
	C195.502319,609.969788 195.619324,609.865234 196.080154,609.887817 
	C196.304642,610.323975 196.310745,610.538940 196.195847,611.061523 
	C192.646469,612.166321 196.392731,616.110168 194.190231,616.918152 
	C194.891586,616.396912 196.412857,613.934570 197.764435,615.965210 
	C199.702866,618.877441 197.328720,621.107544 195.184677,623.010010 
	C195.083099,623.100098 194.733124,623.044739 194.727112,623.088501 
	C194.592972,624.064453 195.397400,623.186951 195.927383,623.570801 
	C196.467453,623.679260 196.683441,623.739807 197.212555,623.935547 
	C197.722412,624.187683 197.919159,624.304749 198.384460,624.637329 
	C199.298431,625.510498 199.916122,626.188477 200.256500,627.399231 
	C196.556061,630.905579 192.556870,633.717407 189.307846,637.815491 
	C188.308456,639.935181 187.189667,641.738953 189.462219,643.735291 
	C189.990356,644.244690 190.241684,644.567017 190.544922,645.192261 
	C190.710632,645.835144 190.981735,645.781921 191.058884,645.839478 
	C191.590515,646.236267 191.387344,646.339233 191.181763,645.705078 
	C190.976181,645.070923 191.436005,645.642944 190.725845,645.542419 
	C190.688965,645.056091 190.721344,644.853699 190.908203,644.372925 
	C191.774536,643.167236 192.680893,642.528503 194.177094,642.196899 
	C195.561234,642.192383 196.407455,642.630798 197.366684,643.613403 
	C199.348282,650.755310 202.572510,656.757141 207.957916,661.911499 
	C208.139053,662.471680 208.197220,662.698486 208.309998,663.268066 
	C208.535538,665.194214 209.023071,666.690430 209.756653,668.468018 
	C210.263519,669.620850 210.577515,670.483337 210.993225,671.682190 
	C211.337875,672.926086 211.479584,673.861023 211.923538,675.088989 
	C212.465500,677.565430 212.886841,679.700012 213.313019,682.197876 
	C213.189514,685.529724 215.201447,687.802368 216.011642,690.827026 
	C216.345016,692.264832 216.409607,693.430725 217.416138,694.635498 
	C217.710617,695.162170 217.812485,695.385193 218.023621,695.965759 
	C218.829239,702.215271 215.632675,707.186646 214.061920,712.890991 
	C207.643158,717.733337 206.992615,719.495667 208.751007,726.968811 
	C210.059311,732.528992 208.940369,735.419922 204.159668,738.830994 
	C200.040115,741.770325 198.466461,751.373169 201.546799,754.174072 
	C203.330078,755.795593 204.897202,756.683838 207.142014,754.885437 
	C209.282318,753.170715 213.305664,752.823181 211.197449,748.122131 
	C210.342422,744.942139 211.875336,742.525696 212.611649,739.599731 
	C213.074265,733.973755 213.364349,728.687134 214.956070,723.576538 
	C215.304260,722.458557 214.206482,721.871521 213.454773,720.785400 
	C212.583633,716.659363 214.525345,713.400269 215.470459,709.954529 
	C216.557388,705.991760 217.785004,702.065735 219.103470,697.748535 
	C220.909119,695.035217 220.202011,692.201965 220.901978,689.655518 
	C222.084213,685.354553 223.533798,684.729980 226.974564,687.332825 
	C228.344040,688.368835 229.723892,689.315735 231.443054,689.338989 
	C236.128326,689.402649 238.078064,692.328979 238.187897,696.377502 
	C238.510284,708.259033 242.088074,719.609497 243.667709,731.660034 
	C246.814026,745.707886 251.874710,758.509949 259.931091,770.273987 
	C261.661926,769.006287 260.661041,767.082397 262.094788,765.861450 
	C265.888153,765.373474 265.593903,762.720337 265.719604,759.958435 
	C266.347046,757.237732 267.166504,755.549683 269.629028,758.733704 
	C270.296844,760.111633 270.681519,761.224487 271.512573,762.500732 
	C273.180298,766.898987 276.110260,769.943909 279.367249,773.172363 
	C280.011993,773.989807 280.325684,774.605164 280.673706,775.589966 
	C280.996155,778.352112 282.736816,780.189453 282.784637,782.934509 
	C282.832031,785.655151 286.297363,786.069275 288.711670,785.928101 
	C291.941895,785.739258 294.561249,786.738525 297.251770,788.898926 
	C298.646240,789.893127 299.438568,790.968201 299.964172,792.192322 
	C305.249176,804.501587 311.416077,816.325012 318.921448,826.571960 
	C318.235962,824.663818 315.880463,822.632019 316.023193,819.221069 
	C316.529480,817.280334 315.567657,815.980713 315.215942,814.557312 
	C314.887817,813.229492 314.777405,811.817993 316.039490,810.915100 
	C317.434967,809.916809 318.536560,810.892334 319.540710,811.786926 
	C320.411072,812.562256 321.267181,813.344360 322.645508,814.026123 
	C326.064606,817.418579 330.540527,817.721252 334.643707,819.631958 
	C335.194061,819.822815 335.407990,819.913757 335.920959,820.180176 
	C338.803741,822.010681 339.029755,826.100830 343.045044,826.661987 
	C346.849670,826.990417 348.359436,829.561157 349.864319,832.187744 
	C352.255798,836.361694 354.730591,840.478394 359.707031,842.712341 
	C363.595856,844.429504 364.041840,848.677734 367.105042,851.115723 
	C367.619324,851.628418 367.909424,851.916931 368.402466,852.403564 
	C368.748108,852.915894 369.055267,852.877380 369.192932,852.972046 
	C369.738342,853.346985 369.185699,852.243164 369.327881,852.894470 
	C369.328400,852.896729 369.194550,852.928406 368.739075,852.768372 
	C368.266327,852.260010 367.981201,851.972656 367.469421,851.454102 
	C366.261169,850.146851 364.860992,849.101746 365.713959,847.446411 
	C366.736664,845.461853 368.562256,846.742432 370.054108,846.721619 
	C367.209076,841.330078 361.994629,838.416443 357.423767,834.378479 
	C356.972687,832.970154 358.710052,831.377869 356.294861,830.472656 
	C355.635864,829.297546 355.498077,828.301819 355.146301,827.376099 
	C354.797699,826.458801 354.416687,825.446350 355.488831,824.721497 
	C356.408051,824.100220 357.383026,824.299438 358.300293,824.775330 
	C359.186279,825.234924 359.930420,825.901123 360.642120,826.587646 
	C366.472198,832.211792 373.662628,830.845703 380.991119,830.365723 
	C385.508087,830.767334 389.688324,829.817322 394.231842,830.258057 
	C400.986298,828.137390 402.223785,828.566101 406.204071,834.171753 
	C408.606628,837.555420 411.389435,840.654053 413.845551,844.418335 
	C414.323242,845.509827 414.462189,846.412048 415.600098,847.032471 
	C417.424713,848.172974 418.717224,849.397888 419.818726,851.283569 
	C420.713593,852.849121 420.852905,854.750061 423.389282,854.310181 
	C431.178162,855.593201 438.822693,857.337219 445.281219,860.831238 
	C449.761749,863.255249 454.377411,866.341064 458.862183,869.847229 
	C460.235382,870.933777 461.095551,871.995728 461.883606,873.514038 
	C462.438263,874.921143 463.356384,875.086121 464.714417,875.048950 
	C470.572723,876.246643 475.150879,879.707703 480.486450,881.461121 
	C485.368042,883.065369 490.095886,884.739563 495.637268,883.890747 
	C497.378326,884.260986 498.692230,887.262024 500.313293,883.847778 
	C500.984589,880.979797 502.433380,879.228760 505.352997,878.431519 
	C509.548645,878.416016 512.234314,881.197205 515.821472,882.698059 
	C516.308655,882.891663 516.494873,882.978088 516.939331,883.212952 
	C517.509583,883.577087 517.824524,883.788452 518.401306,884.035767 
	C522.899292,880.620056 527.989685,882.443665 532.676697,881.987488 
	C535.875000,881.676270 539.170593,882.562439 542.798645,882.118042 
	C543.392578,882.007446 543.634033,881.975952 544.243164,881.932617 
	C552.588440,883.465088 560.719727,884.044189 568.679565,885.624268 
	C572.491821,886.381042 575.708740,884.484314 579.652893,884.293335 
	C572.285706,882.418457 564.941101,881.581421 559.041504,876.191284 
	C558.088257,875.012695 557.824158,873.502380 555.777588,873.840332 
	C551.411072,873.116699 549.889099,869.840515 548.073120,866.369995 
	C547.370605,862.474670 548.095825,859.287170 550.704163,856.271729 
	C551.197693,855.905212 551.408752,855.783630 551.967712,855.574585 
	C554.079041,855.940857 555.831726,856.093140 557.911011,855.270508 
	C562.614685,856.080139 566.480347,858.322876 570.962097,859.050171 
	C574.487366,859.622253 577.811340,858.533630 581.540039,859.316895 
	C582.270935,859.800659 582.588257,860.176758 582.947754,860.955139 
	C583.529236,862.600098 583.043091,864.561157 585.685486,864.593628 
	C587.810120,864.336121 589.656982,865.013855 591.193787,862.973877 
	C592.734558,860.928711 595.468628,862.530273 597.968262,862.061768 
	C599.042358,862.174622 599.690674,862.444336 600.471619,863.201660 
	C603.183350,867.636902 598.208923,868.154846 597.074280,870.922058 
	C595.940491,874.129272 597.976379,876.700684 597.681519,879.630249 
	C600.466797,876.832947 597.715027,873.863220 598.414490,870.722168 
	C600.591736,866.864197 603.469604,865.000916 608.060791,865.865845 
	C609.430908,866.157227 610.349487,866.589600 611.647705,867.017822 
	C614.316467,868.562744 616.335022,869.993042 618.559509,866.513062 
	C619.984192,864.284241 622.227051,865.372925 623.107971,867.559326 
	C623.703491,869.037292 624.650330,870.514404 624.333618,872.548889 
	C623.940063,873.066284 623.685425,873.237549 623.396179,873.337036 
	C622.948608,873.491089 622.492371,873.525330 622.030212,873.401672 
	C622.442810,873.650146 622.867310,873.404358 623.565796,873.267944 
	C624.008606,872.960449 624.170837,872.816895 624.656738,872.667297 
	C626.063416,873.232300 627.182800,873.932129 627.684814,871.635010 
	C631.528137,867.625732 635.991211,869.641602 639.944580,870.224060 
	C643.293701,870.717529 646.857666,870.973328 650.050964,872.612305 
	C652.269653,873.751099 654.170166,871.919434 656.554565,871.342651 
	C657.352844,871.201782 657.812012,871.198059 658.598206,871.342407 
	C660.055176,872.059875 661.145996,872.807922 662.872498,872.643555 
	C663.649719,872.625000 664.097534,872.617920 664.890747,872.604858 
	C666.758362,872.736511 667.959839,871.950195 669.539185,871.087891 
	C673.825317,870.115967 677.784424,870.400146 682.104248,870.715454 
	C683.720520,871.060364 684.897949,872.055908 686.602661,871.049194 
	C689.115234,870.242615 691.291992,869.468628 693.946228,870.534424 
	C698.793457,879.177307 697.140564,883.092834 687.920959,885.627625 
	C685.943665,886.171265 684.142151,886.933472 682.800903,888.516174 
	C679.555237,892.346069 675.226196,894.590271 670.604492,895.944031 
	C663.726501,897.958557 657.965027,901.725952 652.457703,906.067078 
	C652.196838,906.272705 651.978027,906.605408 651.682861,906.684021 
	C641.789795,909.320984 633.658997,917.311646 622.530823,916.233826 
	C619.685364,915.958252 616.797668,916.236084 614.307983,917.745361 
	C607.775269,921.705688 600.560669,922.468262 593.183044,922.440674 
	C590.442505,922.430481 587.914734,922.557190 585.928040,924.828003 
	C584.927368,925.971741 583.447876,926.457886 581.972900,926.820862 
	C569.823669,929.811340 557.593140,931.786255 544.652344,929.879883 
	C543.734314,930.029358 543.204712,930.054321 542.279663,930.012451 
	C541.557922,929.930237 541.184082,929.743530 540.907410,929.839661 
	C534.008728,932.234070 526.886902,932.686829 519.661682,933.464050 
	C511.679871,934.322815 503.674896,934.337646 495.919922,933.933289 
	C481.749969,933.194397 467.643280,930.956909 453.405823,930.411011 
	C448.248138,930.213196 443.215240,929.592957 439.144196,925.552856 
	C436.959106,923.384399 433.594269,923.321472 430.589874,923.505981 
	C422.803101,923.984131 414.866699,922.785828 408.050995,920.041321 
	C397.976410,915.984680 387.110779,913.563782 377.916718,907.414246 
	C374.616974,905.207153 370.804413,904.630920 367.148346,903.595459 
	C360.776886,901.790894 354.682404,899.828552 351.740967,892.983765 
	C350.912445,891.055786 348.770844,890.103943 346.785156,889.977661 
	C337.634491,889.395691 330.431488,883.816772 322.443329,880.400208 
	C315.781586,877.550964 309.701294,873.296387 302.865997,870.633850 
	C300.798248,869.828308 298.848694,868.650452 297.927246,866.617371 
	C294.546204,859.157715 287.549896,857.080383 280.654297,855.238464 
	C277.240295,854.326599 274.645416,852.898560 272.272186,850.358398 
	C267.758087,845.526489 264.423462,839.529358 258.272095,836.275513 
	C255.185928,834.643066 251.858337,833.515503 248.946808,831.509827 
	C244.281265,828.295776 243.869263,825.741333 247.228348,821.220642 
	C248.444321,819.584106 251.779190,817.691528 248.866364,815.622375 
	C246.539795,813.969788 245.490173,816.992676 244.360138,818.851013 
	C242.087860,822.587708 238.936340,823.526917 234.921326,821.840942 
	C227.978027,818.925415 224.659882,812.620605 221.213028,806.595093 
	C220.060547,804.580383 220.403183,802.014648 222.720657,799.957153 
	C223.253922,799.642700 223.477158,799.540161 224.046051,799.339111 
	C225.649658,799.076843 227.088043,798.512451 225.834106,797.187317 
	C221.869400,792.997559 224.397003,789.882019 227.414154,786.555908 
	C227.655853,786.422852 228.051208,786.210388 228.051208,786.210388 
	C228.051208,786.210388 228.036652,785.765930 227.802597,785.646606 
	C224.760147,784.291199 224.496475,785.955200 223.991760,788.203125 
	C222.834335,793.357788 219.347778,794.230225 215.092102,790.321106 
	C214.677017,783.453918 217.694504,777.523315 218.446136,771.174438 
	C218.618851,769.715576 218.827591,768.260925 219.016266,766.375854 
	C218.884323,765.756165 218.755875,765.564880 218.676392,765.031372 
	C220.912704,762.487183 221.016205,762.140015 220.675064,758.850647 
	C220.626099,758.378418 220.778519,757.890015 220.931274,757.072388 
	C221.241364,755.826416 221.598419,754.967896 222.251831,753.863647 
	C223.628403,749.629578 225.400940,745.852234 226.077454,743.008606 
	C226.174957,745.660095 223.103226,748.673828 222.703613,752.802307 
	C219.948181,755.463989 220.673767,759.665466 217.348999,761.898315 
	C208.466309,765.184265 200.312973,759.320007 191.816559,760.941772 
	C189.560379,761.372375 188.308609,759.672668 188.188629,757.709106 
	C187.949722,753.799194 186.637939,752.504089 182.460602,753.515259 
	C179.617767,754.203430 176.817505,753.782654 177.256149,748.828979 
	C177.594055,745.013062 178.023560,740.523010 175.056076,736.963501 
	C174.222473,735.963623 173.614014,734.568054 174.510544,733.257629 
	C175.688461,731.536011 176.614380,729.723755 173.696747,729.215271 
	C171.271729,728.792725 168.900177,729.340515 169.572708,732.880188 
	C169.607132,733.061340 169.223877,733.321899 169.006454,733.579468 
	C166.617752,732.287598 167.191223,729.754028 166.444092,727.851196 
	C163.886703,721.338196 158.837189,715.573181 161.633423,707.477966 
	C162.333954,705.449890 160.623795,701.635376 157.446259,700.209167 
	C156.344864,699.714905 155.658188,698.843994 154.910278,697.913635 
	C151.918137,694.191589 151.757492,688.788513 147.559982,685.757874 
	C146.754028,685.175842 146.852936,683.670227 147.718765,683.062561 
	C151.490448,680.415649 149.434616,678.305969 147.542633,675.690979 
	C146.075668,673.663452 144.302872,671.415710 145.673767,668.367126 
	C146.434219,666.676025 145.418060,664.791870 144.171951,663.887756 
	C137.913071,659.346252 137.581863,650.418701 130.911865,646.215149 
	C130.364609,645.870300 129.854630,644.516846 130.101746,644.008545 
	C132.451218,639.175659 129.244843,636.674927 126.401993,633.569458 
	C122.462440,629.265991 124.503807,624.357178 126.264801,619.762756 
	C127.544479,616.424072 127.554016,613.449280 123.317261,613.176208 
	C118.757172,612.882446 117.053543,609.366943 117.372139,606.565308 
	C117.935699,601.609375 116.054169,596.332764 118.984436,591.277588 
	C119.829987,590.017334 120.810432,590.029358 122.166687,590.539429 
	C124.900940,589.768738 124.900940,589.768738 127.767036,583.348572 
	C123.734772,584.852356 122.837593,582.514221 122.265343,579.250488 
	C121.260307,578.281433 120.181786,578.118958 119.210434,577.214844 
	C115.647209,576.840820 113.786201,575.776367 116.209091,572.259277 
	C116.863533,571.309326 116.733482,570.464722 116.104301,569.247070 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M224.122009,799.806030 
	C221.676086,801.822327 220.988480,804.654358 222.538895,806.635193 
	C226.204269,811.317993 228.525223,817.227661 234.279251,820.140076 
	C239.413925,822.738953 242.156921,821.336853 243.541840,815.704590 
	C244.331924,812.491455 247.734070,813.510803 249.723984,813.406250 
	C252.413895,813.264954 252.363007,816.138794 251.711929,817.986755 
	C251.197098,819.448120 249.780411,820.631165 248.648499,821.828430 
	C244.765060,825.936340 244.992996,827.691772 250.070786,830.512878 
	C253.399597,832.362244 256.864624,833.965393 260.206390,835.792725 
	C266.406250,839.182861 269.491028,845.594849 274.196869,850.439941 
	C275.974487,852.270081 277.945129,852.854919 280.381348,853.634766 
	C287.239777,855.830017 295.308685,856.780518 298.533691,865.026367 
	C299.147858,866.596619 300.027588,868.175964 301.711639,868.710571 
	C312.978485,872.286560 322.234070,880.010315 333.558319,883.757874 
	C338.353180,885.344604 342.752106,888.102661 348.082062,888.740723 
	C351.111450,889.103271 353.567749,890.832703 354.368347,894.774231 
	C354.922852,897.504272 358.158417,899.203491 361.051971,900.116821 
	C365.478638,901.514282 370.167603,902.258362 374.381348,904.108521 
	C379.758759,906.469727 384.766113,909.642456 390.313751,911.736938 
	C392.791016,912.672302 395.136627,914.475525 397.777191,914.318237 
	C403.072510,914.002930 405.638153,919.514404 410.924194,919.638550 
	C417.986237,919.804504 424.791931,922.598389 432.072266,922.120361 
	C435.958618,921.865234 439.696228,922.615601 442.541016,926.462097 
	C444.670776,929.341797 448.962982,928.504944 452.297821,928.905823 
	C459.936768,929.824036 467.880432,928.672913 475.241455,929.698486 
	C481.461578,930.565125 487.863678,931.514526 494.156342,932.595093 
	C496.658478,933.024780 499.133545,933.302490 501.561401,932.609863 
	C504.214539,931.852844 506.885712,932.705933 509.381104,932.459351 
	C518.057922,931.601868 526.995544,933.188354 535.427734,929.841675 
	C537.358521,929.075317 539.216309,927.643982 541.767090,928.739319 
	C542.133118,929.196106 542.266296,929.392273 542.323486,929.935852 
	C541.261047,932.201904 538.724854,931.831909 537.209229,933.517029 
	C541.030579,937.304810 544.655457,936.057434 548.600464,933.581848 
	C549.511963,933.099304 550.171326,932.927917 551.159302,932.757080 
	C551.811462,932.672852 552.127930,932.632690 552.422363,932.587708 
	C552.116943,932.453735 551.795593,932.399170 551.098389,932.436768 
	C548.169678,932.272095 545.282593,932.919617 543.925842,929.396240 
	C550.024353,929.000000 556.048950,928.964722 562.072998,929.009155 
	C569.130554,929.061157 576.724060,927.750854 582.199158,924.705017 
	C588.272949,921.326111 593.942627,921.659424 599.928345,920.775635 
	C604.982727,920.029419 609.879211,918.936829 614.407776,915.989929 
	C618.996704,913.003845 624.720703,915.308228 629.898804,914.182129 
	C632.737793,913.564819 635.278076,912.928528 637.633667,911.232361 
	C640.433472,909.216370 642.632324,906.669434 647.141357,907.514771 
	C650.522278,908.148560 652.675232,903.715271 655.434631,901.656677 
	C661.701294,896.981567 669.296509,895.405945 676.053650,891.949524 
	C677.517273,891.200867 679.308105,890.475342 680.131836,889.208313 
	C682.545532,885.495789 685.912292,884.268799 690.099976,883.711243 
	C694.299500,883.152100 696.190979,878.832703 694.581726,874.873962 
	C694.218323,873.979980 694.184937,872.951843 693.998657,871.614380 
	C696.843079,868.456909 700.714050,869.502563 704.431152,868.546509 
	C707.800171,868.545105 710.450928,867.000671 713.376282,866.683350 
	C715.764465,866.424316 715.648682,864.812256 715.124756,862.711670 
	C715.063293,860.241943 716.113831,859.137634 718.567383,859.063965 
	C720.302917,859.318420 720.946777,860.752808 722.562012,861.191528 
	C725.862732,862.329773 728.150940,860.411682 731.023987,859.488403 
	C738.376526,861.435852 741.546143,860.306274 745.628845,854.419922 
	C747.293152,852.020569 748.625000,849.059387 752.740784,850.738342 
	C751.187988,853.468079 749.928894,856.560120 748.173767,858.697083 
	C743.938110,863.854065 738.413696,867.952393 733.205933,872.243774 
	C732.307312,871.297302 731.619690,870.553162 730.314880,871.582886 
	C725.973389,874.412048 721.193604,875.005554 716.569885,875.954468 
	C712.241943,876.842712 708.999878,879.207336 706.352295,882.511292 
	C705.740234,883.275085 704.594055,884.474365 705.852173,885.558167 
	C706.755066,886.336182 707.636292,885.411621 708.536133,885.011414 
	C712.315247,883.330505 715.595886,880.638489 719.697815,879.603699 
	C721.302795,879.198853 723.488831,878.158081 724.236145,879.740112 
	C725.174133,881.725952 722.730835,882.742126 721.349426,883.754395 
	C718.520569,885.827087 715.066833,886.746826 711.935974,888.225098 
	C705.247070,891.383240 699.400879,896.417969 691.789246,897.581970 
	C689.194214,897.978821 686.690247,899.087463 685.657288,894.960571 
	C684.946533,892.120972 682.254822,892.725403 680.274963,893.923462 
	C673.854492,897.808411 667.147400,901.109680 660.260010,904.056335 
	C659.085754,904.558777 658.073975,905.180481 658.219116,906.525208 
	C658.407532,908.270203 660.249207,907.932190 661.295898,908.616211 
	C662.325623,909.289246 664.417297,908.404968 664.469116,910.279846 
	C664.507202,911.662842 663.104248,912.418640 661.801331,912.919250 
	C653.162659,916.237549 644.562744,919.661133 635.873657,922.841187 
	C633.419373,923.739319 630.721252,924.011353 628.113342,924.438660 
	C625.479797,924.870178 621.817444,926.997742 622.715210,921.179077 
	C622.941284,919.713135 621.435608,919.067566 619.861023,919.217712 
	C613.798706,919.795715 608.595947,922.985046 602.939819,924.794189 
	C602.274292,925.007080 601.431091,925.966125 601.389221,926.624939 
	C600.995239,932.816895 595.168823,932.309814 591.637329,934.519165 
	C589.861389,935.630249 588.670471,934.352173 588.039673,932.415222 
	C586.605164,928.010559 581.403809,926.873108 578.048584,930.046021 
	C576.432739,931.574097 577.321350,932.695068 578.373901,933.850525 
	C580.350830,936.020935 579.757568,937.247009 576.970276,936.950623 
	C567.358704,935.928589 558.043457,938.725708 548.979370,940.580811 
	C542.061646,941.996521 535.118530,943.857849 528.033752,944.839172 
	C524.283630,945.358582 522.449097,949.425476 518.509949,949.754639 
	C516.207825,949.947083 514.387695,949.871338 514.061523,947.217773 
	C513.650330,943.872498 511.144073,943.417847 508.932343,944.096436 
	C499.544861,946.976685 490.635284,942.790161 481.493988,942.332886 
	C476.940826,942.105103 472.403625,939.481689 467.467438,939.878296 
	C467.025177,939.913818 466.328094,939.681152 466.123352,939.345215 
	C461.249634,931.346008 453.781372,937.298584 447.672852,935.971619 
	C445.642914,935.530640 443.143524,936.562622 441.397614,935.096191 
	C437.625946,931.928528 433.427002,932.535339 429.150421,933.130432 
	C423.292877,933.945374 419.935974,931.856323 417.851929,926.079895 
	C417.437988,924.932495 416.759338,923.962524 415.626343,923.526062 
	C414.142639,922.954468 413.443176,924.027466 413.083130,925.177246 
	C412.374786,927.439331 410.507172,927.239136 408.984619,926.893311 
	C404.956329,925.978394 400.850830,925.459473 396.918152,924.013550 
	C385.319061,919.749023 373.174774,916.858276 361.762390,912.430542 
	C354.224030,909.505798 346.365448,905.801331 339.534515,900.887390 
	C337.621063,899.510864 335.744690,897.975952 333.564789,897.359985 
	C325.708984,895.140320 319.980225,888.627258 311.676147,887.076172 
	C308.217041,886.429993 305.129303,883.513428 302.694550,879.262756 
	C307.552246,880.278931 311.709808,879.534729 315.567444,881.303406 
	C316.311584,881.644531 317.176636,881.975708 317.687500,880.941101 
	C318.035706,880.235962 317.685181,879.575867 317.125244,879.200562 
	C315.115143,877.853699 313.613831,875.602661 310.653809,875.950378 
	C309.052460,876.138550 307.217133,876.424438 305.853699,875.182129 
	C302.949066,872.535767 300.771912,871.862488 299.743958,876.752075 
	C299.408417,878.348145 297.933014,877.969299 296.877747,877.375793 
	C295.143402,876.400391 293.392670,875.403625 291.836823,874.177368 
	C290.588318,873.193237 288.834595,872.176270 289.316803,870.224365 
	C289.744049,868.494995 291.499237,869.154602 292.734528,868.980469 
	C293.576630,868.861694 294.447998,868.790222 294.713623,867.813965 
	C295.022705,866.678101 294.381439,865.896973 293.507233,865.320862 
	C288.208069,861.828674 283.171875,857.820251 276.255493,857.075867 
	C278.031311,858.640747 279.641144,860.478333 281.631927,861.690186 
	C283.445312,862.793945 283.962372,864.386658 284.532959,866.109009 
	C285.199188,868.119995 284.720490,869.158508 282.457428,868.274048 
	C275.365601,865.502441 266.442688,866.138245 263.293915,856.320679 
	C262.107208,852.620605 258.025452,850.159668 253.446152,851.136475 
	C248.755203,852.137085 245.840866,850.027161 243.668060,846.280151 
	C242.664871,844.550171 241.908676,842.645752 239.349823,842.040527 
	C238.639114,839.858887 237.474594,838.264465 235.334961,837.053284 
	C230.113968,832.732666 229.939056,831.318115 234.303070,828.047241 
	C236.418579,827.887268 237.763458,828.672791 239.231476,830.054443 
	C240.910812,832.449158 242.764847,834.040161 245.117798,835.081787 
	C246.315765,835.612061 247.732788,836.039124 248.211426,837.417297 
	C249.922043,842.342529 252.180313,839.411865 254.623001,837.623352 
	C248.696823,837.765564 246.695206,832.006714 241.975113,829.798950 
	C240.313965,827.817078 238.657593,826.422058 236.104767,825.722412 
	C235.197693,825.312561 234.557983,824.645813 233.992340,824.878784 
	C228.574036,827.110229 226.988327,823.390564 225.240173,819.251953 
	C225.204224,816.161743 223.354828,815.108704 221.047409,815.088989 
	C216.272919,815.048157 215.369034,812.766907 216.956146,808.375854 
	C219.459152,807.558167 218.981232,806.515747 217.279327,805.138977 
	C215.040421,802.892395 217.062180,799.748535 215.035095,797.321289 
	C213.894379,794.908875 213.598068,792.756714 214.711197,790.252686 
	C221.000534,793.204590 222.794434,792.102783 222.631195,785.293396 
	C222.564468,782.509583 222.854584,782.465759 228.730591,784.664795 
	C229.383728,785.401306 229.485397,785.939148 228.918274,786.771362 
	C226.299591,789.914429 223.015335,792.372803 227.098846,796.603333 
	C229.106857,798.683655 228.366211,799.643188 225.228729,800.254395 
	C224.867233,799.636108 224.614944,799.506042 224.122009,799.806030 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M222.944916,753.778442 
	C222.763336,754.830627 222.514145,755.616333 222.136978,756.688110 
	C220.295013,759.177002 220.821213,762.154297 219.210052,764.763184 
	C219.000717,765.061218 219.007217,765.505005 219.010010,765.726196 
	C217.093918,769.290649 215.455063,772.752686 214.263138,776.758423 
	C211.716324,776.365723 209.306320,775.539368 209.165924,772.822937 
	C209.031357,770.218872 211.275253,769.400085 213.430588,768.687683 
	C216.225037,767.763855 217.090302,765.530945 216.998749,762.393921 
	C219.313919,759.472046 218.046692,755.226379 221.603455,753.056763 
	C222.325455,753.047485 222.630707,753.228088 222.944916,753.778442 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M214.731094,797.207764 
	C218.968674,798.488037 216.933075,801.656616 217.001801,804.618469 
	C213.997665,804.686829 215.620804,801.833008 214.251266,800.233276 
	C214.148941,799.132935 214.313019,798.276672 214.731094,797.207764 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M93.359329,343.914001 
	C93.210724,341.296844 92.420898,339.401337 89.782127,337.963531 
	C85.883972,335.839569 86.182846,333.364929 90.629257,331.039490 
	C90.858162,333.565613 91.491722,335.651184 93.602287,337.496552 
	C95.641090,339.279114 95.820992,341.678253 93.359329,343.914001 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M91.327255,345.957886 
	C91.081589,345.101135 91.335815,344.324890 92.675049,344.040436 
	C92.918228,344.895721 92.665657,345.673126 91.327255,345.957886 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M107.001167,706.571472 
	C103.022652,703.563477 102.724480,699.626343 106.651527,696.987915 
	C107.358185,697.086609 107.603973,697.283203 107.899826,697.761841 
	C107.186600,700.639343 106.768143,703.351807 107.001167,706.571472 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M856.791077,132.247772 
	C858.124023,131.921432 859.366089,132.150711 859.977539,130.407166 
	C860.784607,130.059082 861.515137,130.040924 862.611206,130.025482 
	C864.711121,132.397095 864.873840,134.513229 862.693054,136.910126 
	C860.024048,139.843658 860.572754,142.351807 862.271362,146.397446 
	C865.008240,152.915924 871.971924,151.254272 875.596313,155.640488 
	C876.942993,157.270157 877.635742,158.010269 876.921631,159.672073 
	C875.609131,162.726517 876.785706,165.715958 879.288269,166.309341 
	C885.859802,167.867554 885.795715,172.172119 885.007324,177.599426 
	C884.713989,178.786224 884.145813,179.259903 882.963867,179.445679 
	C880.514282,176.711700 878.635864,173.823349 874.683533,173.527527 
	C873.564087,173.443741 872.568054,172.481934 871.984314,171.465256 
	C870.164246,168.295761 868.404053,165.227112 864.407471,164.199921 
	C863.001648,163.838593 862.389160,162.145508 861.919434,160.669601 
	C859.844421,154.149323 858.828552,153.404755 851.830322,153.036728 
	C851.333923,153.010635 850.857361,152.848373 850.084717,152.450378 
	C849.304749,151.613174 849.140869,150.900085 849.231689,149.775269 
	C850.390259,147.031448 852.685608,145.858307 854.496826,144.180237 
	C858.140869,140.803787 858.794617,136.693710 856.791077,132.247772 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M882.742188,179.335327 
	C883.484070,178.737198 883.985962,178.480515 884.747559,178.112396 
	C890.189697,176.781967 888.393494,173.020584 888.009277,169.376495 
	C889.342346,169.049957 891.100647,169.113373 890.870300,170.834335 
	C890.316223,174.974075 893.254944,176.442688 895.993774,178.575607 
	C891.722168,178.841339 888.321350,182.573944 883.428162,181.981659 
	C882.342957,181.324036 882.247742,180.539627 882.742188,179.335327 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M878.970703,153.399490 
	C882.669250,153.839325 886.104614,154.955429 886.272278,159.785217 
	C885.241211,159.682510 884.441650,159.370407 883.320190,159.034531 
	C881.428040,159.695328 881.287598,158.027222 880.234802,157.235504 
	C878.835693,156.177216 879.044434,154.925568 878.970703,153.399490 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M887.518127,164.924438 
	C887.893616,165.359650 887.938416,165.832520 887.982666,166.660828 
	C887.298828,167.590271 886.533813,167.705917 885.360596,167.191254 
	C885.106934,165.601974 885.895630,165.009369 887.518127,164.924438 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M880.032837,157.318207 
	C881.217651,156.959641 881.850830,157.804916 882.743042,158.827606 
	C881.023621,160.685928 880.628479,158.983322 880.032837,157.318207 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M885.157532,167.254059 
	C885.763062,166.973694 886.502869,166.988586 887.612366,167.009888 
	C887.991150,167.454315 888.000244,167.892349 888.014282,168.658875 
	C886.857544,168.986877 885.922729,168.556259 885.157532,167.254059 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M881.170349,193.710785 
	C875.361511,195.308746 868.863831,189.969223 869.508972,184.242279 
	C869.748352,182.117767 869.644775,181.331024 867.368347,180.949280 
	C862.649658,180.157974 861.875244,178.438187 864.135803,174.377457 
	C865.103577,172.639069 866.593018,171.001709 864.829651,169.017059 
	C863.047852,167.011719 861.721619,168.731110 860.146973,169.596313 
	C858.295044,170.613892 856.609192,169.410843 855.097290,168.418198 
	C853.822632,167.581345 852.701721,166.587219 851.067566,167.578186 
	C850.289062,168.050232 849.402588,169.277466 848.542114,168.210068 
	C847.418091,166.815765 848.087646,165.420334 849.680969,164.169525 
	C858.069519,159.626358 866.494629,162.239502 870.699097,170.483749 
	C871.420410,171.898026 872.445007,172.853409 873.814697,173.567947 
	C878.508667,176.016754 881.018188,179.841507 881.019531,185.601547 
	C880.999878,186.445175 880.997375,186.889725 880.994995,187.667709 
	C881.215820,189.798492 881.726135,191.577484 881.170349,193.710785 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M881.306763,185.839233 
	C879.632751,184.871246 878.996277,183.362518 879.026978,181.644699 
	C879.060120,179.795883 878.243591,178.376511 876.846985,177.416000 
	C872.996155,174.767548 869.692627,172.240463 867.613098,167.367020 
	C866.004700,163.597839 860.269348,162.873505 855.701904,163.342072 
	C854.100586,163.506348 852.502991,164.047638 850.413940,163.999771 
	C849.989929,163.556320 849.991455,163.113235 849.991577,162.337845 
	C849.510986,161.490829 849.169739,160.657410 849.572937,160.248932 
	C852.142212,157.645676 850.849854,155.108795 849.815430,152.258163 
	C853.294739,151.962097 856.370789,150.694778 859.738220,153.425995 
	C861.969788,155.235947 863.223206,156.512222 863.017395,159.243042 
	C862.862976,161.292099 863.772034,163.348022 865.634094,163.550705 
	C869.751160,163.998932 871.062073,166.925034 872.484619,169.956451 
	C873.343506,171.786804 874.870728,173.052185 876.759399,172.874359 
	C881.098267,172.465851 882.212952,174.924698 882.711914,178.734222 
	C882.987976,179.740372 882.993713,180.486847 883.009033,181.605743 
	C883.023926,183.391937 882.976440,184.785614 881.306763,185.839233 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M881.333252,194.008667 
	C880.094910,192.236343 879.511108,190.433441 880.732910,188.241623 
	C883.770508,189.820175 885.179565,187.333221 886.946167,186.077637 
	C890.676025,183.426880 892.415100,183.834244 892.748352,188.299942 
	C893.110413,193.152435 896.425964,197.143524 896.048157,202.125793 
	C895.847656,204.769180 896.111572,207.428360 894.620972,209.826935 
	C892.649475,212.999191 895.548523,215.413452 896.003296,218.598969 
	C896.014648,218.984940 896.002625,218.997437 895.998901,219.000870 
	C892.044983,219.048325 889.003113,215.784943 889.179321,211.597610 
	C889.248718,209.947922 889.705200,208.340881 890.217590,206.779739 
	C891.099854,204.091476 891.171509,201.619247 888.900330,199.507843 
	C887.909241,198.586487 887.238403,197.459717 887.291687,196.032669 
	C887.420227,192.594742 885.203796,194.125031 883.307861,194.157990 
	C882.547180,194.015015 882.105774,194.012100 881.333252,194.008667 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M883.030640,194.359436 
	C884.705139,193.504593 885.466309,190.704575 887.497131,191.521194 
	C889.661621,192.391571 889.019836,194.483627 888.451599,196.283783 
	C888.235352,196.968689 888.484558,197.587387 889.126587,197.970535 
	C893.660645,200.676315 892.647644,204.396469 891.120911,208.310303 
	C889.262024,213.075485 890.974670,216.536026 895.645752,218.962219 
	C896.172424,222.493011 895.425781,223.970016 892.247314,220.865219 
	C891.098022,219.742447 889.635437,218.947906 888.384338,217.920212 
	C885.838745,215.829239 883.539307,215.486923 881.305542,218.839432 
	C879.013123,216.602463 878.775513,214.973267 882.477722,213.993958 
	C886.978516,212.803406 887.965759,209.128952 885.166992,204.332642 
	C884.343384,203.353226 883.657349,202.708191 883.878479,201.324402 
	C885.092163,198.693130 884.395569,196.650909 883.030640,194.359436 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M741.295044,98.193596 
	C740.534912,98.045616 740.096680,98.037605 739.329834,98.016708 
	C740.987488,94.809860 743.220215,92.178619 747.626282,92.963615 
	C749.871887,93.363708 751.138672,92.546204 750.188721,89.896385 
	C749.817688,88.861481 749.554871,87.259659 750.677063,86.719292 
	C752.395813,85.891754 752.511658,88.120834 753.490112,88.733925 
	C758.310791,91.754524 763.595764,93.885315 768.989990,95.514160 
	C770.721375,96.036957 771.891418,93.750389 771.979980,91.351410 
	C771.976501,90.980492 772.011963,90.508667 772.024231,90.272400 
	C772.403870,89.638817 772.855713,89.476852 773.708374,89.758377 
	C774.768555,89.980400 775.512207,89.994156 776.627258,90.026703 
	C779.619385,93.375351 781.934448,97.100502 786.963257,97.083588 
	C787.707703,97.081078 789.019043,97.541901 789.114563,97.991173 
	C790.482239,104.421082 796.795532,109.798721 793.148682,117.626938 
	C792.984009,118.434517 792.974243,118.877701 792.957275,119.653175 
	C791.916260,121.360954 790.357605,121.507950 788.466431,121.737350 
	C784.158020,120.359352 784.487244,117.408455 786.340271,114.983055 
	C789.226379,111.205490 786.958984,107.643127 786.681885,103.994690 
	C786.618164,103.154305 785.947693,102.461235 784.960510,102.510567 
	C784.007996,102.558167 783.552734,103.262146 783.261719,104.087341 
	C782.875488,105.182831 782.854248,106.389397 782.239868,107.415878 
	C780.516602,110.295067 777.227783,110.740089 775.286865,108.013466 
	C773.895203,106.058594 772.763184,104.207031 770.146423,103.815475 
	C769.115356,103.661194 768.497986,102.732613 768.310181,101.663940 
	C767.558838,97.387604 764.697388,97.687477 761.485962,98.371155 
	C756.333374,99.468086 751.147278,100.034630 746.040833,98.103996 
	C744.500427,97.521599 743.095520,98.375587 741.295044,98.193596 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M760.220154,76.284111 
	C761.161621,75.426712 762.498962,74.607262 762.611023,73.376938 
	C762.963135,69.512093 765.627197,69.951050 768.609863,70.037849 
	C765.752136,71.662415 765.645264,77.466431 760.220154,76.284111 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M731.176147,73.346031 
	C734.495422,73.269165 738.537415,72.071114 740.744873,76.779854 
	C740.906433,77.670021 740.122864,78.519485 740.111816,78.486244 
	C738.670898,74.148750 733.944092,75.829643 731.176147,73.346031 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M748.037720,76.348267 
	C748.014465,75.537346 748.028992,75.075905 748.088196,74.269577 
	C748.750061,74.123520 749.380005,74.290100 749.981995,74.527481 
	C751.571899,75.154510 752.190125,76.362038 751.954163,78.021248 
	C751.864624,78.651070 751.825562,79.519165 751.003174,79.492363 
	C749.278076,79.436142 748.564941,78.143639 748.037720,76.348267 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M742.991272,69.630104 
	C738.211792,73.657417 739.664429,67.885773 738.016541,66.382988 
	C739.888794,66.726616 742.606445,66.174118 742.991272,69.630104 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M755.655457,73.044777 
	C755.985291,73.742203 755.989746,74.501892 755.984924,75.641640 
	C753.929504,75.455643 753.229553,74.591454 755.655457,73.044777 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M746.970642,70.709229 
	C746.448486,70.828140 745.894592,70.658424 745.170288,70.246422 
	C745.906677,68.930618 746.524048,69.204155 746.970642,70.709229 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M773.976074,89.675667 
	C773.582764,89.985443 773.140625,90.004250 772.367432,90.029594 
	C772.252991,89.520569 772.332947,88.761101 772.717529,88.545952 
	C773.311646,88.213600 773.734009,88.780388 773.976074,89.675667 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M830.930542,135.418030 
	C831.788269,135.278748 832.581177,135.476059 833.657837,135.801941 
	C832.893616,140.278870 827.417908,143.710312 822.976318,142.415314 
	C821.516846,140.871689 820.081543,140.061020 819.710999,137.667404 
	C819.372192,135.478500 817.677246,133.218491 814.383057,133.011703 
	C815.374817,129.779892 816.875916,127.002823 820.359985,131.187927 
	C820.899658,131.836258 821.535950,131.621750 822.160767,131.434982 
	C823.793030,130.947021 824.729492,131.991394 825.364319,133.134964 
	C826.282959,134.789734 824.847412,135.426987 823.742554,136.161209 
	C822.579834,136.933868 821.441040,137.975006 822.614990,139.342102 
	C823.627197,140.520813 825.042542,140.007034 826.196411,139.180969 
	C827.766052,138.057236 829.310486,136.898407 830.930542,135.418030 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M822.653625,142.164001 
	C827.789673,142.637543 830.257263,138.662384 833.814270,136.254395 
	C835.009338,135.217346 835.628052,133.786987 837.534851,133.839600 
	C838.955872,133.851639 840.047180,133.911560 841.546875,133.968445 
	C843.332947,132.683990 845.157532,132.625763 846.555481,133.286438 
	C849.542053,134.697830 851.896545,133.582779 854.657471,132.043854 
	C855.448792,131.979889 855.893982,131.979706 856.673096,131.981186 
	C859.429871,133.577698 861.862854,135.022202 859.338623,138.544876 
	C857.543213,141.050323 857.829773,144.902100 854.054138,146.206512 
	C852.785217,146.644913 851.821289,147.966248 850.375610,148.948471 
	C849.068542,142.458389 845.451050,142.138412 839.505371,142.988022 
	C834.727539,143.670761 830.228516,143.947662 825.927246,146.475662 
	C821.054932,149.339294 816.346375,147.312012 812.665527,144.085388 
	C810.122009,141.855713 808.308533,141.049377 805.400330,142.927902 
	C799.943665,146.452713 794.591675,144.753708 788.961182,142.161179 
	C788.380493,141.904861 788.154297,141.784927 787.616699,141.435532 
	C785.033630,138.923401 783.836548,136.558029 787.048218,133.665436 
	C789.698120,132.809631 791.271179,130.910965 793.659546,129.896606 
	C796.570557,129.803055 797.463135,131.417694 797.931885,133.573959 
	C798.856689,137.827988 801.308777,139.127838 805.477295,137.625854 
	C810.541016,135.801331 814.354797,136.827972 817.616882,141.315948 
	C819.061646,143.303619 820.499634,143.187195 822.653625,142.164001 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M793.996216,129.624786 
	C792.332092,131.459793 791.615112,134.174255 788.349365,133.991821 
	C787.282471,133.789337 786.887268,133.299545 786.861450,132.302399 
	C787.118713,131.688675 787.308899,131.432983 787.788940,131.120651 
	C789.627014,129.789276 789.389587,127.621201 790.753540,125.890106 
	C792.054932,125.266342 792.929871,125.436157 793.792725,126.694366 
	C794.013489,126.991798 794.002563,126.999657 794.007324,126.992935 
	C794.006714,127.740135 794.001343,128.494049 793.996216,129.624786 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M787.819458,121.355721 
	C789.345459,120.251190 790.724182,119.874680 792.586792,119.986984 
	C794.181274,120.816071 793.966980,122.094940 793.964294,123.674347 
	C793.054504,124.853714 791.972778,124.995354 790.414978,124.844650 
	C788.643555,124.180107 787.520630,123.405098 787.819458,121.355721 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M789.829956,124.240448 
	C791.048889,124.012085 792.105103,124.017578 793.555420,124.034088 
	C793.974365,124.781433 793.999146,125.517769 794.018738,126.622940 
	C793.215088,126.687843 792.416687,126.383904 791.297363,126.059525 
	C790.595764,125.966881 790.263977,125.798218 789.834961,125.288818 
	C789.681946,124.853874 789.674622,124.664085 789.829956,124.240448 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M844.332764,122.110718 
	C844.404297,121.924225 844.429443,121.923241 844.431702,121.910400 
	C844.605713,122.090767 844.621704,122.295441 844.353577,122.603317 
	C844.225220,122.695053 844.261292,122.297218 844.332764,122.110718 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M854.743164,131.714386 
	C852.925842,135.153824 850.718933,137.551147 846.618103,134.535645 
	C845.701172,133.861404 844.078491,134.146759 842.367981,133.977875 
	C842.192993,132.629974 843.007202,132.065506 844.345154,132.005890 
	C847.725891,131.855255 851.103638,131.638763 854.743164,131.714386 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M912.690308,805.031982 
	C912.987610,805.435486 912.992615,805.884521 912.995117,806.670227 
	C911.770752,806.679260 910.324402,806.420715 912.690308,805.031982 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M896.974731,786.635986 
	C896.994690,787.442810 896.995239,787.887024 896.995483,788.664429 
	C896.559448,789.392090 896.203735,789.839600 896.028320,790.710999 
	C894.033875,795.446899 893.355957,799.914307 894.801880,804.757446 
	C895.337830,806.552551 894.441406,808.879333 892.980469,810.205261 
	C889.630127,813.246155 890.638184,815.604431 893.718262,817.826050 
	C894.951416,818.715515 896.727905,819.674194 895.431091,821.216248 
	C893.988770,822.931030 892.541199,821.660095 891.472168,820.138428 
	C890.588562,818.880676 889.439880,817.949280 887.388062,817.998291 
	C885.954651,818.604736 885.027954,818.168945 883.838257,817.357117 
	C883.294373,816.648926 883.109497,816.179443 882.981812,815.307495 
	C882.989136,814.439453 882.956116,813.826965 883.272095,813.498352 
	C887.905884,808.678772 888.254944,802.549805 888.566895,796.403564 
	C888.356262,796.141968 888.046509,795.897400 887.733521,795.990723 
	C884.676025,796.902039 881.671509,797.132385 879.754028,794.030334 
	C877.713013,790.728333 879.582947,787.934509 881.591797,785.417542 
	C884.039917,782.350342 887.326050,780.461548 891.432800,779.435730 
	C894.108887,781.301941 895.979126,783.446472 896.974731,786.635986 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M897.254395,786.766113 
	C895.529541,784.753601 893.086609,783.270508 892.031860,780.333008 
	C890.347473,777.638123 891.694275,775.972473 893.645630,774.182495 
	C896.761963,773.277283 897.518311,769.858154 900.688354,769.138062 
	C901.549255,772.194153 900.810608,775.384216 901.618164,778.714050 
	C902.261353,781.366516 898.456421,780.648315 896.602600,781.353271 
	C896.628357,783.277710 898.984009,784.593811 897.254395,786.766113 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M883.722046,816.776367 
	C884.851929,817.127014 885.640808,817.428772 886.721252,817.860840 
	C887.383911,819.342834 889.083801,820.816895 886.320312,821.967163 
	C885.175354,821.110046 884.361572,820.195679 883.266724,819.152954 
	C882.855225,818.835510 882.724854,818.646423 882.572388,818.113403 
	C882.703186,817.323181 882.974182,816.971069 883.722046,816.776367 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M899.724731,825.141296 
	C901.692688,826.986328 899.985596,827.378540 898.311157,827.963867 
	C898.005432,826.838623 898.446228,825.913513 899.724731,825.141296 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M896.666748,828.060608 
	C896.758667,828.809387 896.508728,829.612061 896.129395,830.707336 
	C895.829346,830.998169 895.658691,830.996338 895.231934,830.987549 
	C895.060181,829.853210 894.844482,828.584656 896.666748,828.060608 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M896.243835,790.826782 
	C894.948120,790.098755 895.194397,789.477661 896.707764,789.032349 
	C896.824402,789.549561 896.653564,790.101562 896.243835,790.826782 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M893.998230,773.628845 
	C894.085815,776.039673 891.729492,777.151184 891.999756,779.627136 
	C887.797302,782.171570 883.266663,783.869568 880.944519,788.537842 
	C879.991028,790.454712 879.333252,792.185486 881.091309,793.941223 
	C882.820251,795.667908 884.689026,796.281799 886.771973,794.543274 
	C889.721375,792.081482 891.303040,794.494202 890.686768,796.531921 
	C888.864807,802.556152 889.968140,809.767761 884.043701,814.625244 
	C883.195923,815.802795 882.359070,816.601562 881.381897,817.746338 
	C878.636108,820.876160 876.054810,823.278198 871.908203,824.029358 
	C868.416748,824.661926 866.049438,827.031494 867.496582,831.330200 
	C868.584656,834.562134 867.371277,837.243958 864.648987,839.095093 
	C861.908752,840.958557 859.723694,839.592407 857.720703,836.684570 
	C857.737061,840.176697 860.780762,842.980042 857.274902,845.788391 
	C849.069763,847.684692 847.999756,848.964783 847.999878,856.754700 
	C847.999878,857.418152 848.054688,858.086975 847.989746,858.744019 
	C847.787537,860.791016 848.879578,863.460632 846.386230,864.552002 
	C844.109070,865.548828 842.399963,863.645874 840.747375,862.270264 
	C838.589233,860.473755 836.508057,859.260315 833.693298,861.428223 
	C832.076233,862.673645 829.822021,861.862305 827.427063,862.001221 
	C825.564087,861.356445 824.130615,860.710388 822.356140,860.024902 
	C820.091064,856.677673 817.799194,856.182068 814.985229,858.996155 
	C814.065857,859.915649 813.113892,861.380371 811.691406,860.905945 
	C805.988403,859.003784 800.350586,860.865967 794.693542,860.827087 
	C789.515015,860.791504 784.264282,865.810303 784.738525,871.767578 
	C786.144348,873.299255 786.186951,874.716003 785.228638,876.606934 
	C784.681824,877.467712 784.364990,877.938110 784.022827,878.704285 
	C780.870667,883.068787 776.482239,884.078491 771.690308,883.972534 
	C770.044922,883.936096 768.405212,883.642822 766.776306,884.086487 
	C765.190918,884.518372 764.266174,885.114197 764.668518,887.196960 
	C765.662659,892.343750 761.036133,895.975281 755.639526,894.317810 
	C753.923828,893.790771 752.230591,893.107056 750.263916,892.241943 
	C753.235168,885.983215 757.460449,881.882019 764.483765,879.481567 
	C769.304688,877.833862 773.283691,875.229675 778.729553,876.664429 
	C780.708984,877.185974 782.678589,875.234436 781.699036,872.759583 
	C780.546570,869.847900 781.381165,867.595703 783.085815,865.378357 
	C783.845764,864.389832 784.247009,863.251831 783.821533,862.109863 
	C782.065857,857.397949 784.633972,856.213379 788.613281,855.866150 
	C791.202698,855.640259 793.827820,854.329346 794.870178,852.246033 
	C797.758789,846.473267 803.522339,847.637390 808.122253,846.123962 
	C810.251160,845.423645 811.960510,846.897339 812.814392,848.931335 
	C813.305908,850.102234 813.788269,851.043518 815.261230,850.872498 
	C816.831665,850.690063 816.905701,849.507812 817.009399,848.312744 
	C817.247681,845.566772 818.738159,844.101929 821.515320,844.026245 
	C822.721863,843.993347 823.910034,844.173340 824.488708,845.399841 
	C825.016907,846.519470 824.121582,847.214417 823.511963,847.988098 
	C822.542053,849.219116 820.591248,850.042725 821.418274,852.089111 
	C821.866821,853.198853 823.105469,853.092224 824.048035,852.923950 
	C826.255005,852.529846 828.639832,851.525635 828.794678,849.238098 
	C829.093994,844.816895 830.240723,842.752014 834.810669,845.341919 
	C835.584717,845.780640 836.668945,845.812195 836.765320,844.725891 
	C836.917053,843.015991 838.507690,841.388611 837.028625,839.532837 
	C836.323242,838.647766 834.274536,837.151306 836.021790,836.523010 
	C838.225586,835.730713 839.801331,833.791443 842.065369,833.550781 
	C843.018494,833.449463 843.030640,834.799561 842.594849,835.509521 
	C841.719238,836.936096 840.744568,838.283081 841.017029,840.466980 
	C841.224060,841.690979 840.927917,843.032043 842.791809,842.217896 
	C845.852966,841.379456 847.453003,840.022522 846.480652,836.746643 
	C845.809509,834.485474 847.607788,832.671509 848.188049,830.656250 
	C849.136292,827.362732 849.097961,824.521912 847.046997,821.372925 
	C846.565430,820.331665 846.788940,819.688538 847.241821,818.745605 
	C847.944641,817.683716 848.538025,816.972046 849.394348,816.023560 
	C849.862732,815.676147 850.071960,815.568359 850.628113,815.395508 
	C854.866943,816.764099 858.397644,816.794678 861.049011,812.919067 
	C861.604675,812.106873 862.514954,811.323303 863.459473,811.273315 
	C870.186707,810.916748 874.158142,805.049683 880.092407,803.055908 
	C882.532532,802.236206 885.342590,800.571777 880.414368,798.679871 
	C878.193848,797.827454 877.756836,795.433044 876.497314,793.309937 
	C876.196899,792.524048 876.109497,792.051758 876.085266,791.207214 
	C876.809021,788.723999 876.594177,786.648254 876.152283,784.470764 
	C875.484741,781.181458 876.552734,778.295654 879.875977,777.191589 
	C883.136292,776.108398 882.506042,774.373474 881.326660,772.291809 
	C879.325500,768.759644 880.177856,765.883301 883.316956,763.584595 
	C886.296692,761.402649 888.269531,758.594666 888.610657,754.897461 
	C889.010010,750.568848 893.516968,750.251648 895.753418,747.255310 
	C895.999939,746.555725 896.002380,746.113953 896.007263,745.340942 
	C896.754944,745.005432 897.500061,745.001160 898.617737,744.994751 
	C899.232544,748.724609 898.364258,752.545227 899.935425,756.583923 
	C899.676453,757.107666 899.420715,757.297607 899.106628,757.775330 
	C896.136169,758.587646 894.170105,760.694946 892.200623,762.503540 
	C889.795593,764.712158 889.031982,767.541931 892.172485,770.002258 
	C893.282166,770.871521 894.026794,771.809937 893.998230,773.628845 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M900.707764,763.033752 
	C900.993103,763.444641 900.995911,763.891663 900.997498,764.674194 
	C900.378540,765.692200 899.854980,765.395630 899.528259,764.761780 
	C899.055969,763.845703 899.505005,763.305969 900.707764,763.033752 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M826.819580,202.337067 
	C827.327698,198.820648 826.540894,195.997665 824.161987,193.316315 
	C824.000000,193.000610 824.000000,193.000000 824.000000,192.999390 
	C825.315613,191.771637 826.803162,192.061325 828.199585,192.689178 
	C836.564758,196.450623 846.114868,198.017487 852.365662,205.728043 
	C852.984497,206.491409 854.090759,206.699951 854.964600,207.176392 
	C859.317993,209.549820 859.735168,210.924698 856.712463,214.772995 
	C854.458008,217.643127 855.045349,219.119415 858.410583,220.038467 
	C861.324646,220.834274 863.292969,222.722443 864.390442,225.580780 
	C865.290405,227.924744 866.581177,230.121887 868.238708,231.968292 
	C870.247253,234.205795 871.281677,236.472702 870.972046,239.608093 
	C870.548462,243.897125 874.304871,246.693863 875.730774,250.733673 
	C876.243652,251.501984 876.509216,251.998779 876.884644,252.755722 
	C876.925598,256.996826 880.299988,257.294098 882.816406,257.996857 
	C887.873291,259.409058 891.668823,261.658112 892.114319,267.511810 
	C892.270508,269.564545 894.055176,270.453888 895.692932,271.356049 
	C899.184692,273.279633 900.720581,275.630737 897.514709,279.219391 
	C896.541992,280.308319 896.182495,281.928986 895.266174,283.087982 
	C893.863770,284.861877 894.236450,285.911011 896.059875,287.072632 
	C899.107910,289.014343 902.452942,290.685516 903.552490,294.670563 
	C903.895386,295.913239 905.434265,295.773254 906.536621,296.112396 
	C908.922302,296.846313 910.898865,298.419586 910.897461,300.977081 
	C910.893616,307.967529 915.501465,314.157715 914.191040,321.397919 
	C913.934753,322.814087 914.703247,325.066010 915.795227,325.959351 
	C919.770386,329.211670 920.528320,333.318726 920.013733,338.015076 
	C919.840332,339.597198 920.358215,340.977051 921.570618,342.048309 
	C924.057983,344.246002 925.763977,346.480408 924.552490,350.278229 
	C923.764587,352.748169 926.250549,353.971588 928.210266,354.877106 
	C929.287720,355.375000 931.150513,356.116852 930.659363,356.879059 
	C927.821960,361.282684 931.570312,363.023224 933.594849,365.689392 
	C934.829285,367.315094 933.364441,369.166443 932.599121,370.812256 
	C929.829712,376.768402 930.528503,381.588318 935.312866,386.073212 
	C937.068909,387.719299 936.441833,388.653442 935.537476,390.253204 
	C933.833618,393.267090 933.291443,396.113068 937.399048,397.982941 
	C940.736633,399.502289 940.277649,402.348450 939.428406,405.262604 
	C938.324829,409.049713 939.387146,411.920715 943.464111,412.962280 
	C947.613647,414.022369 947.846680,417.001984 946.408325,419.907715 
	C944.174438,424.420593 945.120911,429.279846 944.126282,433.902466 
	C943.566406,436.504333 944.679504,437.342957 947.135681,435.577118 
	C948.380005,434.682587 950.042786,433.244141 951.441711,435.302582 
	C952.580017,436.977539 951.468811,438.132233 950.288818,439.569977 
	C947.994995,442.364929 945.984680,445.190186 949.236450,449.175385 
	C951.782227,452.295380 949.786926,456.294434 945.691345,456.882019 
	C943.021484,457.265045 941.711365,458.635284 941.289612,460.855469 
	C940.866211,463.084320 942.108215,464.316467 944.368958,465.192230 
	C947.187561,466.284088 945.745239,468.235870 944.646240,469.998962 
	C942.946045,472.726776 941.530212,475.061676 946.438416,476.530884 
	C950.272644,477.678589 951.043274,482.684387 949.113342,488.699280 
	C948.713989,489.943909 947.618469,491.139984 948.341248,492.470764 
	C951.800720,498.840118 948.754883,504.781342 947.006409,510.693115 
	C946.346741,512.923645 946.290344,514.699219 947.819031,516.147949 
	C950.711487,518.888977 950.786865,522.421753 949.544434,525.390747 
	C947.173279,531.057129 949.046692,536.169983 950.805603,541.194336 
	C951.774841,543.963257 951.405029,545.566406 949.332275,547.163269 
	C946.930481,549.013611 946.419861,551.239746 948.393738,553.595703 
	C950.735352,556.390747 950.312256,559.655334 949.759827,562.655090 
	C949.202576,565.681091 947.102478,568.443176 948.629089,571.745300 
	C948.741455,571.988281 948.634583,572.591980 948.467102,572.667480 
	C942.205139,575.489441 947.329529,582.450012 943.510803,586.268677 
	C942.260437,587.519104 943.880188,588.935730 944.510376,590.269836 
	C946.506592,594.495361 947.402039,598.601501 943.398010,602.465576 
	C939.347534,606.374573 938.139771,611.556152 938.038452,616.989624 
	C937.996582,619.233704 937.688416,621.319153 936.285217,623.093079 
	C935.268555,624.378296 934.888489,625.620972 935.555786,627.229370 
	C936.663086,629.898132 935.727844,632.032776 933.651123,633.894226 
	C931.632080,635.704041 931.472290,637.903625 932.802795,640.211487 
	C934.772217,643.627258 934.348877,646.536621 931.928589,649.799927 
	C927.879333,655.259460 925.726562,661.713074 924.187012,668.281250 
	C923.600220,670.784729 922.264893,672.848267 921.201843,675.046753 
	C920.366882,676.773499 919.501221,678.959534 916.378174,676.721924 
	C914.063477,675.063477 911.273865,677.256531 910.494385,679.602112 
	C909.428528,682.809570 911.730713,683.101868 914.294373,682.517090 
	C915.701355,682.196106 915.934082,683.292419 915.964172,684.340271 
	C916.030884,686.664856 916.391235,689.118347 914.890442,691.115601 
	C912.565125,694.210022 911.274048,697.275452 912.760376,701.282288 
	C913.475098,703.209351 907.251587,712.113220 904.896790,712.959412 
	C901.801636,714.071655 900.830627,715.368469 903.968628,717.588501 
	C906.597778,719.448364 905.520691,720.944641 903.785339,722.794434 
	C901.055481,725.704285 897.575562,727.667480 894.940552,730.793579 
	C890.920898,735.562317 891.027039,740.672180 892.001526,746.581665 
	C892.247009,747.942505 891.666321,748.430115 890.631653,749.015625 
	C887.180176,749.748352 884.282959,751.014954 881.146606,751.585571 
	C876.367310,752.455139 872.789551,760.160828 874.934875,764.604187 
	C876.621277,768.097046 875.714966,770.737549 872.754578,772.823303 
	C871.085144,773.999451 869.443542,775.212952 868.791626,777.168518 
	C866.586243,783.784851 861.657471,788.784241 858.432739,794.796631 
	C857.630798,796.291992 857.989136,797.689453 857.768066,799.115906 
	C856.748352,805.695435 851.676941,809.407104 845.158142,807.781982 
	C844.261353,807.026062 843.853455,806.371582 843.434570,805.277344 
	C843.266113,804.393005 843.306824,803.890747 843.638184,803.079590 
	C844.610779,802.067749 845.598022,801.917114 846.578613,801.703979 
	C851.465393,800.641541 851.506165,800.615295 849.696960,795.913757 
	C848.934570,793.932617 849.484375,792.405945 851.413574,792.015137 
	C854.536621,791.382446 854.688538,789.189270 854.856934,786.450378 
	C855.005737,785.885315 855.084778,785.664490 855.338745,785.138000 
	C856.254028,783.908752 857.309082,783.412170 858.284363,782.814819 
	C860.324158,781.565552 860.330444,780.204712 858.851990,778.395203 
	C857.006653,776.136353 854.875977,773.925110 855.411255,770.270996 
	C856.557007,768.537048 858.025146,767.774170 859.877197,766.978943 
	C862.582581,766.569397 863.234558,764.662109 864.118774,762.528320 
	C864.408569,762.049194 864.543518,761.869019 864.931519,761.464966 
	C868.779480,759.962463 867.198120,756.183472 868.885010,753.608032 
	C869.170471,753.123657 869.297913,752.937988 869.647522,752.498901 
	C872.418823,750.507568 871.609436,747.371826 872.991943,744.775146 
	C873.262939,744.268372 873.390259,744.075012 873.762878,743.633728 
	C876.333496,742.136780 878.160278,740.063843 880.708252,739.210938 
	C882.053833,738.760498 882.480408,738.013428 882.352478,736.635437 
	C881.902405,731.785339 884.511292,728.417480 888.126465,725.801514 
	C892.574280,722.583069 894.051208,718.562439 892.517944,713.289246 
	C891.761230,710.686584 892.003662,708.319336 895.560913,707.532166 
	C897.018799,708.222534 897.969421,710.505920 899.400452,708.747314 
	C901.177917,706.562988 898.143921,705.556519 897.507019,703.528809 
	C897.526489,701.489014 898.854858,700.598511 899.801636,699.471313 
	C900.670959,698.436401 901.928040,697.148254 901.599915,696.017944 
	C899.069519,687.301880 907.233337,682.535645 909.114380,675.430847 
	C909.942322,672.303528 911.943787,669.497498 911.809082,665.995911 
	C911.684021,662.745789 913.654785,660.312561 915.666199,658.107666 
	C918.248352,655.276978 919.615051,652.056641 920.143860,648.305054 
	C920.847900,643.309998 922.485535,638.600769 925.154968,634.285828 
	C928.396179,629.046570 930.198608,623.446045 929.512146,617.202515 
	C928.836731,611.059937 930.780945,605.321350 932.453857,599.588989 
	C934.498962,592.580872 935.220276,585.219177 938.548767,578.534241 
	C940.261902,575.093506 938.811279,571.262085 938.181641,567.712830 
	C937.326904,562.895020 937.578796,558.461243 940.385620,554.314331 
	C941.121094,553.227783 941.986633,551.937988 941.309998,550.596741 
	C937.701904,543.444214 940.440857,536.171631 940.874146,528.972778 
	C941.433105,519.686462 941.456055,510.465393 939.301208,501.354614 
	C938.901917,499.666443 938.759888,497.975677 939.748535,496.524933 
	C942.865845,491.950256 941.147949,487.495789 940.159424,482.693298 
	C938.375549,474.026520 935.601074,465.334869 937.656738,456.278961 
	C938.710022,451.638794 938.496704,447.121948 937.518188,442.445923 
	C935.955688,434.979218 936.076721,427.273682 934.664368,419.735687 
	C933.894287,415.625305 932.716431,411.669647 931.701233,407.649872 
	C931.540161,407.011932 931.289368,406.271606 930.784729,405.896729 
	C927.014343,403.096039 926.058167,399.865204 927.886353,395.277679 
	C928.868042,392.814209 927.136902,390.123413 925.365356,388.009033 
	C923.830261,386.176880 922.542908,384.193420 922.735046,381.715515 
	C923.389832,373.271210 918.481201,366.901337 914.727844,360.122803 
	C913.851562,358.540253 912.318298,357.225067 911.998901,355.410828 
	C911.159302,350.641968 908.151855,348.285522 903.331177,347.196075 
	C900.966003,345.831299 899.139343,344.408112 897.756592,342.476654 
	C895.821472,339.773590 895.105713,336.982178 897.766479,334.383881 
	C899.770630,332.426849 899.611328,330.553680 898.703125,328.091583 
	C896.357849,321.733521 893.966492,315.426300 894.378540,308.333710 
	C894.665710,303.391266 891.741028,299.025421 889.285278,295.026886 
	C886.336670,290.225739 884.302795,285.342255 883.478699,279.827362 
	C882.895081,275.922363 881.145142,272.677307 877.958191,270.099182 
	C875.648682,268.230896 873.738647,267.580841 870.787048,268.915680 
	C866.831421,270.704559 863.841919,268.477386 863.913940,264.119751 
	C863.966797,260.920349 864.765747,257.851105 866.978699,255.348419 
	C867.877441,254.332031 869.043701,253.134232 868.438660,251.843735 
	C865.961670,246.560364 866.314270,240.370926 863.028687,235.367645 
	C861.807617,233.508255 860.196228,232.123596 858.288818,231.036896 
	C856.389954,229.954971 854.498962,228.897400 854.328186,226.315186 
	C854.286804,225.689865 854.730408,226.061554 854.261230,225.873215 
	C848.217834,223.447388 849.274109,217.263000 847.138123,212.765778 
	C845.369873,209.042664 842.539917,206.452499 838.007874,207.643356 
	C833.539185,208.817535 830.239807,207.152191 827.117737,203.696411 
	C826.798828,203.158279 826.718994,202.916214 826.819580,202.337067 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M889.748413,748.249573 
	C890.551575,747.826538 891.103210,747.653137 891.828613,747.241089 
	C893.067810,747.001160 894.133240,746.999878 895.598083,746.998047 
	C895.971008,748.154602 896.178467,749.802856 895.043945,750.141785 
	C890.706726,751.437622 889.881836,754.857300 889.091492,758.607422 
	C888.730530,760.319885 887.884155,762.291687 886.212830,763.115479 
	C880.722778,765.821594 880.397949,769.529053 883.724426,774.107849 
	C885.629333,776.729858 885.037231,777.730164 881.792175,778.076233 
	C877.778076,778.504517 876.165955,781.293091 877.648926,785.076050 
	C878.424011,787.053406 879.203491,788.726868 877.150818,790.699585 
	C872.347717,790.734253 871.621399,786.456970 869.767639,783.556213 
	C868.454651,781.501709 870.472595,780.325134 872.255249,779.553772 
	C872.255249,779.553772 876.372375,766.778259 876.372375,766.778259 
	C875.985168,765.665466 875.201538,764.764038 874.728394,763.697449 
	C873.594849,761.141968 874.192749,758.632141 876.634583,757.767700 
	C881.906677,755.901184 885.282715,751.673157 889.748413,748.249573 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M919.000000,298.404938 
	C924.979797,301.200317 925.543762,306.313293 923.998230,312.586853 
	C923.137878,312.899170 921.962341,312.579834 922.039917,311.794220 
	C922.504700,307.089508 918.730042,303.424866 919.000000,298.404938 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M917.051270,296.307739 
	C917.909363,296.079529 918.682739,296.345612 918.957520,297.681091 
	C918.095520,297.917786 917.318115,297.656952 917.051270,296.307739 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M876.037476,250.630798 
	C872.486206,249.050140 868.391541,242.144684 869.653992,238.972946 
	C871.413269,234.552628 867.052917,233.647949 865.689331,231.217072 
	C863.242493,226.855301 862.451843,220.463486 854.932434,220.614197 
	C852.445190,220.664062 852.662842,216.350510 855.434387,213.835861 
	C858.517212,211.038742 858.355957,209.469727 854.421082,208.399948 
	C851.968018,207.733017 850.158569,207.027924 849.466309,204.337830 
	C849.259277,203.533463 848.218384,202.702850 847.365906,202.343140 
	C839.855164,199.174240 832.300781,196.108963 824.379639,193.005905 
	C823.751526,189.669907 820.545044,189.708786 818.313232,188.138519 
	C818.679504,187.069000 819.745422,186.746567 820.658630,187.133774 
	C826.475098,189.599762 829.550842,185.823212 832.588257,181.995056 
	C833.930176,180.303726 835.073486,178.482361 837.434265,177.995651 
	C839.291748,177.612671 841.144104,176.164093 842.756287,178.555267 
	C844.256592,180.780396 843.584900,182.595535 841.986755,184.353714 
	C841.471436,184.920654 840.428162,185.104858 840.678528,186.624939 
	C842.824219,186.419327 845.002380,186.210602 847.587830,186.002472 
	C848.810608,190.482300 851.461182,192.997055 855.781250,194.571960 
	C859.607849,195.966980 859.740723,200.002228 859.960510,203.450272 
	C860.483704,211.658264 866.179810,215.623779 873.734985,212.795883 
	C874.590881,212.475555 875.346252,212.122879 875.869019,213.020691 
	C876.678833,214.411530 878.165710,215.870026 877.112305,217.554535 
	C875.073975,220.814117 877.356140,225.443130 873.633240,228.108475 
	C873.426697,228.256348 873.280090,228.921722 873.374084,228.993225 
	C876.829651,231.621872 876.742432,237.356857 880.982422,238.810257 
	C884.626404,240.059357 884.353149,242.852325 884.468079,245.107544 
	C884.580933,247.321320 882.205688,248.055710 880.011536,248.003281 
	C878.259155,247.961411 876.646912,248.194565 876.037476,250.630798 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M787.767212,141.259216 
	C789.299622,141.187912 790.868225,140.978531 791.848877,141.640350 
	C797.245361,145.282349 802.225342,143.603668 806.899719,140.463272 
	C809.008484,139.046494 810.052979,138.844727 811.705505,141.021225 
	C815.859192,146.492188 822.164551,148.420563 828.514099,143.671722 
	C831.027344,141.792023 833.393127,140.707474 836.739807,141.813461 
	C838.818237,142.500320 841.274719,142.160889 843.563843,141.248489 
	C848.971252,139.093140 849.912048,139.807083 849.998840,145.635010 
	C850.011230,146.466400 850.006165,147.298050 850.021240,148.571259 
	C850.027039,149.754593 850.020752,150.496246 850.015076,151.608383 
	C850.285950,152.545303 850.425598,153.468933 850.846802,153.623047 
	C855.156006,155.199890 854.399353,157.053329 851.339355,159.272186 
	C850.742493,159.705017 850.490356,160.613281 850.034729,161.653900 
	C847.861877,161.086945 848.240051,159.091629 847.859070,157.337387 
	C846.925720,153.039108 845.057861,152.369553 841.664001,154.882889 
	C840.376099,155.836655 839.062500,158.435883 837.834778,157.467789 
	C835.022766,155.250290 831.176575,157.278748 828.665527,154.497299 
	C826.330139,151.910370 823.493774,152.720810 820.249023,152.757660 
	C815.681213,152.809509 811.963867,158.010239 807.075684,154.585434 
	C806.159363,153.943451 805.516479,155.412613 804.889221,156.156815 
	C802.281006,159.251526 800.334106,157.987656 798.917542,155.048691 
	C795.989502,148.973969 794.200562,148.269333 787.361206,150.960220 
	C786.683411,150.888596 786.426331,150.697464 786.113159,150.214447 
	C783.865906,146.696014 787.599304,144.453140 787.767212,141.259216 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M840.323547,169.857513 
	C838.659851,170.002167 838.005127,168.934113 837.159790,168.171280 
	C834.947510,166.174667 832.696655,165.320312 830.261108,167.841751 
	C830.043152,168.067383 829.687317,168.177734 829.377563,168.293854 
	C826.883972,169.228806 824.401245,172.815414 821.881165,170.320129 
	C819.330811,167.794754 822.523865,165.746521 824.326172,163.898331 
	C824.939392,163.269501 825.079407,162.327408 824.419556,161.650711 
	C823.498108,160.705765 822.221985,160.868423 821.127075,161.192230 
	C819.188416,161.765625 818.493530,163.294159 817.896179,165.198807 
	C816.682007,169.070526 813.890686,169.783752 810.519043,167.128311 
	C808.815063,165.786270 807.608459,166.036148 806.170898,167.323502 
	C804.424805,168.887100 802.414185,170.792465 800.036804,169.584244 
	C797.789307,168.441956 800.440552,166.313812 799.932007,164.626633 
	C799.645325,163.675568 799.374451,162.719742 799.048218,161.383026 
	C801.397644,161.174362 803.681824,160.658203 805.624207,159.305771 
	C808.572876,157.252670 811.553894,157.448807 814.450134,159.053497 
	C815.959839,159.889999 817.396667,160.222412 818.575378,159.126450 
	C821.560547,156.350845 824.367798,156.542114 828.240845,157.699051 
	C831.508484,158.675125 834.234497,158.482925 834.275085,162.934464 
	C834.291321,164.720642 835.975647,165.255676 837.567566,163.880432 
	C838.195435,163.338089 838.731506,162.683334 839.386047,162.179276 
	C841.711670,160.388031 844.210632,160.293152 845.957336,162.670074 
	C847.828369,165.216248 845.529968,166.929459 843.834351,168.398163 
	C843.010193,169.112122 841.724060,169.292877 840.323547,169.857513 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M883.710693,201.148346 
	C884.315613,201.790085 884.631226,202.581406 884.973389,203.693222 
	C884.100769,203.921570 882.991577,204.264709 882.482178,203.224136 
	C882.044678,202.330444 882.834534,201.812592 883.710693,201.148346 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M842.710938,173.540436 
	C841.481995,174.309830 840.190247,174.857330 839.972900,172.389130 
	C841.175293,171.787613 842.292297,171.692276 842.710938,173.540436 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M760.055115,934.408264 
	C762.450989,933.979187 766.334167,931.600647 764.987854,937.590942 
	C763.080139,937.223206 760.436340,937.745728 760.055115,934.408264 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M724.011353,933.393433 
	C724.756775,933.007385 725.499023,933.000244 726.612183,932.987488 
	C728.062866,934.426697 727.624695,935.895081 727.009766,937.700500 
	C724.310608,937.774658 723.907715,935.948242 724.011353,933.393433 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M776.348267,922.944946 
	C776.026184,921.027771 776.000122,919.110107 775.278564,917.218506 
	C774.898743,916.222473 774.604980,914.609009 776.211182,914.522339 
	C777.582275,914.448425 777.968750,915.727295 777.491150,917.038269 
	C777.149902,917.974854 777.443665,918.592773 778.706787,918.963318 
	C779.011597,920.768433 778.834717,922.422607 776.348267,922.944946 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M782.055847,919.287354 
	C783.174438,917.560852 784.789734,918.135071 786.630920,918.008301 
	C785.672974,919.453979 784.621338,921.802673 782.055847,919.287354 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M791.893005,904.523315 
	C789.658997,905.607727 788.037354,906.430847 788.526367,909.432556 
	C788.934998,911.940735 786.213074,913.210510 785.035339,915.126099 
	C784.517395,915.968506 783.154419,915.606201 783.221191,914.669495 
	C783.609619,909.220947 779.400330,912.474365 777.224670,911.235596 
	C777.271118,908.712585 778.582764,907.404785 780.769958,907.030884 
	C784.616699,906.373230 785.877930,903.580627 785.914978,900.265930 
	C785.948120,897.303345 786.868469,894.813599 788.714355,892.215576 
	C789.544556,892.162231 790.094055,892.331848 790.818481,892.735352 
	C789.380676,895.661316 788.299683,898.320679 791.782043,900.868896 
	C792.038574,902.058167 791.983215,903.097046 791.893005,904.523315 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M893.339844,843.973328 
	C892.938904,842.403198 893.130859,840.990234 895.623535,840.999573 
	C895.397949,842.116943 895.084717,843.465576 893.339844,843.973328 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M929.685913,692.153015 
	C930.065613,693.365784 929.752625,694.585083 928.876831,695.714478 
	C927.577881,697.389832 927.904358,698.976196 929.837219,699.762756 
	C933.015076,701.055969 932.435364,703.031067 930.641357,704.722168 
	C927.949341,707.259766 927.786621,711.746582 923.551147,713.178833 
	C921.903503,713.736084 921.785583,715.431702 924.101624,716.205933 
	C925.204834,716.574707 927.061035,716.829590 926.367432,718.752625 
	C925.900391,720.047607 924.690247,721.018494 923.268555,720.896484 
	C920.768433,720.681763 918.792419,721.816895 916.378418,722.952759 
	C916.120117,721.854431 915.798401,720.105774 916.419800,719.653687 
	C921.292786,716.108765 920.004578,708.953674 924.908020,705.492798 
	C925.747070,704.900635 926.155823,703.819092 925.749084,702.802979 
	C923.918030,698.228882 927.054749,695.422180 929.685913,692.153015 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M936.618591,680.020020 
	C938.219482,682.305298 940.519714,684.288757 939.055420,687.457581 
	C937.567017,690.678589 935.001831,691.399658 931.400635,690.995361 
	C931.139709,688.914917 932.949036,689.284302 934.195984,688.714294 
	C935.679932,688.036011 936.804993,686.588867 935.090759,685.367493 
	C930.950623,682.417542 934.167847,681.444824 936.618591,680.020020 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M938.630005,664.064270 
	C937.764648,667.237427 941.902222,670.629822 938.371704,673.917969 
	C934.509521,670.619263 936.910889,667.396973 938.630005,664.064270 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M937.731323,675.132812 
	C937.832764,675.861755 937.665588,676.723450 937.239014,677.796265 
	C935.520081,676.807983 936.533142,676.044189 937.731323,675.132812 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M785.256592,876.888489 
	C784.999146,875.609802 784.999573,874.222351 785.000000,872.418640 
	C786.591858,871.962708 788.003235,872.161194 787.984863,874.626099 
	C787.678711,875.108521 787.422119,875.299561 787.110229,875.782593 
	C786.503174,876.258240 786.008789,876.518982 785.256592,876.888489 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M857.380371,845.995483 
	C858.964355,843.102417 857.357300,840.640015 856.073242,838.119995 
	C855.479492,836.954834 854.585571,835.603149 856.194885,834.637756 
	C857.767151,833.694580 858.807556,835.018311 859.358887,836.130920 
	C860.314941,838.060181 861.228149,840.027527 863.684509,838.454895 
	C865.823914,837.085266 867.878357,834.798096 866.444275,832.244446 
	C863.585266,827.153198 866.329956,824.262817 870.337952,823.086121 
	C874.366699,821.903259 877.630371,820.211975 880.624146,817.037537 
	C881.579224,816.990723 882.125366,817.134460 882.738647,817.799255 
	C882.909668,818.033752 882.956055,818.528320 882.970825,818.776489 
	C881.968384,822.108337 880.085938,824.820007 879.607239,828.190002 
	C879.382324,829.773438 877.918213,831.455994 876.575684,832.557312 
	C871.053101,837.087830 865.375916,841.431335 859.704041,845.776367 
	C859.261963,846.115112 858.422363,845.934814 857.380371,845.995483 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M698.011597,78.414032 
	C703.392517,77.099686 704.337036,79.744225 703.004944,84.613304 
	C702.840637,85.011452 702.673889,85.016289 702.257019,85.026634 
	C700.137390,85.157921 699.114380,84.075211 698.208496,82.262177 
	C697.998840,80.939850 698.002441,79.876175 698.011597,78.414032 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M698.001099,82.365646 
	C699.212646,82.839485 700.430176,83.675446 701.827271,84.771774 
	C701.847595,85.849434 701.644836,86.915970 700.582642,86.544403 
	C698.893005,85.953384 698.008118,84.580040 698.001099,82.365646 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M545.565552,88.001564 
	C546.470276,90.784340 548.808594,89.556244 550.362549,90.092506 
	C556.690857,92.276428 562.942566,92.987030 569.796143,92.294678 
	C576.057800,91.662132 582.559570,93.502113 588.964417,94.166695 
	C597.887451,95.092598 606.260376,97.687500 614.446716,101.388626 
	C617.375732,102.712875 621.122314,103.083542 624.592957,101.992104 
	C625.818420,101.606735 627.444946,100.547134 628.318848,101.802086 
	C632.256226,107.456497 639.656311,106.729500 644.455444,110.839928 
	C646.561279,112.643555 649.300720,113.438622 652.374023,112.438713 
	C655.296936,111.487747 657.054016,114.583961 659.689758,115.363182 
	C665.174744,116.984787 671.369202,117.809250 675.841919,122.690948 
	C673.179871,125.355598 670.754639,121.851959 668.262146,122.265862 
	C667.800415,122.701904 667.666504,122.993690 667.702026,123.334282 
	C667.910950,125.337967 670.055115,127.518112 667.672913,129.293686 
	C665.348694,131.026031 662.691956,130.158936 660.642578,128.731857 
	C656.242859,125.668106 651.302490,123.013611 646.535278,121.497917 
	C641.007141,119.740288 636.792419,113.755615 629.938477,115.904289 
	C628.616333,116.318779 627.014648,115.404892 625.624329,114.852943 
	C617.106995,111.471725 608.263306,108.673393 599.266418,107.795212 
	C588.905151,106.783859 579.486450,102.547836 569.387451,100.690529 
	C564.359253,99.765808 558.649170,98.300041 553.954712,95.064171 
	C551.319214,93.247543 548.373352,92.898720 546.032043,96.064072 
	C544.320618,98.377899 542.667725,97.613663 541.243835,95.647728 
	C540.471558,94.581436 539.991760,92.928917 538.574280,92.851242 
	C531.404907,92.458374 525.618652,87.196014 519.121765,86.385063 
	C510.729401,85.337509 501.445129,84.803268 494.131439,91.658348 
	C489.563934,95.939484 483.394165,94.400955 477.910889,95.187881 
	C475.932251,95.471848 474.033813,94.890831 473.017029,97.363953 
	C472.478119,98.674820 470.991058,99.096069 469.586243,98.924103 
	C462.778259,98.090706 456.014709,99.583488 449.279266,99.895706 
	C441.882050,100.238586 434.197266,102.275085 427.446930,106.192657 
	C426.295319,106.861023 425.140594,108.049156 423.855377,107.740746 
	C418.615601,106.483376 414.524902,109.350906 410.167603,111.258125 
	C406.214386,112.988449 401.984100,111.348747 397.915009,112.392380 
	C396.380615,112.785919 394.699310,112.400871 393.456573,113.821526 
	C392.891449,114.467567 392.057983,114.814720 391.197876,114.644920 
	C380.708771,112.574150 372.311859,116.993172 364.501434,123.224144 
	C363.424530,124.083290 362.336670,124.807289 360.878784,124.475380 
	C354.384918,122.996918 349.774628,126.234764 345.683777,130.624329 
	C343.377197,133.099304 340.527527,134.465408 337.258698,134.317047 
	C328.507812,133.919830 321.494598,138.427124 314.817291,142.852341 
	C305.975983,148.711716 298.155579,156.103790 287.973480,159.890060 
	C285.529816,160.798767 284.288513,162.735397 285.146240,165.484695 
	C285.868805,167.800842 288.131348,170.180450 286.364807,172.502457 
	C284.275299,175.249008 281.274872,177.217422 278.211609,178.916946 
	C277.488342,179.318253 276.650604,179.514374 275.838684,179.680862 
	C274.001099,180.057709 271.841827,181.717621 270.455780,179.302399 
	C269.071930,176.891037 269.648041,174.461105 271.755981,172.531830 
	C273.089508,171.311325 274.888000,170.594955 275.971069,168.460526 
	C274.220825,168.147614 272.904053,168.852249 272.056183,169.878159 
	C265.383881,177.951523 256.571655,181.283447 246.406158,181.455917 
	C243.050049,181.512863 240.928223,184.687607 237.290588,184.228607 
	C239.915604,177.053482 242.631088,175.367645 248.866333,176.879227 
	C249.849258,176.185028 250.603973,175.728027 251.719772,175.210175 
	C252.907532,173.058426 253.858032,171.154160 255.881790,170.020737 
	C256.562958,169.639206 257.348206,169.034668 257.597443,168.353363 
	C260.372040,160.769119 260.368927,160.568390 268.942932,161.589447 
	C273.498993,162.132019 276.512848,160.301849 279.042999,156.925919 
	C281.322113,153.884949 284.110626,151.574188 288.159943,152.041702 
	C292.584747,152.552536 293.837402,148.245041 296.937622,146.348740 
	C298.167419,144.613083 299.908203,144.094666 301.675354,143.062531 
	C305.266815,140.171768 309.905396,139.000626 311.991516,134.368561 
	C312.249481,133.686203 312.562134,133.457626 313.221924,133.242905 
	C314.314728,133.024719 314.938080,132.592377 315.628601,131.678421 
	C317.180969,130.128555 318.681152,129.642319 320.724854,130.751617 
	C325.259216,132.303192 329.024811,129.787231 332.594604,128.601349 
	C338.830139,126.529915 345.379669,124.693222 351.056854,120.058716 
	C351.904510,118.593025 352.655975,117.851601 353.619812,116.879974 
	C354.092590,116.656578 354.303162,116.618492 354.756897,116.790054 
	C360.093079,116.395584 362.870056,111.702637 367.361938,109.973228 
	C368.670715,109.469337 368.837585,108.056389 368.382019,106.531715 
	C367.594696,103.896942 369.759888,103.424721 371.516907,103.142601 
	C373.565674,102.813637 374.022491,103.959358 373.608154,105.905121 
	C373.011688,108.706055 375.387054,108.814522 377.053528,108.876534 
	C378.824005,108.942421 380.944733,108.828056 382.348785,107.920380 
	C388.646240,103.849236 397.257782,106.516876 402.938141,100.352142 
	C403.425507,99.525879 403.774323,98.987099 404.454224,98.306641 
	C405.501495,97.342644 406.057129,96.373779 407.144958,95.430519 
	C408.773102,94.644234 410.178741,94.681198 411.938232,94.835861 
	C414.442963,95.469666 416.250549,94.758987 418.184448,93.179527 
	C424.223511,91.019035 429.899231,88.824440 436.440918,88.760963 
	C438.593445,89.293762 440.354553,89.232101 442.398621,88.360527 
	C442.946411,88.169678 443.170044,88.100677 443.739166,87.946762 
	C444.320282,87.816711 444.555939,87.771568 445.153198,87.680283 
	C445.759918,87.615471 446.005035,87.596779 446.620605,87.549393 
	C450.195129,86.563934 453.488342,86.532288 456.786682,86.603554 
	C459.135345,86.654312 461.415161,86.523788 463.560791,84.536407 
	C465.384308,83.655602 466.945831,83.601181 468.910645,83.852318 
	C471.335632,84.494629 473.251068,85.755455 475.892059,85.073090 
	C476.973450,84.870422 477.703857,84.835457 478.798187,84.868698 
	C482.498260,85.826698 485.361908,85.230057 487.638824,82.418686 
	C489.346497,80.310104 491.627075,79.570580 494.302917,79.915779 
	C498.465820,80.452812 502.459045,78.580589 506.592743,79.271889 
	C506.864807,77.963303 505.894379,77.740456 505.363037,77.245705 
	C504.095703,76.065491 502.936798,74.803635 503.792419,72.943779 
	C504.581329,71.228966 506.259796,70.906960 507.955017,70.682014 
	C509.633331,70.459305 511.246643,70.972382 512.905579,71.005707 
	C518.379333,71.115662 519.225464,72.192879 518.149414,77.650345 
	C520.122375,75.509254 522.446289,74.587982 525.234314,74.652481 
	C530.026550,74.763321 531.505005,76.142525 531.839722,81.090424 
	C531.897705,81.948792 532.266235,82.679199 532.792725,83.719284 
	C536.818420,86.016174 540.217590,89.304184 545.565552,88.001564 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M476.224457,85.749252 
	C473.965363,88.004349 472.210541,86.580780 470.212067,85.271225 
	C468.702728,82.199066 471.556244,81.306145 472.753418,79.269821 
	C470.634735,75.906296 471.657806,72.348434 476.255493,71.214272 
	C484.214203,69.250999 492.318726,68.033150 500.678650,68.591965 
	C506.125671,68.956085 511.499146,70.139519 517.258667,68.462906 
	C520.996521,67.374840 525.765564,68.959473 529.838501,70.148262 
	C533.566589,71.236389 537.089294,71.564102 540.908508,71.098305 
	C543.182251,70.820984 546.800842,69.829384 545.448242,74.806274 
	C545.019653,76.383240 546.679138,77.413315 548.202576,77.821121 
	C549.759155,78.237816 550.620911,77.486099 551.162537,75.967987 
	C552.889832,71.127083 556.881165,73.309448 560.089050,73.306396 
	C561.109009,73.305428 561.806213,74.347534 561.813721,75.445587 
	C561.822876,76.783775 560.997559,77.537437 559.732178,77.874863 
	C553.823303,79.450546 548.331909,82.793068 541.646118,79.367607 
	C538.763062,77.890472 534.216492,78.198860 533.007690,83.614555 
	C531.791077,83.370445 530.198181,82.442726 530.438049,81.227753 
	C530.967590,78.544769 530.862000,76.454872 527.706116,76.171875 
	C524.828247,75.913795 521.643372,75.074753 519.507446,78.171501 
	C518.605591,79.479004 517.751648,81.816322 515.889893,80.508560 
	C513.851807,79.076935 516.063660,77.685555 516.991211,76.479019 
	C517.905029,75.290382 518.240295,74.127205 517.160095,72.904694 
	C516.097168,71.701721 506.225159,71.494659 505.105225,72.724403 
	C503.284698,74.723396 505.138336,75.694992 506.462402,76.552025 
	C507.801697,77.418869 510.775543,76.890945 509.629791,79.743446 
	C508.755280,81.920631 506.469543,80.987175 504.769501,80.737511 
	C500.382843,80.093315 496.160248,82.649391 491.669983,81.164108 
	C490.095856,80.643425 489.402252,82.719414 488.424347,83.811890 
	C486.077209,86.434090 483.377533,88.169212 479.351196,86.154709 
	C477.547821,84.988853 478.918488,83.102486 477.776672,81.937607 
	C476.336975,82.796501 477.198181,84.449570 476.224457,85.749252 
z"
    />
    <path
      fill="#F5F4F3"
      opacity="1.000000"
      stroke="none"
      d="
M472.572998,79.000305 
	C473.864563,81.749748 471.520844,82.762505 470.067322,84.645889 
	C468.285645,84.998947 466.571289,84.999718 464.428467,85.000549 
	C458.596649,88.294693 452.018768,85.757401 446.254272,88.761314 
	C445.999481,88.999084 445.499756,88.999641 445.249878,88.999817 
	C444.833344,88.865585 444.666656,88.731163 444.250000,88.798370 
	C443.833344,88.999855 443.666656,88.999702 443.250000,88.999237 
	C441.487244,88.980881 439.964966,88.909981 438.307190,89.894043 
	C431.215240,89.484390 425.215515,92.752632 418.397766,93.993866 
	C416.866608,95.361725 415.110809,95.216370 413.326782,95.896568 
	C411.285645,95.999222 409.571289,96.000664 407.428467,96.002426 
	C406.338745,95.334579 406.250824,94.549416 406.764008,93.344879 
	C411.200439,90.398384 416.479706,89.781914 419.952057,85.778084 
	C421.008575,84.559807 422.958954,84.563789 424.182556,85.295029 
	C428.230957,87.714363 431.868896,85.727440 435.602783,84.622253 
	C436.869354,84.247360 438.193512,82.997581 439.269409,83.220184 
	C446.625793,84.742210 453.308136,80.225777 460.510712,81.029793 
	C464.628632,81.489471 468.000854,78.108772 472.572998,79.000305 
z"
    />
    <path
      fill="#F5F4F3"
      opacity="1.000000"
      stroke="none"
      d="
M398.213043,100.782852 
	C395.675018,97.812477 391.628052,101.277641 389.072479,98.352905 
	C393.435303,95.507088 397.978943,93.321533 403.589966,93.000114 
	C404.997528,94.088539 405.302673,95.359245 404.862823,97.175461 
	C404.302368,98.347481 403.610260,98.677177 402.373535,98.856209 
	C401.999969,98.999695 401.500000,98.999870 401.250000,98.999939 
	C400.357117,99.874718 399.223450,99.943314 398.213043,100.782852 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M404.067017,98.249023 
	C403.999176,96.610741 403.999603,95.222000 404.000000,93.416626 
	C404.750000,93.000000 405.500000,93.000000 406.625000,93.000000 
	C407.000000,93.750648 407.000000,94.501289 407.000000,95.627342 
	C406.939575,97.136086 407.160736,98.398338 405.340851,98.941246 
	C404.647980,98.942986 404.362793,98.770462 404.067017,98.249023 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M236.876923,184.264130 
	C243.344574,178.047897 252.344498,181.336349 259.460968,177.550049 
	C262.188507,176.098892 263.801697,171.810196 268.211304,173.455780 
	C268.941254,173.728195 268.875793,171.468872 269.341583,170.434464 
	C270.789673,167.218903 274.061951,167.135422 276.769836,166.134125 
	C277.230652,165.963760 278.163330,166.338501 278.512207,166.762421 
	C279.080475,167.452911 278.968872,168.504364 278.227844,168.990555 
	C276.434021,170.167496 275.628326,172.440872 273.339294,173.083572 
	C270.452545,173.894058 270.508057,176.476822 271.236053,178.399704 
	C272.367584,181.388519 273.777985,177.937424 275.145264,178.141144 
	C279.898865,178.849487 281.287354,173.596634 285.165436,172.423111 
	C286.897705,171.898895 285.613525,169.637070 284.756683,168.128403 
	C281.610870,162.589371 282.462006,160.170624 288.534851,158.022446 
	C298.384644,154.538239 305.590973,147.058884 314.103973,141.562943 
	C320.858704,137.202133 327.725281,132.571777 336.469849,132.955750 
	C340.407257,133.128632 343.798279,132.192139 345.398743,127.743179 
	C346.276581,125.302963 357.553864,121.624954 359.985931,122.719948 
	C361.272949,123.299393 361.937103,123.862167 363.331543,122.534485 
	C368.339111,117.766724 373.986115,114.166283 381.264465,113.854118 
	C382.607819,113.796494 383.916229,112.617981 385.215179,111.909882 
	C386.813660,111.038528 388.316040,110.658569 389.638031,112.379967 
	C391.399353,114.673447 392.495636,114.295807 393.144653,111.555267 
	C393.269684,111.027504 394.304443,110.210052 394.338074,110.237549 
	C397.594574,112.899170 400.969849,108.867508 404.286377,110.485275 
	C408.120941,112.355721 410.772827,108.882240 413.978363,107.938477 
	C416.954956,107.062096 419.508759,103.864647 423.018463,106.751106 
	C423.338776,107.014526 424.472168,106.785133 424.851318,106.406952 
	C430.808929,100.464478 439.551483,101.848801 446.404419,98.218323 
	C447.719177,97.521812 449.749146,97.733330 451.333679,98.069778 
	C456.366577,99.138405 461.134644,98.048355 465.901337,96.708939 
	C466.561310,96.523483 467.090668,96.372299 467.756226,96.771164 
	C473.087006,99.966042 472.504913,96.111908 472.205719,92.099197 
	C477.995270,95.978539 483.621552,92.368652 489.242767,92.887413 
	C490.743317,93.025894 492.031281,92.063080 493.054413,90.825775 
	C496.729492,86.381310 501.762573,84.840553 507.217590,85.081085 
	C512.550232,85.316223 518.236145,82.026863 523.178467,85.977547 
	C528.021301,89.848763 533.626099,90.657280 539.444763,91.116638 
	C539.913757,91.153671 540.693909,91.367363 540.770508,91.650246 
	C542.378296,97.592010 544.233398,95.130020 547.566101,92.612175 
	C550.228088,90.601089 553.921814,92.692383 556.641541,94.792892 
	C557.225891,95.244217 557.399048,96.956390 558.065369,96.515419 
	C563.605530,92.848717 565.863892,100.554672 570.351257,99.944885 
	C575.169556,99.290138 579.790710,101.012093 583.716675,102.733231 
	C590.707520,105.798035 598.297485,104.644714 605.073914,107.189598 
	C610.802429,109.340927 617.050171,109.375320 622.544983,112.202316 
	C624.299622,113.105034 626.257751,113.708176 628.191040,114.148430 
	C632.815674,115.201530 638.936157,111.912781 641.496338,118.835785 
	C648.977112,118.570930 653.795288,124.351143 659.998962,126.985657 
	C661.524536,127.633514 663.005310,128.447311 664.590515,128.872650 
	C665.626648,129.150681 667.011353,129.339264 667.648560,127.966408 
	C668.149353,126.887299 667.445374,126.065201 666.773682,125.311157 
	C665.651184,124.051018 664.229492,122.717987 665.775879,121.025887 
	C667.295410,119.363228 669.045044,119.923767 670.689697,121.137222 
	C672.036987,122.131279 673.483521,122.962479 675.620239,122.993179 
	C676.884033,123.500648 677.768127,124.001297 678.826050,124.750977 
	C682.236633,126.712799 685.005005,129.218063 688.723877,130.790924 
	C690.415466,130.963654 691.789246,131.037201 692.838257,132.696991 
	C700.785156,138.813263 710.443970,140.710037 718.775574,145.299698 
	C724.990173,148.723160 732.174988,150.390060 737.733643,155.742813 
	C742.322876,159.206879 746.196594,163.275909 752.190796,163.248718 
	C752.811707,163.245895 753.526917,163.605499 754.005859,163.995636 
	C758.171692,167.389069 763.547180,168.827255 767.786072,172.716400 
	C770.762695,178.422058 776.112244,178.953094 781.599487,179.990448 
	C784.931152,179.723831 787.333740,180.439865 788.752930,183.732819 
	C790.162537,186.956787 791.393921,189.841858 795.623047,189.998840 
	C802.029602,186.490631 802.029602,186.490631 805.799927,192.709869 
	C806.061462,194.133453 805.837769,195.397308 807.659058,195.940918 
	C809.573730,197.207703 810.734619,198.737747 811.772583,200.770645 
	C812.908081,202.144760 813.816162,203.289505 814.862183,204.717117 
	C816.248901,206.240891 817.840271,206.964355 819.302734,207.916718 
	C819.687500,207.116302 819.233337,206.289642 819.778076,205.271332 
	C822.191833,203.420059 823.458679,204.766998 824.287842,206.564621 
	C825.565247,209.334290 827.203857,211.696136 829.613892,213.659653 
	C832.322083,215.866165 832.136658,218.184708 828.978210,219.969421 
	C825.931763,221.690857 824.145020,223.489105 827.577759,226.698959 
	C828.652161,227.703613 828.324646,229.555542 828.000427,231.034836 
	C827.604309,232.842331 827.986877,234.280182 829.471985,235.466354 
	C831.759155,237.293015 832.287048,239.450806 830.101135,241.643372 
	C831.054077,242.419647 832.002869,242.443710 832.261047,241.992401 
	C835.262939,236.744339 840.580566,237.266876 845.402039,236.687881 
	C847.708252,236.410934 850.047058,236.368530 852.364380,236.083160 
	C854.767456,235.787216 856.715271,236.347092 857.108032,239.564117 
	C857.030579,240.689697 856.766785,241.380203 856.077026,242.284546 
	C853.800903,243.586502 852.658081,245.709900 850.265259,246.832932 
	C848.393127,247.243256 846.929504,246.918427 845.099731,246.546204 
	C843.887756,246.499161 843.094055,246.677032 842.321289,247.033737 
	C839.996277,248.106857 836.787415,248.388702 836.947876,251.943924 
	C837.098938,255.289886 840.268616,255.021042 842.409973,256.014343 
	C848.183350,258.692505 851.417725,263.942108 853.859436,269.261841 
	C855.485352,272.804169 857.046936,273.894257 860.524475,272.973022 
	C861.809814,272.632538 863.160278,272.673248 864.492004,272.626587 
	C868.360840,272.491089 870.185181,274.180786 870.477356,278.192230 
	C870.893494,283.905212 873.138184,289.231445 875.056396,294.906067 
	C876.341064,295.965759 877.148499,296.953094 878.079956,298.333252 
	C882.797485,304.458496 886.159241,310.636658 884.851135,318.384430 
	C884.683838,319.375244 884.627319,320.533569 885.201172,321.315765 
	C888.636902,325.998383 889.145691,331.404846 889.382141,336.933746 
	C889.477844,339.170563 890.777222,340.972900 892.000488,342.787964 
	C893.885986,345.585754 894.968933,348.401123 894.497803,352.108368 
	C893.948059,356.434204 897.403259,359.673126 899.318237,363.302460 
	C903.316223,370.879517 909.665710,377.273651 910.769409,386.322998 
	C910.992004,388.147339 911.852539,390.172577 910.915405,391.700806 
	C908.453308,395.715790 909.949158,399.020325 912.200684,402.295654 
	C914.187378,405.185760 914.490356,408.057831 912.734436,411.187988 
	C911.083252,414.131531 911.337158,416.927551 912.614868,420.208069 
	C915.528931,427.689575 917.260132,435.490295 915.035217,443.583313 
	C914.378967,445.970612 915.072449,448.161682 915.644653,450.439667 
	C917.037354,455.983673 917.006470,461.341675 913.004395,465.989471 
	C917.578430,467.991394 919.014954,470.312714 918.414246,475.124908 
	C918.226624,476.627502 917.691650,478.273285 918.585205,479.516663 
	C921.686768,483.832489 920.789124,488.254974 919.503052,492.856415 
	C918.487671,496.489410 918.754700,500.110107 920.639160,503.516815 
	C923.302673,508.332001 920.051025,512.914307 919.911987,517.631226 
	C919.810303,521.083618 918.657104,524.477722 919.698120,528.001282 
	C920.272461,529.945557 919.482178,531.648804 917.890564,532.849426 
	C915.776123,534.444214 915.442932,535.781738 918.015259,537.296936 
	C921.161255,539.150024 921.534302,542.011108 920.217590,545.115906 
	C917.956543,550.447632 917.979858,556.096375 917.243286,561.702454 
	C916.110229,570.326599 915.705566,578.729919 916.676697,587.436951 
	C917.201660,592.142944 915.009033,597.266663 911.988342,601.367432 
	C910.302795,603.655640 909.743530,605.603943 911.215942,608.177795 
	C912.606140,610.607971 913.122681,613.578735 911.416138,615.805664 
	C907.109253,621.425964 906.888306,628.247070 905.466858,634.647522 
	C903.909729,641.658813 901.748657,648.453247 899.521484,655.641479 
	C899.331177,656.495178 899.187378,656.969910 898.831665,657.756470 
	C895.919800,660.776917 894.356689,663.898926 894.898499,667.886536 
	C895.295227,670.805603 893.285767,673.730225 895.595581,676.804565 
	C896.635864,678.189087 895.028931,680.571655 893.984436,682.297119 
	C889.253296,690.112671 885.313660,698.300476 882.544739,707.016968 
	C881.557373,710.125122 879.875244,712.710510 877.481628,714.874451 
	C876.105103,716.118896 874.762573,717.386475 874.998352,719.490417 
	C875.315552,722.320557 873.972900,723.807373 871.273621,724.431152 
	C869.395325,724.865295 868.029846,726.050781 867.363037,728.310791 
	C867.221924,728.865723 867.152466,729.083252 866.945312,729.612061 
	C866.046692,731.273987 864.923828,732.356934 863.881714,733.894043 
	C863.193542,734.830505 862.860657,735.573181 862.803223,736.760986 
	C861.931152,739.728394 861.391418,742.514954 858.897644,744.684937 
	C857.419189,745.638123 855.349365,745.832703 855.560181,747.646057 
	C855.852417,750.160217 858.803406,749.392639 860.405762,750.975342 
	C862.390747,756.111572 859.570679,760.263489 858.448853,764.656372 
	C858.119507,765.946106 856.606934,766.450562 855.343079,766.941528 
	C849.134399,769.353760 847.744568,772.877319 850.535645,779.124756 
	C850.939941,780.029724 851.554016,780.852417 851.689697,781.859131 
	C851.938477,783.704956 851.147888,785.103760 849.635925,786.071289 
	C848.033875,787.096375 846.175659,787.134033 845.205017,785.513000 
	C842.581726,781.131897 840.591431,783.651123 838.515198,785.888733 
	C837.298462,787.200073 835.984802,788.417480 834.519775,789.771057 
	C836.267822,790.182800 837.697815,789.516113 839.163147,789.692383 
	C842.055847,790.040405 844.643921,791.123474 844.644409,794.338562 
	C844.645020,797.890808 841.783386,798.904724 838.749939,799.034119 
	C836.595947,799.125916 834.517883,798.317261 832.382507,798.046082 
	C830.601807,797.820007 828.822693,797.093933 826.769958,798.372803 
	C826.026917,798.792236 825.581787,798.990662 824.777466,799.268555 
	C820.115479,804.523743 815.198669,808.571594 808.028870,808.855042 
	C807.153015,808.889709 806.589539,810.098938 805.981506,811.328430 
	C805.283386,812.235107 804.669312,812.665100 803.609741,813.101929 
	C796.481323,814.098206 792.906982,817.992798 792.535278,824.758057 
	C792.316772,828.735718 790.280457,832.052429 788.734802,835.511719 
	C787.647888,837.944214 785.419250,839.754028 782.716003,837.969482 
	C780.150085,836.275696 781.789856,834.107666 783.181458,832.425720 
	C785.130859,830.069458 785.819153,827.950806 783.249207,825.493103 
	C780.297180,822.670288 781.062988,820.237915 784.728333,818.133240 
	C785.185303,817.753967 785.375671,817.614075 785.863037,817.291321 
	C786.817322,816.666443 787.754761,816.442322 787.338013,815.111023 
	C786.374512,814.435913 785.544250,814.338867 784.352417,814.420410 
	C780.175354,815.906433 778.646851,819.389465 776.681580,822.931641 
	C775.525940,824.468201 774.380493,825.556458 772.959106,826.843628 
	C771.859436,827.831970 771.597900,828.619934 772.410400,829.621643 
	C775.095947,832.932190 773.195557,835.055176 770.443909,836.898926 
	C764.399719,840.949158 758.466614,845.134338 753.245056,850.756348 
	C750.142883,850.594788 748.486816,851.848328 747.190552,854.435486 
	C744.733643,859.339172 740.959656,862.455078 735.016052,862.019592 
	C733.247314,861.889954 731.761169,861.536011 730.706543,859.432678 
	C730.253967,858.610168 730.053223,858.165100 729.826843,857.733276 
	C728.917358,855.997803 728.095886,854.007812 725.684937,854.197083 
	C723.341614,854.381104 722.632874,856.343201 721.828613,858.542725 
	C721.419312,859.256470 721.081848,859.575439 720.337097,859.913086 
	C718.361389,860.163696 716.212952,859.392273 716.013184,862.559082 
	C714.794189,863.620911 714.009827,862.747437 712.913208,861.966309 
	C712.084778,861.216309 711.408264,860.847473 710.256592,860.815063 
	C703.955444,859.091309 698.837524,855.668762 693.290894,852.358276 
	C692.656799,851.919983 692.308655,851.656555 691.716797,851.188965 
	C690.668701,850.346619 689.942627,849.607178 688.765015,848.948486 
	C687.793945,848.331543 687.234314,847.717773 686.476990,846.858521 
	C685.455566,845.660095 684.600281,844.735718 683.424011,843.667114 
	C681.552307,841.574341 679.462097,840.225952 677.416870,838.287537 
	C676.851562,837.292542 676.691284,836.581604 677.384644,835.565613 
	C686.087646,831.607849 695.028748,830.027527 704.444519,828.714233 
	C711.466492,828.520569 717.591553,825.760925 724.460815,824.977417 
	C726.697815,825.128662 728.842163,826.402893 730.231750,823.436096 
	C731.126343,822.434814 731.993408,822.141174 733.311035,822.108521 
	C736.566833,823.276917 736.566833,823.276917 740.332275,819.513733 
	C741.555359,818.510376 742.637390,818.069153 744.242188,818.149170 
	C747.788513,819.755066 750.269409,819.083374 752.121216,815.551819 
	C755.929565,811.944580 760.881958,811.136780 765.201599,808.382690 
	C767.677551,807.306824 769.689941,806.045837 772.332764,805.414551 
	C775.096863,805.693420 776.905823,804.475891 778.814331,802.736023 
	C779.302612,802.116821 779.614624,801.790833 780.213379,801.270996 
	C781.812378,800.186218 783.224426,799.500610 785.134277,799.199219 
	C786.541687,799.190674 787.493713,798.966797 788.130310,797.549805 
	C789.259705,795.949158 790.606628,795.087769 792.218689,794.010742 
	C799.535400,790.471741 805.440430,785.396362 812.151001,780.950623 
	C814.930908,778.516663 817.885925,776.903320 820.809204,774.727234 
	C821.269165,774.231018 821.681580,774.423645 821.924805,774.227722 
	C821.965515,774.194885 821.817871,774.046204 821.698608,774.050659 
	C821.032410,774.075500 821.974487,774.021240 821.152832,774.544373 
	C817.855896,776.718750 814.800232,778.698486 811.385620,780.767883 
	C804.497131,784.567627 797.817139,787.989929 790.354858,790.544495 
	C789.803467,790.720215 789.585938,790.800537 789.037964,790.990173 
	C785.143372,792.180115 781.999817,794.247009 778.188232,795.597290 
	C777.288757,795.983398 776.671753,796.173157 775.776855,796.496582 
	C775.298401,796.667664 775.107239,796.731384 774.629883,796.878235 
	C774.154541,797.013062 773.965454,797.064697 773.498901,797.201416 
	C772.675171,797.485901 772.151123,797.731262 771.393555,798.192871 
	C770.929688,798.407959 770.730652,798.471313 770.217529,798.582642 
	C769.238586,798.711853 768.588745,798.859009 767.594849,799.175110 
	C757.720764,801.633850 748.179932,803.845764 737.924316,804.452148 
	C730.122681,803.988281 722.841003,806.391479 715.034180,805.013062 
	C711.940857,802.477234 713.394348,800.456360 715.334045,798.708008 
	C717.290466,796.944458 719.638000,795.727844 722.496582,794.691895 
	C726.670654,793.510803 730.210815,791.764099 734.238770,790.304626 
	C735.012634,790.027161 735.476013,789.929321 735.900696,789.744873 
	C736.402039,789.527222 737.346619,789.600952 737.230103,788.927124 
	C737.162109,788.534241 736.129211,788.791565 735.222778,788.487793 
	C734.301208,787.913879 733.896484,787.327454 733.465515,786.326904 
	C733.107971,781.439453 737.493286,780.531738 739.945190,777.658752 
	C742.075500,775.695862 744.274292,774.385437 746.443176,772.478882 
	C750.586670,769.832214 754.329834,767.204712 758.447144,764.525757 
	C761.462402,763.357483 764.031250,762.023499 766.916992,760.658020 
	C767.783875,760.225220 766.937134,760.471680 767.561768,760.238647 
	C767.408203,760.297791 767.301514,760.425232 766.865845,760.653381 
	C763.492432,762.346191 760.150024,763.088318 756.488281,764.121460 
	C749.095337,765.073730 742.215820,764.534119 735.146973,762.136292 
	C732.555908,759.830322 734.750732,758.933105 736.089233,758.494202 
	C745.751709,755.325867 753.322632,748.724060 761.931091,742.927429 
	C759.111206,740.422546 755.368591,742.102295 752.219666,740.437378 
	C751.841675,739.975220 751.756470,739.740112 751.773071,739.139893 
	C753.827637,736.834412 756.560242,736.947571 759.288696,735.997070 
	C766.920349,735.387390 772.463379,731.048584 778.338440,727.454285 
	C779.882996,726.509460 781.236328,725.275208 783.161133,724.263062 
	C786.776794,723.337646 790.119446,722.730530 793.570984,721.336487 
	C796.734924,722.836304 798.485413,721.768799 799.763062,718.757263 
	C802.395203,717.173645 806.122314,718.120483 807.276611,714.049927 
	C809.245728,711.836914 811.553650,710.969421 814.392761,710.501953 
	C815.180847,710.829041 815.531982,711.156128 815.949829,711.887878 
	C816.618408,713.023132 816.196716,714.356506 817.498230,714.748535 
	C817.584595,714.441223 817.633301,714.127502 817.652832,713.507446 
	C817.672974,712.416321 817.618286,711.637939 817.041382,710.687744 
	C814.417725,709.481445 811.012939,709.229126 812.989624,704.755493 
	C813.721130,703.377197 814.554199,702.619080 816.127563,702.375854 
	C818.169373,702.904175 819.756958,703.069275 820.853210,700.788147 
	C822.996277,699.049927 824.926086,697.550476 827.239685,696.000000 
	C836.703430,691.201721 844.965637,685.064209 854.079346,679.823181 
	C854.883423,679.348328 854.565491,680.077271 854.659790,679.440979 
	C854.742554,678.882263 854.543518,678.866577 854.323364,679.633789 
	C845.883179,685.343567 836.725525,689.186646 827.983398,694.468750 
	C825.822205,695.973694 823.671814,696.677795 821.224365,697.500916 
	C820.083130,697.756653 819.327820,698.071472 818.501648,698.938293 
	C816.945435,700.241394 815.452209,700.803162 813.383667,700.604980 
	C810.649597,699.114746 811.303284,696.687927 811.014648,694.211243 
	C811.054077,693.358887 811.155457,692.886597 811.508850,692.124390 
	C814.065369,690.579712 817.126648,690.300293 816.940369,686.235596 
	C816.865356,684.598938 819.838440,684.555542 821.216553,683.011597 
	C821.413757,682.533203 821.481873,682.337158 821.653076,681.855774 
	C822.655457,679.739990 820.808167,679.918274 819.843506,679.029175 
	C819.383301,678.359131 819.257019,677.909119 819.237854,677.069458 
	C821.589417,673.486572 823.783569,670.078308 828.816162,670.814697 
	C829.672241,671.986816 828.911865,672.961487 829.346558,673.913269 
	C829.781982,673.742920 829.966614,673.524292 830.201233,673.035156 
	C830.426147,672.479004 830.491028,672.136108 830.451050,671.495972 
	C828.295349,667.627014 830.996460,666.119446 833.334595,664.532349 
	C838.242859,661.200928 843.587708,658.453857 847.738525,653.638306 
	C848.061829,653.189880 848.607788,653.463440 848.327148,653.078857 
	C842.561340,657.854065 836.045227,661.199402 830.399170,665.770813 
	C829.487427,666.509033 828.398499,666.916992 826.851135,666.875244 
	C825.968384,666.812012 825.588257,666.297485 825.019958,666.343201 
	C825.133545,666.623413 825.270752,666.892029 825.489624,667.484375 
	C825.453552,668.926819 824.764038,669.511719 823.403442,669.893433 
	C820.878845,669.872437 819.862427,668.525574 819.272522,666.277344 
	C819.990906,662.544189 818.471497,660.313354 815.539978,658.407043 
	C815.420776,657.861511 815.414612,657.636658 815.478027,657.084351 
	C816.170776,655.585083 816.075317,654.173889 817.034058,652.799500 
	C819.264160,648.619568 825.396118,647.405823 824.471375,641.226990 
	C825.374512,635.640686 828.737793,631.465271 830.837402,626.351440 
	C831.833862,624.425415 832.945923,622.975525 833.977539,621.085327 
	C835.135925,619.426025 834.871826,617.853699 834.892029,615.984131 
	C835.079834,614.447266 835.499451,613.246155 836.451660,612.586243 
	C840.456299,609.810852 840.325439,606.411743 838.134399,602.268433 
	C838.158325,598.667908 840.053589,596.151550 841.705444,593.162659 
	C842.412292,591.938171 843.522400,591.193115 842.668396,589.592651 
	C842.428711,589.094116 842.340454,588.890991 842.158203,588.369080 
	C841.985718,587.612976 841.942932,587.172363 841.958984,586.396545 
	C842.010193,585.846924 842.043884,585.628662 842.152588,585.088745 
	C842.536255,583.718506 842.817261,582.662354 843.199951,581.293213 
	C843.470947,580.573242 843.650940,580.171204 843.961426,579.464294 
	C845.319763,576.323608 843.316101,573.854370 842.889893,571.128052 
	C841.481689,570.935791 840.458862,570.566650 839.284790,569.690674 
	C838.002991,567.692932 839.520508,566.410950 840.049194,564.605713 
	C840.333496,564.114990 840.462524,563.929932 840.825378,563.506592 
	C842.810181,562.115906 843.668701,560.554504 843.244141,558.093811 
	C843.149841,555.348511 844.910522,553.677795 845.867371,551.313660 
	C846.277283,550.390503 846.579956,549.787170 846.899292,548.818848 
	C847.322388,547.615845 847.704285,546.766113 848.187561,545.569214 
	C850.839417,539.384399 853.258423,533.498535 854.761597,526.891724 
	C855.711304,523.300842 856.486877,520.060547 856.880920,516.345825 
	C858.000977,512.508850 857.735901,508.755646 859.549194,505.123535 
	C863.041077,502.661133 863.023376,500.271973 860.104004,497.338623 
	C858.820251,492.513794 860.477295,488.209564 860.838074,483.406036 
	C863.377563,474.207947 864.847717,465.330444 863.495911,455.764557 
	C862.543457,449.520111 861.556213,443.703278 860.230713,437.955139 
	C861.433899,443.458984 862.537964,448.981415 863.339905,454.986755 
	C864.706848,463.604218 863.764099,471.654572 861.540466,480.010345 
	C860.262878,483.819641 859.566406,487.426910 857.222900,490.755066 
	C856.131958,492.218628 853.968933,492.418396 854.409302,494.869049 
	C854.429688,496.923492 853.739746,498.280151 851.923218,499.298950 
	C850.916504,499.488464 850.273621,499.312195 849.339111,498.948181 
	C848.453003,498.539551 847.908142,498.193695 847.163818,497.581848 
	C846.443726,496.578094 846.618774,495.780853 847.008118,494.713501 
	C847.230896,494.283173 847.333557,494.121124 847.614624,493.743469 
	C848.154907,493.108246 848.416931,492.639252 848.563599,491.804321 
	C848.788208,490.952148 849.027466,490.406494 849.301208,489.538452 
	C849.330139,486.765778 849.361206,484.329193 853.208984,482.288513 
	C848.048096,482.093231 846.589111,479.744904 846.825500,475.808502 
	C846.905457,475.249298 846.954346,475.029877 847.123901,474.499023 
	C847.997314,472.772308 849.453735,472.193665 850.914673,471.136658 
	C851.524597,470.747009 851.899902,470.590332 852.020264,470.383484 
	C851.612549,470.094818 851.487915,469.926483 851.245239,469.462158 
	C851.026428,468.742157 850.961975,468.312683 850.912842,467.544617 
	C850.902039,465.971802 850.581360,464.836731 849.393677,464.276459 
	C846.523926,462.922821 846.565918,460.485260 846.843811,457.557526 
	C846.586365,455.603302 846.567871,453.855316 845.528503,452.586151 
	C843.502258,450.112061 843.903503,447.495087 844.694946,444.415833 
	C846.078186,439.876953 847.473938,435.727417 849.612305,431.497559 
	C850.213257,430.682037 850.704224,430.242584 851.488281,429.695374 
	C852.207764,429.196533 852.478760,428.779541 852.210449,427.865051 
	C852.662537,423.164612 855.155396,419.349762 855.905151,414.664825 
	C856.442993,412.617584 857.280151,410.968536 856.792908,408.713531 
	C853.599609,410.090820 852.692139,414.463562 848.498413,414.485077 
	C847.930054,414.231720 847.726929,414.070099 847.322754,413.563202 
	C846.706238,412.161499 846.654846,411.056549 846.695984,409.506348 
	C849.802368,394.236633 849.399597,379.268982 848.446716,363.849609 
	C847.814819,341.672607 843.036377,320.671387 836.825439,299.524902 
	C835.732239,294.155151 833.636475,289.570404 831.434998,284.628967 
	C830.127869,280.748047 828.078247,277.562408 827.148315,273.565674 
	C827.206238,272.962097 827.261963,272.727173 827.490173,272.179749 
	C828.764526,270.392822 831.648315,271.264313 831.982178,268.603485 
	C830.124023,267.795807 829.120972,269.981628 827.161255,269.714478 
	C824.636047,268.895447 824.206604,266.821045 823.352844,265.047607 
	C817.223022,252.314926 811.159668,239.544556 802.651123,227.717529 
	C796.608215,216.408646 789.886780,205.968323 782.189880,196.161835 
	C781.673096,195.503357 781.156189,194.864243 780.673157,193.800903 
	C779.915222,192.905457 779.315186,192.309433 778.506104,191.500198 
	C777.890015,190.860077 777.560547,190.389282 777.336304,189.541275 
	C776.980530,188.999771 776.737976,188.723251 776.279785,188.277252 
	C775.838562,187.835068 775.588806,187.583771 775.154175,187.151642 
	C774.733704,186.730286 774.496033,186.491928 774.098145,186.073654 
	C773.939575,185.892059 773.650513,185.521683 773.607422,185.297699 
	C773.564331,185.073700 773.109253,185.047333 773.327637,185.123291 
	C773.546021,185.199265 773.709290,185.642349 773.865356,185.824585 
	C774.242615,186.249039 774.471252,186.483994 774.888367,186.890579 
	C775.311829,187.316010 775.555664,187.561188 775.990356,187.992599 
	C776.434631,188.438461 776.689941,188.696213 777.114075,189.177917 
	C777.510864,189.921722 777.545776,190.528503 778.191040,191.174255 
	C778.986206,191.998413 779.585083,192.590118 780.386841,193.455444 
	C785.873291,200.736862 790.785583,208.002472 795.387939,215.472870 
	C796.815308,217.789734 799.008911,220.052200 797.036194,223.151962 
	C796.978149,223.243164 796.690552,223.522858 796.928650,223.490555 
	C800.597778,222.992783 800.739624,226.581268 802.727783,228.566803 
	C811.974121,246.609711 819.852112,264.824432 825.731384,284.257385 
	C826.283264,286.194824 826.552734,287.824097 827.048096,289.749207 
	C827.276855,290.724915 827.358765,291.385498 827.672729,292.324158 
	C828.031555,293.233826 828.258789,293.845703 828.617432,294.762787 
	C828.778687,295.292267 828.826782,295.509369 828.896057,296.060303 
	C828.885010,296.846069 828.791931,297.285583 828.495239,298.017761 
	C825.953491,300.610474 829.179565,301.015472 829.855469,302.670654 
	C831.745911,311.576080 833.341858,320.162933 833.988037,329.267334 
	C833.651245,330.997742 833.204834,332.353638 834.075439,334.075836 
	C834.666687,339.338898 836.207642,344.173737 834.961792,349.567841 
	C832.423401,356.611298 833.100769,363.338867 834.493530,370.467438 
	C835.325195,372.992218 834.864258,374.948456 833.395874,377.120422 
	C831.692017,378.318878 830.225159,378.367523 828.316284,377.618774 
	C825.893494,374.927063 827.422424,372.274139 827.527405,369.262787 
	C827.552490,362.866547 828.385498,356.903412 827.509521,350.540100 
	C827.545349,349.352905 827.279968,348.619537 827.098877,347.511719 
	C827.024902,346.259644 827.371277,345.373840 827.041260,344.164673 
	C826.241089,342.477814 826.012878,340.953583 825.550781,339.166565 
	C825.401306,338.382477 825.670593,337.913208 825.480408,337.456665 
	C825.444946,338.002258 825.524231,338.545654 825.562256,339.447266 
	C826.629211,342.754700 827.076294,345.812775 827.400696,349.309937 
	C827.451233,354.769012 828.557739,359.825134 827.481079,365.304260 
	C827.178711,371.294403 826.328796,376.768127 824.566772,382.496582 
	C822.618652,387.497864 821.736450,392.589355 817.450195,396.178894 
	C816.026184,394.508331 816.615479,392.752380 816.513855,391.013794 
	C816.679871,393.533905 816.111023,396.048828 816.738342,398.955200 
	C815.432739,405.034729 814.003418,410.714569 811.743164,416.518982 
	C807.372803,417.029663 807.647644,413.760803 807.310486,411.223785 
	C807.054932,409.300690 807.613770,407.214691 805.262146,405.698914 
	C804.558472,404.815735 804.543152,404.111786 804.709412,403.048584 
	C805.553040,400.749603 804.837280,399.423676 802.748169,398.341431 
	C801.564453,396.895325 801.006470,395.611389 802.013550,393.828430 
	C802.658875,393.296265 803.099060,393.109894 803.536682,392.912598 
	C804.362061,392.540375 805.803955,392.421478 805.497314,391.264923 
	C805.101929,389.773590 803.637878,390.653290 802.254639,390.607941 
	C800.662292,390.506256 799.473511,390.230957 798.044250,389.469757 
	C797.406250,388.849152 797.156555,388.404907 796.880554,387.544250 
	C796.740601,386.916229 796.703430,386.661530 796.643921,386.022644 
	C796.623901,384.372986 796.666016,383.109772 796.892700,381.492737 
	C797.512085,378.880829 797.955566,376.587677 798.170776,374.296387 
	C798.556824,370.187286 799.973328,366.944580 805.395386,365.504791 
	C802.552979,364.636505 800.427734,364.572693 799.632324,362.396271 
	C799.353577,361.633575 798.668457,361.380768 797.620605,361.656067 
	C791.030945,363.387299 786.929321,359.439117 788.531189,352.980377 
	C788.850769,351.691803 789.401978,350.403412 789.111267,349.089996 
	C787.776123,343.058899 787.194275,337.036896 788.749084,330.944733 
	C788.897827,330.361664 788.396851,329.690948 788.015320,328.736206 
	C787.477783,326.921021 786.697876,325.598022 786.056152,323.792603 
	C786.906616,313.425018 785.812744,303.629425 783.051880,293.605469 
	C782.745117,292.495941 782.629639,291.740021 782.216187,290.685059 
	C781.606567,289.219543 781.212097,288.068939 780.761169,286.558167 
	C772.584778,265.508270 761.426208,246.666168 746.648499,230.018021 
	C744.779968,227.912903 742.843811,225.867615 740.948608,223.332336 
	C740.227356,222.598724 739.910156,221.987381 739.239014,221.238983 
	C737.963867,219.943878 736.904358,218.896729 735.636353,217.625793 
	C734.995544,216.988052 734.606506,216.544464 734.394043,215.668015 
	C734.175232,215.051407 733.891907,215.121124 733.791138,215.057297 
	C733.231323,214.702698 733.900208,215.796616 733.672668,215.169952 
	C733.671509,215.166626 733.795959,215.110657 734.192139,215.247314 
	C734.516479,216.054108 734.627563,216.647141 735.304810,217.304916 
	C736.618530,218.625656 737.705627,219.724411 739.046265,221.064056 
	C739.877075,222.080231 740.339966,222.934738 741.172363,223.998550 
	C748.021790,234.818466 752.877625,245.875504 752.402893,258.667603 
	C752.277710,262.040100 751.781067,265.240875 749.320801,268.216980 
	C747.724304,269.462524 746.109070,269.746643 744.152771,270.459686 
	C746.053589,271.329346 747.963135,271.489502 749.476562,273.078003 
	C749.807312,277.271484 752.760864,280.265228 752.685120,284.430786 
	C752.607117,288.723083 751.945129,292.493835 749.007324,296.056702 
	C745.352356,294.631134 745.066284,291.819580 745.792969,288.386322 
	C746.201416,287.750519 746.841553,287.724426 746.842896,287.280182 
	C746.843994,286.925629 746.532837,286.987946 746.097290,287.632385 
	C744.637878,288.097137 743.580444,287.813324 742.364319,286.846008 
	C736.796936,280.544098 731.002319,274.954773 725.323547,269.250671 
	C720.096497,264.000397 715.884033,258.337646 717.315308,250.180023 
	C717.832520,247.231949 717.328857,244.312393 713.377258,244.010452 
	C710.965515,243.826157 709.832336,242.316681 709.239929,240.290924 
	C708.086426,236.346741 705.793213,232.666061 706.562378,228.262405 
	C706.911804,226.261749 705.094604,225.451004 703.714417,224.048874 
	C700.589050,220.086700 699.041565,215.661392 697.003906,211.508698 
	C697.976929,213.870605 699.545105,216.086426 698.238281,219.133057 
	C691.724304,220.266052 690.381165,219.301041 690.094910,213.296707 
	C689.927551,209.784912 687.457275,209.549637 684.578064,209.134949 
	C684.008545,208.955994 683.789307,208.860153 683.290100,208.540848 
	C682.249451,206.817001 683.691101,205.908524 683.981445,204.691605 
	C684.703857,201.664551 682.273987,197.829880 679.210083,198.055191 
	C675.236328,198.347412 673.360718,196.685593 672.412292,192.822357 
	C671.734680,191.760452 671.331543,190.946671 671.118164,189.693542 
	C670.954834,188.306488 671.697632,187.160553 670.445557,186.042099 
	C668.627686,185.052261 666.740662,185.233597 664.995483,184.005753 
	C664.393494,183.519989 664.093323,183.199020 663.647461,182.577408 
	C662.994263,181.367172 662.864868,180.323395 662.457886,179.060852 
	C662.112671,178.274033 661.837891,177.781311 661.239990,177.169968 
	C660.863708,176.872513 660.707581,176.757156 660.303955,176.479401 
	C659.708618,176.100586 659.361877,175.882263 658.739746,175.491180 
	C650.843079,171.075821 647.588318,163.405853 643.505859,156.020142 
	C643.234314,155.340378 642.989929,155.003006 642.484863,154.492554 
	C642.141602,153.988419 641.817322,153.889801 641.674988,153.702515 
	C641.274658,153.175705 641.147766,154.251572 641.507751,153.690475 
	C641.508850,153.688843 641.647461,153.739120 642.110718,154.073135 
	C642.588928,154.589630 642.864563,154.888519 643.287537,155.471375 
	C643.650635,157.489105 645.132263,158.778961 645.273560,160.876923 
	C645.607178,161.736725 645.855530,162.294388 646.303833,163.092255 
	C646.555969,163.532425 646.655701,163.710190 646.902283,164.158203 
	C647.343689,164.981064 647.619629,165.543472 648.073730,166.381119 
	C648.867065,169.781113 651.448914,172.028290 652.005554,175.513855 
	C650.587646,180.241409 647.785889,184.070923 646.939514,188.984451 
	C647.189209,190.202179 647.260315,191.105392 647.442322,192.336365 
	C649.617004,197.540405 651.801941,202.310516 656.104309,205.820206 
	C657.438721,206.908798 658.546936,208.990982 656.593811,210.563446 
	C654.988281,211.856049 653.239807,211.350754 651.720886,209.878693 
	C647.917603,206.192657 643.282532,204.792160 638.091675,205.461136 
	C636.096741,205.718231 634.473938,205.074463 632.938965,204.103470 
	C630.804871,202.753586 628.680603,201.775696 626.033569,201.698898 
	C621.970154,201.581009 621.228699,197.585373 618.930603,194.906265 
	C617.659851,192.788177 616.781738,190.767944 614.220215,189.842651 
	C613.735229,189.549866 613.549194,189.415741 612.937256,188.731171 
	C612.443726,187.955521 612.375977,187.730331 612.290833,187.154327 
	C612.310120,186.574600 612.346863,186.345688 612.489197,185.790604 
	C613.969482,182.534531 611.714478,180.526794 610.447205,178.525894 
	C609.575623,177.149643 609.305847,179.246918 608.468140,179.933563 
	C605.327087,181.449387 603.941650,179.160126 602.459351,177.506287 
	C593.850159,167.900604 584.394409,159.354050 572.876892,153.313919 
	C572.287231,153.004684 573.045227,153.333466 572.423340,153.115677 
	C572.016724,152.973236 571.870911,153.281448 572.259216,153.948700 
	C572.620728,154.563171 572.829041,154.920700 573.209534,155.556381 
	C573.500488,156.021622 573.617188,156.209915 573.905640,156.687759 
	C574.415894,157.582458 574.675537,158.219498 574.906311,159.243622 
	C574.971558,160.081451 574.909851,160.557755 574.636108,161.360626 
	C571.833740,164.225769 569.877319,161.997620 567.964478,160.603653 
	C561.389404,155.812195 555.169800,150.495544 547.579468,147.251038 
	C545.901733,146.533875 544.333801,145.555618 542.170898,145.059860 
	C535.918762,142.710052 529.843628,141.152466 523.237366,140.240204 
	C519.035767,138.590790 515.095886,137.847473 510.223541,138.812180 
	C514.827271,144.510025 521.197083,147.303711 525.858765,152.481720 
	C526.254822,152.880630 526.408142,153.043320 526.776306,153.457260 
	C527.122620,153.880905 527.254211,154.053299 527.565796,154.491348 
	C528.276428,155.644821 528.767029,156.560150 529.718201,157.531586 
	C530.080383,157.906967 530.225098,158.059128 530.584167,158.447784 
	C530.936890,158.849579 531.075317,159.014893 531.409912,159.443726 
	C531.724792,159.895142 531.843567,160.083038 532.118774,160.569519 
	C532.925842,162.382370 533.269470,163.926056 532.566345,165.863373 
	C530.320190,167.885284 531.447388,169.151611 532.859863,170.608093 
	C535.481384,173.311295 538.727173,175.559891 539.826843,179.830933 
	C538.409424,183.771927 536.154541,187.396255 540.259644,191.163513 
	C540.259949,192.243317 539.762390,192.695404 538.936768,193.297577 
	C538.465576,193.588608 538.272095,193.694809 537.784363,193.946991 
	C536.894653,194.363708 536.299622,194.635315 535.493896,195.220459 
	C534.993469,195.475433 534.779541,195.553131 534.223022,195.669724 
	C529.769287,194.714325 528.425903,191.093262 526.516602,187.643951 
	C526.421509,186.554550 527.180054,185.740173 526.555664,185.091019 
	C526.017883,184.531845 525.459351,185.000122 524.832275,185.760590 
	C524.416443,186.128738 524.231262,186.257858 523.715332,186.478333 
	C520.965515,185.474686 518.891541,184.075455 515.874329,186.038040 
	C513.914551,187.312790 511.556976,185.211243 509.717377,183.798553 
	C507.726654,182.269821 506.594421,179.963974 504.567139,177.994995 
	C502.912140,176.272690 500.819397,175.407028 500.165649,172.870651 
	C500.063293,171.281799 500.386475,170.015686 499.961914,168.463898 
	C499.531128,166.990463 499.154938,165.827713 498.155518,164.621796 
	C495.957703,162.569214 493.724548,164.376648 491.301941,163.938919 
	C484.853943,163.814514 479.352814,165.422333 474.748932,169.605057 
	C473.899994,170.376389 472.988831,171.068878 471.469757,171.344208 
	C470.378510,171.091156 469.867249,170.587799 469.353821,169.598053 
	C470.584991,160.016357 475.178558,152.051971 479.773315,144.240997 
	C485.001404,135.353394 491.531036,127.361961 499.406311,120.545052 
	C488.539948,125.942245 479.897583,134.156815 471.335815,143.026459 
	C470.772064,143.598007 470.443176,143.915146 469.869995,144.466370 
	C468.665680,145.631302 467.688629,146.546570 466.851715,148.025024 
	C466.405487,148.684525 466.127960,149.046570 465.617157,149.664627 
	C460.938690,154.687454 458.417786,160.756058 454.346130,166.156921 
	C453.528900,166.925400 452.949799,167.472900 452.744080,168.617126 
	C452.517853,169.555237 452.172028,170.064545 451.291687,170.511185 
	C448.053894,170.111664 445.024536,168.874771 442.273071,171.970566 
	C435.341309,174.097656 428.847504,176.362793 422.692444,179.556381 
	C420.813568,180.531219 418.735840,181.263504 416.212830,180.094055 
	C415.267365,179.397507 414.811066,178.778458 414.343628,177.708099 
	C414.185089,177.095078 414.151886,176.844742 414.141968,176.220367 
	C416.429443,169.489914 421.481415,165.409073 426.490753,160.842133 
	C427.735138,159.928085 428.860901,160.032349 430.158813,159.995453 
	C430.356689,159.989716 430.462372,159.713577 430.292786,159.819351 
	C429.052826,159.870499 428.207367,159.469711 427.784119,158.412582 
	C427.415314,157.491364 428.108490,155.634750 426.115021,156.102295 
	C424.399078,156.504745 424.769287,158.129684 424.671692,159.764954 
	C423.545959,162.693863 420.802429,163.032883 418.786987,164.301071 
	C414.750824,166.840820 410.796173,169.443909 407.219696,173.137985 
	C406.322540,174.026031 405.615753,174.607346 404.652283,175.406998 
	C402.047607,176.930176 400.319763,178.954346 398.168701,180.991028 
	C397.753601,181.344208 397.582123,181.476532 397.141846,181.784302 
	C395.786682,182.713562 394.025665,182.940552 394.572632,185.280106 
	C391.880798,191.922028 386.332642,196.042542 382.062164,201.579102 
	C378.875519,202.629730 376.081573,201.793121 373.512207,200.866272 
	C369.816772,199.533188 366.206909,199.080582 362.060974,199.915466 
	C361.500580,199.757706 361.288055,199.667526 360.809082,199.368958 
	C359.907654,199.115219 360.381409,196.951004 358.732422,198.530029 
	C353.414551,197.683762 349.368439,200.078201 344.790436,201.713089 
	C340.615753,203.203934 335.221802,203.256287 329.975739,202.416595 
	C327.895538,202.334869 326.148468,202.376602 324.372681,201.082794 
	C322.005615,196.925003 319.634552,196.281311 316.173706,198.802460 
	C314.732727,199.852219 313.461700,201.163589 311.115631,200.595947 
	C309.458252,199.668182 309.022461,198.095337 307.932190,197.109695 
	C305.538849,194.946075 302.834991,194.775681 300.252655,196.578903 
	C297.962067,198.178360 297.185059,200.488541 298.716370,203.056686 
	C300.414795,205.905045 302.906952,206.579483 305.927124,205.215469 
	C307.423828,204.539505 308.804840,203.597244 310.808838,203.223938 
	C314.084900,203.185303 316.980011,202.224380 320.264435,203.087708 
	C321.518555,203.610397 322.207794,204.295395 323.141785,205.237259 
	C324.267914,206.559006 324.745697,207.933762 326.201477,208.992584 
	C329.375305,212.584061 329.303253,212.830109 324.530182,214.794525 
	C323.449371,215.084717 322.713531,215.145615 321.615173,215.124420 
	C315.459106,212.104874 311.039490,216.173355 305.895905,218.220978 
	C304.788177,218.837158 303.920044,219.149963 302.738342,219.627258 
	C302.195160,219.858719 301.974457,219.946457 301.414246,220.149567 
	C298.972778,220.857986 296.879089,221.529160 294.311096,220.845322 
	C290.420959,219.629822 287.472992,221.711441 284.601776,222.978683 
	C279.518921,225.222076 274.332611,226.563828 268.484619,226.939270 
	C266.208069,226.547974 264.502380,225.596786 262.389099,224.733307 
	C261.914917,224.394516 261.749023,224.235687 261.411987,223.790710 
	C261.099945,223.085220 261.021027,222.645065 260.864166,222.233948 
	C260.626770,221.611710 260.126892,221.473465 260.611145,221.931671 
	C261.095398,222.389877 259.914001,222.351852 260.590088,222.506073 
	C260.593781,222.971466 260.577911,223.166794 260.468903,223.658508 
	C260.252380,224.130447 260.129028,224.306015 259.757721,224.697479 
	C259.142487,225.164536 258.756104,225.382294 258.039856,225.696350 
	C257.078735,226.019882 256.412506,226.119965 255.409515,226.239471 
	C253.081680,226.293030 251.092667,226.960159 248.823395,226.130859 
	C247.354401,223.202927 245.322678,224.780640 243.212311,224.965591 
	C242.397568,224.883591 241.968567,224.706665 241.314545,224.203979 
	C240.405533,222.857666 239.753677,221.756393 239.051147,220.330307 
	C239.445038,219.602509 239.804840,219.143951 239.981293,218.253784 
	C239.158188,215.117844 233.726120,213.691757 237.493149,209.311600 
	C238.163635,208.531982 236.340073,208.070999 235.490051,207.700180 
	C231.804123,206.092209 230.740631,204.445190 235.297394,202.483231 
	C235.701965,202.309021 235.761368,201.333084 235.990234,200.364151 
	C235.871704,199.832245 235.744019,199.665710 235.808167,199.249573 
	C239.904297,196.551056 237.660950,193.990509 235.217056,193.455963 
	C230.715271,192.471268 233.242188,190.547684 233.959106,188.348495 
	C233.538284,187.289200 233.898727,186.761261 234.692505,186.130951 
	C235.584427,185.509842 236.168839,185.019043 236.876923,184.264130 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M175.311295,196.267380 
	C172.164017,189.684891 172.792145,184.308609 179.001678,180.224548 
	C180.402237,179.303406 180.233719,177.472748 179.812698,176.043274 
	C178.421127,171.318359 181.734650,171.389694 184.199295,172.370316 
	C188.597092,174.120026 192.104263,175.371170 195.036087,169.965576 
	C195.965286,168.252365 199.227493,167.960663 201.483643,168.198135 
	C204.517395,168.517487 204.566772,171.535385 204.802399,173.643768 
	C205.158051,176.826202 202.758972,175.062057 201.377441,175.070221 
	C200.261108,175.076813 198.900925,174.660995 198.368774,176.095947 
	C198.181595,176.600693 198.386230,177.438309 198.717026,177.910049 
	C199.498810,179.025009 200.676605,179.113770 201.831436,178.530365 
	C203.649246,177.612030 205.106705,177.184082 207.316666,178.355133 
	C210.016159,179.785583 211.794479,177.716400 211.691376,174.983276 
	C211.635254,173.495361 211.614639,172.117447 211.865677,170.637436 
	C212.309479,168.020981 213.663162,167.201584 215.474136,169.084381 
	C220.310745,174.112839 223.806885,172.444687 228.014481,167.935486 
	C229.869965,165.946991 231.378952,162.258209 235.422424,162.843124 
	C236.408600,162.985779 236.638382,162.051758 236.491440,161.074570 
	C235.529175,154.675064 239.021011,158.137466 241.648300,158.960693 
	C241.997559,159.749893 241.998764,160.499054 242.000320,161.622833 
	C241.318237,164.885620 239.509598,167.436508 239.225891,170.756012 
	C233.869019,173.218933 228.940414,175.743546 224.470520,179.195480 
	C221.420700,181.550735 218.178024,183.450089 217.754517,187.893234 
	C217.554047,189.996246 215.501434,189.996201 213.396790,189.999374 
	C212.101028,190.848022 211.086456,191.514145 209.598236,192.089157 
	C203.688110,194.823273 199.366867,191.148468 194.768417,189.184006 
	C193.068420,188.457779 193.010788,186.520081 193.174866,184.782944 
	C193.299698,183.461075 193.497406,182.109573 192.562363,180.959793 
	C191.999329,180.267456 191.268265,179.890686 190.376312,180.179703 
	C189.397293,180.496964 189.037064,181.292145 189.557632,182.128922 
	C192.275101,186.497177 188.541595,187.946274 185.813446,190.200195 
	C184.344086,191.144455 183.289520,191.986801 182.969543,193.869049 
	C180.978745,196.716171 178.506088,197.263519 175.311295,196.267380 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M174.626724,196.000504 
	C177.397385,195.578476 179.977890,195.705276 181.926529,193.359665 
	C182.850571,192.141785 183.565918,191.144913 184.780731,190.217346 
	C185.071121,188.111511 186.683960,187.720367 187.947861,187.011810 
	C189.816238,185.964401 190.409256,184.725418 188.445145,183.252274 
	C187.199677,182.318146 186.624023,181.138901 187.411636,179.838257 
	C188.225693,178.493973 189.716782,177.967255 191.302338,178.085907 
	C192.956085,178.209671 194.056015,179.239548 194.661377,180.685135 
	C195.261948,182.119324 195.057480,183.611710 194.483994,185.045273 
	C194.019348,186.206757 193.914383,188.022461 195.000748,188.101929 
	C199.591812,188.437744 202.959396,194.106018 208.617493,191.050995 
	C210.347321,192.719299 209.528564,194.615875 209.228149,196.725143 
	C205.593262,200.652267 203.439148,198.737305 201.309479,195.169601 
	C198.914719,195.174469 197.142242,195.702759 196.327316,197.999359 
	C195.331131,200.806641 193.324554,201.614990 190.893600,199.798355 
	C188.367096,197.910263 187.542679,199.049637 186.825638,201.441589 
	C186.194839,203.545914 184.917328,205.405441 183.181931,206.706497 
	C177.582550,210.904449 174.071533,217.341278 167.871201,220.870956 
	C166.029785,221.919205 166.404404,223.452560 166.653824,225.206528 
	C167.367508,230.225464 164.754181,233.131577 159.255646,233.240723 
	C159.173157,231.212280 158.386871,229.897232 157.159836,228.634460 
	C152.374146,223.709351 153.220932,218.239502 158.809006,214.390579 
	C163.364868,211.252640 167.108521,207.017105 172.064651,204.338684 
	C173.334793,203.652283 174.366791,202.328735 173.128784,200.302795 
	C172.475769,199.553223 171.930435,199.127945 171.487396,198.173828 
	C171.324265,197.382599 171.373230,196.930222 171.776993,196.244965 
	C172.763382,195.999481 173.508728,196.000549 174.626724,196.000504 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M158.887161,233.215561 
	C164.907898,231.719467 166.504684,229.191940 164.540222,223.911530 
	C163.782455,221.874649 164.450287,220.913452 166.276443,220.035553 
	C173.639786,216.495758 177.431168,208.939270 183.789612,204.221405 
	C184.863876,203.424301 185.697952,201.737976 185.855362,200.371750 
	C186.439331,195.303207 187.914734,194.759247 191.343262,198.676605 
	C193.326385,200.942474 194.544937,199.996033 195.194305,197.182999 
	C195.820419,194.470734 196.982071,191.733322 200.798828,194.734268 
	C201.506775,197.299057 200.046600,199.415222 200.659073,201.869293 
	C201.260544,204.279205 199.353699,203.742355 197.760956,203.452332 
	C193.697113,202.712357 189.305679,200.368439 186.146057,205.347717 
	C185.231308,206.789276 182.699661,207.450119 183.586929,209.723190 
	C184.338593,211.648880 186.276169,211.784744 188.169357,212.105240 
	C193.630508,213.029724 196.603485,219.181824 194.140900,224.684845 
	C192.900024,225.330627 191.800034,225.661880 190.350037,225.996262 
	C189.543549,225.516373 189.049332,225.069855 188.624802,224.555771 
	C187.571274,223.279999 187.485458,220.650055 184.830261,221.567520 
	C182.790771,222.272232 181.515305,223.766556 181.471603,226.042236 
	C181.411499,229.171112 178.890640,231.482407 178.228363,233.901337 
	C176.703064,239.472153 173.855621,240.141205 168.873581,240.275558 
	C166.211212,240.347351 163.303436,242.866272 161.066818,245.117798 
	C160.351074,245.838303 159.699905,246.584656 158.275909,246.217102 
	C154.345261,242.269745 158.725937,238.538818 157.999527,234.403854 
	C158.315079,233.888107 158.573181,233.698486 158.887161,233.215561 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M301.999939,142.599808 
	C301.249054,144.951935 300.113312,146.356873 297.369263,145.999695 
	C296.837128,146.020187 296.672638,146.059570 296.509003,146.056396 
	C293.803802,146.003967 290.336639,150.174149 288.739624,146.375031 
	C287.491089,143.404938 292.248810,142.340286 294.321320,140.419479 
	C294.915771,139.868561 295.703705,139.528397 296.395996,139.080811 
	C300.866241,136.190643 301.618439,136.608536 301.999939,142.599808 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M355.046082,116.640923 
	C355.000000,116.999695 354.500610,117.000206 354.250916,117.000259 
	C353.144775,117.858414 352.279358,118.707520 351.212250,119.783569 
	C351.000000,119.999931 351.000000,120.000000 351.000000,120.000000 
	C349.207825,119.756142 346.653809,122.508965 345.945648,119.979713 
	C345.549438,118.564529 346.660339,115.131180 349.550232,114.298454 
	C352.108063,113.561417 354.475128,112.442024 356.644348,110.901398 
	C357.644501,110.191086 358.835876,109.565636 359.614594,110.897202 
	C360.308533,112.083862 359.528687,113.185699 358.351929,113.675842 
	C356.985626,114.244926 355.438110,114.477440 355.046082,116.640923 
z"
    />
    <path
      fill="#F5F4F3"
      opacity="1.000000"
      stroke="none"
      d="
M239.343567,170.995422 
	C238.365448,167.719574 237.740707,164.441925 241.797867,162.267212 
	C244.371826,162.000961 246.742981,162.004501 249.558624,162.008636 
	C249.852982,164.431229 247.532806,163.600830 246.361633,164.470978 
	C245.001022,165.481873 242.975952,165.988373 243.008392,168.630798 
	C241.943787,169.744797 241.340378,171.242432 239.343567,170.995422 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M234.999878,185.600662 
	C234.739716,186.495224 234.479416,186.989822 234.109253,187.741913 
	C232.932892,187.999680 231.866394,187.999954 230.399948,188.000122 
	C229.837097,185.953369 228.621826,184.735855 226.795090,184.078476 
	C225.719894,183.691544 224.732117,182.810287 225.806915,182.076965 
	C228.199478,180.444641 231.012619,182.364059 233.785324,181.655197 
	C235.490952,181.219147 234.705109,183.904175 234.999878,185.600662 
z"
    />
    <path
      fill="#F5F4F3"
      opacity="1.000000"
      stroke="none"
      d="
M321.146423,130.664459 
	C319.331482,131.057510 317.532288,130.674057 316.173645,132.685760 
	C315.599243,133.425873 315.154114,133.784790 314.288055,133.970917 
	C313.555542,133.999359 313.111115,133.999939 312.333313,134.000885 
	C311.739838,133.506012 311.479675,133.010773 311.109741,132.257751 
	C310.619598,131.614624 310.473114,131.154724 310.780853,130.310486 
	C313.243683,129.082092 315.421112,127.904114 317.747650,127.321136 
	C319.892303,126.783737 322.830536,126.153648 321.146423,130.664459 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M384.525238,103.739601 
	C382.711823,104.574654 381.204041,105.651428 379.711426,103.966148 
	C378.972473,103.131836 378.901031,102.101555 379.569550,101.232681 
	C380.666351,99.807091 382.415314,99.553902 384.009491,99.399124 
	C385.234344,99.280197 386.141785,100.343056 386.495026,101.483910 
	C386.896759,102.781311 385.623199,103.043930 384.525238,103.739601 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M209.348145,196.931763 
	C208.634155,195.235123 207.881104,193.475494 208.965210,191.347260 
	C210.101288,190.670822 211.200623,190.338593 212.649994,190.003784 
	C212.092422,192.381973 211.415680,194.873856 209.348145,196.931763 
z"
    />
    <path
      fill="#F5F4F3"
      opacity="1.000000"
      stroke="none"
      d="
M339.263580,116.196106 
	C339.000000,116.000000 339.000000,115.500443 339.000000,115.250679 
	C340.549347,115.726067 342.526428,112.994576 343.719757,115.424210 
	C344.226593,116.456116 343.409637,117.899338 342.410156,118.365562 
	C340.698242,119.164108 340.371033,117.256149 339.263580,116.196106 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M270.901794,154.812805 
	C272.338593,154.277466 273.531982,152.114075 274.724030,154.314453 
	C275.231781,155.251709 274.400787,156.347931 273.409302,156.587433 
	C272.222046,156.874237 270.199707,158.019287 270.901794,154.812805 
z"
    />
    <path
      fill="#F5F4F3"
      opacity="1.000000"
      stroke="none"
      d="
M326.658539,124.006027 
	C326.999847,124.749626 326.999664,125.499565 326.999146,126.624443 
	C325.872437,126.906471 324.699493,126.648643 324.473389,125.403557 
	C324.230774,124.067680 325.420319,124.118340 326.658539,124.006027 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M251.683014,174.913773 
	C251.996918,176.410172 251.292297,177.038605 249.587708,176.914597 
	C248.929672,175.137161 248.716064,173.508087 251.683014,174.913773 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M398.350525,100.993149 
	C398.094025,99.561249 398.935425,98.986382 400.648407,99.006531 
	C400.905914,100.439194 400.064636,101.014328 398.350525,100.993149 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M403.703186,98.023758 
	C404.314758,98.111069 404.573029,98.301071 404.886810,98.784912 
	C404.530487,99.317558 404.061005,99.634811 403.295746,99.976334 
	C402.888519,99.684784 402.698639,99.426636 402.215179,99.112930 
	C402.469177,98.682480 402.938354,98.365257 403.703186,98.023758 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M363.445129,107.628410 
	C363.663757,108.402885 363.392029,108.682327 362.791504,108.563599 
	C362.666595,108.538902 362.498383,108.362129 362.479462,108.236023 
	C362.388367,107.629135 362.683228,107.374451 363.445129,107.628410 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M310.707947,130.042404 
	C311.000793,130.444916 311.000397,130.889221 311.000000,131.666763 
	C309.805756,131.666641 308.295227,131.429932 310.707947,130.042404 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M200.371613,213.445099 
	C199.597260,213.663574 199.317581,213.392029 199.436417,212.791443 
	C199.461121,212.666550 199.637909,212.498352 199.764038,212.479416 
	C200.370956,212.388260 200.625565,212.683228 200.371613,213.445099 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M856.287231,240.211365 
	C856.000122,238.370697 855.762268,236.870544 853.600220,237.113861 
	C848.191650,237.722565 842.793030,238.419159 837.383728,239.020538 
	C836.050842,239.168701 835.201355,239.651230 835.050415,241.071426 
	C834.536377,245.909241 830.818481,244.002167 828.519165,243.697876 
	C826.262390,243.399231 826.881775,241.210068 828.397278,240.550644 
	C832.155884,238.915115 830.444641,237.364319 828.365356,236.304337 
	C825.858948,235.026566 824.908447,234.058228 826.579895,231.148544 
	C827.583679,229.400909 828.026611,226.562210 824.373535,225.841080 
	C822.127258,225.397675 821.675659,224.129532 823.777771,222.509354 
	C825.067017,221.515656 825.952393,219.725464 827.357239,219.204376 
	C831.824097,217.547485 831.269348,215.948837 827.750549,213.845230 
	C825.696289,212.617126 824.297485,210.886993 823.622314,208.466339 
	C823.221558,207.029739 823.149292,204.944275 820.373779,205.010635 
	C820.735229,201.203506 822.970947,200.464920 826.640930,201.958740 
	C827.000000,202.000000 827.000000,202.499695 827.000000,202.749542 
	C827.229370,203.238541 827.481018,203.460022 827.685547,203.718781 
	C830.546387,207.338196 833.163696,208.536469 838.158264,209.074249 
	C840.540466,209.330734 843.514587,209.458344 843.772583,212.797440 
	C844.006104,215.818542 842.283386,217.718048 839.369080,218.596893 
	C837.316528,219.215881 835.232666,219.771637 833.207581,221.020142 
	C837.121216,220.095947 840.427490,220.964066 843.094788,223.922318 
	C843.657776,224.546722 844.551453,224.740494 845.386230,224.749100 
	C852.337708,224.820862 855.490601,229.700363 858.134033,235.018967 
	C859.122192,237.007141 860.147400,239.514893 856.287231,240.211365 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M737.958740,155.653778 
	C732.207275,152.490295 725.561218,150.793030 719.816345,147.055115 
	C714.460266,143.570190 707.902466,142.506271 702.253784,139.347366 
	C699.103882,137.585800 695.680847,136.366440 693.057129,133.341858 
	C700.389832,137.461517 709.449036,137.814896 716.676514,142.933914 
	C720.208923,145.435822 724.617249,146.778671 728.745117,148.339462 
	C732.548523,149.777573 735.422424,152.245972 737.958740,155.653778 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M768.000000,172.588852 
	C762.956055,170.755844 757.688904,168.936722 753.623413,164.903854 
	C753.010254,164.295609 751.730103,163.950195 750.851013,164.085220 
	C744.563477,165.051117 741.463989,160.661392 738.040527,156.351257 
	C742.900513,156.674576 747.026672,158.857910 751.451721,161.099640 
	C756.187195,163.498566 760.082703,168.726227 766.713745,167.637344 
	C768.783020,167.297546 767.594055,170.581375 768.000000,172.588852 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M806.135010,192.684219 
	C805.687805,192.929352 805.203430,192.962494 805.089050,192.772644 
	C802.923950,189.179947 800.138184,188.046051 796.196838,190.235199 
	C796.577881,188.664444 797.964417,187.756927 797.985718,186.116974 
	C798.007263,184.454330 797.988220,182.630905 800.124023,182.228531 
	C802.071411,181.861618 803.175903,183.427734 804.054871,184.711349 
	C805.600220,186.968155 807.206238,189.338257 806.135010,192.684219 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M789.000977,183.611694 
	C786.573120,183.311249 785.345215,180.370377 782.235107,180.261719 
	C784.082397,178.585037 787.044434,178.596725 788.326477,175.807571 
	C788.782654,174.815125 790.070618,174.964066 790.917419,175.670914 
	C791.918396,176.506470 792.374634,177.754791 791.239014,178.624084 
	C789.644531,179.844620 788.790894,181.222061 789.000977,183.611694 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M819.583374,205.000000 
	C819.593323,207.028610 823.338074,208.657623 821.289673,210.208191 
	C819.550171,211.524918 818.337708,208.229828 816.490601,207.607071 
	C815.875977,207.399902 815.543884,206.354874 815.041382,205.346436 
	C816.388916,205.000000 817.777771,205.000000 819.583374,205.000000 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M688.842285,130.699188 
	C684.700073,131.098892 682.305054,127.657913 679.060303,125.341881 
	C682.978821,125.452759 685.794678,127.991951 688.842285,130.699188 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M812.006836,200.624191 
	C809.630371,200.530029 808.997253,198.479706 808.038818,196.345032 
	C811.257568,195.602478 813.522156,196.143127 812.006836,200.624191 
z"
    />
    <path
      fill="#F5F4F3"
      opacity="1.000000"
      stroke="none"
      d="
M803.961060,176.653992 
	C800.963440,176.741333 799.887390,173.585663 797.195801,172.262558 
	C801.419739,170.042130 802.119080,174.061264 803.961060,176.653992 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M788.001221,140.997559 
	C790.359741,144.479935 785.983704,146.344131 786.005615,149.633789 
	C783.161621,148.443802 782.194458,143.165909 783.961914,138.341675 
	C785.961121,137.743423 786.015686,140.143799 787.761414,140.763062 
	C788.004883,140.996780 788.000000,141.000000 788.001221,140.997559 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M787.943359,140.654510 
	C786.140625,141.065659 785.378357,139.664764 784.260620,138.193390 
	C783.997559,137.999847 783.994324,137.507782 783.992920,137.261932 
	C781.298706,133.763977 782.552002,132.319778 786.644653,132.006317 
	C787.261230,132.494812 787.517578,132.988419 787.881104,133.738861 
	C785.132141,136.053696 786.024109,138.174835 787.943359,140.654510 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M171.697495,195.868027 
	C172.010559,196.443741 172.003052,196.889084 172.002197,197.668427 
	C172.024567,198.889160 171.741943,199.701813 171.180206,200.744659 
	C170.241013,200.973373 169.511093,200.954590 168.419998,200.888855 
	C168.638977,198.798630 166.097504,194.726074 171.697495,195.868027 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M171.234558,200.888977 
	C171.146088,200.135193 171.321259,199.278214 171.752640,198.211838 
	C172.323563,198.471237 172.638275,198.940033 172.975891,199.704071 
	C172.498581,200.261475 171.998398,200.523636 171.234558,200.888977 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M202.988617,139.279449 
	C203.863113,137.960892 204.500351,138.157150 204.984818,139.677307 
	C204.572433,139.979507 204.119888,139.996002 203.327789,140.022461 
	C202.987595,139.865082 202.986923,139.697754 202.988617,139.279449 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M789.812378,123.752777 
	C789.983765,124.181267 789.974854,124.355949 789.952271,124.792618 
	C789.729675,125.733398 789.258057,126.127090 788.290039,126.164291 
	C784.480469,126.345459 782.607544,124.992508 782.983032,121.216843 
	C783.097168,120.068901 782.913757,118.892258 783.016113,117.742081 
	C783.326355,114.258286 782.029663,113.267570 778.566650,111.423309 
	C772.954468,108.434517 765.749451,109.990410 761.199463,104.544075 
	C759.863037,102.944374 758.359558,104.790062 757.237671,106.108536 
	C755.157471,108.553139 753.390625,108.260742 753.097107,104.964798 
	C752.839355,102.070290 750.888367,101.098808 749.179871,102.295959 
	C744.126587,105.836754 742.687927,102.397369 741.025269,98.409454 
	C743.443481,96.596008 745.920410,93.945595 748.359253,98.095055 
	C752.201172,95.990074 756.266724,99.082230 760.102478,97.629059 
	C766.002625,95.393814 766.438660,97.008400 769.881775,101.328613 
	C771.827026,103.769356 775.172974,106.547485 778.215393,108.610825 
	C779.666565,109.595009 781.798767,107.699127 781.541504,105.084007 
	C781.282593,102.452293 782.484558,100.224937 785.092468,100.376892 
	C787.519592,100.518311 789.306885,102.378189 788.343445,105.343727 
	C787.934875,106.601494 788.294189,107.587822 789.039734,108.571114 
	C790.674194,110.726807 789.818970,112.440804 788.289551,114.324738 
	C786.812683,116.143959 784.595093,118.163582 787.745483,120.851517 
	C787.974365,122.226601 788.813599,122.856232 789.812378,123.752777 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M787.996460,126.440613 
	C788.477234,125.773003 788.959351,125.519569 789.690063,125.160378 
	C790.272949,125.139984 790.543335,125.320976 790.863037,125.818344 
	C790.940979,127.991203 791.387939,130.165939 788.366089,130.957413 
	C788.000916,129.623047 787.999329,128.238922 787.996460,126.440613 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M855.975830,240.295746 
	C858.121704,239.701309 858.199402,238.292404 857.710938,236.692627 
	C855.843445,230.575714 851.006104,226.901321 844.451538,226.675827 
	C843.314270,226.636688 842.393616,226.182281 841.964966,225.331436 
	C839.891785,221.216431 836.690186,221.591278 833.081604,222.749100 
	C831.947205,223.113083 830.133301,223.677963 829.638489,222.028976 
	C829.137085,220.357971 831.010071,219.980362 832.132568,219.511505 
	C834.111877,218.684738 836.147705,217.837646 838.242126,217.478409 
	C841.394714,216.937683 843.608459,214.918304 842.451416,212.270828 
	C841.523621,210.148163 837.575317,208.989365 835.563232,210.560638 
	C830.585144,214.448196 828.849121,213.269272 828.571472,207.415710 
	C828.512634,206.175858 827.607971,204.976120 827.046509,203.378632 
	C829.844910,204.298889 837.299011,205.889755 841.063293,206.000793 
	C844.923584,206.114670 849.872498,211.635101 849.954712,216.250916 
	C850.019592,219.891068 849.581543,223.595749 855.083130,223.078430 
	C855.931641,222.998642 857.162842,223.800797 856.676575,224.466965 
	C853.269836,229.133636 858.269714,229.064804 860.102356,230.354721 
	C864.165283,233.214523 866.563538,236.911163 866.959412,241.801605 
	C867.138550,244.014603 867.725769,246.045853 868.662964,247.993637 
	C870.158447,251.101898 872.051453,253.748535 867.655029,256.679352 
	C865.185364,258.325714 864.833557,262.306946 865.186951,265.438232 
	C865.494324,268.162231 867.207764,269.358429 870.232117,267.992432 
	C871.829163,267.271057 873.240906,264.445648 874.725891,265.738373 
	C877.542603,268.190491 881.169006,269.806091 883.020325,273.405701 
	C884.736877,276.743347 885.239258,280.383423 885.688538,283.934814 
	C886.179321,287.814026 888.343994,290.643982 890.248047,293.706787 
	C892.845215,297.884155 895.861694,302.299011 895.757080,307.099701 
	C895.574280,315.491241 897.800964,322.968719 901.502136,330.230286 
	C902.246765,331.691284 901.632324,332.732147 900.665710,333.793915 
	C896.064148,338.848541 896.254333,340.285675 902.007263,344.664917 
	C902.266907,344.862549 902.458069,345.150116 902.840576,345.697906 
	C907.881775,349.865143 908.835632,356.330231 912.790100,360.837616 
	C913.631165,361.796265 913.677979,363.497620 913.208984,364.709930 
	C911.550720,368.996368 913.189331,372.714539 914.491333,376.728149 
	C918.344543,388.606140 921.105530,400.807556 924.775024,412.753632 
	C926.281067,417.656525 925.114136,422.979919 926.244690,428.093414 
	C929.395996,442.347565 930.327271,456.941650 933.579163,471.276276 
	C936.811157,485.523224 938.493408,500.292389 937.845764,515.227356 
	C937.475281,523.768616 935.517578,532.337097 937.135132,540.954407 
	C937.352417,542.112244 937.214355,543.448303 936.479797,544.325073 
	C930.915100,550.966248 932.939758,558.662292 933.166138,566.175903 
	C933.373047,573.045837 932.730347,579.822876 928.866516,585.864258 
	C926.040039,590.283569 926.292847,595.595276 925.948120,600.349731 
	C925.063660,612.548828 920.966003,623.721924 917.180786,635.093689 
	C914.258728,643.872742 912.184021,652.922974 909.161743,661.680115 
	C907.315369,667.030334 905.093628,672.226318 902.310547,677.160461 
	C901.578491,678.458252 900.070007,679.618469 900.162964,681.037659 
	C900.639099,688.310791 896.499329,694.683533 896.317444,701.778748 
	C896.229248,705.222473 894.451843,707.584595 891.553162,708.766968 
	C888.672974,709.941772 887.266602,711.741333 887.232910,714.720337 
	C887.104065,726.104614 880.404541,734.569763 874.266235,743.702637 
	C874.000000,744.000000 874.000000,744.500000 874.000000,744.750000 
	C871.299683,746.772949 872.244690,750.264954 870.237061,752.738159 
	C866.738037,754.911133 867.524597,758.975159 865.232971,761.763062 
	C863.802612,763.830750 863.247803,766.072388 861.232422,767.764343 
	C859.214966,767.864319 858.289734,769.656677 856.342285,769.962158 
	C853.612854,772.815979 852.291809,775.276306 856.469604,778.031555 
	C858.893005,779.629700 858.508240,782.339233 856.237061,784.758850 
	C855.992798,785.167542 855.985596,785.335022 855.963989,785.753662 
	C852.726501,793.263733 850.432068,793.734924 844.290955,788.408386 
	C843.669250,787.869141 843.122375,787.208130 841.909851,787.317444 
	C843.789917,789.655701 847.769348,790.142029 848.075806,793.914062 
	C848.369263,797.525330 845.423218,799.505981 844.093384,802.667114 
	C843.999207,803.402710 843.998352,803.805420 843.996338,804.510132 
	C843.889404,805.594482 843.443481,806.159363 842.531311,806.812012 
	C838.279053,808.934692 836.363770,813.925232 831.259216,814.189819 
	C830.142761,814.247742 829.031250,814.853149 827.844971,814.708557 
	C823.213196,814.143799 820.297546,816.527588 818.014282,820.605347 
	C817.595276,821.293762 817.310425,821.651001 816.731812,822.194824 
	C814.848755,823.368713 812.876221,823.901794 811.978638,826.234619 
	C810.960815,827.818237 809.744690,828.806763 808.557434,830.272461 
	C805.281494,834.058533 802.015198,837.287964 796.647461,838.753723 
	C791.700806,840.180420 787.170715,840.738464 784.795227,845.520447 
	C783.880432,847.362000 782.105103,848.759888 779.452454,847.066589 
	C776.503235,845.183960 775.703979,847.300842 776.442139,849.610901 
	C777.558289,853.103760 776.414856,854.235291 773.049194,854.015808 
	C771.705811,853.928223 770.375610,854.065857 769.472107,855.279175 
	C767.948486,857.325500 767.464233,856.076050 765.625244,855.119141 
	C762.029968,853.248413 758.884277,848.751526 753.382324,850.992615 
	C755.661865,843.886963 762.611511,841.352844 767.911438,837.060974 
	C768.810608,836.332886 769.804932,835.712646 770.796143,835.109863 
	C772.875488,833.845520 773.881409,832.588562 771.415344,830.594482 
	C769.831787,829.314026 769.175476,827.678040 771.650635,826.070312 
	C774.137085,826.526245 776.017212,826.608459 776.057739,823.307007 
	C776.689819,818.404846 779.410217,815.306885 783.654419,813.038696 
	C785.170349,814.222839 784.582092,816.138245 785.862305,817.688965 
	C785.877136,818.851929 785.634460,819.612793 784.694885,819.905334 
	C781.369873,820.940613 781.775940,822.816528 784.016907,824.416748 
	C788.102661,827.334290 787.807068,830.080139 784.351685,833.209534 
	C783.351318,834.115479 781.645081,834.981445 782.659607,836.830383 
	C782.903564,837.274902 783.816101,837.719910 784.286194,837.606323 
	C785.901672,837.215942 787.563721,836.602173 787.873718,834.662964 
	C788.516724,830.641357 791.290955,827.437500 791.347473,823.005676 
	C791.431702,816.405334 795.738037,812.852112 802.616150,812.007263 
	C804.007507,815.586792 800.415161,817.011780 799.151367,820.093933 
	C804.283936,817.894409 805.560120,811.497314 811.195984,810.561401 
	C809.170227,810.496948 807.500549,811.986023 805.255737,811.232727 
	C805.387695,806.931702 808.926636,806.492554 811.546509,806.482605 
	C816.323364,806.464478 819.025513,804.267456 820.855896,800.283264 
	C821.349243,799.209473 822.041748,798.322632 823.650696,798.042236 
	C824.646484,799.836060 824.332703,802.798035 827.929504,800.815063 
	C829.171143,800.130554 830.839050,801.048828 832.662109,800.919373 
	C830.218872,800.359253 827.647278,801.006836 826.097168,798.331177 
	C826.914490,794.770691 829.404602,796.062012 831.328308,796.315308 
	C833.768372,796.636658 836.134705,797.659607 838.570984,797.828247 
	C840.922485,797.991089 843.664612,797.302551 843.719788,794.420532 
	C843.767700,791.918091 841.523621,791.025635 839.091003,790.985840 
	C837.231262,790.955383 835.475220,791.168152 833.802429,792.058899 
	C832.992249,792.490295 831.927368,793.260193 831.303650,791.895935 
	C830.816895,790.831299 831.483276,789.905029 832.394287,789.408569 
	C834.636230,788.187195 836.203125,786.230896 837.981567,784.506714 
	C840.778687,781.795105 843.494385,778.025513 846.057129,784.786743 
	C846.683655,786.439636 848.208984,786.029663 849.356873,785.039429 
	C850.732910,783.852356 851.723877,782.322266 850.054565,780.826355 
	C846.215454,777.385986 848.230591,773.057190 848.298584,769.164551 
	C848.340088,766.792175 850.753601,765.945435 853.183044,765.974365 
	C856.289673,766.011353 858.013611,764.795227 858.117737,761.261536 
	C858.213074,758.028687 860.770752,755.155640 859.935730,751.224976 
	C858.424072,749.086792 858.420776,746.827087 857.695190,744.490967 
	C860.859070,742.650818 859.885742,738.841309 861.954468,736.363586 
	C862.021362,735.404358 862.202881,734.867249 862.791626,734.201355 
	C863.016785,732.252625 864.150391,731.231812 865.705566,730.146851 
	C867.087341,730.102478 867.162292,729.180908 867.680908,728.236572 
	C872.489014,726.935425 874.820984,723.755554 876.772095,719.622192 
	C881.355042,709.913635 885.270752,699.949097 889.259094,689.998230 
	C890.971436,685.726013 894.463379,682.362854 896.097229,677.946533 
	C897.160461,675.072632 897.934753,672.272583 896.929199,669.244263 
	C895.722351,665.609680 896.154480,662.062500 897.761475,658.284668 
	C898.000000,657.555542 898.000000,657.111084 898.000000,656.333313 
	C901.684814,654.563477 903.266052,651.557068 904.828796,648.077881 
	C913.194336,629.453186 918.620667,610.025574 921.618713,589.869141 
	C922.353516,584.928894 924.290771,580.278137 925.284668,575.372925 
	C926.052490,571.583374 926.953857,567.788757 926.394592,564.010925 
	C924.916260,554.023804 926.922668,544.097656 926.539856,534.137756 
	C926.343628,529.034119 928.959534,523.940613 927.045898,518.827148 
	C923.992249,510.667542 925.817017,502.521423 926.450623,494.276611 
	C927.303833,483.172943 927.376587,472.005493 924.802734,461.043640 
	C923.465698,455.349609 922.266907,449.620148 922.542786,443.778015 
	C922.765991,439.053162 919.923279,435.273224 919.330444,430.779724 
	C918.546326,424.837311 919.614441,418.274780 914.713013,413.365509 
	C914.264526,412.916382 914.213013,412.128204 914.259766,411.457886 
	C915.039368,400.300629 911.730896,389.586975 908.333130,379.472168 
	C905.340271,370.562592 902.839355,361.496185 899.747437,352.633820 
	C898.361084,348.660309 894.823059,345.579376 893.363342,341.431244 
	C892.924683,340.184662 891.798767,339.008606 891.943054,337.740845 
	C892.833740,329.911407 889.189697,322.835236 888.396667,315.300049 
	C887.639832,308.108429 883.725525,303.068787 877.321289,299.252197 
	C876.419678,298.182098 875.839355,297.364166 875.129517,296.273132 
	C874.441528,295.379547 873.548157,294.692230 873.637512,293.967896 
	C874.387207,287.892487 869.706787,282.805267 870.581360,276.739227 
	C871.045959,273.517120 869.775635,272.179016 866.429932,272.407806 
	C863.932495,272.578613 861.449585,272.542206 858.744019,273.560425 
	C857.077576,273.071411 856.723389,271.708618 855.582520,270.611084 
	C853.617737,268.292114 854.935364,264.675903 853.164307,263.468414 
	C849.267822,260.811859 846.585938,256.092377 841.085754,255.903931 
	C839.084778,255.835373 836.870178,255.192123 836.425354,252.945740 
	C835.945129,250.519791 837.566040,248.880096 839.764282,248.082153 
	C841.499023,247.452484 843.107239,246.685501 844.753357,245.243805 
	C846.420837,245.329590 847.841736,245.659164 849.631287,245.994370 
	C851.871887,248.183472 852.107178,251.700851 855.794800,252.775665 
	C856.404541,249.181824 852.645996,246.099792 854.769287,242.255920 
	C855.317200,241.530502 855.634460,241.060989 855.975830,240.295746 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M316.333313,901.999878 
	C314.862701,901.416504 313.725372,900.833008 312.295959,900.119873 
	C309.633606,898.036316 306.526245,897.922302 303.801056,896.913269 
	C298.925659,895.108032 295.201202,891.600464 290.447754,889.044678 
	C289.733398,888.639038 289.354614,888.366882 288.742920,887.835205 
	C286.121857,885.255127 283.467102,883.339539 279.975525,882.097290 
	C278.744110,881.728394 277.886932,881.353088 276.797241,880.645996 
	C269.107147,873.422729 259.866211,869.123840 251.266861,863.243286 
	C250.825424,862.450256 250.649796,861.900452 250.235565,861.177795 
	C249.825180,860.836670 249.653427,860.668457 249.741440,860.250122 
	C251.435532,860.165466 252.869858,860.330872 254.401978,860.507507 
	C254.901871,857.934875 252.087402,853.971680 256.301788,853.388489 
	C259.299164,852.973694 261.710785,857.104858 261.545654,859.013794 
	C260.860107,866.938599 266.630280,867.997131 271.736267,870.072510 
	C272.956055,870.568359 274.467987,870.958496 275.218140,871.898071 
	C280.465790,878.471191 289.068451,880.191895 294.983154,885.873535 
	C300.104584,890.793030 306.418884,894.046021 313.078583,896.586975 
	C315.280823,897.427246 317.093597,898.923157 317.960266,901.649780 
	C317.555542,901.998291 317.111115,901.998962 316.333313,901.999878 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M227.005493,841.386963 
	C230.274933,843.823364 234.409149,843.325623 238.631195,843.957642 
	C239.314362,844.789429 239.629364,845.576416 239.972168,846.681702 
	C240.014709,847.597473 240.197632,848.132385 240.795395,848.794556 
	C242.721817,850.073059 244.294571,851.281067 244.960876,853.674255 
	C242.259552,854.071472 239.768433,852.444580 236.791183,853.406494 
	C234.591156,854.117249 234.985596,852.193237 235.676071,850.992004 
	C237.859131,847.194031 235.384415,846.262695 232.478302,846.075317 
	C229.499130,845.883240 227.331406,845.126099 227.005493,841.386963 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M667.977539,907.724609 
	C672.124084,906.018982 675.339233,903.431152 678.768127,901.182495 
	C679.771301,900.524658 681.170227,899.318665 682.311035,900.637695 
	C683.311768,901.795105 681.727844,902.648621 681.214233,903.572266 
	C679.316345,906.985168 671.469177,910.268066 667.642456,909.266296 
	C667.563477,909.245605 667.695374,908.419434 667.977539,907.724609 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M803.678589,837.700562 
	C801.943420,840.807129 801.624207,846.421875 795.384399,843.048218 
	C795.308167,841.894653 795.618774,840.791748 795.964722,839.344421 
	C798.143188,837.049561 800.590332,836.495483 803.678589,837.700562 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M249.725922,859.785034 
	C250.000687,860.167480 250.000137,860.334961 249.998276,860.753662 
	C247.169174,860.346130 245.802551,858.308716 245.006226,855.363708 
	C248.571838,854.487549 247.903870,858.108398 249.725922,859.785034 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M730.455322,871.862305 
	C730.279419,870.747437 729.709778,869.291992 731.447205,869.091614 
	C733.058411,868.905823 733.406860,869.869202 733.095093,871.622070 
	C732.273193,871.987427 731.543518,871.964905 730.455322,871.862305 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M240.975845,848.704224 
	C240.108841,848.809814 238.960953,848.843628 239.781097,847.175842 
	C240.317230,847.469482 240.634460,847.939026 240.975845,848.704224 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M815.771606,822.228638 
	C816.110046,821.684082 816.300537,821.425720 816.784546,821.111206 
	C817.232300,820.755371 817.507263,820.425232 817.792908,820.446106 
	C828.434570,821.224060 835.701294,814.956970 843.084778,808.189331 
	C843.639709,807.559021 844.029297,807.284546 844.825195,807.035889 
	C851.008057,808.232605 856.726196,804.461670 856.424988,798.575012 
	C856.163879,793.473389 859.800415,790.373108 861.626221,786.678528 
	C862.851624,784.198792 864.913635,781.545776 866.368774,778.726501 
	C867.805298,775.943176 868.598267,772.674866 872.489502,771.388062 
	C874.714966,770.652100 876.091736,767.752319 873.664917,765.232727 
	C872.200439,763.712280 871.890503,762.264832 872.728271,760.295410 
	C874.873779,755.251648 875.841125,749.243103 883.583374,749.790405 
	C885.408691,749.919495 887.333252,748.645081 889.606079,748.008179 
	C887.685669,752.740173 884.137329,756.206482 879.144958,757.951599 
	C874.778015,759.478027 874.075195,760.768799 876.876343,764.582092 
	C878.995911,767.467529 878.793091,769.446960 876.008423,771.550110 
	C874.299622,772.840637 873.416138,774.403137 875.025391,776.629028 
	C876.867737,779.177246 875.669983,780.762573 872.833130,780.931580 
	C869.710815,781.117676 869.797119,782.939026 871.358582,784.341431 
	C873.485901,786.251892 874.366333,788.937561 876.654907,790.938843 
	C876.998108,791.446350 876.998596,791.890198 876.999573,792.667053 
	C875.578186,796.858704 872.109009,796.706543 869.090332,796.532776 
	C866.067139,796.358643 865.379028,793.658508 864.858459,790.933716 
	C864.836975,792.687622 863.804199,793.819458 862.629395,794.837036 
	C861.208008,796.068115 860.813171,797.568604 861.382141,799.322144 
	C862.337402,802.266113 861.931824,804.884155 859.642639,807.098389 
	C859.286133,807.443176 858.700989,807.893494 858.772827,808.269470 
	C860.140564,815.424805 854.843079,814.921143 850.252258,815.762634 
	C849.202698,816.678467 848.793274,817.604553 848.193481,818.766724 
	C847.741150,819.496643 847.479858,819.990906 847.108093,820.742371 
	C845.680847,821.924927 843.715454,822.663330 844.572021,824.714966 
	C845.268860,826.383850 846.914856,829.042053 844.741455,829.191650 
	C840.973511,829.450928 838.849426,835.143127 834.070679,831.725647 
	C829.753662,828.638245 825.099304,825.008789 819.218140,828.761780 
	C819.091797,828.842468 818.855652,828.883423 818.734253,828.820435 
	C814.006775,826.368347 812.094177,830.160278 809.352051,832.941284 
	C807.256714,833.043884 807.375488,831.886475 807.763611,830.353149 
	C807.940979,828.142761 809.333130,827.376648 810.808044,826.264465 
	C812.360962,824.620911 814.225830,823.888367 815.771606,822.228638 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M749.649780,892.004028 
	C751.878113,891.622742 753.611267,890.820740 755.426819,892.732971 
	C756.751953,894.128601 763.466553,892.413086 764.368896,890.862854 
	C765.015198,889.752625 764.390808,888.989685 763.714600,888.237976 
	C760.630798,884.809937 762.342041,883.767212 765.955139,882.696655 
	C768.584412,881.917542 770.842102,883.023987 773.203308,882.830688 
	C776.826233,882.534058 780.231750,881.181946 783.636719,879.043701 
	C784.312622,881.297424 782.976868,882.814270 781.386780,884.067444 
	C778.899963,886.027283 776.736206,888.139038 775.219421,891.031433 
	C774.367371,892.656189 772.977051,894.285217 770.361389,893.328552 
	C766.942139,892.078003 764.894897,893.875916 764.145935,897.115051 
	C763.508606,899.871521 761.818359,901.478821 759.297668,902.296021 
	C756.434082,903.224426 753.749084,903.494568 751.852783,906.939941 
	C750.432983,909.519348 745.477478,907.287781 742.685669,909.586426 
	C742.078308,910.086731 741.175293,910.228027 740.174927,910.826843 
	C739.522034,911.070984 739.102539,911.023010 738.368347,910.936401 
	C736.832825,909.486816 735.053040,909.872864 733.256836,909.245361 
	C731.797058,907.083008 731.989624,904.934937 731.996094,902.410889 
	C732.683594,901.129822 733.649475,901.193665 734.639404,901.074829 
	C736.448303,900.857666 737.267456,904.295105 739.083374,902.567017 
	C741.378540,900.382874 744.849487,899.178284 745.765808,895.253357 
	C746.735657,893.599915 747.199463,891.898804 749.649780,892.004028 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M745.653687,895.057617 
	C746.470276,897.939636 745.652710,900.661926 742.726624,901.373901 
	C739.707581,902.108459 740.699036,905.873535 738.570557,906.278870 
	C735.947937,906.778259 737.631470,902.159424 734.765259,902.142090 
	C734.104614,902.138123 733.444519,902.052124 732.389648,902.004395 
	C731.444092,901.827698 730.893005,901.650452 730.171021,901.231750 
	C731.212402,898.376282 734.260498,898.098389 735.895996,898.540649 
	C740.167542,899.695496 742.693359,897.651489 745.653687,895.057617 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M807.650513,830.041748 
	C808.162415,830.859192 808.327271,831.718323 808.748535,832.789368 
	C808.755066,833.805115 808.505249,834.609070 808.127686,835.706482 
	C807.458557,836.524719 806.930786,837.063232 806.195618,837.794067 
	C805.553589,837.996704 805.110413,837.998291 804.334839,838.001221 
	C801.556824,837.963745 799.100464,837.838318 796.395508,838.948425 
	C796.264587,838.421204 796.442139,837.402527 796.806885,837.330383 
	C801.377380,836.426819 804.158997,833.002136 807.650513,830.041748 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M842.732178,841.885986 
	C843.273315,843.096008 844.675415,844.800415 842.312073,844.818359 
	C840.377808,844.833130 839.623840,843.283081 840.765991,841.092957 
	C841.503418,841.153625 841.987549,841.461243 842.732178,841.885986 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M806.314758,837.950562 
	C806.085266,837.096375 806.343567,836.319702 807.682556,836.045898 
	C807.913635,836.900330 807.657288,837.678772 806.314758,837.950562 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M732.871582,909.298645 
	C734.645325,909.295532 736.628052,908.204041 737.956421,910.544556 
	C736.045959,910.165955 734.348999,911.038635 732.334351,911.977417 
	C732.254822,911.205627 732.499023,910.398987 732.871582,909.298645 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M279.718018,881.220276 
	C283.843689,881.245483 286.134064,884.171143 288.944458,886.656738 
	C288.853851,887.415710 288.366852,887.782959 288.613190,888.239868 
	C288.715363,888.429321 289.186462,888.294678 289.738281,888.066406 
	C291.378479,887.935730 292.649109,888.174133 293.703247,889.157593 
	C298.382874,893.523682 304.145355,895.778503 310.121063,897.660522 
	C310.806549,897.876465 311.292267,898.726807 311.937195,899.636597 
	C308.563446,900.603760 304.947266,897.606873 302.516876,898.512024 
	C298.798248,899.896973 295.560059,898.993835 293.426086,897.407166 
	C288.131653,893.470520 280.447418,892.239136 278.051697,884.407715 
	C278.049622,882.926331 278.591858,882.078430 279.718018,881.220276 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M251.010849,863.373779 
	C254.151566,863.031799 256.773102,864.463867 258.934021,866.523376 
	C261.581573,869.046509 264.245880,870.549133 268.074615,871.454590 
	C271.375000,872.235046 273.835846,876.565735 276.828735,879.621094 
	C277.228424,880.398071 277.116058,880.796021 276.344482,881.057861 
	C273.243347,882.143799 270.926300,881.580261 268.919250,879.366699 
	C264.023163,873.966919 259.317902,868.321350 252.097321,865.786316 
	C251.184769,865.465881 251.084702,864.584656 251.010849,863.373779 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M221.052612,837.341553 
	C223.142227,837.785095 225.928787,837.418091 226.954956,840.648193 
	C224.727325,840.442017 222.458466,839.877136 221.052612,837.341553 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M276.022400,881.318665 
	C276.252808,880.789917 276.515747,880.584534 276.884155,880.147278 
	C277.784790,880.193054 278.579926,880.470703 279.664459,880.882141 
	C279.999756,882.179199 279.569397,883.104248 278.300049,883.904785 
	C276.993011,883.550964 276.234406,882.831421 276.022400,881.318665 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M127.975441,795.359985 
	C129.323837,795.228577 131.233627,794.314697 131.709991,796.393982 
	C132.002182,797.669434 130.749191,797.893860 129.341248,797.988342 
	C128.117432,797.451721 128.004990,796.591431 127.975441,795.359985 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M160.414764,828.626221 
	C160.710022,829.821655 160.183090,830.397156 159.288788,830.577271 
	C158.705383,830.694824 158.305984,830.284729 158.439835,829.700317 
	C158.643600,828.810486 159.224808,828.294128 160.414764,828.626221 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M200.008423,256.681580 
	C200.999054,260.064636 202.590469,259.230927 203.648117,257.279205 
	C204.733093,255.277054 205.233109,252.957901 205.998840,250.396683 
	C206.639481,249.139282 207.762100,249.058456 208.769897,248.230408 
	C208.985489,248.017899 209.001007,248.001434 209.009460,247.993927 
	C209.546158,247.455078 210.080414,246.929779 210.807968,246.200409 
	C211.444992,245.998840 211.885101,245.997681 212.655273,245.993805 
	C213.380051,245.617065 213.846420,245.469681 214.690292,245.780533 
	C216.470276,247.245255 217.944366,248.478287 219.709229,249.855652 
	C220.667603,250.414215 221.493271,250.618591 221.937485,251.706696 
	C221.999847,252.749863 221.999710,253.498703 221.999176,254.621933 
	C223.423447,257.300720 221.519470,258.303528 220.156830,259.359985 
	C218.947250,260.297760 218.246231,261.248383 219.092468,262.694244 
	C220.018723,264.276825 221.409637,263.576538 222.629257,263.191528 
	C224.961533,262.455292 226.776886,261.084686 227.458115,258.605255 
	C228.252533,255.713760 230.042068,254.493439 233.022812,255.109344 
	C237.465225,256.027283 240.266617,253.843903 242.518829,249.920807 
	C243.149796,249.380630 243.570267,249.160950 244.369354,248.920639 
	C249.922974,250.836639 254.969818,249.098572 260.092072,248.291885 
	C264.107239,247.659576 269.359039,247.474030 270.793915,251.268707 
	C272.113953,254.759628 269.590729,259.146484 265.345825,261.596283 
	C264.182861,261.868591 263.303101,261.884583 262.220856,262.430786 
	C261.710938,262.617645 261.499268,262.673645 260.959229,262.760071 
	C257.292267,262.210175 254.755569,264.036224 251.827805,265.766235 
	C250.167618,266.354187 248.769714,266.350037 247.025177,266.351624 
	C245.974060,266.446777 245.380539,265.858856 244.876389,266.128021 
	C247.689011,267.027466 250.259186,267.473419 252.887680,265.289520 
	C255.396378,263.803589 257.798340,263.172028 260.709839,263.009460 
	C265.923920,264.641174 270.856110,265.049957 275.941223,264.506073 
	C279.024475,264.176331 279.174469,266.544220 279.135101,269.156860 
	C278.138306,272.601196 275.031708,273.136719 272.883453,274.792480 
	C270.680634,276.490295 268.289032,277.956421 266.240173,280.393829 
	C264.111969,282.523682 262.179810,284.336395 259.573608,285.873657 
	C254.209366,285.517303 249.657410,287.342041 245.064743,288.978851 
	C243.988159,289.362579 242.812180,289.958221 241.900864,288.806549 
	C241.032928,287.709686 241.662079,286.694977 241.890182,286.398376 
	C240.540131,291.367523 232.283691,292.049347 232.165085,299.372559 
	C232.027145,299.927795 231.954926,300.144409 231.720779,300.660583 
	C230.925842,301.888977 229.739899,302.223663 228.954803,303.472076 
	C227.265884,305.476532 225.395447,306.811127 223.536835,308.669067 
	C222.256454,309.605499 221.151611,309.966949 219.566498,309.743164 
	C216.934265,307.998291 215.647461,309.539307 214.118591,311.399048 
	C213.214783,311.964996 212.532852,312.144196 211.468658,312.259003 
	C208.962921,311.862732 207.033066,311.738586 207.132156,314.857239 
	C207.152878,315.508850 206.664032,316.668427 206.425018,316.609467 
	C200.203995,315.074829 200.625519,322.700043 196.349625,323.861572 
	C194.470840,324.371918 193.876923,325.878082 194.354538,327.816895 
	C194.731491,329.347198 194.097626,330.970642 192.538879,330.571259 
	C188.980194,329.659363 187.433792,332.013763 185.545746,334.047119 
	C184.190857,335.506287 181.952133,335.765839 180.506012,337.810699 
	C183.769974,338.760834 187.087311,337.454559 190.139084,339.082825 
	C193.051849,340.636963 194.613693,341.971252 191.994232,345.007233 
	C191.012039,346.145599 190.004639,347.361328 191.024994,349.012329 
	C194.148987,354.067047 193.853073,359.003754 190.610474,363.897247 
	C190.166458,364.567322 190.169479,365.491638 189.674057,366.566406 
	C189.088791,367.365814 188.362106,367.314636 187.931381,367.767639 
	C188.704681,367.369751 189.552185,367.260376 190.755341,367.164307 
	C193.947647,368.676056 197.328491,367.331879 200.330688,369.235657 
	C199.821411,373.946838 197.587601,377.626862 194.892929,381.418152 
	C194.749756,382.592102 195.021851,383.261108 196.184479,383.714111 
	C197.086700,384.097260 197.638611,384.443024 198.298294,385.195068 
	C200.969589,387.799103 201.852127,389.824371 199.664734,393.385437 
	C197.886688,396.280060 198.074478,400.337677 198.853973,404.286987 
	C199.496796,406.336975 200.374908,407.875366 201.764313,409.527649 
	C202.804108,411.071564 203.188019,412.433289 202.778488,414.288788 
	C202.525940,414.829865 202.389984,415.027557 201.960754,415.422546 
	C201.448975,415.692810 201.230438,415.765747 200.675385,415.881317 
	C199.080948,415.938141 197.799484,415.461395 196.295441,416.258026 
	C194.050690,419.888824 194.182739,422.524902 198.612411,423.916412 
	C199.928787,424.329956 201.521896,425.322571 201.295868,427.119080 
	C201.044876,429.114105 199.122086,428.808685 197.741974,429.142426 
	C196.286850,429.494354 194.847473,429.917511 193.372955,430.184021 
	C191.272232,430.563690 189.819016,430.795166 190.310928,433.957123 
	C190.681229,436.337463 189.245956,439.077209 185.922607,439.731262 
	C183.187485,440.269592 181.228973,443.739624 177.505005,441.972900 
	C175.236038,442.733765 173.527527,443.513184 176.244995,445.905212 
	C175.077286,449.095062 177.354126,451.782562 176.565506,455.054352 
	C175.448532,459.688385 173.560242,464.011047 172.192566,468.507202 
	C171.510803,470.748444 171.520706,472.250427 172.981781,474.235535 
	C173.911407,475.842865 175.471191,475.667542 176.833939,476.571014 
	C177.201065,477.004120 177.293503,477.221344 177.354599,477.795135 
	C177.114899,478.585876 176.841293,478.973053 176.194336,479.503540 
	C173.941605,480.274200 172.536652,481.138123 171.570526,483.284729 
	C169.730301,487.373657 166.823441,488.448456 162.642654,486.863525 
	C160.345688,485.992737 158.047623,486.381500 155.768799,486.048859 
	C154.328110,485.838593 152.647675,485.313477 151.853149,487.160675 
	C151.055069,489.016205 151.411102,490.814453 152.954483,492.148041 
	C155.113464,494.013519 156.471161,496.706482 158.589890,498.398041 
	C162.542908,501.553986 164.157364,505.491394 164.511505,510.621765 
	C164.631271,511.990601 164.311020,512.894348 163.531937,514.001343 
	C162.870987,514.544250 162.412613,514.788574 161.916122,514.804016 
	C156.415909,514.974792 155.644226,515.806030 156.174072,521.532532 
	C156.443207,524.441345 156.186890,526.892151 152.388580,527.567139 
	C151.606735,527.305969 151.224167,527.033691 150.694641,526.394104 
	C149.911102,525.011169 150.431412,523.151184 148.722412,522.894653 
	C146.949753,522.628662 146.254623,524.245361 145.431198,525.444763 
	C144.547104,526.732483 143.851135,528.160767 145.400818,529.375916 
	C147.688248,531.169556 148.809113,533.001831 145.962082,535.623352 
	C144.354752,536.297852 143.103577,536.203979 141.566895,535.368896 
	C138.855972,534.386719 137.603989,532.401794 136.110016,530.208435 
	C135.838257,529.747192 135.715378,529.595154 135.527237,529.173462 
	C135.279510,528.616699 135.757111,527.930664 134.819763,528.247437 
	C134.648849,528.833801 134.691147,529.194885 134.898331,529.846924 
	C133.690140,532.690857 130.049789,532.422729 129.115967,535.711609 
	C127.885567,534.217590 126.888802,533.203003 126.123154,532.036682 
	C124.535561,529.618347 122.657730,529.425842 120.969749,531.614685 
	C118.097069,535.339600 115.239265,533.659241 113.184181,531.284973 
	C110.965385,528.721558 113.709167,526.981934 115.697128,525.153076 
	C116.524544,524.456970 117.074570,523.938416 117.809189,523.203491 
	C119.648338,522.198364 120.419746,520.900024 119.106445,519.431213 
	C115.337151,515.215637 115.509758,510.417877 116.639542,505.394928 
	C116.707443,505.093018 116.714821,504.570099 116.542717,504.451630 
	C110.924194,500.583740 115.548691,496.709900 116.556557,492.885345 
	C117.642212,488.765625 117.156837,485.315216 113.088318,483.040009 
	C108.847420,480.668427 108.565308,477.453644 109.845680,472.890961 
	C111.274826,467.798096 112.239914,462.215332 111.675262,457.034546 
	C111.209717,452.762970 113.855095,448.059998 110.350624,444.146362 
	C113.858124,441.514465 109.645660,435.198486 115.430740,433.571777 
	C116.607452,433.240875 117.394287,431.972717 116.494003,430.785736 
	C115.056900,428.891022 114.040550,431.865295 112.251228,431.205902 
	C113.286629,427.100159 112.794556,422.835297 113.050201,418.601898 
	C113.292778,414.584869 115.111404,413.684906 118.529106,415.891327 
	C120.455566,417.135071 120.804222,416.193420 121.052589,414.608673 
	C121.711548,410.404144 123.970161,406.131805 119.625656,402.341156 
	C118.138786,401.043854 119.965729,399.705933 120.900047,398.602783 
	C124.423203,394.443115 129.836853,392.516693 133.174210,388.029144 
	C134.246979,386.586670 135.371124,385.473877 134.731079,383.726013 
	C131.475769,374.836151 134.623932,365.975677 135.448151,357.256500 
	C135.781143,353.733795 138.247116,349.604553 139.875107,345.897308 
	C143.453873,337.747620 144.097626,329.300415 145.020874,320.767761 
	C145.375000,317.495117 146.678360,314.852142 150.360611,314.268005 
	C146.898575,310.423248 148.477875,306.421326 150.746063,303.053528 
	C154.076431,298.108582 157.006348,292.982422 159.674255,287.680359 
	C160.440628,286.157349 161.282288,284.416626 163.027100,284.243042 
	C167.578659,283.790283 168.910492,280.066833 170.363678,276.909729 
	C171.611786,274.198181 173.084961,272.038849 175.798279,270.269135 
	C176.678604,269.200836 177.638687,268.844513 178.767670,268.194183 
	C181.436462,264.290527 178.777634,261.757385 176.374893,260.124786 
	C172.020782,257.166321 176.339157,254.507675 176.284927,251.795532 
	C176.229706,249.033569 178.809952,250.324341 180.524445,250.675659 
	C182.457367,251.071732 184.258255,250.491501 183.423569,248.197479 
	C182.537491,245.762207 184.443970,244.531509 184.957306,242.362091 
	C185.667969,241.337143 186.459641,241.245850 187.662598,241.753235 
	C189.269089,245.787521 191.123398,243.604782 192.174957,242.123123 
	C195.873566,236.911591 200.510025,232.561249 204.759735,227.858566 
	C206.091019,226.385361 207.399307,224.779312 209.035629,223.728470 
	C210.404282,222.849518 212.542343,220.380554 213.745422,223.598007 
	C214.368073,225.263214 216.997330,228.502991 212.197205,229.168472 
	C210.827225,229.358414 209.442566,230.379654 209.557999,231.737549 
	C209.719131,233.633026 211.615356,232.913025 212.797211,232.993103 
	C215.564590,233.180573 216.336792,235.185638 216.841843,237.321274 
	C217.222763,238.931976 216.656189,240.356079 214.883606,240.805328 
	C212.506989,241.407654 210.947479,240.565308 209.586624,238.344833 
	C206.988251,234.105133 206.022903,234.250595 203.778900,238.802490 
	C204.394150,239.380081 204.541275,239.838974 204.221588,240.687714 
	C205.555618,244.072388 204.209183,246.293854 202.284225,248.759201 
	C201.168015,250.188766 198.848404,250.900482 199.008011,253.633820 
	C199.741226,254.606094 200.117630,255.373428 200.008423,256.681580 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M263.778015,261.021515 
	C268.775543,259.750366 267.569153,254.454361 270.743561,252.084885 
	C264.437042,247.510986 258.328217,249.489471 252.409866,251.729019 
	C250.924667,252.291016 250.098633,252.765900 249.198380,251.372849 
	C248.336273,250.038849 247.058212,249.990555 245.338593,249.988556 
	C244.518295,249.473358 243.990005,249.302032 243.179123,249.826355 
	C241.840195,255.073318 237.716415,257.525391 232.878128,256.236359 
	C231.474609,255.862411 229.471588,255.485458 229.194229,257.519287 
	C228.271393,264.286102 223.050232,264.753662 217.989594,265.581665 
	C215.336624,260.707581 219.512115,258.547394 221.957092,255.339905 
	C226.300659,251.925964 231.246750,251.030334 236.669159,250.865540 
	C237.623749,251.269684 238.372742,251.787354 238.211151,250.238831 
	C237.954788,248.376511 238.185562,246.637695 237.759369,245.078476 
	C237.324814,243.488647 237.406769,241.180557 234.826050,241.058105 
	C232.690445,240.956772 231.021454,241.696548 229.726120,243.536102 
	C228.990814,244.580322 229.478531,247.253433 227.209366,246.403381 
	C224.798264,245.500183 222.643402,243.891174 220.435379,242.497314 
	C220.181259,242.336899 220.138992,241.520416 220.287476,241.113586 
	C220.547485,240.401291 221.153763,240.231262 221.880768,240.599976 
	C222.753464,241.042587 223.709167,241.878967 224.518936,241.764771 
	C225.929886,241.565811 227.740738,241.138947 228.505051,240.137085 
	C229.648712,238.637955 227.924530,237.468216 226.912003,236.438293 
	C225.665695,235.170563 224.488144,233.898849 226.207321,232.254715 
	C227.397263,231.116684 226.215897,228.339081 228.507111,228.345200 
	C230.662918,228.350983 229.603531,230.731613 230.260574,231.920013 
	C231.773315,234.656067 235.937408,234.669571 237.526505,231.833893 
	C238.813797,229.536774 239.804810,227.073608 240.992310,224.321503 
	C241.489639,223.969070 241.921906,223.979202 242.678909,223.994049 
	C244.560226,225.420044 246.888870,224.037048 248.721405,225.716293 
	C250.789825,225.626816 252.572342,225.315338 254.676819,225.002228 
	C255.951553,225.897461 256.061218,226.990509 255.712967,228.174026 
	C255.302460,229.569031 253.338684,231.149673 255.898911,232.248596 
	C257.856354,233.088806 259.972748,233.690308 261.943451,232.031723 
	C263.961639,230.333176 265.333679,228.005920 267.730804,226.209717 
	C274.693848,225.213852 281.070740,223.334778 287.150085,220.440430 
	C288.522461,219.787048 290.107605,220.079727 291.239990,218.264954 
	C292.549591,216.166138 292.997253,218.731461 293.877625,219.734589 
	C296.819244,223.446655 299.019226,219.738480 301.759705,219.190857 
	C302.012238,218.983276 302.001831,219.002396 301.991577,218.998749 
	C302.770050,218.591919 303.913910,219.637970 304.752441,218.167664 
	C309.727142,215.118484 313.913025,210.370941 320.671051,213.854568 
	C320.463226,216.537384 323.922546,215.147064 324.011475,217.065994 
	C323.438599,216.272491 323.124695,215.433472 323.780090,214.205475 
	C323.557892,212.323212 331.067413,213.895813 325.204041,210.083130 
	C324.316010,208.894287 324.443817,207.570038 323.458374,206.355743 
	C322.348480,205.973801 321.449036,205.789352 321.055725,204.382751 
	C321.070068,203.046616 321.679138,202.394714 322.747986,201.699982 
	C323.285004,201.537903 323.510712,201.516144 324.066833,201.566864 
	C325.532654,202.395248 326.848633,202.646133 328.461639,203.073105 
	C329.216156,203.331268 329.632324,203.521042 330.333984,203.919434 
	C330.813141,204.247406 330.994324,204.394318 331.401062,204.809067 
	C331.747437,205.275879 331.868286,205.474854 332.137299,205.987610 
	C333.202179,208.910339 335.402924,208.236923 337.299316,208.212433 
	C344.710663,208.116760 352.109039,207.537476 359.871063,207.591675 
	C362.291443,207.614899 364.337921,207.830322 366.631042,208.520096 
	C368.981964,208.013962 367.562561,205.752319 369.122803,204.637695 
	C372.958710,204.107315 375.963379,205.498672 379.113586,207.565094 
	C380.408905,212.425537 377.953827,215.007309 374.197266,216.708221 
	C371.639099,217.866531 368.818329,218.171051 365.742493,218.881409 
	C364.495209,219.247849 363.561066,219.417664 362.517456,220.219406 
	C362.001984,220.470856 361.783264,220.544403 361.219482,220.643982 
	C360.179932,220.599075 359.550323,220.339508 358.658630,219.837677 
	C356.717590,218.234711 354.918945,218.065308 352.677948,219.212128 
	C348.557343,222.807434 344.348938,223.683060 339.180145,222.721756 
	C333.917267,221.742935 328.611725,222.517273 324.399200,227.265228 
	C318.579193,230.493164 312.776855,232.029556 306.415344,231.592346 
	C302.490723,231.322632 298.916412,232.867538 295.294128,235.139160 
	C293.576935,236.231415 291.793945,236.091995 290.176544,237.279907 
	C289.490295,238.045364 289.009308,238.535126 288.177612,239.148193 
	C284.215240,239.657898 280.589386,240.139145 278.256226,236.141129 
	C277.664764,235.127609 277.057648,235.548248 275.132233,239.000916 
	C276.961426,237.955582 278.728638,237.359070 280.436218,239.595764 
	C280.532745,240.146957 280.525299,240.373428 280.421204,240.919540 
	C280.057129,242.873474 280.288757,244.516296 282.015839,242.426544 
	C283.863861,240.190506 286.002258,239.775787 288.894165,239.804871 
	C290.206177,239.650757 291.144623,239.768311 292.379242,240.242645 
	C293.496307,240.838989 293.654755,242.468735 293.981354,242.321869 
	C296.580994,241.153091 298.065643,242.340149 298.150665,244.673965 
	C298.237122,247.048294 296.825439,248.953186 294.597229,250.229431 
	C292.209259,251.597198 288.838013,250.752609 286.956268,253.873688 
	C285.766296,255.528870 284.044342,256.044891 282.847168,257.580566 
	C282.692596,258.020172 282.646637,258.199982 282.517517,258.641510 
	C282.292511,259.189789 281.925842,259.503693 282.166412,259.791138 
	C282.590546,260.297913 281.872589,259.842468 282.777069,259.685577 
	C284.282104,260.611267 285.304718,261.675690 286.512421,263.031219 
	C286.968475,264.008575 287.058411,264.718872 286.965454,265.825073 
	C286.530640,267.505066 285.708344,268.491669 284.354095,269.557190 
	C282.308685,270.537750 280.586090,271.256866 278.344116,270.364502 
	C277.878601,267.600952 279.220612,264.959381 273.933655,266.235596 
	C270.536133,267.055695 265.173920,267.658295 261.265503,263.968201 
	C260.567230,263.524231 260.459595,263.055542 260.817871,262.259033 
	C261.069611,261.980499 261.429138,261.996063 261.608398,262.011292 
	C262.278351,261.591339 262.805817,261.219177 263.778015,261.021515 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M134.897278,551.234009 
	C135.639832,551.015808 136.452332,551.124023 137.264038,551.237854 
	C138.616333,551.427246 140.138504,553.493103 141.197739,551.847778 
	C142.700058,549.514221 139.334320,550.414673 138.869125,549.190613 
	C138.642822,548.595154 138.136673,548.015381 138.147934,547.436096 
	C138.195587,544.984802 142.089096,543.696899 141.742935,543.294556 
	C139.755081,540.983643 140.404678,539.562988 142.824615,538.045410 
	C144.144882,537.300293 145.230560,537.278015 146.656586,537.680908 
	C148.033783,538.976929 147.644867,540.290344 147.368820,541.556702 
	C146.944077,543.505127 147.410110,544.741882 149.634506,544.954224 
	C152.765518,545.253113 153.331711,547.151611 152.381729,550.222717 
	C153.896515,549.390991 155.019577,548.163513 156.984924,548.572754 
	C159.679611,552.451355 161.528366,556.353699 160.839737,561.318237 
	C160.368011,562.331787 159.825882,562.814880 158.925873,563.439331 
	C157.597824,564.199219 156.586075,564.793701 155.220673,565.469482 
	C154.418427,565.657043 153.956238,565.621887 153.246323,565.231201 
	C153.148849,563.256165 153.309372,561.519653 153.513107,559.315552 
	C150.656311,560.401917 148.683136,563.009949 145.316772,563.072266 
	C143.022491,562.533142 142.158127,561.205383 142.412170,558.855957 
	C142.863831,558.161438 143.151428,557.783875 143.548431,557.066406 
	C144.037094,555.841675 144.729813,555.181641 145.464478,554.331787 
	C144.381454,554.598816 144.332382,555.529785 143.752869,556.417847 
	C143.545349,556.925049 143.477356,557.133667 143.235474,557.619934 
	C142.907364,558.050598 142.753128,558.203552 142.284088,558.437378 
	C140.728989,558.390503 140.158310,557.533020 139.701752,556.200317 
	C139.565399,555.545959 139.928055,555.435303 140.059265,555.191528 
	C139.765045,555.100281 139.539108,555.222046 139.108124,555.711914 
	C138.187805,556.082214 137.524612,556.119568 136.497833,555.965820 
	C135.420517,555.599670 134.873520,555.136719 134.828445,554.058044 
	C135.011765,553.014893 134.989319,552.288940 134.897278,551.234009 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M157.873016,246.293564 
	C159.846375,242.259872 164.678696,242.333405 166.918182,238.937592 
	C167.975723,237.334000 169.868927,238.169006 171.500183,238.661835 
	C172.680222,239.018311 175.097214,239.867905 175.050095,238.959991 
	C174.799423,234.129822 180.452850,231.872696 179.933044,227.281265 
	C179.411087,222.671127 181.935486,220.179260 185.674515,219.415054 
	C190.237961,218.482376 188.377304,223.330688 189.953064,225.620956 
	C188.847321,225.900940 187.596069,226.056625 186.560959,225.655106 
	C183.755203,224.566772 182.780746,225.712662 183.030121,228.336624 
	C183.295364,231.127762 182.591049,233.201462 179.723083,234.217133 
	C179.174042,234.411575 178.429626,235.112045 178.415924,235.597168 
	C178.275421,240.573792 174.683197,240.806717 171.000046,241.586517 
	C162.379211,243.411758 161.383759,245.270355 162.635391,255.294998 
	C162.717072,255.949173 162.867722,256.594727 162.992844,257.622131 
	C160.085632,257.293549 159.897644,254.621384 159.104034,252.451523 
	C158.691986,251.324860 159.323380,249.789047 157.361053,249.077133 
	C157.248459,248.195709 157.496948,247.391418 157.873016,246.293564 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M223.659836,212.420471 
	C227.402725,218.391830 226.637283,219.770248 220.073166,220.027328 
	C216.903687,220.151443 213.992020,221.102951 212.448929,216.536469 
	C211.590469,213.996109 211.534607,212.780823 213.355972,211.093094 
	C215.590622,209.022461 217.509842,206.169235 220.342270,210.495239 
	C220.903763,211.352798 222.324875,211.647507 223.659836,212.420471 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M142.756134,558.000977 
	C142.773132,559.802307 143.584839,561.181763 144.847122,562.766602 
	C144.309845,565.147095 146.725632,567.335205 144.992172,569.229736 
	C141.339722,573.221619 138.610718,569.093018 136.043060,567.581055 
	C134.092758,566.432617 132.009338,563.785645 135.846222,561.285522 
	C135.869537,560.829224 135.743622,560.665039 135.806030,560.254272 
	C135.922516,559.673340 135.850693,559.338989 135.652740,558.507507 
	C135.722275,558.000854 135.917923,557.991333 136.406265,558.015015 
	C136.893845,558.076477 137.088699,558.104797 137.578568,558.158081 
	C139.082245,558.198486 140.298080,558.056824 141.746445,558.175232 
	C141.998718,558.002441 142.485535,557.981445 142.756134,558.000977 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M152.835022,565.344727 
	C153.389648,564.987854 153.790985,564.983032 154.493134,564.971313 
	C155.842636,565.272095 156.807404,564.863037 158.118210,564.593384 
	C159.412949,564.736145 160.063324,565.334412 160.726761,566.440552 
	C161.028000,567.202881 161.145554,567.656372 161.295013,568.463501 
	C161.386353,569.042175 161.418304,569.274353 161.520325,569.850220 
	C162.562881,572.565491 166.040634,574.688293 161.974426,577.544067 
	C161.415878,577.501892 161.201065,577.441467 160.716187,577.202637 
	C159.849304,576.482666 159.236649,575.993164 158.054565,576.472412 
	C157.326706,576.605713 156.910416,576.552551 156.261383,576.201904 
	C155.901108,575.156738 155.783035,574.326172 155.469467,573.247314 
	C153.660965,573.916809 153.125565,572.591248 152.211090,571.318298 
	C152.114670,569.224182 151.650742,567.365356 152.835022,565.344727 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M129.336624,568.961792 
	C127.225334,569.130981 126.300430,567.370178 124.379341,567.026245 
	C126.126251,565.090088 122.373459,563.583252 123.847656,561.490479 
	C123.989639,560.376160 124.410385,559.644531 124.813324,558.584839 
	C128.436356,553.509460 129.197739,557.573425 130.470413,560.011902 
	C130.606995,560.273499 130.793839,560.508911 130.957367,560.756470 
	C130.471497,560.844604 129.988327,560.985413 129.499222,561.010681 
	C128.228394,561.076233 127.184792,561.355652 127.111839,562.922791 
	C127.040573,564.453674 128.169205,564.749146 129.261673,565.235046 
	C131.389709,566.181519 133.305161,567.276611 129.336624,568.961792 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M231.878464,213.909348 
	C233.290665,213.474625 234.536560,212.780960 234.845459,214.612396 
	C235.126205,216.277023 234.405731,217.557175 232.676270,217.820053 
	C231.310638,218.027634 229.629654,218.628677 228.652115,217.192917 
	C227.975922,216.199768 229.084030,215.435699 229.810013,214.847412 
	C230.294128,214.455109 230.949524,214.274155 231.878464,213.909348 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M188.262573,241.764404 
	C187.249390,242.004425 186.499985,242.003479 185.375916,242.003723 
	C183.790405,238.018967 188.109634,237.608109 189.531082,235.312210 
	C189.855194,234.788696 191.261353,236.390121 190.557083,237.181427 
	C189.396759,238.485214 189.115936,240.042557 188.262573,241.764404 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M235.955856,198.646912 
	C236.000000,199.000000 235.999649,199.499405 235.999512,199.749084 
	C235.368164,200.148468 234.727676,200.267334 234.107559,200.453934 
	C232.563416,200.918579 230.609039,201.811447 229.679596,200.186630 
	C228.810760,198.667740 230.765625,197.661285 231.841232,196.840103 
	C233.750641,195.382339 235.157715,196.112274 235.955856,198.646912 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M116.310638,550.869080 
	C115.115707,550.983093 113.889549,550.151428 114.400055,549.856018 
	C117.357399,548.144531 113.668945,544.682739 116.319138,543.207214 
	C117.356430,542.629761 117.665779,543.811157 117.827217,544.569702 
	C118.125229,545.970032 118.297371,547.397217 118.268173,549.053467 
	C117.515572,549.749878 117.182640,550.360901 116.310638,550.869080 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M105.250397,528.827637 
	C105.312706,528.039062 105.326797,527.427551 105.639023,527.141846 
	C106.695358,526.175354 107.618210,524.725647 109.155434,524.781555 
	C110.065208,524.814636 110.180244,526.237000 109.690704,526.782715 
	C108.562119,528.040833 108.125648,530.876038 105.250397,528.827637 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M151.898468,571.347290 
	C153.381485,570.816101 153.841919,572.046875 154.968109,572.858398 
	C154.150513,574.232178 153.920929,576.865417 151.061890,575.300354 
	C150.663971,573.752380 151.121384,572.690552 151.898468,571.347290 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M146.475128,537.714233 
	C145.407761,538.295349 144.569366,538.951111 143.408600,538.113708 
	C142.522400,537.238892 141.883606,536.479370 141.249664,535.424744 
	C142.567307,535.082520 143.880112,535.035339 145.592758,535.001221 
	C146.688568,535.652283 146.830795,536.453796 146.475128,537.714233 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M131.892883,550.095825 
	C131.780838,552.002014 132.505844,554.486572 129.678085,553.611084 
	C127.689827,552.995483 127.903656,551.487671 129.792160,550.119629 
	C130.610352,549.611877 131.173218,549.508972 131.892883,550.095825 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M134.731506,553.764832 
	C135.157181,554.434998 135.429810,554.977783 136.590485,554.988525 
	C137.536301,555.583801 137.776413,556.287231 137.576691,557.474915 
	C137.124817,558.275208 136.693085,558.521179 135.894226,558.293213 
	C135.634659,558.008728 135.580856,558.000000 135.553741,558.005127 
	C135.526642,558.010315 135.493454,558.356201 135.652710,558.425659 
	C134.841156,558.898132 134.038269,558.532654 133.358612,557.492065 
	C133.642944,555.919800 133.394684,554.657959 134.731506,553.764832 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M200.288300,256.860962 
	C199.132401,256.461548 199.003967,255.594513 199.001297,254.335449 
	C199.868835,253.793655 200.823227,252.665680 201.598175,253.823868 
	C202.402176,255.025421 201.517868,255.948792 200.288300,256.860962 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M218.259384,233.092041 
	C219.009766,232.092651 219.817184,231.870834 220.424011,232.689987 
	C220.862015,233.281250 220.440445,234.007690 219.841217,234.341156 
	C218.873337,234.879791 218.324173,234.426651 218.259384,233.092041 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M204.350555,240.991425 
	C204.000595,240.554611 204.000580,240.110443 204.000885,239.333130 
	C204.942673,238.784348 206.162445,237.473587 206.717987,239.159149 
	C207.198425,240.616806 205.885666,240.921051 204.350555,240.991425 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M233.435455,225.021332 
	C232.640656,226.086029 231.756531,226.142410 230.887329,225.565613 
	C230.432480,225.263748 230.439743,224.720825 230.905289,224.429657 
	C231.784668,223.879654 232.668472,223.924423 233.435455,225.021332 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M168.914856,274.018127 
	C168.775467,275.033783 168.337265,275.790314 167.676285,275.305176 
	C166.764496,274.635895 166.763565,273.421478 167.663147,272.743988 
	C168.316635,272.251831 168.765717,273.002594 168.914856,274.018127 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M237.902649,250.256683 
	C238.929901,250.426529 240.683029,248.432892 240.730499,250.414566 
	C240.784286,252.661041 238.811096,252.025681 237.165466,251.282074 
	C237.294815,250.905136 237.570282,250.733307 237.902649,250.256683 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M222.239899,251.807465 
	C221.415726,251.510101 220.831451,251.019165 220.123596,250.264114 
	C221.611954,249.331238 223.432953,248.341263 222.239899,251.807465 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M124.812271,550.838745 
	C123.604584,550.600342 122.994705,550.083313 123.971268,549.329529 
	C124.558197,548.876526 125.633377,548.721680 126.091278,549.512024 
	C126.441170,550.115906 125.705910,550.557922 124.812271,550.838745 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M135.236206,530.068848 
	C134.076828,529.963745 132.945404,530.041504 132.302109,529.518677 
	C131.834229,529.138428 132.735428,528.494324 133.632050,528.125366 
	C134.589722,527.987061 135.123352,528.174866 135.761749,528.792603 
	C135.916992,529.040955 135.955353,529.469238 135.958237,529.684204 
	C135.961121,529.899170 135.473297,529.994446 135.236206,530.068848 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M208.634521,248.016541 
	C208.911240,249.465393 208.054291,250.012680 206.358505,250.006775 
	C206.081985,248.570175 206.916428,247.989838 208.634521,248.016541 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M124.649063,558.379395 
	C126.136520,559.392151 125.760925,560.239563 124.251923,561.092163 
	C122.259384,560.056702 123.651192,559.305481 124.649063,558.379395 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M178.648285,268.006287 
	C178.533615,269.036530 177.808548,269.758636 176.319977,269.970795 
	C176.093384,268.560822 176.933670,267.984741 178.648285,268.006287 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M116.165833,551.193237 
	C116.478439,550.434509 116.970474,549.902100 117.738449,549.331787 
	C117.785667,550.259644 118.443130,551.921326 116.165833,551.193237 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M215.092560,245.743469 
	C214.549454,246.009720 214.102707,246.007233 213.320648,245.997925 
	C213.120499,245.020157 213.362457,244.073486 214.598358,244.144943 
	C215.456604,244.194550 215.424789,244.835648 215.092560,245.743469 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M120.445259,563.628662 
	C120.663651,564.403076 120.391823,564.682373 119.791412,564.563599 
	C119.666565,564.538879 119.498375,564.362122 119.479439,564.236023 
	C119.388275,563.628967 119.683678,563.374817 120.445259,563.628662 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M239.767258,218.140778 
	C241.057617,218.873032 240.846466,219.517059 239.292130,219.970825 
	C239.176254,219.444489 239.352051,218.883484 239.767258,218.140778 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M131.445358,371.628906 
	C131.663773,372.403503 131.392685,372.683899 130.791397,372.564697 
	C130.666397,372.539917 130.498093,372.363037 130.479172,372.236847 
	C130.388092,371.629669 130.683044,371.374725 131.445358,371.628906 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M222.617493,224.545166 
	C223.402557,224.325333 223.689148,224.599686 223.569824,225.210037 
	C223.545044,225.336716 223.365189,225.507782 223.237167,225.526871 
	C222.620956,225.618866 222.362869,225.317566 222.617493,224.545166 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M117.689224,523.045410 
	C117.919128,523.903198 117.659752,524.681763 116.316971,524.954468 
	C116.083313,524.095764 116.335701,523.311951 117.689224,523.045410 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M134.275787,546.959778 
	C134.092590,546.364685 134.067398,545.632324 134.887955,545.504089 
	C135.064194,545.476562 135.300964,545.836304 135.509827,546.017639 
	C135.197052,546.325012 134.884277,546.632385 134.275787,546.959778 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M109.365959,493.667084 
	C110.319893,493.300476 110.946144,493.441833 110.779366,494.307434 
	C110.712914,494.652313 110.219032,495.109436 109.863083,495.167358 
	C108.812897,495.338348 109.164192,494.485992 109.365959,493.667084 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M210.687531,246.044693 
	C210.917038,246.893295 210.671967,247.674667 209.334030,247.944763 
	C209.083221,247.079300 209.348984,246.315094 210.687531,246.044693 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M134.005493,547.009033 
	C133.391922,547.919983 132.500412,548.100159 131.511566,547.888062 
	C131.317291,547.846436 131.184250,547.519165 131.022949,547.323608 
	C131.787811,547.210144 132.552689,547.096680 133.648819,546.981445 
	C133.980087,546.979797 133.998306,546.998535 134.005493,547.009033 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M132.341492,550.405762 
	C131.591003,550.361816 131.179337,550.194397 130.431183,549.999634 
	C131.100555,548.875244 132.058289,548.179626 132.341492,550.405762 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M123.667053,567.041504 
	C123.496223,567.339905 123.037933,567.646606 122.287766,567.946655 
	C122.457123,567.643311 122.918350,567.346558 123.667053,567.041504 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M133.445190,557.489075 
	C134.170944,557.267822 134.909088,557.494324 135.729584,558.107910 
	C134.905792,558.820862 134.049271,558.937256 133.445190,557.489075 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M203.746613,250.592468 
	C203.865021,250.443878 204.066391,250.423431 204.267776,250.402985 
	C204.121704,250.508865 203.975647,250.614731 203.746613,250.592468 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M134.924530,550.904053 
	C134.751572,550.725891 134.607697,550.611816 134.463806,550.497742 
	C134.550293,550.542542 134.636780,550.587402 134.775467,550.768677 
	C134.827667,550.905151 134.953613,550.968201 134.924530,550.904053 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M114.542252,552.474121 
	C114.569267,552.521851 114.515244,552.426331 114.542252,552.474121 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M135.837418,560.097778 
	C135.994354,560.007629 135.995270,560.500610 135.995361,560.747009 
	C135.890457,560.724915 135.785477,560.456421 135.837418,560.097778 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M217.526550,243.543701 
	C217.476898,243.571899 217.576187,243.515518 217.526550,243.543701 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M122.576134,590.422546 
	C121.563835,590.856201 120.643242,590.959229 119.377625,590.981689 
	C117.958626,589.452820 118.443275,587.944763 119.874664,587.690125 
	C120.833153,587.519592 123.496140,587.289551 122.576134,590.422546 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M199.546967,765.281006 
	C200.650833,764.119568 201.752502,763.610657 202.612198,764.852844 
	C203.228302,765.743042 202.798935,766.887573 201.825394,767.338013 
	C200.172485,768.102966 199.442245,767.214783 199.546967,765.281006 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M160.893219,729.994507 
	C160.609497,731.534973 159.816620,732.224609 158.791183,731.480652 
	C157.708588,730.695068 157.705811,729.313049 158.782791,728.521545 
	C159.802719,727.771912 160.601364,728.454163 160.893219,729.994507 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M201.392090,774.406250 
	C199.970688,774.899170 199.130188,774.617676 199.307343,773.227600 
	C199.352768,772.871216 199.892960,772.341431 200.250244,772.302856 
	C201.637604,772.153259 201.915604,772.990662 201.392090,774.406250 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M119.055939,577.341003 
	C120.144173,577.012939 121.070564,577.446777 121.854507,578.713623 
	C120.865784,578.938171 119.601997,579.160583 119.055939,577.341003 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M164.448135,734.623779 
	C164.671371,735.412292 164.392593,735.694214 163.783142,735.575195 
	C163.656265,735.550415 163.484787,735.370239 163.465698,735.242126 
	C163.373825,734.625488 163.673141,734.365540 164.448135,734.623779 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M141.554947,667.371582 
	C141.336609,666.597351 141.607880,666.317444 142.208588,666.436523 
	C142.333481,666.461243 142.501648,666.638000 142.520569,666.764160 
	C142.611649,667.370972 142.316772,667.625671 141.554947,667.371582 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M146.368134,257.042511 
	C146.307190,255.574722 146.611328,254.152084 146.957733,252.365326 
	C149.799057,252.024200 152.264206,253.071716 151.621017,256.166840 
	C151.036224,258.980927 148.558044,257.627197 146.368134,257.042511 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M150.704254,260.025055 
	C150.809753,260.892181 150.843201,262.039795 149.175980,261.218933 
	C149.469650,260.682953 149.939087,260.365906 150.704254,260.025055 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M225.000427,819.387085 
	C225.463364,819.145325 226.209869,819.159607 226.347015,819.455872 
	C228.009735,823.048218 230.024063,825.672180 234.492371,823.174744 
	C234.680679,823.069580 235.394333,823.904297 235.932541,824.649292 
	C236.283463,826.219543 236.291336,827.400146 235.239044,828.728149 
	C228.187866,831.356812 234.831177,833.863647 234.959778,836.648682 
	C231.763412,837.054016 230.255981,835.453247 229.930130,832.232239 
	C229.665817,829.619507 229.080200,826.978821 226.315399,825.162964 
	C225.667374,823.258057 225.334122,821.516113 225.000427,819.387085 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M226.673401,829.540527 
	C227.925888,830.825745 228.512726,831.997925 226.709869,832.763855 
	C225.806213,833.147766 224.626938,833.064636 224.326477,831.874939 
	C223.933380,830.318481 224.715286,829.425476 226.673401,829.540527 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M280.202179,776.382690 
	C279.807251,775.493469 279.501312,775.023987 279.092468,774.286133 
	C277.596039,770.405945 274.972961,767.034424 277.001770,762.492004 
	C277.674652,761.700256 278.246246,761.215149 278.905396,761.303040 
	C281.770111,761.685181 280.062714,760.329407 279.494476,759.146973 
	C278.800751,752.649048 275.832611,747.080261 274.778839,741.007568 
	C274.316040,738.340515 273.706726,735.850464 275.533997,733.391846 
	C277.124451,731.251831 276.088257,728.924805 275.568237,726.669678 
	C275.296356,725.490479 275.265472,724.281555 276.529419,723.613525 
	C277.483948,723.109009 278.572388,723.207642 279.326263,723.949707 
	C281.488647,726.078430 284.595673,726.261169 285.809479,724.209229 
	C287.196899,721.863708 288.667480,718.226929 285.181183,715.749146 
	C283.048950,714.233765 283.198792,711.676697 282.035980,709.391785 
	C278.382141,711.367920 278.745880,714.924377 278.219696,718.020630 
	C277.920349,719.781921 278.358917,722.005066 276.009094,722.510193 
	C273.424316,723.065674 272.697388,720.751892 271.786163,719.051453 
	C270.054993,715.821167 268.849884,712.356506 267.454651,708.600708 
	C267.516022,704.174622 270.433960,703.386902 273.574097,703.580994 
	C275.638123,703.708557 277.332458,703.827942 277.887817,701.511414 
	C278.493500,698.984924 276.200378,699.369934 274.870972,698.573853 
	C270.984375,696.246338 270.916290,691.669861 269.170837,687.706909 
	C268.239014,683.565613 267.398560,679.803711 272.219177,677.821106 
	C272.794281,677.584595 272.512482,678.076721 272.565521,677.541199 
	C273.064819,672.498718 278.132446,668.752502 276.600006,663.145142 
	C275.844910,660.382019 276.618866,658.889465 280.102081,659.813232 
	C280.596741,660.076050 280.782349,660.198181 281.208405,660.542175 
	C282.009766,661.351624 282.402039,662.120605 283.535004,662.454895 
	C284.260468,662.459839 284.681976,662.454834 285.430481,662.532532 
	C285.948334,662.712036 286.139496,662.830933 286.518890,663.234985 
	C286.556122,668.076477 286.580475,672.612000 288.457092,677.246216 
	C288.796753,677.955627 288.955811,678.373657 289.198608,679.109131 
	C290.275970,681.817871 291.555115,681.301941 293.048798,679.493530 
	C293.381378,678.535889 293.724457,677.960999 294.496765,677.262085 
	C296.304504,676.337952 297.654999,675.229980 299.765198,674.968384 
	C300.634827,674.984375 301.118866,675.084412 301.908295,675.437744 
	C305.539673,679.280884 302.453156,682.158325 300.988770,685.459229 
	C300.926849,685.676392 300.909424,686.047607 300.972778,685.888184 
	C302.078156,684.296448 303.243561,684.100769 304.750916,685.632690 
	C305.367737,686.637085 305.992188,687.099548 307.156128,687.328003 
	C307.647797,687.538696 307.832855,687.653564 308.172729,688.059937 
	C308.096375,691.862671 305.450562,695.449158 308.736816,699.167358 
	C308.881256,700.924316 308.793457,702.394409 309.708252,703.995483 
	C310.325378,705.898560 310.775238,707.521362 310.858948,709.512329 
	C310.743195,710.328125 310.422363,710.805481 310.749390,711.525513 
	C311.224304,711.927246 311.467773,712.192322 311.861145,712.697998 
	C312.280304,713.204712 312.548309,713.470154 313.087402,713.875488 
	C313.693054,714.254028 314.025391,714.502869 314.549774,715.032776 
	C315.503021,716.620605 315.598969,717.914978 314.285248,719.378723 
	C310.565460,720.871521 310.595551,723.201599 312.199524,725.879517 
	C314.279144,729.351562 313.838776,731.964050 310.117798,734.055359 
	C307.228729,735.679016 306.411560,737.642639 308.874969,740.839966 
	C311.019958,743.624023 311.828003,747.284546 311.086792,751.258301 
	C309.685883,752.229309 308.439392,752.264282 306.787689,751.970520 
	C305.945251,751.688232 305.507782,751.442566 304.826904,750.896545 
	C300.962921,747.969238 298.495728,744.272766 295.440308,740.621826 
	C292.954559,738.434387 292.496948,735.697571 291.809692,732.679565 
	C291.575928,731.172058 291.728058,729.997864 291.486694,728.737427 
	C291.240692,730.217163 291.418518,731.614746 291.164673,733.321960 
	C291.041534,733.871216 290.974274,734.085083 290.756592,734.591431 
	C290.471466,735.049683 290.336823,735.215332 289.967346,735.585205 
	C289.284943,736.160095 288.746094,736.440369 288.467896,737.309448 
	C288.360962,738.413147 288.535522,739.204346 288.712555,740.328613 
	C288.715332,741.115356 288.604065,741.551392 288.207153,742.244934 
	C286.894653,743.391846 285.719238,743.466675 284.817688,743.008057 
	C285.790039,748.011108 289.907745,752.616516 287.212463,758.716309 
	C286.775787,759.294556 286.147400,759.306396 286.166992,759.760498 
	C286.181183,760.088989 286.503510,760.020569 286.998108,759.545898 
	C289.634430,760.377136 289.929871,762.626831 290.863525,764.867065 
	C291.412231,766.663208 291.283844,768.223633 291.982422,770.018372 
	C292.560089,772.869629 292.285858,775.272034 291.333923,778.030518 
	C287.676361,782.580200 284.751923,782.142883 280.202179,776.382690 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M207.428802,664.227783 
	C207.118393,663.828369 207.037369,663.668945 207.051514,663.258850 
	C208.864166,660.833252 211.632599,658.408020 205.782303,658.385132 
	C204.008240,658.378174 203.151855,656.931274 203.021530,655.361267 
	C202.760880,652.221252 203.460144,649.106079 203.555756,645.961548 
	C203.604446,644.360168 205.197632,643.992310 206.612244,644.200867 
	C208.296951,644.449097 209.956192,644.891052 210.703827,642.343750 
	C210.863312,641.838562 210.982300,641.656067 211.333252,641.244995 
	C214.276489,639.420349 212.723190,636.748901 213.279724,634.260864 
	C216.305084,630.632263 212.681244,627.019287 213.801971,623.302124 
	C213.521713,620.760986 211.757843,618.958069 212.145432,616.299561 
	C212.385818,615.702515 212.602859,615.470886 212.843430,615.256348 
	C212.902283,615.203857 212.849777,615.043091 212.894272,614.955322 
	C213.195831,614.360840 212.017426,614.995789 212.657822,614.810486 
	C212.661606,614.809448 212.722992,614.958374 212.599396,615.464294 
	C211.294708,616.730042 210.033142,617.031250 208.280151,616.496582 
	C207.821091,616.141785 207.676804,615.952209 207.438797,615.418396 
	C207.353271,614.837830 207.361481,614.601440 207.477280,614.028809 
	C208.586792,612.179260 209.851898,610.888916 211.055206,609.542542 
	C212.272064,608.180847 210.589630,608.078308 209.858459,607.199951 
	C209.369812,606.226685 209.448257,605.551086 210.032410,604.628418 
	C213.123093,601.351257 216.916718,598.746460 213.583237,593.655640 
	C212.603775,592.159790 213.645157,590.129028 213.853973,588.003906 
	C215.429367,583.131592 213.394943,578.788574 213.167358,574.350098 
	C213.035034,571.769348 213.522247,569.656677 216.135971,568.680176 
	C218.720505,567.714722 220.107681,569.710571 221.621094,571.197998 
	C223.028229,572.580994 223.290039,574.398132 221.765305,575.585266 
	C218.487473,578.137268 220.066208,580.889709 221.033249,583.648560 
	C222.610901,588.149475 227.031982,591.216919 227.452698,596.323181 
	C227.530579,597.268311 228.160172,597.211731 228.849640,596.895264 
	C230.451660,596.159912 231.455566,596.853943 231.884659,598.373291 
	C232.406631,600.221497 232.496597,602.404846 230.686127,603.329102 
	C227.870865,604.766296 227.674011,606.096252 229.400452,608.623047 
	C230.402100,610.089050 229.369095,612.219055 229.846710,614.365356 
	C230.022934,615.402466 230.038177,616.099304 229.927765,617.140015 
	C230.014984,618.565063 228.033630,619.267273 229.573288,620.765381 
	C232.595947,626.667664 232.486832,627.555969 228.037415,631.789429 
	C226.342148,633.402466 225.510162,635.573120 224.097610,637.377258 
	C222.489395,639.431274 224.577042,640.111206 226.280212,641.013184 
	C227.340836,641.468079 228.063553,641.947632 229.262848,641.548035 
	C231.031662,640.683960 232.752243,639.571350 233.477066,642.026611 
	C234.014496,643.847046 233.204361,645.590698 231.454651,646.585083 
	C229.963318,647.432617 228.286835,647.117004 226.344940,647.122314 
	C224.289566,648.975830 224.600159,651.260925 224.490799,653.777954 
	C224.487778,654.350830 224.457504,654.579956 224.287491,655.126221 
	C222.697754,657.660461 222.805099,661.037292 219.235229,661.693787 
	C218.135193,661.896118 217.680847,663.151855 218.376480,664.060608 
	C219.218872,665.161072 220.362778,664.368774 220.859055,663.614868 
	C222.497269,661.126099 224.056900,658.598633 226.500549,656.337158 
	C227.260208,655.143005 227.213821,654.013611 227.909668,652.818542 
	C229.077576,652.134949 230.056335,652.245911 231.011475,652.405457 
	C234.298080,652.954407 235.941086,651.628784 235.963547,647.926453 
	C236.447403,646.730347 236.844803,645.879883 237.635010,644.851685 
	C238.517685,644.406433 237.660568,644.504333 238.306610,644.339783 
	C249.062302,641.600159 249.417816,640.983582 247.190506,629.777344 
	C246.657303,627.094666 245.194305,624.323608 247.806824,621.527954 
	C249.024994,620.897339 249.982117,620.856567 251.302490,621.154297 
	C253.205933,622.207458 254.491592,623.507080 256.119354,624.854614 
	C256.703369,625.171936 255.949173,626.163879 257.136322,625.638855 
	C259.005005,625.220581 260.214783,624.115601 262.006836,623.464417 
	C263.642822,623.627991 264.543243,624.387146 265.449371,625.696655 
	C265.862061,626.821045 266.059418,627.770691 265.534576,628.405701 
	C262.792480,631.723022 263.483154,635.116943 265.355713,638.821045 
	C266.303497,639.942749 267.347717,640.654785 266.917175,642.338623 
	C266.320587,643.537964 264.952179,643.709106 264.820892,644.675476 
	C264.006622,650.669373 263.903046,650.740906 257.815979,647.635254 
	C253.073212,644.320374 252.916107,644.356445 249.865601,648.572510 
	C248.601364,650.319702 247.117294,651.891174 245.519196,653.329407 
	C244.304504,654.422607 243.508759,655.391846 244.549164,657.122131 
	C246.099075,659.700012 243.882675,659.716064 241.994415,660.164734 
	C238.603165,664.195984 240.948288,668.478088 240.968536,672.629211 
	C241.905838,672.473877 241.657822,671.282898 242.735260,670.791504 
	C243.302521,670.589050 243.551239,670.566833 244.165741,670.651794 
	C247.931961,673.707214 250.299698,676.962830 247.533798,681.971924 
	C243.586014,684.243408 240.622818,681.465332 237.456390,680.553101 
	C231.658157,678.882690 222.849152,681.366211 219.177200,686.186951 
	C216.430099,689.793518 215.552917,689.444824 212.371857,683.282959 
	C211.731567,680.608337 211.322586,678.224854 210.922791,675.433350 
	C210.975128,674.136536 211.027100,673.246033 210.218796,672.236877 
	C209.027573,671.494080 209.092880,670.535156 208.980804,669.332031 
	C208.930222,667.376282 209.734299,665.492371 207.428802,664.227783 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M459.248840,871.146606 
	C455.987183,870.865417 454.917877,865.730042 450.839722,868.073364 
	C450.570679,868.227905 448.964172,866.976746 448.765869,866.183044 
	C447.485077,861.056213 443.279236,860.750000 439.211914,859.935791 
	C433.832947,858.858948 428.739319,856.857666 423.386597,854.454590 
	C421.275299,854.767883 420.518768,853.361633 419.314941,852.126343 
	C417.969116,850.636963 416.826691,849.437500 415.420990,848.067627 
	C414.756042,847.596680 414.441803,847.222900 414.131470,846.415161 
	C412.557190,841.492493 409.211884,837.956116 407.266113,833.543030 
	C405.411652,829.337219 404.239532,824.782593 401.751801,820.839050 
	C401.124329,819.844421 400.480530,818.237366 401.199158,817.561584 
	C405.026581,813.961914 400.888824,811.701782 399.862488,809.042175 
	C398.974854,806.741943 397.328217,804.717896 398.244080,801.690308 
	C398.552887,801.175598 398.713776,800.995422 399.224518,800.729980 
	C401.016479,800.980530 401.788635,802.307495 403.303314,803.151001 
	C407.144653,807.545410 412.038361,810.672241 414.208069,816.415405 
	C414.616058,816.780090 414.783539,816.920105 415.191772,817.278564 
	C416.040985,818.100769 416.665894,818.682800 417.756287,819.184692 
	C419.791779,820.149109 420.888672,822.186890 423.425629,822.066345 
	C423.957886,820.424133 422.656647,819.858398 421.419342,818.926636 
	C420.570465,818.293579 420.160889,817.730530 419.604248,816.436035 
	C419.366638,815.530823 419.352600,815.295898 419.361481,814.710693 
	C419.772430,812.310486 419.632965,810.213806 420.002594,807.810547 
	C420.135162,807.237732 420.199554,807.010681 420.378601,806.449463 
	C421.128967,800.323853 426.139404,795.774597 432.133850,796.039185 
	C433.122040,798.148193 432.912445,800.118652 433.169312,802.383179 
	C433.117096,803.210510 433.026703,803.670654 432.773621,804.440491 
	C431.975647,805.898682 431.441467,807.052551 432.395844,808.661133 
	C432.497803,809.200806 432.509888,809.421997 432.469727,809.970703 
	C431.980072,813.722839 432.909760,816.694580 435.589233,819.421814 
	C436.585358,820.658447 436.949921,821.772583 436.939819,823.365234 
	C435.723877,826.099426 437.818756,828.172363 437.392944,830.778931 
	C436.675171,831.511475 435.931458,831.829651 435.387115,831.456177 
	C432.122101,829.216003 430.662781,831.148193 429.501892,833.887390 
	C434.638794,837.114624 438.768829,841.114624 441.447510,846.784424 
	C443.642761,851.431030 447.103516,855.410950 450.875916,858.932861 
	C452.714539,860.649292 455.281830,861.230408 457.861755,860.659546 
	C459.317230,860.337463 460.708374,859.711731 462.558594,860.066162 
	C463.147339,860.306396 463.351593,860.560913 463.604004,860.731079 
	C464.155334,861.103027 463.396851,860.592468 463.988800,860.858765 
	C464.302917,861.000000 464.315338,860.773621 463.569427,860.552185 
	C462.649414,859.369202 461.991272,858.342346 460.665283,857.492920 
	C459.906738,856.810913 459.521179,856.250427 459.065338,855.314331 
	C456.877197,850.153748 454.100220,846.017517 448.783142,844.259827 
	C446.733002,843.582092 445.393036,842.275269 445.401855,839.628296 
	C446.617126,837.864075 447.044250,836.148071 447.664124,834.146240 
	C449.240784,833.430603 450.217194,834.200378 450.830505,835.161987 
	C455.714935,842.820496 463.423096,847.826233 469.113800,854.722412 
	C470.481842,856.380188 472.153564,857.818909 472.291656,860.517090 
	C470.827118,862.817627 471.916473,864.136230 473.280640,865.528442 
	C475.183655,867.470520 475.204376,869.670227 473.621002,871.824097 
	C472.100067,873.892883 470.211578,873.347473 468.290771,872.428467 
	C465.529907,871.107300 462.634003,870.586548 459.248840,871.146606 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M557.269653,855.799683 
	C556.029907,858.311951 554.653381,858.215637 552.946533,856.403809 
	C552.687134,855.931213 552.533691,855.777039 552.222656,855.908508 
	C550.071777,858.863586 548.681458,861.731995 549.030640,865.596436 
	C548.765259,866.134583 548.563965,866.271790 547.997681,866.408875 
	C542.893250,864.744019 538.990356,861.409790 533.994873,859.481445 
	C532.962097,853.512512 528.076538,852.017517 523.939026,849.767822 
	C522.791870,849.144043 521.695190,849.288513 520.794983,850.303223 
	C519.811279,851.411926 520.345032,852.439819 520.963318,853.478577 
	C521.661682,854.651978 522.581238,855.565491 523.828735,856.148682 
	C525.412964,856.889038 526.114502,858.092529 525.417114,859.778320 
	C524.767822,861.347717 523.425232,861.866272 521.788940,861.853699 
	C517.643799,861.821777 513.174438,858.325745 512.174072,854.416626 
	C511.783020,852.888489 512.821167,850.497131 510.903076,849.971008 
	C508.586609,849.335510 505.872345,849.179382 503.718781,850.802246 
	C501.761078,852.277588 500.088867,853.632263 499.041626,850.056946 
	C498.774139,849.143860 498.349274,848.866150 497.235809,849.093811 
	C493.211395,849.916626 492.616089,847.445557 492.602112,844.193359 
	C492.580719,839.227112 493.045380,834.344971 494.590393,829.354980 
	C498.795746,828.299255 502.103027,828.424316 505.001007,832.122009 
	C506.957947,834.619019 511.214966,834.035461 513.660950,837.200012 
	C513.099548,831.301880 513.332581,831.045288 518.767090,831.528687 
	C520.106323,831.647766 521.393005,831.493958 522.706177,831.356873 
	C524.778381,831.140442 526.199890,832.251648 525.681396,834.198792 
	C524.875305,837.226074 526.565369,839.653137 526.939453,842.714478 
	C529.136414,847.081177 532.912537,849.039673 537.214478,850.796082 
	C537.723022,850.936218 537.918396,851.002502 538.377319,851.198608 
	C538.958496,851.550110 539.311584,851.629578 539.983154,851.559753 
	C540.467407,851.552795 540.662598,851.578125 541.127014,851.729309 
	C541.888672,852.192810 542.344727,852.584229 543.169739,852.967041 
	C547.558289,854.216492 550.328979,850.367920 554.442993,850.272217 
	C555.508362,850.447266 556.160828,850.744202 557.075745,851.341675 
	C558.544678,852.842773 558.721924,854.155640 557.269653,855.799683 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M600.467896,863.817444 
	C599.924072,863.210266 599.384094,863.052307 598.565552,862.845398 
	C595.108276,862.180542 591.763977,862.146912 588.795349,859.833740 
	C588.560303,858.224182 588.774414,856.784058 586.976929,856.416992 
	C582.088501,855.418579 578.956238,850.492432 573.694031,850.432007 
	C572.478577,850.418030 571.829407,851.652832 571.193298,852.633606 
	C569.411377,855.381287 567.919006,854.965881 566.595703,851.854248 
	C566.522522,850.884399 566.343567,850.210815 566.800476,849.830688 
	C566.262329,850.499878 566.024109,851.765686 564.318665,851.785339 
	C563.765930,851.717346 563.551086,851.654846 563.061584,851.402710 
	C562.546082,850.843872 562.387634,850.443726 562.351562,849.689453 
	C562.478394,848.977844 562.620667,848.594360 562.909912,847.955322 
	C563.127502,847.311707 563.186951,846.930237 563.146118,846.243774 
	C562.280640,842.356384 557.950134,842.020508 556.465942,838.612183 
	C556.295044,833.140320 555.520447,827.697083 561.849731,824.699097 
	C562.633789,824.322754 563.119873,824.212891 564.007202,824.185547 
	C568.222168,824.823486 571.949341,823.669067 575.731812,823.454529 
	C583.762451,822.998779 590.294983,826.323242 595.984375,831.301331 
	C599.450867,834.334473 601.342957,838.486694 598.983582,843.321289 
	C598.448364,844.418091 598.551575,845.572266 598.486694,847.108032 
	C597.019104,849.531555 595.140137,848.329163 593.064575,848.153503 
	C592.465454,847.970093 592.233582,847.880432 591.678406,847.615540 
	C589.544067,845.973877 587.726929,844.512329 585.950256,843.007568 
	C584.833252,842.061523 583.306763,840.843811 584.100525,839.292908 
	C585.077576,837.383972 586.921875,838.153198 588.501221,838.805298 
	C589.093689,839.049988 589.659912,839.543396 590.397888,839.068176 
	C590.311523,837.428833 589.008545,836.963501 587.789429,836.445557 
	C586.876831,836.057922 585.568970,836.033386 585.161011,835.138184 
	C581.236694,826.526733 573.446167,831.511597 567.502075,830.075684 
	C566.922791,829.935669 566.108459,830.287231 565.899719,831.023621 
	C565.632812,831.964783 566.321106,832.547241 566.942322,833.103027 
	C568.227112,834.252686 569.808777,834.908386 571.439453,835.151550 
	C574.912781,835.669434 576.656738,837.786316 577.541626,841.314392 
	C577.795837,843.011108 578.151672,844.253113 579.513123,844.933044 
	C588.828369,849.585205 597.341064,855.863647 607.883362,858.868164 
	C608.474854,859.091797 608.708130,859.202637 609.263916,859.543335 
	C611.012512,861.322266 612.083069,862.891663 609.476257,864.845459 
	C606.143555,865.586670 603.200867,866.112549 600.467896,863.817444 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M263.208008,765.832031 
	C261.031372,767.071350 262.520081,768.241455 262.857178,769.305237 
	C263.328278,770.792053 262.922974,772.044434 261.695770,772.803223 
	C259.856049,773.940613 259.444061,771.971375 258.828705,771.067688 
	C251.087936,759.699829 245.888214,747.232483 242.968109,733.407166 
	C242.347610,723.734436 240.384720,714.666565 238.612747,705.582886 
	C237.801987,701.426453 238.158890,697.307312 238.145752,692.783630 
	C242.223221,690.389404 243.902710,690.960205 245.250824,694.869690 
	C246.042465,697.165405 246.107513,699.767151 248.472137,701.755493 
	C248.951096,702.207336 249.129013,702.402405 249.506134,702.937500 
	C249.786728,703.525330 249.868149,703.773010 249.996933,704.406494 
	C250.035446,705.303467 249.943054,705.798767 249.568726,706.586670 
	C249.510223,708.133240 247.131927,705.420410 248.078568,708.109802 
	C248.321213,709.417542 248.264679,710.376587 247.937286,711.322876 
	C247.767471,711.813721 247.471085,712.284363 247.586899,712.844177 
	C247.756485,712.102478 248.239548,711.789917 249.255936,712.059204 
	C251.081436,715.268616 251.171188,718.575562 251.795166,722.141174 
	C252.450623,724.583313 252.174850,726.879333 253.700500,729.075989 
	C254.369873,730.246826 254.712036,731.152832 254.809128,732.500488 
	C254.057571,736.224365 255.368500,739.204041 256.687744,742.531128 
	C256.964111,743.297729 257.085846,743.746155 257.248230,744.540649 
	C258.001678,750.513916 261.065826,755.484863 261.819336,761.419373 
	C261.941010,762.340149 261.078278,762.423523 261.436096,762.862061 
	C262.365784,762.833801 262.869385,763.184998 263.417877,763.986450 
	C263.619232,764.729309 263.578247,765.162720 263.208008,765.832031 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M395.230713,831.857300 
	C390.740662,830.292175 386.095428,833.218628 381.435181,831.125488 
	C377.373901,831.884033 374.655792,829.554626 371.317566,828.089966 
	C368.410980,826.476685 367.512360,823.335510 364.646790,821.711426 
	C363.482635,821.144897 362.829224,820.529480 362.480896,819.253784 
	C363.966125,816.370911 364.952118,813.709412 364.509338,810.383179 
	C367.060394,809.108276 367.886169,811.118530 368.858215,812.471497 
	C369.722321,813.674133 370.310181,815.047485 372.134827,815.724365 
	C373.764954,816.656006 374.846344,817.693237 375.731995,819.336426 
	C375.953156,819.791016 376.035004,819.972229 376.362549,820.670654 
	C376.699677,821.348389 376.791168,821.508911 377.026733,821.900879 
	C377.266022,822.284119 377.361237,822.435852 377.592834,822.814941 
	C377.816864,823.192810 377.904602,823.343323 378.125366,823.715576 
	C378.352814,824.079407 378.447296,824.221619 378.701660,824.563110 
	C378.985626,824.882935 379.109650,825.003357 379.688782,825.408875 
	C380.496521,825.854004 380.850677,826.010559 381.498474,826.291565 
	C382.795715,826.719666 383.620514,827.943665 385.253662,826.787598 
	C380.120300,823.566040 381.456543,819.239197 383.336914,814.592651 
	C386.268158,812.649170 386.703979,810.388855 385.625641,807.478516 
	C384.648376,804.840881 384.068207,802.058899 382.809967,799.140869 
	C381.845520,796.523071 380.998535,794.231750 382.913269,791.799988 
	C380.284302,791.824768 378.095612,792.096741 375.597412,792.839783 
	C372.765289,793.018494 371.478516,791.600769 370.659393,789.085815 
	C369.205200,785.569214 369.742493,783.413635 373.466278,782.540710 
	C375.508484,782.062012 376.837219,779.920837 379.449524,779.712769 
	C380.674713,779.573914 381.550720,779.467957 382.597778,778.787109 
	C383.114319,778.627563 383.332153,778.603027 383.865601,778.672607 
	C384.718201,779.160156 385.116516,779.680176 385.693970,780.474487 
	C387.077515,782.285706 388.104126,783.949829 389.423492,785.818542 
	C389.816986,786.501953 389.999207,786.916077 390.251282,787.672607 
	C391.844635,792.858154 392.372498,797.758850 392.784515,802.871948 
	C393.280853,809.031799 395.829468,815.319336 399.532501,820.745544 
	C401.438080,823.537781 403.500671,826.394226 397.703156,827.134155 
	C395.086273,827.468140 396.480682,830.229370 395.230713,831.857300 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M197.038055,606.444153 
	C199.100250,600.418762 192.437073,598.198792 191.093842,593.415649 
	C190.725571,592.993958 190.618134,592.803528 190.325226,592.343018 
	C189.872040,591.477051 189.717651,590.847107 189.480392,589.910828 
	C187.395096,587.639099 183.952362,587.961548 182.225769,584.971619 
	C179.396210,581.950623 179.685013,580.164001 183.413025,578.506104 
	C186.951981,576.932190 190.383484,575.029907 193.741364,572.975891 
	C197.110764,570.914856 201.328491,569.927307 205.281281,567.812439 
	C208.734131,570.489075 210.273849,573.846313 209.704742,578.360413 
	C208.938705,579.606934 208.038696,580.074524 206.714050,580.537231 
	C204.827942,580.961060 203.867233,580.212952 203.188095,578.938660 
	C203.133957,581.054443 204.631943,581.980896 206.471222,583.063538 
	C209.500443,586.955872 210.073563,590.682556 208.661606,595.721130 
	C208.742615,596.531006 208.713181,596.992920 208.463165,597.765381 
	C207.974823,598.395020 207.592300,598.636963 206.848984,598.857422 
	C206.150330,599.032776 205.418152,598.943298 205.504074,599.410278 
	C205.603043,599.948364 206.409149,600.021851 207.160370,600.544373 
	C208.320663,601.617310 208.970200,602.629944 209.339828,604.201172 
	C207.787338,606.941223 205.164719,607.314941 202.392395,608.144043 
	C200.014450,608.847595 199.228546,606.226196 197.038055,606.444153 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M706.477844,869.322876 
	C702.385315,869.946472 698.622375,870.498230 694.428589,871.146606 
	C691.591614,871.450378 689.185486,871.657471 686.389954,871.973022 
	C685.600586,872.389038 685.148987,872.524536 684.322388,872.315796 
	C679.308350,872.149841 674.617737,872.156555 669.467651,872.241699 
	C667.669678,873.171936 665.930298,872.867859 664.304688,873.625488 
	C663.976685,873.413147 663.589355,873.638000 663.558899,873.748047 
	C662.280212,873.132812 660.784424,873.114258 659.257568,872.202881 
	C658.583801,871.817810 658.166565,871.682007 657.425415,871.629272 
	C649.174866,873.676514 641.833679,870.052673 633.866577,869.118042 
	C633.426208,868.806213 633.200623,868.593201 633.208801,868.583740 
	C635.756470,865.656921 633.953796,862.052551 635.016357,858.619751 
	C637.663940,857.320435 639.934387,858.315186 642.618347,858.779663 
	C647.277161,859.911621 651.704163,859.894836 656.337097,860.827026 
	C657.398376,861.227661 658.141479,861.531555 658.194092,862.650391 
	C658.118835,862.832825 658.444397,863.023560 658.408447,862.812012 
	C658.508484,862.065491 658.341187,861.529114 658.491577,860.656006 
	C664.151978,856.730286 669.725464,858.499268 675.601685,860.481323 
	C676.138306,862.239868 677.190125,864.068481 676.477844,865.189331 
	C675.537354,866.669189 673.384399,865.500305 671.770874,865.825989 
	C670.224854,866.138123 668.557861,865.172852 667.039429,866.369995 
	C671.733215,864.869507 676.864197,867.601868 681.513184,864.225159 
	C683.430603,862.832397 686.029846,864.027649 688.395020,865.456177 
	C690.695496,867.003113 692.871704,866.758789 695.393555,866.211060 
	C696.583801,865.940979 697.458557,865.885193 698.648376,866.040405 
	C699.505798,866.265503 700.021606,866.604614 700.924561,866.342651 
	C701.409607,866.193604 701.608704,866.139648 702.119263,866.035278 
	C703.081299,865.933350 703.723999,865.993713 704.683105,866.215698 
	C706.029297,867.030090 707.738464,867.331360 706.477844,869.322876 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M239.239441,691.781494 
	C238.172775,704.356506 242.458908,716.044922 243.602493,728.132690 
	C243.742126,729.608765 244.254822,731.025146 243.267883,732.735352 
	C240.178024,720.571777 237.420258,708.127808 236.805130,695.339111 
	C236.657196,692.263306 236.787994,689.761963 232.109268,691.029053 
	C228.374344,692.040588 225.804916,689.316284 224.412659,685.520081 
	C222.009583,687.935425 222.217102,690.567566 222.068802,693.173584 
	C221.977020,694.786499 223.198120,696.838379 220.467575,697.891968 
	C218.420166,697.810913 217.951492,696.525940 217.306305,695.055054 
	C217.252365,694.736206 217.050018,694.979492 217.162384,694.868042 
	C216.064072,694.442444 215.892807,693.284485 215.215729,692.136963 
	C214.052399,689.152283 213.044144,686.441895 212.088226,683.361694 
	C215.679916,682.295105 213.911148,688.273315 216.778900,687.098999 
	C218.505432,686.391968 219.158005,683.313232 220.579025,681.527710 
	C221.659409,680.170288 223.428131,679.924194 225.159515,679.997253 
	C227.518600,680.096802 229.791733,680.072693 231.563004,678.037598 
	C232.907227,676.493103 234.271820,677.794128 235.628296,678.284241 
	C239.144470,679.554565 242.682556,680.764282 246.606567,682.000305 
	C248.614899,683.595032 248.217712,685.394043 247.350983,687.565186 
	C246.882141,688.327515 246.563004,688.716980 245.940125,689.317261 
	C244.024567,691.528992 241.174866,689.741882 239.239441,691.781494 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M360.259399,842.953735 
	C354.540710,842.131714 352.049469,837.703186 349.347656,833.400696 
	C347.976624,831.217468 347.614746,827.781372 343.420166,828.012939 
	C340.258148,826.358826 338.819397,823.442810 336.221771,821.232056 
	C335.940063,821.052063 335.693420,820.868347 335.547546,820.819702 
	C330.324524,815.494934 326.117615,809.600647 322.705688,802.715088 
	C321.426300,797.954773 318.596863,794.381531 316.713074,790.084839 
	C322.760223,787.922546 323.790466,793.122681 326.433350,796.536011 
	C327.025330,797.629272 327.359497,798.467407 328.181061,799.383789 
	C328.842377,800.344482 329.105621,801.146240 329.591919,802.178101 
	C330.035004,802.966003 330.337769,803.484924 330.998749,804.115234 
	C331.375397,804.459595 331.529083,804.595703 331.920532,804.927002 
	C333.883911,806.231445 336.022308,805.014587 338.053101,806.265747 
	C343.698456,809.991028 345.784637,816.302734 350.554291,820.800415 
	C351.004120,821.165405 351.176208,821.321167 351.581421,821.735596 
	C353.502197,824.442139 355.005676,827.006897 356.743530,829.828308 
	C357.581696,831.010864 359.117767,831.372253 358.673218,833.257690 
	C358.516754,833.508911 358.298981,833.467651 358.048676,833.750977 
	C357.635193,834.359558 357.386719,834.153076 356.995270,833.980591 
	C356.415497,833.725098 357.248505,833.824097 356.692505,834.191833 
	C356.569885,834.272949 356.716522,834.242004 357.276001,834.300232 
	C359.976379,836.702271 360.242798,839.653931 360.259399,842.953735 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M197.472351,644.069946 
	C196.265747,643.695557 195.401230,643.357422 194.189667,642.977661 
	C192.339584,641.115112 190.701569,639.458374 188.246719,638.286621 
	C190.169052,632.631042 195.013184,630.128723 199.758667,627.035278 
	C199.405884,626.305542 198.910477,625.892151 198.206375,625.227173 
	C197.971375,625.006104 198.003586,624.997864 197.997345,625.013367 
	C197.642609,624.711365 197.530029,624.296265 197.502106,623.846191 
	C197.411453,624.074463 197.459625,624.322754 197.187836,624.784302 
	C194.928467,624.342834 192.340530,624.504761 191.604401,621.842590 
	C192.949570,619.611633 194.804047,624.046570 195.612610,621.347168 
	C196.104950,619.703491 198.506744,618.045776 197.166397,616.653320 
	C195.533676,614.957092 194.713699,617.988708 193.271622,618.464478 
	C192.397827,618.752686 191.745529,619.960327 190.689743,619.388062 
	C190.292007,619.172424 190.095490,618.234314 190.130386,617.644714 
	C190.203842,616.403992 191.098312,615.974976 192.216660,616.217102 
	C193.611038,616.518982 194.467514,615.387268 193.838669,614.735291 
	C190.843643,611.630127 194.991333,611.977478 195.790390,610.394287 
	C196.570801,609.516113 197.185455,609.914978 198.088272,610.281128 
	C199.781479,612.005127 201.562225,611.840942 203.708069,611.616699 
	C205.225845,612.372375 206.232605,613.261902 207.187317,614.678894 
	C207.388412,615.206848 207.442398,615.428223 207.607971,615.974243 
	C208.575531,617.440430 209.072495,618.725952 209.317322,620.477539 
	C209.606567,623.345764 211.332382,625.277466 212.084595,627.612732 
	C212.735764,629.634277 212.621994,632.892883 211.584152,633.018921 
	C206.562683,633.628601 209.162720,637.268005 208.306763,639.841187 
	C203.686035,638.813660 201.139359,642.475525 197.472351,644.069946 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M547.729370,865.840698 
	C548.032776,865.987915 548.499634,865.990784 548.733093,865.994141 
	C550.963379,868.497559 551.786194,872.051025 555.665955,873.333008 
	C556.955261,873.456299 557.806458,873.720337 558.662415,874.798462 
	C565.288391,880.797485 574.171875,880.620422 581.911987,884.505188 
	C568.924805,889.495056 556.909180,884.698975 544.389099,882.828918 
	C543.768188,875.587769 538.112854,872.547424 533.469849,868.349915 
	C533.469543,866.949707 534.033569,866.207458 535.168945,865.464478 
	C539.469543,865.368225 543.396790,867.122009 547.729370,865.840698 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M459.052551,871.490723 
	C462.049225,866.896484 465.433228,870.319580 468.480347,871.202454 
	C470.312042,871.733093 471.856689,872.956421 473.040802,870.810730 
	C474.027832,869.022156 474.215149,867.200073 471.833252,865.817566 
	C469.916046,864.704773 467.292816,863.082153 471.925232,861.059753 
	C472.680115,861.110107 472.841309,861.177002 473.481079,861.472412 
	C474.300629,861.836853 474.643372,861.968079 475.261230,862.196411 
	C476.495758,862.737000 477.212463,863.418884 477.702240,864.706055 
	C477.971954,865.877747 477.234161,866.956299 478.640808,867.717773 
	C479.096252,867.897278 479.278168,867.971130 479.724060,868.172485 
	C480.437317,868.429932 480.814941,869.161499 481.643097,868.319580 
	C482.802948,867.896729 483.680420,867.956665 484.883789,868.325073 
	C486.738007,869.599426 488.206055,870.809204 489.711548,872.512939 
	C490.503632,875.332214 492.339386,876.959229 494.020599,878.721252 
	C495.566315,880.341431 497.474487,881.871582 496.222534,884.852539 
	C484.263214,886.526733 474.826233,880.211121 464.448120,875.816406 
	C463.333954,875.486816 462.476715,875.881042 461.630066,875.057617 
	C461.288605,873.351746 460.187897,872.608765 459.052551,871.490723 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M316.614136,818.799194 
	C318.432129,823.065674 320.357330,826.993042 322.282532,830.920471 
	C310.976624,819.173950 304.155304,804.844177 297.317139,790.168335 
	C294.095093,786.890381 293.529114,782.703918 291.853882,778.616577 
	C291.544098,776.738770 292.221191,775.225647 291.748962,773.349854 
	C295.895569,769.947144 295.848206,769.973938 297.561401,774.035706 
	C297.763428,774.514709 298.616302,773.196655 299.765137,773.563293 
	C304.070923,777.083923 304.138885,782.552063 307.049469,786.928101 
	C307.362244,788.189941 307.585144,789.101990 308.033325,790.304199 
	C308.958527,793.112244 309.612823,795.675659 311.599915,797.918091 
	C311.581146,798.448547 311.539093,798.656555 311.364197,799.147827 
	C310.938965,799.959656 310.549866,800.445923 310.533630,801.396851 
	C311.472778,804.249451 312.349884,806.802979 313.742737,809.523376 
	C314.717010,812.749695 316.030060,815.503418 316.614136,818.799194 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M395.398560,832.114685 
	C394.033661,825.661194 394.033661,825.661194 401.317902,825.260437 
	C395.207001,819.005798 394.238861,810.738525 390.670959,803.652283 
	C390.395844,803.105896 390.049042,802.175049 390.296661,801.849121 
	C393.904114,797.100403 389.862152,793.117737 389.022827,788.454834 
	C389.279663,787.731934 389.653717,787.455688 390.438507,787.217407 
	C394.903320,787.155884 394.222382,791.275391 396.269287,793.510498 
	C397.783234,795.776184 399.547272,797.565918 399.256348,800.501221 
	C398.052216,805.430603 401.734680,808.405090 403.076141,812.207764 
	C403.595306,813.679504 405.642456,815.125854 402.691620,816.575012 
	C401.482269,817.168823 402.087585,818.890869 402.767365,820.153687 
	C407.000977,828.018372 409.283722,836.837158 414.519562,844.220032 
	C414.781525,844.589417 414.742889,845.172119 414.802612,846.033447 
	C414.325409,846.550903 413.897064,846.538147 413.337708,846.047180 
	C409.845947,841.089783 406.743225,836.223083 403.040161,831.866699 
	C401.074280,829.554138 398.097260,828.827332 395.398560,832.114685 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M261.240448,763.087036 
	C259.288239,757.209900 257.659515,751.486206 256.031555,745.369751 
	C257.897247,743.633850 259.016876,744.321655 259.396210,745.436340 
	C258.172699,743.182617 256.889618,739.979065 255.970062,736.285522 
	C256.001343,735.701355 256.030212,735.469299 256.132507,734.896606 
	C256.694122,733.232361 256.657715,731.860657 256.548645,730.137695 
	C255.029663,725.128967 255.951813,720.242798 255.199692,715.505493 
	C254.984283,714.148682 255.416336,712.793335 257.075226,712.474976 
	C258.682861,712.166382 259.569855,713.150940 260.040314,714.500488 
	C260.747253,716.528259 262.143799,718.513916 262.008789,720.601379 
	C261.642365,726.267029 265.024475,731.103577 265.225342,736.534851 
	C265.405121,741.396423 269.084259,743.064209 272.375031,745.676636 
	C272.635529,746.204285 272.736053,746.416077 272.968109,746.951050 
	C273.353333,747.913757 273.563934,748.573303 274.159149,749.437500 
	C275.480865,752.139160 275.948730,754.693604 275.988373,757.689331 
	C275.860718,758.275574 275.784882,758.504211 275.529114,759.046204 
	C273.744446,761.384705 271.832367,761.630188 269.350464,760.161499 
	C268.491913,759.109680 267.938477,758.239014 267.385010,757.368347 
	C266.589386,758.033386 267.357208,758.754822 267.370728,759.805176 
	C264.195160,759.138977 263.182526,757.907410 262.476105,754.273804 
	C262.938507,757.344971 265.469208,760.204041 263.268799,763.754761 
	C262.526581,763.738647 262.045258,763.489502 261.240448,763.087036 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M211.685471,747.764282 
	C214.953201,750.956116 214.430466,753.177551 210.550018,754.668457 
	C209.930664,754.906372 209.004089,754.875366 208.694366,755.308289 
	C204.621033,761.002563 201.664154,756.267090 198.871506,754.029541 
	C195.299988,751.167969 198.358643,747.969666 199.184372,744.952515 
	C199.309006,744.497192 199.608215,743.910400 199.989227,743.732422 
	C203.736252,741.981506 198.901886,739.372070 200.485352,737.925659 
	C201.938660,736.598206 204.721283,738.724487 206.336624,735.732544 
	C207.900833,732.835266 208.604507,730.122253 207.472794,727.235229 
	C206.589249,724.981506 206.702271,722.931519 206.688004,720.601257 
	C206.661545,716.285461 208.776367,714.387207 212.622437,713.046570 
	C215.486801,715.633301 213.190292,718.416321 212.879059,720.931213 
	C211.820267,729.486755 212.331711,738.184570 210.190125,746.909851 
	C209.351852,747.425476 208.753708,747.638062 208.258972,748.042725 
	C207.917862,748.321899 207.488541,748.534973 207.437439,749.052368 
	C208.152496,749.049622 208.176270,748.257324 208.954529,747.784180 
	C209.963013,747.406616 210.672333,747.210022 211.685471,747.764282 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M350.740051,822.016479 
	C345.166412,818.449463 343.222565,811.965332 338.398865,807.433960 
	C343.044891,803.758789 345.745087,807.725769 349.141602,810.489746 
	C349.610107,810.845032 349.792023,810.990784 350.217773,811.380737 
	C351.049988,812.290527 351.471130,813.083801 352.291962,813.972290 
	C352.799103,814.514282 353.091461,814.819397 353.620911,815.331909 
	C355.231049,816.845154 356.936951,817.750916 358.437592,819.396851 
	C359.726013,820.948730 361.039307,822.014160 362.580811,823.306641 
	C365.482910,824.319519 367.211945,827.366516 370.791412,826.510193 
	C374.196869,827.838440 377.298950,829.305176 380.716370,830.954102 
	C380.639435,832.831787 379.660309,832.899353 378.074432,832.625549 
	C375.361115,832.156982 372.357361,829.703247 369.958160,833.561462 
	C369.460541,834.361633 368.351074,833.344299 367.524323,832.714050 
	C363.901886,829.952637 359.707886,828.024597 356.219635,824.512695 
	C355.165588,826.731995 357.539978,827.827209 356.897339,829.771484 
	C354.333893,828.281921 352.845978,825.483582 350.807800,822.839417 
	C350.997314,822.019836 351.504913,821.785156 351.732178,821.331238 
	C351.469513,821.482544 351.225067,821.660034 350.740051,822.016479 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M543.044922,882.433716 
	C541.111084,885.499451 537.677063,883.928711 536.318970,883.461548 
	C531.509277,881.806885 526.888123,885.366028 522.240295,883.161316 
	C520.797913,882.477112 520.075928,883.704529 520.272400,885.487061 
	C520.582520,888.301758 519.146057,886.904175 518.059326,885.878906 
	C517.915100,885.141479 517.722412,884.764648 517.186157,884.237061 
	C516.930603,884.041199 516.999939,884.013123 516.978760,884.043945 
	C513.702026,881.252380 508.978210,880.049561 507.378998,875.047363 
	C507.435120,874.476624 507.496063,874.253479 507.738342,873.735962 
	C508.215790,873.089722 508.526031,872.750854 509.094604,872.181580 
	C509.521698,871.797974 509.704681,871.657715 510.199677,871.367065 
	C511.409180,870.920349 512.313110,870.933594 513.531006,871.278809 
	C515.215393,872.099243 515.793762,874.364014 518.295105,873.457886 
	C523.869080,873.420166 528.110046,876.799866 533.425476,877.781433 
	C533.992371,877.901245 534.217102,877.958191 534.771484,878.124756 
	C535.529785,878.403137 535.954651,878.581238 536.696045,878.893372 
	C539.009644,880.079712 541.531677,880.101196 543.044922,882.433716 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M543.282715,882.782471 
	C540.880981,881.966614 538.726196,881.154602 536.277588,880.138550 
	C535.715210,879.676025 535.431824,879.435120 534.938965,879.411865 
	C534.744629,879.611755 534.384766,879.746460 534.232422,879.629395 
	C534.456848,877.944885 533.271484,877.529724 532.227051,877.056763 
	C527.018860,874.698181 523.251648,870.348022 518.607361,867.207703 
	C517.372131,866.372498 516.084290,865.225891 516.712097,863.541260 
	C517.359192,861.804871 519.037537,862.200256 520.542480,862.153076 
	C525.700256,861.991394 529.800354,864.288940 533.791626,867.715820 
	C536.215637,871.388245 541.121399,871.050476 543.206604,875.271912 
	C544.442810,877.774597 544.708740,879.602539 544.025452,882.322998 
	C544.012939,882.684570 543.529663,882.786438 543.282715,882.782471 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M197.118958,644.431885 
	C198.858780,640.312256 203.532974,638.472717 207.711472,639.962524 
	C209.207565,639.737610 210.228394,640.039185 211.041656,641.366333 
	C213.629333,644.119629 209.827789,644.914307 209.549484,646.758240 
	C209.341751,648.134644 208.256577,645.707703 207.307632,645.425598 
	C205.981750,645.031494 204.075394,644.637207 204.572525,646.644104 
	C205.226517,649.284058 204.371399,651.540588 204.084930,653.974426 
	C203.778641,656.576782 204.225113,656.964111 207.071793,656.176819 
	C208.513107,655.778198 208.495438,656.908264 208.601013,657.345215 
	C209.042038,659.170715 210.532806,659.211731 213.060486,659.132202 
	C210.648361,660.850464 209.226776,661.863098 207.475922,662.941895 
	C204.644608,658.738708 200.125885,655.927795 198.619507,650.885071 
	C198.019806,648.877625 196.706894,647.084106 197.118958,644.431885 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M632.808594,867.602173 
	C640.705627,869.823975 648.500061,870.859558 656.739197,871.526611 
	C654.236816,874.223633 651.697876,874.799255 647.513733,873.408447 
	C643.519653,872.080750 639.088013,869.551453 634.144775,871.518311 
	C632.704041,872.091614 630.422729,869.281982 628.252686,871.872864 
	C627.378479,872.812683 626.640625,873.318604 625.309204,873.158081 
	C624.998779,872.999756 624.499390,872.999451 624.249695,872.999756 
	C624.444824,870.691223 620.259521,870.046814 621.848450,867.182739 
	C621.917786,867.057800 619.684265,865.594727 619.246216,867.312927 
	C617.728271,873.267212 615.021301,870.708557 612.103821,868.172913 
	C611.375183,867.319763 610.826416,866.775024 610.636902,865.598389 
	C610.807251,864.991211 610.955444,864.775269 611.435059,864.347229 
	C615.329224,862.977234 619.009338,863.847107 622.999146,863.451233 
	C623.632019,863.406799 623.885071,863.417236 624.500916,863.528198 
	C626.919373,865.930603 630.204346,865.674438 632.808594,867.602173 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M211.983704,747.598022 
	C211.199005,747.993713 210.420197,747.994812 209.251617,747.991272 
	C208.861877,747.986694 208.491547,747.899475 208.354950,747.767029 
	C211.260956,745.033508 210.635010,740.638306 210.384918,738.109497 
	C209.552231,729.689758 212.608551,721.951355 212.990601,713.421631 
	C213.957565,707.113220 217.450226,701.825073 217.177429,695.181885 
	C218.109009,695.581909 218.965637,696.427673 219.931671,697.560059 
	C218.031464,705.299133 216.021851,712.751465 213.999527,720.600952 
	C215.093567,724.291687 213.728775,727.490784 213.302170,730.647156 
	C212.941010,733.319275 213.025558,735.768127 213.809647,738.656738 
	C214.334457,741.990601 211.554382,744.204285 211.983704,747.598022 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M292.644867,777.787476 
	C294.271698,781.755066 295.608887,785.488403 296.976837,789.608582 
	C292.024811,785.529663 284.440826,788.885071 279.651245,784.072815 
	C281.563873,782.215332 281.564514,782.137634 279.856598,776.155273 
	C281.993866,777.475159 283.724426,779.183228 286.052673,780.074951 
	C287.757507,780.728027 289.232239,780.996155 290.166016,778.442627 
	C290.895294,777.399292 291.585602,777.326233 292.644867,777.787476 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M357.038818,834.333618 
	C357.351746,833.842957 357.620605,833.782654 358.311981,833.636230 
	C364.608215,837.693115 368.978363,843.339355 374.199310,848.842957 
	C370.996307,849.382324 368.755188,847.165405 366.118744,847.039124 
	C365.756622,849.058960 367.530884,849.657593 368.362732,851.193848 
	C368.497803,851.657532 368.435852,851.825256 368.116425,852.068359 
	C364.295197,850.379272 362.592285,846.966003 360.319427,843.568604 
	C358.349152,840.696106 358.960205,837.257996 357.038818,834.333618 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M160.924438,577.596558 
	C166.500259,574.264771 159.693542,572.998108 160.215637,570.343750 
	C161.894302,568.353516 163.895294,568.643372 165.750992,569.410828 
	C167.939774,570.316101 170.229721,570.418091 172.875778,570.862976 
	C174.962296,571.566650 176.376648,572.559937 177.474182,574.452637 
	C177.692612,575.484558 177.567627,576.149048 177.166107,577.077515 
	C176.911987,577.530090 176.796295,577.698853 176.465439,578.077148 
	C175.915665,578.481445 175.562454,578.630127 174.920898,578.771118 
	C174.315170,578.830139 174.181915,578.913269 174.242966,579.078613 
	C174.314087,579.271179 174.384094,579.369812 174.479034,579.672913 
	C171.664978,583.895630 170.551834,583.828125 166.998627,579.214966 
	C166.612823,578.303528 166.310349,577.728271 165.350464,577.357300 
	C164.175812,577.194519 163.513474,577.514099 162.998901,578.546082 
	C161.643021,579.913818 160.967178,579.434814 160.924438,577.596558 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M161.073151,576.936707 
	C161.220886,578.032776 161.322250,578.789978 162.646881,578.840088 
	C157.957718,583.709717 153.659775,589.584045 145.574051,590.017883 
	C146.038193,587.714722 146.433167,585.051880 147.140488,582.474792 
	C147.675018,580.527283 148.748260,581.447205 149.811737,582.622314 
	C149.978821,582.910828 150.268417,583.270325 150.349243,583.476135 
	C150.430069,583.681946 150.471252,584.095276 150.490326,584.287292 
	C150.509384,584.479309 150.216873,584.690430 150.373688,584.769409 
	C150.530518,584.848450 150.559158,584.487793 150.792175,584.248901 
	C152.516953,581.478882 154.008713,578.947754 155.759842,576.201904 
	C156.454712,575.990601 156.890244,575.993896 157.652557,575.994934 
	C158.641220,575.428223 159.387466,575.265015 160.548615,575.757629 
	C160.912918,576.205200 160.950485,576.416016 161.073151,576.936707 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M316.837158,818.961670 
	C315.329102,816.342041 314.151276,813.545715 312.915527,810.385254 
	C313.070129,809.715088 313.340118,809.479736 313.939331,809.287842 
	C314.593842,809.346191 314.993652,809.100098 314.831238,808.831787 
	C314.493683,808.274170 315.080780,808.969360 314.132019,808.928223 
	C312.522095,808.278992 312.065002,807.227051 312.413239,805.536743 
	C313.207428,804.164978 314.994995,805.142273 315.578735,803.362183 
	C318.283142,798.612610 320.642761,800.725220 323.198120,803.606812 
	C324.752869,807.493591 326.183807,811.073120 323.205688,814.891663 
	C321.084351,815.023560 319.255646,815.129456 318.705963,812.731262 
	C318.532349,811.973694 317.838257,811.175720 316.899109,811.552429 
	C315.738220,812.018066 316.020660,813.133911 316.213257,814.063416 
	C316.539093,815.635803 318.976929,816.775146 316.837158,818.961670 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M323.056946,815.393494 
	C324.067902,811.726624 323.738373,808.352051 322.995087,804.599304 
	C322.382111,803.551697 322.456207,802.875366 323.368713,802.162964 
	C326.303497,808.937866 332.612335,813.332031 335.344971,820.417358 
	C331.263306,819.282104 326.294525,819.786621 323.056946,815.393494 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M557.403076,856.108276 
	C557.115112,854.980652 557.196838,853.878113 557.136230,852.530151 
	C556.843994,852.066162 556.693970,851.847534 556.511597,851.231201 
	C557.100403,848.973633 558.625366,848.637756 560.686401,848.670410 
	C561.454468,849.145630 561.699890,849.591614 561.883423,850.486572 
	C565.456909,856.504517 571.414246,856.044556 576.839233,856.758606 
	C579.055359,857.050415 581.499939,856.134338 583.344666,858.656860 
	C583.355957,859.563293 583.104431,859.991455 582.295776,860.391235 
	C573.455139,861.323181 565.346436,860.219055 557.403076,856.108276 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M174.141983,578.034302 
	C174.486267,578.392212 174.699722,578.496155 175.095123,578.394104 
	C176.560699,577.765259 176.732971,578.963928 177.409943,579.960938 
	C177.644043,580.503052 177.725739,580.725281 177.896332,581.292114 
	C178.162445,582.580811 178.156693,583.526550 177.918701,584.818909 
	C177.303452,586.759827 175.783371,586.996826 174.259552,587.822083 
	C173.996964,588.057129 173.996155,587.997070 174.025421,588.003723 
	C173.912018,588.159668 173.769318,588.308960 173.805817,588.710938 
	C172.838837,593.012695 171.560684,588.446716 170.168930,588.960388 
	C169.187943,589.322388 167.704132,588.587036 168.697647,586.995789 
	C169.619629,585.519043 169.307678,584.997192 167.358002,584.970337 
	C166.544281,584.619934 166.155350,584.193481 166.015442,583.290161 
	C166.012802,581.605896 166.003250,580.217285 165.987732,578.412170 
	C166.223495,577.868774 166.465256,577.741943 167.091583,577.755798 
	C168.185654,579.640625 168.737610,581.600342 170.981903,581.912598 
	C173.099777,582.207275 173.543488,580.484985 174.152191,578.635803 
	C174.208466,578.284668 173.968750,577.867249 174.141983,578.034302 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M507.694000,871.910156 
	C507.065826,875.403625 509.188690,877.032837 511.265259,878.702576 
	C513.181763,880.243652 515.073730,881.815186 516.967041,883.723999 
	C512.984802,883.521912 510.283752,880.411682 506.405334,878.894165 
	C504.326508,879.097412 502.776337,878.526184 500.982483,877.528687 
	C499.604187,875.490662 497.517242,874.475830 496.761841,872.061279 
	C500.342041,868.288147 503.914276,870.526550 507.694000,871.910156 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M404.759766,878.290955 
	C403.610962,874.669739 405.204559,872.776978 408.169250,872.097107 
	C410.517883,871.558411 412.362762,872.651855 413.332947,874.985901 
	C414.247864,877.187012 413.472748,879.037109 411.722290,880.204163 
	C409.082184,881.964294 406.697174,881.307129 404.759766,878.290955 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M581.920898,860.772461 
	C582.254395,860.207703 582.593079,859.948975 582.972717,859.363159 
	C584.631226,857.421265 586.405334,857.497986 588.590210,858.904785 
	C591.879700,860.252197 594.848755,861.244324 598.052490,862.516418 
	C596.951538,863.651062 595.617737,863.786621 594.082397,863.182068 
	C593.437927,862.928345 591.851685,863.374329 591.854431,863.408386 
	C592.332031,869.322876 587.947693,864.514893 586.077271,865.825806 
	C584.635254,864.831970 582.671021,864.810059 582.427734,862.525818 
	C582.132568,861.868225 582.029297,861.473450 581.920898,860.772461 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M182.543091,583.682800 
	C184.147263,587.501526 188.347275,586.246948 190.624237,588.729492 
	C190.447235,589.969360 189.895676,590.626404 188.663544,591.024170 
	C187.357910,591.111755 186.761444,591.643372 186.212006,592.766846 
	C185.797974,593.000061 185.600906,592.997375 185.108932,593.015320 
	C183.867859,593.842957 183.079315,593.430908 182.180603,592.390442 
	C181.342743,590.462952 178.934952,590.483765 178.414108,588.292603 
	C177.009628,587.194702 177.572968,586.206970 178.237442,584.882935 
	C179.472458,583.394714 180.770844,583.293945 182.543091,583.682800 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M278.252228,761.554932 
	C275.641022,765.871582 280.039368,769.292419 278.996948,773.598511 
	C275.472198,771.159058 272.408142,767.930359 270.399353,763.365784 
	C271.318298,761.551514 274.051605,762.155273 274.610352,759.457031 
	C274.824829,759.177429 274.945007,759.056946 275.005432,758.996948 
	C276.058105,758.159485 276.962433,758.497009 278.085693,759.451782 
	C278.538940,760.230286 278.585907,760.728333 278.252228,761.554932 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M214.125702,738.707031 
	C209.828918,736.253296 212.577484,732.169434 212.205643,728.740967 
	C211.953796,726.418701 212.980072,723.957825 213.710754,721.278503 
	C217.089478,721.194519 218.303406,721.175659 216.135071,725.164551 
	C214.657867,727.881897 213.285110,732.188293 214.741882,736.100037 
	C214.973129,736.721008 214.440567,737.626404 214.125702,738.707031 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M204.353760,612.259033 
	C201.964417,612.537598 199.937500,614.838440 197.924667,611.743103 
	C197.543167,610.679321 197.746918,609.879517 197.803680,608.785400 
	C198.130203,607.674805 197.947006,607.111694 196.865204,606.649292 
	C198.614044,604.915344 199.827744,607.588257 201.903290,607.275269 
	C204.378891,607.764221 206.515900,606.118896 208.960800,607.613647 
	C210.016510,609.356018 209.542267,610.654297 208.367096,612.193237 
	C206.938156,613.090332 205.824661,613.076233 204.353760,612.259033 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M496.081818,885.429443 
	C496.375305,882.614868 494.655487,881.251038 493.188446,879.657715 
	C491.562775,877.892090 489.084869,876.683655 488.981750,873.445068 
	C491.570374,872.295105 492.489349,874.676514 494.331360,876.020996 
	C496.556152,878.704163 499.436707,880.380859 500.686707,883.777771 
	C500.522064,885.188477 502.644958,885.960571 501.352325,886.992188 
	C499.293884,888.635071 497.828766,886.576904 496.081818,885.429443 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M500.983398,883.923096 
	C498.483215,881.969421 496.289276,879.821045 493.978119,877.357056 
	C495.883453,873.985413 498.289276,875.228455 501.109497,876.541382 
	C502.910065,877.196472 504.292694,877.742493 505.872498,878.541382 
	C504.234406,880.204590 501.840057,881.073364 500.983398,883.923096 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M610.805969,864.813965 
	C611.020325,865.098083 611.017029,865.493530 611.024292,865.691162 
	C610.659790,866.604187 610.028076,866.910828 608.899597,867.027588 
	C604.957397,866.717407 601.503540,866.686584 599.406738,870.840332 
	C598.402344,871.587463 597.618469,871.776917 596.381470,871.304504 
	C596.404480,869.308716 596.629456,867.372009 598.926819,867.080750 
	C601.161621,866.797546 599.979614,865.141724 600.363525,863.720581 
	C603.177673,864.686768 606.091248,864.651611 609.441895,864.559448 
	C610.117798,864.502991 610.354736,864.516418 610.805969,864.813965 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M595.597656,870.979004 
	C596.789917,871.069153 597.551147,871.067078 598.700928,871.025391 
	C598.374146,872.222656 598.830322,873.211548 599.837708,873.973755 
	C600.867249,874.752502 601.289001,875.676453 601.095032,876.970764 
	C600.794250,878.978455 598.553833,878.859314 597.685181,880.212952 
	C597.652466,880.263916 597.815674,880.440613 597.887634,880.558655 
	C597.679932,878.911072 594.829834,878.565674 595.882690,876.209290 
	C596.651062,874.489441 595.079102,873.627441 594.170715,872.481445 
	C593.557129,871.707458 593.398865,870.523438 595.597656,870.979004 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M187.839722,638.357239 
	C191.098541,637.844055 193.690918,638.328735 193.883606,642.560425 
	C193.093552,643.619507 192.344513,644.302979 191.307373,645.096069 
	C190.851089,645.294373 190.682907,645.383118 190.257141,645.349243 
	C186.201309,644.113647 184.314285,642.319458 187.839722,638.357239 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M263.025513,764.336548 
	C264.140472,760.258972 260.618774,756.799438 262.454590,752.391296 
	C263.548981,755.485291 264.945526,757.791870 267.106445,759.988098 
	C267.735413,763.331787 268.168518,766.579224 263.215515,766.375244 
	C263.003082,765.613525 263.023102,765.149414 263.025513,764.336548 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M156.067902,607.940674 
	C155.438293,610.629150 154.106537,612.262695 151.553360,611.695557 
	C150.136505,611.380859 148.932571,610.299194 148.949905,608.490112 
	C148.966675,606.738953 150.064545,605.695679 151.472214,605.176208 
	C153.711823,604.349915 155.294800,605.261353 156.067902,607.940674 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M193.435547,726.665466 
	C194.540527,728.914490 195.252335,730.910828 194.958206,733.119812 
	C194.697311,735.079102 193.602341,736.198242 191.643646,735.821533 
	C189.445435,735.398682 190.526428,733.682129 190.959076,732.709656 
	C191.873291,730.654358 188.849609,727.213623 193.435547,726.665466 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M543.666687,929.000000 
	C545.699219,932.131226 548.690857,930.634399 551.581177,931.000000 
	C552.578430,931.685974 552.730286,932.458496 552.234924,933.657349 
	C551.250610,934.000366 550.501221,934.000732 549.377136,934.001770 
	C543.851257,935.793030 542.658325,935.285095 541.775513,930.415039 
	C542.000000,930.000000 542.000000,929.500000 542.000000,929.250000 
	C542.444458,929.000000 542.888916,929.000000 543.666687,929.000000 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M223.289917,753.808716 
	C222.744171,753.840088 222.475800,753.635376 222.079834,753.208618 
	C221.397186,748.174683 225.015564,744.977661 226.603485,740.963867 
	C226.716354,745.457214 225.303818,749.553894 223.289917,753.808716 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M177.919739,610.403870 
	C179.586136,610.976379 181.561646,610.881958 180.998749,613.127380 
	C180.605957,614.694214 179.296753,615.681274 177.795792,615.859680 
	C176.421875,616.023071 175.293777,615.204163 174.975952,613.675903 
	C174.537903,611.569397 175.686234,610.668701 177.919739,610.403870 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M478.183929,888.166870 
	C480.246765,886.287415 482.050293,886.192139 483.050385,888.606323 
	C483.585663,889.898315 482.709290,891.431946 481.402802,891.785706 
	C478.805542,892.488892 477.879028,890.936218 478.183929,888.166870 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M231.547852,720.407837 
	C234.415833,720.725525 234.648087,722.392700 233.638382,724.223999 
	C232.988052,725.403381 231.272537,726.137268 230.330109,725.041992 
	C229.054169,723.559143 229.210037,721.702209 231.547852,720.407837 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M181.708954,592.241028 
	C182.760223,592.374146 183.489273,592.682251 184.516174,593.013184 
	C185.067841,594.481567 185.543625,596.085022 183.718185,596.815247 
	C182.301483,597.381958 181.133453,596.417725 180.341812,595.440552 
	C179.257584,594.102356 179.942780,593.051147 181.708954,592.241028 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M165.664703,583.039978 
	C166.338394,583.458191 166.654465,583.921875 167.005890,584.675537 
	C165.543076,585.932068 166.134964,588.765198 163.634888,588.811096 
	C162.935928,588.823853 162.204315,588.501160 162.246246,587.710022 
	C162.357681,585.607300 163.084839,583.829651 165.664703,583.039978 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M336.016846,821.390747 
	C340.724365,820.314331 341.049194,824.669861 343.011444,827.554688 
	C338.563385,828.293762 338.182617,824.048645 336.016846,821.390747 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M274.543427,759.175537 
	C275.435120,762.224670 274.235352,763.542053 270.681519,763.035034 
	C269.800690,762.198914 269.371033,761.368408 268.993378,760.259155 
	C270.517792,757.988403 272.838104,761.479126 274.543427,759.175537 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M640.978394,884.030029 
	C641.989441,882.002930 643.332031,881.123657 644.937195,882.619629 
	C645.981628,883.593140 646.098145,885.196899 644.803101,886.105652 
	C642.825684,887.493347 641.492554,886.664673 640.978394,884.030029 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M219.291092,764.903809 
	C218.980957,762.234375 219.379578,759.536133 221.692902,757.151611 
	C221.937225,757.772644 221.510864,758.797607 221.852310,759.332092 
	C223.779083,762.348145 221.787537,763.558411 219.291092,764.903809 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M207.194992,664.332031 
	C208.597702,664.180481 210.147583,663.520630 211.501404,664.797119 
	C210.841705,666.055420 210.181244,667.315186 209.265106,668.785828 
	C208.403137,667.556580 207.796860,666.116394 207.194992,664.332031 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M274.517700,789.420959 
	C275.357178,791.688782 274.583771,792.926147 272.551300,792.972473 
	C271.458984,792.997314 270.894409,791.926392 271.017700,790.878113 
	C271.250977,788.895081 272.609467,788.716736 274.517700,789.420959 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M377.239471,854.419861 
	C376.961884,852.897278 376.379456,851.237427 378.552032,851.537170 
	C379.817505,851.711731 381.210449,852.633240 380.665894,854.140747 
	C380.115662,855.664001 378.844238,856.445801 377.239471,854.419861 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M229.397614,733.205688 
	C228.866760,734.529114 229.050125,735.885986 227.213791,736.289062 
	C227.475769,733.660278 227.919174,731.254089 229.380600,728.460815 
	C230.264954,730.288940 229.997498,731.577026 229.397614,733.205688 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M383.939545,833.766602 
	C385.413940,833.040649 387.157990,831.908875 387.930450,834.185974 
	C388.254395,835.140930 387.469055,836.332092 386.578003,836.607422 
	C384.606171,837.216675 384.162720,835.712585 383.939545,833.766602 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M191.197968,600.479858 
	C192.644104,600.917847 194.314026,601.177856 193.278595,603.107117 
	C192.532318,604.497620 191.223541,604.393494 190.318237,603.698303 
	C188.894180,602.604797 189.348053,601.445007 191.197968,600.479858 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M419.049255,852.194214 
	C420.282990,852.513672 421.454437,853.190979 422.830688,854.072998 
	C422.896576,854.986389 423.180389,856.363953 422.315552,856.184875 
	C420.530640,855.815186 419.463623,854.351013 419.049255,852.194214 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M625.005371,873.338562 
	C625.764465,872.659607 626.250610,871.689636 627.625122,871.997925 
	C628.600037,873.073364 629.698792,874.248657 628.110657,875.248108 
	C626.448792,876.293945 625.664246,874.967834 625.005371,873.338562 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M582.177917,862.661255 
	C584.231567,862.240234 584.409058,864.429077 585.752136,865.467957 
	C583.853210,865.760498 580.845520,867.267944 582.177917,862.661255 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M559.094299,874.596375 
	C558.020020,874.712646 557.151855,874.360535 556.124756,873.771790 
	C555.912659,871.606201 556.571838,870.213989 558.766418,870.816772 
	C561.012451,871.433777 560.205017,872.842346 559.094299,874.596375 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M178.844208,584.891846 
	C178.665863,585.837830 177.669922,586.336060 178.236908,587.760681 
	C177.058044,588.108337 175.944672,588.124695 174.414124,588.099121 
	C174.166306,586.412292 175.632034,586.157898 176.919678,585.225708 
	C177.536713,584.588318 178.017593,584.486267 178.844208,584.891846 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M464.079132,892.441895 
	C465.756897,891.920410 466.516113,892.732300 466.784515,893.962036 
	C466.969849,894.811462 466.163544,895.086365 465.524567,895.101135 
	C463.913361,895.138306 463.671906,894.067688 464.079132,892.441895 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M132.433380,628.580444 
	C132.889664,629.689453 132.866196,630.576965 131.720993,630.518005 
	C131.266327,630.494568 130.502380,629.722229 130.486008,629.268921 
	C130.444855,628.130371 131.326706,628.103821 132.433380,628.580444 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M664.402405,873.582520 
	C664.752197,870.931152 666.785645,872.527222 668.603271,872.215515 
	C668.242859,874.630859 666.438477,873.908081 664.402405,873.582520 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M659.083679,872.337585 
	C660.713440,872.074951 662.656738,871.101318 663.760864,873.432007 
	C661.989441,873.805420 660.211426,874.669922 659.083679,872.337585 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M623.619202,872.997681 
	C622.150330,874.545654 620.349792,874.332336 618.606628,872.546509 
	C620.150574,872.696106 621.694458,872.845703 623.619202,872.997681 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M580.271667,870.251587 
	C582.195618,869.431152 582.450745,870.590393 582.464417,871.755493 
	C582.472290,872.430359 581.861511,873.016602 581.151489,872.862061 
	C579.918640,872.593689 579.873047,871.578918 580.271667,870.251587 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M432.478882,869.594971 
	C431.525665,868.700745 430.328857,868.050964 431.297607,867.082458 
	C432.101532,866.278809 433.248535,866.940491 433.741241,867.719910 
	C434.282623,868.576355 435.029388,869.979675 432.478882,869.594971 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M130.561676,602.833862 
	C130.880295,603.950012 131.307571,604.952087 130.053207,605.372192 
	C129.867706,605.434387 129.377014,605.164307 129.334579,604.978027 
	C129.105133,603.971191 128.532822,602.749268 130.561676,602.833862 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M565.664185,865.588867 
	C566.720154,865.885010 567.460327,866.062439 568.200562,866.239868 
	C566.804199,866.794617 565.351746,868.138611 564.291626,866.048950 
	C564.250183,865.967224 564.383789,865.700623 564.490112,865.659119 
	C564.759827,865.554016 565.060303,865.527832 565.664185,865.588867 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M186.065399,593.207397 
	C186.371414,592.007263 185.562836,590.532715 187.541931,590.035400 
	C188.242020,590.209045 188.468521,590.493103 188.579773,591.149780 
	C188.490799,591.607788 188.411880,591.780884 188.074463,592.074829 
	C186.640305,591.750732 187.868134,594.626099 186.065399,593.207397 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M209.979599,672.331909 
	C211.654541,672.217163 212.386932,672.956177 211.189972,674.826050 
	C210.183029,674.412109 209.981308,673.577087 209.979599,672.331909 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M164.787994,700.773560 
	C163.791870,700.712708 163.251297,700.365112 163.668030,699.615662 
	C163.817612,699.346680 164.563126,699.165771 164.902405,699.295532 
	C165.641541,699.578369 165.712555,700.150146 164.787994,700.773560 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M224.250641,800.001709 
	C223.223663,799.330688 222.551498,798.266418 223.618820,797.728210 
	C225.043884,797.009644 224.786362,798.545959 224.994080,799.666870 
	C225.000000,800.000000 224.500427,800.000977 224.250641,800.001709 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M228.808578,787.324890 
	C228.628204,786.540527 228.703812,786.072632 228.879242,785.262695 
	C230.048981,784.779480 231.289398,784.304932 231.083389,786.254517 
	C230.943680,787.576782 230.618546,788.457703 228.808578,787.324890 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M608.711609,867.393799 
	C609.137573,866.714539 609.741699,866.386108 610.688660,865.973206 
	C611.342224,866.341675 611.652893,866.794556 611.953735,867.548828 
	C610.925842,867.814941 609.907776,867.779724 608.711609,867.393799 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M183.474777,618.262451 
	C183.565781,619.481689 182.903610,619.899475 181.981735,619.700317 
	C181.734970,619.646973 181.618713,618.989624 181.442535,618.609497 
	C182.033340,618.422363 182.624146,618.235229 183.474777,618.262451 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M461.238770,875.074768 
	C462.303406,874.880066 463.088074,874.988403 463.995209,875.375610 
	C463.073059,875.467102 461.667114,879.406189 461.238770,875.074768 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M215.047882,692.240967 
	C215.733765,692.628662 216.406891,693.394592 217.177383,694.458618 
	C215.636993,694.978088 215.004364,694.146240 215.047882,692.240967 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M186.377029,601.545776 
	C187.132690,601.298523 187.537445,601.511047 187.476715,602.105103 
	C187.454727,602.320129 187.098907,602.651062 186.876831,602.666748 
	C186.271255,602.709656 186.112762,602.278320 186.377029,601.545776 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M144.422165,593.955139 
	C144.808182,593.248352 145.197205,593.241211 145.541306,593.744446 
	C145.613434,593.849854 145.611038,594.094177 145.536316,594.197144 
	C145.178375,594.690063 144.789627,594.670837 144.422165,593.955139 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M190.619522,650.543945 
	C191.074356,650.592590 191.349869,650.814758 191.625381,651.036865 
	C191.438004,651.216553 191.250610,651.396240 191.063232,651.575928 
	C190.855560,651.289795 190.647873,651.003601 190.619522,650.543945 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M552.260742,856.179565 
	C552.205322,855.467102 552.007202,854.380737 553.222656,854.586365 
	C553.769226,854.678833 553.636658,855.331238 553.065491,855.920288 
	C552.840576,856.085449 552.456360,856.164917 552.260742,856.179565 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M684.047607,872.423035 
	C684.444519,872.070679 684.890198,871.998230 685.668213,872.003662 
	C685.906311,872.709473 685.841125,873.451538 685.067627,873.508240 
	C684.765198,873.530457 684.421387,872.988159 684.047607,872.423035 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M198.001740,625.321167 
	C198.855927,624.942261 199.489334,625.275330 199.843506,626.431213 
	C198.967911,626.901245 198.254700,626.696350 198.001740,625.321167 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M197.455826,608.557007 
	C198.856781,609.051758 198.940369,609.841614 198.111450,611.125366 
	C197.471542,611.101990 196.979767,610.752930 196.224152,610.256836 
	C195.960327,610.109680 196.081085,610.024780 196.071945,609.951538 
	C195.869843,609.749268 195.676880,609.620239 195.772369,609.296143 
	C196.427536,608.943176 196.794266,608.785156 197.455826,608.557007 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M413.207672,846.084290 
	C413.544312,845.891113 413.890320,846.037903 414.498657,846.298035 
	C414.888641,846.733398 415.016327,847.055359 415.150848,847.637207 
	C414.183197,847.837830 413.384552,847.546814 413.207672,846.084290 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M190.595184,591.693970 
	C190.828629,592.103027 190.808121,592.319031 190.810333,592.859680 
	C189.964035,593.031677 189.095047,592.878967 188.021027,592.460999 
	C187.815994,592.195740 188.157364,591.855408 188.328857,591.686035 
	C189.111526,591.224243 189.725235,591.227600 190.595184,591.693970 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M197.229584,625.024658 
	C197.043320,624.467102 196.803131,623.736450 197.681000,623.666992 
	C197.788132,623.658569 197.937027,624.178101 198.028839,624.740906 
	C197.821838,625.031250 197.652557,625.033691 197.229584,625.024658 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M195.886810,609.130371 
	C196.060822,609.101196 196.126907,609.489868 196.094849,609.684082 
	C196.123215,609.552490 194.761185,609.919250 195.886810,609.130371 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M167.263733,577.607117 
	C167.226974,577.910156 166.977768,577.923706 166.355164,577.966431 
	C165.712280,577.926636 165.478073,577.794922 165.157944,577.437012 
	C165.708298,577.288208 166.379807,577.302917 167.263733,577.607117 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M516.843384,884.413208 
	C517.297363,884.293640 517.664185,884.546021 518.025452,885.180847 
	C517.598694,885.303894 517.177490,885.044495 516.843384,884.413208 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M461.514343,891.575195 
	C461.438965,891.620056 461.413574,891.431213 461.413574,891.431213 
	C461.413574,891.431213 461.589691,891.530273 461.514343,891.575195 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M459.362183,890.363831 
	C459.621277,890.341370 459.715485,890.439941 459.809692,890.538574 
	C459.605560,890.520691 459.401398,890.502808 459.362183,890.363831 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M457.433899,889.582520 
	C457.327606,889.455444 457.710785,889.399658 457.710785,889.399658 
	C457.710785,889.399658 457.540192,889.709534 457.433899,889.582520 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M174.040085,588.728027 
	C173.312607,588.765564 172.998795,588.426880 173.784332,587.886658 
	C174.054703,588.010376 174.095154,588.492493 174.040085,588.728027 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M150.256165,584.434082 
	C149.914093,584.135010 149.887054,583.889221 149.919418,583.277100 
	C150.281784,583.099426 150.584747,583.288086 150.956451,583.743408 
	C151.025162,584.010071 151.000092,584.005493 151.005615,584.014648 
	C150.864487,584.178406 150.717850,584.332947 150.256165,584.434082 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M191.050262,646.130005 
	C191.111176,646.134827 191.225403,646.267212 191.339630,646.399536 
	C191.260956,646.352234 191.182266,646.304871 191.050262,646.130005 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M190.127411,645.401611 
	C190.168747,645.185669 190.337921,645.144653 190.763199,645.154602 
	C191.019287,645.205627 191.006088,645.603943 191.001526,645.803223 
	C190.996948,646.002441 191.000000,646.000000 191.001526,645.998779 
	C190.714279,645.927429 190.465012,645.787109 190.127411,645.401611 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M423.598267,858.577759 
	C423.479370,858.675354 423.423004,858.324646 423.423004,858.324646 
	C423.423004,858.324646 423.717133,858.480164 423.598267,858.577759 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M368.382141,851.650635 
	C368.842773,851.630310 369.083862,851.823242 369.401672,852.269043 
	C369.509796,852.639221 369.499298,852.808899 369.234741,853.000122 
	C368.660675,852.897278 368.340668,852.772827 367.939819,852.395996 
	C367.858978,852.143677 368.204529,851.811523 368.382141,851.650635 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M369.381531,852.603882 
	C369.659088,852.852966 369.797913,853.236328 369.936737,853.619690 
	C369.666382,853.612427 369.395996,853.605103 369.053162,853.309814 
	C368.980682,853.021790 369.242798,852.738220 369.381531,852.603882 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M592.478271,869.518921 
	C592.459595,869.482788 592.496887,869.554993 592.478271,869.518921 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M322.278656,832.249084 
	C322.577942,832.250305 322.651764,832.488220 322.725555,832.726135 
	C322.501434,832.646057 322.277313,832.565918 322.278656,832.249084 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M700.674744,867.004517 
	C699.243652,867.057251 698.085571,867.020142 696.492920,866.983276 
	C693.583191,867.319153 691.100098,867.514526 688.292053,867.179321 
	C684.883667,865.002991 684.884888,865.005066 681.630859,866.943787 
	C681.501709,867.020752 681.301880,867.019775 681.142151,866.999207 
	C676.220825,866.365723 671.434692,867.742798 666.572754,867.954163 
	C665.273071,868.010681 663.624023,867.938843 663.571533,866.171875 
	C663.525574,864.626831 665.274292,864.905334 666.230469,864.458801 
	C668.171448,863.552246 670.079041,864.254822 672.020203,864.780396 
	C673.973938,865.309387 675.979736,865.468567 675.860962,862.145874 
	C679.218506,859.453064 682.899780,860.656982 686.366943,860.831970 
	C688.607605,860.945129 690.881653,861.255127 693.484009,861.572754 
	C694.864990,861.614624 695.796143,862.735535 696.695862,861.884155 
	C694.956238,861.440796 693.545776,860.695679 691.719116,860.084351 
	C689.048401,858.684387 686.413696,857.849854 684.466553,855.312134 
	C683.705750,853.815674 683.550476,852.557312 683.553833,850.902466 
	C684.556458,848.263184 687.185852,849.084412 688.666138,847.405273 
	C689.617554,847.699463 690.554443,848.363098 691.670593,849.321655 
	C692.049133,850.043945 692.336914,850.396851 693.063354,850.756653 
	C698.963440,853.996826 704.144043,857.848511 710.601318,860.062012 
	C711.584656,860.062378 712.143066,860.223328 712.814697,860.850342 
	C713.792542,861.720093 714.585815,862.354675 715.689575,862.962402 
	C716.677429,864.340149 717.354736,865.744690 718.297363,867.699646 
	C714.135132,867.806030 710.457703,868.619202 706.499023,869.565918 
	C707.187866,868.163208 705.805542,868.072876 705.150024,867.402588 
	C704.217163,865.745972 703.081543,866.617371 701.750122,866.907104 
	C701.454102,867.014343 701.499512,867.029236 701.508850,867.007263 
	C701.328064,866.961731 701.137878,866.938232 700.674744,867.004517 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M241.686523,829.023193 
	C244.722000,830.453186 248.356400,830.467224 249.276047,834.603394 
	C249.780869,836.873840 251.776138,834.846741 253.076019,834.984619 
	C254.601608,835.146362 256.179901,835.091431 256.707550,836.859131 
	C257.255524,838.694946 255.900940,840.134949 254.531738,840.075684 
	C252.053207,839.968445 247.403992,846.535828 247.244553,838.032227 
	C247.216614,836.542725 245.471970,836.857788 244.555954,836.303284 
	C242.479706,835.046570 239.869553,834.478943 239.039993,831.350830 
	C239.458969,829.947571 240.366669,829.439331 241.686523,829.023193 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M720.587891,859.384155 
	C720.355896,855.309509 721.886047,852.711731 725.731018,852.559570 
	C729.444946,852.412537 730.887451,854.910034 730.646362,858.718506 
	C729.244202,863.278503 726.528137,864.000427 722.435547,861.972656 
	C720.919373,861.592712 720.588440,860.771545 720.587891,859.384155 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M695.936523,891.024414 
	C693.696106,891.725098 691.834045,892.388184 689.915588,892.762634 
	C688.382263,893.061890 687.421875,892.145386 687.154053,890.631836 
	C686.925781,889.341370 687.339478,888.451172 688.743958,888.039246 
	C690.624939,887.487488 692.415771,886.554504 694.322693,886.188965 
	C695.807739,885.904297 697.824219,885.249573 698.677734,887.247498 
	C699.580566,889.360474 697.728943,890.069519 695.936523,891.024414 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M541.651489,930.153320 
	C542.077271,934.404419 545.374878,933.827454 548.595093,934.001343 
	C545.585754,938.009888 539.780701,938.699707 536.172241,935.388550 
	C534.354431,933.720398 534.838013,932.102356 537.023132,931.228882 
	C538.359131,930.694763 539.870483,930.599243 541.651489,930.153320 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M348.470093,895.671448 
	C344.787292,897.802673 341.633820,897.609863 339.021057,894.428040 
	C338.353058,893.614746 338.189240,891.892761 339.334259,892.356750 
	C342.394623,893.597046 345.675232,892.726501 348.620819,893.940063 
	C348.800995,894.014282 348.664581,894.856995 348.470093,895.671448 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M241.957245,828.638184 
	C241.534622,830.034790 240.808838,830.757690 239.319031,830.970581 
	C237.882446,830.354492 236.766129,829.708923 235.324905,829.031738 
	C235.157410,827.825195 235.314789,826.650330 235.737000,825.236572 
	C238.679291,824.817444 240.987076,825.303284 241.957245,828.638184 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M595.726013,926.800049 
	C595.096375,927.474365 594.551453,927.816589 593.981934,928.111206 
	C592.327576,928.967224 590.771179,928.867249 589.547485,927.379944 
	C588.857727,926.541626 588.800354,925.373657 589.617065,924.689880 
	C591.062744,923.479553 592.702026,923.972290 594.250977,924.685059 
	C595.025269,925.041382 595.660034,925.528076 595.726013,926.800049 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M268.235718,854.193665 
	C269.383575,851.864136 270.922577,851.397644 272.685608,852.846497 
	C273.383789,853.420288 274.183289,854.841797 273.241791,855.361328 
	C271.589447,856.272949 269.550171,856.867065 268.235718,854.193665 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M260.265137,841.239014 
	C262.727234,841.077087 263.265259,842.215393 262.628174,843.980896 
	C262.314880,844.849121 261.329437,845.089111 260.543549,844.790649 
	C258.578888,844.044373 258.743103,842.759888 260.265137,841.239014 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M552.367676,933.998901 
	C552.000000,933.250000 552.000000,932.500000 552.000000,931.375000 
	C553.443054,931.082642 555.358276,930.840820 555.446899,932.800537 
	C555.520020,934.419434 553.762512,933.809204 552.367676,933.998901 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M720.490417,859.160645 
	C721.060974,859.743652 721.480835,860.455688 721.998230,861.464966 
	C720.255554,863.581848 720.202148,861.991455 720.001038,860.333069 
	C720.052551,859.734070 720.167969,859.498108 720.490417,859.160645 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M330.694519,889.491577 
	C330.515625,888.086121 331.266357,888.064941 332.099121,888.361206 
	C332.293182,888.430298 332.518616,888.930969 332.447235,889.133423 
	C332.185028,889.877441 331.558380,889.888550 330.694519,889.491577 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M883.054749,817.791687 
	C882.379395,817.842590 881.849182,817.651428 881.150146,817.224915 
	C881.459595,815.957153 882.181763,815.222046 883.678833,815.006653 
	C884.010193,815.386658 884.020447,815.799988 884.046875,816.519348 
	C883.775269,817.066711 883.487549,817.308167 883.054749,817.791687 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M850.370361,816.048218 
	C850.882568,813.074829 852.815308,812.817932 855.439819,813.610352 
	C857.439575,814.214172 858.745483,813.186768 857.226624,811.380371 
	C855.241455,809.019531 856.475464,807.661072 858.195801,806.537048 
	C861.217651,804.562683 861.278687,802.042358 859.897827,799.233154 
	C858.720154,796.837463 859.227234,795.197021 861.541138,793.946167 
	C863.096375,793.105530 864.184448,792.064392 863.210449,789.993958 
	C862.838135,789.202515 862.681274,787.739624 863.987488,787.550781 
	C865.506409,787.331177 866.344299,788.570129 866.656067,789.928345 
	C868.179932,796.567383 869.816467,797.156067 876.677246,793.167603 
	C879.448059,795.175903 880.656128,798.981628 884.942993,798.789490 
	C885.657471,798.757385 885.881897,800.099365 885.376648,800.883057 
	C884.934448,801.568970 884.394531,802.477844 883.715942,802.681030 
	C876.895874,804.723389 872.493835,811.619324 864.887634,812.030762 
	C863.429993,812.109558 862.316895,812.896423 861.626770,814.048523 
	C858.738098,818.870667 855.017700,818.865906 850.370361,816.048218 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M848.288025,818.856567 
	C848.060791,817.867615 847.836670,816.603943 849.658813,816.058838 
	C849.983398,817.145020 849.551941,818.071716 848.288025,818.856567 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M874.369324,743.994385 
	C873.959412,738.922852 877.696838,735.300720 880.204590,731.866028 
	C884.580688,725.872375 885.154358,719.380005 885.904236,712.724792 
	C886.229614,709.836914 886.726013,708.289978 890.067078,707.844177 
	C894.349670,707.272644 895.795776,703.917236 894.731750,700.030762 
	C894.060181,697.577698 894.243469,695.852783 896.181274,694.313782 
	C898.524109,692.453125 898.069153,689.825195 897.969971,687.379456 
	C897.789978,682.942444 899.200439,679.422119 901.502502,675.419495 
	C904.349487,670.469299 907.867126,665.244568 907.978455,659.015503 
	C908.016357,656.896606 908.730896,655.103638 910.009216,653.513123 
	C911.522156,651.630737 912.671021,649.314392 912.660034,647.143799 
	C912.621460,639.515564 916.724548,633.227783 918.801819,626.303162 
	C921.279175,618.044922 923.368896,609.814575 924.319336,601.127747 
	C924.992493,594.975647 924.718323,588.398254 928.893250,582.507507 
	C932.285522,577.721069 932.147644,571.230652 931.585144,565.186462 
	C930.911865,557.950745 928.957031,550.473389 935.047058,544.315613 
	C935.573181,543.783691 936.134583,542.589294 935.888672,542.100647 
	C933.784851,537.920654 935.382324,533.572449 935.461914,529.398315 
	C935.566406,523.916077 936.384705,518.442383 936.252563,512.901184 
	C936.135071,507.971252 936.057739,503.071594 935.695679,498.152313 
	C935.385559,493.937622 934.663086,489.520264 934.326599,485.317200 
	C933.906433,480.068634 933.675354,474.480194 931.941833,469.296967 
	C928.747925,459.747559 927.212708,449.915985 927.030884,439.878265 
	C926.955200,435.700195 924.873291,432.039917 924.790344,427.743805 
	C924.503845,412.916931 919.086670,399.093201 915.402405,384.945831 
	C914.237976,380.474548 913.354309,375.844513 910.915283,371.772186 
	C910.317017,370.773285 909.638550,369.560791 910.420105,368.491638 
	C913.519043,364.252380 911.882996,360.851349 908.778015,357.564301 
	C905.876587,354.492767 906.026733,349.787384 903.042847,346.357910 
	C908.867188,346.506561 912.065552,349.014130 913.687012,355.367523 
	C914.807068,359.756500 918.680969,363.665924 920.832397,368.132996 
	C922.855469,372.333618 925.248230,376.238800 924.062866,381.214294 
	C923.568787,383.288177 924.512451,384.975616 926.002563,386.574615 
	C929.603638,390.438904 931.987854,394.625977 927.924316,399.695984 
	C927.144958,400.668335 927.203369,401.584869 928.589783,401.894043 
	C932.905029,402.856262 932.929199,406.716064 933.987366,409.844879 
	C937.871826,421.330200 936.934265,433.607513 939.771118,445.293335 
	C940.830933,449.658783 940.026428,454.235077 938.644409,458.569458 
	C937.693787,461.550751 937.653259,464.429077 939.103577,467.275940 
	C939.475220,468.005402 940.010681,468.909698 939.863586,469.605286 
	C938.273499,477.124268 943.044250,483.604675 943.291016,490.866760 
	C943.373474,493.293060 943.390381,494.993958 941.357544,496.419678 
	C939.925476,497.424103 939.814697,498.893463 940.306274,500.444916 
	C944.178467,512.665222 942.921082,525.014282 941.424194,537.412292 
	C940.923279,541.561096 939.532043,546.004456 943.095764,549.704895 
	C944.172913,550.823364 944.238953,552.227478 943.098267,553.275269 
	C936.935486,558.936462 939.369995,565.892334 940.461731,572.429871 
	C940.932312,575.248291 941.067505,577.906921 939.736206,580.129517 
	C935.608459,587.021118 936.078857,595.184753 933.136597,602.383179 
	C930.497375,608.840210 930.776123,615.702148 931.045593,622.480835 
	C931.253723,627.717041 928.131531,631.596436 925.965454,635.803833 
	C924.041687,639.540710 922.207153,643.239136 921.969666,647.443604 
	C921.640686,653.268005 918.407288,657.516357 914.828369,661.637085 
	C913.948853,662.649658 912.687744,663.672241 913.080872,665.054260 
	C915.284424,672.800049 907.308777,677.384338 907.130371,684.408691 
	C907.109680,685.223450 905.682190,686.146179 904.740417,686.765564 
	C902.331604,688.349792 901.445862,689.803650 903.082703,692.866028 
	C905.191284,696.810913 902.428162,699.570862 899.400818,701.870239 
	C898.898926,702.251526 898.542297,702.824158 898.059265,703.654175 
	C898.741089,705.709778 898.253967,707.053772 896.387817,708.051636 
	C893.555176,708.578735 892.799683,710.276550 893.387939,712.483093 
	C895.440491,720.182007 894.799744,721.884583 889.013000,726.693176 
	C885.358154,729.730347 881.352356,732.957214 884.675354,738.706726 
	C884.898987,739.093750 884.438904,739.875977 884.296814,740.474365 
	C879.897949,738.347168 878.346313,743.963989 874.369324,743.994385 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M844.425903,803.000000 
	C841.905090,800.955750 843.918152,799.822876 845.006836,798.494629 
	C848.281494,794.499329 847.772217,792.241699 843.157349,790.167542 
	C842.121277,789.701843 841.020935,789.257202 840.162231,788.546631 
	C838.831970,787.445862 839.230896,786.248779 840.377686,785.177917 
	C841.433655,784.191833 842.334412,784.109131 843.220032,785.426453 
	C843.676086,786.104736 844.278320,786.700989 844.882507,787.260925 
	C850.454834,792.424866 850.730957,792.395935 855.651794,786.168274 
	C856.083618,789.531799 856.762146,793.311523 851.209900,793.287354 
	C850.093994,793.282532 849.771423,794.878540 850.776306,795.535339 
	C852.508911,796.668030 854.555115,797.604797 853.219177,800.057068 
	C851.914490,802.452087 849.601379,803.355530 846.847046,803.011963 
	C846.191650,802.930237 845.517334,802.999939 844.425903,803.000000 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M856.366943,784.957092 
	C856.719299,781.877930 858.561951,778.600525 852.997620,777.671265 
	C850.326416,777.225098 851.559631,772.694031 855.623901,770.011719 
	C855.646423,772.772949 857.588562,774.668579 858.946045,776.559570 
	C863.028137,782.246155 863.166199,782.147034 856.366943,784.957092 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M913.206482,326.976379 
	C914.043701,329.684509 915.098938,332.451324 911.965820,333.670837 
	C910.444946,334.262817 907.460938,335.727386 907.041626,334.394379 
	C906.267639,331.934387 906.319031,328.632050 907.398743,326.322021 
	C908.689514,323.560577 911.042053,326.338409 913.206482,326.976379 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M895.999023,708.371704 
	C896.576721,706.867981 897.155884,705.736023 897.867554,704.302002 
	C900.203613,704.254883 901.592407,705.475891 901.897705,707.655334 
	C902.193787,709.768433 900.369019,710.240601 898.976929,711.002808 
	C896.288879,712.474487 895.940552,710.882141 895.999023,708.371704 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M865.392944,761.990845 
	C865.861267,758.748108 864.462769,754.404663 869.654785,753.038940 
	C869.660767,754.876099 869.180664,756.740173 869.020020,758.631409 
	C868.841187,760.736938 867.959961,761.920471 865.392944,761.990845 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M864.132080,770.758118 
	C865.340210,771.929810 866.384644,772.890320 865.008728,774.220642 
	C864.096375,775.102661 862.913757,775.205566 861.866150,774.632812 
	C860.831482,774.067078 860.087952,772.942505 860.398682,771.807617 
	C860.883545,770.036926 862.251282,769.762878 864.132080,770.758118 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M870.369080,752.948975 
	C870.256287,750.019531 869.910278,746.789551 873.636597,745.047791 
	C872.500549,747.462402 874.380981,751.320740 870.369080,752.948975 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M861.353516,767.956055 
	C861.404419,765.665771 862.937988,764.007324 864.702942,762.154785 
	C864.867004,764.507996 864.451111,766.858398 861.353516,767.956055 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M845.155640,806.655945 
	C844.943665,807.256470 844.701477,807.514465 844.228638,807.904785 
	C842.760071,808.427002 842.206238,807.681458 841.886536,806.350830 
	C842.487976,805.705994 842.993103,805.417542 843.746704,804.970581 
	C844.761963,805.018982 845.089478,805.556458 845.155640,806.655945 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M833.797974,202.223389 
	C834.724548,202.844971 834.657959,203.418030 833.915344,203.702820 
	C833.574646,203.833466 832.825623,203.653900 832.676331,203.384872 
	C832.260010,202.634903 832.798462,202.283981 833.797974,202.223389 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M903.798096,323.223511 
	C904.724792,323.845154 904.657837,324.418213 903.915344,324.702911 
	C903.574646,324.833557 902.825623,324.653961 902.676331,324.384949 
	C902.260193,323.635101 902.798523,323.283997 903.798096,323.223511 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M867.577881,768.045166 
	C867.191895,768.752075 866.802856,768.758728 866.458740,768.255920 
	C866.386597,768.150513 866.389099,767.906128 866.463928,767.803284 
	C866.821777,767.311096 867.210510,767.329041 867.577881,768.045166 
z"
    />
    <path
      fill="#EFEFEE"
      opacity="1.000000"
      stroke="none"
      d="
M810.773743,161.211945 
	C810.712830,162.208252 810.365234,162.749023 809.615601,162.332169 
	C809.346558,162.182571 809.165649,161.436890 809.295471,161.097534 
	C809.578369,160.358246 810.150208,160.287277 810.773743,161.211945 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M446.421204,88.987656 
	C446.151642,87.899742 446.674957,87.201485 447.858948,87.015427 
	C453.091705,86.193130 458.318604,85.333366 463.773804,84.743896 
	C463.287537,88.012352 460.843811,87.928299 458.544586,87.985413 
	C454.622070,88.082832 450.661377,87.666534 446.421204,88.987656 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M476.375000,85.999695 
	C475.771454,84.895119 475.201996,83.722878 475.405029,82.704651 
	C475.632751,81.562576 475.523895,79.804649 477.060944,79.523621 
	C477.783936,79.391434 478.717621,80.310829 479.531219,80.795059 
	C481.520905,81.979271 479.835754,82.864059 479.298920,83.881744 
	C479.083221,84.290657 479.102722,84.823608 479.007568,85.650352 
	C478.250000,86.000404 477.500000,86.000206 476.375000,85.999695 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M438.202972,90.234375 
	C438.701538,87.115753 440.655090,87.936531 442.756073,88.735428 
	C441.677246,90.140594 440.202728,90.808647 438.202972,90.234375 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M413.212006,96.237076 
	C413.340118,92.843483 416.150909,95.241280 417.684540,94.028763 
	C417.451294,96.632095 415.354095,96.399582 413.212006,96.237076 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M351.338562,119.961731 
	C351.081696,118.474686 351.742706,117.409927 353.662476,117.038361 
	C353.918152,118.524567 353.257507,119.589653 351.338562,119.961731 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M314.175690,134.217987 
	C314.469513,133.681839 314.939026,133.364883 315.704285,133.023972 
	C315.810059,133.891571 315.843903,135.039825 314.175690,134.217987 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M444.250000,89.000237 
	C444.272186,88.894203 444.544373,88.788399 444.908264,88.841301 
	C444.833344,89.000160 444.666656,89.000313 444.250000,89.000237 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M489.391663,75.841843 
	C487.306061,73.898697 488.308746,72.798065 490.173279,72.245056 
	C491.440918,71.869087 492.536041,72.686501 492.701843,73.923317 
	C493.016235,76.268692 491.186615,75.905373 489.391663,75.841843 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M849.084961,545.696289 
	C848.848267,546.848755 848.571289,547.654785 848.102173,548.727356 
	C847.528931,549.418579 847.177185,549.860840 847.027832,550.719177 
	C846.911133,553.545410 844.891174,555.122803 843.814331,557.559814 
	C843.686340,560.102478 842.719604,561.869751 840.863281,563.246948 
	C840.542053,563.122620 840.248718,563.309204 840.215576,563.479858 
	C840.149048,565.250244 839.454773,566.558960 838.242554,567.986572 
	C838.020691,568.466736 837.952942,568.668030 837.750610,569.157227 
	C837.318481,570.005737 836.987488,570.546997 836.500610,571.366455 
	C835.140198,573.140015 833.911499,574.616089 832.786072,576.578674 
	C829.842651,581.779968 825.733154,585.561523 821.630798,589.811096 
	C819.226562,592.172913 816.948181,593.971375 813.530579,592.754761 
	C813.056580,592.653503 813.617920,593.808533 813.632263,593.142029 
	C816.317139,593.303467 815.859924,595.389465 815.994995,597.432617 
	C814.643616,602.249817 814.639587,602.181213 819.354065,601.905334 
	C822.094116,601.745056 825.272949,600.977051 825.984680,605.462097 
	C825.918213,606.034302 825.866577,606.259155 825.679871,606.800659 
	C824.914124,608.048096 824.232483,608.946533 823.552490,610.250732 
	C821.681641,612.090454 820.542358,614.151489 818.357666,615.661987 
	C816.337524,615.998291 814.815918,616.627930 813.443848,617.619629 
	C811.985046,618.674011 810.405396,618.947144 808.730957,618.266235 
	C806.605103,617.401794 803.833801,616.824463 804.229492,613.794922 
	C804.671265,610.412781 807.479187,611.064026 809.745850,611.045349 
	C804.550964,611.306396 798.990356,610.595276 796.283264,616.942810 
	C795.784241,618.112732 794.613342,619.022400 793.580139,619.844604 
	C787.117371,624.988098 783.353699,631.469849 783.185547,639.898560 
	C783.141357,642.112854 782.223145,644.284302 780.435486,645.592407 
	C774.839905,649.687134 769.734558,654.676514 762.621094,656.204529 
	C760.904785,656.573303 759.473511,657.198730 758.341431,659.037720 
	C757.698486,659.912170 757.224731,660.458435 756.466187,661.233276 
	C755.083923,662.199585 754.130859,663.082764 753.302368,664.136841 
	C752.270386,665.449890 750.960632,666.065308 749.468201,664.881348 
	C748.026123,663.737244 748.138123,662.188965 749.103455,660.902405 
	C752.062500,656.958618 754.050537,652.329773 757.944885,648.690002 
	C759.525391,648.322876 760.838440,648.144226 760.613037,646.004028 
	C760.291626,642.938477 759.221313,643.679321 757.867676,645.023499 
	C756.631653,646.250916 755.407837,648.235413 753.346313,646.608398 
	C751.509155,645.158447 752.547974,643.262085 753.275269,641.608643 
	C756.218872,634.916565 759.839417,628.613098 764.519287,622.544189 
	C775.136963,618.585754 783.435425,610.769287 794.081116,607.584839 
	C796.223633,606.943970 797.518188,605.061157 798.825684,603.298584 
	C802.032959,598.974976 802.011719,598.627441 797.243652,596.001404 
	C795.035339,594.785156 793.676941,592.586792 791.419250,591.374451 
	C789.879395,590.547485 790.658325,589.131042 791.516418,587.958191 
	C792.873352,586.103577 794.358337,584.312195 795.078552,581.676270 
	C796.416809,578.043335 799.312866,577.992554 802.507202,577.814697 
	C803.124939,577.876160 803.368225,577.913879 803.965393,578.036316 
	C807.802979,579.217896 812.269165,578.874512 812.955566,584.068237 
	C813.526550,582.762451 814.698059,582.177490 815.743713,581.429260 
	C819.179016,578.971008 819.104309,577.256104 815.488098,574.828003 
	C814.088928,573.888550 811.713440,573.540100 814.025513,570.965027 
	C815.389648,569.445679 813.375732,569.055725 812.503418,568.724609 
	C809.592468,567.619934 807.327454,565.807739 805.549561,562.887695 
	C805.013367,562.136780 805.910095,560.275574 803.873413,561.570374 
	C802.768738,562.611023 801.726501,562.739685 800.290283,562.460083 
	C798.875793,562.028198 798.028503,562.511414 796.948486,563.357178 
	C793.858948,563.510864 793.902954,561.507385 794.113586,559.663269 
	C794.437012,556.832031 795.311646,554.140564 797.194275,551.377014 
	C794.229492,553.926575 792.804321,557.063843 790.816223,559.784302 
	C789.635620,561.399719 788.275879,563.828247 786.039429,560.486755 
	C784.818359,558.662292 783.744019,560.049561 783.110229,561.208435 
	C782.234985,562.808716 781.640442,564.572571 780.379578,565.940491 
	C779.182190,567.239563 778.235535,569.095337 775.674927,567.940918 
	C774.792908,564.116699 776.012512,560.618042 775.720581,556.705200 
	C775.794556,556.081665 775.836182,555.835388 775.963806,555.228333 
	C776.602966,553.515137 776.690002,552.064758 777.003540,550.302307 
	C778.695557,544.628967 780.223389,539.332458 782.895264,534.313293 
	C784.325256,531.627014 784.053955,527.985535 786.465332,525.116821 
	C789.356567,525.527954 791.557861,527.162903 794.087280,528.112915 
	C796.711182,529.098450 797.131653,532.078369 794.452820,533.968201 
	C792.610962,535.267517 794.690247,535.769226 794.694702,536.943298 
	C795.205994,538.010925 795.463928,538.886475 796.890625,538.785645 
	C800.692383,537.910522 801.316956,534.422119 803.570129,531.987671 
	C804.063293,531.727783 804.287781,531.681641 804.836304,531.749329 
	C805.622009,532.399902 805.577271,533.039856 805.530762,533.680115 
	C805.854797,533.265137 805.815613,532.735107 805.862366,532.275330 
	C805.977783,531.140076 806.139771,530.032227 806.756592,528.662598 
	C807.321899,527.473083 807.836548,526.672485 808.909668,525.894348 
	C812.691772,532.256531 817.328674,536.908813 824.534302,539.970703 
	C829.870056,542.238037 829.344360,543.870422 825.573425,548.983643 
	C824.591797,550.314697 824.741455,551.117615 826.196228,551.595154 
	C827.569946,552.046143 828.681091,553.297913 830.951111,552.908997 
	C828.882446,544.497375 832.195679,540.458801 841.048706,539.857483 
	C846.077881,539.515808 847.274292,539.431335 849.084961,545.696289 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M877.058289,299.341553 
	C883.193481,299.508942 889.710266,307.230713 889.903442,314.908417 
	C889.994385,318.524292 890.706421,321.737946 892.359802,324.917999 
	C893.240234,326.611359 893.874756,328.440216 893.347412,330.631317 
	C891.667969,337.609497 895.177612,343.210052 899.059448,348.351868 
	C900.859375,350.735962 901.361694,353.132385 902.222656,355.785217 
	C904.202026,361.884430 904.604492,368.464569 907.351196,374.219452 
	C910.673767,381.181061 911.862915,388.683929 913.820801,395.977844 
	C914.543640,398.670471 915.849487,401.484589 915.835999,404.075531 
	C915.806763,409.704071 916.958740,414.640045 919.824829,419.512817 
	C920.948669,421.423492 919.988037,424.444885 920.501892,426.848145 
	C924.215088,444.211426 926.921387,461.762115 928.538452,479.400360 
	C928.943481,483.818756 928.888611,488.784821 928.191895,493.526917 
	C926.932312,502.099548 926.947327,510.513245 928.417297,519.359131 
	C930.029480,529.060913 928.421082,539.381531 927.532288,549.371765 
	C927.201294,553.091980 927.354309,556.548828 927.908264,560.168762 
	C929.010132,567.369263 927.636475,574.535828 925.585022,581.275452 
	C920.829895,596.896667 919.616211,613.273499 913.831055,628.806335 
	C910.786377,636.981018 906.665649,644.914917 904.613342,653.521240 
	C903.859253,656.683594 901.087891,655.911072 898.419800,656.000000 
	C901.407166,645.174316 904.490845,634.276733 906.234375,623.024414 
	C906.578613,620.802856 907.196045,618.366760 909.014160,616.717407 
	C912.140869,613.881042 911.645996,610.891968 909.403015,607.966675 
	C908.010925,606.151062 907.390076,604.436035 908.991638,602.601135 
	C915.098633,595.604187 916.513916,587.760498 914.289307,578.778198 
	C913.402893,575.199280 913.230957,571.143250 915.208862,567.549316 
	C916.146362,565.845886 916.064636,564.002197 915.984314,562.182068 
	C915.717590,556.135925 916.182434,550.244141 918.925415,544.690674 
	C920.545654,541.410278 920.006287,538.917664 916.043335,537.847717 
	C914.800964,537.512329 913.289734,537.102356 913.198303,535.459473 
	C913.109009,533.856567 914.443237,533.176453 915.688965,532.689697 
	C918.913940,531.429504 919.636536,529.429077 917.771362,526.480225 
	C916.199524,523.995178 917.619141,521.392822 918.319214,519.290894 
	C919.574097,515.523682 917.622986,511.170593 920.658203,507.804840 
	C920.937256,507.495453 920.434082,506.235840 920.024170,505.588501 
	C916.312134,499.726227 916.770569,493.771942 919.501953,487.737823 
	C919.570618,487.586212 919.759521,487.376953 919.714355,487.302002 
	C916.260376,481.576752 918.268982,474.577576 915.385498,468.739777 
	C914.595886,467.140930 913.425415,466.449707 911.901978,468.102051 
	C911.308411,468.745758 910.498535,469.229279 909.751770,468.479309 
	C908.916870,467.640930 909.671509,466.950897 910.315552,466.449524 
	C916.384949,461.724762 915.899353,455.683868 913.667847,449.375061 
	C913.088196,447.736542 912.660461,446.063171 913.140137,444.497528 
	C915.957886,435.301453 914.355896,426.782043 909.895081,418.457764 
	C908.463196,415.785767 909.846680,412.821594 911.390015,410.525787 
	C913.151123,407.906036 912.930847,406.177612 911.243530,403.427307 
	C909.127563,399.978546 906.465820,397.070465 909.308960,391.290283 
	C911.779785,386.267151 908.002747,379.272797 904.339905,373.839417 
	C900.135437,367.602478 896.365540,361.070831 892.485962,354.619598 
	C892.108398,353.991760 891.901428,352.675781 892.267029,352.277466 
	C895.797974,348.431213 892.315002,345.444489 890.445190,343.291687 
	C887.581604,339.994690 887.345032,336.201202 887.502686,332.676086 
	C887.649902,329.382874 887.122925,326.899017 885.076172,324.518646 
	C883.615051,322.819305 882.522461,320.801147 883.117371,318.563843 
	C885.144958,310.939423 881.562500,305.195984 877.058289,299.341553 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M724.211609,795.392395 
	C720.114441,797.510254 715.123657,797.785828 713.959839,803.515015 
	C714.125977,804.204468 714.196228,804.508301 713.968994,805.173340 
	C705.392944,807.255310 697.068359,806.503906 688.756042,805.929382 
	C687.633301,805.851685 686.514282,805.468384 684.913086,805.469116 
	C677.591919,805.218689 670.788635,804.197144 663.536133,803.481445 
	C662.847412,803.402954 662.575439,803.361206 661.905640,803.229187 
	C660.493286,802.849670 659.538391,802.371338 658.116089,802.098999 
	C652.647461,801.076843 647.863770,799.009033 642.810791,797.925598 
	C641.605042,797.666992 640.617981,797.139954 640.208008,795.455994 
	C640.194275,793.974304 640.610779,793.023193 641.524902,791.875366 
	C643.856873,790.356262 646.220642,790.530273 648.827271,790.147461 
	C650.438110,790.411011 651.476624,790.937561 652.068115,788.888916 
	C652.753662,786.514526 654.336182,784.528259 657.577576,784.663635 
	C658.150696,785.094238 658.142334,785.366882 658.037048,785.686096 
	C657.963013,785.910522 658.055664,786.071594 658.053528,786.082947 
	C657.926270,786.737366 658.923096,786.153931 658.263489,786.250793 
	C658.262817,786.250916 658.188232,786.102051 658.126099,785.494141 
	C657.243164,783.374390 657.171265,781.490906 658.416748,779.174255 
	C652.063354,780.175415 651.529480,774.679016 648.498352,771.674622 
	C648.168213,771.282288 648.026978,771.139404 647.677490,770.794006 
	C647.183289,770.356567 646.880432,770.146118 646.302002,769.884766 
	C645.880981,769.753906 645.713013,769.706787 645.304993,769.573059 
	C644.872498,769.604797 644.351501,768.655090 644.492676,769.952881 
	C644.523254,771.423157 644.437256,772.624756 644.924133,774.107300 
	C646.614258,779.214478 642.413696,779.733948 639.509766,781.588257 
	C638.481873,781.926086 637.775513,781.969421 636.722412,781.784119 
	C636.178345,781.599304 635.971191,781.502014 635.476746,781.228394 
	C633.435242,781.309143 630.787231,776.625732 629.934937,782.335815 
	C629.733337,783.800781 629.688171,784.954102 629.017456,786.286499 
	C620.012329,787.653809 613.153870,792.717224 605.868530,797.644043 
	C605.411743,797.933594 605.227539,798.040161 604.752869,798.247070 
	C604.106934,798.538086 603.709351,798.046631 603.516785,798.255737 
	C603.342529,798.445068 603.666687,798.686646 604.378967,798.661926 
	C605.740417,799.186890 606.477478,799.928223 606.736572,801.435730 
	C606.620850,802.784424 606.016907,803.476624 604.946289,804.258850 
	C604.424927,804.562744 604.206177,804.668762 603.641113,804.895996 
	C602.578369,805.220703 601.842957,805.322449 600.735535,805.501465 
	C597.216797,806.498352 594.057739,807.341125 590.756775,807.511841 
	C588.413147,807.632996 586.885803,806.911682 587.187866,803.856384 
	C589.105774,801.147766 592.055542,800.262451 594.109497,798.213806 
	C594.380066,797.943909 594.843750,797.990662 595.230347,797.945374 
	C594.610107,797.770874 593.942505,797.853882 593.043396,797.396240 
	C592.260498,792.257690 594.815491,788.010193 599.089966,787.031677 
	C600.869568,786.624268 602.561218,785.848633 604.718994,785.648132 
	C605.270813,785.264465 605.591187,785.335205 605.902222,785.362732 
	C605.691895,785.074524 605.442566,785.100769 604.826416,785.436157 
	C601.949768,780.907043 602.439819,778.713318 606.975281,777.048462 
	C613.524048,774.644714 620.536072,774.261658 627.349731,773.045776 
	C633.119141,772.016174 636.397034,768.513550 636.188354,762.516174 
	C633.659424,763.343689 631.514282,765.153137 628.740234,765.016479 
	C627.029968,764.932251 625.066650,766.487915 623.672363,764.569275 
	C622.290039,762.666992 623.347229,760.784668 624.438599,758.672119 
	C625.794739,755.843445 626.986938,753.322144 630.408875,752.569824 
	C633.119507,754.571106 637.026611,754.085876 638.704712,755.602966 
	C643.721252,760.137756 645.807373,755.569397 648.811951,753.453979 
	C651.676392,751.437073 652.944580,747.959534 655.869751,745.916321 
	C658.914246,743.789734 660.652222,744.044922 661.362061,748.118530 
	C661.823120,748.400391 662.113953,748.342712 662.588135,747.950378 
	C663.125549,747.544128 663.473328,747.354065 664.151733,747.093506 
	C664.916992,746.907471 665.372314,746.866333 666.192932,746.881531 
	C668.329041,747.265320 669.903564,747.914307 670.706909,750.123840 
	C670.728088,750.995972 670.615540,751.477844 670.216553,752.251465 
	C669.714417,752.945618 669.384705,753.304565 668.759766,753.878052 
	C665.671753,755.860229 661.975464,755.236389 659.352295,757.518005 
	C658.002563,758.691956 656.527649,759.750183 655.710632,762.075195 
	C659.704590,760.667908 662.623962,755.201599 667.571960,759.734131 
	C669.593567,763.489990 672.961365,764.490356 676.366760,765.258728 
	C678.952637,765.842224 681.603333,766.160278 684.229797,766.569946 
	C689.587585,767.405640 691.554932,771.417175 688.923157,776.800171 
	C687.994873,777.732361 687.131836,778.090698 685.953247,778.554810 
	C684.223938,779.101379 682.946838,779.723145 682.103210,781.437805 
	C681.812134,781.891602 681.687500,782.070312 681.354126,782.504883 
	C680.482849,783.805359 678.393982,784.888611 679.257446,785.773376 
	C681.526428,788.098633 682.740723,792.119080 687.492188,791.643799 
	C694.298218,792.491882 700.562134,792.793762 706.962097,790.428467 
	C711.622192,788.706177 716.836365,790.512451 721.832886,790.157776 
	C724.857117,789.943176 725.598816,792.353943 724.211609,795.392395 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M853.184204,429.778137 
	C852.547607,430.445618 852.088135,430.740967 851.340332,431.080505 
	C848.552673,431.058624 846.030823,430.911713 843.893799,432.585327 
	C842.718567,433.505615 841.784058,433.332367 840.800171,431.800964 
	C838.697021,427.904419 840.093445,424.428467 841.065979,420.917847 
	C841.359192,419.859650 842.138062,418.466187 840.725769,417.830322 
	C839.361511,417.216064 838.721863,418.668182 838.067749,419.563873 
	C833.644836,425.619446 830.262085,432.302673 826.913391,438.960419 
	C825.534119,441.702637 824.060181,444.164001 821.582764,446.426819 
	C819.727417,447.805267 819.963135,448.895782 821.720947,450.135071 
	C823.722412,451.673737 823.986694,453.274506 822.842896,455.143250 
	C822.020203,456.487305 821.548157,457.869232 822.612061,459.311249 
	C822.350220,459.944763 823.708069,459.489563 823.043091,459.537354 
	C822.824768,459.553040 822.959167,459.381073 822.930969,459.067688 
	C822.776733,457.354065 824.169373,456.810699 825.201599,456.087097 
	C827.489990,454.482941 829.112122,452.809235 826.766235,450.035339 
	C826.440308,449.649933 826.379089,449.123322 826.362061,448.618896 
	C826.352905,448.349548 832.876465,439.445435 833.142151,439.449799 
	C835.823120,439.493591 836.370667,437.732819 836.682129,435.551849 
	C836.977661,433.482208 836.925049,430.889526 840.599121,431.692932 
	C841.783447,433.589294 842.766479,433.506561 844.415649,432.584991 
	C846.103943,431.641602 848.117371,431.725098 848.238892,434.458496 
	C848.476501,439.800415 839.511108,451.170776 834.169922,452.380005 
	C835.485046,452.397552 836.443848,452.840088 836.764282,454.554749 
	C836.715210,455.655945 836.416992,456.306976 835.736450,457.167603 
	C830.053650,460.412598 830.053650,460.412598 835.471191,467.477875 
	C835.558594,468.053772 835.549194,468.289124 835.409546,468.852356 
	C833.695801,470.924347 831.527100,471.542908 829.189636,472.096039 
	C830.087891,472.736237 830.937256,472.716278 832.037598,473.057678 
	C832.566345,473.278870 832.766174,473.391785 833.204224,473.750580 
	C835.286316,475.739014 831.734253,478.080566 834.267395,480.053223 
	C834.542480,480.549103 834.633301,480.758759 834.803101,481.303589 
	C835.294250,484.473480 832.403503,485.936615 831.951172,488.789062 
	C831.429016,490.234680 830.995972,491.338074 829.645386,492.181396 
	C827.688660,491.836426 826.503784,490.557831 824.624817,489.831421 
	C823.726013,489.169922 823.322937,488.568115 822.980591,487.504150 
	C822.801270,486.417114 822.861816,485.698792 823.161743,484.664246 
	C823.935974,483.255432 824.828613,482.240326 824.224731,480.503418 
	C823.707458,477.538330 820.527405,479.115753 819.408325,476.945496 
	C818.131531,470.565735 818.306946,469.407532 820.745789,466.087799 
	C816.021790,462.350159 810.446655,459.540558 808.419128,453.141968 
	C807.644104,450.696106 808.293335,448.605042 808.938416,446.355042 
	C809.830994,443.241241 809.047791,441.088898 804.975342,440.937317 
	C804.439636,440.696381 804.238586,440.572296 803.814514,440.172913 
	C802.850464,437.561768 804.395386,436.285065 805.783325,434.808624 
	C809.038330,431.346252 809.290649,428.140747 806.538330,424.060333 
	C806.086060,423.706055 805.916260,423.550781 805.543213,423.117859 
	C804.573730,420.447876 803.531799,418.283264 800.616821,417.206543 
	C798.994080,414.255890 794.425415,411.765686 800.086426,408.445099 
	C802.134766,407.875885 803.533447,406.977966 804.741699,405.250519 
	C808.499512,405.047180 810.090454,406.876984 808.715576,410.034668 
	C807.491882,412.845062 809.784973,414.273682 810.556946,416.519958 
	C813.715637,415.984894 816.533020,414.906128 819.882446,415.774841 
	C821.887146,418.392914 820.719543,420.736847 819.888367,422.983643 
	C818.472595,426.811127 816.647644,430.472229 814.808472,434.501099 
	C814.429871,435.440491 814.009399,435.951141 813.574646,436.781403 
	C813.867676,437.489655 812.594849,437.417816 813.315796,437.657776 
	C813.561401,436.813354 813.762573,436.253235 814.326538,435.502655 
	C817.468811,428.785217 820.544556,422.391968 822.876343,415.274597 
	C826.804504,403.992035 829.426270,392.772827 831.383057,381.387787 
	C831.605713,380.092224 831.675354,378.757172 832.733093,377.317627 
	C833.240540,375.255066 833.501221,373.511871 833.701416,371.395142 
	C833.458069,370.210144 833.668823,369.447144 834.062500,368.313019 
	C835.030029,363.513367 834.391663,359.045319 834.607605,354.603241 
	C834.865967,349.287872 836.715820,348.274750 841.543152,350.643463 
	C844.149109,351.922150 845.257263,354.272217 846.440552,356.638306 
	C847.399719,358.556122 848.214844,360.548096 849.627625,362.684875 
	C850.667236,378.465240 851.682861,393.934784 847.966919,409.608856 
	C845.123291,414.364166 846.496033,418.817780 847.369934,423.850952 
	C849.547180,420.424622 849.918335,416.859192 851.724854,413.980225 
	C853.177612,411.664978 854.994751,409.721161 857.277466,413.812988 
	C855.991150,418.721100 854.578613,423.305542 853.163025,428.248352 
	C853.227051,428.873138 853.294189,429.139557 853.184204,429.778137 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M469.914062,143.273346 
	C479.032898,132.329300 489.520416,123.481712 502.894653,118.265358 
	C487.219604,131.911362 476.881531,148.868927 469.902618,168.453934 
	C469.702454,170.792053 469.210510,172.671127 468.179626,174.689148 
	C467.909882,175.259003 467.850769,175.587326 468.057373,175.710114 
	C468.356476,175.402359 468.521210,175.318375 468.968628,175.171509 
	C469.875336,175.056091 470.483337,175.146454 471.393829,175.437820 
	C474.203705,176.600632 473.886078,179.506973 475.655060,181.453751 
	C478.042023,184.979675 476.016327,189.408844 478.918915,192.834534 
	C476.407745,197.126556 474.398590,197.273849 470.265625,193.539215 
	C469.830231,192.888428 469.584808,192.535995 469.326447,192.192734 
	C469.309753,192.170486 469.176239,192.119766 468.931732,192.048935 
	C468.752106,191.996902 468.860901,192.072952 469.467712,192.498962 
	C470.756775,195.073410 473.295349,196.823303 472.520569,200.228424 
	C472.827667,203.140579 475.888641,204.115280 476.106018,206.786209 
	C476.267120,208.765762 476.460602,210.705856 474.661438,212.512939 
	C470.601044,213.752563 467.977570,212.551178 466.489258,208.384918 
	C466.528046,211.066620 468.836731,212.950073 467.598938,215.711212 
	C465.185669,218.401337 463.394196,220.875229 462.559265,224.289108 
	C461.713531,227.747177 460.080994,231.356750 454.992188,229.513718 
	C453.215302,228.870193 451.661194,230.809128 449.462067,230.506317 
	C447.502106,228.941742 447.344482,226.851425 448.480865,225.476913 
	C450.718414,222.770569 447.707825,223.182236 446.698730,222.007080 
	C444.792297,217.808487 445.657623,213.497177 448.238708,211.115372 
	C452.505829,207.177673 450.941193,203.654099 449.265015,199.320251 
	C448.125488,195.708435 448.837677,192.425690 449.066284,188.782776 
	C454.313995,183.585556 456.367859,177.177200 458.013245,170.561050 
	C458.363770,169.151581 459.344574,168.819199 460.912842,168.985703 
	C461.553894,168.704742 461.844147,168.462616 462.172394,167.845291 
	C462.386536,167.389175 462.486511,167.213181 462.807068,166.821838 
	C463.202759,166.492691 463.377869,166.378922 463.808716,166.079605 
	C468.073486,159.008423 468.890961,151.275314 469.914062,143.273346 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M747.465393,296.023071 
	C751.804565,293.607147 751.408447,289.565796 751.663696,285.720703 
	C751.941223,281.539551 749.630859,278.022552 748.881042,273.769348 
	C748.045410,272.015228 747.238037,270.595367 747.826782,268.471558 
	C752.950317,262.933716 751.047302,256.626282 750.504944,250.765823 
	C749.617554,241.175888 745.132751,232.840530 740.001831,224.480057 
	C739.400757,223.373795 739.875061,222.831909 740.670288,222.147964 
	C759.108276,240.192368 772.167480,261.632172 781.785645,285.654907 
	C781.443970,286.456360 781.134033,286.917908 781.012329,287.767853 
	C780.967529,288.639771 781.073975,289.206360 781.431763,290.055847 
	C781.786255,291.051453 781.839722,291.760498 781.730957,292.873627 
	C780.761292,299.183777 782.432312,305.209320 780.706299,311.461914 
	C777.937500,313.358795 776.893982,311.541229 776.929504,309.665466 
	C776.997437,306.071625 776.026489,302.503693 776.487122,299.904449 
	C776.485229,303.154205 776.740173,307.388367 777.002563,312.032745 
	C776.559998,318.665497 776.867981,325.003754 775.387329,331.717682 
	C767.924438,324.595367 764.709534,317.001404 766.823486,307.252930 
	C767.501648,305.143494 768.601501,303.659576 769.492737,302.121948 
	C771.170776,299.227142 771.421936,301.953918 772.259338,302.959625 
	C769.320679,298.197723 771.698669,292.476715 769.203613,287.245544 
	C768.204529,278.487427 764.867676,270.636719 763.454163,261.972717 
	C763.372559,261.167725 762.459167,261.023468 762.992615,260.390656 
	C762.426453,260.964355 763.301758,261.117645 763.406982,261.886475 
	C764.750732,270.443634 768.015198,278.173981 769.101746,286.832886 
	C770.992798,292.320923 771.326843,297.381042 768.786987,302.756714 
	C764.036011,305.022217 763.725037,301.662476 763.630920,298.847656 
	C763.318481,289.510315 761.189880,280.470581 759.557312,271.341888 
	C758.552673,265.724915 755.733704,260.768433 753.965210,255.199585 
	C752.845459,255.314224 753.557495,256.055847 753.550903,256.893280 
	C753.703613,257.393738 753.761902,257.595337 753.901611,258.101349 
	C754.216797,259.428040 754.431152,260.458160 755.354492,261.546143 
	C757.521484,264.590149 758.212219,267.888641 759.250671,271.035614 
	C760.333496,274.316559 761.119080,277.451385 756.778381,279.345642 
	C754.844055,280.189728 754.148926,282.174896 754.790039,284.443817 
	C755.517090,287.016907 755.197693,289.748993 755.912659,292.690369 
	C756.273804,294.428009 756.352722,295.829865 756.059448,297.572449 
	C755.789246,298.521759 755.510132,299.119507 755.514160,300.116547 
	C755.453369,300.658264 755.396729,300.871948 755.139832,301.352478 
	C753.288574,303.534363 752.127625,299.947021 750.273560,301.445801 
	C749.003723,302.370819 747.905884,302.783325 746.293457,302.565918 
	C745.008301,301.925476 744.510803,301.109283 744.411377,299.689423 
	C745.029480,297.971436 745.852661,296.811829 747.465393,296.023071 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M801.141846,408.272003 
	C795.988953,410.819794 800.591980,413.631866 800.966064,416.618378 
	C801.120789,417.177216 801.263367,417.360504 801.357544,417.888855 
	C799.872803,420.565613 797.239319,421.921692 795.997437,424.855591 
	C795.588379,425.569580 795.318237,425.956451 794.779785,426.590698 
	C794.151428,427.148438 793.727966,427.359100 792.902527,427.446777 
	C787.992188,422.721130 787.950562,422.430573 791.340881,416.714142 
	C792.091736,416.548279 792.602844,416.572723 792.832825,416.925720 
	C793.044495,417.250610 792.965698,417.467865 792.953552,417.341553 
	C792.952881,417.334167 792.923462,417.191803 792.640381,416.744019 
	C789.088318,416.579865 786.415894,414.941925 783.517700,413.114014 
	C783.067505,412.138611 782.994324,411.435211 783.074341,410.362122 
	C785.766785,404.424133 786.462036,398.298065 787.987000,392.403137 
	C788.251648,391.380310 789.131836,389.902679 787.417114,389.531738 
	C786.108826,389.248688 785.537415,390.671967 785.143677,391.748993 
	C784.178406,394.389374 783.670166,397.176392 782.678101,399.807343 
	C782.126404,401.270416 782.025513,403.475616 779.815674,403.237457 
	C777.829163,403.023315 778.234985,401.071289 778.114075,399.638245 
	C778.034180,398.691315 778.322632,397.253967 777.165710,397.131195 
	C775.628906,396.968170 775.795776,398.632324 775.381592,400.009460 
	C775.163696,401.403137 774.827454,402.347839 773.606689,403.158203 
	C771.759949,402.927277 771.067993,401.795166 770.371948,400.167542 
	C770.056519,395.462280 772.447754,391.888519 773.773132,388.027863 
	C775.535156,382.895203 777.162842,377.724487 778.881836,372.168854 
	C779.502686,366.763306 782.680725,362.451660 782.940186,356.925171 
	C785.422729,347.817474 784.170593,338.737946 785.920410,329.530273 
	C786.477722,328.249634 787.077148,327.633179 788.511108,327.803925 
	C789.444458,329.332214 790.856262,330.392303 790.170776,332.090637 
	C787.923462,337.658600 789.286499,343.187531 790.338440,348.722198 
	C790.491028,349.524963 790.860535,350.414093 790.660889,351.133087 
	C789.971252,353.617310 787.607239,356.321930 789.450439,358.543579 
	C790.929626,360.326416 793.842163,360.998901 796.195435,363.676453 
	C795.844971,360.112549 796.672729,358.303741 799.756348,358.850372 
	C800.196960,358.928497 800.611511,359.153595 801.038086,359.310608 
	C798.067566,366.141754 805.403809,362.734772 806.854309,365.171448 
	C807.004822,365.424316 807.102539,365.708649 807.225037,365.979095 
	C799.896240,368.939575 799.901123,368.940063 799.063599,377.443298 
	C798.966309,378.430908 798.778137,379.409607 798.405701,380.710815 
	C796.853760,382.298706 797.729980,383.899139 797.667969,385.634918 
	C797.833252,386.133881 797.862427,386.335968 797.734741,386.801208 
	C795.135254,391.590790 793.416992,396.170135 796.506958,401.437805 
	C794.024841,404.401855 796.222229,405.170044 798.438232,406.187531 
	C799.183899,406.667175 799.615173,407.059998 800.302246,407.571655 
	C800.672729,407.840698 800.817139,407.952942 801.141846,408.272003 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M703.559448,222.908508 
	C705.641418,225.104080 709.966919,225.025482 707.589111,230.398361 
	C706.295166,233.322128 709.767395,238.352264 711.080750,242.435318 
	C711.357178,243.294769 711.963501,243.210205 712.618286,243.021332 
	C714.558105,242.461807 716.742188,241.670212 717.793823,244.142700 
	C718.886353,246.711319 720.303467,249.950912 718.474609,252.181274 
	C716.897400,254.104767 718.094299,254.715836 718.477844,256.165222 
	C721.125977,266.172668 730.399475,271.079315 735.912476,278.880859 
	C737.585022,281.247681 742.143005,281.265350 742.714050,285.682312 
	C742.599731,286.172913 742.438721,286.311462 741.986328,286.543213 
	C740.328857,288.850555 741.966431,290.932281 742.562256,292.674957 
	C744.208374,297.489746 743.412354,302.163422 742.940674,307.282135 
	C741.701416,311.548096 743.360107,316.257233 739.127380,319.440735 
	C738.278625,319.223785 737.294739,319.288940 737.977966,318.289368 
	C737.519043,318.330109 737.430725,318.545166 737.595276,318.979187 
	C737.888855,319.753784 737.982605,320.552185 737.489563,321.672791 
	C737.073120,322.096619 736.853516,322.204834 736.275635,322.248749 
	C734.233826,320.399628 734.800903,318.146851 734.640991,316.045197 
	C734.351868,312.243774 735.053284,308.519501 732.737427,304.756744 
	C730.637695,301.345093 733.189270,297.515045 733.974426,294.956268 
	C734.624390,293.366119 733.506958,293.660217 734.165833,293.761719 
	C734.260864,293.776398 734.330811,293.977478 734.158752,294.157166 
	C732.908386,295.463165 734.016602,298.062775 731.243652,298.761505 
	C730.159668,299.034637 730.913940,301.032257 731.496155,302.271545 
	C733.096436,305.678040 734.615479,309.132324 733.625977,313.406433 
	C733.349487,313.936462 733.195923,314.126282 732.709473,314.476562 
	C731.158997,314.929535 729.895264,314.811523 728.284973,315.110229 
	C723.113831,314.442749 723.113831,314.442749 723.514404,306.726013 
	C723.336670,294.608795 720.249084,283.717560 713.955261,273.350525 
	C713.618958,272.780853 713.491272,272.548004 713.208923,271.946686 
	C712.062622,266.123627 711.350708,260.621399 710.128479,254.820892 
	C708.757080,250.119324 707.797974,245.706039 706.391602,241.038361 
	C706.119507,240.250473 706.000305,239.795288 705.835083,239.004074 
	C705.626160,237.378754 705.273560,236.151062 704.437500,234.751755 
	C703.811890,233.743958 703.273682,233.068848 702.519531,232.128098 
	C702.221130,231.638138 702.117493,231.429733 701.890625,230.889404 
	C701.288452,228.670135 700.673401,226.798233 701.248779,224.453140 
	C701.799255,223.415115 702.408081,223.001434 703.559448,222.908508 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M449.782349,198.716278 
	C453.212463,201.684616 452.417542,205.561798 452.921295,209.043915 
	C452.980682,209.454605 452.646545,210.279938 452.388733,210.326569 
	C445.773560,211.522873 447.846985,217.075195 446.968384,221.855469 
	C446.908203,224.087967 446.225952,225.465363 446.089630,227.291824 
	C445.632507,228.156982 446.415253,227.798691 445.865112,228.061401 
	C442.064880,229.876221 439.468964,233.758926 434.632874,234.347290 
	C432.649475,233.574417 431.593597,232.215820 431.244781,230.600464 
	C430.389557,226.639938 429.200867,223.091415 425.484100,220.686417 
	C423.463745,219.379105 424.546753,217.459335 425.887177,216.031509 
	C427.922668,213.863297 430.552734,212.363831 432.835144,209.933899 
	C436.318604,205.543732 435.644440,200.003082 431.285583,196.943710 
	C427.657349,194.397156 423.822418,192.135361 420.291351,189.449127 
	C417.846924,187.589523 418.607697,186.586807 421.587158,186.272049 
	C422.127502,185.837570 422.280060,185.680878 422.273529,185.658051 
	C422.173370,185.767715 422.073181,185.877380 421.730469,186.153412 
	C417.674957,186.501144 415.104553,185.123520 414.877808,180.606903 
	C415.130981,180.050461 415.264160,179.842972 415.692566,179.412720 
	C419.178741,179.286377 422.167114,178.645782 424.994904,177.071854 
	C430.058533,174.253418 435.438965,172.242722 441.522461,171.103790 
	C442.564880,171.323425 443.119019,171.724899 443.546326,172.722900 
	C447.801758,179.271103 445.146057,185.080933 442.752869,191.178009 
	C444.941132,193.973099 448.171906,195.333191 449.782349,198.716278 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M522.815552,138.586884 
	C529.298096,140.043701 535.446777,141.650970 541.851685,143.520172 
	C547.626099,146.101974 551.118347,151.804810 557.393860,152.907623 
	C558.348511,153.075394 558.979126,154.045258 559.678955,155.174805 
	C559.931702,155.640640 560.024963,155.826309 560.413086,156.527908 
	C560.948059,157.347717 561.212219,157.629089 561.740234,158.060547 
	C562.139526,158.354553 562.299011,158.475861 562.681152,158.806274 
	C563.032288,159.176758 563.160828,159.338135 563.435303,159.775482 
	C563.639893,160.251923 563.698608,160.452393 563.799561,160.966064 
	C563.854553,161.491135 563.867371,161.703018 563.904419,162.232147 
	C568.828735,165.656281 569.364258,172.742920 575.521606,175.159760 
	C575.948303,175.537079 576.096252,175.712128 576.315552,176.221741 
	C576.769470,176.999680 575.160095,177.374893 576.603271,178.077667 
	C578.905701,181.832169 581.062439,185.301834 581.988037,189.726929 
	C583.047180,195.198914 584.972656,199.922760 587.625000,204.408096 
	C590.558960,209.369659 588.164734,214.220428 582.310486,214.650208 
	C578.664673,210.512817 578.742859,205.149628 576.296082,200.447784 
	C576.095093,199.021851 576.702332,197.913254 575.936279,196.569794 
	C574.443298,194.400848 573.258362,192.457916 572.663208,190.187515 
	C572.334717,188.934235 571.967285,187.550659 569.847046,188.061386 
	C569.375183,188.351547 569.182678,188.466721 568.674927,188.712433 
	C567.450500,189.111023 566.539246,189.089600 565.303589,188.738007 
	C562.629333,186.155945 560.440552,183.602081 559.856750,179.731293 
	C559.539307,178.008102 558.778931,176.995651 557.213257,176.195435 
	C553.687439,172.745544 552.444153,168.145111 549.318848,164.737640 
	C547.545593,162.804337 545.943909,160.764145 543.374390,159.187881 
	C542.884949,158.847656 542.692505,158.704803 542.222473,158.331711 
	C534.206909,154.805557 527.933411,149.288574 521.238525,144.415222 
	C519.929932,143.462631 518.167358,142.550278 518.691101,140.536545 
	C519.174561,138.677551 521.145020,139.211685 522.815552,138.586884 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M677.136108,835.557617 
	C677.286072,836.482361 677.638794,836.934509 678.087036,837.661133 
	C677.684631,840.017212 676.039612,841.239624 674.245056,840.899902 
	C670.289917,840.151123 666.930176,841.743652 663.033813,842.877441 
	C660.637329,842.934387 658.681519,842.425110 656.328003,842.038696 
	C651.995667,840.344666 647.694824,839.890808 643.365723,838.244141 
	C638.289429,835.453064 632.521057,834.595825 628.898315,829.980347 
	C628.007996,828.846008 627.605347,830.118225 627.007202,830.458252 
	C623.445679,832.482910 619.587952,831.511475 617.627136,828.278320 
	C614.208618,822.641479 609.087280,819.999695 602.545654,820.269043 
	C598.035706,820.454773 593.641296,820.373047 589.822144,817.193726 
	C590.299683,816.171631 590.915283,815.720032 591.969055,815.195435 
	C598.173889,814.324829 603.733398,812.761597 608.870911,809.807129 
	C611.121460,808.512878 613.639221,808.466675 616.565186,808.651428 
	C617.513855,808.771912 618.037354,808.915466 618.898193,809.318970 
	C622.031616,814.721375 626.569946,816.603699 632.237610,817.732178 
	C639.649719,819.207886 646.999512,821.051331 654.857300,822.338989 
	C656.243103,822.619629 657.279175,822.658020 658.604980,823.123352 
	C660.507812,825.207947 665.061340,825.361755 664.025574,828.361084 
	C662.828430,831.827942 659.101013,829.027405 656.518555,829.331421 
	C655.570984,829.442993 654.582581,829.003906 654.348145,828.888123 
	C656.920593,829.675476 660.258423,830.033997 664.025024,829.866028 
	C668.354614,829.186768 672.227539,829.775635 676.445374,830.780640 
	C677.038940,830.976807 677.262939,831.080811 677.770752,831.410400 
	C678.464294,832.250977 678.491272,832.912659 678.175415,833.885437 
	C677.851135,834.500854 677.615784,834.809204 677.136108,835.557617 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M818.003052,615.943481 
	C819.276978,613.690613 819.825623,611.215515 822.724548,610.078674 
	C823.804626,609.218750 823.893311,608.096436 824.870117,607.263977 
	C825.111206,607.095093 825.000000,607.003418 825.051331,607.054138 
	C828.201599,598.522400 828.117249,598.045715 822.531494,593.987366 
	C821.115967,592.682434 820.569885,591.383728 820.931274,589.455505 
	C824.281738,584.674561 828.772949,581.282166 831.734070,576.197021 
	C834.508240,575.260681 836.730164,573.749817 839.575806,572.999634 
	C840.040405,572.811340 840.219849,572.731628 840.641846,572.502930 
	C841.316040,571.989868 841.641235,571.545105 842.027588,570.795898 
	C843.404968,570.138916 844.637573,569.723022 845.870239,569.307068 
	C842.553955,572.714905 847.453796,576.178772 845.382812,579.720581 
	C844.840942,580.210999 844.566284,580.448059 844.161743,580.939209 
	C843.888123,582.279175 843.771118,583.395752 843.430664,584.764893 
	C842.845398,585.123047 842.669189,585.364197 842.854736,585.904175 
	C843.016113,586.509277 843.001343,586.950989 842.967651,587.723755 
	C842.482422,588.481689 842.178406,589.002258 841.935669,589.919312 
	C841.735474,590.412109 841.645874,590.607300 841.416382,591.096130 
	C839.635986,594.545471 839.300598,598.265442 836.899963,601.384521 
	C836.859314,601.635803 836.004395,602.419067 837.160034,601.669556 
	C837.808167,601.430725 838.198853,601.470093 838.803467,601.820679 
	C844.409180,607.903381 839.519897,611.479675 836.005493,615.727844 
	C834.756653,616.985046 835.151428,618.403015 834.699463,619.889648 
	C833.672913,621.711182 832.630554,623.147217 831.709839,625.053101 
	C828.575439,629.763000 827.296570,635.418091 821.738586,638.208618 
	C820.682983,638.022095 820.124268,637.584473 819.316772,636.890259 
	C815.288818,632.665161 812.572083,628.379944 815.152954,622.286499 
	C815.136353,621.497253 815.179260,621.050781 815.365173,620.291992 
	C816.270386,618.698059 816.768005,617.246887 818.003052,615.943481 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M849.426331,545.852844 
	C848.600708,545.226196 848.158325,544.339722 847.535461,543.606750 
	C845.930359,541.718079 844.926392,539.023193 841.355652,541.545410 
	C838.959106,543.238220 834.565247,540.606934 832.647400,543.769104 
	C831.002319,546.481567 832.022217,549.863159 832.595703,552.980469 
	C832.733948,553.732178 831.985352,554.906616 831.304077,555.486572 
	C830.360718,556.289612 829.481079,555.590698 828.861938,554.689148 
	C828.768066,554.552551 828.681641,554.395630 828.553162,554.300110 
	C825.512146,552.038391 825.117615,549.169250 827.321411,545.951599 
	C827.656860,545.461792 827.630249,544.723999 827.771179,544.100952 
	C823.241455,546.402954 824.615173,540.483948 821.554382,539.715271 
	C815.041687,538.079834 810.195923,533.951233 809.267944,526.250000 
	C808.472778,525.243652 808.542114,524.541809 809.527100,523.732300 
	C810.427979,523.540466 810.952515,523.584656 811.846313,523.826050 
	C815.228699,525.280823 815.929688,527.645996 815.498596,529.550659 
	C815.045166,524.836975 815.691284,519.041016 815.501221,512.849731 
	C815.589600,511.463867 815.649109,510.455322 815.947571,509.103943 
	C816.397827,507.801239 817.076965,507.101135 818.017944,506.163025 
	C818.447937,505.774048 818.630005,505.633240 819.104980,505.321167 
	C820.647217,504.644073 821.597900,503.822418 822.312256,502.269958 
	C822.649170,501.807983 822.808228,501.638733 823.262512,501.276672 
	C824.213318,500.773315 824.899475,500.631836 825.953613,500.839539 
	C830.912537,503.978027 833.534973,507.942200 832.504822,514.037598 
	C833.318542,516.711365 832.498535,518.732178 830.989685,520.922913 
	C829.293640,522.404175 827.269531,523.119995 827.062012,525.673767 
	C829.905029,524.488037 832.766724,525.058899 835.961426,525.215820 
	C836.530029,525.331543 836.751770,525.401978 837.270020,525.640747 
	C840.148132,528.020203 841.169189,522.555237 843.866333,524.584534 
	C845.706787,525.969299 846.926697,527.996094 848.936462,529.152466 
	C849.719238,529.602844 850.524963,529.861206 850.713989,528.566345 
	C851.184875,525.339661 853.186279,525.690125 855.647217,526.761169 
	C855.048462,533.628174 852.030518,539.527588 849.426331,545.852844 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M849.956787,362.554443 
	C847.345642,362.668518 846.525085,360.846375 845.716064,358.693481 
	C844.457947,355.345306 843.089294,352.249786 838.870239,351.054779 
	C836.204956,350.299835 836.018250,350.971863 836.011780,353.011078 
	C836.000183,356.652374 836.064026,360.295135 835.976746,363.934418 
	C835.938904,365.511505 836.542175,367.295380 834.527466,368.672211 
	C831.867310,362.888245 833.270325,356.818268 833.475586,350.394165 
	C833.450012,344.614075 833.236938,339.250916 832.941650,333.449036 
	C832.583191,332.598297 832.452698,332.144470 832.615479,331.316223 
	C832.039734,321.729065 830.401184,312.611786 828.622070,303.125427 
	C828.642700,302.551788 828.679565,302.365936 828.559937,301.947205 
	C829.279358,300.599579 827.974731,301.137543 827.564209,300.607361 
	C826.963623,299.501099 827.154297,298.670532 827.331238,297.553589 
	C827.155945,296.982544 827.271240,296.777832 827.700256,296.626587 
	C827.923523,296.304565 828.275269,296.082153 828.282166,295.912994 
	C828.308899,295.252899 827.335510,295.661591 827.997253,295.742462 
	C828.016785,295.744843 828.024292,295.878387 827.899780,296.349060 
	C826.680908,296.066223 826.949524,294.951904 826.578369,293.625000 
	C826.772949,292.587921 826.827759,292.022949 826.303589,291.226105 
	C825.682617,289.272034 825.246155,287.553131 824.758667,285.454407 
	C827.284302,283.124969 826.180054,281.090485 825.254517,278.680664 
	C824.380554,276.405212 821.740540,272.804871 827.684204,272.779480 
	C829.425476,276.371857 830.894470,279.739960 832.491699,283.463409 
	C831.700928,286.686432 831.338074,289.431061 832.113953,292.557068 
	C832.880737,295.646240 834.458130,297.034027 837.552368,297.772705 
	C844.255066,318.924713 849.075134,340.145569 849.956787,362.554443 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M706.667358,237.784729 
	C706.976562,238.390350 707.085388,238.759476 707.201782,239.418884 
	C707.161682,242.458405 705.250244,242.040115 703.811279,241.373718 
	C700.833313,239.994583 697.542419,238.634766 697.975647,234.373550 
	C698.153809,232.621735 697.226379,231.596207 695.671326,230.542664 
	C694.762573,230.173584 694.190186,229.868454 693.286987,229.573456 
	C691.716980,229.403091 690.696899,230.606934 688.741943,229.963684 
	C687.937805,229.583328 687.757507,229.445877 687.350098,229.053894 
	C685.969604,225.560715 683.681824,225.462494 680.631226,226.502441 
	C679.091858,225.930618 678.414978,224.642410 676.803711,224.173584 
	C673.392273,223.664581 672.126160,222.028793 671.767151,218.785370 
	C671.480286,216.194168 670.309326,213.512177 668.419434,211.436371 
	C667.175049,210.069580 666.074341,208.648392 665.390259,206.554565 
	C666.785156,201.381210 663.910278,197.594803 662.081543,193.199738 
	C660.669678,189.640518 659.092712,186.492035 659.089539,182.524033 
	C659.651001,180.815598 659.841370,179.073410 661.648804,178.974838 
	C663.053223,178.898239 663.340271,180.218933 663.773926,181.681122 
	C664.279663,182.246231 664.563721,182.494492 664.965515,183.003876 
	C669.037659,184.920883 669.852356,189.249344 672.846130,191.980988 
	C673.670593,192.323105 674.646484,192.786499 674.594482,193.032349 
	C673.778442,196.888672 675.800720,197.550919 678.757080,196.537735 
	C683.575928,194.886337 683.695190,199.430130 685.327576,201.566116 
	C686.931213,203.664612 686.247681,205.996292 683.248169,207.410980 
	C683.347351,208.090103 683.477051,208.670639 683.705505,209.527649 
	C684.109009,210.096466 684.377197,210.391464 684.865356,210.890686 
	C685.476562,211.576675 685.477783,212.227158 685.975830,212.979584 
	C686.497070,213.739090 686.511780,214.405136 687.107422,215.145935 
	C687.441589,215.689087 687.589905,215.973251 687.819946,216.307251 
	C687.619568,216.126099 687.550232,215.764801 687.193604,215.259857 
	C686.638489,214.596970 686.521118,214.038254 686.200012,213.262344 
	C685.611511,212.588943 685.524170,212.007797 685.181030,211.232468 
	C684.731873,210.729630 684.460754,210.454865 684.003662,209.957199 
	C683.614929,209.392029 683.528503,209.022430 683.674561,208.366089 
	C691.414246,207.870010 691.835205,208.287994 690.797058,215.791809 
	C690.611877,217.129913 691.197754,217.624710 692.198669,217.873520 
	C693.932312,218.304474 695.697632,218.607773 697.850403,218.959335 
	C700.743896,219.353485 701.156250,221.630615 702.308960,223.663147 
	C701.116577,226.646545 703.279053,228.578003 703.748779,231.303802 
	C703.370850,231.611572 703.077637,231.552322 702.773193,231.587158 
	C702.468262,231.622070 702.570007,231.653519 703.370361,231.782440 
	C704.753296,231.748123 705.070923,232.480972 705.375122,233.627167 
	C704.206604,235.573181 705.517822,236.510620 706.667358,237.784729 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M559.317017,157.337097 
	C558.734497,155.283264 558.226501,153.919113 555.863770,153.595749 
	C552.534363,153.140152 549.608154,152.038757 548.057556,148.275940 
	C547.208191,146.214661 543.477783,146.870575 542.132690,144.136627 
	C552.833069,146.796844 561.166199,153.828766 569.826538,160.305923 
	C570.955017,161.149918 571.785278,162.741074 573.770630,161.274811 
	C574.107483,160.844070 574.246277,160.698303 574.632996,160.720139 
	C575.160095,161.165146 575.438416,161.443268 575.936218,161.909424 
	C576.616821,162.430649 577.079651,162.761734 577.603882,163.428757 
	C577.963013,163.937256 578.187561,164.201157 578.610962,164.613510 
	C578.784119,164.784302 579.103088,165.106323 579.475830,165.384384 
	C579.848572,165.662445 579.478638,165.552338 579.361816,165.384506 
	C579.244934,165.216675 578.925049,164.925262 578.766174,164.765442 
	C578.383423,164.381577 578.163452,164.153793 577.793091,163.719940 
	C577.400513,163.034195 577.031494,162.674500 576.355835,162.237701 
	C575.855530,161.842026 575.588562,161.591415 575.135254,161.131592 
	C574.501526,160.507950 574.525208,159.918716 574.304626,159.117081 
	C573.864136,158.413651 573.508972,157.978836 573.075439,157.273346 
	C574.817505,155.258133 576.840454,155.855255 578.508728,156.959656 
	C596.509094,168.876144 609.340942,184.653534 614.670105,206.271515 
	C613.947754,207.775330 613.375122,208.900864 615.671753,209.508881 
	C617.816528,213.458313 620.001831,217.138824 618.245667,221.890747 
	C617.731567,222.289383 617.491394,222.398727 616.857544,222.526260 
	C615.668518,222.368576 615.089478,221.882919 614.426514,220.933975 
	C613.852600,219.756348 613.710205,218.820984 613.316284,217.618225 
	C612.826782,216.094818 612.515686,214.849426 611.400635,213.632385 
	C609.644287,211.638794 606.575928,210.834091 607.683838,207.328735 
	C607.809753,207.130356 608.224609,207.030746 608.224609,207.030746 
	C608.224609,207.030746 608.257019,206.607620 608.025391,206.531616 
	C605.587646,202.202515 601.398315,201.219086 596.951477,199.985687 
	C595.911682,199.604034 595.311951,199.185867 594.549316,198.370239 
	C592.992615,194.311493 591.368347,190.795181 588.315125,187.977707 
	C585.413208,185.299850 584.376892,181.907532 586.203857,177.719177 
	C590.523132,175.703735 589.653564,179.948898 591.258057,181.706665 
	C591.732483,182.202057 591.522827,182.646835 591.763000,182.887665 
	C591.818726,182.943604 591.957153,182.801819 591.958435,182.681595 
	C591.965698,182.014969 592.012390,182.944077 591.465210,182.148743 
	C590.267090,179.732193 589.965759,177.098267 587.229065,175.651016 
	C586.693909,175.057877 586.553589,174.620392 586.420776,174.179550 
	C586.127136,174.356750 586.367249,174.725006 585.889282,175.141266 
	C582.713745,175.740799 581.946960,173.017914 580.302185,171.613327 
	C577.002869,168.795898 574.481201,164.740784 568.986572,164.975494 
	C568.221008,164.759476 567.809937,164.575851 567.140015,164.175308 
	C566.508057,163.756500 566.158813,163.505356 565.561523,163.060654 
	C565.152100,162.737885 564.996521,162.600800 564.647217,162.224060 
	C564.007629,161.323669 564.062988,160.430664 563.216919,159.686005 
	C562.791382,159.464340 562.609619,159.399216 562.181885,159.181015 
	C561.936035,159.027954 561.996094,159.004410 561.994629,159.036621 
	C561.595215,158.561203 560.988098,158.501114 560.184448,158.171326 
	C559.939636,158.004776 559.999573,157.995102 559.996460,158.025314 
	C559.850403,157.888596 559.707458,157.721664 559.317017,157.337097 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M646.261719,189.146484 
	C644.793945,185.354401 647.998474,183.004776 648.691528,179.895493 
	C648.959595,178.692993 650.050964,177.674026 650.913574,176.258392 
	C652.206482,174.992676 653.387634,175.118744 654.887024,176.064774 
	C655.717346,176.840210 656.104797,177.480667 656.607605,178.478577 
	C656.848572,179.031845 656.932800,179.255905 657.116821,179.819992 
	C658.215149,185.756531 660.824829,190.863037 662.292908,196.672272 
	C662.704651,199.244095 663.158630,201.491928 662.827881,204.099579 
	C661.140625,205.593613 662.803345,207.014984 662.305969,208.556824 
	C661.909546,209.346375 661.039734,208.646179 660.993591,209.317398 
	C660.949341,209.962997 660.744995,209.213455 661.672058,209.455124 
	C664.182129,211.994934 664.434021,215.305954 665.903687,218.409714 
	C665.055603,221.770905 667.682678,223.060837 669.199463,225.295776 
	C669.452881,225.836548 669.523987,226.069305 669.594360,226.672546 
	C668.753723,229.567261 666.416077,229.130493 664.157471,229.608719 
	C662.084717,229.218704 661.287598,228.111206 661.308960,226.069061 
	C661.536316,225.485779 661.703125,225.251831 661.898254,225.042267 
	C662.352539,224.554382 661.885376,225.352539 662.221741,224.776932 
	C662.086060,224.820801 662.024597,224.953949 661.627563,225.137543 
	C658.806946,224.002090 658.011597,221.169357 656.027527,219.436462 
	C652.960815,216.757996 650.292908,213.366486 645.319153,213.497101 
	C644.538391,213.687332 644.081726,213.701889 643.295227,213.530945 
	C641.645325,212.495071 641.113586,210.861206 639.561218,209.805557 
	C639.138184,209.725296 638.969238,209.757370 638.652466,210.013214 
	C638.236694,211.489807 638.443481,212.762665 638.323364,214.392273 
	C637.805115,217.282974 636.578979,219.116989 633.720581,218.850479 
	C631.196899,218.615173 630.893372,216.542572 630.556580,214.533859 
	C630.232544,212.601288 629.683167,210.714066 628.856689,208.537506 
	C626.949280,204.778885 623.915161,203.107910 620.091248,201.893158 
	C618.583191,201.211700 617.529480,200.519897 616.578979,199.113373 
	C616.078064,196.681015 616.798889,195.103760 619.040039,194.025009 
	C621.375488,194.490799 621.547913,196.382660 622.342407,197.755356 
	C623.023132,198.931519 623.878662,200.659561 625.042847,200.367630 
	C629.550903,199.237228 632.178406,203.300613 635.728271,203.858795 
	C638.979797,204.370071 642.453125,203.613174 645.785095,204.927917 
	C648.493347,205.996582 651.517090,206.270630 653.083130,209.365158 
	C653.398376,209.988083 655.204590,210.392380 656.028564,210.091125 
	C657.147278,209.682144 656.922668,208.388290 656.058838,207.656601 
	C654.229980,206.107452 653.744202,203.226807 650.791199,202.704117 
	C649.861267,202.539536 648.290649,200.893661 648.449219,200.490082 
	C649.678040,197.363297 647.174866,195.728973 646.045776,193.220032 
	C646.494690,192.663422 646.939270,192.462540 647.398193,192.295624 
	C648.739258,191.807953 650.851562,191.519089 650.361938,189.753586 
	C649.794556,187.707886 647.886536,189.346832 646.261719,189.146484 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M781.085571,801.510254 
	C780.680298,802.007202 780.404480,802.190369 780.031128,802.598938 
	C779.865295,802.990356 779.794128,803.152100 779.466919,803.374756 
	C778.860535,803.545288 778.178772,802.882019 778.008667,804.085693 
	C776.382874,804.736938 775.248230,805.866394 773.386658,806.105469 
	C770.963257,807.139648 768.863647,808.114685 766.417053,809.159424 
	C759.897583,811.502747 753.579956,813.395203 747.390808,815.596863 
	C739.706238,818.330505 731.917786,821.532104 723.603271,822.315186 
	C719.327698,822.717834 715.105286,823.784973 710.363464,823.398560 
	C709.420532,823.338440 708.881348,823.276001 707.938477,823.103271 
	C705.359619,822.353210 703.142639,821.897644 701.452942,820.091248 
	C699.552307,818.059448 696.893738,818.757019 694.494873,818.555664 
	C691.811401,818.330261 688.843079,819.198853 686.683960,816.190918 
	C687.721191,813.289673 690.031128,813.632446 692.540283,813.405884 
	C706.457275,814.205322 719.769714,812.769531 733.447388,810.640137 
	C738.332581,809.778809 742.736633,808.453003 747.181152,808.646851 
	C746.727966,808.493896 747.694336,809.060181 747.423096,808.752441 
	C746.776367,808.018616 742.581909,808.104614 746.739441,806.593506 
	C749.855103,805.461060 753.353943,804.824646 757.131836,804.420959 
	C759.672852,803.063904 762.282715,802.779785 765.228638,800.983276 
	C762.303345,802.635986 759.843567,803.065735 757.334717,804.337036 
	C751.353149,805.237061 745.877380,806.860168 739.871277,807.817139 
	C738.411987,807.930481 737.400757,807.767029 736.252319,806.834717 
	C735.232910,804.959412 736.386902,804.311646 737.743835,803.395813 
	C748.059814,802.184937 757.887329,800.133179 768.041504,797.747314 
	C768.712830,797.689697 769.001404,797.659302 769.693848,797.791504 
	C770.097656,797.954041 770.544617,797.996582 770.769104,797.997070 
	C772.906189,798.977478 774.472168,798.135803 776.095520,797.152588 
	C777.646423,796.213318 779.227722,795.214172 781.511108,795.483887 
	C783.753235,797.698792 781.573792,799.348999 781.085571,801.510254 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M856.059753,526.663147 
	C854.206482,527.366577 850.507202,525.464966 852.716858,530.102173 
	C853.370178,531.473267 851.932190,532.107239 850.559448,531.692017 
	C848.580811,531.093506 846.915283,529.860168 845.786499,528.213501 
	C842.978760,524.117432 840.913513,524.027100 839.704712,529.318115 
	C838.503113,528.408875 837.452332,527.860413 837.042236,526.312744 
	C835.392578,526.117004 833.949768,527.013672 832.169373,526.263428 
	C829.585022,525.174438 827.602539,526.815430 825.371582,529.337219 
	C825.754639,525.381775 824.960693,522.018188 829.509705,520.990479 
	C833.376282,519.443298 834.586487,515.725037 837.353027,513.012207 
	C839.637024,511.108643 841.549683,509.306396 843.409668,506.981628 
	C844.026733,506.526306 844.426636,506.354584 845.167664,506.189941 
	C846.593567,505.930725 847.379456,505.350372 847.829407,503.912872 
	C848.063110,503.391235 848.168884,503.184540 848.470764,502.680542 
	C848.823730,502.199005 848.980591,502.014832 849.407471,501.578735 
	C849.880005,501.180206 850.082397,501.033691 850.621216,500.715088 
	C854.800537,498.569855 856.454956,500.666412 857.191711,504.049866 
	C857.994934,507.738556 858.085144,511.510651 858.108398,515.661377 
	C858.173523,519.592163 857.226624,522.959106 856.059753,526.663147 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M788.906311,327.678589 
	C788.397888,328.166443 787.879211,328.329559 787.191833,328.713440 
	C785.176025,332.759155 785.680969,336.997253 785.258057,341.005096 
	C784.725830,346.048340 783.918823,351.114288 784.019653,356.645020 
	C785.150940,362.419830 780.700073,366.433990 780.288818,371.747437 
	C776.499451,377.968201 775.996094,385.038300 772.930786,391.181824 
	C771.439209,394.171356 768.949768,396.945648 770.143677,401.115662 
	C770.537903,403.986267 769.815125,406.147461 767.814758,408.263123 
	C764.146179,410.671600 763.047668,409.000641 763.029724,405.747528 
	C763.021484,404.259033 763.213379,402.731110 763.675720,401.337677 
	C765.998718,394.336060 765.604553,386.670685 769.219666,379.951660 
	C771.454407,375.798096 770.320190,370.820862 771.425842,366.504333 
	C772.772766,361.245361 774.295471,356.008881 775.491272,350.324799 
	C775.395264,347.280762 775.803223,344.668304 776.694275,342.118622 
	C776.347107,342.747406 776.606445,343.652954 775.715210,343.928253 
	C774.764526,344.222015 774.123657,343.591034 773.652100,342.912170 
	C772.724182,341.575958 773.030884,340.088684 773.153870,338.577881 
	C773.479370,334.579041 775.708740,331.028534 775.923096,326.638794 
	C776.953247,322.659180 776.748047,318.789062 778.754028,315.050140 
	C779.345032,314.767517 779.624939,314.766541 780.221680,315.040894 
	C782.212036,318.415771 781.551880,321.789581 781.593018,325.053680 
	C781.682007,332.125854 781.697388,339.201630 781.309326,344.453094 
	C782.085022,337.838928 781.923950,329.339661 783.295288,320.564087 
	C787.404785,318.362396 786.117798,321.664276 786.802002,323.623718 
	C787.665710,325.110870 788.280884,326.232330 788.906311,327.678589 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M751.634460,740.175781 
	C756.674927,740.444458 762.242554,737.860962 764.525452,743.938477 
	C754.004456,748.450195 746.054321,757.736450 734.342163,760.521118 
	C733.305420,764.530640 729.840088,763.748474 727.010254,764.656982 
	C725.833374,764.356628 725.428711,763.764343 725.197815,762.646484 
	C725.208374,762.074097 725.247681,761.851685 725.434509,761.340759 
	C725.591797,760.490417 726.792236,761.300232 726.222229,760.096191 
	C724.544617,758.357971 722.699890,757.205322 721.452515,755.036743 
	C721.139709,752.536804 719.667664,750.502930 720.699707,747.902832 
	C723.797241,746.121582 725.828796,746.957825 727.484497,749.709473 
	C729.249634,752.643188 731.701721,753.579102 734.932983,751.678162 
	C736.185303,750.941345 737.299561,750.336914 736.636475,748.696228 
	C735.501465,745.887512 736.852844,743.953979 739.043579,741.931763 
	C739.506226,741.530701 739.701782,741.378784 740.214844,741.025146 
	C740.979492,740.576599 741.442566,740.364319 742.292358,740.063721 
	C745.535339,739.558167 748.405457,739.306519 751.634460,740.175781 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M522.804932,138.266632 
	C522.655029,140.233673 520.634460,139.702728 518.885986,141.104782 
	C526.067993,145.331879 531.122803,151.825302 538.923401,154.546585 
	C540.412354,155.065994 542.153320,155.540070 542.939880,157.652069 
	C549.920776,160.693497 552.694641,167.017624 555.949463,172.886475 
	C556.335632,173.582809 556.323242,174.484589 557.578247,174.895874 
	C558.286865,175.334610 558.537476,175.766586 558.765625,176.631195 
	C556.976440,180.453659 555.205200,181.031982 552.544800,178.438187 
	C550.068420,176.023834 547.566956,173.611282 545.523254,170.712006 
	C542.033142,165.760788 537.781372,161.590118 531.047729,160.400421 
	C530.709229,160.250290 530.839294,160.143860 530.803955,160.220108 
	C530.788330,159.910660 530.648743,159.595505 530.115112,159.206879 
	C529.880310,159.062973 529.966919,159.024475 529.944763,159.066345 
	C529.280396,157.748779 528.628052,156.396393 527.091919,155.306732 
	C526.815430,155.143021 526.916443,155.091568 526.895020,155.144043 
	C526.786255,154.852921 526.598999,154.574127 526.117920,154.196106 
	C521.851807,149.929688 517.007019,146.787582 512.553040,143.164093 
	C510.720306,141.673050 507.302399,140.257858 508.350922,137.815811 
	C509.315033,135.570404 512.789917,136.888245 515.143738,137.026596 
	C517.595154,137.170700 520.022644,137.723007 522.804932,138.266632 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M724.304443,795.589600 
	C724.250732,792.314087 723.774048,789.962463 719.395874,791.621216 
	C718.092224,792.115173 716.456909,791.937744 715.002136,791.789062 
	C711.299561,791.410461 707.703796,790.856445 704.114197,792.762817 
	C698.793213,795.588806 693.241211,793.515747 687.382141,793.004639 
	C686.653870,792.634766 686.475891,792.204712 686.541992,791.364502 
	C687.910217,789.575989 689.836853,789.070435 691.555176,787.661865 
	C693.106995,787.169556 694.498352,787.254639 695.416382,786.069702 
	C697.318665,783.614136 699.612427,783.231201 702.354309,784.691101 
	C704.767639,785.975952 704.723145,784.499573 704.337341,782.463745 
	C704.358032,781.733276 704.955627,781.567383 704.869690,781.190552 
	C704.797913,780.875916 704.521667,780.990112 704.143433,781.583557 
	C700.948059,781.607117 698.677185,779.850830 699.141785,777.242737 
	C699.671021,774.272217 702.679504,774.340454 705.275391,774.968018 
	C705.926392,775.125427 706.581421,775.144897 707.604736,775.006348 
	C708.214233,774.986938 708.460083,774.993896 709.073975,775.055176 
	C710.428650,775.344055 711.300964,775.800171 712.294678,776.745300 
	C713.754700,779.474731 712.524841,781.835815 712.030518,784.222717 
	C720.591248,784.529419 721.065796,784.280640 721.573608,779.139526 
	C722.361267,778.335449 723.063416,778.170166 724.187378,778.265503 
	C724.770752,778.445007 724.990173,778.552307 725.480591,778.898926 
	C726.913818,780.754700 728.384644,781.947693 730.223938,782.775146 
	C731.569214,783.380310 732.908081,783.920959 731.214966,785.958435 
	C730.713562,786.561829 732.584961,785.826355 733.681030,785.975342 
	C734.721558,786.034485 735.183411,786.399658 735.406433,787.428589 
	C735.839233,788.588135 735.909302,789.440552 735.288452,790.669189 
	C731.579285,792.521729 728.109802,794.005127 724.304443,795.589600 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M725.315063,779.209167 
	C724.268677,778.973206 723.502747,778.963684 722.353760,778.957458 
	C719.553589,779.181458 719.233948,776.844727 717.798096,775.354919 
	C717.609253,774.802917 717.582520,774.567322 717.650391,773.988892 
	C720.320984,770.356384 724.921448,771.561096 727.426208,769.136841 
	C725.291321,769.476868 723.229858,772.250610 720.010864,770.447998 
	C719.741699,766.444458 722.638550,765.360107 725.661865,764.039673 
	C728.575317,763.134888 731.116821,762.244751 733.879883,761.059875 
	C741.317139,762.447754 748.583557,763.588196 756.491272,762.825745 
	C758.045166,763.081360 758.989502,763.568359 759.808838,764.885132 
	C756.006409,767.852783 752.046753,770.444336 747.765015,773.124023 
	C743.452332,774.155273 739.262329,774.033081 735.039246,775.624878 
	C732.065735,776.791382 729.416992,777.805054 726.660767,779.430420 
	C726.072815,779.491760 725.834167,779.463684 725.315063,779.209167 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M539.351990,180.230408 
	C537.337830,175.563034 533.756470,172.674423 530.263672,169.781235 
	C527.764038,167.710724 528.797119,166.755310 531.416138,165.889069 
	C534.083130,163.673355 536.333740,164.260483 538.900879,165.714722 
	C545.870789,169.663193 549.328857,176.483612 553.609680,183.136993 
	C554.539062,184.467422 554.977722,185.606186 555.241699,187.183365 
	C555.388000,189.974335 555.361572,192.469162 558.280945,194.002258 
	C559.979492,195.387054 560.941589,196.900742 561.666382,198.945862 
	C561.784668,200.329849 561.472778,201.438019 562.288818,202.719086 
	C562.421326,203.264511 562.433533,203.494034 562.338867,204.056717 
	C561.563049,205.453415 560.550659,206.054993 559.058228,206.566437 
	C557.953430,206.680710 557.289246,206.463043 556.505310,205.688705 
	C554.967163,202.196289 554.876648,198.313034 551.104004,196.073975 
	C550.398682,195.402863 550.035217,194.878220 549.627747,194.021332 
	C549.300354,192.990051 549.053467,192.261017 548.073120,191.658051 
	C547.500488,191.356888 547.216431,191.088898 546.911621,191.060822 
	C545.141846,190.897797 543.464478,194.149277 541.609741,191.198746 
	C540.009216,188.652695 538.287231,186.135635 539.158752,182.869125 
	C539.367737,182.085907 539.925781,181.372574 539.351990,180.230408 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M760.224915,736.718506 
	C757.597656,737.662537 755.188232,738.299194 752.404724,739.005859 
	C748.590881,740.917419 744.697815,739.981323 740.731995,740.913818 
	C733.328552,740.978088 732.815857,740.592529 732.788818,735.059448 
	C732.014221,733.556824 731.473328,732.347534 732.296875,730.670410 
	C732.360962,728.509949 733.227234,726.973328 734.267334,725.192505 
	C734.647034,724.675476 734.891174,724.490662 735.164856,724.390747 
	C734.698486,724.002930 734.287720,724.433655 733.507324,724.488159 
	C727.678284,723.176392 726.541077,721.598877 727.258362,716.167297 
	C727.453979,714.686462 727.601379,713.181580 728.420410,711.485596 
	C730.866943,709.618225 733.307068,711.152954 733.463196,712.612915 
	C734.090637,718.481934 737.824829,715.321594 740.354370,715.306885 
	C741.796265,715.298523 743.124756,714.375000 744.487976,713.818420 
	C746.185974,713.125244 747.899719,712.608398 749.966309,713.770203 
	C751.882935,717.898499 748.642090,718.732788 746.048340,720.340454 
	C743.814026,721.149109 740.709656,720.751587 742.420837,724.751099 
	C742.661682,725.510803 742.717224,725.960632 742.680908,726.752502 
	C742.195251,729.213745 743.744629,729.486267 745.201172,729.842957 
	C747.320251,730.361816 749.199951,731.246826 750.450562,733.517151 
	C750.628906,734.242065 750.007568,734.414734 750.121765,734.711426 
	C750.221863,734.971436 750.573669,734.828674 751.010132,734.272949 
	C754.121948,733.410278 756.920959,733.702026 759.985901,734.722168 
	C760.481323,735.424072 760.529663,735.909790 760.224915,736.718506 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M828.003235,272.753723 
	C822.026001,275.053314 826.452820,277.923431 827.672791,280.034698 
	C829.429688,283.075073 829.880981,284.904968 825.117432,285.051086 
	C818.864685,265.930023 810.823608,247.693802 801.781982,229.480606 
	C801.855896,228.233276 801.939087,227.308365 802.945801,226.326111 
	C812.331848,239.542786 819.728394,253.857956 826.552856,268.847168 
	C827.650757,269.606354 828.296082,270.268341 828.233643,271.662903 
	C828.092163,272.167175 828.071045,272.335449 828.003235,272.753723 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M707.540771,821.761963 
	C708.399780,821.977783 708.878296,822.001648 709.715393,822.048218 
	C711.568237,826.318481 709.874451,828.646362 705.311890,829.670654 
	C695.847290,831.757080 686.706360,833.614685 677.249390,835.751221 
	C676.933350,836.030151 677.007629,836.014526 677.006042,836.052429 
	C676.894165,835.813171 676.783936,835.535950 676.839294,834.843079 
	C677.184814,833.993347 677.364746,833.559265 677.747375,832.977600 
	C682.807068,830.984985 687.539185,828.663940 691.903931,828.788391 
	C681.770996,827.917908 670.372314,828.680786 659.301147,824.290649 
	C657.941040,824.033508 656.899536,824.007080 655.467346,823.975708 
	C655.055176,823.481567 654.992493,822.725769 655.480591,822.683105 
	C660.874939,822.211670 665.587891,818.692688 671.126526,819.063538 
	C679.705261,819.638123 688.255493,820.868347 696.877258,820.359375 
	C700.411499,820.150818 703.648010,821.926575 707.540771,821.761963 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M504.339783,177.147675 
	C508.270752,178.629028 508.617950,183.378738 512.577576,184.637924 
	C513.917786,185.064148 515.691284,186.696365 516.549316,185.188400 
	C519.752136,179.559616 521.211975,183.646774 523.394897,186.218689 
	C525.128784,186.744843 525.560425,187.803360 525.526733,189.451279 
	C525.182312,190.209503 524.740479,190.442123 524.385925,190.768356 
	C525.094482,191.205994 525.834229,190.725311 526.903687,190.889679 
	C528.560913,191.309402 529.445923,191.989059 529.044678,193.886749 
	C528.465332,195.364716 527.945129,196.459961 526.531494,197.251923 
	C524.031738,196.606766 522.579773,196.555267 523.681763,199.582520 
	C524.839966,202.764389 525.114990,206.189316 526.401794,209.649475 
	C526.704224,210.082901 526.824341,210.261124 527.106567,210.723938 
	C528.127563,213.201920 530.957458,214.731339 529.290771,217.957153 
	C527.261414,218.583496 525.450500,218.180466 523.633423,218.334045 
	C521.178894,218.541458 519.184692,217.026825 518.724487,214.929337 
	C518.074036,211.964828 516.400391,209.469833 515.676086,206.637711 
	C515.038147,204.143570 514.897644,201.809128 512.213623,200.201492 
	C510.680939,199.283447 510.713257,196.963943 512.487793,195.484177 
	C513.247803,194.850372 514.186157,194.376938 514.676575,193.499466 
	C515.263550,192.449203 516.858154,191.231583 515.325134,190.122955 
	C513.543457,188.834488 513.219299,190.656311 512.809021,191.891983 
	C512.467834,192.919479 511.775208,193.551041 510.358948,193.756409 
	C509.794464,193.698029 509.575165,193.637573 509.076843,193.386185 
	C507.648193,191.668076 509.048920,189.761063 507.861511,187.950989 
	C507.541748,187.512177 507.415771,187.333038 507.114502,186.872498 
	C506.223083,185.112503 505.404449,183.721619 504.106354,182.645615 
	C502.209045,181.073013 502.357025,179.313232 504.339783,177.147675 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M330.933594,205.192902 
	C330.595093,205.007675 330.477234,205.068604 330.410889,205.068909 
	C330.024048,204.877884 329.718384,204.665222 329.240723,204.223145 
	C327.377106,203.951752 325.568329,204.355728 324.151398,202.380417 
	C325.607056,200.850983 327.516144,201.839676 329.598785,201.247116 
	C334.454620,202.430267 338.912109,199.627579 343.613770,200.946228 
	C345.051575,201.349472 348.443939,199.724518 349.108734,197.594025 
	C350.108948,194.388550 351.662567,194.833481 353.291718,196.383499 
	C354.815643,197.833405 356.400024,198.088028 358.663391,197.980957 
	C359.503693,197.604828 360.001099,197.493607 360.826660,197.954376 
	C361.094513,198.257202 361.097473,198.450058 361.049042,198.533447 
	C360.984253,199.943008 359.750549,201.584763 362.584320,201.865112 
	C364.788208,202.083145 367.250977,202.400665 368.934418,204.770309 
	C367.932159,206.930862 371.861786,209.146851 368.295593,210.670135 
	C366.114807,207.892654 363.306366,208.595947 360.401672,208.750076 
	C351.812134,209.253159 343.521545,209.484695 335.239532,209.890686 
	C332.965637,210.002151 331.298218,209.730377 330.951477,206.753723 
	C336.348206,203.782959 342.134674,205.026779 347.191467,204.548218 
	C342.252075,204.301025 336.768829,205.026688 330.933594,205.192902 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M616.707275,198.750397 
	C617.857788,199.630569 618.789246,200.241455 619.857239,201.160233 
	C620.907410,202.203079 620.958618,203.182617 620.638428,204.576630 
	C619.158142,206.371964 617.457703,207.326111 615.439697,208.400055 
	C614.583557,208.371216 614.181091,208.132797 613.954102,207.361145 
	C608.297058,186.506577 596.328064,170.540634 578.888428,158.533768 
	C577.335022,157.464264 575.724304,156.701248 573.388000,156.986420 
	C572.997070,157.002686 573.000000,157.000000 572.998474,157.001526 
	C572.573242,156.563843 572.527832,155.960602 572.098145,155.237976 
	C571.507690,154.753311 571.010437,154.534348 570.783508,154.154083 
	C570.115723,153.034988 569.555176,151.851944 568.773376,150.349472 
	C571.042114,150.037476 572.208069,151.558823 573.655518,152.323502 
	C585.963074,158.825516 596.018494,168.030975 605.062622,178.457428 
	C605.810669,179.319824 606.579285,180.241043 608.291931,180.233841 
	C610.361633,180.629562 610.583435,182.207565 611.397949,183.684174 
	C611.632812,184.113327 611.728516,184.282516 612.095947,184.960236 
	C612.462463,185.836411 612.568604,186.200790 612.923584,186.716248 
	C613.175293,187.062027 613.191284,187.252853 613.111328,187.714401 
	C613.636597,191.661301 615.664978,194.871857 616.707275,198.750397 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M820.888550,416.523987 
	C817.470886,415.601105 814.473938,418.122406 811.065186,416.967255 
	C812.519409,411.021423 813.070251,405.046204 815.913940,399.357239 
	C816.024231,398.297363 816.090515,397.609131 816.799011,396.812866 
	C819.028442,392.315613 820.971619,388.017181 823.037292,383.373444 
	C824.092224,382.125458 825.195923,381.908142 826.821899,382.394897 
	C829.184814,385.236267 828.561096,388.240906 828.165344,391.115936 
	C827.203369,398.104950 825.876160,405.029816 823.606201,412.132751 
	C823.313049,412.946289 823.113037,413.384125 822.695679,414.081421 
	C822.275818,414.699768 822.024170,415.033844 821.603760,415.607300 
	C821.327148,416.008545 821.213135,416.165710 820.888550,416.523987 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M897.659302,658.056396 
	C898.217468,662.363586 897.565552,666.631592 898.756348,671.123840 
	C900.226196,676.668518 896.336792,681.518005 893.320862,685.606140 
	C890.359985,689.619751 888.659729,694.023560 887.192810,698.419312 
	C884.308838,707.061829 879.998352,715.005127 876.426514,723.301819 
	C874.815430,727.044128 872.773804,729.327026 868.218262,728.745422 
	C867.620056,729.393616 867.161194,729.540527 866.311646,729.220276 
	C866.189453,728.035706 866.343384,727.062744 866.574646,726.108582 
	C867.502686,722.279846 872.496582,724.188477 873.482605,721.734497 
	C874.442200,719.346436 872.602112,716.198364 876.124451,714.188843 
	C879.029114,712.531616 880.533020,709.418396 881.514587,706.080139 
	C884.086548,697.332886 888.228638,689.283020 892.971985,681.531555 
	C894.017212,679.823486 894.751038,678.031921 894.768372,676.058105 
	C893.795044,675.007690 893.314636,675.943970 892.656372,676.335999 
	C892.147766,676.639038 891.501343,676.710876 890.703186,676.949036 
	C890.616577,676.215088 890.380310,675.473694 890.539429,675.376038 
	C894.050293,673.219604 894.163513,669.203979 893.582031,666.384338 
	C892.707947,662.145447 894.935303,660.333679 897.659302,658.056396 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M684.742188,854.771973 
	C687.107483,856.225159 689.255920,857.463135 691.710205,858.853882 
	C693.192444,861.754761 690.908203,861.973206 689.500183,861.754089 
	C680.082336,860.288391 670.587219,859.103333 661.595276,855.669617 
	C660.825562,855.375671 660.098877,854.976074 659.399353,854.537964 
	C657.947449,853.628601 656.558289,852.500854 656.897766,850.636108 
	C657.218079,848.877136 658.980530,848.794312 660.798706,848.362793 
	C661.694153,848.121277 662.211609,848.058716 663.109009,848.080750 
	C666.308411,848.232788 668.803833,851.404785 672.137634,848.836670 
	C672.605103,848.468262 673.035522,848.343628 673.073425,848.158630 
	C673.123413,847.914490 672.745361,847.817871 672.080444,847.998169 
	C669.518127,848.062744 668.019043,847.081909 667.441895,844.458008 
	C671.727844,840.179443 676.470154,840.519226 681.683838,842.814575 
	C683.403748,844.096069 684.004333,846.449768 686.770508,845.873047 
	C687.528076,846.097900 687.981812,846.400269 688.558167,846.869263 
	C689.500000,850.847229 686.030273,849.471802 684.260132,850.758667 
	C683.764404,851.941284 683.127380,852.378906 681.970581,852.549805 
	C681.386780,852.399414 681.160217,852.212341 680.990723,851.966187 
	C680.815552,851.711731 680.856750,851.799561 681.270813,852.483887 
	C682.233459,853.730835 683.644775,853.692505 684.742188,854.771973 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M705.405151,829.963867 
	C706.881104,827.709412 710.042847,826.402161 709.997437,822.440552 
	C719.579102,821.471436 729.050720,821.045898 737.874207,816.559875 
	C740.676147,815.135254 744.885010,816.177856 747.372498,814.496948 
	C752.613586,810.955017 758.808838,810.825073 764.300110,808.449829 
	C764.666748,808.291199 765.223633,808.572266 765.881042,808.938965 
	C762.093445,811.736938 757.657288,813.249146 753.109436,815.313293 
	C751.151306,818.247009 748.580139,819.005310 745.209412,818.677612 
	C743.739563,818.909241 742.636658,819.263245 741.267212,819.845215 
	C738.952698,821.256897 737.036011,822.767578 734.143555,822.565613 
	C732.979980,822.764709 732.154785,823.088928 731.162964,823.701416 
	C729.830017,825.298279 728.493530,826.214600 726.265015,825.836304 
	C719.181335,827.037720 712.687012,829.409546 705.405151,829.963867 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M847.712646,474.743958 
	C848.184143,481.165588 848.359314,481.320435 854.740723,480.510925 
	C854.572876,484.239319 849.399109,485.792725 850.367310,489.942047 
	C849.702637,490.339935 849.348328,490.852051 848.915588,490.897644 
	C846.748840,491.126007 847.588257,492.341736 848.022034,493.762207 
	C847.792786,494.821381 847.546448,495.559570 847.173706,496.564728 
	C846.395508,497.407898 845.736328,497.165680 844.783691,496.651550 
	C842.088074,494.442352 840.782837,492.305481 843.895264,489.410339 
	C844.769958,488.086914 845.685486,487.221313 846.653442,486.021484 
	C847.004639,485.444397 847.151672,485.090942 847.201843,484.426147 
	C844.221436,481.714172 842.656189,477.502350 837.819214,477.013702 
	C837.001770,475.964630 836.921082,475.048431 837.118408,473.759521 
	C838.002991,470.799713 839.637268,468.244568 837.756409,465.077820 
	C837.732483,462.815765 839.062439,461.875732 840.906738,460.952789 
	C843.469971,460.306244 844.616699,457.683197 847.477051,457.846497 
	C847.831543,460.687347 847.731384,463.175659 851.666077,463.660736 
	C853.552246,463.893250 852.269287,466.081940 851.974609,467.781982 
	C851.445435,468.553894 851.339233,469.044037 851.826782,469.792236 
	C852.043518,469.968842 852.000000,470.000000 852.022583,469.985962 
	C852.145569,470.173126 852.245911,470.374298 852.163147,470.858673 
	C851.828491,473.348480 849.632202,472.855988 848.185791,473.904419 
	C847.468018,474.558655 846.887268,474.465454 846.065186,474.467468 
	C845.502075,474.376678 845.297058,474.217285 845.174988,473.972809 
	C845.054749,473.731934 845.082642,473.820557 845.516235,474.345703 
	C846.337463,474.526703 846.908752,474.508728 847.712646,474.743958 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M844.617676,245.007233 
	C844.656128,247.811020 842.784180,248.734650 840.246643,249.178635 
	C838.526794,249.479507 836.948364,250.746033 837.284729,252.575974 
	C837.677368,254.712158 839.893921,255.377975 841.616089,254.792389 
	C844.659424,253.757507 846.408325,254.418747 847.044373,257.563293 
	C847.538269,260.004425 849.808411,258.794037 851.064575,259.289307 
	C855.969788,261.223267 854.822571,265.624451 855.942627,269.490936 
	C856.152222,270.937927 856.711548,271.794220 857.712769,272.749603 
	C861.661987,270.743713 865.781006,270.870697 869.902161,271.005646 
	C871.489441,271.057648 872.722107,271.036469 872.400269,273.469788 
	C871.802429,277.989136 871.153259,282.428192 874.469727,286.679443 
	C876.124268,288.800323 873.394897,292.388611 874.991821,295.609741 
	C873.524536,295.886414 871.971802,295.132050 872.205811,293.728790 
	C873.192749,287.810089 868.114685,283.185638 868.969788,277.343262 
	C869.363098,274.655853 867.796265,273.850861 865.366028,273.986328 
	C863.383423,274.096802 861.437195,274.278168 859.460388,274.588013 
	C856.304321,275.082672 854.197937,274.625610 852.653259,270.403778 
	C850.617798,264.840607 847.437134,259.059906 840.780273,256.950226 
	C838.503296,256.228577 835.337341,256.002625 835.211487,252.944809 
	C835.085693,249.887680 836.301331,247.096558 840.027649,246.423599 
	C841.468079,246.163452 842.834961,245.496231 844.617676,245.007233 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M842.199585,461.624207 
	C840.639893,462.623505 838.904602,462.706909 838.147827,464.645630 
	C838.581421,466.903320 837.858337,468.094360 835.530029,468.387726 
	C834.117676,467.242767 833.041565,466.263977 832.066589,465.193085 
	C827.507507,460.185577 827.819946,459.071289 834.663452,457.043457 
	C836.827942,456.798676 836.682861,457.963776 836.551941,459.311554 
	C837.038391,457.809631 837.240234,456.505463 836.239136,455.101501 
	C835.150208,453.364532 833.780823,452.729095 836.443237,450.361816 
	C840.589661,446.675049 845.050842,442.541626 846.835571,436.776733 
	C847.155457,435.743317 847.415527,434.180084 846.884644,433.512665 
	C846.057617,432.473083 844.864929,433.270599 843.895569,434.076538 
	C842.101318,435.568390 841.240234,434.754730 841.009399,432.363892 
	C842.119080,432.599060 843.175171,432.647919 843.975464,431.506073 
	C845.874451,428.796661 848.164185,429.062164 850.846497,430.847778 
	C849.424988,435.181793 847.797974,439.238861 846.037720,443.637970 
	C844.368896,446.469330 843.826111,448.893646 844.738403,451.953125 
	C845.724976,455.261597 844.157349,458.453644 842.199585,461.624207 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M811.637390,693.710083 
	C813.595764,695.662659 811.510193,697.914185 812.948059,699.969666 
	C813.237732,701.983459 811.928101,702.612976 810.549683,703.571167 
	C808.901978,704.443970 807.497986,705.057800 805.861084,705.934814 
	C804.407043,706.639404 803.246399,707.204834 801.714355,707.664490 
	C800.865540,707.861267 800.313171,707.984863 799.838379,708.754395 
	C799.583374,709.412842 799.380371,709.766785 798.878174,710.299072 
	C798.000488,710.792603 797.352356,710.840759 796.377686,710.565247 
	C794.668701,707.576721 792.465637,709.329468 790.210938,709.465332 
	C789.448120,709.358093 789.064819,709.163757 788.569336,708.603882 
	C788.164673,706.553223 787.055847,707.239319 785.746399,707.504150 
	C785.196411,707.371277 784.985962,707.265320 784.562073,706.865906 
	C783.925476,705.600281 783.945312,704.621643 784.498047,703.304138 
	C786.658264,701.165344 789.126892,700.638062 792.010620,700.275452 
	C793.323242,700.150635 793.863220,701.812378 794.941833,700.611633 
	C794.068970,696.569336 797.525452,696.006042 799.748901,694.111206 
	C801.069885,693.641907 802.058350,693.346191 803.435303,692.915405 
	C806.369263,692.474365 808.902832,692.163269 811.637390,693.710083 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M553.201233,184.189285 
	C549.677490,181.047714 548.357727,176.822266 545.806335,173.391174 
	C543.323486,170.052277 539.741211,168.409409 536.738220,165.908249 
	C535.355469,164.756561 533.902710,165.647278 532.116943,165.901596 
	C532.976196,163.994064 531.547546,162.556290 530.795654,160.616882 
	C535.668213,159.214111 543.629150,164.052368 546.913818,170.257263 
	C548.640991,173.519958 551.999573,175.242783 554.054688,178.227753 
	C554.842468,179.371948 556.710144,179.604828 557.869629,177.497009 
	C559.365845,176.035461 559.711487,177.609543 560.649170,178.429626 
	C561.045654,182.065109 563.771606,184.283112 564.919678,187.606079 
	C566.952454,190.938507 563.161072,189.002930 562.730347,190.412567 
	C557.958130,190.675476 555.967590,187.185074 553.201233,184.189285 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M845.167786,488.190186 
	C845.916443,490.791534 840.764404,492.876160 844.942078,495.574310 
	C845.659058,497.502930 847.081238,497.559937 848.802490,497.800262 
	C849.656616,498.198273 850.218384,498.399323 850.903442,498.885803 
	C851.465271,500.128937 851.040710,500.850464 850.110046,501.684021 
	C849.832947,501.858643 849.944275,501.956299 849.909912,501.890717 
	C849.760376,502.151062 849.578003,502.432190 849.100525,502.795898 
	C848.882874,502.930054 848.979370,502.984436 848.945740,502.940430 
	C848.232666,504.082947 847.841064,505.490967 846.099670,506.208923 
	C845.428894,506.573669 845.092468,506.847198 844.468872,507.274536 
	C842.287109,506.536713 841.905090,505.220123 843.069153,503.469086 
	C842.933777,503.387909 842.806519,503.265472 842.660828,503.236938 
	C842.507568,503.206879 842.336670,503.266693 841.891724,503.139404 
	C840.015564,500.728424 839.556152,497.834137 837.391479,495.471558 
	C836.560425,494.071106 836.155273,492.904297 836.091919,491.259491 
	C837.516724,486.473022 834.636047,481.587189 837.912109,477.157349 
	C839.202026,475.747498 840.127014,474.490479 841.722900,476.305481 
	C844.099792,479.008728 846.532471,481.662811 848.967896,484.686615 
	C848.801208,485.157349 848.607971,485.279205 848.085327,485.487183 
	C846.538147,485.923004 843.629456,484.408936 845.167786,488.190186 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M521.307312,124.262787 
	C514.899536,120.689125 513.174194,115.858955 516.685730,111.824890 
	C518.963440,109.208229 522.021790,108.253304 525.232056,109.340492 
	C528.457397,110.432777 530.324463,112.951645 530.225281,116.564705 
	C530.111206,120.717377 528.190918,123.418953 523.591309,124.274544 
	C522.716003,124.574615 522.191467,124.622070 521.307312,124.262787 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M782.364014,796.092407 
	C779.102051,796.536316 776.730469,798.278931 774.240784,799.832886 
	C772.879883,800.682190 771.115356,801.790649 770.971313,798.354858 
	C771.069946,797.078613 771.463623,796.394775 772.709900,796.118652 
	C773.214172,796.216675 773.402039,796.304260 773.804138,796.166626 
	C774.018433,795.941406 774.181458,795.792175 774.275269,795.850464 
	C775.171631,795.738342 775.961975,795.524536 777.000366,794.934082 
	C780.806885,793.182007 784.347107,791.741089 788.169739,790.500000 
	C788.432068,790.041321 789.407166,790.695129 789.447083,789.757385 
	C796.938843,787.495300 803.426941,783.464172 810.552063,780.000977 
	C811.698425,779.491150 812.296936,779.903381 813.038879,780.733459 
	C807.167786,786.309326 800.348328,790.425293 793.306030,794.782959 
	C789.309998,793.655762 786.013794,795.137878 782.364014,796.092407 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M403.701752,175.083664 
	C404.384186,174.238281 404.882599,173.688416 405.649628,173.047333 
	C407.310028,172.415619 408.860291,170.700516 410.148529,172.410706 
	C411.284149,173.918274 409.629883,175.328247 408.633514,176.921570 
	C408.322662,177.367462 408.204987,177.548584 407.898010,177.987823 
	C407.578430,178.412918 407.448242,178.579910 406.933777,179.248932 
	C406.302551,180.098038 406.053253,180.443390 405.604706,181.050659 
	C405.257233,181.478302 405.106293,181.642059 404.704681,182.030914 
	C399.563629,187.738815 394.281403,192.959702 392.271332,200.749969 
	C391.937500,202.527420 391.318817,203.769699 389.906189,204.922668 
	C389.191620,205.291412 388.752747,205.343033 388.026184,205.064636 
	C387.618073,204.727417 387.483124,204.565552 387.196686,204.134323 
	C386.883148,203.504166 386.730347,203.139603 386.500031,202.525955 
	C386.403687,202.284561 386.212860,201.881027 386.212860,201.881027 
	C386.212860,201.881027 385.761902,201.902863 385.565063,202.084213 
	C384.092133,203.245193 383.188843,204.996948 380.869873,204.080627 
	C380.271057,202.833710 380.502472,202.015930 381.691864,201.197998 
	C385.000580,194.954391 390.788879,191.088318 394.495178,185.139618 
	C394.822876,183.601059 395.421753,182.619507 396.896179,181.831711 
	C397.235840,181.708664 397.269226,181.333496 397.450867,181.283661 
	C399.536835,179.224594 401.745758,177.521530 403.701752,175.083664 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M407.208282,178.372696 
	C406.730255,175.787552 409.322662,175.036713 409.681519,173.087173 
	C408.654114,172.022552 407.594421,173.330353 406.253601,172.979858 
	C410.905975,167.259933 417.054688,163.262161 424.339539,160.260620 
	C426.053528,159.314606 427.169556,159.956100 428.539856,161.279602 
	C422.932373,164.835251 418.423401,169.408508 415.226929,175.729156 
	C414.870758,176.130783 414.736603,176.275742 414.711975,176.695969 
	C415.119751,177.520142 415.417969,178.069061 415.851990,178.903961 
	C416.050537,179.379913 416.113342,179.569901 416.134094,180.057098 
	C413.408722,181.257019 414.355530,184.415161 412.365601,186.131653 
	C409.655212,186.964355 409.300201,185.337875 409.259033,183.583313 
	C409.214569,181.688721 408.528168,180.107651 407.208282,178.372696 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M539.059448,180.333435 
	C541.821411,179.237350 540.876099,180.967865 540.468323,182.077728 
	C538.899109,186.349136 541.902588,188.786484 543.522949,192.100037 
	C545.525818,190.406601 543.493469,189.120834 544.464783,187.758789 
	C545.783569,188.680481 547.131531,189.622589 548.714661,190.783310 
	C549.445862,191.378708 549.773499,191.858612 549.835571,192.786316 
	C550.116272,193.559570 550.419983,194.033401 550.868042,194.748962 
	C551.321533,195.301910 551.580933,195.650131 551.887939,196.347046 
	C552.213623,197.740158 552.659790,198.722839 553.477051,199.915848 
	C553.779297,200.678223 553.873413,201.143463 553.943970,201.989563 
	C554.149353,208.863144 553.684631,209.629593 548.353149,210.895844 
	C547.470459,210.631271 547.038330,210.347183 546.455933,209.644928 
	C545.250305,203.435318 543.656555,197.760605 540.261597,192.339432 
	C534.675842,186.644745 534.675842,186.644745 539.059448,180.333435 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M831.303101,672.775085 
	C831.059937,673.606873 830.935547,674.222778 830.507935,674.942017 
	C827.639404,675.426086 828.436646,673.868652 828.835571,672.236389 
	C827.090149,669.582336 826.118408,671.885132 824.967224,672.672241 
	C823.340210,673.785034 821.824463,675.060425 820.095398,676.555664 
	C818.927124,678.401306 818.067505,680.073425 816.149292,681.321533 
	C815.155457,681.794189 814.449463,681.843018 813.404419,681.494629 
	C811.833740,678.630859 813.964600,676.816162 815.014282,674.371216 
	C815.366150,673.896179 815.520203,673.718018 815.935791,673.307312 
	C818.257324,671.686890 820.236023,670.157349 822.965515,669.278931 
	C824.135925,668.963806 824.811523,668.541138 825.188354,667.352905 
	C825.369263,666.863892 825.442261,666.668335 825.820435,666.402710 
	C832.737549,663.541260 837.893616,658.490234 843.951721,654.837036 
	C845.499573,653.903625 846.959045,652.823669 848.799377,651.660889 
	C849.455017,652.041626 849.551880,652.609802 849.169434,653.575500 
	C845.770203,658.905945 840.456787,661.427002 835.895935,664.529053 
	C832.516052,666.827820 829.229980,667.707153 832.275024,672.398010 
	C831.913147,672.517395 831.421936,672.559143 831.303101,672.775085 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M539.861084,191.992889 
	C545.407410,196.049652 546.220398,201.957291 546.804382,208.344818 
	C546.501038,209.212143 546.126831,209.483459 545.236694,209.478058 
	C542.792419,208.070511 541.050903,206.213654 538.671753,205.304657 
	C533.560669,203.351868 529.976807,199.886597 528.078796,194.315598 
	C527.713867,193.342148 529.816895,192.374771 527.398193,191.971313 
	C526.414734,191.494568 525.612671,191.221115 524.938110,190.293716 
	C525.085022,188.839020 524.822937,187.816818 523.858398,186.646790 
	C523.729553,186.341263 524.031494,185.976318 524.241455,185.866714 
	C525.503540,185.291016 526.264282,185.722153 526.973938,186.897156 
	C529.533813,189.836487 530.664734,193.708527 534.822632,195.143784 
	C535.454285,195.643906 535.427734,196.121078 535.460632,196.586441 
	C535.535461,196.308975 535.523560,196.032120 535.295654,195.514496 
	C535.040466,194.231186 535.289673,193.419495 536.806885,193.138397 
	C537.157288,193.105820 537.413147,193.465469 537.517944,193.651535 
	C537.622803,193.837601 537.633850,193.448517 537.793762,193.337601 
	C538.473816,192.823135 538.993896,192.419586 539.861084,191.992889 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M850.177979,501.871216 
	C850.199463,501.160675 850.565979,500.462708 850.979614,499.468018 
	C851.968689,497.993256 852.910522,496.815277 853.901978,495.314301 
	C853.762024,493.489227 854.559570,492.434204 855.790283,491.235107 
	C857.983337,488.194000 858.648499,484.843628 860.006958,481.395264 
	C861.462585,480.704498 862.008972,481.575989 862.405151,483.027649 
	C861.739197,488.085419 861.100403,492.744354 860.713501,497.813751 
	C862.705688,500.021149 862.531067,502.022156 861.316895,504.463623 
	C858.354736,507.937744 859.622864,511.943298 858.401978,515.744507 
	C856.299683,512.623535 856.403503,508.777985 856.088745,505.102020 
	C855.729492,500.906219 854.171082,499.865082 850.177979,501.871216 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M821.830322,414.282440 
	C824.463745,403.974792 826.567810,393.926056 827.638672,383.211487 
	C826.049377,380.958832 826.523438,379.162384 827.993774,377.171753 
	C829.649475,376.988770 830.930176,376.995941 832.595398,377.000671 
	C832.315552,390.280945 828.726868,402.884094 823.958618,415.587891 
	C822.651184,416.245087 822.044495,415.640930 821.830322,414.282440 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M310.973877,202.834106 
	C306.374268,207.696716 302.439789,208.782562 299.115723,206.024063 
	C296.737183,204.050232 295.855988,201.472122 296.914917,198.475800 
	C298.072174,195.201233 300.693359,194.120026 303.935547,193.991867 
	C307.767853,193.840378 309.721588,195.913971 310.743652,199.700531 
	C311.807068,200.790161 311.846710,201.694031 310.973877,202.834106 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M475.210327,181.846558 
	C471.655457,181.493683 474.058319,177.382294 471.185913,176.214813 
	C470.825745,173.845078 472.548004,174.263641 473.856537,173.814835 
	C474.038727,173.737839 474.291809,173.761337 474.291809,173.761337 
	C474.291809,173.761337 474.134613,173.572037 473.965973,173.697235 
	C473.050720,174.008652 472.319458,173.930359 471.313416,173.516663 
	C470.734985,172.967758 470.631165,172.544876 470.912537,171.817749 
	C475.952454,165.007004 482.819336,162.756683 490.826965,162.511978 
	C493.257599,165.367279 492.283203,167.201675 489.555176,168.457230 
	C487.641449,169.338013 485.594269,169.949081 483.793304,171.685883 
	C483.005676,172.202667 482.400238,172.352661 481.602692,172.780228 
	C480.730194,173.424042 480.177582,173.980286 479.549683,174.901993 
	C477.795654,177.169174 477.256927,179.781662 475.210327,181.846558 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M381.566101,201.055237 
	C381.674896,201.771545 381.375610,202.563385 381.043243,203.678833 
	C381.277496,205.528397 380.920715,206.828964 379.302246,207.907532 
	C375.900085,207.059082 372.835663,206.098846 369.397400,205.121643 
	C366.052338,204.213669 363.032349,203.452332 360.136383,202.361572 
	C359.118378,201.978134 357.019287,202.630859 357.231079,200.527588 
	C357.485443,198.001297 359.649078,199.898972 360.877441,198.927399 
	C368.117310,196.267014 374.421600,200.431366 381.566101,201.055237 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M655.099182,177.210846 
	C653.800232,176.631561 652.760193,176.301941 651.390015,175.958832 
	C650.431152,172.510468 647.085449,170.244354 646.989563,166.171997 
	C646.664001,165.411621 646.479553,164.995743 646.167847,164.269196 
	C645.949951,164.000031 646.003601,163.997253 645.994507,164.022552 
	C645.589050,163.581787 645.525269,162.982483 645.145752,162.211533 
	C642.663696,160.438797 644.208618,157.654968 642.993896,155.365021 
	C646.450623,158.917389 648.889282,163.556290 651.930054,167.753159 
	C653.932983,170.517578 656.999817,172.511124 659.904541,174.992325 
	C660.346375,175.295670 660.473938,175.456573 660.826355,175.827988 
	C661.051208,176.038483 660.972717,176.021820 660.976624,175.981888 
	C661.509094,176.514389 662.063354,177.061401 662.834717,177.804230 
	C664.161804,178.862259 663.976257,180.100525 664.000854,181.636688 
	C662.817810,181.322449 661.873901,177.623764 660.068359,181.866272 
	C658.014648,182.588806 656.977905,181.686432 656.110779,179.841095 
	C655.973206,179.495483 656.003479,179.494553 655.997803,179.508514 
	C655.747498,178.845383 655.524780,178.161545 655.099182,177.210846 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M387.395081,165.448090 
	C386.747925,162.550827 387.593140,160.379990 389.699036,159.081085 
	C392.603333,157.289703 395.803741,157.061157 398.262695,159.794464 
	C400.801483,162.616531 400.115082,165.525360 397.503632,168.075394 
	C394.783905,170.731140 391.973145,170.718369 389.204102,168.231705 
	C388.496979,167.596680 388.094788,166.622070 387.395081,165.448090 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M787.092896,323.553284 
	C785.397095,323.225830 786.622559,320.750244 784.416992,320.038330 
	C782.607300,318.796814 782.456787,317.100403 782.459961,315.453522 
	C782.474854,307.818604 780.839844,300.326263 780.792603,292.332520 
	C780.876099,291.414764 780.731812,290.883972 780.373535,290.172852 
	C779.667236,289.631195 779.473022,289.149475 779.803589,288.196655 
	C780.127014,287.058868 780.340210,286.352966 781.511230,286.064972 
	C782.208496,287.105438 782.623413,288.195526 783.030945,289.638000 
	C783.016846,290.563843 783.147034,291.096100 783.663086,291.787598 
	C786.812622,302.181000 789.159363,312.416809 787.092896,323.553284 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M267.683472,225.837006 
	C265.096649,232.750305 261.958038,234.994797 256.797913,234.012741 
	C251.923553,233.085083 251.574020,232.088699 254.555054,228.161011 
	C254.991455,227.585999 254.863678,226.582779 254.997864,225.389160 
	C255.743851,224.993149 256.488922,224.985687 257.606262,224.963989 
	C258.151611,224.802536 258.324646,224.655319 258.756317,224.286087 
	C259.014923,224.064087 259.504303,224.023819 259.749390,224.009369 
	C260.383270,223.617401 260.845123,223.471756 261.690552,223.778992 
	C264.021820,223.811768 265.876953,224.154999 267.683472,225.837006 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M760.406860,737.073486 
	C760.021301,736.583496 760.035522,736.141052 760.056946,735.366699 
	C764.379028,732.373535 768.763367,729.859192 771.143005,724.975769 
	C771.961365,723.296448 774.005066,722.869507 775.781555,722.424133 
	C778.200745,721.817505 780.544495,721.070923 782.593750,718.900879 
	C783.184082,718.667358 783.445068,718.635864 784.083252,718.738831 
	C786.273071,720.697266 786.777588,722.564209 784.272339,724.694275 
	C776.783936,730.052429 770.006958,735.870911 760.406860,737.073486 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M498.443237,163.831451 
	C500.121857,164.732941 500.586517,166.032898 500.929504,167.771484 
	C500.297760,169.121185 499.228638,170.066422 500.741547,171.576477 
	C500.798676,174.434769 503.350983,174.789490 504.758423,176.518005 
	C504.920624,176.802414 504.985016,176.990463 505.010651,177.086487 
	C503.206482,178.091827 501.316681,178.674347 498.832397,178.170563 
	C494.287933,175.641296 489.580322,177.491943 485.008911,176.056305 
	C486.359314,168.617340 492.258179,166.212433 498.443237,163.831451 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M803.241699,225.786072 
	C803.019897,226.916458 802.702209,227.743759 802.035889,228.850189 
	C799.565002,227.184189 799.446960,222.834473 794.475586,223.750565 
	C798.772644,222.432861 796.614685,220.205185 795.752075,218.587814 
	C791.152954,209.965332 785.365234,202.106888 779.546875,193.870636 
	C779.372498,193.288925 779.367615,193.000275 779.793701,192.754074 
	C788.934387,202.942108 796.248047,214.066681 803.241699,225.786072 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M857.625122,413.849182 
	C854.831665,411.484283 853.249146,412.482483 852.294189,415.557312 
	C851.170837,419.174042 849.990540,422.760284 847.761658,426.077484 
	C843.582764,421.675018 845.203918,416.348022 845.191833,411.346619 
	C845.189697,410.460541 846.182983,410.140747 847.450195,410.017456 
	C847.720947,410.937408 847.588989,411.881317 847.518066,413.181519 
	C847.544373,413.724091 847.509583,413.910309 847.757812,414.070068 
	C850.956787,413.263763 852.703552,411.080261 854.289734,408.749207 
	C855.141357,407.497528 856.163574,405.793152 857.579529,406.214417 
	C859.409607,406.758911 858.459229,408.710052 858.302551,410.115509 
	C858.174255,411.266907 858.000366,412.413208 857.625122,413.849182 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M737.577881,803.091614 
	C737.869446,804.579773 736.566956,805.069519 735.566406,806.281738 
	C734.536926,808.595276 731.565796,807.513306 730.747925,810.029907 
	C729.614136,812.268311 727.810852,811.941284 725.756592,811.915161 
	C724.648254,811.737000 723.978394,811.421082 723.008057,810.885254 
	C720.880310,808.799805 718.113892,809.741211 715.782837,808.285400 
	C714.740784,807.433960 714.100098,806.712524 713.901978,805.387024 
	C713.996826,804.843445 713.998718,804.654358 714.028198,804.182861 
	C721.785645,804.712341 729.403992,802.631958 737.577881,803.091614 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M794.338562,205.985168 
	C791.903503,202.047348 795.511353,200.926208 797.244995,199.681641 
	C799.522095,198.046951 801.617004,200.080460 803.122131,201.795013 
	C804.975952,203.906601 805.892883,206.478699 803.852356,208.956848 
	C802.525452,210.568359 800.887878,212.235214 798.470459,211.873474 
	C795.319702,211.401978 795.190613,208.622177 794.338562,205.985168 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M784.274170,724.862427 
	C784.809021,723.222168 785.927979,721.588379 784.133301,719.425415 
	C781.711914,717.304749 783.290039,716.353210 785.120117,715.252197 
	C787.331848,714.678711 789.143921,714.048584 790.965210,712.653564 
	C791.450134,712.450500 791.652893,712.393555 792.167969,712.311462 
	C793.095520,712.305237 793.710327,712.369080 794.636353,712.249939 
	C795.157898,712.151917 795.369690,712.111572 795.909302,712.032715 
	C797.168640,711.965210 798.054199,712.116272 799.078857,712.921875 
	C800.009033,714.637390 799.505371,715.982971 798.769409,717.622681 
	C798.273865,718.294800 797.886902,718.802795 797.465881,718.788818 
	C795.283203,718.716492 794.875488,720.266846 794.165161,721.978149 
	C791.135254,724.172241 787.875183,724.456055 784.274170,724.862427 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M469.612366,143.008728 
	C471.122131,143.566803 472.091797,144.361755 471.484467,146.001877 
	C469.042145,152.597656 469.459534,159.997421 465.792267,166.249680 
	C465.465240,166.807236 465.226776,167.463272 464.168518,167.123505 
	C462.486115,165.413788 464.178925,163.377274 462.961365,161.485596 
	C460.189484,158.422546 459.899506,155.679321 462.642578,152.757782 
	C463.501740,151.842758 464.168823,150.680710 464.759277,149.125732 
	C464.906067,148.488953 465.017548,148.223877 465.459381,147.936768 
	C467.153839,147.180634 467.997070,145.940170 468.788147,144.221817 
	C468.957520,143.594498 469.090149,143.304550 469.612366,143.008728 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M725.702759,779.221069 
	C727.908142,776.806396 730.489319,775.773926 733.613037,775.060303 
	C735.604065,777.453064 738.351257,776.865784 740.861511,777.790405 
	C739.356262,781.057800 735.271667,781.755310 734.007690,785.579346 
	C732.337280,790.248779 730.015930,786.742798 726.616760,786.830750 
	C728.937317,785.625305 730.040405,785.052246 731.401672,784.345093 
	C728.604736,783.880737 726.453979,782.913818 725.061096,780.305054 
	C725.164368,779.794922 725.318909,779.631653 725.702759,779.221069 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M798.195007,718.244751 
	C798.291565,716.649963 798.614990,715.243286 799.086548,713.157593 
	C799.507629,711.865051 799.869324,711.304810 800.461731,710.492737 
	C801.832764,708.581116 803.623230,707.467163 804.745483,705.347290 
	C806.327148,704.362854 807.733093,703.665344 809.476624,702.933228 
	C810.520264,703.291870 810.764160,703.957031 810.880615,705.054993 
	C810.796875,708.464233 808.622498,710.698608 807.765869,713.765015 
	C809.021606,720.259277 804.264221,718.541138 800.813843,719.057007 
	C800.214111,719.117432 800.020630,719.204468 799.685669,719.015869 
	C799.465820,718.371887 799.831909,717.960571 799.739075,717.763855 
	C799.459229,717.170776 799.973877,717.642517 799.207214,718.206055 
	C798.815002,718.428040 798.618469,718.430481 798.195007,718.244751 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M826.792847,694.437866 
	C828.066345,691.727783 830.582581,691.308472 832.579346,690.250916 
	C839.762146,686.446289 847.011597,682.767517 854.690125,679.101746 
	C854.989441,680.720703 853.687012,681.324890 852.597351,681.953308 
	C844.451111,686.652161 837.220825,692.840576 828.229858,696.795410 
	C826.903381,696.484070 826.349060,695.837952 826.792847,694.437866 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M464.604889,148.950089 
	C465.103973,150.319519 464.873840,151.744934 463.721283,152.823746 
	C460.928802,155.437469 461.518219,157.913086 463.966858,160.704285 
	C464.137115,161.209625 464.267944,161.371613 464.341888,161.841217 
	C464.153259,162.548538 464.037903,162.952942 463.831116,163.663147 
	C463.613373,164.146576 463.502838,164.328705 463.188843,164.756699 
	C462.097626,165.933701 461.126343,166.682434 459.525208,166.384171 
	C459.147858,166.035767 458.967590,165.886826 458.783386,165.827332 
	C458.998138,165.984344 459.175751,166.177002 459.413208,166.682938 
	C456.394531,168.184647 454.580597,171.364548 451.038239,172.203003 
	C450.529205,171.553925 450.476715,171.080460 450.783997,170.298584 
	C451.344910,169.540329 451.675140,169.073700 452.022552,168.309265 
	C452.037720,167.410355 452.213257,166.871796 452.815796,166.210007 
	C456.783020,160.372406 460.538361,154.733734 464.604889,148.950089 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M816.476990,212.669586 
	C817.863281,213.271454 820.016907,212.575394 819.453613,214.757095 
	C818.947937,216.715836 818.979431,219.390778 816.267944,219.862778 
	C813.917603,220.271896 811.200500,219.722595 809.913696,217.904160 
	C808.469116,215.862701 807.623230,213.108154 808.206116,210.394302 
	C808.549927,208.793625 809.881409,208.984344 810.982605,209.558060 
	C812.738098,210.472641 814.417664,211.532928 816.476990,212.669586 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M310.797668,203.390976 
	C310.666016,202.185760 310.699463,201.386292 310.825165,200.299881 
	C312.849518,200.360458 314.376892,199.221008 315.114227,197.888016 
	C317.791107,193.048553 321.408325,197.248901 324.505066,196.822296 
	C325.246948,196.720062 324.669312,198.496780 324.153015,199.667892 
	C323.537384,200.339554 323.148987,200.789993 323.058075,201.767456 
	C322.527679,202.654633 322.018005,203.219742 321.248596,203.915009 
	C317.672272,202.937500 314.253052,205.969009 310.797668,203.390976 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M812.860596,681.262817 
	C813.818604,681.036682 814.432312,680.956238 815.343872,680.780029 
	C815.857849,680.720947 816.074036,680.757690 816.548584,680.994019 
	C817.755981,682.622986 819.272461,682.617126 821.367737,682.873352 
	C823.338135,683.307556 824.527405,683.979370 822.510620,684.833862 
	C820.191589,685.816589 816.761597,684.847595 818.479065,689.818542 
	C819.138733,691.727966 815.109192,691.918884 812.468750,692.006287 
	C807.737671,693.225769 807.737671,693.225769 808.905884,687.221802 
	C809.221924,684.452271 810.883972,682.940247 812.860596,681.262817 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M681.961426,191.977829 
	C677.630005,193.523575 680.108826,187.052979 676.699890,187.453476 
	C676.630676,186.193588 677.208557,185.194412 678.181152,184.528671 
	C679.543762,183.595947 681.133301,182.907730 680.997803,180.349213 
	C686.421570,183.088959 684.091675,190.232529 688.344666,194.026520 
	C688.831421,194.460754 688.869324,195.393219 688.106812,195.676620 
	C687.573914,195.874695 686.679932,195.795059 686.253235,195.455246 
	C684.980652,194.441681 683.858887,193.238708 682.337097,192.056793 
	C681.995972,192.003860 681.919617,191.957840 681.961426,191.977829 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M804.826782,811.239014 
	C806.840210,810.329773 808.766113,809.825317 810.491455,808.933044 
	C811.704224,808.305786 812.616455,808.128723 813.562134,809.139221 
	C814.105530,809.719971 814.900940,810.324341 814.379944,811.241394 
	C813.783203,812.292053 812.626343,812.053223 811.705017,811.971313 
	C806.820068,811.536682 806.746338,817.195496 803.292969,818.558411 
	C803.052551,818.653320 802.983032,819.128174 802.782776,819.386475 
	C801.464050,821.087036 799.498535,823.580811 797.760803,822.201721 
	C795.526917,820.428833 798.203613,818.566528 799.509216,816.964661 
	C800.645569,815.570618 801.656067,814.074036 802.861084,812.311401 
	C803.551208,811.825989 804.102356,811.651978 804.826782,811.239014 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M833.237671,780.190674 
	C831.143005,785.433289 825.379211,783.491638 822.286194,786.477234 
	C821.608704,787.131165 820.770386,785.311279 821.417175,784.513184 
	C824.211853,781.064758 827.180603,777.736084 830.914795,775.270874 
	C831.813904,774.677307 833.338318,774.626404 834.185913,775.489319 
	C835.791199,777.123718 834.013672,778.458252 833.237671,780.190674 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M826.662109,694.185303 
	C827.213745,694.890137 827.419800,695.620850 827.755737,696.610962 
	C825.825378,698.228210 823.765198,699.586060 821.376343,701.036499 
	C820.217834,702.343262 819.180969,703.231689 817.313354,703.139343 
	C815.865967,702.916626 815.012268,703.476440 814.067017,704.486755 
	C813.145874,705.926270 812.221436,706.750000 810.393738,706.116516 
	C810.021423,705.118042 809.941223,704.379700 809.837646,703.269958 
	C810.774536,702.183533 811.734863,701.468506 812.878540,700.521790 
	C814.577881,699.733032 816.093933,699.175903 817.821960,698.350708 
	C818.386292,697.598022 818.831604,697.236023 819.694580,696.999878 
	C822.094910,696.041687 824.205750,695.126465 826.662109,694.185303 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M682.295044,844.155762 
	C677.551147,841.287292 672.937622,843.341736 668.191406,843.791504 
	C665.872742,845.526001 664.276917,844.751099 662.966797,842.441772 
	C666.203003,839.675598 669.472473,838.024963 673.620850,839.858765 
	C675.103882,840.514282 676.314514,839.088013 677.834106,838.084839 
	C680.010071,839.433777 681.837585,840.931946 683.804199,842.724365 
	C683.743958,843.708557 683.270203,844.107910 682.295044,844.155762 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M837.792847,297.645416 
	C835.951416,297.260071 832.844849,299.761017 832.234863,297.142731 
	C831.259521,292.956024 827.221863,288.521606 832.282715,283.975952 
	C834.342163,288.304138 836.064270,292.789490 837.792847,297.645416 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M498.866760,163.548157 
	C493.316223,166.181519 489.202942,170.225235 485.061646,174.695465 
	C484.695587,175.656219 484.262970,176.113113 483.268219,176.318573 
	C482.306854,176.028931 481.855957,175.557816 481.388550,174.663605 
	C481.179596,173.916824 481.160675,173.477005 481.546967,172.470184 
	C483.709839,169.894913 485.362152,167.838516 488.515106,167.565155 
	C490.582031,167.385956 492.082703,165.958298 491.112915,163.080231 
	C493.659210,163.982986 496.500916,159.647598 498.866760,163.548157 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M862.619751,483.109131 
	C861.821838,482.723755 861.265625,482.021027 860.402283,481.180786 
	C861.906128,472.744080 863.492920,464.423492 862.447388,455.391052 
	C862.659058,454.831573 862.917725,454.737732 863.536865,454.844696 
	C865.247131,464.357513 864.835388,473.603821 862.619751,483.109131 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M854.664307,242.037842 
	C854.799011,245.240738 856.150879,248.055145 857.414368,250.902191 
	C858.114746,252.480453 858.532532,254.257446 856.781311,255.285385 
	C855.059692,256.296051 854.850891,254.081161 853.883545,253.427979 
	C851.535583,251.842667 849.231628,250.221481 850.000000,246.402405 
	C852.049805,245.361359 851.980042,242.384720 854.664307,242.037842 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M796.157715,402.347198 
	C792.132996,397.323669 792.492554,391.763275 797.265991,387.176666 
	C797.541626,387.492035 797.505249,387.919800 797.467407,388.670532 
	C799.432129,393.492188 801.947876,397.954987 798.033142,403.017944 
	C797.141479,403.304657 796.679321,403.133484 796.157715,402.347198 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M797.383789,403.984985 
	C798.638123,400.678162 800.583862,397.830658 798.190063,394.273651 
	C797.357971,393.037201 796.753906,391.338318 797.306763,389.308929 
	C798.825684,389.006470 800.234985,388.631256 801.755432,389.813171 
	C803.189026,390.864410 803.743103,391.891266 803.302246,393.610535 
	C801.384338,394.799164 802.476562,395.860992 802.960754,397.172363 
	C803.225220,398.947571 803.136108,400.374542 802.067871,401.968506 
	C801.252930,403.047485 800.520935,403.686035 799.342041,404.341370 
	C798.513428,404.547516 798.040710,404.482849 797.383789,403.984985 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M741.410950,777.936890 
	C739.866028,778.273438 738.618530,778.437805 737.408569,778.748352 
	C734.840515,779.407288 733.845398,778.307312 733.978516,775.440491 
	C737.842896,772.124817 742.247803,772.377747 747.069214,773.047241 
	C745.537048,774.751831 743.631226,776.291443 741.410950,777.936890 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M782.015747,796.278687 
	C785.314331,793.791626 788.520813,791.353271 792.779419,794.543823 
	C791.726196,795.636719 790.485596,796.415710 789.068970,797.455322 
	C788.385803,798.753174 787.628967,799.458496 786.112793,799.481018 
	C784.408447,800.164734 783.017395,800.938110 781.289856,801.765625 
	C779.156738,799.667358 781.645630,798.352173 782.015747,796.278687 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M828.335571,376.586243 
	C828.213745,378.679871 828.058777,380.378113 827.778870,382.409058 
	C826.455872,382.830963 825.257874,382.920227 823.609802,383.018799 
	C824.341614,377.698792 825.971069,372.449005 826.079102,366.501465 
	C828.176025,365.717896 827.697754,367.680939 828.580261,368.812317 
	C828.600586,371.452301 828.451477,373.821564 828.335571,376.586243 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M842.255493,461.772095 
	C842.944458,457.459564 844.393188,453.074036 843.527039,448.481598 
	C843.154968,446.508972 844.056335,445.402069 845.639343,444.157532 
	C843.155701,448.878235 848.765930,452.513428 847.846313,457.540558 
	C845.362000,458.216705 844.399780,460.557739 842.255493,461.772095 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M820.722168,638.647461 
	C825.576599,634.826050 827.454712,629.614563 830.638062,624.930664 
	C831.378113,624.831909 831.763062,625.081360 832.250549,625.750000 
	C830.007263,630.809998 827.591064,635.562988 825.076050,640.653931 
	C824.816711,641.136353 824.656067,641.280884 824.181396,641.510803 
	C822.958069,641.628296 822.167603,641.282898 821.159790,640.616089 
	C820.645386,640.051147 820.491150,639.652222 820.722168,638.647461 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M816.314209,656.953003 
	C817.250793,659.677551 822.052551,660.253845 819.940491,664.664551 
	C818.166870,663.408569 816.834961,662.379150 814.514832,663.062866 
	C811.782593,662.922058 809.737305,662.185913 808.547913,659.495850 
	C808.702515,657.606689 809.763855,657.174500 811.438721,657.147461 
	C812.167847,657.385132 812.447571,657.746033 812.798584,657.945984 
	C813.369263,658.271118 812.511353,658.016907 813.119690,657.744080 
	C813.728027,657.471313 813.199280,656.980347 813.098389,657.639343 
	C813.094482,657.664917 812.953491,657.688721 812.506958,657.420288 
	C812.047546,654.541443 813.307434,653.080078 816.377014,652.743591 
	C816.606750,654.288940 818.965515,655.837097 816.314209,656.953003 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M797.069946,403.337219 
	C797.507812,403.727051 797.830261,403.797729 798.318604,403.935181 
	C799.943970,404.208160 800.917664,403.342285 802.203979,402.339020 
	C803.443481,401.925598 804.360962,401.932434 805.542603,402.535980 
	C805.622253,403.335266 805.421631,403.912506 805.113525,404.778503 
	C804.464722,406.579102 807.006470,410.548767 801.743164,408.555450 
	C801.322021,408.478851 801.075623,408.634460 801.078979,408.780121 
	C800.580139,408.536499 799.927979,408.507874 799.212891,407.961670 
	C797.690063,407.195312 796.359497,406.615509 794.979004,406.202545 
	C793.997437,405.908936 792.987244,405.678619 793.000061,404.453400 
	C793.014526,403.067719 793.354370,401.802917 795.617676,402.038696 
	C796.321777,402.322144 796.640137,402.656586 797.069946,403.337219 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M809.814392,706.086792 
	C811.147095,705.542969 812.192688,705.229492 813.562439,704.829407 
	C811.095581,709.780029 817.592712,707.080566 817.916260,709.895813 
	C817.325562,710.880249 816.548340,711.090820 815.384216,711.040527 
	C812.887878,711.924927 810.697998,712.854370 808.156372,713.938721 
	C806.581970,713.593750 803.982605,713.213196 806.440430,711.537659 
	C808.538940,710.107056 808.710388,708.163391 809.814392,706.086792 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M741.753052,286.188141 
	C742.095032,286.011566 742.426819,286.053650 742.593750,286.044006 
	C743.579285,287.382751 744.804749,287.424805 746.474915,286.933411 
	C745.681458,289.839081 747.203308,292.522125 747.923462,295.676636 
	C746.547791,296.542175 747.291382,298.547882 745.430298,298.836731 
	C741.976562,295.249725 740.884521,291.048035 741.753052,286.188141 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M809.661865,686.714478 
	C809.824829,687.953247 810.868042,688.928467 809.865173,690.025879 
	C806.904175,693.266052 810.692932,691.468994 811.721375,692.001953 
	C812.044983,692.442383 812.025208,692.879883 811.989929,693.645020 
	C809.386963,693.984375 806.799438,693.996155 803.785400,693.996216 
	C802.487488,694.493958 801.601379,694.603027 800.398987,693.883179 
	C799.972168,693.487366 799.838135,693.292175 799.660400,692.744202 
	C800.054077,690.592346 801.282166,689.629272 803.327637,689.149658 
	C805.023621,689.406494 805.250549,688.428528 805.685669,687.170776 
	C806.955444,685.989746 808.115906,685.907349 809.661865,686.714478 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M823.911499,641.198853 
	C824.224976,641.097229 824.601501,641.047058 824.789429,641.019470 
	C826.975159,644.196716 826.143372,648.466003 822.553650,649.432434 
	C820.377625,650.018372 820.685364,649.317749 821.271912,651.592773 
	C822.390259,655.930542 818.819092,652.068298 817.388428,653.020935 
	C816.903015,652.908752 816.781494,652.793396 816.477722,652.504883 
	C816.197754,651.235901 816.651794,650.282837 817.221680,649.038696 
	C817.597473,648.392578 817.842529,648.040955 818.299683,647.441101 
	C820.347473,645.352356 821.080811,642.552490 823.911499,641.198853 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M825.666687,798.000000 
	C827.915039,798.830688 829.606384,800.328369 832.030823,799.215820 
	C833.240417,798.660767 834.696716,798.968506 835.466309,800.386047 
	C835.816345,801.030884 835.610291,801.657532 835.084290,802.169312 
	C834.083435,803.143188 832.938721,803.056519 831.893372,802.394470 
	C830.143982,801.286804 828.426758,801.613342 826.669312,802.252258 
	C825.272339,802.760193 823.953125,805.116333 822.440613,803.127991 
	C821.201294,801.498962 823.738220,800.328186 823.956421,798.357300 
	C824.444458,798.000000 824.888916,798.000000 825.666687,798.000000 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M840.725830,573.868896 
	C837.532410,573.733337 835.780273,577.447937 832.271118,576.112305 
	C833.070923,574.402100 834.172058,572.845154 835.561279,571.146851 
	C836.386108,570.621948 836.501892,570.023560 836.803406,569.181091 
	C836.958923,568.921570 836.991394,568.993652 836.958679,568.971436 
	C837.345032,568.601196 837.825134,568.458252 838.694946,568.729126 
	C839.908813,569.368774 840.794067,569.799988 841.925781,570.393066 
	C842.151123,571.535461 842.130005,572.516113 842.353271,573.696350 
	C842.066467,573.878906 841.560669,573.376770 840.725830,573.868896 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M702.643921,223.950989 
	C699.942932,223.454208 699.653503,221.175110 698.496521,219.173309 
	C697.150696,215.234940 696.050293,211.515793 694.949890,207.796646 
	C699.132446,211.959045 700.909180,217.471725 703.887207,222.643829 
	C703.579895,223.295837 703.227051,223.602753 702.643921,223.950989 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M616.935669,198.656677 
	C613.404846,196.330261 612.549255,192.511734 612.718689,188.184952 
	C613.015442,187.985138 613.070068,187.885483 613.124268,187.902710 
	C613.286987,188.118164 613.395630,188.316406 613.755676,188.742096 
	C616.626160,189.344772 618.804871,190.271362 619.396118,193.614410 
	C618.607239,195.424530 617.776123,196.859100 616.935669,198.656677 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M450.685425,169.858078 
	C450.935516,170.417740 450.856323,170.828506 450.669739,171.537323 
	C450.768433,174.160492 449.868988,176.394669 447.799347,176.916962 
	C445.705963,177.445221 444.742157,175.055817 443.632935,173.118759 
	C443.113861,172.373856 442.662079,171.976501 442.054108,171.315796 
	C444.183868,167.245590 447.118469,167.523117 450.685425,169.858078 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M833.299561,350.112366 
	C833.762268,355.993134 833.861450,362.009033 834.032227,368.409027 
	C834.022339,369.359222 833.940857,369.925323 833.750122,370.756531 
	C833.224304,370.767639 832.457458,370.532867 832.442383,370.256958 
	C832.076599,363.576691 831.071899,356.872437 833.299561,350.112366 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M249.531403,187.683044 
	C249.033966,188.737778 248.743851,189.548904 248.213608,190.147217 
	C246.364609,192.233704 244.024765,193.618408 241.246368,192.739914 
	C239.447357,192.171082 240.682587,190.778915 241.409485,190.056427 
	C243.667313,187.812332 245.616013,184.357742 249.531403,187.683044 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M610.784058,185.053268 
	C610.689575,183.269638 609.794434,181.974640 608.880249,180.414017 
	C607.510376,178.582977 607.465271,176.937683 609.388062,176.070847 
	C611.848755,174.961533 611.795166,177.730637 611.854980,178.584717 
	C612.038513,181.207428 617.758545,182.377335 613.436829,186.068665 
	C612.885010,186.255890 612.680420,186.319794 612.244995,186.159912 
	C612.014099,185.936096 611.995850,185.993805 612.025757,185.989258 
	C611.741699,185.700806 611.415344,185.431900 610.784058,185.053268 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M429.101807,161.606003 
	C427.738770,161.173370 426.604095,160.693481 425.035461,160.095856 
	C422.847961,158.870743 422.015045,157.538330 423.314209,155.509171 
	C424.685150,153.367950 427.010773,154.403137 428.732513,154.199905 
	C430.350403,154.008926 429.298523,156.116379 428.967133,156.933304 
	C427.433533,160.713730 430.218689,158.351944 431.536499,158.474411 
	C432.048584,158.646622 432.198059,158.911911 432.180725,159.602386 
	C431.119354,160.537964 430.224731,161.048355 429.101807,161.606003 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M857.391724,744.183716 
	C860.453552,745.349243 860.166748,747.673706 859.891357,750.394043 
	C857.599487,750.858032 854.744263,752.133972 853.800110,748.693115 
	C853.092346,746.113892 854.679871,744.776733 857.391724,744.183716 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M828.864136,368.624512 
	C827.045166,369.004120 827.253052,367.392334 826.385376,366.263855 
	C826.908081,360.719421 826.999023,355.329132 826.745972,349.462311 
	C826.933289,348.892334 827.117004,348.785828 827.558289,348.866882 
	C828.413025,355.412231 829.382629,361.747314 828.864136,368.624512 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M645.945923,189.379303 
	C647.519958,187.851593 648.611389,185.239029 651.176636,187.077255 
	C652.354553,187.921371 653.823669,189.471542 652.646851,190.661774 
	C651.191040,192.134232 649.762085,194.995621 646.390442,192.972946 
	C646.016418,191.840561 645.977112,190.804382 645.945923,189.379303 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M822.932495,669.095825 
	C821.916931,671.292603 819.497681,672.060059 817.429565,673.796631 
	C814.825012,671.389954 815.075012,668.194336 815.369873,664.627747 
	C816.891296,662.841614 818.136047,663.449707 819.683838,664.716064 
	C820.006042,665.005981 820.000000,665.000000 819.996948,664.997131 
	C820.258728,666.713989 820.684448,668.330078 822.932495,669.095825 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M797.620361,718.040161 
	C798.173767,718.206360 798.379395,718.356140 798.966553,718.502747 
	C799.422791,718.335205 799.497498,718.170898 799.483276,718.262329 
	C799.542847,718.643677 799.691406,718.769226 800.123779,718.962524 
	C799.233948,721.748169 799.501587,726.862183 794.037842,722.420654 
	C793.987244,719.887695 793.920654,717.518921 797.620361,718.040161 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M836.764709,602.260254 
	C836.971924,598.174011 838.673035,594.287659 840.722229,590.210449 
	C841.969849,590.528381 842.408142,591.465088 842.921021,592.700684 
	C841.696655,595.772644 840.398132,598.545288 839.062012,601.677734 
	C838.467224,602.070740 837.910095,602.104065 836.764709,602.260254 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M828.522095,271.999176 
	C827.712463,271.238068 827.311646,270.477173 826.868958,269.390533 
	C827.849976,268.944916 828.625000,268.485840 829.223816,267.603394 
	C830.143250,266.248413 831.440552,265.657867 833.012268,266.372925 
	C834.083801,266.860413 833.966614,267.963593 833.946594,268.928802 
	C833.911438,270.622833 833.078186,271.692322 831.365356,271.920319 
	C830.564575,272.026917 829.743103,271.977905 828.522095,271.999176 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M601.975586,158.826447 
	C602.368713,155.711624 604.341431,155.613754 606.392883,155.842880 
	C607.798279,155.999863 608.159912,157.095169 608.184998,158.349808 
	C608.212952,159.751038 607.894043,161.159409 606.496155,161.595306 
	C604.378296,162.255707 602.674194,161.573227 601.975586,158.826447 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M733.265503,245.812469 
	C731.285645,243.529724 731.476135,241.669586 733.964417,240.517487 
	C735.921326,239.611420 737.294373,240.899048 737.562073,242.602676 
	C738.013123,245.472595 736.074036,246.172546 733.265503,245.812469 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M760.276001,765.199707 
	C758.862122,765.035339 757.993958,764.452087 757.150146,763.182373 
	C760.232605,761.769470 763.511536,760.707947 767.155396,759.555115 
	C767.687744,759.523132 767.855103,759.582458 768.091614,759.899048 
	C766.164856,762.621155 763.328491,763.808350 760.276001,765.199707 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M863.951782,454.623657 
	C863.647461,455.034607 863.397461,455.023743 862.773926,454.969177 
	C861.535583,449.293549 860.670776,443.661652 859.805969,438.029724 
	C862.437561,443.159912 863.292786,448.664276 863.951782,454.623657 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M673.041870,191.670990 
	C672.227661,194.403671 671.017029,194.570251 670.319397,191.975952 
	C669.435303,188.688141 667.262939,186.363495 665.140259,183.584702 
	C666.942871,183.633377 668.802551,184.001755 670.841431,184.685013 
	C671.224915,186.204758 671.779785,187.350281 671.703125,188.913605 
	C672.152832,189.935394 672.531860,190.658249 673.041870,191.670990 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M747.611084,268.053925 
	C748.824768,269.504425 749.016174,271.036255 748.911865,273.023865 
	C748.597656,273.314117 748.231567,273.280762 747.993164,273.088867 
	C746.033264,271.511749 741.839111,273.075348 741.610657,270.740295 
	C741.349487,268.070984 745.883301,270.290741 747.611084,268.053925 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M813.528931,780.996338 
	C812.629089,780.778564 812.056152,780.489319 811.190796,780.066040 
	C814.235779,777.896179 817.573181,775.860474 821.475220,773.930420 
	C819.887695,777.016418 816.617126,778.668152 813.528931,780.996338 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M819.074463,367.641602 
	C819.367859,374.495819 818.771790,380.852356 817.367920,387.147400 
	C816.465637,379.758453 818.771423,372.522766 818.685791,365.117157 
	C818.816650,365.818329 818.947510,366.519501 819.074463,367.641602 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M803.482300,393.940552 
	C802.794312,392.879272 802.478210,391.827362 802.094238,390.447327 
	C803.503052,389.588287 804.587952,387.425842 806.511658,388.843323 
	C807.403992,389.500854 808.119934,390.642578 807.770935,391.792694 
	C807.208557,393.645905 805.550720,393.930115 803.482300,393.940552 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M373.027222,119.006714 
	C374.435913,116.682434 376.011719,116.846657 377.756683,117.966156 
	C378.577850,118.492958 378.822571,119.188011 378.297455,119.996895 
	C377.331238,121.485229 375.931824,122.117065 374.142578,121.973053 
	C372.154510,121.813042 372.113831,120.781898 373.027222,119.006714 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M806.013672,402.444092 
	C804.785583,402.705566 803.748291,402.653107 802.328979,402.503143 
	C801.742493,402.416016 801.538147,402.426392 801.115112,402.221436 
	C801.539185,400.735748 802.932739,399.818451 802.863525,397.805023 
	C805.811401,397.834991 808.395386,398.348816 806.013672,402.444092 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M733.986206,822.777344 
	C735.964294,821.640442 738.123413,820.840271 740.641602,820.056641 
	C743.371826,821.593689 734.722900,822.160156 740.646301,824.046875 
	C740.732178,824.074158 740.722107,825.029236 740.481018,825.330811 
	C739.911194,826.043579 738.971191,826.155762 738.245911,825.756714 
	C736.830322,824.977661 735.520630,824.005981 733.986206,822.777344 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M744.985107,818.894958 
	C747.275879,817.626282 749.709351,816.697388 752.494385,815.652466 
	C751.503296,820.291199 748.962830,821.508484 744.985107,818.894958 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M775.677429,823.044922 
	C777.505859,823.275696 779.556091,823.920776 778.305542,825.602112 
	C776.978210,827.386597 774.538452,828.399658 772.059326,826.331543 
	C773.119080,825.030762 774.238220,824.061584 775.677429,823.044922 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M843.500977,633.362671 
	C842.228943,630.909485 843.100403,629.469360 845.245789,629.089905 
	C846.841431,628.807678 847.804565,630.329651 847.678650,631.580933 
	C847.455139,633.801392 845.975586,634.633423 843.500977,633.362671 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M659.226624,160.110107 
	C661.571411,160.736481 662.717407,161.823578 661.282593,163.973373 
	C660.550659,165.069931 659.296997,165.593430 658.411682,164.639084 
	C657.079834,163.203400 656.768494,161.470032 659.226624,160.110107 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M786.251831,818.003662 
	C782.083557,818.152893 783.200867,815.841675 783.956970,813.363892 
	C785.175171,813.156311 786.350403,813.312622 787.765259,813.733276 
	C789.128113,815.327087 788.170288,816.354309 787.253540,817.712158 
	C787.000000,818.000000 786.501221,818.002441 786.251831,818.003662 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M763.723022,237.994385 
	C764.438904,238.153702 764.801208,238.230957 765.012268,238.452393 
	C766.515869,240.029434 766.416260,241.703217 765.114929,243.315414 
	C764.305542,244.318222 763.178223,244.161743 762.670166,243.169662 
	C761.764404,241.400864 760.214355,239.344986 763.723022,237.994385 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M807.650757,322.386871 
	C807.873413,323.997162 809.767029,325.436035 808.075195,326.529968 
	C807.356384,326.994720 805.811157,326.259949 805.139465,325.263763 
	C804.544373,324.381256 803.463745,323.465576 804.248291,322.211273 
	C805.194397,320.698547 806.325195,320.972626 807.650757,322.386871 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M571.217468,141.323013 
	C569.141174,139.620438 569.596008,138.166534 571.335388,137.217514 
	C572.792114,136.422714 574.066223,137.405792 574.365723,138.796371 
	C574.820068,140.906372 573.536011,141.711517 571.217468,141.323013 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M505.845917,113.185280 
	C503.394012,114.667366 502.182098,113.615669 501.982788,111.413765 
	C501.843933,109.879959 503.039490,108.906883 504.506622,109.119537 
	C506.863892,109.461189 506.596771,111.164391 505.845917,113.185280 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M738.909302,740.336243 
	C743.255859,740.394165 747.205872,739.501282 751.608276,739.002197 
	C752.025269,739.282166 752.019775,739.488342 752.009399,740.003845 
	C749.062439,741.481323 745.976868,740.940063 742.511780,741.092407 
	C741.739136,741.324158 741.402039,741.536255 740.767822,741.707275 
	C740.471619,741.667664 740.228821,741.519287 740.094238,741.565430 
	C738.711853,742.366516 738.768860,741.362366 738.909302,740.336243 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M827.864258,348.686157 
	C827.638062,349.051331 827.460327,349.048157 827.016052,349.021912 
	C825.807434,346.002106 824.819153,343.013824 825.106201,339.378845 
	C825.264221,338.800201 825.384216,338.642059 825.683350,338.721771 
	C826.566589,340.326630 827.318359,341.678467 827.278381,343.616272 
	C827.622559,344.428711 827.856262,344.942352 827.747742,345.821869 
	C827.760315,346.839966 827.836487,347.578857 827.864258,348.686157 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M301.609009,218.996613 
	C300.652771,220.148636 299.428711,221.555527 297.897247,222.414093 
	C296.235657,223.345612 294.275146,223.796127 294.014038,220.418015 
	C296.409241,219.696167 298.807495,219.353073 301.609009,218.996613 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M861.399902,504.930481 
	C861.050171,502.895905 861.058655,500.969208 861.016235,498.633362 
	C864.982727,499.346100 865.163391,500.899078 861.399902,504.930481 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M523.249329,174.299835 
	C522.997864,175.728683 523.088806,177.285919 521.340332,175.864029 
	C520.202942,174.939148 518.343323,173.784576 520.457886,172.045700 
	C521.159058,171.469101 522.203613,169.983246 522.980530,171.933884 
	C523.219177,172.533112 524.252319,173.129547 523.249329,174.299835 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M735.421143,791.046509 
	C735.107544,790.203857 735.166443,789.369385 735.356934,788.137451 
	C736.098267,787.605835 736.724182,787.521057 737.314453,787.327271 
	C738.542236,786.924316 740.034180,786.383179 740.463440,788.043213 
	C740.878052,789.646362 739.422485,790.077515 738.074890,790.328186 
	C737.296387,790.473022 736.552856,790.806213 735.421143,791.046509 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M249.003342,225.563232 
	C247.045273,225.862778 244.893158,226.614441 243.185364,224.320435 
	C244.872910,225.047195 248.321564,218.133881 249.003342,225.563232 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M603.802612,138.064728 
	C605.487122,139.662720 605.858643,141.099121 603.901489,142.266129 
	C602.655273,143.009216 601.532715,142.461807 601.145203,141.224792 
	C600.555420,139.341446 601.132507,137.958038 603.802612,138.064728 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M682.040405,844.371704 
	C682.475220,843.752380 682.954102,843.485657 683.688110,843.118774 
	C684.903015,843.756897 685.862732,844.495178 686.948364,845.514526 
	C685.057617,848.722412 684.649048,848.634705 682.040405,844.371704 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M503.696716,157.813110 
	C505.799805,156.628433 507.231781,156.774017 507.672760,158.992294 
	C507.962982,160.452240 506.444122,160.493912 505.728485,160.406509 
	C504.489288,160.255173 501.992462,160.845749 503.696716,157.813110 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M527.404175,187.083160 
	C526.387329,186.776382 525.722473,186.309677 524.754517,185.800034 
	C524.033325,184.580795 523.799805,183.249908 525.327576,182.932297 
	C526.362427,182.717163 527.967468,182.854553 528.575623,183.516190 
	C529.588928,184.618591 528.903137,185.979034 527.404175,187.083160 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M840.891663,563.335693 
	C841.426392,561.494934 842.310730,559.867249 843.466675,558.073608 
	C845.408813,560.895935 845.474792,563.173950 840.891663,563.335693 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M403.560364,174.883331 
	C403.593994,178.297424 400.997162,179.770554 397.955444,181.184891 
	C398.316742,178.088974 401.546204,177.223724 403.560364,174.883331 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M323.630615,213.956970 
	C322.621918,216.371964 327.720184,216.022934 325.989594,217.522842 
	C323.617615,219.578613 321.886963,214.725922 319.097595,215.838730 
	C318.976288,214.672272 320.247711,215.073654 320.817139,214.238785 
	C321.753448,213.989944 322.504303,213.978363 323.630615,213.956970 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M501.003052,171.543518 
	C499.407898,172.195618 497.601135,172.113083 497.276642,170.530090 
	C496.905945,168.721771 499.065186,168.728119 500.596558,168.107849 
	C501.963135,169.127228 502.032074,170.167358 501.003052,171.543518 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M471.022034,171.195297 
	C471.036011,171.903641 470.932434,172.250610 470.693298,172.834991 
	C470.620178,174.497345 469.586121,175.054016 468.376678,175.907593 
	C468.203888,176.206528 468.069305,176.092300 468.119019,176.165222 
	C467.968201,176.314240 467.767639,176.390305 467.343445,176.235077 
	C466.988037,173.473679 468.668030,171.509155 469.542908,169.008057 
	C470.196167,169.488754 470.550354,170.161331 471.022034,171.195297 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M837.115479,773.596008 
	C835.201477,771.591858 836.908997,770.668335 838.098267,769.670715 
	C838.643494,769.213379 839.471252,769.367798 839.712158,770.068787 
	C840.416870,772.118774 839.169434,773.052856 837.115479,773.596008 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M260.304993,223.228745 
	C258.663574,221.823990 258.798187,220.480957 259.650574,219.138885 
	C260.001862,218.585800 260.718323,218.169724 261.283264,218.627426 
	C262.448456,219.571335 261.849304,220.994736 262.000092,222.607803 
	C261.614349,223.383820 261.151886,223.536011 260.304993,223.228745 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M778.714905,802.759705 
	C779.001465,802.834106 779.469116,802.848450 779.702759,802.838501 
	C778.702454,805.606262 776.376587,806.544800 773.302124,806.395020 
	C774.208191,804.632446 775.932861,804.183899 777.856995,803.434570 
	C778.188049,802.998474 778.308167,802.841858 778.714905,802.759705 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M832.663086,625.855103 
	C832.024475,625.820679 831.625427,625.584351 831.069275,625.029785 
	C831.722900,623.139709 832.533630,621.567871 833.623901,619.800537 
	C834.312683,619.752319 834.629028,620.015381 834.929016,620.688354 
	C834.295593,622.550659 833.599121,624.101868 832.663086,625.855103 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M396.913605,181.511581 
	C396.592499,182.738525 395.949158,183.768402 395.053467,184.861053 
	C394.015045,184.863663 392.852722,185.098892 392.523682,184.679855 
	C391.899170,183.884491 392.855591,183.129822 393.468719,182.732086 
	C394.417053,182.116913 395.541565,181.773270 396.913605,181.511581 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M844.253906,763.805542 
	C844.492249,761.744385 845.375244,760.742981 847.195740,761.472595 
	C847.547302,761.613464 847.875305,762.704224 847.691162,763.131348 
	C847.029907,764.664856 845.987488,765.565796 844.253906,763.805542 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M323.266998,202.065460 
	C322.074493,201.220428 321.209137,200.304352 321.473541,198.843933 
	C322.144897,199.151901 322.816223,199.459885 323.761688,199.863922 
	C325.614319,200.963257 327.404236,200.919830 329.551544,201.018448 
	C328.460419,202.667618 326.499756,201.759308 324.361877,202.054779 
	C323.957153,202.075745 323.496643,202.041382 323.266998,202.065460 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M843.296753,592.844360 
	C841.515808,592.937378 841.012329,592.041687 841.017822,590.347900 
	C841.004028,589.995972 841.000000,590.000000 840.997925,590.002075 
	C841.380371,589.589050 841.808533,589.238770 842.645874,589.061523 
	C846.029602,589.725769 845.059509,591.161926 843.296753,592.844360 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M817.927246,712.040588 
	C819.136658,713.848755 819.005493,715.496277 817.755798,717.125671 
	C816.295471,716.321045 815.450134,715.083069 815.124268,713.107605 
	C815.844971,712.145325 816.682617,711.928345 817.927246,712.040588 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M826.692688,731.208191 
	C828.381104,732.856750 827.910217,734.071777 826.363403,734.915344 
	C825.556030,735.355774 824.670227,734.886169 824.361572,734.069885 
	C823.670227,732.241943 824.670044,731.368896 826.692688,731.208191 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M526.052734,164.654739 
	C524.224060,164.244263 524.323547,162.639160 523.750854,161.430939 
	C524.358948,161.258759 525.125977,160.764420 525.546753,160.971970 
	C527.103210,161.739655 527.529602,162.954788 526.052734,164.654739 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M817.064453,396.153107 
	C816.893250,397.318481 816.701233,398.022888 816.257812,398.862610 
	C814.103638,395.796875 817.340088,392.991180 817.043640,389.885956 
	C817.043640,391.821381 817.043640,393.756775 817.064453,396.153107 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M498.452148,156.259521 
	C497.330139,156.860489 496.922516,158.495956 495.844910,157.750565 
	C494.839264,157.054901 495.675507,155.833023 496.237457,155.179810 
	C497.115265,154.159454 498.533203,153.334167 499.722046,154.288162 
	C500.573059,154.971115 499.274841,155.537201 498.452148,156.259521 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M853.103271,383.321106 
	C855.037048,383.760284 855.535889,384.861176 854.854187,386.300842 
	C854.462769,387.127502 853.440247,387.396484 852.762573,386.818451 
	C851.584106,385.813293 851.592896,384.570404 853.103271,383.321106 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M839.199341,568.614746 
	C838.546265,569.044983 838.068909,569.152405 837.258789,569.104492 
	C837.888794,567.344727 838.851624,565.740295 839.998474,563.893188 
	C841.560242,565.484192 840.545532,566.901611 839.199341,568.614746 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M818.288696,711.927063 
	C817.543762,712.714905 816.772888,713.124207 815.465454,712.828857 
	C815.138733,712.363159 815.145264,711.971436 815.114868,711.287659 
	C815.827515,710.726807 816.577148,710.458069 817.635559,710.217041 
	C818.071289,710.744019 818.198303,711.243225 818.288696,711.927063 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M882.694092,656.484497 
	C884.507141,657.804626 883.964844,658.913147 882.763489,659.729004 
	C881.671631,660.470398 880.931763,659.551270 880.568420,658.673035 
	C879.857727,656.955017 880.911987,656.408142 882.694092,656.484497 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M872.974365,477.666321 
	C871.467346,476.916870 870.092468,476.267365 871.630127,474.701569 
	C872.444641,473.872223 873.352417,473.886993 874.202148,474.720459 
	C875.768250,476.256439 874.372498,476.937408 872.974365,477.666321 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M263.690063,188.780609 
	C263.949127,189.634567 263.999054,190.297440 263.827087,190.896652 
	C263.429291,192.282944 262.331909,193.439789 261.054535,192.651993 
	C259.269135,191.550919 261.327515,190.609879 261.851898,189.296295 
	C262.386047,188.621826 262.846100,188.475174 263.690063,188.780609 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M630.912109,186.749634 
	C630.172363,188.542892 629.208313,189.608078 627.529236,188.712921 
	C627.126648,188.498322 626.738525,187.477448 626.915527,187.085861 
	C627.828796,185.065521 629.413391,185.797928 630.912109,186.749634 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M248.012238,200.690369 
	C246.503250,199.466263 246.495651,198.262711 247.733047,197.290298 
	C248.469345,196.711670 249.368896,197.139908 249.817780,197.860214 
	C250.832199,199.488037 249.624054,200.162125 248.012238,200.690369 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M819.955322,679.667236 
	C821.383423,678.807373 822.764404,678.617249 824.427124,678.388245 
	C824.933960,680.169434 823.534302,680.828552 821.950806,681.775146 
	C820.678955,681.499023 820.217896,680.988586 819.955322,679.667236 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M726.127258,826.074768 
	C727.373230,825.184631 728.834839,824.586548 730.646362,823.989136 
	C730.502991,826.852966 728.949158,827.640015 726.127258,826.074768 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M835.268066,620.838806 
	C834.700439,620.726318 834.408813,620.453247 834.010376,619.892578 
	C833.894226,618.263000 833.623291,616.817017 835.619507,616.109741 
	C835.396973,617.576477 837.827393,619.339783 835.268066,620.838806 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M758.462219,774.920593 
	C757.645874,776.261536 756.743103,776.415894 755.903748,775.527344 
	C755.069702,774.644409 755.817749,774.095703 756.549561,773.606384 
	C757.833618,772.748108 758.296326,773.482361 758.462219,774.920593 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M828.711731,300.696899 
	C828.962402,301.301941 828.912109,301.593567 828.733826,302.311401 
	C826.879333,301.506958 822.908264,301.084351 827.002930,297.539490 
	C827.584167,298.301483 827.997437,299.342499 828.711731,300.696899 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M857.632141,365.811462 
	C856.860352,364.709778 855.692932,363.604828 857.531311,362.944702 
	C858.428284,362.622589 859.746826,362.450531 860.060730,363.588562 
	C860.460510,365.038269 859.858032,366.185974 857.632141,365.811462 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M855.693604,491.039673 
	C855.533386,492.224426 855.034546,493.444305 854.243652,494.827759 
	C851.654297,493.394958 851.800232,492.987793 855.693604,491.039673 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M735.237793,216.340576 
	C736.883301,217.530121 738.122009,218.723618 739.567139,220.166565 
	C739.626587,220.770111 739.479614,221.124146 738.814819,221.727982 
	C737.203857,220.584625 736.110840,219.191513 734.922791,217.478271 
	C734.828857,216.886917 734.829956,216.615723 735.237793,216.340576 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M817.042969,703.282227 
	C818.135315,702.370728 819.269226,701.766724 820.725342,701.145874 
	C820.731934,703.925598 819.299561,704.815002 817.042969,703.282227 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M828.589233,672.033264 
	C830.282471,672.622864 828.348633,674.151794 829.912109,674.974731 
	C829.823181,675.809814 829.687622,677.448364 828.549927,676.682617 
	C826.872498,675.553650 826.914124,673.765259 828.589233,672.033264 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M671.951904,188.892395 
	C671.807983,187.732895 668.315063,187.384064 670.756714,185.108521 
	C673.672424,185.481964 672.964050,187.007599 671.951904,188.892395 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M815.736938,680.766968 
	C816.451599,679.057739 817.886047,678.056702 819.614136,676.939453 
	C819.952271,677.323425 819.974915,677.801758 820.000000,678.638855 
	C820.002441,678.997559 820.000000,679.000000 819.998901,679.001221 
	C819.387451,680.513000 818.560852,681.727722 816.332397,681.235291 
	C816.019531,681.018616 815.832153,680.849609 815.736938,680.766968 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M701.490967,197.498108 
	C700.747925,195.884460 701.078674,195.008118 702.618591,194.989853 
	C703.545898,194.978851 704.203369,195.631424 704.172241,196.452606 
	C704.113892,197.990967 703.030579,198.128189 701.490967,197.498108 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M848.040649,494.066437 
	C845.415649,491.702179 846.916565,490.691284 849.725586,490.001923 
	C849.918640,490.596527 849.780151,491.193939 849.401672,491.993103 
	C848.566223,492.488312 848.655823,493.130219 848.254883,493.828979 
	C848.070923,494.041687 848.042175,494.049683 848.040649,494.066437 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M468.683044,144.092560 
	C469.036407,145.852722 468.798157,147.557983 466.047394,147.868576 
	C466.574432,146.652466 467.480652,145.441299 468.683044,144.092560 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M577.379150,143.533539 
	C579.229248,142.688568 579.657898,143.799210 579.894653,144.955246 
	C580.076233,145.841949 579.343140,145.946594 578.666931,145.984314 
	C577.030945,146.075546 576.662292,145.262009 577.379150,143.533539 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M526.886719,155.461243 
	C529.065979,155.032913 529.980957,156.233749 529.942871,158.729156 
	C528.934448,157.998627 527.946228,156.889038 526.886719,155.461243 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M866.000000,729.250000 
	C866.444458,729.000000 866.888916,729.000000 867.666687,729.000000 
	C868.203857,729.847900 869.278320,730.739746 868.241516,731.543396 
	C867.255371,732.307922 866.503967,731.695984 866.051086,730.321045 
	C866.000000,730.000000 866.000000,729.500000 866.000000,729.250000 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M722.919434,798.508972 
	C724.092896,798.013123 725.068298,797.620850 725.783875,798.585266 
	C726.227600,799.183289 726.085938,799.997986 725.357239,800.017334 
	C724.366577,800.043701 722.739136,800.947021 722.919434,798.508972 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M825.917847,338.540039 
	C825.862488,338.959625 825.503296,338.975250 825.323730,338.966797 
	C825.093201,338.473969 824.862549,337.917786 825.022461,337.517700 
	C825.707031,335.804565 823.096619,335.562439 823.575012,334.069885 
	C823.627014,333.907562 824.541809,333.828094 824.928650,333.999146 
	C826.976685,334.904785 825.490540,336.771729 825.917847,338.540039 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M787.329346,817.948242 
	C787.310242,816.895630 787.620544,815.791321 787.967834,814.342285 
	C791.300720,815.600037 788.985596,816.704407 787.329346,817.948242 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M817.452881,356.260864 
	C817.080505,354.660492 817.018433,353.252960 816.976318,351.422546 
	C817.153992,350.884674 817.311890,350.769684 817.670044,350.868652 
	C818.173096,352.484375 818.476074,353.886047 818.925049,355.616699 
	C818.702759,356.289001 818.266785,356.458344 817.452881,356.260864 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M361.031799,197.901962 
	C360.644806,198.187256 360.195099,198.117294 359.406128,198.016693 
	C358.944244,196.926102 356.656616,196.207458 357.804413,195.433426 
	C359.492279,194.295166 360.074768,196.522568 361.031799,197.901962 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M750.966919,779.054932 
	C752.762268,779.210205 752.421143,780.145569 752.025452,781.055176 
	C751.837036,781.488464 751.539673,781.867920 750.977966,781.466980 
	C750.056274,780.808960 749.735352,780.070312 750.966919,779.054932 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M825.950684,666.027954 
	C826.056702,666.599670 825.903137,666.802551 825.480530,667.000366 
	C824.242615,666.882935 822.538208,666.874146 822.935059,665.535950 
	C823.232422,664.533325 824.833374,664.938171 825.950684,666.027954 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M849.242065,653.908813 
	C848.936279,653.400452 848.964050,652.865173 849.065735,651.920288 
	C849.558350,650.838440 850.326233,650.039795 850.805054,650.938049 
	C851.392395,652.040039 851.145630,653.383179 849.242065,653.908813 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M785.952515,799.707214 
	C786.638367,798.883301 787.477234,798.375244 788.604553,797.791504 
	C788.816956,799.515564 788.610718,801.158020 785.952515,799.707214 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M823.634521,720.064087 
	C822.742798,721.009399 822.026550,722.021240 820.450256,721.461060 
	C821.313660,720.261414 821.756348,718.754395 823.634521,720.064087 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M465.014038,133.983948 
	C466.642883,131.970276 466.798370,133.898605 467.388153,134.623886 
	C467.673798,134.975128 467.882751,135.619873 467.239990,135.800705 
	C466.034973,136.139725 465.531769,135.178818 465.014038,133.983948 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M478.270691,159.502472 
	C478.902496,158.513840 479.452179,157.472305 480.251617,158.141220 
	C481.507446,159.191971 480.323822,160.292648 479.596161,160.901962 
	C478.413239,161.892426 477.967133,161.112640 478.270691,159.502472 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M757.321899,752.155273 
	C758.391418,750.610962 758.846497,751.413208 759.031799,752.446777 
	C759.127686,752.981873 758.904907,754.063599 758.775757,754.071533 
	C757.792603,754.131531 757.685486,753.206970 757.321899,752.155273 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M323.232300,206.431473 
	C325.611633,206.179443 325.050018,207.835342 324.942688,209.584656 
	C323.783417,209.175888 323.303833,208.059494 323.232300,206.431473 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M643.079346,199.198456 
	C644.607788,199.167374 644.504822,199.914261 644.043579,200.664001 
	C643.865845,200.952881 643.189636,201.276016 643.005554,201.160690 
	C642.234802,200.677612 642.032776,199.993317 643.079346,199.198456 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M521.167114,124.345131 
	C521.473145,124.064590 521.981323,124.082954 522.856445,124.219193 
	C523.145569,125.208038 523.889526,126.586647 522.671265,126.791321 
	C521.384033,127.007599 521.678406,125.468018 521.167114,124.345131 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M855.555359,397.304138 
	C855.823303,398.749756 855.279419,399.306396 854.098511,398.983490 
	C853.406128,398.794189 852.992981,398.103851 853.262939,397.568268 
	C853.631470,396.837036 854.450623,396.648590 855.555359,397.304138 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M799.151489,312.057129 
	C797.684692,311.153259 797.687561,310.397644 799.006775,309.964233 
	C799.802612,309.702698 800.725952,309.790771 800.981201,310.776855 
	C801.261841,311.860718 800.499146,312.141205 799.151489,312.057129 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M817.873291,350.731262 
	C817.870178,351.082672 817.434082,351.031403 817.215088,351.015533 
	C815.859985,350.088348 815.706360,348.945862 816.398315,347.025299 
	C817.010925,348.415771 817.443665,349.397827 817.873291,350.731262 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M817.045532,356.465576 
	C817.573975,356.071289 818.005493,356.074493 818.754028,356.011658 
	C820.197815,356.262512 820.810303,356.976501 819.612793,357.717224 
	C818.747742,358.252350 817.197205,359.089905 817.045532,356.465576 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M907.017212,576.953247 
	C905.694763,576.205505 905.553833,575.361328 906.440247,574.553894 
	C906.673096,574.341797 907.433899,574.306458 907.603943,574.498474 
	C908.335266,575.324036 908.300903,576.207825 907.017212,576.953247 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M638.626221,148.140930 
	C637.584900,148.096558 637.130066,147.545685 637.387939,146.638702 
	C637.432861,146.480927 637.957825,146.297852 638.164368,146.377380 
	C638.860657,146.645508 638.894104,147.276764 638.626221,148.140930 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M818.132507,361.593597 
	C819.144348,360.872498 820.079285,360.782593 820.857422,361.465668 
	C821.035217,361.621704 820.805237,362.242218 820.762939,362.648926 
	C819.911194,362.189392 818.615417,363.391602 818.132507,361.593597 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M848.942139,497.723938 
	C847.683228,497.767456 845.897766,498.651520 845.038025,496.270020 
	C845.488953,496.184143 845.982666,496.478180 846.761841,496.801941 
	C847.627991,497.038025 848.208740,497.244324 848.942139,497.723938 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M797.899353,385.531281 
	C796.636169,384.473358 796.745544,382.954926 797.885254,381.215515 
	C798.117737,382.396393 798.056152,383.763580 797.899353,385.531281 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M304.671021,217.963959 
	C304.115051,218.331894 304.763977,221.704071 302.204285,219.142120 
	C302.518616,218.089966 303.413116,218.005096 304.671021,217.963959 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M740.951843,221.749786 
	C740.717285,222.537674 740.436829,223.067871 740.032349,223.871750 
	C739.448059,223.588654 738.987793,223.031891 738.412231,222.226440 
	C738.526672,221.702240 738.756470,221.426727 739.379883,220.783615 
	C740.151001,220.774704 740.528503,221.133408 740.951843,221.749786 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M756.283997,234.689117 
	C757.078003,233.744659 757.904724,233.770752 758.561646,234.667725 
	C758.670593,234.816559 758.558777,235.317505 758.389282,235.447464 
	C757.585999,236.063385 756.994507,235.411789 756.283997,234.689117 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M852.279907,469.700684 
	C851.514771,469.784790 850.728333,469.749146 850.517029,469.382233 
	C850.191528,468.817291 850.691833,468.327667 851.634399,468.150604 
	C852.123962,468.574188 852.320129,469.003357 852.279907,469.700684 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M452.736267,166.042114 
	C452.867340,166.571579 452.707031,167.132080 452.293243,167.852020 
	C450.956390,167.110703 451.188538,166.484894 452.736267,166.042114 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M778.192932,190.462372 
	C779.081909,191.059052 779.581909,191.645828 780.153259,192.514587 
	C780.085083,192.928497 779.945557,193.060455 779.591675,193.384979 
	C778.883301,192.984253 778.389221,192.390945 777.828491,191.513580 
	C777.775879,190.970505 777.789917,190.711487 778.192932,190.462372 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M832.491455,331.036194 
	C832.842468,331.433868 832.921997,331.884003 832.930481,332.672180 
	C831.571228,332.727478 830.603821,332.337952 832.491455,331.036194 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M842.935059,588.795044 
	C842.473389,589.350952 842.021484,589.660156 841.282715,589.986694 
	C841.060730,589.111633 841.228821,588.290771 842.630859,588.079956 
	C842.948730,588.054688 842.944885,588.548401 842.935059,588.795044 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M263.957947,188.707397 
	C263.555542,189.000610 263.111115,189.000610 262.333313,189.000610 
	C262.333069,187.806747 262.568878,186.295227 263.957947,188.707397 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M783.859863,291.723206 
	C782.917542,291.809204 781.898376,291.684357 782.800537,290.150360 
	C783.312439,290.471436 783.601318,290.952515 783.859863,291.723206 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M812.554810,794.371582 
	C812.336243,793.597229 812.607788,793.317566 813.208435,793.436462 
	C813.333313,793.461182 813.501526,793.637939 813.520447,793.764038 
	C813.611633,794.370972 813.316589,794.625488 812.554810,794.371582 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M862.712158,734.033813 
	C862.826050,734.551270 862.652100,735.102539 862.239136,735.826904 
	C860.946777,735.093506 861.198120,734.473572 862.712158,734.033813 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M732.235718,797.834839 
	C732.726257,797.057434 733.250366,797.034058 733.649292,797.632568 
	C733.736938,797.764160 733.483032,798.343262 733.368225,798.348938 
	C733.002258,798.367126 732.627808,798.212219 732.235718,797.834839 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M532.670776,189.066742 
	C532.321106,189.823425 531.869934,189.875870 531.495422,189.444275 
	C531.352722,189.279800 531.349670,188.783234 531.491699,188.622726 
	C531.892212,188.170105 532.322083,188.329788 532.670776,189.066742 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M663.065063,177.621307 
	C661.929138,178.228210 661.117737,177.987442 660.997803,176.306183 
	C662.136169,175.634262 663.051636,175.717453 663.065063,177.621307 
z"
    />
    <path
      fill="#FAF9F9"
      opacity="1.000000"
      stroke="none"
      d="
M712.966858,860.786438 
	C712.425232,860.927673 711.851135,860.769775 711.120911,860.356079 
	C711.869202,858.968018 712.508423,859.184998 712.966858,860.786438 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M819.799805,696.743408 
	C819.536560,697.312805 819.089050,697.668701 818.337708,698.053589 
	C818.188293,697.185242 818.073730,696.013977 819.799805,696.743408 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M809.738647,301.363831 
	C810.587219,301.823242 810.785828,302.379211 810.190857,302.853851 
	C809.947998,303.047577 809.299805,303.120819 809.135254,302.953918 
	C808.595398,302.406342 809.033630,301.870300 809.738647,301.363831 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M432.355835,160.011993 
	C431.990143,159.753555 431.966339,159.479507 431.920837,158.793396 
	C432.597229,158.076233 433.470673,157.505005 433.920929,158.456360 
	C434.370026,159.405411 433.486298,159.772278 432.355835,160.011993 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M826.059998,291.272400 
	C827.169067,291.267792 828.315308,291.496979 826.786133,293.032745 
	C826.335022,292.621063 826.167969,292.087463 826.059998,291.272400 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M847.235840,550.815552 
	C845.972595,550.158508 846.130066,549.522827 847.627991,549.032959 
	C847.760925,549.538757 847.611877,550.083618 847.235840,550.815552 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M827.970703,345.926361 
	C827.518066,345.528137 827.352051,344.955261 827.249084,344.160675 
	C828.288208,344.198517 829.028625,344.580994 827.970703,345.926361 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M815.727539,343.663330 
	C815.884033,344.349243 815.835205,344.802948 815.786377,345.256683 
	C815.562073,345.211823 815.184448,345.227417 815.138916,345.111969 
	C814.893616,344.490479 814.895813,343.876282 815.727539,343.663330 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M848.309082,494.008759 
	C848.247131,493.493286 848.423401,492.944916 848.880676,492.295654 
	C849.255310,492.891449 849.928955,493.788269 848.309082,494.008759 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M637.148315,196.714325 
	C636.353394,196.358582 636.657532,196.021484 637.004089,195.717834 
	C637.100525,195.633316 637.338745,195.568649 637.414246,195.625229 
	C637.892944,195.983856 637.764038,196.348618 637.148315,196.714325 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M847.953552,474.687195 
	C847.062073,474.824371 846.172363,474.740875 845.071350,474.392944 
	C844.860107,474.128510 844.939392,473.692352 845.156738,473.649170 
	C845.994873,473.732025 846.615540,473.858093 847.551514,474.009216 
	C847.866699,474.034241 847.955261,474.466553 847.953552,474.687195 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M693.049866,203.217682 
	C693.453064,203.216156 693.716919,203.599228 693.980530,204.223633 
	C694.059021,204.703934 694.137634,204.942886 693.853149,205.079346 
	C693.296814,204.518631 693.103638,204.060455 693.049866,203.217682 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M776.829102,188.913834 
	C777.520325,189.241470 777.913940,189.612015 778.444702,190.227417 
	C778.445618,190.598969 778.309387,190.725647 777.967529,191.040924 
	C777.375977,190.823196 776.990112,190.416885 776.455811,189.762634 
	C776.382080,189.328720 776.456787,189.142715 776.829102,188.913834 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M571.965332,155.319641 
	C572.181702,155.512878 572.481934,155.979782 572.889587,156.724884 
	C572.681091,156.533127 572.365173,156.063202 571.965332,155.319641 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M644.953125,162.311737 
	C645.744263,162.118637 646.089111,162.630554 646.068726,163.716400 
	C645.265747,163.850220 645.007019,163.316269 644.953125,162.311737 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M645.945312,164.286163 
	C646.802734,163.947922 646.902466,164.525070 646.939636,165.472870 
	C646.616516,165.376709 646.278625,164.974487 645.945312,164.286163 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M575.116943,161.066956 
	C574.884216,161.127609 574.426208,161.061417 574.197510,161.025620 
	C574.036865,160.515945 574.104980,160.042053 574.196167,159.208313 
	C574.608215,159.226578 574.997192,159.604691 575.438049,160.264526 
	C575.489990,160.546249 575.349670,161.006302 575.116943,161.066956 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M683.566284,208.024429 
	C683.895142,208.521103 683.985535,208.937332 684.034119,209.681122 
	C683.667114,209.444656 683.342041,208.880615 682.988403,207.958710 
	C683.082642,207.715225 683.205322,207.829605 683.566284,208.024429 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M633.900757,139.029297 
	C633.482727,139.713791 633.123230,139.569077 633.115845,139.045441 
	C633.109558,138.602737 633.420654,138.270203 633.900757,139.029297 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M641.996948,153.749939 
	C641.432556,153.689041 640.724731,153.343246 641.571045,152.620270 
	C641.736328,152.884598 641.901672,153.148926 641.996948,153.749939 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M827.810791,296.557831 
	C827.989807,296.111877 827.540894,295.346436 828.426147,295.233582 
	C828.359375,295.526428 828.292664,295.819244 828.054810,296.359436 
	C827.883667,296.606812 827.841797,296.560669 827.810791,296.557831 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M774.134766,186.044525 
	C773.489319,185.740158 773.093628,185.364563 772.552368,184.747620 
	C772.464966,184.341812 772.523071,184.177353 772.836853,183.956985 
	C773.487915,184.266693 773.883362,184.632324 774.419556,185.241165 
	C774.501648,185.647339 774.443054,185.810318 774.134766,186.044525 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M733.856812,215.013016 
	C734.475952,215.257385 734.899353,215.574387 735.483643,216.114014 
	C735.513428,216.478531 735.382324,216.620438 735.039490,216.960236 
	C734.460632,216.771515 734.093567,216.384888 733.628418,215.740204 
	C733.530457,215.482132 733.661133,215.085648 733.856812,215.013016 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M813.290039,338.384644 
	C813.154968,337.805847 813.009521,337.247498 813.689758,337.499084 
	C813.744141,337.519165 813.549072,338.213867 813.290039,338.384644 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M811.777100,332.130493 
	C811.582947,332.338837 811.383118,332.356110 811.195923,332.315308 
	C811.163391,332.308228 811.184448,332.055634 811.180725,331.916443 
	C811.379028,331.917542 811.577332,331.918671 811.777100,332.130493 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M627.248535,197.007812 
	C627.409363,196.902557 627.572266,196.954742 627.735229,197.006927 
	C627.573730,197.059708 627.412170,197.112488 627.248535,197.007812 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M645.272461,178.400513 
	C645.147278,178.559128 644.932312,178.581985 644.717346,178.604843 
	C644.872437,178.491486 645.027527,178.378113 645.272461,178.400513 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M538.175781,195.265640 
	C537.540100,194.714951 537.327332,194.200668 537.135925,193.396103 
	C537.157288,193.105820 537.566833,193.091675 537.760254,193.159180 
	C537.947083,193.562912 538.067932,193.844406 538.454590,194.262177 
	C538.594727,194.736130 538.596680,195.019089 538.175781,195.265640 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M854.991455,678.984253 
	C855.341919,678.456848 855.683960,677.736694 856.390869,678.587402 
	C856.134216,678.758911 855.877502,678.930420 855.382629,679.132141 
	C855.144409,679.162292 855.041687,679.044189 854.991455,678.984253 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M812.686035,334.576080 
	C812.819336,335.103455 812.944458,335.603821 812.329102,335.374542 
	C812.277222,335.355255 812.450867,334.730835 812.686035,334.576080 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M691.021606,199.281662 
	C691.415405,199.353638 691.728577,199.836563 691.936768,200.735748 
	C691.534790,200.665543 691.237854,200.179077 691.021606,199.281662 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M814.325806,340.462097 
	C814.575378,340.658508 814.715271,341.385864 814.664734,341.399353 
	C813.962036,341.586914 814.178040,341.016846 814.325806,340.462097 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M692.948792,203.032318 
	C692.565063,202.633133 692.262634,202.168961 691.895996,201.428406 
	C691.831787,201.152008 691.975891,201.027771 692.050720,200.967682 
	C692.433105,201.366425 692.740723,201.825272 693.118774,202.558624 
	C693.189270,202.833099 693.030090,202.967316 692.948792,203.032318 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M776.861328,794.710388 
	C776.763306,795.342896 776.129150,795.847900 774.787415,795.893433 
	C775.064819,795.538513 775.760437,795.168213 776.861328,794.710388 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M259.997131,223.249664 
	C260.442108,223.000610 260.887207,222.999969 261.666321,222.999329 
	C262.000458,223.166107 262.000641,223.332886 262.000854,223.749832 
	C261.555389,224.000137 261.109833,224.000275 260.329407,223.997681 
	C259.994507,223.994919 259.997284,223.498077 259.997131,223.249664 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M852.568115,649.449463 
	C852.625366,649.530884 852.400452,649.557190 852.400452,649.557190 
	C852.400452,649.557190 852.510803,649.367981 852.568115,649.449463 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M843.066895,585.803650 
	C842.853760,585.861389 842.663147,585.664062 842.515381,585.438660 
	C842.495239,585.407959 842.689087,585.236938 842.995544,585.074219 
	C843.172485,585.191589 843.137695,585.365662 843.066895,585.803650 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M844.245483,581.024536 
	C844.203308,580.843750 844.401489,580.524902 844.869202,580.103210 
	C844.921143,580.295776 844.703491,580.591125 844.245483,581.024536 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M769.997375,797.676025 
	C769.816040,797.936584 769.534424,797.919128 768.838501,797.810852 
	C768.821472,797.184204 769.288208,796.966003 769.997375,797.676025 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M640.470947,199.450928 
	C640.522827,199.420319 640.419128,199.481522 640.470947,199.450928 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M773.761169,795.939453 
	C773.761536,796.101196 773.504700,796.260986 773.137085,796.274902 
	C773.185547,796.065186 773.344727,796.001343 773.761169,795.939453 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M690.924133,199.054260 
	C690.765259,198.911606 690.669739,198.711777 690.574219,198.511932 
	C690.688293,198.543716 690.802368,198.575485 691.009338,198.738998 
	C691.102234,198.870728 690.987488,198.997086 690.924133,199.054260 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M639.532043,197.554565 
	C639.475037,197.589279 639.589050,197.519852 639.532043,197.554565 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M529.934082,159.334564 
	C530.151611,159.292847 530.422913,159.522736 530.731445,160.024506 
	C530.508362,160.066315 530.248047,159.836243 529.934082,159.334564 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M642.976196,155.018982 
	C642.621155,154.966797 642.354736,154.792557 642.041626,154.300537 
	C641.926941,154.086639 642.009155,153.996567 642.056030,153.954102 
	C642.423401,154.021652 642.671997,154.221191 642.941040,154.734879 
	C643.033386,154.959503 642.996887,155.000763 642.976196,155.018982 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M772.583618,184.361084 
	C772.213074,184.150070 772.019409,183.793854 771.825684,183.437622 
	C772.183105,183.395004 772.540405,183.352402 772.995117,183.605423 
	C773.092468,183.901047 772.760437,184.215881 772.583618,184.361084 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M767.675598,759.641418 
	C767.900085,759.384338 768.279724,759.304932 768.659424,759.225464 
	C768.663452,759.470886 768.667542,759.716370 768.416199,760.059021 
	C768.160828,760.156250 767.830811,759.819031 767.675598,759.641418 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M776.518799,189.362152 
	C776.056213,189.339035 775.863525,189.113388 775.663513,188.623108 
	C775.629089,188.238342 775.660583,188.068207 775.947266,187.851105 
	C776.472961,187.992554 776.743469,188.180954 777.070312,188.620148 
	C776.994568,188.983841 776.862366,189.096725 776.518799,189.362152 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M821.687134,773.718811 
	C821.947266,773.474609 822.324768,773.336487 822.702209,773.198364 
	C822.674133,773.439148 822.646057,773.679993 822.328979,773.978394 
	C822.039917,774.036011 821.804504,773.824829 821.687134,773.718811 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M746.876831,286.868286 
	C747.124817,286.708649 747.348511,286.518250 747.572144,286.327820 
	C747.468872,286.554352 747.365601,286.780853 747.032104,286.892365 
	C746.802002,286.777374 746.852478,286.837463 746.876831,286.868286 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M775.748657,188.257172 
	C775.351318,188.184433 775.105042,187.960403 774.804443,187.484955 
	C774.750244,187.233521 774.829651,186.760223 775.066223,186.720123 
	C775.567383,186.884827 775.831970,187.089645 776.149536,187.549316 
	C776.202515,187.804153 775.899658,188.105896 775.748657,188.257172 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M774.893311,187.100052 
	C774.485535,187.032700 774.220825,186.831863 773.920532,186.373383 
	C773.884949,186.115753 774.213623,185.792145 774.386902,185.638260 
	C774.852173,185.653656 775.062866,185.894150 775.247559,186.442932 
	C775.302795,186.680008 775.036316,186.966568 774.893311,187.100052 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M612.106445,186.196472 
	C612.217957,185.875290 612.421814,185.814499 612.857666,185.972839 
	C613.089600,186.191986 613.250183,186.519638 613.204712,186.695419 
	C612.839172,186.733093 612.518982,186.594986 612.106445,186.196472 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M829.389404,768.539917 
	C829.332581,768.463928 829.548767,768.437134 829.548767,768.437134 
	C829.548767,768.437134 829.446228,768.615967 829.389404,768.539917 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M613.924927,188.751282 
	C613.700562,188.803955 613.394043,188.638351 613.132935,188.196335 
	C613.501587,188.014053 613.723022,188.218430 613.924927,188.751282 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M827.689087,769.680664 
	C827.448853,769.714905 827.358398,769.618530 827.267944,769.522156 
	C827.458252,769.531433 827.648560,769.540771 827.689087,769.680664 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M766.873047,176.912918 
	C767.392395,177.169098 767.659485,177.471237 767.926636,177.773376 
	C767.588318,177.843674 767.250000,177.913956 766.717834,177.723297 
	C766.556274,177.294525 766.588501,177.126694 766.873047,176.912918 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M766.676270,177.315521 
	C766.361328,177.078720 766.198669,176.695068 766.036011,176.311432 
	C766.324890,176.304169 766.613708,176.296921 767.013916,176.578308 
	C767.125244,176.866959 766.828552,177.168686 766.676270,177.315521 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M852.315796,471.064270 
	C851.924927,470.943207 851.869873,470.744598 851.930054,470.258942 
	C852.287598,469.944489 852.529968,469.917053 852.772339,469.889618 
	C852.732117,470.255310 852.691895,470.621033 852.315796,471.064270 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M414.938202,176.738068 
	C414.704498,176.783447 414.568817,176.603470 414.484558,176.401978 
	C414.465088,176.355453 414.621552,176.235382 414.850952,176.067505 
	C415.021545,176.158844 415.038208,176.331879 414.938202,176.738068 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M625.446960,195.442780 
	C625.516602,195.397812 625.377319,195.487747 625.446960,195.442780 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M661.132690,175.776825 
	C660.752747,175.966949 660.512878,175.807281 660.275269,175.347137 
	C660.550598,175.261581 660.882385,175.388367 661.132690,175.776825 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M769.201172,179.602905 
	C769.683960,179.891754 769.923462,180.223129 770.162964,180.554504 
	C769.803528,180.611801 769.444153,180.669098 768.972900,180.427979 
	C768.861084,180.129562 768.958008,179.645447 769.201172,179.602905 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M769.007935,179.988281 
	C768.598511,179.831009 768.335938,179.532471 768.073303,179.233932 
	C768.408447,179.168060 768.743652,179.102203 769.261597,179.298370 
	C769.444397,179.560394 769.154724,179.847015 769.007935,179.988281 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M680.922974,180.053101 
	C680.769653,179.918076 680.680298,179.725876 680.590942,179.533691 
	C680.699036,179.564072 680.807190,179.594452 681.008545,179.746704 
	C681.101746,179.868576 680.986877,179.995926 680.922974,180.053101 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M733.663635,215.349213 
	C733.392700,215.126495 733.254883,214.770874 733.117065,214.415253 
	C733.366760,214.416046 733.616455,214.416824 733.959351,214.678986 
	C734.052551,214.940399 733.796753,215.216324 733.663635,215.349213 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M693.227783,850.556335 
	C693.004944,850.637146 692.611206,850.424072 692.033691,849.913818 
	C692.252197,849.831970 692.654541,850.047241 693.227783,850.556335 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M820.454468,689.528809 
	C820.425354,689.478333 820.483521,689.579346 820.454468,689.528809 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M258.791992,224.144470 
	C258.892395,224.366028 258.694244,224.605331 258.199493,224.865875 
	C258.096893,224.644211 258.293732,224.402573 258.791992,224.144470 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M831.320374,672.935547 
	C831.074646,672.633423 831.194641,672.388916 831.728760,672.387451 
	C831.786255,672.670105 831.634033,672.791016 831.320374,672.935547 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M821.999390,681.999390 
	C821.998840,682.165649 821.998962,682.332520 821.999146,682.749695 
	C820.825500,683.269897 820.840088,682.744934 821.643799,681.966492 
	C822.002441,682.000793 822.000000,682.000000 821.999390,681.999390 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M525.966431,154.310547 
	C526.182739,154.255783 526.455017,154.470795 526.800415,154.941162 
	C526.589844,154.991135 526.306091,154.785736 525.966431,154.310547 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M693.929993,204.662140 
	C694.020752,204.835754 694.836853,205.047195 694.197571,205.539703 
	C694.179077,205.554001 693.988831,205.345352 693.684326,205.108734 
	C693.489990,204.976807 693.879639,204.859314 693.929993,204.662140 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M853.307434,430.074524 
	C853.020386,429.891968 853.033691,429.633728 853.103455,428.991089 
	C853.735901,428.933472 854.173523,429.304749 853.307434,430.074524 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M525.559937,126.576614 
	C525.426086,126.657974 525.353394,126.341072 525.353394,126.341072 
	C525.353394,126.341072 525.693726,126.495255 525.559937,126.576614 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M207.583832,182.613342 
	C209.150238,181.940079 210.167847,182.348923 210.502640,183.697128 
	C210.685745,184.434464 209.972626,184.854248 209.265350,184.882492 
	C207.906891,184.936676 206.970139,184.504074 207.583832,182.613342 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M184.627289,190.012085 
	C184.919922,191.530777 184.254196,192.590332 182.338562,192.965363 
	C181.964218,191.412506 182.157990,189.994934 184.627289,190.012085 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M841.610229,806.002686 
	C841.891968,807.066162 842.902893,807.213684 843.725708,807.892212 
	C841.708801,813.015259 837.194092,815.531128 832.678162,817.872253 
	C830.384705,819.061218 827.719360,819.419250 825.869385,821.564880 
	C823.013977,824.876770 820.507507,820.690796 817.379639,821.005432 
	C817.987427,816.408081 822.129517,812.798889 826.198181,813.049072 
	C831.229858,813.358521 835.901123,812.983948 838.321228,807.525818 
	C838.844055,806.346619 840.004822,806.017700 841.610229,806.002686 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M877.052734,283.095215 
	C876.419067,281.651031 875.806396,280.210876 877.687012,280.189453 
	C879.758057,280.165894 882.295288,280.236816 882.895752,282.920288 
	C883.688171,286.461212 884.119019,290.082642 884.720032,293.666962 
	C884.968933,295.151154 884.312500,295.858734 882.864197,295.937317 
	C881.384094,296.017670 880.182251,295.699341 880.060730,293.912811 
	C879.810059,290.228790 879.000305,286.699402 877.052734,283.095215 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M773.941528,843.711975 
	C771.053345,844.226074 768.408630,843.544983 765.267700,845.243652 
	C765.664551,842.099854 766.541138,839.554382 768.818726,839.551453 
	C770.903076,839.548706 774.213745,839.859436 773.941528,843.711975 
z"
    />
    <path
      fill="#F8F7F7"
      opacity="1.000000"
      stroke="none"
      d="
M815.633179,822.005249 
	C816.193604,825.041809 814.169556,825.721802 811.379150,825.994812 
	C811.344177,823.517090 812.753052,822.172241 815.633179,822.005249 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M858.026672,272.593262 
	C856.036133,273.134308 854.353149,273.098663 855.773499,270.128113 
	C857.627136,269.807373 858.493713,270.450714 858.026672,272.593262 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M289.890442,887.783081 
	C289.739075,890.135376 288.206512,890.057312 286.645111,889.907288 
	C285.882111,889.833984 285.306854,889.350830 285.489258,888.632996 
	C285.838104,887.260193 287.045685,887.060486 288.632202,887.005493 
	C289.316345,887.110596 289.574463,887.300964 289.890442,887.783081 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M277.833252,270.385498 
	C279.707764,269.641510 281.379089,269.172302 283.406433,268.586060 
	C285.661194,270.304474 284.955688,273.812958 288.224365,275.120850 
	C291.303589,275.729675 294.795746,275.201782 294.227386,279.910400 
	C293.851135,283.027527 290.815369,285.082092 287.115784,285.095856 
	C292.746887,287.459442 298.569458,291.443329 305.326599,294.095154 
	C305.946198,295.208160 306.180847,296.097565 306.483246,297.322083 
	C306.651184,298.331512 306.629761,299.008362 306.564911,300.022034 
	C305.919861,303.100250 306.320465,306.377808 302.467987,307.603821 
	C300.496063,307.044464 299.199615,305.878143 297.413696,305.007721 
	C296.826630,304.732910 296.483215,304.653625 295.891174,304.798279 
	C295.241364,305.297791 294.882172,305.725281 294.346436,306.415009 
	C293.589966,307.298126 293.024109,307.932983 292.746582,309.078430 
	C292.964050,310.366730 293.604614,311.226593 293.590271,312.593994 
	C293.423676,313.318146 293.248383,313.709106 292.864929,314.366699 
	C292.316650,315.209106 291.886383,315.723297 291.109924,316.374512 
	C286.318481,317.576294 282.871887,313.648560 278.200317,313.910339 
	C277.464386,313.835449 277.055450,313.757050 276.354279,313.608582 
	C275.855255,313.542847 275.652557,313.526123 275.155457,313.539154 
	C274.861023,313.568909 274.389984,313.734070 274.227539,313.842316 
	C274.065063,313.950562 273.829987,314.138733 273.829987,314.138733 
	C273.829987,314.138733 274.131775,314.290527 274.381653,314.269775 
	C276.111572,314.369751 276.796631,315.522095 277.869080,316.731842 
	C279.078278,317.380463 280.095062,317.227295 281.073761,317.350861 
	C283.793213,317.694031 284.406342,319.686066 282.512207,320.989166 
	C275.816315,325.595734 270.546387,331.891205 263.419434,336.352661 
	C260.338409,334.644897 261.479553,332.812927 262.869507,330.957947 
	C263.908020,329.571930 265.715027,328.613556 265.397797,325.762390 
	C262.839722,329.788483 258.121613,329.270721 255.216782,332.570770 
	C253.022018,335.064117 250.701447,333.734161 249.869888,330.321533 
	C249.551086,329.013306 248.541885,329.968506 247.582108,329.610657 
	C245.021042,327.118988 242.544830,325.736572 238.935074,327.600861 
	C238.368591,327.484436 238.150482,327.393005 237.690079,327.036926 
	C236.838287,325.649689 236.808838,324.456116 237.118622,322.889282 
	C237.339401,322.339203 237.456467,322.133575 237.831512,321.690125 
	C241.140305,320.535675 243.690643,318.807648 246.229858,316.425110 
	C246.710876,316.149689 246.910156,316.054016 247.416870,315.842529 
	C249.623734,315.149872 251.262009,314.287048 251.757980,311.768066 
	C252.456100,309.056091 253.408905,306.899811 256.120239,305.621338 
	C258.354279,305.699036 259.835785,304.827637 261.299042,303.759949 
	C262.880859,302.605774 264.597168,301.721527 266.979340,302.559601 
	C269.084106,305.620880 271.233154,306.471680 274.085846,304.025024 
	C274.800385,303.412170 276.153992,302.412781 276.650940,303.070496 
	C279.779053,307.210510 283.702301,303.598541 287.427338,304.290894 
	C289.010284,303.325378 288.567108,301.984802 288.605103,300.454712 
	C288.613129,298.645782 288.083466,296.919800 290.477173,296.305664 
	C291.035278,296.114380 291.162323,296.827332 291.298035,296.314392 
	C290.223755,295.808075 289.556000,295.148682 288.863586,294.062927 
	C288.556213,293.395081 288.418030,292.999725 288.223083,292.907349 
	C287.984070,293.345093 287.814209,293.475586 287.334290,293.707550 
	C286.346039,293.893982 285.701782,293.777100 284.818604,293.277740 
	C283.537933,291.578949 282.365601,290.298798 280.131134,289.898315 
	C277.225830,288.846893 275.528992,287.632843 278.768433,285.032288 
	C273.231232,285.729187 268.732422,284.425690 264.920074,280.196350 
	C268.335693,275.969238 272.630402,272.823669 277.833252,270.385498 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M163.010178,514.340210 
	C163.256180,513.195496 163.502853,512.393433 163.882996,511.300720 
	C165.774323,509.784698 168.160416,503.738190 169.284744,501.157104 
	C171.130112,496.920776 168.984161,490.881958 175.220123,488.426666 
	C176.860321,487.780853 175.246384,484.658203 176.253418,482.500610 
	C177.194214,480.814301 179.260666,480.886719 179.952332,478.912903 
	C180.606934,477.825409 181.262070,477.212463 182.429886,476.710876 
	C183.399033,476.507568 184.059586,476.555145 184.706375,476.442627 
	C184.244583,476.387085 183.783112,476.538208 182.990112,476.375000 
	C180.093033,473.994354 177.873474,471.258698 174.429016,470.064087 
	C172.562775,469.416840 172.148438,467.567596 173.458160,466.550873 
	C177.042877,463.768280 177.699875,458.573425 182.391754,456.446167 
	C182.990433,456.379150 183.235199,456.394592 183.825012,456.547607 
	C184.770340,457.160614 185.176437,457.779266 185.600525,458.822876 
	C185.981979,460.909729 185.528732,462.532257 184.795563,464.427856 
	C183.922791,465.521393 182.940674,465.619812 181.787750,465.754913 
	C181.610474,465.774628 181.561279,466.048737 181.738297,465.959991 
	C182.457962,465.832031 182.986343,465.715302 183.843445,465.536621 
	C184.400101,465.520874 184.624100,465.545837 185.128448,465.762848 
	C186.150284,466.871552 186.388748,467.971191 186.576721,469.428223 
	C186.158417,472.686676 188.964233,472.871918 190.528732,474.522614 
	C191.046387,476.149384 190.678223,477.380676 189.998444,478.815735 
	C189.679764,479.307587 189.177917,479.523895 189.186188,479.593506 
	C189.264420,480.251892 188.900284,479.576447 189.796906,479.494080 
	C190.396072,479.429077 190.735229,479.546265 191.253510,479.961670 
	C191.589447,480.597687 191.641373,481.003906 191.472168,481.708710 
	C191.133926,482.313965 190.876358,482.595551 190.331467,482.874542 
	C190.124817,482.954468 189.828049,483.053162 189.828049,483.053162 
	C189.828049,483.053162 189.945114,483.319366 190.105728,483.205414 
	C190.956055,482.857819 191.417542,482.361420 192.035828,481.560120 
	C192.871658,481.040253 193.511902,480.885681 194.513763,480.763245 
	C195.550461,480.723297 196.234146,480.809082 197.245514,481.012085 
	C198.613922,481.373688 199.581009,481.981567 201.019913,481.617554 
	C201.513733,481.358246 201.713104,481.249237 202.232162,480.999084 
	C203.287384,480.638916 203.977066,480.634766 204.726151,481.606628 
	C205.292938,485.276947 206.894104,488.484100 205.892670,492.336792 
	C205.130096,493.740540 204.098816,493.943542 202.635284,494.048889 
	C201.996231,493.997070 201.745010,493.952881 201.135986,493.785217 
	C199.893417,493.210693 199.132812,492.632446 198.758759,491.343109 
	C198.888336,490.418091 199.613632,489.971222 199.204193,489.280426 
	C199.172195,489.226471 198.812317,489.593140 198.728638,490.281311 
	C197.765762,493.560486 195.532501,495.768219 193.795074,498.623901 
	C191.186172,499.641541 188.821091,499.525421 186.134598,499.114777 
	C185.182953,499.217926 185.041840,498.046783 184.545807,498.621368 
	C186.847946,499.922821 186.738678,501.731812 186.133118,503.937988 
	C185.939301,504.569946 185.471832,504.726410 185.556396,505.154480 
	C186.006180,504.761353 186.518112,504.476196 187.410751,504.201691 
	C188.460007,504.052643 189.151001,504.156372 190.114868,504.661804 
	C191.393982,506.092102 191.788330,507.511658 191.849579,509.400665 
	C187.899246,513.252014 184.604614,517.594116 179.407364,519.545837 
	C180.668442,521.290222 182.701431,523.379822 181.565353,526.813110 
	C180.758789,527.895935 179.906006,528.264771 178.600372,528.414917 
	C176.680084,527.827637 175.629715,526.078491 173.357849,526.343933 
	C172.012909,526.388062 170.953705,526.238892 169.745285,526.998474 
	C165.309830,529.995056 167.389481,533.788818 167.856522,537.682007 
	C167.742203,538.248230 167.664078,538.468201 167.370636,538.966309 
	C165.165024,540.280457 163.596481,538.765930 161.518433,538.270142 
	C160.984985,538.094910 160.776703,538.015442 160.272827,537.788940 
	C158.929459,536.917908 157.833252,536.283142 156.363770,535.672852 
	C155.937439,535.371277 155.786285,535.225891 155.483246,534.807739 
	C155.259903,534.342041 155.188461,534.149170 155.013535,533.671631 
	C154.565048,532.332458 153.539978,531.928894 152.681732,530.927856 
	C153.228302,526.134399 156.852905,528.397400 159.216721,527.566711 
	C156.075546,525.180969 157.650360,522.726990 160.082596,521.451599 
	C163.222839,519.804993 163.584076,517.605347 163.010178,514.340210 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M189.102386,366.983948 
	C189.069778,364.253204 189.337891,362.358154 191.146454,360.101227 
	C192.712860,358.146484 193.159821,353.302948 190.697495,351.074921 
	C187.437637,348.125244 188.044937,346.426971 190.917053,344.175049 
	C191.542694,343.684540 191.937302,342.899353 192.715378,341.890686 
	C189.221130,340.558899 186.354736,337.986847 182.194107,339.842163 
	C180.987701,340.380127 178.666183,340.712585 178.182281,338.292480 
	C177.795059,336.355835 179.370667,335.638977 180.794769,335.237762 
	C182.797043,334.673676 184.881760,333.746460 185.690552,332.096741 
	C187.466690,328.473724 189.423828,326.872772 193.321060,330.319427 
	C191.472168,324.129883 192.446503,322.812805 198.942245,321.718506 
	C196.848099,316.940948 198.461273,314.219574 203.190216,315.710876 
	C206.763519,316.837799 206.143936,315.003235 205.935989,313.403778 
	C205.308960,308.580780 207.824493,309.920502 210.671738,311.003235 
	C211.763123,311.845367 211.813599,312.761444 211.193604,314.024902 
	C209.782242,315.291412 210.338501,316.241852 210.913208,317.330841 
	C211.711411,318.843384 213.492889,320.439514 211.802109,322.189362 
	C210.338242,323.704407 208.302444,323.869843 206.035950,322.749390 
	C204.956085,322.239807 204.732513,319.989868 203.315369,322.094269 
	C203.483078,324.789703 209.122543,323.572296 206.773224,327.600677 
	C207.297775,327.783966 207.321136,327.251312 207.519806,327.031677 
	C209.089539,325.296478 210.292297,325.362091 211.163574,327.978455 
	C211.162460,328.545227 211.125183,328.687164 211.125183,328.687164 
	C210.896866,329.099731 210.861618,329.155060 211.124741,328.625763 
	C211.133698,326.727905 211.597473,325.457397 213.840424,325.239471 
	C214.389771,325.338654 214.601166,325.414490 215.073425,325.701050 
	C215.705292,326.476685 215.851776,327.110687 215.942642,328.078094 
	C216.470779,330.076294 218.280304,330.006744 219.636688,331.120087 
	C220.063171,331.506866 220.214417,331.685394 220.495453,332.194397 
	C220.830139,333.794617 220.396103,334.877289 219.385635,336.105347 
	C217.797333,337.032593 216.444229,336.555359 214.776520,336.403076 
	C214.035217,336.348053 213.615936,336.328613 212.895416,336.337891 
	C212.398544,336.380035 212.202560,336.401764 211.722076,336.479340 
	C210.724777,336.833099 210.132217,337.274170 209.640198,338.229370 
	C209.514526,338.697296 209.492096,338.893707 209.458099,339.395660 
	C209.449844,339.911743 209.453171,340.122314 209.474289,340.655212 
	C211.247375,344.256836 211.013931,347.651489 209.992966,351.428711 
	C208.807159,354.358124 207.717865,356.952240 205.360138,359.164581 
	C204.759048,359.666992 204.405716,359.934845 203.792862,360.391907 
	C202.862259,361.095428 202.232758,361.644318 201.743469,362.737732 
	C200.199158,369.035431 195.689301,368.155975 191.003372,367.580811 
	C190.172989,367.492737 189.696625,367.431396 189.102386,366.983948 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M185.273895,459.245300 
	C184.719528,458.614655 184.399139,458.190948 184.056396,457.489807 
	C184.053513,456.449921 183.578568,456.120026 182.607208,455.844299 
	C180.642868,454.831390 178.765717,453.427765 179.674606,451.624603 
	C181.528046,447.947510 179.370209,446.671753 176.580475,445.020630 
	C175.954880,443.728455 175.953430,442.750977 176.695557,441.486389 
	C177.009872,440.862976 177.085358,440.542328 177.160828,440.221710 
	C180.348099,442.021790 182.168335,439.228180 184.670181,438.302399 
	C188.471832,436.895691 190.086334,434.189514 187.792862,430.256775 
	C192.235855,429.931366 195.928482,427.055328 200.592667,427.708618 
	C200.142059,425.558838 198.264008,425.603790 197.325165,424.528625 
	C196.209183,423.250458 192.846375,424.549591 193.065598,423.338440 
	C193.549561,420.664520 193.488861,417.978882 193.946930,415.341888 
	C193.978058,415.162689 194.846130,415.128906 195.687744,415.022461 
	C196.820236,415.644135 197.597153,416.262817 198.491974,417.174194 
	C200.672852,417.993683 200.200317,416.027618 201.175919,415.169739 
	C201.452423,415.011017 201.467987,414.990631 201.471863,414.978394 
	C202.964310,414.287170 204.381088,413.302948 206.416931,414.135498 
	C207.483627,415.756195 207.659622,417.310333 208.136597,419.183014 
	C208.269012,428.299683 208.423721,437.022400 206.585815,446.030579 
	C206.463089,446.962250 206.426163,447.496613 206.381378,448.428955 
	C206.332306,449.348663 206.270187,449.867279 206.101227,450.757080 
	C204.902786,454.269714 205.731415,457.463531 205.693741,460.984741 
	C204.245132,463.160919 203.364288,465.204010 202.867615,467.445404 
	C202.448700,469.335846 201.566238,470.796753 198.933380,470.322815 
	C196.434982,467.586761 196.313507,465.000977 198.772003,462.179382 
	C199.118317,461.665161 199.589340,461.331116 199.559830,461.251221 
	C199.330338,460.630280 199.881393,461.405487 199.078506,461.632874 
	C198.007324,463.089691 196.865540,463.925171 194.905411,463.738464 
	C194.201996,463.475342 193.875916,463.217987 193.432495,463.105957 
	C193.037399,463.455261 192.844757,463.558716 192.326111,463.735168 
	C190.747925,463.867126 189.822113,463.356720 189.130341,461.928040 
	C188.794449,460.821777 188.711975,459.996307 188.591187,458.889709 
	C188.572876,458.335907 188.553314,458.068451 188.518753,457.802277 
	C188.482193,457.520721 188.439697,457.603058 188.273163,458.309631 
	C187.913620,458.882568 187.622620,459.143433 187.008698,459.477356 
	C186.305771,459.647736 185.901535,459.596405 185.273895,459.245300 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M211.260696,328.778320 
	C210.154617,328.187439 210.153870,324.838013 207.976334,327.997070 
	C207.514297,328.667389 207.067154,329.374634 206.483475,329.928131 
	C205.619553,330.747437 204.465836,331.497925 203.519531,330.290985 
	C202.507767,329.000488 203.650757,328.262421 204.692398,327.658905 
	C205.372955,327.264587 206.042221,326.850830 206.830353,326.377441 
	C205.354782,324.660217 202.060776,325.967957 201.997375,322.514771 
	C202.872009,321.206360 203.986450,321.487579 205.373413,321.757294 
	C207.653915,322.882355 209.482559,322.132141 211.733047,321.363129 
	C210.467484,319.333008 209.295715,317.452209 208.122833,315.572144 
	C207.102844,313.937164 208.417740,314.111145 209.668579,313.991516 
	C210.625214,314.643158 211.445953,314.048218 212.344360,314.413452 
	C212.677063,314.545532 212.623672,314.855133 212.818466,314.479065 
	C212.856735,314.080902 212.938278,313.922028 213.203491,313.544739 
	C215.434479,312.170685 215.895294,309.311401 218.716278,308.750061 
	C220.148209,308.979401 221.252319,309.011353 222.527054,308.149048 
	C229.808838,305.367523 236.546829,302.146393 243.798340,299.242981 
	C248.040314,297.207489 252.592300,297.104919 256.743042,294.819702 
	C257.525055,295.209534 257.836945,295.625824 258.200012,296.485413 
	C259.160309,299.805359 260.103180,302.754425 257.250885,305.605957 
	C254.985123,307.244812 253.534943,308.971466 253.202179,311.806244 
	C251.617935,313.442413 249.590332,313.389313 247.710297,314.523132 
	C245.008408,315.626678 243.131577,317.917236 240.205383,317.499939 
	C240.202194,317.571838 239.897903,317.370392 240.102432,317.578613 
	C242.725128,320.248413 239.776947,320.589355 238.098663,321.688049 
	C237.764984,321.776184 238.003082,321.915497 237.892563,321.832855 
	C235.434036,325.448120 231.943985,327.775360 227.831329,329.809082 
	C224.576569,329.150085 223.205704,332.687256 220.208054,332.207275 
	C219.965866,331.990540 220.009003,331.994659 220.012573,332.016022 
	C218.897079,330.505341 217.225937,329.928253 215.254242,329.215820 
	C215.003998,328.255737 215.008011,327.511017 214.999542,326.373840 
	C212.949875,325.651764 212.097168,326.918488 211.260696,328.778320 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M206.344604,413.822083 
	C204.209335,413.868011 203.994919,417.455048 201.506012,415.403687 
	C201.452774,413.573425 202.351776,412.120758 201.062500,410.466431 
	C200.569672,409.529968 200.636734,408.910797 200.955521,407.988953 
	C201.660736,406.558655 201.822800,405.318176 202.018768,403.755066 
	C202.121582,403.226257 202.172302,403.015564 202.317871,402.492126 
	C203.222015,399.905060 204.036423,397.632294 204.672287,394.956665 
	C205.017456,393.327515 205.485352,392.075836 205.862488,390.455414 
	C206.460083,388.492493 205.832352,386.492126 207.724152,384.989929 
	C210.168472,383.078613 210.485306,380.336182 211.852936,377.799377 
	C212.768494,376.418121 213.604736,375.375336 214.218597,373.789307 
	C215.403564,371.510406 216.457397,369.560760 218.755951,368.161682 
	C220.804474,367.498566 222.599457,367.381348 224.277756,366.778351 
	C227.256348,365.708130 229.307343,366.611542 230.499329,369.979187 
	C230.782654,371.066803 230.755493,371.793304 230.410004,372.837402 
	C230.040054,373.559326 229.798538,373.950684 229.371414,374.621704 
	C228.845688,375.482483 228.438370,376.013275 227.709351,376.713379 
	C226.464371,377.676056 225.011139,378.139893 225.771484,380.218628 
	C226.113235,384.344910 223.737350,387.291412 222.511749,390.982849 
	C221.346100,394.330078 219.484558,396.820251 217.309677,399.071442 
	C213.072784,403.457062 210.558121,409.077332 206.344604,413.822083 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M177.216904,481.747986 
	C177.072449,483.775665 177.059570,485.602020 177.257538,487.405212 
	C177.411179,488.804443 177.621048,490.262115 175.729309,490.284729 
	C172.100784,490.328156 171.898239,492.639221 171.988647,495.346161 
	C172.049103,497.156982 172.049377,498.902679 170.677094,500.470947 
	C169.546921,501.762512 169.865097,503.406799 171.216690,504.425751 
	C172.759766,505.589050 173.516785,507.067047 172.734222,508.704285 
	C171.865402,510.521973 169.906372,510.912140 168.076004,510.450684 
	C166.789703,510.126373 165.825684,510.727356 164.367981,510.969543 
	C163.460861,510.201080 162.408966,509.380920 162.426910,508.584808 
	C162.595413,501.104279 156.033615,498.046692 152.055542,493.611511 
	C149.598694,490.872375 149.787796,488.530273 150.331345,486.091064 
	C150.981674,483.172577 153.501556,484.208374 155.690323,484.689789 
	C158.722031,485.356659 161.849991,484.073517 164.984222,486.417786 
	C166.533249,487.576416 170.710236,485.082397 170.484192,482.817963 
	C170.020523,478.173157 172.677734,479.042908 175.849899,478.798431 
	C176.700623,478.638306 176.903656,478.676697 177.308411,478.951080 
	C177.681183,479.567322 177.775177,479.966400 177.761688,480.686737 
	C177.627029,481.173340 177.533157,481.355042 177.216904,481.747986 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M162.630524,513.991638 
	C165.578842,516.866089 165.125427,519.528564 162.178268,521.209778 
	C160.725983,522.038208 158.823181,523.633850 158.747070,524.974487 
	C158.612991,527.335693 162.093094,526.326172 163.265488,528.317993 
	C160.117599,530.322815 156.087753,527.386536 153.076248,530.476196 
	C153.116623,530.982117 153.275986,531.113892 153.450989,531.557861 
	C153.403824,532.068726 153.341019,532.267578 153.146881,532.743896 
	C152.356995,535.683289 154.558365,534.561462 155.923508,534.998901 
	C157.543762,535.189087 159.149323,534.473877 159.925049,536.632568 
	C161.521469,538.587280 161.453751,540.546997 161.080734,542.948792 
	C160.011398,545.211304 159.349182,547.261597 157.338242,548.849487 
	C155.243210,548.939758 154.658295,550.697021 153.313492,551.306458 
	C152.288773,551.770874 151.320892,551.895691 150.559052,551.170288 
	C149.001251,549.687134 151.258194,550.050903 151.518600,549.407104 
	C152.161209,547.818481 152.195206,545.908875 150.174438,546.155823 
	C143.764481,546.939087 145.234665,543.526611 146.515228,539.987976 
	C146.672180,539.554321 146.607834,539.040527 146.458267,538.325317 
	C146.206070,537.317139 146.141663,536.547607 146.034927,535.396179 
	C146.653778,533.382263 147.940781,531.790466 144.899231,530.899048 
	C142.512665,530.199585 141.596115,528.220032 143.199005,526.028625 
	C145.077209,523.460693 146.356094,519.402161 150.870361,521.292847 
	C152.887604,522.137695 151.291153,523.843506 151.054077,525.540039 
	C150.361053,526.977966 148.930435,525.987427 148.125092,526.999451 
	C147.612198,527.153076 148.304916,527.076477 148.355865,527.010254 
	C149.158463,525.967712 150.300247,526.713013 151.611816,526.775391 
	C155.308701,526.497009 155.676071,524.318787 154.609467,521.709351 
	C154.134079,520.546204 154.029480,519.573853 154.337845,518.426636 
	C155.988907,512.284485 156.033112,512.252319 162.630524,513.991638 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M176.233246,444.984375 
	C182.539215,445.942810 182.772293,446.298828 180.696365,451.399689 
	C179.875107,453.417664 181.670044,453.891815 182.988358,454.986389 
	C183.528976,455.565369 183.569229,456.017731 183.188339,456.745605 
	C178.599960,459.113556 178.598007,464.890717 174.193069,467.195160 
	C172.640717,468.007233 174.507233,468.675995 175.604385,469.122406 
	C178.735306,470.396332 181.482956,472.217651 182.988983,476.173340 
	C182.540573,477.363342 181.935150,477.928894 181.144348,478.748871 
	C180.768723,479.161957 180.578476,479.320648 180.053909,479.643738 
	C178.960968,479.977173 178.243118,479.882812 177.287109,479.238647 
	C177.000610,479.001221 177.000000,479.000000 176.999695,478.999390 
	C176.868378,478.828064 176.737366,478.657349 176.805176,478.232361 
	C177.003998,477.978058 177.002594,477.495056 177.002823,477.253571 
	C175.757904,475.176147 174.071442,476.479218 172.291245,476.196899 
	C171.985489,474.753021 171.294449,473.861420 170.507935,472.969452 
	C169.978073,472.368469 169.109116,471.188507 169.274612,470.984833 
	C171.555267,468.178009 171.401535,464.461060 172.718460,461.638184 
	C173.819260,459.278595 175.562546,457.309906 174.654251,454.722809 
	C173.887207,452.538055 175.593765,452.009827 176.635620,451.129913 
	C175.189682,449.159546 172.809036,450.811096 171.555771,449.177704 
	C172.339951,447.042389 175.379517,448.956299 175.896210,446.211853 
	C175.955063,445.623627 175.965256,445.405304 176.233246,444.984375 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M219.100403,368.704285 
	C217.756546,370.623688 216.692291,372.319061 215.313248,374.159088 
	C213.841797,374.473206 212.681839,374.442963 211.170898,374.344910 
	C207.078140,373.875397 204.482391,370.376312 200.264938,370.237305 
	C197.360245,368.302155 193.954727,370.862335 191.220123,368.194916 
	C190.821045,367.747131 190.648590,367.573669 190.770721,367.181458 
	C193.042465,366.981384 194.657913,365.639160 197.103729,366.656097 
	C199.099243,367.485809 199.479050,364.006042 200.805786,362.204041 
	C201.543411,361.498474 202.015961,360.998474 202.753967,360.276978 
	C203.202179,359.771545 203.403366,359.505524 203.832016,359.127563 
	C207.298462,358.398163 208.982681,359.824097 209.376495,363.370209 
	C211.701874,367.625427 215.688400,365.945190 219.208008,366.874939 
	C219.599228,367.573700 219.558990,368.031677 219.100403,368.704285 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M207.441589,389.469299 
	C207.044785,391.136475 206.411911,392.553619 205.614380,394.324646 
	C205.318024,395.597778 204.819977,396.328461 204.015808,397.167664 
	C203.407318,397.151825 203.111465,397.255676 203.002869,397.655609 
	C202.943817,397.873108 203.113434,398.006317 203.376205,398.300659 
	C203.372009,398.796448 203.323181,398.997925 203.097641,399.465485 
	C201.073792,401.069458 200.728912,403.538086 198.657013,405.004211 
	C197.824081,401.981232 195.713058,399.465454 197.659821,395.593201 
	C198.584274,393.754395 202.379272,389.380585 197.873352,386.209869 
	C199.220535,384.319977 199.644318,382.462708 199.246704,380.074036 
	C199.521790,378.526093 200.264038,377.617279 201.269897,376.469727 
	C202.912857,374.954437 203.888901,372.587585 206.322021,373.579102 
	C208.676056,374.538361 206.639999,376.940826 207.604568,378.805023 
	C207.768188,380.562744 207.405258,381.905853 206.824310,383.554810 
	C205.038803,385.833130 205.599854,387.576996 207.441589,389.469299 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M264.372467,280.093384 
	C265.985535,280.024628 267.415314,279.901031 268.397644,281.002167 
	C271.661041,284.660278 275.436646,284.565735 279.495209,282.631653 
	C280.199188,282.296204 280.798462,282.404633 281.231903,283.026062 
	C282.064392,284.219666 280.727600,284.633575 280.449707,285.406311 
	C280.107910,286.356812 275.420197,286.518341 279.838135,288.743286 
	C278.961334,291.604065 276.481415,291.679382 274.244598,292.126678 
	C272.812134,292.413147 271.256805,292.014587 269.981781,293.127197 
	C267.693970,295.123566 266.419922,293.661163 266.409607,291.522980 
	C266.385101,286.449921 264.193390,288.454651 261.996979,289.918549 
	C260.755829,290.745758 259.528046,291.629578 257.583618,291.554504 
	C255.625626,290.288940 255.144073,288.903503 256.765045,286.984711 
	C257.559326,286.315613 258.103149,285.892395 258.916199,285.264954 
	C260.829376,283.456970 262.459015,281.835876 264.372467,280.093384 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M258.874023,285.077301 
	C258.947296,285.588074 258.694885,286.098053 258.249390,286.823639 
	C254.984451,287.225739 255.690872,291.046478 253.062012,292.127319 
	C252.569977,292.422272 252.363892,292.524017 251.826904,292.720398 
	C247.719482,291.340759 244.685684,292.988434 241.457306,295.260315 
	C238.930206,295.666260 237.047394,296.506927 235.698990,298.393463 
	C234.704315,299.785095 233.506500,300.893219 231.299164,300.260254 
	C229.576736,295.966766 234.593475,293.717072 233.971390,289.840515 
	C233.954926,289.737793 234.620865,289.342163 234.753265,289.425446 
	C239.703995,292.539398 238.762238,286.267426 241.216110,285.257355 
	C242.299316,284.811493 242.914886,282.084015 244.491440,283.498291 
	C246.708420,285.487061 242.564926,284.820038 242.828796,286.381989 
	C242.919739,286.920319 242.510101,287.543182 242.304092,288.208221 
	C248.059753,288.025421 252.556305,282.723877 258.874023,285.077301 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M207.315872,379.449402 
	C206.560806,378.762787 206.180328,378.229889 205.778381,377.713745 
	C204.868149,376.544922 208.456085,375.010345 205.631897,374.203461 
	C203.735168,373.661499 204.350662,376.781555 202.264893,376.989624 
	C200.891724,377.779297 199.443893,378.201385 199.877914,380.250610 
	C201.004272,382.700562 202.305054,384.912659 198.288956,385.885406 
	C197.478912,385.668243 196.953110,385.331238 196.105042,384.951691 
	C194.859589,384.273285 194.016556,383.556427 193.225159,382.317200 
	C195.415649,378.333618 196.383194,373.751221 199.933075,370.354645 
	C204.497620,367.459381 206.879364,372.279572 210.821289,373.047180 
	C211.810760,374.647125 211.353561,376.137482 210.640106,377.968964 
	C209.725327,379.174164 208.842422,379.690247 207.315872,379.449402 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M231.013245,300.295837 
	C232.939804,299.822174 233.869354,298.726715 234.952499,297.068207 
	C235.877747,295.651520 237.667404,293.583954 240.745087,294.991699 
	C241.282196,295.994324 241.804565,296.138641 242.637238,296.394226 
	C243.982910,297.168243 244.652466,298.020905 244.218475,299.616516 
	C242.454849,302.454742 239.363083,302.416382 237.088593,303.713165 
	C233.038483,306.022308 228.470932,307.602448 223.247101,308.002747 
	C224.068787,306.130188 225.820404,304.811798 227.823547,303.298553 
	C228.681305,302.182434 229.662888,301.862885 230.791199,301.255127 
	C230.959503,301.003601 230.997940,300.531952 231.013245,300.295837 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M130.264114,520.818481 
	C128.497208,521.791138 129.247055,525.016357 126.688316,524.547058 
	C124.894951,524.218140 124.723534,522.102234 123.882996,520.743530 
	C122.676613,518.793457 123.174202,517.661499 125.204079,516.465271 
	C126.255753,515.845520 126.511261,513.932983 127.216385,512.654114 
	C128.497192,510.331177 130.110382,509.675934 132.466095,511.898315 
	C133.654984,512.488586 133.119690,513.002808 132.440262,513.637085 
	C131.684952,515.866211 125.330582,515.596619 129.934158,519.963623 
	C130.235886,520.392334 130.350693,520.522705 130.264114,520.818481 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M168.486725,405.185303 
	C171.721313,409.103790 171.387665,411.988037 167.897888,413.003052 
	C166.295166,413.469208 164.611450,413.540924 163.372192,412.058258 
	C162.272476,410.742615 161.562622,409.223328 162.298477,407.531982 
	C163.422501,404.948517 165.694092,404.759491 168.486725,405.185303 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M249.574081,278.764893 
	C249.502884,281.002441 249.501953,281.039093 247.400696,280.985962 
	C245.813141,280.945862 243.118530,281.061035 243.592621,279.160828 
	C244.330078,276.205109 245.221069,272.794495 248.475906,271.209198 
	C249.064163,270.922668 249.636353,271.607483 249.934723,272.612244 
	C250.393204,273.386902 250.521591,273.895599 250.063599,274.743591 
	C249.748535,275.014252 249.438950,275.006470 249.284348,274.997681 
	C248.698441,275.311218 248.357544,275.713989 247.948868,276.391846 
	C247.603638,276.902130 247.315613,277.035797 247.154541,277.170105 
	C247.501541,277.431671 247.649918,277.536743 248.017883,277.828033 
	C248.568924,278.251770 248.927567,278.438477 249.574081,278.764893 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M152.032806,513.064087 
	C152.212692,515.238586 154.807587,518.425598 149.772232,517.239624 
	C148.017868,516.826416 146.844284,520.325012 144.244141,517.945068 
	C147.350815,516.835083 146.826111,513.191040 149.424271,511.464355 
	C151.570282,510.038208 151.903168,510.684601 152.032806,513.064087 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M198.276962,405.396423 
	C199.495270,402.949158 199.084702,399.806610 202.641891,399.021454 
	C203.859222,400.016937 203.393188,401.201782 203.211090,402.689880 
	C202.986923,403.059631 202.968414,402.982971 203.005417,402.969360 
	C201.982697,404.213928 202.742081,405.739655 202.243256,407.415527 
	C201.770569,408.324524 201.486343,408.926086 201.061035,409.829712 
	C199.212173,409.164948 198.511505,407.580750 198.276962,405.396423 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M129.060669,496.021790 
	C127.666504,496.545959 126.684105,497.209259 125.601028,497.509949 
	C124.205185,497.897430 122.716141,497.442139 122.523941,495.933167 
	C122.335411,494.452942 123.627266,494.039276 124.929192,494.480194 
	C126.613350,495.050568 126.639427,493.564331 127.389191,492.808746 
	C128.904846,491.281311 130.190018,492.509827 131.049835,493.732117 
	C132.444839,495.715179 130.837723,495.855438 129.060669,496.021790 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M253.049240,265.678802 
	C250.396393,270.439331 247.258087,268.358276 244.074951,266.474426 
	C244.313934,265.429932 245.034744,265.551910 245.857666,265.164062 
	C247.733719,264.981262 249.356064,264.990387 251.383560,265.006378 
	C252.320251,264.652771 252.760040,264.742371 253.049240,265.678802 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M253.367584,266.024170 
	C252.765091,265.789398 252.539764,265.503418 252.051559,265.115356 
	C254.182861,262.680298 256.578857,260.352112 260.729553,261.855927 
	C261.030518,262.417328 260.991455,262.854126 260.920837,263.617310 
	C258.507812,264.620087 256.126282,265.296539 253.367584,266.024170 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M150.382324,478.951233 
	C150.298950,477.906677 150.649048,476.826355 150.888962,475.722107 
	C151.164261,474.454987 151.160507,472.898926 153.087662,473.270416 
	C154.369370,473.517548 155.638748,474.075226 155.855560,475.624084 
	C156.094101,477.328094 155.030655,477.846924 153.557129,478.070160 
	C152.606766,478.214111 151.695740,478.617676 150.382324,478.951233 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M218.944275,308.521973 
	C216.469116,309.716919 217.122726,313.886902 213.229950,313.736816 
	C212.660538,313.325104 212.642654,313.128143 212.711243,312.647522 
	C213.008743,312.029388 213.257751,311.726593 213.766190,311.260132 
	C214.273331,307.829559 215.546356,306.272034 218.944275,308.521973 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M176.644073,444.444244 
	C176.226868,444.921082 176.062561,445.136536 175.971497,445.635071 
	C174.225388,446.222137 172.262390,446.221344 172.207443,444.094452 
	C172.148361,441.808868 174.805832,442.120728 176.581329,441.205597 
	C176.888641,442.157959 176.842880,443.132294 176.644073,444.444244 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M150.001221,479.001221 
	C149.793365,480.880554 148.949951,482.147858 146.861694,481.740906 
	C145.774338,481.529022 144.605270,481.062866 144.277390,479.806152 
	C144.130234,479.242096 144.123688,478.328979 144.463623,478.005798 
	C145.745377,476.787109 146.253265,478.849091 147.320480,478.844055 
	C147.964859,478.841003 148.609756,478.941956 149.625977,478.996857 
	C149.997559,478.997559 150.000000,479.000000 150.001221,479.001221 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M201.074890,415.012604 
	C203.539185,415.538116 202.919235,416.781006 202.077362,417.848663 
	C201.021729,419.187469 199.962158,420.923981 197.897659,418.924377 
	C197.459793,417.165863 197.189392,415.834198 199.546783,415.210449 
	C200.197327,415.072266 200.447342,415.043213 201.074890,415.012604 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M132.400925,513.829529 
	C132.305908,513.309875 132.484024,512.864990 132.718643,512.187012 
	C133.877579,511.656464 134.752625,510.185730 136.133926,511.322968 
	C137.206955,512.206360 136.606247,512.916992 135.753510,513.879761 
	C135.840118,515.036377 135.505173,515.427063 134.454132,514.752319 
	C133.785416,514.109131 133.229721,514.006653 132.400925,513.829529 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M148.413483,501.497131 
	C149.014923,502.639160 150.613144,503.761627 148.919724,504.465790 
	C147.890045,504.893921 145.914398,504.362183 145.604477,503.132477 
	C145.174683,501.427155 146.642059,501.044586 148.413483,501.497131 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M209.571350,264.629639 
	C211.410767,263.900391 212.553223,264.555634 214.107742,266.707275 
	C211.824554,266.556580 209.937164,268.446167 209.571350,264.629639 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M136.191071,528.807007 
	C135.354477,528.902161 134.791946,528.763306 134.102249,528.357544 
	C134.181442,526.897949 133.708282,524.936523 135.762039,525.362000 
	C137.538773,525.729919 137.045197,527.305237 136.191071,528.807007 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M130.310822,521.246704 
	C130.062729,520.983887 130.168686,520.629028 130.144882,520.445435 
	C131.135880,520.386230 132.085602,517.800903 133.137299,520.168701 
	C133.493607,520.970886 134.494934,521.900635 133.359497,522.699036 
	C131.973358,523.673706 130.948944,523.190063 130.310822,521.246704 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M175.533722,291.393860 
	C175.530487,293.329681 174.747986,294.155121 173.123169,293.329376 
	C172.380600,292.951996 172.025375,292.069061 172.589722,291.240753 
	C173.424652,290.015259 174.404617,290.045135 175.533722,291.393860 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M118.014130,474.477600 
	C119.164154,475.737396 119.380272,476.885406 117.867752,477.664459 
	C117.567780,477.818970 116.818535,477.645538 116.627007,477.375061 
	C115.629265,475.966095 116.363792,475.064056 118.014130,474.477600 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M186.522476,272.011841 
	C186.069809,273.517029 185.227570,274.126648 183.960571,273.654419 
	C183.324890,273.417511 182.986465,272.742340 183.327850,272.112946 
	C184.135040,270.624664 185.278931,270.841003 186.522476,272.011841 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M172.257019,476.275696 
	C173.632751,476.276306 175.583008,472.062164 176.965500,476.683044 
	C175.506104,476.857391 174.009171,476.702667 172.257019,476.275696 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M213.612915,311.051208 
	C213.801392,311.586945 213.621887,312.114777 213.209854,312.806763 
	C212.856461,313.140320 212.735596,313.309692 212.824753,313.709991 
	C213.034790,313.940887 213.037445,313.967224 213.055450,313.965546 
	C213.069229,314.135132 213.065018,314.306396 213.019989,314.745819 
	C212.114532,314.853485 211.249908,314.692993 210.186554,314.263916 
	C210.869965,313.463348 210.957977,312.584717 211.017227,311.350952 
	C211.773544,311.026031 212.509247,311.034668 213.612915,311.051208 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M199.659103,414.967285 
	C199.710281,416.321289 197.783401,416.329529 197.961731,418.216827 
	C196.744736,418.018250 196.384506,416.879028 196.031708,415.382507 
	C197.111526,414.130859 198.198761,413.716583 199.659103,414.967285 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M197.840363,303.611847 
	C199.621338,304.965179 199.288452,305.948181 197.848618,306.676453 
	C197.354721,306.926239 196.608780,307.235107 196.219131,306.436493 
	C195.518433,305.000366 196.108841,304.042725 197.840363,303.611847 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M134.243164,514.628723 
	C134.604721,514.100464 134.879349,514.052673 135.372711,514.098267 
	C135.702087,515.204956 136.593613,516.784424 135.180328,517.154297 
	C133.714157,517.538086 134.238770,515.913208 134.243164,514.628723 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M246.845413,277.057312 
	C247.002426,275.725708 247.450592,275.128601 248.805786,275.007629 
	C249.092316,275.710327 249.054886,276.431732 249.023804,277.514008 
	C248.668503,278.329834 248.200119,278.514435 247.280518,278.222504 
	C246.935760,278.015656 246.998123,278.004974 246.989044,278.035278 
	C246.871933,277.838318 246.763916,277.611023 246.845413,277.057312 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M166.502441,466.013611 
	C166.017654,466.876251 165.414108,466.975800 164.825378,466.573700 
	C164.347015,466.246948 164.350876,465.723145 164.841476,465.409454 
	C165.439987,465.026733 166.041962,465.133759 166.502441,466.013611 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M230.692474,273.493530 
	C230.513657,272.087219 231.264496,272.064484 232.098755,272.361572 
	C232.293213,272.430756 232.519135,272.931854 232.447693,273.134552 
	C232.185150,273.879456 231.557419,273.890106 230.692474,273.493530 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M230.609940,301.007812 
	C231.227493,302.912964 229.485352,302.425659 228.314072,303.007019 
	C228.074402,301.601227 228.885696,300.995148 230.609940,301.007812 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M184.358032,346.820557 
	C184.700256,347.697388 184.799316,348.493713 183.950485,348.436096 
	C182.891647,348.364197 182.946625,347.305908 183.109894,346.582825 
	C183.353760,345.502899 183.838013,345.990265 184.358032,346.820557 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M243.333679,250.049149 
	C242.429169,249.515152 241.841248,248.836182 242.877258,248.294662 
	C243.844803,247.788940 244.640488,248.162628 244.929169,249.653992 
	C244.527405,249.994995 244.093079,250.008240 243.333679,250.049149 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M193.007309,382.389221 
	C194.498749,382.112885 194.828964,383.498596 195.713058,384.617065 
	C194.137665,385.094147 193.332672,384.207153 193.007309,382.389221 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M235.734756,267.156616 
	C235.888641,267.906921 236.285721,268.979889 235.186203,269.461945 
	C235.012375,269.538147 234.523682,269.334503 234.465744,269.163574 
	C234.157028,268.252686 234.842331,267.771790 235.734756,267.156616 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M151.825623,526.752441 
	C150.687088,526.973389 149.455444,526.973389 147.878571,526.973389 
	C148.628006,525.665649 149.592880,526.109924 150.666534,525.924805 
	C151.291824,526.064575 151.534454,526.271912 151.825623,526.752441 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M198.582245,260.041473 
	C198.195145,260.749725 197.804993,260.760712 197.459229,260.254700 
	C197.387009,260.149017 197.390015,259.903687 197.465179,259.800629 
	C197.823425,259.309387 198.213608,259.320435 198.582245,260.041473 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M158.422043,470.954224 
	C158.808014,470.246613 159.197052,470.239471 159.541245,470.743134 
	C159.613373,470.848663 159.611038,471.093140 159.536316,471.196075 
	C159.178421,471.688995 158.789566,471.670807 158.422043,470.954224 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M145.578171,507.045044 
	C145.192032,507.751953 144.802765,507.759491 144.458374,507.256073 
	C144.386215,507.150604 144.388626,506.906067 144.463425,506.803192 
	C144.821442,506.310699 145.210434,506.328552 145.578171,507.045044 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M190.715790,366.913269 
	C191.065292,366.962708 191.013641,367.441132 191.003571,367.680878 
	C190.169769,367.380737 188.216843,369.592529 188.644531,366.729370 
	C189.441727,366.714233 189.904007,366.789032 190.715790,366.913269 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M235.975677,266.974304 
	C236.082260,266.340179 236.123810,265.638031 236.913132,265.526245 
	C237.087555,265.501587 237.314926,265.850983 237.517914,266.028107 
	C237.211853,266.334534 236.905777,266.640961 236.327667,267.000549 
	C236.055649,267.053741 236.001968,267.001160 235.975677,266.974304 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M245.784210,264.931030 
	C245.612091,265.322815 245.112839,265.673462 244.298904,266.076202 
	C243.664307,265.893829 243.344360,265.659393 243.024429,265.424957 
	C243.835312,265.246613 244.646194,265.068298 245.784210,264.931030 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M140.954987,510.577881 
	C140.248291,510.192017 140.240982,509.803009 140.743973,509.458771 
	C140.849335,509.386627 141.093750,509.389099 141.196655,509.463776 
	C141.689163,509.821594 141.670898,510.210358 140.954987,510.577881 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M226.628189,268.554810 
	C227.402969,268.336212 227.682953,268.607697 227.563934,269.208771 
	C227.539185,269.333740 227.362305,269.502045 227.236115,269.520996 
	C226.629013,269.612000 226.373947,269.317169 226.628189,268.554810 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M263.902435,260.766785 
	C263.558289,261.330353 263.014557,261.671509 262.129272,262.019592 
	C262.157501,261.185455 262.124329,259.823639 263.902435,260.766785 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M179.552429,346.374817 
	C179.332596,345.595734 179.605576,345.314148 180.209930,345.433472 
	C180.335571,345.458252 180.504868,345.636169 180.523911,345.763062 
	C180.615585,346.373535 180.318771,346.629883 179.552429,346.374817 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M215.419922,278.959564 
	C215.814102,278.240570 216.211258,278.224762 216.563278,278.742767 
	C216.636642,278.850708 216.633438,279.099945 216.556961,279.205231 
	C216.191772,279.707825 215.794662,279.691010 215.419922,278.959564 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M237.422195,274.954895 
	C237.808121,274.248413 238.197083,274.240967 238.541229,274.743988 
	C238.613327,274.849365 238.610840,275.093719 238.536102,275.196564 
	C238.178314,275.688843 237.789597,275.670624 237.422195,274.954895 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M219.691467,299.122986 
	C220.510071,299.811493 219.857834,299.854309 219.496719,299.882141 
	C219.270065,299.899628 219.022507,299.645813 218.784317,299.513733 
	C219.015045,299.371246 219.245789,299.228760 219.691467,299.122986 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M246.937881,278.386047 
	C247.404358,278.015625 247.872940,278.015594 248.685852,277.945190 
	C249.296509,278.063477 249.562851,278.252106 249.835205,278.692078 
	C248.874146,278.881104 247.907074,278.818756 246.937881,278.386047 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M184.745407,368.355713 
	C184.988785,368.370087 185.121811,368.502686 185.254837,368.635284 
	C185.048248,368.581482 184.841660,368.527679 184.745407,368.355713 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M250.052368,274.943359 
	C249.820847,274.557098 249.893158,274.099976 249.979019,273.294983 
	C250.756226,273.467560 251.186234,274.051025 250.052368,274.943359 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M189.976288,423.249268 
	C190.081970,423.410645 190.029831,423.574005 189.977692,423.737335 
	C189.924622,423.575287 189.871536,423.413269 189.976288,423.249268 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M182.455841,369.527863 
	C182.427032,369.477722 182.484650,369.578003 182.455841,369.527863 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M190.456604,375.526947 
	C190.428650,375.477722 190.484573,375.576172 190.456604,375.526947 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M180.456512,370.527039 
	C180.428741,370.478271 180.484268,370.575775 180.456512,370.527039 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M194.527603,313.543945 
	C194.476654,313.573212 194.578552,313.514648 194.527603,313.543945 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M176.791641,478.096405 
	C177.004135,478.148193 177.004288,478.318298 177.001923,478.743591 
	C176.999390,478.998779 176.509674,478.995239 176.265076,478.994446 
	C176.124146,478.674744 176.310425,478.415131 176.791641,478.096405 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M205.444351,293.533142 
	C205.408005,293.473419 205.480698,293.592865 205.444351,293.533142 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M224.455597,283.528656 
	C224.426102,283.477692 224.485077,283.579620 224.455597,283.528656 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M194.536102,425.557373 
	C194.473267,425.596008 194.598953,425.518707 194.536102,425.557373 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M288.347198,275.838043 
	C284.223297,275.124481 283.190704,272.684937 283.833740,268.826477 
	C284.470978,267.895447 285.179504,267.321838 286.010681,266.500671 
	C287.513092,263.797302 289.637482,262.049500 292.052582,260.112244 
	C295.808197,257.819519 299.110779,255.417465 303.197571,253.786469 
	C303.659698,253.613998 303.837799,253.551163 304.260101,253.407135 
	C304.504333,253.325974 304.930725,253.188889 305.092163,253.101257 
	C305.177460,252.715164 304.997650,252.501221 304.486633,252.201004 
	C302.978943,250.779587 301.753021,249.494934 301.488159,247.253632 
	C303.066925,242.376022 306.566010,239.975372 311.172913,238.355103 
	C313.006989,238.212173 314.476868,239.175110 316.253906,238.233307 
	C317.688782,237.575348 318.642548,236.904068 318.584900,235.116180 
	C318.697449,232.777359 319.271301,231.012405 320.996277,229.745605 
	C322.063843,228.961594 322.950043,228.012253 322.222107,226.268845 
	C328.324860,221.165710 335.315460,219.450317 343.145782,221.834183 
	C345.456116,222.537552 347.620483,222.753983 348.993256,220.002258 
	C349.574738,218.836731 350.776337,218.082870 352.530945,217.787079 
	C354.393097,218.163559 355.995758,217.786179 357.781616,218.582153 
	C358.909119,220.095535 358.512146,221.133728 357.094604,222.156296 
	C356.504120,222.437073 355.989563,222.309296 355.920807,222.402969 
	C355.665924,222.750488 355.788330,222.734070 356.239441,222.475464 
	C356.811279,222.147705 356.059570,222.597946 357.033936,222.297256 
	C366.162567,218.924362 374.711792,221.501678 383.614471,223.504333 
	C385.496704,226.362122 383.882874,228.122772 382.107635,229.593658 
	C380.220551,231.157257 378.433014,232.809769 376.435303,234.721405 
	C375.772919,235.283157 375.248169,235.555725 375.348633,236.464783 
	C375.541840,236.890701 375.627472,237.062943 375.821350,237.512146 
	C376.248840,239.214432 376.698608,240.592361 377.492645,242.200378 
	C377.436188,244.580429 376.105560,245.021057 374.437103,244.627670 
	C372.584351,244.190826 371.102692,243.118515 370.365631,241.276779 
	C369.924500,240.174484 370.082642,238.978943 370.009125,237.820709 
	C369.632355,231.884781 369.630035,231.841980 363.637909,231.786316 
	C362.039581,231.771469 360.626587,231.634338 359.826569,230.062531 
	C358.864990,228.173233 360.741028,227.522812 361.562592,226.354706 
	C357.598938,225.695724 356.211182,227.868835 356.932220,231.142044 
	C357.765594,234.925568 355.191681,238.530121 357.364288,242.598785 
	C358.542358,244.804947 354.891510,246.347290 352.232300,247.374329 
	C351.646973,247.399704 351.412720,247.372818 350.875183,247.167328 
	C349.137604,245.239914 347.291595,243.386795 345.251556,245.362885 
	C343.602539,246.960205 339.552856,246.802933 340.959137,251.297241 
	C341.846436,254.132782 339.628937,255.001114 336.833099,253.210617 
	C336.264923,252.774109 336.475433,252.151001 336.289368,252.133774 
	C335.978577,252.104996 336.013977,252.414490 336.561035,252.929535 
	C336.749329,253.374619 336.831177,253.522614 336.870758,253.681137 
	C337.167999,254.871155 336.880829,256.868134 336.211670,256.778992 
	C332.389771,256.269836 332.268951,262.476501 327.876892,260.786163 
	C325.595154,258.185181 327.064331,256.176819 328.410522,254.279861 
	C330.168304,251.802963 329.609833,249.616730 327.190308,248.441818 
	C324.910095,247.334564 323.100220,245.842285 321.380157,243.994217 
	C321.578033,246.791794 323.455322,248.366196 325.221375,250.017685 
	C326.328613,251.053116 328.717712,251.198822 327.989014,253.535126 
	C327.278473,255.813202 325.800903,257.252716 322.886292,256.705719 
	C322.450623,256.554810 322.431549,256.232758 322.175598,256.614807 
	C321.762543,257.640991 321.014496,258.475769 320.319885,258.366821 
	C313.608307,257.314087 309.872375,263.432281 304.243286,264.940399 
	C303.427246,265.158997 302.986267,265.968903 302.716003,267.168030 
	C301.758636,270.169403 299.438049,271.069244 297.147308,271.970490 
	C294.270966,273.102112 291.244171,273.891907 288.347198,275.838043 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M321.706543,226.058533 
	C324.763916,227.569443 326.168915,229.312714 322.075562,230.798141 
	C319.634155,231.684097 320.078308,233.436508 319.991486,235.580124 
	C319.149872,236.846054 318.293091,237.690475 317.260223,238.782837 
	C315.635895,240.941513 314.114044,241.155746 312.184937,239.114624 
	C307.426208,239.669525 303.044342,241.048035 298.409454,240.407959 
	C295.583466,240.017685 293.708160,238.993347 294.225708,235.321060 
	C298.256012,229.921768 303.941925,229.535156 309.519501,230.070892 
	C314.324615,230.532425 318.144440,229.391525 321.706543,226.058533 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M293.835602,234.900177 
	C294.726166,234.931152 295.204926,234.944992 295.683685,234.958832 
	C294.266815,240.741913 298.492432,238.977478 301.179199,238.935776 
	C304.627838,238.882248 308.071777,238.528107 311.706848,238.633392 
	C308.114075,240.704544 304.332367,242.445969 302.415283,246.909027 
	C301.128174,248.415237 301.507477,250.408249 299.677246,251.691849 
	C295.086121,252.717316 291.071320,254.832352 286.267822,254.156143 
	C286.343689,251.497177 287.350830,250.360931 290.152435,250.232132 
	C293.253021,250.089584 296.204010,248.796906 297.225861,245.165085 
	C297.519592,244.121078 297.055267,243.620743 296.485535,243.041519 
	C295.954346,242.501434 295.413544,242.535400 294.740448,242.933563 
	C293.065155,243.924652 293.042572,243.886505 292.871490,241.457962 
	C291.960571,239.807144 290.279846,239.049698 289.134338,237.296356 
	C289.481964,234.215561 291.761963,235.196182 293.835602,234.900177 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M378.978058,208.389099 
	C379.464691,206.804382 379.964935,205.589462 380.737671,204.188477 
	C383.132812,204.073624 383.283936,201.296310 385.547119,201.047363 
	C386.779175,201.573364 386.621765,202.845261 387.713623,203.639404 
	C388.132416,204.134583 388.329346,204.426361 388.754150,204.856934 
	C389.268951,205.404465 389.642151,206.105774 389.352875,206.318436 
	C384.222321,210.089584 383.117554,217.058456 377.489441,220.889191 
	C371.629639,220.744492 366.071686,222.687378 360.746155,220.011810 
	C360.824402,219.569611 361.015991,219.471466 361.112640,219.518829 
	C362.167938,219.905396 362.725464,219.073456 363.751038,218.649231 
	C366.008484,217.116913 368.401398,217.023773 370.632385,216.582550 
	C375.131927,215.692688 378.142822,213.439850 378.978058,208.389099 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M360.635681,219.413605 
	C362.749359,222.057968 365.511963,221.231567 367.715668,220.724106 
	C370.862823,219.999390 373.958740,220.069351 377.548828,220.001953 
	C380.706390,219.005585 383.515808,218.392014 384.566833,214.949875 
	C385.061218,213.330887 387.014893,212.928711 388.840454,213.383820 
	C390.894623,213.895889 391.002808,215.458130 391.078888,217.146042 
	C391.219513,220.264908 389.188873,222.668213 388.272491,225.426071 
	C387.703400,227.138779 385.773102,226.325775 384.253845,225.159119 
	C375.072998,223.008286 366.122101,220.858521 356.437866,223.016083 
	C355.826599,222.834717 355.654175,222.667236 355.740234,222.248398 
	C357.030518,221.529434 357.658325,220.740128 357.928406,219.316925 
	C358.151215,218.846283 358.329071,218.688904 358.782471,218.757263 
	C359.520996,219.074524 359.983948,219.166077 360.635681,219.413605 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M285.697205,254.100952 
	C290.319153,252.776489 294.658722,251.660767 299.388702,250.432663 
	C301.428497,250.026016 302.803131,250.797455 304.496704,251.718201 
	C305.502716,252.774139 308.531769,250.180267 307.060852,253.760651 
	C306.354889,253.577133 305.933075,253.525131 305.254578,253.835846 
	C305.016998,254.063400 304.996979,254.006531 305.026520,254.012558 
	C299.248108,255.552719 293.662048,257.821594 287.394257,259.279602 
	C285.515411,259.466492 284.099213,259.380646 282.507935,258.243652 
	C281.518860,257.868011 279.699493,258.607361 280.657959,257.182404 
	C281.595306,255.788803 283.778442,255.233124 285.697205,254.100952 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M289.196655,240.549042 
	C286.581299,241.243164 283.527740,240.198410 282.745911,244.480515 
	C282.395966,246.397095 280.143677,244.978058 279.336853,244.082764 
	C278.971527,243.677368 275.329926,241.878143 279.683044,240.995209 
	C280.003937,240.994751 280.260376,241.266113 280.392151,241.398285 
	C280.523956,241.530457 280.801025,241.780624 280.664307,241.653564 
	C280.527557,241.526520 280.267700,241.258591 280.131226,241.131302 
	C278.466919,236.438263 275.414215,241.117218 273.116394,239.630768 
	C273.203094,238.813232 274.482849,238.328735 274.203308,236.883286 
	C273.712830,234.347214 276.529755,235.206192 277.768158,234.405579 
	C279.578491,233.235245 278.831146,235.291412 279.145874,235.863785 
	C281.084900,239.390259 284.909790,237.106094 287.709595,238.813721 
	C288.630890,239.141800 289.031555,239.591919 289.196655,240.549042 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M330.661285,205.359238 
	C336.762299,203.161545 342.753357,203.960144 348.715027,205.195251 
	C343.050751,203.512222 337.438812,206.896759 331.384003,206.282715 
	C330.863220,206.168076 330.795349,205.939438 330.661285,205.359238 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M292.992371,260.474884 
	C290.889526,262.613525 288.836548,264.340973 286.458435,266.160767 
	C286.112305,265.399384 286.091278,264.545593 286.054016,263.264526 
	C285.590454,261.463776 285.819122,260.195312 286.975769,258.801239 
	C289.391693,258.443634 291.765808,257.129883 292.992371,260.474884 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M287.248474,258.676941 
	C287.079376,260.138702 287.283142,261.305328 286.162445,262.517822 
	C283.705811,263.484344 283.404266,261.510010 282.312622,259.984192 
	C281.895966,259.664032 281.771637,259.538055 281.828430,259.217957 
	C282.044678,258.846802 282.079773,258.669739 282.150055,258.223938 
	C283.460358,257.975464 284.735474,257.995667 286.394867,257.988159 
	C287.013275,258.090302 287.247406,258.220123 287.248474,258.676941 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M268.770721,236.920593 
	C268.940186,241.210327 265.579315,240.271759 262.521820,241.328476 
	C263.433594,239.624191 264.027191,238.514633 264.926239,237.283981 
	C266.437073,237.940231 267.400879,236.894958 268.770721,236.920593 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M289.532104,240.904236 
	C288.837982,240.399826 288.529266,239.935837 288.085144,239.227020 
	C288.183716,238.470108 288.417694,237.958023 288.825531,237.221436 
	C291.235474,237.106964 292.504150,238.307785 292.970459,240.839844 
	C291.987701,241.119812 290.952576,241.032242 289.532104,240.904236 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M360.504486,208.971222 
	C362.860077,207.633438 365.599579,206.539993 367.979553,210.108154 
	C365.708191,209.722702 363.424408,208.825439 360.504486,208.971222 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M358.999908,218.621307 
	C358.877319,218.985672 358.696655,218.988358 358.244659,218.997360 
	C356.426422,218.868073 354.789917,219.378311 353.133728,218.051331 
	C355.039307,216.482101 357.039551,216.803574 358.999908,218.621307 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M250.139740,246.749496 
	C249.018036,247.436966 248.117462,248.363312 246.988022,247.331680 
	C246.427063,246.819305 246.359772,246.283463 246.893585,245.728638 
	C247.722519,244.867065 248.572556,245.109924 249.699463,245.859924 
	C250.089157,246.168213 250.195633,246.334793 250.139740,246.749496 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M269.324097,236.944244 
	C267.845337,236.908325 266.926117,238.604874 265.412842,237.405609 
	C266.700317,237.221466 267.961823,234.926147 269.324097,236.944244 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M252.991486,245.180939 
	C254.081375,244.649826 254.215805,242.062653 255.530136,243.711761 
	C256.297943,244.675140 254.913574,245.635147 253.317841,245.965271 
	C252.842575,245.866486 252.829880,245.508118 252.991486,245.180939 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M287.761505,245.766785 
	C287.141754,246.716171 286.575928,246.627808 286.299896,245.879486 
	C286.171570,245.531586 286.347687,244.779694 286.616516,244.632614 
	C287.372711,244.218872 287.718689,244.754913 287.761505,245.766785 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M252.929398,245.075378 
	C251.988037,244.891541 250.956238,244.837830 250.760986,243.675781 
	C250.735291,243.522888 251.089325,243.179825 251.310852,243.133911 
	C252.207993,242.947983 252.777924,243.361633 253.081390,244.549133 
	C253.153076,244.853775 253.002701,245.000259 252.929398,245.075378 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M270.056885,264.568481 
	C269.069397,264.058289 269.276672,263.489777 269.792175,263.001831 
	C269.953278,262.849335 270.478241,262.792633 270.574951,262.909210 
	C271.069031,263.504791 270.886047,264.073608 270.056885,264.568481 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M363.709656,218.390778 
	C363.705353,219.583328 362.945923,219.932983 361.549500,219.675964 
	C361.583832,218.541931 362.415039,218.288177 363.709656,218.390778 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M259.226440,239.934753 
	C259.134003,239.243652 259.704895,238.786606 260.231171,239.271439 
	C260.726868,239.728119 260.041656,239.910614 259.226440,239.934753 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M278.699554,258.573181 
	C278.692200,258.833435 278.569366,258.976868 278.446564,259.120300 
	C278.492401,258.898987 278.538269,258.677643 278.699554,258.573181 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M262.393188,237.610214 
	C262.321960,237.513565 262.580627,237.460785 262.580627,237.460785 
	C262.580627,237.460785 262.464386,237.706863 262.393188,237.610214 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M281.711243,259.028076 
	C282.009583,259.023865 282.024231,259.407684 282.022278,259.598846 
	C281.294586,259.956909 280.887451,259.868408 281.711243,259.028076 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M257.512451,242.542938 
	C257.456482,242.573364 257.568420,242.512512 257.512451,242.542938 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M259.065796,240.079483 
	C258.975281,240.341507 258.783630,240.499725 258.591980,240.657928 
	C258.548004,240.447067 258.504059,240.236221 258.608124,239.899780 
	C258.756165,239.774185 258.964630,239.975662 259.065796,240.079483 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M250.140671,246.908875 
	C249.977386,246.997635 249.987122,246.499680 249.984909,246.250641 
	C250.151031,246.168137 250.335602,246.322662 250.474548,246.510880 
	C250.503738,246.550430 250.365204,246.713791 250.140671,246.908875 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M253.074158,246.093094 
	C252.942444,246.260559 252.631012,246.972290 252.717590,245.972061 
	C252.842575,245.866486 253.000381,246.014160 253.074158,246.093094 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M289.437195,243.534668 
	C289.401855,243.476807 289.472504,243.592514 289.437195,243.534668 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M329.164276,204.293793 
	C329.349762,204.232986 329.645721,204.450882 330.143097,204.868988 
	C329.987946,204.903656 329.631317,204.738083 329.164276,204.293793 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M157.060394,549.282349 
	C158.852417,547.694885 157.032410,544.936279 159.396362,543.537415 
	C160.812149,543.217651 160.939255,542.264709 161.503601,541.227905 
	C162.480377,540.256836 163.399277,540.110535 164.748550,540.316589 
	C166.541107,541.132263 167.426743,542.297668 167.965912,544.156677 
	C168.181763,546.984192 166.250977,550.682922 171.741180,550.244141 
	C172.307404,550.339355 172.529419,550.406372 173.041870,550.667725 
	C174.833649,553.163635 174.940826,555.139404 172.020874,556.385376 
	C169.355316,557.522705 167.939163,560.110901 165.488174,561.991943 
	C164.625519,562.733521 163.967178,563.218140 163.261505,564.060974 
	C162.917099,564.612427 162.775330,564.951904 162.639542,565.589600 
	C162.576263,566.061584 162.546692,566.251953 162.406830,566.713745 
	C162.149048,567.115723 162.001587,567.246216 161.566849,567.451172 
	C161.084259,567.492065 160.888947,567.458496 160.490982,567.207520 
	C159.737183,566.314026 159.186020,565.637939 158.387802,564.743958 
	C158.034836,564.370117 157.928909,564.214233 157.994400,563.834106 
	C158.612198,563.266052 159.043442,562.906311 159.537872,562.217346 
	C160.581085,557.491943 158.306549,553.651123 157.060394,549.282349 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M136.580658,557.673218 
	C136.655350,556.665649 136.788956,556.123535 136.920868,555.301758 
	C137.689041,555.011780 138.458923,555.001465 139.615234,554.996033 
	C140.499893,555.887268 140.998108,556.773560 141.747528,557.831177 
	C140.676666,559.142029 139.202271,559.806885 137.204102,559.240234 
	C136.879868,558.716187 136.759720,558.427429 136.580658,557.673218 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M143.245209,557.197266 
	C141.538605,554.050232 144.950943,553.745605 145.539566,551.813354 
	C147.758453,554.136902 146.253098,555.439331 144.307877,556.856079 
	C143.842834,557.134827 143.671524,557.264832 143.245209,557.197266 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M158.320312,564.162476 
	C158.037704,564.557922 158.090164,564.549377 158.115448,564.537720 
	C157.328293,565.670044 156.943497,568.033081 154.846176,565.313965 
	C155.525772,564.169312 156.153000,563.233643 157.673279,563.035522 
	C158.198837,563.260437 158.400864,563.513733 158.320312,564.162476 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M152.106934,556.976074 
	C150.920242,555.929382 151.934952,555.296143 152.358887,554.567688 
	C152.392120,554.510498 153.328323,554.757690 153.375595,554.968628 
	C153.566483,555.820435 153.081116,556.471252 152.106934,556.976074 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M158.339905,564.391602 
	C158.035767,564.299255 158.033844,564.040649 158.014343,563.394592 
	C158.387238,562.689148 158.777710,562.371277 159.428345,562.016968 
	C159.977524,563.026123 159.570023,563.747009 158.339905,564.391602 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M139.999390,554.998779 
	C140.087570,554.962830 140.176376,554.928040 140.133423,554.947144 
	C140.001663,555.000977 140.000000,555.000000 139.999390,554.998779 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M142.985626,557.243103 
	C143.160828,557.000305 143.331421,557.000977 143.758072,557.003296 
	C143.907715,557.323425 143.721695,557.585388 143.241394,557.905640 
	C143.026733,558.020447 142.999039,557.998596 142.985901,557.985962 
	C142.972748,557.973328 142.981018,557.486511 142.985626,557.243103 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M138.785828,562.937500 
	C138.770981,564.549500 141.067490,565.052856 140.123764,566.544739 
	C139.946884,566.824341 139.221634,566.909180 138.783844,566.845825 
	C136.657166,566.538269 137.095551,564.882080 137.139191,563.073242 
	C137.642380,562.494873 138.102097,562.497192 138.785828,562.937500 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M139.250671,563.026978 
	C138.597870,563.110474 138.187881,563.003967 137.481415,562.786316 
	C137.309921,561.822876 137.696564,561.135315 138.647507,561.226685 
	C139.601974,561.318420 139.983017,561.894165 139.250671,563.026978 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M136.302597,557.259888 
	C136.625900,557.500732 136.730057,557.793701 136.917114,558.545776 
	C136.736725,558.895386 136.473450,558.785889 135.922424,558.342529 
	C135.650055,557.690491 135.799652,557.458252 136.302597,557.259888 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M163.745651,562.187134 
	C166.830231,559.582153 168.264008,555.557373 172.563385,554.658813 
	C174.017105,554.354919 172.588257,552.722412 172.649597,551.373413 
	C172.739914,546.240173 175.674454,542.559021 177.906891,538.697083 
	C179.348969,536.202454 180.681763,533.900452 180.564331,530.622009 
	C180.555084,529.342957 180.326111,528.409851 180.756958,527.169067 
	C181.374390,523.083435 179.162979,520.381836 176.945129,517.384583 
	C180.066528,517.759094 182.800003,518.182800 184.336838,514.546997 
	C185.422287,511.979004 188.590012,511.778748 191.055328,510.331177 
	C193.896011,509.038849 194.231491,511.128693 194.870895,513.237793 
	C194.838745,513.875671 194.791809,514.125122 194.584900,514.713318 
	C193.210678,516.292175 191.129883,516.844360 191.279007,519.168579 
	C191.321045,519.823486 191.045120,520.598572 190.499603,520.938904 
	C184.743607,524.529724 186.703720,529.324646 188.088348,534.286682 
	C189.201385,538.275452 185.428177,541.057190 184.644470,544.690063 
	C184.364014,545.990112 183.313599,547.399719 182.309998,548.441772 
	C179.321869,551.544434 177.467896,554.111267 182.058868,557.780396 
	C184.509750,559.739136 182.865646,563.681396 178.908997,564.197815 
	C174.592316,564.761230 174.573364,566.582581 175.483673,569.734680 
	C175.770645,570.728333 175.608322,571.719788 174.211502,572.160339 
	C169.321320,573.077026 165.413147,569.942017 160.600342,570.000061 
	C160.136032,569.830322 160.073990,569.667480 160.086456,569.254150 
	C160.594940,568.496765 161.036972,567.997498 161.783295,567.329224 
	C162.037140,567.112915 162.073196,567.069031 162.100159,567.060181 
	C162.127106,567.051270 162.389359,566.668579 162.576050,566.562622 
	C162.762741,566.456726 163.108795,566.231873 163.108795,566.231873 
	C163.108795,566.231873 163.079727,565.810547 163.032684,565.551514 
	C162.894730,564.283386 163.250229,563.371033 163.745651,562.187134 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M160.860016,575.662354 
	C160.152435,575.995483 159.429520,575.996582 158.342987,575.995178 
	C158.490982,573.853210 159.382339,573.356750 160.860016,575.662354 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M161.746048,567.084290 
	C161.923111,567.935608 161.647324,568.641602 160.455017,568.951172 
	C160.148666,568.553101 160.136353,568.102539 160.206192,567.321045 
	C160.677216,567.011963 161.066086,567.033813 161.746048,567.084290 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M153.522430,568.269470 
	C154.099594,568.076965 154.552704,568.049072 154.313293,568.653931 
	C154.291946,568.707886 153.681213,568.528564 153.522430,568.269470 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M329.228333,803.192993 
	C328.065186,802.551697 328.131226,801.626526 327.957764,800.473633 
	C327.497131,799.491333 326.933563,798.958618 326.166107,798.221069 
	C322.928558,795.743408 323.569702,789.937622 317.802307,789.906067 
	C315.829071,789.526978 315.842010,787.969849 315.039673,786.554077 
	C313.464600,782.462708 312.533203,778.621155 312.498077,774.263794 
	C312.362854,773.529114 312.236816,773.133484 311.871643,772.520996 
	C311.399475,772.000549 311.128845,771.701904 310.672638,771.158569 
	C310.036682,770.207764 309.926117,769.401428 309.800110,768.296021 
	C309.664612,767.240051 309.554138,766.481201 308.811462,765.651001 
	C308.494781,765.260010 308.371124,765.097778 308.079773,764.673706 
	C307.197876,763.125122 306.755371,761.641235 305.068115,760.631104 
	C304.648071,760.200684 304.498047,760.003906 304.187561,759.460876 
	C303.395142,756.393372 303.357391,753.766968 305.589722,751.222534 
	C307.337616,750.997314 308.746246,750.998352 310.577454,750.999695 
	C312.916290,751.528381 313.389862,753.291199 314.351746,755.009766 
	C319.300507,755.428284 321.315674,758.240479 320.113708,762.959290 
	C319.484833,765.428101 318.200531,765.995972 316.008118,764.587524 
	C315.982697,766.754761 317.124603,768.460999 317.825134,770.638184 
	C318.265381,771.720093 318.523865,772.538391 319.658813,773.086731 
	C321.350891,773.395569 322.486694,775.448242 323.939148,774.254089 
	C325.481781,772.985962 324.217926,771.567627 323.447601,770.302734 
	C322.784454,769.213745 322.635345,768.256165 323.782776,767.251404 
	C328.619934,763.015991 335.901764,763.788086 339.394562,769.207886 
	C341.540924,772.538330 344.020081,775.737549 345.321808,779.534607 
	C346.719849,783.612610 350.177307,786.815491 351.364838,790.475952 
	C352.821259,794.965088 355.109802,798.854858 357.005341,803.016174 
	C357.486023,804.071411 358.187714,805.006775 358.835541,805.971436 
	C361.357544,809.726685 361.142670,810.169006 356.192474,810.979370 
	C355.536896,810.785950 355.204803,810.613953 354.787720,810.164856 
	C354.665680,809.954956 354.188202,810.055908 354.396240,810.195557 
	C354.883881,810.854797 354.851807,811.401733 354.626526,812.251526 
	C354.239594,812.917358 353.892670,813.189270 353.134674,813.446777 
	C351.787933,813.445435 350.947174,812.934631 349.739197,812.756287 
	C349.422821,812.939941 349.175568,812.832275 349.084076,812.733215 
	C345.946625,803.949585 341.310181,796.439453 332.630402,790.728699 
	C334.606720,794.858887 334.335846,798.531555 334.783966,802.099548 
	C335.071442,804.388428 334.461487,806.186157 331.203369,805.276489 
	C330.919373,805.073303 330.989929,804.995972 330.961090,805.039062 
	C330.483917,804.471558 329.939484,803.947632 329.228333,803.192993 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M245.914368,688.397461 
	C246.968597,686.406616 247.099045,684.619202 247.001007,682.395142 
	C248.558624,677.912048 246.721008,674.704163 244.151550,671.332642 
	C243.477722,670.199646 243.696152,669.162537 243.165039,668.272827 
	C242.804306,669.024841 243.417023,669.694336 243.118805,670.674683 
	C241.757416,672.708130 243.358200,676.117310 239.777634,676.692383 
	C239.456406,671.643982 239.210724,666.814880 238.808731,661.998779 
	C238.671265,660.351807 238.010742,658.671326 240.798431,658.752930 
	C248.807861,660.537415 252.643082,667.780090 259.195862,671.401855 
	C258.978485,668.976501 259.779327,666.708740 261.555878,664.777405 
	C253.879257,662.388062 250.775604,655.716248 255.029343,650.518494 
	C255.603119,650.581543 255.735489,650.963013 255.845032,650.984924 
	C256.208160,651.057617 256.211182,650.879272 255.712769,650.416870 
	C255.657547,648.760010 255.988937,647.515259 257.693542,646.899170 
	C259.396698,647.496704 260.767914,647.984680 262.122772,648.514587 
	C263.003052,648.858826 263.740356,648.656982 264.282501,647.944153 
	C264.850800,647.196899 264.808990,646.324097 264.057281,645.785461 
	C259.550323,642.555725 263.895844,642.999573 265.698547,642.137573 
	C268.425110,643.890808 270.078949,646.547913 271.745605,649.116394 
	C272.880524,650.865479 274.285278,652.020996 276.313171,653.098022 
	C277.167755,654.129822 277.292664,655.301636 278.651550,655.881104 
	C280.876404,656.860229 282.940186,657.342834 285.214783,658.103333 
	C285.785767,658.398010 286.049683,658.624207 286.345093,659.129761 
	C286.447845,659.332642 286.114746,659.629333 286.321442,659.548523 
	C286.490234,659.139038 286.422394,658.816589 286.293915,658.214417 
	C286.443909,656.340271 287.392120,655.157776 288.613708,653.760010 
	C292.091980,652.406494 293.749115,653.262939 293.053864,656.910828 
	C292.700165,658.766785 293.337585,660.472534 293.534515,662.245422 
	C293.619781,661.570251 293.351379,662.418396 293.941132,662.107483 
	C298.549805,659.677979 298.549835,659.678040 300.272522,660.762634 
	C301.693176,663.646301 300.581512,665.533875 298.288818,667.378662 
	C297.338470,668.059753 296.647034,668.402527 295.546692,668.759399 
	C294.682312,668.895203 294.189453,668.877930 293.362549,668.666504 
	C291.123169,666.480957 287.025299,667.303772 286.204041,663.272583 
	C285.990936,663.013733 285.497192,662.988892 285.250183,662.979126 
	C284.631195,663.008057 284.404938,663.216919 284.136963,663.768311 
	C282.390900,664.032593 280.988342,663.862427 281.013306,661.363220 
	C279.864563,660.824219 278.386322,660.164856 277.717743,660.641418 
	C276.381165,661.594177 277.972443,662.473755 278.341187,663.455261 
	C279.370575,666.195068 277.862152,668.515259 276.689575,670.874817 
	C276.267395,671.724426 274.459564,671.250549 275.107056,673.273865 
	C276.216064,676.739441 274.411133,678.800598 271.032135,680.147827 
	C267.315033,681.629822 270.818237,684.411499 270.188721,686.775391 
	C268.600708,688.906799 266.732574,689.173706 264.822784,688.102966 
	C261.453949,686.213989 257.709381,685.919861 253.722229,685.197205 
	C252.962875,685.339722 252.493896,684.865784 252.137939,685.144531 
	C251.617966,685.551819 252.385223,685.981567 252.058548,685.400452 
	C251.731842,684.819336 253.063187,684.826660 252.790054,685.271973 
	C255.496521,687.823853 252.777634,688.873901 251.592026,689.843750 
	C249.518539,691.539612 247.372177,691.176025 245.914368,688.397461 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M300.308014,661.253540 
	C298.078064,661.307800 296.607330,660.097290 294.569641,658.837097 
	C294.647705,661.428467 297.236633,663.091797 295.354401,665.429810 
	C290.789825,663.913452 290.921844,660.142334 291.830963,656.691833 
	C292.535217,654.019043 291.620361,653.865479 289.264130,654.036865 
	C288.533722,654.362915 288.103485,654.514771 287.291382,654.327759 
	C289.242188,650.076904 292.067657,649.130920 298.209290,650.448975 
	C298.526550,647.172791 294.110016,646.656372 294.236053,643.205994 
	C296.626617,639.884399 297.065216,636.212280 298.001251,632.709595 
	C299.117340,628.533020 300.413116,628.359680 303.694916,631.279480 
	C308.405884,635.470642 311.246307,641.044617 314.435791,646.218201 
	C316.285797,649.219055 319.298279,649.865356 321.401367,652.418823 
	C321.745819,653.260010 320.681671,653.743652 321.357941,654.198242 
	C321.893188,654.557983 321.127930,654.297302 321.883057,653.876587 
	C324.246216,655.619995 326.133026,657.396240 327.823853,659.502747 
	C329.485016,661.572266 331.126587,663.935120 334.391205,664.246155 
	C339.207916,664.704956 339.708130,665.683289 338.443848,671.031311 
	C335.298523,672.848267 333.223663,671.666016 331.303833,668.986450 
	C330.701630,668.518860 330.400452,668.190918 330.039490,667.976440 
	C329.469543,667.637878 330.292297,667.937866 329.632996,668.036560 
	C329.475281,668.060181 329.614990,668.061462 330.293091,668.206909 
	C331.090546,668.845459 331.413177,669.365906 330.967712,670.342346 
	C328.956970,671.522827 327.354828,672.597595 327.359253,675.265076 
	C327.314270,676.276489 327.257477,676.951355 327.179535,677.971985 
	C327.108032,678.546265 327.067108,678.775513 326.924408,679.341675 
	C326.721985,679.892456 326.621399,680.106201 326.317322,680.612061 
	C325.949371,681.075806 325.784821,681.247559 325.333771,681.633240 
	C323.013000,682.961853 321.401825,682.038818 319.609711,680.530518 
	C314.058899,677.945190 310.185242,678.738342 307.867126,682.989990 
	C307.842743,683.577087 307.831024,683.814758 307.761658,684.410400 
	C307.181030,686.215637 306.566193,687.559631 304.356812,687.267212 
	C303.076996,683.261536 301.789948,687.143127 300.303833,686.930420 
	C299.899597,685.069946 300.144806,683.340271 301.468170,681.833069 
	C302.776001,680.343506 303.019653,678.585815 302.029114,676.409180 
	C301.837524,675.589294 301.485504,674.847839 301.711548,674.655640 
	C305.287018,671.615417 305.762238,665.714600 311.481201,664.254517 
	C313.265350,663.434692 315.458557,662.923767 315.107117,661.020630 
	C314.781403,659.256775 313.540558,657.412842 310.819550,657.536743 
	C307.144043,662.726135 306.126434,663.118591 300.308014,661.253540 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M314.267609,755.791382 
	C313.093079,754.549927 312.186188,753.075378 311.139648,751.300415 
	C309.484436,747.201538 309.169250,742.974915 306.246246,739.666931 
	C303.836456,736.939636 306.220947,734.382446 308.244904,733.645874 
	C314.306793,731.439575 312.087128,728.180420 310.111328,724.600891 
	C308.241455,721.213440 309.201233,719.227356 313.583191,718.985718 
	C315.581696,720.172974 314.321991,722.142944 315.210297,723.631226 
	C317.944153,722.125000 315.067963,717.702087 319.129578,716.724976 
	C320.349548,716.407532 321.226593,716.126343 322.482300,716.037842 
	C324.572266,716.145142 326.204865,716.097595 326.177551,713.324585 
	C326.239563,712.756287 326.284607,712.528259 326.470215,711.975037 
	C326.886505,711.259766 327.214600,710.920288 327.913879,710.472107 
	C330.597992,709.705688 332.016937,710.949036 333.057892,712.836060 
	C334.554443,715.549194 336.613586,715.471924 338.887543,713.998779 
	C340.135498,713.190247 341.256592,712.002930 342.856598,712.188782 
	C346.513275,712.613464 349.893250,713.378113 348.465057,707.366638 
	C347.870850,704.865845 350.965088,703.971863 355.209412,705.610474 
	C356.571350,707.692932 356.072723,709.638977 355.860352,711.916565 
	C356.527893,717.367737 355.714996,721.303406 349.294159,721.380371 
	C347.434601,721.402649 347.185455,723.169312 346.713684,724.612915 
	C345.974609,726.874573 344.625397,726.934143 342.985077,725.441711 
	C341.192902,723.810974 338.024902,724.173340 337.049530,721.194092 
	C336.874725,720.660278 334.992218,720.388794 334.546570,721.569336 
	C333.716797,723.767395 331.448944,726.062927 333.611053,728.432068 
	C335.362640,730.351379 335.935944,732.885315 337.440857,734.753418 
	C340.441071,738.477783 338.004333,739.869934 335.104156,740.663452 
	C332.320068,741.425110 330.568695,742.454773 331.289459,745.872864 
	C331.669586,746.284973 331.828094,746.447815 332.443726,747.139648 
	C333.033997,747.866211 333.167114,748.063721 333.472656,748.577576 
	C334.885895,751.530579 335.605438,754.056091 331.856201,755.835815 
	C325.101349,756.627930 324.011536,756.074707 323.099030,749.934998 
	C322.294891,744.524292 319.511353,739.965759 317.742889,734.867126 
	C315.647736,738.013184 315.505371,739.887024 317.008423,743.381226 
	C319.968658,750.263000 319.968658,750.263000 314.267609,755.791382 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M277.828949,760.210754 
	C276.853333,759.637024 276.226379,759.322449 275.332642,758.972473 
	C274.578491,756.140869 274.091156,753.344727 273.348938,750.271118 
	C272.552002,749.568359 272.521759,748.912048 272.216187,748.035645 
	C272.091888,747.747375 272.097870,747.419617 272.076569,747.257080 
	C269.369202,740.729492 266.631012,734.339233 265.897369,727.411255 
	C265.199219,720.818298 264.571564,714.240906 262.533478,707.455200 
	C262.243225,705.319214 263.067688,704.374268 265.165894,704.115479 
	C267.365204,704.489014 267.640015,705.776489 267.767029,707.642700 
	C270.901306,711.313293 270.994629,716.014954 273.621887,719.484619 
	C274.258270,720.325073 274.114868,722.115173 275.760468,721.668945 
	C277.524445,721.190491 277.010590,719.532593 276.867462,718.293945 
	C276.442322,714.614746 277.995605,711.573364 279.742310,708.552185 
	C280.419037,707.381714 281.250122,706.819519 282.590057,707.364258 
	C283.738678,707.831299 284.568542,708.368408 284.189056,709.933289 
	C283.793762,711.563293 283.749512,713.210205 285.554504,714.305176 
	C288.029572,715.806641 289.486328,717.646912 288.072083,720.848633 
	C287.267853,722.669312 289.143188,726.235474 286.710205,726.479492 
	C284.300537,726.721191 280.612213,728.857117 278.736603,724.822571 
	C278.459808,724.227234 277.699097,723.954956 277.006378,724.313477 
	C276.304535,724.676697 276.199951,725.332031 276.558838,725.980164 
	C278.617462,729.698120 277.380371,732.533813 275.705109,736.347046 
	C274.383698,739.354736 276.952026,744.196472 278.110626,748.129944 
	C279.083313,751.432312 280.874847,754.527039 280.031342,758.499878 
	C279.744141,759.780273 279.117767,760.299011 277.828949,760.210754 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M268.044617,707.649048 
	C267.471436,707.106079 266.953430,706.219421 266.188049,705.133911 
	C263.896301,702.711243 263.306000,699.928772 262.922363,696.723389 
	C262.954926,696.156067 262.982513,695.934021 263.076599,695.391785 
	C263.334595,694.280640 263.623444,693.503418 263.421844,692.405762 
	C261.894104,691.301575 260.688202,692.760986 259.136810,692.504211 
	C252.690567,691.437012 252.117294,692.129517 253.158127,698.813660 
	C253.545456,701.300964 255.199646,704.233154 250.501404,704.854614 
	C249.944702,704.687866 249.735962,704.579407 249.257965,704.242798 
	C248.988739,704.014648 248.971786,703.519714 248.967255,703.272095 
	C247.596466,698.510864 244.620087,694.522522 244.792358,689.290161 
	C245.124161,688.697388 245.311310,688.445679 245.781982,688.139709 
	C247.322830,689.222107 248.975906,690.863647 250.470276,689.221497 
	C251.189911,688.430664 254.564056,687.976562 252.147049,685.230713 
	C256.366119,682.960449 260.422485,684.584106 264.218018,686.440063 
	C266.016174,687.319397 267.455994,687.909607 269.643982,687.040527 
	C269.610260,690.726562 274.704926,692.352112 273.571930,696.564270 
	C273.293701,697.598755 275.136292,697.868958 276.363525,697.382141 
	C277.975342,696.742798 278.710449,697.704590 279.456329,698.970886 
	C280.939697,701.489319 278.697144,702.881531 277.907562,704.720398 
	C277.297180,706.141846 275.895294,706.336975 275.039886,705.677307 
	C271.918182,703.270081 270.039093,705.207947 268.044617,707.649048 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M300.051605,773.716309 
	C298.655457,773.434509 296.958405,777.069824 296.367065,773.548096 
	C295.754242,769.898254 293.960968,773.828308 292.681519,772.903381 
	C291.293640,772.768921 291.604980,771.905640 291.505920,770.892944 
	C291.136200,768.926453 290.767273,767.319885 290.308594,765.383545 
	C288.331207,763.889954 289.069458,760.921509 286.338745,760.055420 
	C287.869293,755.487732 286.543640,751.374146 284.701691,747.203796 
	C283.551910,744.600403 282.446960,741.532959 287.792969,742.024292 
	C289.381531,741.459839 290.782837,741.945679 291.028931,742.985901 
	C292.007355,747.121338 295.516052,749.781067 296.931458,753.988403 
	C297.382812,759.912598 297.952423,765.459717 300.021942,770.703308 
	C300.403961,771.671204 300.813873,772.501221 300.051605,773.716309 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M312.186890,664.721558 
	C309.240967,666.409851 306.112976,667.384766 306.858826,671.592834 
	C306.975159,672.249146 306.733582,673.632935 305.088959,673.039185 
	C302.505463,672.106506 302.717010,674.258118 302.180542,675.898193 
	C301.544037,676.042297 301.103851,676.022827 300.333069,676.000488 
	C297.796143,674.484192 296.639252,676.078125 295.229309,677.773926 
	C294.689545,678.469727 294.357880,678.930969 293.997620,679.688354 
	C292.306000,681.521301 290.568665,682.330688 288.278259,680.278687 
	C287.993561,679.572632 287.995911,679.124207 288.002869,678.339539 
	C287.999817,677.353088 287.806671,676.694519 287.985016,676.054565 
	C288.828857,673.026794 292.361816,671.400146 292.626953,667.935181 
	C293.457458,668.004517 293.918457,668.011475 294.725098,668.027832 
	C295.879486,668.674438 296.505096,668.534241 296.968018,667.287415 
	C298.618011,665.596619 300.527191,664.378662 300.005554,661.385132 
	C303.890320,660.995056 303.890320,660.995056 310.559509,656.008789 
	C313.067322,655.727112 312.968872,657.383667 312.823090,658.534485 
	C312.577484,660.473755 312.931061,662.452515 312.186890,664.721558 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M307.214325,684.265015 
	C307.072083,683.959351 307.049072,683.484802 307.059509,683.247437 
	C307.813446,681.046265 309.606445,680.929932 311.171204,681.106506 
	C314.224640,681.450928 316.893829,680.484741 319.897461,679.395752 
	C321.498932,680.379639 322.327301,682.330994 324.719299,681.149292 
	C325.427185,681.079407 325.650055,680.879211 325.867126,680.297363 
	C326.050415,680.126648 326.089355,680.081665 326.111542,680.061890 
	C326.133728,680.042114 326.521759,679.739136 326.521759,679.739136 
	C326.521759,679.739136 326.422394,679.256958 326.205902,679.139465 
	C326.014709,678.291260 326.040009,677.560486 326.133850,676.468506 
	C329.660736,674.857544 334.336243,676.384583 336.702454,679.903503 
	C336.567963,681.942932 334.394775,680.438599 333.982697,682.167603 
	C332.465973,684.499146 328.561829,682.370972 328.012360,686.239319 
	C327.721313,686.975281 327.513947,687.375366 327.100189,688.043762 
	C326.554535,688.884155 326.163544,689.425415 325.583923,690.246704 
	C325.069885,690.845337 324.721527,691.128479 324.029907,691.500793 
	C322.344849,691.917908 321.095459,691.481384 319.599274,690.820312 
	C318.867737,690.549377 319.041779,689.657959 318.215057,690.049927 
	C317.941681,690.364563 317.779602,690.451355 317.330536,690.588379 
	C315.664856,690.592041 314.720154,689.743225 313.558228,688.659668 
	C312.414856,687.307312 311.421234,687.134949 310.040283,688.355591 
	C309.314697,688.614563 308.878723,688.601013 308.173798,688.287354 
	C307.903351,688.085449 307.541046,687.945923 307.355469,687.888977 
	C306.516541,686.703918 307.253754,685.655457 307.214325,684.265015 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M327.119812,711.557129 
	C327.037628,712.077393 327.028564,712.263428 327.008972,712.728638 
	C325.888062,714.256409 324.777222,715.504517 323.256927,716.789978 
	C322.206726,717.244629 321.393738,717.493164 320.283447,717.868408 
	C318.379181,716.703918 316.377167,716.255859 314.286957,715.225220 
	C313.603882,715.010803 313.178680,715.016663 312.434601,715.026733 
	C311.853516,714.798523 311.597412,714.559631 311.162079,714.128113 
	C310.802521,713.400269 310.628387,712.858521 310.215332,712.149780 
	C309.867767,711.813354 309.759125,711.643799 309.826050,711.228455 
	C309.667725,708.924744 309.333832,706.866882 308.997009,704.412109 
	C311.009491,702.602356 313.273376,705.471802 315.600281,703.564209 
	C316.166840,703.472717 316.398224,703.468445 316.966248,703.546326 
	C317.915192,703.873535 318.539001,704.487366 319.110291,704.371033 
	C324.758270,703.221252 326.316132,706.801453 327.119812,711.557129 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M296.145081,754.297424 
	C292.783783,750.511047 291.372772,745.963501 288.365417,742.129822 
	C288.083557,741.560486 288.065887,741.115356 288.042450,740.336365 
	C287.989075,739.126831 288.024963,738.232117 287.228180,737.238770 
	C287.067932,736.073059 287.385071,735.319641 288.709717,735.012939 
	C289.189362,735.107788 289.345947,735.244019 289.742554,735.182007 
	C289.982605,734.983704 290.000000,735.000000 289.991913,734.991577 
	C290.989319,734.064758 290.786926,732.255920 292.694336,731.852417 
	C293.878906,734.386780 293.529510,737.255798 295.816406,739.545654 
	C297.084320,744.306702 299.872894,747.710388 303.019531,751.315796 
	C303.358795,754.663269 303.337036,757.567322 300.019989,759.514526 
	C297.133545,758.861389 296.379639,756.991272 296.145081,754.297424 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M307.754028,688.413696 
	C308.397705,688.046753 308.892029,688.008057 309.757141,687.949341 
	C311.129517,687.366699 312.174438,687.520508 313.534332,687.803101 
	C314.970337,688.550598 316.095490,689.237061 317.553101,689.958313 
	C320.720947,691.434509 321.386292,693.593018 319.792419,696.722534 
	C317.177429,698.205811 314.075226,697.507019 311.783081,699.980835 
	C310.554657,700.514221 309.629883,700.760437 308.364044,700.229004 
	C304.950226,697.561035 304.783752,693.660706 307.754028,688.413696 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M292.959900,667.659790 
	C291.505493,671.184570 290.014526,674.371521 288.265503,677.780884 
	C284.723206,673.626038 284.609314,668.800964 285.993347,663.388794 
	C286.936066,666.647278 291.999634,663.653870 292.959900,667.659790 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M298.680969,759.420288 
	C302.372131,757.764404 302.021240,754.733887 302.039337,751.436401 
	C302.647339,750.178894 303.403198,749.607361 304.828857,749.726746 
	C305.460297,750.016663 305.654633,750.251282 305.841858,750.780151 
	C305.664734,753.716370 305.400513,756.436462 305.072998,759.576538 
	C305.193481,761.059692 305.160400,762.111023 304.714294,763.440552 
	C304.457214,763.899109 304.324890,764.063904 303.910858,764.369385 
	C303.207306,764.575073 302.791504,764.552063 302.110657,764.269043 
	C299.795898,763.288391 295.580566,764.122314 298.680969,759.420288 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M314.046021,715.303101 
	C316.553375,714.357849 318.381134,715.461182 319.942993,717.668152 
	C319.198792,718.251953 317.342407,717.627563 317.872925,718.925049 
	C318.487396,720.427979 317.538239,721.413025 317.656158,722.487244 
	C317.944855,725.117554 316.283051,725.981628 314.538330,725.711487 
	C312.797485,725.441895 313.361115,723.449219 313.507935,722.078674 
	C313.594055,721.274292 313.822754,720.485168 313.977600,719.335083 
	C314.756134,717.875671 314.412384,716.738525 314.046021,715.303101 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M305.165436,749.491455 
	C304.353210,750.209167 303.534698,750.558228 302.383362,750.964417 
	C300.184875,749.006226 298.210266,747.078247 296.495667,744.941711 
	C295.515045,743.719727 294.633606,742.204895 295.903931,740.182556 
	C296.799072,740.379517 298.060242,740.744629 298.329834,741.475525 
	C299.618500,744.968689 302.092041,747.282410 305.165436,749.491455 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M307.947571,700.277527 
	C308.895142,699.743286 309.705475,699.493530 310.822571,699.142334 
	C312.382141,699.559143 313.196289,700.828613 314.878418,700.696045 
	C316.598480,700.560486 316.843323,701.864563 316.246704,703.556274 
	C314.034393,707.411133 311.832184,705.520630 309.320374,704.039001 
	C307.430084,703.265076 306.700226,702.228882 307.947571,700.277527 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M293.028809,731.517822 
	C292.204407,732.874817 291.381958,733.802124 290.271667,734.856323 
	C289.992279,732.868103 289.945770,730.750854 290.030365,728.638855 
	C290.078888,727.426514 289.566956,725.561096 291.307617,725.415710 
	C293.666718,725.218689 292.790710,727.383484 292.993988,728.651489 
	C293.121216,729.445251 293.026093,730.274719 293.028809,731.517822 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M291.805908,769.744324 
	C292.168060,770.649170 292.199097,771.398132 292.301605,772.529175 
	C292.607361,774.366394 292.841644,775.821472 293.005219,777.649170 
	C292.292725,778.045288 291.650970,778.068726 290.687317,778.085693 
	C291.089020,775.705261 290.888062,773.269470 290.863159,770.428040 
	C291.076599,769.870483 291.275696,769.729492 291.805908,769.744324 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M288.037445,680.367065 
	C289.753693,680.343079 291.512115,681.134949 293.627930,680.026001 
	C295.395538,681.642273 294.102814,681.847595 292.859192,682.004700 
	C291.102203,682.226685 288.928680,683.891785 288.037445,680.367065 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M277.499542,760.288940 
	C278.090973,759.632935 278.701691,759.314270 279.650238,758.916443 
	C282.159515,759.210693 282.667633,760.938354 281.841492,762.376343 
	C280.844879,764.110840 280.237671,760.058350 278.397217,761.806458 
	C277.862396,761.454224 277.690582,761.040222 277.499542,760.288940 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M295.361206,677.966858 
	C294.586090,673.355042 297.417267,674.684814 299.944672,675.674927 
	C298.220581,675.863281 297.298218,677.646912 295.361206,677.966858 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M306.926941,684.259155 
	C308.097931,684.973145 308.026825,686.099365 307.291168,687.556641 
	C306.135620,688.151550 305.034851,689.735046 304.083771,687.370605 
	C305.656128,686.997803 306.133972,685.685608 306.926941,684.259155 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M292.095215,769.557983 
	C291.924744,769.891968 291.712463,769.883789 291.188843,769.943604 
	C290.074371,768.712708 289.706848,767.328003 290.100372,765.435730 
	C291.158783,766.279968 292.116302,767.514221 292.095215,769.557983 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M301.618347,732.358643 
	C301.670471,731.462769 301.936829,730.750427 302.203186,730.038147 
	C302.434906,730.129639 302.862885,730.212158 302.867554,730.314026 
	C302.908966,731.219116 303.230286,732.297302 301.618347,732.358643 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M286.997253,737.341980 
	C288.582733,737.234009 289.299835,737.973938 288.275848,739.797913 
	C287.116760,739.485657 287.006531,738.606140 286.997253,737.341980 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M296.446045,685.627014 
	C296.664978,686.403198 296.394409,686.685059 295.791016,686.564697 
	C295.665588,686.539673 295.496674,686.362854 295.477661,686.236450 
	C295.386169,685.627686 295.682281,685.372559 296.446045,685.627014 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M273.578827,708.044556 
	C273.192688,708.752441 272.803375,708.759094 272.458862,708.255737 
	C272.386658,708.150269 272.389038,707.905640 272.463776,707.802612 
	C272.821960,707.309448 273.210968,707.327820 273.578827,708.044556 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M283.691040,737.932739 
	C283.722412,738.479004 283.596008,738.866089 283.469604,739.253174 
	C283.364105,739.147217 283.153381,739.017761 283.170868,738.939148 
	C283.258972,738.543335 283.406586,738.160767 283.691040,737.932739 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M300.023132,687.023193 
	C299.877838,687.482971 299.702576,687.912537 299.527344,688.342102 
	C299.255524,688.213318 298.835938,688.152588 298.739441,687.943054 
	C298.444336,687.302307 298.961578,687.138855 299.689758,686.971313 
	C299.933014,686.932617 299.993134,686.993042 300.023132,687.023193 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M284.197205,663.862793 
	C284.134491,663.496033 283.751831,662.717224 284.848999,662.828491 
	C284.894745,663.295166 284.701172,663.558289 284.197205,663.862793 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M304.052490,735.131409 
	C304.118378,735.132446 304.232758,735.269775 304.347107,735.407104 
	C304.265076,735.360657 304.183044,735.314148 304.052490,735.131409 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M289.745148,734.981079 
	C289.813110,735.326233 289.658508,735.704590 289.108765,735.143494 
	C289.032745,734.971497 289.507690,734.978455 289.745148,734.981079 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M303.997559,735.002441 
	C303.880676,734.868469 303.766235,734.731995 303.651825,734.595520 
	C303.733215,734.641846 303.814606,734.688232 303.950012,734.864868 
	C304.003998,734.995117 304.000000,735.000000 303.997559,735.002441 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M286.006561,760.008789 
	C285.914978,760.044250 285.815704,760.069946 285.847900,760.039307 
	C285.979401,759.982910 285.998871,759.999084 286.006561,760.008789 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M309.844177,711.075562 
	C309.999908,711.149536 309.998260,711.316406 309.986481,711.733032 
	C309.817474,711.814392 309.641693,711.657349 309.512848,711.468323 
	C309.484741,711.427063 309.624359,711.271423 309.844177,711.075562 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M311.082001,714.199097 
	C311.264496,714.149658 311.552338,714.357300 311.977936,714.797913 
	C311.806244,714.839294 311.496796,714.647705 311.082001,714.199097 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M209.077209,579.642578 
	C209.209518,574.976746 206.930923,571.785400 205.300140,568.043457 
	C202.976105,565.633240 203.465256,563.180237 204.392273,560.356445 
	C207.858459,556.927673 207.551254,552.784302 207.458771,548.912720 
	C207.374344,545.378174 210.232193,540.152466 213.858597,539.244141 
	C214.435135,539.525269 214.688095,539.709473 214.921448,539.917725 
	C215.155640,540.126648 215.411636,540.302856 215.698715,540.435974 
	C215.903778,539.762939 215.535980,540.335754 215.437897,540.300964 
	C215.298141,540.251465 215.187469,540.119751 214.821228,539.800171 
	C213.115784,536.901428 209.622238,541.567322 208.699554,538.105286 
	C208.117386,535.920959 210.723541,534.532959 211.218292,532.327515 
	C212.132477,528.252319 211.671921,523.478882 216.341339,520.657471 
	C217.180161,520.511047 217.646927,520.624634 218.367050,521.096008 
	C219.111084,521.929565 219.401138,522.599182 219.599945,523.682495 
	C219.561356,525.690186 219.627625,527.411926 219.220459,528.971375 
	C218.446793,531.934570 220.127457,533.148682 222.465225,534.516479 
	C222.372086,538.483887 223.370972,541.992554 223.980209,545.537659 
	C224.203476,546.836853 225.022293,548.071228 223.787598,549.220886 
	C220.989014,551.826599 223.121002,553.186523 225.282806,553.735352 
	C229.939514,554.917786 230.149323,558.264587 229.637878,561.887939 
	C228.915024,567.009277 229.449509,571.876953 232.547897,576.056763 
	C234.934402,579.276123 231.316437,580.755859 230.849442,583.605164 
	C237.315948,583.095215 239.587830,587.868652 242.018021,592.382141 
	C243.200470,594.578186 244.487152,596.714111 245.631439,598.935425 
	C246.821442,601.245483 248.383743,602.533630 251.192596,600.560547 
	C253.742004,598.769653 255.903351,599.463867 256.822510,603.023193 
	C256.779297,604.294739 255.760086,605.170471 256.853455,606.381226 
	C257.215210,606.761658 257.349121,606.929749 257.604248,607.404907 
	C258.008179,609.824341 256.842468,610.741516 254.954147,611.001160 
	C253.476013,611.204529 251.887558,611.187622 251.609558,613.548828 
	C251.373444,615.816711 249.148651,617.605469 251.350342,619.944702 
	C251.859589,620.530273 252.055069,620.923462 252.076187,621.673096 
	C250.914871,621.995117 249.856018,621.997559 248.400116,622.000000 
	C245.884674,622.432312 244.535553,620.835876 244.548065,619.432007 
	C244.584900,615.299866 241.812393,615.712402 239.285919,615.690430 
	C236.035324,615.662109 232.780792,615.919495 229.276749,615.227112 
	C227.501541,613.649597 227.740097,611.900269 228.391891,610.405151 
	C229.776596,607.228882 224.417252,608.333984 225.389679,605.164734 
	C226.312195,602.158081 229.925125,604.038574 230.773224,601.606812 
	C231.207031,600.362854 231.212570,598.997253 230.537796,597.748108 
	C228.932098,597.227966 229.225601,598.776367 228.660233,599.396667 
	C228.241135,599.856567 227.640930,600.505310 227.155594,600.477234 
	C226.141800,600.418762 225.819016,599.370422 226.060638,598.607605 
	C227.802475,593.108643 222.229691,590.646423 220.905670,586.312439 
	C219.615906,582.090698 215.643509,578.498657 221.269180,574.632263 
	C222.186111,574.002075 222.147079,572.588257 220.924194,572.270935 
	C219.058807,571.786743 218.884430,568.405457 216.278152,569.588074 
	C214.857742,570.232483 213.904053,571.401367 214.213745,573.169373 
	C214.696320,575.924438 215.140045,578.686951 215.673203,581.432190 
	C216.059219,583.419739 216.286835,585.368347 215.163162,587.624634 
	C213.401733,588.478638 213.183777,587.005554 212.302155,585.927002 
	C211.163864,583.837769 209.811600,582.262634 209.077209,579.642578 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M229.014526,615.377075 
	C234.045349,613.424683 239.207092,614.759521 244.300018,614.405762 
	C245.253326,614.339600 247.469559,615.107910 246.027954,616.816162 
	C243.784653,619.474304 246.797913,620.089294 247.732849,621.802490 
	C246.068893,626.330811 248.690399,630.444702 249.032135,634.600647 
	C249.672363,642.387390 248.575027,644.835144 240.915680,645.007568 
	C240.091553,645.026062 239.268661,645.098877 237.986389,645.219482 
	C237.020721,645.910461 236.558243,646.542358 236.929810,647.703247 
	C237.093658,653.883911 235.331589,654.971008 228.310547,653.078613 
	C227.733231,653.150208 227.549057,653.252258 227.077545,653.224976 
	C226.548767,652.756836 226.380508,652.386230 226.283585,651.679993 
	C226.276855,650.815552 226.304733,650.245850 225.998444,649.439514 
	C225.375580,648.340637 224.742249,647.557434 224.835739,646.263733 
	C226.641434,644.304993 228.280533,646.397705 230.012634,646.021973 
	C231.692261,645.657715 231.905228,644.487976 232.349426,643.301819 
	C232.573029,642.704773 232.825851,641.705627 232.177536,641.707947 
	C231.360092,641.710938 230.545334,642.459290 229.421326,642.937622 
	C228.477432,642.812683 227.957428,642.410645 227.070984,642.040039 
	C224.456314,642.105713 222.693100,641.847839 221.781189,639.059082 
	C220.972931,636.587463 223.089508,636.867493 223.673599,635.871521 
	C225.370041,632.978638 227.129898,630.602173 229.973755,628.553894 
	C231.436478,627.500366 230.097565,623.999634 229.154877,621.345154 
	C228.963043,620.200073 228.996765,619.400085 229.000931,618.300049 
	C229.005569,617.250610 229.011139,616.501160 229.014526,615.377075 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M257.973450,646.621582 
	C257.440826,648.124451 256.849274,649.257874 256.129639,650.694458 
	C252.265533,652.375977 254.022568,655.363220 254.789886,657.214111 
	C255.797195,659.643860 256.185120,663.418396 260.405243,662.935303 
	C261.688538,662.788452 263.206116,662.016052 263.940063,663.638977 
	C264.825012,665.595764 262.471558,665.299072 261.824829,666.233582 
	C260.651062,667.929504 259.956970,669.646545 260.735168,671.784790 
	C261.242340,673.178284 260.279175,674.016785 259.018127,674.298035 
	C257.681732,674.596069 257.762115,673.114929 257.183380,672.650879 
	C254.965271,670.872192 254.426743,666.524048 250.123795,667.766907 
	C249.493195,667.949036 248.512894,667.869263 248.621384,667.023560 
	C249.372360,661.170227 243.544586,662.071960 241.035751,659.266968 
	C241.975647,658.978821 242.951218,658.978821 244.909592,658.978821 
	C241.391022,656.452881 239.927277,654.655273 244.413757,652.540161 
	C246.557144,651.529724 248.220947,648.949158 249.473999,646.725769 
	C251.639679,642.882996 254.337189,642.587402 257.973450,646.621582 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M286.718750,653.810303 
	C287.424286,654.021179 287.850159,654.030396 288.593842,654.045288 
	C289.217041,655.903015 287.231110,656.752625 286.659973,658.701904 
	C283.510223,659.298645 281.006104,658.362305 278.197083,657.036011 
	C276.860687,656.423340 276.801636,655.438660 276.290161,654.326294 
	C270.249420,652.367371 268.834351,646.895081 266.033691,642.243408 
	C267.016357,640.871338 265.320740,640.992188 265.110260,640.203369 
	C267.719940,634.474304 270.019806,633.564392 275.182129,635.934265 
	C277.569092,637.030029 280.100250,637.884277 282.005737,640.335205 
	C282.294037,640.798157 282.385284,640.990417 282.530640,641.479492 
	C282.804504,642.259949 281.680237,642.431091 282.544189,642.884033 
	C283.176086,642.837585 283.520203,643.006226 284.042358,643.479614 
	C284.335663,643.918701 284.420654,644.118896 284.558655,644.647461 
	C285.342957,647.827454 282.995880,651.330872 286.718750,653.810303 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M212.708588,584.790527 
	C213.490097,585.875183 213.992905,586.750427 214.748901,587.806641 
	C215.122879,590.844849 214.725616,593.397339 216.393051,596.465637 
	C217.798981,599.052734 214.471039,602.696228 211.269165,604.943909 
	C210.498550,604.524353 209.887375,604.681091 209.232773,604.246826 
	C207.816040,603.502686 207.165283,602.503906 206.738892,600.881226 
	C206.048584,600.036499 205.747559,599.412476 205.837860,598.331909 
	C206.453262,597.981018 206.932236,597.961426 207.769547,597.910461 
	C208.401535,597.615540 208.724747,597.463318 209.360382,597.538086 
	C209.791687,597.821411 209.980225,597.998352 210.180298,598.162476 
	C210.400558,598.343018 210.343185,598.290649 209.822861,597.820557 
	C209.264191,597.255371 208.889191,596.876770 208.322647,596.307373 
	C204.203827,593.323486 208.196213,592.171753 209.476166,590.788269 
	C209.933731,590.293701 211.486954,590.335388 211.514847,589.210266 
	C211.550598,587.769104 210.184494,587.977417 209.278793,587.994263 
	C206.738419,588.041321 207.280762,585.789185 206.274841,584.247070 
	C205.615265,582.901978 205.131195,581.816956 205.736359,580.324219 
	C206.510193,579.150696 207.404510,579.007996 208.702347,578.976013 
	C209.051834,578.962708 209.023666,578.987183 209.023560,578.976929 
	C209.941360,580.970093 209.610382,583.731323 212.708588,584.790527 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M282.433502,642.226746 
	C279.767181,638.425537 275.305603,638.162720 272.210541,635.737549 
	C271.009918,634.796753 269.970276,635.389160 269.320892,636.515625 
	C268.418610,638.080688 266.711914,638.488037 265.205963,639.738281 
	C261.382080,636.607605 261.375366,632.313232 264.986115,626.382324 
	C265.180206,625.900024 265.361664,625.802490 265.844360,625.769165 
	C266.792969,626.316162 267.143433,627.077393 268.058350,627.672791 
	C271.016907,627.365295 272.499115,630.093628 275.293640,630.358337 
	C280.951416,632.195374 282.911530,636.664734 284.347412,641.773193 
	C283.759735,642.446289 283.282501,642.550598 282.433502,642.226746 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M250.789948,614.293213 
	C249.031616,610.975464 250.705399,609.818787 253.457275,609.800903 
	C255.237732,609.789368 256.872864,610.278137 256.992065,607.364624 
	C257.220398,606.906555 257.440796,606.813049 257.998474,606.844299 
	C260.060852,608.874390 260.237457,610.724731 257.829895,612.616638 
	C256.755035,613.319336 255.948380,613.200073 254.888550,612.732910 
	C254.343536,612.637451 254.506927,612.045471 254.336151,612.374146 
	C255.368973,613.074280 256.207764,613.490906 257.499146,612.914917 
	C258.641296,613.368469 258.814484,614.174133 258.801880,615.368103 
	C258.815308,616.454163 257.468658,617.232666 259.070190,618.080261 
	C259.966736,618.390625 260.503296,618.731995 261.236694,619.385803 
	C261.900269,620.180298 262.213226,620.793640 262.559082,621.778198 
	C262.689728,622.587830 262.632599,623.046143 262.218628,623.738281 
	C260.733429,625.129578 260.037689,627.424377 257.298065,626.019836 
	C256.826599,626.169373 256.666016,626.346008 256.270996,626.251953 
	C254.970840,624.572021 252.319000,625.069275 252.021255,622.336304 
	C251.890457,621.672363 251.717133,621.412048 251.242859,621.100586 
	C249.834488,618.930481 250.173721,616.760742 250.789948,614.293213 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M211.243164,641.719849 
	C210.195587,641.269592 209.305222,640.838623 208.208801,640.174316 
	C206.954788,637.858887 207.913422,635.153992 205.272308,633.428345 
	C212.202866,632.672485 212.003372,632.731201 210.095505,626.247498 
	C209.590546,624.531494 207.572708,623.480408 208.239502,621.025391 
	C209.203079,620.584045 209.896988,621.065002 210.803802,621.769348 
	C211.424637,622.226746 211.688019,622.839539 212.600891,622.355225 
	C213.474976,622.164734 214.044022,622.250671 214.779022,622.800659 
	C215.833267,625.405762 212.495468,628.719238 216.795578,630.546021 
	C217.394104,630.800232 215.533295,632.924316 214.369781,634.236572 
	C211.965958,636.410767 213.758698,639.377563 212.009674,641.728943 
	C211.689682,641.867981 211.400360,641.739075 211.243164,641.719849 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M262.249695,624.117004 
	C261.970642,623.547302 261.968170,623.107239 261.959595,622.337036 
	C262.136383,621.819275 262.319183,621.631653 262.859253,621.279663 
	C267.812836,621.207764 272.639893,620.511292 275.563080,625.500916 
	C276.591644,627.256592 277.116150,628.799927 275.346191,630.719727 
	C273.502655,631.942505 272.308350,631.845459 271.417725,630.052856 
	C270.733032,628.674805 269.654083,628.330200 268.161346,629.158569 
	C267.128937,628.357483 267.056946,627.224182 266.205139,626.220825 
	C265.987793,626.007324 265.493286,626.002319 265.246033,626.000000 
	C264.174622,625.413940 263.350464,624.830261 262.249695,624.117004 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M227.817642,655.819885 
	C226.396729,659.191162 223.261871,661.210571 222.172256,664.878113 
	C221.593948,666.824646 218.543167,665.556396 216.365982,667.709106 
	C216.835922,663.455994 215.983551,659.988892 220.872955,659.694153 
	C222.062714,659.622498 221.944458,658.215942 221.591751,657.242249 
	C221.024796,655.677185 221.754639,655.111633 223.532654,654.986572 
	C225.111160,655.255737 226.431610,654.990723 227.817642,655.819885 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M211.213654,623.193970 
	C210.280930,622.326294 209.552765,621.626709 208.557068,620.779907 
	C207.864761,619.325256 207.439972,618.017700 207.025482,616.355042 
	C207.196487,615.813416 207.357193,615.626892 207.806152,615.671204 
	C209.492767,615.630188 210.891144,615.358337 212.640564,615.040100 
	C212.143570,617.828796 214.807205,619.697205 214.990799,622.632019 
	C214.227219,623.070679 213.461594,623.126282 212.311707,623.199402 
	C211.757736,623.265381 211.587982,623.313721 211.213654,623.193970 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M207.834686,612.201538 
	C208.402863,610.826355 208.715851,609.757507 209.016296,608.349854 
	C208.844086,607.503662 208.980377,607.094910 209.758636,606.899841 
	C211.649368,607.124573 213.754089,606.430725 214.333740,608.387329 
	C215.033737,610.750305 212.038223,609.416077 211.470047,610.660400 
	C210.849869,612.018616 210.124725,613.221802 208.291290,613.870483 
	C207.619019,613.556885 207.492889,613.059387 207.834686,612.201538 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M224.674057,646.059937 
	C225.577393,646.821106 226.160538,647.635071 226.866760,648.724487 
	C226.992523,649.444885 226.994507,649.889832 226.996262,650.668457 
	C227.004669,651.455505 227.012527,651.908936 227.007446,652.702148 
	C227.089462,654.827148 225.889786,654.599243 224.412430,654.246582 
	C222.877777,652.470642 222.945496,650.615845 224.674057,646.059937 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M212.056519,641.861328 
	C212.036728,639.293579 210.406265,636.289551 213.682434,634.311523 
	C213.938141,636.944275 216.638779,640.137512 212.056519,641.861328 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M210.126495,606.723816 
	C209.814560,607.193542 209.524445,607.372192 209.119049,607.781006 
	C206.937805,607.941528 204.871826,607.871887 202.531403,607.553223 
	C204.399338,606.410828 206.541733,605.517456 208.872070,604.329590 
	C209.599625,604.169128 210.139267,604.302979 210.791763,604.703125 
	C210.652527,605.457214 210.400421,605.945007 210.126495,606.723816 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M243.246063,670.987549 
	C242.924698,670.826172 242.861572,670.676453 242.778580,670.538635 
	C241.988754,669.226990 240.346848,668.156860 241.501053,666.285095 
	C241.870850,665.685364 242.516037,665.402954 243.138992,665.827026 
	C244.845596,666.989136 245.417297,668.488281 244.124115,670.700806 
	C243.993988,671.019775 243.495041,671.004761 243.246063,670.987549 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M224.038757,654.255493 
	C224.912567,653.864075 225.759796,653.714355 226.800751,653.303284 
	C226.994492,653.041931 227.456787,653.037476 227.687088,653.042847 
	C228.071487,653.922607 228.225586,654.796936 228.200974,655.867554 
	C226.732071,655.476501 225.092697,658.033997 223.943527,655.291809 
	C223.881485,654.965759 224.012146,654.497253 224.038757,654.255493 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M207.681305,611.931152 
	C208.013474,612.360535 207.937088,612.825928 207.909302,613.645874 
	C207.957932,614.000427 207.787109,614.312378 207.814178,614.488159 
	C207.685349,614.934570 207.529419,615.205200 206.979034,615.223022 
	C205.698456,614.229126 204.228012,614.114197 204.027374,612.307495 
	C205.090805,611.992859 206.181793,611.980042 207.681305,611.931152 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M237.213470,647.763062 
	C236.053650,647.663635 235.017731,647.312683 235.035843,646.115234 
	C235.054398,644.888000 236.061447,645.127869 237.195267,645.233398 
	C237.960968,646.060303 238.257034,646.822144 237.213470,647.763062 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M258.688141,635.456787 
	C256.866241,635.438843 256.772675,634.458740 257.455597,633.586426 
	C258.160431,632.686218 259.358673,632.930847 259.995117,633.735474 
	C260.872223,634.844177 260.247040,635.447449 258.688141,635.456787 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M228.762909,618.161804 
	C230.009933,618.716248 230.182617,619.587952 229.245605,620.810486 
	C226.379715,620.548828 228.069824,619.325867 228.762909,618.161804 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M226.774551,642.332031 
	C227.666687,641.134888 228.493942,640.711121 228.998169,642.701904 
	C228.091507,644.805664 227.397583,644.100403 226.774551,642.332031 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M212.146240,648.365967 
	C212.826279,648.824890 213.480591,649.253723 213.566986,649.776489 
	C213.664001,650.363525 212.956512,650.367981 212.535416,650.134705 
	C211.856735,649.758972 210.869797,649.420166 212.146240,648.365967 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M256.027466,626.305176 
	C256.036621,625.981140 256.512085,625.988098 256.749634,625.990356 
	C257.142700,626.842834 258.448334,627.948486 257.031860,628.451721 
	C256.121063,628.775269 256.089691,627.423279 256.027466,626.305176 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M214.417877,670.953796 
	C214.805191,670.245422 215.195847,670.236694 215.541565,670.741882 
	C215.613922,670.847595 215.611206,671.092957 215.536133,671.196228 
	C215.177094,671.690002 214.786896,671.673218 214.417877,670.953796 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M221.540649,649.476685 
	C221.566315,649.523254 221.514999,649.430115 221.540649,649.476685 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M215.468323,668.451416 
	C215.523087,668.418823 215.413559,668.483948 215.468323,668.451416 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M217.587646,600.429932 
	C217.652878,600.522583 217.404907,600.563965 217.404907,600.563965 
	C217.404907,600.563965 217.522400,600.337280 217.587646,600.429932 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M213.003296,615.004150 
	C213.091171,614.970703 213.175766,614.933044 213.125992,614.944580 
	C212.991623,614.993713 213.000000,615.000000 213.003296,615.004150 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M206.610657,615.265503 
	C206.794556,614.921387 207.004547,614.872620 207.527893,614.743835 
	C207.884628,614.870300 207.928009,615.076782 208.032898,615.592651 
	C207.918610,615.925659 207.742844,615.949219 207.301437,615.986328 
	C206.902756,615.853516 206.769745,615.707214 206.610657,615.265503 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M432.295929,803.221802 
	C432.453827,801.210022 430.530823,799.542847 431.871277,797.393799 
	C433.220459,795.635681 434.769531,796.666260 436.449097,796.484070 
	C437.129791,796.388733 437.491211,796.257996 438.047607,795.848267 
	C438.407257,795.489746 438.542633,795.332153 438.871521,794.919189 
	C440.730072,792.132446 442.510040,790.849426 446.054779,791.586609 
	C449.017365,792.202576 452.229462,791.758545 453.431091,787.225342 
	C454.883484,781.746216 456.412415,781.604492 461.252777,784.842834 
	C463.351501,786.247131 465.626801,786.709106 467.762665,785.212585 
	C470.272675,783.454041 472.989563,783.343018 476.195557,783.512329 
	C482.142334,782.920776 487.636505,781.626221 493.292267,781.572021 
	C496.711670,781.539307 499.883362,781.653748 502.112000,784.864868 
	C503.196411,786.427307 504.911896,787.439880 506.875000,786.633118 
	C511.341248,784.797729 516.275024,784.596985 521.004700,782.417603 
	C524.715698,781.480347 528.062927,780.610229 531.292603,778.513062 
	C532.202515,778.280701 532.822754,778.250000 533.678711,777.946289 
	C534.113892,777.735657 534.284912,777.645996 534.711243,777.421082 
	C535.307861,777.112122 535.651123,776.942749 536.249695,776.644531 
	C536.670654,776.421082 536.838867,776.331421 537.251465,776.098267 
	C537.999390,775.645081 538.363525,775.218811 538.670410,774.354858 
	C539.191589,772.510498 540.005005,771.237488 541.639893,770.911682 
	C546.892761,769.864868 551.582642,767.189514 556.715454,765.793457 
	C558.595093,765.282288 560.598572,764.857422 561.659302,766.718140 
	C562.994812,769.061035 560.532593,769.795410 559.177551,770.761414 
	C555.121094,773.653259 550.571716,775.971436 546.978027,779.362793 
	C543.014343,783.103638 538.120300,784.088196 533.514771,786.193420 
	C526.338074,789.473877 518.681091,791.893066 511.640808,796.357117 
	C510.108551,797.412231 508.732635,798.202576 507.278137,798.161682 
	C500.931396,797.983154 495.638397,801.054993 489.777557,803.570923 
	C487.956024,803.994812 486.653503,803.447571 485.050629,802.718689 
	C484.306061,802.475220 483.867340,802.386169 483.094391,802.259277 
	C482.328247,802.102478 481.902771,801.969666 481.191254,801.651550 
	C480.352875,801.119934 479.890411,800.644958 479.097900,800.061157 
	C475.413544,799.241028 472.759216,800.643066 470.456970,802.977112 
	C469.277313,804.172913 467.856506,805.784973 466.247711,804.977844 
	C462.650055,803.172852 461.324982,805.543213 460.166199,807.930237 
	C459.004822,810.322754 456.479767,811.239990 454.862732,813.551270 
	C453.860962,814.540161 453.072021,814.308044 452.049500,813.638489 
	C451.556305,813.187927 451.302399,812.916199 450.872620,812.455811 
	C450.550934,812.169617 450.415619,812.062439 450.048767,811.852173 
	C449.648193,811.773560 449.479126,811.797852 449.089539,811.975098 
	C448.577179,812.371643 448.270874,812.595764 447.675323,812.938477 
	C446.028717,813.807312 445.366333,811.757629 443.741455,812.273682 
	C441.173309,811.728027 440.478607,809.748657 440.007660,807.934143 
	C438.952484,803.868713 436.592468,802.375427 432.295929,803.221802 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M432.024902,803.341064 
	C437.252014,800.164795 438.323669,800.667847 440.799377,806.408630 
	C441.563507,808.180542 441.657867,810.340393 443.683380,811.832153 
	C444.126923,812.186768 444.258759,812.371094 444.452789,812.888672 
	C444.329712,815.102173 444.960571,816.679138 446.513123,818.247437 
	C449.281342,822.173767 452.548859,825.278931 455.239777,829.249023 
	C455.611725,829.632141 455.771576,829.772644 456.157623,830.132751 
	C456.525879,830.504028 456.667908,830.655640 457.004639,831.045227 
	C457.313202,831.450134 457.427155,831.617004 457.689178,832.043762 
	C458.115112,832.817505 458.352325,833.354858 458.899719,834.044434 
	C459.202545,834.423706 459.319977,834.578735 459.594971,834.979370 
	C459.848633,835.394043 459.944641,835.563049 460.174683,835.989319 
	C460.620605,837.025513 461.210846,837.464600 462.313354,837.717957 
	C463.020935,837.906982 463.422791,838.041870 464.124603,838.324036 
	C467.262817,840.342590 470.630920,841.305359 474.106323,844.186401 
	C480.425934,850.351868 486.223236,855.522339 492.347748,860.318115 
	C496.826538,863.825195 501.587463,866.740234 507.495026,867.140320 
	C510.128632,867.318665 511.579346,868.481995 510.205536,871.629883 
	C509.756592,872.041199 509.554779,872.190735 509.266052,872.655518 
	C509.017090,872.763489 508.854980,872.556213 508.302856,872.288208 
	C504.498260,872.176392 501.150909,870.430664 497.316833,871.933350 
	C492.475708,873.622498 489.291779,870.195618 485.151306,869.038574 
	C483.786560,868.994629 482.779297,868.995605 481.394287,868.994629 
	C480.838806,869.122498 480.661041,869.252258 480.218872,869.184326 
	C479.954498,868.986572 479.488647,868.989502 479.255768,868.994995 
	C477.871399,868.112610 477.416077,866.905762 477.207703,865.228271 
	C476.534760,863.961548 475.825531,863.251648 474.378143,863.027100 
	C473.830414,862.775574 473.592072,862.557068 473.180420,862.169189 
	C473.010406,861.996216 473.000458,862.000000 473.002045,861.994751 
	C472.776215,861.776001 472.559143,861.552490 472.182404,861.158447 
	C472.012451,860.997986 472.002075,860.998840 472.002625,860.991943 
	C470.324524,856.658020 466.807892,853.531250 463.623657,850.678406 
	C458.036316,845.672546 452.906372,840.412598 448.295776,834.153015 
	C442.466492,832.882568 437.558838,830.973755 436.046814,824.336548 
	C435.755615,822.882019 435.504974,821.764099 435.109863,820.328003 
	C432.167877,817.455505 434.058960,813.499451 432.098785,810.338440 
	C432.019897,809.836304 432.015991,809.668091 432.009033,809.247559 
	C431.668884,808.433777 431.786194,807.841187 431.909912,806.965210 
	C431.936157,806.492310 431.938293,806.303833 431.976074,805.554077 
	C432.014862,804.552246 432.018036,804.111816 432.024902,803.341064 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M438.236389,797.713806 
	C436.116180,798.457764 434.475311,797.590271 432.393799,797.108582 
	C431.493927,796.387329 430.121948,795.870605 430.073883,796.357605 
	C429.572296,801.440613 423.442657,798.360901 422.031189,802.061218 
	C421.416412,803.673096 423.997925,805.459412 421.291626,806.899414 
	C420.061981,806.109070 419.964417,804.965271 419.668060,803.483032 
	C419.849609,804.772095 420.021973,805.655640 420.727631,806.694641 
	C420.918091,809.437805 421.384277,811.957520 420.303162,814.704651 
	C419.742493,815.312683 419.530884,815.673157 419.180664,816.304871 
	C417.890076,817.191406 416.627655,817.367737 415.214294,817.983215 
	C413.685669,817.950134 412.146423,817.372192 412.084137,816.395996 
	C411.709930,810.529480 405.164520,809.331665 403.205414,804.315613 
	C402.704498,799.709290 402.696106,799.757446 406.751923,800.463867 
	C407.054657,800.516602 406.457367,799.411316 406.307556,798.483704 
	C406.044403,794.951294 408.858307,795.951416 410.662170,795.114319 
	C409.335449,793.250000 407.313446,793.023193 405.528870,792.340515 
	C400.179626,790.294312 399.597412,788.702209 402.389343,783.163147 
	C404.191956,781.711426 406.016266,783.367920 407.940582,782.364868 
	C409.935120,781.944031 412.178619,781.681396 412.137390,783.886108 
	C412.041168,789.030396 415.427246,790.195923 419.584900,791.288940 
	C420.925659,792.216431 421.182404,793.501770 422.046814,794.695679 
	C422.763977,795.147278 423.208069,794.978882 423.839417,794.409912 
	C426.413757,791.928101 429.780304,791.730835 432.470581,790.087280 
	C434.269043,788.988586 435.485535,790.683838 436.947266,792.017334 
	C437.961426,793.155640 438.555542,794.145447 438.879211,795.638794 
	C438.888519,795.987793 438.922119,796.485352 438.943542,796.733765 
	C438.809967,797.151978 438.654938,797.321655 438.236389,797.713806 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M445.847900,838.708252 
	C446.276428,841.419189 447.468903,842.581055 449.974304,843.262024 
	C455.473297,844.756775 457.893677,849.448181 460.002808,854.623413 
	C459.335968,855.858521 458.806030,856.683777 460.777924,856.977051 
	C463.425659,856.806946 464.174347,858.091431 463.988312,860.625122 
	C462.169708,861.139221 460.426178,860.864990 458.652588,861.816711 
	C455.065430,863.741577 452.238892,861.617188 449.662811,859.452087 
	C445.773102,856.183044 442.036591,852.193054 440.538300,847.666321 
	C439.101624,843.325928 434.464264,842.066711 433.483826,837.710999 
	C433.111176,836.055359 430.487030,836.078674 428.644623,835.888611 
	C427.929718,835.814880 426.970245,835.290833 426.658813,834.692566 
	C425.928741,833.290039 427.335205,833.214722 428.155334,832.793640 
	C428.587311,832.571777 429.213745,832.288086 429.309479,831.917847 
	C430.857910,825.930664 433.547333,828.050537 436.696350,830.907349 
	C440.914948,832.270813 443.179108,835.494873 445.847900,838.708252 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M446.323944,839.011658 
	C442.227814,837.531860 440.330566,833.908936 437.186218,831.240723 
	C436.996429,828.660645 433.794464,827.147827 435.744202,824.231018 
	C437.393097,824.557129 437.520325,826.081421 438.323730,827.012024 
	C440.190826,829.174377 441.284210,832.856445 445.575623,831.215088 
	C446.690735,830.788635 447.604187,832.040894 447.955139,833.640869 
	C446.955231,835.499878 450.808685,838.297852 446.323944,839.011658 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M414.929932,818.051819 
	C415.075592,815.913696 416.619812,815.959045 418.535187,816.003479 
	C419.097565,815.875916 419.284332,815.742371 419.759399,815.797302 
	C420.047668,815.985779 419.998779,816.002258 420.005890,815.977417 
	C420.542877,816.563538 420.411377,817.323975 420.517761,818.040039 
	C420.614990,818.694641 421.018402,818.730774 420.628845,818.189758 
	C420.239227,817.648743 421.597717,817.906616 421.237671,817.921082 
	C424.449310,817.685791 425.051270,819.986023 425.817200,821.940735 
	C426.118896,822.710754 425.669647,823.549866 424.711609,823.858887 
	C422.468048,824.582581 420.905334,823.460266 419.461304,821.959656 
	C418.889313,821.365051 418.259766,820.825867 417.351257,820.092346 
	C416.516815,819.468994 416.003723,818.996704 415.222412,818.301270 
	C414.958069,818.073914 414.945709,818.051331 414.929932,818.051819 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M406.705566,797.790405 
	C407.155426,798.332520 407.324127,798.616394 407.493134,798.899963 
	C408.140900,799.987122 409.786621,800.548035 409.458832,802.113953 
	C409.243256,803.143738 408.164917,802.924561 407.372498,802.959717 
	C405.365479,803.048401 404.882996,801.696411 405.023926,798.632446 
	C404.094116,801.134033 403.672455,802.268494 403.118835,803.723938 
	C401.490143,804.008240 400.511353,803.275330 399.989929,801.529907 
	C399.968964,799.884888 400.355652,798.721558 401.728821,797.637024 
	C402.989502,796.945190 403.991028,796.820618 405.401978,797.038513 
	C405.989532,797.267639 406.207031,797.399841 406.705566,797.790405 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M431.674988,810.065063 
	C433.939423,811.031677 436.783691,811.509827 434.292877,814.768982 
	C432.867706,816.633667 435.793488,817.797913 435.198395,819.804199 
	C431.473602,817.554077 430.133575,814.306091 431.674988,810.065063 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M402.017517,797.351013 
	C401.557861,798.933289 401.027588,799.892334 400.216797,801.027832 
	C399.936279,801.204346 399.501587,800.982239 399.274841,800.891663 
	C398.662689,798.480469 395.975098,797.299438 396.066101,794.396118 
	C397.425659,793.296082 398.534332,794.149902 399.990570,794.725220 
	C400.454102,794.985657 400.632874,795.101501 401.051636,795.428589 
	C401.560150,795.972351 401.778748,796.334839 402.017517,797.351013 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M460.983551,856.705200 
	C460.166138,858.530579 458.980133,859.764343 457.555603,857.685791 
	C456.405304,856.007446 457.349579,855.054871 459.632324,855.015015 
	C460.291687,855.481750 460.586670,855.961426 460.983551,856.705200 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M432.849884,805.831787 
	C434.634155,807.344910 432.962921,807.788635 432.168243,808.755737 
	C430.462952,807.918701 428.888550,806.847412 431.684204,805.047363 
	C432.011658,804.992676 431.999756,804.999573 432.000793,804.994995 
	C432.195343,805.247620 432.421997,805.471191 432.849884,805.831787 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M422.564850,827.803406 
	C421.364807,827.524780 421.022217,826.865540 421.328644,826.026367 
	C421.427277,825.756226 422.237976,825.429016 422.359192,825.536926 
	C423.025116,826.129089 423.795441,826.803589 422.564850,827.803406 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M415.028076,818.382263 
	C415.854401,818.099548 416.630341,818.316833 416.983337,819.615173 
	C416.132385,819.930237 415.376617,819.687561 415.028076,818.382263 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M418.409607,840.636475 
	C419.052399,841.247375 419.013092,841.972534 418.444305,842.587952 
	C418.348297,842.691772 417.927216,842.495117 417.656372,842.437256 
	C417.825745,841.880798 417.995148,841.324402 418.409607,840.636475 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M421.516907,817.627930 
	C419.802887,818.905640 420.101776,817.434937 419.950378,816.260620 
	C420.505554,816.395935 420.998108,816.839172 421.516907,817.627930 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M410.627014,826.680786 
	C410.615356,826.964539 410.475128,827.117737 410.334900,827.270874 
	C410.389374,827.030640 410.443878,826.790405 410.627014,826.680786 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M464.026276,860.980591 
	C464.165649,861.127258 464.280182,861.296326 464.394714,861.465332 
	C464.292938,861.422729 464.191162,861.380127 464.023193,861.195312 
	C463.957001,861.053101 464.001404,861.002930 464.026276,860.980591 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M409.555939,824.504517 
	C409.595642,824.574768 409.516235,824.434326 409.555939,824.504517 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M420.051697,815.737915 
	C419.859222,816.031677 419.670746,816.077576 419.196533,816.066467 
	C419.100250,815.700134 419.289764,815.390747 419.754089,815.038208 
	C420.028870,814.995056 420.055756,815.490112 420.051697,815.737915 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M562.041016,849.633789 
	C560.236450,849.286438 558.904419,849.945557 557.258545,850.528870 
	C556.582458,851.481750 555.997620,851.131836 555.156738,850.999634 
	C552.513000,852.011047 550.137024,852.985046 547.827576,854.096985 
	C546.144470,854.907410 544.521851,855.374084 542.461975,854.181519 
	C541.428101,853.512756 541.670654,852.670776 541.165771,851.983154 
	C540.897217,851.971741 540.468201,851.982239 540.253662,851.982056 
	C539.873413,852.105042 539.707703,852.228088 539.288696,852.173096 
	C539.035461,851.994934 539.013611,851.986816 539.008179,851.976562 
	C534.487366,850.022095 531.380005,845.731873 526.255615,844.182068 
	C526.305969,840.453369 522.014526,837.627869 524.738464,833.695068 
	C525.349854,832.812317 523.581360,831.615356 522.963806,832.339172 
	C520.305420,835.455322 515.994568,829.624146 514.411011,833.663330 
	C513.259827,836.599731 513.255249,837.372131 510.244873,835.985474 
	C508.592041,835.224121 506.789856,834.791443 505.068420,834.173218 
	C503.922729,833.761902 502.904694,834.246216 502.684540,831.740723 
	C502.422516,828.758789 498.304565,830.419922 495.457336,829.987427 
	C494.875977,829.837036 494.714661,829.684937 494.462341,829.211304 
	C494.388519,828.666931 494.405731,828.443970 494.546417,827.907959 
	C494.917114,827.221924 495.199585,826.878174 495.776886,826.357178 
	C497.437408,825.178833 498.987091,824.383789 500.117554,822.603210 
	C501.109375,820.986023 501.571259,819.472534 502.556549,817.840210 
	C503.975739,816.589539 505.555145,815.720581 506.775665,816.785767 
	C510.717987,820.226440 512.663696,816.846313 514.644775,814.522034 
	C516.152588,812.752991 517.317383,810.803772 520.223206,810.466187 
	C520.790649,810.395630 521.019775,810.391968 521.583984,810.457825 
	C522.930359,810.990906 523.799194,811.738708 525.223938,812.128845 
	C532.892761,813.786682 537.034973,819.956360 542.579712,824.097717 
	C543.789490,825.001343 543.672546,826.404053 543.108948,827.713989 
	C542.551453,829.009888 542.444458,830.252625 543.670715,831.644043 
	C545.369629,833.453674 547.394592,833.581543 549.640747,834.057861 
	C551.027893,834.482300 552.045593,834.909180 553.011169,835.928894 
	C553.291809,836.284424 552.884338,836.653015 553.598267,836.489319 
	C555.002136,836.050659 555.915222,836.472839 556.825928,837.675232 
	C558.981934,841.371033 564.685974,841.448486 564.781006,846.973633 
	C564.519592,847.491455 564.281677,847.728027 564.050537,848.297852 
	C563.977661,848.548218 563.721252,848.530396 563.721252,848.530396 
	C563.721252,848.530396 563.533508,848.706055 563.529724,848.834351 
	C563.173096,849.229736 562.786865,849.431824 562.041016,849.633789 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M496.266693,826.790894 
	C495.937195,827.352661 495.742676,827.614136 495.256012,827.937561 
	C494.899475,828.206543 494.759949,828.357544 494.817505,828.745850 
	C495.018188,829.150879 495.021729,829.318604 495.031311,829.737793 
	C493.424347,831.245605 494.793671,832.387085 495.252075,833.479492 
	C495.886597,834.991760 496.376038,836.296692 494.319427,837.099792 
	C492.837219,837.678528 493.051910,838.804382 493.539581,839.986633 
	C494.195007,841.575562 497.147980,842.978088 493.576385,844.957642 
	C492.889587,845.338318 494.028595,848.713135 496.299622,848.572693 
	C500.047913,848.340637 500.033722,848.111145 500.950256,852.337280 
	C502.972839,846.293518 508.225037,849.284363 511.815277,847.612915 
	C513.054993,847.035767 513.595825,849.953308 513.599609,851.527161 
	C513.612610,856.912964 516.988525,860.665588 522.069031,860.827332 
	C523.404175,860.869873 524.404846,860.416504 524.732849,859.125549 
	C525.190552,857.324158 523.834167,857.746033 522.736755,857.350342 
	C518.880798,855.959595 517.767822,850.776367 520.512329,848.167603 
	C521.640991,847.094849 522.702332,846.666382 523.917053,847.915771 
	C526.145935,850.208252 529.298279,851.017639 531.920105,852.687195 
	C534.536499,854.353271 534.723450,855.858093 534.019470,858.605713 
	C532.343689,861.915710 534.965271,863.386780 536.104980,865.666382 
	C535.418091,866.488159 534.833679,866.976318 534.125366,867.727295 
	C531.767944,868.015320 530.282776,865.035217 527.581238,866.530396 
	C526.703674,867.016113 526.201538,866.080750 526.480103,865.066406 
	C526.798706,863.906189 526.165039,862.568359 525.227783,863.348511 
	C523.208496,865.029602 521.780945,864.211243 519.997559,863.248169 
	C519.440369,862.947266 518.119995,863.132202 517.757629,863.583496 
	C516.897888,864.653809 517.939392,865.396729 518.781921,865.846558 
	C524.153381,868.714417 527.623291,874.347107 533.754456,876.106384 
	C535.438538,876.589722 535.892883,877.547607 534.363403,879.248962 
	C528.631165,878.782959 524.079407,875.356079 518.358704,874.238892 
	C516.561096,874.080872 515.476746,873.207703 513.985962,872.434326 
	C512.670593,872.247131 511.679291,872.157532 510.323181,871.979797 
	C509.604156,870.661560 510.908020,868.396423 508.398132,868.480286 
	C501.200562,868.720764 495.812195,864.822266 490.656586,860.723816 
	C484.841583,856.101318 479.040466,851.396790 474.109680,845.328308 
	C475.992432,843.622437 479.641388,844.855591 480.211945,840.936707 
	C480.326447,840.150024 482.082092,840.470886 482.857574,841.118530 
	C484.867584,842.797180 486.195007,841.241638 487.700317,840.201477 
	C485.991241,839.424561 484.778320,838.072693 483.776428,836.540161 
	C482.545197,834.656616 481.838898,832.721558 484.932861,832.042297 
	C486.761261,831.640930 487.001099,830.059875 488.136200,828.709290 
	C491.840698,827.271179 492.789215,824.365417 493.517029,820.890381 
	C497.437012,819.297791 499.982147,817.275146 499.065948,812.700928 
	C498.817139,811.458679 499.162903,809.863037 500.896149,809.679016 
	C502.529114,809.505676 503.210114,810.833984 503.565948,812.129333 
	C504.049133,813.888000 504.292633,815.704956 503.262360,817.746399 
	C503.103638,819.725464 502.580627,821.237000 501.260986,822.782837 
	C499.694763,824.443848 497.536163,824.798157 496.266693,826.790894 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M536.350647,866.038391 
	C534.800293,865.137512 533.454590,864.417175 532.428101,863.380798 
	C530.698181,861.633850 531.131653,860.165649 533.714050,859.110107 
	C539.425354,859.286316 543.164551,863.112122 547.823975,865.716064 
	C544.274719,868.554504 540.492065,867.992188 536.350647,866.038391 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M525.852783,844.127563 
	C532.313721,842.909912 534.700317,848.670349 538.945129,851.630249 
	C532.802002,852.489868 529.596985,847.777039 525.852783,844.127563 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M540.930054,852.296387 
	C541.190857,851.302002 541.290161,849.986450 542.314148,850.652283 
	C543.300232,851.293518 541.973022,852.424805 542.155151,853.700256 
	C541.745911,853.557129 541.354370,853.089111 540.930054,852.296387 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M555.036194,851.254272 
	C555.299072,850.115173 555.896667,850.434814 556.741455,850.760986 
	C557.010010,851.016357 557.031982,851.269043 557.023926,851.903320 
	C556.399963,852.033447 555.805908,851.782043 555.036194,851.254272 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M539.114502,852.171875 
	C539.202454,851.984070 539.369446,851.973206 539.787720,851.972107 
	C539.850525,852.319275 539.692322,852.726685 539.114502,852.171875 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M598.623047,848.374329 
	C597.297729,845.798340 596.627319,844.061584 598.365540,841.452393 
	C600.332031,838.500610 597.843201,834.569153 595.644653,832.668945 
	C588.493103,826.488098 580.094788,823.242615 570.394165,825.413513 
	C568.422302,825.854797 566.543701,826.310059 564.290039,825.323608 
	C563.395752,823.986511 563.631836,822.932983 564.281860,821.529236 
	C573.611938,818.533936 582.838379,816.785767 591.786743,821.290649 
	C596.043823,823.433777 600.247986,825.784180 604.680298,828.510010 
	C605.215637,828.936462 605.418640,829.120544 605.885864,829.615479 
	C606.292419,830.154663 606.434753,830.383057 606.724854,830.983276 
	C607.128784,832.343933 607.324097,833.476318 606.799683,834.287720 
	C603.984009,838.644226 603.160706,843.830688 600.438232,848.515625 
	C599.820129,848.685730 599.560730,848.704224 598.623047,848.374329 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M577.153687,842.286133 
	C575.714417,835.505249 568.485535,837.302429 565.277344,833.450378 
	C564.531433,832.554932 562.392578,832.434265 563.550537,830.674927 
	C564.271118,829.580200 565.058899,828.301147 567.037292,828.461853 
	C572.445801,828.901123 577.901367,828.816223 583.292053,829.380981 
	C584.713623,829.529968 587.705078,830.000183 585.905334,833.252625 
	C584.830872,835.194519 586.765381,835.016541 587.744812,834.962830 
	C589.705994,834.855286 590.667786,835.772156 591.539734,837.424683 
	C592.143860,838.569763 593.503113,839.424622 592.373718,840.839294 
	C591.015808,842.540283 589.637939,841.415955 588.525269,840.492065 
	C587.478027,839.622742 586.611450,838.633667 584.729309,839.399109 
	C585.901062,842.929810 590.157410,843.423157 591.833374,846.732056 
	C586.011597,849.137390 581.778931,845.436768 577.153687,842.286133 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M633.308716,867.618164 
	C630.088684,867.259216 626.911438,867.368713 624.678955,864.949707 
	C624.835571,864.431458 624.718506,864.222595 624.856445,863.764160 
	C626.690430,862.410278 628.696106,864.061829 630.568176,862.571533 
	C628.206543,860.291687 626.126648,859.615784 623.712036,862.825317 
	C623.280945,863.254700 623.085632,863.407288 622.537964,863.693848 
	C617.674011,863.916931 613.607849,862.307617 609.271912,860.298828 
	C608.956482,860.073669 608.482788,860.034851 608.246277,860.011475 
	C606.360962,858.889648 604.524658,858.113464 602.883545,857.000977 
	C601.479797,856.049377 599.987488,855.052368 600.812866,853.050537 
	C601.564636,851.227417 603.237488,850.956909 604.986023,851.204468 
	C610.386597,851.969055 615.642517,853.319153 620.734314,855.305298 
	C619.685547,854.823059 617.790466,854.932983 618.095276,853.398499 
	C618.476318,851.479736 620.744019,851.852295 622.537109,851.150208 
	C626.409424,849.496033 630.558411,852.103943 630.957031,853.654419 
	C631.646912,856.337524 634.221680,856.367065 634.948730,858.669312 
	C634.736267,862.025940 636.639587,864.001221 638.612427,865.904602 
	C637.124817,867.783203 635.007629,866.324707 633.308716,867.618164 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M622.953674,851.635132 
	C622.047302,853.657532 620.103455,852.433350 618.702148,853.660339 
	C620.777588,854.364502 622.662720,855.004089 624.547852,855.643616 
	C617.963074,856.235718 612.066711,853.489502 605.807922,852.743652 
	C604.371155,852.572510 602.682312,851.404358 601.794983,853.097107 
	C600.590942,855.393982 603.375183,855.468323 604.324768,856.546570 
	C605.286133,857.638367 606.564270,858.451294 607.856750,859.687195 
	C602.500732,859.183655 597.760376,856.425171 592.946655,853.924500 
	C588.301819,851.511597 584.035583,848.404968 579.110107,846.479065 
	C577.388184,845.805725 575.915710,844.640320 576.797729,842.119507 
	C581.744446,843.632080 586.088379,846.359070 591.571289,847.040161 
	C591.965088,847.042236 591.987000,847.014771 591.981750,847.019531 
	C593.315979,847.851807 595.083801,847.360229 596.662537,848.529419 
	C599.141663,849.068665 601.318115,849.352600 603.859619,849.340942 
	C606.537659,849.381104 609.370056,851.473877 611.120972,847.568909 
	C615.027832,847.103821 618.326965,848.299500 621.947632,849.641907 
	C622.589722,850.282654 622.824036,850.738037 622.953674,851.635132 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M589.091003,858.969849 
	C587.220520,859.116882 585.530396,858.973633 583.427002,858.933289 
	C581.027893,857.188110 578.681641,858.483826 576.524536,858.212708 
	C572.009888,857.645203 567.200134,857.904114 563.370056,854.651001 
	C562.065369,853.542908 560.914551,852.438721 561.459900,850.103760 
	C561.936035,849.951599 562.254150,850.244446 562.763428,850.807739 
	C562.982300,851.048035 562.999512,850.999023 562.975159,851.008179 
	C564.123291,851.859558 565.333435,851.516479 566.856934,851.086914 
	C568.152588,851.152466 568.228271,856.906860 570.230103,851.400330 
	C571.414246,848.142700 574.206055,847.384460 577.307617,849.544800 
	C581.218262,852.268921 585.219971,854.820007 589.921631,855.999573 
	C590.674255,856.188354 591.815186,856.234009 591.263306,857.369019 
	C590.964539,857.983398 589.958374,858.253967 589.091003,858.969849 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M553.382568,836.964722 
	C552.120911,836.511841 551.195068,836.042114 550.073486,835.316162 
	C551.013550,832.876160 552.175720,830.791077 551.263916,828.089294 
	C550.729675,826.506226 551.281128,824.887207 553.425537,823.966553 
	C554.532532,823.673889 555.297302,823.606506 556.462769,823.588318 
	C558.201477,823.591003 559.540100,823.618591 561.408936,824.640991 
	C561.920105,826.214539 561.575806,827.340210 560.551697,827.544373 
	C555.171143,828.617065 557.804443,830.317627 560.928040,832.380554 
	C557.425842,832.905090 556.064453,834.506226 557.086060,837.710754 
	C555.908813,837.626343 554.813660,837.287109 553.382568,836.964722 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M609.014771,860.373657 
	C613.600647,859.942688 617.659668,862.402588 622.520264,863.080383 
	C623.528870,863.210144 624.047546,863.490112 624.689026,864.346802 
	C622.553345,867.497559 619.851501,864.802063 617.317322,865.182373 
	C615.574402,865.443909 613.740295,865.097168 611.483643,865.060181 
	C610.787537,864.910217 610.523010,864.795410 610.053833,864.621704 
	C610.945557,862.935242 609.706482,861.868530 609.014771,860.373657 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M564.664917,821.736816 
	C564.735168,822.796143 564.460693,823.613159 564.093750,824.743652 
	C563.556824,825.127563 563.112427,825.197815 562.333923,825.219116 
	C561.999817,825.170105 562.010193,825.055054 561.957397,825.031738 
	C561.189026,823.965515 561.145325,822.931580 562.303345,821.684021 
	C563.217468,821.307495 563.770630,821.319214 564.664917,821.736816 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M597.317139,849.151611 
	C595.456726,848.540222 593.934753,848.042969 592.194702,847.285034 
	C593.808289,846.799316 595.518433,847.271851 597.570190,847.980103 
	C597.944763,848.026001 597.966553,848.030518 597.963928,848.038391 
	C598.169617,848.530457 598.053284,848.933289 597.317139,849.151611 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M567.129517,850.730713 
	C566.060608,852.400696 564.732117,852.341980 563.127441,851.330078 
	C564.378723,851.151123 565.525208,849.337830 567.129517,850.730713 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M563.190186,850.737549 
	C562.632690,850.786804 562.283142,850.525513 561.761841,849.976685 
	C561.590027,849.689087 561.628418,849.725037 561.674011,849.716553 
	C562.081116,849.347534 562.442688,848.986938 563.165100,848.794434 
	C563.483337,849.450745 563.440735,849.938843 563.190186,850.737549 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M564.115967,848.342773 
	C564.066345,848.175354 564.155090,847.802551 564.546570,847.390137 
	C564.691772,847.643677 564.493408,847.905945 564.115967,848.342773 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M262.988373,706.776428 
	C266.587708,710.802490 266.343475,715.840454 266.686432,720.251831 
	C267.283508,727.932190 268.981750,735.180725 271.543549,742.345520 
	C271.975372,743.553284 271.902252,744.941589 272.058838,746.670532 
	C270.953918,746.728882 271.029938,745.415771 269.208313,745.039429 
	C264.705200,744.109070 264.494263,739.334839 263.663574,735.572815 
	C263.095703,733.000916 263.038055,730.263245 261.397400,728.086548 
	C260.364838,726.716614 260.274414,725.518005 260.820831,723.957886 
	C262.115082,720.262512 258.701202,717.494080 258.663116,714.005249 
	C258.657013,713.447754 257.785004,713.057800 257.038208,713.359558 
	C256.312408,713.652832 256.002441,714.328552 256.244629,714.959778 
	C258.014069,719.571655 255.916199,724.615906 257.927734,729.606812 
	C257.825775,730.178711 257.674042,730.336182 257.347351,730.772400 
	C256.764191,732.374756 256.339417,733.679565 254.243622,733.202454 
	C253.727921,732.198486 253.483017,731.391418 253.108368,730.290527 
	C253.040955,729.807068 253.103271,729.617310 253.267883,729.147705 
	C253.500626,728.272705 253.487976,727.691345 253.067688,726.855896 
	C252.760574,726.191711 252.635925,725.793030 252.567322,725.063721 
	C252.672882,724.183594 253.082733,723.765381 253.276428,723.248291 
	C253.510269,722.624146 252.950531,722.261597 253.097656,722.911804 
	C253.244766,723.562012 252.347595,723.174622 252.753021,723.434631 
	C252.131088,723.599792 251.767471,723.544495 251.204300,723.206543 
	C249.699402,719.746338 249.241379,716.323242 248.995087,712.435669 
	C248.163788,711.194641 248.017181,710.187927 248.331985,708.740845 
	C251.206894,706.886292 253.780045,704.967957 257.470093,705.272095 
	C259.169678,706.873108 260.975433,706.584473 262.988373,706.776428 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M258.107819,706.213745 
	C256.351501,706.066284 254.787140,705.697388 253.887878,707.297302 
	C252.791153,709.248474 251.230698,708.608887 249.142380,707.900024 
	C248.578094,707.536133 248.552582,707.370483 248.731079,707.057739 
	C248.933487,706.470215 248.931885,706.029663 248.947189,705.259155 
	C249.204239,704.728394 249.437363,704.519775 249.844208,704.153687 
	C251.923645,704.002014 253.549484,703.658569 252.525345,701.181946 
	C251.830490,699.501587 251.578262,697.963440 251.644119,696.038147 
	C251.822067,690.836060 254.277390,688.930115 258.924255,691.287231 
	C260.631927,692.153381 261.273163,690.944458 262.315491,690.235718 
	C263.902893,689.156311 265.076141,688.933228 264.140015,691.768188 
	C264.601013,692.680908 264.478302,693.505371 264.226074,694.665283 
	C264.015900,695.015991 264.005402,695.000000 264.015137,695.007812 
	C262.463287,696.764526 262.673737,698.922485 262.740479,700.999023 
	C262.839264,704.073547 261.565521,705.938538 258.107819,706.213745 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M244.692322,689.135742 
	C246.231094,692.107178 247.442459,695.215759 248.679840,698.313965 
	C249.207260,699.634521 250.306351,700.860474 249.106049,702.710449 
	C243.758835,703.177795 244.773712,698.700378 244.184769,695.960022 
	C243.588577,693.185730 242.692902,691.777588 239.411682,692.014893 
	C238.617004,686.853394 242.900116,690.797363 244.692322,689.135742 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M256.602661,734.811279 
	C258.300751,738.922852 259.622162,742.840820 260.883667,746.777893 
	C261.273560,747.994690 262.970520,749.682007 260.889435,750.374939 
	C259.349213,750.887878 259.085571,748.911011 259.025452,747.607849 
	C258.956329,746.109741 258.500397,745.053589 256.412384,744.998901 
	C256.022369,744.538696 256.012512,744.100403 255.998581,743.333191 
	C255.051422,741.256958 255.092712,739.257507 254.570557,737.400208 
	C254.172989,735.986145 254.394135,734.804749 256.602661,734.811279 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M257.001221,734.998779 
	C252.672913,736.837463 257.490997,739.951111 255.998001,742.595337 
	C253.720383,740.220825 253.087723,736.997070 253.709839,733.256348 
	C255.780533,733.075256 255.850662,731.454407 256.767242,730.202454 
	C257.844757,731.350342 257.385590,732.819458 257.217163,734.618103 
	C257.002441,734.995728 257.000000,735.000000 257.001221,734.998779 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M250.995178,723.398315 
	C251.438110,723.004211 251.881729,723.003479 252.657959,723.005005 
	C253.361176,723.390564 253.508850,723.846252 253.219635,724.682739 
	C253.105545,725.308838 253.288208,725.568604 253.766083,725.883484 
	C254.786957,726.995117 254.412949,728.158020 254.217697,729.633118 
	C253.831741,729.999878 253.661209,729.999756 253.234665,729.998169 
	C251.003464,728.348389 250.925522,726.093750 250.995178,723.398315 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M248.980194,708.024475 
	C248.985748,709.095825 248.992065,710.155945 248.994675,711.614868 
	C248.441513,712.672058 247.892075,713.330322 247.014709,714.381531 
	C247.014709,712.170593 247.014709,710.479919 247.020355,708.385498 
	C247.413605,707.639160 247.866776,707.510620 248.671341,707.793579 
	C248.965820,708.019348 248.980927,708.013245 248.980194,708.024475 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M248.965179,707.742004 
	C248.534653,708.010986 248.103500,708.002625 247.349182,707.987976 
	C247.050476,707.050110 247.196503,706.263733 248.689941,706.852539 
	C248.944901,707.095337 248.954727,707.280029 248.965179,707.742004 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M261.019012,763.028809 
	C260.913788,763.090332 260.815216,763.899841 260.776978,763.008423 
	C260.916962,762.933655 260.987335,762.994690 261.019012,763.028809 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M249.765839,704.002930 
	C249.913681,704.326538 249.712448,704.576660 249.192657,704.841736 
	C248.964081,704.929138 248.982101,704.471802 248.985413,704.243225 
	C249.161423,704.010376 249.334091,704.006104 249.765839,704.002930 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M313.999390,756.328491 
	C314.114624,754.741150 313.535400,752.942993 314.960144,752.391113 
	C320.151855,750.380493 318.274078,747.987488 315.856232,744.992126 
	C313.702118,742.323486 314.214233,734.267273 316.349121,731.912781 
	C317.450226,730.698364 318.625214,731.024353 318.839020,732.558960 
	C319.686096,738.637817 324.503082,743.404358 324.421936,749.765564 
	C324.362305,754.434814 327.013947,755.663940 331.570862,754.994019 
	C334.057739,756.005188 334.096802,759.507141 337.531372,759.143555 
	C338.261749,759.036621 338.677429,758.991455 339.398926,758.956299 
	C340.281067,758.986389 340.836121,759.115967 341.561584,759.495972 
	C341.763031,759.623779 342.114105,759.853027 342.260315,759.685181 
	C342.494873,758.781555 342.190491,758.091309 342.144836,757.043945 
	C342.163239,756.498230 342.194000,756.278137 342.344971,755.740906 
	C344.130402,753.509216 345.688171,754.079407 346.951996,755.799805 
	C348.693634,758.170654 351.103821,760.224304 351.511108,763.328674 
	C351.864838,766.024475 353.567200,765.829773 355.777222,765.715088 
	C356.405182,766.206238 356.598450,766.626282 356.660278,767.447266 
	C352.418701,771.981079 355.433838,776.964233 354.984497,781.858521 
	C354.967255,782.645630 353.601624,782.108398 354.502441,782.827820 
	C354.944580,782.761108 355.130432,782.848267 355.507263,783.185181 
	C355.783234,783.629578 355.868256,783.824280 356.033508,784.329956 
	C356.295227,785.920532 356.276794,787.194580 355.904663,788.742065 
	C355.687561,789.389709 355.063263,789.380615 355.119568,789.752563 
	C355.158356,790.008606 355.528259,789.917908 356.028687,789.552979 
	C357.371033,794.699463 359.533203,799.382935 362.157074,803.897644 
	C362.726135,804.876709 363.094238,805.971252 364.150360,807.063843 
	C364.844360,807.898254 365.146820,808.538818 365.131104,809.615540 
	C365.858215,813.228027 366.995483,816.558044 363.026611,819.178589 
	C360.765991,819.477112 359.577057,817.815369 357.777161,816.535034 
	C356.205231,814.573303 355.214264,812.701477 354.919678,810.260925 
	C356.516541,809.825867 358.057739,810.468811 359.583099,809.164917 
	C355.507416,804.985291 351.747406,799.414734 351.146667,794.628784 
	C350.491302,789.407288 345.491882,787.978210 345.189941,783.040894 
	C344.906982,778.414062 341.318787,774.409424 338.633698,770.514038 
	C336.502197,767.421753 334.361755,762.807617 329.115875,767.406189 
	C328.127655,768.272522 325.362640,767.153198 323.548645,768.802795 
	C325.385895,770.991028 329.834747,773.167175 324.130768,775.904175 
	C322.037720,776.908508 321.181183,774.150818 319.165527,774.168091 
	C318.408478,773.413818 317.884705,772.867920 317.160767,772.121460 
	C315.962067,768.762451 313.056030,765.961487 315.422058,762.060181 
	C316.889984,761.933228 316.464050,764.730347 318.362457,764.316223 
	C319.547882,762.950745 319.062744,761.198181 318.922028,759.662415 
	C318.661285,756.816467 318.001282,754.299377 314.701569,757.863159 
	C314.202332,758.402344 314.147644,757.132446 313.999390,756.328491 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M383.890381,814.753906 
	C384.014496,818.614014 380.792511,822.740234 386.031830,825.329956 
	C386.808075,825.713623 388.048370,826.988708 387.440613,828.186707 
	C386.778961,829.490906 385.092529,829.089966 383.872223,829.337463 
	C382.284180,829.659424 382.186646,827.492554 380.581055,827.077087 
	C379.954865,826.832214 379.658417,826.615967 379.189270,826.175537 
	C378.997620,825.977173 378.995270,826.004883 379.009125,826.003296 
	C378.788696,825.778320 378.558014,825.551392 378.161743,825.146729 
	C377.992615,824.972656 377.990112,825.009949 378.008789,825.010193 
	C377.576843,824.577698 377.525391,823.974365 377.172821,823.191040 
	C376.995697,822.957947 376.986237,823.010254 377.012634,823.012939 
	C376.798218,822.787781 376.561646,822.555603 376.156921,822.139893 
	C375.984467,821.960876 375.985870,822.014221 376.012573,822.015320 
	C375.776764,821.771973 375.529449,821.513123 375.137817,821.023071 
	C375.014984,819.175293 373.853912,818.294800 372.548798,817.180176 
	C367.985382,813.143677 366.635559,807.923279 365.657288,802.143799 
	C365.611816,801.278992 365.621063,800.790466 365.660339,799.953308 
	C365.672150,799.376404 365.677216,799.148499 365.677490,798.586792 
	C363.289337,794.954773 362.324188,791.585693 365.372437,787.736450 
	C365.923431,787.086853 366.307434,786.781555 367.082184,786.376831 
	C369.163330,785.848450 370.428040,786.739868 371.819977,788.102417 
	C371.924530,790.765930 372.965332,792.015320 375.723236,791.954346 
	C376.371185,792.330872 376.539703,792.758362 376.477051,793.570496 
	C375.133728,794.938354 373.908081,795.988281 375.186829,798.159790 
	C375.447083,800.353943 375.458405,802.216003 375.073853,804.398926 
	C374.824249,805.115295 374.606140,805.467041 374.298309,805.762390 
	C374.268494,805.791016 373.274139,805.576355 374.547852,805.863159 
	C377.742859,807.232361 378.039764,811.106384 381.385864,812.541199 
	C382.536041,813.092346 383.303101,813.594910 383.890381,814.753906 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M372.017181,788.090942 
	C370.577637,787.972534 369.164673,787.492004 367.375580,787.015198 
	C363.252594,788.131958 362.757446,785.096436 361.551117,782.501038 
	C361.355042,781.958191 361.265686,781.746399 361.066071,781.210510 
	C359.684906,777.420227 362.699341,776.257568 364.750854,774.266785 
	C367.473724,773.305847 369.527985,774.899902 372.103119,775.082397 
	C373.240112,774.907898 374.025787,774.663208 375.037720,774.122925 
	C375.492462,773.855103 375.675262,773.743774 376.427155,773.286560 
	C377.409393,772.722900 377.840424,772.548218 378.647034,772.341797 
	C381.380096,772.382019 383.502777,773.098755 385.435883,775.140625 
	C386.092682,776.872742 386.083282,778.155701 384.353882,779.091431 
	C383.944763,779.062439 383.465332,779.011475 383.225708,778.985168 
	C382.286316,779.334167 381.920349,780.033936 381.202179,780.791504 
	C377.105347,780.292114 374.981506,784.184814 371.356171,784.696594 
	C369.443085,784.966675 372.426788,786.506714 372.017181,788.090942 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M375.717957,794.214844 
	C375.968842,793.512329 376.007263,793.065918 376.065125,792.283081 
	C377.963745,789.179871 381.330627,791.537598 383.836243,789.708191 
	C384.805328,789.000671 385.258698,791.888428 383.963287,792.790405 
	C381.645905,794.404175 382.990723,795.885498 383.793640,797.796021 
	C383.344940,799.134399 382.655640,800.069397 381.904388,800.934387 
	C379.389282,803.830200 378.261444,806.733704 381.206421,810.092041 
	C381.959442,810.950867 382.044464,812.223816 381.257263,813.646240 
	C377.537842,812.513000 376.520599,809.021423 374.315491,806.145020 
	C374.019318,803.649841 374.021820,801.286804 374.037842,798.481689 
	C375.122742,797.089111 374.297089,795.365662 375.717957,794.214844 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M381.350403,813.970520 
	C381.055115,812.572632 380.739319,811.336182 380.011169,810.177368 
	C376.558350,804.682617 377.463593,801.463379 383.596924,798.218872 
	C385.081085,801.690247 386.213165,805.292236 387.485962,808.843811 
	C388.562958,811.849121 388.123138,813.993408 384.310120,814.921021 
	C383.202087,814.737000 382.432892,814.379822 381.350403,813.970520 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M383.976013,779.342529 
	C385.164032,778.212830 384.978363,776.921631 385.067383,775.065186 
	C385.374207,773.898499 385.402893,773.324951 385.508911,772.425049 
	C387.003113,769.700195 389.083282,768.634827 391.774811,769.468201 
	C393.813019,770.099304 394.901276,771.784241 395.252655,773.847778 
	C395.781860,776.955750 393.432068,778.084290 390.984222,779.459106 
	C388.513336,779.075012 386.997742,780.380371 385.092529,781.211548 
	C384.542358,780.573975 384.274811,780.098267 383.976013,779.342529 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M366.666107,800.731201 
	C367.722595,806.461792 368.848389,811.740173 372.176117,816.658020 
	C367.977844,816.833252 368.203705,812.140808 365.306915,810.113403 
	C364.759003,809.474304 364.495850,808.988098 364.109985,808.252869 
	C362.499481,805.367920 361.043579,802.726196 362.755798,799.273926 
	C364.579315,798.482361 365.681732,799.073547 366.666107,800.731201 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M370.907227,826.124939 
	C370.093384,828.821289 368.516663,830.333374 366.779541,827.297852 
	C365.566284,825.177673 362.978943,826.691833 362.033356,824.429382 
	C360.655334,821.316284 362.890564,821.645203 364.769379,821.274902 
	C365.827057,819.784668 366.393280,820.071045 367.078888,821.266357 
	C368.058960,822.975220 368.966919,824.768188 370.907227,826.124939 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M364.815033,821.150208 
	C365.088837,823.287476 362.372192,821.867249 362.016785,823.837280 
	C360.715088,823.134460 359.424774,822.142273 358.055054,820.862061 
	C357.453247,819.193054 356.844513,817.825317 357.608765,815.819092 
	C359.368530,816.573669 360.838104,817.800537 362.592041,819.262085 
	C363.423431,820.035278 363.970520,820.573792 364.815033,821.150208 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M384.900757,781.382568 
	C386.659546,780.844666 385.518738,774.945312 389.662903,778.823364 
	C391.426575,781.183899 390.597015,783.360779 389.344238,785.864014 
	C386.501343,785.539551 386.790588,782.749512 384.900757,781.382568 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M389.801422,821.887146 
	C387.430695,823.931641 386.736328,822.118042 386.102478,820.433167 
	C385.760132,819.523132 386.336975,818.765015 387.194214,818.592651 
	C389.598724,818.108948 389.849670,819.716797 389.801422,821.887146 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M381.324951,780.959534 
	C381.183624,779.959229 381.032501,778.697205 382.800415,778.782104 
	C382.955505,779.896118 382.685822,780.670654 381.324951,780.959534 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M391.052307,787.572388 
	C390.364685,787.941772 389.965179,787.993958 389.263672,788.066162 
	C388.901001,787.664368 388.840332,787.242493 388.767426,786.505798 
	C388.755219,786.190918 388.911743,786.072754 388.988251,786.011353 
	C390.472809,785.252686 391.210602,785.723999 391.052307,787.572388 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M388.560852,794.249023 
	C387.924072,794.252563 387.382477,793.893860 387.828186,793.465210 
	C388.244843,793.064636 388.667175,793.509277 388.560852,794.249023 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M376.989349,823.271301 
	C377.348419,823.434143 377.701172,823.910339 378.040710,824.698486 
	C377.679352,824.535156 377.331177,824.059875 376.989349,823.271301 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M376.077393,822.208252 
	C376.258301,822.159668 376.532135,822.358398 376.922516,822.786377 
	C376.749481,822.828918 376.459869,822.642273 376.077393,822.208252 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M375.066681,821.080627 
	C375.253418,821.045410 375.530792,821.282349 375.923706,821.767822 
	C375.745270,821.795227 375.451294,821.574036 375.066681,821.080627 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M378.095154,825.206299 
	C378.289093,825.144653 378.585571,825.316650 378.952484,825.745117 
	C378.747864,825.814453 378.472778,825.627258 378.095154,825.206299 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M379.116455,826.244019 
	C379.319672,826.161133 379.641693,826.345032 380.117004,826.775696 
	C379.925293,826.851868 379.580261,826.681335 379.116455,826.244019 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M206.246674,559.728516 
	C204.131027,562.114441 205.350632,564.655090 205.034012,567.386719 
	C204.728851,568.720398 205.560150,569.894958 203.660858,570.385010 
	C197.576584,571.955322 192.517242,575.825867 186.908813,578.492188 
	C185.610352,579.109436 184.471786,579.811035 182.993118,580.093018 
	C180.255463,580.615051 180.742172,581.931458 182.698120,583.568970 
	C181.866074,584.169800 180.809967,584.520996 179.445160,585.009888 
	C178.686691,585.094482 178.236938,585.041321 177.448273,584.965332 
	C176.935181,584.006226 176.760986,583.070068 176.692520,581.788330 
	C176.924423,581.200745 177.076324,580.977173 177.254181,580.772339 
	C177.432510,580.566895 177.357697,580.619507 176.841156,581.124634 
	C176.201218,580.527771 175.749634,579.736145 175.266388,578.609741 
	C175.234711,578.274841 175.591400,578.092834 175.778854,578.022522 
	C176.429764,577.558411 176.898224,577.168823 177.131195,576.272339 
	C177.197281,573.974365 174.951355,573.942505 174.072876,572.308716 
	C175.743927,570.561890 173.832275,569.395874 173.567673,568.100708 
	C173.000015,565.322144 172.800873,563.062866 176.967651,563.664001 
	C179.228149,563.990112 180.401642,561.534180 182.197510,560.549927 
	C181.270477,559.012817 181.464294,556.990173 178.801376,556.321533 
	C176.435287,555.727478 175.070663,553.742188 177.999832,551.330261 
	C181.451401,548.488159 183.820496,544.882629 184.317139,540.218567 
	C184.405014,539.393494 185.328827,538.490662 186.093338,537.936584 
	C188.163788,536.436157 187.703995,535.071899 186.254410,533.451904 
	C184.734116,531.752869 182.639664,530.005859 185.931793,527.899292 
	C187.115829,527.141602 186.303833,526.328369 185.751724,525.421204 
	C183.819397,522.246277 184.099380,521.872620 187.679199,521.354248 
	C189.184326,521.136292 190.677612,520.279663 190.064178,518.394104 
	C188.972061,515.037170 191.537369,515.466797 193.650269,515.002258 
	C195.955719,513.682800 197.112549,515.002808 198.013794,516.406921 
	C199.061371,518.038940 198.135406,519.132080 196.671738,520.000305 
	C194.051331,521.554749 194.597092,523.903625 195.418747,526.121094 
	C196.576065,529.244507 197.406815,532.230469 196.652863,535.716919 
	C195.887390,539.256653 198.723694,541.326294 201.083740,543.434021 
	C202.709885,544.886230 205.388000,545.657959 204.658569,549.011292 
	C204.248444,549.702820 203.950638,550.052368 203.399139,550.607117 
	C200.070999,551.800232 202.632004,553.514038 202.561691,554.547302 
	C202.290802,553.789612 201.831619,552.699585 202.634445,551.273926 
	C203.256577,550.720459 203.612061,550.402100 204.226776,549.832764 
	C206.918259,549.460632 206.913879,551.223206 206.999481,552.836853 
	C207.117294,555.057800 207.091309,557.278198 206.246674,559.728516 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M205.630783,579.978149 
	C205.893784,581.185486 206.847168,582.257263 206.098724,583.723511 
	C201.586670,583.729065 202.566666,579.696838 201.380783,577.217651 
	C201.002823,576.427490 201.818130,575.575562 202.665939,575.407288 
	C203.806870,575.180908 204.544830,575.845154 204.713821,577.000305 
	C204.858109,577.986572 203.512604,579.272705 205.630783,579.978149 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M205.684509,597.894165 
	C206.140900,598.677185 206.307510,599.353760 206.546463,600.279175 
	C204.720200,602.101318 203.999496,600.067627 202.864655,599.302063 
	C203.762466,598.888245 203.307281,596.213928 205.684509,597.894165 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M190.946777,591.541077 
	C190.249100,591.839844 189.649063,591.792786 188.774689,591.631104 
	C188.342026,591.194763 188.183701,590.872986 187.958923,590.289307 
	C188.662781,589.801758 189.433105,589.576050 190.474625,589.211792 
	C190.845352,589.780518 190.944885,590.487915 190.946777,591.541077 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M209.960327,597.688599 
	C209.598755,597.973633 209.176529,597.975708 208.441086,597.928467 
	C208.104279,597.486694 208.080673,597.094177 208.094101,596.409180 
	C209.025848,596.127502 209.698303,596.443420 209.960327,597.688599 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M622.502808,851.257568 
	C619.003052,849.170837 615.169189,849.494263 611.404114,848.147827 
	C608.996826,843.821411 613.658386,843.114258 614.890564,843.366394 
	C618.933655,844.193848 620.674072,842.532959 621.912903,839.222595 
	C622.123352,838.660217 622.829651,838.294189 623.606567,837.633240 
	C624.976135,836.789978 626.203796,836.672791 627.753479,836.363831 
	C632.623657,836.597534 636.189087,839.592102 640.800537,840.650513 
	C645.885437,840.443909 650.618164,839.513733 655.695312,840.841675 
	C658.159668,840.915894 660.271301,840.923279 662.605164,841.986084 
	C664.077515,843.420349 665.507629,844.078613 667.634888,844.026367 
	C668.794617,845.786316 669.676819,847.417053 672.410522,846.949524 
	C673.240173,847.286682 673.461609,847.754395 673.210449,848.691650 
	C670.477417,854.829712 667.304565,849.625244 664.206421,849.288696 
	C663.474670,848.581787 663.088318,847.837769 662.119995,848.586792 
	C661.188721,850.721069 658.873047,849.308105 657.576660,850.915771 
	C660.248474,854.434204 664.330017,855.193787 668.175720,856.143799 
	C675.044617,857.840576 681.981506,859.263184 688.897095,860.768005 
	C690.038635,861.016418 691.242065,861.171448 691.920166,859.365845 
	C693.750916,859.652527 695.485840,860.298340 697.567078,860.995300 
	C698.282410,862.985535 696.607483,862.496582 695.339600,862.909058 
	C688.960754,862.588196 682.937683,862.272034 676.452759,861.932617 
	C670.359802,860.274353 664.737183,858.310608 658.665161,861.789917 
	C653.423340,861.596741 648.497498,861.080078 643.277344,860.287354 
	C643.502258,856.148254 646.666626,857.680908 649.194458,857.125610 
	C652.527283,857.401428 655.300171,858.668823 658.316467,858.655029 
	C654.822205,858.686462 651.648438,856.819763 647.756226,857.005920 
	C642.231140,853.366211 635.442444,852.113159 631.732422,845.889648 
	C631.429016,845.417603 631.314819,845.227661 630.920349,844.460815 
	C630.533142,843.489929 630.469055,843.089722 630.332642,842.944275 
	C630.055786,843.336487 629.909485,843.465454 629.509705,843.736267 
	C628.597107,844.216614 627.804932,844.328308 627.181763,845.245728 
	C626.908569,845.654053 626.795166,845.817383 626.493286,846.220947 
	C626.167358,846.616516 626.029907,846.771912 625.679077,847.157227 
	C624.145874,848.447266 625.273315,851.128418 622.502808,851.257568 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M622.266724,851.293945 
	C624.658630,850.316467 621.785950,846.948059 624.846741,846.208435 
	C627.352600,846.735413 627.478821,843.824646 629.506470,843.530701 
	C629.773010,843.345703 629.930420,843.128052 630.025696,843.033386 
	C630.120972,842.938721 630.543274,842.702576 630.743774,842.838013 
	C630.944275,842.973450 631.009888,842.991699 631.025513,843.021973 
	C631.041077,843.052246 631.333557,843.371704 631.505005,843.504028 
	C634.461365,847.697754 637.446777,851.364258 642.804626,852.289185 
	C644.173950,852.525574 645.291199,854.222900 646.787231,855.465576 
	C647.425232,856.710815 648.857605,856.383179 649.130981,857.742554 
	C647.355408,859.451721 644.844788,857.384705 643.176453,859.751526 
	C640.596619,859.689575 638.210205,859.367981 635.420410,859.028076 
	C631.895203,858.034790 630.467773,854.950317 627.882568,853.270630 
	C626.315552,852.252563 626.442749,852.056824 623.338074,852.020142 
	C622.691650,851.907288 622.481506,851.730591 622.266724,851.293945 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M688.251831,867.239014 
	C690.432678,867.398682 692.864136,865.421570 695.688965,866.941528 
	C693.662964,868.795410 691.084778,868.073486 688.251831,867.239014 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M701.899597,867.038025 
	C702.890930,865.616272 704.485046,865.527222 706.189026,866.463867 
	C706.217163,866.479248 706.158020,866.758362 706.071533,866.850586 
	C705.965393,866.963745 705.787598,867.009583 705.325317,867.157898 
	C704.121582,867.174683 703.233337,867.118225 701.899597,867.038025 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M658.140076,862.389282 
	C658.349182,862.113403 658.520447,862.221313 658.616028,862.250854 
	C658.851257,862.900391 658.990845,863.520264 659.186707,864.454590 
	C659.203491,864.940613 659.163940,865.112122 658.873291,865.365479 
	C656.256348,865.050476 656.789062,863.933472 658.140076,862.389282 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M700.494385,867.213379 
	C700.619080,866.845886 700.881531,866.785767 701.353638,866.949646 
	C701.207825,867.101013 700.897461,867.216736 700.494385,867.213379 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M659.081543,864.932922 
	C659.855103,865.281006 660.103943,865.689819 658.799316,865.577515 
	C658.622253,865.447205 658.920166,865.096802 659.081543,864.932922 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M241.955338,685.579102 
	C241.248123,685.192932 241.240936,684.803650 241.744095,684.459229 
	C241.849548,684.387085 242.094147,684.389465 242.196976,684.464294 
	C242.689270,684.822205 242.671646,685.211304 241.955338,685.579102 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M235.916748,684.462402 
	C237.285019,684.311890 237.026108,685.310303 236.952652,686.191406 
	C236.939651,686.347290 236.613190,686.476990 236.431458,686.618774 
	C236.222198,686.063538 236.012955,685.508301 235.916748,684.462402 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M235.793854,684.193481 
	C235.095459,683.776367 234.579285,682.950134 235.199432,682.461304 
	C236.071671,681.773621 235.805573,682.939026 236.034973,683.635010 
	C236.029800,683.971741 235.872192,684.119263 235.793854,684.193481 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M298.868896,758.908630 
	C298.821716,761.190247 296.795197,765.032104 301.695374,761.691589 
	C302.533234,761.120422 301.775482,762.838501 301.990417,763.996582 
	C301.943085,764.734863 301.944611,764.910156 302.076050,765.318115 
	C302.329376,765.687134 302.452698,765.823486 302.755676,766.177734 
	C303.516510,767.199707 304.084778,768.012329 304.726807,769.159302 
	C304.938080,769.657776 305.010101,769.865540 305.164734,770.397949 
	C306.633118,774.783081 306.836945,779.139526 308.275696,783.352539 
	C308.081848,783.769348 309.467499,783.293701 308.800873,783.299805 
	C308.439270,783.303101 308.345123,783.168579 308.518402,782.896240 
	C310.510620,779.764709 311.498016,779.710327 313.072174,782.779968 
	C313.668457,783.942749 313.803741,785.376343 315.692200,785.867920 
	C317.350983,786.672729 317.040466,788.020508 317.346252,789.519165 
	C319.832458,792.612244 321.714722,795.665833 322.985962,799.083374 
	C323.266388,799.837280 323.472107,800.619080 323.730530,801.758118 
	C323.517120,802.632202 323.285400,803.136169 323.024109,803.933105 
	C320.695587,804.317322 318.804932,798.709412 315.830688,803.871033 
	C314.601898,804.099976 313.900177,803.532288 312.810913,803.143799 
	C311.540649,802.604919 310.530151,802.248474 309.511536,801.273193 
	C309.317688,800.417358 309.344360,799.792297 309.664917,799.040039 
	C310.120178,799.057068 310.281494,799.201355 310.722137,799.180237 
	C310.247131,796.219360 307.315247,794.628113 306.967224,791.314148 
	C307.579285,790.013306 310.003052,788.694946 306.312347,788.235718 
	C304.072968,783.553589 302.072845,779.078796 299.916748,774.264893 
	C297.829407,769.724426 297.361542,765.134888 296.223633,760.722168 
	C295.701630,758.697815 295.052155,756.734802 295.831909,754.282837 
	C297.977936,754.837952 297.275604,757.362610 298.868896,758.908630 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M330.980865,805.387573 
	C332.449219,804.948608 334.597260,804.676819 333.784668,802.734131 
	C331.951721,798.351929 333.173767,793.494446 330.711243,789.215576 
	C334.479095,787.884583 335.910858,791.220703 337.389221,792.499084 
	C343.321564,797.628967 348.119263,803.818848 349.268250,812.282471 
	C345.914185,810.317688 344.297058,805.380249 338.595245,807.123657 
	C336.724457,806.637878 335.332153,806.135620 333.758026,807.168823 
	C332.209076,808.185547 331.479156,807.070801 330.980865,805.387573 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M326.040375,798.333984 
	C326.903290,798.097900 327.712372,798.361694 327.901062,799.813416 
	C327.004578,800.021851 326.294495,799.654114 326.040375,798.333984 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M329.088654,803.300903 
	C329.945831,803.097961 330.721649,803.380798 330.915405,804.756836 
	C330.010681,804.991577 329.262054,804.688599 329.088654,803.300903 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M356.933167,834.056152 
	C356.766846,833.893799 356.652740,833.682861 356.538635,833.471863 
	C356.663361,833.510803 356.788086,833.549744 356.997864,833.746033 
	C357.082886,833.903320 356.985352,834.007446 356.933167,834.056152 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M202.579865,622.890320 
	C202.770020,622.226807 202.626282,621.445190 202.719727,621.415771 
	C204.130692,620.970764 203.716782,622.193481 203.888412,622.822632 
	C204.103790,623.612183 204.216980,624.561829 203.014175,624.509094 
	C202.393311,624.481812 201.901825,623.908020 202.579865,622.890320 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M484.910156,869.311279 
	C486.480347,866.445740 487.910400,869.143982 489.351379,869.394836 
	C491.715118,869.806274 493.941833,871.005188 496.590454,871.958740 
	C498.239624,873.561279 500.657410,873.816895 501.437592,876.322388 
	C499.226501,877.935547 496.860504,875.482239 494.210297,876.906555 
	C492.436920,875.783447 491.012939,874.525574 489.281555,873.176025 
	C487.658264,871.932434 486.342377,870.780640 484.910156,869.311279 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M476.963074,865.263611 
	C478.118744,865.818176 478.598999,867.039673 478.986664,868.668091 
	C476.878510,868.735596 475.036835,868.286072 476.963074,865.263611 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M472.117371,861.217163 
	C472.337860,861.090637 472.598969,861.274536 472.899719,861.769592 
	C472.679626,861.894836 472.419189,861.710449 472.117371,861.217163 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M473.122620,862.222168 
	C473.342621,862.094666 473.612122,862.282898 473.942200,862.779297 
	C473.788574,862.814514 473.511719,862.631287 473.122620,862.222168 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M480.056152,869.149658 
	C480.131439,868.986267 480.308380,868.986023 480.750916,868.989258 
	C480.730286,869.099426 480.444031,869.206055 480.056152,869.149658 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M309.199402,801.396851 
	C310.292023,801.272522 311.293060,801.502686 312.585022,801.855591 
	C313.549896,802.547180 313.718536,803.271057 313.300659,804.429321 
	C312.842896,806.328186 313.206940,807.651917 314.880615,808.804504 
	C315.163086,809.270325 315.225372,809.447693 315.073059,809.807800 
	C314.413696,809.989746 313.968964,809.988953 313.190918,810.004517 
	C310.895294,807.587463 310.435699,804.472473 309.199402,801.396851 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M306.039307,788.347717 
	C306.394165,788.023743 306.763275,787.912842 307.028290,788.032532 
	C308.358307,788.632812 309.386688,785.193237 310.523804,787.525452 
	C311.718781,789.976196 309.310303,790.303955 307.367615,790.959351 
	C306.678345,790.218445 306.341949,789.442810 306.039307,788.347717 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M310.746399,798.932129 
	C310.776184,799.317261 310.586121,799.741943 310.047485,799.088501 
	C310.136353,798.891724 310.313843,798.870544 310.746399,798.932129 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M391.093567,788.036377 
	C390.868927,786.996704 390.733917,786.241394 389.375488,785.979614 
	C389.815979,783.929016 388.565277,781.643311 389.902588,779.356323 
	C391.426849,777.542603 394.151825,777.075867 394.126587,774.336975 
	C394.110626,772.605347 393.175568,770.959229 391.566132,770.480286 
	C389.118561,769.751953 387.196014,770.897827 385.994995,773.568848 
	C385.925415,774.291809 385.754059,774.548889 385.180786,774.818726 
	C384.970215,774.969727 384.979248,774.978271 384.968048,774.963623 
	C382.985809,774.299072 381.014771,773.649170 378.380829,772.828491 
	C376.761932,771.057312 377.258484,769.158569 376.725067,767.148132 
	C378.652954,764.838196 380.859100,763.037415 380.291992,759.504333 
	C381.258331,756.931641 382.323700,754.797180 384.573792,753.123047 
	C384.962799,752.819092 385.116791,752.702515 385.484436,752.407471 
	C385.946228,751.959167 386.158539,751.663635 386.338837,751.060181 
	C386.031769,749.148987 385.124329,747.736023 384.653503,745.826904 
	C386.147949,742.069641 390.054413,741.951904 393.023804,739.246826 
	C385.927094,740.716919 385.642517,735.479614 383.927124,731.192383 
	C383.467377,730.883667 383.288269,730.754272 382.880188,730.387817 
	C381.911285,728.990723 382.215118,727.681580 382.022766,726.139160 
	C381.821289,725.669250 381.716064,725.489807 381.415253,725.059448 
	C379.766724,723.300537 378.572723,720.630432 376.358032,724.235901 
	C375.408722,725.781372 373.721497,726.056641 371.850861,724.605957 
	C371.355438,723.986938 371.138763,723.591248 370.839386,722.866455 
	C370.622833,722.362427 370.533051,722.163696 370.267639,721.693237 
	C368.398315,719.798096 365.387787,719.159119 366.001801,715.521729 
	C365.746948,714.165710 368.670074,713.805908 366.534393,712.289795 
	C365.056885,711.241028 364.802551,709.466125 365.566406,707.789185 
	C366.238647,706.313416 367.302338,705.019775 368.916168,706.692444 
	C371.264282,709.126099 374.669098,709.996399 377.270935,712.593262 
	C378.086670,713.125916 378.628784,713.476807 379.470276,713.945801 
	C379.919037,714.221802 380.091919,714.341064 380.497498,714.670105 
	C381.148590,715.338623 381.473969,715.865112 382.022461,716.599426 
	C384.427246,718.038086 386.071655,717.922607 386.747375,714.738770 
	C387.500824,714.089050 386.771515,714.512451 387.322601,714.137390 
	C390.502319,711.973755 394.998047,711.241943 396.073578,706.357422 
	C400.437561,707.895142 403.084808,712.073120 407.998688,712.181580 
	C409.307098,712.210510 409.823761,714.345886 410.544006,716.023682 
	C410.686157,717.429871 410.206085,718.190979 409.005707,718.873169 
	C406.735626,720.184387 403.361420,719.174805 403.113098,723.039062 
	C403.249329,723.550781 403.248474,723.769287 403.088318,724.270142 
	C401.430176,726.402649 403.316711,726.700562 404.266235,727.257629 
	C406.759674,728.720459 407.629059,730.660461 406.235657,733.662598 
	C405.612640,734.646667 405.039886,735.271484 404.606506,736.334717 
	C404.328796,738.069641 405.369049,738.933411 406.243347,740.240112 
	C406.516541,740.731934 406.607513,740.940430 406.770020,741.485718 
	C407.053680,743.091553 405.984863,743.960693 407.601807,745.758606 
	C410.604309,749.097168 412.865509,752.067505 417.987030,751.880981 
	C419.965698,751.808838 421.969788,754.119690 423.030060,756.280151 
	C426.771637,763.903931 426.746643,763.916199 434.809204,762.759705 
	C436.641052,765.765930 437.141663,769.052124 439.021790,772.137085 
	C439.072357,774.900513 438.681885,777.136658 436.119141,778.704102 
	C430.108948,778.902527 425.524658,775.288391 419.845123,774.225037 
	C419.053955,774.088257 418.599060,774.009155 417.796692,773.859375 
	C414.190063,773.175964 410.557831,773.531860 408.093933,770.011475 
	C406.752930,768.991089 407.992096,766.343750 405.383362,766.986206 
	C405.286255,768.047241 405.638092,768.702454 406.289795,769.575073 
	C406.544800,770.044006 406.627319,770.245422 406.769501,770.774292 
	C404.498047,774.543091 405.737152,779.030457 403.250366,782.741516 
	C400.871521,788.385925 402.106598,791.545532 408.509949,791.429199 
	C409.021545,791.419861 409.702728,792.074341 410.036194,792.593140 
	C410.956726,794.025330 414.182648,793.958191 413.228516,796.246033 
	C412.591309,797.773804 410.275757,796.969849 408.686401,796.768005 
	C407.978546,796.677979 407.406891,796.649414 407.042572,797.731873 
	C406.986542,798.048828 406.495819,798.018188 406.250366,798.004211 
	C404.893158,796.745239 403.482513,795.824402 402.333527,794.624268 
	C401.392120,793.641052 400.971039,794.437500 400.225311,795.286865 
	C398.854095,795.090820 397.736969,794.697632 396.349670,794.173523 
	C394.402527,793.939453 392.498993,793.903137 393.393646,791.389404 
	C394.132874,789.312439 392.614685,788.916199 391.093567,788.036377 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M257.275513,734.743469 
	C257.006165,733.608643 257.009888,732.221619 257.021301,730.418457 
	C257.028992,730.002441 257.503601,730.005920 257.740540,730.013550 
	C257.834503,731.511169 257.691528,733.001221 257.275513,734.743469 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M272.074707,748.084961 
	C272.407043,748.282471 272.722198,748.817627 273.065674,749.673218 
	C272.748535,749.469971 272.403015,748.946289 272.074707,748.084961 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M207.912872,747.485474 
	C208.218369,747.634583 208.499802,747.884827 208.680847,747.935791 
	C209.141510,749.669861 209.390900,751.386841 207.088882,751.815613 
	C206.043427,752.010315 205.132507,751.680481 204.940262,750.410034 
	C204.627182,748.341125 205.488770,747.294006 207.912872,747.485474 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M357.878052,815.071045 
	C357.936981,816.748291 357.975006,818.149780 357.994385,820.062622 
	C356.370514,819.270935 354.765350,817.967896 353.007874,816.360413 
	C353.200043,814.846985 354.740295,814.128723 354.141724,812.293762 
	C353.984131,811.263855 353.963409,810.529114 353.995056,809.372559 
	C354.361053,809.072449 354.621704,809.265503 354.958496,809.739014 
	C356.010803,811.563782 356.933990,813.179565 357.878052,815.071045 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M353.889648,812.214233 
	C356.616028,813.650574 356.337677,814.953430 353.191559,816.003235 
	C352.575226,815.906494 352.294861,815.756897 351.978516,815.359985 
	C351.531128,814.376587 351.834290,813.801208 352.676086,813.138977 
	C353.313416,812.890076 353.572723,812.699036 353.889648,812.214233 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M352.709259,812.719543 
	C352.728088,813.527771 352.459595,814.052429 352.066833,814.844849 
	C350.877136,814.978943 350.252533,814.236877 349.539337,813.198059 
	C350.318054,812.150269 351.299744,811.874939 352.709259,812.719543 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M350.540161,822.277222 
	C350.963043,821.398193 351.140625,820.115967 352.549774,820.868652 
	C352.793091,820.998535 352.923004,821.340637 353.105316,821.584717 
	C352.540375,821.812683 351.975433,822.040710 351.111511,822.387817 
	C350.812561,822.507019 350.599060,822.385071 350.540161,822.277222 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M513.776489,872.627686 
	C515.307800,871.569641 516.518005,872.448547 517.859131,873.743164 
	C515.367554,878.202820 515.333496,873.060303 513.776489,872.627686 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M509.214996,873.086670 
	C509.179169,872.970703 509.132172,873.028381 509.091003,873.029297 
	C509.049805,873.030273 509.250824,873.202698 509.214996,873.086670 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M534.906494,879.774170 
	C535.055420,879.584473 535.366272,879.557190 535.830383,879.732178 
	C535.983704,879.934448 535.526123,880.003418 535.526123,880.003418 
	C535.526123,880.003418 535.068298,879.936523 534.906494,879.774170 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M624.909973,864.713318 
	C624.449524,864.397766 623.990295,864.052307 623.221558,863.409546 
	C622.912109,863.112305 623.022705,862.985107 623.079956,862.923279 
	C623.815796,862.388428 624.428894,862.528015 625.097290,863.396118 
	C625.194580,863.731567 625.038635,864.175049 624.995667,864.407715 
	C624.952698,864.640381 624.911194,864.683411 624.909973,864.713318 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M176.871521,576.126282 
	C177.761841,576.098145 178.571533,576.239136 178.514023,576.985107 
	C178.428925,578.088867 177.386917,577.870056 176.298126,577.963745 
	C176.179428,577.396057 176.392578,576.839905 176.871521,576.126282 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M174.212631,578.234985 
	C174.072998,578.089966 173.937531,577.895203 173.802063,577.700500 
	C173.901947,577.767395 174.001816,577.834290 174.208450,578.051270 
	C174.315216,578.201416 174.216812,578.185303 174.212631,578.234985 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M150.770142,584.950928 
	C150.626724,586.206177 150.712051,587.801086 149.250671,587.413147 
	C148.517578,587.218445 148.811325,585.780273 149.691833,584.778198 
	C150.209381,584.625793 150.418823,584.647278 150.770142,584.950928 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M150.941895,584.931152 
	C150.745605,585.149719 150.579163,585.066467 150.206329,584.793762 
	C149.999939,584.604309 149.982819,584.487427 149.961975,584.434082 
	C150.113724,584.304138 150.286331,584.227478 150.735031,584.087341 
	C150.998001,584.225708 150.984879,584.427490 150.941895,584.931152 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M313.412964,804.867432 
	C313.064941,804.055664 313.057251,803.357544 312.962769,802.318909 
	C314.234894,801.693665 314.645203,802.649109 315.272369,803.818237 
	C315.747253,804.985840 317.593109,805.876282 316.496307,806.721741 
	C314.832550,808.004333 314.917328,805.246094 313.412964,804.867432 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M315.034485,809.936523 
	C314.858459,809.990601 314.947723,809.532349 315.024292,809.312683 
	C315.225037,809.276794 315.362274,809.454102 315.459320,809.651245 
	C315.473694,809.680420 315.298157,809.803040 315.034485,809.936523 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M684.962769,854.635620 
	C683.534607,854.297363 680.304565,856.819458 680.905701,852.344482 
	C681.783813,851.760193 682.584106,851.496216 683.689758,851.138611 
	C684.800293,851.980347 684.968262,853.106750 684.962769,854.635620 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M695.183350,863.161987 
	C695.850098,862.449951 696.716309,861.995544 697.747986,861.293823 
	C698.639343,861.322693 699.795227,861.415222 700.006897,861.910889 
	C700.544800,863.171082 699.275879,862.983154 698.615356,863.015747 
	C697.522156,863.069641 696.565186,864.192383 695.183350,863.161987 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M775.770386,567.738098 
	C778.874084,566.428162 780.821289,564.093750 781.714417,560.964417 
	C781.920471,560.242432 781.370117,559.196045 782.590820,559.010315 
	C784.218689,558.762817 785.207275,556.455994 787.037537,557.349854 
	C788.782776,558.202209 786.075562,560.127319 787.936523,561.471252 
	C791.813965,557.277283 793.401123,550.942932 799.709351,547.976501 
	C799.137451,552.356140 795.585205,554.941711 795.500793,558.860229 
	C795.467346,560.413696 794.127319,562.182190 797.207458,562.672852 
	C797.989319,562.036865 798.467041,561.562683 799.456543,561.190430 
	C800.808350,561.208252 801.833435,562.260620 803.054565,560.805725 
	C804.165039,560.385498 804.912903,560.774109 805.772217,561.661316 
	C808.040039,565.353882 811.512268,566.800598 814.924805,568.407471 
	C816.002686,568.915039 817.848511,569.351196 817.422913,571.046814 
	C817.000671,572.729736 815.016541,571.516296 813.946960,572.307373 
	C814.641663,574.827454 817.214050,572.847717 818.474976,574.212646 
	C820.866150,576.801208 820.165955,580.968994 816.643250,582.181458 
	C814.138123,583.043884 814.670593,586.606628 813.377686,586.533813 
	C810.783386,586.387695 812.822510,582.698669 810.661377,581.342163 
	C808.703735,580.113403 806.504211,580.305542 804.255737,579.276367 
	C804.002441,579.002441 804.158203,578.840881 804.077881,578.919189 
	C801.159790,579.002930 798.264587,578.810425 796.071167,581.639587 
	C793.250916,587.367554 787.618286,589.295105 783.192200,592.640808 
	C779.661621,595.309631 775.757080,597.380066 773.885498,601.873047 
	C773.179199,603.568726 771.924805,605.976746 768.780457,605.087646 
	C767.376770,602.947754 767.687988,600.929199 768.412476,599.069214 
	C771.201660,591.908264 771.375244,584.098328 774.011230,576.903381 
	C774.321472,576.056519 774.965088,575.097534 774.510681,574.502563 
	C774.386230,576.260132 773.640625,578.141846 772.924255,580.332520 
	C770.400146,577.074585 770.400146,577.074585 771.678406,572.620972 
	C769.273376,579.645508 768.988098,579.827820 761.802368,577.102600 
	C759.644165,576.283997 758.245544,576.819946 756.409485,578.143494 
	C751.756165,581.498169 749.881042,586.351013 748.855103,591.507080 
	C747.304077,599.301819 743.647827,606.613647 743.360840,615.043823 
	C742.713867,615.676147 742.250000,615.916626 741.348267,616.167236 
	C739.854675,616.392395 738.788269,616.277527 737.417114,615.688721 
	C736.156616,611.057495 737.884460,606.875000 738.465515,602.599548 
	C738.930786,599.175720 739.810425,595.789062 740.208496,592.338379 
	C740.409058,590.600342 740.041748,588.979431 738.180237,588.238770 
	C736.553589,587.591553 735.781494,588.757812 735.008179,589.879211 
	C734.035278,591.290100 732.932739,592.095215 731.241821,590.154907 
	C730.500061,583.442505 731.694092,577.319641 733.813232,570.948975 
	C734.409485,569.055237 738.360657,568.558777 735.263428,565.880371 
	C734.680420,565.376221 735.166565,563.904541 735.732605,563.090881 
	C738.708862,558.812500 738.734863,553.363525 741.450745,548.662903 
	C741.560364,547.778625 741.619202,547.205017 741.363403,546.404297 
	C741.099915,546.054199 740.969543,545.931396 740.595398,545.690002 
	C739.352234,545.481750 739.020691,546.151001 738.726562,547.294373 
	C738.188110,548.959473 738.140930,551.012695 736.318848,550.781738 
	C734.636353,550.568542 734.661621,548.566956 734.602722,547.164734 
	C734.527832,545.382324 734.159119,543.619751 734.387573,541.453674 
	C737.842346,537.684387 737.325012,533.076538 738.401733,528.557068 
	C738.644836,524.052551 738.592468,519.937744 738.475220,515.418457 
	C738.801392,512.025330 738.007507,508.953827 738.804199,505.987274 
	C738.011475,508.866486 738.996094,511.988037 737.418335,515.025452 
	C735.699280,509.150146 732.347534,503.756439 733.314819,496.903625 
	C733.911133,496.173523 734.354492,495.662354 734.998108,496.226837 
	C735.343811,496.529999 735.381042,497.032318 735.608765,497.418976 
	C735.461060,496.826447 735.280823,496.250793 734.531006,495.620850 
	C733.143799,493.820831 732.844421,491.979950 732.606506,489.762573 
	C732.348267,486.736450 730.844116,483.096710 734.889465,482.500702 
	C739.115784,481.878021 738.782959,485.908081 739.301941,488.908936 
	C741.032349,483.765137 745.540833,480.311646 750.656982,480.420685 
	C751.270874,480.907440 751.511719,481.275482 751.724487,482.019104 
	C751.852966,483.418518 752.237488,484.377625 753.094971,485.519073 
	C754.994751,491.041168 756.677795,496.277222 756.946167,502.194702 
	C756.674805,503.030426 756.438782,503.467377 755.897522,504.139099 
	C751.215393,507.104919 749.504150,511.476746 748.394165,516.561768 
	C748.387329,517.354431 748.392151,517.807983 748.422485,518.603394 
	C748.882629,521.010254 748.838501,523.076111 748.432739,525.486572 
	C748.403442,526.059753 748.395630,526.288818 748.376099,526.860840 
	C748.287476,528.956970 747.943237,530.653870 747.385315,532.319946 
	C746.229004,535.773010 748.882202,533.217163 749.961487,533.669312 
	C758.124146,532.593445 758.124146,532.593445 760.748169,539.032471 
	C761.373047,540.687622 761.365723,542.041992 761.420715,543.803711 
	C761.411926,546.173462 761.460815,548.131104 761.152649,550.487671 
	C759.453003,554.141479 759.473267,557.639893 759.491333,561.509583 
	C759.229736,564.108765 758.409546,566.245728 758.450867,568.522705 
	C758.513733,566.645142 758.919922,564.829041 759.455688,562.666992 
	C759.456116,559.486145 759.445312,556.667969 760.160034,553.513916 
	C761.197083,551.731995 761.738403,550.214355 761.615906,548.486511 
	C761.387695,545.267700 762.793335,543.296448 765.850891,542.045166 
	C770.967834,539.950989 771.377808,539.995850 773.616516,545.180542 
	C773.948975,545.950562 774.492371,546.577698 775.291626,547.488220 
	C776.341614,549.344788 776.644470,551.122864 775.834534,552.572510 
	C773.761292,556.282898 774.038574,560.529114 772.868652,564.423706 
	C773.720825,561.622192 773.805176,558.665588 774.619751,555.470520 
	C774.926208,554.969299 775.142761,554.837708 775.730591,554.786499 
	C777.338501,559.134460 775.848816,563.275208 775.770386,567.738098 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M800.877441,418.279785 
	C800.880859,417.945648 800.928894,417.469757 800.953613,417.231812 
	C804.908020,416.549713 806.063171,418.782227 806.001343,422.601532 
	C809.057129,422.806091 810.323425,424.420563 809.964844,427.248047 
	C809.507568,430.853424 810.047241,434.971283 805.426270,436.716248 
	C804.752563,436.970673 804.518005,438.388000 804.042847,439.637268 
	C812.378662,439.987183 812.786743,440.628632 809.451660,448.673157 
	C808.839661,450.149445 808.526917,451.964539 809.711609,452.876495 
	C812.338257,454.898438 810.966003,460.690338 816.554199,459.931305 
	C817.738281,459.770447 817.884216,461.622650 818.385193,462.642334 
	C818.955750,463.803680 819.354980,465.239319 821.266602,464.220398 
	C823.447876,463.057709 823.280518,465.375610 822.714539,465.740295 
	C818.557434,468.419220 820.385010,472.494843 820.025635,476.494934 
	C820.906982,478.881012 822.260071,479.984741 824.754517,479.778717 
	C825.046021,481.345673 828.987488,483.011383 824.333130,483.996582 
	C823.077576,484.732971 822.621277,485.549896 823.755127,486.815491 
	C824.170532,487.560822 824.341064,488.108246 824.748474,488.827820 
	C825.830688,489.871429 826.735779,490.707397 826.768921,492.398193 
	C825.628662,495.637329 822.636780,493.795715 820.570312,495.179291 
	C818.948730,494.517242 817.884460,494.332642 817.582520,496.644257 
	C815.745178,499.231110 816.994263,502.216125 815.341797,504.847534 
	C814.551270,505.252930 814.081543,505.126831 813.447449,504.443909 
	C812.275208,500.397430 811.809509,496.591705 811.353271,492.410736 
	C811.170898,491.816589 811.107483,491.577911 810.969360,490.980103 
	C810.657532,488.739502 810.512146,486.866180 811.436646,484.776703 
	C811.071472,484.146088 812.284607,483.663513 811.432068,483.662170 
	C811.471741,484.334167 811.400940,484.721222 811.143311,485.386414 
	C808.709473,490.155762 810.069214,494.793732 810.463013,499.735626 
	C810.467285,500.311981 810.429077,500.541870 810.202026,501.066010 
	C807.652893,502.757172 805.169128,503.872009 808.853333,506.838806 
	C812.026367,509.394012 808.366333,510.004395 806.839050,511.426575 
	C806.127441,511.838287 805.698730,512.033203 804.918335,512.307495 
	C804.343628,512.439758 804.109009,512.467224 803.526306,512.437012 
	C801.687073,511.766571 801.141235,510.482086 800.780334,508.914368 
	C799.992065,510.180664 800.372864,511.713104 799.320801,513.107666 
	C798.484619,513.755615 797.818054,513.884583 796.786499,513.734863 
	C793.892883,512.858398 792.449951,514.685791 792.111572,516.476257 
	C791.719971,518.548828 793.927246,517.665771 795.065125,517.859131 
	C802.043457,519.045166 802.093811,519.034302 801.970398,526.264465 
	C801.935974,528.278198 803.316833,529.603088 803.951050,531.640869 
	C802.785828,535.361572 801.978821,539.047913 797.306519,539.975769 
	C796.342651,539.405029 795.493347,539.329895 794.604248,538.697754 
	C793.674255,536.895447 791.833862,535.827209 792.028931,533.783752 
	C792.097473,533.065613 791.724854,531.656067 792.783264,531.908569 
	C793.968018,532.191284 795.223267,534.284546 794.969971,531.061584 
	C794.831177,529.295471 794.270569,528.369873 792.210754,528.700562 
	C789.707520,529.102539 788.438843,527.395630 787.270203,525.194580 
	C786.014038,525.321106 784.956970,525.466553 783.776367,526.203064 
	C782.618408,526.959351 781.618591,527.358459 780.465576,528.161133 
	C779.945129,528.368164 779.720581,528.415039 779.149658,528.412842 
	C778.360229,528.174805 777.953003,527.925903 777.306641,527.370117 
	C776.712585,526.719543 776.441162,526.293152 776.084229,525.468506 
	C775.452271,523.030762 775.675781,520.916565 775.580322,518.421631 
	C775.564819,517.220642 775.568420,516.418152 775.538452,515.210571 
	C774.533936,509.730347 774.296326,504.576508 774.037598,499.074921 
	C775.357605,496.963074 776.626892,497.151794 777.636108,498.789825 
	C780.734619,503.818909 786.163147,503.833710 790.940430,504.492889 
	C794.782715,505.023041 797.313232,501.400635 796.435364,497.523438 
	C795.794434,494.692719 795.465149,491.998138 796.579773,489.253571 
	C797.738159,486.400970 796.722839,485.131317 793.707336,485.414215 
	C790.280090,485.735840 789.899719,484.170013 791.381470,481.593475 
	C792.335327,479.934937 792.594543,478.211548 792.492493,476.344208 
	C792.319885,473.184021 792.389038,470.064087 793.997925,467.164612 
	C794.814941,465.692230 794.684082,464.555328 792.187622,464.757111 
	C791.623169,464.642670 791.405273,464.559418 790.926270,464.241150 
	C789.850830,462.224365 790.622437,460.316040 790.231689,458.147888 
	C791.006836,454.613953 793.226440,452.545624 796.016052,450.546509 
	C796.901855,450.263336 797.548950,450.234314 797.808838,449.223022 
	C797.833374,445.081055 799.283508,441.698914 801.196228,438.110962 
	C801.492798,437.615845 801.583130,437.483185 801.633301,437.423676 
	C801.840027,437.178253 802.026428,436.918793 802.230042,436.676910 
	C802.574646,436.267761 802.504578,436.097717 801.994202,436.145081 
	C802.399658,436.365845 802.312500,436.626068 801.828125,437.151489 
	C798.299011,443.267700 795.446411,449.419708 790.981079,454.550659 
	C789.831055,455.872070 788.768738,457.516235 786.322021,457.416046 
	C784.844238,456.100464 785.014526,454.800629 785.894714,453.215515 
	C786.268738,452.614166 786.914368,452.636078 786.876709,452.230835 
	C786.848755,451.930145 786.527405,452.036682 786.220276,452.738586 
	C783.653503,455.855469 780.760803,457.915863 777.028870,458.502838 
	C774.362671,458.922180 772.318542,458.424164 773.416138,454.767242 
	C774.120850,454.422699 774.390686,454.980896 774.732239,454.887115 
	C775.052063,454.799194 774.946716,454.524048 774.288696,454.158569 
	C773.060120,451.865753 771.700195,452.158997 769.999268,453.142670 
	C768.211792,454.176361 765.920898,456.031036 764.450439,453.236298 
	C763.023560,450.524384 763.626587,446.868927 766.387390,445.804260 
	C772.787781,443.335999 772.429016,437.149811 774.718933,432.476013 
	C775.513794,430.853607 772.718140,428.994080 774.158936,426.575806 
	C774.469727,426.089722 774.623535,425.915771 775.086060,425.588959 
	C775.619751,425.407623 775.844604,425.379120 776.401245,425.408997 
	C778.019348,426.266174 779.173340,427.251587 780.614502,428.373901 
	C783.256836,434.383026 780.958923,439.062286 777.567688,443.295258 
	C782.553528,442.108978 785.380371,438.708435 787.176697,433.902222 
	C788.130859,431.349487 790.254700,429.275940 792.744934,427.309204 
	C793.315491,426.919647 793.594116,426.716125 794.001343,426.285645 
	C794.258484,425.919525 794.386963,425.780365 794.797668,425.573669 
	C795.256226,425.496033 795.432556,425.486023 795.829712,425.575500 
	C796.128662,426.031891 796.345764,426.174164 796.824524,425.914673 
	C796.947205,425.727509 797.396301,425.554596 797.638000,425.518829 
	C798.247009,425.374847 798.405823,425.100494 798.551758,424.441895 
	C799.347900,422.281311 800.418823,420.572296 800.877441,418.279785 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M742.769775,615.270020 
	C741.799866,609.921021 743.470337,605.290283 744.927002,600.536438 
	C746.633057,594.968445 748.838379,589.474731 748.994934,583.536560 
	C749.047791,581.532288 750.949646,581.619812 751.741943,580.683228 
	C755.814636,575.868652 759.842041,572.546753 766.134155,577.412170 
	C767.294800,578.309631 768.835449,577.688660 769.287292,575.611816 
	C769.694885,573.738831 770.716797,571.999451 771.827698,569.339111 
	C771.827698,573.004028 771.827698,575.837219 771.827698,579.661926 
	C773.894104,576.504395 773.870789,573.833984 774.649780,571.341858 
	C774.413330,579.381653 773.197693,587.278992 771.118530,595.059937 
	C770.309387,598.088074 768.725525,600.935669 768.969482,604.630981 
	C768.672729,609.113647 767.119751,612.856262 765.928162,616.681458 
	C765.344177,618.556274 764.639526,620.357727 765.064026,622.709412 
	C761.838257,629.332581 757.292786,634.891663 754.765076,641.566833 
	C754.223816,642.996216 752.129395,644.703247 753.877197,645.924072 
	C755.886108,647.327332 756.315125,644.633606 757.259460,643.499695 
	C758.204895,642.364624 759.077148,640.769897 760.787659,641.319397 
	C762.682800,641.928284 761.791931,643.837402 762.001038,645.614075 
	C761.005310,646.810791 760.590759,648.128723 759.168030,648.912109 
	C755.871765,653.263367 752.883911,657.509521 749.857788,661.728210 
	C749.172852,662.682922 749.075317,663.597412 750.037170,664.277710 
	C751.308716,665.176941 752.587891,664.488647 752.576416,663.244263 
	C752.556030,661.029297 753.707397,660.881226 755.659424,660.983215 
	C758.364441,662.854492 760.859619,662.452576 763.495422,661.605713 
	C765.776917,660.872620 768.493774,660.469177 769.362000,663.371582 
	C770.289734,666.472778 768.180786,668.213135 765.349426,669.132690 
	C758.755859,671.274292 758.760315,671.287964 759.149292,678.315308 
	C757.766113,684.430420 753.928040,680.325806 750.956787,680.421753 
	C749.254578,675.857239 752.946045,672.949036 754.020935,668.537537 
	C747.210083,670.515686 742.994507,668.219910 741.439514,661.206055 
	C740.642700,660.341003 739.963196,659.843628 739.106262,659.860046 
	C734.830139,659.942139 734.052124,657.784790 735.466309,653.886047 
	C735.509033,652.486145 735.401062,651.400330 735.854370,650.051514 
	C736.065979,649.575012 736.167053,649.394348 736.462402,648.977844 
	C736.809082,648.613525 736.961609,648.484924 737.340332,648.172119 
	C737.995789,647.574158 738.293396,647.074463 738.684875,646.257935 
	C739.346497,645.502686 739.953796,645.301208 740.973145,645.352783 
	C742.680420,645.869995 742.613281,647.665894 743.912781,648.369934 
	C748.007935,644.214966 749.372681,638.762085 751.523926,633.772644 
	C753.367432,629.496948 754.459106,624.927979 755.721802,620.439331 
	C756.148193,618.923340 756.394348,617.301697 754.487793,616.670349 
	C752.489868,616.008789 753.125610,618.052246 752.613525,618.856262 
	C751.213257,621.054626 749.616638,622.175354 747.497437,619.853027 
	C746.003662,618.216248 742.672119,618.721497 742.769775,615.270020 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M765.462524,622.989502 
	C763.751831,622.873962 762.580566,622.020020 763.149109,620.787048 
	C765.411194,615.881592 765.475708,610.212402 768.745605,605.329346 
	C771.472351,603.266113 773.701172,601.665405 773.959839,597.881470 
	C774.128296,595.416504 777.818604,594.843628 779.819397,593.330322 
	C784.880981,589.502197 790.606812,586.563110 795.690125,582.140442 
	C796.141113,585.553589 793.377258,587.507996 791.598755,590.310730 
	C795.678650,590.802979 797.211060,595.440063 801.506653,596.337708 
	C803.523865,596.759216 803.553040,599.789795 802.126770,601.470337 
	C799.565979,604.487671 797.688538,608.300903 793.504028,609.640686 
	C785.948853,612.059753 779.085999,615.678467 772.858276,620.686523 
	C771.072083,622.122925 768.204834,622.215088 765.462524,622.989502 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M818.169922,615.500977 
	C817.260315,617.073059 819.441467,619.413513 816.477539,620.068481 
	C810.164368,623.705444 803.313599,625.373962 797.159302,628.623962 
	C793.426392,630.595215 791.027039,633.578125 789.020264,637.078674 
	C788.058228,638.756897 788.099854,639.921875 790.758118,640.123169 
	C793.689331,641.057800 796.394592,641.688965 798.376587,644.304688 
	C798.699951,646.039856 797.995239,647.059265 796.864685,648.257080 
	C795.732788,648.988403 794.909607,649.493286 793.861816,648.821167 
	C793.639465,648.678528 793.188599,647.843262 793.294373,648.335999 
	C794.538330,654.124512 790.645752,655.825928 786.096191,657.231873 
	C782.394775,652.230530 778.353271,651.485291 773.827026,655.530273 
	C768.969482,659.871338 763.675903,661.256531 757.237244,659.257629 
	C757.954895,656.499756 759.501587,655.356506 762.408752,654.794373 
	C769.620117,653.399780 774.964355,648.344360 780.352051,643.796326 
	C783.357239,641.259583 782.039734,636.865417 782.741089,633.375793 
	C783.576477,629.218750 785.319702,626.118774 788.209778,623.161377 
	C791.518433,619.775696 795.143616,616.778870 797.657288,612.447998 
	C800.670654,607.255981 806.374512,611.300781 810.909119,609.852966 
	C810.836426,611.585205 809.666321,611.543884 808.914795,611.833740 
	C807.635315,612.327332 805.711609,611.434998 805.179077,613.480896 
	C804.831360,614.816467 805.451660,615.798889 806.830994,616.031860 
	C808.740295,616.354492 810.398315,619.094055 812.609985,616.664490 
	C814.095215,615.033020 815.629211,613.536804 818.169922,615.500977 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M756.760132,659.172485 
	C763.345154,658.766968 769.484009,659.025940 774.328064,653.088562 
	C777.753357,648.890137 783.912537,650.932800 785.978882,656.407959 
	C786.351440,658.998901 788.857605,658.464539 790.095825,660.079773 
	C790.414978,661.678223 789.718018,662.906189 789.822754,664.556030 
	C789.575562,665.832336 788.981262,666.491150 787.586670,667.296997 
	C786.738525,667.609802 786.513550,667.644592 785.948853,667.686768 
	C783.052673,667.660767 780.561890,668.132080 777.945435,668.945679 
	C777.045532,670.615112 777.634277,671.201721 779.361511,671.411865 
	C781.085083,675.206970 779.356934,678.567993 776.569336,677.304443 
	C772.278992,675.359619 769.251465,677.425293 765.500610,678.981689 
	C763.657837,679.567749 762.069946,679.486145 760.187561,679.731567 
	C759.406799,679.774597 758.967957,679.710693 758.284424,679.362000 
	C757.932983,677.990723 757.930847,676.818176 757.723022,675.683350 
	C756.895264,671.163635 758.516968,668.750977 762.977112,668.038086 
	C764.115845,667.856140 765.402588,668.042725 766.385315,667.569702 
	C767.960693,666.811279 769.044556,665.248901 768.468811,663.515686 
	C767.853516,661.663452 766.219299,662.071411 764.679382,662.601318 
	C762.962830,663.192139 761.149048,663.497986 759.426514,664.074280 
	C757.108582,664.849792 756.506592,663.418274 756.033508,661.328918 
	C756.174255,660.440186 756.348511,659.892517 756.760132,659.172485 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M782.780762,526.271606 
	C783.389221,523.700378 784.811035,523.719299 786.788818,524.758484 
	C785.799561,533.732788 780.216125,540.933777 778.279419,549.742554 
	C776.974670,551.346436 777.761475,552.946594 777.209351,554.711792 
	C777.003296,555.031433 776.996643,554.994019 777.015015,554.998779 
	C776.869751,555.183472 776.706055,555.363281 776.298950,555.271545 
	C776.055481,555.000000 775.576904,555.037170 775.338684,555.066772 
	C775.056885,551.901611 775.786377,548.795959 776.487488,546.105896 
	C776.730957,546.530884 776.094788,547.098022 775.328125,547.852417 
	C770.940247,547.603577 771.292908,544.689331 771.823730,541.793274 
	C764.457153,543.216797 763.708435,543.990234 762.971191,550.769348 
	C762.876953,551.636414 762.675476,552.391296 761.440552,552.914246 
	C760.740234,552.823792 760.514893,552.540955 760.398071,551.888306 
	C760.456787,551.429199 760.521362,551.251953 760.703979,550.817993 
	C760.952759,549.953613 760.429749,549.466492 760.509277,548.538879 
	C760.512085,546.789612 760.509033,545.369507 760.510193,543.563660 
	C760.917908,539.911377 759.429199,536.778198 759.947510,533.128357 
	C761.648743,530.448792 763.873230,529.450867 766.935059,529.638306 
	C767.778198,530.077087 768.263550,530.483765 769.218262,530.464600 
	C771.203125,530.418701 772.103821,529.171387 773.186707,528.171143 
	C774.115601,527.313171 775.011902,526.074463 776.739502,527.519775 
	C778.803528,532.025330 777.636230,536.386719 777.610352,540.926270 
	C777.720642,541.077332 777.643616,541.023865 777.579590,540.656128 
	C777.508789,539.696045 777.510986,539.036194 777.644165,538.036255 
	C778.238647,535.561951 777.890198,533.388672 778.088867,530.879028 
	C778.318359,530.067444 778.560059,529.657104 779.173462,529.094177 
	C780.288940,528.500916 780.957275,527.831299 782.158203,527.488281 
	C782.962463,527.545715 783.560791,527.303772 783.881104,527.904785 
	C783.922119,527.511719 783.717773,527.346375 783.001831,527.257568 
	C782.684875,526.907104 782.634216,526.707886 782.780762,526.271606 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M813.767090,503.767273 
	C814.193665,504.249023 814.397827,504.511536 814.829346,504.883728 
	C818.712097,506.157074 815.532898,509.024109 816.657959,511.214630 
	C817.453613,518.346985 816.848694,525.053284 815.611267,532.321594 
	C814.632263,529.390320 815.297791,525.957153 811.365295,525.046082 
	C810.526062,524.473206 810.579163,523.814392 810.497559,523.173340 
	C810.428589,522.631592 810.756104,522.107300 810.509949,521.576477 
	C810.446960,522.205444 810.509338,522.841736 810.193848,523.729065 
	C809.672791,524.460693 809.357788,524.931274 809.017212,525.696899 
	C808.741455,526.790222 808.491089,527.588562 808.111450,528.677490 
	C804.531128,528.125366 805.917847,525.451477 806.000793,523.411682 
	C806.143738,519.893555 807.825073,516.390564 805.827026,512.519470 
	C805.621460,511.909302 805.591919,511.649475 805.786560,511.067688 
	C806.596375,509.431915 808.068848,509.297333 809.260803,508.372650 
	C807.626099,506.875977 804.067139,507.052216 805.073853,503.444336 
	C805.721436,501.123627 807.387512,500.982269 809.678589,501.025330 
	C811.698242,501.241272 812.597473,502.427582 813.767090,503.767273 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M798.662231,513.414429 
	C798.938904,512.722656 798.962830,512.226440 798.980774,511.729889 
	C799.050659,509.799988 798.716553,506.664978 800.297607,506.435974 
	C803.278809,506.004211 801.162964,509.703613 802.646240,510.947906 
	C802.897583,511.158813 803.111755,511.414093 803.651001,511.841248 
	C804.284302,512.326233 804.482788,512.685547 804.469482,513.434448 
	C804.273926,513.907349 804.141235,514.069214 803.713013,514.288147 
	C803.220459,514.123779 802.893799,514.025513 802.743469,513.823059 
	C802.348083,513.290710 802.199036,514.320740 802.600769,513.788696 
	C802.601440,513.787842 802.731934,513.850952 803.211670,514.197327 
	C803.593262,514.554626 803.755371,514.692566 804.171143,515.035645 
	C808.068481,520.427856 805.756592,526.009949 805.246399,531.682007 
	C804.825012,531.921204 804.657532,531.934021 804.238892,531.966858 
	C801.250916,529.872009 798.612427,527.790771 801.397400,523.839844 
	C802.438293,522.363098 800.998230,520.904297 800.103577,520.524475 
	C798.211853,519.721191 795.969727,519.122314 793.992676,519.377869 
	C792.842590,519.526611 791.734680,522.120667 791.086914,519.087524 
	C790.719177,517.365601 788.651917,515.098511 792.039551,513.856140 
	C793.222107,513.422424 794.216858,512.481445 795.313416,511.797974 
	C795.394958,511.747162 795.643188,511.869049 795.747192,511.975708 
	C795.854248,512.085510 795.879578,512.275024 795.968872,512.695312 
	C796.639465,513.774780 797.309387,514.486389 798.662231,513.414429 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M822.718140,593.186523 
	C829.872925,596.297913 830.322754,598.592346 825.423462,606.958923 
	C825.481445,601.762634 821.768555,602.805115 818.787170,603.267456 
	C812.842651,604.189331 812.497559,603.928162 814.393066,598.259216 
	C816.779297,595.751038 818.984070,593.551880 822.718140,593.186523 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M804.996033,532.219971 
	C803.731445,527.634521 806.395325,523.372925 804.956421,519.007690 
	C804.536316,517.733337 804.022339,516.658691 803.153564,515.299805 
	C803.242371,514.708984 803.543152,514.516052 803.970215,514.172424 
	C804.022461,513.487610 804.020081,513.071289 803.988281,512.344238 
	C803.958862,512.033630 803.981934,512.010498 803.972290,512.014526 
	C804.465576,511.627441 805.031738,511.482117 806.053101,511.811951 
	C809.349060,511.996704 808.909912,513.721985 808.666992,515.746826 
	C808.161438,519.959961 806.446960,524.068359 807.704346,528.696655 
	C807.402832,530.447327 807.127991,532.173828 806.173645,533.348389 
	C804.692688,535.171143 805.286926,533.004456 804.996033,532.219971 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M822.796021,592.829773 
	C820.487061,594.688965 817.931580,596.344849 815.004272,598.023193 
	C815.167175,596.071228 815.495850,594.134521 813.390808,592.398987 
	C816.278564,592.310181 818.621826,591.410889 820.717285,589.209351 
	C821.553955,590.281860 822.051758,591.454163 822.796021,592.829773 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M776.038330,555.445435 
	C776.055481,555.000000 776.544189,555.007629 776.788818,555.005615 
	C777.078796,559.089844 777.290710,563.190002 776.023560,567.586670 
	C773.681946,566.634216 775.188904,564.713013 775.552979,563.175781 
	C776.130554,560.737427 775.843994,558.313477 776.038330,555.445435 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M759.117676,649.327576 
	C759.309448,647.708191 759.356750,646.088318 761.636475,645.967529 
	C761.321228,647.368774 763.745789,650.979126 759.117676,649.327576 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M806.143555,561.636658 
	C805.073730,561.935059 804.249512,561.774109 803.471802,560.721802 
	C803.194519,557.632263 805.264709,558.551270 806.509888,558.988464 
	C808.652466,559.740662 806.535645,560.558716 806.143555,561.636658 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M794.417603,538.813110 
	C795.448425,538.027527 796.626038,537.197449 796.964478,539.614502 
	C795.840576,540.454956 794.277893,542.272583 794.417603,538.813110 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M799.498840,560.912476 
	C799.494019,561.892883 799.047546,562.551392 797.838745,562.637573 
	C797.445068,562.038635 797.001648,561.321167 797.199219,560.917175 
	C797.665466,559.963684 798.503784,560.326294 799.498840,560.912476 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M777.283081,554.777588 
	C777.152954,553.518921 777.302612,552.006470 777.733887,550.244873 
	C777.864563,551.505127 777.713684,553.014465 777.283081,554.777588 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M824.775269,607.118408 
	C824.604797,608.017395 824.098389,608.939697 823.271545,609.830444 
	C822.924683,608.620483 823.027954,607.514771 824.775269,607.118408 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M836.660339,569.045776 
	C837.291931,569.667664 837.083862,570.299072 836.198608,570.933228 
	C836.020142,570.393677 836.190918,569.781799 836.660339,569.045776 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M762.530334,639.549744 
	C762.477600,639.581055 762.583069,639.518433 762.530334,639.549744 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M631.326050,752.904907 
	C628.502075,753.977600 627.061829,755.931335 626.035339,758.648804 
	C618.307739,764.189148 609.658569,767.911377 602.338989,773.558228 
	C597.880859,776.997742 592.823669,780.297119 590.593811,786.087402 
	C589.884766,787.928467 588.713135,789.781982 586.166992,789.296143 
	C584.820312,789.039062 584.401855,789.890381 583.982239,790.819946 
	C583.479675,791.933472 582.703369,792.798767 581.570801,793.232056 
	C571.659729,797.023010 562.490845,802.561218 552.362793,805.796631 
	C548.694031,806.968567 544.796814,806.854004 540.762817,805.187683 
	C540.252197,804.818115 540.065063,804.646118 539.677856,804.147644 
	C538.912659,801.761780 539.630127,799.803040 540.055725,797.451904 
	C540.526978,796.154297 541.090942,795.359192 542.144897,794.514587 
	C544.250854,793.289978 546.834778,794.109253 548.326782,791.642517 
	C549.217102,790.133667 550.353760,789.276306 551.703125,789.347717 
	C555.309875,789.538696 557.916626,787.461609 560.994507,785.576660 
	C562.615234,784.737061 563.936218,784.052307 564.723694,782.286011 
	C567.391418,779.007019 569.127441,774.709778 574.681519,776.242249 
	C575.993835,776.138306 575.993835,776.138306 575.660095,775.335938 
	C575.720886,774.829956 575.804260,774.632935 576.101379,774.185486 
	C583.407410,772.176941 588.768738,767.997803 592.944702,762.120422 
	C594.089661,760.508911 595.822510,759.972778 597.730164,759.827881 
	C600.457581,759.620667 602.917664,758.702393 605.299072,756.700378 
	C605.983093,756.259460 606.396179,756.046814 607.136475,755.717407 
	C611.204407,750.756836 617.155090,751.908142 622.084900,750.381470 
	C624.737915,749.559937 626.940918,748.465210 628.888000,746.613525 
	C630.295898,745.274658 631.264893,743.927002 630.175354,741.839050 
	C628.622803,738.863586 630.147034,737.187622 632.998535,737.186829 
	C637.823059,737.185486 640.490417,734.029846 643.127319,730.879883 
	C646.207336,727.200745 648.822998,723.144470 652.163330,719.234497 
	C652.491760,718.790955 652.614441,718.609131 652.923706,718.160156 
	C653.363281,717.544189 653.624512,717.201660 654.100525,716.619629 
	C654.618530,716.070801 654.975891,715.835938 655.691895,715.604309 
	C656.449829,715.565369 656.885437,715.601929 657.654541,715.691956 
	C658.216919,715.753784 658.444214,715.783386 659.014221,715.883179 
	C662.726807,717.190918 661.865417,719.476685 660.435669,721.455017 
	C655.437073,728.371765 651.139893,735.813354 644.943359,742.280029 
	C640.948181,746.719360 635.563538,748.829651 631.326050,752.904907 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M539.740356,804.273438 
	C548.216614,806.712646 555.003601,802.969421 562.430725,800.118774 
	C567.975708,797.990479 572.598267,793.987793 578.537048,792.900574 
	C581.048889,792.440735 583.422302,791.736938 583.094177,788.224915 
	C582.949829,786.680237 584.122620,785.239014 585.511963,786.784790 
	C588.987976,790.651855 589.500305,785.827820 589.641907,784.997437 
	C590.147095,782.035461 592.390930,780.897949 594.034241,778.941956 
	C598.108582,774.092590 603.305481,770.620667 608.471558,767.700989 
	C613.894409,764.635986 618.953491,760.541016 625.609375,759.002380 
	C625.451660,760.354797 623.927124,761.235962 624.068115,762.934448 
	C624.162598,764.072449 624.636414,765.130920 625.866882,764.337891 
	C628.698730,762.512634 632.735474,764.262268 635.105347,761.038147 
	C635.599976,760.365173 636.821838,759.823975 637.839478,760.304199 
	C639.526245,761.100098 638.177856,762.322632 638.072205,763.320923 
	C637.300171,770.613464 633.218506,773.873657 625.914551,774.600708 
	C619.766907,775.212585 613.713684,776.862000 607.649292,778.191406 
	C603.367126,779.130188 603.468201,781.761230 605.688354,784.953247 
	C604.024597,786.861084 602.191833,788.270874 598.990356,788.382935 
	C594.556030,788.538086 594.955078,793.587708 593.020386,796.730469 
	C592.753540,797.762878 592.260132,798.173401 591.302185,798.497314 
	C589.947021,798.594116 588.859741,798.522888 588.415344,800.154419 
	C588.226257,801.128113 588.030945,801.761414 587.533691,802.645264 
	C587.187744,803.098816 587.023804,803.261169 586.527771,803.525635 
	C585.963745,803.619812 585.731750,803.611877 585.171692,803.487793 
	C584.655823,803.238159 584.467896,803.104614 584.028442,802.739441 
	C581.938293,800.855713 578.594055,800.816284 578.716553,797.145020 
	C576.784180,799.622375 574.170593,801.571289 576.804199,805.001770 
	C576.673462,806.166626 576.808960,807.349792 576.176025,807.696716 
	C572.909729,809.487122 570.748413,813.276428 566.046387,812.562073 
	C562.949280,812.091492 559.767334,811.933594 556.275757,811.446899 
	C553.143433,810.968140 550.370972,811.230347 547.594604,811.319702 
	C546.111511,811.367554 544.337830,811.275024 543.632446,809.891785 
	C543.020081,808.690918 543.158813,807.823975 541.157043,808.064026 
	C538.843994,808.341431 538.944580,806.282288 539.740356,804.273438 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M631.134399,753.308960 
	C631.893494,749.817017 634.620850,748.057861 637.117920,746.813293 
	C639.582703,745.584900 641.280945,743.713928 643.661621,742.074524 
	C646.408325,741.080078 648.599365,739.986816 651.503723,740.336975 
	C655.141174,740.775574 656.471802,738.384216 657.060730,734.859314 
	C657.642700,731.376038 659.574219,728.166626 660.566284,724.686707 
	C661.045654,723.005188 662.805481,723.977295 664.381226,724.384521 
	C666.130066,724.554993 667.540039,724.612671 669.309814,724.688416 
	C670.148376,724.770691 670.616699,724.880005 671.405396,725.174438 
	C671.927917,725.460510 672.121033,725.600647 672.544312,726.011353 
	C673.334595,727.297974 674.016846,728.251892 674.348389,729.733765 
	C673.354248,732.659546 670.405273,734.088562 670.204529,737.474487 
	C671.468994,742.097473 676.042664,742.878540 678.686401,745.734680 
	C679.511230,746.625916 680.944885,746.969604 681.547424,748.663879 
	C679.266296,755.131409 674.492676,756.314514 670.134583,751.458130 
	C669.867249,749.004761 668.261047,748.584595 666.376343,748.054199 
	C665.826843,746.334656 665.053589,747.264954 664.135254,747.852173 
	C663.624207,748.147644 663.417358,748.308044 663.150146,748.692932 
	C662.823242,749.097046 662.610229,749.341248 662.220825,749.750244 
	C660.021362,751.501831 659.109009,751.227539 659.701721,748.509033 
	C659.915405,747.528870 660.341553,746.338074 659.414001,745.744934 
	C657.973694,744.823792 657.546204,746.443115 656.780090,747.183411 
	C652.567566,751.254028 648.402222,755.377441 644.069031,759.315918 
	C643.123657,760.175171 640.926453,763.776917 640.746338,758.561096 
	C640.671021,756.380615 638.358276,755.656982 636.568665,756.381409 
	C633.454590,757.641602 632.338562,755.886475 631.134399,753.308960 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M640.776917,794.768799 
	C645.441772,797.763367 650.614563,798.596436 655.591187,799.999451 
	C656.216125,800.175598 656.852905,800.309692 657.742920,800.736206 
	C660.302551,803.199524 662.070251,806.228210 666.195312,806.153931 
	C667.992065,807.210022 667.581360,809.609985 669.829224,810.354004 
	C672.606323,812.262390 676.325745,810.569458 678.419067,813.903809 
	C678.390503,814.570129 678.308350,814.834106 677.913086,815.379761 
	C675.127991,816.847839 672.576904,816.635376 669.612671,815.952271 
	C666.138306,813.193115 662.524841,812.281799 658.565857,812.515564 
	C652.432434,812.877808 646.476746,811.005737 640.559448,811.421021 
	C642.903931,812.128479 646.341736,810.283630 648.057495,813.917603 
	C646.994507,815.113770 645.843689,815.504639 644.698425,815.364014 
	C636.189026,814.319397 627.728455,813.018250 619.349731,809.618652 
	C617.596802,806.660400 619.522156,805.593506 621.441162,804.652039 
	C623.349121,803.716003 625.451843,803.584167 627.816101,803.547668 
	C628.082153,803.531616 628.500916,803.462280 628.541809,803.281006 
	C626.776794,802.040283 623.055725,802.690796 623.778870,799.348694 
	C624.461304,796.194641 627.183777,794.639099 630.669983,794.942383 
	C631.883423,795.047974 633.175049,796.207703 634.481567,794.558411 
	C635.384460,793.171387 634.719482,790.621521 637.705078,791.593628 
	C638.128235,791.872681 638.275696,792.008240 638.602051,792.372925 
	C638.996704,792.904236 639.216797,793.203064 639.648926,793.664612 
	C640.135010,794.047729 640.388977,794.290466 640.776917,794.768799 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M669.883301,751.261230 
	C674.626282,754.604675 677.841553,753.911194 681.204956,748.115112 
	C684.004700,747.458496 686.535706,747.442200 687.552002,743.996460 
	C687.977661,743.544800 688.182007,743.391968 688.740051,743.072388 
	C695.507324,741.644958 701.887573,742.083435 708.177307,743.586304 
	C710.032532,744.029602 711.995422,744.786621 713.213135,747.041138 
	C712.975159,749.666748 711.172241,749.988281 709.413025,750.250793 
	C707.512756,750.534485 705.568115,750.569824 703.532654,751.687378 
	C702.746460,752.331604 702.198792,752.722290 701.442749,753.375854 
	C701.027466,753.731323 700.856567,753.868530 700.397705,754.168091 
	C699.890808,754.365356 699.672058,754.400269 699.126831,754.353882 
	C698.616394,754.139832 698.432251,754.007141 698.030396,753.609680 
	C697.296692,752.817261 696.664307,752.547668 695.557800,752.492676 
	C690.742859,752.495911 686.303223,752.481750 682.148376,752.492920 
	C681.199341,752.573059 681.662537,753.110901 681.773071,753.079102 
	C685.997192,751.865845 690.330872,752.823059 694.998230,752.509399 
	C698.241150,752.749084 697.811218,755.241455 698.441528,757.363403 
	C698.192200,758.454651 697.690063,758.945129 696.730469,759.440796 
	C694.436768,759.793518 692.588440,760.437744 690.485291,761.386963 
	C687.026611,765.055786 682.989441,762.711243 679.573547,762.574463 
	C676.405945,762.447632 673.240906,761.239868 669.904175,761.912598 
	C668.911011,762.112854 667.991516,761.527832 667.221558,760.310669 
	C662.667236,756.981506 660.457886,760.964783 657.793701,763.000244 
	C656.575623,763.930969 655.886536,765.385620 654.252747,763.701538 
	C652.808716,762.213013 653.636353,761.385864 654.706177,760.021057 
	C658.005066,755.812500 662.301819,753.911987 667.731567,753.286865 
	C668.233459,752.798401 668.463928,752.574219 668.856812,752.171265 
	C669.328552,751.897034 669.578125,751.719482 669.883301,751.261230 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M630.795532,804.899170 
	C626.407410,805.151184 622.020081,804.054199 619.014893,808.545898 
	C619.113586,809.063965 619.275513,809.220276 619.193115,809.609558 
	C618.544250,809.798096 618.139709,809.753601 617.438171,809.638306 
	C615.905762,807.431396 614.504456,806.434326 611.660583,807.108337 
	C603.729614,808.988159 595.612488,810.023987 586.832153,811.929260 
	C589.266296,813.099609 592.244202,812.474915 592.167236,815.890686 
	C591.800110,816.410034 591.262817,816.711792 590.409851,817.052612 
	C588.527283,818.815857 586.492493,819.081604 584.312927,819.023193 
	C580.258850,818.914429 576.206238,818.968628 571.773315,819.320190 
	C569.826782,818.718811 567.895386,818.293457 568.124939,816.202454 
	C568.277161,814.815430 569.834351,814.145142 571.141357,813.993042 
	C575.817688,813.448914 578.592957,811.348999 578.239014,805.916199 
	C580.270447,803.662354 582.388184,802.552246 585.461609,803.159790 
	C585.775452,802.960083 586.152527,802.908264 586.341064,802.934448 
	C586.826782,802.834351 587.124084,802.708130 587.782593,802.904785 
	C587.856995,805.213013 587.735168,806.584106 590.734253,806.173584 
	C593.985535,805.728516 597.100525,804.925720 600.625610,804.058350 
	C601.734253,804.876709 602.622864,804.530518 603.736694,804.168091 
	C603.990845,803.950195 603.999023,804.000732 603.979370,803.984192 
	C608.615723,802.681641 613.213257,801.035950 618.398621,801.416382 
	C620.166809,798.884888 622.313904,797.087097 623.066528,794.141479 
	C623.664490,791.801086 626.222839,791.515625 628.725952,791.137207 
	C629.768066,790.979614 630.468140,790.988342 631.514771,791.155151 
	C633.011658,791.621033 633.991333,792.240967 634.805359,793.613281 
	C635.295776,795.011841 637.590515,795.411438 636.153870,797.087219 
	C635.161377,798.244751 634.037476,797.543518 633.040039,796.999695 
	C629.198486,794.905518 626.509155,796.169312 624.494934,800.079163 
	C626.500732,802.016602 629.785889,801.068481 631.922363,803.325806 
	C631.820312,804.090759 631.516418,804.459595 630.795532,804.899170 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M667.019165,760.403198 
	C668.521912,760.759277 670.188721,761.602356 671.677307,760.495300 
	C673.683105,759.003479 675.429443,759.898865 677.202087,760.642639 
	C679.321899,761.531982 681.325684,762.943787 683.449646,760.619263 
	C684.074524,759.935303 685.225647,759.966248 685.589661,760.755859 
	C687.755249,765.452881 687.956665,759.483154 689.664185,760.055298 
	C693.114319,760.905884 696.231750,761.661011 697.270325,765.495728 
	C697.630493,766.825684 699.250122,767.140259 700.633179,767.052979 
	C704.476074,766.810669 707.615479,768.600525 710.983459,770.737305 
	C711.320312,771.245911 711.413452,771.476624 711.509766,772.082642 
	C711.418274,772.690369 711.323669,772.922791 710.938110,773.395142 
	C709.731506,774.109436 708.744629,774.173584 707.384155,774.112000 
	C704.635986,773.429443 702.608643,774.045532 700.483276,775.345337 
	C697.240051,777.328674 693.717773,778.797729 689.412720,778.628357 
	C688.843445,778.595154 688.617981,778.549744 688.102722,778.317261 
	C687.734497,777.914185 687.656128,777.698242 687.780823,777.203796 
	C688.973389,774.786804 688.691467,772.858398 688.259827,770.453552 
	C687.440918,765.890991 684.449158,768.657593 682.616577,768.022156 
	C678.152100,766.474060 673.101562,766.951965 669.003479,763.935852 
	C667.809937,763.057495 667.016907,762.255432 667.019165,760.403198 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M667.319946,807.354248 
	C662.992004,807.186707 658.854553,807.400024 658.150269,801.349609 
	C658.869751,801.161743 659.737793,801.314392 660.832397,801.711792 
	C661.206299,802.096985 661.353760,802.237366 661.726562,802.171692 
	C669.050476,802.654419 676.148987,803.343140 683.682739,804.097168 
	C689.880554,806.122131 695.902222,805.610168 701.826599,805.983093 
	C706.295776,806.264404 710.812073,805.522583 715.625244,806.824097 
	C717.829285,809.210815 721.078613,806.639465 722.897461,809.478210 
	C720.700500,811.989075 717.718994,811.963684 714.447571,812.423828 
	C712.558228,812.579224 711.104370,812.672913 709.211548,812.477722 
	C696.803833,812.892334 684.892944,811.764221 672.546631,811.018860 
	C670.246826,810.361023 668.829956,809.113586 667.319946,807.354248 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M638.822266,781.079102 
	C640.179504,778.468628 645.239380,779.482056 643.938965,774.728638 
	C644.444824,772.535889 646.950623,772.487854 647.693481,770.342712 
	C650.490540,770.385986 650.588928,773.088501 652.131042,774.418884 
	C653.748352,775.814209 653.953796,779.646545 657.518738,777.150024 
	C658.114685,778.452515 662.761169,778.260010 659.008118,781.164429 
	C657.234314,782.537170 659.802979,784.258850 658.266235,785.798462 
	C654.243408,785.053833 652.629944,788.190674 652.915222,790.237183 
	C653.423523,793.883789 651.225647,791.264526 650.262512,791.612488 
	C646.909973,789.357666 643.947998,787.124573 641.888367,783.592773 
	C640.179932,783.364990 638.936096,783.005920 638.822266,781.079102 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M629.161865,791.490967 
	C626.303162,792.625000 623.592163,793.010071 623.743347,797.122559 
	C623.796387,798.564697 621.035217,800.110229 619.281372,801.794556 
	C617.285156,803.077393 616.653564,802.025024 616.215393,800.210571 
	C616.285400,799.082214 617.288513,798.127441 615.254333,798.186401 
	C611.661072,798.290466 608.098206,800.272766 604.210815,798.230957 
	C611.426453,792.702087 618.100159,785.779907 628.713806,786.222900 
	C629.567322,786.464600 629.952820,786.685547 630.441650,787.312744 
	C631.016663,789.094055 630.399048,790.236084 629.161865,791.490967 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M630.202148,788.270630 
	C629.770813,787.618103 629.532593,787.202515 629.206421,786.570557 
	C629.011841,785.421997 627.982971,784.616211 628.917908,783.345337 
	C630.451721,780.651978 633.153503,780.619080 635.699097,779.776367 
	C636.050415,779.884094 636.102295,780.186523 636.061157,780.334290 
	C638.132629,780.650879 638.027466,786.163696 641.764709,782.503540 
	C642.525635,782.125610 643.127869,782.044434 643.730103,781.963318 
	C643.657532,786.382446 648.270691,787.458191 649.967896,791.048218 
	C647.633606,791.666321 645.256348,791.881470 642.468140,792.121582 
	C641.038696,792.895874 640.128174,793.894592 638.305298,793.481201 
	C637.125183,792.669800 636.809509,792.751343 635.282227,793.938721 
	C634.112549,793.497681 633.258362,793.069214 632.205872,792.433350 
	C630.373291,791.462097 631.241943,789.612976 630.202148,788.270630 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M715.853394,806.818359 
	C706.250488,807.519592 696.557434,806.439636 686.841248,805.983032 
	C685.860291,805.936951 684.821899,806.008301 684.201294,804.532837 
	C693.740051,805.260742 703.402466,805.095825 713.527466,805.058594 
	C714.565430,805.543335 715.135925,806.054138 715.853394,806.818359 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M603.978271,797.997375 
	C609.328369,798.132935 614.505981,796.752869 619.762085,795.969116 
	C620.351135,797.856689 618.647339,798.026428 617.375854,798.896973 
	C614.181885,801.931458 610.474182,802.360596 606.342346,802.248169 
	C606.054016,800.793274 605.216553,800.181946 604.178345,799.332031 
	C603.815796,798.892944 603.633057,798.706665 603.721436,798.238159 
	C603.992676,797.955872 604.000916,798.001526 603.978271,797.997375 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M605.829102,802.227600 
	C609.491089,801.186401 613.055725,800.688843 616.696655,799.077332 
	C617.017578,800.197327 616.867310,801.384216 618.670227,801.910767 
	C619.152710,802.689148 619.611023,804.184631 618.652710,803.818298 
	C614.718628,802.314026 611.154724,802.713928 607.504395,804.611816 
	C606.852844,804.950562 605.651611,804.232239 604.331970,803.984802 
	C604.520264,803.462036 605.080872,802.956421 605.829102,802.227600 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M686.848022,790.929810 
	C686.964478,791.629272 686.973755,792.021545 686.983032,792.708130 
	C681.348267,793.908813 680.947327,787.472351 677.058838,785.928894 
	C678.551086,785.000000 678.055542,782.441406 680.517090,782.029358 
	C681.575439,782.058411 682.066528,782.519165 682.571838,783.456726 
	C682.731995,784.000183 682.768799,784.222961 682.819946,784.778198 
	C682.727356,787.946594 685.341614,788.854004 686.848022,790.929810 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M588.500549,803.199158 
	C587.879150,803.163757 587.614502,803.099792 586.939697,802.998230 
	C586.703247,802.293823 586.876953,801.627014 587.087036,800.618225 
	C587.816895,798.975159 588.816528,798.126465 590.705933,798.098389 
	C591.546753,797.871521 592.043762,797.630493 592.778320,797.249084 
	C593.730835,797.451965 595.864380,796.351013 594.837158,798.449463 
	C593.661926,800.850464 591.614136,802.563721 588.500549,803.199158 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M635.989380,779.608643 
	C633.959717,780.921021 631.869080,781.957947 629.438721,783.059570 
	C628.375977,777.791077 628.375977,777.791077 635.989380,779.608643 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M647.932617,770.084534 
	C649.319336,773.442566 647.369141,774.154846 644.283936,774.335876 
	C642.676758,772.907288 642.251587,771.362732 643.887512,769.411621 
	C644.219238,768.946167 644.360352,768.798462 644.723022,768.862427 
	C645.115234,769.122803 645.285950,769.171509 645.722900,769.211670 
	C646.575989,769.106812 647.106262,769.227234 647.739014,769.839478 
	C647.911438,770.063843 647.930298,770.072021 647.932617,770.084534 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M641.647827,782.228760 
	C641.295593,784.408691 641.295593,784.408691 637.534851,786.361084 
	C636.159302,784.866028 638.372925,782.424866 636.101318,780.817505 
	C636.766174,780.571228 637.512451,780.660400 638.634521,780.774841 
	C639.508057,781.755188 639.769897,783.094238 641.647827,782.228760 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M682.422119,784.120117 
	C681.765686,783.501648 681.432129,783.008118 680.953979,782.252930 
	C680.809448,781.991211 680.959778,781.514587 681.017212,781.271362 
	C682.166748,780.329773 682.917053,779.180847 684.475769,778.594360 
	C685.609863,778.214111 686.427612,777.913025 687.602539,777.917786 
	C687.907043,778.036560 687.932068,778.100159 687.966064,778.102966 
	C688.330688,778.442017 688.565369,778.833984 688.690491,779.638672 
	C688.254395,781.812256 686.497681,782.218689 684.972168,783.373901 
	C684.088623,783.934631 683.451843,784.190186 682.422119,784.120117 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M664.233826,748.062500 
	C665.099487,746.664368 665.654541,744.613708 668.083740,743.964844 
	C668.584839,745.831238 666.639404,746.272156 666.159607,747.727661 
	C665.551697,748.039551 665.072693,748.061096 664.233826,748.062500 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M637.956909,793.380737 
	C639.077393,792.926453 640.208801,792.508118 641.698730,792.118164 
	C641.773071,792.902527 641.488831,793.658630 641.072754,794.693970 
	C640.515259,794.983643 640.089539,794.994080 639.343933,795.007324 
	C638.718628,794.727600 638.456055,794.409302 638.105713,793.775391 
	C637.980896,793.490784 637.967834,793.416748 637.956909,793.380737 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M685.114624,778.541260 
	C683.869385,779.474792 682.795227,780.205566 681.397827,780.982300 
	C681.699829,779.586609 682.141052,777.954346 684.548950,777.994690 
	C685.022583,778.101929 685.154175,778.220154 685.114624,778.541260 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M662.735657,792.720947 
	C661.381042,792.897339 660.263306,792.897339 657.913452,792.897339 
	C660.415222,791.787415 661.689087,791.700806 662.735657,792.720947 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M685.255859,778.778931 
	C684.943542,778.744019 684.936707,778.362732 684.913879,778.173218 
	C685.472107,777.119812 686.363037,776.989807 687.641602,776.946289 
	C687.983704,777.111389 687.983582,777.297363 687.945190,777.759888 
	C687.127380,778.295654 686.347778,778.554810 685.255859,778.778931 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M647.899902,769.760254 
	C647.398499,769.903564 646.885620,769.743347 646.180908,769.393066 
	C646.809753,767.882996 647.424316,768.106750 647.899902,769.760254 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M603.619629,803.907959 
	C603.143066,804.751343 602.446594,806.260986 601.042114,804.303223 
	C601.760620,803.976501 602.504517,803.921082 603.619629,803.907959 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M603.730469,797.947754 
	C603.994995,798.143005 603.997253,798.330139 603.999023,798.798218 
	C603.584412,798.838074 603.170166,798.596985 602.755981,798.355896 
	C602.993408,798.217163 603.230835,798.078369 603.730469,797.947754 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M661.728699,801.964111 
	C661.802307,802.313599 661.659546,802.677246 661.125244,802.127747 
	C661.058899,801.956604 661.505432,801.962585 661.728699,801.964111 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M644.879211,768.882568 
	C644.944580,769.074036 644.510254,769.066895 644.294189,769.080444 
	C644.171326,768.681885 644.247498,768.238831 644.879211,768.882568 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M662.266968,749.805969 
	C662.180237,749.608032 662.382385,749.354858 662.843628,749.004150 
	C662.864746,749.143555 662.682495,749.424988 662.266968,749.805969 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M605.998779,784.998779 
	C606.084961,784.963928 606.172363,784.930237 606.131104,784.949463 
	C606.002441,785.002441 606.000000,785.000000 605.998779,784.998779 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M658.058472,786.073486 
	C657.952759,786.138916 657.766968,786.959778 657.784668,786.017456 
	C657.915039,785.930542 658.013489,786.023071 658.058472,786.073486 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M668.803284,752.112183 
	C668.907227,752.318970 668.711243,752.581177 668.217102,752.900757 
	C668.110657,752.693054 668.305481,752.429443 668.803284,752.112183 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M819.853516,446.319122 
	C824.776245,443.419830 825.261353,437.964325 827.840454,433.891266 
	C831.171875,428.630310 834.063660,423.090515 837.410400,417.840088 
	C838.199341,416.602386 839.200806,414.143921 841.619446,415.648926 
	C843.442871,416.783569 843.604126,418.545776 842.736694,420.417572 
	C841.158203,423.823975 840.750671,427.415375 841.000000,431.557953 
	C838.491760,431.801788 838.097290,433.241180 837.934204,435.393311 
	C837.709717,438.356049 838.529968,442.525818 832.778931,440.619263 
	C831.210938,440.099457 831.370728,442.170349 831.676208,443.571198 
	C832.186584,445.911682 832.239441,448.090088 828.671021,447.453522 
	C827.177368,447.187042 826.692871,448.636078 827.485596,449.202026 
	C833.565552,453.542419 827.860168,455.144318 825.683777,457.584686 
	C825.204041,458.122650 822.529846,457.692627 824.808472,459.532257 
	C825.681091,460.236755 826.132141,461.219635 824.732483,461.796661 
	C823.843994,462.162994 821.999207,462.191681 822.014038,461.571930 
	C822.069763,459.246399 817.923767,457.197388 821.548279,454.666077 
	C823.255676,453.473724 822.905212,452.208527 820.980591,450.982239 
	C820.441772,449.405151 820.095398,448.011261 819.853516,446.319122 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M819.614441,495.216949 
	C821.425842,492.369354 823.823853,494.258911 825.951416,493.550049 
	C826.579285,492.342346 827.258911,491.729004 828.666260,491.784027 
	C831.260986,492.922485 830.175293,490.660736 830.973389,489.786194 
	C833.561462,485.420593 835.111145,487.902466 836.833618,490.592773 
	C837.298096,492.078491 837.610413,493.199890 837.959839,494.671051 
	C838.622986,498.763367 836.993225,502.388062 837.429382,506.129425 
	C837.633972,507.883911 837.333435,509.709778 838.341919,511.719910 
	C838.478027,512.336792 838.515015,512.587280 838.574585,513.216919 
	C835.921204,516.371277 834.194885,520.156250 830.018433,521.247070 
	C830.639526,518.917114 831.424255,516.849731 832.325073,514.421143 
	C831.719360,512.845154 831.713196,511.451263 832.149414,510.270691 
	C833.747681,505.944702 831.763672,503.490143 827.757080,501.700012 
	C826.565674,501.632324 827.083374,500.281097 825.921997,500.431519 
	C825.017944,500.690918 824.562805,501.083405 823.895691,501.716858 
	C823.612488,501.855835 823.307800,501.704071 823.144043,501.750488 
	C821.289490,502.402100 821.249329,500.401917 819.948120,499.775452 
	C819.591370,499.331238 819.468079,499.135254 819.222168,498.609009 
	C818.884338,497.315918 818.934753,496.377563 819.614441,495.216949 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M837.271729,490.750854 
	C835.210266,490.440002 834.073120,487.880371 831.418457,489.399780 
	C829.524780,485.978455 833.990479,484.693939 833.991577,481.383698 
	C829.645020,479.553558 833.094543,476.858887 832.998718,474.365295 
	C833.215942,473.788239 833.429260,473.574707 833.639954,473.358673 
	C833.850403,473.142975 833.778687,473.213776 833.212402,473.786072 
	C831.505859,473.959076 829.999634,474.019440 828.521118,473.844971 
	C827.168152,473.685364 826.443420,472.511169 826.477112,471.372620 
	C826.529663,469.603058 828.230286,469.765839 829.094849,470.246094 
	C831.461792,471.561096 832.595093,468.623077 834.957764,468.889679 
	C838.269592,468.255554 838.497131,470.185822 838.359009,472.653564 
	C838.114014,474.085266 838.057312,475.144836 838.114929,476.586914 
	C837.047852,481.447144 839.091858,486.084442 837.271729,490.750854 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M821.758789,414.086426 
	C822.413696,414.532074 822.865112,415.114990 823.571045,415.814819 
	C821.560120,422.907074 818.589905,429.563080 814.501709,436.016846 
	C814.009521,436.150299 813.819763,436.116547 813.537659,435.808899 
	C816.215027,429.662018 818.476746,423.593445 820.551025,417.064514 
	C820.677979,416.725128 820.644592,416.387085 820.497437,416.302246 
	C820.396606,415.987305 820.442993,415.757202 820.727783,415.268036 
	C821.153809,414.740631 821.350220,414.478851 821.758789,414.086426 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M838.476074,472.962708 
	C837.768799,471.431427 837.985535,469.536682 835.700806,468.764404 
	C835.341187,468.846252 835.338562,468.523560 835.242859,468.393768 
	C837.288208,468.670624 837.415344,466.991852 838.004822,465.311707 
	C839.497864,465.241943 841.512634,466.209442 840.578125,467.212463 
	C838.898193,469.015686 840.451843,471.411194 838.476074,472.962708 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M825.126953,479.703735 
	C821.834351,482.341736 819.872803,482.178223 820.035034,477.278381 
	C821.254211,477.803253 822.497131,477.138641 823.744751,477.056427 
	C825.529053,476.938934 826.127930,477.735718 825.126953,479.703735 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M819.627258,446.088013 
	C820.750244,447.320679 823.188354,448.215729 820.825195,450.539154 
	C817.720398,449.990265 817.950745,448.234772 819.627258,446.088013 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M835.899353,455.248474 
	C836.664307,455.118500 837.617920,452.429749 837.993286,455.124054 
	C838.288330,457.240692 837.671509,459.235962 836.443359,461.268341 
	C836.118164,459.872498 835.836243,458.662170 835.301514,457.224731 
	C835.300964,456.502258 835.553223,456.006958 835.899353,455.248474 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M828.944336,491.582153 
	C828.254272,492.312744 827.504761,492.714661 826.434570,493.184814 
	C825.769226,492.079010 825.424683,490.904968 825.032715,489.365479 
	C826.585938,489.230743 828.099915,489.611328 828.944336,491.582153 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M824.001343,486.636841 
	C823.141846,487.290924 821.834900,488.020264 821.713379,486.687592 
	C821.633240,485.809418 820.899780,483.496124 823.736572,483.907776 
	C823.999207,484.755066 824.000916,485.507660 824.001343,486.636841 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M839.631653,440.070435 
	C839.367126,439.810852 839.259583,439.429688 839.205200,439.041077 
	C839.196289,438.976746 839.419800,438.879913 839.535828,438.798126 
	C839.626038,439.180847 839.716248,439.563599 839.631653,440.070435 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M814.014282,436.017731 
	C813.981873,436.719757 813.764465,437.255493 813.300659,437.940369 
	C812.198303,437.442810 812.962646,436.801666 813.253113,435.846619 
	C813.445251,435.535004 813.829224,435.851410 814.014282,436.017731 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M838.468384,369.452454 
	C838.521790,369.421387 838.414978,369.483521 838.468384,369.452454 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M822.545227,474.472229 
	C822.574829,474.523682 822.515625,474.420746 822.545227,474.472229 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M813.006531,438.007385 
	C812.814026,438.181610 812.515869,438.575562 812.482361,438.554199 
	C811.936646,438.206604 812.343506,437.968079 812.835205,437.904358 
	C813.054321,438.089508 813.023987,438.033844 813.006531,438.007385 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M833.529175,454.544617 
	C833.478821,454.573486 833.579529,454.515747 833.529175,454.544617 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M497.929565,176.580414 
	C500.104919,177.786438 502.214661,176.666382 504.613800,177.059204 
	C503.823792,179.214478 502.234497,181.129074 506.054535,182.442688 
	C507.003571,182.769058 507.315887,184.946854 507.959991,186.636505 
	C507.999084,186.999390 507.974945,187.111298 507.987885,187.055954 
	C511.655151,188.076065 509.348480,190.373703 509.043823,192.631821 
	C510.339233,192.942032 511.647705,193.025558 511.988251,191.151596 
	C512.176636,190.115067 510.539948,188.162918 513.020264,188.131683 
	C514.582581,188.112015 516.612610,188.256668 517.601379,189.199539 
	C518.788574,190.331650 517.440918,191.888748 516.558899,193.114761 
	C515.981689,193.917160 515.622009,194.874878 515.054993,195.686142 
	C514.255676,196.829666 511.069519,195.890854 512.458862,199.058762 
	C513.614563,201.693787 515.641174,199.741104 517.481628,200.524078 
	C515.865540,203.790665 518.062744,206.674576 518.390198,209.559448 
	C519.173218,216.457626 523.407471,217.041199 528.624817,217.884155 
	C529.249512,218.334137 529.477905,218.703827 529.535339,219.492813 
	C528.796570,221.370270 528.503723,223.031967 528.158142,224.986298 
	C528.028381,225.850494 528.006409,226.416260 528.402466,227.215149 
	C528.679382,227.640396 528.797424,227.811829 529.077881,228.261810 
	C529.637451,229.407089 529.729614,230.280258 529.063049,231.440125 
	C522.371155,233.513123 517.175049,237.655579 511.559357,241.018768 
	C510.271942,241.789825 508.976288,242.526794 507.058899,242.162964 
	C506.152100,237.488770 508.728973,234.705887 512.285645,232.100601 
	C514.119690,231.103134 514.593506,229.525513 515.526489,227.855072 
	C516.449280,226.859741 517.307556,226.327240 518.233276,225.963531 
	C521.296570,224.759888 521.293823,223.184631 519.366943,220.703995 
	C514.356750,214.254059 514.441956,214.187866 504.579529,216.852814 
	C504.059357,217.039597 503.852753,217.112183 503.037598,217.326874 
	C502.053528,217.467041 501.923492,217.525558 502.027405,217.653915 
	C502.146759,217.801315 502.247375,217.879105 502.517578,218.139587 
	C503.874176,220.907272 502.277008,222.911530 501.487030,225.387085 
	C501.459961,225.932236 501.434387,226.152634 501.313293,226.697784 
	C500.304626,229.353546 498.153564,230.922180 497.237305,233.485977 
	C498.656036,234.562836 500.437469,233.678543 501.911041,234.336563 
	C504.043549,235.288803 505.597473,236.990784 503.943146,238.893402 
	C501.426971,241.787094 505.032471,241.234421 505.599792,242.724518 
	C505.806854,243.561005 505.778107,244.052795 505.503418,244.885056 
	C502.724030,247.331848 500.128845,248.956421 497.643127,244.574097 
	C497.250336,243.356033 497.456512,241.540344 496.240631,242.004593 
	C492.559570,243.410080 487.754089,243.027420 485.736023,248.064545 
	C480.715881,250.293060 483.728455,254.372284 482.926941,257.777893 
	C482.627808,258.526764 482.420410,258.940277 482.020905,259.650452 
	C479.340027,263.629700 475.803955,266.016724 471.829315,267.782501 
	C466.843994,269.997284 466.023376,269.334442 466.463989,263.256836 
	C468.220886,261.072937 469.388428,258.909210 470.776672,256.498474 
	C471.075531,256.044769 471.201324,255.869156 471.532959,255.449799 
	C471.885559,255.057373 472.032349,254.908691 472.407715,254.553040 
	C472.938416,254.080460 473.240509,253.814774 473.724365,253.314880 
	C474.017426,252.915604 474.128632,252.750473 474.384186,252.320847 
	C474.705688,251.681702 474.879089,251.305313 475.181274,250.627991 
	C475.684967,249.587906 476.339233,248.961853 476.550507,248.187714 
	C477.847168,243.436111 478.470520,238.226166 485.395142,237.430527 
	C481.944763,235.869232 479.453064,237.655319 476.480255,237.914200 
	C472.889923,238.255188 470.943817,236.274689 468.854492,233.933167 
	C468.054901,233.844376 467.606384,233.719391 466.863892,233.333588 
	C466.398468,232.925995 466.240356,232.729340 465.904541,232.194550 
	C464.186920,227.589142 464.119720,223.273560 465.489685,218.603271 
	C465.902161,217.876160 466.142059,217.471436 466.708649,216.517471 
	C467.560028,213.417343 465.815857,211.590820 465.008606,209.468964 
	C464.407074,207.887817 463.673676,206.098068 466.174133,205.557755 
	C468.460724,205.063660 467.932373,207.004547 467.977112,208.155502 
	C468.115356,211.712936 470.632385,211.869431 473.627136,212.000153 
	C474.488586,212.206497 475.032227,212.517334 475.044922,212.965103 
	C475.229034,219.454071 475.215698,219.454453 482.062622,218.403412 
	C482.602600,218.331085 482.816193,218.296295 483.349060,218.210754 
	C483.881012,218.129547 484.093658,218.099091 484.620056,218.007141 
	C486.278259,217.609222 487.147461,216.608963 488.333252,215.454956 
	C488.959229,214.917160 489.353882,214.654572 490.107910,214.287811 
	C491.407928,213.889923 492.364746,213.894180 493.628296,214.389832 
	C495.004913,217.873810 495.736450,217.788971 500.309937,213.504227 
	C500.654449,206.589432 498.588989,203.475952 491.930695,200.755051 
	C489.018585,198.738297 487.195190,196.410110 486.959167,192.736176 
	C487.085236,191.720810 487.243591,191.079620 487.597748,190.523193 
	C488.468170,189.155655 489.246338,187.387177 491.191406,187.689728 
	C493.381378,188.030411 493.000671,190.197556 493.535431,192.089523 
	C493.287750,190.463043 493.358307,189.212601 493.810364,187.635742 
	C493.975677,187.057434 494.050385,186.826782 494.264221,186.257568 
	C495.772003,182.982117 496.109711,179.681732 497.929565,176.580414 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M467.268219,216.694214 
	C465.553070,221.732574 465.372467,226.534622 467.012177,231.793396 
	C463.851959,236.978958 461.552399,242.196777 458.902802,247.233292 
	C456.219513,252.333923 452.932648,257.396973 446.126831,258.400574 
	C445.035858,257.084656 445.248810,255.882324 445.601501,254.314743 
	C445.853058,253.002487 446.266327,252.142975 446.956116,251.032715 
	C448.808289,248.362335 449.100525,245.362198 450.808502,242.633118 
	C452.189178,240.331558 453.063049,238.177307 453.590820,235.892807 
	C454.106384,233.661087 452.619385,233.879456 450.862701,234.014679 
	C450.015350,233.910660 449.558289,233.735275 448.859253,233.218109 
	C448.254089,232.195724 448.257874,231.449173 448.895996,230.448502 
	C451.942688,229.868317 453.949341,224.938400 457.718262,229.030548 
	C458.187012,229.539474 461.569061,225.747711 461.650665,223.226089 
	C461.766266,219.654495 463.375305,217.583038 466.658813,216.033936 
	C467.016388,215.956879 467.010468,215.990326 467.024353,215.991699 
	C467.134949,216.140808 467.231689,216.288544 467.268219,216.694214 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M443.236511,172.941345 
	C445.539429,173.163635 446.029510,175.102509 447.365051,176.443665 
	C448.294708,175.020569 449.196533,173.640030 450.330322,172.047424 
	C451.052490,171.794662 451.808136,171.942398 451.991608,171.683945 
	C453.738068,169.223984 455.291931,166.564163 458.986145,166.367569 
	C460.510132,166.047638 461.390198,164.975861 462.773438,164.179321 
	C463.648438,164.761154 463.498688,165.566162 463.209351,166.682709 
	C462.976929,167.008972 463.004639,167.001556 463.006287,166.987274 
	C462.560547,167.419952 462.492737,168.007278 462.195801,168.792313 
	C460.224823,167.675079 462.966309,173.521484 459.719482,170.379715 
	C458.797729,169.487808 459.182220,171.268646 459.016479,171.778717 
	C457.847382,175.376678 456.578522,178.957855 455.702057,182.630432 
	C454.986237,185.629868 452.021729,185.640457 450.164001,187.598572 
	C447.969513,190.059860 445.791443,191.943573 442.305359,192.078430 
	C439.208130,191.338287 440.872284,189.245712 441.393280,188.596512 
	C445.099274,183.978806 446.291443,179.391815 442.260101,174.354401 
	C442.142212,174.207108 442.676941,173.537537 443.236511,172.941345 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M449.133484,229.796600 
	C449.162842,230.866394 449.133087,231.539459 449.089478,232.551346 
	C449.213287,233.103714 449.350952,233.317276 449.601257,233.895782 
	C449.445435,237.818054 448.303131,241.176773 447.258575,244.954514 
	C446.649628,246.562073 445.977844,247.671661 444.502106,248.621643 
	C442.448486,249.192764 441.062775,248.750610 439.885620,246.965088 
	C439.704346,245.526535 440.160034,244.219177 438.361450,243.472336 
	C437.732025,240.818756 436.806763,238.862900 433.868652,238.140808 
	C433.072510,236.586090 433.448364,235.471741 434.592468,234.224274 
	C436.244934,232.738815 439.473480,233.018234 438.378937,229.747116 
	C439.823761,229.785294 440.685608,227.278275 442.611572,228.980438 
	C443.975861,230.186188 444.548553,228.872650 444.935730,227.391876 
	C445.675385,225.642258 446.257446,224.149277 446.783722,222.273468 
	C446.971222,221.979126 446.985687,221.985260 446.973053,221.973480 
	C448.616455,222.239151 450.272522,222.516571 451.928558,222.794006 
	C449.192596,224.535049 448.567566,226.715836 449.133484,229.796600 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M487.784088,192.022461 
	C487.856934,195.435562 490.078613,197.259155 491.979553,199.654510 
	C492.433716,200.735138 492.137787,201.376724 491.443115,202.238159 
	C489.664764,203.409012 488.074188,203.896362 487.172974,201.850754 
	C486.259460,199.777222 484.611053,199.699463 482.457001,199.647369 
	C481.690063,199.363205 481.307922,199.100922 480.768555,198.492737 
	C479.397430,196.660553 480.765900,194.212402 478.468170,192.759949 
	C475.416626,191.087784 473.980377,189.231964 475.845520,185.966003 
	C476.380066,185.029877 475.950806,183.697968 475.057800,182.352936 
	C475.627991,179.198212 476.811523,176.598724 478.644684,173.908112 
	C479.076782,173.570770 479.315857,173.532867 479.886505,173.642334 
	C481.412872,175.143356 481.181732,176.903748 481.704956,178.799713 
	C482.148041,179.716171 482.402313,180.342621 482.811401,181.264435 
	C483.196259,182.178955 483.406952,182.805359 483.854065,183.689758 
	C485.122009,186.608307 487.757782,188.511902 487.784088,192.022461 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M483.253815,218.991730 
	C479.866608,220.009506 476.934814,223.770630 473.575653,218.487076 
	C471.666687,215.484497 475.101868,214.549652 474.130920,212.274658 
	C475.656403,208.540009 476.047455,205.354202 471.836517,203.434082 
	C470.303009,202.734833 470.711670,201.686172 471.060791,200.244873 
	C471.955292,199.391159 472.840668,198.982162 474.233704,199.150421 
	C476.503174,199.895798 476.955353,202.066284 478.782867,203.327087 
	C479.451080,204.174286 479.519653,204.861984 479.516571,205.908936 
	C479.499298,206.483368 479.494354,206.713486 479.517120,207.287476 
	C481.105621,211.227829 484.620972,214.133774 483.253815,218.991730 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M474.945679,200.036545 
	C473.657318,200.017776 472.726959,199.992020 471.451691,199.943726 
	C472.642822,196.886444 468.958679,195.335144 469.028076,192.355042 
	C471.942139,192.840744 474.232544,198.065399 478.112946,192.947647 
	C478.849579,191.296249 479.638916,190.865341 480.946655,191.793930 
	C481.743256,192.359589 482.232758,193.006470 481.616608,193.800064 
	C480.784027,194.872375 481.045166,195.995346 480.903595,197.824615 
	C479.099762,199.675705 477.309631,200.237427 474.945679,200.036545 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M442.130066,192.313171 
	C444.413696,190.682129 446.855316,189.349976 449.640320,187.955353 
	C451.323120,191.300613 449.327576,194.716461 450.001831,198.559509 
	C447.426056,196.866150 444.857025,194.739105 442.130066,192.313171 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M457.714783,192.022522 
	C461.545990,190.322189 460.820251,190.455383 461.352112,193.353470 
	C461.902863,196.354340 459.407166,197.191498 458.774536,199.207275 
	C458.393524,200.421249 457.374573,201.144577 456.040253,200.310165 
	C454.866608,199.576218 454.765320,198.308167 455.180450,197.201859 
	C455.812561,195.517319 456.715424,193.934357 457.714783,192.022522 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M468.553467,176.242676 
	C468.200928,174.854675 469.221130,174.150162 470.295715,173.193344 
	C471.643677,172.895859 472.729584,172.719330 474.231171,172.514771 
	C474.838867,172.533035 475.030884,172.579300 475.209045,172.921555 
	C474.988251,175.736755 472.759094,174.789825 471.213104,175.663681 
	C470.290192,176.039886 469.596588,176.159363 468.553467,176.242676 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M463.223816,166.998825 
	C463.004395,166.273544 463.031860,165.538132 463.069092,164.436523 
	C463.078827,164.070343 463.055054,164.047852 463.048706,164.042206 
	C463.425629,163.612549 463.502472,163.077103 463.725372,162.312469 
	C463.888611,161.908722 463.911560,161.736267 463.970398,161.305725 
	C466.312531,162.751938 464.339203,164.488327 464.003479,166.573151 
	C463.961914,166.934158 463.470734,166.988678 463.223816,166.998825 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M466.917847,176.202271 
	C467.300171,176.017197 467.480530,176.030594 467.914825,176.141068 
	C468.105927,177.022949 467.911469,177.881531 466.964600,177.751495 
	C466.150482,177.639694 466.411591,176.933716 466.917847,176.202271 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M462.184570,169.122833 
	C462.166473,168.441956 462.351349,167.888428 462.772064,167.153946 
	C462.797577,167.731094 463.450592,168.724380 462.184570,169.122833 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M469.000610,191.999512 
	C468.874146,191.857162 468.747070,191.715317 468.619995,191.573456 
	C468.711517,191.620468 468.803009,191.667496 468.946655,191.857849 
	C468.998840,192.001190 469.000000,192.000000 469.000610,191.999512 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M749.758423,301.210999 
	C750.098633,299.848877 749.779541,298.400726 751.413635,298.234039 
	C752.780884,298.094513 752.864075,299.298706 753.000183,300.281616 
	C753.173523,301.533142 754.030273,301.291077 755.181885,301.149078 
	C756.047424,301.242920 756.529419,301.598297 756.498535,302.077545 
	C755.952515,310.547333 754.784668,318.842499 749.869934,326.125244 
	C747.805603,329.184143 744.837341,332.100006 748.572266,336.108704 
	C749.615417,337.228210 748.711731,338.969635 747.839294,340.251770 
	C746.628235,342.031281 745.102722,343.559906 743.726135,345.306793 
	C744.753601,346.146271 745.967834,346.561981 747.418884,347.145691 
	C749.754089,346.682343 751.586365,345.638336 754.007141,345.326630 
	C754.847351,345.310181 755.307922,345.430267 756.020203,345.872620 
	C757.866394,351.086487 753.740234,350.066101 751.036560,351.045471 
	C749.938416,351.806946 748.907593,352.859283 748.171753,352.486908 
	C744.388855,350.572449 744.261353,353.586121 743.009583,355.747192 
	C740.335754,360.363037 739.991272,365.850159 737.037598,370.680573 
	C736.732483,376.051514 735.390808,380.749084 732.238892,385.139954 
	C728.509155,383.838684 726.919067,380.580261 725.057251,377.809753 
	C720.375977,370.843689 714.283508,364.909027 710.272461,357.062805 
	C711.611084,351.273834 712.446411,345.694458 715.551880,340.840820 
	C717.523254,337.759583 719.785645,336.802582 723.157104,339.529968 
	C724.085388,340.707336 724.127625,341.725433 723.895691,343.135559 
	C722.476196,345.730377 720.467224,347.595245 720.085632,350.343262 
	C719.991150,351.023438 720.037231,351.717896 720.657104,352.098145 
	C721.605713,352.680115 722.201111,352.027924 722.597107,351.280762 
	C724.363037,347.949310 726.459290,344.783905 727.824158,340.866180 
	C728.609863,339.074371 728.950378,337.534515 729.085999,335.926636 
	C729.244141,334.050812 730.311157,333.062012 732.543396,332.988678 
	C733.349243,332.927917 733.756104,332.726593 734.128723,332.442535 
	C733.913818,332.380157 733.660950,332.455719 733.056030,332.728027 
	C728.728821,331.985443 729.775696,328.113800 728.364868,325.304565 
	C728.378967,322.063721 730.426819,321.562073 733.044861,321.334015 
	C734.532837,321.739105 734.913696,322.710938 735.511597,323.959656 
	C735.744873,324.522308 735.828369,324.750031 736.022400,325.321533 
	C736.329895,326.353516 736.501709,327.049286 736.858276,328.063293 
	C737.026550,328.634705 737.084290,328.865662 737.202393,329.447632 
	C737.573364,338.586151 733.722046,346.548035 731.872620,355.996490 
	C734.912659,352.468964 736.530762,349.511902 736.602295,345.744965 
	C736.714539,339.828186 738.980957,334.490021 741.527710,328.931671 
	C742.510864,327.955750 743.576965,327.483826 743.806763,325.948029 
	C743.933350,325.415314 743.984741,325.200531 744.121216,324.662842 
	C744.768250,322.833923 744.557983,321.178650 745.242615,319.356812 
	C746.607056,316.970459 749.317749,316.105896 749.981567,313.179077 
	C750.496887,311.569000 750.650757,310.226501 750.912109,308.561890 
	C752.298218,305.596069 748.152344,304.113861 749.758423,301.210999 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M777.191223,326.603271 
	C775.709045,332.294983 774.295654,337.583649 774.626892,344.152710 
	C776.656494,341.583099 775.415100,339.582153 776.760742,337.260742 
	C778.201660,341.606354 776.397522,345.440674 776.976990,349.581512 
	C776.070618,350.820984 775.070679,350.634888 773.697632,350.227509 
	C772.393311,349.686310 771.534180,349.150604 770.607300,348.104675 
	C769.152527,344.451233 772.450623,340.524719 768.784790,337.282776 
	C767.766846,336.382599 768.682068,333.814514 769.005188,331.969177 
	C767.384827,333.908813 765.695068,334.114929 765.263245,331.451233 
	C764.721069,328.107025 763.828552,324.686707 764.629456,321.402100 
	C763.977844,323.617432 763.852783,326.873108 759.560059,324.925171 
	C758.063843,322.085114 758.554443,319.449799 758.914001,316.822235 
	C759.683655,311.198792 759.595154,305.595367 758.729736,299.980072 
	C758.334595,297.415924 757.457703,295.197876 755.113525,293.250977 
	C754.201660,288.962555 753.461975,284.941223 753.104431,280.886139 
	C752.916504,278.754639 757.284973,275.843323 759.098816,276.964722 
	C758.649719,271.743958 756.469177,267.247162 754.729370,262.298401 
	C752.894043,261.290527 753.768555,259.757294 753.227417,258.284882 
	C753.009766,257.963165 752.989258,258.004944 753.011841,258.010529 
	C752.029785,256.393005 752.678406,254.617004 752.751648,252.518143 
	C757.218506,255.765076 758.344910,261.217865 759.620178,266.053894 
	C762.480835,276.901947 765.108826,287.908936 765.041565,299.291626 
	C765.032043,300.906158 764.713196,302.827972 767.693115,302.881500 
	C768.693115,303.547668 768.824402,304.324615 768.392334,305.539764 
	C766.596985,314.816071 768.399658,322.837341 774.956604,328.933960 
	C774.913879,324.161194 774.917664,318.539429 776.047424,312.599670 
	C777.948547,311.925476 777.776001,313.391296 778.010010,314.963196 
	C778.018799,319.026703 777.251892,322.596375 777.191223,326.603271 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M754.834351,293.431091 
	C758.104919,292.896759 759.505554,294.098480 760.178894,297.794586 
	C761.790710,306.641388 760.363525,315.328735 760.031799,324.528442 
	C761.884033,331.442261 759.404785,337.394562 757.692139,343.827606 
	C757.413269,344.434692 757.284241,344.669861 756.559082,345.431763 
	C755.551331,345.954803 755.139648,345.951080 754.419373,345.939941 
	C752.106873,344.527679 751.955994,342.494720 752.160522,340.309113 
	C751.793579,343.159821 750.730652,345.662903 748.209534,347.758240 
	C744.943481,349.772797 744.360229,345.348907 741.395691,346.208252 
	C743.301025,343.862061 744.759399,342.152435 746.118286,340.367126 
	C747.316589,338.792786 749.494629,337.990448 745.860229,335.611847 
	C742.807739,333.614105 745.661377,328.746216 747.503479,326.780365 
	C753.150879,320.753693 753.299683,313.166412 754.974121,305.960785 
	C755.305237,304.535919 755.562561,303.093872 755.685669,301.397827 
	C755.458984,300.946777 755.400513,300.757629 755.393066,300.276001 
	C755.370239,299.822388 755.296387,299.661316 755.334290,299.259094 
	C755.259338,297.300018 755.072693,295.582153 754.834351,293.431091 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M780.717163,292.242859 
	C782.413025,300.933197 784.744812,309.815948 784.065735,319.497925 
	C784.174744,329.387451 783.766663,338.777679 781.789246,348.034210 
	C781.630554,348.777344 781.435364,349.680908 779.991089,350.006500 
	C779.991089,338.527985 779.991089,327.170624 779.988220,315.335693 
	C779.411072,314.246094 779.288025,313.535950 779.756348,312.441620 
	C780.241516,305.606537 779.497009,299.055206 780.717163,292.242859 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M746.355896,318.633392 
	C745.931580,320.869690 745.747559,322.733978 745.287231,324.881653 
	C742.432617,322.324249 741.258118,319.098999 741.901428,315.198456 
	C742.303528,312.760468 742.042603,310.267090 741.889160,307.411926 
	C742.050476,301.773132 742.658813,296.517029 741.039551,291.335266 
	C740.639160,290.053986 740.332153,288.875397 739.393433,287.884247 
	C738.624329,287.072327 737.416443,286.007172 738.443848,284.984131 
	C739.394104,284.038025 740.450989,285.442047 741.777588,285.912689 
	C741.105957,290.524048 743.451782,294.231171 745.071960,298.482971 
	C745.360352,299.726074 745.597290,300.612183 745.987183,301.813232 
	C746.969666,303.014587 747.309753,304.091766 747.399902,305.708923 
	C747.585999,307.576324 747.598877,309.014496 747.464111,310.889893 
	C747.622375,313.693817 747.702026,316.051880 746.355896,318.633392 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M768.519043,305.846863 
	C768.153076,305.145996 768.114746,304.390076 768.092163,303.250122 
	C769.615051,297.847626 769.375854,292.829742 768.052979,287.398071 
	C768.409790,286.612823 768.886108,286.467468 769.759277,286.758514 
	C773.319336,293.496460 771.838074,300.481384 772.579773,307.275116 
	C770.210388,305.943481 771.562927,303.704742 770.639160,302.032990 
	C769.326233,302.985352 770.742310,305.187042 768.519043,305.846863 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M770.062012,286.557220 
	C769.623596,286.981445 769.159912,286.993652 768.349365,286.994934 
	C767.512634,280.027679 765.082336,273.477570 763.763184,266.695251 
	C763.450500,265.087860 763.255127,263.457642 762.999268,261.422791 
	C766.243469,269.133881 768.269592,277.603210 770.062012,286.557220 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M779.604248,312.021362 
	C779.975525,312.817657 779.964600,313.499237 779.969482,314.519501 
	C779.805176,314.872192 779.625061,314.886230 779.174927,314.922363 
	C778.592407,315.325409 778.188416,315.470032 777.458862,315.165833 
	C777.018066,314.101166 776.823425,313.280396 776.347473,312.318604 
	C774.203491,307.085266 777.207764,302.109772 776.274719,297.009766 
	C777.494507,301.972656 776.868164,307.219666 779.604248,312.021362 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M746.448975,306.164490 
	C746.194092,304.908966 746.152222,303.895477 746.125244,302.505127 
	C747.194946,301.768555 748.249817,301.408905 749.638733,301.000244 
	C748.875122,303.899750 754.195312,304.384827 752.254578,307.780212 
	C749.989990,308.241791 748.561707,306.534912 746.448975,306.164490 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M774.076416,283.669830 
	C774.065186,282.570374 774.065186,281.789551 774.065186,281.008698 
	C774.249939,280.995056 774.434692,280.981415 774.619446,280.967743 
	C774.841370,282.319946 775.211792,283.671478 775.217590,285.024597 
	C775.220215,285.629669 774.532471,285.358917 774.288452,284.920898 
	C774.140442,284.655243 774.149292,284.302216 774.076416,283.669830 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M775.670532,292.696747 
	C774.836426,291.214630 774.712463,289.504913 775.448303,287.637482 
	C776.462585,289.283661 775.889221,290.899292 775.670532,292.696747 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M752.685303,252.370300 
	C753.012268,253.807770 753.083557,255.490982 753.094604,257.595154 
	C751.185120,256.400635 752.114624,254.473938 752.685303,252.370300 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M752.971375,258.369812 
	C754.450745,258.697113 753.864502,260.183868 754.393738,261.664673 
	C752.698181,261.582672 752.910583,260.132629 752.971375,258.369812 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M770.144653,269.696594 
	C770.230774,269.237762 770.357605,268.995178 770.484375,268.752625 
	C770.594238,269.139587 770.724182,269.522827 770.796936,269.916626 
	C770.811157,269.993500 770.604248,270.187592 770.497925,270.188660 
	C770.394714,270.189667 770.289673,270.012024 770.144653,269.696594 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M755.194397,299.068481 
	C755.750305,299.218994 755.825745,299.478271 755.558228,299.889618 
	C755.017029,299.846100 754.356506,299.844086 755.194397,299.068481 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M763.003479,260.997803 
	C762.762939,260.657288 762.518921,260.319244 762.274841,259.981232 
	C762.394714,259.897247 762.514526,259.813263 762.634399,259.729279 
	C762.745361,259.999451 762.856323,260.269623 762.980042,260.773712 
	C762.992737,261.007660 763.000000,261.000244 763.003479,260.997803 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M779.794067,288.020477 
	C780.043701,288.324554 780.056641,288.803406 780.114624,289.637390 
	C779.293884,289.616272 778.994690,289.050171 779.794067,288.020477 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M761.532837,257.551697 
	C761.479553,257.585541 761.586060,257.517883 761.532837,257.551697 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M756.203125,345.261414 
	C758.246826,338.796539 758.747070,332.226562 760.005249,325.391693 
	C764.389587,325.207031 763.457458,321.411774 764.841919,317.907837 
	C764.609009,323.617737 767.702393,327.645813 765.916321,332.541229 
	C768.730896,332.337036 767.718689,329.428253 770.278503,328.943604 
	C767.815308,335.351746 773.701904,340.947845 771.022766,347.652832 
	C771.450623,349.095245 771.994629,349.907562 773.604187,349.149506 
	C774.706482,349.329834 775.511292,349.642700 776.637939,349.991821 
	C775.530640,355.817413 773.769775,361.681335 772.745178,367.362213 
	C771.957520,371.728882 772.290344,376.727356 770.428894,380.907013 
	C766.931641,388.759644 766.842468,397.338806 764.372192,405.376099 
	C763.977051,406.661682 762.871155,409.064087 766.511658,408.003418 
	C769.297241,407.070526 770.587646,404.840057 772.696655,403.088226 
	C773.875122,402.417114 773.961304,401.553070 773.981934,400.325623 
	C775.605042,398.865875 776.853394,400.001648 776.979065,401.189850 
	C777.440613,405.554657 780.798584,407.264313 783.952026,409.685059 
	C784.067017,410.751984 784.034973,411.499786 784.010254,412.621948 
	C782.934814,417.592224 778.387756,418.708466 775.141052,421.482056 
	C772.949280,419.146484 771.754395,416.231354 768.151978,415.744568 
	C761.609009,414.860535 760.806213,415.177399 757.598633,421.313538 
	C754.914917,421.051331 752.412537,421.445923 749.947510,420.079407 
	C749.585632,419.455078 749.521667,419.060028 749.718506,418.394287 
	C749.813599,417.669952 750.775879,418.360748 750.264526,417.402374 
	C749.389648,416.406433 749.050720,415.430634 748.398071,414.275391 
	C747.624817,413.389313 746.811951,413.113770 745.787598,412.541321 
	C745.134399,412.091614 744.801208,411.778809 744.284912,411.151276 
	C741.185486,403.859741 737.661743,397.185364 733.614685,390.779724 
	C732.651978,389.255951 731.673889,387.707458 731.826538,385.449158 
	C733.416260,380.521118 735.754883,376.148499 735.703674,371.062408 
	C738.251526,371.501221 738.631226,373.264496 738.921387,375.140289 
	C739.229492,377.132141 739.505676,379.397095 742.130981,379.451660 
	C744.463806,379.500183 746.165955,378.100342 747.165405,375.974213 
	C747.799438,374.625397 748.093689,373.122253 748.963745,371.880371 
	C749.726318,370.791687 750.481873,369.552185 752.015442,369.632477 
	C753.827209,369.727295 754.419373,371.220734 754.897095,372.651794 
	C755.653503,374.917603 755.329529,377.636749 758.026489,379.673431 
	C760.916504,371.972290 764.354126,364.838837 766.719299,357.265472 
	C767.129272,355.952545 767.080811,354.630157 766.618225,353.348724 
	C765.382568,349.925262 765.455261,346.508026 766.619141,343.436798 
	C762.604492,344.955811 764.422363,350.587982 760.166382,352.526825 
	C759.468689,352.791809 759.028748,351.743103 758.746826,352.899384 
	C758.807983,355.420868 757.745056,357.046875 755.549438,357.392975 
	C753.184875,357.765717 753.226257,355.592377 752.812317,354.116577 
	C752.382446,352.583862 750.937500,351.896057 750.086243,350.315430 
	C750.803955,348.889893 751.972900,348.834290 753.079102,349.178131 
	C755.488831,349.927124 755.939148,348.575378 755.979675,346.318207 
	C755.962952,345.958527 755.986450,345.987518 755.974243,345.986481 
	C756.107544,345.828857 756.253052,345.672241 756.203125,345.261414 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M774.774414,421.199097 
	C776.640930,417.144470 781.226990,416.516144 783.719849,413.141174 
	C787.203918,413.315582 789.777954,415.145172 792.764282,416.720551 
	C788.438110,419.990570 791.300110,423.356598 792.769165,426.882843 
	C790.807434,431.973907 787.606750,436.284912 785.013184,440.931641 
	C783.652344,443.369751 779.671204,444.390076 776.795410,445.902710 
	C776.166931,446.233276 775.077087,446.111755 774.615662,445.271545 
	C774.117126,444.363556 774.802917,443.649292 775.430420,443.169556 
	C780.317749,439.432739 781.157043,434.443268 779.976685,428.389130 
	C779.702515,427.575531 779.642822,427.110596 779.511841,426.661957 
	C779.271240,425.838226 780.310730,424.891693 779.352661,424.293671 
	C779.199219,424.197845 778.302979,425.321198 777.296021,425.857086 
	C776.964050,425.974854 776.493164,425.967834 776.257690,425.964386 
	C775.480164,425.433990 774.853455,425.614502 773.910156,425.702576 
	C773.391785,425.563965 773.195740,425.469482 772.786255,425.126709 
	C772.414978,424.467743 772.343506,424.043518 772.466919,423.292419 
	C772.994263,422.178802 773.751892,421.777191 774.774414,421.199097 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M784.351196,409.761261 
	C781.785339,409.863708 780.225159,407.611725 777.693909,407.853302 
	C776.431274,407.973816 776.346130,406.879120 776.589844,405.741638 
	C777.061096,403.541870 776.562622,401.583984 774.343201,400.063080 
	C774.000305,399.662506 773.956482,399.319550 774.026306,399.001526 
	C774.488281,396.898010 774.945435,393.924835 777.449463,394.371185 
	C779.946411,394.816254 780.170166,397.669678 779.473022,400.083679 
	C779.237732,400.898682 778.964844,401.661926 780.264404,402.923615 
	C781.505737,398.792877 782.689392,395.011047 783.767517,391.199371 
	C784.291016,389.348450 785.034729,387.484222 787.042664,387.255219 
	C789.305237,386.997192 791.500122,388.610229 790.342834,390.727692 
	C787.102844,396.655731 788.433777,403.867493 784.351196,409.761261 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M769.411804,401.275757 
	C767.585876,398.786316 768.408875,396.098083 769.647583,394.240692 
	C773.569031,388.360626 775.657471,381.901276 777.235535,375.133667 
	C777.522095,373.904877 777.034363,371.550232 779.799316,371.781067 
	C778.670959,379.666656 775.549500,386.758270 772.985962,394.027130 
	C772.378479,395.749542 771.692627,397.444305 770.985657,399.516663 
	C770.844116,400.614960 770.387512,401.036011 769.411804,401.275757 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M784.296021,356.775391 
	C781.215027,351.924561 783.479065,346.645630 784.348083,341.718262 
	C785.071899,337.614197 783.486023,333.068054 786.680542,329.139709 
	C786.135376,338.112244 786.643494,347.414886 784.296021,356.775391 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M800.592285,418.105530 
	C803.378601,420.678772 800.188416,422.357117 799.415100,424.754639 
	C798.866150,425.254700 798.608643,425.567932 798.089233,425.968201 
	C797.787781,426.022125 797.406128,426.013245 797.215271,426.008453 
	C796.847046,426.193665 796.669678,426.383698 796.214722,426.300018 
	C795.937073,426.026276 795.492676,426.013489 795.270752,426.001099 
	C794.900818,425.838165 794.752869,425.687592 794.815796,425.300842 
	C795.313965,421.655975 797.885559,420.020264 800.592285,418.105530 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M769.070801,401.620728 
	C769.536743,400.899567 769.990417,400.544891 770.686279,400.035889 
	C771.539001,400.713074 772.149536,401.544556 772.881958,402.662720 
	C772.363403,405.885834 770.682739,407.690247 767.189514,407.895142 
	C767.670776,405.940033 769.627625,404.448792 769.070801,401.620728 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M787.915771,385.771667 
	C786.283447,385.638641 786.080566,384.945007 786.841614,383.932709 
	C787.258911,383.377747 787.911682,383.156586 788.443909,383.670166 
	C789.151428,384.352966 788.844727,385.073669 787.915771,385.771667 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M799.185608,407.996338 
	C799.696350,407.884949 800.350708,408.066132 801.043701,408.586548 
	C800.497986,408.713470 799.913635,408.501190 799.185608,407.996338 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M794.806763,425.157715 
	C795.026733,425.064636 795.026794,425.527039 795.037842,425.757874 
	C795.048828,425.988739 794.587891,426.020508 794.358887,426.039612 
	C794.153015,425.716278 794.305298,425.446991 794.806763,425.157715 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M794.421387,419.373718 
	C794.504883,419.304626 794.542786,419.552795 794.542786,419.552795 
	C794.542786,419.552795 794.337830,419.442810 794.421387,419.373718 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M792.990601,416.983337 
	C793.203308,417.080780 793.426514,417.194580 793.649780,417.308380 
	C793.468628,417.321136 793.287537,417.333923 793.037109,417.164032 
	C792.967896,416.981384 793.001099,416.999634 792.990601,416.983337 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M734.188477,333.691772 
	C731.811584,334.073120 729.177246,333.614471 730.600586,337.343658 
	C731.100769,338.654266 729.910706,339.436432 729.155090,340.683350 
	C728.495728,341.509705 727.880737,341.776703 726.828186,341.726257 
	C725.921753,341.458893 725.441650,341.082245 724.718506,340.604492 
	C724.307922,340.482605 724.141052,340.460815 723.880798,340.673523 
	C718.373535,338.594360 715.494934,340.107056 714.158936,346.627655 
	C713.463440,350.022400 712.371582,353.224182 711.091675,356.800476 
	C707.930481,357.213043 706.783997,355.219513 706.077148,352.323242 
	C706.978638,347.566925 708.254150,343.356140 711.400879,339.546021 
	C715.495117,337.326050 715.833862,333.520508 716.447754,329.975067 
	C717.097290,326.223969 717.679749,322.478577 719.377075,318.659058 
	C720.821716,317.240570 720.586487,315.846008 720.333801,314.448395 
	C720.178223,313.786987 719.238953,314.592896 719.872986,314.386902 
	C720.141846,314.299500 720.268494,314.365845 720.331482,314.560425 
	C720.635498,315.499756 720.460571,316.443054 720.180054,317.691193 
	C718.606323,319.039642 717.729797,318.126099 716.654480,316.892517 
	C714.496826,313.380798 716.733582,310.742737 717.862061,307.634125 
	C719.983765,306.142090 721.689819,304.322632 724.594971,305.724304 
	C724.916382,307.412079 725.303528,308.989868 724.876282,310.302582 
	C723.790344,313.639008 725.269043,314.331238 728.520325,314.025757 
	C729.267883,314.334991 729.501221,314.700562 729.591797,315.483643 
	C729.256775,317.038727 728.595581,318.207458 729.002319,319.873260 
	C729.491089,321.753204 727.819336,323.047852 728.755127,324.826843 
	C732.175415,326.287170 729.169739,331.248108 733.574036,332.018036 
	C734.353516,332.408081 734.507935,332.857880 734.188477,333.691772 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M741.686584,307.306763 
	C742.301453,308.261169 742.891724,309.490967 742.943665,310.743134 
	C743.135010,315.356781 743.004395,319.998993 745.000610,324.750793 
	C745.010864,325.165039 745.023682,325.572388 745.014038,325.775940 
	C745.151123,327.300415 742.335510,326.466522 743.174988,328.653748 
	C740.095215,333.235992 738.898071,338.413910 738.329285,343.233795 
	C737.790222,347.801025 736.685364,351.791382 734.652893,355.767120 
	C733.796326,357.442657 732.728210,358.020721 731.120544,357.401764 
	C729.053894,356.606110 730.605530,355.247406 730.826294,354.158081 
	C732.468994,346.053711 736.023254,338.359528 736.022827,329.465118 
	C736.143311,328.853455 736.263245,328.678375 736.192749,328.248169 
	C735.690674,327.531708 735.378906,327.070404 735.042114,326.318085 
	C735.144287,325.890106 735.271484,325.753143 735.591553,325.412415 
	C736.185974,324.794128 736.512756,324.334167 736.761780,323.477600 
	C737.638428,322.061005 737.455566,320.738068 737.843567,319.251343 
	C741.127563,317.732605 740.334106,314.724823 740.940308,312.393005 
	C741.339966,310.855591 741.272949,309.196838 741.686584,307.306763 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M694.704590,229.174500 
	C698.269104,229.805115 701.017761,230.673294 699.256958,235.244553 
	C698.398682,237.472687 703.032349,240.411575 706.987671,239.997955 
	C709.077515,244.038773 710.047058,248.622864 711.006958,253.604279 
	C710.776062,255.314362 709.921814,255.991852 708.382568,256.307434 
	C707.456726,255.963684 707.048279,255.455948 706.484375,254.674652 
	C705.819458,253.952454 705.276611,253.943710 704.396118,254.346985 
	C702.602295,254.935318 701.529236,254.153488 700.250916,253.025757 
	C699.864075,252.625168 699.722961,252.452042 699.420410,251.985870 
	C698.920105,250.873062 698.762085,250.001038 698.415649,248.840637 
	C698.243774,248.317734 698.180847,248.106155 698.040894,247.571732 
	C697.705505,245.952698 697.709961,244.608002 697.153198,243.027161 
	C695.468933,239.810577 695.133179,236.398895 693.001709,233.619781 
	C691.864319,233.580383 691.137207,233.191681 690.079529,232.889175 
	C688.976257,232.917923 688.203552,233.160461 687.098694,233.408127 
	C686.556030,233.401947 686.338013,233.356903 685.830200,233.135757 
	C683.836792,230.559082 685.709045,229.701157 687.692383,228.857239 
	C688.026917,228.967621 688.016418,228.997940 688.013489,228.984406 
	C688.174927,229.103485 688.339355,229.236069 688.756226,229.177307 
	C691.203674,231.065094 690.737732,226.230515 692.825928,227.799286 
	C693.388977,228.544312 693.765076,229.088562 694.704590,229.174500 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M724.923401,305.523682 
	C722.391479,305.235352 720.951111,307.764709 718.373291,307.987549 
	C717.617798,307.629608 717.379211,307.185699 717.258179,306.282013 
	C717.544373,304.387665 717.490173,302.855286 717.476440,300.934570 
	C716.109741,292.949738 714.571411,285.393066 713.623291,277.306763 
	C713.554504,275.406158 713.555847,273.944061 714.778809,272.341217 
	C720.836975,282.288147 724.930420,293.081116 724.923401,305.523682 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M699.850098,252.227570 
	C701.827148,251.611389 702.083496,253.757889 703.788086,254.037659 
	C704.278320,254.262238 704.435791,254.413666 704.752441,254.855515 
	C705.148010,255.735718 705.298340,256.356842 705.798096,257.079041 
	C706.645264,257.058899 707.004456,256.548309 707.776123,256.056366 
	C708.874207,255.372070 709.660278,254.806641 710.714966,254.123352 
	C713.390381,259.439301 713.499390,265.300629 714.246948,271.486389 
	C710.745728,272.597076 707.522888,271.116455 707.977905,268.405151 
	C708.526489,265.135956 707.432800,266.645538 705.875854,267.352753 
	C703.749329,266.735168 703.170837,265.128601 702.612549,263.598175 
	C701.601257,260.825867 700.401978,258.680267 696.827759,258.548950 
	C694.516174,258.464081 693.097961,256.622650 693.316772,253.716797 
	C694.737366,251.603348 696.275818,252.645248 697.623779,253.205948 
	C698.726440,253.664597 699.312317,253.630997 699.850098,252.227570 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M705.632202,267.689880 
	C706.808350,266.113739 707.734558,264.832550 709.293884,262.675476 
	C709.102295,267.039185 708.076294,271.425659 713.960999,271.948242 
	C714.337036,271.912842 714.698120,271.949829 714.877991,271.974365 
	C715.026428,273.341370 714.994995,274.683807 714.924438,276.427551 
	C713.425049,279.186646 713.674377,282.211609 711.669861,284.726807 
	C710.596008,285.701538 709.649414,285.899261 708.252319,285.467865 
	C707.551331,284.893707 707.307068,284.438629 707.102844,283.540894 
	C706.996094,278.043396 704.286438,273.221405 705.632202,267.689880 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M735.246704,324.772003 
	C734.113647,324.438599 734.009766,323.573975 734.001709,322.332977 
	C732.888672,319.671661 732.396790,317.246429 732.917480,314.336823 
	C731.969849,310.857910 734.076416,306.212006 731.681641,304.613617 
	C727.451660,301.790405 730.524536,298.455170 729.168640,295.678070 
	C731.343445,295.118408 730.583435,297.554535 732.084900,297.582672 
	C732.130005,295.432037 733.409241,293.800537 734.608765,291.925385 
	C735.717957,294.619080 733.892151,296.790436 733.788086,299.161346 
	C733.690735,301.381683 730.647461,304.290314 735.439514,305.337341 
	C735.845520,305.426117 735.964417,307.459442 735.979797,308.592560 
	C736.035889,312.749512 735.992004,316.907867 735.989258,321.533325 
	C736.134277,322.912262 736.075684,323.785889 735.246704,324.772003 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M732.732788,314.002014 
	C733.438660,316.432037 735.412903,318.640991 734.031738,321.665222 
	C732.256897,322.535187 730.210999,322.434631 729.069702,324.679077 
	C727.487122,326.286255 727.034790,325.484039 727.201355,323.981110 
	C727.322815,322.885742 727.719299,321.820831 728.002441,320.372772 
	C728.522583,319.372345 728.992249,318.705536 729.820312,317.953491 
	C730.858521,316.794922 730.711548,315.415344 731.711060,314.234192 
	C732.103516,314.011108 732.283264,314.007599 732.732788,314.002014 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M731.654541,313.883179 
	C731.813171,315.467712 731.702515,316.920837 731.362427,318.651978 
	C730.188354,318.332672 729.061401,317.933746 728.743652,316.360626 
	C728.829773,315.583466 728.875183,315.143799 728.918213,314.372986 
	C729.613647,312.877441 730.469971,313.063477 731.654541,313.883179 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M706.899658,237.699295 
	C705.444336,237.062363 700.925476,237.752975 705.097046,233.966812 
	C705.953613,235.075760 706.442627,236.226547 706.899658,237.699295 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M738.011353,318.746368 
	C738.045410,320.715698 738.018250,322.413208 737.975464,324.535126 
	C737.464417,325.169739 737.032532,325.066650 736.498535,324.358459 
	C736.272217,323.523010 736.339050,323.041290 736.745056,322.276855 
	C737.086243,320.666107 737.107605,319.332825 737.368469,318.048157 
	C737.490784,317.445526 738.268250,317.679901 737.742249,318.088715 
	C737.723145,318.103577 737.876892,318.340912 738.011353,318.746368 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M736.723267,321.986816 
	C736.972900,322.428802 736.979065,322.878143 736.982849,323.664276 
	C736.562317,324.452850 736.635803,325.075073 736.215576,325.795288 
	C736.004883,325.991455 735.510620,326.005432 735.263855,326.016266 
	C735.011414,325.852600 735.005676,325.678131 734.991455,325.241760 
	C735.149658,324.126831 735.316406,323.273865 735.737915,322.210907 
	C735.992676,322.000946 736.479797,321.994171 736.723267,321.986816 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M703.643127,231.855316 
	C703.372009,231.733749 703.081726,231.583725 702.791443,231.433701 
	C702.954102,231.417343 703.116760,231.400986 703.466064,231.574585 
	C703.652771,231.764542 703.623962,231.826843 703.643127,231.855316 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M434.632690,233.762787 
	C434.653351,235.194046 434.418152,236.391083 434.221985,237.954010 
	C434.469299,238.713013 434.558807,239.129776 434.399414,239.873611 
	C434.130707,240.799042 432.532013,241.555832 434.773987,242.003021 
	C435.314148,242.037735 435.534058,242.061920 436.084473,242.174530 
	C436.622711,242.370163 436.830414,242.477371 437.293213,242.833069 
	C438.044006,243.917313 438.372894,244.827103 438.810547,246.087433 
	C438.725983,251.556229 435.621155,255.620270 433.979034,260.205627 
	C433.150452,262.519287 432.159119,264.773865 431.171844,267.410583 
	C430.924377,268.048553 430.624054,268.386322 430.680115,268.641052 
	C431.756195,273.531525 428.794434,276.801086 425.919037,280.483459 
	C425.583466,280.962250 425.428314,281.139038 424.977661,281.509094 
	C421.848572,282.250610 420.623322,282.370331 419.520905,278.335327 
	C418.074310,273.040527 418.816833,268.926086 420.691223,264.271118 
	C421.693939,261.780884 420.615509,259.090057 419.704102,256.925934 
	C416.260376,264.381958 409.532837,270.790405 411.149170,280.485657 
	C411.235077,281.089508 411.265381,281.334320 411.325012,281.955109 
	C411.365814,282.586578 411.377350,282.842072 411.399017,283.485840 
	C411.269012,286.999847 412.258667,290.348969 409.267090,293.174194 
	C407.518738,293.849060 406.448120,293.084686 405.041870,292.207581 
	C403.729614,291.329895 402.739502,290.637695 401.572693,289.591614 
	C399.085724,286.682373 395.069916,285.760437 394.222137,281.658386 
	C393.933258,280.260651 393.719452,279.174652 394.881836,277.824341 
	C397.084259,277.153198 398.980255,277.681763 401.229492,277.710724 
	C403.674957,278.078918 404.296051,277.067078 403.856628,275.182159 
	C403.259735,272.621490 402.789062,270.044434 402.714722,266.487488 
	C401.217926,269.246948 402.440399,272.434875 399.148560,273.564880 
	C398.339050,273.727753 397.872437,273.689697 397.103394,273.398682 
	C395.888275,268.039520 394.779388,262.840424 400.082458,259.047424 
	C401.304474,258.173370 402.306885,256.804108 401.572754,255.191177 
	C400.898468,253.709763 399.294250,253.141220 397.821014,253.214523 
	C395.638367,253.323090 393.554718,253.021667 391.121521,252.515442 
	C389.194244,252.379532 387.605804,252.262009 385.714355,252.764374 
	C383.915497,253.331314 382.260834,253.261368 380.716736,254.534912 
	C377.174469,256.596802 374.033051,258.681580 370.403961,260.557556 
	C369.575165,260.967926 368.966248,261.191833 368.899719,262.285828 
	C366.897003,264.365387 364.545929,264.336090 362.237274,264.431946 
	C358.585358,264.583618 355.006409,265.056335 351.365845,266.892578 
	C348.193298,268.261963 345.123688,269.327881 342.675995,270.992157 
	C340.275024,272.624664 344.197662,276.043488 342.041534,278.857391 
	C340.400482,281.973175 337.242188,282.512634 334.684113,284.459869 
	C331.255219,286.210571 327.810455,287.015991 324.366760,288.574707 
	C322.106812,287.326050 320.675659,285.408997 318.133057,284.507904 
	C317.726624,284.094299 317.601990,283.893738 317.393005,283.348572 
	C318.203247,279.398926 318.237823,275.480194 322.104889,273.186859 
	C323.864319,272.143433 323.672974,270.164917 322.914551,268.278168 
	C320.884277,263.227539 321.997620,261.162201 327.719238,259.781677 
	C328.599182,260.209076 329.212646,260.621277 329.802155,260.589508 
	C330.704620,260.540924 330.579254,259.961426 330.281464,259.230316 
	C329.193024,256.557831 331.412933,255.888214 332.906830,256.283112 
	C336.783936,257.307983 335.852051,254.705444 335.999512,252.394562 
	C337.330750,252.214371 338.431030,254.265228 339.899597,252.837341 
	C341.638977,251.146149 338.499390,251.207077 338.651215,249.985321 
	C338.671417,249.822830 338.682922,249.657959 338.719238,249.498947 
	C339.601410,245.634811 343.542236,245.104218 346.097229,243.251282 
	C347.640228,242.132294 350.789185,242.654602 350.916687,246.523590 
	C350.909302,246.911240 350.725861,247.277588 350.908722,247.185242 
	C352.488647,246.194199 353.920044,245.343948 355.274506,244.385101 
	C357.154327,243.054382 358.142242,241.759171 354.675781,241.620163 
	C352.945496,241.550766 353.019348,240.381943 353.739899,239.730148 
	C357.215240,236.586456 355.729309,232.397278 355.405853,229.002197 
	C355.024078,224.994659 356.545258,224.847214 359.411163,224.968307 
	C360.218048,225.002380 361.053711,224.759781 361.849915,224.546143 
	C362.976471,224.243851 364.310608,223.613007 364.700958,225.286057 
	C365.085327,226.933487 363.854675,227.616501 362.409729,227.926697 
	C361.498138,228.122391 360.147247,228.013824 360.573029,229.544952 
	C360.876923,230.637772 361.886444,230.916153 362.989990,230.799454 
	C370.740173,229.979767 371.689880,230.862915 371.550751,238.727676 
	C371.481750,242.625961 373.286530,243.991364 376.963623,242.432861 
	C377.386078,241.660110 378.942261,241.684845 377.659546,240.616821 
	C376.639130,239.767212 375.928223,238.727219 375.210876,237.248871 
	C375.005371,236.971924 374.996399,237.003967 375.012695,237.000397 
	C374.635803,236.611191 374.475952,236.145477 374.781219,235.289337 
	C376.824860,230.808456 381.873444,229.638977 383.903198,225.211380 
	C385.683289,224.723862 388.952728,226.954178 387.660889,222.478531 
	C387.209381,220.914291 388.221466,220.343399 389.227997,219.805511 
	C391.176208,218.764389 389.685699,216.994843 390.067780,215.606705 
	C390.370422,214.507202 388.479523,214.195480 387.418762,214.155960 
	C385.836151,214.096985 384.807526,215.448212 384.775757,216.681396 
	C384.652130,221.476547 381.763123,220.785553 378.361938,220.012573 
	C380.143524,216.377045 382.575928,212.890137 384.315155,209.086288 
	C385.340118,206.844666 387.991791,207.623642 388.926147,205.346832 
	C389.642059,203.931198 390.302124,202.866669 390.992493,201.487183 
	C394.599274,193.985596 399.566132,187.756287 404.794891,181.286972 
	C405.045837,181.039413 405.018738,181.021774 405.028137,181.024902 
	C405.429382,180.581635 405.501709,180.010254 405.836029,179.255447 
	C406.024078,179.043091 406.006744,179.024612 406.014282,179.025650 
	C406.220947,178.780930 406.441650,178.556412 406.854523,178.189484 
	C407.025085,178.025833 407.041260,178.033157 407.055176,178.045013 
	C411.251862,177.986404 410.826141,181.026260 410.618896,183.636368 
	C410.543579,184.585205 409.839355,185.387497 411.553284,185.849136 
	C415.333710,187.084396 418.118561,189.376663 420.808105,191.690491 
	C423.900085,194.350540 426.483124,197.424011 430.259979,199.472565 
	C433.764618,201.373489 434.609375,205.490799 434.210022,209.780991 
	C432.471436,213.211792 429.307983,214.601700 426.855835,216.747406 
	C425.367493,218.049744 424.498108,219.427063 427.006317,220.432114 
	C430.575165,221.862167 431.785797,224.804184 431.928955,228.200562 
	C432.023560,230.444580 433.451752,231.811050 434.632690,233.762787 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M434.250397,210.029480 
	C432.873749,206.266800 432.834564,201.177795 428.817505,199.762314 
	C422.223480,197.438828 419.775848,190.136185 413.243103,188.086044 
	C412.835419,187.958099 412.710938,186.927719 412.176208,186.085342 
	C413.338989,184.118073 411.606384,180.468582 415.722717,180.239014 
	C415.917511,183.981232 418.465393,185.108643 421.632935,185.995056 
	C421.227264,186.535080 420.461853,187.077454 419.862213,187.502365 
	C424.884125,191.020523 430.197845,194.286224 434.908844,198.265472 
	C436.826355,199.885147 435.840851,203.436234 437.170258,205.992279 
	C437.874451,207.346283 435.587036,208.721771 434.250397,210.029480 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M422.003601,186.003662 
	C422.020142,184.485855 422.099945,182.987854 424.223724,183.127792 
	C424.836548,183.168167 425.657806,183.294174 425.579712,184.138092 
	C425.420380,185.859406 424.038330,185.903091 422.366302,185.988892 
	C421.992676,185.992722 422.000000,186.000000 422.003601,186.003662 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M446.676849,222.171997 
	C447.165527,223.747055 446.564331,225.269882 445.241577,226.871796 
	C444.668823,225.280869 445.471558,223.806503 446.676849,222.171997 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M581.839722,214.191498 
	C584.361084,214.282379 586.108337,213.707932 586.727356,211.328995 
	C587.276001,209.220764 588.651306,207.308716 586.562927,204.945038 
	C582.940979,200.845627 581.917542,195.485733 580.868347,190.149857 
	C581.502014,189.633240 581.926941,189.459473 582.757996,189.489441 
	C583.755676,189.882187 584.261475,190.372574 584.924683,191.201935 
	C587.474426,196.368240 589.981384,201.139694 594.585938,204.858582 
	C594.954712,205.304794 595.085938,205.497696 595.327515,206.024658 
	C593.612793,210.809448 595.484253,214.307983 598.523682,217.853363 
	C601.395325,223.210403 601.787048,228.359222 598.983887,233.734924 
	C596.646179,233.840622 594.784302,234.688263 592.797058,234.789993 
	C587.910339,235.040115 585.650757,232.557510 586.741821,227.763519 
	C587.386292,224.931549 588.103271,222.399963 583.538635,222.091660 
	C581.404175,219.703049 580.681763,217.262955 581.839722,214.191498 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M594.241577,205.747253 
	C589.560486,202.451111 586.517578,197.901108 584.199951,192.460632 
	C584.722473,190.735016 584.528992,189.246674 583.998230,187.445435 
	C583.683105,186.093887 583.327148,185.110321 582.451172,184.023224 
	C581.815125,183.077881 581.329834,182.417374 580.310852,181.838531 
	C578.005920,180.460068 577.752930,177.992233 576.034058,176.178864 
	C572.173889,174.628006 567.865967,174.135727 568.483643,168.417145 
	C568.575073,167.571060 565.995544,166.375916 564.582886,165.447083 
	C563.444336,164.698456 561.373840,164.717911 562.635864,162.213943 
	C563.254211,161.857468 563.387634,161.597198 563.116455,161.045456 
	C562.946777,160.874374 562.928345,160.544769 562.961792,160.383133 
	C562.879578,160.036987 562.763855,159.852478 562.780029,159.369080 
	C564.389954,159.053070 564.983887,159.876816 564.987427,161.611237 
	C565.131470,162.085083 565.280396,162.205200 565.901001,162.638763 
	C566.749939,163.373962 567.151184,163.766846 567.945496,164.033508 
	C574.157654,162.772293 577.365051,166.937088 580.911011,170.413559 
	C582.417114,171.890167 583.894043,173.396484 585.963013,175.019318 
	C586.677979,175.257172 586.814087,175.364960 587.133484,175.659027 
	C587.342957,176.017685 587.369202,176.190048 587.253723,176.582275 
	C585.636292,182.478165 587.608032,186.679367 592.334229,190.010056 
	C594.678650,191.662277 594.190857,194.768112 594.968506,197.613129 
	C595.284668,199.519989 596.282349,200.880676 595.958740,202.871735 
	C595.720215,204.151611 595.193787,204.884903 594.241577,205.747253 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M562.770630,190.325012 
	C562.453491,187.960876 565.128296,189.942780 565.035889,188.165100 
	C566.134766,187.966431 567.198547,188.012909 568.661865,188.088730 
	C569.353333,188.702713 569.361694,189.404953 569.811035,189.924240 
	C569.943787,189.418762 569.598999,189.051926 569.282593,188.358444 
	C569.129333,187.142700 568.690186,185.940628 569.897095,185.519409 
	C571.014526,185.129410 571.677551,186.187332 572.320312,186.884689 
	C574.709961,189.477448 574.284363,193.526840 577.109314,196.355255 
	C577.466919,197.748779 577.114136,198.259811 575.803101,197.944031 
	C575.533997,197.391693 575.397400,197.278503 575.176331,197.431976 
	C575.109863,197.478149 575.083435,197.532761 575.096741,197.595276 
	C575.235352,198.247391 574.847839,197.466339 575.585449,197.927734 
	C575.905212,198.809174 575.971558,199.441635 576.069092,200.429962 
	C578.183594,205.071274 579.010315,209.778397 580.654480,214.209641 
	C581.001709,215.145401 581.013062,216.141785 580.990845,217.122543 
	C580.949951,218.933609 580.108643,220.349091 578.408142,220.929840 
	C576.309937,221.646378 576.058472,219.750473 575.492981,218.483322 
	C575.207458,217.843552 574.789856,217.407745 573.949951,216.785751 
	C570.825806,207.525375 565.467285,199.605743 562.770630,190.325012 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M573.827271,216.087540 
	C577.442749,215.572021 576.731873,218.528214 577.482910,220.341843 
	C580.214661,219.088913 580.293335,216.991913 579.606995,214.807785 
	C578.137146,210.130356 576.518860,205.499588 574.959473,200.463654 
	C575.376892,199.520279 575.766541,198.927719 576.811646,198.954132 
	C579.275269,203.631393 580.635681,208.399063 582.056763,213.544983 
	C582.051880,216.550888 583.018188,218.960083 583.886963,221.775116 
	C585.344727,224.015533 584.468384,225.332916 582.711792,226.000061 
	C580.160522,226.969025 577.624634,228.585999 574.359619,226.898590 
	C573.172852,225.908981 572.762146,224.896378 572.257202,223.483856 
	C571.958618,222.311325 571.924744,221.514236 572.032288,220.319839 
	C572.478638,218.691162 572.689331,217.381470 573.827271,216.087540 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M575.641968,176.250488 
	C579.588257,175.273483 578.426941,179.488144 580.659912,180.829880 
	C581.701294,181.556870 581.937866,182.382904 582.346191,183.474976 
	C582.937927,184.466766 583.626343,185.049667 583.660400,186.302170 
	C583.438049,187.502457 583.558960,188.393784 583.354797,189.584625 
	C582.750000,189.878464 582.288086,189.862183 581.485046,189.904083 
	C579.415833,186.194977 577.687805,182.427704 575.800537,178.326630 
	C575.641296,177.992828 575.621765,177.512543 575.500122,177.305252 
	C575.423462,176.904846 575.468384,176.711731 575.641968,176.250488 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M577.283081,198.970215 
	C576.588684,199.423203 576.071350,199.701920 575.255798,200.029129 
	C574.909058,199.294495 574.860535,198.511368 574.899658,197.372162 
	C575.721802,197.143295 576.346924,198.441544 577.225586,196.976593 
	C577.896973,197.357956 577.797729,198.064499 577.283081,198.970215 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M560.863647,178.375336 
	C560.056946,178.220596 559.278259,177.745087 558.223022,177.216339 
	C557.923035,176.675140 557.899658,176.187149 557.898926,175.334076 
	C559.436462,175.492325 560.515991,176.434738 560.863647,178.375336 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M560.133850,158.285065 
	C560.569946,158.138290 561.200195,158.271805 561.911804,158.737076 
	C561.188049,159.727997 560.832031,158.901932 560.133850,158.285065 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M562.669128,159.032104 
	C562.946594,159.260239 562.981323,159.450302 563.005737,159.930939 
	C562.580505,160.054581 562.165771,159.887650 561.843506,159.374329 
	C561.936035,159.027954 562.426331,158.994019 562.669128,159.032104 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M559.093262,157.371490 
	C559.450195,157.233109 559.830933,157.346756 560.102478,157.757965 
	C559.701233,157.911545 559.409180,157.767548 559.093262,157.371490 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M562.949097,161.156067 
	C563.132385,161.076340 563.336243,161.265930 563.488281,161.490601 
	C563.518677,161.535553 563.351318,161.714218 563.080566,161.914276 
	C562.907715,161.810806 562.929565,161.624283 562.949097,161.156067 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M575.323364,177.219513 
	C575.378540,177.097961 575.630310,177.510345 575.635803,177.751587 
	C575.375305,177.850647 575.250916,177.633392 575.323364,177.219513 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M619.000732,810.217041 
	C618.948853,809.842651 618.936707,809.376160 618.944153,809.141846 
	C628.352722,810.519165 637.484680,813.618652 647.548035,813.995239 
	C654.774475,812.912659 661.543518,812.658997 668.659912,814.679932 
	C671.735107,814.972046 674.419800,815.008301 677.552612,815.050293 
	C679.972961,814.426514 682.008972,814.676147 684.404785,814.621033 
	C685.301208,814.636108 685.805542,814.713684 686.640503,815.035217 
	C689.113464,818.523804 692.422729,816.455444 695.160950,816.967468 
	C698.243774,817.543823 702.288269,815.343140 703.788147,820.366760 
	C703.983032,821.019653 706.161926,821.080261 707.671509,821.684631 
	C694.690308,822.053955 681.425171,822.524170 668.350525,819.986267 
	C666.010925,819.532104 667.035278,822.020203 665.406372,822.113770 
	C662.134827,822.301758 658.890442,822.961609 655.355713,823.698792 
	C645.022034,821.967041 634.977722,819.909180 624.907837,817.985107 
	C620.709595,817.182983 620.939514,813.100281 619.000732,810.217041 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M569.636108,818.028137 
	C576.412354,817.948303 582.863281,818.370239 589.656982,817.057495 
	C595.450500,819.570618 601.174072,818.788452 606.787109,818.951050 
	C609.263916,819.022827 611.479065,819.598022 612.527771,822.089294 
	C613.896729,825.341248 616.881165,820.580200 618.222412,823.308899 
	C619.109131,825.112976 617.014465,827.891113 619.696594,829.010803 
	C621.811829,829.893860 624.059509,832.690186 626.500916,829.108704 
	C628.042542,826.847107 628.031067,827.160095 629.928406,829.690796 
	C630.952576,831.056824 632.827393,831.870178 634.441956,832.689392 
	C637.097717,834.036682 639.853943,835.186157 642.783020,836.692200 
	C644.346924,839.024841 642.923889,840.314880 641.349854,841.836365 
	C636.569763,841.234009 632.835205,838.673828 628.339966,837.062744 
	C620.723938,835.714478 613.643188,833.732239 606.332581,831.221741 
	C606.034607,830.959167 606.006836,830.968140 606.017700,830.973999 
	C605.857300,830.822205 605.686096,830.664551 605.265015,830.263184 
	C599.199158,827.005005 593.580078,823.489929 587.516174,821.106262 
	C580.362366,818.294250 572.916687,821.164612 565.302002,821.694946 
	C564.578613,821.841980 564.147522,821.704773 563.380859,821.512207 
	C562.423157,820.936890 562.291138,820.255676 562.538025,819.160522 
	C564.630371,816.548340 566.976379,817.437134 569.636108,818.028137 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M592.738525,816.109009 
	C590.154419,814.556274 587.785645,813.606812 585.044495,813.670532 
	C584.788879,813.676453 584.310242,812.951172 584.289734,812.547241 
	C584.226868,811.313171 585.330627,810.579529 586.166931,810.584778 
	C589.802002,810.607300 592.992493,808.785706 596.638550,808.509949 
	C602.247925,808.085815 607.820129,806.685547 613.307495,805.300659 
	C617.001221,804.368591 617.734375,805.707153 617.110352,809.238403 
	C613.131897,809.698914 609.535461,810.871460 605.960571,812.838623 
	C602.075806,814.976135 597.762634,816.396729 592.738525,816.109009 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M659.051514,824.397095 
	C671.291565,825.438049 683.600586,826.816162 695.909607,828.194336 
	C690.174622,829.770142 684.805420,832.539917 678.369507,832.859619 
	C677.950073,832.829956 677.975037,832.437195 677.979675,832.240479 
	C673.721313,829.764465 669.105164,830.673828 664.325562,830.770569 
	C658.632141,831.741455 653.648010,830.027466 647.359619,827.921692 
	C653.550720,825.822693 658.438660,829.633606 663.900085,828.448730 
	C662.500183,826.412781 659.802002,826.768494 659.051514,824.397095 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M641.229248,842.273804 
	C640.814148,840.196655 642.699951,839.294006 642.960693,837.312134 
	C647.194397,838.099426 651.390442,839.233459 655.814087,840.658020 
	C651.206238,841.727356 646.225098,841.164368 641.229248,842.273804 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M664.495361,830.981079 
	C669.053040,829.275574 674.075317,829.631775 679.100403,830.865051 
	C679.164124,830.880737 679.243713,831.182617 679.188293,831.265808 
	C679.027710,831.506653 678.801941,831.704102 678.293030,831.981140 
	C673.619934,831.896973 669.359436,830.473755 664.495361,830.981079 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M662.521729,839.543335 
	C662.468079,839.574219 662.575439,839.512390 662.521729,839.543335 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M676.778259,834.607544 
	C677.114136,834.704529 677.223328,834.981750 677.168457,835.674622 
	C676.390930,835.816895 676.115173,835.426086 676.778259,834.607544 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M791.235229,641.193970 
	C789.380310,640.426025 787.478760,642.719421 786.391785,640.943665 
	C785.472961,639.442566 787.185364,637.896729 787.825806,636.407715 
	C791.274231,628.389893 798.880127,625.608215 806.134033,623.352051 
	C809.298462,622.367859 811.518677,618.566345 815.775391,620.016724 
	C816.067322,620.468506 816.024231,620.903625 815.987305,621.668457 
	C815.997986,624.445435 813.695862,625.546692 812.565430,627.749146 
	C809.881714,630.276855 807.033203,631.922119 804.131165,633.489258 
	C802.533508,634.352051 800.684021,635.118469 799.931641,636.807922 
	C798.254272,640.574280 795.282532,641.612183 791.235229,641.193970 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M811.900635,627.366455 
	C813.150085,625.465942 814.277893,623.878418 815.699585,622.144531 
	C816.282776,622.535461 816.963501,623.325745 816.803833,623.572693 
	C813.509949,628.667236 816.659424,632.149475 819.878967,635.971313 
	C819.996948,638.223022 818.600464,639.332214 817.075806,640.779053 
	C814.651794,642.232727 812.358398,642.476746 809.595154,642.160095 
	C808.973083,641.994446 808.732788,641.903381 808.172729,641.601990 
	C806.533325,639.819946 806.546143,637.710876 805.546265,635.627808 
	C804.827759,632.534363 806.834717,631.667175 808.720764,630.631592 
	C809.982056,629.939026 812.149292,630.091064 811.900635,627.366455 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M835.872437,602.580994 
	C836.176392,602.383240 836.391296,602.416382 836.457214,602.331055 
	C836.942017,603.116333 838.688965,603.848511 836.926147,604.960388 
	C836.070374,605.500183 834.789124,605.746033 834.211121,604.839050 
	C833.462646,603.664490 834.653931,603.181519 835.872437,602.580994 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M825.087158,624.208984 
	C824.249634,624.985962 823.073242,625.339661 823.257324,624.253235 
	C823.475220,622.967468 824.636475,621.841553 825.452576,620.545044 
	C826.396912,621.946350 825.755737,622.910156 825.087158,624.208984 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M817.139221,640.125610 
	C817.838135,638.838989 818.773071,637.806580 819.872437,636.523682 
	C820.883728,636.289124 820.883728,636.888062 820.937927,637.787720 
	C820.999451,638.083313 820.987976,638.022827 821.017822,638.030151 
	C820.228455,638.805664 820.011414,640.144775 818.389038,640.519165 
	C817.821533,640.500244 817.598389,640.439941 817.139221,640.125610 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M837.611023,581.559448 
	C838.422058,581.228210 839.155457,580.938354 839.318420,581.811584 
	C839.370117,582.088562 838.809387,582.686401 838.431763,582.792908 
	C837.684448,583.003662 837.227417,582.624817 837.611023,581.559448 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M820.399780,630.070923 
	C820.706909,630.455017 820.815308,631.055664 820.923645,631.656250 
	C820.676147,631.656128 820.428650,631.655945 820.181152,631.655762 
	C820.187744,631.199646 820.194336,630.743591 820.399780,630.070923 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M840.983826,573.922363 
	C840.920898,573.192078 841.503174,572.717102 842.518921,573.496033 
	C842.239014,573.903992 841.880432,573.911865 840.983826,573.922363 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M844.542786,576.477173 
	C844.569397,576.524963 844.516174,576.429321 844.542786,576.477173 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M827.030762,500.762573 
	C831.780273,500.402954 834.481628,503.482147 833.855286,508.577667 
	C833.653198,510.221710 833.210999,511.836212 832.659912,513.762024 
	C831.390991,513.761108 828.787170,513.977478 829.831543,512.615662 
	C833.302368,508.090088 830.285950,506.103333 827.296326,503.744415 
	C826.714783,503.285583 826.481384,502.385590 826.194336,501.125854 
	C826.475464,500.575592 826.649780,500.588531 827.030762,500.762573 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M819.893677,525.875671 
	C820.692200,523.472412 821.497131,521.480042 823.790894,518.801697 
	C824.414429,524.783203 822.175476,529.111145 820.658813,533.545166 
	C820.453430,534.145630 819.725098,534.681763 819.050537,534.238037 
	C818.027344,533.564880 817.350830,532.411743 817.688110,531.255615 
	C818.193298,529.523743 819.077759,527.902466 819.893677,525.875671 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M816.770508,511.219482 
	C816.373596,509.565674 815.948486,507.555603 815.290039,505.269470 
	C815.681824,502.046295 814.399536,498.839630 816.649658,495.928223 
	C818.530090,496.120087 818.829285,497.554657 819.819885,498.791687 
	C820.056152,499.013367 819.991882,499.007935 819.995972,498.975952 
	C821.977844,501.290649 821.103333,503.322540 818.962891,505.356903 
	C818.621826,505.461426 818.521545,505.550140 818.482361,505.604431 
	C818.568665,506.531250 817.870605,506.982483 817.279297,507.797638 
	C816.979980,508.964111 816.861206,509.913666 816.770508,511.219482 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M819.033081,505.646271 
	C818.309814,503.337524 820.915649,501.867126 819.968018,499.268829 
	C821.081482,499.600739 823.125305,499.062836 822.955200,501.550598 
	C823.108948,504.287537 822.976990,506.549744 819.033081,505.646271 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M823.783936,509.803131 
	C824.002869,509.969482 824.455200,509.964569 824.678101,510.002625 
	C825.191833,510.244995 825.450623,510.483887 825.772339,511.005371 
	C825.867554,511.638275 825.867981,512.023315 825.839355,512.695557 
	C825.917969,513.607239 825.756348,514.150818 825.053711,514.741699 
	C821.012390,514.658203 819.565857,513.283203 822.645691,509.770081 
	C823.201233,509.682404 823.383118,509.659607 823.783936,509.803131 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M826.271729,518.114380 
	C826.476196,517.178467 826.878052,516.114807 828.115540,516.854309 
	C828.418335,517.035278 828.695374,517.801331 828.554626,518.085449 
	C828.037964,519.127747 827.249207,518.535217 826.271729,518.114380 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M810.103943,524.206421 
	C810.202698,523.003296 809.524719,521.817688 811.002625,520.727417 
	C811.002625,522.110229 811.002625,523.189819 811.001343,524.634705 
	C810.682068,524.885864 810.422119,524.693420 810.103943,524.206421 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M825.106323,514.887146 
	C824.998779,514.368530 825.211304,513.865845 825.617065,513.172974 
	C826.295715,513.244385 827.220154,513.572815 827.192200,513.756226 
	C827.053833,514.665527 826.215332,514.809265 825.106323,514.887146 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M817.432861,507.944336 
	C817.114136,507.192383 817.055664,506.343689 818.166138,505.737061 
	C818.988098,506.632263 818.898560,507.412231 817.432861,507.944336 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M823.934204,501.832703 
	C823.739868,500.884766 823.892456,499.933533 825.522278,499.797485 
	C826.053101,499.918640 826.237061,500.116089 826.208984,500.658356 
	C825.996826,501.003174 826.000000,501.000000 826.002075,500.997070 
	C825.421448,501.265930 824.838745,501.537750 823.934204,501.832703 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M829.746948,507.989807 
	C829.585876,508.095001 829.423706,508.043182 829.261536,507.991364 
	C829.422974,507.938507 829.584412,507.885651 829.746948,507.989807 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M825.868286,518.051880 
	C825.912231,518.034546 825.824402,518.069153 825.868286,518.051880 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M825.799377,510.987244 
	C825.605652,511.016754 825.344177,510.780243 824.991882,510.292175 
	C825.177917,510.267517 825.454773,510.494385 825.799377,510.987244 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M823.927979,509.823090 
	C823.824768,509.978149 823.646729,509.986816 823.244080,509.850311 
	C823.301147,509.430328 823.579041,509.420837 823.927979,509.823090 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M828.994873,300.993225 
	C829.084595,300.957977 829.179382,300.929352 829.143433,300.955505 
	C829.012756,301.010315 829.000000,300.999817 828.994873,300.993225 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M665.169189,219.281158 
	C663.618469,215.975159 662.200867,212.982224 660.688293,209.695068 
	C660.593323,209.400818 660.691284,208.956818 660.912720,208.905457 
	C662.509766,207.891449 661.554688,207.092941 661.072083,206.099472 
	C659.858398,203.601212 662.182007,205.612762 662.831543,205.047028 
	C664.056824,204.543182 664.806458,204.907455 665.728577,205.738342 
	C667.727844,208.036621 668.157166,211.227310 671.186768,212.236008 
	C668.175720,215.202988 675.061157,215.636414 672.829590,219.046555 
	C671.823364,220.584274 673.075623,223.783646 676.898071,222.772522 
	C677.955383,224.072998 677.628601,225.203629 677.037598,226.661346 
	C676.664551,227.409409 676.414001,227.816422 675.910278,228.487946 
	C675.335754,229.105652 674.944824,229.381165 674.155579,229.671234 
	C671.462646,229.848679 670.607239,228.016373 669.322998,226.179382 
	C669.106873,225.899506 668.998230,225.932861 669.053955,225.927658 
	C667.947754,223.748566 665.709961,222.221954 665.169189,219.281158 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M655.948364,179.938965 
	C657.058960,180.244385 658.144653,180.993271 659.519775,181.880219 
	C660.261963,185.557022 661.640930,188.796875 663.071411,192.395477 
	C663.051392,194.065384 663.663696,195.471085 662.226685,196.786163 
	C659.498352,195.479248 659.320923,193.693481 659.381409,190.743851 
	C659.417725,188.970825 657.138489,186.017258 656.565308,183.298645 
	C656.359985,182.324753 656.137878,181.354401 655.948364,179.938965 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M661.930542,197.339279 
	C662.158020,195.657135 662.376465,194.349228 662.848450,192.901184 
	C665.564209,195.377609 665.428589,199.217804 667.507019,202.049286 
	C668.583008,203.515076 666.940430,204.354172 666.102783,205.680756 
	C665.166443,205.690750 664.391724,205.396622 663.381653,205.080673 
	C661.689819,202.785217 660.587402,200.452499 661.930542,197.339279 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M687.999512,228.614288 
	C687.185791,230.069214 685.398132,230.571625 685.677979,232.961716 
	C681.784912,234.312149 681.418030,229.993439 678.671936,228.755402 
	C678.474976,228.227020 678.440063,227.999847 678.466675,227.431229 
	C678.647705,226.890778 678.767334,226.691772 679.498901,226.193024 
	C680.431519,225.864426 680.873901,225.962143 681.054810,225.788071 
	C685.043030,221.950058 686.870728,224.073990 687.999512,228.614288 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M676.245544,227.142029 
	C676.304871,225.896088 676.661133,224.773209 677.088745,223.300262 
	C678.591431,223.117142 679.637268,223.759781 680.054199,225.571060 
	C680.110901,225.893311 679.938965,226.059372 679.853271,226.142639 
	C679.632263,226.359283 679.497009,226.492645 679.163391,226.830597 
	C678.965027,227.035187 678.994446,227.006622 679.009644,226.992889 
	C678.248657,227.519287 677.374084,227.205505 676.245544,227.142029 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M686.931824,215.323441 
	C688.186890,215.329697 688.599792,216.328506 689.018860,217.649231 
	C687.265320,218.332367 687.295471,216.771881 686.931824,215.323441 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M694.744507,228.882141 
	C694.087341,230.058365 693.471069,229.802750 693.033081,228.301025 
	C693.498230,228.260681 693.997742,228.506058 694.744507,228.882141 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M689.001648,217.991135 
	C689.261780,218.547119 689.542236,219.090134 689.822632,219.633118 
	C689.669739,219.683121 689.389282,219.790237 689.382385,219.774796 
	C689.209167,219.388000 689.064697,218.988312 688.986694,218.268555 
	C689.058228,217.946243 689.021973,217.978165 689.001648,217.991135 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M685.873779,213.466675 
	C685.281189,212.835785 685.057922,212.242188 684.921448,211.319580 
	C685.386780,211.391312 685.765381,211.792053 686.205444,212.477295 
	C686.258972,213.009201 686.251038,213.256592 685.873779,213.466675 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M686.768066,215.057861 
	C686.333252,214.690063 686.028442,214.290848 685.614014,213.660522 
	C685.630554,213.317673 685.756775,213.205948 686.074951,212.928009 
	C686.522156,213.263748 686.777405,213.765732 687.062805,214.555328 
	C687.092957,214.842957 686.898132,215.026413 686.768066,215.057861 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M691.694702,224.291534 
	C691.411926,224.066681 691.274231,223.305389 691.317932,223.294846 
	C692.052246,223.117401 691.898071,223.687820 691.694702,224.291534 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M678.023376,201.690033 
	C677.665955,201.554794 677.355652,201.082596 677.038940,200.290344 
	C677.378479,200.431229 677.724487,200.892151 678.023376,201.690033 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M676.994934,200.003082 
	C676.732788,199.658813 676.225769,199.034836 676.272156,198.990494 
	C677.010315,198.285629 676.882874,199.161041 677.055115,199.722687 
	C677.032471,199.970291 677.007507,199.993088 676.994934,200.003082 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M688.759277,228.973114 
	C688.733582,229.091583 688.458557,229.197205 688.097046,229.136856 
	C688.010559,228.970886 688.509888,228.960266 688.759277,228.973114 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M678.843872,202.826233 
	C678.556519,202.993805 678.331116,202.752960 678.041016,202.269562 
	C677.976257,202.026993 678.005737,201.994781 678.023254,201.978424 
	C678.329163,202.113983 678.617493,202.265900 678.843872,202.826233 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M679.046509,202.965530 
	C679.321472,203.059753 679.506287,203.241791 679.691162,203.423828 
	C679.470581,203.467316 679.250000,203.510788 678.905640,203.394455 
	C678.781921,203.234634 678.956360,203.053345 679.046509,202.965530 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M690.336426,221.670776 
	C690.302368,221.371613 690.411743,221.259079 690.521118,221.146561 
	C690.616760,221.270950 690.792358,221.399963 690.785522,221.518448 
	C690.778748,221.636932 690.589355,221.744873 690.336426,221.670776 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M684.993286,211.006409 
	C684.685852,210.858276 684.384644,210.704224 684.037842,210.279419 
	C683.992249,210.008682 684.000122,209.999893 684.005554,209.994797 
	C684.307251,210.145401 684.603516,210.301102 684.953979,210.723694 
	C685.008240,210.990555 684.999573,211.000488 684.993286,211.006409 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M615.746582,221.809265 
	C616.069336,221.927017 616.505310,222.018921 616.722656,222.067932 
	C616.003723,225.154068 619.219849,225.622726 620.438354,227.742706 
	C620.968628,228.677994 621.168091,229.365005 621.372925,230.425644 
	C621.931580,233.141235 623.219666,235.291412 623.297607,238.122452 
	C623.303894,238.939194 623.270325,239.398972 623.147339,240.181610 
	C622.785217,241.505539 622.438904,242.497971 622.650146,243.892548 
	C622.586670,244.874756 622.363586,245.491287 621.984802,246.409607 
	C621.274414,249.047592 619.405212,251.118607 620.526917,254.117432 
	C620.450134,257.225677 618.796021,259.403656 617.549988,261.671814 
	C615.317139,265.735931 612.880127,269.652039 612.747437,274.493805 
	C612.719055,275.528809 612.092346,276.339813 610.758362,276.907959 
	C609.745667,277.032135 610.584045,277.041016 609.924011,277.103760 
	C609.192505,277.173309 608.465332,276.834717 607.741638,277.115662 
	C608.570679,277.199005 608.697815,277.891174 608.972778,278.861053 
	C608.304443,282.561890 606.273254,285.211456 604.180176,288.204834 
	C602.057495,288.629700 600.963196,285.215515 598.571716,287.549500 
	C597.983337,288.123779 596.157837,287.183350 594.848389,286.082123 
	C594.348633,285.335938 594.203918,284.852142 594.184937,283.966980 
	C594.416504,282.378021 595.305420,281.492767 595.916382,280.088867 
	C596.439941,278.953796 596.797058,278.122040 597.134705,276.914764 
	C599.179199,273.296967 600.652100,270.156555 598.221375,266.233063 
	C596.659973,263.712860 593.599304,264.768890 591.885071,262.451538 
	C591.668884,261.920349 591.639099,261.688354 591.731445,261.128418 
	C594.847168,258.778961 597.845642,258.800629 601.085815,260.381378 
	C602.401489,261.023254 603.714966,262.549164 605.695251,261.208282 
	C606.277161,260.272369 606.654480,259.567688 607.160522,258.596741 
	C607.177490,257.891998 608.552551,258.161957 607.593201,257.147461 
	C607.264954,256.514099 607.164429,256.116180 607.106567,255.387634 
	C607.132935,254.856903 607.163452,254.644501 607.283569,254.120544 
	C607.532898,253.402802 607.712341,253.005447 608.063965,252.320557 
	C608.332275,251.831497 608.447388,251.638657 608.755737,251.168427 
	C610.063354,249.174927 610.278259,247.165573 610.734009,245.245911 
	C611.191711,243.318390 612.349304,242.132584 614.047852,241.174484 
	C618.856079,238.462189 618.998657,236.479706 614.586487,231.357162 
	C613.849426,233.774292 615.228455,236.677094 612.578735,238.175293 
	C610.304016,239.461533 607.909973,239.732483 605.054626,239.150421 
	C601.061340,238.283844 602.311279,235.934784 601.865417,233.233795 
	C600.818298,226.889282 605.026917,224.920425 608.523315,222.005295 
	C610.705200,220.186111 613.059143,220.389557 615.746582,221.809265 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M615.938110,221.682190 
	C610.117126,221.109955 606.468567,224.464981 603.245117,228.815506 
	C602.356628,230.014679 601.309509,230.583969 603.315552,231.749771 
	C604.665466,232.534225 604.387573,234.585373 603.504456,235.240402 
	C599.279419,238.374542 605.366577,236.251526 604.796448,237.746094 
	C606.007019,239.620193 606.324585,241.170685 604.082642,242.588715 
	C600.341675,242.365631 598.284119,240.619858 597.668945,236.871826 
	C597.591736,235.995880 597.548889,235.499283 597.464294,234.639709 
	C597.466248,234.036926 597.504700,233.797531 597.771729,233.275269 
	C599.281128,231.561859 600.289429,224.821533 599.226624,222.990021 
	C598.448914,221.649643 597.871216,220.359528 597.862366,218.422379 
	C598.892212,215.916122 599.294067,213.759735 599.242676,211.341675 
	C599.179626,208.381989 601.421143,207.181061 603.927551,208.696518 
	C605.601196,209.708450 607.720886,210.782944 608.399475,212.506226 
	C609.647339,215.675339 612.179993,217.414062 614.752197,219.727936 
	C615.277527,220.461807 615.542175,220.949585 615.938110,221.682190 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M615.010681,219.643646 
	C611.910217,218.824142 608.433044,218.607925 607.720642,213.936661 
	C607.443542,212.119446 604.575439,210.308334 602.498901,209.276474 
	C600.808289,208.436386 599.837646,209.792664 600.397339,211.768616 
	C601.075500,214.162903 601.971375,216.658630 598.241455,217.970016 
	C593.116211,215.224625 592.180237,211.817261 594.868408,206.321075 
	C595.486389,204.768051 596.145508,203.581619 596.010620,201.845184 
	C596.037903,200.805283 596.090942,200.115204 596.735413,199.252502 
	C602.447021,198.979721 606.720093,200.859665 608.845459,206.694336 
	C606.409302,210.756165 611.132996,210.507278 611.986023,212.668213 
	C612.843018,214.002762 612.977539,215.374283 613.848877,216.753021 
	C614.902649,217.557083 615.002075,218.416534 615.010681,219.643646 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M620.436096,228.074371 
	C618.191284,226.742188 615.003052,226.241226 616.921875,222.405212 
	C619.997498,217.823975 615.045105,214.506485 615.263062,210.265350 
	C614.622620,209.616348 614.471741,209.155640 614.771240,208.315826 
	C616.310974,206.902985 616.825562,204.731613 619.458862,204.904373 
	C622.281189,208.276703 622.219421,212.517578 623.602722,216.634369 
	C622.623474,218.478668 622.785828,219.960129 623.303528,221.583725 
	C624.189148,224.361923 623.457703,226.656921 620.436096,228.074371 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M587.388855,176.992203 
	C587.112061,176.802109 587.019531,176.388916 586.999512,175.703400 
	C591.154297,175.440384 590.792725,179.383759 592.385376,182.226746 
	C592.400452,182.765198 592.332031,182.952118 591.977966,183.225708 
	C590.350098,181.269012 589.007874,179.225647 587.388855,176.992203 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M596.719238,198.886475 
	C596.979553,199.746002 596.982422,200.495758 596.980164,201.620270 
	C596.591370,202.502426 596.247620,203.053604 595.294189,203.130386 
	C594.833435,201.481583 594.666687,199.960724 594.735596,198.221603 
	C595.468079,198.261124 595.964966,198.518921 596.719238,198.886475 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M614.797791,207.881256 
	C614.996765,208.453049 614.995972,208.895981 614.996338,209.671295 
	C613.923035,209.688507 612.317566,210.733429 611.826294,209.096100 
	C611.555481,208.193405 612.356628,207.384659 613.699097,207.049622 
	C614.166077,207.288849 614.358337,207.538849 614.797791,207.881256 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M614.056152,216.639648 
	C612.525757,216.413391 611.983704,215.210953 612.010986,213.299927 
	C613.456177,213.595505 614.026855,214.773483 614.056152,216.639648 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M594.953491,188.423889 
	C595.820129,188.907425 596.488831,189.972504 597.237122,191.164322 
	C594.971313,191.622986 594.965088,190.216599 594.953491,188.423889 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M608.998779,207.000916 
	C609.546204,206.953369 610.094910,206.904922 610.643555,206.856476 
	C610.524536,207.254837 610.525208,207.782196 610.262695,208.025848 
	C609.629517,208.613358 609.208740,208.285217 609.024902,207.259644 
	C609.002441,206.998535 609.000000,207.000000 608.998779,207.000916 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M576.186768,161.677002 
	C577.244873,161.956345 577.893738,162.457352 578.047485,163.687881 
	C577.403076,163.521500 576.784790,163.071793 576.016907,162.373322 
	C575.867249,162.124542 575.975403,161.710449 576.186768,161.677002 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M568.019775,163.841370 
	C567.092896,165.727509 566.650513,163.849487 565.926819,163.279785 
	C566.113220,162.830444 566.266785,162.694214 566.677368,162.727692 
	C567.227722,163.144379 567.521118,163.391357 568.019775,163.841370 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M586.046875,174.952972 
	C586.218567,174.408905 586.371460,173.876511 586.524353,173.344116 
	C586.722961,173.692963 586.921570,174.041794 587.049805,174.704254 
	C586.979492,175.017899 586.996643,175.003052 587.003296,174.997070 
	C586.839661,175.110504 586.669250,175.229919 586.267761,175.139618 
	C586.036621,174.929886 586.028076,174.964661 586.046875,174.952972 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M581.165283,167.143311 
	C581.438416,166.987823 581.651123,167.203125 581.863892,167.418427 
	C581.610901,167.450317 581.357910,167.482224 581.165283,167.143311 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M580.057007,166.246521 
	C580.328979,166.097000 580.649780,166.203125 581.098083,166.540894 
	C581.225647,166.772522 581.079468,166.917603 581.005127,166.989410 
	C580.655762,166.874863 580.380798,166.688507 580.057007,166.246521 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M579.062866,165.256409 
	C579.288391,165.170792 579.609131,165.305344 579.969055,165.715363 
	C580.008240,165.990860 580.000305,165.999634 579.997192,166.002319 
	C579.715393,165.828873 579.436768,165.652725 579.062866,165.256409 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M579.012329,164.991791 
	C578.744202,164.806702 578.459961,164.634995 578.098511,164.217255 
	C578.021301,163.971222 577.993896,164.004913 577.977783,164.020294 
	C578.286560,164.176270 578.611450,164.316849 578.951965,164.746857 
	C578.967651,165.036255 578.996155,165.005188 579.012329,164.991791 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M566.830933,162.698837 
	C566.934387,162.897400 566.446655,162.926697 566.203125,162.946686 
	C565.959595,162.966690 565.996887,162.998917 566.014893,163.015808 
	C565.910706,162.873367 565.788635,162.714005 565.819702,162.295380 
	C566.272278,162.113068 566.523865,162.267792 566.830933,162.698837 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M565.727173,161.993134 
	C566.023865,162.199753 566.074890,162.363403 566.079346,162.779892 
	C565.648376,162.906357 565.263855,162.779984 564.931030,162.309296 
	C564.982605,161.964966 565.481384,161.950150 565.727173,161.993134 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M575.999023,162.003296 
	C575.516907,162.069839 575.255859,161.887375 574.984131,161.352386 
	C574.884216,161.127609 575.172302,160.827362 575.331177,160.686798 
	C575.786438,160.698975 576.018066,160.919724 576.291565,161.426025 
	C576.398193,161.643555 576.130859,161.882034 575.999023,162.003296 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M592.270569,182.757385 
	C592.587463,182.998627 592.705994,183.418991 592.824524,183.839325 
	C592.557190,183.840927 592.289917,183.842529 591.857422,183.578247 
	C591.818848,183.187088 591.945496,183.061783 592.270569,182.757385 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M594.795654,188.207794 
	C594.450195,188.001373 594.223206,187.673004 593.996216,187.344620 
	C594.319458,187.307541 594.642639,187.270462 595.058655,187.537872 
	C595.151428,187.842361 594.914124,188.085861 594.795654,188.207794 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M593.706055,185.700989 
	C593.470276,185.694229 593.417053,185.495804 593.363831,185.297394 
	C593.538757,185.368042 593.713745,185.438690 593.706055,185.700989 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M638.686157,207.782715 
	C641.505615,208.316452 642.104431,210.427505 643.088135,212.630432 
	C644.228210,213.941559 644.748169,215.235031 645.415161,216.566116 
	C645.473877,215.488419 644.969299,214.585892 645.305298,213.309479 
	C645.460205,210.457748 646.891113,211.550583 647.987183,212.057983 
	C653.759155,214.730087 658.244934,218.759354 661.266479,224.802643 
	C662.035034,226.438004 662.554626,227.835449 663.290161,229.548004 
	C663.696533,229.669174 663.886963,229.475204 664.344116,229.589951 
	C664.969482,230.649857 665.063049,231.447327 664.977295,232.662125 
	C664.154236,235.466110 664.049377,238.228989 661.104370,239.798157 
	C656.652344,238.782394 653.935913,242.180023 650.137573,242.975220 
	C648.613525,242.243759 647.536743,241.473328 646.684204,239.995316 
	C646.296143,238.736237 646.251343,237.668716 645.039673,236.837860 
	C642.407715,234.693588 641.911133,231.708984 640.520386,228.832504 
	C640.041321,227.684616 639.802734,226.810165 639.381104,225.634613 
	C639.192688,225.091721 639.124817,224.871262 638.979065,224.312469 
	C638.649292,222.362808 638.682800,220.750885 638.937988,218.807190 
	C639.447754,216.846069 638.795410,215.579926 637.471863,214.215424 
	C637.188171,212.262802 637.109985,210.560440 637.083252,208.434464 
	C637.484802,207.657761 637.906799,207.511581 638.686157,207.782715 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M640.754028,227.687286 
	C643.367371,229.966324 642.897034,233.649750 645.670288,235.807816 
	C646.582031,236.657104 646.814148,237.495880 646.990112,238.695251 
	C647.381958,240.358612 648.713501,240.682846 649.750854,241.739014 
	C651.448669,244.758774 648.784302,247.325562 649.492859,250.420685 
	C648.837708,253.167465 647.294678,254.240509 644.474365,253.988983 
	C641.832458,253.028961 641.390320,250.830780 640.363037,248.597229 
	C640.043030,247.478683 640.022705,246.722916 640.314148,245.623718 
	C641.362854,244.163986 642.390137,244.205338 643.747131,245.162460 
	C644.499573,245.066055 644.280762,246.359650 644.656860,245.651627 
	C643.862366,245.208267 643.464905,244.747040 642.934082,243.963776 
	C640.587646,240.985825 641.001770,237.318192 639.349976,234.351776 
	C637.341064,234.278671 636.733093,237.043121 634.300598,236.473129 
	C632.508118,235.335190 631.976074,233.680679 630.986816,231.898102 
	C630.136475,229.992142 629.528442,228.388046 629.295227,226.296631 
	C629.916565,223.876846 630.895020,222.335526 633.686890,223.204437 
	C634.133118,223.590073 634.174927,223.907867 634.336914,224.092300 
	C634.639160,224.436584 634.744568,224.353653 634.498657,223.726654 
	C635.094666,221.937515 634.047241,221.579803 632.928162,221.149353 
	C631.313538,220.528305 629.432190,220.311218 628.398743,218.199371 
	C628.607666,214.158234 625.284851,210.389511 628.789062,206.843658 
	C632.239136,208.619751 630.917236,211.959930 631.861267,214.439499 
	C632.335571,215.685394 631.338013,217.683258 633.558777,217.917450 
	C635.723511,218.145676 637.016418,217.033051 637.164551,214.350632 
	C639.514771,214.235764 641.824097,214.458038 639.979065,217.951416 
	C639.663940,220.349167 638.944153,222.522552 639.769165,224.852417 
	C640.039124,224.971390 640.008728,225.005112 640.009705,224.982529 
	C640.664856,225.676804 640.407837,226.597717 640.754028,227.687286 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M663.835144,229.955414 
	C663.550598,229.769089 663.559448,229.829193 663.532837,229.846161 
	C664.239441,227.042450 667.816406,228.977661 668.980347,226.282593 
	C671.276550,225.758698 671.578125,228.035446 673.274353,228.994141 
	C677.589905,231.411758 677.422729,236.275894 680.120056,239.803223 
	C680.440308,240.288879 680.558472,240.489777 680.827881,241.005859 
	C682.086914,244.872986 684.040466,248.040283 685.695129,251.739792 
	C686.180298,253.284988 686.242004,254.506073 685.862671,256.100159 
	C685.598450,256.670197 685.457031,256.881134 684.989441,257.290771 
	C681.852478,257.839172 680.585144,255.961777 679.355591,253.533920 
	C678.687866,248.658798 675.808838,245.147659 673.955566,241.188095 
	C673.460632,240.130600 672.527100,239.935440 671.170776,240.724731 
	C667.127319,242.143448 665.717957,240.240753 665.478394,236.537476 
	C666.016418,235.643173 666.604553,235.442963 667.271545,235.292618 
	C668.379517,235.042877 670.396240,236.164978 670.344727,234.109589 
	C670.301147,232.371811 669.432983,230.502274 666.950989,230.723221 
	C665.956787,230.811737 664.967590,230.800995 663.835144,229.955414 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M629.110840,206.595856 
	C628.130859,209.190918 627.916626,211.479477 629.180115,213.596832 
	C629.960205,214.904190 630.131531,215.998230 629.106323,217.535400 
	C627.684387,219.376221 626.054260,219.367233 623.656433,218.471466 
	C621.993896,213.896164 619.345520,210.227188 619.859009,205.318756 
	C619.933350,203.970718 620.061462,203.054169 620.091614,201.802856 
	C623.881104,201.425034 627.236633,202.401688 629.110840,206.595856 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M645.052795,212.977020 
	C645.774048,215.138382 648.609192,217.139893 646.596741,219.294220 
	C644.989502,221.014801 644.131714,219.472427 643.955444,217.640945 
	C643.829163,216.329224 643.523804,215.034744 643.259155,213.344360 
	C643.708679,212.957626 644.197449,212.959106 645.052795,212.977020 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M664.915161,219.272217 
	C667.202393,220.522049 668.347412,222.745316 669.117676,225.565674 
	C667.092102,224.200882 663.741638,223.385681 664.915161,219.272217 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M661.507446,224.987305 
	C662.311218,223.861404 663.302185,223.398697 664.518616,224.471710 
	C664.887939,224.797531 665.010315,225.507996 664.458923,225.556763 
	C663.752625,225.619232 663.006653,225.233261 661.896606,225.040543 
	C661.515442,225.040543 661.545654,224.954544 661.507446,224.987305 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M638.806702,207.757294 
	C638.564331,208.007538 638.156799,208.021408 637.441895,208.023071 
	C637.222229,206.572113 637.992371,207.232697 638.806702,207.757294 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M654.909912,177.315964 
	C655.592346,177.327148 655.916016,177.964172 655.986816,179.143539 
	C655.654602,178.905243 655.317078,178.287979 654.909912,177.315964 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M660.730225,209.265778 
	C660.258179,208.925217 659.458923,208.297699 661.026001,208.631714 
	C661.134216,208.854095 660.867126,209.130753 660.730225,209.265778 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M687.336914,815.087402 
	C686.652527,815.296875 686.323853,815.379761 685.761841,815.559570 
	C683.320679,814.559570 680.727905,815.548096 678.312683,814.033081 
	C675.381470,812.336853 671.997864,813.745361 669.311890,811.037964 
	C669.266907,810.327515 669.389709,810.181152 670.032959,810.034119 
	C675.792847,809.137878 681.116577,809.648315 686.254456,810.201538 
	C693.678650,811.000916 701.096924,810.673950 708.967896,811.381287 
	C710.082581,814.275574 708.384460,814.504883 706.357239,814.499084 
	C702.268433,814.487488 698.179199,814.446289 693.671997,814.663452 
	C691.399902,814.855347 689.546265,814.908142 687.336914,815.087402 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M757.567627,803.995728 
	C754.416199,806.220947 750.500793,807.231506 746.162292,807.496887 
	C747.047119,807.779724 747.931946,808.062561 749.705627,808.629578 
	C744.442322,809.831238 739.954041,810.855957 735.051392,811.846069 
	C733.989380,811.421936 733.135742,811.475403 732.443848,810.499634 
	C732.825684,805.910217 735.984192,807.600891 738.659180,807.240356 
	C744.965759,805.588684 751.007935,804.547729 757.567627,803.995728 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M732.787476,810.716675 
	C733.482483,811.105408 733.925781,811.268494 734.503113,811.621521 
	C721.230835,814.044495 707.781860,815.710327 693.698486,814.834534 
	C696.928101,813.310608 700.783142,814.388245 704.533813,814.002319 
	C706.206299,813.830200 708.360352,814.805908 709.281799,811.890930 
	C710.633362,811.412537 711.827637,811.279968 713.391113,811.134888 
	C717.267700,813.466736 720.790283,809.764099 724.651611,811.238525 
	C726.491394,810.604675 728.697205,811.950928 729.788574,809.367676 
	C731.444824,808.343323 731.811829,809.735413 732.787476,810.716675 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M757.998718,803.997559 
	C760.193237,801.858154 763.172485,801.126282 765.755188,799.683228 
	C766.633789,799.192322 767.929382,798.682068 768.381897,799.759766 
	C768.988281,801.204224 767.566284,801.665100 766.438782,801.988647 
	C763.909851,802.714417 761.548157,804.080383 758.406006,804.002869 
	C758.004883,804.004883 758.000000,804.000000 757.998718,803.997559 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M733.044067,810.559814 
	C731.682800,811.157104 731.148560,810.144104 730.221924,809.141113 
	C729.164307,808.045105 729.152283,807.268738 730.575500,807.091980 
	C731.979370,806.917542 733.398315,806.864014 735.084351,806.674438 
	C736.330139,806.730713 737.302185,806.869873 738.631653,807.015198 
	C737.597656,809.181152 733.484009,806.217407 733.044067,810.559814 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M778.873169,802.711304 
	C778.860291,802.929688 778.719177,803.025269 778.322998,803.137939 
	C778.293518,802.966187 778.519226,802.777344 778.873169,802.711304 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M841.713684,503.090515 
	C843.133545,502.537262 843.032043,500.859161 844.591431,500.767487 
	C845.798706,502.926544 841.686462,504.559875 844.036621,507.098938 
	C842.479797,509.343536 840.777893,511.258698 838.836548,513.384949 
	C838.597107,513.596069 838.238770,513.255310 838.071899,513.072876 
	C837.113159,508.972260 838.748413,505.913788 841.713684,503.090515 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M845.889404,506.596954 
	C846.625610,505.234161 847.485962,504.168243 848.629211,502.999390 
	C848.830078,504.870056 849.255493,507.211609 845.889404,506.596954 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M836.993652,525.991455 
	C837.086792,525.954712 837.186096,525.925659 837.150024,525.954102 
	C837.014648,526.011658 836.999878,525.999268 836.993652,525.991455 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M849.123779,502.823853 
	C849.043152,502.618530 849.203430,502.307037 849.619629,501.910339 
	C849.705261,502.122650 849.534973,502.420166 849.123779,502.823853 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M777.148926,315.385895 
	C777.589050,314.931763 777.965393,314.941559 778.623291,314.947906 
	C779.224426,318.871826 777.838989,322.596771 777.248169,326.720398 
	C776.999878,323.276794 777.042542,319.563293 777.148926,315.385895 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M786.540649,326.471771 
	C786.570618,326.525879 786.510742,326.417664 786.540649,326.471771 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M711.305298,772.177490 
	C707.372498,770.481995 704.301147,767.235535 699.666077,768.758789 
	C698.262390,769.220154 696.319153,768.182800 696.069153,766.738647 
	C695.456970,763.202515 692.773438,762.087463 690.080566,760.367493 
	C692.045532,760.178406 693.419434,757.869507 695.796997,758.725586 
	C696.479370,759.385376 696.860657,760.126526 697.553406,759.997375 
	C698.232910,759.870667 698.934631,760.017639 699.586426,759.756287 
	C699.147766,759.328918 698.485657,759.177124 698.065552,758.279053 
	C697.549316,756.510803 695.994751,755.382568 697.066650,753.104248 
	C697.783203,752.532288 698.187134,752.568298 698.802368,752.922180 
	C699.022705,753.138977 699.033997,753.456116 699.029602,753.614746 
	C699.025208,753.773376 699.264771,754.179749 699.264771,754.179749 
	C699.264771,754.179749 699.736206,754.167297 699.851807,753.961548 
	C699.967468,753.755859 699.967834,753.465576 699.976318,753.320679 
	C700.476562,752.376221 701.440063,752.701355 702.017090,752.023560 
	C702.779236,749.254578 704.890991,749.104980 707.029297,749.006104 
	C708.783569,748.924988 710.594177,749.275024 712.597656,748.162109 
	C713.928223,747.272827 714.932983,747.432861 715.211487,748.525452 
	C715.745178,750.619202 714.060242,751.171021 712.221313,751.714600 
	C711.660217,751.805176 711.299011,751.769836 711.173889,751.913208 
	C710.956299,752.162598 711.064758,752.212830 711.550598,751.850220 
	C713.389954,753.058594 712.759155,754.398743 711.819397,756.011719 
	C711.369751,756.603516 711.085815,756.915283 710.574829,757.422241 
	C710.104431,757.842102 709.907288,758.095093 709.814514,758.421326 
	C710.460266,758.586975 709.728516,758.641357 710.187500,757.806274 
	C710.710510,757.295166 711.012390,756.995667 711.536621,756.441895 
	C714.432800,752.373962 718.051086,754.112000 721.721863,754.764709 
	C723.762512,756.299438 725.674072,757.443604 726.955322,759.678345 
	C726.909363,760.345032 726.707581,760.585388 726.173584,760.857056 
	C725.970581,761.002808 726.007019,761.006836 726.004883,760.988647 
	C724.115784,765.060669 720.338623,767.298340 716.825623,770.263428 
	C715.806458,770.966187 714.986389,771.330139 713.871094,771.851074 
	C712.933960,772.234375 712.285400,772.382446 711.305298,772.177490 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M711.249084,752.217041 
	C710.876038,750.049072 715.833313,751.456848 713.263855,748.046997 
	C710.517334,745.353271 707.297424,744.135193 703.890564,744.086731 
	C698.876648,744.015564 693.852783,743.212830 688.422729,744.018982 
	C686.969421,741.137329 684.017151,742.535217 681.849854,741.301147 
	C681.033203,741.019958 680.601685,740.774353 679.968140,740.164001 
	C679.698120,739.312683 679.805359,738.804810 680.283691,737.995483 
	C681.397400,736.414978 682.505554,735.137939 684.021118,735.127747 
	C692.276794,735.072021 700.299438,732.582886 708.940430,732.982117 
	C711.313538,734.866089 713.969971,736.057861 712.312561,739.832886 
	C711.536133,741.601440 713.744629,743.100403 715.713562,744.226929 
	C717.585327,745.781067 720.769714,744.382324 721.228210,747.660767 
	C720.296021,750.237061 723.372314,751.931458 722.027832,754.662231 
	C718.777649,756.633972 714.611206,753.249512 711.346680,756.924927 
	C710.881104,755.406616 712.630859,754.111328 711.249084,752.217041 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M721.438660,747.938782 
	C720.403381,745.128784 716.928040,748.258240 716.048767,745.344055 
	C715.852966,742.807251 717.657104,741.879578 719.367432,740.467712 
	C723.694946,737.073669 729.204773,736.990356 733.598022,733.918213 
	C734.071350,734.295959 734.317505,734.582458 734.330139,734.878845 
	C734.509705,739.084534 734.502075,739.084839 738.851440,739.982483 
	C739.336975,740.461365 739.483765,740.874451 739.795105,741.449585 
	C738.746826,744.424927 735.047791,747.041687 739.260315,750.323975 
	C739.335693,750.382690 738.971252,751.173218 738.680725,751.508484 
	C735.794922,754.838196 727.995361,754.534241 726.333069,750.542419 
	C725.240662,747.919312 723.797241,748.014221 721.438660,747.938782 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M715.856079,770.192322 
	C718.871399,767.033142 722.149719,764.172424 725.715393,761.141113 
	C726.001404,761.734192 726.000122,762.497925 726.016357,763.643311 
	C724.031189,765.770142 722.028564,767.515381 719.820312,769.531433 
	C719.343018,770.281677 718.946045,770.609619 718.031494,770.849854 
	C717.098694,770.891418 716.592346,770.750854 715.856079,770.192322 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M747.916504,747.904541 
	C747.158752,748.207703 746.415649,748.917847 746.297852,747.843262 
	C746.201477,746.964661 746.969666,746.321411 747.854553,746.424377 
	C748.740051,746.527344 748.678528,747.165283 747.916504,747.904541 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M730.765503,758.009888 
	C730.646973,758.232483 730.309631,758.476013 729.972229,758.719543 
	C729.894775,758.597534 729.817322,758.475464 729.739929,758.353394 
	C730.008911,758.245850 730.277832,758.138306 730.765503,758.009888 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M726.202576,760.996094 
	C726.109802,760.700500 726.310913,760.447327 726.787476,760.125122 
	C727.777222,760.891052 727.012878,760.886597 726.202576,760.996094 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M740.773438,741.704529 
	C740.804260,741.454041 741.136841,741.240417 741.772461,741.068848 
	C741.742126,741.321045 741.408691,741.531189 740.773438,741.704529 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M731.006042,758.008301 
	C731.087524,757.970215 731.162903,757.924377 731.111328,757.933716 
	C730.984314,757.988953 731.000000,758.000488 731.006042,758.008301 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M543.001221,158.001526 
	C542.913879,158.036865 542.825256,158.070679 542.867126,158.051147 
	C542.997559,157.997803 543.000000,158.000000 543.001221,158.001526 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M688.160034,779.888184 
	C688.147034,779.221680 688.109009,778.901306 687.990295,778.348755 
	C693.341980,776.693665 698.914124,775.953674 703.405701,772.203491 
	C704.257507,771.492371 705.592834,772.295349 706.921997,773.093262 
	C707.882751,773.869385 708.352295,774.531738 708.169067,775.709473 
	C707.108826,776.295593 706.234314,777.174255 705.239746,776.542114 
	C703.272644,775.291748 700.756165,775.242859 700.254883,777.239990 
	C699.820190,778.971375 701.746521,780.963684 704.640015,780.995972 
	C705.309143,782.096313 705.564209,783.211548 705.938660,784.285034 
	C706.708374,786.492432 705.765137,787.918640 703.796509,786.661926 
	C700.026245,784.254944 697.392517,784.901428 694.933899,788.346375 
	C694.403564,789.089355 693.434326,788.691040 692.394226,788.166016 
	C691.088806,786.407471 692.389404,784.904846 692.429749,783.316833 
	C692.489441,780.965271 691.469849,780.437317 689.142212,780.644165 
	C688.612061,780.464355 688.418701,780.343994 688.160034,779.888184 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M717.674133,774.728760 
	C720.285828,775.086670 720.329102,777.351257 721.711609,778.766235 
	C722.352661,780.711304 722.734558,782.461792 723.203247,784.610107 
	C721.032654,785.491516 718.520325,785.056946 716.201782,785.669067 
	C710.758240,787.106079 710.214539,786.332947 711.580444,780.727844 
	C711.812561,779.775269 711.859558,778.777527 711.992065,777.395447 
	C712.261902,773.368896 713.118103,772.965210 717.674133,774.728760 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M689.129395,780.120483 
	C690.748291,778.826233 692.509949,778.517822 693.242798,780.117981 
	C694.348572,782.532349 694.147217,785.183350 692.216675,787.735901 
	C691.052856,789.682373 689.769287,790.986755 687.307373,791.162109 
	C684.061584,790.295288 681.514771,789.028259 681.710205,785.162720 
	C683.170227,784.269836 683.799744,785.384521 684.904297,786.032471 
	C685.324524,786.346924 685.491882,786.476074 685.911987,786.795044 
	C687.558716,788.095642 688.440918,787.298523 689.377441,785.880615 
	C689.656433,785.210571 689.790771,784.822021 689.990356,784.146729 
	C690.405701,782.727234 689.612610,782.093872 688.829346,781.117920 
	C688.734985,780.643738 688.788940,780.443176 689.129395,780.120483 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M718.043579,774.752686 
	C716.008240,775.346497 713.453430,773.212036 712.047058,776.666199 
	C711.191467,776.746277 710.390869,776.502319 709.300171,776.126099 
	C708.400818,774.506836 709.487732,773.984985 710.737793,773.187805 
	C710.995117,772.966003 711.001404,773.004333 710.983704,772.996094 
	C711.530151,772.483582 712.321106,772.229370 712.822327,771.232544 
	C713.680603,770.663086 714.363647,770.323792 715.319885,769.939209 
	C716.132568,769.926758 716.672058,769.959656 717.614380,769.996094 
	C718.814331,770.982666 719.012878,772.052490 718.306519,773.622375 
	C718.127869,774.017151 718.092407,774.510315 718.043579,774.752686 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M710.615845,772.972778 
	C710.428711,773.795837 709.862305,774.625671 709.152954,775.724609 
	C709.010010,775.993835 708.510803,776.003723 708.261475,775.991394 
	C707.732849,775.265686 707.453552,774.552246 707.215698,773.561646 
	C708.250244,773.182861 709.243347,773.081177 710.615845,772.972778 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M705.130676,780.948486 
	C705.087097,780.965637 705.174255,780.931274 705.130676,780.948486 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M725.707642,778.977539 
	C725.864258,779.296753 725.699219,779.558533 725.223267,779.858521 
	C725.009766,779.958252 725.016724,779.470581 725.025696,779.226685 
	C725.034668,778.982727 725.483337,778.981506 725.707642,778.977539 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M718.445496,773.854736 
	C718.089417,772.946045 718.050964,771.874939 718.014893,770.401733 
	C718.374207,769.950317 718.731140,769.901001 719.351318,769.826965 
	C723.391785,771.678650 726.276978,767.149109 730.144104,768.327759 
	C726.986938,771.730896 722.335693,771.578674 718.445496,773.854736 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M574.046143,215.499939 
	C573.941772,217.081009 573.735535,218.297241 573.260742,219.763123 
	C572.067505,221.860260 570.174744,216.377640 569.636719,220.795135 
	C570.216797,222.912292 571.799133,225.138382 569.481628,226.327057 
	C566.742493,227.731934 565.670227,224.816055 563.937256,223.014542 
	C562.408386,219.908142 559.959473,220.299316 557.213440,220.567551 
	C556.157654,220.475494 555.547424,220.164322 554.812500,219.395599 
	C555.749512,215.024948 557.754517,213.818207 561.661926,214.955582 
	C562.655090,215.244675 563.691711,215.698273 564.103943,214.377411 
	C564.449036,213.272064 563.631531,212.441132 562.681458,212.095566 
	C560.741638,211.389984 558.772827,210.310730 557.580566,213.439728 
	C556.767151,215.574661 554.923950,215.632401 552.827332,214.130737 
	C550.911560,211.857620 549.411987,214.693069 547.595581,213.796021 
	C546.950806,212.581024 546.932800,211.634277 547.560059,210.390350 
	C553.556824,210.046082 552.846497,205.942169 553.013245,201.993256 
	C555.845337,200.932083 555.626465,203.373871 556.758545,204.753250 
	C557.469482,205.315826 557.941650,205.622803 558.707703,205.956512 
	C559.783325,205.997421 560.562500,205.989487 561.575439,205.684601 
	C562.157776,205.784760 562.483032,205.804062 562.621216,205.560425 
	C562.236328,205.128891 562.127258,204.815247 562.105957,204.217010 
	C562.126770,203.956894 562.105042,203.474091 562.064697,203.235825 
	C561.276672,202.413040 561.033386,201.594681 560.915039,200.374054 
	C560.092407,198.504578 559.313293,196.959305 558.282837,195.199860 
	C555.904053,193.327118 555.567993,190.673203 554.243042,188.177002 
	C553.796753,186.783463 553.520752,185.687073 553.076538,184.287354 
	C556.629211,185.016388 558.483398,189.008072 562.642395,189.956604 
	C564.370544,192.797592 565.504883,195.740494 567.175781,198.338257 
	C570.516846,203.532623 572.472778,209.220978 574.046143,215.499939 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M556.992065,204.618088 
	C554.865906,205.177078 555.289734,202.644592 553.583984,201.914642 
	C553.240540,201.388809 553.188354,201.003311 553.103333,200.326080 
	C552.183533,199.362686 551.839600,198.339661 551.214233,197.121948 
	C551.051758,196.431808 551.041199,196.020157 551.021545,195.299591 
	C555.554138,196.420044 556.325378,200.288147 556.992065,204.618088 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M554.047852,188.279755 
	C556.621765,189.251160 557.041809,191.908005 557.983826,194.632019 
	C554.383484,194.353134 554.179810,191.531296 554.047852,188.279755 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M561.829468,204.077820 
	C562.407104,204.446198 562.687439,204.935501 562.990234,205.703888 
	C561.838867,206.436600 560.748901,207.466858 559.185791,206.248581 
	C559.845093,205.388443 560.688599,204.793594 561.829468,204.077820 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M560.646973,200.263947 
	C561.811584,200.526764 561.939148,201.407120 562.011841,202.659271 
	C560.443542,202.823364 559.669739,202.136002 560.646973,200.263947 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M550.063660,192.917908 
	C549.549194,192.584167 549.285950,192.082596 548.986206,191.291473 
	C549.892212,191.204025 551.191467,191.127548 550.063660,192.917908 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M542.376587,183.962402 
	C542.567627,184.252914 542.595947,184.665466 542.624268,185.078003 
	C541.940674,184.972687 542.189758,184.482071 542.376587,183.962402 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M750.742554,714.087585 
	C745.619690,713.270264 742.533325,717.019470 738.295654,717.254578 
	C733.962341,717.494995 734.160522,717.884705 732.932129,713.639099 
	C732.620300,712.561584 730.817505,711.915527 729.147217,710.792542 
	C728.520447,710.309265 728.446228,710.104614 728.338074,709.573853 
	C728.331970,708.364868 728.409241,707.487061 728.718689,706.308228 
	C730.672424,704.608887 732.299622,703.232544 733.330627,700.959595 
	C734.515503,698.347473 737.267456,698.544312 738.632507,700.976501 
	C739.742249,702.953796 743.452576,703.262634 744.867249,701.198364 
	C746.077515,699.432495 747.957520,698.813965 749.687378,697.329224 
	C750.113953,696.956116 750.290955,696.815369 750.757080,696.501343 
	C752.740723,695.768982 754.260803,694.976318 755.338013,693.022583 
	C755.744324,692.601624 755.926453,692.448547 756.420349,692.111511 
	C760.252014,690.871460 763.516907,688.800842 767.751221,689.380981 
	C770.422058,691.160217 770.846863,693.999268 771.720520,696.309326 
	C772.951599,699.564331 770.858643,702.247986 767.747742,703.197815 
	C764.294312,704.252136 760.930542,705.697021 757.987244,706.109619 
	C762.804260,705.181458 767.911621,702.924438 773.576599,701.279907 
	C774.185242,701.355469 774.425110,701.424683 774.964844,701.724915 
	C776.026245,703.743652 774.873291,704.858032 773.548218,706.264526 
	C766.288330,709.879272 759.031799,712.428589 750.742554,714.087585 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M744.752441,720.255493 
	C745.816650,717.444580 750.378540,718.436951 750.000122,714.381470 
	C749.996521,714.013306 750.001526,714.001709 750.007202,714.002075 
	C751.159546,713.725098 751.994629,714.612061 753.630249,713.741699 
	C757.709351,711.571228 762.412964,710.530640 766.880432,709.091309 
	C768.454163,708.584290 769.888000,707.892212 771.718750,707.147278 
	C773.646851,706.884949 775.141846,707.044312 776.767822,708.242737 
	C777.824158,709.462158 777.422485,710.425171 776.879944,711.712280 
	C775.856628,713.182373 774.770569,714.120789 773.703430,715.533447 
	C771.851501,718.941101 771.806519,723.443542 766.143494,722.927063 
	C765.162964,722.837585 762.118347,725.461853 762.391602,728.495789 
	C762.578308,730.568359 760.688843,731.466248 759.444031,730.696106 
	C754.694397,727.757874 749.303467,729.606689 744.397461,728.229858 
	C743.753296,728.049072 743.117554,727.871704 742.325378,727.243469 
	C742.078430,726.537964 742.053955,726.099915 742.008667,725.333374 
	C741.387573,722.704956 742.736145,721.487549 744.752441,720.255493 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M728.938110,706.586182 
	C730.326172,707.714539 729.734009,708.579895 729.097778,709.722534 
	C728.856140,710.167236 728.728210,710.332642 728.820496,710.721375 
	C729.040771,710.944702 729.037598,710.962158 729.051941,710.960815 
	C729.014771,712.432129 729.315735,714.029297 728.847473,715.354736 
	C727.265137,719.833313 728.566772,722.529114 733.435547,723.738403 
	C734.136353,723.989075 734.560425,724.207336 734.970093,724.963135 
	C735.431091,727.186279 732.899597,728.427673 733.957886,730.733276 
	C731.264465,731.901306 731.179199,730.030640 731.086914,727.916504 
	C731.209839,727.077820 731.761292,726.774536 731.567444,726.500610 
	C728.846375,727.917542 726.233887,728.966736 723.134583,729.367310 
	C722.513062,728.889160 722.271667,728.509094 722.035034,727.751587 
	C721.938477,726.592590 721.966614,725.782959 722.708801,725.397644 
	C721.572815,725.888367 721.527161,727.353210 720.501648,728.509766 
	C719.285156,729.590820 718.272400,730.839050 716.879333,729.762512 
	C715.711304,728.859985 715.669678,727.325500 716.195251,726.101257 
	C717.296753,723.535461 718.469482,720.984741 720.021973,718.639709 
	C720.650513,717.690308 722.801147,716.984009 721.018921,715.559448 
	C719.197754,714.103577 718.514221,716.108643 717.663330,717.215942 
	C715.383606,720.182556 713.022644,722.968811 708.332336,721.662170 
	C704.550171,717.628540 709.091125,714.905396 709.588379,711.313110 
	C709.918335,710.745789 710.109070,710.442078 710.271484,709.900513 
	C710.249084,709.666321 709.926453,709.288635 709.713379,709.125610 
	C709.342773,708.852417 709.185181,708.742310 708.786499,708.447876 
	C707.016357,706.741211 705.973389,705.102356 707.493713,702.634644 
	C708.298096,701.887024 708.951721,701.582397 710.050171,701.406860 
	C716.703857,702.355347 723.479492,701.477783 728.938110,706.586182 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M741.761414,727.040283 
	C746.657776,726.814026 751.189514,728.267944 755.772400,727.104553 
	C756.822083,726.838074 757.647888,727.473877 758.052185,728.569031 
	C758.561829,729.949463 759.821533,730.361084 760.857178,729.690186 
	C762.548889,728.594177 761.008606,727.884338 760.246460,727.089417 
	C759.143616,725.939087 760.524414,725.586487 761.048706,725.513550 
	C764.336853,725.056274 763.827698,722.646729 763.264832,719.667297 
	C770.194519,724.402039 770.694824,718.892456 772.662354,715.081177 
	C775.898010,716.983398 779.811340,716.185608 782.744507,718.864502 
	C782.731934,721.500793 781.392517,722.597107 779.134216,722.977783 
	C775.202087,723.640564 771.887939,724.680115 770.008423,729.149170 
	C768.558655,732.596558 764.890015,734.615173 760.436584,735.007935 
	C756.987854,735.023376 753.911560,735.012085 750.417603,735.000427 
	C748.898193,731.960938 746.489868,731.198914 743.533936,730.922974 
	C740.685425,730.657043 740.512329,730.091064 741.761414,727.040283 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M731.687744,726.065186 
	C732.282227,727.699646 731.155945,729.666199 733.586792,730.951599 
	C733.635193,731.849060 732.072632,732.597534 733.882446,733.753174 
	C732.409790,737.435120 728.962402,737.454529 726.066956,738.506287 
	C724.255920,739.164124 722.537354,740.076538 720.427002,740.901245 
	C718.534912,740.531982 718.125732,739.340454 718.007874,737.571777 
	C719.066162,734.357300 720.643677,731.836304 722.797974,729.294312 
	C725.602783,727.427856 728.113403,725.839722 731.687744,726.065186 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M744.648804,720.002808 
	C744.864563,721.956970 742.757385,722.672180 742.126953,724.702148 
	C739.325684,724.105774 740.201050,721.411255 740.493713,720.163086 
	C740.958313,718.181885 742.961243,720.458740 744.648804,720.002808 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M735.374756,725.175903 
	C734.627563,725.104980 734.216980,724.878662 733.711182,724.310425 
	C734.884216,723.083191 736.332703,722.055603 737.630371,723.425964 
	C738.037598,723.856018 736.574097,724.681396 735.374756,725.175903 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M749.868774,735.052124 
	C749.912537,735.034729 749.825073,735.069458 749.868774,735.052124 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M527.693909,194.145813 
	C528.738892,193.985138 529.860779,193.552155 530.052124,194.635559 
	C531.185181,201.050995 536.536133,202.863983 541.442261,205.037720 
	C543.076904,205.761978 544.307983,206.513885 544.854004,208.606781 
	C543.751648,211.343063 542.131042,209.533661 540.657715,209.441833 
	C538.086548,209.281570 535.209106,208.226349 533.375366,211.722641 
	C530.891418,212.641083 528.808899,211.047318 526.298340,211.184082 
	C526.046875,210.967499 526.039734,210.478455 526.050049,210.234131 
	C527.955383,207.534668 527.248718,205.219955 525.785645,202.859314 
	C524.710571,201.124664 523.093994,199.334564 525.676270,197.194946 
	C527.152527,196.515045 526.799194,195.165955 527.693909,194.145813 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M526.132690,211.250397 
	C526.835144,210.871628 527.937805,211.074524 528.359619,210.630508 
	C530.749634,208.114685 531.229187,210.963608 532.952637,212.155533 
	C536.136841,214.116852 534.201538,218.540894 538.123535,219.927368 
	C541.123230,226.341873 538.965942,229.972458 529.164429,231.054260 
	C528.638794,230.020966 528.424500,229.283478 528.096313,228.275238 
	C528.306885,225.527222 529.094727,223.083649 528.726257,220.257477 
	C528.934753,219.542847 528.942505,219.112061 528.922974,218.359634 
	C530.326904,214.913391 526.174011,214.087112 526.132690,211.250397 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M525.870056,196.803528 
	C524.355774,199.197296 525.903381,200.513443 526.945984,202.256393 
	C528.342224,204.590485 531.038879,207.392807 526.377686,209.844650 
	C521.648376,207.170212 524.167419,201.752411 521.644104,198.195602 
	C520.721252,196.894775 521.913940,196.088379 522.760376,195.324677 
	C524.541199,193.717896 524.867859,195.819534 525.870056,196.803528 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M524.507812,189.985214 
	C525.500793,190.458771 526.175171,190.913101 526.978943,191.650421 
	C525.841553,191.802185 524.278870,192.089813 523.372253,191.449066 
	C521.693054,190.262238 523.393677,190.209671 524.507812,189.985214 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M352.705261,200.932907 
	C353.178925,199.220795 354.277039,200.215637 356.593475,200.766525 
	C354.543427,200.882965 353.774536,200.926636 352.705261,200.932907 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M610.739197,185.129791 
	C610.977600,185.012039 611.461914,185.097702 612.000977,185.584030 
	C611.698853,185.767517 611.341980,185.550354 610.739197,185.129791 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M820.743164,415.135071 
	C820.928711,415.360046 820.891235,415.711121 820.601990,416.139801 
	C820.406921,415.898651 820.463562,415.579926 820.743164,415.135071 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M673.383789,849.138794 
	C672.940186,848.573608 672.887207,848.107117 672.798584,847.290283 
	C673.634460,845.772583 674.909607,845.039062 675.736206,846.541077 
	C676.593140,848.098511 675.623047,849.193054 673.383789,849.138794 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M662.340942,848.878784 
	C661.501038,848.301147 660.983643,847.790466 660.466248,847.279785 
	C661.824280,846.933105 663.170776,846.623596 663.933472,848.681885 
	C663.537537,848.999268 663.100586,848.972534 662.340942,848.878784 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M681.012451,851.993713 
	C680.911621,851.850342 680.799072,851.714844 680.686462,851.579346 
	C680.758240,851.636719 680.830017,851.694092 680.942627,851.887817 
	C680.983459,852.024170 681.000671,852.001526 681.012451,851.993713 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M711.450928,826.521118 
	C711.420715,826.470459 711.481201,826.571716 711.450928,826.521118 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M845.384155,488.571045 
	C843.681763,487.024750 843.026123,485.286407 843.422607,483.224670 
	C844.537109,483.799072 845.674988,484.385559 847.124756,485.010468 
	C847.737061,485.146088 848.037537,485.243256 848.044250,485.712158 
	C847.045349,486.923737 847.009888,488.313446 845.384155,488.571045 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M846.912720,467.194275 
	C848.074829,467.737488 849.073181,468.263245 848.346252,469.399475 
	C847.830811,470.205170 846.849243,469.993378 846.227539,469.461731 
	C845.222595,468.602448 844.735596,467.642090 846.912720,467.194275 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M842.459900,471.446014 
	C842.522217,471.406616 842.397583,471.485413 842.459900,471.446014 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M844.990845,474.000061 
	C844.650635,473.925415 844.441223,473.722321 844.231812,473.519196 
	C844.497620,473.447327 844.763489,473.375488 845.201721,473.454773 
	C845.374146,473.605927 845.121582,473.871582 844.990845,474.000061 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M843.448486,469.421997 
	C843.522156,469.371826 843.546936,469.570526 843.546936,469.570526 
	C843.546936,469.570526 843.374756,469.472137 843.448486,469.421997 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M848.004272,485.967163 
	C847.659119,485.923248 847.567688,485.762695 847.456421,485.325531 
	C847.696411,485.063141 847.956238,485.077423 848.605225,485.063538 
	C848.748901,485.307129 848.503418,485.578796 848.004272,485.967163 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M792.334534,712.670532 
	C790.754517,714.361755 789.669006,716.265686 786.910278,715.895325 
	C784.410706,715.672913 782.798767,714.867126 783.051392,711.876282 
	C783.529541,710.098389 784.320312,708.986450 785.747620,707.857422 
	C786.882141,707.436584 787.666443,707.227112 788.648193,708.000061 
	C788.937500,708.706726 789.165894,708.904297 789.770752,708.952209 
	C791.736450,709.419067 792.633667,710.492371 792.334534,712.670532 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M800.361877,694.522339 
	C799.253784,697.039917 795.062683,696.751099 795.970703,700.709839 
	C795.381104,701.619263 794.660461,701.741455 793.543396,701.294067 
	C790.788025,701.725037 788.349365,702.378479 785.335022,702.815308 
	C785.162170,699.880493 787.844604,699.576904 789.734131,697.949524 
	C790.183167,697.622559 790.367004,697.498535 790.834595,697.204041 
	C792.285828,696.443604 793.400208,695.776001 794.574463,694.608521 
	C796.124207,693.469604 797.355164,692.400757 799.409607,692.566589 
	C799.729004,692.694519 799.850952,692.831238 799.912598,692.899048 
	C800.314087,693.306335 800.483704,693.716248 800.361877,694.522339 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M786.331665,707.463013 
	C785.743713,708.608154 785.424744,709.616089 784.843750,710.811523 
	C781.811890,712.161926 779.759460,716.635193 775.966309,712.486084 
	C775.811523,711.007385 775.820435,710.022644 777.539551,709.543457 
	C778.254272,707.357178 779.737976,706.533142 781.846313,705.919067 
	C782.989014,705.830505 783.719666,705.975281 784.716675,706.551331 
	C785.226562,706.785522 785.418335,706.818420 785.901611,706.865601 
	C786.328979,707.028564 786.464844,707.177124 786.331665,707.463013 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M792.414795,713.004395 
	C792.059204,711.593750 791.144836,710.595398 790.178406,709.248657 
	C790.847290,707.652893 792.469299,708.229492 793.562073,707.458496 
	C797.329224,704.800720 796.161072,707.872437 796.076233,710.000000 
	C796.353577,711.233582 796.038879,711.952942 795.169189,712.782349 
	C794.936523,712.988831 795.002075,713.008240 795.012024,712.975525 
	C794.682800,713.294922 794.265259,713.451782 793.468628,713.281128 
	C792.993652,713.078552 792.830566,713.035950 792.414795,713.004395 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M804.620239,705.140869 
	C805.465088,707.896545 803.056641,709.056152 801.367188,710.963623 
	C800.638611,711.349976 800.209717,711.337097 799.402954,710.576050 
	C799.019226,709.589539 799.014587,709.152710 799.088501,708.390503 
	C799.442810,707.433899 800.014771,707.376099 800.851196,707.202271 
	C802.179871,706.457581 803.249634,705.839478 804.620239,705.140869 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M795.350220,712.980225 
	C795.246338,712.287537 795.556152,711.586243 795.968994,710.610352 
	C796.806396,710.225220 797.540833,710.114624 798.649536,710.015259 
	C799.023865,710.026428 799.010925,709.992371 798.993286,709.992126 
	C799.530518,710.689209 799.744995,711.466492 799.338867,712.719360 
	C799.149658,713.093750 799.100830,713.086426 799.083496,713.104370 
	C797.965454,713.072144 796.864624,713.021851 795.350220,712.980225 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M786.406616,707.708374 
	C786.062744,707.600281 786.189758,707.251465 786.191467,707.065674 
	C787.333252,706.228455 787.450439,703.719421 789.267944,704.643372 
	C790.409729,705.223755 788.945984,706.599731 788.901245,708.002747 
	C788.147522,708.172729 787.448975,707.994629 786.406616,707.708374 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M793.275940,701.387268 
	C793.917786,701.053406 794.608948,701.035339 795.646973,701.022095 
	C796.427795,701.666504 798.073120,702.026978 797.576599,702.435181 
	C796.154358,703.604553 794.625854,702.734863 793.275940,701.387268 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M785.022827,706.389893 
	C784.098633,706.788208 783.162537,706.823853 781.760010,706.908691 
	C781.151001,706.496155 781.117493,706.026428 781.305420,705.207886 
	C782.044678,703.774536 782.911926,702.999573 784.535645,702.817871 
	C784.888184,702.883423 785.021606,703.014832 785.091187,703.079590 
	C785.110779,704.105286 785.060791,705.066223 785.022827,706.389893 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M800.592957,694.902222 
	C800.177307,694.431030 800.113525,694.014160 800.011963,693.282104 
	C800.877991,693.218445 801.781799,693.470032 803.022217,693.853149 
	C802.858154,695.063660 801.962708,695.162170 800.592957,694.902222 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M800.861938,706.968262 
	C800.649658,707.377930 800.189148,707.680237 799.447876,708.023865 
	C799.004517,707.721558 798.611084,707.247009 798.718933,707.057007 
	C799.205933,706.199524 799.931519,706.501892 800.861938,706.968262 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M808.527588,696.543091 
	C808.478760,696.570740 808.576355,696.515442 808.527588,696.543091 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M841.993652,502.624237 
	C840.735901,506.051300 839.383728,509.115295 837.968323,512.534851 
	C834.631287,509.036896 836.400818,504.499420 836.298279,500.247437 
	C836.260742,498.691650 837.032471,497.116333 837.716064,495.285339 
	C841.160034,496.430573 841.263428,499.489380 841.993652,502.624237 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M789.248047,789.794678 
	C789.803101,789.798828 790.312195,790.095886 790.305542,790.380920 
	C790.287781,791.137878 789.587463,791.173645 788.726196,790.974854 
	C788.623718,790.493896 788.795349,790.287964 789.248047,789.794678 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M404.694031,181.049469 
	C405.795319,185.072464 401.758057,186.057709 400.155792,188.595566 
	C397.505219,192.793839 394.487579,196.760315 391.323700,200.998322 
	C392.593414,192.567886 398.334656,186.725510 404.694031,181.049469 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M387.909607,203.706848 
	C386.007874,204.365417 385.745361,203.282959 385.906067,201.384506 
	C386.080505,200.820190 386.282471,200.637375 386.484467,200.454544 
	C386.671661,200.789764 386.858856,201.124985 387.070496,201.740417 
	C387.311432,202.279709 387.512817,202.550049 387.801147,203.063171 
	C387.903137,203.294739 387.883728,203.570877 387.909607,203.706848 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M405.755554,179.183777 
	C405.873291,179.605545 405.722473,180.167999 405.304596,180.879242 
	C405.187347,180.460159 405.337158,179.892319 405.755554,179.183777 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M387.849487,203.036774 
	C387.618561,203.059296 387.334015,202.823837 387.072205,202.304504 
	C387.328583,202.273361 387.562225,202.526077 387.849487,203.036774 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M406.777924,178.095230 
	C406.870758,178.334091 406.716400,178.642365 406.291931,178.988663 
	C406.191467,178.739334 406.361115,178.451981 406.777924,178.095230 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M528.494873,230.887512 
	C531.603149,230.448349 533.826233,228.694702 536.333130,227.748795 
	C539.491760,226.556946 537.923828,223.124344 537.325439,220.259003 
	C536.286011,217.288788 535.116089,214.797165 533.226013,212.287018 
	C532.974487,212.045471 532.981201,212.025726 532.966675,212.025375 
	C532.846863,207.157532 536.548767,207.772171 539.472412,207.769760 
	C541.107117,207.768402 542.742615,208.681763 544.654724,209.067444 
	C545.337646,208.937057 545.743652,208.922195 546.448608,208.830154 
	C546.945557,209.083527 547.143616,209.414062 547.569153,209.922394 
	C547.872437,211.085068 547.948242,212.069946 548.022217,213.427383 
	C549.227722,215.043030 550.527222,216.550034 548.892944,218.044693 
	C547.259460,219.538559 545.884583,218.364059 544.913025,216.732300 
	C544.340698,215.771164 543.683594,214.219498 542.355835,215.295105 
	C540.799622,216.555786 542.391907,217.559372 543.206909,218.522415 
	C546.025574,221.853073 547.812866,225.367264 544.925232,229.935028 
	C544.245972,230.751602 543.681519,231.161148 542.729858,231.611450 
	C541.775024,232.098053 540.734009,231.737274 540.498596,232.671326 
	C540.447327,232.874466 541.328918,232.940201 542.143555,233.075027 
	C546.146240,235.553375 546.104980,235.967041 541.620728,238.273102 
	C538.298035,236.899124 535.488892,237.918915 532.348999,238.969910 
	C530.674438,239.588196 529.327454,240.093719 527.516724,239.665985 
	C526.240845,239.114960 526.146790,238.001785 525.425293,237.334961 
	C525.002014,238.064514 525.657227,238.793823 525.201111,239.826477 
	C518.570374,243.160645 511.966919,245.618698 504.384827,245.351120 
	C504.347046,244.766022 504.627899,244.447845 505.038788,243.837769 
	C505.162598,242.713638 505.522247,242.124435 506.666931,241.949188 
	C511.272064,239.342285 516.127625,237.596756 519.826721,233.937164 
	C522.066895,231.720978 525.460876,232.280640 528.494873,230.887512 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M551.046875,197.215881 
	C552.048584,197.426407 552.766174,198.212845 553.026611,199.706589 
	C551.559204,199.912216 550.955750,199.033310 551.046875,197.215881 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M782.062500,704.680237 
	C781.856812,705.438599 781.706055,705.875549 781.424377,706.635193 
	C779.780334,707.109985 780.244385,709.607666 778.146240,709.371460 
	C776.194092,708.908875 774.510620,708.459229 772.410034,707.994141 
	C771.593933,707.255676 771.987427,706.787720 772.750122,706.141724 
	C772.905273,704.419067 774.574768,703.954834 774.950684,702.375244 
	C774.822754,701.935425 774.643555,701.813965 774.188110,701.863281 
	C767.337097,704.086426 760.762268,706.138733 754.187439,708.191101 
	C756.101379,704.787109 759.535767,704.952026 762.409485,704.091492 
	C771.449829,701.384399 772.968445,699.218994 768.024902,690.330627 
	C768.741577,686.112244 768.972046,681.853210 774.570129,681.743713 
	C776.142334,681.712952 776.380859,679.988159 777.234680,679.037537 
	C779.663208,676.333984 781.906189,676.546143 783.002808,679.997986 
	C783.900635,682.823792 785.004578,682.642212 786.723145,681.097900 
	C789.696167,678.426331 793.228394,676.553101 796.414307,674.182129 
	C798.906067,672.327759 801.632629,670.572205 805.393066,671.259888 
	C806.108337,671.649963 806.411133,671.988464 806.684692,672.748413 
	C806.752930,673.473267 806.539368,674.034363 806.850952,674.218994 
	C807.410706,674.550659 806.737854,674.399963 807.334839,673.735962 
	C807.864258,673.795471 808.061401,673.907043 808.390869,674.334961 
	C805.960754,679.003540 801.298279,680.483704 796.317871,683.335876 
	C802.594971,680.772217 806.907410,677.110535 811.469971,673.860352 
	C813.158936,672.657166 814.637817,671.184021 816.283447,673.913330 
	C815.154358,676.292908 814.173584,678.357666 813.198853,680.769775 
	C812.149536,682.829041 811.094238,684.541077 810.005920,686.592896 
	C808.772095,687.070068 807.571289,687.207336 806.183105,687.665405 
	C805.438477,688.700623 805.006042,689.553101 803.666687,689.798584 
	C802.223145,690.602905 801.122925,691.391113 799.875854,692.436890 
	C798.449829,693.637207 797.170715,694.579956 795.583984,695.682617 
	C794.109741,695.714111 793.802734,693.850952 792.439880,694.298523 
	C790.584839,694.907593 792.422546,696.704224 791.366211,697.974487 
	C791.234070,698.280945 791.005615,698.157166 791.127869,698.201050 
	C787.058472,698.843201 782.841797,699.301086 778.446411,700.479919 
	C777.855652,700.748657 777.432190,700.493713 777.163147,700.743774 
	C777.104858,700.797913 777.242676,700.939819 777.368713,700.945984 
	C778.034607,700.978394 777.102722,700.985779 777.937073,700.544922 
	C783.629272,700.036011 783.701050,700.107300 782.062500,704.680237 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M806.903198,672.157837 
	C799.054993,671.378906 795.085632,677.795959 789.368958,680.655640 
	C788.220154,681.230286 787.359436,682.355591 786.296753,683.132507 
	C783.765747,684.982910 781.736877,685.682007 781.972656,681.186951 
	C782.037781,679.946167 782.286255,678.173645 780.288696,678.265381 
	C778.709045,678.337830 777.452148,679.320190 777.642517,681.175659 
	C777.817017,682.875793 777.012634,684.151550 775.513367,683.559387 
	C771.134766,681.829712 770.396729,684.371887 769.894653,687.694580 
	C769.786255,688.411499 769.035217,689.031311 768.286194,689.852783 
	C764.031921,690.138428 760.665466,692.484314 756.421570,693.015747 
	C754.702637,690.603638 756.699707,689.708496 758.181580,688.928101 
	C760.189026,687.871094 762.343994,687.005981 765.279358,686.821106 
	C762.166626,685.698547 759.395996,684.663025 760.288879,680.630127 
	C761.082947,679.004883 763.150269,680.203796 763.813477,678.273315 
	C766.332581,677.660095 768.357666,676.542053 770.174255,675.200378 
	C771.940430,673.895874 773.194397,674.029602 774.915100,675.352905 
	C778.817810,678.354248 779.508423,675.822205 779.240601,672.208374 
	C778.862305,671.792969 778.738647,671.592346 778.729004,671.056885 
	C780.674194,668.790771 783.243408,668.210571 785.854614,667.026733 
	C786.197266,666.973511 786.598816,666.980652 786.799438,666.990356 
	C787.567932,666.491760 788.135864,665.983521 788.856201,665.228271 
	C791.903503,663.431946 794.340088,661.189453 797.523193,659.435974 
	C798.320862,659.167175 798.793579,659.074158 799.634033,658.980469 
	C801.564514,659.119629 802.739258,658.074341 804.104675,657.617249 
	C806.087524,656.953430 807.958374,656.708679 809.426758,658.947754 
	C809.722412,661.840454 812.650696,660.881714 814.348999,662.064331 
	C815.507202,662.214539 816.019165,662.663696 816.030457,663.732910 
	C816.234314,667.067261 816.657593,670.107971 817.133667,673.577759 
	C817.006470,674.020935 816.826416,674.034973 816.390808,674.138550 
	C815.632385,673.499817 814.557800,672.686340 814.137451,673.322449 
	C811.637146,677.106506 806.330811,676.994507 803.960632,680.886353 
	C802.358704,683.516602 798.230408,682.458496 796.841553,685.665466 
	C796.590576,686.245056 795.229309,685.684082 794.732666,684.979004 
	C793.798401,683.652649 794.660400,682.784058 795.743591,682.170471 
	C799.068787,680.287109 802.429260,678.466125 805.768738,676.607727 
	C806.628418,676.129333 807.610046,675.762817 807.943909,674.303345 
	C808.085022,673.122742 809.174561,672.534912 808.961609,671.890015 
	C808.802673,671.408691 807.863098,672.126892 806.903198,672.157837 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M535.084961,195.218048 
	C535.736572,196.473816 536.306702,197.674515 537.217346,199.592178 
	C534.741516,198.572647 534.728271,197.326767 535.153931,195.560242 
	C535.190308,195.224411 535.003479,195.162979 535.084961,195.218048 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M538.375732,194.639526 
	C539.267395,195.032364 540.399597,195.644028 539.583557,196.608765 
	C538.815613,197.516724 538.367065,196.408859 537.846741,195.537842 
	C537.888123,195.094788 538.023376,194.960327 538.375732,194.639526 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M480.218384,174.209335 
	C479.750763,173.953751 479.560333,173.907272 479.103821,173.734741 
	C479.323120,173.076157 479.808533,172.543610 480.838623,172.098877 
	C481.413025,172.416168 481.442749,172.645691 481.314209,173.539154 
	C480.935822,174.274857 480.715729,174.346634 480.218384,174.209335 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M474.771484,172.678772 
	C475.147858,171.976440 475.823608,171.775711 476.301544,172.277191 
	C476.783844,172.783234 476.122589,172.955734 475.472290,173.175888 
	C475.195160,173.217529 474.896179,172.870773 474.771484,172.678772 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M480.868713,171.862549 
	C481.023315,171.369415 481.293518,171.117081 481.817322,171.706818 
	C481.874298,171.905899 481.903198,171.947296 481.925293,171.936401 
	C481.822296,172.116058 481.697266,172.306610 481.312561,172.274841 
	C481.052826,172.052536 480.929993,171.925949 480.868713,171.862549 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M657.443359,178.567230 
	C657.414246,178.515411 657.472412,178.619034 657.443359,178.567230 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M775.667480,712.052490 
	C776.539001,713.981628 777.574524,713.518188 778.895752,712.882019 
	C780.461426,712.128174 782.161560,711.653564 784.192627,711.028442 
	C784.459839,712.668091 783.711975,714.574585 786.385132,715.595825 
	C785.455933,716.581665 783.322449,716.124084 783.896851,718.796143 
	C783.823486,719.099548 783.647339,719.102783 783.208984,719.139526 
	C779.898254,717.323181 775.611267,719.090698 773.040894,715.395325 
	C774.249695,714.358154 773.301147,712.039917 775.667480,712.052490 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M481.947021,182.249878 
	C481.428864,181.641083 481.215149,181.124100 480.972961,180.318756 
	C481.013733,179.185516 480.992615,178.330536 481.368500,177.212814 
	C481.784515,176.579071 482.121277,176.316696 482.844360,176.116959 
	C483.595734,175.790634 484.023041,175.481430 484.703552,175.096771 
	C487.411163,177.132446 489.847107,174.209015 492.353882,175.006668 
	C494.033539,175.541153 495.832794,175.699631 497.908539,176.196091 
	C498.438171,180.030289 496.947113,183.247757 495.265991,186.760590 
	C494.674408,189.825150 493.444885,192.652084 496.401459,196.028229 
	C491.582275,194.479294 493.700989,190.062607 491.068573,188.447662 
	C489.543091,189.065109 489.130219,190.634705 488.018890,192.009125 
	C486.232849,189.815567 484.578491,187.290253 482.817139,184.427521 
	C482.863007,183.427078 482.705414,182.845505 481.947021,182.249878 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M847.926270,413.916351 
	C847.820557,414.256531 847.600342,414.469421 847.380127,414.682281 
	C847.333069,414.432953 847.286011,414.183655 847.409058,413.736084 
	C847.579102,413.537842 847.811768,413.789032 847.926270,413.916351 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M724.886108,811.010681 
	C721.355530,811.553406 717.822693,814.299805 713.906250,811.474854 
	C716.558228,810.723511 719.356201,810.324463 722.572876,809.879150 
	C723.584045,810.119629 724.176575,810.406311 724.886108,811.010681 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M793.306763,713.304688 
	C793.569519,713.017761 793.982239,712.914368 794.708496,712.876892 
	C794.654053,713.566101 794.192627,713.921021 793.306763,713.304688 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M465.737549,158.963623 
	C465.586487,159.071884 465.437897,159.021118 465.289307,158.970367 
	C465.439545,158.915115 465.589752,158.859863 465.737549,158.963623 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M799.447388,713.041931 
	C799.083557,712.319214 799.017395,711.544678 798.963501,710.380981 
	C799.482971,710.306458 799.990234,710.621033 800.783203,711.034729 
	C800.912231,711.955505 800.615417,712.677368 799.447388,713.041931 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M799.519409,718.318481 
	C799.394287,718.518066 799.385864,718.459167 799.366943,718.479370 
	C799.445129,718.371338 798.787354,717.655151 799.519409,718.318481 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M459.018402,166.034729 
	C457.814850,165.561111 457.806946,164.663467 458.326599,163.674713 
	C458.443451,163.452301 458.971924,163.187744 459.098633,163.269913 
	C460.109528,163.925293 458.781769,164.680084 459.105164,165.716049 
	C459.072510,166.036377 459.007172,165.999084 459.018402,166.034729 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M463.644043,162.252304 
	C463.743408,162.620529 463.621124,163.159882 463.270599,163.867889 
	C463.169037,163.498856 463.295746,162.961151 463.644043,162.252304 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M821.873169,681.727295 
	C821.895935,682.272522 821.789429,682.544250 821.841064,682.907959 
	C819.675903,684.239563 817.380798,685.339783 816.025757,681.402649 
	C817.462097,681.053223 818.376038,680.049805 819.705994,679.146240 
	C820.579834,679.819580 821.161804,680.636658 821.873169,681.727295 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M813.527466,685.541870 
	C813.478699,685.569275 813.576294,685.514526 813.527466,685.541870 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M682.001587,191.998901 
	C681.718872,191.463028 681.424744,190.932632 681.177063,190.381393 
	C681.163818,190.352020 681.540649,190.147339 681.735901,190.024261 
	C681.824646,190.490631 681.913391,190.957001 681.999023,191.713623 
	C681.995972,192.003860 682.000000,192.000000 682.001587,191.998901 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M483.004028,175.832443 
	C482.970154,176.425171 482.771851,176.750488 482.369202,177.277191 
	C480.379150,177.314270 480.529877,176.006378 480.782532,174.365234 
	C481.001282,173.962952 481.006439,174.008087 480.984009,174.004593 
	C481.120209,173.832901 481.278900,173.664719 481.724670,173.691208 
	C482.287689,174.445602 482.563660,175.005325 483.004028,175.832443 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M482.006378,173.559692 
	C481.838470,173.921143 481.665192,173.956390 481.226746,173.996368 
	C480.965363,173.568619 480.969208,173.136124 481.012939,172.378082 
	C481.052826,172.052536 481.501221,171.995392 481.724304,171.960449 
	C481.965271,172.361511 481.983154,172.797501 482.006378,173.559692 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M800.570312,402.071106 
	C801.175659,401.883881 801.438904,401.911774 801.816467,402.247742 
	C801.912537,404.383362 801.228638,405.471588 798.771118,404.246704 
	C798.502380,402.816498 799.644043,402.734833 800.570312,402.071106 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M810.044312,658.843201 
	C807.364014,657.798279 805.114075,657.994202 802.947998,659.569824 
	C802.167725,660.137451 801.200378,661.465332 800.208252,659.275269 
	C801.137512,657.192444 801.986450,655.426208 800.661865,653.105896 
	C801.041382,651.456604 802.076355,650.533325 802.622864,649.329834 
	C803.598206,647.182007 804.355957,644.970581 807.605164,646.824219 
	C808.846619,647.532410 810.210327,645.679504 811.968872,645.285767 
	C813.408569,644.526611 814.640076,644.095947 816.021179,643.254089 
	C817.656006,642.090820 818.528992,640.513550 820.461670,639.860046 
	C821.702026,640.202393 822.625854,640.503357 823.887329,640.950806 
	C822.753601,643.318481 821.282288,645.539734 819.522339,647.921997 
	C818.836975,648.243103 818.512695,648.496033 818.213989,649.153198 
	C817.658264,650.265381 817.149292,651.066040 816.467896,652.099182 
	C815.026489,653.838074 813.757568,655.344482 812.629150,657.165405 
	C812.911987,657.721436 813.054321,657.962891 812.833130,657.993164 
	C811.157532,656.932434 811.181213,658.796570 810.044312,658.843201 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M820.896790,639.596741 
	C819.736267,641.109497 818.694336,642.317566 817.393799,643.689392 
	C815.928833,642.552795 816.626221,641.504211 817.884644,640.247681 
	C818.943481,639.369812 819.697815,638.734009 820.749939,638.067810 
	C821.036926,638.455688 821.026123,638.873840 820.896790,639.596741 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M816.171997,664.051819 
	C815.495300,663.571533 815.179626,663.116577 814.782837,662.384033 
	C817.423645,660.527832 818.987183,661.689514 819.968506,664.661499 
	C818.848389,664.696350 817.690735,664.386780 816.171997,664.051819 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M812.690918,657.551880 
	C812.957703,657.669922 813.145752,657.859741 813.333862,658.049622 
	C813.143372,658.063354 812.952942,658.077148 812.616028,657.936462 
	C812.469604,657.782043 812.612183,657.623657 812.690918,657.551880 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M815.947632,656.870361 
	C815.885193,656.867065 815.770447,656.734131 815.655701,656.601196 
	C815.735535,656.647705 815.815430,656.694214 815.947632,656.870361 
z"
    />
    <path
      fill="#F4F3F3"
      opacity="1.000000"
      stroke="none"
      d="
M803.648865,689.903809 
	C803.995544,689.248596 804.667786,688.682617 805.667969,688.051453 
	C805.913940,689.251404 806.295654,690.983582 803.648865,689.903809 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M818.422913,649.303711 
	C818.338623,649.098450 818.509888,648.732178 818.957458,648.224426 
	C819.048645,648.436218 818.863586,648.789429 818.422913,649.303711 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M276.853210,313.272430 
	C281.906219,311.859314 285.813995,315.054932 290.590942,315.982208 
	C291.818817,316.163574 292.261993,316.769806 292.539795,317.779938 
	C292.557037,318.000641 292.445984,318.374084 292.445984,318.374084 
	C292.445984,318.374084 292.837585,318.384491 292.844238,318.167511 
	C293.065887,316.692749 291.671051,315.961426 291.895905,314.402649 
	C292.183716,313.795837 292.374054,313.518646 292.787750,313.118042 
	C294.817017,309.676666 297.957581,308.133057 301.592773,306.892822 
	C305.544922,306.089355 304.442474,302.827881 305.102905,300.220154 
	C306.847687,298.898682 308.523560,300.146057 308.150787,301.446320 
	C307.012238,305.417877 309.278442,309.031006 308.502289,313.098724 
	C302.027435,315.440277 308.832886,318.080811 307.409882,320.646759 
	C312.676483,318.188110 313.578796,318.458618 315.779053,323.071655 
	C315.761139,323.895752 315.652405,324.366455 315.522705,324.836731 
	C315.345551,325.479065 315.194000,324.687164 315.401703,325.271942 
	C315.577789,325.767609 315.589203,325.018524 315.985229,324.783752 
	C316.902466,323.743835 317.620392,322.940063 318.513611,321.854340 
	C318.932495,321.504608 319.116058,321.385437 319.596313,321.129883 
	C321.938934,320.803040 322.710724,319.318939 323.508240,317.362976 
	C324.329773,316.563721 325.021454,316.423584 326.111206,316.851685 
	C326.911224,317.754333 327.137421,318.518890 327.254089,319.735352 
	C326.475739,324.617584 326.548401,329.229279 324.991943,333.950104 
	C322.477661,334.084900 322.510681,335.898987 321.713867,337.440399 
	C319.943726,340.864471 319.106079,344.571167 317.776825,348.117432 
	C317.287262,349.423523 316.458405,351.627289 318.032440,352.165527 
	C322.185699,353.585754 321.528870,355.558258 319.674316,358.447266 
	C318.248535,360.668274 320.402466,362.875763 320.260437,365.510956 
	C314.120880,368.176422 311.858307,366.915985 311.544678,361.048187 
	C311.198639,354.573120 310.903564,348.095459 310.619568,341.252289 
	C310.763641,338.582153 312.171844,336.621765 312.686615,334.270874 
	C310.738586,334.288879 310.126129,335.995850 308.459717,336.579498 
	C307.461517,336.741577 306.779449,336.702301 306.289459,336.207916 
	C304.671082,334.574982 307.970215,331.991241 306.051056,331.343536 
	C304.333405,330.763855 302.650543,330.700836 301.490295,328.666534 
	C300.362122,326.226227 299.370422,326.157074 298.240234,328.629425 
	C298.043884,329.104584 297.958282,329.290497 297.712219,329.735352 
	C297.296295,330.292572 297.017731,330.566986 296.489319,330.990173 
	C296.131134,331.300568 295.991791,331.430145 295.687073,331.785034 
	C295.473999,332.184509 295.426331,332.358643 295.455444,332.792511 
	C296.791840,333.873474 298.285522,334.175446 299.894104,335.044556 
	C301.195343,337.989410 303.476074,337.227600 305.841980,337.176208 
	C306.560333,337.369385 306.952789,337.519653 307.649048,337.597290 
	C308.179321,337.484344 308.401001,337.456390 308.964081,337.489624 
	C310.728088,338.262329 310.381470,339.597137 310.457855,341.177856 
	C309.797241,346.926697 309.740753,352.339111 308.896393,358.050903 
	C307.564270,359.877502 306.822388,358.988281 306.198273,357.894867 
	C305.554626,356.767273 305.311096,355.431763 304.200745,354.157532 
	C303.461639,353.486938 303.414032,352.722076 302.809204,352.388123 
	C302.994537,352.834320 303.011444,353.313385 303.077393,354.133698 
	C302.127289,358.029419 299.440186,360.342682 297.056976,362.838654 
	C295.769379,364.187195 294.197327,365.296906 292.234741,363.963104 
	C290.148102,362.544983 289.710266,360.554657 290.615936,358.285828 
	C291.459686,356.172150 293.119049,354.285400 292.241455,351.384216 
	C292.819702,347.946594 291.537384,344.736938 293.035797,341.420776 
	C293.457428,340.163239 295.658478,340.077301 294.238525,338.237305 
	C293.878967,337.723755 293.746399,337.397369 293.617676,337.307312 
	C293.509186,337.741699 293.457733,337.915894 293.273224,338.333008 
	C292.905273,338.875214 292.635742,339.139740 292.088135,339.533600 
	C291.654968,339.772980 291.472290,339.855499 291.001465,340.036377 
	C289.902649,340.353546 289.084595,340.502686 287.933777,340.281769 
	C286.872711,339.678741 286.262207,339.041504 285.584564,338.012787 
	C284.971466,335.337921 286.694916,333.947601 287.747559,332.202881 
	C288.348480,331.206818 290.560669,330.773590 288.897430,328.823486 
	C281.993256,325.227081 277.368622,328.563782 272.963654,333.092621 
	C270.454498,335.672333 268.207794,338.577545 264.624939,340.495361 
	C263.803406,340.746277 263.312897,340.782623 262.447632,340.664246 
	C261.611267,340.354187 261.212799,340.045135 260.699158,339.312378 
	C260.198792,337.480194 260.815979,336.529724 262.606934,336.018188 
	C269.553192,330.360046 275.314026,323.804840 283.286865,319.297302 
	C280.912964,317.710510 279.048035,319.917786 277.228638,318.256958 
	C276.668732,316.532379 276.929108,315.025330 276.853210,313.272430 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M262.019653,339.854614 
	C262.591370,339.914124 262.992889,339.926483 263.694824,339.962708 
	C264.996918,343.794128 261.943726,343.256042 260.141357,343.142914 
	C252.087814,342.637604 248.569580,348.115753 245.005753,354.206207 
	C243.542770,355.431427 241.284470,355.290405 241.217422,357.955048 
	C240.688599,360.670959 238.459122,360.389954 236.620514,361.311066 
	C234.575180,362.765045 233.247940,364.226593 233.906769,366.997192 
	C233.699707,369.301514 232.662949,370.542755 230.390457,371.040039 
	C228.666794,368.028564 227.013367,366.190582 223.480392,368.575104 
	C222.543365,369.207550 220.947525,368.863861 219.237503,368.948334 
	C218.972870,368.360687 219.124939,367.793060 219.443375,366.815857 
	C219.463943,366.491608 219.318130,366.576965 218.972717,366.522095 
	C218.176910,365.043060 217.117111,366.519928 216.058228,365.925659 
	C215.067078,365.654846 214.480103,365.310089 213.672394,364.658417 
	C212.484085,363.373260 211.524567,362.338226 211.349014,360.513550 
	C211.551590,358.436707 213.327942,357.729187 213.961960,356.249207 
	C213.038696,355.111328 211.502563,355.658752 210.211639,354.690918 
	C209.412552,353.988220 209.151489,353.366730 209.140915,352.296753 
	C208.963806,348.219116 210.896713,344.274872 208.099457,340.427002 
	C209.414673,339.622070 210.252808,340.827515 211.901764,340.885559 
	C210.065994,338.306854 211.298096,336.758392 213.746704,335.361511 
	C215.492386,334.812531 216.920166,334.850891 218.580536,335.794250 
	C219.919830,335.119415 220.805832,334.210724 222.510483,334.535400 
	C224.191345,336.918213 222.164902,339.114105 222.685638,341.697937 
	C222.227036,343.752136 219.910736,344.404541 220.162399,346.901947 
	C222.333160,345.742157 224.497818,344.967926 226.804077,343.657440 
	C228.526306,343.029358 229.992920,345.284668 231.551529,343.163391 
	C232.361389,342.061188 234.212524,341.813049 235.724792,341.956665 
	C240.436417,342.404114 243.154053,340.299225 244.143036,335.391052 
	C243.470871,334.566406 246.521667,333.788147 243.867264,333.179260 
	C243.434433,332.534790 243.360458,332.096863 243.483124,331.313110 
	C244.257034,329.970947 245.243896,329.414337 246.720078,329.059937 
	C248.707321,329.202515 250.199677,326.888885 251.592010,328.153442 
	C252.999069,329.431427 250.807709,330.697052 250.453323,332.453583 
	C253.261063,332.407257 255.021378,331.167542 257.244812,329.642456 
	C260.010345,327.745483 263.155273,326.454926 265.622467,323.915588 
	C266.670868,322.836487 268.000641,324.050171 267.592865,325.458801 
	C267.070648,327.262756 267.171753,329.326752 265.125366,330.657166 
	C263.655579,331.612701 261.384705,332.749908 262.859985,335.688965 
	C262.349396,336.786865 261.714081,337.581665 261.040009,338.687622 
	C261.001221,338.998779 261.232147,339.266602 261.253174,339.439880 
	C261.274200,339.613190 261.485107,339.885315 261.543457,339.720459 
	C261.601776,339.555634 261.849457,339.807434 262.019653,339.854614 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M317.843536,282.703613 
	C318.017059,282.994354 318.043762,283.469696 318.050262,283.707642 
	C318.705688,284.648468 319.040375,285.468933 318.949341,286.779572 
	C318.844788,287.348297 318.778534,287.571686 318.548462,288.105927 
	C318.235199,288.598358 318.085754,288.779938 317.667419,289.190430 
	C317.195435,289.537628 316.992310,289.655914 316.463745,289.907410 
	C312.371613,291.008728 308.425720,291.575592 305.315430,294.867249 
	C303.972778,294.332062 303.312073,293.484711 301.726410,293.302155 
	C297.505493,292.816193 293.611084,291.083801 290.603516,287.873322 
	C289.811462,287.027802 289.225342,285.835236 287.861481,286.082489 
	C284.143860,286.756409 284.496796,284.392548 284.433746,281.919586 
	C286.770874,281.632874 286.035767,286.843628 288.409454,284.281555 
	C290.237976,282.307922 295.913940,281.757904 292.071045,276.481995 
	C291.074615,275.880371 289.448608,279.796021 288.049957,276.248718 
	C288.959076,273.614166 290.681854,272.560242 293.115936,271.886169 
	C296.177551,271.038300 299.547241,270.468079 301.845947,267.315308 
	C310.957336,267.390198 310.957336,267.390198 313.604279,275.903259 
	C313.988922,277.170746 312.802582,278.349762 314.481628,279.142548 
	C316.510712,279.598053 317.669037,280.488464 317.843536,282.703613 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M256.890625,291.225525 
	C259.381470,290.586914 260.950684,289.043488 262.639404,287.780884 
	C263.991974,286.769623 265.295135,285.347351 266.990753,286.406342 
	C268.712524,287.481689 268.004791,289.321106 267.521149,290.891602 
	C267.261353,291.735229 267.162506,292.482666 268.041168,293.176270 
	C270.681396,288.682678 276.114929,292.302124 279.674194,289.130249 
	C282.356232,289.019470 284.887177,288.775391 284.991394,292.620117 
	C285.122650,293.219452 285.243469,293.434296 285.236053,293.995850 
	C280.012085,298.641785 273.862976,300.845337 267.366425,303.041992 
	C264.361298,302.888397 262.365204,304.162354 260.559418,305.925110 
	C259.573761,306.887268 258.579956,308.499695 257.020844,306.078064 
	C258.173340,303.184479 258.377106,300.513000 257.336426,297.393433 
	C258.071259,295.829803 257.891449,294.859039 256.499634,293.821472 
	C256.154755,292.783569 256.234558,292.093384 256.890625,291.225525 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M267.014191,303.365814 
	C272.124359,299.087830 278.710358,297.939423 284.191345,294.041809 
	C285.420898,293.214355 286.664368,294.240082 287.785828,293.196960 
	C288.011322,292.967560 288.011536,293.012695 287.989014,293.011292 
	C288.127777,292.893707 288.289062,292.777557 288.700684,292.823303 
	C289.943085,294.101379 290.935120,295.217529 291.954407,296.678711 
	C289.902618,296.734070 289.335693,297.672668 289.918274,299.875916 
	C289.433716,300.587372 289.504608,301.146606 289.380920,301.928833 
	C289.117920,302.723206 289.108337,303.256683 289.371979,304.061890 
	C289.477020,304.707581 289.459137,305.081421 289.250427,305.694214 
	C287.251678,306.209320 285.543549,305.596100 283.785980,305.173950 
	C281.618774,304.653351 283.920135,310.091309 280.458435,307.823944 
	C279.055237,306.904907 278.212524,305.098846 276.783875,304.259644 
	C275.586975,303.556549 274.634735,305.171753 273.746246,305.867035 
	C270.336945,308.534821 268.168976,307.882874 267.014191,303.365814 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M247.006378,328.691223 
	C246.502747,330.000214 245.928116,330.932617 244.326874,330.997864 
	C240.862595,334.715057 236.365646,336.790314 232.945328,340.171997 
	C231.306503,341.792328 229.295776,342.695129 227.054749,341.680115 
	C224.649323,340.590607 226.170822,338.491089 226.156128,336.817291 
	C226.149902,336.108063 226.061295,335.522339 225.256866,334.816498 
	C224.027893,332.446777 225.742508,331.168976 226.798859,329.378540 
	C230.924744,327.461395 233.623993,324.138123 237.451477,321.838959 
	C237.849274,323.203552 237.916489,324.656891 237.983521,326.546661 
	C236.304611,328.033081 234.423157,328.736206 232.959915,330.163574 
	C234.423538,329.179138 235.784851,328.009979 237.739349,327.217224 
	C241.207703,325.970154 244.739136,322.646515 247.006378,328.691223 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M305.003510,295.339111 
	C306.687469,289.198090 311.965790,290.079926 316.597809,288.998474 
	C317.995178,290.717773 317.323334,292.561218 317.403259,294.740570 
	C317.425201,297.001709 317.043030,298.780182 316.001465,300.790070 
	C311.853149,305.832703 311.026550,305.760651 308.058014,300.436920 
	C307.653503,299.711487 307.075745,299.142151 306.247681,298.285004 
	C305.130951,297.454315 305.029510,296.582367 305.003510,295.339111 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M305.898773,298.248352 
	C307.710358,298.063629 308.484161,299.076294 309.108978,300.589813 
	C310.442902,303.820923 312.282928,303.957489 314.646423,301.040710 
	C317.258636,301.904297 317.233337,303.915009 317.252716,306.300537 
	C316.780609,310.049194 315.355682,312.858704 312.408661,315.263397 
	C310.243774,316.107605 308.819092,315.750977 308.019562,313.408691 
	C307.241699,309.056366 304.855103,305.214966 307.215424,301.045135 
	C307.233551,301.013062 306.209595,300.391144 305.345703,299.997009 
	C305.276733,299.466858 305.534271,298.984283 305.898773,298.248352 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M301.791687,306.690308 
	C299.164948,308.900787 296.397217,310.811066 293.320282,312.857971 
	C293.056763,311.809570 292.378693,311.104492 291.232513,310.340942 
	C292.215637,309.090393 292.206940,307.309906 293.756561,306.197174 
	C294.202423,305.474670 294.391541,304.945740 294.790344,304.226257 
	C295.370758,303.633179 295.826752,303.471802 296.687500,303.762115 
	C298.794617,304.317657 300.477173,304.864960 301.791687,306.690308 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M238.163391,321.835632 
	C239.382111,317.950653 242.068176,315.208466 246.240829,313.472260 
	C246.774261,313.318237 247.027756,313.381104 247.472656,313.784485 
	C247.891769,314.897766 247.855087,315.649780 247.255890,316.681183 
	C244.671631,319.429840 242.578140,322.320007 238.163391,321.835632 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M282.599182,274.431519 
	C284.562195,277.319122 283.628052,279.373779 281.258118,280.854614 
	C280.238922,281.491425 278.627289,281.329529 278.577423,279.856262 
	C278.489471,277.255951 278.821075,274.571198 282.599182,274.431519 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M246.632645,313.455200 
	C248.252487,311.962646 250.202072,311.761932 252.651733,312.117188 
	C253.126144,315.189026 250.682892,315.876190 248.213608,316.885437 
	C247.329102,316.164978 247.610474,315.287537 247.216248,314.260620 
	C247.015549,314.024323 246.744507,313.655060 246.632645,313.455200 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M276.916840,312.770996 
	C277.711823,314.419312 278.427094,315.846405 277.131104,317.687256 
	C275.715759,317.210388 276.026245,314.659637 273.354858,315.010956 
	C273.107117,313.854767 272.668945,312.468475 274.686676,311.988342 
	C275.206940,312.118103 275.363434,312.260712 275.771729,312.209778 
	C276.358124,312.089020 276.621216,312.267303 276.916840,312.770996 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M292.858826,319.068359 
	C292.209595,318.457733 291.757019,317.557526 291.125427,316.322662 
	C291.129303,315.446503 291.312225,314.905060 291.744263,314.218323 
	C291.884979,315.746002 295.575195,316.561554 292.858826,319.068359 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M268.436951,315.687164 
	C268.522247,317.052368 268.333710,318.174255 266.958862,318.462891 
	C266.809631,318.494232 266.375183,318.056610 266.399963,317.883270 
	C266.560364,316.761963 266.763580,315.616516 268.436951,315.687164 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M293.672028,306.053772 
	C293.064636,307.206085 293.342163,309.134583 291.371277,310.007874 
	C291.195770,308.412415 291.554840,306.853058 293.672028,306.053772 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M288.719482,302.362030 
	C289.062317,301.525177 289.238617,300.992157 289.661896,300.329224 
	C289.829834,301.953613 289.750793,303.707886 289.386261,305.698547 
	C289.082489,305.492432 289.064240,305.049988 288.978119,304.281982 
	C288.631744,303.570374 288.512695,303.140167 288.719482,302.362030 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M288.505310,310.695374 
	C289.902313,310.523224 289.952759,311.260864 289.644196,312.105743 
	C289.572876,312.301147 289.072021,312.525360 288.868469,312.453613 
	C288.123230,312.190948 288.107697,311.562836 288.505310,310.695374 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M287.637756,292.899414 
	C287.854675,295.267975 286.466858,294.504425 284.869476,294.045227 
	C284.614441,293.841736 284.709045,293.682617 284.902710,293.264038 
	C285.755920,292.946869 286.510040,292.889069 287.637756,292.899414 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M247.001816,314.394348 
	C247.921707,314.515717 248.003418,315.362335 247.916107,316.575195 
	C247.855240,316.894806 247.437943,316.926666 247.231140,316.956604 
	C247.012268,316.245850 247.000168,315.505096 247.001816,314.394348 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M296.985535,303.710266 
	C296.561066,303.992310 296.115326,304.012146 295.334808,304.033875 
	C295.529388,303.420715 295.693787,301.586121 296.985535,303.710266 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M271.476746,311.459778 
	C271.524017,311.433350 271.429474,311.486176 271.476746,311.459778 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M275.780121,312.009399 
	C275.842102,312.360748 275.694031,312.790466 275.125549,312.157013 
	C275.050476,311.975525 275.536713,312.002533 275.780121,312.009399 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M272.998474,314.998047 
	C272.907135,315.028473 272.817291,315.060883 272.865784,315.048279 
	C273.004120,315.003326 273.000000,315.000000 272.998474,314.998047 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M288.905243,292.729370 
	C288.951050,292.985199 288.457886,292.963806 288.212158,292.986816 
	C287.981232,292.360046 288.200439,292.050690 288.905243,292.729370 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M292.013794,296.989471 
	C292.137909,297.125000 292.249756,297.270508 292.361603,297.415985 
	C292.274994,297.371948 292.188354,297.327942 292.041718,297.153809 
	C291.981689,297.023743 292.001495,296.999420 292.013794,296.989471 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M206.364258,559.940857 
	C204.841675,556.825928 207.993225,553.006653 204.248718,550.126648 
	C204.863770,545.443176 199.890915,545.507141 198.154053,542.950073 
	C196.289322,540.204834 192.924149,538.146545 195.646866,534.026001 
	C196.576050,532.619751 195.881073,530.856018 195.165909,529.291260 
	C194.414963,527.648193 193.660034,525.960510 193.264725,524.211365 
	C192.506744,520.857666 194.088257,518.728821 197.630783,518.003601 
	C197.327621,516.327026 196.167328,515.538025 194.337677,515.036377 
	C193.997177,515.000000 194.036285,514.510498 194.068329,514.267090 
	C197.440872,510.160095 196.064316,504.827911 198.418365,500.289856 
	C200.482513,497.689270 205.245834,498.574005 205.393906,493.799896 
	C205.088257,489.674561 204.535904,485.797638 203.908157,481.491272 
	C200.956909,474.862610 202.637131,468.801849 204.654922,462.353210 
	C203.910263,458.555420 204.196930,455.087524 204.656647,451.289001 
	C209.740082,450.232483 207.908615,454.766235 209.731049,456.740417 
	C213.342575,457.957764 214.265778,460.622284 214.381577,463.724518 
	C214.479660,466.352081 214.876480,468.878632 216.424713,471.514465 
	C216.865631,472.589355 216.921036,473.344238 216.718765,474.478516 
	C215.459717,476.490417 215.425186,478.496704 214.721741,480.664307 
	C212.941147,483.368835 210.003235,482.588989 207.296585,483.946167 
	C212.928940,488.646973 213.634354,494.494080 211.968521,501.013580 
	C211.571350,502.567993 211.991577,504.334076 212.730164,505.876343 
	C213.325302,507.119019 213.834473,508.313904 212.647766,509.530304 
	C210.184128,512.055664 210.845856,514.626160 212.833771,517.416504 
	C213.405960,517.909058 213.761780,518.147156 214.442657,518.467285 
	C215.376572,518.789795 215.994736,519.027161 216.846497,519.553833 
	C217.192017,519.980957 217.273010,520.193542 217.188766,520.704712 
	C212.928986,523.877380 212.594101,528.863220 212.592590,532.705261 
	C212.591309,535.956604 209.745270,535.780396 209.259552,538.267944 
	C211.313034,538.171753 213.985489,535.519714 214.954590,539.650757 
	C212.863968,539.820007 210.447968,540.110840 210.823303,542.711609 
	C211.225983,545.501892 203.906555,548.996216 212.550201,551.157776 
	C208.778259,551.406677 207.375656,552.357910 208.324432,555.597656 
	C208.643799,556.688110 208.616241,558.972046 206.364258,559.940857 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M184.977142,459.414917 
	C185.472290,459.036835 185.904678,459.035278 186.661713,459.039276 
	C188.016815,459.267059 188.857925,459.808411 189.782410,460.787537 
	C190.084137,462.449799 190.932404,463.010681 192.647247,462.978271 
	C194.412903,464.732635 195.326172,466.793793 196.595673,468.986450 
	C196.918991,470.367645 195.055283,472.210541 198.090149,472.387329 
	C202.733780,474.095245 203.999191,477.140594 202.223938,481.723358 
	C202.033401,481.999512 202.017868,482.016327 202.011337,482.025757 
	C200.983185,482.912872 199.810287,482.544525 198.292862,482.219299 
	C197.172287,481.994934 196.428284,481.991394 195.312347,481.980988 
	C194.029037,480.611145 192.511108,480.408752 190.797180,479.987549 
	C190.180115,480.063324 189.877045,480.096344 189.225647,480.000427 
	C189.239395,478.484283 189.598221,477.067444 189.998260,475.285400 
	C190.104767,472.484497 186.059326,473.017822 186.759338,469.894348 
	C189.284180,467.345276 187.852859,466.722046 185.300934,466.232178 
	C185.031235,466.008545 184.613739,466.063599 184.403198,466.064697 
	C183.945969,465.938141 183.699295,465.810516 183.722809,465.365784 
	C183.850494,463.217438 184.125717,461.459442 184.977142,459.414917 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M181.784088,529.793030 
	C181.919754,538.113953 176.001511,543.609070 172.750610,550.706177 
	C172.575134,551.038391 172.460602,551.038879 172.403778,551.031738 
	C169.964920,550.081421 167.924835,548.811523 167.517044,545.613037 
	C167.665543,543.761475 167.141876,542.419495 166.533035,540.726807 
	C166.476883,540.134644 166.497910,539.895020 166.687866,539.336426 
	C166.930298,538.861694 167.003784,538.705811 167.178406,538.312134 
	C168.274857,536.761475 169.708588,536.466309 171.180176,536.672363 
	C173.795959,537.038757 173.909805,534.969482 174.549515,533.412659 
	C175.376587,531.399902 174.251221,530.414612 172.254822,529.589966 
	C171.676331,529.047913 171.478546,528.649353 171.421036,527.861450 
	C171.529022,526.663940 172.586624,526.798218 172.729645,525.948914 
	C172.590500,525.759827 172.624374,525.275757 172.822449,525.135620 
	C175.227203,524.768005 177.337921,524.728760 177.957550,527.929321 
	C179.083557,528.867615 180.548157,528.540649 181.784088,529.793030 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M171.701599,528.664917 
	C177.686066,529.276855 177.718323,529.304871 175.659576,534.540894 
	C174.999435,536.219849 174.211975,537.848755 173.229019,540.075317 
	C171.967651,537.604370 170.016571,537.652771 167.625504,538.069702 
	C165.437775,534.060730 163.617630,530.048401 168.183624,526.163818 
	C170.842621,525.089417 170.661102,527.356689 171.701599,528.664917 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M198.420105,498.984497 
	C199.324951,501.330780 199.722595,503.307251 198.151520,505.480560 
	C196.412384,507.886322 196.425522,510.515106 199.998413,512.118896 
	C197.986954,513.391846 195.806076,511.801361 194.369553,513.866882 
	C193.760666,512.475952 193.424850,510.926025 191.515335,510.034729 
	C190.781693,508.661072 190.374542,507.345367 189.852539,505.693176 
	C188.727325,502.177612 191.670898,501.119354 193.359421,499.028137 
	C195.148407,498.183685 196.616760,498.401855 198.420105,498.984497 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M199.098511,498.981140 
	C197.381485,498.587982 196.053116,500.349640 194.331955,499.125763 
	C193.857681,498.830963 193.721786,498.641815 193.795975,498.173523 
	C193.542587,494.415894 197.123749,493.223999 198.262939,490.302765 
	C198.604645,490.108917 198.823517,490.194427 199.018341,490.631531 
	C199.593155,491.476349 200.192017,491.969574 200.904633,492.740112 
	C200.787552,493.185913 200.548935,493.342194 200.302521,493.486237 
	C200.054810,493.631073 200.137009,493.584167 200.769592,493.164795 
	C202.235245,493.099854 203.480515,493.215790 205.183197,493.441650 
	C206.575302,499.585785 206.575302,499.585785 199.098511,498.981140 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M202.289948,481.961243 
	C202.645386,478.456177 201.607773,475.538849 198.478210,473.188293 
	C197.570618,472.647217 197.684891,472.053589 197.969635,471.173950 
	C198.235382,470.739471 198.367432,470.582275 198.772766,470.283203 
	C203.845886,469.462646 200.045471,464.287689 202.984177,462.530975 
	C203.425430,462.267212 203.576141,461.532654 204.630219,461.902802 
	C204.510513,468.013794 203.525620,474.033875 203.894012,480.595490 
	C203.404007,481.348877 202.975250,481.635925 202.289948,481.961243 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M193.556702,498.004547 
	C193.979904,498.081757 193.953781,498.269135 193.960602,498.738281 
	C193.451080,501.495728 191.420685,502.875946 189.806641,504.985046 
	C189.047989,505.320770 188.358322,505.284912 187.318115,505.229736 
	C186.387100,505.058838 185.841034,504.840881 185.244873,504.154724 
	C186.787628,501.704224 185.017303,500.323730 183.833618,498.494019 
	C183.845230,498.007965 183.894150,497.823456 184.207428,497.528961 
	C187.288513,498.418030 190.190933,498.353333 193.556702,498.004547 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M184.919418,466.386169 
	C185.194244,465.989716 185.361420,465.987915 185.519608,465.949341 
	C187.230194,465.532043 189.185684,463.328247 190.602386,465.847748 
	C192.157379,468.613220 188.971817,468.732117 187.229111,469.856628 
	C185.779099,468.999481 184.431473,468.430115 184.919418,466.386169 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M166.513824,538.965210 
	C166.906082,539.177490 166.955368,539.337402 167.026230,539.747437 
	C166.729828,540.586609 166.212570,540.888794 165.267090,541.049927 
	C164.074860,540.903748 163.198944,540.764526 162.228195,541.740479 
	C159.232147,541.284119 161.001785,539.143494 160.803848,537.364624 
	C163.155807,536.551819 164.455856,538.287415 166.513824,538.965210 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M199.349121,490.990082 
	C198.932968,490.726654 198.819794,490.495605 198.520279,490.156708 
	C197.548325,489.318359 196.136230,488.574738 197.552643,487.425964 
	C198.629211,486.552826 200.244263,486.815247 201.138458,487.863098 
	C202.656952,489.642487 201.065628,490.344574 199.349121,490.990082 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M186.432343,469.903015 
	C186.746674,469.810699 186.923965,469.803040 186.955078,469.886047 
	C187.225098,472.263000 190.692978,472.075104 190.308456,474.780823 
	C188.391800,473.549713 183.856415,474.478607 186.432343,469.903015 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M156.229630,534.687317 
	C154.979248,536.107727 153.859619,538.061401 151.895340,536.244019 
	C150.337585,534.802856 150.316437,533.242859 152.626968,531.945801 
	C153.957977,532.289673 154.582687,533.174500 155.839874,533.588257 
	C156.153320,533.906616 156.182587,534.129028 156.229630,534.687317 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M190.444489,480.348450 
	C192.378113,478.876312 194.496460,476.865509 194.997360,481.555511 
	C194.208008,482.069275 193.475586,482.164429 192.394409,482.380890 
	C191.809708,482.498230 191.573807,482.494263 191.067627,482.261780 
	C190.667923,481.587585 190.538452,481.141907 190.444489,480.348450 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M171.957031,528.579590 
	C168.604019,530.525452 170.774414,526.291565 168.754272,526.062256 
	C169.310898,524.264343 170.932083,525.229614 172.605377,524.945251 
	C173.020508,524.995544 173.019928,525.471985 173.012329,525.710083 
	C172.925735,526.311951 172.726883,526.583313 172.191040,526.926147 
	C171.982529,527.498474 171.971725,527.891846 171.957031,528.579590 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M171.899719,516.171387 
	C173.258530,517.198547 173.108292,518.284302 172.631638,519.880798 
	C171.726547,518.499756 168.073563,518.505310 171.899719,516.171387 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M182.074219,529.673096 
	C180.648407,529.862671 179.035233,530.659119 177.982941,528.664307 
	C178.716568,527.930542 179.517426,527.535522 180.618744,526.998413 
	C181.323105,527.679932 181.727005,528.503479 182.074219,529.673096 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M190.288132,483.710632 
	C189.897675,485.579254 188.865067,486.621460 187.522064,485.586090 
	C185.962555,484.383820 188.385284,483.552429 188.662201,482.191864 
	C189.490692,482.408783 190.061203,482.752289 190.288132,483.710632 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M160.777344,536.978455 
	C161.142303,538.498840 161.243408,540.002014 161.651642,541.719971 
	C161.791595,542.846802 161.640396,543.774048 160.043503,543.502197 
	C159.796295,541.510437 159.862854,539.651733 159.957092,537.382568 
	C160.160995,536.968384 160.337234,536.964844 160.777344,536.978455 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M183.639893,464.988098 
	C184.013367,465.220306 184.033752,465.391937 184.123383,465.814667 
	C183.031525,466.363678 181.870392,466.661621 180.365173,466.988800 
	C179.681488,464.090210 180.992996,463.741638 183.639893,464.988098 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M179.804504,479.186829 
	C180.047684,478.995148 180.503799,478.999054 180.731384,479.001160 
	C181.229904,481.425079 179.959793,482.191223 177.382797,481.964905 
	C176.994507,481.959290 176.997330,481.508240 176.996780,481.282715 
	C177.656418,480.199860 178.645203,479.844727 179.804504,479.186829 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M156.120056,533.638062 
	C154.578568,534.727051 153.094330,535.577393 152.914383,532.280762 
	C153.011185,531.929199 152.990356,531.992798 152.958618,531.982178 
	C154.247757,531.349487 155.476303,531.214294 156.219635,533.095947 
	C156.029053,533.559570 156.116058,533.591858 156.120056,533.638062 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M183.220001,457.050842 
	C183.011536,456.584900 183.075943,456.164673 183.224609,455.436737 
	C184.063400,455.294922 185.068253,455.225830 185.504456,455.690552 
	C185.971863,456.188507 185.269089,456.768402 184.339722,457.119568 
	C183.853653,457.173767 183.673279,457.135193 183.220001,457.050842 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M198.091995,482.277344 
	C199.008270,481.981384 200.100250,481.964294 201.598511,481.991211 
	C201.210205,485.444611 199.569153,482.782471 198.091995,482.277344 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M190.487732,483.985962 
	C189.894470,483.543152 189.556564,483.051178 189.092072,482.289368 
	C189.372971,482.008850 189.780457,481.998199 190.492645,482.010406 
	C191.007538,482.105713 191.217712,482.178162 191.736755,482.376404 
	C191.897720,483.240295 191.496078,483.748047 190.487732,483.985962 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M184.973999,504.185364 
	C185.631363,504.145813 186.182541,504.384247 186.850647,504.916565 
	C186.428131,505.805695 185.779495,506.297882 184.971359,505.946350 
	C184.175034,505.599945 184.578400,504.985565 184.973999,504.185364 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M156.356033,533.338623 
	C155.710907,532.350464 154.758972,532.095276 153.310913,531.990112 
	C152.843292,531.782288 152.759735,531.593079 152.816711,531.127075 
	C154.331573,531.388611 156.939835,529.899048 156.356033,533.338623 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M184.559784,508.050110 
	C185.397827,508.025085 186.436798,507.978241 186.211395,508.544006 
	C185.926392,509.259399 184.873856,509.061096 184.123398,508.744080 
	C184.027786,508.703674 183.908630,508.493866 183.939606,508.426880 
	C184.000046,508.296112 184.158920,508.210815 184.559784,508.050110 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M183.447083,476.840942 
	C183.146011,476.797760 183.120911,476.494843 183.129822,476.344574 
	C183.868530,476.285187 184.598343,476.376099 185.328140,476.466980 
	C184.801468,476.606018 184.274811,476.745087 183.447083,476.840942 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M181.389496,509.248413 
	C181.404938,508.996155 181.535568,508.860352 181.666229,508.724579 
	C181.612381,508.938019 181.558533,509.151428 181.389496,509.248413 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M189.058380,480.075623 
	C188.920822,480.275238 188.669174,481.006378 188.729828,479.992828 
	C188.880569,479.901093 189.002258,480.014313 189.058380,480.075623 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M184.550629,474.530609 
	C184.513336,474.546509 184.587906,474.514709 184.550629,474.530609 
z"
    />
    <path
      fill="#FAFAFA"
      opacity="1.000000"
      stroke="none"
      d="
M178.462173,483.533569 
	C178.435532,483.485809 178.488815,483.581299 178.462173,483.533569 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M179.994720,466.992554 
	C179.888901,467.008514 179.790527,467.033844 179.856613,467.038605 
	C180.021072,467.018066 180.002182,467.001862 179.994720,466.992554 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M183.973236,498.006104 
	C183.100998,497.655548 182.964874,497.271881 184.266479,497.322449 
	C184.364563,497.552582 184.257355,497.686188 183.973236,498.006104 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M211.515106,359.675049 
	C211.818176,361.464813 213.112656,362.121857 214.228333,363.371704 
	C213.660706,367.111572 211.128418,365.610107 208.658203,365.433685 
	C208.039261,362.738251 208.414490,359.858887 204.399994,359.166443 
	C206.371597,357.325592 206.937973,354.459961 208.867096,352.131073 
	C209.448044,352.421204 209.734467,352.893951 210.090668,353.643311 
	C211.381622,355.525391 211.550415,357.407593 211.515106,359.675049 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M226.850479,328.897827 
	C228.303940,331.570343 225.750412,332.021423 224.736618,333.627136 
	C224.813339,333.993988 225.028061,334.076080 225.263260,334.501648 
	C225.130600,335.015167 224.977432,335.185211 224.496338,335.453949 
	C223.942947,335.518982 223.717499,335.485352 223.249283,335.210938 
	C221.886734,335.317871 220.766983,335.665527 219.295746,335.918274 
	C219.269791,334.767822 219.595322,333.712311 219.943359,332.323669 
	C222.667755,331.825073 223.249680,327.417267 226.850479,328.897827 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M213.676224,335.053101 
	C214.103439,336.904175 209.907623,338.722351 214.254608,340.588776 
	C215.297409,341.036499 213.629761,342.070251 212.868591,342.483093 
	C210.620880,343.702179 209.562454,342.219208 208.407166,340.268677 
	C208.110794,339.863525 208.061417,339.686829 208.072800,339.241699 
	C208.229965,338.817963 208.326340,338.662628 208.291092,338.266754 
	C208.238281,336.550385 209.519958,336.130829 210.757324,335.339172 
	C211.223160,335.317963 211.389282,335.366730 211.768341,335.266785 
	C212.434830,335.069031 212.888382,335.019928 213.676224,335.053101 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M205.728577,321.535614 
	C204.623840,321.986450 203.574448,322.075897 202.254288,322.170593 
	C200.494690,320.122925 202.490906,319.566681 203.584244,319.143555 
	C205.165756,318.531525 206.002060,319.454681 205.728577,321.535614 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M214.872025,329.214722 
	C216.912872,329.165192 219.238907,328.451935 219.952393,331.669159 
	C218.050400,331.589081 216.175079,330.958038 214.872025,329.214722 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M196.098358,351.229523 
	C197.154755,351.704681 198.449387,351.860107 198.027893,353.161743 
	C197.710526,354.141815 196.715134,354.089874 196.015350,353.851440 
	C194.334198,353.278595 194.939606,352.269043 196.098358,351.229523 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M210.998230,328.998718 
	C211.477997,330.080109 211.859970,331.157898 210.348267,331.747803 
	C209.757431,331.978363 208.841690,332.092529 208.758423,331.395264 
	C208.632370,330.339661 209.721893,329.863342 210.726959,329.135834 
	C211.002609,329.001740 211.000000,329.000000 210.998230,328.998718 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M203.698730,354.552246 
	C203.528351,355.857147 202.682510,357.253937 201.998550,356.513428 
	C201.032135,355.467163 202.268158,354.558105 203.698730,354.552246 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M202.686325,360.075256 
	C202.899902,360.898804 202.622559,361.634094 201.373108,361.944580 
	C201.103088,361.092346 201.353622,360.337433 202.686325,360.075256 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M204.001953,354.001923 
	C204.163925,353.574585 204.331467,353.152832 204.499023,352.731079 
	C204.658310,352.988068 204.817596,353.245087 204.992981,353.747986 
	C204.846725,354.110992 204.684402,354.228058 204.270218,354.181763 
	C204.018356,354.018372 204.007523,354.007507 204.001953,354.001923 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M211.469788,333.455872 
	C211.519775,333.427368 211.419785,333.484344 211.469788,333.455872 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M208.080902,338.261017 
	C208.302170,338.187653 208.462555,338.338013 208.575165,338.518250 
	C208.603378,338.563416 208.477997,338.704529 208.278397,338.887421 
	C208.133575,338.973297 208.002319,338.495819 208.080902,338.261017 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M211.736374,335.071259 
	C211.981262,335.118134 211.713562,335.505371 211.713562,335.505371 
	C211.713562,335.505371 211.278641,335.504486 211.167847,335.386841 
	C211.057053,335.269226 211.491486,335.024384 211.736374,335.071259 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M204.013031,354.268494 
	C204.018356,354.018372 204.513565,354.004120 204.761322,353.999023 
	C204.905426,354.319885 204.715607,354.583893 204.221558,354.902466 
	C204.004898,354.852264 204.006302,354.685455 204.013031,354.268494 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M216.046875,474.848755 
	C216.057114,473.996674 216.076981,473.502716 216.070068,472.762604 
	C214.149673,468.453217 214.347382,464.217499 214.944794,459.928436 
	C215.377533,456.821686 215.565491,453.689545 215.709122,450.158630 
	C216.781448,447.745941 217.209076,445.522980 217.969818,443.776825 
	C217.968430,443.211151 217.974884,443.362488 217.876312,443.846619 
	C217.683472,444.793884 217.078384,445.604675 216.830170,446.899078 
	C216.611603,447.423065 216.503601,447.622589 216.172195,448.076721 
	C212.770782,449.025604 212.317276,451.603210 211.766937,454.509460 
	C211.180573,455.626770 210.494614,456.173706 209.356659,456.697235 
	C206.083176,456.039276 208.271820,452.096283 205.229401,451.026001 
	C204.877365,450.518951 204.881607,450.081573 204.968048,449.323364 
	C205.509308,448.577881 205.706512,448.078644 205.318527,447.226959 
	C206.934769,438.058746 206.308746,428.955078 207.087891,419.552124 
	C208.238403,415.625854 207.962143,411.714081 210.278397,408.492035 
	C211.433517,406.885223 212.234573,404.763977 213.958679,403.916901 
	C219.042053,401.419464 220.247864,396.720764 221.285995,391.626282 
	C222.414688,387.830261 223.712265,384.332428 225.023926,380.457825 
	C224.743896,378.534760 225.925293,377.640961 226.814819,376.263397 
	C227.331650,375.535065 227.643204,375.070679 227.977295,374.314392 
	C228.232147,373.801880 228.460281,373.576965 228.849609,373.173462 
	C229.329819,372.536957 229.644669,372.074738 229.987961,371.322510 
	C230.772842,369.752716 232.695129,369.513733 232.972168,367.447632 
	C233.722137,365.253082 234.305862,363.324249 235.661896,361.384094 
	C235.065720,356.822845 239.179810,360.475922 239.943588,358.342072 
	C240.615845,357.415009 241.179520,356.716125 242.239014,356.058472 
	C242.731567,355.767426 242.933441,355.656708 243.451950,355.397583 
	C243.992981,355.178467 244.217407,355.107666 244.783600,354.939453 
	C247.483322,353.926117 249.339386,352.408020 250.909393,350.412567 
	C252.037979,348.978119 253.548615,347.541901 255.525650,348.668213 
	C257.566559,349.830902 256.719421,351.921478 256.282959,353.577850 
	C255.430344,356.813324 253.164688,359.314148 251.253098,361.941010 
	C250.142960,363.466553 249.341232,364.957245 249.092361,367.164154 
	C248.537460,368.916107 247.849258,370.203339 246.856720,371.740784 
	C246.466019,372.202820 246.292313,372.371765 245.822922,372.746674 
	C245.317078,373.063049 245.106918,373.173462 244.573395,373.421448 
	C239.604645,376.732086 238.352676,381.904572 236.905304,386.772217 
	C235.630539,391.059296 233.097244,395.523071 236.496307,400.081207 
	C236.975067,400.723206 237.040710,402.109924 236.415741,402.410919 
	C231.967560,404.553101 237.171173,405.813293 236.943451,407.869995 
	C238.885345,413.256775 237.167145,418.694183 238.757294,424.142517 
	C238.596634,425.015656 238.399384,425.477753 237.912933,426.210815 
	C235.854462,428.324188 235.320099,430.588898 235.448456,433.449158 
	C235.484329,435.892365 235.601105,437.979675 234.157288,440.107483 
	C228.589493,441.762817 227.899231,441.371155 226.203018,435.705933 
	C225.585083,439.742920 224.085876,443.472107 227.795898,446.581818 
	C229.138519,447.707245 228.932693,449.255859 227.941406,450.609070 
	C226.952652,451.958862 225.873886,453.246185 226.630692,455.431458 
	C226.924484,456.444458 226.993576,457.138916 226.953552,458.190002 
	C226.295166,460.261383 226.855560,462.213531 225.721100,464.165283 
	C224.738129,465.265717 224.149002,466.239258 223.209747,467.370178 
	C218.806702,468.568268 220.210846,473.469727 217.079117,475.435120 
	C216.502579,475.377686 216.279541,475.292297 216.046875,474.848755 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M221.008286,391.642151 
	C222.894287,392.991241 222.463028,395.198303 221.482132,396.672272 
	C220.600647,397.996826 219.511673,398.785431 220.614853,400.435638 
	C221.495514,401.752960 220.585861,401.899139 219.513107,402.295135 
	C212.201706,404.994171 208.938675,410.227875 208.836639,419.147186 
	C208.833099,419.455811 208.689972,419.762848 208.611908,420.070618 
	C208.307220,419.824799 208.002533,419.578979 207.410370,419.244751 
	C206.787064,417.665619 206.451279,416.174835 206.054337,414.301056 
	C209.336212,405.493988 216.164597,399.435577 221.008286,391.642151 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M198.992783,469.757355 
	C199.046051,470.141266 199.019669,470.610352 199.001984,470.844604 
	C198.599319,471.336639 198.181961,471.523895 197.390869,471.690033 
	C196.817657,471.674896 196.590668,471.620941 196.075394,471.366333 
	C195.709183,470.944611 195.631226,470.723450 195.782104,470.235168 
	C195.376053,467.727448 191.833069,466.904877 192.998001,463.385742 
	C192.999023,462.984344 192.998657,463.002167 192.991379,463.001099 
	C193.159561,462.883514 193.335037,462.766998 193.773315,462.825867 
	C195.836411,463.246643 197.189850,462.352081 198.784851,461.115723 
	C199.279556,460.873383 199.456894,460.779510 199.822403,460.920563 
	C198.976364,463.806519 196.931839,466.325928 198.992783,469.757355 
z"
    />
    <path
      fill="#31302F"
      opacity="1.000000"
      stroke="none"
      d="
M200.679626,429.677124 
	C202.518387,429.979828 204.437378,429.957886 203.413406,432.559845 
	C202.849472,433.992859 201.611694,434.682495 200.434692,434.351349 
	C198.182526,433.717651 199.488800,432.100372 199.947998,430.434784 
	C200.150131,429.941315 200.282028,429.821869 200.679626,429.677124 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M189.993744,460.651062 
	C188.885361,460.912445 187.617813,461.170410 187.048386,459.380859 
	C187.201019,458.764709 187.415665,458.484558 187.870178,458.085815 
	C188.231842,457.795807 188.353638,457.624359 188.665955,457.704346 
	C189.798294,458.465576 189.988235,459.337982 189.993744,460.651062 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M199.722855,453.900085 
	C198.635880,452.653229 198.496658,451.315308 199.776321,450.185699 
	C199.904877,450.072296 200.430191,450.202423 200.606415,450.381683 
	C201.898041,451.695679 200.746384,452.697662 199.722855,453.900085 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M193.543045,440.293518 
	C194.265930,441.210052 195.178421,441.986694 194.560135,443.019928 
	C194.418457,443.256653 193.511688,443.378937 193.337708,443.194611 
	C192.502502,442.309631 191.961716,441.294525 193.543045,440.293518 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M198.077698,440.083862 
	C198.142303,439.016907 197.443222,437.592865 198.653870,437.686615 
	C199.404648,437.744751 199.159149,439.286072 198.896698,440.198639 
	C198.855072,440.343414 198.748199,440.587219 198.681259,440.583954 
	C198.505508,440.575439 198.335129,440.455841 198.077698,440.083862 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M200.306915,460.954865 
	C200.010574,461.155457 199.564590,461.021759 199.333405,460.994507 
	C198.903992,459.688019 199.318466,458.194550 200.495178,458.270020 
	C201.426758,458.329742 200.936676,459.859650 200.306915,460.954865 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M205.057007,447.323181 
	C205.774719,447.109436 206.521011,447.200470 206.511383,447.997131 
	C206.507660,448.304810 205.932068,448.605621 205.333618,448.956055 
	C205.022034,448.552979 204.993866,448.103394 205.057007,447.323181 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M188.872253,457.685242 
	C188.856445,457.955780 188.482224,457.961121 188.296143,457.964172 
	C188.166077,457.486664 187.747086,456.828186 188.580582,456.663086 
	C188.631454,456.653015 188.783234,457.152405 188.872253,457.685242 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M182.540741,443.474487 
	C182.568024,443.522736 182.513443,443.426239 182.540741,443.474487 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M193.935242,462.839264 
	C193.860840,463.002228 193.685547,463.003265 193.247192,463.002136 
	C193.267532,462.892456 193.550949,462.784882 193.935242,462.839264 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M200.805389,429.561584 
	C200.797684,429.735535 200.650040,429.819275 200.260315,429.981873 
	C200.233978,429.864258 200.449722,429.667816 200.805389,429.561584 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M257.141602,290.748505 
	C257.101227,291.761597 257.022736,292.512146 257.059021,293.626038 
	C257.236542,294.193634 257.299316,294.397919 257.127563,294.820709 
	C254.105881,299.745270 248.726471,298.059021 244.399292,299.918396 
	C244.009399,298.795868 243.474716,297.994110 242.365692,297.212189 
	C241.626343,296.734772 241.440384,296.252533 241.321350,295.442474 
	C241.261429,295.182037 241.050430,295.058289 240.942047,294.999908 
	C241.587738,294.312256 242.455231,293.777985 243.075851,293.036987 
	C245.527512,290.109833 248.222473,289.697052 251.658051,292.001404 
	C252.030884,291.967316 252.292740,292.381592 252.292740,292.381592 
	C252.292740,292.381592 252.245438,292.344971 252.108154,292.183350 
	C254.217407,290.875031 254.009705,287.010498 257.725342,287.081726 
	C257.738708,288.188141 257.421112,289.337067 257.141602,290.748505 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M223.679138,316.325073 
	C225.028030,311.129639 227.864578,312.978363 230.721359,314.373474 
	C228.833130,315.608032 228.571503,319.051178 224.856934,317.844482 
	C223.836197,317.730743 223.498688,317.414154 223.679138,316.325073 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M223.530945,315.782288 
	C223.960510,316.331421 224.079437,316.731232 224.363831,317.399597 
	C223.319672,317.773468 222.110062,317.878784 220.454559,317.996094 
	C219.952652,315.693054 220.517822,314.217987 223.530945,315.782288 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M239.372726,309.000214 
	C240.442383,306.622131 243.372116,307.439545 245.655182,306.043823 
	C246.187546,306.186005 246.351852,306.341034 246.271484,306.730499 
	C244.069275,308.037811 242.066895,308.974976 239.372726,309.000214 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M256.945892,295.375885 
	C256.954407,294.868195 257.015808,294.697113 257.125458,294.257690 
	C259.127899,294.497284 259.639679,295.389648 257.641663,296.943024 
	C257.227264,296.580902 257.113007,296.146698 256.945892,295.375885 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M219.995163,317.995361 
	C220.008484,318.899811 220.266708,319.919800 219.231461,320.440186 
	C219.047211,320.532745 218.369141,320.120026 218.275543,319.826843 
	C217.988892,318.928955 218.506409,318.376404 219.677521,318.050629 
	C220.008698,318.008087 219.999802,317.999847 219.995163,317.995361 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M213.217590,323.099976 
	C213.336533,325.122253 211.945984,324.878082 209.861603,324.741516 
	C211.251266,323.976318 212.092972,323.512848 213.217590,323.099976 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M246.397964,307.044678 
	C246.026810,306.964935 245.972092,306.498138 245.997665,306.264557 
	C246.179535,306.002960 246.356384,305.912842 246.488892,305.956665 
	C247.619598,306.331085 249.238953,304.025513 249.654449,305.867676 
	C250.058502,307.658997 247.760284,306.630585 246.397964,307.044678 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M228.472977,322.551208 
	C229.229111,322.290405 229.623154,322.496155 229.563034,323.085327 
	C229.541641,323.294861 229.186295,323.614960 228.964615,323.631409 
	C228.383545,323.674438 228.167587,323.295929 228.472977,322.551208 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M213.006744,314.995544 
	C213.757538,315.574921 214.037155,316.222870 213.027466,316.747314 
	C212.746765,316.893097 212.244675,316.612701 211.844147,316.527832 
	C212.136917,316.175446 212.429703,315.823029 212.850830,315.242310 
	C212.979156,315.014008 212.997879,315.001526 213.006744,314.995544 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M239.001175,309.002380 
	C238.701096,309.271271 238.392502,309.529449 238.083893,309.787628 
	C237.946686,309.617279 237.809479,309.446899 237.672256,309.276550 
	C237.932053,309.156616 238.191849,309.036652 238.711807,308.942871 
	C238.971985,308.969055 238.992661,308.991638 239.001175,309.002380 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M214.994781,325.991943 
	C215.346619,325.614624 215.569870,324.757019 216.272171,325.624573 
	C216.009705,325.746002 215.747253,325.867462 215.253250,326.005219 
	C215.021713,326.021545 215.002502,326.002441 214.994781,325.991943 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M206.807648,379.567444 
	C207.725433,378.935883 208.499832,378.583710 209.571503,378.120239 
	C210.584610,377.409088 211.387360,377.229553 212.636429,377.760712 
	C212.834702,380.898132 210.731171,382.954376 209.516785,385.686310 
	C208.318970,385.104736 207.010269,385.127075 206.045197,383.959351 
	C205.982071,382.319702 205.806046,380.987122 206.807648,379.567444 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M205.639847,383.764465 
	C206.902069,384.370819 208.809357,382.987274 209.193024,385.461975 
	C206.065292,385.959656 207.981659,387.950043 207.830017,389.473083 
	C202.769882,387.050293 202.500641,386.388245 205.639847,383.764465 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M213.095398,377.709564 
	C212.218262,378.047089 211.448761,378.082092 210.274017,378.063019 
	C208.777115,376.267212 210.192261,375.103760 211.027863,373.417175 
	C212.399536,373.053528 213.635757,372.774292 214.836578,373.980652 
	C215.180359,375.789642 214.197495,376.601715 213.095398,377.709564 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M203.277725,402.838562 
	C202.986160,401.978577 202.985413,400.897552 202.993073,399.410889 
	C202.991135,398.824799 202.980789,398.644318 202.926392,398.196289 
	C202.767715,397.750671 202.653091,397.572632 202.826721,397.261078 
	C203.708405,396.371155 204.301865,395.614746 205.172501,394.768433 
	C205.991806,397.601898 204.197098,399.971558 203.277725,402.838562 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M226.708618,376.128723 
	C226.529999,377.218842 226.039886,378.438202 225.293884,379.869293 
	C220.826065,377.148254 225.134125,377.244385 226.708618,376.128723 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M202.331985,407.505463 
	C202.202881,406.293152 202.350967,404.863312 202.770752,403.194611 
	C202.898010,404.399811 202.753601,405.843872 202.331985,407.505463 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M228.793152,373.111450 
	C228.896774,373.317078 228.702530,373.578491 228.216034,373.902954 
	C228.105606,373.695648 228.296097,373.429382 228.793152,373.111450 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M154.440216,490.182587 
	C157.090775,484.803070 158.481827,489.850708 160.836334,490.149017 
	C156.317642,492.794800 155.525024,492.800659 154.440216,490.182587 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M168.145035,494.686646 
	C168.031525,495.930145 167.729340,497.412079 166.727341,497.130768 
	C165.191269,496.699463 166.428329,494.781494 165.468277,493.495544 
	C165.251221,493.266113 164.771332,493.185852 164.732330,492.945709 
	C165.368515,492.012451 166.118057,491.887177 167.154114,492.652679 
	C167.581528,493.408112 167.889923,493.881989 168.145035,494.686646 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M179.666229,478.992249 
	C179.575607,480.054047 178.845322,480.801697 177.321991,481.021606 
	C176.998306,480.600037 177.000381,480.142944 177.001526,479.343506 
	C177.761993,478.997253 178.523376,478.993286 179.666229,478.992249 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M168.399109,494.774658 
	C167.879639,494.477203 167.535019,493.998840 167.273087,493.208069 
	C168.173615,493.166290 169.029724,493.409424 168.399109,494.774658 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M165.112640,493.125092 
	C165.013718,493.430756 164.776199,493.595398 164.538666,493.760010 
	C164.479279,493.508911 164.419876,493.257782 164.526917,492.856140 
	C164.693344,492.705566 164.974060,492.984070 165.112640,493.125092 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M162.213287,518.000732 
	C162.400024,517.877991 162.586426,517.936707 162.772842,517.995483 
	C162.586227,518.057739 162.399597,518.119995 162.213287,518.000732 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M154.648193,543.697998 
	C154.380508,543.743774 154.169342,543.573669 154.198334,543.505188 
	C154.399200,543.030762 154.627060,543.048096 154.648193,543.697998 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M153.406250,545.351074 
	C153.612000,545.334412 153.684708,545.418579 153.757416,545.502808 
	C153.596008,545.485840 153.434616,545.468872 153.406250,545.351074 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M208.199310,365.582611 
	C210.063110,364.650269 212.233047,365.919617 214.185822,364.025146 
	C214.770447,364.060822 215.128189,364.464966 215.750000,364.945496 
	C216.973495,365.815918 218.197327,365.345917 219.415894,366.090240 
	C219.582687,366.481964 219.542175,366.427124 219.575958,366.416687 
	C215.642441,369.624023 211.957245,366.984467 208.199310,365.582611 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M200.192657,380.286255 
	C197.771515,378.542816 198.090759,377.258820 201.501068,376.899109 
	C201.411987,378.032227 200.909454,379.021027 200.192657,380.286255 
z"
    />
    <path
      fill="#FBFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M202.984146,396.958008 
	C203.114944,397.127594 203.033920,397.538422 202.958130,397.733582 
	C202.654510,397.592773 202.426697,397.256775 202.198868,396.920807 
	C202.417023,396.876648 202.635193,396.832520 202.984146,396.958008 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M241.045044,295.385468 
	C241.558380,295.624939 241.855331,296.067841 242.127762,296.786621 
	C241.349197,296.985016 240.289688,297.180481 239.916336,296.762756 
	C239.419846,296.207214 240.426529,295.944550 241.045044,295.385468 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M246.645386,276.767517 
	C247.048172,276.954224 247.061417,277.177612 247.027313,277.733276 
	C245.492813,278.360229 245.967209,277.528900 246.645386,276.767517 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M213.018250,313.698517 
	C212.643723,313.808136 212.263474,313.669830 212.803223,313.060120 
	C212.977310,312.970917 213.001724,313.456146 213.018250,313.698517 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M318.167023,282.657318 
	C316.750549,282.283905 316.584564,280.417175 314.654999,280.022675 
	C313.052704,279.490021 313.299683,278.410950 313.175690,277.070618 
	C312.364288,275.391205 311.378967,274.106689 311.153625,272.700439 
	C310.593933,269.207764 309.151306,267.593079 305.447845,268.895508 
	C304.196503,269.335541 303.273163,268.416199 302.285156,267.210754 
	C299.743866,264.019379 302.626953,263.373352 304.206360,263.259674 
	C306.783295,263.074127 308.268280,261.730286 310.029907,260.178253 
	C312.328613,258.153076 314.827942,256.014374 318.431580,257.600891 
	C319.566498,258.100555 320.616608,257.189545 321.790833,256.271057 
	C324.717255,256.356506 326.478424,255.279861 327.244751,252.421951 
	C322.990540,251.971664 321.790161,248.039871 319.455505,245.441330 
	C318.454468,244.327148 318.735840,242.405365 320.362274,241.581467 
	C321.952972,240.775681 323.013885,242.170013 323.456329,243.299942 
	C324.304138,245.464935 324.907104,246.897766 327.948853,246.591721 
	C330.033173,246.382019 331.348297,252.431671 329.769287,254.679962 
	C328.742310,256.142273 327.063141,257.261292 327.890930,259.712585 
	C322.812927,262.438965 322.345520,264.000519 324.909180,268.074158 
	C326.176636,270.088196 324.864502,273.787292 323.967285,273.982544 
	C319.093048,275.043243 320.343079,279.716339 318.167023,282.657318 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M333.010620,236.072113 
	C336.503326,236.024139 338.619873,237.633957 340.493042,240.448776 
	C338.342590,240.886978 336.383850,241.276932 334.429413,241.687271 
	C332.835602,242.021896 330.969421,242.772018 330.240417,240.601929 
	C329.537018,238.507996 330.034729,236.656052 333.010620,236.072113 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M287.398163,259.020752 
	C286.928650,258.840790 286.841980,258.677643 286.767212,258.237488 
	C292.583710,256.540375 298.116852,253.980133 304.637329,253.913330 
	C301.233704,256.234528 297.411346,258.450470 293.265747,260.776245 
	C291.846313,258.526611 289.726379,259.025757 287.398163,259.020752 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M374.978149,237.370148 
	C376.454590,238.042374 377.884216,239.140762 379.359253,240.174362 
	C380.524994,240.991226 381.705536,242.052200 380.513092,243.338455 
	C379.418457,244.519180 378.595428,242.775543 377.340912,242.225372 
	C374.409668,241.588943 375.128113,239.462738 374.978149,237.370148 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M304.868988,251.654144 
	C303.230347,251.902954 301.593079,252.182037 300.038208,250.638199 
	C300.871033,249.513138 299.754486,247.481689 301.845612,247.077469 
	C302.313721,248.992035 303.826630,250.032959 304.868988,251.654144 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M355.610962,221.998230 
	C355.996490,222.164566 355.994263,222.332077 355.995544,222.750900 
	C354.551208,223.562454 353.133179,224.699875 351.516602,223.224426 
	C352.258636,221.233505 353.973938,222.305984 355.610962,221.998230 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M317.393921,238.965790 
	C317.101715,237.483063 317.717163,236.398514 319.652893,236.026443 
	C319.928558,237.498688 319.284637,238.563202 317.393921,238.965790 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M374.741699,234.887695 
	C375.007660,235.427643 375.002289,235.876282 375.012939,236.660858 
	C374.020294,236.912186 373.121368,236.624023 373.164490,235.398560 
	C373.195343,234.520599 373.825165,234.549026 374.741699,234.887695 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M321.996948,256.003052 
	C321.247681,255.826736 320.501465,255.647369 319.755249,255.468002 
	C319.881836,255.186951 319.931091,254.785553 320.148468,254.645828 
	C321.055084,254.063156 321.624725,254.451660 321.963135,255.696686 
	C322.006104,255.993286 322.000000,256.000000 321.996948,256.003052 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M305.309326,254.037445 
	C304.912811,252.697708 305.774811,253.249634 306.584229,253.573212 
	C306.370148,253.861771 305.985901,253.936630 305.309326,254.037445 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M336.000610,252.000885 
	C335.887024,251.868668 335.772827,251.735580 335.658630,251.602493 
	C335.737610,251.649399 335.816589,251.696320 335.948395,251.872223 
	C336.001221,252.001221 336.000000,252.000000 336.000610,252.000885 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M276.483337,236.458801 
	C276.541168,236.425201 276.425507,236.492416 276.483337,236.458801 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M279.997375,241.002014 
	C280.323029,241.094894 280.590576,241.275681 280.890717,241.783142 
	C280.659210,241.915970 280.400421,241.723297 280.105804,241.218323 
	C280.003937,240.994751 280.000000,241.000000 279.997375,241.002014 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M281.010010,241.992249 
	C281.135345,242.131516 281.250763,242.278778 281.366180,242.426041 
	C281.276825,242.381012 281.187469,242.335999 281.041077,242.155457 
	C280.984070,242.019928 281.000153,242.000244 281.010010,241.992249 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M290.401215,239.597412 
	C290.319153,239.501892 290.611908,239.459885 290.611908,239.459885 
	C290.611908,239.459885 290.483276,239.692932 290.401215,239.597412 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M167.662933,544.768250 
	C167.942749,547.755981 171.214493,548.188416 172.111908,550.748291 
	C170.487320,551.791382 168.562790,552.477478 166.850983,550.860779 
	C165.160385,549.264038 166.288666,547.583984 166.985016,545.594421 
	C167.103363,545.001587 167.225235,544.780579 167.662933,544.768250 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M168.033218,544.594299 
	C167.809525,545.005432 167.640305,545.033813 167.226288,545.142334 
	C166.661621,543.914368 166.448822,542.552368 165.084686,541.392944 
	C165.444977,540.727905 165.932159,540.375916 166.733582,540.010742 
	C168.602020,541.104248 167.749329,542.804871 168.033218,544.594299 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M163.628876,562.039917 
	C163.925797,563.328796 163.960724,564.740906 163.996323,566.578979 
	C163.580688,567.014893 163.164383,567.024841 162.437592,567.043091 
	C162.110474,566.861145 162.093842,566.670959 162.038788,566.197021 
	C161.642654,565.539795 161.511276,565.093628 161.786713,564.284058 
	C162.455292,563.376404 162.911072,562.769775 163.628876,562.039917 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M161.771439,564.118286 
	C161.999802,564.412354 162.000122,564.841736 162.000397,565.592163 
	C161.180893,565.543762 161.068878,564.979431 161.771439,564.118286 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M168.585846,567.248657 
	C168.833054,567.989258 169.227997,568.929504 168.155762,568.591064 
	C167.696198,568.446045 167.423111,567.710266 167.065308,567.242920 
	C167.471756,567.169678 167.878204,567.096497 168.585846,567.248657 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M164.001923,566.998718 
	C164.125412,567.139404 164.246994,567.281372 164.368591,567.423279 
	C164.280579,567.376099 164.192581,567.328918 164.050781,567.143311 
	C163.996994,567.004883 164.000000,567.000000 164.001923,566.998718 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M315.992218,785.557068 
	C312.952332,788.080688 311.916504,786.776611 311.945068,783.558411 
	C311.953491,782.609314 312.040039,781.448730 310.649231,781.375244 
	C310.291290,781.356323 309.944855,782.031250 309.521973,782.169312 
	C307.845551,782.716431 311.969208,784.972168 308.902893,784.617310 
	C307.635651,784.470703 305.386993,782.124756 305.608368,781.520935 
	C307.094666,777.467102 303.766266,774.316711 304.015503,770.197021 
	C304.913147,769.381287 305.737122,769.704163 306.872375,770.065308 
	C307.988922,770.457703 308.816254,770.697571 309.885498,771.197693 
	C310.714935,771.318054 311.273376,771.261230 312.251465,771.167114 
	C313.048645,771.217957 313.368378,771.407104 313.785950,771.926636 
	C313.900513,776.611511 313.957458,781.038940 315.992218,785.557068 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M310.291901,771.033325 
	C309.258881,771.958557 308.341522,772.218933 307.199890,771.358521 
	C306.491058,770.372070 305.670929,769.945435 304.532715,769.373169 
	C303.075287,768.750244 302.343872,767.933777 302.061554,766.359924 
	C302.248688,765.186890 303.031097,764.897095 303.807068,764.195557 
	C304.010498,763.989563 304.000366,764.000000 303.995239,764.004761 
	C304.551147,763.469055 305.215637,763.174072 306.369873,763.220215 
	C307.471283,763.540283 307.988403,764.059692 308.511230,765.038452 
	C308.601654,765.396301 308.883759,765.032593 308.729523,765.203430 
	C309.726349,765.461609 310.132965,766.375122 310.409943,767.510742 
	C310.281464,768.772339 310.354401,769.732117 310.291901,771.033325 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M308.690277,765.002869 
	C308.143219,765.087830 307.684814,764.779297 307.063324,764.245361 
	C305.795380,763.267700 304.923584,762.395752 305.447296,760.539062 
	C308.778778,760.141235 308.124298,762.845947 308.690277,765.002869 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M305.454285,760.141724 
	C305.960175,761.250671 306.318237,762.310364 306.788269,763.694946 
	C306.172821,764.013916 305.445374,764.007874 304.354004,764.005737 
	C304.153595,762.830994 304.317108,761.652344 304.745178,760.235229 
	C305.009766,759.996643 305.306396,760.092529 305.454285,760.141724 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M317.006592,772.242920 
	C317.897980,771.958801 318.691925,772.198669 318.938324,773.593628 
	C318.017548,773.919800 317.264465,773.629700 317.006592,772.242920 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M331.578766,786.044556 
	C331.193329,786.751343 330.804840,786.758179 330.460938,786.255615 
	C330.388977,786.150391 330.391632,785.906128 330.466370,785.803406 
	C330.823517,785.312683 331.211823,785.327087 331.578766,786.044556 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M310.403198,767.437134 
	C309.713684,767.195312 309.218872,766.578064 308.649658,765.667480 
	C309.864288,765.199829 310.818970,765.426147 310.403198,767.437134 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M353.967072,809.032349 
	C353.304565,808.943542 352.577393,808.914490 352.463043,808.086792 
	C352.437561,807.902283 352.797333,807.664551 352.979706,807.451355 
	C353.300262,807.764954 353.620789,808.078491 353.994385,808.671509 
	C354.047455,808.950867 353.994110,809.005432 353.967072,809.032349 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M351.448212,805.424194 
	C351.523712,805.371765 351.548645,805.578003 351.548645,805.578003 
	C351.548645,805.578003 351.372711,805.476562 351.448212,805.424194 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M313.802063,771.912781 
	C313.584351,772.005493 313.235291,771.840820 312.778687,771.403076 
	C313.004333,771.305054 313.337555,771.480225 313.802063,771.912781 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M272.328735,658.007141 
	C273.706085,657.995544 275.610931,657.464783 275.573273,659.313965 
	C275.531097,661.383972 275.077423,663.538513 274.305145,665.455811 
	C273.988007,666.243225 272.445343,666.123108 272.342224,664.635437 
	C272.196014,662.525757 272.190552,660.406311 272.328735,658.007141 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M296.740906,667.089600 
	C299.405884,668.166870 297.796387,669.376221 296.788910,670.104858 
	C295.131866,671.303162 295.048401,669.872131 295.028992,668.363770 
	C295.543396,667.758362 296.015991,667.479492 296.740906,667.089600 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M260.707275,657.939209 
	C260.039276,659.882141 259.118011,658.922729 258.332092,658.053772 
	C258.142761,657.844482 258.407379,657.224609 258.463196,656.793518 
	C259.136169,657.119263 259.809143,657.445007 260.707275,657.939209 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M255.999207,651.000427 
	C257.179504,651.616882 258.120972,652.393738 257.750122,653.915527 
	C257.718353,654.045898 257.177673,654.214661 257.005707,654.121521 
	C256.029755,653.592651 256.064148,652.603577 256.004822,651.333923 
	C256.001587,650.997559 256.000000,651.000000 255.999207,651.000427 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M276.063843,654.397217 
	C279.041473,653.044312 277.015717,655.687378 277.777771,656.606201 
	C276.333038,656.912598 276.130432,655.875061 276.063843,654.397217 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M268.750732,657.301025 
	C269.641083,657.112244 270.302216,657.116577 270.558258,657.835449 
	C270.630402,658.038025 270.402771,658.566406 270.249237,658.594116 
	C269.355652,658.755493 268.749817,658.351685 268.750732,657.301025 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M267.683533,683.956665 
	C267.827362,684.514099 268.030090,685.225525 267.335083,684.994324 
	C266.947174,684.865295 267.015900,684.146362 267.683533,683.956665 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M287.214233,661.140381 
	C287.632202,661.023987 288.020569,661.246460 288.408905,661.468872 
	C288.004822,661.949463 287.595428,661.798462 287.214233,661.140381 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M286.223877,659.318604 
	C286.301117,659.025696 286.598053,659.059631 286.746277,659.063171 
	C286.988892,659.450745 287.083252,659.834717 287.210754,660.510132 
	C287.243866,660.801575 287.064789,660.944824 286.975769,661.019043 
	C286.640076,660.599365 286.393372,660.105469 286.223877,659.318604 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M280.989502,660.985229 
	C281.081055,660.948242 281.185486,660.926575 281.161621,660.965454 
	C281.033386,661.025879 281.002380,661.000488 280.989502,660.985229 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M256.526031,656.543823 
	C256.478394,656.571045 256.573669,656.516663 256.526031,656.543823 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M251.995117,685.003845 
	C251.876663,684.869568 251.763107,684.731384 251.649536,684.593262 
	C251.732254,684.638855 251.814941,684.684448 251.952637,684.860107 
	C252.007629,684.990234 252.000000,685.000000 251.995117,685.003845 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M275.224365,631.216919 
	C275.866180,626.510986 273.168610,623.233215 268.617462,622.947021 
	C266.998291,622.845154 265.404633,622.337891 263.405151,622.006592 
	C262.674286,621.620728 262.456848,621.186707 262.416931,620.317810 
	C264.218903,617.103882 265.425140,614.027039 266.408875,610.488525 
	C268.850403,608.773865 272.178772,612.108154 274.046631,608.227051 
	C274.581268,606.485168 274.414551,604.777954 276.278992,603.700500 
	C276.773346,603.464294 276.982208,603.392151 277.520660,603.272156 
	C278.481567,603.208313 279.107635,603.286987 279.970398,603.311646 
	C280.204987,603.294739 280.518799,603.039734 280.518799,603.039734 
	C280.518799,603.039734 280.325012,602.664307 280.122070,602.465454 
	C279.292023,601.541138 279.106750,600.397034 277.720032,599.929138 
	C277.081848,596.944336 277.065308,594.400085 280.566315,593.704956 
	C280.858154,593.647034 281.324066,593.933472 281.411163,593.757812 
	C281.762238,593.049622 280.964417,592.637634 280.868256,592.057190 
	C280.247284,588.309814 277.208008,585.114502 278.579193,580.723389 
	C283.858337,578.683289 283.858337,578.683289 286.915558,585.532288 
	C284.731628,580.976013 283.456512,576.400879 284.789032,571.229980 
	C285.561310,568.233276 284.892120,565.192871 281.013977,563.736938 
	C280.543793,563.114502 280.382629,562.708984 280.312012,561.958435 
	C280.376678,561.260010 280.452698,560.874512 280.554749,560.221069 
	C280.528900,559.773621 280.492950,559.597351 280.359131,559.165039 
	C280.186981,558.737793 280.112701,558.566528 279.932190,558.130920 
	C279.624023,557.307007 279.494293,556.729919 279.373962,555.829041 
	C279.307343,554.391296 278.896576,553.347290 278.567902,551.901123 
	C275.973907,543.651184 274.543793,535.611328 274.472992,527.301758 
	C274.402924,519.082886 272.214478,510.915314 273.594940,502.663757 
	C274.638611,496.425446 274.604095,490.170990 274.400818,483.498138 
	C274.268402,482.841034 274.211517,482.581573 274.140594,481.929077 
	C274.267059,480.187103 274.903717,479.182434 276.490723,478.530212 
	C277.466858,478.454498 278.093842,478.618530 279.029419,478.673157 
	C280.243958,479.217285 281.041046,478.978577 282.104370,478.245056 
	C283.219055,478.219513 283.895905,478.490051 284.683075,479.390991 
	C286.057770,486.793457 285.539093,493.897919 285.625031,501.396973 
	C285.016144,506.520569 286.102295,511.041412 287.138733,515.567932 
	C288.174255,520.090210 289.446533,524.572815 289.400024,529.275085 
	C289.391785,530.105591 289.288147,531.104675 289.816315,531.689270 
	C293.618195,535.897461 292.930267,541.275696 293.613953,546.245239 
	C295.550171,560.319153 300.523468,573.432617 306.126740,586.731812 
	C306.402588,587.255554 306.488098,587.471985 306.631195,588.022400 
	C306.807709,589.398804 305.931335,590.273254 306.300171,591.624634 
	C306.474182,592.116089 306.533569,592.321411 306.609924,592.856079 
	C305.142426,597.957764 306.128601,602.310364 308.784363,606.351685 
	C308.174225,603.928528 307.150421,601.596802 306.548187,599.133240 
	C306.064484,597.154602 305.616486,595.153809 308.529327,594.107300 
	C313.411682,594.879150 314.147583,599.138306 315.979675,602.244080 
	C318.004425,605.676392 319.814117,609.144470 324.087921,611.523987 
	C323.640808,607.850525 320.443695,605.593994 320.814209,601.938904 
	C320.776581,601.103027 321.282196,600.429993 320.583435,600.111816 
	C319.989075,599.841248 320.856201,600.036743 320.063019,600.593811 
	C317.959381,600.563599 317.160919,599.752625 318.110748,597.638672 
	C321.364990,595.899841 320.764160,593.443604 320.155731,590.933716 
	C319.239716,587.154907 316.092712,583.253906 321.190887,579.845398 
	C322.323578,579.088135 321.068085,577.338135 320.241211,576.329468 
	C315.635559,570.711365 315.835175,563.569763 314.088135,557.052429 
	C313.788391,555.934204 313.998322,554.641602 315.129578,553.922729 
	C316.412079,553.107727 317.485687,553.819214 318.039490,554.899536 
	C318.966278,556.707581 320.622498,556.007935 322.335693,556.470825 
	C324.233673,557.700378 324.506500,559.396423 324.405029,561.030334 
	C323.968140,568.063965 326.114410,574.376892 329.544861,580.752686 
	C327.956696,582.934143 327.029144,584.552490 328.448944,587.253540 
	C329.693481,589.621277 330.469757,592.402466 330.739014,595.537231 
	C329.859406,598.658630 329.601440,601.372192 331.356110,603.946838 
	C332.040253,604.950562 332.072266,606.124817 332.095398,607.308472 
	C332.122253,608.682007 331.227448,610.985840 333.379608,610.931580 
	C336.028625,610.864746 338.701996,614.919250 341.368317,611.103394 
	C340.592926,609.655640 339.269287,612.053955 338.764221,610.680969 
	C337.153687,606.302551 333.508881,603.511597 330.759735,600.020874 
	C330.143158,599.238037 330.330139,598.106018 330.876404,596.820435 
	C332.083344,593.405212 330.079651,590.569946 330.280670,587.182007 
	C331.701782,583.626282 334.135559,583.577515 335.465576,586.226624 
	C339.224640,593.713806 347.509674,598.269348 348.658569,607.501465 
	C349.099548,611.045044 351.224518,614.300659 353.774475,617.077332 
	C355.462311,618.915222 357.070862,621.008362 357.881256,623.297546 
	C359.465332,627.772217 362.556549,630.793091 366.041229,633.708435 
	C367.767944,635.153015 369.915283,636.412231 370.415497,639.279785 
	C369.964386,640.321350 369.359863,640.687988 368.277405,640.905212 
	C367.705627,640.908142 367.480957,640.876892 366.954041,640.711914 
	C366.123535,640.828674 366.627747,639.638245 366.175781,640.319397 
	C366.408539,640.773254 366.425934,640.990540 366.400360,641.535950 
	C366.102875,644.111267 365.411987,646.240845 366.144440,648.642151 
	C367.231201,652.205322 365.747620,653.557129 362.114197,652.635681 
	C360.532013,652.234497 359.041168,651.483398 357.103455,651.022461 
	C356.574951,650.801270 356.372498,650.691040 355.916473,650.344849 
	C354.992889,647.944092 353.319794,646.968262 350.988464,646.275146 
	C350.503082,646.017822 350.317291,645.902954 349.877625,645.586426 
	C349.158539,644.948059 348.736908,644.469055 348.080872,643.785278 
	C347.753815,643.393127 347.637970,643.228088 347.406860,642.790894 
	C347.209686,642.158936 347.166779,641.795288 347.223816,641.197021 
	C347.263367,640.968262 347.362579,640.545044 347.362579,640.545044 
	C347.362579,640.545044 346.955933,640.350586 346.684998,640.265503 
	C344.069397,639.026794 341.821350,637.749023 340.295074,635.518250 
	C339.326141,634.102051 338.694366,631.815308 336.436707,632.617065 
	C334.253387,633.392456 335.499664,635.772522 334.899170,637.694641 
	C334.557190,638.365601 334.199829,638.636108 333.974579,638.981262 
	C333.613129,639.535156 333.938660,638.717041 334.080444,639.368408 
	C334.113312,639.519409 334.109467,639.378723 334.257660,638.763062 
	C335.438751,638.256409 336.196442,638.598633 337.089935,639.433838 
	C337.372681,639.868225 337.454712,640.057434 337.600006,640.539185 
	C337.739838,641.577881 337.864990,642.285828 339.138367,642.366699 
	C339.639130,642.464111 339.840088,642.521606 340.324341,642.729431 
	C344.911530,645.446350 347.304413,649.978027 351.325806,653.324097 
	C354.412567,657.450562 357.863617,660.747437 361.792908,664.039307 
	C362.737579,664.768982 363.285339,665.471375 364.414185,665.931519 
	C365.516541,666.431641 366.292542,666.847473 367.206146,667.655945 
	C367.763611,668.499878 367.888306,669.141785 367.777100,670.123474 
	C367.601837,670.717407 367.426361,670.940247 367.176941,671.088013 
	C366.886505,671.259949 366.704865,671.423950 366.635925,671.586609 
	C366.652161,670.920166 366.409424,671.618286 367.357727,671.557495 
	C367.992981,671.807861 368.257233,672.104675 368.545380,672.770264 
	C368.636078,673.300842 368.641510,673.520081 368.609741,674.067505 
	C370.589539,679.645813 375.850891,681.632446 379.566315,685.159912 
	C381.444489,686.943054 383.909332,688.171753 385.932495,689.864990 
	C387.462067,691.145081 390.302582,692.139404 389.074219,694.733643 
	C388.032471,696.933655 385.588654,695.826050 383.345673,695.518677 
	C382.573914,695.496155 382.139954,695.544617 381.393799,695.719727 
	C380.860138,695.826721 380.645325,695.863159 380.103943,695.920349 
	C379.342407,695.920959 378.917511,695.843933 378.216064,695.575073 
	C377.772583,695.296570 377.614563,695.159363 377.254883,694.789124 
	C376.596313,693.874207 376.018188,693.371277 374.855896,693.223328 
	C374.124481,693.089111 373.705872,692.987427 372.970703,692.757874 
	C371.575714,692.177246 370.615784,691.540649 369.588196,690.413574 
	C367.986176,687.104736 365.225220,685.061340 363.651917,682.136841 
	C362.548615,680.085876 360.885712,680.186462 359.079102,681.428772 
	C356.435364,683.246765 353.825073,682.983887 351.046265,680.889832 
	C350.595032,680.584412 350.420044,680.458191 349.991882,680.138306 
	C349.408203,679.689026 349.077240,679.434021 348.493896,679.001465 
	C348.081909,678.693970 347.921661,678.564880 347.540771,678.220459 
	C346.895325,677.549927 346.492950,677.074402 345.848572,676.379517 
	C344.670319,674.720886 343.993317,672.981323 341.573334,672.820984 
	C341.032623,672.761353 340.817047,672.733948 340.284302,672.633179 
	C339.347229,672.340393 338.800323,672.003418 338.171143,671.263000 
	C338.854248,667.253052 337.881531,664.794922 333.545471,665.385071 
	C330.164764,665.845215 329.942017,661.277344 326.844086,661.536377 
	C327.144562,657.618286 322.408905,657.439514 321.271057,654.196655 
	C320.893616,653.149048 320.608185,652.422729 319.796570,651.959534 
	C315.204010,649.338562 310.818329,644.617493 308.077942,638.481201 
	C306.580750,635.128723 303.057159,633.782227 301.370758,630.630188 
	C300.744263,629.459106 299.810303,630.172607 299.362183,631.825867 
	C298.373962,635.471619 299.546539,640.020081 295.192383,642.759155 
	C291.190430,643.178528 290.293915,642.637939 288.188202,638.925232 
	C289.697510,643.566772 289.117035,644.463867 284.201294,644.351318 
	C283.900024,644.081299 283.908569,644.037781 283.930573,644.040710 
	C283.616364,643.627136 283.482819,643.153503 283.764374,642.305176 
	C281.612885,638.094482 280.277527,633.362854 275.224365,631.216919 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M335.688202,681.452148 
	C335.738922,680.377380 335.673950,679.592163 335.608978,678.806946 
	C332.161407,679.633850 330.272980,675.550781 326.565826,676.078979 
	C325.782196,672.924316 325.898163,670.095825 330.601715,670.063354 
	C333.184937,671.292297 333.086548,675.498779 337.229980,674.550110 
	C338.019989,674.495728 338.462952,674.559021 339.196594,674.839355 
	C340.069855,675.317017 340.628265,675.675903 341.508240,676.151123 
	C341.968170,676.470825 342.139374,676.617554 342.499390,677.045959 
	C343.692078,679.593384 342.688446,682.887573 346.376953,684.093506 
	C346.862152,684.394226 347.042938,684.535522 347.420898,684.958618 
	C349.292450,688.914917 345.273346,689.426758 344.008484,691.851074 
	C343.038635,695.356506 341.835388,698.369812 339.297974,701.062256 
	C338.183319,701.717957 337.315155,701.630493 336.145081,701.306458 
	C335.661530,701.150208 335.472687,701.083557 335.005310,700.927246 
	C334.160400,700.701721 333.589539,700.657715 332.714172,700.805542 
	C332.000610,700.909241 331.583374,700.919434 330.838867,700.853333 
	C329.622162,700.578369 328.819733,700.180115 327.924866,699.284180 
	C327.701538,698.768860 327.682495,698.536438 327.825867,697.992920 
	C331.065002,695.881714 335.182159,695.905151 336.549408,691.224792 
	C337.275513,688.739319 335.232880,686.955322 336.175476,684.498535 
	C336.049835,683.767456 335.948578,683.359436 335.735718,682.658508 
	C335.618408,682.154724 335.595459,681.948181 335.688202,681.452148 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M283.776123,644.398193 
	C288.654449,644.648621 288.087067,641.288147 288.001312,637.931824 
	C290.282043,639.746094 291.801636,642.001343 294.652283,642.977661 
	C295.021301,645.050659 296.509735,645.814636 298.024475,646.520813 
	C300.138855,647.506348 300.245270,649.016235 299.487946,651.007996 
	C298.587463,653.376343 296.332733,651.840210 295.942047,651.575012 
	C292.250946,649.069580 290.362030,653.718262 287.180756,653.757690 
	C282.971191,653.378296 282.833191,653.348450 283.585052,649.870605 
	C283.967499,648.101379 282.145386,646.393860 283.776123,644.398193 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M320.166077,679.164429 
	C317.145844,684.788208 311.739197,681.232666 307.370544,682.895935 
	C305.897766,681.907043 306.836182,680.736389 307.304840,679.942200 
	C308.843201,677.335571 317.235962,676.711487 320.166077,679.164429 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M338.272400,674.795288 
	C332.330750,679.194214 332.460358,673.671570 330.994019,670.451782 
	C330.732025,669.570190 330.472290,669.055664 330.110870,668.264404 
	C332.795258,668.098206 334.065125,671.840637 337.628235,670.995972 
	C338.564972,671.161743 339.118286,671.339844 339.865784,671.741394 
	C340.610687,673.449951 339.478180,673.963074 338.272400,674.795288 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M312.375885,665.084839 
	C309.806732,662.321228 312.087189,659.300781 311.237518,656.216248 
	C315.189148,654.491089 314.369690,660.016479 317.191620,660.482361 
	C316.110901,662.405212 315.755585,665.030151 312.375885,665.084839 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M305.820312,656.779297 
	C305.520203,657.272156 305.092651,657.677307 305.046326,657.637512 
	C303.423889,656.247559 302.139008,660.726624 300.587952,657.912354 
	C300.325439,657.436035 299.010254,656.546387 300.289825,655.954224 
	C302.162598,655.087646 304.188843,654.488220 305.820312,656.779297 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M325.807129,680.190857 
	C325.940613,680.629211 326.241241,681.377441 325.157135,681.225220 
	C325.198059,680.797485 325.380981,680.526245 325.807129,680.190857 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M326.008728,679.279419 
	C326.418976,679.162598 326.848511,679.303223 327.278015,679.443848 
	C327.053528,679.629028 326.829041,679.814209 326.369141,680.020752 
	C326.098511,679.873718 326.063293,679.705261 326.008728,679.279419 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M320.998596,653.998230 
	C320.912354,654.032227 320.827515,654.067993 320.873169,654.053101 
	C321.003662,654.002441 321.000000,654.000000 320.998596,653.998230 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M329.993652,668.005066 
	C329.870239,667.870361 329.752930,667.730530 329.635590,667.590759 
	C329.721527,667.635315 329.807434,667.679932 329.951263,667.856201 
	C330.009186,667.987793 329.999786,668.000000 329.993652,668.005066 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M366.834717,714.798279 
	C365.686615,719.446655 370.423859,718.333618 371.914185,720.639526 
	C371.858673,721.172791 371.728729,721.338806 371.795593,721.752441 
	C372.006714,722.422058 372.020966,722.844116 372.049744,723.582520 
	C369.584473,726.816284 367.459503,722.234375 364.868774,723.842590 
	C364.416260,724.488159 364.110718,724.823425 363.485657,725.331360 
	C362.979431,725.619751 362.761658,725.707520 362.197296,725.859985 
	C360.075409,725.819580 358.323151,725.292053 356.900970,727.176025 
	C355.068207,729.603821 352.482239,731.128418 349.686310,732.341370 
	C346.377106,733.776978 346.334259,735.650879 349.123657,737.831726 
	C350.219421,738.688477 351.919861,739.428711 350.328613,741.500549 
	C349.731049,741.895813 349.370453,741.646667 349.044922,741.742371 
	C349.189026,742.066040 349.439941,742.108887 350.058289,742.047974 
	C351.136688,742.816528 349.265442,745.303833 352.639008,744.496094 
	C355.536530,743.802307 357.583435,747.855957 360.806824,749.133423 
	C361.623840,749.772766 362.087036,750.286255 362.802582,751.039795 
	C364.919312,753.557068 366.827393,755.828613 362.926208,758.409546 
	C361.309631,759.296448 360.049072,760.104980 358.769348,761.423462 
	C357.529633,762.772766 357.050812,764.197571 356.181396,765.768677 
	C353.516693,766.728516 350.279327,770.903870 350.113037,763.687683 
	C350.046295,760.791504 347.975220,756.318176 343.167175,755.773926 
	C340.525970,754.455933 340.525970,754.455933 338.244171,759.494385 
	C334.209045,761.185913 334.036682,761.068542 332.053284,755.359863 
	C334.803040,753.554749 333.160675,751.458313 332.907104,749.277039 
	C332.764618,748.788269 332.544006,748.564270 332.156769,748.174561 
	C331.977783,748.020996 332.000000,748.000000 331.989227,748.011597 
	C331.766785,747.763916 331.533051,747.526245 331.086304,747.147949 
	C329.870819,746.256165 327.662933,744.955139 328.256592,744.656982 
	C330.484894,743.537781 330.272675,739.833557 332.567078,739.965271 
	C334.984558,740.104065 336.098877,738.458374 338.490540,737.538330 
	C333.501068,736.640137 336.278229,731.476135 332.867279,729.282104 
	C329.500000,727.116211 332.494354,723.795715 333.170837,721.061096 
	C333.404602,720.115967 334.066010,717.548218 335.489563,719.298157 
	C336.704376,720.791443 340.930847,717.460815 339.508240,722.025085 
	C339.290192,722.724670 339.243530,723.390686 339.979858,723.683289 
	C340.332733,723.823486 341.178284,723.641479 341.242676,723.433044 
	C341.542328,722.463074 340.944855,720.664856 342.922607,721.327881 
	C344.585144,721.885376 342.831635,723.424561 343.387634,724.352051 
	C343.689941,724.856445 344.277679,725.221497 344.803680,725.538086 
	C344.938934,725.619568 345.577484,725.251160 345.564453,725.137512 
	C344.645813,717.104248 351.609680,720.526123 355.001892,718.545959 
	C353.948273,716.713135 353.447113,714.733643 354.875061,712.353271 
	C355.879700,710.885010 356.806000,709.838684 358.536652,709.270691 
	C361.807434,709.019836 363.072510,711.288086 364.770325,713.392517 
	C365.207062,713.939209 364.838257,714.723450 365.900208,714.581787 
	C366.325592,714.554382 366.498627,714.574280 366.834717,714.798279 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M337.837341,701.293640 
	C339.820648,698.387451 341.980713,695.850647 343.041840,692.212524 
	C343.871368,691.953003 344.091522,691.945557 344.638794,691.951904 
	C346.740723,692.166870 347.626953,691.041931 348.368988,689.692810 
	C349.574921,687.500122 351.395538,686.336731 354.277100,686.482422 
	C356.997223,688.892700 357.124908,692.265808 358.679657,695.358032 
	C360.091339,698.658936 355.937195,699.282898 356.188721,701.486755 
	C356.425873,703.565063 360.845337,703.427917 359.211487,707.066345 
	C357.634277,707.934021 356.530609,707.528076 355.285950,706.275208 
	C353.279419,705.805359 351.476959,704.521729 349.863098,706.210938 
	C348.105591,708.050415 350.231415,709.006592 350.773712,710.416077 
	C352.049164,713.731079 349.383820,713.997131 347.296051,713.923889 
	C344.154999,713.813538 341.091705,712.273865 338.611237,716.187134 
	C337.975800,717.189575 333.142822,718.077942 332.126282,713.939453 
	C331.669769,712.080811 330.729523,710.971130 328.335876,711.023621 
	C328.681152,707.504211 331.191864,704.425049 330.820374,700.343140 
	C331.510559,700.082458 331.930725,700.042786 332.666504,699.978882 
	C333.387848,699.614014 333.852051,699.487122 334.690308,699.777344 
	C335.006012,699.981323 335.003052,700.000000 335.011200,699.993958 
	C335.749176,700.675659 336.260864,701.745728 337.837341,701.293640 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M330.789917,699.970520 
	C331.449860,703.908691 332.047272,707.758362 328.150085,710.746399 
	C327.853882,711.307800 327.682434,711.537354 327.243927,711.803101 
	C326.369324,710.780884 325.417664,709.749695 325.068909,708.544189 
	C324.209808,705.574463 322.856781,704.192932 319.792358,706.056213 
	C318.196136,707.026733 317.905090,705.447144 317.095703,704.362671 
	C319.144562,699.883545 323.674530,700.429321 327.621216,699.023621 
	C328.826355,699.285278 329.657867,699.552124 330.789917,699.970520 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M355.001465,706.378906 
	C356.108795,706.319763 357.210297,706.627258 358.655884,706.971069 
	C359.613464,707.631714 359.724823,708.370422 359.270203,709.511475 
	C357.923340,710.536194 356.802399,711.235962 355.351013,711.973755 
	C355.012238,710.256348 355.003937,708.500916 355.001465,706.378906 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M323.327972,717.125244 
	C323.400787,715.089722 323.690613,713.109131 326.636566,713.001343 
	C328.717255,716.671631 327.666199,718.142334 323.327972,717.125244 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M330.427948,749.516968 
	C330.370087,749.419678 330.608765,749.379944 330.608765,749.379944 
	C330.608765,749.379944 330.485809,749.614319 330.427948,749.516968 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M332.019470,748.282593 
	C332.264832,748.184265 332.551849,748.347595 332.918243,748.755493 
	C332.685455,748.848083 332.373291,748.696167 332.019470,748.282593 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M330.983337,747.254517 
	C331.219421,747.146912 331.543579,747.308105 331.923096,747.746277 
	C331.676117,747.856628 331.373749,747.690002 330.983337,747.254517 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M257.823730,706.390991 
	C260.093109,704.807800 262.264832,703.765381 261.192474,700.207825 
	C260.702057,698.580811 261.455444,696.347229 263.664429,695.066772 
	C263.633667,698.286621 265.625061,701.051025 265.955505,704.550171 
	C264.806915,704.923218 263.592621,704.748840 263.247070,706.616943 
	C261.485809,706.507446 259.486908,711.343018 257.823730,706.390991 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M264.261169,694.827698 
	C264.009796,694.260193 264.003693,693.504456 263.998779,692.370544 
	C265.090790,692.698364 265.113190,693.608704 264.261169,694.827698 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M257.461487,686.525146 
	C257.434967,686.478088 257.487976,686.572144 257.461487,686.525146 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M336.746216,682.750366 
	C339.038574,687.265808 338.068665,691.778809 336.188751,695.555237 
	C335.271454,697.397949 331.488373,697.799072 328.348328,697.894104 
	C323.309021,701.386475 321.834778,701.353027 319.055176,697.389832 
	C319.995544,694.719055 320.197968,692.525024 318.095642,690.278625 
	C317.885559,689.993103 318.052094,690.081116 318.146240,690.078613 
	C318.344208,689.879822 318.448029,689.683655 318.759338,689.747803 
	C320.376099,690.293274 321.785309,690.578308 323.556580,690.872437 
	C326.449768,693.350159 329.039124,694.807922 331.752533,691.150940 
	C332.593109,690.018066 334.230591,688.916931 333.063141,687.295776 
	C332.075195,685.923889 330.649902,686.861755 329.426727,687.301880 
	C328.786865,687.532166 328.142853,687.602844 327.216400,687.185303 
	C326.046600,681.299316 330.247406,682.443848 334.010437,682.091125 
	C335.283478,682.138062 335.845734,682.466492 336.746216,682.750366 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M326.674561,687.063293 
	C329.453613,687.472168 330.815338,682.788330 333.885529,685.520447 
	C335.444885,686.907959 336.535675,688.100952 334.555298,689.901184 
	C332.486664,691.781799 331.509216,694.948425 327.846954,694.933350 
	C325.390625,694.923279 324.065369,694.173340 323.943726,691.269775 
	C324.063416,690.601013 324.259186,690.362244 324.712158,690.056641 
	C325.420197,689.606384 325.745514,689.162476 325.652466,688.252563 
	C325.842010,687.706604 326.120117,687.435730 326.674561,687.063293 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M313.806885,687.541992 
	C312.936493,689.232849 311.863861,689.203125 310.404541,688.082153 
	C310.036194,686.998901 309.691742,685.703552 310.786865,685.450806 
	C312.037476,685.162170 313.203278,685.897461 313.806885,687.541992 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M335.585785,681.026978 
	C335.803864,681.162598 335.848602,681.568054 335.874634,681.770386 
	C335.531494,682.334290 335.087402,682.470398 334.277405,682.199341 
	C333.976166,681.986816 333.997009,681.998779 334.006714,682.006348 
	C334.060486,681.441895 333.144653,680.758545 334.087158,680.346191 
	C334.378693,680.218567 334.933472,680.692688 335.585785,681.026978 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M325.384369,688.173584 
	C326.969543,688.633423 327.201324,689.282837 325.235748,689.934998 
	C325.013885,689.421997 325.109375,688.895813 325.384369,688.173584 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M319.123474,689.768982 
	C318.966888,690.008179 318.601044,690.031250 318.420715,690.053650 
	C318.077057,689.282166 317.768341,688.389771 318.932465,688.133118 
	C319.298279,688.052551 319.743774,688.333618 320.152802,688.449219 
	C319.861877,688.809448 319.570953,689.169617 319.123474,689.768982 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M318.666748,696.985168 
	C321.369720,700.465027 324.316467,699.237366 327.619141,698.016235 
	C327.984741,698.155029 327.989319,698.327637 327.994354,698.759277 
	C325.385895,702.452026 320.631805,701.222839 317.287750,703.940186 
	C316.951416,704.090820 316.491394,703.944458 316.258301,703.880676 
	C315.325165,703.497559 317.388458,700.580933 314.324188,702.217712 
	C312.814880,703.023987 311.993378,700.864441 311.160522,699.347046 
	C313.408844,697.953613 314.887695,694.087769 318.666748,696.985168 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M314.558228,708.448120 
	C314.604553,708.520325 314.415222,708.543091 314.415222,708.543091 
	C314.415222,708.543091 314.511871,708.375916 314.558228,708.448120 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M303.674011,763.988892 
	C304.037415,764.964355 303.767090,765.738770 302.349487,765.990540 
	C302.018555,766.018127 302.000824,766.000793 301.993103,765.993042 
	C301.615601,765.594543 301.476349,765.133972 301.787659,764.296021 
	C302.008148,763.988281 302.001373,763.997986 301.996826,764.002563 
	C302.440735,764.000793 302.889130,763.994507 303.674011,763.988892 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M279.242493,580.678833 
	C279.439697,585.314758 281.309723,589.184937 282.823608,593.184448 
	C283.205383,594.193054 284.666199,595.744202 282.927032,596.626221 
	C281.048828,597.578857 281.381775,594.966675 280.040863,594.351685 
	C279.353180,596.065369 277.912750,597.420959 278.067749,599.833496 
	C278.944763,601.138672 279.037842,602.200195 278.270386,603.672119 
	C277.994598,603.997803 277.499146,603.996704 277.251404,603.997131 
	C276.194092,603.966797 275.377533,604.042542 274.294464,603.737305 
	C273.297699,603.289856 272.683533,602.797852 271.838318,602.154602 
	C271.358856,601.742859 271.120819,601.481201 270.789185,600.990234 
	C270.663025,600.789917 270.273254,600.579468 270.108276,600.765686 
	C268.451447,603.082520 267.197327,601.741455 265.726807,600.440857 
	C265.449371,600.066101 265.363739,599.930176 265.290955,599.787659 
	C265.218231,599.645325 265.158447,599.496277 265.092987,599.350220 
	C264.146454,600.992615 263.021515,602.487000 261.331299,603.932861 
	C261.032715,604.382019 260.916443,604.561829 260.613342,604.999146 
	C259.873993,605.884949 259.249573,606.430969 258.263428,606.998962 
	C257.974182,607.103943 257.486328,607.059143 257.243469,607.029541 
	C256.329681,606.370178 255.869568,605.647339 255.984787,604.344604 
	C255.947510,600.763794 254.307877,599.774170 251.769318,601.674744 
	C248.968048,603.772095 245.661514,603.407837 244.257034,600.123230 
	C242.455719,595.910522 240.106308,592.086182 237.706314,588.195679 
	C235.872421,585.222839 234.177719,584.863037 231.540039,585.338013 
	C230.333420,585.555359 229.246368,586.014893 228.526642,584.651978 
	C227.925598,583.513916 228.792618,582.797424 229.443542,582.066345 
	C231.307571,579.972656 232.607300,578.858154 230.224640,575.238953 
	C228.027817,571.901978 226.779633,566.717102 228.291336,561.680786 
	C228.885513,559.701294 230.451584,555.072815 224.587921,555.452576 
	C222.036606,555.617859 221.170242,552.585815 220.392014,550.506470 
	C219.408066,547.877441 221.532196,548.900452 222.840973,548.527405 
	C224.783493,547.973694 222.946747,546.739746 222.831406,545.782715 
	C222.424225,542.404175 220.717361,539.138428 221.968140,535.310608 
	C225.058350,533.179260 227.180023,534.736023 229.214020,537.112549 
	C229.325592,535.564148 228.097824,534.907288 227.401398,533.587891 
	C226.243210,531.139038 228.336151,528.748047 226.554016,526.339172 
	C226.266312,525.553223 226.187790,525.079468 226.182587,524.228027 
	C226.507172,522.140991 226.958008,520.476257 228.241669,518.752747 
	C229.451447,517.689819 229.798767,516.631714 229.919083,515.426392 
	C230.099167,513.622620 230.926971,512.335144 232.809006,511.820465 
	C234.893845,511.250366 235.814316,512.667236 236.839905,513.985229 
	C237.349335,514.639893 237.952927,515.216553 238.629669,516.223511 
	C239.549744,520.806824 238.068802,525.004456 238.538666,529.616699 
	C241.359573,535.904053 238.656586,542.408447 240.509277,548.445984 
	C241.335739,551.139282 241.858826,553.912659 243.817444,556.117310 
	C244.843521,557.272278 245.387161,558.743958 245.319626,560.241821 
	C245.111664,564.854431 245.697327,568.525269 251.833801,567.036743 
	C252.179550,566.952881 252.335846,567.747498 252.706512,568.445007 
	C250.706436,562.617126 249.660385,556.949646 249.786560,550.763062 
	C250.879837,548.540222 250.172836,547.020752 248.682419,545.319275 
	C248.469620,544.772339 248.417801,544.539856 248.361511,543.948059 
	C249.871918,539.537109 246.312012,535.578491 248.367737,531.239014 
	C248.810226,530.837708 249.020645,530.718567 249.589798,530.563721 
	C251.312958,530.801331 252.145996,531.518005 252.273254,532.238892 
	C250.736938,528.704346 251.887405,523.971313 249.747437,519.777161 
	C248.978622,518.270325 249.898087,516.872559 250.466827,515.518311 
	C251.908615,512.085327 251.647400,508.832245 249.724670,505.484039 
	C246.286057,507.011017 245.818237,511.671234 241.511017,512.181885 
	C240.919540,512.229492 240.681870,512.235168 240.088104,512.224487 
	C234.368912,510.973541 234.110748,510.187531 237.760986,506.207184 
	C242.739456,500.778503 242.720596,493.950745 237.564148,489.133545 
	C235.951981,487.627441 235.481567,486.417511 236.965805,484.518799 
	C239.254074,481.591644 238.890976,479.879730 234.673492,479.686859 
	C231.965378,479.563019 231.017227,477.215088 230.281662,474.892792 
	C228.692596,469.875916 228.424606,464.489349 226.007843,459.352600 
	C226.008835,458.252106 226.017670,457.504181 226.031555,456.382324 
	C226.863724,453.738342 228.485779,451.852539 229.058716,449.437988 
	C229.629669,447.031769 231.376709,446.087860 234.114105,446.400146 
	C234.863281,447.064697 234.416199,446.326660 234.821838,446.853790 
	C235.118332,447.239044 235.427368,447.596527 235.450043,448.109619 
	C235.434723,447.723419 235.277496,447.391724 234.811005,446.823303 
	C233.097321,444.852264 231.710510,443.114716 232.779083,440.337128 
	C234.791092,438.211121 233.683029,435.912354 234.002289,433.420715 
	C235.932724,431.251862 236.166611,428.838318 236.773682,426.238586 
	C237.104095,425.677216 237.293106,425.419189 237.773560,425.103455 
	C238.829041,424.248077 239.795166,423.934387 241.273743,424.263855 
	C242.051636,424.671387 242.364868,425.033691 242.580048,425.849976 
	C242.534531,427.353333 241.463501,428.496887 242.724243,429.950287 
	C244.669693,432.811371 241.883087,435.642548 243.335999,438.587799 
	C243.393204,439.593384 243.403976,440.266113 243.431519,441.275665 
	C243.532654,443.458130 244.226715,445.074097 245.482956,446.843933 
	C247.000870,449.885040 243.476486,452.397888 245.511215,455.443115 
	C248.889618,458.626251 248.391953,462.456329 248.427094,466.546265 
	C248.451050,468.762451 248.541153,470.591827 247.591232,472.626038 
	C247.008667,473.419739 246.483093,473.731934 245.963043,474.075104 
	C245.414627,474.437042 245.371277,475.021759 245.720230,474.453705 
	C246.069183,473.885651 246.213165,475.182465 246.455444,474.470245 
	C250.548401,474.871826 249.356354,479.089020 251.780426,480.968201 
	C252.503845,481.573151 252.885437,482.052368 253.262238,482.839783 
	C253.353241,483.420319 253.226669,484.056580 253.772720,482.948120 
	C254.726028,478.557343 255.920425,474.509186 255.752899,469.895447 
	C256.526062,467.145294 257.457428,464.751617 255.936661,461.927277 
	C255.578400,459.830414 255.519135,458.048248 254.797211,455.985840 
	C254.521103,453.451874 254.613907,451.300629 254.580811,448.750824 
	C255.330688,439.171112 258.953461,430.831360 262.232178,422.402649 
	C263.066498,420.257843 264.145660,418.208862 264.798401,415.590424 
	C269.728577,408.780304 273.585571,401.890808 274.839722,393.787567 
	C275.125519,391.940979 276.053986,390.343811 277.088074,388.827698 
	C278.222778,387.164001 279.728455,384.907135 281.742371,386.184784 
	C283.673309,387.409790 284.442780,390.400391 283.000580,392.458099 
	C277.246063,400.668396 276.039795,410.675873 271.899109,419.499420 
	C271.529968,420.286011 271.504303,421.089508 271.445129,421.917175 
	C271.181671,425.602173 272.443054,429.378418 270.589966,432.998962 
	C270.210144,433.741058 271.168091,434.838623 271.559692,436.150085 
	C270.307190,443.791595 268.639832,450.983398 266.784332,458.147430 
	C264.538391,466.818970 263.807495,475.731598 263.467010,484.667664 
	C263.221008,491.124390 263.987762,497.611847 262.778259,504.043823 
	C262.433838,505.875366 262.938538,507.656860 264.079285,509.619293 
	C264.797943,512.923401 264.376801,515.908386 264.482300,518.880066 
	C264.552948,520.870911 264.225922,523.096802 265.378021,524.705811 
	C269.200745,530.044739 268.845917,535.845764 267.904816,541.831421 
	C267.724762,542.976562 266.965027,544.366089 269.503601,544.776611 
	C270.989563,545.016907 271.143494,547.784485 271.351562,549.610901 
	C271.796722,553.518494 271.116699,557.398865 271.475403,561.133972 
	C272.109955,560.465393 270.775726,558.752869 272.265442,558.090576 
	C272.559479,558.045410 273.020813,558.042664 273.140656,558.308228 
	C276.321167,565.355713 278.953094,572.565918 279.242493,580.678833 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M227.740143,532.782837 
	C231.502869,534.542419 231.437195,537.261841 230.393509,540.982544 
	C228.047104,538.241394 226.556259,534.888062 222.343231,535.037048 
	C217.978226,535.028076 216.315399,533.498108 217.926117,529.318115 
	C218.479614,527.881775 218.289612,526.158875 218.719330,524.283447 
	C220.519562,522.646179 221.677307,523.368713 222.763626,524.636841 
	C223.721512,525.755005 224.051895,524.666931 224.787384,523.879883 
	C225.502228,523.496643 225.962509,523.481079 226.715485,523.794189 
	C227.002396,524.445557 227.002365,524.886169 227.001266,525.657349 
	C228.935745,528.050415 225.925293,530.472778 227.740143,532.782837 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M217.234985,521.117432 
	C217.020691,520.836060 217.017883,520.668823 217.007233,520.250793 
	C215.614349,516.455933 217.443497,515.753967 220.265854,516.694946 
	C222.655914,517.491882 224.559113,519.110413 224.481964,522.436951 
	C222.355103,524.418091 220.593872,522.721375 218.366882,522.225159 
	C217.894073,521.690430 217.708328,521.433411 217.234985,521.117432 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M218.112366,522.259949 
	C219.833939,521.820435 221.556885,522.260315 223.622681,522.953491 
	C224.469421,522.814026 224.847672,522.938477 225.074554,523.664917 
	C224.690689,525.495911 225.911819,527.836182 223.942337,528.326538 
	C221.938095,528.825562 222.284897,526.400879 221.773117,525.144775 
	C221.399155,524.226807 220.592041,524.102417 219.377518,524.012085 
	C218.739960,523.505798 218.480515,523.011658 218.112366,522.259949 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M212.881470,584.682495 
	C208.637634,585.494934 208.107712,583.121826 209.009628,579.325317 
	C210.274200,580.766052 211.524933,582.565552 212.881470,584.682495 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M250.747864,614.121582 
	C251.090302,616.117493 251.171310,618.235291 251.142136,620.671265 
	C246.143951,619.121399 249.743881,616.566956 250.747864,614.121582 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M255.713013,604.164673 
	C256.876770,604.551392 256.954987,605.428101 256.986511,606.665283 
	C256.079926,606.617554 254.801575,608.284180 254.176987,606.624390 
	C253.784409,605.581116 254.669205,604.905212 255.713013,604.164673 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M239.481293,609.782104 
	C240.881439,609.396606 241.331223,609.937012 241.057281,610.921692 
	C240.956894,611.282593 240.344116,611.764709 239.991714,611.743713 
	C238.795151,611.672607 238.885239,610.830566 239.481293,609.782104 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M248.515137,608.436218 
	C248.574280,607.972961 248.807800,607.689697 249.041290,607.406433 
	C249.216614,607.590149 249.391922,607.773926 249.567230,607.957642 
	C249.274643,608.177124 248.982071,608.396667 248.515137,608.436218 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M215.366821,564.931458 
	C215.633942,565.193481 215.742538,565.578186 215.797440,565.970337 
	C215.806534,566.035156 215.580780,566.132935 215.463562,566.215454 
	C215.372482,565.829163 215.281403,565.442871 215.366821,564.931458 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M215.004059,539.996338 
	C215.424484,540.117432 215.841049,540.242126 216.257629,540.366821 
	C215.943497,541.047546 215.587646,540.679382 215.115250,540.207397 
	C214.994873,540.007324 215.000198,540.000000 215.004059,539.996338 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M222.406006,569.746460 
	C222.556396,569.866333 222.577255,570.070312 222.598129,570.274353 
	C222.490906,570.126404 222.383698,569.978516 222.406006,569.746460 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M227.215637,650.851929 
	C226.317184,650.572510 226.005630,650.047424 226.772400,649.149536 
	C227.674271,649.427124 227.998947,649.948242 227.215637,650.851929 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M233.526596,648.543945 
	C233.476730,648.572327 233.576462,648.515564 233.526596,648.543945 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M276.471832,645.123901 
	C273.992493,645.206482 271.425079,645.895691 271.312988,642.633362 
	C271.286102,641.851318 271.399170,639.768738 272.646881,639.927429 
	C274.549774,640.169434 276.639343,640.891785 278.092224,642.087524 
	C279.191071,642.991821 278.342407,644.498352 276.471832,645.123901 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M283.665314,641.994019 
	C283.979309,642.444336 283.981628,642.901123 283.968262,643.700806 
	C283.468262,643.767395 282.983917,643.491150 282.257111,643.112915 
	C282.014648,643.010864 282.054474,642.514954 282.082520,642.267883 
	C282.524902,642.014038 282.939301,642.007263 283.665314,641.994019 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M281.993652,642.991943 
	C281.898468,643.029724 281.809296,643.075073 281.867371,643.065674 
	C282.014648,643.010864 281.999634,642.999512 281.993652,642.991943 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M209.987350,598.006836 
	C210.083145,598.140564 210.192154,598.264648 210.301178,598.388794 
	C210.231812,598.336365 210.162476,598.283936 210.057068,598.101562 
	C210.020996,597.971558 210.000565,597.997253 209.987350,598.006836 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M215.441193,597.536987 
	C215.400665,597.471497 215.481705,597.602478 215.441193,597.536987 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M265.997284,626.357910 
	C267.438690,626.088074 268.014038,626.931458 267.989410,628.653931 
	C266.568848,628.899658 265.980927,628.069275 265.997284,626.357910 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M258.012054,607.427979 
	C258.073395,606.188416 258.289642,605.353088 259.673706,605.048584 
	C260.230042,603.617920 259.159729,601.626465 261.997223,601.545898 
	C263.236664,601.510681 263.536163,600.051575 263.692505,598.902222 
	C263.819519,597.968628 262.424652,596.360413 264.456512,596.296143 
	C266.383453,596.235168 265.856476,598.065369 266.016510,599.644897 
	C267.580048,600.980469 267.944916,602.872131 269.320099,604.387817 
	C269.848541,605.867554 269.529388,606.859436 268.401611,607.909912 
	C267.972687,608.238342 267.793427,608.357361 267.329590,608.622559 
	C266.448364,608.973145 265.824341,609.098022 265.012878,609.519775 
	C264.573212,609.848694 264.207428,609.874756 264.101654,610.028503 
	C263.726349,610.574280 264.479736,610.887512 264.126343,610.322205 
	C263.772949,609.756897 265.006531,609.737549 264.649933,609.914673 
	C265.206085,609.562073 265.571716,609.458130 266.478882,609.708191 
	C267.014893,613.619995 266.813690,617.165161 263.319519,619.836914 
	C262.614502,620.380371 262.149994,620.528870 261.294983,620.228882 
	C260.494019,619.732178 260.009399,619.452393 259.269684,619.055664 
	C258.259460,618.364441 258.009918,617.572632 258.042206,616.339355 
	C257.868103,615.157288 257.687531,614.298279 257.250427,613.215271 
	C258.064728,611.389709 259.517822,609.865234 258.012054,607.427979 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M257.790588,616.179443 
	C258.881561,616.557312 258.992523,617.401123 259.007996,618.613281 
	C256.521027,619.214905 256.002289,618.363647 257.790588,616.179443 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M257.001709,613.002502 
	C255.869904,614.243469 254.865189,614.414429 254.051468,612.321167 
	C254.809708,612.236328 255.610657,612.483643 256.702759,612.861084 
	C256.993896,612.991272 256.999725,612.999695 257.001709,613.002502 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M261.007172,620.304443 
	C261.428955,620.008301 261.879272,620.004517 262.667236,620.000366 
	C263.005493,620.443909 263.006104,620.887878 263.008850,621.664673 
	C262.834930,621.999146 262.658844,622.000671 262.218170,622.004517 
	C261.647583,621.536865 261.341644,621.066895 261.007172,620.304443 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M253.995483,612.003601 
	C253.871536,611.855957 253.752396,611.704468 253.633240,611.552917 
	C253.724991,611.602051 253.816757,611.651123 253.958633,611.844604 
	C254.008759,611.988953 254.000290,611.999695 253.995483,612.003601 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M211.074951,623.187439 
	C211.009109,623.025879 211.499069,622.973572 211.713272,623.095276 
	C211.665237,623.260986 211.403015,623.304993 211.074951,623.187439 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M606.188904,756.842407 
	C605.477661,759.867615 602.651367,760.832642 600.805603,760.614319 
	C595.179749,759.948792 592.583740,764.212891 590.090820,767.338928 
	C586.446899,771.908386 581.900513,773.609924 576.350098,774.861694 
	C575.675049,775.081970 575.366089,775.215698 575.046082,775.295593 
	C573.290466,775.733826 570.962585,776.548889 570.136353,774.648865 
	C569.250061,772.610840 571.649353,771.697266 573.107422,770.668945 
	C574.027283,770.020264 575.176025,769.596863 575.654541,767.705994 
	C572.039978,770.198608 568.700745,772.239441 564.675110,772.779663 
	C563.312744,772.962463 563.894470,773.844727 564.918335,774.651978 
	C565.430847,775.635620 565.228333,776.308594 564.669678,777.262451 
	C561.008728,780.793091 557.407776,783.682556 552.284668,783.565125 
	C551.048157,783.536804 549.931213,783.555298 549.509766,781.776917 
	C550.132935,780.096313 551.455383,779.954468 552.808472,779.543945 
	C552.983582,779.515747 553.103577,779.478149 552.924744,779.507446 
	C551.400574,779.836182 550.048889,780.124023 549.084961,781.751404 
	C543.064697,787.542542 535.915222,790.844543 528.398621,792.859009 
	C523.027222,794.298523 518.099548,797.828674 511.541992,796.418335 
	C512.593140,792.503845 516.249329,792.550232 519.127441,791.276001 
	C523.673584,789.263184 528.240173,787.316650 532.501953,784.711365 
	C534.181213,783.684692 535.941772,783.309570 538.131226,783.634766 
	C539.633484,783.858032 543.233704,784.319519 541.988037,780.330200 
	C544.782349,779.548706 546.806641,777.519775 549.116577,775.948364 
	C553.168274,773.192139 557.244812,770.472534 561.503357,767.608215 
	C558.007507,764.569641 555.183228,768.167053 552.432251,768.746765 
	C549.037048,769.462463 545.782959,771.530212 542.149414,772.013855 
	C540.025085,772.296692 540.029419,773.734985 539.967651,775.674438 
	C539.215088,776.673645 538.296143,776.384399 537.243042,776.814392 
	C537.000916,777.024292 536.999268,776.997559 537.010254,777.005188 
	C536.570068,777.423157 535.974854,777.474854 535.209839,777.839966 
	C534.989807,778.028381 534.993652,777.991211 535.011597,777.996460 
	C534.679993,778.314636 534.274841,778.495544 533.466797,778.474854 
	C529.018555,778.550842 525.588440,780.376160 522.179810,783.004150 
	C517.346863,786.783386 511.162872,785.757141 506.196259,788.717773 
	C504.345245,789.821167 501.448608,787.833130 500.554321,785.621338 
	C499.456207,782.905273 497.597107,783.005981 495.385956,782.984253 
	C489.112732,782.922607 482.980377,784.064453 476.399414,784.990234 
	C474.634186,784.372742 473.303864,783.678162 471.771179,783.741150 
	C470.678284,783.786011 469.099823,783.088623 469.335815,782.562195 
	C471.535522,777.655151 465.560303,775.865967 465.560455,771.559570 
	C466.488129,769.791260 467.839874,768.909668 469.344208,769.094421 
	C475.864197,769.895630 481.577911,766.793396 487.663544,765.527588 
	C489.766235,765.090210 491.876099,764.240417 493.583282,766.890381 
	C493.667358,767.920227 493.669189,768.789490 493.076172,769.035095 
	C489.718903,770.425171 488.275665,773.333191 487.087128,776.491943 
	C490.781036,777.013367 494.429474,776.185730 498.121582,775.436035 
	C500.563110,774.940247 501.319519,773.516541 501.456116,770.987305 
	C502.494812,769.498169 503.739960,768.880798 505.092682,769.314270 
	C509.979675,770.879944 514.248718,770.754822 519.337036,768.470947 
	C526.341614,765.327026 534.282776,764.348816 541.528748,761.436157 
	C544.670166,760.173401 547.272095,757.252747 551.481079,758.302979 
	C552.756531,761.382080 554.791870,761.783081 557.725220,760.792236 
	C558.681091,760.642883 559.319580,760.609375 560.232605,760.391113 
	C561.103333,760.048584 562.467224,760.440552 561.669556,758.681274 
	C563.476440,755.836975 565.291687,753.353516 568.025085,751.242615 
	C571.906311,750.043335 575.615173,749.412903 579.237549,747.425415 
	C580.405396,746.924988 581.308167,746.717529 582.472168,746.222656 
	C586.761536,743.678101 587.404053,740.184265 585.982178,735.706055 
	C585.788452,734.046692 585.072205,732.614136 586.917236,731.504517 
	C587.471924,731.428833 587.697388,731.436646 588.249634,731.505737 
	C591.420532,731.364136 592.513428,729.252075 592.787903,726.975586 
	C593.104858,724.346375 594.262024,722.335999 596.166565,720.227966 
	C596.603760,719.876526 596.798584,719.760925 597.323730,719.583252 
	C598.285583,719.542480 598.929199,719.652161 599.741577,719.051392 
	C601.820312,717.699097 603.233582,718.251221 604.455261,720.230774 
	C604.594360,721.062073 604.592529,721.596741 604.198914,722.045776 
	C604.392578,722.192810 604.851624,722.366577 605.092407,722.447388 
	C605.507996,722.556213 605.682800,722.584167 606.132324,722.580200 
	C607.660156,722.126953 608.571289,721.283691 609.514160,719.956970 
	C611.670166,718.016235 614.489563,717.453369 615.982666,714.680176 
	C616.465881,714.055847 616.798462,713.749756 617.463257,713.305786 
	C621.604431,712.029541 623.927673,709.343506 625.567261,705.493286 
	C625.828613,705.012024 625.940186,704.826355 626.246460,704.386536 
	C626.735718,703.835754 627.057434,703.572571 627.662598,703.187988 
	C628.248718,702.818604 628.564026,702.584229 629.045654,702.091431 
	C629.575256,701.364258 629.915283,700.866272 630.475342,700.154419 
	C630.987000,699.680969 631.323792,699.481628 631.968994,699.296143 
	C632.560791,699.269775 632.994080,699.387634 633.083801,698.986938 
	C633.228577,698.341064 633.280823,699.229736 632.745361,698.550415 
	C632.826660,698.103149 632.911987,697.932678 633.190674,697.544067 
	C633.986633,696.810730 634.599487,696.304932 635.135681,695.313354 
	C635.523682,694.684937 635.770874,694.339844 636.247253,693.760193 
	C636.763672,693.204407 637.082153,692.910522 637.664429,692.425659 
	C638.824646,691.629028 639.579346,690.906494 640.076111,689.551086 
	C640.431213,688.877991 640.664551,688.510193 641.122803,687.897217 
	C641.614075,687.306274 641.918945,686.990051 642.482117,686.464478 
	C643.236206,685.816101 643.791748,685.454773 644.628540,684.915405 
	C648.976746,681.572388 651.778870,676.994690 656.744019,674.426514 
	C659.978699,675.374451 659.536743,676.999146 657.941284,678.827515 
	C655.464172,681.666443 652.863953,684.385010 650.806763,687.589844 
	C649.458923,689.689636 647.865662,691.327515 649.745728,694.118286 
	C651.306824,696.435608 648.414917,696.917725 647.069458,698.333008 
	C644.008179,702.092102 641.062195,705.499146 637.446167,708.727173 
	C637.036499,709.068298 636.868652,709.195007 636.440002,709.487305 
	C635.832764,709.848633 635.485291,710.042480 634.902710,710.395081 
	C634.371155,710.788513 634.089417,711.039062 633.643311,711.531006 
	C633.235352,712.079590 632.999634,712.397888 632.550293,712.950623 
	C632.162659,713.311951 631.983276,713.434875 631.487305,713.662720 
	C629.932251,713.903015 628.506226,713.359802 627.391479,714.983398 
	C627.014526,715.358521 626.860901,715.500854 626.477478,715.842590 
	C626.100525,716.171997 625.953430,716.302002 625.606934,716.636292 
	C625.173401,717.135742 624.980591,717.455750 624.756958,718.078064 
	C624.557556,718.933838 624.381897,719.497803 624.056274,720.339905 
	C623.834412,720.811951 623.733826,720.997253 623.455139,721.447998 
	C623.013611,722.058228 622.736938,722.392029 622.230225,722.968140 
	C619.565125,725.929871 616.403015,727.810974 613.518677,730.537598 
	C606.878296,734.682922 601.050598,739.377136 594.883972,744.064819 
	C596.064575,746.910095 597.690430,746.986633 599.747070,745.117615 
	C601.899902,743.161255 604.282227,741.822144 606.326294,745.138550 
	C607.278503,746.683594 607.856567,745.667603 608.607605,745.227417 
	C616.375488,740.674255 622.621277,734.207458 629.514404,728.555298 
	C630.547791,727.708008 631.634155,726.558105 631.075073,725.151855 
	C629.417053,720.981323 632.667847,720.473694 635.491333,719.221680 
	C643.094971,715.535583 647.246460,709.930176 647.378540,701.284546 
	C648.507446,699.627075 650.024658,698.608398 651.137878,700.213074 
	C653.975037,704.302856 655.538635,701.830811 657.320435,699.386475 
	C657.706848,698.856506 658.381409,698.620544 659.348145,698.303467 
	C659.931396,698.210999 660.168945,698.199829 660.761475,698.231689 
	C664.383301,699.132568 664.993591,700.813416 662.828430,703.498779 
	C661.098877,705.643921 659.344360,707.784668 657.988220,710.602417 
	C656.806702,713.237610 653.761719,714.268860 653.812683,717.568115 
	C649.616699,725.345703 644.483337,732.113647 637.972778,737.776306 
	C637.740356,737.978516 637.279663,737.918884 637.048401,738.121643 
	C635.157837,739.778931 632.575745,736.826721 630.959656,738.877869 
	C629.077209,741.267029 633.554993,741.467712 632.903625,743.754456 
	C632.487000,745.217163 626.155396,751.818542 624.493408,751.593811 
	C620.996155,751.120850 618.031494,752.646667 614.900513,753.588318 
	C612.856262,754.203125 609.273315,756.117432 608.223877,756.913696 
	C607.884094,756.947083 607.590820,756.649475 607.391479,756.592041 
	C607.192200,756.534668 606.789917,756.457153 606.598022,756.397095 
	C606.406067,756.337036 606.363647,756.739990 606.188904,756.842407 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M503.385681,817.976929 
	C502.188721,815.585571 503.800415,812.465515 500.716614,810.098877 
	C499.155365,814.175842 503.739838,821.814026 494.262512,820.899048 
	C491.692993,823.073669 491.258392,826.209045 489.255890,828.791626 
	C487.114288,830.218079 488.963531,832.042175 488.568420,833.382507 
	C486.816406,833.959473 485.438232,832.336548 483.705688,833.527405 
	C484.493439,837.561279 487.758698,838.932556 492.158875,840.070679 
	C489.552338,841.348450 487.380493,841.065552 486.103302,843.329224 
	C484.682037,845.848389 483.597595,843.182068 482.486328,842.240234 
	C482.032928,841.856079 481.382294,841.642761 480.788300,841.536194 
	C480.661499,841.513428 480.109070,842.419617 480.228271,842.681091 
	C482.961334,848.679016 478.405548,845.219360 476.722168,845.359070 
	C476.086700,845.411804 475.424835,845.145691 474.375305,845.022583 
	C473.975586,845.020203 473.998596,845.006042 473.994568,845.014771 
	C473.825531,844.882629 473.660492,844.741638 473.241821,844.804810 
	C470.642212,842.326233 466.784790,842.077820 464.150757,839.304321 
	C463.588623,838.486877 463.254944,837.716553 462.271057,838.746582 
	C460.524323,838.956482 459.450378,838.302307 459.025452,836.370850 
	C459.113342,835.861206 459.247253,835.692993 459.179596,835.272888 
	C458.978058,835.021057 458.996460,835.000000 458.984161,835.010193 
	C458.144623,834.350586 458.055054,833.237915 457.205109,832.242798 
	C456.982666,832.037354 456.987793,832.015076 456.977661,832.009766 
	C456.810883,831.832703 456.654236,831.660828 456.240723,831.253113 
	C455.983795,831.017212 455.993927,831.004883 455.984955,831.013306 
	C453.929626,828.250183 451.666077,825.653748 451.178162,821.981628 
	C450.887665,819.795288 448.414551,819.286499 446.906464,817.557922 
	C446.656250,817.048584 446.600342,816.826416 446.595093,816.267151 
	C448.372650,814.509705 448.372650,814.509705 447.792725,812.244507 
	C448.098206,811.681641 448.273468,811.425964 448.730103,811.106506 
	C449.123657,810.865784 449.312988,810.741699 449.787048,810.802612 
	C450.071808,810.987488 450.523743,810.985779 450.749695,810.987427 
	C451.194214,811.227844 451.419922,811.459595 451.830292,811.849976 
	C452.494659,812.593689 452.981567,813.171875 453.729614,813.872681 
	C456.898865,813.027039 458.675873,810.568787 460.915894,808.723145 
	C462.140167,807.714417 463.795441,806.683655 464.379456,808.776367 
	C465.230011,811.824402 467.806549,812.446838 470.168701,814.039917 
	C472.158844,815.672607 473.875214,815.974243 475.896851,814.683533 
	C479.148407,812.607483 481.892212,813.650330 484.392853,816.638794 
	C484.743500,817.186890 484.979736,817.424072 485.535522,817.432251 
	C486.029205,817.104980 486.313873,816.907043 486.849457,816.592896 
	C487.763885,816.169189 488.405243,815.834106 488.917603,814.901489 
	C489.318085,814.102295 489.647186,813.600159 490.205688,812.878235 
	C491.977814,809.258911 493.499176,805.893250 488.900085,803.305664 
	C495.248016,798.171387 502.813690,797.401123 510.573547,796.013855 
	C510.946381,795.945862 511.019043,796.004028 511.056946,796.031128 
	C514.349548,797.204773 517.912720,797.507019 521.036133,799.869446 
	C521.903076,801.563538 520.962708,802.616272 520.230835,804.021912 
	C519.967468,804.452271 519.854797,804.616211 519.539734,804.988770 
	C519.048523,805.299805 518.593140,805.532898 518.638916,805.665161 
	C518.735535,805.944153 519.250427,805.745972 519.622925,806.304199 
	C520.341370,807.858521 521.055481,809.107971 521.161743,810.790283 
	C516.423096,811.736023 515.787292,816.760376 512.364746,818.885681 
	C510.080902,820.303894 508.513336,821.634888 507.028900,818.474365 
	C506.085876,816.466614 504.866455,817.823547 503.385681,817.976929 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M465.704712,771.738037 
	C467.660889,773.875183 468.992645,775.843628 471.385345,777.282715 
	C474.899597,779.396484 470.520142,780.402527 469.561279,781.848938 
	C471.487579,783.503479 474.032013,782.780151 475.766052,784.712524 
	C473.059448,784.767578 470.598206,784.518677 467.863434,787.052856 
	C465.942841,788.832581 461.202301,788.153015 459.556641,785.404968 
	C456.909973,780.985168 456.536591,783.950867 455.298431,786.014832 
	C454.595245,787.186951 454.249176,788.394531 453.835876,789.656311 
	C453.308563,791.266235 453.861664,793.676941 449.989471,792.708313 
	C448.447174,792.322449 445.650879,793.053955 443.160522,792.329529 
	C441.033081,791.710693 441.550873,795.369812 439.248718,795.937500 
	C437.889130,795.498901 437.202301,794.766663 437.016541,793.305115 
	C436.852875,792.841858 436.718719,792.694885 436.579834,792.251343 
	C436.771271,791.208679 436.934540,790.464294 436.305786,789.488281 
	C435.373138,787.726562 435.503632,786.348938 436.951782,784.940063 
	C437.426880,784.756226 437.633179,784.737549 438.112061,784.854736 
	C438.533905,785.120239 438.683258,785.249878 439.253723,785.758789 
	C439.986267,786.337097 440.319214,786.489014 440.956238,786.609924 
	C442.281799,786.654236 443.167969,786.289368 444.171478,785.370056 
	C445.055847,784.835144 445.677887,784.740234 446.550842,785.408691 
	C447.476379,786.311707 447.623993,787.674377 449.005157,787.385437 
	C449.189178,787.346924 449.118561,786.854187 448.818726,786.271240 
	C449.397644,781.632996 445.953003,784.341125 444.235352,783.478149 
	C441.830750,782.764832 439.883575,781.747314 437.400330,781.446289 
	C434.772675,781.755676 434.296844,780.491089 434.850006,778.277161 
	C437.823029,777.247559 437.394257,774.695984 438.025177,772.356018 
	C439.401489,770.678223 440.680023,771.466797 442.042999,772.058105 
	C445.249390,773.449341 448.590912,773.800232 452.371979,773.012329 
	C453.405426,772.863159 454.091949,772.885864 455.084991,773.070679 
	C456.622864,774.372437 456.839813,772.987488 457.661621,772.215942 
	C460.413971,771.465515 462.843414,770.598206 465.704712,771.738037 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M470.363525,814.845093 
	C467.319458,813.448730 462.267883,814.372742 463.480194,808.698120 
	C459.827148,809.733093 459.098053,815.174194 454.212097,814.251465 
	C454.017639,813.498169 453.843445,812.745667 454.101715,812.542358 
	C457.810303,809.621826 459.665375,805.107361 463.196564,802.046021 
	C464.083191,801.277283 464.958160,801.830383 465.326874,802.389954 
	C467.841614,806.206543 468.434235,802.567932 469.991302,801.447510 
	C472.766663,799.450623 475.551086,796.776245 479.789001,798.872559 
	C479.953522,802.479187 476.645264,802.949890 474.524780,804.913940 
	C473.886292,805.351501 473.545319,804.823608 473.260132,804.984192 
	C472.997559,805.131897 473.142700,805.433777 473.695831,805.841919 
	C474.274017,807.127014 474.266998,808.209351 474.240143,809.630432 
	C474.153259,810.420532 474.043213,810.861084 473.751953,811.603516 
	C472.812683,813.037842 471.735199,813.864746 470.363525,814.845093 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M472.987000,805.419678 
	C474.686218,802.566101 477.528351,801.525635 479.835144,799.312622 
	C480.345367,799.500610 480.647278,799.953186 480.992493,800.699463 
	C481.189880,802.009277 480.389282,803.236633 481.977386,804.179199 
	C483.165924,804.971313 484.078827,805.579224 485.036774,806.620239 
	C485.484497,807.354065 484.852356,808.050781 485.424622,808.333801 
	C485.708008,808.473938 486.141968,807.912659 486.823486,807.575684 
	C490.557404,807.466797 489.196350,811.015015 490.928528,812.694336 
	C490.797852,813.473511 490.510284,813.950684 490.094818,814.677368 
	C489.130615,815.813965 487.408356,814.864014 486.872742,816.713257 
	C486.792969,817.324158 486.637177,817.574463 486.191193,817.876221 
	C485.617065,818.371338 485.160126,818.535156 484.286926,818.299927 
	C481.848389,814.268188 478.830963,813.288086 475.628632,816.400452 
	C473.060425,818.896606 471.963104,816.683960 470.205261,815.276917 
	C470.028900,813.587952 470.700714,812.655396 472.257690,812.038208 
	C474.830414,811.649780 476.607971,810.208130 479.345612,809.653687 
	C476.828278,808.320435 475.292328,808.927917 473.498322,810.035278 
	C473.097351,808.615417 473.039062,807.222534 472.987000,805.419678 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M485.243744,807.390991 
	C483.928741,806.982910 482.905243,806.642761 482.178223,805.044067 
	C482.754822,803.304810 483.548615,802.298645 484.789734,801.247925 
	C486.133057,801.662598 487.242706,802.296875 488.674896,802.956787 
	C494.682739,804.573914 495.132660,805.558044 491.888458,810.631531 
	C491.283539,811.577515 493.341248,812.486328 491.330017,812.978027 
	C487.969299,812.705994 488.389221,810.039490 487.489441,808.023193 
	C486.591309,807.996216 485.989166,807.959290 485.243744,807.390991 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M522.388489,783.216797 
	C523.331604,777.493286 528.510376,778.442200 532.755249,777.518188 
	C533.153625,777.993164 533.289246,778.241333 533.140503,778.804199 
	C529.694641,780.950378 526.659363,783.096497 522.388489,783.216797 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M447.732330,812.088257 
	C447.710938,814.180359 450.824768,813.637756 450.898376,815.762329 
	C449.803253,816.243591 448.585144,814.604980 447.276001,815.891479 
	C445.740448,815.574768 444.867859,814.690308 444.095459,813.298584 
	C444.027893,812.989929 444.015320,812.496033 444.005188,812.249268 
	C444.191223,811.256226 444.165863,810.141418 444.639404,809.856873 
	C445.234924,809.498962 445.683472,810.407227 445.863434,811.050049 
	C446.098114,811.888367 446.299194,812.693787 447.732330,812.088257 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M481.963837,805.036621 
	C478.891449,804.847290 477.926971,803.782654 480.722229,801.152344 
	C481.481537,800.985596 481.927246,800.978088 482.707550,800.977966 
	C484.106018,802.303650 483.196716,803.327698 482.286255,804.674805 
	C482.037872,804.960938 481.988586,805.011475 481.963837,805.036621 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M482.369080,804.918823 
	C482.340240,803.864319 482.642639,802.767700 482.993591,801.328247 
	C483.482056,800.992432 483.921997,800.999451 484.692688,801.017456 
	C484.752258,802.614929 484.388885,804.145813 482.369080,804.918823 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M537.325562,777.108032 
	C537.379395,775.855225 538.381531,776.052063 539.606506,776.036438 
	C539.419250,776.944397 538.513611,777.024292 537.325562,777.108032 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M533.038086,779.294922 
	C532.891724,778.891663 532.927246,778.664490 532.990356,778.091064 
	C533.469421,777.773254 533.920837,777.801575 534.700867,777.915771 
	C534.637146,778.751038 534.313538,779.584961 533.038086,779.294922 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M535.321167,778.059570 
	C534.968323,777.091858 535.590454,776.921265 536.678589,776.955078 
	C536.946960,777.857117 536.338684,778.023376 535.321167,778.059570 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M438.198914,797.849731 
	C438.098022,797.639221 438.290649,797.395508 438.757874,797.093750 
	C438.887665,797.313599 438.707642,797.573975 438.198914,797.849731 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M449.960876,810.821594 
	C449.882507,810.992249 449.693176,810.997070 449.219116,810.995972 
	C449.239563,810.878540 449.544769,810.767151 449.960876,810.821594 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M451.895874,811.790405 
	C451.684723,811.898865 451.419586,811.703552 451.093994,811.209351 
	C451.245148,811.181091 451.514587,811.373169 451.895874,811.790405 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M446.584961,816.965454 
	C450.365387,818.359985 453.460449,819.626526 452.912506,824.528137 
	C452.710571,826.334717 454.855103,828.403503 455.952667,830.687195 
	C451.823242,828.001770 449.247864,823.640442 446.132233,819.308716 
	C445.410706,818.257935 445.472534,817.577576 446.584961,816.965454 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M446.986694,816.727722 
	C446.666595,817.447876 446.352142,817.926331 446.013123,818.703003 
	C443.892151,817.713135 442.196777,816.245850 443.764648,813.213745 
	C445.098938,813.647888 446.495605,813.910583 446.954590,815.683716 
	C446.998779,816.002441 446.992371,816.486023 446.986694,816.727722 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M462.405823,838.964600 
	C462.381805,837.733582 461.147247,835.590149 463.447876,835.593994 
	C465.076965,835.596680 465.448334,836.992554 464.144745,838.684082 
	C463.574493,838.987610 463.149414,838.982361 462.405823,838.964600 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M456.970703,832.388855 
	C458.471710,832.070618 459.026733,832.927002 458.978302,834.664917 
	C457.541016,834.931274 456.923950,834.123596 456.970703,832.388855 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M432.905151,805.782104 
	C432.691650,805.898315 432.426788,805.708618 432.112579,805.211792 
	C432.329803,805.091614 432.593323,805.282104 432.905151,805.782104 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M443.472870,826.457947 
	C443.521301,826.430420 443.424438,826.485474 443.472870,826.457947 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M473.238708,845.018616 
	C473.264526,844.903137 473.540863,844.797302 473.903870,844.857544 
	C473.823425,845.025146 473.656311,845.026733 473.238708,845.018616 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M456.071960,831.247681 
	C456.311646,831.103516 456.574371,831.284363 456.869781,831.782104 
	C456.622345,831.945679 456.353180,831.770203 456.071960,831.247681 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M458.970093,835.273193 
	C459.149811,835.185730 459.337036,835.338562 459.480072,835.525208 
	C459.508453,835.562256 459.367279,835.729126 459.141876,835.932861 
	C458.973694,835.861450 458.967896,835.693359 458.970093,835.273193 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M420.280212,792.293640 
	C417.163116,792.162354 414.732910,792.022705 412.513550,789.500061 
	C411.039734,787.824951 410.101715,786.972656 411.012512,784.663635 
	C411.947571,782.293030 409.684174,783.298767 408.346069,783.007080 
	C407.114807,782.162415 406.879333,780.991638 406.506500,779.908569 
	C405.120300,775.881165 405.722656,774.796753 410.056366,774.699158 
	C412.293365,774.648743 414.259460,774.034180 416.631775,773.338989 
	C417.879517,773.582825 418.739258,773.962402 419.953674,774.352905 
	C421.165405,775.433716 421.518707,776.415222 420.700317,777.571350 
	C420.027771,778.521423 419.239899,779.489868 420.501923,780.542786 
	C421.500336,781.375793 422.606079,781.163757 423.746704,780.809265 
	C425.741852,780.189148 427.242249,778.287231 429.884216,778.754395 
	C430.717285,780.166016 430.330048,781.328674 429.789398,782.824585 
	C429.455811,783.437988 429.308228,783.792358 429.037292,783.941528 
	C425.812561,785.717285 423.730103,788.401367 422.344330,792.120972 
	C421.584656,792.535217 421.098663,792.570190 420.280212,792.293640 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M419.974609,792.465271 
	C420.439850,792.055359 420.921417,792.005615 421.763062,791.912354 
	C425.062195,790.495300 428.293579,789.734985 430.803406,787.465332 
	C432.373810,786.045166 433.564575,784.619141 432.418121,782.080139 
	C431.837006,781.042847 431.255737,780.417786 430.499634,779.526306 
	C428.075531,778.957275 426.677277,780.786682 425.119324,781.745789 
	C422.200165,783.542847 420.176270,783.057434 418.188843,780.662781 
	C415.690094,777.652039 419.914398,777.966614 420.294769,776.390015 
	C420.364502,776.101074 420.186005,775.752319 420.017242,775.153259 
	C419.615875,774.534546 419.482697,774.142090 419.733948,773.429077 
	C422.676849,773.755615 426.008301,773.285400 427.970306,775.365540 
	C429.906525,777.418335 432.445465,776.401550 434.695190,777.854492 
	C435.901031,778.795044 434.741516,780.560364 436.729004,780.922974 
	C437.748627,781.325317 438.638214,782.308777 437.938416,782.721497 
	C436.069946,783.823547 438.569397,783.878906 438.097382,784.802856 
	C436.849670,785.962952 436.163635,786.975769 436.869598,788.729431 
	C436.999023,789.799622 437.267365,790.588440 437.028259,791.699097 
	C436.981049,791.997864 436.993866,792.493469 436.990448,792.741211 
	C432.195343,789.183716 428.733978,793.836975 424.342407,794.974731 
	C423.563690,795.445496 423.157654,795.867249 422.255798,795.977234 
	C419.900726,795.802979 420.041260,794.254333 419.974609,792.465271 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M417.300720,773.791260 
	C414.026337,776.704102 410.115662,775.678223 406.685760,776.112488 
	C406.429871,778.640381 409.207489,780.033813 408.109650,782.716187 
	C406.646912,787.332703 405.123383,783.172363 403.346680,783.042297 
	C404.007996,778.913208 402.751709,774.293579 405.982513,770.389526 
	C406.303497,769.424744 406.820801,769.115417 407.766907,768.964844 
	C410.735840,771.313354 413.806396,772.495056 417.712463,772.436035 
	C418.150391,773.078003 418.012451,773.427979 417.300720,773.791260 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M420.973572,806.971069 
	C417.404724,806.173035 419.944916,803.278076 419.549866,801.404907 
	C420.019348,803.009460 420.488831,804.613953 420.952393,806.582520 
	C420.946503,806.946594 421.000000,806.999451 420.973572,806.971069 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M422.022949,796.277466 
	C422.437531,795.662720 422.911560,795.356323 423.679688,795.039917 
	C423.808044,795.805786 423.876801,796.795654 423.415131,797.301758 
	C422.880310,797.888123 422.316010,797.260498 422.022949,796.277466 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M400.199707,795.661621 
	C400.270081,794.149048 399.239441,792.208496 400.855530,791.560181 
	C402.796967,790.781433 402.106720,793.301819 403.046875,793.941406 
	C404.319397,794.807129 406.156433,795.164001 405.999023,797.639893 
	C404.959503,797.988281 403.914154,797.986328 402.478455,797.979370 
	C402.088104,797.974304 402.027039,797.969482 402.043701,797.944702 
	C401.691071,797.480530 401.824127,796.822083 401.186462,796.221497 
	C400.790924,795.970825 400.609589,795.904968 400.199707,795.661621 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M401.024506,796.318909 
	C401.907257,796.037598 402.781677,796.093567 402.152649,797.677246 
	C401.732483,797.480347 401.404602,797.040710 401.024506,796.318909 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M540.004883,803.993286 
	C540.081482,805.671143 541.488098,806.369812 542.224854,807.320190 
	C546.124146,812.349915 551.204285,808.242554 555.636963,810.007751 
	C555.365234,814.206116 557.238770,816.895874 561.208069,818.753052 
	C561.942444,822.180054 558.801758,822.639954 557.218994,824.669495 
	C556.224915,824.927368 555.490234,824.941406 554.388123,824.953247 
	C550.898560,824.153687 548.564392,823.906433 548.999390,828.594849 
	C549.302856,831.865234 546.157288,832.515686 543.303955,833.111084 
	C541.663208,831.230103 538.473022,829.924744 542.010132,827.171570 
	C543.508789,826.004944 542.472534,824.553589 541.020203,824.262268 
	C539.242676,823.905701 537.895203,823.185669 537.094360,821.633179 
	C534.631775,816.859253 529.811768,815.537659 525.210815,813.281372 
	C524.318054,812.305176 523.726013,811.553650 523.582031,810.189636 
	C524.324097,807.360046 526.833374,808.220886 528.512146,807.245850 
	C528.646973,805.336487 526.580322,804.419861 526.264709,802.332031 
	C526.205200,801.733826 526.214478,801.490295 526.329895,800.893921 
	C526.966309,799.565491 527.840149,799.228882 529.243408,799.277527 
	C531.376465,800.271301 532.914429,801.717957 535.363525,802.014038 
	C536.306213,802.323608 536.883972,802.628479 537.674072,803.187866 
	C538.587891,803.563538 537.905579,804.602356 538.547852,804.579895 
	C539.177612,804.557922 538.241394,804.706116 538.813599,803.938477 
	C539.193176,803.672546 539.384094,803.628784 539.792419,803.782715 
	C540.009766,803.980469 540.004822,803.991516 540.004883,803.993286 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M543.193848,833.286377 
	C547.683594,830.784607 549.966248,827.750427 545.549866,822.058044 
	C549.039551,823.020020 551.469421,823.689880 553.959961,824.655396 
	C550.903137,825.631714 552.685669,827.576965 552.868408,829.186035 
	C553.123840,831.435547 554.077087,834.116638 550.120972,834.808716 
	C547.609009,835.055420 545.318787,835.145874 543.193848,833.286377 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M521.244751,811.069641 
	C519.831970,809.832214 518.415894,808.724976 519.040344,806.327698 
	C518.851196,805.808228 518.653931,805.661987 518.697021,805.236572 
	C518.937317,804.957458 518.989441,804.990234 518.972473,804.964600 
	C523.334900,803.372803 523.424622,806.165894 523.295715,809.562012 
	C523.309753,810.436829 523.122375,810.855469 522.351196,811.164856 
	C521.988098,811.161621 521.494141,811.085876 521.244751,811.069641 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M496.395386,826.984375 
	C495.557007,823.701050 498.411194,823.927856 500.620544,823.035828 
	C500.561096,825.374329 498.482544,825.975769 496.395386,826.984375 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M522.061646,811.484070 
	C522.270874,810.940125 522.553711,810.718628 522.962402,810.257446 
	C523.238708,809.898376 523.389221,809.779114 523.772339,809.829895 
	C524.875183,810.552063 524.993835,811.420227 524.984863,812.678711 
	C523.897400,812.930542 522.672974,813.175598 522.061646,811.484070 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M553.009766,836.976440 
	C552.897095,837.769775 552.788025,838.681091 551.687195,838.588440 
	C551.358704,838.560791 551.077698,837.968140 550.774963,837.634338 
	C551.326294,837.422119 551.877563,837.209900 552.737793,836.989624 
	C553.046692,836.981567 552.998047,836.999817 553.009766,836.976440 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M495.017517,828.751099 
	C494.848022,828.823242 494.667511,828.674194 494.527496,828.493652 
	C494.501923,828.460693 494.641296,828.299744 494.871765,828.126709 
	C495.039032,828.055481 495.020416,828.519104 495.017517,828.751099 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M489.267578,829.011047 
	C489.946014,826.107727 489.288055,822.330322 493.633972,821.062317 
	C494.893372,825.010132 493.272858,827.596191 489.267578,829.011047 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M606.022705,831.351685 
	C613.554565,831.371460 620.607605,833.612671 627.752319,836.699707 
	C627.178406,838.166687 625.916382,837.960327 624.361694,837.980103 
	C623.692993,838.133240 623.402344,838.608398 623.265442,838.584534 
	C615.227295,837.185913 611.827698,843.691833 607.263245,848.080933 
	C606.066162,849.231995 604.577332,849.251282 603.023865,850.174988 
	C601.427612,849.756287 599.892151,849.805054 598.910278,848.356628 
	C599.819580,847.852173 600.760681,847.598450 600.808228,846.659668 
	C601.039734,842.096069 602.716309,838.160706 605.511292,834.583557 
	C606.033630,833.915161 605.862122,832.704651 606.022705,831.351685 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M598.730347,848.008911 
	C600.124939,848.582825 601.262817,849.163208 602.707581,849.866211 
	C603.179871,849.848572 603.345276,849.708313 603.735535,849.803162 
	C601.634094,852.018066 599.640564,849.592957 597.222900,849.292603 
	C597.284546,848.917603 597.539185,848.728516 597.851929,848.258301 
	C598.132141,848.035889 598.302917,848.025574 598.730347,848.008911 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M605.127563,830.232727 
	C605.336853,830.110840 605.605042,830.284790 605.924194,830.760620 
	C605.707031,830.888611 605.444153,830.710632 605.127563,830.232727 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M625.576782,863.798523 
	C624.695984,863.470520 624.197388,863.209473 623.417969,862.904907 
	C622.762695,860.036743 624.131531,858.766724 626.828186,859.049622 
	C629.388672,859.318237 631.735901,860.301331 632.708557,862.901001 
	C633.246521,864.338806 632.129883,865.092102 630.761108,864.912048 
	C629.142456,864.699097 627.558105,864.225891 625.576782,863.798523 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M604.292358,850.161133 
	C603.960388,850.038269 603.487183,850.013550 603.250793,850.001221 
	C605.699463,845.251648 611.420532,843.600098 614.205994,838.716003 
	C615.236450,836.909363 618.599854,835.192932 621.290771,837.363098 
	C622.270447,838.153198 622.827393,838.000366 623.837097,837.823608 
	C623.581726,840.469543 618.299438,841.953308 623.937073,845.538513 
	C618.631958,846.093872 613.814880,841.269653 611.006104,847.787109 
	C610.805176,854.450623 607.202515,850.865112 604.292358,850.161133 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M557.372253,824.947388 
	C556.265381,821.672791 559.595398,821.451599 560.939392,819.347717 
	C561.409851,818.659363 561.876587,818.529602 562.718384,818.821289 
	C563.032959,819.638489 563.031738,820.247681 563.037842,821.156799 
	C562.669434,822.387512 562.293640,823.318237 561.911194,824.628662 
	C560.531372,824.999329 559.158142,824.990356 557.372253,824.947388 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M253.256439,724.769897 
	C253.001846,724.550537 252.998856,724.109558 252.993225,723.338013 
	C253.090210,722.294373 253.189835,721.581421 253.381805,720.207397 
	C254.176743,722.174316 254.133820,723.372742 253.256439,724.769897 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M254.256241,729.783875 
	C253.998032,728.932434 253.993805,727.864807 253.984467,726.396729 
	C255.262375,727.022339 255.186142,728.250488 254.256241,729.783875 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M253.132980,716.715271 
	C253.227875,716.267761 253.362396,716.033569 253.496918,715.799438 
	C253.630966,716.033386 253.883530,716.273560 253.871719,716.499939 
	C253.853348,716.852295 253.844788,717.517273 253.132980,716.715271 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M256.528931,708.543335 
	C256.476837,708.573975 256.581055,708.512695 256.528931,708.543335 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M361.786194,758.153076 
	C365.339935,756.239075 364.134888,754.267578 362.138855,752.074463 
	C361.260529,748.826721 363.197723,749.619324 364.665283,750.172302 
	C366.611237,750.905518 368.436371,752.249939 370.819550,750.468872 
	C371.589539,749.996765 372.138794,750.849609 372.469635,750.000977 
	C372.222534,749.341919 372.153137,748.941650 372.138947,748.215271 
	C372.287842,747.020935 372.645508,746.231384 373.291382,745.204468 
	C373.663788,744.785828 373.835907,744.638611 374.313782,744.339783 
	C375.500305,743.887939 376.409210,743.864563 377.665222,743.949280 
	C379.334839,744.268005 380.630493,744.685486 382.280457,745.109253 
	C382.807434,745.344910 383.008789,745.461426 383.463989,745.818604 
	C385.101074,747.954468 386.550171,749.822449 386.164429,752.690186 
	C384.537292,754.893127 383.074585,756.786194 381.315186,758.841370 
	C377.548981,759.869507 374.001617,760.638916 373.406494,765.454651 
	C373.333649,766.262573 373.270386,766.723877 373.104309,767.529236 
	C372.930542,768.097656 372.839661,768.319214 372.539978,768.834961 
	C372.130249,769.261658 371.929321,769.394287 371.380981,769.620361 
	C370.795654,769.720093 370.557678,769.726318 369.969482,769.682007 
	C367.623871,768.766602 365.237915,769.958923 363.174103,768.283752 
	C364.167725,769.784424 365.922028,770.077942 366.685608,771.453491 
	C367.568207,773.043274 367.656433,774.326599 365.087097,774.807129 
	C364.835663,777.312561 360.254547,777.162109 361.985718,780.711426 
	C363.310211,783.135010 363.626526,785.881042 366.662170,786.951294 
	C366.875244,787.320007 366.683990,787.569763 366.220642,787.889648 
	C365.660645,788.360596 365.060883,788.854187 364.782440,788.661194 
	C361.944733,786.694702 357.928528,787.329773 355.304199,784.185791 
	C355.079712,783.924683 355.067047,783.464111 355.054382,783.234131 
	C357.102325,780.779114 357.350433,777.637878 358.842407,775.062683 
	C359.576416,773.795837 357.183350,770.847229 356.162598,768.323364 
	C356.079865,767.550720 356.036560,767.115967 355.990845,766.354004 
	C355.406006,764.105286 356.005890,762.498779 357.580994,761.031799 
	C359.757202,761.085205 360.630829,759.706726 361.786194,758.153076 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M355.053406,784.359985 
	C358.810608,784.466248 362.000366,786.370911 365.660736,787.997681 
	C363.855011,791.480774 364.289429,794.648010 367.013428,797.815674 
	C366.424500,798.518860 365.911133,798.871704 364.627258,798.986450 
	C361.335358,801.744568 364.548706,804.465515 363.995575,807.596436 
	C360.283752,803.042053 355.465546,798.571106 356.828796,791.402100 
	C356.962250,790.700317 356.330505,790.236938 355.301086,790.039673 
	C355.004944,788.261841 355.016022,786.528625 355.053406,784.359985 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M355.854828,768.137695 
	C361.431030,772.002502 361.241150,778.847839 355.375000,782.972656 
	C354.868652,783.004639 354.695618,783.005127 354.258850,783.000977 
	C352.917267,778.622375 354.632843,773.701355 351.249908,769.966431 
	C352.406708,768.145935 354.778168,770.459534 355.854828,768.137695 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M338.329102,759.815674 
	C335.702393,756.228210 341.102325,755.003174 339.876526,751.673096 
	C341.946686,752.648560 341.651276,754.434204 342.679626,755.818970 
	C342.935791,756.023499 342.962891,756.495850 342.930206,756.730103 
	C342.563904,758.294861 341.518311,758.936829 340.178345,759.716553 
	C339.507050,759.896973 339.076782,759.888184 338.329102,759.815674 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M340.245056,759.953735 
	C339.972412,758.334534 341.326965,757.943848 342.612030,757.099976 
	C343.375671,758.306641 345.026764,759.724182 342.330139,760.946655 
	C341.527283,760.674255 341.040039,760.337952 340.245056,759.953735 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M355.002075,790.002441 
	C354.913727,790.036621 354.823273,790.068359 354.863342,790.047607 
	C354.993896,789.995117 355.000000,790.000000 355.002075,790.002441 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M341.994568,760.992798 
	C341.913879,761.027588 341.838654,761.069641 341.888977,761.061157 
	C342.014496,761.010620 342.000000,761.000000 341.994568,760.992798 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M354.001740,783.002441 
	C353.914062,783.036621 353.824677,783.068359 353.865204,783.048218 
	C353.995117,782.996399 354.000000,783.000000 354.001740,783.002441 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M375.574432,793.970337 
	C375.555603,795.315674 376.107574,796.984680 374.318237,797.997559 
	C373.027954,796.280823 369.873779,793.909180 375.574432,793.970337 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M366.870667,800.741150 
	C365.999084,800.568176 365.175507,800.030762 364.175079,799.256470 
	C363.998199,799.019592 364.012665,798.995117 364.024475,798.986938 
	C364.683380,798.419006 365.457825,798.518738 366.561890,798.733154 
	C366.908508,799.398438 366.913605,799.887573 366.870667,800.741150 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M366.915680,798.705933 
	C366.186920,798.951538 365.470428,798.993652 364.395142,799.007263 
	C364.432831,797.868652 365.379456,798.053833 366.571838,798.068848 
	C366.901367,798.095886 366.927948,798.502563 366.915680,798.705933 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M373.993896,805.991577 
	C373.905731,806.022461 373.823914,806.061768 373.879456,806.056946 
	C374.016846,806.012878 374.000275,806.000000 373.993896,805.991577 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M365.195801,775.002014 
	C366.099457,773.832031 367.190216,772.663879 365.071869,771.261292 
	C363.327087,770.106018 361.575195,768.961487 359.969238,766.796265 
	C363.167511,767.324524 366.365784,767.852844 369.788116,768.586304 
	C370.989868,769.892944 371.643158,771.252930 373.003387,772.053650 
	C374.401245,772.876587 374.602081,774.073975 373.297058,775.644165 
	C370.197632,778.806519 368.296417,773.558411 365.195801,775.002014 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M373.380981,775.935791 
	C373.003387,774.794312 374.127625,773.264648 371.851776,772.980713 
	C369.792816,772.723877 370.048553,771.036499 370.013885,769.189392 
	C370.198761,768.777283 370.385345,768.763000 370.841278,768.827698 
	C371.330170,769.292664 371.666565,769.440735 372.398865,769.305176 
	C373.803162,769.185791 374.767029,769.622498 375.983521,770.305237 
	C376.768280,770.932190 377.045471,771.525208 377.367859,772.460449 
	C377.432373,773.001709 377.417908,773.221924 377.208130,773.709534 
	C377.012787,773.976868 376.989441,773.992920 376.983398,774.005737 
	C376.815063,774.178894 376.652771,774.339294 376.172913,774.627563 
	C375.136993,775.158691 374.418640,775.562073 373.380981,775.935791 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M377.284973,773.854248 
	C377.012787,773.976868 376.991089,773.482117 377.012054,773.235413 
	C377.186066,772.812195 377.339081,772.635620 377.818756,772.586060 
	C378.145386,772.713196 378.263641,772.760986 378.314819,772.803162 
	C378.096405,773.140808 377.826813,773.436218 377.284973,773.854248 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M376.103271,774.747620 
	C376.058685,774.527649 376.290802,774.334534 376.764587,774.097290 
	C376.899567,774.372620 376.690796,774.613037 376.103271,774.747620 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M373.549866,781.473877 
	C373.583221,781.531311 373.516541,781.416382 373.549866,781.473877 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M376.518494,776.469360 
	C376.577759,776.552124 376.338074,776.564697 376.338074,776.564697 
	C376.338074,776.564697 376.459259,776.386536 376.518494,776.469360 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M385.208038,774.973877 
	C385.096771,774.665466 385.281586,774.402039 385.729248,774.048218 
	C386.732147,774.712585 386.169098,774.882141 385.208038,774.973877 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M202.752655,550.874451 
	C202.981964,551.481323 203.037674,551.981628 202.967422,552.463684 
	C202.672043,554.490356 205.147659,556.379517 203.263138,558.431152 
	C203.113419,558.594116 202.043564,558.195007 202.047134,558.175476 
	C202.527435,555.553772 200.087402,553.481873 200.621201,550.928406 
	C200.938477,549.410706 201.783813,549.714844 202.752655,550.874451 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M183.569397,572.047729 
	C183.187225,572.745178 182.801956,572.749695 182.461380,572.255371 
	C182.390076,572.151917 182.393387,571.909607 182.467545,571.808167 
	C182.821686,571.323669 183.206100,571.338989 183.569397,572.047729 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M203.005341,551.008179 
	C203.126129,550.737793 203.300385,550.503052 203.726456,550.208618 
	C203.919418,550.105164 204.003525,550.010742 203.950531,550.046753 
	C203.794403,550.368469 203.620987,550.602051 203.180725,550.886475 
	C202.984192,550.989380 202.999985,551.000122 203.005341,551.008179 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M176.712402,581.322876 
	C176.919785,581.208801 176.762238,580.365295 177.396362,580.888672 
	C177.303665,580.983398 177.210968,581.078125 176.958252,581.307861 
	C176.798248,581.442871 176.771988,581.326416 176.712402,581.322876 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M649.426147,858.067505 
	C648.416321,857.498962 647.817078,856.907043 647.134033,855.998657 
	C650.704224,856.523621 654.358215,857.365112 658.494385,858.317688 
	C655.120239,860.032959 652.565002,857.695312 649.426147,858.067505 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M629.430542,843.290894 
	C629.280273,845.621948 627.972290,846.825989 625.351257,846.329224 
	C625.156311,846.098755 625.576599,845.978699 625.787537,845.921570 
	C626.132751,845.731445 626.267029,845.598389 626.221680,845.245972 
	C626.221069,843.006897 627.550781,842.944946 629.430542,843.290894 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M651.490479,850.551147 
	C651.441162,850.480652 651.644653,850.473755 651.644653,850.473755 
	C651.644653,850.473755 651.539856,850.621643 651.490479,850.551147 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M653.678345,851.592407 
	C653.429749,851.619995 653.340698,851.513672 653.251587,851.407349 
	C653.447021,851.424438 653.642395,851.441528 653.678345,851.592407 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M630.854614,842.852417 
	C630.944275,842.973450 630.532837,842.926086 630.326904,842.932373 
	C630.120972,842.938721 630.392944,842.617371 630.392944,842.617371 
	C630.392944,842.617371 630.764893,842.731323 630.854614,842.852417 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M631.598938,843.513184 
	C631.477966,843.582214 631.322510,843.468872 631.125610,843.174316 
	C631.213440,843.147400 631.373535,843.260010 631.598938,843.513184 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M626.019836,845.235474 
	C626.240662,845.185974 626.445435,845.339172 626.625732,845.517212 
	C626.639465,845.530823 626.479248,845.720520 626.199158,845.846375 
	C625.998474,845.864441 625.997620,845.444336 626.019836,845.235474 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M641.571167,854.305542 
	C642.010986,854.263794 642.274841,854.383972 642.538696,854.504150 
	C642.423523,854.613953 642.281006,854.833984 642.198120,854.813416 
	C641.919006,854.744324 641.661133,854.589478 641.571167,854.305542 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M638.641785,853.626221 
	C638.528259,853.716858 638.455994,853.403564 638.455994,853.403564 
	C638.455994,853.403564 638.755310,853.535645 638.641785,853.626221 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M304.175232,769.354187 
	C305.379395,769.140137 306.790710,768.459839 307.057129,770.797363 
	C305.893707,771.546631 305.437195,770.479004 304.356567,769.919373 
	C304.037506,769.782471 304.124878,769.495483 304.175232,769.354187 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M301.728394,764.101807 
	C302.006409,764.432190 302.004669,764.876038 301.994141,765.652649 
	C301.237793,765.872009 300.490204,765.758606 299.742645,765.645264 
	C300.311340,765.168640 300.880005,764.692017 301.728394,764.101807 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M315.030579,793.169678 
	C314.345459,792.767090 313.737396,792.352356 314.456238,791.671875 
	C314.483002,791.646667 314.902008,792.035828 315.232239,792.454712 
	C315.326263,792.677490 315.256592,793.130798 315.030579,793.169678 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M317.020752,796.171143 
	C317.136566,796.143494 317.243195,796.323303 317.349792,796.503174 
	C317.237061,796.461609 317.124329,796.420105 317.020752,796.171143 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M315.195251,792.809692 
	C315.463989,793.005859 315.601715,793.334167 315.739441,793.662537 
	C315.516388,793.659058 315.293365,793.655640 314.937439,793.430420 
	C314.804596,793.208618 315.064209,792.941833 315.195251,792.809692 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M316.959167,796.035950 
	C316.789307,795.868103 316.644287,795.677490 316.499298,795.486816 
	C316.626068,795.530334 316.752869,795.573853 316.954773,795.790527 
	C317.029907,795.963684 316.983978,796.013123 316.959167,796.035950 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M381.746857,694.360657 
	C384.077698,693.470276 386.183319,695.852661 388.975281,694.131348 
	C382.299133,688.840027 375.915527,683.876099 369.664520,678.750427 
	C368.314514,677.643494 365.461090,677.009094 367.424896,674.030518 
	C372.439850,674.436096 374.753998,678.911682 379.037109,680.796143 
	C379.910583,681.339600 380.475830,681.727234 381.372192,682.232056 
	C386.476593,686.433594 392.248627,688.982605 397.317993,692.604980 
	C398.670074,693.571289 400.207825,692.961975 401.913300,693.550537 
	C404.810822,696.097290 408.111725,696.939758 411.228424,698.328918 
	C415.700653,700.322327 420.480042,699.889771 425.145905,701.726318 
	C425.791595,702.622498 425.896820,703.324219 425.674988,704.409058 
	C424.525879,706.244751 422.805267,706.507812 420.955383,707.210083 
	C418.728088,706.743591 416.732574,707.269287 414.594849,706.537354 
	C413.925262,705.956421 413.490082,705.578369 412.622162,705.549988 
	C412.290192,705.435547 412.210754,705.287354 412.170166,705.386475 
	C412.754242,705.464844 413.266693,705.561279 413.982513,706.016602 
	C415.143372,707.536560 416.608398,707.606750 418.326721,707.851440 
	C418.836761,707.939453 419.038605,707.983765 419.535065,708.118286 
	C420.400299,708.416382 420.959137,708.656067 421.830902,708.919434 
	C422.315979,709.062439 422.509583,709.123596 422.989777,709.290405 
	C423.656830,709.550781 424.036072,709.708435 424.710449,709.976746 
	C425.207489,710.156982 425.408356,710.229126 425.911987,710.415283 
	C429.524384,711.830139 433.201080,712.034424 436.577850,714.115295 
	C437.713379,714.685425 437.239197,717.058716 439.419281,715.703247 
	C439.875793,715.432495 440.068298,715.336182 440.571167,715.136108 
	C441.097321,714.988831 441.313080,714.945251 441.859497,714.871338 
	C442.858276,714.826416 443.518127,714.904419 444.499298,715.083984 
	C445.462860,715.310242 446.096161,715.495422 447.082581,715.629272 
	C448.328613,715.740723 449.240021,715.829346 450.507446,715.983276 
	C451.103027,716.089478 451.341309,716.143860 451.929810,716.316284 
	C457.236389,717.548096 462.220856,718.485718 467.681854,718.902344 
	C471.536377,720.071899 475.061371,720.845825 479.116516,720.737061 
	C479.739197,720.850891 479.981750,720.917053 480.565063,721.133301 
	C482.232239,722.587036 483.451660,723.330566 485.546509,722.339417 
	C487.753601,721.295166 490.285004,721.296570 492.838745,722.805115 
	C493.125977,723.294189 493.178650,723.521790 493.035950,724.039612 
	C491.910980,724.613831 490.947021,724.387451 489.728455,724.548584 
	C490.969421,724.545532 491.940826,724.414978 493.131470,724.962830 
	C493.930298,728.275574 490.515198,728.381226 489.306946,730.588623 
	C484.893707,733.356140 480.301453,732.491394 475.918213,732.403687 
	C465.574921,732.196899 455.302063,730.567139 445.254211,730.774048 
	C447.117950,731.681213 449.739960,729.997620 452.036469,732.044189 
	C450.590149,738.103088 448.405457,739.390625 442.675415,738.971008 
	C437.993927,738.628235 433.444580,736.160217 428.334473,738.225220 
	C423.357483,740.400513 418.930756,739.308411 414.294067,737.311584 
	C413.689240,737.363342 413.407623,737.287476 413.157501,737.115845 
	C412.851532,738.055542 413.984558,738.083252 414.282593,739.029053 
	C413.519653,742.409729 414.401703,744.259888 417.675537,745.254639 
	C421.478577,746.410156 424.919525,749.082825 429.256744,747.113342 
	C430.566956,746.518433 430.949738,748.530334 432.518982,748.646179 
	C429.994202,745.842102 426.109009,743.500427 430.098755,739.093567 
	C432.729828,738.412170 435.001648,738.343323 437.536163,739.439087 
	C437.984924,739.827820 438.129700,740.012817 438.370941,740.512573 
	C439.183502,742.483643 440.930817,742.146057 442.196869,742.725647 
	C443.353088,743.254944 445.026306,743.097412 445.317230,744.747559 
	C445.622955,746.481384 444.032928,746.836426 442.928772,747.485840 
	C442.363892,747.818176 441.727692,748.058899 441.341431,749.154785 
	C443.691711,750.173096 446.670197,750.619263 447.448029,753.855713 
	C447.943268,755.916443 448.057587,757.774902 446.364624,759.727661 
	C445.938019,760.119934 445.753082,760.260437 445.258118,760.558105 
	C442.857361,761.707642 439.956573,759.369873 438.114685,762.551514 
	C437.711273,762.899780 437.535889,763.018799 437.065857,763.241516 
	C436.566589,763.344360 436.361877,763.343445 435.590271,763.163086 
	C432.199707,762.774536 431.049042,767.141174 427.392151,765.423706 
	C424.998230,764.299316 423.420471,762.975098 423.112488,760.500732 
	C422.719513,757.343445 420.877594,755.276489 418.630615,753.334473 
	C417.807220,752.622742 416.951263,751.861084 416.085114,752.731934 
	C414.543488,754.281860 413.466705,755.457886 412.670837,752.175903 
	C412.405121,751.080261 411.415863,751.743958 410.723633,752.274780 
	C409.844910,752.948669 408.081726,752.844849 408.418427,751.986450 
	C409.900757,748.207031 407.148438,746.772400 405.230072,744.660706 
	C404.847290,744.239441 405.705322,742.690918 405.993774,741.330200 
	C406.942474,738.718140 406.636658,736.613159 405.046631,734.341919 
	C407.359375,730.227173 404.955200,728.735901 401.928162,727.413696 
	C401.154083,727.075623 399.655731,727.359436 399.787018,725.913269 
	C399.908905,724.571106 400.898621,723.979309 402.590576,724.070190 
	C402.933960,724.083496 403.330200,724.324219 403.211151,724.125305 
	C401.969330,723.104980 400.846527,722.283508 399.423767,721.242615 
	C402.211945,720.082947 404.683136,719.055176 407.520264,718.004272 
	C410.597931,718.974854 413.103912,720.373230 415.869812,721.998169 
	C417.031708,722.439880 417.868774,722.786926 418.999634,723.322327 
	C424.123810,726.110596 429.020050,726.417358 433.829071,723.956360 
	C429.192047,723.441345 424.904938,721.442444 420.379333,719.149048 
	C419.590240,719.098511 419.142639,719.043701 418.367126,718.905090 
	C415.550140,717.930664 412.943359,717.356140 410.240112,716.073120 
	C407.103149,712.294922 402.326599,712.078125 398.649231,709.859802 
	C397.111938,708.932373 397.650635,708.282959 397.273376,707.195374 
	C394.426544,704.085388 391.692596,702.510376 387.528687,701.828125 
	C383.387634,701.149597 381.849213,698.467773 381.746857,694.360657 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M359.373230,709.930664 
	C359.029968,709.130371 359.015686,708.424316 359.000671,707.362793 
	C358.868225,705.962158 359.333588,704.333923 357.691345,704.380798 
	C354.743225,704.464905 354.181702,702.721069 354.023041,700.406616 
	C353.823792,697.500610 356.136383,698.945984 357.322540,698.392151 
	C357.719666,698.206787 357.773315,697.285767 357.991089,696.349243 
	C362.758728,695.987122 366.432068,693.824463 369.715424,690.130737 
	C370.903046,690.461853 371.764343,690.906067 372.815277,691.569580 
	C373.609680,692.783447 374.447327,693.302429 375.972412,693.091064 
	C376.735474,693.163574 377.140289,693.316711 377.762726,693.765686 
	C378.008057,693.985779 378.031555,694.284485 378.028717,694.434204 
	C378.919647,695.954468 379.711975,697.397217 380.970825,698.497742 
	C382.417175,699.762207 383.478882,701.263184 382.596191,703.237305 
	C381.632507,705.392517 379.630615,704.819397 377.855316,704.737183 
	C377.060150,704.700317 376.287415,704.356323 375.407532,704.570557 
	C378.510925,708.662354 383.476166,710.511658 386.895813,714.623840 
	C386.400360,716.309631 388.318695,719.206909 386.372131,718.861694 
	C384.750549,718.574036 381.926666,720.794312 381.018402,717.215454 
	C381.452820,716.181641 381.022156,715.755615 380.242981,715.167358 
	C379.988892,714.973022 379.991669,715.011536 380.010742,715.008667 
	C379.594757,714.580017 379.022156,714.496704 378.243256,714.193542 
	C374.308319,711.884216 370.278473,710.234009 367.337311,706.888428 
	C365.437653,708.926758 365.780121,710.859253 367.918152,712.264709 
	C370.350159,713.863220 368.116455,713.936584 367.171814,714.768677 
	C366.997803,715.002441 366.498901,715.002441 366.249451,715.001221 
	C365.835449,715.127441 365.670898,715.254883 365.263367,715.186279 
	C362.976593,713.625427 362.100983,711.009399 359.373230,709.930664 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M364.001495,724.051880 
	C364.197937,722.566284 363.458374,720.935913 365.088043,720.343872 
	C366.668701,719.769531 366.738586,721.422363 367.399231,722.245361 
	C368.369659,723.454224 369.737579,723.964111 371.678467,723.918701 
	C373.763489,725.090210 375.092133,724.926941 376.040375,722.919128 
	C377.501221,719.825867 379.018402,717.976196 380.865967,722.737732 
	C381.130524,723.419495 382.048126,723.847900 382.834045,724.700439 
	C384.776367,726.442322 382.601471,727.864685 382.999634,729.652588 
	C385.883118,730.063416 388.692230,729.814209 386.596863,734.375610 
	C385.609955,736.524048 387.351807,737.935974 389.813843,738.035767 
	C391.563385,738.106689 393.448273,737.505249 395.231598,738.998169 
	C392.756104,742.473694 388.316742,742.901978 385.148865,745.760986 
	C384.540497,746.347412 384.084686,746.470825 383.284912,746.219910 
	C382.987152,746.005066 382.999878,745.997559 383.000641,746.004639 
	C381.682587,745.119141 379.885071,745.645081 378.314209,744.431885 
	C376.919281,743.778870 376.216583,742.884888 375.537415,741.531006 
	C375.287109,740.550598 375.132477,739.845642 375.368530,739.237610 
	C377.341400,734.156006 376.947418,732.927124 372.531555,730.262512 
	C372.291473,730.117554 372.335022,729.648193 372.149323,729.844116 
	C371.921936,730.084045 371.765686,730.430786 371.493896,730.575928 
	C367.786438,732.556030 366.789856,730.367920 366.382141,726.935913 
	C366.453125,726.133911 366.294586,725.453003 366.912720,725.418579 
	C366.517181,725.421753 366.363434,725.506836 365.940125,725.616943 
	C365.489990,725.604919 365.309448,725.567932 364.882874,725.396667 
	C364.357880,725.001953 364.152039,724.692749 364.001495,724.051880 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M387.382629,714.949707 
	C375.910492,708.618103 373.883514,706.602051 373.580566,701.591187 
	C374.778107,701.245911 374.824524,702.847290 375.196228,702.847290 
	C377.358551,702.847412 380.040436,704.986816 381.508362,703.026001 
	C383.235962,700.718384 380.226135,699.895325 378.809875,698.697449 
	C377.799591,697.842834 378.065613,696.570923 377.981201,695.011597 
	C378.449280,694.702271 378.872650,694.820496 379.620605,694.992310 
	C380.127838,695.159851 380.310516,695.273865 380.754578,695.198181 
	C381.119141,694.718567 381.288757,694.474976 381.720306,694.170593 
	C383.352509,698.185547 385.484161,698.232178 389.112823,699.974670 
	C391.617584,701.177490 393.437653,700.415222 395.518280,700.731628 
	C396.388489,700.863953 396.869537,701.047180 396.173004,701.770142 
	C394.311615,703.702148 396.843597,704.896851 396.976807,706.746704 
	C396.633850,707.137817 395.744476,707.326599 395.754089,707.405334 
	C396.509888,713.578796 390.599091,712.640625 387.382629,714.949707 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M453.223694,773.713135 
	C447.816132,776.470520 443.345764,773.773193 438.390991,771.989563 
	C436.388794,769.449219 434.685577,766.941467 435.017334,763.338379 
	C435.023376,762.983765 435.063995,762.954773 435.084290,762.940308 
	C435.104614,762.925781 435.529572,762.700134 435.733734,762.827393 
	C435.937897,762.954712 435.993958,762.989197 436.022583,763.005371 
	C440.190277,766.605652 445.693298,767.180420 450.387726,769.533020 
	C451.977417,770.329651 453.733704,771.115540 453.223694,773.713135 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M372.513824,766.462219 
	C372.754425,759.323181 374.529846,757.684082 380.630615,758.992310 
	C382.571564,762.761292 381.494812,765.410400 377.335999,766.953613 
	C376.839600,767.149048 376.692993,767.298218 376.244812,767.518433 
	C375.742249,767.562439 375.541260,767.535339 375.075592,767.375854 
	C374.254303,766.658325 373.542450,766.535461 372.513824,766.462219 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M409.927094,716.326782 
	C412.902588,713.927124 414.932953,717.538452 417.831360,717.549622 
	C418.766937,719.781067 417.358582,721.074646 416.284180,722.790833 
	C412.904480,722.425964 410.606476,720.496765 408.110535,718.251953 
	C408.914917,718.086182 409.718750,717.840088 409.927094,716.326782 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M408.097046,768.632568 
	C407.585083,769.285339 407.090912,769.609436 406.294006,769.976501 
	C405.991211,770.019470 405.993652,770.002808 405.987030,770.005737 
	C405.208252,769.320129 404.846924,768.368896 404.134460,767.305359 
	C403.419281,764.607117 405.260590,763.953979 406.884460,764.351624 
	C408.734802,764.804688 408.800873,766.626282 408.097046,768.632568 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M382.991974,746.363586 
	C383.422241,746.018005 383.857330,746.031006 384.617737,746.042175 
	C387.297394,746.996643 387.007935,749.431274 387.886566,751.582520 
	C387.753693,752.195190 387.552460,752.435364 387.168457,752.829468 
	C386.845917,753.008118 386.676727,753.008118 386.253784,753.004089 
	C384.998932,750.907349 383.997864,748.814758 382.991974,746.363586 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M404.718933,734.129761 
	C408.486267,735.541138 409.303162,737.760559 406.358154,740.921387 
	C405.996338,740.998291 406.000000,741.000000 406.000793,741.002441 
	C404.973236,739.599243 404.705414,737.768738 403.241791,736.247192 
	C403.492889,735.429504 403.974823,734.841858 404.718933,734.129761 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M375.276245,767.706177 
	C375.690796,767.305969 375.947754,767.235779 376.595459,767.082764 
	C379.952423,768.115234 377.972382,770.321045 378.123749,772.387451 
	C377.963440,772.773499 377.781494,772.833801 377.316284,772.941406 
	C376.692169,772.443909 376.351318,771.899170 375.838135,771.080566 
	C375.044647,769.969910 374.890656,769.040833 375.276245,767.706177 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M403.015808,736.367615 
	C405.754364,736.189270 406.068726,738.080261 406.001129,740.623413 
	C404.307831,740.062805 402.983673,738.863708 403.015808,736.367615 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M401.634827,746.660767 
	C401.272980,747.353882 401.143402,748.002136 400.809631,748.134155 
	C399.667389,748.586060 400.075439,747.441406 400.174255,747.203552 
	C400.518951,746.373779 400.299133,745.062500 401.745392,745.014954 
	C401.754791,745.469727 401.764160,745.924438 401.634827,746.660767 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M403.930542,767.458740 
	C405.008392,767.477783 405.696381,768.218445 405.957214,769.687866 
	C404.581055,769.935059 404.000946,769.113831 403.930542,767.458740 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M399.489136,752.365173 
	C399.014740,751.346252 399.502533,750.940674 400.189941,750.746643 
	C400.323364,750.708923 400.727112,751.076782 400.744720,751.280273 
	C400.806244,751.990784 400.637177,752.613403 399.489136,752.365173 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M417.280548,774.104736 
	C417.222717,773.625854 417.434875,773.322815 417.845428,772.820312 
	C418.467072,772.729858 418.890350,772.838867 419.621826,773.057983 
	C419.939697,773.547913 419.949310,773.927856 419.935669,774.591553 
	C419.029907,775.055298 418.204071,775.010071 417.280548,774.104736 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M400.256287,778.590698 
	C399.167084,778.033752 399.983887,777.530029 400.170044,776.993347 
	C400.176575,776.974487 400.631134,777.111084 400.876892,777.175232 
	C400.749023,777.625427 400.621124,778.075684 400.256287,778.590698 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M379.971375,715.262939 
	C381.076630,715.112122 382.685944,714.817810 381.274109,716.862549 
	C380.665375,716.451721 380.309601,716.002258 379.971375,715.262939 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M378.134674,714.311768 
	C378.609436,714.149475 379.196411,714.289246 379.906616,714.717407 
	C379.435547,714.875122 378.841217,714.744446 378.134674,714.311768 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M371.997375,721.751648 
	C371.884338,721.728943 371.776215,721.457947 371.828369,721.096802 
	C371.988647,721.006714 372.002289,721.503296 371.997375,721.751648 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M398.542603,723.469971 
	C398.570557,723.519348 398.514679,723.420532 398.542603,723.469971 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M425.458588,772.442871 
	C425.518280,772.406494 425.398895,772.479187 425.458588,772.442871 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M385.546509,756.466980 
	C385.576874,756.519348 385.516174,756.414612 385.546509,756.466980 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M387.239441,752.898926 
	C387.149506,752.716675 387.329712,752.457092 387.740631,752.079895 
	C387.896271,752.283875 387.742371,752.570312 387.239441,752.898926 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M736.671814,614.916748 
	C738.052979,615.000610 739.108398,615.003662 740.559570,615.009399 
	C741.512268,615.518433 741.784058,616.149536 741.718994,617.244873 
	C741.277039,618.707886 740.181824,619.290344 739.544556,620.606079 
	C739.252686,621.573364 739.014099,622.205505 738.610657,623.141785 
	C736.433533,626.775452 735.393555,629.976746 738.733826,633.261414 
	C739.980591,634.487366 739.773193,636.291260 739.091492,637.948364 
	C738.141174,640.258484 737.673767,642.697083 737.356384,645.522827 
	C737.251770,646.341003 737.151062,646.801514 736.886963,647.585327 
	C735.572937,649.427124 735.015686,651.210571 734.478210,653.379150 
	C734.147095,654.913269 733.714539,656.020020 732.624023,657.180176 
	C729.534790,659.264465 725.167419,657.226257 722.984924,661.368469 
	C722.697144,661.823364 722.570984,661.994263 722.223938,662.381958 
	C721.381226,663.099731 720.875671,663.673584 720.758057,664.829956 
	C720.514771,666.046082 720.055237,666.786316 719.006836,667.512207 
	C715.590881,667.179016 712.803101,667.216797 709.928955,669.262146 
	C707.729248,670.827637 705.301941,670.031250 705.254578,666.590332 
	C705.235718,665.221741 704.950134,664.071411 704.298828,662.829407 
	C700.657227,655.885437 700.855469,648.812744 704.025635,641.764160 
	C704.947449,639.714539 706.261841,638.011719 709.224731,638.445801 
	C711.605469,640.766846 710.283447,643.313782 710.334595,645.708130 
	C710.707886,643.484253 711.416199,641.246338 709.826538,638.836914 
	C708.007812,636.007751 709.119385,633.640747 710.559631,631.639893 
	C712.669739,628.708313 715.171509,625.973633 717.898438,623.669983 
	C720.398071,621.558350 723.481201,620.361267 724.564087,616.715698 
	C725.142639,614.768127 726.947144,615.357666 728.116699,616.652527 
	C729.234375,617.889893 728.757019,619.484924 729.029297,621.228943 
	C729.431091,617.350098 731.603027,615.892090 735.285400,616.016235 
	C735.645996,615.344666 735.959534,615.052551 736.671814,614.916748 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M751.059448,480.696716 
	C750.068176,482.275970 748.856140,480.207855 748.006470,481.441101 
	C745.615662,484.911316 738.507874,484.580139 740.582825,492.231140 
	C738.673401,490.358124 738.432556,488.817902 738.046570,487.373413 
	C737.555725,485.536469 737.630005,482.741760 734.877502,483.370087 
	C731.988647,484.029541 733.455017,486.479675 733.934204,488.682495 
	C732.886047,490.134674 734.187134,491.950195 732.646729,493.055542 
	C730.967712,492.754456 730.859070,491.189667 730.205811,489.614380 
	C727.875366,483.514404 727.728027,477.508240 727.587402,471.312988 
	C726.900757,471.041504 726.482544,470.930420 725.735901,470.708649 
	C723.087402,469.098694 722.740601,466.677734 722.434326,463.876556 
	C722.591003,462.523102 723.169922,461.755005 723.959351,460.709229 
	C725.231567,459.686615 726.160339,457.448608 727.485229,460.314575 
	C727.462402,460.476562 727.577271,460.522614 727.668945,460.352356 
	C730.833130,458.846100 734.649658,459.229431 737.328064,456.112122 
	C739.479614,455.873566 740.911560,456.736145 742.561646,457.970520 
	C742.969482,458.394684 743.109009,458.583588 743.332764,459.110596 
	C743.358521,460.180756 742.952759,460.686737 742.068054,461.179443 
	C741.422058,461.351959 741.282227,461.149902 741.224854,460.845123 
	C740.978943,461.093994 741.063293,461.308777 741.652710,461.756165 
	C742.166138,462.863403 742.210510,463.746033 742.172485,464.983948 
	C742.057617,465.792450 741.923401,466.239075 741.548584,466.968079 
	C740.358643,468.245850 739.173950,468.677521 737.761169,468.576233 
	C736.725342,468.501984 735.283264,467.439270 734.792908,469.104401 
	C734.119507,471.391327 735.793518,473.199951 736.669128,475.101227 
	C737.339478,476.556732 738.761719,475.655090 739.692261,475.294861 
	C742.603882,474.167480 744.554077,471.296631 748.133301,470.862793 
	C750.657776,473.762726 751.526672,476.905640 751.059448,480.696716 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M730.558838,488.981445 
	C732.138855,489.610962 731.799927,490.914429 732.451477,492.347534 
	C732.875916,491.158112 733.181580,490.301453 733.726379,489.248779 
	C734.296753,491.108582 734.628052,493.164368 734.985229,495.604431 
	C732.592834,498.981934 734.452637,502.700287 735.425415,504.758667 
	C736.876770,507.829926 736.541809,510.896912 737.914673,513.833923 
	C737.914673,510.989166 737.914673,508.144409 737.914673,505.299683 
	C738.285950,505.292084 738.657288,505.284515 739.028564,505.276917 
	C739.028564,508.201538 739.028564,511.126160 739.014282,514.501221 
	C740.089050,519.295593 739.159424,523.723572 739.736938,528.495239 
	C739.285828,532.041565 738.545776,535.174011 738.047729,538.344421 
	C737.823853,539.769531 737.578796,540.877991 735.532227,541.068726 
	C734.740967,540.856384 734.523071,540.490662 734.551636,539.661743 
	C735.442139,537.707520 735.972717,536.067810 734.787354,533.997559 
	C734.493652,529.722534 735.341797,525.797791 735.010620,521.773071 
	C735.019470,521.707520 734.980225,521.853821 734.991089,522.032654 
	C734.386475,535.026123 735.174255,547.887817 733.728210,560.673157 
	C733.509338,562.607910 733.580750,564.712341 731.152832,566.288818 
	C728.500916,562.126282 729.491394,557.650940 729.967102,553.407898 
	C730.282654,550.594055 730.784058,547.857056 730.032837,544.617188 
	C729.321411,534.976135 729.848022,525.704346 728.188477,516.132202 
	C727.968079,515.435913 727.890442,515.155396 727.748413,514.446533 
	C728.047546,509.777893 726.548157,505.679443 726.775146,501.048950 
	C728.107544,496.797943 726.819580,492.213898 730.558838,488.981445 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M737.774963,623.252258 
	C737.994934,622.247437 737.997253,621.499084 737.999878,620.376587 
	C738.761536,619.330078 739.826050,619.148743 740.762329,618.204712 
	C740.969421,617.241577 740.969543,616.498169 740.962463,615.383545 
	C741.405701,615.005859 741.856079,614.999512 742.643494,614.991699 
	C743.865906,618.319458 748.837402,616.520935 749.473022,620.163757 
	C751.543335,619.796082 751.949707,618.298523 751.937561,616.523682 
	C751.929688,615.368225 750.672729,613.816223 752.943359,613.394531 
	C753.200256,616.421936 758.666687,614.103455 757.800049,617.700195 
	C755.117798,628.832947 751.440613,639.634949 745.570984,649.566223 
	C744.901184,650.699463 743.903687,651.143433 742.599670,650.664368 
	C740.168213,649.771240 742.224304,647.697693 741.223022,646.158997 
	C738.417480,642.109497 739.709839,638.494507 741.823975,634.623047 
	C742.476013,633.640015 743.041626,632.990051 742.765381,631.771240 
	C741.805969,628.281128 736.790771,627.744934 737.774963,623.252258 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M737.001770,614.670654 
	C736.683838,615.113037 736.427551,615.305115 736.116699,615.789307 
	C732.081970,617.045166 730.376587,613.915955 728.709778,611.592957 
	C727.027161,609.247864 729.920410,607.927734 731.290283,605.965637 
	C729.668335,605.283081 727.607239,604.852051 728.224792,602.171021 
	C728.464844,601.623474 728.646301,601.375671 728.906799,600.987671 
	C728.860779,601.310791 728.427979,601.335327 727.769043,601.426758 
	C724.369690,598.014282 726.231201,594.366577 727.130249,590.492188 
	C728.564453,589.308777 729.822876,588.930542 731.599731,589.700562 
	C732.965576,590.930359 733.874634,590.708984 733.880615,589.336548 
	C733.893616,586.346191 735.402527,585.539978 737.988831,586.189392 
	C740.664612,586.861206 742.525085,588.384399 741.959534,591.450989 
	C741.128601,595.955933 740.111633,600.426331 739.195312,604.915833 
	C738.549805,608.078491 739.963928,611.717590 737.001770,614.670654 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M735.424194,538.536621 
	C735.157471,539.340515 735.120789,539.847351 735.116211,540.735901 
	C736.020386,543.975220 735.989624,546.936340 736.046814,549.996033 
	C737.717896,549.293579 736.546753,547.472412 737.778748,546.363892 
	C738.776367,544.851929 740.045044,544.648315 741.703613,544.769836 
	C742.038940,544.970459 741.998230,544.998230 742.012451,544.977783 
	C742.363403,545.388672 742.480164,545.899170 742.713135,546.635620 
	C742.304871,552.367737 739.832153,557.261414 737.655945,562.226135 
	C736.798096,564.183167 734.988586,565.786438 737.561951,567.801697 
	C738.358276,568.425354 738.159546,568.679138 735.328857,569.978271 
	C732.995850,569.321655 732.652283,570.814636 732.116394,572.202148 
	C731.503479,573.789185 730.714722,575.253601 728.440430,573.543457 
	C727.989807,563.818542 728.155762,554.441406 729.255737,544.700500 
	C729.698547,544.131042 729.926208,543.945374 730.577759,543.879883 
	C732.799805,546.756592 732.159180,549.780029 731.557068,552.601807 
	C730.678101,556.720520 731.111511,560.764038 731.420837,564.853027 
	C733.180603,549.848572 734.253418,534.810120 734.093689,519.214233 
	C734.557434,518.494263 734.991760,518.432495 735.719727,518.759521 
	C735.816284,523.808472 735.644226,528.644287 735.748413,533.744385 
	C736.976379,535.513062 736.356567,536.879883 735.424194,538.536621 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M760.113586,538.701538 
	C759.400574,538.815857 758.602295,538.569275 758.610901,538.354919 
	C758.790771,533.847412 756.565857,533.374756 752.943359,534.724426 
	C752.117065,535.032227 751.016357,534.603333 749.654053,534.268433 
	C748.913025,532.187317 749.269775,530.395752 749.660889,528.287354 
	C749.767456,527.770569 749.819336,527.569397 749.984009,527.088501 
	C750.282288,526.338257 750.935059,526.046387 750.563354,525.115906 
	C750.472046,524.646484 750.455750,524.452759 750.468872,523.965210 
	C750.617920,523.081482 750.806458,522.511719 751.118164,521.654602 
	C751.333374,521.178040 751.433838,520.991272 751.718811,520.540222 
	C752.484375,519.591980 752.969055,518.835754 753.772705,517.857727 
	C755.382202,516.619568 756.839844,516.117065 758.877930,516.806580 
	C761.640808,521.496887 760.038330,526.360718 760.550049,531.015503 
	C760.811096,533.390015 760.636353,535.910095 760.113586,538.701538 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M728.718506,574.253174 
	C732.120422,573.522949 730.426270,570.664429 731.446167,569.162231 
	C731.996887,568.351074 732.269958,567.195557 733.474792,567.225342 
	C734.792603,567.257996 734.926453,568.347900 735.019226,569.763672 
	C734.015137,576.459534 732.992004,582.770996 731.957153,589.506348 
	C730.800232,590.236694 729.655029,590.543152 728.162964,590.955322 
	C727.396667,590.645020 727.085144,590.159851 726.778503,589.182861 
	C726.281128,585.902100 726.429993,583.045288 726.765747,579.766846 
	C727.226807,577.700317 727.475586,576.006653 728.718506,574.253174 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M750.946411,481.318542 
	C749.924866,477.918793 749.020752,474.825684 747.978455,471.382080 
	C745.410278,469.574829 745.120911,467.164825 745.080994,464.315552 
	C745.254150,463.732605 745.356201,463.509521 745.714417,463.020447 
	C746.687073,462.490387 747.424683,462.476837 748.527649,462.691650 
	C749.525635,463.289276 749.877014,463.953888 750.208008,465.067688 
	C751.943787,474.660217 755.531555,483.334167 757.940552,492.750671 
	C758.609375,496.563934 759.849792,499.926605 758.983887,503.871826 
	C758.690857,504.474274 758.537170,504.695831 758.029114,505.110901 
	C756.690308,505.267181 756.337891,504.592743 756.118225,503.372864 
	C755.820007,496.992615 753.798218,491.405273 752.041931,485.365326 
	C751.512390,484.567505 751.187683,484.102142 751.177612,483.212738 
	C751.161316,482.493408 751.112549,482.059296 750.946411,481.318542 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M755.861206,503.055298 
	C756.403625,503.456207 756.700928,503.939056 757.095093,504.706360 
	C759.993469,508.320984 759.618591,512.184753 758.853394,516.480713 
	C757.256470,517.418396 755.861206,517.955627 754.224976,518.772949 
	C753.580811,519.389221 753.120911,519.605469 752.216736,519.673035 
	C751.321655,519.469177 750.894531,519.179077 750.342896,518.434326 
	C750.053162,517.845825 749.965759,517.597046 749.806274,516.959839 
	C749.452820,511.822601 751.506409,507.993896 754.744202,504.241669 
	C755.211243,503.683014 755.413635,503.410156 755.861206,503.055298 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M758.748413,517.282227 
	C758.145264,513.193665 757.638855,509.506165 757.162170,505.404724 
	C757.380005,504.976654 757.568115,504.962494 758.038330,504.927704 
	C761.944214,507.134521 761.102417,510.868225 761.481750,514.630432 
	C762.585266,519.320862 762.741943,523.649597 762.455566,528.448120 
	C762.400330,529.789612 762.194885,530.640747 761.483032,531.794800 
	C761.152893,535.803040 761.047668,539.428650 760.553101,543.510254 
	C760.085449,542.598755 760.007080,541.231079 759.875854,539.455444 
	C759.497070,531.926086 759.171143,524.804749 758.748413,517.282227 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M746.963257,555.119141 
	C746.704346,561.817444 746.616821,568.082397 743.519104,573.804260 
	C745.084717,565.742920 744.337830,557.406372 745.953491,548.023071 
	C747.520813,551.115906 746.813660,552.923218 746.963257,555.119141 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M754.662109,504.010254 
	C754.180908,508.201721 750.755188,511.504089 750.666504,516.407715 
	C750.009399,517.855896 749.091064,517.837280 747.880615,517.047241 
	C746.961853,510.969269 750.139282,507.255432 754.662109,504.010254 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M750.434692,526.747437 
	C751.131348,529.266235 750.504089,531.374207 749.465210,533.736084 
	C747.690247,534.337646 746.954895,536.377808 744.711548,536.060547 
	C745.296692,533.219482 747.378235,530.795898 747.022095,527.416687 
	C747.565002,526.486084 748.157410,526.180176 749.221802,526.172974 
	C749.772339,526.311584 749.980469,526.408875 750.434692,526.747437 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M775.005005,548.413208 
	C775.465820,546.982422 775.920044,545.974243 776.805237,544.009460 
	C777.103149,548.264343 775.900208,551.262146 775.205688,554.718018 
	C775.006958,558.900391 773.954895,562.485596 772.701294,566.038757 
	C772.252441,560.120789 774.627991,554.617798 775.005005,548.413208 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M750.750000,526.731812 
	C750.680786,526.988647 750.446106,526.993896 750.329163,527.004272 
	C749.461060,526.362000 748.519836,526.537903 747.411255,526.181641 
	C746.832581,523.896301 748.073792,521.857056 747.217285,519.477173 
	C747.997070,518.027405 749.064087,517.547485 750.649170,517.814087 
	C751.096680,518.343140 751.312439,518.587280 751.761108,518.945923 
	C752.326172,519.453552 752.488281,519.890503 752.232422,520.708374 
	C752.021729,521.012329 752.000000,521.006042 752.010132,521.014771 
	C751.260803,521.601868 751.024414,522.427979 750.878235,523.646240 
	C750.837219,524.145325 750.849670,524.320312 750.934021,524.748535 
	C751.131409,525.166138 751.256897,525.330688 751.181030,525.731384 
	C750.926147,526.136719 750.872681,526.305786 750.750000,526.731812 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M760.539062,550.690857 
	C760.837585,551.351440 760.902893,551.830383 761.016602,552.669739 
	C760.693176,556.028870 760.321289,559.027527 759.893005,562.446045 
	C759.690674,563.022217 759.544678,563.178467 759.112732,563.189575 
	C759.040039,559.243286 759.253357,555.442322 759.717407,551.317871 
	C760.080750,550.832703 760.193298,550.670959 760.539062,550.690857 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M758.566040,563.315430 
	C758.994751,563.011658 759.162720,562.979065 759.583618,562.906189 
	C759.467285,565.927124 759.097961,568.988281 758.728638,572.049500 
	C758.341858,572.065979 757.955017,572.082397 757.568176,572.098877 
	C757.813904,569.261475 758.059692,566.424011 758.566040,563.315430 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M756.019043,568.223999 
	C754.116699,569.289246 753.256775,568.690430 753.123413,566.944336 
	C753.057068,566.076599 753.314819,565.096497 754.293091,565.118164 
	C756.062805,565.157349 757.100891,565.989990 756.019043,568.223999 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M739.958618,528.441406 
	C737.923401,524.676697 739.544861,520.208496 739.013184,515.420105 
	C741.069214,519.165344 739.439026,523.660095 739.958618,528.441406 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M741.904297,544.641663 
	C740.913086,545.308167 739.787231,545.645813 738.305786,546.024719 
	C738.184875,543.219116 740.037048,543.896240 741.904297,544.641663 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M760.852905,550.858643 
	C760.772339,550.872498 760.364807,550.897522 760.166443,550.945984 
	C759.964600,548.937988 759.961060,546.881531 760.060669,544.395752 
	C760.163818,543.966370 760.489990,543.951477 760.653198,543.954468 
	C760.863525,545.697266 760.910645,547.437073 761.029297,549.607666 
	C761.178223,550.207153 761.255615,550.375916 761.167725,550.768921 
	C761.002563,550.993042 760.933533,550.844727 760.852905,550.858643 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M803.998779,578.998779 
	C804.164124,578.106079 803.632080,576.911865 804.995544,576.549011 
	C805.187134,576.497986 805.633972,576.803040 805.710876,577.028931 
	C806.039978,577.995483 805.565674,578.586365 804.330078,578.949463 
	C804.002441,579.002441 804.000000,579.000000 803.998779,578.998779 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M750.868652,517.751099 
	C749.908142,518.395142 748.898071,518.724182 747.568237,519.096313 
	C747.263367,518.583557 747.278259,518.027710 747.488770,517.098755 
	C748.487061,516.741699 749.289673,516.757751 750.396484,516.791626 
	C750.740112,517.018311 750.779541,517.227173 750.868652,517.751099 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M750.962524,483.075562 
	C751.464783,483.425629 751.719543,483.923706 751.931213,484.714783 
	C750.946960,484.774750 749.843811,484.648193 750.962524,483.075562 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M736.846069,503.678894 
	C735.963257,504.378937 736.233704,503.608643 736.163757,503.279999 
	C736.139832,503.167511 736.302979,503.015198 736.380676,502.881104 
	C736.546570,503.077393 736.712463,503.273682 736.846069,503.678894 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M734.995605,496.002563 
	C735.277039,496.539307 735.574219,497.067871 735.823486,497.618103 
	C735.837891,497.649841 735.462891,497.857910 735.269043,497.983948 
	C735.183472,497.513397 735.097961,497.042877 735.011719,496.280518 
	C735.011047,495.988708 735.000977,495.999146 734.995605,496.002563 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M747.114380,526.207703 
	C748.046143,526.119995 748.914490,526.302368 749.997498,526.749695 
	C749.430359,527.000366 748.648560,526.986084 747.479858,527.001404 
	C747.078979,526.846619 747.064941,526.662170 747.114380,526.207703 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M742.900696,546.542236 
	C742.586304,546.404541 742.301758,545.962402 742.021912,545.238892 
	C742.835938,545.025635 742.930115,545.598633 742.900696,546.542236 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M788.408447,564.745911 
	C788.556335,564.864014 788.576599,565.064941 788.596924,565.265930 
	C788.491516,565.120239 788.386108,564.974548 788.408447,564.745911 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M790.656982,457.829987 
	C792.245239,459.716187 791.857544,461.515869 791.028625,463.676880 
	C790.988403,464.033173 790.853027,464.201111 790.908875,464.107819 
	C792.476013,465.246246 793.804932,461.318481 795.549683,463.977203 
	C797.130981,466.387054 795.889526,467.377899 794.425537,469.216583 
	C793.318665,470.606750 793.504150,473.401978 793.820435,475.463593 
	C794.328613,478.775970 794.085815,481.640015 789.956787,484.599060 
	C792.948730,484.209656 794.424194,483.778992 795.963074,483.885223 
	C798.840027,484.083862 799.716003,485.738831 798.732300,487.812469 
	C797.080322,491.294556 796.501221,494.215271 798.358459,498.043396 
	C799.919922,501.261902 794.628967,507.054993 790.790466,506.584503 
	C786.352905,506.040588 782.563171,503.055817 777.777893,503.026428 
	C775.917053,503.014984 778.193726,499.113129 775.356995,498.075592 
	C774.872314,497.839569 774.744324,497.679718 774.538513,497.223450 
	C774.517639,495.863647 774.083740,494.966553 773.384705,493.822632 
	C773.091003,492.882324 773.102051,492.238647 773.428833,491.308533 
	C776.073608,487.552734 772.804749,485.375427 771.499939,482.434387 
	C770.279602,480.492950 769.238770,478.840912 769.413086,476.423279 
	C776.236389,469.723816 782.591431,462.948425 790.656982,457.829987 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M774.786682,420.820557 
	C774.914673,421.899384 774.678040,422.705566 773.331787,423.012146 
	C772.867981,423.178741 772.723877,423.289886 772.559937,423.654419 
	C772.540039,423.907837 772.298767,424.329102 772.653198,424.657227 
	C773.177612,424.980255 773.347534,424.975128 773.772339,424.976807 
	C774.242493,425.247864 774.512390,425.431854 774.935608,425.763489 
	C775.598267,427.342499 773.140747,429.767487 776.556152,430.135315 
	C777.608521,430.248596 778.020874,431.359070 777.309631,431.924408 
	C773.052063,435.308746 774.842773,441.582611 770.634583,445.239410 
	C768.987061,446.671051 765.422852,445.309204 765.423950,449.015625 
	C765.424438,450.441437 764.373108,452.207794 765.583313,453.202972 
	C766.966492,454.340363 768.277100,452.797302 769.442810,451.987640 
	C773.859009,448.920227 774.457825,449.145966 775.000732,454.590820 
	C774.099304,455.097809 773.394531,455.381042 773.860352,456.493286 
	C774.311829,457.571045 775.070923,457.573120 776.201843,457.472656 
	C780.380981,457.101410 782.903076,453.799591 786.648560,452.035431 
	C786.669312,453.425812 786.338562,454.851105 786.010010,456.646729 
	C782.509460,462.848419 777.885437,467.649841 772.460205,471.702667 
	C771.518860,472.405914 770.439026,472.814362 768.899292,472.241119 
	C766.257690,468.379700 766.267944,463.873596 764.062439,459.774170 
	C760.718140,449.659821 757.289246,439.976929 752.971985,430.629456 
	C751.115417,426.609741 753.500000,424.030701 756.397522,421.299072 
	C759.149963,413.613983 769.298706,411.446960 774.830566,417.193787 
	C776.055725,418.466614 773.352661,419.386108 774.786682,420.820557 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M768.968872,472.274261 
	C774.506226,469.922638 777.495911,465.287323 781.172119,461.422363 
	C782.527527,459.997345 783.994995,458.678925 785.711792,457.164429 
	C792.697937,453.203461 794.637451,445.764282 798.910034,440.107666 
	C799.684448,439.082428 799.854004,437.582062 801.658386,437.053802 
	C801.703308,441.201385 798.866638,444.781067 799.037354,449.498291 
	C798.753296,450.558197 798.170654,450.630432 797.309937,450.811584 
	C794.498352,452.522217 793.026855,455.058319 791.140259,457.640411 
	C784.297058,463.912079 777.592834,469.887177 770.577881,475.957886 
	C768.807007,475.325958 768.571045,474.051727 768.968872,472.274261 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M810.614502,499.997803 
	C808.403259,498.657349 807.621399,496.976074 808.573364,494.810852 
	C809.937073,491.709229 806.020874,487.722168 810.540955,485.030273 
	C812.165222,486.619476 811.138367,488.583588 811.788208,490.689240 
	C812.025452,491.013123 811.990662,491.004822 811.996826,490.988037 
	C812.348328,494.044861 813.807068,497.235321 810.614502,499.997803 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M774.481567,496.865662 
	C774.851562,497.070557 774.800903,497.555023 774.900635,497.777222 
	C775.612793,503.374664 776.225220,508.749939 776.791260,514.532837 
	C776.499573,515.076599 776.254211,515.212708 775.596069,515.263916 
	C772.497375,511.567505 773.363464,507.172821 772.521118,502.732819 
	C772.626648,500.310608 770.672180,497.721954 774.481567,496.865662 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M780.095459,528.958862 
	C779.506226,529.256226 779.363342,529.365234 779.267761,529.692749 
	C777.669983,532.145508 778.913513,534.773438 778.239624,537.587280 
	C777.984802,538.717834 777.924988,539.471130 777.915894,540.600159 
	C778.363586,541.347412 778.509705,541.799927 778.119568,542.581604 
	C776.060486,538.010437 777.572449,532.777710 775.905029,527.521362 
	C775.766541,526.882874 775.684387,526.660889 775.762817,526.122192 
	C776.146301,525.725891 776.369202,525.646301 776.872742,525.784363 
	C777.406494,526.219604 777.659668,526.437195 778.046509,526.874023 
	C778.381165,527.380493 778.624695,527.625427 779.088684,528.019287 
	C779.266785,528.210327 779.353027,528.417603 779.464844,528.427917 
	C779.878113,528.522522 780.179626,528.606750 780.095459,528.958862 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M810.141174,500.266815 
	C813.255493,497.835785 811.436279,494.571808 811.994995,491.402527 
	C813.496887,494.885132 813.737549,499.004150 813.990845,503.553711 
	C812.232544,504.034271 811.224487,502.937927 810.225952,501.324951 
	C810.094482,500.864655 810.102539,500.693420 810.141174,500.266815 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M776.997742,426.277679 
	C777.145996,423.636078 779.018860,422.918732 780.752808,421.894196 
	C781.139526,423.847656 781.232117,425.621002 780.121704,427.639862 
	C778.335571,428.884277 777.375732,428.379761 776.997742,426.277679 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M820.053772,498.605774 
	C818.329468,499.015442 817.907837,497.612061 817.152405,496.113708 
	C817.415344,494.540466 817.316406,492.262421 819.653076,494.804565 
	C819.930847,496.039581 819.991150,497.118866 820.053772,498.605774 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M781.258301,515.874084 
	C781.509399,515.361328 781.445862,514.827576 781.591492,514.762573 
	C783.288574,514.005188 782.921570,515.434937 782.724915,516.079285 
	C782.425903,517.058716 782.079102,518.690308 780.820740,518.317566 
	C779.712708,517.989441 780.216431,516.779785 781.258301,515.874084 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M798.555054,513.167236 
	C798.673950,514.751282 797.620789,515.484558 796.258789,515.913025 
	C796.156006,515.147827 796.053162,514.382568 795.973633,513.289185 
	C796.728821,513.012512 797.460693,513.064026 798.555054,513.167236 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M777.134888,525.559692 
	C776.980408,525.991577 776.807434,525.981201 776.379150,525.922058 
	C774.055969,523.959412 774.482361,521.615173 775.178223,518.859741 
	C775.584473,518.253967 775.795166,518.046692 776.436218,517.945618 
	C776.949890,520.406982 777.033203,522.762207 777.134888,525.559692 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M780.057129,529.139954 
	C779.709717,529.226746 779.580017,528.839905 779.578308,528.639038 
	C780.051331,527.194153 780.526001,525.950012 782.594971,526.033630 
	C782.978027,526.034607 782.988831,526.516602 782.998901,526.757446 
	C783.189026,527.165588 783.369080,527.332825 783.279419,527.751221 
	C782.141357,528.352722 781.272888,528.702942 780.057129,529.139954 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M812.036011,490.571167 
	C810.350037,489.538055 811.209229,487.533936 810.897705,485.358765 
	C810.903931,484.510925 810.942810,484.071747 810.985962,483.301453 
	C811.162537,482.879761 811.334900,482.789215 811.729736,482.887970 
	C811.983643,485.427887 812.015076,487.778564 812.036011,490.571167 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M797.735474,461.541504 
	C795.903809,461.536774 795.988037,460.795593 796.817444,459.849670 
	C797.271790,459.331421 797.914673,459.032257 798.485901,459.550446 
	C799.372192,460.354309 798.758972,460.920105 797.735474,461.541504 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M797.196899,476.695190 
	C796.132751,476.075195 795.813843,475.365143 796.457092,474.619690 
	C796.703064,474.334625 797.480164,474.088959 797.670776,474.245300 
	C798.658630,475.055481 798.044067,475.918762 797.196899,476.695190 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M806.811401,446.910950 
	C806.381470,447.609131 805.881470,447.806152 805.543518,447.328186 
	C805.088806,446.684937 805.434387,446.055115 806.095459,445.817291 
	C806.722656,445.591675 806.857483,446.140198 806.811401,446.910950 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M797.308105,451.138184 
	C797.164368,450.195557 797.762817,449.972443 798.717590,449.844116 
	C799.026672,450.554108 800.228271,451.211853 799.175354,451.787445 
	C798.828186,451.977234 798.106995,451.482910 797.308105,451.138184 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M806.003662,423.004272 
	C805.910095,423.628784 805.893982,424.331177 805.100159,424.470459 
	C804.932495,424.499878 804.697937,424.148376 804.494202,423.972412 
	C804.808960,423.672119 805.123718,423.371826 805.715576,423.031891 
	C805.992676,422.992218 806.000000,423.000000 806.003662,423.004272 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M776.941162,517.664673 
	C776.866638,518.051819 776.500671,518.023621 776.318115,518.042236 
	C775.370483,517.454163 775.253235,516.651489 775.678040,515.403381 
	C775.917297,515.049561 776.325562,514.952881 776.535217,514.946655 
	C776.835144,515.719482 776.925415,516.498535 776.941162,517.664673 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M795.245239,433.008484 
	C795.407410,432.896820 795.570251,432.950897 795.733093,433.004944 
	C795.570679,433.061371 795.408325,433.117798 795.245239,433.008484 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M801.007568,503.232056 
	C801.121277,503.405975 801.065002,503.582214 801.008667,503.758453 
	C800.951660,503.583771 800.894653,503.409088 801.007568,503.232056 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M802.000000,437.000610 
	C802.116577,436.587097 802.233215,436.173004 802.349854,435.758881 
	C803.037720,436.063110 802.645691,436.415894 802.197510,436.880859 
	C802.000000,436.999359 802.000000,437.000000 802.000000,437.000610 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M797.974365,426.184540 
	C798.058838,425.736938 798.329895,425.451782 798.885559,425.073425 
	C798.889954,425.477295 799.267578,426.460144 797.974365,426.184540 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M795.975159,426.265259 
	C796.118164,426.020050 796.299194,426.013824 796.752319,426.005615 
	C796.854065,426.416412 796.683716,426.829193 796.513367,427.241943 
	C796.346619,426.996033 796.179932,426.750122 795.975159,426.265259 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M775.281311,425.987488 
	C774.705688,425.893646 774.439514,425.707001 774.131470,425.207550 
	C774.573669,425.155121 775.119995,425.326630 775.844299,425.729553 
	C776.022217,425.960938 775.528442,425.983551 775.281311,425.987488 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M809.635254,456.756073 
	C809.620728,456.993347 809.491638,457.122742 809.362549,457.252136 
	C809.415283,457.050842 809.468018,456.849518 809.635254,456.756073 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M806.329712,511.720337 
	C805.890869,512.103149 805.336548,512.164185 804.372375,512.121826 
	C804.436584,511.621552 804.910522,511.224579 805.697632,510.786682 
	C806.078552,510.963379 806.146362,511.180969 806.329712,511.720337 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M779.110229,527.996948 
	C778.937134,528.037231 778.607544,527.864197 778.229004,527.392212 
	C778.437927,527.323425 778.695801,527.553528 779.110229,527.996948 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M804.002747,440.003662 
	C803.915710,440.039398 803.825989,440.071503 803.864502,440.049072 
	C803.992676,439.994476 804.000000,440.000000 804.002747,440.003662 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M811.887207,482.899567 
	C811.952209,483.077240 811.472778,483.009796 811.231445,482.990051 
	C811.192078,482.634979 811.393372,482.297485 811.887207,482.899567 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M787.000000,451.999390 
	C787.086426,451.963928 787.172852,451.929016 787.129639,451.947327 
	C787.000000,452.000519 787.000000,452.000000 787.000000,451.999390 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M777.515442,451.544250 
	C777.468933,451.569824 777.561951,451.518677 777.515442,451.544250 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M774.998169,455.001404 
	C775.111816,455.134308 775.227295,455.265778 775.342834,455.397247 
	C775.263794,455.350189 775.184692,455.303131 775.054077,455.126190 
	C775.002502,454.996338 775.000000,455.000000 774.998169,455.001404 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M741.513794,659.935303 
	C742.880310,661.385010 745.243408,661.824829 744.138550,664.692444 
	C743.480591,666.400391 749.283264,668.520813 752.431763,667.682251 
	C753.413269,667.420898 754.287109,665.751526 755.387634,667.245056 
	C756.414978,668.639221 755.975464,669.936890 754.796570,671.204773 
	C752.709961,673.448853 752.054199,676.420410 751.040283,679.584717 
	C748.684448,682.290222 746.581238,684.853943 743.384644,686.684509 
	C738.921448,686.047302 734.496582,688.661804 730.538696,685.494141 
	C729.625671,684.763489 729.318115,686.193787 729.030212,686.869629 
	C728.574951,687.938354 727.965393,688.940918 727.572205,690.025513 
	C726.154968,693.934937 723.541809,694.460144 719.758545,692.675049 
	C718.274536,691.789734 716.953796,690.747803 718.553406,689.540222 
	C721.810608,687.081116 720.396973,684.889709 718.707458,682.395386 
	C715.933411,678.299927 716.196167,671.189697 719.411682,667.796387 
	C720.503113,667.507568 721.162048,667.745117 721.995605,668.474548 
	C722.989380,669.969177 722.608215,671.309204 722.612610,672.609924 
	C722.624390,676.078918 723.939026,677.368530 727.477173,675.169800 
	C727.882324,674.805115 728.035278,674.649475 728.410156,674.253418 
	C729.730042,672.931335 730.403259,671.455017 732.099487,670.515686 
	C732.903320,670.342346 733.383179,670.398376 733.864441,670.409241 
	C737.480347,670.491089 738.316406,669.064697 736.241638,665.712891 
	C736.415833,664.921326 736.606140,664.501282 737.053284,663.828369 
	C738.540100,662.305603 739.511047,660.723450 741.513794,659.935303 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M742.782349,686.283447 
	C745.891785,684.585632 746.581299,680.483582 750.640137,679.998657 
	C753.233521,679.388550 755.607666,681.652771 757.905396,679.388794 
	C758.464966,679.114502 758.900330,679.083008 759.663025,679.041260 
	C760.205872,679.143616 760.421326,679.256287 760.808105,679.690918 
	C760.949158,682.393677 760.904602,684.770264 764.194824,685.047424 
	C765.367249,685.146179 765.898987,685.905029 765.955872,687.025452 
	C766.087585,689.615051 764.332886,686.878113 763.578064,687.889771 
	C761.746521,690.344421 757.387573,688.788574 756.067627,692.690613 
	C756.033142,693.027344 756.018250,693.019409 756.022339,693.026123 
	C754.750977,694.527527 752.871277,695.234375 751.225830,696.769897 
	C750.999817,696.998291 751.000000,697.000000 750.999451,696.999512 
	C746.669250,698.855286 745.374390,697.673462 745.062805,691.191650 
	C746.727234,689.358093 744.658142,690.045654 744.103882,689.504028 
	C743.449646,689.124512 743.136536,688.822876 742.705078,688.178589 
	C742.430664,687.430115 742.431396,686.981995 742.782349,686.283447 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M737.636292,623.071411 
	C738.286926,624.836243 738.794983,626.473022 740.880249,627.118835 
	C742.869446,627.734924 743.323547,629.588806 743.960999,631.644775 
	C744.380859,632.396851 744.518799,632.860474 744.187134,633.700439 
	C740.833130,637.128052 740.830017,641.087891 740.981812,645.521729 
	C740.431396,646.140686 739.883728,646.327881 739.163452,646.754150 
	C738.001831,647.617310 737.236206,647.141479 736.255615,646.246948 
	C736.320679,644.066162 736.472168,642.082458 736.988464,640.198669 
	C737.901917,636.866394 739.753723,633.949646 735.012085,631.258728 
	C733.464722,630.380493 735.604614,625.720947 737.636292,623.071411 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M741.730347,659.670593 
	C740.751648,661.225525 739.654724,662.468750 738.267456,663.847107 
	C734.989319,662.706848 731.796570,661.638062 731.772705,657.299500 
	C732.330383,655.901794 732.665710,654.806030 733.005859,653.361206 
	C734.006470,652.299133 735.060608,651.993652 736.609131,652.769836 
	C736.969177,653.469910 737.122986,654.037354 736.921448,654.415405 
	C735.209595,657.626099 734.983765,659.604065 739.684204,658.203796 
	C740.169067,658.059326 740.962097,658.949097 741.730347,659.670593 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M736.884033,652.714233 
	C735.921570,652.995544 734.863159,653.000854 733.407715,653.009155 
	C734.022278,651.554077 733.732910,649.434143 735.698181,648.154175 
	C736.348755,648.067078 736.642517,648.240417 736.947937,648.770874 
	C737.130371,650.175293 736.057129,651.245239 736.884033,652.714233 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M737.011292,649.015259 
	C736.701294,648.903076 736.437927,648.706665 736.112061,648.216553 
	C735.996033,647.554688 735.998291,647.111877 736.002686,646.336914 
	C736.805298,646.250488 737.605774,646.496094 738.698486,646.867432 
	C739.171143,647.729309 740.011169,648.619995 738.268188,648.986816 
	C737.839905,648.875000 737.671753,648.742676 737.256714,648.815552 
	C737.009949,649.020813 737.002075,649.005798 737.011292,649.015259 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M737.259521,649.021240 
	C737.279785,648.909790 737.549622,648.798828 737.913757,648.847595 
	C738.007996,649.007324 737.509094,649.021667 737.259521,649.021240 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M747.544312,664.473633 
	C747.572266,664.522339 747.516418,664.424988 747.544312,664.473633 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M812.829346,645.812561 
	C810.493591,646.175049 808.738770,650.564331 805.752686,646.948425 
	C805.058655,646.107971 804.778564,647.886719 804.611267,648.405640 
	C804.040222,650.177307 803.415222,651.830017 801.375732,652.948914 
	C799.289673,652.440735 798.879883,651.042297 799.519714,649.535034 
	C800.223938,647.875854 799.628296,646.712280 798.481262,645.228394 
	C796.005615,643.633606 793.824158,642.100586 791.035156,641.333496 
	C793.927429,640.048096 798.573608,641.730896 798.411987,635.784973 
	C798.386292,634.840149 801.179138,633.789734 802.700073,632.838135 
	C805.611877,631.016418 808.839722,629.690796 811.702271,627.171997 
	C813.095764,629.197266 814.121948,631.050537 810.171631,631.355469 
	C808.782715,631.462708 806.478271,631.872864 806.052185,634.529297 
	C805.319641,636.612976 803.453735,637.784790 803.546509,640.186218 
	C803.531494,640.454041 803.500916,640.908875 803.648193,641.065430 
	C804.097656,641.265076 804.400146,641.306519 804.946777,641.398560 
	C805.688843,641.596680 806.179749,641.766724 806.952698,641.968140 
	C807.406738,642.089050 807.589722,642.141907 808.048218,642.286011 
	C808.698669,642.509521 809.072327,642.645203 809.738342,642.888306 
	C811.248047,643.502502 812.317993,644.175354 812.829346,645.812561 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M789.313782,661.322266 
	C788.321289,660.203430 787.282898,660.214417 786.156433,660.101807 
	C784.111023,659.897339 783.997742,658.664795 785.620911,656.983704 
	C789.116516,654.815857 792.840515,653.582397 792.706116,648.650635 
	C792.642517,646.319580 794.778809,650.275269 795.709290,648.058044 
	C801.987915,648.417114 797.815552,652.096558 797.844727,654.784058 
	C796.073730,657.619812 793.927429,659.688660 792.130859,662.043579 
	C791.282410,663.155762 790.019653,664.025757 789.313782,661.322266 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M797.002319,655.018433 
	C795.645874,652.729370 800.244812,650.150452 796.132568,648.147949 
	C796.687866,647.115845 797.409485,646.345825 798.210876,645.257202 
	C800.706116,645.807068 804.056335,646.218140 800.347351,649.778748 
	C799.534485,650.559082 800.258667,651.597900 800.916138,652.756836 
	C805.581055,655.341431 804.067566,657.071533 800.341064,658.785217 
	C799.668884,659.194336 799.223022,659.446594 798.402100,659.777283 
	C795.967407,658.699951 796.325928,657.043945 797.002319,655.018433 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M802.306519,613.106689 
	C801.948181,614.143188 801.517456,615.278625 800.444214,614.354370 
	C799.624756,613.648621 800.310547,613.052979 801.502136,612.820801 
	C801.824158,612.811157 802.269836,612.883911 802.306519,613.106689 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M801.952820,612.941772 
	C802.038025,612.629883 802.251953,612.448547 802.465820,612.267212 
	C802.526794,612.515076 802.587769,612.762878 802.495972,613.170166 
	C802.343140,613.329529 802.081482,613.072449 801.952820,612.941772 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M796.652954,654.951721 
	C798.784607,655.877136 796.351257,658.018005 797.993164,659.539185 
	C795.406738,661.796265 793.562012,664.975037 789.385376,665.006714 
	C789.071228,663.776672 787.779297,662.607300 789.067078,661.166321 
	C790.067688,660.699585 789.944336,664.630920 791.370361,661.518372 
	C792.543701,658.957275 794.532410,657.140442 796.652954,654.951721 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M785.916626,666.810181 
	C784.783020,669.289490 782.120056,669.723511 779.709595,671.062988 
	C777.960876,671.784729 776.951843,671.571106 776.789917,669.449524 
	C776.705139,666.856750 778.478638,666.263733 779.664856,666.792236 
	C781.827576,667.755798 783.654663,664.970764 785.916626,666.810181 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M761.283203,680.131348 
	C760.665405,679.907410 760.409485,679.721924 760.101013,679.243713 
	C760.817566,677.335754 762.466980,679.122314 763.746460,678.112244 
	C763.887756,679.503357 764.183960,681.449463 761.283203,680.131348 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M776.663818,669.127075 
	C777.029236,670.116699 777.474548,670.857422 779.009644,671.026428 
	C779.368713,671.074097 779.189148,671.538757 779.087524,671.766113 
	C777.829407,672.324402 776.672913,672.655334 775.244934,673.063904 
	C775.673584,671.550476 776.002502,670.389038 776.663818,669.127075 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M763.456116,676.525818 
	C763.428528,676.477234 763.483704,676.574402 763.456116,676.525818 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M764.473755,672.457642 
	C764.522644,672.430054 764.424866,672.485291 764.473755,672.457642 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M775.642517,514.754883 
	C775.943726,515.805115 775.970154,516.560669 776.066162,517.688538 
	C776.133911,520.375793 776.132202,522.690674 776.127197,525.439453 
	C776.062012,526.074829 776.000122,526.276367 775.893555,526.791321 
	C773.352905,527.859985 773.700806,532.910583 769.086731,530.838867 
	C768.551147,530.203247 768.528992,529.970459 768.555298,529.390930 
	C768.973328,527.416565 769.942261,526.080688 771.055603,524.125488 
	C767.616882,526.067322 766.052612,530.453369 761.427490,529.147095 
	C761.059875,524.486145 761.077209,520.073975 760.185425,515.319946 
	C760.426453,514.440063 760.476685,513.562622 760.679504,513.539734 
	C767.543091,512.764526 768.595032,507.046326 770.784180,501.821899 
	C771.748535,501.235626 772.459534,501.213043 773.473694,501.731140 
	C774.300171,506.127716 774.833923,510.293945 775.642517,514.754883 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M761.140625,529.295532 
	C764.148315,529.293457 765.695190,527.117004 767.323792,525.356567 
	C768.819336,523.740112 770.442566,522.497620 772.569641,521.766418 
	C773.911194,525.325256 769.560608,526.350586 769.395752,529.498047 
	C769.120605,530.063721 768.866028,530.297241 768.211670,530.678040 
	C765.376282,528.856812 763.977051,532.126343 761.581299,532.089783 
	C761.197754,531.353638 761.137451,530.529785 761.140625,529.295532 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M778.327942,537.683350 
	C778.182739,535.380676 778.320984,532.796814 778.856812,530.022339 
	C779.040039,532.355225 778.825684,534.878723 778.327942,537.683350 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M778.014893,543.002686 
	C777.853638,542.417175 777.872375,542.004578 777.928833,541.283936 
	C778.601990,541.651062 779.772583,542.270691 778.014893,543.002686 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M783.251709,527.983398 
	C783.009766,527.835083 783.009766,527.667725 783.009399,527.249390 
	C783.423645,527.163513 783.838257,527.328735 784.252869,527.493958 
	C783.999756,527.650757 783.746704,527.807556 783.251709,527.983398 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M761.161987,550.913574 
	C761.002563,550.993042 760.954529,550.505676 761.027710,550.272095 
	C761.246765,550.211182 761.407043,550.374878 761.525085,550.564880 
	C761.546387,550.599182 761.393799,550.741577 761.161987,550.913574 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M768.459106,538.531799 
	C768.431335,538.482483 768.486938,538.581116 768.459106,538.531799 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M768.998657,530.997925 
	C768.752380,531.412720 768.477966,531.699707 767.921997,531.022705 
	C767.815613,530.829285 767.893311,530.858704 767.904297,530.898804 
	C768.103149,530.842712 768.291016,530.746521 768.741455,530.827332 
	C769.003906,531.004333 769.000000,531.000000 768.998657,530.997925 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M803.768921,513.972229 
	C804.833252,514.797302 804.033752,514.795837 803.229797,514.958862 
	C803.002136,514.828247 803.004211,514.658569 802.994873,514.255310 
	C803.159973,514.028015 803.336487,514.034241 803.768921,513.972229 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M803.012695,513.989563 
	C802.871765,513.822021 802.719727,513.664062 802.567688,513.506104 
	C802.673157,513.562988 802.778687,513.619873 802.933838,513.849243 
	C802.983459,514.021729 803.001587,513.999084 803.012695,513.989563 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M815.438416,599.565796 
	C815.398621,599.503906 815.478210,599.627625 815.438416,599.565796 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M538.248169,804.292847 
	C537.444641,803.832764 536.896484,803.653381 536.176575,803.234558 
	C535.471741,799.308533 532.130676,801.711243 530.238892,800.226257 
	C529.138794,800.165222 528.279968,800.325562 527.208130,800.740540 
	C526.862122,801.131042 526.729065,801.267029 526.321899,801.474121 
	C525.686646,801.511169 525.339966,801.424744 524.801270,801.147766 
	C524.587952,801.034607 524.145447,800.914551 523.919495,800.971924 
	C523.693542,801.029297 523.275391,801.279297 523.051514,801.396851 
	C522.659485,801.554871 522.491272,801.595276 522.059692,801.622559 
	C521.635010,801.536743 521.473633,801.464050 521.156982,801.176025 
	C517.830627,799.508545 514.659607,798.056335 511.291718,796.331116 
	C520.127319,794.782654 528.570007,791.602417 536.914246,788.090942 
	C541.029236,786.359131 544.627380,783.768494 548.626587,781.187805 
	C549.098572,780.864380 549.277100,780.705322 549.725586,780.782410 
	C552.772705,783.461853 555.843323,782.092957 558.208862,780.812317 
	C559.928406,779.881287 561.695557,778.486511 563.647278,777.059082 
	C564.502380,776.589478 565.154175,776.314026 565.174683,775.294678 
	C563.570740,774.849731 562.026428,775.385010 560.583313,774.377380 
	C560.862915,772.422974 562.687195,771.301270 563.945374,771.340820 
	C568.371765,771.480286 571.385376,768.955994 574.639893,766.683716 
	C575.459290,766.111450 576.382324,764.529541 577.536011,765.877502 
	C578.525635,767.033752 577.706848,768.289124 576.998352,769.503845 
	C575.562439,771.965576 572.084045,771.572632 570.605774,774.315002 
	C572.268982,775.789490 574.074219,773.132874 575.787109,774.760742 
	C576.003235,775.016113 576.007019,775.484619 576.010376,775.718872 
	C576.136536,776.136108 576.259460,776.319092 576.202698,776.779785 
	C571.667725,776.841431 568.129456,778.170349 566.039062,782.659424 
	C564.809204,783.840515 563.258118,784.170898 562.226685,785.764404 
	C558.749939,789.988647 553.971985,790.481934 549.202393,791.734009 
	C547.411194,793.582886 545.315063,793.140564 543.397522,793.446228 
	C541.969482,793.673889 540.061768,793.352661 540.349243,795.984619 
	C540.687378,796.398132 540.805908,796.579041 540.964722,797.081848 
	C541.149841,799.420654 540.883057,801.373413 540.052368,803.612671 
	C539.841064,803.983704 539.672363,803.986877 539.250610,803.993835 
	C538.832886,804.189514 538.668213,804.381531 538.248169,804.292847 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M663.947876,725.843018 
	C662.650635,725.530029 661.006165,722.892639 661.069946,726.503235 
	C661.117798,729.204773 660.293518,731.353394 658.696838,733.361816 
	C658.390381,733.747314 657.785095,734.421875 657.854553,734.502991 
	C659.819946,736.796448 662.591492,739.111816 656.839905,740.050598 
	C654.325867,740.460938 652.426086,743.718750 649.242798,741.322388 
	C647.574524,740.066528 646.539307,743.976562 644.215088,742.262024 
	C649.060852,735.086487 654.191406,728.209412 659.115601,721.187561 
	C659.898926,720.070679 662.803894,717.930786 658.318359,716.993591 
	C657.586182,716.238342 657.817322,715.549133 658.362183,714.563599 
	C663.239441,711.039917 666.312500,706.589111 668.485474,701.431885 
	C671.100464,695.225769 674.902283,691.066772 682.793762,693.566406 
	C685.299011,695.186462 684.719604,696.937256 683.544006,698.710022 
	C682.559448,700.194641 681.338074,701.496460 680.009277,703.174072 
	C673.432312,708.474243 670.815430,715.955261 666.883118,722.985046 
	C666.004639,724.208374 665.071594,724.886597 663.947876,725.843018 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M540.530640,796.525635 
	C538.955139,796.899597 537.540405,796.780518 537.436279,795.002991 
	C537.351135,793.549866 538.735962,793.366028 539.797180,792.895203 
	C542.491882,791.699646 545.318848,792.048950 548.557068,791.997375 
	C548.615845,795.603455 545.761047,794.666504 543.184387,794.940918 
	C542.614441,794.861816 542.435486,794.799133 541.982849,794.819092 
	C541.557983,795.020386 541.406738,795.139038 541.105408,795.507263 
	C540.866150,795.930664 540.776978,796.104492 540.530640,796.525635 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M659.253906,714.675232 
	C658.765808,715.493408 658.466858,715.973389 658.068115,716.718201 
	C657.968323,716.983032 657.494995,717.021973 657.257996,717.007202 
	C656.782776,716.713745 656.596985,716.404236 656.220825,715.957397 
	C655.682800,716.026794 655.428528,716.234802 655.081360,716.694336 
	C654.773987,717.177612 654.561279,717.421753 654.127686,717.790161 
	C653.935852,717.939819 653.910950,717.913452 653.895386,717.898499 
	C651.453003,717.435791 651.890076,716.040649 652.960266,714.694458 
	C653.984192,713.406433 655.232788,712.297058 656.690247,710.948120 
	C658.521545,711.482239 659.607971,712.478577 659.253906,714.675232 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M562.330200,785.957275 
	C561.798828,783.614258 563.045288,782.806580 565.610474,783.004028 
	C565.246765,784.391724 564.292053,785.547302 562.330200,785.957275 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M585.693237,779.496582 
	C585.511780,778.080872 586.263672,778.053101 587.105225,778.356567 
	C587.301147,778.427124 587.526855,778.930847 587.455078,779.135803 
	C587.192627,779.885254 586.564819,779.904663 585.693237,779.496582 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M540.552612,796.909424 
	C540.207520,796.354980 540.041504,795.937073 539.935730,795.186157 
	C540.284241,794.704285 540.545349,794.517456 540.984009,794.164429 
	C541.647339,794.190247 542.106079,794.344360 542.679077,794.711426 
	C542.388367,795.649475 541.983337,796.374512 541.291626,797.251587 
	C541.005005,797.403748 540.731689,797.045959 540.552612,796.909424 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M656.081055,716.098938 
	C656.975281,715.068909 656.796692,716.062744 656.996460,716.758667 
	C656.745361,716.775391 656.469666,716.558289 656.081055,716.098938 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M606.326294,756.951416 
	C606.014160,756.944885 605.992371,756.500671 606.041870,756.200439 
	C606.599304,756.090088 607.107178,756.280090 607.749634,756.708557 
	C607.468872,756.950684 607.053589,756.954346 606.326294,756.951416 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M654.170898,717.841553 
	C654.125610,717.670105 654.315369,717.400391 654.731323,717.019348 
	C654.773621,717.186401 654.589722,717.464783 654.170898,717.841553 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M576.185303,776.949036 
	C576.015503,776.873657 576.007935,776.689819 576.007080,776.229492 
	C576.124939,776.248901 576.236206,776.544739 576.185303,776.949036 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M571.549316,779.468872 
	C571.580505,779.521362 571.518127,779.416321 571.549316,779.468872 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M563.377563,818.981812 
	C562.582520,819.020630 562.130859,819.011902 561.340820,819.002808 
	C558.822205,818.242798 556.489868,818.245605 554.914795,815.767822 
	C553.372986,813.342346 553.952209,812.066650 555.959656,810.350891 
	C561.264893,810.691772 566.497925,811.499817 571.870239,810.788269 
	C570.997192,809.106079 569.007629,810.781128 568.037842,809.453491 
	C570.517456,808.059814 575.027893,810.378235 575.758911,805.206421 
	C576.943909,804.846619 577.836182,804.935608 578.847778,805.787048 
	C578.888489,806.701477 578.543884,807.350037 578.582458,807.974976 
	C578.926575,813.554932 578.931335,813.487732 571.910828,815.104065 
	C570.995056,815.314880 567.793701,814.412720 569.844971,817.797607 
	C568.152527,820.135010 565.591675,817.089661 563.377563,818.981812 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M579.368042,805.903320 
	C578.182617,805.888977 577.309875,805.714050 576.219604,805.284912 
	C575.910950,803.922241 575.205688,803.429565 574.300354,802.840942 
	C572.538635,801.695374 572.024231,800.378662 574.725830,799.694275 
	C575.949402,799.384277 577.100281,798.411865 577.076050,797.486450 
	C576.991516,794.258362 579.364380,796.509399 579.363037,796.608765 
	C579.309082,800.443542 583.409424,799.280884 584.924561,801.600708 
	C584.893738,802.147278 584.776855,802.330139 584.865601,802.765381 
	C585.071167,803.017761 585.425598,802.992310 585.600525,802.976196 
	C583.743835,803.887634 581.712280,804.815186 579.368042,805.903320 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M590.838013,797.838501 
	C589.951721,798.775269 588.853577,799.437927 587.439453,800.188416 
	C587.199219,797.962036 587.958740,796.530945 590.838013,797.838501 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M592.952515,789.585083 
	C592.242676,789.197632 592.236816,788.806213 592.740845,788.459961 
	C592.846619,788.387268 593.092468,788.389221 593.195801,788.464233 
	C593.692200,788.824036 593.670105,789.214783 592.952515,789.585083 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M585.059021,802.754272 
	C584.946167,802.740417 584.821167,802.463013 584.853394,802.075012 
	C585.022766,802.139893 585.034851,802.315308 585.059021,802.754272 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M665.895386,723.102844 
	C669.359375,715.796204 671.962280,708.164551 678.718567,702.813354 
	C680.176758,703.632935 680.254700,704.957092 680.037231,706.713989 
	C679.131470,709.465698 675.831482,709.510864 675.082520,712.404175 
	C674.569275,713.486145 674.141907,714.265686 675.358398,715.145935 
	C676.223267,715.709473 676.780945,716.121216 677.548828,716.848511 
	C679.526367,719.937012 679.813110,722.723572 677.336060,725.696289 
	C676.872375,726.064819 676.673401,726.189087 676.146606,726.421753 
	C674.704956,726.662354 673.639160,726.379272 672.249939,726.109253 
	C671.500244,726.057617 671.067261,726.050659 670.310303,726.021362 
	C668.768616,724.984070 667.109802,724.644470 665.895386,723.102844 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M675.724792,726.352722 
	C678.136169,723.586243 678.609375,720.817749 677.041565,717.397339 
	C677.779602,715.913635 679.034790,715.782959 680.591309,715.449097 
	C681.222473,715.427979 681.477600,715.451782 682.398254,715.870667 
	C683.226685,716.473755 683.389648,716.681763 683.730713,717.245972 
	C684.563110,719.674622 684.477966,721.790405 684.208252,724.287231 
	C684.055664,724.893494 683.973877,725.125305 683.708252,725.665283 
	C683.117004,727.410400 679.325928,725.626770 680.735474,729.156982 
	C680.330017,730.717590 678.843567,731.063660 678.385132,732.571167 
	C678.278625,733.063354 678.234558,733.260986 678.112915,733.753662 
	C677.968689,734.241272 677.902039,734.433838 677.702698,734.902222 
	C677.408142,736.047424 675.884705,736.203186 676.434143,737.736694 
	C674.297485,740.826172 671.879456,740.096069 669.184082,738.272949 
	C668.087769,734.177429 671.043213,732.441284 673.511169,730.130188 
	C674.719543,729.024658 674.382690,727.444763 675.724792,726.352722 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M668.750244,738.212097 
	C671.297424,738.644714 673.620789,739.026184 675.915161,737.452698 
	C676.927429,735.834839 678.169739,736.408203 679.720093,736.844727 
	C680.512817,737.247375 680.872559,737.591431 681.275818,738.377808 
	C681.067200,738.944336 680.729980,739.157410 680.190979,739.593445 
	C679.989197,739.816406 680.297852,740.023621 680.363281,740.174255 
	C680.428650,740.324890 680.321472,740.642212 680.503113,740.642151 
	C680.937744,740.498779 681.208984,740.412415 681.691467,740.226868 
	C683.998047,741.022705 687.095215,740.162537 687.994995,743.692871 
	C688.010071,744.006897 688.022705,744.036133 688.031982,744.049255 
	C686.263428,746.009094 684.598511,748.170044 681.214478,747.748901 
	C680.970581,747.965698 680.978149,747.981934 680.973022,747.970947 
	C675.842651,746.054932 671.493591,743.139404 668.750244,738.212097 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M665.407837,722.868042 
	C667.565247,722.885498 669.041321,723.491211 669.847290,725.672852 
	C668.187866,726.018860 666.389526,726.038757 664.124939,726.039673 
	C663.837524,724.865234 663.576660,723.509888 665.407837,722.868042 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M675.726929,726.148315 
	C676.098083,727.587463 675.808472,728.878479 674.146301,729.868408 
	C673.212830,729.205017 672.609131,728.420410 671.998169,727.324768 
	C672.630554,726.266846 673.452698,726.018616 674.738281,726.185974 
	C675.070312,726.242615 675.507996,726.179016 675.726929,726.148315 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M674.897400,725.955566 
	C674.264954,726.489563 673.459656,726.736511 672.322632,726.998535 
	C671.990906,727.013672 671.945557,726.539612 671.939331,726.302124 
	C672.731689,725.002686 673.644226,724.744446 674.897400,725.955566 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M677.049316,751.672241 
	C676.515381,751.700806 676.137390,751.576721 675.759399,751.452637 
	C675.863831,751.350464 675.991272,751.146118 676.068909,751.163574 
	C676.454895,751.250122 676.827698,751.395203 677.049316,751.672241 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M666.428223,736.741577 
	C666.575012,736.860657 666.593872,737.066345 666.612793,737.271973 
	C666.508667,737.124023 666.404541,736.976135 666.428223,736.741577 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M672.546143,750.474731 
	C672.574890,750.525269 672.517395,750.424194 672.546143,750.474731 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M668.968323,814.586182 
	C662.287354,814.688660 655.484253,815.771851 648.372559,814.157593 
	C645.307129,812.184326 642.107849,812.248901 638.570679,811.647095 
	C647.187805,808.761841 655.348206,811.626099 663.463440,811.204529 
	C665.449951,811.101379 667.639343,812.216919 668.968323,814.586182 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M670.603394,809.717041 
	C670.553467,810.033508 670.526733,810.023254 670.511658,810.016296 
	C670.095093,810.115112 669.734375,810.300415 669.210205,810.795654 
	C669.005859,811.026184 669.004700,811.008240 669.014404,811.021118 
	C666.708923,810.920044 665.429260,810.131104 666.950500,807.315796 
	C668.573669,807.384155 670.081482,807.696167 670.603394,809.717041 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M678.062012,814.038696 
	C680.401855,814.429443 683.056641,813.188782 685.360229,815.295898 
	C683.216187,816.069641 680.905151,816.314209 678.301453,815.327820 
	C678.011963,814.829773 678.023132,814.603577 678.062012,814.038696 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M655.342773,807.402832 
	C656.404297,807.505005 657.476746,807.552979 657.615479,808.647217 
	C657.634888,808.800415 656.531616,809.261353 655.932373,809.294189 
	C654.573059,809.368591 655.281250,808.239502 655.342773,807.402832 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M632.877075,804.802368 
	C633.297058,804.400452 633.728271,804.289551 634.165710,804.252258 
	C634.209106,804.248535 634.422485,804.793518 634.331848,804.912964 
	C633.948242,805.418457 633.482727,805.884949 632.877075,804.802368 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M638.749146,798.591919 
	C638.866211,798.446411 639.065979,798.426941 639.265808,798.407471 
	C639.121216,798.510925 638.976562,798.614441 638.749146,798.591919 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M637.983704,793.839600 
	C638.288757,793.786499 638.596619,794.082275 638.964233,794.694092 
	C638.678223,794.736206 638.332397,794.462280 637.983704,793.839600 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M635.456970,806.454590 
	C635.511780,806.422302 635.402161,806.486877 635.456970,806.454590 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M630.903931,805.031860 
	C630.782166,804.695923 631.104553,804.355042 631.734924,803.834534 
	C632.229980,804.324341 631.998047,804.781433 630.903931,805.031860 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M681.342651,748.022827 
	C683.093750,746.659485 685.216858,745.353271 687.690613,744.054688 
	C688.133301,748.867493 685.636475,749.595703 681.342651,748.022827 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M696.985901,753.056580 
	C691.731812,753.076660 686.483765,753.076660 681.235657,753.076660 
	C681.230713,752.716309 681.225769,752.355896 681.220764,751.995544 
	C686.169067,751.995544 691.117310,751.995544 696.533203,751.996582 
	C697.172607,752.163513 697.344360,752.329529 697.260498,752.746521 
	C697.004883,752.997559 696.991882,753.036499 696.985901,753.056580 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M697.718018,758.075684 
	C699.249878,758.668091 700.795044,759.081970 701.086304,761.361267 
	C699.857361,758.741455 696.893555,763.678833 696.005859,759.273010 
	C696.495544,758.704712 696.982910,758.450928 697.718018,758.075684 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M701.625122,751.837341 
	C702.307556,751.989441 703.020508,752.368530 702.992981,752.433289 
	C702.501953,753.589539 701.417725,752.772217 700.314026,753.127808 
	C700.426880,752.756531 700.868958,752.337280 701.625122,751.837341 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M697.342041,753.006592 
	C697.002808,752.830872 697.000793,752.664246 696.999878,752.247559 
	C697.579285,752.189575 698.157654,752.381653 698.879395,752.856323 
	C698.574890,753.097839 698.127014,753.056763 697.342041,753.006592 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M699.732178,753.776367 
	C699.828552,754.147949 699.850098,754.817993 699.085449,754.001709 
	C699.025208,753.773376 699.496948,753.796875 699.732178,753.776367 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M629.981262,788.317627 
	C632.893799,788.052673 631.238281,790.465637 631.961365,791.937378 
	C631.234314,792.133484 630.461121,792.041077 629.310791,791.841736 
	C627.616577,790.151062 630.041565,789.785339 629.981262,788.317627 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M712.765503,771.161255 
	C712.941772,771.936462 712.666931,772.715637 711.293579,772.968872 
	C710.966003,772.987854 710.984863,772.505737 710.996704,772.264893 
	C711.516846,771.789429 712.025146,771.554749 712.765503,771.161255 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M698.451782,772.533813 
	C698.420471,772.481445 698.483032,772.586182 698.451782,772.533813 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M652.457886,784.528809 
	C652.427795,784.477295 652.487976,784.580261 652.457886,784.528809 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M685.210449,786.600037 
	C683.634705,787.164368 683.066467,786.213257 682.208374,785.107300 
	C681.938416,784.922607 681.952759,784.447876 682.026001,784.221558 
	C682.476196,783.729614 682.853088,783.464050 683.423218,783.066528 
	C685.203003,783.501953 685.653809,784.695068 685.210449,786.600037 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M684.999878,786.972046 
	C685.274353,785.614197 684.275208,784.712891 683.740845,783.274841 
	C684.509705,781.251404 686.534790,781.316162 687.951538,779.886597 
	C688.319824,779.612244 688.544922,779.671692 689.093018,779.864685 
	C689.415955,779.998291 689.193665,780.353821 689.215820,780.562256 
	C689.680115,781.891785 691.085022,782.195251 691.562500,783.709961 
	C691.511230,784.468811 691.350708,784.896851 691.018433,785.622070 
	C689.303894,786.945312 687.876160,788.352783 685.404419,787.254639 
	C685.055542,787.054382 685.022949,786.996155 684.999878,786.972046 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M825.527832,451.541260 
	C825.479248,451.568665 825.576477,451.513824 825.527832,451.541260 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M829.725098,496.352753 
	C830.087769,496.321564 830.306885,496.434296 830.526001,496.547058 
	C830.428406,496.641418 830.302795,496.831787 830.238098,496.812927 
	C830.008057,496.746002 829.798767,496.607819 829.725098,496.352753 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M826.307617,500.993286 
	C825.988770,500.788422 825.980713,500.573700 825.920898,500.040039 
	C827.048523,498.379669 826.805542,499.899261 827.169678,500.628906 
	C827.031128,500.943604 826.824829,500.963501 826.307617,500.993286 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M833.000000,474.000549 
	C833.155701,472.834106 832.717957,471.265106 834.679993,471.276672 
	C835.022644,471.278687 835.584106,471.736084 835.665588,472.074432 
	C836.052673,473.681030 834.895569,473.863617 833.365723,473.989288 
	C833.000000,473.999146 833.000000,474.000000 833.000000,474.000549 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M834.248718,481.001801 
	C834.273682,480.892303 834.547424,480.784607 834.908020,480.842133 
	C834.994873,481.007324 834.497437,481.003632 834.248718,481.001801 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M835.003662,480.997284 
	C835.120605,481.131805 835.233887,481.269043 835.347168,481.406281 
	C835.265808,481.359894 835.184509,481.313538 835.049011,481.137268 
	C834.994873,481.007324 835.000000,481.000000 835.003662,480.997284 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M481.964722,257.923157 
	C482.519501,255.040497 480.320953,252.735962 481.538239,249.938202 
	C482.235840,248.334839 483.057831,248.145248 484.701447,248.083252 
	C486.266663,249.132416 486.143250,251.082565 487.901489,252.122620 
	C488.909485,253.012436 489.364746,253.975601 490.474182,254.733810 
	C490.912994,254.991013 491.091949,255.092056 491.550354,255.333252 
	C492.222443,255.655090 492.612793,255.841522 493.305450,256.192261 
	C495.858398,258.084381 496.295624,260.185303 494.682343,262.937347 
	C492.461029,264.575165 490.395752,265.894745 490.355469,269.072296 
	C490.257599,270.103241 490.085144,270.769806 489.639404,271.715637 
	C487.351318,273.220001 484.740662,273.514984 482.743286,275.553925 
	C480.852264,275.215210 479.856598,273.672272 477.844543,273.534241 
	C475.828094,272.752472 474.098816,272.378967 472.269684,272.743500 
	C471.470398,272.902802 470.179230,273.195770 470.517365,273.994415 
	C472.188293,277.941162 468.442810,280.368530 467.916687,284.030762 
	C462.010590,289.455444 455.093231,292.345123 447.520905,294.609985 
	C446.429779,294.178497 445.962830,293.605835 445.610199,292.517578 
	C445.835785,290.247772 447.115234,289.281067 448.841827,288.876648 
	C452.433777,288.035400 453.972015,285.686554 453.757629,282.232880 
	C452.971680,282.830719 454.130768,285.196259 452.402069,284.897583 
	C450.526825,284.573517 450.340820,282.588654 450.399597,280.544861 
	C453.667267,278.737488 455.092438,276.188477 455.349060,272.556335 
	C456.914612,271.786285 458.257294,272.130341 459.902222,272.110229 
	C462.741302,271.944916 461.984680,269.580505 462.930664,267.973450 
	C463.812866,266.374420 464.228455,264.962067 464.686096,263.547455 
	C465.027283,262.492859 465.611023,261.247345 467.063477,262.682129 
	C467.432648,268.522614 468.211761,268.921265 473.155029,265.790802 
	C475.900330,264.052246 478.801514,262.446259 480.841675,259.337830 
	C481.410858,258.883820 481.738770,258.610352 481.964722,257.923157 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M460.545959,272.496216 
	C459.234406,274.160950 457.900574,272.784241 456.360413,273.032227 
	C453.069366,271.397186 455.080078,269.360687 455.580383,267.471039 
	C455.878784,266.343994 456.571228,265.412537 457.227478,264.458832 
	C457.806000,263.618011 458.214264,262.495056 457.251862,261.812042 
	C456.064545,260.969391 455.647858,262.324463 455.004944,262.984680 
	C451.165741,266.927185 449.012207,266.898010 445.214264,262.546692 
	C444.883636,261.471313 444.854736,260.726349 445.050964,259.601013 
	C445.279541,259.004120 445.408386,258.780426 445.853729,258.333496 
	C451.331665,254.793335 456.462311,251.686691 458.015472,244.853378 
	C458.692017,241.876770 460.832642,238.948532 463.028137,236.701752 
	C464.487946,235.207855 462.410065,231.281601 466.741211,232.068344 
	C467.027740,232.223648 467.001282,232.560181 466.991272,232.728668 
	C467.356049,233.473495 467.873016,233.647827 468.739807,233.230988 
	C469.949493,233.210541 471.742035,229.448349 471.545959,232.729355 
	C471.326538,236.401764 473.446106,236.069229 475.598785,236.954651 
	C476.711365,237.635651 476.845428,238.589920 476.302856,239.209030 
	C473.342438,242.587128 473.485321,247.183655 471.254822,250.998245 
	C472.762634,248.993408 473.813538,250.145340 475.033569,251.436798 
	C475.259949,251.980835 475.326355,252.208344 475.190308,252.728073 
	C474.987854,253.020309 474.998322,252.999664 475.007751,252.992920 
	C474.473236,253.526123 473.928070,254.064835 473.189270,254.816437 
	C472.987701,255.021301 472.999268,254.996887 473.010803,254.989899 
	C472.778168,255.201248 472.549561,255.434799 472.164856,255.858765 
	C470.619598,258.366180 470.732697,261.628876 467.332031,262.957764 
	C465.324371,262.100372 465.374207,263.407227 465.700012,264.207336 
	C466.426941,265.992157 465.263336,266.808136 464.081909,268.066467 
	C462.106567,269.035706 461.444397,270.651917 460.545959,272.496216 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M507.022522,241.695969 
	C506.572968,242.463303 506.125885,242.850403 505.423798,243.391693 
	C504.249084,241.183533 502.685852,242.530090 501.303619,243.028397 
	C501.242737,242.567749 501.073120,242.202911 501.180206,241.960052 
	C503.755890,236.118332 503.759674,236.155487 496.707336,235.848953 
	C494.722229,235.762650 493.923035,234.942383 495.424988,233.054504 
	C497.159363,230.874512 498.788239,228.610626 500.730164,226.192535 
	C500.899658,225.225250 498.886261,224.812408 500.453369,223.762222 
	C502.370239,222.477661 501.896942,220.577469 502.005859,218.410736 
	C501.890625,217.854065 501.770111,217.685577 501.833801,217.266830 
	C502.017944,217.016556 502.001221,217.002090 502.008606,217.006577 
	C502.231201,216.766495 502.463593,216.539505 502.890625,216.162933 
	C505.685120,214.899338 508.405243,214.009735 511.233429,214.076767 
	C512.812256,214.114197 514.692505,212.795609 515.950012,214.272110 
	C518.405640,217.155640 521.610046,219.978470 522.597046,223.379898 
	C523.667053,227.067245 518.774963,226.349350 516.381836,227.949829 
	C514.487000,229.094452 514.939026,230.922440 514.215576,232.683411 
	C509.684967,234.069473 509.133240,238.346527 507.022522,241.695969 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M494.162170,263.291901 
	C494.476196,260.988800 495.462616,258.876404 492.859833,257.098511 
	C492.142456,256.902924 491.767700,256.704834 491.220093,256.188507 
	C491.005646,255.948959 491.005676,256.004913 491.029877,255.990875 
	C490.341064,255.327576 489.675537,254.647781 489.568909,253.270020 
	C491.868256,249.736710 493.356293,245.903992 497.431702,243.959808 
	C499.350403,246.180496 501.173798,247.133789 503.829590,245.126068 
	C510.854462,243.345734 517.642761,241.607239 524.761230,239.714813 
	C525.038818,238.785782 525.396729,238.449799 526.578979,238.771118 
	C528.425659,238.665466 529.860535,238.343948 531.661621,237.960526 
	C534.108032,241.229218 532.226318,242.593872 528.970947,243.547440 
	C528.475464,243.780533 528.291382,243.897629 527.832520,244.181061 
	C527.364197,244.428558 527.170715,244.509766 526.675415,244.656387 
	C526.172119,244.729492 525.970581,244.737183 525.475342,244.723923 
	C524.587524,244.654922 524.025940,244.768158 523.218018,245.189148 
	C517.198425,246.370621 512.038086,248.208893 509.982483,254.883026 
	C508.885193,256.559204 507.956238,257.929565 506.581970,259.409851 
	C502.788666,262.270691 499.040863,264.228363 494.162170,263.291901 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M475.305298,252.205872 
	C473.940155,251.951218 472.382050,248.774979 471.835938,252.597977 
	C471.625702,254.069626 470.482422,253.774872 469.655243,253.026382 
	C468.969971,252.406281 467.979431,251.553619 469.681946,250.924072 
	C471.234406,250.350006 471.527893,248.759781 471.501862,247.688187 
	C471.402283,243.591293 473.758148,240.729294 475.928253,237.353333 
	C478.457977,236.244965 480.970734,235.519836 483.464569,234.734573 
	C485.271210,234.165710 486.268005,235.485916 487.227997,236.482132 
	C488.046906,237.331924 487.027466,238.100647 486.331299,238.295181 
	C480.910370,239.810089 478.339661,243.449493 477.880859,248.934097 
	C477.813141,249.743790 476.739532,250.469330 476.069946,251.586792 
	C475.861450,252.081741 475.714294,252.223740 475.305298,252.205872 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M478.210632,206.261932 
	C478.130920,205.573624 478.269836,205.155685 478.571472,204.579010 
	C478.641449,203.940216 479.352539,203.540649 479.266815,203.500504 
	C476.128784,202.031052 476.718628,200.593063 479.597229,199.086823 
	C480.398438,199.018982 480.857178,199.004730 481.658875,198.991058 
	C485.101410,198.146469 488.319183,197.061783 488.592194,202.135742 
	C489.035248,201.984573 489.478271,201.833389 490.234406,201.728302 
	C490.777588,201.796661 491.007690,201.818924 491.412720,202.141571 
	C491.975494,204.013718 491.224030,205.662613 491.978516,207.569504 
	C492.214081,210.222809 491.823669,212.399017 490.415863,214.707901 
	C489.933563,215.316147 489.668518,215.617096 489.186188,216.126709 
	C487.520355,215.238754 487.946411,213.332581 486.831879,211.586960 
	C484.704529,213.811478 482.841248,213.345230 481.682526,210.607269 
	C481.173676,209.404846 480.004974,209.173935 478.978729,208.163879 
	C478.606903,207.443970 478.489014,206.995590 478.210632,206.261932 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M497.953796,243.674271 
	C495.345581,246.919403 492.910004,249.857071 490.145721,252.917801 
	C489.383148,253.414093 488.883972,253.553497 487.997437,253.213104 
	C485.105164,252.632629 484.635498,250.975189 485.038605,248.436325 
	C486.600067,246.759140 484.520111,243.428543 486.218628,243.447556 
	C490.625092,243.496933 493.828644,239.685562 497.974304,240.155640 
	C501.424316,240.546844 498.626373,242.234100 497.953796,243.674271 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M491.670868,223.480057 
	C493.337799,219.282822 496.026123,222.078629 498.403625,221.982483 
	C496.159760,224.013168 493.945770,225.652527 493.794769,229.089462 
	C493.704773,231.136948 486.825806,233.607605 484.973450,232.723633 
	C482.733582,231.654678 482.964172,228.939438 485.221100,227.084641 
	C487.119171,225.524765 490.171051,226.297958 491.670868,223.480057 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M478.751099,206.793304 
	C481.310852,208.035919 483.552765,209.218903 483.623901,212.549515 
	C485.332306,211.586334 483.404266,208.721848 486.167175,209.059235 
	C488.245789,209.313034 489.012085,210.486084 488.979675,212.426025 
	C488.963043,213.421417 488.981323,214.417404 488.976379,215.874237 
	C487.876038,217.601746 487.095612,219.242767 484.944336,219.324631 
	C484.649078,218.967758 484.576080,218.792923 484.330688,218.844055 
	C483.974121,219.104965 483.789917,219.139908 483.330017,219.232056 
	C482.779968,214.807693 479.801941,211.498154 478.031952,207.298187 
	C478.203217,206.834610 478.360443,206.743912 478.751099,206.793304 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M490.721893,201.870483 
	C490.984070,201.354553 491.420654,200.934677 491.988464,200.240479 
	C498.908264,201.190720 501.905243,205.184097 501.980774,213.546402 
	C497.676544,216.059021 497.244781,215.870682 496.836182,211.369766 
	C497.010468,210.854279 497.098602,210.657928 497.374084,210.209778 
	C497.787231,209.673782 498.212494,209.777206 498.443726,209.503754 
	C498.151001,209.381729 497.890900,209.207001 497.525665,208.703156 
	C497.168549,206.814560 495.646271,206.899796 494.370483,206.137634 
	C493.470154,205.431839 492.973206,204.776260 492.227112,203.955322 
	C491.815155,203.422379 491.629456,203.088242 491.308777,202.508881 
	C491.136749,202.283905 490.896271,201.966553 490.721893,201.870483 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M493.899414,204.817841 
	C495.041931,206.672150 498.988312,204.502686 498.113892,208.616211 
	C498.247375,209.117767 498.399536,209.311829 498.275879,209.752335 
	C498.000000,209.998779 498.000000,210.000000 497.999390,210.000305 
	C497.232056,211.057846 496.684052,212.236771 496.041077,213.725067 
	C495.879913,214.252335 495.785095,214.454330 495.465454,214.906616 
	C495.029297,215.233154 494.817932,215.309387 494.324615,215.200851 
	C493.016327,215.012482 491.989960,215.008865 490.581085,215.010223 
	C489.630157,212.558319 490.798492,210.334061 491.164795,207.605988 
	C491.123444,206.196442 491.632782,205.473145 492.689575,204.719833 
	C493.225677,204.563385 493.453705,204.558655 493.899414,204.817841 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M495.195007,214.234741 
	C494.999725,212.216675 496.172272,211.231003 497.704102,210.144043 
	C497.917267,212.308502 496.571472,215.660431 501.630737,213.979874 
	C500.403076,217.664703 496.065857,216.978989 493.768585,219.477020 
	C492.391571,217.936035 493.914978,216.884995 493.997711,215.352295 
	C494.042694,215.016083 494.516907,215.014938 494.754028,215.012543 
	C495.121307,214.839233 495.251465,214.668335 495.195007,214.234741 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M528.626465,220.027405 
	C529.711487,222.580154 531.520508,225.242706 528.244995,227.823029 
	C527.982300,228.004517 528.005432,228.001190 528.006226,228.011627 
	C527.564758,227.593002 527.193726,227.118301 527.002136,226.203690 
	C527.565674,223.994720 526.483826,221.696777 528.626465,220.027405 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M514.300537,232.768585 
	C514.484253,231.172333 512.558289,228.707947 515.765503,227.792374 
	C516.186096,229.720566 515.516907,231.182846 514.300537,232.768585 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M473.310791,254.985413 
	C473.081268,254.112839 473.314087,253.303665 474.693939,253.034042 
	C474.895660,253.879898 474.653259,254.688461 473.310791,254.985413 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M508.000427,187.000305 
	C507.921600,187.850464 507.780365,188.655151 506.609192,188.191452 
	C506.346375,188.087387 505.937683,187.768204 505.964783,187.612015 
	C506.120117,186.717148 506.906464,187.140076 507.716370,187.001373 
	C507.999084,186.999390 508.000000,187.000000 508.000427,187.000305 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M481.637390,257.724670 
	C482.343262,258.286163 483.628479,258.508636 483.074768,259.641449 
	C483.020355,259.752747 482.048767,259.415802 481.246033,259.162354 
	C481.106659,258.641937 481.224152,258.246063 481.637390,257.724670 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M526.741211,226.099854 
	C527.297485,226.399597 527.627075,226.898514 527.981873,227.709747 
	C527.063110,227.834427 525.756226,227.961182 526.741211,226.099854 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M468.626923,232.996140 
	C468.696167,233.543930 468.563385,234.340179 468.149506,234.532959 
	C467.437408,234.864624 467.151062,234.171463 467.007263,233.215332 
	C467.420288,232.922455 467.859344,232.947754 468.626923,232.996140 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M500.390442,237.755341 
	C500.548553,237.888580 500.568817,238.126602 500.589111,238.364639 
	C500.476929,238.196472 500.364746,238.028320 500.390442,237.755341 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M509.005066,193.005066 
	C508.881592,193.431076 508.753418,193.852386 508.625244,194.273682 
	C507.942291,193.953796 508.303436,193.593613 508.787720,193.115005 
	C508.990234,192.993515 509.000366,193.000351 509.005066,193.005066 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M478.475647,253.457947 
	C478.515015,253.437485 478.436249,253.478409 478.475647,253.457947 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M475.025757,252.289368 
	C475.043518,252.046021 475.527008,252.003098 475.767822,251.971420 
	C475.817383,252.217087 475.626160,252.494431 475.211395,252.896042 
	C474.987854,253.020309 475.007965,252.532730 475.025757,252.289368 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M472.217194,255.914673 
	C472.188171,255.764252 472.383118,255.494522 472.800201,255.103851 
	C472.828613,255.253738 472.634888,255.524567 472.217194,255.914673 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M513.471558,190.454224 
	C513.524963,190.423035 513.418213,190.485397 513.471558,190.454224 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M501.849670,217.102173 
	C502.024261,217.184189 502.030548,217.351822 502.024017,217.770996 
	C501.901245,217.744293 501.791321,217.466034 501.849670,217.102173 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M484.300354,219.079559 
	C484.158295,219.070007 484.375732,218.656876 484.375732,218.656876 
	C484.375732,218.656876 484.733398,218.805298 484.727753,218.973953 
	C484.722107,219.142609 484.442413,219.089096 484.300354,219.079559 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M467.328430,216.842194 
	C467.208038,216.952148 467.066071,216.482697 467.052155,216.237885 
	C467.420380,216.131332 467.557251,216.377716 467.328430,216.842194 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M502.832001,216.094604 
	C502.876373,216.272812 502.684662,216.549866 502.254456,216.918991 
	C502.209290,216.738525 502.402588,216.465973 502.832001,216.094604 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M507.473206,212.460266 
	C507.522430,212.432327 507.423981,212.488190 507.473206,212.460266 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M446.152405,257.751801 
	C446.170197,258.110321 446.013824,258.523804 445.953369,258.736389 
	C443.617432,260.272003 442.148346,265.038971 438.371399,260.314850 
	C437.378174,256.394531 438.878418,253.838577 442.081665,251.754791 
	C443.978485,251.798615 445.228912,252.553131 446.671875,253.719879 
	C446.675446,255.102356 446.405029,256.247833 446.152405,257.751801 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M450.850800,233.237701 
	C452.248383,233.475418 453.378021,231.275238 454.476166,232.972382 
	C455.349976,234.322845 455.956970,235.499756 454.629761,237.199554 
	C453.502014,238.643875 453.928528,241.018311 452.128540,242.708893 
	C451.227295,243.303757 450.503998,243.238129 449.484192,242.700623 
	C447.522095,239.741928 449.239288,237.314835 450.042969,234.445694 
	C450.311951,233.896851 450.444336,233.690170 450.850800,233.237701 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M449.759705,242.202209 
	C450.497406,242.269669 450.983276,242.530518 451.718994,242.909775 
	C450.827057,245.664398 449.685272,248.300613 448.228546,250.969543 
	C446.632141,251.508987 445.424561,251.311188 443.995056,250.354446 
	C443.511444,249.606720 443.480835,249.113525 443.930145,248.377380 
	C444.788422,247.306137 445.347107,246.466766 446.042236,245.354248 
	C446.727966,243.491196 448.066925,242.880203 449.759705,242.202209 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M458.369751,233.977081 
	C458.131958,231.041794 460.129303,229.484131 462.610565,227.720306 
	C463.102325,231.311996 462.570557,233.694321 458.369751,233.977081 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M443.548523,249.930786 
	C445.059662,250.072800 446.295044,249.869827 447.586578,250.876877 
	C447.631073,251.780746 447.348572,252.559265 447.005981,253.647339 
	C445.499481,253.914856 443.814514,254.555389 442.913330,252.236298 
	C442.412964,251.132202 442.545624,250.487183 443.548523,249.930786 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M450.781799,232.986908 
	C451.105682,233.174179 451.086456,233.356430 451.050537,233.813095 
	C450.623627,234.385025 450.167969,234.524948 449.351654,234.278748 
	C449.040283,233.876999 449.037292,233.679855 449.054993,233.186432 
	C449.529999,232.920746 449.984314,232.951324 450.781799,232.986908 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M457.991394,233.985275 
	C457.901611,234.014664 457.822296,234.058105 457.886169,234.061371 
	C458.029358,234.021210 458.001923,233.999329 457.991394,233.985275 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M443.950623,249.618195 
	C443.602539,250.386566 443.291016,250.856995 442.883179,251.604156 
	C440.649750,253.880478 439.141602,256.214508 438.850342,259.590851 
	C438.846863,261.682129 438.170837,263.118866 436.829041,264.633759 
	C435.040619,265.881866 434.542694,267.721436 433.311462,268.974609 
	C432.129181,270.177917 430.986053,270.888428 430.087372,268.341980 
	C430.907562,262.045563 434.224976,257.021973 436.266235,251.518097 
	C436.829895,249.998230 438.051697,248.718796 437.914551,246.584747 
	C438.226624,245.757507 438.678284,245.543564 439.590027,245.775543 
	C441.047180,246.790115 441.768707,248.278137 443.860443,248.098572 
	C444.148895,248.537079 444.068054,248.928665 443.950623,249.618195 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M448.896759,234.415558 
	C449.485504,234.060471 449.927765,234.046783 450.701904,234.060303 
	C450.531128,236.576553 450.028442,239.065628 449.768646,241.781754 
	C449.984558,244.170227 447.900146,243.916763 446.471130,244.928497 
	C447.035828,241.639725 447.893036,238.198349 448.896759,234.415558 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M438.460541,242.609711 
	C437.847961,242.890411 437.581543,242.799438 436.894653,242.670029 
	C435.846405,242.709122 435.456573,242.259583 434.793335,241.765442 
	C434.357605,241.260071 434.177246,240.925629 434.019592,240.281067 
	C434.074646,239.625366 434.150726,239.255707 434.243896,238.602966 
	C439.373138,235.452957 438.648529,239.357605 438.460541,242.609711 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M437.900513,243.287018 
	C438.114349,242.981384 438.570618,243.035339 438.686340,243.031860 
	C438.972534,243.971130 441.262024,243.780090 440.012695,245.707214 
	C439.479279,246.024918 439.025452,246.075348 438.231812,246.172150 
	C437.135651,245.397018 436.895752,244.535156 437.900513,243.287018 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M441.523865,238.996872 
	C441.917542,239.760361 442.500946,240.686157 441.513000,241.148666 
	C441.302429,241.247253 440.837341,241.108749 440.660767,240.919601 
	C439.948547,240.156448 440.199982,239.493103 441.523865,238.996872 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M441.761230,238.815765 
	C441.900665,238.041595 442.137146,237.338318 442.403320,236.546799 
	C442.986725,237.356476 443.000549,238.073837 442.331238,238.926788 
	C442.045990,239.036713 441.858276,238.886642 441.761230,238.815765 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M480.734985,173.935730 
	C481.197998,174.954269 481.394714,175.945587 481.878113,177.207733 
	C481.927399,178.219666 481.689972,178.960770 481.198547,179.866135 
	C479.273010,178.548950 480.135529,176.574417 479.934784,174.404129 
	C480.116974,173.969666 480.292816,173.939087 480.734985,173.935730 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M481.688354,182.446167 
	C482.699310,182.179688 484.051361,181.905121 482.987488,183.932785 
	C482.384766,183.638153 482.059418,183.186218 481.688354,182.446167 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M481.945251,198.741333 
	C481.760803,199.261475 481.519989,199.538528 481.012573,199.896866 
	C480.509552,199.525925 480.438538,199.052109 480.793304,198.263153 
	C481.026642,197.973114 481.068085,197.937439 481.088409,197.919128 
	C481.440460,198.006409 481.700531,198.205536 481.945251,198.741333 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M480.656525,198.074127 
	C480.956970,198.417007 480.887329,198.860886 480.781799,199.641479 
	C479.653473,200.077759 478.088470,199.840088 478.270813,201.502243 
	C478.555573,204.097488 481.144775,201.777786 482.217926,203.061813 
	C481.726471,204.657166 480.281311,203.675400 479.072235,204.284424 
	C475.926117,204.701630 476.273346,201.898346 474.813507,200.342194 
	C476.290833,198.821564 478.276794,198.462341 480.656525,198.074127 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M476.544434,215.470795 
	C476.573517,215.521484 476.515350,215.420105 476.544434,215.470795 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M478.859924,206.737122 
	C478.984558,206.933380 478.514771,206.956955 478.280396,206.941132 
	C478.045990,206.925323 478.020447,206.458481 478.006226,206.225021 
	C478.286652,206.111221 478.534424,206.294312 478.859924,206.737122 
z"
    />
    <path
      fill="#F5F7F8"
      opacity="1.000000"
      stroke="none"
      d="
M446.772430,294.367554 
	C453.606445,291.243286 460.101166,288.072418 466.849731,284.677521 
	C470.106354,280.600769 474.694702,279.855072 478.948364,279.103241 
	C486.417908,277.783081 493.715485,275.399109 501.400879,275.295929 
	C504.776031,275.250610 507.604431,273.066437 511.272034,272.602966 
	C514.494751,278.426361 514.494873,278.427124 521.546204,277.079315 
	C522.032837,276.986328 522.515442,276.871979 523.385254,276.707153 
	C527.115601,276.294830 530.466187,276.348938 534.244934,276.530212 
	C536.102905,276.426910 537.532776,276.544464 539.394470,276.555237 
	C540.401306,276.532990 540.977051,276.529633 541.985352,276.537048 
	C548.218384,276.108307 553.949402,276.893585 560.119202,277.541626 
	C561.404358,277.589355 562.262451,277.573578 563.525879,277.790497 
	C570.363831,279.285095 576.881653,280.180725 583.749390,281.458557 
	C587.564758,282.529327 591.282104,282.469513 594.681030,284.811218 
	C597.411316,287.205048 601.051758,285.534637 603.738892,287.789886 
	C607.248596,286.638489 609.836365,288.992920 613.138550,289.435852 
	C623.469849,292.735809 633.025269,296.919281 642.694824,301.774231 
	C649.551147,305.728058 655.946167,309.740906 662.663086,313.894958 
	C663.933105,314.673553 664.829468,315.384277 666.083008,316.171021 
	C668.437500,318.044128 670.535645,319.694427 673.195190,321.140625 
	C682.459595,327.962769 690.726318,335.452820 699.394226,343.009552 
	C701.913208,346.180847 705.530823,347.990601 706.790039,352.147766 
	C708.022461,353.957672 709.195679,355.421661 710.660400,357.019531 
	C718.000305,366.180634 725.121765,375.153961 731.648865,384.948853 
	C736.342041,393.461639 742.405640,400.947235 745.010010,410.615265 
	C744.669312,411.676666 744.073914,412.078735 743.002502,412.384888 
	C740.369751,411.976746 739.740784,409.971100 738.709473,408.317596 
	C734.279358,401.214386 730.537842,393.660919 724.704102,387.074524 
	C723.864868,386.389496 723.443909,385.816345 722.989502,384.838379 
	C720.611572,378.539337 716.117310,374.198090 711.741577,369.366302 
	C711.273743,369.022278 711.094421,368.874084 710.674561,368.473358 
	C708.786011,365.482452 707.017456,362.846222 704.060181,360.785400 
	C702.646606,359.297852 701.675659,357.848480 700.004883,356.617004 
	C699.113281,355.818329 698.522766,355.176483 697.665039,354.333923 
	C695.796936,351.935455 693.565979,350.496185 691.049561,348.878387 
	C689.236328,347.400421 688.380798,345.509064 686.570923,344.027222 
	C684.527405,342.493805 682.841431,341.043457 680.815369,339.485321 
	C680.341248,339.188019 680.150024,339.070221 679.674927,338.768402 
	C679.013672,338.331635 678.643494,338.068726 678.006165,337.587463 
	C672.070496,332.314758 666.217285,327.520386 658.979736,324.087494 
	C657.614075,323.109741 656.484619,322.398773 654.985840,321.645874 
	C652.777039,319.920135 650.620361,318.724457 648.266541,317.228241 
	C647.438843,316.697601 646.896729,316.333252 646.032532,315.872894 
	C645.355652,315.522369 644.972412,315.313080 644.300476,314.940704 
	C641.933228,313.468842 639.847473,312.172913 637.243530,311.094727 
	C630.112915,307.802246 623.450317,304.307007 615.869690,301.999237 
	C606.436157,298.512451 597.164429,295.799103 587.360474,293.445007 
	C572.939331,290.436707 558.780212,288.675842 544.491028,287.727142 
	C538.969421,287.360565 533.554321,287.635193 527.690430,288.433289 
	C521.842468,288.078827 516.584900,289.656647 510.812378,289.886932 
	C508.050110,290.565063 505.588898,290.446228 502.838257,291.097076 
	C499.906921,291.463989 497.425873,292.072174 494.587830,292.820374 
	C493.027283,293.335449 491.726013,293.298615 490.193298,293.928162 
	C480.692657,296.090698 471.915314,299.263184 462.845581,302.737061 
	C459.435486,303.639923 456.684052,305.149048 453.547089,306.663330 
	C448.972931,308.189819 445.139740,310.466278 440.843445,312.563995 
	C439.308289,313.301544 438.048950,313.815094 436.566956,314.638977 
	C433.668915,315.990753 431.105835,317.250092 428.690063,319.396851 
	C427.097717,320.407104 425.856171,321.331055 424.445496,322.592102 
	C422.179749,324.271271 420.088348,325.609650 417.775757,327.217255 
	C416.092407,328.451935 414.398621,329.050110 412.851501,330.465210 
	C412.457428,330.825592 412.299164,330.967804 411.901855,331.318054 
	C411.501740,331.660736 411.340668,331.795441 410.932861,332.124023 
	C410.517395,332.440826 410.348663,332.563751 409.922424,332.863281 
	C408.963196,333.492676 408.277557,333.966614 407.446472,334.784424 
	C405.206818,336.800507 402.780304,338.122498 401.015228,340.679077 
	C400.641754,341.105072 400.483246,341.266968 400.063416,341.644135 
	C399.440796,342.124725 399.073914,342.381989 398.433197,342.829285 
	C396.698700,344.011383 395.240601,345.005890 393.795959,346.552551 
	C388.885437,351.090179 385.039398,356.125183 380.774109,361.244385 
	C371.734924,372.221741 363.754791,383.464722 358.313538,396.714966 
	C358.147644,397.267578 358.071045,397.485443 357.861755,398.023010 
	C357.631622,398.551727 357.534210,398.760773 357.279053,399.277039 
	C357.008301,399.784271 356.895294,399.984283 356.600922,400.476196 
	C354.935425,402.790497 353.756165,404.959534 353.133698,407.766907 
	C352.813019,408.980164 352.448181,409.816040 351.998962,410.983978 
	C351.264038,412.768005 350.701050,414.250488 350.285889,416.150085 
	C349.965271,417.422882 349.523163,418.258148 348.745789,419.320374 
	C346.162598,421.029236 346.799835,424.661804 343.632446,425.834076 
	C343.025299,425.911072 342.778656,425.914185 342.164642,425.864258 
	C338.032227,424.902435 335.364105,423.114594 336.011902,418.273529 
	C335.866943,416.420380 336.175568,415.004547 336.839478,413.295685 
	C337.563873,411.710602 338.042755,410.397308 339.061523,408.996002 
	C340.089264,407.837982 341.160797,407.092041 341.147736,405.368469 
	C341.152985,404.851227 341.158783,404.643707 341.205109,404.126160 
	C341.392822,403.203644 341.675415,402.653687 342.197266,401.887268 
	C342.874207,400.998566 343.172485,400.280823 343.196655,399.128296 
	C349.300812,384.353455 356.673340,370.646057 366.699738,358.059357 
	C367.587830,356.819275 368.273895,355.869446 369.176147,354.642700 
	C375.651978,347.841492 382.302734,341.702820 388.630066,334.773010 
	C388.961517,334.310608 389.105286,334.134827 389.491699,333.722870 
	C390.592529,332.792480 391.551544,332.222687 392.533447,331.152832 
	C395.226746,327.470276 399.071198,325.615845 402.331909,322.569580 
	C406.244171,319.685394 409.771790,316.873352 413.358521,313.596130 
	C415.150269,312.444489 416.822144,311.746033 418.491333,310.393799 
	C422.407471,307.484650 426.238220,305.059418 430.381805,302.486572 
	C432.451721,301.301636 434.346924,300.522339 436.424652,299.349609 
	C440.110931,297.864563 442.738220,295.027069 446.772430,294.367554 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M758.750488,351.811218 
	C761.119202,351.780792 761.607300,350.069031 761.979980,348.473206 
	C762.829346,344.836395 766.478027,342.576660 766.083130,338.690338 
	C767.911011,338.599670 767.980957,339.514832 767.949585,340.306366 
	C767.749573,345.344727 767.078674,350.154633 768.390015,355.438019 
	C769.293945,359.080109 766.308472,363.981323 764.330200,367.955963 
	C762.620117,371.391754 761.596191,375.046417 759.984558,378.482422 
	C759.465637,379.588898 759.580872,381.404968 757.720886,381.193451 
	C756.335266,381.035858 755.484741,379.936279 754.827942,378.675995 
	C754.100220,377.279663 754.027283,375.828339 753.978210,374.320312 
	C753.929260,372.818176 753.764343,370.880737 752.085327,370.713440 
	C750.494324,370.554932 749.755737,372.514191 749.323242,373.807220 
	C748.492493,376.290894 748.384094,378.837372 745.306946,380.149261 
	C740.651123,382.134216 737.478333,380.676208 737.472778,375.610657 
	C737.471008,373.961639 737.321350,372.771484 736.165894,371.310211 
	C738.015869,363.729919 740.873962,356.782379 744.222229,349.940613 
	C745.954773,350.138763 747.508728,353.497162 749.727539,350.161469 
	C752.176697,351.169617 755.977478,351.071747 754.208557,355.413666 
	C753.959595,356.024963 754.701721,356.800110 755.381409,356.548126 
	C757.059509,355.926056 757.973022,354.611938 758.020142,352.401367 
	C758.188477,351.898376 758.344788,351.760986 758.750488,351.811218 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M725.738159,341.152466 
	C726.750000,340.976959 727.500000,340.983246 728.625000,340.987427 
	C728.170654,345.487854 724.795166,348.809967 723.398132,353.058624 
	C723.009155,354.241333 722.234985,355.091797 720.759949,354.357910 
	C719.398621,353.680542 717.586182,352.884125 718.263184,351.202484 
	C719.259521,348.727875 719.201599,345.548859 722.492249,344.064758 
	C723.438843,343.430328 724.221191,343.051483 724.909302,342.100983 
	C725.215088,341.669495 725.345703,341.501892 725.738159,341.152466 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M746.175232,306.350586 
	C748.399536,305.194946 750.405273,304.956421 751.738647,307.807098 
	C751.843689,309.493530 751.687378,310.985870 751.263062,312.735413 
	C748.983337,314.331909 747.942993,312.688934 746.480469,311.332703 
	C746.197449,309.636108 746.155945,308.207428 746.175232,306.350586 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M748.151550,348.138611 
	C749.311218,344.902649 750.695679,341.851105 752.434570,338.018433 
	C753.089478,340.973724 753.554260,343.070984 754.064880,345.550415 
	C752.199280,346.729401 750.287781,347.526215 748.151550,348.138611 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M746.181396,311.500366 
	C748.105347,310.540375 748.934143,312.344849 750.660522,312.939148 
	C751.831665,316.552734 748.732971,317.292816 746.452759,318.933167 
	C746.118347,316.648895 746.121094,314.292389 746.181396,311.500366 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M725.780884,341.676758 
	C726.154846,343.839874 725.467102,344.749878 723.160400,344.091980 
	C723.008545,343.149445 723.174988,342.277771 723.564453,341.157043 
	C723.787354,340.908020 724.027283,340.899323 724.130737,340.838348 
	C724.163452,340.365906 724.247986,340.072388 724.499084,340.054474 
	C725.478271,339.984497 725.345154,340.827881 725.780884,341.676758 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M743.427612,328.936523 
	C741.542786,327.066711 743.846069,326.969696 744.709534,326.023438 
	C744.803101,327.048615 745.313538,328.414093 743.427612,328.936523 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M734.207397,333.807495 
	C733.963013,333.558228 733.966858,333.124847 733.961914,332.367188 
	C734.185059,331.670654 734.416931,331.298370 734.648743,330.926056 
	C735.244873,331.872467 734.997986,332.758514 734.207397,333.807495 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M733.064331,367.479248 
	C733.132263,368.347076 733.006531,369.318726 732.473816,369.090485 
	C731.700989,368.759369 731.977783,367.780457 732.323242,367.059875 
	C732.367737,366.967102 732.567566,366.866394 732.653564,366.895050 
	C732.787537,366.939606 732.885620,367.091888 733.064331,367.479248 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M729.232361,361.831421 
	C729.076355,361.359589 729.152771,360.718781 729.229126,360.077942 
	C729.438110,360.152771 729.834290,360.263611 729.827820,360.297089 
	C729.739258,360.758453 729.595154,361.209137 729.232361,361.831421 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M736.005005,328.251953 
	C736.116638,328.272278 736.230835,328.551514 736.184204,328.929657 
	C736.018005,328.856018 736.012756,328.683472 736.005005,328.251953 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M729.000000,361.999390 
	C728.913513,362.032776 728.827087,362.066833 728.870361,362.050659 
	C729.000000,362.000427 729.000000,362.000000 729.000000,361.999390 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M773.610107,348.920044 
	C773.317322,350.393158 773.126099,352.526794 770.974060,351.837677 
	C769.258911,351.288483 770.133728,349.826691 770.883240,348.388245 
	C771.789062,348.316193 772.553833,348.569641 773.610107,348.920044 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M755.993652,345.251617 
	C756.170776,345.177429 756.349548,345.336639 756.483337,345.527374 
	C756.510010,345.565399 756.364624,345.724030 756.130371,345.906250 
	C755.962097,345.985443 755.979492,345.496063 755.993652,345.251617 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M742.669434,412.788452 
	C743.332642,412.007965 743.834473,411.556427 744.686035,411.047668 
	C745.367859,411.066711 745.621155,411.251190 745.924744,411.746521 
	C746.653687,412.636383 747.590210,412.847443 748.434143,413.749847 
	C749.120239,415.475830 750.171387,416.816467 749.633423,418.833008 
	C751.381714,421.835449 754.620789,417.585541 756.471313,420.847534 
	C756.507935,421.862335 756.575989,423.026337 756.070618,423.387207 
	C751.875610,426.382690 753.916809,429.711029 755.411255,433.012634 
	C759.123169,441.213379 761.986206,449.729004 764.981689,458.611511 
	C764.756348,459.346252 764.299500,459.543091 763.391174,459.474854 
	C760.825256,457.771088 760.691833,455.004211 759.217041,452.557983 
	C755.584534,443.036163 752.034241,433.883270 747.358215,424.811707 
	C745.838074,420.611053 742.819702,417.420258 742.669434,412.788452 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M749.782166,418.849182 
	C749.014648,417.796783 748.346008,416.428223 747.637207,414.724609 
	C747.597107,414.389526 747.794800,414.016205 747.849121,413.810547 
	C747.903442,413.604858 748.192139,413.289429 748.587585,413.153412 
	C749.607971,414.456665 750.232971,415.895905 750.932983,417.656067 
	C750.632324,418.162354 750.256714,418.347717 749.782166,418.849182 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M758.947144,351.678040 
	C759.000000,351.998779 758.515747,352.018585 758.273926,352.027161 
	C757.292419,351.740723 755.745178,351.750275 756.856018,350.463959 
	C757.604736,349.596924 758.511780,350.333893 758.947144,351.678040 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M748.934692,399.036621 
	C747.954407,398.603577 747.839966,397.763611 748.394653,397.623138 
	C748.912720,397.491913 749.640869,398.190369 750.276550,398.523682 
	C749.932678,398.709869 749.588806,398.896057 748.934692,399.036621 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M747.725830,414.265259 
	C747.117676,413.769897 746.638306,413.150238 746.106384,412.239868 
	C746.983521,412.119263 748.016357,411.416199 748.923462,412.649292 
	C748.983032,413.017395 749.006897,412.987976 749.017944,412.972595 
	C748.871033,413.108124 748.713196,413.259003 748.331482,413.649048 
	C748.107666,413.888214 747.854492,414.140991 747.725830,414.265259 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M763.550415,411.479126 
	C763.577209,411.527527 763.523560,411.430756 763.550415,411.479126 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M750.537109,411.458649 
	C750.621704,411.560486 750.302307,411.581238 750.302307,411.581238 
	C750.302307,411.581238 750.452454,411.356812 750.537109,411.458649 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M750.974915,418.017426 
	C751.100464,418.148438 751.240295,418.268372 751.380066,418.388367 
	C751.302795,418.337128 751.225525,418.285889 751.078125,418.105835 
	C751.007996,417.976990 750.989136,418.006378 750.974915,418.017426 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M773.006104,424.665344 
	C773.007690,424.985352 772.580994,425.065094 772.215027,424.974792 
	C772.135315,424.178009 771.523743,423.131622 772.835388,422.907013 
	C773.009644,423.493530 773.007080,423.919434 773.006104,424.665344 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M706.984863,352.120392 
	C704.239624,349.903473 701.630066,347.313263 698.879272,344.412476 
	C700.662231,341.992310 702.092285,339.872192 701.790833,336.644531 
	C701.515198,333.693207 702.980042,331.196228 706.426025,330.067261 
	C711.954895,329.395660 711.954895,329.395660 712.767334,336.281067 
	C713.078003,337.623260 713.043335,338.588013 712.217285,339.757874 
	C710.355225,343.944244 708.737915,347.845673 706.984863,352.120392 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M717.923340,305.595428 
	C717.996704,306.453979 717.997070,306.895050 717.997559,307.666809 
	C719.956970,310.740051 715.869446,312.617828 716.957825,315.571472 
	C716.933533,317.386993 717.351807,318.862091 716.927124,320.719666 
	C716.487915,322.069458 716.272888,323.089081 715.814819,324.461975 
	C715.278748,325.764648 714.787170,326.634827 714.265686,327.484131 
	C712.680725,330.065491 711.383484,329.030060 710.336853,327.065674 
	C709.536133,325.562775 709.434021,323.851929 709.556091,322.190857 
	C709.970947,316.545715 710.792725,310.953888 712.167908,305.459900 
	C712.520874,304.049683 712.836060,302.149353 714.535034,302.001190 
	C716.434387,301.835541 717.324341,303.496216 717.923340,305.595428 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M715.891602,321.470490 
	C716.030029,319.380127 716.237305,317.913788 716.698975,316.177002 
	C717.758057,316.577362 718.562866,317.248169 719.682617,317.958618 
	C718.937012,324.010468 717.929626,330.032776 716.787598,336.029449 
	C716.377075,338.184937 714.839844,339.433228 712.342041,339.987061 
	C712.006958,338.962311 712.041321,337.881744 712.095825,336.397278 
	C712.116028,332.326874 714.137573,329.220215 715.021606,325.459656 
	C715.171387,324.938904 715.244751,324.735901 715.444946,324.237793 
	C715.827087,323.353088 715.963562,322.738495 715.891602,321.470490 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M728.536865,316.183167 
	C729.512756,316.785583 730.241089,317.548035 731.051270,318.620239 
	C730.312134,319.266052 729.491272,319.602112 728.341064,319.970703 
	C727.317444,318.723480 726.696533,317.449310 728.536865,316.183167 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M726.003296,341.717896 
	C725.535767,341.635773 725.065552,341.304626 724.414795,340.875427 
	C724.190308,340.000824 723.789795,338.937744 724.800232,338.676544 
	C726.030762,338.358582 725.808716,339.565796 725.993774,340.624451 
	C726.000000,340.970642 726.000610,341.468811 726.003296,341.717896 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M720.001221,318.001831 
	C720.002441,316.696045 720.002441,315.388397 720.002441,313.584503 
	C721.448486,315.182892 720.987427,316.364380 720.254761,317.772461 
	C719.997559,317.998291 720.000000,318.000000 720.001221,318.001831 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M736.300598,325.970459 
	C736.173645,325.444000 736.342468,324.896576 736.745850,324.175110 
	C737.093384,324.305359 737.279480,324.555176 737.749207,324.855042 
	C737.505310,325.289520 737.050781,325.619476 736.300598,325.970459 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M700.076294,252.016327 
	C699.028442,257.689819 696.076416,252.950546 693.663086,253.194824 
	C693.187012,253.065521 693.065247,252.875610 692.828247,252.369171 
	C690.641113,248.974594 689.775330,245.329971 687.833618,241.822601 
	C687.510864,241.355286 687.390381,241.161133 687.111755,240.660965 
	C686.572266,239.253143 685.937134,238.276245 685.291382,236.904282 
	C684.922180,235.590317 684.940308,234.636093 685.640503,233.431015 
	C685.864380,233.139175 685.939819,233.061890 685.977783,233.023132 
	C686.185852,232.989807 686.356018,232.995270 686.781433,233.003265 
	C687.886169,233.017120 688.731567,233.087433 689.878601,233.152420 
	C690.579895,233.060333 690.980286,233.022064 691.681885,233.002838 
	C693.983215,231.584534 696.422180,232.699768 696.098694,234.250168 
	C695.409912,237.551178 698.350464,239.563736 698.049316,242.852905 
	C698.894836,244.568802 698.255615,246.079788 698.802002,247.760666 
	C699.024658,248.045792 698.988647,248.006912 698.984131,247.980789 
	C699.861938,249.009048 699.300110,250.305878 699.795654,251.748199 
	C700.022583,252.033127 700.057861,252.000656 700.076294,252.016327 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M704.167114,254.907593 
	C704.054321,254.582657 704.119324,254.349686 704.120117,254.230255 
	C704.744751,253.140747 704.552185,251.408997 706.171753,251.391998 
	C707.160156,251.381622 706.857849,252.591370 707.166565,253.605194 
	C707.006592,254.860367 706.755127,255.747498 705.463501,256.235992 
	C704.718567,256.024475 704.438477,255.677536 704.167114,254.907593 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M704.975220,256.216309 
	C705.705383,255.365128 706.198669,254.785217 706.954712,254.076538 
	C707.460205,254.429657 707.703003,254.911560 708.016968,255.665466 
	C707.183044,256.739960 708.129395,258.493225 706.282104,258.969299 
	C705.264282,258.326263 704.882996,257.467529 704.975220,256.216309 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M690.979370,233.683975 
	C689.531616,235.757568 688.540894,233.997772 687.207153,233.262711 
	C687.838318,232.416794 688.639893,231.827759 689.722961,231.119598 
	C690.580322,231.683258 690.821594,232.501984 690.979370,233.683975 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M705.390625,249.757935 
	C704.337402,249.988861 703.480286,250.157532 703.305908,249.069519 
	C703.268311,248.834824 703.480408,248.387955 703.678284,248.316010 
	C704.830688,247.897003 705.380554,248.554810 705.390625,249.757935 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M699.015381,247.670792 
	C697.844238,246.899963 697.894165,245.429245 698.008545,243.595490 
	C699.543213,244.320389 698.728455,245.933670 699.015381,247.670792 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M691.212280,233.895721 
	C690.096680,233.469498 690.005432,232.594330 690.011963,231.337799 
	C690.580688,231.491821 691.156921,231.983170 691.856812,232.735260 
	C691.882874,233.318970 691.699219,233.581848 691.212280,233.895721 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M700.047974,251.657318 
	C698.571594,251.283554 698.897034,249.953995 698.959717,248.335693 
	C700.398071,248.717178 699.861145,250.122498 700.047974,251.657318 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M715.700928,323.774963 
	C715.981812,328.162811 714.319458,331.856049 712.379395,335.769257 
	C710.504944,334.495331 710.504944,334.495331 711.397705,330.663116 
	C710.025391,330.276428 709.039795,332.636383 707.272339,331.117798 
	C704.805908,329.286774 704.080261,326.955017 704.568298,324.355255 
	C706.752502,312.718475 706.435547,300.948242 706.575317,289.200958 
	C706.591309,287.858032 706.695312,286.553772 707.768799,285.108276 
	C708.899048,284.686951 709.706665,284.544830 710.757568,284.202759 
	C714.348389,283.876678 714.221375,286.705658 714.842896,288.718445 
	C716.031677,292.568756 716.303894,296.691559 718.573730,300.563904 
	C718.926880,302.380005 719.302795,303.962982 718.271973,305.741577 
	C716.409180,305.498901 716.190247,303.685883 714.338867,302.323334 
	C712.783264,311.076080 709.752136,319.232544 711.915955,329.148529 
	C713.049622,326.911041 713.679199,325.668365 714.535645,324.195007 
	C714.973206,323.828552 715.183899,323.692810 715.700928,323.774963 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M718.814087,300.435608 
	C713.629944,299.915985 715.868774,294.888214 714.166992,292.008148 
	C712.853516,289.785187 713.618896,286.554260 711.341248,284.154175 
	C713.102661,281.979431 711.217529,278.196198 714.578430,276.710938 
	C716.179810,284.570404 717.474365,292.311981 718.814087,300.435608 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M693.183716,252.180557 
	C693.099976,252.511749 693.139526,252.901733 693.224182,253.078583 
	C694.040894,256.396057 695.521118,258.265015 699.149170,256.992401 
	C700.698975,256.448761 701.748413,256.704010 702.249695,258.789154 
	C702.922241,261.586731 704.238037,264.229736 705.579956,267.166992 
	C706.872620,272.522583 707.863037,277.650238 708.475098,282.907593 
	C705.219238,284.194489 703.275269,285.674469 706.054443,288.832520 
	C707.159729,290.088531 707.422791,291.783112 706.689270,293.020813 
	C705.086731,295.724976 703.853577,298.617859 702.220337,301.285706 
	C701.453674,302.537933 700.268311,303.841980 698.578003,303.279358 
	C696.811462,302.691437 696.961121,300.976562 697.088196,299.473572 
	C697.213318,297.993317 697.670715,296.530640 697.387451,295.031250 
	C697.134827,293.694489 696.672058,292.241486 695.365662,291.798065 
	C693.831970,291.277557 693.715332,293.043579 693.077820,293.879211 
	C691.212402,296.324158 688.971619,295.654083 688.900879,293.244995 
	C688.704224,286.546478 684.515625,281.663361 682.089233,275.632568 
	C682.083435,274.444641 682.062073,273.368134 682.631653,272.911560 
	C686.638062,269.700256 684.884216,266.650787 682.299377,263.314148 
	C681.517761,262.455322 681.481628,261.771667 681.514099,261.410370 
	C681.219177,262.043304 681.795837,262.748322 682.618164,263.756409 
	C683.695740,267.093323 683.728577,269.925751 680.555420,272.161621 
	C677.310730,269.041260 678.208984,264.604614 676.269775,260.698669 
	C675.542542,257.739380 674.418457,255.157440 676.353271,252.264404 
	C677.767700,251.360138 678.676025,251.795456 679.699829,252.948593 
	C681.374878,254.549164 682.853149,255.802673 684.664001,257.030304 
	C684.977905,255.672241 684.959229,254.340042 684.877319,252.606445 
	C684.459412,251.435791 684.841309,250.794479 685.421936,249.887405 
	C686.102966,249.371063 686.568054,249.286713 687.066528,249.340225 
	C688.523743,249.496613 689.404053,253.082718 691.549561,249.981445 
	C691.921387,249.444061 692.925781,250.942200 693.183716,252.180557 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M705.085571,255.665955 
	C705.460022,256.749146 705.707947,257.553253 705.988525,258.675049 
	C703.689087,258.739197 704.027161,256.982483 704.033325,255.003571 
	C704.355896,254.850739 704.657410,255.118820 705.085571,255.665955 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M682.663269,274.934082 
	C684.840271,279.675446 688.135681,283.364227 689.670044,288.162354 
	C690.344849,290.272736 687.392334,293.179504 691.218872,294.426483 
	C692.882568,292.817261 692.638367,289.821838 694.833069,288.527649 
	C699.718445,292.124573 699.648987,296.960388 697.648132,302.562012 
	C700.449280,301.977509 701.407959,300.709778 702.126770,299.004791 
	C702.975281,296.991943 702.926880,294.665558 705.363342,293.221924 
	C706.548462,292.519714 707.027466,290.082886 704.122192,289.331482 
	C702.331055,288.868317 702.390320,286.133148 704.082336,282.563202 
	C705.315186,279.961761 706.360901,282.328217 707.787109,282.883789 
	C708.068481,283.435730 708.040161,283.834229 708.051697,284.530884 
	C708.061523,292.264832 708.037109,299.700562 707.999878,307.136261 
	C707.972656,312.573853 707.342041,317.953369 706.048706,323.224884 
	C705.416382,325.802368 706.424683,328.022339 706.979980,330.685364 
	C703.644287,332.056244 702.726135,334.041138 703.316711,337.646332 
	C703.727783,340.155762 703.288452,343.416992 699.123962,344.068665 
	C690.316284,336.905487 682.151794,329.399109 672.984131,322.636902 
	C671.650818,319.609802 675.632141,319.462494 675.534668,316.858002 
	C675.758728,316.104156 676.005249,315.728668 676.579956,315.171326 
	C680.415344,312.607330 682.794800,310.178955 678.913147,305.915833 
	C677.411804,304.266968 678.911499,302.202942 679.800110,300.420776 
	C681.384338,297.243011 683.906921,294.326141 682.704834,290.019836 
	C682.517029,289.478027 682.451904,289.258240 682.323120,288.702209 
	C681.981873,286.115692 681.483093,283.931549 680.202148,281.600708 
	C679.565125,279.176666 679.538147,277.163696 681.272339,275.126709 
	C681.842285,274.816132 682.098755,274.749420 682.663269,274.934082 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M334.108765,284.262268 
	C335.449219,281.506531 338.464752,281.190704 340.745544,279.227722 
	C345.665131,276.031738 349.854706,272.360046 354.570221,269.265411 
	C353.838593,266.877228 350.728699,268.770813 349.954163,266.363892 
	C353.847839,263.937592 357.944672,263.014709 362.270477,262.981537 
	C364.396912,262.965210 366.554810,263.042450 368.675751,261.435333 
	C368.845093,260.987823 368.744141,260.795624 368.814758,260.326721 
	C372.416687,258.046387 375.847015,256.042755 379.624298,253.968491 
	C380.861206,253.410767 381.788086,253.063507 383.179749,253.234833 
	C385.571075,255.206451 384.953644,256.688751 382.794250,257.850555 
	C380.542603,259.061981 379.768707,260.632172 381.338074,263.269226 
	C380.252930,265.710571 378.357025,266.115417 375.914001,266.092560 
	C374.434418,266.086334 373.748962,264.344910 372.735565,265.039734 
	C372.290436,265.344910 373.215454,266.400787 374.312317,266.858276 
	C377.557465,267.683990 380.307465,268.917114 383.446228,270.098999 
	C383.933380,270.428650 384.110809,270.584595 384.467834,271.044739 
	C385.293182,273.740784 384.340332,275.628174 382.590698,277.099182 
	C381.051056,278.393646 379.192810,279.049347 377.966705,276.810516 
	C376.021515,273.258850 372.343109,272.402161 369.265564,270.466980 
	C368.110809,273.488983 369.002045,277.096924 365.390076,279.067322 
	C364.581177,279.268158 364.108154,279.332672 363.270874,279.380829 
	C360.993347,279.368622 359.140503,280.076691 356.874115,279.837769 
	C355.554077,279.529572 354.860413,278.736725 353.933136,278.388397 
	C353.309296,278.154083 352.793182,278.302856 353.448639,278.421509 
	C353.501678,278.431122 353.509918,278.688293 353.751831,279.014893 
	C354.540771,279.774658 355.079437,280.390503 355.964661,280.513733 
	C358.256165,280.832825 359.216614,282.168732 359.343384,284.473450 
	C359.480988,286.975494 357.296661,286.594727 355.817749,287.633972 
	C352.871277,290.133301 349.185028,291.534332 348.606140,295.992310 
	C348.629059,296.732727 348.562592,297.139709 348.233063,297.759949 
	C347.714905,298.181396 347.371765,298.211304 346.807861,298.180786 
	C346.450409,298.463226 346.307800,298.580292 345.951355,298.880188 
	C345.138428,299.570496 344.566864,300.114441 343.551758,300.555573 
	C342.804138,300.642609 342.381836,300.555145 341.696838,300.196899 
	C340.440186,298.173584 337.177979,297.681396 338.281555,294.635620 
	C338.680206,293.535370 338.020721,294.747253 338.021515,294.516571 
	C336.410034,297.636749 334.235931,295.832764 331.891663,295.014435 
	C328.957092,290.106812 329.218018,289.675873 336.722168,286.473358 
	C336.668823,284.902802 334.937286,285.369659 334.108765,284.262268 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M364.747009,278.372681 
	C367.069855,275.955048 368.067200,273.486969 366.970856,270.517578 
	C366.696838,269.775360 365.908447,269.081665 366.864685,268.403290 
	C367.616974,267.869598 368.506195,268.119110 369.122192,268.707703 
	C372.169861,271.619720 377.772400,271.163086 379.069641,276.475372 
	C379.537567,278.391479 381.089203,277.117065 381.766113,276.537079 
	C383.164795,275.338684 383.932068,273.668518 383.993927,271.383728 
	C386.107056,269.125977 388.061493,270.122162 389.752289,271.541565 
	C391.752899,273.221100 393.955231,274.766449 395.078796,277.622467 
	C395.581696,281.224640 395.845490,284.644379 399.578430,286.008423 
	C400.769135,286.443573 401.650482,287.024963 401.935852,288.677673 
	C399.999908,290.468445 397.466614,289.574585 395.422424,290.597046 
	C394.273041,291.171936 392.684448,291.892365 395.276184,293.328125 
	C396.917450,294.536865 397.064606,295.741913 395.762512,297.268311 
	C395.053314,297.476074 394.619171,297.453491 394.282318,297.190186 
	C394.174835,297.106171 394.355896,296.340698 394.371399,297.328918 
	C395.231018,297.956573 395.360474,298.538116 394.814514,299.442505 
	C393.475616,300.220917 392.311401,300.218811 390.799408,300.203491 
	C386.054688,302.124146 382.614349,298.596069 378.297943,298.032837 
	C377.747711,297.841095 377.536469,297.740082 377.064545,297.408081 
	C376.724335,296.962250 376.644806,296.747345 376.573181,296.192810 
	C377.416260,294.231842 377.600952,292.597443 376.580963,290.631958 
	C376.443542,290.110748 376.405182,289.898346 376.354431,289.364105 
	C376.420654,287.987671 376.450592,286.937592 375.936035,285.620422 
	C373.507294,279.905579 373.247314,279.802338 369.627106,283.508087 
	C368.580139,284.579803 367.493042,285.069122 366.090027,284.689209 
	C364.523743,284.265076 364.223358,282.965118 364.158112,281.570007 
	C364.111481,280.573792 364.091431,279.576904 364.747009,278.372681 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M394.691071,299.412476 
	C394.697876,298.641449 394.476105,298.109863 394.150513,297.296326 
	C395.678986,296.728210 396.424591,295.987488 395.146820,294.161407 
	C394.067627,290.970032 396.432190,290.892883 398.217010,290.578766 
	C400.263794,290.218506 402.369293,290.546082 404.859131,290.783203 
	C406.411835,291.021790 406.891296,291.933441 407.746033,292.831726 
	C408.032471,293.151978 408.155060,293.417511 408.105133,293.455322 
	C404.849396,295.921753 403.259827,299.375214 401.339050,302.894073 
	C399.957672,305.424713 400.477386,306.833984 400.819977,309.098419 
	C397.819824,309.396088 400.083740,311.723969 399.028931,312.903625 
	C396.643829,315.413269 400.282623,314.287994 400.317780,315.559235 
	C401.122833,316.741943 401.396271,317.814301 401.989227,319.103088 
	C402.550110,320.402161 403.071136,321.368317 403.288757,322.781281 
	C400.115051,326.010590 396.924988,328.912933 393.420868,331.931641 
	C392.253601,332.621613 391.206665,332.859802 390.240051,333.820801 
	C390.037781,334.054443 390.075195,334.020355 390.080566,334.045074 
	C389.723358,334.359772 389.316498,334.552673 388.520508,334.591614 
	C386.120728,333.627991 385.913147,331.474762 384.926270,329.489014 
	C384.736908,328.850342 385.017151,328.384583 384.799927,328.164429 
	C384.337708,327.695923 385.029816,328.042786 384.205933,328.551727 
	C381.008209,328.960632 378.266052,327.924713 375.104431,327.467407 
	C374.369904,327.109863 374.023285,326.797913 373.561951,326.115692 
	C372.903687,321.899963 373.592926,317.836975 371.640869,314.131348 
	C371.054688,313.018585 371.480835,311.867737 372.645508,310.734619 
	C374.145538,310.030762 375.323761,310.323181 376.845764,310.754730 
	C382.793610,312.599121 379.680634,317.158417 380.308075,321.126099 
	C383.616486,317.953552 388.655212,316.867249 387.596191,310.780090 
	C387.285675,308.995239 388.963776,307.127472 390.155975,305.122162 
	C392.006195,303.336578 393.024963,301.328247 394.691071,299.412476 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M399.828613,309.399750 
	C400.964630,308.119324 400.262177,307.535553 399.345001,307.590149 
	C395.365051,307.827271 396.919098,305.497192 398.217743,304.532928 
	C400.634918,302.738220 401.472198,300.974365 401.491852,297.797211 
	C401.508942,295.033203 405.217865,294.605469 407.775513,293.265778 
	C411.698730,290.683533 409.425568,286.987549 410.101624,283.537598 
	C410.121216,283.104340 410.349518,282.840576 410.484833,282.847229 
	C410.620148,282.853912 410.808197,282.636902 410.633179,282.625214 
	C410.458130,282.613525 410.302155,282.260254 410.190918,282.102448 
	C410.079651,281.944641 410.065643,281.487518 410.053467,281.259186 
	C415.204590,278.386139 415.332794,278.342773 416.970490,283.183838 
	C417.455688,284.618225 418.147400,283.751984 418.757599,283.905426 
	C421.278503,284.539307 422.514130,282.204742 424.652588,281.182678 
	C424.873413,280.921234 424.912292,280.904388 424.916351,280.883606 
	C426.488373,279.901825 427.893311,277.448975 429.976471,279.678192 
	C431.956573,281.797058 430.126526,283.355103 427.634430,285.202606 
	C431.698547,284.436310 431.356934,280.347290 434.533020,279.732605 
	C435.747040,280.219971 436.068878,281.087463 436.539215,282.248230 
	C437.306183,284.069580 437.968231,285.553589 440.328674,285.110962 
	C441.067810,286.239441 441.112457,287.188141 440.924255,288.526123 
	C440.383728,290.323517 439.659332,291.615356 438.467957,293.050964 
	C434.092102,297.070343 429.092346,297.329498 423.583801,296.851074 
	C421.102600,296.256348 420.326569,294.326813 419.050812,292.507660 
	C418.837921,291.912109 419.008392,291.387543 418.800446,291.270935 
	C418.227448,290.949646 418.933838,291.031219 418.539124,291.920105 
	C415.672028,295.742737 412.240265,298.467072 408.364532,300.680023 
	C406.702332,301.629059 405.495605,302.891998 406.184326,305.359802 
	C406.656555,307.111176 405.834320,308.215973 405.083801,309.671387 
	C404.608673,310.518494 404.253235,311.057892 403.670044,311.831787 
	C403.284973,312.202820 403.109009,312.327332 402.301666,312.542664 
	C401.466522,312.567596 401.262787,312.501770 400.805725,312.239807 
	C399.963837,311.370544 399.840759,310.532898 399.828613,309.399750 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M424.499573,280.936462 
	C423.892212,285.564606 419.769104,285.775726 416.337341,286.703278 
	C415.210968,287.007751 414.946594,285.495880 415.336273,284.479553 
	C415.885468,283.047394 416.161560,281.612030 415.874176,279.449066 
	C414.401581,281.766113 412.373627,280.553558 410.329102,281.003113 
	C409.094604,274.168854 409.643433,267.808868 415.301971,262.769684 
	C417.872040,260.480957 417.864502,256.981659 418.503601,253.847321 
	C422.893311,258.215851 423.806213,262.982635 420.822510,268.181671 
	C419.112610,271.161041 417.534668,273.723114 421.592010,276.049042 
	C422.361206,276.489990 422.934296,277.367767 422.312805,278.582062 
	C421.255371,280.648041 421.344910,280.693878 424.499573,280.936462 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M410.289062,232.233292 
	C408.024384,231.173126 410.583954,224.999924 405.250305,227.931488 
	C405.137268,227.993607 403.169189,226.517822 403.854462,225.241714 
	C406.384094,220.531250 404.036133,217.079056 401.181580,213.662964 
	C400.234772,212.529968 398.855499,211.162186 399.579803,209.779205 
	C400.028839,208.921738 401.854218,208.445312 403.085632,208.396408 
	C408.310760,208.188843 420.238434,218.603333 420.620148,223.715347 
	C420.713043,224.959229 420.017181,226.261993 419.470764,227.803375 
	C415.565338,228.072128 417.086823,223.346329 413.880005,222.482452 
	C412.938721,224.363510 413.481598,226.430252 413.467773,228.764832 
	C413.549347,229.593948 413.575562,230.071960 413.560791,230.918106 
	C413.454651,231.517944 413.371796,231.750641 413.011261,232.230667 
	C412.013794,232.787827 411.295654,232.795670 410.289062,232.233292 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M395.375854,278.121857 
	C391.311462,276.090607 389.808685,270.964325 384.386841,271.013153 
	C384.006836,271.013428 383.912689,270.856537 383.865540,270.778137 
	C382.871613,269.356384 383.435242,267.924866 383.685730,266.569397 
	C384.016724,264.778381 383.279816,263.874603 381.382080,263.259949 
	C378.319611,259.866272 378.907227,257.636566 382.851135,256.653870 
	C383.926880,256.385834 383.883392,255.570694 384.016785,254.444122 
	C388.924622,252.915833 391.218842,254.239777 392.922424,259.593872 
	C394.260559,263.799438 394.772064,268.247955 396.776367,272.662689 
	C397.415741,273.063324 397.831329,273.135162 398.558533,273.261261 
	C401.000214,274.350708 403.097382,275.409210 402.224060,278.685333 
	C399.885254,278.827637 397.738892,279.175232 395.375854,278.121857 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M397.002625,272.622925 
	C392.592834,270.697937 393.008911,266.041595 392.158966,262.250305 
	C391.178680,257.877563 389.693298,254.627213 384.408142,254.109756 
	C382.947540,254.051254 381.864899,254.002274 380.376770,253.925568 
	C380.856567,252.140686 382.437317,251.906509 384.520325,251.993591 
	C386.575378,251.005768 388.452637,251.775513 390.554016,251.257080 
	C394.056396,252.246933 397.410156,251.434921 400.789001,252.447784 
	C405.761536,253.938354 403.720947,258.025116 402.671448,258.545258 
	C396.492157,261.607819 398.014587,267.372833 397.002625,272.622925 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M333.555481,283.988708 
	C335.725037,284.199036 338.463745,283.596405 338.759033,285.849365 
	C339.133850,288.709229 336.342163,288.929810 334.205109,288.952240 
	C330.535461,288.990845 331.095306,291.318115 331.709045,293.828308 
	C332.314362,295.105621 331.606628,295.654419 330.803345,295.769470 
	C327.798798,296.199829 324.835999,295.965485 321.975311,297.935150 
	C320.106445,299.221924 318.293182,296.523376 316.288818,295.189636 
	C316.090515,293.215546 315.713257,291.368805 316.848145,289.347290 
	C317.003662,288.997284 317.000000,289.000000 316.998169,289.001831 
	C317.240540,288.800720 317.465485,288.578278 317.837250,288.166534 
	C318.003296,287.996704 318.000000,288.000000 317.998779,288.001099 
	C318.907227,287.134827 319.821045,287.458221 320.485901,288.217804 
	C321.519440,289.398621 322.413452,288.932068 323.688782,288.125244 
	C327.118744,286.789856 329.820709,284.608490 333.555481,283.988708 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M349.705811,266.150269 
	C351.267242,267.784058 352.730652,266.191071 354.060883,266.343903 
	C355.391571,266.496826 356.795990,267.015839 356.798187,268.627319 
	C356.799927,269.933350 356.062317,271.909943 354.887451,271.654114 
	C351.942841,271.012817 351.367767,273.289429 349.777435,274.599396 
	C347.433960,276.529694 344.852448,278.376556 341.325134,278.973022 
	C341.111969,276.630798 342.403015,274.271606 340.141266,272.076996 
	C339.030609,270.999298 339.819397,270.359039 341.291290,269.857605 
	C344.069946,268.910950 346.687500,267.491455 349.705811,266.150269 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M409.996582,232.346481 
	C410.776581,231.984436 411.554413,231.968430 412.720428,231.927216 
	C416.974274,233.280792 415.799042,236.473755 415.879944,239.633911 
	C416.316833,241.853836 420.077454,242.012344 418.523499,244.594421 
	C417.028534,247.078461 415.149750,249.411636 411.924438,249.857330 
	C410.709381,250.025223 410.152618,249.314316 409.974762,247.859283 
	C410.172394,245.643906 407.992828,244.437317 408.781281,242.209793 
	C409.002930,241.955338 409.010010,242.007126 408.983917,242.005692 
	C408.957825,242.004257 409.191101,241.675171 409.353455,241.555603 
	C409.515808,241.436035 409.457031,241.024414 409.330444,240.849716 
	C409.203857,240.675018 409.017426,240.276657 408.986877,240.058884 
	C408.307617,237.313797 407.846893,234.813797 409.996582,232.346481 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M435.068573,279.729187 
	C433.052307,281.135590 432.531891,283.055481 431.812378,284.882263 
	C431.350189,286.055695 430.257019,286.579712 429.080902,286.637451 
	C427.928589,286.694000 427.420715,285.813507 427.125244,284.802399 
	C426.511810,282.703094 427.882507,283.285797 429.086975,283.048340 
	C430.055542,282.857391 430.248993,281.439697 429.686310,280.538055 
	C428.133575,278.049927 427.121246,282.153809 425.266846,280.905304 
	C425.053436,280.067932 424.896759,278.924988 425.366180,278.534180 
	C428.602325,275.840057 430.235077,272.584229 429.795532,268.162781 
	C430.844086,268.229370 431.182404,270.101440 432.540314,268.266785 
	C433.551147,266.901062 433.529602,264.790619 435.786865,264.039337 
	C437.927032,266.683899 436.185913,269.443390 436.225342,272.526581 
	C435.800812,274.626617 434.841919,276.274536 435.370117,278.488770 
	C435.380768,279.047394 435.346710,279.270721 435.068573,279.729187 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M416.149414,239.661682 
	C414.289093,237.768951 415.147400,234.721420 413.365051,232.162277 
	C413.108643,231.902023 413.060486,231.471222 413.045715,231.254974 
	C412.770447,230.798645 412.533295,230.537369 412.171936,230.034546 
	C412.024445,228.321152 411.867950,226.807236 412.062927,225.340012 
	C412.326691,223.354935 410.774506,220.043747 413.183167,219.706406 
	C415.412354,219.394196 416.826324,222.586090 417.922241,224.790329 
	C418.345337,225.641296 418.407684,226.671631 418.945984,227.844650 
	C418.270203,231.817581 417.282806,235.566132 416.149414,239.661682 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M402.285858,279.117615 
	C402.971436,276.083893 398.866425,276.314758 398.899902,273.656250 
	C400.664856,271.932098 400.347900,270.140045 400.305908,268.138916 
	C400.272827,266.563629 400.993164,264.085236 403.337738,264.494354 
	C405.396118,264.853516 404.389008,266.744110 404.154877,268.168060 
	C403.685516,271.022644 405.052765,273.598938 405.608215,276.301392 
	C406.116211,278.772766 405.664825,280.373047 402.285858,279.117615 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M405.089813,290.713806 
	C402.084930,292.655518 398.133606,290.816742 394.995667,293.597565 
	C393.906189,294.304718 392.683624,295.458984 392.158905,293.640503 
	C391.695984,292.036316 391.749146,290.271729 393.800751,289.674286 
	C396.219086,288.970062 398.629456,287.761993 401.608124,288.991150 
	C402.949707,289.499268 403.930176,289.966949 405.089813,290.713806 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M323.859467,287.698883 
	C322.292938,294.016235 320.437927,289.022888 318.317291,288.049561 
	C318.003723,286.921173 318.009857,285.840149 318.036377,284.352356 
	C320.817810,283.643646 322.784912,284.653198 323.859467,287.698883 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M426.217743,259.735596 
	C423.257874,259.653931 422.786377,258.506989 424.909485,256.639648 
	C426.632965,255.123795 428.467773,253.734497 430.537262,252.125824 
	C431.003357,251.794952 431.185364,251.627289 431.642395,251.708923 
	C430.856354,255.026871 429.490631,257.873230 426.217743,259.735596 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M408.735046,242.004181 
	C408.704285,243.882141 412.161469,244.684662 410.243317,247.264832 
	C409.517792,247.324783 409.006683,247.214203 408.624573,246.930130 
	C406.551086,245.388489 404.619995,243.800659 408.735046,242.004181 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M433.630341,240.006989 
	C434.268463,240.207199 434.538330,240.419357 434.927521,240.863007 
	C435.366730,241.493973 435.483673,241.950317 435.141449,242.687775 
	C433.385620,244.255966 432.171417,243.895615 431.365784,242.237000 
	C430.381683,240.211029 431.820038,240.070496 433.630341,240.006989 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M390.494263,251.053314 
	C389.386078,252.617645 387.433472,251.687241 385.291992,251.999039 
	C386.369324,250.320480 388.344269,251.286102 390.494263,251.053314 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M351.046234,247.047241 
	C350.757812,247.567352 350.424042,248.041809 350.090271,248.516281 
	C349.887207,248.339523 349.684143,248.162766 349.481079,247.986008 
	C349.780304,247.674500 350.079498,247.362991 350.644012,246.981354 
	C350.909302,246.911240 351.000885,247.001602 351.046234,247.047241 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M399.631348,248.076538 
	C399.365387,247.814957 399.257324,247.428879 399.203583,247.035385 
	C399.194550,246.969025 399.419830,246.870667 399.536865,246.787109 
	C399.627075,247.174606 399.717285,247.562088 399.631348,248.076538 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M408.735016,239.983932 
	C409.296844,240.343887 409.637360,240.846680 409.980225,241.685333 
	C409.811615,242.033218 409.640656,242.045258 409.213745,242.030792 
	C408.809784,241.378433 408.661743,240.752594 408.735016,239.983932 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M431.948120,251.591339 
	C431.735535,251.963516 431.553650,251.968826 431.096558,251.968353 
	C431.028412,251.101730 431.235504,250.240875 431.731201,249.196609 
	C432.006134,249.750305 431.992462,250.487396 431.948120,251.591339 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M431.985840,248.986435 
	C432.108459,248.237457 432.241791,247.498688 432.375122,246.759918 
	C433.361908,247.329941 432.887939,248.001999 432.264069,248.840332 
	C432.019806,249.013214 431.996490,248.996658 431.985840,248.986435 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M435.264984,242.878052 
	C434.898956,242.514801 434.943024,242.109100 435.016968,241.398941 
	C435.451141,241.435104 435.855438,241.775696 436.366943,242.373932 
	C436.207794,242.699570 435.941437,242.767578 435.264984,242.878052 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M368.798218,260.131134 
	C368.983521,260.237946 368.980652,260.425934 368.961914,260.896942 
	C368.834045,260.857422 368.722076,260.534851 368.798218,260.131134 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M425.994995,260.003784 
	C426.103210,260.135864 426.216431,260.264160 426.329651,260.392426 
	C426.254242,260.344055 426.178833,260.295685 426.055542,260.118652 
	C426.007629,259.989960 426.000000,260.000000 425.994995,260.003784 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M410.890381,282.792053 
	C410.970520,283.042816 410.544006,283.062805 410.332611,283.083557 
	C410.098938,282.911713 410.076660,282.719116 410.067017,282.235596 
	C410.370636,282.085358 410.614166,282.284241 410.890381,282.792053 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M385.461304,227.437714 
	C385.526276,227.396683 385.396301,227.478745 385.461304,227.437714 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M598.665527,234.857513 
	C598.134827,238.188416 600.172607,239.947922 602.729492,241.800201 
	C603.321899,242.290375 603.526245,242.629440 603.652100,243.329193 
	C603.574646,244.246979 603.372009,244.827576 603.073608,245.706238 
	C602.876892,246.192673 602.784729,246.383377 602.499268,246.832336 
	C602.125122,247.212051 601.944336,247.333511 601.451233,247.560226 
	C598.851746,248.109833 596.678040,247.986588 594.574768,246.135529 
	C593.633301,244.737793 593.384888,243.389557 592.742004,242.226807 
	C590.775879,238.670700 587.674866,237.951172 584.317688,240.229156 
	C581.290833,242.282990 575.641724,242.689651 573.075684,240.423370 
	C571.052429,238.636383 568.972778,238.543472 566.321777,238.466766 
	C565.838745,238.161957 565.680786,237.990707 565.390564,237.497986 
	C565.580261,233.351776 568.563599,230.683807 569.625000,227.142548 
	C570.106201,225.536987 572.027832,225.843094 573.756165,225.853867 
	C577.926392,226.716187 581.383728,226.140762 584.010864,222.500732 
	C585.431030,221.743973 586.605286,218.779404 588.059998,220.993622 
	C589.075012,222.538651 589.603577,226.062698 588.695435,226.892441 
	C586.225708,229.148987 587.607422,231.185043 588.624390,232.613434 
	C590.112366,234.703491 592.770447,233.633560 594.765625,233.044113 
	C595.713562,232.764069 596.437195,232.234436 597.708740,232.866302 
	C598.000366,232.992416 598.010498,233.452499 598.020630,233.682373 
	C597.863831,234.318863 598.016174,234.595932 598.665527,234.857513 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M583.333435,190.176880 
	C583.058533,189.060486 582.905151,188.226242 582.920776,187.214081 
	C583.460693,186.630615 583.912781,186.494354 584.678345,186.925903 
	C586.312500,188.863037 586.406738,190.422546 584.334229,192.041443 
	C583.843750,191.628860 583.649414,191.043945 583.333435,190.176880 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M594.874756,203.295593 
	C595.489136,202.740707 595.978149,202.478989 596.721130,202.106171 
	C596.817749,203.367249 597.549072,205.028427 595.365967,205.929688 
	C594.843628,206.000473 594.674438,205.998306 594.251465,205.992889 
	C594.248291,205.189346 594.498840,204.389053 594.874756,203.295593 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M584.929810,186.865540 
	C584.506592,187.169403 584.102600,187.114212 583.394165,187.047577 
	C582.786865,186.246475 582.483948,185.456833 582.064209,184.368942 
	C581.694824,183.571381 581.799866,183.164230 582.628662,182.920593 
	C583.812561,184.071167 585.301636,184.776871 584.929810,186.865540 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M582.946777,182.672180 
	C582.729065,183.201233 582.462952,183.410431 582.072144,183.845154 
	C581.648010,183.274521 581.348633,182.478363 580.999634,181.354401 
	C581.871887,181.068085 582.650208,181.320618 582.946777,182.672180 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M586.108154,175.151398 
	C586.036621,174.929886 586.523804,174.950882 586.766907,174.970978 
	C586.991516,175.160797 586.972961,175.330505 586.901611,175.750824 
	C586.561340,175.860458 586.338318,175.650955 586.108154,175.151398 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M569.095398,188.059097 
	C571.120544,188.252350 572.086365,189.324905 571.990051,191.331223 
	C571.956787,192.023453 571.813965,192.656601 570.900513,192.434219 
	C569.377441,192.063461 568.173645,191.116928 567.493286,189.784866 
	C567.146790,189.106552 568.230042,188.956055 568.894897,188.376114 
	C569.061523,188.118057 569.061646,188.033615 569.095398,188.059097 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M575.016541,196.982880 
	C574.801453,196.894089 574.575928,196.816315 574.350342,196.738525 
	C574.528931,196.699875 574.707581,196.661209 574.936768,196.819305 
	C574.987366,197.016068 575.006042,196.993896 575.016541,196.982880 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M574.035645,225.656235 
	C572.830261,225.877106 574.008057,228.618515 572.489990,227.572296 
	C571.132812,226.636978 570.274353,226.490891 570.184387,228.120468 
	C569.997864,231.497696 567.846436,233.753235 566.075317,236.646103 
	C561.485840,238.939819 556.572632,237.973175 551.901184,238.862061 
	C549.882812,239.246109 547.946228,239.069809 546.460510,236.854004 
	C546.237122,236.331589 546.172668,236.115189 546.065796,235.569244 
	C546.350891,233.057205 544.254822,232.059235 543.785278,230.067627 
	C546.347168,225.409012 545.388367,221.855133 541.465027,218.834091 
	C540.400696,218.014481 538.565552,216.957687 539.654419,215.262772 
	C540.392212,214.114227 541.886353,213.034973 543.206787,212.781311 
	C544.908386,212.454391 545.689026,214.067627 545.965271,215.608627 
	C546.183838,216.827744 546.440247,218.286636 547.971924,217.618912 
	C549.484680,216.959396 547.934692,215.589233 548.029541,214.166870 
	C549.201477,211.633316 550.454163,209.736465 553.151794,212.788788 
	C553.072327,215.021103 553.086670,216.889984 554.802490,218.680817 
	C555.499817,219.249771 555.974792,219.559311 556.728638,219.920685 
	C559.255188,220.053619 561.149658,221.414810 563.665039,221.801926 
	C565.048462,222.025864 565.669678,222.614319 566.226013,223.545837 
	C566.837769,224.570038 567.473206,226.572922 568.939148,225.650360 
	C570.595520,224.607895 568.826721,223.096115 568.306946,221.515701 
	C570.189880,219.230026 571.335327,220.830582 572.798096,222.573944 
	C573.393555,223.663132 573.701477,224.488998 574.035645,225.656235 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M573.072998,222.485199 
	C571.272949,223.193161 570.692505,220.584198 568.489624,221.149200 
	C568.263611,219.572464 567.072327,217.251404 569.027039,216.460052 
	C571.399536,215.499588 571.287720,218.502289 572.721558,219.794189 
	C573.015015,220.719559 573.037720,221.426346 573.072998,222.485199 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M657.508545,819.885864 
	C658.567139,818.806274 659.658020,818.938477 660.779175,819.197571 
	C659.318115,821.319824 659.318115,821.319824 657.508545,819.885864 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M681.455811,831.526489 
	C681.422913,831.472534 681.488770,831.580444 681.455811,831.526489 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M808.242004,642.662354 
	C807.950806,642.903381 807.577759,642.912415 807.391235,642.917969 
	C806.703857,642.629089 806.154602,642.494446 805.320435,642.321533 
	C804.613464,642.114563 804.169434,642.018188 803.389771,641.939331 
	C800.892700,638.692749 802.016541,636.563171 805.702271,635.040894 
	C808.214478,635.998230 807.902527,638.380981 808.788818,640.595459 
	C808.922729,641.500122 808.806885,641.979797 808.242004,642.662354 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M813.188721,646.015625 
	C811.771301,645.460327 810.695801,644.784973 809.296631,644.052917 
	C808.382263,643.192017 808.482544,642.330383 809.198364,641.541382 
	C811.822632,641.134949 814.010132,640.640991 816.550476,640.009216 
	C817.121643,639.854065 817.340088,639.836731 817.873840,639.912476 
	C817.786072,641.059448 817.383118,642.113159 817.057617,643.510010 
	C816.413330,645.380432 814.564453,644.918823 813.188721,646.015625 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M811.640503,633.746460 
	C811.781738,634.544006 811.480713,634.848938 810.883118,634.788025 
	C810.755127,634.774902 810.645508,634.581116 810.527466,634.470581 
	C810.851501,634.163635 811.175537,633.856750 811.640503,633.746460 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M809.472961,641.436279 
	C809.417419,642.202209 809.199768,642.775452 808.977539,643.672485 
	C808.707642,643.785706 808.442383,643.575256 808.063904,643.134033 
	C808.074402,642.425354 808.198120,641.947327 808.601196,641.225830 
	C809.023987,641.069397 809.167419,641.156555 809.472961,641.436279 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M693.319458,252.177414 
	C692.557617,251.959900 691.779053,250.836472 691.510681,250.947372 
	C689.518921,251.770813 690.476624,253.784698 690.043518,256.146423 
	C688.757202,253.500839 688.670776,251.084198 686.537842,249.443481 
	C683.858582,246.985031 683.443176,243.252350 680.164673,241.253677 
	C679.942261,240.992157 680.000427,241.001862 679.980469,241.023590 
	C679.957764,236.375809 676.501892,233.049973 675.097290,228.564453 
	C675.137756,227.933243 675.306152,227.613724 675.711609,227.156586 
	C676.796509,226.886719 677.606323,226.226486 678.766235,226.906738 
	C681.046509,229.018066 683.068176,231.056992 685.477051,233.117554 
	C685.884644,234.174225 685.904968,235.209290 685.948364,236.634186 
	C685.681763,237.566589 685.378967,238.106064 685.726318,239.017685 
	C686.440979,239.883942 686.904053,240.594666 688.066101,240.851196 
	C691.708862,243.761276 691.770386,248.203003 693.319458,252.177414 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M674.790833,228.467163 
	C677.829407,230.371490 678.558716,234.009399 680.869446,236.524521 
	C682.352600,238.138901 680.076599,239.050491 680.026306,240.722382 
	C676.534912,238.038162 676.139099,233.359268 673.658447,229.439850 
	C673.917969,228.958771 674.265137,228.820160 674.790833,228.467163 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M658.433838,184.666336 
	C658.370300,184.572021 658.605042,184.517532 658.605042,184.517532 
	C658.605042,184.517532 658.497314,184.760651 658.433838,184.666336 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M679.136353,226.938690 
	C679.065430,226.784943 679.215698,226.569504 679.591675,226.307388 
	C679.672913,226.475082 679.519592,226.680511 679.136353,226.938690 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M613.369934,290.670166 
	C610.275696,289.895294 607.500305,288.984283 604.385254,287.926025 
	C604.480469,284.749756 606.105347,282.308167 607.957520,279.623047 
	C608.551147,278.331268 608.833069,277.221832 609.908081,276.211304 
	C610.608765,275.809631 611.433899,275.581970 611.422302,275.410980 
	C610.863831,267.158295 616.860962,261.570892 619.803223,254.400940 
	C619.977478,253.878647 620.122131,253.696945 620.571045,253.343018 
	C621.098267,253.100540 621.321167,253.030304 621.890625,252.903809 
	C622.471008,252.835999 622.704895,252.824463 623.289673,252.833221 
	C627.756287,253.562500 626.442261,257.023987 627.046265,259.863922 
	C627.427429,264.477905 622.821838,263.972229 621.127930,266.632477 
	C621.907959,269.579010 621.990173,273.439148 624.349609,274.250519 
	C626.485596,274.985046 627.317810,270.369934 630.368774,269.593658 
	C632.751648,269.226349 633.632751,267.097809 635.770996,266.405121 
	C636.311890,266.400970 636.529968,266.436554 637.050537,266.616699 
	C638.060547,267.236816 638.585693,268.040253 639.897522,267.935303 
	C641.057373,267.651520 641.890564,267.392822 643.094604,267.259735 
	C645.307678,267.465027 647.034668,268.109955 648.941772,269.260925 
	C650.976929,272.208069 653.512268,273.392761 657.064026,273.355164 
	C660.140869,274.835236 661.514404,276.973999 661.123291,280.456116 
	C661.008423,281.061218 660.944214,281.298767 660.741333,281.876801 
	C660.488281,282.432495 660.374023,282.647705 660.068115,283.169739 
	C659.647217,287.084808 659.912170,290.697021 659.925293,294.303314 
	C659.937988,297.811340 658.930359,300.099762 654.941406,300.780090 
	C648.982239,298.875977 644.984375,295.443939 643.638611,289.426147 
	C643.316162,287.983917 642.714294,286.635315 641.513672,285.226196 
	C641.117981,284.798126 640.970276,284.616791 640.666260,284.123596 
	C640.477661,283.584015 640.445312,283.356293 640.491455,282.796143 
	C641.029175,281.331268 642.769592,281.619720 643.129089,279.626617 
	C642.239136,281.188721 641.030334,281.543030 639.840454,282.463013 
	C637.614685,279.716614 635.196228,280.495056 632.633057,281.446533 
	C631.215698,281.972717 629.697388,281.682434 628.220703,281.280334 
	C626.231018,280.738495 624.533203,281.241425 623.635925,283.214355 
	C621.672913,287.530853 617.584473,288.885132 613.369934,290.670166 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M584.359253,282.681519 
	C577.547302,281.751282 571.048279,280.674927 564.243896,279.353638 
	C563.739441,278.610077 563.786804,277.861389 564.180359,277.803192 
	C569.268188,277.050537 570.805908,273.044952 572.607666,268.766327 
	C574.623718,267.267029 576.271240,265.863312 578.500854,264.751038 
	C580.906311,266.007111 582.132996,268.293335 585.505249,267.688416 
	C588.882507,267.082611 587.655151,263.699066 589.963745,262.408783 
	C590.371338,261.991486 590.555298,261.845123 591.076172,261.594849 
	C591.991760,261.815979 592.540955,262.343231 593.153931,262.432373 
	C596.481201,262.916138 598.809204,263.418304 600.317505,267.669342 
	C601.768921,271.760315 599.750305,273.762115 598.321045,276.782410 
	C597.933655,277.129486 597.789246,277.266327 597.383911,277.499634 
	C596.716675,277.609741 596.535461,277.851379 596.378052,278.503845 
	C592.875732,280.494965 592.962341,277.695312 592.410278,275.468018 
	C592.534302,274.974823 592.619629,274.787231 592.448547,274.788757 
	C591.421936,275.415344 590.631714,275.226532 589.838989,275.059845 
	C586.999512,274.462646 585.802185,275.495331 586.446899,278.432281 
	C586.835083,280.200775 587.375610,282.082855 584.359253,282.681519 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M626.280518,260.300323 
	C624.331421,258.368011 627.705933,254.661652 623.244629,254.009766 
	C621.022461,251.936035 621.017456,249.343384 620.917236,246.384979 
	C621.138550,245.504562 621.397217,245.005753 621.794556,244.262131 
	C625.202026,242.187531 624.172180,246.385696 626.049622,246.856964 
	C627.210205,245.054306 627.988708,242.516296 631.331909,242.996521 
	C632.118286,243.210403 632.516357,243.441895 633.044983,244.047638 
	C633.736328,244.689941 632.253784,245.007492 633.551025,245.327927 
	C636.225342,245.650452 638.575500,245.942902 640.785645,247.710373 
	C642.446594,249.174927 642.775635,250.939117 643.598999,252.800262 
	C645.141235,256.032959 643.214661,258.026398 641.290527,260.368835 
	C636.170166,261.970032 631.361450,263.345398 626.280518,260.300323 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M632.393860,243.765472 
	C630.331116,244.140335 628.113586,243.474289 628.039246,246.327499 
	C627.987610,248.307373 626.978394,249.755157 625.125000,249.511414 
	C623.204224,249.258789 623.534241,247.432800 623.832886,245.922394 
	C624.018494,244.983810 623.518921,244.368103 622.270142,244.056915 
	C621.561157,243.190506 621.259705,242.362137 621.983032,241.267258 
	C622.512756,240.573853 622.728943,240.070251 622.353149,239.205429 
	C621.551453,236.581589 620.909119,234.201797 620.275696,231.464340 
	C620.929871,230.204041 621.877991,230.021866 623.259155,230.037231 
	C624.552979,230.218460 625.462891,230.487198 626.751709,230.598083 
	C628.488464,230.639008 629.889832,230.430084 631.582214,230.955231 
	C632.582397,232.494064 633.261108,233.897232 633.994141,235.632019 
	C636.064087,238.905853 635.120178,241.340225 632.393860,243.765472 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M603.009033,244.245819 
	C603.010315,243.544693 603.011169,243.108109 603.012146,242.344452 
	C605.115234,241.795319 604.973694,240.220703 604.993713,238.364838 
	C606.279053,238.808151 607.566528,238.064774 608.855652,238.042633 
	C613.043884,237.970657 614.162659,236.015427 612.576355,232.268936 
	C612.257141,231.515076 611.844177,230.767075 612.399597,229.940292 
	C618.206604,228.696701 617.983093,233.315094 619.192688,236.760086 
	C620.736816,241.157822 617.332336,241.296539 614.809021,242.183197 
	C612.808655,242.886078 612.178101,244.210709 611.862549,246.265503 
	C611.576721,248.126343 611.546143,250.247543 609.732178,251.909973 
	C607.692627,250.297791 605.881897,248.904724 602.959106,249.961380 
	C602.145996,249.258469 601.867371,248.627777 601.915955,247.557343 
	C602.018494,247.217789 602.152466,247.054977 602.232910,246.986771 
	C602.313354,246.918564 602.600952,246.512344 602.720642,246.289093 
	C602.880249,245.898224 602.920166,245.730637 602.979065,245.301239 
	C603.001526,244.863098 603.005066,244.686752 603.009033,244.245819 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M592.705139,274.128174 
	C593.932068,275.271912 592.374329,278.421021 595.947449,277.301331 
	C596.496704,276.947449 596.948242,276.950287 597.738892,276.972900 
	C597.846252,277.861206 597.614502,278.729767 597.099609,279.792908 
	C596.232544,280.809601 595.367981,281.414917 594.960083,282.681519 
	C594.955811,283.452820 594.960083,283.922241 594.972534,284.743622 
	C591.591614,284.399536 588.202515,283.703430 584.429810,282.917480 
	C585.155396,281.860077 586.780396,280.864441 585.443298,279.219421 
	C583.827026,277.230988 584.190796,275.530823 585.723572,273.969940 
	C587.548035,272.112000 589.305420,273.148712 591.013733,274.402252 
	C591.280945,274.598297 591.914185,274.295441 592.705139,274.128174 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M591.482056,261.329529 
	C591.413086,261.490936 591.121826,261.569916 590.996521,261.653992 
	C590.076416,263.103485 588.836121,263.931610 587.130005,264.640503 
	C586.182800,264.743561 585.551453,264.688263 584.608582,264.722595 
	C583.876892,264.785828 583.456055,264.793335 582.715698,264.727173 
	C582.192139,264.607971 581.987427,264.535278 581.501587,264.293213 
	C580.508484,263.574707 579.921082,262.915558 579.332886,261.824036 
	C578.930664,260.599396 578.951477,259.684906 579.271729,258.435455 
	C579.608948,257.688263 579.878418,257.306946 580.460693,256.733276 
	C580.924561,256.391449 581.123535,256.275726 581.636353,256.024200 
	C583.965759,255.553253 585.316895,253.643616 587.780396,253.624481 
	C590.239746,255.291138 591.258240,252.947418 593.134644,252.330597 
	C596.570923,252.974747 595.623535,255.109589 595.070374,257.408264 
	C594.408936,258.974091 593.436157,259.768677 592.066772,260.685059 
	C591.741699,260.873383 591.550964,261.168091 591.482056,261.329529 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M631.809082,230.768646 
	C630.506714,231.787659 629.048767,232.148605 627.094849,231.847351 
	C626.131958,231.112503 625.568237,230.390015 624.174622,230.949768 
	C622.896790,231.051300 621.941101,231.050781 620.635010,231.078476 
	C620.214111,230.334457 620.143494,229.562256 620.081421,228.402634 
	C622.420227,225.878922 623.390808,223.515015 621.776978,220.419998 
	C621.446716,219.786758 619.596924,218.016891 622.700806,217.916672 
	C622.986023,217.981293 622.999329,217.998322 622.992798,217.992676 
	C625.388611,220.360489 628.439575,222.161758 629.803650,225.882050 
	C630.528015,227.613708 631.121277,229.030060 631.809082,230.768646 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M602.566040,248.811447 
	C605.460632,248.648849 609.182312,244.881973 609.304321,251.659103 
	C609.313782,252.205643 609.228149,252.383041 609.035889,252.836060 
	C608.747375,253.187561 608.565369,253.263428 608.294312,253.621399 
	C608.192932,254.096405 608.180420,254.289322 608.063232,254.752716 
	C606.725586,256.278564 606.091309,258.156952 603.750732,258.729492 
	C602.141479,258.706787 601.217102,258.125061 600.272461,256.804871 
	C599.582886,253.491302 598.293701,250.351562 602.566040,248.811447 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M600.681213,256.941162 
	C601.684509,257.305939 602.352600,257.653351 603.299805,258.051666 
	C605.024231,258.396210 606.329834,258.885834 606.926697,260.799896 
	C606.159119,262.426697 606.489563,265.058350 603.396667,262.924377 
	C600.318298,260.800385 596.528931,259.739136 592.164673,260.917969 
	C591.811646,259.722076 592.447327,258.906830 593.591125,258.070129 
	C595.833984,256.309937 598.179810,257.205200 600.681213,256.941162 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M620.626831,246.246399 
	C622.469971,248.146317 622.718323,250.671082 622.933594,253.605972 
	C622.937256,254.005630 622.514099,253.998581 622.302490,254.001373 
	C622.090942,254.004181 621.749817,253.663971 621.749817,253.663971 
	C621.749817,253.663971 621.268372,253.679779 621.089844,253.841827 
	C620.731934,254.008484 620.552490,254.013062 620.102966,254.038986 
	C617.786194,253.588394 617.614197,252.258575 618.746765,250.906891 
	C619.869080,249.567490 619.759460,247.927475 620.626831,246.246399 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M607.275146,260.918701 
	C605.976929,260.317413 604.970154,259.484283 603.771118,258.376892 
	C605.205811,257.592621 605.336182,255.232910 607.617737,255.067017 
	C608.047241,255.484711 608.135803,255.946259 608.303833,256.751343 
	C608.383240,257.094879 608.380737,257.489655 608.410767,257.684753 
	C608.149353,258.815552 607.857971,259.751221 607.275146,260.918701 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M595.242310,282.828186 
	C594.453308,282.701355 593.509583,282.382446 593.529358,282.143250 
	C593.657654,280.592743 595.142517,280.592194 596.502930,280.040039 
	C597.050293,281.198792 596.745667,282.152802 595.242310,282.828186 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M609.838989,276.015442 
	C610.470581,277.419891 609.443970,278.117798 608.308716,279.105652 
	C608.341675,278.125977 606.514832,275.971161 609.838989,276.015442 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M622.138062,239.302490 
	C623.568359,239.334976 624.368713,239.839981 622.364746,240.971863 
	C622.085449,240.561661 622.083923,240.102631 622.138062,239.302490 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M608.371094,253.818359 
	C607.155640,253.205643 608.089905,253.246643 608.717957,253.103271 
	C608.863586,253.360001 608.732666,253.567184 608.371094,253.818359 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M608.533569,257.795227 
	C608.440674,257.879883 608.268494,257.496674 608.325867,257.295776 
	C608.642273,257.234314 608.723328,257.439514 608.533569,257.795227 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M598.862671,234.707214 
	C597.947937,234.961349 597.415894,237.055847 596.269714,235.744324 
	C595.769165,235.171524 596.500671,234.273956 597.729980,233.983673 
	C598.337463,234.028305 598.586975,234.219086 598.862671,234.707214 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M601.638367,204.980438 
	C600.904419,205.922897 600.401672,205.588623 600.213989,204.847198 
	C600.135925,204.538742 600.523132,204.112549 600.698669,203.739899 
	C601.023865,204.062820 601.349121,204.385742 601.638367,204.980438 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M649.017212,270.193481 
	C646.975342,269.382660 645.274109,268.815491 643.264526,268.101685 
	C641.945618,266.735962 642.788269,265.827637 643.704285,264.640076 
	C644.117065,264.244019 644.295288,264.100098 644.779297,263.805908 
	C647.114746,263.338623 648.300232,262.571869 646.664551,260.088440 
	C646.387451,259.343689 646.367126,258.894196 646.579224,258.129730 
	C649.181519,255.879776 647.919434,252.972519 648.791626,250.299866 
	C646.479614,247.324615 650.463074,245.019852 649.680115,242.202515 
	C650.843750,242.308228 651.405457,242.486740 652.208618,241.279541 
	C653.850220,238.812347 656.300598,237.639313 659.716309,239.017059 
	C661.980652,241.210098 666.319275,241.011490 666.018921,245.601074 
	C665.681091,249.064590 663.750244,250.701767 660.593262,251.613800 
	C658.731873,252.319977 658.665466,254.099472 657.344238,255.273224 
	C653.161255,254.955948 654.834290,258.395691 653.739746,260.303833 
	C651.569641,263.067566 653.700195,265.819092 652.905518,268.737488 
	C652.027771,270.545624 650.887878,270.998047 649.017212,270.193481 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M665.449585,246.322495 
	C664.698853,243.971481 665.139954,241.128998 661.163269,242.055099 
	C659.265991,242.496933 660.391052,240.541931 660.058777,239.394897 
	C663.144470,238.369156 662.454895,235.391769 663.742249,233.218491 
	C665.786499,232.679977 666.062744,233.989929 666.352783,235.587982 
	C666.948364,237.958786 665.686646,241.856186 670.599609,240.055252 
	C673.053345,240.725052 673.639099,242.304504 673.474487,244.625916 
	C671.024048,246.514343 668.075439,244.910812 665.449585,246.322495 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M666.636230,236.018951 
	C665.713379,235.076828 665.138611,234.192749 664.287842,233.149216 
	C663.910095,232.185791 663.808289,231.381805 663.628540,230.173462 
	C665.955994,230.219101 669.144104,227.521317 670.667053,231.431961 
	C671.274719,232.992050 673.326721,234.528030 671.948730,236.225266 
	C670.607422,237.877350 668.645386,236.677628 666.636230,236.018951 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M640.152466,224.552521 
	C639.262024,224.948074 638.959412,226.590073 638.124939,225.734253 
	C637.531982,225.126236 636.553711,223.856064 637.585327,223.164276 
	C639.328430,221.995407 638.682617,220.154724 639.526245,218.442505 
	C639.883789,220.148178 640.074829,222.140915 640.152466,224.552521 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M647.268311,238.865097 
	C646.068665,238.512833 645.834045,237.651459 645.906372,236.323456 
	C647.280212,236.435349 648.049316,237.202164 647.268311,238.865097 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M640.940186,227.609756 
	C640.006165,227.424332 640.103577,226.493362 640.022095,225.294052 
	C641.080017,225.425888 640.843933,226.414093 640.940186,227.609756 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M649.632507,238.094666 
	C649.368774,237.823135 649.260925,237.412552 649.212158,236.995071 
	C649.202820,236.915619 649.427124,236.808884 649.543701,236.714752 
	C649.632141,237.127579 649.720520,237.540390 649.632507,238.094666 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M632.248962,244.114075 
	C632.404846,241.511520 634.885925,239.597580 634.027832,236.366165 
	C635.842468,236.002731 636.626404,234.797348 637.324402,233.399826 
	C637.729248,232.589172 637.959656,231.357193 639.351562,231.824631 
	C640.381348,232.170441 640.749207,232.994980 641.037292,234.045135 
	C641.893738,237.166275 641.885193,240.567505 643.961975,243.701447 
	C643.118347,244.931412 642.015137,244.316467 640.984375,244.817078 
	C640.964478,245.816589 640.994812,246.523178 641.042725,247.581482 
	C638.600525,247.386078 636.140625,246.838943 633.337036,246.144485 
	C632.879944,245.835693 632.766418,245.674225 632.815491,245.265320 
	C632.895752,244.695114 632.723145,244.431503 632.248962,244.114075 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M644.774536,264.798645 
	C644.340088,265.737396 643.718323,266.540070 643.026367,267.648865 
	C642.192566,268.278809 641.428955,268.602600 640.002930,268.872650 
	C639.152771,268.669159 638.965210,268.519409 638.607056,268.054443 
	C638.393555,267.505890 638.350708,267.272552 638.331299,266.686432 
	C639.079895,264.411163 639.528931,262.393707 640.538940,260.216431 
	C641.727539,257.850708 642.736145,255.775848 643.793152,253.361328 
	C645.886536,253.095932 647.898132,253.110565 648.697754,250.243225 
	C649.201721,251.899475 648.625366,253.823471 650.001587,255.591476 
	C652.110718,258.301147 649.585327,257.878510 647.752075,258.088898 
	C646.538086,258.432861 646.179321,258.915588 647.197754,259.836975 
	C648.883179,260.004517 650.453125,260.249634 649.307373,262.109955 
	C648.572388,263.303345 648.584290,266.593262 645.342896,264.263062 
	C645.102478,264.124481 644.661926,264.216614 644.661926,264.216614 
	C644.661926,264.216614 644.587341,264.662537 644.774536,264.798645 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M630.059509,225.909653 
	C627.451294,223.846283 624.701294,221.741837 622.984741,218.334900 
	C624.687927,217.962753 626.389526,217.938461 628.512268,217.872879 
	C630.446838,220.183594 633.356079,219.585510 635.424744,220.889435 
	C637.441406,222.160583 635.390503,222.640778 635.130005,223.750381 
	C634.814148,224.198318 634.630188,224.370529 634.179565,224.274994 
	C631.923889,222.822525 631.102661,224.334381 630.059509,225.909653 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M641.273315,245.095673 
	C640.583862,244.895126 640.233582,244.680237 639.883240,244.465347 
	C640.891235,243.385681 642.158447,244.206100 643.644531,244.041473 
	C644.290710,244.523605 644.578125,244.965515 644.815308,245.827667 
	C643.714172,245.859055 642.663330,245.470200 641.273315,245.095673 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M633.927979,224.353867 
	C634.093750,224.004837 634.274719,224.002380 634.726929,224.013016 
	C635.260376,225.140625 636.947083,226.525574 635.300293,227.257187 
	C634.364807,227.672836 633.952515,225.850586 633.927979,224.353867 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M645.073669,245.939575 
	C645.366211,246.044662 645.554504,246.251083 645.742737,246.457520 
	C645.504639,246.503525 645.266602,246.549545 644.896790,246.421738 
	C644.765076,246.247925 644.969360,246.040909 645.073669,245.939575 
z"
    />
    <path
      fill="#E7E7E6"
      opacity="1.000000"
      stroke="none"
      d="
M672.768188,245.335510 
	C672.868347,243.348022 672.380859,241.908768 671.082275,240.429993 
	C671.403198,237.556198 673.209351,237.412506 674.295349,239.119507 
	C676.754028,242.984161 678.700134,247.177521 680.777222,251.276459 
	C680.916077,251.550522 680.405701,252.153519 680.046997,252.949585 
	C679.159912,252.858063 678.423157,252.420486 677.361816,251.973236 
	C673.026550,251.667328 673.311401,248.346359 672.768188,245.335510 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M679.947021,241.372192 
	C684.832458,241.291397 684.768311,245.672287 686.270264,248.984528 
	C685.872986,249.985077 685.519714,250.727768 684.990234,251.837738 
	C683.193237,248.720764 681.572510,245.236496 679.947021,241.372192 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M669.303650,810.938965 
	C669.286926,810.710022 669.567993,810.393799 670.172852,810.043457 
	C670.198181,810.290161 669.899780,810.570923 669.303650,810.938965 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M711.002563,752.003662 
	C710.469055,752.293884 709.938110,752.592163 709.384583,752.839783 
	C709.349365,752.855347 709.138123,752.477173 709.008789,752.282104 
	C709.476501,752.187927 709.944153,752.093750 710.701965,751.995789 
	C710.992065,751.992004 710.999939,751.999939 711.002563,752.003662 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M699.988525,756.261475 
	C700.090454,756.417053 700.039551,756.574341 699.988647,756.731628 
	C699.937683,756.575439 699.886719,756.419312 699.988525,756.261475 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M709.848999,758.061523 
	C709.924927,758.129456 709.739624,758.996277 709.848999,758.061523 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M709.957947,757.947388 
	C710.030273,757.591492 710.221436,757.339539 710.706909,757.041443 
	C710.921509,756.934814 710.995422,757.000793 711.029785,757.038574 
	C710.959229,757.391113 710.772766,757.646057 710.293945,757.955322 
	C710.083313,758.069336 709.997009,757.990784 709.957947,757.947388 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M681.574097,738.478943 
	C681.101074,738.554443 680.797668,738.377625 680.257446,738.087402 
	C679.183899,737.033813 677.923889,736.607422 677.053528,735.301147 
	C676.966187,735.000427 677.067871,734.559265 677.163696,734.354248 
	C679.623291,731.614319 682.909485,732.937073 686.069458,732.056885 
	C688.171997,731.672913 689.936218,731.626404 691.966125,731.020508 
	C693.948792,730.818115 695.276184,729.822144 697.119385,729.325317 
	C698.308594,729.325012 699.115479,729.611450 700.230225,729.864502 
	C701.096375,729.987366 701.657471,729.962097 702.413635,729.466309 
	C702.985901,729.011963 703.332031,728.762939 703.996338,728.367310 
	C704.528870,728.126831 704.754089,728.047974 705.336670,727.890137 
	C706.448181,727.698608 707.206909,727.680542 708.367981,727.735474 
	C711.041504,728.192261 712.151245,729.510376 711.802612,732.266235 
	C711.408875,733.186523 711.008240,733.567749 710.057922,733.838867 
	C702.800781,734.724426 695.953369,735.496277 689.139648,736.497559 
	C686.649963,736.863342 683.422668,734.374146 681.574097,738.478943 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M709.760376,734.205444 
	C709.886353,733.581482 710.137817,733.301147 710.637573,732.882935 
	C712.926331,731.784058 714.772888,732.535217 715.568542,734.259827 
	C716.257080,735.752319 717.520508,735.778748 718.741821,736.737976 
	C719.249207,738.086853 719.507874,739.195862 719.921631,740.616333 
	C718.860107,742.183411 717.643555,743.438965 716.147827,744.881104 
	C711.132202,744.308289 709.671143,741.975098 711.443298,738.077698 
	C712.218262,736.373230 709.024719,736.340088 709.760376,734.205444 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M692.015747,741.677490 
	C691.967285,741.179260 692.054199,741.147278 692.015747,741.677490 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M681.572632,740.001709 
	C681.508240,740.416199 681.132141,740.761658 680.327881,741.004272 
	C679.920410,740.721252 679.940979,740.541199 679.975342,740.088745 
	C680.413086,739.855164 680.836975,739.893921 681.572632,740.001709 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M696.447998,740.284546 
	C696.985962,740.120728 697.458801,740.073792 697.234253,740.672485 
	C697.216187,740.720520 696.613647,740.549255 696.447998,740.284546 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M726.715576,747.151978 
	C725.501526,745.616272 722.980713,746.271362 722.852051,743.512146 
	C725.009338,743.099792 727.307922,742.313354 729.597412,742.338989 
	C731.256287,742.357422 733.472656,742.644592 734.327820,744.437500 
	C735.108459,746.074097 733.792114,747.695374 732.901367,748.930176 
	C729.831665,753.185669 728.917175,748.146240 726.715576,747.151978 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M698.532104,779.543762 
	C698.481323,779.572571 698.582947,779.514954 698.532104,779.543762 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M685.115479,787.374329 
	C686.739990,786.678101 688.424500,786.301758 690.477783,785.922363 
	C689.185547,787.243652 688.799438,792.641602 685.115479,787.374329 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M691.837036,783.767944 
	C690.896790,783.140930 689.391968,782.954224 689.212402,781.134155 
	C691.095764,780.728760 692.248413,781.402344 691.837036,783.767944 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M555.081055,218.613251 
	C552.484741,217.829971 550.718018,216.395050 553.028198,213.235809 
	C559.017029,216.437775 556.260803,209.878601 558.595703,208.891602 
	C559.502441,212.167145 564.214172,208.281311 565.307861,212.426819 
	C565.587585,213.487228 567.262817,214.185089 565.858154,215.563248 
	C564.798462,216.602905 563.001038,218.234665 562.141602,217.212814 
	C558.911682,213.372559 557.286499,216.943329 555.081055,218.613251 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M563.985352,221.500641 
	C561.596497,222.405914 558.946777,224.021805 557.255981,220.181915 
	C559.546936,219.035034 562.230591,217.219086 563.985352,221.500641 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M562.990906,206.006958 
	C563.102966,206.140610 563.223633,206.267502 563.344299,206.394394 
	C563.265686,206.345978 563.187012,206.297577 563.060608,206.116089 
	C563.012817,205.982986 562.999512,206.000198 562.990906,206.006958 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M745.706726,689.995361 
	C746.501953,693.087341 744.643311,697.591248 750.742676,696.912720 
	C750.904724,698.881042 749.205017,699.137512 748.082092,700.037415 
	C747.484741,700.516113 744.059692,700.349792 747.128113,702.520203 
	C747.670105,702.903503 746.617920,703.885071 746.128235,703.668701 
	C742.428101,702.033691 740.314514,704.473450 738.346558,706.920776 
	C736.376465,705.510254 738.246399,703.990417 737.612915,702.629456 
	C737.198364,701.738831 738.088684,700.204407 736.209656,700.102295 
	C734.745361,700.022766 733.991760,700.828735 734.068848,702.201904 
	C734.250854,705.445984 731.913391,705.875122 729.365479,706.644165 
	C723.327759,703.751038 716.835754,703.575745 710.260254,702.333496 
	C709.470093,698.995911 711.716980,697.444397 713.865845,697.412842 
	C717.500916,697.359436 718.760925,695.402222 719.839233,692.276611 
	C726.238953,694.440674 726.546936,689.123474 727.877197,685.736389 
	C728.924500,683.069885 729.208740,680.910400 731.644043,684.819092 
	C732.690491,686.498657 734.566711,686.130493 736.188477,685.759216 
	C738.284424,685.279297 740.345520,685.029480 742.721191,685.896606 
	C743.010925,686.471863 743.004639,686.910767 742.852173,687.988892 
	C742.412598,688.982483 742.087952,689.305542 741.458130,689.805237 
	C740.393738,690.566101 739.478394,690.937134 738.651001,691.701782 
	C740.306458,691.835266 741.465393,690.867981 743.104431,690.305298 
	C744.078979,689.950928 744.764709,689.872192 745.706726,689.995361 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M772.604858,705.946045 
	C772.733154,706.433105 772.479614,706.945312 772.109558,707.718140 
	C767.721619,710.537231 762.721008,711.100342 758.211060,713.011475 
	C756.437134,713.763245 754.731018,713.561340 753.315674,716.064209 
	C751.287659,719.650330 751.389038,715.257385 750.252808,714.213135 
	C757.416260,711.325378 764.819641,708.648254 772.604858,705.946045 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M751.378052,696.995361 
	C750.604675,693.694824 753.424194,693.921082 755.655945,693.063965 
	C755.691833,695.526917 754.645996,697.253967 751.378052,696.995361 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M774.184448,702.039246 
	C774.131409,701.692261 774.326843,701.286560 774.914429,701.873169 
	C774.820312,702.052673 774.638672,702.048523 774.184448,702.039246 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M729.031738,710.708679 
	C728.662598,710.815491 728.306213,710.675232 728.818542,710.090393 
	C728.984131,710.001892 729.022766,710.472656 729.031738,710.708679 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M750.553223,707.467346 
	C750.586853,707.522827 750.519531,707.411865 750.553223,707.467346 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M759.598511,724.259338 
	C759.445557,724.138245 759.423645,723.932373 759.401733,723.726501 
	C759.510986,723.875854 759.620239,724.025208 759.598511,724.259338 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M758.529358,714.539185 
	C758.479614,714.566833 758.579102,714.511536 758.529358,714.539185 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M719.781372,691.880432 
	C719.473083,695.012451 721.709167,700.175964 714.889832,698.262573 
	C712.680420,697.642700 711.628723,700.055420 710.141663,701.741821 
	C709.494690,702.318054 708.995361,702.577820 708.250732,702.980835 
	C704.578430,704.599121 703.699219,702.350586 703.059570,699.363892 
	C703.656189,695.582397 707.414062,691.557251 700.861328,689.489136 
	C699.218018,689.450623 698.292358,690.510071 696.760803,690.787598 
	C696.262756,690.804504 696.069336,690.784973 695.609497,690.669922 
	C694.452271,689.782776 694.239380,690.580811 693.768188,691.450806 
	C692.906006,692.660522 692.116882,693.456543 690.550171,693.667847 
	C689.486206,693.594666 688.809021,693.377563 687.801331,693.007690 
	C686.810608,692.546631 686.240479,692.109131 685.556519,691.264648 
	C684.312012,689.185303 685.468079,686.966431 684.107971,684.958923 
	C681.954651,685.550049 679.799438,687.554871 677.366760,685.255127 
	C676.502869,684.438477 675.612671,685.738770 675.051392,686.530090 
	C673.890442,688.166748 672.509460,689.613647 671.158386,691.078003 
	C670.325684,691.980591 669.585144,693.636780 668.007507,692.778076 
	C666.682739,692.057007 666.845581,690.499939 667.045837,689.162109 
	C667.246094,687.824402 667.926331,686.660156 668.644897,685.146484 
	C669.713074,681.342224 673.720032,679.283142 673.023132,674.908691 
	C671.425781,673.555908 669.823059,673.309387 668.110718,673.329895 
	C664.193237,673.376770 663.608398,671.177368 664.755493,668.050842 
	C666.071655,664.463989 666.989563,660.724182 668.959717,657.006348 
	C673.387390,650.414001 676.748474,643.662659 679.669922,636.299194 
	C679.836121,635.485718 679.982056,635.036194 680.318604,634.283813 
	C682.451538,631.649719 683.329895,628.854858 684.035767,625.611938 
	C684.354553,624.619141 684.650085,623.989380 685.101929,623.044312 
	C685.716003,621.900085 686.277771,621.152466 687.265076,620.312134 
	C690.381775,618.785095 692.536926,617.132080 690.654907,613.214600 
	C690.548706,612.649170 690.545898,612.415894 690.646729,611.847473 
	C698.016113,599.732605 704.742615,587.793823 702.002563,572.748047 
	C701.700684,571.499146 701.637207,570.557861 701.292358,569.309570 
	C701.188538,568.481262 701.210144,568.006409 701.398254,567.193848 
	C702.088135,565.828613 702.748169,564.856506 703.729858,563.687134 
	C706.083374,562.539551 706.656006,560.393738 708.122437,558.501953 
	C711.275818,557.681519 712.472595,555.775818 712.904175,553.028564 
	C714.184082,544.882324 716.048279,537.091187 713.177490,528.511902 
	C711.037842,522.117737 711.817688,514.696533 713.451660,507.429443 
	C716.928589,506.549713 717.121948,509.046997 717.363159,511.073181 
	C717.839600,515.075378 719.152344,518.788757 720.442322,522.570618 
	C722.301331,528.020691 720.693542,533.728943 720.909302,539.318726 
	C721.092163,544.054871 720.585144,548.919189 720.135498,554.113525 
	C720.093628,554.778137 720.067993,555.040833 719.978638,555.688477 
	C718.937439,558.799561 719.649780,561.486084 720.145142,564.555786 
	C720.204102,565.440369 720.192993,565.947205 720.125732,566.833374 
	C719.324158,569.940491 721.824646,573.436584 717.792114,575.472046 
	C714.397400,573.435669 715.696716,569.773499 713.777283,567.000244 
	C715.153870,572.894226 712.485535,577.953369 711.008545,583.563599 
	C710.760132,584.249512 710.178528,584.382935 710.225891,584.822632 
	C710.257568,585.117126 710.633728,585.175964 711.222717,585.238892 
	C712.573303,587.402649 711.751587,589.321594 711.298889,591.234497 
	C709.460938,599.000183 706.860291,606.545837 704.135803,614.017639 
	C701.933228,620.058228 699.137878,625.866089 697.420593,632.114563 
	C694.570129,642.486389 687.240234,650.672852 683.233765,660.507690 
	C681.995972,663.546204 680.202271,666.346497 679.029236,669.602600 
	C681.188782,667.250488 681.788513,663.833130 685.252319,662.566650 
	C685.985168,663.167908 685.511658,662.492249 685.986206,662.933594 
	C686.486511,663.398804 686.369873,664.243286 687.085144,664.517456 
	C686.876221,663.864990 686.402405,663.400818 685.752625,662.629700 
	C686.668640,659.427246 687.808838,656.538696 688.155090,653.386292 
	C688.299072,652.075745 689.395447,651.016418 689.806213,649.798401 
	C690.853638,646.692566 696.813477,648.325134 695.775940,642.527588 
	C695.208191,639.355469 699.348145,636.169250 700.243408,632.298157 
	C701.056396,628.782593 702.658875,625.429138 703.367493,621.825928 
	C704.052612,618.342224 706.429077,615.597778 707.603760,612.291382 
	C709.636902,606.568726 709.465759,600.501465 710.733215,594.664307 
	C711.445984,591.381958 712.309021,588.230835 714.397339,585.149292 
	C717.594604,584.609924 717.854675,586.580505 717.832092,588.707764 
	C717.817993,590.035767 717.434204,591.346497 717.529480,592.685364 
	C717.600891,593.688843 717.682434,594.856628 718.821289,595.137939 
	C720.101318,595.454102 720.441406,594.248596 720.834290,593.362671 
	C722.081360,590.550537 724.137207,588.863647 727.520752,588.839355 
	C727.793274,589.514709 727.807251,589.955200 727.818604,590.728394 
	C728.574097,594.283569 725.398682,597.731079 728.734253,600.856445 
	C729.537415,602.076111 727.457703,605.045776 731.208130,603.900452 
	C732.395020,603.538025 732.743225,604.429016 732.832336,605.175842 
	C732.943970,606.111145 732.887512,607.858643 732.574219,607.936829 
	C724.419067,609.973938 731.839417,612.020569 732.615295,613.898926 
	C733.039368,614.925659 734.076965,615.676941 735.651978,615.961426 
	C736.366272,617.184570 737.761292,618.504639 736.171631,619.537415 
	C734.719299,620.480957 734.423828,618.831604 733.691589,618.003174 
	C732.280212,616.406189 731.403198,617.262512 731.077026,618.945679 
	C730.888733,619.917114 731.081421,620.980103 730.802307,621.911987 
	C730.417114,623.197815 729.593079,624.685181 728.147705,624.419922 
	C726.315491,624.083618 727.098267,622.306702 727.179260,621.096497 
	C727.244141,620.126160 727.736267,619.180847 727.776794,618.213440 
	C727.813049,617.346252 727.223877,616.557434 726.310608,616.494995 
	C725.105469,616.412598 724.940979,617.476318 725.265381,618.206970 
	C726.148193,620.195007 725.070740,620.780640 723.485413,621.406677 
	C718.750366,623.276611 716.390686,627.976196 712.524414,630.961182 
	C710.753357,632.328613 709.383850,635.259827 710.000122,638.590698 
	C704.087341,638.800964 703.540466,644.583252 703.530640,647.350647 
	C703.512024,652.605469 700.916626,659.102905 707.075867,663.204529 
	C707.925842,663.770569 707.607727,664.640686 707.081116,665.386719 
	C706.406067,666.343018 705.521240,667.454773 706.731018,668.444275 
	C707.978455,669.464600 709.212341,668.867737 710.205750,667.744324 
	C712.654785,664.975037 715.290894,664.090210 718.638184,666.991028 
	C719.467346,666.807678 719.854065,666.903259 720.078613,667.670776 
	C718.018616,670.557678 717.564575,679.088623 719.315125,681.106262 
	C723.306824,685.707031 723.225037,687.161072 718.835754,690.611328 
	C718.790833,690.646667 719.275330,691.355347 719.781372,691.880432 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M703.868591,697.658813 
	C704.160400,700.295105 704.225037,702.620728 707.634399,703.082031 
	C706.911072,705.242554 708.095459,706.582886 709.898010,707.949280 
	C710.313660,708.250305 710.437561,708.413757 710.777466,708.796265 
	C710.993591,709.015259 710.997314,708.996704 710.988342,709.001709 
	C711.103271,709.190063 711.227173,709.373413 711.159241,709.827576 
	C711.143372,713.868408 707.481262,716.508972 707.972534,720.693115 
	C707.594055,723.584106 710.897583,725.875793 708.270996,728.779175 
	C707.239990,728.999023 706.487061,729.019104 705.357666,729.026917 
	C704.820923,728.883850 704.660645,728.753113 704.278687,728.839355 
	C703.942383,729.373169 703.746094,729.626587 703.249695,729.924805 
	C702.612915,730.445190 702.156067,730.797119 701.288818,730.993713 
	C700.131287,730.902954 699.262817,730.777344 698.156616,730.419678 
	C697.781921,727.190857 699.779053,716.858337 701.127258,715.149658 
	C702.170166,713.827759 701.840210,712.866455 701.240417,711.558838 
	C700.183289,709.254272 701.125366,707.841125 703.528625,707.223206 
	C701.675354,705.681885 700.306152,707.077148 698.818542,708.163086 
	C697.119812,709.403198 695.570984,711.143372 692.818542,710.076050 
	C692.032837,708.648071 692.077759,707.418457 693.065369,706.648682 
	C695.981384,704.375854 698.182983,701.636475 699.719177,698.247559 
	C700.476196,696.577759 701.940979,695.783142 703.868591,697.658813 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M708.365540,728.995483 
	C708.818909,727.551880 709.060547,726.246765 707.391418,725.183716 
	C705.238403,723.812317 707.046753,722.740906 707.856018,721.306885 
	C714.327026,721.749084 716.512207,717.225464 718.664673,712.411011 
	C719.699829,712.966248 720.637024,713.554504 721.640137,713.991150 
	C724.862305,715.393616 725.503845,716.766602 722.077759,718.941040 
	C719.119568,720.818665 718.291260,724.374084 717.164185,727.366516 
	C716.208923,729.902649 717.980408,729.541748 719.716309,728.152100 
	C720.598450,727.110596 721.227722,726.277161 722.748169,726.926025 
	C722.998169,727.657288 723.004944,728.082458 723.074768,728.822632 
	C722.571045,731.927734 721.524170,734.462402 719.140259,736.671387 
	C715.159241,738.127014 714.786987,732.928650 711.231384,732.841492 
	C710.926758,731.065308 711.414001,729.128784 708.365540,728.995483 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M722.107788,709.196045 
	C721.022766,709.576233 720.771179,711.100830 719.535583,710.464844 
	C718.943115,710.159912 718.478271,709.150024 718.395325,708.410156 
	C718.236877,706.996521 719.478088,707.128967 720.421631,706.912109 
	C721.902344,706.571716 723.330383,706.328369 724.866577,707.602661 
	C724.587769,708.990845 723.530151,709.173645 722.107788,709.196045 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M721.858887,709.290039 
	C722.626892,708.689270 723.510315,708.341980 724.740356,707.918701 
	C726.872131,708.270447 727.796265,709.756836 726.611023,710.930237 
	C724.788574,712.734375 723.619446,709.820618 721.858887,709.290039 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M723.010071,726.883484 
	C722.089661,726.985901 721.316528,727.259155 720.307434,727.910400 
	C719.843933,726.277039 720.804810,725.056824 722.265686,724.744141 
	C723.380615,724.505554 722.777710,725.902771 723.010071,726.883484 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M715.256165,707.933350 
	C715.011230,707.215393 715.167419,706.545776 715.969543,706.494751 
	C716.156555,706.482849 716.560669,706.992188 716.538452,707.022461 
	C716.277283,707.378845 715.953918,707.689575 715.256165,707.933350 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M715.033813,708.048401 
	C714.673767,708.533630 714.322449,709.247620 713.640930,708.425537 
	C713.893860,708.265076 714.146790,708.104675 714.632568,707.899292 
	C714.865356,707.854370 714.979187,707.982422 715.033813,708.048401 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M731.927185,725.915283 
	C732.041931,725.820007 732.238586,724.970581 732.210754,725.972168 
	C732.068054,726.056580 731.971863,725.964600 731.927185,725.915283 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M711.157593,709.993652 
	C710.963623,709.916504 710.959717,709.734497 710.967651,709.279663 
	C711.181396,709.182068 711.395691,709.346619 711.571167,709.545349 
	C711.595337,709.572754 711.426941,709.770142 711.157593,709.993652 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M710.920776,708.813599 
	C710.693298,708.938293 710.457031,708.771057 710.237305,708.300110 
	C710.461182,708.196655 710.680542,708.371704 710.920776,708.813599 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M530.121460,204.725067 
	C530.217163,204.268097 530.356934,204.029190 530.496704,203.790253 
	C530.637756,204.029694 530.904663,204.276810 530.890686,204.506821 
	C530.868774,204.866501 530.848755,205.547623 530.121460,204.725067 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M532.990112,212.405426 
	C535.830811,213.650635 538.140991,215.576370 537.149048,219.676453 
	C535.948425,220.214233 534.870789,220.443466 532.867310,220.869644 
	C535.580383,217.748016 533.768250,215.308029 532.990112,212.405426 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M782.296387,704.904968 
	C781.686707,704.937500 781.365845,704.873291 781.044922,704.809021 
	C783.363037,700.618652 780.660522,700.747070 777.383972,701.003174 
	C781.241272,698.973511 785.203186,695.586731 790.844116,698.152893 
	C789.742859,700.362183 787.039795,700.682190 785.037354,702.581421 
	C784.120544,703.525024 783.352844,704.166626 782.296387,704.904968 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M791.535767,698.233582 
	C791.976624,696.631958 788.133179,694.778870 791.571777,693.160095 
	C793.947327,692.041748 794.358521,693.695862 795.171021,695.495972 
	C794.130127,696.623718 792.983765,697.404968 791.535767,698.233582 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M543.694763,229.939636 
	C544.773621,231.577774 549.317810,230.798325 547.116455,234.907959 
	C546.855469,235.372009 546.730225,235.513855 546.802551,235.859467 
	C547.877319,237.810883 549.319092,238.236481 551.128723,237.854324 
	C554.019775,237.243790 556.853638,235.445312 559.890503,237.623154 
	C560.235596,237.870605 561.862000,237.225204 561.974854,236.767227 
	C563.429016,230.867279 564.216003,238.027893 565.687866,236.957138 
	C566.014160,237.000610 566.115112,237.474457 566.171509,237.710052 
	C565.947632,239.017700 565.448120,239.986359 566.036987,241.210129 
	C568.374512,246.068451 568.330566,246.089600 563.037354,248.862076 
	C562.320984,249.475388 561.799561,249.808960 561.044678,250.303345 
	C560.666565,250.576431 560.521912,250.688583 560.191528,250.990753 
	C559.611694,251.611038 559.615540,252.123260 559.762085,252.932556 
	C559.892700,253.602829 559.934814,253.996429 559.948975,254.706085 
	C559.913879,255.230118 559.884705,255.440567 559.779419,255.963928 
	C559.442932,257.113068 559.117004,257.922913 558.543457,258.990417 
	C557.584229,260.132080 556.608704,260.708740 555.258423,261.324188 
	C553.926697,261.472595 553.140503,261.003265 552.168945,260.148132 
	C551.799377,259.705719 551.670593,259.512665 551.410278,258.996704 
	C551.029419,257.067657 550.601196,255.555252 548.878052,254.347610 
	C545.026672,249.619965 544.089111,243.665070 540.700317,238.610764 
	C541.016174,236.610062 545.932190,237.086823 542.133789,234.212784 
	C541.365906,233.337265 541.204956,232.536499 541.737305,231.314148 
	C542.469971,230.672165 542.959412,230.379242 543.694763,229.939636 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M527.005127,244.012436 
	C527.237915,242.265121 528.345337,242.001785 529.547913,242.007629 
	C531.884094,242.018967 532.183228,240.543839 532.039429,238.305542 
	C534.934265,237.237503 537.681885,234.371811 540.920166,237.629242 
	C540.996033,237.994965 541.000000,238.000000 540.997437,237.998901 
	C539.156921,241.157822 538.792847,244.808640 537.591858,248.175720 
	C536.261292,251.905930 535.370911,252.009567 532.954102,248.811157 
	C532.139160,247.732590 531.259399,246.839401 529.765503,246.067230 
	C528.619324,245.474564 527.785278,245.037827 527.005127,244.012436 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M526.990540,238.605865 
	C526.575195,239.137024 526.159729,239.287109 525.417847,239.499023 
	C525.029846,239.412659 524.956482,239.268158 524.908447,239.115662 
	C524.480896,237.757187 522.121521,237.710526 522.608887,235.832581 
	C522.704285,235.465210 523.621155,235.090134 524.154480,235.096664 
	C526.143250,235.120941 526.960510,236.349594 526.990540,238.605865 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M541.700195,230.861877 
	C541.964294,231.743500 541.948059,232.521942 541.907715,233.690460 
	C540.464294,234.239227 539.044922,234.397903 537.164612,234.608139 
	C538.086243,232.392731 538.673950,230.461594 541.700195,230.861877 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M806.454712,672.404541 
	C806.792480,671.931396 807.030029,671.711304 807.255249,671.479248 
	C808.387634,670.312317 809.501282,669.045654 811.358948,669.462585 
	C811.634033,669.524292 811.946167,670.264893 811.922119,670.672668 
	C811.804626,672.670959 810.229614,673.214966 808.335083,673.848267 
	C807.840027,673.882324 807.679138,673.804321 807.266846,673.819458 
	C806.821533,674.049866 806.627686,674.187073 806.264160,674.011169 
	C806.179199,673.349304 806.263977,673.000671 806.454712,672.404541 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M783.198059,687.336853 
	C782.663391,686.907043 782.361572,686.388855 783.142151,686.369629 
	C783.676147,686.356506 783.863220,686.894653 783.198059,687.336853 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M774.692505,691.733032 
	C774.816528,693.112427 774.239441,693.432373 773.119995,692.378296 
	C773.392090,691.661133 773.837646,691.491577 774.692505,691.733032 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M775.013550,691.692627 
	C774.574219,691.926453 774.131592,691.932007 773.360229,691.995728 
	C773.487305,691.718201 773.943237,691.382507 774.704529,691.027222 
	C775.009888,691.007568 775.010315,691.464233 775.013550,691.692627 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M771.467285,693.424194 
	C771.561462,693.373962 771.606384,693.586670 771.606384,693.586670 
	C771.606384,693.586670 771.373047,693.474426 771.467285,693.424194 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M774.996094,690.995056 
	C775.083130,690.958618 775.174072,690.927063 775.137451,690.951538 
	C775.009888,691.007568 775.000000,691.000000 774.996094,690.995056 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M777.008789,701.011230 
	C776.915161,701.047729 776.813110,701.074036 776.845764,701.042236 
	C776.980469,700.984131 777.000366,701.001099 777.008789,701.011230 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M806.120422,673.911377 
	C806.094421,673.697998 806.560791,673.779358 806.788086,673.845947 
	C806.799255,674.284668 806.601746,674.732605 806.120422,673.911377 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M776.528442,707.542969 
	C776.477905,707.571472 776.578918,707.514526 776.528442,707.542969 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M767.743042,684.599060 
	C767.862183,684.450684 768.065857,684.430054 768.269470,684.409424 
	C768.122192,684.515198 767.974854,684.621033 767.743042,684.599060 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M787.057007,667.130737 
	C787.118774,667.135071 787.237549,667.270081 787.356323,667.405151 
	C787.275574,667.357300 787.194763,667.309387 787.057007,667.130737 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M494.984772,187.014740 
	C494.329803,186.946838 493.639221,186.887222 493.529510,186.077499 
	C493.505402,185.899689 493.864624,185.669907 494.047272,185.464050 
	C494.349060,185.780304 494.650818,186.096573 494.988953,186.693298 
	C495.025269,186.973755 494.998535,187.001266 494.984772,187.014740 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M805.263367,654.510498 
	C805.156921,654.389526 805.518066,654.313538 805.518066,654.313538 
	C805.518066,654.313538 805.369812,654.631409 805.263367,654.510498 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M284.341278,478.790833 
	C283.250305,478.997711 282.504272,478.996674 281.385223,478.993530 
	C279.842194,477.902710 279.703094,476.492126 279.805115,474.599731 
	C280.560242,471.026123 280.486755,467.814148 280.522522,464.225769 
	C280.821503,461.182373 281.339874,458.529907 280.801056,455.480103 
	C280.809265,454.878967 280.825378,454.639648 280.897156,454.049866 
	C281.007690,453.473572 281.062531,453.247742 281.218140,452.694275 
	C282.162781,450.636902 281.929291,449.025085 280.662933,447.191895 
	C280.522888,446.639709 280.507629,446.408295 280.567169,445.834747 
	C285.627289,438.942200 287.200348,431.306030 288.302460,423.334839 
	C289.320343,415.972931 291.730499,408.966553 297.514038,403.576721 
	C297.062164,402.953522 296.839813,402.563232 296.581665,401.796204 
	C300.049255,395.188599 303.729156,389.031097 306.496368,382.351349 
	C307.813507,379.171844 308.592346,376.208984 307.864288,372.791809 
	C306.934082,368.426086 306.236420,364.004364 307.761108,359.238617 
	C307.691956,352.872040 308.289032,346.881256 309.007996,340.459534 
	C309.719086,339.491150 310.469696,339.249939 311.673401,339.757507 
	C312.338440,347.457642 312.660278,354.925720 312.986420,362.393555 
	C313.213379,367.589752 317.284729,364.893951 319.851593,365.323151 
	C319.876709,363.154907 320.031158,361.375214 320.722382,359.614319 
	C321.580536,357.428162 321.421417,355.283142 321.075928,352.873962 
	C320.227448,346.957550 320.638794,340.994476 323.571777,335.137115 
	C324.594635,330.191010 325.293060,325.517120 326.067261,320.441406 
	C327.178711,319.104706 328.099091,319.615875 329.265381,320.569214 
	C330.042236,321.806580 330.136200,322.825531 330.082275,324.231140 
	C330.455048,329.830383 330.514679,335.040649 329.131683,340.179535 
	C328.775055,341.504639 329.189789,342.781830 330.070740,344.242767 
	C330.329437,344.992798 330.328491,345.431396 330.017883,346.146790 
	C329.472931,346.925995 329.042267,347.391113 328.753265,348.302307 
	C328.483643,349.673035 327.782288,350.510681 327.217896,351.703613 
	C326.602539,352.412933 327.301666,351.737244 326.808563,352.185638 
	C326.473511,352.490265 326.444611,352.494781 326.934082,352.041321 
	C330.446198,350.588593 332.956696,348.261383 335.532562,345.466522 
	C339.899475,342.898010 343.089539,343.174255 345.180267,347.978455 
	C346.026367,349.922699 347.403931,351.085327 348.916473,348.295624 
	C349.588837,347.055573 350.497345,346.099548 352.083618,346.839325 
	C353.418610,347.461914 353.907959,348.670807 354.056427,350.044250 
	C354.342865,352.694366 353.033600,354.804596 351.543274,357.210205 
	C349.268005,361.740570 344.594604,364.105621 343.051331,369.164734 
	C339.111176,375.876312 334.713318,381.891663 331.042847,388.366669 
	C326.791962,395.865631 323.294067,403.702301 320.446442,412.183136 
	C320.364227,413.840515 320.286560,415.160614 321.239594,416.628998 
	C321.376251,417.168335 321.386993,417.394897 321.268555,417.938507 
	C320.973083,418.413025 320.806885,418.570435 320.351562,418.911774 
	C316.968903,420.152893 317.499146,424.475677 313.974548,425.458374 
	C311.914093,424.788849 312.054382,423.313995 312.127441,421.561188 
	C312.381805,420.834625 312.725128,420.535126 312.930481,420.171112 
	C313.254608,419.596588 312.986145,420.428284 312.782776,419.793488 
	C312.735107,419.644592 312.719788,419.796814 312.620300,420.483032 
	C312.606140,425.787659 310.172302,430.244720 309.207977,435.031494 
	C308.871002,436.704071 308.160339,438.443390 306.144806,438.386597 
	C303.814087,438.320862 303.551086,436.392029 303.530731,435.768921 
	C302.949493,441.485413 300.843201,448.120514 299.273407,454.856842 
	C298.856079,456.647797 297.811432,458.240631 299.807800,460.083008 
	C300.239502,460.541321 300.398254,460.743713 300.734985,461.297821 
	C301.202301,462.418762 301.309967,463.223175 301.407135,464.457703 
	C301.173431,473.822174 299.719879,482.659790 298.999664,491.566376 
	C298.316376,500.016083 298.328979,508.356628 299.368591,517.160889 
	C300.002747,526.186768 301.788727,534.607910 303.315796,543.473572 
	C303.593903,545.412354 304.047943,546.888000 304.776978,548.683960 
	C305.354126,551.033081 306.004333,552.983887 306.022278,555.428223 
	C305.260620,558.429871 306.602966,560.620911 307.488953,562.899109 
	C310.582428,570.853516 314.749542,578.445557 315.368805,587.223450 
	C315.509644,589.219543 316.854675,590.853638 317.996796,592.481262 
	C319.029968,593.953674 320.320038,595.367920 319.236206,597.703979 
	C317.518158,599.890503 319.101166,599.819763 320.608826,600.009644 
	C321.296021,600.057068 321.548859,600.241882 321.833252,600.795044 
	C321.700958,604.026855 322.964844,606.396118 324.960846,608.585632 
	C326.290192,610.043884 326.778381,612.170349 325.180969,613.464783 
	C323.639069,614.714172 322.840881,612.518494 321.673340,611.783142 
	C316.373230,608.444763 315.359222,602.225159 311.881775,597.686646 
	C311.197052,596.792969 310.999298,595.503052 309.380249,595.051392 
	C307.485626,593.497192 307.145447,591.396729 306.180664,589.224121 
	C306.036224,588.913086 306.030487,588.484253 306.026947,588.269775 
	C304.435242,580.358093 301.951691,572.955200 298.514893,565.893433 
	C296.992065,562.764221 296.345245,559.845215 297.763153,556.355103 
	C299.398773,552.329102 297.498840,548.163879 295.588104,544.997803 
	C293.613464,541.725891 292.443909,538.460449 291.879639,534.899414 
	C290.318939,525.050537 287.263611,515.485168 286.455505,505.128418 
	C286.992249,498.329437 286.274261,491.925140 286.438538,485.508698 
	C286.599274,479.228912 286.184845,472.925537 287.575623,466.718567 
	C286.398041,470.617950 287.548798,475.101562 284.341278,478.790833 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M297.943420,402.997223 
	C299.178253,403.074493 300.338257,402.898132 300.704163,404.097351 
	C300.984222,405.015137 300.467041,405.720551 299.498352,405.863861 
	C292.956451,406.831665 292.648102,412.874847 291.245392,417.195404 
	C289.060791,423.924225 288.360413,431.129242 286.936005,438.113037 
	C286.275330,441.352173 283.196198,443.004456 281.353546,445.849731 
	C278.328369,443.570709 279.436188,440.526611 279.872742,437.663513 
	C281.573608,426.509338 284.446503,415.659393 288.707611,404.797791 
	C289.840057,398.216095 293.076935,392.694183 294.441345,386.567505 
	C292.127686,391.666931 290.170807,396.908020 287.830688,402.385895 
	C282.785004,412.785828 279.580505,423.450562 276.552368,434.175659 
	C276.101440,435.772858 275.878906,437.444672 274.462341,439.036743 
	C271.926575,439.915771 271.247284,438.733551 271.125793,436.388489 
	C268.902527,434.202576 266.564270,432.437744 270.096344,429.800110 
	C270.846436,429.240051 271.252106,428.232971 270.863586,427.596405 
	C267.245911,421.668610 272.276978,417.034546 273.339264,411.889496 
	C274.423279,406.639282 276.847443,401.666077 278.678650,396.569366 
	C278.903107,395.944611 278.985565,395.246429 279.309723,394.683594 
	C281.264343,391.289764 281.614075,389.991058 281.269775,386.376160 
	C278.137268,388.577972 276.665497,391.442841 275.977417,394.942017 
	C274.512817,402.390320 271.962646,409.345947 266.021667,415.012207 
	C264.359589,416.495300 263.033844,417.849396 260.894653,418.650330 
	C255.431168,418.218353 254.566971,416.314728 257.268005,410.677765 
	C258.777649,410.073669 260.229706,410.256683 261.326050,408.788574 
	C262.272369,407.729736 263.155762,406.971130 262.329468,405.397034 
	C260.815460,402.095062 263.163025,400.958740 265.228333,399.332947 
	C266.362244,391.027954 271.160980,384.601959 274.721100,377.290161 
	C274.921326,376.777954 275.011719,376.580017 275.256348,376.102600 
	C275.720673,375.294250 276.026337,374.763519 276.131287,373.835876 
	C276.168793,373.110687 276.207581,372.693146 276.295197,371.953857 
	C275.542694,367.220734 275.804779,363.684845 280.972076,361.611237 
	C283.937286,360.421295 282.795410,355.733246 285.514343,353.270905 
	C287.974609,352.120026 289.691376,349.961426 292.717407,350.777771 
	C294.322449,352.792236 295.524963,354.449432 293.121765,356.457153 
	C292.183289,357.241241 291.620911,358.628510 291.208801,359.849548 
	C290.727570,361.275452 291.564636,362.351105 292.711731,363.212189 
	C294.094055,364.249878 294.835388,363.203003 295.575226,362.351349 
	C297.717438,359.885345 299.811829,357.377838 301.969513,354.551910 
	C302.313873,353.665497 302.581451,353.088531 303.475006,352.751160 
	C308.099274,352.632843 306.463837,356.344177 307.686432,358.649414 
	C308.301544,369.075226 311.699310,379.284149 305.213959,389.153687 
	C302.597015,393.136200 300.315887,397.402618 297.177460,401.487762 
	C296.856354,402.387238 297.066467,402.801697 297.943420,402.997223 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M329.442841,321.182465 
	C328.377411,320.711487 327.578461,320.399750 326.461304,320.063782 
	C326.119904,319.272247 326.096741,318.504944 326.069366,317.354156 
	C326.065125,316.970642 325.649719,316.756073 325.604218,316.529694 
	C325.480469,315.901062 325.240814,315.753510 324.704224,315.621338 
	C324.305328,313.386749 326.446533,312.773193 327.116669,310.929718 
	C327.621521,310.044037 328.041748,309.511902 328.865417,308.902832 
	C330.964600,309.036377 330.825317,310.454956 330.974304,311.490723 
	C331.821167,309.699951 328.811493,305.101379 334.814240,306.431213 
	C337.253235,309.419983 338.086090,312.576538 337.494324,316.440186 
	C338.156708,315.413330 338.395294,314.651398 339.075134,313.736237 
	C342.402802,310.258606 346.001068,307.601929 349.543793,304.338257 
	C356.671326,298.850861 364.154541,294.550903 371.361572,289.835754 
	C372.998535,288.764801 374.689484,287.470703 376.895569,289.112640 
	C376.953033,289.480286 376.964264,289.504303 376.969604,289.516449 
	C377.721863,291.134186 377.578064,292.783661 377.087463,294.774353 
	C376.762238,295.535706 376.636383,295.986542 376.774170,296.741577 
	C376.977173,297.028442 376.989410,296.996887 376.976868,297.011047 
	C379.026489,299.447784 378.651550,302.167664 377.760559,305.271271 
	C376.838318,307.110229 374.632355,306.839325 373.979156,308.846436 
	C373.837097,309.584198 373.700531,309.988129 373.309265,310.626892 
	C372.516235,312.054138 371.815887,313.135498 372.976105,314.469574 
	C375.738342,317.645691 374.395142,321.339142 374.044403,325.226440 
	C373.984741,325.780518 373.998901,325.995544 373.958191,326.531555 
	C373.818604,327.053925 373.733948,327.255280 373.488892,327.742371 
	C372.507599,328.952301 372.559601,330.006775 372.936920,331.437744 
	C373.349915,334.475769 372.283569,336.858704 371.171448,339.578705 
	C370.033508,341.939819 368.111603,342.642609 365.833130,343.395599 
	C362.679810,347.771210 358.994659,351.305511 355.573273,355.067230 
	C354.246552,356.525970 353.097321,358.328705 350.362122,357.295471 
	C351.199768,354.775177 353.351135,352.882416 352.972992,349.941711 
	C352.840057,348.907593 352.601440,347.829803 351.541229,347.573792 
	C350.088348,347.223022 350.149872,348.530060 349.945923,349.448608 
	C349.601105,351.001465 348.777466,352.382202 347.190674,352.561737 
	C345.139526,352.793823 345.316132,350.699493 344.616760,349.508789 
	C344.123260,348.668610 343.183624,348.046326 342.838959,347.170959 
	C341.725647,344.343506 340.398743,343.468048 338.179352,346.213470 
	C337.760376,346.731720 337.069153,346.881531 336.200562,346.250122 
	C335.826294,342.348145 338.729187,340.203888 340.360901,337.461914 
	C341.453430,335.625916 342.602112,333.792725 344.040344,331.740540 
	C350.995758,324.309723 357.493469,316.962646 362.579376,308.452209 
	C360.554901,310.525085 358.014893,312.087219 355.534393,315.176086 
	C354.821747,316.100067 354.692535,316.258026 354.162598,316.851868 
	C353.625549,317.422119 353.489197,317.556488 352.945129,318.076843 
	C352.403107,318.590057 352.268829,318.717316 351.735596,319.231628 
	C351.203125,319.751495 351.069519,319.884308 350.515411,320.418152 
	C349.939484,320.945526 349.784088,321.071808 349.370178,321.364807 
	C348.920654,321.617401 348.729645,321.703217 348.232910,321.879303 
	C347.500610,322.053131 347.070129,322.106323 346.302124,322.147339 
	C343.163391,321.692108 341.361786,323.220581 339.436951,325.010590 
	C337.166656,327.121857 334.575134,329.421509 331.343353,325.803772 
	C331.334564,323.880981 331.783447,322.130493 329.442841,321.182465 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M286.328308,353.601562 
	C285.232727,356.067871 283.912964,358.060791 283.877014,360.555176 
	C283.856873,361.953430 282.791901,363.417267 281.570496,363.532227 
	C275.270569,364.125031 276.534058,367.563690 278.344940,371.366760 
	C278.396362,371.474792 277.973602,371.808594 277.469269,372.105255 
	C274.946503,373.439545 273.184174,371.823883 270.949524,371.082428 
	C270.449860,370.802734 270.259369,370.675598 269.818237,370.318787 
	C267.977814,368.166199 264.073364,368.312592 264.794006,364.255249 
	C265.644684,363.290436 266.590515,362.957184 267.215149,362.232452 
	C267.659241,361.728607 266.906555,361.369690 266.987915,361.819763 
	C267.192169,362.949249 265.837433,363.057922 265.028931,363.961548 
	C263.536835,368.725037 259.232697,370.383636 256.173981,373.177979 
	C255.129089,374.132568 253.315048,374.227448 252.021515,373.054108 
	C250.747742,371.898804 250.889984,370.351898 251.330017,368.883606 
	C251.995941,366.661530 253.179916,364.626312 253.893646,362.034851 
	C254.136581,361.488983 254.252457,361.277802 254.601990,360.788147 
	C255.812195,359.711792 256.905609,359.283020 258.505310,359.561523 
	C260.987213,361.359833 260.815582,358.922974 261.726318,357.840912 
	C262.001984,357.391815 262.120392,357.220795 262.432617,356.816345 
	C263.008301,356.152832 263.383423,355.718842 263.468292,354.822327 
	C263.516144,353.269226 263.751831,352.045319 264.250885,350.542145 
	C264.525208,350.066284 264.664307,349.892334 265.093323,349.547852 
	C266.046906,349.160645 266.714386,349.172272 267.701477,349.455750 
	C271.437256,350.282196 272.247162,347.096588 273.948792,345.423035 
	C277.309814,342.117493 280.611572,338.837097 285.611694,337.762878 
	C286.840363,338.082855 287.689056,338.335754 287.977051,339.780609 
	C289.262482,344.706635 288.519196,349.079437 286.328308,353.601562 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M248.304504,368.196655 
	C247.148834,362.765564 251.784378,360.049805 253.579819,356.029388 
	C253.844101,355.437561 255.019348,355.036224 254.894455,354.153717 
	C254.664261,352.526794 256.588165,350.377075 255.082611,349.554443 
	C253.088318,348.464752 251.817627,350.827850 251.192429,352.330933 
	C249.898712,355.441345 247.186539,355.384766 244.270523,355.900635 
	C243.601181,355.526001 243.464539,355.129608 243.593018,354.399200 
	C245.900925,346.608093 252.243652,342.135895 260.906708,341.991486 
	C262.050110,341.972412 263.284637,342.117859 263.916809,340.353027 
	C267.571289,336.675659 271.451202,333.631256 274.647766,329.987366 
	C278.785248,325.270935 283.365784,325.358398 288.774872,327.747742 
	C289.489655,329.159454 288.868011,330.031677 288.084473,330.577484 
	C283.077026,334.065674 279.130127,338.794312 273.873260,342.489197 
	C269.732391,343.601990 268.349884,347.418060 265.282684,349.789337 
	C265.060608,350.045502 265.005951,350.010651 265.025574,350.036560 
	C260.553223,352.348694 258.234528,356.615753 255.294922,360.782776 
	C255.074203,361.047455 255.019577,361.020844 255.034729,361.047180 
	C252.294067,362.978699 252.828247,367.776306 248.304504,368.196655 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M325.783875,315.794617 
	C326.046295,316.037750 326.076996,316.504150 326.071045,316.737396 
	C325.511597,317.111084 324.958099,317.251495 324.214935,317.609344 
	C322.956055,319.235413 321.902405,320.659271 320.094849,321.807495 
	C319.840515,322.019165 319.614929,321.975250 319.500671,321.962982 
	C318.410828,322.533386 317.668640,323.414673 316.413208,324.181030 
	C315.877441,324.310059 315.650116,324.291992 315.216278,324.001282 
	C313.634338,318.029327 310.513580,319.916687 307.581726,322.070953 
	C306.413818,320.034943 305.122650,318.265839 304.335846,316.295319 
	C303.258698,313.597687 304.121521,312.151123 307.635193,312.993591 
	C309.044250,313.724915 309.625092,315.238495 311.614868,314.990662 
	C316.931610,312.836517 321.343140,308.758392 327.742920,310.725952 
	C329.164276,313.627167 325.262177,313.433411 325.783875,315.794617 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M323.669800,334.698212 
	C323.313324,341.815460 320.813385,348.670959 322.942627,355.756256 
	C323.956390,359.129700 321.132629,361.521698 320.296753,364.749329 
	C318.018707,362.206879 315.522217,359.426025 319.501984,356.299774 
	C320.290955,355.679993 320.586670,354.941101 320.015900,354.218964 
	C318.537872,352.349030 317.192932,355.165771 315.666595,354.461456 
	C315.249207,346.903168 319.077057,340.569427 321.691528,333.929688 
	C322.729828,331.292664 322.859711,334.241882 323.669800,334.698212 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M272.677551,342.104492 
	C278.291412,337.632019 282.861633,332.390259 288.848572,328.305969 
	C290.108551,327.768158 291.189026,327.513123 292.836212,327.124268 
	C291.543823,331.545563 287.075409,333.323212 285.956604,337.626160 
	C279.579132,340.813690 275.358948,346.119110 270.602295,350.806183 
	C269.950195,351.448761 268.554565,354.222290 267.999451,349.972321 
	C268.232849,348.511200 268.632019,347.748138 269.012390,346.628357 
	C270.119232,344.872223 270.693573,343.101105 272.677551,342.104492 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M286.401184,353.948334 
	C287.203735,349.619995 286.000854,345.007080 288.023285,340.500183 
	C288.813660,339.750244 289.598328,339.380371 290.709961,338.995728 
	C291.188080,339.113983 291.339203,339.247040 291.712494,339.175507 
	C292.047180,338.668640 292.243744,338.438049 292.744690,338.209839 
	C293.118622,338.216339 293.272125,338.292023 293.603699,338.553528 
	C293.986206,339.016785 294.167938,339.308258 294.410187,339.859009 
	C294.494385,340.273682 294.491760,340.445251 294.353210,340.835419 
	C292.136566,343.959869 295.399902,347.410034 293.134644,350.664429 
	C290.478821,351.115479 289.032135,353.439484 286.401184,353.948334 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M301.562592,327.969910 
	C303.596741,332.441132 307.204041,327.695648 309.377319,329.521973 
	C309.366394,331.950684 306.246124,333.242584 307.508728,335.979706 
	C308.294678,336.321930 308.506653,336.755859 308.192169,337.654053 
	C307.961456,337.973907 307.990021,338.005798 307.989960,337.984497 
	C307.803375,338.092163 307.616882,338.221130 307.158508,338.145813 
	C305.465210,338.259674 304.013580,338.913452 302.632111,338.788971 
	C301.541473,338.690765 298.810242,339.104828 300.219604,336.075256 
	C298.246002,333.976166 298.784271,331.882202 299.783447,329.395386 
	C300.258392,328.552490 300.657349,328.189270 301.562592,327.969910 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M308.227844,338.005737 
	C307.919617,337.542847 307.877777,337.111786 307.811523,336.356537 
	C309.731140,334.196594 311.363708,331.904266 314.454376,331.307007 
	C314.686646,334.448578 313.446930,336.871643 312.068665,339.630096 
	C311.268372,339.997314 310.522034,340.004944 309.403503,340.017761 
	C309.137939,339.283905 310.077759,338.319397 308.227844,338.005737 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M300.669006,328.660095 
	C300.705688,330.951660 299.655273,332.916504 300.043640,335.499146 
	C297.868866,335.121979 295.089294,335.869415 293.773865,333.196442 
	C294.433228,332.575317 294.501709,331.995209 294.818787,331.216858 
	C294.986725,330.984772 295.000000,331.000000 294.992004,330.992920 
	C295.430847,330.576904 296.020386,330.525024 296.779846,330.166687 
	C296.997101,329.978760 296.999695,330.002838 296.989441,329.996155 
	C297.909119,329.060791 298.850006,328.156006 300.669006,328.660095 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M301.102966,328.668579 
	C299.892792,329.240692 298.787689,329.613129 297.330872,329.987488 
	C296.688385,328.121887 296.763000,326.300079 298.553711,325.198120 
	C300.870941,323.772217 302.252350,324.245453 302.001282,327.632629 
	C301.663483,328.073181 301.409363,328.227600 301.102966,328.668579 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M293.183228,339.163391 
	C293.010834,339.005707 292.985748,338.571960 292.975403,338.356293 
	C292.976807,337.950256 292.988495,337.759918 292.998840,337.286377 
	C294.571899,337.536438 296.805939,337.248016 297.351593,339.145844 
	C297.629150,340.111023 295.867767,340.517242 294.535889,340.989563 
	C294.170776,340.879822 294.124329,340.705627 294.038940,340.263855 
	C293.759857,339.795441 293.545074,339.570374 293.183228,339.163391 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M320.232788,322.014069 
	C319.798431,319.634918 321.615021,318.852325 323.674561,317.901825 
	C323.992798,320.115906 323.443268,321.984680 320.232788,322.014069 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M314.782684,324.016235 
	C315.009735,323.728638 315.498810,323.784546 315.744080,323.804565 
	C316.340393,323.936432 316.608429,324.154236 316.840637,324.728638 
	C316.122375,326.194061 315.368347,327.394226 314.468353,328.826660 
	C313.415985,326.971832 314.204376,325.650848 314.782684,324.016235 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M317.191467,324.855347 
	C316.621490,324.773895 316.366608,324.553894 316.050537,324.079224 
	C316.528961,322.692017 317.385681,321.988159 319.023285,321.899872 
	C318.992218,323.030670 318.655853,324.149963 317.191467,324.855347 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M303.789490,352.451233 
	C303.383759,353.171326 302.976868,353.594879 302.291992,354.117798 
	C302.189514,353.061829 301.296326,351.569916 302.768372,350.859283 
	C303.286407,350.609192 303.718018,351.484009 303.789490,352.451233 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M292.497925,319.677887 
	C292.662140,319.357941 292.697388,319.299500 292.727783,319.294586 
	C294.205963,319.559082 293.917847,320.570709 293.581146,321.461121 
	C293.499817,321.676208 292.697083,321.871979 292.438293,321.718597 
	C291.686188,321.272858 292.237091,320.590210 292.497925,319.677887 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M299.837891,352.108826 
	C299.806396,352.693481 299.368164,353.129059 298.737946,352.497192 
	C298.970856,352.328278 299.203796,352.159393 299.837891,352.108826 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M296.675812,329.944031 
	C296.534637,330.321106 296.072174,330.663452 295.296875,330.995819 
	C295.137726,330.241028 295.659058,329.964325 296.675812,329.944031 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M294.709229,331.117340 
	C294.833099,331.556885 294.679474,332.128998 294.262115,332.851288 
	C294.142822,332.417603 294.287262,331.833771 294.709229,331.117340 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M291.710266,338.977020 
	C291.792877,339.315338 291.657410,339.673798 291.115295,339.143311 
	C291.036957,338.980927 291.485901,338.983154 291.710266,338.977020 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M261.295380,339.984497 
	C260.989258,339.991791 260.997070,339.495331 260.999146,339.247070 
	C261.289612,339.167999 261.578033,339.337219 262.028137,339.704102 
	C261.993713,339.926941 261.797607,339.952087 261.295380,339.984497 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M307.000549,338.116028 
	C307.070923,337.918427 307.255249,337.895355 307.714752,337.917725 
	C307.809875,338.371124 307.610962,338.728516 307.000549,338.116028 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M293.001526,336.998749 
	C292.882050,336.855011 292.761047,336.712524 292.640045,336.570038 
	C292.724976,336.620911 292.809906,336.671783 292.946167,336.862915 
	C292.997528,337.003113 293.000000,337.000000 293.001526,336.998749 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M299.968292,351.956329 
	C300.067230,351.695801 300.254852,351.527191 300.442444,351.358551 
	C300.481903,351.571228 300.521362,351.783875 300.399963,352.111877 
	C300.239075,352.227173 300.056946,352.048218 299.968292,351.956329 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M222.872223,335.290771 
	C223.208817,334.751312 223.411148,334.532471 224.059509,334.343872 
	C224.436462,334.697754 224.258789,335.177521 224.597626,335.287476 
	C225.221298,335.489746 224.473572,335.542114 224.665436,334.628601 
	C224.640732,334.345612 224.562485,334.134064 224.580566,334.023010 
	C226.977432,333.898499 228.511597,334.546326 227.746857,337.440582 
	C227.459213,338.529205 225.603226,339.863647 227.464020,340.782196 
	C228.986008,341.533539 230.728607,340.920044 231.934448,339.493958 
	C235.174789,335.661804 239.621552,333.403900 243.801971,330.770020 
	C244.004791,331.437622 244.009598,331.875244 244.008026,332.641022 
	C244.550598,333.342133 244.608170,333.932281 244.849243,334.757935 
	C246.904388,337.890533 243.223602,339.285309 243.114151,341.997406 
	C242.977753,345.376862 238.931152,343.026093 237.160660,343.112915 
	C234.772568,343.230011 231.773361,343.485046 230.867264,344.701996 
	C228.706116,347.604553 228.722656,343.823822 227.366180,344.085938 
	C225.707581,342.873474 223.030487,345.832214 222.170303,342.276855 
	C220.300171,339.648895 221.597824,337.639191 222.872223,335.290771 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M221.824219,342.235901 
	C222.996857,344.748596 226.054977,340.272156 227.002075,343.733887 
	C225.260620,346.640594 222.098083,347.157867 219.685074,348.827820 
	C219.098190,349.233948 217.953812,349.116699 217.579712,348.266144 
	C217.390533,347.836060 217.661057,346.884949 218.048096,346.576904 
	C219.508591,345.414429 219.283722,342.795563 221.824219,342.235901 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M211.771759,359.885284 
	C210.306427,358.411499 210.092819,356.553467 210.094803,354.287903 
	C210.935532,354.168274 211.714203,354.629333 212.484955,354.616455 
	C213.733032,354.595642 214.836121,353.391907 215.634399,355.830231 
	C216.573196,358.697632 213.992050,357.392914 213.438019,358.336334 
	C213.118011,358.881256 212.551102,359.281158 211.771759,359.885284 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M233.655365,351.772583 
	C232.316101,352.895050 230.958054,353.558472 229.880661,352.519989 
	C229.174515,351.839386 229.955566,350.546509 230.666245,349.972229 
	C231.891922,348.981750 230.844788,347.414673 231.982254,346.186523 
	C234.056717,347.505951 235.477097,348.900330 233.655365,351.772583 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M235.535187,361.133789 
	C236.190659,363.426392 235.748474,365.430176 233.329071,366.952911 
	C231.383087,364.271393 232.100098,362.273346 235.535187,361.133789 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M243.388977,354.084656 
	C243.789383,354.499939 243.842239,354.890564 243.886780,355.576263 
	C243.878494,355.871338 243.902420,355.906769 243.920700,355.916138 
	C243.759735,356.192352 243.551773,356.434509 243.146240,356.822266 
	C242.194199,357.321014 241.410995,357.649475 240.310272,358.013855 
	C240.041016,355.980194 240.397934,354.146454 243.388977,354.084656 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M219.883087,366.289856 
	C218.531799,366.222992 217.480911,365.964020 216.222046,365.363464 
	C217.403076,364.973450 218.814026,364.754730 220.174210,364.929932 
	C221.285202,365.073029 220.430161,365.690216 219.883087,366.289856 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M245.111420,334.744415 
	C244.692581,334.525879 244.371292,334.049988 244.025818,333.271637 
	C244.990021,333.009094 245.908615,333.104492 245.111420,334.744415 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M226.765137,355.115662 
	C226.788696,355.549255 226.550217,356.071411 226.311737,356.593567 
	C226.104675,356.479614 225.897614,356.365662 225.690552,356.251709 
	C225.961411,355.902527 226.232269,355.553375 226.765137,355.115662 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M238.748108,346.596283 
	C238.867996,346.447052 239.072769,346.426697 239.277542,346.406342 
	C239.129364,346.512604 238.981186,346.618896 238.748108,346.596283 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M226.993286,354.989746 
	C227.345886,354.593445 227.572937,353.727264 228.290604,354.609619 
	C228.026077,354.737274 227.761551,354.864929 227.262085,355.009827 
	C227.027145,355.027069 227.003326,355.003052 226.993286,354.989746 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M261.004883,340.006317 
	C260.917511,340.043243 260.825562,340.074280 260.861450,340.048553 
	C260.989258,339.991791 261.000336,340.000427 261.004883,340.006317 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M217.462891,340.448303 
	C217.521255,340.413574 217.404510,340.483032 217.462891,340.448303 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M221.542053,363.684570 
	C221.461578,363.762085 221.440399,363.485596 221.440399,363.485596 
	C221.440399,363.485596 221.622543,363.607025 221.542053,363.684570 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M237.454834,355.530640 
	C237.425613,355.479736 237.484039,355.581543 237.454834,355.530640 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M312.845337,277.026367 
	C313.926300,277.332214 313.977081,278.327087 314.250061,279.614319 
	C313.497498,280.920105 312.599609,281.430817 311.680084,280.070923 
	C310.771790,278.727631 311.960693,278.096741 312.845337,277.026367 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M315.117676,286.416016 
	C314.298340,287.029907 313.639435,287.083588 313.353027,286.346985 
	C313.251221,286.085236 313.646790,285.366699 313.952179,285.264130 
	C314.614838,285.041504 315.625946,284.699188 315.117676,286.416016 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M317.785980,288.109863 
	C317.891022,288.314514 317.699799,288.574188 317.212952,288.889679 
	C317.105896,288.683868 317.296661,288.423676 317.785980,288.109863 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M265.362366,295.240936 
	C265.376892,295.006744 265.504700,294.879028 265.632507,294.751282 
	C265.580231,294.949982 265.527924,295.148682 265.362366,295.240936 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M288.683258,302.201538 
	C288.908112,302.480011 288.930206,302.901794 288.931305,303.640015 
	C288.216736,303.565979 287.962799,303.058319 288.683258,302.201538 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M238.004974,327.006836 
	C233.693024,332.398285 233.578842,332.450897 229.430923,330.651672 
	C232.023666,329.068207 234.001831,326.824188 237.600159,326.986115 
	C237.983322,326.983093 237.998672,326.998627 238.004974,327.006836 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M243.447357,327.541473 
	C243.411758,327.482117 243.482971,327.600830 243.447357,327.541473 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M230.129990,336.943024 
	C230.079224,336.956238 230.180756,336.929810 230.129990,336.943024 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M230.007843,337.010345 
	C229.915634,337.046631 229.814758,337.072388 229.845764,337.040405 
	C229.977676,336.982666 229.999176,336.999817 230.007843,337.010345 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M335.410736,306.621490 
	C333.220612,306.631744 331.748718,306.443878 331.994507,309.108978 
	C332.167969,310.989197 331.999847,312.886505 330.845856,314.632660 
	C329.239563,313.119080 330.623169,311.010315 329.292023,309.263733 
	C328.519806,308.562195 328.447876,307.979797 328.615906,307.067596 
	C328.741211,306.419617 328.730438,306.056122 328.439148,305.485107 
	C325.923065,303.859070 323.799927,305.032410 321.646851,305.805267 
	C319.974426,306.405548 318.582855,307.854095 316.322815,307.272797 
	C315.889435,305.262817 316.324799,303.372498 315.137634,301.333313 
	C315.315918,299.244202 315.657196,297.509491 316.002869,295.388794 
	C316.755768,294.732910 317.610840,294.117279 318.273407,294.870819 
	C320.261780,297.132172 321.678925,298.291534 324.097961,294.857910 
	C325.745972,292.518707 328.991394,296.283936 331.707214,294.303833 
	C334.238098,294.412476 336.668671,296.575806 338.509247,292.932343 
	C339.030304,291.900970 341.033081,291.399231 342.463013,292.805511 
	C342.060211,294.938293 339.731567,294.368774 338.669403,295.561188 
	C339.095306,297.408905 342.417389,296.710388 341.991211,299.660889 
	C342.465302,301.588348 341.394470,302.176605 339.941162,302.815948 
	C337.082336,302.513397 336.349823,304.201660 335.410736,306.621490 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M315.902405,307.387390 
	C318.970032,306.124573 321.283081,303.902618 324.387543,303.305695 
	C326.390198,302.920624 328.229431,302.594940 329.874390,304.768005 
	C330.372314,305.489136 330.479248,305.933899 330.195618,306.714905 
	C329.666809,307.478302 329.351105,307.952057 329.027222,308.725647 
	C328.847046,309.575134 328.675079,310.124817 328.269287,310.850281 
	C324.284058,310.568085 321.127991,312.095245 317.919006,313.777527 
	C316.404968,314.571259 314.494232,314.608276 312.358948,314.993469 
	C312.475861,312.181793 315.846375,310.846527 315.902405,307.387390 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M225.334732,523.997986 
	C224.890244,523.681824 224.700272,523.424500 224.216263,523.112732 
	C224.090927,519.884827 221.413513,518.980469 219.487045,517.619995 
	C217.789062,516.420898 217.169235,517.698547 217.008240,519.635864 
	C216.252686,520.002930 215.505966,520.005737 214.385895,520.008179 
	C213.810547,519.755127 213.579407,519.532715 213.147842,519.170776 
	C208.825302,515.891968 208.154984,511.695374 211.518188,508.759521 
	C212.245331,508.124786 212.790421,508.168121 211.970993,507.140533 
	C209.863800,504.497986 210.112518,501.398895 210.853226,498.392853 
	C212.017838,493.666290 212.130768,489.457336 207.076691,486.717529 
	C205.664261,485.951874 205.343735,484.311646 205.785614,482.766724 
	C206.325455,480.879364 207.661865,481.404114 208.997345,481.833954 
	C210.544846,482.332031 211.910889,481.422699 213.726196,481.032043 
	C214.408310,481.306091 214.617493,481.678345 214.819519,482.413269 
	C215.011215,483.607483 215.328079,484.415344 215.902374,485.503174 
	C216.678375,488.182037 217.442551,490.516388 217.412598,493.370453 
	C218.145599,498.290741 218.466080,502.925354 220.358154,507.591736 
	C220.586853,508.134644 220.678802,508.349487 220.887589,508.889771 
	C221.222534,509.848724 221.380417,510.504211 221.855469,511.385620 
	C222.894257,513.112915 223.533188,514.729553 225.249771,515.931641 
	C226.496002,516.640381 227.622559,516.798584 228.859802,517.560913 
	C229.213898,517.992676 229.299011,518.207520 229.190521,518.709961 
	C228.503082,520.516663 228.009232,522.035828 227.258911,523.779907 
	C226.557709,524.002747 226.112976,524.000549 225.334732,523.997986 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M209.361298,457.079468 
	C209.158508,455.991333 209.504456,455.313690 210.706329,454.955322 
	C213.510452,453.400299 213.630951,450.333038 215.632278,448.154480 
	C215.887619,447.940918 215.956741,448.102814 216.042908,448.084747 
	C216.386581,447.930603 216.644089,447.794556 217.308060,447.818695 
	C216.931442,455.813141 215.861542,463.626495 216.021790,472.022156 
	C214.840317,473.476624 214.257751,472.864532 214.092178,471.651550 
	C213.755600,469.186127 213.197067,466.695709 213.299850,464.239441 
	C213.432434,461.071167 212.247375,458.890259 209.361298,457.079468 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M216.580856,493.879822 
	C215.751938,491.200195 215.354538,488.508850 214.881653,485.415619 
	C214.453537,484.526459 214.218536,484.007050 214.491852,483.122009 
	C214.555069,482.429016 214.487381,481.998505 214.262482,481.278137 
	C214.687088,478.661407 212.676956,475.947906 215.599670,474.197876 
	C216.037277,474.490662 216.159790,474.722107 216.243317,474.822937 
	C218.058655,477.708710 218.283478,480.850922 218.470352,484.426971 
	C218.560257,487.131683 218.636597,489.408936 218.156204,492.089905 
	C217.816437,493.049438 217.473618,493.503479 216.580856,493.879822 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M206.235397,541.641907 
	C205.992981,541.626526 205.860901,541.495300 205.728821,541.364014 
	C205.934448,541.417969 206.140091,541.471985 206.235397,541.641907 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M213.095398,519.219116 
	C213.302795,519.107056 213.569504,519.296692 213.894623,519.788940 
	C213.687149,519.900513 213.421036,519.710266 213.095398,519.219116 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M196.730469,471.183685 
	C197.349686,470.913116 197.817276,470.894897 198.634567,470.977783 
	C198.766968,471.563568 198.549667,472.048279 198.275146,472.796509 
	C196.891037,475.200562 195.725494,473.584473 194.743362,472.774292 
	C193.445908,471.704041 194.293411,470.816284 195.665894,470.032227 
	C196.010956,469.968079 196.192001,470.418213 196.241562,470.655701 
	C196.504333,471.371002 196.436920,471.911346 196.625824,472.394562 
	C196.524658,472.082245 196.509003,471.762726 196.730469,471.183685 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M172.285797,527.086121 
	C172.176224,526.808472 172.359100,526.511902 172.773346,526.081787 
	C173.380295,526.143066 173.755859,526.337952 174.131439,526.532837 
	C173.613708,526.710938 173.095978,526.889038 172.285797,527.086121 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M200.996094,492.992828 
	C200.600296,494.136627 199.800262,494.903046 198.710052,495.513092 
	C197.770432,493.566132 199.211929,493.396393 200.694092,493.023071 
	C201.018387,493.017426 201.002182,493.001770 200.996094,492.992828 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M224.610718,334.370667 
	C224.478638,334.450623 225.041031,335.102051 224.409256,334.577148 
	C224.505539,334.374084 224.580688,334.395691 224.610718,334.370667 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M217.237915,485.227173 
	C216.723465,481.922058 216.461899,478.843353 216.263611,475.344238 
	C218.129333,474.624878 218.101456,473.215027 217.974304,471.938568 
	C217.643509,468.617340 219.296005,467.176483 222.801529,466.937714 
	C223.975128,466.242249 224.371582,465.360779 224.837128,464.181763 
	C226.199722,462.535522 224.393921,460.790741 225.762009,459.186157 
	C231.236160,463.207520 229.598419,469.899139 231.763748,475.237549 
	C232.813477,477.825623 234.082230,479.817749 236.613846,478.414734 
	C240.387405,476.323456 239.950012,478.600067 240.003448,480.904907 
	C240.063934,483.513458 239.273621,485.661224 235.045090,487.031921 
	C242.436340,488.435150 243.054001,493.002563 242.878586,497.448425 
	C242.713394,501.635284 241.659241,505.856079 237.364807,508.099792 
	C236.508087,508.547363 235.925812,509.074219 236.299881,509.977142 
	C236.928238,511.493774 238.486465,509.950256 239.671661,510.878052 
	C240.330475,511.283295 240.468582,511.679260 240.453903,512.140808 
	C240.565643,511.923981 240.545044,511.679230 240.810760,511.221497 
	C244.771637,511.235077 245.045837,507.563507 246.798660,505.645111 
	C247.776245,504.575134 248.072327,502.831268 249.993881,503.263428 
	C251.843094,503.679321 251.663483,505.358154 252.080032,506.792084 
	C253.145355,510.459106 253.274445,513.940796 251.160706,517.282593 
	C250.738007,517.950867 250.008240,518.549683 250.651108,519.471497 
	C253.752502,523.918945 252.824432,528.930115 253.007858,533.649902 
	C252.065170,532.883240 251.214539,532.191467 250.177368,531.235229 
	C247.742493,527.094971 244.111679,524.015259 243.459702,519.213135 
	C243.083206,516.440125 241.053635,515.953186 238.334106,516.140991 
	C236.920486,515.909302 235.543121,516.192810 235.208786,514.723145 
	C234.878433,513.270935 234.575653,512.320496 232.718155,512.958679 
	C231.067795,513.525696 230.830872,514.649414 231.192108,515.995178 
	C231.601807,517.521484 232.154648,518.936157 229.358093,518.990906 
	C228.996948,518.997559 228.998169,518.498779 228.999084,518.249390 
	C227.614166,515.006409 225.242966,512.228638 227.193970,508.334595 
	C227.844299,507.915771 228.262024,507.791046 228.674622,507.950623 
	C229.054962,508.097778 229.188309,508.187531 229.099152,508.129028 
	C229.072067,508.111237 228.938232,508.057465 228.485596,507.714722 
	C224.015900,507.302216 224.991516,509.735962 226.088516,511.891602 
	C227.102203,513.883484 228.265045,515.675354 225.313995,517.132202 
	C222.429581,516.731140 222.509109,514.202820 221.197525,512.434204 
	C220.510086,511.583313 220.527542,510.805725 220.105743,509.812866 
	C219.969025,509.476532 219.990356,509.517914 220.013611,509.519043 
	C218.418198,504.805817 217.756973,499.900269 217.214813,494.618958 
	C217.505219,493.077240 218.284042,492.321899 219.079010,491.483948 
	C222.288116,488.101624 221.922546,486.833252 217.237915,485.227173 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M266.105225,399.697693 
	C265.143707,401.638092 261.923126,401.565338 262.890778,404.720276 
	C263.538635,406.043610 262.698914,406.598663 261.957703,407.502686 
	C260.622864,408.486267 260.347290,409.885712 259.085388,410.871704 
	C255.793884,412.047516 257.048828,414.547516 257.179810,416.416870 
	C257.350800,418.857361 259.759644,416.526306 260.898834,417.955505 
	C260.799652,424.112976 256.670319,428.404602 254.444397,433.864441 
	C253.408524,434.433472 252.729401,434.197723 251.830353,433.494202 
	C250.391068,428.698883 252.412964,424.415771 252.671692,419.654175 
	C252.802170,419.099396 252.859695,418.881042 253.011902,418.343323 
	C253.232819,417.602814 253.340897,417.177155 253.447083,416.419922 
	C253.577255,413.996429 251.824112,413.077362 250.533218,411.551086 
	C250.268814,411.305542 250.254791,411.184204 250.299393,411.407959 
	C250.521530,412.313965 250.243484,412.832977 249.382721,413.326904 
	C244.080719,412.790833 245.305908,417.370758 244.084702,419.686615 
	C243.335480,421.107391 244.031631,423.250793 242.283997,424.834290 
	C240.918625,425.001984 239.853119,424.999573 238.387589,424.993103 
	C235.283051,419.852722 237.343872,414.131836 236.003174,408.417664 
	C237.103775,400.995453 240.599716,394.894104 243.505127,388.584778 
	C244.719177,385.948364 246.965759,385.321808 249.135651,387.497864 
	C250.839569,389.206635 253.448914,390.188507 253.608124,393.671875 
	C255.951920,391.514496 257.097931,388.458130 260.781097,389.538513 
	C261.848328,389.704956 262.359436,390.136810 262.887024,391.050629 
	C263.344177,392.174652 263.484009,393.057983 264.117493,394.096222 
	C265.376862,395.873962 264.841675,398.000916 266.105225,399.697693 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M247.739258,368.195312 
	C250.024948,367.843536 250.478180,366.218353 250.933960,364.711945 
	C251.480087,362.906921 252.436722,361.588287 254.689575,361.084778 
	C255.351028,363.717804 254.071747,365.885132 252.961258,368.115936 
	C252.301987,369.440308 251.435654,370.854156 252.554047,372.287476 
	C253.317383,373.265808 254.423416,372.856750 255.246002,372.303528 
	C256.494202,371.463989 258.921539,371.576019 258.484589,369.232056 
	C257.922455,366.216400 258.078796,365.329041 261.363312,367.086212 
	C263.807922,368.393982 262.215302,364.221558 264.562622,364.179993 
	C265.521088,366.308777 266.338074,368.074341 269.158875,367.471466 
	C271.314819,367.010681 270.715424,368.269623 270.114990,369.711456 
	C272.497284,369.592041 274.220551,371.552277 276.787079,372.091766 
	C277.138062,372.576202 277.109528,372.979004 277.076019,373.686279 
	C277.006836,374.585358 276.805420,375.123566 276.209412,375.791412 
	C276.002441,375.998108 276.000000,376.000000 275.999359,376.001221 
	C272.654083,378.968384 269.897522,382.394958 267.576416,386.226868 
	C266.376648,388.207611 264.816803,390.193298 263.174866,386.378265 
	C262.453156,384.701355 261.122192,386.323730 259.777191,385.968323 
	C258.981598,385.826721 258.562378,385.644165 257.956726,385.122986 
	C257.668640,384.650421 257.603088,384.435760 257.575256,383.891052 
	C257.342865,383.097321 258.445984,383.501343 257.830078,383.207336 
	C257.444031,383.882782 257.159576,384.219513 256.490173,384.621368 
	C252.695953,384.941345 253.394791,382.318115 253.247101,380.327271 
	C252.598389,380.276093 252.226669,380.650818 252.024063,381.074585 
	C250.978989,383.260345 251.600037,387.379791 246.939362,385.186127 
	C241.771179,382.753510 240.067551,379.618256 242.021317,375.630249 
	C242.525970,374.600159 243.132706,373.611389 243.806473,372.280579 
	C244.183609,372.002014 244.351349,371.996521 244.770828,371.988037 
	C245.179230,372.118103 245.335907,372.251160 245.723358,372.179749 
	C246.463043,370.781616 246.971939,369.587891 247.739258,368.195312 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M243.705872,372.158569 
	C243.986862,372.832611 244.016708,373.664886 243.916992,374.481323 
	C243.683167,376.395844 240.724335,378.479248 242.402161,379.754333 
	C244.740601,381.531433 246.264053,384.816406 250.364258,384.915314 
	C249.781799,382.959412 248.522339,380.734528 251.099869,379.586090 
	C252.544922,378.942261 253.784241,376.669312 255.284790,377.676056 
	C256.998505,378.825714 254.896942,380.189606 254.406006,381.454803 
	C253.715195,383.235077 254.609375,383.887909 256.624420,383.975433 
	C257.160278,383.844269 257.329895,383.700104 257.746704,383.780426 
	C258.110535,384.317474 258.300507,384.576263 258.775574,384.900848 
	C260.336761,386.185211 261.295105,387.543427 261.128723,389.799561 
	C258.776123,391.244171 256.422363,391.887787 255.153595,394.783234 
	C254.572418,396.109558 251.079086,395.445831 251.402924,393.329102 
	C251.699677,391.389343 251.254471,391.521149 249.992416,390.572296 
	C245.469330,387.171692 245.633377,387.002777 243.383606,392.166443 
	C241.139786,397.316345 238.832123,402.438385 236.276886,407.789978 
	C235.304535,407.112305 234.253571,406.310883 233.986893,405.302155 
	C233.478683,403.379913 229.908203,404.707855 230.667664,402.549957 
	C231.697220,399.624481 233.199692,403.098877 234.566803,402.802582 
	C235.852570,402.523895 236.338867,401.248779 235.604233,400.627075 
	C230.772476,396.538025 234.028778,392.022675 235.156464,387.990540 
	C236.754166,382.277802 237.957260,376.106293 243.705872,372.158569 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M232.587723,440.034058 
	C232.422821,442.516602 235.139297,443.927338 234.993103,446.625916 
	C232.086929,447.044952 229.993973,447.809509 229.860535,451.376373 
	C229.789368,453.278870 228.971848,455.211700 226.394165,455.956543 
	C223.190384,454.069153 224.563583,452.016724 226.318283,450.613708 
	C227.584732,449.601135 228.540131,448.014404 227.322311,447.667450 
	C222.046875,446.164551 224.542007,442.114136 224.084824,439.077637 
	C223.971039,438.322052 224.248077,437.370911 224.658508,436.701599 
	C225.513275,435.307587 223.913177,432.235687 226.723801,432.485596 
	C228.853836,432.674957 227.996582,435.242310 227.942963,436.723480 
	C227.824860,439.985138 229.830780,440.111359 232.587723,440.034058 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M215.594025,447.834259 
	C215.091537,450.609863 216.307343,454.472687 211.370209,454.874969 
	C211.299164,451.785553 209.705368,447.577332 215.594025,447.834259 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M236.637421,426.063446 
	C237.786255,428.817352 238.261627,431.537140 234.349930,432.959381 
	C234.018539,430.465668 234.114502,427.956512 236.637421,426.063446 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M217.851227,447.653015 
	C217.450760,448.002411 217.186996,448.025970 216.526154,448.058105 
	C216.390015,444.985046 216.863754,441.963715 218.708359,438.771973 
	C219.542603,442.066559 217.851242,444.625763 217.851227,447.653015 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M224.675842,463.961243 
	C224.929031,465.062592 227.897552,467.285400 223.559235,466.945923 
	C223.088394,465.738068 223.112061,464.624664 224.675842,463.961243 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M229.577164,392.042938 
	C229.191605,392.748993 228.803131,392.756287 228.459595,392.253143 
	C228.387543,392.147644 228.389557,391.903748 228.464096,391.800873 
	C228.821869,391.307190 229.210175,391.329468 229.577164,392.042938 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M231.386581,412.944153 
	C231.793610,412.204987 232.207321,412.177032 232.577530,412.710419 
	C232.653030,412.819183 232.642395,413.078644 232.562088,413.189178 
	C232.188568,413.703278 231.777878,413.712585 231.386581,412.944153 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M245.721268,371.978729 
	C245.697693,372.088013 245.441208,372.200684 245.103638,372.149231 
	C245.022552,371.985077 245.488373,371.982117 245.721268,371.978729 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M243.195572,356.872925 
	C243.144287,356.704742 243.311188,356.416931 243.708527,356.027344 
	C243.860229,356.262573 243.685150,356.538513 243.195572,356.872925 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M219.696320,437.323669 
	C219.684174,437.600342 219.489655,437.661102 219.295135,437.721863 
	C219.368073,437.517151 219.441010,437.312439 219.696320,437.323669 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M196.734879,470.913940 
	C196.754333,471.464813 196.626541,471.998322 196.498749,472.531799 
	C196.373795,472.183777 196.248856,471.835785 196.207520,471.190491 
	C196.291122,470.893188 196.587631,470.896515 196.734879,470.913940 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M251.986664,292.009003 
	C252.883698,292.327423 253.872864,292.526276 254.243637,293.636047 
	C253.065338,294.182159 252.357574,293.800110 252.035950,292.280487 
	C252.030884,291.967316 252.002441,291.996307 251.986664,292.009003 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M328.800598,582.127563 
	C323.397736,574.753479 322.732147,566.263489 322.398132,557.297852 
	C321.726288,556.715881 321.490997,556.338257 321.424377,555.511475 
	C322.115875,553.429993 322.641266,551.544617 321.557312,549.943542 
	C319.819214,547.376343 320.036499,544.667053 320.417938,541.558716 
	C320.770294,540.492737 321.237610,539.846619 321.818176,538.976074 
	C321.955292,538.765564 322.018066,538.333740 322.018066,538.333740 
	C322.018066,538.333740 321.560760,538.421387 321.285309,538.470093 
	C320.367645,538.421753 319.934357,538.056885 319.647614,537.155396 
	C321.288879,533.706665 318.456421,531.801453 317.444000,529.044922 
	C316.844727,527.245178 317.166046,525.881714 318.138916,524.296631 
	C319.580048,522.753235 319.298553,521.309937 318.002441,520.677429 
	C315.325073,519.370728 314.646759,517.214417 314.629547,514.582336 
	C314.615112,512.369263 313.487915,510.877747 311.690247,509.732300 
	C309.278564,508.195587 309.609131,506.674408 311.800171,505.207306 
	C312.904907,504.467590 313.788574,503.458832 314.815125,502.627563 
	C320.169617,498.291534 325.181427,499.945038 325.509155,506.891296 
	C325.742310,511.832489 328.122131,516.311096 327.470520,521.407959 
	C327.099182,524.312500 330.724060,525.708801 332.347900,528.368164 
	C334.317322,531.342957 335.051392,534.435547 335.991791,537.444519 
	C348.387970,577.109009 371.563171,608.441895 407.451355,629.964722 
	C409.032440,630.912903 410.806610,631.416260 412.646301,632.537109 
	C413.354462,633.216492 412.797821,633.932068 413.464233,634.618042 
	C414.062408,635.467957 414.582855,635.966858 415.658752,636.161499 
	C416.370392,636.304443 416.761597,636.458557 417.392456,636.875488 
	C420.603516,641.553467 423.623718,645.926941 428.708313,648.316284 
	C429.911163,648.881470 430.851105,649.906006 431.392670,651.181763 
	C431.811218,652.167664 431.801483,653.179504 431.025879,653.967285 
	C430.384979,654.618225 429.440765,654.710754 428.724030,654.206848 
	C425.499023,651.939575 421.329315,652.371399 418.000732,650.304932 
	C415.950562,649.032043 414.508179,646.961121 411.216156,650.217407 
	C408.270721,653.130859 403.770081,649.562805 400.436035,647.490845 
	C397.925323,645.930542 396.753418,642.417664 392.639648,643.193970 
	C391.032837,643.497192 391.085144,641.059692 390.940460,639.585510 
	C390.581482,635.927673 388.062500,633.477661 385.888855,630.867981 
	C385.484192,630.382080 384.617737,630.730530 383.611572,630.835144 
	C381.820770,630.769714 380.582520,630.087769 378.984528,629.412842 
	C376.839172,628.512512 374.900848,629.629578 372.584442,628.536316 
	C373.639252,629.522644 374.453003,629.532837 375.440491,630.033264 
	C376.843109,630.896179 377.584503,631.813110 377.565430,633.526489 
	C375.566498,636.176697 375.492188,638.310791 377.988190,640.287476 
	C378.897430,641.007507 379.787781,641.785278 380.320038,642.847778 
	C380.881195,643.967957 380.973755,645.153198 380.021301,646.074951 
	C379.062836,647.002502 377.853638,646.918701 376.810547,646.206238 
	C375.310455,645.181763 374.051300,643.796509 371.931152,643.078674 
	C371.407288,642.804993 371.206238,642.677429 370.752869,642.294556 
	C370.384613,641.829590 370.268646,641.619873 370.078796,640.702637 
	C369.978058,639.831177 370.011902,639.588745 369.915771,639.514465 
	C362.051758,633.436462 357.172302,624.949707 351.630463,616.984680 
	C349.031555,613.249390 347.274445,609.129272 346.223450,604.803955 
	C345.325500,601.108521 344.208557,598.009827 340.639862,595.956177 
	C337.625488,594.221436 337.198273,590.372314 335.160675,587.789734 
	C334.282410,586.676514 333.653351,583.650818 331.139954,586.758911 
	C329.507538,587.454163 328.902679,586.523071 328.441833,585.038940 
	C328.193604,584.045593 329.587006,584.094666 328.859985,583.090454 
	C328.631805,582.693665 328.603668,582.502258 328.800598,582.127563 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M322.141205,554.705200 
	C322.053436,555.452942 322.063477,555.889282 322.106232,556.651245 
	C319.016052,558.979431 317.291595,557.449890 316.173370,553.593445 
	C314.008453,557.495483 316.531830,559.992554 316.957428,562.630310 
	C317.714905,567.324829 317.851471,572.189087 321.764008,576.032532 
	C323.339905,577.580627 326.820099,581.610291 320.239197,582.275330 
	C318.891937,582.411438 319.698944,583.815674 319.748993,584.745972 
	C319.920380,587.929749 321.835022,590.569763 322.285461,593.755249 
	C322.665833,596.445374 322.001587,597.398193 319.366150,597.964844 
	C318.893097,597.188049 319.071625,596.116577 318.618591,595.610840 
	C315.269348,591.871887 314.159637,587.652100 313.366516,582.601379 
	C312.350800,576.133606 308.858826,570.052307 306.412140,563.812927 
	C305.475861,561.425293 303.793182,559.236084 304.813538,556.209351 
	C307.247192,553.412415 304.528839,551.533936 303.839172,549.139282 
	C303.070801,547.706299 302.592834,546.455322 302.178467,544.867371 
	C302.360657,537.086060 305.544952,529.854553 303.714996,522.255981 
	C303.336212,520.683228 303.578613,518.987549 304.488037,517.117065 
	C305.311340,516.264893 306.033569,515.994385 307.200195,515.832397 
	C310.595154,516.009888 311.847809,517.837280 312.045929,520.638123 
	C313.267273,517.490784 315.127594,519.171692 316.929779,519.947693 
	C318.466278,520.609253 319.199249,521.748657 319.194153,523.696411 
	C318.709625,525.445984 318.367065,526.852966 318.022247,528.626404 
	C316.986176,531.822266 318.277557,534.112305 319.840454,536.690552 
	C320.433746,537.345032 320.916748,537.673889 321.705322,538.011597 
	C322.173462,537.897522 322.336060,537.774719 322.746521,537.830811 
	C322.868134,539.110046 323.156128,540.407410 321.305969,540.910522 
	C314.995728,543.721252 319.385376,546.899841 320.588074,550.274597 
	C320.572754,551.454468 319.391541,552.444458 321.155884,553.268799 
	C321.790894,553.670227 322.053497,554.000305 322.141205,554.705200 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M370.828217,640.836426 
	C371.004974,640.992126 371.043610,641.474854 371.057251,641.716553 
	C371.152313,644.826904 373.274231,646.028503 375.466400,646.885010 
	C377.144287,647.540527 378.976379,647.813293 380.331909,649.122009 
	C381.427155,650.179382 382.170929,649.949158 383.141205,648.830994 
	C385.481842,646.133789 387.326874,646.859253 388.654663,650.243164 
	C388.527374,651.575378 388.119720,652.453491 387.506622,653.207825 
	C385.249939,655.984375 386.992889,656.700256 389.728271,657.274048 
	C390.439362,658.042175 390.514404,658.703186 390.522705,659.709290 
	C390.466156,660.685669 390.516479,661.334656 390.507507,662.311646 
	C390.418610,662.850464 390.355011,663.061523 390.139526,663.565308 
	C388.719208,665.459656 390.464813,669.469604 385.861145,668.548645 
	C385.382294,668.352783 385.203827,668.254517 384.781006,667.992554 
	C384.372101,667.739502 384.207520,667.650146 383.790283,667.471375 
	C382.982758,667.012390 383.951477,667.048096 383.303894,667.127380 
	C383.034424,667.160400 383.373749,667.059143 383.538757,667.319702 
	C384.097198,667.701843 384.338867,667.996826 384.532532,668.685547 
	C384.469543,669.692322 384.106354,670.226013 383.303314,670.854370 
	C382.471252,671.133972 372.076599,669.945068 371.493225,669.430786 
	C371.330780,668.629028 371.414154,668.168274 371.634430,667.706299 
	C371.772125,667.417603 371.756683,667.299622 371.804504,667.455688 
	C371.999939,668.093079 370.691528,667.789978 371.211975,667.890442 
	C370.883148,668.211975 370.673676,668.245911 370.162537,668.163940 
	C368.237671,666.768494 367.832611,664.724243 366.533264,662.861328 
	C364.827271,660.978699 363.152252,659.557678 361.065918,658.106812 
	C360.133331,657.338806 359.541718,656.690979 358.674652,655.858765 
	C357.823761,655.015930 357.235535,654.373169 356.426453,653.482544 
	C355.696625,652.469055 355.333466,651.669250 355.717590,650.431213 
	C358.260681,649.793091 360.441925,650.030945 362.472504,651.547302 
	C363.393707,652.235291 364.799774,652.346252 365.558075,651.158936 
	C366.238220,650.093933 365.200653,649.497742 364.560272,648.722961 
	C362.381561,646.087036 365.485321,643.735596 365.433716,641.100708 
	C365.966339,640.748596 366.237976,640.504944 366.780273,640.153564 
	C367.756470,639.994812 368.505859,639.992615 369.630066,639.992737 
	C370.004883,639.995117 370.000000,640.000000 370.002075,639.998413 
	C370.206909,640.237122 370.422699,640.465088 370.828217,640.836426 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M279.371979,580.981079 
	C276.921265,573.711792 274.830261,566.444397 272.441925,558.143555 
	C272.111267,560.724670 271.906036,562.326904 271.598053,564.731140 
	C270.250183,559.109680 269.848724,554.264099 269.907837,549.343323 
	C269.934448,547.130432 269.736694,547.087708 267.647827,546.869507 
	C266.495209,546.749207 267.085388,545.902893 267.008545,545.426147 
	C266.596191,542.867493 265.938690,540.459229 266.712860,537.667297 
	C267.487976,534.872070 268.002167,531.696228 265.827942,528.865479 
	C261.570709,523.322815 262.723511,517.021912 264.064514,510.190613 
	C264.714813,509.540894 264.940582,509.496521 265.503479,509.522217 
	C266.024445,509.728241 266.208221,509.864166 266.577332,510.286560 
	C267.260406,511.803772 267.464813,513.143127 268.290039,514.608154 
	C268.717987,516.485657 269.511017,517.822144 270.489258,519.452026 
	C269.268951,527.186401 274.043427,533.415833 274.530914,540.472229 
	C275.028900,547.680603 277.233765,554.584167 278.373383,561.675232 
	C278.680847,563.588196 279.809265,562.204468 280.738953,562.786133 
	C282.330688,564.575806 285.371063,562.254333 285.675812,564.308167 
	C286.070862,566.970093 287.158295,569.770752 286.186005,572.534241 
	C284.728760,576.676270 285.383667,580.523560 287.696228,584.117615 
	C288.354431,585.140564 289.091125,585.989319 288.339996,587.228821 
	C288.029205,587.741699 287.737915,588.626526 286.988312,587.890442 
	C286.227234,587.143005 285.340454,586.596985 285.314362,585.100891 
	C285.252991,581.585205 283.712433,579.475281 279.371979,580.981079 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M270.855560,436.355774 
	C272.242767,436.610535 271.761993,438.602692 273.687805,438.960785 
	C276.635925,442.646759 274.948486,446.494781 274.555939,450.273651 
	C274.312164,452.620605 273.149597,455.151642 276.192017,457.063538 
	C276.489410,457.746521 276.406921,458.108948 276.330109,458.264648 
	C276.924591,457.870422 277.297211,457.735443 277.995911,457.838928 
	C279.276764,459.063995 281.449493,459.436554 280.437439,461.988251 
	C279.839874,462.818115 279.286774,463.221954 278.429443,463.802643 
	C275.215149,466.562378 275.381042,470.118225 275.278046,473.493347 
	C275.207550,475.805115 275.466888,478.119141 274.811249,480.688293 
	C274.933228,481.013916 274.944763,481.505829 274.953583,481.751709 
	C275.015900,484.111023 274.996735,486.224518 274.979248,488.338226 
	C274.957458,490.974487 274.013153,492.768707 270.638062,492.601379 
	C268.128387,487.334320 269.974396,482.073059 269.792267,476.874969 
	C269.746826,475.578369 270.574890,474.303802 269.949219,473.117798 
	C270.405701,472.381622 269.270294,472.811188 269.927338,472.924042 
	C270.159210,472.963898 270.046631,473.083435 269.894135,473.420532 
	C269.555817,474.168365 269.295990,475.196594 268.311157,474.943420 
	C266.996002,474.605286 266.689575,473.418823 266.809326,472.157349 
	C267.131714,468.761383 267.024933,465.367523 267.044647,461.977356 
	C266.301910,476.365936 266.520264,490.774048 265.809540,505.160522 
	C265.736053,506.647736 265.474640,508.140076 264.268799,509.674286 
	C264.032593,509.967743 263.999695,510.000427 263.982910,510.017334 
	C259.569550,509.531769 260.795013,505.681061 261.162994,503.706879 
	C261.551056,501.625214 262.005371,499.551208 261.938690,497.448151 
	C261.571381,485.858643 262.580658,474.364624 264.026367,462.884125 
	C264.561462,458.635040 267.692017,455.064026 267.077484,450.557831 
	C266.379822,445.441986 270.423676,441.567657 270.855560,436.355774 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M286.985809,504.621399 
	C288.667999,512.523010 290.480927,520.007446 291.914215,527.563782 
	C292.495178,530.626648 293.279327,533.701599 293.124756,536.924683 
	C293.057678,538.323059 293.501465,540.566528 294.428040,541.046692 
	C298.021454,542.908813 297.978729,546.304443 298.647675,549.414368 
	C299.297668,552.435974 302.225128,555.427856 298.275238,558.578125 
	C297.224579,559.416138 298.044495,561.938232 299.068848,563.672546 
	C302.645172,569.727600 304.230835,576.581665 306.541290,583.144226 
	C307.053711,584.599670 307.249939,586.049438 306.283447,587.779053 
	C302.534332,584.855408 301.996429,580.173584 300.273376,576.141174 
	C295.776520,565.617065 293.058258,554.572754 291.849762,543.163757 
	C291.450012,539.389954 291.925659,535.600281 288.166382,532.477783 
	C286.494965,531.089478 288.135651,526.887329 287.556976,523.885376 
	C286.275238,517.235901 283.754211,510.788666 284.014282,503.426727 
	C285.612610,501.960510 286.364929,502.915985 286.985809,504.621399 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M371.827271,667.848022 
	C374.807465,670.749817 375.101929,670.677185 377.723724,666.680603 
	C379.589600,667.349121 378.405609,672.389526 382.710846,670.025024 
	C386.373352,673.863770 390.848083,676.113342 395.133850,678.611816 
	C396.996094,679.697449 398.957153,680.614075 400.590942,682.589417 
	C401.096191,683.248779 401.263611,683.768799 401.626740,684.049072 
	C406.828979,688.064270 406.834625,688.056946 400.754364,691.171936 
	C399.541199,690.952332 399.359009,690.322083 399.401245,689.448975 
	C399.432678,688.799072 399.571594,689.668152 399.358856,689.038208 
	C399.231171,688.660034 399.037750,688.754700 398.653625,689.346436 
	C394.213074,688.530762 391.131683,685.450195 387.213226,684.062500 
	C385.350281,683.402832 383.662567,682.208191 381.212524,682.957397 
	C380.362823,682.882935 379.843994,682.717468 379.155884,682.332397 
	C377.151581,677.104126 372.143646,675.938599 368.123718,673.051575 
	C367.779633,672.578796 367.617523,672.401428 367.267883,672.162720 
	C366.904999,671.927856 366.699677,671.781616 366.746216,671.353516 
	C366.988159,670.317200 366.978333,669.562805 366.953918,668.431519 
	C368.213501,667.028870 369.302094,668.233398 370.730957,668.163086 
	C370.980621,667.976868 371.000122,667.997559 370.995789,667.983459 
	C371.199982,667.822205 371.408569,667.674988 371.827271,667.848022 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M269.816254,492.371002 
	C273.018463,492.144897 274.090118,490.620422 274.025726,487.833801 
	C273.985382,486.086487 274.276672,484.331482 274.690796,482.288757 
	C277.162170,487.410980 276.016052,493.059540 275.766296,498.548859 
	C275.442291,505.668976 273.345276,512.747498 275.918915,519.850403 
	C276.132843,520.440857 276.026733,521.166687 275.990875,521.825317 
	C275.425629,532.203125 277.063293,542.289307 279.995667,552.600098 
	C279.537537,553.392639 279.000702,553.541382 278.007202,553.240540 
	C274.119171,546.376526 274.023529,538.821350 272.174866,531.724121 
	C271.115631,527.657715 270.244324,523.382446 270.901459,518.629761 
	C268.926697,509.781250 269.258240,501.239624 269.816254,492.371002 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M369.992920,689.655518 
	C366.008514,697.070984 364.610474,697.820557 358.380768,696.031128 
	C356.425690,693.309021 355.056824,690.541687 354.406250,687.130493 
	C350.262421,685.872803 348.679108,683.456848 349.790924,679.236206 
	C353.378571,681.093933 356.649750,683.464417 360.108643,678.878174 
	C361.099548,677.564331 362.708221,678.582397 363.479462,679.659851 
	C365.729279,682.803040 367.802002,686.072998 369.992920,689.655518 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M287.368530,505.009033 
	C286.217773,504.365540 285.427917,503.721405 284.329407,503.031219 
	C284.013184,495.302246 284.005615,487.619324 283.997192,479.467590 
	C285.059753,474.378204 286.123169,469.757599 287.186584,465.137024 
	C287.459503,465.162201 287.732391,465.187378 288.005280,465.212585 
	C288.005280,475.220795 287.958588,485.229340 288.037262,495.236938 
	C288.057617,497.824677 287.424896,500.507080 289.212799,502.939209 
	C290.316925,504.441193 289.091949,504.927124 287.368530,505.009033 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M350.001129,678.987610 
	C350.349091,681.937622 351.670166,684.361755 354.089661,686.534302 
	C351.763580,687.906860 349.409210,688.749634 348.783264,691.910522 
	C348.324402,694.227722 346.444061,694.319214 344.212555,693.061035 
	C343.734192,692.746277 343.589294,692.586487 343.205170,692.208740 
	C342.965973,691.990845 343.001007,691.994507 342.998047,692.012939 
	C343.381500,690.607849 341.905701,688.069397 345.386658,688.552307 
	C347.518951,688.848083 346.788940,686.881287 346.990662,685.360962 
	C347.183777,684.804749 347.377838,684.618591 347.568939,684.429382 
	C347.760132,684.240112 347.697632,684.304382 347.196777,684.817627 
	C341.184357,685.669495 342.000732,681.533081 341.967010,677.590820 
	C340.821625,675.890503 339.698792,674.603394 340.612976,672.282104 
	C344.289734,670.596191 345.417908,673.070984 346.740051,675.789001 
	C347.411743,676.419617 347.475433,677.026611 347.856262,677.803894 
	C348.050446,678.027100 347.976959,678.023376 347.974609,677.986694 
	C348.394897,678.419006 348.991150,678.500061 349.793213,678.829590 
	C350.033020,679.001892 350.000763,679.005005 350.001129,678.987610 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M271.834442,517.798828 
	C272.243439,529.713318 274.537811,541.049377 278.225830,552.539795 
	C278.781128,553.729858 279.286316,554.514526 279.747070,555.711609 
	C280.091980,556.502625 280.344360,556.956360 280.711182,557.643311 
	C280.753143,558.077148 280.680695,558.277832 280.819672,558.718689 
	C281.031067,558.958923 281.015198,559.000610 281.031769,558.984619 
	C281.397400,559.374817 281.549805,559.844849 281.351624,560.729614 
	C281.144135,561.506165 281.090637,561.931763 281.030151,562.679199 
	C279.038666,562.624451 279.385376,565.208923 278.030640,565.506958 
	C275.069641,555.224365 274.132111,544.614685 271.781921,534.292053 
	C270.673340,529.422913 267.371490,524.937439 269.827698,519.203369 
	C269.890015,518.367310 270.033356,518.003052 270.663269,517.607666 
	C271.172455,517.539246 271.381134,517.568909 271.834442,517.798828 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M332.103607,596.668823 
	C330.279388,599.334167 332.336761,600.490051 333.589630,601.505798 
	C336.092438,603.534790 338.123840,605.637146 339.194611,608.880737 
	C339.648773,610.256409 341.688110,607.346863 343.202301,608.574707 
	C341.768433,614.892761 341.775208,614.874878 335.690857,612.569641 
	C335.137848,612.360046 334.406006,612.481628 333.788818,612.606079 
	C330.729950,613.222595 329.791382,612.005249 330.461975,609.058105 
	C330.949829,606.914124 331.971954,604.865967 328.901093,603.132629 
	C328.000488,602.624329 328.111725,598.544434 329.743347,596.139404 
	C330.819092,595.383911 331.526764,595.548828 332.103607,596.668823 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M370.746704,667.778870 
	C369.964294,669.472534 368.768341,668.901611 367.253387,668.164062 
	C366.149139,667.788818 365.358917,667.523010 364.275726,667.137695 
	C363.478363,666.455688 362.931396,665.935547 362.178711,665.221191 
	C357.192596,662.648804 353.803314,658.838623 351.017700,654.031982 
	C349.859833,651.839478 350.919342,650.649414 352.576630,649.364258 
	C353.771820,649.031494 354.556122,649.122375 355.592987,649.808167 
	C355.891632,650.078796 355.909729,650.105469 355.902283,650.125549 
	C356.189392,650.892822 356.484009,651.640076 356.893188,652.672607 
	C357.372864,653.715576 357.637787,654.567505 358.947357,654.840454 
	C359.741455,655.347351 360.245453,655.722168 360.891113,656.288330 
	C361.812775,659.369568 363.510437,661.324829 366.904358,661.897095 
	C369.726654,662.990417 369.110901,665.863953 370.746704,667.778870 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M347.692963,639.847412 
	C347.978058,640.775513 347.959808,641.554138 347.911926,642.721069 
	C347.508667,643.387024 347.089294,643.534241 346.294312,643.466370 
	C345.376953,643.030090 344.920654,642.529175 344.207886,641.810425 
	C342.140320,639.884583 339.904205,639.785339 337.261841,639.973206 
	C336.162598,639.702942 335.405518,639.393372 334.306091,639.027710 
	C334.820831,636.233337 331.517761,632.191711 335.878052,630.721252 
	C339.724915,629.423828 340.197754,633.349060 341.867279,635.591248 
	C343.179535,637.353638 345.510895,638.357239 347.692963,639.847412 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M275.092010,480.728912 
	C272.612244,475.180450 273.962494,469.456329 275.540802,463.767975 
	C275.643890,463.396393 276.636963,463.271790 277.608643,463.017395 
	C279.803986,464.643463 279.129425,466.869354 279.438660,468.878510 
	C279.688721,470.503235 278.480774,472.445557 280.711121,473.862366 
	C281.005493,475.384644 281.005310,476.772186 281.008667,478.575592 
	C280.839203,479.426147 280.666168,479.860840 280.493134,480.295532 
	C280.142212,479.954437 279.791290,479.613342 279.018616,479.098663 
	C278.596832,478.925049 278.502167,478.530457 278.251434,478.764618 
	C276.837585,479.011444 275.904358,479.461609 275.092010,480.728912 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M305.836121,589.176025 
	C308.075226,590.106323 309.331696,591.739136 309.036804,594.619385 
	C307.270142,595.203003 306.753662,596.206665 307.424866,597.772949 
	C308.588226,600.487854 308.706757,603.656128 310.902161,605.919250 
	C311.654175,606.694458 312.263672,607.528870 311.316650,608.441284 
	C310.512665,609.215759 309.323456,609.179382 308.600586,608.443420 
	C307.208893,607.026550 304.883423,606.375610 305.366150,603.421082 
	C305.940216,599.907654 302.714691,596.165100 305.933472,592.351746 
	C305.114838,591.858215 303.777802,592.350647 303.587341,591.080872 
	C303.396423,589.808167 305.060669,590.154968 305.836121,589.176025 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M293.328552,617.699829 
	C294.464111,619.530640 293.809479,622.107910 296.660248,622.397461 
	C298.342773,622.568359 298.154602,624.053406 297.596954,625.059265 
	C296.909698,626.298889 295.463593,626.278076 294.647583,625.407776 
	C293.143494,623.803711 291.423279,622.765198 289.439636,621.857849 
	C287.686676,621.055908 286.137970,619.544678 286.476349,617.312317 
	C286.666046,616.060486 287.328827,614.627441 288.737823,614.295532 
	C291.210114,613.713196 292.164062,615.651611 293.328552,617.699829 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M346.297974,642.818359 
	C346.870087,642.911133 347.160797,642.936890 347.662384,643.087280 
	C347.882324,643.109375 347.899323,643.071289 347.902618,643.076294 
	C348.531036,643.525085 349.046051,644.085510 349.714996,644.885681 
	C349.867798,645.126648 350.002228,645.007324 349.936981,645.070068 
	C351.247864,646.342957 352.918335,647.286316 353.556396,649.527588 
	C352.122192,650.427856 351.571411,651.699402 351.037720,653.337097 
	C346.269287,651.575256 344.388153,646.342163 339.994873,643.428711 
	C340.162048,642.723999 340.518066,642.441101 341.306824,642.234741 
	C343.097565,642.402039 344.510956,643.003479 346.297974,642.818359 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M332.325989,596.836426 
	C331.565796,596.737854 331.094208,596.396240 330.320435,596.028503 
	C328.635559,592.133484 327.414917,588.196289 325.823730,584.415100 
	C324.285461,580.759521 328.134918,583.414490 328.718506,581.977905 
	C329.054596,582.110718 329.083588,582.277100 329.156006,582.692993 
	C329.324127,583.127441 329.448822,583.312378 329.391876,583.778137 
	C329.036957,585.222473 329.501526,586.092590 330.721252,586.896790 
	C331.905792,590.169189 333.105469,593.238098 332.325989,596.836426 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M367.855988,673.072937 
	C372.423126,672.676636 375.078552,676.453247 378.798401,678.022888 
	C380.488861,678.736328 379.834686,680.190857 379.126404,681.774658 
	C374.999573,680.118774 371.767792,677.064697 367.970734,674.195801 
	C367.743774,673.751282 367.771973,673.556824 367.855988,673.072937 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M342.232056,642.817627 
	C341.516937,643.025208 341.049957,643.035522 340.233337,643.077515 
	C339.883667,643.109192 339.464966,642.990051 339.251312,642.948486 
	C338.236420,642.797974 337.697174,642.333008 337.274841,641.275574 
	C337.227203,640.906616 337.097504,640.451294 337.008575,640.231934 
	C339.676727,635.932800 342.183197,639.432922 344.927368,640.611694 
	C344.565399,642.058044 343.453705,642.231689 342.232056,642.817627 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M274.391235,607.776123 
	C276.257538,612.689331 271.363342,610.711609 269.766663,612.571350 
	C268.489807,614.058716 269.552155,609.956787 267.304565,610.044922 
	C267.001221,610.000000 267.000000,610.000000 266.999390,610.000000 
	C266.620972,609.613525 266.474945,609.153381 266.780182,608.309875 
	C269.189545,606.961060 271.508728,607.791260 274.391235,607.776123 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M289.425079,628.374207 
	C288.773804,627.847473 288.453827,627.411743 288.025543,627.226379 
	C286.985138,626.776062 285.601746,626.642761 285.500488,625.232849 
	C285.423553,624.161194 286.463959,623.950928 287.276733,624.111206 
	C288.855072,624.422363 290.809235,624.463623 291.592651,626.017456 
	C292.302917,627.426147 291.446075,628.530090 289.425079,628.374207 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M353.857208,649.910767 
	C352.377228,648.510254 351.182404,647.145081 349.929626,645.456360 
	C351.854401,646.649719 356.883972,643.782227 355.953491,649.740479 
	C355.308533,650.034607 354.725464,649.990356 353.857208,649.910767 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M278.248230,604.061340 
	C278.005646,602.981323 278.016693,601.964844 278.058289,600.567993 
	C280.358490,598.786560 279.812836,600.803528 280.327301,601.996582 
	C281.220612,602.283752 281.490204,602.669556 281.098877,603.626465 
	C280.110260,604.774353 279.293152,604.294495 278.248230,604.061340 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M273.883881,603.034302 
	C275.036499,603.152649 275.829437,603.331299 276.813049,603.753784 
	C275.521301,604.828796 275.868591,606.417053 275.251404,607.859253 
	C275.030121,608.020630 274.993408,607.998596 274.974640,607.988586 
	C273.476288,607.435730 272.416229,606.547424 272.438538,604.471558 
	C272.758453,603.682251 273.090271,603.337769 273.883881,603.034302 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M340.568970,671.910522 
	C341.131683,673.381836 341.526215,674.883484 341.946014,676.776978 
	C341.971283,677.168762 341.477631,677.094543 341.229614,677.066406 
	C340.607361,676.534058 340.016541,676.506348 339.272583,676.163635 
	C338.928680,675.660583 338.722107,675.409119 338.222656,675.108154 
	C337.963318,673.762451 338.812958,673.117676 339.870392,672.224915 
	C340.059967,671.964844 340.400848,671.940857 340.568970,671.910522 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M284.829468,620.209534 
	C284.919769,621.507874 284.989899,622.699402 283.607666,622.510803 
	C282.580231,622.370667 281.958679,621.363464 282.255432,620.340393 
	C282.696564,618.819702 283.677277,618.849365 284.829468,620.209534 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M336.945435,641.099121 
	C337.774597,641.394043 338.321991,641.881470 338.953552,642.637878 
	C338.381409,643.800781 337.554230,644.251099 336.604980,643.441162 
	C335.848877,642.796021 336.134735,641.997375 336.945435,641.099121 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M280.098022,555.893311 
	C278.393677,555.853577 277.907745,554.986450 278.223999,553.272278 
	C278.685425,552.947693 279.061035,552.968811 279.720764,552.991394 
	C280.264343,553.889648 281.263275,554.691711 280.098022,555.893311 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M376.305664,693.671021 
	C374.634003,694.720642 373.059937,695.306091 373.006104,692.154602 
	C373.432465,691.860168 373.860077,691.931458 374.611572,692.027954 
	C375.485596,692.395874 376.039459,692.734436 376.305664,693.671021 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M330.859375,657.642151 
	C330.171600,657.175232 329.508759,656.743958 329.421234,656.217163 
	C329.322357,655.621826 330.035828,655.619751 330.457062,655.861694 
	C331.125641,656.245605 332.149261,656.575073 330.859375,657.642151 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M287.481384,612.291992 
	C287.104126,611.412842 287.125488,610.740723 287.893433,610.564819 
	C288.126801,610.511414 288.716095,611.047485 288.769989,611.374878 
	C288.884705,612.071106 288.547485,612.602295 287.481384,612.291992 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M281.111694,604.037964 
	C280.772614,603.538086 280.727661,603.143616 280.627136,602.456177 
	C281.516510,602.107422 282.566010,602.002747 282.807343,603.226501 
	C283.045929,604.436218 282.256958,604.417358 281.111694,604.037964 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M376.560120,693.991516 
	C375.918335,693.519531 375.564728,693.045288 375.073334,692.312134 
	C376.092712,692.050598 377.351013,691.810547 377.939880,693.644409 
	C377.621460,693.986938 377.234863,693.988159 376.560120,693.991516 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M280.429871,528.757324 
	C280.712799,527.892334 281.156250,527.290955 281.599731,526.689514 
	C281.729309,527.125854 281.978607,527.566528 281.962860,527.997559 
	C281.932953,528.816711 282.180176,530.216614 280.429871,528.757324 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M281.469849,561.002686 
	C281.154480,560.611938 281.111359,560.143188 281.058289,559.321533 
	C282.363678,559.236206 283.415497,559.597290 281.469849,561.002686 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M349.869019,644.789062 
	C348.987244,644.995850 348.233337,644.699951 347.960693,643.393372 
	C348.880615,643.079346 349.679016,643.330139 349.869019,644.789062 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M362.022064,665.343506 
	C362.892609,665.078430 363.671509,665.332275 363.941406,666.696411 
	C363.079407,666.939575 362.325226,666.651428 362.022064,665.343506 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M285.576355,630.044800 
	C285.191711,630.751465 284.804047,630.756409 284.461182,630.255066 
	C284.389282,630.149963 284.391815,629.906372 284.466278,629.803650 
	C284.822784,629.311768 285.210175,629.329895 285.576355,630.044800 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M348.001709,640.001709 
	C348.099579,639.378845 348.109283,638.669983 348.908203,638.523621 
	C349.074432,638.493164 349.311035,638.846985 349.515228,639.023743 
	C349.195374,639.323608 348.875549,639.623474 348.276031,639.960144 
	C347.996338,639.996948 348.000000,640.000000 348.001709,640.001709 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M362.962677,637.286987 
	C363.297577,637.351807 363.609680,637.683960 363.921814,638.016052 
	C363.729767,638.150330 363.537720,638.284546 363.345673,638.418762 
	C363.210419,638.130615 363.075165,637.842468 362.962677,637.286987 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M348.042908,677.710449 
	C347.703827,677.546997 347.357239,677.066833 346.990845,676.280823 
	C347.325775,676.447876 347.680573,676.920837 348.042908,677.710449 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M297.125610,589.191650 
	C297.435608,589.104980 297.849121,589.238770 298.262634,589.372620 
	C297.943268,590.044678 297.584045,589.672546 297.125610,589.191650 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M349.835815,678.773315 
	C349.467896,678.812134 348.902802,678.622314 348.154968,678.191223 
	C348.527710,678.148193 349.083160,678.346436 349.835815,678.773315 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M321.989868,600.761108 
	C321.671051,600.893921 321.401886,600.709534 321.060303,600.228516 
	C321.725220,599.273315 321.881866,599.842346 321.989868,600.761108 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M366.716400,671.060303 
	C367.003571,671.239746 367.009125,671.407959 367.062500,671.825073 
	C366.554230,672.030945 365.876648,672.228638 365.689453,671.442871 
	C365.673218,671.374695 366.174103,671.183228 366.716400,671.060303 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M300.544250,593.472900 
	C300.575317,593.525635 300.513184,593.420227 300.544250,593.472900 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M345.450684,609.530029 
	C345.420013,609.478149 345.481323,609.581909 345.450684,609.530029 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M296.986572,589.009827 
	C296.862946,588.874878 296.752869,588.728760 296.642761,588.582642 
	C296.728973,588.626282 296.815186,588.669861 296.961761,588.842285 
	C297.022095,588.971191 297.000061,588.998657 296.986572,589.009827 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M299.450928,591.444702 
	C299.520782,591.400208 299.381073,591.489258 299.450928,591.444702 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M288.458557,591.443481 
	C288.518097,591.406677 288.399017,591.480286 288.458557,591.443481 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M280.993652,558.685669 
	C280.641998,558.819641 280.246155,558.684570 280.674438,558.005676 
	C280.869141,558.055176 280.912659,558.233765 280.993652,558.685669 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M300.474274,589.459839 
	C300.521729,589.433105 300.426819,589.486633 300.474274,589.459839 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M366.786469,639.961548 
	C366.779755,640.303711 366.552460,640.610352 366.031677,640.978882 
	C365.738220,641.040771 365.728577,640.647827 365.926117,640.254578 
	C366.123657,639.861328 366.565857,639.926086 366.786469,639.961548 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M365.834961,640.157349 
	C365.531067,640.029541 365.324677,639.804321 365.118256,639.579102 
	C365.372894,639.538269 365.627533,639.497437 366.002930,639.658936 
	C366.123657,639.861328 365.932465,640.059937 365.834961,640.157349 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M334.016663,639.022095 
	C333.910126,639.000366 333.931549,639.777222 333.830414,639.035706 
	C333.963745,638.971680 334.000824,639.003540 334.016663,639.022095 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M362.054413,636.261719 
	C362.329956,636.084839 362.604523,636.260559 362.894836,636.786499 
	C362.985443,637.019714 363.003662,636.997192 363.015289,636.987305 
	C362.727417,636.817322 362.427887,636.657227 362.054413,636.261719 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M362.018890,635.983643 
	C361.812958,635.885010 361.592865,635.799377 361.372742,635.713745 
	C361.537537,635.697388 361.702332,635.681030 361.923798,635.845459 
	C361.980469,636.026245 362.004700,635.996643 362.018890,635.983643 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M279.000000,478.999847 
	C278.727448,479.105713 278.454926,479.211731 278.091553,479.158264 
	C278.000702,478.998779 278.000000,479.000000 277.999390,479.000610 
	C278.165741,478.900116 278.332733,478.798981 278.750488,478.849060 
	C279.001221,479.000275 279.000000,479.000000 279.000000,478.999847 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M278.473877,481.451111 
	C278.528717,481.418884 278.419006,481.483307 278.473877,481.451111 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M380.748077,695.011047 
	C380.850922,695.416382 380.633850,695.703186 380.049774,695.211914 
	C380.123535,695.035034 380.301849,695.024292 380.748077,695.011047 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M337.810638,701.004272 
	C337.814301,701.905151 337.621307,702.814026 336.560211,702.786682 
	C335.208618,702.751953 335.114349,701.705444 335.019928,700.342834 
	C335.848175,700.278015 336.677002,700.568054 337.810638,701.004272 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M334.176331,682.257812 
	C334.404297,681.981262 334.832428,681.975708 335.580627,681.971436 
	C336.184448,682.144043 336.468231,682.315491 336.887085,682.697510 
	C336.140259,682.781738 335.258392,682.655334 334.176331,682.257812 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M334.861389,699.759033 
	C334.556122,699.984802 334.106232,699.988220 333.319244,699.973145 
	C333.439148,699.312317 333.969208,698.973206 334.861389,699.759033 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M339.208069,676.226318 
	C339.600677,676.161133 340.137451,676.351624 340.827942,676.790222 
	C340.438507,676.852844 339.895386,676.667419 339.208069,676.226318 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M287.572784,646.766724 
	C287.911560,647.856262 287.733917,648.596497 286.784546,648.763672 
	C286.474609,648.818298 285.770844,648.391724 285.780914,648.215820 
	C285.835449,647.263550 286.086060,646.349304 287.572784,646.766724 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M313.537598,659.541138 
	C313.485840,659.572144 313.589386,659.510193 313.537598,659.541138 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M365.845520,725.027222 
	C366.191681,724.793823 366.361786,724.826050 366.753784,724.922607 
	C366.181427,726.598938 369.168854,727.366150 367.828918,729.308594 
	C367.203644,730.214966 368.816925,730.648987 369.308594,730.537964 
	C370.192627,730.338562 371.839874,730.337280 371.069183,728.524414 
	C370.819275,727.936707 370.374512,727.255432 371.463867,727.142273 
	C371.886292,727.098511 372.741180,727.449707 372.732025,727.570251 
	C372.485535,730.811462 375.245026,730.613037 377.083374,731.664429 
	C380.592743,733.671570 380.578766,736.057434 377.153748,738.101013 
	C375.874634,738.864197 376.645203,739.803101 376.258362,740.883789 
	C375.698273,741.449524 375.277588,741.668457 374.428467,741.726562 
	C372.733154,741.334229 372.508972,740.280823 372.244873,739.083313 
	C371.423187,735.357666 365.756256,731.806396 362.308167,732.747437 
	C357.025269,734.189392 355.803375,737.918518 359.203125,743.231201 
	C359.903290,744.056213 360.270233,744.678894 360.797241,745.636047 
	C361.459198,747.116455 361.917236,748.275208 361.243958,749.852295 
	C358.601349,748.958984 355.694214,748.618408 354.157776,745.940552 
	C353.885895,745.466675 352.967072,744.895752 352.579651,745.031799 
	C349.858582,745.987854 348.175873,745.964172 349.250000,742.183899 
	C349.109802,741.075378 352.366425,739.910034 348.879181,739.231934 
	C346.309296,738.732300 345.264435,736.719849 345.160065,734.670410 
	C345.039185,732.297119 347.348572,731.546936 349.285522,731.204590 
	C352.486603,730.638794 355.069672,728.047607 356.221558,726.130859 
	C358.140656,722.937622 359.601837,724.526062 361.846405,725.043335 
	C362.862122,726.282349 362.477264,727.048340 360.923126,727.513977 
	C360.419464,727.824341 360.163300,727.904114 359.853180,727.789673 
	C360.106171,728.009399 360.349365,727.950745 360.881744,727.554565 
	C362.827576,727.068115 364.304626,726.294739 365.845520,725.027222 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M361.002594,750.423340 
	C360.742737,748.993530 360.473999,747.842896 360.211304,746.337402 
	C362.134277,744.637939 364.762329,745.176392 366.584930,743.567566 
	C367.773285,742.518677 369.862946,741.700256 368.977844,739.776611 
	C367.943298,737.528259 365.614990,736.742432 363.195526,736.684875 
	C361.193878,736.637207 360.197174,737.748230 360.148895,739.748108 
	C360.113495,741.214417 360.297943,742.737061 358.891479,744.017944 
	C358.149109,742.530212 353.803894,743.428101 356.028595,740.666809 
	C358.013855,738.202698 352.440063,732.020020 360.078644,732.675781 
	C363.775421,727.220520 366.871948,732.947449 370.112579,733.476318 
	C373.794739,734.077209 374.050720,737.558716 373.707397,741.071533 
	C374.872986,742.157593 374.962372,743.147461 374.268372,744.564819 
	C374.029663,744.888000 373.981659,744.919556 373.962769,744.941162 
	C373.278137,745.736328 372.460724,746.430420 372.569366,747.913696 
	C372.573669,748.621643 372.569275,749.020752 372.473694,749.707642 
	C372.400787,750.232910 372.419098,750.470337 372.089172,750.787354 
	C369.333557,753.051636 366.883667,753.282471 364.198029,751.258911 
	C363.738220,750.912415 362.664368,749.561096 361.930634,751.594360 
	C361.228973,751.756348 360.957184,751.344055 361.002594,750.423340 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M338.383240,749.632812 
	C337.850067,746.976135 335.078949,744.741211 337.508270,741.490112 
	C338.639221,744.374634 339.775787,746.864746 338.383240,749.632812 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M360.175995,719.084473 
	C357.805389,717.966125 357.805389,717.966125 358.994720,714.313904 
	C360.495483,715.632874 361.380585,716.895691 360.175995,719.084473 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M365.865784,724.688293 
	C365.894928,728.477478 363.196381,727.948975 360.404968,727.964539 
	C360.686737,727.239075 361.366516,726.473083 362.120941,725.394958 
	C362.195618,725.082825 362.458923,724.963562 362.602417,724.946411 
	C363.280029,724.618042 363.854706,724.492859 364.918091,724.594788 
	C365.366302,724.635986 365.710022,724.614929 365.865784,724.688293 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M361.687531,757.984131 
	C361.949402,760.289917 361.786133,762.580139 358.091858,761.255188 
	C358.825836,759.774170 359.652405,758.390259 361.687531,757.984131 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M334.927368,724.422852 
	C334.272491,723.440002 334.629822,722.798950 335.353760,722.827881 
	C335.750000,722.843750 336.413818,723.712158 336.426361,724.209534 
	C336.452637,725.252197 335.695160,724.902344 334.927368,724.422852 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M365.149048,724.471863 
	C364.790314,724.764038 364.214325,724.892090 363.192108,724.974670 
	C363.010742,724.660645 363.275604,724.392029 363.762451,723.945923 
	C364.300232,723.948242 364.616028,724.127991 365.149048,724.471863 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M348.989532,741.980591 
	C348.903473,742.026428 348.828705,742.087219 348.892761,742.082275 
	C349.031616,742.016602 349.000824,741.995544 348.989532,741.980591 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M349.436249,758.529724 
	C349.402374,758.473145 349.470123,758.586304 349.436249,758.529724 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M343.458221,752.534790 
	C343.429688,752.483704 343.486755,752.585876 343.458221,752.534790 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M365.105652,715.153809 
	C365.020386,714.990234 365.510193,714.995117 365.755066,714.997559 
	C365.730286,715.105713 365.460602,715.211548 365.105652,715.153809 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M354.009766,696.743958 
	C353.906982,696.586853 353.957855,696.428467 354.008667,696.270020 
	C354.060211,696.427551 354.111786,696.585083 354.009766,696.743958 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M343.062897,692.194580 
	C343.271729,692.070557 343.517609,692.238098 343.791351,692.699707 
	C343.575623,692.827026 343.335876,692.657776 343.062897,692.194580 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M250.976852,549.621582 
	C250.875198,557.447693 253.098129,564.616821 253.986191,572.257568 
	C251.045700,571.577637 252.683914,568.994568 251.513367,567.824890 
	C251.208313,567.824890 250.833527,567.711914 250.585663,567.841553 
	C245.329971,570.590576 244.311066,569.738647 244.215897,563.265259 
	C244.177048,560.622375 243.609589,558.221008 241.767822,555.656677 
	C238.939102,551.718140 237.565186,546.472290 238.286774,541.565918 
	C238.816879,537.961426 239.055420,534.854065 237.005646,531.399170 
	C241.719040,529.470825 244.686539,531.183289 245.288330,536.129395 
	C245.671997,539.282776 245.882034,542.411194 247.956924,545.441406 
	C249.298706,546.763367 250.652542,547.588989 250.976852,549.621582 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M253.792328,417.736115 
	C253.607712,422.776337 253.254715,427.558655 251.986313,432.605164 
	C252.090286,435.571198 251.252289,437.921478 250.139786,440.161102 
	C248.223495,444.018860 247.379700,444.089447 244.632050,440.736969 
	C244.061569,440.040894 243.218185,439.902161 242.254883,439.254272 
	C241.189117,436.242737 243.130280,433.469513 242.029160,430.334534 
	C241.421280,429.313293 241.258118,428.542755 241.762238,427.342133 
	C241.996811,426.555786 241.996613,426.112457 241.990280,425.336792 
	C242.719879,420.985474 243.327194,416.937439 244.227921,412.955811 
	C245.104630,409.080383 247.046143,412.044525 248.783813,412.727844 
	C249.927231,415.105682 251.436234,416.615326 253.792328,417.736115 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M248.412598,547.052673 
	C244.994598,544.717285 243.783371,541.537109 244.037521,537.913818 
	C244.362289,533.283813 242.204163,531.196899 237.388763,531.007629 
	C236.901031,526.260925 236.920731,521.519836 238.010651,516.428467 
	C240.743607,512.478577 244.247299,514.850708 244.357849,516.908142 
	C244.645065,522.253540 249.671158,525.184082 249.983246,530.588928 
	C249.990845,530.970703 249.497086,530.996460 249.250122,531.007446 
	C247.226089,532.412720 247.514923,534.368347 248.098602,536.020996 
	C248.952072,538.437378 248.846344,540.874756 248.987793,543.664246 
	C248.991730,544.003540 249.002777,544.502563 249.009125,544.752075 
	C249.584229,545.761414 249.522049,546.460327 248.412598,547.052673 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M253.418823,434.144958 
	C255.141983,428.440399 258.482880,423.871979 260.936462,418.522705 
	C262.460663,417.217041 262.726532,414.757355 265.480286,415.227234 
	C264.947327,421.961304 261.085602,427.596802 259.501801,434.025574 
	C258.396271,438.512909 256.315186,442.748047 255.849014,447.856262 
	C255.154434,449.180725 256.004761,450.764435 254.109741,451.127197 
	C252.950668,449.828033 253.253296,447.449402 250.479492,447.752930 
	C249.348267,442.695679 250.422974,438.363342 253.418823,434.144958 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M253.538422,433.812378 
	C252.453400,438.260651 251.272156,442.627899 251.010208,447.605011 
	C251.595993,448.677551 251.518997,449.360443 250.906036,450.325684 
	C248.203278,451.750610 245.775452,452.951752 244.280304,455.820801 
	C241.191940,452.894318 245.333023,450.598206 244.988144,447.385437 
	C243.595856,445.801514 244.329910,443.417755 242.240463,442.232239 
	C241.999832,441.258087 241.997864,440.518646 241.990326,439.410614 
	C246.162155,437.984467 246.162155,437.984467 246.912476,442.603973 
	C249.727219,440.106689 249.570816,436.265472 251.706055,433.220795 
	C252.426010,433.233704 252.869324,433.468933 253.538422,433.812378 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M244.181259,456.337860 
	C243.747482,452.076843 246.203934,450.673157 249.929474,450.085876 
	C252.973495,454.962860 250.277359,459.803925 250.039886,465.088806 
	C249.475159,466.364136 248.807007,466.912201 247.446808,467.078796 
	C246.552200,463.446228 248.524887,459.268127 244.181259,456.337860 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M247.004822,467.407104 
	C247.527008,466.737030 248.047775,466.469971 248.844543,466.104980 
	C250.917175,467.146088 251.143768,469.000275 251.360504,471.256042 
	C251.278259,473.707764 251.836990,475.626221 252.796753,477.852844 
	C253.150543,479.278229 253.134247,480.315216 252.460266,481.641846 
	C250.462982,482.059448 249.057404,481.780823 248.983368,479.531677 
	C248.925003,477.758789 248.587967,476.034027 246.365753,475.079102 
	C245.652115,474.616394 245.507187,474.152344 245.786316,473.314209 
	C247.635834,471.522797 246.770950,469.560913 247.004822,467.407104 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M252.409668,482.169159 
	C252.126480,480.967255 252.031448,479.973846 251.946014,478.605225 
	C251.286041,476.978241 252.302917,476.173370 252.921997,474.934418 
	C254.569397,472.763214 253.270065,470.274567 254.695892,468.072906 
	C255.706070,467.622772 256.277405,467.935303 256.909363,468.842957 
	C257.079102,474.114166 256.706909,479.012146 254.225174,483.870605 
	C253.813629,484.248993 253.625595,484.322083 253.205597,484.200867 
	C252.848358,483.463593 252.723083,482.920624 252.409668,482.169159 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M249.602615,578.318115 
	C249.096359,576.358154 249.465714,574.545959 251.132889,574.501526 
	C253.711197,574.432800 254.609222,576.942139 256.304260,578.498108 
	C254.457901,579.960754 252.464752,578.125610 251.328751,579.897461 
	C250.658386,580.943054 251.163483,583.730286 248.911423,582.451477 
	C247.138336,581.444580 250.188858,580.131348 249.602615,578.318115 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M249.269226,543.729980 
	C247.410385,539.807251 245.791611,535.613098 248.627502,531.100952 
	C247.558060,535.235474 251.546036,539.215088 249.269226,543.729980 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M253.698929,451.346649 
	C255.336075,450.768188 254.695328,449.349518 255.578308,448.483093 
	C255.808243,450.519897 255.827133,452.760559 255.870361,455.424011 
	C255.583649,457.845703 256.304596,459.847900 255.754425,462.223633 
	C255.467896,462.811890 255.302460,463.022919 254.758850,463.383728 
	C253.814941,463.583466 253.290863,463.457245 252.573227,462.810974 
	C251.854385,458.773010 252.185226,455.164886 253.698929,451.346649 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M269.297424,605.260376 
	C266.884277,604.376160 267.313965,602.079102 266.160400,600.335571 
	C267.113037,599.610474 268.856934,602.817139 269.658844,599.242188 
	C270.109192,599.171570 270.331268,599.279236 270.607422,599.753296 
	C270.936310,600.399658 271.211182,600.679749 271.770050,601.052002 
	C272.169220,601.272888 272.284393,601.401550 272.393311,601.785156 
	C272.291718,602.379822 272.315643,602.715393 272.445648,603.329346 
	C272.395447,603.790955 272.322815,603.971130 272.058655,604.380249 
	C271.188965,605.111816 270.436249,605.395386 269.297424,605.260376 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M257.168823,468.823853 
	C256.666077,468.799103 256.247437,468.449005 255.623184,467.916443 
	C253.627823,466.753021 253.820312,465.312897 254.778595,463.393127 
	C255.060089,463.025726 254.999023,463.008820 255.008286,463.039124 
	C255.372269,462.519012 255.724838,461.966370 256.689453,461.892700 
	C258.991150,464.112183 258.557404,466.287140 257.168823,468.823853 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M228.011719,532.615112 
	C227.194962,533.058411 225.766281,534.763794 225.819626,532.466797 
	C225.864151,530.548889 226.522812,528.645203 226.954865,526.361511 
	C231.133957,527.446899 227.619980,530.157837 228.011719,532.615112 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M274.404785,585.206543 
	C276.516663,585.540161 275.570099,583.417908 276.663666,582.817810 
	C277.209015,584.861328 277.318176,586.638611 275.340912,587.663391 
	C274.022644,588.346558 273.007874,587.371521 272.519440,586.347900 
	C271.388977,583.978760 273.491943,585.447998 274.404785,585.206543 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M272.800232,572.726013 
	C274.853760,574.802124 275.292816,577.032715 275.651611,580.097107 
	C272.866821,578.210022 273.044495,575.666931 272.041748,573.364624 
	C272.178131,572.816528 272.356232,572.633057 272.800232,572.726013 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M256.990082,461.636597 
	C256.514374,462.332520 256.046204,462.625580 255.297791,462.994019 
	C255.157913,460.887177 255.298279,458.704926 255.666656,456.184753 
	C256.936890,457.504303 257.109070,459.339966 256.990082,461.636597 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M248.186157,547.259644 
	C248.326294,546.536255 248.640320,546.063904 248.984924,545.296509 
	C250.930389,545.920471 252.879700,546.820435 251.267258,549.770142 
	C250.129883,549.167419 249.244965,548.338989 248.186157,547.259644 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M242.041901,442.354553 
	C245.308487,441.747620 245.030457,444.058380 244.996384,446.598206 
	C242.424347,446.657593 242.927917,444.226776 242.041901,442.354553 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M245.648865,473.076599 
	C246.003510,473.451324 246.002731,473.898132 246.002594,474.680481 
	C245.812912,475.774353 245.622574,476.532715 245.432251,477.291046 
	C245.041016,477.173218 244.361511,477.133453 244.305038,476.924988 
	C243.922272,475.511688 243.512787,474.019531 245.648865,473.076599 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M233.360046,454.804077 
	C233.800598,453.714081 233.862579,452.530670 235.129150,452.557983 
	C235.283707,452.561340 235.661346,453.441071 235.524979,453.668945 
	C235.060181,454.445740 235.127182,456.230865 233.360046,454.804077 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M254.112656,505.338867 
	C253.894836,503.433624 255.076492,504.117279 255.848709,504.253265 
	C256.102264,504.297943 256.512970,505.106781 256.402832,505.279175 
	C255.871826,506.109772 255.080765,505.896667 254.112656,505.338867 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M234.358337,457.996979 
	C235.740112,458.050842 236.045425,458.867859 235.705093,459.897217 
	C235.619202,460.157043 234.830414,460.484497 234.655441,460.362793 
	C233.908569,459.843292 234.026031,459.006317 234.358337,457.996979 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M237.684357,470.033325 
	C237.971390,471.059265 238.090027,471.905334 237.313324,472.399445 
	C237.137161,472.511444 236.362366,472.138702 236.284317,471.867798 
	C235.987289,470.836639 236.278595,470.007782 237.684357,470.033325 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M273.045471,572.590454 
	C272.888458,573.002686 272.710785,573.002930 272.266541,573.001587 
	C272.000000,571.054199 272.000000,569.108459 272.000000,566.314270 
	C273.512909,568.723816 272.739502,570.518005 273.045471,572.590454 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M241.667923,427.053894 
	C241.998444,427.749664 241.999863,428.500244 241.997482,429.626129 
	C239.913620,429.458649 239.214569,428.603241 241.667923,427.053894 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M259.970734,595.383423 
	C261.077728,596.065002 260.690155,596.665527 260.031555,597.162476 
	C259.898682,597.262634 259.389587,597.136536 259.304413,596.979187 
	C258.981537,596.382751 259.164368,595.835632 259.970734,595.383423 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M248.979156,489.949341 
	C248.871399,490.874115 248.743240,491.612274 247.962036,490.685822 
	C247.559494,490.208527 247.258759,489.489136 247.838577,489.003967 
	C248.626434,488.344757 248.869003,488.993408 248.979156,489.949341 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M271.826141,602.383667 
	C271.992340,602.014099 272.018097,601.578491 272.036072,601.361328 
	C272.661255,601.595032 273.268463,602.045837 274.059570,602.735291 
	C274.008148,603.280640 273.772766,603.587341 273.264801,604.002747 
	C272.737854,604.158630 272.483521,604.205872 272.137817,603.875977 
	C272.046417,603.498840 272.041138,603.472839 272.051697,603.464783 
	C271.928131,603.222229 271.794037,602.987732 271.826141,602.383667 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M252.706818,484.033569 
	C253.142868,484.047546 253.312103,484.088531 253.741486,484.152710 
	C253.810883,485.259216 253.404449,486.163971 252.134460,485.367889 
	C251.407227,484.912048 251.528931,484.311676 252.706818,484.033569 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M277.957367,589.580688 
	C277.249695,589.193909 277.240234,588.804077 277.744843,588.458801 
	C277.850342,588.386658 278.095459,588.389465 278.198395,588.464478 
	C278.689911,588.822571 278.676025,589.212402 277.957367,589.580688 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M235.000793,446.999390 
	C235.871735,447.623932 236.106506,448.492279 235.876404,449.485809 
	C235.832504,449.675293 235.507782,449.799713 235.312607,449.954163 
	C235.207932,449.191193 235.103241,448.428192 234.998367,447.333221 
	C234.998169,447.001221 235.000000,447.000000 235.000793,446.999390 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M233.577606,471.044983 
	C233.191772,471.751740 232.802795,471.758942 232.458588,471.255951 
	C232.386475,471.150574 232.388901,470.906158 232.463638,470.803284 
	C232.821396,470.310822 233.210144,470.329071 233.577606,471.044983 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M255.330597,547.253784 
	C254.860703,547.873352 254.345032,547.648132 254.389557,547.141724 
	C254.456055,546.385010 254.944885,546.726440 255.330597,547.253784 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M230.254639,568.017456 
	C230.413422,567.913391 230.573624,567.964722 230.733810,568.015991 
	C230.574554,568.068237 230.415283,568.120544 230.254639,568.017456 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M265.367432,533.234558 
	C265.382111,533.004089 265.506622,532.878845 265.631134,532.753601 
	C265.579865,532.948975 265.528595,533.144409 265.367432,533.234558 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M249.742386,500.991119 
	C249.584991,501.093903 249.426453,501.043152 249.267899,500.992371 
	C249.425674,500.940765 249.583450,500.889160 249.742386,500.991119 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M241.593719,471.256836 
	C241.443848,471.137573 241.423065,470.934326 241.402252,470.731079 
	C241.509109,470.878357 241.615952,471.025604 241.593719,471.256836 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M240.756302,510.983643 
	C240.843781,511.429474 240.665451,511.866272 240.487122,512.303101 
	C240.322067,512.034851 240.157013,511.766632 239.975327,511.243042 
	C240.135941,510.983368 240.313202,510.979004 240.756302,510.983643 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M270.876831,599.997375 
	C270.397644,599.964478 270.197693,599.749878 269.974365,599.269775 
	C270.340637,599.257385 271.388428,598.568115 270.876831,599.997375 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M235.479126,520.461914 
	C235.523682,520.437866 235.434555,520.485901 235.479126,520.461914 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M259.528839,599.546448 
	C259.476410,599.576843 259.581268,599.516052 259.528839,599.546448 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M279.526886,592.541931 
	C279.478424,592.569458 279.575348,592.514404 279.526886,592.541931 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M254.542450,549.473267 
	C254.569687,549.521423 254.515198,549.425110 254.542450,549.473267 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M241.542267,463.473419 
	C241.569748,463.521973 241.514786,463.424866 241.542267,463.473419 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M255.532074,501.553650 
	C255.475876,501.587677 255.588257,501.519653 255.532074,501.553650 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M248.526245,486.541870 
	C248.478592,486.568695 248.573883,486.515015 248.526245,486.541870 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M245.527008,487.542542 
	C245.479004,487.569794 245.575012,487.515259 245.527008,487.542542 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M243.451385,488.528564 
	C243.420471,488.475647 243.482315,488.581482 243.451385,488.528564 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M271.720032,603.616699 
	C272.201691,603.604492 272.356964,603.710083 272.752228,603.963562 
	C272.968964,605.513245 273.980530,606.381836 274.823059,607.692261 
	C272.578766,609.534180 270.170319,609.048584 267.377258,608.044678 
	C267.638397,607.242188 268.276794,606.484436 268.963165,605.430908 
	C269.805298,604.668396 270.599487,604.201477 271.720032,603.616699 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M265.218140,609.735596 
	C264.923859,611.152771 264.548706,611.848511 263.238373,611.041077 
	C262.477478,610.572266 261.797577,609.838867 262.232178,609.108276 
	C262.803070,608.148499 263.608978,610.344849 264.708740,609.142578 
	C265.100189,609.193298 265.237030,609.337341 265.218140,609.735596 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M265.384827,609.992065 
	C265.062347,609.989685 264.996643,609.520752 264.979980,609.285034 
	C265.440704,608.716858 265.918060,608.384399 266.697693,608.026001 
	C266.998810,608.444458 266.997650,608.888916 266.997620,609.666748 
	C266.568268,609.998169 266.137787,609.996277 265.384827,609.992065 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M260.544189,609.472534 
	C260.573181,609.523193 260.515228,609.421875 260.544189,609.472534 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M259.998962,605.000977 
	C260.112061,605.132996 260.226257,605.264160 260.340424,605.395264 
	C260.262146,605.348328 260.183868,605.301392 260.053436,605.125977 
	C260.001282,604.997559 260.000000,605.000000 259.998962,605.000977 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M453.381897,773.990173 
	C452.440521,769.952881 447.688934,768.799744 446.135223,769.007324 
	C440.999023,769.693420 439.098633,766.307556 436.242065,763.296936 
	C436.190521,762.939331 436.418030,762.886414 436.456421,762.770752 
	C437.873535,758.501099 440.792206,758.489380 444.627197,760.000366 
	C448.243927,754.789795 446.891113,751.713074 441.041107,750.992371 
	C440.116913,750.878479 438.875580,751.273010 438.549103,749.999023 
	C438.266022,748.894409 439.420380,748.360596 439.925293,747.559021 
	C440.991730,745.866028 443.306122,746.522400 444.659058,744.982788 
	C442.254120,744.091675 440.010590,743.260376 437.885376,742.209656 
	C439.036652,741.006104 440.295929,740.733093 442.064209,740.857544 
	C442.715302,740.935669 442.972992,740.979797 443.608826,741.115906 
	C451.631348,742.822266 459.334991,743.830811 467.173401,743.611938 
	C469.663513,743.542358 472.162476,743.500854 474.649933,743.686218 
	C476.538544,743.827087 478.395020,744.207947 478.753845,746.519775 
	C479.111481,748.823669 477.611023,749.785522 475.705963,750.372559 
	C474.283508,750.810913 472.834747,751.184082 470.660706,752.409424 
	C474.369446,752.472839 476.806519,753.608276 479.274292,755.210388 
	C483.578949,758.004700 488.672546,758.663086 493.675812,757.697998 
	C500.835327,756.317017 508.033936,754.890991 514.477234,751.137939 
	C515.784241,750.376648 517.341980,749.752258 518.778931,750.243652 
	C526.053284,752.731689 532.486694,749.549072 539.040222,747.356262 
	C542.663208,746.143982 546.273254,744.883179 550.237366,743.522705 
	C551.260437,743.155212 551.949097,742.948364 552.929932,742.532471 
	C553.452271,742.289429 553.659363,742.190552 554.169434,741.937927 
	C555.930908,737.969482 557.516724,734.282898 562.250305,733.552795 
	C563.438599,733.369507 564.719177,733.193848 565.334656,734.439636 
	C566.111145,736.011414 565.663452,737.328796 563.969727,738.104614 
	C566.037170,737.267273 568.079346,736.361633 570.117493,734.717346 
	C571.143677,734.022949 571.968018,733.674011 573.071777,733.154053 
	C574.172180,732.678772 574.935303,732.263306 575.991821,731.689209 
	C579.211609,731.335693 580.338440,728.821411 581.757812,726.950134 
	C583.522156,724.623962 585.483582,722.817932 588.560181,721.787720 
	C589.442871,721.378296 590.042542,721.146484 590.900024,720.727844 
	C591.362732,720.491333 591.548340,720.397461 592.014648,720.166260 
	C592.866394,719.758911 593.452637,719.526733 594.363831,719.248596 
	C595.338135,719.103516 595.968262,719.202881 596.781128,719.753906 
	C593.752563,723.476196 595.289124,729.098022 591.369812,732.384155 
	C590.103821,733.445679 589.445129,734.859558 588.059204,732.338745 
	C585.980774,732.047424 586.819092,733.361145 586.976685,734.652344 
	C586.295288,737.091980 585.432129,738.891602 582.830811,736.810974 
	C582.726868,736.727905 582.634155,736.630859 582.536194,736.540283 
	C582.529724,736.730896 582.526245,737.004456 582.405029,737.625610 
	C581.911865,738.359802 581.419922,738.667114 580.639099,739.037842 
	C580.231262,739.236633 580.070984,739.315918 579.681030,739.523438 
	C579.305359,739.745361 579.159363,739.839111 578.798645,740.078247 
	C578.438416,740.316895 578.292908,740.410156 577.922241,740.630981 
	C577.390320,740.920654 577.083435,741.082703 576.548645,741.383423 
	C576.166321,741.617310 576.011902,741.712524 575.621704,741.951660 
	C575.059265,742.292847 574.729614,742.484985 574.129883,742.814270 
	C572.834656,743.463562 571.830872,743.335449 570.559631,742.690918 
	C569.726562,742.225830 568.803223,741.606018 568.595520,742.177795 
	C568.282532,743.039734 569.757080,742.913025 570.237061,743.914551 
	C570.898376,746.824646 567.693359,747.519531 567.294434,750.041199 
	C566.339172,750.815674 565.529236,750.849731 564.462585,750.434692 
	C563.933777,750.103088 563.868469,749.850098 563.849854,749.537476 
	C563.655029,749.530273 563.307983,749.813843 563.093994,749.940308 
	C562.557556,750.213074 562.222046,750.319763 561.595398,750.413330 
	C560.941528,750.352844 560.587219,750.226868 560.014465,749.907959 
	C559.420593,749.634888 559.090210,749.693237 558.548828,750.086609 
	C556.641296,751.559326 554.977966,752.843994 554.734070,755.487305 
	C554.785156,756.040955 554.799438,756.265686 554.792114,756.833374 
	C554.711182,757.400452 554.651917,757.624695 554.417480,758.152588 
	C553.744507,758.991577 553.146362,759.322510 552.113403,759.159302 
	C546.359497,758.803040 542.590271,763.023132 537.640259,764.451904 
	C531.140259,766.328064 524.140015,767.208618 518.271973,770.291443 
	C514.758118,772.137512 511.837830,771.816833 508.517090,772.034119 
	C506.371155,772.174561 505.158905,768.772034 502.372406,770.844971 
	C500.612610,770.828064 499.262360,770.214539 497.491028,770.209351 
	C496.980042,770.168274 496.776703,770.144836 496.273682,770.063354 
	C495.779755,769.948547 495.585602,769.891785 495.111816,769.722229 
	C494.655914,769.515503 494.479553,769.421631 494.060303,769.154907 
	C493.683929,768.836243 493.550476,768.690369 493.217407,768.325317 
	C492.659485,765.687683 491.079926,765.857727 489.348175,766.386719 
	C485.613068,767.527405 481.881989,768.681152 478.147797,769.824951 
	C475.654785,770.588440 473.227905,771.633301 470.533081,770.347473 
	C469.019806,769.625427 467.699066,770.618530 466.228638,771.704651 
	C463.261627,772.129089 460.666870,773.473633 457.422760,772.998840 
	C456.852173,773.300781 456.678986,773.586304 456.242218,773.926086 
	C455.251923,773.992920 454.503876,773.992737 453.381897,773.990173 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M612.781616,729.946045 
	C615.719543,727.212830 618.464539,724.766663 621.481323,722.146362 
	C622.063232,721.819702 622.347229,721.628113 622.780396,721.210022 
	C622.951904,721.017212 622.953735,720.964233 622.979065,720.956421 
	C625.674866,718.258057 628.819336,716.107300 631.764709,713.222900 
	C632.000000,712.983887 631.975281,712.589111 631.967529,712.391479 
	C632.197876,711.894226 632.434021,711.593018 632.827942,711.051758 
	C633.112732,710.558838 633.293518,710.353394 633.712402,710.098633 
	C634.181274,709.763123 634.471069,709.529907 634.987976,709.134216 
	C635.211426,708.967163 635.421692,708.924500 635.528931,708.925537 
	C638.391663,708.673645 638.695435,710.689087 637.903198,712.252258 
	C636.727051,714.573059 637.281799,717.064636 636.240723,719.639893 
	C634.863342,720.542053 633.703796,721.123718 632.585327,721.775879 
	C632.179932,722.012268 631.536255,722.666260 631.568848,722.710327 
	C636.699402,729.651855 628.443726,730.899414 626.006348,733.226807 
	C623.227295,735.880615 620.006104,738.698914 616.930847,741.255920 
	C614.132812,743.582214 610.474976,744.391357 608.106506,747.208008 
	C607.475281,747.958679 606.118530,748.785828 605.671082,746.678162 
	C604.857483,742.845825 602.989624,743.755371 600.907959,745.671021 
	C597.501587,748.805786 595.403442,748.506287 592.617554,744.240479 
	C601.127747,741.921753 607.172974,736.531006 612.781616,729.946045 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M669.402100,684.158325 
	C670.534485,687.163391 666.732056,689.016907 668.445618,692.178711 
	C670.294434,689.567017 672.847168,687.777466 674.408875,684.892822 
	C675.233459,683.369629 677.209167,680.048889 679.050354,684.558899 
	C679.583984,685.866211 680.322449,685.798706 681.287781,684.992432 
	C682.364197,684.093384 682.452332,681.468079 684.896729,682.903015 
	C687.184570,684.246094 686.308105,686.245483 686.048157,688.143616 
	C685.959534,688.790833 686.030640,689.459961 686.043579,690.508362 
	C686.549988,692.863464 685.420166,693.701599 683.404175,694.085388 
	C681.696533,694.677856 680.041504,695.622681 678.821350,694.897461 
	C674.341797,692.234619 673.473145,695.947632 671.770203,698.477966 
	C667.998230,704.082458 666.592651,711.465088 659.355835,714.854980 
	C658.404358,713.841553 657.743896,712.669617 657.040649,711.142334 
	C657.161865,707.013184 660.150269,704.919556 662.236267,702.377014 
	C663.709473,700.581421 663.271790,700.044800 661.200195,699.371521 
	C660.850708,698.988037 660.701050,698.820190 660.514038,698.315186 
	C660.582031,697.299805 660.849304,696.679810 661.319092,695.784302 
	C662.381165,694.397278 662.726257,693.287354 661.401489,691.836548 
	C660.755920,688.533142 661.547485,685.422729 660.581665,682.128296 
	C662.394653,680.042114 663.773315,677.795837 667.050598,678.201782 
	C669.467285,679.685547 666.385864,682.751526 669.402100,684.158325 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M668.532776,679.242676 
	C665.036560,677.702393 664.223877,681.635132 661.407654,681.997559 
	C660.434692,682.454956 658.766296,682.437927 659.263367,683.171997 
	C662.520447,687.982300 658.631165,690.255493 656.049683,693.041260 
	C655.384644,693.758911 654.846863,694.592285 653.908813,695.410095 
	C656.080139,695.716125 658.159241,695.954163 660.040955,697.618530 
	C660.298462,698.137085 660.331909,698.374756 660.076904,698.828125 
	C658.997192,699.618164 657.583008,700.142944 657.532532,700.776367 
	C657.387878,702.593262 658.128906,704.447266 654.899231,703.696716 
	C653.000854,703.255493 650.063232,705.248657 650.624817,700.902527 
	C650.732239,700.071106 649.307922,700.444214 648.255432,700.885803 
	C646.161255,701.213928 645.934998,699.956909 645.881409,698.297363 
	C647.453552,696.109009 650.789856,694.586731 646.725159,691.960083 
	C645.763489,691.338684 646.442566,690.184875 647.262390,689.462891 
	C651.400085,685.818848 653.418823,680.322754 658.102295,677.114014 
	C658.939819,676.540283 658.069214,675.676819 657.103516,675.046021 
	C654.754639,671.098816 660.026184,670.687866 660.186035,667.543945 
	C660.487732,666.597595 660.784424,666.010254 661.296753,665.171143 
	C661.958191,664.218811 662.547180,663.608826 663.048706,662.562317 
	C663.400635,661.928406 663.625671,661.581360 664.052246,660.997742 
	C664.494507,660.429138 664.756226,660.111389 665.206299,659.547119 
	C665.761597,658.787537 666.142517,658.285278 666.742432,657.540588 
	C667.651306,656.683899 668.420898,656.311401 669.663574,656.688110 
	C668.548767,660.847717 667.185913,664.851013 665.730957,668.820496 
	C664.909424,671.061646 664.385071,673.153809 667.948730,671.947571 
	C670.468384,671.094727 671.977234,672.530090 673.902527,673.958130 
	C673.705566,677.380066 673.506592,680.681091 668.532776,679.242676 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M570.587891,742.420166 
	C571.833374,742.259460 572.642334,742.902649 573.804077,742.089661 
	C574.460449,741.513184 575.073853,741.531372 575.812256,741.147461 
	C576.031860,740.966003 576.015808,741.024597 575.986023,741.018799 
	C576.413513,740.588867 577.019226,740.524658 577.805176,740.155273 
	C578.033264,739.966309 578.017944,740.025208 577.987976,740.020142 
	C578.209290,739.798401 578.449158,739.569702 578.841370,739.141846 
	C579.005127,738.954651 579.006287,739.008728 578.979614,739.003906 
	C579.412781,738.595032 580.000183,738.521362 580.781433,738.188110 
	C582.826416,737.113953 581.207397,734.817749 582.762146,733.662048 
	C583.603638,735.208313 583.297363,739.536621 586.730347,735.133362 
	C588.993652,741.051453 588.228394,743.904602 583.404541,746.998047 
	C582.021545,746.642578 581.144592,746.865906 580.194824,747.844116 
	C576.818542,750.875977 572.833801,751.421692 568.462830,752.024048 
	C567.305481,751.841003 566.889648,751.324463 566.846191,750.273071 
	C565.088684,746.599060 568.768555,746.330200 569.969482,744.034180 
	C570.076111,743.216125 570.127991,743.025330 570.587891,742.420166 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M597.259521,720.003540 
	C596.271423,720.001953 595.540466,720.011292 594.443970,720.023438 
	C593.381531,719.730469 592.885071,720.052612 592.222534,720.799194 
	C591.997742,721.042786 591.992676,720.988220 592.020081,720.989624 
	C591.633545,721.342102 591.155518,721.509460 590.227905,721.548401 
	C587.514709,722.210815 586.377075,721.153687 586.233521,718.906616 
	C586.118347,717.105225 585.766663,715.309753 586.106689,713.130554 
	C586.792725,711.353516 588.131958,711.481140 589.651489,711.216064 
	C590.469543,711.166931 590.930176,711.207214 591.696472,711.434814 
	C592.707581,712.017944 593.170715,712.774536 594.264282,713.115173 
	C594.761169,713.097778 594.960938,713.074707 595.461365,712.998779 
	C596.998474,712.758972 598.211670,712.442566 599.774780,712.288269 
	C600.304565,712.339294 600.513123,712.388855 600.997070,712.600586 
	C601.661682,713.329895 601.664429,713.083801 601.720398,712.328125 
	C604.844543,713.264282 606.806702,711.586914 608.672913,709.665649 
	C610.857971,707.416016 613.600586,707.169800 616.824036,707.564087 
	C619.157654,709.511963 618.163391,711.048279 616.302368,711.750427 
	C613.325806,712.873352 612.276184,715.236267 611.242859,718.172729 
	C610.953857,718.640686 610.821289,718.814880 610.447083,719.201050 
	C610.047974,719.552063 609.890625,719.691101 609.530396,720.065796 
	C609.032593,720.579590 608.714050,720.826721 607.871338,721.322571 
	C607.218811,721.708252 607.059814,721.804993 606.666443,722.049072 
	C606.131531,722.409302 605.824524,722.612427 605.250305,722.895081 
	C604.989624,722.984314 604.497009,723.003601 604.250549,723.000610 
	C603.895813,722.858948 603.787476,722.720276 603.764160,722.331787 
	C603.714478,719.888062 602.945801,718.529968 600.207275,719.738037 
	C599.621643,720.358337 599.179504,720.506470 598.347290,720.243652 
	C597.858948,720.038452 597.687805,720.026367 597.259521,720.003540 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M645.709473,698.107666 
	C646.492493,698.842102 646.980347,699.743103 647.711914,700.825317 
	C648.966797,709.486267 644.546265,716.957458 636.369873,719.953125 
	C635.755676,716.954102 633.679932,713.724548 637.207092,711.211121 
	C637.309814,711.137878 636.589478,709.909485 635.944580,709.073120 
	C638.895569,705.375793 642.154907,701.825073 645.709473,698.107666 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M492.775635,768.356140 
	C493.017792,768.106018 493.437988,768.090332 493.647308,768.070312 
	C494.139374,768.230835 494.404114,768.434631 494.812439,768.854126 
	C494.974060,769.046265 494.975128,768.996643 494.964325,769.020142 
	C495.138123,769.157593 495.322693,769.271423 495.778748,769.205933 
	C498.020264,769.244324 500.297760,768.031616 501.938660,770.632935 
	C503.244507,775.137695 500.978210,776.326782 497.397003,777.097473 
	C492.827515,778.080811 488.324402,777.923645 483.504333,777.458252 
	C488.093140,775.910950 486.481812,768.474060 492.775635,768.356140 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M610.421997,719.495056 
	C610.696594,714.851868 611.590576,710.869202 616.923096,710.301208 
	C617.530823,710.236511 617.477173,709.224426 617.097778,708.271729 
	C617.041260,707.109314 616.957336,706.376831 615.463928,706.583740 
	C614.995605,706.306458 614.833618,706.150696 614.567993,705.669800 
	C614.490784,703.521912 615.881714,703.186401 617.558228,702.729553 
	C618.101013,702.605713 618.317078,702.558350 618.839355,702.385620 
	C619.348450,702.173035 619.551270,702.085754 620.055420,701.859192 
	C622.562683,700.532349 624.765015,699.311279 627.542480,701.155029 
	C628.095093,701.667175 628.270325,702.034790 628.189087,702.749390 
	C627.785095,703.268372 627.463135,703.472778 626.930298,703.831177 
	C626.622009,704.382690 626.647278,704.718079 626.942932,705.283813 
	C625.197205,709.310059 623.447083,713.172180 618.245483,713.954468 
	C617.869324,713.941345 617.539124,713.680115 617.522339,713.473999 
	C617.505615,713.267883 617.170166,713.521057 617.018188,713.669434 
	C616.866150,713.817871 616.688049,714.205933 616.745850,714.411499 
	C616.567932,718.344604 613.417297,718.827515 610.421997,719.495056 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M612.637939,729.792114 
	C612.577332,736.653809 605.727905,737.888977 601.806580,741.608704 
	C600.078735,743.247742 597.190613,746.310486 593.182617,744.006104 
	C599.069458,739.002075 605.388733,734.051575 612.637939,729.792114 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M660.479797,666.671021 
	C662.597961,671.127258 657.373901,671.290710 656.884705,674.571106 
	C653.151123,678.588257 649.479919,682.260254 645.492310,686.034607 
	C644.986328,685.986511 644.796570,685.836121 644.444397,685.360168 
	C643.934814,681.484802 647.145630,680.034180 648.693970,677.607117 
	C649.672607,676.073181 650.888855,674.696533 652.100952,672.872559 
	C654.804016,670.440613 655.558533,666.213379 660.479797,666.671021 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M566.652710,749.960266 
	C567.268921,750.467957 567.546631,750.983459 567.942749,751.768188 
	C566.252686,754.249329 564.444214,756.461365 562.394653,758.883789 
	C559.710999,759.484680 558.031372,758.001953 556.039795,756.421753 
	C555.481201,753.414673 557.430786,752.332214 559.685242,751.127991 
	C561.162109,751.005127 562.163513,750.731445 562.817627,749.265747 
	C563.020630,749.021240 563.513184,749.021362 563.744019,749.017456 
	C564.494568,749.969238 565.416077,749.940002 566.652710,749.960266 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M555.503296,755.591370 
	C558.229858,755.704834 559.586182,757.880127 561.914734,758.815613 
	C562.188782,759.783875 563.310669,760.819458 561.298950,760.991211 
	C560.433594,761.027466 559.890625,761.184387 559.222534,761.781250 
	C557.627686,762.264648 556.216675,762.471069 554.844116,762.826416 
	C551.865356,763.597473 551.265991,762.254700 551.882874,759.324768 
	C552.366028,758.677673 552.908630,758.395325 553.717285,757.959595 
	C554.121033,757.683960 554.258728,757.561768 554.181885,757.265625 
	C553.959900,756.908447 553.952454,756.725098 553.964233,756.267212 
	C554.321228,755.673828 554.722046,755.501160 555.503296,755.591370 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M631.679749,712.987671 
	C630.634644,717.084045 627.538391,719.340332 623.344482,720.869141 
	C623.226624,720.157471 623.448853,719.366272 623.787842,718.283020 
	C623.984985,717.403198 624.199097,716.874329 624.809875,716.230957 
	C625.025574,716.036682 625.003296,716.018188 625.016479,716.016907 
	C625.186157,715.846619 625.342651,715.677551 625.718689,715.241821 
	C627.261047,712.990295 628.641174,711.162231 631.679749,712.987671 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M610.106323,720.097595 
	C609.915894,723.071045 607.741394,723.872559 605.194397,724.236694 
	C605.460388,723.595581 605.505432,723.046387 605.625244,722.228943 
	C605.951172,721.675842 606.248718,721.463684 606.812134,721.144531 
	C607.030334,720.972290 606.990601,720.990723 606.975891,721.006836 
	C607.413086,720.587708 608.018738,720.524353 608.810547,720.167114 
	C609.041077,719.980652 608.999512,719.997253 608.985840,720.014954 
	C609.128967,719.911255 609.285828,719.789917 609.701904,719.814453 
	C609.961182,719.960327 610.057129,720.052734 610.106323,720.097595 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M641.127686,689.033875 
	C641.107239,690.970032 640.244812,692.167542 638.584290,693.150635 
	C638.072144,693.135315 637.869751,693.017395 637.542725,692.569702 
	C637.351624,690.610718 638.269775,689.610840 639.915894,688.758850 
	C640.486633,688.680908 640.719910,688.704712 641.127686,689.033875 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M580.341797,748.092041 
	C579.852844,746.748169 580.315491,745.720459 581.592896,745.308289 
	C582.337769,745.067871 582.792175,745.607666 582.985352,746.685425 
	C582.471619,747.849182 581.697021,748.237244 580.341797,748.092041 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M550.324341,780.966675 
	C549.816650,781.016846 549.637695,781.015198 549.189453,781.018555 
	C549.840576,779.299805 551.413635,778.881165 553.626953,778.970581 
	C553.219849,780.221985 551.899902,780.501404 550.324341,780.966675 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M626.991455,703.208740 
	C627.529968,702.990051 627.800110,702.838806 628.099976,702.349243 
	C628.169678,701.006531 628.839417,700.343018 630.078552,699.726135 
	C630.668823,699.674133 630.907227,699.719543 631.222534,700.120667 
	C630.926819,701.095947 630.554138,701.715576 630.054565,702.582642 
	C629.764893,703.135864 629.558472,703.408875 629.101929,703.804016 
	C628.581848,704.032532 628.281738,704.142456 627.759033,704.335083 
	C627.340332,704.361267 627.157532,704.341248 626.788513,704.118042 
	C626.665222,703.742432 626.728088,703.569946 626.991455,703.208740 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M636.230225,695.048218 
	C636.011841,696.579651 635.598511,697.742798 633.884766,698.135864 
	C633.354614,698.319336 633.131714,698.473877 632.758057,698.257446 
	C632.637817,696.438904 633.443298,695.506042 634.991943,694.723633 
	C635.590393,694.648804 635.833679,694.681152 636.230225,695.048218 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M598.163208,720.312988 
	C598.465759,720.037598 598.901367,720.024719 599.664001,720.002319 
	C600.570801,720.418030 601.062805,721.039062 600.273132,721.508423 
	C599.393921,722.031006 598.809387,721.217285 598.163208,720.312988 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M596.367249,727.951782 
	C596.654602,727.373474 596.976685,727.062317 597.298767,726.751160 
	C597.468262,727.117737 597.844971,727.538635 597.765991,727.840088 
	C597.574768,728.569946 597.074402,728.874939 596.367249,727.951782 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M564.908752,775.260437 
	C565.523438,775.314880 566.447083,775.623474 566.449585,775.939087 
	C566.458313,777.070984 565.403687,776.865051 564.322632,776.994141 
	C564.260193,776.505371 564.527649,776.011597 564.908752,775.260437 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M589.441772,746.629883 
	C589.660400,747.410767 589.379456,747.686707 588.779175,747.572632 
	C588.653442,747.548828 588.481384,747.370239 588.463684,747.244324 
	C588.378052,746.635742 588.675293,746.376709 589.441772,746.629883 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M559.318604,761.992188 
	C558.947815,760.754456 559.061707,759.730530 560.885071,760.758057 
	C560.560425,761.358154 560.088135,761.675842 559.318604,761.992188 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M616.932251,714.439636 
	C616.261108,714.697266 615.378357,715.399109 615.262573,714.592590 
	C615.117188,713.579407 616.269348,713.248169 617.566284,712.962402 
	C618.022644,713.019958 617.944824,713.480469 617.907104,713.710938 
	C617.572021,713.978088 617.302490,714.085083 616.932251,714.439636 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M624.738342,716.055664 
	C624.826355,716.587585 624.627136,717.138489 624.166199,717.840149 
	C622.941895,717.025635 623.253601,716.423828 624.738342,716.055664 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M606.202759,721.728943 
	C605.836914,722.536560 605.664001,723.095093 605.245544,723.824341 
	C604.998047,723.826721 604.996094,723.658325 604.991882,723.237061 
	C605.161316,722.434387 605.332947,721.884521 605.745667,721.167480 
	C606.123047,721.160156 606.259399,721.319946 606.202759,721.728943 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M644.489136,684.240601 
	C644.920044,684.559143 644.973755,684.873962 645.101746,685.662842 
	C644.741943,686.458557 644.307800,686.780151 643.642456,687.242310 
	C643.176514,687.299011 642.941650,687.215149 642.578430,686.778687 
	C642.419922,685.252991 643.012024,684.552246 644.489136,684.240601 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M633.388000,698.107422 
	C633.577576,698.164856 633.456848,698.601807 633.405151,698.822449 
	C633.329346,699.225952 633.305298,699.408813 633.061462,699.759827 
	C632.503174,700.067261 632.164673,700.206604 631.562866,700.411194 
	C631.299561,700.476379 631.138428,700.205872 631.044922,700.079224 
	C630.603333,698.788696 631.273438,698.203491 632.551514,697.771851 
	C632.906738,697.781799 633.198425,698.049927 633.388000,698.107422 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M553.960388,778.946045 
	C554.322571,778.413696 554.685120,777.782349 555.361511,778.596924 
	C555.086365,778.727234 554.811157,778.857544 554.296143,779.022339 
	C554.056274,779.056885 553.990601,778.984436 553.960388,778.946045 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M622.697632,721.091675 
	C622.731995,721.292847 622.512024,721.568481 622.022583,721.908142 
	C621.983215,721.703491 622.213318,721.434753 622.697632,721.091675 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M599.529297,725.543823 
	C599.479492,725.572144 599.579102,725.515503 599.529297,725.543823 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M603.715942,722.178467 
	C603.849182,722.081970 603.941956,722.537231 603.973022,722.767395 
	C603.863647,722.756714 603.723206,722.515808 603.715942,722.178467 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M605.972046,756.021301 
	C605.738220,755.898193 605.545532,755.736145 605.352783,755.574097 
	C605.557800,755.556763 605.762756,755.539368 606.029541,755.711060 
	C606.091370,755.900146 606.013184,755.982300 605.972046,756.021301 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M585.448181,749.536987 
	C585.410645,749.477661 585.485718,749.596313 585.448181,749.536987 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M577.456543,752.527588 
	C577.426208,752.476501 577.486938,752.578735 577.456543,752.527588 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M638.141235,693.132996 
	C638.173279,693.573303 637.999878,693.847900 637.539795,694.197632 
	C637.131592,694.235901 636.937805,694.153503 636.745911,693.755249 
	C636.875793,693.272095 637.003723,693.104797 637.436584,692.854980 
	C637.741516,692.772461 638.007996,693.012817 638.141235,693.132996 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M637.180786,694.176025 
	C637.197998,694.619446 637.014282,694.880981 636.578857,695.242859 
	C636.383545,695.382935 636.058533,695.062073 635.896179,694.901489 
	C635.768250,694.370972 635.923157,694.065491 636.483765,693.791138 
	C636.749146,693.747314 637.036255,694.033813 637.180786,694.176025 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M660.090576,699.082214 
	C659.710449,698.866089 659.632263,698.688354 659.493896,698.225830 
	C659.759949,697.812561 660.086243,697.684265 660.933594,697.625977 
	C661.372437,697.937012 661.290222,698.177979 661.104248,698.787354 
	C660.797729,699.144104 660.595093,699.132385 660.090576,699.082214 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M633.015747,699.902954 
	C632.900269,699.765015 632.958862,699.602173 633.185425,699.241211 
	C633.298889,699.321411 633.244385,699.599731 633.015747,699.902954 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M642.464417,688.107422 
	C642.278870,688.736450 642.054077,688.976257 641.529236,689.243408 
	C641.302246,689.339172 641.108032,689.120056 641.005859,689.015259 
	C640.805664,688.470825 640.939453,688.106689 641.569824,687.711792 
	C642.083191,687.674744 642.290710,687.730774 642.464417,688.107422 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M643.243103,687.228333 
	C643.271362,687.681213 643.078369,687.940063 642.631470,688.293823 
	C642.430542,688.428101 642.096741,688.103577 641.931885,687.939148 
	C641.771606,687.400452 641.917847,687.093811 642.470703,686.811157 
	C642.735779,686.767700 643.074890,687.073792 643.243103,687.228333 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M632.711426,710.954346 
	C632.815002,711.165771 632.642395,711.518433 632.214844,712.032471 
	C632.118286,711.827820 632.276733,711.461731 632.711426,710.954346 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M634.916626,709.027954 
	C634.959412,709.256775 634.707458,709.546326 634.175171,709.918213 
	C634.137207,709.696594 634.379517,709.392639 634.916626,709.027954 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M625.742004,715.105835 
	C625.882629,715.309082 625.722900,715.582214 625.244385,715.905029 
	C625.128540,715.707458 625.300537,715.447754 625.742004,715.105835 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M627.174683,705.253418 
	C626.737732,705.294983 626.442871,705.056885 626.086304,704.519287 
	C626.141357,704.059753 626.258179,703.899780 626.655518,703.837524 
	C626.936035,703.935181 627.220398,704.160400 627.371704,704.270874 
	C627.454285,704.578796 627.385559,704.776306 627.174683,705.253418 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M629.137024,703.843140 
	C629.073975,703.654663 629.252563,703.350159 629.679443,702.937866 
	C629.744690,703.129089 629.561707,703.428162 629.137024,703.843140 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M632.478638,708.463135 
	C632.525696,708.437073 632.431580,708.489258 632.478638,708.463135 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M524.706299,799.866943 
	C525.119324,800.315186 525.312683,800.568787 525.789368,800.884033 
	C526.243408,801.201294 526.472290,801.416748 526.843994,801.823120 
	C527.072510,802.146606 527.152405,802.290039 527.216431,802.440308 
	C528.132263,804.588867 531.699402,805.813477 530.837891,808.046692 
	C529.995239,810.230774 526.557373,808.249878 524.267273,809.724182 
	C524.004883,810.000000 523.545959,810.011047 523.317078,810.014404 
	C521.498230,808.648438 523.494202,804.828491 519.305786,804.967407 
	C518.757385,803.434326 519.309448,802.294373 520.809326,801.241333 
	C521.169067,800.967468 521.336426,800.974182 521.755005,800.989197 
	C522.169250,801.125671 522.332336,801.253845 522.734985,801.183533 
	C522.974548,800.985107 523.000000,800.999634 522.992432,800.987000 
	C523.402832,800.491028 523.760132,799.938171 524.706299,799.866943 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M487.178101,817.122009 
	C485.297058,816.795898 484.883514,816.134277 485.840637,814.731201 
	C487.051147,812.956604 488.210052,813.539490 489.694824,814.797791 
	C489.933868,816.539429 490.171265,818.441650 487.178101,817.122009 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M483.966797,818.419312 
	C484.404175,818.083618 484.858246,818.044800 485.653442,817.995972 
	C485.863831,818.913635 485.899811,819.990479 484.688110,820.180176 
	C484.533447,820.204407 484.222198,819.228638 483.966797,818.419312 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M518.690063,804.977234 
	C518.960754,805.123230 518.984192,805.288940 519.028076,805.704468 
	C518.604065,805.818054 518.159668,805.681763 517.715271,805.545471 
	C517.957764,805.362671 518.200256,805.179810 518.690063,804.977234 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M499.539093,808.557495 
	C499.475098,808.596741 499.603088,808.518188 499.539093,808.557495 
z"
    />
    <path
      fill="#252128"
      opacity="1.000000"
      stroke="none"
      d="
M498.545715,806.475098 
	C498.574127,806.525513 498.517273,806.424744 498.545715,806.475098 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M437.983398,785.318726 
	C436.296844,784.197083 437.703491,782.592407 437.078369,781.168457 
	C437.153259,779.899841 438.147614,778.568909 438.641296,779.293152 
	C439.999146,781.285156 442.515564,780.599976 443.980042,782.463135 
	C443.999359,783.741150 443.558044,784.776245 444.782104,785.777832 
	C444.159180,787.914124 442.507874,788.078064 440.375488,788.002441 
	C439.805450,787.761841 439.584717,787.540710 439.169983,787.182251 
	C438.995392,787.025513 438.991760,787.007324 438.982605,787.006042 
	C438.756195,786.763184 438.539154,786.521423 438.150238,786.107056 
	C437.978149,785.934570 437.977325,785.523987 437.983398,785.318726 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M465.992767,781.307129 
	C464.041687,782.692993 462.851532,782.027588 462.198822,780.382996 
	C461.660797,779.027344 462.045929,777.767944 463.393677,776.982910 
	C464.427155,776.380920 465.590851,775.869690 466.640167,776.731445 
	C468.516998,778.272888 467.013763,779.655701 465.992767,781.307129 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M445.089294,785.849365 
	C443.940735,785.781921 442.434540,786.606567 441.905609,785.368530 
	C441.487701,784.390259 442.849060,783.826294 443.788818,782.961914 
	C449.268341,781.599365 450.329865,782.148010 449.224823,785.749268 
	C448.408966,786.665833 447.605927,786.761902 446.368317,786.241089 
	C445.736389,785.898438 445.460236,785.793701 445.089294,785.849365 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M446.046082,786.351318 
	C446.780945,786.006226 447.549347,786.009216 448.704285,785.999634 
	C450.311768,786.125610 451.448761,786.456360 451.638947,787.886475 
	C451.768799,788.863098 451.018677,789.662476 450.230621,789.624268 
	C448.326935,789.532104 446.685242,788.774109 446.046082,786.351318 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M437.286957,791.961914 
	C435.943909,791.291260 435.833069,790.404602 436.712555,789.186035 
	C437.969452,789.799988 440.104095,790.356201 437.286957,791.961914 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M452.193665,777.222046 
	C452.820190,776.680481 453.515381,775.996277 454.341248,776.545166 
	C454.513123,776.659363 454.607300,777.240967 454.480103,777.395386 
	C453.843506,778.168030 453.071899,777.997620 452.193665,777.222046 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M456.159973,774.218140 
	C456.110382,773.677673 456.300018,773.420654 456.784424,773.111084 
	C457.121735,773.705505 458.423859,773.814026 457.935211,774.714905 
	C457.850800,774.870605 456.880859,774.546021 456.159973,774.218140 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M439.091248,787.252808 
	C439.324219,787.111023 439.588684,787.291077 439.897766,787.784058 
	C439.672302,787.924316 439.399109,787.750183 439.091248,787.252808 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M445.456268,790.532104 
	C445.429016,790.484436 445.483521,790.579773 445.456268,790.532104 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M438.063171,786.180786 
	C438.246033,786.134766 438.513885,786.335022 438.877594,786.770020 
	C438.698364,786.812134 438.423248,786.619568 438.063171,786.180786 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M451.946625,777.036682 
	C451.803589,776.900635 451.706604,776.723572 451.609619,776.546509 
	C451.710388,776.581970 451.811127,776.617432 451.993286,776.778198 
	C452.074646,776.903442 451.992676,776.995667 451.946625,777.036682 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M445.457520,777.526489 
	C445.429779,777.477722 445.485260,777.575317 445.457520,777.526489 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M473.033508,810.327698 
	C474.211365,806.825928 475.598663,804.827942 478.878082,808.106506 
	C479.609222,808.837280 480.535675,810.513184 480.175140,810.496094 
	C477.551300,810.371826 476.106354,814.242798 472.823792,812.253113 
	C472.674835,811.525452 472.793121,811.086304 473.033508,810.327698 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M473.004822,804.996460 
	C472.896912,804.872864 472.784180,804.752808 472.671448,804.632751 
	C472.741699,804.679138 472.811951,804.725525 472.937683,804.890747 
	C472.993164,805.009705 473.000000,805.000000 473.004822,804.996460 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M484.835968,807.419556 
	C485.629456,807.270081 486.221985,807.373230 487.009705,807.727112 
	C486.534637,808.702820 485.934875,809.514160 485.177460,810.132324 
	C484.069336,811.036621 482.808350,810.918213 482.400452,809.520813 
	C481.858765,807.665222 483.808746,808.219055 484.835968,807.419556 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M432.700867,780.998413 
	C435.994049,784.478943 434.316986,786.761108 430.997925,788.884766 
	C428.489929,790.489502 426.360443,792.809204 422.538208,791.914185 
	C422.365845,787.652405 425.157867,785.243530 428.653351,782.978027 
	C430.121002,782.283020 431.313171,781.802185 432.700867,780.998413 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M432.953247,780.582520 
	C432.579498,782.747009 432.069580,784.474670 429.267151,782.985413 
	C429.330139,781.849304 429.688385,780.850037 430.184143,779.556580 
	C431.452209,778.910706 432.383606,779.065979 432.953247,780.582520 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M530.063110,800.359619 
	C532.690186,800.879822 534.613098,798.112854 537.171875,798.237427 
	C537.913452,800.095642 535.501038,800.736755 536.010742,802.641418 
	C533.761963,802.967102 531.431458,803.164612 530.063110,800.359619 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M537.156250,808.448853 
	C537.148132,810.408752 536.004211,810.021729 534.941895,809.664124 
	C534.751404,809.600037 534.522644,809.097107 534.593933,808.898010 
	C535.045105,807.638855 536.034851,808.053711 537.156250,808.448853 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M538.012817,804.254761 
	C538.160278,804.008972 538.327759,804.005676 538.746338,804.000000 
	C538.835144,804.415955 538.672729,804.834351 538.510315,805.252747 
	C538.351135,805.000916 538.191895,804.749084 538.012817,804.254761 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M526.999634,801.748779 
	C526.678711,801.896484 526.416077,801.710205 526.106812,801.220581 
	C526.001343,801.000000 526.498291,800.997559 526.746704,800.996338 
	C526.996521,801.162598 526.997864,801.330078 526.999634,801.748779 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M374.419128,744.920837 
	C374.019714,743.933289 374.009735,742.978638 373.974487,741.675659 
	C374.417175,741.255676 374.885132,741.184021 375.706757,741.112610 
	C376.708191,741.848816 377.355957,742.584778 378.038269,743.629272 
	C378.224945,744.125854 378.377075,744.313843 378.229919,744.706787 
	C376.889923,744.925659 375.849274,744.939697 374.419128,744.920837 
z"
    />
    <path
      fill="#C9C8C7"
      opacity="1.000000"
      stroke="none"
      d="
M375.303894,767.408264 
	C375.606995,768.296082 375.780151,769.215942 375.809540,770.471313 
	C374.803833,770.559814 373.941895,770.312805 372.803650,770.016113 
	C372.292145,769.897461 372.056915,769.828491 372.018341,769.448730 
	C372.222229,768.965881 372.229401,768.793762 372.215454,768.364014 
	C372.682373,767.288635 373.428131,767.073303 374.614807,767.295044 
	C374.910461,767.392761 375.173920,767.440369 375.303894,767.408264 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M374.956299,767.062744 
	C374.202637,767.638611 373.494781,767.884399 372.490631,768.118286 
	C372.152435,767.716858 372.110535,767.327393 372.157837,766.650391 
	C373.025330,767.529785 374.730225,761.794495 374.956299,767.062744 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M372.735321,747.894531 
	C370.894928,746.689453 371.052307,745.590515 373.641815,744.978455 
	C373.593445,745.830750 373.243011,746.698669 372.735321,747.894531 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M378.271362,745.079041 
	C377.930603,744.911743 377.992157,744.423950 378.032501,744.180908 
	C379.581146,744.447815 381.089417,744.957825 382.799561,745.739746 
	C381.498138,745.986816 379.953156,746.201416 378.271362,745.079041 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M370.165649,758.916931 
	C369.386261,758.306274 369.261200,757.722595 369.995880,757.405457 
	C370.253693,757.294128 370.882629,757.631287 371.107758,757.936462 
	C371.664459,758.691040 371.161438,758.956909 370.165649,758.916931 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M362.035034,781.036865 
	C361.915375,781.477234 361.763184,781.887573 361.610962,782.297913 
	C360.980652,781.973999 361.281830,781.600403 361.743347,781.090210 
	C361.932770,780.952026 362.002472,781.006836 362.035034,781.036865 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M372.028198,750.919739 
	C371.817078,750.628174 371.930573,750.408691 372.231964,750.102051 
	C372.640228,750.122131 372.897980,750.248779 373.155762,750.375427 
	C372.875671,750.574463 372.595581,750.773499 372.028198,750.919739 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M378.314240,753.694824 
	C378.030457,753.679749 377.874420,753.534058 377.718384,753.388428 
	C377.959564,753.447021 378.200745,753.505615 378.314240,753.694824 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M380.580811,754.402710 
	C379.916321,754.159668 379.968231,753.819824 380.467834,753.509399 
	C380.565613,753.763123 380.663391,754.016846 380.580811,754.402710 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M371.943420,769.058716 
	C372.215027,769.138062 372.487030,769.508362 372.507202,769.737427 
	C372.133331,769.813599 371.739258,769.660706 371.227905,769.207275 
	C371.297699,768.930908 371.484772,768.955078 371.943420,769.058716 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M489.279266,730.276733 
	C488.699554,727.103394 492.072174,727.552124 492.947510,725.350891 
	C492.821930,724.847961 492.636566,724.683777 492.719086,724.262207 
	C492.986969,724.004883 493.000000,724.000671 492.999023,723.994263 
	C493.315369,723.653320 493.697388,723.442749 494.486786,723.414307 
	C495.491760,723.607971 496.032440,724.430237 496.766724,724.007446 
	C496.936859,723.909546 496.215302,723.492065 495.846802,722.856567 
	C496.063995,721.186279 497.328186,720.801941 498.600983,719.892334 
	C500.755524,718.758545 502.697418,718.584656 504.706635,718.774292 
	C506.013245,718.897705 507.371307,719.254456 508.623016,718.712952 
	C517.401001,714.915405 527.136902,716.664185 536.417236,713.979858 
	C537.081604,713.896057 537.338074,714.836182 537.518799,713.933838 
	C536.734009,712.864136 536.443481,711.856995 536.139648,710.493652 
	C536.073792,709.689636 536.119141,709.232544 536.369568,708.472168 
	C536.658875,708.000366 536.809326,707.838989 537.252258,707.540161 
	C538.444824,707.100159 539.536377,707.555725 540.469238,706.466675 
	C541.037537,705.735657 541.558350,705.451172 542.472595,705.276794 
	C544.038269,705.303894 545.170593,705.038574 546.204834,703.750061 
	C546.983948,702.857117 547.612488,702.264099 548.583008,701.560608 
	C549.631409,700.968994 550.479126,700.727722 551.527222,700.140259 
	C553.883911,698.291565 556.271057,697.128723 558.864685,695.670166 
	C559.298706,695.324036 559.476196,695.190247 560.223267,694.713074 
	C560.995911,694.285767 561.199036,694.201904 561.713867,694.019165 
	C563.504395,693.636108 564.857483,692.979370 566.582520,692.416260 
	C568.864258,692.273987 570.631409,691.182190 572.949951,691.266846 
	C577.569275,691.739197 579.443726,696.222046 583.590759,697.515503 
	C584.090088,698.002502 584.065918,698.297729 584.049316,698.621277 
	C584.015442,699.284241 583.803650,698.463989 583.996765,699.101440 
	C584.117859,699.501160 584.115479,699.427551 584.304138,698.612427 
	C586.406921,697.325195 588.377197,696.448425 590.756348,695.787842 
	C591.242432,696.026062 591.390137,696.205322 591.654724,696.716125 
	C591.789673,697.282410 591.807617,697.517273 591.769043,698.101929 
	C588.331177,703.372925 582.941833,705.709045 578.172607,708.793335 
	C574.596191,711.106140 570.741272,712.988953 567.153809,715.837585 
	C566.660522,716.090210 566.427979,716.205994 566.194641,716.320190 
	C565.927917,716.450684 566.009033,716.465576 566.687500,716.237915 
	C568.323242,715.509949 569.752686,715.156921 571.588867,715.505981 
	C572.458252,716.125244 572.584167,716.746216 572.297791,717.738159 
	C571.718140,718.508423 571.171875,718.820923 570.336365,719.227051 
	C569.904785,719.449646 569.735840,719.539856 569.331970,719.785034 
	C568.808044,720.180908 568.542542,720.445557 568.136963,720.974854 
	C567.745728,721.551819 567.475037,721.849365 566.869202,722.214172 
	C565.664185,722.249329 564.949280,721.684753 563.795959,721.310730 
	C560.695740,719.714966 558.033020,720.293274 555.357361,721.543213 
	C550.693237,723.721985 546.023987,725.894165 541.043396,727.293762 
	C538.960815,727.879089 536.901917,728.596252 534.405457,727.636658 
	C532.598877,725.446106 534.256653,724.580688 535.854614,723.274292 
	C531.111816,724.047302 527.016357,725.766907 522.407654,726.817017 
	C521.432617,727.082520 520.776489,727.232300 519.816772,727.546997 
	C519.284485,727.727417 519.069763,727.794800 518.527710,727.944458 
	C516.204651,728.284851 514.225342,728.612671 512.032166,729.513428 
	C511.495209,729.705750 511.276794,729.774353 510.721985,729.918213 
	C509.469025,730.140808 508.563416,730.084229 507.357422,729.735596 
	C505.806030,728.839844 504.568939,727.971558 502.960114,729.633179 
	C502.331573,730.079407 501.924591,730.243896 501.154327,730.314209 
	C498.707825,728.956299 496.685120,728.919128 494.445740,730.840820 
	C492.536316,731.412292 491.003998,731.421997 489.279266,730.276733 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M533.635315,726.728516 
	C539.689514,727.248657 544.670959,724.529785 549.847778,722.564941 
	C551.988159,721.752563 554.188171,720.965271 556.125671,719.781433 
	C558.937378,718.063477 561.491516,718.129395 564.462158,720.026855 
	C565.630310,724.003906 562.033020,724.488342 559.956543,725.400269 
	C550.107422,729.726135 540.325623,734.280090 529.914124,737.188843 
	C528.477966,737.590088 527.029785,737.948120 525.277588,738.527954 
	C523.856934,738.959229 522.732544,739.097961 521.321167,739.434937 
	C520.805786,739.581726 520.602173,739.645325 520.090698,739.787354 
	C519.373047,739.950378 518.960449,740.017517 518.241089,740.111206 
	C517.334167,740.229858 516.730530,740.301147 515.845154,740.540955 
	C515.351013,740.692810 515.150879,740.751404 514.643127,740.883240 
	C513.072693,741.187805 511.770477,741.210754 510.224670,741.779907 
	C509.672028,741.952759 509.445282,742.015869 508.865906,742.150757 
	C505.275299,742.361084 502.057922,742.681763 498.490509,743.341187 
	C496.544983,743.466064 494.950195,743.268188 493.054871,744.026611 
	C491.822266,744.326843 490.968903,744.358521 489.684753,744.527100 
	C474.095520,743.964233 458.881653,744.676941 443.405090,742.214478 
	C441.644073,741.000061 440.066254,741.731750 438.308716,741.224365 
	C435.891876,739.100586 433.314087,740.373291 430.564819,739.953979 
	C429.164154,739.449341 427.779633,739.763245 427.166473,738.119873 
	C428.604370,735.636047 430.401733,735.067383 433.044922,735.778687 
	C436.649231,736.748718 440.439667,737.007263 444.080414,737.866882 
	C448.199738,738.839355 449.367310,735.479492 451.702820,733.154297 
	C456.333710,730.496094 460.751801,732.960144 465.125366,732.818787 
	C467.404114,732.745117 469.734344,733.373779 472.478790,733.734192 
	C475.633026,734.905884 478.561401,734.928284 481.283356,734.461487 
	C489.011078,733.136169 496.909088,733.892090 504.982971,732.169128 
	C512.899719,730.971619 520.117188,728.481323 527.954834,727.168640 
	C529.395752,727.063965 530.509033,727.209900 531.831543,726.578857 
	C532.553955,726.390869 532.972534,726.412170 533.635315,726.728516 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M538.071655,714.427124 
	C532.498230,715.990967 527.068420,717.366516 521.559753,717.856934 
	C517.270874,718.238647 513.134338,717.703064 509.124176,720.416626 
	C506.529419,722.172485 502.823425,718.649475 499.294434,720.873901 
	C493.313324,723.003113 487.496307,722.610535 481.328278,722.236450 
	C480.952393,721.964600 480.500427,721.972351 480.274780,721.985962 
	C479.139893,721.144653 477.824677,721.750671 476.672791,720.720276 
	C476.732452,716.374390 479.609436,715.782349 482.687347,715.544861 
	C486.425232,715.256592 490.198700,715.921387 494.320007,714.995300 
	C505.727844,712.538452 516.955872,711.499817 528.360779,708.990845 
	C530.182373,708.635864 531.599854,708.519470 533.458618,708.599182 
	C534.758240,708.705566 535.584839,708.914429 536.650879,709.692993 
	C537.388245,711.255798 537.784729,712.556641 538.071655,714.427124 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M424.853729,705.346008 
	C425.012054,704.233093 425.003967,703.486328 424.992554,702.366089 
	C425.849548,700.695618 426.049377,699.223206 426.195374,697.383179 
	C426.482025,696.375610 426.820526,695.768311 427.507202,694.962158 
	C428.190155,694.496826 428.640625,694.373230 429.459656,694.431702 
	C429.994415,694.614868 430.189880,694.728271 430.621460,695.075195 
	C431.639160,696.238464 432.149933,697.451721 433.744080,697.924316 
	C434.332153,698.204224 434.640930,698.389038 435.056213,698.794556 
	C435.879150,699.214050 435.388977,698.594910 435.738892,698.178711 
	C436.808319,697.049133 437.962036,696.622253 439.076385,696.877625 
	C443.821533,697.965088 448.754608,698.149780 453.680420,700.105835 
	C455.486420,702.384155 454.627380,703.967468 453.072693,705.459656 
	C451.785522,706.695007 450.074982,707.415161 448.671570,709.128418 
	C447.918396,709.665344 447.435577,709.868042 446.538696,710.056519 
	C443.158508,709.915405 440.123108,710.241516 436.782104,709.454712 
	C432.747498,708.993896 429.171539,708.240845 425.416321,706.644958 
	C424.957581,706.175293 424.822449,705.943726 424.853729,705.346008 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M426.810638,737.952087 
	C428.007507,738.331055 428.887604,738.885559 429.956329,739.689758 
	C428.851898,742.963257 429.513733,745.181213 432.733185,746.434509 
	C434.023193,746.936707 435.112061,747.706543 434.862274,749.365051 
	C434.721619,750.298889 434.122772,750.835388 433.201599,750.902161 
	C431.871277,750.998596 430.496033,751.163818 430.049530,749.335693 
	C429.975800,749.033752 430.801208,747.613098 428.985657,748.498962 
	C423.044006,751.398560 418.508606,746.517273 413.453552,744.937561 
	C410.526154,744.022644 412.368164,741.420715 413.817627,739.337158 
	C413.906067,738.569824 413.876740,738.117249 413.936432,737.325439 
	C413.929871,735.035278 415.437683,735.316345 416.323181,735.727661 
	C418.358032,736.672791 424.448090,738.300598 426.810638,737.952087 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M381.096069,683.320435 
	C382.401917,679.145813 385.052460,681.451172 386.885651,682.316406 
	C390.803436,684.165527 394.472321,686.542175 398.552002,688.838074 
	C399.550293,689.337708 399.446014,690.020447 399.783752,690.810425 
	C400.988281,688.408203 402.922302,687.684753 405.801941,688.664062 
	C404.422760,685.677612 400.137939,687.265991 399.984863,683.330933 
	C404.891571,682.270874 408.632416,685.201294 412.989258,687.332703 
	C413.825928,687.957947 414.357391,688.404358 415.305634,688.853638 
	C415.837555,689.104736 416.050385,689.210022 416.574646,689.496460 
	C418.166168,690.765381 419.888031,691.584106 419.143311,694.051208 
	C414.937958,695.410095 411.287994,692.936951 407.369720,693.284119 
	C405.806427,693.422607 404.129181,692.478760 402.348877,693.906372 
	C397.112122,696.854553 394.603180,691.872925 391.297394,690.305725 
	C387.785156,688.640625 383.991425,686.776978 381.096069,683.320435 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M402.013275,694.380859 
	C402.241760,692.063110 402.945374,691.147217 405.266296,691.514587 
	C409.653748,692.208923 414.113617,692.448486 418.783356,693.815186 
	C419.355133,693.710266 419.524658,693.617615 419.980713,693.484741 
	C420.653839,693.423462 421.039246,693.388489 421.715729,693.284546 
	C422.206940,693.194031 422.405975,693.160461 422.911987,693.103271 
	C423.426819,693.091248 423.634705,693.102844 424.151184,693.182312 
	C424.655518,693.333130 424.851257,693.416077 425.311340,693.680420 
	C426.391724,694.585693 427.047058,695.418762 427.180420,696.754089 
	C425.774109,698.539795 430.218964,701.640686 425.273621,702.096497 
	C421.700562,702.768127 418.284332,700.545044 414.981232,702.498291 
	C414.290070,702.907043 413.492645,702.022095 413.594055,701.005249 
	C413.819031,698.749878 412.566315,699.318115 411.106750,699.428772 
	C407.209167,699.724121 404.369598,697.649658 402.013275,694.380859 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M473.432159,734.966675 
	C466.248871,734.744873 459.540955,734.022095 452.434998,733.003174 
	C449.397888,730.934387 445.673248,732.983154 442.893829,730.261963 
	C449.136627,729.766479 455.112335,730.202148 461.087860,730.242065 
	C470.072906,730.302063 479.206573,732.913513 488.594727,730.051575 
	C490.711823,729.974182 492.415649,729.966431 494.544434,729.929932 
	C496.631805,728.644043 498.423065,728.880554 500.629333,729.698181 
	C501.329376,729.870239 501.588867,729.705444 501.907166,729.250977 
	C502.474548,728.645935 503.000488,728.498352 503.921204,728.883057 
	C504.260651,729.450806 504.275970,729.736938 504.085144,730.401123 
	C500.972076,733.455200 497.276398,732.474182 493.929108,732.735229 
	C487.207733,733.259155 480.688080,735.529663 473.432159,734.966675 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M419.827728,718.196411 
	C423.273682,715.430786 423.139801,720.195801 425.381470,720.408508 
	C429.252289,720.775757 432.635864,723.238037 437.084778,723.388733 
	C430.931610,727.871338 425.099365,728.069641 419.036621,724.279419 
	C418.302490,721.998230 419.394348,720.255493 419.827728,718.196411 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M424.723206,706.014343 
	C428.737793,706.591187 432.444031,707.307129 436.558044,708.020752 
	C437.773834,710.005066 439.894867,710.614075 441.351654,712.395264 
	C441.491852,712.968628 441.492920,713.212769 441.348175,713.801819 
	C440.998566,714.298645 440.794708,714.450500 440.224213,714.723633 
	C434.946075,714.259216 430.544922,711.544006 425.194519,711.199036 
	C424.863831,710.987854 424.520508,711.027161 424.348663,711.045044 
	C423.836121,710.761841 423.480957,710.479004 422.788147,710.106628 
	C422.464935,709.998962 422.484528,710.058594 422.454285,710.067017 
	C422.218475,709.980652 422.012909,709.885864 421.750793,709.455078 
	C422.283295,707.839050 423.360535,707.006775 424.723206,706.014343 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M476.733582,720.703735 
	C477.891266,721.137634 478.755524,721.310364 479.834473,721.741333 
	C476.144409,722.186829 472.293762,721.918335 468.292450,720.372253 
	C465.441925,718.803711 462.184723,720.041382 459.691223,717.548340 
	C459.964142,714.756714 461.935181,714.222778 463.640717,714.752014 
	C468.105713,716.137390 473.223938,716.215332 476.733582,720.703735 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M447.265686,716.781433 
	C446.464630,716.807922 445.896057,716.633118 445.158600,716.204224 
	C444.831635,715.705811 444.673584,715.461609 444.442322,714.786499 
	C444.475372,713.487732 444.872345,712.526123 445.690399,712.491943 
	C450.364716,712.296997 455.101776,712.057251 458.628967,716.505981 
	C456.554840,720.387939 453.936371,716.148010 451.334259,717.218811 
	C450.958008,717.002869 451.007568,716.986084 451.013184,717.011597 
	C449.947083,716.100098 448.692200,716.574402 447.265686,716.781433 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M481.013855,722.329102 
	C486.687775,722.213684 492.376709,721.616394 498.497101,721.029602 
	C498.053864,721.522461 497.179474,722.010620 496.114105,722.746887 
	C495.820618,723.210510 495.718079,723.426025 495.307800,723.824463 
	C494.555206,724.002930 494.110352,723.998596 493.331787,723.991028 
	C490.391205,722.348572 487.625153,722.404419 485.174835,723.980469 
	C482.981201,725.391418 481.891846,724.880981 481.013855,722.329102 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M425.066742,711.314636 
	C430.331543,710.164429 434.957092,713.587952 440.577393,714.022278 
	C441.298767,714.418762 441.445129,714.869446 441.134888,715.646851 
	C440.713776,715.820374 440.550385,715.727478 440.142151,715.494873 
	C439.105835,715.855286 438.298584,715.937561 437.267578,715.195923 
	C433.133514,713.843811 429.201569,712.742615 425.066742,711.314636 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M419.725555,717.921143 
	C420.135406,719.824341 420.381348,721.697571 419.104675,723.643066 
	C418.105042,724.064880 417.204803,724.256226 416.240265,723.239014 
	C416.094788,721.330261 415.758087,719.480286 417.854431,718.138550 
	C418.497498,717.840332 418.945587,717.852600 419.725555,717.921143 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M451.209961,717.292664 
	C453.125977,717.227234 455.293976,717.451660 457.757507,717.501282 
	C458.353058,716.740601 458.824097,716.520325 459.689331,717.038086 
	C462.463409,718.113953 465.014282,718.817261 467.793243,719.814636 
	C462.380920,720.057312 456.894043,718.999451 451.209961,717.292664 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M414.030762,706.382080 
	C415.945068,706.968201 418.112549,704.098938 419.935669,706.885620 
	C419.985596,707.436401 420.206604,707.819641 420.278381,708.030762 
	C420.350159,708.241821 420.294586,708.685425 420.126770,708.833069 
	C417.860962,708.576965 414.953094,710.476196 414.030762,706.382080 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M419.975098,708.987000 
	C419.985291,708.811584 420.011688,708.642395 420.032684,708.216553 
	C419.902710,707.805298 419.778198,707.650757 419.803833,707.239136 
	C419.953979,706.981995 419.980774,706.979065 419.989166,706.989502 
	C421.165833,705.773743 422.368317,704.620239 424.652130,704.980835 
	C425.020172,704.979919 425.033844,705.428833 425.032715,705.651978 
	C424.947052,707.340088 424.422638,708.481445 422.517212,708.892273 
	C421.779358,709.382019 421.276642,709.548828 420.324951,709.214233 
	C419.968384,708.991028 419.991302,708.993286 419.975098,708.987000 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M436.947510,715.252686 
	C437.851898,714.984070 438.638367,715.023193 439.661072,715.208740 
	C439.610626,716.785583 439.343872,718.699829 437.651825,718.581604 
	C435.994965,718.465820 436.043640,716.883057 436.947510,715.252686 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M413.736023,736.794006 
	C414.671631,737.429565 415.000916,737.958923 414.199524,738.867676 
	C412.620300,738.933350 412.021576,738.083862 411.831207,736.853394 
	C411.706085,736.044495 410.526245,734.881226 411.984375,734.530579 
	C413.211212,734.235596 412.812866,735.995117 413.736023,736.794006 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M438.000000,741.253052 
	C439.495300,741.157410 440.991821,741.307556 442.744781,741.728882 
	C441.612885,741.999512 440.224579,741.999023 438.419952,741.994385 
	C438.003662,741.990234 438.001221,741.498779 438.000000,741.253052 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M437.527344,726.434509 
	C438.109619,726.889587 438.567902,727.322144 437.987793,727.693909 
	C437.381927,728.082092 436.685150,727.875732 436.451782,727.147766 
	C436.267151,726.571960 436.705505,726.302307 437.527344,726.434509 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M492.619995,724.015747 
	C492.993500,724.172729 493.000031,724.340515 493.006897,724.760254 
	C491.763916,725.012207 490.520599,725.012207 488.954651,725.012207 
	C490.024078,723.488342 491.259338,724.256897 492.619995,724.015747 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M447.430664,716.999756 
	C448.060364,715.619263 449.295593,715.865784 450.801392,716.780518 
	C449.955200,717.030334 448.891663,717.023499 447.430664,716.999756 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M444.740601,714.822693 
	C444.983582,715.079285 444.981384,715.515076 444.985535,715.732544 
	C444.255432,715.948608 443.521179,715.947205 442.419739,715.956177 
	C441.892822,715.789307 441.733093,715.611877 441.715332,715.106018 
	C442.243134,714.489197 442.670929,714.298218 443.501373,714.247192 
	C444.068970,714.370361 444.283264,714.468262 444.740601,714.822693 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M411.194946,705.265686 
	C411.867188,705.142090 412.745422,705.267517 413.853210,705.639771 
	C414.082764,705.886597 413.997894,705.995544 413.951355,706.048767 
	C413.070160,705.906250 412.235535,705.710510 411.194946,705.265686 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M406.884216,702.804565 
	C406.583130,702.889648 406.176025,702.766907 405.768921,702.644226 
	C406.075531,701.995361 406.428284,702.326477 406.884216,702.804565 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M420.085510,709.357910 
	C420.460510,708.959900 420.952637,708.928711 421.817108,708.916992 
	C422.231201,709.125732 422.272949,709.315063 422.369354,709.789856 
	C421.683563,709.958496 420.943085,709.841614 420.085510,709.357910 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M411.009888,704.990723 
	C410.578094,704.858154 410.138000,704.733521 409.697937,704.608887 
	C410.036163,703.891724 410.401611,704.288086 410.876770,704.799683 
	C410.988953,705.016663 411.001587,704.998596 411.009888,704.990723 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M441.999573,714.573730 
	C441.937988,715.064270 441.951660,715.246033 442.008972,715.697205 
	C441.856750,715.973450 441.660950,715.980164 441.171143,715.950073 
	C440.926819,715.493652 440.976532,715.074036 441.003021,714.337402 
	C440.979797,714.020447 440.996155,714.003723 441.001617,713.999329 
	C441.434540,713.802124 441.790436,713.892212 441.999573,714.573730 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M440.269226,730.394897 
	C440.148987,730.544678 439.939819,730.565308 439.730591,730.585938 
	C439.880493,730.479248 440.030396,730.372498 440.269226,730.394897 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M436.991028,729.270752 
	C437.043427,729.887451 436.945557,729.897644 436.991028,729.270752 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M409.473389,733.447266 
	C409.531830,733.413025 409.414917,733.481506 409.473389,733.447266 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M403.050415,723.963501 
	C403.195190,724.103455 403.298309,724.280579 403.401428,724.457642 
	C403.298798,724.420044 403.196167,724.382507 403.013733,724.214233 
	C402.933960,724.083496 403.008728,724.000610 403.050415,723.963501 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M435.863373,762.817139 
	C435.937897,762.954712 435.519287,762.953735 435.311951,762.939758 
	C435.104614,762.925781 435.387512,762.585999 435.387512,762.585999 
	C435.387512,762.585999 435.788849,762.679504 435.863373,762.817139 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M393.404907,696.556824 
	C393.311646,696.424866 393.658661,696.356018 393.658661,696.356018 
	C393.658661,696.356018 393.498169,696.688782 393.404907,696.556824 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M391.558228,694.544189 
	C391.499420,694.572388 391.617065,694.516052 391.558228,694.544189 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M422.664246,710.305298 
	C422.938690,710.115540 423.412415,710.232056 424.031494,710.705750 
	C423.739075,710.912415 423.301331,710.762024 422.664246,710.305298 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M407.005402,702.995117 
	C407.140076,703.148743 407.269165,703.307373 407.398254,703.465942 
	C407.296906,703.417480 407.195557,703.369080 407.042206,703.166504 
	C406.990234,703.012390 406.999817,703.000061 407.005402,702.995117 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M385.473175,716.456116 
	C385.522095,716.428284 385.424255,716.483948 385.473175,716.456116 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M383.002228,725.003662 
	C382.656494,725.249023 382.309631,725.492615 381.950500,725.716614 
	C381.944061,725.720642 381.789795,725.487488 381.704498,725.364990 
	C381.975739,725.247559 382.247009,725.130127 382.755463,725.002686 
	C382.992676,724.992676 382.999878,725.000000 383.002228,725.003662 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M378.747803,726.348511 
	C378.999084,726.363220 379.135559,726.497192 379.272034,726.631226 
	C379.058990,726.576721 378.846008,726.522217 378.747803,726.348511 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M383.004028,730.004761 
	C382.911957,730.036377 382.815460,730.062927 382.853638,730.039795 
	C382.988312,729.990234 382.999573,729.999695 383.004028,730.004761 
z"
    />
    <path
      fill="#A67277"
      opacity="1.000000"
      stroke="none"
      d="
M367.012268,725.016479 
	C367.095795,724.968140 367.168976,724.907593 367.108887,724.916992 
	C366.975647,724.986938 367.001862,725.004272 367.012268,725.016479 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M387.306915,710.523560 
	C385.236084,709.665405 384.130219,708.536865 385.259949,706.669495 
	C386.025726,705.403748 387.247772,705.730225 388.155670,706.629089 
	C389.651154,708.109619 389.866150,709.546448 387.306915,710.523560 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M720.270020,668.119934 
	C719.897156,667.692139 719.704285,667.431274 719.215576,667.114014 
	C719.313049,666.212341 719.628540,665.424683 719.972046,664.318481 
	C720.021667,663.405151 720.205688,662.870667 720.794312,662.205566 
	C720.997559,662.000000 721.495117,661.992432 721.743896,661.990112 
	C726.080627,662.599854 730.287964,660.937500 734.337158,662.700439 
	C735.373962,663.151855 736.616272,663.143555 737.106873,664.664917 
	C737.085327,665.623962 737.171997,666.255249 737.258728,666.886536 
	C738.792603,666.670532 741.637329,665.769897 740.180481,668.543518 
	C739.426514,669.979004 737.297302,673.331055 733.901123,671.836365 
	C733.463745,671.643921 733.071533,671.348816 732.333740,671.050659 
	C731.561890,671.200439 731.137634,671.173767 730.511353,670.621338 
	C728.624756,666.891357 726.100037,667.263000 722.661255,668.909485 
	C721.515808,668.755981 721.021790,668.492615 720.270020,668.119934 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M737.211975,664.878540 
	C733.383911,664.764587 730.140259,661.153992 726.104431,664.124451 
	C724.563782,665.258484 723.833801,662.782288 722.277588,662.129822 
	C722.095703,654.882141 727.693726,657.889709 731.584473,656.998413 
	C732.168274,660.659973 736.274170,660.820007 737.829407,663.682617 
	C737.869812,664.296997 737.685852,664.557800 737.211975,664.878540 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M709.992798,639.374634 
	C714.784912,642.203979 711.190918,645.104553 709.369141,649.078552 
	C709.140686,645.279297 709.140564,642.498352 709.992798,639.374634 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M732.862061,644.797607 
	C734.006348,645.932129 734.470581,647.130737 732.934692,647.661072 
	C731.190979,648.263184 730.207825,646.479553 729.626770,645.368225 
	C728.876709,643.933655 729.915527,643.317566 731.698059,643.884644 
	C732.321350,644.066162 732.571777,644.262268 732.862061,644.797607 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M713.255249,638.920654 
	C713.050659,637.677917 712.907532,636.600586 714.149414,636.243225 
	C714.454895,636.155396 715.249207,636.608154 715.233276,636.743347 
	C715.100464,637.869690 714.963623,639.082581 713.255249,638.920654 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M740.652466,618.018921 
	C740.917297,619.447815 740.064331,620.004578 738.352112,619.994995 
	C738.458313,618.968811 739.180054,618.250977 740.652466,618.018921 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M720.710022,662.033875 
	C720.824768,662.551636 720.652039,663.103333 720.239624,663.827515 
	C718.945496,663.094543 719.198303,662.474976 720.710022,662.033875 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M733.013062,644.715942 
	C732.686768,644.893921 732.419006,644.702942 732.092773,644.207520 
	C732.164490,643.630615 732.354919,643.273926 732.545288,642.917175 
	C732.700745,643.421875 732.856140,643.926636 733.013062,644.715942 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M706.740540,653.989502 
	C706.584778,654.091064 706.427979,654.040771 706.271240,653.990540 
	C706.427368,653.939575 706.583435,653.888672 706.740540,653.989502 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M707.530823,651.549500 
	C707.477112,651.581299 707.584534,651.517700 707.530823,651.549500 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M708.542480,658.473083 
	C708.569763,658.521301 708.515137,658.424866 708.542480,658.473083 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M708.472717,660.457031 
	C708.521118,660.429504 708.424316,660.484558 708.472717,660.457031 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M709.527466,655.544128 
	C709.477844,655.572632 709.577087,655.515625 709.527466,655.544128 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M728.072388,461.671936 
	C726.734131,462.385468 727.836609,458.491425 725.359009,460.801453 
	C723.258362,460.163910 722.697266,458.359772 722.034851,456.699066 
	C718.116821,446.875275 713.240967,437.507446 708.329041,427.752441 
	C706.985779,421.503510 703.913330,416.468506 700.737549,411.468231 
	C698.983032,408.705688 696.945435,406.053558 696.667358,402.283875 
	C697.668213,400.867828 699.009216,400.274506 700.231323,400.207764 
	C702.956055,400.059021 704.617188,398.425079 706.654175,396.654724 
	C708.544861,397.117920 709.428711,398.339233 710.292664,399.559998 
	C717.290161,409.447021 724.424377,419.247620 729.947754,430.490601 
	C730.287476,431.241760 730.474243,431.673401 730.803528,432.427979 
	C731.222046,433.412231 731.386108,434.092804 731.090454,435.107117 
	C728.215820,436.109955 727.952515,439.310150 725.345581,440.498718 
	C722.411743,439.991455 722.236572,437.479401 720.927551,435.817871 
	C722.344421,439.821198 723.985168,443.729340 726.279175,447.700928 
	C726.969666,448.824127 727.501892,449.664185 728.899780,449.920868 
	C729.385620,450.065552 729.579956,450.130005 730.060303,450.312958 
	C731.268982,450.982544 732.245056,451.298462 733.682983,451.099365 
	C737.064941,451.509033 738.097717,453.574127 738.167969,456.652435 
	C735.605286,460.454071 731.616333,460.319214 728.072388,461.671936 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M730.948242,488.595093 
	C729.012146,492.252960 729.061462,495.969818 728.194397,499.764740 
	C725.155518,497.238190 726.912598,493.351776 725.665710,491.162567 
	C725.369812,489.602905 724.553894,490.492767 725.156860,490.208435 
	C725.348511,490.118073 725.500610,490.210358 725.521118,490.492035 
	C725.747742,493.606079 727.084839,496.632233 726.015137,500.090271 
	C725.544250,500.452393 725.322388,500.553345 724.733398,500.694794 
	C723.281067,500.655884 722.530273,500.042755 721.735718,498.881592 
	C721.471008,498.326843 721.391785,498.094055 721.269531,497.498779 
	C721.243958,496.896667 721.261230,496.657043 721.356445,496.071289 
	C722.326233,492.301300 719.483643,489.728485 719.265991,486.204346 
	C719.386963,483.603088 717.594910,481.544617 718.713623,478.934021 
	C721.037476,477.719940 722.358276,478.394318 723.060181,480.541382 
	C721.474121,476.337982 720.008911,472.069427 718.902344,467.311218 
	C718.602112,465.217529 718.049316,463.505524 718.554871,461.364502 
	C720.541870,459.800110 721.693726,460.744659 722.756348,462.630859 
	C723.693481,465.215607 724.550049,467.405426 725.674927,469.820007 
	C726.385864,470.462830 726.848816,470.795288 727.653687,470.168823 
	C730.206970,469.574829 730.121277,472.191589 729.908691,472.690613 
	C727.607727,478.092010 730.312500,483.059021 730.948242,488.595093 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M729.208984,431.760132 
	C723.179382,419.318909 715.179504,408.474152 707.015930,397.329041 
	C701.715088,392.697083 696.378906,388.529144 694.769958,381.258362 
	C697.840515,379.578247 698.830872,382.062927 700.534241,383.837097 
	C702.935303,386.882843 705.686279,389.148102 707.916382,392.339600 
	C721.557922,409.061493 732.292847,427.145172 741.042969,446.444092 
	C742.960938,450.674286 744.981567,454.858185 746.840576,459.525391 
	C747.067017,460.664612 747.001160,461.419922 746.488525,462.465637 
	C746.096130,462.914642 745.933289,463.051758 745.525757,463.394104 
	C743.909119,463.030670 743.200623,462.020966 743.322510,460.200989 
	C743.364502,459.719696 743.316711,459.556122 743.101929,459.199097 
	C738.834778,454.754486 737.527344,448.933167 734.359741,443.745850 
	C733.223267,440.750427 731.239502,438.523773 730.879028,435.323029 
	C730.852844,434.450165 730.668274,433.899200 730.243530,433.174225 
	C729.699463,432.726532 729.441833,432.414642 729.208984,431.760132 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M723.133423,462.906250 
	C721.797729,462.361084 720.758545,461.696411 719.408936,460.979614 
	C718.477722,460.651520 717.854553,460.271667 717.657166,459.636810 
	C714.453064,449.330139 708.390747,440.246674 704.983032,429.606445 
	C705.253601,427.185211 706.161316,425.980011 708.786743,426.765686 
	C715.421387,437.608643 720.302490,448.880371 725.084473,460.593597 
	C724.576477,461.566711 724.003235,462.176727 723.133423,462.906250 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M743.093262,459.981018 
	C743.935425,460.946869 744.458496,462.010468 745.131348,463.336670 
	C746.127197,465.836945 746.973267,468.074646 747.829834,470.671967 
	C745.576355,472.837189 743.305054,474.633575 741.050659,476.450958 
	C738.900818,478.184021 737.143921,477.904938 735.678955,475.531799 
	C734.563599,473.725037 733.092346,472.098053 733.031799,469.806458 
	C732.959351,467.066986 733.424683,464.744476 736.804932,466.956573 
	C738.257507,467.907166 739.269958,467.171783 740.855835,466.972351 
	C741.619629,466.389069 741.708862,465.829010 741.146484,465.008545 
	C740.955383,463.774231 740.973328,462.765442 740.995667,461.378357 
	C741.591431,460.692963 742.182800,460.385895 743.093262,459.981018 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M734.904175,442.766754 
	C737.767578,448.044464 740.344116,453.163666 742.927795,458.644257 
	C741.521118,458.360260 740.107239,457.714844 738.355896,457.033630 
	C736.626099,455.305939 736.907959,452.159088 733.349426,452.039215 
	C731.487000,451.395752 730.485352,450.247986 729.655273,448.504761 
	C729.454224,446.851837 729.396179,445.522919 730.726807,444.277496 
	C732.184265,443.546875 733.280518,442.783142 734.904175,442.766754 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M727.512512,469.950989 
	C727.833313,470.900757 727.892700,471.944061 726.645874,472.183990 
	C726.525574,472.207123 726.204956,471.189331 725.960327,470.349731 
	C726.362366,470.022186 726.781372,469.999603 727.512512,469.950989 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M740.920349,465.145935 
	C742.050598,464.510590 742.751099,464.963196 742.981323,466.052124 
	C743.139832,466.801910 742.753540,467.178894 741.599487,467.021790 
	C741.116089,466.452881 741.009705,465.980865 740.920349,465.145935 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M728.004395,462.004395 
	C728.423279,462.125000 728.837769,462.241211 729.252319,462.357422 
	C728.947388,463.044769 728.596741,462.653442 728.131958,462.203918 
	C728.012024,462.008026 728.000000,462.000000 728.004395,462.004395 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M727.540283,466.555511 
	C727.477844,466.593414 727.602661,466.517609 727.540283,466.555511 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M730.463562,465.529449 
	C730.438477,465.482910 730.488647,465.575989 730.463562,465.529449 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M740.947571,460.869995 
	C740.885132,460.866455 740.770264,460.732910 740.655457,460.599365 
	C740.735352,460.646240 740.815247,460.693115 740.947571,460.869995 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M727.554321,464.457245 
	C727.592041,464.518341 727.516541,464.396149 727.554321,464.457245 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M731.003967,543.531494 
	C731.001709,544.000000 730.518127,543.990784 730.276367,543.985229 
	C727.679260,541.419373 727.900513,538.071289 727.643921,535.022888 
	C727.115112,528.740051 727.217834,522.414795 727.379028,515.681641 
	C727.809082,515.060974 728.047302,514.863220 728.741333,514.754883 
	C731.341003,524.151733 731.028931,533.616699 731.003967,543.531494 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M725.197815,500.166229 
	C726.590149,495.562256 724.351440,491.379578 724.637085,486.908264 
	C726.977661,490.729553 726.709412,495.186890 727.656006,499.702820 
	C728.295166,504.419830 728.666138,508.844543 729.057373,513.726440 
	C726.181824,513.942932 726.927185,511.390533 726.351074,509.748718 
	C725.644348,507.734650 725.554932,505.556671 724.892090,503.173523 
	C724.665955,502.649933 724.587097,502.434723 724.453491,501.878967 
	C724.421936,501.068726 724.580383,500.650391 725.197815,500.166229 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M724.960144,502.072205 
	C728.002075,505.323303 726.923279,509.829254 728.815491,513.889587 
	C729.077576,514.183594 729.129944,514.516418 729.163452,514.680359 
	C728.980225,514.882446 728.763428,514.920532 728.218567,514.996033 
	C724.548584,516.088196 723.833862,513.588623 723.520569,511.378326 
	C723.088867,508.332733 722.964600,505.180023 724.960144,502.072205 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M735.527161,538.800781 
	C735.461365,537.463867 735.728699,536.094116 736.010376,534.366455 
	C737.322021,535.641724 737.661133,537.241821 735.527161,538.800781 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M736.016602,518.602966 
	C735.575806,518.949524 735.163208,518.926331 734.449341,518.817261 
	C734.113831,517.802551 733.645569,516.491943 734.875671,516.240540 
	C736.511658,515.906311 735.667175,517.536804 736.016602,518.602966 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M752.471069,621.918457 
	C752.724731,622.901001 752.923401,623.959900 752.602173,624.825928 
	C752.141602,626.067932 754.191162,628.216919 751.697571,628.497253 
	C749.815247,628.708801 747.237183,628.579773 746.021667,627.459717 
	C744.328613,625.899597 746.577942,624.361145 747.583801,622.974609 
	C748.809998,621.284424 750.813354,622.965332 752.471069,621.918457 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M744.186157,633.862671 
	C743.968445,633.560669 743.979553,633.116089 743.996582,632.338135 
	C744.699890,632.441895 745.011658,632.971802 744.186157,633.862671 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M745.470459,630.454529 
	C745.523682,630.423218 745.417297,630.485840 745.470459,630.454529 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M729.065186,600.955566 
	C729.386230,599.562988 729.701111,598.335999 731.070068,600.577087 
	C730.520813,601.039917 730.112366,600.996399 729.398743,600.919556 
	C729.093628,600.886230 729.009155,600.997437 729.065186,600.955566 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M731.249634,600.761841 
	C731.478027,600.879761 731.597412,601.102539 731.716797,601.325317 
	C731.526611,601.352478 731.336365,601.379639 731.037720,601.245117 
	C730.929321,601.083435 731.140503,600.866699 731.249634,600.761841 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M725.290710,501.834900 
	C725.084473,504.759888 724.628357,507.682831 724.641785,510.603638 
	C724.649719,512.323120 724.997437,514.288452 727.549805,514.968628 
	C729.984558,524.282349 728.119812,533.830200 730.021973,543.537109 
	C729.889893,553.748840 730.357727,563.537720 729.064575,573.621338 
	C728.878601,575.473206 728.722046,576.974731 728.302979,578.740601 
	C725.165222,580.735596 723.688782,583.372742 722.857300,586.554871 
	C722.487244,587.971191 721.688721,589.502930 719.836304,589.330505 
	C718.101135,589.169067 718.271301,587.534363 717.866577,586.304932 
	C717.250000,584.432373 716.148621,582.712891 716.168518,580.271423 
	C716.069885,578.459717 718.625183,577.322266 716.835938,575.358643 
	C719.332764,574.647705 719.019653,572.932190 718.877625,571.337463 
	C718.757568,569.989197 717.482849,568.685913 719.056213,567.086426 
	C719.713867,566.417236 719.803955,565.939819 719.244629,565.259155 
	C718.345398,562.269653 717.298035,559.486206 718.717407,556.275757 
	C718.995117,556.004883 719.138428,556.201355 719.071655,556.099426 
	C718.489319,554.067078 718.703979,552.181702 718.851013,550.186646 
	C719.441833,542.172119 719.513977,534.120728 719.999939,526.096436 
	C720.147217,523.665283 718.556702,522.026184 717.700867,520.231934 
	C715.718628,516.076294 717.153931,511.277161 714.372559,507.150146 
	C712.082947,501.153046 710.187378,495.344208 708.586487,489.049805 
	C708.693115,485.353455 706.982605,482.465576 706.264771,478.987000 
	C705.592041,477.312347 704.807983,476.064789 704.640991,474.239807 
	C705.876343,472.209869 706.604797,469.413666 709.764893,472.124634 
	C712.051941,475.735443 712.786255,479.529266 713.136597,483.315186 
	C713.367310,485.808807 714.398193,487.322083 716.467468,488.378601 
	C716.556519,488.424072 716.765503,488.429047 716.777954,488.397400 
	C716.837769,488.245728 716.849182,488.074982 717.184204,487.733063 
	C721.320862,488.769135 720.467773,492.788635 722.122070,495.679626 
	C722.113403,496.058350 722.077637,496.532074 722.042480,496.766968 
	C721.645447,497.137817 721.508850,497.392242 721.807373,497.853577 
	C722.512146,498.526093 723.007202,499.110199 723.739502,499.867523 
	C724.340942,500.502716 724.205505,501.178375 724.900757,501.751709 
	C725.154663,501.896027 725.238159,501.831970 725.290710,501.834900 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M751.126465,523.805908 
	C749.819763,522.788025 749.518127,521.741394 751.698364,521.067505 
	C751.822876,521.896179 751.625488,522.768799 751.126465,523.805908 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M752.317383,520.936890 
	C751.995483,520.587463 751.969238,520.162537 751.949829,519.415527 
	C752.407288,519.086548 752.857849,519.079651 753.646240,519.062866 
	C753.824890,519.881653 753.527893,520.605713 752.317383,520.936890 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M751.217896,525.914917 
	C750.979675,525.967590 750.955872,525.483643 750.980835,525.242676 
	C751.261353,525.165283 751.516907,525.328918 751.772339,525.492554 
	C751.666931,525.615784 751.561523,525.739014 751.217896,525.914917 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M716.590210,579.968140 
	C717.887939,582.828552 718.784241,585.655334 719.743835,588.681641 
	C723.838135,586.427246 720.378723,578.687012 727.639587,579.003296 
	C727.983337,582.054504 727.926147,585.104187 727.824158,588.614014 
	C725.094421,590.682495 721.610046,591.407898 721.718323,595.772217 
	C721.752197,597.138367 720.055481,597.641296 718.571777,597.262268 
	C717.239441,596.921875 715.955322,596.496277 716.038391,594.753113 
	C716.124512,592.945068 716.033875,591.083313 716.452942,589.349548 
	C716.835083,587.768555 717.106750,586.454651 715.332520,585.168884 
	C714.161865,583.013794 714.533691,581.325073 716.590210,579.968140 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M746.088562,460.218689 
	C742.165283,452.430664 738.429688,444.870605 734.889465,437.220154 
	C727.596252,421.459473 717.876709,407.255341 707.112854,393.316803 
	C708.182251,389.258728 711.412231,391.860687 713.828125,390.903076 
	C714.802002,390.950409 715.426147,391.085907 716.340210,391.219666 
	C717.244385,391.251434 717.668152,390.920013 718.187012,390.192749 
	C718.490723,389.761597 718.620239,389.587921 718.976318,389.166473 
	C721.217407,386.901215 723.434570,386.995026 726.046387,388.514954 
	C727.718079,394.876526 731.557007,400.521393 729.258606,407.395721 
	C728.368713,410.057434 730.302979,412.837341 731.183472,415.524628 
	C733.948792,410.835938 736.319458,410.381989 739.570557,414.626862 
	C742.088074,417.913879 744.740051,421.011169 747.898376,423.775146 
	C753.280457,432.200867 756.398560,441.654938 760.172302,451.203369 
	C760.503662,453.213501 759.772827,453.965973 758.128662,454.150665 
	C752.233398,454.812836 751.610596,455.530090 751.596985,461.302399 
	C751.592102,463.370544 752.332520,465.963806 748.424011,465.798645 
	C747.925476,465.557953 747.698853,465.091583 747.383545,464.381348 
	C747.452148,462.777039 747.303589,461.492859 746.088562,460.218689 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M748.156372,466.372589 
	C749.795776,465.078766 750.789124,464.152405 750.241516,461.777679 
	C749.543884,458.752655 749.612793,455.656555 752.806885,453.440125 
	C754.687500,452.135162 756.015808,450.635468 758.135925,452.993500 
	C758.895874,453.838745 759.510010,452.782013 760.098755,451.843079 
	C761.147522,453.795197 762.061951,456.030853 763.091919,458.618652 
	C766.142761,462.165527 766.777588,466.398529 767.670044,470.296509 
	C769.458252,478.106384 772.537842,485.587219 773.843384,493.786621 
	C775.845764,494.321198 776.397949,495.165405 775.122681,496.890869 
	C771.005127,497.573181 774.230347,499.884460 773.773804,501.608704 
	C773.103516,501.993591 772.440552,502.025726 771.446167,502.073547 
	C769.435486,500.444214 768.406616,498.311401 767.385376,496.300232 
	C765.054077,491.708832 764.147888,491.180481 759.493835,493.100067 
	C758.714722,493.421417 757.929932,493.646118 756.759399,493.283997 
	C753.646729,484.348694 751.907349,475.220459 748.156372,466.372589 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M756.536072,493.557739 
	C759.504456,490.914459 763.093323,490.698181 766.687622,490.461731 
	C767.428772,490.412964 768.411377,490.852722 768.236450,491.203705 
	C766.118713,495.451874 771.845276,497.446594 771.076172,501.670837 
	C771.136841,505.140411 770.024780,507.748169 768.463684,510.363800 
	C766.609436,513.470642 764.200012,514.943298 760.437256,514.734863 
	C759.761108,511.763428 759.281738,508.627441 758.561401,505.199280 
	C757.758667,501.260651 757.196838,497.614197 756.536072,493.557739 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M745.848267,460.377808 
	C748.235657,460.182007 749.041931,461.245392 747.528931,463.811768 
	C747.062927,463.728912 746.831055,463.320374 746.429077,462.844727 
	C746.134033,462.093048 746.009094,461.408539 745.848267,460.377808 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M774.082153,493.620117 
	C771.582825,495.871124 772.208435,493.296112 772.005310,492.315399 
	C769.718811,481.275146 766.926453,470.375092 763.210205,459.346100 
	C763.635925,458.977234 764.064514,458.983673 764.814392,458.979797 
	C767.425781,462.724915 767.594727,467.153259 769.111511,471.565674 
	C769.477966,473.092255 769.744629,474.238800 770.139221,475.719421 
	C770.702271,477.844971 771.137451,479.636353 771.771118,481.716400 
	C773.301086,484.613861 774.441162,487.265076 773.672119,490.682617 
	C773.690063,491.789124 773.844604,492.521881 774.082153,493.620117 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M773.855103,491.057465 
	C772.992371,488.308868 772.449158,485.561371 771.937744,482.409424 
	C775.585693,483.830048 775.216248,487.514435 776.006287,490.657837 
	C775.395752,490.791382 774.785217,490.924957 773.855103,491.057465 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M790.982056,464.006104 
	C790.750732,464.898621 790.658142,465.841400 790.271240,466.641907 
	C790.079651,467.038177 789.211548,466.755005 789.310486,466.307465 
	C789.468201,465.594482 790.011414,464.966736 790.690002,464.168060 
	C790.988403,464.033173 790.999390,463.997681 790.982056,464.006104 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M789.527893,479.544830 
	C789.480042,479.572113 789.575745,479.517548 789.527893,479.544830 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M767.678467,421.975555 
	C767.636292,423.214874 768.933289,425.278809 766.546265,425.425903 
	C765.520874,425.489105 765.107666,424.264984 765.051880,423.204834 
	C764.943970,421.154572 766.290283,421.811981 767.678467,421.975555 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M768.003052,422.003235 
	C768.324646,421.279083 768.631042,420.545441 768.994141,419.840973 
	C769.013977,419.802368 769.700989,419.950989 769.745850,420.107513 
	C770.025391,421.082581 769.544006,421.656891 768.302979,421.953491 
	C767.993591,421.994049 768.000000,422.000000 768.003052,422.003235 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M763.415527,416.748932 
	C763.794495,417.234528 763.818909,417.452881 763.415527,416.748932 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M772.078918,425.076965 
	C771.951538,425.543243 771.749146,425.943512 771.546753,426.343781 
	C770.981445,425.994629 771.164673,425.592285 771.656616,425.033081 
	C771.849060,424.884491 772.003845,425.010956 772.078918,425.076965 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M768.473755,443.454285 
	C768.524841,443.424286 768.422729,443.484283 768.473755,443.454285 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M730.678772,669.671631 
	C731.110901,670.307800 731.301941,670.569275 731.791016,670.886108 
	C731.251953,672.277222 730.944519,673.854797 729.242554,674.824951 
	C728.719910,674.713562 728.476562,674.494141 728.212830,674.297607 
	C727.922729,674.897827 728.020020,674.147339 728.748840,674.752319 
	C728.537170,678.513306 724.396301,675.905029 723.308289,678.354309 
	C721.608948,675.692322 720.168030,672.988586 721.965332,669.394043 
	C722.009827,669.019348 722.003479,669.002808 722.009583,669.006531 
	C724.862610,667.523682 727.672791,666.924072 730.678772,669.671631 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M753.625488,683.352051 
	C754.638000,682.990845 755.439697,682.722778 756.068298,683.428894 
	C756.105896,683.471130 755.580750,684.170532 755.213135,684.387085 
	C754.272217,684.941467 753.379333,685.166870 753.625488,683.352051 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M746.172363,690.038452 
	C745.486511,690.434937 745.005493,690.649353 744.261597,690.937866 
	C743.690979,690.766174 743.493286,690.451172 743.419067,689.780029 
	C743.515625,689.318787 743.597656,689.145142 743.949219,688.869751 
	C744.902283,689.202209 746.170410,688.476746 746.172363,690.038452 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M743.002441,688.004456 
	C743.353638,688.016663 743.654968,688.140442 744.063721,688.574097 
	C744.218811,688.768066 744.055725,688.938293 743.973511,689.022705 
	C743.701782,689.188416 743.512146,689.269714 743.052368,689.199585 
	C742.717896,688.852234 742.653442,688.656189 742.798584,688.232300 
	C743.008118,688.004456 743.000305,688.000000 743.002441,688.004456 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M799.502441,643.221558 
	C799.943665,643.169495 800.209106,643.291443 800.474548,643.413452 
	C800.362732,643.532166 800.221252,643.768188 800.144470,643.748718 
	C799.860229,643.676636 799.597473,643.520264 799.502441,643.221558 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M805.269043,642.418701 
	C805.664795,642.257751 806.272156,642.304626 807.042114,642.637512 
	C806.630005,642.824524 806.055298,642.725464 805.269043,642.418701 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M803.023193,641.975525 
	C802.938721,642.001038 802.835632,642.053101 802.893311,642.031006 
	C803.054077,641.956848 803.004517,642.002075 803.023193,641.975525 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M769.072144,530.702393 
	C768.822266,530.997131 768.640625,530.989929 768.187134,530.960815 
	C768.149536,530.518738 768.383850,530.098694 768.994751,529.752319 
	C769.294373,530.017578 769.217346,530.209045 769.072144,530.702393 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M767.499878,528.353516 
	C767.732727,528.340942 767.811462,528.441284 767.890198,528.541687 
	C767.708740,528.516663 767.527283,528.491577 767.499878,528.353516 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M524.949646,799.699951 
	C524.500671,800.262817 524.004089,800.518433 523.246216,800.874207 
	C523.159119,800.093872 522.644470,798.899170 523.956848,798.552429 
	C524.182251,798.492920 524.582581,799.095825 524.949646,799.699951 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M535.527344,790.543152 
	C535.479187,790.570251 535.575500,790.516052 535.527344,790.543152 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M522.732422,800.988831 
	C522.709778,801.098816 522.444946,801.212524 522.093140,801.161865 
	C522.006165,800.997559 522.490356,800.992554 522.732422,800.988831 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M683.146240,694.299377 
	C683.946716,693.150330 684.859863,692.311035 685.915649,691.184692 
	C686.574829,691.217590 687.091248,691.537598 687.885742,691.981384 
	C690.898499,694.113037 688.208374,697.196899 689.962036,699.963623 
	C691.620850,697.446655 688.290833,695.453796 689.845215,693.196899 
	C691.293091,692.972229 691.878235,692.090698 692.793335,691.146729 
	C693.523071,690.541260 694.120361,690.313904 694.988098,689.965454 
	C695.300354,689.913696 695.572083,689.895264 695.708008,689.903625 
	C697.875732,692.104431 698.693176,690.570374 699.622253,688.488281 
	C705.942932,688.171814 707.755676,691.753113 704.299805,697.795288 
	C703.141968,697.768433 701.672180,696.699036 701.417542,697.769958 
	C700.212830,702.836243 696.141663,705.641174 692.997681,709.557373 
	C693.131592,710.091553 693.281006,710.263245 693.401306,710.777405 
	C693.168823,711.539734 692.893066,711.906372 692.265503,712.423157 
	C691.756836,712.704041 691.539307,712.790100 690.978027,712.948059 
	C689.931824,713.090637 689.238464,713.026611 688.219604,712.789307 
	C685.066711,710.799866 682.363770,714.534241 679.261902,712.673950 
	C678.835571,712.286377 678.694336,712.099731 678.445435,711.576782 
	C678.220093,710.266968 678.446350,709.370361 679.044067,708.198120 
	C679.105774,706.417114 679.067566,704.972595 679.024414,703.079041 
	C679.647400,699.541321 684.499451,698.685242 683.146240,694.299377 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M540.948547,794.106689 
	C540.954712,794.268433 540.720764,794.500732 540.241394,794.793091 
	C540.159546,794.549927 540.397034,794.324585 540.948547,794.106689 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M678.760559,707.899902 
	C680.416016,708.729797 679.618896,709.574829 679.000732,710.706055 
	C678.886047,710.988892 678.565918,711.317810 678.565918,711.317810 
	C678.565918,711.317810 678.707520,711.754333 678.912903,711.683594 
	C680.927979,712.750671 682.454346,712.271545 683.991577,710.953735 
	C685.456970,709.697388 686.948425,709.324036 687.892456,712.043213 
	C689.106140,714.656189 686.954590,715.111084 685.610229,716.343628 
	C684.859802,716.682922 684.401917,716.774963 683.585144,716.725037 
	C683.021301,716.580688 682.801331,716.503479 682.292419,716.229309 
	C682.003479,716.032288 681.989075,716.016907 681.979309,716.012695 
	C681.799133,715.890442 681.628723,715.772400 681.233521,715.869141 
	C680.135620,717.204651 678.889832,716.978638 677.348267,717.008057 
	C676.444275,716.830933 675.903564,716.642334 675.190552,716.216431 
	C674.339417,715.318970 673.664612,714.656494 673.799438,713.288574 
	C673.971558,710.011841 675.342163,708.241272 678.760559,707.899902 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M673.639282,713.026245 
	C674.278015,713.757019 674.612732,714.547913 674.982849,715.658936 
	C672.253296,715.287415 671.983154,714.823120 673.639282,713.026245 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M684.752808,716.183594 
	C685.945557,714.987854 686.921387,713.978882 687.927429,712.690308 
	C688.718140,712.322205 689.478577,712.233643 690.620850,712.116699 
	C691.187317,712.177002 691.371887,712.265564 691.734131,712.103577 
	C692.079407,711.579346 692.304077,711.365540 692.797974,711.111084 
	C693.361877,710.662048 693.780823,710.490356 694.627319,710.591553 
	C696.636414,711.078430 694.861328,713.446594 697.015869,713.890137 
	C698.395569,716.675720 696.266357,719.791016 698.561279,722.605774 
	C698.608948,723.159790 698.593933,723.381958 698.474609,723.917725 
	C697.844666,725.159668 697.196228,725.995056 696.218018,726.932251 
	C695.630859,727.371033 695.369995,726.822876 695.218018,726.929993 
	C694.989868,727.090759 695.149963,727.399353 695.614075,727.843140 
	C696.023865,729.959961 694.701904,730.740601 693.255554,731.821777 
	C691.291931,732.384705 689.535156,732.701416 687.389221,733.008911 
	C686.454956,731.368225 687.768494,730.088196 687.914062,728.240662 
	C688.641907,726.079163 689.467102,724.264893 688.482300,722.266907 
	C687.791077,722.821228 687.870667,723.771118 687.113037,724.669067 
	C686.700134,725.105286 686.512451,725.259216 685.990967,725.563721 
	C684.915527,725.890076 684.203796,725.826538 683.260986,725.199951 
	C683.003845,722.594238 683.017273,720.236389 683.220825,717.231812 
	C683.790527,716.537476 684.165466,716.465820 684.752808,716.183594 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M688.714844,728.001953 
	C689.142273,730.003418 689.272339,731.604431 687.176636,732.710144 
	C684.250061,735.150696 681.044373,733.479492 677.683350,734.104614 
	C677.259583,734.149170 677.149597,733.841248 677.075012,733.695801 
	C677.607666,731.819031 679.236877,731.092041 680.687012,729.675598 
	C681.732727,727.895874 683.082397,727.190552 685.069458,726.793762 
	C686.583801,726.714111 687.635864,726.895203 688.714844,728.001953 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M689.040894,728.019165 
	C687.863892,728.259033 686.820068,728.155701 685.376526,728.026428 
	C684.494080,727.483582 683.918457,727.073853 683.211975,726.309937 
	C683.015137,725.827148 682.988159,725.653442 682.975830,725.215881 
	C683.740479,724.953796 684.490479,724.955505 685.629150,724.972961 
	C687.482300,723.619568 686.003723,720.444458 689.024719,720.065857 
	C689.402466,720.018555 689.828064,720.352661 690.231445,720.510010 
	C689.213135,722.800415 692.242249,725.687988 689.040894,728.019165 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M682.675537,725.983521 
	C683.974243,726.031982 684.657288,726.399048 684.933960,727.686279 
	C683.649780,728.133484 682.764526,729.012634 681.558594,729.950439 
	C681.047058,730.180908 680.831055,730.310303 680.310547,730.216187 
	C680.251099,728.351562 677.515808,725.006042 682.675537,725.983521 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M679.599243,730.001099 
	C680.214966,730.005005 680.423889,730.017334 680.947937,730.040649 
	C680.038818,731.091919 678.814636,732.132324 677.295410,733.361511 
	C677.364441,732.143127 676.457153,729.948853 679.599243,730.001099 
z"
    />
    <path
      fill="#2A3642"
      opacity="1.000000"
      stroke="none"
      d="
M676.719727,735.103760 
	C678.076660,735.619751 679.440063,735.920105 679.968262,737.656799 
	C678.899536,737.686523 677.778381,737.399109 676.306702,737.145874 
	C674.851746,736.187805 675.800842,735.733643 676.719727,735.103760 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M682.085327,716.267639 
	C682.203003,716.017578 682.402466,716.002930 682.900879,716.013672 
	C683.291321,716.228271 683.382751,716.417358 683.231812,716.807739 
	C682.989441,717.009094 682.998474,717.001099 683.003174,716.997437 
	C682.655762,716.953369 682.375549,716.789795 682.085327,716.267639 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M681.249023,716.066345 
	C681.159363,715.721436 681.270020,715.285217 681.891724,715.827759 
	C681.969543,716.008545 681.489319,716.048645 681.249023,716.066345 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M680.150269,741.132690 
	C679.988586,742.205872 679.214722,743.657959 678.589844,743.293701 
	C677.688599,742.768494 678.213074,741.567810 679.604614,740.977051 
	C679.899780,740.901367 680.065125,741.057373 680.150269,741.132690 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M512.081665,273.060974 
	C504.139679,278.039917 495.131073,276.828827 486.845581,278.866211 
	C480.434235,280.442688 473.997589,281.773285 467.473694,284.333771 
	C467.325134,282.463135 466.103058,279.921906 469.239838,279.056580 
	C470.600189,278.681305 469.724640,277.729980 469.555511,277.102844 
	C469.054840,275.246429 467.940460,272.618958 470.007385,271.938873 
	C472.330170,271.174622 475.442352,269.476868 477.956177,272.853455 
	C479.031067,273.829132 480.183472,273.873413 481.380127,274.629730 
	C482.131134,274.741913 483.004425,274.687714 483.003601,274.656982 
	C482.881805,270.123230 486.061584,272.079681 488.562622,272.002869 
	C491.225769,272.461975 493.199890,271.278503 495.636353,270.693604 
	C499.167389,269.829926 502.408203,269.181641 505.661285,267.383789 
	C506.745026,267.028809 507.497803,266.990875 508.639954,267.161102 
	C510.897980,267.946106 511.982574,269.329010 512.494751,271.629669 
	C512.477356,272.275726 512.419617,272.530945 512.081665,273.060974 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M445.496460,261.434143 
	C448.771484,265.657532 452.160461,265.923096 453.908264,262.016693 
	C454.720245,260.201874 455.191376,258.231873 457.596771,259.689972 
	C459.861725,261.062927 459.698608,263.425934 458.408325,265.277557 
	C456.870483,267.484344 455.922089,269.757416 455.894897,272.710938 
	C455.757446,273.390869 455.187225,273.988983 455.271332,274.102570 
	C458.856018,278.943176 455.004547,279.279724 451.421417,280.026031 
	C449.425415,281.505707 448.603363,283.910370 445.781708,284.455841 
	C444.484314,284.448059 443.708923,284.006836 442.836334,283.096619 
	C442.574158,282.627258 442.504333,282.424896 442.424683,281.911682 
	C442.465088,281.408203 442.515320,281.215576 442.698608,280.773804 
	C443.105286,280.047760 443.242371,279.566742 442.650452,278.853119 
	C438.268494,272.787079 439.642731,263.998383 445.496460,261.434143 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M506.231262,267.919281 
	C503.672668,271.320007 499.696136,270.368866 496.309601,271.906799 
	C493.999512,270.746094 491.549744,270.931580 489.424133,269.360321 
	C489.206024,266.263977 489.479095,263.536316 493.529358,263.047791 
	C497.976868,262.485199 501.912567,261.505066 505.654724,259.005859 
	C506.354828,261.865997 506.328735,264.765839 506.231262,267.919281 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M422.750916,296.352051 
	C427.837646,295.586853 432.888428,295.933929 437.795532,293.075775 
	C438.855621,293.781219 438.411377,295.578003 440.041718,294.393738 
	C441.510712,293.326660 442.377594,291.777130 443.566193,290.028870 
	C444.037445,289.678925 444.261200,289.587830 444.850281,289.500244 
	C445.980530,289.768463 446.445312,290.260315 446.282715,291.451874 
	C446.379364,292.444244 446.626923,293.097595 446.993103,294.082581 
	C444.297363,296.800446 440.960724,298.306122 437.368500,300.003601 
	C434.311523,300.184235 431.630524,299.310486 428.650391,300.077271 
	C427.970551,300.503662 427.535370,300.639038 426.730713,300.695557 
	C425.926880,300.598511 425.485992,300.457855 424.746887,300.116821 
	C423.824982,299.602753 423.294861,299.151733 422.680023,298.295654 
	C422.402222,297.530212 422.400513,297.072113 422.750916,296.352051 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M446.415100,291.594666 
	C445.936951,291.273315 445.742096,290.755768 445.305481,290.089722 
	C444.750397,289.683197 444.437103,289.425018 443.903870,288.946411 
	C443.563354,288.553162 443.442810,288.380310 443.168610,287.927246 
	C442.730621,287.054504 442.518799,286.437775 442.390015,285.453247 
	C442.604919,284.093384 443.181793,283.509430 444.566528,283.765076 
	C447.397308,283.680206 448.027588,280.646454 450.743042,280.087402 
	C451.445160,281.322845 451.795746,282.573669 452.238953,284.154968 
	C453.433411,283.263214 452.612488,280.883972 454.684906,281.189850 
	C453.844238,287.546112 453.844238,287.546112 446.415100,291.594666 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M489.119995,269.499634 
	C491.722015,268.674835 493.869446,269.421661 495.778809,271.629028 
	C493.545044,271.967102 491.460205,275.242126 488.885071,272.299225 
	C488.983765,271.290588 489.029144,270.572906 489.119995,269.499634 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M460.627106,272.836548 
	C459.851501,270.328064 459.857941,268.217163 463.562164,268.283630 
	C462.488678,269.598969 464.977844,273.426819 460.627106,272.836548 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M481.581299,274.499512 
	C480.483856,274.147858 478.442688,276.575348 478.008911,273.457275 
	C479.718140,272.016754 481.010620,271.791565 481.581299,274.499512 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M487.731903,253.324402 
	C488.151672,252.978668 488.627960,252.990280 489.460632,253.021393 
	C490.315125,253.804520 490.813263,254.568146 491.182739,255.654297 
	C489.270233,256.201141 488.554260,254.906006 487.731903,253.324402 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M491.124603,256.276611 
	C491.390106,256.117493 491.774567,256.286011 492.358948,256.738342 
	C492.120422,256.882874 491.681976,256.743561 491.124603,256.276611 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M444.730591,283.714600 
	C443.930115,284.038422 443.222260,284.385925 442.953735,285.701477 
	C442.908752,286.453522 442.896973,286.894318 442.734802,287.648376 
	C442.393127,288.346893 442.107147,288.683350 441.473389,289.130432 
	C440.962036,289.303040 440.741821,289.316833 440.246948,289.146118 
	C439.982758,287.918976 439.993317,286.876404 439.995361,285.442841 
	C439.213135,283.998474 438.185608,283.247040 436.886749,282.312958 
	C436.565796,281.072418 437.091858,280.301270 437.424164,279.169373 
	C437.345764,278.275238 437.052399,277.772034 436.546936,277.044800 
	C435.938263,276.113373 435.552124,275.400055 435.253998,274.309326 
	C434.560669,270.858887 435.348175,267.841461 436.080719,264.402100 
	C436.847137,262.758362 437.563965,261.527008 438.546906,260.131042 
	C441.579437,263.770416 442.887085,258.152161 445.542725,258.963867 
	C445.888550,259.601715 445.884125,260.254425 445.870239,261.223236 
	C440.572021,264.918365 439.475281,274.680359 444.003906,278.707458 
	C444.024292,279.584137 443.863495,280.124207 443.346802,280.838928 
	C443.196564,281.083954 443.130127,281.120850 443.107697,281.151550 
	C442.925201,281.301361 442.765106,281.420502 442.809357,281.758362 
	C443.546143,282.474243 444.078583,282.971436 444.730591,283.714600 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M465.269775,245.386627 
	C466.757507,244.661362 467.903046,244.087967 469.328644,243.374390 
	C470.307465,245.068436 470.724823,247.303970 469.046906,248.070419 
	C466.815186,249.089859 462.793243,249.629807 461.783112,248.146881 
	C460.012024,245.546906 463.833008,246.527390 465.269775,245.386627 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M464.547668,240.472992 
	C464.578705,240.525696 464.516632,240.420303 464.547668,240.472992 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M462.536743,241.545624 
	C462.489838,241.571747 462.583618,241.519485 462.536743,241.545624 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M462.527496,243.547287 
	C462.479584,243.575226 462.575409,243.519348 462.527496,243.547287 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M500.998718,226.000946 
	C501.242065,226.336670 501.486725,226.671478 501.731384,227.006256 
	C501.612579,227.089661 501.493805,227.173080 501.375000,227.256516 
	C501.261139,226.991348 501.147247,226.726181 501.018005,226.228973 
	C501.002686,225.996948 501.000000,226.000000 500.998718,226.000946 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M506.400208,268.156982 
	C506.040009,265.338257 501.860809,262.544006 505.962158,259.321716 
	C506.647278,257.759064 507.305115,256.531952 508.202545,255.125610 
	C512.548279,254.641586 510.557678,259.180756 512.993469,260.613831 
	C515.662598,263.365387 515.978210,259.317047 517.954834,259.286224 
	C519.199646,258.898102 520.124451,258.887268 521.373901,259.293518 
	C522.662292,261.325562 523.661133,262.835846 525.564880,260.198853 
	C526.220032,259.291290 527.158081,258.604248 528.143066,257.508636 
	C529.713745,256.029694 531.200439,255.063934 533.664734,254.410568 
	C530.521057,253.948318 528.755798,252.312790 526.904358,250.242233 
	C526.706421,249.725754 526.696533,249.496719 526.869324,248.975998 
	C530.554565,249.413208 528.090515,245.788254 529.985596,244.997314 
	C532.732300,245.904816 534.520203,247.480972 534.797058,250.625717 
	C537.598755,246.585434 536.234863,241.144638 540.723450,238.124405 
	C541.257507,238.927094 541.240723,240.399399 541.826355,240.700714 
	C547.011719,243.368423 546.088867,249.415771 548.803467,253.799805 
	C548.605591,254.307144 548.132507,254.373169 547.909790,254.309082 
	C546.884521,254.270630 547.499634,255.087646 546.850708,255.557114 
	C543.306519,257.429993 540.894043,260.308136 536.636719,260.855286 
	C533.743591,261.227142 532.441345,265.646851 530.112610,267.996094 
	C528.797424,269.322876 527.848450,270.987640 525.660767,269.270935 
	C524.293701,268.198151 523.656555,269.228699 523.115662,270.423950 
	C521.530151,273.927399 518.875000,274.834900 515.104248,273.253998 
	C514.420471,272.912109 515.126892,271.953430 513.909912,272.287720 
	C513.357788,272.339111 513.134155,272.292786 512.718384,271.963745 
	C511.719513,270.371460 512.598633,267.705414 509.325043,268.172943 
	C508.252594,268.199219 507.511322,268.166870 506.400208,268.156982 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M530.048706,244.614777 
	C530.121521,245.523682 529.565002,246.443726 529.802979,246.753632 
	C532.144775,249.803436 529.498169,249.020477 527.688538,249.150177 
	C525.480164,249.956848 523.944519,248.182755 522.139343,248.481583 
	C521.052917,248.661423 519.750977,248.374527 519.197388,249.720993 
	C519.003174,250.193375 519.298706,250.679016 519.643921,251.033478 
	C520.508545,251.921280 521.530029,252.282379 522.762024,251.887360 
	C524.193054,251.428558 524.992126,249.939026 526.837036,249.661865 
	C530.122375,250.831360 532.246948,253.571777 535.920349,253.614594 
	C534.250305,256.306702 531.137939,255.770950 529.289795,257.888885 
	C526.988831,259.014038 524.679260,258.828705 522.352661,259.819092 
	C520.946960,259.928314 519.869629,259.994568 518.386963,260.053894 
	C516.962097,259.851868 516.382141,257.322205 514.758179,259.743530 
	C514.452393,260.178253 514.309448,260.339996 513.566162,260.722290 
	C510.732117,261.984711 510.613190,260.055389 510.125031,258.829956 
	C509.667633,257.681763 510.480682,256.176788 508.807709,255.087646 
	C510.708588,247.705109 516.243286,244.916183 523.616333,244.057831 
	C524.174561,243.885559 524.339661,243.751709 524.742676,243.830048 
	C525.147583,244.057388 525.314453,244.072495 525.730591,244.054626 
	C526.144226,244.145737 526.308533,244.269836 526.559143,244.097336 
	C526.645447,243.800751 526.786011,243.776398 526.837891,243.726334 
	C527.855896,243.898453 528.822021,244.120636 530.048706,244.614777 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M526.469849,243.828293 
	C526.681946,244.192184 526.805115,244.662064 526.045166,244.206421 
	C525.979858,244.021622 526.294250,243.855835 526.469849,243.828293 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M524.915771,243.856552 
	C524.817810,244.079010 524.654968,244.115753 524.250732,244.085968 
	C524.160767,243.591492 524.377075,243.320282 524.915771,243.856552 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M493.531555,204.879913 
	C493.047058,205.735962 492.386658,206.292252 491.458832,207.030853 
	C489.256042,205.870224 490.337677,204.396088 491.071136,202.611938 
	C491.445312,202.198486 491.712219,202.269669 492.061279,202.676453 
	C492.548615,203.448486 492.977325,203.997238 493.531555,204.879913 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M493.815369,205.178192 
	C493.183655,204.661850 492.659851,204.143997 492.160583,203.240814 
	C493.096924,203.030838 493.878754,203.361908 494.086548,204.749512 
	C494.117126,205.081772 493.923279,205.176697 493.815369,205.178192 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M498.242554,209.983444 
	C498.007751,209.819183 498.015472,209.639587 498.059204,209.191864 
	C498.487457,209.126389 498.879730,209.329056 499.271973,209.531708 
	C499.009705,209.677170 498.747406,209.822647 498.242554,209.983444 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M495.002686,214.231537 
	C495.180511,214.147110 495.370087,214.309738 495.510986,214.507111 
	C495.541321,214.549606 495.395935,214.717545 495.161316,214.918915 
	C494.993103,214.837112 494.995056,214.664078 495.002686,214.231537 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M412.399475,634.244690 
	C386.592834,619.832764 365.866852,600.752991 351.177612,575.392395 
	C342.834808,560.988708 336.613831,545.714844 331.973755,529.360107 
	C328.295776,524.057983 327.825775,518.093628 326.907898,512.447815 
	C325.122986,501.469025 323.743103,490.395325 324.480042,478.760376 
	C324.535675,477.138611 324.314758,475.967346 324.424347,474.349030 
	C324.958740,464.661530 325.830811,455.452759 327.597961,445.921600 
	C328.774292,438.551514 331.094696,431.892578 332.788452,424.663940 
	C333.882172,422.132874 333.973206,419.530823 336.511597,417.974182 
	C337.369781,420.957855 337.502472,424.174927 341.824402,424.839905 
	C343.092407,427.729095 341.616150,429.978210 340.885742,432.267670 
	C337.862976,441.742371 336.503418,451.510284 335.466095,461.762817 
	C335.430725,468.319153 334.166107,474.368652 334.415070,480.556427 
	C334.688721,487.357910 336.329193,494.089783 336.409760,500.740875 
	C336.528625,510.552460 338.735474,519.845581 340.654175,529.669800 
	C347.533844,558.379150 361.126617,582.995728 381.385345,603.854919 
	C389.542664,612.253967 399.519897,618.377563 409.352478,624.621460 
	C411.700409,626.112427 414.363831,627.418030 415.101135,630.954834 
	C414.942963,632.823486 414.681091,634.235168 412.399475,634.244690 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M356.952026,398.283203 
	C357.620392,389.981232 363.481934,384.469238 366.969177,377.848816 
	C370.185211,371.743317 374.585419,366.202789 379.540192,360.822449 
	C381.179871,360.891205 381.792542,362.048370 382.867432,363.085876 
	C383.262848,363.578583 383.488739,364.060730 383.792480,363.918060 
	C384.385132,363.639771 383.886810,364.174286 383.667389,363.277649 
	C385.902954,360.469330 388.463501,358.375458 391.067444,355.847015 
	C391.876984,355.238098 392.463837,354.901276 393.325073,354.354858 
	C394.020844,353.938507 394.448456,353.758789 395.248810,353.609497 
	C395.820465,353.694000 396.037628,353.790558 396.473755,354.170166 
	C397.432892,356.005219 397.305420,357.717041 397.619476,359.712463 
	C397.532684,362.208466 396.402161,363.857483 394.988068,365.302460 
	C390.690857,369.693512 387.885864,375.365906 382.641663,379.277344 
	C381.083191,378.872894 380.689423,377.831482 380.383759,376.412109 
	C380.391663,375.691315 380.857086,375.500183 380.886017,375.097778 
	C380.894043,374.986145 380.771759,374.927582 380.663757,374.932434 
	C379.997742,374.962189 380.732574,374.712463 380.460999,375.597198 
	C379.165222,377.397766 380.200195,378.513519 381.256226,379.966003 
	C381.674652,382.021667 381.114288,383.609863 380.177612,384.989716 
	C374.009430,394.076202 369.096832,403.862061 363.844849,413.837891 
	C360.941528,414.955261 359.792084,412.847382 358.343262,411.420715 
	C357.231201,410.325653 356.683075,408.483673 354.263306,408.778625 
	C353.464294,408.744202 353.035828,408.600128 352.458069,408.075928 
	C351.815826,404.600647 354.100555,402.538116 355.687744,399.861969 
	C356.672852,399.636658 356.962677,399.231659 356.952026,398.283203 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M690.772949,348.075684 
	C694.248413,348.388885 695.977844,351.088074 698.530884,353.185059 
	C698.728088,353.671570 698.698669,353.904999 698.476379,354.432068 
	C695.307373,357.961517 690.932007,359.276154 687.514648,362.477478 
	C686.943726,363.313202 683.468994,361.872070 685.999756,364.392609 
	C686.888611,365.277893 687.700073,366.243591 688.911377,367.321411 
	C689.770691,368.454132 689.999084,369.415405 689.810364,370.841370 
	C688.521912,373.321991 686.756226,372.993744 684.572754,372.198425 
	C678.689636,366.900421 672.874329,362.047974 667.646851,356.130005 
	C667.394897,354.206329 666.499634,353.161896 664.814087,352.308685 
	C663.009277,350.120880 661.074036,348.587982 658.710571,347.010864 
	C657.984924,345.825806 657.932129,344.870575 658.313660,343.543610 
	C658.925659,342.565491 659.574463,342.310181 660.681396,342.394836 
	C661.429504,342.694275 661.811890,342.944550 662.416870,343.460876 
	C662.980896,343.984680 663.288574,344.292847 663.827026,344.813660 
	C664.532837,345.394531 665.050476,345.700348 665.767456,346.193176 
	C666.142029,346.425385 666.292053,346.507477 666.619202,346.750793 
	C666.796326,346.911987 667.154724,347.140533 666.978577,347.015411 
	C666.802429,346.890289 666.453064,346.586365 666.241089,346.469666 
	C665.607666,346.062958 665.192017,345.764404 664.526123,345.349426 
	C663.998291,344.983765 663.726257,344.733643 663.268433,344.266022 
	C662.801636,343.790253 662.522400,343.529572 662.002563,343.102112 
	C661.169434,342.389832 660.441711,342.122467 659.384033,341.857483 
	C658.920044,341.669983 658.742310,341.574890 658.316589,341.305603 
	C657.284363,340.500671 656.564148,339.787170 655.412964,339.124390 
	C654.966553,338.851044 654.788330,338.737244 654.345947,338.441650 
	C653.055176,337.434814 651.894531,336.773651 650.780457,335.122742 
	C650.584167,334.259857 650.631653,334.031891 650.873291,333.511780 
	C653.448425,331.056030 655.266846,332.953094 657.448303,334.551147 
	C658.494446,331.815033 659.456665,329.604523 657.768433,327.026978 
	C656.750671,325.473175 657.101807,323.885376 658.869385,322.463196 
	C666.051270,326.319580 672.530579,330.918915 678.805298,336.301117 
	C679.075073,337.009766 679.303528,337.373810 679.998291,337.685120 
	C680.328796,337.720856 680.048584,337.846283 680.178040,337.763794 
	C681.294067,339.358276 680.575684,341.177368 680.766663,342.910889 
	C681.147034,346.362213 681.646057,349.875702 686.596680,350.248779 
	C689.949524,352.918823 690.172180,350.490234 690.772949,348.075684 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M640.853943,282.770020 
	C641.000183,283.014709 640.978760,283.501801 640.995056,283.757996 
	C644.500183,283.512451 644.187805,286.569153 644.482422,288.299316 
	C645.429138,293.859894 649.200928,296.853302 653.796021,299.724731 
	C651.364563,304.551849 649.648132,305.070953 643.378113,303.081970 
	C633.278931,298.998688 623.784302,294.388580 613.408325,290.929504 
	C615.559631,289.025024 617.241760,286.042297 620.813477,285.783447 
	C622.060242,285.693085 621.838928,284.314850 621.992920,283.354095 
	C622.639099,279.323029 625.896240,278.602661 628.559753,279.840485 
	C630.970154,280.960663 633.589783,280.466431 634.557068,279.656036 
	C638.474915,276.373810 638.295593,280.021881 639.522461,281.993408 
	C640.202209,282.006165 640.502747,282.171478 640.853943,282.770020 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M526.598022,287.260864 
	C547.331421,284.803406 567.342957,287.786774 587.647949,292.057678 
	C589.669556,295.916443 585.690002,297.190125 584.472656,300.048035 
	C583.780640,300.565002 583.321350,300.735870 582.464783,300.813660 
	C578.265869,299.690704 574.272034,299.619934 570.033264,298.924255 
	C569.195007,298.833191 569.088806,297.542694 568.027832,298.541321 
	C566.850708,299.053955 565.946289,299.085358 564.708374,298.773315 
	C563.857300,298.884094 564.197021,297.256378 563.064148,298.347076 
	C559.927979,299.115479 557.075867,299.727783 553.779785,299.363464 
	C552.222717,299.373901 551.025757,299.285065 549.501526,298.865723 
	C548.720215,298.514740 548.325134,298.235046 547.757080,297.613037 
	C546.693176,295.724518 546.951843,294.120636 547.650879,292.289368 
	C545.972656,292.698700 544.395264,292.763489 542.598877,291.930969 
	C541.403381,291.319397 540.702209,290.514343 539.416504,290.129150 
	C538.537842,290.015228 537.964966,290.089233 537.136230,290.455505 
	C536.445984,290.679169 536.033203,290.746307 535.294312,290.795929 
	C533.109558,291.014374 531.693787,293.726196 529.196411,291.604370 
	C526.930115,290.604218 526.877319,292.195831 526.718872,293.364807 
	C526.509705,294.908630 527.289307,296.535522 526.111816,298.297913 
	C525.433960,298.809540 524.984680,299.001892 524.147766,299.218262 
	C522.541138,299.384308 521.308044,299.363342 519.761047,298.906250 
	C517.921082,297.964874 516.500488,295.412537 514.580872,298.830322 
	C514.153381,299.228119 513.950989,299.354462 513.391785,299.547455 
	C512.792847,299.576294 512.550720,299.538513 511.987244,299.314941 
	C510.655823,297.954681 510.334869,296.462891 509.697815,294.704041 
	C509.313599,293.339630 509.159546,292.339264 509.761353,291.021698 
	C513.305908,289.756134 516.223877,291.917328 519.753235,292.049774 
	C520.340759,292.349579 520.594604,292.523590 520.794739,292.749023 
	C521.001526,292.981964 521.217773,293.192902 521.511169,293.293793 
	C521.588196,293.320282 521.696594,293.255646 521.790588,293.232849 
	C521.521667,293.283508 521.286804,293.220520 520.913330,292.721649 
	C521.062256,289.054108 522.575867,287.018341 526.598022,287.260864 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M342.740997,425.396118 
	C346.346527,424.474945 344.309570,420.114471 347.425690,419.061157 
	C349.739075,421.252594 348.246979,423.757782 348.185486,426.573944 
	C346.690582,432.868652 346.107025,438.923798 344.490570,445.196472 
	C343.423187,453.602234 343.036987,461.662689 341.607880,470.040710 
	C341.459961,483.462830 342.761871,496.353333 344.408112,509.643188 
	C346.181274,522.911804 349.315033,535.373352 354.714752,547.208862 
	C355.402222,548.715759 356.113281,550.227722 355.475220,552.324463 
	C351.930450,553.786499 351.444031,550.807678 350.508148,549.012268 
	C346.982269,542.247986 344.463013,535.074524 342.380249,527.305664 
	C339.733246,521.402649 339.171539,515.426453 338.170074,509.571716 
	C336.253204,498.365601 334.096741,487.190063 334.444366,475.711761 
	C334.555084,472.055939 334.935089,468.469818 335.512817,464.444641 
	C336.242859,450.968018 338.861053,438.274933 342.740997,425.396118 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M514.643677,271.904053 
	C518.282959,273.491791 520.796204,273.608704 522.135437,268.956085 
	C523.589172,263.906128 525.778748,267.874451 527.680725,269.237244 
	C529.497314,265.427795 533.411621,263.260803 534.965759,259.308899 
	C535.125732,258.902069 535.867249,258.291901 535.953796,258.349976 
	C540.722534,261.549042 542.433228,255.015213 546.563354,255.158783 
	C549.508972,258.868286 548.468445,262.087280 545.303345,265.260925 
	C544.767151,266.115204 543.897217,265.854156 543.599182,266.427094 
	C544.266479,266.361084 544.673462,265.849182 545.490417,265.340607 
	C546.507690,264.894318 547.241699,264.829742 548.381836,264.962891 
	C551.999939,266.639160 551.525818,269.319885 550.619629,272.396179 
	C549.863708,273.291260 549.162903,273.474182 548.068481,273.538147 
	C546.857300,273.597382 546.054932,273.857727 545.024048,274.453125 
	C544.304260,274.656036 543.880676,274.654755 543.197144,274.447327 
	C542.551636,274.342560 542.579163,272.578583 541.865173,274.064148 
	C541.362061,275.110992 540.589661,276.003387 540.023071,277.378418 
	C538.536072,277.718567 537.195923,277.774445 535.457275,277.765015 
	C534.794250,277.509705 534.504028,277.352997 534.252136,277.150055 
	C529.065125,272.970886 529.066101,272.969666 523.218140,277.707458 
	C521.076782,279.161438 518.854553,278.729095 516.816345,279.188568 
	C510.216400,280.676422 510.224579,280.712677 511.800018,273.723633 
	C511.833527,273.131226 511.865601,272.926636 511.932495,272.413452 
	C512.118469,271.961395 512.269653,271.817963 512.657043,271.879333 
	C513.093201,272.097900 513.293152,272.111694 513.793274,272.107666 
	C514.226257,271.942596 514.359131,271.795258 514.643677,271.904053 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M726.121948,388.699585 
	C723.646301,388.826508 721.247375,387.972168 718.832275,389.879761 
	C717.529358,389.973541 716.852661,390.521881 715.985596,391.394958 
	C713.038696,392.467621 710.264648,392.570190 707.234863,392.730957 
	C704.750183,390.522919 702.499756,388.047180 700.140869,385.252899 
	C700.719666,381.886719 705.211731,382.277710 705.382935,378.590363 
	C701.549866,377.456512 699.526245,374.795837 700.060181,370.124634 
	C699.997009,369.559296 699.982605,369.332123 699.977295,368.764771 
	C700.016357,368.200836 700.046204,367.977081 700.157104,367.426147 
	C700.382751,366.677948 700.545288,366.264038 700.860962,365.557312 
	C701.085754,365.058350 701.179016,364.860718 701.416138,364.369598 
	C702.192505,362.924835 702.621033,361.691589 703.159729,360.138855 
	C703.438660,359.651764 703.576355,359.471710 704.040894,359.197937 
	C707.086792,361.426117 709.815247,363.740112 711.163452,367.584534 
	C711.356995,368.998138 711.188721,369.993683 710.669922,371.111023 
	C710.631531,371.282043 710.476868,371.577209 710.612366,371.450500 
	C710.970520,370.793671 711.437134,370.609955 712.278992,370.497284 
	C714.233215,370.991913 714.729980,372.400238 715.374329,373.737122 
	C717.327576,377.789734 720.010254,381.234192 724.252625,383.623505 
	C724.765808,384.138947 724.924255,384.660675 725.055542,385.460083 
	C725.580811,386.549957 726.132080,387.362823 726.121948,388.699585 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M368.197205,357.707947 
	C357.993256,370.583221 350.859467,384.834015 343.951416,399.602997 
	C343.945587,400.561829 343.806122,401.106628 343.235840,401.791992 
	C342.696533,402.471497 342.356720,402.942291 341.886322,403.681763 
	C339.562805,405.767548 337.322083,404.450348 334.750031,403.902100 
	C332.778778,402.909027 332.850586,400.763763 331.156555,399.519104 
	C330.848877,399.010590 330.761169,398.787201 330.695374,398.204681 
	C340.315308,389.135834 347.277191,378.528381 353.202209,367.105835 
	C355.417175,362.835632 358.372314,358.965546 361.847229,355.087341 
	C362.350952,354.730560 362.569397,354.609161 363.152771,354.387054 
	C365.762695,354.278839 367.508972,355.050903 368.197205,357.707947 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M397.538910,360.125488 
	C395.343445,358.709473 396.214020,356.716278 396.009644,354.576294 
	C394.122528,349.104187 397.914093,345.518768 399.483215,340.933533 
	C400.934326,337.489227 404.053009,336.293854 406.589111,334.058533 
	C407.756958,333.362885 408.836395,333.134064 409.655945,332.021667 
	C409.762451,331.726990 409.861450,331.848633 409.789062,331.818481 
	C410.103363,331.781586 410.419250,331.628662 410.779297,331.076752 
	C410.894165,330.823944 410.941650,330.931335 410.891724,330.900452 
	C413.000732,329.890015 415.106506,328.832214 416.584534,326.581299 
	C418.750458,324.651825 420.972046,323.072998 423.522888,321.350586 
	C424.462830,321.576111 425.165649,321.775452 425.970520,322.497986 
	C426.630737,323.719086 426.413788,324.653748 426.009033,325.916321 
	C425.058868,327.774902 423.480194,328.344086 421.869629,329.382874 
	C420.957275,330.107300 419.714996,330.351105 420.726929,331.870117 
	C421.082550,332.243073 421.225433,332.397400 421.572632,332.801514 
	C422.420044,333.970123 423.043579,334.899506 423.388000,336.337585 
	C422.156555,340.150146 419.134583,341.551147 415.792908,343.059479 
	C413.145935,343.436371 411.238647,344.242035 410.670837,347.182770 
	C407.294586,352.674164 403.116150,356.957520 397.538910,360.125488 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M665.752808,317.195740 
	C664.624878,316.715607 663.851013,316.308014 662.885010,315.712341 
	C664.537720,309.268372 662.389709,302.917786 663.289001,296.482056 
	C664.075867,290.850861 666.429138,286.348999 668.805786,281.249329 
	C669.517334,279.145935 668.970703,277.679688 667.502319,276.121277 
	C667.226318,275.624695 667.134155,275.412262 666.945740,274.859467 
	C666.705750,273.805115 666.650940,273.081146 666.596680,271.980408 
	C666.746216,269.045441 666.414917,266.507507 666.349243,263.576508 
	C667.085327,261.927338 668.062500,261.076416 669.845581,260.848785 
	C671.229004,261.966187 671.493347,263.306244 671.998047,264.971375 
	C672.626160,270.623932 673.902283,275.782257 674.340210,281.434998 
	C674.332092,282.052185 674.323181,282.294434 674.293335,282.888794 
	C675.102173,285.732758 674.745117,289.614380 679.741821,287.551147 
	C680.821777,287.530182 681.410889,287.871002 682.036743,288.749817 
	C682.642090,291.651062 681.582458,294.228912 679.530640,294.949707 
	C674.073914,296.866730 674.937256,301.412476 675.128296,305.152985 
	C675.288879,308.296570 673.788025,310.681732 673.212830,313.794128 
	C672.860352,314.501556 672.504578,314.803558 671.784302,315.214233 
	C669.694092,316.049957 668.205139,317.520233 665.752808,317.195740 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M461.660034,302.163788 
	C470.684204,297.977966 479.701172,294.801941 489.558105,292.933014 
	C495.335083,293.551086 496.285187,294.849976 495.794922,301.485016 
	C494.853485,303.031891 493.700623,303.684998 492.423492,304.033112 
	C484.501282,306.192657 476.640045,308.530060 469.012207,311.602753 
	C467.940948,312.034302 466.766693,312.068207 465.206543,311.721954 
	C461.609802,309.343689 460.622894,306.277069 461.660034,302.163788 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M669.908020,279.699402 
	C670.051086,283.041748 670.241577,286.084686 666.652954,287.491943 
	C666.147339,287.690186 665.489014,288.590973 665.573914,289.039215 
	C666.828552,295.662048 661.116699,302.372284 665.789673,308.903473 
	C666.790161,310.301819 665.655273,310.607452 664.883301,311.024109 
	C663.362305,311.845062 663.011536,313.219299 662.781738,315.134338 
	C656.229736,311.575928 649.766724,307.627625 643.148682,303.376434 
	C646.478882,302.273438 650.718201,304.298950 653.673096,300.152679 
	C657.014465,299.184631 659.049255,298.541992 658.528137,294.060394 
	C658.101929,290.395477 657.781128,286.335297 658.732910,282.221283 
	C659.767822,283.086212 659.573914,284.246552 659.241577,285.344696 
	C659.742310,284.541504 659.402710,283.547729 659.787903,282.319763 
	C660.012512,282.018707 660.000000,282.000000 660.009766,282.006104 
	C661.152527,280.686157 662.486633,279.805542 664.655273,280.144043 
	C665.535828,280.354950 665.710876,281.058319 666.314331,281.124756 
	C666.059387,280.855499 665.654175,280.727905 665.563965,279.981873 
	C666.840027,277.638275 668.270325,277.977264 669.908020,279.699402 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M546.787354,272.201630 
	C547.567505,272.184143 548.119080,272.336548 548.849976,272.719055 
	C549.547485,273.226654 549.722412,274.265503 550.867188,273.489502 
	C553.019043,272.595581 553.758545,271.293610 553.110107,268.971619 
	C553.150574,268.437073 553.192322,268.227478 553.384277,267.740173 
	C553.737183,267.218536 553.980286,267.036957 554.265442,266.922272 
	C554.569153,266.800079 554.475647,266.823730 553.697754,267.103088 
	C552.778503,266.935150 552.503540,266.414978 552.493286,265.438477 
	C552.642944,264.933502 552.740845,264.743561 553.040100,264.303894 
	C553.831787,263.434082 554.482239,262.880066 555.394409,262.140259 
	C556.589294,261.308472 556.840088,260.187073 557.606079,259.038086 
	C557.938599,257.912598 558.144409,257.085754 558.593445,256.049316 
	C559.203613,255.763885 559.466309,255.552139 559.429565,255.057617 
	C559.115295,254.505692 559.011230,254.120117 558.845947,253.441269 
	C558.476196,252.359924 558.504578,251.542023 558.797974,250.379486 
	C559.181213,249.974411 559.336243,249.910690 559.736328,249.790726 
	C563.165039,251.145538 567.248962,250.749451 569.229980,254.603439 
	C569.859497,255.828125 570.931641,254.587036 571.768127,254.419540 
	C574.095093,253.953674 575.716431,254.395187 575.796021,257.491699 
	C575.187500,260.094849 572.816650,261.295319 572.291992,263.955627 
	C571.019836,267.988098 568.751099,268.463165 565.736511,266.363342 
	C564.150269,265.258484 562.477295,264.140167 560.891113,265.973022 
	C559.372986,267.727203 558.316345,269.766998 559.304871,272.328125 
	C560.069580,274.309326 561.683105,276.000244 561.259399,278.619843 
	C554.999512,278.552277 548.942383,278.199646 542.430786,277.753601 
	C541.019287,276.438782 541.635010,275.420563 542.699829,274.227905 
	C543.446289,274.021851 543.887756,274.008850 544.660400,273.989990 
	C545.475098,273.408844 545.826782,272.705475 546.787354,272.201630 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M725.968872,389.432678 
	C725.657043,388.165039 725.350769,387.298492 725.036377,386.084839 
	C727.094604,385.912018 727.481812,387.782166 728.305786,389.117126 
	C733.000488,396.723785 737.625122,404.373749 742.552246,412.234192 
	C744.572876,415.957245 746.314941,419.455017 748.095947,423.296021 
	C747.980652,424.094452 747.826355,424.549622 747.672058,425.004822 
	C745.808594,422.412689 741.934143,422.063721 740.726929,418.673706 
	C740.294006,417.458374 739.566467,416.258423 738.696106,415.304413 
	C735.426819,411.720734 734.483154,411.971588 732.655762,416.520142 
	C732.256348,417.514313 732.071472,418.822388 730.276123,419.258026 
	C730.461975,415.395905 727.884155,412.338623 727.672119,408.574493 
	C727.617615,407.607025 727.083984,406.644653 727.695984,405.717346 
	C730.046387,402.155701 728.946960,398.751160 726.992126,395.586823 
	C725.827637,393.701752 726.094116,391.784668 725.968872,389.432678 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M615.740173,300.871094 
	C623.539001,302.892670 630.474609,306.287231 637.670715,309.976990 
	C639.177002,311.707153 638.721802,312.860382 637.075500,313.650879 
	C636.009155,314.162903 634.953491,314.620056 635.452637,316.439423 
	C635.682190,317.186218 635.767212,317.626556 635.835571,318.416931 
	C635.689209,320.578339 635.999634,322.646698 633.153198,323.146301 
	C630.541077,320.385773 625.740845,320.712372 624.870239,316.373718 
	C624.447083,317.149261 624.411133,318.169006 623.119507,318.748718 
	C620.900513,319.268066 619.320740,318.699249 617.769287,317.076843 
	C616.795410,314.603760 616.361938,312.237885 617.013245,310.121368 
	C617.872314,307.329681 616.392883,305.917450 614.564331,304.044586 
	C614.220947,302.628296 614.562683,301.742157 615.740173,300.871094 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M336.934448,417.605286 
	C335.478577,419.678650 335.189209,421.797913 334.514771,424.125275 
	C333.397949,424.302643 332.584717,424.038208 331.976654,423.460999 
	C329.490540,421.100708 327.847321,421.084747 326.976379,424.866394 
	C326.393585,427.396912 324.477905,429.129425 322.451752,430.637085 
	C321.504395,431.342010 320.417145,431.912415 319.236572,431.178467 
	C318.071289,430.454041 318.177948,429.242523 318.207001,428.060730 
	C318.289948,424.688293 320.898621,421.962158 320.569275,418.165039 
	C320.513947,417.824493 320.669342,417.554565 320.824615,417.542511 
	C320.692047,416.931763 320.334991,416.363647 320.163910,415.378937 
	C320.196686,414.835754 320.240326,414.622192 320.412537,414.111115 
	C321.144958,412.949890 321.750671,412.087128 322.092285,410.721008 
	C322.931915,408.753723 323.795105,407.161194 324.773865,405.242126 
	C325.313812,404.357086 325.730530,403.806183 326.410980,403.020233 
	C326.853821,402.649750 327.053345,402.529266 327.589966,402.314240 
	C329.842438,402.070404 331.987915,403.336365 331.754974,404.615845 
	C330.856445,409.550385 334.439484,410.123047 337.464417,411.434906 
	C337.727600,411.549072 337.814697,411.973907 337.933655,412.645782 
	C337.628357,414.406982 337.292664,415.822510 336.934448,417.605286 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M635.222412,319.232483 
	C635.018494,318.517609 635.013977,318.085419 635.009521,317.329041 
	C638.200745,316.279175 641.070496,313.607239 644.972595,315.619141 
	C648.238831,318.711548 648.877625,323.159271 652.281006,326.369659 
	C652.979431,324.228149 651.975586,321.637848 654.794189,320.779358 
	C656.479797,320.593201 657.686462,320.828674 658.918640,322.084351 
	C658.818359,324.014679 657.352234,325.332581 659.756592,327.378479 
	C662.426819,329.650574 658.708374,333.086090 659.372925,336.208893 
	C659.454224,336.590881 655.892151,335.776459 655.179871,334.609558 
	C654.016052,332.703003 653.002075,333.025513 651.244385,333.892029 
	C650.459717,334.140900 650.036377,334.062012 649.460815,333.474335 
	C648.727905,331.788635 648.265503,330.281281 645.862793,331.020691 
	C645.069946,331.061035 644.616333,331.021637 643.832397,330.842285 
	C643.296448,330.627411 643.093933,330.513275 642.646729,330.150146 
	C642.273132,329.707703 642.144104,329.514282 641.857788,329.011841 
	C641.459473,328.066986 641.128906,327.463806 640.483948,327.196564 
	C637.052856,325.774872 635.133057,323.378204 635.222412,319.232483 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M422.729126,295.870605 
	C423.003418,296.571594 423.028931,297.054321 423.086365,297.898804 
	C423.179138,298.459473 423.240021,298.658386 423.130035,299.115906 
	C422.238678,300.043915 421.124786,300.131897 420.461090,301.290039 
	C420.334106,301.766296 420.290527,301.960938 420.181915,302.451904 
	C419.894379,303.763245 419.540955,304.745178 419.298950,306.093445 
	C418.909302,307.737976 418.803131,309.059479 419.279938,310.723450 
	C417.997131,312.075409 416.653687,313.088257 414.998596,314.142761 
	C412.432190,314.707458 411.723328,313.016693 410.737946,311.195221 
	C410.538025,310.642761 410.476624,310.418640 410.379089,309.854858 
	C410.300934,308.675598 410.632812,307.823090 410.094116,306.746063 
	C408.936951,305.299469 407.766998,305.995483 406.346222,306.089722 
	C401.703888,303.806793 405.573700,300.333252 406.314148,300.099915 
	C411.451263,298.481171 414.229401,293.916809 418.717468,291.118286 
	C420.704742,292.157806 421.484619,293.985687 422.729126,295.870605 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M561.411377,278.982666 
	C559.048401,275.458984 556.928894,272.088623 557.970947,267.747803 
	C559.045227,263.272797 562.663208,262.014282 566.097961,265.033661 
	C567.806030,266.535156 569.543213,268.103668 570.953979,264.344604 
	C571.750000,263.385712 572.594299,263.203705 573.921875,263.675781 
	C575.328369,265.298492 575.205139,266.732727 574.250977,268.583435 
	C573.482666,269.771301 572.445862,270.583405 572.473450,271.357544 
	C572.676941,277.059143 569.184448,278.428070 564.204346,278.815155 
	C563.214355,279.092621 562.490295,279.076508 561.411377,278.982666 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M364.803040,343.261292 
	C365.929901,341.073914 368.383057,341.811737 369.803955,340.190033 
	C370.378357,339.683777 370.787689,339.482574 371.614624,339.537231 
	C373.361633,340.589325 372.973175,342.663818 374.608948,343.821777 
	C375.288452,342.392059 374.283234,341.750549 373.616058,341.002594 
	C372.015350,339.208160 373.112152,338.503937 375.135712,338.013550 
	C375.667084,337.981659 375.876801,337.982544 376.390381,337.987366 
	C377.095734,337.957397 377.481720,337.861053 378.111481,337.526917 
	C381.494965,336.649963 384.886353,336.687561 387.666626,334.065063 
	C388.464966,334.028656 388.928192,334.036194 389.738708,334.056763 
	C388.715790,338.024170 384.946014,339.880554 382.381348,342.788269 
	C378.563782,347.116425 374.452911,351.185822 370.218292,355.645081 
	C368.243805,354.672607 366.419800,353.594238 364.146606,352.599945 
	C361.116089,349.191620 364.838043,346.532410 364.803040,343.261292 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M615.845459,300.440216 
	C615.744141,301.570068 615.401794,302.432587 615.028503,303.642761 
	C612.792053,305.855652 610.954346,304.149597 609.037964,303.357910 
	C604.458130,301.466003 599.878113,300.247009 594.853577,303.202240 
	C590.995361,303.094482 587.599060,302.596741 584.367554,300.290588 
	C582.971741,296.278137 587.750793,295.822449 588.001953,292.545837 
	C597.400635,294.245697 606.531311,297.110046 615.845459,300.440216 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M701.120483,367.167236 
	C701.000305,367.488983 701.026672,367.508362 701.025513,367.524658 
	C700.811096,367.914886 700.698181,368.317017 700.868652,369.007629 
	C701.517273,370.144806 701.664307,370.973297 701.556946,372.055695 
	C701.198486,375.671783 703.526672,377.491882 708.250732,377.197784 
	C705.839355,380.072784 705.662292,384.744629 700.288452,384.677826 
	C698.582764,383.603516 697.133118,382.272675 695.047852,380.913086 
	C694.234009,380.787292 694.055786,380.690308 693.635559,380.403778 
	C693.240723,380.074646 693.087769,379.934998 692.713806,379.574036 
	C691.330505,378.142151 689.685486,377.256195 689.903015,374.967590 
	C691.094177,374.017151 692.595825,374.208893 693.457886,372.646271 
	C696.409424,368.282501 699.762878,364.396942 697.596436,358.810852 
	C697.250244,357.918274 698.185425,356.975952 699.020386,355.905823 
	C699.448120,355.490204 699.640869,355.346069 700.194641,355.194092 
	C701.739014,356.312195 702.922424,357.438171 704.236816,358.834167 
	C704.311462,359.382874 704.255188,359.661560 704.127197,360.360626 
	C703.084839,361.818207 702.543335,363.049835 702.194946,364.739624 
	C701.999512,365.021790 701.992126,364.995026 702.005554,364.991486 
	C701.459778,365.481537 701.500061,366.226318 701.120483,367.167236 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M395.218201,354.044189 
	C394.895966,354.628662 394.761658,354.760010 394.478973,354.934448 
	C393.716339,355.366486 392.964569,355.457275 392.167023,356.165222 
	C389.490692,358.767242 387.507843,361.618042 384.277039,363.576782 
	C383.893188,363.966248 383.741760,364.140564 383.281921,364.157227 
	C381.232605,363.940674 380.809265,362.482513 379.981140,360.984680 
	C383.531586,355.437103 387.888306,350.798431 392.674927,346.126923 
	C398.333710,347.067230 395.187866,350.886292 395.218201,354.044189 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M435.804626,313.747162 
	C437.128937,313.045807 438.278229,312.637970 439.727600,312.136841 
	C440.343597,312.341187 440.577942,312.690399 440.748108,313.410950 
	C441.646423,315.898193 443.405487,314.552643 444.542999,314.402679 
	C447.666382,313.990967 449.230438,315.212189 449.707825,318.527740 
	C449.405243,321.130188 447.557678,321.987457 445.924713,323.057220 
	C442.292877,325.436493 438.316925,327.060242 433.908020,328.279541 
	C432.467468,327.005646 432.016846,325.651001 432.182251,323.742249 
	C434.006256,321.144928 435.592194,316.755981 435.804626,313.747162 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M338.197388,412.759949 
	C337.055664,411.956024 335.208099,412.146332 334.733276,411.707275 
	C333.156647,410.249268 326.958099,411.641449 330.877167,406.095428 
	C331.676910,404.963715 329.842407,403.023926 327.385864,402.997925 
	C325.509979,398.675293 326.488464,396.217590 331.251648,393.536804 
	C333.291962,394.844299 332.289520,395.989777 331.330658,397.497559 
	C331.102081,398.068390 331.081726,398.285767 331.046143,399.062256 
	C331.286194,399.835083 331.535004,400.056061 331.968689,400.479370 
	C332.668365,401.245697 332.970062,401.966309 333.697845,402.739868 
	C336.360992,402.523071 338.628876,403.513062 341.365967,403.935303 
	C341.755707,403.950439 341.807129,404.432678 341.800751,404.675049 
	C342.064789,406.362000 341.213135,407.404297 340.299255,408.749725 
	C339.535828,410.175873 338.983246,411.352112 338.197388,412.759949 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M342.721405,424.994263 
	C342.433289,433.461945 339.560638,441.485535 338.521484,449.834900 
	C337.969604,454.269226 337.494324,458.713043 336.894958,463.580017 
	C335.187408,465.009918 334.733856,463.991394 334.305023,462.338806 
	C335.154358,449.615631 337.096100,437.413696 342.015686,425.486206 
	C342.040710,425.120361 342.487122,424.969879 342.721405,424.994263 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M410.792694,310.100281 
	C412.075104,311.363495 413.038666,312.646973 414.344543,314.057434 
	C411.109100,317.112549 407.531250,320.040680 403.629272,323.038574 
	C402.094421,322.673004 401.849487,321.487579 401.233551,320.204285 
	C402.068420,318.388092 402.779480,316.955444 399.963867,316.232697 
	C399.507904,314.937225 396.279083,315.684723 398.200104,313.090759 
	C398.907898,312.171021 399.531281,311.618530 400.748169,311.836548 
	C401.016327,312.005219 400.996368,312.001343 400.990295,311.993073 
	C401.163513,312.111511 401.342773,312.238220 401.791809,312.176147 
	C402.061584,311.987396 402.522400,311.994659 402.752777,311.999329 
	C403.857269,311.082123 404.751587,311.430573 405.678345,311.925659 
	C405.946991,312.069183 406.572418,312.237823 405.847046,311.652863 
	C405.820435,311.631409 405.843842,311.400604 405.921417,310.906433 
	C407.475769,309.796326 408.991699,310.100555 410.792694,310.100281 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M461.508179,301.821808 
	C462.421326,304.878967 462.766754,308.037933 464.953125,310.984314 
	C463.327820,314.942474 459.871155,315.589905 456.153320,316.228943 
	C454.870544,312.798920 453.721802,309.572479 452.760040,306.037598 
	C455.666412,304.442078 458.405273,303.148590 461.508179,301.821808 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M452.558594,305.806274 
	C454.796112,308.626953 456.864960,311.453552 455.992554,315.581848 
	C455.802277,316.076324 455.617432,316.218658 455.086853,316.413452 
	C450.076355,315.858490 445.623352,313.840363 440.614685,314.073364 
	C440.352173,313.389496 440.262665,313.004242 440.100403,312.331268 
	C443.596039,309.030365 447.983795,307.633087 452.558594,305.806274 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M422.661011,299.223328 
	C422.942078,299.014648 423.023926,298.634705 423.071106,298.447632 
	C423.585938,298.454895 424.053558,298.649231 424.752136,298.959351 
	C425.145294,299.210815 425.307587,299.346497 425.711609,299.687622 
	C426.396393,299.897614 426.839355,299.902161 427.614899,299.913635 
	C430.146332,298.883575 431.591553,299.130981 431.245544,302.389954 
	C427.325470,305.508698 423.656403,308.240631 419.663940,311.017578 
	C415.200439,310.697601 417.941589,308.226593 418.044983,306.205414 
	C419.321960,304.905182 420.704681,304.118103 420.776489,301.980927 
	C421.091095,300.725067 421.516418,299.926880 422.661011,299.223328 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M510.736755,293.796783 
	C511.326019,295.398468 511.649170,296.787354 511.962524,298.536926 
	C511.880615,299.459229 511.649506,299.944916 510.917572,300.580597 
	C507.228241,301.697784 503.877014,302.569885 499.987976,302.002258 
	C495.075836,300.357483 496.047028,295.453003 493.809052,292.114685 
	C496.226776,291.168182 498.704620,290.576202 501.574890,290.020264 
	C503.769043,292.262238 500.546936,293.927643 501.518738,296.442474 
	C504.342499,295.093353 507.693298,295.413422 510.736755,293.796783 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M510.899200,293.713928 
	C510.170227,294.135986 509.329834,294.268677 509.375946,295.402832 
	C509.401886,296.040710 509.477753,296.676514 509.531067,297.313263 
	C506.224121,297.093658 502.246246,297.926422 499.795380,296.363953 
	C497.248993,294.740540 501.773285,292.964844 501.931000,290.402466 
	C504.210144,288.693237 506.657135,288.743042 509.556519,289.009430 
	C510.343231,289.394073 510.504333,289.860687 510.269623,290.732635 
	C510.318359,291.850647 510.556946,292.634430 510.899200,293.713928 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M687.288452,350.691040 
	C682.400452,352.313538 678.343872,347.618286 679.226379,341.863342 
	C679.401001,340.724731 679.820312,339.623627 680.217163,338.093262 
	C682.395874,339.299408 684.484192,340.917511 686.767761,342.802429 
	C687.627014,345.477295 688.270020,347.886963 687.288452,350.691040 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M655.089233,320.546570 
	C653.578552,322.055237 653.272888,323.469513 654.175781,325.188782 
	C654.716492,326.218262 655.347107,327.234589 653.744995,328.065155 
	C652.047546,328.945160 651.209717,327.827454 650.521667,326.745178 
	C648.639709,323.784821 646.894775,320.737305 645.093506,317.402527 
	C645.517029,315.956696 646.325500,315.456787 647.866455,315.777008 
	C650.492188,317.375336 652.773621,318.786591 655.089233,320.546570 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M510.454254,291.092651 
	C510.043427,290.607422 510.007080,290.148010 509.973419,289.343567 
	C515.185242,287.223999 520.614380,287.177032 526.459839,287.009460 
	C524.044128,287.789185 522.742493,290.149048 521.155579,292.685638 
	C517.447266,293.249878 514.000366,292.928894 510.454254,291.092651 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M346.870148,427.256409 
	C346.932922,424.585480 347.331970,422.182281 347.746704,419.380310 
	C348.161743,418.212006 348.561035,417.442444 349.045227,416.343506 
	C350.191498,414.949341 350.706329,413.029755 353.158813,413.895386 
	C354.642395,415.862671 354.308594,417.826080 354.407928,420.132477 
	C354.407806,420.825256 354.403961,421.103607 354.379944,421.801971 
	C354.258850,423.686096 353.807281,425.009064 352.470032,426.388916 
	C350.590546,427.491058 349.004364,428.120392 346.870148,427.256409 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M724.451416,383.308533 
	C717.695190,383.982086 716.513184,378.123657 714.040405,373.753571 
	C713.567505,372.917816 713.386475,371.918793 712.241272,371.107849 
	C711.357666,370.269897 711.256592,369.529297 711.780029,368.409424 
	C716.998718,372.336182 720.468140,377.815399 724.451416,383.308533 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M648.118286,315.692566 
	C647.374023,316.244141 646.537415,316.524139 645.396057,316.942444 
	C641.991028,314.015228 638.832275,318.150452 635.363037,317.004791 
	C633.649292,316.842651 632.756714,316.152679 633.276611,314.740051 
	C633.685608,313.628723 633.965088,311.948547 635.846558,312.495026 
	C637.449890,312.960724 637.940735,312.243988 637.980103,310.491150 
	C640.183838,311.247711 642.385803,312.341858 644.790161,313.708252 
	C645.248901,314.282135 645.539978,314.544586 646.159668,314.826202 
	C646.974060,315.067352 647.500000,315.244202 648.118286,315.692566 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M435.649597,313.623596 
	C436.896271,317.009583 438.498596,320.742767 433.305176,322.987671 
	C432.145416,323.638794 431.309204,323.319092 430.178345,322.826660 
	C429.515717,322.418152 429.177155,322.132080 428.655701,321.558289 
	C428.074432,320.752228 427.810638,320.155640 427.657532,319.176483 
	C429.824738,316.605835 432.442139,315.002045 435.649597,313.623596 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M523.424927,278.038208 
	C522.682068,272.919739 528.456482,274.264618 530.044495,271.210022 
	C532.457153,272.498108 533.303040,275.101654 534.999512,277.370300 
	C531.333130,277.844055 527.607483,277.988373 523.424927,278.038208 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M665.606689,317.416687 
	C667.066406,316.172150 668.734070,315.221100 670.730957,314.140472 
	C672.210510,315.380096 674.246704,315.168854 675.645264,316.706970 
	C677.160950,317.650543 678.467957,318.466309 676.369690,319.673981 
	C675.247864,320.319641 673.778625,320.375244 672.959961,322.074341 
	C670.470032,320.834930 668.142334,319.272522 665.606689,317.416687 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M687.283386,351.213867 
	C687.396118,348.545807 684.908447,346.254089 686.825562,343.409637 
	C688.948547,343.801300 689.851685,345.540283 690.918457,347.530579 
	C691.081238,348.667603 690.980286,349.550385 691.246948,350.302887 
	C691.863953,352.044250 691.955627,353.992340 690.109680,354.492340 
	C688.218323,355.004639 688.216064,352.655273 687.283386,351.213867 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M395.249634,354.364319 
	C394.360077,351.797974 396.176086,348.718079 393.322266,346.166138 
	C394.323029,344.691711 395.669922,343.401306 397.315369,342.021362 
	C398.062286,341.769470 398.479797,341.548767 399.170288,341.083893 
	C399.474152,340.898254 399.475098,340.698700 399.533203,340.620422 
	C400.838013,345.472412 396.360901,348.864227 396.077942,353.786926 
	C395.844666,354.202057 395.669006,354.229156 395.249634,354.364319 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M493.395691,291.776489 
	C495.292755,292.930969 497.302307,293.726105 496.415558,296.419281 
	C495.723389,298.521484 498.610382,298.685364 498.942841,300.690765 
	C498.170654,302.273743 497.052856,302.769958 495.287323,302.140320 
	C494.941895,298.274170 495.344055,294.424591 490.189514,293.181824 
	C490.606842,291.451660 492.041962,292.232483 493.395691,291.776489 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M431.330872,302.871033 
	C431.210632,301.031708 430.675751,299.911743 428.316620,299.961731 
	C428.563538,297.540771 429.177307,296.298523 432.125885,298.092651 
	C432.993805,298.620758 435.335907,298.074341 436.831543,299.758240 
	C435.230713,301.005798 433.448944,301.985565 431.330872,302.871033 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M363.813019,352.313354 
	C366.871765,351.491730 369.105103,351.881287 369.949402,355.498352 
	C369.508759,356.594330 369.047791,357.263794 368.347260,358.006073 
	C367.391785,356.089172 365.520630,355.691925 363.520721,354.966888 
	C362.441437,354.000641 362.958313,353.238312 363.813019,352.313354 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M543.007935,273.727356 
	C542.694824,275.034119 542.384888,276.033386 542.025635,277.346436 
	C541.507141,277.721008 541.037842,277.781769 540.222412,277.752625 
	C538.219666,274.641846 541.475220,273.757996 542.494202,271.918091 
	C542.548950,271.819305 542.945435,272.848450 543.007935,273.727356 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M353.244781,413.640564 
	C351.460449,413.755280 350.988220,415.447083 349.450592,415.946838 
	C349.574615,414.482391 350.019104,412.950714 350.695740,411.160828 
	C352.082703,410.396790 352.717560,411.104340 353.410706,412.268799 
	C353.492310,412.856812 353.468628,413.097473 353.244781,413.640564 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M353.439392,413.140381 
	C352.563049,412.291656 352.038086,411.678986 351.220520,410.984497 
	C351.242584,410.048187 351.557251,409.193695 352.076904,408.059021 
	C352.691681,407.781921 353.101532,407.785004 353.818298,407.829712 
	C355.332275,409.786499 356.794983,411.717163 353.439392,413.140381 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M416.242462,326.412476 
	C416.866455,329.855438 414.259491,330.407898 411.233154,330.900330 
	C412.126862,328.942413 414.175323,327.928467 416.242462,326.412476 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M427.342346,318.875275 
	C428.057281,319.278778 428.484528,319.705414 428.980621,320.438751 
	C428.683167,322.025848 427.724365,322.634369 426.173248,322.994812 
	C425.233612,322.647156 424.646545,322.275665 423.955811,321.555542 
	C424.919647,320.437469 425.987183,319.667969 427.342346,318.875275 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M471.790588,288.819519 
	C469.992401,288.967255 468.888062,290.546844 466.323181,289.559967 
	C469.173096,288.083435 471.244507,286.857941 474.770569,287.094971 
	C473.440491,287.886414 472.796112,288.269836 471.790588,288.819519 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M356.953674,397.993286 
	C357.505859,398.062531 358.598785,397.429840 358.595795,398.210968 
	C358.591827,399.229767 358.056854,400.418671 357.370392,401.200867 
	C356.927948,401.704987 356.269653,400.937469 355.997833,399.963898 
	C355.970306,399.645844 355.838989,399.244293 355.967010,399.076263 
	C356.257080,398.750519 356.419159,398.592804 356.797821,398.173950 
	C357.014374,397.912842 356.997131,397.998718 356.953674,397.993286 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M711.822510,368.004456 
	C712.020691,368.806793 712.017700,369.516815 711.968750,370.578064 
	C711.478027,371.552124 711.033325,372.174927 710.215393,372.832336 
	C709.951721,371.352142 709.718140,369.781952 710.873108,368.167358 
	C711.180115,367.948181 711.621338,367.912079 711.822510,368.004456 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M340.410950,408.942383 
	C340.491364,407.778564 340.894348,406.557556 341.545837,405.126984 
	C343.737488,406.854095 342.098541,407.833008 340.410950,408.942383 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M409.464325,331.815186 
	C409.998383,333.354218 409.158936,333.976196 407.282166,333.969849 
	C407.660095,333.274841 408.413147,332.589111 409.464325,331.815186 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M390.365753,334.019653 
	C390.077484,332.523621 390.979218,331.982605 392.743408,332.031311 
	C392.605774,333.071533 391.925812,333.872131 390.365753,334.019653 
z"
    />
    <path
      fill="#F7F6F6"
      opacity="1.000000"
      stroke="none"
      d="
M343.325745,401.962189 
	C343.154419,401.421417 343.272522,400.842133 343.651489,400.114441 
	C344.986816,400.796753 344.895416,401.450439 343.325745,401.962189 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M698.556519,353.568542 
	C699.246704,353.894501 699.735901,354.350861 700.390381,354.996735 
	C700.470886,355.333374 700.386169,355.480469 700.139282,355.823914 
	C698.558777,356.796173 697.835327,356.236237 697.786255,354.391968 
	C697.991150,354.010010 698.355530,353.698944 698.556519,353.568542 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M477.809570,285.890411 
	C477.651733,287.059967 476.703033,287.080872 475.753510,286.966492 
	C475.546722,286.941589 475.383392,286.555786 475.200012,286.336243 
	C475.933960,286.128601 476.667908,285.920929 477.809570,285.890411 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M478.049225,285.657623 
	C478.904541,284.726837 480.107880,284.478271 480.445740,285.386505 
	C480.813934,286.376312 479.459625,285.942963 478.549988,286.125122 
	C478.217316,286.067535 477.948486,285.812927 478.049225,285.657623 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M458.970032,293.151123 
	C459.317322,292.198242 460.005066,291.606812 460.675781,292.164948 
	C461.549072,292.891693 460.361298,292.899658 459.747925,293.340149 
	C459.431854,293.461304 459.002167,293.368561 458.970032,293.151123 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M459.310181,293.327698 
	C458.650940,294.077576 457.864349,294.442627 457.048584,293.515137 
	C457.445770,293.318573 457.842987,293.122040 458.589020,292.929596 
	C458.937866,292.933685 459.188538,293.194092 459.310181,293.327698 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M464.190063,290.955200 
	C463.766449,292.011078 462.988739,292.369751 462.353424,291.832794 
	C461.464661,291.081665 462.658081,291.086639 463.271484,290.662781 
	C463.788910,290.600220 463.978729,290.633575 464.190063,290.955200 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M483.527039,284.679016 
	C483.097656,284.670990 482.866272,284.497711 482.634918,284.324432 
	C482.998291,284.387543 483.361694,284.450653 483.527039,284.679016 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M463.752075,290.736084 
	C464.250671,290.048523 465.132660,289.426971 465.752533,290.074463 
	C466.612671,290.972809 465.353394,290.870728 464.580811,291.200806 
	C464.211609,291.243500 463.905090,290.905365 463.752075,290.736084 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M399.087036,340.880432 
	C399.087830,341.182434 398.701538,341.466614 397.964600,341.841339 
	C397.975922,341.575409 398.337921,341.218994 399.087036,340.880432 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M410.627655,330.889801 
	C410.683044,331.112091 410.471924,331.400238 409.988708,331.738342 
	C409.931488,331.510742 410.146332,331.233215 410.627655,330.889801 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M680.189941,337.444244 
	C679.958435,337.535858 679.588013,337.350861 679.090820,336.867554 
	C679.326294,336.768707 679.688660,336.968170 680.189941,337.444244 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M646.303284,314.614929 
	C646.079407,314.748901 645.710632,314.607269 645.167236,314.223114 
	C645.381165,314.100159 645.769836,314.219757 646.303284,314.614929 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M356.731049,397.878113 
	C356.900543,398.212372 356.741089,398.485229 356.315552,398.819824 
	C356.212616,398.553284 356.330170,398.198303 356.731049,397.878113 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M752.365479,368.239624 
	C752.380005,368.009644 752.505432,367.884308 752.630920,367.758972 
	C752.579346,367.954041 752.527832,368.149139 752.365479,368.239624 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M748.346191,413.820374 
	C748.107361,413.528687 748.277832,413.292358 748.824036,413.068237 
	C749.109009,413.349823 748.960876,413.614929 748.346191,413.820374 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M716.003052,323.997192 
	C715.800598,323.994476 715.593933,323.996063 715.074890,323.980957 
	C714.961975,323.089355 715.161438,322.214417 715.591797,321.092957 
	C715.822693,320.846436 715.946350,320.965088 716.003540,321.028809 
	C716.563232,321.784821 716.487732,322.570862 716.210571,323.682495 
	C715.993469,324.010590 715.998840,324.001556 716.003052,323.997192 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M716.241211,323.832031 
	C716.008545,323.279419 716.023560,322.548248 716.049683,321.454834 
	C717.116150,321.793610 717.166565,322.662659 716.241211,323.832031 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M685.367920,240.209763 
	C683.990479,239.694931 683.646667,238.777740 683.625854,237.895355 
	C683.598267,236.727646 684.719360,237.251022 685.652954,237.023560 
	C686.593079,237.773315 688.046143,237.931396 687.727417,239.710663 
	C687.177551,240.609253 686.463135,240.693115 685.367920,240.209763 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M685.169312,240.291885 
	C685.738770,240.011139 686.423584,240.031906 687.452026,240.038116 
	C687.923767,240.140274 688.051941,240.256973 688.279297,240.614166 
	C687.196594,242.551819 686.241272,241.565247 685.169312,240.291885 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M690.565491,236.571442 
	C690.487549,236.623352 690.457947,236.418045 690.457947,236.418045 
	C690.457947,236.418045 690.643433,236.519516 690.565491,236.571442 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M696.458252,243.526581 
	C696.431213,243.477509 696.485291,243.575653 696.458252,243.526581 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M680.338867,287.746216 
	C679.402893,289.215820 678.805847,290.432861 677.884705,292.310333 
	C676.633179,288.874695 673.292358,287.604889 672.931458,284.136841 
	C673.384399,283.693665 674.053040,283.845734 673.849365,283.220276 
	C673.643005,282.586395 674.074341,283.540680 673.251648,283.163757 
	C673.096619,277.223480 670.607117,271.667877 671.048462,265.326233 
	C672.197021,264.541107 673.445618,264.742432 673.306458,262.665649 
	C673.186584,260.876709 673.260254,258.038696 676.942261,259.784760 
	C679.734558,263.300995 679.434265,267.319092 680.037598,271.520416 
	C680.756958,272.572632 681.362305,273.286011 681.641663,274.600342 
	C680.862915,276.955322 680.888916,279.042755 681.124756,281.544373 
	C681.981262,283.880554 681.975342,285.745239 680.338867,287.746216 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M677.132080,259.631531 
	C675.719360,259.475189 674.404114,258.693542 674.558044,261.533936 
	C674.636414,262.979126 675.061462,265.888763 671.381531,264.905701 
	C669.915405,264.055603 670.265320,262.788971 670.078003,261.308960 
	C669.391663,260.704346 668.673035,260.358643 668.578735,259.706482 
	C668.118225,256.520508 667.080139,254.678558 663.151001,255.436996 
	C661.159668,255.821381 658.781738,254.659531 659.684204,251.253174 
	C661.038513,248.990311 664.982117,250.225952 664.977051,246.435776 
	C667.103638,243.495514 669.409424,242.867599 672.543396,244.972321 
	C674.623291,246.889511 674.188599,249.960464 676.739258,251.781311 
	C675.590332,254.399826 676.881409,256.820251 677.132080,259.631531 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M682.010864,274.987701 
	C680.219177,274.827606 680.001709,273.808044 680.026489,272.279449 
	C682.662964,269.726624 681.878906,266.702698 682.024658,263.401947 
	C685.526978,263.474945 685.483582,267.349335 687.840759,269.006134 
	C688.572815,269.520660 688.699768,271.088409 687.111877,270.928864 
	C684.314941,270.647827 684.073730,272.799683 683.109070,274.827637 
	C682.759705,275.131500 682.548096,275.077515 682.010864,274.987701 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M681.973083,263.016052 
	C680.911926,261.433075 679.768311,259.839630 681.065552,257.329071 
	C682.537170,259.009430 681.828613,260.693817 682.074646,262.538513 
	C682.088257,262.918182 682.012756,262.984955 681.973083,263.016052 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M684.998291,257.002197 
	C685.216370,257.085693 685.620789,257.157898 685.625305,257.249664 
	C685.649780,257.748840 685.426270,257.817169 685.044067,257.223633 
	C685.000549,256.999146 685.000000,257.000000 684.998291,257.002197 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M673.134521,313.949585 
	C672.835449,312.970978 671.125854,312.961426 672.170349,311.857422 
	C674.286987,309.620239 674.361755,307.171814 673.311096,304.473114 
	C672.636169,302.739655 673.350037,301.238617 674.215393,299.758118 
	C674.482544,299.301025 675.335999,299.113678 674.785339,298.187561 
	C673.185791,295.497345 673.223755,295.236755 676.569031,295.048401 
	C680.190613,294.844482 680.282227,291.709930 682.357544,289.322876 
	C688.075195,293.321411 682.676514,296.672028 681.553833,300.097351 
	C680.667664,302.800995 677.996765,304.341705 681.784302,307.145966 
	C684.455383,309.123627 682.657166,312.612366 677.385864,315.967163 
	C675.906433,315.125031 674.207214,315.500275 673.134521,313.949585 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M680.258606,288.107208 
	C680.357910,286.240662 680.715820,284.482513 681.125610,282.333679 
	C683.806213,283.106354 682.590393,285.785431 683.195679,287.831665 
	C682.814026,288.186737 682.665955,288.364594 682.257690,288.772278 
	C681.997559,289.002167 682.000000,289.000000 682.001221,288.999268 
	C681.507385,288.737579 681.012268,288.476624 680.258606,288.107208 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M672.714722,313.635681 
	C674.161011,314.629669 676.297241,313.217651 677.009094,315.634460 
	C676.735657,316.178253 676.432129,316.343872 675.990479,316.729340 
	C674.158386,316.475464 672.068115,316.818237 671.120544,314.346497 
	C671.498840,313.897217 671.937500,313.783539 672.714722,313.635681 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M682.252686,288.998535 
	C682.091064,288.683716 682.271912,288.426697 682.751038,288.119995 
	C682.962097,288.008911 683.012146,288.503204 683.015076,288.751587 
	C682.847961,288.998291 682.677917,288.996582 682.252686,288.998535 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M338.891815,302.153473 
	C340.292694,301.979095 341.135437,301.490906 341.865967,300.296875 
	C342.440186,300.012207 342.882690,300.002319 343.657166,299.989380 
	C344.523529,299.458282 345.070190,298.942841 345.816406,298.228821 
	C346.026245,298.040802 345.999176,298.008911 346.016846,298.018280 
	C346.190552,297.902252 346.346588,297.776886 346.734253,297.842468 
	C347.260895,297.872467 347.555878,297.711456 347.999268,297.341827 
	C348.944336,296.346802 349.675476,295.484314 351.005951,294.820160 
	C351.554871,294.694641 351.784973,294.696716 352.319641,294.863800 
	C354.508759,296.811951 353.008484,298.414337 352.239380,300.390991 
	C351.570892,302.110016 350.450714,302.926636 348.933777,303.827728 
	C345.614960,306.989990 341.728302,309.212708 340.205933,313.922363 
	C339.797699,314.648315 339.642212,315.222656 339.270111,315.466461 
	C338.046875,316.268188 339.325623,319.184570 337.195435,318.636353 
	C335.115784,318.101105 335.877228,315.828094 336.412933,314.398804 
	C337.385376,311.804260 334.788849,310.150757 334.992065,307.460693 
	C334.976349,307.089661 335.024658,306.949005 335.047485,306.878204 
	C336.234283,305.296051 336.125519,302.790894 338.891815,302.153473 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M364.643311,278.097046 
	C365.134857,278.737732 365.130524,279.518707 365.513214,279.975128 
	C366.492462,281.143127 363.866547,283.626312 366.871857,283.869751 
	C369.060394,284.047058 368.855225,282.176727 369.094940,280.750092 
	C369.396698,278.954407 370.840546,279.051666 372.155182,279.016052 
	C373.712372,278.973785 375.191040,279.223572 375.782349,280.929047 
	C376.264069,282.318451 376.581482,283.764862 376.980927,285.576660 
	C372.258759,287.855042 368.979950,292.888428 362.819275,292.150391 
	C360.903717,287.515564 359.163208,283.095551 362.752136,278.398529 
	C363.471466,278.122101 363.897552,278.105774 364.643311,278.097046 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M362.630798,278.109406 
	C361.036133,282.530640 362.468079,286.950134 362.969757,291.706055 
	C362.659424,294.919312 359.904022,295.038422 357.828796,296.370880 
	C355.815704,296.224304 354.274628,295.410400 352.287354,295.125366 
	C351.998779,295.000000 352.000000,295.000000 352.000610,295.000061 
	C349.472382,293.263733 349.496307,291.451416 352.334595,290.384186 
	C353.913727,289.790436 354.234650,288.812897 354.793335,287.280212 
	C355.108124,286.079773 355.398865,285.467377 356.550537,285.759766 
	C357.513519,286.004272 359.161072,285.798096 358.429047,284.765015 
	C357.805756,283.885345 359.059967,281.565491 356.553894,281.946320 
	C354.939514,282.191681 353.622559,281.579193 353.100616,279.471039 
	C353.566772,278.435303 354.260742,278.255157 355.402374,278.531952 
	C357.994202,279.478729 359.998627,277.670990 362.630798,278.109406 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M380.910278,263.328918 
	C382.563995,263.182098 384.631653,261.164703 385.109680,262.557281 
	C385.913971,264.900421 385.168854,267.616699 383.944702,270.355438 
	C380.388367,271.183105 377.700409,268.989044 374.265869,268.106171 
	C373.356659,267.032532 373.819946,266.239777 374.764709,265.267883 
	C377.072235,265.160278 378.999664,264.860840 380.910278,263.328918 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M354.695740,287.164978 
	C354.799011,288.853180 358.433685,292.703644 352.382172,291.523590 
	C350.447632,291.146332 351.240997,293.208466 351.857422,294.685303 
	C350.684479,295.463409 350.900757,298.159668 348.407837,297.210876 
	C343.813721,292.262268 350.480072,291.832611 351.503204,289.114655 
	C351.819122,288.275330 353.387695,287.907471 354.695740,287.164978 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M374.773529,264.811584 
	C374.737305,265.840912 374.434723,266.640808 374.014160,267.749084 
	C370.468292,268.243835 371.680023,264.911896 370.460571,263.413513 
	C370.116974,262.991394 370.730652,262.291138 371.494476,262.128632 
	C373.544067,261.692535 373.828125,263.367981 374.773529,264.811584 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M355.751526,278.360107 
	C355.074738,278.815857 354.408112,278.928741 353.403137,279.081116 
	C352.193848,279.071350 351.283600,280.463074 350.516815,279.265564 
	C349.962616,278.400085 350.842560,277.671600 351.529907,277.407898 
	C352.991058,276.847168 354.592834,276.188721 355.751526,278.360107 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M345.705139,298.073669 
	C345.919556,298.934204 345.648193,299.704407 344.307434,299.949524 
	C344.086945,299.087311 344.329041,298.295319 345.705139,298.073669 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M346.887207,297.868011 
	C346.965942,298.033478 346.499939,298.021027 346.267212,298.024353 
	C346.292480,297.919281 346.550507,297.810913 346.887207,297.868011 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M375.841003,306.274719 
	C377.858215,303.510834 376.655884,300.625031 376.961060,297.461914 
	C379.693420,296.999664 382.657562,296.340118 384.588409,299.034271 
	C386.434998,301.610901 388.270294,297.295563 390.160614,299.491669 
	C390.611603,300.797516 390.185028,301.630859 389.606567,302.772522 
	C386.581390,307.511292 383.231781,311.255585 377.197632,311.841064 
	C375.869965,311.700745 374.818512,311.350586 373.441864,310.941864 
	C373.077759,310.463318 373.038788,310.043304 372.978577,309.306702 
	C373.198547,307.484314 374.571594,307.160370 375.841003,306.274719 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M356.667419,296.060181 
	C358.896332,294.925873 360.275970,292.996490 362.789093,292.300140 
	C367.974884,291.327545 370.749969,285.699768 376.612427,286.039368 
	C378.164398,286.813202 378.115631,287.817566 377.162323,289.196167 
	C375.110870,288.595764 373.883057,289.794769 372.507721,290.708313 
	C365.259216,295.523041 357.981232,300.293335 350.373230,305.179779 
	C349.103729,305.075592 348.516510,304.467957 348.057861,303.323669 
	C348.586029,301.749359 350.603149,302.225250 350.927307,300.283997 
	C352.841614,298.781769 354.333923,297.085571 356.667419,296.060181 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M388.811279,302.308777 
	C389.289185,301.421753 389.691589,300.849060 390.195709,300.047241 
	C391.549133,299.385651 392.660553,298.353607 394.532532,299.045471 
	C395.768402,302.099121 393.318237,303.364105 391.594360,305.319305 
	C389.948029,305.516510 388.060242,305.973846 389.895844,303.606659 
	C389.976746,303.502350 388.926666,303.217438 388.811279,302.308777 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M376.325928,296.170593 
	C376.341766,294.076508 376.544434,292.209595 376.861023,289.935669 
	C378.090424,291.477356 380.336273,293.329041 377.197021,295.784454 
	C376.768372,296.100342 376.640564,296.249084 376.325928,296.170593 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M385.073975,291.645691 
	C388.095947,292.110626 385.299347,294.174072 386.425385,295.379852 
	C384.422089,294.667908 385.205658,293.186157 385.073975,291.645691 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M380.293457,279.674927 
	C379.913757,279.712616 379.680115,279.587708 379.446503,279.462799 
	C379.548309,279.358276 379.682495,279.148315 379.746185,279.167877 
	C379.989624,279.242676 380.210327,279.391296 380.293457,279.674927 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M376.168884,296.193085 
	C376.139099,295.943390 376.518707,295.960022 376.707458,295.955811 
	C376.917786,296.130432 376.939423,296.309265 376.969116,296.758270 
	C376.717682,296.833221 376.458191,296.638000 376.168884,296.193085 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M389.463074,296.447418 
	C389.518005,296.414581 389.408142,296.480255 389.463074,296.447418 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M394.009521,297.028198 
	C393.894806,296.901154 393.774445,296.752838 393.654083,296.604523 
	C393.741425,296.651672 393.828735,296.698822 393.981384,296.880188 
	C394.046661,297.014404 394.003906,297.006866 394.009521,297.028198 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M372.240356,339.773193 
	C371.571350,340.017609 371.132172,340.020264 370.364136,340.025208 
	C370.705597,337.263489 371.375946,334.499481 372.047668,331.334229 
	C372.197052,329.812347 372.695770,328.764771 372.847626,327.300537 
	C372.956970,326.978851 372.989777,327.001831 372.980103,326.985382 
	C373.432068,326.610504 373.950165,326.484924 374.887177,326.795410 
	C378.370300,325.677277 381.177673,327.923676 384.676025,327.958252 
	C387.234741,329.282166 386.807343,331.662781 387.868317,333.699341 
	C386.212677,337.949951 383.010681,338.897217 379.017395,338.548706 
	C378.655243,339.315613 378.209076,339.512482 377.321899,339.196381 
	C377.004700,338.963593 377.005432,339.006165 377.021973,338.992706 
	C375.261047,337.056030 373.731049,337.180359 372.240356,339.773193 
z"
    />
    <path
      fill="#5E515C"
      opacity="1.000000"
      stroke="none"
      d="
M388.624634,302.192017 
	C390.379150,302.544617 390.261536,304.050751 391.029236,305.332916 
	C390.449188,308.616577 386.683685,311.033417 389.022308,315.103180 
	C389.192932,315.400208 387.395325,318.821747 385.147552,319.077698 
	C384.533936,319.147614 384.066101,318.931000 383.723358,319.573639 
	C382.762482,321.375214 382.080475,324.378845 379.478943,323.133087 
	C377.109039,321.998230 379.091125,319.221252 379.057587,317.183167 
	C379.027924,315.381714 380.574615,313.181244 377.234863,312.194580 
	C377.053528,311.204254 377.296509,310.270203 378.250122,310.194305 
	C383.345062,309.788727 385.765625,305.989166 388.624634,302.192017 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M399.731140,316.398987 
	C402.224884,313.395386 403.762848,317.081665 404.099701,317.622955 
	C405.299927,319.551941 403.252716,319.692261 401.506897,319.896606 
	C400.373505,318.976898 399.729218,318.014557 399.731140,316.398987 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M400.989227,311.691559 
	C400.310852,312.289185 399.605347,312.573120 398.619598,312.841003 
	C398.039062,311.387238 398.050018,310.037994 399.671204,309.088623 
	C400.277710,309.863098 400.619934,310.620514 400.989227,311.691559 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M375.094421,326.713013 
	C374.742218,327.009949 374.234802,327.021088 373.348877,327.000610 
	C373.120819,326.604126 373.271271,326.239288 373.696106,325.720001 
	C374.293457,325.852753 374.616333,326.140015 375.094421,326.713013 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M384.915466,327.894775 
	C385.071259,327.648621 385.392059,326.986511 385.295135,328.024597 
	C385.157501,328.132294 384.992828,327.977264 384.915466,327.894775 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M387.436462,326.538452 
	C387.394073,326.474274 387.478882,326.602661 387.436462,326.538452 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M411.069214,309.834839 
	C410.331818,313.357574 408.122284,310.216827 406.448608,311.080933 
	C405.352936,311.537872 404.811798,311.167480 404.193359,310.325134 
	C403.837250,308.453522 405.057709,307.648895 405.927032,306.294434 
	C407.540466,304.804291 408.957214,303.063293 410.969269,305.618103 
	C411.059631,306.787598 411.615082,307.611328 411.188965,308.816284 
	C410.994751,309.093079 411.026855,309.589661 411.069214,309.834839 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M438.788208,278.068726 
	C438.985657,279.671356 438.687805,280.819366 437.244019,281.734436 
	C437.201477,282.505219 437.059387,282.909485 436.277893,283.151550 
	C434.960815,282.529541 435.095642,281.539246 434.905945,280.302979 
	C434.824493,279.964294 434.879639,279.513062 434.845337,279.288361 
	C434.670746,278.611786 434.764069,278.226044 435.449036,277.840302 
	C436.191772,277.947479 436.354492,278.648712 436.544922,278.666260 
	C437.065582,278.714294 436.401794,278.088226 437.065247,277.730225 
	C437.785461,277.519958 438.211792,277.602112 438.788208,278.068726 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M435.849060,283.356873 
	C436.220551,282.891449 436.516266,282.681976 436.954742,282.238739 
	C438.045380,282.847900 440.463562,281.943420 439.997498,284.752991 
	C437.739166,287.041046 436.660095,285.608429 435.849060,283.356873 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M439.719666,289.156738 
	C440.162689,288.961426 440.353149,288.961304 440.829010,288.949402 
	C442.126648,291.347595 441.361115,292.856537 438.424316,293.283844 
	C438.028961,293.102264 437.985443,292.945129 437.959045,292.866089 
	C438.444122,291.641998 438.955627,290.496979 439.719666,289.156738 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M403.819580,310.163147 
	C404.578644,310.284393 405.079956,310.568146 405.841187,310.986206 
	C406.425323,314.546143 404.360687,311.568665 403.286194,312.030762 
	C403.176056,311.444519 403.368958,310.885071 403.819580,310.163147 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M411.022736,282.977417 
	C411.715973,283.138641 412.862396,282.575317 412.943237,283.549957 
	C412.983673,284.037842 411.881409,283.860718 411.137817,283.275085 
	C410.970520,283.042816 411.003937,282.998077 411.022736,282.977417 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M401.792267,311.978577 
	C401.769165,312.094818 401.476715,312.202209 401.084290,312.147217 
	C401.163818,311.979797 401.343384,311.974792 401.792267,311.978577 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M418.994141,290.992432 
	C419.087524,290.955933 419.186615,290.926514 419.149628,290.953827 
	C419.013580,291.010559 418.999786,290.999512 418.994141,290.992432 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M412.115845,230.074570 
	C412.292572,230.054153 412.560760,230.294052 412.929962,230.786331 
	C412.756409,230.804108 412.481873,230.569504 412.115845,230.074570 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M410.007843,241.994049 
	C410.137085,242.147064 410.257080,242.307800 410.377075,242.468521 
	C410.277313,242.420456 410.177521,242.372391 410.030182,242.172760 
	C409.982605,242.021194 409.998596,242.001785 410.007843,241.994049 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M436.687592,276.754333 
	C435.976807,277.593536 435.594971,277.976105 435.012085,278.711182 
	C432.571472,277.563690 434.056671,275.934143 434.917175,274.170898 
	C436.626190,274.108643 437.018188,275.046082 436.687592,276.754333 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M562.002197,249.172211 
	C561.944214,248.677887 561.927429,248.417938 562.018616,248.371948 
	C566.378357,246.172379 567.644531,243.542480 563.570435,239.712891 
	C563.090881,239.262131 564.536682,238.269547 565.879578,237.992737 
	C569.271301,236.697357 571.715332,236.307861 574.170654,239.697266 
	C576.088135,242.344223 582.501404,240.479462 584.232849,237.173904 
	C584.847412,236.000565 585.544495,235.818710 586.572937,236.281189 
	C590.597534,238.090942 594.911926,239.635254 595.333557,245.459290 
	C594.794617,247.822281 593.414368,248.555649 591.039917,248.022461 
	C589.103210,247.059814 587.531738,246.191711 586.377319,244.741394 
	C585.762024,243.968460 585.059998,243.049881 583.962524,243.639099 
	C582.664978,244.335663 583.823364,245.287903 583.891235,246.157639 
	C584.164612,249.663910 584.876221,253.260605 582.205322,256.623840 
	C581.940979,256.843414 581.475464,256.974670 581.234863,256.999420 
	C580.789185,257.152710 580.583984,257.281250 580.054810,257.248810 
	C579.593262,256.891907 579.455627,256.695984 579.188965,256.167847 
	C577.512451,250.363464 577.552734,250.559341 572.307190,251.638763 
	C568.731506,252.374527 565.257812,251.379013 562.002197,249.172211 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M590.597534,246.956329 
	C592.615845,248.172211 593.655945,246.867157 595.138062,245.958557 
	C597.270691,247.143600 599.483704,246.334824 601.729004,247.070496 
	C602.272522,247.659836 602.526550,248.101898 602.875488,248.784241 
	C599.388184,250.564545 601.592285,253.727524 601.000854,256.530273 
	C599.393433,260.436584 596.718994,257.307892 594.258728,257.942078 
	C593.427185,256.308228 595.584229,254.801178 593.756958,253.065643 
	C592.037842,252.403976 590.751587,251.635361 589.654419,250.008652 
	C589.162170,248.592575 589.226868,247.614075 590.597534,246.956329 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M582.697998,232.081268 
	C586.018005,233.272293 583.036804,233.629791 582.617432,234.236679 
	C582.386292,234.571014 581.747803,235.599396 581.279175,234.743408 
	C580.706848,233.698044 581.016968,232.572433 582.697998,232.081268 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M602.760498,244.208420 
	C603.297607,244.442154 603.585754,244.903046 603.878479,245.650269 
	C603.883057,245.936600 603.568726,246.296265 603.344055,246.377625 
	C603.119385,246.459000 602.641418,246.468430 602.406616,246.423553 
	C602.285034,245.730957 602.398254,245.083267 602.760498,244.208420 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M602.197998,246.516052 
	C602.171814,246.378662 602.469482,246.253754 602.621948,246.200958 
	C602.959106,246.293137 603.143799,246.438126 603.041626,246.787445 
	C602.754822,246.991791 602.533508,246.933502 602.423462,246.926025 
	C602.313354,246.918564 602.224121,246.653458 602.197998,246.516052 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M546.990051,235.855087 
	C546.697449,235.877686 546.607666,235.642899 546.855774,235.294525 
	C546.980713,235.230164 546.979919,235.646942 546.990051,235.855087 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M659.562805,250.984818 
	C659.741882,253.667862 661.704407,254.623993 663.708069,254.493378 
	C669.217712,254.134186 669.267578,254.028824 669.564148,259.892700 
	C669.571716,260.043121 669.712219,260.186829 669.906555,260.643799 
	C669.275330,261.599243 668.528381,262.244507 667.484375,262.941101 
	C665.938782,265.925537 668.255310,268.678925 667.842407,271.882355 
	C668.073608,272.835266 668.082947,273.486969 668.082886,274.464417 
	C667.817932,275.098877 667.650879,275.448364 667.880005,275.887573 
	C669.977966,276.518250 670.932617,277.638641 670.264526,279.841400 
	C668.683655,279.573059 667.241943,277.991028 666.050293,280.670349 
	C664.179260,280.845886 662.451599,281.243744 660.396362,281.966064 
	C659.869629,279.359863 660.715576,276.345856 657.213257,274.550476 
	C656.007690,272.919739 656.608337,271.505402 656.910400,269.766785 
	C657.250427,268.546600 657.598633,267.702148 658.118164,266.576050 
	C658.396362,266.115509 658.523682,265.944366 658.882874,265.562531 
	C659.723938,264.860504 660.475586,264.493744 660.690857,263.304993 
	C661.914429,259.374481 658.302979,258.302307 656.993896,255.657944 
	C658.398865,254.229111 656.337952,251.305603 659.562805,250.984818 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M640.348877,259.954895 
	C640.681702,262.087677 641.583008,264.464966 639.045410,266.363190 
	C638.640137,267.216492 638.256409,267.486145 637.304565,267.200714 
	C636.970398,266.978668 637.007324,266.994598 637.021606,267.008789 
	C633.814209,265.007874 631.626221,266.176880 629.723145,269.409027 
	C626.274658,269.498932 623.063354,269.948944 620.237427,267.250336 
	C618.789734,264.909424 619.569336,264.270477 621.707825,263.804901 
	C623.376038,263.441742 625.823792,263.536255 625.984741,260.401794 
	C630.660522,261.352997 635.318604,260.331726 640.348877,259.954895 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M619.820068,267.166077 
	C622.871704,268.551666 626.225891,267.687073 629.586670,268.827332 
	C630.391602,268.755707 630.755554,268.837555 630.978882,269.545837 
	C629.766785,271.470215 627.873901,272.296387 626.785339,274.093811 
	C626.271423,274.942444 627.404846,277.355713 625.108643,276.653961 
	C623.791626,276.251495 621.596619,274.704346 621.733459,274.221588 
	C622.549255,271.344330 619.730347,269.805511 619.820068,267.166077 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M657.664062,268.820801 
	C657.959717,270.568420 658.329895,272.146301 657.168823,273.902283 
	C653.666748,274.497284 650.182495,275.043549 648.782593,270.302948 
	C649.789246,269.621948 651.229004,270.467377 652.126343,268.662170 
	C653.975281,267.990479 655.668823,268.672577 657.664062,268.820801 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M631.247070,269.988647 
	C630.800659,269.574951 630.611328,269.343658 630.154297,269.055786 
	C630.434204,267.201599 630.911682,265.461365 631.391113,263.714264 
	C633.642700,263.788910 634.871155,265.636841 636.830933,266.760223 
	C635.395325,267.100128 634.383911,267.832336 634.060791,269.548279 
	C633.700867,271.459106 632.566223,270.623657 631.247070,269.988647 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M647.086365,288.617676 
	C649.488342,285.404572 651.420288,287.902985 653.153992,289.068268 
	C654.315613,289.849060 653.276428,291.501953 652.373779,291.807892 
	C649.811401,292.676422 647.819275,291.846039 647.086365,288.617676 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M641.166992,282.740875 
	C640.695190,282.846741 640.390137,282.678802 639.937744,282.285522 
	C640.956482,280.725922 642.122559,279.391724 643.317749,278.024200 
	C644.782898,282.135895 644.782898,282.135895 641.166992,282.740875 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M636.987061,267.331726 
	C637.381592,266.929840 637.792725,266.881042 638.497070,266.723114 
	C638.859497,266.890198 638.928833,267.166443 639.094666,267.858887 
	C639.325256,268.393036 639.459473,268.510956 639.800171,268.799866 
	C640.006592,268.970795 640.013306,268.988983 640.026245,268.989105 
	C638.292297,270.419403 638.292297,270.419403 636.987061,267.331726 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M659.765686,282.002075 
	C660.012512,282.951538 660.012512,283.884369 660.012512,284.990570 
	C658.467957,284.421631 659.239929,283.373566 659.015503,282.311401 
	C659.024414,281.970825 659.518860,281.985443 659.765686,282.002075 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M619.879822,279.791382 
	C619.526489,279.915222 619.093750,279.785156 618.661072,279.655121 
	C618.979858,279.024445 619.377441,279.157501 619.879822,279.791382 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M619.031128,282.094360 
	C619.254700,282.310913 619.270386,282.547577 619.234680,282.776184 
	C619.230103,282.805603 618.958374,282.793335 618.810364,282.800354 
	C618.809387,282.570892 618.808472,282.341400 619.031128,282.094360 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M621.206299,254.009552 
	C621.146667,253.661743 621.356201,253.250076 621.978699,253.827133 
	C621.894409,254.007858 621.697815,254.011520 621.206299,254.009552 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M620.004883,279.998016 
	C620.144531,280.060699 620.268127,280.137878 620.391663,280.215057 
	C620.268494,280.269562 620.145386,280.324097 619.990723,280.211914 
	C619.959167,280.045258 619.988708,280.012512 620.004883,279.998016 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M653.404053,277.543640 
	C653.335083,277.447815 653.597351,277.407684 653.597351,277.407684 
	C653.597351,277.407684 653.473083,277.639435 653.404053,277.543640 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M561.643555,248.903381 
	C566.185852,249.039490 570.278992,252.865280 574.835571,248.802490 
	C576.761292,247.085449 580.075439,251.214157 580.024353,255.782959 
	C579.710022,256.347168 579.544250,256.641602 579.733948,257.482697 
	C580.019043,258.999908 580.084167,260.042694 580.124451,261.477173 
	C580.746338,262.938538 580.604980,263.901001 579.342651,264.934143 
	C577.685974,266.502563 576.454651,267.972260 574.318481,268.836182 
	C574.123718,267.589722 574.220764,266.202698 574.400818,264.405640 
	C574.136353,262.192688 575.461426,260.467590 574.852539,258.327942 
	C574.989929,253.649918 572.634460,255.261597 570.506226,256.215912 
	C569.804199,256.530670 569.273071,257.607605 568.406189,257.075165 
	C567.718323,256.652740 567.758972,255.877014 567.943604,254.952744 
	C568.294128,253.197632 566.377808,253.411041 565.473816,252.743652 
	C563.923035,251.598724 561.434692,252.512177 560.135010,250.079529 
	C560.446411,249.479233 560.911499,249.223984 561.643555,248.903381 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M579.028198,265.450928 
	C579.318481,264.257019 579.658081,263.406281 580.048706,262.212219 
	C580.701660,262.359772 581.303650,262.850616 581.998291,263.629761 
	C582.492920,263.851562 582.894958,263.785126 583.603088,263.735077 
	C583.909119,263.751495 584.347412,263.868042 584.782349,263.895935 
	C585.217224,263.923859 585.619385,263.758575 585.817200,263.668274 
	C587.404907,262.962891 588.794800,262.347809 590.527954,261.735413 
	C590.933716,264.648865 587.716797,266.369690 588.093140,269.394318 
	C586.050720,267.428375 584.267029,269.906555 582.467041,269.706757 
	C580.575745,269.496765 579.893250,267.398010 579.028198,265.450928 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M592.984253,273.983459 
	C593.175415,272.935211 593.463074,271.960144 594.840576,272.246613 
	C595.098145,272.300171 595.489685,272.639893 595.489807,272.847809 
	C595.490723,274.075409 594.474915,273.879608 593.370239,274.026031 
	C593.032104,274.033081 593.000061,274.000122 592.984253,273.983459 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M578.456177,268.445923 
	C578.516357,268.408234 578.395996,268.483643 578.456177,268.445923 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M595.966431,277.025879 
	C595.823669,276.876740 595.709045,276.702301 595.594421,276.527832 
	C595.699341,276.570099 595.804321,276.612396 595.977234,276.799622 
	C596.045227,276.944580 595.994507,277.000580 595.966431,277.025879 
z"
    />
    <path
      fill="#151920"
      opacity="1.000000"
      stroke="none"
      d="
M632.823425,245.108429 
	C632.978027,245.017883 632.995056,245.507416 632.994263,245.752289 
	C632.885193,245.731110 632.776978,245.465042 632.823425,245.108429 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M624.098999,231.262283 
	C623.844116,229.582260 624.452271,228.294739 625.980164,228.422607 
	C627.676819,228.564575 626.881714,230.078094 626.787781,231.446320 
	C625.860535,232.136856 625.045349,232.221893 624.098999,231.262283 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M603.012695,246.897659 
	C602.754822,246.991791 602.820496,246.569748 602.797485,246.358948 
	C602.958130,246.105209 603.141846,246.062271 603.604248,245.977966 
	C603.678894,246.225586 603.474792,246.514557 603.012695,246.897659 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M590.773682,246.750366 
	C590.141968,247.695847 590.092529,248.599442 590.242188,250.179901 
	C590.169678,251.173279 590.059021,251.538300 589.831177,252.184387 
	C589.458862,253.051392 589.072205,253.537430 588.248901,254.021332 
	C585.796631,254.349564 585.026733,257.156982 582.294678,256.894684 
	C582.530029,252.449173 584.698364,247.905731 580.722839,243.942749 
	C584.706970,241.241119 584.675964,241.270203 588.067993,244.883591 
	C588.721985,245.580276 589.724304,245.949966 590.773682,246.750366 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M588.011719,254.454376 
	C588.306946,253.329910 588.671265,252.535080 589.093872,251.418167 
	C589.272278,250.896057 589.392273,250.696060 589.814575,250.320038 
	C590.116882,250.143982 590.206116,250.217728 590.264648,250.236252 
	C591.970093,249.999191 592.665161,251.065826 593.340698,252.655197 
	C590.187500,257.166992 590.187500,257.166992 588.011719,254.454376 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M586.006226,264.645752 
	C585.413696,264.773010 584.830688,264.541992 584.078369,264.031250 
	C584.380005,263.189636 584.921570,262.865570 585.841248,263.403381 
	C586.015259,263.814453 586.015503,264.050964 586.006226,264.645752 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M579.818481,257.167358 
	C580.039490,256.925507 580.516907,256.975372 580.755615,256.999756 
	C580.936768,257.379730 580.744263,257.632538 580.185303,257.869873 
	C579.953918,257.957153 579.973511,257.968689 579.966553,257.961975 
	C579.838867,257.773285 579.718140,257.591248 579.818481,257.167358 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M660.115906,265.753540 
	C659.873840,265.932465 659.423889,265.923981 659.199585,265.941589 
	C654.500977,267.096039 653.642090,264.068420 653.052002,260.386993 
	C654.236816,257.729004 651.388855,255.806168 652.490906,253.493240 
	C654.278442,252.926926 654.851501,255.045883 656.635742,255.229431 
	C659.180237,257.510773 664.308533,257.838287 661.785034,263.322418 
	C661.586487,264.526764 660.895081,264.995850 660.115906,265.753540 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M652.756165,260.154114 
	C654.587158,262.165375 655.119812,265.184570 658.631714,265.917969 
	C658.807739,266.844696 658.640076,267.730133 658.197571,268.825134 
	C656.183472,268.772766 654.141357,272.373840 652.449951,268.466034 
	C652.359680,265.645660 649.282227,262.904572 652.756165,260.154114 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M647.405212,259.652161 
	C646.276978,259.476288 644.320557,259.616730 644.734741,257.659424 
	C644.915222,256.806641 646.066833,257.632904 647.075378,257.966736 
	C647.378113,258.502533 647.367798,258.919830 647.405212,259.652161 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M644.996704,264.732117 
	C643.897644,264.951294 642.963928,264.635559 642.643250,263.548096 
	C642.562134,263.272949 643.121216,262.809021 643.386108,262.431824 
	C643.865295,262.816193 644.344421,263.200562 644.963013,263.854675 
	C645.102478,264.124481 645.031616,264.529510 644.996704,264.732117 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M650.543274,249.557556 
	C650.485779,249.592285 650.600769,249.522842 650.543274,249.557556 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M667.862732,233.369476 
	C667.455627,233.068878 667.316162,232.680710 667.176697,232.292542 
	C667.441284,232.267685 667.705811,232.242828 668.122925,232.477783 
	C668.227173,232.919006 668.178833,233.100449 667.862732,233.369476 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M668.101196,232.913513 
	C668.717102,233.239243 669.421387,233.816650 667.785767,233.615723 
	C667.595032,233.457062 667.926941,233.089432 668.101196,232.913513 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M639.796631,238.997406 
	C639.974182,240.198029 639.653564,240.874268 638.580017,240.849945 
	C638.362610,240.845032 638.152161,240.538040 637.938477,240.370331 
	C638.516541,239.830902 639.094666,239.291489 639.796631,238.997406 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M639.952026,268.776855 
	C639.774902,268.877960 639.561035,268.754272 639.278076,268.437408 
	C639.487000,268.238983 639.722412,268.341583 639.952026,268.776855 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M695.153564,711.345947 
	C694.476440,711.157837 694.055603,711.132751 693.322449,711.059082 
	C692.991150,710.829407 692.972107,710.648254 692.967651,710.193542 
	C694.845886,710.036621 696.372192,708.836182 697.262329,707.707397 
	C700.254517,703.912720 702.994141,704.265442 705.949768,707.700439 
	C703.229187,708.314087 699.177795,708.503235 703.893494,712.494568 
	C705.435181,713.799438 704.445557,714.831909 703.048340,715.458313 
	C701.059204,716.350220 699.264038,717.782227 700.154053,719.966248 
	C701.718445,723.805054 700.108948,726.586731 697.974060,729.805786 
	C696.221924,730.126892 695.589417,732.435486 693.399902,732.063965 
	C693.697327,730.614197 694.346008,729.160278 694.993958,727.357788 
	C696.214294,726.410217 695.616699,724.336060 697.675293,724.057373 
	C695.949341,721.092346 694.419312,718.142273 697.002319,714.371826 
	C696.783569,712.979248 696.244812,712.149292 695.153564,711.345947 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M701.177551,731.241272 
	C701.474854,730.703308 701.949951,730.377930 702.728271,730.042786 
	C702.820679,730.917114 702.795898,732.020508 701.177551,731.241272 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M704.287964,729.045166 
	C704.192688,728.694885 704.325500,728.327515 704.897827,728.848755 
	C704.981140,729.014648 704.518982,729.034058 704.287964,729.045166 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M742.597900,688.786865 
	C743.049133,689.027771 743.218384,689.039795 743.639465,689.079468 
	C743.916504,689.529907 743.941711,689.952637 743.982788,690.693604 
	C742.552307,691.721680 741.125977,692.477234 739.652893,693.126465 
	C738.520691,693.625427 737.021667,694.762085 736.261658,693.066650 
	C735.469116,691.298523 736.979919,690.487183 738.508850,690.006775 
	C739.130859,689.811279 739.725952,689.530396 740.664185,689.139648 
	C741.375000,688.662720 741.815308,688.518433 742.597900,688.786865 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M741.141724,696.671021 
	C742.287170,697.954529 742.341003,699.291260 741.716064,700.738831 
	C741.274231,699.403320 739.635986,698.386841 741.141724,696.671021 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M742.922363,688.764160 
	C742.461426,689.007141 742.043030,688.998657 741.309814,688.990601 
	C741.466797,688.675659 741.938538,688.360291 742.709229,688.024658 
	C742.993713,688.173828 742.979309,688.343262 742.922363,688.764160 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M709.701172,487.776611 
	C709.730103,490.252289 710.210876,492.512482 711.324768,494.234436 
	C713.716309,497.931519 714.202332,501.883026 714.036255,506.506287 
	C714.031494,513.078857 714.024719,519.217041 713.997192,525.355042 
	C713.988281,527.349487 714.372559,529.122314 715.232239,531.041077 
	C717.151917,535.326050 717.616028,539.813660 716.090454,544.550110 
	C714.940979,548.118652 714.463318,551.945129 714.049072,555.696838 
	C713.649292,559.316833 711.924255,560.160278 708.377563,559.002441 
	C707.402344,558.471069 707.209961,557.780029 707.259521,556.656128 
	C708.400269,553.400146 710.587097,550.627991 708.143921,547.030518 
	C706.852234,544.790771 706.597168,542.714355 706.871216,540.143860 
	C707.539185,536.741089 707.453430,533.705872 707.452759,530.254639 
	C707.623047,527.304565 707.564453,524.771851 707.529846,521.825989 
	C707.235962,502.564026 703.125793,484.388000 697.749939,466.452087 
	C696.962097,463.823456 696.042419,461.076324 693.122620,459.262390 
	C692.526611,458.958862 692.301147,458.813965 691.790894,458.386963 
	C691.137146,457.556366 690.764587,456.978851 690.705444,456.324402 
	C690.430237,453.277405 689.341919,449.160461 692.619629,447.992859 
	C695.814880,446.854614 696.529907,451.024811 697.814392,453.351044 
	C698.290283,454.212891 698.720886,455.105804 699.828857,455.961273 
	C700.794250,456.953400 701.197144,457.843933 701.572632,459.132690 
	C701.851379,461.675476 701.794861,463.975067 704.183472,465.700714 
	C706.033630,468.241119 706.594055,470.668365 705.270508,473.651428 
	C705.660645,475.096527 706.285645,476.224945 706.944946,477.678406 
	C706.930603,481.408905 708.003052,484.518402 709.701172,487.776611 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M717.025208,579.707947 
	C715.468628,581.076599 716.349976,583.092468 715.152710,584.667969 
	C712.793823,591.673767 711.142517,598.420349 710.792908,605.551575 
	C710.464966,612.239990 706.070984,617.717224 704.163330,623.985474 
	C702.321960,630.036133 701.829895,636.639832 697.048401,641.504944 
	C695.513489,643.066772 696.772400,644.120728 697.742004,645.293274 
	C699.521301,647.444885 698.672302,648.661865 696.169189,648.811707 
	C691.303284,649.102966 689.103455,651.046204 689.093811,656.126465 
	C689.088623,658.843262 688.407043,660.828430 686.074829,662.634521 
	C683.010742,664.972290 681.564636,668.088562 680.114990,671.152161 
	C679.535461,672.377014 678.989868,673.287537 677.567017,672.603516 
	C676.225708,671.958801 676.356812,671.158508 677.104309,669.823853 
	C682.357727,660.442505 686.390808,650.462708 692.184814,641.280579 
	C695.030762,636.770508 698.114990,631.314941 697.375061,625.125671 
	C697.177490,623.472961 699.111267,622.794434 699.693176,621.419067 
	C704.358459,610.391846 707.950623,599.010559 710.883850,587.415588 
	C711.028503,586.843689 710.668091,586.143982 710.271362,585.251892 
	C709.275024,581.553955 711.312988,578.694702 711.995300,575.547241 
	C712.440918,573.491272 714.285583,571.439514 711.057434,569.591980 
	C709.400085,568.643494 711.778381,567.534912 712.313416,566.555359 
	C713.042297,565.220764 714.483765,564.147522 715.979980,564.533691 
	C717.646912,564.963928 716.705139,566.435242 716.324646,567.420532 
	C715.394653,569.829224 717.366394,571.901001 717.022095,574.635376 
	C717.414673,576.502502 718.765198,577.950928 717.025208,579.707947 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M702.792114,571.751221 
	C705.125183,576.654724 703.950073,581.569763 703.592834,586.209290 
	C702.889282,595.345459 696.697083,602.480408 693.255981,610.649475 
	C693.045593,611.149048 692.171448,611.369141 691.280701,611.849731 
	C689.723694,610.717590 690.128418,609.265564 690.522217,607.472900 
	C690.519897,605.731812 692.194397,604.604309 690.233826,602.809875 
	C688.788269,601.486877 691.565735,600.322144 692.755798,598.886597 
	C693.492126,597.880676 693.664124,597.008301 693.907837,595.789673 
	C694.044983,595.212219 694.108948,594.980469 694.298096,594.405273 
	C696.963989,587.700134 697.254639,580.845337 698.829346,573.884033 
	C699.058228,572.771973 699.257202,572.041443 699.469788,570.922729 
	C699.670105,569.519836 699.796875,568.491516 700.540161,567.249451 
	C701.040466,566.878418 701.294556,566.814209 701.837341,567.016724 
	C702.108093,567.627075 702.090088,567.970825 702.041260,568.570923 
	C702.650208,569.616882 702.411621,570.592529 702.792114,571.751221 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M708.265869,555.652344 
	C708.010925,556.753357 708.007690,557.500671 708.005920,558.621582 
	C707.797058,561.191956 708.921875,564.225647 704.464600,564.019653 
	C701.636292,561.564026 702.658691,558.411255 702.374268,555.083496 
	C702.709351,550.702271 702.129211,546.739014 702.669739,542.366333 
	C702.836792,541.354675 703.039124,540.807251 703.622314,539.954346 
	C705.019409,538.930420 706.164124,538.890747 707.682373,539.699951 
	C707.885925,542.131287 708.040344,544.228821 708.937866,546.618530 
	C710.798584,549.882446 708.503235,552.525146 708.265869,555.652344 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M703.730347,553.563110 
	C703.946289,556.983093 703.969604,560.047485 704.029358,563.570557 
	C703.960022,565.162537 704.160583,566.429565 702.447632,567.142822 
	C702.126160,567.283386 701.726746,567.142761 701.516785,567.115601 
	C700.741577,566.838135 700.298645,566.448914 699.782715,565.517151 
	C697.747009,559.560425 698.564880,553.715088 697.748474,548.044434 
	C697.561279,546.744080 698.125610,545.451111 699.815918,544.611450 
	C703.468628,546.615112 701.854797,550.539368 703.730347,553.563110 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M670.203735,656.654053 
	C669.183105,657.184570 668.416077,657.510559 667.109619,657.660645 
	C666.424438,657.008972 666.219604,656.435486 666.448730,656.041504 
	C670.462463,649.141907 673.930847,641.905334 679.333374,635.546631 
	C679.938660,635.489746 680.183899,635.523926 680.725464,635.781860 
	C678.495850,643.334656 674.985962,650.155334 670.203735,656.654053 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M668.047424,679.349426 
	C671.419495,679.398621 672.566711,677.000427 673.991455,674.558105 
	C675.855652,674.145996 676.100403,674.871277 675.176331,676.260132 
	C673.466797,678.829407 671.768433,681.406006 669.830505,684.201538 
	C665.629089,684.063049 664.863464,682.535095 668.047424,679.349426 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M689.913818,692.742371 
	C689.920349,694.380188 690.381104,695.379700 691.552551,696.377686 
	C694.364868,698.773560 692.543152,700.585999 690.283997,701.677856 
	C688.728516,702.429565 686.955444,702.549744 687.553833,699.527832 
	C687.989746,697.326477 689.019165,695.118835 688.265259,692.433472 
	C688.685974,692.246338 689.208313,692.387451 689.913818,692.742371 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M690.222778,613.352356 
	C691.980652,613.079773 693.654480,613.351929 692.735107,615.147705 
	C691.964050,616.653748 694.567139,619.170715 691.485596,619.554382 
	C690.194153,619.715271 689.668030,620.952759 688.148682,620.918335 
	C687.264282,620.428833 687.068726,619.771118 686.996704,618.714600 
	C687.097229,617.462646 687.440857,616.635742 687.995605,615.543457 
	C688.625916,614.544006 689.205994,613.933594 690.222778,613.352356 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M685.223389,625.044067 
	C684.837830,628.441467 683.781860,631.334045 681.796875,634.193726 
	C681.299500,634.455505 681.045837,634.480896 680.577698,634.190796 
	C679.660767,630.279602 681.561340,627.648438 683.954163,624.903564 
	C684.532898,624.728760 684.781067,624.727539 685.223389,625.044067 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M685.956177,663.364014 
	C686.481140,663.106995 687.024658,663.105957 687.432007,663.340881 
	C688.748108,664.099854 689.309143,665.209290 688.505005,666.647095 
	C687.974731,667.595154 686.993713,668.228516 686.029785,667.662537 
	C684.097595,666.528076 685.821533,665.057129 685.956177,663.364014 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M699.517151,688.250122 
	C700.010864,690.040222 700.389099,692.023132 698.013977,692.681152 
	C696.463806,693.110596 696.068115,691.928955 695.904541,690.285583 
	C697.350891,690.156616 698.057800,688.761353 699.517151,688.250122 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M690.610474,612.917603 
	C690.140076,613.821533 689.766785,614.406860 688.890015,614.842224 
	C688.137634,614.200806 687.979858,613.681580 687.898315,612.725220 
	C688.509033,610.092224 688.032349,607.205200 691.655396,606.835876 
	C691.652771,608.472656 691.326111,609.865845 690.977295,611.619629 
	C690.872742,612.186401 690.790222,612.392639 690.610474,612.917603 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M673.807556,665.745605 
	C673.284973,667.099426 672.659241,668.579468 671.490662,668.024536 
	C670.520264,667.563660 670.748230,665.825378 671.371521,664.932861 
	C672.231445,663.701538 673.260132,663.899048 673.807556,665.745605 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M690.920593,655.594971 
	C690.729309,654.574036 690.982178,653.816528 691.786926,653.520508 
	C692.005310,653.440186 692.679932,653.907471 692.737000,654.202454 
	C692.972412,655.418335 692.283081,655.875183 690.920593,655.594971 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M687.710693,617.697449 
	C688.058044,618.500427 687.996826,619.308105 687.883057,620.517944 
	C687.439392,621.592163 687.048279,622.264221 686.087402,622.753906 
	C684.762634,620.453674 685.398254,618.845520 687.710693,617.697449 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M695.350037,689.653320 
	C694.766235,690.246338 694.232117,690.578979 693.378906,690.951355 
	C693.558899,689.954712 693.583740,688.553162 695.099609,688.332153 
	C695.147095,688.325195 695.297363,689.023376 695.350037,689.653320 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M719.063477,565.384705 
	C719.860046,565.133728 721.271851,564.986755 721.372253,565.283447 
	C721.687012,566.213989 720.762634,566.578552 719.661743,566.842407 
	C719.273682,566.485474 719.176147,566.088562 719.063477,565.384705 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M702.586914,686.653320 
	C702.157227,685.508972 702.729797,685.490295 703.359253,685.791565 
	C703.666138,685.938293 703.841003,686.360840 704.075684,686.658508 
	C703.647888,686.707703 703.220093,686.756836 702.586914,686.653320 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M703.017700,571.664856 
	C702.093750,571.431335 702.014404,570.505432 702.011963,569.187622 
	C702.930969,569.417297 703.051392,570.333923 703.017700,571.664856 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M686.079834,623.095459 
	C686.011353,623.739380 685.927063,624.332458 685.630127,625.143677 
	C685.417480,625.361816 685.153687,625.140076 685.021606,625.029419 
	C684.424988,624.164856 684.552307,623.509888 685.596924,622.966553 
	C685.973999,622.987610 686.063965,623.044617 686.079834,623.095459 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M681.134705,635.750366 
	C680.731812,636.012756 680.441772,636.019958 679.718079,636.063538 
	C679.147583,635.284485 679.363770,634.584717 680.335938,633.893921 
	C680.844116,633.882935 680.985474,633.990601 681.339966,634.264160 
	C681.451294,634.785095 681.349426,635.140076 681.134705,635.750366 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M709.870972,585.051941 
	C709.914001,585.034668 709.828003,585.069275 709.870972,585.051941 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M708.527954,690.549744 
	C708.475525,690.579712 708.580322,690.519775 708.527954,690.549744 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M715.416748,731.415039 
	C714.617432,731.653625 714.335205,731.380554 714.477356,730.757996 
	C714.506958,730.628540 714.683228,730.462219 714.814819,730.438232 
	C715.452515,730.321716 715.696472,730.627136 715.416748,731.415039 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M556.146912,262.546570 
	C555.472961,263.450806 555.032349,264.010651 554.351013,264.689209 
	C553.607239,264.478912 553.091003,264.190857 552.169861,264.374084 
	C550.798584,265.091370 549.645203,265.502686 548.114868,265.752258 
	C547.118713,265.956177 546.402771,266.027161 545.331909,266.158264 
	C544.765076,266.298218 544.553162,266.378052 544.080994,266.239594 
	C546.563477,263.209259 547.233765,259.728729 546.991211,255.570129 
	C546.600159,254.542770 546.278748,253.921570 545.957336,253.300369 
	C546.536682,253.376770 547.116028,253.453156 547.847778,253.763245 
	C548.171326,254.120605 548.342468,254.244278 548.758179,254.166656 
	C549.002747,253.965347 549.001404,253.997787 548.984375,253.992432 
	C552.184387,253.883026 552.950623,255.523544 552.028564,258.664001 
	C552.769043,259.556366 553.546631,260.124237 554.581299,260.829224 
	C555.976501,260.599640 556.269531,261.287201 556.146912,262.546570 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M556.288696,262.847565 
	C555.728027,262.350006 555.542480,261.809052 555.097717,261.117218 
	C555.605408,260.281677 556.372375,259.597015 557.436035,258.825897 
	C557.757629,260.194611 558.639954,261.875305 556.288696,262.847565 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M558.766479,250.204041 
	C558.991577,250.817047 558.956970,251.595963 558.853516,252.761444 
	C557.938232,252.297409 557.849182,251.371048 558.766479,250.204041 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M559.113525,255.124237 
	C559.544983,255.145493 559.870483,255.399719 560.196045,255.653931 
	C559.906860,255.740906 559.617615,255.827881 559.082581,255.877258 
	C558.893616,255.678848 558.950562,255.518021 559.113525,255.124237 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M298.234985,518.394043 
	C295.693848,504.722900 297.618561,491.468872 298.493347,478.212799 
	C298.785614,473.783508 299.525696,469.383820 300.084045,464.534668 
	C301.286377,463.109497 300.727661,460.430695 303.059357,460.689331 
	C305.309692,460.938995 303.752319,463.735199 305.588654,464.750793 
	C309.110413,465.771881 312.233734,464.032135 315.797363,464.625092 
	C317.427856,465.710388 318.031342,467.122070 318.744110,468.908386 
	C319.290436,472.488922 317.470886,475.235901 316.894348,478.617493 
	C315.778992,484.953766 313.095032,490.038147 309.024719,494.541870 
	C307.458221,496.275208 307.327942,498.581177 307.804779,501.098846 
	C308.663025,505.630463 307.707581,510.071106 305.421448,514.357666 
	C302.688446,513.974548 302.905090,511.919830 302.567108,510.238953 
	C301.670380,505.779572 301.573486,501.182159 300.021606,496.791870 
	C299.017822,499.196503 299.192383,501.530396 300.481506,504.149017 
	C302.027588,508.838959 301.826965,513.263977 301.159668,517.652161 
	C301.000427,518.699341 300.750671,521.842407 298.234985,518.394043 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M320.457520,417.835327 
	C323.167938,418.101410 322.852325,419.563263 321.871582,421.263641 
	C320.222748,424.122162 319.531036,427.201874 319.004272,431.279388 
	C322.616974,429.227142 325.058624,427.384247 325.922546,424.194244 
	C327.637268,417.862671 327.636322,417.821747 332.505737,422.316376 
	C332.988098,422.761627 333.424774,423.256378 334.065918,424.052368 
	C332.582214,431.017853 330.915253,437.659119 329.089050,444.653992 
	C328.351624,445.550598 327.664093,445.738464 326.550293,445.506775 
	C324.512482,443.885315 324.455109,441.933228 324.770660,439.928223 
	C324.963501,438.702942 325.072357,437.551178 323.615479,437.107117 
	C322.334381,436.716614 321.400726,437.385651 320.671448,438.391693 
	C319.099884,440.559723 318.177124,443.018097 317.412323,445.908447 
	C317.039795,447.894623 316.458099,450.043213 317.950989,450.929932 
	C322.871277,453.852417 321.892639,458.236359 321.244263,462.904907 
	C318.455811,464.292938 315.389740,463.742889 314.494019,461.570648 
	C312.256714,456.145050 307.576721,452.100189 306.200500,445.897888 
	C306.924713,439.493652 309.786926,434.208435 312.496674,428.498901 
	C316.203400,425.730652 317.117493,421.434784 320.022766,418.132202 
	C320.314056,417.921143 320.401123,417.846191 320.457520,417.835327 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M278.256958,457.719879 
	C277.492401,458.243225 277.013062,458.519043 276.249207,458.884918 
	C275.937500,458.811432 275.935760,458.514038 275.879486,458.503326 
	C270.116486,457.407928 272.658844,453.208313 273.134186,450.257355 
	C273.690369,446.804565 274.039124,443.390198 274.012543,439.462860 
	C276.973816,426.397858 280.622620,414.015900 286.683105,402.168884 
	C288.615845,400.951538 289.405975,401.911316 290.104797,403.652832 
	C286.944397,414.933594 282.985107,425.621765 281.462677,436.973999 
	C281.090546,439.748962 280.394745,442.461273 281.037292,445.645935 
	C281.061310,446.019745 280.995270,446.504364 280.977112,446.748230 
	C281.547516,449.535095 279.822113,451.574799 279.428711,454.275696 
	C279.322998,455.648102 279.094299,456.609406 278.256958,457.719879 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M314.135345,428.675964 
	C310.625183,434.027893 308.982758,439.708221 307.060547,445.663391 
	C307.768494,452.564606 308.217072,459.068024 305.263641,465.645142 
	C301.753510,465.642883 303.857849,462.851227 302.924103,461.434814 
	C302.135284,462.328522 301.427338,463.130585 300.413239,464.015717 
	C300.085114,463.280884 300.063110,462.462982 300.065125,461.237732 
	C300.089142,460.830353 300.240204,460.423584 300.240204,460.423584 
	C300.240204,460.423584 300.235291,460.488068 300.172333,460.675385 
	C296.083954,461.234985 296.570618,458.239777 297.234772,456.223022 
	C299.849365,448.283142 301.311401,440.083862 303.211365,431.982910 
	C303.312439,431.551941 303.722809,431.193481 304.000183,430.785095 
	C304.333923,430.987640 304.788177,431.147766 304.779175,431.275055 
	C304.624878,433.451477 302.974854,436.744446 305.899048,437.272217 
	C308.918854,437.817230 307.669830,434.072327 308.333984,432.281403 
	C309.812958,428.293457 310.992615,424.194489 312.647919,420.070984 
	C313.130157,421.517914 312.654388,423.102112 313.789978,424.790985 
	C315.018890,426.057739 314.504974,427.225067 314.135345,428.675964 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M298.716827,494.547119 
	C301.941528,493.245209 301.649811,495.849945 301.899689,497.348206 
	C302.791016,502.691864 303.426208,508.078278 304.385864,513.777771 
	C304.808624,514.139099 305.003845,514.171082 305.324097,514.474182 
	C305.480927,515.359375 305.327667,515.926270 304.924713,516.735779 
	C305.169281,520.488892 305.543396,523.955261 305.822235,527.429199 
	C306.006439,529.724182 306.338898,532.058167 304.723572,534.095581 
	C303.719238,535.362366 303.326202,537.325500 304.015778,538.337402 
	C305.864685,541.050537 303.083618,542.210571 302.427673,544.312500 
	C300.792084,535.987427 298.658356,527.558472 297.988495,518.454834 
	C298.868652,518.277039 300.252777,520.245361 300.085632,517.310120 
	C299.852997,513.224426 299.722076,509.132904 299.364075,504.699310 
	C297.772400,501.315277 298.822052,498.110748 298.716827,494.547119 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M332.442261,394.052612 
	C328.178192,395.389282 327.571960,398.804291 327.014465,402.626892 
	C327.037354,403.018585 327.012085,403.004608 327.012543,403.014465 
	C326.489746,403.445374 326.504669,404.092316 326.172821,404.920959 
	C325.098602,406.950104 324.178772,408.716827 323.104797,410.784546 
	C322.258118,411.816650 322.091980,412.869873 321.202515,413.820892 
	C320.997009,414.035583 320.995300,413.994263 321.014771,414.001190 
	C320.352142,414.499329 319.879639,414.143097 319.275330,413.347717 
	C321.640106,402.087982 327.126221,392.419342 332.845123,382.890076 
	C335.572815,378.345032 338.739014,374.063141 341.893463,369.383240 
	C343.819092,368.729462 343.993469,370.082001 344.366608,371.543823 
	C343.177338,373.708801 341.190033,375.201080 342.052917,378.155273 
	C340.535248,384.622894 336.597992,389.316864 332.442261,394.052612 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M331.696899,324.786926 
	C334.340363,326.996307 336.231232,326.743896 338.744934,323.798401 
	C340.881134,321.295166 343.054535,319.359955 346.967285,321.122620 
	C347.564117,321.603882 347.656677,322.057587 347.499512,322.871948 
	C343.444183,328.169891 339.076111,332.824524 335.980042,338.830688 
	C335.215851,340.081696 334.485168,340.942291 333.654785,342.144562 
	C332.502563,343.355377 331.650146,344.407928 330.086884,345.069183 
	C328.659180,344.090973 326.562408,343.781647 327.298462,341.468109 
	C328.968567,336.218567 329.655304,330.881470 328.806030,325.202240 
	C329.755157,324.408295 330.525513,324.272705 331.696899,324.786926 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M344.305878,372.066711 
	C343.548676,371.011566 343.045685,370.212128 342.311523,369.258575 
	C341.947815,367.766541 342.077850,366.535614 343.058105,365.457672 
	C345.392090,362.891113 347.668091,360.271820 350.023438,357.384247 
	C355.992889,353.576538 360.848572,349.104950 363.360596,342.483521 
	C363.494720,342.130066 364.023163,341.904388 364.409790,341.697144 
	C364.456787,341.671967 364.765564,342.135162 364.976013,342.677521 
	C367.303467,346.064270 362.675476,348.364014 364.085083,351.743408 
	C363.837311,352.876953 363.561249,353.608246 363.231628,354.636780 
	C363.178101,354.933960 362.996338,354.969299 362.909363,355.001007 
	C357.356842,357.882355 354.379639,363.096954 350.825714,367.743958 
	C349.442017,369.553284 348.518494,371.769012 345.628357,372.386536 
	C345.034729,372.398865 344.797424,372.360657 344.305878,372.066711 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M329.701355,345.316895 
	C330.510162,344.119568 331.264648,343.120758 332.324585,342.061462 
	C333.568146,341.387909 334.530273,340.803894 335.425812,339.602478 
	C337.404266,336.346191 339.577148,333.661713 341.956970,331.173126 
	C342.972748,330.110992 344.460724,328.572815 345.219177,331.686890 
	C342.746246,336.873138 339.490479,341.118713 336.150696,345.711884 
	C335.172638,347.039551 334.036285,347.942261 333.579651,349.110596 
	C332.407959,352.108490 330.378662,352.700623 327.256714,351.879730 
	C327.192474,350.840240 327.444611,349.739532 327.827637,348.321350 
	C328.005310,347.411499 328.202118,346.879852 328.809235,346.232147 
	C329.293945,345.938568 329.502136,345.764954 329.701355,345.316895 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M281.289185,473.702545 
	C279.925171,474.391571 280.003601,476.156158 278.001434,476.088989 
	C278.001434,472.049225 278.001434,467.988708 278.001343,463.465332 
	C278.469360,462.684052 278.937469,462.365631 279.700684,462.016602 
	C280.613037,461.947693 281.144928,462.135162 281.787109,462.790009 
	C281.772980,466.463226 282.494415,469.969025 281.289185,473.702545 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M282.008301,462.617188 
	C281.443726,462.820831 280.891113,462.645844 280.167145,462.228424 
	C281.325378,459.677490 277.321960,460.468536 277.982971,458.254761 
	C277.993103,456.906494 278.014435,455.845612 278.052826,454.386475 
	C279.041168,453.169769 280.102081,452.942139 281.635620,453.753052 
	C281.873383,456.749939 283.187347,459.489532 282.008301,462.617188 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M281.993469,453.754578 
	C280.942780,454.001923 279.896606,453.998962 278.460144,453.992157 
	C278.860443,451.805511 279.651062,449.622772 280.700317,447.216034 
	C284.514252,448.008026 283.710236,450.236877 282.257812,452.789490 
	C282.001526,453.170532 281.999756,453.337402 281.993469,453.754578 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M314.152039,428.829224 
	C313.916992,427.947998 313.982605,426.891418 314.059998,425.439484 
	C316.695465,423.235718 315.566132,418.620514 319.905579,417.855438 
	C319.152374,421.931335 317.017242,425.409882 314.152039,428.829224 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M303.679688,549.343018 
	C307.364380,550.153625 306.312378,554.266418 308.130951,556.679626 
	C308.644104,557.360657 307.819672,557.826416 307.152435,557.944153 
	C305.958405,558.154907 306.003906,557.039001 305.349854,556.197266 
	C304.664429,553.905212 304.237518,551.816895 303.679688,549.343018 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M332.274902,324.785339 
	C331.281982,325.025726 330.545166,325.005737 329.442017,324.989471 
	C329.082672,323.933655 329.089600,322.874084 329.136444,321.418854 
	C333.801453,318.830811 333.330658,321.520325 332.274902,324.785339 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M327.018921,352.019348 
	C326.711945,353.453400 326.542053,354.965759 324.684326,355.625763 
	C324.094635,353.825348 324.896454,352.811768 326.616577,351.995483 
	C326.940338,351.940918 326.992859,351.993073 327.018921,352.019348 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M298.102295,402.713562 
	C297.419556,404.233795 296.175568,404.932831 295.108826,403.800232 
	C294.259338,402.898315 295.239990,402.139587 296.667542,401.846558 
	C297.339752,401.997131 297.664062,402.218109 298.102295,402.713562 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M319.009186,413.469574 
	C319.573792,413.334381 320.046326,413.589417 320.776550,413.926270 
	C321.021820,414.885986 321.009430,415.763824 320.988464,417.086060 
	C318.919373,417.078430 318.486084,415.711945 319.009186,413.469574 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M292.100342,457.434326 
	C293.168121,456.234467 292.991028,457.540344 293.283051,457.882446 
	C293.369171,457.983307 293.091370,458.394806 292.980347,458.663910 
	C292.682800,458.336121 292.385315,458.008301 292.100342,457.434326 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M328.733765,346.057617 
	C328.831635,346.582001 328.640869,347.127655 328.204346,347.838562 
	C326.956665,347.067932 327.213776,346.450287 328.733765,346.057617 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M312.999390,419.998871 
	C313.086914,419.963684 313.175049,419.929626 313.132385,419.948364 
	C313.001587,420.001221 313.000000,420.000000 312.999390,419.998871 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M292.525452,419.541565 
	C292.477875,419.568573 292.573029,419.514557 292.525452,419.541565 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M261.308105,390.303864 
	C259.744507,388.985687 259.755096,387.198425 259.033020,385.371002 
	C261.318085,385.638763 262.677490,383.065887 264.921326,383.149872 
	C265.919525,384.693268 262.804413,385.986633 264.749115,387.505402 
	C269.055725,384.523560 268.938049,377.310303 275.666595,376.143402 
	C275.815643,381.796814 270.961792,385.481384 269.298218,390.706787 
	C268.528900,393.123230 266.176453,394.746704 268.525360,397.603180 
	C269.333893,398.586395 267.907593,399.589752 266.341675,399.960999 
	C263.908844,399.243622 264.367981,396.911102 263.092896,395.353363 
	C263.768311,394.041504 262.898010,393.251740 262.188568,392.171204 
	C261.821533,391.450348 261.676361,390.947723 261.308105,390.303864 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M290.449524,403.868439 
	C289.328918,403.392426 288.482513,402.739471 287.314270,402.040802 
	C289.420441,395.439209 291.848419,388.883301 294.276428,382.327423 
	C294.746979,382.477386 295.217529,382.627350 295.688080,382.777313 
	C297.338867,390.428589 290.919067,396.419952 290.449524,403.868439 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M258.996582,411.249634 
	C258.831848,409.528259 259.170990,408.184387 260.694000,407.058838 
	C261.737793,407.149506 262.152466,407.660370 262.263245,408.705322 
	C261.484009,410.221252 261.549683,412.316071 258.996582,411.249634 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M287.563354,383.750854 
	C284.000732,382.280792 288.097046,380.835815 286.787292,378.748291 
	C288.902679,380.549042 288.590698,382.044952 287.563354,383.750854 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M262.488708,408.966187 
	C261.858490,408.523499 261.540802,408.006561 261.120544,407.204224 
	C261.579834,406.437775 262.141693,405.956757 262.851013,405.235413 
	C266.356812,406.467346 263.932343,407.646942 262.488708,408.966187 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M276.287903,375.967834 
	C276.188354,375.438568 276.374268,374.879059 276.815613,374.155151 
	C278.062439,374.935547 277.749390,375.546844 276.287903,375.967834 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M345.277924,331.788391 
	C345.089905,329.701752 344.184692,330.231049 343.228699,331.406708 
	C340.991272,334.158203 338.814667,336.959106 336.307587,339.877991 
	C335.527710,340.014008 335.087708,339.890594 334.468018,339.369751 
	C337.539734,333.352203 341.250183,327.919403 346.135986,323.044739 
	C347.335236,322.709778 347.721222,321.788452 348.733276,321.142395 
	C348.963715,320.904968 348.989471,320.976196 348.953186,320.965332 
	C349.208527,320.793396 349.460205,320.584229 349.798767,320.115601 
	C349.925629,319.904205 349.976318,320.003479 349.938782,319.962189 
	C350.208832,319.789154 350.465546,319.591339 350.797668,319.117432 
	C350.924042,318.907410 351.003326,318.984497 350.956390,318.955566 
	C351.212708,318.786041 351.468445,318.584991 351.810303,318.117310 
	C351.943939,317.911163 351.992432,317.983276 351.953033,317.964966 
	C352.210114,317.789886 352.465149,317.582275 352.824921,317.123535 
	C352.971069,316.923309 352.990601,316.989746 352.957092,316.981018 
	C353.207092,316.796173 353.457184,316.581116 353.812561,316.121429 
	C353.951202,315.915649 353.965179,315.991211 353.930115,315.975525 
	C354.040985,315.797882 354.186951,315.635956 354.136719,315.212402 
	C357.155121,311.244080 360.904846,308.241699 365.911926,305.142456 
	C364.975861,308.805389 362.621887,309.978210 361.316925,311.902618 
	C359.780518,314.168365 356.311615,315.570282 357.853638,319.343018 
	C358.211792,320.219177 357.420502,321.046997 356.677307,321.276672 
	C351.293518,322.940460 349.450775,328.371338 345.277924,331.788391 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M347.705811,303.149902 
	C348.609802,303.556976 349.214081,304.113983 349.925720,304.975739 
	C346.894318,308.129059 343.755585,310.977600 340.334625,314.025757 
	C338.990204,308.218719 344.716827,306.781433 347.705811,303.149902 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M372.632324,327.049072 
	C375.045135,328.435699 374.317902,329.597595 372.310577,330.792877 
	C370.497742,329.550659 371.120117,328.315826 372.632324,327.049072 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M375.730774,306.059387 
	C376.007263,307.685852 374.619385,308.059204 373.264160,308.871490 
	C371.154663,305.874878 373.882568,306.462402 375.730774,306.059387 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M368.699249,309.117157 
	C369.202484,308.683044 369.628937,308.484863 370.055389,308.286682 
	C370.112671,308.567688 370.315826,308.968170 370.201080,309.108276 
	C369.785309,309.615875 369.283813,310.186523 368.699249,309.117157 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M348.695496,320.762939 
	C348.765015,321.938629 348.134460,322.584564 346.798218,322.944946 
	C346.649200,322.575195 346.858124,322.178802 347.209045,321.477661 
	C347.709808,320.988892 348.068542,320.804901 348.695496,320.762939 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M330.928345,317.716980 
	C330.158081,318.634613 330.115051,317.896606 330.083221,317.500671 
	C330.062897,317.247864 330.346802,316.970612 330.494293,316.704315 
	C330.653046,316.962372 330.811798,317.220398 330.928345,317.716980 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M351.604279,317.824677 
	C351.783661,318.228119 351.623383,318.545105 351.186279,318.894348 
	C351.027832,318.530487 351.146240,318.134338 351.604279,317.824677 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M352.632507,316.886902 
	C352.794617,317.224945 352.618164,317.526550 352.177673,317.887421 
	C352.040405,317.581268 352.167145,317.215851 352.632507,316.886902 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M349.585083,319.818939 
	C349.766876,320.212555 349.608093,320.520905 349.183105,320.891846 
	C349.026093,320.547516 349.135315,320.140594 349.585083,319.818939 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M350.577271,318.830933 
	C350.773651,319.230835 350.623230,319.554260 350.187012,319.899292 
	C350.010986,319.532074 350.120758,319.143250 350.577271,318.830933 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M370.470917,314.453369 
	C370.519348,314.426086 370.422485,314.480682 370.470917,314.453369 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M325.986938,315.981628 
	C326.094391,316.013123 326.112122,315.144012 326.184418,315.978760 
	C326.046295,316.037750 326.005066,316.002045 325.986938,315.981628 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M353.746521,315.194855 
	C354.174072,315.135162 354.407715,315.319519 354.641327,315.503845 
	C354.544983,315.624786 354.448639,315.745758 354.123657,315.913269 
	C353.780914,315.786163 353.666748,315.612518 353.746521,315.194855 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M353.620667,315.848267 
	C353.779694,316.213837 353.608154,316.512024 353.180115,316.891235 
	C353.045746,316.575195 353.167938,316.178040 353.620667,315.848267 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M255.407013,361.008453 
	C255.923462,355.488556 259.068970,351.860352 264.694519,350.042450 
	C264.964081,351.500641 265.151978,352.988464 264.106873,354.603699 
	C264.566437,355.674438 264.035828,356.130676 263.248169,356.786865 
	C262.968323,356.981140 263.003784,356.996887 262.992493,356.980804 
	C262.400085,357.603699 261.769775,358.195679 260.968506,359.078735 
	C260.565643,359.446960 260.385712,359.573792 259.567902,359.830139 
	C257.866638,360.296265 256.803223,360.632874 255.407013,361.008453 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M272.812988,341.794952 
	C272.098145,343.505859 271.188843,344.968811 270.148651,346.729980 
	C269.186615,347.671509 268.799591,348.608643 268.174622,349.747986 
	C267.978729,349.977966 267.989441,349.991394 267.980042,349.984406 
	C267.242615,349.994019 266.514587,350.010651 265.423584,350.036377 
	C266.388611,346.153229 268.776276,343.203339 272.812988,341.794952 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M259.740234,359.242065 
	C260.466583,357.975647 261.210358,357.270660 262.664093,357.005768 
	C262.427826,358.459808 261.930603,359.979279 261.298035,361.440063 
	C260.965179,362.208771 261.053864,363.739410 259.697021,363.285156 
	C258.702881,362.952332 259.084259,361.613007 258.947021,360.326233 
	C258.930023,359.959656 258.962708,359.967804 258.972961,359.957703 
	C259.158234,359.797791 259.333282,359.648010 259.740234,359.242065 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M264.887329,364.070831 
	C265.624542,362.555634 266.404205,361.046417 267.409760,359.099945 
	C268.105682,361.512695 269.078949,363.503967 265.317993,364.117493 
	C264.931122,364.162598 264.929810,364.076813 264.887329,364.070831 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M263.273468,356.972076 
	C263.219269,356.472351 263.470184,355.963531 263.833649,355.191833 
	C264.401672,355.209351 265.263672,355.531189 265.240082,355.762848 
	C265.152069,356.627472 264.332733,356.821472 263.273468,356.972076 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M268.269897,349.844910 
	C268.062775,348.853088 267.844910,347.584778 269.675598,347.077759 
	C269.981537,348.166656 269.536774,349.083374 268.269897,349.844910 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M293.113190,339.225586 
	C293.328796,339.108246 293.585571,339.294922 293.890594,339.781036 
	C293.680847,339.894867 293.419342,339.711243 293.113190,339.225586 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M338.861206,301.891663 
	C338.013214,303.540405 336.835022,305.070648 335.363586,306.704132 
	C335.325775,304.511932 334.235229,301.291138 338.861206,301.891663 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M330.191589,306.862274 
	C330.003082,306.580261 330.023651,306.156036 330.052002,305.415100 
	C330.797729,305.507751 330.920074,306.046448 330.191589,306.862274 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M228.916306,507.631714 
	C224.587204,511.068390 229.465393,514.152771 228.992035,517.614014 
	C227.825058,517.843872 226.650101,517.687744 225.237274,517.265747 
	C227.717041,514.960754 225.578979,513.530029 224.565323,511.695862 
	C223.763351,510.244690 222.239410,508.158112 223.690796,506.746033 
	C225.025650,505.447296 227.296249,505.266632 228.916306,507.631714 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M217.816040,492.836487 
	C217.773483,498.317047 219.536575,503.366119 220.037537,509.051208 
	C217.015335,505.040527 217.262192,499.681244 216.100739,494.302795 
	C216.402145,493.667542 216.654968,493.443512 217.058060,493.009094 
	C217.208344,492.798706 217.656738,492.665192 217.816040,492.836487 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M214.326202,482.962402 
	C214.718094,483.397095 214.813431,483.934723 214.857452,484.743042 
	C213.698975,484.702332 212.643860,484.370178 214.326202,482.962402 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M220.000946,509.857910 
	C220.290375,510.119202 220.611740,510.761841 220.986618,511.773132 
	C220.704376,511.507568 220.368607,510.873413 220.000946,509.857910 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M218.332062,492.967407 
	C217.975357,493.007751 217.590729,492.906738 217.399536,492.852722 
	C217.090317,490.487854 216.972290,488.177032 216.919647,485.433472 
	C218.852432,485.961914 221.922989,482.091339 222.592224,485.667328 
	C223.074570,488.244659 223.568085,492.475739 218.332062,492.967407 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M229.052826,508.128052 
	C229.113800,508.131866 229.227600,508.263733 229.341370,508.395630 
	C229.262787,508.349121 229.184204,508.302612 229.052826,508.128052 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M254.001526,418.000000 
	C251.130157,417.883545 247.943787,418.144135 248.866150,413.311340 
	C249.308090,412.546814 249.619247,412.091187 249.998245,411.308197 
	C252.951828,411.578766 255.349869,412.631439 254.981964,416.642944 
	C254.811813,417.187714 254.646622,417.347931 254.240417,417.755310 
	C253.999374,418.002441 254.000000,418.000000 254.001526,418.000000 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M261.990967,392.288208 
	C263.920624,392.103851 264.391510,393.080536 263.240417,394.972107 
	C262.628113,394.310638 262.321686,393.467072 261.990967,392.288208 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M248.865875,405.268921 
	C248.767990,405.734406 248.631638,405.978363 248.495300,406.222321 
	C248.357925,405.978180 248.100052,405.728302 248.111313,405.491211 
	C248.128418,405.130768 248.136963,404.450958 248.865875,405.268921 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M249.998810,411.034302 
	C249.695282,410.509430 249.393036,409.966431 249.149216,409.398376 
	C249.129944,409.353424 249.516296,409.134399 249.714157,408.996277 
	C249.816223,409.464722 249.918289,409.933136 250.043213,410.691223 
	C250.066101,410.980835 250.014984,411.009796 249.998810,411.034302 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M254.210052,417.892273 
	C254.105774,417.691895 254.290497,417.438446 254.765259,417.134766 
	C254.870148,417.336945 254.684738,417.588501 254.210052,417.892273 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M270.009216,370.015503 
	C269.646088,370.462769 269.370392,371.306366 268.644775,370.397064 
	C268.915741,370.256287 269.186676,370.115540 269.700134,369.957794 
	C269.942627,369.940826 269.988220,369.989563 270.009216,370.015503 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M258.136322,382.944092 
	C258.099457,383.034424 258.035339,382.232605 258.136322,382.944092 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M258.000549,383.760529 
	C257.826691,384.004425 257.659454,384.003937 257.241455,383.995941 
	C257.108246,383.680054 257.297089,383.424316 257.765411,383.100677 
	C257.973633,382.980194 258.001648,383.002014 258.014832,383.014893 
	C258.028015,383.027740 258.007233,383.516174 258.000549,383.760529 
z"
    />
    <path
      fill="#FAFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M494.620697,302.007538 
	C496.102661,301.673004 497.216248,301.466675 498.657501,301.143799 
	C502.566772,300.615936 506.148346,300.204559 510.134705,299.816711 
	C511.166626,299.754547 511.760620,299.508911 512.681519,299.323303 
	C513.139893,299.111725 513.311401,299.033051 513.739136,298.834351 
	C515.165527,297.859070 516.460510,297.634033 518.234131,298.093689 
	C520.142761,298.726746 521.715881,298.457275 523.625427,298.290527 
	C524.396179,298.011871 524.817383,297.904755 525.546875,297.691467 
	C526.785522,297.069855 527.758850,296.686829 529.224609,296.591309 
	C530.386353,296.577057 531.157654,296.616089 532.309326,296.595093 
	C533.191467,296.560486 533.694214,296.556702 534.570312,296.568237 
	C535.436523,296.590210 535.929688,296.603668 536.789795,296.592560 
	C538.124268,296.574432 539.091248,296.620697 540.416260,296.627930 
	C541.716492,296.614929 542.658813,296.653137 543.950195,296.652954 
	C544.530396,296.655609 544.761963,296.661591 545.339233,296.693298 
	C546.141357,296.778107 546.588928,296.876099 547.334351,297.161407 
	C547.854919,297.378113 548.067200,297.453735 548.615723,297.564667 
	C550.359619,297.647278 551.767395,297.694580 553.591553,297.819427 
	C554.847656,299.000885 554.519470,300.015503 553.453552,301.274261 
	C551.629211,302.385620 550.002869,302.385651 547.979553,302.185364 
	C546.318909,301.929260 544.914124,300.925415 543.759338,301.737244 
	C538.815552,305.212921 532.803589,306.163422 526.693237,308.849335 
	C529.190796,310.082062 531.660339,309.566772 533.277710,311.664429 
	C533.568176,312.726379 533.724976,313.470764 533.577881,314.621826 
	C532.955872,316.294281 531.848938,316.980835 530.315674,317.722839 
	C519.763916,318.854004 509.609375,320.169983 499.658356,322.732880 
	C499.091309,322.878937 498.314911,322.729370 498.211670,323.512817 
	C500.383240,324.007385 501.883392,324.859924 501.679352,327.556213 
	C500.274628,330.251892 497.835815,330.530151 495.617188,331.048523 
	C490.102234,332.337067 484.745331,334.067566 479.221558,336.476746 
	C476.696503,337.344788 474.498413,338.044922 472.167908,339.324951 
	C471.266357,339.782104 470.648499,340.047943 469.760162,340.527893 
	C469.267975,340.804169 469.069427,340.914246 468.566132,341.181091 
	C456.466339,347.348969 445.060974,353.993317 434.343872,362.354156 
	C433.989685,362.785553 433.836700,362.946014 433.426819,363.310791 
	C432.311981,364.111237 431.224945,364.330078 430.315125,365.395813 
	C430.003143,365.820038 429.872284,365.987274 429.528046,366.397186 
	C429.157318,366.790894 428.999908,366.941986 428.581696,367.297302 
	C428.131744,367.619263 427.942596,367.737061 427.454590,368.007874 
	C424.323730,369.585693 421.808228,371.429199 419.517578,374.079163 
	C419.221375,374.535919 419.100708,374.715790 418.781006,375.147766 
	C418.437683,375.554626 418.293335,375.709381 417.917084,376.076172 
	C417.524658,376.419495 417.364075,376.550842 416.965393,376.878143 
	C416.418549,377.345825 416.117340,377.625641 415.615509,378.147400 
	C414.268066,379.634735 412.639862,380.289917 411.309998,381.791931 
	C410.967468,382.173157 410.829712,382.323059 410.268494,382.896698 
	C409.702301,383.454041 409.559540,383.587708 408.993073,384.111938 
	C408.431213,384.632172 408.293030,384.761871 407.748535,385.284607 
	C407.207245,385.810883 407.072296,385.944122 406.515442,386.471436 
	C405.794586,387.111542 405.484711,387.342987 404.922546,387.716980 
	C404.371368,388.084076 404.068420,388.303253 403.592346,388.738220 
	C403.301514,389.083740 403.194824,389.228577 402.791199,389.817383 
	C402.389893,390.403198 402.285522,390.545105 401.861267,391.101196 
	C401.340332,391.801544 401.148346,392.093719 400.833374,392.626099 
	C400.605347,393.014862 400.507996,393.168427 400.241272,393.538879 
	C399.823029,394.029053 399.563385,394.291595 399.096375,394.740967 
	C398.640991,395.203796 398.389099,395.475494 397.980896,395.980042 
	C397.613464,396.517487 397.413757,396.831360 397.080933,397.391571 
	C396.840424,397.788849 396.738434,397.943207 396.458282,398.304138 
	C395.977173,398.637054 395.880463,398.762787 395.984772,398.892029 
	C396.086609,399.018250 396.184937,399.083496 396.404205,399.334961 
	C396.641235,400.416718 396.277374,401.035645 395.436218,401.709229 
	C394.747101,402.184021 394.290161,402.500122 393.756470,403.164001 
	C393.476410,403.563538 393.365936,403.728088 393.086731,404.143799 
	C392.186188,405.410736 391.628571,406.515320 391.130737,407.992432 
	C390.816010,408.843536 390.546356,409.378448 390.156250,410.170532 
	C389.944427,410.589966 389.857056,410.754028 389.635742,411.157562 
	C389.328613,411.710968 389.159546,412.027130 388.891479,412.587341 
	C388.721344,412.989960 388.654633,413.150909 388.492859,413.553986 
	C388.278046,414.120148 388.161072,414.445190 387.971069,415.019012 
	C387.837097,415.429047 387.778900,415.591339 387.618225,415.989990 
	C387.366943,416.532135 387.214722,416.836151 386.952209,417.365356 
	C386.846130,417.592651 386.625824,418.037964 386.512756,418.256378 
	C386.250153,418.760803 386.108734,419.050751 385.896790,419.570923 
	C385.818146,419.797150 385.698181,420.262665 385.672852,420.501343 
	C385.643372,421.078094 385.707855,421.402679 385.979431,421.950714 
	C386.451172,422.668091 386.796570,423.140137 387.231018,423.923584 
	C387.411865,425.378571 386.733215,426.199829 385.859833,427.263062 
	C385.122894,428.173676 384.875092,428.943878 384.857788,430.144531 
	C381.082397,441.390167 377.927734,452.466217 375.112854,464.008240 
	C374.862152,465.123901 374.885162,465.975739 374.143433,466.512207 
	C375.145020,466.211151 374.722290,465.252563 375.078033,464.279419 
	C376.376373,459.675446 377.525482,455.382080 380.341187,451.389343 
	C382.125275,450.424530 383.607483,449.875824 385.370239,451.327271 
	C386.251434,453.248993 385.499390,454.750214 385.208221,456.658081 
	C383.106293,460.712616 381.965088,464.755432 380.214172,468.939667 
	C379.248779,470.754028 379.396362,472.583984 378.159760,474.267395 
	C375.783905,476.116516 375.327393,478.060242 376.746582,480.754639 
	C376.585236,489.647064 374.764587,498.088715 375.576782,506.945496 
	C376.340973,507.660645 376.904114,508.100647 377.575623,508.967407 
	C378.450256,510.866241 378.391479,512.540649 378.448425,514.201782 
	C379.635864,548.827515 392.866699,578.415161 417.892242,601.948853 
	C439.825195,622.574341 466.094391,635.551270 496.400940,639.219482 
	C511.856171,641.090149 527.246643,641.002747 542.792969,636.810059 
	C543.916687,636.599548 544.674133,636.521240 545.742371,636.127197 
	C550.890442,635.181885 555.535767,633.886169 560.183777,631.439331 
	C565.061035,629.236816 569.818665,627.611206 574.231934,624.480713 
	C579.668396,620.962891 584.889832,617.767212 590.264160,614.192383 
	C590.877686,614.158081 591.125916,614.177002 591.734436,614.306152 
	C593.272766,615.036194 594.072571,615.981812 594.574707,617.607910 
	C594.580139,618.761475 594.277161,619.440430 593.584595,620.337402 
	C582.112915,630.325073 569.860229,638.486816 556.206665,645.141846 
	C555.384766,645.724365 554.827637,646.093140 554.091187,646.772827 
	C553.399292,647.523193 552.892334,647.979553 552.004089,648.515503 
	C551.222778,648.791199 550.752441,648.861328 549.912354,648.862366 
	C542.956665,649.490784 537.030823,652.657349 530.226807,653.998291 
	C528.093140,654.646240 526.335815,655.231812 524.078186,655.499756 
	C515.218750,657.276672 506.646393,657.819885 498.077942,658.655762 
	C477.697174,660.643799 458.583374,655.621765 439.154175,649.493408 
	C433.091431,646.630615 427.183655,644.388611 421.662445,641.328491 
	C420.065613,640.443420 418.601166,639.353821 417.235138,637.581360 
	C416.602692,637.026672 416.221924,636.755798 415.504486,636.387451 
	C414.399780,636.621216 413.879028,636.261780 413.216064,635.414490 
	C412.699280,634.918213 412.407288,634.679138 412.055206,634.259338 
	C413.940613,633.609070 413.975525,632.036011 414.107574,630.034546 
	C417.260315,630.534363 419.912445,628.717712 423.121155,628.207214 
	C429.210205,630.570374 435.329712,631.775940 441.341522,634.418091 
	C444.349976,635.657104 446.949829,636.958923 450.214783,637.378906 
	C452.421387,637.620850 453.813629,638.930176 455.825195,639.608154 
	C457.481415,640.181641 458.764160,639.266174 460.406860,639.677979 
	C460.964081,640.002319 461.231018,640.156982 461.505493,640.299622 
	C461.937195,640.523804 462.493286,640.653076 462.850616,640.490906 
	C463.438782,640.223877 463.168335,641.101929 462.955902,640.483154 
	C462.921387,640.382629 462.087952,640.453491 461.334106,640.206177 
	C460.006989,639.176880 458.835785,639.547791 457.337524,639.633179 
	C455.211639,638.888123 453.386047,638.307983 451.258545,637.523193 
	C447.964813,636.979431 445.126801,636.191956 442.150391,634.632446 
	C437.063385,631.831970 431.475739,631.531372 426.380890,628.853516 
	C424.807007,622.745483 424.428131,622.535034 418.309662,624.541809 
	C414.310669,624.517700 411.778656,622.586426 409.722504,619.355042 
	C409.513153,618.835571 409.425262,618.632751 409.347626,618.109253 
	C409.390594,617.344604 410.053040,617.386230 410.026367,617.179565 
	C409.989685,616.895020 409.581177,616.912537 408.959778,617.053711 
	C406.581909,615.372314 404.756989,613.647217 402.374847,612.292664 
	C395.682526,608.487122 389.768951,603.451294 385.173401,596.674561 
	C384.866699,596.243835 384.743347,596.076233 384.435913,595.667969 
	C384.134918,595.269592 384.017975,595.112000 383.741760,594.714661 
	C383.396820,594.156250 383.219910,593.832764 382.920746,593.281006 
	C382.696594,592.916321 382.604614,592.774475 382.363922,592.432495 
	C382.215240,592.232239 381.895966,591.856140 381.555603,591.504150 
	C381.215210,591.152161 380.869354,590.806763 380.697754,590.633057 
	C380.297577,590.226440 380.070740,589.991821 379.667328,589.584595 
	C379.488953,589.413696 379.133514,589.066162 378.783997,588.707397 
	C378.322144,588.223938 378.209808,588.099304 377.938477,587.779663 
	C377.683105,587.448486 377.586670,587.312134 377.359253,586.964783 
	C377.228271,586.753723 376.974579,586.332397 376.843445,586.127075 
	C376.712311,585.921692 376.431763,585.527161 376.281525,585.338257 
	C376.131287,585.149414 375.817322,584.783264 375.655945,584.604126 
	C375.275116,584.188843 375.054810,583.953369 374.658875,583.546997 
	C374.484100,583.375427 374.144104,583.018188 373.984100,582.828064 
	C373.728180,582.501526 373.632233,582.365051 373.411072,582.011353 
	C373.130676,581.494690 372.977020,581.194580 372.693665,580.668396 
	C372.472229,580.289551 372.381836,580.136108 372.151978,579.749390 
	C371.715454,579.029724 371.406708,578.551392 370.859680,577.857178 
	C369.502899,575.973816 368.408661,574.288696 367.018707,572.377991 
	C363.548126,566.342957 361.392059,560.056396 357.998108,553.940063 
	C354.400482,544.462646 352.584595,534.922058 350.588898,525.000366 
	C350.036285,516.161499 347.368347,508.001190 347.538391,499.049011 
	C347.585358,487.258148 346.901215,475.894318 348.556824,464.157623 
	C348.758636,458.588562 350.270966,453.645386 350.732666,448.140137 
	C351.214386,446.196655 351.716858,444.676514 351.732239,442.665588 
	C351.824951,442.021698 351.876404,441.767548 352.037750,441.143066 
	C354.498413,436.004425 355.154388,430.717621 356.862366,425.358795 
	C358.751953,421.450989 359.187988,417.250763 362.497772,414.097443 
	C367.806732,402.222839 374.173035,391.301208 381.243286,380.359772 
	C381.403442,379.818756 381.446106,379.620819 381.747711,379.264221 
	C386.990692,372.973785 391.974731,366.842010 397.075134,360.387787 
	C401.441315,355.930237 405.691132,351.795105 410.028503,347.328247 
	C411.138855,345.120636 412.710236,343.844147 414.916443,342.805084 
	C417.843414,340.715057 420.442108,338.722351 423.145416,336.397125 
	C425.959991,332.558929 429.634399,330.297180 433.765503,328.185181 
	C438.947968,325.477173 443.785187,322.763153 448.853149,319.826996 
	C450.035431,317.177429 452.270050,316.902374 454.750549,316.504822 
	C455.267059,316.481018 455.426025,316.392334 455.786072,316.118805 
	C458.868042,314.575745 461.748932,313.217468 464.847412,311.606232 
	C474.630646,307.763275 484.230255,304.280670 494.620697,302.007538 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M416.991058,637.644104 
	C423.852844,640.759094 430.722229,644.220581 437.749176,648.003845 
	C439.672821,650.364258 441.785675,651.797546 444.342468,652.803955 
	C447.337646,653.982971 448.829376,656.225830 447.431244,659.573547 
	C446.767273,661.163330 446.749207,662.786255 446.872284,664.473267 
	C447.084503,667.382202 446.079742,669.971619 443.317688,671.017334 
	C440.566071,672.059143 437.719177,671.652649 435.628784,669.136963 
	C434.911285,668.273560 434.026489,667.687378 432.901947,667.207275 
	C434.272095,668.030640 435.887878,668.621460 435.360901,670.551941 
	C434.865387,672.367126 433.379730,672.586182 431.415161,672.267334 
	C430.405731,672.028564 429.759674,671.790771 428.794067,671.443054 
	C426.572296,670.595215 424.627655,670.278259 422.257202,670.683716 
	C421.460602,670.557190 421.034637,670.384277 420.349121,669.953064 
	C415.783936,664.045227 408.209290,663.145935 403.097961,658.066772 
	C402.545502,657.531189 402.238647,657.221619 401.936035,656.907776 
	C401.916962,656.888000 401.769684,656.873718 401.506226,656.850281 
	C401.354706,656.836853 401.495789,656.860474 402.149139,657.168518 
	C402.545197,659.687561 401.176361,659.356812 399.470276,658.785889 
	C399.011932,658.463074 398.847870,658.308105 398.505432,657.868530 
	C396.229279,653.899414 391.808258,654.214172 388.410400,651.530701 
	C385.988220,647.313965 385.995880,647.334167 384.002228,650.082031 
	C382.951385,651.530396 382.192780,652.660156 380.196014,650.483459 
	C377.774109,647.843323 373.442200,648.248108 370.717926,645.276611 
	C369.168091,643.586121 371.400574,643.433228 371.015137,642.255249 
	C373.126556,641.886780 375.037750,642.112061 376.391388,643.981323 
	C377.106934,644.969482 377.947144,646.849182 379.351532,645.314209 
	C380.803375,643.727295 379.040466,642.781616 377.822601,641.899231 
	C376.888794,641.222595 375.901123,640.528442 375.205139,639.633728 
	C373.510651,637.455688 373.214905,635.406250 376.653809,634.050659 
	C379.259003,634.639893 381.387146,635.859131 384.196472,635.370667 
	C385.514771,635.497803 386.149261,636.367126 387.129730,636.578308 
	C386.221375,635.139465 385.343109,633.734253 383.489380,632.750427 
	C382.688629,631.958801 382.439056,631.306458 382.808350,630.255554 
	C385.616333,628.354919 387.828064,629.123108 388.654022,631.737976 
	C389.677734,634.978760 394.281769,636.920105 392.138885,641.320007 
	C391.881348,641.848877 392.868256,642.855652 393.249878,642.361328 
	C396.562012,638.071655 398.035187,643.061401 398.673126,644.069702 
	C400.903290,647.594666 404.618622,648.341980 407.824219,649.582336 
	C412.910248,651.550293 411.734253,647.332825 411.805054,644.148926 
	C416.148682,650.172913 423.561066,649.614197 428.900726,653.191589 
	C429.480377,653.579956 430.162323,653.842712 430.597565,653.110413 
	C430.805084,652.761230 430.824402,651.992798 430.585022,651.739990 
	C429.685883,650.790527 428.782776,649.642700 427.633698,649.166687 
	C422.395966,646.997070 419.489105,642.715393 416.991058,637.644104 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M305.104858,515.248657 
	C304.925354,514.994507 304.752106,514.557007 304.682739,514.332031 
	C306.749420,512.935059 306.182434,510.885315 305.830231,509.252014 
	C305.135803,506.031616 307.905487,503.350372 306.008667,499.495758 
	C304.308380,496.040649 308.184692,491.853485 310.793304,489.463379 
	C313.515045,486.969635 314.165771,484.694580 314.015747,481.539520 
	C313.966797,480.510193 314.238312,479.570374 315.703979,478.979187 
	C316.860443,478.441101 317.688995,478.577698 318.812561,478.587189 
	C320.399017,478.508545 321.497559,477.878937 322.972931,477.347351 
	C323.969513,477.152649 324.619720,477.234863 325.443909,477.834656 
	C326.040009,495.016052 327.250854,511.834198 331.849915,528.618896 
	C329.129913,528.239197 328.941010,524.653015 326.121735,523.936401 
	C325.709686,523.831726 325.117523,522.579529 325.302582,522.259155 
	C328.185364,517.269287 322.633606,512.774170 324.522430,507.892426 
	C324.947662,506.793365 324.223175,506.071655 323.800598,505.180328 
	C321.586395,500.509979 318.593811,499.765594 315.539429,503.813934 
	C314.203766,505.584259 312.366486,505.943024 310.585510,507.572784 
	C314.825775,509.147552 316.761688,511.910980 316.105042,516.466187 
	C315.633392,519.737915 320.169464,519.023987 321.051270,521.842163 
	C321.669495,523.817810 323.035187,525.032104 319.353149,524.166016 
	C316.021332,519.118958 314.442749,518.695496 311.373993,522.106506 
	C310.608124,520.317810 311.537720,517.612000 308.292542,517.037231 
	C307.313904,516.024292 306.106689,516.104431 305.104858,515.248657 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M322.353943,554.958923 
	C321.803619,554.737000 321.563843,554.457397 321.068939,554.066406 
	C320.040253,553.352051 319.900818,552.511108 319.970032,551.281494 
	C320.031616,549.127991 318.128845,548.795166 317.363464,547.550415 
	C314.436707,542.790588 314.532440,542.517090 320.592773,540.984375 
	C320.583191,545.402893 323.231934,549.184509 323.636200,553.446411 
	C323.707520,554.198364 323.372589,554.672974 322.353943,554.958923 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M384.463013,634.884216 
	C381.591705,638.769592 379.362640,637.192871 377.123688,634.263184 
	C377.032654,632.862122 376.532867,631.973022 375.393433,631.105225 
	C374.778534,628.377075 377.463104,629.347473 378.659668,628.075867 
	C380.134674,628.549194 381.287994,629.145081 382.744751,629.859863 
	C383.270874,630.580139 383.493561,631.181458 383.982483,631.976013 
	C384.836792,632.910278 384.916779,633.732239 384.463013,634.884216 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M320.207123,536.850098 
	C316.279999,535.022217 315.318146,531.879700 317.833313,529.240601 
	C319.619598,531.307129 323.325439,532.952271 320.207123,536.850098 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M378.748840,627.714233 
	C379.015991,630.416565 376.764832,629.713806 375.330078,630.692017 
	C372.899902,630.651917 370.896698,630.132141 371.086823,626.253357 
	C373.569855,629.245178 376.082306,627.321838 378.748840,627.714233 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M412.999847,635.502441 
	C413.583893,635.427917 414.176514,635.698547 414.968475,636.129578 
	C414.959290,636.948303 414.575317,637.698730 413.876038,637.450134 
	C413.450714,637.299011 413.288727,636.406799 412.999847,635.502441 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M408.625458,641.550171 
	C409.407715,641.329468 409.690308,641.603882 409.570740,642.210571 
	C409.545868,642.336853 409.367432,642.507202 409.240204,642.526184 
	C408.627319,642.617798 408.369904,642.319519 408.625458,641.550171 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M364.242859,623.687439 
	C363.521118,623.748596 362.738495,623.643677 362.549072,623.251831 
	C362.245941,622.624939 362.858521,622.247437 363.783051,622.075195 
	C364.369690,622.452942 364.483124,622.902649 364.242859,623.687439 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M364.397644,624.032227 
	C364.023010,623.551575 364.008545,623.120972 364.038757,622.370239 
	C364.530975,622.305664 364.978516,622.561157 365.663513,622.927124 
	C366.028198,623.176819 366.155457,623.316040 366.240479,623.735107 
	C365.718048,624.037354 365.237885,624.059753 364.397644,624.032227 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M329.427429,584.016846 
	C329.190796,583.873291 329.171295,583.687500 329.175598,583.222168 
	C329.569794,583.125366 329.940125,583.308228 330.310425,583.491089 
	C330.088470,583.652283 329.866547,583.813477 329.427429,584.016846 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M372.466187,636.451416 
	C372.520477,636.419006 372.411896,636.483887 372.466187,636.451416 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M322.909912,537.836426 
	C322.994385,538.009766 322.502411,538.001770 322.256653,538.011108 
	C322.179535,537.666748 322.331177,537.274231 322.909912,537.836426 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M370.890350,640.778687 
	C370.683990,640.889771 370.423584,640.703003 370.113953,640.214355 
	C370.317749,640.109741 370.574951,640.299194 370.890350,640.778687 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M369.526672,633.546875 
	C369.474915,633.576660 369.578461,633.517029 369.526672,633.546875 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M367.532745,632.544067 
	C367.479675,632.574951 367.585815,632.513245 367.532745,632.544067 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M367.440674,625.541626 
	C367.399353,625.476074 367.482025,625.607239 367.440674,625.541626 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M366.314453,623.883118 
	C366.136749,623.855652 366.075348,623.696350 365.957428,623.287354 
	C366.077545,623.275574 366.254120,623.513428 366.314453,623.883118 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M316.833221,525.577209 
	C315.955841,526.693420 315.216156,527.487183 314.361359,528.404480 
	C313.625824,526.294922 313.517395,524.423950 315.427521,523.471313 
	C316.347168,523.012634 317.058746,524.106567 316.833221,525.577209 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M319.669495,550.991760 
	C320.510803,551.657288 321.251099,552.269714 320.843689,553.625488 
	C320.062531,554.510132 319.329712,555.223328 318.532745,553.989624 
	C317.692200,552.688477 317.770203,551.665588 319.669495,550.991760 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M304.701477,515.118103 
	C306.070831,515.045410 307.404663,514.670288 307.891937,516.642822 
	C307.156403,517.001770 306.385071,517.008423 305.224670,517.017212 
	C304.212616,516.528259 304.096893,515.934937 304.701477,515.118103 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M309.674988,542.484375 
	C311.241241,542.228394 310.927460,543.277954 310.960938,543.981323 
	C310.984741,544.481201 310.500092,544.933594 310.074585,544.440979 
	C309.697296,544.004211 309.635681,543.294861 309.674988,542.484375 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M309.899536,564.098877 
	C309.818054,564.680969 309.703217,564.977051 309.588379,565.273193 
	C308.879761,564.904175 308.980621,564.303406 309.164795,563.702148 
	C309.197266,563.596130 309.419708,563.452759 309.505371,563.480164 
	C309.646667,563.525269 309.748016,563.695496 309.899536,564.098877 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M308.331787,561.340271 
	C308.611115,561.294189 308.830048,561.472900 308.799408,561.544189 
	C308.587158,562.037720 308.349731,562.018250 308.331787,561.340271 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M413.212341,689.070068 
	C409.286316,686.058289 404.682739,685.261658 400.311523,683.078125 
	C394.850159,679.473633 389.016418,677.146057 384.220093,673.136597 
	C383.358765,672.416443 382.049713,672.101562 382.952057,670.277588 
	C383.364441,669.531799 383.665466,669.070496 383.994141,668.322754 
	C383.864655,667.853821 383.707550,667.671509 383.825745,667.255737 
	C384.101044,667.022217 384.527496,667.013000 384.740662,667.006287 
	C389.555267,669.525940 393.698517,672.910522 399.184937,674.368835 
	C399.755615,674.318298 399.986572,674.314453 400.560059,674.374268 
	C401.114868,674.533569 401.327118,674.629150 401.821472,674.927856 
	C402.268768,675.291199 402.433929,675.451355 402.817078,675.875122 
	C404.929260,679.173950 408.245728,680.115784 411.462891,681.789795 
	C416.345093,684.326172 421.455597,685.333435 426.444275,687.537598 
	C426.940582,687.695618 427.136627,687.756470 427.624146,687.885620 
	C428.518005,688.030151 429.097717,687.951355 429.942902,687.560181 
	C431.946808,686.748291 433.529449,687.017273 434.881622,688.228699 
	C437.286407,690.383423 440.212341,691.585327 443.496857,693.040466 
	C442.078186,689.069031 444.775024,687.764221 447.605286,686.520142 
	C443.910706,687.402954 440.779419,687.382874 439.203339,682.885498 
	C439.194458,682.270813 439.226501,682.022705 439.405151,681.424194 
	C439.723328,680.885071 439.894836,680.696350 440.397583,680.313049 
	C443.573608,679.643372 446.362396,678.657288 449.287689,678.904419 
	C451.306213,679.075134 453.001251,679.957275 454.020020,682.236511 
	C454.363129,682.803894 454.480225,683.317322 454.851959,683.256104 
	C455.112152,683.213318 454.992554,682.881409 454.501587,682.528625 
	C454.562622,680.948242 455.926239,679.345886 453.157043,678.980896 
	C451.654388,678.782898 450.360413,677.615723 450.613098,675.833618 
	C450.834015,674.275696 452.294159,673.978760 453.601532,673.654175 
	C457.283539,672.739807 460.893005,671.607300 464.815399,672.604065 
	C466.513489,673.035522 468.420319,672.888123 470.146454,672.147461 
	C468.760620,666.120789 469.571136,665.208740 477.368835,664.272583 
	C478.186951,664.143066 478.660034,664.144043 479.479553,664.285767 
	C481.094452,665.158325 482.434814,665.330627 484.237549,664.761841 
	C494.583984,664.771423 504.517548,664.461487 514.750610,662.577026 
	C519.375549,662.639038 523.349121,661.277222 527.767761,660.376343 
	C530.128967,659.698425 532.246155,659.556702 534.510071,658.633301 
	C535.029907,658.453857 535.234619,658.379883 535.748169,658.215820 
	C537.237671,657.807556 538.506897,657.784424 539.830444,656.912842 
	C540.271362,656.655884 540.451294,656.558716 540.911743,656.336914 
	C541.763672,655.994507 542.351807,655.825439 543.212280,655.509583 
	C543.689087,655.332886 543.880920,655.265625 544.364014,655.110840 
	C545.607239,654.742798 546.627563,654.673950 547.741760,653.950439 
	C548.171387,653.697998 548.346802,653.603638 548.793884,653.387573 
	C549.433411,653.126953 549.803650,652.994995 550.445801,652.756653 
	C550.891541,652.558167 551.067932,652.472900 551.504761,652.242432 
	C551.940979,651.996216 552.116638,651.895142 552.571777,651.657593 
	C553.054565,651.462463 553.257935,651.403870 553.783203,651.327393 
	C554.544678,651.367920 554.957092,651.508850 555.613647,651.925476 
	C557.964355,654.114990 557.393311,655.933960 555.400513,657.474670 
	C553.405273,659.017334 552.154663,661.122437 550.509094,663.386536 
	C548.097473,665.504028 546.667053,668.324463 543.142212,668.930603 
	C534.293640,667.566711 525.836548,667.041687 517.500488,669.809570 
	C516.282043,670.214111 514.090698,670.490845 516.909790,672.472717 
	C517.002136,673.320679 516.949341,673.800720 516.655762,674.589905 
	C515.427856,676.067932 514.109741,675.929749 512.791504,675.561096 
	C510.298859,674.864197 509.369598,675.314941 510.210144,678.186646 
	C511.053131,681.066650 506.811310,681.124268 506.452026,683.911011 
	C503.231750,684.415161 500.464325,685.560364 497.600922,686.323608 
	C496.783447,686.541504 495.949341,686.731812 495.940125,687.798584 
	C495.935730,688.304688 496.155884,688.868896 496.611908,689.052795 
	C497.778137,689.522949 497.915924,688.475464 498.217621,687.784973 
	C499.965942,683.783447 504.529327,685.673523 507.402344,683.265503 
	C511.323517,681.145081 515.388123,681.288391 519.316467,680.793884 
	C520.627686,680.628845 522.237000,680.796387 522.876831,682.094360 
	C523.660400,683.684143 522.299744,684.878418 521.400024,686.039429 
	C518.636414,689.605408 513.749756,688.690796 510.439484,691.181213 
	C509.580719,691.827271 508.091064,691.412476 507.276733,692.901367 
	C510.037109,694.829712 512.928284,695.409424 516.108521,694.137695 
	C518.591614,693.144836 520.831970,692.855774 522.909973,695.160217 
	C525.003235,697.481628 527.636169,693.712708 530.163574,695.787842 
	C532.205811,701.322571 527.255920,701.928772 524.416382,704.462402 
	C518.389709,707.105835 512.548706,708.977295 505.936096,709.473389 
	C504.728119,709.502441 503.959473,709.715271 502.807739,710.065796 
	C496.018066,710.617981 489.766022,712.382812 482.955109,712.247559 
	C481.593689,712.284424 481.389709,710.888367 480.178223,711.183960 
	C479.261902,711.758606 478.495087,711.699463 477.434265,711.615112 
	C476.738159,711.584106 476.335999,711.577087 475.622009,711.544373 
	C475.107666,711.463867 474.905151,711.408569 474.420197,711.213013 
	C473.429840,710.652710 472.820770,710.082642 471.791504,709.646545 
	C471.108459,709.593689 470.718689,709.656799 470.047485,709.869507 
	C469.166687,710.233093 468.562897,710.456360 467.597992,710.677124 
	C466.771942,710.710388 466.307251,710.607239 465.538086,710.235718 
	C463.185059,707.673035 461.117523,705.324951 462.296387,701.681274 
	C468.897919,702.279114 475.092346,703.225220 481.790070,703.509949 
	C490.157684,703.746887 498.082306,703.336548 504.829132,702.527161 
	C498.500977,703.145691 491.001526,703.814392 483.064331,703.490234 
	C475.264374,703.434937 468.028534,701.878967 460.278931,701.431946 
	C450.424561,698.975525 440.613647,698.210449 430.931488,695.072388 
	C428.997925,694.092163 427.110291,694.700256 425.217133,693.681763 
	C423.813385,693.245300 422.741119,692.820435 421.290466,693.402039 
	C420.763184,693.478943 420.547821,693.454651 420.059143,693.259094 
	C419.301636,691.446045 417.891876,690.937195 416.256104,690.205872 
	C416.010590,690.007263 415.645203,689.955078 415.461243,689.969604 
	C414.707581,689.639648 414.061523,689.503601 413.212341,689.070068 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M439.808899,681.746948 
	C440.991333,686.182312 443.492584,686.753967 447.043274,684.999756 
	C448.170593,684.442810 449.724182,683.301819 450.532288,684.843994 
	C451.552216,686.790344 449.435822,687.336731 448.203308,687.853149 
	C444.843445,689.261169 441.494965,690.487122 447.551941,694.685608 
	C441.845917,694.523560 438.071198,692.416870 434.649689,689.825562 
	C433.268188,688.779236 432.373596,687.474670 430.248291,688.711304 
	C429.621918,689.359314 429.154877,689.510620 428.293396,689.198914 
	C427.976837,688.976135 427.993622,689.005432 428.010223,689.008606 
	C426.584106,687.532837 425.234497,685.947144 423.093872,684.738525 
	C422.043304,683.679626 421.239288,682.895630 419.587433,683.156372 
	C416.485443,683.300049 414.003693,682.328613 411.199982,681.153931 
	C408.142395,679.399780 405.099182,678.380554 402.268188,676.230774 
	C402.000336,675.982178 401.996338,676.002441 402.006470,676.003296 
	C401.789154,675.765320 401.550323,675.538513 401.122711,675.151611 
	C400.945282,674.979614 400.547577,674.982422 400.348755,674.986877 
	C395.713928,672.131226 391.847076,668.749512 389.924133,663.364868 
	C390.048431,663.029907 390.010437,663.019592 390.026978,663.017578 
	C390.638824,661.989014 389.743347,661.336426 389.212097,660.260986 
	C389.259369,659.491760 389.516876,659.001282 389.883514,658.255371 
	C390.702332,656.719177 391.905701,656.268555 393.646210,656.045044 
	C395.606079,656.189514 397.118683,656.518982 398.708893,657.725098 
	C398.979614,658.002625 398.994720,658.005249 398.997253,658.012634 
	C406.272156,661.338440 413.130432,665.495850 420.745422,668.893860 
	C421.131866,669.437134 421.331573,669.656128 421.800476,669.908447 
	C422.588165,670.559692 423.591797,670.246887 424.184509,671.279297 
	C424.545013,671.871033 423.591370,672.049927 424.501526,672.411194 
	C425.006104,672.488159 425.203827,672.556274 425.681641,672.773254 
	C427.128662,673.809631 428.412903,673.564697 430.040039,673.269043 
	C430.847137,673.235413 431.297913,673.321899 432.022888,673.673462 
	C433.855103,675.649109 432.186005,678.081299 433.514008,679.993958 
	C435.238617,679.263123 434.530426,677.339233 435.707001,676.034424 
	C439.592834,675.248718 439.114044,678.692444 440.208557,680.706543 
	C439.995117,681.000000 440.000000,681.000000 440.000000,680.997559 
	C439.872620,681.162170 439.745239,681.329285 439.808899,681.746948 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M389.726624,663.047363 
	C393.364960,666.787598 396.681458,670.545288 400.073914,674.647156 
	C399.165070,675.283752 398.750702,677.095276 397.287079,676.096924 
	C393.201782,673.310364 389.126617,670.509033 385.000641,667.356567 
	C386.419861,667.385010 389.175690,669.936768 387.707977,665.376526 
	C387.191925,663.773132 388.213043,663.374817 389.726624,663.047363 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M394.156982,656.649048 
	C392.905701,657.303406 391.795868,657.615662 390.339355,657.963989 
	C388.507935,656.166687 386.647217,660.973633 385.308350,657.769409 
	C384.178589,655.065613 385.827393,653.187256 387.744751,651.185181 
	C388.000061,651.000000 387.997955,650.997559 387.994446,650.994385 
	C389.338684,653.658752 394.167786,652.195435 394.156982,656.649048 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M367.097290,661.583496 
	C363.132141,663.434998 361.466370,662.205078 360.941589,656.821899 
	C363.003296,658.078979 364.973816,659.678284 367.097290,661.583496 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M383.033875,664.516235 
	C380.978394,663.751648 380.618622,662.528076 381.796753,661.007568 
	C382.036377,660.698364 382.851776,660.406616 383.046356,660.559448 
	C384.493286,661.695496 384.179962,663.068604 383.033875,664.516235 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M359.065430,654.715210 
	C358.317963,655.000000 357.487183,656.312866 356.577545,655.312805 
	C356.322235,655.032043 356.725250,654.152466 356.917023,653.252808 
	C357.636322,653.457947 358.264893,653.957947 359.065430,654.715210 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M389.024445,660.306519 
	C390.017151,660.601929 391.529877,660.933716 390.307556,662.855469 
	C389.711365,662.220642 389.379181,661.425659 389.024445,660.306519 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M372.078491,667.876587 
	C371.862030,668.140320 371.686737,668.112427 371.251434,668.026917 
	C371.119171,667.556152 371.246918,667.143005 371.374664,666.729797 
	C371.622986,667.014832 371.871338,667.299927 372.078491,667.876587 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M383.836761,666.997925 
	C384.086731,667.190979 384.072388,667.359802 384.039917,667.782349 
	C383.745758,667.851074 383.469727,667.665955 383.118103,667.213196 
	C383.219116,666.954895 383.395813,666.964294 383.836761,666.997925 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M382.970459,667.020630 
	C382.841156,666.885559 382.737946,666.727661 382.634766,666.569702 
	C382.725128,666.610901 382.815491,666.652039 382.974152,666.819397 
	C383.042450,666.945557 382.996582,666.997681 382.970459,667.020630 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M272.317627,517.763367 
	C271.813721,517.979431 271.548340,517.959656 270.885315,517.905273 
	C269.669586,517.240540 268.958527,516.517151 268.335205,515.318481 
	C265.396790,514.692749 266.141541,512.520386 266.001160,510.404602 
	C265.565765,510.001587 265.127228,509.997620 264.360657,509.980713 
	C265.315491,492.830383 263.941620,475.478943 268.080536,458.575836 
	C268.080536,463.706299 268.080536,468.836761 268.080536,474.936981 
	C269.371185,472.843292 268.832458,470.867889 270.925964,469.662933 
	C271.377502,477.087280 271.487274,484.153473 270.120056,491.621887 
	C271.276825,495.760712 271.003235,499.595337 270.974243,503.406921 
	C270.937927,508.179108 270.680481,512.971436 272.317627,517.763367 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M268.020172,515.356934 
	C269.950653,514.711792 270.449493,515.761475 270.525391,517.551758 
	C270.268341,518.009338 270.106293,518.197388 269.983032,518.622498 
	C268.164093,518.493103 265.848145,518.520020 268.020172,515.356934 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M271.080322,465.505493 
	C271.153748,464.828735 271.273651,464.407257 271.393555,463.985748 
	C272.331421,464.428650 271.919769,465.168396 271.780670,465.833435 
	C271.759125,465.936249 271.547272,466.088104 271.459320,466.067078 
	C271.331390,466.036560 271.236084,465.869507 271.080322,465.505493 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M276.017426,459.018066 
	C275.895416,459.446350 275.756989,459.858887 275.618530,460.271423 
	C274.972626,459.952454 275.287659,459.585632 275.765472,459.097809 
	C275.964722,458.974976 276.000977,459.002380 276.017426,459.018066 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M272.527710,460.546936 
	C272.473450,460.579437 272.582001,460.514435 272.527710,460.546936 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M400.002014,690.657715 
	C398.296692,691.575195 399.412720,689.925049 398.884827,689.256531 
	C399.055481,688.582336 399.250092,688.191284 399.444702,687.800171 
	C400.453888,688.469971 399.922852,689.455383 400.002014,690.657715 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M274.473389,497.458435 
	C274.520355,497.431732 274.426392,497.485107 274.473389,497.458435 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M363.063721,691.150635 
	C359.938080,691.554260 355.390533,686.371826 356.424286,683.994568 
	C356.721802,683.310364 357.281281,682.710083 358.309692,682.688293 
	C361.109711,682.628784 362.790497,684.249878 364.423370,686.222534 
	C366.285309,688.471985 363.763428,689.317749 363.063721,691.150635 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M285.990051,487.266266 
	C286.044861,487.894653 285.945190,487.924011 285.990051,487.266266 
z"
    />
    <path
      fill="#2D4B5D"
      opacity="1.000000"
      stroke="none"
      d="
M347.006104,685.006653 
	C347.258545,684.504883 347.484711,683.983826 347.781616,683.506897 
	C347.812225,683.457642 348.445129,683.617676 348.490387,683.771301 
	C348.713623,684.529541 348.177246,684.778320 347.275696,684.955566 
	C346.986664,684.987732 347.000000,685.000000 347.006104,685.006653 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M342.247559,643.169067 
	C341.950317,641.392456 342.952637,640.949158 344.708801,640.947876 
	C345.496765,641.430298 345.916473,641.909546 346.453247,642.688416 
	C345.257324,643.584717 343.996796,644.817261 342.247559,643.169067 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M278.909607,478.839783 
	C278.834137,479.000916 278.667084,479.001526 278.249390,479.001678 
	C278.271851,478.893890 278.544922,478.786591 278.909607,478.839783 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M306.132080,591.946167 
	C306.088074,591.964111 306.176117,591.928162 306.132080,591.946167 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M358.689819,744.346069 
	C358.646637,743.399231 358.820496,742.750916 358.835999,742.098816 
	C358.895508,739.591919 357.446350,736.355042 360.912384,735.237122 
	C364.712585,734.011414 367.763794,736.405029 370.621674,738.550354 
	C371.321533,739.075684 371.713562,740.114258 371.253540,741.238770 
	C370.080627,744.105835 364.340851,746.812134 360.552246,746.013184 
	C359.756226,745.536011 359.295166,745.089417 358.689819,744.346069 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M359.997711,727.996704 
	C359.995422,727.993347 359.732330,728.399658 359.732330,728.399658 
	C359.732330,728.399658 359.772675,728.103638 359.889832,728.054382 
	C360.006989,728.005127 360.000000,728.000000 359.997711,727.996704 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M254.033966,450.695679 
	C254.073303,454.899536 253.901489,458.726227 253.016373,462.922729 
	C253.139633,463.578918 253.222961,463.797516 253.264191,464.363647 
	C252.552551,466.108459 252.479507,467.527374 252.731064,469.070679 
	C252.972458,470.551605 253.011795,472.178467 250.543152,472.241577 
	C249.944656,470.291443 249.644226,468.515472 249.232178,466.373291 
	C249.508301,460.991699 249.896057,455.976349 250.299423,450.518005 
	C250.510986,449.571777 250.706924,449.068512 250.970856,448.296631 
	C253.823593,446.475067 254.471802,447.716156 254.033966,450.695679 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M250.177856,472.437622 
	C252.021591,471.680359 252.036911,470.512634 251.550323,469.122589 
	C250.928543,467.346344 250.838348,465.655884 252.683243,464.104919 
	C254.738907,467.342834 253.713486,470.949921 253.941193,474.816833 
	C253.840256,476.358856 252.905075,476.928589 252.092133,477.951172 
	C250.282761,476.782562 250.332260,474.749115 250.177856,472.437622 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M254.218033,475.041443 
	C252.700394,471.785461 252.427460,468.295776 253.012695,464.360840 
	C253.026169,463.987823 253.152496,463.679352 253.104385,463.519806 
	C253.522278,463.053436 253.988281,462.746643 254.757187,462.732788 
	C255.213821,464.331757 255.367554,465.637817 255.469437,467.338898 
	C253.581604,469.929077 256.800018,472.743103 254.218033,475.041443 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M271.753326,602.104858 
	C272.022522,602.252136 272.052673,602.490173 272.072540,603.092468 
	C271.879608,603.036377 271.696960,602.616028 271.753326,602.104858 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M443.271912,742.276611 
	C458.337067,743.766174 473.731689,742.870605 489.575439,743.014038 
	C493.544281,745.675171 497.411591,742.795410 501.347076,743.781311 
	C501.887726,743.807495 502.102509,743.818359 502.634888,743.826904 
	C505.241119,743.296082 507.053436,741.482361 509.749176,741.182556 
	C509.992523,740.969238 510.004150,740.997681 509.992157,740.989136 
	C511.370178,740.066467 512.956665,740.750854 514.716919,740.198364 
	C515.013672,739.961914 515.004639,740.010132 514.982178,740.000244 
	C515.508423,739.498474 516.185669,739.516541 517.191528,739.510803 
	C518.190918,739.506470 518.857605,739.529297 519.765808,739.175903 
	C520.016602,738.958557 520.007629,739.012329 519.981445,739.004639 
	C521.032898,738.131409 522.375366,738.748840 523.782715,738.078125 
	C534.772522,734.403015 545.253784,730.304382 555.647766,725.969604 
	C558.953613,724.590942 562.264099,723.240662 564.821655,720.325073 
	C565.382874,720.592590 565.943787,721.128845 566.753540,721.834961 
	C567.631226,722.517151 567.884155,723.214417 567.973694,724.357483 
	C566.994873,728.638550 563.690125,729.683411 560.454346,730.243591 
	C553.694336,731.414062 548.853455,736.462402 542.622681,738.649048 
	C541.756409,738.953064 541.043030,739.873901 541.549805,741.381470 
	C544.922729,741.245605 548.135376,740.281982 551.391113,739.182861 
	C554.407959,738.164429 555.873169,739.262268 554.290222,742.809937 
	C554.012085,743.018799 554.000000,743.000000 554.006226,743.007568 
	C553.569458,743.422058 552.984802,743.474792 552.222961,743.819763 
	C543.375671,747.814453 534.478516,750.816956 525.285583,752.932373 
	C524.967102,753.005615 524.566223,753.093445 524.300354,752.968689 
	C516.241760,749.187805 510.311798,755.717529 503.377441,757.320007 
	C495.440887,759.154236 486.877686,761.838806 479.814331,757.441162 
	C475.881653,754.992676 471.862488,754.598938 467.390320,752.879272 
	C469.911865,750.758484 472.285400,749.583069 474.969604,749.050293 
	C476.258881,748.794250 477.833496,749.002380 477.832642,747.085999 
	C477.831665,744.849121 476.075562,745.049133 474.602356,745.023926 
	C467.159058,744.896790 459.689148,745.465515 452.299866,744.019714 
	C449.396698,743.451599 446.463409,743.037415 443.271912,742.276611 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M567.209534,725.228271 
	C567.007446,724.261292 567.003601,723.509094 567.001160,722.380859 
	C566.998657,721.549805 566.994873,721.094666 567.006897,720.298584 
	C567.465515,719.549500 568.062927,719.526672 568.805481,719.141724 
	C569.020935,718.954712 569.010315,719.016663 568.979248,719.011719 
	C569.385132,718.633545 569.920959,718.520142 570.668579,718.244202 
	C571.231750,717.583557 571.591614,717.107605 571.979370,716.318237 
	C575.609436,713.078918 579.449036,710.572693 584.329956,709.265137 
	C585.461731,709.439697 586.132263,709.775452 586.989380,710.533386 
	C587.299744,711.064209 587.358582,711.310181 587.186401,711.857178 
	C586.568115,714.712158 588.403992,717.051208 587.517700,719.752014 
	C587.154419,720.859131 588.725342,720.921875 589.881592,721.393188 
	C585.652649,724.424866 581.821899,727.794006 579.261658,732.437012 
	C578.193970,734.373291 577.428040,732.586731 576.265381,732.127930 
	C575.466614,732.549438 574.915649,733.071533 574.182617,733.813965 
	C573.256592,734.359436 572.528259,734.700317 571.498657,735.075928 
	C569.464905,734.881409 567.999390,733.949036 569.214111,732.264465 
	C570.687012,730.221924 569.344482,728.545349 569.365601,726.413330 
	C569.495361,725.824768 569.643677,725.585205 569.863953,725.402710 
	C570.103149,725.204407 570.012634,725.223694 569.329529,725.576843 
	C568.485779,725.824036 567.928833,725.749512 567.209534,725.228271 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M569.626282,724.998291 
	C571.101746,727.899902 572.830994,730.815247 569.788208,733.613220 
	C570.134583,733.949646 570.480957,734.286072 571.012329,734.866638 
	C569.501343,737.678223 566.880066,738.540100 564.034973,738.915100 
	C563.185547,739.027161 562.201904,738.752625 562.197083,737.733521 
	C562.188538,735.934448 563.277161,737.564331 563.787476,737.327087 
	C564.641663,736.930054 564.959351,736.193420 564.809509,735.304138 
	C564.669617,734.473267 563.887756,734.151245 563.344604,734.509888 
	C560.988037,736.066101 555.783813,734.818909 557.529541,740.535889 
	C558.148987,742.564453 556.256287,742.835205 554.369629,743.012268 
	C554.788330,740.536255 554.546143,738.407654 551.484741,740.436829 
	C548.238647,742.588379 544.355957,741.587097 541.156921,743.381409 
	C540.413147,743.798645 539.133911,743.709595 539.320007,742.672485 
	C539.671631,740.714294 539.112061,737.844849 541.599670,737.191223 
	C549.077515,735.226501 554.647583,728.899780 562.639343,728.208679 
	C564.377014,728.058411 565.706238,727.068115 566.847900,725.320068 
	C567.758362,725.007874 568.505493,725.002197 569.626282,724.998291 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M556.026123,755.461914 
	C555.414978,755.787720 555.009155,755.866760 554.293335,755.969238 
	C551.956360,754.972107 552.570251,754.233948 553.904907,752.856079 
	C555.312439,751.402771 556.549622,749.870483 558.757202,749.022400 
	C559.220398,748.862427 559.383606,748.741333 559.784302,748.811401 
	C560.325439,749.102783 560.575623,749.278870 560.884033,749.734436 
	C561.393555,750.331787 561.542786,750.804199 561.223694,751.679077 
	C558.784729,752.140320 557.699524,753.962097 556.026123,755.461914 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M570.001221,743.999023 
	C568.932190,742.546082 567.350342,745.523560 566.378296,743.596619 
	C565.934143,742.716187 566.569641,742.061707 567.109924,741.452820 
	C568.293152,740.119202 569.496521,739.793335 570.851135,741.716980 
	C570.995911,742.005737 571.000671,741.998657 571.003174,741.994751 
	C570.540283,742.404602 570.504333,743.000305 570.172363,743.774109 
	C569.997681,744.003540 570.000000,744.000000 570.001221,743.999023 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M592.301392,721.052856 
	C591.888916,719.723022 591.763428,718.296814 593.462402,717.950500 
	C594.720398,717.694092 594.736328,718.471802 594.156433,719.695374 
	C593.587402,720.371765 593.096252,720.717285 592.301392,721.052856 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M561.343506,751.943542 
	C560.999268,751.544617 560.994690,751.086304 560.992859,750.282959 
	C561.494141,749.692627 561.992676,749.447144 562.755981,749.111450 
	C562.938171,750.145264 563.156860,751.409973 561.343506,751.943542 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M564.002808,749.012085 
	C563.826538,748.294556 563.647400,747.562561 563.468262,746.830566 
	C563.736328,746.885315 564.088867,746.851440 564.256653,747.011353 
	C564.767700,747.498291 564.951965,748.084595 564.206543,748.862732 
	C563.974915,749.013611 564.000000,748.997559 564.002808,749.012085 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M574.305054,733.970825 
	C574.083313,733.122375 574.324768,732.327881 575.671997,732.046631 
	C575.902832,732.901978 575.664001,733.696472 574.305054,733.970825 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M590.388489,721.714355 
	C590.116943,721.701538 590.265259,721.439087 590.335693,721.305664 
	C590.769043,721.126038 591.132080,721.079834 591.771301,721.012390 
	C591.585022,721.236450 591.122498,721.481812 590.388489,721.714355 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M575.707031,740.911011 
	C575.569519,741.290955 575.107239,741.615906 574.351440,741.963806 
	C574.092163,741.133057 574.746155,741.005066 575.707031,740.911011 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M552.295898,743.973450 
	C552.472656,743.682129 552.945251,743.366638 553.715088,743.033203 
	C553.538818,743.326538 553.065308,743.637939 552.295898,743.973450 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M577.694092,739.940430 
	C577.542725,740.293396 577.052185,740.620483 576.258911,740.980225 
	C575.990295,740.096497 576.687988,740.025085 577.694092,739.940430 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M563.396545,743.692322 
	C563.570862,743.825928 563.598999,744.058105 563.627197,744.290222 
	C563.501587,744.123779 563.375977,743.957336 563.396545,743.692322 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M587.999573,731.998779 
	C588.335205,731.756042 588.671265,731.514526 589.007324,731.272949 
	C589.090271,731.391418 589.173218,731.509888 589.256226,731.628296 
	C588.992371,731.742615 588.728455,731.856873 588.233521,731.986816 
	C588.002441,732.002441 588.000000,732.000000 587.999573,731.998779 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M580.688965,737.974243 
	C580.952209,738.881348 580.310547,739.016357 579.286865,739.044678 
	C579.427246,738.649536 579.901428,738.299988 580.688965,737.974243 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M578.703491,738.993469 
	C578.856079,739.279053 578.707031,739.603394 578.257996,739.971436 
	C578.105957,739.687500 578.253906,739.359863 578.703491,738.993469 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M444.999390,760.000000 
	C445.113647,760.133667 445.228516,760.267395 445.343384,760.401062 
	C445.263672,760.354126 445.183990,760.307190 445.052429,760.130127 
	C445.000610,760.000000 445.000000,760.000000 444.999390,760.000000 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M553.973938,757.270264 
	C554.298218,757.203064 554.861084,757.177124 554.177246,757.790161 
	C553.983276,757.806152 553.980591,757.448853 553.973938,757.270264 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M497.546631,766.471680 
	C497.576721,766.522949 497.516541,766.420471 497.546631,766.471680 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M494.857666,768.811890 
	C494.688934,768.863281 494.403809,768.680359 493.987671,768.273926 
	C494.151520,768.226074 494.446381,768.401733 494.857666,768.811890 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M495.775879,769.022583 
	C495.749725,769.146484 495.449280,769.266357 495.051178,769.214966 
	C495.136230,769.035278 495.318909,769.026917 495.775879,769.022583 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M559.928467,748.850220 
	C560.021851,749.002441 559.538757,748.997131 559.297974,748.990295 
	C559.316467,748.888306 559.575745,748.793152 559.928467,748.850220 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M627.544067,724.549927 
	C627.490417,724.582764 627.597656,724.517029 627.544067,724.549927 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M661.570679,697.577515 
	C661.114258,697.698242 660.773865,697.700562 659.933594,697.821899 
	C657.380127,696.918274 653.664185,697.622375 653.275146,695.681396 
	C652.810974,693.366150 655.348999,691.165894 657.574585,689.709045 
	C661.728577,686.989807 658.466980,685.166748 656.354370,682.653320 
	C658.082581,682.681946 658.344482,679.623413 660.793884,681.865967 
	C663.760620,684.826965 662.380615,688.079895 662.013916,691.632324 
	C662.412537,693.300415 662.252441,694.556274 661.976807,696.128052 
	C661.876648,696.783142 661.781677,697.121155 661.570679,697.577515 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M662.257324,696.232239 
	C660.571533,695.284302 661.034485,693.908569 661.770752,692.284485 
	C667.904968,692.617554 662.997070,694.610229 662.257324,696.232239 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M663.487549,662.060791 
	C664.070251,663.286377 664.050598,664.450012 662.191406,664.745850 
	C661.323486,663.349976 661.822205,662.543152 663.487549,662.060791 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M667.030518,658.028564 
	C666.876160,658.599670 666.702332,659.153259 666.069641,659.599731 
	C665.477722,658.776733 665.677917,658.203552 666.648499,657.899841 
	C666.970703,657.977295 667.010986,658.011047 667.030518,658.028564 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M662.068115,665.062927 
	C661.918335,665.634644 661.724670,666.168640 661.306519,666.885986 
	C661.082092,667.069458 660.985596,666.980347 660.938599,666.934509 
	C660.401062,666.152344 660.554565,665.516663 661.563843,664.954346 
	C661.934387,664.952087 662.024170,665.025146 662.068115,665.062927 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M666.002441,660.004395 
	C665.863525,660.317078 665.710999,660.616699 665.194824,660.707031 
	C664.973022,660.310669 665.114990,660.123657 665.608887,659.951904 
	C665.960754,659.967163 665.988770,659.991272 666.002441,660.004395 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M665.041016,661.093140 
	C664.996521,661.456116 664.827881,661.716003 664.388550,662.051331 
	C664.188171,662.172424 664.052002,662.046936 663.983887,661.984253 
	C663.806763,661.509583 663.920959,661.173584 664.552673,660.939087 
	C664.846802,660.964661 664.987244,661.035645 665.041016,661.093140 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M570.250122,743.843018 
	C570.164734,743.441162 570.331787,742.878723 570.752319,742.153625 
	C570.838013,742.554749 570.670288,743.118652 570.250122,743.843018 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M615.066284,705.657959 
	C615.712891,706.589783 618.065857,704.681519 617.147705,707.743408 
	C613.533081,708.523926 610.061951,709.001404 607.743286,712.130005 
	C606.154358,714.273865 604.318115,714.278259 602.203247,712.761047 
	C601.858704,713.083679 601.698425,713.190186 601.276489,713.174988 
	C601.014832,713.053345 600.966431,713.052734 600.955811,713.030945 
	C598.317688,711.278320 595.493713,711.250244 592.236084,712.055420 
	C591.529419,712.061035 591.161682,712.054871 590.518066,712.051208 
	C589.543335,712.012451 588.867981,711.884521 587.982788,711.296204 
	C587.314209,710.485168 586.846558,709.937195 586.583862,708.883545 
	C587.272827,705.491211 589.789185,704.019409 592.026001,702.351624 
	C597.734863,698.095032 603.951599,694.525940 609.187500,689.625183 
	C610.044922,688.822571 611.086914,688.282471 612.673584,688.567627 
	C613.627930,689.446411 613.302551,691.635193 615.411682,690.094421 
	C616.834900,689.054749 617.988220,687.463318 620.323792,687.448853 
	C622.027466,688.334412 621.919617,690.208374 623.354370,691.394470 
	C623.561035,691.903992 623.607178,692.122864 623.644287,692.679321 
	C623.554749,693.465454 623.437622,693.904419 623.166687,694.658630 
	C621.243713,697.157349 620.353760,699.648560 620.201172,702.732178 
	C619.961853,702.985535 619.999939,703.005798 619.998230,702.984375 
	C619.996521,702.962891 619.672974,703.192505 619.476929,703.216858 
	C619.280823,703.241211 618.928772,703.417786 619.128540,703.412476 
	C619.328369,703.407104 619.054077,703.698914 618.926147,703.853027 
	C617.454224,704.101257 615.878418,703.546143 615.066284,705.657959 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M591.928467,712.416077 
	C594.966858,708.891846 597.910034,709.176941 600.845642,712.699951 
	C599.216431,713.348511 597.487549,713.687927 595.385803,714.008667 
	C593.112915,715.780640 592.283569,714.902893 591.928467,712.416077 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M587.621948,710.576477 
	C588.589478,710.846191 589.265259,711.115051 590.091675,711.718811 
	C589.406860,712.939148 588.473389,712.629028 587.245850,712.216919 
	C586.926697,711.963562 586.898010,711.768982 586.892273,711.280151 
	C587.053528,710.849243 587.191833,710.712402 587.621948,710.576477 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M608.721802,719.926392 
	C608.561890,720.357178 608.082642,720.733704 607.282349,721.066650 
	C607.441650,720.639343 607.922058,720.255737 608.721802,719.926392 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M606.314819,721.974182 
	C606.009766,721.978088 605.913696,721.491089 605.950195,721.245728 
	C606.160400,720.986023 606.334106,720.971802 606.769043,720.964966 
	C606.893494,721.304932 606.756714,721.637573 606.314819,721.974182 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M609.995911,719.727905 
	C609.961182,719.960327 609.467773,720.013367 609.219971,720.023010 
	C609.117554,719.712646 609.263000,719.392578 609.699707,719.049805 
	C609.991028,719.027039 610.030640,719.495422 609.995911,719.727905 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M601.073975,713.262573 
	C601.181702,713.034668 601.348572,713.015991 601.767151,712.987183 
	C601.860596,713.387146 601.735168,713.856079 601.073975,713.262573 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M489.556030,771.445068 
	C489.610840,771.529663 489.390472,771.560242 489.390472,771.560242 
	C489.390472,771.560242 489.501251,771.360474 489.556030,771.445068 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M613.258301,688.902710 
	C604.552917,695.700867 594.978333,700.819397 587.105835,708.685974 
	C586.652405,709.552185 586.157166,709.831360 585.245544,709.937988 
	C580.366943,710.915527 577.331055,715.013611 572.376770,715.991394 
	C570.262329,716.334900 568.517395,716.664917 566.385010,716.994995 
	C565.870178,716.829346 565.742859,716.663574 565.807739,716.250122 
	C567.979126,712.209167 571.943726,710.901062 575.282532,709.002686 
	C580.694275,705.925476 585.723572,702.354553 590.853027,698.353027 
	C591.286865,697.711609 591.489258,697.329834 591.785889,696.622681 
	C592.366638,695.507080 593.047302,694.925415 594.077271,694.215942 
	C605.480591,687.275085 614.312378,678.103394 622.120728,667.844543 
	C622.742981,667.027039 623.182068,666.197021 623.091858,665.114563 
	C622.717224,660.616577 625.423523,658.114868 628.996399,656.270752 
	C630.331970,655.581421 632.174072,655.175415 632.495728,653.687195 
	C633.420532,649.407959 636.429810,646.467041 638.680603,643.025879 
	C639.307800,642.067017 639.525513,640.516724 640.810608,640.505249 
	C644.502014,640.472107 644.425049,637.745728 644.761475,635.337036 
	C645.195923,632.226440 641.689819,633.430298 640.615356,631.462158 
	C640.425537,630.383728 640.678345,629.734131 641.331177,628.875000 
	C644.704956,626.986755 646.661926,624.344177 648.126587,620.842407 
	C649.868286,616.744019 652.283569,613.290527 653.093140,608.796021 
	C653.335999,608.263550 653.456726,608.061768 653.821899,607.607056 
	C657.572144,603.130249 660.064331,598.683411 657.490417,592.988281 
	C656.577759,590.968811 656.828125,588.787598 658.561401,587.545105 
	C663.244751,584.187622 663.978149,579.112427 665.081543,573.744751 
	C666.913513,567.594788 667.114258,561.495789 668.737061,555.337402 
	C668.806580,554.543762 669.670288,554.025208 669.030579,553.699768 
	C668.498840,553.429260 669.303406,553.558594 668.798950,554.369141 
	C668.052673,556.343567 667.612610,558.114868 666.531982,560.025879 
	C665.760010,560.880615 665.099670,561.263733 663.977295,561.615051 
	C663.037842,561.709351 662.518188,561.596252 661.735657,561.074463 
	C659.979126,558.343628 661.045654,555.508972 660.135864,552.583984 
	C659.750977,543.372864 658.824280,534.543091 659.738892,525.324219 
	C658.425720,518.448059 656.401794,511.869720 656.080078,505.464783 
	C655.769409,499.278168 654.839050,493.333160 653.660950,487.375458 
	C652.371704,480.855499 649.790894,474.708435 647.600586,468.437927 
	C642.839905,454.808472 634.414429,443.377106 626.439209,431.230591 
	C625.688416,429.699097 625.836182,428.244507 624.849365,426.835693 
	C624.462158,426.198792 624.283203,425.815948 624.036438,425.126526 
	C623.709473,424.058441 623.447205,423.299316 622.689453,422.437500 
	C622.367615,422.011749 622.245544,421.832428 621.962769,421.363831 
	C619.329529,415.266815 613.399780,412.694366 609.626526,408.076538 
	C606.113098,403.776825 606.219788,402.239655 610.942505,399.937653 
	C612.702637,399.079712 613.088501,399.298798 617.323669,403.502502 
	C614.198669,400.358521 611.401672,396.833862 606.965942,394.674896 
	C604.974304,392.571198 603.799927,390.229248 601.535889,388.328979 
	C600.249084,386.301361 599.225769,384.300598 601.237000,382.788971 
	C603.328735,381.216888 604.753296,383.585632 606.740723,384.534729 
	C616.814941,395.121460 628.667175,403.054657 639.802063,412.365845 
	C640.346619,412.932343 640.539246,413.327301 640.625793,414.092163 
	C640.505981,415.022949 640.212097,415.579346 640.145874,416.486389 
	C643.624023,420.462280 647.616455,423.536652 650.595703,427.708405 
	C652.981506,431.049377 654.187317,435.103241 657.358887,438.325409 
	C657.662231,438.826874 657.767944,439.036407 657.997070,439.574402 
	C659.832092,445.496124 663.648254,449.953613 666.561279,454.861755 
	C667.914062,457.141113 668.913391,459.004700 668.628540,461.856079 
	C668.984863,462.530121 669.200378,462.909088 669.594971,463.565125 
	C670.601746,465.401733 671.388367,466.985931 672.675293,468.673706 
	C673.736450,470.656677 675.087036,472.088348 675.655457,474.350983 
	C679.019287,487.290588 682.244568,499.859924 683.992493,512.775879 
	C684.455139,516.194641 683.937012,519.735657 685.296265,523.451050 
	C685.428955,525.517151 685.401428,527.174988 685.379395,529.247437 
	C685.340088,530.210999 685.281067,530.756592 685.101074,531.695312 
	C684.770752,532.889893 684.452820,533.658142 684.010437,534.362793 
	C683.280090,535.526001 682.378906,536.607300 680.859924,536.358887 
	C679.274841,536.099792 679.097595,534.600586 678.635498,533.362061 
	C675.832886,525.850952 674.502686,517.933350 673.443176,510.107727 
	C672.418091,502.536438 669.492493,495.786438 666.843140,488.468262 
	C666.588562,487.570648 666.509399,487.008057 666.739746,486.449799 
	C666.452515,486.452271 666.365479,486.651459 666.503418,487.375183 
	C669.133911,495.406647 671.079773,503.273987 672.085815,511.344757 
	C672.928284,518.103149 675.364014,524.487366 676.411743,531.226196 
	C677.294067,536.901489 677.402893,542.139160 673.893738,546.866699 
	C672.855591,548.265259 673.559875,548.912476 674.259033,549.800232 
	C674.504578,547.611755 674.183533,544.624207 677.383118,544.768066 
	C680.075928,544.889099 680.205933,547.591919 680.329529,549.788940 
	C680.385498,550.784729 680.318604,551.780518 679.987915,553.086426 
	C678.035522,556.707153 681.142273,560.384888 679.019348,563.967163 
	C678.913391,564.475769 678.880859,564.679016 678.785278,565.180115 
	C678.617493,565.856689 678.492249,566.228455 678.242432,566.850647 
	C678.072998,567.274963 678.005066,567.440979 677.854492,567.850464 
	C677.713440,568.459167 677.818909,568.783264 678.115234,569.372925 
	C678.348022,575.327454 674.308289,579.933289 674.200012,585.572327 
	C674.168884,587.194336 673.711853,588.788940 674.585693,590.680420 
	C674.778259,591.470337 674.813660,591.932312 674.741455,592.741150 
	C673.382935,596.143005 676.038696,599.361328 674.523315,601.941711 
	C674.725769,601.363831 674.926941,600.281433 674.968018,598.827637 
	C674.928650,598.259827 674.900452,598.033020 674.822449,597.465088 
	C674.759338,596.661255 674.788086,596.201050 674.923279,595.403931 
	C675.073059,594.849609 675.152161,594.634277 675.407959,594.125671 
	C676.034302,593.326294 676.588745,592.960754 677.459595,592.505981 
	C678.721069,592.074707 679.514282,591.587830 679.836609,590.168579 
	C681.865173,587.568359 683.281494,588.555969 684.708984,590.872070 
	C685.510132,593.440735 684.622070,595.413330 683.959778,597.457397 
	C681.068787,606.379395 678.355896,615.358948 675.223328,624.598633 
	C674.962952,625.697510 674.675964,626.386047 674.178833,627.377686 
	C673.884460,627.897034 673.757996,628.096985 673.422668,628.576904 
	C670.807007,631.299500 670.984314,634.586914 670.299866,637.947510 
	C670.057739,639.258545 670.218567,640.585815 669.566406,641.136902 
	C664.190063,645.680298 664.786987,653.097290 660.914856,658.707825 
	C660.227478,659.724243 659.506348,660.283142 658.949585,661.382507 
	C657.581177,665.576843 655.593384,669.074707 653.208740,672.708435 
	C651.316101,677.235779 647.815125,680.185608 644.955078,683.969299 
	C644.177551,684.825928 643.065918,684.955017 642.814148,686.427979 
	C642.411255,686.863525 642.153442,687.051086 641.864624,687.552490 
	C641.456543,687.918457 641.218994,688.140381 640.979004,688.675415 
	C639.910034,689.995178 638.916504,691.079895 637.832275,692.468506 
	C637.423950,692.870605 637.167114,693.053955 636.860107,693.534912 
	C636.427612,693.851379 636.165833,694.038818 635.848816,694.525269 
	C634.861023,695.567017 633.988281,696.393127 633.011108,697.500488 
	C632.318298,698.316162 631.729797,698.850525 631.046387,699.668701 
	C630.207703,700.587158 629.464050,701.221802 628.458984,701.994263 
	C625.524475,700.780701 623.038330,701.217834 620.323059,702.951599 
	C618.440125,699.706482 619.377991,696.992981 621.937622,694.313232 
	C622.248901,693.801575 622.469055,693.581421 622.821350,693.162170 
	C622.988342,692.997864 623.085266,692.567932 623.118896,692.350159 
	C628.223389,686.958130 634.007935,682.538879 639.153931,676.952454 
	C640.545349,675.632446 641.422485,674.353699 642.314209,672.661072 
	C642.933655,671.665833 643.774048,671.014221 643.757324,670.254944 
	C643.529663,659.877441 650.527832,652.548157 654.875122,643.722046 
	C655.161316,643.205750 655.289124,643.006897 655.645020,642.537537 
	C660.111755,638.241638 661.280151,632.753235 663.026917,627.160156 
	C664.976624,621.609009 668.456360,616.819336 667.147827,610.640503 
	C666.349304,610.279785 665.831909,610.302063 665.380981,611.145813 
	C666.352661,616.390991 662.411560,620.201660 662.105774,625.186523 
	C661.859314,625.694031 661.744080,625.887939 661.417480,626.343384 
	C660.117859,627.433105 659.280273,628.373718 659.747803,630.233459 
	C659.722778,631.546570 659.346619,632.371033 658.385498,633.266724 
	C652.539185,635.136719 651.197388,637.331482 650.835205,645.593384 
	C647.849792,651.115906 644.417297,655.974365 640.489380,660.404785 
	C639.129333,661.938843 639.492310,664.358643 637.156860,665.466309 
	C636.367126,665.933899 635.821106,666.187683 635.124512,666.732239 
	C634.790527,667.067139 634.661377,667.205811 634.343018,667.560364 
	C634.026001,667.922546 633.898193,668.068909 633.376343,668.665344 
	C632.846008,669.271423 632.709656,669.427429 632.140747,670.067261 
	C625.744019,676.713684 620.597290,683.686035 613.258301,688.902710 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M622.897034,707.176025 
	C623.812683,707.167236 624.277344,707.535217 623.735840,708.285706 
	C623.361267,708.804749 622.826721,709.224426 622.072449,708.311890 
	C622.252136,707.790405 622.492798,707.573853 622.897034,707.176025 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M617.833862,712.854370 
	C618.051270,712.600952 618.332458,712.401733 618.613647,712.202515 
	C618.600281,712.436646 618.586914,712.670715 618.298096,712.962402 
	C618.022644,713.019958 617.897644,712.908569 617.833862,712.854370 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M620.457825,709.535583 
	C620.415588,709.470703 620.500061,709.600464 620.457825,709.535583 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M626.989868,703.703552 
	C626.936035,703.935181 626.468445,704.038635 626.246521,704.129211 
	C626.233521,703.896851 626.442505,703.573914 626.921692,703.135498 
	C627.191956,703.020020 627.043640,703.471924 626.989868,703.703552 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M619.062378,703.914429 
	C618.837402,703.843506 618.876465,703.679810 618.967285,703.246582 
	C619.018982,702.976990 619.506958,702.927246 619.751709,702.945068 
	C619.773193,703.249146 619.549866,703.535400 619.062378,703.914429 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M622.838257,707.105103 
	C622.876099,707.278503 622.679810,707.549011 622.241577,707.906677 
	C622.201050,707.729980 622.402588,707.466125 622.838257,707.105103 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M672.645203,628.047607 
	C673.161926,627.436523 673.337646,626.887756 673.754272,626.169434 
	C679.093567,623.802551 681.205994,619.460327 681.927429,614.376160 
	C682.733948,608.692993 686.000977,603.673401 685.985901,597.747437 
	C685.977661,594.494202 687.833618,591.794312 690.319336,589.673645 
	C692.376282,587.918762 694.182373,587.964966 695.583740,590.930054 
	C695.703552,591.474243 695.721497,591.694702 695.677734,592.234497 
	C695.330811,593.516968 694.240601,594.130310 694.450928,595.583374 
	C694.485596,596.237549 694.488831,596.605591 694.481262,597.230225 
	C694.464661,597.877869 694.266846,598.099731 693.934570,598.592529 
	C693.002686,600.236816 692.144653,603.421204 691.990723,606.739319 
	C688.927368,607.630859 690.272522,610.642273 689.001221,612.599854 
	C688.425537,613.392517 688.208557,613.910278 688.548950,614.826782 
	C688.690186,615.094849 688.850830,615.144592 688.894775,615.218872 
	C688.735474,615.935303 688.532227,616.577515 688.224060,617.456177 
	C686.701355,618.880615 686.264526,620.541443 685.948608,622.617920 
	C685.234863,623.116882 685.053955,623.686340 684.912354,624.611816 
	C683.060242,627.399109 681.965454,630.243469 680.774658,633.425537 
	C680.348389,634.350403 679.993896,634.925476 679.461914,635.800293 
	C675.210022,643.157776 671.135620,650.215576 667.015930,657.625244 
	C666.642700,658.441406 666.314697,658.905518 665.973755,659.668396 
	C665.671875,660.144165 665.382996,660.321167 664.970459,660.731445 
	C664.531616,661.046692 664.287964,661.225891 664.015747,661.711914 
	C662.983582,662.487183 662.280518,663.233398 661.976929,664.640015 
	C661.612732,665.408203 661.291077,665.864258 660.930542,666.604492 
	C656.800659,666.734741 656.203735,670.856323 653.478760,672.881592 
	C652.364563,672.561340 651.870789,671.764099 652.643616,671.066589 
	C655.525330,668.465942 655.994324,664.708313 657.754822,661.216064 
	C658.762390,660.092896 659.976685,659.767029 660.860962,658.520752 
	C665.964355,652.507507 669.480957,646.036133 671.029785,638.636414 
	C671.300293,637.344116 670.004089,639.174194 669.314697,638.229492 
	C669.726196,634.581238 668.969604,630.672119 672.645203,628.047607 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M640.679993,630.761780 
	C641.066040,631.110413 641.163025,631.247925 641.281128,631.363770 
	C642.766602,632.821472 646.053894,630.199524 646.368774,632.887024 
	C646.675781,635.507996 646.153320,638.491821 644.510071,640.940796 
	C644.087219,641.571045 643.914246,642.550293 642.744751,641.743652 
	C640.639648,640.291687 640.610352,641.981567 639.841125,643.392334 
	C638.977722,644.975769 640.489746,649.224670 635.998474,647.534485 
	C635.875549,647.488098 635.370361,647.931763 635.389771,648.109009 
	C636.064758,654.271851 632.115906,656.522461 627.313843,658.753967 
	C625.974609,659.376282 622.454956,661.981140 625.289490,665.267090 
	C625.523804,665.538696 625.189209,666.604797 624.817688,667.050781 
	C616.132141,677.478577 607.329834,687.792908 595.526428,694.947449 
	C595.396973,695.025940 595.200745,694.994446 594.689941,695.023560 
	C593.485535,695.389282 592.654175,695.823181 591.367798,695.975342 
	C589.378174,697.899231 587.196289,698.689880 584.368164,698.990845 
	C579.425964,698.395203 577.547424,693.574341 573.187012,692.132019 
	C574.764954,686.526245 578.749878,688.168030 582.780884,688.905884 
	C586.938843,689.707520 591.038940,687.744812 594.720581,690.235962 
	C595.110596,690.499939 595.943542,689.326965 596.448364,688.305237 
	C596.636841,687.431641 596.676147,686.872314 595.626831,686.713257 
	C594.383301,687.127380 593.481201,687.557190 592.509766,687.760864 
	C590.987854,688.079895 589.445923,688.119385 588.575134,686.559082 
	C587.761841,685.101562 588.292297,683.654541 589.151611,682.369995 
	C590.268555,680.700378 591.960083,679.594482 593.648499,677.968140 
	C594.274597,677.387756 594.643616,677.073242 595.307251,676.557373 
	C600.922852,672.854187 606.405334,669.523010 609.267456,663.025635 
	C609.437073,662.471619 609.529480,662.256897 609.857056,661.780334 
	C616.924438,658.410278 621.001709,652.366028 625.871338,646.652771 
	C630.545593,642.894043 632.610291,637.537170 636.257690,633.416016 
	C637.428223,632.093384 638.170166,630.284668 640.679993,630.761780 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M504.827942,708.463989 
	C511.451477,708.199951 517.105103,705.279907 523.520386,703.888550 
	C530.134460,699.339355 537.563843,698.180420 544.755737,696.366760 
	C546.268188,695.985352 547.732361,696.279785 549.525452,696.997070 
	C551.099548,697.931641 551.878723,698.989258 552.138184,700.819275 
	C551.457703,701.508423 550.764221,701.792053 549.778748,702.052124 
	C547.366150,702.617676 545.691650,704.062134 543.426270,705.139160 
	C541.160767,705.256042 539.714966,706.805481 537.501709,706.908081 
	C536.605164,706.848389 536.056274,706.661011 535.263550,706.463074 
	C535.024780,706.431702 534.570007,706.477905 534.367554,706.558472 
	C534.165161,706.639099 533.920715,706.999573 533.920715,706.999573 
	C533.920715,706.999573 534.357788,707.090088 534.501709,707.294556 
	C534.691833,707.666321 534.738098,707.833679 534.789612,708.268677 
	C534.734985,708.901184 534.590637,709.230347 534.144287,709.683716 
	C532.549011,709.885803 531.185242,709.933960 529.411682,709.985352 
	C528.189819,709.384521 528.054871,708.523621 528.170654,707.286865 
	C528.367920,706.645447 528.737366,706.547058 528.913513,706.267212 
	C528.582153,706.270386 528.308105,706.416321 527.786072,706.872070 
	C520.418579,709.041077 513.587219,712.060120 505.664917,711.757446 
	C505.088470,711.437927 504.882629,711.265808 504.514709,710.722778 
	C504.266113,709.803589 504.333649,709.275024 504.827942,708.463989 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M583.294373,689.763672 
	C579.707153,689.804077 576.179871,687.921326 573.193481,691.587280 
	C570.960449,691.850403 569.557373,694.099060 567.177917,693.115967 
	C565.831543,691.618896 566.438660,689.533813 564.723999,688.194702 
	C564.377808,687.475830 564.321045,687.021545 564.435669,686.208618 
	C568.541382,681.909790 573.334473,679.135437 578.710083,676.765930 
	C581.006165,678.214050 583.638306,678.939514 583.766235,682.299683 
	C583.165283,683.970764 581.848206,685.326721 584.144409,686.777710 
	C584.709167,688.071350 584.587585,688.950989 583.294373,689.763672 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M533.987793,726.586731 
	C533.468384,726.946167 533.052124,726.967346 532.323730,727.019287 
	C531.595642,727.459717 531.137878,727.797424 530.256287,727.900513 
	C522.253601,729.630127 514.539490,731.361450 506.403748,733.104370 
	C505.586548,732.715881 505.354645,732.236572 505.327393,731.272949 
	C506.489075,728.473877 508.713623,729.955688 510.685120,729.194824 
	C510.999420,728.945557 511.000580,728.999878 510.978302,728.984375 
	C513.383789,728.177795 515.953186,728.075012 518.705750,727.188110 
	C518.999573,726.941833 518.999268,727.000305 518.973999,726.985474 
	C519.388733,726.635803 519.906006,726.518860 520.654846,726.287720 
	C525.731750,724.674011 530.587952,723.203308 535.453735,721.764893 
	C536.575989,721.433167 538.015503,720.053711 538.720215,722.057373 
	C539.338867,723.816528 537.545410,724.127563 536.450012,724.694763 
	C535.600769,725.134460 534.489746,725.132935 533.987793,726.586731 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M564.666260,687.750427 
	C568.650085,687.833130 567.995789,689.921997 567.045837,692.494629 
	C565.637207,693.702637 564.543762,695.114014 562.342285,695.028931 
	C561.818115,694.882141 561.647705,694.753845 561.248047,694.848633 
	C561.018677,695.071777 561.004333,695.006287 561.032959,695.023743 
	C560.808655,695.228394 560.537048,695.364075 560.241699,695.422424 
	C559.797363,695.510193 559.328552,695.442444 558.899597,695.631531 
	C559.201477,695.611084 559.503357,695.556824 560.005005,695.917725 
	C558.195618,698.514282 554.555237,698.287354 552.467407,701.097168 
	C551.478210,700.305542 550.805237,699.386292 549.966858,698.165771 
	C551.164734,693.808167 555.261292,692.544373 558.251343,689.656860 
	C559.111023,689.059631 559.730164,688.737000 560.658264,688.245911 
	C562.062683,687.615295 563.154297,687.143860 564.666260,687.750427 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M535.748535,705.780273 
	C538.125610,705.666443 540.174561,705.326965 542.597778,705.458862 
	C542.344055,706.088440 541.716187,706.246460 540.838562,706.614441 
	C540.154724,707.831909 539.283569,707.676025 538.159546,707.735535 
	C537.866638,707.844177 537.442749,707.935608 537.235107,707.997986 
	C536.336914,708.577332 535.578979,708.788208 534.361755,708.378906 
	C533.994568,707.973877 533.991638,707.757080 533.987915,707.214966 
	C533.881226,706.734436 533.775269,706.579163 533.825928,706.189209 
	C533.982483,705.954651 533.997925,706.002625 533.975159,705.991760 
	C534.376099,705.612671 534.865540,705.470764 535.748535,705.780273 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M495.050232,724.330688 
	C495.087311,723.691956 495.258575,723.431885 495.718445,723.111145 
	C497.336090,723.071838 498.749054,723.148621 500.162018,723.225342 
	C498.583954,724.541504 497.338531,726.886414 495.050232,724.330688 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M500.943878,729.619385 
	C499.288513,729.948303 497.561096,729.946411 495.401550,729.922913 
	C496.509003,725.574219 498.744659,727.954407 500.943878,729.619385 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M518.595093,726.932983 
	C517.093079,729.379578 514.380798,728.987610 511.369385,728.982849 
	C513.069336,727.232056 515.627319,727.068726 518.595093,726.932983 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M564.495117,703.291748 
	C568.481812,700.921021 565.952148,705.264832 567.465698,705.549194 
	C565.619446,706.012085 564.684631,705.223145 564.495117,703.291748 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M543.008545,705.009155 
	C543.883667,702.138306 546.436035,702.412109 549.058716,702.022583 
	C548.854736,702.614136 548.222656,703.199707 547.317627,703.950867 
	C546.007690,704.821533 545.045105,705.716614 543.320312,705.232544 
	C543.011353,705.010986 543.001404,705.001404 543.008545,705.009155 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M510.601685,728.908997 
	C509.913788,730.708496 507.814209,729.529480 506.229523,730.781738 
	C505.234100,731.612122 504.560089,731.458740 503.973877,730.395630 
	C503.995026,730.002441 504.056122,729.567261 504.082245,729.349121 
	C504.686859,728.419861 505.437592,728.268616 506.655548,728.646667 
	C508.079834,728.847168 509.141876,728.859802 510.601685,728.908997 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M506.934631,728.511169 
	C506.290649,728.918335 505.563538,729.002014 504.472382,729.108337 
	C503.644348,729.098816 503.180298,729.066650 502.368103,729.040405 
	C503.318817,727.006592 504.789307,725.932251 506.934631,728.511169 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M543.004517,705.240967 
	C544.124146,704.715881 545.237000,704.420776 546.697205,704.121094 
	C546.524414,706.020996 545.386169,706.936218 543.163330,706.176025 
	C542.971985,705.930420 542.997620,705.470947 543.004517,705.240967 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M537.971680,715.302734 
	C537.962036,714.996765 538.211121,714.894897 538.280273,714.779419 
	C539.123779,714.532776 539.949158,713.867493 540.640076,714.674255 
	C540.831665,714.898010 540.725525,715.769775 540.522034,715.870300 
	C539.674622,716.288574 538.695251,716.878845 537.971680,715.302734 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M538.177979,708.005737 
	C538.575806,707.512573 539.284973,707.180908 540.291504,706.836792 
	C541.166443,707.142944 541.907471,707.668579 541.476196,708.254578 
	C540.573914,709.480530 539.482239,708.664978 538.177979,708.005737 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M565.671875,716.006531 
	C566.000000,716.002441 565.998779,716.498840 565.998169,716.746948 
	C565.654114,717.730225 565.209534,718.532410 564.321960,718.347168 
	C563.928772,718.265076 563.386597,717.396545 563.444580,716.954956 
	C563.578613,715.934570 564.591248,716.115601 565.671875,716.006531 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M534.001465,708.465820 
	C534.754517,708.150330 535.511475,708.110046 536.647949,708.065063 
	C537.016541,708.481567 537.005554,708.902832 536.993164,709.639526 
	C536.221436,709.951660 535.451111,709.948303 534.296814,709.891357 
	C533.943726,709.472107 533.974548,709.106567 534.001465,708.465820 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M591.030396,696.237671 
	C591.631592,695.039185 592.598511,694.990540 593.965088,695.013977 
	C593.754883,695.627625 593.165649,696.222412 592.301086,696.960571 
	C591.841431,697.201721 591.657104,697.299438 591.248047,697.179321 
	C591.023254,696.961548 591.041748,696.478943 591.030396,696.237671 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M560.410034,696.058105 
	C559.739380,695.931885 559.273865,695.661560 558.808289,695.391296 
	C559.355042,695.253052 559.901855,695.114807 560.755127,695.008911 
	C560.912720,695.332153 560.763855,695.623169 560.410034,696.058105 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M568.708801,718.930786 
	C569.143799,719.977112 568.367798,719.988586 567.363220,720.041870 
	C567.480713,719.607361 567.938660,719.257141 568.708801,718.930786 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M570.575684,718.039917 
	C570.830994,718.888489 570.214844,719.023071 569.262817,719.069336 
	C569.391968,718.677856 569.835754,718.349060 570.575684,718.039917 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M520.588257,726.125000 
	C520.419312,726.410645 519.966614,726.688293 519.231323,726.968323 
	C519.400696,726.686157 519.852539,726.401611 520.588257,726.125000 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M561.263184,695.088867 
	C561.141724,694.670837 561.236633,694.218323 561.898621,694.825806 
	C561.988525,695.010498 561.507690,695.106018 561.263184,695.088867 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M584.024658,699.035522 
	C583.923462,699.004822 583.881592,699.843384 583.817627,699.027283 
	C583.943665,698.957031 584.000061,699.007019 584.024658,699.035522 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M591.015015,697.235840 
	C591.190918,696.980530 591.358643,696.999573 591.776062,697.061279 
	C591.901123,697.404053 591.705261,697.646729 591.229614,697.945435 
	C591.016174,697.875916 591.011536,697.693054 591.015015,697.235840 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M575.474060,697.457153 
	C575.521667,697.430176 575.426453,697.484131 575.474060,697.457153 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M503.616425,730.168945 
	C504.464691,730.332886 504.934357,730.663391 505.701965,731.023438 
	C505.997253,731.510498 505.994537,731.967957 505.987000,732.770752 
	C504.544891,734.619690 502.435089,734.380127 500.814026,734.421387 
	C491.732788,734.652710 482.727631,736.850891 473.299683,735.245544 
	C480.884552,733.114746 488.783356,731.411194 496.901550,731.022949 
	C499.024628,730.921387 501.218933,731.415710 503.616425,730.168945 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M503.180664,745.259155 
	C499.894745,745.178955 496.741852,744.444885 493.722198,745.597229 
	C491.793976,746.333008 491.080627,744.993347 490.193542,743.352112 
	C490.784668,743.019104 491.522675,743.006775 492.630371,742.998413 
	C494.588135,742.621582 496.303589,743.736084 497.944611,742.315857 
	C501.704865,741.302979 505.368744,740.776733 509.546326,740.939209 
	C507.788055,742.492859 505.583588,744.016541 503.180664,745.259155 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M497.669800,742.020508 
	C497.971283,742.703125 500.222809,743.420898 498.230713,743.891724 
	C496.748901,744.242065 495.013214,744.384399 493.242432,743.281006 
	C494.161346,741.539978 495.799713,742.114258 497.669800,742.020508 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M514.623474,739.942627 
	C513.881104,741.465515 512.231323,740.882629 510.369690,740.998596 
	C511.110718,739.472961 512.764038,740.068420 514.623474,739.942627 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M523.658203,737.857483 
	C523.271790,739.171936 521.940796,738.937683 520.333069,738.991455 
	C520.768494,737.718140 522.113159,738.078003 523.658203,737.857483 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M530.131592,728.135559 
	C530.453674,727.616394 530.939514,727.333923 531.718506,727.050781 
	C531.803345,727.963074 531.767090,729.099304 530.131592,728.135559 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M518.261108,739.707764 
	C517.245789,739.968628 516.484070,739.985718 515.341064,739.996582 
	C515.476807,739.038086 516.382263,738.969971 517.655334,738.971558 
	C518.170654,739.145325 518.342651,739.304688 518.261108,739.707764 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M518.305420,739.935791 
	C518.007507,739.951477 517.998047,739.468811 517.998352,739.227295 
	C518.446899,738.965393 518.895142,738.944885 519.679993,738.941467 
	C519.545532,739.279053 519.074463,739.599548 518.305420,739.935791 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M445.052246,714.719116 
	C444.983582,715.079285 444.488678,715.050964 444.242065,715.026306 
	C443.329376,714.215210 442.433044,713.798157 441.249817,713.277588 
	C440.197632,711.026489 435.500061,712.643860 436.849609,708.319946 
	C439.635529,709.380737 442.623352,707.791016 445.674072,708.964966 
	C447.249420,710.006287 448.189636,709.318054 449.462891,708.380737 
	C450.333008,708.190613 450.847107,708.202576 451.748230,708.404175 
	C462.787140,710.786743 473.571686,711.976440 484.436096,712.505432 
	C486.415741,712.601746 488.407104,712.373779 490.800018,712.777344 
	C492.627258,713.273682 493.852814,713.959778 494.855133,715.599854 
	C491.146576,718.076416 486.999298,716.680359 482.997681,716.942078 
	C480.384369,717.113037 478.060028,717.293457 477.067993,720.593201 
	C472.893097,719.466125 468.946960,717.530396 464.632904,716.401306 
	C462.836548,715.931152 461.654358,715.842590 460.078369,717.088684 
	C459.499573,717.346741 459.086609,717.282776 458.363342,717.272644 
	C457.926208,715.763611 457.071350,714.862305 455.514923,715.066528 
	C451.989807,715.529175 448.760895,711.894897 445.052246,714.719116 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M495.417175,715.986938 
	C494.113922,715.497986 493.226593,714.993530 492.169952,714.235229 
	C492.730194,710.693359 495.781647,711.230530 497.921692,710.750122 
	C499.916046,710.302429 502.111450,709.755127 504.608246,710.704712 
	C511.102936,710.245911 517.215210,709.366272 522.858704,706.691650 
	C524.614502,705.859558 526.359985,706.120789 528.581543,705.995178 
	C529.029419,707.087952 529.017334,708.142883 529.003540,709.593201 
	C520.450623,712.921021 511.416382,713.308838 502.625397,714.978455 
	C500.379456,715.405029 498.098206,715.645508 495.417175,715.986938 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M451.210632,709.186890 
	C450.523682,708.989563 450.112488,708.992004 449.392883,708.989502 
	C448.887085,709.134521 448.689697,709.284363 448.232300,709.168457 
	C448.257629,705.081238 453.685852,705.616089 453.983887,701.449768 
	C455.380463,699.626404 457.110107,700.832153 459.058044,700.527527 
	C460.270508,700.734863 461.003021,701.073181 461.842102,701.964478 
	C463.327911,704.611877 464.607300,706.908386 465.960815,709.537842 
	C465.644165,710.522217 465.022919,710.750244 463.974335,710.777344 
	C463.429016,710.674011 463.219238,710.602722 462.725189,710.370117 
	C461.906738,709.872620 461.404480,709.473755 460.442505,709.323242 
	C457.248230,709.603027 454.377594,710.445740 451.210632,709.186890 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M460.196960,701.081482 
	C458.325409,701.292053 456.590027,701.171082 454.419128,701.072021 
	C449.021362,700.095886 444.068848,699.044434 439.089691,698.139465 
	C437.932220,697.929138 436.438568,697.279419 435.508484,699.331543 
	C435.191498,699.311707 435.032318,698.957703 434.630005,698.435425 
	C432.850006,698.143005 431.886719,696.813965 430.196716,696.285645 
	C429.909821,696.162231 429.825043,695.769409 429.834869,695.568726 
	C429.707214,695.130066 429.569763,694.892029 429.718262,694.357056 
	C439.704987,696.048767 449.405762,698.037537 459.510986,700.096802 
	C460.054657,700.361511 460.193878,700.555725 460.196960,701.081482 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M430.082855,696.476318 
	C431.589264,695.962646 433.331543,695.579163 434.312256,697.954468 
	C432.266357,699.854614 431.250366,698.352600 430.082855,696.476318 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M429.656860,693.940063 
	C429.986023,694.278137 429.967834,694.496277 429.897156,695.041138 
	C429.451294,695.307373 429.057892,695.246765 428.368866,695.132568 
	C427.073181,695.829834 426.242218,695.371765 425.148682,694.483521 
	C424.668121,694.250122 424.478485,694.243896 424.008301,694.190063 
	C423.644653,693.975586 423.561615,693.808594 423.486694,693.360718 
	C423.592529,692.918945 423.690247,692.758057 424.070190,692.590698 
	C426.004761,692.996155 427.657104,693.408142 429.656860,693.940063 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M424.947754,694.539185 
	C425.712128,694.477051 426.566498,694.697693 427.747070,694.998718 
	C427.888580,695.628906 427.703888,696.178772 427.261108,696.875488 
	C426.347931,696.288879 425.692841,695.555420 424.947754,694.539185 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M435.251221,699.987671 
	C435.350677,699.665649 435.511749,699.743469 435.580872,699.794434 
	C436.000793,700.285889 436.358093,700.721985 436.675110,701.185669 
	C436.686127,701.201721 436.277161,701.649109 436.093872,701.628601 
	C435.344666,701.544556 435.216736,700.931519 435.251221,699.987671 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M447.649994,708.942139 
	C448.157043,708.921570 448.341736,708.940369 448.805481,708.971863 
	C448.378815,710.130859 447.441711,710.128418 446.207031,709.270081 
	C446.462402,709.001526 446.895020,708.991516 447.649994,708.942139 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M416.135406,690.307739 
	C417.778442,690.021606 419.514404,690.105103 420.351746,692.410828 
	C420.362152,692.988403 420.419525,693.188354 420.202515,693.536987 
	C419.928131,693.685669 419.560028,693.771545 419.372833,693.787231 
	C419.081879,691.940063 417.033691,691.857727 416.135406,690.307739 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M413.148193,689.143433 
	C413.619385,689.031738 414.250824,689.233032 415.079773,689.709229 
	C414.620972,689.808167 413.964691,689.632263 413.148193,689.143433 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M420.054443,693.944946 
	C419.928131,693.685669 420.041229,693.209473 420.173004,692.998901 
	C420.304779,692.788391 420.456055,692.839722 420.512085,692.896606 
	C421.136353,692.526062 421.638245,692.592712 422.038574,693.600708 
	C421.453247,694.758606 420.856018,694.930969 420.054443,693.944946 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M422.282043,694.063721 
	C421.726196,693.745117 421.448822,693.444214 420.869812,693.048401 
	C421.323334,691.445984 422.552734,691.820740 424.061127,692.365479 
	C424.352386,692.584106 424.151794,692.827515 424.049591,692.947632 
	C423.799164,693.231140 423.650909,693.394592 423.310547,693.827637 
	C422.932465,694.091919 422.746490,694.086670 422.282043,694.063721 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M404.627014,694.758301 
	C404.613068,694.986389 404.490753,695.110168 404.368469,695.233887 
	C404.418518,695.040588 404.468597,694.847290 404.627014,694.758301 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M423.270752,694.108643 
	C423.095032,693.745361 423.227692,693.468445 423.731873,693.166992 
	C423.932953,693.250488 423.918488,693.433350 423.815887,693.879395 
	C423.727722,694.142578 423.423065,694.120117 423.270752,694.108643 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M424.756409,724.586548 
	C424.241119,722.920471 425.376740,722.941467 426.243713,722.799744 
	C426.357544,722.781189 426.789459,723.521179 426.728271,723.844238 
	C426.550751,724.781128 425.859497,725.047119 424.756409,724.586548 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M441.009155,713.331909 
	C442.146423,713.070068 443.383453,712.808838 443.933899,714.661499 
	C443.534424,714.998352 443.073425,714.995056 442.268341,714.937134 
	C441.814209,714.603821 441.637299,714.377197 441.200317,714.098755 
	C441.007080,713.994934 441.015961,713.552856 441.009155,713.331909 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M432.743927,709.603149 
	C432.861176,709.460693 433.064392,709.442566 433.267639,709.424438 
	C433.121735,709.525452 432.975830,709.626465 432.743927,709.603149 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M419.794922,707.079102 
	C419.953979,706.981995 419.992340,707.471191 420.009796,707.715515 
	C419.896790,707.698608 419.766357,707.437378 419.794922,707.079102 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M730.989929,669.614624 
	C728.343567,669.204956 725.648071,668.748596 722.440918,669.012817 
	C726.008362,665.084290 728.220459,665.141296 730.989929,669.614624 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M695.007324,381.007446 
	C693.809082,382.604095 695.546509,383.138275 696.205688,383.948700 
	C699.621765,388.148712 703.126526,392.276642 706.703247,396.744202 
	C706.663940,397.138550 706.425964,397.190521 706.393494,397.302216 
	C705.110779,401.716583 701.143677,401.269470 697.483948,402.052979 
	C696.455139,402.129120 695.819092,402.015656 694.914551,401.619202 
	C693.517273,400.752533 692.196533,400.669800 690.724854,400.044220 
	C688.242920,393.064972 682.687439,388.806732 678.343872,383.632263 
	C676.479309,381.410980 674.656128,379.020111 679.784790,377.991241 
	C681.860718,375.496307 679.168762,374.152985 678.729126,371.941833 
	C678.713562,371.378601 678.762512,371.152222 679.079468,370.699707 
	C680.822998,369.828339 682.103210,370.287903 683.604614,371.351379 
	C685.242432,372.375885 686.679871,373.060577 688.247070,374.177185 
	C689.769653,376.524048 691.507141,378.188690 693.797852,379.772644 
	C694.040955,379.996368 693.988831,380.006500 693.988647,379.979919 
	C694.209717,380.203369 694.434265,380.450439 694.855286,380.853882 
	C695.048096,381.013641 695.005554,381.028778 695.007324,381.007446 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M695.026672,401.021332 
	C695.867554,401.169739 696.346558,401.388123 696.972290,401.838074 
	C698.739014,406.581329 701.827148,410.253998 704.135620,414.371307 
	C706.253906,418.149414 708.444275,421.914978 709.079895,426.666626 
	C707.584167,427.006744 706.020752,427.021423 706.036682,429.617676 
	C705.019409,430.695068 703.919800,430.426910 702.522827,430.183655 
	C698.944153,427.183960 698.313843,422.837463 696.188843,419.324341 
	C693.883911,415.513794 691.196289,411.912384 689.436340,407.398743 
	C690.315674,404.306000 692.700134,402.884460 695.026672,401.021332 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M735.128296,442.575989 
	C734.008301,443.575653 733.425476,445.242706 731.349426,444.986145 
	C729.503540,443.584991 727.718506,443.182434 725.416382,443.308411 
	C724.269104,442.426361 724.180359,441.526123 724.797607,440.257355 
	C725.586487,439.430511 726.673218,438.732666 726.571838,438.347870 
	C725.611694,434.703827 727.932800,434.891510 730.647949,435.003326 
	C732.380127,437.409668 733.722839,439.818207 735.128296,442.575989 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M724.768433,439.784668 
	C725.210999,440.847656 725.371094,441.712219 725.800781,442.772980 
	C727.845947,444.262817 728.297485,446.008240 727.620972,448.436615 
	C727.209656,448.961182 726.989441,449.122528 726.384277,449.144043 
	C722.359680,444.174347 720.040833,438.816650 718.832397,432.449188 
	C722.842224,433.804199 722.809082,437.318146 724.768433,439.784668 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M726.057861,449.346985 
	C726.169006,449.005219 726.338684,449.006195 726.762939,449.005066 
	C727.675293,448.322693 728.334839,447.645660 729.706360,447.836334 
	C730.983154,449.126251 731.944275,450.258942 732.948120,451.715820 
	C731.801392,453.495422 731.048462,452.456268 730.136597,451.298065 
	C729.845825,450.896057 729.688354,450.759857 729.276489,450.804626 
	C727.881104,450.940155 726.614258,451.176971 726.057861,449.346985 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M727.567261,456.419556 
	C727.111755,455.310852 727.133606,454.422607 728.279358,454.481659 
	C728.734009,454.505066 729.497620,455.277649 729.514038,455.731140 
	C729.555359,456.869385 728.673950,456.896667 727.567261,456.419556 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M724.978149,431.656799 
	C723.347229,431.772217 722.788757,430.798431 723.249939,429.309052 
	C723.341431,429.013794 724.079651,428.600128 724.247131,428.699646 
	C725.222107,429.278931 724.915710,430.342194 724.978149,431.656799 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M725.021545,431.987793 
	C725.436462,432.337769 726.242798,432.602386 725.399292,433.296326 
	C725.263550,433.039856 725.127808,432.783386 724.962463,432.296570 
	C724.932861,432.066193 724.990662,432.012360 725.021545,431.987793 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M729.219849,431.759674 
	C729.447449,431.761597 729.707397,432.067505 729.985352,432.686829 
	C729.752930,432.688049 729.502502,432.375854 729.219849,431.759674 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M729.267517,450.993286 
	C729.293030,450.891418 729.563965,450.797211 729.919067,450.867615 
	C730.003235,451.032227 729.513000,451.000977 729.267517,450.993286 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M713.399048,424.600098 
	C713.330444,424.506714 713.582214,424.458771 713.582214,424.458771 
	C713.582214,424.458771 713.467590,424.693481 713.399048,424.600098 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M718.054932,430.183105 
	C718.203979,430.120667 718.313782,430.349304 718.423523,430.577942 
	C718.287598,430.543365 718.151672,430.508789 718.054932,430.183105 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M717.082886,429.222961 
	C717.404541,428.991455 717.671692,429.169739 717.979370,429.675659 
	C718.094177,429.892029 717.980347,430.013580 717.917236,430.072815 
	C717.530762,430.018219 717.280273,429.814697 717.082886,429.222961 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M716.963806,429.027527 
	C716.609314,428.948761 716.351807,428.755280 716.095093,428.218842 
	C716.377747,428.019836 716.646606,428.195953 716.951721,428.705505 
	C717.063110,428.924347 716.998962,428.995209 716.963806,429.027527 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M715.983032,428.012115 
	C715.854492,427.864746 715.744446,427.701019 715.634399,427.537292 
	C715.729553,427.583405 715.824585,427.629486 715.976807,427.816925 
	C716.033875,427.958282 716.001648,427.995758 715.983032,428.012115 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M702.737488,430.232300 
	C703.799988,429.685638 704.611267,429.143829 705.763367,429.895813 
	C710.361023,440.042145 716.197754,449.452148 719.026184,460.554840 
	C719.397461,462.694336 719.696472,464.461212 719.989380,466.617615 
	C719.075989,467.875854 718.133362,467.562927 716.876831,466.874390 
	C715.094116,465.268036 714.337280,463.426392 713.275635,461.352356 
	C711.863892,456.469818 709.947754,452.202606 707.352539,447.852631 
	C706.866211,446.678070 706.623352,445.795380 706.572266,444.537933 
	C706.081421,441.138184 704.422363,438.502655 703.356445,435.644104 
	C702.729492,433.963043 701.695984,432.385010 702.737488,430.232300 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M713.921143,460.647644 
	C715.060913,462.370605 715.825623,463.888153 716.755005,465.681152 
	C717.253845,466.267273 717.478882,466.643127 717.686035,467.405945 
	C717.786133,470.603638 718.545959,473.325043 719.589233,476.326904 
	C719.785645,477.084717 719.816406,477.529419 719.695312,478.300812 
	C717.960205,480.996155 721.439819,482.966278 720.107788,485.653259 
	C719.584045,486.628296 718.902039,486.882874 717.742920,486.883118 
	C716.900391,486.734192 716.473145,486.520599 715.923706,485.896912 
	C715.928711,484.040527 716.061768,482.495361 714.740479,480.873230 
	C712.125122,474.244812 709.434387,467.993195 710.005310,460.724182 
	C711.386719,459.897980 712.466492,459.893250 713.921143,460.647644 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M717.026367,468.331665 
	C716.990234,467.540985 716.969055,467.088776 716.933716,466.296570 
	C717.725037,466.282990 718.530457,466.609344 719.659607,466.971405 
	C721.816040,472.720001 723.983765,478.346527 724.688538,484.831299 
	C721.679993,483.589020 723.076355,479.580841 719.904968,478.722198 
	C719.521057,478.296997 719.443481,477.970673 719.347351,477.389465 
	C718.787231,474.253357 717.645020,471.534515 717.026367,468.331665 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M716.775635,486.305969 
	C717.770996,485.998901 718.542542,485.997772 719.702271,485.994751 
	C720.418152,489.447784 724.370911,491.935791 722.425049,495.986206 
	C719.156677,494.252472 719.795288,490.441498 717.347351,488.080017 
	C716.642883,487.605408 716.496765,487.140289 716.775635,486.305969 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M722.012024,497.706970 
	C721.920532,498.501007 721.996887,499.212677 721.177490,499.244263 
	C720.952698,499.252869 720.609070,498.953522 720.500549,498.713806 
	C720.086914,497.799530 720.552246,497.306732 721.712036,497.034729 
	C722.007324,497.001892 722.006958,497.471985 722.012024,497.706970 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M725.226501,501.591095 
	C724.202759,501.943359 723.280457,501.961121 723.887573,500.289978 
	C724.233276,500.040314 724.489746,500.039886 725.133301,500.044037 
	C725.446350,500.461121 725.372314,500.873657 725.226501,501.591095 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M730.096436,447.760803 
	C729.232422,448.309723 728.442932,448.625885 727.335449,448.972534 
	C726.726807,447.246429 726.436096,445.489868 726.107910,443.351257 
	C728.637634,440.014496 729.873535,441.828064 730.956421,444.656647 
	C730.719360,445.836182 730.445068,446.682129 730.096436,447.760803 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M734.459351,449.332642 
	C734.332336,448.703156 734.390137,448.264832 734.447937,447.826508 
	C734.809509,448.018524 735.366699,448.125977 735.493591,448.419403 
	C735.796082,449.119171 735.572571,449.651001 734.459351,449.332642 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M716.784058,485.887085 
	C716.996277,486.445984 716.993103,486.891998 716.990112,487.672668 
	C714.668762,489.055847 712.195374,489.582550 712.093872,486.065521 
	C711.967346,481.681793 710.517883,477.667694 709.275757,473.256348 
	C707.727417,470.348389 706.279785,467.758575 705.913025,464.390564 
	C705.676392,463.335907 705.599548,462.619507 705.283813,461.591309 
	C705.115234,461.022766 705.051392,460.794189 704.903687,460.219025 
	C704.770325,459.639496 704.720703,459.406586 704.599915,458.823608 
	C703.689331,454.721588 701.942017,451.246368 701.075745,447.112915 
	C700.744629,445.762543 700.651306,444.753021 700.563599,443.350952 
	C700.646606,441.248444 699.695557,439.798248 699.361572,438.195953 
	C699.083679,436.862793 698.970581,435.391541 700.435608,434.744385 
	C702.129089,433.996307 703.280212,435.126526 704.051147,436.481689 
	C705.274658,438.632599 705.792786,441.089417 706.988892,443.693542 
	C707.446289,444.824005 707.752319,445.585541 708.101013,446.653381 
	C707.983826,448.866821 708.877014,450.386841 709.795532,451.970459 
	C711.325867,454.608887 712.033325,457.409882 710.280396,460.667786 
	C711.926453,467.426758 713.813660,473.872986 715.851685,480.660797 
	C716.658875,482.021301 716.718872,483.127930 716.248169,484.634705 
	C716.115295,485.311035 716.300781,485.571899 716.784058,485.887085 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M705.793762,459.766876 
	C705.996521,459.991821 706.023987,460.392914 706.015991,460.593750 
	C706.372009,461.314697 706.478455,461.922607 706.798279,462.759979 
	C707.656555,466.063721 708.314941,469.133392 708.987915,472.602051 
	C707.061340,470.290863 706.877502,474.065125 705.332886,473.941528 
	C705.324707,471.325653 704.579651,468.883362 703.332886,466.224792 
	C701.632446,464.513092 701.697327,462.475098 700.999146,460.366638 
	C700.664551,459.219635 700.361511,458.429016 699.991699,457.327759 
	C697.154846,453.616638 695.666504,449.656921 694.895752,445.024048 
	C695.146973,443.623199 695.678467,442.802032 696.822266,441.982635 
	C697.362854,441.715668 697.591187,441.637848 698.176208,441.553284 
	C698.750977,441.631439 698.969116,441.716339 699.430664,442.048035 
	C700.557495,443.595154 700.846436,445.170197 701.841431,446.766479 
	C703.361877,450.771027 704.666626,454.527863 705.964539,458.672302 
	C705.835571,459.220551 705.713318,459.381226 705.793762,459.766876 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M709.931030,487.642944 
	C706.072571,485.836060 706.298157,482.161011 706.716187,478.257568 
	C708.519409,480.909363 709.172485,484.100250 709.931030,487.642944 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M719.002441,555.998779 
	C719.866272,556.424500 720.710327,556.873962 720.689148,558.021057 
	C720.687317,558.121277 720.444763,558.318115 720.345764,558.299683 
	C719.416809,558.127136 719.201538,557.379944 719.017212,556.303223 
	C718.995117,556.004883 719.000000,556.000000 719.002441,555.998779 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M741.082275,438.941162 
	C741.283081,438.580353 741.363586,438.334656 741.285889,438.264801 
	C740.010986,437.118256 737.957825,436.568420 738.171936,434.300049 
	C738.206055,433.938721 738.798584,433.288208 738.989197,433.336670 
	C740.800964,433.797394 742.917053,433.782471 743.157898,436.527557 
	C743.290161,438.035461 744.426453,437.537506 745.360229,437.039856 
	C746.829651,436.256775 747.034363,437.164795 746.898132,438.309174 
	C746.753479,439.524170 745.296570,441.045929 745.015564,440.531342 
	C743.925171,438.534668 740.609741,443.879944 741.082275,438.941162 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M715.884888,391.728394 
	C715.229492,390.251953 715.569702,388.985443 716.690430,388.219360 
	C717.497986,387.667389 718.174072,388.294556 718.404663,389.569702 
	C718.466675,389.873535 718.533447,390.129547 718.613464,390.234894 
	C718.715942,391.434082 717.866638,391.961853 716.875549,392.721710 
	C716.368774,392.496460 716.200989,392.167206 715.884888,391.728394 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M742.255493,424.988037 
	C743.118774,426.341400 742.942017,427.107483 741.568726,427.160583 
	C740.974426,427.183502 740.157349,427.073944 740.162720,426.254730 
	C740.170044,425.146576 740.390259,424.039032 742.255493,424.988037 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M716.948547,392.832764 
	C716.491394,391.710663 717.140747,391.094940 718.384644,390.512451 
	C718.898376,391.519623 718.820984,392.548462 716.948547,392.832764 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M747.870972,456.052368 
	C747.912354,456.033600 747.829590,456.071136 747.870972,456.052368 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M748.001892,456.002563 
	C748.087280,455.968414 748.170837,455.931702 748.124756,455.945496 
	C747.995117,455.996063 748.000000,456.000000 748.001892,456.002563 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M765.505249,480.530457 
	C764.609863,480.896851 764.076050,480.631226 763.811462,479.990875 
	C763.738525,479.814301 763.944275,479.321411 764.093262,479.287659 
	C764.927673,479.098633 765.656250,479.201019 765.505249,480.530457 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M728.975464,674.973999 
	C728.025635,674.957520 726.960205,675.347107 726.367554,674.311707 
	C726.233276,674.076965 726.564941,673.274963 726.858704,673.150330 
	C727.880127,672.717041 728.721619,672.953247 728.948120,674.587402 
	C728.946716,674.951538 728.999390,675.000000 728.975464,674.973999 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M679.084534,711.447876 
	C678.904907,712.527771 678.691589,713.442627 678.478271,714.357483 
	C678.079224,714.231079 677.418030,714.204163 677.325928,713.961304 
	C676.900513,712.839478 676.930542,711.757202 678.558716,711.064941 
	C678.886047,710.988892 679.050781,711.282837 679.084534,711.447876 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M703.045410,692.406738 
	C703.775635,692.798096 703.754089,693.192322 703.254639,693.539734 
	C703.147583,693.614136 702.898193,693.616943 702.795227,693.541809 
	C702.293762,693.176147 702.322815,692.780945 703.045410,692.406738 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M691.673462,711.869324 
	C691.681213,712.026978 691.450684,712.200867 691.111450,712.231567 
	C691.002686,712.088379 691.435181,711.885620 691.673462,711.869324 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M683.298706,717.030945 
	C682.989441,717.009094 683.066284,716.518188 683.133057,716.278687 
	C683.592712,716.026123 683.985657,716.013123 684.674194,715.998535 
	C684.515869,716.348877 684.061951,716.700806 683.298706,717.030945 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M694.918823,711.514099 
	C696.622742,711.041443 698.523621,710.705994 697.272644,713.787964 
	C696.494690,714.345093 695.659180,715.072815 695.514526,714.954651 
	C694.513306,714.136963 695.207275,712.887451 694.918823,711.514099 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M695.004578,726.996582 
	C694.894043,726.861023 694.778931,726.728882 694.663818,726.596680 
	C694.738831,726.647583 694.813782,726.698547 694.940979,726.879272 
	C694.993164,727.009155 695.000000,727.000000 695.004578,726.996582 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M686.008850,724.994324 
	C686.121033,725.124451 686.224304,725.260254 686.327637,725.396057 
	C686.258362,725.346741 686.189087,725.297485 686.054199,725.132935 
	C685.988586,725.017700 686.000000,725.000000 686.008850,724.994324 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M512.953674,271.706787 
	C512.893188,272.084106 512.430481,272.086975 512.198914,272.095886 
	C510.884766,271.133881 511.092987,269.211823 509.213623,268.448822 
	C509.974091,266.565399 511.677948,266.660126 512.887695,267.334717 
	C514.694519,268.342224 512.708557,269.975647 512.953674,271.706787 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M443.484253,281.029724 
	C443.314850,280.503601 443.433136,279.923248 443.797424,279.169281 
	C444.483063,279.152466 445.277985,279.267151 445.302429,279.473206 
	C445.425598,280.511902 444.524048,280.706268 443.484253,281.029724 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M443.031067,281.778320 
	C442.824341,281.829834 442.634979,281.682617 442.445587,281.535400 
	C442.533752,281.444824 442.621887,281.354218 442.897644,281.222900 
	C443.085266,281.182220 443.048462,281.579559 443.031067,281.778320 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M437.992249,293.428711 
	C439.880585,292.689087 440.457855,291.162476 441.081177,289.292358 
	C441.220978,288.710327 441.374969,288.521729 441.725372,288.261261 
	C442.214264,287.668976 442.673004,287.487427 443.540497,287.882111 
	C443.830475,288.158173 443.922241,288.165710 443.883972,288.140137 
	C444.322021,288.409180 444.525696,288.834473 444.223328,289.691589 
	C444.552490,294.659027 440.418152,295.304871 437.831573,297.349792 
	C436.348572,296.005493 438.032379,294.922577 437.992249,293.428711 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M444.258667,289.991425 
	C443.926849,289.576080 443.864136,289.159241 443.823547,288.428497 
	C444.219116,288.525513 444.592529,288.936401 445.014862,289.644287 
	C444.885101,289.957489 444.706451,289.973724 444.258667,289.991425 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M425.839508,299.706482 
	C425.644348,299.850342 425.393494,299.706299 425.091949,299.268036 
	C425.277832,299.144562 425.525360,299.292816 425.839508,299.706482 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M443.884033,287.835754 
	C443.397400,288.169617 442.964355,288.181061 442.202820,288.171570 
	C442.052032,287.566895 442.229736,286.983124 442.641510,286.205566 
	C443.229584,286.512299 443.583588,287.012817 443.884033,287.835754 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M438.847290,278.046539 
	C438.493591,278.190369 438.031952,278.022797 437.270447,277.445892 
	C436.970612,277.036560 436.993927,277.009125 437.006714,276.996033 
	C437.592743,277.233643 438.166046,277.484375 438.847290,278.046539 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M527.169312,249.555145 
	C522.923035,255.039902 520.794312,255.240952 517.965210,251.273956 
	C516.084106,248.636261 517.474670,247.903809 519.435364,247.518311 
	C521.903076,247.033127 524.637939,246.076385 527.014893,248.918457 
	C527.347168,249.148071 527.165222,249.399872 527.169312,249.555145 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M521.907227,260.184021 
	C523.259827,256.391205 526.228271,258.305359 528.700378,257.924652 
	C527.675903,259.517792 526.434021,261.054413 525.039246,262.436829 
	C524.303467,263.166077 524.029846,265.051483 522.628235,264.333160 
	C521.213501,263.608063 520.807434,262.063416 521.907227,260.184021 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M513.838135,260.115265 
	C514.177368,258.605194 513.298767,256.669556 515.253418,256.265808 
	C517.361633,255.830322 517.923279,257.482422 517.981812,259.659607 
	C517.304626,260.918365 516.548584,261.741302 515.974854,262.676117 
	C515.493896,263.459747 515.389832,264.749329 514.054932,264.182159 
	C513.037231,263.749756 513.048157,262.625488 512.987000,261.294464 
	C512.965698,260.942871 512.996887,260.971039 512.980774,260.980042 
	C513.245361,260.805328 513.481201,260.575562 513.838135,260.115265 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M548.752197,253.975510 
	C548.839783,254.323792 548.706970,254.747437 548.095215,254.168900 
	C548.167297,253.993179 548.334412,253.989426 548.752197,253.975510 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M514.784058,271.974548 
	C514.795288,272.160156 514.444580,272.112366 514.269043,272.101135 
	C514.217834,271.759308 514.276367,271.279968 514.784058,271.974548 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M513.771057,260.042694 
	C513.813904,260.211609 513.623596,260.492371 513.198975,260.881104 
	C513.155762,260.710876 513.346802,260.432709 513.771057,260.042694 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M408.820374,618.658447 
	C411.438934,620.574646 414.345306,622.295410 417.610474,624.060059 
	C420.214752,623.926392 421.967651,625.055664 423.923889,626.527161 
	C424.429565,627.548523 424.177124,628.163269 423.323822,628.850403 
	C422.608185,630.008972 418.842896,626.679016 420.763062,630.783997 
	C421.625854,632.628479 419.745880,632.048279 419.242645,631.873962 
	C417.726959,631.349121 416.312469,630.532227 414.504272,629.701904 
	C405.685974,623.193298 396.123657,618.406921 388.031036,611.476562 
	C364.475891,591.304138 349.718475,565.650513 341.006378,536.214966 
	C340.585571,534.793335 340.294769,533.333252 339.946106,531.494141 
	C340.035767,528.945862 339.396637,526.302490 343.375488,526.789185 
	C346.259613,536.229614 349.679962,545.077881 354.978577,553.452454 
	C358.522430,560.712402 362.546143,567.314148 367.267944,573.882019 
	C367.622589,574.280884 367.761871,574.439148 368.102905,574.832458 
	C368.436218,575.222229 368.567688,575.376892 369.091003,575.984741 
	C369.611725,576.588928 369.740662,576.739929 370.056244,577.124634 
	C370.358429,577.522522 370.473969,577.686707 370.745605,578.109985 
	C371.097443,578.728516 371.290436,579.089478 371.629761,579.733032 
	C371.863678,580.213379 371.948456,580.412659 372.164032,580.914917 
	C380.664581,595.019592 392.042511,605.904419 405.114532,615.167480 
	C406.513641,616.158936 408.598389,616.379272 408.820374,618.658447 
z"
    />
    <path
      fill="#1E1D1C"
      opacity="1.000000"
      stroke="none"
      d="
M343.623962,526.671570 
	C341.010925,526.604614 341.078308,528.980591 340.104736,530.781616 
	C337.072723,522.628540 334.932739,513.835938 334.994507,505.011749 
	C335.042664,498.126801 332.955658,491.604218 333.203461,484.815704 
	C333.470306,477.507568 333.729095,470.199158 334.069641,462.455627 
	C334.843719,462.651093 335.539551,463.281799 336.520447,463.959961 
	C335.922913,473.788391 335.521332,483.598724 337.023682,493.326141 
	C338.734680,504.404053 340.228790,515.544373 343.623962,526.671570 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M320.901184,463.304169 
	C319.318237,458.979126 321.586029,453.435211 316.011292,450.781342 
	C314.615967,450.117096 315.430420,448.125824 315.962982,446.393036 
	C318.406799,443.894836 320.044373,446.942780 322.449585,446.920471 
	C323.604492,446.705750 324.444580,446.531525 325.512970,445.393127 
	C326.770355,445.020416 327.490173,445.009430 328.569916,445.002991 
	C327.927551,454.354370 326.925262,463.701233 325.866821,473.481201 
	C323.334381,473.565796 323.418121,470.401672 320.976685,469.524597 
	C320.165710,468.589172 319.997772,467.749847 319.992493,466.558411 
	C320.078186,465.861847 320.169952,465.478577 320.377686,464.838928 
	C320.574280,464.231018 320.703247,463.893677 320.901184,463.304169 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M320.653564,468.760559 
	C324.284332,468.649902 325.105011,470.741913 325.598816,473.643005 
	C325.843597,475.037994 325.876556,476.161591 325.784851,477.690033 
	C324.995453,478.070343 324.330719,478.045837 323.333008,478.005585 
	C321.569275,477.841187 320.462280,478.533234 319.131592,479.550354 
	C318.106995,479.528198 317.362396,479.327209 316.353577,478.989502 
	C315.145569,475.546997 317.604462,472.951019 317.942749,469.535522 
	C318.562927,468.371918 319.378021,468.273590 320.653564,468.760559 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M362.541138,413.749146 
	C361.393372,417.464844 359.964691,421.023560 358.241333,424.781799 
	C356.524384,426.409149 354.989166,427.613007 352.475098,427.436890 
	C351.982544,427.107941 351.837769,426.907806 351.838684,426.367004 
	C352.322144,424.950012 352.659943,423.873688 353.048279,422.459991 
	C355.379211,422.015198 356.854095,420.957672 357.340240,418.340515 
	C357.595062,417.830933 357.697144,417.576660 357.748291,417.304810 
	C357.798767,417.036469 357.731415,417.111115 357.385193,417.780823 
	C356.161621,419.297241 355.956116,421.488525 353.452911,421.070831 
	C353.217712,418.784058 353.144165,416.795319 353.057617,414.374786 
	C353.049530,413.769867 353.054443,413.596710 353.073669,413.163940 
	C356.074890,412.095123 352.987762,409.847443 354.116760,408.233826 
	C355.353027,407.249542 356.708649,405.284515 357.607941,408.189453 
	C358.375458,410.668732 360.619476,411.869080 362.541138,413.749146 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M381.987701,378.746002 
	C382.063995,379.414062 381.990997,379.687836 381.574219,379.971771 
	C379.802979,380.214691 378.261414,380.208008 377.847778,378.282776 
	C377.444641,376.406250 378.859283,375.716431 380.615845,375.044769 
	C381.298859,376.110962 381.633789,377.248718 381.987701,378.746002 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M365.819580,391.549988 
	C365.825134,390.590698 366.284485,389.967651 367.203857,390.266815 
	C367.473358,390.354523 367.572723,390.964966 367.750092,391.335846 
	C367.192444,391.460693 366.634796,391.585541 365.819580,391.549988 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M378.718933,369.145935 
	C379.106537,370.214386 378.490875,371.049652 377.273315,371.847473 
	C376.909363,370.765594 377.530304,369.945435 378.718933,369.145935 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M378.997559,368.996216 
	C379.355927,368.728271 379.718597,368.466125 380.081268,368.203979 
	C380.657257,369.007446 379.839325,368.849945 379.263123,369.022827 
	C379.014709,369.015289 379.001801,369.002014 378.997559,368.996216 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M392.316101,356.336853 
	C392.085999,355.552307 392.608490,355.037506 393.943542,354.957123 
	C393.780487,355.395844 393.206055,355.789276 392.316101,356.336853 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M381.014679,375.020447 
	C381.108429,374.994293 381.186859,374.950714 381.114624,374.940155 
	C380.963928,374.973175 380.999359,375.003021 381.014679,375.020447 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M375.825073,373.045013 
	C375.901794,373.116211 375.734070,374.034973 375.825073,373.045013 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M375.962769,372.955139 
	C376.014313,372.587067 376.196777,372.321655 376.708984,372.036346 
	C376.937408,371.946838 376.989838,371.993042 377.014038,372.019531 
	C376.946228,372.370605 376.764069,372.632507 376.274872,372.939941 
	C376.058014,373.048157 375.992493,372.988159 375.962769,372.955139 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M381.598694,366.469482 
	C381.656433,366.555176 381.430359,366.589478 381.430359,366.589478 
	C381.430359,366.589478 381.540985,366.383759 381.598694,366.469482 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M383.084961,364.188690 
	C383.154419,363.979065 383.335327,363.958557 383.780457,363.864990 
	C383.907715,364.198395 383.858368,364.731628 383.084961,364.188690 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M359.259155,396.344299 
	C359.529755,396.353058 359.590515,396.564880 359.651245,396.776672 
	C359.450623,396.700226 359.249969,396.623779 359.259155,396.344299 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M684.248779,371.930206 
	C682.726624,371.761139 681.424622,371.251160 679.816895,370.569702 
	C679.217468,370.571899 678.903259,370.688965 678.685181,370.965210 
	C680.715454,373.480804 682.790649,375.692474 682.626526,378.994812 
	C682.624146,379.042084 682.389893,379.107727 682.262329,379.111298 
	C682.104126,379.115784 681.944397,379.064514 681.417847,378.932800 
	C675.711121,377.007843 672.642761,372.221527 668.314575,369.081268 
	C666.589966,367.829926 665.069946,366.322021 663.020630,364.916504 
	C662.566284,364.544647 662.393921,364.382874 662.004700,363.939026 
	C661.687866,363.442291 661.587769,363.227570 661.439575,362.661621 
	C662.955078,358.039948 665.325562,362.103424 667.278870,361.802826 
	C665.105225,361.156921 663.400879,360.008026 662.427002,357.519379 
	C662.367065,355.603333 663.201660,354.423279 664.446045,353.068512 
	C666.154175,352.472809 667.065125,353.152527 667.797363,354.677979 
	C673.231201,360.099274 678.606445,365.157349 684.166382,370.500854 
	C684.390320,371.053925 684.429565,371.321625 684.248779,371.930206 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M697.646057,354.067657 
	C698.086365,354.899994 698.338379,355.678467 699.662720,355.968994 
	C697.614197,358.995392 701.212891,362.382446 699.099609,365.680420 
	C697.584290,368.045258 696.884338,370.955872 694.268799,372.977905 
	C691.932983,374.259125 690.581787,373.221436 689.179688,371.424835 
	C688.846802,370.201141 688.681641,369.318237 688.255127,368.217072 
	C687.588806,366.102234 685.115723,364.774933 686.734985,362.186707 
	C689.532959,358.162720 694.156738,357.113281 697.646057,354.067657 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M659.296387,342.315521 
	C659.113220,343.696716 659.098450,344.811218 659.147583,346.339691 
	C659.070801,346.947876 658.930176,347.142090 658.449097,347.452667 
	C657.606506,347.585876 657.034241,347.605469 656.666260,347.304871 
	C649.732178,341.641632 641.575195,337.755768 634.421265,331.835693 
	C633.702637,329.615234 631.702576,328.260712 632.290955,325.764587 
	C634.399414,324.709290 635.930359,325.743835 637.809326,326.551849 
	C638.276978,326.780914 638.460205,326.876495 638.910034,327.122498 
	C639.525696,327.474792 639.875427,327.675262 640.493530,328.009094 
	C640.936829,328.239258 641.112488,328.334839 641.538330,328.596680 
	C642.076965,329.039093 642.357056,329.323578 642.847778,329.819427 
	C643.066650,330.022583 642.982239,330.030823 642.973633,329.989319 
	C643.270813,330.263214 643.644775,330.442078 644.397278,330.538605 
	C644.916138,330.655365 645.115051,330.737000 645.589600,330.986450 
	C647.538513,332.140686 648.934570,333.504974 650.800781,334.774445 
	C651.060669,334.966339 651.002319,334.995728 651.014648,334.965454 
	C651.686401,336.480835 653.291382,336.795959 654.784485,337.782532 
	C655.057922,337.952576 655.002319,337.999237 655.022583,337.969177 
	C656.038391,339.062195 657.310913,339.826202 658.809753,340.815491 
	C659.075195,340.989929 659.009583,340.997711 659.020630,340.966553 
	C659.353394,341.237518 659.489502,341.611877 659.296387,342.315521 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M688.851929,371.379730 
	C690.416138,371.720276 691.820312,372.356537 693.569397,373.017334 
	C694.523926,376.905762 691.983765,375.051758 690.046875,374.994812 
	C689.226440,375.394531 688.729675,375.476746 687.806030,375.132660 
	C686.389099,374.246033 685.351074,373.495453 684.170776,372.507996 
	C684.068420,372.020569 684.108154,371.770020 684.249512,371.152893 
	C685.927124,370.452698 686.924072,372.945892 688.851929,371.379730 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M686.672302,361.949158 
	C685.884338,364.030487 688.900818,365.296387 688.012878,367.650452 
	C686.806091,366.928375 685.613342,365.863495 684.432495,364.785583 
	C683.671875,364.091278 681.910950,363.976166 682.546509,362.429321 
	C683.403076,360.344543 685.019348,361.831512 686.672302,361.949158 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M659.097900,346.886292 
	C662.005737,347.063263 663.771790,348.734985 664.876709,351.742859 
	C661.358582,352.843201 660.041382,350.447693 658.750793,347.649963 
	C658.753296,347.281036 658.984253,347.018951 659.097900,346.886292 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M651.001221,334.733429 
	C648.210266,335.313812 646.732361,333.052429 644.882202,331.305908 
	C647.071716,329.882019 648.336548,331.103516 649.711548,332.920593 
	C649.977600,333.575714 650.166382,333.795593 650.707642,333.960449 
	C650.926819,334.019958 650.941711,334.500519 651.001221,334.733429 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M668.130127,354.864685 
	C667.074768,354.477295 666.293457,353.913422 665.230896,353.258301 
	C664.847229,353.032501 664.744812,352.897980 664.689941,352.514313 
	C664.737427,352.265198 664.967468,352.026855 665.081299,351.902710 
	C666.783813,352.175964 669.137390,351.729889 668.130127,354.864685 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M659.003540,340.660522 
	C656.954956,341.324768 655.831055,339.789886 654.790527,338.255615 
	C656.767273,338.040588 657.740662,339.362976 659.003540,340.660522 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M654.975464,337.634583 
	C652.788513,338.582306 651.836487,336.671387 650.745605,335.252960 
	C652.315613,335.729004 653.604248,336.522827 654.975464,337.634583 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M659.457764,342.696533 
	C658.965881,342.197388 658.803772,341.812531 658.836670,341.181549 
	C659.972229,341.390472 660.912842,341.845551 661.986816,342.592072 
	C662.089417,343.076996 662.058716,343.270477 661.807800,343.658508 
	C660.987549,343.505676 660.387512,343.158264 659.457764,342.696533 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M664.070557,345.315369 
	C665.362183,344.762299 666.017944,345.694214 666.914185,346.682190 
	C667.014465,346.986694 666.729126,347.202850 666.588989,347.314117 
	C665.637878,346.873505 664.826965,346.321594 664.070557,345.315369 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M663.590698,329.247314 
	C663.443970,329.129578 663.424011,328.929474 663.403992,328.729401 
	C663.508484,328.874603 663.612915,329.019806 663.590698,329.247314 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M663.846802,345.151123 
	C663.411743,345.129272 663.079773,345.021332 662.574219,344.711456 
	C662.450073,344.318634 662.499634,344.127747 662.842407,343.898224 
	C663.433044,344.022583 663.730408,344.185608 664.076416,344.604858 
	C664.125061,344.861053 663.949951,345.065002 663.846802,345.151123 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M662.588501,344.351746 
	C662.180054,344.418671 661.985352,344.289001 661.702026,343.986816 
	C661.662476,343.683868 661.737427,343.514679 661.966248,343.114502 
	C662.407532,343.047974 662.694885,343.212463 663.058960,343.618225 
	C663.135681,343.859558 662.776367,344.193909 662.588501,344.351746 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M667.810486,347.983551 
	C668.283569,348.208923 668.523621,348.465210 668.763672,348.721497 
	C668.494019,348.776611 668.224304,348.831757 667.750732,348.685242 
	C667.546875,348.483612 667.577454,348.014496 667.810486,347.983551 
z"
    />
    <path
      fill="#E2E2E1"
      opacity="1.000000"
      stroke="none"
      d="
M667.671814,348.351593 
	C667.269592,348.279236 666.992310,348.074890 666.581909,347.647980 
	C666.448792,347.425415 666.731506,347.205902 666.872986,347.096283 
	C667.320557,347.134644 667.626648,347.282623 667.988159,347.691589 
	C668.043518,347.952606 667.796753,348.219574 667.671814,348.351593 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M641.005676,284.007111 
	C640.913635,284.043365 640.815979,284.072510 640.851807,284.045471 
	C640.985352,283.989319 641.000000,284.000000 641.005676,284.007111 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M534.962769,297.756775 
	C534.634644,297.853912 534.221619,297.698547 534.221619,297.698547 
	C534.221619,297.698547 533.780273,297.699127 533.574219,297.777771 
	C532.351746,297.515747 531.326172,297.330109 529.967285,297.768372 
	C528.644653,297.827545 527.624146,297.792023 526.229370,297.670898 
	C525.711487,294.660187 523.584351,291.453003 527.309265,289.100464 
	C528.419739,288.399139 529.040161,288.435608 529.861755,289.699982 
	C529.111816,290.715210 529.529114,291.590851 529.685730,292.738464 
	C529.509338,293.270477 529.417847,293.521851 529.393127,293.791748 
	C529.366699,294.080933 529.412903,294.004456 529.776733,293.389343 
	C531.993286,293.052979 533.311707,291.891022 534.703735,290.203827 
	C535.449158,290.006989 535.886169,289.974823 536.650574,289.912292 
	C537.542908,289.788269 538.005188,289.478821 538.729370,289.072968 
	C540.112793,289.104431 541.412048,288.843140 542.014404,290.638550 
	C541.749451,292.918549 541.508972,294.867493 541.210388,297.102844 
	C540.021301,297.505157 539.025330,297.636810 537.652649,297.826233 
	C536.639038,297.590637 535.984802,297.449707 534.962769,297.756775 
z"
    />
    <path
      fill="#F1F6F7"
      opacity="1.000000"
      stroke="none"
      d="
M553.233398,301.139801 
	C553.301697,300.054382 553.632874,299.290710 553.986023,298.212036 
	C556.830139,297.792511 559.652161,297.688049 562.796143,297.559448 
	C563.117981,297.535339 563.489014,297.618134 563.679077,297.612915 
	C565.007874,297.593140 566.146606,297.578552 567.688599,297.414856 
	C568.218994,297.382324 568.346313,297.498901 568.702881,297.495300 
	C572.995483,299.003082 577.713867,296.965881 581.831421,299.858154 
	C582.421814,300.622070 582.507385,301.204407 582.033813,302.176147 
	C576.775757,304.468811 571.636902,303.085968 566.151978,303.284943 
	C561.789978,301.655487 557.486328,302.635315 553.233398,301.139801 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M541.409851,297.507538 
	C540.348633,295.291870 538.948242,293.157196 541.771362,291.143311 
	C542.907410,291.082184 543.726501,291.226990 544.551331,291.277161 
	C547.628235,291.464386 548.665100,292.904572 548.018555,296.538391 
	C548.010193,296.921021 548.345398,297.282990 548.184448,297.469910 
	C547.549500,297.690277 547.075439,297.723755 546.249756,297.692505 
	C545.898132,297.627747 545.497437,297.566711 545.294922,297.575745 
	C543.995789,297.603699 542.899048,297.622620 541.409851,297.507538 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M582.110474,302.646820 
	C582.061523,301.745422 582.081726,301.268616 582.087158,300.435120 
	C582.530334,300.056366 582.988159,300.034271 583.791931,299.985382 
	C587.417664,300.677277 590.697449,301.395966 594.370239,302.117126 
	C598.061707,302.930817 602.311157,301.253754 603.963745,306.077820 
	C596.290039,306.905029 589.103394,305.831818 582.110474,302.646820 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M529.380371,294.005188 
	C529.716614,292.750214 527.676514,291.220947 529.689514,290.031281 
	C530.642639,292.509155 531.632385,291.838928 532.744507,290.281555 
	C533.274597,289.539185 533.114685,286.973602 534.969788,289.723755 
	C534.540771,293.049286 532.598694,294.119141 529.380371,294.005188 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M518.843384,298.752014 
	C517.171143,298.787354 515.947327,298.802734 514.359497,298.766235 
	C514.141907,297.303986 513.597412,295.485321 515.742737,295.141266 
	C517.284363,294.894043 518.852966,294.916779 518.966431,297.512878 
	C519.082947,298.171112 519.187378,298.451569 518.843384,298.752014 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M556.926025,290.900146 
	C557.295776,291.206116 557.423645,291.317535 557.516602,291.452942 
	C558.480042,292.856354 561.638916,290.603088 561.465820,293.750519 
	C561.389282,295.141724 560.174316,295.759155 558.968201,295.429138 
	C556.560852,294.770416 555.614014,293.176147 556.926025,290.900146 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M571.072937,292.280945 
	C572.131104,293.761169 571.045837,294.478241 570.233887,295.532501 
	C569.103516,293.983246 570.129272,293.188995 571.072937,292.280945 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M538.822144,288.751526 
	C538.811829,289.903259 538.839294,291.081512 537.140076,290.125854 
	C537.160339,288.964172 537.245117,287.925537 538.822144,288.751526 
z"
    />
    <path
      fill="#F1F6F7"
      opacity="1.000000"
      stroke="none"
      d="
M518.824585,298.835327 
	C518.481812,298.618225 518.568726,298.464478 518.817139,298.100708 
	C520.370117,297.917114 521.761597,297.943604 523.564087,298.044525 
	C522.581299,299.468079 520.868591,298.885895 518.824585,298.835327 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M561.675232,289.879028 
	C561.185791,290.648102 560.708374,290.184540 560.737061,289.812622 
	C560.793091,289.085327 561.278687,289.369263 561.675232,289.879028 
z"
    />
    <path
      fill="#F1F6F7"
      opacity="1.000000"
      stroke="none"
      d="
M512.711914,299.126099 
	C512.380859,299.431458 511.793243,299.672485 510.872620,299.876892 
	C510.835571,299.604492 511.131592,299.368774 511.690186,299.015381 
	C512.120239,298.952362 512.287842,299.007050 512.711914,299.126099 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M520.999634,293.000610 
	C521.422668,293.122345 521.846008,293.243469 522.269348,293.364563 
	C521.951599,294.035950 521.588928,293.691467 521.114502,293.204651 
	C521.000000,292.998779 521.000000,293.000000 520.999634,293.000610 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M551.465576,295.453003 
	C551.522034,295.418518 551.409180,295.487518 551.465576,295.453003 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M549.528259,294.552612 
	C549.471924,294.587097 549.584595,294.518127 549.528259,294.552612 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M548.260254,297.719299 
	C548.023499,297.656830 547.997253,297.290100 548.003723,297.105560 
	C548.261047,297.010803 548.494812,297.133301 548.831665,297.444244 
	C548.951904,297.599976 548.497070,297.781799 548.260254,297.719299 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M563.808472,297.498260 
	C563.869080,297.607666 563.477600,297.716675 563.297791,297.626007 
	C563.279663,297.289490 563.489624,297.240662 563.808472,297.498260 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M568.847656,297.292969 
	C568.932251,297.375122 568.493469,297.459473 568.292603,297.362610 
	C568.306030,297.122711 568.529785,297.104401 568.847656,297.292969 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M352.410217,446.647308 
	C351.743683,452.031555 350.933807,457.111450 350.006195,462.582550 
	C347.921844,465.592926 346.814423,468.483490 346.757843,471.812653 
	C346.721405,473.954742 346.282959,476.048157 343.858398,477.420593 
	C339.106354,476.656219 341.965790,472.826752 340.567352,470.313263 
	C340.970459,461.906860 341.475555,453.845215 343.087402,445.481873 
	C346.092865,442.801849 345.789368,438.064240 350.012024,436.402618 
	C353.778717,435.741974 353.563538,438.041077 353.636475,440.562744 
	C353.786469,441.148804 353.739960,441.441315 353.548431,442.153717 
	C351.153748,443.265961 352.199249,444.951874 352.410217,446.647308 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M340.294250,470.419067 
	C342.410339,470.552307 341.885254,472.232544 342.000580,473.635803 
	C342.104034,474.894775 341.743652,476.369110 343.887115,476.893677 
	C344.519806,476.889099 344.764313,476.851379 345.296936,477.050018 
	C346.194946,478.097351 346.521606,479.038605 346.920502,480.352814 
	C346.431763,486.363495 349.036987,491.982208 347.023163,497.963074 
	C346.723145,498.571564 346.606384,498.873413 346.516510,499.186218 
	C346.332336,499.826935 346.342743,498.973114 346.344482,499.639771 
	C346.629150,499.629730 346.494659,499.337250 346.796112,498.957977 
	C347.386932,498.528473 347.818878,498.496124 348.522461,498.800537 
	C348.726074,506.228851 350.711548,513.179077 351.397339,520.288940 
	C351.522705,521.588623 351.619354,522.891052 351.663574,524.615967 
	C348.353119,525.875793 348.118439,523.299866 347.594238,521.326904 
	C346.740540,518.113586 346.371887,514.788696 345.611938,511.547913 
	C345.149353,509.575195 344.330353,512.264038 343.483582,511.215332 
	C341.127686,497.720154 339.780487,484.363007 340.294250,470.419067 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M343.162109,511.429993 
	C344.512634,510.920166 345.027740,509.867615 345.308624,508.370056 
	C347.937744,512.872864 348.209229,517.370789 348.967102,521.719910 
	C349.213806,523.135498 349.463959,524.309509 351.243256,524.972168 
	C354.166687,534.322693 355.748932,543.870789 359.134125,553.316467 
	C359.563141,554.492554 359.166931,555.152039 358.491577,556.060364 
	C355.977295,556.695679 355.789215,555.038086 355.194763,553.256897 
	C354.959930,552.960754 355.039185,552.952698 355.056610,552.987915 
	C354.357758,548.427734 351.617584,544.622009 350.120453,540.322693 
	C346.888489,531.041382 344.679199,521.524414 343.162109,511.429993 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M350.174438,436.569580 
	C348.234833,439.311249 346.221466,441.600098 345.523376,444.693634 
	C345.425201,445.128723 345.153687,447.783447 343.392822,445.214996 
	C344.196716,439.273468 345.232269,433.478424 346.400879,427.336029 
	C348.087860,426.678253 349.641785,426.367798 351.590057,426.041870 
	C352.000732,426.203979 352.017090,426.381653 352.070007,426.824402 
	C352.730927,430.383575 351.111603,433.249023 350.174438,436.569580 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M548.214417,265.989105 
	C549.008484,264.959991 550.182434,264.034760 551.677979,263.059387 
	C552.465454,263.397461 553.015930,263.702637 553.176514,264.595825 
	C553.205566,265.469147 553.323975,266.048828 553.700806,266.864990 
	C553.959229,267.101440 553.967346,267.415436 553.994019,267.570190 
	C552.813293,269.468079 553.541687,272.056458 551.275391,273.698792 
	C550.839600,274.853668 550.880981,276.253632 549.675171,276.066437 
	C548.511963,275.885834 549.168030,274.491974 549.014160,273.293518 
	C550.332092,270.571899 551.010803,268.234222 548.214417,265.989105 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M543.580994,266.033020 
	C544.017212,266.032501 544.213623,266.043701 544.693481,266.136597 
	C544.321533,266.841583 543.640198,267.311127 542.759033,266.590973 
	C542.953125,266.408905 543.147156,266.226837 543.580994,266.033020 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M546.696411,272.066559 
	C546.520203,272.608246 546.024414,273.184784 545.260132,273.872742 
	C545.077271,273.079865 545.343872,272.308685 546.696411,272.066559 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M541.454163,268.536072 
	C541.423706,268.483673 541.484619,268.588440 541.454163,268.536072 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M702.273926,364.823059 
	C702.073486,363.562469 701.499634,361.889221 703.690308,360.894684 
	C703.553162,362.062134 703.050781,363.343231 702.273926,364.823059 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M710.013062,373.017944 
	C709.746216,373.584717 709.423645,374.099701 709.101074,374.614685 
	C708.123230,373.890717 709.252747,373.611420 709.628479,372.997437 
	C709.842163,372.866913 709.957397,372.966125 710.013062,373.017944 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M701.264893,367.247620 
	C701.130493,366.780029 701.260742,366.071075 701.704956,365.175049 
	C701.855835,365.660736 701.692688,366.333496 701.264893,367.247620 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M701.076782,368.835754 
	C700.379150,369.008911 700.107666,368.596405 700.779785,367.728760 
	C701.050110,367.828491 701.075867,368.115997 701.076782,368.835754 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M344.439880,372.662109 
	C349.544159,368.719482 351.973328,362.978333 356.136597,358.659119 
	C357.852692,356.878723 359.151306,354.681946 362.456757,355.016693 
	C355.804199,364.630829 350.447693,375.252869 344.116333,385.255737 
	C341.062561,390.080383 336.804230,394.459503 331.432220,397.755676 
	C331.390076,396.787842 331.657745,395.724640 331.999329,394.333130 
	C335.048645,389.079651 338.024109,384.154480 340.998138,378.895691 
	C341.507416,376.356171 342.564087,374.455750 344.439880,372.662109 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M334.057739,402.571716 
	C332.411011,402.816772 331.073761,402.483368 330.885681,400.283203 
	C330.808411,399.752808 330.751099,399.578125 330.894897,399.214722 
	C331.096008,399.026031 331.038177,399.089966 331.066010,399.123657 
	C334.063019,398.181030 334.345337,399.972778 334.057739,402.571716 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M434.036835,327.825623 
	C430.715576,330.791077 427.320404,333.390961 423.587585,336.027710 
	C422.191864,335.561584 421.134033,335.058289 420.960968,333.353088 
	C422.042267,330.581573 426.434052,331.457397 426.298187,327.407440 
	C427.584595,325.515259 426.889160,322.825439 429.734741,322.004028 
	C430.879028,322.245880 431.683624,322.531281 432.765839,323.002625 
	C433.349945,324.612396 433.656433,326.036224 434.036835,327.825623 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M426.883667,326.769592 
	C428.927612,332.362518 424.542542,331.948303 421.339539,332.968445 
	C420.967407,333.004456 421.005951,333.000366 421.005066,333.019165 
	C420.176025,331.999817 419.885712,330.882477 420.754272,329.248352 
	C421.644196,327.144714 423.519226,327.249054 424.908569,326.084381 
	C425.812286,325.643524 426.339905,325.948242 426.883667,326.769592 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M427.291016,326.955505 
	C426.515808,326.760406 426.052399,326.442749 425.345459,325.971252 
	C425.319366,325.083496 425.536743,324.349518 425.787415,323.317078 
	C426.592316,322.202576 426.941528,320.900238 428.700745,320.782562 
	C429.333649,320.975433 429.617859,321.205444 429.988220,321.697998 
	C426.834320,322.358978 430.495728,326.258362 427.291016,326.955505 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M414.887756,342.606812 
	C414.019104,344.406982 412.828278,346.142792 410.441284,346.979126 
	C409.697998,343.982758 410.631866,343.032837 414.887756,342.606812 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M420.643372,328.916443 
	C420.989868,329.979126 420.973969,331.093140 420.981140,332.622559 
	C419.619202,332.930756 418.444733,332.436249 418.179962,330.936829 
	C417.898834,329.344727 419.144745,329.164642 420.643372,328.916443 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M668.051819,271.737640 
	C666.840271,269.360321 665.726562,266.517395 666.838379,263.166321 
	C667.471313,265.758820 667.755249,268.525269 668.051819,271.737640 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M673.045776,283.300598 
	C673.819031,282.553833 674.608459,282.031403 675.890564,281.182922 
	C675.481079,282.835785 675.994141,284.433350 673.431030,284.042328 
	C673.182007,283.958405 673.061951,283.524902 673.045776,283.300598 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M668.137878,275.645569 
	C667.911987,276.074036 667.651550,276.218506 667.391174,276.362976 
	C667.484375,275.945496 667.577515,275.528015 667.872131,274.950378 
	C668.083557,274.980682 668.093506,275.171112 668.137878,275.645569 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M655.342896,303.208069 
	C655.831421,301.770782 656.529846,300.822571 657.864258,301.504089 
	C658.257080,301.704681 658.645325,302.727295 658.465637,303.093140 
	C657.739136,304.571899 656.612854,304.687805 655.342896,303.208069 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M665.997070,281.002960 
	C666.416382,281.123383 666.838745,281.240875 667.261047,281.358337 
	C666.949036,282.059265 666.593933,281.672302 666.123413,281.196533 
	C666.003601,280.994476 666.000000,281.000000 665.997070,281.002960 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M574.699707,258.205841 
	C577.772461,259.900208 576.501404,261.775635 574.717041,263.822815 
	C573.609863,263.991821 572.735901,263.987976 571.432190,263.994141 
	C570.331238,261.028595 573.186584,260.209259 574.699707,258.205841 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M551.397217,273.985962 
	C551.214539,271.762054 551.887451,269.769897 553.711914,267.923035 
	C553.960754,270.070770 556.703552,273.420227 551.397217,273.985962 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M553.344910,264.893066 
	C552.793945,264.430969 552.500671,263.972504 552.103516,263.261658 
	C552.893860,263.013977 553.657471,263.231903 554.033813,264.507751 
	C553.941040,264.837524 553.771851,264.867065 553.344910,264.893066 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M553.926636,267.029602 
	C554.285156,266.769287 554.478882,265.827393 555.200317,266.640778 
	C554.937317,266.758636 554.674316,266.876465 554.185303,267.047852 
	C553.959229,267.101440 553.965088,267.023987 553.926636,267.029602 
z"
    />
    <path
      fill="#F1F6F7"
      opacity="1.000000"
      stroke="none"
      d="
M664.430115,352.146240 
	C664.737427,352.265198 664.832947,352.718628 664.891235,352.942841 
	C665.009399,354.758423 663.651611,355.629974 662.951416,357.231018 
	C662.061768,358.207092 660.873047,358.298584 660.274048,359.039246 
	C657.751404,362.158569 655.247192,361.102081 652.474243,359.391754 
	C645.566223,355.130951 638.791809,350.649200 631.433105,347.136780 
	C626.196960,344.637512 620.921692,342.254181 615.168823,340.024048 
	C611.639709,338.398651 608.274902,337.392853 604.608154,336.083099 
	C590.914429,331.733368 577.538086,327.731476 563.627930,325.818085 
	C561.800903,325.566742 559.994934,325.486755 557.771973,325.383667 
	C556.839050,325.353638 556.310181,325.304138 555.397705,325.153595 
	C552.810547,324.173676 550.491943,324.427216 547.822571,324.395752 
	C546.684998,324.400208 545.927734,324.427185 544.794617,324.403809 
	C544.170227,324.381134 543.921631,324.363495 543.304321,324.292389 
	C542.459961,324.124237 542.001343,323.962463 541.284058,323.525330 
	C540.307312,320.919006 542.844238,321.173309 543.880981,319.788147 
	C544.771973,318.661713 544.909729,317.694885 544.448608,316.342407 
	C544.251526,315.613892 544.188049,315.185547 544.155640,314.424438 
	C544.279297,312.824432 543.688477,312.097534 542.150391,311.755127 
	C541.088501,311.350922 540.628235,310.630554 539.641968,310.240723 
	C538.829712,310.246521 538.320984,310.395233 537.647339,310.886230 
	C536.843323,311.588562 536.275513,312.136139 535.204224,312.391632 
	C532.076599,311.448273 529.214844,310.757660 526.191650,310.948456 
	C525.337830,311.002350 524.440063,310.667480 524.334290,309.728760 
	C524.276367,309.213928 524.691162,308.303131 525.120667,308.127380 
	C531.817566,305.386444 538.546570,302.723267 545.293152,300.106354 
	C545.617737,299.980438 546.172302,300.447571 546.844666,300.941406 
	C547.061462,302.449005 546.081787,302.847778 544.866760,303.685669 
	C551.698303,302.682983 558.114197,302.014130 564.947510,302.916229 
	C579.616821,302.620270 592.932678,307.201569 606.757690,311.266052 
	C610.609314,312.706787 614.401245,313.204285 617.693420,315.866180 
	C619.320435,316.819489 620.710083,317.451447 622.438110,318.098450 
	C623.269531,318.239746 623.729736,318.441010 624.324829,319.056030 
	C624.599182,320.193817 624.356506,320.860840 623.414917,321.549988 
	C622.102417,321.993439 621.083374,321.954651 620.159485,321.616669 
	C616.323975,320.213684 615.084351,322.093414 615.006531,326.436798 
	C616.818726,322.806580 619.271912,322.993408 621.850647,324.116455 
	C626.208191,326.014160 630.997559,327.079803 634.739624,330.873810 
	C642.817139,336.239868 650.979736,340.898712 658.426147,347.177612 
	C660.543152,348.863129 662.332947,350.445221 664.430115,352.146240 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M618.043396,315.855011 
	C614.311829,315.937164 611.199341,314.092499 607.495361,312.877441 
	C606.814636,311.300934 605.464722,311.028076 604.408325,310.394897 
	C602.708069,309.375702 602.381287,308.091705 603.890625,306.236938 
	C601.475281,303.748260 598.094604,303.906738 594.982056,302.423126 
	C600.367493,298.513977 605.689392,299.910858 610.976990,302.944305 
	C611.945190,303.499786 613.147034,303.648132 614.619263,303.987427 
	C618.260986,304.923340 620.175232,306.289673 618.155823,310.192749 
	C617.430847,311.594208 618.114990,313.724731 618.043396,315.855011 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M634.833984,319.130463 
	C635.797791,319.137421 636.572510,319.325043 637.347290,319.512665 
	C635.587708,324.597626 640.387024,324.979889 642.748291,327.135284 
	C643.007996,327.372345 642.843567,328.073883 642.843994,328.949249 
	C642.371582,329.447845 641.928345,329.460083 641.215820,329.169739 
	C640.949219,328.963837 640.986633,329.031281 641.024963,329.035828 
	C640.613281,328.593475 640.010742,328.498566 639.201782,328.149323 
	C638.966064,327.962677 638.980713,328.027283 639.013672,328.024048 
	C637.232422,326.363495 634.764709,325.713623 633.050293,323.459717 
	C635.013733,322.247131 634.747314,320.744629 634.833984,319.130463 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M624.214111,319.519623 
	C623.651245,319.032959 623.341431,318.823517 622.904053,318.363770 
	C624.244080,317.541809 623.646484,315.834473 624.876831,314.491180 
	C625.278687,319.742645 631.372437,318.341949 633.053589,322.522400 
	C633.154663,322.856110 633.049866,322.956604 632.998962,323.008362 
	C629.209106,324.342499 626.867432,321.993500 624.214111,319.519623 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M324.565430,415.411804 
	C325.381592,415.136353 326.379669,414.832581 326.332184,415.524384 
	C326.293488,416.087280 325.252808,416.984192 324.628601,417.008209 
	C323.724701,417.042999 324.225983,416.141754 324.565430,415.411804 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M321.328674,413.972168 
	C321.003937,412.903870 321.447632,411.996765 322.666382,411.213806 
	C322.903290,412.201599 323.006958,413.386597 321.328674,413.972168 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M326.258362,404.980469 
	C326.166443,404.571503 326.314423,403.959625 326.737701,403.186035 
	C326.841431,403.608704 326.669861,404.193115 326.258362,404.980469 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M649.941284,332.843781 
	C648.503174,332.503113 647.084045,331.764954 645.318359,330.984833 
	C644.971802,330.942841 644.998169,331.015961 645.035706,331.005951 
	C644.876953,330.833344 644.680603,330.670715 644.765076,330.248535 
	C647.205994,329.764313 650.421143,327.429535 649.941284,332.843781 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M644.698975,329.978210 
	C645.021423,330.157684 644.996887,330.326355 645.022827,330.745483 
	C644.306396,331.458099 643.655029,331.138763 642.993774,330.320251 
	C643.427368,329.954315 643.889709,329.960876 644.698975,329.978210 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M641.013428,329.305786 
	C641.367981,328.985321 641.786804,329.006805 642.508850,329.183411 
	C642.812134,329.338501 642.869446,329.706573 642.968018,329.864563 
	C642.403625,329.897644 641.740601,329.772675 641.013428,329.305786 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M421.788513,301.938477 
	C422.081116,304.453857 421.445557,306.043762 418.473907,305.887268 
	C418.401215,304.835632 418.682922,303.846191 419.102417,302.570618 
	C419.415497,301.814575 419.750610,301.515259 420.581360,301.359589 
	C421.157715,301.449677 421.376587,301.543701 421.788513,301.938477 
z"
    />
    <path
      fill="#38717B"
      opacity="1.000000"
      stroke="none"
      d="
M421.980286,301.893616 
	C421.981567,302.239197 421.501190,302.122864 421.269165,302.037781 
	C420.693085,301.661041 420.293335,301.507599 419.526672,301.389008 
	C419.018738,299.015564 420.607605,299.027496 422.558899,299.013611 
	C422.621033,299.859131 422.300018,300.703583 421.980286,301.893616 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M411.297729,308.944763 
	C410.891510,308.169006 410.788269,307.244965 410.858704,306.107361 
	C412.223053,306.665314 412.087006,307.696594 411.297729,308.944763 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M419.194214,301.550751 
	C419.728760,301.359833 420.249939,301.414490 420.904144,301.710938 
	C420.639740,302.036102 420.242310,302.119507 419.542542,302.243713 
	C419.240173,302.284546 419.180878,301.796295 419.194214,301.550751 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M372.011475,340.404053 
	C372.396271,338.133209 372.663696,335.891510 374.854370,335.499725 
	C376.316284,335.238281 376.457764,337.173065 377.004211,338.633209 
	C376.061310,338.979248 375.084076,338.979248 373.051270,338.979248 
	C377.321350,341.669006 378.256927,344.103088 373.794403,346.633026 
	C372.597809,344.865509 371.930359,342.962860 372.011475,340.404053 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M377.157959,339.193604 
	C377.443787,338.946045 377.882874,338.928528 378.649567,338.874329 
	C378.640137,339.653595 378.139618,340.004669 377.157959,339.193604 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M687.636169,375.300049 
	C687.927124,375.027405 688.427002,375.058167 689.301025,375.055481 
	C691.140259,376.453217 692.605469,377.884399 694.055786,379.655975 
	C690.670654,380.372742 690.210327,376.644775 687.636169,375.300049 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M699.383057,370.650940 
	C699.553894,370.788330 699.578979,371.042053 699.604065,371.295776 
	C699.481812,371.119598 699.359619,370.943420 699.383057,370.650940 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M695.052368,380.700653 
	C694.463684,381.504181 693.971191,381.420044 693.778259,380.365173 
	C694.344543,380.098114 694.700623,380.242889 695.052368,380.700653 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M454.964050,316.232391 
	C453.319122,317.494354 451.530121,318.418976 449.412537,319.474213 
	C448.389801,316.605377 447.127716,314.750641 443.474548,315.882446 
	C442.580353,316.159454 439.943329,317.413239 440.228455,314.039734 
	C445.483734,312.791046 450.247192,313.696411 454.964050,316.232391 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M330.909973,399.071899 
	C331.096008,399.026031 331.022827,399.487518 330.944275,399.707520 
	C330.723724,399.740753 330.572449,399.559479 330.454102,399.358826 
	C330.442139,399.338593 330.629242,399.200928 330.909973,399.071899 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M428.527527,302.463776 
	C428.549103,302.505402 428.505981,302.422150 428.527527,302.463776 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M357.633179,416.995483 
	C359.237457,420.961243 358.485718,423.292175 353.415894,422.233643 
	C353.105103,421.894867 353.111389,421.667084 353.204468,421.106079 
	C355.965210,420.890381 354.184387,416.470825 357.633179,416.995483 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M356.847839,295.725769 
	C355.825043,298.146881 353.943481,299.300659 351.322479,299.938232 
	C350.743958,298.312439 353.711029,297.585419 352.116608,295.290283 
	C353.655792,294.137360 355.303345,293.378693 356.847839,295.725769 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M359.626465,288.553314 
	C360.412201,288.328705 360.694305,288.603058 360.572632,289.211914 
	C360.547394,289.338165 360.367920,289.506958 360.239655,289.526672 
	C359.625641,289.621033 359.365234,289.326782 359.626465,288.553314 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M436.853699,277.037659 
	C436.970612,277.036560 437.012817,277.214630 437.010254,277.298279 
	C436.838531,277.939697 436.669342,278.497467 436.500183,279.055206 
	C436.393188,278.669159 436.286224,278.283142 436.268951,277.554016 
	C436.358643,277.210938 436.736816,277.038788 436.853699,277.037659 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M580.020264,256.722961 
	C580.014404,257.096283 579.989258,257.267059 579.961853,257.696594 
	C578.459717,257.752625 578.232910,257.216217 579.755676,256.198151 
	C579.990417,256.113739 580.001099,256.520447 580.020264,256.722961 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M660.229187,265.906677 
	C659.992004,265.067352 660.209167,264.263641 661.426819,263.780090 
	C661.912537,264.692413 661.794128,265.565155 660.229187,265.906677 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M665.428345,261.578125 
	C665.384583,261.510620 665.472107,261.645630 665.428345,261.578125 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M582.045837,263.958923 
	C581.992371,263.959229 581.893921,264.000458 581.939148,263.975616 
	C582.082825,263.909546 582.000854,263.999786 582.045837,263.958923 
z"
    />
    <path
      fill="#48929C"
      opacity="1.000000"
      stroke="none"
      d="
M586.001831,264.998474 
	C586.124817,265.145142 586.245911,265.293396 586.367004,265.441650 
	C586.276489,265.392151 586.185913,265.342651 586.046021,265.148560 
	C585.996704,265.004028 586.000000,265.000000 586.001831,264.998474 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M589.888306,250.225067 
	C590.081177,250.494644 589.906616,250.750549 589.372742,251.003876 
	C589.192749,250.750107 589.361938,250.486801 589.888306,250.225067 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M698.011841,723.992004 
	C698.129822,724.126160 698.235107,724.270630 698.340332,724.415161 
	C698.253235,724.373413 698.166138,724.331604 698.028564,724.159058 
	C697.978027,724.028259 697.999023,724.002380 698.011841,723.992004 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M695.581177,443.814331 
	C696.056213,448.522552 698.606262,452.186615 699.904663,456.648926 
	C697.414185,456.839386 696.716980,454.839844 696.173584,452.960449 
	C695.637207,451.105591 694.413574,449.861633 693.029419,447.971375 
	C691.493652,451.279327 691.623596,454.255463 691.991333,457.635010 
	C690.003723,458.597290 689.608765,456.889923 688.656494,455.621490 
	C689.034729,458.289093 690.559570,460.243652 691.761230,462.714447 
	C693.742371,468.335449 696.923340,473.140198 698.010010,478.725983 
	C696.618713,472.436157 693.287537,466.829193 691.329346,460.358032 
	C691.251038,459.507660 691.340454,459.032196 691.753845,458.289856 
	C696.009766,458.353699 699.067139,462.212036 699.970825,468.044556 
	C701.027771,474.866302 703.450012,481.292236 705.444275,487.860229 
	C706.999817,492.983490 707.625671,498.511627 707.822388,503.890656 
	C708.021484,509.335815 708.622437,514.726562 708.989380,520.585815 
	C707.961609,523.758179 708.697021,526.581909 708.768555,529.672363 
	C708.802795,533.124634 709.475525,536.300720 708.302917,539.701294 
	C707.056763,539.984680 706.091736,539.975891 704.765259,539.975098 
	C703.435120,539.664368 703.221863,538.834473 703.021790,537.558472 
	C703.407471,532.035034 704.086060,526.931274 702.915039,521.444214 
	C702.802917,520.558044 702.797852,520.056458 702.868469,519.202393 
	C703.320251,517.294067 703.083862,515.810486 702.312012,514.073364 
	C700.162903,511.760529 700.844788,508.617096 698.889648,506.278442 
	C697.361572,504.972656 696.486328,503.621948 696.108032,501.604370 
	C695.279541,496.158600 693.161255,491.670044 689.725769,487.390808 
	C687.379211,478.669647 683.592590,470.916229 679.594238,463.290558 
	C674.427063,453.435455 668.524963,444.013153 661.620056,434.816467 
	C658.395508,429.223145 654.265076,424.813141 650.091858,420.441864 
	C648.732056,419.017548 647.446838,417.547089 646.288208,415.525513 
	C643.483154,411.077118 641.573425,406.275024 636.292908,404.572266 
	C635.982788,404.472229 635.573792,404.254639 635.516479,403.984314 
	C633.818359,395.969147 626.175415,395.679199 620.729736,392.506927 
	C618.003113,390.918610 615.274780,389.155914 615.733032,385.032104 
	C618.123962,383.448364 619.963867,385.669647 622.386353,385.617340 
	C621.965393,382.483704 619.957825,380.257355 619.403625,377.519867 
	C618.970947,375.382416 617.571899,376.926331 616.654175,377.011871 
	C611.486572,377.493591 610.100647,376.138702 610.383240,370.935547 
	C610.639648,366.215271 608.985657,362.648834 604.470215,360.422607 
	C602.163086,359.285065 598.613098,359.165588 598.796326,354.964630 
	C602.619751,353.063385 605.197083,356.049774 608.632446,356.919678 
	C615.395569,360.077911 621.039978,364.555603 627.738770,367.041687 
	C629.535095,367.708313 630.013611,369.323364 630.909607,371.064697 
	C631.502014,371.528351 631.872742,371.748383 632.505737,372.142761 
	C632.908752,372.468903 633.051819,372.616913 633.299561,373.046204 
	C633.400391,373.713135 633.101135,373.743469 632.828674,373.806000 
	C633.023499,374.092682 633.218140,374.031616 633.673645,373.469330 
	C635.807800,372.802521 637.000916,374.536499 638.980103,374.830750 
	C639.522217,374.988342 639.739990,375.059631 640.281372,375.263306 
	C656.189514,386.253784 669.127136,399.819580 680.779480,414.695587 
	C681.736450,415.917236 682.208191,417.324219 681.719238,419.239502 
	C681.748230,420.882507 678.800598,419.613800 679.814819,422.178436 
	C681.753052,427.079803 680.915588,427.895264 674.940674,428.038696 
	C669.566467,425.464691 667.720947,420.025574 663.225281,415.765076 
	C668.238037,422.634949 672.240540,429.110229 677.726624,434.914917 
	C678.138977,435.329254 678.290955,435.507751 678.614868,435.992371 
	C679.903748,440.093750 682.059509,443.355042 684.269958,446.957428 
	C684.727112,447.970337 684.487305,448.796143 685.008911,449.588196 
	C685.165344,449.669250 685.174194,449.250031 685.111450,449.013306 
	C685.009766,448.597900 684.970825,448.419189 684.868164,447.952881 
	C682.817261,444.343323 680.441223,441.220184 679.509277,436.992401 
	C680.399963,434.065369 678.354919,429.996857 681.455017,428.951935 
	C684.832581,427.813477 684.027222,432.932098 686.402527,434.149170 
	C687.398376,434.659454 688.271851,435.563599 688.373779,437.184143 
	C688.032593,437.882080 687.642700,438.165497 687.226379,437.976074 
	C686.870972,437.814362 686.797546,437.615997 686.865662,437.708466 
	C686.873474,437.719086 686.982422,437.818726 687.386108,438.240234 
	C690.341187,439.966705 693.275024,441.137329 695.581177,443.814331 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M708.378418,555.923218 
	C707.692444,553.203186 708.317749,550.510437 708.976624,547.413635 
	C713.029175,550.065857 709.551147,552.916077 708.378418,555.923218 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M700.732361,460.236877 
	C702.317749,461.488586 702.651855,463.365509 703.046692,465.595764 
	C699.980347,465.178772 700.886047,462.517273 700.732361,460.236877 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M708.993530,529.537842 
	C707.237488,527.289307 707.985779,524.425964 708.708130,521.304932 
	C708.987976,523.714661 708.991577,526.402222 708.993530,529.537842 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M695.418335,592.025391 
	C693.980103,589.502136 692.719421,588.197754 690.560730,591.070862 
	C689.085022,593.035034 687.326294,594.794556 687.299866,597.546875 
	C687.221985,605.660461 683.049500,612.785706 681.624634,620.585327 
	C680.941345,624.325500 678.026184,625.427551 674.377014,625.993164 
	C673.996094,623.382507 674.522949,621.013733 675.468018,618.443970 
	C678.684448,609.697815 681.211609,600.698242 684.010498,591.397034 
	C683.593018,587.891968 684.173340,588.184875 686.653564,583.270691 
	C687.983765,580.634888 687.750183,576.874573 688.001099,573.623169 
	C688.507141,567.065857 690.851624,560.527710 688.454163,553.876404 
	C688.101440,552.898010 688.640686,551.923218 690.108154,551.465454 
	C691.866882,551.571228 692.584656,552.971558 693.970032,553.332397 
	C694.776062,550.555176 693.417664,547.775269 694.109985,544.714783 
	C694.471741,543.622253 694.637695,542.837158 694.234680,541.722412 
	C693.909180,540.558716 693.911743,539.693298 694.202271,538.507751 
	C695.220642,536.850220 695.595764,535.491638 694.321411,533.756531 
	C694.277405,529.881409 693.883850,526.349243 693.798462,522.465210 
	C694.033142,521.022888 693.985229,519.957031 693.256226,518.647278 
	C692.880127,517.373962 692.905273,516.434021 693.421082,515.182373 
	C696.234741,512.725830 697.697998,513.715759 698.231934,516.699402 
	C698.577332,518.629456 699.157715,520.443176 700.245911,522.480591 
	C700.669312,523.718140 700.582031,524.670105 700.787598,525.929688 
	C700.872009,526.721924 700.874207,527.175110 700.810791,527.962036 
	C700.046875,530.899719 700.532410,533.480225 700.925781,536.376099 
	C700.895203,536.891174 700.865417,537.092712 700.753845,537.579590 
	C700.477844,538.392151 700.278748,538.918579 700.470459,539.777954 
	C700.607178,540.250977 700.661743,540.444641 700.793762,540.937805 
	C701.077148,542.289612 701.669739,543.286865 701.084106,544.651489 
	C699.508118,545.218750 698.914673,546.142090 699.049622,547.440125 
	C699.623962,552.964111 700.249573,558.482788 700.932251,564.400269 
	C700.044006,566.686951 700.556702,568.669739 700.842773,570.938965 
	C700.936829,571.692627 700.829102,572.132935 700.586304,572.757141 
	C697.780518,578.244507 697.893860,584.197205 696.538025,589.807678 
	C696.351929,590.577637 696.529419,591.441895 695.418335,592.025391 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M694.977722,592.253906 
	C697.198730,585.859741 696.880920,579.101135 700.041870,573.017761 
	C698.869995,580.301697 698.470886,587.919983 695.317688,595.241028 
	C694.623169,594.982056 694.449768,594.429626 694.630615,593.399780 
	C694.844238,592.824463 694.899536,592.665161 694.977722,592.253906 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M701.032349,570.830078 
	C699.124084,569.448669 700.175537,567.298828 700.676758,565.024414 
	C701.049255,565.310242 701.090637,565.823425 701.219421,566.712463 
	C701.211243,568.194885 701.115662,569.301392 701.032349,570.830078 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M694.512451,593.037964 
	C694.826721,593.537415 694.864563,594.090942 694.947449,595.058777 
	C694.992432,595.473022 695.007202,595.438904 695.011230,595.453308 
	C694.923767,595.745117 694.832336,596.022461 694.351929,596.098389 
	C693.670105,594.924622 692.445190,593.861572 694.512451,593.037964 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M693.962402,596.245361 
	C694.138672,595.826355 694.314453,595.755676 694.752686,595.576355 
	C694.893616,596.509277 694.771973,597.550903 694.318604,598.812683 
	C693.986816,599.032898 693.992859,599.003235 693.982056,599.009521 
	C693.805481,598.867004 693.639709,598.718323 693.622681,598.270386 
	C693.834961,597.512085 693.898499,597.052917 693.962402,596.245361 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M700.901978,545.302368 
	C701.487732,543.410217 698.230347,542.782898 700.323547,540.966064 
	C701.758118,540.212524 702.637207,540.702881 703.687622,541.686646 
	C703.941467,545.643799 703.964539,549.387085 703.955261,553.524536 
	C701.351318,551.757996 700.746216,548.886719 700.901978,545.302368 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M703.981140,541.576538 
	C703.016113,541.556152 702.113770,541.211914 700.922852,540.869629 
	C700.590332,540.709778 700.546509,540.547913 700.372498,540.170654 
	C699.708923,539.678345 699.505676,539.246094 699.934570,538.399414 
	C700.236328,538.140259 700.281067,538.321350 700.373840,538.311646 
	C700.954651,536.993713 701.836975,536.334534 703.569946,536.813477 
	C703.981323,537.771912 704.100281,538.511047 704.311523,539.616638 
	C704.283813,540.406250 704.163818,540.829468 703.981140,541.576538 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M668.516968,682.411926 
	C668.586182,682.530029 668.307251,682.588257 668.307251,682.588257 
	C668.307251,682.588257 668.447754,682.293823 668.516968,682.411926 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M688.772095,614.744446 
	C687.867859,614.875061 687.142212,614.505676 687.119751,613.537476 
	C687.098694,612.629883 687.658569,612.513733 688.651611,612.878540 
	C688.891663,613.437317 688.872803,613.915710 688.772095,614.744446 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M551.995728,258.994263 
	C551.913086,259.026825 551.834778,259.065125 551.883911,259.056061 
	C552.011475,259.008698 552.000000,259.000000 551.995728,258.994263 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M320.580872,462.987427 
	C321.246429,463.135132 321.493103,463.300323 321.807068,463.732056 
	C321.769775,464.073700 321.615479,464.223114 321.153656,464.469360 
	C319.694519,465.226959 318.488556,465.669403 316.837585,465.343689 
	C312.852966,465.652924 309.282135,467.148529 305.193298,466.228271 
	C305.619720,459.591217 306.311096,453.237122 307.013489,446.461182 
	C308.750336,446.959412 306.869690,450.307373 309.917877,450.337158 
	C309.468964,454.826019 312.271149,452.715118 314.951843,452.042297 
	C313.481079,454.603027 312.879791,456.641510 313.948334,459.020111 
	C315.238678,461.892456 317.815216,462.138977 320.580872,462.987427 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M316.469788,465.493408 
	C317.648132,464.858154 318.749268,464.520874 320.148682,464.123718 
	C320.755035,464.158630 321.063141,464.253479 320.951416,464.588318 
	C320.696198,465.936005 320.860779,467.043762 321.022827,468.573914 
	C320.248505,469.033447 319.476715,469.070557 318.318298,469.131836 
	C317.011902,468.220520 316.488800,467.103638 316.469788,465.493408 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M298.625946,494.393066 
	C299.000824,497.190155 299.119843,500.280884 299.209686,503.863159 
	C297.147552,501.279846 298.269318,497.940491 298.625946,494.393066 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M307.636169,472.100037 
	C307.364685,471.835663 307.253174,471.443665 307.196442,471.043945 
	C307.186981,470.977264 307.415924,470.876770 307.534668,470.791870 
	C307.627930,471.184509 307.721222,471.577209 307.636169,472.100037 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M307.467163,468.453705 
	C307.518402,468.424164 307.415924,468.483246 307.467163,468.453705 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M323.290039,447.830078 
	C320.096832,450.210236 319.214600,445.554260 316.356720,446.056366 
	C316.504181,442.734985 318.333710,440.010468 319.735565,437.061554 
	C321.218536,433.942017 323.000671,435.236542 324.967102,435.884277 
	C327.426910,436.694550 327.415466,438.100403 326.088776,439.997070 
	C325.140625,441.352509 326.303162,442.856720 326.035400,444.660095 
	C326.050537,445.031403 326.026611,445.032074 326.028412,445.040466 
	C325.115692,445.833984 324.287109,446.705688 323.290039,447.830078 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M300.047119,460.888367 
	C299.576080,459.824066 298.121552,458.768402 299.971222,457.719116 
	C300.406464,457.472229 301.480957,457.575897 301.727417,457.910950 
	C302.645935,459.160156 301.750854,459.940063 300.399902,460.699127 
	C300.089142,460.830353 299.984833,460.914032 300.047119,460.888367 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M302.365173,536.856079 
	C303.191864,537.463989 303.365784,538.323608 302.734985,539.218689 
	C302.683228,539.292175 302.455017,539.346924 302.411316,539.304626 
	C301.726410,538.640686 302.040253,537.845581 302.365173,536.856079 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M344.497131,372.333435 
	C344.332703,374.729950 343.295013,376.649017 341.316528,378.400726 
	C339.774323,375.615997 341.020691,373.521088 343.691132,371.801910 
	C344.051666,371.810974 344.296234,372.211853 344.497131,372.333435 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M334.075439,339.289490 
	C334.707642,339.204468 335.160767,339.315735 335.807800,339.721497 
	C335.944946,341.713531 334.822388,342.042084 333.028564,342.010651 
	C333.052155,341.162567 333.474243,340.324158 334.075439,339.289490 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M282.001648,453.001831 
	C281.888855,452.870514 281.774475,452.737335 281.660095,452.604187 
	C281.739105,452.651215 281.818115,452.698242 281.950378,452.874268 
	C282.003662,453.003265 282.000000,453.000000 282.001648,453.001831 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M259.771576,359.106812 
	C259.894012,359.316711 259.713287,359.575989 259.206543,359.855652 
	C259.122803,359.661621 259.318756,359.421692 259.771576,359.106812 
z"
    />
    <path
      fill="#05070B"
      opacity="1.000000"
      stroke="none"
      d="
M544.234558,637.647461 
	C536.245972,640.706055 528.109436,641.299744 519.995422,641.674805 
	C457.724274,644.553711 388.993591,603.312378 377.297760,525.890564 
	C376.502655,520.627136 376.447205,515.280823 376.866180,509.487793 
	C376.780609,497.923065 382.223969,489.135071 388.768250,480.841431 
	C390.094055,479.161163 391.950439,477.922607 393.267700,475.721863 
	C395.697662,473.494415 398.656006,473.224915 401.172302,471.959381 
	C403.738342,470.668884 405.003143,472.225983 404.922058,474.701019 
	C404.826752,477.609436 404.191681,480.341797 400.786835,481.324493 
	C394.649200,483.095886 395.479706,489.614624 392.609650,493.626923 
	C392.147644,494.272797 393.354492,495.380707 394.115906,496.006622 
	C398.589050,499.683777 403.555756,501.404755 409.401917,499.989136 
	C411.388367,499.508087 412.253540,498.648285 411.852295,496.532562 
	C410.042297,486.989166 409.531494,477.454620 412.174957,467.948608 
	C412.541138,466.631775 412.145447,465.367584 411.437042,464.145020 
	C408.434052,458.962677 408.774994,457.288879 413.793304,453.463745 
	C415.125122,453.612427 415.974792,454.216461 417.262299,454.344330 
	C416.448700,453.864594 415.718048,453.912323 414.819580,453.482971 
	C411.959839,450.478119 413.876526,449.472992 416.482635,448.804932 
	C422.133148,447.356445 422.649658,445.977020 419.886597,440.793091 
	C417.891418,437.049927 414.125824,435.437592 410.894989,432.621735 
	C410.603638,432.083679 410.522888,431.847900 410.438354,431.234375 
	C411.171692,428.611908 413.130737,427.975525 415.442017,427.270233 
	C418.576355,427.253479 420.497620,428.526489 421.592468,431.100403 
	C422.387207,432.968781 423.771759,434.365295 425.624664,435.711334 
	C425.070587,432.280579 422.355774,429.044556 425.713562,425.611328 
	C428.385559,424.728088 430.156189,425.573334 430.420105,427.983795 
	C430.971405,433.018280 434.774841,434.599030 438.630463,435.351746 
	C444.788635,436.553986 448.820770,439.973297 451.201630,445.479614 
	C452.864532,449.325531 455.618347,452.430298 457.699402,456.315125 
	C457.686371,457.090240 457.078522,457.319580 457.198761,457.737976 
	C457.293610,458.067963 457.602631,457.947021 458.023010,457.301697 
	C464.971588,456.943085 467.802979,458.859680 470.345459,464.545410 
	C473.068848,470.635864 476.641266,476.248566 480.920898,481.391724 
	C482.285492,483.031647 484.076324,484.467224 483.615356,487.324829 
	C481.730743,489.281891 480.253143,487.767120 478.367188,487.071228 
	C477.652161,486.613495 477.280426,486.308868 476.702179,485.710205 
	C475.274902,483.508728 473.797333,481.846344 471.531799,480.471619 
	C469.402252,478.328491 468.738586,475.729736 467.296143,473.166321 
	C466.573578,471.102631 466.438965,469.262115 465.929291,467.504089 
	C465.313171,465.378937 464.377563,464.197296 461.724945,464.358246 
	C456.499329,464.675293 450.717499,460.134613 448.502472,455.136047 
	C447.243744,452.295563 445.730530,449.652069 445.079376,446.514893 
	C444.532745,443.881195 442.374786,443.395844 439.974579,444.497925 
	C439.218109,444.845276 438.584808,445.401611 437.906067,445.879456 
	C435.987610,447.230011 434.028900,448.756592 431.540619,447.472626 
	C429.002045,446.162659 431.527313,443.763763 430.335144,441.598602 
	C431.394928,445.162659 428.418915,447.033936 427.893646,450.101013 
	C430.006256,450.801941 432.092102,449.219818 434.070557,450.172607 
	C436.328979,451.260223 437.007202,453.202026 437.221161,455.438751 
	C437.640686,459.825104 436.281281,461.376770 430.658539,462.648621 
	C430.070221,462.670654 429.834442,462.657776 429.258301,462.547913 
	C426.880524,459.986908 424.112671,460.285553 420.631073,460.309296 
	C423.623657,466.284943 422.823547,471.942780 420.452576,477.857056 
	C421.879089,482.431580 422.893127,486.763153 423.838257,491.437744 
	C422.870636,493.345520 424.214508,493.269745 425.421906,493.591370 
	C426.438538,493.786133 427.091522,494.003906 428.013306,494.489532 
	C429.349884,495.664642 429.888367,497.000000 430.913208,498.425171 
	C433.422058,501.840363 433.396637,505.555725 433.719849,509.173340 
	C433.860229,510.744507 433.272430,512.093079 432.152069,513.208008 
	C430.960663,514.393616 428.261475,515.023254 429.567291,517.293396 
	C430.918121,519.641785 432.458771,522.845154 435.480225,522.763367 
	C439.885071,522.644043 442.066650,525.506958 444.536804,528.045044 
	C448.798462,532.423828 452.680359,537.193176 458.585114,539.573059 
	C460.835419,540.479980 461.744202,542.899597 463.236969,544.546082 
	C466.717468,548.385132 471.151337,550.785461 475.298889,554.184082 
	C476.528290,556.771729 478.023834,558.588684 480.312195,559.823608 
	C483.706085,561.655212 485.827087,564.878235 488.568298,567.909058 
	C489.378052,570.007812 490.783478,571.051636 492.669312,572.096436 
	C493.110443,572.454346 493.272980,572.613647 493.628693,573.052490 
	C495.014618,575.539673 495.372253,578.346069 498.479309,579.359375 
	C499.189575,579.235046 499.597351,579.173218 500.318115,579.124084 
	C501.036743,579.163513 501.436096,579.250488 502.099579,579.510010 
	C502.548065,579.731384 502.721222,579.829773 503.146423,580.082275 
	C503.740967,580.434021 504.092316,580.614441 504.738770,580.879089 
	C505.419250,581.120056 505.801575,581.287231 506.450226,581.651733 
	C507.068695,582.115234 507.376556,582.436462 507.849060,583.070312 
	C509.351318,586.624573 510.141876,589.773499 508.904785,593.439331 
	C507.961243,596.235352 509.374207,599.054688 511.737183,600.877991 
	C514.660645,603.133850 517.011841,605.916199 519.492493,608.578796 
	C522.524475,611.833069 525.958191,614.249817 530.485535,614.843323 
	C534.302673,615.343750 536.590088,617.665283 538.312317,621.005188 
	C540.270691,624.803223 541.791321,628.958374 545.675232,631.907471 
	C546.081970,632.324707 546.230652,632.504517 546.537231,632.993835 
	C546.753052,633.525330 546.810791,633.747375 546.885132,634.308228 
	C546.863953,634.866089 546.826233,635.085266 546.651123,635.603455 
	C545.900146,636.503357 545.112488,636.875427 544.234558,637.647461 
z"
    />
    <path
      fill="#05070B"
      opacity="1.000000"
      stroke="none"
      d="
M591.218323,614.656372 
	C586.446533,618.944397 581.095276,621.860229 575.547180,625.040283 
	C575.001892,625.197937 574.798279,625.306946 574.248657,625.407532 
	C569.405396,624.105042 567.231140,619.247620 562.329590,617.948364 
	C561.567322,617.638306 561.147034,617.423401 560.431885,616.998169 
	C555.602173,613.390076 551.773315,609.209717 547.687744,605.318298 
	C544.263672,602.056946 542.922668,597.721924 542.867615,593.107788 
	C542.779114,585.686096 540.558594,579.951477 532.854370,577.169250 
	C529.485474,575.952515 527.194214,572.621155 523.825134,571.062134 
	C522.521057,570.458679 522.033569,569.104370 522.075134,567.698120 
	C522.186707,563.921204 520.853088,560.531006 519.234009,556.843384 
	C518.040039,554.110168 516.454224,551.990906 515.228271,549.263550 
	C514.602722,547.777527 514.267212,546.580383 513.823730,545.020264 
	C513.549194,541.668457 511.911743,538.759399 513.432251,535.417725 
	C513.711853,534.749146 514.577209,534.752686 513.888306,534.095947 
	C511.803711,534.084656 510.441376,533.258423 509.752197,531.593811 
	C508.312866,528.117310 505.494049,525.856628 502.568298,523.167480 
	C501.115051,520.760071 500.421356,518.556396 501.272156,515.765747 
	C504.565216,513.088196 505.960114,515.861267 507.374786,517.670654 
	C509.749268,520.707886 511.946930,522.561035 515.193237,518.694336 
	C516.025574,517.703003 517.388550,517.001221 518.780212,517.850037 
	C519.926392,518.549194 519.804077,519.843506 519.877686,520.994690 
	C519.984985,522.673401 519.744873,524.309570 519.279297,525.923279 
	C518.909363,527.205688 518.270081,528.746460 519.120178,529.791443 
	C522.020996,533.356934 521.737915,537.141174 521.315674,541.460938 
	C520.728271,547.470093 526.442444,553.805054 532.526489,554.865417 
	C535.492310,555.382263 537.489075,556.602722 538.090332,560.009033 
	C538.090149,560.771729 537.979187,561.194458 537.978088,561.612793 
	C537.976990,562.014282 538.123169,562.153564 538.183838,562.025269 
	C538.185730,562.021362 538.171448,561.862427 538.543701,561.423218 
	C539.455688,561.012085 540.415649,560.725952 540.728088,561.116028 
	C543.735901,564.870911 549.182312,565.087524 552.042664,569.090942 
	C553.735413,571.460266 556.420471,572.683960 559.142456,573.312439 
	C561.830444,573.932983 563.983948,572.841675 565.023071,570.000183 
	C565.630920,568.337952 566.811646,567.031738 568.754944,567.357178 
	C570.923218,567.720398 571.659119,569.307922 571.468323,571.408264 
	C571.434692,571.778503 570.759888,572.335571 571.265930,572.426331 
	C576.448730,573.355530 578.474670,579.462341 583.603210,580.137878 
	C589.246094,580.881226 594.868652,579.713440 600.188049,577.975586 
	C605.264038,576.317200 606.559448,576.796509 606.928650,582.138489 
	C606.986511,582.976257 606.803650,583.788025 606.465820,584.865234 
	C606.245850,585.416077 606.146667,585.696411 606.073975,585.985962 
	C606.371155,586.043945 606.630615,585.946228 607.122925,585.520020 
	C610.718323,584.709167 614.089478,582.883972 617.461426,586.071899 
	C618.317749,588.778076 616.861450,590.310852 615.265808,592.169189 
	C613.594727,593.737000 612.718384,595.591858 610.551086,596.573120 
	C607.732483,596.957703 604.452942,595.849060 604.330322,600.429016 
	C604.406189,600.958923 604.428467,601.169983 604.413208,601.692139 
	C603.996216,602.472839 603.626831,602.950439 603.039551,603.612671 
	C602.692993,603.923218 602.547729,604.035645 602.168457,604.284180 
	C601.594238,604.480835 601.251709,604.520752 600.669861,604.568970 
	C600.433594,604.606018 599.984985,604.742004 599.773926,604.838562 
	C599.562927,604.935120 599.163391,605.153992 598.973450,605.271606 
	C598.783508,605.389221 598.497681,605.729675 598.485840,605.964355 
	C598.481689,606.536377 598.485291,606.873718 598.466553,607.490845 
	C598.378784,607.948669 598.310059,608.126648 598.079163,608.544556 
	C597.459290,609.429138 597.156860,610.164856 596.547119,611.070618 
	C595.692444,612.075134 594.907104,612.704407 593.777588,613.370239 
	C592.798584,613.779053 592.021606,613.920044 591.218323,614.656372 
z"
    />
    <path
      fill="#030408"
      opacity="1.000000"
      stroke="none"
      d="
M410.698486,431.972168 
	C417.027252,434.189789 420.927582,438.122742 422.909882,444.459351 
	C424.325958,448.985931 422.342957,449.608154 418.829620,450.067200 
	C417.205963,450.279388 414.038422,448.407776 414.740540,452.754211 
	C415.127197,453.167084 415.253174,453.334198 415.184326,453.747406 
	C410.444794,456.512878 409.527283,459.920441 412.769196,462.919464 
	C414.451691,464.475922 414.063507,466.231750 413.789215,467.974792 
	C413.302094,471.070221 412.532257,474.120544 412.019470,477.212708 
	C410.796204,484.588989 412.488312,491.699310 414.230377,498.775085 
	C414.529663,499.990723 414.541382,500.628296 413.227722,500.919525 
	C404.349762,502.887848 396.513214,502.471313 390.328705,493.831451 
	C389.246277,492.319275 388.164276,491.159637 391.410187,491.510010 
	C393.824829,491.770660 392.825806,489.337494 393.373749,488.179565 
	C395.082214,484.569366 395.461243,480.290375 401.080139,479.722626 
	C404.159424,479.411499 403.760468,475.670074 403.688354,473.140350 
	C403.661285,472.189209 402.268463,472.191254 401.750214,472.925537 
	C399.933655,475.499359 396.806641,474.521332 394.173401,475.927673 
	C391.354736,478.742920 388.503387,480.899628 384.956757,482.711700 
	C376.755341,482.855316 375.722656,481.462189 378.289062,474.147217 
	C378.793945,472.708221 379.100433,471.294525 378.921082,469.409027 
	C380.184998,465.086761 381.667297,461.272644 383.956299,457.430542 
	C386.830627,452.726501 389.705109,448.437439 394.266357,445.143982 
	C396.943726,444.058014 398.856476,442.249695 401.584656,441.902100 
	C404.077881,441.584442 403.966461,439.719269 402.847046,437.545105 
	C402.660828,436.780304 402.728241,436.351654 403.178955,435.717773 
	C404.401306,434.731354 405.821381,434.741730 406.846191,433.447479 
	C407.202148,433.076782 407.353210,432.936707 407.760223,432.622986 
	C408.767242,432.083679 409.556793,431.898315 410.698486,431.972168 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M573.730347,624.762939 
	C574.202454,624.993164 574.403625,625.005798 574.905151,625.053650 
	C571.389282,628.689087 566.249817,629.804749 561.453735,632.374268 
	C559.776001,631.885376 559.114136,630.812805 559.107971,629.360657 
	C559.084045,623.691711 557.621887,622.359436 551.751953,622.724304 
	C551.093750,622.765198 550.440735,622.893127 549.464722,622.817871 
	C548.759338,622.718872 548.670715,622.080750 548.329468,622.201233 
	C548.128174,622.272278 548.323853,622.702820 548.924194,623.113892 
	C550.263367,625.416626 550.788269,627.642639 550.488464,629.921326 
	C550.203796,632.085266 549.555847,634.207397 546.214966,633.222778 
	C540.097900,631.209900 539.677124,625.301147 536.986267,621.169556 
	C535.592163,619.028992 534.442627,616.610901 531.706238,616.460693 
	C524.559021,616.068481 520.101379,611.567444 515.867310,606.642029 
	C514.129761,604.620850 511.974121,603.091858 510.062744,601.325378 
	C507.645905,599.091614 505.740814,595.995972 507.661316,592.266052 
	C509.159790,589.355652 507.874756,586.635498 507.001068,583.510498 
	C507.336700,580.560059 506.916779,578.243286 504.651886,576.141235 
	C504.085114,574.591980 504.447021,573.475891 505.250610,572.119385 
	C506.136810,571.142578 506.909180,570.637390 507.926331,570.637207 
	C506.897888,570.236633 506.203461,570.858276 505.247864,571.686890 
	C503.406525,572.990173 501.876312,573.312378 500.383423,571.222046 
	C499.956543,569.583008 499.751404,568.573547 497.870453,568.373779 
	C489.272522,567.460571 487.740295,565.295532 489.553528,556.933838 
	C489.542023,556.190735 489.480530,555.772156 489.284241,555.050781 
	C489.133362,554.530640 489.081299,554.318787 488.986206,553.779785 
	C488.915314,552.782104 489.012756,552.128357 489.332458,551.180786 
	C490.196442,549.791504 490.774384,548.621338 491.526398,547.171753 
	C492.026672,546.587952 492.369171,546.314819 493.028931,545.924927 
	C494.822754,545.286377 495.730988,544.071350 497.046173,542.848328 
	C499.931244,543.344360 499.630035,546.121704 500.958252,547.860046 
	C502.873016,545.329651 502.242340,541.200745 506.610840,540.710083 
	C507.501221,543.736328 509.535583,545.653870 511.484070,547.894226 
	C511.623688,548.201965 510.915466,548.771790 511.939026,548.277161 
	C513.337097,547.776428 514.439270,547.838623 515.776367,548.697327 
	C517.367432,551.043030 518.659180,553.182800 519.994019,555.668945 
	C519.962341,556.163452 519.918579,556.341675 519.808105,556.455078 
	C517.847290,558.468811 514.981689,560.199402 514.178284,562.578918 
	C513.324524,565.107483 516.642334,567.238098 517.627136,569.844238 
	C518.377930,571.831177 518.898254,573.578125 517.716797,575.495605 
	C516.265198,577.851440 516.624756,580.249634 517.606506,582.996582 
	C517.734802,584.013062 516.733887,584.765503 518.073364,585.441345 
	C522.032898,589.578552 522.194763,594.820618 523.217407,599.785217 
	C523.655640,601.912354 523.036194,605.337463 526.038696,605.292480 
	C528.590881,605.254272 528.916016,602.091553 529.302490,599.778320 
	C529.412170,599.121948 529.223022,599.933533 529.392578,599.289001 
	C530.115479,596.540894 530.833618,593.064087 533.906738,592.828735 
	C536.803894,592.606934 535.751770,596.550903 537.108582,598.292419 
	C537.508545,598.805786 537.605164,599.513977 537.609863,600.530518 
	C537.309143,602.206238 535.826843,602.785583 535.480286,604.406128 
	C538.211243,604.871887 540.940308,605.219238 543.910034,606.056519 
	C547.022156,610.064209 549.151062,614.577515 554.664124,615.583496 
	C556.577881,615.932739 558.404907,616.664673 560.691040,616.171753 
	C561.257629,616.176208 561.533020,616.364441 561.933472,616.772888 
	C562.466919,617.496399 562.965881,617.936890 563.242432,618.533752 
	C566.353394,625.248047 566.351318,625.249023 573.730347,624.762939 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M440.427521,680.999756 
	C439.306091,679.119751 438.674957,677.197449 436.325684,676.241089 
	C434.334839,676.550598 433.913788,674.915466 432.659607,673.975342 
	C431.991577,673.591980 431.570221,673.495605 430.796936,673.463501 
	C430.266724,673.342651 430.067108,673.245605 429.777771,672.826782 
	C429.781281,672.058960 429.988922,671.674805 430.605774,671.193237 
	C432.082672,670.757996 433.711975,672.380737 434.427307,670.582947 
	C435.124908,668.829651 432.943451,668.751221 432.199036,667.795471 
	C431.369537,666.730591 428.336151,667.023499 429.404755,665.164429 
	C430.735535,662.849182 431.941315,665.936829 433.355835,666.217285 
	C435.924927,666.726501 436.656555,670.117554 439.936279,670.135254 
	C443.894714,670.156616 446.264252,668.333801 445.606720,664.435730 
	C445.357330,662.957153 444.594513,661.477356 445.586090,660.153015 
	C448.946716,655.664429 445.408783,654.407288 442.266052,653.457458 
	C439.805511,652.713867 438.027924,651.838013 437.914734,648.678040 
	C444.924164,648.706787 451.270294,651.759521 457.945740,653.482117 
	C479.686584,659.092102 501.400604,658.419006 523.595764,654.164795 
	C524.880432,655.087646 525.047119,656.162964 524.549194,657.728760 
	C523.023193,659.423279 521.284058,659.293274 519.211548,659.481384 
	C518.260193,659.646973 517.726379,659.794678 516.789734,660.066895 
	C513.583557,661.122070 510.866577,659.216003 507.715668,660.174805 
	C502.965515,660.585327 498.574341,660.443481 493.753601,660.490234 
	C486.838135,659.762878 480.295105,659.512207 473.874420,658.251770 
	C479.885437,659.422974 486.028198,659.595581 492.494080,660.509888 
	C497.394684,660.508057 501.874542,660.515015 506.758728,660.482605 
	C507.636536,660.218689 508.090942,659.946594 508.908264,659.592285 
	C509.540558,659.595215 509.790131,659.647583 510.383301,659.861145 
	C512.430725,661.032532 514.867249,660.303528 516.146851,662.704590 
	C515.899902,663.221069 515.658630,663.340698 515.007751,663.474976 
	C502.735291,665.162354 490.843536,665.512939 478.530060,664.453491 
	C477.075134,666.469604 475.257751,665.962585 473.667664,665.987122 
	C472.151947,666.010437 471.127319,666.582581 470.577423,667.990967 
	C469.917542,669.680969 470.922943,670.335144 472.267151,670.970276 
	C475.012390,672.267639 473.340332,673.613892 471.854340,673.736328 
	C468.966553,673.974426 465.896912,675.179016 463.159241,673.999146 
	C459.129974,672.262573 455.921417,675.473389 452.214325,675.244995 
	C451.991821,675.231323 451.453064,676.029419 451.524506,676.344849 
	C451.776489,677.457886 452.709839,677.903687 453.764618,677.950500 
	C458.249451,678.149719 456.789948,680.147949 455.056854,682.554932 
	C454.976868,683.268311 454.773254,683.538269 454.173828,683.839050 
	C452.216400,680.413391 449.588226,679.186829 445.663513,680.551392 
	C444.188690,681.064148 442.468323,680.870850 440.427521,680.999756 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M384.854004,427.194885 
	C385.584076,426.206635 386.075104,425.398987 386.635254,424.308258 
	C386.159119,422.044128 387.358704,420.525574 388.113953,418.916718 
	C393.878998,406.635864 402.677612,396.587311 411.829346,386.804169 
	C413.068756,385.479309 414.467194,384.304993 415.796265,382.575806 
	C419.177155,379.515228 422.672577,377.176361 426.410004,374.633667 
	C430.419464,374.557312 433.421356,373.299500 435.401428,370.048401 
	C436.640839,368.013458 438.868042,367.078003 440.784546,365.277893 
	C441.205597,364.886963 441.384491,364.742493 441.856201,364.417603 
	C444.212036,363.362579 446.383636,362.690979 448.324158,360.859131 
	C448.801300,360.522766 449.005341,360.404785 449.543335,360.162659 
	C453.540192,358.996246 454.923004,361.897125 456.992798,364.290558 
	C458.647247,366.755707 457.182587,368.722565 456.633087,371.114075 
	C455.493225,373.344604 453.428284,373.621155 451.440857,374.674011 
	C438.441925,379.834930 427.177094,387.072998 416.919769,396.554749 
	C401.915405,411.021667 391.384827,428.012054 383.691559,446.871887 
	C383.004669,448.555786 382.600739,450.391815 380.750427,451.681732 
	C378.913971,455.879883 377.437408,460.036652 375.824707,464.544830 
	C375.456177,465.284882 375.119995,465.466034 374.462769,465.164246 
	C375.854462,452.745331 379.326660,441.095001 384.130981,429.428192 
	C384.102692,428.439575 384.225189,427.874420 384.854004,427.194885 
z"
    />
    <path
      fill="#EDEFF1"
      opacity="1.000000"
      stroke="none"
      d="
M427.325714,374.906372 
	C423.947357,377.543427 420.922607,380.180420 417.590637,382.925903 
	C414.994720,383.876404 412.870209,386.205017 409.995605,383.449951 
	C409.743927,382.800537 409.721283,382.514954 409.865784,381.833557 
	C410.809570,379.067657 413.758453,379.222443 415.047852,376.952179 
	C415.515717,376.490021 415.808075,376.312988 416.331848,376.037903 
	C416.577942,375.964142 416.538361,376.061462 416.487000,376.050507 
	C416.863800,375.915924 417.250549,375.714752 417.816711,375.182983 
	C418.044342,374.942596 418.030548,375.031921 417.985382,375.030640 
	C420.782898,372.052826 423.980927,369.521637 427.760315,367.171967 
	C428.016510,366.946045 428.010406,367.013397 427.976624,367.011963 
	C428.199158,366.796265 428.443604,366.569214 428.836304,366.136810 
	C428.996490,365.944305 429.002808,366.002869 428.973877,365.997375 
	C430.009735,364.947906 431.118591,363.964172 432.752075,363.162994 
	C432.984924,362.946167 432.999878,363.000031 432.974182,362.988922 
	C440.993958,356.196075 450.598053,352.246185 460.414185,347.911072 
	C466.872894,345.549622 472.872925,343.024597 479.430176,340.924194 
	C484.691437,339.804230 489.445984,338.153839 494.781982,337.346741 
	C495.388733,337.140137 495.637054,337.068512 496.266357,336.909485 
	C498.484894,336.544342 500.304535,336.173279 502.533081,335.713806 
	C514.749146,333.972656 526.579407,332.774536 538.956055,333.556519 
	C539.666931,333.642029 539.948181,333.688873 540.642761,333.832092 
	C543.039429,334.838470 545.543579,334.489258 547.285706,336.796448 
	C547.600708,339.888916 545.753052,340.067413 543.847900,339.756744 
	C541.827881,339.427399 539.937012,339.324768 537.815796,340.769745 
	C529.095764,342.459412 520.566162,340.838287 512.223328,342.515930 
	C510.128418,342.937225 507.961273,343.047577 505.479248,343.715454 
	C501.690155,343.681061 498.355042,344.013428 494.715942,345.023590 
	C492.378143,345.441711 490.423798,345.815216 488.239655,346.787659 
	C476.797394,348.870667 466.665894,353.587494 456.403809,357.897552 
	C454.104218,358.863403 451.832428,359.862122 449.065521,360.625061 
	C448.690338,360.678467 449.034027,360.833160 448.865906,360.745972 
	C447.317047,363.128662 444.567017,363.411194 442.042419,364.637634 
	C441.695618,364.667389 441.997559,364.906647 441.846680,364.782471 
	C437.243805,368.375427 432.916565,372.263123 427.325714,374.906372 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M380.740204,451.783569 
	C388.241089,430.839630 399.064636,411.828369 415.632111,396.103699 
	C416.638306,395.579681 417.252625,395.809204 418.057861,396.504333 
	C418.341766,396.988251 418.405670,397.207184 418.437378,397.764893 
	C417.440033,400.390259 415.049774,401.586273 413.869171,404.120148 
	C412.947998,405.781311 412.047211,407.055786 410.837158,408.523621 
	C407.645020,411.839905 405.360687,415.384949 403.209412,419.415802 
	C402.771118,420.267822 402.444244,420.810913 402.003784,421.640259 
	C401.576233,422.436981 401.257355,422.943726 400.812622,423.714172 
	C400.462402,424.290375 400.247650,424.607605 399.864288,425.150909 
	C399.391388,425.845123 399.070221,426.302094 398.677826,427.045227 
	C398.299500,427.806305 398.028564,428.306824 397.666016,429.089294 
	C397.286407,429.896240 396.994995,430.418854 396.564880,431.218567 
	C395.865845,432.387451 395.267700,433.255157 394.601593,434.465118 
	C392.024658,438.493500 389.986664,442.428040 388.397949,446.937408 
	C388.292267,447.694855 388.214203,448.123566 388.044495,448.864746 
	C387.905182,449.385956 387.839294,449.591339 387.647064,450.094086 
	C387.230682,450.971039 386.876129,451.507019 386.174072,452.167236 
	C385.695312,452.358093 385.482178,452.369232 385.101074,452.107819 
	C383.648865,450.442291 382.371704,452.176880 380.740204,451.783569 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M409.665955,382.808319 
	C412.100189,384.734100 414.300140,383.082916 416.887085,383.023987 
	C405.442017,395.165894 394.260101,407.790161 386.902893,423.723145 
	C385.777222,423.906982 385.160004,423.449097 384.984680,422.164978 
	C384.635132,421.465912 384.526855,421.022736 384.812927,420.237000 
	C385.022705,419.944153 385.032776,420.023499 384.994080,420.033600 
	C385.419373,419.605255 385.500763,418.998566 385.840820,418.184845 
	C386.016052,417.941132 386.028320,418.019135 385.990234,418.029510 
	C386.419464,417.605408 386.500702,417.000519 386.834290,416.185944 
	C387.006226,415.940796 387.022308,416.012604 386.987244,416.023773 
	C387.740509,415.340057 387.405182,414.351166 387.801392,413.221985 
	C387.988586,412.939240 388.005554,413.001953 387.973511,413.007263 
	C388.427429,412.600616 388.500122,412.001740 388.815247,411.192322 
	C388.977417,410.945496 388.999817,410.997986 388.973206,410.987610 
	C389.531555,410.347351 389.988556,409.623901 390.537354,408.595886 
	C391.110260,406.602539 392.027863,405.099945 392.813599,403.195221 
	C393.000488,402.926117 393.010590,403.007843 392.969971,403.014526 
	C393.424011,402.606537 393.498413,401.993073 393.875732,401.202087 
	C394.791779,400.915588 395.475983,400.791199 395.911011,399.769958 
	C395.914795,399.269043 395.794739,399.003296 395.846558,398.335388 
	C396.018433,397.933167 396.029846,398.019958 395.987305,398.030151 
	C396.422485,397.604462 396.501953,396.996704 396.829041,396.176971 
	C397.069550,395.074097 397.342682,394.357697 398.574249,394.056030 
	C399.145386,393.803894 399.407684,393.575928 399.836182,393.145844 
	C400.006622,392.948608 400.012146,393.010498 399.981140,393.012238 
	C400.429260,392.597900 400.500092,391.999084 400.820740,391.193787 
	C400.986145,390.949524 401.003113,391.002472 400.975372,391.000427 
	C401.210358,390.795288 401.454102,390.571472 401.839203,390.137207 
	C401.999573,389.947479 402.005005,390.004456 401.976807,389.999756 
	C402.089752,389.846558 402.230927,389.698120 402.196777,389.297241 
	C402.045746,387.828857 402.534973,387.028900 404.183899,387.010773 
	C404.906433,386.900024 405.273895,386.695007 405.808472,386.174194 
	C406.019165,385.934937 406.012543,386.013214 405.973267,386.012268 
	C406.194641,385.798096 406.442657,385.571228 406.834106,385.131836 
	C406.992096,384.935089 406.998932,384.999054 406.967041,384.994781 
	C407.207947,384.796600 407.455902,384.574951 407.834900,384.130371 
	C407.990814,383.935150 407.999268,383.999146 407.967163,383.995789 
	C408.203186,383.784515 408.456238,383.559723 408.861633,383.126282 
	C409.031525,382.937195 409.019165,383.022247 408.976257,383.019775 
	C409.092773,382.892181 409.252197,382.767059 409.665955,382.808319 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M554.767212,644.626953 
	C568.100220,637.649963 580.823120,630.182373 592.098450,619.949585 
	C593.783691,619.425903 594.899780,619.850525 596.011597,621.240540 
	C596.452209,625.492432 592.402222,626.049194 590.710999,628.482422 
	C589.594421,630.088928 587.714905,630.983215 585.937866,632.464111 
	C580.457092,636.535278 575.100769,640.166687 569.441162,643.961731 
	C568.983521,644.301697 568.795898,644.430176 568.317261,644.733276 
	C565.533630,645.857727 563.572693,647.866150 560.577454,648.500549 
	C558.693054,648.270447 557.310486,647.538696 555.565796,646.839172 
	C555.104614,646.527039 554.938599,646.375671 554.642883,645.913452 
	C554.573608,645.386841 554.634033,645.171021 554.767212,644.626953 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M495.980225,337.844360 
	C491.052734,339.381714 485.931671,340.707428 480.410339,341.996674 
	C478.990662,340.180756 477.311432,338.693909 477.713196,336.068909 
	C484.975739,332.073975 492.647430,330.175476 500.676025,328.170868 
	C501.193878,327.835571 501.386322,327.701904 501.928070,327.531311 
	C504.518341,327.931122 507.129425,329.050232 506.822052,331.233002 
	C506.530273,333.304901 506.068268,336.640839 502.200653,336.875122 
	C500.416718,338.297485 498.560822,337.875183 496.451050,338.125092 
	C495.804657,337.800537 495.654816,337.335815 495.328583,337.118225 
	C495.065979,336.943115 495.023254,337.153687 495.464355,337.358337 
	C496.069092,337.638916 495.264099,337.218750 495.980225,337.844360 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M545.930847,633.249390 
	C549.390625,633.286255 549.564270,630.960022 549.260681,628.526428 
	C549.018860,626.587952 548.424683,624.693481 547.994690,622.387329 
	C552.890686,620.607483 557.485779,620.715942 561.190247,624.788147 
	C562.254883,625.958496 565.551758,627.475952 560.629150,628.109009 
	C559.143616,628.299988 560.420715,630.538086 561.011597,632.160522 
	C556.753540,635.259155 551.870239,636.338806 546.479797,637.119690 
	C545.920837,636.955261 545.770264,636.783997 545.723145,636.286621 
	C545.826721,635.960510 545.941162,635.484863 545.978516,635.243164 
	C546.145203,634.837341 546.274536,634.673340 546.197754,634.273438 
	C545.979431,633.862183 545.967102,633.686951 545.930847,633.249390 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M554.676880,646.105957 
	C556.686646,645.855957 558.145447,646.439575 559.748535,647.562256 
	C559.884705,649.352783 558.591309,649.922119 557.335693,650.921021 
	C556.882141,651.204956 556.693542,651.304932 556.200867,651.497314 
	C555.073608,651.596191 554.243896,651.370056 553.189697,651.851562 
	C552.950745,652.044312 552.973511,651.957947 553.017822,651.963135 
	C552.806885,652.193298 552.560608,652.427673 552.162598,652.869690 
	C552.001770,653.067688 552.000977,653.004639 552.029053,653.018982 
	C551.295898,653.670349 550.327820,653.385254 549.220581,653.833984 
	C548.966431,654.055359 548.988708,653.979370 549.027466,653.987671 
	C547.681824,654.940369 546.077698,655.258423 544.211792,655.824097 
	C543.940308,656.044373 543.974792,655.950195 544.023804,655.960632 
	C543.331482,656.688904 542.338196,656.381897 541.213074,656.828369 
	C540.949829,657.047363 540.978577,656.961975 541.022705,656.970825 
	C539.695190,657.949463 538.086670,658.251099 536.236084,658.834595 
	C535.977295,659.069214 535.999207,658.997681 536.035156,659.008057 
	C534.083435,659.638000 532.000610,659.861694 529.619019,660.505249 
	C527.502014,661.194702 525.706543,661.570618 523.499634,661.864624 
	C521.401917,661.340637 519.450806,661.545959 517.883423,659.807617 
	C519.431641,657.557434 521.718628,658.984558 523.830627,658.139221 
	C523.995483,656.860413 523.994995,655.886169 523.995483,654.546509 
	C525.742310,653.772278 527.488159,653.363342 529.619263,652.983643 
	C530.858643,653.206116 531.304382,653.804199 531.592957,654.836426 
	C531.621399,655.086548 531.432007,655.480286 531.655518,655.501221 
	C531.879028,655.522095 532.273499,655.224792 532.487305,655.074463 
	C533.180420,654.611084 533.566040,654.202759 534.083008,653.479736 
	C538.228088,651.874268 541.987427,649.948425 546.089294,649.011780 
	C547.409973,648.710144 548.711853,648.498047 549.498108,646.864014 
	C550.039795,646.359314 550.556580,646.195312 551.435974,646.244629 
	C552.864014,648.479675 553.500549,646.353821 554.676880,646.105957 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M408.971039,618.450745 
	C393.213226,608.891418 380.043671,596.778992 370.652649,580.571167 
	C371.122375,579.729736 371.653290,579.517151 372.680328,579.797241 
	C373.069611,580.023010 372.993195,580.005981 373.000549,579.967529 
	C373.406097,580.411499 373.476074,581.023682 373.853027,581.823059 
	C374.041534,582.057129 373.948059,582.040283 373.948120,581.992798 
	C374.246002,582.273438 374.577209,582.563660 375.250732,582.928833 
	C375.729279,583.106628 375.845032,583.225159 375.932434,583.517090 
	C376.134094,584.000427 376.354401,584.331787 376.846313,584.829834 
	C377.071472,585.027771 376.987457,585.010803 376.993896,584.968384 
	C377.402710,585.407288 377.476166,586.019836 377.853333,586.820679 
	C378.043365,587.054382 377.953583,587.034180 377.956421,586.988281 
	C378.193024,587.196106 378.432861,587.443970 378.871674,587.858398 
	C379.064453,588.031006 378.973602,588.026733 378.970947,587.981323 
	C379.210602,588.219421 379.475677,588.479675 379.991180,588.878540 
	C380.479523,589.107117 380.679413,589.255737 380.893372,589.661865 
	C381.185791,590.121826 381.414368,590.396973 381.859558,590.835571 
	C382.064819,591.008423 381.993683,591.006104 381.993713,590.970520 
	C382.205688,591.197144 382.431946,591.446228 382.869873,591.840759 
	C383.064850,592.001404 383.001617,592.000427 383.011719,591.970459 
	C383.414062,592.421265 383.475769,593.031250 383.855927,593.821228 
	C384.044922,594.051880 383.955109,594.036987 383.955139,593.991455 
	C384.205780,594.219788 384.473480,594.475769 384.980408,594.881165 
	C387.715515,596.151123 388.164825,598.980957 390.024384,600.784180 
	C394.833832,605.447815 399.920654,609.623840 405.868713,612.614807 
	C407.749298,613.560486 408.378906,615.105652 409.735657,616.797852 
	C409.847839,617.385437 409.628662,617.716553 408.971039,618.450745 
z"
    />
    <path
      fill="#05070B"
      opacity="1.000000"
      stroke="none"
      d="
M378.724335,469.228027 
	C380.767059,471.242889 379.427460,473.505402 378.842896,475.561340 
	C377.714386,479.530243 377.211975,482.556732 383.035187,481.202148 
	C383.421875,481.112213 383.928955,481.539764 384.706543,481.868591 
	C386.583801,484.887726 383.852966,486.501343 382.990326,488.632629 
	C382.318451,490.292572 381.154663,491.752686 380.018616,493.574280 
	C379.157349,494.600159 378.300995,495.262421 378.553955,496.798584 
	C377.775177,500.649048 376.761566,504.098297 375.284912,507.749207 
	C373.420837,508.773651 374.059021,507.233826 374.051422,506.873047 
	C373.867798,498.148346 374.225220,489.453339 375.723694,480.429840 
	C374.522827,478.026703 375.925629,476.394257 376.702515,474.309448 
	C378.054504,472.661804 378.048615,470.977295 378.724335,469.228027 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M384.860596,452.217590 
	C384.933105,451.835297 385.253784,451.910461 385.404358,451.977112 
	C386.213318,451.737274 386.503479,451.177704 386.790527,450.215271 
	C386.963379,449.933289 387.003876,449.990051 386.973206,449.973602 
	C389.551788,445.484070 392.924255,441.571991 396.337036,437.234375 
	C403.466858,429.522369 411.899750,424.579437 421.565521,421.765076 
	C425.540497,420.607666 426.281769,421.331482 425.510742,425.226868 
	C422.417816,426.555939 419.751404,428.199951 416.193329,427.772430 
	C414.315094,428.701172 412.781311,429.474548 411.087799,430.530365 
	C410.400879,431.844360 409.335602,432.133301 408.129578,432.753540 
	C407.838593,432.877533 407.946472,432.932495 407.915283,432.880615 
	C407.081726,434.180603 405.839752,434.989960 404.178467,435.789673 
	C403.613983,436.127899 403.318359,436.290283 402.942261,436.700500 
	C402.008331,441.438110 397.617065,442.737671 394.665344,445.701050 
	C391.103027,449.447052 387.890137,453.142487 384.365479,456.967773 
	C383.596863,455.483826 384.411133,454.077545 384.860596,452.217590 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M461.257721,348.680969 
	C451.571869,353.060150 442.255341,357.410034 433.305176,363.062927 
	C434.127228,360.825531 443.671783,352.604645 445.817535,352.868866 
	C448.947021,353.254242 449.554840,350.593231 451.299561,349.428436 
	C456.696320,345.825562 462.561859,343.105896 468.561279,339.937988 
	C470.630890,341.925232 470.111664,343.513031 467.935883,344.692841 
	C465.755371,345.875153 463.538544,346.989746 461.257721,348.680969 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M345.115540,477.188904 
	C344.892303,476.944824 344.581024,476.921753 344.428162,476.924286 
	C344.836426,474.432465 345.895721,471.925140 345.831360,469.446991 
	C345.752563,466.412598 347.025482,464.602570 349.587341,463.029907 
	C349.186523,474.666962 348.634064,486.364594 348.812012,498.556091 
	C348.361298,499.052673 347.938660,499.078033 347.199402,499.128204 
	C346.706146,499.283844 346.529510,499.414642 346.177551,499.266235 
	C347.938934,492.908081 344.484528,486.835358 345.968567,480.350372 
	C345.281494,479.221985 345.654785,478.242767 345.115540,477.188904 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M592.708313,613.235596 
	C593.908020,612.507202 594.783569,611.928589 595.828308,611.079651 
	C596.632690,610.190979 596.885315,609.235657 598.085815,608.810059 
	C598.421143,608.799988 598.706360,608.665527 598.762573,608.518188 
	C599.007385,606.557922 600.085693,605.477051 602.058350,604.815430 
	C602.401001,604.780701 602.618042,604.682434 602.685669,604.584412 
	C602.290405,603.364624 603.268188,603.077576 604.161865,602.680725 
	C604.459656,602.792175 604.696533,602.641296 604.740356,602.507935 
	C605.351624,599.590637 606.783203,597.474243 609.759155,596.172119 
	C611.642517,595.153137 612.725708,593.869690 613.570679,592.032166 
	C614.300659,591.622375 614.767944,591.466431 615.642578,591.587097 
	C617.700500,595.271973 614.240723,597.021545 613.020264,599.936768 
	C608.443176,605.227112 604.330566,610.451538 599.404297,614.931152 
	C597.950928,616.252747 596.769409,617.976501 594.280518,618.079468 
	C593.355103,617.028748 592.743591,616.157043 592.021729,614.984619 
	C591.909058,614.192871 592.074585,613.762878 592.708313,613.235596 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M593.800903,618.168457 
	C600.388977,612.719482 605.709351,606.474548 611.296143,600.050293 
	C612.464233,599.333374 613.381775,599.182312 614.902954,600.602173 
	C615.452698,602.400208 614.947021,603.153687 614.317017,604.195801 
	C614.042236,604.643921 613.927734,604.817871 613.632324,605.239929 
	C613.326660,605.645691 613.201904,605.803284 612.702148,606.415527 
	C611.837402,607.464294 611.408875,608.097717 610.961243,609.065186 
	C610.630432,609.669373 610.399719,609.988892 609.921143,610.490967 
	C609.551941,610.821411 609.397156,610.944946 609.002075,611.239258 
	C608.599915,611.518494 608.438171,611.627014 608.035767,611.896851 
	C607.149475,612.465637 606.536133,612.908813 605.953430,613.805908 
	C605.552979,614.349243 605.310425,614.650208 604.866821,615.164734 
	C604.543579,615.530884 604.410645,615.674683 603.863403,616.239868 
	C603.308838,616.802551 603.168579,616.943787 602.607666,617.520935 
	C602.046265,618.107666 601.905396,618.258423 601.330383,618.871216 
	C599.601562,620.488708 598.544373,621.988342 596.337219,622.140259 
	C595.047424,621.362793 594.064941,620.711548 592.741577,619.955566 
	C592.812073,619.379333 593.223572,618.907898 593.800903,618.168457 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M378.564819,494.217651 
	C380.827972,490.240173 382.875610,486.478668 384.978149,482.363892 
	C387.951202,480.390228 389.927368,477.429260 393.498291,476.183136 
	C393.699219,476.687531 393.801025,477.556152 393.433594,477.863220 
	C383.900818,485.829163 380.037048,496.808929 376.979614,508.664551 
	C376.399139,508.729645 375.967041,508.437469 375.282227,508.078339 
	C374.915375,503.994263 376.478394,500.392853 377.836670,496.366516 
	C377.535889,495.321106 377.845856,494.846405 378.564819,494.217651 
z"
    />
    <path
      fill="#F1F6F7"
      opacity="1.000000"
      stroke="none"
      d="
M502.429321,328.313232 
	C501.869263,328.149750 501.694031,328.121918 501.260101,328.031677 
	C501.519836,325.042450 498.975311,325.211456 497.182495,324.110901 
	C498.868927,321.361938 501.839630,321.584167 504.411682,321.130798 
	C506.184540,320.818359 507.962189,320.391663 510.154572,320.289917 
	C513.693176,323.608368 517.295898,321.320587 520.676147,321.106628 
	C523.604675,320.921265 526.544495,319.826935 529.321777,322.429688 
	C529.465454,323.062469 529.456421,323.329620 529.259766,323.955414 
	C528.197998,325.061981 527.134277,325.260437 525.633545,325.402435 
	C523.655457,325.662628 522.015198,325.196869 520.098389,326.012207 
	C514.086304,326.961243 508.632568,329.031738 502.429321,328.313232 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M461.365265,348.904724 
	C462.121674,345.934662 464.921967,344.561737 467.403687,343.490845 
	C469.256470,342.691315 468.817169,341.745361 468.939545,340.255127 
	C468.921722,339.911499 468.937164,339.957794 468.917725,339.944214 
	C469.410675,339.613495 470.005371,339.523987 470.761536,339.110535 
	C472.758392,337.312958 475.044220,336.730072 477.538696,335.806946 
	C477.711975,338.024597 481.399353,338.696838 480.096008,341.665466 
	C473.918945,344.272339 467.827820,346.584534 461.365265,348.904724 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M511.284363,321.459076 
	C506.501465,321.928284 502.031128,322.636536 497.264191,323.581512 
	C496.050232,324.079315 495.132874,324.340393 494.215515,324.601471 
	C494.114197,324.145111 494.012878,323.688751 493.911560,323.232422 
	C505.211456,319.098083 517.139832,317.823669 529.595581,316.823883 
	C533.271912,316.425018 536.776489,313.794556 539.371094,318.412048 
	C536.834167,321.339569 533.736877,320.451172 530.804077,320.450317 
	C528.972351,320.449768 527.138977,320.392334 524.891235,320.707520 
	C522.513184,320.943024 520.580505,321.282349 518.226318,321.470093 
	C516.769226,321.461853 515.712524,321.403534 514.304199,321.812042 
	C513.131348,322.028839 512.354309,321.970398 511.284363,321.459076 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M350.346832,436.927795 
	C349.221008,433.549377 350.755127,430.684143 351.934692,427.403381 
	C354.097992,427.050415 355.133026,424.731354 357.587677,424.961060 
	C356.680115,430.082855 355.413574,435.184357 353.989990,440.571045 
	C351.387177,440.706665 354.085022,436.377991 350.346832,436.927795 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M565.339783,303.303284 
	C557.573792,304.050110 549.967407,304.498962 542.223572,304.955933 
	C542.626953,302.197296 544.867126,302.317200 546.810181,301.461365 
	C548.757996,301.148102 550.446533,301.052002 552.552795,300.886963 
	C556.810730,300.759186 560.676636,300.084747 564.724731,301.566986 
	C565.343323,302.143250 565.514343,302.533508 565.339783,303.303284 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M549.232300,646.601990 
	C549.849976,647.487732 549.952576,649.256042 549.288025,649.174988 
	C544.492126,648.589478 541.543945,654.483582 536.441895,652.681396 
	C535.687744,652.414978 535.237061,653.619080 535.000549,654.769653 
	C534.211914,655.671387 533.282532,655.381775 532.207214,655.812988 
	C531.952515,656.013794 532.004578,656.011353 532.018799,655.989502 
	C531.848022,656.114624 531.663086,656.261597 531.243408,656.143066 
	C530.753723,655.110901 530.498779,654.344177 530.124207,653.295166 
	C536.316956,650.902100 542.629333,648.791382 549.232300,646.601990 
z"
    />
    <path
      fill="#383737"
      opacity="1.000000"
      stroke="none"
      d="
M423.240784,629.270935 
	C423.264404,628.552856 423.527039,628.108459 423.873352,627.419189 
	C424.554840,626.599426 425.122650,626.850647 425.864075,627.511719 
	C430.987244,630.202209 437.153137,629.445679 441.689697,633.893433 
	C442.105530,634.263550 442.223389,634.435669 442.157532,634.861084 
	C435.506775,634.261719 429.631378,631.444702 423.240784,629.270935 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M357.535522,557.092285 
	C358.064484,555.994995 358.437256,555.229736 358.988647,554.077209 
	C362.044037,559.532104 364.920837,565.374329 367.943542,571.541992 
	C368.342316,572.351868 368.287445,572.783203 367.504364,573.230591 
	C362.590668,568.811462 359.712402,563.285400 357.535522,557.092285 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M539.089783,319.079651 
	C537.052856,314.595581 533.401001,318.197906 530.479370,316.869690 
	C531.433533,315.936066 532.814819,315.095001 534.631348,314.024506 
	C535.066589,313.795044 535.515930,313.890533 535.762329,313.863129 
	C536.165222,313.742584 536.321716,313.649414 536.690063,313.352783 
	C537.333862,312.694641 537.687683,312.183197 538.170776,311.375336 
	C539.031799,310.696014 539.222656,310.170258 538.864258,309.240845 
	C541.271362,307.555603 541.879333,308.649231 542.018677,310.960388 
	C543.246582,311.823578 544.056458,312.734497 544.944458,313.949310 
	C545.024414,314.600128 545.016418,314.957214 545.010742,315.582153 
	C543.964355,317.447266 541.958496,317.719452 540.361084,319.141968 
	C539.819336,319.299347 539.587646,319.301178 539.089783,319.079651 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M426.188934,627.497559 
	C425.539581,627.571045 425.036530,627.355713 424.245270,627.157288 
	C422.074066,626.349426 420.191071,625.524536 418.138672,624.401855 
	C420.113007,622.959290 421.646515,619.907410 424.889648,622.687805 
	C426.577209,624.134644 427.672455,625.096558 426.188934,627.497559 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M427.754456,366.621399 
	C425.532288,370.389008 422.056244,372.688141 418.295105,375.048553 
	C419.837769,370.711243 423.833710,368.688416 427.754456,366.621399 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M492.787537,313.636902 
	C489.918610,313.625702 489.583984,311.898376 490.074402,309.936218 
	C490.443512,308.459625 492.064636,308.418152 492.932220,308.918488 
	C495.052795,310.141418 494.226074,311.836487 492.787537,313.636902 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M535.229126,314.133759 
	C535.114624,313.870972 535.007507,313.845398 535.037048,313.820221 
	C534.889221,313.588409 534.695374,313.392334 534.550476,313.165009 
	C534.525757,313.126251 534.710449,312.953918 534.850708,312.618774 
	C535.432983,311.608337 535.963013,310.822479 536.745361,310.008728 
	C537.206726,310.613922 537.105347,309.710693 537.341064,310.325531 
	C537.582397,310.955048 537.488220,311.561493 537.473938,312.183624 
	C537.574707,311.609497 537.774963,311.098663 538.671265,310.911133 
	C539.018250,312.220428 539.683960,313.721588 537.313965,314.215454 
	C536.422729,314.670624 535.886292,314.745819 535.229126,314.133759 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M442.203766,635.384399 
	C441.974823,634.943909 441.975891,634.773376 441.982330,634.347168 
	C444.771820,635.017761 447.555969,635.944031 450.686005,637.007324 
	C451.208618,637.331970 451.385315,637.519714 451.250427,637.936401 
	C447.873535,638.108276 445.244232,636.574524 442.203766,635.384399 
z"
    />
    <path
      fill="#262524"
      opacity="1.000000"
      stroke="none"
      d="
M451.141693,638.412476 
	C450.968536,637.996460 450.998230,637.827576 451.029907,637.401489 
	C453.204865,636.523193 455.137512,637.164856 457.181885,638.607971 
	C457.212128,639.549866 456.871216,640.046326 456.103943,640.582092 
	C454.175140,640.386292 452.421844,640.257202 451.141693,638.412476 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M366.948425,573.257568 
	C367.458710,572.829346 367.694000,572.608093 367.966766,572.081787 
	C369.334473,573.695190 370.579468,575.523010 371.908142,577.676575 
	C371.841156,578.142944 371.690491,578.283508 371.239807,578.498291 
	C370.738647,578.528076 370.537537,578.483521 370.094086,578.247192 
	C369.851776,578.055481 369.953003,578.029175 369.959839,578.081055 
	C369.791687,577.828003 369.577454,577.554382 369.097229,577.174561 
	C368.877045,577.031921 368.972687,577.028809 368.969177,577.076538 
	C368.791443,576.824097 368.576874,576.556274 368.106934,576.176514 
	C367.891998,576.032166 367.968506,576.024902 367.957153,576.061646 
	C367.507965,575.270325 366.963287,574.492188 366.948425,573.257568 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M376.601624,474.051819 
	C376.621094,475.749908 376.302673,477.521362 375.846313,479.657990 
	C372.128784,477.702698 375.327972,476.020477 376.601624,474.051819 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M392.630859,402.900726 
	C393.386810,405.068237 391.755981,406.493408 390.972168,408.601837 
	C390.682495,409.095520 390.523132,409.253296 390.104980,409.196228 
	C390.651184,406.946045 391.456207,404.910675 392.630859,402.900726 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M352.715942,446.674133 
	C350.483337,445.397522 350.913361,443.931671 353.056854,442.543884 
	C353.228363,443.847931 353.053345,445.122253 352.715942,446.674133 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M432.696045,362.634216 
	C432.657318,364.836121 430.886627,365.209045 429.291260,366.061157 
	C428.772552,363.517151 431.208313,363.503235 432.696045,362.634216 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M456.181885,640.799194 
	C456.133118,640.169556 456.434265,639.613953 457.059265,638.998840 
	C458.493683,639.067261 459.604279,639.195190 460.727356,639.815613 
	C459.337189,640.496521 457.934479,640.684875 456.181885,640.799194 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M592.851318,612.849854 
	C592.714539,613.457153 592.396606,613.828430 591.994995,614.441895 
	C591.911377,614.683960 591.467957,614.839417 591.232971,614.838867 
	C591.233826,613.855164 590.477722,612.126404 592.851318,612.849854 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M370.702515,578.379150 
	C371.045105,578.043640 371.234344,578.033020 371.707733,578.012329 
	C372.351501,578.475220 372.711182,578.948059 373.070221,579.721985 
	C372.518829,579.904846 371.968079,579.786743 371.057800,579.938904 
	C370.698334,580.209167 370.744202,580.097961 370.803802,580.089844 
	C370.636902,579.650269 370.537811,579.189758 370.702515,578.379150 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M374.122070,465.255432 
	C374.564789,464.775452 374.883636,464.723022 375.453094,464.829498 
	C375.234161,465.808228 375.608215,467.400177 374.395050,467.552307 
	C373.669525,467.643280 374.023071,466.305298 374.122070,465.255432 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M555.001892,646.004272 
	C554.270813,647.180237 553.521729,648.327881 551.834351,646.905884 
	C551.895447,646.298218 552.007568,646.147461 552.258301,645.954956 
	C552.971619,645.789307 553.562622,645.679932 554.560608,645.772217 
	C554.966553,645.968506 554.990540,645.991577 555.001892,646.004272 
z"
    />
    <path
      fill="#F1F6F7"
      opacity="1.000000"
      stroke="none"
      d="
M521.063354,301.647827 
	C521.559814,300.860687 522.813477,300.875336 522.838013,301.351776 
	C522.892944,302.417694 521.838623,301.819092 521.063354,301.647827 
z"
    />
    <path
      fill="#E7E6E5"
      opacity="1.000000"
      stroke="none"
      d="
M384.790466,427.048828 
	C384.967651,427.594452 384.842224,428.174561 384.457581,428.906708 
	C383.038025,428.216064 383.106781,427.553558 384.790466,427.048828 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M545.539612,636.015015 
	C545.865601,636.155212 545.904419,636.349976 546.007324,636.835693 
	C545.572998,637.400085 545.074646,637.673584 544.283325,637.896118 
	C544.080383,637.018188 544.363464,636.328430 545.539612,636.015015 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M461.008850,639.994507 
	C462.079315,639.958618 463.059479,640.026794 464.508301,640.127502 
	C463.229828,641.296387 462.225281,641.029602 460.980957,640.539429 
	C460.739868,640.308167 460.918549,640.098572 461.008850,639.994507 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M387.668091,413.139343 
	C388.526642,413.949493 387.992035,414.798126 387.265045,415.849915 
	C385.932037,414.967468 387.102234,414.221283 387.668091,413.139343 
z"
    />
    <path
      fill="#F1F6F7"
      opacity="1.000000"
      stroke="none"
      d="
M530.126709,297.909180 
	C530.629822,297.055145 531.700195,297.051239 533.095703,297.652344 
	C532.441528,297.889374 531.514893,297.922333 530.126709,297.909180 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M389.566528,409.108948 
	C389.846161,408.981384 390.343506,408.948608 390.592651,408.943176 
	C390.413818,409.726349 389.985840,410.514954 389.252228,411.140442 
	C389.060059,410.397003 389.173462,409.816772 389.566528,409.108948 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M429.762329,625.753113 
	C429.143219,625.815308 428.115509,626.156494 428.578644,625.255981 
	C428.915863,624.600342 429.581360,625.000122 429.762329,625.753113 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M393.690277,400.954987 
	C393.920563,401.636108 393.771179,402.299072 393.275574,402.991608 
	C392.487732,402.222382 392.879333,401.575958 393.690277,400.954987 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M384.694427,419.933624 
	C384.997131,420.367615 384.971527,420.791077 384.952637,421.532715 
	C383.636505,421.554474 383.974121,420.747162 384.694427,419.933624 
z"
    />
    <path
      fill="#F1F6F7"
      opacity="1.000000"
      stroke="none"
      d="
M535.073975,297.898987 
	C535.281860,297.239197 536.009888,297.110809 537.049194,297.687195 
	C536.688477,297.904022 536.100891,297.924072 535.073975,297.898987 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M386.623413,415.898285 
	C386.847290,416.545105 386.688324,417.149414 386.240753,417.896790 
	C386.048279,417.311859 386.144440,416.583801 386.623413,415.898285 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M388.668579,411.052612 
	C389.533539,411.731903 389.107544,412.396667 388.328156,413.039062 
	C388.080872,412.394928 388.220276,411.777344 388.668579,411.052612 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M554.934875,645.551025 
	C554.394531,645.996643 553.822449,646.024719 552.816040,645.978516 
	C552.415588,644.798096 553.451294,644.950012 554.450439,644.432739 
	C554.861023,644.576965 554.882141,644.855286 554.934875,645.551025 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M385.647766,417.949768 
	C385.853546,418.544861 385.691071,419.148560 385.241943,419.898010 
	C385.063416,419.348602 385.171448,418.653534 385.647766,417.949768 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M384.208557,593.739197 
	C383.510071,593.526367 382.975189,593.000854 382.731079,592.207886 
	C383.471954,592.435852 383.922058,592.931152 384.208557,593.739197 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M378.326752,586.777222 
	C377.578796,586.552795 377.114227,586.051147 376.825012,585.237793 
	C377.536926,585.450684 378.073547,585.975342 378.326752,586.777222 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M374.314972,581.773865 
	C373.559998,581.549194 373.078461,581.041199 372.802429,580.231201 
	C373.534760,580.449646 374.061584,580.970093 374.314972,581.773865 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M396.665710,396.055786 
	C396.863220,396.556000 396.713623,397.174561 396.254395,397.916748 
	C395.197144,397.243561 396.012665,396.759857 396.665710,396.055786 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M400.676697,391.084930 
	C401.497803,391.693848 401.041473,392.244354 400.282776,392.907471 
	C399.507355,392.280792 399.946838,391.752777 400.676697,391.084930 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M470.654449,338.907776 
	C470.481781,339.231079 470.002441,339.570190 469.210693,339.919983 
	C469.381439,339.594940 469.864624,339.259247 470.654449,338.907776 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M409.966064,382.591766 
	C409.920349,382.974731 409.428894,383.043701 409.181152,383.030518 
	C409.088470,382.618500 409.243530,382.219666 409.715790,381.629272 
	C410.025909,381.694763 410.018829,381.951782 409.966064,382.591766 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M416.345276,376.069946 
	C416.288055,376.145966 415.998138,376.327759 415.458435,376.576233 
	C415.510010,376.487152 415.811310,376.331451 416.345276,376.069946 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M417.661743,374.868530 
	C417.759949,375.293274 417.475616,375.643982 416.813416,376.017090 
	C416.716827,375.624542 416.998016,375.209503 417.661743,374.868530 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M385.149658,594.695679 
	C384.834473,594.882935 384.466217,594.717529 384.026581,594.249023 
	C384.335663,594.078308 384.716156,594.210632 385.149658,594.695679 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M377.031586,584.703674 
	C376.466797,585.234558 376.053619,585.037842 375.892426,584.040649 
	C376.299652,583.887939 376.645660,584.133789 377.031586,584.703674 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M379.125214,587.632935 
	C378.718536,587.869568 378.372620,587.708130 377.992981,587.244507 
	C378.492065,586.520325 378.901703,586.614807 379.125214,587.632935 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M380.182312,588.683167 
	C379.816864,588.904480 379.415161,588.768311 378.990845,588.284058 
	C379.360870,588.065857 379.753479,588.195801 380.182312,588.683167 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M375.401123,582.665710 
	C375.090302,582.913940 374.616577,582.791443 374.045532,582.307129 
	C374.505249,581.593201 374.935486,581.708923 375.401123,582.665710 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M383.029236,591.709961 
	C382.533905,592.430420 382.116028,592.341675 381.903320,591.331421 
	C382.327057,591.096130 382.660309,591.257385 383.029236,591.709961 
z"
    />
    <path
      fill="#5A5959"
      opacity="1.000000"
      stroke="none"
      d="
M382.042358,590.697510 
	C381.520630,591.269714 381.117188,591.128967 380.911743,590.211060 
	C381.318970,590.020386 381.669434,590.203430 382.042358,590.697510 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M428.592407,365.767120 
	C428.859161,366.302063 428.721863,366.659821 428.263672,367.014099 
	C427.485901,366.443878 427.567444,365.970306 428.592407,365.767120 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M409.976929,616.968262 
	C410.087128,616.924316 410.175415,616.046509 410.215729,616.983887 
	C410.067017,617.054321 410.004883,616.999084 409.976929,616.968262 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M431.268219,626.283081 
	C431.570251,626.288940 431.641632,626.501160 431.713043,626.713379 
	C431.487915,626.638672 431.262756,626.564026 431.268219,626.283081 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M395.622406,397.882263 
	C396.025330,398.199615 396.032227,398.466095 396.036987,399.133667 
	C395.179810,399.244904 394.640442,398.805176 395.622406,397.882263 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M401.973145,389.280090 
	C402.707794,389.154144 403.190033,389.363739 402.250092,390.059479 
	C401.948578,389.995026 401.924896,389.515350 401.973145,389.280090 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M405.646454,385.842224 
	C405.763824,386.299530 405.508453,386.664093 404.877075,387.033569 
	C404.758606,386.608856 405.016174,386.179199 405.646454,385.842224 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M406.625061,384.790527 
	C406.845947,385.282013 406.699768,385.628937 406.243835,385.993561 
	C406.041992,385.556183 406.149994,385.101105 406.625061,384.790527 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M407.608063,383.790466 
	C407.853546,384.301849 407.716278,384.668579 407.257080,385.012878 
	C406.573822,384.443420 406.671326,383.995331 407.608063,383.790466 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M408.649292,382.849426 
	C408.868683,383.294464 408.705841,383.651764 408.239044,384.000732 
	C407.576630,383.455627 407.686005,383.045013 408.649292,382.849426 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M399.673157,392.893860 
	C399.827209,393.276642 399.647766,393.604645 399.173279,393.979492 
	C399.032043,393.630554 399.185852,393.234802 399.673157,392.893860 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M401.724701,390.000366 
	C401.871826,390.343842 401.744049,390.740234 401.281982,391.067505 
	C401.115051,390.683350 401.282440,390.368286 401.724701,390.000366 
z"
    />
    <path
      fill="#56D1CE"
      opacity="1.000000"
      stroke="none"
      d="
M545.290039,297.667419 
	C545.265015,297.327454 545.485352,297.269928 545.825806,297.519958 
	C545.898132,297.627747 545.487610,297.750061 545.290039,297.667419 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M420.884033,668.828430 
	C412.881927,667.376343 405.907715,663.201477 399.041199,658.366943 
	C399.905304,657.523743 401.132355,659.270203 401.975464,657.255127 
	C407.315002,659.924377 413.247559,661.794128 417.541260,666.380798 
	C418.412811,667.311829 420.095337,667.165161 420.884033,668.828430 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M394.350311,657.141846 
	C393.663940,653.406189 388.789917,654.894409 388.038330,651.348145 
	C391.273773,652.140686 394.567322,653.260742 397.834290,654.453552 
	C399.187408,654.947693 400.309113,655.681580 399.122009,657.701294 
	C397.495392,658.084595 396.018127,658.124573 394.350311,657.141846 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M384.555420,635.396851 
	C384.252960,634.363464 384.217621,633.569946 384.215515,632.472839 
	C387.112671,633.664673 388.982819,635.899414 389.754333,639.400757 
	C386.843018,639.396667 386.317413,636.881104 384.555420,635.396851 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M425.356384,673.022400 
	C424.998352,672.956482 424.503601,672.973633 424.256287,672.983765 
	C423.889740,672.829468 423.770538,672.664978 423.824402,672.249634 
	C423.434998,671.494934 422.872528,670.991028 422.157898,670.243530 
	C424.122803,668.546753 426.251648,669.081238 428.756165,670.022583 
	C429.548981,670.769104 429.556213,671.451782 428.832947,672.370056 
	C427.662598,673.095886 426.712769,673.236084 425.356384,673.022400 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M427.653625,672.360840 
	C428.004303,671.541992 428.475525,671.128662 429.038269,670.399109 
	C429.571716,670.320312 430.013641,670.557739 430.678223,670.914673 
	C430.662018,671.525085 430.423157,672.016052 430.091370,672.765259 
	C429.174103,673.655029 428.443085,673.479614 427.653625,672.360840 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M442.064514,662.655762 
	C441.186981,662.904480 440.560974,662.833740 440.485901,662.000305 
	C440.469025,661.812866 440.816956,661.433289 441.022583,661.413818 
	C441.749390,661.345154 442.206543,661.713562 442.064514,662.655762 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M437.013123,660.738098 
	C436.957672,660.102051 437.058105,660.076660 437.013123,660.738098 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M401.957764,657.030396 
	C401.812012,656.892212 401.709778,656.713806 401.607574,656.535400 
	C401.711792,656.570679 401.816040,656.605957 402.000244,656.771545 
	C402.080170,656.901855 402.001282,656.990234 401.957764,657.030396 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M319.192871,479.783936 
	C319.089783,477.095184 320.501862,476.534821 322.890198,477.747437 
	C322.139374,479.158569 320.916107,479.647400 319.192871,479.783936 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M561.200806,688.732422 
	C560.480042,689.232483 559.998718,689.530518 559.225586,689.888428 
	C549.186951,690.333984 540.707703,696.589294 530.382996,696.018860 
	C529.019165,696.082275 527.595154,695.767578 527.112976,696.302246 
	C524.552673,699.141418 522.924194,699.635071 521.395996,695.332703 
	C520.612183,693.126038 518.124268,694.467224 517.078003,695.233948 
	C513.398804,697.929565 510.121979,695.345581 506.698303,694.912292 
	C505.205780,694.723450 505.031647,693.533386 505.029449,692.290894 
	C505.026825,690.812683 505.706024,690.518250 507.175690,690.354187 
	C509.843689,690.056335 513.012085,690.398254 514.284058,686.948792 
	C514.410400,686.606018 515.270935,686.149475 515.447937,686.266052 
	C519.912476,689.206482 520.142029,684.295898 523.225586,682.462219 
	C517.586670,681.474365 513.233276,683.095581 508.446350,683.800964 
	C507.680542,684.362244 507.198761,684.509888 506.329834,684.174255 
	C506.452148,682.132629 504.556030,678.762085 508.940155,679.572754 
	C509.001831,679.584229 509.325409,678.548828 509.312225,678.543640 
	C506.368073,677.377686 507.323486,675.567444 508.731659,674.015564 
	C510.714966,671.829712 512.237305,674.202820 513.940857,674.793457 
	C514.393616,674.950439 514.916016,674.906555 515.792969,674.942688 
	C517.109253,674.357422 518.169250,674.327759 519.531311,674.179443 
	C522.375793,673.726074 524.034485,671.697144 526.536133,670.508789 
	C528.504883,669.295105 530.245300,668.887756 532.378906,670.010742 
	C534.779724,671.181091 536.224976,669.247864 538.332153,668.762817 
	C539.863159,667.883728 541.203979,668.486084 542.774292,668.160950 
	C546.188110,667.685913 546.712524,664.020142 549.703857,663.032898 
	C550.517944,662.257019 551.015625,661.562256 552.173706,661.189697 
	C553.212585,661.016174 554.001160,661.150513 554.874756,660.492310 
	C555.212280,660.158203 555.348389,660.022034 555.713074,659.701660 
	C556.116882,659.425781 556.292053,659.334045 556.750488,659.148438 
	C557.421265,658.970398 557.812744,658.911621 558.500122,658.848755 
	C559.368774,658.778992 559.945557,658.740479 560.777344,658.483643 
	C561.222046,658.278748 561.397949,658.187988 561.833862,657.944763 
	C562.448303,657.575745 562.802979,657.359497 563.428711,656.961121 
	C567.423889,650.741882 574.945923,648.791992 578.734436,642.815552 
	C579.096985,642.243530 579.854675,641.704895 580.398315,641.910217 
	C584.863220,643.597046 585.596069,639.881226 587.267151,637.369446 
	C590.510315,632.494690 594.765991,628.543762 599.298523,624.921387 
	C602.743835,622.167908 605.390320,618.964661 606.530334,614.297302 
	C606.618591,613.495789 606.800476,613.083374 607.359436,612.514221 
	C608.876465,611.618408 610.753235,611.633850 610.705688,609.207153 
	C610.652893,607.950134 611.252625,607.262268 611.842041,606.233276 
	C612.054077,605.981689 611.997925,605.992432 612.011536,606.017578 
	C612.224792,605.788696 612.443115,605.552002 612.862915,605.174438 
	C613.045593,605.016296 613.000000,604.999634 613.011108,605.021118 
	C613.578674,604.191589 614.139221,603.343201 614.848877,602.232178 
	C615.049377,602.001953 614.996643,601.999695 615.008911,602.023010 
	C618.171875,598.352478 620.429077,594.098145 622.865845,589.526062 
	C623.207947,589.020020 623.363098,588.827026 623.807922,588.393921 
	C624.787537,587.760071 625.492493,587.567261 626.459717,588.307983 
	C627.383728,591.561707 627.214111,594.571472 627.384399,597.552795 
	C627.431763,598.381653 627.560181,599.410828 627.084229,599.996521 
	C624.375793,603.329712 621.675293,606.477478 628.329895,608.508667 
	C629.058167,608.730957 628.931946,610.371094 628.571594,611.314941 
	C626.845215,615.836975 625.153809,620.526794 621.093750,623.462402 
	C618.103455,625.624573 616.795776,628.244690 616.353577,631.763550 
	C615.632751,637.501404 614.953064,638.126892 608.907593,638.843079 
	C605.904602,639.198792 605.512512,641.037598 605.643188,643.400635 
	C605.753113,645.387573 606.295349,647.346863 605.963196,649.362732 
	C605.180359,654.114136 602.508240,656.034302 597.356323,655.117981 
	C596.642090,654.494202 596.390198,653.945923 596.043701,653.468445 
	C595.656738,652.935242 595.084595,652.921631 595.665894,653.247925 
	C596.247192,653.574280 594.845459,653.684998 595.429138,653.930054 
	C595.991272,656.822815 598.219604,658.334534 599.877014,660.672546 
	C600.479919,662.552673 599.434814,663.458496 598.367554,664.195984 
	C592.703247,668.109863 587.752686,673.002258 581.364868,676.519226 
	C580.821655,676.760620 580.595947,676.838379 579.658203,676.922241 
	C574.532227,679.886536 570.118347,682.844849 565.393677,685.949768 
	C563.952576,687.033264 562.578430,687.605164 561.200806,688.732422 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M553.297546,661.837952 
	C552.220459,662.255737 551.419983,662.492249 550.328369,662.849365 
	C550.713135,660.184326 552.233154,658.015686 554.755005,656.608276 
	C556.712830,655.515686 556.527100,654.267212 555.030884,652.600952 
	C554.927185,651.860718 555.101562,651.534668 555.769165,651.154175 
	C556.008118,650.951355 556.007507,651.010559 555.978088,651.007080 
	C559.974060,649.115784 563.793213,646.862183 567.788757,644.227539 
	C568.044006,643.986206 567.984131,643.979858 567.979614,644.009644 
	C573.801880,641.004578 579.144043,637.239807 584.575439,632.981384 
	C590.494751,628.460022 596.527893,624.584106 600.784180,618.242004 
	C600.974976,617.953064 600.997314,618.004272 600.969482,618.001709 
	C601.207825,617.797546 601.453552,617.573425 601.838318,617.134888 
	C601.997925,616.942932 602.004089,617.003540 601.973755,617.000488 
	C602.203186,616.788025 602.450012,616.564453 602.843140,616.133362 
	C603.004639,615.942505 603.010803,616.010742 602.976929,616.015503 
	C603.278137,615.740051 603.569641,615.419983 603.907349,614.746643 
	C604.026733,614.255920 604.118042,614.120239 604.391602,613.991211 
	C605.154785,614.062378 605.784973,613.840515 606.742310,613.933105 
	C608.522522,616.546387 607.991943,618.443359 603.825806,622.640930 
	C599.992188,626.503418 595.807922,630.015442 591.873596,633.781616 
	C589.732361,635.831360 588.015930,638.151428 586.838867,641.008362 
	C585.946045,643.175476 584.331421,645.884155 581.216492,643.323181 
	C580.247070,642.526245 579.407410,642.818909 579.364197,643.551636 
	C579.108093,647.894958 575.232483,648.464050 572.543274,649.859070 
	C568.782471,651.809937 567.889160,656.539551 563.496826,657.993347 
	C562.855408,658.200684 562.586121,658.428162 562.154907,658.867981 
	C561.984192,659.069885 561.993896,658.993103 562.032471,658.996765 
	C561.352234,659.646118 560.438171,659.420532 559.350098,659.752686 
	C558.623535,659.979431 558.151306,660.006775 557.324219,660.024963 
	C556.864136,659.606079 556.607605,659.456665 556.078552,659.784058 
	C555.957275,660.000610 555.970764,660.482117 555.973022,660.722900 
	C555.351990,661.808289 554.481262,661.795410 553.297546,661.837952 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M529.996704,696.381348 
	C530.200195,695.562317 530.428162,694.720642 530.578369,694.734253 
	C537.928955,695.402222 544.101196,691.419128 550.851501,689.759521 
	C553.117432,689.202393 555.682373,689.861755 558.522217,689.956665 
	C556.696472,693.183533 553.307556,695.074219 550.034302,697.618164 
	C542.984680,697.809387 536.532593,699.536377 530.203186,701.869141 
	C528.363892,702.546997 526.517395,703.205505 524.294739,703.866333 
	C524.719727,700.450928 530.723022,701.484985 529.996704,696.381348 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M462.766907,709.617554 
	C463.722656,709.851013 464.493134,709.898560 465.649292,709.908447 
	C466.474457,709.894897 466.913971,709.918945 467.684204,709.943604 
	C468.606171,709.963440 469.132965,709.795471 469.779968,709.198853 
	C470.061035,708.709229 470.222260,708.479858 470.647552,708.202637 
	C471.987915,709.037109 472.980530,710.206238 474.733276,710.835205 
	C474.973846,711.049011 474.995270,711.000000 474.981903,711.025208 
	C475.729004,711.516663 476.210297,711.063904 476.936401,710.320862 
	C477.932281,710.397400 478.659943,710.687195 479.687408,710.959229 
	C480.166016,710.770691 480.344788,710.599854 480.761780,710.713257 
	C488.125061,711.309631 495.003387,708.941589 502.552032,709.076843 
	C503.596436,709.062561 504.136230,709.222900 504.802429,709.821899 
	C505.000702,710.033630 504.989960,710.491089 504.978333,710.718994 
	C500.818878,711.778137 496.671021,712.609375 492.261902,713.710938 
	C479.375732,714.704712 467.045349,711.787537 454.549316,710.906433 
	C453.462280,710.829773 452.423096,710.074463 451.148285,709.310791 
	C454.258698,710.008362 457.186401,707.714111 460.720154,707.978760 
	C461.320221,708.307922 461.626160,708.476257 462.143494,708.787842 
	C462.416626,709.123169 462.499146,709.277466 462.766907,709.617554 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M542.691956,667.852600 
	C542.428711,669.999695 541.038452,669.601013 539.306641,669.077271 
	C537.255676,669.838867 535.117737,669.195618 533.267822,670.743164 
	C531.227295,670.583313 529.457703,669.911255 527.349304,670.971069 
	C524.992188,672.541626 521.883667,671.697021 519.942993,674.217407 
	C518.677429,674.226562 517.736328,674.194458 516.564392,673.619690 
	C512.676025,669.114746 516.484619,667.991211 519.311462,667.515869 
	C526.947937,666.231506 534.671143,665.675476 542.691956,667.852600 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M478.465088,664.649658 
	C477.895691,662.684692 479.241150,662.977844 480.366211,663.120300 
	C491.944366,664.586914 503.505127,663.976379 515.511475,662.905151 
	C516.091736,663.105774 516.223816,663.272400 516.179260,663.704468 
	C505.412048,665.639709 494.777252,666.379028 483.606384,665.467529 
	C482.287476,665.848633 481.422760,666.029785 480.268799,665.299072 
	C479.591614,664.952393 479.189301,664.808228 478.465088,664.649658 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M410.745361,680.248657 
	C413.677490,680.650635 416.359253,681.394470 419.427429,682.157715 
	C420.732666,682.515076 421.916016,682.162720 422.802612,683.407654 
	C425.121155,684.707642 427.965515,685.006165 428.026917,688.638794 
	C422.353973,687.736511 416.981781,685.607971 411.364502,683.100586 
	C410.480591,682.248413 410.196442,681.486877 410.745361,680.248657 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M505.589813,683.960693 
	C506.475952,683.953552 506.935455,683.975098 507.741821,683.985352 
	C506.882141,685.540100 504.943237,684.639465 503.491852,685.335815 
	C501.610687,686.238403 498.528290,685.963806 499.489563,689.669800 
	C499.902161,691.260437 498.176727,691.590210 497.234558,691.605652 
	C494.074890,691.657471 495.080994,688.594299 494.089417,686.995483 
	C493.137238,685.460205 494.542419,685.279236 495.355865,685.160706 
	C498.612732,684.686218 501.892090,684.366455 505.589813,683.960693 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M460.246399,701.617737 
	C460.033234,701.205688 460.005707,700.998291 459.946777,700.479126 
	C466.994904,701.130249 474.074371,702.093201 481.520569,703.079712 
	C482.096863,703.235291 482.306488,703.367249 482.202087,703.697876 
	C475.455017,704.395264 469.170258,703.144653 462.450500,702.299072 
	C461.509674,702.151001 460.970856,701.986694 460.246399,701.617737 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M489.690887,686.253052 
	C482.295929,688.118164 474.692139,687.270691 467.129730,685.033813 
	C474.549652,685.375427 481.969543,685.717041 489.690887,686.253052 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M482.365906,703.936890 
	C481.888062,703.896423 481.922699,703.499023 481.904968,703.301147 
	C490.222351,702.734619 498.557465,702.365967 506.892548,701.997253 
	C498.998596,704.759460 490.885162,703.799011 482.365906,703.936890 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M410.780701,679.647217 
	C411.002045,680.667969 411.008362,681.429199 411.027527,682.568237 
	C407.928009,681.033386 403.707458,680.708618 402.034302,676.333496 
	C405.324219,675.938354 407.789337,678.054382 410.780701,679.647217 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M516.411743,663.989990 
	C515.983032,663.798706 515.963501,663.627441 515.951782,663.197632 
	C515.959656,662.939148 515.854675,662.852051 515.858032,662.783936 
	C517.486328,660.205017 520.024841,661.208801 522.648926,661.086975 
	C524.797363,660.932739 526.621826,661.091675 528.629150,660.153809 
	C529.190125,660.303833 529.372131,660.473083 529.315308,660.928650 
	C525.166565,662.915100 520.850159,662.833069 516.411743,663.989990 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M470.372467,680.969360 
	C468.449249,682.590759 466.726990,682.047424 464.615204,680.409729 
	C466.847473,679.850098 468.595093,679.701599 470.372467,680.969360 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M474.840759,710.738647 
	C473.336548,710.849670 471.139038,711.682495 470.881836,708.477173 
	C472.708984,707.906311 473.735565,709.122009 474.840759,710.738647 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M529.464111,661.203796 
	C529.062134,661.035034 529.047729,660.855225 529.020752,660.405029 
	C531.151978,659.558228 533.295776,658.981812 535.755371,658.711914 
	C534.291199,660.581787 532.029663,660.767578 529.464111,661.203796 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M516.242310,673.683960 
	C517.195007,673.224487 518.065918,672.218506 519.185669,673.606445 
	C519.345581,674.189331 519.401672,674.396606 519.190186,674.783569 
	C518.181763,675.031921 517.545654,676.517517 516.438110,675.029175 
	C516.192261,674.598572 516.205017,674.266663 516.242310,673.683960 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M536.365051,659.115417 
	C536.786560,657.101318 538.761658,657.418640 540.680237,656.923584 
	C540.277588,658.989746 538.338684,658.726685 536.365051,659.115417 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M544.268188,656.304810 
	C545.036438,654.570312 546.794617,654.423340 548.703003,653.922729 
	C548.242310,656.011475 546.150818,655.821472 544.268188,656.304810 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M449.603363,694.352783 
	C449.715363,692.744507 450.777557,693.071899 451.689850,693.152039 
	C451.790710,693.160889 451.934967,693.799011 451.923523,693.803589 
	C451.217682,694.083618 450.497620,694.328003 449.603363,694.352783 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M464.125732,684.267334 
	C463.326050,686.441895 462.078735,684.486755 460.536163,684.867310 
	C461.770447,683.540039 462.849335,684.146057 464.125732,684.267334 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M480.066986,665.434265 
	C480.849091,665.126465 481.704254,665.156372 482.853577,665.317383 
	C482.304108,667.034180 481.351929,667.610596 480.066986,665.434265 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M455.044647,694.424316 
	C455.754578,694.810303 455.760223,695.199402 455.256134,695.543701 
	C455.150452,695.615845 454.905975,695.613220 454.802734,695.538452 
	C454.308838,695.180664 454.326172,694.791748 455.044647,694.424316 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M454.304413,684.025513 
	C454.153320,683.720032 454.347504,683.423828 454.783752,683.000488 
	C455.715027,683.090576 457.096344,682.692505 456.976318,683.493774 
	C456.825012,684.503845 455.470093,683.881348 454.304413,684.025513 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M505.011047,709.720337 
	C504.447205,709.877747 503.893677,709.721924 503.167114,709.332947 
	C503.486145,708.770508 503.978271,708.441162 504.785828,708.136902 
	C505.074615,708.576965 505.048035,708.992004 505.011047,709.720337 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M469.687653,709.013550 
	C469.961334,710.082764 469.778168,710.980408 468.172729,710.185791 
	C468.476257,709.640991 468.937500,709.337830 469.687653,709.013550 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M476.904358,710.168701 
	C476.926239,711.046265 476.739471,712.108032 475.143494,711.281006 
	C475.513702,710.776855 476.058899,710.503357 476.904358,710.168701 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M555.737183,650.647339 
	C555.837463,651.284546 555.666748,651.617737 555.233765,652.132690 
	C554.443054,652.460327 553.914734,652.606323 553.168579,652.398315 
	C553.312134,650.771729 554.703369,651.022156 555.737183,650.647339 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M549.248047,654.350830 
	C549.246155,652.605225 550.700806,652.937988 551.803711,652.692932 
	C551.749878,654.409607 550.306335,654.005493 549.248047,654.350830 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M541.253174,657.363281 
	C541.347168,655.825623 542.454407,656.079834 543.711182,655.932068 
	C544.041382,657.729858 542.276611,656.935059 541.253174,657.363281 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M428.124695,689.206665 
	C428.428772,688.973389 428.880737,688.970642 429.671783,688.967651 
	C429.588135,689.703796 429.050629,690.015564 428.124695,689.206665 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M480.986450,710.754150 
	C480.830566,711.000916 480.661133,711.004333 480.239441,710.974609 
	C480.166412,710.537537 480.345642,710.133606 480.524872,709.729675 
	C480.674225,709.990051 480.823578,710.250427 480.986450,710.754150 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M461.988831,679.234985 
	C462.103180,679.408630 462.047302,679.583679 461.991394,679.758728 
	C461.933838,679.584595 461.876251,679.410522 461.988831,679.234985 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M552.378784,653.079956 
	C551.685364,652.584229 551.767151,652.219116 552.691528,651.981323 
	C553.454102,652.477661 553.355957,652.853333 552.378784,653.079956 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M498.463745,700.449585 
	C498.515991,700.419800 498.411469,700.479431 498.463745,700.449585 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M495.457397,699.523682 
	C495.427368,699.472595 495.487457,699.574829 495.457397,699.523682 
z"
    />
    <path
      fill="#1A1A1F"
      opacity="1.000000"
      stroke="none"
      d="
M440.000305,681.746948 
	C439.894135,681.725464 439.788269,681.453369 439.841187,681.088196 
	C440.000214,681.162170 440.000397,681.329285 440.000305,681.746948 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M401.066345,675.205322 
	C401.228790,675.165955 401.512268,675.352356 401.906189,675.771362 
	C401.740204,675.813110 401.463806,675.622070 401.066345,675.205322 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M431.833771,673.249390 
	C434.337463,672.666626 434.924347,674.192017 435.788757,675.929016 
	C435.345276,678.339600 437.531647,682.638550 433.676270,682.330383 
	C429.656281,682.009033 431.772186,678.078125 431.992004,675.539795 
	C432.006042,675.377808 432.002563,675.214233 432.020752,674.674805 
	C431.834717,674.042053 431.635132,673.785950 431.833771,673.249390 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M427.252533,671.986877 
	C428.119537,672.330750 428.705963,672.706238 429.645447,673.052612 
	C429.998474,673.023499 430.000122,672.995850 429.986481,672.999878 
	C430.165588,673.184814 430.358337,673.365723 430.265808,673.822144 
	C428.244751,675.273376 426.573517,675.731018 425.063782,673.309204 
	C425.656250,672.643799 426.314117,672.331116 427.252533,671.986877 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M423.023102,683.283569 
	C422.116547,683.258911 421.184113,682.930359 420.032745,682.389404 
	C421.078766,681.636230 422.577667,680.167236 423.023102,683.283569 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M421.039124,675.279053 
	C421.638947,675.084961 422.347778,675.101685 422.481659,675.904724 
	C422.510223,676.076111 422.155426,676.311340 421.977234,676.517151 
	C421.672699,676.202332 421.368164,675.887512 421.039124,675.279053 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M420.991882,675.007324 
	C420.366486,674.909729 419.659546,674.896118 419.524963,674.099487 
	C419.496307,673.929749 419.848206,673.695801 420.024780,673.491394 
	C420.326630,673.804993 420.628510,674.118530 420.972473,674.708740 
	C421.014557,674.985352 420.999634,675.000000 420.991882,675.007324 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M430.325287,674.102905 
	C429.941223,673.915527 429.901978,673.733521 429.917786,673.277710 
	C430.474182,672.969482 430.975525,672.935059 431.854431,672.934753 
	C432.221497,673.193542 432.211060,673.418152 432.117432,673.970520 
	C431.579559,674.234924 431.124847,674.171570 430.325287,674.102905 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M396.122009,665.208313 
	C396.433655,665.121460 396.866089,665.245361 397.298523,665.369263 
	C396.979919,666.092041 396.609131,665.708496 396.122009,665.208313 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M395.999390,665.000549 
	C395.886810,664.856506 395.774902,664.711975 395.662964,664.567383 
	C395.746735,664.617920 395.830475,664.668457 395.957703,664.858276 
	C396.001221,664.997559 396.000000,665.000000 395.999390,665.000549 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M424.129211,677.947327 
	C424.084656,677.963928 424.173767,677.930725 424.129211,677.947327 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M424.001709,678.002441 
	C423.914764,678.038086 423.826141,678.071228 423.866516,678.050781 
	C423.995544,677.997131 424.000000,678.000000 424.001709,678.002441 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M423.837738,672.090942 
	C423.997467,671.998840 424.002625,672.496460 424.005798,672.745178 
	C423.898651,672.723633 423.788330,672.453308 423.837738,672.090942 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M266.002136,510.002747 
	C266.088226,509.969513 266.172180,509.933502 266.125244,509.946564 
	C265.994385,509.995636 266.000000,510.000000 266.002136,510.002747 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M487.630676,749.833740 
	C485.688080,750.000061 484.073730,750.043213 482.466125,750.640259 
	C481.641144,750.946594 479.891937,751.795654 479.857666,749.851379 
	C479.832275,748.411743 481.343353,747.346252 482.635895,747.600220 
	C484.470428,747.960754 486.171875,748.998230 487.630676,749.833740 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M492.111816,748.651489 
	C492.966431,747.835754 494.004333,747.834656 495.079803,748.493530 
	C494.196686,749.237244 493.238159,748.930359 492.111816,748.651489 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M585.117798,710.188477 
	C585.423828,709.637756 585.898254,709.329163 586.665527,709.004456 
	C587.265503,709.351990 587.572510,709.715698 587.896606,710.328369 
	C587.744934,710.639465 587.576111,710.701660 587.161255,710.875000 
	C586.372314,710.800842 585.829285,710.615723 585.117798,710.188477 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M570.128174,724.946655 
	C570.085449,724.964417 570.170898,724.928833 570.128174,724.946655 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M568.551758,735.472473 
	C568.583618,735.528809 568.519958,735.416138 568.551758,735.472473 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M657.544434,695.474243 
	C657.571594,695.521667 657.517334,695.426880 657.544434,695.474243 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M640.101379,676.978027 
	C635.748108,683.394897 630.035034,687.961121 623.505615,692.074097 
	C621.366943,691.682129 619.227173,691.460571 620.997681,688.424316 
	C620.879578,687.938538 620.724426,687.766479 620.540527,687.246216 
	C620.605591,686.440186 620.742859,685.995911 621.081238,685.253174 
	C624.226196,679.497375 628.553711,674.952454 631.775513,669.248901 
	C631.962769,668.959717 632.000000,669.000000 631.977295,668.984985 
	C632.219055,668.785217 632.458618,668.572266 632.822327,668.142090 
	C632.971497,667.953064 633.000793,667.998718 632.976807,667.986206 
	C633.218872,667.785278 633.460632,667.569641 633.832153,667.138428 
	C633.986206,666.949951 634.001404,667.003113 633.974304,666.997559 
	C634.433594,666.594116 634.498718,666.002258 634.833313,665.223022 
	C639.423157,664.783203 639.485901,659.952393 641.264282,657.674805 
	C644.030151,654.132385 647.319641,650.524475 649.667969,646.087097 
	C650.393433,644.942993 652.556824,646.975891 652.308594,644.267517 
	C652.160767,642.655334 653.478577,641.078308 655.858887,642.716003 
	C655.161072,648.581787 650.862976,652.396545 648.710388,657.332214 
	C647.103333,661.017090 644.296570,664.170959 645.918152,668.720581 
	C646.525330,670.424133 644.957153,671.721008 643.250183,672.870972 
	C641.185242,674.507141 640.503296,671.899963 639.098083,672.016785 
	C638.515625,673.776733 640.968811,674.850037 640.101379,676.978027 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M631.628296,669.022095 
	C632.272583,670.465759 632.238708,672.009705 631.108032,673.102295 
	C627.422974,676.663391 625.465881,681.446899 622.101013,685.636658 
	C621.804077,686.096130 621.674316,686.261169 621.392822,686.702332 
	C621.210693,687.167542 621.180298,687.356750 621.092285,687.828247 
	C618.902100,688.042725 618.177979,689.947388 616.811340,690.989502 
	C615.241211,692.186829 612.874512,693.933594 612.960815,689.303833 
	C613.464844,686.016663 616.262512,685.100403 618.051086,683.250183 
	C622.543030,678.603455 626.889160,673.815735 631.628296,669.022095 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M622.769714,693.103638 
	C622.880432,693.313110 622.692261,693.569458 622.208984,693.876953 
	C622.088623,693.662537 622.274292,693.403381 622.769714,693.103638 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M614.873657,706.050354 
	C614.915771,706.033569 614.831604,706.067139 614.873657,706.050354 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M618.990845,703.006958 
	C618.863098,702.851562 618.745789,702.687622 618.628418,702.523621 
	C618.727966,702.572815 618.827576,702.622070 618.973022,702.824097 
	C619.018982,702.976990 619.001221,702.998352 618.990845,703.006958 
z"
    />
    <path
      fill="#282832"
      opacity="1.000000"
      stroke="none"
      d="
M595.006470,713.994995 
	C594.890076,713.851501 594.767212,713.713013 594.644409,713.574585 
	C594.729492,713.625732 594.814514,713.676880 594.944702,713.870605 
	C594.989746,714.013184 595.000000,714.000000 595.006470,713.994995 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M456.787354,371.449280 
	C453.546997,369.140350 457.867981,367.679504 456.997314,365.375671 
	C457.554718,364.363831 458.293671,364.129028 459.489502,364.020447 
	C463.820984,364.303833 467.329987,362.267517 471.551941,361.839600 
	C482.129852,360.576050 492.353607,360.458344 502.582184,360.323517 
	C504.773346,360.294617 507.305237,360.833099 509.231506,358.219604 
	C510.018982,357.581482 510.556763,357.411011 511.560120,357.476807 
	C515.686096,360.849854 520.477539,359.481903 524.728882,360.427246 
	C526.954773,360.922180 529.366577,360.406036 532.081909,360.759094 
	C551.582520,362.276855 568.734802,369.778168 585.667297,377.973083 
	C588.960815,379.567078 592.191162,381.257660 595.475769,383.603210 
	C597.075439,386.003357 598.610718,387.824066 601.765259,387.770996 
	C605.174805,388.349426 605.534058,391.290649 606.958801,393.627502 
	C608.901733,396.549042 608.698242,398.936890 606.019653,401.300751 
	C604.965027,401.635803 604.228516,401.565979 603.172485,401.772278 
	C602.607300,401.916199 602.380371,401.971863 601.807922,402.089722 
	C600.530518,402.270721 599.598511,402.235840 598.323486,402.111084 
	C593.275452,401.403534 591.032288,396.774658 586.811401,394.873840 
	C586.920898,394.106201 587.262146,393.725159 587.410645,393.249329 
	C587.510010,392.931091 587.628967,392.427338 587.445801,392.293030 
	C587.011536,391.974609 586.928040,391.964386 587.214355,392.207062 
	C587.646729,392.573578 587.410522,393.079620 587.130615,393.786682 
	C584.414917,394.203766 583.016113,392.414642 581.229492,390.694672 
	C580.586121,390.019440 580.161377,389.572937 579.406372,389.038879 
	C578.980591,388.757355 578.810059,388.643829 578.384399,388.355469 
	C577.957214,388.063110 577.785095,387.945618 577.078796,387.482971 
	C574.710510,385.962097 572.916992,384.709503 570.467529,383.983093 
	C568.885071,383.337738 567.687927,382.659332 566.079468,382.023987 
	C565.529480,381.790222 565.309326,381.691467 564.761841,381.429077 
	C555.523987,376.770721 546.007202,374.176605 536.285095,372.178894 
	C534.204529,371.751373 532.037048,371.519196 529.813477,370.262573 
	C528.124329,368.085266 526.302185,368.452209 524.039978,369.181396 
	C515.895264,368.637024 508.135376,367.979858 500.369354,367.556915 
	C490.708771,367.030792 481.231049,369.172974 471.292664,370.167480 
	C469.783844,370.111267 468.781647,369.345978 467.312775,369.710114 
	C466.438934,370.118744 465.848358,370.385651 464.937195,370.757446 
	C463.522644,371.199524 462.384949,371.272034 460.908264,371.419922 
	C459.412170,371.649933 458.270111,372.026001 456.787354,371.449280 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M691.428955,551.838501 
	C690.325378,552.163574 689.001648,552.392517 689.474060,553.288330 
	C692.166992,558.395203 690.587280,563.559875 690.001282,568.723450 
	C689.506592,573.081909 689.108521,577.454773 688.447815,581.787720 
	C688.325073,582.592285 688.339661,583.644592 686.389771,584.099426 
	C684.027527,584.650391 684.887329,588.262573 684.265625,590.795532 
	C682.893066,590.016296 681.817505,588.387390 680.171875,590.873291 
	C679.530579,591.548767 679.052612,591.971619 678.315186,592.585632 
	C677.645447,593.176636 677.217590,593.558777 676.493774,593.992798 
	C675.542725,593.789490 675.310242,593.234009 675.161255,592.300354 
	C675.170776,591.790100 675.195862,591.587708 675.294556,591.091309 
	C675.427063,590.606018 675.486023,590.414734 675.634094,589.939087 
	C675.781372,589.466003 675.839478,589.277283 676.167236,588.251465 
	C676.607300,586.861023 676.772583,586.306335 676.928040,585.449768 
	C677.021118,584.975037 677.064453,584.786438 677.190308,584.320801 
	C677.457397,583.502258 677.657043,582.965454 677.822876,582.119568 
	C677.900024,581.643799 677.931885,581.452759 678.191162,580.410889 
	C678.610718,579.000916 678.818909,578.447083 679.052734,577.585449 
	C679.421997,576.322021 679.681519,575.343872 679.651123,573.999939 
	C679.877930,571.972290 678.225586,570.411865 679.436096,568.386719 
	C680.227722,567.529541 680.683167,566.853760 680.789917,565.651733 
	C680.861694,565.103333 680.893066,564.880432 680.982727,564.317871 
	C681.598145,561.154114 682.307983,558.357361 682.561768,555.114868 
	C682.482422,551.848694 682.626953,548.932983 682.164429,546.054260 
	C682.577271,548.569275 682.718262,551.060120 681.465454,553.808044 
	C680.769592,554.409241 680.267517,554.513123 679.377075,554.244141 
	C679.010864,552.225891 679.029663,550.397949 678.967224,548.572937 
	C678.921997,547.250610 678.912354,545.528137 677.169983,545.581970 
	C675.494080,545.633850 675.694275,547.579712 675.383240,548.723145 
	C674.983154,550.193970 676.820068,551.811584 675.001587,553.668945 
	C673.543152,551.851196 673.797363,548.184326 669.732788,548.955933 
	C676.971924,544.031006 676.137268,537.533020 674.820496,530.710205 
	C673.366821,523.177490 670.226624,515.825928 669.780701,508.375183 
	C669.340271,501.015442 665.902832,494.607880 665.741516,487.231995 
	C666.193848,486.814880 666.404297,486.628204 666.924683,486.727875 
	C670.638000,494.082397 673.807983,501.025848 674.587219,509.188904 
	C675.392822,517.627380 678.170105,525.876465 680.056396,534.214905 
	C680.219421,534.935852 680.513123,535.591003 681.244568,535.500732 
	C682.967896,535.288086 682.757935,533.541443 683.657227,532.238892 
	C684.550293,531.527954 684.499207,530.823975 684.509521,529.794189 
	C684.514709,528.518677 684.494629,527.588501 684.642090,526.313965 
	C686.573242,523.520996 684.722046,521.129456 684.514160,518.757446 
	C683.295044,504.846741 680.746582,491.178497 676.507751,477.846741 
	C676.150757,476.723938 675.455505,475.768890 675.053467,474.271912 
	C673.738464,472.748962 672.431213,471.660400 672.058472,469.564240 
	C670.925110,467.698059 671.220520,465.345917 668.866272,464.163940 
	C668.360352,463.866302 668.207642,463.712677 668.026672,463.372009 
	C667.831726,462.992920 667.702332,462.763733 667.625122,462.142456 
	C667.926819,461.282745 668.263855,460.890686 669.041260,460.420227 
	C674.249634,459.877991 675.954834,463.646118 677.642578,466.959595 
	C679.440979,470.490295 680.810364,474.268829 681.855652,478.119354 
	C682.249817,479.571472 682.357422,481.104736 684.350220,481.967834 
	C686.859314,487.467346 687.223206,493.191803 689.073364,498.949036 
	C691.677795,512.838196 693.679321,526.378235 693.435791,540.537476 
	C693.423584,543.708069 690.572815,545.994202 692.141846,549.351135 
	C692.465820,550.437256 692.247314,551.085571 691.428955,551.838501 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M624.762512,423.862061 
	C624.953491,424.508636 624.966064,424.932587 625.005249,425.673584 
	C626.109802,426.912384 625.959106,428.377014 626.792847,429.746399 
	C633.264038,438.160553 638.326904,447.072815 643.628479,455.868225 
	C647.599060,462.455505 649.233948,469.754211 652.069641,476.667908 
	C655.104858,484.068146 657.450073,492.047272 657.536621,499.743683 
	C657.631775,508.197388 660.741394,515.934998 660.893738,524.595093 
	C658.205872,526.291565 657.458618,523.548645 655.615723,522.295471 
	C653.146790,516.529297 652.563354,510.669708 651.964417,504.941711 
	C649.204468,478.546997 638.918884,455.288879 623.066772,434.339142 
	C618.061401,427.724182 612.288757,421.724182 606.403687,415.840851 
	C603.797363,413.235168 599.494324,414.178833 597.294189,410.524048 
	C596.778748,408.465363 597.265503,407.071320 598.935486,405.783020 
	C601.718506,404.443207 603.704346,405.620209 605.656921,406.932373 
	C607.998657,408.506104 609.936707,410.537689 611.862732,412.561768 
	C615.089844,415.952972 618.319763,419.320496 622.785950,421.733521 
	C622.991394,422.009186 623.000000,422.000000 622.994385,422.004242 
	C623.538818,422.534302 624.064819,423.083649 624.762512,423.862061 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M641.077759,630.727722 
	C636.955750,632.925720 635.569763,637.125061 633.018372,640.306152 
	C631.134583,642.654968 630.226257,645.848145 626.291626,646.840698 
	C624.591248,645.205627 623.695801,643.575378 623.158142,641.406250 
	C623.455444,638.341553 625.357910,636.428711 626.768799,633.879272 
	C627.982361,631.787720 628.829834,629.893494 630.058228,627.810608 
	C633.202454,623.606445 635.515137,619.376221 636.858276,614.280273 
	C637.083008,613.495483 638.510437,613.543823 637.452820,612.332031 
	C637.286743,608.405823 639.016418,605.321289 640.481628,602.270874 
	C643.118835,596.780212 644.210999,590.742676 647.133240,585.014526 
	C649.079224,583.708252 650.891052,582.963623 653.157959,582.453979 
	C653.742554,582.580566 653.961914,582.681885 654.402588,583.066284 
	C653.029236,590.456360 655.479980,598.512939 649.410828,604.612122 
	C648.196960,605.832031 647.308716,607.589661 646.584778,609.277039 
	C645.704651,611.328735 644.020996,613.464233 647.737915,614.708069 
	C648.924255,615.105042 648.921143,616.577637 648.172607,618.031677 
	C647.632263,618.564636 647.279236,618.809326 646.645020,619.178894 
	C646.065674,619.588196 645.808411,619.893127 645.493164,620.541382 
	C644.482666,623.414124 643.806824,626.075073 642.229370,628.736938 
	C641.728821,629.485962 641.462585,629.979187 641.077759,630.727722 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M646.782410,618.345398 
	C647.418030,615.211975 647.418030,615.211975 642.996704,614.736267 
	C644.828430,609.824158 646.958191,605.307129 650.318359,601.145569 
	C652.156372,598.869202 654.538635,595.337036 651.753723,591.737000 
	C650.547607,590.177979 651.388428,588.782349 652.381958,587.731384 
	C653.491516,586.557678 654.151245,585.384949 654.007446,583.399170 
	C657.996582,579.537354 658.301697,574.490417 659.409485,569.859375 
	C659.937439,567.652039 659.206665,564.846558 662.775696,565.073608 
	C666.122192,565.286438 666.479309,567.817444 666.481689,570.466187 
	C666.482544,571.458191 666.527344,572.451599 666.203247,573.744141 
	C665.483459,579.433899 665.319885,584.921082 659.626038,588.030640 
	C658.048889,588.891968 657.190857,591.106079 658.455444,592.347595 
	C662.516541,596.334473 659.376343,600.072937 658.113770,603.572937 
	C657.604858,604.983765 658.893188,608.981201 654.371582,608.004639 
	C654.617981,612.936462 651.285095,616.504639 649.317627,620.826904 
	C647.164551,621.312134 646.592590,620.283691 646.782410,618.345398 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M616.118103,384.677124 
	C618.049805,390.926422 622.754211,392.865265 628.462402,393.870667 
	C632.304871,394.547424 634.524475,397.655212 635.665771,401.258209 
	C635.938965,402.120911 635.428162,403.534637 636.658936,403.651550 
	C643.171326,404.270172 643.990784,410.275574 646.985107,414.612701 
	C644.430664,416.383545 642.534363,414.656494 640.200562,413.260040 
	C638.089172,413.205444 636.627380,412.386780 635.464417,411.194489 
	C627.409546,402.937286 617.383728,397.028107 609.110840,389.028839 
	C608.111450,388.062561 607.497498,386.989471 607.096313,385.117432 
	C607.968506,383.631348 609.028320,383.191284 610.276123,382.419861 
	C611.630554,381.672180 612.717651,381.452393 614.167908,382.158875 
	C615.243896,382.838379 615.937195,383.425537 616.118103,384.677124 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M622.988892,421.641449 
	C616.446411,421.473389 613.779968,415.714722 609.607605,412.008362 
	C606.905884,409.608337 604.545776,406.774963 600.396423,405.990173 
	C599.127136,406.573212 598.259705,406.027466 597.060303,405.749939 
	C596.348633,405.341675 595.977417,405.055115 595.373047,404.499390 
	C589.222046,399.605865 582.793274,395.776733 576.284058,392.071533 
	C574.708435,391.174652 572.877991,390.639008 571.770691,388.600708 
	C571.808044,387.752411 572.047302,387.325714 572.685913,386.720154 
	C573.446350,386.254181 573.931580,386.111603 574.824036,386.088135 
	C575.936829,386.317505 576.670898,386.512726 577.746582,386.802002 
	C578.063843,387.009216 577.989319,387.015137 577.984009,386.978119 
	C578.201965,387.198883 578.434509,387.447876 578.863403,387.862671 
	C579.048706,388.038818 578.964600,388.043884 578.955017,388.002869 
	C579.382874,388.393768 579.953613,388.504547 580.746826,388.803864 
	C582.799500,390.531372 584.629150,392.071930 586.729492,393.806519 
	C591.623657,394.522461 593.208313,399.745361 597.835449,400.929291 
	C599.398193,401.818176 600.656555,401.595703 602.227539,401.090149 
	C602.534119,400.974274 602.514954,401.027008 602.487915,401.019714 
	C602.721313,400.897400 602.981812,400.782318 603.634460,400.836670 
	C608.095520,400.408203 606.689941,397.198792 606.993225,394.387451 
	C613.985046,395.290070 615.980103,402.176849 620.815735,405.955109 
	C617.739197,406.517487 616.747131,403.725861 614.734375,402.645935 
	C613.237427,401.842804 612.050598,397.860626 610.749268,402.816467 
	C610.391418,404.178894 608.497681,402.288757 607.710693,403.579315 
	C611.226440,409.516144 617.819397,412.511475 621.677551,418.144196 
	C622.355225,419.133575 623.031372,420.025940 622.988892,421.641449 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M655.726746,522.297180 
	C658.242126,521.802002 658.640503,524.224060 660.564575,524.944946 
	C660.925049,530.407471 661.088501,535.809448 660.971069,541.205261 
	C660.898438,544.546570 662.061279,547.880066 660.997314,551.606567 
	C661.138367,555.526550 660.970581,559.009644 659.412415,562.665405 
	C659.100281,564.279968 658.345581,565.496887 658.797485,566.801086 
	C660.010925,570.303345 658.432129,572.344238 654.958313,573.703857 
	C653.151428,573.657593 651.870483,573.227844 650.565674,571.909790 
	C649.489136,566.127258 651.467957,561.015808 652.084717,555.757324 
	C653.150452,546.671265 654.299622,537.597412 654.417175,528.431335 
	C654.442688,526.440186 654.324829,524.412048 655.726746,522.297180 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M668.154236,461.896057 
	C667.987122,462.282166 667.992249,462.469360 667.979248,462.939331 
	C667.832764,463.116974 667.604553,463.023804 667.590393,462.905060 
	C666.618225,454.762054 660.813721,449.058167 657.201599,442.249268 
	C656.981995,441.835358 657.029541,441.279694 656.963623,440.419006 
	C657.353271,439.579407 657.830383,439.288544 658.714661,439.262848 
	C659.302246,439.378296 659.661011,439.681244 659.931702,439.420074 
	C660.408203,438.960236 659.970520,439.720001 659.900513,438.846558 
	C660.252869,438.498230 660.440979,438.399170 660.957275,438.233673 
	C665.571777,438.372772 667.011047,442.205627 668.582703,444.917969 
	C674.285889,454.760895 680.747314,464.272278 684.107361,475.736267 
	C684.271606,477.897858 685.010986,479.659729 684.173035,481.768921 
	C681.995300,482.516052 681.321777,482.080231 680.815552,479.821960 
	C679.625427,474.512909 677.756897,469.336823 674.798950,464.687653 
	C673.653015,462.886627 672.350464,461.056213 669.461121,460.997314 
	C668.828674,461.207520 668.571411,461.445709 668.154236,461.896057 
z"
    />
    <path
      fill="#2F3842"
      opacity="1.000000"
      stroke="none"
      d="
M639.916016,413.321350 
	C642.374023,412.453552 644.258423,413.922516 646.659058,414.930481 
	C651.063660,419.537750 655.072510,424.133453 659.147705,428.669495 
	C660.385315,430.046997 661.433838,431.488647 661.988403,433.629761 
	C661.651123,435.585327 659.696777,436.227539 659.471313,438.313538 
	C658.932312,439.275360 658.318604,439.488831 657.319458,439.222870 
	C652.551758,436.709229 651.936768,431.592194 649.317505,427.938721 
	C646.133728,423.497925 641.201172,421.091980 638.098999,416.380737 
	C638.433655,415.570312 639.023621,415.529602 639.702026,415.104004 
	C639.889404,414.460083 639.914551,414.045837 639.916016,413.321350 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M674.688232,474.160400 
	C676.270569,474.045227 677.104004,474.864716 677.440674,476.046295 
	C679.301697,482.578033 681.545654,489.042389 682.815125,495.690826 
	C684.572632,504.894226 685.601807,514.237732 686.882690,523.529541 
	C687.034546,524.630859 686.814209,525.784485 686.741699,526.912292 
	C686.738708,526.958679 686.429321,527.065308 686.354065,527.012817 
	C686.084900,526.825134 685.855225,526.581116 685.376038,526.191711 
	C684.435791,525.817261 684.559082,525.193604 684.300781,524.454834 
	C682.295715,514.011597 681.404175,503.644867 679.075073,493.491486 
	C677.610779,487.108307 675.047913,480.995209 674.688232,474.160400 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M654.379028,573.421631 
	C657.102905,571.655273 659.554382,570.281006 657.216553,566.752441 
	C656.584534,565.798523 657.204468,564.529846 658.028442,563.219971 
	C659.146484,562.093018 660.424866,561.921997 661.710327,561.115723 
	C662.444092,561.021057 662.887329,561.024170 663.662842,561.033691 
	C664.720154,561.312561 665.272888,561.163452 665.752136,560.178101 
	C666.408691,558.132202 666.885864,556.303528 667.624146,554.245239 
	C668.345032,553.617310 668.867859,553.499573 669.758545,553.957764 
	C668.994019,560.691589 668.366638,567.210388 666.278931,573.780640 
	C665.978638,573.226318 666.231323,572.251038 665.877197,571.612122 
	C664.819031,569.703125 666.177124,565.842163 662.413391,566.153137 
	C658.911682,566.442566 661.370667,569.527588 660.928345,571.229492 
	C659.739868,575.802856 658.136902,580.197266 655.879333,584.274597 
	C654.905518,586.033325 655.214661,583.325256 654.244690,583.193054 
	C654.011047,583.015747 653.951782,582.957153 653.921509,582.928528 
	C652.128906,580.699707 653.384949,578.342224 653.594116,575.649048 
	C653.737427,574.725342 653.896423,574.218811 654.379028,573.421631 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M668.910461,638.294556 
	C671.579529,638.192322 670.545166,634.254883 673.551453,634.488647 
	C671.671143,640.291321 671.397766,646.384521 667.104370,651.379883 
	C665.176453,653.623047 665.831116,657.889587 661.471924,658.937439 
	C660.888794,659.145935 660.661133,659.309631 660.184570,659.134155 
	C660.766052,656.125305 661.441833,653.403748 663.237549,651.131165 
	C664.064392,650.084717 664.829956,648.513123 664.168213,647.486877 
	C662.251404,644.514221 664.826538,643.152405 666.351929,642.615112 
	C668.693115,641.790588 668.547729,640.247498 668.910461,638.294556 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M678.149658,583.957397 
	C677.987305,584.231995 678.030579,584.530701 678.037598,584.681519 
	C677.324402,585.577148 677.561768,586.558289 677.208008,587.727539 
	C677.015015,588.013916 677.000000,588.000000 677.009644,588.006531 
	C676.617493,588.188477 676.543579,588.497253 676.862000,589.074463 
	C677.059448,589.263672 677.064819,589.568054 677.054688,589.719971 
	C676.798462,590.110657 676.570435,590.365540 676.186523,590.825623 
	C676.012817,591.014893 676.002441,591.000000 676.009888,591.008240 
	C675.610535,591.387207 675.135193,591.529053 674.272583,591.220337 
	C670.091187,587.943665 673.316772,584.312378 673.680298,581.257202 
	C674.155151,577.266541 676.482239,573.486084 677.090088,569.140381 
	C676.841614,568.755066 676.747803,568.596619 676.827026,568.222778 
	C677.010010,567.826782 677.020020,567.646118 677.050110,567.194946 
	C677.216675,566.781860 677.363281,566.639404 677.743652,566.298706 
	C677.977417,566.100403 677.945496,565.616394 677.924622,565.374756 
	C678.895020,564.005920 680.074707,563.990173 681.701416,564.869507 
	C682.051147,565.190308 682.057800,565.509766 682.052856,565.669434 
	C681.292603,566.590149 681.588867,567.618713 681.129028,568.782104 
	C678.729309,570.527710 682.141724,571.932556 680.931396,573.748230 
	C679.765320,574.974915 679.737122,576.300537 679.507935,577.876465 
	C679.491638,578.636780 679.453247,579.069580 679.190002,579.765991 
	C678.998474,580.032471 678.987976,579.992249 679.008545,579.989136 
	C678.686768,580.159729 678.584412,580.425720 678.882019,580.955994 
	C679.042053,581.127930 679.034241,581.539551 679.026611,581.745239 
	C678.514526,582.418457 678.502380,583.087036 678.149658,583.957397 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M614.230713,382.661682 
	C613.203064,382.705536 612.426331,382.175537 611.308167,382.887543 
	C610.115356,383.898621 608.446899,383.033203 607.453003,384.593536 
	C607.246338,384.836090 607.045227,384.939301 606.952881,385.007141 
	C606.100464,385.203217 605.179077,385.661804 604.616150,385.386749 
	C603.630615,384.905182 603.755615,382.441925 601.960999,383.403320 
	C600.084412,384.408691 601.821106,385.962463 601.971680,387.639465 
	C601.376526,388.003784 600.477966,387.727478 600.129150,388.048492 
	C595.537048,392.273804 597.124756,386.992462 596.118286,385.270325 
	C596.147278,382.362274 598.412109,381.374786 600.286743,379.712494 
	C600.818604,379.545563 601.039062,379.511261 601.582031,379.534698 
	C602.239502,379.525330 602.311157,380.521393 602.695740,379.844025 
	C602.795044,379.669128 602.347107,379.086029 602.261353,378.282288 
	C603.283447,374.898102 605.469910,375.155212 607.836182,375.925629 
	C611.081787,376.982330 614.760803,377.592865 614.230713,382.661682 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M661.194336,626.290588 
	C661.210388,622.341064 661.761414,618.635071 663.985107,615.743896 
	C665.525940,613.740417 662.855774,612.858154 663.804382,611.178406 
	C664.658569,610.306885 665.417297,609.869263 666.649902,609.860229 
	C667.998230,609.263123 668.910400,607.953918 669.078674,610.731628 
	C669.434448,616.605957 666.861389,621.520508 664.281006,626.783447 
	C663.015747,627.612427 662.192322,627.178284 661.194336,626.290588 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M656.013794,643.013306 
	C653.140259,641.742798 653.149963,643.734863 652.991699,645.655273 
	C652.699036,649.205750 651.290710,645.385498 650.232666,645.936157 
	C649.241333,641.587280 650.100891,637.814514 652.752625,634.215515 
	C654.195801,632.256836 655.439209,632.127625 657.654968,632.963379 
	C660.860046,636.638855 658.413452,639.456421 656.281372,642.697449 
	C656.005676,643.029785 656.000000,643.007141 656.013794,643.013306 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M656.356995,642.984619 
	C655.934082,639.804138 659.008850,637.264404 658.006042,633.419495 
	C658.309326,632.212646 658.616211,631.422852 658.954346,630.315491 
	C659.126770,628.654236 659.677734,627.491516 660.769165,626.231567 
	C660.994202,625.995117 661.000000,626.000000 660.997559,625.996338 
	C661.801086,626.244873 662.606995,626.497131 663.708496,626.874634 
	C662.946716,632.942322 661.638977,638.770020 656.356995,642.984619 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M682.195496,564.814392 
	C680.945312,565.145203 679.839478,565.100159 678.318726,565.094116 
	C678.073486,561.466736 678.243225,557.800293 678.719910,554.093262 
	C679.410278,554.026550 679.793762,554.000549 680.463318,553.934570 
	C681.705627,553.832886 682.649170,553.826721 683.704346,554.739075 
	C684.101624,558.242188 683.001343,561.302551 682.195496,564.814392 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M646.667786,618.004272 
	C647.016418,619.151855 647.451355,620.075867 648.718018,620.857239 
	C648.043152,624.551147 646.686523,627.789734 642.361084,628.986511 
	C641.010498,625.565125 644.154053,623.038574 644.005249,619.392944 
	C644.171082,618.832275 644.334778,618.671692 644.740601,618.266724 
	C645.431946,618.016113 645.881348,618.009888 646.667786,618.004272 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M652.998901,452.036407 
	C653.968201,454.229126 655.855164,456.013153 653.323975,458.456268 
	C651.304688,455.207764 649.344421,452.054260 647.129883,448.491608 
	C650.504395,447.786804 651.448669,450.139984 652.998901,452.036407 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M640.378296,677.144653 
	C639.698303,675.285645 636.741028,674.911072 637.014526,672.340271 
	C637.166992,670.906860 637.383179,669.561768 638.833496,669.197632 
	C640.704346,668.727844 640.989624,670.168518 641.025024,671.599548 
	C641.044312,672.378967 641.451965,672.781860 642.540649,672.985168 
	C642.788269,674.689575 641.700439,675.816406 640.378296,677.144653 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M661.999878,560.577026 
	C661.874084,563.195190 660.818359,563.893982 658.496826,562.972900 
	C658.952209,559.448853 659.696594,555.989929 660.714722,552.264282 
	C662.995605,554.502991 661.569885,557.434631 661.999878,560.577026 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M644.996399,444.634216 
	C641.657776,442.167664 638.639160,439.101440 637.740417,434.556061 
	C640.896118,437.247040 643.893250,440.042206 644.996399,444.634216 
z"
    />
    <path
      fill="#171A21"
      opacity="1.000000"
      stroke="none"
      d="
M675.761475,597.664551 
	C675.496277,600.382324 675.058167,602.890747 674.620056,605.399231 
	C674.135254,605.400635 673.650452,605.402039 673.165649,605.403442 
	C675.686218,601.168091 671.883789,597.460266 673.559204,593.162170 
	C675.857971,592.101624 674.691406,594.557922 675.873169,595.012329 
	C676.053101,595.675232 676.034424,596.073181 675.991821,596.768860 
	C675.841431,597.196167 675.714966,597.325684 675.761475,597.664551 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M670.386230,554.127197 
	C669.582275,554.174133 669.101318,554.094910 668.252869,554.015625 
	C667.797791,552.520386 667.492249,550.635132 669.841858,551.066040 
	C671.251831,551.324707 672.943420,552.284058 670.386230,554.127197 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M668.704163,464.314514 
	C672.236389,463.402588 672.057617,466.019043 672.103638,468.812805 
	C669.906433,468.440582 669.753906,466.287872 668.704163,464.314514 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M660.652954,626.033936 
	C661.060913,627.425293 660.976318,628.798462 659.294189,629.841797 
	C657.188904,627.933167 658.346985,626.866638 660.652954,626.033936 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M676.105591,594.969421 
	C674.683350,595.283691 674.481262,594.268005 674.072266,593.117126 
	C673.873474,592.560852 673.909058,592.115967 673.949097,591.334717 
	C674.412292,590.990845 674.871155,590.983398 675.673706,590.996216 
	C676.021667,591.777283 676.025940,592.538147 676.114014,593.671753 
	C676.178284,594.250183 676.158875,594.455811 676.105591,594.969421 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M627.000305,429.620728 
	C625.505676,429.392731 624.983887,428.212921 625.036865,426.339752 
	C627.111389,426.217194 626.984009,427.775146 627.000305,429.620728 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M665.641724,560.000732 
	C665.720398,560.976562 666.650452,562.201721 665.395569,562.961670 
	C665.318359,563.008484 664.640564,562.063721 664.118652,561.310181 
	C664.447449,560.706970 664.899719,560.373840 665.641724,560.000732 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M666.986694,609.628662 
	C666.199707,610.342163 665.439453,610.696045 664.356201,611.019531 
	C664.049622,608.723145 665.054932,608.170349 666.986694,609.628662 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M684.106812,524.630371 
	C684.394348,524.665710 684.652954,525.100220 685.026550,525.779907 
	C685.049133,527.030823 684.956726,528.036560 684.880005,529.421753 
	C684.774353,530.038452 684.653015,530.275696 684.319214,530.174316 
	C684.097168,528.233704 684.087524,526.631592 684.106812,524.630371 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M678.399597,592.842773 
	C678.196777,592.018921 677.621338,590.803589 679.569214,590.994629 
	C680.685730,592.393555 681.213562,593.601868 678.399597,592.842773 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M659.611328,658.899414 
	C660.133301,658.820679 660.330750,658.846313 660.822327,658.927124 
	C660.605896,660.011780 659.993347,660.920898 658.365601,661.009277 
	C657.976807,660.030029 658.040161,659.142761 659.611328,658.899414 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M656.996338,439.263672 
	C657.635681,438.869110 658.218140,438.732239 658.927063,438.311523 
	C659.539062,437.885345 659.938843,438.021759 660.259216,438.749390 
	C660.254150,439.098907 660.247009,439.473389 659.998901,439.871155 
	C659.057129,440.231110 658.363586,440.193359 657.322266,440.101807 
	C656.962830,439.872467 656.951111,439.696930 656.996338,439.263672 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M624.918701,423.755676 
	C624.067505,423.929535 623.288025,423.653229 623.033936,422.324829 
	C623.918884,422.085449 624.702698,422.359283 624.918701,423.755676 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M667.142700,486.752808 
	C667.026062,487.013458 666.817566,487.012726 666.296265,487.006805 
	C666.096008,486.587219 666.208618,486.172852 666.321289,485.758484 
	C666.564453,486.002777 666.807617,486.247070 667.142700,486.752808 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M684.049805,530.194580 
	C684.106750,529.835815 684.503357,529.860596 684.699463,529.830872 
	C684.754456,530.424133 684.613220,531.047119 684.185791,531.836243 
	C683.930664,531.519348 683.961792,531.036377 684.049805,530.194580 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M639.588989,414.950073 
	C639.455750,415.238586 639.047180,415.602844 638.343872,416.005066 
	C638.470703,415.703918 638.892151,415.364868 639.588989,414.950073 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M664.351013,480.605164 
	C664.951355,480.840515 664.974731,481.192932 664.466431,481.515839 
	C664.370056,481.258759 664.273682,481.001709 664.351013,480.605164 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M634.681030,665.072693 
	C634.867798,665.603821 634.725098,666.214294 634.264771,666.908447 
	C633.610474,666.275085 633.874817,665.690247 634.681030,665.072693 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M676.846375,568.092163 
	C677.000000,568.007385 676.982117,568.462280 676.958801,568.687927 
	C676.782410,568.757202 676.619690,568.608032 676.488342,568.435181 
	C676.471863,568.413452 676.620605,568.266113 676.846375,568.092163 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M632.673157,667.940308 
	C632.835266,668.308105 632.699036,668.663147 632.258667,668.994141 
	C632.094666,668.622559 632.234741,668.275024 632.673157,667.940308 
z"
    />
    <path
      fill="#5A7B8B"
      opacity="1.000000"
      stroke="none"
      d="
M633.677490,666.930664 
	C633.828857,667.276733 633.671509,667.603577 633.233521,667.952026 
	C633.091553,667.619629 633.230164,667.265442 633.677490,666.930664 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M677.772217,566.169922 
	C677.854858,566.359680 677.680054,566.576782 677.261536,566.838074 
	C677.138916,566.625732 677.304565,566.397461 677.772217,566.169922 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M675.935547,597.671753 
	C675.934387,597.873901 675.573792,597.603333 675.573792,597.603333 
	C675.573792,597.603333 675.678833,597.202576 675.823364,597.134644 
	C675.967957,597.066650 675.936707,597.469666 675.935547,597.671753 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M666.455017,627.525879 
	C666.427307,627.477356 666.482727,627.574341 666.455017,627.525879 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M663.326477,478.666382 
	C663.291748,478.366486 663.401489,478.253510 663.511169,478.140564 
	C663.607361,478.265137 663.783875,478.394196 663.777283,478.513062 
	C663.770630,478.631775 663.580505,478.740326 663.326477,478.666382 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M645.012146,444.990784 
	C645.342773,445.084137 645.607117,445.266479 645.880127,445.799683 
	C645.631348,445.944763 645.382385,445.741333 645.089844,445.241150 
	C644.983032,445.021973 645.000977,444.999847 645.012146,444.990784 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M646.031982,445.977661 
	C646.169373,446.117889 646.275024,446.286804 646.380615,446.455688 
	C646.283142,446.415253 646.185730,446.374786 646.015991,446.202515 
	C645.943726,446.070679 646.000244,446.006348 646.031982,445.977661 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M662.739319,476.332794 
	C662.730530,476.603577 662.540405,476.665070 662.350342,476.726624 
	C662.419617,476.525604 662.488831,476.324554 662.739319,476.332794 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M665.402710,483.583862 
	C665.336487,483.475861 665.590698,483.413940 665.590698,483.413940 
	C665.590698,483.413940 665.468994,483.691833 665.402710,483.583862 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M612.455688,404.527710 
	C612.426514,404.476959 612.484924,404.578461 612.455688,404.527710 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M672.993042,627.992676 
	C673.605042,628.092957 674.317993,628.098389 674.465820,628.893982 
	C674.496765,629.060425 674.144165,629.298096 673.968262,629.502991 
	C673.668945,629.183533 673.369629,628.864075 673.027832,628.265381 
	C672.985352,627.986145 673.000000,628.000000 672.993042,627.992676 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M693.545532,598.006714 
	C693.814697,598.143433 693.857910,598.315552 693.936279,598.751709 
	C693.554749,598.881287 693.138245,598.746765 692.721680,598.612244 
	C692.920959,598.422241 693.120239,598.232239 693.545532,598.006714 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M579.832153,676.178223 
	C586.575073,671.217957 593.156982,666.436829 600.038147,661.066895 
	C600.757690,659.975952 601.269043,659.573486 602.029968,658.950745 
	C604.921875,656.957275 607.474731,655.071655 610.033508,652.655396 
	C612.032349,651.559204 613.519470,650.337646 615.158936,648.787354 
	C615.572205,648.451782 615.742065,648.324097 616.172485,648.016113 
	C617.344727,647.294739 618.111755,646.602295 618.850220,645.422974 
	C619.160034,644.976990 619.293091,644.802979 619.657471,644.390869 
	C620.066833,644.018860 620.244934,643.884949 620.726440,643.610046 
	C621.249268,643.432312 621.468628,643.395691 622.018921,643.394653 
	C623.952087,643.932922 624.580994,645.434998 625.806152,646.788452 
	C626.041870,647.010010 626.157898,647.018677 626.215637,647.036011 
	C624.868774,651.348022 621.646606,654.420044 618.367737,657.046509 
	C616.096436,658.865906 614.847290,662.688843 610.489380,662.027527 
	C609.460693,662.109924 608.664917,661.589478 608.219482,662.502319 
	C608.509216,662.585815 608.805481,662.626160 609.360779,662.776611 
	C610.960388,667.216125 606.926025,668.781250 604.992554,671.068481 
	C602.877991,673.569946 599.522400,675.022339 596.137207,676.941284 
	C595.259949,676.878357 595.017334,676.970703 594.923035,677.616272 
	C594.190002,678.993774 593.474548,680.095398 592.532776,680.946655 
	C590.899902,682.422485 587.971252,683.791748 589.667847,686.212524 
	C591.342651,688.602295 593.278259,685.529663 595.505371,685.001221 
	C596.669922,685.655334 597.539062,686.321777 597.857117,687.776733 
	C595.260681,693.806091 595.178040,693.813232 588.401123,688.762695 
	C587.454163,691.512573 586.227966,693.629028 583.304077,690.021118 
	C583.290955,689.102478 583.593811,688.242188 583.873657,687.036743 
	C583.708801,685.568420 583.718140,684.415222 583.113831,683.031128 
	C583.685547,679.456604 579.110352,680.330750 579.003418,677.286194 
	C578.946106,676.928284 578.986389,676.961609 578.964966,676.973022 
	C579.215942,676.809998 579.458557,676.601013 579.832153,676.178223 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M609.745605,662.711670 
	C608.807190,663.260742 608.001160,663.591492 607.195190,663.922302 
	C606.872192,662.829041 606.634705,661.653015 607.503906,660.780334 
	C608.487061,659.793335 609.163086,660.755066 609.959961,661.736938 
	C610.034119,662.190857 609.956116,662.342102 609.745605,662.711670 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M598.161621,687.736938 
	C596.760254,687.722412 596.449036,686.586731 595.909180,685.275085 
	C597.723694,684.739685 599.357422,684.757385 598.161621,687.736938 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M640.518188,636.705994 
	C640.968201,637.760681 640.864624,638.550232 640.129578,639.071045 
	C639.662048,639.402344 639.175110,639.111450 639.117065,638.593384 
	C639.012390,637.658142 638.870300,636.602600 640.518188,636.705994 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M596.004883,677.007019 
	C595.913269,677.339478 595.726074,677.600342 595.228577,677.901123 
	C595.007324,678.004883 595.000000,678.000000 594.997559,677.996460 
	C594.801025,677.521362 594.921326,677.135803 595.649292,676.906738 
	C595.984436,676.988892 595.998291,676.999390 596.004883,677.007019 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M536.032776,705.708740 
	C535.604065,706.018127 535.131470,706.030273 534.305664,706.011719 
	C534.031616,703.646484 535.010437,704.529785 536.032776,705.708740 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M533.739624,705.953491 
	C533.982483,705.954651 533.970459,706.422729 533.978821,706.656250 
	C533.382935,706.807861 533.093750,706.561157 533.739624,705.953491 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M528.979736,705.973267 
	C529.083923,705.975769 529.085022,705.178345 529.176514,705.966187 
	C529.041504,706.032959 528.998535,705.994934 528.979736,705.973267 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M582.676514,682.812927 
	C584.951355,683.339661 587.805969,683.632446 584.116821,686.487793 
	C582.496521,686.775574 580.745789,687.769348 580.132568,685.755859 
	C579.646912,684.161133 581.402527,683.746765 582.676514,682.812927 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M561.308838,688.935730 
	C561.218323,686.926758 562.840332,686.562012 564.730469,686.096130 
	C565.052307,686.511353 565.021667,686.926331 564.969604,687.652710 
	C563.850891,688.288513 562.753601,688.612732 561.308838,688.935730 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M502.989990,744.987793 
	C503.122009,745.066345 502.981171,744.192627 503.156708,744.949097 
	C503.015808,745.012085 502.997803,744.997437 502.989990,744.987793 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M462.128418,709.176025 
	C461.571136,708.845581 461.366608,708.679871 461.121490,708.279663 
	C461.562897,708.209045 462.090637,708.316467 462.791443,708.640015 
	C462.788147,709.036560 462.611755,709.216980 462.128418,709.176025 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M461.931976,709.184570 
	C462.012665,708.946106 462.203888,708.937439 462.679810,708.892517 
	C462.964508,708.856201 462.976105,709.330811 462.964111,709.567139 
	C462.648926,709.673767 462.345703,709.544128 461.931976,709.184570 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M461.530273,705.525635 
	C461.481445,705.551697 461.579132,705.499634 461.530273,705.525635 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M663.094910,363.808411 
	C669.814148,367.911896 675.024414,373.403381 680.920776,378.523468 
	C680.276123,380.743317 678.621643,378.748840 677.302307,379.833435 
	C680.919006,386.999695 690.078308,390.431854 691.040527,399.819397 
	C691.216980,401.393555 690.541382,402.025452 689.239380,402.542419 
	C686.490845,402.285370 685.256042,400.461304 683.959534,398.761688 
	C679.364868,392.738281 674.633911,386.800934 671.408813,379.468536 
	C670.976562,374.783417 667.507324,372.837067 664.565796,370.608002 
	C662.462646,369.014282 660.266541,367.574829 658.692505,365.413116 
	C658.001465,364.464203 657.346130,363.422058 658.369202,362.312439 
	C659.517883,361.066650 660.650818,361.256226 661.995605,362.670410 
	C662.311951,362.819763 662.168945,362.915344 662.135742,362.835968 
	C662.226196,363.156860 662.453308,363.482056 663.094910,363.808411 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M688.953979,402.321045 
	C689.761719,401.619995 690.328003,401.184357 690.967651,400.483643 
	C692.408203,400.134613 693.808289,397.353790 695.263794,400.609955 
	C694.263855,403.263885 692.689758,405.165436 690.188110,406.667206 
	C686.812683,406.280640 688.387207,404.251099 688.953979,402.321045 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M688.863037,401.936279 
	C691.074829,403.578125 687.937683,405.234589 689.650513,406.847778 
	C695.076355,413.910370 698.487122,421.851898 702.833740,429.661987 
	C703.286072,435.086060 707.127319,438.996887 707.425415,444.035583 
	C704.960449,442.314545 704.610657,439.594391 703.472290,437.285828 
	C702.999390,436.326935 702.141113,434.991882 700.872375,435.576569 
	C699.407654,436.251526 700.385620,437.532806 700.811646,438.519104 
	C701.394287,439.867889 702.168518,441.153717 701.775146,443.122192 
	C700.715698,443.607910 700.041077,443.079529 699.209351,442.177551 
	C698.985901,441.938965 698.986267,442.012115 699.022766,442.014282 
	C698.448364,441.476501 697.911926,440.875580 697.366150,439.907257 
	C697.091431,439.511139 696.981445,439.355377 696.709961,438.971008 
	C696.440491,438.594421 696.332581,438.446472 696.066406,438.080658 
	C695.808777,437.717316 695.709473,437.571777 695.474609,437.205566 
	C695.255493,436.837952 695.171753,436.690979 694.965088,436.327209 
	C694.842102,436.110413 694.588745,435.689178 694.453674,435.486145 
	C694.318604,435.283081 694.033264,434.888763 693.890137,434.690857 
	C693.747009,434.492920 693.478149,434.082214 693.357239,433.867981 
	C693.082397,433.359894 692.931152,433.064667 692.530273,432.315613 
	C692.191956,431.706543 692.105896,431.549988 691.881775,431.154541 
	C691.643005,430.755280 691.542175,430.594879 691.274719,430.188965 
	C690.991821,429.772278 690.875671,429.601074 690.593018,429.156677 
	C681.653809,415.138428 671.479858,402.520447 659.751709,390.726807 
	C659.428223,390.284821 659.305420,390.106903 658.831055,389.407532 
	C658.357300,388.720764 658.235107,388.555450 657.714966,387.921692 
	C657.174988,387.302185 657.032898,387.151123 656.455444,386.536865 
	C655.879761,385.907959 655.739380,385.742249 655.402588,385.309448 
	C655.088623,384.850769 654.971069,384.659241 654.702271,384.161987 
	C651.900574,378.873474 656.180359,374.371918 655.606995,369.467987 
	C655.462158,368.229248 657.220459,367.181305 658.579285,368.354218 
	C659.600647,369.235870 661.273865,369.518982 661.765686,370.456055 
	C663.931763,374.582886 668.092041,376.054443 671.772400,378.719604 
	C676.446960,385.161987 679.752930,392.107422 684.810486,397.883453 
	C686.006165,399.248993 686.947632,400.824585 688.863037,401.936279 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M710.460693,460.986053 
	C711.173401,456.095093 707.597778,452.054993 707.870361,447.166443 
	C710.753052,451.064301 713.166504,455.257935 714.209595,460.483490 
	C713.158203,460.899231 712.020142,460.945404 710.460693,460.986053 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M711.607788,448.258301 
	C711.450500,448.124664 711.429565,447.890106 711.408569,447.655579 
	C711.520386,447.822845 711.632263,447.990112 711.607788,448.258301 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M716.765137,468.221619 
	C718.670349,470.611755 719.645081,473.447052 719.527710,476.849915 
	C716.932678,474.711945 716.901428,471.524170 716.765137,468.221619 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M716.319641,484.948486 
	C716.007141,483.927826 716.002136,482.864105 715.999756,481.401398 
	C716.871399,482.197662 718.967529,483.196533 716.319641,484.948486 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M698.946533,442.313751 
	C700.332336,441.004486 700.591858,442.310730 701.422729,443.258057 
	C701.879578,444.454681 702.068542,445.384399 702.157349,446.664154 
	C700.884888,445.661377 698.258911,445.367157 698.946533,442.313751 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M707.010620,462.672180 
	C706.654724,462.481995 706.311340,461.969910 705.987976,461.126221 
	C706.346375,461.313171 706.684753,461.831726 707.010620,462.672180 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M706.011780,459.757812 
	C705.596558,459.869263 705.226929,459.731293 705.780396,459.137665 
	C705.957764,459.059906 706.027039,459.523804 706.011780,459.757812 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M673.876343,427.291382 
	C675.940857,426.594666 678.473877,428.141968 679.714050,425.669434 
	C680.975708,423.154144 677.555115,422.426544 677.156189,420.365662 
	C678.223999,418.961945 680.520508,420.585571 681.462280,418.317169 
	C685.155884,418.757294 685.762085,422.286865 687.756836,424.498047 
	C689.160645,426.054169 689.947510,428.107880 691.796631,429.790405 
	C692.048889,430.037964 692.045532,429.959991 692.006958,429.953979 
	C691.968445,429.947937 692.249146,430.335236 692.347961,430.552795 
	C692.450317,431.100159 692.549500,431.398163 692.884949,431.856934 
	C693.025635,432.049561 693.033508,431.973663 692.996521,431.964386 
	C693.405457,432.404114 693.475830,433.012451 693.822144,433.820160 
	C693.996216,434.062744 694.008484,433.992767 693.973755,433.985291 
	C694.192017,434.209503 694.439636,434.446777 694.862427,434.872925 
	C695.043945,435.055237 695.041748,434.967926 694.998535,434.961670 
	C695.403992,435.402466 695.475952,436.010254 695.821655,436.817902 
	C695.996338,437.060028 696.008240,436.992798 695.974731,436.986206 
	C696.192200,437.209381 696.437439,437.445068 696.857300,437.867188 
	C697.037476,438.047760 697.039307,437.958954 696.996460,437.947144 
	C697.758667,438.866364 697.945801,440.030365 698.118530,441.524628 
	C697.424438,442.435791 696.788330,443.000336 695.958374,443.829895 
	C692.477905,442.935822 689.369080,441.491272 687.055908,438.341888 
	C687.604370,437.533081 687.920532,436.645050 687.290649,436.409424 
	C684.086121,435.210602 682.822327,432.635834 682.401245,429.110168 
	C679.555664,431.510284 682.854065,434.685333 680.230957,436.796936 
	C679.264343,437.412537 678.812927,437.024719 678.189453,436.283234 
	C677.981689,436.047699 677.971924,436.021698 677.958374,436.018707 
	C677.410950,432.779846 674.369141,430.797913 673.876343,427.291382 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M698.303345,441.955536 
	C697.624878,440.786377 697.189148,439.701477 696.853516,438.275940 
	C698.654785,438.519867 698.944153,440.189392 699.377563,441.760010 
	C698.888123,442.024261 698.717041,442.032043 698.303345,441.955536 
z"
    />
    <path
      fill="#E9E8E8"
      opacity="1.000000"
      stroke="none"
      d="
M366.935791,572.678345 
	C367.495483,573.792847 367.852814,574.625854 368.077942,575.778564 
	C361.650085,569.595581 357.889282,561.555298 354.587952,553.211365 
	C356.617950,553.438843 356.291107,555.255615 357.435852,556.506348 
	C360.062775,562.344116 363.393463,567.373169 366.935791,572.678345 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M370.604797,578.007324 
	C371.026886,578.536377 371.197876,579.018433 371.116150,579.791138 
	C369.745300,580.247009 369.682953,579.240417 369.555115,578.345642 
	C370.019073,578.023865 370.186371,577.992188 370.604797,578.007324 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M367.891602,576.388611 
	C368.262451,576.166870 368.632904,576.301575 368.984528,576.780273 
	C368.607544,576.997803 368.249390,576.871399 367.891602,576.388611 
z"
    />
    <path
      fill="#EBEBEB"
      opacity="1.000000"
      stroke="none"
      d="
M368.788391,577.426392 
	C369.226227,577.188293 369.575409,577.344727 369.945648,577.817017 
	C369.421204,578.529053 368.997467,578.430908 368.788391,577.426392 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M323.465210,448.036377 
	C322.978577,446.448090 323.091217,445.000122 325.655457,445.044373 
	C326.035675,446.588257 325.830383,447.970032 323.465210,448.036377 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M320.837433,464.810425 
	C320.531616,464.828247 320.423767,464.453186 320.435364,464.258484 
	C320.693573,464.042694 320.940155,464.021576 321.555420,463.962402 
	C321.663788,464.213745 321.403534,464.503174 320.837433,464.810425 
z"
    />
    <path
      fill="#494847"
      opacity="1.000000"
      stroke="none"
      d="
M357.966553,416.957458 
	C358.072845,416.869263 358.190613,416.097107 358.209656,416.983276 
	C358.067017,417.055298 357.997925,416.992157 357.966553,416.957458 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M672.091675,378.703674 
	C669.526917,377.990356 667.125366,376.858307 664.630066,375.998688 
	C663.229431,375.516174 662.002747,374.867493 662.119263,373.377197 
	C662.358337,370.318878 658.428833,371.222015 658.035889,368.852386 
	C658.030273,368.818451 657.034546,368.750092 656.787659,368.991241 
	C655.990295,369.770264 655.796387,370.834869 656.659668,371.615295 
	C658.423889,373.210205 657.686951,374.136017 656.273743,375.580933 
	C653.660278,378.253174 655.369141,381.483154 655.679565,384.918335 
	C652.192688,385.719788 650.523376,382.855072 648.350281,381.148529 
	C645.671387,379.044922 642.982056,376.976746 639.852905,374.890137 
	C638.607849,373.906647 637.903992,372.952026 637.518311,371.399414 
	C638.564209,365.801208 634.296936,370.092560 632.885132,368.718079 
	C625.163635,366.558777 618.781860,362.566772 612.733398,357.506012 
	C613.296631,355.284607 614.660522,354.227997 616.521301,353.631683 
	C614.959656,353.548340 613.797058,352.840698 613.074768,351.482605 
	C612.520569,350.440582 612.089661,349.307343 612.846313,348.234467 
	C613.651306,347.092987 614.891663,347.268494 615.975342,347.703674 
	C619.113892,348.963959 622.754211,349.736267 623.062622,354.388123 
	C623.140198,354.607819 623.165039,354.921265 623.165039,354.921265 
	C623.165039,354.921265 623.462219,354.921814 623.347961,354.768677 
	C622.763245,352.854370 623.531250,351.456970 624.779053,349.933716 
	C625.252930,349.469086 625.833984,349.621094 625.863037,349.222107 
	C625.878113,349.013885 625.424011,349.010742 624.833801,349.236786 
	C621.705139,346.807251 619.910828,343.107330 615.313416,342.974030 
	C613.801208,342.930206 613.879700,340.734955 614.800903,339.044006 
	C629.319824,343.857880 642.447998,351.082123 655.001099,359.421661 
	C655.688110,359.878052 656.378967,360.328522 657.642883,361.159851 
	C657.330994,357.540710 658.839905,356.123352 662.475464,357.375427 
	C664.870850,358.410675 665.380127,361.660065 668.984375,360.400421 
	C671.000183,359.695923 669.290283,362.472534 669.657898,363.530884 
	C666.912598,364.151093 665.099121,360.106689 662.204102,362.472534 
	C661.214050,363.032562 659.973267,361.514191 659.090759,362.750092 
	C657.969910,364.319916 659.915466,365.166290 660.590393,365.851410 
	C663.679321,368.986664 668.011719,370.655151 670.769592,374.259521 
	C671.720764,375.502594 673.590393,376.365356 672.091675,378.703674 
z"
    />
    <path
      fill="#2D2F39"
      opacity="1.000000"
      stroke="none"
      d="
M678.988647,370.997864 
	C678.636719,370.865662 678.468628,370.542267 678.300537,370.218872 
	C678.552063,370.174774 678.803589,370.130676 679.283142,370.242432 
	C679.398315,370.534424 679.285400,370.670532 678.988647,370.997864 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M663.233765,363.642212 
	C663.019714,363.718933 662.650757,363.540741 662.192200,363.059570 
	C662.428040,362.966827 662.753479,363.177094 663.233765,363.642212 
z"
    />
    <path
      fill="#272D35"
      opacity="1.000000"
      stroke="none"
      d="
M693.458252,359.522522 
	C693.431396,359.474670 693.485107,359.570374 693.458252,359.522522 
z"
    />
    <path
      fill="#3F5866"
      opacity="1.000000"
      stroke="none"
      d="
M634.945801,330.816162 
	C629.245361,328.645386 623.538940,326.141724 617.909668,323.671906 
	C616.403381,324.853058 617.266785,326.044952 617.151062,327.065063 
	C616.856140,329.664154 615.098877,327.980164 614.190369,327.879608 
	C613.246338,327.775146 612.874207,327.568787 613.228455,325.638641 
	C614.377686,319.376892 617.182678,318.684814 622.547302,321.014526 
	C626.837830,321.228882 630.104370,322.991241 633.091553,325.629578 
	C633.658447,327.496979 634.299133,328.990143 634.945801,330.816162 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M633.379028,326.036896 
	C630.037415,324.027130 626.713989,322.800934 623.149658,321.327637 
	C623.243652,320.635925 623.567749,320.214813 623.926392,319.518036 
	C626.723755,320.463165 629.486511,321.683929 632.598633,322.982422 
	C634.991089,324.478210 637.034058,325.896271 639.061890,327.667603 
	C636.911621,328.366760 635.599365,326.474609 633.379028,326.036896 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M638.987976,328.317017 
	C639.754700,327.387695 640.166870,328.159698 640.888306,328.745697 
	C640.378845,328.917389 639.694336,328.794403 638.987976,328.317017 
z"
    />
    <path
      fill="#46B3B4"
      opacity="1.000000"
      stroke="none"
      d="
M529.002563,294.003235 
	C528.912781,294.039948 528.820496,294.073456 528.861084,294.051208 
	C528.994080,293.995392 529.000000,294.000000 529.002563,294.003235 
z"
    />
    <path
      fill="#2F606E"
      opacity="1.000000"
      stroke="none"
      d="
M565.620911,303.609619 
	C565.128052,303.210297 565.075928,302.819366 565.004944,302.131561 
	C570.360840,302.036682 575.735718,302.238647 581.575928,302.331421 
	C589.085205,303.463593 596.129089,304.704956 603.588989,305.937683 
	C604.087646,308.441986 604.680969,310.524933 607.795044,310.590851 
	C608.793884,310.611969 608.840515,311.131989 608.271179,311.793732 
	C608.064270,312.034271 607.744080,312.177338 607.306885,312.566864 
	C593.668213,308.721252 580.134155,304.961212 565.620911,303.609619 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M345.799530,499.263916 
	C346.002228,498.986969 346.446228,499.053223 346.664520,499.103149 
	C347.047821,500.979279 346.603760,502.512909 344.968292,503.674561 
	C343.311279,501.771698 344.257263,500.617676 345.799530,499.263916 
z"
    />
    <path
      fill="#17181F"
      opacity="1.000000"
      stroke="none"
      d="
M344.927917,477.280945 
	C345.896729,477.413696 345.990356,478.315125 345.973846,479.601990 
	C345.076202,479.402252 345.023193,478.508240 344.927917,477.280945 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M419.424866,330.530792 
	C419.370697,330.451569 419.586395,330.426880 419.586395,330.426880 
	C419.586395,330.426880 419.479004,330.610016 419.424866,330.530792 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M623.393250,356.311218 
	C621.710388,351.730957 618.550903,349.217987 613.935974,348.398132 
	C614.161926,352.433014 614.161926,352.433014 619.697021,353.603577 
	C616.755493,353.866119 615.492676,355.808136 613.284790,356.862305 
	C612.618286,357.364441 612.190735,357.576416 611.335083,357.652161 
	C608.739990,356.750305 606.588257,355.726868 604.630371,353.796478 
	C604.455566,351.318878 603.282227,351.215576 601.299072,351.981140 
	C593.979492,350.534973 587.596741,347.290131 580.741394,345.414551 
	C570.847290,342.707550 560.889954,340.343475 550.798462,338.529053 
	C549.657654,338.323944 548.505432,338.177094 547.301086,337.101196 
	C545.255188,335.525085 543.074890,336.452057 541.028687,335.392944 
	C540.866028,335.049347 541.247437,334.744629 541.084534,334.926422 
	C531.857788,335.556427 522.753235,334.536469 513.710327,335.980377 
	C510.119751,336.553680 506.472626,336.772552 502.406311,337.136353 
	C505.528290,333.893677 505.739014,330.427063 502.260864,328.405548 
	C507.698639,327.079163 513.352783,325.980774 519.419556,324.902405 
	C521.062378,324.257599 521.975159,324.507721 522.585144,325.816254 
	C522.954224,326.608063 522.808594,328.166687 524.141907,327.787872 
	C525.587158,327.377258 524.907166,325.912781 524.960083,324.502502 
	C526.074829,324.053375 527.102966,323.943726 528.515259,323.895416 
	C532.187927,322.390381 535.852356,324.503784 539.451904,322.784424 
	C539.991394,322.638733 540.217102,322.619446 540.717834,322.799744 
	C541.446960,323.074371 541.901062,323.149384 542.687012,323.322083 
	C543.018860,323.419708 543.499146,323.429993 543.739319,323.433502 
	C544.724731,323.384216 545.469910,323.331451 546.582031,323.358551 
	C549.348022,324.477844 551.873840,323.821106 554.677917,323.982239 
	C555.458435,323.957581 555.899048,323.941010 556.658081,324.023804 
	C560.148071,325.609344 563.664368,325.346649 566.996155,325.853729 
	C570.734497,326.422668 573.941589,328.642181 577.757690,328.753113 
	C578.371521,328.770966 578.594421,329.680634 578.759888,330.688477 
	C578.707153,331.282135 578.649231,331.514557 578.410522,332.055481 
	C577.323914,333.349548 576.032227,333.823334 575.087585,335.028961 
	C578.701904,331.076080 582.618103,334.347321 586.375183,334.389587 
	C588.322205,334.411499 590.285339,334.887390 592.596313,334.565491 
	C593.343689,334.616852 593.721558,334.767883 594.249084,335.247864 
	C594.933472,335.908203 594.007874,335.621674 594.653381,335.787750 
	C594.666260,335.791046 594.626160,335.798126 594.478699,335.528503 
	C594.021057,334.967377 593.906250,334.587494 594.133667,333.887390 
	C597.162109,332.561035 599.422668,333.584198 601.451782,335.888428 
	C600.051147,337.171967 601.147827,339.340302 599.442810,340.210083 
	C601.032471,339.900116 600.271545,338.634155 600.713013,337.520996 
	C601.746094,336.068573 602.895874,335.365570 604.655212,335.062134 
	C608.461060,335.349640 611.404114,337.226593 614.845581,338.544800 
	C615.464539,339.988342 613.856079,342.234619 616.490906,342.168884 
	C620.706116,342.063751 623.310425,343.714203 624.161072,347.951813 
	C624.226685,348.278381 624.965149,348.469910 625.699097,348.864929 
	C625.896851,351.229828 623.357361,352.648193 623.980774,355.490448 
	C623.878601,356.021881 623.775940,356.178528 623.393250,356.311218 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M577.636414,332.044891 
	C577.891724,330.887207 577.659058,328.722656 576.687988,329.354004 
	C572.414551,332.132507 570.680115,326.871674 567.151001,326.678192 
	C563.963684,326.503418 560.170166,327.156586 557.087158,324.493225 
	C572.121216,324.536316 586.394043,329.165710 600.788757,333.198242 
	C602.044983,333.550140 603.262695,334.039673 604.747314,334.743835 
	C604.327759,336.411041 603.217407,337.051758 601.345703,336.991089 
	C599.673950,333.982483 597.207764,333.874268 594.237549,334.727783 
	C589.142151,336.850922 588.218811,336.740448 580.498474,333.875122 
	C578.926636,333.291748 578.330505,335.442657 577.272217,335.522980 
	C576.015686,335.618408 574.830811,338.814911 573.457336,335.810669 
	C572.322144,333.327576 574.400391,333.147614 575.868530,332.561859 
	C576.326233,332.379211 576.803955,332.246552 577.636414,332.044891 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M586.189087,316.970978 
	C583.953979,316.186249 581.809082,315.163055 582.477783,312.932861 
	C583.177673,310.598480 585.531372,311.621704 587.199097,312.011688 
	C588.507751,312.317657 589.707336,313.311554 589.647644,314.739685 
	C589.575073,316.477112 588.355042,317.280151 586.189087,316.970978 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M598.363159,316.168518 
	C600.394897,317.820862 599.473572,319.904694 598.589783,320.632843 
	C596.578735,322.289673 594.686340,319.935394 592.362610,319.565857 
	C593.391357,316.775726 594.688232,314.874268 598.363159,316.168518 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M539.824829,319.419189 
	C540.039856,316.216797 541.953552,315.803986 544.620972,315.879272 
	C546.704163,316.993011 547.178772,318.314789 545.313904,320.040588 
	C544.098022,320.844696 543.044006,321.002106 541.525879,320.874512 
	C540.591858,320.626312 540.171265,320.295410 539.824829,319.419189 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M554.667969,323.795410 
	C552.445007,325.572693 549.841064,325.475983 547.081421,323.777771 
	C549.405273,323.497864 551.861633,323.557281 554.667969,323.795410 
z"
    />
    <path
      fill="#4B4A4A"
      opacity="1.000000"
      stroke="none"
      d="
M605.365051,318.686707 
	C607.486572,317.939606 609.036560,318.486572 609.174500,320.417358 
	C609.260010,321.615631 607.845947,322.095398 606.600098,322.030731 
	C604.326904,321.912750 604.437561,320.579224 605.365051,318.686707 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M553.350586,318.948547 
	C551.544006,318.608246 550.143311,318.276215 547.629639,317.680328 
	C552.339111,316.335297 555.493774,319.184174 558.990112,318.169220 
	C557.292236,319.469452 555.488220,318.793793 553.350586,318.948547 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M540.635620,320.179504 
	C542.062561,320.055389 543.139038,320.038055 544.614807,320.082764 
	C544.817444,322.469604 542.581421,321.640869 541.205322,322.734924 
	C540.839539,323.072754 540.686279,323.146118 540.290649,323.298035 
	C539.808716,323.437988 539.569336,323.499390 538.964355,323.493896 
	C537.731934,322.978516 536.688171,322.391205 537.038269,321.344879 
	C537.558167,319.791168 539.024231,320.126282 540.635620,320.179504 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M545.384766,314.122498 
	C543.566956,314.050232 541.826416,314.195831 542.168457,311.491638 
	C545.304932,309.767242 546.153625,311.115082 545.384766,314.122498 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M557.203857,309.199738 
	C559.526306,310.558899 557.645691,311.703217 557.310181,313.364929 
	C555.072510,311.846741 557.112244,310.648956 557.203857,309.199738 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M539.161987,310.851593 
	C538.432007,311.430542 537.862488,311.859436 537.292969,312.288330 
	C537.195801,311.721466 537.098572,311.154633 536.999512,310.284332 
	C537.466980,309.638916 537.936279,309.296997 538.713440,308.975311 
	C539.121704,309.564240 539.222107,310.132874 539.161987,310.851593 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M622.365784,332.024536 
	C623.372437,332.262054 624.074829,332.487732 624.777161,332.713409 
	C624.707764,332.956848 624.638306,333.200287 624.568909,333.443695 
	C623.448486,333.173248 622.327332,332.905853 621.210999,332.619537 
	C621.172852,332.609741 621.148132,332.324493 621.176453,332.312958 
	C621.464417,332.195557 621.764648,332.108368 622.365784,332.024536 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M562.242798,319.278687 
	C563.696838,319.109344 564.896851,319.109344 566.096863,319.109344 
	C564.691345,320.134399 563.322205,320.341278 562.242798,319.278687 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M595.352295,326.049225 
	C594.826965,325.749359 593.433167,325.854248 594.632019,325.180939 
	C595.225037,324.847839 596.602295,324.129700 596.976624,325.803589 
	C596.534119,325.896973 596.091675,325.990356 595.352295,326.049225 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M570.886353,321.026337 
	C570.125977,320.937286 569.673096,320.843048 569.220276,320.748840 
	C569.267883,320.539795 569.287476,320.160858 569.367432,320.147675 
	C570.281799,319.996857 571.225586,319.691742 572.070679,320.363861 
	C572.114929,320.399078 572.087830,320.635498 572.034180,320.663452 
	C571.765015,320.803711 571.475952,320.905701 570.886353,321.026337 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M640.934326,343.849243 
	C642.100037,343.966553 643.740417,343.253052 643.537598,344.791504 
	C643.407837,345.775299 641.975525,345.090210 640.801392,344.672424 
	C640.539490,344.284058 640.595886,344.099426 640.934326,343.849243 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M622.626953,335.729462 
	C622.614075,335.984863 622.480103,336.123627 622.346191,336.262390 
	C622.399414,336.045868 622.452637,335.829346 622.626953,335.729462 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M543.740967,323.342804 
	C543.979492,323.437012 543.643860,323.751892 543.643860,323.751892 
	C543.643860,323.751892 543.192993,323.689484 543.105957,323.554596 
	C543.180054,323.362701 543.341187,323.305664 543.740967,323.342804 
z"
    />
    <path
      fill="#5F5E5E"
      opacity="1.000000"
      stroke="none"
      d="
M640.669800,344.301086 
	C640.387329,344.057892 640.291626,343.647125 640.195923,343.236328 
	C640.469849,343.228027 640.743713,343.219696 641.117004,343.497528 
	C641.096375,343.900299 640.976440,344.016876 640.669800,344.301086 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M617.557861,333.574036 
	C617.478638,333.623932 617.446472,333.423462 617.446472,333.423462 
	C617.446472,333.423462 617.637024,333.524139 617.557861,333.574036 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M620.397888,334.589233 
	C620.331360,334.501526 620.576294,334.460632 620.576294,334.460632 
	C620.576294,334.460632 620.464417,334.676941 620.397888,334.589233 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M614.155640,383.242432 
	C612.247803,377.812988 606.413086,375.225342 603.023682,378.493896 
	C602.811462,379.240875 602.598328,379.619568 601.993164,379.955872 
	C601.664795,379.949249 601.356018,379.965881 601.201660,379.974670 
	C594.496460,378.220734 588.937378,374.241638 582.623779,371.851105 
	C570.689514,367.332397 558.412842,363.878326 546.416565,359.570770 
	C542.050598,358.003052 537.348206,357.840454 532.304199,357.908447 
	C531.638245,357.974426 531.368835,357.988831 530.692139,357.979431 
	C521.915283,356.156494 513.361206,356.775635 504.513794,356.102295 
	C502.788910,356.375824 501.450806,356.362183 499.729828,356.412231 
	C497.849091,356.486359 496.358246,356.434967 494.523804,356.232910 
	C493.062897,355.972717 491.980164,356.005310 490.631042,356.686523 
	C489.576965,356.916931 488.873871,356.844849 487.875397,356.386902 
	C484.660400,352.955292 487.685669,349.496826 486.958801,345.885376 
	C488.831696,344.854218 490.849487,343.819641 493.583435,343.918884 
	C497.376312,344.763275 500.443939,343.118713 503.532043,342.440247 
	C514.535583,339.820587 525.819763,339.641113 537.709106,340.062195 
	C538.250427,340.439941 538.237366,340.612549 538.167664,340.757996 
	C538.099670,340.899902 537.973877,341.014069 537.873413,341.140411 
	C543.406738,340.204254 547.559387,344.528107 552.725281,345.066742 
	C553.680237,345.166290 554.526489,346.058594 554.414856,347.213257 
	C554.146973,349.984558 556.039368,350.821991 558.438843,351.455475 
	C558.963135,351.255859 559.162048,351.145569 559.675415,350.898071 
	C561.954102,350.122040 563.918945,349.490173 566.277832,350.357971 
	C566.782104,350.783752 567.028625,351.046875 567.359863,351.033508 
	C566.836365,349.579651 564.711792,348.866974 565.975952,347.093140 
	C566.966553,345.703186 568.578125,345.936554 570.061829,346.200684 
	C574.224609,346.941803 578.224060,348.718750 582.947144,348.101196 
	C588.985107,348.455444 594.139099,350.296204 598.871582,354.087830 
	C599.325989,358.790741 603.627625,358.166199 606.018860,359.682831 
	C610.101807,362.272430 612.574158,366.290558 611.725525,371.343414 
	C611.051025,375.359833 613.227905,377.376526 616.939575,375.618591 
	C619.778015,374.274261 621.009949,373.062408 620.667969,377.683624 
	C620.514587,379.757172 622.878418,381.952789 623.841492,384.203583 
	C624.246277,385.149628 625.258179,386.411407 624.254211,387.407379 
	C623.133667,388.519012 621.848999,387.837494 620.653076,387.124573 
	C619.387451,386.370056 618.038452,385.755341 616.364136,385.039185 
	C615.438965,384.493713 614.875427,383.988647 614.155640,383.242432 
z"
    />
    <path
      fill="#E0DFDE"
      opacity="1.000000"
      stroke="none"
      d="
M599.410400,354.559143 
	C594.137207,352.129089 589.050232,350.317749 583.422363,348.908691 
	C576.568176,348.773590 570.574524,346.288361 564.334229,345.042603 
	C555.786316,343.336151 547.099121,342.509155 538.273743,340.338074 
	C537.946777,340.096283 537.896667,339.870636 537.982117,339.792786 
	C539.152771,336.766113 541.482422,338.138672 543.341980,338.326752 
	C544.702087,338.464294 546.109192,339.597839 546.985718,337.165192 
	C565.982483,339.184479 584.267090,343.989075 602.139526,351.920166 
	C603.338562,351.365723 603.695862,351.693878 603.477905,352.469299 
	C605.722778,353.602966 608.282410,354.906647 611.063293,356.544098 
	C610.942383,357.615295 610.329773,357.997864 609.208069,358.286774 
	C605.822327,357.155823 602.797180,355.923706 599.410400,354.559143 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M683.938110,482.468018 
	C683.607910,480.298767 683.304810,478.538025 683.001526,476.388824 
	C685.797363,475.649902 685.942627,478.045258 686.814026,479.684967 
	C687.983948,481.886444 688.455994,484.364960 689.851196,486.712158 
	C694.994873,490.082581 696.303345,495.288666 697.467285,500.714111 
	C695.392334,503.056244 698.200745,504.508575 698.022705,506.751190 
	C698.015320,507.640472 697.889648,508.126678 697.454590,508.889709 
	C694.038635,509.004242 695.378540,511.661255 694.608765,513.457214 
	C692.114807,516.120544 694.976257,519.651062 692.233093,522.043335 
	C691.684570,514.345032 689.471924,507.013855 688.013550,499.231018 
	C685.894043,493.738037 684.929443,488.307343 683.938110,482.468018 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M690.289734,486.521851 
	C689.259644,489.581085 688.569763,488.107513 688.054993,486.679779 
	C686.779724,483.142731 685.855042,479.461670 683.312378,476.168091 
	C678.551025,463.905426 672.055298,452.894012 665.092468,442.126129 
	C664.077881,440.557007 663.061157,439.095795 660.600952,439.080505 
	C660.024353,438.811066 659.794495,438.523193 659.309143,438.131500 
	C656.300232,434.579681 660.362549,435.376373 661.703796,434.131470 
	C667.370911,439.633636 671.230042,446.321442 675.333740,452.862793 
	C681.504944,462.699768 685.640930,473.524323 690.730652,483.884033 
	C691.018677,484.470215 690.546021,485.430176 690.289734,486.521851 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M639.831421,373.776611 
	C645.055054,377.285431 650.028503,381.182281 655.311768,385.205322 
	C655.621521,385.331451 655.646484,385.314453 655.648071,385.328430 
	C656.052429,385.294434 656.394043,385.416992 656.731018,385.993469 
	C656.787231,386.277344 656.846802,386.158264 656.786987,386.187500 
	C657.110352,386.248962 657.428955,386.405945 657.751343,386.974030 
	C657.829590,387.241455 657.858765,387.143127 657.809570,387.157776 
	C658.127258,387.241211 658.431091,387.418243 658.761597,387.960510 
	C658.851135,388.217743 658.874512,388.123535 658.827148,388.134186 
	C659.144043,388.237457 659.439331,388.433655 659.786011,388.980042 
	C671.920837,401.539001 682.984924,414.612030 692.020325,429.684448 
	C688.808411,429.470825 688.359192,426.247040 686.615356,424.266510 
	C685.006042,422.438812 683.612610,420.421112 681.885132,418.229401 
	C670.091187,401.967316 656.181763,388.291779 640.275513,376.119568 
	C639.623718,375.375763 639.466858,374.807678 639.831421,373.776611 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M693.737305,513.500366 
	C694.877808,511.871613 692.070068,508.441315 696.617859,508.982239 
	C698.230835,509.756104 698.065613,511.434937 699.545349,512.771179 
	C697.913635,511.053436 698.136292,509.323608 697.614075,507.471252 
	C697.571655,506.654724 697.770996,506.260773 698.469238,505.969360 
	C699.914795,505.804016 702.038452,502.359283 701.778198,506.311707 
	C701.602295,508.982422 702.745544,510.998199 703.005127,513.674133 
	C702.903259,515.221436 703.704346,516.183716 703.991821,517.649902 
	C704.021362,518.453125 704.012451,518.910889 703.983948,519.711426 
	C703.661621,520.342712 703.515747,520.699280 703.504211,521.418457 
	C703.472412,522.134583 703.368896,522.528381 703.061890,523.182678 
	C702.796326,523.626831 702.674011,523.795166 702.313538,524.166321 
	C701.879333,524.439758 701.683228,524.510437 701.174805,524.581665 
	C700.669006,524.506165 700.475525,524.430115 700.111694,524.092773 
	C698.034119,521.709778 696.458862,519.499695 696.970276,516.365417 
	C697.263977,514.565552 696.066895,514.577515 694.458496,514.994385 
	C693.718323,514.737305 693.518494,514.335388 693.737305,513.500366 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M673.857666,426.678101 
	C674.598328,430.126495 680.153687,430.871948 678.001038,435.670227 
	C672.249939,432.998047 670.199890,426.846252 666.257507,422.318970 
	C664.157043,419.906891 662.618713,417.005310 661.281433,413.955841 
	C667.604248,415.968140 669.220215,422.619141 673.857666,426.678101 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M609.069946,358.640930 
	C609.412231,357.932556 609.976929,357.477173 610.913086,356.949829 
	C611.659119,356.913239 612.033630,356.948578 612.690491,356.997406 
	C619.421753,360.617004 625.340271,365.141296 632.504272,368.226440 
	C633.760315,368.996918 633.443542,369.801971 632.986206,370.878540 
	C632.466370,371.673370 631.966858,372.046600 631.205872,372.587891 
	C630.631104,372.815186 630.308472,372.864227 629.985779,372.913239 
	C630.247192,366.907684 624.070923,368.054016 621.257507,365.409760 
	C618.055908,362.400696 613.346436,360.996002 609.069946,358.640930 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M702.738953,522.324219 
	C703.130005,521.583618 703.304077,521.002380 703.721191,520.237732 
	C705.463318,525.511353 705.224487,531.034668 704.228210,536.802856 
	C702.586609,536.511108 701.930359,537.566101 700.773071,538.213928 
	C699.449829,535.084167 698.222656,531.886963 699.831909,528.212280 
	C701.089844,526.951660 701.182129,525.530762 701.892700,524.048950 
	C701.937012,523.718750 701.867065,523.547485 701.887573,523.457275 
	C702.094910,523.060242 702.298645,522.765137 702.738953,522.324219 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M691.664246,460.147980 
	C694.943787,466.415405 698.457825,472.654297 698.602966,480.037506 
	C695.266479,474.981659 693.101624,469.412262 690.989685,463.438660 
	C690.225830,462.041595 690.534119,461.148102 691.664246,460.147980 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M632.138672,371.251892 
	C632.360474,370.283295 632.566895,369.593567 632.808716,368.629517 
	C633.244873,367.429504 634.226685,365.960541 634.753662,366.482758 
	C638.205994,369.903320 636.340759,364.675110 638.117737,364.848358 
	C639.139832,366.630341 640.385559,368.489166 638.070801,370.901855 
	C637.364807,371.566193 636.828430,371.709930 635.962646,371.518707 
	C635.507385,371.220642 635.400635,371.109467 635.400635,371.109467 
	C634.867432,371.383606 634.883789,371.407623 635.653076,371.364380 
	C637.438110,372.352142 638.743469,373.408081 639.164978,375.563782 
	C636.894165,376.663727 635.785767,374.010986 633.368042,374.055725 
	C632.962769,373.799286 632.954834,373.621735 632.962769,373.178101 
	C632.531494,372.551941 632.209167,372.114563 632.138672,371.251892 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M677.987549,436.420410 
	C678.473511,436.289093 678.965393,436.530457 679.708618,436.880859 
	C681.959595,440.739288 683.959106,444.488678 685.996643,448.596558 
	C685.315308,449.385254 684.731873,449.097809 684.041077,448.291321 
	C681.180664,444.643463 679.415527,440.808777 677.987549,436.420410 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M692.005249,459.654846 
	C691.692200,460.792084 691.369812,461.600067 691.013550,462.736084 
	C688.653687,460.000183 685.878845,457.047150 688.186707,452.617859 
	C690.341309,453.857239 689.534119,456.572327 691.694336,457.841431 
	C691.992615,458.005951 692.000000,458.000000 691.995850,458.003906 
	C691.993042,458.447052 691.994446,458.886292 692.005249,459.654846 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M639.359314,375.944031 
	C638.141785,374.190613 636.797302,372.831665 634.926025,371.530701 
	C634.767822,371.097443 634.835083,370.895966 635.110352,370.453186 
	C635.922852,370.458771 636.527222,370.705627 637.455566,371.007355 
	C638.429321,371.696381 639.079163,372.330505 639.905334,373.176636 
	C640.069458,373.939667 640.057312,374.490784 640.048584,375.455353 
	C640.052063,375.868835 639.592346,375.992249 639.359314,375.944031 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M683.675781,448.179443 
	C684.422363,448.240631 684.949829,448.503845 685.755981,448.861023 
	C686.034607,448.955017 686.024292,448.981781 686.033447,448.988861 
	C686.384338,449.417511 686.512939,449.896362 686.234070,450.765778 
	C685.909363,451.693146 685.754395,452.718903 685.007874,452.157379 
	C683.862305,451.295685 683.021240,450.028137 683.675781,448.179443 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M698.766724,505.670593 
	C698.527649,506.116760 698.323303,506.319183 698.101440,506.803436 
	C697.853088,507.173523 697.706726,507.346985 697.313416,507.286102 
	C696.968201,505.149689 695.141174,503.384918 696.935913,501.163757 
	C697.744080,502.426819 698.230591,503.884186 698.766724,505.670593 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M704.258545,517.806885 
	C702.969360,517.017456 701.944946,516.024414 702.768799,514.183105 
	C704.040466,514.984558 705.064331,515.973633 704.258545,517.806885 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M659.116333,410.122864 
	C657.820068,410.576355 657.436462,409.935486 657.408386,409.002441 
	C657.402222,408.799316 657.802246,408.400116 658.006104,408.406006 
	C658.947510,408.433136 659.566711,408.836700 659.116333,410.122864 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M631.897644,371.106354 
	C632.404114,371.437439 632.654175,371.901825 632.941406,372.639130 
	C632.520996,372.937988 632.063416,372.963959 631.279785,372.878052 
	C631.182922,372.257324 631.412109,371.748505 631.897644,371.106354 
z"
    />
    <path
      fill="#262B33"
      opacity="1.000000"
      stroke="none"
      d="
M686.271179,450.943695 
	C686.029053,450.631165 686.018860,450.163208 686.025635,449.345581 
	C686.797180,449.438934 687.048096,450.011353 686.271179,450.943695 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M633.021362,374.025452 
	C632.910706,374.023193 632.942749,374.930359 632.824951,374.040527 
	C632.970703,373.976837 633.005981,374.007721 633.021362,374.025452 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M690.469604,444.455963 
	C690.515137,444.429413 690.424133,444.482513 690.469604,444.455963 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M686.993896,438.005035 
	C686.873230,437.869202 686.758667,437.728333 686.644043,437.587463 
	C686.728760,437.632965 686.813477,437.678467 686.953857,437.855896 
	C687.009583,437.987793 687.000000,438.000000 686.993896,438.005035 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M693.648438,513.447388 
	C693.942322,513.595581 693.973938,514.010132 694.068115,514.732605 
	C694.139709,516.081299 694.148743,517.122070 694.200806,518.551270 
	C694.524170,519.423462 694.840637,519.891663 694.887756,520.750366 
	C694.679749,525.086060 696.231995,529.113403 695.072510,533.580688 
	C695.838867,534.987610 696.063721,536.078674 695.347412,537.655396 
	C695.129456,539.077087 695.135132,540.120667 695.215637,541.549561 
	C695.509399,542.395752 695.579468,542.878235 695.321777,543.711670 
	C695.338989,547.729553 695.535339,551.418213 695.747375,555.403137 
	C693.994141,554.684875 691.861938,555.000671 691.235901,552.290894 
	C691.327393,551.519531 691.534119,551.015259 691.770386,550.238953 
	C691.433472,547.389343 693.420471,545.017273 692.271362,542.123413 
	C691.964539,527.722168 690.623718,513.708374 687.764465,499.410461 
	C691.926636,505.701324 691.324097,513.492188 692.737549,520.916565 
	C692.953796,518.515625 693.170044,516.114685 693.648438,513.447388 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M694.769531,562.497253 
	C693.509888,560.604004 695.198853,559.534485 695.911682,558.151489 
	C695.911682,559.678101 695.916809,561.204834 695.901978,562.731262 
	C695.900818,562.851257 695.723694,563.082153 695.691711,563.071533 
	C695.431824,562.984924 695.187134,562.852966 694.769531,562.497253 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M695.451172,537.951416 
	C695.083557,536.951416 695.043274,535.869324 695.039490,534.382263 
	C697.611145,534.859253 697.562683,536.207642 695.451172,537.951416 
z"
    />
    <path
      fill="#A09F9F"
      opacity="1.000000"
      stroke="none"
      d="
M701.686340,523.771851 
	C703.371460,525.602539 702.446899,526.832642 700.474731,527.919678 
	C700.073364,527.539856 699.986877,527.100037 699.891602,526.323730 
	C699.975586,525.402222 700.186707,524.868347 700.754456,524.185059 
	C701.106567,523.916504 701.271118,523.870728 701.686340,523.771851 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M695.110962,520.880005 
	C694.627258,520.528015 694.379272,520.025146 694.187622,519.231079 
	C695.142334,519.209106 696.122314,519.428162 695.110962,520.880005 
z"
    />
    <path
      fill="#54555B"
      opacity="1.000000"
      stroke="none"
      d="
M700.691589,523.931274 
	C700.794556,524.573181 700.647095,525.184082 700.191223,525.891113 
	C698.972351,525.554688 699.071899,524.929504 699.744873,524.032043 
	C700.107910,523.854431 700.274536,523.877258 700.691589,523.931274 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M699.912598,538.128662 
	C700.297485,538.544617 700.358704,538.949036 700.331055,539.654419 
	C699.672729,539.747314 698.803894,539.693359 698.599792,539.296997 
	C698.245239,538.608459 698.910278,538.237793 699.912598,538.128662 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M695.412415,543.986145 
	C695.142090,543.570007 695.141479,543.099182 695.215698,542.281555 
	C696.580872,542.372864 697.385010,542.906250 695.412415,543.986145 
z"
    />
    <path
      fill="#030408"
      opacity="1.000000"
      stroke="none"
      d="
M621.913757,643.830566 
	C621.601318,643.955017 621.224304,643.933594 621.037720,643.962524 
	C620.729248,644.163635 620.607300,644.335876 620.235229,644.701904 
	C619.985107,644.895813 619.944885,644.941895 619.915100,644.948608 
	C618.863831,645.990723 617.819641,647.010132 617.192383,648.707764 
	C616.993713,648.948853 616.551697,649.033264 616.327881,649.056030 
	C615.322021,649.431824 614.514160,649.405029 613.704224,649.218628 
	C610.296814,648.434692 610.639587,650.325073 611.220459,652.801514 
	C608.593445,655.757446 605.967468,658.229797 602.389221,660.033325 
	C601.440430,660.057678 600.889709,660.202637 600.229614,660.831665 
	C600.044189,661.060791 600.025757,661.046875 600.045410,661.054565 
	C596.045410,660.944092 596.047607,655.845825 592.607361,654.890930 
	C592.488586,654.857971 593.752808,654.147766 594.843750,653.938965 
	C595.418213,653.807495 595.640137,653.704773 596.217590,653.692627 
	C602.005676,655.710144 605.731567,652.643982 604.716919,646.868835 
	C604.402100,645.077148 604.035217,643.330078 603.979675,641.498047 
	C603.869629,637.865417 606.080994,636.367493 609.013367,637.304382 
	C613.429260,638.715088 614.688293,636.218201 614.861328,633.037292 
	C615.122864,628.226990 615.923767,624.674927 620.898499,621.926331 
	C624.581909,619.891235 625.861572,614.670959 627.578674,610.552429 
	C628.442688,608.480164 627.102295,607.688904 625.235474,608.455261 
	C622.121460,609.733704 621.985779,607.286011 621.363159,605.529602 
	C620.568970,603.289185 621.375305,602.075073 623.845947,601.931763 
	C626.318481,601.788330 625.904663,599.823425 625.999512,598.215027 
	C626.192200,594.948975 625.013916,591.697571 625.849243,588.013306 
	C625.818481,587.366821 625.835938,587.131653 626.107544,586.652954 
	C627.098206,585.998840 627.897888,585.843567 628.731079,585.816833 
	C635.120850,585.611938 635.624573,584.826477 633.048401,579.057434 
	C632.645691,578.155518 632.185547,577.279785 631.624023,576.078857 
	C631.242737,575.140808 631.112549,574.486633 631.067627,573.491028 
	C631.100159,572.957947 631.130432,572.748474 631.240906,572.237366 
	C631.511963,571.364380 631.741638,570.806885 632.017822,569.964111 
	C632.175964,569.522766 632.246643,569.351990 632.452820,568.945862 
	C632.799500,568.441040 633.034668,568.195312 633.497925,567.870605 
	C633.691772,567.757996 634.066467,567.576904 634.066467,567.576904 
	C634.066467,567.576904 633.628845,567.589966 633.396118,567.701416 
	C632.991699,567.883484 632.819946,567.954041 632.385620,568.146362 
	C629.879272,571.802673 628.455627,575.778259 625.943054,579.498413 
	C625.361938,579.919495 625.025085,580.139099 624.465393,580.526550 
	C624.109436,580.819458 623.973206,580.939819 623.645996,581.246948 
	C623.334167,581.561157 623.213440,581.688721 622.762573,582.218018 
	C622.237976,582.910034 622.053833,583.206726 621.750977,583.747925 
	C621.528320,584.144287 621.432495,584.301208 621.173157,584.685791 
	C620.890320,585.061462 620.771057,585.209656 620.256714,585.787842 
	C619.276672,586.772278 618.644165,587.254395 617.569885,587.549927 
	C614.517883,582.981140 610.753662,587.120728 607.274170,586.722412 
	C606.237793,587.579468 605.534485,587.529907 604.948364,586.398193 
	C604.990723,584.366089 606.175415,583.003296 605.639893,581.158386 
	C604.791016,578.233704 604.852478,576.375977 600.849182,579.178101 
	C597.767822,581.335022 592.988220,581.181335 588.924255,581.792542 
	C582.243042,582.797241 577.313232,579.666565 573.731445,574.411438 
	C571.684448,571.408142 569.579163,576.301392 567.797241,574.060669 
	C567.634583,572.794189 568.717346,572.745422 569.327820,572.334778 
	C570.203735,571.745483 571.019226,571.211243 570.428101,569.980591 
	C569.990479,569.069519 569.528137,568.107117 568.314209,568.180420 
	C567.198547,568.247803 566.603027,569.031006 566.168274,570.004456 
	C563.604492,575.745056 560.710693,576.363220 555.088562,572.937195 
	C552.961243,571.640808 549.621704,571.894348 550.256226,568.004639 
	C545.028625,568.699341 543.276123,562.786804 538.431030,561.988037 
	C537.678894,561.923584 537.437988,561.733398 537.177490,561.187134 
	C536.890320,557.554565 535.338501,555.374512 531.705750,556.318359 
	C528.375244,557.183655 527.314392,554.251953 525.715637,552.902405 
	C521.126831,549.028870 518.238892,544.122009 520.602600,537.698914 
	C521.090149,536.373962 521.343079,534.836487 520.225342,533.955933 
	C516.478699,531.004456 516.834106,527.653381 518.481812,523.752563 
	C519.126221,522.226990 517.992493,520.317505 518.674500,518.699280 
	C517.328918,518.160278 516.755066,518.817749 516.160095,519.365784 
	C510.992615,524.125671 510.392456,524.099976 506.234131,518.362976 
	C505.247223,517.001404 504.480621,515.524353 502.074829,514.822693 
	C499.340942,509.580231 498.721344,503.991943 494.783081,499.724182 
	C492.625092,497.385651 490.589935,495.041534 487.450928,493.405334 
	C486.947205,493.194458 486.753540,493.097290 486.296173,492.817200 
	C485.226898,491.986847 484.915009,490.917297 483.803162,490.144226 
	C483.397522,489.837860 483.241333,489.705627 482.898743,489.327423 
	C482.675568,488.878693 482.638794,488.675934 482.800995,488.236572 
	C483.188629,486.186859 482.301971,484.912567 481.142059,483.591034 
	C475.894806,477.612488 471.475433,471.145233 468.584900,463.613831 
	C466.783752,458.920837 462.363434,458.082825 457.417297,457.993042 
	C452.649078,452.308380 450.524994,445.256683 445.703186,439.855347 
	C442.440918,436.201080 437.211456,437.737854 433.543182,434.914795 
	C431.139130,433.064636 428.265991,432.074738 429.282837,428.115234 
	C429.769012,426.222168 428.047455,426.148682 426.356781,426.004761 
	C425.341522,425.938385 424.737762,425.722992 423.911835,424.953918 
	C424.173798,424.206329 424.907745,423.977051 425.200470,423.485138 
	C425.565979,422.870941 424.889374,422.632263 424.396637,422.711243 
	C423.259857,422.893463 422.098297,423.062958 421.019684,423.443909 
	C412.184570,426.564880 404.079071,430.886108 397.336761,438.055725 
	C396.076263,437.764404 395.660553,436.881989 395.222961,435.584534 
	C395.078796,434.711395 395.099792,434.206818 395.322205,433.339294 
	C395.596680,432.760986 395.745483,432.548645 396.169800,432.060577 
	C413.176544,407.185425 435.529907,389.695648 463.706635,380.277863 
	C480.643372,374.616852 498.207489,372.484711 516.213196,374.352448 
	C523.652954,375.124176 531.021912,376.042938 538.717285,377.604187 
	C559.843201,382.017700 577.970581,392.062256 595.741211,403.763977 
	C596.315430,404.138641 596.552795,404.325867 596.863220,404.767761 
	C597.956177,406.446350 596.401855,408.320679 597.862549,410.034119 
	C598.092651,410.456635 598.162903,410.698242 598.276733,410.720947 
	C610.137329,413.088074 615.751160,423.243896 622.367249,431.370483 
	C635.225708,447.164734 645.002991,464.909454 649.820374,484.998260 
	C652.678040,496.915192 653.884583,509.116211 656.016418,521.581787 
	C656.371582,538.657227 654.115173,555.051758 651.078125,571.781006 
	C651.701538,572.802979 651.890320,573.543152 651.874512,574.731934 
	C650.581787,578.129150 650.931335,581.692932 648.399658,584.628723 
	C645.424744,594.224487 641.560486,603.121094 637.979980,612.507446 
	C638.074646,613.087402 638.228210,613.304565 638.144714,613.842651 
	C636.900574,619.175537 635.248230,623.881287 631.248901,627.792664 
	C628.358521,628.917603 628.107300,631.038269 628.138367,633.687073 
	C626.744629,636.102295 625.483215,638.197205 624.113708,640.607483 
	C623.219177,641.726990 623.297302,643.083862 621.913757,643.830566 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M475.358093,555.098389 
	C473.025696,553.889587 471.652435,551.882202 469.009766,551.760437 
	C467.069366,551.671082 465.730347,550.142029 465.233978,548.249756 
	C464.740784,546.369507 463.498199,545.419006 461.771454,545.152222 
	C460.031464,544.883301 458.939819,544.499817 459.597015,542.350403 
	C459.867523,541.465576 458.914917,540.859131 458.122131,540.629883 
	C449.890015,538.249023 446.034821,530.620850 440.077362,525.523132 
	C439.325317,524.879578 438.743225,523.733704 437.774933,524.136841 
	C434.241394,525.607605 432.895477,523.678467 430.696014,521.152954 
	C427.403992,517.372925 424.490417,515.413818 431.357819,512.089355 
	C434.549927,510.544128 431.470734,505.733582 431.568909,502.404144 
	C431.594788,501.526276 430.759308,500.622986 430.156830,499.394836 
	C431.297821,496.028687 434.401062,495.965515 437.235046,494.910217 
	C438.533447,495.124969 439.318665,495.602844 440.220367,496.512604 
	C440.807556,497.259247 441.112823,497.799652 441.621582,498.533783 
	C441.943634,498.857697 442.074493,498.980133 442.406616,499.267181 
	C442.745056,499.529846 442.882202,499.627869 443.220612,499.863892 
	C443.421875,500.001923 443.799652,500.283691 443.959290,500.438171 
	C444.118927,500.592651 444.359222,500.925537 444.359222,500.925537 
	C444.359222,500.925537 444.277771,500.493286 444.253937,500.236145 
	C444.261292,499.611481 444.351990,499.258179 444.635712,498.659821 
	C445.672211,497.554291 446.350403,496.622498 446.002014,494.965607 
	C446.041779,494.203369 446.143158,493.779144 446.459656,493.079102 
	C447.945801,491.608368 448.802460,490.187683 448.939392,488.032318 
	C449.234406,486.781952 449.626129,485.934235 450.282135,484.817017 
	C453.718231,481.899780 455.703949,479.757843 451.432281,475.672485 
	C448.608459,472.971863 447.870544,468.389130 449.772888,463.905273 
	C452.493835,462.992859 454.058105,464.566772 454.585785,466.382507 
	C456.138397,471.724609 460.039276,472.283447 464.584137,471.970367 
	C465.570709,471.902435 466.546021,471.969208 467.708862,472.761963 
	C470.620667,474.315063 470.082581,477.476440 471.862976,479.683044 
	C473.576233,481.437378 474.820221,483.202393 476.763519,484.718689 
	C477.275085,485.132019 477.545227,485.335602 477.932617,485.767395 
	C479.348267,487.495697 480.282318,489.165527 480.423676,491.538757 
	C476.347198,496.209503 475.688568,489.372284 472.661926,489.786591 
	C471.876007,493.050598 473.808868,496.712585 471.951813,500.112762 
	C471.167847,501.548157 472.827087,501.644012 473.508606,501.798950 
	C475.769073,502.312714 478.076263,502.612305 480.450500,503.925598 
	C480.965729,504.178436 481.167145,504.288605 481.925537,504.798767 
	C483.180969,505.780548 483.815155,506.438049 484.819916,507.197693 
	C485.220917,507.617889 485.362701,507.804779 485.640869,508.318420 
	C485.947876,509.349243 485.925415,510.052826 485.664673,511.092438 
	C484.315613,514.224915 482.850983,516.157776 479.418854,513.920959 
	C478.032593,513.017395 476.250671,513.935547 474.300201,513.898560 
	C470.255371,512.719055 468.420380,509.254303 465.531250,507.101105 
	C464.661194,506.452698 463.805298,505.115112 462.586029,506.060150 
	C461.440247,506.948212 462.116577,508.259888 462.715027,509.216644 
	C464.299683,511.750275 464.371246,514.539490 464.351624,517.715820 
	C464.254303,520.566528 463.773010,522.923096 460.644836,522.982361 
	C457.551636,523.041016 453.948029,525.079468 451.741577,520.881287 
	C451.244080,519.934692 451.160461,517.723267 449.161530,518.733582 
	C447.568329,519.538818 446.973358,521.100647 447.331299,522.992676 
	C447.834900,525.654419 453.250397,531.142029 455.824310,531.560547 
	C458.016937,531.916992 458.576599,530.168457 459.578888,528.888733 
	C461.380371,526.588684 463.356293,524.419373 466.701447,525.847107 
	C468.855133,526.766296 470.203186,528.396423 469.968903,530.923523 
	C469.784363,532.913879 469.280548,534.864258 469.168945,537.210327 
	C470.253235,540.740906 469.935303,544.732544 474.366425,546.344421 
	C475.957794,547.760437 476.199158,549.693848 477.615265,551.313904 
	C477.993896,553.356506 477.433380,554.601257 475.358093,555.098389 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M467.999939,472.582336 
	C466.243073,473.273743 464.356567,474.093567 462.755585,473.712799 
	C459.177032,472.861694 453.989471,474.085052 453.870239,467.763306 
	C453.837952,466.051086 452.166626,465.115509 450.543579,464.042572 
	C447.556366,463.347961 447.185333,460.939117 446.154724,458.622803 
	C445.347290,455.379395 442.364838,454.096039 441.388519,450.965271 
	C439.582520,452.986633 440.478271,454.683441 440.810150,456.363708 
	C442.104980,462.919006 439.385254,467.856445 433.210815,470.235657 
	C431.297150,470.973022 431.297150,470.973053 428.294983,468.048706 
	C428.897186,471.012939 429.040405,473.542480 426.543121,475.132141 
	C424.354919,476.525085 422.560059,478.779358 419.276215,478.224548 
	C419.026794,477.185303 418.723419,476.121979 419.124512,475.579254 
	C422.471893,471.049133 421.955688,466.505249 419.478699,461.859131 
	C418.846100,460.672638 419.297668,459.796997 420.183685,458.805786 
	C421.327148,457.526520 421.941345,457.878113 422.739929,459.044189 
	C423.136475,459.623230 423.984406,459.928955 424.446198,459.387787 
	C428.369415,454.790375 427.525238,460.265656 428.865173,461.692017 
	C429.165680,462.120514 429.331024,462.239777 429.746582,462.183197 
	C432.407501,460.432220 437.108978,461.276154 436.043213,456.030090 
	C435.643982,454.065125 435.813751,451.844788 433.436066,451.183197 
	C431.235291,450.570892 429.126373,450.835205 428.181244,454.571533 
	C426.554504,450.967194 424.566010,448.750732 428.186066,446.116333 
	C431.901733,443.412415 428.646210,442.128082 424.928619,440.503174 
	C429.283020,440.248016 432.218475,439.506989 431.496216,443.900208 
	C430.858734,447.777924 432.702362,447.330780 435.111908,446.260834 
	C435.260345,446.194946 435.375000,446.056946 435.518005,445.974396 
	C438.100159,444.485046 439.790649,440.930145 443.655029,442.403412 
	C447.538574,443.884064 446.114075,447.703094 447.289246,450.368988 
	C449.629547,455.678040 452.797516,460.643127 458.591400,463.058899 
	C459.040131,463.246002 459.470001,463.615234 459.921783,463.637909 
	C467.488708,464.017944 467.503113,464.023346 467.999939,472.582336 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M419.010651,478.397400 
	C423.245697,476.150543 423.245697,476.150543 428.318329,464.971283 
	C430.433624,465.759857 428.672394,470.911072 432.500580,469.266815 
	C438.323975,466.765503 441.357452,461.058685 439.562988,456.647247 
	C438.850067,454.894562 438.347778,453.514069 438.829651,451.570343 
	C439.263458,449.820679 440.006012,448.836426 441.684540,448.773895 
	C443.635559,448.701202 442.830353,450.380402 443.031281,451.337006 
	C443.409485,453.137512 444.448151,454.351166 446.138458,454.872314 
	C447.645844,455.336945 447.979156,456.094330 447.100952,457.727600 
	C445.244415,460.795776 445.338409,463.476990 446.603851,466.520477 
	C448.381989,470.796967 446.239380,474.060425 441.969818,474.279602 
	C442.588593,477.352783 445.418549,481.020752 439.214569,481.951508 
	C437.726135,482.174805 439.703857,484.888885 438.543915,486.622467 
	C438.171539,487.060913 437.978241,487.194458 437.434875,487.356964 
	C433.828064,485.620392 431.351501,485.126678 430.413788,490.022949 
	C430.155365,491.372375 428.112244,492.500244 428.239319,494.739319 
	C427.261719,494.997650 426.515564,495.005310 425.396332,495.015015 
	C424.530945,494.387329 424.149353,493.640747 423.120178,493.141815 
	C421.631805,488.193878 420.324738,483.490662 419.010651,478.397400 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M474.990814,555.372925 
	C476.405121,554.376221 477.043030,553.273804 476.984741,551.399414 
	C476.788971,550.563354 476.916840,550.163086 477.684570,549.820190 
	C478.238281,549.934265 478.434204,550.069763 478.862793,550.473267 
	C481.179016,553.064697 483.110535,554.877502 486.260101,551.806824 
	C487.692566,550.410278 489.298279,551.415833 489.819580,553.858032 
	C490.017883,554.144775 490.027130,554.484863 490.023773,554.654907 
	C490.202393,555.123901 490.416046,555.398132 490.854095,555.824707 
	C491.405243,558.098328 489.609192,559.704895 489.921814,561.929504 
	C490.391296,565.270752 493.624969,568.007507 496.900543,567.019958 
	C499.539307,566.224487 501.158447,566.593872 501.254395,569.799927 
	C499.431396,571.312866 500.921661,572.217712 501.458588,573.232727 
	C502.574036,575.341553 503.080414,577.440552 501.232605,579.783203 
	C500.544159,580.021729 500.104675,580.030762 499.335571,580.041870 
	C496.864594,578.110535 494.690826,576.204956 493.206116,573.269531 
	C492.995636,573.011292 492.998779,573.000000 492.993042,573.002075 
	C491.770630,571.448547 490.009399,570.586304 488.229950,569.229004 
	C484.687347,565.685486 482.133484,561.559937 477.506714,559.647522 
	C475.980225,559.016541 474.903381,557.641968 474.990814,555.372925 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M428.044739,495.324707 
	C428.142609,493.562958 427.529114,492.493988 426.580994,491.454712 
	C425.873962,490.679626 425.685486,489.314117 426.565216,488.779572 
	C428.223572,487.771942 427.662872,490.441650 429.161560,490.481384 
	C430.368591,488.661224 428.715454,485.289215 431.335114,484.334259 
	C433.294861,483.619873 435.539581,484.388214 436.987793,486.764648 
	C436.522949,492.468292 436.522949,492.468292 438.228271,495.424713 
	C435.228668,495.488037 433.418335,498.684570 430.210388,498.781189 
	C428.730865,498.277527 428.458862,496.938812 428.044739,495.324707 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M423.339813,424.610168 
	C424.207275,424.807343 424.778809,425.062469 425.675171,425.658813 
	C424.076355,429.267609 425.604279,432.059906 427.252289,434.808990 
	C427.908447,435.903534 428.322662,437.062561 427.239136,437.959106 
	C425.846313,439.111450 425.392548,437.277100 424.465973,436.880859 
	C420.483551,435.177948 421.233704,429.202301 416.190247,428.077881 
	C417.970398,426.230194 420.523346,425.484894 423.339813,424.610168 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M501.235291,580.152710 
	C501.907837,576.929199 501.041290,574.059875 499.452209,571.532227 
	C498.340698,569.764038 499.503479,570.143311 500.710510,570.005981 
	C501.372314,572.520996 502.870209,571.880554 504.684326,571.086426 
	C505.472168,570.811401 505.870880,570.922607 506.148041,571.674438 
	C505.748993,573.110779 505.378052,574.201050 504.969818,575.636841 
	C504.410553,577.498047 503.786896,578.980957 503.226685,580.822632 
	C502.496582,580.822937 501.991760,580.557861 501.235291,580.152710 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M503.005066,581.096985 
	C502.803345,579.253601 501.659454,577.173889 504.616821,576.029053 
	C508.828735,577.076416 509.765961,579.291382 507.204102,582.931580 
	C506.665466,582.996643 506.438660,582.817383 506.125885,582.416687 
	C505.669495,581.756897 505.224152,581.477234 504.343323,581.485107 
	C503.881317,581.449524 503.697632,581.421326 503.273499,581.252136 
	C503.032990,581.111206 503.008698,581.105957 503.005066,581.096985 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M493.001648,573.383911 
	C495.142761,574.971863 498.169159,576.097229 498.996948,579.681763 
	C498.612762,582.113831 497.472931,582.179871 496.477631,580.659729 
	C495.070831,578.511230 493.053314,576.608093 493.001648,573.383911 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M480.285828,492.157776 
	C479.296936,490.249634 477.963013,488.745056 478.036865,486.356018 
	C479.494568,486.572235 480.939301,487.148895 482.692017,487.862793 
	C483.000000,488.000000 483.001465,488.497345 483.001892,488.746033 
	C483.113800,490.184052 482.613678,491.114777 481.495361,492.075867 
	C481.000641,492.275513 480.783722,492.300842 480.285828,492.157776 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M415.025757,454.282349 
	C414.989502,453.993530 414.997467,453.496796 414.999329,453.248413 
	C415.162079,452.999969 415.324371,452.986023 415.483612,453.002136 
	C417.173828,453.173065 419.121674,452.429108 420.340088,454.258240 
	C420.523346,454.533356 420.399109,455.393890 420.189636,455.490479 
	C418.300262,456.362152 416.603973,455.822449 415.025757,454.282349 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M476.961853,484.592957 
	C473.856171,485.279297 473.184723,482.743073 472.045349,480.352692 
	C474.554047,480.368256 475.948517,482.068207 476.961853,484.592957 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M488.038269,569.352295 
	C490.463531,568.867188 492.146973,569.897217 492.950500,572.650635 
	C490.905975,572.562927 488.847900,572.087158 488.038269,569.352295 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M408.189209,432.909973 
	C408.422607,431.937958 409.126160,431.148071 410.605408,430.869080 
	C410.942474,431.010925 410.956818,431.209045 410.986481,431.704834 
	C410.402924,432.896637 409.501953,432.999634 408.189209,432.909973 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M436.627502,526.553284 
	C437.404663,526.333923 437.684723,526.606812 437.565338,527.209229 
	C437.540466,527.334717 437.363495,527.503906 437.237122,527.522888 
	C436.627808,527.614258 436.373199,527.317322 436.627502,526.553284 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M457.555847,547.371216 
	C457.337585,546.597290 457.608673,546.317444 458.209167,546.436462 
	C458.334015,546.461182 458.502106,546.637878 458.521057,546.763977 
	C458.612122,547.370544 458.317322,547.625122 457.555847,547.371216 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M422.764282,493.085022 
	C423.839874,493.059448 424.696014,493.282898 424.975647,494.686951 
	C424.202728,494.448669 422.860382,494.608459 422.764282,493.085022 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M503.771362,581.266724 
	C504.507355,581.189148 505.141785,581.384521 505.893250,581.906311 
	C505.576843,582.172852 505.143433,582.113037 504.384583,582.013123 
	C503.929413,581.828613 503.799652,581.684204 503.771362,581.266724 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M438.450745,529.529419 
	C438.417908,529.473572 438.483582,529.585205 438.450745,529.529419 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M503.662231,581.018311 
	C503.872894,580.993774 503.988464,581.479248 504.023834,581.726135 
	C503.745209,581.903870 503.483124,581.747681 503.152954,581.307861 
	C503.032990,581.111206 503.451569,581.042786 503.662231,581.018311 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M505.546692,591.471313 
	C505.575317,591.520935 505.518036,591.421753 505.546692,591.471313 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M545.992371,634.278564 
	C546.384766,634.179688 546.677185,634.367310 546.183044,634.917603 
	C546.015808,635.001404 545.993042,634.519653 545.992371,634.278564 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M457.007751,458.000977 
	C456.923431,458.034729 456.832001,458.067047 456.869080,458.042419 
	C456.997559,457.985443 457.000580,457.999481 457.007751,458.000977 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M429.747650,462.006042 
	C429.725830,462.110870 429.454926,462.214417 429.092163,462.159607 
	C429.000336,462.001221 429.498535,462.004791 429.747650,462.006042 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M560.717041,615.795166 
	C560.755615,618.122070 559.166321,618.021667 557.656189,617.981750 
	C551.801208,617.826965 546.009277,613.173096 544.297363,607.235229 
	C541.869995,605.048462 539.130005,603.831421 537.113098,601.264648 
	C536.057190,598.439270 533.681519,596.499146 534.901001,592.918152 
	C528.765076,596.930481 532.730896,605.480469 525.302185,607.855347 
	C523.294556,606.348572 522.031006,604.034912 521.854858,600.561707 
	C521.601929,595.574646 520.188232,590.708740 517.542969,586.080627 
	C516.748169,585.694031 516.531555,585.288208 516.740723,584.442139 
	C514.581665,580.652344 515.190063,577.582947 516.873413,573.735352 
	C518.164490,570.784485 515.359985,567.440796 513.016724,565.105713 
	C511.100128,563.195923 509.772583,562.058411 513.452087,560.876953 
	C515.965698,560.069946 516.128113,556.203003 519.674316,556.055237 
	C521.962341,559.647217 524.040344,563.239380 523.263550,567.618286 
	C523.108521,568.492065 523.108887,569.598206 523.958191,569.780457 
	C528.302246,570.712463 530.409180,574.921570 534.225708,576.584900 
	C542.249268,580.081848 544.428284,583.932678 544.147278,592.552795 
	C543.992493,597.299744 545.491577,601.678284 549.189941,605.059143 
	C552.985107,608.528442 556.701294,612.084229 560.717041,615.795166 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M502.704498,523.587891 
	C506.701263,524.105103 508.900818,526.895569 510.456879,530.134338 
	C511.304352,531.898254 511.973114,533.344360 514.619202,533.000244 
	C515.380310,533.387329 515.526001,533.845276 515.224609,534.685669 
	C512.417847,538.051941 515.287903,541.114807 514.998596,544.587036 
	C512.184814,546.684509 511.329865,544.644348 510.342896,542.260254 
	C509.917633,540.987610 509.738281,540.033752 509.214996,538.828857 
	C507.991119,536.074951 508.310059,533.168640 506.367004,530.668640 
	C505.264404,528.110107 502.400940,527.021179 502.704498,523.587891 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M573.927490,624.631104 
	C564.435486,628.132080 564.435486,628.132080 562.048828,617.389282 
	C565.122070,616.322205 567.142456,618.560425 568.817932,620.228210 
	C570.394043,621.797180 572.244629,622.856873 573.927490,624.631104 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M510.742981,540.739868 
	C512.605408,541.917725 511.277771,545.184143 514.634155,544.990112 
	C515.338257,546.075317 515.676453,547.150696 516.045166,548.564697 
	C514.670837,548.933716 513.265991,548.964111 511.434082,548.986511 
	C510.929871,547.617981 510.032318,547.113098 508.852295,546.820984 
	C505.762207,546.055847 505.875214,544.040161 507.211365,541.062988 
	C508.505402,539.946045 509.479736,539.945007 510.742981,540.739868 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M502.000610,515.000000 
	C501.651978,517.489319 502.543182,519.809448 502.993500,522.593140 
	C503.004883,522.995117 503.000000,523.000000 502.998779,523.001221 
	C502.816711,523.161804 502.635864,523.321106 502.169556,523.701233 
	C500.830658,524.419312 499.722565,524.786926 498.576324,524.926147 
	C496.788544,525.143372 494.662018,525.628357 493.824127,523.478271 
	C493.088806,521.591370 495.096313,521.071167 496.254486,520.293152 
	C498.315002,518.909058 498.783905,517.392517 497.054993,515.392151 
	C496.083588,514.268311 495.140717,513.067627 495.707397,511.070190 
	C499.553101,509.167877 500.370514,512.045288 501.776184,514.674255 
	C502.000000,514.997559 502.000000,515.000000 502.000610,515.000000 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M609.721069,595.877136 
	C608.465210,598.020996 606.872803,600.017944 605.032349,602.194702 
	C603.134216,601.168030 601.447632,599.885254 603.039368,597.597839 
	C604.601624,595.352539 606.743286,594.563843 609.721069,595.877136 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M617.144836,587.854980 
	C617.976501,586.736084 618.685303,585.896118 619.718994,584.992310 
	C621.269409,588.276123 619.665344,590.414978 616.588379,592.064026 
	C615.679016,592.034607 615.164429,592.034424 614.267151,591.983032 
	C614.930298,590.665894 615.976135,589.399902 617.144836,587.854980 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M602.132202,604.516479 
	C601.361023,605.867737 600.321106,606.954773 599.050049,608.206421 
	C598.025085,607.620911 597.176880,606.891846 597.882812,605.290283 
	C598.326172,604.857422 598.591125,604.667908 598.921021,604.183655 
	C599.202087,603.808777 599.361267,603.648987 599.756531,603.247009 
	C600.616211,603.420654 601.239807,603.836487 602.132202,604.516479 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M604.760864,586.189331 
	C605.471008,586.313171 605.939819,586.631165 606.704285,586.973389 
	C606.445801,587.905029 605.946106,589.129395 604.600952,588.417419 
	C603.790527,587.988464 604.085083,587.089111 604.760864,586.189331 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M598.106262,608.626648 
	C597.790710,609.432556 597.160217,610.065125 596.263672,610.753479 
	C596.153259,609.687256 595.817871,608.191284 598.106262,608.626648 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M515.318604,534.996155 
	C515.006042,534.552307 515.004822,534.109436 515.004211,533.334534 
	C515.947693,533.156067 517.061157,533.111816 517.205017,534.385742 
	C517.221130,534.528442 516.180481,534.790466 515.318604,534.996155 
z"
    />
    <path
      fill="#030408"
      opacity="1.000000"
      stroke="none"
      d="
M596.953918,602.577393 
	C596.247986,602.191589 596.241333,601.802734 596.743469,601.458740 
	C596.848755,601.386658 597.093018,601.388916 597.195801,601.463623 
	C597.688293,601.821472 597.668152,602.210022 596.953918,602.577393 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M604.109253,602.627319 
	C604.020142,603.259888 603.580688,603.727600 602.947266,604.340820 
	C602.296448,603.418274 602.052246,602.455811 604.109253,602.627319 
z"
    />
    <path
      fill="#030408"
      opacity="1.000000"
      stroke="none"
      d="
M564.554688,586.371643 
	C564.336121,585.597534 564.607300,585.317993 565.207825,585.437195 
	C565.332764,585.461975 565.500732,585.638489 565.519714,585.764648 
	C565.611206,586.371643 565.315857,586.625488 564.554688,586.371643 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M537.026306,561.216431 
	C537.337830,561.103271 537.594177,561.293762 537.913574,561.769653 
	C537.203491,562.729858 537.122742,562.073120 537.026306,561.216431 
z"
    />
    <path
      fill="#030408"
      opacity="1.000000"
      stroke="none"
      d="
M597.984741,604.999268 
	C597.862793,604.867249 597.754456,604.731323 597.646057,604.595459 
	C597.726318,604.638062 597.806519,604.680664 597.945496,604.845276 
	C598.004211,604.967285 597.998291,604.995483 597.984741,604.999268 
z"
    />
    <path
      fill="#030408"
      opacity="1.000000"
      stroke="none"
      d="
M599.784912,603.112183 
	C599.890991,603.310181 599.712341,603.560669 599.249878,603.862427 
	C599.137024,603.658997 599.315125,603.409302 599.784912,603.112183 
z"
    />
    <path
      fill="#05070B"
      opacity="1.000000"
      stroke="none"
      d="
M394.705231,445.796814 
	C396.953644,442.889832 399.591400,440.028046 402.545471,437.057312 
	C404.129883,437.764069 406.251831,437.866516 405.367645,440.372223 
	C404.847534,441.846100 404.040710,443.896545 402.703400,443.440582 
	C399.330261,442.290527 397.808289,445.743347 394.705231,445.796814 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M404.286499,435.971069 
	C404.834045,434.757446 405.731964,433.520172 407.557068,432.885406 
	C407.955719,435.049713 406.751404,435.965240 404.286499,435.971069 
z"
    />
    <path
      fill="#05070B"
      opacity="1.000000"
      stroke="none"
      d="
M404.626221,464.817383 
	C406.591492,464.607941 405.890411,465.932983 405.746094,466.885071 
	C405.715393,467.087677 405.039612,467.192474 404.661072,467.342346 
	C404.627686,466.590210 404.594299,465.838043 404.626221,464.817383 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M490.038208,553.754028 
	C485.834381,549.303284 486.354584,554.499573 485.086395,557.324829 
	C483.945038,553.228210 478.952271,555.389954 478.173950,551.059448 
	C478.832092,549.513306 480.135803,549.661926 481.375244,549.073608 
	C481.621246,548.951538 482.006439,548.699402 481.849030,548.510742 
	C480.663361,547.656616 479.472412,547.685486 478.003662,547.449219 
	C475.042267,545.880066 473.496735,543.444458 471.960602,540.953369 
	C471.198334,539.717285 470.998901,537.606934 468.329437,538.087646 
	C468.083771,535.854065 467.761536,533.690002 468.644867,531.613098 
	C469.687164,529.162415 468.021606,527.995422 466.286774,526.853882 
	C464.383484,525.601501 463.191864,526.873047 462.007141,528.026794 
	C461.069275,528.940125 460.278473,529.901550 459.892395,531.290833 
	C459.297852,533.430298 457.259644,533.830322 455.499695,533.479126 
	C452.135590,532.807922 445.309662,524.678589 445.339630,521.346802 
	C445.365479,518.479187 448.210938,516.920837 449.972595,515.731323 
	C451.652313,514.597168 451.524445,517.798889 452.229248,519.106079 
	C452.973053,520.485535 454.773224,523.069092 455.169830,522.479187 
	C457.185577,519.481079 463.127228,525.168579 463.130005,518.325500 
	C463.512085,517.630859 463.905579,517.459229 464.680481,517.510010 
	C465.942993,518.091858 466.594879,518.897156 467.307007,519.983521 
	C467.564484,520.329224 467.203644,521.241272 468.054108,520.278442 
	C468.833313,519.463440 469.589813,519.150085 470.729431,518.872375 
	C472.683533,518.641479 474.206268,519.025391 475.713837,519.581909 
	C477.234070,520.143188 478.791046,520.698547 480.081024,518.545044 
	C480.443207,518.116821 480.605438,517.962646 481.079102,517.691406 
	C482.492462,517.449158 483.408813,518.245117 484.807068,518.206238 
	C485.751709,518.251526 486.347076,518.405029 487.064423,519.017578 
	C487.393616,519.643921 487.478119,520.041260 487.544312,520.749268 
	C487.654541,521.455627 487.733673,521.861755 487.878540,522.588745 
	C488.443909,524.884399 486.786346,526.260742 486.608795,528.346802 
	C486.850708,529.328735 486.924042,529.996704 486.856995,531.015503 
	C486.733521,531.575073 486.657593,531.793701 486.394958,532.307800 
	C485.890564,532.944519 485.531952,533.234070 484.798187,533.567505 
	C482.169952,533.734619 479.737640,532.384460 477.278503,534.386353 
	C476.724792,534.445740 476.500183,534.425842 475.978882,534.246704 
	C475.009552,533.476196 474.819275,532.185303 473.316772,532.567932 
	C474.052521,533.086670 474.905945,533.366455 475.423706,534.464294 
	C476.913971,536.735291 477.877563,538.962646 479.807922,540.916504 
	C480.409515,541.602661 480.735718,542.105042 481.226105,542.823914 
	C481.716675,543.150574 481.791992,544.195557 482.613525,543.130981 
	C484.492981,541.963745 486.105164,542.222534 487.969360,543.302612 
	C489.082977,544.216003 489.783752,545.060242 490.463409,546.326660 
	C490.808258,547.921936 490.548828,549.190369 490.264282,550.748047 
	C490.098602,551.809082 490.078583,552.586182 490.038208,553.754028 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M486.042908,532.372742 
	C486.012573,531.270081 486.003571,530.503906 485.993988,529.354492 
	C486.179596,528.855286 486.365753,528.739197 486.881500,528.601929 
	C487.868164,528.678650 488.493286,528.895325 489.442261,529.186279 
	C492.991547,530.127747 496.167297,531.248047 499.900574,531.269470 
	C500.608429,531.370178 501.164398,531.608459 501.326080,531.303772 
	C501.630157,530.730835 501.522888,531.398987 500.751190,530.877441 
	C500.551819,529.565063 500.921356,528.723206 501.836914,527.761230 
	C504.117493,527.017273 505.244934,528.385315 506.726807,529.779541 
	C509.447449,531.918396 509.129150,534.846497 509.879089,537.710938 
	C509.845734,539.530640 508.630981,540.030396 507.227844,540.783325 
	C506.972900,540.971802 506.982666,540.987305 506.974548,540.975708 
	C503.896027,541.965698 503.548370,544.709290 503.051666,547.272278 
	C502.767120,548.740417 502.927155,550.580994 500.885620,550.541504 
	C499.189636,550.508667 499.072937,548.872009 499.040344,547.529236 
	C499.002167,545.956299 498.356812,544.653442 497.154358,543.223389 
	C496.006256,544.089050 495.075012,545.102051 494.244507,546.612427 
	C493.598755,546.936890 493.143372,546.976440 492.119812,546.800781 
	C491.090240,544.250061 490.475281,541.962097 489.329132,539.492981 
	C489.141449,538.973022 489.072540,538.762085 488.912964,538.230164 
	C488.856110,535.670288 486.423309,534.739685 486.042908,532.372742 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M536.726318,601.101868 
	C539.721436,602.222351 542.597046,603.270508 543.985779,606.644958 
	C541.941711,606.671387 539.838318,606.390137 537.750305,606.021851 
	C536.039246,605.719971 533.394531,606.954224 532.785339,605.157288 
	C531.989380,602.809082 535.401245,602.828552 536.726318,601.101868 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M506.446167,571.973328 
	C505.896271,571.752136 505.672607,571.483887 505.221069,571.084351 
	C506.202087,567.041687 508.322784,570.469116 510.130951,570.647278 
	C509.011444,571.073608 507.891937,571.499939 506.446167,571.973328 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M490.456543,550.943237 
	C489.438721,549.916992 488.829651,548.794861 489.876831,547.220764 
	C490.374603,546.599976 490.816010,546.449219 491.644592,546.632141 
	C491.972046,546.790955 491.989044,546.926941 491.996521,546.995728 
	C491.826599,548.399902 492.515106,550.011658 490.456543,550.943237 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M494.371490,546.786499 
	C493.887878,545.144226 494.239777,543.685608 496.522339,543.075439 
	C497.378876,544.971985 496.246582,545.949585 494.371490,546.786499 
z"
    />
    <path
      fill="#3B4552"
      opacity="1.000000"
      stroke="none"
      d="
M511.010376,548.996826 
	C511.172485,550.294800 510.782898,551.325928 509.394531,551.586060 
	C508.706787,551.714966 508.055115,551.288818 508.116119,550.489624 
	C508.216217,549.178101 509.312897,549.142639 510.647034,548.994995 
	C511.006958,548.978455 510.999359,549.000671 511.010376,548.996826 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M516.631653,584.119629 
	C516.980286,584.538086 517.023926,584.951599 517.130981,585.671265 
	C516.439087,586.958923 515.820740,586.462463 515.420288,585.702698 
	C514.960510,584.830566 515.234070,584.193420 516.631653,584.119629 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M510.747925,566.997009 
	C510.584229,567.104065 510.419952,567.051392 510.255676,566.998657 
	C510.419556,566.944885 510.583466,566.891113 510.747925,566.997009 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M490.931366,555.761475 
	C490.759796,555.786011 490.472687,555.570435 490.103027,555.089905 
	C490.285553,555.057129 490.550690,555.289246 490.931366,555.761475 
z"
    />
    <path
      fill="#2A404C"
      opacity="1.000000"
      stroke="none"
      d="
M547.997559,622.002136 
	C547.859436,621.849670 547.723694,621.695068 547.587952,621.540466 
	C547.692261,621.586914 547.796570,621.633362 547.951660,621.837402 
	C548.002441,621.995117 548.000000,622.000000 547.997559,622.002136 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M443.307678,678.107666 
	C442.969666,675.863708 438.736420,674.827515 441.297241,672.690918 
	C443.759552,670.636536 445.059357,674.194702 446.949524,675.086060 
	C447.987030,675.575439 448.090271,676.569336 447.253662,677.786682 
	C445.950806,678.749939 444.774750,678.472107 443.307678,678.107666 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M492.665955,660.380493 
	C486.336914,660.750671 479.648743,659.980957 473.055481,658.358704 
	C479.524078,657.974426 485.851288,659.217224 492.665955,660.380493 
z"
    />
    <path
      fill="#343232"
      opacity="1.000000"
      stroke="none"
      d="
M522.817566,660.836304 
	C521.182434,662.803162 518.573425,661.426880 516.240906,662.711121 
	C514.304810,661.250183 512.133057,662.549011 510.239868,661.257874 
	C511.546234,659.435852 513.570557,660.254456 515.700439,659.825562 
	C516.233215,659.604004 516.428955,659.477051 516.826233,659.064087 
	C517.159790,658.467529 517.388977,658.269409 517.948730,658.166626 
	C518.284363,658.323242 518.386536,658.496948 518.242188,658.845337 
	C519.153748,660.661926 521.267578,659.429749 522.817566,660.836304 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M493.568665,660.818726 
	C493.113159,660.784851 493.065582,660.443970 493.066528,660.272339 
	C497.731842,660.079529 502.396179,660.058411 507.504578,659.996582 
	C503.423035,662.054077 498.657074,660.420532 493.568665,660.818726 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M454.512665,672.164673 
	C452.917847,673.125916 452.204773,673.028748 451.715393,672.061829 
	C451.417114,671.472473 451.075592,670.769714 451.856232,670.395203 
	C453.321259,669.692383 453.831879,670.874939 454.512665,672.164673 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M516.592041,658.853149 
	C517.011841,658.966064 516.995911,659.154236 516.993896,659.625366 
	C515.045105,661.025635 512.919556,660.294678 510.450745,660.289551 
	C509.569550,660.052063 509.109497,659.973511 508.299011,659.925415 
	C507.948608,659.955811 507.976349,659.978699 507.983643,659.992920 
	C510.533264,658.288879 513.444397,659.362183 516.592041,658.853149 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M473.523132,662.142090 
	C474.609039,662.559204 475.298096,662.900085 475.987122,663.240967 
	C475.934418,663.436401 475.881744,663.631775 475.829071,663.827148 
	C473.701355,663.478516 471.573639,663.129883 469.114594,662.726929 
	C470.517944,661.254761 471.910278,662.271729 473.523132,662.142090 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M518.393555,658.988647 
	C517.995544,659.020142 518.026306,658.571228 518.104248,658.360413 
	C519.844971,658.091553 521.507812,658.033569 523.583313,657.905151 
	C522.738831,660.423340 520.453308,658.244263 518.393555,658.988647 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M510.022339,660.347351 
	C512.065063,660.083435 514.100586,660.036194 516.572021,659.948669 
	C515.236816,661.870850 512.897644,660.646790 510.420502,661.002686 
	C510.000000,660.997559 510.015076,660.564087 510.022339,660.347351 
z"
    />
    <path
      fill="#3D3C3C"
      opacity="1.000000"
      stroke="none"
      d="
M442.973633,678.134155 
	C444.060150,677.869812 445.129608,677.933350 446.600739,677.998413 
	C445.731445,678.834045 444.560120,680.540894 442.973633,678.134155 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M466.194946,668.270874 
	C466.868347,667.217041 467.523926,667.070740 468.147827,667.933838 
	C468.291290,668.132324 468.383301,668.640442 468.320862,668.673889 
	C467.597870,669.061584 466.849457,669.215881 466.194946,668.270874 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M466.858276,662.026001 
	C465.887085,661.907410 465.269989,661.753540 464.652893,661.599731 
	C465.543701,660.636536 466.534515,660.835449 467.542877,661.365845 
	C467.595337,661.393372 467.618500,661.598633 467.573090,661.664246 
	C467.483307,661.794128 467.335907,661.884216 466.858276,662.026001 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M486.403015,345.620941 
	C489.312378,346.399261 489.294159,348.192047 488.342194,350.161377 
	C487.547974,351.804443 486.881989,353.372131 488.012817,355.473053 
	C487.459442,356.900848 486.287628,356.325348 485.136444,356.903564 
	C481.294281,357.844025 477.604218,357.213531 473.840240,358.260559 
	C473.604248,358.324554 473.390747,358.668915 473.583313,358.790527 
	C477.283569,356.754364 481.412933,358.189178 485.380310,356.767822 
	C490.518036,357.729828 495.358673,358.264435 500.558838,356.471069 
	C501.676971,356.065216 502.468933,356.039154 503.668396,356.024231 
	C506.010406,356.109375 507.877411,356.459259 509.821472,357.802124 
	C510.132599,361.196381 508.398621,362.091736 505.615082,362.015930 
	C494.367249,361.709442 483.139374,362.216339 471.460480,362.990356 
	C470.151459,362.988586 469.303040,362.984436 468.130554,362.858582 
	C466.423035,362.295166 465.185242,362.742432 463.684998,363.517487 
	C462.452240,364.041901 461.514893,364.440338 460.281860,364.899841 
	C459.248932,364.986877 458.501160,364.987152 457.379730,364.996918 
	C455.896912,364.947113 454.410278,365.144287 454.231537,363.721893 
	C453.842590,360.626709 451.543671,361.081207 449.117249,360.770325 
	C460.394012,353.787811 472.941223,348.994293 486.403015,345.620941 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M418.572815,397.117493 
	C417.661652,396.777679 417.093048,396.493469 416.229797,396.086548 
	C425.748352,386.180420 437.293030,379.069733 450.662903,374.055603 
	C451.072693,376.769592 448.838593,377.205719 446.921417,378.407715 
	C446.070923,378.856873 445.493164,379.127594 444.686096,379.635071 
	C443.901123,380.167175 443.359833,380.495178 442.548828,380.995056 
	C440.378326,382.146790 438.413635,383.021484 436.556091,384.706665 
	C430.154785,388.508087 425.136353,393.693817 418.572815,397.117493 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M427.019470,375.236938 
	C431.187531,370.896027 436.126831,367.897217 441.362793,364.752991 
	C438.285645,369.979279 434.273956,374.615387 427.953735,376.553284 
	C427.775635,376.607880 427.367676,375.912872 427.019470,375.236938 
z"
    />
    <path
      fill="#D1D0D0"
      opacity="1.000000"
      stroke="none"
      d="
M456.220032,371.503693 
	C457.507812,371.026764 458.622101,371.016174 459.675598,370.123627 
	C460.137207,370.375366 460.281250,370.587219 460.413635,371.184021 
	C458.496796,374.561371 455.476929,374.913635 451.968231,374.857971 
	C451.468201,374.578644 451.334351,374.416656 450.999023,374.012329 
	C452.292267,372.524139 454.149597,372.201691 456.220032,371.503693 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M444.603821,368.056976 
	C445.905457,368.133789 446.652222,368.479889 445.363007,369.233948 
	C443.625305,370.250275 441.759064,371.046814 438.780457,372.512054 
	C440.385468,369.096130 442.557129,368.897766 444.603821,368.056976 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M442.087158,364.757233 
	C443.810211,363.392456 445.924805,362.117554 448.368591,360.750732 
	C448.176117,364.358429 445.214905,364.435669 442.087158,364.757233 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M449.961792,367.848267 
	C448.308929,367.656769 448.608826,367.021301 449.391693,366.421295 
	C449.916290,366.019226 450.725464,365.816284 451.109497,366.442291 
	C451.544464,367.151276 450.778503,367.486145 449.961792,367.848267 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M503.092438,342.066040 
	C500.961029,345.467651 497.612427,345.321472 494.055054,344.253479 
	C496.758392,342.498688 499.844666,342.571106 503.092438,342.066040 
z"
    />
    <path
      fill="#10100F"
      opacity="1.000000"
      stroke="none"
      d="
M418.159058,397.282043 
	C423.644379,392.444275 429.077942,387.851868 435.446228,383.965912 
	C437.998016,383.213226 440.614838,383.257050 441.875336,380.299591 
	C442.453400,379.671631 442.907562,379.346954 443.644592,378.993073 
	C444.203308,378.728516 444.513885,378.648712 445.101959,378.846130 
	C445.630524,379.105011 445.943420,379.211914 446.526611,379.243805 
	C446.944244,379.198059 447.112000,379.167419 447.531677,379.071594 
	C448.127472,378.905457 448.468414,378.795349 449.071014,378.565247 
	C449.514709,378.370575 449.694397,378.288757 450.148041,378.077087 
	C450.801880,377.766296 451.183777,377.589691 451.876160,377.276733 
	C452.402771,377.053436 452.620667,376.970459 453.181519,376.787964 
	C459.928406,374.469238 466.293121,372.127472 473.268372,370.363617 
	C485.766968,367.702423 497.980560,366.798157 510.700348,368.017853 
	C512.401062,367.923004 513.632385,368.930603 515.311462,368.247559 
	C518.557678,367.657379 521.326660,369.982391 524.594849,368.459656 
	C526.619263,366.588135 528.301941,366.039673 529.884888,368.881775 
	C529.606018,371.016174 531.128967,370.517181 531.841797,370.643951 
	C543.439026,372.705902 554.541992,376.410919 565.780945,380.821045 
	C566.065857,381.014862 565.999878,381.006012 565.984619,380.976440 
	C566.833679,381.643524 567.761292,382.233551 569.058228,382.859497 
	C569.351562,383.321106 569.391602,383.551117 569.308472,384.115021 
	C568.633911,385.274536 567.934631,385.941956 566.701294,386.534149 
	C559.333557,384.924408 552.987549,381.291199 545.905884,379.726257 
	C543.702820,379.239410 541.334900,379.165497 538.887207,377.963196 
	C524.384949,374.599792 510.141754,373.430695 495.634430,374.338959 
	C471.323486,375.861023 449.285156,383.604156 429.201111,397.255951 
	C425.327026,399.889313 422.065460,403.203186 418.475006,406.147064 
	C416.808655,407.513306 415.304108,409.111206 412.635010,409.557495 
	C411.345428,409.497162 410.596558,409.133820 409.708435,408.206512 
	C410.342407,406.626434 411.209320,405.402344 412.353027,404.014282 
	C414.780914,402.530182 416.530731,400.837708 417.785278,398.228455 
	C417.980347,397.952271 418.087860,397.502197 418.159058,397.282043 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M409.228668,408.056396 
	C410.266754,408.220947 411.057983,408.591339 412.088440,409.338684 
	C410.755951,414.631927 407.472443,418.083069 402.451355,420.211182 
	C403.229340,415.382263 406.254700,411.913849 409.228668,408.056396 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M395.586121,434.853180 
	C396.275238,435.784027 396.606354,436.657806 396.989746,437.880219 
	C393.861877,442.329437 390.681793,446.429993 387.222107,450.243866 
	C386.961578,449.551178 386.980652,449.145203 387.028107,448.426758 
	C389.802124,443.688232 390.980347,438.300476 395.586121,434.853180 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M396.014099,434.612549 
	C393.158752,439.171173 390.373413,443.432098 387.322266,447.903656 
	C387.371124,442.841309 390.699463,438.860107 393.187714,434.213531 
	C394.235565,433.733490 394.697845,432.900452 395.929962,432.951416 
	C396.223602,433.543610 396.153839,433.929230 396.014099,434.612549 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M417.668457,398.072418 
	C418.094727,401.206940 416.295685,402.973999 413.004089,403.860962 
	C414.205444,401.964447 415.780975,400.078522 417.668457,398.072418 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M396.252625,433.125732 
	C395.604523,433.424927 394.915680,433.691833 393.835571,433.988464 
	C394.000916,433.060211 394.557556,432.102203 395.352386,430.909149 
	C395.809998,430.687347 396.029419,430.700592 396.412292,431.000336 
	C396.662140,431.725586 396.628204,432.154785 396.292969,432.849274 
	C396.112610,433.124207 396.211914,433.093506 396.252625,433.125732 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M399.402008,423.914124 
	C399.616394,423.088135 399.813965,422.667938 400.197571,422.108459 
	C400.612549,421.851044 400.841461,421.732880 401.453400,421.672852 
	C402.698212,422.971924 401.817017,423.610962 400.573608,424.363342 
	C399.917694,424.418091 399.651428,424.368988 399.402008,423.914124 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M402.108185,421.815674 
	C401.622009,421.928894 401.374146,421.914185 400.754974,421.934326 
	C400.682007,421.376831 400.980347,420.784454 401.566711,420.028381 
	C401.998108,420.001953 402.141541,420.139252 402.499878,420.482727 
	C402.676727,421.053040 402.549500,421.384369 402.108185,421.815674 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M396.802063,429.026978 
	C396.807465,428.226990 397.054962,427.679474 397.823730,427.242859 
	C398.461029,427.816925 398.437836,428.270844 397.995850,428.950378 
	C397.506470,429.217651 397.275330,429.248535 396.802063,429.026978 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M395.726837,430.781433 
	C395.768951,430.153076 395.947327,429.632050 396.342834,428.942749 
	C396.559937,428.774475 396.901550,428.928375 397.020569,429.073059 
	C397.566437,429.924103 397.501465,430.554596 396.491638,431.100525 
	C396.133209,431.106415 395.863098,430.888733 395.726837,430.781433 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M397.997986,426.990356 
	C397.995300,426.292175 398.147583,425.719116 398.820190,425.176819 
	C399.598114,425.878510 399.505157,426.493958 398.756226,427.208191 
	C398.463776,427.364471 398.152924,427.115448 397.997986,426.990356 
z"
    />
    <path
      fill="#302E37"
      opacity="1.000000"
      stroke="none"
      d="
M386.639862,450.041412 
	C388.167023,450.966095 386.855103,451.391815 386.005188,452.040741 
	C385.808716,451.412354 386.062531,450.780945 386.639862,450.041412 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M398.968933,424.964783 
	C398.890442,424.499573 398.976898,424.180328 399.241119,423.684723 
	C399.552399,423.619293 399.685944,423.730194 400.011292,424.017181 
	C400.250244,424.553345 400.150146,424.870239 399.693970,425.275452 
	C399.465240,425.401062 399.133881,425.110718 398.968933,424.964783 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M600.662354,618.041748 
	C602.209717,620.187805 600.087524,621.055054 599.045105,622.099915 
	C594.964172,626.190002 590.725891,630.126526 585.637512,633.595093 
	C585.110352,633.481323 584.894409,633.196411 584.821533,632.394775 
	C588.548157,628.787109 592.131653,625.696167 595.872559,622.309631 
	C597.469849,620.719482 598.909790,619.424927 600.662354,618.041748 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M584.640625,631.993530 
	C585.021057,632.193176 585.077454,632.508362 585.230103,633.294861 
	C579.830200,637.574402 574.520325,641.669189 568.243896,644.364685 
	C572.806702,639.219421 579.224609,636.572266 584.640625,631.993530 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M567.687134,643.930359 
	C565.549316,648.417725 560.564880,648.898865 556.319702,651.072632 
	C556.034546,648.789001 558.367859,649.270813 559.672729,647.981079 
	C562.428162,646.497375 564.879150,645.185913 567.687134,643.930359 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M496.120117,338.037842 
	C495.104523,337.536255 492.936157,339.263336 492.966553,336.656097 
	C492.978607,335.624176 493.553162,334.301331 495.150696,334.704163 
	C496.743652,335.105835 497.420349,335.967468 496.146729,337.814026 
	C496.067932,338.132935 496.066498,338.019714 496.120117,338.037842 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M532.292114,656.037231 
	C532.735718,655.677612 533.518921,655.341492 534.630615,655.047607 
	C534.470764,656.102600 533.515686,655.996277 532.292114,656.037231 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M530.718628,655.943542 
	C531.178772,655.900208 531.348816,655.922791 531.775879,655.956543 
	C531.706726,657.289673 530.682373,657.265320 529.862854,656.782654 
	C529.379272,656.497864 530.056335,656.164551 530.718628,655.943542 
z"
    />
    <path
      fill="#030408"
      opacity="1.000000"
      stroke="none"
      d="
M378.469543,494.008484 
	C378.579346,494.510498 378.378326,495.019379 378.013214,495.761841 
	C377.375122,495.689880 376.515533,495.404785 376.498077,495.075043 
	C376.441498,494.008301 377.433075,494.128113 378.469543,494.008484 
z"
    />
    <path
      fill="#FAFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M447.407349,353.608215 
	C447.432953,353.346649 447.632263,353.299896 447.831573,353.253113 
	C447.748047,353.443085 447.664520,353.633057 447.407349,353.608215 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M616.080078,592.368042 
	C617.950012,590.157227 619.196106,587.956726 620.145020,585.280273 
	C620.043884,584.928406 620.024536,585.000305 619.988342,584.991577 
	C620.231567,584.803406 620.474548,584.583679 620.840820,584.135986 
	C621.000732,583.948364 621.010132,584.007629 620.980225,584.009399 
	C621.433044,583.595581 621.499756,582.992981 621.827576,582.188721 
	C621.995056,581.945007 622.005249,582.004028 621.975342,582.003296 
	C622.204346,581.789490 622.450867,581.562805 622.851929,581.134766 
	C623.019287,580.947205 623.023682,581.019409 622.988037,581.025513 
	C623.414124,580.607361 623.500183,580.015503 623.837402,579.223206 
	C624.341370,578.947266 624.663696,578.902710 624.985962,578.858154 
	C624.339661,574.724854 630.495483,573.729980 629.373840,569.296570 
	C629.107544,568.244202 629.396912,568.171021 632.828857,567.095764 
	C633.411194,567.521179 633.484436,567.977356 633.186523,568.749512 
	C632.976624,569.039429 632.965759,568.976990 632.995789,568.966797 
	C632.268433,569.666138 632.593750,570.650208 632.183472,571.772949 
	C631.985718,572.047974 631.972412,571.983582 632.003601,571.973206 
	C631.614075,572.380798 631.486877,572.903992 631.490356,573.753174 
	C631.483093,574.217285 631.478088,574.403076 631.443420,574.866821 
	C631.330261,575.508545 631.216187,575.862427 630.966797,576.463745 
	C630.789734,576.890930 630.717468,577.060852 630.540771,577.486328 
	C630.305420,578.086731 630.177979,578.432800 629.967407,579.046021 
	C629.811462,579.486084 629.741638,579.659912 629.540527,580.082764 
	C629.306519,580.489441 629.203857,580.647034 628.780823,581.271118 
	C628.259338,582.062927 628.064270,582.391846 627.738037,582.987549 
	C627.502136,583.425659 627.402771,583.600342 627.142090,584.033325 
	C626.080994,585.674133 624.892883,586.879211 624.211060,588.762146 
	C624.032349,589.018860 624.002441,589.000916 624.013916,589.014038 
	C620.904114,592.757019 619.529663,597.736206 615.215698,600.918701 
	C614.000671,600.636719 613.131836,600.296631 611.913086,599.914062 
	C613.031006,597.481567 614.498718,595.091492 616.080078,592.368042 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M614.893066,601.246277 
	C617.686523,596.916809 620.503479,592.856812 623.672913,588.911987 
	C622.274414,594.075989 619.693604,598.603699 615.370361,602.151489 
	C614.986328,601.869446 614.951416,601.692566 614.893066,601.246277 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M610.437256,608.968994 
	C613.212402,612.320862 610.952698,612.698425 607.896606,612.705444 
	C607.393372,612.190857 607.421326,611.797302 607.778320,611.186646 
	C607.984680,610.962891 608.004700,610.998352 607.984802,610.994263 
	C608.275757,610.699463 608.606506,610.433350 609.255371,610.083618 
	C609.556580,609.979126 609.789490,609.636841 609.884033,609.590454 
	C610.034973,609.358948 610.091370,609.173828 610.437256,608.968994 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M607.639038,610.844666 
	C607.914795,611.333740 607.844910,611.704529 607.641479,612.333740 
	C607.321472,612.885376 607.135132,613.178589 607.004150,613.726562 
	C606.281067,614.658447 605.650208,615.828735 604.435120,614.373474 
	C605.097046,612.585022 605.983582,611.473450 607.639038,610.844666 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M610.896790,608.656433 
	C610.570679,609.008545 610.414429,609.067871 610.118408,609.335693 
	C608.937073,607.853027 610.429382,607.127380 611.662231,606.067627 
	C612.330200,607.026611 611.526733,607.623962 610.896790,608.656433 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M614.715698,602.076904 
	C615.768677,603.507019 614.396423,604.036682 613.363770,604.963318 
	C612.622986,603.678040 613.599854,602.960693 614.715698,602.076904 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M609.155090,609.787476 
	C609.243103,610.285645 608.929626,610.592163 608.290527,610.944458 
	C607.876221,610.326538 608.140747,609.862671 609.155090,609.787476 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M612.732300,604.974670 
	C612.907837,605.376526 612.770081,605.736816 612.328735,606.069946 
	C612.156433,605.672852 612.287720,605.302917 612.732300,604.974670 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M601.713867,616.982178 
	C602.432861,617.527161 602.340881,618.020264 601.341492,618.212402 
	C601.104370,617.673279 601.267151,617.347351 601.713867,616.982178 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M602.708496,615.961853 
	C603.316711,616.504700 603.210632,616.974487 602.318115,617.175293 
	C602.099792,616.658691 602.256104,616.319946 602.708496,615.961853 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M603.728638,614.638062 
	C604.576965,614.987610 604.551758,615.509766 603.427979,616.009644 
	C603.115417,615.627930 603.287842,615.235474 603.728638,614.638062 
z"
    />
    <path
      fill="#FAFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M510.826477,321.514099 
	C511.627472,321.097534 512.283142,320.975037 513.272583,320.851074 
	C514.491150,320.528442 515.417847,320.506561 516.654419,320.315979 
	C518.939209,320.035004 520.911499,319.904022 523.302368,319.832886 
	C526.452820,320.065887 529.769592,317.540070 531.571167,322.067596 
	C531.182739,323.124420 530.694275,323.431458 529.564514,323.362793 
	C524.042358,320.009521 519.074158,323.754425 514.049377,323.494385 
	C512.673157,323.423187 510.149597,325.679871 510.826477,321.514099 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M524.665527,324.101959 
	C526.755859,325.580780 527.511108,327.307678 526.020386,329.205353 
	C525.122681,330.348083 523.597656,330.159149 522.279419,329.848541 
	C519.385376,329.166626 523.296143,325.872437 520.165466,325.019989 
	C521.205017,324.066772 522.759827,324.130310 524.665527,324.101959 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M529.071960,323.455475 
	C529.539307,323.292938 529.970032,323.303925 530.701355,323.182922 
	C533.232849,320.849945 535.689331,321.860016 538.557129,322.739075 
	C539.166077,323.004791 539.341309,323.081909 539.782349,323.267792 
	C536.755737,326.058807 533.176453,324.833649 529.267822,324.048859 
	C528.899414,323.956757 529.035339,323.629028 529.071960,323.455475 
z"
    />
    <path
      fill="#F1F6F7"
      opacity="1.000000"
      stroke="none"
      d="
M538.844971,322.700012 
	C536.618591,323.029602 534.246338,323.131592 531.438049,323.142212 
	C530.816711,318.020111 526.872070,320.755402 524.176880,319.930206 
	C528.366760,318.225372 533.210754,319.545074 538.375610,318.903107 
	C538.823608,318.856293 539.299438,318.983459 539.545288,318.997253 
	C540.028870,319.279388 540.266663,319.547791 540.745239,319.944458 
	C541.325806,321.922943 537.199585,319.516968 538.844971,322.700012 
z"
    />
    <path
      fill="#F1F6F7"
      opacity="1.000000"
      stroke="none"
      d="
M516.615417,320.113525 
	C516.253479,320.981598 515.308167,321.001678 513.971619,320.890961 
	C514.343201,320.084534 515.305054,320.077911 516.615417,320.113525 
z"
    />
    <path
      fill="#FAFBFB"
      opacity="1.000000"
      stroke="none"
      d="
M535.058716,314.208130 
	C535.568970,314.017303 536.023376,314.163635 536.716858,314.341614 
	C536.718811,314.922241 536.586426,315.685577 535.797485,315.531891 
	C535.480591,315.470184 535.264709,314.889648 535.058716,314.208130 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M530.634460,683.093811 
	C529.112366,682.946472 527.955872,682.929871 526.830750,682.733704 
	C526.197144,682.623230 525.336914,681.968201 525.744446,681.422546 
	C527.495361,679.078003 530.266602,681.247375 532.451355,680.114685 
	C533.327576,679.660278 535.009827,680.049927 534.761169,681.733582 
	C534.398132,684.191833 532.687317,683.783081 530.634460,683.093811 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M519.253052,675.109131 
	C518.959717,674.790588 518.996826,674.618103 519.161621,674.213745 
	C520.638000,670.775818 523.402344,670.855042 526.601685,671.008789 
	C524.776245,672.854065 523.496216,676.357605 519.253052,675.109131 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M532.887756,671.204590 
	C533.967346,668.032654 536.499695,668.820374 538.788452,668.799072 
	C538.314941,672.363586 533.524780,670.085632 532.931519,673.515686 
	C531.874451,672.916992 532.520813,672.190308 532.887756,671.204590 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M596.730835,653.423828 
	C596.573120,653.783081 596.109985,653.748413 596.109985,653.748413 
	C596.109985,653.748413 595.656250,653.847351 595.426270,653.878784 
	C594.766602,652.500977 593.132629,650.504150 595.812988,650.109192 
	C597.679504,649.834167 597.191467,651.795471 596.730835,653.423828 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M624.331543,588.990295 
	C623.231567,586.454468 625.518921,585.105530 626.675659,583.082031 
	C628.058533,584.242676 627.978577,585.416199 626.447510,586.757141 
	C626.131348,587.007019 625.902039,587.270203 625.851562,587.436035 
	C625.411011,588.055237 625.020935,588.508484 624.331543,588.990295 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M553.304810,662.135315 
	C553.529541,661.114075 554.403625,661.016296 555.641357,660.974060 
	C555.888733,662.706970 555.050964,663.057800 553.304810,662.135315 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M556.210327,660.004150 
	C555.206543,659.406921 555.453491,658.873779 556.185913,658.638489 
	C556.805664,658.439514 556.896484,659.041565 556.964233,659.765625 
	C556.800720,660.013123 556.632019,660.010437 556.210327,660.004150 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M559.446899,660.009766 
	C559.422974,658.697266 560.523621,658.998962 561.727051,658.907959 
	C561.702942,660.186035 560.786987,660.204346 559.446899,660.009766 
z"
    />
    <path
      fill="#B0AEAE"
      opacity="1.000000"
      stroke="none"
      d="
M562.267639,659.009705 
	C562.145691,658.704895 562.307129,658.339844 562.800415,657.978455 
	C562.938477,658.304565 562.744751,658.627014 562.267639,659.009705 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M610.444946,615.522095 
	C610.416992,615.475952 610.472900,615.568237 610.444946,615.522095 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M579.756165,676.086670 
	C579.886902,676.329224 579.689880,676.591125 579.172729,676.884583 
	C579.135376,676.714294 579.327271,676.444214 579.756165,676.086670 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M576.419006,645.450195 
	C576.488770,645.401733 576.508606,645.594482 576.508606,645.594482 
	C576.508606,645.594482 576.349243,645.498657 576.419006,645.450195 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M481.332825,712.623901 
	C481.061218,712.611389 480.912689,712.469299 480.764130,712.327271 
	C480.994720,712.382996 481.225281,712.438721 481.332825,712.623901 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M621.411438,686.845215 
	C621.160461,686.641174 621.258606,686.368225 621.734619,686.045227 
	C621.904968,686.231262 621.787598,686.491577 621.411438,686.845215 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M531.734436,357.304382 
	C536.398254,354.853394 540.978333,356.558777 544.901245,357.735870 
	C559.504089,362.117493 573.999817,366.976105 588.191223,372.535126 
	C592.398682,374.183289 597.387634,375.528961 600.758728,379.789978 
	C599.260376,381.225525 598.941223,383.973541 596.218567,384.732727 
	C582.339600,377.551239 568.219238,371.189728 553.480103,366.143433 
	C546.965088,363.912811 540.223511,363.622192 533.253723,362.329529 
	C531.721680,360.837555 530.684937,359.548126 531.734436,357.304382 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M513.318298,369.328857 
	C499.797394,368.329224 486.659241,368.481934 473.342377,371.843445 
	C472.547272,371.619568 472.128967,371.463226 471.353210,371.304535 
	C469.123627,371.317993 467.231415,371.404663 465.041931,370.716248 
	C464.518860,370.387970 464.334778,370.216705 464.286316,369.693512 
	C465.060303,369.183075 465.698700,369.024567 466.660156,368.787354 
	C467.422607,368.490692 467.884338,368.407104 468.691284,368.645874 
	C474.766083,368.203339 480.509705,367.432129 486.278931,367.016357 
	C498.895782,366.107147 511.488342,366.462738 524.453857,368.279785 
	C522.183411,372.069397 518.790283,368.360352 515.402710,369.161804 
	C514.575684,369.461914 514.119629,369.550873 513.318298,369.328857 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M510.379303,358.116089 
	C508.264648,357.849274 506.333862,358.362396 504.287476,357.136963 
	C503.629059,356.619873 503.489136,356.216278 503.740906,355.482727 
	C510.338196,354.356262 516.738342,354.987640 523.125183,355.281433 
	C525.534973,355.392303 527.913635,356.178406 530.674194,356.816803 
	C530.916260,360.241425 528.423096,360.396088 526.156860,360.430420 
	C521.596191,360.499481 517.073792,360.008057 512.287537,358.427582 
	C511.493713,358.146820 511.088379,358.125793 510.379303,358.116089 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M565.924744,380.685181 
	C563.541016,381.864685 561.746643,379.981995 559.681641,379.229401 
	C549.699097,375.591278 539.523315,372.717529 529.021606,371.036621 
	C527.930603,370.862000 526.626404,371.073914 526.046021,369.659027 
	C526.899963,368.300873 528.074646,369.248474 529.511780,369.244019 
	C542.174561,371.992279 554.380554,374.876129 565.924744,380.685181 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M511.952637,358.557495 
	C516.473755,358.450287 521.046326,358.780487 525.624329,358.991058 
	C527.389404,359.072205 529.314392,359.427704 530.834839,357.320190 
	C531.041809,356.976898 531.504395,356.975067 531.735107,356.966370 
	C532.278442,358.410126 532.591064,359.862610 532.926208,361.689850 
	C526.852539,362.535339 520.868713,361.117828 514.809326,360.989655 
	C513.354919,360.958862 512.185364,360.666656 511.952637,358.557495 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M467.522400,361.754059 
	C468.743958,362.116516 469.642792,362.293427 470.770813,362.729370 
	C467.537811,364.039886 464.142426,365.369904 460.229919,365.251434 
	C460.100586,363.529388 460.970093,362.978516 462.687927,362.998291 
	C464.395599,362.417023 465.727875,361.787933 467.522400,361.754059 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M569.520996,384.108612 
	C569.433350,383.791077 569.225952,383.382690 569.078857,383.207214 
	C569.078674,382.874878 569.225647,382.718018 569.585571,382.803253 
	C572.638000,383.742554 575.377625,384.682922 577.943298,386.674896 
	C577.401123,387.002228 576.738464,386.995239 575.734192,386.931610 
	C574.775879,386.717712 574.178467,386.462982 573.197876,386.493713 
	C571.549500,386.171631 570.444092,385.513123 569.520996,384.108612 
z"
    />
    <path
      fill="#B7B6B5"
      opacity="1.000000"
      stroke="none"
      d="
M569.857178,382.690796 
	C569.798462,383.045349 569.365662,382.998718 569.148682,383.015228 
	C567.844482,382.561798 566.757263,382.091858 565.819702,381.284393 
	C567.569275,380.602112 568.608337,381.850555 569.857178,382.690796 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M460.715332,371.795654 
	C460.319427,371.404663 460.242615,371.159546 460.079468,370.539001 
	C461.233337,369.972473 462.473541,369.781372 464.067841,369.465942 
	C464.421936,369.341614 464.727692,369.680542 465.042908,370.163269 
	C465.257904,370.858276 465.157684,371.070618 464.771729,371.489807 
	C463.364166,372.182800 462.209747,372.209656 460.715332,371.795654 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M602.097046,400.957642 
	C601.428284,402.493286 599.937317,401.869629 598.350586,401.383789 
	C599.336060,401.029022 600.497986,400.985016 602.097046,400.957642 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M468.963013,368.544159 
	C468.559052,368.821350 468.107208,368.829376 467.319275,368.773010 
	C467.353668,367.345612 467.868225,366.624390 468.963013,368.544159 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M586.999756,393.999390 
	C587.075256,393.366669 587.150940,392.734558 587.226624,392.102448 
	C587.431213,392.174896 587.819031,392.283569 587.812927,392.314392 
	C587.723083,392.768372 587.580872,393.212006 587.225647,393.829346 
	C587.000244,394.000580 587.000000,394.000000 586.999756,393.999390 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M580.903198,388.651093 
	C580.384888,388.835114 579.799927,388.679413 579.080200,388.242767 
	C579.679260,387.518372 580.251343,387.949951 580.903198,388.651093 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M603.951782,400.687439 
	C603.765686,401.029572 603.504822,401.052948 602.852417,401.044373 
	C602.688416,400.260132 603.119141,399.954681 603.951782,400.687439 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M579.160706,387.701477 
	C578.627991,387.896942 578.207336,387.755035 577.882690,387.277130 
	C578.410095,387.082123 578.841370,387.223114 579.160706,387.701477 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M600.472412,391.448914 
	C600.525635,391.417694 600.419189,391.480133 600.472412,391.448914 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M683.995117,554.533386 
	C682.996582,555.103943 682.034973,555.157471 680.980713,554.177368 
	C681.162415,550.834412 680.455627,547.569763 682.419861,544.556824 
	C684.638794,547.601562 683.940613,550.916016 683.995117,554.533386 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M691.994934,542.228760 
	C696.094727,544.411316 693.409973,547.165161 692.060791,549.918945 
	C687.807861,547.451294 690.654419,545.036804 691.994934,542.228760 
z"
    />
    <path
      fill="#383C44"
      opacity="1.000000"
      stroke="none"
      d="
M679.209717,578.249512 
	C679.055359,576.568481 677.534302,574.609253 680.607117,574.098022 
	C681.050049,575.278992 680.848450,576.386475 680.060181,577.715088 
	C679.755249,578.190002 679.593384,578.348694 679.209717,578.249512 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M677.328613,587.843872 
	C677.183472,587.113220 677.351929,586.212524 677.782471,585.072021 
	C677.910461,585.779480 677.776306,586.726685 677.328613,587.843872 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M681.195435,568.848145 
	C681.084167,568.120422 681.274658,567.191467 681.756531,566.045776 
	C681.864319,566.768372 681.680725,567.707642 681.195435,568.848145 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M677.068970,588.950378 
	C676.990906,588.805603 674.779480,589.302246 676.766968,588.026855 
	C677.039062,588.221069 677.058838,588.429077 677.068970,588.950378 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M678.260864,584.039612 
	C678.140930,583.588745 678.294617,582.945496 678.733643,582.126587 
	C678.857544,582.583008 678.696045,583.215088 678.260864,584.039612 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M678.974609,578.331665 
	C678.988037,577.991638 679.453735,577.997803 679.685425,578.014587 
	C679.785461,578.600281 679.653748,579.169128 679.260254,579.885254 
	C678.986084,579.578857 678.973633,579.125305 678.974609,578.331665 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M679.059387,580.842163 
	C678.700684,580.900757 678.264648,580.711426 678.847107,580.086548 
	C679.044983,580.176147 679.060852,580.366333 679.059387,580.842163 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M676.281921,590.940857 
	C676.195923,590.718079 676.378967,590.421265 676.803223,589.998169 
	C676.880005,590.203491 676.715515,590.535156 676.281921,590.940857 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M668.240723,461.995178 
	C668.183411,461.793823 668.384827,461.492706 668.842224,461.087158 
	C668.898682,461.286926 668.699036,461.591156 668.240723,461.995178 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M597.987915,409.937164 
	C596.277405,409.076477 595.849304,407.525665 596.932007,405.294067 
	C597.792664,405.230499 598.601624,405.498840 599.720947,405.876770 
	C599.225220,407.107300 597.628235,407.772552 597.987915,409.937164 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M648.493774,584.931274 
	C648.865784,581.732666 649.597534,578.565369 650.639282,575.196655 
	C651.876282,574.225891 652.902588,573.897339 654.451904,574.509338 
	C655.558960,577.306946 653.810608,579.585022 653.911804,582.493408 
	C652.074402,583.251587 651.097534,585.654602 648.493774,584.931274 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M628.265259,633.791626 
	C626.563843,632.451721 627.849915,630.628113 627.515564,628.926392 
	C626.672913,624.638672 628.759216,626.568848 630.679810,627.835205 
	C630.155396,629.845581 629.339966,631.710815 628.265259,633.791626 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M621.940125,644.084839 
	C622.221313,643.062744 622.841309,642.170471 623.733398,641.100464 
	C623.935913,643.008057 626.655579,643.986328 626.082031,646.632812 
	C623.898743,647.274658 623.439453,645.272705 621.940125,644.084839 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M630.275391,639.405762 
	C630.150635,639.563354 629.934692,639.585693 629.718689,639.608032 
	C629.873840,639.495483 630.029053,639.382996 630.275391,639.405762 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M638.112000,614.043213 
	C637.878357,613.947937 637.849060,613.732361 637.870361,613.193481 
	C638.133423,613.086487 638.366577,613.287720 638.541138,613.531128 
	C638.577576,613.581970 638.396973,613.788269 638.112000,614.043213 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M629.134888,641.929199 
	C629.059814,641.876892 629.232666,641.034241 629.134888,641.929199 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M629.036377,642.049438 
	C628.929688,642.109863 628.772034,642.943420 628.770874,642.006104 
	C628.906067,641.923706 628.995789,642.004700 629.036377,642.049438 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M644.770874,618.129333 
	C644.878967,618.333069 644.694580,618.584595 644.218384,618.884949 
	C644.108765,618.680481 644.292725,618.428284 644.770874,618.129333 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M641.044800,615.177612 
	C641.167358,615.146729 641.296021,615.341980 641.424683,615.537231 
	C641.300110,615.492737 641.175598,615.448303 641.044800,615.177612 
z"
    />
    <path
      fill="#3B3F49"
      opacity="1.000000"
      stroke="none"
      d="
M640.956848,615.034424 
	C640.808289,614.882202 640.688965,614.704468 640.569702,614.526672 
	C640.673584,614.572754 640.777466,614.618835 640.959961,614.808228 
	C641.038635,614.951477 640.986145,615.008850 640.956848,615.034424 
z"
    />
    <path
      fill="#1F2329"
      opacity="1.000000"
      stroke="none"
      d="
M650.279053,612.509888 
	C649.409607,612.880371 648.782471,612.862671 648.544678,612.107910 
	C648.480042,611.902771 648.719788,611.412537 648.917419,611.346863 
	C649.757263,611.067810 650.501648,611.111084 650.279053,612.509888 
z"
    />
    <path
      fill="#0C0E12"
      opacity="1.000000"
      stroke="none"
      d="
M653.997559,608.001648 
	C653.754456,607.664124 653.513794,607.324951 653.273132,606.985718 
	C653.392578,606.902588 653.512024,606.819458 653.631470,606.736328 
	C653.747620,607.001038 653.863770,607.265686 653.992371,607.762756 
	C654.004883,607.995117 654.000000,608.000000 653.997559,608.001648 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M607.558716,384.812805 
	C606.191711,381.036865 608.500793,382.203644 610.716187,382.848999 
	C610.324402,384.155029 608.951111,384.213989 607.558716,384.812805 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M538.776855,377.400635 
	C548.489624,378.801331 557.339294,382.467224 566.598755,386.064819 
	C567.797302,385.936127 568.622620,385.899597 569.741882,386.261047 
	C570.621704,386.643768 571.115417,387.032898 571.627747,387.835480 
	C580.012024,392.895569 588.454529,397.388000 596.014709,403.663574 
	C594.539001,404.368195 593.498474,403.421753 592.419922,402.696411 
	C576.200928,391.788422 558.845093,383.424408 539.335205,378.912231 
	C538.644043,378.459015 538.518250,378.083252 538.776855,377.400635 
z"
    />
    <path
      fill="#10100F"
      opacity="1.000000"
      stroke="none"
      d="
M571.996460,388.000671 
	C571.125610,387.988312 570.512512,387.843750 569.693115,387.457581 
	C569.971802,386.437714 570.714050,386.216522 572.287415,386.625671 
	C572.751404,387.288910 572.502869,387.578674 571.996460,388.000671 
z"
    />
    <path
      fill="#10100F"
      opacity="1.000000"
      stroke="none"
      d="
M572.887329,385.846313 
	C573.732971,385.762970 574.354675,386.045441 575.184570,386.601440 
	C574.861084,386.912750 574.329529,386.950500 573.398987,386.993713 
	C573.000000,386.999146 573.000000,387.000000 573.000000,387.000610 
	C572.887817,386.738190 572.775635,386.475189 572.887329,385.846313 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M590.521606,396.544128 
	C590.470154,396.574188 590.573059,396.514038 590.521606,396.544128 
z"
    />
    <path
      fill="#B3B1B0"
      opacity="1.000000"
      stroke="none"
      d="
M654.810059,574.456665 
	C653.788452,574.833252 652.763855,574.936279 651.344238,575.017212 
	C650.980225,574.295593 651.011292,573.595947 651.087097,572.548462 
	C652.236694,571.748962 653.253235,571.755371 654.351501,572.937622 
	C654.687134,573.465881 654.747131,573.824402 654.810059,574.456665 
z"
    />
    <path
      fill="#858484"
      opacity="1.000000"
      stroke="none"
      d="
M660.068237,439.946533 
	C660.328369,440.031586 660.480591,440.222046 660.632812,440.412537 
	C660.425720,440.456848 660.218567,440.501160 659.881104,440.407166 
	C659.750732,440.268890 659.960388,440.051941 660.068237,439.946533 
z"
    />
    <path
      fill="#15171D"
      opacity="1.000000"
      stroke="none"
      d="
M656.541504,578.472534 
	C656.569092,578.521912 656.513977,578.423096 656.541504,578.472534 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M601.785522,380.251709 
	C601.922302,379.660980 602.179810,379.372711 602.698975,378.955322 
	C603.097717,380.019653 605.500305,379.572937 604.835510,380.822937 
	C604.276428,381.874329 602.732300,382.403381 601.785522,380.251709 
z"
    />
    <path
      fill="#22252C"
      opacity="1.000000"
      stroke="none"
      d="
M605.453247,382.445312 
	C605.520447,382.400726 605.386108,382.489899 605.453247,382.445312 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M611.395752,653.031860 
	C611.020508,651.332336 607.156128,651.247559 608.896729,648.932800 
	C610.762207,646.451965 613.225342,647.258789 615.783325,648.925293 
	C614.853027,650.610596 613.522522,652.052185 611.395752,653.031860 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M617.303101,648.832397 
	C617.149902,647.328247 616.432739,645.228943 619.518311,644.984131 
	C620.084290,646.786987 618.907471,647.787170 617.303101,648.832397 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M595.940369,676.700256 
	C595.657837,677.083862 595.399475,677.272400 595.102234,677.773682 
	C594.117859,677.687866 592.208435,678.573608 592.782288,676.640625 
	C593.013062,675.863281 594.722900,674.922363 595.940369,676.700256 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M600.333801,661.030457 
	C600.199646,660.120667 600.131653,658.920044 601.878967,659.874878 
	C601.575378,660.409729 601.099426,660.704895 600.333801,661.030457 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M622.547607,648.462280 
	C622.579956,648.518250 622.515198,648.406311 622.547607,648.462280 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M620.195923,644.819458 
	C620.106079,644.633911 620.277283,644.410461 620.674866,644.108398 
	C620.818604,644.310242 620.670471,644.560791 620.195923,644.819458 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M694.312012,433.789673 
	C693.543701,433.569031 693.091187,433.075287 692.799072,432.268311 
	C693.992188,431.966461 694.202454,432.856445 694.312012,433.789673 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M696.309082,436.784607 
	C695.601013,436.574371 695.205688,436.088684 694.882874,435.279236 
	C695.992798,434.956421 696.186890,435.862000 696.309082,436.784607 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M695.049927,434.674744 
	C694.687744,434.911591 694.331604,434.767944 693.957214,434.301056 
	C694.454346,433.578522 694.827454,433.681213 695.049927,434.674744 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M697.040588,437.672241 
	C696.696899,437.912018 696.356262,437.776306 695.978516,437.310120 
	C696.451599,436.588745 696.819824,436.691681 697.040588,437.672241 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M659.801758,388.970947 
	C659.598450,389.037109 659.290771,388.847687 658.881470,388.401550 
	C659.085693,388.335022 659.391541,388.525177 659.801758,388.970947 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M658.759888,387.962463 
	C658.551514,388.036224 658.251892,387.854706 657.856323,387.422791 
	C658.063171,387.350647 658.365906,387.528900 658.759888,387.962463 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M657.742676,386.982422 
	C657.529053,387.064575 657.228455,386.887726 656.827515,386.463806 
	C657.036743,386.385620 657.346313,386.554504 657.742676,386.982422 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M656.704895,386.013672 
	C656.483948,386.121185 656.180664,385.964996 655.763550,385.575623 
	C655.973938,385.478271 656.298279,385.614105 656.704895,386.013672 
z"
    />
    <path
      fill="#CFCECE"
      opacity="1.000000"
      stroke="none"
      d="
M693.083313,431.780090 
	C692.658508,431.936493 692.291321,431.823456 691.836792,431.372986 
	C691.944885,430.906769 692.140320,430.777985 692.641602,430.821838 
	C693.011902,431.166504 693.076416,431.338562 693.083313,431.780090 
z"
    />
    <path
      fill="#DDDCDC"
      opacity="1.000000"
      stroke="none"
      d="
M692.926575,430.719360 
	C692.747864,431.003418 692.548279,431.012390 692.049072,431.028442 
	C691.641724,430.825256 691.534119,430.614990 691.697510,430.176331 
	C692.280884,430.113373 692.593262,430.278809 692.926575,430.719360 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M632.778687,362.518677 
	C631.554932,362.970734 630.332703,363.236877 629.899170,361.753204 
	C629.788269,361.373749 630.102173,360.870209 630.220886,360.423676 
	C631.106140,361.014679 631.991394,361.605652 632.778687,362.518677 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M623.114380,356.626587 
	C623.113281,356.287292 623.570984,356.124908 623.776123,355.995056 
	C624.884949,356.056274 626.252014,356.268738 625.583740,357.436157 
	C625.054810,358.360260 623.690063,358.512085 623.114380,356.626587 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M648.543701,371.411530 
	C648.321655,370.615479 648.589172,370.314606 649.215942,370.443268 
	C649.344666,370.469666 649.514160,370.651855 649.536133,370.783630 
	C649.641907,371.420105 649.319092,371.663116 648.543701,371.411530 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M641.915894,366.303833 
	C642.475220,366.272797 642.869751,366.399750 643.264343,366.526672 
	C643.156860,366.634277 643.025513,366.849152 642.945923,366.831451 
	C642.540283,366.741333 642.148071,366.591034 641.915894,366.303833 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M651.563232,376.251343 
	C651.415527,376.129059 651.396484,375.918701 651.377502,375.708344 
	C651.482300,375.859955 651.587036,376.011597 651.563232,376.251343 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M646.751038,361.374023 
	C647.001709,361.391632 647.136963,361.523804 647.272156,361.656006 
	C647.059937,361.600220 646.847778,361.544434 646.751038,361.374023 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M645.530151,367.547241 
	C645.474487,367.580750 645.585815,367.513733 645.530151,367.547241 
z"
    />
    <path
      fill="#4C545F"
      opacity="1.000000"
      stroke="none"
      d="
M649.601257,373.445190 
	C649.683472,373.557648 649.377441,373.611664 649.377441,373.611664 
	C649.377441,373.611664 649.519043,373.332703 649.601257,373.445190 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M647.707275,364.320404 
	C647.743530,364.553162 647.648499,364.641449 647.553406,364.729706 
	C647.466248,364.633850 647.307739,364.534821 647.311829,364.443054 
	C647.316040,364.350281 647.481262,364.264679 647.707275,364.320404 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M625.997314,348.996338 
	C626.085754,348.961426 626.176819,348.930176 626.137634,348.952271 
	C626.007324,349.005615 626.000000,349.000000 625.997314,348.996338 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M600.996338,336.996368 
	C601.071533,338.125275 601.080200,339.268250 601.249390,340.386902 
	C601.410828,341.453705 601.049194,342.005157 600.003113,341.767700 
	C599.104370,341.563721 597.927307,341.434418 597.706726,340.344910 
	C597.590088,339.768707 597.657715,338.588043 598.944092,338.967804 
	C600.629395,339.465302 599.685364,337.546478 600.767578,337.146484 
	C601.006287,337.004181 601.000000,336.999786 600.996338,336.996368 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M603.601929,352.189850 
	C602.995667,352.271698 602.829102,352.234253 602.596802,352.146759 
	C603.812805,351.678314 601.652893,349.349243 603.865234,349.576904 
	C604.720520,349.664917 605.808655,350.215820 605.800781,351.335205 
	C605.791870,352.596375 604.706848,351.992096 603.601929,352.189850 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M540.938354,335.064575 
	C541.590027,334.338715 539.977661,332.604309 541.970459,332.480469 
	C542.468933,332.449463 543.382507,333.024933 543.463257,333.445099 
	C543.743164,334.901306 542.517761,334.837982 541.213745,335.005554 
	C540.866028,335.049347 540.955139,335.020966 540.938354,335.064575 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M596.145508,342.684662 
	C595.589966,342.721985 595.196838,342.597046 594.803711,342.472107 
	C594.911926,342.365540 595.042175,342.153137 595.124573,342.170319 
	C595.526001,342.253967 595.914734,342.399048 596.145508,342.684662 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M595.052429,336.135193 
	C595.120422,336.136688 595.238403,336.275818 595.356384,336.414917 
	C595.271729,336.367554 595.187073,336.320190 595.052429,336.135193 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M594.998779,336.000977 
	C594.679260,335.893005 594.422668,335.701324 594.115906,335.210754 
	C594.003906,334.994598 594.000000,335.000000 593.997375,335.001953 
	C594.311401,335.113495 594.572327,335.300690 594.889954,335.781555 
	C595.002441,335.997559 595.000000,336.000000 594.998779,336.000977 
z"
    />
    <path
      fill="#252830"
      opacity="1.000000"
      stroke="none"
      d="
M578.000122,331.999390 
	C578.115906,332.136017 578.231445,332.273254 578.346985,332.410522 
	C578.264832,332.363098 578.182739,332.315674 578.050293,332.134766 
	C578.000000,332.001221 578.000000,332.000000 578.000122,331.999390 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M537.782959,340.334351 
	C550.893005,339.900146 563.241455,343.349274 575.664612,346.316803 
	C577.999451,346.874573 580.276550,347.673981 582.807251,348.624817 
	C579.995056,351.371796 577.161255,348.455872 574.195374,348.506622 
	C571.679688,348.549652 569.353210,346.507965 566.898376,347.331421 
	C565.038147,349.535095 573.368591,349.106781 567.350464,352.001160 
	C564.679993,349.625458 561.947754,352.307922 559.098267,351.176575 
	C558.698303,350.499725 558.608765,350.026001 558.589600,349.547791 
	C558.564697,349.838013 558.583496,350.126373 558.660767,350.691345 
	C558.416565,353.156067 555.929077,353.392212 555.817444,352.363251 
	C555.570496,350.086273 551.337463,351.175507 552.879761,348.546722 
	C553.945984,346.729462 553.553711,346.035095 551.793762,346.011078 
	C546.627136,345.940582 542.727600,341.632141 537.440247,341.870819 
	C536.430908,341.916412 537.313904,341.005157 537.782959,340.334351 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M485.371857,357.079651 
	C485.548187,356.081696 486.478210,356.019714 487.742340,355.875824 
	C488.795715,355.841980 489.504059,355.865082 490.567444,355.870911 
	C491.391815,355.528473 491.851562,355.184662 492.717926,355.064972 
	C494.772278,355.057709 496.540588,355.059662 498.750763,355.087585 
	C499.681854,355.613800 500.278046,355.993469 500.889984,356.827942 
	C498.079010,359.771942 489.864929,359.783813 485.371857,357.079651 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M558.676270,351.010742 
	C556.448303,350.304810 556.507507,348.703583 557.201172,347.018463 
	C557.448486,346.417725 558.219299,346.235535 558.916016,346.649231 
	C560.901123,347.828033 559.888550,349.094452 558.855164,350.669189 
	C558.680542,350.980286 558.677002,351.051697 558.676270,351.010742 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M501.386261,357.132385 
	C500.325958,356.792145 499.256592,356.920654 499.188110,355.430176 
	C500.516602,355.086456 501.840546,355.059357 503.553650,355.123291 
	C503.974426,355.605743 504.006012,355.997223 503.985657,356.679413 
	C503.206238,357.017456 502.478790,357.064789 501.386261,357.132385 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M606.053650,368.323151 
	C607.165161,368.115509 608.271423,368.354767 609.245850,369.643372 
	C607.804138,370.107483 606.753784,370.181824 606.053650,368.323151 
z"
    />
    <path
      fill="#1B1D24"
      opacity="1.000000"
      stroke="none"
      d="
M492.842041,354.830261 
	C492.509766,355.336517 492.015503,355.617279 491.221832,355.875854 
	C491.202789,355.007385 491.301910,353.906036 492.842041,354.830261 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M592.471252,368.454956 
	C592.521912,368.425934 592.420593,368.483948 592.471252,368.454956 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M605.996338,368.002716 
	C605.878967,367.869873 605.765259,367.734314 605.651550,367.598785 
	C605.732544,367.644531 605.813599,367.690277 605.949829,367.864746 
	C606.005005,367.993408 606.000000,368.000000 605.996338,368.002716 
z"
    />
    <path
      fill="#181C22"
      opacity="1.000000"
      stroke="none"
      d="
M567.003662,352.004395 
	C566.915283,352.041046 566.823181,352.073547 566.861938,352.049988 
	C566.992676,351.993927 567.000000,352.000214 567.003662,352.004395 
z"
    />
    <path
      fill="#9C9A9A"
      opacity="1.000000"
      stroke="none"
      d="
M697.023682,507.376312 
	C697.066467,507.051758 697.531921,507.007660 697.765747,507.003845 
	C699.471558,508.914124 699.275879,511.229675 699.654724,513.600464 
	C697.844482,512.767029 697.206055,511.398987 697.016113,509.350281 
	C696.961182,508.572479 696.971008,508.136658 697.023682,507.376312 
z"
    />
    <path
      fill="#1C2127"
      opacity="1.000000"
      stroke="none"
      d="
M701.165405,519.170898 
	C701.216736,518.546875 701.450500,518.180786 701.684265,517.814697 
	C701.770142,518.112366 701.856018,518.409973 701.940430,518.956421 
	C701.741943,519.279724 701.544922,519.354309 701.165405,519.170898 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M700.961731,519.253906 
	C701.138000,518.975769 701.293030,519.038635 701.693481,519.153320 
	C701.847656,519.787903 702.170654,520.521667 701.344482,520.836670 
	C701.209778,520.422729 701.075134,520.008850 700.961731,519.253906 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M702.580688,522.128601 
	C702.762939,522.466797 702.569946,522.768799 702.142456,523.218933 
	C702.007141,522.942200 702.106323,522.517273 702.580688,522.128601 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M630.458618,369.414917 
	C630.554932,369.356964 630.600037,369.590118 630.600037,369.590118 
	C630.600037,369.590118 630.362366,369.472900 630.458618,369.414917 
z"
    />
    <path
      fill="#BAB9B9"
      opacity="1.000000"
      stroke="none"
      d="
M634.888123,371.045929 
	C633.948914,370.765076 633.424683,370.253265 635.075867,370.070282 
	C635.237488,370.405609 635.156555,370.599274 634.888123,371.045929 
z"
    />
    <path
      fill="#D5D4D3"
      opacity="1.000000"
      stroke="none"
      d="
M538.609619,377.043457 
	C538.976135,377.514465 538.969849,377.870026 538.970764,378.492310 
	C513.849243,373.369446 488.826965,373.239258 464.252960,381.377411 
	C435.838593,390.787262 413.913879,408.913177 396.459686,433.152222 
	C396.083832,432.679718 396.055054,432.235199 396.079773,431.448578 
	C396.454926,430.666809 396.776611,430.227173 397.118958,429.502686 
	C397.506348,428.780670 397.873138,428.343567 398.351868,427.635468 
	C398.739197,426.888184 399.014618,426.411896 399.377625,425.668335 
	C399.680786,425.163055 399.896332,424.925049 400.157471,424.440155 
	C400.706238,423.629272 401.209412,423.065277 401.791199,422.222412 
	C402.080627,421.679047 402.291412,421.414490 402.608521,420.919434 
	C405.804657,417.206635 408.894470,413.724396 412.155945,409.978882 
	C434.160889,387.841003 460.933197,376.409027 491.303589,373.354095 
	C507.042938,371.770874 522.822449,372.721680 538.609619,377.043457 
z"
    />
    <path
      fill="#05070B"
      opacity="1.000000"
      stroke="none"
      d="
M501.998779,514.590576 
	C499.690491,514.393677 499.497314,510.976990 496.352783,511.007629 
	C495.191162,511.622223 494.323151,511.354309 493.140686,511.065918 
	C492.619476,510.859344 492.418243,510.757050 491.956482,510.436462 
	C491.577484,510.024567 491.459015,509.830994 491.241516,509.311401 
	C491.087830,508.534149 491.083527,508.082794 491.206604,507.301727 
	C491.468872,506.332733 491.732941,505.717957 492.021912,504.759918 
	C492.554077,501.506622 490.652191,499.592133 489.113739,497.522034 
	C487.983795,496.001587 486.123108,494.856598 486.780945,492.267029 
	C496.621033,496.269836 500.587860,504.362274 501.998779,514.590576 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M626.544922,587.005615 
	C626.503113,585.893616 626.874939,584.780273 627.133118,583.327393 
	C627.019470,582.987854 626.979492,583.000854 626.985718,583.021362 
	C627.453003,582.604675 627.496216,581.992310 627.847656,581.197876 
	C628.029297,580.962830 627.969666,580.977051 627.972656,581.007568 
	C628.208679,580.800781 628.443420,580.565125 628.861450,580.160034 
	C629.043030,579.989014 628.988220,579.992737 628.993774,580.019653 
	C629.722595,579.325623 629.407898,578.350891 629.826538,577.238220 
	C630.032349,576.971252 629.977844,576.987122 629.984802,577.014648 
	C630.426270,576.593750 630.500793,575.994080 630.852783,575.202148 
	C631.037598,574.971069 630.977112,574.981079 630.979797,575.011597 
	C631.131287,574.850281 631.280029,574.658508 631.686096,574.724182 
	C633.391846,575.980347 633.727783,577.685486 634.535156,579.088013 
	C635.898987,581.456970 636.720642,583.899597 635.169128,586.519897 
	C633.490051,589.355652 631.324036,586.608643 629.416443,586.984009 
	C628.626404,587.139465 627.779541,587.006226 626.544922,587.005615 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M486.750610,491.998932 
	C487.287964,495.172638 490.665955,496.149109 491.772400,498.682953 
	C492.584869,500.543488 494.567688,502.122406 493.258728,504.776550 
	C489.887360,506.199646 487.866486,504.628693 485.952179,501.998413 
	C485.543793,501.295288 485.378632,500.868683 485.225952,500.094971 
	C485.123505,498.924438 485.011047,498.092712 484.420532,497.047546 
	C484.194397,496.538361 484.117981,496.324677 483.970184,495.770996 
	C483.796082,494.462402 483.891937,493.520233 484.480713,492.308899 
	C484.905914,491.886322 485.124664,491.771149 485.672943,491.826172 
	C486.002441,491.996338 486.501221,491.997864 486.750610,491.998932 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M484.993530,491.671631 
	C485.002502,493.062653 485.003784,494.124817 485.006775,495.585266 
	C481.339508,497.793671 480.644653,497.293365 480.745728,492.402405 
	C481.490387,491.160492 481.994171,490.257172 482.750122,489.174255 
	C483.002289,488.994690 483.497498,489.000946 483.745087,489.002625 
	C484.725067,489.613342 484.974762,490.427399 484.993530,491.671631 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M550.294922,433.632080 
	C549.651733,435.803436 548.742554,435.845612 547.681274,434.304260 
	C546.894348,433.161316 547.189575,431.946045 548.341675,431.473785 
	C549.880798,430.842865 550.124268,432.199921 550.294922,433.632080 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M632.914185,563.217285 
	C634.085999,563.099670 635.203125,562.973145 636.007690,563.415283 
	C637.073608,564.001160 636.783875,560.291443 638.052795,562.867432 
	C638.395874,563.563843 638.740112,564.623474 638.449097,565.190918 
	C637.885681,566.289673 636.781738,565.847229 635.867371,565.402222 
	C635.126038,565.041321 634.394348,564.660706 633.340454,564.147827 
	C632.921875,563.825012 632.821167,563.643250 632.914185,563.217285 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M540.614746,501.614197 
	C540.594177,499.836212 540.890686,498.455780 542.785645,499.154144 
	C543.763245,499.514404 544.260010,500.534119 543.643127,501.592346 
	C542.879272,502.902679 541.934448,503.755310 540.614746,501.614197 
z"
    />
    <path
      fill="#504E4E"
      opacity="1.000000"
      stroke="none"
      d="
M633.105652,562.622192 
	C633.073547,563.143677 633.039001,563.314270 633.013550,563.745850 
	C633.022583,564.006775 632.941650,564.428162 632.943848,564.642700 
	C632.807068,565.025940 632.668213,565.194641 632.253540,565.186523 
	C631.568542,563.990479 631.122253,562.984253 630.761230,561.948242 
	C630.417725,560.962585 631.095337,560.341431 631.867188,560.284180 
	C633.277405,560.179443 633.085693,561.351868 633.105652,562.622192 
z"
    />
    <path
      fill="#C4C3C2"
      opacity="1.000000"
      stroke="none"
      d="
M559.595032,433.879425 
	C559.778748,432.544067 560.184692,431.511139 561.437500,432.178253 
	C562.196350,432.582367 562.287231,433.618408 561.866760,434.334564 
	C561.196228,435.476685 560.381165,435.322449 559.595032,433.879425 
z"
    />
    <path
      fill="#8B8A89"
      opacity="1.000000"
      stroke="none"
      d="
M617.481262,442.119141 
	C614.977417,443.004089 615.227661,441.575378 615.609741,440.299469 
	C615.758362,439.803406 616.552979,439.045013 616.855896,439.127838 
	C618.385742,439.546204 618.165466,440.725159 617.481262,442.119141 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M638.284546,552.741333 
	C638.160461,551.007324 637.519714,548.938232 638.504578,546.741211 
	C639.424805,548.806641 639.147400,550.634216 638.284546,552.741333 
z"
    />
    <path
      fill="#05070B"
      opacity="1.000000"
      stroke="none"
      d="
M485.251526,491.999725 
	C484.131317,491.456848 484.005554,490.592926 483.996948,489.340576 
	C485.434723,489.090057 486.012146,489.929413 485.995178,491.644806 
	C485.835571,491.997223 485.668701,491.998077 485.251526,491.999725 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M473.857300,400.581421 
	C474.262024,401.689911 474.172485,402.447815 473.181671,402.597900 
	C472.915985,402.638184 472.328766,402.149994 472.299805,401.862946 
	C472.209167,400.964691 472.808685,400.489532 473.857300,400.581421 
z"
    />
    <path
      fill="#EBEAE9"
      opacity="1.000000"
      stroke="none"
      d="
M496.708954,451.858551 
	C495.778778,451.008698 495.752960,450.099487 496.313019,449.227478 
	C496.381714,449.120392 496.985931,449.162903 497.114288,449.320984 
	C497.777557,450.137939 497.917694,451.004395 496.708954,451.858551 
z"
    />
    <path
      fill="#C0BFBE"
      opacity="1.000000"
      stroke="none"
      d="
M498.077148,458.212494 
	C497.921631,459.390228 497.634460,460.223145 496.567963,459.749176 
	C496.284760,459.623291 496.007904,458.864319 496.131927,458.561249 
	C496.454651,457.772491 496.792358,456.501282 498.077148,458.212494 
z"
    />
    <path
      fill="#CCCBCA"
      opacity="1.000000"
      stroke="none"
      d="
M567.904053,407.593445 
	C568.837830,406.849457 569.628113,406.547577 569.951599,407.617371 
	C570.116150,408.161316 569.927429,408.997894 569.170288,409.045319 
	C568.517273,409.086151 567.094788,409.702667 567.904053,407.593445 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M633.750000,567.434204 
	C634.383362,566.348633 634.923157,566.478943 635.427856,567.100342 
	C635.615112,567.330933 635.801941,567.735168 635.724548,567.974792 
	C635.501831,568.664490 634.982971,568.341675 634.307434,568.098755 
	C633.902527,567.899597 633.732239,567.787476 633.750000,567.434204 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M632.333984,571.933960 
	C632.159790,571.168823 632.333801,570.289612 632.766846,569.183594 
	C632.911255,569.911133 632.796753,570.865540 632.333984,571.933960 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M631.964600,574.603027 
	C631.943481,574.981628 631.463440,575.019775 631.222961,575.030945 
	C631.127747,574.129333 631.272949,573.216492 631.726501,572.133301 
	C632.018433,572.716675 632.002075,573.470581 631.964600,574.603027 
z"
    />
    <path
      fill="#101217"
      opacity="1.000000"
      stroke="none"
      d="
M631.825623,565.151367 
	C631.977600,565.009705 632.466553,564.962891 632.706299,564.910095 
	C632.680115,565.174133 632.414307,565.490967 632.148499,565.807861 
	C631.990234,565.636230 631.831970,565.464661 631.825623,565.151367 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M633.750122,567.147705 
	C633.938110,567.192993 633.938416,567.613342 634.005615,567.812561 
	C633.895508,568.340759 633.718201,568.669739 633.258667,569.019104 
	C633.020996,568.606262 633.065430,568.173157 633.142700,567.415588 
	C633.175598,567.091187 633.562195,567.102356 633.750122,567.147705 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M623.677673,579.052246 
	C623.870728,579.602722 623.722290,580.213684 623.263123,580.928101 
	C623.080322,580.392029 623.208252,579.752380 623.677673,579.052246 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M621.657349,582.036499 
	C622.375610,582.677551 622.067200,583.278748 621.290527,583.933350 
	C620.486633,583.268250 620.903503,582.698181 621.657349,582.036499 
z"
    />
    <path
      fill="#434242"
      opacity="1.000000"
      stroke="none"
      d="
M637.992676,552.985718 
	C637.983643,552.972717 637.760193,553.369995 637.760193,553.369995 
	C637.760193,553.369995 637.803955,553.106201 637.915710,553.061890 
	C638.027466,553.017517 638.001770,552.998718 637.992676,552.985718 
z"
    />
    <path
      fill="#07080D"
      opacity="1.000000"
      stroke="none"
      d="
M636.457153,592.526672 
	C636.429871,592.478577 636.484375,592.574768 636.457153,592.526672 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M620.672974,583.847900 
	C620.873840,584.371277 620.747009,584.794128 620.286133,585.099976 
	C620.083191,584.571045 620.214233,584.159241 620.672974,583.847900 
z"
    />
    <path
      fill="#908F8F"
      opacity="1.000000"
      stroke="none"
      d="
M622.689575,580.875610 
	C622.882996,581.354736 622.746704,581.762207 622.277893,582.086182 
	C622.083496,581.603027 622.221680,581.203552 622.689575,580.875610 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M437.902496,487.413330 
	C438.514587,484.482819 436.984650,482.326355 436.263031,480.033752 
	C438.277954,479.109467 440.437012,482.614990 441.703400,479.815247 
	C443.107971,476.709930 440.431122,474.766998 438.196564,473.209473 
	C439.420990,471.788940 440.379486,472.057709 441.361359,472.276459 
	C442.484650,472.526703 444.010956,474.802216 444.525818,472.915924 
	C445.034790,471.051178 447.535431,469.350922 445.361664,466.635651 
	C443.214081,463.953217 442.617798,460.623352 446.594635,458.079834 
	C447.921661,459.816650 448.873047,461.600159 450.017944,463.666016 
	C450.611542,468.953644 448.949768,474.628235 455.637970,477.351959 
	C457.141998,477.964417 456.197998,480.758606 455.150848,482.144531 
	C454.304443,483.264862 452.807037,483.893341 451.266479,484.854889 
	C449.337921,484.616455 447.779572,481.546417 446.376160,483.789001 
	C444.992676,485.999695 448.149994,486.288818 449.656189,487.580353 
	C449.670135,489.848145 451.209595,492.673096 447.050598,493.094543 
	C443.917664,494.855988 441.656067,494.611420 440.151398,491.546844 
	C439.493378,490.206665 438.294800,489.215759 437.902496,487.413330 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M480.741119,492.045593 
	C481.395569,494.003296 480.654663,496.920471 484.690613,496.015015 
	C485.008484,495.983551 485.048218,496.475037 485.063782,496.721100 
	C485.458344,497.404907 485.816528,497.855835 486.055908,498.701385 
	C486.092468,499.432892 486.072174,499.881104 486.040436,500.665710 
	C484.135742,503.724030 488.215668,505.265015 487.143005,508.050659 
	C486.469421,508.504303 486.008972,508.536316 485.254517,508.223328 
	C484.971497,508.007599 485.002747,507.994476 485.002319,508.011414 
	C484.212402,507.381836 483.332642,506.872894 482.209717,506.178772 
	C481.962494,505.999725 482.008972,505.989594 482.010162,506.013336 
	C481.783447,505.773621 481.535095,505.530914 481.064880,505.143860 
	C478.271790,505.362946 476.350250,502.573975 473.358856,503.599670 
	C472.567047,503.871185 469.695343,502.320801 471.086426,498.824890 
	C472.354675,495.637695 471.355164,491.548096 471.355164,487.877075 
	C473.644958,488.375214 476.615234,487.874146 476.835388,491.700714 
	C476.870483,492.310455 478.406647,492.789642 479.729248,492.096863 
	C480.004883,491.989380 480.495667,492.027435 480.741119,492.045593 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M480.847260,517.689209 
	C481.310791,521.978394 479.362640,522.967957 476.047760,521.266663 
	C474.562927,520.504639 473.161133,519.924866 471.103149,519.826416 
	C470.272766,518.546936 471.117310,517.182678 470.292786,515.925903 
	C468.268707,516.107239 467.826263,519.262329 465.133820,518.212646 
	C464.489166,517.981384 464.110413,517.974060 463.451172,517.947998 
	C462.840271,514.754517 463.580292,511.436401 460.689575,508.761078 
	C459.142731,507.329529 460.517700,505.104553 461.546356,503.854797 
	C463.080597,501.990692 464.115448,504.288635 465.039093,505.066162 
	C467.921295,507.492554 470.615356,510.142456 473.671997,512.867920 
	C475.730560,515.189697 478.326172,516.091064 480.847260,517.689209 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M481.258667,517.953125 
	C478.266510,517.299805 474.654999,518.035278 473.976959,513.407166 
	C476.598297,512.689453 479.083679,510.802612 481.882935,513.431213 
	C483.296753,514.758850 482.972046,511.350464 484.737579,511.135925 
	C486.833435,510.931366 486.675323,511.895538 486.135315,513.060730 
	C486.977844,512.048157 486.106659,510.944489 486.375122,509.564758 
	C487.070862,508.447662 487.919708,508.157898 489.182648,508.010620 
	C489.742584,508.051392 489.963287,508.093384 490.489868,508.271881 
	C491.138916,508.695679 491.409119,509.038910 491.814819,509.690369 
	C492.036835,509.932343 492.528259,509.965668 492.774200,509.978333 
	C495.711334,512.895386 496.175018,515.516602 491.894257,517.276062 
	C490.074158,518.024170 490.159027,520.005920 488.675171,521.303650 
	C488.111664,521.484375 487.868317,521.497559 487.287048,521.361694 
	C486.822784,520.999634 486.696381,520.786621 486.792358,520.276001 
	C486.550690,519.661865 486.086609,519.345337 485.329895,519.002808 
	C484.171844,518.743164 483.425293,518.199646 482.207550,517.974609 
	C481.875610,517.929871 481.464355,517.946777 481.258667,517.953125 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M437.746674,487.076447 
	C439.079895,488.744415 441.633179,488.972168 441.851837,491.764465 
	C442.039337,494.158630 444.585968,492.429688 446.279694,493.090576 
	C446.712189,493.515686 446.795685,493.898590 446.893372,494.575745 
	C446.249451,495.992523 445.985504,497.276703 445.304260,498.737976 
	C445.109894,499.439240 445.052429,499.848328 444.958191,500.565186 
	C444.805328,501.088043 444.689240,501.303162 444.254669,501.336609 
	C443.936157,501.154999 443.570343,501.008453 443.381836,500.951172 
	C442.879944,500.757568 442.600983,500.570038 442.253906,500.098511 
	C442.151337,499.865692 442.156799,499.830841 442.167053,499.816528 
	C441.400299,499.449066 440.906982,498.797424 440.429871,497.829163 
	C439.877319,497.022034 439.318329,496.521240 438.506836,495.892822 
	C433.731384,492.888855 433.557251,491.789764 437.024231,487.462769 
	C437.057068,487.132141 437.516876,487.095703 437.746674,487.076447 
z"
    />
    <path
      fill="#161B23"
      opacity="1.000000"
      stroke="none"
      d="
M467.761169,538.201660 
	C469.170074,537.326599 469.899902,533.565125 471.662628,537.872437 
	C473.011841,541.169373 475.261902,544.157898 478.085632,547.021606 
	C478.235413,547.568237 478.215820,547.792236 477.930847,548.225952 
	C476.673523,548.735229 475.901917,548.412598 475.148071,547.298828 
	C471.959656,547.649231 469.490601,547.616821 469.868805,543.351685 
	C470.047394,541.337463 466.788147,540.792236 467.761169,538.201660 
z"
    />
    <path
      fill="#55616F"
      opacity="1.000000"
      stroke="none"
      d="
M450.000275,487.552917 
	C449.561340,487.876648 449.235565,487.941315 448.907898,487.953400 
	C446.192047,488.053406 444.531586,486.749664 444.061157,484.094818 
	C443.846802,482.885040 444.299255,481.415375 445.493347,481.314362 
	C447.784576,481.120544 450.144653,481.427185 450.885315,484.655151 
	C450.660553,485.736359 450.386566,486.502899 450.000275,487.552917 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M474.888641,547.282104 
	C475.859863,547.329285 476.727692,547.660156 477.942383,548.039307 
	C478.501465,548.500671 478.568939,548.938354 478.322784,549.679810 
	C477.837830,550.166321 477.542358,550.370300 477.117157,550.814575 
	C474.991028,550.688599 474.211975,549.553955 474.888641,547.282104 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M487.186707,508.762390 
	C486.982788,509.808899 487.058533,510.624115 486.935211,511.407959 
	C486.856323,511.909363 487.412872,512.827026 486.447327,512.828796 
	C486.141449,512.829346 485.833801,511.855957 485.277740,511.163055 
	C485.000854,510.250336 484.973206,509.502777 484.958496,508.381409 
	C485.415192,508.019989 485.858856,508.032379 486.635498,508.046387 
	C487.106293,508.204559 487.244171,508.361176 487.186707,508.762390 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M445.426941,498.897339 
	C444.905212,497.567474 444.924744,496.186340 446.592773,495.030518 
	C447.333221,496.429260 447.801147,498.001770 445.426941,498.897339 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M437.585327,499.027039 
	C437.194427,499.744507 436.801727,499.746643 436.454834,499.238678 
	C436.381683,499.131531 436.381866,498.883881 436.457306,498.781036 
	C436.820282,498.286407 437.214203,498.301575 437.585327,499.027039 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M440.336182,497.883606 
	C440.979431,498.083801 441.522552,498.644775 442.121490,499.503967 
	C441.074921,499.850250 440.227234,499.580933 440.336182,497.883606 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M482.122253,506.287415 
	C483.157013,505.955139 484.085022,506.438629 484.883301,507.721191 
	C483.706696,508.271149 482.958374,507.490448 482.122253,506.287415 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M443.972839,501.392975 
	C444.098389,501.100952 444.260620,501.046875 444.672119,500.932861 
	C444.824524,501.326630 444.727631,501.780396 444.630737,502.234131 
	C444.423676,502.033051 444.216583,501.832001 443.972839,501.392975 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M458.543579,505.474487 
	C458.571655,505.525513 458.515472,505.423492 458.543579,505.474487 
z"
    />
    <path
      fill="#202730"
      opacity="1.000000"
      stroke="none"
      d="
M442.231079,500.117249 
	C442.428314,500.052979 442.705292,500.240265 443.087769,500.660736 
	C442.899139,500.718872 442.604980,500.543854 442.231079,500.117249 
z"
    />
    <path
      fill="#314750"
      opacity="1.000000"
      stroke="none"
      d="
M480.986877,505.223145 
	C481.176361,505.162109 481.492188,505.342560 481.909668,505.780060 
	C481.711975,505.846252 481.412598,505.655426 480.986877,505.223145 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M462.955139,468.581879 
	C462.244049,468.193604 462.236450,467.802002 462.742554,467.455383 
	C462.848572,467.382782 463.094666,467.385132 463.198120,467.460327 
	C463.693634,467.820404 463.675323,468.211792 462.955139,468.581879 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M478.169006,550.139709 
	C478.231079,549.546692 478.328857,549.130920 478.357971,548.401367 
	C478.289276,548.087585 478.306702,547.713257 478.280853,547.528687 
	C480.136383,545.647766 481.829407,545.319397 483.243317,548.328857 
	C483.363922,548.820801 483.347687,548.995972 483.067535,549.171082 
	C482.086823,551.583191 480.361725,551.010193 478.435425,550.621216 
	C478.191345,550.678406 478.204742,550.317017 478.169006,550.139709 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M519.471924,573.456543 
	C519.521484,573.428284 519.422363,573.484802 519.471924,573.456543 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M506.943665,529.692810 
	C505.554626,529.394836 504.134979,528.747253 502.376709,528.062622 
	C499.044739,526.977112 499.713776,525.311157 501.636597,523.248901 
	C501.998993,523.002930 502.498291,523.001709 502.747925,523.002075 
	C502.770264,526.062378 506.156006,526.891113 506.943665,529.692810 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M507.379761,540.981689 
	C507.796326,540.069885 508.619720,539.167908 509.723358,538.134460 
	C510.872498,538.535828 511.006958,539.393677 511.034241,540.637817 
	C509.959808,540.977966 508.873199,540.984741 507.379761,540.981689 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M501.600464,523.010559 
	C501.309601,524.472473 500.471039,525.926086 501.774353,527.802856 
	C501.773956,528.820923 501.509857,529.616333 501.127869,530.708130 
	C501.123077,531.168396 501.236176,531.332092 501.174072,531.737549 
	C496.852936,534.061218 493.373566,530.951538 489.295044,530.249146 
	C488.610687,529.578491 488.160980,529.201843 487.282013,528.998474 
	C486.989105,528.962830 486.491119,528.945801 486.242249,528.958618 
	C484.761475,528.136230 483.001862,527.249023 485.546600,526.008911 
	C487.113312,525.245361 487.000519,524.052246 487.003906,522.380310 
	C486.853149,521.833496 486.708984,521.665527 486.821655,521.287109 
	C488.786987,520.790161 489.179260,519.762756 488.635254,518.250610 
	C488.095276,516.749756 488.769867,515.980652 490.190857,516.058472 
	C494.925262,516.317932 494.201660,513.666626 493.068176,510.379395 
	C493.818726,510.242432 494.617279,510.493896 495.707947,510.873291 
	C496.393158,511.884827 496.617188,512.906250 497.206757,513.629822 
	C501.228455,518.565735 500.772888,520.252930 494.387024,522.843140 
	C496.710175,525.377075 499.010773,522.862610 501.600464,523.010559 
z"
    />
    <path
      fill="#706F70"
      opacity="1.000000"
      stroke="none"
      d="
M489.740906,537.771729 
	C489.900452,538.078552 489.894531,538.532227 489.889587,538.759033 
	C489.598022,540.711975 491.490753,542.971985 488.311584,544.086182 
	C486.339783,544.312927 484.755768,542.358826 482.751343,544.002197 
	C482.156281,544.150574 481.882721,544.262817 481.206726,544.188354 
	C480.509460,543.672791 480.214600,543.343811 479.933258,542.782654 
	C479.544647,540.384399 477.887665,538.468750 478.908508,535.784912 
	C480.603424,534.648315 482.065521,534.715820 483.836792,535.602295 
	C485.432739,535.864746 484.562531,540.349670 487.579163,537.143555 
	C488.560669,536.599121 489.130768,536.844849 489.740906,537.771729 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M484.332458,537.306641 
	C482.954559,535.546448 481.289978,536.100159 479.411102,535.998840 
	C477.956665,535.853394 477.313019,535.209656 476.951477,533.884766 
	C479.238525,531.167053 481.764557,531.928284 484.653748,532.970459 
	C485.198212,532.752075 485.432098,532.540039 485.859070,532.189026 
	C486.019592,532.014160 486.004730,532.007935 486.013153,532.022095 
	C487.244324,533.876160 490.173584,534.445190 489.937378,537.711304 
	C489.408722,537.799622 488.916962,537.520752 488.147919,537.184570 
	C486.766785,537.032654 485.713257,537.407715 484.332458,537.306641 
z"
    />
    <path
      fill="#7F7D7D"
      opacity="1.000000"
      stroke="none"
      d="
M464.961304,518.290527 
	C466.137054,518.026978 467.091583,517.480042 467.659363,516.361389 
	C468.349243,515.002197 467.943237,512.297485 470.932404,514.028625 
	C473.916931,515.757080 472.331024,517.449524 470.876709,519.483398 
	C469.962646,520.056396 469.238525,520.351440 468.243713,520.767700 
	C467.786896,521.098328 467.600861,521.307556 467.287598,521.116577 
	C466.487518,519.979553 465.124329,519.927185 464.961304,518.290527 
z"
    />
    <path
      fill="#969493"
      opacity="1.000000"
      stroke="none"
      d="
M476.727478,533.496399 
	C477.526459,534.174561 478.092285,534.823730 478.837921,535.739075 
	C478.845825,538.052490 481.931274,539.477844 480.038818,542.193237 
	C477.450470,540.912842 475.851349,538.642456 475.149017,535.386963 
	C475.135193,534.583923 475.248291,534.172241 475.759521,533.605713 
	C476.027374,533.415283 476.494354,533.467407 476.727478,533.496399 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M488.033722,544.398560 
	C489.862671,542.949890 488.683075,540.867920 489.653442,539.177368 
	C490.873291,541.290833 493.775360,543.013428 492.017456,546.465881 
	C491.533295,546.838013 491.094543,546.885071 490.324310,546.928833 
	C489.356415,546.181885 488.719971,545.438232 488.033722,544.398560 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M476.069580,533.107300 
	C475.889130,533.811890 475.750916,534.208496 475.382812,534.818359 
	C475.051819,532.924072 471.868347,535.430359 471.954193,532.846008 
	C472.009613,531.178528 473.537964,531.184692 474.328644,530.770996 
	C476.842743,529.455811 475.734802,531.888550 476.069580,533.107300 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M474.149506,528.090698 
	C473.509521,526.716858 472.394897,525.616516 474.387054,523.817810 
	C474.047363,525.699585 477.861877,526.517944 474.149506,528.090698 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M482.020813,518.209595 
	C483.046814,516.877563 484.122650,516.543579 484.976776,518.655273 
	C483.795502,520.490845 482.987396,519.450867 482.020813,518.209595 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M458.709381,527.030457 
	C458.752502,527.501465 458.528839,528.019775 458.243073,528.501221 
	C458.221497,528.537598 457.589844,528.370972 457.529205,528.197998 
	C457.257874,527.423645 457.804901,527.193665 458.709381,527.030457 
z"
    />
    <path
      fill="#444A52"
      opacity="1.000000"
      stroke="none"
      d="
M459.003662,527.004028 
	C459.270508,526.495605 459.411377,525.803528 459.829620,525.518921 
	C460.293121,525.203552 460.744324,525.732117 460.583984,526.218262 
	C460.481232,526.529785 459.934906,526.695068 459.289551,526.959106 
	C458.991669,526.992371 459.000000,527.000000 459.003662,527.004028 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M483.236572,548.777527 
	C483.945129,549.245117 484.505188,549.845520 483.702911,550.663696 
	C483.415497,550.379211 483.128052,550.094727 482.822113,549.490601 
	C482.803619,549.171021 483.092957,548.909485 483.236572,548.777527 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M467.125671,520.984009 
	C467.160400,520.716248 467.569000,520.793457 467.770996,520.841248 
	C467.859253,521.275635 467.745544,521.662231 467.631836,522.048828 
	C467.451569,521.783142 467.271271,521.517517 467.125671,520.984009 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M487.059357,521.058228 
	C487.078430,521.076721 487.023499,521.537781 487.010376,521.769653 
	C486.677368,521.896484 486.417908,521.708191 486.110657,521.218262 
	C486.169403,520.883057 486.336334,520.766113 486.752869,520.825806 
	C487.002441,521.002441 487.040283,521.039734 487.059357,521.058228 
z"
    />
    <path
      fill="#323C48"
      opacity="1.000000"
      stroke="none"
      d="
M487.016632,520.746582 
	C486.835754,521.004944 486.669067,521.007507 486.252411,521.005005 
	C486.102814,520.670654 486.291779,520.404236 486.792084,520.089539 
	C487.020111,520.149109 487.025482,520.319885 487.016632,520.746582 
z"
    />
    <path
      fill="#233441"
      opacity="1.000000"
      stroke="none"
      d="
M485.799805,532.121582 
	C485.919067,532.341675 485.728363,532.603455 485.222229,532.899780 
	C485.112122,532.683167 485.306488,532.426147 485.799805,532.121582 
z"
    />
    <path
      fill="#6F9AA6"
      opacity="1.000000"
      stroke="none"
      d="
M480.937134,544.232788 
	C481.073914,544.016663 481.343475,544.031494 482.021423,544.042236 
	C481.976562,544.180176 481.523254,544.322021 480.937134,544.232788 
z"
    />
    <path
      fill="#252D38"
      opacity="1.000000"
      stroke="none"
      d="
M487.162964,529.212036 
	C487.910828,527.966553 488.542664,528.221558 488.997314,529.703735 
	C488.471222,529.813049 487.904022,529.637146 487.162964,529.212036 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M501.170715,531.902405 
	C500.998901,531.979248 501.001617,531.491821 501.005798,531.248230 
	C501.120819,531.278259 501.231689,531.551880 501.170715,531.902405 
z"
    />
    <path
      fill="#2F343B"
      opacity="1.000000"
      stroke="none"
      d="
M485.003601,357.006104 
	C480.892151,358.110321 476.775726,359.207031 472.353699,360.163116 
	C471.817047,359.712921 471.824097,359.430939 471.931671,359.127808 
	C472.087677,358.688263 472.802246,358.767975 472.765198,358.181274 
	C472.528107,358.400696 472.236389,358.414398 471.930176,357.882721 
	C473.771301,354.652588 476.034119,357.674866 478.126038,357.102509 
	C479.982727,356.594513 482.085388,356.985382 484.532104,356.982025 
	C484.984741,356.984528 484.998627,356.998566 485.003601,357.006104 
z"
    />
    <path
      fill="#696868"
      opacity="1.000000"
      stroke="none"
      d="
M467.684692,361.661011 
	C466.481232,362.282013 465.117401,362.624390 463.397888,362.975525 
	C464.330017,361.873230 465.538330,360.539825 467.684692,361.661011 
z"
    />
    <path
      fill="#A9A8A7"
      opacity="1.000000"
      stroke="none"
      d="
M471.617859,357.630646 
	C473.173645,358.049896 473.514465,358.679993 472.151917,359.780701 
	C471.205933,360.073608 470.418427,360.000000 470.171356,358.993744 
	C469.948334,358.085419 470.734283,358.001892 471.617859,357.630646 
z"
    />
    <path
      fill="#27292F"
      opacity="1.000000"
      stroke="none"
      d="
M460.070129,371.794434 
	C461.691223,371.436798 462.986267,371.223846 464.676636,370.983246 
	C465.421265,372.433380 464.349274,373.143372 463.326538,373.440247 
	C459.604187,374.520752 456.345673,376.781097 452.247131,377.831421 
	C451.965546,378.078003 451.984467,377.978821 452.034576,377.984955 
	C451.600281,378.396301 450.983551,378.475677 450.188782,378.874023 
	C449.964172,379.078369 449.985443,379.014099 450.014313,379.031799 
	C449.344910,379.565826 448.531555,379.477539 447.336182,379.484009 
	C446.372162,379.383392 445.964417,379.079132 445.819519,378.180481 
	C447.627869,376.883636 449.328827,375.972900 451.315918,374.901398 
	C454.316071,373.806793 457.030060,372.872955 460.070129,371.794434 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M441.664764,380.058502 
	C442.872253,385.051971 439.554169,384.487640 436.093262,384.022278 
	C437.625244,382.628906 439.477783,381.374847 441.664764,380.058502 
z"
    />
    <path
      fill="#191818"
      opacity="1.000000"
      stroke="none"
      d="
M445.633728,377.864716 
	C446.184967,378.129486 446.443024,378.464630 446.988281,378.944031 
	C447.322693,379.281738 447.369873,379.475189 447.184326,379.855896 
	C446.951630,380.043152 446.968323,379.960876 447.010254,379.962891 
	C446.670685,380.338348 446.214508,380.497070 445.356354,380.252838 
	C444.816040,379.785614 444.603058,379.506989 444.158783,379.096100 
	C444.398499,378.620911 444.869476,378.277985 445.633728,377.864716 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M452.352386,378.196747 
	C455.848358,375.089508 460.468933,373.588348 464.977448,371.313110 
	C465.072021,370.955597 465.009674,370.983459 465.024170,371.014404 
	C466.908752,369.967194 468.936401,370.726257 471.289978,370.677612 
	C472.009949,371.000061 472.323364,371.188995 472.789856,371.606415 
	C466.208405,373.995087 459.473816,376.155273 452.352386,378.196747 
z"
    />
    <path
      fill="#14151A"
      opacity="1.000000"
      stroke="none"
      d="
M572.856201,385.295471 
	C573.092896,385.733978 573.074646,385.987457 573.028198,386.621033 
	C572.119995,387.033905 571.239990,387.066620 569.923340,387.157654 
	C568.752136,387.010193 568.017517,386.804382 567.126221,386.357727 
	C567.111084,385.049347 567.541992,384.214722 569.067078,383.878571 
	C571.191223,382.545441 571.544678,384.671967 572.856201,385.295471 
z"
    />
    <path
      fill="#0B0C10"
      opacity="1.000000"
      stroke="none"
      d="
M513.048096,369.432587 
	C513.448486,369.126923 513.894043,369.095367 514.668213,369.136475 
	C514.890625,369.823486 514.783997,370.536316 514.019653,370.536133 
	C513.710815,370.536041 513.402100,369.999268 513.048096,369.432587 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M447.314697,380.120056 
	C446.996796,379.881012 447.041962,379.718903 447.181305,379.322571 
	C447.984344,378.963684 448.693207,378.839050 449.722595,378.881958 
	C449.739319,380.431030 448.547485,379.982025 447.314697,380.120056 
z"
    />
    <path
      fill="#D9D9D8"
      opacity="1.000000"
      stroke="none"
      d="
M450.328003,379.107178 
	C449.831726,377.979156 450.718384,378.079071 451.722687,377.941284 
	C452.242645,379.129730 451.324829,378.959625 450.328003,379.107178 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M445.103088,380.395325 
	C445.478119,380.022583 445.927155,379.980927 446.714172,379.952118 
	C446.954010,381.516083 446.022339,381.014893 445.103088,380.395325 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M629.713501,577.139832 
	C630.413391,577.954163 630.073120,578.820007 629.297363,579.854980 
	C628.524963,579.046326 628.949097,578.175781 629.713501,577.139832 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M630.703613,575.036560 
	C630.920776,575.639587 630.803894,576.308105 630.339355,577.009399 
	C630.117676,576.395447 630.243591,575.748718 630.703613,575.036560 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M627.759827,581.126587 
	C627.917419,581.620483 627.805481,582.278137 627.342712,582.988892 
	C627.158081,582.458069 627.324219,581.874146 627.759827,581.126587 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M628.724976,579.922729 
	C628.899536,580.378723 628.755981,580.768433 628.294067,581.098145 
	C628.119385,580.644165 628.263184,580.250305 628.724976,579.922729 
z"
    />
    <path
      fill="#757373"
      opacity="1.000000"
      stroke="none"
      d="
M471.422302,370.584595 
	C469.754028,370.947998 467.811523,371.084900 465.453796,371.133545 
	C465.016632,370.875763 464.994598,370.706177 464.998962,370.281586 
	C467.066223,370.137085 469.107117,370.247559 471.422302,370.584595 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M487.391602,509.005920 
	C486.991394,509.006989 486.987457,508.526825 486.977936,508.287384 
	C486.912354,506.142456 485.224304,505.375977 484.190430,504.184631 
	C482.588165,502.338196 482.812286,501.327271 485.668091,501.010925 
	C488.260406,502.015320 489.546997,504.636505 492.667664,504.853149 
	C492.825897,505.551605 492.649323,506.103210 492.233276,506.828400 
	C491.549561,507.439819 491.493561,508.020050 491.201477,508.791199 
	C489.925140,508.996185 488.858459,509.000519 487.391602,509.005920 
z"
    />
    <path
      fill="#1A1E28"
      opacity="1.000000"
      stroke="none"
      d="
M490.996429,508.996368 
	C491.166107,508.442780 491.340393,507.893707 491.754272,507.173340 
	C491.999664,507.445862 492.005524,507.889740 492.003632,508.666443 
	C491.832397,509.116547 491.668945,509.233795 491.257568,509.179047 
	C491.009613,509.007080 491.001038,509.000885 490.996429,508.996368 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M491.120422,509.220764 
	C491.009613,509.007080 491.502747,509.000305 491.749298,508.999817 
	C491.995850,508.999329 492.008698,509.466797 492.022766,509.699585 
	C491.707855,509.864136 491.439301,509.698181 491.120422,509.220764 
z"
    />
    <path
      fill="#0E1115"
      opacity="1.000000"
      stroke="none"
      d="
M486.310028,498.798950 
	C485.772919,498.515991 485.433075,498.047333 485.086273,497.272919 
	C485.950531,497.173004 487.020874,497.206116 486.310028,498.798950 
z"
    />
    <path
      fill="#101318"
      opacity="1.000000"
      stroke="none"
      d="
M499.473511,527.458191 
	C499.521667,527.431152 499.425354,527.485229 499.473511,527.458191 
z"
    />
    <path
      fill="#A3A2A1"
      opacity="1.000000"
      stroke="none"
      d="
M484.044159,537.512024 
	C485.251312,537.775391 486.071991,534.449585 487.662689,536.963806 
	C487.676849,538.538574 488.771606,540.697449 486.326935,540.898682 
	C484.572449,541.043152 484.109955,539.434448 484.044159,537.512024 
z"
    />
  </svg>
);



